import {Form, FormListFieldData} from 'antd';
import {fixtureCurrencyUnits, newPeriodOption} from '../../../utils';
import {Select} from '../../../../../../../antd/components/Select';
import {FC} from 'react';
import {assert} from '../../../../../../../utils/assert';
import {greaterThanOrEqualTo} from '../../../../../../../utils/AntDValidator';
import {InputNumber} from '../../../../../../../antd/components/InputNumber';

export const RateAndCurrencyField: FC<{
  field: FormListFieldData;
}> = ({field}) => {
  return (
    <Form.Item
      noStyle
      name={[field.name, 'rate']}
      rules={[
        {type: 'number'},
        {
          validator: greaterThanOrEqualTo(0),
          message: 'Must be >= 0',
        },
      ]}
      data-cy="rate">
      <RateInputBox field={field} />
    </Form.Item>
  );
};

// Is needed for antd Form.Item to work
const RateInputBox: FC<{
  field: FormListFieldData;
  value?: number | null;
  onChange?: (value: number | null) => void;
}> = ({field, onChange, value}) => {
  return (
    <InputNumber
      value={value}
      precision={0}
      onChange={value => {
        assert(typeof value === 'number' || value === null);
        onChange?.(value);
      }}
      addonAfter={<RateCurrencyPicker field={field} />}
      style={{padding: 0}}
    />
  );
};

const RateCurrencyPicker: FC<{
  field: FormListFieldData;
}> = ({field}) => {
  return (
    <Form.Item noStyle name={[field.name, 'rateCurrency']} data-cy="rateCurrency">
      <Select
        size={'small'}
        defaultValue={newPeriodOption.rateCurrency}
        style={{
          width: 80,
        }}
        options={Object.entries(fixtureCurrencyUnits).map(([value, label]) => ({value, label}))}
      />
    </Form.Item>
  );
};
