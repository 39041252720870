import {FC} from 'react';
import {AddItemToProjectModal} from '../../../components/Modal/AddItemToProjectModal/AddItemToProjectModal';
import {useSelector} from '../../../redux/react-redux';

type Props = {projectType: 'cargo' | 'vessel'; visible: boolean; onCancel: () => void};

export const AddClipboardItemsToProjectModal: FC<Props> = props => {
  const marketClipboard = useSelector(state => state.gridClipboard.market);
  const {projectType} = props;
  switch (projectType) {
    case 'vessel': {
      const cargoIds: number[] = marketClipboard.cargoes.map(({id}) => id);
      return (
        <AddItemToProjectModal
          visible={props.visible}
          onCancel={props.onCancel}
          projectType={'vessel'}
          cargoIds={cargoIds}
        />
      );
    }
    case 'cargo': {
      const vesselIds: number[] = marketClipboard.vessels.map(({id}) => id);
      return (
        <AddItemToProjectModal
          visible={props.visible}
          onCancel={props.onCancel}
          projectType={'cargo'}
          vesselIds={vesselIds}
        />
      );
    }
  }
};
