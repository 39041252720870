import React, {FC} from 'react';
import {BasicBox} from './BasicBox';
import classNames from 'classnames';

export const ChartLabel: FC<{borderColor: string; right: boolean; text: string; disable: boolean}> = ({
  borderColor,
  text,
  right,
}) => {
  return (
    <BasicBox style={{borderColor}} className={classNames({right})}>
      {text}
    </BasicBox>
  );
};
