/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ChokepointCongestionSummaryReport,
  ChokepointCongestionSummaryReportFromJSON,
  ChokepointCongestionSummaryReportFromJSONTyped,
  ChokepointCongestionSummaryReportToJSON,
  Visit,
  VisitFromJSON,
  VisitFromJSONTyped,
  VisitToJSON,
} from './';

/**
 *
 * @export
 * @interface GetChokepointCongestionStatisticsResponse
 */
export interface GetChokepointCongestionStatisticsResponse {
  /**
   *
   * @type {Array<Visit>}
   * @memberof GetChokepointCongestionStatisticsResponse
   */
  visits: Array<Visit>;
  /**
   *
   * @type {ChokepointCongestionSummaryReport}
   * @memberof GetChokepointCongestionStatisticsResponse
   */
  report: ChokepointCongestionSummaryReport;
  /**
   *
   * @type {string}
   * @memberof GetChokepointCongestionStatisticsResponse
   */
  queryTimestamp: string;
}

export function GetChokepointCongestionStatisticsResponseFromJSON(
  json: any
): GetChokepointCongestionStatisticsResponse {
  return GetChokepointCongestionStatisticsResponseFromJSONTyped(json, false);
}

export function GetChokepointCongestionStatisticsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetChokepointCongestionStatisticsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    visits: (json['visits'] as Array<any>).map(VisitFromJSON),
    report: ChokepointCongestionSummaryReportFromJSON(json['report']),
    queryTimestamp: json['queryTimestamp'],
  };
}

export function GetChokepointCongestionStatisticsResponseToJSON(
  value?: GetChokepointCongestionStatisticsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    visits: (value.visits as Array<any>).map(VisitToJSON),
    report: ChokepointCongestionSummaryReportToJSON(value.report),
    queryTimestamp: value.queryTimestamp,
  };
}
