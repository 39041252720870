import {LocationType} from '../../api/symfony/generated';
import {Location} from '../../components/FilterProvider/Filters/utils/Location';

export const getLocationValues = (values: Location[] | readonly Location[] | null | undefined): Location[] => {
  if (!values || !Array.isArray(values)) {
    return [];
  }
  return values.reduce((acc, currentLocation) => {
    if (currentLocation.type === LocationType.Collection) {
      currentLocation.locations.forEach(subLocation => {
        if (!values.some(location => location.id === subLocation.id)) {
          acc.push(subLocation);
        }
      });
    } else {
      acc.push(currentLocation);
    }
    return acc;
  }, [] as Location[]);
};
