import {ReactElement} from 'react';
import {Option} from './AutocompleteOptions';
import {Coordinates} from '../../utils/Coordinates';
import {CargoContractType} from '../../atoms/CargoHexagon/CargoHexagon';
import {TradingArea} from '../../redux/ApiService/MapService/GetTradingAreasResponse';
import {SearchPickerPort} from './SearchPickerPort';
import {VesselTypeEnum} from '../../api/node-backend/generated';
import {Station} from '../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

export type Country = {
  id: number;
  code: string;
  name: string;
  type: 'country';
};

export type SeaboVessel = {
  imo: string;
  id: number;
  name: string;
  builtYear: number;
  callSign: string;
  bale: number;
  type: 'seaboVessel';
  baleUnit: string;
  grainUnit: string;
  grain: number;
  nextOpenFrom: string;
  charterName: string;
  comment: string;
  designSubType: string;
  dwtSummer: number;
  lengthOverall?: number;
  beam?: number;
  draft?: number;
  consumptions: Array<{
    fuelType: string;
    speed: number;
    type: string;
    fuelConsumption: number;
    extraFuelConsumption: number;
    extraFuelType: string;
    id: number;
  }>;
  countryFlag: string;
  vesselType?: VesselTypeEnum;
  stations?: Station[];
};

export interface AisVessel {
  mmsi: number;
  beam: number;
  callsign: string;
  class: string;
  coordinates: string;
  country: string;
  imo: number;
  course: number;
  destination: string;
  destinationArea: number;
  destinationPort: number;
  eta: Date;
  draught: number;
  heading: number;
  loa: number;
  longitude: number;
  latitude: number;
  location: number;
  speed: number;
  type: 'aisVessel';
  name: string;
}

export type StationLocation = {
  name: string;
  additionalInfo?: {
    rate?: number;
    terms?: string;
  };
  type: string;
  code: string;
  country: string;
  coordinates?: Coordinates;
  id: number;
};

export type Cargo = {
  id: number;
  chartererName: string | null;
  cargoType: 'drybulk' | 'container' | null;
  commodity: string | null;
  commodityType: string | null;
  commodityCategory: string | null;
  commodityStowageFactor: number | null;
  commissionTotal: number | null;
  laycanFrom: string | null;
  quantityUnit: string;
  laycanTo: string | null;
  quantityMax: number | null;
  quantityMin: number | null;
  quantity: number | null;
  vesselSizeMax: number | null;
  vesselSizeMin: number | null;
  vesselSize: number | null;

  contractType: CargoContractType | null;
  stations: Station[];
};

export type Contact = {
  id: number;
  fullName: string;
  email: string;
};

export interface PortOption extends Option {
  id: number;
  key: string;
  source: SearchPickerPort;
  value: string;
  label: ReactElement;
  type: 'port';
}
export interface AisVesselOption extends Option {
  id: number;
  key: string;
  source: AisVessel;
  value: string;
  label: ReactElement;
  type: 'aisVessel';
}

export interface CargoOption extends Option {
  id: number;
  key: string;
  source: Cargo;
  value: string;
  label: ReactElement;
  type: 'cargo';
}

export interface SeaboVesselOption extends Option {
  id: number;
  key: string;
  source: SeaboVessel;
  value: string;
  name: string;
  label: ReactElement;
  type: 'seaboVessel';
}

export interface TradingAreaOption extends Option {
  id: number;
  key: string;
  source: TradingArea;
  value: string;
  label: ReactElement;
  type: 'tradingarea';
}

export interface CountryOption extends Option {
  id: number;
  key: string;
  source: Country;
  value: string;
  label: ReactElement;
  type: 'country';
}

export const possibleLocationTypes = ['port', 'country', 'tradingarea'];
export type Location = SearchPickerPort | TradingArea | Country;

export type LocationOption = PortOption | TradingAreaOption | CountryOption;

export interface ContactOption extends Option {
  id: number;
  key: number;
  source: Contact;
  value: string;
  label: ReactElement;
}
