import {Popover} from 'antd';
import dayjs from 'dayjs';
import {ReactNode, FC} from 'react';
import styled from 'styled-components';
import {Attribute} from '../../../../atoms/Attribute/Attribute';
import {getFormattedDateOrNotAvailable} from '../../../../utils/DateTime';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {ResultVessel} from '../types';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';

export const arrivalColumn = ({
  minWidth = 12,
  sortable = true,
}: {
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<ResultVessel> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-shipname">
      <div>Arrive</div>
    </div>
  ),
  id: 'arrive',
  accessorFn: (vessel: ResultVessel): ReactNode => {
    return (
      <Popover color={'white'} content={<TooltipContent vessel={vessel} />}>
        <StyledSpan>{getFormattedDateOrNotAvailable(vessel.estimatedArrivalDate)}</StyledSpan>
      </Popover>
    );
  },
  minWidth,
  cell: info => info.getValue(),
  enableSorting: sortable,
});

export const TooltipContent: FC<{vessel: ResultVessel}> = ({vessel}) => {
  const now = dayjs();
  const eta = dayjs(vessel.eta);
  const startDate = now.isBefore(vessel.eta) ? eta : now;

  const durations = vessel.distanceRoutes.map(route => route.duration / (60000 * 60 * 24));

  return (
    <AttributeContainer>
      <Attribute
        tab={'xl'}
        label={`ETA (${vessel.lastPort?.code})`}
        value={eta ? eta.format(DateTimeFormat.Date) : '-'}
      />
      <Attribute tab={'xl'} label={'Voyage start'} value={startDate.format(DateTimeFormat.Date)} />
      <Attribute tab={'xl'} label={'Voyage duration'} value={durations[0].toFixed(1) + ' days'} />
      <Attribute
        tab={'xl'}
        label={'Estimated arrival'}
        value={startDate.add(durations[0], 'days').format(DateTimeFormat.Date)}
      />
    </AttributeContainer>
  );
};

const StyledSpan = styled.span`
  color: var(--color-azure);
  cursor: pointer;
`;

const AttributeContainer = styled.div`
  padding: 10px;
  margin-bottom: -10px;
`;
