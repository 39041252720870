/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {CompanyMember, CompanyMemberFromJSON, CompanyMemberFromJSONTyped, CompanyMemberToJSON} from './';

/**
 *
 * @export
 * @interface CompanyMemberResponseData
 */
export interface CompanyMemberResponseData {
  /**
   *
   * @type {Array<CompanyMember>}
   * @memberof CompanyMemberResponseData
   */
  items: Array<CompanyMember>;
  /**
   *
   * @type {number}
   * @memberof CompanyMemberResponseData
   */
  totalItems: number;
}

export function CompanyMemberResponseDataFromJSON(json: any): CompanyMemberResponseData {
  return CompanyMemberResponseDataFromJSONTyped(json, false);
}

export function CompanyMemberResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyMemberResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(CompanyMemberFromJSON),
    totalItems: json['totalItems'],
  };
}

export function CompanyMemberResponseDataToJSON(value?: CompanyMemberResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(CompanyMemberToJSON),
    totalItems: value.totalItems,
  };
}
