/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NegotiationSubjectStatus {
  Ongoing = 'ongoing',
  Lifted = 'lifted',
  Rejected = 'rejected',
  RejectionRequested = 'rejection_requested',
  Failed = 'failed',
  Accepted = 'accepted',
}

export function NegotiationSubjectStatusFromJSON(json: any): NegotiationSubjectStatus {
  return NegotiationSubjectStatusFromJSONTyped(json, false);
}

export function NegotiationSubjectStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationSubjectStatus {
  return json as NegotiationSubjectStatus;
}

export function NegotiationSubjectStatusToJSON(value?: NegotiationSubjectStatus | null): any {
  return value as any;
}
