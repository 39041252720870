import {Location} from '../../../../CargoPicker/CargoPicker';

const DEFAULT_TURN_TIME = 12;

export const getTurnTimeFromLocation = (location: Location): number => {
  if (location.additionalInfo?.timeToTurn) {
    return location.additionalInfo.timeToTurn;
  }
  return DEFAULT_TURN_TIME;
};
