import {Helmet} from 'react-helmet-async';
import {useLocation, useParams} from 'react-router-dom';
import CargoForm from './CargoForm/CargoForm';
import {RouteParams} from './RouteParams';

export const CargoFormScreen = () => {
  const params = useParams<RouteParams>();
  const location = useLocation();

  const defaultCargoValues = location.state?.defaultCargoValues;

  return (
    <>
      <Helmet title={params.id ? 'Edit cargo' : 'Add cargo'} />
      <CargoForm newCargoFromExternal={defaultCargoValues} />
    </>
  );
};
