import React, {FC} from 'react';
import styled from 'styled-components';
import {Divider} from 'antd';
import {SidebarControls} from './SidebarControls';
import {getHasProPlan, getSidebarMode} from '../../../redux/selectors';
import {useSelector} from '../../../redux/react-redux';
import {PromoBanner} from './PromoBanner';

export const SidebarLowerAreaContent: FC = () => {
  const sidebarMode = useSelector(getSidebarMode);
  const hasProPlan = useSelector(getHasProPlan);

  return (
    <>
      {!hasProPlan && <PromoBanner />}
      <DividerStyled />
      <SidebarControls mode={sidebarMode} />
    </>
  );
};

const DividerStyled = styled(Divider)`
  margin-top: 0;
  margin-bottom: 0;
`;
