import React, {useState} from 'react';
import {Button, Form, Input, message} from 'antd';
import {Workspace} from '../../../redux/ApiService/PortfolioService';
import {SubPortfolio} from '../../../redux/Portfolio';
import {CloneWorkspaceSelector} from './components/CloneWorkspaceSelector';
import {CloneSelectSubPortfolios} from './components/CloneSelectSubPortfolios';
import {useCloneCargoToMyCargoesMutation} from './useCloneCargoToMyCargoesMutation';
import {Modal, ModalWidth} from '../../../antd/components/Modal';
import {useUser} from '../../UserContext/useUser';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {assert} from '../../../utils/assert';

type Props = {
  open: boolean;
  cargo: PortfolioCargo;
  onSuccess: (newCargo: PortfolioCargo) => void;
  onCancel: () => void;
};

export const AddToMyCargoesModal = (props: Props) => {
  assert(props.cargo, 'Missing cargo');
  const user = useUser();
  const activeWorkspace = user.workspace;
  const [chartererName, setChartererName] = useState(props.cargo.chartererName ?? '');
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(activeWorkspace);
  const [selectedSubPortfolios, setSelectedSubPortfolios] = useState<SubPortfolio[]>([]);

  const cloneCargoMutation = useCloneCargoToMyCargoesMutation({
    onSuccess: newCargo => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success(`Cargo was added to My Cargoes.`);
      props.onSuccess(newCargo);
      props.onCancel();
    },
  });

  return (
    <Modal
      centered={true}
      onCancel={props.onCancel}
      width={ModalWidth.Middle}
      footer={
        <Button
          data-cy={'clonePortfolioEntryModalCloneBtn'}
          disabled={!chartererName}
          type="primary"
          loading={cloneCargoMutation.isPending}
          onClick={async () => {
            cloneCargoMutation.mutate({
              cargo: {...props.cargo, chartererName},
              workspaceId: selectedWorkspace.id,
              subPortfolioIds: selectedSubPortfolios.map(item => item.id),
            });
          }}>
          Add to My Cargoes
        </Button>
      }
      open={props.open}
      title={'Add to My Cargoes'}>
      <div data-cy={'AddToMyCargoesModalContent'}>
        <Form layout={'vertical'}>
          <Form.Item label="Charter name">
            <Input id={'name'} value={chartererName} onChange={event => setChartererName(event.target.value)} />
          </Form.Item>
          <CloneWorkspaceSelector selectedWorkspace={selectedWorkspace} onChange={setSelectedWorkspace} />
          <CloneSelectSubPortfolios
            type={'cargo'}
            workspace={selectedWorkspace}
            selectedSubPortfolios={selectedSubPortfolios}
            onChange={setSelectedSubPortfolios}
          />
        </Form>
      </div>
    </Modal>
  );
};
