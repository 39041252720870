import {Button} from 'antd';
import React, {ReactElement} from 'react';
import {Modal} from '../../../antd/components/Modal';
import {ModalFooter} from '../../../antd/components/ModalFooter';
import styled from 'styled-components';

interface Props {
  visible: boolean;
  title: string;
  description: ReactElement;
  onClickCreate: () => void;
  onClickUpdate: () => void;
  onClose: () => void;
}

export const CreateOrUpdateFilterChoiceModal = ({
  visible,
  title,
  description,
  onClickCreate,
  onClickUpdate,
  onClose,
}: Props) => (
  <Modal title={title} open={visible} footer={null} destroyOnClose onCancel={onClose}>
    <Description>{description}</Description>
    <ModalFooter>
      <Button type="primary" onClick={onClickCreate}>
        Create new
      </Button>

      <Button type="primary" onClick={onClickUpdate}>
        Update
      </Button>
    </ModalFooter>
  </Modal>
);

const Description = styled.div`
  text-align: center;
`;
