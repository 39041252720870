import {useQuery} from '@tanstack/react-query';
import {DraftItem, useDraftStatsQuery} from './useDraftStatsQuery';

export const useDraftStatsMapQuery = () => {
  const draftStatsQuery = useDraftStatsQuery();

  return useQuery({
    queryKey: ['draftStatsMap'],
    queryFn: () => {
      const map: Record<number, DraftItem> = {};
      draftStatsQuery.data!.forEach(draftItem => {
        map[draftItem.imo] = draftItem;
      });
      return map;
    },
    enabled: draftStatsQuery.isSuccess,
    staleTime: Infinity,
  });
};
