import {Divider} from 'antd';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import {FC, useContext} from 'react';
import {Attribute, TabSize} from '../../../../../../atoms/Attribute/Attribute';
import {DateTimeFormat} from '../../../../../../utils/DateTimeFormat';
import {VoyageCharterContext} from '../../../Context/VoyageCharterContext';
import {CalcTimeLinePoint} from '../../VoyageTypes';
import {ExplainDurationItem, useDurationAtPortExplainQuery} from './useDurationAtPortExplainQuery';

const tabSize: TabSize = 'xxl';

export const PointOverlay: FC<{point: CalcTimeLinePoint}> = ({point}) => {
  const start = dayjs(point.outputItem?.startDate);
  const end = dayjs(point.outputItem?.endDate);
  const voyageContext = useContext(VoyageCharterContext);

  const durationAtPortExplainQuery = useDurationAtPortExplainQuery({
    point,
    laycanFrom: voyageContext.state.inputState.voyage.laycanFrom,
  });

  const data = durationAtPortExplainQuery.data?.map(item => [item.date, item.working ? 10 : 2]);

  const month = getMonths(durationAtPortExplainQuery.data ?? []);
  return (
    <div style={{color: 'var(--color-black)'}}>
      <Divider orientation={'center'}>Duration at port</Divider>
      <Attribute label="Start" value={`${start.format(DateTimeFormat.Date)}`} tab={tabSize} />
      <Attribute label="End" value={`${end.format(DateTimeFormat.Date)}`} tab={tabSize} />

      <ReactECharts
        style={{height: Math.ceil(month.length / 2) * 160}}
        option={{
          visualMap: {
            show: false,
            min: 0,
            max: 10,
          },
          calendar: month.map((range, index) => {
            const isLeft = index % 2 === 0;
            const top = Math.floor(index / 2) * 160;
            return {orient: 'vertical', range: range, left: isLeft ? 40 : 230, top};
          }),
          series: month.map((_, index) => ({
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: data,
            calendarIndex: index,
          })),
        }}
      />
    </div>
  );
};

const getMonths = (items: ExplainDurationItem[]): string[] => {
  const months: Record<string, true> = {};
  items.forEach(item => {
    months[dayjs(item.date).format('YYYY-MM')] = true;
  });
  return Object.keys(months);
};
