import emptyStateMarketCargoes from './empty-states-market-cargoes.png';
import emptyStateMarketVessels from './empty-states-market-vessels.png';

import './style.scss';

const EmptyStateMarket = ({type}: $TSFixMe) => (
  <div className="emptyStateMarketContainer">
    <div className="emptyStateMarketContainer__image">
      <img src={type === 'vessels' ? emptyStateMarketVessels : emptyStateMarketCargoes} alt="All filters" />
    </div>
    <h2 className="emptyStateMarketContainer__headline">No {type} in Market</h2>
    <div className="emptyStateMarketContainer__subline">
      There are currently no {type} in your market.
      <br />
      Please select a different workspace.
    </div>
  </div>
);

export default EmptyStateMarket;
