/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Bale volume unit - default is "cbm"
 * @export
 * @enum {string}
 */
export enum IntakeUnitEnum {
  Cbm = 'cbm',
  Cft = 'cft',
}

export function IntakeUnitEnumFromJSON(json: any): IntakeUnitEnum {
  return IntakeUnitEnumFromJSONTyped(json, false);
}

export function IntakeUnitEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntakeUnitEnum {
  return json as IntakeUnitEnum;
}

export function IntakeUnitEnumToJSON(value?: IntakeUnitEnum | null): any {
  return value as any;
}
