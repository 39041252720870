import {useMutation, useQueryClient} from '@tanstack/react-query';
import {portfolioGroupApi} from '../../../api/symfony/symfonyApi';
import {PortfolioGroupList, UpdateSubPortfolioSortOrderRequest} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';
import produce from 'immer';
import arrayMove from 'array-move';
import {invalidateGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';
import {PortfolioType} from '../../../redux/Portfolio';
import {mapPortfolioTypeToEnum} from '../../../queries/mapPortfolioTypeToEnum';

interface UpdateSubPortfolioSortOrderParams {
  type: PortfolioType;
  oldIndex: number;
  newIndex: number;
}

export const useUpdateSubPortfolioSortOrder = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<PortfolioGroupList, ApiError, UpdateSubPortfolioSortOrderRequest>({
    mutationFn: request => portfolioGroupApi.updateSubPortfolioSortOrder(request),
  });

  const updateSubPortfolioSortOrder = async ({type, oldIndex, newIndex}: UpdateSubPortfolioSortOrderParams) => {
    const subPortfoliosQueryKey = ['subPortfolios', type];
    await queryClient.cancelQueries({
      queryKey: subPortfoliosQueryKey,
    });

    // Calculate our new state so that we can display it immediately
    const portfolioGroupList = queryClient.getQueryData(subPortfoliosQueryKey) as PortfolioGroupList;
    const portfolioGroupListInNewOrder = produce(portfolioGroupList, draftPortfolioGroupList => {
      const subPortfolios = draftPortfolioGroupList.data.items;
      arrayMove.mutate(subPortfolios, oldIndex, newIndex);
      subPortfolios.forEach((item, index) => {
        item.sortOrder = index;
      });
    });

    // Optimistically update to the state
    queryClient.setQueryData(subPortfoliosQueryKey, portfolioGroupListInNewOrder);

    // Talk to the API
    const subPortfolioIdsInNewOrder = portfolioGroupListInNewOrder.data.items.map(subPortfolio => subPortfolio.id);
    mutation.mutate(
      {
        type: mapPortfolioTypeToEnum(type),
        requestBody: subPortfolioIdsInNewOrder,
      },
      {
        onSettled: async (_data, _error, variables) => {
          // Reload
          await invalidateGetSubPortfoliosQuery(queryClient, variables.type);
        },
      }
    );
  };

  return updateSubPortfolioSortOrder;
};
