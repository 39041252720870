import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {reducers as attachmentApiReducer} from './ApiService/AttachmentService';
import {reducers as autocompleteApiReducer} from './ApiService/autocomplete';
import {reducers as cargoApiReducer} from './ApiService/cargoService';
import {reducers as commodityApiReducer} from './ApiService/commodity';
import {reducers as companyImageApiReducer} from './ApiService/companyImageService';
import {reducers as contactsApiReducer} from './ApiService/ContactsService';
import {reducers as contribApiReducer} from './ApiService/contribService';
import {reducers as fixtureApiReducer} from './ApiService/FixtureService';
import {reducers as mapApiReducer} from './ApiService/MapService/MapService';
import {reducers as marketApiReducer} from './ApiService/MarketService';
import {reducers as monitorApiReducer} from './ApiService/monitor';
import {reducers as portfolioApiReducer} from './ApiService/PortfolioService';
import {reducers as portsApiReducer} from './ApiService/ports';
import {reducers as projectApiReducer} from './ApiService/ProjectService';
import {reducers as spotifindApiReducer} from './ApiService/SpotifindService';
import {reducers as tradingAreaApiReducer} from './ApiService/tradingAreaService';
import {reducers as vesselApiReducer} from './ApiService/vesselService';
import {authenticationReducer} from './Authentication';
import {charterCalculationReducer} from './CharterCalculation';
import {chatReducer} from './Chat';
import {companyReducer} from './Company';
import {contactsReducer} from './Contacts';
import {contribReducer} from './Contrib';
import {fixtureDataReducer} from './FixtureData';
import {gridClipboardReducer} from './GridClipboard';
import {mapReducer} from './Map';
import {mapDetailsReducer} from './MapDetails';
import {marketReducer} from './Market';
import {marketFiltersReducer} from './MarketFilters';
import {apiMiddleware} from './middlware/apiMiddleware';
import {modalReducer} from './Modal';
import {monitorBackendReducer} from './MonitorBackend';
import {portBackendDetailsReducer} from './PortBackendDetails';
import {portfolioReducer} from './Portfolio';
import {portfolioFiltersReducer} from './PortfolioFilters';
import {portsReducer} from './Ports';
import {portsBackendReducer} from './PortsBackend';
import {preFillReducer} from './PreFill';
import {projectsReducer} from './Projects';
import {searchReducer} from './Search';
import {sidebarReducer} from './Sidebar';
import {spotifindReducer} from './Spotifind';
import {subMarketReducer} from './SubMarket';
import {unknownLocationReducer} from './UnknownLocation';
import {userReducer} from './User';
import {vesselDatabaseReducer} from './VesselDatabase';
import {vesselDetailsReducer} from './VesselDetails';
import {weatherReducer} from './Weather';

const rootReducer = combineReducers({
  api: combineReducers({
    attachmentService: attachmentApiReducer,
    autoComplete: autocompleteApiReducer,
    cargoes: cargoApiReducer,
    commodity: commodityApiReducer,
    companyImage: companyImageApiReducer,
    contacts: contactsApiReducer,
    contrib: contribApiReducer,
    fixture: fixtureApiReducer,
    map: mapApiReducer,
    market: marketApiReducer,
    monitor: monitorApiReducer,
    portfolio: portfolioApiReducer,
    ports: portsApiReducer,
    project: projectApiReducer,
    spotifind: spotifindApiReducer,
    tradingArea: tradingAreaApiReducer,
    vessels: vesselApiReducer,
  }),
  authentication: authenticationReducer,
  charterCalculation: charterCalculationReducer,
  company: companyReducer,
  contacts: contactsReducer,
  contrib: contribReducer,
  fixtureData: fixtureDataReducer,
  map: mapReducer,
  mapDetails: mapDetailsReducer,
  market: marketReducer,
  chat: chatReducer,
  marketFilters: marketFiltersReducer,
  modal: modalReducer,
  monitorBackend: monitorBackendReducer,
  portfolio: portfolioReducer,
  portfolioFilters: portfolioFiltersReducer,
  ports: portsReducer,
  portsBackend: portsBackendReducer,
  portBackendDetails: portBackendDetailsReducer,
  preFill: preFillReducer,
  projects: projectsReducer,
  search: searchReducer,
  sidebar: sidebarReducer,
  spotifind: spotifindReducer,
  subMarkets: subMarketReducer,
  unknownLocation: unknownLocationReducer,
  user: userReducer,
  vesselDatabase: vesselDatabaseReducer,
  vesselDetails: vesselDetailsReducer,
  weather: weatherReducer,
  gridClipboard: gridClipboardReducer,
});

// Make store creation accessible to tests.
export const createReduxStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      // Disable immutableCheck because it is so slow on our large state that it issues warnings in the console.
      getDefaultMiddleware({serializableCheck: false, immutableCheck: false}).concat(apiMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
  });

// The type of a Redux store instance
export type ReduxStore = ReturnType<typeof createReduxStore>;

// The shape of the full Redux state.
export type RootState = ReturnType<typeof rootReducer>;

// Create the store
export const store: ReduxStore = createReduxStore();

export type GetState = typeof store.getState;
