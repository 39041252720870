import {MapSwitchesKey} from '../MapDetails/utils/types';
import {MapControlButton} from '../MapContext/Types';

export const SWITCHES: Record<string, MapSwitchesKey> = {
  M_VESSEL: MapControlButton.VESSELS,
  M_CARGO: MapControlButton.CARGOES,
  M_PORT: MapControlButton.PORTS,
  M_LAYER: MapControlButton.LAYERS,
  VESSEL_ON: 'vessel_on',
  VESSEL_MARKET: 'vessel_market',
  VESSEL_PORTFOLIO: 'vessel_portfolio',
  VESSEL_PORTFOLIO_SUB: 'vessel_portfolio_sub',
  VESSEL_AIS: 'vessel_ais',
  VESSEL_AIS_SUB: 'vessel_ais_sub',
  CARGO_ON: 'cargo_on',
  CARGO_MARKET: 'cargo_market',
  CARGO_PORTFOLIO: 'cargo_portfolio',
  CARGO_PORTFOLIO_SUB: 'cargo_portfolio_sub',
  CARGO_COMMODITIES: 'cargo_commodities',
  CARGO_COMMODITIES_SUB: 'cargo_commodities_sub',
  CARGO_CONTRACT_TYPE: 'cargo_contract_type',
  CARGO_CONTRACT_TYPE_SUB: 'cargo_contract_type_sub',
  LAYER_TRADING_AREA: 'layer_trading_area',
  LAYER_SATELLITE: 'layer_satellite',
  LAYER_PIRACY: 'layer_piracy',
  LAYER_ECA: 'layer_eca',
  TRADING_AREA_NAMES: 'trading_area_names',
};
