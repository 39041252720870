import {PortfolioType} from '../../../redux/Portfolio';
import {TabList} from '../../TabList/TabList';
import {FC} from 'react';
import {TabType} from '../../TabList/Tab';
import {assert} from '../../../utils/assert';
import {PortfolioGroupListDataItems} from '../../../api/symfony/generated';

interface Props {
  type: PortfolioType;
}

export const ArchiveSubPortfolioList: FC<Props> = props => {
  assert(props.type === 'cargo' || props.type === 'vessel', props.type);
  const allItemsLabel = getAllItemsLabel(props.type);

  return (
    <TabList<PortfolioGroupListDataItems>
      tabs={[]}
      activeTab={'all'}
      allItemsLabel={allItemsLabel}
      createModalTitle="never"
      createTabEnabled={false}
      createModalButtonLabel="never"
      createTabDefaultName="never"
      renameModalTitle="never"
      renameModalButtonLabel="never"
      onSelectTab={ignored}
      onSortEnd={never}
      onCreateTab={never}
      onRenameTab={never}
      onDeleteTab={never}
    />
  );
};

const getAllItemsLabel = (type: TabType) => {
  switch (type) {
    case 'cargo':
      return `All archived cargoes`;
    case 'vessel':
    default:
      return `All archived vessels`;
  }
};

const ignored = () => {};

const never = () => {
  throw new Error('Never happens');
};
