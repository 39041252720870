import React, {FC} from 'react';
import styled from 'styled-components';

interface Props {
  noPadding?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const SettingsCardBody: FC<Props> = ({children, style}) => (
  <StyledCardBody style={style}>{children}</StyledCardBody>
);

const StyledCardBody = styled.div`
  flex: 1;
  padding: 0 40px;
`;
