import {AccessorFn} from '@tanstack/react-table';
import {TODO} from '../../../utils/TODO';
import {RowValueType} from './RowValueType';

export const cargoAccessor = <T>(component: TODO, type: RowValueType): AccessorFn<T> => {
  return (cargoSource: TODO) => {
    if (typeof component === 'string' || Array.isArray(component)) {
      return component;
    }
    switch (type) {
      case 'market':
        return component(cargoSource.cargo);
      case 'search':
        return component(cargoSource._source);
      default:
        return component(cargoSource);
    }
  };
};
