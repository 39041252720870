import Flag from '../../atoms/Flags';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SingleValue = ({innerRef, innerProps, children, data: {countryObject, searchTitle}}: $TSFixMe) => {
  return (
    <div ref={innerRef} {...innerProps} className={'stations-select-single-value'}>
      <Flag keepWidthWhenEmpty shadow={true} countryCode={countryObject && countryObject.code} />
      <span className={'stations-select-single-value__name'}>{searchTitle}</span>
      <span className={'stations-select-single-value__country'}>{countryObject && `(${countryObject.name})`}</span>
    </div>
  );
};

export default SingleValue;
