import {useEffect, FC} from 'react';
import {Col, Divider, Form, Row} from 'antd';
import {CargoInput} from '../../utils/CargoTypes';
import {VoyageConsumption, VoyageInput} from '../../VoyageInformation/VoyageTypes';
import {LegFormItems} from './LegFormItems';
import {CargoQuantityField} from './CargoQuantityField';
import {getVoyageConsumption} from '../../utils/getVoyageConsumption';
import {VesselInput} from '../../VesselInformation/VesselTypes';
import {FormValues, transformFormValuesToVoyageCalcState} from './utils/transformFormValuesToVoyageCalcState';
import {FullWidthButton, Headline} from '../utils/components';
import styled from 'styled-components';
import {addAlternativePortsFormVoyagePointToVoyagePoints} from '../utils/addAlternativePortsFormVoyagePointToVoyagePoints';
import produce from 'immer';
import {OfferMailCollapse} from '../../components/OfferMailCollapse';
import {useSelector} from '../../../../../redux/react-redux';
import {getIsPayingCustomer} from '../../../../../redux/selectors';

export const CargoAddition: FC<{
  voyage: VoyageInput;
  vessel: VesselInput;
  cargo: CargoInput;
  onSubmit: (value: {voyage: VoyageInput; cargo: CargoInput}) => void;
  onClose: () => void;
  onBack: () => void;
}> = ({voyage, cargo, vessel, onSubmit, onBack, onClose}) => {
  const isPayingCustomer = useSelector(getIsPayingCustomer);
  const [form] = Form.useForm();

  const defaultPortConsumption: VoyageConsumption = getVoyageConsumption({
    consumptionModes: vessel.consumptionModes,
    useCase: 'portworking',
    isEco: false,
  });

  useEffect(() => {
    const dischargeLegs = voyage.points.filter(leg => leg.types.includes('discharge'));
    const loadingLegs = voyage.points.filter(leg => leg.types.includes('loading'));

    form.setFieldsValue({
      cargo,
      voyage,
    });

    form.setFieldValue('loadingLegs', loadingLegs);
    form.setFieldValue('dischargeLegs', dischargeLegs);

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [cargo, voyage]);

  return (
    <Form<FormValues>
      onFinish={formValues => {
        const result = transformFormValuesToVoyageCalcState({
          cargo,
          voyage,
          formValues,
          defaultPortConsumption,
        });
        onSubmit(result);
        onClose();
      }}
      wrapperCol={{span: 12}}
      labelCol={{span: 12}}
      form={form}>
      <Row gutter={[16, 16]} justify={'center'}>
        <Col span={24}>
          <Headline>Edit cargo details</Headline>
        </Col>
        <Col span={3}></Col>
        <Col span={9} style={{marginRight: '-10px', marginBottom: '-17px'}}>
          <CargoQuantityField />
        </Col>
        <Col span={12}></Col>
        <Col span={24}>
          <LegFormItems
            data-cy="loadingLegs"
            onSplitAlternativePortsOnVoyagePoint={indexVoyagePoint => {
              const newVoyage = produce(voyage, draftVoyage => {
                const newVoyagePoints = addAlternativePortsFormVoyagePointToVoyagePoints({
                  voyagePoints: voyage.points,
                  indexVoyagePoint,
                });
                draftVoyage.points = newVoyagePoints;
              });
              onSubmit({voyage: newVoyage, cargo});
            }}
            voyagePoints={voyage.points}
            legsType={'loadingLegs'}
            title={'Loading'}
          />
          <Divider style={{marginTop: 8}} />
          <LegFormItems
            data-cy="dischargeLegs"
            onSplitAlternativePortsOnVoyagePoint={indexVoyagePoint => {
              const newVoyage = produce(voyage, draftVoyage => {
                const newVoyagePoints = addAlternativePortsFormVoyagePointToVoyagePoints({
                  voyagePoints: voyage.points,
                  indexVoyagePoint,
                });
                draftVoyage.points = newVoyagePoints;
              });
              onSubmit({voyage: newVoyage, cargo});
            }}
            voyagePoints={voyage.points}
            legsType={'dischargeLegs'}
            title={'Discharging'}
          />
        </Col>
        {isPayingCustomer && (
          <Col span={24}>
            <OfferMailCollapse id={cargo.id} type="cargo" />
          </Col>
        )}
        <Col span={12}>
          <FullWidthButton data-cy="backButton" onClick={onBack}>
            Back
          </FullWidthButton>
        </Col>
        <Col span={12}>
          <StyledFormItem wrapperCol={{span: 24}}>
            <FullWidthButton data-cy="finishButton" type={'primary'} htmlType={'submit'}>
              Finish
            </FullWidthButton>
          </StyledFormItem>
        </Col>
      </Row>
    </Form>
  );
};

const StyledFormItem = styled(Form.Item)`
  width: 100%;
`;
