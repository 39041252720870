import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import without from 'lodash/without';
import {Content} from './Content/Content';
import {GraphSvg} from './GraphSvg';
import {NoFilters} from './NoFilters/NoFilters';
import {LoggedFilters} from '../../../../hooks/useGetCompetitors/filterVesselsAndAddDistance/steps/StepResult';
import {FilterId, filterOptions} from '../../../../hooks/useGetCompetitors/filterOptions';

export const Schematic: FC<{
  resultsAfterFilter?: LoggedFilters;
}> = ({resultsAfterFilter}) => {
  const usedIds: FilterId[] = resultsAfterFilter?.map(item => item.id) ?? [];

  const inactiveFilterIds: FilterId[] = without<FilterId>(
    filterOptions.map(({id}) => id),
    ...usedIds
  );

  if (!resultsAfterFilter) {
    return <NoFilters />;
  }

  return (
    <div>
      <SvgWrapper inactiveFilterIds={inactiveFilterIds}>
        <GraphSvg />
      </SvgWrapper>
      <Content resultsAfterFilter={resultsAfterFilter} />
    </div>
  );
};

interface SvgWrapperProps {
  inactiveFilterIds: FilterId[];
  children: React.ReactNode;
}

const SvgWrapper: FC<SvgWrapperProps> = styled.div<SvgWrapperProps>`
  text-align: center;
  z-index: 100;
  position: absolute;
  width: 400px;
  margin-left: calc(50% - 200px);
  ${props => {
    const rules = props.inactiveFilterIds
      .map(id => {
        return css`
          #${id} {
            filter: grayscale(1);
          }
        `.join('');
      })
      .join('\n');
    return rules;
  }}
`;
