import {FC} from 'react';
import ReactPaginate from 'react-paginate';
import Icon from '../../../atoms/Icon';
import {numberFormat} from '../../../utils/formatter';
import {isSm} from '../mediaQuery';
import './style.scss';

interface PaginationProps {
  pages: number;
  page: number;
  totalCount: number;
  showPageSizeOptions?: boolean;
  pageSize: number;
  pageSizeOptions?: number[];
  isZeroBased?: boolean;
  showJumper?: boolean;
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  pages,
  page,
  totalCount,
  showPageSizeOptions,
  pageSize,
  pageSizeOptions,
  isZeroBased,
  showJumper,
  onPageChange,
  onPageSizeChange,
}) => {
  const pageRangeDisplayed = isSm() ? 2 : 5;
  const marginPagesDisplayed = isSm() ? 0 : 1;
  const fromUntilPage = isZeroBased ? page + 1 : page;
  const from = (fromUntilPage - 1) * pageSize + 1;
  const until = Math.min(fromUntilPage * pageSize, totalCount);

  const getSafePage = (newPage: number) => {
    if (isNaN(newPage)) {
      newPage = page;
    }
    return Math.min(Math.max(newPage, isZeroBased ? 0 : 1), pages + (isZeroBased ? -1 : 0));
  };

  const changePage = (newPage: number) => {
    newPage = getSafePage(newPage);
    if (page !== newPage) {
      onPageChange(newPage);
    }
  };

  if (totalCount === 0) {
    return null;
  }

  return (
    <div className="pagination">
      <div className="pagination__entries">
        {numberFormat(from)}-{numberFormat(until)} of {numberFormat(totalCount)}{' '}
      </div>
      <div className="pagination__page-selector">
        {pages > 1 && (
          <ReactPaginate
            pageCount={pages}
            pageRangeDisplayed={pageRangeDisplayed}
            marginPagesDisplayed={marginPagesDisplayed}
            forcePage={page + (isZeroBased ? 0 : -1)}
            previousLabel={<Icon type="angle-left" />}
            nextLabel={<Icon type="angle-right" />}
            disableInitialCallback={true}
            onPageChange={({selected}) => {
              const newPage = selected + (isZeroBased ? 0 : 1);
              changePage(newPage);
            }}
            pageClassName={'page-selector__page'}
            breakClassName={'page-selector__page page-selector__page--break'}
            pageLinkClassName={'page-selector__link'}
            activeClassName={'page-selector__page--active'}
            activeLinkClassName={'page-selector__link--active'}
            previousClassName={'page-selector__page page-selector__page--prev'}
            nextClassName={'page-selector__page page-selector__page--next'}
            previousLinkClassName={'page-selector__link page-selector__link--prev'}
            nextLinkClassName={'page-selector__link page-selector__link--next'}
            disabledClassName={'page-selector__page--disabled'}
            containerClassName={'page-selector'}
          />
        )}
      </div>
      {(showPageSizeOptions || showJumper) && (
        <div className="pagination__pagesize-wrapper">
          {showPageSizeOptions && (
            <div className="pagination__pagesize-select">
              <select onChange={e => onPageSizeChange(Number(e.target.value))} value={pageSize}>
                {pageSizeOptions?.map((option: number, i: number) => (
                  <option key={i} value={option}>
                    Rows per page: {option}
                  </option>
                ))}
              </select>
              <Icon type="angle-down" />
            </div>
          )}
          {showJumper && (
            <div className="pagination__pagesize-select">
              <select onChange={e => changePage(Number(e.target.value))} value={page}>
                {[...Array(pages)].map((_, i) => (
                  <option key={i} value={i + 1}>
                    Jump to {i + (isZeroBased ? 2 : 1)}
                  </option>
                ))}
              </select>
              <Icon type="angle-down" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
