import ngeohash from 'ngeohash';
import {AisVessel} from '../../api/symfony/generated/models';

export const defaultPrecision = 8;

export interface AisVesselWithGeohash extends AisVessel {
  geohash: string;
}

export const prepareVessels = (
  vessel: AisVessel,
  options: {
    precision?: number;
    reverseCoordinates?: boolean;
  }
): AisVesselWithGeohash => {
  let coordinates = [...vessel.coordinates!];
  if (options.reverseCoordinates) {
    coordinates = coordinates.reverse();
  }

  return {
    ...vessel,
    geohash: ngeohash.encode(coordinates[0], coordinates[1], options.precision ?? defaultPrecision),
  };
};
