/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ChokepointCongestionSummaryReport
 */
export interface ChokepointCongestionSummaryReport {
  /**
   *
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  normalVisitDuration: number;
  /**
   *
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  maximumVisitDuration: number;
  /**
   *
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  normalPassedVisitsPerDay: number;
  /**
   *
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  minimumPassedVisitsPerDay: number;
  /**
   *
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  medianVisitDurationLast40Vessels?: number;
  /**
   *
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  passedVisitsLast48h: number;
  /**
   *
   * @type {boolean}
   * @memberof ChokepointCongestionSummaryReport
   */
  isCongested: boolean;
  /**
   * Percentage of vessels that are stuck in the passage out of the vessels that are currently in the passage.
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  percentageVesselsStuckInPassage: number;
  /**
   * Number of vessels that are currently in the passage.
   * @type {number}
   * @memberof ChokepointCongestionSummaryReport
   */
  vesselsInPassage: number;
}

export function ChokepointCongestionSummaryReportFromJSON(json: any): ChokepointCongestionSummaryReport {
  return ChokepointCongestionSummaryReportFromJSONTyped(json, false);
}

export function ChokepointCongestionSummaryReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChokepointCongestionSummaryReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    normalVisitDuration: json['normalVisitDuration'],
    maximumVisitDuration: json['maximumVisitDuration'],
    normalPassedVisitsPerDay: json['normalPassedVisitsPerDay'],
    minimumPassedVisitsPerDay: json['minimumPassedVisitsPerDay'],
    medianVisitDurationLast40Vessels: !exists(json, 'medianVisitDurationLast40Vessels')
      ? undefined
      : json['medianVisitDurationLast40Vessels'],
    passedVisitsLast48h: json['passedVisitsLast48h'],
    isCongested: json['isCongested'],
    percentageVesselsStuckInPassage: json['percentageVesselsStuckInPassage'],
    vesselsInPassage: json['vesselsInPassage'],
  };
}

export function ChokepointCongestionSummaryReportToJSON(value?: ChokepointCongestionSummaryReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    normalVisitDuration: value.normalVisitDuration,
    maximumVisitDuration: value.maximumVisitDuration,
    normalPassedVisitsPerDay: value.normalPassedVisitsPerDay,
    minimumPassedVisitsPerDay: value.minimumPassedVisitsPerDay,
    medianVisitDurationLast40Vessels: value.medianVisitDurationLast40Vessels,
    passedVisitsLast48h: value.passedVisitsLast48h,
    isCongested: value.isCongested,
    percentageVesselsStuckInPassage: value.percentageVesselsStuckInPassage,
    vesselsInPassage: value.vesselsInPassage,
  };
}
