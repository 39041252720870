import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError} from '../../../../api/utils/ApiError';
import {annotationApi} from '../../../../api/annotations/annotationsApi';
import {NewAnnotationRequest} from '../../../../api/annotations/generated';

export const useCreateAnnotationMutation = (options: UseMutationOptions<void, ApiError, NewAnnotationRequest>) => {
  const createAnnotation = async (newAnnotation: NewAnnotationRequest) => {
    return annotationApi.createAnnotation({
      newAnnotationRequest: newAnnotation,
    });
  };
  return useMutation({
    mutationFn: createAnnotation,
    ...options,
  });
};
