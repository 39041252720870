import {ReactNode, useState} from 'react';
import {useDispatch} from 'react-redux';
import NoResults from '../../market/EmptyState/NoResults';
import {ModalActions} from '../../../redux/Modal';
import FixtureService from '../../../redux/ApiService/FixtureService';
import styled from 'styled-components';
import {Fixture} from './fixtureType';
import {getColumnsForCommercialHistory} from './getColumnsForCommercialHistory';
import {getColumnsForSalesHistory} from './getColumnsForSalesHistory';
import {CustomColumnDef, DataGrid, DataGridProps} from '../../../components/DataGrid/DataGrid';
import {useLocalStorage} from '../../../utils/useLocalStorage';
import ManageFixtureModal, {ManageFixtureProps} from '../../../components/Modal/ManageFixture/ManageFixtureModal';

type Props = Omit<DataGridProps<Fixture>, 'columns'> & {
  selectedFixtureId?: number;
  data: Fixture[];
  id?: string;
  tabKey: 'commercialHistory' | 'salesHistory';
  onFixtureChanged: () => void;
};

export const VesselFixturesGrid = (props: Props) => {
  const dispatch = useDispatch();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const deleteFixture = (id: number) => dispatch(FixtureService.deleteFixture(id));
  const cookiePageKey = `grid_page_size-vesseldetails-fixtures_${props.tabKey}`;
  const [pageSize, setPageSize] = useLocalStorage(cookiePageKey, 10);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const getEmptyState = () => {
    if (props.data.length) {
      return null;
    }
    return <NoResults type={'fixtures'} />;
  };

  const {data, selectedFixtureId, onFixtureChanged} = props;

  const gridColumns = (props.tabKey === 'salesHistory' ? getColumnsForSalesHistory : getColumnsForCommercialHistory)({
    showModal: showModal,
    onFixtureChanged,
    deleteFixture,
    showManageFixtureModal: (props: ManageFixtureProps) => {
      return dispatch(ModalActions.show(<ManageFixtureModal {...props} />));
    },
    selectedFixtureId,
  });

  return (
    <StyledDataGrid
      {...props}
      className={'datagrid-vesseldetails-fixtures'}
      data={data}
      columns={gridColumns as CustomColumnDef<Fixture>[]}
      totalCount={data.length}
      page={pageIndex}
      pageSize={pageSize}
      zeroBasedPageIndex
      TbodyComponent={getEmptyState()}
      responsive={true}
      noHoverStatePointer={true}
      sorted={[{id: 'laycanFrom', desc: true}]}
      manualSorting={false}
      onPageChange={(page: number) => {
        setPageIndex(page);
      }}
      onSortedChange={() => {
        setPageIndex(0);
      }}
      onPageSizeChange={(pageSize: number) => {
        setPageIndex(0);
        setPageSize(pageSize);
      }}
    />
  );
};

export const StyledDataGrid = styled<typeof DataGrid<Fixture>>(DataGrid)`
  .rt-tr {
    max-height: 68px;
  }
`;

export default VesselFixturesGrid;
