/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FeaturePermission {
  DownloadEmails = 'DOWNLOAD_EMAILS',
  ManageCompanies = 'MANAGE_COMPANIES',
  ManageNews = 'MANAGE_NEWS',
  ViewSentinelData = 'VIEW_SENTINEL_DATA',
  ManageOwnCompany = 'MANAGE_OWN_COMPANY',
  ManagePorts = 'MANAGE_PORTS',
  ManageTradingAreas = 'MANAGE_TRADING_AREAS',
  ManageUsers = 'MANAGE_USERS',
  ManageVessels = 'MANAGE_VESSELS',
  ViewEmailMonitor = 'VIEW_EMAIL_MONITOR',
  ManageContracts = 'MANAGE_CONTRACTS',
  ViewFullMarketLiveFeed = 'VIEW_FULL_MARKET_LIVE_FEED',
  ViewMapWithoutWatermark = 'VIEW_MAP_WITHOUT_WATERMARK',
  ExportFromMyFleet = 'EXPORT_FROM_MY_FLEET',
  ExportFromFixtures = 'EXPORT_FROM_FIXTURES',
  AccessTimeCharterCalculation = 'ACCESS_TIME_CHARTER_CALCULATION',
  AccessPlatform = 'ACCESS_PLATFORM',
  AccessNegotiation = 'ACCESS_NEGOTIATION',
  CreateNegotiation = 'CREATE_NEGOTIATION',
  ListNegotiations = 'LIST_NEGOTIATIONS',
  VoyageTimelineExport = 'VOYAGE_TIMELINE_EXPORT',
  AccessFullVesselDb = 'ACCESS_FULL_VESSEL_DB',
  AccessPorts = 'ACCESS_PORTS',
  AccessPortCongestion = 'ACCESS_PORT_CONGESTION',
  AccessMyFleet = 'ACCESS_MY_FLEET',
  AccessMyCargoes = 'ACCESS_MY_CARGOES',
  AccessProjects = 'ACCESS_PROJECTS',
  AccessDistanceCalculation = 'ACCESS_DISTANCE_CALCULATION',
  AccessVoyageCalculation = 'ACCESS_VOYAGE_CALCULATION',
  AccessSpotifind = 'ACCESS_SPOTIFIND',
  AccessCircularStats = 'ACCESS_CIRCULAR_STATS',
  AccessCompetitorAnalysis = 'ACCESS_COMPETITOR_ANALYSIS',
  AccessCongestionAnalysis = 'ACCESS_CONGESTION_ANALYSIS',
  AccessTiedUpVessels = 'ACCESS_TIED_UP_VESSELS',
  PaidPlan = 'PAID_PLAN',
  PromotionPhase = 'PROMOTION_PHASE',
}

export function FeaturePermissionFromJSON(json: any): FeaturePermission {
  return FeaturePermissionFromJSONTyped(json, false);
}

export function FeaturePermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeaturePermission {
  return json as FeaturePermission;
}

export function FeaturePermissionToJSON(value?: FeaturePermission | null): any {
  return value as any;
}
