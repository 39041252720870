import React, {FC} from 'react';

export const Definitions: FC<{children: React.ReactNode}> = props => {
  return (
    <div style={{overflowX: 'auto', maxWidth: '100%'}}>
      <div style={{width: 'max-content', minWidth: '100%', padding: '0 8px'}}>
        <table style={{width: '100%'}}>{props.children}</table>
      </div>
    </div>
  );
};
