/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Attachment
 */
export interface Attachment {
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  url: string;
  /**
   *
   * @type {Date}
   * @memberof Attachment
   */
  created: Date;
}

export function AttachmentFromJSON(json: any): Attachment {
  return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    url: json['url'],
    created: new Date(json['created']),
  };
}

export function AttachmentToJSON(value?: Attachment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    url: value.url,
    created: value.created.toISOString(),
  };
}
