import {useQuery} from '@tanstack/react-query';
import {UseQueryOptions} from '../api/utils/ReactQuery';
import {vesselDBVesselsApi} from '../api/node-backend/nodeBackendApis';
import {SeaboVessel} from '../api/node-backend/generated';
import {ApiError} from '../api/utils/ApiError';
import {wrapperForOnSuccessAndOnError} from './utils/wrapperForOnSuccessAndOnError';

export const getVesselByImo = async (imo: number): Promise<SeaboVessel> => {
  const vessels = await vesselDBVesselsApi.listVessels({
    listVesselsRequest: {
      filters: {
        imo: [imo],
      },
    },
  });

  if (vessels.totalItems === 0) {
    throw new ApiError('Vessel not found.', 404);
  }
  const vessel = vessels.items[0];
  return vessel;
};

export const useGetVesselByImoQuery = ({imo}: {imo: number}, options?: UseQueryOptions<SeaboVessel, ApiError>) =>
  useQuery({
    queryKey: ['vesselDBVesselsApi.listVessels', imo],
    queryFn: async (): Promise<SeaboVessel> => {
      return wrapperForOnSuccessAndOnError({
        queryFunction: () => getVesselByImo(imo),
        onError: error => {
          if (error instanceof ApiError && error.status === 404) {
            return null;
          }
          throw error;
        },
      });
    },
    ...options,
  });
