import {useEffect, useState, FC} from 'react';

export const FetchingForE2ETests: FC<{fetching?: boolean}> = ({fetching}) => {
  const [lastFetchEndedAt, setLastFetchEndedAt] = useState<string>('---');
  useEffect(() => {
    if (fetching) {
      return;
    }
    setLastFetchEndedAt(new Date().toISOString());
  }, [fetching]);

  return (
    <span
      data-cy="FetchingForE2ETests"
      data-fetching={fetching ? 'true' : 'false'}
      data-last-fetch-ended-at={lastFetchEndedAt}></span>
  );
};
