import {SeaboVessel, VesselTypeEnum} from '../../../../api/node-backend/generated';

export type DesignSubType =
  | 'minibulker'
  | 'handysize'
  | 'handymax'
  | 'supramax'
  | 'ultramax'
  | 'panamax'
  | 'kamsarmax'
  | 'postpanamax'
  | 'capesize';

const designSubTypes: {key: DesignSubType; minDwt: number; maxDwt: number}[] = [
  {key: 'minibulker', minDwt: 0, maxDwt: 19_999},
  {key: 'handysize', minDwt: 20_000, maxDwt: 39_999},
  {key: 'handymax', minDwt: 40_000, maxDwt: 49_999},
  {key: 'supramax', minDwt: 50_000, maxDwt: 59_999},
  {key: 'ultramax', minDwt: 60_000, maxDwt: 69_999},
  {key: 'panamax', minDwt: 70_000, maxDwt: 79_999},
  {key: 'kamsarmax', minDwt: 80_000, maxDwt: 83_999},
  {key: 'postpanamax', minDwt: 84_000, maxDwt: 109_999},
  {key: 'capesize', minDwt: 110_000, maxDwt: 500_000},
];

export const getDesignSubType = (vessel: SeaboVessel): DesignSubType | undefined => {
  if (vessel.vesselType !== VesselTypeEnum.Bulker) {
    return undefined;
  }
  if (!vessel.dwtSummer) {
    return undefined;
  }
  const designSubType = designSubTypes.find(designType => {
    return vessel.dwtSummer! >= designType.minDwt && vessel.dwtSummer! <= designType.maxDwt;
  })!.key;

  return designSubType;
};
