import React, {createContext} from 'react';
import {OutputState, InputState} from '../utils/voyageChartercalculation/voyageChartercalculation';
import {blankOutputState, blankState} from './blankState';

import {CharterCalculation} from '../../../../api/symfony/generated/models';
import {CharterCalculationState} from '../../../../redux/CharterCalculation';
import {SetVesselInput, UpdateVesselInput, useCharterCalculationState} from '../utils/useCharterCalculationState';

export interface MetaState {
  calculation: {
    id: number | null;
    version?: number;
  };
  savedLastChanges: boolean;
}

interface Context {
  state: CharterCalculationState & {outputState: OutputState};
  setInputState: (v: InputState) => void;
  setVesselInput: SetVesselInput;
  updateVesselInput: UpdateVesselInput;
  setMeta: (v: MetaState) => void;
  reset: () => void;
  loadFromCloud: (id: number) => Promise<CharterCalculation | void>;
  props: Props;
}
interface Props {
  /**
   * the ID of the calculation, when its not null, it load the calc on start
   */
  id?: number;
  projectId?: number;
  vesselId?: number;
  cargoesIds?: number[];
}

export interface VoyageCharterContextProviderProps {
  children?: React.ReactNode;
  props: Props;
}

const VoyageCharterContext = createContext<Context>({
  state: {...blankState, outputState: blankOutputState},
  setInputState: () => {},
  setMeta: () => {},
  setVesselInput: () => blankState.inputState.vessel,
  updateVesselInput: () => blankState.inputState.vessel,
  reset: () => {},
  loadFromCloud: () => {
    return Promise.resolve({} as CharterCalculation);
  },
  props: {},
});

const VoyageCharterContextProvider = (props: VoyageCharterContextProviderProps) => {
  const {
    inputState,
    outputState,
    metaState,
    setMeta,
    setVesselInput,
    updateVesselInput,
    setInputState,
    reset,
    loadFromCloud,
  } = useCharterCalculationState();

  const value: Context = {
    state: {
      inputState,
      outputState,
      metaState,
    },
    setInputState,
    setVesselInput,
    updateVesselInput,
    setMeta,
    reset,
    loadFromCloud,
    props: props.props,
  };

  return <VoyageCharterContext.Provider value={value}>{props.children}</VoyageCharterContext.Provider>;
};

export {VoyageCharterContext, VoyageCharterContextProvider};
