import {Rule} from 'rc-field-form/lib/interface';
import {TODO} from '../../../../../../utils/TODO';
import {VesselConsumptionModeUseCase} from '../../../VesselInformation/VesselTypes';

export const validateSpeed: Rule = form => {
  return {
    validator: async rule => {
      const fullField = (rule as TODO).fullField as string;
      const index = fullField.split('.')[1];

      const useCaseField = ['consumptionModes', index, 'useCase'];
      const speedField = ['consumptionModes', index, 'speed'];

      const useCase: VesselConsumptionModeUseCase = form.getFieldValue(useCaseField);
      const speed: number | undefined = form.getFieldValue(speedField);

      if (useCase === 'laden' || useCase === 'ballast') {
        if ((speed ?? 0) > 0) {
          return true;
        }
        throw new Error("Speed can't be 0 for this usecase.");
      } else {
        if ((speed ?? 0) === 0) {
          return true;
        }
        throw new Error('Speed must be 0 for this usecase.');
      }
    },
  };
};
