/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  RemoveCargoesFromSubportfolioRequest,
  RemoveCargoesFromSubportfolioRequestFromJSON,
  RemoveCargoesFromSubportfolioRequestToJSON,
} from '../models';

export interface RemoveCargoesFromSubportfolioOperationRequest {
  removeCargoesFromSubportfolioRequest: RemoveCargoesFromSubportfolioRequest;
}

/**
 *
 */
export class PortfolioCargoesApi extends runtime.BaseAPI {
  /**
   */
  async removeCargoesFromSubportfolioRaw(
    requestParameters: RemoveCargoesFromSubportfolioOperationRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.removeCargoesFromSubportfolioRequest === null ||
      requestParameters.removeCargoesFromSubportfolioRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'removeCargoesFromSubportfolioRequest',
        'Required parameter requestParameters.removeCargoesFromSubportfolioRequest was null or undefined when calling removeCargoesFromSubportfolio.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/cargoes/remove-from-subportfolio`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RemoveCargoesFromSubportfolioRequestToJSON(requestParameters.removeCargoesFromSubportfolioRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async removeCargoesFromSubportfolio(requestParameters: RemoveCargoesFromSubportfolioOperationRequest): Promise<void> {
    await this.removeCargoesFromSubportfolioRaw(requestParameters);
  }
}
