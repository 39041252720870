import {Button, ConfigProvider, Progress, Table, Tag, Tooltip} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useContext, useMemo, FC} from 'react';
import {PotentialCompetitorsFormData} from '../../../../screens/Analytics/PotentialCompetitors/types';
import NoResults from '../../../../screens/market/EmptyState/NoResults';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {VoyagePoint} from '../VoyageInformation/VoyageTypes';
import {formatWithThousandSeperator} from '../../../../utils/formatWithThousandSeperator';
import {MapPort, useMapPortsQuery} from '../../../../queries/useMapPortsQuery';
import Flag from '../../../../atoms/Flags';
import {VesselNameLabel} from '../../../VesselNameLabel';
import {DataSourceVessel} from './generateDataSourceVessel';
import {moreOrLessLabel} from '../../../../utils/moreOrLessLabel';
import {higherOrFewerLabel} from '../../../../utils/higherOrFewerLabel';
import {formatUSD} from '../../../../utils/formatCurrency';

type Props = {
  loadingPort: VoyagePoint;
  getCompetitors: (params: PotentialCompetitorsFormData) => Promise<void>;
  toDate: Dayjs | undefined;
  minDwt: undefined | number;
  maxDwt: undefined | number;
  resultVessels: undefined | DataSourceVessel[];
  isLoading: boolean;
  progress: number;
};

export const CompetitorsBody: FC<Props> = props => {
  const calculate = async () => {
    await props.getCompetitors({
      speed: 12,
      port: props.loadingPort.originalPort!,
      toDate: props.toDate,
      fromDate: undefined,
      minDwt: props.minDwt,
      maxDwt: props.maxDwt,
    });
  };
  return (
    <div>
      <ProgressBar isLoading={props.isLoading} progress={props.progress} />
      {!props.resultVessels ? (
        <ViewWithoutResult isLoading={props.isLoading} calculate={calculate} />
      ) : (
        <ViewWithResult isLoading={props.isLoading} resultVessels={props.resultVessels} />
      )}
    </div>
  );
};

const ViewWithoutResult = ({isLoading, calculate}: {isLoading: boolean; calculate: () => void}) => {
  return (
    <Button
      block
      loading={isLoading}
      onClick={() => {
        if (!isLoading) {
          calculate();
        }
      }}>
      Auto calculate TCE of competing vessels
    </Button>
  );
};

const ViewWithResult = ({isLoading, resultVessels}: {isLoading: boolean; resultVessels: DataSourceVessel[]}) => {
  const mapPortsQuery = useMapPortsQuery();
  const yourTce = useContext(VoyageCharterContext).state.outputState.cargo.tce;

  const portCodeToMap: Map<string, MapPort> = useMemo(() => {
    if (!mapPortsQuery.data) {
      return new Map();
    }

    return new Map(mapPortsQuery.data.data.items.map(port => [port.code, port]) ?? []);
  }, [mapPortsQuery.data]);

  return (
    <ConfigProvider
      renderEmpty={() => (
        <NoResults showFirstLine={false} secondLine={'No potential competitors were found.'} type={'vessels'} />
      )}>
      <Table<DataSourceVessel>
        size={'small'}
        dataSource={resultVessels}
        loading={isLoading || resultVessels === undefined}
        columns={[
          {
            title: (
              <span>
                Vessel
                <br />
                <small>&nbsp; </small>
              </span>
            ),
            width: 200,
            dataIndex: 'name',
            key: 'name',
            render: (_, vessel) => {
              const vesselWithCorrectVesselId = {
                ...vessel.seaboVessel,
                id: vessel.resultVessels.vesselId,
              };

              if (!vessel.seaboVessel) {
                return (
                  <div>
                    {vessel.resultVessels.name}
                    <br />
                    {vessel.resultVessels.imo}
                  </div>
                );
              }
              return <VesselNameLabel vessel={vesselWithCorrectVesselId} />;
            },
          },
          {
            width: 100,
            title: (
              <span>
                Size
                <br />
                <small>&nbsp;</small>
              </span>
            ),
            dataIndex: 'dwt',
            key: 'dwt',
            align: 'right',

            render: (_, vessel) => {
              return `${formatWithThousandSeperator(vessel.resultVessels.dwt)} mt`;
            },
          },
          {
            width: 100,
            title: (
              <span>
                Last port
                <br />
                <small>&nbsp;</small>
              </span>
            ),
            dataIndex: ['resultVessels', 'lastPort', 'code'],
            key: 'lastPort',
            render: lastPortCode => {
              const port = portCodeToMap.get(lastPortCode);
              if (!port) {
                return lastPortCode;
              }
              return (
                <span>
                  <Flag countryCode={port.country} /> <span style={{paddingLeft: 4}}>{port.name}</span>
                </span>
              );
            },
          },
          {
            width: 100,

            title: (
              <span>
                Next port
                <br />
                <small>&nbsp;</small>
              </span>
            ),
            key: 'nextPort',
            render: (_, vessel) => {
              const code = vessel.resultVessels.destinationPort?.code;
              if (!code) {
                return '';
              }
              const port = portCodeToMap.get(code);
              if (!port) {
                return code;
              }

              return (
                <span>
                  <Flag countryCode={port.country} /> <span style={{paddingLeft: 4}}>{port.name}</span>
                </span>
              );
            },
          },
          {
            width: 100,

            title: (
              <span>
                ETA loading port
                <br />
                <small>&nbsp; </small>
              </span>
            ),
            dataIndex: 'eta',
            key: 'eta',
            align: 'right',
            render: (_, vessel) => {
              return dayjs(vessel.resultVessels.estimatedArrivalDate).format(DateTimeFormat.DateTime);
            },
          },
          {
            width: 100,

            title: (
              <span>
                Ballast route
                <br />
                <small>to loading port</small>
                <small>compared to subject vessel</small>
              </span>
            ),
            dataIndex: 'ballastRoute',
            key: 'ballastRoute',
            render: (_, vessel) => {
              const distanceSeaVoyageInNm = Math.round(vessel.resultVessels.distanceSeaVoyageInNm);

              const diffAbs = Math.abs(Math.round(vessel.distanceDifferenceMoreThanReferenceVessel));

              const relationLabel = moreOrLessLabel(vessel.distanceDifferenceMoreThanReferenceVessel);

              return (
                <Tooltip
                  title={`The ballast route has a distance of ${distanceSeaVoyageInNm} nm. That is ${diffAbs} nm ${relationLabel} than your vessel.`}>
                  {distanceSeaVoyageInNm} nm
                  <br />
                  <small>{Math.round(vessel.distanceDifferenceMoreThanReferenceVessel)} nm</small>
                </Tooltip>
              );
            },
            align: 'right',
          },
          {
            width: 100,

            title: (
              <span>
                Extra ballast duration
                <br />
                <small>compared to subject vessel</small>
              </span>
            ),
            dataIndex: 'ballastRoute',
            key: 'ballastRoute',
            render: (_, vessel) => {
              const days = Math.abs(vessel.extraVoyageDurationMoreThanReferenceVessel).toFixed(1);
              const relationLabel = moreOrLessLabel(vessel.extraVoyageDurationMoreThanReferenceVessel);
              return (
                <Tooltip
                  title={`This vessel has ${days} days ${relationLabel} ballast route voyage duration than your vessel.`}>
                  {days} days {relationLabel}
                </Tooltip>
              );
            },
            align: 'right',
          },
          {
            width: 100,

            title: (
              <span>
                Extra waiting time
                <br />
                <small>compared to subject vessel</small>
              </span>
            ),
            dataIndex: 'waiting time',
            key: 'waiting time',
            render: (_, vessel) => {
              const days = Math.abs(vessel.extraWaitingTimeMoreThanReferenceVessel).toFixed(1);
              const relationLabel = moreOrLessLabel(vessel.extraWaitingTimeMoreThanReferenceVessel);
              return (
                <Tooltip title={`This vessel has ${days} days ${relationLabel} waiting time than your vessel.`}>
                  {days} days {relationLabel}
                </Tooltip>
              );
            },
            align: 'right',
          },
          {
            width: 100,

            title: (
              <span>
                Ballast consumption costs
                <br />
                <small>compared to subject vessel</small>
              </span>
            ),
            dataIndex: 'ballastRoute',
            key: 'ballastRoute',
            render: (_, vessel) => {
              const value = Math.abs(vessel.extraConsumptionCosts);
              const relationLabel = moreOrLessLabel(vessel.extraConsumptionCosts);

              return (
                <span>
                  {formatUSD(value)} {relationLabel}
                </span>
              );
            },
            align: 'right',
          },
          {
            width: 100,

            title: (
              <Tooltip title={'The TCE assuming the same freight idea.'}>
                TCE <br />
                <small>&nbsp;</small>
              </Tooltip>
            ),
            dataIndex: 'ballastRoute',
            key: 'ballastRoute',
            render: (_, vessel) => {
              return <Tooltip title={'The TCE assuming the same freight idea.'}>{formatUSD(vessel.tce)}</Tooltip>;
            },
            align: 'right',
          },
          {
            width: 100,

            title: (
              <span>
                Summary
                <br />
                <small>&nbsp;</small>
              </span>
            ),
            dataIndex: 'result',
            key: 'result',
            render: (_, vessel) => {
              const youHaveABetterTCE = vessel.tce < yourTce;
              const moreTCE = yourTce - vessel.tce;

              const relationLabel = higherOrFewerLabel(moreTCE);

              return (
                <Tooltip
                  title={`You have a ${formatUSD(Math.abs(moreTCE))} ${relationLabel} TCE than your competitor.`}>
                  <Tag color={youHaveABetterTCE ? 'green' : 'red'}>{formatUSD(moreTCE)}</Tag>
                </Tooltip>
              );
            },
            align: 'right',
          },
        ]}
      />
    </ConfigProvider>
  );
};

const ProgressBar = (props: {isLoading: boolean; progress: number}) => {
  return (
    <div
      style={{
        marginTop: -18,
        height: 18,
      }}>
      {props.isLoading ? <Progress percent={props.progress} showInfo={false} /> : null}{' '}
    </div>
  );
};
