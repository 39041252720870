import {HeaderRow} from '../../Definitions/HeaderRow';
import {Header} from '../../Definitions/Header';
import {Tooltip} from 'antd';

export const PointHeaderRow = ({
  avgDurationLast10VesselsInMinutes,
}: {
  avgDurationLast10VesselsInMinutes: number | undefined;
}) => {
  return (
    <HeaderRow>
      <Header width={160}>Consumption</Header>
      <Header width={160}>Aux consumption</Header>
      <Header width={140}>Quantity</Header>
      <Header width={230}>Terms</Header>
      <Header width={120}>Turn time</Header>
      <Header width={120}>
        <ExtraTimeColumn avgDurationLast10VesselsInMinutes={avgDurationLast10VesselsInMinutes} />
      </Header>
      <Header width={120}>Port DA</Header>
      <Header calculated width={120}>
        Duration
      </Header>
      <Header calculated width={120}>
        CO2 emissions
      </Header>
      <Header calculated width={120}>
        Start date
      </Header>
      <Header calculated width={120}>
        End date
      </Header>
    </HeaderRow>
  );
};

const ExtraTimeColumn = ({
  avgDurationLast10VesselsInMinutes,
}: {
  avgDurationLast10VesselsInMinutes: number | undefined;
}) => {
  if (!avgDurationLast10VesselsInMinutes) {
    return <span>Extra time</span>;
  }
  const timeInHours = Math.round(avgDurationLast10VesselsInMinutes / 60);

  return (
    <Tooltip placement={'bottom'} title={<div>Currently estimated waiting time is {timeInHours} hours.</div>}>
      Extra time
    </Tooltip>
  );
};
