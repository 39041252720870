/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AisOutage,
  AisOutageFromJSON,
  AisOutageFromJSONTyped,
  AisOutageToJSON,
  AnchorageVisit,
  AnchorageVisitFromJSON,
  AnchorageVisitFromJSONTyped,
  AnchorageVisitToJSON,
  DestinationChange,
  DestinationChangeFromJSON,
  DestinationChangeFromJSONTyped,
  DestinationChangeToJSON,
  DraftChange,
  DraftChangeFromJSON,
  DraftChangeFromJSONTyped,
  DraftChangeToJSON,
  Drift,
  DriftFromJSON,
  DriftFromJSONTyped,
  DriftToJSON,
  DryDockVisit,
  DryDockVisitFromJSON,
  DryDockVisitFromJSONTyped,
  DryDockVisitToJSON,
  EtaChange,
  EtaChangeFromJSON,
  EtaChangeFromJSONTyped,
  EtaChangeToJSON,
  FlagChange,
  FlagChangeFromJSON,
  FlagChangeFromJSONTyped,
  FlagChangeToJSON,
  NameChange,
  NameChangeFromJSON,
  NameChangeFromJSONTyped,
  NameChangeToJSON,
  PassingPortVisit,
  PassingPortVisitFromJSON,
  PassingPortVisitFromJSONTyped,
  PassingPortVisitToJSON,
  PierVisit,
  PierVisitFromJSON,
  PierVisitFromJSONTyped,
  PierVisitToJSON,
  PortVisit,
  PortVisitFromJSON,
  PortVisitFromJSONTyped,
  PortVisitToJSON,
  VoyageTimelineRoute,
  VoyageTimelineRouteFromJSON,
  VoyageTimelineRouteFromJSONTyped,
  VoyageTimelineRouteToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselVoyageTimelineResponse
 */
export interface VesselVoyageTimelineResponse {
  /**
   *
   * @type {Array<Drift>}
   * @memberof VesselVoyageTimelineResponse
   */
  drifts: Array<Drift>;
  /**
   *
   * @type {Array<VoyageTimelineRoute>}
   * @memberof VesselVoyageTimelineResponse
   */
  routes: Array<VoyageTimelineRoute>;
  /**
   *
   * @type {Array<AisOutage>}
   * @memberof VesselVoyageTimelineResponse
   */
  aisOutages: Array<AisOutage>;
  /**
   *
   * @type {Array<EtaChange>}
   * @memberof VesselVoyageTimelineResponse
   */
  etaChanges: Array<EtaChange>;
  /**
   *
   * @type {Array<DraftChange>}
   * @memberof VesselVoyageTimelineResponse
   */
  draftChanges: Array<DraftChange>;
  /**
   *
   * @type {Array<DestinationChange>}
   * @memberof VesselVoyageTimelineResponse
   */
  destinationChanges: Array<DestinationChange>;
  /**
   *
   * @type {Array<NameChange>}
   * @memberof VesselVoyageTimelineResponse
   */
  nameChanges: Array<NameChange>;
  /**
   *
   * @type {Array<FlagChange>}
   * @memberof VesselVoyageTimelineResponse
   */
  flagChanges: Array<FlagChange>;
  /**
   *
   * @type {Array<PortVisit>}
   * @memberof VesselVoyageTimelineResponse
   */
  portVisits: Array<PortVisit>;
  /**
   *
   * @type {Array<PassingPortVisit>}
   * @memberof VesselVoyageTimelineResponse
   */
  passingPortVisits: Array<PassingPortVisit>;
  /**
   *
   * @type {Array<PierVisit>}
   * @memberof VesselVoyageTimelineResponse
   */
  pierVisits: Array<PierVisit>;
  /**
   *
   * @type {Array<AnchorageVisit>}
   * @memberof VesselVoyageTimelineResponse
   */
  anchorageVisits: Array<AnchorageVisit>;
  /**
   *
   * @type {Array<DryDockVisit>}
   * @memberof VesselVoyageTimelineResponse
   */
  dryDockVisits: Array<DryDockVisit>;
}

export function VesselVoyageTimelineResponseFromJSON(json: any): VesselVoyageTimelineResponse {
  return VesselVoyageTimelineResponseFromJSONTyped(json, false);
}

export function VesselVoyageTimelineResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselVoyageTimelineResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    drifts: (json['drifts'] as Array<any>).map(DriftFromJSON),
    routes: (json['routes'] as Array<any>).map(VoyageTimelineRouteFromJSON),
    aisOutages: (json['aisOutages'] as Array<any>).map(AisOutageFromJSON),
    etaChanges: (json['etaChanges'] as Array<any>).map(EtaChangeFromJSON),
    draftChanges: (json['draftChanges'] as Array<any>).map(DraftChangeFromJSON),
    destinationChanges: (json['destinationChanges'] as Array<any>).map(DestinationChangeFromJSON),
    nameChanges: (json['nameChanges'] as Array<any>).map(NameChangeFromJSON),
    flagChanges: (json['flagChanges'] as Array<any>).map(FlagChangeFromJSON),
    portVisits: (json['portVisits'] as Array<any>).map(PortVisitFromJSON),
    passingPortVisits: (json['passingPortVisits'] as Array<any>).map(PassingPortVisitFromJSON),
    pierVisits: (json['pierVisits'] as Array<any>).map(PierVisitFromJSON),
    anchorageVisits: (json['anchorageVisits'] as Array<any>).map(AnchorageVisitFromJSON),
    dryDockVisits: (json['dryDockVisits'] as Array<any>).map(DryDockVisitFromJSON),
  };
}

export function VesselVoyageTimelineResponseToJSON(value?: VesselVoyageTimelineResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    drifts: (value.drifts as Array<any>).map(DriftToJSON),
    routes: (value.routes as Array<any>).map(VoyageTimelineRouteToJSON),
    aisOutages: (value.aisOutages as Array<any>).map(AisOutageToJSON),
    etaChanges: (value.etaChanges as Array<any>).map(EtaChangeToJSON),
    draftChanges: (value.draftChanges as Array<any>).map(DraftChangeToJSON),
    destinationChanges: (value.destinationChanges as Array<any>).map(DestinationChangeToJSON),
    nameChanges: (value.nameChanges as Array<any>).map(NameChangeToJSON),
    flagChanges: (value.flagChanges as Array<any>).map(FlagChangeToJSON),
    portVisits: (value.portVisits as Array<any>).map(PortVisitToJSON),
    passingPortVisits: (value.passingPortVisits as Array<any>).map(PassingPortVisitToJSON),
    pierVisits: (value.pierVisits as Array<any>).map(PierVisitToJSON),
    anchorageVisits: (value.anchorageVisits as Array<any>).map(AnchorageVisitToJSON),
    dryDockVisits: (value.dryDockVisits as Array<any>).map(DryDockVisitToJSON),
  };
}
