import Tooltip from '../../../atoms/Tooltip';

function GridTooltip({disabled, title, children}: $TSFixMe) {
  return (
    <Tooltip
      title={title}
      disabled={disabled}
      position="top"
      size={'small'}
      arrow={false}
      animateFill={false}
      trigger={'mouseenter focus'}
      theme={'dark'}
      delay={0}>
      {children}
    </Tooltip>
  );
}

export default GridTooltip;
