import {FilterProviderState} from '../components/FilterProvider/FilterProviderState';
import {assert} from '../utils/assert';
import {FilterItem} from '../api/symfony/generated';
import {
  SpotifindDatabaseFilter,
  SpotifindFilterBranchDefinitions,
} from '../components/FilterProvider/Filters/Spotifind/SpotifindFilterBranchDefinitions';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ViewBounds} from './ViewBounds';
import {getLocalStorage} from '../utils/useLocalStorage';

export const SpotifindPageSizeCookie = 'spotifind_page_size';

export type GridAndMapLayoutType = 'oneColumn' | 'twoColumn';

interface GridState {
  page: number;
  pageSize: number;
  sorted: SortColumn[];
}

export interface SpotifindState {
  syncMapWithGrid: boolean;
  mapBoundingBox: ViewBounds | null;
  userSelectedLayout: GridAndMapLayoutType;

  filterState: {
    filter: SpotifindDatabaseFilter | null;
    filterId: number | null;
    filterProviderState: FilterProviderState<typeof SpotifindFilterBranchDefinitions> | null;
    filterChanged: boolean;
  };
  gridState: GridState;
}

interface SortColumn {
  id: string;
  desc: boolean;
}

const initialState: SpotifindState = {
  syncMapWithGrid: false,
  mapBoundingBox: null,
  userSelectedLayout: 'oneColumn',

  filterState: {
    filter: null,
    filterId: null,
    filterProviderState: null,
    filterChanged: false,
  },
  gridState: {
    page: 1,
    pageSize: getLocalStorage(SpotifindPageSizeCookie, 50),
    sorted: [{id: 'name', desc: false}],
  },
};

const SpotifindSlice = createSlice({
  name: 'Spotifind',
  initialState,
  reducers: {
    setSyncMapWithGrid(state, action: PayloadAction<boolean>) {
      state.syncMapWithGrid = action.payload;
      // In this case we always want to reset page.
      state.gridState.page = 1;
    },
    setMapBoundingBox(state, action: PayloadAction<ViewBounds | null>) {
      state.mapBoundingBox = action.payload;
    },
    setUserSelectedLayout(state, action: PayloadAction<GridAndMapLayoutType>) {
      state.userSelectedLayout = action.payload;
    },
    loadFiltersSuccess(state, action: PayloadAction<FilterItem[]>) {
      assert(Array.isArray(action.payload));
      const filters: FilterItem[] = action.payload;
      const firstFilter = filters[0];
      const filterSettings = firstFilter.filterSettings as SpotifindDatabaseFilter | null;
      state.filterState.filter = filterSettings;
      state.filterState.filterId = firstFilter.id;
    },

    setFilterSettings(state, action: PayloadAction<SpotifindDatabaseFilter>) {
      state.filterState.filter = action.payload;
      state.filterState.filterChanged = true;
      state.gridState.page = 1;
    },

    setFilterProvider(state, action: PayloadAction<FilterProviderState<typeof SpotifindFilterBranchDefinitions>>) {
      state.filterState.filterProviderState = action.payload;
    },

    updateGridState(state, action: PayloadAction<Partial<GridState>>) {
      state.gridState = {
        ...state.gridState,
        ...action.payload,
      };
    },
  },
});

export const SpotifindActions = SpotifindSlice.actions;
export const spotifindReducer = SpotifindSlice.reducer;
