import {GetPortfolioVesselsRequest, SortDirection} from '../../../../api/symfony/generated';
import {QueryClient, useQuery} from '@tanstack/react-query';
import {ApiError} from '../../../../api/utils/ApiError';
import {PortfolioVessel, SortOrder, SubPortfolioId} from '../../../../redux/Portfolio';
import {portfolioVesselApi} from '../../../../api/symfony/symfonyApi';
import {getFiltersForNewFilterRequest} from './getFiltersForNewFilterRequest';
import {PortfolioVesselDatabaseFilter} from '../../../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';

export interface GetPortfolioVesselsQueryParams {
  subPortfolioId?: SubPortfolioId;
  pageNumber: number;
  pageSize?: number;
  sortField?: string;
  filters: PortfolioVesselDatabaseFilter;
  sortOrder: SortOrder;
  isArchive?: boolean;
}

export interface VesselCollection {
  totalItems: number;
  items: PortfolioVessel[];
}

export interface VesselCollectionWrapper {
  data: VesselCollection;
}

const GetPortfolioVesselsQueryKey = 'portfolioVesselApi.getPortfolioVessels';

export const useGetPortfolioVesselsQuery = (
  params: GetPortfolioVesselsQueryParams,
  options?: UseQueryOptions<VesselCollectionWrapper, ApiError>
) => {
  return useQuery<VesselCollectionWrapper, ApiError>({
    queryKey: [GetPortfolioVesselsQueryKey, params],
    queryFn: () => {
      const sortOrder = params.sortOrder as SortDirection;
      const group = params.subPortfolioId === 'all' ? undefined : params.subPortfolioId;

      const request: GetPortfolioVesselsRequest = {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        sortField: params.sortField,
        sortOrder,
        group,
        archive: params.isArchive,
        ...getFiltersForNewFilterRequest({filters: params.filters as PortfolioVesselDatabaseFilter}),
      };

      return portfolioVesselApi.getPortfolioVessels(request) as Promise<VesselCollectionWrapper>;
    },
    ...options,
  });
};

export const invalidateGetPortfolioVesselsQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [GetPortfolioVesselsQueryKey],
  });
};
