import {TourStepProps} from 'antd';
import {ReactNode} from 'react';

export enum TourId {
  Dashboard = 1,
  Market = 2,
  VesselDB = 3,
  Ports = 4,
  MyFleet = 5,
  MyCargoes = 6,
  Projects = 7,
  Fixtures = 8,
  DistanceCalc = 9,
  VoyageCalc = 10,
  Analytics = 11,
  Negotiation = 12,
  Competitors = 13,
}

export interface TourDefinition {
  id: TourId;
  steps: TourStepDefinition[];
}

export type TourStepMedia = TourStepMediaImage | TourStepMediaVideo;

export type TourStepMediaImage = {
  type: 'image';
  url: string;
};

export type TourStepMediaVideo = {
  type: 'video';
  url: string;
  placeholderImageUrl?: string;
  keyframes?: TourStepMediaVideoKeyframe[];
};

export type TourStepMediaVideoKeyframe = {
  timestampSeconds: number;
  text: string;
  label: string;
};

export interface TourStepDefinition {
  target: string | null; // CSS Selector
  title: string;
  content?: string | number | ReactNode;
  media?: TourStepMedia;
  pdfDownloadUrl?: string;
  placement: TourStepProps['placement'];
  isLarge?: boolean;
  onEnterCallback?: () => void;
  onExitCallback?: () => void;
}

const HELP_BUTTON_SELECTOR = '[data-testid="ShowTourButton"]';
export const getFinalStep = (tourLabel: string): TourStepDefinition => ({
  title: `You've completed the ${tourLabel} tour!`,
  content: 'You can always revisit tours by clicking the "Help" button in the top right corner of the screen.',
  target: HELP_BUTTON_SELECTOR,
  placement: 'bottomLeft',
});
