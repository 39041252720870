import useSessionStorage from 'beautiful-react-hooks/useSessionStorage';
import useInterval from 'beautiful-react-hooks/useInterval';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {AuthenticationActions} from '../../redux/Authentication';
import {useGenerateAccessTokenMutation} from './useGenerateAccessTokenMutation';
import {getAuthorizationCode} from './getAuthorizationCode';
import {generateAuthorizationCode, removeOAuthStateToken} from './generateAuthorizationCode';
import {useGenerateRefreshTokenMutation} from './useGenerateRefreshTokenMutation';
import {isCSRFTokenValid} from './isCSRFTokenValid';
import {authRefreshTokenSessionStorageKey} from './removeRefreshToken';
import {isApiErrorWithStatus} from '../../api/utils/ApiError';
import {useStrictModeUnsafeEffect} from '../../utils/useStrictModeUnsafeEffect';
import {useIsAccessTokenExpired} from './useIsAccessTokenExpired';
import useOnlineState from 'beautiful-react-hooks/useOnlineState';
import {useCallback, useEffect} from 'react';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import dayjs from 'dayjs';
import {z} from 'zod';

// The access token is valid for 10 minutes. We refresh it every 8 minutes. This gives us a 2 minute buffer.
const REFRESH_INTERVAL = 8 * 60 * 1000;

const AUTH_SOURCE_HREF_KEY = 'authSourceHref';
const ACCESS_TOKEN_REQUEST_FAILED_AT_LOCAL_STORAGE_KEY = 'accessTokenRequestFailedAt';

/**
 * This hook manages the OAuth flow to retrieve and refresh an access token.
 * It resets the browser's session storage and window location if an error occurs.
 * @returns {boolean} Returns true when the access token is present, otherwise false.
 */
export const useAccessTokenManager = (): boolean => {
  const dispatch = useDispatch();

  const [accessTokenRequestFailedAt, setAccessTokenRequestFailedAt] = useLocalStorage<number>(
    ACCESS_TOKEN_REQUEST_FAILED_AT_LOCAL_STORAGE_KEY,
    0
  );

  const authorizationCode = getAuthorizationCode();
  const [sourceHref, setSourceHref] = useSessionStorage<string | null>(AUTH_SOURCE_HREF_KEY, null);
  const [refreshToken, setRefreshToken] = useSessionStorage<string | null>(authRefreshTokenSessionStorageKey, null);
  const accessToken = useSelector(state => state.authentication.accessToken);

  const isOnline = useOnlineState();
  const isAccessTokenExpired = useIsAccessTokenExpired();

  const generateRefreshTokenMutation = useGenerateRefreshTokenMutation();
  const generateAccessTokenMutation = useGenerateAccessTokenMutation();

  /**
   * Resets the browser by removing the sourceHref, OAuth state token, refresh token and current access token from the Session Storage.
   */
  const resetBrowserWithMessage = useCallback((message: string) => {
    // eslint-disable-next-line no-console
    console.warn(message);
    setSourceHref(null);
    removeOAuthStateToken();
    setRefreshToken(null);
    dispatch(AuthenticationActions.setAccessToken(undefined));
    window.location.href = '/login';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles API errors that can occur during the token retrieval and resets the browser if necessary.
   */
  const handleTokenApiError = useCallback(
    (error: unknown) => {
      if (isApiErrorWithStatus(error, 400)) {
        resetBrowserWithMessage(`Authorization code expired: ${error.message}`);
        return;
      }
      if (isApiErrorWithStatus(error, 401)) {
        resetBrowserWithMessage(`Refresh token expired: ${error.message}`);
        return;
      }
      // If other error, we throw to the error boundary
      throw error;
    },
    [resetBrowserWithMessage]
  );

  const getRefreshToken = useCallback(async (authorizationCode: string, sourceHref: string) => {
    try {
      const newRefreshToken = await generateRefreshTokenMutation.mutateAsync({authorizationCode, sourceHref});
      if (!newRefreshToken) {
        resetBrowserWithMessage('Received incomplete refresh token data.');
        return;
      }
      setRefreshToken(newRefreshToken);
    } catch (error) {
      handleTokenApiError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccessToken = useCallback(async (refreshToken: string) => {
    try {
      const data = await generateAccessTokenMutation.mutateAsync({refreshToken});
      const {accessToken: newAccessToken, refreshToken: newRefreshToken} = data;
      if (!newAccessToken || !newRefreshToken) {
        resetBrowserWithMessage('Received incomplete access token data.');
        return;
      }
      setRefreshToken(newRefreshToken);
      dispatch(AuthenticationActions.setAccessToken(newAccessToken));
    } catch (error) {
      // Set a timestamp in local storage to prevent a render loop in case of multiple failed requests.
      setAccessTokenRequestFailedAt(Date.now());
      handleTokenApiError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the OAuth flow to retrieve a refresh and access token.
   * This function runs only once on every first render of the page in the `useStrictModeUnsafeEffect` hook below.
   * This way it checks for existing authorization code and refresh token and runs the corresponding step.
   * Following access tokens are refreshed inside the `useInterval` hook with the refresh token that is generated here.
   */
  const handleOAuthFlow = useCallback(async () => {
    const hasRefreshToken = !!refreshToken;
    const hasAuthorizationCode = !!authorizationCode;

    // Step 0 - If the access token request failed recently, we first reset the browser to avoid a redirect loop.
    if (isAccessTokenRequestFailedRecently(accessTokenRequestFailedAt)) {
      setAccessTokenRequestFailedAt(0);
      resetBrowserWithMessage('The access token request failed recently - resetting browser to avoid redirect loop.');
      return;
    }

    // Step 1 - Get authorization code (neither authorization nor refresh token is present)
    if (!hasRefreshToken && !hasAuthorizationCode) {
      setSourceHref(window.location.href);
      // Redirects to the OAuth server and back to sourceHref to run this flow again.
      generateAuthorizationCode();
      return;
    }

    // Step 2 - Get refresh token (only authorization code is present, no refresh token yet)
    if (!hasRefreshToken && hasAuthorizationCode) {
      // We check if the sourceHref is present from the URL, if not we reset the browser.
      // This can happen if the user opens link from somewhere that includes an authorization code.
      // For example: https://beta.seabo.com/dashboard?code=def5020011811044be9c783b0f...
      if (!sourceHref) {
        resetBrowserWithMessage('No sourceHref found.');
        return;
      }
      // Check if the OAuth state in the URL is equal to the Session Storage, to prevent CSRF attacks.
      if (!isCSRFTokenValid()) {
        resetBrowserWithMessage('OAuth state is not valid.');
        return;
      }
      // We get the refresh token and store it in the Session Storage.
      await getRefreshToken(authorizationCode, sourceHref);
      // When we have the refresh token, we redirect back to the sourceHref and run this flow again.
      window.location.href = sourceHref;
      return;
    }

    // Step 3 - Get access token (refresh token is present)
    if (hasRefreshToken) {
      // Since we have a refresh token, we can generate an access token.
      await getAccessToken(refreshToken);
      // We don't need the sourceHref anymore, so we remove it from the Session Storage.
      setSourceHref(null);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenRequestFailedAt, authorizationCode, refreshToken, sourceHref]);

  // We run the OAuth flow once when the component mounts.
  useStrictModeUnsafeEffect(() => {
    void handleOAuthFlow();
  });

  // We refresh the access token every 8 minutes once we have received the refresh token.
  useInterval(async () => {
    if (!refreshToken) {
      return;
    }
    await getAccessToken(refreshToken);
  }, REFRESH_INTERVAL);

  // We run an effect when the computer goes from offline to online and the access token expired.
  // If the access token is expired, we need to reset the browser.
  useEffect(() => {
    if (!isAccessTokenExpired || !isOnline) {
      return;
    }
    resetBrowserWithMessage('Access token expired. Redirecting to login page.');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccessTokenExpired, isOnline]);

  return !!accessToken;
};

const isAccessTokenRequestFailedRecently = (lastAccessTokenErrorAt: number | null): boolean => {
  if (!z.number().safeParse(lastAccessTokenErrorAt).success) {
    return false;
  }
  // Less then 10 seconds ago?
  const failedRecently = dayjs(lastAccessTokenErrorAt).isAfter(dayjs().subtract(10, 'seconds'));
  return failedRecently;
};
