/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  FilterCategory,
  FilterCategoryFromJSON,
  FilterCategoryFromJSONTyped,
  FilterCategoryToJSON,
  FilterType,
  FilterTypeFromJSON,
  FilterTypeFromJSONTyped,
  FilterTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface FilterItemWrite
 */
export interface FilterItemWrite {
  /**
   *
   * @type {object}
   * @memberof FilterItemWrite
   */
  filterSettings?: object;
  /**
   *
   * @type {string}
   * @memberof FilterItemWrite
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof FilterItemWrite
   */
  sortKey?: number;
  /**
   *
   * @type {FilterCategory}
   * @memberof FilterItemWrite
   */
  category?: FilterCategory;
  /**
   *
   * @type {FilterType}
   * @memberof FilterItemWrite
   */
  type?: FilterType;
  /**
   *
   * @type {boolean}
   * @memberof FilterItemWrite
   */
  outbox?: boolean;
}

export function FilterItemWriteFromJSON(json: any): FilterItemWrite {
  return FilterItemWriteFromJSONTyped(json, false);
}

export function FilterItemWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterItemWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    filterSettings: !exists(json, 'filterSettings') ? undefined : json['filterSettings'],
    name: !exists(json, 'name') ? undefined : json['name'],
    sortKey: !exists(json, 'sortKey') ? undefined : json['sortKey'],
    category: !exists(json, 'category') ? undefined : FilterCategoryFromJSON(json['category']),
    type: !exists(json, 'type') ? undefined : FilterTypeFromJSON(json['type']),
    outbox: !exists(json, 'outbox') ? undefined : json['outbox'],
  };
}

export function FilterItemWriteToJSON(value?: FilterItemWrite | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    filterSettings: value.filterSettings,
    name: value.name,
    sortKey: value.sortKey,
    category: FilterCategoryToJSON(value.category),
    type: FilterTypeToJSON(value.type),
    outbox: value.outbox,
  };
}
