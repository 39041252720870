import React, {FC} from 'react';
import lightHouse from './empty-states-market-filtering-no-result.png';
import './style.scss';

export const NoResults: FC<{
  type: string;
  secondLine?: string;
  showFirstLine?: boolean;
}> = ({type, secondLine, showFirstLine = true}) => {
  return (
    <div id="emptyStateContainer">
      <div className="emptyStateContainer">
        <img src={lightHouse} alt="no results" />
        <h2>No results found</h2>
        <span>
          {showFirstLine && (
            <>
              The filter(s) did not match any {type}. <br />
            </>
          )}
          {secondLine ?? 'Please use different filters for a better result.'}
        </span>
      </div>
    </div>
  );
};

export default NoResults;
