import {ReactNode} from 'react';
import styled from 'styled-components';

export const ResultBox = ({
  label,
  labelSecondary,
  value,
  valueSecondary,
  hasError,
}: {
  label: ReactNode;
  labelSecondary?: ReactNode;
  value: ReactNode;
  valueSecondary: ReactNode;
  hasError?: boolean;
}) => {
  return (
    <ResultBoxContainer data-testid={`ResultBox-${label}`} $hasError={hasError}>
      <Label>
        {label}
        {labelSecondary && <span style={{fontWeight: 'normal'}}> {labelSecondary}</span>}
      </Label>
      <Value>
        <Primary>{value}</Primary>
        <Secondary>{valueSecondary}</Secondary>
      </Value>
    </ResultBoxContainer>
  );
};

const ResultBoxContainer = styled.div<{$hasError?: boolean}>`
  flex: 1 1 auto;
  border-right: var(--border-base);
  padding: 13px 17px 19px;

  ${({$hasError}) => $hasError && 'color: var(--color-red);'}

  &:last-child {
    border-right-width: 0;
  }
`;

const Label = styled.div`
  color: var(--color-gray-2);
  font-size: var(--font-size-sm);
  line-height: 19px;
  font-weight: bold;
`;

const Value = styled.div`
  color: var(--color-gray-1);
`;

const Primary = styled.span`
  font-size: var(--font-size-lg);
  font-weight: bold;
`;

const Secondary = styled.span`
  font-size: var(--font-size-md);
  margin-left: 7px;
  margin-right: 7px;
`;
