import {Dayjs} from 'dayjs';
import {useAisVesselsQuery} from '../../queries/useAisVesselsQuery';
import {EnabledFilters} from '../../screens/Analytics/PotentialCompetitors/FilterOptionDropdown';
import {
  PotentialCompetitorsFormData,
  PreparedAisVessel,
  ResultVessel,
} from '../../screens/Analytics/PotentialCompetitors/types';
import {doFilter} from './doFilter';
import {LoggedFilters} from './filterVesselsAndAddDistance/steps/StepResult';
import {prepareVessels} from './prepareVessels';
import {useEffect, useState} from 'react';
import {notification} from '../../components/Notification';
import {Step10Result} from './filterVesselsAndAddDistance/steps/step10';
import {useQueryClient} from '@tanstack/react-query';

export type GetCompetitors = (formValues: PotentialCompetitorsFormData) => Promise<ResultVessel[]>;

type UseGetCompetitors = {
  isReady: boolean;
  getCompetitors: GetCompetitors;
  cancelRunningCalculation: () => Promise<void>;
};

/*
 * This hook is used to get potential competing vessels for a given combination of port, vessel-size and laycan.
 */
export const useGetCompetitors = ({
  currentTime,
  onChangeIsLoading,
  filterOptions,
  onProgress,
  onChangeResult,
  onChangeLogs,
}: {
  currentTime?: Dayjs;
  onChangeIsLoading: (loading: boolean) => void;
  filterOptions: EnabledFilters;
  onProgress: (progress: number) => void;
  onChangeResult: (result: ResultVessel[]) => void;
  onChangeLogs: (logs: LoggedFilters) => void;
}): UseGetCompetitors => {
  const [preparedAisVessels, setPreparedAisVessels] = useState<PreparedAisVessel[] | undefined>();

  const queryClient = useQueryClient();

  const {isSuccess, data} = useAisVesselsQuery(
    {
      format: 'extended',
      onStart: () => setPreparedAisVessels(undefined),
    },
    {
      refetchOnMount: true,
    }
  );

  const calculateCompetitors = async (formValues: PotentialCompetitorsFormData) => {
    onChangeIsLoading(true);
    const result: Step10Result = await doFilter({
      currentTime: currentTime,
      formData: formValues,
      filterOptions,
      preparedAisVessels: preparedAisVessels,
      onProgress,
    });
    onChangeResult(result.vessels);
    onChangeLogs(result.loggedFilters);
    onChangeIsLoading(false);
    notification.info({
      message: `${result.vessels?.length} potential competitors found `,
    });
    return result.vessels;
  };

  const getCompetitors = async (formValues: PotentialCompetitorsFormData) => {
    await queryClient.cancelQueries({
      queryKey: ['getCompetitors'],
    });
    return await queryClient.fetchQuery({
      queryKey: ['getCompetitors', formValues],
      queryFn: () => calculateCompetitors(formValues),
      // 60 Min,
      staleTime: 60 * (60 * 1000),
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      setPreparedAisVessels(data.map(vessel => prepareVessels(vessel, {reverseCoordinates: true})));
    }
  }, [isSuccess, data]);

  return {
    isReady: preparedAisVessels !== undefined,
    cancelRunningCalculation: async () => {
      await queryClient.cancelQueries({
        queryKey: ['getCompetitors'],
      });
    },
    getCompetitors,
  };
};
