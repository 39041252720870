import {Helmet} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';
import {PortDetailScreen} from './PortDetailScreen/PortDetailScreen';
import {PortListScreen} from './PortListScreen/PortListScreen';
import {NotFound} from '../../atoms/EmptyState/404';

export const PortScreens = () => (
  <>
    <Helmet title={'Ports'} />
    <Routes>
      <Route path="/" element={<PortListScreen />} />
      <Route path="/:portCode" element={<PortDetailScreen />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
);
