import styled from 'styled-components';

type HeaderBoxVariant = 'primary' | 'secondary';

interface HeaderBoxProps {
  isMarginRight?: boolean;
  title: string;
  variant?: HeaderBoxVariant;
  renderIcon: (color: string) => React.ReactNode;
  children?: React.ReactNode;
}

const variantToCircleColor: Record<HeaderBoxVariant, string> = {
  primary: '#D8F8E8',
  secondary: '#DBE3F7',
};

const variantToCircleIconColor: Record<HeaderBoxVariant, string> = {
  primary: '#28C76F',
  secondary: 'var(--color-white)',
};

const StyledBox = styled.div<{$isMarginRight: boolean}>`
  display: flex;
  flex-direction: row;
  padding: 12px;
  margin-right: ${props => (props.$isMarginRight ? 18 : 0)}px;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius);
  border-color: var(--border-color-light);
  background-color: var(--color-white);
  flex-grow: 1;
  height: 100%;
  align-items: flex-start;

  .ant-affix & {
    padding: 6px 12px;
  }
`;

const Circle = styled.div<{variant: HeaderBoxVariant}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 24px;
  background-color: ${p => variantToCircleColor[p.variant]};
`;

const TitleTextBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
  color: var(--color-gray-2);
`;

export const HeaderBox: React.FC<HeaderBoxProps> = ({isMarginRight = true, renderIcon, title, children}) => {
  return (
    <StyledBox $isMarginRight={isMarginRight}>
      <Circle variant={'primary'}>{renderIcon(variantToCircleIconColor['primary'])}</Circle>
      <TitleTextBox>
        <span>{title}</span>
        {children}
      </TitleTextBox>
    </StyledBox>
  );
};
