import {FeatureCollection} from 'geojson';
import {FC, useMemo, ReactElement} from 'react';
import styled from 'styled-components';
import {AisVessel} from '../../../api/symfony/generated';
import {useAisVesselsQuery} from '../../../queries/useAisVesselsQuery';
import {PortfolioVessel} from '../../../redux/Portfolio';
import {Coordinates} from '../../../utils/Coordinates';
import {SeaboMap} from '../../../components/SeaboMap/SeaboMap';
import {PolygonLayer} from './PolygonLayer';
import {MergedVisits} from '../ChokepointCongestionScreen/utils/useCongestionVisitsWithVesselInformationsQuery';
import {useVesselHistoryQuery} from '../../../queries/useVesselHistoryQuery';
import {VesselRouteLayer} from '../../../components/SeaboMap/layers/VesselRouteLayer/VesselRouteLayer';
import {SeaboVessel} from '../../../api/node-backend/generated';
import {RGBAColor} from '../../../utils/deck.gl/deck.gl-types';
import {
  MapControlButton,
  MapControlMenuSectionsToHide,
  MapSettings,
  MapStyle,
} from '../../../components/SeaboMap/MapContext/Types';
import {DEFAULT_MAP_STYLE} from '../../../components/SeaboMap/const';

type SeaboVesselWithStringType = Omit<SeaboVessel, 'vesselType' | 'color'> & {vesselType: string; color: string};

export interface MapCardMapProps {
  vessels: SeaboVesselWithStringType[] | PortfolioVessel[] | null;
  selectedVisit?: MergedVisits;
  polygon?: FeatureCollection;
  polygonColor?: RGBAColor;
  centerPoint?: Coordinates;
  zoom?: number;
  staticMapBoxLayer: boolean;
  mapControlsVisible?: boolean;
  componentOnTop?: ReactElement;
  promoLayerEnabled?: boolean;
  initialMapSettings?: Partial<MapSettings>;
  mapControlButtonsToHide?: MapControlButton[];
  mapControlMenuSectionsToHide?: MapControlMenuSectionsToHide[];

  className?: string;
  mapStyle?: MapStyle;
}

export const MapCardMap: FC<MapCardMapProps> = ({
  vessels,
  selectedVisit,
  polygon,
  polygonColor,
  initialMapSettings,
  mapControlButtonsToHide,
  centerPoint,
  zoom,
  mapControlsVisible,
  mapControlMenuSectionsToHide,
  componentOnTop: componentsOnTop,
  promoLayerEnabled = true,
  staticMapBoxLayer,
  mapStyle,
  className,
}) => {
  const historyQuery = useVesselHistoryQuery(selectedVisit?.imo, {
    enabled: !!selectedVisit?.imo,
  });

  const aisVesselsQuery = useAisVesselsQuery();
  const aisVesselsMap = useMemo(() => {
    const map: Record<number, AisVessel> = {};
    aisVesselsQuery.data?.forEach(vessel => {
      map[vessel.imo!] = vessel;
    });
    return map;
  }, [aisVesselsQuery.data]);

  const imos = vessels?.map(vessel => vessel.imo as number) ?? [];

  const vesselsInMap = getVesselsInMap({aisVesselsMap, imos});

  const coordsToFocus = centerPoint ? undefined : vesselsInMap.map(vessel => vessel.coordinates as Coordinates);

  const layersOnTop = polygon
    ? [
        // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'PolygonLayer' does not have... Remove this comment to see the full error message
        <PolygonLayer key={'polygonLayer'} polygon={polygon} polygonColor={polygonColor} />,
      ]
    : [];
  const layersOnBottom = historyQuery.data
    ? [
        // @ts-expect-error ts(2786) "VesselRouteLayer" kann nicht als JSX-Komponente verwendet... Remove this comment to see the full error message
        <VesselRouteLayer id={'vessel-history'} key={'vessel-history'} data={historyQuery.data.route ?? []} />,
      ]
    : [];

  return (
    <MapCardMapContainer className={className}>
      <SeaboMap
        staticMapBoxLayer={staticMapBoxLayer}
        initialViewState={{
          zoom: zoom,
        }}
        mapControlButtonsToHide={mapControlButtonsToHide}
        showLegend={!!mapControlsVisible}
        showSearch={!!mapControlsVisible}
        mapControlMenuSectionsToHide={mapControlMenuSectionsToHide}
        showControl={!!mapControlsVisible}
        showNavigation={!!mapControlsVisible}
        showFocusBtn={!!mapControlsVisible}
        settingsButtonVisible={!!mapControlsVisible}
        showTradingAreaPopup={!!mapControlsVisible}
        showPopups={!!mapControlsVisible}
        vesselCargoMode={true}
        defaultVesselIconSizeMultiplicator={2.5}
        coordinatesToFocus={coordsToFocus}
        centerPoint={centerPoint}
        vesselsToVisualize={{data: vesselsInMap, opacity: 1, constantIconSize: 8}}
        layersOnTop={layersOnTop}
        layersOnBottom={layersOnBottom}
        componentOnTop={componentsOnTop}
        initialMapSettings={{
          ...initialMapSettings,
          mapStyle: mapStyle ?? DEFAULT_MAP_STYLE,
        }}
        settingsPersistent={false}
        promoLayerEnabled={promoLayerEnabled}
      />
    </MapCardMapContainer>
  );
};

const MapCardMapContainer = styled.div`
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  z-index: 0;

  > div.seabo-mapContainer {
    border-radius: var(--border-radius-card);
    border: var(--border-base);
  }
`;

export const getVesselsInMap = ({aisVesselsMap, imos}: {aisVesselsMap: Record<number, AisVessel>; imos: number[]}) => {
  if (imos.length === 0 || Object.keys(aisVesselsMap).length === 0) {
    return [];
  }

  const vesselsInMap: AisVessel[] = imos.map(imo => {
    const vessel = aisVesselsMap[imo!];

    return vessel;
  });

  return vesselsInMap;
};
