import {ReactNode, FC} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {UpgradeButton} from '../../atoms/Button/UpgradeButton';
import {Icon} from '../../atoms/Icon';

export const RestrictedAccessPromotion: FC<{headline: string | ReactNode}> = ({headline}) => {
  return (
    <ContentContainer>
      <PromotionHeadline>
        {headline ?? (
          <>
            Upgrade to seabo Premium <br />
            to unveil all circular sources
          </>
        )}
      </PromotionHeadline>
      <PromotionBody>With seabo Premium you also get:</PromotionBody>
      <PromotionUvpList>
        <PromotionUvpListItem>
          <UvpIcon type="arrow-right" />
          <UvpClaim>
            Advantage over competition by full access to our <b>all-in-one shipping platform</b>
          </UvpClaim>
        </PromotionUvpListItem>
        <PromotionUvpListItem>
          <UvpIcon type="arrow-right" />
          <UvpClaim>
            <b>Faster processes and management</b> through analytical and organisational tools
          </UvpClaim>
        </PromotionUvpListItem>
        <PromotionUvpListItem>
          <UvpIcon type="arrow-right" />
          <UvpClaim>
            With all Premium features including <b>Voyage Calculation, Fixtures and more</b>
          </UvpClaim>
        </PromotionUvpListItem>
      </PromotionUvpList>
      <Link to={`/subscription`}>
        <UpgradeButton block to={`/subscription`}>
          See all seabo Premium benefits now <ArrowIcon type="arrow-right" />
        </UpgradeButton>
      </Link>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 308px;
  text-align: center;

  & > * {
    width: 100%;
  }
`;

const PromotionHeadline = styled.p`
  width: 100%;
  margin-bottom: 10px;
  font-size: var(--font-size-lg);
  font-weight: bold;
  line-height: 1.25;
`;

const PromotionBody = styled.p`
  color: var(--color-gray-2);
  line-height: 1.125;
`;

const PromotionUvpList = styled.ul`
  width: 295px;
  padding: 0;
  margin-bottom: 18px;
  list-style: none;
  color: var(--color-green-dark);
  text-align: start;
`;

const PromotionUvpListItem = styled.li`
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 8px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const UvpIcon = styled(Icon)`
  margin-top: 3px;
  transform: rotateZ(90deg);
  font-size: var(--font-size-lg) !important;
  color: var(--color-green-dark) !important;
  vertical-align: middle;
`;

const UvpClaim = styled.span`
  display: block;
  line-height: 1.25;
`;

const ArrowIcon = styled(Icon)`
  display: inline-block;
  margin-bottom: 2px;
  margin-right: -6px;
  font-size: var(--font-size-lg) !important;
  color: var(--color-white) !important;
  vertical-align: middle;
`;
