import {Helmet} from 'react-helmet-async';
import {ProjectCreateScreen, ProjectsListScreen} from '../../components/ProjectsList/ProjectsList';
import {ProjectDetailsScreen} from '../../components/ProjectDetailsScreen/ProjectDetailsScreen';
import {Route, Routes} from 'react-router-dom';

export const ProjectScreens = () => (
  <>
    <Helmet title="Projects" />
    <Routes>
      <Route path="/" element={<ProjectsListScreen />} />
      <Route path="/archive" element={<ProjectsListScreen />} />
      <Route path="/new" element={<ProjectCreateScreen />} />
      <Route path="/:id" element={<ProjectDetailsScreen />} />
    </Routes>
  </>
);
