import {useSelector} from '../../../../redux/react-redux';
import {getHasProPlan, getIsInPromotionPhase} from '../../../../redux/selectors';
import {CancelTrialBanner} from './CancelTrialBanner';
import {StartTrialBanner} from './StartTrialBanner';

export const TrialBanner = () => {
  const isInPromotionPhase = useSelector(getIsInPromotionPhase);
  const hasProPlan = useSelector(getHasProPlan);

  if (hasProPlan) {
    return null;
  }

  return isInPromotionPhase ? <CancelTrialBanner /> : <StartTrialBanner />;
};
