/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  PortfolioGroupDetails,
  PortfolioGroupDetailsFromJSON,
  PortfolioGroupDetailsToJSON,
  PortfolioGroupList,
  PortfolioGroupListFromJSON,
  PortfolioGroupListToJSON,
  PortfolioGroupStub,
  PortfolioGroupStubFromJSON,
  PortfolioGroupStubToJSON,
  PortfolioGroupType,
  PortfolioGroupTypeFromJSON,
  PortfolioGroupTypeToJSON,
} from '../models';

export interface AssignVesselToPortfolioGroupsRequest {
  vessel: number;
  requestBody: Array<number>;
}

export interface CreateSubPortfolioRequest {
  portfolioGroupStub: PortfolioGroupStub;
}

export interface DeleteSubPortfolioRequest {
  id: number;
}

export interface GetSubPortfolioRequest {
  id: number;
}

export interface GetSubPortfoliosRequest {
  type: PortfolioGroupType;
  pageIndex?: number;
  pageSize?: number;
  sortField?: string;
}

export interface UpdateSubPortfolioRequest {
  id: number;
  portfolioGroupStub: PortfolioGroupStub;
}

export interface UpdateSubPortfolioSortOrderRequest {
  type: PortfolioGroupType;
  requestBody: Array<number>;
}

/**
 *
 */
export class PortfolioGroupApi extends runtime.BaseAPI {
  /**
   */
  async assignVesselToPortfolioGroupsRaw(
    requestParameters: AssignVesselToPortfolioGroupsRequest
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.vessel === null || requestParameters.vessel === undefined) {
      throw new runtime.RequiredError(
        'vessel',
        'Required parameter requestParameters.vessel was null or undefined when calling assignVesselToPortfolioGroups.'
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling assignVesselToPortfolioGroups.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/vessel-to-groups/{vessel}`.replace(
        `{${'vessel'}}`,
        encodeURIComponent(String(requestParameters.vessel))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async assignVesselToPortfolioGroups(requestParameters: AssignVesselToPortfolioGroupsRequest): Promise<object> {
    const response = await this.assignVesselToPortfolioGroupsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Add a portfolio group.
   */
  async createSubPortfolioRaw(
    requestParameters: CreateSubPortfolioRequest
  ): Promise<runtime.ApiResponse<PortfolioGroupDetails>> {
    if (requestParameters.portfolioGroupStub === null || requestParameters.portfolioGroupStub === undefined) {
      throw new runtime.RequiredError(
        'portfolioGroupStub',
        'Required parameter requestParameters.portfolioGroupStub was null or undefined when calling createSubPortfolio.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/groups`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PortfolioGroupStubToJSON(requestParameters.portfolioGroupStub),
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortfolioGroupDetailsFromJSON(jsonValue));
  }

  /**
   * Add a portfolio group.
   */
  async createSubPortfolio(requestParameters: CreateSubPortfolioRequest): Promise<PortfolioGroupDetails> {
    const response = await this.createSubPortfolioRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete a portfolio group.
   */
  async deleteSubPortfolioRaw(requestParameters: DeleteSubPortfolioRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteSubPortfolio.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/groups/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a portfolio group.
   */
  async deleteSubPortfolio(requestParameters: DeleteSubPortfolioRequest): Promise<void> {
    await this.deleteSubPortfolioRaw(requestParameters);
  }

  /**
   * Get a specific portfolio group.
   */
  async getSubPortfolioRaw(
    requestParameters: GetSubPortfolioRequest
  ): Promise<runtime.ApiResponse<PortfolioGroupDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getSubPortfolio.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/groups/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortfolioGroupDetailsFromJSON(jsonValue));
  }

  /**
   * Get a specific portfolio group.
   */
  async getSubPortfolio(requestParameters: GetSubPortfolioRequest): Promise<PortfolioGroupDetails> {
    const response = await this.getSubPortfolioRaw(requestParameters);
    return await response.value();
  }

  /**
   * Lists portfolio groups.
   */
  async getSubPortfoliosRaw(
    requestParameters: GetSubPortfoliosRequest
  ): Promise<runtime.ApiResponse<PortfolioGroupList>> {
    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling getSubPortfolios.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.pageIndex !== undefined) {
      queryParameters['pageIndex'] = requestParameters.pageIndex;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters['sortField'] = requestParameters.sortField;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/{type}-groups`.replace(`{${'type'}}`, encodeURIComponent(String(requestParameters.type))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortfolioGroupListFromJSON(jsonValue));
  }

  /**
   * Lists portfolio groups.
   */
  async getSubPortfolios(requestParameters: GetSubPortfoliosRequest): Promise<PortfolioGroupList> {
    const response = await this.getSubPortfoliosRaw(requestParameters);
    return await response.value();
  }

  /**
   * Change a portfolio group.
   */
  async updateSubPortfolioRaw(
    requestParameters: UpdateSubPortfolioRequest
  ): Promise<runtime.ApiResponse<PortfolioGroupDetails>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateSubPortfolio.'
      );
    }

    if (requestParameters.portfolioGroupStub === null || requestParameters.portfolioGroupStub === undefined) {
      throw new runtime.RequiredError(
        'portfolioGroupStub',
        'Required parameter requestParameters.portfolioGroupStub was null or undefined when calling updateSubPortfolio.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/groups/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: PortfolioGroupStubToJSON(requestParameters.portfolioGroupStub),
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortfolioGroupDetailsFromJSON(jsonValue));
  }

  /**
   * Change a portfolio group.
   */
  async updateSubPortfolio(requestParameters: UpdateSubPortfolioRequest): Promise<PortfolioGroupDetails> {
    const response = await this.updateSubPortfolioRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates the order of portfolio groups.
   */
  async updateSubPortfolioSortOrderRaw(
    requestParameters: UpdateSubPortfolioSortOrderRequest
  ): Promise<runtime.ApiResponse<PortfolioGroupList>> {
    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling updateSubPortfolioSortOrder.'
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling updateSubPortfolioSortOrder.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/{type}-groups/sortOrder`.replace(
        `{${'type'}}`,
        encodeURIComponent(String(requestParameters.type))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortfolioGroupListFromJSON(jsonValue));
  }

  /**
   * Updates the order of portfolio groups.
   */
  async updateSubPortfolioSortOrder(
    requestParameters: UpdateSubPortfolioSortOrderRequest
  ): Promise<PortfolioGroupList> {
    const response = await this.updateSubPortfolioSortOrderRaw(requestParameters);
    return await response.value();
  }
}
