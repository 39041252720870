import {FC} from 'react';
import {
  NegotiationSummary,
  NegotiationStatus,
  NegotiationRoundResult,
  NegotiationRoundResultModification,
} from '../../../../../../api/node-backend/generated';
import {SidebarActionArea} from './SidebarActionArea';
import {NegotiationTermAreaSidebarContainer, StickySideBarContainer} from './shared';
import {SidebarActionsHook} from './useGetSidebarActionsWithMutations';

type NegotiationTermAreaSidebarRightProps = {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  roundResultModifications: NegotiationRoundResultModification;
  isFirstRecapRound?: boolean;
  getSidebarActions: SidebarActionsHook;
  actionsBusy?: boolean;
};

export const NegotiationTermAreaSidebarRight: FC<NegotiationTermAreaSidebarRightProps> = ({
  negotiation,
  roundResult,
  roundResultModifications,
  isFirstRecapRound,
  getSidebarActions,
  actionsBusy,
}) => {
  const {status} = negotiation;

  if ([NegotiationStatus.Cancelled, NegotiationStatus.Settled].includes(status)) {
    return null;
  }

  return (
    <NegotiationTermAreaSidebarContainer $position="right">
      <StickySideBarContainer>
        <SidebarActionArea
          negotiation={negotiation}
          roundResult={roundResult}
          roundResultModifications={roundResultModifications}
          isFirstRecapRound={isFirstRecapRound}
          actionsBusy={actionsBusy}
          getSidebarActions={getSidebarActions}
        />
      </StickySideBarContainer>
    </NegotiationTermAreaSidebarContainer>
  );
};
