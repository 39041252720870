import {useFullScreenHandler} from './useFullScreenHandler';
import {useKeyHandler} from './useKeyHandler';
import {useHandleCenterPoint} from './useHandleCenterPoint';
import {useInitalFocusOnCoordinatesReceivedByMapProps} from './useInitalFocusOnCoordinatesReceivedByMapProps';

export const HookComponent = () => {
  useFullScreenHandler();
  useKeyHandler();
  useHandleCenterPoint();
  useInitalFocusOnCoordinatesReceivedByMapProps();
  return <></>;
};
