import {z} from 'zod';

export const CompanyLogoSchema = z.object({
  uuid: z.string().uuid(),
  filename: z.string(),
  fileExtension: z.string(),
  size: z.number(),
  mimeType: z.string(),
  url: z.string(),
  context: z.string(),
  uploaded: z.boolean(),
  assigned: z.boolean(),
  created: z.string(),
  downloadCount: z.number(),
});

export const CompanyUserSchema = z.object({
  id: z.number(),
  userID: z.number(),
  fullName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  companyPosition: z.string().nullable(),
  companyVisibility: z.boolean(),
  companyJoinTime: z.string().nullable(),
});

export const CompanyAddressSchema = z.object({
  name: z.string().nullable(),
  street: z.string().nullable(),
  street2: z.string().nullable(),
  streetNumber: z.string().nullable(),
  zip: z.string().nullable(),
  city: z.string().nullable(),
  country: z.string().nullable(),
});

export const CompanyContactSchema = z.object({
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  fax: z.string().nullable(),
  website: z.string().nullable(),
});

export const SubscriptionPlans = {
  Free: 'free',
  Standard: 'standard',
  Pro: 'pro',
} as const;

export const SubscriptionPlanSchema = z.enum([
  SubscriptionPlans.Free,
  SubscriptionPlans.Standard,
  SubscriptionPlans.Pro,
]);

export const CompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  foundYear: z.number().nullable(),
  employees: z.string().nullable(),
  vesselsManaged: z.number().nullable(),
  business: z.string().nullable(),
  companyType: z.string().nullable(),
  companyLogo: CompanyLogoSchema.nullable(),
  user: z.array(CompanyUserSchema),
  address: CompanyAddressSchema,
  contact: CompanyContactSchema,
  isVerified: z.boolean(),
  isOnboarded: z.boolean(),
  showConnectMarketplaceBanner: z.boolean(),
  isPayingCustomer: z.boolean(),
  isFollowing: z.boolean(),
  createdAt: z.string(),
  companyMailDomain: z.string().nullish(),
  taxId: z.string().nullish(),
  promotionUntil: z.string().nullish(),
  subscriptionPlan: SubscriptionPlanSchema,
});

/**
 * It is the response for CompanyServiceActions.getCompany request.
 */
export type Company = z.infer<typeof CompanySchema>;

export type CompanyUser = z.infer<typeof CompanyUserSchema>;

export type SubscriptionPlan = z.infer<typeof SubscriptionPlanSchema>;
