import {Col, Row, Typography} from 'antd';
import capitalize from 'lodash/capitalize';
import React, {FC} from 'react';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import {SegmentSizeSelector} from './SegmentSizeSelector';
import {SelectorCard} from './SelectorCard';

const {Title} = Typography;

export const BulkerContainerSizeSelector: FC<{
  selectedSizes: string[];
  onChangeItem: (item: string) => void;
  segments: string[];
}> = ({selectedSizes, onChangeItem, segments}) => {
  switch (segments.length) {
    case 1:
      return <SingleSegmentSelector segment={segments[0]} selectedSizes={selectedSizes} onChangeItem={onChangeItem} />;
    case 2:
      return <MultipleSegmentSelector segments={segments} selectedSizes={selectedSizes} onChangeItem={onChangeItem} />;
    default:
      throw new Error('Bad segments');
  }
};

const SingleSegmentSelector = ({
  segment,
  selectedSizes,
  onChangeItem,
}: {
  segment: string;
  selectedSizes: string[];
  onChangeItem: (item: string) => void;
}) => {
  return <SegmentSizeSelector colSpanXxl={12} segment={segment} onChangeItem={onChangeItem} values={selectedSizes} />;
};

const MultipleSegmentSelector = ({
  segments,
  selectedSizes,
  onChangeItem,
}: {
  selectedSizes: string[];
  onChangeItem: (item: string) => void;
  segments: string[];
}) => {
  return (
    <Row gutter={[32, 32]}>
      {segments.map(segment => {
        return (
          <Col key={segment} span={24} xl={12}>
            <SelectorCard>
              <Title level={5} style={{marginBottom: 0}}>
                {capitalize(segment)}
              </Title>
              <HorizontalLine />
              <SegmentSizeSelector
                colSpanXxl={24}
                segment={segment}
                onChangeItem={onChangeItem}
                values={selectedSizes}
              />
            </SelectorCard>
          </Col>
        );
      })}
    </Row>
  );
};
