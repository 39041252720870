import {CompositeLayer, GeoJsonLayer} from 'deck.gl';

class OpenSeaMapGeoJSONLayer extends CompositeLayer {
  renderLayers() {
    return [
      new GeoJsonLayer({
        data: '/static/seamarks-all.geojson',

        onClick: this.props.onClick,
        stroked: false,
        filled: true,
        extruded: true,
        wireframe: true,

        getLineColor: [255, 255, 255],

        pickable: true,
      }),
    ];
  }
}

OpenSeaMapGeoJSONLayer.layerName = 'OpenSeaMapGeoJSONLayer';
export default OpenSeaMapGeoJSONLayer;
