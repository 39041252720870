import {Dayjs} from 'dayjs';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import {SearchPickerPortSchema} from '../../components/SearchPicker/SearchPickerPort';
import routesApi from '../../redux/ApiService/routeservice';
import {TODO} from '../../utils/TODO';
import {initialRoute} from './const';
import {DistanceCalculatorFormValues} from './CalculatorSettings/DistanceCalculatorFormValues';
import {preparePortsForRequest} from './preparePortsForRequest';
import {blockedAreas, calculate, calculateResultsObject} from './utils';

type Result = {
  distance: number;
  routes: TODO[];
  duration?: number;
  seca?: number;
  piracy?: number;
  errorMessage?: string;
  hasError: TODO[];
  speed: number;
  ETA: Dayjs | null;
  ETD: Dayjs | null;
};
export const useRouteCalculationMutation = (
  options: UseMutationOptions<Result, unknown, DistanceCalculatorFormValues>
) => {
  const dispatch = useDispatch();
  const getRoute = ({from, to, ...rest}: TODO) => {
    return dispatch(
      routesApi.get({
        fromLat: from.lat,
        toLat: to.lat,
        fromLon: from.lon,
        toLon: to.lon,
        ...rest,
      })
    );
  };

  return useMutation({
    mutationFn: async ({type, stations, speed, options, ETA, ETD}: DistanceCalculatorFormValues): Promise<Result> => {
      if (stations.length < 2) {
        throw new Error('You need at least two stations to calculate a route');
      }

      if (speed < 1 || speed > 30) {
        throw new Error('Speed must be between 1 and 30');
      }

      const ports = stations.map(port => {
        try {
          return SearchPickerPortSchema.parse(port);
        } catch (e) {
          throw new Error(`Invalid port format: ${JSON.stringify(port)}`);
        }
      });

      const routePoints = preparePortsForRequest(ports);

      const responses = await Promise.all(
        routePoints.map((route: TODO) =>
          getRoute({
            ...route,
            speed,
            avoidSeca: options.seca.value,
            avoidPiracy: options.hra.value,
            blockedAreas: blockedAreas(options),
          })
        )
      );

      const {distance, routes, seca, piracy, hasError, errorMessage} = responses.reduce(calculate(routePoints), {
        ...initialRoute,
      });

      const result = calculateResultsObject({
        type,
        eta: ETA,
        etd: ETD,
        speed,
        distance,
      });
      return {
        ...result,
        distance,
        routes,
        duration: result.duration,
        speed: result.speed ?? 0,
        ETA: result.ETA,
        ETD: result.ETD,
        seca,
        errorMessage,
        hasError,
        piracy,
      };
    },
    ...options,
  });
};
