/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Visibility, VisibilityFromJSON, VisibilityFromJSONTyped, VisibilityToJSON} from './';

/**
 *
 * @export
 * @interface VisibilitySettings
 */
export interface VisibilitySettings {
  /**
   *
   * @type {Visibility}
   * @memberof VisibilitySettings
   */
  canViewProfile: Visibility;
  /**
   *
   * @type {Visibility}
   * @memberof VisibilitySettings
   */
  canViewOnlineStatus: Visibility;
  /**
   *
   * @type {Visibility}
   * @memberof VisibilitySettings
   */
  canViewEmailAddress: Visibility;
  /**
   *
   * @type {Visibility}
   * @memberof VisibilitySettings
   */
  canMail: Visibility;
}

export function VisibilitySettingsFromJSON(json: any): VisibilitySettings {
  return VisibilitySettingsFromJSONTyped(json, false);
}

export function VisibilitySettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibilitySettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    canViewProfile: VisibilityFromJSON(json['canViewProfile']),
    canViewOnlineStatus: VisibilityFromJSON(json['canViewOnlineStatus']),
    canViewEmailAddress: VisibilityFromJSON(json['canViewEmailAddress']),
    canMail: VisibilityFromJSON(json['canMail']),
  };
}

export function VisibilitySettingsToJSON(value?: VisibilitySettings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    canViewProfile: VisibilityToJSON(value.canViewProfile),
    canViewOnlineStatus: VisibilityToJSON(value.canViewOnlineStatus),
    canViewEmailAddress: VisibilityToJSON(value.canViewEmailAddress),
    canMail: VisibilityToJSON(value.canMail),
  };
}
