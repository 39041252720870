import {FC, Fragment} from 'react';
import capitalize from 'lodash/capitalize';
import DetailsSectionJS from '../../atoms/DetailsSection';
import DetailsColumn from '../../atoms/DetailsSection/DetailsColumn';
import {IntakeGrid} from '../VesselDetailScreen/sections/Intake';
import {Attribute} from '../../atoms/Attribute/Attribute';
import DetailTable from '../../components/DetailTable';
import DetailTableRowJS from '../../components/DetailTable/DetailTableRow';
import DetailsTableCellJS from '../../components/DetailTable/DetailTableCell';
import DetailTableBodyJS from '../../components/DetailTable/DetailTableBody';
import LocationOutput from '../../components/LocationOutput';
import {numberFormat, numberUnitFormat} from '../../utils/formatter';
import DateTime from '../../model/DateTime';
import {TradingAreaLocation as TradingAreaLocationJS} from '../../components/LocationOutput/LocationOutputRow';
import {PortLocation} from '../../components/LocationOutput/PortLocation';
import Country from '../../model/Country';
import AttributeIcon from '../../atoms/Attribute/AttributeIcon';
import {Link} from 'react-router-dom';
import {GetPortfolioCargoesGearEnum} from '../../api/symfony/generated/apis';
import {FeatureSection} from './CargoVesselForm.styled';
import {PortfolioVesselDatabaseFilter} from '../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';
import {CommodityCategory, useCommodityCategory} from '../../atoms/CommodityCategory';
import DetailTableCell from '../../components/DetailTable/DetailTableCell';
import {
  Consumption,
  Vessel,
} from '../MyFleetScreens/MyFleetScreen/VesselPositionListModal/useMyFleetQuery/useMyFleetQuery';
import {Row} from 'antd';
import {modes} from './VesselSections/Consumption/ConsumptionRows';
import {
  VesselDetailsVessel,
  VesselTypeWithTanker,
} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {TODO} from '../../utils/TODO';

export type VesselDetailAttribute = {
  label: string;
  field: keyof Vessel;
  props?: {
    formatted?: boolean;
    numberFormatOptions?: Intl.NumberFormatOptions;
  };
};
export type VesselDetailDataColumn = {
  props?: {
    additionalClasses?: string;
  };
  content: VesselDetailAttribute[];
};

const TradingAreaLocation = TradingAreaLocationJS as TODO;
const DetailsSection = DetailsSectionJS as TODO;
const DetailsTableCell = DetailsTableCellJS as TODO;
const DetailTableBody = DetailTableBodyJS as TODO;
const DetailTableRow = DetailTableRowJS as TODO;

type ModesKey = keyof typeof modes;

export const vesselStatusOptions = {
  active: 'Active',
  employed: 'Employed',
  drydock: 'Ex-Drydock',
  yard: 'Ex-Yard',
  inlayup: 'In lay up',
  onorder: 'On order',
  scrapped: 'Scrapped',
};

export type VesselStatus = keyof typeof vesselStatusOptions;

const getVesselStatus = ({status}: {status: VesselStatus}) => {
  return vesselStatusOptions[status] || null;
};

export const getDesignType = ({designType}: TODO) => {
  if (typeof designType === 'object') {
    return designType ? designType.label : null;
  }
  return designType;
};
const getChartererName = ({chartererName}: TODO) => {
  if (typeof chartererName === 'object') {
    return chartererName ? chartererName.label : null;
  }
  return chartererName;
};

export const getManagementHeadOwner = ({managementHeadOwner}: TODO) => {
  if (!managementHeadOwner) {
    return null;
  }

  const registeredOwner = typeof managementHeadOwner === 'object' ? managementHeadOwner.label : managementHeadOwner;
  const filterSettings: PortfolioVesselDatabaseFilter = {
    ownership: {
      registeredOwner: registeredOwner,
    },
  };
  return (
    <Link target={'_blank'} to={`/my-fleet?filters=${encodeURIComponent(JSON.stringify(filterSettings))}`}>
      {registeredOwner}
    </Link>
  );
};

export const getManagementManagingOwner = ({managementManagingOwner}: TODO) => {
  if (!managementManagingOwner) {
    return null;
  }

  const managingOwner =
    typeof managementManagingOwner === 'object' ? managementManagingOwner.label : managementManagingOwner;
  const filterSettings: PortfolioVesselDatabaseFilter = {
    ownership: {
      managingOwner: managingOwner,
    },
  };

  return (
    <Link target={'_blank'} to={`/my-fleet?filters=${encodeURIComponent(JSON.stringify(filterSettings))}`}>
      {managingOwner}
    </Link>
  );
};

export const getManagementDisponentOwner = ({managementDisponentOwner}: TODO) => {
  if (!managementDisponentOwner) {
    return null;
  }

  const disponentOwner =
    typeof managementDisponentOwner === 'object' ? managementDisponentOwner.label : managementDisponentOwner;
  const filterSettings: PortfolioVesselDatabaseFilter = {
    ownership: {
      disponentOwners: [disponentOwner],
    },
  };
  return (
    <Link target={'_blank'} to={`/my-fleet?filters=${encodeURIComponent(JSON.stringify(filterSettings))}`}>
      {disponentOwner}
    </Link>
  );
};

export const generalInfoColumns = (type: string, target: string = 'portfolio') => [
  {
    props: {additionalClasses: 'scol-lg-5'},
    content:
      target === 'portfolio'
        ? [
            {label: 'Vessel type', field: 'vesselType', props: {upper: true}},
            {label: 'Current name', field: 'name'},
            {label: 'Imo', field: 'imo'},
            {label: 'Flag', field: 'countryFlag', props: {showCountry: true, displayInline: true}},
            {label: 'Status', field: getVesselStatus},
          ]
        : [
            {label: 'Vessel type', field: 'vesselType', props: {upper: true}},
            {label: 'Current name', field: 'name'},
            {label: 'Imo', field: 'imo'},
            {label: 'Flag', field: 'countryFlag', props: {showCountry: true, displayInline: true}},
          ],
  },
  {
    props: {additionalClasses: 'scol-lg-5'},
    content: generalInfoColumnsContent(type),
  },
];

const generalInfoColumnsContent = (type: string) => {
  switch (type) {
    case 'bulker':
      return [
        {label: 'Sub type', field: 'designSubType', props: {upper: true}},
        {label: 'Charter name', field: 'charterName'},
        {label: 'Built', field: 'builtYear'},
        {label: 'Ship builder', field: 'shipBuilder'},
      ];
    case 'container':
      return [
        {label: 'Design type', field: getDesignType},
        {label: 'Charter name', field: 'charterName'},
        {label: 'Built', field: 'builtYear'},
        {label: 'Ship builder', field: 'shipBuilder'},
      ];
    default:
      return [
        {label: 'Charter name', field: 'charterName'},
        {label: 'Built', field: 'builtYear'},
        {label: 'Ship builder', field: 'shipBuilder'},
      ];
  }
};

export const backendColumns = ({reviewed, displayed, mmsi}: TODO) => {
  return (
    <FeatureSection>
      <AttributeIcon key={'reviewed'} check={reviewed} label={'Reviewed'} />
      <AttributeIcon key={'displayed'} check={displayed} label={'Show in frontend'} />
      <DetailsColumn style={{marginLeft: 48}}>
        <Attribute label={'MMSI'} value={mmsi} />
      </DetailsColumn>
    </FeatureSection>
  );
};

export const DimensionsTable: FC<{vessel: VesselDetailsVessel}> = ({vessel}) => {
  return (
    <DetailTable fullWidth>
      <DetailTableRow headerWrapper>
        {dimensionsColumns(vessel.vesselType).map(row =>
          row.content.map(cell => <DetailTableCell key={cell.label} value={cell.label} />)
        )}
      </DetailTableRow>
      <tbody>
        <DetailTableRow>
          {dimensionsColumns(vessel.vesselType).map((row, rowIndex) =>
            row.content.map(cell => (
              <Fragment key={`${rowIndex}/${cell.label}`}>
                <DetailTableCell value={vessel[cell.field]} {...cell.props} />
              </Fragment>
            ))
          )}
        </DetailTableRow>
      </tbody>
    </DetailTable>
  );
};

export const dimensionsColumns = (type: VesselTypeWithTanker | null): VesselDetailDataColumn[] => {
  const content: VesselDetailAttribute[] = [
    {
      label: 'LOA',
      field: 'lengthOverall',
      props: {formatted: true, numberFormatOptions: {minimumFractionDigits: 2, maximumFractionDigits: 2}},
    },
    {
      label: 'Beam',
      field: 'beam',
      props: {formatted: true, numberFormatOptions: {minimumFractionDigits: 2, maximumFractionDigits: 2}},
    },
    {
      label: 'Draft',
      field: 'draft',
      props: {formatted: true, numberFormatOptions: {minimumFractionDigits: 2, maximumFractionDigits: 2}},
    },
    {label: 'GT', field: 'grossTonnage', props: {formatted: true}},
    {label: 'NT', field: 'netTonnage', props: {formatted: true}},
  ];
  if (type !== 'container') {
    content.push({label: 'TPC', field: 'tonnesPerCentimetre', props: {formatted: true}});
  }
  const columns: VesselDetailDataColumn[] = [
    {
      props: {},
      content,
    },
  ];
  return columns;
};

export const ownershipColumns = [
  {
    content: [
      {label: 'Commercial manager', field: getManagementHeadOwner},
      {label: 'Owner', field: getManagementManagingOwner},
      {label: 'Charterer / Disp. Owner', field: getManagementDisponentOwner},
    ],
  },
];

export const aisColumns = [
  {
    props: {additionalClasses: 'scol-lg-4'},
    content: [
      {
        label: 'Speed',
        field: ({aisDetails}: TODO) => numberUnitFormat(aisDetails.speed, 'knots', {toUpperCase: false}),
      },
      {
        label: 'Course',
        field: ({aisDetails}: TODO) => numberUnitFormat(aisDetails.course, '°', {unitSpace: false}),
      },
      {
        label: 'Draft',
        field: ({aisDetails}: TODO) => numberUnitFormat(aisDetails.draught, 'meters', {toUpperCase: false}),
      },
      {label: 'Status', field: ({aisDetails}: TODO) => aisDetails.statusText},
    ],
  },
  {
    props: {additionalClasses: 'scol-lg-4'},
    content: [
      {
        label: 'Received',
        field: ({aisDetails}: TODO) => DateTime.fromServerResponse(aisDetails.postime).getFormattedDateTime(),
      },
      {label: 'Position', field: ({aisDetails}: TODO) => aisDetails.coordinates},
      {
        label: 'Current port',
        field: ({aisDetails}: TODO) =>
          aisDetails.currentPort ? (
            <PortLocation
              location={{
                ...aisDetails.currentPort,
                countryObject: {
                  name: Country.getName(aisDetails.currentPort.country),
                  code: aisDetails.currentPort.country,
                },
              }}
              style={{display: 'block'}}
            />
          ) : null,
      },
      {
        label: 'Current area',
        field: ({aisDetails}: TODO) =>
          aisDetails.currentArea ? <TradingAreaLocation location={aisDetails.currentArea} /> : null,
      },
    ],
  },
];

export const IntakeTable: FC<{vessel: Vessel & TODO}> = ({vessel}) => {
  switch (vessel.vesselType) {
    case 'bulker':
      return <IntakeBulker {...vessel} hideTitle={true} />;
    case 'container':
      return <IntakeContainer {...vessel} type={'vessel'} hideTitle={true} />;
    case 'mpp':
      return <IntakeMpp {...vessel} type={'vessel'} hideTitle={true} />;
    default:
      return <p>No intake data found</p>;
  }
};

export const IntakeContainer = ({dwtSummer, reeferPlugs, intakes, ...rest}: Vessel & TODO) => (
  <Row>
    {intakes && intakes.length > 0 && (
      <DetailsColumn style={{marginBottom: '20px'}} size={12}>
        <IntakeGrid intakes={intakes} noText {...rest} />
      </DetailsColumn>
    )}
    <DetailsColumn size={6} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'l'} label={'Dwt summer'} value={numberFormat(dwtSummer)} />
    </DetailsColumn>
    <DetailsColumn size={6} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'l'} label={'Reefer plugs'} value={numberFormat(reeferPlugs)} />
    </DetailsColumn>
  </Row>
);

export const IntakeBulker = ({
  dwtSummer,
  hatchesAmount,
  holdsAmount,
  grain,
  bale,
  grainUnit,
  baleUnit,
}: Vessel & TODO) => (
  <Row>
    <DetailsColumn size={12} additionalClasses={'scol-lg-12'}>
      <Attribute label={'Dwt summer'} value={numberFormat(dwtSummer)} tab={'m'} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute label={'Holds'} value={holdsAmount} tab={'m'} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute label={'Hatches'} value={hatchesAmount} tab={'m'} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute label={'Grain'} value={numberUnitFormat(grain, grainUnit)} tab={'m'} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute label={'Bale'} value={numberUnitFormat(bale, baleUnit)} tab={'m'} />
    </DetailsColumn>
  </Row>
);

export const IntakeMpp = ({
  dwtSummer,
  reeferPlugs,
  holdsAmount,
  hatchesAmount,
  grain,
  grainUnit,
  bale,
  baleUnit,
  intakes,
  ...rest
}: Vessel & TODO) => (
  <Row>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'m'} label={'Dwt summer'} value={numberFormat(dwtSummer)} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'m'} label={'Reefer plugs'} value={numberFormat(reeferPlugs)} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'m'} label={'Holds'} value={holdsAmount} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'m'} label={'Hatches'} value={hatchesAmount} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'m'} label={'Grain'} value={numberUnitFormat(grain, grainUnit)} />
    </DetailsColumn>
    <DetailsColumn size={12} additionalClasses={'scol-lg-6'}>
      <Attribute tab={'m'} label={'Bale'} value={numberUnitFormat(bale, baleUnit)} />
    </DetailsColumn>
    {intakes && intakes.length > 0 && (
      <DetailsColumn style={{marginTop: '20px'}} size={12}>
        <IntakeGrid intakes={intakes} noText {...rest} />
      </DetailsColumn>
    )}
  </Row>
);

export const ConsumptionTable = ({
  consumptions = [],
  noText = false,
}: (Vessel | VesselDetailsVessel) & {noText?: boolean}) => {
  consumptions = consumptions.filter((c: Consumption) => c.type.length > 0);
  if (consumptions.length === 0) {
    if (!noText) {
      return <span className="vessel-content__text">No consumptions found</span>;
    } else {
      return null;
    }
  }

  return (
    <DetailTable fullWidth>
      <DetailTableRow headerWrapper>
        <DetailsTableCell value={'Mode'} />
        <DetailsTableCell value={'Speed (kn)'} />
        <DetailsTableCell value={'Consumption (mt)'} />
        <DetailsTableCell value={'Additional consumption (mt)'} />
      </DetailTableRow>
      <DetailTableBody>
        {consumptions.map((item, idx: number) => (
          <DetailTableRow key={idx}>
            <DetailsTableCell value={item.type ? modes[item.type as ModesKey] : null} />
            <DetailsTableCell value={detailsTableCellValue(item)} />
            <DetailsTableCell upper value={item.fuelConsumption ? `${item.fuelConsumption} ${item.fuelType}` : null} />
            <DetailsTableCell
              upper
              value={item.extraFuelConsumption ? `${item.extraFuelConsumption} ${item.extraFuelType}` : null}
            />
          </DetailTableRow>
        ))}
      </DetailTableBody>
    </DetailTable>
  );
};

const detailsTableCellValue = (item: TODO) => {
  if (item.speed) {
    return item.speed;
  }
  return item.type === 'portidle' || item.type === 'portworking' ? '' : null;
};

export type VesselTypeKey = 'bulker' | 'container' | 'mpp' | 'tanker' | 'other';

export const vesselTypes: Partial<Record<VesselTypeKey, string>> = {
  bulker: 'Bulker',
  container: 'Container',
  mpp: 'MPP',
};

export type BulkerDesignType = keyof typeof designSubTypes;

export const designSubTypes = {
  minibulker: 'Mini-Bulker',
  handysize: 'Handysize',
  handymax: 'Handymax',
  supramax: 'Supramax',
  ultramax: 'Ultramax',
  panamax: 'Panamax',
  kamsarmax: 'Kamsarmax',
  postpanamax: 'Post-Panamax',
  capesize: 'Capesize',
};

export const vesselFeatureUnits = {
  dwt: 'DWT',
  teu: 'TEU',
  teu14: 'TEU14',
};

export const iceClasses = {
  '1as': '1A Super',
  '1a': '1A',
  '1b': '1B',
  '1c': '1C',
  '1d': '1D',
  e: 'E',
};

export const gearTypes = {
  gear: 'Gear',
  grab: 'Grab',
};

export const newGear = {
  type: Object.keys(gearTypes)[0],
  quantity: 1, // Amount Dropdown, 1-20
  capacity: '',
  description: '', // Type
};

export const cargoFeatureSet = {
  cellular: 'Cellular',
  itfFitted: 'ITF-fitted',
  bowThrust: 'Bow thruster',
  sternThrust: 'Stern thruster',
  openHatch: 'Open-hatch',
  lakesFitted: 'Lakes-fitted',
  logFitted: 'Log-fitted',
  co2Fitted: 'CO2-fitted',
  boxShape: 'Box-shape',
  tweenDecks: 'Tween-decks',
  a60Bulkhead: 'A60 Bulkhead',
  scrubberFitted: 'Scrubber-fitted',
};

export const cargoFeatures = {
  mpp: Object.keys(cargoFeatureSet),
  bulker: [
    'logFitted',
    'lakesFitted',
    'tweenDecks',
    'a60Bulkhead',
    'co2Fitted',
    'boxShape',
    'openHatch',
    'itfFitted',
    'scrubberFitted',
  ],
  container: ['cellular', 'itfFitted', 'bowThrust', 'sternThrust', 'openHatch', 'lakesFitted', 'scrubberFitted'],
};

export const vesselFeatureSet = {
  featureLakesFitted: 'Lakes-fitted',
  featureCellular: 'Cellular',
  featureSternThrust: 'Stern thruster',
  featureOpenHatch: 'Open-hatch',
  featureItfFitted: 'ITF-fitted',
  featureBowThrust: 'Bow thruster',
  featureLogFitted: 'Log-fitted',
  featureTweenDecks: 'Tween-decks',
  featureA60Bulkhead: 'A60 Bulkhead',
  featureCo2Fitted: 'CO2-fitted',
  featureBoxShape: 'Box-shape',
  featureScrubberFitted: 'Scrubber-fitted',
};

export const vesselFeatures = {
  mpp: Object.keys(vesselFeatureSet),
  bulker: [
    'featureLogFitted',
    'featureLakesFitted',
    'featureTweenDecks',
    'featureA60Bulkhead',
    'featureCo2Fitted',
    'featureBoxShape',
    'featureOpenHatch',
    'featureItfFitted',
    'featureScrubberFitted',
  ],
  container: [
    'featureLakesFitted',
    'featureCellular',
    'featureSternThrust',
    'featureOpenHatch',
    'featureItfFitted',
    'featureBowThrust',
    'featureScrubberFitted',
  ],
};

export const gearRequiredTypes: Record<GearFeatureFrontend, string> = {
  [GetPortfolioCargoesGearEnum.Geared]: 'Gear required', // geared = true
  // eslint-disable-next-line camelcase
  gear_optional: 'Gear optional', // geared = null
  [GetPortfolioCargoesGearEnum.Gearless]: 'Gearless', // geared = false
  noData: 'no data', // geared = false
};

export const LocationWrapperVc = ({stations, title, type}: TODO) => {
  return (
    <DetailsSection title={title} hasBorderBottom>
      <DetailsColumn>
        <LocationOutput
          stationType={type}
          locations={
            stations[type].length > 0
              ? stations[type].reduce((acc: TODO, curr: TODO) => {
                  if (curr.location) {
                    acc.push({
                      ...curr.location,
                      additionalInfo: {
                        cqd: curr.cqd,
                        rate: curr.rate,
                        terms: curr.terms,
                        timeToTurn: curr.timeToTurn,
                        unit: curr.unit,
                      },
                    });
                  }
                  return acc;
                }, [])
              : []
          }
        />
      </DetailsColumn>
    </DetailsSection>
  );
};

export const LocationWrapper = ({stations, title, type}: TODO) => {
  return (
    <DetailsSection title={title} hasBorderBottom>
      <DetailsColumn>
        <LocationOutput
          stationType={type}
          locations={
            stations[type].length > 0
              ? stations[type].reduce((acc: TODO, curr: TODO) => {
                  if (curr.id) {
                    acc.push(curr);
                  }
                  return acc;
                }, [])
              : []
          }
        />
      </DetailsColumn>
    </DetailsSection>
  );
};

export const getMaxVesselAge = ({maxVesselAge}: TODO) => {
  return numberUnitFormat(maxVesselAge, 'years', {toUpperCase: false});
};

export const cargoGeneralInfoColumnsVc = [
  {
    props: {size: 4},
    content: [
      {label: 'Market segment', field: 'cargoType', props: {upper: true}},
      {label: 'Contract type', field: 'contractType'},
    ],
  },
  {
    props: {size: 4},
    content: [
      {label: 'charterer', field: getChartererName},
      {label: 'Max age', field: getMaxVesselAge},
    ],
  },
];

export const cargoGeneralInfoColumns = [
  {
    props: {size: 4},
    content: [
      {label: 'Market segment', field: 'cargoType', props: {upper: true}},
      {label: 'Contract type', field: 'contractType'},
    ],
  },
  {
    props: {size: 4},
    content: [{label: 'charterer', field: getChartererName}],
  },
];

export const CommoditySummarized: FC<{cargo: TODO}> = ({cargo}) => {
  const category = useCommodityCategory({commodityCategory: cargo.commodityCategory})?.name;

  const type = getCommodityTypeFormatted(cargo);

  if (!category) {
    return null;
  }

  if (type) {
    return (
      <span>
        {category} ({type})
      </span>
    );
  }

  return <span>{category}</span>;
};

/**
 * @deprecated Please, dont use this function.
 * Instead use useCommodityQuery and find the right category.
 */
export const getCommodityCategoryFormatted = ({commodityCategory}: TODO) =>
  commodityCategory ? commodityCategory.charAt(0).toUpperCase() + commodityCategory.slice(1) : null;

export const getCommodityTypeFormatted = ({commodityType}: TODO): string | null =>
  commodityType ? commodityType.charAt(0).toUpperCase() + commodityType.slice(1).replace(/-/g, ' ') : null;

export const commoditySectionVc = [
  {
    props: {size: 2},
    content: [
      {label: 'Category', field: getCommodityCategoryFormatted},
      {
        label: 'Stowage factor',
        field: 'commodityStowageFactor',
        unit: 'commodityStowageFactorUnit',
        props: {upper: true},
      },
    ],
  },
  {
    props: {size: 1},
    content: [{label: 'Type', field: getCommodityTypeFormatted}],
  },
];

export const commoditySection = [
  {
    props: {size: 1},
    content: [
      {
        label: 'Category',
        field: (cargo: TODO) => {
          return <CommodityCategory commodityCategory={cargo.commodityCategory} />;
        },
      },
    ],
  },
  {
    props: {size: 1},
    content: [{label: 'Type', field: getCommodityTypeFormatted}],
  },
];

export const getCargoQuantityMin = ({quantityMin, quantityUnit}: TODO) => {
  return numberUnitFormat(quantityMin, quantityUnit);
};

export const getCargoQuantityMax = ({quantityMax, quantityUnit}: TODO) => {
  return numberUnitFormat(quantityMax, quantityUnit);
};

export const getCargoQuantityTolerance = ({tolerance, toleranceUnit}: TODO) => {
  return numberUnitFormat(tolerance, toleranceUnit);
};

export const quantitySection = [
  {
    props: {size: 1},
    content: [{label: 'Min', field: getCargoQuantityMin}],
  },
  {
    props: {size: 1},
    content: [{label: 'Max', field: getCargoQuantityMax}],
  },
  {
    props: {size: 1},
    content: [{label: 'Tolerance', field: getCargoQuantityTolerance}],
  },
  {
    props: {size: 1},
    content: [{label: 'Option', field: 'quantityOption'}],
  },
];

export const getCommissionTotal = ({commissionAddress, commissionBrokerage}: TODO) => {
  if (commissionAddress || commissionBrokerage) {
    return ((parseFloat(commissionAddress) || 0) + (parseFloat(commissionBrokerage) || 0)).toFixed(2);
  } else {
    return '-';
  }
};

export const commissionSection = [
  {
    props: {size: 1},
    content: [{label: 'Address', field: 'commissionAddress', exactUnit: '%'}],
  },
  {
    props: {size: 1},
    content: [{label: 'Brokerage', field: 'commissionBrokerage', exactUnit: '%'}],
  },
  {
    props: {size: 1},
    content: [{label: 'Total', field: getCommissionTotal, exactUnit: '%'}],
  },
];

interface Cargo {
  gear: GearFeatureApi;
}

export const getNameForGeared = ({gear}: Cargo) => {
  switch (gear) {
    case GetPortfolioCargoesGearEnum.Geared:
      return 'Gear required';
    case GetPortfolioCargoesGearEnum.Gearless:
      return 'Gearless';
    case 'gear_optional':
      return 'Gear optional';
    case null:
      return 'N/A';
  }
};

type GearFeatureFrontend =
  | GetPortfolioCargoesGearEnum.Geared
  | GetPortfolioCargoesGearEnum.Gearless
  | 'gear_optional'
  | 'noData';
type GearFeatureApi =
  | GetPortfolioCargoesGearEnum.Geared
  | GetPortfolioCargoesGearEnum.Gearless
  | null
  | 'gear_optional';

export const gearFromFrontendToApi = (gear: GearFeatureFrontend): GearFeatureApi => {
  if (gear === 'noData') {
    return null;
  }
  return gear;
};

export const gearFromApiToFrontend = (gear: GearFeatureApi): GearFeatureFrontend => {
  if (gear === null) {
    return 'noData';
  }
  return gear;
};

const getMinSize = ({vesselSizeMin, vesselSizeUnit}: TODO) => {
  return numberUnitFormat(vesselSizeMin, vesselSizeUnit);
};

const getMaxSize = ({vesselSizeMax, vesselSizeUnit}: TODO) => {
  return numberUnitFormat(vesselSizeMax, vesselSizeUnit);
};

export const getVesselType = ({vesselType, vesselSubtype}: TODO) => {
  if (!vesselType) {
    return 'N/A';
  }
  vesselType = vesselType.toUpperCase();
  if (typeof vesselSubtype === 'object') {
    vesselSubtype = vesselSubtype ? vesselSubtype.label : null;
  }
  if (vesselType === 'BULKER') {
    vesselSubtype = capitalize(vesselSubtype);
  }

  if (vesselSubtype) {
    vesselSubtype = ' (' + vesselSubtype + ')';
  }

  return vesselType + (vesselSubtype || '');
};

export const cargoVesselFeatures = (type?: string) => {
  return [
    {
      props: {size: 2},
      content: [
        {label: 'Min size', field: getMinSize},
        {label: 'Max size', field: getMaxSize},
        {label: 'Geared', field: getNameForGeared},
      ],
    },
    {
      props: {size: 2},
      content: cargoVesselFeaturesContent(type),
    },
  ];
};

const cargoVesselFeaturesContent = (type?: string) => {
  switch (type) {
    case 'bulker':
      return [
        {label: 'Vessel type', field: getVesselType},
        {label: 'Max age', field: getMaxVesselAge},
      ];
    case 'container':
      return [
        {label: 'Vessel type', field: getVesselType},
        {label: 'Max age', field: getMaxVesselAge},
        {label: 'Min reefer plugs', field: 'reeferPlugs'},
      ];
    default:
      return [
        {label: 'Vessel type', field: 'vesselType', props: {upper: true}},
        {label: 'Max age', field: getMaxVesselAge},
        {label: 'Min reefer plugs', field: 'reeferPlugs'},
      ];
  }
};
