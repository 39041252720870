import {FeaturePermission} from '../../api/symfony/generated';

export const BackendScreenPermissions: FeaturePermission[] = [
  FeaturePermission.ManagePorts,
  FeaturePermission.ManageCompanies,
  FeaturePermission.ManageVessels,
  FeaturePermission.ViewEmailMonitor,
  FeaturePermission.ManageTradingAreas,
  FeaturePermission.ManagePorts,
  FeaturePermission.ManageUsers,
  FeaturePermission.ManageNews,
];
