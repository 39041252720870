/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Country, CountryFromJSON, CountryFromJSONTyped, CountryToJSON} from './';

/**
 *
 * @export
 * @interface CountryResponse
 */
export interface CountryResponse {
  /**
   *
   * @type {Array<Country>}
   * @memberof CountryResponse
   */
  countries?: Array<Country>;
}

export function CountryResponseFromJSON(json: any): CountryResponse {
  return CountryResponseFromJSONTyped(json, false);
}

export function CountryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    countries: !exists(json, 'countries') ? undefined : (json['countries'] as Array<any>).map(CountryFromJSON),
  };
}

export function CountryResponseToJSON(value?: CountryResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    countries: value.countries === undefined ? undefined : (value.countries as Array<any>).map(CountryToJSON),
  };
}
