import z from 'zod';

const LocationSchema = z.object({id: z.number(), type: z.string()});

const StationSchema = z.object({
  // id added for the workaround for https://seanexxt.atlassian.net/browse/FRIN-5051
  id: z.number().optional(),
  type: z.enum(['fixturedelivery', 'fixturedeliveryvia', 'fixtureredelivery']),
  locations: z.array(LocationSchema),
});

export type Station = z.infer<typeof StationSchema>;

const OptionSchema = z.object({
  id: z.number().nullish(),
  periodMin: z.number().nullish(),
  periodMax: z.number().nullish(),
  periodUnit: z.enum(['days', 'weeks', 'months', 'years']).nullish(),
  rate: z.number().nullish(),
  rateCurrency: z.string(),
  rateTerms: z.enum(['day', 'mt', 'cbm', 'lumpsum', 'teu', 'feu']),
  ladenLegsMin: z.number().nullish(),
  ladenLegsMax: z.number().nullish(),
});

export const UpdateFixtureRequestSchema = z.object({
  id: z.number().nullish(),
  vessel: z.object({id: z.number(), targetType: z.string()}).nullish(),
  cargo: z.object({id: z.number(), name: z.string().nullish()}).nullish(),
  fixtureDate: z.string(),
  vesselName: z.string().nullish(),
  vesselType: z.enum(['bulker', 'mpp', 'container']),
  designType: z.string().nullish(),
  designSubType: z.string().nullish(),
  dwat: z.number().nullish(),
  teuQuantity: z.number().nullish(),
  isGeared: z.boolean().nullish(),
  chartererName: z.string().nullish(),
  contractType: z.enum(['pc', 'tct', 'vc', 'bc', 'sale']),
  buyerName: z.string().nullish(),
  sellerName: z.string().nullish(),
  stations: z.array(StationSchema),
  laycanFrom: z.string().nullish(),
  laycanTo: z.string().nullish(),
  options: z.array(OptionSchema),
  commissionTotal: z.number().nullish(),
  broker: z.string().nullish(),
  redeliveryNotice: z.number().nullish(),
  isExtension: z.boolean(),
  isOptionDeclared: z.boolean(),
  source: z.string().nullish(),
  remark: z.string().nullish(),
  updateVessel: z.boolean().nullish(),
});

export type UpdateFixtureRequest = z.infer<typeof UpdateFixtureRequestSchema>;
export type Option = z.infer<typeof OptionSchema>;
