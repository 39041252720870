import {modal} from '../../../../../components/Notification';

export const confirmOverwriteImo = ({onOk, onCancel}: {onOk: () => void; onCancel: () => void}) => {
  const {destroy} = modal.confirm({
    title: 'Overwrite IMO?',
    content: (
      <>
        Do you want to overwrite the current IMO? <br />
        Press "OK" to confirm or "Cancel" to keep only the selected name.
      </>
    ),
    okButtonProps: {
      'data-testid': 'OverwriteImoModalOkButton',
    },
    cancelButtonProps: {
      'data-testid': 'OverwriteImoModalCancelButton',
    },
    onOk: () => {
      onOk();
      destroy();
    },
    onCancel: () => {
      onCancel();
      destroy();
    },
  });
};
