import {Statistic} from 'antd';
import styled from 'styled-components';
import {InterpolatedArrow} from './InterpolatedArrow';

export const OfferStatistics = styled(Statistic)`
  .ant-statistic-content-prefix {
    top: 0;
    position: relative;
  }

  .ant-statistic-content-value-int {
    font-size: var(--font-size-4xl);
  }
`;

interface Props {
  title: string;
  value: number | string;
  trend: number | null;
}

export const OfferStatisticsElement = ({title, value, trend}: Props) => {
  return (
    <FlexRow>
      <Cell>
        <Title>{`${title}: `}</Title>
      </Cell>
      <Cell>
        <Value>{value}</Value>
      </Cell>
      <Cell>
        <InterpolatedArrow size={16} trend={trend} />
      </Cell>
      <Divider>{'________'}</Divider>
    </FlexRow>
  );
};

const Cell = styled.div`
  display: flex;
  font-size: var(--font-size-md);
  padding: 0 6px 0 0;
  align-items: center;
  bottom: 1px;
  position: relative;
`;

const Title = styled.div`
  white-space: nowrap;
`;

const Value = styled.div`
  display: flex;
  font-weight: bold;
  font-size: var(--font-size-lg);
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-xl);
  padding: 0 6px 0 0;
`;

const Divider = styled.div`
  display: flex;
  font-size: var(--font-size-xl);
  padding: 0 6px 0 0;
  opacity: 0;
`;
