import React, {CSSProperties, FC} from 'react';
import classNames from 'classnames';

import {MarginMappingProps, withMarginMapping} from '../../components/withMargin';

import './style.scss';
import {TODO} from '../../utils/TODO';

interface Props extends MarginMappingProps {
  id?: string;
  label?: string | React.ReactElement;
  hidden?: boolean;
  style?: CSSProperties;
  contentWrapperStyle?: CSSProperties;
  flex?: boolean;
  hasError?: boolean;
  htmlFor?: string;
  required?: boolean;
  row?: TODO;
  children?: React.ReactNode;
}

export const LabelWrapper: FC<Props> = ({
  label,
  hidden = false,
  children,
  style,
  flex = false,
  hasError = false,
  htmlFor,
  required = false,
  row,
  contentWrapperStyle,
  ...rest
}) => {
  const [spaceClasses] = withMarginMapping(rest);
  return (
    <div
      className={classNames(
        'labeled-wrapper',
        {
          'labeled-wrapper--flex': flex,
          'labeled-wrapper--hasError': hasError,
          'labeled-wrapper--hidden': hidden,
          'labeled-wrapper--required': required,
          'labeled-wrapper--row': row,
        },
        spaceClasses
      )}
      style={style}>
      {label && (
        <label htmlFor={htmlFor} className="labeled-wrapper__label">
          {label}
        </label>
      )}
      {!!children && (
        <div className="labeled-wrapper__content" style={contentWrapperStyle}>
          {children}
        </div>
      )}
    </div>
  );
};

export default LabelWrapper;
