import React, {FC, useMemo} from 'react';
import {TermItem} from '../../TermItem/TermItem';
import {
  NegotiationSummary,
  NegotiationRoundResult,
  NegotiationStatus,
  NegotiationRoundResultModification,
  NegotiationTemplateSummary,
  NegotiationTemplate,
} from '../../../../../../api/node-backend/generated';
import {ListHeader} from '../ListHeader';
import {useLocalStorage} from '../../../../../../utils/useLocalStorage';
import sortBy from 'lodash/sortBy';
import {combineWithTermModification, getIsTermRejectedAndCommitted, getIsTermSettledAndCommitted} from '../utils';
import {Button} from 'antd';
import {EmptyBanner} from '../../../../Components/shared';
import {DragAndDropList} from '../../../../../../components/DragAndDropList/DragAndDropList';
import {TermActionsHook} from '../../TermItem/utils/useGetTermActionsWithMutations';
import {SubjectActionsHook} from '../../Subjects/utils/useGetSubjectActionsWithMutations';
import {NegotiationActions} from '../../CommitArea/utils/useGetNegotiationActionsWithMutations';
import {TermParserModalButton} from './TermParser/TermParserModalButton';
import {SelectContractTemplateModalButton} from './ContractTemplates/SelectContractTemplateModalButton';
import {HideTermsCheckbox, TermsContainer} from '../shared';

type TermsProps = {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  roundResultModifications: NegotiationRoundResultModification;
  templateSummaries: NegotiationTemplateSummary[];
  negotiationActions: NegotiationActions;
  isFirstRecapRound?: boolean;
  actionsBusy?: boolean;
  useGetTemplateDetail: (id?: number) => {data?: NegotiationTemplate};
  getTermActions: TermActionsHook;
  getSubjectActions: SubjectActionsHook;
};

export const Terms: FC<TermsProps> = ({
  negotiation,
  roundResult,
  roundResultModifications,
  templateSummaries,
  negotiationActions,
  isFirstRecapRound,
  actionsBusy,
  useGetTemplateDetail,
  getTermActions,
  getSubjectActions,
}) => {
  const {createTerms, updateSortIndex} = negotiationActions;

  const [hideSettledTerms, setHideSettledTerms] = useLocalStorage('negotiation.hideSettledTerms', true);

  const termsWithModifications = useMemo(() => {
    const {terms} = roundResult;
    let termsWithModifications = terms.map(combineWithTermModification(roundResultModifications));
    if (hideSettledTerms) {
      termsWithModifications = termsWithModifications.filter(item => !getIsTermSettledAndCommitted(item));
    }
    if (negotiation.status === NegotiationStatus.Recap) {
      termsWithModifications = termsWithModifications.filter(item => !getIsTermRejectedAndCommitted(item));
    }
    termsWithModifications = sortBy(termsWithModifications, ({term}) => term.sortIndex);
    return termsWithModifications;
  }, [roundResult, roundResultModifications, hideSettledTerms, negotiation.status]);

  const {status} = negotiation;
  const {committedAt} = roundResult;
  const canEditTerms = committedAt === null;
  const canAddTerms = canEditTerms && ![NegotiationStatus.Cancelled, NegotiationStatus.Settled].includes(status);
  const isOfferOrSettled = [NegotiationStatus.Offer, NegotiationStatus.Settled, NegotiationStatus.Cancelled].includes(
    status
  );

  if (roundResult.terms.length === 0) {
    return (
      <EmptyBanner
        data-testid="TermAreaBodyEmpty"
        block
        hideImage
        headline="No terms yet"
        subHeadline={
          canAddTerms ? (
            <>
              <SelectContractTemplateModalButton
                negotiation={negotiation}
                templateSummaries={templateSummaries}
                useGetTemplateDetail={useGetTemplateDetail}
                onCreateTerms={createTerms}
              />
              <span>- or -</span>
              <TermParserModalButton onCreateTerms={createTerms} />
            </>
          ) : null
        }
      />
    );
  }

  return (
    <TermsContainer data-testid="Terms">
      <ListHeader
        additionalContent={
          !isOfferOrSettled ? (
            <HideTermsCheckbox checked={hideSettledTerms} onChange={event => setHideSettledTerms(event.target.checked)}>
              Hide agreed terms
            </HideTermsCheckbox>
          ) : null
        }
      />
      {termsWithModifications.length === 0 ? (
        <EmptyBanner
          data-testid="TermAreaBodyEmptyFiltered"
          block
          hideImage
          headline="No actionable terms"
          subHeadline={
            <Button type="primary" onClick={() => setHideSettledTerms(false)}>
              Show all terms
            </Button>
          }
        />
      ) : (
        <DragAndDropList
          items={termsWithModifications.map(item => ({...item, id: item.term.id}))}
          canUpdateSort={canEditTerms}
          onUpdateSort={updateSortIndex}
          renderTermItem={(idx, {term, termModifications}, dragListeners) => (
            <TermItem
              negotiation={negotiation}
              roundResult={roundResult}
              term={term}
              termModifications={termModifications}
              isFirstRecapRound={isFirstRecapRound}
              dragListeners={dragListeners}
              negotiationActions={negotiationActions}
              actionsBusy={actionsBusy}
              getTermActions={getTermActions}
              getSubjectActions={getSubjectActions}
            />
          )}
        />
      )}
    </TermsContainer>
  );
};
