import {useState} from 'react';
import styled from 'styled-components';
import {DatePicker, Form, InputNumber} from 'antd';
import dayjs from 'dayjs';
import Icon from '../../../../../../../atoms/Icon';
import {assert} from '../../../../../../../utils/assert';

const RangePicker = DatePicker.RangePicker;

type DurationFieldProps = {
  value?: number;
  maxLength: number;
  placeholder: string;
  style?: React.CSSProperties;
  onChange?: (v: number | null) => void;
};

export const DurationField = ({value, onChange, placeholder, style, maxLength}: DurationFieldProps) => {
  const [open, setOpen] = useState(false);

  const laycanFrom = Form.useWatch('laycanFrom');

  const laycanFromDayjs = laycanFrom ? dayjs(laycanFrom) : dayjs();

  return (
    <StyledInput
      style={style}
      value={value}
      placeholder={placeholder}
      precision={0}
      onChange={value => {
        assert(value === null || typeof value === 'number');
        onChange?.(value);
      }}
      maxLength={maxLength}
      addonAfter={
        <>
          <Icon
            type={'date'}
            size={'small'}
            color={'blue'}
            style={{cursor: 'pointer'}}
            onClick={() => {
              setOpen(true);
            }}
            data-cy="DurationPickerButton"
          />
          <div
            style={{
              overflow: 'hidden',
              width: 0,
              left: -60,
              position: 'absolute',
              height: 0,
              display: 'inline-block',
            }}>
            <RangePicker
              defaultValue={[laycanFromDayjs, null]}
              open={open}
              onOpenChange={setOpen}
              onChange={dateRange => {
                const start = dateRange ? dateRange[0] : null;
                const end = dateRange ? dateRange[1] : null;
                if (start && end) {
                  const durationInDays = end.diff(start, 'days');
                  onChange?.(durationInDays);
                }
                setOpen(false);
              }}
            />
          </div>
        </>
      }
    />
  );
};

const StyledInput = styled(InputNumber)`
  .ant-input-group-addon {
    padding: 4px;
  }

  text-align: right;
`;
