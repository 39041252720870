import {FC} from 'react';
import {Route, Routes} from 'react-router-dom';
import {NotFound} from '../../atoms/EmptyState/404';
import {NegotiationOverviewScreen} from './NegotiationOverview/NegotiationOverviewScreen';
import {NegotiationDetailScreen} from './NegotiationDetail/NegotiationDetailScreen';
import {NegotiationTemplatesScreen} from './NegotiationTemplates/NegotiationTemplatesScreen';
import {PremiumRoute} from '../../components/routes/PremiumRoute';
import {FeaturePermission} from '../../api/symfony/generated';
import {NegotiationCreateScreen} from './NegotiationCreate/NegotiationCreateScreen';
import {NegotiationTutorialScreen} from './NegotiationTutorialScreen/NegotiationTutorialScreen';

export const NegotiationScreens: FC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PremiumRoute requiredPermission={FeaturePermission.ListNegotiations}>
          <NegotiationOverviewScreen />
        </PremiumRoute>
      }
    />
    <Route
      path="/create"
      element={
        <PremiumRoute requiredPermission={FeaturePermission.CreateNegotiation}>
          <NegotiationCreateScreen />
        </PremiumRoute>
      }
    />
    <Route path="/tutorial" element={<NegotiationTutorialScreen />} />
    <Route path="/templates" element={<NegotiationTemplatesScreen />} />
    <Route path="/:negotiationId" element={<NegotiationDetailScreen />} />
    <Route path="*" element={<NotFound redirectTarget={{label: 'Back to negotiations', href: '/negotiations'}} />} />
  </Routes>
);
