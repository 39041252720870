import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {useDispatch} from '../../../redux/react-redux';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {ApiError} from '../../../api/utils/ApiError';

export type CompanyVerifyRequestBody = {isVerified?: boolean; isOnboarded?: boolean};

interface UpdateCompanyInformation {
  id: number;
  updatedCompanyInformation: CompanyVerifyRequestBody;
}

export const useUpdateCompanyMutation = (options: UseMutationOptions<void, ApiError, UpdateCompanyInformation>) => {
  const dispatch = useDispatch();
  const updateCompany = (id: number, data: CompanyVerifyRequestBody) =>
    dispatch(CompanyActions.updateCompany(id, data));

  return useMutation({
    mutationFn: async ({id, updatedCompanyInformation}) => await updateCompany(id, updatedCompanyInformation),
    ...options,
  });
};
