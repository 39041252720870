import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  VesselDatabaseDatabaseFilter,
  VesselDatabaseFilterBranchDefinitions,
} from '../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';
import {VesselGridLayoutMode, VesselGridLayoutModeSchema} from './Portfolio';
import {FilterItem} from '../api/symfony/generated';
import findIndex from 'lodash/findIndex';
import {FilterProviderState} from '../components/FilterProvider/FilterProviderState';
import {ColumnSort} from '@tanstack/react-table';
import {getLocalStorage, setLocalStorage} from '../utils/useLocalStorage';

export type TabId = 'all' | number;

interface VesselDatabaseState {
  pageIndex: number;
  pageSize: number;
  sortingRule: ColumnSort;
  activeFilterId: TabId;
  userSelectedLayoutMode: VesselGridLayoutMode;
  filterSettings: VesselDatabaseDatabaseFilter;
  filterProviderState: FilterProviderState<typeof VesselDatabaseFilterBranchDefinitions> | null;
  isFilterUiOpen: boolean;
  mapIsOpen: boolean;
  filters: FilterItem[];
}

const COOKIE_BASE_NAME = 'vessel-database-grid';

const DEFAULT_PAGE_SIZE = 10;

const getPageSizeFromCookie = () => getLocalStorage(`${COOKIE_BASE_NAME}-page-size`, DEFAULT_PAGE_SIZE);

const setPageSizeCookie = (pageSize: number) => setLocalStorage(`${COOKIE_BASE_NAME}-page-size`, pageSize);

const getVesselGridLayoutCookie = () =>
  getLocalStorage<VesselGridLayoutMode>(`${COOKIE_BASE_NAME}-grid_layout`, 'auto', VesselGridLayoutModeSchema);

const setVesselGridLayoutCookie = (layout: VesselGridLayoutMode) =>
  setLocalStorage(`${COOKIE_BASE_NAME}-grid_layout`, layout);

const initialState: VesselDatabaseState = {
  pageIndex: 1,
  pageSize: getPageSizeFromCookie(),
  sortingRule: {id: 'dwtSummer', desc: true},
  userSelectedLayoutMode: getVesselGridLayoutCookie(),
  activeFilterId: 'all',
  filterSettings: {},
  filterProviderState: null,
  isFilterUiOpen: true,
  mapIsOpen: true,
  filters: [],
};

interface UpdateSortKeyPayload {
  id: number;
  newSortKey: number;
}

const vesselDatabaseSlice = createSlice({
  name: 'VesselDatabase',
  initialState,
  reducers: {
    setPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      setPageSizeCookie(action.payload);
    },
    setSortingRule(state, action: PayloadAction<ColumnSort>) {
      state.sortingRule = action.payload;
    },
    setUserSelectedLayoutMode(state, action: PayloadAction<VesselGridLayoutMode>) {
      state.userSelectedLayoutMode = action.payload;
      setVesselGridLayoutCookie(action.payload);
    },
    setFilterSettings(state, action: PayloadAction<VesselDatabaseDatabaseFilter>) {
      state.filterSettings = action.payload;
    },
    setFilterProviderState(
      state,
      action: PayloadAction<FilterProviderState<typeof VesselDatabaseFilterBranchDefinitions>>
    ) {
      state.filterProviderState = action.payload;
    },
    setActiveFilterId(state, action: PayloadAction<TabId>) {
      state.activeFilterId = action.payload;
    },
    setFilterUiOpen(state, action: PayloadAction<boolean>) {
      state.isFilterUiOpen = action.payload;
    },
    setMapIsOpen(state, action: PayloadAction<boolean>) {
      state.mapIsOpen = action.payload;
    },
    setFilters(state, action: PayloadAction<FilterItem[]>) {
      state.filters = action.payload;
    },
    addFilter(state, action: PayloadAction<FilterItem>) {
      state.filters.push(action.payload);
      state.activeFilterId = action.payload.id;
    },
    deleteFilter(state, action: PayloadAction<number>) {
      const index = findIndex(state.filters, {id: action.payload});
      if (index >= 0) {
        state.filters.splice(index, 1);
        state.activeFilterId = 'all';
      }
    },
    updateFilter(state, action: PayloadAction<FilterItem>) {
      const index = findIndex(state.filters, {id: action.payload.id});
      if (index >= 0) {
        state.filters[index] = action.payload;
      }
    },
    updateFilterSortKey(state, action: PayloadAction<UpdateSortKeyPayload>) {
      const index = findIndex(state.filters, {id: action.payload.id});
      if (index >= 0) {
        state.filters[index].sortKey = action.payload.newSortKey;
      }
    },
  },
});

export const vesselDatabaseReducer = vesselDatabaseSlice.reducer;
export const VesselDatabaseActions = vesselDatabaseSlice.actions;
