import {Dropdown, Menu, MenuProps} from 'antd';
import {VoyagePoint} from '../../../VoyageTypes';
import {DownOutlined} from '@ant-design/icons/lib';
import {AlternativePortIcon} from './AlternativePortIcon';
import styled from 'styled-components';

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: var(--border-base);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);
  padding: 4px;
  height: 32px;
  width: 44px;
`;

export const SelectAlternativePort = (props: {point: VoyagePoint; onChange: (point: Partial<VoyagePoint>) => void}) => {
  if (!props.point.alternativePorts) {
    return null;
  }

  const menuItems: MenuProps['items'] = props.point.alternativePorts!.map(port => {
    return {
      key: port.coordinates.join('_'),
      onClick: () => {
        const change: Partial<VoyagePoint> = {
          coordinates: port.coordinates,
          countryCode: port.countryCode,
          name: port.name,
          rawTerm: port.rawTerm,
        };

        if (props.point.types.includes('loading')) {
          change.loadingRate = port.loadingRate;
          change.termsLoading = port.termsLoading;
        }
        if (props.point.types.includes('discharge')) {
          change.dischargingRate = port.dischargingRate;
          change.termsDischarging = port.termsDischarging;
        }

        props.onChange(change);
      },
      label: port.name,
    };
  });

  return (
    <span style={{paddingRight: 16}}>
      <Dropdown
        trigger={['hover']}
        menu={{
          items: [
            {
              key: '1',
              label: <Menu items={menuItems} />,
            },
          ],
        }}>
        <IconBox>
          <AlternativePortIcon /> <DownOutlined />
        </IconBox>
      </Dropdown>
    </span>
  );
};
