import {QueryClient, useQuery} from '@tanstack/react-query';
import {TagType} from '../api/symfony/generated';
import {tagApi} from '../api/symfony/symfonyApi';

const AvailableTagsQueryKey = 'tagApi.listTags';
export const useAvailableTagsQuery = ({type}: {type: TagType}) => {
  return useQuery({
    queryKey: [AvailableTagsQueryKey, type],
    queryFn: async () => await tagApi.listTags({type: type}),
  });
};

export const invalidateAvailableTagsQuery = async (queryClient: QueryClient, type: TagType) => {
  await queryClient.invalidateQueries({
    queryKey: [AvailableTagsQueryKey, type],
  });
};
