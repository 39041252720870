import {VesselConsumptionMode, VesselConsumptionModeUseCase, VesselInput} from '../VesselInformation/VesselTypes';
import {loadNextOpenLocation} from './loadNextOpenLocation';
import {defaultRemainingOnBoards} from '../Context/blankState';
import {TODO} from '../../../../utils/TODO';

type Vessel = TODO;

type Params = {
  vessel: Vessel;
};

const cftToCbmMultiplicator = 0.0283168;

const getCapacity = (unit: string | null, value: number | null): undefined | number => {
  if (!value || !unit) {
    return undefined;
  }
  return unit === 'cbm' ? value : Math.round(value * cftToCbmMultiplicator);
};

export const mapVesselToVesselInput = async (params: Params): Promise<VesselInput> => {
  const data: VesselInput = {
    remainingOnBoards: defaultRemainingOnBoards,
    consumptionModes: [],
    isScrubberFitted: false,
  };

  data.isScrubberFitted = !!params.vessel.featureScrubberFitted;

  // consumptions = consumptions modes like portworking,balast ,laden
  data.consumptionModes = (params.vessel.consumptions ?? []).map((consumptionMode: TODO) => {
    let useCase = consumptionMode.type.toLowerCase();

    const isEco = useCase.endsWith('eco');
    if (isEco) {
      useCase = useCase.slice(0, -3);
    }

    const consumption: VesselConsumptionMode = {
      speed: consumptionMode.speed,
      useCase: useCase as VesselConsumptionModeUseCase,

      mainConsumption: consumptionMode.fuelConsumption,
      mainFuelType: consumptionMode.extraFuelType,

      auxConsumption: consumptionMode.extraFuelConsumption,
      auxFuelType: consumptionMode.extraFuelType,
    };
    consumption.mainConsumption = consumptionMode.fuelConsumption;
    consumption.mainFuelType = consumptionMode.fuelType;

    consumption.mainFuelType = consumption.mainFuelType ?? 'ifo';
    consumption.auxFuelType = consumption.auxFuelType ?? 'mdo';

    return consumption;
  });

  const grainCapacity = getCapacity(params.vessel.grainUnit, params.vessel.grain);
  const baleCapacity = getCapacity(params.vessel.balueUnit, params.vessel.bale);
  data.capacity = baleCapacity ?? grainCapacity;

  data.dwat = params.vessel.dwtSummer;

  data.imo = params.vessel.imo;

  data.openDate = params.vessel.nextOpenFrom ?? new Date().toISOString();

  data.id = params.vessel.id;

  data.name = params.vessel.name;

  data.countryCode = params.vessel.countryFlag;
  data.nextOpenPoint = await loadNextOpenLocation({vessel: params.vessel});

  return data;
};
