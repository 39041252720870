import {useCallback, useEffect, useState} from 'react';
import {Alert} from 'antd';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {ProfileForm} from './ProfileForm';
import {ProfileFormValues} from './ProfileFormValues';
import animateScrollTo from 'animated-scroll-to';
import {accountManagementApi} from '../../../api/symfony/symfonyApi';
import {ContactDetails} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';
import {Helmet} from 'react-helmet-async';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';
import {showNotification} from '../../../utils/notification';

const QUERY_KEY = 'account-management/profile';

export const Profile = () => {
  const [formKey, setFormKey] = useState(1);

  const resetForm = useCallback(() => {
    setFormKey(formKey + 1);
  }, [formKey, setFormKey]);

  // The query the loads the data to be edited
  const queryResult = useQuery<ContactDetails, ApiError>({
    queryKey: [QUERY_KEY],
    queryFn: (): Promise<ContactDetails> => accountManagementApi.getContactDetails(),
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  // The mutation that saves the data to be edited
  const mutation = useMutation({
    mutationFn: (contactDetails: ContactDetails): Promise<ContactDetails> =>
      accountManagementApi.updateContactDetails({contactDetails}),

    onSuccess: async () => {
      // Reload profile data
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });

      await showNotification('Your settings have been saved');
      // Scroll to top in order to show the success message

      await animateScrollTo(0);
    },

    onError: async () => {
      // Scroll to top in order to show the error message
      await animateScrollTo(0);
    },
  });

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.data, queryResult.isSuccess]);

  if (queryResult.isLoading) {
    return <span style={{visibility: 'hidden'}}>Loading profile data</span>;
  }

  if (queryResult.isError) {
    if (!queryResult.error) {
      throw new Error('Missing error');
    }
    return <span>Error: {queryResult.error.message}</span>;
  }

  if (!queryResult.data) {
    throw new Error('Missing data');
  }

  const initialValues: ProfileFormValues = getInitialValues(queryResult.data);

  const onSubmit = async (formValues: ProfileFormValues): Promise<void> => {
    const request = getSaveProfileRequest(formValues);
    await mutation.mutate(request);
  };

  return (
    <>
      <Helmet title="Profile - Account management" />
      <div className="Profile">
        <SettingsCard>
          <SettingsCardHeader title={'Profile'} />

          <SettingsCardBody>
            <Alert
              message="Personal information entered on this page is displayed on your profile to other users."
              type="warning"
            />
            <br />

            <ProfileForm
              key={formKey}
              initialValues={initialValues}
              onSubmit={onSubmit}
              isSubmitting={mutation.isPending}
              submitError={mutation.error}
            />
          </SettingsCardBody>
        </SettingsCard>
      </div>
    </>
  );
};

const getInitialValues = (data: ContactDetails): ProfileFormValues => ({
  street: data.street ?? '',
  streetNumber: data.streetNumber ?? '',
  zipCode: data.zipCode ?? '',
  city: data.city ?? '',
  country: data.country ?? '',
  website: data.website ?? '',
  telephone: data.telephone ?? '',
  fax: data.fax ?? '',
  skype: data.skype ?? '',
});

const getSaveProfileRequest = (formValues: ProfileFormValues): ContactDetails => ({
  ...formValues,
});
