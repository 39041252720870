import {Button, Checkbox, Form, Typography} from 'antd';
import {FC} from 'react';
import {useGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';
import styled from 'styled-components';
import forEach from 'lodash/forEach';
import {parseIntTS} from '../../../utils/parseNumberTS';
import {Center} from '../../Center/Center';
import {ModalFooter} from '../../../antd/components/ModalFooter';

const {Paragraph} = Typography;

export type FormValues = {
  subPortfolios: Record<number, boolean>;
};

export const ManageSubPortfolioForm: FC<{
  type: 'cargo' | 'vessel';
  saving: boolean;
  initialValues?: FormValues;
  onFinish: (values: FormValues) => void;
}> = ({initialValues, saving, type, onFinish}) => {
  const subPortfoliosQuery = useGetSubPortfoliosQuery({type: type});

  const subPortfolios = subPortfoliosQuery.data?.data.items ?? [];
  return (
    <Form<FormValues> initialValues={initialValues} onFinish={onFinish}>
      <div className={'subPortfolios__data'}>
        <Paragraph style={{textAlign: 'center'}}>You can add and remove via this modal.</Paragraph>
        <br />
        <Center>
          <div style={{display: 'inline-flex', flexDirection: 'column'}}>
            {subPortfolios.length ? (
              subPortfolios.map(subPortfolio => (
                <StyledFormItem valuePropName={'checked'} name={['subPortfolios', subPortfolio.id]}>
                  <Checkbox id={`${subPortfolio.id}-${subPortfolio.name}`} style={{}}>
                    {subPortfolio.name}
                  </Checkbox>
                </StyledFormItem>
              ))
            ) : (
              <span>You have no {subPortfolios ? 'cargo lists.' : 'vessel lists.'}</span>
            )}
          </div>
        </Center>
      </div>
      <ModalFooter>
        <Button htmlType={'submit'} type="primary" loading={saving}>
          Manage
        </Button>
      </ModalFooter>
    </Form>
  );
};

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
  .ant-form-item-control-input {
    min-height: auto;
  }
`;

export const transformPortfolioIdListToFormValues = (portfolioIdList: number[]): FormValues => {
  const subPortfolios: Record<number, boolean> = {};

  portfolioIdList.forEach(subPortfolioId => {
    subPortfolios[subPortfolioId] = true;
  });

  return {subPortfolios};
};

export const transformFormValuesToPortfolioIdList = (formValues: FormValues): number[] => {
  const selectedSubPortfolios: number[] = [];

  forEach(formValues.subPortfolios, (subPortfolioIsSelected, subPortfolioId) => {
    if (subPortfolioIsSelected) {
      selectedSubPortfolios.push(parseIntTS(subPortfolioId));
    }
  });
  return selectedSubPortfolios;
};
