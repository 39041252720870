import {FC, isValidElement} from 'react';
import DetailsSection from '../DetailsSection';
import DetailsColumn from '../DetailsSection/DetailsColumn';
import {Attribute, TabSize} from '../Attribute/Attribute';
import {getCargoContractTypeLabel} from '../../utils/helper';
import Cargo from '../../model/Cargo';

type Props = {
  detailObject: $TSFixMe;
  columns: $TSFixMe[];
  label?: string;
  hasBorderBottom?: boolean;
  size?: number;
  tab?: TabSize;
  vertical?: boolean;
};

export const MultiColAttributesSection = ({columns, label, detailObject, tab, size = 4, ...otherProps}: Props) => (
  <DetailsSection {...otherProps} title={label}>
    {columns.map((col, key) => {
      const props = col.props || {};
      return (
        <DetailsColumn {...props} key={`multi-col-${key}`} size={size}>
          <ColumnContent detailObject={detailObject} content={col.content} tab={tab} />
        </DetailsColumn>
      );
    })}
  </DetailsSection>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValue = (attr: Record<string, any>, detailObject: any): any => {
  if (typeof attr.field === 'function') {
    return attr.field(detailObject);
  }

  if (attr.field === 'contractType') {
    return getCargoContractTypeLabel(detailObject[attr.field]);
  }
  return detailObject[attr.field];
};

type ContentAttr = {
  label: string;
  props: Record<string, number | string>;
  subString?: keyof Cargo;
  exactUnit?: boolean;
  unit?: keyof Cargo;
};

type ColumnContentProps = {
  detailObject: Cargo;
  content: ContentAttr[];
  vertical?: boolean;
  tab?: TabSize;
};

export const ColumnContent: FC<ColumnContentProps> = ({detailObject, content, vertical, tab}) => {
  return (
    <>
      {content.map((attr: ContentAttr, key: $TSFixMe) => {
        const props = attr.props || {};
        const value = getValue(attr, detailObject);
        let unit = attr.exactUnit && value ? attr.exactUnit : null;
        if (!unit) {
          unit = attr.subString && value ? detailObject[attr.subString] : null;
        }
        if (!unit) {
          unit = attr.unit && value ? detailObject[attr.unit] : null;
        }

        const isReactComponent = isValidElement(value);
        const visibleLabel = isReactComponent ? value : `${value}${unit ? ' ' + unit : ''}`;

        return (
          <Attribute
            key={`${attr.label}-${key}`}
            label={attr.label}
            value={value && visibleLabel}
            vertical={vertical}
            tab={tab}
            {...props}
          />
        );
      })}
    </>
  );
};
