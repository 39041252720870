/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface MailInfo
 */
export interface MailInfo {
  /**
   *
   * @type {number}
   * @memberof MailInfo
   */
  sent?: number;
  /**
   *
   * @type {string}
   * @memberof MailInfo
   */
  to?: string;
  /**
   *
   * @type {string}
   * @memberof MailInfo
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof MailInfo
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof MailInfo
   */
  content?: string;
}

export function MailInfoFromJSON(json: any): MailInfo {
  return MailInfoFromJSONTyped(json, false);
}

export function MailInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    sent: !exists(json, 'sent') ? undefined : json['sent'],
    to: !exists(json, 'to') ? undefined : json['to'],
    from: !exists(json, 'from') ? undefined : json['from'],
    subject: !exists(json, 'subject') ? undefined : json['subject'],
    content: !exists(json, 'content') ? undefined : json['content'],
  };
}

export function MailInfoToJSON(value?: MailInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    sent: value.sent,
    to: value.to,
    from: value.from,
    subject: value.subject,
    content: value.content,
  };
}
