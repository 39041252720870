import styled from 'styled-components';

export const NegotiationTermAreaSidebarContainer = styled.div<{$position: 'left' | 'right'}>`
  position: absolute;
  top: 0;
  bottom: 0;
  ${({$position}) => $position}: calc(-1 * var(--sidebar-width) + 1px);
  width: calc(var(--sidebar-width) - 12px);
`;

export const StickySideBarContainer = styled.div`
  position: sticky;
  top: 185px;
  bottom: 30px;
  z-index: 3;
  margin: 55.5px 0 0;
`;

export const SidebarActionsLabel = styled.div`
  padding: 6px 9px;
  background: var(--transparent-lighter);
  text-align: center;
  font-weight: bold;
  border-radius: var(--border-radius-tab);
`;

export const SidebarCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 10px 0 40px;
  padding: 8px;
  background: var(--color-white);
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-tab);
`;
