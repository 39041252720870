import {numberFormat, numberUnitFormat} from '../../../utils/formatter';

class displayGridUtils {
  static getIntake = (intakes: $TSFixMe, search: $TSFixMe) => {
    let intakeValue = 0;
    intakes &&
      intakes.forEach((e: $TSFixMe) => {
        if (e.type === search) {
          intakeValue += e.quantity;
        }
      });
    return numberFormat(intakeValue, {emptyString: '-'});
  };

  static getOpenLocationsPorts = (stations: $TSFixMe, type: $TSFixMe) => {
    const linkedLocations = [];
    stations &&
      stations.forEach((station: $TSFixMe) => {
        if (station.type === type) {
          station.locations.forEach((location: $TSFixMe) => {
            if (location.type === 'port' || location.type === 'unknown') {
              linkedLocations.push(location.name);
            }
          });
        }
      });
    if (linkedLocations.length === 0) {
      linkedLocations.push('-');
    }
    return linkedLocations;
  };

  static getOpenLocations = (stations: $TSFixMe, type: $TSFixMe) => {
    const linkedLocations = [];
    stations &&
      stations.forEach((station: $TSFixMe) => {
        if (station.type === type) {
          station.locations.forEach((location: $TSFixMe) => {
            if (location.type === 'country' || location.type === 'tradingarea') {
              linkedLocations.push(location.name);
            }
          });
        }
      });
    if (linkedLocations.length === 0) {
      linkedLocations.push('-');
    }
    return linkedLocations;
  };

  static getVesselPeriodRate = ({rate, rateCurrency, rateTerms}: $TSFixMe) => {
    let result = '-';
    if (rate && rateCurrency) {
      result = numberUnitFormat(rate, rateCurrency, {unitSpace: true});
      if (rateTerms) {
        if (rateTerms !== 'lumpsum') {
          result += ' per';
        }
        result += ' ' + rateTerms;
      }
    }
    return result;
  };
}

export default displayGridUtils;
