import {FC} from 'react';
import * as VesselDetailsActions from '../../redux/VesselDetails';
import Icon from '../../atoms/Icon';
import Lighthouse from '../../atoms/EmptyState/LightHouse';
import Country from '../../model/Country';
import {CountryLocation} from '../../components/LocationOutput/LocationOutputRow';
import {UserDateTime} from '../../components/DateTime/UserDateTime';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {VesselHistoryParams} from '../../redux/VesselDetails';
import {StyledCard} from './shared';
import {useLocalStorage} from '../../utils/useLocalStorage';
import {DataGrid} from '../../components/DataGrid/DataGrid';

export const VesselHistory: FC<{vesselId: number}> = ({vesselId}) => {
  const [, setLocalStoragePageSize] = useLocalStorage('grid_page_size-vessel_details_history', 25);
  const vesselHistory = useSelector(state => state.vesselDetails.vesselHistory);
  const vesselHistoryLoading = useSelector(state => state.api.vessels.getVesselHistory.loading);
  const dispatch = useDispatch();
  const setVesselHistoryParams = (params: VesselHistoryParams) =>
    dispatch(VesselDetailsActions.setVesselHistoryParams(params));

  const emptyResult = !vesselHistoryLoading && vesselHistory.items!.length === 0;
  if (emptyResult) {
    return <Lighthouse headline={'No vessel history yet'} style={{marginTop: 0}} />;
  }

  return (
    <StyledCard>
      <div style={{minHeight: '200px'}}>
        <DataGrid
          className="datagrid-vessel-history"
          columns={[
            {
              header: 'Attribute',
              id: 'fieldName',
              cell: info => info.getValue(),
              accessorFn: d => <span>{(d as $TSFixMe).fieldName.toUpperCase()}</span>,
              minWidth: 3,
              enableSorting: true,
            },
            {
              header: () => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <span>From</span>
                  <Icon className={'arrow'} type={'arrow-right'} size={'medium-small'} />
                  <span>To</span>
                </div>
              ),
              id: 'fieldValueFrom',
              cell: info => info.getValue(),
              accessorFn: d => {
                return (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {(d as $TSFixMe).fieldName === 'flag' ? (
                      <CountryLocation
                        location={{
                          name: Country.getName((d as $TSFixMe).fieldValueFrom),
                          code: (d as $TSFixMe).fieldValueFrom,
                        }}
                      />
                    ) : (
                      <span>{(d as $TSFixMe).fieldValueFrom || '-'}</span>
                    )}
                    <Icon className={'arrow'} type={'arrow-right'} size={'medium-small'} />
                    {(d as $TSFixMe).fieldName === 'flag' ? (
                      <CountryLocation
                        location={{
                          name: Country.getName((d as $TSFixMe).fieldValueTo),
                          code: (d as $TSFixMe).fieldValueTo,
                        }}
                      />
                    ) : (
                      <span>{(d as $TSFixMe).fieldValueTo || '-'}</span>
                    )}
                  </div>
                );
              },
              minWidth: 7,
              enableSorting: false,
            },
            {
              header: 'Changed at',
              id: 'createdAt',
              cell: info => info.getValue(),
              accessorFn: ({createdAt}) => <UserDateTime value={createdAt} format={'dateTime'} />,
              minWidth: 2,
              enableSorting: true,
            },
          ]}
          data={vesselHistory.items}
          loading={vesselHistoryLoading}
          zeroBasedPageIndex={false}
          onPageChange={pageIndex => setVesselHistoryParams({pageIndex, vesselId})}
          onPageSizeChange={pageSize => {
            setLocalStoragePageSize(pageSize);
            setVesselHistoryParams({pageSize, vesselId, pageIndex: 1});
          }}
          onSortedChange={sorted => setVesselHistoryParams({vesselId, sorted: [sorted], pageIndex: 1})}
          page={vesselHistory.pageIndex}
          pageSize={vesselHistory.pageSize}
          totalCount={vesselHistory.totalItems}
          sorted={vesselHistory.sorted}
        />
      </div>
    </StyledCard>
  );
};
