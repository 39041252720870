import cx from 'classnames';
import React, {FC} from 'react';
import {vesselMenu, VesselTypeKey} from './utils/vesselMenu';

const getPath = ({type, vesselIconStyle}: {type: VesselTypeKey; vesselIconStyle: string}) =>
  `/static/icon/seabo_map_icons/${vesselMenu[type]}-${vesselIconStyle}.svg`;

export const MapVesselIcon: FC<{
  label: string;
  vesselIconStyle?: string;
  type: VesselTypeKey;
  iconCSS?: React.CSSProperties;
}> = ({label, type, vesselIconStyle = 'empty', iconCSS}) => {
  return (
    <span className={cx('map-option-vessel', type && `map-option-vessel--${type}`)}>
      <img style={{width: 20, height: 20, ...iconCSS}} src={getPath({type, vesselIconStyle})} /> {label}
    </span>
  );
};
