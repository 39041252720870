import styled from 'styled-components';
import {Button} from 'antd';

export const StyledMapCtrlButton = styled(Button)`
  line-height: 1;
  display: flex;
  justify-content: center;
  padding: 0px;
  align-items: center;
  width: 40px;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  border: none;
  border-radius: var(--border-radius-button);
  background-color: var(--color-blue-dark) !important;
  color: var(--color-white);
  margin-bottom: 4px;
  cursor: pointer !important;
  transition: none;

  &.active > i.icon {
    color: white !important;
  }

  &.isOpen {
    background-color: var(--color-blue) !important;
  }

  &:hover {
    background-color: var(--color-blue);
  }
  &.ant-dropdown-open {
    i {
      color: white !important;
    }
    background-color: var(--color-blue) !important;
  }
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;
