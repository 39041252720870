/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {AreaType, AreaTypeFromJSON, AreaTypeFromJSONTyped, AreaTypeToJSON} from './';

/**
 *
 * @export
 * @interface AreaVisit
 */
export interface AreaVisit {
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  portId: number | null;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  upriverId: number | null;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  highRiskAreaId: number | null;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  tradingAreaId: number | null;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  chokepointId: number | null;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  pierId: number | null;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  anchorageId: number | null;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  dryDockId: number | null;
  /**
   *
   * @type {AreaType}
   * @memberof AreaVisit
   */
  areaType: AreaType;
  /**
   *
   * @type {number}
   * @memberof AreaVisit
   */
  imo: number;
  /**
   * Time of entering the port
   * @type {Date}
   * @memberof AreaVisit
   */
  enterTime: Date | null;
  /**
   * Time of exiting the port
   * @type {Date}
   * @memberof AreaVisit
   */
  exitTime: Date | null;
  /**
   * Draft of the vessel when entering the port
   * @type {number}
   * @memberof AreaVisit
   */
  enterDraft: number | null;
  /**
   * Draft of the vessel when exiting the port
   * @type {number}
   * @memberof AreaVisit
   */
  exitDraft: number | null;
  /**
   * Difference in draft when entering and exiting the port
   * @type {number}
   * @memberof AreaVisit
   */
  draftDifference: number | null;
  /**
   * Heading of the vessel when entering the port
   * @type {number}
   * @memberof AreaVisit
   */
  enterHeading: number | null;
  /**
   * Heading of the vessel when exiting the port
   * @type {number}
   * @memberof AreaVisit
   */
  exitHeading: number | null;
  /**
   * Speed of the vessel when entering the port
   * @type {number}
   * @memberof AreaVisit
   */
  enterSpeed: number | null;
  /**
   * Speed of the vessel when exiting the port
   * @type {number}
   * @memberof AreaVisit
   */
  exitSpeed: number | null;
  /**
   * Course of the vessel when entering the port
   * @type {number}
   * @memberof AreaVisit
   */
  enterCourse: number | null;
  /**
   * Course of the vessel when exiting the port
   * @type {number}
   * @memberof AreaVisit
   */
  exitCourse: number | null;
  /**
   * Duration of the visit in minutes
   * @type {number}
   * @memberof AreaVisit
   */
  duration: number | null;
}

export function AreaVisitFromJSON(json: any): AreaVisit {
  return AreaVisitFromJSONTyped(json, false);
}

export function AreaVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaVisit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    portId: json['portId'],
    upriverId: json['upriverId'],
    highRiskAreaId: json['highRiskAreaId'],
    tradingAreaId: json['tradingAreaId'],
    chokepointId: json['chokepointId'],
    pierId: json['pierId'],
    anchorageId: json['anchorageId'],
    dryDockId: json['dryDockId'],
    areaType: AreaTypeFromJSON(json['areaType']),
    imo: json['imo'],
    enterTime: json['enterTime'],
    exitTime: json['exitTime'],
    enterDraft: json['enterDraft'],
    exitDraft: json['exitDraft'],
    draftDifference: json['draftDifference'],
    enterHeading: json['enterHeading'],
    exitHeading: json['exitHeading'],
    enterSpeed: json['enterSpeed'],
    exitSpeed: json['exitSpeed'],
    enterCourse: json['enterCourse'],
    exitCourse: json['exitCourse'],
    duration: json['duration'],
  };
}

export function AreaVisitToJSON(value?: AreaVisit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    portId: value.portId,
    upriverId: value.upriverId,
    highRiskAreaId: value.highRiskAreaId,
    tradingAreaId: value.tradingAreaId,
    chokepointId: value.chokepointId,
    pierId: value.pierId,
    anchorageId: value.anchorageId,
    dryDockId: value.dryDockId,
    areaType: AreaTypeToJSON(value.areaType),
    imo: value.imo,
    enterTime: value.enterTime,
    exitTime: value.exitTime,
    enterDraft: value.enterDraft,
    exitDraft: value.exitDraft,
    draftDifference: value.draftDifference,
    enterHeading: value.enterHeading,
    exitHeading: value.exitHeading,
    enterSpeed: value.enterSpeed,
    exitSpeed: value.exitSpeed,
    enterCourse: value.enterCourse,
    exitCourse: value.exitCourse,
    duration: value.duration,
  };
}
