import {InputNumber, Slider} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';

export const MinMaxRangeFilter: FC<{
  onChange?: (value: [number | '', number | '']) => void;
  value?: [number | '', number | ''];
  min: number;
  max: number;
  precision?: number;
  step?: number;
  disabled?: boolean;
}> = ({value, step, onChange, min, max, disabled, precision}) => {
  const valuesForTheSlider = getValuesForTheSlider({value, min, max});
  const valuesForInputBoxes = getValuesForInputBoxes({value});

  const onSliderChange = (value: [number, number]) => {
    const minValue = value[0] === min ? '' : value[0];
    const maxValue = value[1] === max ? '' : value[1];
    onChange?.([minValue, maxValue]);
  };

  const onChangeMin = (minValue: number | null) => {
    if (minValue === null) {
      return;
    }
    const valueLeft = minValue === min ? '' : minValue;
    onChange?.([valueLeft, value?.[1] ?? '']);
  };

  const onChangeMax = (maxValue: number | null) => {
    if (maxValue === null) {
      return;
    }
    const valueRight = maxValue === max ? '' : maxValue;
    onChange?.([value?.[0] ?? '', valueRight]);
  };

  return (
    <div>
      <StyledSlider
        disabled={disabled}
        step={step}
        onChange={value => {
          onSliderChange(value as [number, number]);
        }}
        range
        value={valuesForTheSlider}
        min={min}
        max={max}
      />
      <StyledSingleValueBox>
        From
        <StyledInputNumber
          precision={precision}
          disabled={disabled}
          size={'small'}
          value={valuesForInputBoxes[0]}
          onChange={onChangeMin}
        />
        To
        <StyledInputNumber
          size={'small'}
          precision={precision}
          disabled={disabled}
          value={valuesForInputBoxes[1]}
          onChange={onChangeMax}
        />
      </StyledSingleValueBox>
    </div>
  );
};

const getValuesForTheSlider = ({
  value,
  min,
  max,
}: {
  value?: [number | '', number | ''];
  min: number;
  max: number;
}): [number, number] => {
  const minV = typeof value?.[0] !== 'number' ? min : value?.[0];
  const maxV = typeof value?.[1] !== 'number' ? max : value?.[1];
  return [minV, maxV];
};

const getValuesForInputBoxes = ({
  value,
}: {
  value?: [number | '', number | ''];
}): [number | undefined, number | undefined] => {
  const minV = typeof value?.[0] !== 'number' ? undefined : value?.[0];
  const maxV = typeof value?.[1] !== 'number' ? undefined : value?.[1];
  return [minV, maxV];
};

const StyledInputNumber = styled(InputNumber)`
  width: 80px;
  margin-left: 5px;
  font-size: var(--font-size-sm);
  background: var(--color-gray-6-fixed);
  color: var(--color-black-fixed);
  &:first-child {
    margin-right: 5px;
  }
` as typeof InputNumber;

const StyledSingleValueBox = styled.div`
  color: var(--color-white-fixed);
`;

const StyledSlider = styled(Slider)`
  &:hover {
    .ant-slider-track {
      background: #62d388;
    }
  }
  .ant-slider-handle {
    border-radius: 50%;
    background: #62d388;
    cursor: pointer;
    width: 16px;
    height: 16px;
    right: -8px;
    top: -2px;
    border-color: #62d388;
    &::after {
      display: none;
    }
  }
  .ant-slider-track {
    background: #62d388;
  }

  .ant-slider-rail {
    background: white;
  }
`;
