import {AISRecord} from '../queries/aisRecordsQuery/AISRecord';
import {VoyageAnimationPortVisit} from '../model/VoyageAnimationPortVisit';
import sortBy from 'lodash/sortBy';
import {transformLonLatFromObject} from '../../../../../utils/Coordinates';
import {assert, assertUnreachable} from '../../../../../utils/assert';
import {
  AnimationEvent,
  AnimationPortEntry,
  AnimationPortExit,
  AnimationSeaSegment,
  AnimationSeaSegmentWithoutRedundantFields,
} from '../model/AnimationEvent';
import {distance} from '../utils/distance';
import dayjs from 'dayjs';
import {splitSeaSegment} from './splitSeaSegment';
import {calculateRedundantFields} from './calculateRedundantFields';

export const makeSeaSegments = (aisRecords: AISRecord[]) => {
  let eventId = 0;
  const seaSegments: AnimationSeaSegment[] = [];
  for (let i = 0; i < aisRecords.length - 1; i++) {
    const id = `sea-segment-${eventId++}`;
    const from = aisRecords[i];
    const to = aisRecords[i + 1];
    const seaSegmentWithoutRedundantFields: AnimationSeaSegmentWithoutRedundantFields = {
      id,
      type: 'sea-segment',
      from,
      to,
    };
    const seaSegment: AnimationSeaSegment = calculateRedundantFields(seaSegmentWithoutRedundantFields);

    // We don't know yet if this sea segment is inside a port or not
    seaSegments.push(seaSegment);
  }
  return seaSegments;
};

const makePortEntries = (portVisits: VoyageAnimationPortVisit[]) => {
  let eventId = 0;
  const portEntries: AnimationPortEntry[] = portVisits.map(portVisit => ({
    id: `port-entry-${eventId++}`,
    type: 'port-entry',
    portVisit: portVisit,
    timestamp: portVisit.enterTimestamp,
  }));
  return portEntries;
};

const makePortExits = (portVisits: VoyageAnimationPortVisit[]) => {
  let eventId = 0;
  const portExits: AnimationPortExit[] = portVisits
    .filter(portVisit => !!portVisit.exitTimestamp)
    .map(portVisit => ({
      id: `port-exit-${eventId++}`,
      type: 'port-exit',
      portVisit: portVisit,
      timestamp: portVisit.exitTimestamp,
    }));
  return portExits;
};

const splitLongSeaSegmentsBeforePortEntry = (
  animationEvents: AnimationEvent[],
  minDistanceToPortAnimationStartInKM: number
): void => {
  for (let portEntryIndex = 0; portEntryIndex < animationEvents.length; portEntryIndex++) {
    const animationEvent = animationEvents[portEntryIndex];
    if (animationEvent.type !== 'port-entry') {
      continue;
    }
    const portEntry = animationEvent;
    const found = findSeaSegmentBeforePortEntryWithMinDistance(
      portEntry,
      portEntryIndex,
      animationEvents,
      minDistanceToPortAnimationStartInKM
    );
    if (!found) {
      continue;
    }
    const {seaSegment, seaSegmentIndex} = found;
    // const lengthInKM = seaSegment.distanceInKM;
    // if (lengthInKM < 10) {
    //   continue;
    // }

    // Split the sea segment into two
    const newSeaSegments: AnimationSeaSegment[] = splitSeaSegment(
      seaSegment,
      portEntry,
      minDistanceToPortAnimationStartInKM
    );
    animationEvents.splice(seaSegmentIndex, 1, ...newSeaSegments);
    // Skip the second added sea segment
    portEntryIndex++;
  }
};

/**
 * Add a reference to an ais record at which the porty entry animation should play.
 */
const addWaypointApproachingPortToEachPortEntry = (
  animationEvents: AnimationEvent[],
  minDistanceToPortAnimationStartInKM: number
): void => {
  // For each port entry, find the first AIS record that is within n KM of the port
  for (let portEntryIndex = 0; portEntryIndex < animationEvents.length; portEntryIndex++) {
    const portEntry = animationEvents[portEntryIndex];
    if (portEntry.type !== 'port-entry') {
      continue;
    }

    const waypointApproachingPort = findWaypointApproachingPort(
      portEntry,
      portEntryIndex,
      animationEvents,
      minDistanceToPortAnimationStartInKM
    );
    portEntry.vesselCoordinates = waypointApproachingPort?.coordinates;
    if (waypointApproachingPort) {
      // Make sure that the port entry event will be sorted just after the waypoint approaching port event
      // by subtracing 1 minute from the timestamp
      portEntry.timestamp = waypointApproachingPort.timestamp.add(1, 'minute');
    }
  }
};

const findSeaSegmentBeforePortEntryWithMinDistance = (
  portEntry: AnimationPortEntry,
  portEntryIndex: number,
  animationEvents: AnimationEvent[],
  minDistanceToPortAnimationStartInKM: number
): {seaSegment: AnimationSeaSegment; seaSegmentIndex: number} | undefined => {
  const portCenterCoordinates = transformLonLatFromObject(portEntry.portVisit.port.coordinates);
  assert(portCenterCoordinates);

  // From the port entry event, go backwards in time until we find an AIS record that is more than DISTANCE_TO_PORT_ANINMATION_START_KM away from the port.
  for (let seaSegmentIndex = portEntryIndex; seaSegmentIndex >= 0; seaSegmentIndex--) {
    const seaSegment = animationEvents[seaSegmentIndex];
    if (seaSegment.type !== 'sea-segment') {
      continue;
    }
    const aisRecord = seaSegment.from;
    const aisRecordCoordinates = aisRecord.coordinates;
    const distanceToPortInKM = distance(aisRecordCoordinates, portCenterCoordinates);

    if (distanceToPortInKM > minDistanceToPortAnimationStartInKM) {
      // Found a sea segment that should sit right before the port entry animation
      return {seaSegment, seaSegmentIndex};
    }
  }

  // Found no matching sea segment
  return undefined;
};

const findWaypointApproachingPort = (
  portEntry: AnimationPortEntry,
  portEntryIndex: number,
  animationEvents: AnimationEvent[],
  minDistanceToPortAnimationStartInKM: number
): AISRecord | undefined => {
  const portCenterCoordinates = transformLonLatFromObject(portEntry.portVisit.port.coordinates);
  assert(portCenterCoordinates);

  // From the port entry event, go backwards in time until we find an AIS record that is more than DISTANCE_TO_PORT_ANINMATION_START_KM away from the port.
  for (let seaSegmentIndex = portEntryIndex; seaSegmentIndex >= 0; seaSegmentIndex--) {
    const seaSegment = animationEvents[seaSegmentIndex];
    if (seaSegment.type !== 'sea-segment') {
      continue;
    }
    const aisRecord = seaSegment.from;
    const aisRecordCoordinates = aisRecord.coordinates;
    const distanceToPortInKM = distance(aisRecordCoordinates, portCenterCoordinates);

    if (distanceToPortInKM > minDistanceToPortAnimationStartInKM) {
      // Found a waypoint approaching port
      return aisRecord;
    }
  }

  // Found no waypoint approaching port
  return undefined;
};

/**
 * Add a reference to the next port visit to each port exit.
 */
const addNextPortVisitToEachPortExit = (animationEvents: AnimationEvent[]): void => {
  // Find all port exits
  const portExits = animationEvents.filter(voyageEvent => voyageEvent.type === 'port-exit') as AnimationPortExit[];
  // Tell each port exit what the next port visit is
  for (let index = 0; index < portExits.length - 1; index++) {
    portExits[index].nextPortVisit = portExits[index + 1].portVisit;
  }
};

const markSeaSegmentsInsidePort = (animationEvents: AnimationEvent[]) => {
  let insidePort = false;
  for (let i = 0; i < animationEvents.length; i++) {
    const animationEvent = animationEvents[i];
    const type = animationEvent.type;
    switch (type) {
      case 'sea-segment':
        animationEvent.insidePort = insidePort;
        break;
      case 'port-entry':
        insidePort = true;
        break;
      case 'port-exit':
        insidePort = false;
        break;
      default:
        assertUnreachable(type);
    }
  }
};

const sortByTimestamp = (animationEvents: AnimationEvent[]) => {
  const now = dayjs();
  return sortBy(animationEvents, (animationEvent: AnimationEvent) => (animationEvent.timestamp ?? now).unix());
};

/**
 * Turns a set of port visits and AIS records into a set of sorted animation events.
 */
export const makeAnimationEvents = (
  portVisits: VoyageAnimationPortVisit[],
  aisRecords: AISRecord[],
  minDistanceToPortAnimationStartInKM: number
): AnimationEvent[] => {
  const seaSegments = makeSeaSegments(aisRecords);
  const portEntries = makePortEntries(portVisits);
  const portExits = makePortExits(portVisits);
  let animationEvents: AnimationEvent[] = [...portExits, ...portEntries, ...seaSegments];
  animationEvents = sortByTimestamp(animationEvents);
  splitLongSeaSegmentsBeforePortEntry(animationEvents, minDistanceToPortAnimationStartInKM);
  animationEvents = sortByTimestamp(animationEvents);
  addWaypointApproachingPortToEachPortEntry(animationEvents, minDistanceToPortAnimationStartInKM);
  // Sort again, because we've updated timestamps of port entry events
  animationEvents = sortByTimestamp(animationEvents);
  addNextPortVisitToEachPortExit(animationEvents);
  markSeaSegmentsInsidePort(animationEvents);

  return animationEvents;
};
