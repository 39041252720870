import {Col, Row} from 'antd';
import React, {FC} from 'react';
import {AccountSegmentCheckboxButton} from './AccountSegmentCheckboxButton';
import {SegmentHeadline} from './sharedComponents/SegmentHeadline';
import styled from 'styled-components';
import {SegmentSubHeadline} from './sharedComponents/SegmentSubHeadline';
import {SegmentCenterContainer} from './sharedComponents/SegmentCenterContainer';
import {Button} from '../../../atoms/Button/Button';

const availableRoles: {
  label: string;
  iconUrl: string;
}[] = [
  {label: 'Operator', iconUrl: '/static/icon/accountSegment/Roles/Operator.svg'},
  {label: 'Shipowner', iconUrl: '/static/icon/accountSegment/Roles/Shipowner.svg'},
  {label: 'Cargo owner', iconUrl: '/static/icon/accountSegment/Roles/Cargoowner.svg'},
  {label: 'Broker', iconUrl: '/static/icon/accountSegment/Roles/Broker.svg'},
  {label: 'Analyst', iconUrl: '/static/icon/accountSegment/Roles/Analyst.svg'},
];

export const RoleSelector: FC<{
  onChange: (roles: string[]) => void;
  selectedRoles: string[];
}> = ({onChange, selectedRoles}) => {
  const changeOneItem = (key: string) => {
    const newList = selectedRoles.filter(item => item !== key);
    if (newList.length === selectedRoles.length) {
      newList.push(key);
    }
    onChange(newList);
  };

  const unselectAll = () => {
    onChange([]);
  };

  const selectAll = () => {
    const newList = availableRoles.map(role => role.label);
    onChange(newList);
  };

  const hasSelectedElements = selectedRoles.length !== 0;

  return (
    <>
      <SegmentCenterContainer>
        <div>
          <SegmentHeadline>Which position describes you best?</SegmentHeadline>
        </div>
        <div>
          <SegmentSubHeadline>Select all that apply</SegmentSubHeadline>
        </div>
      </SegmentCenterContainer>
      <StyledRow gutter={[16, 16]}>
        {availableRoles.map(roles => {
          const key = roles.label;
          return (
            <Col key={key} span={12}>
              <AccountSegmentCheckboxButton
                dataTestid={`RoleSelectorButton_${key}`}
                checked={selectedRoles.includes(key)}
                onClick={() => changeOneItem(key)}>
                <StyledIconContainer>
                  <StyledIcon src={roles.iconUrl} />
                </StyledIconContainer>
                {key}
              </AccountSegmentCheckboxButton>
            </Col>
          );
        })}
      </StyledRow>
      <StyledRow gutter={[16, 16]} style={{justifyContent: 'center'}}>
        <ButtonContainer>
          <Button
            label={hasSelectedElements ? 'Unselect all' : 'Select all'}
            bold
            onClick={hasSelectedElements ? unselectAll : selectAll}
          />
        </ButtonContainer>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  padding: 0 40px;
`;

const ButtonContainer = styled.div`
  margin: 16px 10px 48px 10px;
`;

const StyledIconContainer = styled.span`
  display: inline-block;
  width: 60px;
  height: 60px;
  padding: 10px;
  margin-right: 12px;
  background-color: var(--color-gray-7);
`;

const StyledIcon = styled.img`
  width: 100%;
  height: 100%;
`;
