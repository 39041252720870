import {ReactElement, useState} from 'react';
import styled from 'styled-components';
import {Select, SelectProps} from 'antd';
import Icon from '../Icon';
import {Property} from 'csstype';

type Props<ValueType> = {
  value?: ValueType;
  size?: SelectProps['size'];
  defaultOpen?: boolean;
  formattedValue?: ReactElement;
  options?: {
    label: string;
    value: ValueType;
  }[];
  editButtonAlignment?: Property.VerticalAlign;
  hideEditButton?: boolean;
  closeOnBlur?: boolean;
  readonly?: boolean;
  onSelect: (value: ValueType) => void;
  editing?: boolean;
  onEditingChanged?: (editing: boolean) => void;
};

export const InlineSelectEditor = <ValueType extends string>(props: Props<ValueType>) => {
  const [editingState, setEditingState] = useState(props.defaultOpen ?? false);
  const closeOnBlur = props.closeOnBlur ?? true;

  const label = props.formattedValue
    ? props.formattedValue
    : props.options?.find(option => option.value === props.value)?.label ?? '';

  if (props.editing || editingState) {
    return (
      <Select
        showSearch={true}
        defaultOpen={true}
        size={props.size}
        style={{width: '100%'}}
        value={props.value}
        onSelect={(value: unknown) => {
          props.onSelect(value as ValueType);
          if (props.editing !== undefined && props.onEditingChanged) {
            props.onEditingChanged(false);
          } else {
            setEditingState(false);
          }
        }}
        options={props.options}
        onBlur={() => {
          if (!closeOnBlur) {
            return;
          }
          if (props.editing !== undefined && props.onEditingChanged) {
            props.onEditingChanged(false);
          } else {
            setEditingState(false);
          }
        }}
      />
    );
  }
  return (
    <StyledSpan
      onClick={() => {
        if (props.readonly) {
          return;
        }
        if (props.editing !== undefined && props.onEditingChanged) {
          props.onEditingChanged(true);
        } else {
          setEditingState(true);
        }
      }}>
      <span>
        {label}{' '}
        <Icon
          className={'editMarker'}
          size={'unset'}
          style={{
            display: props.hideEditButton ? 'none' : 'block',
            verticalAlign: props.editButtonAlignment || 'bottom',
          }}
          type={'edit'}
        />
      </span>
    </StyledSpan>
  );
};

const StyledSpan = styled.span`
  cursor: pointer;

  width: 100%;

  .editMarker {
    opacity: 0;
  }
  :hover {
    .editMarker {
      opacity: 1;
    }
  }
`;
