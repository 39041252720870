import React, {FC} from 'react';
import {NegotiationActor} from '../../../../../api/node-backend/generated';
import {UserDateTime} from '../../../../../components/DateTime/UserDateTime';
import {NegotiationActorLabel} from '../../../Components/NegotiationPartyLabel';
import styled from 'styled-components';
import {NoWrap, Faded, MetaContainer} from '../TermItem/Components/shared';

type SubjectMetaProps = {
  ownerActor: NegotiationActor;
  isOwnSubject: boolean;
  lastEditActor: NegotiationActor;
  isOwnEdit: boolean;
  isEditable?: boolean;
  committedAt?: Date | null;
  roundIndex?: number;
};

export const SubjectMeta: FC<SubjectMetaProps> = ({
  ownerActor,
  isOwnSubject,
  lastEditActor,
  isOwnEdit,
  isEditable,
  committedAt,
  roundIndex,
}) => {
  return (
    <SubjectMetaContainer data-testid="SubjectMeta">
      <NoWrap>
        <Faded>created by:</Faded> <NegotiationActorLabel actor={ownerActor} isSelf={isOwnSubject} />
        {isEditable && (
          <>
            <Faded> / </Faded>
            <Faded>last edit:</Faded> <NegotiationActorLabel actor={lastEditActor} isSelf={isOwnEdit} />
          </>
        )}
        {roundIndex !== undefined && <span> ({roundIndex + 1})</span>}
      </NoWrap>
      <NoWrap>{committedAt ? <UserDateTime value={committedAt.toISOString()} format={'dateTime'} /> : ''}</NoWrap>
    </SubjectMetaContainer>
  );
};

const SubjectMetaContainer = styled(MetaContainer)`
  min-width: 250px;
`;
