import {Button} from 'antd';
import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {MinimalLayout} from '../../atoms/Layout/MinimalLayout';

export const GuestLayout: FC<{children: ReactNode}> = ({children}) => {
  return (
    <MinimalLayout
      navbarAddon={
        <NavbarContainer>
          <NavbarTitle>The essentials of digital chartering</NavbarTitle>
          <LoginContainer>
            Already have an account?
            <Button href="/login" ghost={true}>
              Log in
            </Button>
            <Button href="/signup" type="primary">
              Sign up for free
            </Button>
          </LoginContainer>
        </NavbarContainer>
      }>
      <ContentContainer>{children}</ContentContainer>
    </MinimalLayout>
  );
};

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const NavbarTitle = styled.div`
  font-weight: bold;
  color: var(--color-gray-2);
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ContentContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  --header-height: 72px;
`;
