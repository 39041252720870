import {AnyAction, ThunkDispatch} from '@reduxjs/toolkit';
import * as ReactRedux from 'react-redux';
import {RootState} from './store';

export type {DispatchFunc} from './AppThunk';
export type {GetState} from './store';

export const useSelector: ReactRedux.TypedUseSelectorHook<RootState> = ReactRedux.useSelector;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

export const useDispatch = () => ReactRedux.useDispatch<TypedDispatch<RootState>>();

export const useStore = () => ReactRedux.useStore<RootState>();
