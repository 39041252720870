/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  UpdateCompanyMembersResponseData,
  UpdateCompanyMembersResponseDataFromJSON,
  UpdateCompanyMembersResponseDataFromJSONTyped,
  UpdateCompanyMembersResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface UpdateCompanyMembersResponse
 */
export interface UpdateCompanyMembersResponse {
  /**
   *
   * @type {UpdateCompanyMembersResponseData}
   * @memberof UpdateCompanyMembersResponse
   */
  data: UpdateCompanyMembersResponseData;
}

export function UpdateCompanyMembersResponseFromJSON(json: any): UpdateCompanyMembersResponse {
  return UpdateCompanyMembersResponseFromJSONTyped(json, false);
}

export function UpdateCompanyMembersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCompanyMembersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: UpdateCompanyMembersResponseDataFromJSON(json['data']),
  };
}

export function UpdateCompanyMembersResponseToJSON(value?: UpdateCompanyMembersResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: UpdateCompanyMembersResponseDataToJSON(value.data),
  };
}
