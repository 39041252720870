/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ModelDate,
  ModelDateFromJSON,
  ModelDateFromJSONTyped,
  ModelDateToJSON,
  Observed,
  ObservedFromJSON,
  ObservedFromJSONTyped,
  ObservedToJSON,
} from './';

/**
 *
 * @export
 * @interface Weekday
 */
export interface Weekday {
  /**
   *
   * @type {ModelDate}
   * @memberof Weekday
   */
  date?: ModelDate;
  /**
   *
   * @type {Observed}
   * @memberof Weekday
   */
  observed?: Observed;
}

export function WeekdayFromJSON(json: any): Weekday {
  return WeekdayFromJSONTyped(json, false);
}

export function WeekdayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Weekday {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: !exists(json, 'date') ? undefined : ModelDateFromJSON(json['date']),
    observed: !exists(json, 'observed') ? undefined : ObservedFromJSON(json['observed']),
  };
}

export function WeekdayToJSON(value?: Weekday | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: ModelDateToJSON(value.date),
    observed: ObservedToJSON(value.observed),
  };
}
