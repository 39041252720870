import {Popover} from 'antd';
import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import {FeaturePermission} from '../../api/symfony/generated';
import {useIsPermitted} from '../../utils/useIsPermitted';

interface Props {
  permission: FeaturePermission | undefined;
  children: JSX.Element;
}

export const PremiumFeatureProtection: FC<Props> = ({children, permission}) => {
  const permitted = useIsPermitted(permission);
  return permitted ? children : <UpgradePopover>{children}</UpgradePopover>;
};

const UpgradePopover: FC<{children: JSX.Element}> = ({children}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const togglePopoverVisible = () => {
    setPopoverVisible(!popoverVisible);
  };

  return (
    <Popover trigger="click" open={popoverVisible} onOpenChange={setPopoverVisible} content={<CallToUpgrade />}>
      <ClickShield
        onShieldClick={() => {
          togglePopoverVisible();
        }}>
        {children}
      </ClickShield>
    </Popover>
  );
};

interface ClickShieldProps {
  children: JSX.Element;
  // For some reason, calling this event "onClick" causes an error in JSDom with a message about clientX.
  // Therefore I name the event differently.
  onShieldClick: () => void;
}

/**
 * Prevents clicks from reaching our children.
 */
const ClickShield: FC<ClickShieldProps> = ({children, onShieldClick}) => (
  <div
    onClickCapture={event => {
      event.stopPropagation();
      onShieldClick();
    }}>
    {children}
  </div>
);

const CallToUpgrade: FC<{onClick?: () => void}> = ({onClick}) => (
  <div
    data-testid="CallToUpgrade"
    onClick={event => {
      event.stopPropagation();
      onClick?.();
    }}>
    <Link to={`/subscription`}>Upgrade</Link> to seabo Premium to use this feature
  </div>
);
