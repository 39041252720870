import {useState} from 'react';
import {FilterTypeDefault, FilterBoxSize, FilterBoxRenderFunction, RangePickerValues} from './types';
import {FilterDropdown} from './FilterDropdown';
import {FilterBox} from './FilterBox';
import {FilterBtn} from '../Button/FilterBtn';

export type FilterButtonDumbProps<FilterType extends FilterTypeDefault> = {
  title: string;
  values: FilterType;
  isApplied?: boolean;
  isValid?: boolean;
  validationErrors?: Partial<Record<keyof FilterType, string>>;
  size?: FilterBoxSize;
  dark?: boolean;
  disabled?: boolean;
  dataCy?: string;
  dataTestid?: string;
  onChange: (key: keyof FilterType, value: FilterType[keyof FilterType] | null) => void;
  onChangeRangePicker: (values: RangePickerValues) => void;
  onApply: () => void;
  onReset: () => void;
  children: FilterBoxRenderFunction<FilterType>;
};

/**
 * This component is used to render a filter button and corresponding dropdown.
 */
export const FilterButtonDumb = <FilterType extends FilterTypeDefault>({
  title,
  values,
  isApplied = false,
  isValid = true,
  validationErrors = {},
  size = 'medium',
  dark = false,
  disabled,
  dataCy,
  dataTestid,
  onChange,
  onChangeRangePicker,
  onApply,
  onReset,
  children,
}: FilterButtonDumbProps<FilterType>) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleApply = () => {
    onApply();
    setOpen(false);
  };

  return (
    <FilterDropdown
      dataCy={dataCy}
      dataTestid={dataTestid}
      title={title}
      size={size}
      open={open}
      onOpenChange={setOpen}
      trigger={
        <FilterBtn
          label={title}
          isApplied={isApplied}
          active={open}
          dark={dark}
          disabled={disabled}
          dataCy={`${dataCy}-FilterBtn`}
          dataTestid={`${dataTestid}-FilterBtn`}
          onClick={() => setOpen(!open)}
        />
      }
      content={
        <FilterBox<FilterType>
          dataCy={`${dataCy}-FilterBox`}
          dataTestid={`${dataTestid}-FilterBox`}
          title={title}
          values={values}
          validations={validationErrors}
          isApplied={isApplied}
          valid={isValid}
          onChange={onChange}
          onChangeRangePicker={onChangeRangePicker}
          onApply={handleApply}
          onReset={onReset}
          onCancel={() => setOpen(false)}
          children={children}
        />
      }
    />
  );
};
