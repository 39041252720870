/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyWorkspace2,
  CompanyWorkspace2FromJSON,
  CompanyWorkspace2FromJSONTyped,
  CompanyWorkspace2ToJSON,
} from './';

/**
 *
 * @export
 * @interface Company2
 */
export interface Company2 {
  /**
   *
   * @type {number}
   * @memberof Company2
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Company2
   */
  name: string;
  /**
   *
   * @type {Array<CompanyWorkspace2>}
   * @memberof Company2
   */
  workspaces?: Array<CompanyWorkspace2>;
  /**
   *
   * @type {CompanyWorkspace2}
   * @memberof Company2
   */
  defaultWorkspace?: CompanyWorkspace2 | null;
}

export function Company2FromJSON(json: any): Company2 {
  return Company2FromJSONTyped(json, false);
}

export function Company2FromJSONTyped(json: any, ignoreDiscriminator: boolean): Company2 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    workspaces: !exists(json, 'workspaces')
      ? undefined
      : (json['workspaces'] as Array<any>).map(CompanyWorkspace2FromJSON),
    defaultWorkspace: !exists(json, 'defaultWorkspace')
      ? undefined
      : CompanyWorkspace2FromJSON(json['defaultWorkspace']),
  };
}

export function Company2ToJSON(value?: Company2 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    workspaces:
      value.workspaces === undefined ? undefined : (value.workspaces as Array<any>).map(CompanyWorkspace2ToJSON),
    defaultWorkspace: CompanyWorkspace2ToJSON(value.defaultWorkspace),
  };
}
