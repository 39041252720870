/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Quantity
 */
export interface Quantity {
  /**
   *
   * @type {number}
   * @memberof Quantity
   */
  minimum?: number | null;
  /**
   *
   * @type {number}
   * @memberof Quantity
   */
  maximum?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Quantity
   */
  empty?: boolean;
}

export function QuantityFromJSON(json: any): Quantity {
  return QuantityFromJSONTyped(json, false);
}

export function QuantityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Quantity {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    minimum: !exists(json, 'minimum') ? undefined : json['minimum'],
    maximum: !exists(json, 'maximum') ? undefined : json['maximum'],
    empty: !exists(json, 'empty') ? undefined : json['empty'],
  };
}

export function QuantityToJSON(value?: Quantity | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    minimum: value.minimum,
    maximum: value.maximum,
    empty: value.empty,
  };
}
