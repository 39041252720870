import LabelWrapper from '../../../../atoms/LabelWrapper';
import React, {Fragment} from 'react';

export const ConsumptionRowLabels = () => (
  <div className="row consumption-row-labels">
    <div className="scol-12 scol-sm-6 scol-lg-3">
      <LabelWrapper label={'Mode'} />
    </div>
    <div className="scol-12 scol-sm-5 scol-lg-2">
      <Fragment>
        <LabelWrapper label={'Speed (kn)'} />
      </Fragment>
    </div>
    <div className="scol-12 scol-sm-11 scol-lg-3">
      <LabelWrapper label={'ConsumptionSection (mt)'} />
    </div>
    <div className="scol-12 scol-sm-11 scol-lg-3">
      <LabelWrapper label={'Additional consumption (mt)'} />
    </div>
    <div className="scol-12 scol-sm-1"></div>
  </div>
);
