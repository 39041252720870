/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CommodityCategory,
  CommodityCategoryFromJSON,
  CommodityCategoryFromJSONTyped,
  CommodityCategoryToJSON,
} from './';

/**
 *
 * @export
 * @interface CommodityCategoryResponseData
 */
export interface CommodityCategoryResponseData {
  /**
   *
   * @type {Array<CommodityCategory>}
   * @memberof CommodityCategoryResponseData
   */
  items: Array<CommodityCategory>;
  /**
   *
   * @type {number}
   * @memberof CommodityCategoryResponseData
   */
  totalItems: number;
}

export function CommodityCategoryResponseDataFromJSON(json: any): CommodityCategoryResponseData {
  return CommodityCategoryResponseDataFromJSONTyped(json, false);
}

export function CommodityCategoryResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommodityCategoryResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(CommodityCategoryFromJSON),
    totalItems: json['totalItems'],
  };
}

export function CommodityCategoryResponseDataToJSON(value?: CommodityCategoryResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(CommodityCategoryToJSON),
    totalItems: value.totalItems,
  };
}
