import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {setAccessTokenForApi} from '../api/utils/apiAccessTokenMiddleware';

interface AuthenticationState {
  accessToken?: string;
}

const initialState: AuthenticationState = {};

const authenticationSlice = createSlice({
  name: 'Authentication',
  initialState,
  reducers: {
    _setAccessToken(state, action: PayloadAction<string | undefined>) {
      state.accessToken = action.payload;
    },
  },
});

export const authenticationReducer = authenticationSlice.reducer;

const setAccessToken = (accessToken: string | undefined) => {
  setAccessTokenForApi(accessToken);
  return authenticationSlice.actions._setAccessToken(accessToken);
};

export const AuthenticationActions = {
  ...authenticationSlice.actions,
  setAccessToken,
};
