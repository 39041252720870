import {FC, ReactNode, useState} from 'react';
import {StationType} from '../../../api/symfony/generated';
import Checkbox from '../../../atoms/Checkbox';
import EmptyStateArchive from '../../../atoms/EmptyState/Archive/EmptyStateArchive';
import EmptyStateMainPortfolio from '../../../atoms/EmptyState/Portfolio/Vessel/EmptyStateMainPortfolio';
import EmptyStateSubPortfolio from '../../../atoms/EmptyState/Portfolio/Vessel/EmptyStateSubPortfolio';
import Station from '../../../model/Station';
import Vessel from '../../../model/Vessel';
import {GridClipboardActions} from '../../../redux/GridClipboard';
import {ModalActions} from '../../../redux/Modal';
import {PortfolioVessel, VesselGridLayoutMode} from '../../../redux/Portfolio';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import NoResults from '../../../screens/market/EmptyState/NoResults';
import LoadingComponent from '../../DataGrid/LoadingComponent';
import {
  updatedByColumn,
  vesselBulkerGrid,
  vesselContainerGrid,
  VesselGridOptions,
  vesselMppGrid,
} from '../../GridLayout';
import {colorColumn} from '../../GridLayout/columns/shared/colorColumn';
import {commentRedelNoteColumn} from '../../GridLayout/columns/vessel/noteColumn';
import {createdAtColumn} from '../../GridLayout/columns/vessel/createdAtColumn';
import {RowValueType} from '../../GridLayout/utils/RowValueType';
import {ManageSubPortfolioModal} from '../../Modal/ManageSubPortfolio/ManageSubPortfolioModal';
import {chartererColumn} from './Columns/chartererColumn';
import {tagsColumn} from './Columns/TagsColumn/tagsColumn';
import './style.scss';
import {VesselGridActionMenu} from './VesselGridActionMenu';
import {CustomColumnDef, DataGrid} from '../../DataGrid/DataGrid';
import {ColumnSort} from '@tanstack/react-table';
import ManageFixtureModal from '../../Modal/ManageFixture/ManageFixtureModal';
import {PortfolioLocationEdit} from '../../Modal/PortfolioLocationEdit/PortfolioLocationEditModal';
import {VesselDetailsVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {colorPickerFunctionForMyFleetVessel} from './colorPickerFunctionForMyFleetVessel';

export interface VesselGridProps {
  vessels: PortfolioVessel[] | null;
  isSub?: boolean;
  isArchive?: boolean;
  loading?: boolean;
  fetching?: boolean;
  showPageSizeOptions?: boolean;
  pages?: number;
  isFiltered: boolean;
  gridLayout: VesselGridLayoutMode;
  totalCount?: number;
  pageSize?: number;
  page?: number;
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  reloadGrid: (pageIndex?: number) => void;
  goToAll: () => void;
  onSortedChange: (sorted: ColumnSort) => void;
  sorted?: ColumnSort[];
  gridOptions: VesselGridOptions;
}

export const VesselGrid: FC<VesselGridProps> = ({
  vessels,
  isSub = false,
  isArchive = false,
  loading,
  fetching,
  goToAll,
  reloadGrid,
  showPageSizeOptions,
  totalCount,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
  onSortedChange,
  isFiltered,
  gridLayout,
  sorted,
  gridOptions,
}) => {
  const clipboardState = useSelector(state => state.gridClipboard.portfolio.vessels);
  const checkAllVessels = useSelector(state => state.gridClipboard.portfolio.checkAllVessels);

  const dispatch = useDispatch();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const addItemToClipboard = (item: PortfolioVessel) =>
    dispatch(
      GridClipboardActions.addItem({
        section: 'portfolio',
        subsection: 'vessels',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );
  const addAllItemsToClipboard = () =>
    dispatch(GridClipboardActions.addAllGridItemsToClipboard('vessels', 'portfolio'));
  const removeAllItemsFromClipboard = () =>
    dispatch(GridClipboardActions.removeAllItemsFromClipboard('vessels', 'portfolio'));
  const removeItemFromClipboard = (item: PortfolioVessel) =>
    dispatch(
      GridClipboardActions.removeItem({
        section: 'portfolio',
        subsection: 'vessels',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );

  const displayEmptyState = () => {
    if (loading || vessels === null) {
      return <LoadingComponent style={{textAlign: 'center'}} />;
    }
    if (vessels.length) {
      return;
    }
    if (isArchive && vessels.length === 0) {
      return <EmptyStateArchive type={'vessel'} location={'portfolio'} />;
    }
    if (isFiltered && vessels.length === 0) {
      return <NoResults type={'vessels'} />;
    }
    return isSub ? <EmptyStateSubPortfolio goToAll={goToAll} /> : <EmptyStateMainPortfolio />;
  };
  const displayLocationEdit = (d: PortfolioVessel, index: number) => {
    showModal(
      <PortfolioLocationEdit
        vessel={d}
        initialIndex={index}
        callback={() => {
          reloadGrid();
        }}
      />
    );
  };

  const getGridLayoutComponent = (type: RowValueType, options?: VesselGridOptions) => {
    if (gridLayout === 'bulker') {
      const vesselBulkerGridArray = [...vesselBulkerGrid(type, options)];
      vesselBulkerGridArray.splice(1, 0, tagsColumn({options: reloadGrid}));
      return vesselBulkerGridArray;
    } else if (gridLayout === 'mpp') {
      const vesselMppGridArray = [...vesselMppGrid(type, options)];
      vesselMppGridArray.splice(1, 0, tagsColumn({options: reloadGrid}));
      return vesselMppGridArray;
    } else {
      const vesselContainerGridArray = [...vesselContainerGrid(type, options)];
      vesselContainerGridArray.splice(1, 0, tagsColumn({options: reloadGrid}));
      return vesselContainerGridArray;
    }
  };

  const [manageSubPortfolioOpen, setManageSubPortfolioOpen] = useState<boolean>(false);
  const [manageSubPortfolioVessel, setManageSubPortfolioVessel] = useState<PortfolioVessel | undefined>(undefined);

  const selectionColumn: CustomColumnDef<PortfolioVessel> = {
    header: () => (
      <Checkbox
        id={`clipboard-checkbox-all`}
        checked={checkAllVessels}
        onChange={e => {
          e.target.checked ? addAllItemsToClipboard() : removeAllItemsFromClipboard();
        }}
      />
    ),
    id: 'clipBoard',
    enableSorting: false,
    className: 'datagrid-td-clipboard',
    headerClassName: 'datagrid-th-clipboard',
    cell: d => {
      return (
        <Checkbox
          id={`clipboard-checkbox-${d.row.id}`}
          checked={!!clipboardState.find(cbi => cbi.id === d.row.original.id)}
          onChange={e => {
            e.target.checked ? addItemToClipboard(d.row.original) : removeItemFromClipboard(d.row.original);
          }}
        />
      );
    },
    width: 24,
  };

  const gridLayoutColumns = getGridLayoutComponent('portfolio', gridOptions);

  const nextOpenColumn: CustomColumnDef<PortfolioVessel> = {
    header: 'open',
    id: 'nextOpen',
    accessorFn: (d, index) => {
      return (
        <div className={'next-open-column inline-editable'} onClick={() => displayLocationEdit(d, index)}>
          <div className="next-open-column__date">{Vessel.formatDateOpen(d, ' - ')}</div>
          <div className="next-open-column__location">
            {Station.getLocationByStationType((d as $TSFixMe).stations, StationType.Nextopen, {
              short: true,
              highlight: true,
              emptyState: '-',
            })}
          </div>
        </div>
      );
    },
    cell: info => info.getValue(),
    minWidth: 26,
    enableSorting: true,
  };

  const portfolioChartererColumn = chartererColumn({
    onOpenFixture: fixture => {
      return dispatch(
        ModalActions.show(
          <ManageFixtureModal
            formData={fixture}
            isVesselEdit={true}
            updateVessel={true}
            gridUpdate={true}
            callback={() => {
              reloadGrid();
            }}
          />
        )
      );
    },
    onCreateNewFixture: vessel => {
      return dispatch(
        ModalActions.show(
          <ManageFixtureModal
            vessel={vessel as unknown as VesselDetailsVessel}
            updateVessel
            callback={() => {
              reloadGrid();
            }}
          />
        )
      );
    },
  }) as unknown as CustomColumnDef<PortfolioVessel>;

  const actionsColumn: CustomColumnDef<PortfolioVessel> = {
    id: 'vessel.actions',
    header: '',
    enableSorting: false,
    maxWidth: 48,
    className: 'datagrid-td-action',
    accessorFn: d => (
      <VesselGridActionMenu
        vessel={d}
        isArchive={isArchive}
        reloadGrid={reloadGrid}
        onManageSubPortfolios={vessel => {
          setManageSubPortfolioVessel(vessel);
          setManageSubPortfolioOpen(true);
        }}
        onRemoveFromClipboard={removeItemFromClipboard}
      />
    ),
    cell: info => info.getValue(),
  };

  const columns = [
    colorColumn({
      colorPickerFunction: item => colorPickerFunctionForMyFleetVessel(item),
    }),
    selectionColumn,
    ...gridLayoutColumns,
    nextOpenColumn,
    portfolioChartererColumn,
    commentRedelNoteColumn({type: 'portfolio'}),
    updatedByColumn({}),
    createdAtColumn({}),
    actionsColumn,
  ] as CustomColumnDef<PortfolioVessel>[];

  const HIGHLIGHT_TIME_THRESHOLD = Date.now() - 1000 * 60 * 60 * 4;
  return (
    <>
      {manageSubPortfolioVessel && (
        <ManageSubPortfolioModal
          open={manageSubPortfolioOpen}
          onCancel={() => {
            setManageSubPortfolioOpen(false);
          }}
          onSuccess={() => {
            setManageSubPortfolioOpen(false);
          }}
          onClosed={() => {
            setManageSubPortfolioVessel(undefined);
            reloadGrid(1);
          }}
          type={'vessel'}
          vessel={manageSubPortfolioVessel}
        />
      )}

      <DataGrid<PortfolioVessel>
        className={'portfolio-grid portfolio-grid--vessel'}
        showPageSizeOptions={showPageSizeOptions}
        showJumper={true}
        totalCount={totalCount}
        fetching={fetching}
        pageSize={pageSize}
        page={page}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        columns={columns}
        data={vessels || []}
        loading={loading || vessels === null}
        zeroBasedPageIndex={false}
        TbodyComponent={displayEmptyState()}
        onSortedChange={onSortedChange}
        noHoverStatePointer
        sorted={sorted}
        unroundedTopLeft
        unroundedTopRight
        getTrProps={(_: unknown, rowInfo) => {
          if (rowInfo?.original.createdAt && Date.parse(rowInfo.original.createdAt) >= HIGHLIGHT_TIME_THRESHOLD) {
            return {className: 'is-highlighted'};
          }
          return {};
        }}
      />
    </>
  );
};
