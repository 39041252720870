import {FC, useRef} from 'react';
import {Modal, ModalWidth} from '../../../../../../../antd/components/Modal';
import {
  CreateOrUpdateNegotiationTemplateDTO,
  NegotiationRoundResult,
  NegotiationSummary,
} from '../../../../../../../api/node-backend/generated';
import {TermPreview} from '../TermPreview';
import {Form, Input} from 'antd';
import styled from 'styled-components';

type SaveContractTemplateModalProps = {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  open: boolean;
  onClose: () => void;
  onFinish: (contractTemplate: CreateOrUpdateNegotiationTemplateDTO) => void;
};

export const SaveContractTemplateModal: FC<SaveContractTemplateModalProps> = ({
  negotiation,
  roundResult,
  open,
  onClose,
  onFinish,
}) => {
  // Local Ref is used as Modal container to inherit negotiation CSS variables
  // Be careful when using this component outside of NegotiationDetailBody
  const ref = useRef<HTMLDivElement>(null);

  const [form] = Form.useForm<CreateOrUpdateNegotiationTemplateDTO>();

  const terms = roundResult.terms.map((term, idx) => ({
    text: term.text,
    type: term.type,
    sortIndex: idx,
  }));

  return (
    <div ref={ref}>
      <Modal
        getContainer={ref.current!}
        open={open}
        title={'Save current terms as template'}
        width={ModalWidth.Large}
        onCancel={onClose}
        onOk={() => {
          onFinish(form.getFieldsValue());
        }}
        okText={`Save ${terms.length} term${terms.length === 1 ? '' : 's'} to template`}
        okButtonProps={{
          disabled: !terms.length,
        }}
        destroyOnClose>
        <TermPreview terms={terms} showPreviewIfEmpty>
          <Padding>
            <Form<CreateOrUpdateNegotiationTemplateDTO>
              form={form}
              layout="vertical"
              initialValues={{
                name: negotiation.title,
                description: '',
                negotiationTemplateTerms: terms,
              }}>
              <Form.Item label="Title" name="name" required>
                <Input placeholder="Template title" />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea rows={7} placeholder="Enter a description for more information" />
              </Form.Item>
              <Form.Item name="negotiationTemplateTerms" hidden>
                <Input />
              </Form.Item>
            </Form>
          </Padding>
        </TermPreview>
      </Modal>
    </div>
  );
};

const Padding = styled.div`
  padding: 10px 20px;
`;
