import LabelWrapper from '../../../../atoms/LabelWrapper';
import Input from '../../../../atoms/Input';
import Country from '../../../../model/Country';
import {getErrorMessage, hasError} from '../../../../utils/helper';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import NumberRangeInput from '../../../RangeInput/NumberRangeInput';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './GeneralDefinition';
import {MultiSelect} from '../../../../atoms/Select/MultiSelect';
import {Radio} from 'antd';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const General = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <div className="container">
        <div className="row">
          <div className="scol-12">
            <LabelWrapper
              label={'Vessel name'}
              htmlFor={'filter-general-name'}
              hasError={hasError('name', validations)}>
              <Input
                id={'filter-general-name'}
                onChange={value => handleChange('name', value)}
                value={values.name}
                hasError={hasError('name', validations)}
                autoFocus
              />
            </LabelWrapper>
          </div>
        </div>
        <div className="row">
          <div className="scol-12">
            <LabelWrapper label={'IMO'} htmlFor={'filter-general-imo'} hasError={hasError('imo', validations)}>
              <Input
                id={'filter-general-imo'}
                onChange={value => handleChange('imo', value)}
                value={values.imo}
                hasError={hasError('imo', validations)}
                maxLength={7}
              />
            </LabelWrapper>
          </div>
        </div>
        <div className="row">
          <div className="scol-12">
            <NumberRangeInput
              label={'Year built'}
              onFromChange={value => handleChange('minimumAge', value)}
              onToChange={value => handleChange('maximumAge', value)}
              fromValue={values.minimumAge}
              toValue={values.maximumAge}
              allowEqualMinMax
              fromProps={{
                placeholder: 'min year',
                hasError: hasError('minimumAge', validations),
              }}
              fromError={getErrorMessage('minimumAge', validations)}
              toProps={{
                placeholder: 'max year',
                hasError: hasError('maximumAge', validations),
              }}
              toError={getErrorMessage('maximumAge', validations)}
            />
          </div>
        </div>
        <HorizontalLine large topSpace={'small'} />
        <div className="row">
          <div className="scol-12">
            <LabelWrapper label={'Flags'} htmlFor={'filter-general-flags'}>
              <MultiSelect
                id={'filter-general-flags'}
                value={values.flags}
                onChange={value => handleChange('flags', [...value])}
                options={Country.getList()}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.code}
              />
            </LabelWrapper>
          </div>
        </div>
        <Radio.Group
          onChange={event => {
            handleChange('includeFlags', event.target.value);
          }}
          value={Boolean(values.includeFlags)}
          style={{boxShadow: 'none', width: '100%'}}>
          <div className="row">
            <div className="scol-6">
              <Radio id={'filter-excluding'} value={false}>
                Excluding
              </Radio>
            </div>
            <div className="scol-6">
              <Radio id={'filter-limiting'} value={true}>
                Limiting
              </Radio>
            </div>
          </div>
        </Radio.Group>
      </div>
    )}
  </FilterButton>
);

export default General;
