import {SpotifindActions} from '../../redux/Spotifind';
import isEqual from 'lodash/isEqual';
import {FilterCategory, FilterType} from '../../api/symfony/generated';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {useUpdateFilterMutation} from '../../queries/filter/useUpdateFilterMutation';
import {SpotifindDatabaseFilter} from '../../components/FilterProvider/Filters/Spotifind/SpotifindFilterBranchDefinitions';

export const useUpdateSpotifindFilter = () => {
  const filterState = useSelector(state => state.spotifind.filterState);
  const dispatch = useDispatch();
  const updateFilterMutation = useUpdateFilterMutation();

  const updateSpotifindFilter = async (filterSettings: SpotifindDatabaseFilter) => {
    dispatch(SpotifindActions.setFilterSettings(filterSettings));
    if (!isEqual(filterSettings, filterState.filter)) {
      updateFilterMutation.mutate({
        id: filterState.filterId!,
        filterItemWrite: {
          name: 'My spotifind filter',
          category: FilterCategory.Spotifind,
          type: FilterType.Vessel,
          filterSettings: filterSettings,
        },
      });
    }
  };

  return updateSpotifindFilter;
};
