import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {FOCUS_COMMAND, COMMAND_PRIORITY_EDITOR, BLUR_COMMAND} from 'lexical';
import {mergeRegister} from '@lexical/utils';
import {useLayoutEffect} from 'react';

export const useLexicalEditorFocusEvents = ({onFocus, onBlur}: {onFocus: () => void; onBlur: () => void}) => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocus();
          return false;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onBlur();
          return false;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor, onFocus, onBlur]);
};
