import React, {FC, ReactNode} from 'react';
import styled, {css} from 'styled-components';
import {TermIndexDisplayNumber} from '../TermIndex';
import {TermTypeLabelText} from '../TermType/TermTypeLabel';

type TermContentProps = {
  text?: string;
  previousText?: string;
  showDiff?: boolean;
  compact?: boolean;
  interactive?: boolean;
  onTextClick?: () => void;
  aboveTextAddon?: ReactNode;
  beforeTextAddon?: ReactNode;
  afterTextAddon?: ReactNode;
  textReplacement?: ReactNode;
  isRejected?: boolean;
  isRejectRequested?: boolean;
};

export const TermContent: FC<TermContentProps> = ({
  text,
  compact,
  interactive,
  onTextClick,
  aboveTextAddon,
  beforeTextAddon,
  afterTextAddon,
  textReplacement,
  isRejected,
  isRejectRequested,
}) => {
  return (
    <TermContentContainer
      data-testid="TermContent"
      $interactive={interactive}
      $compact={compact}
      $isRejected={isRejected}
      $isRejectRequested={isRejectRequested}>
      {beforeTextAddon}
      <TermTextContainer onClick={onTextClick} data-testid="TermText">
        {textReplacement ?? (
          <TermText>
            {aboveTextAddon}
            {text}
          </TermText>
        )}
      </TermTextContainer>
      {afterTextAddon}
    </TermContentContainer>
  );
};

export const TermContentContainer = styled.div<{
  $interactive?: boolean;
  $compact?: boolean;
  $isRejected?: boolean;
  $isRejectRequested?: boolean;
}>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 32px;
  padding: 3px 10px;
  background: var(--color-self-bg);
  border-radius: var(--border-radius);
  border: var(--border-width-active) dashed transparent;
  white-space: break-spaces;
  transition: all 0.2s;
  overflow: hidden;

  ${({$interactive}) =>
    $interactive
      ? css`
          &:hover {
            background: var(--term-color-border);
          }
        `
      : css`
          background: var(--term-color-background);
        `};

  ${({$compact}) =>
    $compact
      ? css`
          margin-bottom: 3px;
          opacity: 0.8;
        `
      : ''};

  ${({$isRejectRequested}) =>
    $isRejectRequested &&
    css`
      ${TermTextContainer}, ${TermTypeLabelText}, ${TermIndexDisplayNumber} {
        text-decoration: line-through;
        text-decoration-style: dashed;
        text-decoration-color: rgba(0, 0, 0, 0.5);
      }
    `}

  ${({$isRejected}) =>
    $isRejected &&
    css`
      ${TermTextContainer}, ${TermTypeLabelText}, ${TermIndexDisplayNumber} {
        text-decoration: line-through;
        text-decoration-style: solid;
        text-decoration-color: rgba(0, 0, 0, 0.8);
      }
    `}
`;

export const TermTextContainer = styled.div<{onClick?: () => void}>`
  width: 100%;
  margin: 0;
  padding: 1px;
  ${({onClick}) => onClick && `cursor: pointer;`}
`;

const TermText = styled.div`
  margin: 0;
  font-family: var(--font-family-base) !important;
  white-space: break-spaces;
  line-height: 1.125 !important;
`;
