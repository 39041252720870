/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanySummary
 */
export interface CompanySummary {
  /**
   *
   * @type {number}
   * @memberof CompanySummary
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CompanySummary
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CompanySummary
   */
  verified: boolean;
  /**
   *
   * @type {string}
   * @memberof CompanySummary
   */
  subscriptionPlan?: string;
  /**
   *
   * @type {Date}
   * @memberof CompanySummary
   */
  updatedAt: Date;
  /**
   *
   * @type {Date}
   * @memberof CompanySummary
   */
  createdAt: Date;
  /**
   * This property is deprecated and is replaced by the subscription plan.
   * @type {boolean}
   * @memberof CompanySummary
   */
  isPayingCustomer: boolean;
}

export function CompanySummaryFromJSON(json: any): CompanySummary {
  return CompanySummaryFromJSONTyped(json, false);
}

export function CompanySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    verified: json['verified'],
    subscriptionPlan: !exists(json, 'subscriptionPlan') ? undefined : json['subscriptionPlan'],
    updatedAt: new Date(json['updatedAt']),
    createdAt: new Date(json['createdAt']),
    isPayingCustomer: json['isPayingCustomer'],
  };
}

export function CompanySummaryToJSON(value?: CompanySummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    verified: value.verified,
    subscriptionPlan: value.subscriptionPlan,
    updatedAt: value.updatedAt.toISOString(),
    createdAt: value.createdAt.toISOString(),
    isPayingCustomer: value.isPayingCustomer,
  };
}
