import {SelectionLayer} from '@nebula.gl/layers';
import {CompositeLayer} from '@deck.gl/core/typed';
import {TODO} from '../../../../utils/TODO';

interface Props {
  layerIds: string[];
  onSelect: (pickingInfos: TODO) => void;
}

class SelectLayer extends CompositeLayer<Props> {
  // @ts-ignore type stimmt nicht
  renderLayers() {
    // @ts-ignore type stimmt nicht
    const selectionLayer = new SelectionLayer({
      id: `selection-layer-selection`,

      onSelect: ({pickingInfos}: TODO) => {
        this.props.onSelect(pickingInfos);
      },
      layerIds: this.props.layerIds,
      // @ts-ignore Object literal may only specify known properties, and 'getTentativeFillColor' does not exist in type 'SelectionLayerProps'
      getTentativeFillColor: () => [255, 0, 255, 100],
      getTentativeLineColor: () => [0, 0, 255, 255],
      getTentativeLineDashArray: () => [0, 0],
      lineWidthMinPixels: 3,
    });
    return [selectionLayer];
  }
}

SelectLayer.layerName = 'SelectLayer';

export default SelectLayer;
