import {
  MarketCargoDatabaseFilter,
  MarketCargoFilterBranchDefinitions,
} from '../../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';
import {useFilterProvider} from '../../../components/FilterProvider/useFilterProvider';
import {FilterProviderState} from '../../../components/FilterProvider/FilterProviderState';
import {MarketFiltersActions} from '../../../redux/MarketFilters';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {useSetPage} from '../utils/useSetGridMetaData';

export const useMarketCargoFilterProvider = () => {
  const dispatch = useDispatch();

  const filterProviderState = useSelector(state => state.marketFilters.cargoFilterProviderState);

  const setPage = useSetPage('cargo');

  const onFilterSettingsChange = (filterSettings: MarketCargoDatabaseFilter) => {
    setCargoFilterSettings(filterSettings);
  };

  const onFilterSettingsChangeByUser = () => {
    setPage(1);
  };

  const setCargoFilterProviderState = (filters: FilterProviderState<typeof MarketCargoFilterBranchDefinitions>) =>
    dispatch(MarketFiltersActions.setCargoFilterProviderState(filters));

  const setCargoFilterSettings = (filterSettings: MarketCargoDatabaseFilter) =>
    dispatch(MarketFiltersActions.setCargoFilterSettings(filterSettings));

  const filterProviderApi = useFilterProvider({
    name: 'market/cargo',
    filterBranchDefinitions: MarketCargoFilterBranchDefinitions,
    filterProviderState,
    onFilterSettingsChange,
    onFilterSettingsChangeByUser,
    onFilterProviderStateChange: setCargoFilterProviderState,
  });

  return filterProviderApi;
};
