import React, {Fragment} from 'react';
import BindToProvider, {WrappedDetailsSection} from '../../../../components/FormProvider/BindToProvider';
import {acceptIntOrEmpty} from '../../../../utils/formatter';
import {
  alwaysValid,
  numberBetween,
  numberSmallerThan,
  requiredNumberBetween,
  selectUnitFor,
  stringLengthLessThanOrEqualTo,
} from '../../../../utils/validators';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import Input from '../../../../atoms/Input';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import {IntakeRows} from '../../utils/IntakeSection/IntakeRows';
import Select from '../../../../atoms/Select/Select';

const selectQuantityUnit = (value: $TSFixMe, section: $TSFixMe) =>
  (String(section['quantityMin']).trim() !== '' || String(section['quantityMax']).trim()) &&
  !value &&
  'Please select a unit';

const quantityUnitTypes = {
  cbm: 'CBM',
  mt: 'MT',
};

const toleranceUnitTypes = {
  '%': '%',
  mt: 'MT',
};

const quantityOptionTypes = {
  'in owners option': 'in owners option',
  'in charterers option': 'in charterers option',
};

const newIntake = {
  type: 'teu',
  quantity: '',
  isHighCube: false,
  isEmpty: false,
};

const checkTolerance = (value: $TSFixMe, {toleranceUnit}: $TSFixMe) => {
  return numberBetween(0, toleranceUnit === '%' ? 99 : 999999, undefined)(value);
};

export const cargoQuantitySectionDefaultValues = {
  intakes: [newIntake],
  quantityMin: '',
  quantityMax: '',
  quantityUnit: 'mt',
  tolerance: '',
  toleranceUnit: '',
  quantityOption: '',
};

const Wrapper = BindToProvider(
  'Quantity',
  'quantity',
  cargoQuantitySectionDefaultValues,
  {
    intakes: alwaysValid,
    quantityMin: numberSmallerThan('quantityMax', undefined),
    quantityUnit: selectQuantityUnit,
    tolerance: checkTolerance,
    toleranceUnit: selectUnitFor('tolerance', undefined),
    quantityOption: stringLengthLessThanOrEqualTo(127),
    row: {
      quantity: requiredNumberBetween(1, 99999),
    },
  },
  {
    toApi: (
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
      fields,
      {marketSegment, contractType}: $TSFixMe
    ) => ({
      ...fields,
      quantityMin: fields.quantityMin && parseInt(fields.quantityMin),
      quantityMax: fields.quantityMax && parseInt(fields.quantityMax),
      tolerance: fields.tolerance && parseInt(fields.tolerance),

      intakes:
        // eslint-disable-next-line no-nested-ternary
        marketSegment.cargoType === 'container' && contractType.contractType === 'vc'
          ? fields.intakes
            ? fields.intakes.map((intake: $TSFixMe) => ({
                ...intake,
                quantity: parseFloat(intake.quantity),
              }))
            : []
          : [],
    }),
    fromApi: (fields: $TSFixMe) => ({
      ...fields,

      intakes: fields.intakes
        ? fields.intakes.map((intake: $TSFixMe) => ({
            ...newIntake,

            ...Object.keys(intake).reduce((fields, field) => {
              if (intake[field] || intake[field] === 0) {
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                fields[field] = intake[field];
              }
              return fields;
            }, {}),
          }))
        : [],
    }),
  },
  true
)(WrappedDetailsSection);

const Quantity = (props: $TSFixMe) => (
  <Wrapper {...props}>
    {({onChange, values, validations, validate, validateRow, form}: $TSFixMe) => (
      <div className="container-fluid">
        {form.marketSegment.cargoType && form.marketSegment.cargoType === 'drybulk' && (
          <Fragment>
            <div className="row">
              <div className="scol-12 scol-sm-4">
                <LabelWrapper
                  label={'Min'}
                  htmlFor={'form-quantity-quantity-min'}
                  hasError={validations.quantityMin.invalid}>
                  <Input
                    id={'form-quantity-quantity-min'}
                    onChange={v => acceptIntOrEmpty(v, v => onChange('quantityMin', v), 6)}
                    onKeyUp={() => validate('quantityMin')}
                    value={values.quantityMin}
                    hasError={validations.quantityMin.invalid}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12 scol-sm-4">
                <LabelWrapper
                  label={'Max'}
                  htmlFor={'form-quantity-quantity-max'}
                  hasError={validations.quantityMin.invalid || validations.quantityMax.invalid}>
                  <Input
                    id={'form-quantity-quantity-max'}
                    onChange={v => acceptIntOrEmpty(v, v => onChange('quantityMax', v), 6)}
                    onKeyUp={() => validate('quantityMin')}
                    value={values.quantityMax}
                    hasError={validations.quantityMin.invalid || validations.quantityMax.invalid}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12 scol-sm-4">
                <LabelWrapper
                  label={'Unit'}
                  htmlFor={'form-quantity-quantity-unit'}
                  hasError={validations.quantityUnit.invalid}>
                  <Select
                    id={'form-quantity-quantity-unit'}
                    name={'form-quantity-quantity-unit'}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={{value: values.quantityUnit, name: quantityUnitTypes[values.quantityUnit]}}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    defaultValue={{value: values.quantityUnit, name: quantityUnitTypes[values.quantityUnit]}}
                    onChange={(o: $TSFixMe) => onChange('quantityUnit', o.value)}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    options={Object.keys(quantityUnitTypes).map(value => ({value, name: quantityUnitTypes[value]}))}
                    getOptionLabel={(option: $TSFixMe) => option.name}
                    getOptionValue={(option: $TSFixMe) => option.value}
                    components={{
                      DropdownIndicator: ArrowIndicator,
                    }}
                    hasError={validations.quantityUnit.invalid}
                    isSearchable={false}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12">
                {validations.quantityMin.error && (
                  <p className="text-danger text-danger--no-margin">{validations.quantityMin.error}</p>
                )}
                {validations.quantityMax.error && (
                  <p className="text-danger text-danger--no-margin">{validations.quantityMax.error}</p>
                )}
                {validations.quantityUnit.error && (
                  <p className="text-danger text-danger--no-margin">{validations.quantityUnit.error}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="scol-12 scol-sm-4">
                <LabelWrapper
                  label={'Tolerance'}
                  htmlFor={'form-quantity-tolerance'}
                  hasError={validations.tolerance.invalid}>
                  <Input
                    id={'form-quantity-tolerance'}
                    onChange={v =>
                      acceptIntOrEmpty(v, v => onChange('tolerance', v), values.toleranceUnit === '%' ? 2 : 6)
                    }
                    onKeyUp={() => props.validateSections(['quantity'])}
                    value={values.tolerance}
                    hasError={validations.tolerance.invalid}
                  />
                </LabelWrapper>
                {validations.tolerance.error && (
                  <p className="text-danger text-danger--no-margin">{validations.tolerance.error}</p>
                )}
              </div>
              <div className="scol-12 scol-sm-4">
                <LabelWrapper
                  label={'Unit'}
                  htmlFor={'form-quantity-tolerance-unit'}
                  hasError={validations.toleranceUnit.invalid}>
                  <Select
                    id={'form-quantity-tolerance-unit'}
                    name={'form-quantity-tolerance-unit'}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={{value: values.toleranceUnit, name: toleranceUnitTypes[values.toleranceUnit]}}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    defaultValue={{value: values.toleranceUnit, name: toleranceUnitTypes[values.toleranceUnit]}}
                    onChange={(o: $TSFixMe) =>
                      onChange('toleranceUnit', o.value, () => props.validateSections(['quantity']))
                    }
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    options={Object.keys(toleranceUnitTypes).map(value => ({value, name: toleranceUnitTypes[value]}))}
                    getOptionLabel={(option: $TSFixMe) => option.name}
                    getOptionValue={(option: $TSFixMe) => option.value}
                    components={{
                      DropdownIndicator: ArrowIndicator,
                    }}
                    hasError={validations.toleranceUnit.invalid}
                    isSearchable={false}
                  />
                </LabelWrapper>
                {validations.toleranceUnit.error && (
                  <p className="text-danger text-danger--no-margin">{validations.toleranceUnit.error}</p>
                )}
              </div>
              <div className="scol-12 scol-sm-4">
                <LabelWrapper label={'Option'} htmlFor={'form-quantity-quantity-option'}>
                  <Select
                    id={'form-quantity-quantity-option'}
                    name={'form-quantity-quantity-option'}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={{value: values.quantityOption, name: quantityOptionTypes[values.quantityOption]}}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    defaultValue={{value: values.quantityOption, name: quantityOptionTypes[values.quantityOption]}}
                    onChange={(o: $TSFixMe) => onChange('quantityOption', o.value)}
                    options={Object.keys(quantityOptionTypes).map(value => ({
                      value,
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      name: quantityOptionTypes[value],
                    }))}
                    getOptionLabel={(option: $TSFixMe) => option.name}
                    getOptionValue={(option: $TSFixMe) => option.value}
                    components={{
                      DropdownIndicator: ArrowIndicator,
                    }}
                    isSearchable={false}
                  />
                </LabelWrapper>
              </div>
            </div>
          </Fragment>
        )}
        {form.marketSegment.cargoType && form.marketSegment.cargoType === 'container' && (
          <Fragment>
            <IntakeRows
              intakes={values.intakes}
              validation={validations.intakes}
              onChange={(values: $TSFixMe) => onChange('intakes', values)}
              validateRow={validateRow}
              contractTypeVC={props.contractType === 'vc'}
            />
          </Fragment>
        )}
      </div>
    )}
  </Wrapper>
);

export default Quantity;
