/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CreateOrUpdateNegotiationTemplateTermDTO,
  CreateOrUpdateNegotiationTemplateTermDTOFromJSON,
  CreateOrUpdateNegotiationTemplateTermDTOFromJSONTyped,
  CreateOrUpdateNegotiationTemplateTermDTOToJSON,
} from './';

/**
 *
 * @export
 * @interface CreateOrUpdateNegotiationTemplateDTO
 */
export interface CreateOrUpdateNegotiationTemplateDTO {
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateNegotiationTemplateDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateNegotiationTemplateDTO
   */
  description: string;
  /**
   * Only relevant if the user can create global templates. If true, the template will be available for all users. If false, the template will only be available for the user's company. If the user can only create company templates, this field will be ignored.
   * @type {boolean}
   * @memberof CreateOrUpdateNegotiationTemplateDTO
   */
  isGlobalTemplate?: boolean;
  /**
   *
   * @type {Array<CreateOrUpdateNegotiationTemplateTermDTO>}
   * @memberof CreateOrUpdateNegotiationTemplateDTO
   */
  negotiationTemplateTerms: Array<CreateOrUpdateNegotiationTemplateTermDTO>;
}

export function CreateOrUpdateNegotiationTemplateDTOFromJSON(json: any): CreateOrUpdateNegotiationTemplateDTO {
  return CreateOrUpdateNegotiationTemplateDTOFromJSONTyped(json, false);
}

export function CreateOrUpdateNegotiationTemplateDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateOrUpdateNegotiationTemplateDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    description: json['description'],
    isGlobalTemplate: !exists(json, 'isGlobalTemplate') ? undefined : json['isGlobalTemplate'],
    negotiationTemplateTerms: (json['negotiationTemplateTerms'] as Array<any>).map(
      CreateOrUpdateNegotiationTemplateTermDTOFromJSON
    ),
  };
}

export function CreateOrUpdateNegotiationTemplateDTOToJSON(value?: CreateOrUpdateNegotiationTemplateDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    isGlobalTemplate: value.isGlobalTemplate,
    negotiationTemplateTerms: (value.negotiationTemplateTerms as Array<any>).map(
      CreateOrUpdateNegotiationTemplateTermDTOToJSON
    ),
  };
}
