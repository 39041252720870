/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  GetChokepointCongestionStatisticsResponse,
  GetChokepointCongestionStatisticsResponseFromJSON,
  GetChokepointCongestionStatisticsResponseToJSON,
  GetChokepointCongestionWidgetStatisticsResponse,
  GetChokepointCongestionWidgetStatisticsResponseFromJSON,
  GetChokepointCongestionWidgetStatisticsResponseToJSON,
} from '../models';

export interface GetStatisticsRequest {
  chokepoint: string;
  timestamp?: number;
}

/**
 *
 */
export class ChokepointCongestionApi extends runtime.BaseAPI {
  /**
   * Returns all valid visits and a statistic for the last 60 days of one area.
   */
  async getStatisticsRaw(
    requestParameters: GetStatisticsRequest
  ): Promise<runtime.ApiResponse<GetChokepointCongestionStatisticsResponse>> {
    if (requestParameters.chokepoint === null || requestParameters.chokepoint === undefined) {
      throw new runtime.RequiredError(
        'chokepoint',
        'Required parameter requestParameters.chokepoint was null or undefined when calling getStatistics.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.chokepoint !== undefined) {
      queryParameters['chokepoint'] = requestParameters.chokepoint;
    }

    if (requestParameters.timestamp !== undefined) {
      queryParameters['timestamp'] = requestParameters.timestamp;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/chokepoint-congestion/statistics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetChokepointCongestionStatisticsResponseFromJSON(jsonValue)
    );
  }

  /**
   * Returns all valid visits and a statistic for the last 60 days of one area.
   */
  async getStatistics(requestParameters: GetStatisticsRequest): Promise<GetChokepointCongestionStatisticsResponse> {
    const response = await this.getStatisticsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns a statistic for all chokepoints.
   */
  async getWidgetStatisticsRaw(): Promise<runtime.ApiResponse<GetChokepointCongestionWidgetStatisticsResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/chokepoint-congestion/widget-statistics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetChokepointCongestionWidgetStatisticsResponseFromJSON(jsonValue)
    );
  }

  /**
   * Returns a statistic for all chokepoints.
   */
  async getWidgetStatistics(): Promise<GetChokepointCongestionWidgetStatisticsResponse> {
    const response = await this.getWidgetStatisticsRaw();
    return await response.value();
  }
}
