/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AisVesselResponseData,
  AisVesselResponseDataFromJSON,
  AisVesselResponseDataFromJSONTyped,
  AisVesselResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface AisVesselResponse
 */
export interface AisVesselResponse {
  /**
   *
   * @type {AisVesselResponseData}
   * @memberof AisVesselResponse
   */
  data: AisVesselResponseData;
}

export function AisVesselResponseFromJSON(json: any): AisVesselResponse {
  return AisVesselResponseFromJSONTyped(json, false);
}

export function AisVesselResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisVesselResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: AisVesselResponseDataFromJSON(json['data']),
  };
}

export function AisVesselResponseToJSON(value?: AisVesselResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: AisVesselResponseDataToJSON(value.data),
  };
}
