import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Lighthouse from '../../../../atoms/EmptyState/LightHouse';
import LoadingComponent from '../../../DataGrid/LoadingComponent';

export const MapDetailsErrorContainer = styled.div`
  text-align: center;
  padding-top: 60px;
  font-size: var(--font-size-lg);
`;

export const MapDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 14px 70px 14px 20px;
  border-bottom: var(--border-width) solid var(--color-gray-4);
  box-shadow: var(--map-header-shadow);
  z-index: var(--map-header-z);
`;

export const ActionButtonLeft = styled.div`
  margin: 0 14px 0 -5px;
  color: var(--color-gray-2);
  font-size: var(--font-size-xl);
  cursor: pointer;
`;

export const MapDetailsTitle = styled.h3`
  display: block;
  align-self: flex-start;
  padding: 1px 0;
  margin: 0 auto 0 0;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-weight: bold;
  line-height: 1;
`;

export const MapDetailLink = styled(Link)`
  margin: 0 auto 0 0;

  &:hover {
    color: var(--color-gray-1) !important;
  }

  &,
  &:active {
    font-weight: inherit;
  }
`;

export const MapDetailsSubtitle = styled.span<{$isEllipsis?: boolean}>`
  display: block;
  line-height: 17px;
  color: var(--color-gray-2);
  font-size: var(--font-size-md);
  padding-top: 2px;

  ${({$isEllipsis}) =>
    $isEllipsis
      ? `
    padding-right: 30px;
    display: block;
    display: -webkit-box;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `
      : ''}
`;

export const MapDetailsClose = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  z-index: 7;

  .icon {
    color: var(--color-gray-2);
    font-size: var(--font-size-2xl);
  }
`;

export const MapDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  overflow-y: auto;
  z-index: 0;
`;

export const MapDetailsLoading = (
  <>
    <MapDetailsHeader>
      <MapDetailsTitle>
        Loading...
        <MapDetailsSubtitle>Retrieving seabo data.</MapDetailsSubtitle>
      </MapDetailsTitle>
    </MapDetailsHeader>
    <MapDetailsContent>
      <LoadingComponent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}} />
    </MapDetailsContent>
  </>
);

export const MapDetailsError = (
  <>
    <MapDetailsHeader>
      <MapDetailsTitle>
        Sorry, there was an error.
        <MapDetailsSubtitle>Could not load data.</MapDetailsSubtitle>
      </MapDetailsTitle>
    </MapDetailsHeader>
    <MapDetailsContent>
      <MapDetailsErrorContainer>
        <Lighthouse />
        Please try again.
      </MapDetailsErrorContainer>
    </MapDetailsContent>
  </>
);
