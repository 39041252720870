import {Middleware, ResponseContext} from '../symfony/generated';
import {ApiError} from './ApiError';
import {ValidationError} from './ValidationError';
import {UnauthorizedError} from './UnauthorizedError';
import {loadResponseJson} from './loadResponseJson';

const ValidationProblemType = 'https://symfony.com/errors/validation';

/**
 * This middleware for the generated API runs after every fetch and throws nice errors if needed.
 * Normally the API would throw fetch's Response object as error object, which is inconvenient.
 */
export const apiErrorMiddleware: Middleware = {
  async post(context: ResponseContext): Promise<Response> {
    const {init, url, response} = context;

    const contentType = response.headers.get('Content-Type');

    if (response.status === 401 && !url.endsWith('/api/users/me')) {
      // A 401 means that the user lost her session -  possibly because she got logged out forcefully.
      // Send her to the login page.
      // I have tried to do this in a more central place, but none of the usual techniques worked.
      // The check for "/api/users/me" prevents an endless redirect loop on the login page.
      // eslint-disable-next-line no-console
      console.warn('No session - redirecting to login page.');
      window.location.pathname = '/login';
      throw new UnauthorizedError(`Unauthorized fetch("${response.url}"})`);
    }

    if (contentType === 'application/problem+json') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const body = await loadResponseJson<any>(response, init?.method ?? 'GET', url);
      const problemType = body.type;
      if (!problemType) {
        throw new ApiError(`API fetch("${response.url}"): ${body.title}`, response.status, body);
      }
      if (problemType === ValidationProblemType) {
        throw new ValidationError('Validation error', body, response.status);
      }
      throw new ApiError(
        `API fetch("${response.url}") failed with unknown problem+json type ${problemType}`,
        response.status
      );
    }

    if (!response.ok) {
      throw new ApiError(
        `API fetch("${response.url}", {method: "${init.method}"}) failed: ${response.status}/${response.statusText}`,
        response.status
      );
    }

    return context.response;
  },
};
