import {negotiationAttachmentApi} from '../../../../../api/node-backend/nodeBackendApis';
import fileSaver from 'file-saver';
import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {NegotiationAttachment} from '../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../api/utils/ApiError';

export const useDownloadAttachmentMutation = (
  options?: UseMutationOptions<void, ApiError, {negotiationId: string; attachment: NegotiationAttachment}>
) => {
  return useMutation({
    mutationFn: async ({negotiationId, attachment}: {negotiationId: string; attachment: NegotiationAttachment}) => {
      const result = await negotiationAttachmentApi.downloadAttachment({
        negotiationId: negotiationId,
        attachmentId: attachment.id,
      });

      fileSaver.saveAs(result, attachment.title);
    },
    ...options,
  });
};
