import {FC} from 'react';
import {Select, SelectProps} from 'antd';

const {Option} = Select;

type Props = Pick<SelectProps<string>, 'onChange' | 'style' | 'value'>;

export const TimezoneSelector: FC<Props> = selectProps => (
  <Select {...selectProps}>
    {timezones.map((timezone, index) => (
      <Option key={index} value={timezone.id}>
        {timezone.label}
      </Option>
    ))}
  </Select>
);

interface Timezone {
  id: string;
  label: string;
}

const timezones: Timezone[] = [
  {id: 'Pacific/Samoa', label: '(UTC-11:00) American Samoa, Midway'},
  {id: 'Pacific/Honolulu', label: '(UTC-10:00) Hawaii'},
  {id: 'America/Anchorage', label: '(UTC-09:00) Alaska'},
  {id: 'America/Los_Angeles', label: '(UTC-08:00) Pacific Time, Tijuana'},
  {id: 'America/Phoenix', label: '(UTC-07:00) Phoenix'},
  {id: 'America/Chihuahua', label: '(UTC-07:00) Chihuahua, La Paz, Mazatlán'},
  {id: 'America/Denver', label: '(UTC-07:00) Mountain Time'},
  {id: 'America/Chicago', label: '(UTC-06:00) Central Time'},
  {id: 'America/Mexico_City', label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey'},
  {id: 'America/Tegucigalpa', label: '(UTC-06:00) Middle America'},
  {id: 'America/Regina', label: '(UTC-06:00) Saskatchewan'},
  {id: 'America/Bogota', label: '(UTC-05:00) Bogotá, Lima, Quito'},
  {id: 'America/New_York', label: '(UTC-05:00) Eastern Time'},
  {id: 'America/Indiana/Indianapolis', label: '(UTC-05:00) Indianapolis'},
  {id: 'America/Rio_Branco', label: '(UTC-05:00) Rio Branco'},
  {id: 'America/Caracas', label: '(UTC-04:30) Caracas'},
  {id: 'America/Asuncion', label: '(UTC-04:00) Asunción'},
  {id: 'America/Halifax', label: '(UTC-04:00) Halifax, Nova Scotia'},
  {id: 'America/Cuiaba', label: '(UTC-04:00) Cuiabá'},
  {id: 'America/La_Paz', label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'},
  {id: 'America/Santiago', label: '(UTC-04:00) Santiago'},
  {id: 'America/St_Johns', label: '(UTC-03:30) Newfoundland'},
  {id: 'America/Sao_Paulo', label: '(UTC-03:00) Brasília'},
  {id: 'America/Argentina/Buenos_Aires', label: '(UTC-03:00) Buenos Aires'},
  {id: 'America/Cayenne', label: '(UTC-03:00) Cayenne, Fortaleza'},
  {id: 'America/Godthab', label: '(UTC-03:00) Greenland'},
  {id: 'America/Montevideo', label: '(UTC-03:00) Montevideo'},
  {id: 'Atlantic/South_Georgia', label: '(UTC-02:00) South Georgia and the South Sandwich Islands'},
  {id: 'Atlantic/Azores', label: '(UTC-01:00) Azores'},
  {id: 'Atlantic/Cape_Verde', label: '(UTC-01:00) Cape Verde'},
  {id: 'Africa/Casablanca', label: '(UTC) Casablanca'},
  {id: 'Europe/London', label: '(UTC) Dublin, Edinburgh, Lisbon, London'},
  {id: 'Africa/Monrovia', label: '(UTC) Monrovia, Reykjavík'},
  {id: 'Europe/Berlin', label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'},
  {id: 'Europe/Belgrade', label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'},
  {id: 'Europe/Paris', label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'},
  {id: 'Europe/Sarajevo', label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'},
  {id: 'Africa/Algiers', label: '(UTC+01:00) West-Central Africa'},
  {id: 'Africa/Windhoek', label: '(UTC+01:00) Windhoek'},
  {id: 'Europe/Athens', label: '(UTC+02:00) Athens, Bucharest, Istanbul'},
  {id: 'Asia/Beirut', label: '(UTC+02:00) Beirut'},
  {id: 'Asia/Damascus', label: '(UTC+02:00) Damascus'},
  {id: 'Africa/Harare', label: '(UTC+02:00) Harare, Pretoria'},
  {id: 'Europe/Helsinki', label: '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius'},
  {id: 'Asia/Jerusalem', label: '(UTC+02:00) Jerusalem'},
  {id: 'Africa/Cairo', label: '(UTC+02:00) Cairo'},
  {id: 'Europe/Kaliningrad', label: '(UTC+02:00) Kaliningrad'},
  {id: 'Asia/Amman', label: '(UTC+03:00) Amman'},
  {id: 'Asia/Baghdad', label: '(UTC+03:00) Baghdad'},
  {id: 'Europe/Minsk', label: '(UTC+03:00) Minsk'},
  {id: 'Europe/Moscow', label: '(UTC+03:00) Moscow, Saint Petersburg, Volgograd'},
  {id: 'Asia/Kuwait', label: '(UTC+03:00) Kuwait, Riyadh'},
  {id: 'Africa/Nairobi', label: '(UTC+03:00) Nairobi'},
  {id: 'Asia/Tehran', label: '(UTC+03:30) Tehran'},
  {id: 'Asia/Muscat', label: '(UTC+04:00) Abu Dhabi, Muscat'},
  {id: 'Asia/Baku', label: '(UTC+04:00) Baku'},
  {id: 'Asia/Yerevan', label: '(UTC+04:00) Yerevan'},
  {id: 'Indian/Mauritius', label: '(UTC+04:00) Port Louis'},
  {id: 'Asia/Tbilisi', label: '(UTC+04:00) Tbilisi'},
  {id: 'Asia/Kabul', label: '(UTC+04:30) Kabul'},
  {id: 'Asia/Karachi', label: '(UTC+05:00) Islamabad, Karachi'},
  {id: 'Asia/Yekaterinburg', label: '(UTC+05:00) Yekaterinburg'},
  {id: 'Asia/Tashkent', label: '(UTC+05:00) Tashkent'},
  {id: 'Asia/Kolkata', label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'},
  {id: 'Asia/Colombo', label: '(UTC+05:30) Sri Jayawardenapura'},
  {id: 'Asia/Katmandu', label: '(UTC+05:45) Kathmandu'},
  {id: 'Asia/Almaty', label: '(UTC+06:00) Astana'},
  {id: 'Asia/Dhaka', label: '(UTC+06:00) Dhaka'},
  {id: 'Asia/Novosibirsk', label: '(UTC+06:00) Novosibirsk'},
  {id: 'Asia/Rangoon', label: '(UTC+06:30) Yangon (Rangoon)'},
  {id: 'Asia/Bangkok', label: '(UTC+07:00) Bangkok, Hanoi, Jakarta'},
  {id: 'Asia/Krasnoyarsk', label: '(UTC+07:00) Krasnoyarsk'},
  {id: 'Asia/Irkutsk', label: '(UTC+08:00) Irkutsk'},
  {id: 'Asia/Kuala_Lumpur', label: '(UTC+08:00) Kuala Lumpur, Singapore'},
  {id: 'Asia/Chongqing', label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Ürümqi'},
  {id: 'Australia/Perth', label: '(UTC+08:00) Perth'},
  {id: 'Asia/Taipei', label: '(UTC+08:00) Taipei'},
  {id: 'Asia/Ulaanbaatar', label: '(UTC+08:00) Ulan Bator'},
  {id: 'Asia/Yakutsk', label: '(UTC+09:00) Yakutsk'},
  {id: 'Asia/Tokyo', label: '(UTC+09:00) Osaka, Sapporo, Tokyo'},
  {id: 'Asia/Seoul', label: '(UTC+09:00) Seoul'},
  {id: 'Australia/Adelaide', label: '(UTC+09:30) Adelaide'},
  {id: 'Australia/Darwin', label: '(UTC+09:30) Darwin'},
  {id: 'Australia/Brisbane', label: '(UTC+10:00) Brisbane'},
  {id: 'Australia/Sydney', label: '(UTC+10:00) Canberra, Melbourne, Sydney'},
  {id: 'Pacific/Guam', label: '(UTC+10:00) Guam, Port Moresby'},
  {id: 'Australia/Hobart', label: '(UTC+10:00) Hobart'},
  {id: 'Asia/Vladivostok', label: '(UTC+10:00) Magadan, Vladivostok'},
  {id: 'Pacific/Noumea', label: '(UTC+11:00) Solomon Islands, New Caledonia'},
  {id: 'Pacific/Auckland', label: '(UTC+12:00) Auckland, Wellington'},
  {id: 'Pacific/Fiji', label: '(UTC+12:00) Fiji'},
  {id: 'Pacific/Tongatapu', label: "(UTC+13:00) Nuku'alofa"},
  {id: 'Pacific/Apia', label: '(UTC+13:00) Samoa'},
];
