import React, {useContext, useState} from 'react';
import {SaveFilled} from '@ant-design/icons';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {SaveDialog} from './SaveDialog';
import {VoyageCalculationButton} from '../utils/VoyageCalculationButton';

export const SaveAsNewButton = () => {
  const voyageContext = useContext(VoyageCharterContext);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <VoyageCalculationButton
        data-cy="saveNewButton"
        onClick={() => {
          setShowModal(true);
        }}
        type={'primary'}
        disabled={voyageContext.state.metaState.savedLastChanges}
        icon={<SaveFilled />}>
        Save as
      </VoyageCalculationButton>
      {showModal && <SaveDialog visible={showModal} onCancel={() => setShowModal(false)} />}
    </>
  );
};
