import {UserColumn} from '../../atoms/UserColumn/UserColumn';
import './styles.scss';
import {
  aisVesselDestinationColumn,
  aisVesselEtaColumn,
  aisVesselImoMmsiColumn,
  aisVesselNameWithTypeColumn,
  aisVesselSpeedColumn,
} from './aisVesselGridHelper';
import {ShipNameAccessorOptions, shipNameColumn} from './columns/vessel/shipNameColumn/shipNameColumn';
import {dwtColumn} from './columns/vessel/dwtColumn';
import {grainColumn, GrainColumnAccessorOptions} from './columns/vessel/grainColumn';
import {gearsColumn, GearsColumnAccessorOptions} from './columns/vessel/gearsColumn';
import {lengthColumn, LengthColumnAccessorOptions} from './columns/vessel/lengthColumn';
import {draftColumn, DraftColumnAccessorOptions} from './columns/vessel/draftColumn';
import {teuColumn} from './columns/vessel/teuColumn';
import {reeferColumn} from './columns/vessel/reeferPlugsColumns';
import {chartererNameColumn} from './columns/cargo/chartererNameColumn';
import {sizeColumn} from './columns/cargo/sizeColumn';
import {commodityColumn} from './columns/cargo/commodityColumn';
import {durationColumn} from './columns/cargo/durationColumn';
import {laycanColumn} from './columns/cargo/laycanColumn';
import {loadDeliveryColumn} from './columns/cargo/loadDeliveryColumn';
import {dischargeRedeliveryColumn} from './columns/cargo/dischargeRedeliveryColumn';
import {commissionColumn} from './columns/cargo/commissionColumn';
import {commentColumn} from './columns/cargo/commentColumn';
import {aisVesselDraftColumn} from './columns/aisVessel/aisVesselDraftColumn';
import {aisVesselStatusColumn} from './columns/aisVessel/aisVesselStatusColumn';
import {aisVesselDwtColumn} from './columns/aisVessel/aisVesselDwtColumn';
import {aisVesselTeuColumn} from './columns/aisVessel/aisVesselTeuColumn';
import {RowValueType} from './utils/RowValueType';
import {UserDateTime} from '../DateTime/UserDateTime';
import {CustomColumnDef} from '../DataGrid/DataGrid';
import {ColumnHeader} from '../DataGrid/ColumnHeader/ColumnHeader';
import {SpotifindVessel} from '../../screens/Spotifind/useGetSpotifindVesselsQuery';
import {SeaboVessel} from '../../api/node-backend/generated';
import {TODO} from '../../utils/TODO';
import {CargoGridColumnsProps} from './columns/cargo/CargoGridColumnsProps';

export const updatedByColumn = ({
  minWidth = 8,
  sortable = true,
}: {
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<TODO> => ({
  header: () => (
    <ColumnHeader sortable data-cy="header-updated">
      Updated
    </ColumnHeader>
  ),
  id: 'updatedAt',
  cell: info => info.getValue(),
  accessorFn: (d: TODO) => (
    <div className={'market-grid-cell'} data-cy="cell-updated">
      <div className={'market-grid-cell-content'}>
        <UserColumn
          // eslint-disable-next-line no-nested-ternary
          userId={d.updatedBy ? d.updatedBy.id : d.user ? d.user.id : null}
          // eslint-disable-next-line no-nested-ternary
          title={d.updatedBy ? d.updatedBy.fullName : d.user ? d.user.fullName : '-'}
        />
      </div>

      <span className={'market-grid-cell-content market-grid-cell-content--sub'}>
        <UserDateTime value={d.updatedAt} format={'date'} source={'server'} />
      </span>
    </div>
  ),
  enableSorting: sortable,
  minWidth,
});

export type VesselGridOptions = {
  shipNameColumn?: ShipNameAccessorOptions;
  grainColumn?: GrainColumnAccessorOptions;
  gearsColumn?: GearsColumnAccessorOptions;
  lengthColumn?: LengthColumnAccessorOptions;
  draftColumn?: DraftColumnAccessorOptions;
};

export const vesselBulkerGrid = (type: RowValueType, options?: VesselGridOptions): CustomColumnDef<SeaboVessel>[] => [
  shipNameColumn({type, options: options?.shipNameColumn}),
  dwtColumn({type}),
  grainColumn({type, options: options?.grainColumn}),
  gearsColumn({type, showQuantity: true, options: options?.gearsColumn}),
  lengthColumn({type, options: options?.lengthColumn}),
  draftColumn({type, options: options?.draftColumn}),
];

export const vesselContainerGrid = (
  type: RowValueType,
  options?: VesselGridOptions
): CustomColumnDef<SeaboVessel>[] => [
  shipNameColumn({type, options: options?.shipNameColumn}),
  teuColumn({type}),
  dwtColumn({type}),
  lengthColumn({type, options: options?.lengthColumn}),
  draftColumn({type, options: options?.draftColumn}),
  reeferColumn({type}),
];

export const vesselMppGrid = (type: RowValueType, options?: VesselGridOptions): CustomColumnDef<SeaboVessel>[] => [
  shipNameColumn({type, options: options?.shipNameColumn}),
  teuColumn({type}),
  dwtColumn({type}),
  grainColumn({type, options: options?.grainColumn}),
  gearsColumn({type, showQuantity: true, options: options?.gearsColumn}),
  lengthColumn({type, options: options?.lengthColumn}),
  draftColumn({type, options: options?.draftColumn}),
];

export const cargoGrid = (type: RowValueType): CustomColumnDef<CargoGridColumnsProps>[] => [
  chartererNameColumn({type, minWidth: 8}),
  sizeColumn({type, minWidth: 5}),
  commodityColumn({type, minWidth: 6}),
  durationColumn({type}),
  laycanColumn({type, minWidth: 6}),
  loadDeliveryColumn({type}),
  dischargeRedeliveryColumn({type}),
  commissionColumn({type}),
  commentColumn({type, minWidth: 2}),
];

export const aisVesselMooredGrid: CustomColumnDef<TODO>[] = [
  aisVesselNameWithTypeColumn,
  aisVesselTeuColumn,
  aisVesselDwtColumn,
  aisVesselImoMmsiColumn,
  aisVesselEtaColumn,
  aisVesselDraftColumn,
  aisVesselStatusColumn,
];

export const aisVesselExpectedGrid: CustomColumnDef<TODO>[] = [
  aisVesselEtaColumn,
  aisVesselNameWithTypeColumn,
  aisVesselTeuColumn,
  aisVesselDwtColumn,
  aisVesselImoMmsiColumn,
  aisVesselSpeedColumn,
  aisVesselDraftColumn,
  aisVesselStatusColumn,
];

export const aisVesselSpotifindGrid: CustomColumnDef<SpotifindVessel | TODO>[] = [
  aisVesselNameWithTypeColumn,
  aisVesselTeuColumn,
  aisVesselDwtColumn,
  aisVesselImoMmsiColumn,
  aisVesselDestinationColumn,
  aisVesselEtaColumn,
  aisVesselSpeedColumn,
  aisVesselDraftColumn,
  aisVesselStatusColumn,
];
