import {CreateNegotiationFormValues} from './utils/types';
import {useCreateNegotiationMutation} from './utils/useCreateNegotiationMutation';
import {useNavigate} from 'react-router';
import {FC} from 'react';
import {getCreateNegotiationRequestFromFormValues} from './utils/getCreateNegotiationRequestFromFormValues';
import {Alert, Button, Form, Typography} from 'antd';
import {NegotiationTermAreaCard} from '../NegotiationDetail/Components/TermArea/NegotiationTermArea';
import HorizontalLine from '../../../atoms/HorizontalLine';
import {NegotiationFormCargoSection} from './Components/NegotiationFormCargoSection';
import {NegotiationFormGeneralSection} from './Components/NegotiationFormGeneralSection';
import {NegotiationFormVesselSection} from './Components/NegotiationFormVesselSection';
import {NegotiationFormCounterpartSection} from './Components/NegotiationFormCounterpartSection';
import styled from 'styled-components';
import {defaultFormValues} from './utils/utils';

const {Title} = Typography;

type NegotiationCreateBodyProps = {
  presetValues: CreateNegotiationFormValues;
  onFormChange: (values: Partial<CreateNegotiationFormValues> | null) => void;
};

export const NegotiationCreateBody: FC<NegotiationCreateBodyProps> = ({presetValues, onFormChange}) => {
  const navigate = useNavigate();

  const [form] = Form.useForm<CreateNegotiationFormValues>();

  const createNegotiationMutation = useCreateNegotiationMutation();
  const isLoading = createNegotiationMutation.isPending;

  const preventEnterKeySubmit = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <Form
        name="new-negotiation"
        form={form}
        layout="vertical"
        onKeyDown={preventEnterKeySubmit}
        onKeyUp={preventEnterKeySubmit}
        initialValues={presetValues}
        onValuesChange={(_, values) => onFormChange(values)}
        onFinish={async values => {
          // We have different types for form values and request
          // so we need to convert the form values to the mutation values before sending
          const createNegotiationRequest = getCreateNegotiationRequestFromFormValues(values);
          const result = await createNegotiationMutation.mutateAsync({createNegotiationRequest});
          // Clear the form values from local storage
          onFormChange(null);
          navigate(`/negotiations/${result.id}`);
        }}
        scrollToFirstError={{
          block: 'center',
          scrollMode: 'if-needed',
        }}
        data-testid="CreateNegotiationForm">
        <NegotiationTermAreaCard>
          <SpaceBetween>
            <Title level={3}>Start a new negotiation</Title>
            <Button
              size="small"
              onClick={() => {
                onFormChange(null);
                // Resetting the form only restores preset values from above
                // We therefore set the form values to the default values
                form.setFieldsValue(defaultFormValues);
              }}>
              Clear form
            </Button>
          </SpaceBetween>
          <HorizontalLine topSpace="extraLarge" bottomSpace="extraLarge" />
          <NegotiationFormGeneralSection />
          <NegotiationFormCounterpartSection form={form} />
          <Alert
            type="info"
            showIcon
            message={
              <>
                <b>Additional information for your reference:</b> The fields below are optional, they will not be
                included in the final export
              </>
            }
            style={{marginTop: -15}}
          />
          <HorizontalLine topSpace="large" bottomSpace="extraLarge" />
          <NegotiationFormCargoSection form={form} onFormChange={onFormChange} />
          <NegotiationFormVesselSection form={form} onFormChange={onFormChange} />
        </NegotiationTermAreaCard>
        <NegotiationTermAreaCard>
          <Center>
            <CreateButton type="primary" size="large" htmlType="submit" loading={isLoading}>
              Create offer
            </CreateButton>
            <p style={{margin: '10px 0 -5px', textAlign: 'center'}}>(Terms can be created in next step)</p>
          </Center>
        </NegotiationTermAreaCard>
      </Form>
    </>
  );
};

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const CreateButton = styled(Button)`
  padding-left: 80px !important;
  padding-right: 80px !important;
`;
