import {DownloadOutlined, UploadOutlined} from '@ant-design/icons';
import {Popover} from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Flag from '../../../../atoms/Flags';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {Cargo} from '../types';
import {NotAvailable} from '../../../../utils/NotAvailable';
import {TODO} from '../../../../utils/TODO';

const routePointDefault = {name: NotAvailable};

const laycanTimestampToString = (date: string | null) => {
  if (!date) {
    return NotAvailable;
  }

  return dayjs(date).isValid() ? dayjs(date).format(DateTimeFormat.Date) : NotAvailable;
};
export const Route = (props: {cargo: Cargo}) => {
  const firstLeg = props.cargo.stations.find((station: TODO) => station.type === 'loading')?.locations[0];
  const lastLeg = props.cargo.stations.find((station: TODO) => station.type === 'discharge')?.locations[0];

  const firstPoint = firstLeg ?? routePointDefault;
  const lastPoint = lastLeg ?? routePointDefault;

  const laycanFrom = laycanTimestampToString(props.cargo.laycanFrom);
  const laycanTo = laycanTimestampToString(props.cargo.laycanTo);

  return (
    <RouteContainer>
      <RoutePoint>
        <RoutePointIconWrapper>
          <Flag showFallbackFlag countryCode={firstPoint.country} />
          <DownloadOutlined />
        </RoutePointIconWrapper>
        <RoutePointLocation>
          <b>
            <Popover content={firstPoint.countryObject?.name ?? NotAvailable}>{firstPoint.name}</Popover>
          </b>
          {laycanFrom}
        </RoutePointLocation>
      </RoutePoint>
      <RoutePoint>
        <RoutePointLocation>
          <b>
            <Popover content={lastPoint.countryObject?.name ?? NotAvailable}>{lastPoint.name}</Popover>
          </b>
          {laycanTo}
        </RoutePointLocation>
        <RoutePointIconWrapper>
          <Flag showFallbackFlag countryCode={lastPoint.country} />
          <UploadOutlined />
        </RoutePointIconWrapper>
      </RoutePoint>
    </RouteContainer>
  );
};

const RouteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-weight: normal;
`;

const RoutePointIconWrapper = styled.div`
  width: 16px;
  margin-top: -2px;
  font-size: var(--font-size-lg);
  line-height: 1.25;
`;

const RoutePointLocation = styled.div`
  width: calc(100% - 26px);
  line-height: 1.5;

  b {
    display: block;
    width: 100%;
    white-space: pre;
    text-overflow: ellipsis;
  }
`;

const RoutePoint = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  min-height: 54px;
  padding: 10px;
  background: var(--color-gray-5);
  border-radius: var(--border-radius);

  &:first-of-type {
    ${RoutePointIconWrapper} {
      margin-right: 7px;
    }
    padding-left: 12px;
  }

  &:last-of-type {
    ${RoutePointIconWrapper} {
      margin-left: 7px;
    }
    ${RoutePointLocation} {
      text-align: right;
    }
    justify-content: end;
    padding-right: 12px;
  }
`;
