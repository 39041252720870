/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * Raw data for a cargo offer
 * @export
 * @interface RawCargoData
 */
export interface RawCargoData {
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  totalCommission?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  commOptions?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  tct?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  voyage?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  period?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  stowageFactor?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  dischargeRateOptions?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  loadRateOptions?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  teu?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  teu14?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  reefer?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  vesselRequirement?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  offerToBuy?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  mol?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  molOption?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  trading?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  quantity?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  loadRate?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  dischargeRate?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  cargo?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  laycan?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  charterer?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  broker?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  addressCommission?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  brokerFee?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  vesselSize?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  loadPort?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  dischargePort?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  maxVesselAge?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  duration?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  delivery?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  redelivery?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  dwt?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  via?: string;
  /**
   *
   * @type {string}
   * @memberof RawCargoData
   */
  durationUnit?: string;
}

export function RawCargoDataFromJSON(json: any): RawCargoData {
  return RawCargoDataFromJSONTyped(json, false);
}

export function RawCargoDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawCargoData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalCommission: !exists(json, 'totalCommission') ? undefined : json['totalCommission'],
    commOptions: !exists(json, 'commOptions') ? undefined : json['commOptions'],
    tct: !exists(json, 'tct') ? undefined : json['tct'],
    voyage: !exists(json, 'voyage') ? undefined : json['voyage'],
    period: !exists(json, 'period') ? undefined : json['period'],
    stowageFactor: !exists(json, 'stowageFactor') ? undefined : json['stowageFactor'],
    dischargeRateOptions: !exists(json, 'dischargeRateOptions') ? undefined : json['dischargeRateOptions'],
    loadRateOptions: !exists(json, 'loadRateOptions') ? undefined : json['loadRateOptions'],
    teu: !exists(json, 'teu') ? undefined : json['teu'],
    teu14: !exists(json, 'teu14') ? undefined : json['teu14'],
    reefer: !exists(json, 'reefer') ? undefined : json['reefer'],
    vesselRequirement: !exists(json, 'vesselRequirement') ? undefined : json['vesselRequirement'],
    offerToBuy: !exists(json, 'offerToBuy') ? undefined : json['offerToBuy'],
    mol: !exists(json, 'mol') ? undefined : json['mol'],
    molOption: !exists(json, 'molOption') ? undefined : json['molOption'],
    trading: !exists(json, 'trading') ? undefined : json['trading'],
    quantity: !exists(json, 'quantity') ? undefined : json['quantity'],
    loadRate: !exists(json, 'loadRate') ? undefined : json['loadRate'],
    dischargeRate: !exists(json, 'dischargeRate') ? undefined : json['dischargeRate'],
    cargo: !exists(json, 'cargo') ? undefined : json['cargo'],
    laycan: !exists(json, 'laycan') ? undefined : json['laycan'],
    charterer: !exists(json, 'charterer') ? undefined : json['charterer'],
    broker: !exists(json, 'broker') ? undefined : json['broker'],
    addressCommission: !exists(json, 'addressCommission') ? undefined : json['addressCommission'],
    brokerFee: !exists(json, 'brokerFee') ? undefined : json['brokerFee'],
    vesselSize: !exists(json, 'vesselSize') ? undefined : json['vesselSize'],
    loadPort: !exists(json, 'loadPort') ? undefined : json['loadPort'],
    dischargePort: !exists(json, 'dischargePort') ? undefined : json['dischargePort'],
    maxVesselAge: !exists(json, 'maxVesselAge') ? undefined : json['maxVesselAge'],
    duration: !exists(json, 'duration') ? undefined : json['duration'],
    delivery: !exists(json, 'delivery') ? undefined : json['delivery'],
    redelivery: !exists(json, 'redelivery') ? undefined : json['redelivery'],
    dwt: !exists(json, 'dwt') ? undefined : json['dwt'],
    via: !exists(json, 'via') ? undefined : json['via'],
    durationUnit: !exists(json, 'durationUnit') ? undefined : json['durationUnit'],
  };
}

export function RawCargoDataToJSON(value?: RawCargoData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalCommission: value.totalCommission,
    commOptions: value.commOptions,
    tct: value.tct,
    voyage: value.voyage,
    period: value.period,
    stowageFactor: value.stowageFactor,
    dischargeRateOptions: value.dischargeRateOptions,
    loadRateOptions: value.loadRateOptions,
    teu: value.teu,
    teu14: value.teu14,
    reefer: value.reefer,
    vesselRequirement: value.vesselRequirement,
    offerToBuy: value.offerToBuy,
    mol: value.mol,
    molOption: value.molOption,
    trading: value.trading,
    quantity: value.quantity,
    loadRate: value.loadRate,
    dischargeRate: value.dischargeRate,
    cargo: value.cargo,
    laycan: value.laycan,
    charterer: value.charterer,
    broker: value.broker,
    addressCommission: value.addressCommission,
    brokerFee: value.brokerFee,
    vesselSize: value.vesselSize,
    loadPort: value.loadPort,
    dischargePort: value.dischargePort,
    maxVesselAge: value.maxVesselAge,
    duration: value.duration,
    delivery: value.delivery,
    redelivery: value.redelivery,
    dwt: value.dwt,
    via: value.via,
    durationUnit: value.durationUnit,
  };
}
