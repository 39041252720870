import {FC} from 'react';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import CargoGrid from '../../../components/Portfolio/CargoGrid/index';
import {PortfolioType, SortParams, SubPortfolioId} from '../../../redux/Portfolio';
import {PortfolioActions} from '../../../redux/Portfolio';
import {useLocalStorage} from '../../../utils/useLocalStorage';
import {ColumnSort} from '@tanstack/react-table';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

const TYPE: PortfolioType = 'cargo';

export const MyCargoesGrid: FC<{
  isFiltered: boolean;
  isArchive: boolean;
  loading: boolean;
  cargoes: PortfolioCargo[] | null;
  cargoesTotal: number;
  onReloadGrid: (pageNumber?: number) => void;
}> = ({isFiltered, isArchive, loading, cargoes, cargoesTotal, onReloadGrid}) => {
  const activeTabCargo = useSelector(state => state.portfolio.screenState.activeTabCargo);
  const {pageSize, pageNumber, sortField, sortOrder} = useSelector(state => state.portfolio.cargoGridState);
  const [, setLocalStoragePageSize] = useLocalStorage('seabo_portfolio_cargo_page_size', 50);

  const dispatch = useDispatch();

  const setPageNumber = (pageNumber: number) => {
    dispatch(PortfolioActions.setPageNumber(TYPE, pageNumber));
  };
  const setPageSize = (pageSize: number) => {
    setLocalStoragePageSize(pageSize);
    dispatch(PortfolioActions.setPageSize(TYPE, pageSize));
  };
  const setSortParams = (sortParams: SortParams) => {
    dispatch(PortfolioActions.setSortParams({portfolioType: TYPE, sortParams}));
  };
  const selectSubPortfolio = (id: SubPortfolioId) => dispatch(PortfolioActions.selectSubPortfolio(TYPE, id));

  return (
    <CargoGrid
      cargoes={cargoes}
      isSub={activeTabCargo !== 'all'}
      isArchive={isArchive}
      isFiltered={isFiltered}
      loading={loading}
      showPageSizeOptions={true}
      pages={Math.ceil(cargoesTotal / pageSize)}
      totalCount={cargoesTotal}
      pageSize={pageSize}
      page={pageNumber}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      sorted={[{id: sortField, desc: sortOrder === 'desc'}]}
      onPageChange={(pageNumber: number) => {
        setPageNumber(pageNumber);
      }}
      onPageSizeChange={(pageSize: number) => {
        setPageSize(pageSize);
        setPageNumber(1);
      }}
      onReloadGrid={(pageNumber?: number) => {
        onReloadGrid(pageNumber);
      }}
      onGoToAll={() => {
        selectSubPortfolio('all');
      }}
      onSortedChange={(sorted: ColumnSort) => {
        setSortParams({
          sortField: sorted.id,
          sortOrder: sorted.desc ? 'desc' : 'asc',
        });
      }}
    />
  );
};
