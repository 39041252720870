import LabelWrapper from '../../../../atoms/LabelWrapper';
import {hasError} from '../../../../utils/helper';
import NumberRangeInput from '../../../RangeInput/NumberRangeInput';
import {designSubTypes} from '../../../../screens/CargoVesselForm/helper';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './SizeDefinition';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import {MultiSelect} from '../../../../atoms/Select/MultiSelect';
import {ReactNode} from 'react';
import autocompleteApi from '../../../../redux/ApiService/autocomplete';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {AsyncMultiCreatable} from '../../../../atoms/Select/AsyncMultiCreatable';

export interface ContainerDesignOption {
  label: string;
  value: string;
}

export type LoadContainerDesignsFunction = (query: string) => Promise<ContainerDesignOption[]>;

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
  withHoldsAndHatches?: boolean;
  /**
   * This function allows the parent to customize which autocomplete options are offered to the user.
   * If not provided, we use ElasticSearch.
   */
  loadContainerDesignsForAutocomplete?: LoadContainerDesignsFunction;
  disabled?: boolean;
  badge?: ReactNode;
}

export const Size = ({
  filterProviderApi,
  withHoldsAndHatches = true,
  disabled,
  badge,
  loadContainerDesignsForAutocomplete,
}: Props) => {
  const preFillData = useSelector(state => state.preFill.data ?? []);

  const containerDesignDefaultOptions = preFillData.map(p => ({
    value: p.slug,
    label: p.name,
  }));
  const dispatch = useDispatch();

  const defaultLoadContainerDesignsForAutocomplete = (query: string): Promise<ContainerDesignOption[]> =>
    dispatch(autocompleteApi.searchDesignTypes(query));

  const loadContainerOptions = async (query: string) => {
    const options = await (loadContainerDesignsForAutocomplete ?? defaultLoadContainerDesignsForAutocomplete)(query);
    return options;
  };

  function searchContainerOptions(query: string) {
    return query.length > 1 ? loadContainerOptions(query) : Promise.resolve([]);
  }

  return (
    <>
      <FilterButton
        disabled={disabled}
        filterProviderApi={filterProviderApi}
        filterBranchDefinition={filterBranchDefinition}
        size="big">
        {({handleChange, values, validations}) => (
          <div className="container">
            <div className="row bottom-fix">
              <div className="scol-4">
                <NumberRangeInput
                  label={'DWT (Summer)'}
                  onFromChange={value => handleChange('deadWeightFrom', value)}
                  onToChange={value => handleChange('deadWeightTo', value)}
                  fromValue={values.deadWeightFrom}
                  toValue={values.deadWeightTo}
                  allowEqualMinMax
                  fromProps={{
                    min: 0,
                    max: 999999,
                    placeholder: 'min',
                    hasError: hasError('deadWeightFrom', validations),
                  }}
                  toProps={{
                    min: 0,
                    max: 999999,
                    placeholder: 'max',
                    hasError: hasError('deadWeightTo', validations),
                  }}
                />
              </div>
              {withHoldsAndHatches && (
                <>
                  <div className="scol-4">
                    <NumberRangeInput
                      label={'Holds'}
                      onFromChange={value => handleChange('holdsFrom', value)}
                      onToChange={value => handleChange('holdsTo', value)}
                      fromValue={values.holdsFrom}
                      toValue={values.holdsTo}
                      allowEqualMinMax
                      fromProps={{
                        min: 0,
                        max: 999,
                        placeholder: 'min',
                        hasError: hasError('holdsFrom', validations),
                      }}
                      toProps={{
                        min: 0,
                        max: 999,
                        placeholder: 'max',
                        hasError: hasError('holdsTo', validations),
                      }}
                    />
                  </div>
                  <div className="scol-4">
                    <NumberRangeInput
                      label={'Hatches'}
                      onFromChange={value => handleChange('hatchesFrom', value)}
                      onToChange={value => handleChange('hatchesTo', value)}
                      fromValue={values.hatchesFrom}
                      toValue={values.hatchesTo}
                      allowEqualMinMax
                      fromProps={{
                        min: 0,
                        max: 999,
                        placeholder: 'min',
                        hasError: hasError('hatchesFrom', validations),
                      }}
                      toProps={{
                        min: 0,
                        max: 999,
                        placeholder: 'max',
                        hasError: hasError('hatchesTo', validations),
                      }}
                    />
                  </div>
                </>
              )}
              <div className="scol-4">
                <NumberRangeInput
                  label={'TEU'}
                  onFromChange={value => handleChange('teuFrom', value)}
                  onToChange={value => handleChange('teuTo', value)}
                  fromValue={values.teuFrom}
                  toValue={values.teuTo}
                  allowEqualMinMax
                  fromProps={{
                    min: 0,
                    max: 99999,
                    placeholder: 'min',
                    hasError: hasError('teuFrom', validations),
                  }}
                  toProps={{
                    min: 0,
                    max: 99999,
                    placeholder: 'max',
                    hasError: hasError('teuTo', validations),
                  }}
                />
              </div>
              <div className="scol-4">
                <NumberRangeInput
                  label={'TEU14'}
                  onFromChange={value => handleChange('teu14From', value)}
                  onToChange={value => handleChange('teu14To', value)}
                  fromValue={values.teu14From}
                  toValue={values.teu14To}
                  allowEqualMinMax
                  fromProps={{
                    min: 0,
                    max: 99999,
                    placeholder: 'min',
                    hasError: hasError('teu14From', validations),
                  }}
                  toProps={{
                    min: 0,
                    max: 99999,
                    placeholder: 'max',
                    hasError: hasError('teu14To', validations),
                  }}
                />
              </div>
              <HorizontalLine large topSpace={'small'} />
              <div className="scol-6">
                <LabelWrapper label={'Bulker sub type'} htmlFor={'filter-size-design'}>
                  <MultiSelect
                    id={'filter-size-design'}
                    value={values.designs}
                    onChange={value => handleChange('designs', [...value])}
                    options={Object.keys(designSubTypes).map(value => ({
                      value,
                      name: designSubTypes[value as keyof typeof designSubTypes],
                    }))}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.value}
                    isClearable
                  />
                </LabelWrapper>
              </div>
              <div className="scol-6">
                <LabelWrapper label={'Container design type'} htmlFor={'filter-size-subtype-container'}>
                  <AsyncMultiCreatable
                    id={'filter-size-subtype-container'}
                    name={'filter-size-subtype-container'}
                    value={values.designTypes ?? []}
                    onChange={value => handleChange('designTypes', [...value])}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.label} // because of freetext
                    loadOptions={searchContainerOptions}
                    defaultOptions={containerDesignDefaultOptions}
                    placeholder={'Type to search...'}
                    loadingMessage={() => 'Searching ...'}
                    isClearable
                    cacheOptions
                    createLabel={value => value}
                    createOptionPosition={'first'}
                  />
                </LabelWrapper>
              </div>
            </div>
          </div>
        )}
      </FilterButton>
      {badge}
    </>
  );
};
