import {StarFilled} from '@ant-design/icons';
import {Button} from 'antd';
import copy from 'copy-to-clipboard';
import {useState} from 'react';
import styled from 'styled-components';
import {UpdateWorkspaceRequest} from '../../../../../api/symfony/generated';
import {CompanyWorkspace as CompanyWorkspaceType} from '../../../../../api/symfony/generated/models/CompanyWorkspace';
import Input from '../../../../../atoms/Input';
import {MenuItem} from '../../../../../atoms/Menu/MenuItem';
import Tooltip from '../../../../../atoms/Tooltip';
import {ActionMenu} from '../../../../../components/ActionMenu/ActionMenu';
import {useUser} from '../../../../../components/UserContext/useUser';
import CompanyUser from '../../../../../model/company/CompanyUser';
import {useUpdateWorkspaceMutation} from '../../../../../queries/useUpdateWorkspaceMutation';
import {CompanyListWorkspaceUser} from '../../components/CompanyListWorkspaceUser/CompanyListWorkspaceUser';
import {CompanyWorkspaceEasyShareSwitch} from './CompanyWorkspaceEasyShareSwitch';
import {FeatureToggles} from '../../../../../utils/FeatureToggles';
import {useSwitchToWorkspace} from '../../../../../atoms/WorkspaceSelect/useSwitchToWorkspace';

interface CompanyWorkspaceProps {
  onEdit?: (id: number) => void;
  onRemoveUser?: (user: CompanyUser) => void;
  onWorkspaceInactive?: (id: number) => void;
  addMembers: (id: number) => void;
  onDefaultWorkspaceSelected: () => void;
  onChangeWorkspace?: () => void;
  users?: CompanyUser[];
  workspace: CompanyWorkspaceType;
  onEasySharingInfoClick?: () => void;
}

export const CompanyWorkspace = ({
  onEdit,
  onRemoveUser,
  addMembers,
  onWorkspaceInactive,
  users,
  onDefaultWorkspaceSelected,
  workspace,
  onChangeWorkspace,
  onEasySharingInfoClick,
}: CompanyWorkspaceProps) => {
  const [emailLabel, setEmailLabel] = useState(workspace.email ?? '');
  const user = useUser();

  const switchToWorkspace = useSwitchToWorkspace();

  const onGoToCurrentWorkspace = () => {
    switchToWorkspace(workspace.id, '/market');
  };

  const updateWorkspaceMutation = useUpdateWorkspaceMutation({
    onSuccess: () => {
      onChangeWorkspace?.();
    },
  });

  const onEasyShareChange = (value: boolean) => {
    const request: UpdateWorkspaceRequest = {
      company: user.payload.company.id,
      workspace: workspace.id,
      companyWorkspace: {
        ...workspace,
        createdAt: new Date(workspace.createdAt),
        updatedAt: new Date(workspace.updatedAt),
        easySharing: value,
      },
    };

    updateWorkspaceMutation.mutate(request);
  };

  const copyEmail = () => {
    copy(workspace.email ?? '');
    setEmailLabel('Copied!');
    setTimeout(() => setEmailLabel(workspace.email ?? ''), 3000);
  };

  const hasUsers = (users?.length ?? 0) > 0;

  return (
    <WorkspaceEntryContainer>
      <WorkspaceBaseInfos>
        <WorkspaceTitle>
          {!workspace.isDisabled && (
            <Tooltip
              title={workspace.isDefault ? 'This is your default Workspace.' : 'Set this as your default Workspace.'}
              position="left"
              trigger="mouseenter">
              <StarIcon
                $isDefault={workspace.isDefault}
                onClick={() => (workspace.isDefault ? null : onDefaultWorkspaceSelected())}
              />
            </Tooltip>
          )}
          {workspace.name}
        </WorkspaceTitle>
        <WorkspaceInfoDescription>{workspace.description || 'No description'}</WorkspaceInfoDescription>
        <Tooltip
          title={
            'This is your dedicated seabo parser inbox for this workspace. Forward your mails to this address. Click to copy this email address'
          }
          position="left"
          trigger="mouseenter">
          <WorkspaceEmail id={`workspace-email-${workspace.id}`} readOnly value={emailLabel} onClick={copyEmail} />
        </Tooltip>
        {FeatureToggles.easySharing && (
          <CompanyWorkspaceEasyShareSwitch
            onChange={onEasyShareChange}
            checked={workspace.easySharing}
            onInfoClick={onEasySharingInfoClick}
            onGoToCurrentWorkspace={onGoToCurrentWorkspace}
          />
        )}
      </WorkspaceBaseInfos>
      <WorkspaceUsers $isDisabled={workspace.isDisabled}>
        <WorkspaceTitle>
          {users?.length || 'No'} member{users?.length === 1 ? '' : 's'}
        </WorkspaceTitle>
        <WorkspaceMemberContainer>
          {users?.length ? (
            <CompanyListWorkspaceUser users={users} onRemove={onRemoveUser ? onRemoveUser : () => {}} isList />
          ) : (
            <WorkspaceInfoDescription>Click below to add members...</WorkspaceInfoDescription>
          )}
        </WorkspaceMemberContainer>
      </WorkspaceUsers>
      {!workspace.isDisabled ? (
        <>
          <WorkspaceBaseInfos>
            <Button id={`edit-details-${workspace.id}`} onClick={() => (onEdit ? onEdit(workspace.id) : null)} block>
              Edit workspace
            </Button>
          </WorkspaceBaseInfos>
          <WorkspaceUsers $isDisabled={workspace.isDisabled}>
            <Button id={'company-workspace__button'} onClick={() => addMembers(workspace.id)} block>
              {users?.length ? 'Manage members' : 'Add members'}
            </Button>
          </WorkspaceUsers>
          <ActionMenuContainer>
            <ActionMenu
              items={[
                {
                  key: 'Disable',
                  disabled: workspace.isDefault || hasUsers,
                  label: (
                    <MenuItem
                      disabled={workspace.isDefault || hasUsers}
                      disabledHint={'You can only disable empty workspaces that are not the default workspace'}
                      label={'Disable'}
                      onClick={() => (workspace.isDefault ? null : onWorkspaceInactive?.(workspace.id))}
                    />
                  ),
                },
              ]}
            />
          </ActionMenuContainer>
        </>
      ) : (
        <Button
          onClick={() => (onWorkspaceInactive ? onWorkspaceInactive(workspace.id) : null)}
          disabled={workspace.isDisabled}
          block>
          Reactivate
        </Button>
      )}
    </WorkspaceEntryContainer>
  );
};

const WorkspaceEntryContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 20px;
  width: 100%;
  padding-bottom: 10px;
`;

const WorkspaceBaseInfos = styled.div`
  width: calc(35% - 10px);
`;

const WorkspaceEmail = styled(Input)`
  margin-bottom: 20px;
`;

const WorkspaceUsers = styled.div<{$isDisabled?: boolean}>`
  width: calc(65% - 10px);
  ${({$isDisabled}) => ($isDisabled ? 'opacity: .5;' : '')}
`;

const WorkspaceTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const WorkspaceInfoDescription = styled.p`
  margin: 0 0 10px;
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
`;

const ActionMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: -7px;
`;

const WorkspaceMemberContainer = styled.div`
  height: calc(100% - 55px);
  min-height: 71px;
  max-height: 30vh;
  padding: 6px 10px;
  margin-bottom: 20px;
  border: var(--border-light);
  border-radius: var(--border-radius);
  overflow-y: scroll;
`;

const StarIcon = styled(StarFilled)<{$isDefault?: boolean}>`
  padding-right: 6px;
  font-size: var(--font-size-lg);
  color: ${({$isDefault}) => ($isDefault ? 'var(--color-yellow-dark)' : 'var(--color-gray-3)')};
  vertical-align: middle;
`;
