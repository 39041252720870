import React, {FC, useEffect} from 'react';
import {TourDefinition} from './TourDefinition';
import {validateTour} from './TourUtils';
import {Tour, TourStepProps} from 'antd';
import {TourStepHeader} from './TourStep/TourStepHeader';
import {TourStepContent} from './TourStep/TourStepContent';
import {useLocalStorage} from '../../utils/useLocalStorage';
import {useTourUserDataQuery} from './useTourUserDataQuery';
import {useUpdateTourUserDataMutation} from './useUpdateTourUserDataMutation';
import {isTest} from '../../utils/environment';

const root = document.getElementById('root');
const TOUR_MUTED_LOCAL_STORAGE_KEY = 'tour-muted';
const TOUR_AUTO_PLAY_LOCAL_STORAGE_KEY = 'tour-auto-play';

type TourOverlayProps = {
  tourDefinition: TourDefinition;
};

export const TourOverlay: FC<TourOverlayProps> = props => {
  // Don't render the tour in tests or if the root element is not present
  if (!root || isTest()) {
    return null;
  }
  return <TourOverlayInner {...props} />;
};

const TourOverlayInner: FC<TourOverlayProps> = ({tourDefinition}) => {
  const [muted, setMuted] = useLocalStorage(TOUR_MUTED_LOCAL_STORAGE_KEY, false);
  const [autoPlay, setAutoPlay] = useLocalStorage(TOUR_AUTO_PLAY_LOCAL_STORAGE_KEY, true);

  const tourId = tourDefinition.id;
  const tourUserDatQuery = useTourUserDataQuery(tourId);
  const tourUserData = tourUserDatQuery.data;

  const updateTourUserDataMutation = useUpdateTourUserDataMutation(tourId);

  // Validate the tour definition targets for DX
  useEffect(() => {
    validateTour(tourDefinition);
  }, [tourDefinition]);

  if (!tourUserData || tourUserData.hasDone) {
    return null;
  }

  const {stepsDone} = tourUserData;

  const updateTourUserData = (data: {step?: number; done?: boolean}) => {
    updateTourUserDataMutation.mutate(
      {tourId, step: stepsDone, ...data},
      {
        onSuccess: () => {
          void tourUserDatQuery.refetch();
        },
      }
    );
  };

  const steps: TourStepProps[] = tourDefinition.steps.map(definitionStep => {
    const {title, content, media, placement, target} = definitionStep;
    let width = undefined;
    if (media?.type === 'video') {
      width = target ? 800 : 1200;
    }
    return {
      target: target ? root?.querySelector<HTMLElement>(target) : null,
      placement,
      title,
      description: content ? <TourStepContent>{content}</TourStepContent> : null,
      cover: media ? (
        <TourStepHeader
          media={media}
          autoPlay={autoPlay}
          muted={muted}
          onChangeAutoPlay={setAutoPlay}
          onChangeMuted={setMuted}
          hasNext={stepsDone + 1 < tourDefinition.steps.length}
          onPlayNext={() => updateTourUserData({step: stepsDone + 1})}
        />
      ) : null,
      style: {
        width,
      },
    };
  });

  return (
    <Tour
      open={true}
      steps={steps}
      current={stepsDone}
      scrollIntoViewOptions={{block: 'center', inline: 'center'}}
      onChange={step => updateTourUserData({step})}
      onClose={() => updateTourUserData({done: true})}
    />
  );
};
