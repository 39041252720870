import dayjs from 'dayjs';
import max from 'lodash/max';
import min from 'lodash/min';
import sumBy from 'lodash/sumBy';
import {OfferBucket} from '../../../api/symfony/generated/models';
import {getAllDaysInPeriod} from './getAllDaysInPeriod/getAllDaysInPeriod';
import {getValueOfBucket} from './getValueOfBucket';
import {groupBucketsByDate, GroupedPerUnit} from './groupBucketsByDate';

export type OfferType = 'cargo' | 'vessel' | 'all';

export type PointInTimeSeries = {date: string; count: number};

const defaultOfferType: OfferType = 'all';

export const transformBucketsToTimeSeries = ({
  buckets,
  offerType,
  per,
}: {
  buckets: OfferBucket[];
  offerType?: OfferType;
  per?: GroupedPerUnit;
}): Array<PointInTimeSeries> => {
  const groupedBuckets = groupBucketsByDate({
    buckets,
    per,
  });
  if (buckets.length === 0) {
    return [];
  }
  const daysInBuckets = Object.keys(groupedBuckets);
  const minDate = dayjs(min(daysInBuckets));
  const maxDate = dayjs(max(daysInBuckets));

  const allDatesInPeriod = getAllDaysInPeriod({
    start: minDate,
    end: maxDate,
    per,
  });
  return allDatesInPeriod.map(dateString => {
    const bucktesOfTheDay = groupedBuckets[dateString];
    if (bucktesOfTheDay === undefined) {
      return {
        date: dateString,
        count: 0,
      };
    }
    return {
      date: dateString,
      count: sumBy(bucktesOfTheDay, bucket => getValueOfBucket({bucket, offerType: offerType ?? defaultOfferType})),
    };
  });
};
