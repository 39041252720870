import {Link} from 'react-router-dom';
import {animated, useTransition} from 'react-spring';
import styled from 'styled-components';
import Avatar from '../../../../atoms/Avatar';
import ContactButton from '../../../../atoms/ConnectionButtons/ContactButton';

export interface Recommendation {
  id: number;
  company: {
    name: string;
  };
  fullName: string;
}

export const RecommendationsTransition = ({recommendations}: {recommendations: Recommendation[]}) => {
  const transitions = useTransition(recommendations, {
    from: {
      opacity: 1,
      transform: 'translateY(0px)',
      height: 0,
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0px)',
      height: 151,
    },
    leave: {
      opacity: 1,
      transform: 'translateY(0px)',
      height: 0,
    },
  });
  return (
    <>
      {transitions(({opacity, transform, height}, item) => (
        <TransitionContainer key={item.id} style={{opacity, transform, height}}>
          <RecommendationContainer>
            <ContactRow>
              <AvatarContainer>
                <Avatar imageUrl={'/scf/api/UserAvatar?userid=' + item.id} size={'medium'} />
              </AvatarContainer>
              <ContactContainer>
                <ContactName>
                  <StyledLink to={'/user/' + item.id} key={item.id}>
                    {item.fullName}
                  </StyledLink>
                </ContactName>
                <Company>{item.company ? item.company.name : 'No company'}</Company>
              </ContactContainer>
            </ContactRow>
            <ConnectContainer>
              <ContactButton connectedState={0} userID={item.id} />
            </ConnectContainer>
          </RecommendationContainer>
        </TransitionContainer>
      ))}
    </>
  );
};

const TransitionContainer = styled(animated.div)`
  width: calc(50% - 20px);
  background-color: var(--color-white);
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-card);
  overflow: hidden;

  @media (min-width: 1150px) {
    width: calc(25% - 15px);
  }
`;
const RecommendationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;
const ConnectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 160px;
  margin-bottom: 18px;
`;
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const ContactName = styled.div`
  font-weight: 600;
  overflow: hidden;
  height: 20px;
  width: 100%;
  text-overflow: ellipsis;
`;

const StyledLink = styled(Link)`
  color: var(--color-black);
`;

const Company = styled.div`
  color: var(--color-gray-2);
  font-weight: 400;
  overflow: hidden;
  height: 20px;
  width: 100%;
  text-overflow: ellipsis;
`;

const AvatarContainer = styled.div`
  display: block;
  margin-right: 12px;
`;
