/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PierVisitPier,
  PierVisitPierFromJSON,
  PierVisitPierFromJSONTyped,
  PierVisitPierToJSON,
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineEventTypeFromJSON,
  VesselVoyageTimelineEventTypeFromJSONTyped,
  VesselVoyageTimelineEventTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface PierVisit
 */
export interface PierVisit {
  /**
   *
   * @type {VesselVoyageTimelineEventType}
   * @memberof PierVisit
   */
  eventType: VesselVoyageTimelineEventType;
  /**
   *
   * @type {number}
   * @memberof PierVisit
   */
  imo: number;
  /**
   *
   * @type {PierVisitPier}
   * @memberof PierVisit
   */
  pier: PierVisitPier;
  /**
   *
   * @type {Date}
   * @memberof PierVisit
   */
  enterTimestamp: Date;
  /**
   *
   * @type {Date}
   * @memberof PierVisit
   */
  exitTimestamp: Date | null;
  /**
   * The draft of the vessel when it entered the pier
   * @type {number}
   * @memberof PierVisit
   */
  enterDraft: number;
  /**
   * The draft of the vessel when it exited the pier
   * @type {number}
   * @memberof PierVisit
   */
  exitDraft: number | null;
}

export function PierVisitFromJSON(json: any): PierVisit {
  return PierVisitFromJSONTyped(json, false);
}

export function PierVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): PierVisit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeFromJSON(json['eventType']),
    imo: json['imo'],
    pier: PierVisitPierFromJSON(json['pier']),
    enterTimestamp: new Date(json['enterTimestamp']),
    exitTimestamp: json['exitTimestamp'] === null ? null : new Date(json['exitTimestamp']),
    enterDraft: json['enterDraft'],
    exitDraft: json['exitDraft'],
  };
}

export function PierVisitToJSON(value?: PierVisit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeToJSON(value.eventType),
    imo: value.imo,
    pier: PierVisitPierToJSON(value.pier),
    enterTimestamp: value.enterTimestamp.toISOString(),
    exitTimestamp: value.exitTimestamp === null ? null : value.exitTimestamp.toISOString(),
    enterDraft: value.enterDraft,
    exitDraft: value.exitDraft,
  };
}
