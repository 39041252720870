import {AutoComplete} from 'antd';
import styled from 'styled-components';
import {useStowageFactorsSearchQuery} from './useStowageFactorsQuery';
import {CSSProperties, useEffect, useState} from 'react';
import {parseFloatTS} from '../../utils/parseNumberTS';

const StyledAutoComplete: typeof AutoComplete = styled(AutoComplete)`
  width: 100%;
`;

type Parms = {
  onSelectFactorInCbm: (factor: number) => void;
  onChangeFactor: (factor: number | undefined) => void;
  style?: CSSProperties;
  factor: number | undefined;
};

export const StowageFactorPicker = ({onChangeFactor, style, onSelectFactorInCbm, factor}: Parms) => {
  const [searchTerm, setSearchTerm] = useState((factor ?? '') + '');

  const stowageFactorsQuery = useStowageFactorsSearchQuery(searchTerm);

  useEffect(() => {
    if (factor !== undefined) {
      setSearchTerm(factor + '');
    }
  }, [factor]);

  return (
    <StyledAutoComplete
      showSearch={true}
      style={style}
      data-testid={'stowageFactorInput'}
      allowClear={true}
      value={searchTerm}
      onClear={() => {
        onChangeFactor(undefined);
      }}
      onSelect={value => {
        onSelectFactorInCbm(parseFloatTS(value));
        setSearchTerm(value);
      }}
      onSearch={value => {
        setSearchTerm(value);

        if (value === '') {
          onChangeFactor(undefined);
          return;
        }
        const numericalValue = parseFloatTS(value);

        if (!isNaN(numericalValue)) {
          onChangeFactor(numericalValue);
        }
      }}
      popupMatchSelectWidth={false}
      options={[
        ...(stowageFactorsQuery.data?.map(stowageFactor => ({
          value: stowageFactor.stowageFactor,
          label: stowageFactor.name + ' (' + stowageFactor.stowageFactor + ' cbm / mts)',
          key: stowageFactor.name,
        })) ?? []),
      ]}
    />
  );
};
