/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NegotiationTermType {
  Account = 'account',
  Owners = 'owners',
  Vessel = 'vessel',
  Delivery = 'delivery',
  LoadPort = 'load-port',
  Laycan = 'laycan',
  Duration = 'duration',
  Cargo = 'cargo',
  CharterHire = 'charter-hire',
  Freight = 'freight',
  Demurrage = 'demurrage',
  Dispatch = 'dispatch',
  CargoHolds = 'cargo-holds',
  CargoGear = 'cargo-gear',
  Redelivery = 'redelivery',
  DischargePort = 'discharge-port',
  Ilohc = 'ilohc',
  CargoExclusions = 'cargo-exclusions',
  TradingExclusions = 'trading-exclusions',
  BunkerClause = 'bunker-clause',
  LawArbitration = 'law-arbitration',
  OtherwiseAsPerBaseCharterParty = 'otherwise-as-per-base-charter-party',
  Addcom = 'addcom',
  Brokerage = 'brokerage',
}

export function NegotiationTermTypeFromJSON(json: any): NegotiationTermType {
  return NegotiationTermTypeFromJSONTyped(json, false);
}

export function NegotiationTermTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationTermType {
  return json as NegotiationTermType;
}

export function NegotiationTermTypeToJSON(value?: NegotiationTermType | null): any {
  return value as any;
}
