import {Dayjs} from 'dayjs';
import {QueryClient} from '@tanstack/react-query';
import {holidayApi} from '../../../../../api/holiday/holidayApi';
import {Holiday} from '../../../../../api/holiday/generated';

export const getHolidays = async ({
  countryCode,
  queryClient,
  date,
}: {
  countryCode?: string;
  queryClient: QueryClient;
  date: Dayjs;
}): Promise<Holiday[]> => {
  if (!countryCode) {
    return [];
  }
  const year = date.year();
  return await queryClient.fetchQuery({
    queryKey: ['getHolidays', countryCode, year],
    queryFn: () => {
      return holidayApi.getHolidays({
        query: {
          countriesOrRegions: [countryCode],
          year,
        },
      });
    },
    staleTime: Infinity,
  });
};
