import {FC, useState} from 'react';
import styled from 'styled-components';
import {SupportAgentsBar} from '../../components/SupportAgentsBar/SupportAgentsBar';
import {PlanFeatureComparison} from './components/PlanFeatureComparison/PlanFeatureComparison';
import {PlanUvpComparison} from './components/PlanUvpComparison/PlanUvpComparison';
import {ContractDuration} from './components/ContractDurationPicker';
import {TrialBanner} from './components/TrialBanner/TrialBanner';
import {SeaboUvps} from './components/SeaboUvps';
import {SubTitle, Title} from './components/shared';

export const CompanySubscriptionOverviewScreen: FC = () => {
  const [contractDuration, setContractDuration] = useState<ContractDuration>('monthly');

  return (
    <StepContainer data-testid="SubscriptionOverviewScreen">
      <Title>Choose your plan</Title>
      <SubTitle style={{marginBottom: -24}}>Switch plans or cancel easily.</SubTitle>
      <PlanUvpComparison contractDuration={contractDuration} onChangeContractDuration={setContractDuration} />
      <TrialBanner />
      <Title>What's included</Title>
      <SubTitle>Maximize your chartering potential.</SubTitle>
      <SeaboUvps />
      <PlanFeatureComparison contractDuration={contractDuration} onChangeContractDuration={setContractDuration} />
      <SupportAgentsBar />
    </StepContainer>
  );
};

const StepContainer = styled.div`
  padding: 20px 20px 0;
`;
