// mini templating engined, based on github.com/premasagar/tim

import get from 'lodash/get';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NestedNamedValues = Record<string, any>;

const PLACEHOLDER_PATTERN = new RegExp('{\\s*([a-z0-9_$][.a-z0-9_]*)\\s*}', 'gi');

/**
 * Merges the given variables into the template string.
 * Variable names can be nested, like `{user.company.id}`.
 */
export const template = (template: string, variables: NestedNamedValues): string => {
  const lookupVariable = (_match: string, path: string): string => {
    const variableValue = get(variables, path, undefined);
    if (variableValue === undefined) {
      throw new Error(`Template variable ${path} is undefined in ${JSON.stringify(variables)}`);
    }
    return variableValue;
  };
  return template.replace(PLACEHOLDER_PATTERN, lookupVariable);
};
