interface Pagination {
  page: number;
  pageSize: number;
}

export const getCircularsLimitedByPlan = <ItemType>(
  isNewSubMarket: boolean,
  isLoading: boolean | undefined,
  marketGridLimit: number,
  circulars: {items: ItemType[]; totalItems: number},
  pagination: Pagination
): {
  totalCountLimited: number;
  itemsLimited: ItemType[];
  isLastLimitedPage: boolean;
} => {
  if (isNewSubMarket) {
    return {
      totalCountLimited: 0,
      itemsLimited: [],
      isLastLimitedPage: false,
    };
  }

  if (marketGridLimit === Infinity) {
    return {
      totalCountLimited: circulars.totalItems,
      itemsLimited: circulars.items,
      isLastLimitedPage: false,
    };
  }

  const totalCountLimited = Math.min(marketGridLimit, circulars.totalItems);
  const itemsLimited = (circulars.items ?? []).slice(0, marketGridLimit - (pagination.page - 1) * pagination.pageSize);
  const isLastLimitedPage =
    !isLoading && (pagination.page - 1) * pagination.pageSize + circulars.items.length >= marketGridLimit;

  return {
    totalCountLimited,
    itemsLimited,
    isLastLimitedPage,
  };
};
