import {Assumptions} from '../types';
import {setURLSearchParamsFromDistanceCalculator} from './URLParamsAssumptionDistanceCalcConnection';

export const distanceCalcPrefix = 'dc_';

export const updateUrlSearchParamsWithAssumptions = (urlParams: URLSearchParams, assumptions: Assumptions) => {
  urlParams.set('seaMargin', assumptions.seaMargin.toString());
  urlParams.set('costIfoPerMts', assumptions.costIfoPerMts.toString());
  urlParams.set('costMgoPerMts', assumptions.costMgoPerMts.toString());
  urlParams.set('costMdoPerMts', assumptions.costMdoPerMts.toString());
  urlParams.set('portCosts', assumptions.portCosts.toString());
  setURLSearchParamsFromDistanceCalculator(assumptions.distanceCalculator, urlParams);
};
