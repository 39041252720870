/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanyAddress
 */
export interface CompanyAddress {
  /**
   *
   * @type {string}
   * @memberof CompanyAddress
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyAddress
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyAddress
   */
  street2?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyAddress
   */
  streetNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyAddress
   */
  zip?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyAddress
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyAddress
   */
  country?: string | null;
}

export function CompanyAddressFromJSON(json: any): CompanyAddress {
  return CompanyAddressFromJSONTyped(json, false);
}

export function CompanyAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAddress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    street: !exists(json, 'street') ? undefined : json['street'],
    street2: !exists(json, 'street2') ? undefined : json['street2'],
    streetNumber: !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
    zip: !exists(json, 'zip') ? undefined : json['zip'],
    city: !exists(json, 'city') ? undefined : json['city'],
    country: !exists(json, 'country') ? undefined : json['country'],
  };
}

export function CompanyAddressToJSON(value?: CompanyAddress | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    street: value.street,
    street2: value.street2,
    streetNumber: value.streetNumber,
    zip: value.zip,
    city: value.city,
    country: value.country,
  };
}
