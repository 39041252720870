import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {NamedValue} from '../../FilterConversion/utils/NamedValue';
import {AisStatus, filterBranchDefinition} from './SpotifindVesselStatusDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import {MultiSelect} from '../../../../../atoms/Select/MultiSelect';
import {Radio} from 'antd';

const aisStatusList: NamedValue<AisStatus>[] = [
  {name: 'Under way using engine', value: 0},
  {name: 'Under way sailing', value: 8},
  {name: 'At anchor', value: 1},
  {name: 'Not under command', value: 2},
  {name: 'Restricted maneuverability', value: 3},
  {name: 'Moored', value: 5},
  {name: 'Aground', value: 6},
];

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

export const SpotifindVesselStatus = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values}) => (
      <div>
        <LabelWrapper label={'AIS status'} htmlFor={'filter-spotifind-vesselstatus'}>
          <MultiSelect
            id={'filter-spotifind-vesselstatus'}
            value={values.aisStatus}
            onChange={value => handleChange('aisStatus', [...value])}
            options={aisStatusList}
            getOptionLabel={option => option.name}
            getOptionValue={option => `${option.value}`}
          />
        </LabelWrapper>
        <Radio.Group
          onChange={event => {
            handleChange('includeAisStatus', event.target.value);
          }}
          value={Boolean(values.includeAisStatus)}
          style={{boxShadow: 'none'}}>
          <div className="row">
            <div className="scol-6">
              <Radio id={'filter-excluding-aisstatus'} value={false}>
                Excluding
              </Radio>
            </div>
            <div className="scol-6">
              <Radio id={'filter-limiting-aisstatus'} value={true}>
                Limiting
              </Radio>
            </div>
          </div>
        </Radio.Group>
      </div>
    )}
  </FilterButton>
);
