import {OfferBucket} from '../../../api/symfony/generated/models';
import groupBy from 'lodash/groupBy';
import {findMondayOfCalendarWeek} from './findMondayOfCalendarWeek';
import {parseIntTS} from '../../../utils/parseNumberTS';
import {getBucketDate} from './getBucketDate';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';

export type GroupedPerUnit = 'day' | 'month' | 'week';

export const groupBucketsByDate = ({buckets, per}: {buckets: OfferBucket[]; per?: GroupedPerUnit}) => {
  return groupBy(buckets, bucket => {
    const date = getBucketDate(bucket);

    const calendarWeek = bucket.tags?.find(tag => tag.name === 'calendarWeek')?.value;
    switch (per) {
      case 'month':
        return date.startOf('month').format(DateTimeFormat.IsoDate);
      case 'week':
        return findMondayOfCalendarWeek(parseIntTS(date.year()!), parseIntTS(calendarWeek!)).format(
          DateTimeFormat.IsoDate
        );
    }
    return date.format(DateTimeFormat.IsoDate);
  });
};
