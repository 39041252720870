import {CreateNegotiationRequest} from '../../../../api/node-backend/generated';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {parseIntTS} from '../../../../utils/parseNumberTS';
import {CreateNegotiationFormValues} from './types';

export const getCreateNegotiationRequestFromFormValues = (
  formValues: CreateNegotiationFormValues
): CreateNegotiationRequest => {
  const {title, counterpart, vessel, cargo} = formValues;

  const vesselRequest = {
    ...vessel,
    imo: vessel.imo ? parseIntTS(vessel.imo) : undefined,
    size: vessel.size ? parseIntTS(vessel.size) : undefined,
    dateOpen: vessel.dateOpen?.format(DateTimeFormat.IsoDate),
  };

  const cargoRequest = {
    ...cargo,
    quantityMin: cargo.quantityMin ? parseIntTS(cargo.quantityMin) : undefined,
    quantityMax: cargo.quantityMax ? parseIntTS(cargo.quantityMax) : undefined,
    laycanFrom: formValues.cargo.laycan?.[0]?.format(DateTimeFormat.IsoDate),
    laycanTo: formValues.cargo.laycan?.[1]?.format(DateTimeFormat.IsoDate),
  };
  delete cargoRequest.laycan;

  const negotiationRequestBody: CreateNegotiationRequest = {
    title,
    counterpart,
    terms: [],
    vessel: vesselRequest,
    cargo: cargoRequest,
  };

  return negotiationRequestBody;
};
