import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {
  FilterBranchDefinition,
  FilterBranchDefinitionsDefault,
} from '../../components/FilterProvider/FilterBranchDefinition';
import {FilterTypeDefault, FilterBoxSize, FilterBoxRenderFunction, ValidatorsTypeDefault} from './types';
import {useFilterState} from './useFilterState';
import {FilterButtonDumb} from './FilterButtonDumb';

/**
 * Utility type to assert that the necessary filter branch definition is inside the filter provider.
 */
export type FilterButtonFilterProviderApi<FilterBranch extends FilterBranchDefinitionsDefault[string]> =
  FilterProviderApi<Record<FilterBranch['branch'], FilterBranch>>;

/**
 * This component is used to wrap a filter button and corresponding dropdown with the filterProviderApi and filterBranchDefinition.
 */
export const FilterButton = <
  BranchKey extends string,
  InternalType extends FilterTypeDefault,
  DatabaseType extends FilterTypeDefault = FilterTypeDefault,
  ContextType extends FilterTypeDefault = FilterTypeDefault,
  ValidatorsType extends ValidatorsTypeDefault<InternalType> = ValidatorsTypeDefault<InternalType>,
  FilterButtonFilterProviderApiType extends FilterButtonFilterProviderApi<
    FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType>
  > = FilterButtonFilterProviderApi<
    FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType>
  >,
>({
  filterProviderApi,
  filterBranchDefinition,
  size,
  dark,
  disabled,
  children,
}: {
  filterProviderApi: FilterButtonFilterProviderApiType;
  filterBranchDefinition: FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType>;
  size?: FilterBoxSize;
  dark?: boolean;
  disabled?: boolean;
  children: FilterBoxRenderFunction<InternalType>;
}) => {
  const {name: title, branch} = filterBranchDefinition;

  const {values, isApplied, isValid, validationErrors, onChange, onChangeRangePicker, onApply, onReset} =
    useFilterState({
      filterProviderApi,
      filterBranchDefinition,
    });

  return (
    <FilterButtonDumb
      dataCy={`filter-${branch}`}
      dataTestid={`filter-${branch}`}
      title={title}
      values={values}
      isApplied={isApplied}
      isValid={isValid}
      validationErrors={validationErrors}
      size={size}
      dark={dark}
      disabled={disabled}
      onChange={onChange}
      onChangeRangePicker={onChangeRangePicker}
      onApply={onApply}
      onReset={onReset}
      children={children}
    />
  );
};
