import sumBy from 'lodash/sumBy';
import React, {useMemo} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {OfferStatisticsElement} from './OfferStatistic';
import {getDayToDayTrend} from '../../utils/getDayToDayTrend';

export const CargoOfferStatic = ({buckets}: {buckets: OfferBucket[]}) => {
  const cargoBuckets = useMemo(() => buckets.filter(bucket => (bucket.numberOfCargoOffers ?? 0) > 0), [buckets]);

  const value = sumBy(cargoBuckets, bucket => bucket.numberOfCargoOffers ?? 0);

  const trend = getDayToDayTrend(buckets);

  return <OfferStatisticsElement trend={trend} title={'Cargo'} value={value} />;
};
