import {Button, DatePicker} from 'antd';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import {FC, useEffect, useRef, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import BaseModal from '../../../atoms/BaseModal/index';
import LabelWrapper from '../../../atoms/LabelWrapper';
import autocompleteActions from '../../../redux/ApiService/autocomplete';
import vesselService from '../../../redux/ApiService/vesselService';
import {useSelector} from '../../../redux/react-redux';
import {invalidateGetPortfolioVesselsQuery} from '../../../screens/MyFleetScreens/MyFleetScreen/useGetPortfolioVesselsQuery/useGetPortfolioVesselsQuery';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {TODO} from '../../../utils/TODO';
import LoadingComponent from '../../DataGrid/LoadingComponent';
import {Comment} from './Parts/Comment';
import {LocationFormItem} from './Parts/LocationFormItem';
import {ModalError} from './Parts/ModalError';
import {SpotFormItem} from './Parts/SpotFormItem';
import {StatusFormItem} from './Parts/StatusFormItem';
import {VesselPicker} from './Parts/VesselPicker';
import './style.scss';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {PortfolioVessel} from '../../../redux/Portfolio';
import {VesselDetailsVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {Location} from '../../FilterProvider/Filters/utils/Location';

type OwnPortfolioLocationEditModalProps = {
  vesselProp?: $TSFixMe;
  // Is called after the onSave action is done
  callback?: (data: TODO) => void;
  onSave: () => void;
  close: () => void;
  fromMarket?: boolean;
  /* If false, the save and manage next button will not be shown.
   * For example, on the vessel detail screen, we don't want to show it.
   */
  initialIndex?: number;
  showManageNextButton?: boolean;
};

const getLocations = (vessel: TODO): Location[] => {
  return vessel && vessel.stations && vessel.stations[0] && vessel.stations[0].locations
    ? vessel.stations[0].locations
    : [];
};

const PortfolioLocationEditModalFn: FC<OwnPortfolioLocationEditModalProps> = ({
  vesselProp = null,
  callback,
  fromMarket = false,
  onSave,
  close,
  showManageNextButton = true,
  initialIndex = 0,
}) => {
  const _isMounted = useRef(false);
  const [vesselIndex, setVesselIndex] = useState(initialIndex);
  const [vesselOption, setVesselOption] = useState([]);
  const [disableButton, setDisableButton] = useState(!vesselProp);
  const [status, setStatus] = useState(vesselProp && vesselProp.status ? vesselProp.status : '');
  const [spotState, setSpotState] = useState(vesselProp && vesselProp.spotState ? vesselProp.spotState : false);
  const [vessel, setVessel] = useState(vesselProp || null);
  const [nextOpenFrom, setNextOpenFrom] = useState<string>(
    vesselProp && vesselProp.nextOpenFrom ? vesselProp.nextOpenFrom : ''
  );
  const [nextOpenTo, setNextOpenTo] = useState<string>(
    vesselProp && vesselProp.nextOpenTo ? vesselProp.nextOpenTo : ''
  );
  const [marketOneWasSelected, setMarketOneWasSelected] = useState(false);
  const [error, setError] = useState(false);
  const [locations, setLocations] = useState(getLocations(vesselProp));
  const [comment, setComment] = useState(vesselProp && vesselProp.comment ? vesselProp.comment : '');
  const nextVessel = vesselOption[vesselIndex + 1];

  useEffect(() => {
    setDisableButton(!vessel);
    setStatus(vessel && vessel.status ? vessel.status : '');
    setSpotState(vessel && vessel.spotState ? vessel.spotState : false);
    setNextOpenFrom(vessel && vessel.nextOpenFrom ? vessel.nextOpenFrom : '');
    setNextOpenTo(vessel && vessel.nextOpenTo ? vessel.nextOpenTo : '');
    setLocations(getLocations(vessel));
    setComment(vessel && vessel.comment ? vessel.comment : '');
  }, [vessel]);

  const dispatch = useDispatch();
  const preFillSearch = () => dispatch(autocompleteActions.preFillSearchPortfolioVessels());
  const updateVessel = (vesselId: string, body: TODO) => dispatch(vesselService.patch(vesselId, body));
  const loadSearchSingle = (query: TODO) => dispatch(autocompleteActions.searchPortfolioVessels(query));

  const isLoading = useSelector(({api}) => api.autoComplete.singleSearch.loading);
  const savingVesselLoading = useSelector(({api}) => api.vessels.patch.loading);

  const changeVessel = (vesselRes: TODO) => {
    if (vesselRes) {
      setVessel(vesselRes);
      setDisableButton(false);
      setSpotState(spotState || (vesselRes && vesselRes.spotState ? vesselRes.spotState : false));
      setStatus(status || (vesselRes && vesselRes.status ? vesselRes.status : ''));
      setNextOpenFrom(nextOpenFrom || (vesselRes && vesselRes.nextOpenFrom ? vesselRes.nextOpenFrom : ''));
      setNextOpenTo(nextOpenTo || (vesselRes && vesselRes.nextOpenTo ? vesselRes.nextOpenTo : ''));
      setLocations(getLocations(vesselRes));
      setComment(comment || (vesselRes && vesselRes.comment ? vesselRes.comment : ''));
    } else {
      setDisableButton(false);
      setSpotState(false);
      setStatus('');
      setNextOpenFrom('');
      setNextOpenTo('');
      setLocations([]);
      setComment('');
    }
  };

  const selectVesselViaMarket = (vesselRes: $TSFixMe) => {
    const marketData = {
      nextOpenFrom: vessel.nextOpenFrom,
      nextOpenTo: vessel.nextOpenTo,
      stations: vessel.stations,
      spotState: vessel.spotState,
      spotDate: vessel.spotDate,
    };
    changeVessel(vesselRes);
    setMarketOneWasSelected(true);
    setVessel({
      ...vessel,
      nextOpenFrom: marketData.nextOpenFrom,
      nextOpenTo: marketData.nextOpenTo,
      stations: marketData.stations,
      spotState: marketData.spotState,
      spotDate: marketData.spotDate,
    });
  };

  const setOptionsToPreFill = () => {
    if (fromMarket) {
      loadSearchSingle(vesselProp.name).then((res: $TSFixMe) => {
        if (_isMounted.current) {
          setVesselOption(res);
          if (res.length === 1) {
            selectVesselViaMarket(res[0]);
          }
        }
      });
    } else {
      preFillSearch().then((data: $TSFixMe) => {
        if (_isMounted.current) {
          setVesselOption(data);
          setMarketOneWasSelected(true);
        }
      });
    }
  };

  const handleQuery = debounce(query => {
    if (query.length === 0) {
      setOptionsToPreFill();
    }
    if (query.length < 2) {
      return;
    }
    loadSearchSingle(query).then((res: $TSFixMe) => {
      if (_isMounted.current) {
        setVesselOption(res);
      }
    });
  }, 400);

  useEffect(() => {
    _isMounted.current = true;
    setOptionsToPreFill();
    return () => {
      _isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeVesselState = (value: string) => {
    setDisableButton(false);
    if (value === 'scrapped' || value === 'inlayup') {
      setNextOpenFrom('');
      setNextOpenTo('');
      setLocations([]);
      setSpotState(false);
    }
  };

  const save = async (handleClose = false) => {
    try {
      const data = await updateVessel(vessel.id, {
        id: vessel.id,
        stations: [
          {
            type: 'nextopen',
            locations: locations.map((location: $TSFixMe) => ({
              id: location.id,
            })),
          },
        ],
        spotState: spotState,
        status: status ? status : null,
        nextOpenFrom: !spotState ? nextOpenFrom : null,
        nextOpenTo: !spotState ? nextOpenTo : null,
        comment: comment,
      });

      onSave?.();
      if (_isMounted.current) {
        setError(false);
        callback?.({id: vessel.id, data});
        if (handleClose) {
          close();
        } else {
          setVessel(nextVessel || null);
          setVesselIndex(p => p + 1);
          setOptionsToPreFill();
          if (!error) {
            changeVessel(null);
          }
        }
      }
    } catch (e) {
      if (_isMounted.current) {
        setError(true);
      }
      throw e;
    }
  };

  const isButtonDisabled = () => {
    return !!(
      !vessel ||
      disableButton ||
      (fromMarket && ((vesselOption && vesselOption.length === 0) || !marketOneWasSelected))
    );
  };

  const rangeValue = nextOpenFrom !== '' && nextOpenTo !== '' ? [dayjs(nextOpenFrom), dayjs(nextOpenTo)] : undefined;

  return (
    <>
      {!savingVesselLoading ? (
        <div className={'portfolio-location-edit'}>
          <div className={'portfolio-location-edit__body'}>
            <div className={'portfolio-location-body'}>
              <div className={'portfolio-location-body__name'}>
                <VesselPicker
                  handleQuery={handleQuery}
                  isLoading={isLoading}
                  vesselOption={vesselOption}
                  onChangeVessel={vessel => {
                    changeVessel(vessel);
                  }}
                  onSelectVesselViaMarket={vessel => {
                    selectVesselViaMarket(vessel);
                  }}
                  vessel={vessel}
                  fromMarket={fromMarket}
                  marketOneWasSelected={marketOneWasSelected}
                />
                <StatusFormItem
                  value={status}
                  onChange={value => {
                    setStatus(value);
                    changeVesselState(value);
                  }}
                />
              </div>
              <div className={'portfolio-location-body__stations'}>
                <LocationFormItem
                  vessel={vessel}
                  status={status}
                  locations={locations}
                  changeVesselState={locations => {
                    setLocations(locations);
                  }}
                />
              </div>
              <div className={'portfolio-location-body__name'} onClick={e => e.stopPropagation()}>
                <FlexContainer>
                  <LabelWrapper
                    label={'Next open from/to'}
                    style={{width: '100%', paddingRight: '10px', position: 'relative'}}>
                    <RangePicker
                      disabled={spotState}
                      format={DateTimeFormat.Date}
                      value={[rangeValue?.[0], rangeValue?.[1]]}
                      getPopupContainer={() => {
                        return document.getElementById('rangePanel')!;
                      }}
                      placeholder={['Next open from', 'Next open to']}
                      onChange={value => {
                        const [from, to] = (value ?? [null, null]).values();
                        setNextOpenFrom(from ? from.format(DateTimeFormat.IsoDate) : '');
                        setNextOpenTo(to ? to.format(DateTimeFormat.IsoDate) : '');
                      }}
                    />
                  </LabelWrapper>
                </FlexContainer>
                <div id="rangePanel" />

                <SpotFormItem
                  status={status}
                  spotState={spotState}
                  onChangeVesselState={newSpotState => {
                    setSpotState(newSpotState);
                    // When a vessel becomes spot, we want to clear the next open dates
                    if (newSpotState) {
                      setNextOpenFrom('');
                      setNextOpenTo('');
                    }
                  }}
                />
              </div>
              <div className={'portfolio-location-body__name'}>
                <Comment
                  comment={comment}
                  onChange={newComment => {
                    setComment(newComment);
                  }}
                />
              </div>
            </div>
          </div>

          {error && <div className={'modal__error'}>Something went wrong, please try again.</div>}

          <ModalError fromMarket={fromMarket} marketOneWasSelected={marketOneWasSelected} vesselOption={vesselOption} />

          <ModalFooter>
            {showManageNextButton
              ? !fromMarket && (
                  <Button
                    disabled={isButtonDisabled() || nextVessel === undefined}
                    onClick={() => save()}
                    type="primary">
                    Save and manage next
                  </Button>
                )
              : null}
            <Button
              disabled={isButtonDisabled()}
              onClick={() => save(true)}
              type={!showManageNextButton || fromMarket ? 'primary' : 'default'}>
              Save and close
            </Button>
          </ModalFooter>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

export const PortfolioLocationEdit = (props: {
  vessel: PortfolioVessel | VesselDetailsVessel;
  withDateOpen?: boolean;
  showManageNextButton?: boolean;
  callback: () => void;
  initialIndex?: number;
}) => {
  const queryClient = useQueryClient();
  return (
    <BaseModal title="Manage next open location" width={600}>
      {(modalProps: $TSFixMe) => {
        return (
          <PortfolioLocationEditModalFn
            onSave={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              invalidateGetPortfolioVesselsQuery(queryClient);
            }}
            vesselProp={props.vessel}
            {...props}
            {...modalProps}
          />
        );
      }}
    </BaseModal>
  );
};

const RangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  align-self: center;
  margin-right: 10px;
  background-color: var(--color-gray-6);

  .ant-picker-input input {
    &::placeholder {
      color: hsl(0, 0%, 50%);
      opacity: 1;
    }
  }
`;

const FlexContainer = styled.div`
  flex: 2;
`;
