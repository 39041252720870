import {Form, InputNumber} from 'antd';
import {FC} from 'react';

export const CargoQuantityField: FC = () => {
  return (
    <Form.Item
      rules={[{required: true, message: 'Cargo quantity is required', type: 'number', min: 1}]}
      label={'Quantity in MTS'}
      name={['cargo', 'cargoQuantity']}>
      <InputNumber style={{width: '100%'}} data-cy="quantityInput" />
    </Form.Item>
  );
};
