import {SeaboVessel} from '../../../../api/node-backend/generated';
import {IntakeType, PortfolioVessel} from '../../../../redux/Portfolio';
import {DesignSubType} from '../../../VesselDatabaseScreen/VesselDatabaseGrid/VesselGridActionMenu/getDesignSubType';

export const transformSeaboVesselToPortfolioVessel = (seaboVessel: SeaboVessel): Partial<PortfolioVessel> => {
  return {
    name: seaboVessel.name,
    vesselType: seaboVessel.vesselType as PortfolioVessel['vesselType'],
    status: seaboVessel.status as PortfolioVessel['status'],
    intakes: seaboVessel.intakes.map(intake => ({
      type: intake.type as IntakeType,
      quantity: intake.quantity,
      isHighCube: intake.isHighCube,
    })),
    designSubType: seaboVessel.designSubType as DesignSubType | null,
    isArchived: seaboVessel.isArchived,
    redeliveryNotice: seaboVessel.redeliveryNotice,
    consumptions: seaboVessel.consumptions,
  };
};
