import React, {Fragment, ReactNode} from 'react';
import FloatRangeInput from '../../../RangeInput/FloatRangeInput';
import NumberRangeInput from '../../../RangeInput/NumberRangeInput';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import {hasError} from '../../../../utils/helper';
import {filterBranchDefinition, IntakeUnits} from './IntakeDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import Select from '../../../../atoms/Select/Select';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
  badge?: ReactNode;
  disabled?: boolean;
}

const Intake = ({filterProviderApi, disabled, badge}: Props) => (
  <>
    <FilterButton
      disabled={disabled}
      filterProviderApi={filterProviderApi}
      filterBranchDefinition={filterBranchDefinition}
      size="big">
      {({handleChange, values, validations}) => (
        <Fragment>
          <div className="container">
            <div className="row bottom-fix">
              <div className="scol-4">
                <FloatRangeInput
                  flex
                  label={'Grain'}
                  id={'filter-grain'}
                  fromValue={values.grainFrom}
                  onFromChange={v => handleChange('grainFrom', v)}
                  toValue={values.grainTo}
                  onToChange={v => handleChange('grainTo', v)}
                  allowEqualMinMax
                  fromProps={{
                    min: 0,
                    max: values.grainUnit && values.grainUnit.value === 'cbm' ? 999999 : 99999999,
                    placeholder: 'min',
                    hasError: hasError('grainFrom', validations),
                  }}
                  decimalsLeft={values.grainUnit && values.grainUnit.value === 'cbm' ? 6 : 8}
                  toProps={{
                    min: 0,
                    max: values.grainUnit && values.grainUnit.value === 'cbm' ? 999999 : 99999999,
                    placeholder: 'max',
                    hasError: hasError('grainTo', validations),
                  }}
                />
                <Select
                  id={'filter-intake-grain-unit-select'}
                  value={values.grainUnit === '' ? null : values.grainUnit}
                  onChange={unit => handleChange('grainUnit', unit)}
                  options={IntakeUnits}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.value}
                  components={{
                    DropdownIndicator: ArrowIndicator,
                  }}
                  isClearable={true}
                  isSearchable={false}
                />
              </div>

              <div className="scol-4" style={{marginBottom: 15}}>
                <FloatRangeInput
                  flex
                  label={'Bale'}
                  id={'filter-bale'}
                  fromValue={values.baleFrom}
                  onFromChange={v => handleChange('baleFrom', v)}
                  toValue={values.baleTo}
                  onToChange={v => handleChange('baleTo', v)}
                  allowEqualMinMax
                  fromProps={{
                    min: 0,
                    max: values.baleUnit && values.baleUnit.value === 'cbm' ? 999999 : 99999999,
                    placeholder: 'min',
                    hasError: hasError('baleFrom', validations),
                  }}
                  decimalsLeft={values.baleUnit && values.baleUnit.value === 'cbm' ? 6 : 8}
                  toProps={{
                    min: 0,
                    max: values.baleUnit && values.baleUnit.value === 'cbm' ? 999999 : 99999999,
                    placeholder: 'max',
                    hasError: hasError('baleTo', validations),
                  }}
                />
                <Select
                  id={'filter-intake-bale-unit-select'}
                  value={values.baleUnit === '' ? null : values.baleUnit}
                  onChange={unit => handleChange('baleUnit', unit)}
                  options={IntakeUnits}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.value}
                  components={{
                    DropdownIndicator: ArrowIndicator,
                  }}
                  isClearable={true}
                  isSearchable={false}
                />
              </div>

              <div className="scol-4">
                <NumberRangeInput
                  flex
                  label={'Reefer plugs'}
                  id={'filter-plugs'}
                  fromValue={values.plugsFrom}
                  onFromChange={value => handleChange('plugsFrom', value)}
                  toValue={values.plugsTo}
                  onToChange={value => handleChange('plugsTo', value)}
                  allowEqualMinMax
                  fromProps={{
                    min: 0,
                    max: 99999,
                    placeholder: 'min',
                    hasError: hasError('plugsFrom', validations),
                  }}
                  toProps={{
                    min: 0,
                    max: 99999,
                    placeholder: 'max',
                    hasError: hasError('plugsTo', validations),
                  }}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </FilterButton>
    {badge}
  </>
);

export default Intake;
