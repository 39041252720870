import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const ValueRow = styled((props: PropsWithChildren<Props>) => {
  return (
    <tbody className={props.className} onClick={props.onClick}>
      <StyledTableRow>{props.children}</StyledTableRow>
    </tbody>
  );
})`
  height: 33px;
`;

const StyledTableRow = styled.tr``;
