import {$createParagraphNode, $createTextNode, ParagraphNode} from 'lexical';
import {NegotiationTermType} from '../../../../../../api/node-backend/generated';
import {$createPlaceholderNode} from '../nodes/PlaceholderNode';
import {$createTermTypeNode} from '../nodes/TermTypeNode';
import {isNegotiationPlaceholderKey} from '../../../utils/placeholderKeys';
import {RefObject} from 'react';

export const convertTextToLexicalNodes = (
  text: string,
  type: NegotiationTermType | null,
  onTermTypeChange: (type: NegotiationTermType | null) => void
): ParagraphNode => {
  const paragraphNode = $createParagraphNode();
  paragraphNode.append($createTermTypeNode(type, onTermTypeChange));

  const textPartials = text.split(/{.*?}/);
  const placeholderPartials = text.match(/{.*?}/g);

  textPartials.forEach((partial, idx) => {
    const textNode = $createTextNode(partial);
    paragraphNode.append(textNode);
    if (placeholderPartials && placeholderPartials[idx]) {
      const placeholderPartial = placeholderPartials[idx].replace(/{|}/g, '');
      if (isNegotiationPlaceholderKey(placeholderPartial)) {
        const placeholderNode = $createPlaceholderNode(placeholderPartial);
        paragraphNode.append(placeholderNode);
      } else {
        const textNode = $createTextNode(placeholderPartial);
        paragraphNode.append(textNode);
      }
    }
  });

  return paragraphNode;
};

type TemplateTextNode = {
  type: string;
  children?: TemplateTextNode[];
  text?: string;
  placeholderKey?: string;
};

export const convertLexicalNodesToText = (node: TemplateTextNode) => {
  let text = '';

  node.children?.forEach(child => {
    if (child.children) {
      text += convertLexicalNodesToText(child);
      return;
    }
    if (child.type === 'text') {
      text += child.text;
    } else if (child.type === 'linebreak') {
      text += '\n';
    } else if (child.type === 'placeholder') {
      text += `{${child.placeholderKey}}`;
    }
  });

  return text;
};

export const getTermListItem = (termContainerRef: RefObject<HTMLElement>) => {
  return termContainerRef.current?.parentElement?.parentElement?.parentElement;
};

export const getTermTextNode = (termElement?: Element | null) => {
  return termElement?.querySelector("[contenteditable='true']") as HTMLElement | undefined;
};

export const focusTermText = (termElement?: Element | null) => {
  const textNode = getTermTextNode(termElement);
  if (!textNode) {
    return;
  }
  textNode.focus();
  window.getSelection()?.selectAllChildren(textNode as Node);
};
