import {CSSProperties, FC, ReactNode, TransitionEventHandler, useEffect, useRef, useState} from 'react';
import {assertUnreachable} from '../../utils/assert';
import styled from 'styled-components';

interface Props {
  isOpen?: boolean;
  /**
   * Helps with debugging.
   */
  name?: string;
  className?: string;
  children: ReactNode;
}

/**
 * We use a tiny state machine, and these are the states.
 *
 * Possible transitions are:
 *     open => collapsing-1 => collapsing-2 => closed => expanding => open
 */
type State = 'open' | 'collapsing-1' | 'collapsing-2' | 'closed' | 'expanding';

type ContentStyle = Pick<CSSProperties, 'height' | 'overflow' | 'visibility'>;

/**
 * Shows or hides its children, based on the prop `isOpen`.
 * Performs a transition when changing between the two states.
 */
export const Collapse: FC<Props> = props => {
  const [state, setState] = useState<State>(props.isOpen ? 'open' : 'closed');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((props.isOpen && state === 'open') || (!props.isOpen && state === 'closed')) {
      return;
    }
    if (props.isOpen) {
      expand();
    } else {
      collapse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  /**
   * Transitions the component from closed to open.
   */
  const expand = () => {
    setState('expanding');
  };

  /**
   * Transitions the component from open to closed.
   */
  const collapse = () => {
    setState('collapsing-1');
  };

  // After one render cycle go from collapsing-1 to collapsing-2
  useEffect(() => {
    if (state !== 'collapsing-1') {
      return;
    }
    setState('collapsing-2');
  }, [state]);

  const handleTransitionEnd: TransitionEventHandler<HTMLDivElement> = event => {
    if (event.target !== contentRef.current || event.propertyName !== 'height') {
      // This event is not for our component or it does not concern the property we are working on.
      return;
    }
    switch (state) {
      case 'expanding':
        setState('open');
        break;
      case 'collapsing-2':
        setState('closed');
        break;
      case 'open':
        // Component already expanded - do nothing
        break;
      case 'collapsing-1':
        // Component is collapsing and some other place will update its state to "collapsing-2" - do nothing
        break;
      case 'closed':
        // Component already closed - do nothing
        break;
      default:
        assertUnreachable(state);
    }
  };

  const contentHeight = contentRef.current?.scrollHeight ?? 0;

  let contentStyle: ContentStyle;
  switch (state) {
    case 'open':
      contentStyle = {
        height: 'auto',
        overflow: 'visible',
        visibility: 'visible',
      };
      break;
    case 'closed':
      contentStyle = {
        height: 0,
        overflow: 'visible',
        visibility: 'hidden',
      };
      break;
    case 'expanding':
      contentStyle = {
        height: contentHeight,
        overflow: 'hidden',
        visibility: 'visible',
      };
      break;
    case 'collapsing-1':
      contentStyle = {
        height: contentHeight,
        overflow: 'visible',
        visibility: 'visible',
      };
      break;
    case 'collapsing-2':
      contentStyle = {
        height: 0,
        overflow: 'hidden',
        visibility: 'visible',
      };
      break;
    default:
      assertUnreachable(state);
  }

  return (
    <Content
      data-debugname={props.name}
      data-debugstate={state}
      ref={contentRef}
      style={contentStyle}
      className={props.className}
      onTransitionEnd={handleTransitionEnd}>
      {props.children}
    </Content>
  );
};

const Content = styled.div`
  transition-property: height, margin, padding;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: height;
`;
