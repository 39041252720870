import {apiErrorMiddleware} from '../utils/apiErrorMiddleware';
import {apiTransactionIdMiddleware} from '../utils/apiTransactionIdMiddleware';
import {Configuration, DefaultApi} from './generated';
import {apiAccessTokenMiddleware} from '../utils/apiAccessTokenMiddleware';

const BasePathForProduction = 'https://srv20110.dus4.fastwebserver.de';
const BasePathForTest = 'http://localhost';

const BasePath = window ? BasePathForProduction : BasePathForTest;

const configuration = new Configuration({
  basePath: BasePath,
  middleware: [apiTransactionIdMiddleware, apiErrorMiddleware, apiAccessTokenMiddleware],
});

export const bunkerServiceApi = new DefaultApi(configuration);
