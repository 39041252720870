import {useEffect, useState, FC} from 'react';
import {NotFound} from '../../atoms/EmptyState/404';
import LoadingComponent from '../DataGrid/LoadingComponent';
import {CharterCalculations} from './CharterCalculations';
import {ProjectDetailDocuments} from './ProjectDetailDocuments';
import {useProjectDetails} from './useProjectDetails';
import {ProjectDetailItem} from './ProjectDetailItem/ProjectDetailItem';
import {ProjectDetailsShortlist} from './ProjectDetailsShortlist';
import styled from 'styled-components';
import {ProjectDetailMap} from './ProjectDetailMap';
import Cargo from '../../model/Cargo';
import AisVessel from '../../model/AisVessel';
import {ScreenHeader} from '../ScreenHeader/ScreenHeader';
import './style.scss';
import {ProjectDetailHeader} from './ProjectDetailHeader';
import {Space} from 'antd';

export type PortLocation = {
  name: string;
  countryObject: {
    code: string;
    name: string;
  };
  country: string;
  code?: string;
  lat: number;
  lon: number;
};

export type AISDetails = {
  imo: number;
  coordinates: string;
  latitude: number;
  longitude: number;
  postime: number;
  class: string;
  course: number;
  speed: number;
  draught: number;
  draughtMax: number;
  statusText: string;
  currentPort: PortLocation;
  currentArea: PortLocation;
  lastPort: PortLocation;
  lastArea: PortLocation;
  destinationPort: PortLocation;
  destinationArea: PortLocation;
  eta?: string;
};

export interface ProjectVessel extends AisVessel {
  aisDetails: AISDetails;
}

export interface ProjectCargo extends Cargo {
  vesselSizeUnit: string;
  marketSegment: string;
}

export const ProjectDetailsBody: FC = () => {
  const {project, loading, getProject, onEdit, onFavorite, onArchive, onDelete} = useProjectDetails();
  const [selectedShortlistItemId, setSelectedShortlistItemId] = useState<number | null>(null);

  useEffect(() => {
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }

  if (!project) {
    return <NotFound />;
  }

  const onShortlistItemClick = (newId: number) => setSelectedShortlistItemId(id => (id === newId ? null : newId));

  return (
    <div>
      <ScreenHeader
        helmetTitle={project.name}
        breadcrumbs={[{title: 'Projects', href: '/projects'}, {title: project.type + ' Project'}]}
        actions={
          <Space size={4}>
            {!project.isArchived && (
              <ScreenHeader.Actions.Button onClick={onFavorite} data-cy="ProjectDetailFavoriteBTN">
                {project.isSticky ? 'Unmark as favorite' : 'Mark as favorite'}
              </ScreenHeader.Actions.Button>
            )}
            <ScreenHeader.Actions.Button onClick={onEdit} data-cy="ProjectDetailEditBTN">
              Edit
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button onClick={onArchive} data-cy="ProjectDetailArchiveBTN">
              {project.isArchived ? 'Restore' : 'Archive'}
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button onClick={onDelete} data-cy="ProjectDetailDeleteBTN">
              Delete
            </ScreenHeader.Actions.Button>
          </Space>
        }
      />
      <ProjectDetailHeader />
      <FlexBox>
        <BoxThird>
          <ProjectDetailItem />
        </BoxThird>
        <BoxTwoThird>
          <ProjectDetailMap
            selectedShortlistItemId={selectedShortlistItemId}
            onShortlistItemClick={onShortlistItemClick}
          />
        </BoxTwoThird>
      </FlexBox>
      <ProjectDetailsShortlist
        selectedShortlistItemId={selectedShortlistItemId}
        onShortlistItemClick={onShortlistItemClick}
      />
      <CharterCalculations />
      <ProjectDetailDocuments />
    </div>
  );
};

const FlexBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  margin-bottom: 20px;
`;

const BoxThird = styled.div`
  flex: 1 1 45%;
  height: auto;
  align-self: stretch;
`;

const BoxTwoThird = styled.div`
  flex: 1 1 55%;
  height: auto;
  align-self: stretch;
`;
