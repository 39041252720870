import React, {useEffect, useState} from 'react';
import {AutoComplete, Input} from 'antd';
import styled from 'styled-components';
import {PortOption} from '../SearchPicker/Type';
import {useOptionsFromPortSearch} from '../SearchPicker/useOptionsFromPortSearch';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {SearchPickerPort} from '../SearchPicker/SearchPickerPort';

interface PortPickerProps {
  onSelect: (port: SearchPickerPort) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onChangeText?: (text: string, type: 'onSelect' | 'onChange') => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  autoFocus?: boolean;
  className?: string;
  placeholder?: string;
  prefix?: React.ReactNode;
  size?: SizeType;
}

const StyledAutoComplete: typeof AutoComplete = styled(AutoComplete)`
  width: 100%;
`;

export const PortPicker = (props: PortPickerProps) => {
  const [searchTag, setSearchTag] = useState('');

  const [forceUpdateCount, setForceUpdateCount] = useState(1);
  useEffect(() => {
    if (props.defaultValue) {
      setSearchTag(props.defaultValue);
      setForceUpdateCount(forceUpdateCount + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  const options = useOptionsFromPortSearch({
    searchTag,
  });

  return (
    <StyledAutoComplete
      key={forceUpdateCount}
      value={searchTag}
      className={props.className}
      size={props.size}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
      defaultValue={props.defaultValue}
      onSelect={(_v: string, option: PortOption) => {
        props.onSelect(option.source);
        setSearchTag(option.value);
        if (props.onChangeText) {
          props.onChangeText(option.value, 'onSelect');
        }
      }}
      options={options}>
      <Input.Search
        prefix={props.prefix}
        size={props.size}
        onKeyDown={event => props.onKeyDown && props.onKeyDown(event)}
        data-testid="portPickerInputField"
        onChange={event => {
          const newValue = event.currentTarget.value.trimStart();
          setSearchTag(newValue);
          if (props.onChangeText) {
            props.onChangeText(newValue, 'onChange');
          }
        }}
        placeholder={props.placeholder ?? 'Search for a port'}
      />
    </StyledAutoComplete>
  );
};
