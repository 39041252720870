import {useEffect} from 'react';
import CargoType from '../../../../components/FilterProvider/Filters/CargoType/CargoType';
import Charterer from '../../../../components/FilterProvider/Filters/Charterer/Charterer';
import ContractType from '../../../../components/FilterProvider/Filters/ContractType/ContractType';
import {FilterProviderApi} from '../../../../components/FilterProvider/FilterProviderApi';
import VesselSize from '../../../../components/FilterProvider/Filters/VesselSize/VesselSize';
import LocationCargo from '../../../../components/FilterProvider/Filters/LocationCargo/LocationCargo';
import Duration from '../../../../components/FilterProvider/Filters/Duration/Duration';
import Quantity from '../../../../components/FilterProvider/Filters/Quantity/Quantity';
import Features from '../../../../components/FilterProvider/Filters/Features/Features';
import Laycan from '../../../../components/FilterProvider/Filters/Laycan/Laycan';
import Commodities from '../../../../components/FilterProvider/Filters/Commodities/Commodities';
import {useDispatch} from 'react-redux';
import AutocompleteActions from '../../../../redux/ApiService/autocomplete';
import {FilterBar} from '../../../../components/FilterBar/FilterBar';
import {PortfolioCargoFilterBranchDefinitions} from '../../../../components/FilterProvider/Filters/Portfolio/PortfolioCargoFilterBranchDefinitions';

type CargoFiltersProps = {
  filterProviderApi: FilterProviderApi<typeof PortfolioCargoFilterBranchDefinitions>;
};

export const MyCargoesFilters = ({filterProviderApi}: CargoFiltersProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Load options for one of the filter boxes.
    dispatch(AutocompleteActions.preFillSearch('design_type'));
  }, [dispatch]);

  return (
    <FilterBar
      id="cargoFilters"
      filters={[
        <CargoType filterProviderApi={filterProviderApi} />,
        <Charterer filterProviderApi={filterProviderApi} />,
        <ContractType filterProviderApi={filterProviderApi} />,
        <VesselSize filterProviderApi={filterProviderApi} />,
        <Quantity filterProviderApi={filterProviderApi} />,
        <Features filterProviderApi={filterProviderApi} />,
        <Laycan filterProviderApi={filterProviderApi} />,
        <Duration filterProviderApi={filterProviderApi} />,
        <LocationCargo filterProviderApi={filterProviderApi} />,
        <Commodities filterProviderApi={filterProviderApi} />,
      ]}
    />
  );
};
