import {Affix, Button, Modal, Select, Tabs, TabsProps} from 'antd';
import {ReactNode, useState} from 'react';
import {FeatureToggles} from '../../utils/FeatureToggles';
import {isLocalDevEnvironment} from '../../utils/environment';
import {FilterProviderState} from './FilterProviderState';
import {FilterBranchDefinitionsDefault, FilterDatabaseType} from './FilterBranchDefinition';

export const debugUIEnabled = isLocalDevEnvironment() && FeatureToggles.filterDebugger;

export const FilterDebugger = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>({
  filterProviderState,
  currentFilters,
  extra,
  extraTabs = [],
  extraButtonText,
}: {
  currentFilters: Partial<FilterDatabaseType<FilterBranchDefinitions>> | null;
  filterProviderState: FilterProviderState<FilterBranchDefinitions> | null;
  extra?: ReactNode;
  extraTabs?: TabsProps['items'];
  extraButtonText?: string;
}) => {
  const [selectedBranch, setSelectedBranch] = useState<keyof FilterBranchDefinitions | undefined>();
  const [showDebugger, setShowDebugger] = useState<boolean>(false);

  if (!debugUIEnabled) {
    return null;
  }

  const options = Object.keys(currentFilters ?? {}).map(value => ({value: value, label: value}));

  const databaseValue = currentFilters;
  const internalValue = filterProviderState?.internalFilterSettings;

  const databaseValueBranch = selectedBranch ? databaseValue?.[selectedBranch] : null;
  const internalValueBranch = selectedBranch ? internalValue?.[selectedBranch] : null;

  const items = [
    {label: 'Database', key: 'database', children: <pre>{JSON.stringify(databaseValue, null, 2)}</pre>},
    {
      label: 'Database Branch',
      key: 'databaseBranch',
      children: <pre>{JSON.stringify(databaseValueBranch, null, 2)}</pre>,
    },
    {
      label: 'Internal (from cargoFilterProvider)',
      key: 'internal',
      children: <pre>{JSON.stringify(internalValue, null, 2)}</pre>,
    },
    {
      label: 'Internal Branch (from cargoFilterProvider)',
      key: 'internalBranch',
      children: <pre>{JSON.stringify(internalValueBranch, null, 2)}</pre>,
    },
    {label: 'currentFilterProvider', key: '3', children: <pre>{JSON.stringify(filterProviderState, null, 2)}</pre>},
    ...extraTabs,
  ];

  return (
    <>
      <Affix offsetBottom={20}>
        <Button type={'primary'} style={{backgroundColor: 'red'}} onClick={() => setShowDebugger(true)}>
          Show Filter Debugger {extraButtonText ? `- ${extraButtonText}` : ''}
        </Button>
      </Affix>
      <Modal
        width={1000}
        title={
          <>
            Filter Debugger{' '}
            <Select<string>
              showSearch={true}
              style={{width: 150}}
              allowClear={true}
              onClear={() => setSelectedBranch(undefined)}
              onSelect={(value: string) => setSelectedBranch(value)}
              options={options}></Select>
            {extra}
          </>
        }
        open={showDebugger}
        onCancel={() => setShowDebugger(false)}
        okButtonProps={{hidden: true}}>
        <Tabs defaultActiveKey="1" items={items} />
      </Modal>
    </>
  );
};
