import {FirstLastTimestamp, Waypoint} from '../useRouteTripLayer';

export const getFirstLastTimestampsFromRouteItem = (waypoints: Waypoint[]): FirstLastTimestamp => {
  let min = Infinity;
  let max = 0;

  waypoints.forEach(item => {
    if (item.timestamp < min) {
      min = item.timestamp;
    }
    if (item.timestamp > max) {
      max = item.timestamp;
    }
  });

  return {
    first: min,
    last: max,
  };
};
