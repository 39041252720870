import {FC} from 'react';
import {FastVesselImportParsedValues} from './ImportExcel';
import {Button, Form, Select} from 'antd';
import {getDynamicTableColumns} from './getDynamicTableColumns';
import {DataGrid} from '../../../../components/DataGrid/DataGrid';
import {ModalFooter} from '../../../../antd/components/ModalFooter';

export const SelectImoColumn: FC<{
  values: FastVesselImportParsedValues;
  onGoToNextStep: () => void;
  onChangeImoColumn: (columnKey: FastVesselImportParsedValues['predictedImoColumn']) => void;
}> = ({values, onGoToNextStep, onChangeImoColumn}) => {
  return (
    <Form
      onFinish={values => {
        onChangeImoColumn(values.predictedImoColumn);
        onGoToNextStep();
      }}
      initialValues={{
        predictedImoColumn: values.predictedImoColumn ?? undefined,
      }}
      style={{marginTop: 36}}>
      <Form.Item rules={[{required: true}]} name={'predictedImoColumn'} label={'IMO column'}>
        <Select
          showSearch
          style={{width: 200}}
          allowClear={true}
          options={values.availableColumns.map(key => ({label: key, value: key}))}
        />
      </Form.Item>
      <DataGrid data={values.vessels} columns={[...getDynamicTableColumns(values.availableColumns)]} />
      <ModalFooter>
        <Button htmlType={'submit'} type={'primary'}>
          Next
        </Button>
      </ModalFooter>
    </Form>
  );
};
