import {FC} from 'react';
import Icon from '../Icon';
import styled from 'styled-components';

import './style.scss';

interface Props {
  id: string;
  checked: boolean;
  label?: string;
  disabled?: boolean;
  hasSubMenu?: boolean;
  reverse?: boolean;
  visualDisabled?: boolean;
  isSubMenuOpen?: boolean;
  toggleSubMenu?: () => void;
  onChange: (value: boolean) => void;
}

/** @deprecated use AntD Switch instead */
export const Toggle: FC<Props> = ({
  id,
  checked,
  disabled = false,
  visualDisabled,
  onChange,
  label,
  hasSubMenu,
  isSubMenuOpen,
  toggleSubMenu,
  reverse = false,
}) => (
  <StyledToggle theme={{disabled, visualDisabled, reverse}} htmlFor={`toggle-${id}`}>
    <StyledToggleLabel>
      {label}
      {hasSubMenu && (
        <Icon
          type={isSubMenuOpen ? 'angle-up' : 'angle-down'}
          onClick={e => {
            e.preventDefault();
            if (!disabled) {
              toggleSubMenu!();
            }
          }}
        />
      )}
    </StyledToggleLabel>
    <StyledInputSlider
      theme={{disabled}}
      id={`toggle-${id}`}
      checked={checked}
      disabled={disabled}
      type="checkbox"
      onChange={event => onChange(event.target.checked)}
    />
    <StyledToggleSlider />
  </StyledToggle>
);

const StyledToggle = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  min-height: 30px;

  ${props => props.theme.reverse && 'flex-direction: row-reverse;'};

  ${props =>
    (props.theme.disabled || props.theme.visualDisabled) &&
    `
      cursor: default; 
      
      .toggle_label: {
        color: var(--color-gray-3);
      } 
      .toggle__input:checked + .toggle__slider {
        border-color: var(--border-color);
        background-color: var(--color-gray-3);
      }
  `}
`;

const StyledToggleLabel = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-left: 5px;
  }
`;

const StyledToggleSlider = styled.div`
  position: relative;
  width: 36px;
  height: 16px;
  border: 1px solid var(--color-gray-3);
  border-radius: 9px;
  flex: 0 0 auto;

  &:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 2px;
    top: 2px;
    background-color: var(--color-gray-3);
    transition: transform 0.2s;
    border-radius: 50%;
  }
`;

const StyledInputSlider = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  ${props => props.theme.disabled && 'cursor: default;'};

  &:focus + ${StyledToggleSlider} {
    outline: 1px solid var(--color-blue);
  }

  &:checked + ${StyledToggleSlider} {
    border-color: var(--color-green-light);
    background-color: var(--color-green-light);
  }

  &:checked + ${StyledToggleSlider}:before {
    transform: translateX(20px);
    background-color: var(--color-white);
  }
`;
