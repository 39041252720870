import React, {useMemo} from 'react';
import {ResultVessel} from './types';
import {Coordinates, LonLatCoordinates, transformObjectFromLonLat} from '../../../utils/Coordinates';
import PulsatingCirclesLayer from '../../../components/SeaboMap/layers/mapbox/PulsatingCirclesLayer';
import {useHoveredVesselRouteLayer} from './useHoveredVesselRouteLayer';
import {Card} from 'antd';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {SeaboMap} from '../../../components/SeaboMap/SeaboMap';
import {SearchPickerPort} from '../../../components/SearchPicker/SearchPickerPort';
import {makePartialMapSwitches} from '../../../components/SeaboMap/utils/makePartialMapSwitches';
import {MapControlButton} from '../../../components/SeaboMap/MapContext/Types';

export type MapProps = {
  result?: ResultVessel[];
  port?: SearchPickerPort;
  hoveredVessel?: ResultVessel;
  showMapDetails: boolean;
};

export const CompetitorsMap = ({result, port, hoveredVessel, showMapDetails}: MapProps) => {
  const layer: React.ReactElement[] = [];

  if (hoveredVessel?.coordinates) {
    layer.push(
      <PulsatingCirclesLayer
        points={[transformObjectFromLonLat(hoveredVessel.coordinates as LonLatCoordinates)]}
        color={'#ffc752'}
      />
    );
  }

  const hoveredVesselRouteLayer = useHoveredVesselRouteLayer({
    vessel: hoveredVessel,
  });
  const coordinatesToFocus = useMemo(() => {
    if (!hoveredVessel) {
      return result ? result.map(vessel => vessel.coordinates as Coordinates) : undefined;
    }
    return [
      hoveredVessel.coordinates!,
      ...hoveredVessel.distanceRoutes.flatMap(route => route.waypoints),
    ] as Coordinates[];
  }, [result, hoveredVessel]);

  return (
    <div style={{height: 600, width: '100%'}}>
      <SeaboMap
        coordinatesToFocus={coordinatesToFocus}
        showPopups={showMapDetails}
        showControl
        settingsButtonVisible={true}
        showPortIds={port ? [port.id] : undefined}
        defaults={'potential_competitors'}
        settingIdentifier={'potential_competitors'}
        layersOnTopOfMapBox={layer}
        componentOnTop={
          <div style={{position: 'absolute'}} key="timestamp">
            {hoveredVesselRouteLayer.timestamp && (
              <Card size={'small'}>{hoveredVesselRouteLayer.timestamp?.format(DateTimeFormat.Date)}</Card>
            )}
          </div>
        }
        mapControlButtonsToHide={[MapControlButton.VESSELS, MapControlButton.CARGOES]}
        initialMapSettings={{
          mapStyle: 'SAT',
          switches: makePartialMapSwitches({
            ports: true,
            tradingAreaNames: false,
            highRiskAreas: true,
            emissionControlAreas: true,
          }),
        }}
        layers={[hoveredVesselRouteLayer.layer]}
        vesselsToVisualize={{data: result ?? [], opacity: 1, constantIconSize: 8}}
      />
    </div>
  );
};
