import {ApiServiceEntry, ApiService, makeApiService} from './ApiService';
import {Reference} from '../../api/symfony/generated/models';
import {TODO} from '../../utils/TODO';

export type Workspace = Reference;
const entries: ApiServiceEntry[] = [
  {
    fn: 'getData',
    route: '/api/portfolio/{types}',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getSubData',
    route: '/api/portfolio/{type}-groups',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getCargoSubData',
    route: '/api/portfolio/cargo-groups',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getCargoGroups',
    route: '/api/portfolio/cargo-groups',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'getVesselSubData',
    route: '/api/portfolio/vessel-groups',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getVesselGroups',
    route: '/api/portfolio/vessel-groups',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'createSub',
    route: '/api/portfolio/groups',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'updateSub',
    route: '/api/portfolio/groups/{id}',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'deleteSub',
    route: '/api/portfolio/groups/{id}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'sortOrder',
    route: '/api/portfolio/{type}-groups/sortOrder',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'assignToGroups',
    route: '/api/portfolio/{type}-to-groups/{id}',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'getFilterData',
    route: '/api/portfolio/filters/{type}',
    method: 'GET',
  },
  {
    fn: 'updateFilterData',
    route: '/api/portfolio/filters/vessel/{id}',
    method: 'PUT',
  },
  {
    fn: 'multiAssignToGroups',
    route: '/api/portfolio/{type}-to-portfolio/multi',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'multiExportVessels',
    route: '/multi/{type}-export',
    method: 'POST',
  },
];

const portfolioApi: ApiService = makeApiService(entries, {apiName: 'portfolio'});

export const reducers = portfolioApi.reducers;
export const actions = portfolioApi.actions;
export const actionTypes = portfolioApi.actionTypes;

export const PortfolioServiceActions = {
  getData: (
    types: string,
    id: string | number | null, // Sub portfolio id - null = 'all'
    pageIndex = 1,
    pageSize = 10,
    sortField: string,
    sortOrder: string,
    filters: TODO
  ) =>
    actions.getData({
      params: {types},
      queryParams: {
        group: id,
        pageIndex,
        pageSize,
        sortField,
        sortOrder,
        filters: filters ? JSON.stringify(filters) : undefined,
      },
    }),
  getSubData: (type: TODO) => actions.getSubData({params: {type}}),
  getVesselSubData: () => actions.getVesselSubData(),
  getCargoSubData: () => actions.getCargoSubData(),
  getVesselGroups: (workspace: Workspace) =>
    actions.getVesselGroups({body: {workspaceId: workspace && workspace.id !== 0 ? workspace.id : null}}),
  getCargoGroups: (workspace: Workspace) =>
    actions.getCargoGroups({body: {workspaceId: workspace && workspace.id !== 0 ? workspace.id : null}}),
  createSub: (type: TODO, name: TODO, sortOrder = 99) => actions.createSub({body: {name, sortOrder, type}}),
  updateSub: (id: TODO, name: TODO, sortOrder: string | null = null) =>
    actions.updateSub({params: {id}, body: {name, sortOrder}}),
  deleteSub: (id: TODO) => actions.deleteSub({params: {id}}),
  sortOrder: (type: string, body: TODO) => actions.sortOrder({params: {type}, body}),
  assignToGroups: (type: 'vessel' | 'cargo', id: number, body: number[]) =>
    actions.assignToGroups({params: {type, id}, body}),

  getFilterData: (type: TODO) => actions.getFilterData({params: {type}}),
  updateFilterData: (id: TODO, filters: TODO) =>
    actions.updateFilterData({
      params: {id},
      queryParams: {filters: JSON.stringify(filters)},
      body: {id},
    }),
  multiAssignToGroups: (type: TODO, body: TODO) => actions.multiAssignToGroups({params: {type}, body}),
  multiExportVessels: (body: TODO, type: TODO) => actions.multiExportVessels({body, params: {type}}),
};

export default PortfolioServiceActions;
