import {Modal, Result} from 'antd';
import {Center} from '../../../components/Center/Center';
import {ResetPasswordForm, ResetPasswordFormValues} from './ResetPasswordForm';
import {useMutation, useQuery} from '@tanstack/react-query';
import {authenticationApi} from '../../../api/symfony/symfonyApi';
import {PasswordResetRequest} from '../../../api/symfony/generated/models/PasswordResetRequest';
import {assert} from '../../../utils/assert';
import {ApiError, isApiErrorWithStatus} from '../../../api/utils/ApiError';
import {RecoveryToken} from '../../../api/symfony/generated';
import {Dialog, DividerStyled, TitleStyled} from '../utils/shared-components';
import {Link, useLocation, useNavigate} from 'react-router-dom';

export const ResetPasswordDialog = () => {
  const navigate = useNavigate();
  const token = useToken();

  // The query that checks if the token is valid
  const queryResult = useQuery<RecoveryToken | null, ApiError>({
    queryKey: ['fetchRecoveryToken', token],
    queryFn: async () => {
      try {
        const response = await authenticationApi.fetchRecoveryToken({token: token!});
        return response;
      } catch (error) {
        // If token is too old, response has status 404
        if (isApiErrorWithStatus(error, 404)) {
          // do nothing, error is displayed on the screen
          return null;
        }
        throw error;
      }
    },
    enabled: !!token,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const onPasswordChangeSuccess = () => {
    Modal.success({
      content: (
        <>
          Password changed successfully.
          <br />
          <br />
          Now login with your new password.
        </>
      ),
      okText: 'TO LOGIN PAGE',
      onOk: () => {
        navigate('/login');
      },
    });
  };

  // The mutation that resets the password
  const passwordResetMutation = useMutation({
    mutationFn: (passwordResetRequest: PasswordResetRequest): Promise<void> =>
      authenticationApi.resetPassword({
        passwordResetRequest,
      }),
    onSuccess: onPasswordChangeSuccess,
  });

  const onSubmit = (formValues: ResetPasswordFormValues) => {
    assert(token, 'Missing token');
    const request: PasswordResetRequest = {
      password: formValues.password,
      token: token,
    };
    passwordResetMutation.mutate(request);
  };

  if (!token) {
    return <MissingTokenError />;
  }

  if (queryResult.isFetching) {
    return <div data-testid="FetchingRecoveryToken" />;
  }

  if (queryResult.isSuccess && queryResult.data === null) {
    return <TokenTooOldError />;
  }

  return (
    <Center>
      <Dialog>
        <Center>
          <TitleStyled level={3}>Reset password</TitleStyled>
        </Center>
        <DividerStyled />
        <ResetPasswordForm
          submitting={passwordResetMutation.isPending}
          submitError={passwordResetMutation.error}
          onSubmit={onSubmit}
        />
      </Dialog>
    </Center>
  );
};

const useToken = (): string | null => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  return token;
};

const MissingTokenError = () => (
  <div data-testid="MissingTokenError">
    <Dialog>
      <Result status="error" title="Missing token" subTitle="Missing query parameter 'token' in URL" />
    </Dialog>
  </div>
);

const TokenTooOldError = () => {
  return (
    <div data-testid="TokenTooOldError">
      <Dialog>
        <Result
          status="warning"
          title="There is a problem with your token"
          subTitle={
            <>
              The password reset link could be too old.
              <br />
              <br />
              Maybe you want to <Link to="/authentication/lost-password">request another password reset</Link>?
            </>
          }
        />
      </Dialog>
    </div>
  );
};
