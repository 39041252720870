import {AISRecord} from './AISRecord';
import sortBy from 'lodash/sortBy';
import {improveDataQuality} from './improveDataQuality';
import {getYearsInRange} from './getYearsInRange';
import dayjs, {Dayjs} from 'dayjs';
import {useMemo} from 'react';
import {useAISRecordsForYearsQuery} from './useAISRecordsForYearsQuery';
import {AISRecordsQueryResult} from './AISRecordsQueryResult';
import {Timeframe} from '../../model/Timeframe';
import maxBy from 'lodash/maxBy';
import {useFreshAisRecordsQuery} from './useFreshAisRecordsQuery';

const filterAISRecordsByTimeframe = (aisRecords: AISRecord[], timeframe: Timeframe): AISRecord[] => {
  const {start, end} = timeframe;
  return aisRecords.filter(aisRecord => {
    const timestamp = aisRecord.timestamp;
    return timestamp.isAfter(start) && timestamp.isBefore(end);
  });
};

const combinedQueryResults = (
  aisRecordsForYearsQuery: AISRecordsQueryResult,
  freshAisRecordsForYearsQuery: AISRecordsQueryResult,
  timeframe: Timeframe
): AISRecordsQueryResult => {
  if (!aisRecordsForYearsQuery.isSuccess || !freshAisRecordsForYearsQuery.isSuccess) {
    // We are still loading data
    return {
      isSuccess: false,
      data: undefined,
    };
  }
  let aisRecords = aisRecordsForYearsQuery.data.concat(freshAisRecordsForYearsQuery.data);
  aisRecords = filterAISRecordsByTimeframe(aisRecords, timeframe);
  aisRecords = sortBy(aisRecords, aisRecord => aisRecord.timestamp.unix());
  aisRecords = improveDataQuality(aisRecords);
  return {isSuccess: true, data: aisRecords};
};

const useCombinedQueryResult = (
  aisRecordsForYearsQuery: AISRecordsQueryResult,
  freshAisRecordsForYearsQuery: AISRecordsQueryResult,
  imo: number,
  timeframe: Timeframe
) => {
  const queryResult = useMemo(() => {
    return combinedQueryResults(aisRecordsForYearsQuery, freshAisRecordsForYearsQuery, timeframe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imo, timeframe, aisRecordsForYearsQuery.isSuccess]);
  return queryResult;
};

const useLatestTimestamp = (aisRecordsForYearsQuery: AISRecordsQueryResult): Dayjs | undefined => {
  const latestTimestamp: Dayjs | undefined = useMemo(() => {
    const aisRecordsForYears = aisRecordsForYearsQuery.isSuccess ? aisRecordsForYearsQuery.data : [];
    const latestAisRecord = maxBy(aisRecordsForYears, aisRecord => aisRecord.timestamp);
    return latestAisRecord?.timestamp;
  }, [aisRecordsForYearsQuery.data, aisRecordsForYearsQuery.isSuccess]);
  return latestTimestamp;
};

/**
 * Loads AIS records for a given vessel in a given timeframe.
 */
export const useAISRecordsQuery = ({imo, timeframe}: {imo: number; timeframe: Timeframe}): AISRecordsQueryResult => {
  const startDay = dayjs(timeframe.start);
  const endDay = dayjs(timeframe.end);
  const years: number[] = getYearsInRange(startDay, endDay);
  const aisRecordsForYearsQuery = useAISRecordsForYearsQuery({imo, years});
  const latestTimestamp: Dayjs | undefined = useLatestTimestamp(aisRecordsForYearsQuery);
  const freshAisRecordsQuery = useFreshAisRecordsQuery({
    imo,
    from: latestTimestamp,
    options: {enabled: aisRecordsForYearsQuery.isSuccess},
  });
  const aisRecordsQueryResult = useCombinedQueryResult(aisRecordsForYearsQuery, freshAisRecordsQuery, imo, timeframe);
  return aisRecordsQueryResult;
};
