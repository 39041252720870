import React, {useState} from 'react';
import BaseModal from '../../../atoms/BaseModal';
import {Button} from '../../../atoms/Button/Button';

import './style.scss';

type Props = {
  type?: string;
  target?: $TSFixMe;
  update: (id: $TSFixMe, body: $TSFixMe, close: $TSFixMe, setError: $TSFixMe) => void;
  reload?: $TSFixMeFunction;
};

export const Archive = ({type, target, update}: Props) => {
  const [disableButtons, setDisabledButtons] = useState(false);
  const [error, setError] = useState(false);
  const isArchived = target.isArchived;

  return (
    <BaseModal middle title={isArchived ? `Restore ${type}` : `Archive ${type}`} narrow>
      {({close}: $TSFixMe) => {
        const onClick = () => {
          setDisabledButtons(true);
          setError(false);
          update(target.id, {isArchived: !isArchived}, close, setError);
        };

        return (
          <div className={'archive-modal'}>
            <div className={'archive-modal__text'}>
              Do you want to {isArchived ? `restore this ${type}` : `archive this ${type}`}?
            </div>
            {error && <div className={'modal__error'}>Something went wrong, please try again</div>}
            <div className={'archive-modal__buttons'}>
              <Button
                disabled={disableButtons && !error}
                label={isArchived ? 'Restore' : 'Archive'}
                bold
                primary
                upper
                id={'description__edit__save'}
                onClick={onClick}
              />
            </div>
          </div>
        );
      }}
    </BaseModal>
  );
};
