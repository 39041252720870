import {FC} from 'react';
import styled, {css} from 'styled-components';
import {MapAreaElement} from '../../../components/SeaboMap/MapDetails/utils/types';
import {getPortlessSummary} from '../../../components/SeaboMap/helper';

export const SelectedTradingAreaBanner: FC<{
  mapElement: MapAreaElement;
  isReduced: boolean;
  onClick: () => void;
}> = ({mapElement, isReduced, onClick}) => {
  const selectedTradingArea = mapElement.object.properties;
  const amountVessels = selectedTradingArea!.market.portlessAmountVessels;
  const amountCargoes = selectedTradingArea!.market.portlessAmountCargoes;

  return (
    <SelectedTradingAreaBannerContainer onClick={onClick} $isReduced={isReduced}>
      {getPortlessSummary(selectedTradingArea!.name, amountVessels, amountCargoes, isReduced)}
    </SelectedTradingAreaBannerContainer>
  );
};

const SelectedTradingAreaBannerContainer = styled.button<{$isReduced: boolean}>`
  position: absolute;
  top: 12px;
  left: calc(50% - 32px);
  transform: translateX(-50%);
  padding: 8px;
  background: var(--color-map-overlay);
  border: none;
  border-radius: var(--border-radius-lg);
  color: var(--color-white-fixed);
  line-height: 24px;
  text-align: center;
  cursor: pointer;

  ${({$isReduced}) =>
    $isReduced &&
    css`
      @media screen and (max-width: 1500px) {
        left: 12px;
        transform: none;
      }
    `}
`;
