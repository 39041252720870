import React, {FC, useState} from 'react';
import {Alert, Button, Space, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import {UploadOutlined, DeleteOutlined} from '@ant-design/icons';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {isLocalDevEnvironment} from '../../../../utils/environment';
import styled from 'styled-components';

interface Props {
  company: Company;
  onLogoFileChange: (file: File) => void;
  hasLogo: boolean;
  onHasLogoChange: (hasLogo: boolean) => void;
}

const MAX_FILE_SIZE_IN_MP = 5;

export const CompanyLogo: FC<Props> = ({company, onHasLogoChange, hasLogo, onLogoFileChange}) => {
  const [previewImageUrl, setPreviewImageUrl] = useState<string | ArrayBuffer | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const {companyLogo} = company;
  let logoToDisplay: string = hasLogo
    ? previewImageUrl?.toString() || companyLogo?.url || '/static/images/seanexxt/company-placeholder.png'
    : '/static/images/seanexxt/company-placeholder.png';

  if (isLocalDevEnvironment() && !logoToDisplay.includes('localhost')) {
    // Patch the server URL in order to enable local development
    // https://beta.seabo.com/download/5bf05438-bd51-411a-a2bc-d65822d1ff39
    // will become
    // http://beta.seabo.com.localhost:3000/download/5bf05438-bd51-411a-a2bc-d65822d1ff39
    logoToDisplay = logoToDisplay.replace('https://', 'http://');
    logoToDisplay = logoToDisplay.replace('.seabo.com', '.seabo.com.localhost:3000');
  }

  const onFileChange = (file: File) => {
    onLogoFileChange(file);
    const reader = new FileReader();
    reader.onload = e => {
      setPreviewImageUrl(e.target?.result ?? null);
    };
    reader.readAsDataURL(file);
    onHasLogoChange(true);
  };

  const beforeUpload = (file: File) => {
    const validationErrors: string[] = [];
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      validationErrors.push('You can only upload JPG, PNG, or GIF files');
    }

    if (file.size / 1024 > MAX_FILE_SIZE_IN_MP * 1000 * 1000) {
      validationErrors.push(`Image must be smaller than ${MAX_FILE_SIZE_IN_MP}MB`);
    }
    setValidationErrors(validationErrors);

    if (validationErrors.length === 0) {
      onFileChange(file);
    }

    // Return false to avoid auto-uploading by Ant Design's Upload component
    return false;
  };

  return (
    <div data-cy="CompanyLogo">
      <ImageStyled src={logoToDisplay.toString()} alt="Uploaded" />
      <br />
      <br />
      <Space>
        <ImgCrop rotationSlider={false}>
          <Upload showUploadList={false} beforeUpload={beforeUpload}>
            <Button type="primary" data-cy="UploadCompanyLogo">
              <UploadOutlined />
              Upload
            </Button>
          </Upload>
        </ImgCrop>

        <Button
          data-testid="RemoveCompanyLogo"
          onClick={() => {
            setPreviewImageUrl(null);
            onHasLogoChange(false);
            setValidationErrors([]);
          }}>
          <DeleteOutlined /> Remove
        </Button>
      </Space>

      {validationErrors.length > 0 && (
        <Alert data-cy="Error" message={validationErrors[0]} type="error" style={{marginTop: 16}} />
      )}

      <div style={{marginTop: 16}}>
        The image file should be JPG, JPEG or PNG, maximum size of {MAX_FILE_SIZE_IN_MP}MB <br />
        and minimum dimensions of 128x128 pixels.
      </div>
    </div>
  );
};

const ImageStyled = styled.img`
  width: 128px;
  height: 128px;
`;
