import {FC, useState} from 'react';
import {Form, Spin} from 'antd';
import {CompanySignupRequest} from '../../../api/symfony/generated';
import {Layout} from '../utils/shared-components';
import styled from 'styled-components';
import {ValidationError} from '../../../api/utils/ValidationError';
import {SignupFormStep0} from './SignupFormStep0';
import {SignupFormStep1} from './SignupFormStep1';
import {SignupFormStep2} from './SignupFormStep2';

interface ValidateErrorEntity {
  values: unknown;
  errorFields: {
    name: string[];
    errors: string[];
  }[];
  outOfDate: boolean;
}

const isValidateErrorEntity = (v: unknown): v is ValidateErrorEntity =>
  !!v && typeof v === 'object' && 'errorFields' in v;

export const SignupForm: FC<{
  source?: string;
  submitError?: Error | null;
  isSubmitting: boolean;
  onSubmit: (formValues: CompanySignupRequest) => Promise<void>;
}> = ({source, submitError, isSubmitting, onSubmit}) => {
  const [formValuesAtSubmit, setFormValuesAtSubmit] = useState<CompanySignupRequest | null>(null);
  const [activeStep, setActiveStep] = useState(0);

  const [form] = Form.useForm<CompanySignupRequest>();

  const onContinue = async () => {
    try {
      await form.validateFields();
      setActiveStep(1);
    } catch (error) {
      if (isValidateErrorEntity(error)) {
        const {errorFields} = error;
        const touchedFields = ['email', 'newPassword', 'passwordRepeat'];

        if (errorFields.some(field => touchedFields.includes(field.name[0]))) {
          return;
        }
        form.resetFields([['name'], ['firstName'], ['lastName']]);
        setActiveStep(1);
        return;
      }

      throw error;
    }
  };

  const onFinish = async (values: CompanySignupRequest) => {
    setFormValuesAtSubmit(values);
    try {
      await onSubmit({...values, source});
      setActiveStep(2);
    } catch (error) {
      if (error instanceof ValidationError) {
        const fieldWithError = error.validationProblem.violations!.map(validation => validation.propertyPath)[0];
        const fieldsInStep0 = ['email', 'password', 'passwordRepeat'];
        if (fieldsInStep0.includes(fieldWithError)) {
          setActiveStep(0);
        } else {
          setActiveStep(1);
        }

        return;
      }

      throw error;
    }
  };

  return (
    <Layout>
      <Form<CompanySignupRequest> form={form} name="signup" data-testid="SignupForm" onFinish={onFinish}>
        <ProgressBar step={activeStep} />
        <Spin spinning={isSubmitting}>
          <SignupFormStep0
            visible={activeStep === 0}
            form={form}
            formValuesAtSubmit={formValuesAtSubmit}
            onContinue={onContinue}
            submitError={submitError}
          />
          <SignupFormStep1
            isSubmitting={isSubmitting}
            visible={activeStep === 1}
            form={form}
            formValuesAtSubmit={formValuesAtSubmit}
            submitError={submitError}
          />
        </Spin>
      </Form>
      <SignupFormStep2 visible={activeStep === 2} />
    </Layout>
  );
};

const ProgressBar = styled.div<{step: number}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1;

  &::after {
    content: '';
    display: block;
    width: ${({step}: {step: number}) => step * 50 + 50 + '%'};
    height: 1px;
    background: var(--color-azure);
    transition: width 0.3s;
  }
`;
