import styled, {css} from 'styled-components';
import Icon from '../../../../../../atoms/Icon';
import {TermDiffContainer} from './TermDiff';
import {TermContentContainer} from './TermContent/TermContent';

export const StatefulIcon = styled(Icon)<{$disabled?: boolean}>`
  ${({$disabled}) => ($disabled ? 'opacity: .5; pointer-events: none; cursor: default;' : 'cursor: pointer;')}
`;

export const EditorContainer = styled(TermContentContainer)<{$disabled?: boolean}>`
  padding: 0;
  transition: none !important;

  :focus-within {
    border: var(--border-width-active) solid var(--color-self) !important;
  }

  ${({$disabled}) =>
    $disabled
      ? css`
          pointer-events: none;
          background: var(--color-gray-6);
        `
      : ''}
`;

export const EditorContent = styled.div`
  position: relative;
  width: calc(100% + 8px);
  margin: -2px -4px;
  padding: 2px 4px;
  transition: none !important;

  ${TermDiffContainer} {
    width: 100%;
    margin: 0 0 1px;
    border-radius: 0;
    border-bottom: var(--border-width-active) solid var(--color-self);
  }
`;

export const EditorEditorContainer = styled.div`
  position: relative;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  width: 81px;
  height: 100%;
  padding-left: 10px;
  margin-left: 8px;
  margin-right: -2px;
  border-left: var(--border-base);

  > * {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    text-align: center;
  }
`;

export const MetaContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  min-width: var(--meta-width);
  margin: -5px 5px;
  text-align: right;
  font-size: var(--font-size-sm);
  line-height: 1;
  opacity: 0.66;
  transition: color 0.1s;
  user-select: none;
`;

export const NoWrap = styled.div`
  white-space: nowrap;
`;

export const Faded = styled.span`
  opacity: 0.66;
`;
