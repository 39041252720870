import {AISRecord} from '../queries/aisRecordsQuery/AISRecord';
import {interpolateGeoCoordinates} from '../utils/interpolateGeoCoordinates';
import {interpolateTimestamp} from '../utils/interpolateTimestamp';

export const interpolateAisRecord = (from: AISRecord, to: AISRecord, percent: number): AISRecord => {
  const splitTimestamp = interpolateTimestamp(from.timestamp, to.timestamp, percent);
  const interpolatedCoordinates = interpolateGeoCoordinates(from.coordinates, to.coordinates, percent);
  const interpolatedAisRecord = {
    ...from,
    timestamp: splitTimestamp,
    coordinates: interpolatedCoordinates,
    csvRow: -1,
  };
  return interpolatedAisRecord;
};
