/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {ContainerPort, ContainerPortFromJSON, ContainerPortFromJSONTyped, ContainerPortToJSON} from './';

/**
 *
 * @export
 * @interface MostVisitedContainerPortsReport
 */
export interface MostVisitedContainerPortsReport {
  /**
   * List of the most visited container ports
   * @type {Array<ContainerPort>}
   * @memberof MostVisitedContainerPortsReport
   */
  containerPorts: Array<ContainerPort>;
  /**
   * The report does not include non-container vessels. This is the number of non-container vessels that matched the given filter.
   * @type {number}
   * @memberof MostVisitedContainerPortsReport
   */
  nonContainerVessels: number;
  /**
   * Number of container vessels matching the filter. These have influenced the report.
   * @type {number}
   * @memberof MostVisitedContainerPortsReport
   */
  containerVessels: number;
}

export function MostVisitedContainerPortsReportFromJSON(json: any): MostVisitedContainerPortsReport {
  return MostVisitedContainerPortsReportFromJSONTyped(json, false);
}

export function MostVisitedContainerPortsReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MostVisitedContainerPortsReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    containerPorts: (json['containerPorts'] as Array<any>).map(ContainerPortFromJSON),
    nonContainerVessels: json['nonContainerVessels'],
    containerVessels: json['containerVessels'],
  };
}

export function MostVisitedContainerPortsReportToJSON(value?: MostVisitedContainerPortsReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    containerPorts: (value.containerPorts as Array<any>).map(ContainerPortToJSON),
    nonContainerVessels: value.nonContainerVessels,
    containerVessels: value.containerVessels,
  };
}
