import {useContext} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {assert} from '../../../../utils/assert';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import {VoyagePoint} from '../VoyageInformation/VoyageTypes';
import {ResultVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {DataSourceVessel, generateDataSourceVessel} from './generateDataSourceVessel';
import {vesselDBVesselsApi} from '../../../../api/node-backend/nodeBackendApis';
import {SeaboVessel} from '../../../../api/node-backend/generated';

const isNextOpenPortAlsoLoadingPort = (voyagePoints: VoyagePoint[]) => {
  const firstPoint = voyagePoints[0];
  if (!firstPoint) {
    return false;
  }
  return firstPoint.types.includes('loading') && firstPoint.types.includes('nextOpen');
};

const defaultSpeed = 12;

export const useGetCompetitorsDataSource = () => {
  const inputState = useContext(VoyageCharterContext).state.inputState;
  const outputState = useContext(VoyageCharterContext).state.outputState;
  const yourDuration = useContext(VoyageCharterContext).state.outputState.cargo.duration;
  const yourRevenueTotal = useContext(VoyageCharterContext).state.outputState.cargo.revenueTotal;

  const getCompetitorsDataSource = async ({
    resultVessels,
  }: {
    resultVessels: ResultVessel[];
  }): Promise<DataSourceVessel[]> => {
    const ifFirstPointNextOpenPortAndLoadingPort = isNextOpenPortAlsoLoadingPort(inputState.voyage.points);

    const ballastRoute = ifFirstPointNextOpenPortAndLoadingPort ? undefined : inputState.voyage.routes[0];
    const ballastRouteOutput = ifFirstPointNextOpenPortAndLoadingPort ? undefined : outputState.voyage.routes[0];

    const yourLoadingPointInput = ifFirstPointNextOpenPortAndLoadingPort
      ? inputState.voyage.points[0]
      : inputState.voyage.points[1];
    const yourLoadingPointOutput = ifFirstPointNextOpenPortAndLoadingPort
      ? outputState.voyage.points[0]
      : outputState.voyage.points[1];

    assert(yourLoadingPointInput.types.includes('loading'));

    const yourEtaAtLoadingPoint = dayjs(yourLoadingPointOutput.startDate);

    const yourBallastDistance = ballastRoute?.distance ?? 0;

    const yourSpeed = ballastRoute?.consumption?.speed ?? defaultSpeed;
    const vesselImos = resultVessels.filter(vessel => !!vessel.imo).map(vessel => vessel.imo!);

    const vessels = await vesselDBVesselsApi.listVessels({
      listVesselsRequest: {
        filters: {
          imo: vesselImos,
        },
      },
    });
    const imoToSeaboVesselMap = new Map<number, SeaboVessel>();
    vessels.items.forEach(vessel => {
      imoToSeaboVesselMap.set(vessel.imo, vessel);
    });

    const dataSourceUnsortedPromised = resultVessels.map(vessel =>
      generateDataSourceVessel({
        vessel,
        inputState,
        yourBallastDistance,
        yourEtaAtLoadingPoint,
        yourConsumptionCostOnBallastVoyage: ballastRouteOutput?.consumptionCosts ?? 0,
        seaboVessel: imoToSeaboVesselMap.get(vessel.imo!)!,
        yourSpeed,
        yourRevenueTotal,
        ballastRoute,
        yourDuration,
      })
    );

    const dataSourceUnsorted = await Promise.all(dataSourceUnsortedPromised);

    return sortBy(dataSourceUnsorted, vessel => -vessel.tce);
  };

  return getCompetitorsDataSource;
};
