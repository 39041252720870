import {ReactNode} from 'react';
import {GroupBase, Props} from 'react-select';
import Async from 'react-select/async';
import {SelectComponents} from 'react-select/dist/declarations/src/components';
import {ClearIndicator, SearchIndicator} from './Indicators';
import {
  clearIndicator,
  control,
  indicatorsContainer,
  menu,
  menuList,
  multiValue,
  multiValueLabel,
  multiValueRemove,
  option,
  singleValue,
  valueContainer,
} from './styles';

// @ts-expect-error Type 'Element' is not assignable to type 'string'.
interface AsyncMultiSelectProps<OptionType> extends Props<OptionType, true, GroupBase<OptionType>> {
  isDisabled?: boolean;
  width?: number | string;
  components?: Partial<SelectComponents<OptionType, true, GroupBase<OptionType>>>;
  hasError?: boolean;
  clearable?: boolean;
  getOptionLabel?: (option: OptionType) => ReactNode;
  isOptionsDisabled?: (option: OptionType) => boolean;
  height?: number | string;
  id: string;
  name?: string;
  allowCreateWhileLoading?: boolean;
  isMulti?: boolean;
  createLabel?: (inputValue: string) => ReactNode;
  noOptionsMessage?: () => ReactNode;
  maxLength?: number;
  formatCreateLabel?: (inputValue: string) => string;
  loadOptions: (value: string) => void | Promise<OptionType[]>;
  cacheOptions?: boolean;
  defaultOptions?: boolean | OptionType[];
}

export const AsyncMultiSelect = <OptionType,>({
  id,
  name,
  width: _,
  isMulti: __,
  components,
  hasError,
  getOptionLabel,
  ...props
}: AsyncMultiSelectProps<OptionType>) => (
  <Async
    id={id}
    name={`select-${name}`}
    classNamePrefix={'seabo-react-select'}
    isClearable={true}
    // !! react18 - package types only allow string as return. But element is needed in CargoTabPane and works - /projects -> create project
    // @ts-expect-error Type '((option: OptionType) => string | Element) | undefined' is not assignable to type 'GetOptionLabel<OptionType> | undefined'.
    getOptionLabel={getOptionLabel}
    isSearchable={true}
    styles={{
      option,
      control: (base, state) => ({
        ...control('100%', hasError)(base, state),
        padding: '4px 6px',
      }),
      menu,
      menuList,
      singleValue,
      valueContainer,
      indicatorsContainer,
      multiValue,
      multiValueLabel,
      multiValueRemove,
      clearIndicator,
    }}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator: SearchIndicator,
      MultiValueRemove: ClearIndicator,
      ClearIndicator: () => {
        return (
          <ClearIndicator
            innerProps={{
              onClick: event => {
                props?.onChange?.([], {action: 'clear', removedValues: []});
                event.stopPropagation();
              },
            }}
          />
        );
      },
      ...components,
    }}
    noOptionsMessage={() => 'Type to search...'}
    {...props}
    isMulti={true}
  />
);
