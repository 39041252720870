import dayjs, {Dayjs} from 'dayjs';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {dateEarlierThanOrEqualTo} from '../../../../utils/validators';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

export type DatabaseType = {
  date?: {
    from?: string;
    to?: string;
  };
};

export type InternalType = {
  from?: Dayjs | null;
  to?: Dayjs | null;
};

export const Defaults: InternalType = {
  from: null,
  to: null,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {date} = database;
  const {from, to} = date || {};
  return {
    from: from ? dayjs(from) : null,
    to: to ? dayjs(to) : null,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {from, to} = internal;
  return {
    date: {
      from: from ? from.format(DateTimeFormat.IsoDate) : undefined,
      to: to ? to.format(DateTimeFormat.IsoDate) : undefined,
    },
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Laycan',
  branch: 'laycan',
  defaults: Defaults,
  validators: {
    from: dateEarlierThanOrEqualTo('to'),
  },
  fromDatabase,
  toDatabase,
});
