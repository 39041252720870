import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import Input from '../../../../../atoms/Input';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {hasError} from '../../../../../utils/helper';
import {StyledRangePicker} from '../../../../RangePicker/StyledRangePicker';
import {DatePickerPairLayout} from '../../utils/DatePickerPairLayout';
import {filterBranchDefinition} from './FixtureDateDefinition';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const FixtureDate = ({filterProviderApi}: Props) => {
  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
      {({handleChange, handleRangePickerChange, values, validations}) => {
        const fromValue = values.from;
        const toValue = values.to;
        return (
          <div className="container">
            <div className="row">
              <div className="scol-12">
                <LabelWrapper label="Date" id={'filter-fixture-date'}>
                  <DatePickerPairLayout>
                    <StyledRangePicker
                      data-cy={'DatePickerFromDate DatePickerToDate'}
                      format={DateTimeFormat.Date}
                      value={[fromValue, toValue]}
                      placeholder={['from date', 'to date']}
                      onChange={value => {
                        const [from, to] = [...(value ?? [null, null])];
                        handleRangePickerChange({from, to});
                      }}
                    />
                  </DatePickerPairLayout>
                </LabelWrapper>
              </div>
            </div>

            <div className="row bottom-fix">
              <div className="scol-12">
                <LabelWrapper
                  label={'Source'}
                  htmlFor={'filter-general-source'}
                  hasError={hasError('source', validations)}>
                  <Input
                    data-cy={'SourceInputField'}
                    id={'filter-general-source'}
                    onChange={value => handleChange('source', value)}
                    value={values.source}
                    hasError={hasError('source', validations)}
                    maxLength={100}
                  />
                </LabelWrapper>
              </div>
            </div>
          </div>
        );
      }}
    </FilterButton>
  );
};

export default FixtureDate;
