import {roundNumberToDecimals} from '../../../../../utils/roundNumber';
import {MulticalcVesselInput, MulticalcCargoInput} from '../../types';
import {MultiCalculation} from '../../useMultiCalculation';
import {OneCalcResult} from '../../utils/oneCalc';

export const getFilteredMatrix = (
  vessels: MulticalcVesselInput[],
  cargoes: MulticalcCargoInput[],
  multiCalculation: MultiCalculation
) => {
  const filteredCalcVessels: OneCalcResult[] = [];
  const filteredCalcCargoes: OneCalcResult[] = [];
  const calcVesselMatrixIndices: number[] = [];
  const calcCargoMatrixIndices: number[] = [];

  const freightIdeas: number[][] = [];
  let freightIdeaMin = Infinity;
  let freightIdeaMax = -Infinity;

  const durations: number[][] = [];
  let durationMin = Infinity;
  let durationMax = -Infinity;

  for (let i = 0; i < vessels.length; i++) {
    freightIdeas.push([]);
    durations.push([]);
    for (let j = 0; j < cargoes.length; j++) {
      const calcResult = multiCalculation.matrix.getOneCalcResult(multiCalculation.oneCalcResults ?? [], i, j);
      if (!calcResult) {
        continue;
      }
      const freightIdea = calcResult.voyageCalculationInput.cargo.freightIdea ?? 0;
      freightIdeas[i].push(freightIdea);
      if (freightIdea) {
        freightIdeaMin = Math.min(freightIdeaMin, freightIdea);
        freightIdeaMax = Math.max(freightIdeaMax, freightIdea);
      }

      const duration = calcResult.voyageCalculationOutput.cargo.duration ?? 0;
      durations[i].push(duration);
      // freightIdea is used as indicator if voyage calc produced a realistic result
      if (freightIdea) {
        durationMin = Math.min(durationMin, duration);
        durationMax = Math.max(durationMax, duration);
      }

      if (!calcResult.voyageCalculationOutput.cargo.revenueNet) {
        continue;
      }

      if (!filteredCalcVessels.some(c => c?.vessel.id === calcResult.vessel.id)) {
        filteredCalcVessels.push(calcResult);
        calcVesselMatrixIndices.push(i);
      }
      if (!filteredCalcCargoes.some(c => c?.cargo.id === calcResult.cargo.id)) {
        filteredCalcCargoes.push(calcResult);
        calcCargoMatrixIndices.push(j);
      }
    }
  }

  const freightIdeaScores: number[][] = [];
  const durationScores: number[][] = [];
  for (let i = 0; i < vessels.length; i++) {
    freightIdeaScores.push([]);
    durationScores.push([]);
    for (let j = 0; j < cargoes.length; j++) {
      freightIdeaScores[i].push(
        freightIdeas[i][j] ? roundNumberToDecimals(1 - (freightIdeas[i][j] - freightIdeaMin) / freightIdeaMax, 1) : 0
      );
      // freightIdea is used as indicator if voyage calc produced a realistic result
      durationScores[i].push(
        freightIdeas[i][j] ? roundNumberToDecimals(1 - (durations[i][j] - durationMin) / durationMax, 1) : 0
      );
    }
  }

  return {
    filteredCalcVessels,
    filteredCalcCargoes,
    calcVesselMatrixIndices,
    calcCargoMatrixIndices,
    freightIdeaScores,
    durationScores,
  };
};
