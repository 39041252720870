import dayjs from 'dayjs';
import produce from 'immer';
import debounce from 'lodash/debounce';
import {useMemo, useRef, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {CharterCalculation} from '../../../../api/symfony/generated/models';
import {charterCalculationApi} from '../../../../api/symfony/symfonyApi';
import {CharterCalculationActions} from '../../../../redux/CharterCalculation';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {blankState, defaultRemainingOnBoards} from '../Context/blankState';
import {MetaState} from '../Context/VoyageCharterContext';
import {VesselInput} from '../VesselInformation/VesselTypes';
import {addNextOpenLegToVoyage} from './addNextOpenLegToVoyage/addNextOpenLegToVoyage';
import {removeNextOpenLegFromVoyage} from './removeNextOpenLegFromVoyage';
import {
  generateTermsArray,
  updatesWaitingtimesOnInputstate,
} from './updatesWaitingtimesOnInputstate/updatesWaitingtimesOnInputstate';
import {InputState, voyageChartercalculation} from './voyageChartercalculation/voyageChartercalculation';
import {DefaultStowageFactorDisplayUnit} from './CargoTypes';

export type UpdateVesselInput = (partialVesselInput: Partial<VesselInput>) => VesselInput;
export type SetVesselInput = (vesselInput: VesselInput | undefined) => VesselInput;

export const useCharterCalculationState = () => {
  const inputState = useSelector(state => state.charterCalculation.inputState);
  const metaState = useSelector(state => state.charterCalculation.metaState);

  const queryClient = useQueryClient();
  const [oldKeyList, setOldKeyList] = useState<string[]>([]);
  const setInputStateThrottled = useRef(
    debounce(async ({newInputState, oldOutputState}) => {
      const newKeyList = generateTermsArray({inputState: newInputState, outputState: oldOutputState});
      /*
      When the input state is changed, the waiting times at the voyage legs are changed accordingly.
      The new state is then set in Redux.
       */
      const updatedInputState = await updatesWaitingtimesOnInputstate({
        inputState: newInputState,
        newKeyList,
        oldKeyList: oldKeyList,
        queryClient,
      });

      return dispatch(CharterCalculationActions.setInputState(updatedInputState));
    })
  );

  const dispatch = useDispatch();

  const outputState = useMemo(() => {
    const outputState = voyageChartercalculation(inputState);
    setOldKeyList(generateTermsArray({inputState, outputState}));
    return outputState;
  }, [inputState]);

  const setInputState = (newInputState: InputState) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setInputStateThrottled.current({oldOutputState: outputState, newInputState});
  };

  const updateVesselInput = (partialVesselInput: Partial<VesselInput>): VesselInput => {
    const newVesselInput = {
      ...inputState.vessel,
      ...partialVesselInput,
    };
    setInputState(
      produce(inputState, draftInputState => {
        draftInputState.vessel = newVesselInput;
      })
    );
    return newVesselInput;
  };
  const setVesselInput = (vesselInput: VesselInput | undefined): VesselInput => {
    setInputState(
      produce(inputState, draftInputState => {
        if (vesselInput) {
          draftInputState.vessel = vesselInput;
          draftInputState.voyage = addNextOpenLegToVoyage(draftInputState);
          return draftInputState;
        } else {
          draftInputState.vessel = blankState.inputState.vessel;
          draftInputState.voyage.points = removeNextOpenLegFromVoyage({points: draftInputState.voyage.points});
          return draftInputState;
        }
      })
    );

    return vesselInput ? vesselInput : blankState.inputState.vessel;
  };

  const reset = () => dispatch(CharterCalculationActions.resetCharterCalculationState());
  const setMeta = (metaState: MetaState) => dispatch(CharterCalculationActions.setMetaState(metaState));

  const navigate = useNavigate();

  const loadFromCloud = async (id: number): Promise<CharterCalculation | void> => {
    reset();
    const calculation = await charterCalculationApi.getCharterCalculation({
      id,
    });

    if (calculation.input === undefined) {
      throw new Error('no input in the loaded calculation');
    }
    navigate({
      search: '?id=' + id,
    });

    const newInputState = calculation.input as InputState;

    if (newInputState.calculator.distanceCalculator === undefined) {
      newInputState.calculator.distanceCalculator = blankState.inputState.calculator.distanceCalculator;
    }

    if (newInputState.vessel.remainingOnBoards === undefined) {
      newInputState.vessel.remainingOnBoards = defaultRemainingOnBoards;
    }

    if (newInputState.cargo.freightUnit === undefined) {
      newInputState.cargo.freightUnit = 'perMts';
    }

    if (newInputState.cargo.stowageFactorDisplayUnit === undefined) {
      newInputState.cargo.stowageFactorDisplayUnit = DefaultStowageFactorDisplayUnit;
    }

    newInputState.voyage.laycanTo =
      newInputState.voyage.laycanTo === undefined ? undefined : dayjs(newInputState.voyage.laycanTo);
    newInputState.voyage.laycanFrom =
      newInputState.voyage.laycanFrom === undefined ? undefined : dayjs(newInputState.voyage.laycanFrom);
    setInputState(newInputState);
    setMeta({
      ...metaState,
      calculation: {
        id: calculation.id as number,
      },
      savedLastChanges: true,
    });
    return calculation;
  };

  return {
    setVesselInput,
    inputState,
    outputState,
    setInputState,
    metaState,
    updateVesselInput,
    setMeta,
    loadFromCloud,
    reset,
  };
};
