import dayjs, {Dayjs} from 'dayjs';
import {FilterParams} from '../../filterParams';
import {vesselEtaIsAfterLaycanFilter} from '../../filters/vesselEtaIsAfterLaycanFilter';
import {Step5Result} from '../step5';
import {StepResult} from '../StepResult';
import {transformToStep7Vessels} from './transformToStep7Vessels';
import {Step7Vessel} from './vesselWithEstimatedDestination/vesselWithEstimatedDestination';

export type Step7Result = StepResult<Step7Vessel>;

/**
 * filter vessels-  only vessels that can make it to the laycan (upper bound)
 *
 * assuming they have a destination port, will be loaded or unloaded here,
 * but only one of the two with an assumed speed of 15000 pwwd.
 * Then, assuming the given speed (12knots), determine how long the vessels would need for the route
 * and add the Port Duration.

 */
export const step7 = async ({
  params,
  preResult,
  currentTime,
}: {
  preResult: Step5Result;
  params: FilterParams;
  currentTime?: Dayjs;
}): Promise<Step7Result> => {
  let vesselList = await transformToStep7Vessels({vessels: preResult.vessels, currentTime: dayjs(currentTime)});

  if (params.toDate) {
    vesselList = vesselEtaIsAfterLaycanFilter({vessels: vesselList, filter: params});
  }

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: 'ETA is before laycan',
        count: vesselList.length,
        id: 'etaBeforeLaycan',
      },
    ],
  };
};
