import {FC, useState} from 'react';
import {MapOptionChangeValue, MapSwitches, MapSwitchesKey} from '../MapDetails/utils/types';
import {SingleOption} from './SingleOption';
import {MapOption, MapOptionSection} from '../mapOptions/mapOptions';
import './style.scss';

type MapMenuProps = {
  option?: MapOption;
  switches: MapSwitches;
  onChange: (switchId: MapSwitchesKey, value: MapOptionChangeValue) => void;
  onFilterChange: (filter: Record<string, string | string[]>) => void;
};

export const MapMenu: FC<MapMenuProps> = ({option, onChange, onFilterChange}) => {
  const [openSubMenus, setOpenSubMenus] = useState<MapSwitchesKey[]>([]);

  return (
    <div>
      {option?.sections?.map((section: MapOptionSection, idx: number) => (
        <div className={'map-menu__section'} key={idx}>
          {section.map(option => {
            return (
              <SingleOption
                key={option.id}
                onChange={onChange}
                onFilterChange={onFilterChange}
                option={option}
                isSubMenuOpen={openSubMenus.includes(option.id)}
                onChangeSubMenuIsOpen={(newValue: boolean) => {
                  if (newValue) {
                    setOpenSubMenus([...openSubMenus, option.id]);
                  } else {
                    setOpenSubMenus(openSubMenus.filter(v => v !== option.id));
                  }
                }}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};
