import {TODO} from '../../../../utils/TODO';
import {useContext, useMemo} from 'react';
import {MapContext} from '../../MapContext/MapContext';
import {MapElement, MapSwitches} from '../../MapDetails/utils/types';
import {filterAisVessels} from '../../utils/filterAisVessels';
import {LAYERS} from '../../const';
import {useDebouncedValue} from '../../../../utils/useDebouncedValue';
import {addSizeAndIconToVessels} from './addSizeAndIconToVessels';
import {useAisVesselsQuery} from '../../../../queries/useAisVesselsQuery';
import {getAisVesselLayer} from './getAisVesselLayer';
import {AisVesselWithIconAndSize} from './AisVesselWithIconAndSize';

// constant multiplier that comes into effect when "constantIconSize" is set
const ZOOM_FOR_CONSTANT_ICON_SIZE = 4;

export const useVesselLayer = ({
  switches,
  searchElement,
  onSelectElement,
}: {
  switches: MapSwitches;
  searchElement: null | TODO;
  onSelectElement: (item: MapElement) => void;
}) => {
  const mapContext = useContext(MapContext);
  const aisVesselsQuery = useAisVesselsQuery();

  let iconZoom = mapContext.state.viewState.zoom;

  // zoom level is kept constant if "constantIconSize" is set
  if (mapContext.props.vesselsToVisualize?.constantIconSize) {
    iconZoom = ZOOM_FOR_CONSTANT_ICON_SIZE;
  }

  const vesselsFromProps = mapContext.props.vesselsToVisualize?.data;

  const debouncedSwitches = useDebouncedValue({value: switches, debounceWait: 1000});

  const vesselsToVisualize: AisVesselWithIconAndSize[] = useMemo<AisVesselWithIconAndSize[]>(() => {
    if (vesselsFromProps) {
      return addSizeAndIconToVessels({
        vessels: vesselsFromProps,
        vesselIconSizeMultiplicator: mapContext.state.settings.vesselIconSizeMultiplicator,
        vesselIconStyle: mapContext.state.settings.vesselIconStyle,
        vesselIconSizeCapacityRelation: mapContext.state.settings.vesselIconSizeCapacityRelation,
        constantIconSize: mapContext.props.vesselsToVisualize?.constantIconSize,
      });
    }
    const filteredVessels = filterAisVessels({
      switches: debouncedSwitches,
      aisVessels: aisVesselsQuery.data ?? [],
      searchElement,
    });

    return addSizeAndIconToVessels({
      vessels: filteredVessels,
      vesselIconSizeMultiplicator: mapContext.state.settings.vesselIconSizeMultiplicator,
      vesselIconStyle: mapContext.state.settings.vesselIconStyle,
      vesselIconSizeCapacityRelation: mapContext.state.settings.vesselIconSizeCapacityRelation,
    });
  }, [
    vesselsFromProps,
    debouncedSwitches,
    searchElement,
    aisVesselsQuery.data,
    mapContext.state.settings,
    mapContext.props.vesselsToVisualize?.constantIconSize,
  ]);

  return getAisVesselLayer({
    id: LAYERS.AIS,
    data: vesselsToVisualize,
    updateTriggers: {
      getSize: [iconZoom],
    },
    onClick: vessel => {
      mapContext.props.vesselsToVisualize?.onClick?.(vessel);
      return onSelectElement(vessel as MapElement);
    },
    getSize: (item: AisVesselWithIconAndSize) => item.size * iconZoom,
  });
};
