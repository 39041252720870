import {VesselTypeEnum} from '../../../../api/node-backend/generated';
import {FC, useEffect, useState} from 'react';
import {Form, FormInstance, Input, Select, Typography} from 'antd';
import {Location, SeaboVessel as SearchPickerVessel} from '../../../../components/SearchPicker/Type';
import {SeaboVesselPickerWithFlag} from '../../../../components/SeaboVesselPicker/SeaboVesselPickerWithFlag';
import {VesselType} from '../../../../api/symfony/generated';
import {getFormValuesFromApiVessel, getFormValuesFromApiLocation} from './utils';
import {
  FormSection,
  FormSectionHeader,
  FormSectionBody,
  FormSectionItem,
  StyledDatePicker,
  ExpandButton,
  InputGroup,
  ExpandSection,
} from './shared';
import {CreateNegotiationFormValues, CreateNegotiationFormVessel} from '../utils/types';
import {LocationPickerOrControlledInput} from './LocationPickerOrControlledInput';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import {InputNumber} from '../../../../antd/components/InputNumber';

const {Title} = Typography;

type NegotiationFormVesselSectionProps = {
  form: FormInstance<CreateNegotiationFormValues>;
  onFormChange: (values: Partial<CreateNegotiationFormValues> | null) => void;
};

export const NegotiationFormVesselSection: FC<NegotiationFormVesselSectionProps> = ({form, onFormChange}) => {
  const vesselFormValues = Form.useWatch('vessel', form);
  const updateVesselFormValues = (vessel: CreateNegotiationFormVessel) => {
    form.setFieldsValue({vessel});
    onFormChange({vessel});
  };

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (vesselFormValues?.id) {
      setIsExpanded(true);
    }
  }, [vesselFormValues?.id]);

  const onSelectVessel = (vessel?: SearchPickerVessel) => {
    setIsExpanded(true);
    if (!vessel) {
      return;
    }
    // SearchPicker returns incompatible types, so we need to transform it
    const formVessel = getFormValuesFromApiVessel(vessel);
    updateVesselFormValues(formVessel);
  };

  const onSelectLocation = (location: Location | null) => {
    const {label, countryCode} = getFormValuesFromApiLocation(location);
    updateVesselFormValues({
      locationOpenLabel: label,
      locationOpenCountryCode: countryCode,
    });
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Title level={4}>Vessel</Title>
      </FormSectionHeader>
      <FormSectionBody>
        {isExpanded ? (
          <ExpandButton size="small" onClick={() => setIsExpanded(false)}>
            Search vessel
          </ExpandButton>
        ) : (
          <FormSectionItem label="Search for My Fleet vessel">
            <SeaboVesselPickerWithFlag
              size="middle"
              defaultValue={vesselFormValues?.name?.toString()}
              indexNames={['vessels_portfolio']}
              vesselTypes={[VesselType.Bulker, VesselType.Container, VesselType.Mpp]}
              onChange={vessel => onSelectVessel(vessel)}
            />
            <ExpandButton size="small" onClick={() => setIsExpanded(true)}>
              ...or enter vessel details manually
            </ExpandButton>
          </FormSectionItem>
        )}
        <ExpandSection $hidden={!isExpanded}>
          <HorizontalLine topSpace="large" bottomSpace="extraLarge" />
          <FormSectionItem name={['vessel', 'id']} hidden>
            <Input hidden />
          </FormSectionItem>
          <FormSectionItem $span={12} name={['vessel', 'name']} label="Vessel name">
            <Input placeholder="Vessel name" />
          </FormSectionItem>
          <FormSectionItem $span={12} name={['vessel', 'imo']} label="Vessel IMO">
            <InputNumber placeholder="Vessel IMO" style={{width: '100%'}} />
          </FormSectionItem>
          <FormSectionItem $span={12} name={['vessel', 'type']} label="Vessel type">
            <Select
              placeholder="Vessel type"
              options={[
                {label: 'Bulker', value: VesselTypeEnum.Bulker},
                {label: 'Container', value: VesselTypeEnum.Container},
                {label: 'MPP', value: VesselTypeEnum.Mpp},
              ]}
            />
          </FormSectionItem>
          <FormSectionItem $span={12} label="Vessel size">
            <InputGroup>
              <Form.Item name={['vessel', 'size']} noStyle>
                <InputNumber placeholder="Vessel size" style={{width: '50%'}} />
              </Form.Item>
              <Form.Item name={['vessel', 'sizeUnit']} noStyle>
                <Select
                  options={[
                    {label: 'DWT', value: 'DWT'},
                    {label: 'TEU', value: 'TEU'},
                  ]}
                  style={{width: '50%'}}
                />
              </Form.Item>
            </InputGroup>
          </FormSectionItem>
          <FormSectionItem $span={12} name={['vessel', 'dateOpen']} label="Date open">
            <StyledDatePicker placeholder="Date open" allowClear />
          </FormSectionItem>
          <FormSectionItem $span={12} label="Location open">
            <LocationPickerOrControlledInput
              locationLabel={
                vesselFormValues?.locationOpenLabel
                  ? `${vesselFormValues?.locationOpenLabel} (${vesselFormValues?.locationOpenCountryCode})`
                  : undefined
              }
              locationCountryCode={vesselFormValues?.locationOpenCountryCode}
              formNameFactory={label => ['vessel', `locationOpen${label}`]}
              onSelectLocation={onSelectLocation}
            />
          </FormSectionItem>
        </ExpandSection>
      </FormSectionBody>
    </FormSection>
  );
};
