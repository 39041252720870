import styled from 'styled-components';
import Icon from '../../atoms/Icon';
import {ComponentPropsWithoutRef, FC, ReactNode} from 'react';

type ResultDisplayProps = ComponentPropsWithoutRef<'div'> & {
  value: ReactNode;
  onClear?: () => void;
};

export const ResultDisplay: FC<ResultDisplayProps> = ({value, onClear, ...props}) => {
  return (
    <ResultContainer {...props}>
      {value} {onClear && <Icon type="close" onClick={onClear} />}
    </ResultContainer>
  );
};

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  padding: 0 10px;
  color: var(--color-blue);
  background: var(--color-blue-bg);
  font-weight: bold;
  border: var(--border-width) solid var(--color-blue);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-inset-input);

  .icon {
    margin-left: auto;
    cursor: pointer;
    color: var(--color-blue) !important;
  }
`;
