import {FC} from 'react';
import {Popover, PopoverProps} from 'antd';
import styled from 'styled-components';

/**
 * A Popover that, when visible, allows only clicks into the Popover content.
 * Thereby the "click away" action does not reach other components.
 */
export const ModalPopover: FC<PopoverProps> = props => {
  return (
    <>
      <Popover {...props} />
      {(props.open || props.visible) && <ViewportCover />}
    </>
  );
};

/**
 * A component that covers the whole viewport. It prevents clicks from reaching components with a lower z-index.
 */
const ViewportCover = styled.div`
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
`;

export const ModalPopoverTitle = styled.div`
  font-size: var(--font-size-lg);
`;
