import React, {useContext} from 'react';
import {VoyageCharterContext} from '../../Context/VoyageCharterContext';
import numeral from 'numeral';
import ReactECharts from 'echarts-for-react';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

export const Chart = () => {
  const voyageContext = useContext(VoyageCharterContext);

  const {outputState} = voyageContext.state;

  let data: Array<{
    value: number;
    name: string;
  }> = [];

  data.push({
    name: 'Other expense',
    value: outputState.cost.otherCostsSum,
  });
  data.push({
    name: 'Consumption expense',
    value: outputState.cost.costConsumption,
  });

  data.push({
    name: 'Port expenses',
    value: outputState.cost.sumPortCosts,
  });
  data.push({
    name: 'Commission',
    value: outputState.cost.costCommission,
  });
  data.push({
    name: 'CO2',
    value: outputState.cost.costCo2,
  });

  data = data.filter(x => {
    return !isNaN(x.value) && x.value !== 0;
  });

  return (
    <ReactECharts
      option={{
        tooltip: {
          trigger: 'item',
          formatter: (params: {name: string; value: number}) => {
            return `${params.name}<br/> ${numeral(params.value).format('$ 0,0')}`;
          },
        },
        backgroundColor: '#FFFFFF',
        visualMap: {
          show: false,
          min: minBy(data, 'value')?.value,
          max: maxBy(data, 'value')?.value,

          inRange: {
            colorLightness: [0.4, 0.6],
          },
        },
        series: [
          {
            name: 'vessels',
            type: 'pie',
            radius: '60%',
            label: {
              margin: [8, 8, 8, 8],
              width: 200,
              fontSize: 13,
            },

            labelLine: {
              show: true,
              length: 10,
              length2: 20,
              smooth: true,
            },
            data: data,
            minAngle: 7,
            itemStyle: {
              color: '#0064b4',
              shadowBlur: 15,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function () {
              return Math.random() * 200;
            },
          },
        ],
      }}
      style={{minHeight: 300}}
    />
  );
};
