import {VesselTypeEnum} from '../../../../api/node-backend/generated';
import {VesselDetailsVessel} from '../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {SeaboVessel, Cargo as SearchPickerCargo} from '../../../../components/SearchPicker/Type';
import {getCommodityTypeFormatted} from '../../../CargoVesselForm/helper';
import {CreateNegotiationFormVessel, CreateNegotiationFormCargo} from '../utils/types';
import dayjs, {Dayjs} from 'dayjs';
import {getValidNumberOrUndefined} from '../utils/utils';
import {
  PortfolioCargo,
  Station,
} from '../../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

type MinimalLocation = {
  name?: string;
  countryObject?: {
    code: string;
  };
  code?: string;
};

type MinimalStation = {
  type: string;
  locations: MinimalLocation[];
};

export const getFormValueFromDate = (value?: Dayjs | string | null): Dayjs | undefined => {
  return dayjs(value ?? '').isValid() ? dayjs(value) : undefined;
};

export const getFormValuesFromApiLocation = (
  location?: MinimalLocation | null
): {
  label: string | undefined;
  countryCode: string | undefined;
} => {
  return {
    label: location?.name,
    countryCode: location?.countryObject ? location.countryObject.code : location?.code,
  };
};

const getFormValuesFromApiVesselLocation = ({
  stations,
}: {
  stations?: Station[];
}):
  | {
      locationOpenLabel: string | undefined;
      locationOpenCountryCode: string | undefined;
    }
  | undefined => {
  if (!stations?.find(station => station.type === 'nextopen')) {
    return;
  }
  const nextOpenStation = stations.find(station => station.type === 'nextopen');
  const location = nextOpenStation?.locations[0];
  const {label, countryCode} = getFormValuesFromApiLocation(location);
  return {
    locationOpenLabel: label,
    locationOpenCountryCode: countryCode,
  };
};

const getFormValuesFromApiCargoLocation = (
  {stations}: {stations?: MinimalStation[]},
  type: 'loading' | 'discharge'
):
  | {
      loadingLabel: string | undefined;
      loadingCountryCode: string | undefined;
    }
  | {
      dischargeLabel: string | undefined;
      dischargeCountryCode: string | undefined;
    }
  | undefined => {
  let station;
  if (type === 'loading') {
    station = stations?.find(station => station.type === 'loading' || station.type === 'delivery');
  } else {
    station = stations?.find(station => station.type === 'discharge' || station.type === 'redelivery');
  }
  if (!station) {
    return;
  }
  const location = station.locations[0];
  const {label, countryCode} = getFormValuesFromApiLocation(location);
  if (type === 'loading') {
    return {
      loadingLabel: label,
      loadingCountryCode: countryCode,
    };
  } else {
    return {
      dischargeLabel: label,
      dischargeCountryCode: countryCode,
    };
  }
};

export const getFormValuesFromApiVessel = (vessel: SeaboVessel | VesselDetailsVessel): CreateNegotiationFormVessel => {
  return {
    id: vessel.id,
    name: vessel.name ?? undefined,
    imo: getValidNumberOrUndefined(vessel.imo),
    size: getValidNumberOrUndefined(vessel.dwtSummer),
    type: (vessel.vesselType as VesselTypeEnum) ?? undefined,
    sizeUnit: vessel.vesselType?.toLowerCase() === 'container' ? 'TEU' : 'DWT',
    dateOpen: getFormValueFromDate(vessel.nextOpenFrom ?? ''),
    ...getFormValuesFromApiVesselLocation(vessel),
  };
};

export const getFormValuesFromApiCargo = (cargo: SearchPickerCargo | PortfolioCargo): CreateNegotiationFormCargo => {
  return {
    id: cargo.id,
    contractType: cargo.contractType ?? undefined,
    commodityCategory: cargo.commodityCategory ?? undefined,
    commodityType: getCommodityTypeFormatted(cargo) ?? undefined,
    quantityMin: getValidNumberOrUndefined(cargo.quantityMin),
    quantityMax: getValidNumberOrUndefined(cargo.quantityMax),
    quantityUnit: cargo.quantityUnit?.toUpperCase() ?? 'MT',
    laycan: [getFormValueFromDate(cargo.laycanFrom), getFormValueFromDate(cargo.laycanTo)],
    ...getFormValuesFromApiCargoLocation(cargo, 'loading'),
    ...getFormValuesFromApiCargoLocation(cargo, 'discharge'),
  };
};
