import React, {FC} from 'react';
import {LegendBox} from './LegendBox';
import {useCommodityCategoriesQuery} from '../../../queries/useCommodityCategoriesQuery';
import {MapCommodityIcon} from '../mapOptions/MapCommodityIcon';

export const CommoditiesLegend: FC = () => {
  const commodityQuery = useCommodityCategoriesQuery();

  const commodities = commodityQuery.data?.items ?? [];
  return (
    <LegendBox
      title={'Commodities'}
      listItems={commodities.map(commodity => (
        <MapCommodityIcon type={commodity.slug} label={commodity.name} />
      ))}
    />
  );
};
