import {isPointInPolygon} from 'geolib';
import geoJsonEca from '../../../SeaboMap/geoJson-Eca';
import {GeolibInputCoordinates} from 'geolib/es/types';
import {geoJsonLatLngToGeolibCoordinates} from './geoJsonLatLngToGeolibCoordinates';

export const isPointInSECA = ({point}: {point: GeolibInputCoordinates}) => {
  const polygons = geoJsonEca.features.map(polygon =>
    polygon.geometry.coordinates[0].map(coordinate => geoJsonLatLngToGeolibCoordinates([coordinate[0], coordinate[1]]))
  );

  const isInSeca = polygons.some(polygon => isPointInPolygon(point, polygon));
  return isInSeca;
};
