import {Button, Form, Modal, Select} from 'antd';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {FC, useEffect} from 'react';
import {ConsumptionsFormItem} from '../../AssistantModal/VesselAddition/ConsumptionsFormItem/ConsumptionsFormItem';
import {FuelType, VesselConsumptionMode} from '../VesselTypes';
import {ModalWidth} from '../../../../../antd/components/Modal';
import {ModalFooter} from '../../../../../antd/components/ModalFooter';

type FormValues = {
  consumptionModes: VesselConsumptionMode[];
};
export const EditModal: FC<{
  consumptionModes?: VesselConsumptionMode[];
  onClose: () => void;
  onChange: (consumptions: VesselConsumptionMode[]) => void;
}> = ({consumptionModes, onClose, onChange}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({consumptionModes});
  }, [consumptionModes, form]);

  return (
    <Modal
      width={ModalWidth.Large}
      destroyOnClose={true}
      title={'Edit speed and consumption'}
      open={true}
      onOk={() => {
        form.submit();
      }}
      onCancel={onClose}
      maskClosable={false}
      footer={null}>
      <Form<FormValues>
        onFinish={formValue => {
          onChange(formValue.consumptionModes);
          onClose();
        }}
        labelCol={{span: 12}}
        wrapperCol={{span: 12}}
        form={form}>
        <ConsumptionsFormItem form={form} />
        <ModalFooter>
          <Button type="primary" htmlType="submit" style={{minWidth: 96}}>
            Update
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const FuelTypeSelect: FC<{
  value?: FuelType;
  onChange?: (value: FuelType) => void;
  size?: SizeType;
  dataCy?: string;
}> = ({value, size, onChange, dataCy}) => (
  <Select<FuelType>
    data-cy={`FuelTypeSelect-${dataCy}`}
    size={size}
    onChange={onChange}
    value={value}
    style={{minWidth: 70}}>
    <Select.Option value="ifo">IFO</Select.Option>
    <Select.Option value="mdo">MDO</Select.Option>
    <Select.Option value="mgo">MGO</Select.Option>
  </Select>
);
