import Icon from '../Icon';
import Cargo from '../../model/Cargo';
import './style.scss';
import {getIconForCommodity} from '../../utils/helper';
import {commodityCategories} from '../../utils/commodityCategories';

const CargoSvg = ({color = '#FF8E23', small = false}) => {
  return (
    <svg width="42" height="48" style={small ? {transform: 'scale(.75)'} : {}}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M22.5.9l18 10.2a3 3 0 0 1 1.5 2.6v20.6c0 1-.6 2-1.5 2.6L22.5 47a3 3 0 0 1-3 0l-18-10A3 3 0 0 1 0 34.3V13.7c0-1 .6-2 1.5-2.6L19.5 1c1-.6 2-.6 3 0z"
      />
    </svg>
  );
};

const CargoIcon = ({cargoType, commodityType, small, color, className}: $TSFixMe) => {
  const commodityCategory = commodityCategories.find(item => item.slug === commodityType);
  return (
    <span className={className + ' cargo-icon'}>
      <CargoSvg color={color ? color : Cargo.getColor(cargoType)} small={small} />
      <span className="cargo-icon__commodity">
        <Icon
          type={getIconForCommodity(commodityType)}
          title={commodityType ? commodityCategory?.name : undefined}
          style={{color: 'var(--color-white)'}}
        />
      </span>
    </span>
  );
};

export default CargoIcon;
