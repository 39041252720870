import {notification} from '../components/Notification';
import './notification.scss';
import {assert} from './assert';
import {isTest} from './environment';

export type NotificationType = 'success' | 'error' | 'info';

export const showNotification = (
  msg: string = 'The action completed successfully.',
  type: NotificationType = 'success',
  duration = 3,
  description?: string
): void => {
  notification.open({
    className: 'customShowNotification',
    type,
    message: msg,
    duration,
    description,
  });

  let centerTimer: ReturnType<typeof setInterval> | null = null;

  const tryCenterNotification = () => {
    // Center the notification after it is rendered.
    const contentElement = document.querySelector<HTMLElement>('.ant-notification-notice');
    if (!contentElement) {
      // Not rendered yet. Try again later.
      return;
    }
    // Stop waiting.
    clearInterval(centerTimer!);
    centerNotification(contentElement);
  };

  if (!isTest()) {
    // Wait for the notification to be rendered before centering it.
    centerTimer = setInterval(tryCenterNotification, 100);
  }
};

const centerNotification = (contentElement: HTMLElement): void => {
  const contentWidth = contentElement.offsetWidth;
  const windowWidth = window.innerWidth;
  const modalElement = document.querySelector<HTMLElement>('.ant-notification') as HTMLDivElement;
  assert(modalElement);
  modalElement.style.position = 'fixed';
  modalElement.style.top = '24px';
  modalElement.style.left = (windowWidth + contentWidth) / 2 + 'px';
  modalElement.style.right = 'auto';
  modalElement.style.bottom = 'auto';
};
