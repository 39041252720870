import {Dayjs} from 'dayjs';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {cargoOfferApi} from '../../../../../api/symfony/symfonyApi';
import {ApiError} from '../../../../../api/utils/ApiError';
import {Location} from '../../../../../components/SearchPicker/Type';
import {MarketCargo, MarketCargoOffer} from '../../../MarketCargo/MarketCargoOffer';
import {TODO} from '../../../../../utils/TODO';

type Laycan = [from: null | Dayjs, to: null | Dayjs];

export interface CargoFormValues extends MarketCargo {
  loadLocations: Location[];
  dischargeLocations: Location[];
  laycan: Laycan;
  commission: number | null;
}

export const useUpdateCargoOfferMutation = (
  {cargoOffer}: {cargoOffer: MarketCargoOffer},
  options?: UseMutationOptions<TODO, ApiError, CargoFormValues>
) => {
  return useMutation({
    mutationFn: async (changes: CargoFormValues) => {
      const laycanFrom = changes.laycan[0] ? changes.laycan[0]?.toISOString() : undefined;
      const laycanTo = changes.laycan[1] ? changes.laycan[1]?.toISOString() : undefined;
      let load: number[];
      let discharge: number[];
      let delivery: number[];
      let redelivery: number[];
      if (cargoOffer.cargo.contractType === 'vc') {
        load = changes.loadLocations.map(location => location.id);
        discharge = changes.dischargeLocations.map(location => location.id);
        delivery = [];
        redelivery = [];
      } else {
        load = [];
        discharge = [];
        delivery = changes.loadLocations.map(location => location.id);
        redelivery = changes.dischargeLocations.map(location => location.id);
      }

      const newOffer = (await cargoOfferApi.updateCargoOffer({
        offer: cargoOffer.id,
        cargoOfferUpdateRequest: {
          cargo: {
            chartererName: changes.chartererName,
            load,
            discharge,
            delivery,
            redelivery,
            commissionBrokerage: changes.commission ?? undefined,

            vesselSizeMax: changes.vesselSizeMax ?? undefined,
            vesselSizeMin: changes.vesselSizeMin ?? undefined,
            vesselSizeUnit: changes.vesselSizeUnit ?? undefined,

            commissionAddress: changes.commission ? 0 : undefined,
            durationMax: changes.durationMax ?? undefined,
            durationMin: changes.durationMin ?? undefined,
            durationUnit: changes.durationUnit ?? undefined,

            quantityMin: changes.quantityMin ?? undefined,
            quantityMax: changes.quantityMax ?? undefined,
            quantityUnit: changes.quantityUnit ?? undefined,

            commodityCategory: changes.commodityCategory ?? undefined,
            laycanFrom,
            laycanTo,
          },
        },
      })) as MarketCargoOffer;

      return newOffer;
    },
    ...options,
  });
};
