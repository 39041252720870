import {InputState, OutputState} from './voyageChartercalculation/voyageChartercalculation';
import {VoyageRoute, VoyageRouteOutput} from '../VoyageInformation/VoyageTypes';
import {getVoyageConsumption} from './getVoyageConsumption';

export const fixesRouteConsumptionIfNeeded = (params: {
  route: VoyageRoute;
  routeOutput: VoyageRouteOutput;
  routeIndex: number;
  inputState: InputState;
  outputState: OutputState;
}): VoyageRoute => {
  const {inputState, route} = params;

  if (inputState.vessel.consumptionModes.length === 0) {
    return route;
  }

  if (!route.consumption) {
    route.consumption = getVoyageConsumption({
      consumptionModes: inputState.vessel.consumptionModes,
      useCase: params.routeOutput.isBallast ? 'ballast' : 'laden',
    });
  }

  return route;
};
