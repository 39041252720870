import {Button} from 'antd';
import {FC, ReactNode, useState} from 'react';
import styled from 'styled-components';
import Icon from '../../../../../atoms/Icon';
import {NegotiationActions} from './utils/useGetNegotiationActionsWithMutations';
import {DerivedNegotiationState} from '../../utils/getDerivedNegotiationState';
import {ConfirmCancelNegotiationModal} from './ConfirmCancelNegotiationModal';
import {NegotiationRoundResult, NegotiationSummary} from '../../../../../api/node-backend/generated';
import {RetractNegotiationOfferModal} from './RetractNegotiationOfferModal';

type CommitAreaActionAreaProps = {
  negotiation: NegotiationSummary;
  negotiationState: DerivedNegotiationState;
  currentRoundResult?: NegotiationRoundResult;
  isRecap?: boolean;
  actionsBusy?: boolean;
  negotiationActions: NegotiationActions;
};

export const CommitAreaActionArea: FC<CommitAreaActionAreaProps> = ({
  negotiation,
  negotiationState,
  currentRoundResult,
  isRecap,
  actionsBusy,
  negotiationActions,
}) => {
  const {
    canSendOffer,
    canCommitRound,
    canMoveToRecap,
    canMoveToSettled,
    canRetractOffer,
    canOnlyCancel,
    canReopen,
    canExportToPdf,
  } = negotiationState;
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);

  const {
    sendOffer,
    commitRound,
    moveToRecap,
    moveToSettled,
    exitTutorial,
    cancelNegotiation,
    reopenNegotiation,
    exportToPdf,
  } = negotiationActions;

  if (exitTutorial) {
    return (
      <CommitAreaActionAreaContainer>
        <CommitAreaAction onClick={exitTutorial} data-testid="FinishOnboardingButton">
          <Icon type="arrow-right" style={{transform: 'rotate(180deg)'}} /> Exit tutorial
        </CommitAreaAction>
      </CommitAreaActionAreaContainer>
    );
  }

  if (canOnlyCancel) {
    return (
      <CommitAreaActionAreaContainer>
        <ConfirmCancelNegotiationModal
          open={showConfirmCancelModal}
          onOk={(reason, shouldCreateNew) =>
            cancelNegotiation(reason, shouldCreateNew ? {negotiation, roundResult: currentRoundResult!} : undefined)
          }
          onCancel={() => setShowConfirmCancelModal(false)}
        />
        <CommitAreaAction
          type="primary"
          danger
          size="large"
          onClick={() => setShowConfirmCancelModal(true)}
          data-testid="CancelNegotiationButton">
          <Icon type="close" /> Cancel negotiation
        </CommitAreaAction>
      </CommitAreaActionAreaContainer>
    );
  }

  return (
    <CommitAreaActionAreaContainer data-testid="CommitAreaActionArea">
      {canSendOffer && (
        <CommitAreaAction
          type="primary"
          size="large"
          onClick={sendOffer}
          disabled={actionsBusy}
          data-testid="SendOfferButton">
          <Icon type="send" /> Send offer
        </CommitAreaAction>
      )}
      {canMoveToRecap && (
        <CommitAreaAction
          type="primary"
          size="large"
          onClick={moveToRecap}
          disabled={actionsBusy}
          data-testid="MoveToRecapButton">
          <Icon type="check-mark-line" /> Accept
        </CommitAreaAction>
      )}
      {canMoveToSettled && (
        <CommitAreaAction
          type="primary"
          size="large"
          onClick={moveToSettled}
          disabled={actionsBusy}
          data-testid="MoveToSettledButton">
          <Icon type="checkmark" /> Settle negotiation
        </CommitAreaAction>
      )}
      {canCommitRound && (
        <CommitAreaAction
          type="primary"
          size="large"
          onClick={commitRound}
          disabled={actionsBusy}
          data-testid="CommitButton">
          <Icon type="subdirectory-arrow-right" /> Send {isRecap ? 'recap' : 'counter'}
        </CommitAreaAction>
      )}
      {canExportToPdf && (
        <CommitAreaAction
          type={isRecap ? undefined : 'primary'}
          size="large"
          onClick={exportToPdf}
          disabled={actionsBusy}
          data-testid="ExportToPdfButton">
          <Icon type="paper-product" /> Export to PDF
        </CommitAreaAction>
      )}
      {canRetractOffer && (
        <>
          <RetractNegotiationOfferModal
            open={showConfirmCancelModal}
            onOk={reason => cancelNegotiation(reason, {negotiation, roundResult: currentRoundResult!})}
            onCancel={() => setShowConfirmCancelModal(false)}
          />
          <CommitAreaAction
            type="primary"
            danger
            size="large"
            onClick={() => setShowConfirmCancelModal(true)}
            disabled={actionsBusy}
            data-testid="RetractOfferButton">
            <Icon type="undo" /> Retract offer
          </CommitAreaAction>
        </>
      )}
      {canReopen && (
        <CommitAreaAction size="large" onClick={reopenNegotiation} disabled={actionsBusy} data-testid="ReopenButton">
          <Icon type="undo" /> Re-open negotiation
        </CommitAreaAction>
      )}
    </CommitAreaActionAreaContainer>
  );
};

const CommitAreaAction: FC<{
  type?: 'primary';
  danger?: boolean;
  size?: 'small' | 'large';
  onClick?: () => void;
  disabled?: boolean;
  'data-testid'?: string;
  children: ReactNode;
}> = ({type, danger, size, onClick, disabled, 'data-testid': testId, children}) => {
  return (
    <ButtonContainer>
      <Button type={type} danger={danger} size={size} onClick={onClick} disabled={disabled} data-testid={testId}>
        {children}
      </Button>
    </ButtonContainer>
  );
};

const CommitAreaActionAreaContainer = styled.div`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: var(--border-light);

  &:first-of-type {
    margin-top: 20px;
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    width: 50%;

    .icon {
      line-height: 1;
      color: inherit !important;
    }
  }
`;
