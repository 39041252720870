/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  SendSubscriptionMailRequest,
  SendSubscriptionMailRequestFromJSON,
  SendSubscriptionMailRequestToJSON,
} from '../models';

export interface SendSubscriptionMailOperationRequest {
  sendSubscriptionMailRequest: SendSubscriptionMailRequest;
}

/**
 *
 */
export class SubscriptionApi extends runtime.BaseAPI {
  /**
   * Sends an email to seabo support about the current user\'s company subscription.
   */
  async sendSubscriptionMailRaw(
    requestParameters: SendSubscriptionMailOperationRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sendSubscriptionMailRequest === null ||
      requestParameters.sendSubscriptionMailRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'sendSubscriptionMailRequest',
        'Required parameter requestParameters.sendSubscriptionMailRequest was null or undefined when calling sendSubscriptionMail.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/subscription/mails`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SendSubscriptionMailRequestToJSON(requestParameters.sendSubscriptionMailRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sends an email to seabo support about the current user\'s company subscription.
   */
  async sendSubscriptionMail(requestParameters: SendSubscriptionMailOperationRequest): Promise<void> {
    await this.sendSubscriptionMailRaw(requestParameters);
  }
}
