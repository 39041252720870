import {FC} from 'react';
import {ProjectTitle} from './ProjectTitle';
import {MenuItem} from '../../../atoms/Menu/MenuItem';
import {ActionMenu} from '../../ActionMenu/ActionMenu';
import {Rating} from '../../Rating/Rating';
import {ProjectTags} from './ProjectTags';
import {TODO} from '../../../utils/TODO';
import styled from 'styled-components';
import {parseIntTS} from '../../../utils/parseNumberTS';
import {Icon} from '../../../atoms/Icon';

type ProjectOverviewHeaderProps = {
  type: 'vessel' | 'cargo';
  name: string;
  description?: string;
  isFavorite?: boolean;
  negotiations?: string | number;
  items?: string | number;
  rating?: string | number;
  project: number;
  onFavorite?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onUpdate: (updatedProject: TODO) => void;
  onRating?: (rating: number) => void;
  onArchive?: () => void;
  createdUser?: $TSFixMe;
  workspace?: $TSFixMe;
  isArchived?: boolean;
};

export const ProjectOverviewHeader: FC<ProjectOverviewHeaderProps> = ({
  type,
  name,
  description,
  isFavorite,
  items,
  rating,
  project,
  onFavorite,
  onEdit,
  onDelete,
  onUpdate,
  onRating,
  createdUser,
  workspace,
  onArchive,
  isArchived,
}) => {
  const actionsOnlyIfNotArchived = [
    {
      key: 'unmark',
      isValid: !isArchived,
      label: <MenuItem label={isFavorite ? 'Unmark as favorite' : 'Mark as favorite'} onClick={() => onFavorite?.()} />,
    },
    {
      key: 'edit',
      isValid: !isArchived,
      label: <MenuItem label={'Edit'} onClick={() => onEdit?.()} />,
    },
  ];

  return (
    <ProjectOverviewHeaderContainer>
      {isFavorite && <BookmarkIcon type="bookmark" />}
      <ProjectTitleContainer>
        <ProjectTitle type={type} name={name} description={description ?? ''} />
      </ProjectTitleContainer>
      {createdUser && workspace && (
        <ProjectCreatedContainer>
          Created by <br />
          {createdUser.fullName}
        </ProjectCreatedContainer>
      )}
      <ProjectItemsContainer>{items} items</ProjectItemsContainer>
      <ProjectTagsContainer>
        <ProjectTags project={project} onUpdate={(updatedProject: TODO) => onUpdate(updatedProject)} />
      </ProjectTagsContainer>
      <ProjectRatingContainer onClick={e => e.preventDefault()}>
        <Rating rating={parseIntTS(rating)} onClick={rating => onRating?.(rating)} />
      </ProjectRatingContainer>
      <ProjectActionsContainer onClick={e => e.preventDefault()}>
        <ActionMenu
          items={[
            ...(isArchived ? [] : actionsOnlyIfNotArchived),
            {
              key: 'restore',
              label: <MenuItem label={isArchived ? 'Restore' : 'Archive'} onClick={() => onArchive?.()} />,
            },
            {
              key: 'delete',
              label: <MenuItem label={'Delete'} onClick={() => onDelete?.()} />,
            },
          ]}
        />
      </ProjectActionsContainer>
    </ProjectOverviewHeaderContainer>
  );
};

const ProjectOverviewHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
`;

const BookmarkIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: -2px;
  font-size: var(--font-size-2xl) !important;
  color: var(--color-orange) !important;
`;

const ProjectTitleContainer = styled.div`
  flex: 1 1 40%;
`;

const ProjectCreatedContainer = styled.div`
  flex: 1 1 10%;
  color: var(--color-gray-2);
`;

const ProjectItemsContainer = styled.div`
  flex: 1 1 10%;
  color: var(--color-gray-2);
`;

const ProjectTagsContainer = styled.div`
  flex: 1 1 15%;
  color: var(--color-gray-2);
`;

const ProjectRatingContainer = styled.div`
  flex: 1 1 15%;
`;

const ProjectActionsContainer = styled.div`
  flex: 1 1 5%;
`;
