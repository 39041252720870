import {useDispatch} from 'react-redux';
import PortfolioService from '../../../redux/ApiService/PortfolioService';
import {useMutation} from '@tanstack/react-query';
import {PortfolioType} from '../../../redux/Portfolio';
import {ClipboardItem} from '../../../redux/GridClipboard';

interface AssignToSubPortfoliosMutationParams {
  type: PortfolioType;
  subPortfolios: number[];
  items: ClipboardItem[];
}
export const useAssignToSubPortfoliosMutation = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: ({type, subPortfolios, items}: AssignToSubPortfoliosMutationParams) =>
      dispatch(PortfolioService.multiAssignToGroups(type, {subPortfolios, items})),
  });
};
