import {FC} from 'react';
import {useAssignSubPortfoliosMutation} from './useAssignSubPortfoliosMutation';
import {showNotification} from '../../../utils/notification';
import {
  ManageSubPortfolioForm,
  transformFormValuesToPortfolioIdList,
  transformPortfolioIdListToFormValues,
} from './ManageSubPortfolioForm';
import {PortfolioVessel} from '../../../redux/Portfolio';
import {Modal} from '../../../antd/components/Modal';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

type VesselProps = {
  vessel: PortfolioVessel;
  type: 'vessel';
};

type CargoProps = {
  cargo: PortfolioCargo;
  type: 'cargo';
};

type Props = {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  onClosed: () => void;
} & (CargoProps | VesselProps);

export const ManageSubPortfolioModal: FC<Props> = props => {
  const isVessel = props.type === 'vessel';
  const vesselOrCargo = isVessel ? props.vessel : props.cargo;

  const assignSubPortfoliosMutation = useAssignSubPortfoliosMutation(
    {type: props.type, id: vesselOrCargo.id},
    {
      onSuccess: () => {
        showNotification(`${isVessel ? 'Vessel lists' : 'Cargo lists'} updated.`);
        props.onSuccess();
      },
    }
  );
  const initialValues = transformPortfolioIdListToFormValues(
    (vesselOrCargo.portfolioGroups ?? []).map(({id}: {id: number}) => id)
  );

  return (
    <Modal
      footer={null}
      open={props.open}
      onCancel={props.onCancel}
      afterClose={props.onClosed}
      title={`Manage ${isVessel ? 'vessel lists' : 'cargo lists'}`}
      destroyOnClose>
      <ManageSubPortfolioForm
        initialValues={initialValues}
        type={props.type}
        saving={assignSubPortfoliosMutation.isPending}
        onFinish={values => {
          assignSubPortfoliosMutation.mutate(transformFormValuesToPortfolioIdList(values));
        }}
      />
    </Modal>
  );
};
