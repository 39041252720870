import {CSSProperties, useState, useEffect, FC} from 'react';
import {VesselConsumptionMode} from '../../VesselInformation/VesselTypes';
import {CalcTimeLine, VoyageConsumption} from '../VoyageTypes';
import {assertUnreachable} from '../../../../../utils/assert';
import {AutoComplete, List} from 'antd';
import {getDataFieldId} from '../../utils/calcWarnings/calcWarnings';
import {getVesselConsumptionUseCaseLabel} from '../../VesselInformation/getVesselConsumptionUseCaseLabel';

type Props = {
  consumptionModes: VesselConsumptionMode[];
  item: CalcTimeLine;
  area: string;
  field: string;
  onChange?: (value: number | undefined) => void;
  value?: number;
  style: CSSProperties;
  onChangeCompleteConsumption: (value: VoyageConsumption) => void;
};

const filterConsumptionModes = ({
  type,
  consumptionModes,
}: {
  consumptionModes: VesselConsumptionMode[];
  type: 'point' | 'route';
}): VesselConsumptionMode[] => {
  switch (type) {
    case 'route':
      return consumptionModes.filter(item => !item.useCase.match(/port/));
    case 'point':
      return consumptionModes.filter(item => !!item.useCase.match(/port/));
    default:
      assertUnreachable(type);
  }
};

export const ConsumptionOrSpeedField: FC<Props> = ({consumptionModes, item, ...props}) => {
  const filteredConsumptions = filterConsumptionModes({consumptionModes, type: item.nodeType});

  const consumptionOptions = filteredConsumptions.map((item, index) => {
    return {
      label: <VesselConsumptionModeOption vesselConsumptionMode={item} />,
      value: '_' + index,
      item,
    };
  });

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(props.value?.toString() ?? '');
  }, [props.value]);

  return (
    <AutoComplete
      size="small"
      data-cy={props.field}
      options={consumptionOptions}
      style={props.style}
      dropdownMatchSelectWidth={350}
      data-field-id={getDataFieldId({
        field: props.field,
        area: props.area,
      })}
      onSelect={(_: unknown, option: {item: VesselConsumptionMode}) => {
        const vesselConsumption = option.item;

        props.onChangeCompleteConsumption({
          speed: vesselConsumption.speed,
          auxConsumption: vesselConsumption.auxConsumption,
          auxFuelType: vesselConsumption.auxFuelType,
          mainConsumption: vesselConsumption.mainConsumption,
          mainFuelType: vesselConsumption.mainFuelType,
          useCase: vesselConsumption.useCase,
          manuallyAdded: false,
        });
      }}
      value={value}
      onBlur={event => {
        const value = event.target.getAttribute('value') ?? '';
        const numberValue = parseFloat(value);
        if (value.slice(-2) !== '.') {
          props.onChange?.(numberValue);
        }
      }}
      onChange={(value, option) => {
        const isTyping = !('item' in option);
        if (isTyping) {
          setValue(value);
        }
      }}
    />
  );
};

const InPortMode: FC<{vesselConsumptionMode: VesselConsumptionMode}> = ({vesselConsumptionMode}) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={getVesselConsumptionUseCaseLabel(vesselConsumptionMode.useCase).toUpperCase()}
        description={
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
              {vesselConsumptionMode.mainFuelType?.toLocaleUpperCase()} <b>{vesselConsumptionMode.mainConsumption}</b>{' '}
              <small>mts/d</small>
            </div>
            <div style={{flex: 1}}>
              {vesselConsumptionMode.auxFuelType?.toLocaleUpperCase()} <b>{vesselConsumptionMode.auxConsumption}</b>{' '}
              <small>mts/d</small>
            </div>
          </div>
        }
      />
    </List.Item>
  );
};
const InSeaVoyageMode: FC<{vesselConsumptionMode: VesselConsumptionMode}> = ({vesselConsumptionMode}) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <span>
            {vesselConsumptionMode.useCase?.toUpperCase()} <b>{vesselConsumptionMode.speed}</b> knots
          </span>
        }
        description={
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
              {vesselConsumptionMode.mainFuelType?.toLocaleUpperCase()} <b>{vesselConsumptionMode.mainConsumption}</b>{' '}
              <small>mts/d</small>
            </div>
            <div style={{flex: 1}}>
              {vesselConsumptionMode.auxFuelType?.toLocaleUpperCase()} <b>{vesselConsumptionMode.auxConsumption}</b>{' '}
              <small>mts/d</small>
            </div>
          </div>
        }
      />
    </List.Item>
  );
};

export const VesselConsumptionModeOption: FC<{vesselConsumptionMode: VesselConsumptionMode}> = ({
  vesselConsumptionMode,
}) => {
  if (vesselConsumptionMode.useCase.match(/port/)) {
    return <InPortMode vesselConsumptionMode={vesselConsumptionMode} />;
  }
  return <InSeaVoyageMode vesselConsumptionMode={vesselConsumptionMode} />;
};
