import React, {FC} from 'react';
import styled from 'styled-components';
import {Form, Input, Typography} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import {Store} from 'antd/es/form/interface';
import {Center} from '../../../components/Center/Center';
import {ApiErrorMessage} from '../../../components/ApiErrorMessage/ApiErrorMessage';
import {Link} from 'react-router-dom';
import {CtaButton, Layout} from '../utils/shared-components';

export type ResetPasswordFormValues = {
  password: string;
};

const {Text, Paragraph} = Typography;

export const ResetPasswordForm: FC<{
  submitting: boolean;
  submitError?: Error | null;
  onSubmit: (formValues: ResetPasswordFormValues) => void;
}> = ({submitting, submitError, onSubmit}) => {
  const onFinish = (values: Store) => {
    onSubmit(values as ResetPasswordFormValues);
  };

  return (
    <Layout data-testid="ResetPasswordForm">
      <Paragraph style={{marginBottom: '32px'}}>
        Set a new password. Afterwards you will be asked to login with your new password.
      </Paragraph>

      {submitError && (
        <>
          <br />
          <ApiErrorMessage error={submitError} />
        </>
      )}
      <br />
      <Form
        name="reset-password"
        initialValues={{
          email: '',
          password: '',
          rememberMe: true,
        }}
        onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your new password',
            },
            {min: 8, message: 'New password must be minimum 8 characters.'},
          ]}>
          <Input.Password prefix={<LockOutlined />} placeholder="New password" data-testid="password" />
        </Form.Item>

        <Form.Item
          name="passwordConfirmation"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please input your new password again',
            },

            ({getFieldValue}) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two new passwords must match');
              },
            }),
          ]}>
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Confirm new password"
            data-testid="passwordConfirmation"
          />
        </Form.Item>

        <Form.Item className="spacer-mt-xl spacer-mb-m">
          <CtaButton
            type="primary"
            htmlType="submit"
            size="large"
            disabled={submitting}
            loading={submitting}
            data-testid="Submit">
            Submit
          </CtaButton>
        </Form.Item>

        <Center>
          <Text style={{fontSize: '12px'}}>
            or &nbsp;<LoginLink to="/login">Login</LoginLink>
          </Text>
        </Center>
      </Form>
    </Layout>
  );
};

const LoginLink = styled(Link)`
  float: right;
  font-weight: 400;
  color: var(--color-gray-2);
  color: #69bbff;
`;
