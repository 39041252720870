import {InputState, OutputState} from './voyageChartercalculation';
import {calcPointLoadDisDuration} from './calcPointDuration';
import {getConsumptionForPoint} from '../../VoyageInformation/Timeline/getConsumptionForPoint';

export const subCalcPoints = (params: {inputState: InputState; outputState: OutputState}) => {
  const {inputState, outputState} = params;
  let sumDwat = 0; // DWAT used at the point on leaving the Point(Port | Start)

  inputState.voyage.points.forEach((voayagePoint, index) => {
    if (index !== inputState.voyage.points.length - 1) {
      outputState.voyage.routes[index]?.endPoints.push(voayagePoint.coordinates);
    }
    if (index !== 0) {
      outputState.voyage.routes[index - 1]?.endPoints.push(voayagePoint.coordinates);
    }

    sumDwat = sumDwat + (voayagePoint.load || 0) - (voayagePoint.dis || 0);

    outputState.cost.sumPortCosts += voayagePoint.portCosts || 0;

    const {durationDischarging, durationLoad, durationLoadDis} = calcPointLoadDisDuration({voayagePoint});

    let duration = durationLoadDis;

    if (voayagePoint.waitingDurationInHours !== undefined && !isNaN(voayagePoint.waitingDurationInHours)) {
      duration += voayagePoint.waitingDurationInHours / 24;
    }

    if (voayagePoint.turnTimeInHours !== undefined && !isNaN(voayagePoint.turnTimeInHours)) {
      duration += voayagePoint.turnTimeInHours / 24;
    }

    const aux = getConsumptionForPoint({
      costInput: inputState.cost,
      duration: duration,
      point: voayagePoint,
      isScrubberFitted: inputState.vessel.isScrubberFitted,
      type: 'aux',
    });
    const main = getConsumptionForPoint({
      costInput: inputState.cost,
      duration: duration,
      point: voayagePoint,
      isScrubberFitted: inputState.vessel.isScrubberFitted,
      type: 'main',
    });

    const consumptionCosts = aux.costs + main.costs;

    outputState.cargo.duration += duration;

    outputState.voyage.points[index] = {
      duration,
      usedDwat: sumDwat,
      cO2Emission: aux.cO2Emission + main.cO2Emission,
      durationDischarging,
      durationLoad,
      consumptionCosts: consumptionCosts,
    };
  });
};
