/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface SearchHit
 */
export interface SearchHit {
  /**
   *
   * @type {string}
   * @memberof SearchHit
   */
  index: string;
  /**
   *
   * @type {string}
   * @memberof SearchHit
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof SearchHit
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof SearchHit
   */
  score: number;
  /**
   *
   * @type {object}
   * @memberof SearchHit
   */
  source: object;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof SearchHit
   */
  highlight: {[key: string]: Array<string>};
}

export function SearchHitFromJSON(json: any): SearchHit {
  return SearchHitFromJSONTyped(json, false);
}

export function SearchHitFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchHit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    index: json['_index'],
    type: json['_type'],
    id: json['_id'],
    score: json['_score'],
    source: json['_source'],
    highlight: json['highlight'],
  };
}

export function SearchHitToJSON(value?: SearchHit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    _index: value.index,
    _type: value.type,
    _id: value.id,
    _score: value.score,
    _source: value.source,
    highlight: value.highlight,
  };
}
