import {makeApiService} from './ApiService';

const entries = [
  {
    fn: 'getFilterData',
    route: '/api/spotifind/filters/vessel',
    storeData: false,
  },
  {
    fn: 'updateFilterData',
    route: '/api/spotifind/filters/vessel/{id}',
    method: 'PUT',
    storeData: false,
  },
];

const spotifind = makeApiService(entries, {apiName: 'spotifind'});

export const reducers = spotifind.reducers;
export const actions = spotifind.actions;
export const actionTypes = spotifind.actionTypes;
