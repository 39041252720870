import {Dayjs} from 'dayjs';
import {Step5Vessel} from '../step5';
import {extendVesselToStep7Vessel} from './extendVesselToStep7Vessel';
import {Step7Vessel} from './vesselWithEstimatedDestination/vesselWithEstimatedDestination';

export const transformToStep7Vessels = async ({
  vessels,
  currentTime,
}: {
  vessels: Step5Vessel[];
  currentTime: Dayjs;
}): Promise<Step7Vessel[]> => {
  return Promise.all(
    vessels.map(vessel => {
      return extendVesselToStep7Vessel({vessel, currentTime});
    })
  );
};
