import {FC} from 'react';
import {TODO} from '../../../../utils/TODO';
import {VesselStatus} from '../../../../screens/CargoVesselForm/helper';
import {Switch} from 'antd';
import LabelWrapper from '../../../../atoms/LabelWrapper';

export const SpotFormItem: FC<{
  spotState: TODO;
  status: VesselStatus;
  onChangeVesselState: (state: boolean) => void;
}> = ({spotState, onChangeVesselState, status}) => {
  return (
    <LabelWrapper htmlFor={'toogle-spotmarket'} label={'Spot'}>
      <Switch
        size="small"
        disabled={status === 'scrapped' || status === 'inlayup'}
        id={'toogle-spotmarket'}
        checked={!!spotState}
        onChange={() => {
          onChangeVesselState(!spotState);
        }}
        style={{marginTop: 9}}
      />
    </LabelWrapper>
  );
};
