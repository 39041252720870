import {FC} from 'react';
import classNames from 'classnames';
import './style.scss';

interface Props {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  extraLarge?: boolean;
  bottomSpace?: 'small' | 'medium' | 'large' | 'extraLarge';
  topSpace?: 'small' | 'medium' | 'large' | 'extraLarge';
}

export const HorizontalLine: FC<Props> = ({
  small = false,
  medium = false,
  large = false,
  extraLarge = false,
  bottomSpace,
  topSpace,
}) => (
  <hr
    className={classNames('horizontal-line', {
      'horizontal-line--small': small,
      'horizontal-line--medium': medium,
      'horizontal-line--large': large,
      'horizontal-line--extra-large': extraLarge,
      [`horizontal-line--top-${topSpace}`]: !!topSpace,
      [`horizontal-line--bottom-${bottomSpace}`]: !!bottomSpace,
    })}
  />
);

export default HorizontalLine;
