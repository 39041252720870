import {UseQueryResult} from '@tanstack/react-query';
import {UseQueryOptions} from '../../api/utils/ReactQuery';
import {
  VesselDatabaseVessel,
  VesselDetailsVessel,
} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {useGetVesselQuery} from '../../queries/useGetVesselQuery';
import {assertUnreachable} from '../../utils/assert';
import {useVesselDBFindVesselByIdQuery} from '../../queries/useVesselDBFindVesselByIdQuery';

export type VesselDetailsApi = 'new-vessel-db' | 'symfony';

/**
 * Queries for vessel details from the correct API based on whether the vessel is from the new vessel database or from Symfony.
 */
export const useGetVesselDetailsQuery = ({
  vesselId,
  vesselDetailsApi,
  options,
}: {
  vesselId: number | undefined;
  vesselDetailsApi: VesselDetailsApi;
  options?: UseQueryOptions<VesselDetailsVessel | null>;
}): UseQueryResult<VesselDetailsVessel | null> => {
  const enabled = options?.enabled ?? true;

  const newVesselDatabaseQueryEnabled = enabled && vesselDetailsApi === 'new-vessel-db';
  const vesselDatabaseQuery = useVesselDBFindVesselByIdQueryWithVesselDetailsType(vesselId, {
    ...options,
    enabled: newVesselDatabaseQueryEnabled,
  });

  const symfonyQueryEnabled = enabled && vesselDetailsApi === 'symfony';
  const symfonyQuery = useGetVesselQuery({
    vesselId,
    options: {
      ...options,
      enabled: symfonyQueryEnabled,
    },
  });

  switch (vesselDetailsApi) {
    case 'new-vessel-db':
      return vesselDatabaseQuery;
    case 'symfony':
      return symfonyQuery;
    default:
      assertUnreachable(vesselDetailsApi);
  }
};

const useVesselDBFindVesselByIdQueryWithVesselDetailsType = (
  id: number | undefined | null,
  options?: UseQueryOptions<VesselDetailsVessel | null>
): ReturnType<typeof useGetVesselQuery> =>
  useVesselDBFindVesselByIdQuery(id, options as UseQueryOptions<VesselDatabaseVessel> | undefined) as ReturnType<
    typeof useGetVesselQuery
  >;
