import {UserColumn} from '../../../../atoms/UserColumn/UserColumn';
import {CargoHexagon} from '../../../../atoms/CargoHexagon/CargoHexagon';
import Flag from '../../../../atoms/Flags';
import DateTime from '../../../../model/DateTime';
import Station from '../../../../model/Station';
import Vessel from '../../../../model/Vessel';
import Cargo from '../../../../model/Cargo';
import CargoUtil from '../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {CountryLocation, TradingAreaLocation} from '../../../LocationOutput/LocationOutputRow';
import {numberFormat, numberRangeUnitFormat, numberUnitFormat} from '../../../../utils/formatter';
import {UserDateTime} from '../../../DateTime/UserDateTime';
import {cargoMenu} from '../../helper';
import {
  getCommissionTotal,
  getManagementDisponentOwner,
  getManagementHeadOwner,
  getManagementManagingOwner,
  getNameForGeared,
  getVesselType,
} from '../../../../screens/CargoVesselForm/helper';
import Country from '../../../../model/Country';
import capitalize from 'lodash/capitalize';
import {PortLocation} from '../../../LocationOutput/PortLocation';
import {createSelector} from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import {contractCommodityFilter} from '../../layers/PortLayer';
import {SWITCHES} from '../../mapOptions/switches';
import {MapDetailItemView, MapDetailItemType, MapDetailVesselType, PortAreaCV, MapSwitches} from './types';
import {ReactNode} from 'react';
import {PostedByBlur} from '../../../../screens/market/PostedBy/PostedByBlur/PostedByBlur';
import {UserSelectDisabled} from '../../../UserSelectDisabled/UserSelectDisabled';
import {DummyData} from '../../../../screens/market/PostedBy/DummyData';
import {PromotionPopover} from '../../../PromotionPopover/PromotionPopover';
import dayjs from 'dayjs';
import {VesselDetailsVessel} from '../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {NotAvailable} from '../../../../utils/NotAvailable';

export const getPortItemListHeaderLabel = (
  itemView: MapDetailItemView,
  detailType: MapDetailItemType,
  count: number
) => {
  const itemViewKey = detailType === 'vessel' ? 'vessels' : 'cargoes';
  const suffix = ` (${count})`;
  switch (itemView[itemViewKey]) {
    case 'all':
      return (detailType === 'vessel' ? 'All Vessels' : 'All Cargoes') + suffix;
    case 'portfolio':
      return (detailType === 'vessel' ? 'My Fleet' : 'My Cargoes') + suffix;
    case 'market':
      return (detailType === 'vessel' ? 'Market Vessels' : 'Market Cargoes') + suffix;
    case 'none':
      return `Select ${capitalize(detailType)}-Source in Map-Filter`;
  }
};

export const getPortAreaItems = (portCV: PortAreaCV) => {
  const allCargoes = portCV.portfolio.cargoes.items.concat(portCV.market.cargoes.items);
  const allVessels = portCV.portfolio.vessels.items.concat(portCV.market.vessels.items);
  return {
    cargoes: {
      items: allCargoes,
      totalItems: allCargoes.length,
    },
    vessels: {
      items: allVessels,
      totalItems: allVessels.length,
    },
  };
};

export const filterPortAreaData = createSelector(
  (portCV: PortAreaCV) => portCV,
  (portCV: PortAreaCV, switches: Partial<MapSwitches>) => switches,
  (portCV, switches) => {
    if (!switches || !(SWITCHES.VESSEL_PORTFOLIO in switches)) {
      return portCV;
    }

    const {portfolio, market} = cloneDeep(portCV);

    if (!switches[SWITCHES.VESSEL_MARKET]?.state) {
      market.vessels.items = [];
      market.vessels.totalItems = 0;
    }

    if (!switches[SWITCHES.CARGO_MARKET]?.state) {
      market.cargoes.items = [];
      market.cargoes.totalItems = 0;
    }

    if (!switches[SWITCHES.VESSEL_PORTFOLIO]?.state) {
      portfolio.vessels.items = [];
      portfolio.vessels.totalItems = 0;
    }

    if (!switches[SWITCHES.CARGO_PORTFOLIO]?.state) {
      portfolio.cargoes.items = [];
      portfolio.cargoes.totalItems = 0;
    }

    if (Object.values(switches[SWITCHES.VESSEL_PORTFOLIO_SUB] ?? {}).find(i => i)) {
      portfolio.vessels.items = portfolio.vessels.items.filter(
        (i: $TSFixMe) =>
          i.portfolioGroups &&
          i.portfolioGroups.find((p: $TSFixMe) => (switches[SWITCHES.VESSEL_PORTFOLIO_SUB] as $TSFixMe)[p.id])
      );
      portfolio.vessels.totalItems = portfolio.vessels.items.length;
    }

    if (Object.values(switches[SWITCHES.CARGO_PORTFOLIO_SUB] ?? {}).find(i => i)) {
      portfolio.cargoes.items = portfolio.cargoes.items.filter(
        (i: $TSFixMe) =>
          i.portfolioGroups &&
          i.portfolioGroups.find((p: $TSFixMe) => (switches[SWITCHES.CARGO_PORTFOLIO_SUB] as $TSFixMe)[p.id])
      );
      portfolio.cargoes.totalItems = portfolio.cargoes.items.length;
    }

    market.cargoes.items = contractCommodityFilter(switches, market.cargoes.items);
    market.cargoes.totalItems = market.cargoes.items.length;

    portfolio.cargoes.items = contractCommodityFilter(switches, portfolio.cargoes.items);
    portfolio.cargoes.totalItems = portfolio.cargoes.items.length;

    return {
      portfolio,
      market,
    };
  }
);

export const getPostedBy = ({user, originSender}: $TSFixMe) => {
  if (user?.company?.name) {
    return <UserColumn userId={user.id} title={user.fullName} subTitle={user.company.name} />;
  } else if (originSender) {
    return originSender;
  } else {
    return <UserColumn userId={user.id} title={user.fullName} />;
  }
};

export const getSizeAttributesFromVessel = (vessel: MapDetailVesselType) => {
  const size: SizeRows = {};
  const teu = numberUnitFormat(vessel.teuQuantity, 'teu', {emptyString: ''});
  if (teu) {
    size.teu = teu;
  }
  const teu14 = numberUnitFormat(vessel.teu14Quantity, 'teu14', {emptyString: ''});
  if (teu14) {
    size.teu14 = teu14;
  }
  const dwtSummer = numberUnitFormat(vessel.dwtSummer, 'dwt', {emptyString: ''});
  if (dwtSummer) {
    size['dwt summer'] = dwtSummer;
  }
  return size;
};

export type AttributeValueType = string | number | JSX.Element | ReactNode | null | undefined;

export type ColumnAttributeType = Record<string, AttributeValueType>;

export type MapDetailVesselDataAttributes = Record<string, ColumnAttributeType>;

export type MapDetailVesselDataColumns = Record<'leftColumn' | 'rightColumn', MapDetailVesselDataAttributes> & {
  currentLocation: string | JSX.Element;
};

export type OnMapElementChange = (arg: {type: string; latitude: number; longitude: number; id?: number}) => void;

type SizeRows = {
  teu?: string;
  teu14?: string;
  'dwt summer'?: string;
};

export const getMapDetailVesselDataColumns = (
  mapDetailVessel: MapDetailVesselType,
  detailVessel?: VesselDetailsVessel,
  onPortLocationClicked?: OnMapElementChange
) => {
  const {currentPort, currentArea, destinationPort, destinationArea, destination} = mapDetailVessel;
  let currentLocation;
  let nextLocation;
  if (destinationPort) {
    nextLocation = {
      'next port': (
        <PortLocation
          onClick={() => {
            onPortLocationClicked?.({
              ...destinationPort,
              latitude: destinationPort.lat,
              longitude: destinationPort.lon,
            });
          }}
          location={{
            ...destinationPort,
            countryObject: {
              name: Country.getName(destinationPort.country) ?? '',
              code: destinationPort.country,
            },
          }}
          style={{display: 'block'}}
        />
      ),
    };
  } else if (destinationArea) {
    nextLocation = {'next area': <TradingAreaLocation location={destinationArea} />};
  } else {
    nextLocation = {'next location': destination};
  }
  if (currentPort) {
    currentLocation = (
      <PortLocation
        onClick={() => {
          onPortLocationClicked?.({
            type: currentPort.type,
            latitude: currentPort.lat,
            longitude: currentPort.lon,
          });
        }}
        location={{
          ...currentPort,
          countryObject: {
            name: Country.getName(currentPort.country) ?? '',
            code: currentPort.country,
          },
        }}
        style={{display: 'block'}}
      />
    );
  } else if (currentArea) {
    currentLocation = <TradingAreaLocation location={currentArea} />;
  } else {
    currentLocation = NotAvailable;
  }

  const columns: MapDetailVesselDataColumns = {
    currentLocation,
    leftColumn: {
      general: {
        type: mapDetailVessel.class ? mapDetailVessel.class.toUpperCase() : '',
        ...getSizeAttributesFromVessel(mapDetailVessel),
        built: detailVessel?.builtYear ?? '',
        'ship builder': detailVessel?.shipBuilder ?? '',
        owner: getManagementHeadOwner(detailVessel ?? {}),
        'charterer / disp. owner': getManagementDisponentOwner(detailVessel ?? {}),
      },
      commercial: {
        imo: detailVessel?.imo ?? mapDetailVessel.imo,
        'commercial manager': getManagementManagingOwner(detailVessel ?? {}),
      },
    },
    rightColumn: {
      location: {
        received: DateTime.fromServerResponse(mapDetailVessel.postime).getFormattedDateTime(),
        position: mapDetailVessel.coordinates,
        ...nextLocation,
        eta: DateTime.fromServerResponse(mapDetailVessel.eta).getFormattedDateTime(),
      },
      status: {
        speed: numberUnitFormat(mapDetailVessel.speed, 'knots', {toUpperCase: false}),
        course: numberUnitFormat(mapDetailVessel.course, '°', {unitSpace: false}),
        draft: numberUnitFormat(mapDetailVessel.draught, 'meters', {toUpperCase: false}),
        status: mapDetailVessel.statusText,
      },
    },
  };

  return columns;
};

export const vesselDetailData = (vessel: $TSFixMe, isPayingCustomer: boolean) => {
  const vesselSubType = {
    [vessel.vesselType === 'bulker' ? 'Sub type' : 'Design Type']:
      vessel.vesselType === 'bulker' ? capitalize(vessel.designSubType) : capitalize(vessel.designType),
  };
  const numberUnitOptions = {toUpperCase: false, emptyString: '-'};
  const postedBy =
    vessel.target === 'market'
      ? {
          'Offer info': isPayingCustomer
            ? {
                'posted by': getPostedBy(vessel.offer),
                'posted at': <UserDateTime format={'dateTime'} value={vessel.createdAt} />,
              }
            : {
                'posted by': (
                  <PromotionPopover active trigger={'hover'}>
                    <PostedByBlur>
                      <UserSelectDisabled>
                        <DummyData />
                      </UserSelectDisabled>
                    </PostedByBlur>
                  </PromotionPopover>
                ),
              },
        }
      : null;

  return {
    general: {
      name: vessel.name,
      vesselType: vessel.vesselType ? vessel.vesselType.toUpperCase() : '-',
      ...vesselSubType,
      imo: vessel.imo,
      built: vessel.builtYear,
      flag: vessel.countryFlag ? (
        <CountryLocation
          style={{display: 'block'}}
          location={{code: vessel.countryFlag, name: Country.getName(vessel.countryFlag)}}
        />
      ) : null,
    },
    'Next open': {
      'date open': Vessel.formatDateOpen(vessel),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
      'location open': Station.getLocationByStationType(vessel.stations, 'nextopen'),
    },
    intake: {
      ...getSizeAttributesFromVessel(vessel),
      holds: vessel.holds,
      hatches: vessel.hatches,
      grain: numberFormat(vessel.grain, {emptyString: '-'}),
      bale: numberFormat(vessel.bale, {emptyString: '-'}),
    },
    dimensions: {
      loa: numberUnitFormat(vessel.loa, 'meters', numberUnitOptions),
      beam: numberUnitFormat(vessel.beam, 'meters', numberUnitOptions),
      draft: numberUnitFormat(vessel.draft, 'meters', numberUnitOptions),
      gt: numberUnitFormat(vessel.gt, 'tonnes', numberUnitOptions),
      nt: numberUnitFormat(vessel.nt, 'tonnes', numberUnitOptions),
      tpc: numberUnitFormat(vessel.tpc, 'tonnes', numberUnitOptions),
    },
    ownership: {
      manager: vessel.managementHeadOwner,
      owner: vessel.managementManagingOwner,
      charterer: vessel.managementDisponentOwner,
    },
    ...postedBy,
  };
};

export const cargoDetailData = (cargo: $TSFixMe, isPayingCustomer: boolean) => {
  const contractType = cargo.contractType ? (
    <span style={{display: 'flex', alignItems: 'center'}}>
      {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      <CargoHexagon type={cargo.contractType} style={{marginRight: '6px'}} /> {cargoMenu[cargo.contractType]}
    </span>
  ) : null;
  const quantity = {
    quantity: {
      size: Cargo.getSizeValue(cargo, '-'),
    },
  };
  const features = {
    features: {
      size: Cargo.getSizeValue(cargo, '-'),
      geared: cargo.geared ? getNameForGeared(cargo) : null,
      'vessel type': cargo.vesselType ? getVesselType(cargo) : null,
      'min reefer plugs': numberFormat(cargo.reeferPlugs, {emptyString: '-'}),
    },
    duration: {
      duration: numberRangeUnitFormat(cargo.durationMin, cargo.durationMax, cargo.durationUnit, {
        toUpperCase: false,
      }),
      period: CargoUtil.formatPeriod(cargo) || '-',
    },
  };
  const cargoInfo = cargo.contractType === 'vc' ? quantity : features;
  const postedBy =
    cargo.target === 'market'
      ? {
          'Offer info': isPayingCustomer
            ? {
                'posted by': getPostedBy(cargo.offer),
                'posted at': <UserDateTime format={'dateTime'} value={cargo.createdAt} />,
              }
            : {
                'posted by': (
                  <PromotionPopover active trigger={'hover'}>
                    <PostedByBlur>
                      <UserSelectDisabled>
                        <DummyData />
                      </UserSelectDisabled>
                    </PostedByBlur>
                  </PromotionPopover>
                ),
              },
        }
      : null;
  return {
    general: {
      'charterer name': cargo.chartererName,
      'market segment': cargo.cargoType ? cargo.cargoType.toUpperCase() : null,
      'contract type': contractType,
      'max age': numberUnitFormat(cargo.maxVesselAge, 'years', {toUpperCase: false, emptyString: '-'}),
      commodity: Cargo.getCommodityCategoryForDetails(cargo),
    },
    ...cargoInfo,
    location: {
      laycan: CargoUtil.formatLaycan(cargo),
      'load/delivery': Cargo.getLocationLoadDelShortValue(cargo),
      'disch/redelivery': Cargo.getLocationDischRedelShortValue(cargo),
    },
    commission: {
      address: numberUnitFormat(cargo.commissionAddress, '%', {emptyString: '-'}),
      brokerage: numberUnitFormat(cargo.commissionBrokerage, '%', {emptyString: '-'}),
      total: numberUnitFormat(getCommissionTotal(cargo), '%', {emptyString: '-'}),
    },
    ...postedBy,
  };
};

export const vesselPortCallsDetailData = ({port, arrivalDate, departureDate, duration}: $TSFixMe) => ({
  meta: {
    icon: <Flag countryCode={port.country} shadow />,
    extraTitle: `(${port.code})`,
  },
  [port.name]: {
    Arrival: arrivalDate ? DateTime.fromServerResponse(arrivalDate).getFormattedDateTime() : null,
    Departure: departureDate ? DateTime.fromServerResponse(departureDate).getFormattedDateTime() : null,
    Duration: duration
      ? (dayjs.duration(duration, 'seconds') as $TSFixMe).format(
          'y [years], w [weeks], d [days], h [hours], m [minutes]',
          {
            largest: 3,
            trim: 'both',
          }
        )
      : null,
  },
});
