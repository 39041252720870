import {StepResult} from './StepResult';
import {PreparedAisVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {FilterParams} from '../filterParams';
import {Step1Result} from './step1';
import {dwtFilter} from '../filters/dwtFilter';

export type Step2Result = StepResult<PreparedAisVessel>;
/**
 * filter only vessels of a certain size
 *
 * removes all vessels that are bigger or smaller than the frames of the desired size.
 */
export const step2 = ({params, preResult}: {preResult: Step1Result; params: FilterParams}): Step2Result => {
  let vesselList = [...preResult.vessels];

  if (params.enabledFilters.size) {
    vesselList = dwtFilter({vessels: vesselList, filter: params});
  }

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: `Between ${params.minDWT} mt and ${params.maxDWT} mt`,
        count: vesselList.length,
        id: 'vesselSize',
      },
    ],
  };
};
