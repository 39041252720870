/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * Summary of tag values that available among the buckets of a statistic.
 * @export
 * @interface AvailableOfferTag
 */
export interface AvailableOfferTag {
  /**
   * The name of the tag.
   * @type {string}
   * @memberof AvailableOfferTag
   */
  name?: string;
  /**
   * Values that are available for this tag type.
   * @type {Set<string>}
   * @memberof AvailableOfferTag
   */
  values?: Set<string>;
}

export function AvailableOfferTagFromJSON(json: any): AvailableOfferTag {
  return AvailableOfferTagFromJSONTyped(json, false);
}

export function AvailableOfferTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableOfferTag {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    values: !exists(json, 'values') ? undefined : json['values'],
  };
}

export function AvailableOfferTagToJSON(value?: AvailableOfferTag | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    values: value.values,
  };
}
