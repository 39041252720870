import {TourDefinition, TourId, getFinalStep} from '../../../components/OnboardingTour/TourDefinition';

export const MyCargoesTourDefinition: TourDefinition = {
  id: TourId.MyCargoes,
  steps: [
    {
      title: 'My Cargoes',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/6.%20Onboarding%20My%20Cargoes.mp4',
      },
      pdfDownloadUrl:
        "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/07%20My%20Cargoes/Let's%20meet%20my%20cargoes.pdf",
      placement: 'center',
    },
    {
      title: 'How to work with My Cargoes',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/6%20How%20to%20My%20Cargoes/6.%201%20How%20to%20work%20with%20My%20Cargoes.mp4',
      },
      placement: 'center',
    },
    getFinalStep('My Fleet'),
  ],
};
