import {Col, Row} from 'antd';
import React, {useState} from 'react';
import {VesselInformation} from '../VesselInformation/VesselInformation';
import {CargoInformation} from '../CargoInformation/CargoInformation';
import {CostInformation} from '../CostInformation/CostInformation';

export const VesselCargoExpensesRow = () => {
  const [showBody, setShowBody] = useState(false);
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <VesselInformation
          showBody={showBody}
          onChangeShowBody={newShowBody => {
            setShowBody(newShowBody);
          }}
        />
      </Col>
      <Col span={8}>
        <CargoInformation
          showBody={showBody}
          onChangeShowBody={newShowBody => {
            setShowBody(newShowBody);
          }}
        />
      </Col>
      <Col span={8}>
        <CostInformation
          showBody={showBody}
          onChangeShowBody={newShowBody => {
            setShowBody(newShowBody);
          }}
        />
      </Col>
    </Row>
  );
};
