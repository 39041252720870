import {FC} from 'react';
import styled from 'styled-components';
import {NegotiationRoundResult, NegotiationStatus, NegotiationSummary} from '../../../../../api/node-backend/generated';
import {TermItemCreate} from '../TermItem/TermItemCreate/TermItemCreate';
import {NegotiationActions} from '../CommitArea/utils/useGetNegotiationActionsWithMutations';
import {SaveContractTemplateModalButton} from './Terms/ContractTemplates/SaveContractTemplateModalButton';
import {TemplateActions} from '../../../NegotiationTemplates/utils/useTemplateActions';
import {SubjectItemCreate} from '../Subjects/SubjectItemCreate';
import {getDerivedNegotiationState} from '../../utils/getDerivedNegotiationState';

export const CreateArea: FC<{
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  isGuest?: boolean;
  actionsBusy?: boolean;
  negotiationActions: NegotiationActions;
  templateActions: TemplateActions;
}> = ({negotiation, roundResult, isGuest, actionsBusy, negotiationActions, templateActions}) => {
  const {createTerms, createSubjects} = negotiationActions;
  const {createTemplate} = templateActions;

  const {status} = negotiation;
  const termCount = roundResult.terms.length;
  const canSaveContractTemplate =
    [NegotiationStatus.Offer, NegotiationStatus.Settled].includes(status) && termCount > 0 && !!createTemplate;
  const {canAddTerms, canAddSubjects} = getDerivedNegotiationState(negotiation);

  if (!canAddTerms && !canAddSubjects && !canSaveContractTemplate) {
    return null;
  }

  return (
    <>
      <TermListDivider />
      {canAddTerms && (
        <TermItemCreate disabled={actionsBusy} onAdd={term => createTerms([{...term, sortIndex: termCount}])} />
      )}
      {canAddSubjects && (
        <SubjectItemCreate disabled={actionsBusy} onAdd={sub => createSubjects([{...sub, termId: null}])} />
      )}
      {!isGuest && canSaveContractTemplate && !!createTemplate && (
        <SaveContractTemplateModalButton
          negotiation={negotiation}
          roundResult={roundResult}
          disabled={actionsBusy}
          onSaveContactTemplate={(createOrUpdateNegotiationTemplateDTO, onSuccess) => {
            void createTemplate({createOrUpdateNegotiationTemplateDTO}, onSuccess);
          }}
          style={{marginTop: 16}}
          type="dashed"
        />
      )}
    </>
  );
};

export const TermListDivider = styled.div`
  margin: 16px 2px 20px;
  border-bottom: var(--border-base);
`;
