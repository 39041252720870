/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CreateNegotiationCargoDto
 */
export interface CreateNegotiationCargoDto {
  /**
   * The cargo ID is the ID from a cargo on the platform.
   * @type {number}
   * @memberof CreateNegotiationCargoDto
   */
  id?: number;
  /**
   * The commodity category of the cargo.
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  commodityCategory?: string;
  /**
   * The contract type of the cargo.
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  contractType?: string;
  /**
   * The commodity type of the cargo.
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  commodityType?: string;
  /**
   * The minimum quantity of the cargo.
   * @type {number}
   * @memberof CreateNegotiationCargoDto
   */
  quantityMin?: number;
  /**
   * The maximum quantity of the cargo.
   * @type {number}
   * @memberof CreateNegotiationCargoDto
   */
  quantityMax?: number;
  /**
   * The quantity unit of the cargo.
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  quantityUnit?: string;
  /**
   * The laycan from date of the cargo. Format: YYYY-MM-DD
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  laycanFrom?: string;
  /**
   * The laycan to date of the cargo. Format: YYYY-MM-DD
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  laycanTo?: string;
  /**
   * The laycan from location of the cargo.
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  loadingLabel?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  loadingCountryCode?: string;
  /**
   * The laycan to location of the cargo.
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  dischargeLabel?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNegotiationCargoDto
   */
  dischargeCountryCode?: string;
}

export function CreateNegotiationCargoDtoFromJSON(json: any): CreateNegotiationCargoDto {
  return CreateNegotiationCargoDtoFromJSONTyped(json, false);
}

export function CreateNegotiationCargoDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNegotiationCargoDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    commodityCategory: !exists(json, 'commodityCategory') ? undefined : json['commodityCategory'],
    contractType: !exists(json, 'contractType') ? undefined : json['contractType'],
    commodityType: !exists(json, 'commodityType') ? undefined : json['commodityType'],
    quantityMin: !exists(json, 'quantityMin') ? undefined : json['quantityMin'],
    quantityMax: !exists(json, 'quantityMax') ? undefined : json['quantityMax'],
    quantityUnit: !exists(json, 'quantityUnit') ? undefined : json['quantityUnit'],
    laycanFrom: !exists(json, 'laycanFrom') ? undefined : json['laycanFrom'],
    laycanTo: !exists(json, 'laycanTo') ? undefined : json['laycanTo'],
    loadingLabel: !exists(json, 'loadingLabel') ? undefined : json['loadingLabel'],
    loadingCountryCode: !exists(json, 'loadingCountryCode') ? undefined : json['loadingCountryCode'],
    dischargeLabel: !exists(json, 'dischargeLabel') ? undefined : json['dischargeLabel'],
    dischargeCountryCode: !exists(json, 'dischargeCountryCode') ? undefined : json['dischargeCountryCode'],
  };
}

export function CreateNegotiationCargoDtoToJSON(value?: CreateNegotiationCargoDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    commodityCategory: value.commodityCategory,
    contractType: value.contractType,
    commodityType: value.commodityType,
    quantityMin: value.quantityMin,
    quantityMax: value.quantityMax,
    quantityUnit: value.quantityUnit,
    laycanFrom: value.laycanFrom,
    laycanTo: value.laycanTo,
    loadingLabel: value.loadingLabel,
    loadingCountryCode: value.loadingCountryCode,
    dischargeLabel: value.dischargeLabel,
    dischargeCountryCode: value.dischargeCountryCode,
  };
}
