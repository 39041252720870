import {Fragment, useEffect} from 'react';
import commodityApi from '../../../../redux/ApiService/commodity';
import {filterBranchDefinition} from './CommoditiesDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {MultiSelect} from '../../../../atoms/Select/MultiSelect';
import {CommodityCategory} from '../../../../api/symfony/generated';
import {useDispatch, useSelector} from '../../../../redux/react-redux';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const Commodities = ({filterProviderApi}: Props) => {
  const dispatch = useDispatch();
  const commodities = useSelector(state => state.api.commodity.get.data || []) as CommodityCategory[];

  useEffect(() => {
    dispatch(commodityApi.get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
      {({handleChange, values}) => (
        <Fragment>
          <div className="container">
            <MultiSelect
              id={'filter-commodities'}
              value={values.categories}
              onChange={value => handleChange('categories', [...value])}
              options={commodities}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.slug}
            />
          </div>
        </Fragment>
      )}
    </FilterButton>
  );
};

export default Commodities;
