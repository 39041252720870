import {DatePicker} from 'antd';
import {RangePickerProps} from 'antd/es/date-picker';
import {useState} from 'react';
import styled from 'styled-components';
import {preventDefaultOnEnterPress} from '../../utils/preventDefaultOnEnterPress';

const {RangePicker} = DatePicker;

// As per https://stackoverflow.com/questions/66121173/importing-rangevalue-type-from-ant-design
type RangeValue = Parameters<NonNullable<RangePickerProps['onChange']>>[0];

// This is a custom range picker that will not show previously set values when the picker is open
// This is to prevent panel jumping to previously set values which might be far in the past/future
const CustomRangePicker = (props: RangePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RangePicker
      {...props}
      // @ts-expect-error DatePicker.onKeyDown argument preventDefault is deprecated
      {...preventDefaultOnEnterPress(props)}
      onChange={(dates, dateStrings) => {
        const [from, to] = (dates ?? [null, null]).values();
        const updatedDates: RangeValue = [from, to];
        props.onChange?.(updatedDates, dateStrings);
      }}
      onOpenChange={open => {
        setIsOpen(open);
        props.onOpenChange?.(open);
      }}
      value={isOpen ? [null, null] : props.value}
    />
  );
};

export const StyledRangePicker = styled(CustomRangePicker)`
  width: 100%;
  padding: 6px 12px;
  background-color: var(--color-gray-6);
  border: var(--border-base);

  .ant-picker-input > input::placeholder {
    color: var(--color-gray-1);
  }

  .ant-picker-input > input {
    font-size: var(--font-size-md);
    color: var(--color-gray-1);
  }
`;
