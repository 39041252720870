import LocationOutputRow from './LocationOutputRow';

import './styles.scss';

type Props = {
  vertical?: boolean;
  type?: string;
  locations?: $TSFixMe[];
  style?: $TSFixMe;
  stationType?: string;
};

const LocationOutput = ({vertical, locations, style, stationType}: Props) => {
  return (
    <div className="location-output" style={style}>
      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
      {locations.length > 0
        ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          locations.map(location => (
            <LocationOutputRow
              key={location.id}
              vertical={vertical}
              type={location.type}
              location={location}
              stationType={stationType}
            />
          ))
        : 'N/A'}
    </div>
  );
};

export default LocationOutput;
