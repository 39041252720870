/* tslint:disable */
/* eslint-disable */
/**
 * PetridishService
 * PetridishService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface NewAnnotationRequest
 */
export interface NewAnnotationRequest {
  /**
   * User Id
   * @type {number}
   * @memberof NewAnnotationRequest
   */
  userId: number;
  /**
   * IMO
   * @type {number}
   * @memberof NewAnnotationRequest
   */
  imo: number;
  /**
   * Text to store
   * @type {string}
   * @memberof NewAnnotationRequest
   */
  text: string;
}

export function NewAnnotationRequestFromJSON(json: any): NewAnnotationRequest {
  return NewAnnotationRequestFromJSONTyped(json, false);
}

export function NewAnnotationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewAnnotationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json['userId'],
    imo: json['imo'],
    text: json['text'],
  };
}

export function NewAnnotationRequestToJSON(value?: NewAnnotationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    imo: value.imo,
    text: value.text,
  };
}
