import dayjs, {Dayjs} from 'dayjs';

export const getEachDateInRange = (start: dayjs.Dayjs, end: dayjs.Dayjs): Dayjs[] => {
  if (start.isAfter(end)) {
    throw new Error('start date must be before end date');
  }

  const days: Dayjs[] = [];

  for (let day = start; day.isSameOrBefore(end, 'date'); day = day.add(1, 'day')) {
    days.push(day);
  }

  return days;
};
