import {useState, FC} from 'react';
import {CorrectResult} from './useFastVesselImportMapQuery';
import {Button, Col, Row} from 'antd';
import {LoadingModal} from '../../../../../components/LoadingModal/LoadingModal';
import {shipNameColumn} from '../../../../../components/GridLayout/columns/vessel/shipNameColumn/shipNameColumn';
import {teuColumn} from '../../../../../components/GridLayout/columns/vessel/teuColumn';
import {dwtColumn} from '../../../../../components/GridLayout/columns/vessel/dwtColumn';
import {grainColumn} from '../../../../../components/GridLayout/columns/vessel/grainColumn';
import {lengthColumn} from '../../../../../components/GridLayout/columns/vessel/lengthColumn';
import {shipBuilderColumn} from '../../../../../components/GridLayout/columns/vessel/shipBuilderColumn';
import {useCloneMultipleVesselsToMyFleetMutation} from '../../../../../components/Modal/ClonePortfolioEntry/useCloneVesselToMyFleetMutation';
import {showNotification} from '../../../../../utils/notification';
import {DataGrid} from '../../../../../components/DataGrid/DataGrid';
import {ModalFooter} from '../../../../../antd/components/ModalFooter';

export const CorrectVesselGrid: FC<{
  correctVesselResults: CorrectResult[];
  isLoadingQuery: boolean;
  onSuccess: () => void;
}> = ({correctVesselResults, isLoadingQuery, onSuccess}) => {
  const correctVessels = correctVesselResults.map((result: CorrectResult) => result.vessel);
  const [importedVesselsCount, setImportedVesselsCount] = useState(0);

  const cloneMultipleVesselsToMyFleetMutation = useCloneMultipleVesselsToMyFleetMutation(
    {
      onProgress: count => {
        setImportedVesselsCount(count);
      },
    },
    {
      onSuccess: () => {
        showNotification('Vessels are imported.');
        setImportedVesselsCount(0);
        onSuccess();
      },
    }
  );

  const onImport = async () => {
    cloneMultipleVesselsToMyFleetMutation.mutate({vessels: correctVessels});
  };

  const importedPercentage = Math.round((importedVesselsCount / (correctVessels.length ?? 1)) * 100);

  return (
    <>
      <Row>
        {cloneMultipleVesselsToMyFleetMutation.isPending && (
          <LoadingModal progress={importedPercentage} isLoading={true} />
        )}
        <Col span={24}>
          <DataGrid
            data={correctVessels}
            columns={[
              shipNameColumn({options: {openAsNewTab: true}}),
              teuColumn({}),
              dwtColumn({}),
              grainColumn({}),
              lengthColumn({}),
              shipBuilderColumn({}),
            ]}
          />
        </Col>
      </Row>
      <ModalFooter>
        <Button
          loading={isLoadingQuery}
          type={'primary'}
          disabled={cloneMultipleVesselsToMyFleetMutation.isPending}
          onClick={onImport}>
          Import vessels
        </Button>
      </ModalFooter>
    </>
  );
};
