import {MulticalcVesselInput} from '../../types';
import {OneCalcResult} from '../../utils/oneCalc';
import xor from 'lodash/xor';
import intersection from 'lodash/intersection';
import {TODO} from '../../../../../utils/TODO';

export const getCalcVesselSets = (filteredBreakEvenCalcs: OneCalcResult[], vessels: TODO[]) => {
  const successfulCalcVessels: MulticalcVesselInput[] = [];
  const otherCalcVessels: MulticalcVesselInput[] = [];
  for (const calc of filteredBreakEvenCalcs) {
    const calcVessel = vessels.find(v => v.vessel.id === calc.vessel.id)!;
    if (
      calc.voyageCalculationOutput.cargo.revenueNet &&
      !successfulCalcVessels.find(v => v.vessel.id === calcVessel.vessel.id)
    ) {
      successfulCalcVessels.push(calcVessel);
    } else if (!otherCalcVessels.find(v => v.vessel.id === calcVessel.vessel.id)) {
      otherCalcVessels.push(calcVessel);
    }
  }
  const erroneousCalcVessels = xor(otherCalcVessels, intersection(otherCalcVessels, successfulCalcVessels));
  const hiddenCalcVessels = xor(vessels, [...successfulCalcVessels, ...erroneousCalcVessels]);
  return {successfulCalcVessels, erroneousCalcVessels, hiddenCalcVessels};
};
