import {makeApiService, ApiServiceEntry} from './ApiService';

const entries: ApiServiceEntry[] = [
  {
    fn: 'companyUploadLogo',
    route: '/api/attachments/company-image',
    method: 'POST',
    isFormData: true,
  },
];

const companyImageApi = makeApiService(entries, {apiName: 'companyImage'});

export const reducers = companyImageApi.reducers;
export const actions = companyImageApi.actions;
export const actionTypes = companyImageApi.actionTypes;
