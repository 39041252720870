/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationAttachmentType,
  NegotiationAttachmentTypeFromJSON,
  NegotiationAttachmentTypeFromJSONTyped,
  NegotiationAttachmentTypeToJSON,
  NegotiationParty,
  NegotiationPartyFromJSON,
  NegotiationPartyFromJSONTyped,
  NegotiationPartyToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationAttachment
 */
export interface NegotiationAttachment {
  /**
   *
   * @type {string}
   * @memberof NegotiationAttachment
   */
  id: string;
  /**
   * The date the attachment was uploaded.
   * @type {Date}
   * @memberof NegotiationAttachment
   */
  createdAt: Date;
  /**
   *
   * @type {string}
   * @memberof NegotiationAttachment
   */
  title: string;
  /**
   *
   * @type {NegotiationAttachmentType}
   * @memberof NegotiationAttachment
   */
  type: NegotiationAttachmentType;
  /**
   * File size in bytes
   * @type {number}
   * @memberof NegotiationAttachment
   */
  fileSize: number;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationAttachment
   */
  uploader: NegotiationParty;
}

export function NegotiationAttachmentFromJSON(json: any): NegotiationAttachment {
  return NegotiationAttachmentFromJSONTyped(json, false);
}

export function NegotiationAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationAttachment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: new Date(json['createdAt']),
    title: json['title'],
    type: NegotiationAttachmentTypeFromJSON(json['type']),
    fileSize: json['fileSize'],
    uploader: NegotiationPartyFromJSON(json['uploader']),
  };
}

export function NegotiationAttachmentToJSON(value?: NegotiationAttachment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt.toISOString(),
    title: value.title,
    type: NegotiationAttachmentTypeToJSON(value.type),
    fileSize: value.fileSize,
    uploader: NegotiationPartyToJSON(value.uploader),
  };
}
