export const acceptFloatOrEmpty = (
  value: string,
  callback: (value: string) => void,
  decimalsLeft = 0,
  decimalsRight = 0
) => {
  if (
    value.match(
      RegExp(
        `^-?\\d{0,${decimalsLeft > 0 ? decimalsLeft : 99}}([.,]\\d{0,${decimalsRight > 0 ? decimalsRight : 99}})?$`
      )
    ) ||
    value === ''
  ) {
    callback(value.replace(',', ''));
  }
};

export const acceptIntOrEmpty = (value: string, callback: (value: string) => void, decimals = 0) => {
  if (value.match(RegExp(`^-?\\d{0,${decimals > 0 ? decimals : 99}}$`)) || value === '') {
    callback(value);
  }
};

const DEFAULT_EMPTY_STRING = 'N/A';
const DEFAULT_DELIMITER = ' - ';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNotANumber = (value: any) => typeof value !== 'number' || isNaN(value);

/**
 * Don't use _numberLocaleFormat directly.
 * This is an internal function with no validations.
 * It's just exported for the test in case it get's more complex later
 *
 * @private
 */

const resolvedOptions = new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'USD'}).resolvedOptions();
export const currencyOptions = {
  minimumFractionDigits: resolvedOptions.minimumFractionDigits,
  maximumFractionDigits: resolvedOptions.maximumFractionDigits,
};
export const _numberLocaleFormat = (v: number, options?: Intl.NumberFormatOptions) => {
  // Deal with negative zero
  const n = Object.is(v, -0) ? 0 : v;
  return n.toLocaleString('en-GB', options);
};

/** @private */
const _unitFormat = (unitString: string | null, toUpperCase: boolean, unitSpace: boolean) => {
  if (typeof unitString !== 'string') {
    return '';
  }
  if (toUpperCase) {
    unitString = unitString.toUpperCase();
  }
  return unitString ? (unitSpace ? ' ' : '') + unitString : unitString;
};

export const numberFormat = (
  value?: string | number | null,
  options?: {emptyString?: string} | null,
  formatOptions?: Intl.NumberFormatOptions
) => {
  const {emptyString = DEFAULT_EMPTY_STRING} = options || {};
  if (value === undefined || value === null) {
    return emptyString;
  }
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  return isNotANumber(parsedValue) ? emptyString : _numberLocaleFormat(parsedValue, formatOptions);
};

export const numberUnitFormat = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  unitString: string | null | undefined = '',
  options?: {toUpperCase?: boolean; emptyString?: string; unitSpace?: boolean},
  formatOptions?: Intl.NumberFormatOptions
) => {
  const {toUpperCase = true, emptyString = DEFAULT_EMPTY_STRING, unitSpace = true} = options || {};
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    return emptyString;
  }

  return _numberLocaleFormat(parsedValue, formatOptions) + _unitFormat(unitString, toUpperCase, unitSpace);
};

export const numberRangeFormat = (
  minValue?: string | number | null,
  maxValue?: string | number | null,
  options?: {delimiter?: string; emptyString?: string} | null
) => {
  const {delimiter = DEFAULT_DELIMITER} = options || {};
  const min = typeof minValue === 'string' ? parseFloat(minValue) : minValue;
  const max = typeof maxValue === 'string' ? parseFloat(maxValue) : maxValue;

  if (isNotANumber(min)) {
    return numberFormat(max, options);
  }
  if (isNotANumber(max)) {
    return numberFormat(min, options);
  }
  if (min === max) {
    return numberFormat(min, options);
  }

  return numberFormat(min, options) + delimiter + numberFormat(max, options);
};

export const numberRangeUnitFormat = (
  minValue?: string | number | null,
  maxValue?: string | number | null,
  unitString: string | null = '',
  options?: {emptyString?: string; toUpperCase?: boolean; unitSpace?: boolean; delimiter?: string} | null
) => {
  const {emptyString = DEFAULT_EMPTY_STRING, toUpperCase = true, unitSpace = true} = options || {};
  const numberRange = numberRangeFormat(minValue, maxValue, options);

  if (numberRange === emptyString) {
    return numberRange;
  }
  return numberRange + _unitFormat(unitString, toUpperCase, unitSpace);
};
