import {Dayjs} from 'dayjs';

/**
 * Returns the extra waiting time of the competitor in comparison to the subject vessel
 * @param competitorEta
 * @param subjectVesselEta
 * @param laycanFrom
 */
export const getExtraWaitingTimeAfterArrive = ({
  competitorEta,
  subjectVesselEta,
  laycanFrom,
}: {
  competitorEta: Dayjs;
  subjectVesselEta: Dayjs;
  laycanFrom: Dayjs;
}): number => {
  const competiorWaitingForDays = laycanFrom.diff(competitorEta, 'hours') / 24;
  const subjectVesselWaitingForDays = laycanFrom.diff(subjectVesselEta, 'hours') / 24;

  if (competiorWaitingForDays > 0 && subjectVesselWaitingForDays > 0) {
    // both vessels need to wait
    return competiorWaitingForDays - subjectVesselWaitingForDays;
  }
  if (competiorWaitingForDays > 0 && subjectVesselWaitingForDays <= 0) {
    // competitor needs to wait, subject vessel not
    return competiorWaitingForDays;
  }
  if (competiorWaitingForDays <= 0 && subjectVesselWaitingForDays > 0) {
    // subject vessel needs to wait, competitor not
    return -subjectVesselWaitingForDays;
  }

  return 0;
};
