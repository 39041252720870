import {FC, RefObject, useCallback} from 'react';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {PlaceholderMenuPlugin} from './plugins/PlaceholderMenuPlugin';
import {PlaceholderNode} from './nodes/PlaceholderNode';
import styled from 'styled-components';
import {NegotiationTermType} from '../../../../../api/node-backend/generated';
import {TermTypeNode} from './nodes/TermTypeNode';
import {GetTemplateTextPlugin} from './plugins/GetTemplateTextPlugin';
import {$getRoot, BLUR_COMMAND, CLICK_COMMAND, KEY_DOWN_COMMAND, SELECTION_CHANGE_COMMAND} from 'lexical';
import {PlaceholderBar} from './PlaceholderBar';
import {convertTextToLexicalNodes} from './utils/utils';
import {TermTypePlugin} from './plugins/TermTypePlugin';
import {ExtendBlurTargetPlugin} from './plugins/ExtendBlurTargetPlugin';
import {CallbackOnEnterPlugin} from './plugins/CallbackOnEnterPlugin';
import {ArrowKeysCallbackPlugin} from './plugins/ArrowKeysCallbackPlugin';

type TermTemplateEditorProps = {
  containerRef: RefObject<HTMLElement>;
  id: number | string;
  text: string;
  placeholder?: string;
  type: NegotiationTermType | null;
  onChangeText: (newText: string) => void;
  onChangeType: (newType: NegotiationTermType | null) => void;
  onFinishEditing?: (text: string) => void;
  onMoveToPreviousTerm?: () => void;
  onMoveToNextTerm?: () => void;
};

/**
 * A Lexical rich-text editor for term templates with styled placeholders
 */
export const TermTemplateEditor: FC<TermTemplateEditorProps> = ({
  containerRef,
  id,
  text,
  type,
  placeholder,
  onChangeText,
  onChangeType,
  onFinishEditing,
  onMoveToPreviousTerm,
  onMoveToNextTerm,
}) => {
  // We need to initially convert the incoming term to lexical nodes, but hoist the execution inside the composer
  const getConvertedLexicalNodesFromTerm = useCallback(() => {
    return convertTextToLexicalNodes(text, type, onChangeType);
  }, [text, type, onChangeType]);

  return (
    <LexicalComposer
      initialConfig={{
        namespace: 'TermTemplateEditor' + id,
        editable: true,
        nodes: [PlaceholderNode, TermTypeNode],
        editorState: () => {
          $getRoot().append(getConvertedLexicalNodesFromTerm());
        },
        onError: () => {},
      }}>
      <PlainTextPlugin
        contentEditable={<StyledContentEditable />}
        placeholder={<>{placeholder || 'Enter term text...'}</>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <TermTypePlugin />
      <PlaceholderBar termType={type} />
      <PlaceholderMenuPlugin />
      <ExtendBlurTargetPlugin containerRef={containerRef} />
      <GetTemplateTextPlugin
        events={[KEY_DOWN_COMMAND, CLICK_COMMAND, SELECTION_CHANGE_COMMAND, BLUR_COMMAND]}
        callback={onChangeText}
      />
      <CallbackOnEnterPlugin callback={onFinishEditing} />
      <ArrowKeysCallbackPlugin onArrowUp={onMoveToPreviousTerm} onArrowDown={onMoveToNextTerm} />
    </LexicalComposer>
  );
};

const StyledContentEditable = styled(ContentEditable)`
  outline: none;

  p:last-of-type {
    margin: 0;
  }
`;
