import {FC, useRef, useState} from 'react';
import {Modal, ModalWidth} from '../../../../../../../antd/components/Modal';
import {
  NegotiationSummary,
  NegotiationTemplateSummary,
  NegotiationTemplate,
  NewTerm,
} from '../../../../../../../api/node-backend/generated';
import {ContractTemplateSelectorWithPreview} from './ContractTemplateSelectorWithPreview';

type SelectContractTemplateModalProps = {
  negotiation: NegotiationSummary;
  templateSummaries: NegotiationTemplateSummary[];
  open: boolean;
  useGetTemplateDetail: (id?: number) => {data?: NegotiationTemplate};
  onClose: () => void;
  onSuccess: (terms: NewTerm[]) => void;
};

export const SelectContractTemplateModal: FC<SelectContractTemplateModalProps> = ({
  negotiation,
  templateSummaries,
  open,
  useGetTemplateDetail,
  onClose,
  onSuccess,
}) => {
  // Local Ref is used as Modal container to inherit negotiation CSS variables
  // Be careful when using this component outside of NegotiationDetailBody
  const ref = useRef<HTMLDivElement>(null);

  const [terms, setTerms] = useState<NewTerm[]>([]);

  return (
    <div ref={ref}>
      <Modal
        getContainer={ref.current!}
        open={open}
        title={'Use a contract template'}
        width={ModalWidth.Large}
        onCancel={onClose}
        onOk={() => onSuccess(terms)}
        okText={
          terms.length
            ? `Add ${terms.length} term${terms.length === 1 ? '' : 's'} from selected template`
            : `Select a contract template to create terms`
        }
        okButtonProps={{
          disabled: !terms.length,
        }}>
        <ContractTemplateSelectorWithPreview
          negotiation={negotiation}
          templateSummaries={templateSummaries}
          isSmall
          useGetTemplateDetail={useGetTemplateDetail}
          onChangeTerms={setTerms}
        />
      </Modal>
    </div>
  );
};
