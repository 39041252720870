import dayjs from 'dayjs';
import {OtherExpensesInput, OtherExpensesItem} from './OtherExpenses';
import {VesselInput} from './VesselInformation';
import {VoyageInput, VoyageLeg} from './VoyageInformation';

export interface TimeCharterCalculationProp {
  vessel: VesselInput;
  voyage: VoyageInput;
  ifoPrice?: number;
  mdoPrice?: number;
  otherExpenses: OtherExpensesInput;
}
export interface TimeCharterCalculationResult {
  vesselDistancePerDay?: number;
  ballastDays?: number;
  voyage: {
    legs: Array<{
      days: number;
      gross: number;
      net: number;
    }>;
  };
  durationInDays: number;
  netFreight: number;
  bunkerCosts: number;
  totalOtherExpenses: number;
  totalNetFreight?: number;
  totalNetFreightPerDay?: number;
}

export const timeCharterCalculation = (props: TimeCharterCalculationProp): TimeCharterCalculationResult => {
  const {vessel, voyage, ifoPrice, mdoPrice, otherExpenses} = props;
  const result: TimeCharterCalculationResult = {
    voyage: {
      legs: [],
    },
    durationInDays: 1,
    netFreight: 0,
    bunkerCosts: 0,
    totalOtherExpenses: 0,
    totalNetFreight: 0,
    totalNetFreightPerDay: 0,
  };

  if (vessel.ballastSpeed) {
    result.vesselDistancePerDay = 24 * vessel.ballastSpeed;
    if (vessel.ballastDistance) {
      result.ballastDays = vessel.ballastDistance / result.vesselDistancePerDay;
    }
  }

  otherExpenses.items.forEach((item: OtherExpensesItem) => {
    if (!isNaN(item.costs)) {
      result.totalOtherExpenses += -1 * item.costs;
    }
  });

  let minDate = '';
  let maxDate = '';
  voyage.legs.forEach((item: VoyageLeg, index) => {
    if (!minDate || dayjs(item.dateFrom).diff(dayjs(minDate)) < 0) {
      minDate = item.dateFrom;
    }
    if (!maxDate || dayjs(item.dateTo).diff(dayjs(maxDate)) > 0) {
      maxDate = item.dateTo;
    }
    if (result.voyage.legs[index] === undefined) {
      result.voyage.legs[index] = {
        days: 0,
        gross: 0,
        net: 0,
      };
    }
    if (item.dateFrom !== undefined && item.dateTo !== undefined) {
      result.voyage.legs[index].days = dayjs(item.dateTo).diff(dayjs(item.dateFrom), 'days');

      if (item.type === 'on-hire') {
        if (item.hirePerDay !== undefined) {
          result.voyage.legs[index].gross = result.voyage.legs[index].days * item.hirePerDay;
        } else {
          result.voyage.legs[index].gross = 0;
        }
        if (item.commission !== undefined) {
          result.voyage.legs[index].net =
            result.voyage.legs[index].gross - result.voyage.legs[index].gross * (item.commission / 100);
        } else {
          result.voyage.legs[index].net = result.voyage.legs[index].gross;
        }
        result.netFreight += result.voyage.legs[index].net;
      }

      if (item.type === 'waiting') {
        const costIfo = (vessel.waitingConsumptionIFO || 0) * (ifoPrice || 0);
        const costMdo = (vessel.waitingConsumptionMDO || 0) * (mdoPrice || 0);
        const costConsumption = costIfo + costMdo;

        result.voyage.legs[index].net = -1 * result.voyage.legs[index].days * costConsumption;
        result.bunkerCosts += result.voyage.legs[index].net;
      }

      if (item.type === 'ballast') {
        const costIfo = (vessel.ballastConsumptionIFO || 0) * (ifoPrice || 0);
        const costMdo = (vessel.ballastConsumptionMDO || 0) * (mdoPrice || 0);
        const costConsumption = costIfo + costMdo;

        result.voyage.legs[index].net = -1 * result.voyage.legs[index].days * costConsumption;
        result.bunkerCosts += result.voyage.legs[index].net;
      }
    }
  });

  if (minDate && maxDate) {
    result.durationInDays = dayjs(maxDate).diff(dayjs(minDate), 'days');
  }

  result.totalNetFreight = result.netFreight + result.bunkerCosts + result.totalOtherExpenses;
  result.totalNetFreightPerDay = result.totalNetFreight / result.durationInDays;

  return result;
};
