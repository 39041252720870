import {QueryClient, useQuery} from '@tanstack/react-query';
import {annotationApi} from '../../../../api/annotations/annotationsApi';
import {isApiError} from '../../../../api/utils/ApiError';
import {Annotation} from '../../../../api/annotations/generated';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {useUser} from '../../../../components/UserContext/useUser';

const queryKey = 'annotation';

export const useAnnotationQuery = ({imo}: {imo: number}, options?: UseQueryOptions<Annotation | null>) => {
  const user = useUser();
  const userId = user.id;

  return useQuery({
    queryKey: [queryKey, imo],
    queryFn: async () => {
      try {
        return await annotationApi.getAnnotationByIMOAndUserId({
          imo,
          userId: userId!,
        });
      } catch (e) {
        if (isApiError(e)) {
          if (e.status === 404) {
            return null;
          }
        }
        throw e;
      }
    },
    ...options,
  });
};

export const invalidateAnnotationQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [queryKey],
  });
};
