import {Button, ButtonProps} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {CheckCircleFilled} from '@ant-design/icons';

export const AccountSegmentCheckboxButton: FC<{
  checked: boolean;
  isSmall?: boolean;
  dataTestid?: string;
  onClick: ButtonProps['onClick'];
  children: React.ReactNode;
}> = ({checked, isSmall = false, children, dataTestid, onClick}) => {
  return (
    <StyledButton
      block
      data-testid={dataTestid ?? 'AccountSegmentCheckboxButton'}
      onClick={onClick}
      $checked={checked}
      $isSmall={isSmall}>
      <StyledSpan $isSmall={isSmall}>{children}</StyledSpan>
      <StyledCheck>{checked && <CheckCircleFilled data-testid="CheckboxButtonCheck" size={24} />}</StyledCheck>
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{$checked: boolean; $isSmall: boolean}>`
  border: 1px solid ${props => (props.$checked ? 'var(--color-blue)' : 'var(--color-gray-4)')};
  color: var(--color-black) !important;
  padding: 16px;
  height: ${props => (props.$isSmall ? 48 : 92)}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledCheck = styled.div`
  width: 20px;
  height: 20px;
  color: var(--color-blue);

  span {
    font-size: var(--font-size-xl);
  }
`;

const StyledSpan = styled.div<{$isSmall: boolean}>`
  display: flex;
  align-items: center;
  font-weight: ${props => (props.$isSmall ? 500 : 700)};
  font-size: ${props => (props.$isSmall ? 14 : 16)}px;
`;
