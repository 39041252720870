import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {UserWidget} from '../../../api/symfony/generated';
import {InstalledWidget} from '../../../redux/DashboardWidgets/InstalledWidget';
import {WidgetSettings} from '../../../redux/DashboardWidgets/WidgetSettings';
import {useAddWidgetMutation} from '../queries/useAddWidgetMutation';
import {useRemoveWidgetMutation} from '../queries/useRemoveWidgetMutation';
import {UpdateWidgetMutationParams, useUpdateWidgetsMutation} from '../queries/useUpdateWidgetMutation';
import {AvailableWidgetsMap, invalidateWidgetQuery, useWidgetsQuery} from '../queries/useWidgetQuery';
import '../style.scss';
import {AvailableWidget} from '../widgets/utils/availableWidgets';
import {Layout} from 'react-grid-layout';
import {assert} from '../../../utils/assert';
import {useQueryClient} from '@tanstack/react-query';
import produce from 'immer';

/**
 * The minimum screen width that defines a breakpoint.
 */
export const BREAKPOINTS = {md: 1900, xs: 1100, xxs: 0};
/**
 * Number of columns for a given breakpoint.
 */
export const BREAKPOINT_COLUMNS = {md: 8, xs: 4, xxs: 4};

export type Breakpoint = keyof typeof BREAKPOINT_COLUMNS;

export interface WidgetsApi {
  /**
   * Whether we are still readying our API.
   */
  loading: boolean;
  /**
   * The positions and sizes of our widgets.
   */
  layouts: ReactGridLayout.Layouts | undefined;
  installedWidgets: UserWidget[];

  /**
   * Instantiates the given widdget and places it in the grid.
   */
  addWidget: (widget: AvailableWidget) => void;
  removeWidget: (widgetId: number) => void;
  setCurrentBreakpoint: (breakpoint: Breakpoint) => void;
  currentBreakpoint: Breakpoint;

  /**
   * Updates the positions and sizes of multiple widgets.
   */
  updateLayouts: (layouts: ReactGridLayout.Layout[]) => void;
}

export const useWidgetsApi = (): WidgetsApi => {
  const widgetsQuery = useWidgetsQuery();
  const addWidgetsMutation = useAddWidgetMutation();
  const removeWidgetMutation = useRemoveWidgetMutation();
  const updateWidgetsMutation = useUpdateWidgetsMutation();

  const insertWidget = async (installedWidget: InstalledWidget<WidgetSettings>): Promise<UserWidget> => {
    return await addWidgetsMutation.mutateAsync({widget: installedWidget});
  };

  const removeWidget = async (id: number) => {
    // Optimistic update of our state, in order to make the screen update faster
    const newInstalledWidgets = installedWidgets.filter(widget => widget.id !== id);
    assert(widgetsQuery.isSuccess);
    updateAndLayoutInstalledWidgets(newInstalledWidgets, widgetsQuery.data.available);

    // Remove from database
    void removeWidgetMutation.mutate(id);
  };

  const [layouts, setLayouts] = useState<ReactGridLayout.Layouts>();
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>('xs');
  const [installedWidgets, setInstalledWidgets] = useState<UserWidget[]>([]);

  useEffect(() => {
    // Handle initial loading of widgets.
    if (!widgetsQuery.isSuccess) {
      return;
    }

    const newInstalledWidgets = widgetsQuery.data.installed;
    const availableWidgetsMap = widgetsQuery.data.available || {};
    updateAndLayoutInstalledWidgets(newInstalledWidgets, availableWidgetsMap);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [widgetsQuery.data]);

  const updateAndLayoutInstalledWidgets = (
    newInstalledWidgets: UserWidget[],
    availableWidgetsMap: AvailableWidgetsMap
  ) => {
    const newLayouts = layoutWidgets(newInstalledWidgets, availableWidgetsMap);
    if (!isEqual(layouts, newLayouts)) {
      setLayouts(newLayouts);
    }
    setInstalledWidgets(newInstalledWidgets);
  };

  const addWidget = async (availableWidget: AvailableWidget, settings: WidgetSettings = []) => {
    const {posX, posY} = placeAddedWidget(availableWidget, installedWidgets, currentBreakpoint);
    const addedWidget: UserWidget = {
      identifier: availableWidget.identifier,
      width: availableWidget.defaultWidth,
      height: availableWidget.defaultHeight,
      posX,
      posY,
      settings,
    };
    const insertedWidget = await insertWidget(addedWidget);
    const newInstalledWidgets = [...installedWidgets, insertedWidget];
    assert(widgetsQuery.isSuccess);
    const availableWidgetsMap = widgetsQuery.data.available || {};
    updateAndLayoutInstalledWidgets(newInstalledWidgets, availableWidgetsMap);
  };

  const queryClient = useQueryClient();

  /**
   * Updates the positions and sizes of multiple widgets from the given layouts, and also records the layout.
   */
  const updateLayouts = async (newLayouts: ReactGridLayout.Layout[]): Promise<void> => {
    assert(newLayouts.length === installedWidgets.length, 'Must have 1 layout per widget');

    const updatedLayouts: ReactGridLayout.Layouts = produce(layouts!, draft => {
      draft[currentBreakpoint] = newLayouts;
    });
    setLayouts(updatedLayouts);

    const oldInstalledWidgets: UserWidget[] = installedWidgets;
    const newInstalledWidgets: UserWidget[] = updateWidgetsWithLayouts(newLayouts);
    setInstalledWidgets(newInstalledWidgets);
    await saveWidgets(oldInstalledWidgets, newInstalledWidgets);
  };

  const updateWidgetsWithLayouts = (newLayouts: ReactGridLayout.Layout[]): UserWidget[] => {
    const newInstalledWidgets: UserWidget[] = produce(installedWidgets, draftInstalledWidgets => {
      for (const newLayout of newLayouts) {
        const widget = draftInstalledWidgets.find(widget => widget.id?.toString() === newLayout.i);
        if (!widget) {
          continue;
        }

        widget.posX = newLayout.x;
        widget.posY = newLayout.y;
        widget.width = newLayout.w;
        widget.height = newLayout.h;
      }
    });
    return newInstalledWidgets;
  };

  const saveWidgets = async (oldInstalledWidgets: UserWidget[], newInstalledWidgets: UserWidget[]): Promise<void> => {
    assert(oldInstalledWidgets.length === newInstalledWidgets.length, 'Must be the same number of widgets');
    const widgetsNeedingSaving: UpdateWidgetMutationParams[] = [];
    for (let i = 0; i < newInstalledWidgets.length; i++) {
      const oldWidget = oldInstalledWidgets[i];
      const newWidget = newInstalledWidgets[i];
      if (!isEqual(oldWidget, newWidget)) {
        widgetsNeedingSaving.push({id: newWidget.id!, userWidgetWrite: newWidget});
      }
    }

    updateWidgetsMutation.mutate(
      {widgets: widgetsNeedingSaving},
      {
        // Wait until all widgets have been updated before invalidating the widget query.
        onSuccess: async () => {
          await invalidateWidgetQuery(queryClient);
        },
      }
    );
  };

  // We are loading until we have setup our layouts.
  const loading = !widgetsQuery.isSuccess || !layouts;

  return {
    loading,
    installedWidgets,
    layouts,
    addWidget,
    removeWidget,
    setCurrentBreakpoint,
    currentBreakpoint,
    updateLayouts,
  };
};

/**
 * Returns the installedWidgets sorted by y2/x1 coordinate.
 *
 * y2 is the lower right y coordinate.
 * x1 is the upper left x coordinate.
 */
const sortInstalledWidgets = (installedWidgets: UserWidget[]): UserWidget[] => {
  const compare = (a: UserWidget, b: UserWidget): number => {
    // Sort by y2, then x1
    const aPosY2 = a.posY + a.height;
    const bPosY2 = b.posY + b.height;
    const dY2 = aPosY2 - bPosY2;

    if (dY2 !== 0) {
      return dY2;
    }
    const dX1 = a.posX - b.posX;
    if (dX1 !== 0) {
      return dX1;
    }

    // If two different widgets have the same positions, we sort by id to make the order deterministic.
    return (a.id ?? 0) - (b.id ?? 0);
  };
  const sortedInstalledWidgets = [...installedWidgets].sort(compare);
  return sortedInstalledWidgets;
};

/**
 * Decides where to place a newly added widget in the grid.
 */
const placeAddedWidget = (
  widget: AvailableWidget,
  installedWidgets: UserWidget[],
  currentBreakpoint: Breakpoint
): {posX: number; posY: number} => {
  // Sort installed widgets in order to find the "last" widget, which is the one having the highest posY and posX.
  const sortedInstalledWidgets = sortInstalledWidgets(installedWidgets);
  const lastWidget = sortedInstalledWidgets[sortedInstalledWidgets.length - 1];

  const posYs = installedWidgets.map(widget => widget.posY + (widget.height - 1));
  const maxPosY = Math.max(...posYs);
  const lastWidgetWidth = lastWidget?.width || 0;
  const lastWidgetPosX = lastWidget?.posX || 0;
  const lastWidgetPosY = lastWidget?.posY || 0;
  const currentLayoutWidth = BREAKPOINT_COLUMNS[currentBreakpoint];
  const newWidgetFitsNextToLastWidget = lastWidgetWidth + lastWidgetPosX + widget.defaultWidth <= currentLayoutWidth;
  // const newWidgetFitsNextToLastWidget = false;
  const newPosX = newWidgetFitsNextToLastWidget ? lastWidgetWidth + lastWidgetPosX : 0;
  const newPosY = newWidgetFitsNextToLastWidget ? lastWidgetPosY : maxPosY + 1;
  return {posX: newPosX, posY: newPosY};
};

const layoutWidgets = (
  newInstalledWidgets: UserWidget[],
  availableWidgetsMap: AvailableWidgetsMap
): ReactGridLayout.Layouts => {
  // Maps a breakpoint name to an array of widget positions
  const newLayouts: ReactGridLayout.Layouts = {};
  Object.keys(BREAKPOINTS).forEach(breakpoint => {
    newLayouts[breakpoint] = layoutWidgetsForBreakpoint(
      newInstalledWidgets,
      availableWidgetsMap,
      breakpoint as Breakpoint
    );
  });
  return newLayouts;
};

const layoutWidgetsForBreakpoint = (
  installedWidgets: UserWidget[],
  availableWidgetsMap: AvailableWidgetsMap,
  breakpoint: Breakpoint
): Layout[] => {
  const colXXS = BREAKPOINT_COLUMNS['xxs'];
  const isXXS = breakpoint === 'xxs';
  const currentSizeCol = BREAKPOINT_COLUMNS[breakpoint];
  let posY = 0;

  const sortedInstalledWidgets = sortInstalledWidgets(installedWidgets);

  return sortedInstalledWidgets.map(widget => {
    const widgetConfiguration = availableWidgetsMap[widget.identifier as keyof typeof availableWidgetsMap];
    const height = widget.height;
    const width = widget.width;
    // for xxs make all widgets full width and add up posY
    if (isXXS) {
      const widgetConfig = {
        i: widget.id!.toString(),
        x: 0,
        y: posY,
        w: colXXS,
        h: height,
        minW: colXXS,
        maxW: colXXS,
        minH: widgetConfiguration.minHeight,
        maxH: widgetConfiguration.maxHeight,
        isResizable: widgetConfiguration.resizeable,
        isDraggable: widget.identifier !== 'map',
        moved: false,
      };

      posY += height;
      return widgetConfig;
    }

    // limit width and maxWidth to max breakpoint units
    const currentMaxWidth =
      currentSizeCol >= widgetConfiguration.maxWidth ? widgetConfiguration.maxWidth : currentSizeCol;

    const w = width > currentMaxWidth ? currentMaxWidth : width;

    return {
      i: widget.id!.toString(),
      x: widget.posX,
      y: widget.posY,
      w: w,
      h: height,
      minW: widgetConfiguration.minWidth,
      maxW: currentMaxWidth,
      minH: widgetConfiguration.minHeight,
      maxH: widgetConfiguration.maxHeight,
      isResizable: widgetConfiguration.resizeable,
      isDraggable: widget.identifier !== 'map',
      moved: false,
    };
  });
};
