/**
 * Recursively removes any keys with value 'null' or 'undefined' from an object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeNullishProperties = (obj: Record<string, any>): Record<string, any> =>
  Object.keys(obj)
    .filter(k => obj[k] !== null && obj[k] !== undefined) // Remove undef. and null.
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? Object.assign(newObj, {[k]: removeNullishProperties(obj[k])}) // Recurse.
          : Object.assign(newObj, {[k]: obj[k]}), // Copy value.
      {}
    );
