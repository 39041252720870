import produce from 'immer';
import {addNextOpenLegToVoyage} from '../../../utils/addNextOpenLegToVoyage/addNextOpenLegToVoyage';
import {VoyageInput} from '../../../VoyageInformation/VoyageTypes';
import {CargoInput} from '../../../utils/CargoTypes';
import {useContext} from 'react';
import {VoyageCharterContext} from '../../VoyageCharterContext';
import {InputState} from '../../../utils/voyageChartercalculation/voyageChartercalculation';

type SetCargoToCargoInputAndVoyageInputParams = {
  voyageInput: Omit<VoyageInput, 'routesCalculated'> & {routesCalculated?: boolean};
  cargoInput: CargoInput;
};
export type SetCargoToCargoInputAndVoyageInput = (params: SetCargoToCargoInputAndVoyageInputParams) => void;

export const setCargoToCargoInputAndVoyageInput = ({
  setInputState,
  inputState,
  cargoInput,
  voyageInput,
}: {
  inputState: InputState;
  setInputState: (inputState: InputState) => void;
} & SetCargoToCargoInputAndVoyageInputParams) => {
  setInputState(
    produce(inputState, draftState => {
      draftState.voyage = {routesCalculated: false, ...voyageInput};
      draftState.cargo = cargoInput;
      draftState.voyage = {...addNextOpenLegToVoyage(draftState)};
      draftState.voyage.routesCalculated = false;
      return draftState;
    })
  );
};

export const useSetCargoToCargoInputAndVoyageInput = (): SetCargoToCargoInputAndVoyageInput => {
  const {state, setInputState} = useContext(VoyageCharterContext);
  const {inputState} = state;

  return ({voyageInput, cargoInput}) => {
    return setCargoToCargoInputAndVoyageInput({
      voyageInput,
      cargoInput,
      setInputState,
      inputState,
    });
  };
};
