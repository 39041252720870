import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {NamedValue} from '../../FilterConversion/utils/NamedValue';

export type AisStatus = 0 | 1 | 2 | 3 | 5 | 6 | 8;

export interface DatabaseType {
  aisStatus: NamedValue<number>[];
  includeAisStatus: boolean;
}

export interface InternalType {
  aisStatus: NamedValue<number>[];
  includeAisStatus: boolean;
}

export const Defaults: InternalType = {
  aisStatus: [],
  includeAisStatus: false,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {aisStatus, includeAisStatus} = database;
  return {
    aisStatus,
    includeAisStatus: !!includeAisStatus,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {aisStatus, includeAisStatus} = internal;
  return {
    aisStatus,
    includeAisStatus: !!includeAisStatus,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel status',
  branch: 'vesselStatus',
  defaults: Defaults,
  validators: {},
  fromDatabase,
  toDatabase,
});
