import {CompositeLayer, IconLayer, ScatterplotLayer} from 'deck.gl';
import memoize from 'lodash/memoize';
import {COLORS} from '../const';
import iconAtlas from './icons/icon-atlas.png';
import iconMapping from './icons/icon-mapping.json';
import {SWITCHES} from '../mapOptions/switches';

export const TA_LABEL_ZOOM_LEVEL = 3;

const defaultMinimumZoomLevel = 8;

export const contractCommodityFilter = (switches, cargoes) => {
  if (
    switches[SWITCHES.CARGO_CONTRACT_TYPE_SUB] !== undefined &&
    switches[SWITCHES.CARGO_COMMODITIES_SUB] !== undefined
  ) {
    if (Object.values(switches[SWITCHES.CARGO_CONTRACT_TYPE_SUB]).find(i => i)) {
      cargoes = cargoes.filter(c => switches[SWITCHES.CARGO_CONTRACT_TYPE_SUB][c.contractType]);
    }

    if (Object.values(switches[SWITCHES.CARGO_COMMODITIES_SUB]).find(i => i)) {
      cargoes = cargoes.filter(c => switches[SWITCHES.CARGO_COMMODITIES_SUB][c.commodityCategory]);
    }
  }

  return cargoes;
};

const findPorts = memoize((ids, ports) => ports.filter(p => ids.includes(p.id)));

class PortLayer extends CompositeLayer {
  getPickingInfo({info, mode, sourceLayer}) {
    if (info.object) {
      if (mode === 'hover' && sourceLayer.props.noTooltip) {
        info.noTooltip = true;
      }

      // Trading area label
      if (info.object.type === 'Feature') {
        return {
          ...info,
          object: {
            ...info.object.properties,
            name: this.props.switches[SWITCHES.TRADING_AREA_NAMES].state
              ? info.object.properties.name
              : `${info.object.properties.name} (${info.object.properties.code})`,
          },
          polygon: mode === 'hover' ? undefined : info.object.geometry.coordinates[0],
          noHighlight: true,
        };
      }
    }

    return info;
  }

  renderLayers() {
    const layers = [];
    const {id, zoom, ports, smallPorts, largePorts, switches, showPortIds, showIcon} = this.props;

    const minimumZoomLevel = this.props.minimumZoomLevel ?? defaultMinimumZoomLevel;
    const showPorts = showPortIds ? findPorts(showPortIds ?? [], ports) : [];

    const portsToShow = [...showPorts];

    // Without this check if showPortIds is null, it will show all ports in every situation
    if (!showPortIds && switches[SWITCHES.M_PORT].state) {
      portsToShow.push(...largePorts, ...smallPorts);
    }

    if (showIcon) {
      layers.push(
        new IconLayer({
          id: `${id}-search-anchor`,
          data: portsToShow,
          getIcon: () => (switches[SWITCHES.LAYER_SATELLITE].state ? 'anchor-white' : 'anchor-yellow'),
          getPosition: d => d.coordinates,
          visible: zoom > minimumZoomLevel,
          getSize: 32,
          pickable: true,
          iconAtlas,
          iconMapping,
          opacity: 1,
        })
      );
      layers.push(
        new ScatterplotLayer({
          id: `${id}-search-point`,
          data: portsToShow,
          getFillColor: COLORS.PORT_POINT_FILL,
          getPosition: d => d.coordinates,
          visible: zoom <= minimumZoomLevel,
          opacity: 1,
          pickable: true,
          getRadius: 64,
          radiusScale: 32,
          radiusMinPixels: 1,
          radiusMaxPixels: 3,
          stroked: false,
        })
      );
    } else {
      layers.push(
        new ScatterplotLayer({
          id: `${id}-search-point`,
          data: portsToShow,
          getFillColor: COLORS.PORT_POINT_FILL,
          getPosition: d => d.coordinates,
          opacity: 1,
          pickable: true,
          getRadius: 64,
          radiusScale: 32,
          radiusMinPixels: 1,
          radiusMaxPixels: 3,
          stroked: false,
        })
      );
    }

    return layers;
  }
}

PortLayer.layerName = 'PortLayer';
export default PortLayer;
