import {FC} from 'react';
import LoadingComponent from '../../../components/DataGrid/LoadingComponent';
import NoResults from '../../market/EmptyState/NoResults';
import {vesselBulkerGrid, vesselContainerGrid, VesselGridOptions, vesselMppGrid} from '../../../components/GridLayout';
import {VesselGridLayoutMode} from '../../../redux/Portfolio';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {VesselDatabaseActions} from '../../../redux/VesselDatabase';
import {actionsColumn} from './actionsColumn';
import {shipBuilderColumn} from '../../../components/GridLayout/columns/vessel/shipBuilderColumn';
import {managementManagingOwnerColumn} from '../../../components/GridLayout/columns/vessel/managementManagingOwnerColumn';
import {engineColumn} from '../../../components/GridLayout/columns/vessel/engineColumn';
import {SeaboVessel} from '../../../api/node-backend/generated';
import {imoColumn} from '../../../components/GridLayout/columns/vessel/imoColumn';
import {CustomColumnDef, DataGrid} from '../../../components/DataGrid/DataGrid';
import {FeatureToggles} from '../../../utils/FeatureToggles';
import {ColumnSort} from '@tanstack/react-table';
import {useIsPermitted} from '../../../utils/useIsPermitted';
import {FeaturePermission} from '../../../api/symfony/generated';

interface VesselDatabaseGridProps {
  loading: boolean;
  vessels: SeaboVessel[] | null;
  vesselsTotal: number;
  displayedLayout: VesselGridLayoutMode;
}

export const VesselDatabaseGrid: FC<VesselDatabaseGridProps> = ({vessels, vesselsTotal, displayedLayout, loading}) => {
  const {pageSize, pageIndex, sortingRule} = useSelector(state => state.vesselDatabase);

  const dispatch = useDispatch();
  const setPageIndex = (pageIndex: number) => {
    dispatch(VesselDatabaseActions.setPageIndex(pageIndex));
  };
  const setPageSize = (pageSize: number) => {
    dispatch(VesselDatabaseActions.setPageSize(pageSize));
  };
  const setSortingRule = (sortingRule: ColumnSort) => {
    dispatch(VesselDatabaseActions.setSortingRule(sortingRule));
  };

  const displayEmptyState = () => {
    if (loading || !vessels) {
      return <LoadingComponent style={{textAlign: 'center'}} />;
    }
    if (vessels.length) {
      return;
    }
    return <NoResults type={'vessels'} />;
  };

  const accessFullVesselDbPermitted = useIsPermitted(FeaturePermission.AccessFullVesselDb);
  const blurred = !accessFullVesselDbPermitted;
  const gridLayoutColumns = getGridLayoutColumns({gridLayout: displayedLayout, blurred});

  const additionalColumns = [
    imoColumn({}),
    managementManagingOwnerColumn({options: {blurred}}),
    shipBuilderColumn({options: {blurred}}),
    engineColumn({options: {blurred}}),
  ];

  const columns = [...gridLayoutColumns, ...additionalColumns, actionsColumn] as CustomColumnDef<SeaboVessel>[];

  return (
    <DataGrid<SeaboVessel>
      className={'vessel-database-grid'}
      data-testid="VesselDatabaseGrid"
      showPageSizeOptions={true}
      showJumper={true}
      totalCount={vesselsTotal}
      pageSize={pageSize}
      page={pageIndex}
      onPageChange={pageIndex => {
        setPageIndex(pageIndex);
      }}
      onPageSizeChange={pageSize => {
        setPageSize(pageSize);
        setPageIndex(1);
      }}
      columns={columns}
      data={vessels || []}
      loading={loading || vessels === null}
      zeroBasedPageIndex={false}
      TbodyComponent={displayEmptyState()}
      onSortedChange={sorted => {
        setSortingRule(sorted);
      }}
      noHoverStatePointer
      unroundedTopLeft
      unroundedTopRight
      sorted={[sortingRule]}
    />
  );
};

const getGridLayoutColumns = ({
  gridLayout,
  blurred,
}: {
  gridLayout: VesselGridLayoutMode;
  blurred: boolean;
}): CustomColumnDef<SeaboVessel>[] => {
  const gridOptions: VesselGridOptions = {
    shipNameColumn: {
      isLink: FeatureToggles.newVesselDatabaseDetailsPage,
    },
    grainColumn: {
      blurred,
    },
    gearsColumn: {
      blurred,
    },
    lengthColumn: {
      blurred,
    },
    draftColumn: {
      blurred,
    },
  };

  if (gridLayout === 'bulker') {
    return vesselBulkerGrid('vessel-database', gridOptions);
  } else if (gridLayout === 'mpp') {
    return vesselMppGrid('vessel-database', gridOptions);
  } else {
    return vesselContainerGrid('vessel-database', gridOptions);
  }
};
