import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {parserApi} from '../../../../../api/parser/parserApi';
import {JSONAllMultiData} from '../../../../../api/parser/generated';
import {ApiError} from '../../../../../api/utils/ApiError';

export type ParseMailMutationParams = {
  text: string;
};

export const useParseMailMutation = (
  options?: UseMutationOptions<JSONAllMultiData, ApiError, ParseMailMutationParams, void>
) =>
  useMutation({
    mutationFn: ({text}: ParseMailMutationParams): Promise<JSONAllMultiData> => {
      return parserApi.parse({
        query: {
          text,
        },
      });
    },
    ...options,
  });
