import React, {useContext, useEffect, useState} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {Input} from 'antd';
import {useCalcName} from '../utils/useCalcName';
import styled from 'styled-components';

const Name = styled.span`
  font-size: var(--font-size-md);
  font-weight: 700;
  cursor: pointer;
`;

export const CalculatorName = () => {
  const voyageContext = useContext(VoyageCharterContext);
  const calcName = useCalcName();
  const {inputState} = voyageContext.state;
  const setInputState = voyageContext.setInputState;

  const [showInput, setShowInput] = useState(false);
  const [textInput, setTextInput] = useState(inputState.calculator.name);
  useEffect(() => {
    setTextInput(inputState.calculator.name);
  }, [inputState.calculator.name]);

  if (showInput) {
    return (
      <div style={{display: 'inline-block'}}>
        <Input
          onBlur={() => {
            setShowInput(false);
            setInputState({...inputState, calculator: {...inputState.calculator, name: textInput}});
          }}
          value={textInput}
          onChange={v => {
            setTextInput(v.currentTarget.value);
          }}
        />{' '}
      </div>
    );
  } else {
    return (
      <Name
        style={{cursor: 'pointer'}}
        onClick={() => {
          setShowInput(true);
        }}>
        {calcName}
      </Name>
    );
  }
};
