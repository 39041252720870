/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AccountCompanyPrimaryManager,
  AccountCompanyPrimaryManagerFromJSON,
  AccountCompanyPrimaryManagerToJSON,
  Company,
  CompanyFromJSON,
  CompanyToJSON,
  CompanyCreatedByResponse,
  CompanyCreatedByResponseFromJSON,
  CompanyCreatedByResponseToJSON,
  CompanyEdit,
  CompanyEditFromJSON,
  CompanyEditToJSON,
  CompanyList,
  CompanyListFromJSON,
  CompanyListToJSON,
  CompanyMemberResponse,
  CompanyMemberResponseFromJSON,
  CompanyMemberResponseToJSON,
  CompanyMetaData,
  CompanyMetaDataFromJSON,
  CompanyMetaDataToJSON,
  CompanyStartPromotionResponse,
  CompanyStartPromotionResponseFromJSON,
  CompanyStartPromotionResponseToJSON,
  CompanyStatistics,
  CompanyStatisticsFromJSON,
  CompanyStatisticsToJSON,
  CreateCompany,
  CreateCompanyFromJSON,
  CreateCompanyToJSON,
  InviteToCompanyResponse,
  InviteToCompanyResponseFromJSON,
  InviteToCompanyResponseToJSON,
  Invitee,
  InviteeFromJSON,
  InviteeToJSON,
  Member,
  MemberFromJSON,
  MemberToJSON,
  UpdateCompanyMembersResponse,
  UpdateCompanyMembersResponseFromJSON,
  UpdateCompanyMembersResponseToJSON,
  UpdateCompanyPaymentStatus,
  UpdateCompanyPaymentStatusFromJSON,
  UpdateCompanyPaymentStatusToJSON,
  ValidationProblem,
  ValidationProblemFromJSON,
  ValidationProblemToJSON,
} from '../models';

export interface CreateCompanyRequest {
  createCompany: CreateCompany;
}

export interface GetCompaniesRequest {
  filters?: string;
}

export interface GetCompanyRequest {
  id: number;
}

export interface GetCompanyCreatedByRequest {
  company: number;
}

export interface GetCompanyMembersRequest {
  company: number;
  listHiddenMembersIfPossible?: boolean;
}

export interface GetCompanyPrimaryCompanyManagerRequest {
  company: number;
}

export interface GetCompanyStatisticsRequest {
  company: number;
}

export interface InviteToCompanyRequest {
  company: number;
  invitee: Array<Invitee>;
}

export interface RemoveCompanyMembersRequest {
  company: number;
  member: Array<Member>;
}

export interface UpdateCompanyRequest {
  id: number;
  companyEdit: CompanyEdit;
}

export interface UpdateCompanyMembersRequest {
  company: number;
  member: Array<Member>;
}

export interface UpdateCompanyPaymentStatusRequest {
  company: number;
  updateCompanyPaymentStatus: UpdateCompanyPaymentStatus;
}

/**
 *
 */
export class CompanyApi extends runtime.BaseAPI {
  /**
   */
  async companyStartPromotionRaw(): Promise<runtime.ApiResponse<CompanyStartPromotionResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/start-promotion`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyStartPromotionResponseFromJSON(jsonValue));
  }

  /**
   */
  async companyStartPromotion(): Promise<CompanyStartPromotionResponse> {
    const response = await this.companyStartPromotionRaw();
    return await response.value();
  }

  /**
   */
  async createCompanyRaw(requestParameters: CreateCompanyRequest): Promise<runtime.ApiResponse<Company>> {
    if (requestParameters.createCompany === null || requestParameters.createCompany === undefined) {
      throw new runtime.RequiredError(
        'createCompany',
        'Required parameter requestParameters.createCompany was null or undefined when calling createCompany.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/companies`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateCompanyToJSON(requestParameters.createCompany),
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyFromJSON(jsonValue));
  }

  /**
   */
  async createCompany(requestParameters: CreateCompanyRequest): Promise<Company> {
    const response = await this.createCompanyRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getCompaniesRaw(requestParameters: GetCompaniesRequest): Promise<runtime.ApiResponse<CompanyList>> {
    const queryParameters: any = {};

    if (requestParameters.filters !== undefined) {
      queryParameters['filters'] = requestParameters.filters;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/companies`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyListFromJSON(jsonValue));
  }

  /**
   */
  async getCompanies(requestParameters: GetCompaniesRequest): Promise<CompanyList> {
    const response = await this.getCompaniesRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getCompanyRaw(requestParameters: GetCompanyRequest): Promise<runtime.ApiResponse<Company>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getCompany.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyFromJSON(jsonValue));
  }

  /**
   */
  async getCompany(requestParameters: GetCompanyRequest): Promise<Company> {
    const response = await this.getCompanyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get primary manager of a company.
   */
  async getCompanyCreatedByRaw(
    requestParameters: GetCompanyCreatedByRequest
  ): Promise<runtime.ApiResponse<CompanyCreatedByResponse>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling getCompanyCreatedBy.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/created-by`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyCreatedByResponseFromJSON(jsonValue));
  }

  /**
   * Get primary manager of a company.
   */
  async getCompanyCreatedBy(requestParameters: GetCompanyCreatedByRequest): Promise<CompanyCreatedByResponse> {
    const response = await this.getCompanyCreatedByRaw(requestParameters);
    return await response.value();
  }

  /**
   * List members of a company.
   */
  async getCompanyMembersRaw(
    requestParameters: GetCompanyMembersRequest
  ): Promise<runtime.ApiResponse<CompanyMemberResponse>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling getCompanyMembers.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.listHiddenMembersIfPossible !== undefined) {
      queryParameters['listHiddenMembersIfPossible'] = requestParameters.listHiddenMembersIfPossible;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/members`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyMemberResponseFromJSON(jsonValue));
  }

  /**
   * List members of a company.
   */
  async getCompanyMembers(requestParameters: GetCompanyMembersRequest): Promise<CompanyMemberResponse> {
    const response = await this.getCompanyMembersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get primary manager of a company.
   */
  async getCompanyPrimaryCompanyManagerRaw(
    requestParameters: GetCompanyPrimaryCompanyManagerRequest
  ): Promise<runtime.ApiResponse<AccountCompanyPrimaryManager>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling getCompanyPrimaryCompanyManager.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/primary-company-manager`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AccountCompanyPrimaryManagerFromJSON(jsonValue));
  }

  /**
   * Get primary manager of a company.
   */
  async getCompanyPrimaryCompanyManager(
    requestParameters: GetCompanyPrimaryCompanyManagerRequest
  ): Promise<AccountCompanyPrimaryManager> {
    const response = await this.getCompanyPrimaryCompanyManagerRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get company statistics.
   */
  async getCompanyStatisticsRaw(
    requestParameters: GetCompanyStatisticsRequest
  ): Promise<runtime.ApiResponse<CompanyStatistics>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling getCompanyStatistics.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/statistics`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyStatisticsFromJSON(jsonValue));
  }

  /**
   * Get company statistics.
   */
  async getCompanyStatistics(requestParameters: GetCompanyStatisticsRequest): Promise<CompanyStatistics> {
    const response = await this.getCompanyStatisticsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Invite new members to a company.
   */
  async inviteToCompanyRaw(
    requestParameters: InviteToCompanyRequest
  ): Promise<runtime.ApiResponse<InviteToCompanyResponse>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling inviteToCompany.'
      );
    }

    if (requestParameters.invitee === null || requestParameters.invitee === undefined) {
      throw new runtime.RequiredError(
        'invitee',
        'Required parameter requestParameters.invitee was null or undefined when calling inviteToCompany.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/members`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.invitee.map(InviteeToJSON),
    });

    return new runtime.JSONApiResponse(response, jsonValue => InviteToCompanyResponseFromJSON(jsonValue));
  }

  /**
   * Invite new members to a company.
   */
  async inviteToCompany(requestParameters: InviteToCompanyRequest): Promise<InviteToCompanyResponse> {
    const response = await this.inviteToCompanyRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listCompaniesWithMetaDataRaw(): Promise<runtime.ApiResponse<Array<CompanyMetaData>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/meta-data`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(CompanyMetaDataFromJSON));
  }

  /**
   */
  async listCompaniesWithMetaData(): Promise<Array<CompanyMetaData>> {
    const response = await this.listCompaniesWithMetaDataRaw();
    return await response.value();
  }

  /**
   * Remove company members.
   */
  async removeCompanyMembersRaw(
    requestParameters: RemoveCompanyMembersRequest
  ): Promise<runtime.ApiResponse<CompanyMemberResponse>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling removeCompanyMembers.'
      );
    }

    if (requestParameters.member === null || requestParameters.member === undefined) {
      throw new runtime.RequiredError(
        'member',
        'Required parameter requestParameters.member was null or undefined when calling removeCompanyMembers.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/members`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.member.map(MemberToJSON),
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyMemberResponseFromJSON(jsonValue));
  }

  /**
   * Remove company members.
   */
  async removeCompanyMembers(requestParameters: RemoveCompanyMembersRequest): Promise<CompanyMemberResponse> {
    const response = await this.removeCompanyMembersRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateCompanyRaw(requestParameters: UpdateCompanyRequest): Promise<runtime.ApiResponse<Company>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateCompany.'
      );
    }

    if (requestParameters.companyEdit === null || requestParameters.companyEdit === undefined) {
      throw new runtime.RequiredError(
        'companyEdit',
        'Required parameter requestParameters.companyEdit was null or undefined when calling updateCompany.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: CompanyEditToJSON(requestParameters.companyEdit),
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyFromJSON(jsonValue));
  }

  /**
   */
  async updateCompany(requestParameters: UpdateCompanyRequest): Promise<Company> {
    const response = await this.updateCompanyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update company members.
   */
  async updateCompanyMembersRaw(
    requestParameters: UpdateCompanyMembersRequest
  ): Promise<runtime.ApiResponse<UpdateCompanyMembersResponse>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling updateCompanyMembers.'
      );
    }

    if (requestParameters.member === null || requestParameters.member === undefined) {
      throw new runtime.RequiredError(
        'member',
        'Required parameter requestParameters.member was null or undefined when calling updateCompanyMembers.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/members`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.member.map(MemberToJSON),
    });

    return new runtime.JSONApiResponse(response, jsonValue => UpdateCompanyMembersResponseFromJSON(jsonValue));
  }

  /**
   * Update company members.
   */
  async updateCompanyMembers(requestParameters: UpdateCompanyMembersRequest): Promise<UpdateCompanyMembersResponse> {
    const response = await this.updateCompanyMembersRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateCompanyPaymentStatusRaw(
    requestParameters: UpdateCompanyPaymentStatusRequest
  ): Promise<runtime.ApiResponse<UpdateCompanyPaymentStatus>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling updateCompanyPaymentStatus.'
      );
    }

    if (
      requestParameters.updateCompanyPaymentStatus === null ||
      requestParameters.updateCompanyPaymentStatus === undefined
    ) {
      throw new runtime.RequiredError(
        'updateCompanyPaymentStatus',
        'Required parameter requestParameters.updateCompanyPaymentStatus was null or undefined when calling updateCompanyPaymentStatus.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/updateCompanyPaymentStatus`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateCompanyPaymentStatusToJSON(requestParameters.updateCompanyPaymentStatus),
    });

    return new runtime.JSONApiResponse(response, jsonValue => UpdateCompanyPaymentStatusFromJSON(jsonValue));
  }

  /**
   */
  async updateCompanyPaymentStatus(
    requestParameters: UpdateCompanyPaymentStatusRequest
  ): Promise<UpdateCompanyPaymentStatus> {
    const response = await this.updateCompanyPaymentStatusRaw(requestParameters);
    return await response.value();
  }
}
