import {RadioGroupProps, CheckboxOptionType, Radio, Button} from 'antd';
import {ButtonProps} from 'antd/es';
import {FC, ReactNode} from 'react';
import Icon, {IconType} from '../../atoms/Icon';
import styled from 'styled-components';

export const ScreenFeatureButton: FC<ButtonProps> = props => {
  return <FeatureButton {...props} />;
};

const FeatureButton = styled(Button)`
  &.ant-btn {
    min-width: 100px;
    text-align: center;
    padding-inline: 20px;
    text-transform: initial;
    font-weight: normal !important;
  }
`;

export type ScreenFeatureToggleProps = Omit<RadioGroupProps, 'children' | 'options' | 'optionType' | 'buttonType'> & {
  options: Array<
    Omit<CheckboxOptionType, 'label'> & {label?: ReactNode; icon?: IconType; props?: Record<string, string | number>}
  >;
};

export const ScreenFeatureToggle: FC<ScreenFeatureToggleProps> = ({options, ...props}) => {
  return (
    <Radio.Group optionType="button" buttonStyle="solid" {...props}>
      {options.map(option =>
        typeof option === 'string' || typeof option === 'number' ? (
          <RadioButton value={option}>{option}</RadioButton>
        ) : (
          <RadioButton value={option.value} {...option.props}>
            <Label>
              {option.icon && <Icon type={option.icon} color={props.value === option.value ? 'white' : 'gray'} />}
              {option.label}
            </Label>
          </RadioButton>
        )
      )}
    </Radio.Group>
  );
};

const Label = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  > * {
    line-height: 1;
  }
`;

const RadioButton = styled(Radio)`
  .ant-radio-group-solid & {
    min-width: 100px;
    text-align: center;
    padding-inline: 20px;
  }
  .ant-radio-group-solid &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--color-azure);
  }
  .ant-radio-group-solid &:not(.ant-radio-button-wrapper-checked) {
    background: var(--color-gray-5);
    color: var(--color-gray-1);
  }
`;
