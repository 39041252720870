import {VesselType} from '../../../../api/symfony/generated/models';
import {CargoContractType} from '../../../../atoms/CargoHexagon/CargoHexagon';
import Cargo from '../../../../model/Cargo';
import Port from '../../../../model/Port';
import Station from '../../../../model/Station';
import {TODO} from '../../../../utils/TODO';
import {AISDetails} from '../../../ProjectDetailsScreen/types';
import {LAYERS} from '../../const';
import {PortLayer} from '../../layers';
import {PickingInfo} from '@deck.gl/core/typed';
import {IconLayer} from '@deck.gl/layers/typed';
import {MarketCargoInternalFilter} from '../../../FilterProvider/Filters/Market/MarketCargoFilterDefinition';
import {MarketVesselInternalFilter} from '../../../FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';

export type MapDetailItemViewState = 'market' | 'portfolio' | 'all' | 'none';
export type MapDetailItemView = {
  vessels: MapDetailItemViewState;
  cargoes: MapDetailItemViewState;
};

export type MapDetailData = Record<string, Record<string, TODO>>;

export type MapFilters = {
  cargoFilters?: MarketCargoInternalFilter | null;
  vesselFilters?: MarketVesselInternalFilter | null;
};

export type MapFiltersQueryParams = {
  cargoFilters?: MarketCargoInternalFilter;
  vesselFilters?: MarketVesselInternalFilter;
};

export type MapSwitch = {
  [key: string]: TODO;
  state?: boolean;
  vState?: boolean;
  vDisabled?: boolean;
};

export type MapSwitchesKey = keyof MapSwitches;

export type MapSwitches = {
  cargo_commodities: MapSwitch;
  cargo_commodities_sub: Record<string, boolean>;
  cargo_contract_type: MapSwitch;
  cargo_contract_type_sub: MapSwitch & {
    vc: boolean;
    tct: boolean;
    pc: boolean;
    bc: boolean;
  };
  cargo_market: MapSwitch;
  cargo_on?: MapSwitch;
  cargo_portfolio: MapSwitch;
  cargo_portfolio_sub: MapSwitch & Record<number, boolean>;
  layer_eca: MapSwitch;
  layer_piracy: MapSwitch;
  layer_satellite: MapSwitch;
  layer_trading_area: MapSwitch;
  m_port: MapSwitch;
  m_vessel: MapSwitch;
  m_cargo: MapSwitch;
  m_layer: MapSwitch;
  trading_area_names: MapSwitch;
  vessel_ais: MapSwitch & {
    filters: Record<string, string | string[]>;
  };
  vessel_ais_sub: MapSwitch & {
    bulker: boolean;
    container: boolean;
    tanker: boolean;
    mpp: boolean;
    other: boolean;
  };
  vessel_market: MapSwitch;
  vessel_on?: MapSwitch;
  vessel_portfolio: MapSwitch;
  vessel_portfolio_sub: MapSwitch & Record<number, boolean>;
};

/**
 * - If bool, set switch state to bool
 * - If null, invert state of current switch
 * - If object, replace switch object
 */
export type MapOptionChangeValue = boolean | null | Record<string, boolean>;

export type MapElement = PickingInfo & {
  noHighlight?: boolean;
  polygon?: TODO[];
  layer?: {
    id?: string;
  };
  mapElement?: TODO;
};

export interface MapPortElement {
  index: number;
  object: MapPortElementObject;
  layer: PortLayer;
  color: Uint8Array;
  coordinate?: [lon: number, lat: number];
  devicePixel: [x: number, y: number];
  lngLat?: [lon: number, lat: number];
  picked: boolean;
  pixel: [x: number, y: number];
  pixelRatio: number;
  x: number;
  y: number;
}

export type MapPortlessElement = {
  layer: {id: typeof LAYERS.PORTLESS};
  portlessAreas: MapDetailArea[];
};

export type MapAreaElement = PickingInfo & {
  object: {
    type: 'Feature';
    properties: {
      id: number;
      name: string;
      code: string;
      coordinates: [lon: number, lat: number];
      amountCargoes: number;
      amountVessels: number;
      market: AreaItemInfo;
      portfolio: AreaItemInfo;
    };
  };
};

type AreaItemInfo = {
  amountCargoes: number;
  amountVessels: number;
  portlessAmountCargoes: number;
  portlessAmountVessels: number;
  cargoes: TODO[];
  vessels: TODO[];
};

export type MapVesselElement = {
  index: number;
  object: MapVesselElementObject;
  layer: IconLayer;
  color: number;
  coordinate: [lon: number, lat: number];
  devicePixel: [x: number, y: number];
  lngLat: [lon: number, lat: number];
  picked: boolean;
  pixel: [x: number, y: number];
  pixelRatio: number;
  x: number;
  y: number;
};

export const isMapPortElement = (element: TODO): element is MapPortElement => element?.layer?.id === LAYERS.PORT;

export const isMapPortlessElement = (element: TODO): element is MapPortlessElement =>
  element?.layer?.id === LAYERS.PORTLESS;

export const isMapAreaElement = (element: TODO): element is MapAreaElement => element.layer.id === LAYERS.TRADING_AREA;

const VESSEL_LAYER_IDS = [LAYERS.AIS, LAYERS.PORTFOLIO_VESSEL];

export const isMapVesselElement = (element: TODO): element is MapVesselElement =>
  VESSEL_LAYER_IDS.includes(element?.layer?.id);

export type MapElementObject = MapPortElementObject | MapVesselElementObject;

export type MapPortElementObject = {
  code: string;
  coordinates: [lon: number, lat: number];
  country: string;
  id: number;
  isLarge: boolean;
  circulars: {
    id: number;
    amountCargoes: number;
    amountVessels: number;
    market: {
      amountCargoes: number;
      amountVessels: number;
      cargoes: Cargo[];
    };
    portfolio: {
      amountCargoes: number;
      amountVessels: number;
      cargoes: Cargo[];
      vesselIds: number[];
    };
  };
  name: string;
  type: string;
  points: [lon: number, lat: number][];
};

export type MapVesselElementObject = {
  aliasName: string;
  beam: number;
  callsign: string;
  class: string;
  coordinates: [lat: number, lon: number];
  country: string;
  course: number;
  destination: string;
  destinationArea: number;
  destinationPort: number;
  draught: number;
  eta: string;
  heading: number;
  imo: number;
  latitude: number;
  loa: number;
  location: string;
  longitude: number;
  mmsi: number;
  name: string;
  type: string;
};

export type PortAreaCV = {
  market: MapDetailItems;
  portfolio: MapDetailItems;
};

export type MapDetailItems = {
  cargoes: MapDetailItemList<DetailCargo>;
  vessels: MapDetailItemList<DetailVessel>;
};

export type MapDetailItemList<ItemType extends MapDetailItem> = {
  items: ItemType[];
  totalItems: number;
  pageSize?: number;
  pageIndex?: number;
};

export type MapDetailItem = DetailCargo | DetailVessel;

export type MapDetailItemType = 'cargo' | 'vessel';

export type MapDetailArea = {
  id: number;
  name: string;
  code: string;
};

export type MapDetailPort = Port & {
  type: string;
  lat: number;
  lon: number;
};

/**
 * Corresponds to AisVesselDetailsApiModel in the PHP backend.
 *
 * Should be somewhat compatible to the corresponding type that has been generated for the frontend.
 */
export type MapDetailVesselType = {
  beam: number;
  class: string;
  coordinates: string;
  country: string;
  course: number | null;
  currentArea: MapDetailArea | null;
  currentPort: MapDetailPort | null;
  destination: string;
  destinationArea: MapDetailArea;
  destinationPort: MapDetailPort | null;
  draught: number;
  dwtSummer: number;
  eta: string;
  heading: number | null;
  imo: number;
  lastArea: MapDetailArea | null;
  lastPort: MapDetailPort | null;
  latitude: number;
  loa: number;
  longitude: number;
  mmsi: number;
  name: string;
  postime: string;
  speed: number;
  /**
   * AIS status.
   *
   * E.g. "under way using engine".
   */
  statusText: string | null;
  teu14Quantity: number;
  teuQuantity: number;
  vesselId?: number;
};

export type DetailCargo = {
  cargoType: string | null;
  chartererName: string | null;
  color: TODO;
  comment: TODO;
  commissionAddress: number | null;
  commissionBrokerage: number | null;
  commissionTotal: TODO;
  commodity: TODO;
  commodityCategory: string | null;
  commodityStowageFactor?: TODO;
  commodityStowageFactorUnit?: TODO;
  commodityType: TODO;
  company: TODO;
  confidenceScore: number | null;
  contractType: CargoContractType;
  createdAt: string | null;
  documents: [];
  durationMin?: number | null;
  durationMax?: number | null;
  durationAbout?: number | null;
  durationUnit?: number | null;
  gear: TODO;
  id: number;
  intakes: [];
  isArchived: false;
  laycanFrom: string | null;
  laycanTo: string | null;
  maxVesselAge: TODO;
  name: TODO;
  offer: TODO;
  quantityMax?: TODO;
  quantityMin?: TODO;
  quantityOption?: TODO;
  quantityUnit?: TODO;
  reeferPlugs: TODO;
  stations: Station[];
  target: string | null;
  teuQuantity: TODO;
  tolerance?: TODO;
  toleranceUnit?: TODO;
  updatedAt: string | null;
  user: TODO;
  vesselType?: VesselType;
  vesselSubtype?: TODO;
  vesselSizeMin?: TODO;
  vesselSizeMax?: TODO;
  vesselSizeUnit?: TODO;
  gears?: [];
  logFitted?: TODO;
  lakesFitted?: TODO;
  co2Fitted?: TODO;
  itfFitted?: TODO;
  tweenDecks?: TODO;
  boxShape?: TODO;
  openHatch?: TODO;
  a60Bulkhead?: TODO;
  cellular?: TODO;
  bowThrust?: TODO;
  sternThrust?: TODO;
  iceClass?: TODO;
  scrubberFitted?: TODO;
};

export type DetailVessel = {
  aisDetails?: AISDetails;
  bale: TODO;
  baleUnit: TODO;
  beam: number | null;
  builtYear: number | null;
  callSign: string | null;
  charterName: TODO;
  color: TODO;
  comment: TODO;
  company: TODO;
  confidenceScore: TODO;
  consumptions: TODO[];
  countryFlag: string | null;
  createdAt: string | null;
  currentService?: TODO;
  designSubType: string | null;
  designType: string | null;
  displayed?: boolean | null;
  documents: TODO[];
  draft: number | null;
  dryDockFrom: TODO;
  dryDockNote: TODO;
  dryDockTo: TODO;
  dwtSummer: number | null;
  exName: string | null;
  featureA60Bulkhead: TODO;
  featureBowThrust: TODO;
  featureBoxShape: TODO;
  featureCellular: TODO;
  featureCo2Fitted: TODO;
  featureItfFitted: TODO;
  featureLakesFitted: TODO;
  featureLogFitted: TODO;
  featureOpenHatch: TODO;
  featureScrubberFitted: TODO;
  featureSternThrust: TODO;
  featureTweenDecks: TODO;
  fixtureCount: number | null;
  fixtures: TODO[];
  gears: TODO[];
  grain: TODO;
  grainUnit: TODO;
  grossTonnage: number | null;
  hatchesAmount: TODO;
  holdsAmount: TODO;
  iceClass: TODO;
  id: number;
  images: [];
  imo?: number | null;
  intakes: TODO[];
  isArchived: boolean | null;
  lengthOverall: number | null;
  managementDisponentOwner: TODO;
  managementHeadOwner: TODO;
  managementManagingOwner: TODO;
  mmsi: number | null;
  name: string | null;
  netTonnage: number | null;
  offer?: TODO;
  rate: TODO;
  rateCurrency: TODO;
  rateTerms: TODO;
  redeliveryNotice: TODO;
  reeferPlugs: TODO;
  reviewed?: boolean | null;
  selectedFixture: TODO;
  shipBuilder: string | null;
  spotState?: boolean | null;
  spotDate?: Date | string | null;
  nextOpenFrom?: string | null;
  nextOpenTo?: string | null;
  stations: TODO[];
  status: string | null;
  target: string | null;
  teuQuantity?: number;
  teu14Quantity?: number;
  tonnesPerCentimetre: TODO;
  updatedAt: string | null;
  user: TODO;
  usesFixtures: boolean | null;
  vesselType: VesselType;
};
