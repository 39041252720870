import {useQuery} from '@tanstack/react-query';
import {z} from 'zod';

export const useStowageFactorsQuery = () => {
  return useQuery({
    queryKey: ['stowageFactors'],
    queryFn: async (): Promise<StowageFactor[]> => {
      const response = await fetch('/static/jsons/stowageFactors.json');

      const rawFactors = await response.json();

      const factorsWithValue = rawFactors.filter((item: {stowageFactor: unknown}) => item.stowageFactor !== null);

      const factors = factorsWithValue.map((item: unknown) => StowageFactorSchema.parse(item));

      return factors;
    },
  });
};

export const useStowageFactorsSearchQuery = (search: string) => {
  const storageFactorsQuery = useStowageFactorsQuery();
  return useQuery({
    queryKey: ['searchStowageFactors', search],
    queryFn: async (): Promise<StowageFactor[]> => {
      const factors = storageFactorsQuery.data || [];

      const filteredFactors = factors.filter(item => {
        const searchableString = item.name.toLowerCase() + ' ' + item.stowageFactor;

        return searchableString.includes(search.toLowerCase());
      });

      return filteredFactors;
    },
    enabled: storageFactorsQuery.isSuccess && search.length > 0,
  });
};

const StowageFactorSchema = z.object({
  name: z.string(),
  stowageFactor: z.number(),
});

export type StowageFactor = z.infer<typeof StowageFactorSchema>;
