import {Col, Form, InputNumber, Row, Select} from 'antd';
import {FC} from 'react';
import {MarketCargoOffer} from '../../../MarketCargo/MarketCargoOffer';

export const DurationFormItem: FC<{cargoOffer: MarketCargoOffer}> = ({cargoOffer}) => {
  const hasDuration = 'durationMin' in cargoOffer.cargo;

  if (!hasDuration) {
    return null;
  }

  return (
    <Form.Item label={'Duration'}>
      <Row gutter={[4, 4]} data-testid={'DurationFormItem'}>
        <Col span={8}>
          <Form.Item
            rules={[{min: 0, type: 'number', message: 'Min duration cannot be less than 0.'}]}
            name={'durationMin'}
            style={{marginBottom: 0}}>
            <InputNumber placeholder={'min duration'} style={{width: '100%'}} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={[{min: 0, type: 'number', message: 'Max duration cannot be less than 0.'}]}
            name={'durationMax'}
            style={{marginBottom: 0}}>
            <InputNumber placeholder={'max duration'} style={{width: '100%'}} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'durationUnit'} style={{marginBottom: 0}}>
            <Select style={{width: '100%'}}>
              <Select.Option value={'days'}>days</Select.Option>
              <Select.Option value={'weeks'}>weeks</Select.Option>
              <Select.Option value={'months'}>months</Select.Option>
              <Select.Option value={'years'}>years</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};
