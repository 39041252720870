import {Dropdown, Menu, MenuProps, message} from 'antd';
import {useGoToUrl} from '../../../../utils/useChangeBrowserUrl';
import {OneCalcResult} from './oneCalc';
import {Icon} from '../../../../atoms/Icon';
import {isApiError} from '../../../../api/utils/ApiError';
import {projectApi} from '../../../../api/symfony/symfonyApi';

type Props = {
  oneCalcResult: OneCalcResult;
};

export const ActionDropdown = (props: Props) => {
  const {oneCalcResult} = props;
  const goToUrl = useGoToUrl();

  const menuItems: MenuProps['items'] = [
    ...(oneCalcResult.vesselProject
      ? [
          {
            key: 'viewVesselProject',
            onClick: () => {
              goToUrl(`/projects/${oneCalcResult.vesselProject!.id}`);
            },
            label: 'View vessel project',
          },
        ]
      : []),
    ...(oneCalcResult.cargoProject
      ? [
          {
            key: 'viewVesselProject',
            onClick: () => {
              goToUrl(`/projects/${oneCalcResult.cargoProject!.id}`);
            },
            label: 'View cargo project',
          },
        ]
      : []),
    {
      key: 'viewVessel',
      onClick: () => {
        goToUrl(`/vessel/${oneCalcResult.vessel.id}`);
      },
      label: 'View vessel',
    },
    {
      key: 'viewCargo',
      onClick: () => {
        goToUrl(`/cargo/${oneCalcResult.cargo.id}`);
      },
      label: 'View cargo',
    },
    ...(oneCalcResult.vesselProject
      ? [
          {
            key: 'addToVesselProject',
            onClick: async () => {
              try {
                await projectApi.addCargoToProject({
                  requestBody: [oneCalcResult.cargo.id],
                  project: oneCalcResult.vesselProject!.id!.toString()!,
                });
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.success('Cargo added to Project');
              } catch (e) {
                if (isApiError(e)) {
                  if (e.status === 409) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.error('Cargo is already in project.');
                  }
                } else {
                  throw e;
                }
              }
            },
            label: 'Add cargo to vessel project',
          },
        ]
      : []),
    ...(oneCalcResult.cargoProject
      ? [
          {
            key: 'addToCargoProject',
            onClick: async () => {
              try {
                await projectApi.addVesselToProject({
                  requestBody: [oneCalcResult.vessel.id],
                  project: oneCalcResult.cargoProject!.id!.toString()!,
                });
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.success('Vessel added to project');
              } catch (e) {
                if (isApiError(e)) {
                  if (e.status === 409) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.error('Vessel is already in project.');
                  }
                } else {
                  throw e;
                }
              }
            },
            label: 'Add cargo to cargo project',
          },
        ]
      : []),
  ];

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: '1',
            label: <Menu items={menuItems} />,
          },
        ],
      }}>
      <Icon type={'more-vert'} style={{cursor: 'pointer'}} />
    </Dropdown>
  );
};
