import anime from 'animejs';
import {RefObject} from 'react';
import {AnimationEvent} from '../model/AnimationEvent';
import {MapRef} from 'react-map-gl';
import {lineString} from '@turf/helpers';
import {geoBounds} from 'd3-geo';
import {assert, assertUnreachable} from '../../../../../utils/assert';
import {FeatureToggles, VoyageAnimationAnimatedPortVisits} from '../../../../../utils/FeatureToggles';
import {ZOOM_LEVEL_FOR_SEA_VOYAGE_INSIDE_PORT, ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT} from './ZoomLevel';
import {atBegin} from './easing';
import dayjs from 'dayjs';

export const animatePortEntry = ({
  animationEventIndex,
  animationEvents,
  animationSpeed,
  timeline,
  mapRef,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
  mapRef: RefObject<MapRef>;
}) => {
  const portEntryEvent = animationEvents[animationEventIndex];
  assert(portEntryEvent.type === 'port-entry');

  switch (FeatureToggles.voyageAnimationAnimatedPortVisits) {
    case VoyageAnimationAnimatedPortVisits.Disabled:
      animationEventIndex++;
      return animationEventIndex;
    case VoyageAnimationAnimatedPortVisits.Safe:
      return animatePortEntrySafe({
        animationEventIndex,
        animationEvents,
        animationSpeed,
        timeline,
        mapRef,
      });
    case VoyageAnimationAnimatedPortVisits.Old:
      return animatePortEntryOld({
        animationEventIndex,
        animationEvents,
        animationSpeed,
        timeline,
        mapRef,
      });
    case VoyageAnimationAnimatedPortVisits.New:
      return animatePortEntryNew({
        animationEventIndex,
        animationEvents,
        animationSpeed,
        timeline,
        mapRef,
      });
    default:
      assertUnreachable(FeatureToggles.voyageAnimationAnimatedPortVisits);
  }
};

const animatePortEntrySafe = ({
  animationEventIndex,
  animationEvents,
  timeline,
  mapRef,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
  mapRef: RefObject<MapRef>;
}) => {
  const portEntryEvent = animationEvents[animationEventIndex];
  assert(portEntryEvent.type === 'port-entry');
  animationEventIndex++;

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log(
        'PORT-Entry IN',
        portEntryEvent.id,
        portEntryEvent.portVisit.port.name,
        portEntryEvent.timestamp.toString(),
        dayjs().toString()
      );
    },
    duration: 100,
  });
  timeline.add({
    cameraFollowsVessel: 0,
    portVisitId: portEntryEvent.portVisit.id,
    duration: 100,
    easing: atBegin,
  });
  timeline.add({
    delay: 10,
    begin() {
      const polygon = portEntryEvent.portVisit.port.polygon[0];
      const linestring = lineString(polygon);
      const bounds = geoBounds(linestring);
      mapRef.current!.fitBounds(bounds, {
        padding: {top: 60, bottom: 60, left: 60, right: 60},
      });
    },
    duration: 3000,
  });
  // Show port polygon:
  timeline.add({
    portAreaVibility: 1,
    easing: 'linear',
    duration: 2000,
  });
  // Hide port polygon:
  timeline.add({
    portAreaVibility: 0,
    easing: 'linear',
    duration: 1000,
  });

  // Zoom out / fly to vessel
  timeline.add({
    delay: 10,
    begin() {
      mapRef.current!.flyTo({
        zoom: ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT,
        duration: 1000,
      });
      // }
    },
    duration: 1200,
  });
  timeline.add({
    cameraFollowsVessel: 1,
    portVisitId: -1,
    duration: 10,
    easing: atBegin,
  });

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log(
        'PORT-Entry OUT',
        portEntryEvent.id,
        portEntryEvent.portVisit.port.name,
        portEntryEvent.timestamp?.toString(),
        dayjs().toString()
      );
    },
    duration: 100,
  });

  return animationEventIndex;
};

const animatePortEntryNew = ({
  animationEventIndex,
  animationEvents,
  timeline,
  mapRef,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
  mapRef: RefObject<MapRef>;
}) => {
  const portEntryEvent = animationEvents[animationEventIndex];
  assert(portEntryEvent.type === 'port-entry');
  animationEventIndex++;

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log('PORT ENTRY');
    },
    cameraFollowsVessel: 0,
    portVisitId: portEntryEvent.portVisit.id,
    duration: 100,
    easing: atBegin,
  });
  timeline.add({
    delay: 10,
    begin() {
      const polygon = portEntryEvent.portVisit.port.polygon[0];
      const linestring = lineString(polygon);
      const bounds = geoBounds(linestring);
      mapRef.current!.fitBounds(bounds, {
        padding: {top: 60, bottom: 60, left: 60, right: 60},
      });
    },
    duration: 3000,
  });
  // Show port polygon:
  timeline.add({
    portAreaVibility: 1,
    easing: 'linear',
    duration: 2000,
  });
  // Hide port polygon:
  timeline.add({
    portAreaVibility: 0,
    easing: 'linear',
    duration: 1000,
  });
  timeline.add({
    delay: 200,
  });

  // Move camera back to vessel:
  timeline.add({
    begin() {
      mapRef.current!.flyTo({
        zoom: ZOOM_LEVEL_FOR_SEA_VOYAGE_INSIDE_PORT,
        maxDuration: 1000,
        center: portEntryEvent.vesselCoordinates,
      });
    },
    cameraFollowsVessel: 1,
    duration: 1100,
    easing: atBegin,
  });

  return animationEventIndex;
};

const animatePortEntryOld = ({
  animationEventIndex,
  animationEvents,
  timeline,
  mapRef,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
  mapRef: RefObject<MapRef>;
}) => {
  const portEntryEvent = animationEvents[animationEventIndex];
  assert(portEntryEvent.type === 'port-entry');
  animationEventIndex++;

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log('PORT ENTRY');
    },
    cameraFollowsVessel: 0,
    portVisitId: portEntryEvent.portVisit.id,
    duration: 100,
    easing: atBegin,
  });
  timeline.add({
    delay: 10,
    begin() {
      const polygon = portEntryEvent.portVisit.port.polygon[0];
      const linestring = lineString(polygon);
      const bounds = geoBounds(linestring);
      // eslint-disable-next-line no-console
      console.log('zoom in');
      mapRef.current!.fitBounds(bounds, {
        padding: {top: 60, bottom: 60, left: 60, right: 60},
      });
    },
    duration: 3000,
  });
  // Show port polygon:
  timeline.add({
    portAreaVibility: 1,
    easing: 'linear',
    duration: 2000,
  });
  // Hide port polygon:
  timeline.add({
    portAreaVibility: 0,
    easing: 'linear',
    duration: 1000,
  });

  return animationEventIndex;
};
