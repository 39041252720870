/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {ProjectCargo, ProjectCargoFromJSON, ProjectCargoFromJSONTyped, ProjectCargoToJSON} from './';

/**
 *
 * @export
 * @interface AddCargoesToProjectViaOffersResponse
 */
export interface AddCargoesToProjectViaOffersResponse {
  /**
   *
   * @type {number}
   * @memberof AddCargoesToProjectViaOffersResponse
   */
  offersSentCount: number;
  /**
   *
   * @type {number}
   * @memberof AddCargoesToProjectViaOffersResponse
   */
  cargoesAddedCount: number;
  /**
   *
   * @type {Array<ProjectCargo>}
   * @memberof AddCargoesToProjectViaOffersResponse
   */
  items: Array<ProjectCargo>;
}

export function AddCargoesToProjectViaOffersResponseFromJSON(json: any): AddCargoesToProjectViaOffersResponse {
  return AddCargoesToProjectViaOffersResponseFromJSONTyped(json, false);
}

export function AddCargoesToProjectViaOffersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddCargoesToProjectViaOffersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    offersSentCount: json['offersSentCount'],
    cargoesAddedCount: json['cargoesAddedCount'],
    items: (json['items'] as Array<any>).map(ProjectCargoFromJSON),
  };
}

export function AddCargoesToProjectViaOffersResponseToJSON(value?: AddCargoesToProjectViaOffersResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    offersSentCount: value.offersSentCount,
    cargoesAddedCount: value.cargoesAddedCount,
    items: (value.items as Array<any>).map(ProjectCargoToJSON),
  };
}
