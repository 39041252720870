import React, {FC} from 'react';
import {MapVesselIcon} from '../MapVesselIcon';
import {vesselMenu, VesselTypeKey} from '../utils/vesselMenu';
import {LegendBox} from './LegendBox';

export const VesselLegend: FC<{
  vesselIconStyle: string;
}> = ({vesselIconStyle}) => {
  return (
    <LegendBox
      title={'Vessels'}
      listItems={Object.keys(vesselMenu).map(vesselTypeKey => {
        const menuKey = vesselTypeKey as VesselTypeKey;
        return (
          <MapVesselIcon
            iconCSS={{transform: 'rotate(35deg)'}}
            vesselIconStyle={vesselIconStyle}
            type={menuKey}
            label={vesselMenu[menuKey]}
          />
        );
      })}
    />
  );
};
