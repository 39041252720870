import {StorageItem} from './prepareForTheMomentOfConsumption';

export const calculateCosts = ({storageItems, quantity}: {storageItems: StorageItem[]; quantity: number}): number => {
  const internalStorageItems = [...storageItems];

  let totalCosts = 0;
  let quantityToConsume = quantity;
  while (quantityToConsume > 0) {
    const topOfStack = internalStorageItems[internalStorageItems.length - 1];
    if (topOfStack.quantity >= quantityToConsume) {
      totalCosts += topOfStack.price * quantityToConsume;
      topOfStack.quantity -= quantityToConsume;
      quantityToConsume = 0;
      break;
    }

    totalCosts += topOfStack.price * topOfStack.quantity;
    quantityToConsume -= topOfStack.quantity;
    internalStorageItems.pop();
  }

  return totalCosts;
};
