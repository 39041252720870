/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {GearTypeEnum, GearTypeEnumFromJSON, GearTypeEnumFromJSONTyped, GearTypeEnumToJSON} from './';

/**
 *
 * @export
 * @interface Gear
 */
export interface Gear {
  /**
   * The technical id
   * @type {number}
   * @memberof Gear
   */
  id: number;
  /**
   *
   * @type {GearTypeEnum}
   * @memberof Gear
   */
  type: GearTypeEnum;
  /**
   *
   * @type {number}
   * @memberof Gear
   */
  quantity: number;
  /**
   * The gear's capacity in tons
   * @type {number}
   * @memberof Gear
   */
  capacity: number | null;
  /**
   * The gear's type and optionally also combined capacity
   * @type {string}
   * @memberof Gear
   */
  description?: string;
}

export function GearFromJSON(json: any): Gear {
  return GearFromJSONTyped(json, false);
}

export function GearFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gear {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: GearTypeEnumFromJSON(json['type']),
    quantity: json['quantity'],
    capacity: json['capacity'],
    description: !exists(json, 'description') ? undefined : json['description'],
  };
}

export function GearToJSON(value?: Gear | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: GearTypeEnumToJSON(value.type),
    quantity: value.quantity,
    capacity: value.capacity,
    description: value.description,
  };
}
