import {Collapse} from 'antd';
import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import Icon, {IconType} from '../../../../atoms/Icon';
import {UserDateTime} from '../../../../components/DateTime/UserDateTime';
import {TimelineEventColor, getEventColor} from '../utils/utils';
import {voyageTimelineFilterLabels} from '../utils/filters';
import {VesselVoyageTimelineEventType} from '../../../../api/node-backend/generated';
import {TimelineEvent} from '../utils/types';
import {SubEventList} from './SubEventList';
import dayjs from 'dayjs';

type TimelineItemContainerProps = {
  itemRef: React.RefObject<HTMLLIElement>;
  index?: number;
  id: string;
  startDate: Date;
  endDate: Date;
  markerIcon: IconType;
  type: VesselVoyageTimelineEventType;
  isBallast?: boolean;
  header: ReactNode;
  content?: ReactNode;
  subEvents?: TimelineEvent[];
  description?: string | ReactNode;
  margin?: number;
  isReduced?: boolean;
  isSelected?: boolean;
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: () => void;
  onSelectSubEvent?: (id: TimelineEvent | null) => void;
  'data-testid'?: string;
};

export const TimelineItemContainer: FC<TimelineItemContainerProps> = ({
  itemRef,
  index,
  id,
  startDate,
  endDate,
  markerIcon,
  type,
  isBallast,
  header,
  content,
  subEvents,
  description,
  isReduced,
  isSelected,
  margin = 0,
  selectedEvent,
  onSelectEvent,
  onSelectSubEvent,
  'data-testid': dataTestId,
}) => {
  const color = getEventColor(type);
  const ballastColor = isBallast ? 'red' : 'green';
  const duration = dayjs.duration(dayjs(endDate).diff(startDate)).asDays().toFixed(2);
  const isNow = dayjs(endDate).isSame(dayjs().endOf('day'));

  return (
    <TimelineListItem id={id} $color={color} $isSelected={isSelected} ref={itemRef} data-testid={dataTestId}>
      <TimelinePart $color={isBallast !== undefined ? ballastColor : undefined} />
      <TimelineMarker onClick={onSelectEvent}>
        <TimelineItemIcon type={markerIcon} color="white-fixed" size="small" />
        <TimelineItemDate>
          {voyageTimelineFilterLabels[type]}:
          <span>
            <UserDateTime value={startDate.toISOString()} format="dateTime" />
          </span>
          {endDate && (
            <>
              {!isReduced && (
                <span> - {isNow ? 'now' : <UserDateTime value={endDate.toISOString()} format="dateTime" />}</span>
              )}
              <span> ({duration})</span>
            </>
          )}
        </TimelineItemDate>
        {description}
        {index && <TimelineIndex># -{index}</TimelineIndex>}
      </TimelineMarker>
      <TimelineItemContent
        id={id}
        header={header}
        content={content}
        subEvents={subEvents}
        margin={margin}
        selectedEvent={selectedEvent}
        onSelectEvent={onSelectSubEvent}
      />
    </TimelineListItem>
  );
};

const TimelineListItem = styled.li<{
  id: string;
  $color?: TimelineEventColor;
  $isSelected?: boolean;
  children: ReactNode;
  ref: React.RefObject<HTMLLIElement>;
}>`
  --color: var(--timeline-color-${({$color}) => $color});
  --color-bg: var(--timeline-color-${({$color}) => $color}-bg);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  width: 100%;
  background: var(--color-bg);
  border-radius: var(--border-radius-tab);
  outline: var(--border-transparent);
  box-shadow: var(--box-shadow-card);
  z-index: 2;
  cursor: pointer;

  &:first-of-type ${() => TimelinePart} {
    top: 10px;
    border-radius: var(--timeline-width) var(--timeline-width) 0 0;
  }

  &:last-of-type ${() => TimelinePart} {
    bottom: 0;
  }

  ${({$isSelected}) => $isSelected && `outline: 6px solid var(--color);`}
`;

const TimelineIndex = styled.div`
  height: 16px;
  padding: 0 5px;
  margin-left: 0;
  margin-right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-lg);
  font-size: var(--font-size-sm);
  font-weight: bold;
  background: var(--color-white);
  color: var(--color);
  z-index: 3;
`;

export const TimelinePart = styled.div<{$color?: string}>`
  position: absolute;
  left: calc(
    (
        var(--timeline-marker-margin) + var(--timeline-marker-width) - var(--timeline-marker-radius) +
          (var(--timeline-width) / 2)
      ) * -1
  );
  top: calc(var(--timeline-gap) / 2 * -1);
  bottom: calc(var(--timeline-gap) / 2 * -1);
  width: var(--timeline-width);
  background: ${({$color}) => ($color ? `var(--color-${$color})` : 'var(--color)')};
  z-index: 0;
`;

export const TimelineMarker = styled.div<{children: ReactNode; onClick?: () => void}>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  height: calc(var(--timeline-marker-radius) * 2);
  width: 100%;
  padding: 0 var(--timeline-marker-radius);
  z-index: 2;
  background: var(--color);
  border-radius: var(--border-radius-tab) var(--border-radius-tab) 0 0;
  overflow: hidden;
`;

const TimelineItemIcon = styled(Icon)`
  margin-left: -7px;
`;

const TimelineItemDate = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  height: 100%;
  padding: var(--timeline-marker-padding) 0;
  margin-left: 0;
  margin-right: auto;
  font-size: var(--font-size);
  font-weight: bold;
  color: var(--color-white);
  line-height: 1;
`;

type TimelineItemContentProps = {
  id: string;
  header: ReactNode;
  content?: ReactNode;
  subEvents?: TimelineEvent[];
  margin?: number;
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: (id: TimelineEvent | null) => void;
};

const TimelineItemContent: FC<TimelineItemContentProps> = ({
  id,
  header,
  content,
  subEvents,
  selectedEvent,
  onSelectEvent,
  margin = 0,
}) => {
  return (
    <TimelineContentContainer $margin={margin}>
      <TimelineItemContentCollapse
        ghost
        expandIconPosition={'end'}
        items={[
          {
            key: id,
            label: header,
            showArrow: !!content,
            collapsible: content ? 'icon' : 'disabled',
            children: content,
          },
        ]}
      />
      {subEvents?.length ? (
        <SubEventList events={subEvents} selectedEvent={selectedEvent} onSelectEvent={onSelectEvent} />
      ) : null}
    </TimelineContentContainer>
  );
};

const TimelineContentContainer = styled.div<{$margin?: number; children: ReactNode}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: var(--timeline-marker-radius);
  padding-left: calc(var(--timeline-marker-radius) + 12px);
  margin: 3px ${({$margin}) => ($margin ? `${$margin}px` : '0')} 5px 0;
`;

const TimelineItemContentCollapse = styled(Collapse)`
  .ant-collapse-header {
    width: 100%;
    padding-left: 5px !important;
  }
  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 10px 0 0;
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
  }
`;
