import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../api/utils/fetchJSON';

type Project = {
  id: number;
  name: string;
};

export const useProjectsQuery = ({projectType}: {projectType: 'cargo' | 'vessel'}) => {
  return useQuery({
    queryKey: ['projects', projectType],
    queryFn: async () => {
      const response = await fetchJSON<{data: {items: Project[]}}>(`/api/projects?type=${projectType}`);

      return response.data.items;
    },
  });
};
