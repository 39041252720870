import {useNavigate, useParams} from 'react-router-dom';
import ProjectService from '../../redux/ApiService/ProjectService';
import {ModalActions} from '../../redux/Modal';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {parseIntTS} from '../../utils/parseNumberTS';
import {Project} from './types';
import {useDeleteProjectMutation} from './useDeleteProjectMutation';
import {ReactNode} from 'react';
import CreateProject from '../Modal/CreateProject';
import {Delete} from '../Modal/Delete/DeleteModal';
import {Archive} from '../Modal/Archive';

type RouteParams = 'id';

export const useProjectDetails = () => {
  const match = useParams<RouteParams>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = parseIntTS(match.id);
  const loading = useSelector(state => state.api.project.getProject.loading);
  const project = useSelector(state => state.projects.project);

  const getProject = () => dispatch(ProjectService.getProject(id));
  const updateProject = (body: Partial<Project>) => dispatch(ProjectService.updateProject(id, body));
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const deleteProject = useDeleteProjectMutation({
    onSuccess: () => navigate('/projects'),
  });

  const onEdit = () => {
    showModal(<CreateProject siteType={true} project={project} />);
  };

  const onDelete = () => {
    showModal(
      <Delete
        target={{
          name: project.name,
          id: project.id,
        }}
        onDelete={(id: number, _: unknown, close: () => void) => {
          deleteProject.mutate(id);
          close();
        }}
      />
    );
  };

  const onArchive = () => {
    showModal(
      <Archive
        type="project"
        target={project}
        update={async (id: number, body: Project, close: () => void, setError: (error: boolean) => void) => {
          try {
            await updateProject(body);
            close();
          } catch (e) {
            setError(true);
          }
        }}
      />
    );
  };

  const onFavorite = () => {
    updateProject({isSticky: !project.isSticky});
  };

  return {
    project,
    loading,
    getProject,
    updateProject,
    onEdit,
    onDelete,
    onArchive,
    onFavorite,
  };
};
