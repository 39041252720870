import React, {FC} from 'react';
import BaseModal from '../../../atoms/BaseModal';
import Title from '../../../atoms/Title';
import {OfferMailView} from '../../OfferMailModal/OfferMailView';
import {RawMessage, useOfferQuery} from './useOfferQuery';

const RawMessageView: FC<{rawMessage: RawMessage}> = ({rawMessage}) => {
  return (
    <div style={{textAlign: 'left'}}>
      <OfferMailView
        offerMail={{
          ...rawMessage,
          extractedText: rawMessage.text,
        }}
      />
    </div>
  );
};

interface Props {
  offerId: number;
  type: 'vessel' | 'cargo';
}

const ShowRawMessageModal = ({offerId, type}: Props) => {
  const query = useOfferQuery(offerId, type);

  if (!query.isSuccess) {
    return null;
  }

  const rawMessage = query.data.rawMessage;

  if (!rawMessage) {
    return <BaseModal title="">{() => <Title medium> There is no original message for this offer</Title>}</BaseModal>;
  }

  return <BaseModal title="Original email">{() => <RawMessageView rawMessage={rawMessage} />}</BaseModal>;
};

export default ShowRawMessageModal;
