import {useUser} from '../../components/UserContext/useUser';
import {assert} from '../../utils/assert';

export const useIsOwnCompany = ({companyId}: {companyId: number}): boolean => {
  const user = useUser();
  const currentUser = user.payload;

  const companyIdOfCurrentUser = currentUser?.company.id;

  assert(companyIdOfCurrentUser);

  return companyIdOfCurrentUser === companyId;
};
