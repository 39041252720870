import React, {FC} from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import {WorkspaceIcon} from './WorkspaceIcon';
import {Workspace} from './WorkspaceSelectTypes';
import {Spacer} from '../Spacer/Spacer';
import Icon from '../Icon';

/**
 * This is the button that displays the current value and triggers the popover.
 */

export const WorkspaceSelectButtonWide = styled(Button)`
  width: 176px;
  font-weight: bold;
  border: var(--border-width) solid transparent;
  box-shadow: var(--box-shadow-inset);

  &.WorkspaceSelectButton--BlueOnGrey {
    color: var(--color-gray-2);
    background: var(--color-gray-6);
    border-color: var(--border-color);

    svg {
      fill: var(--color-blue);
    }
  }

  &.WorkspaceSelectButton--WhiteOnBlue {
    color: var(--color-white-fixed);
    background: var(--color-blue);

    &:hover {
      color: var(--color-white-fixed) !important;
    }

    .icon {
      color: var(--color-white-fixed) !important;
    }

    svg {
      fill: var(--color-white-fixed);
    }
  }
`;

/**
 * The label that displays the current value.
 */
export const ButtonLabelWide: FC<{workspace: Workspace}> = ({workspace}) => (
  <ButtonLabelStyled>
    {workspace.id !== 0 && (
      <>
        <div style={{width: 22, height: 22}}>
          <WorkspaceIcon />
        </div>
        <Spacer width={9} />
      </>
    )}
    <TitleWrapper>
      <Title>{workspace.name}</Title>
    </TitleWrapper>
    <ArrowIcon />
  </ButtonLabelStyled>
);

const ButtonLabelStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & > * {
    flex-shrink: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  overflow: hidden;
  text-align: left;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: -8px;
`;

const ArrowIcon = () => (
  <IconWrapper>
    <Icon type="angle-down" size="small" />
  </IconWrapper>
);
