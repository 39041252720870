import {makeApiService} from './ApiService';

const entries = [
  {fn: 'getTradingAreas', route: '/api/trading-areas', method: 'GET', storeData: false},
  {fn: 'getTradingArea', route: '/api/trading-areas/{id}', method: 'GET'},
  {fn: 'updateTradingArea', route: '/api/trading-areas/{id}', method: 'PATCH'},
];

const tradingAreaApi = makeApiService(entries, {apiName: 'tradingArea'});

export const reducers = tradingAreaApi.reducers;
export const actions = tradingAreaApi.actions;
export const actionTypes = tradingAreaApi.actionTypes;
