import {FC} from 'react';
import styled from 'styled-components';
import {
  NegotiationAttachment,
  NegotiationRoundResult,
  NegotiationRoundResultSummary,
  NegotiationStatus,
  NegotiationSubjectStatus,
  NegotiationSummary as NegotiationSummaryType,
  NegotiationTermStatus,
} from '../../../../api/node-backend/generated';
import {SeaboLogoWithText} from '../../../../atoms/SeaboLogo/SeaboLogo';
import {NegotiationActorString} from '../../Components/NegotiationActorLabel';
import {termTypeLabels} from './TermItem/Components/TermType/termTypeLabels';
import {getSubjectPrintStatusLabel} from './Subjects/utils/getSubjectStatusLabel';
import {NegotiationLastReply} from '../../Components/NegotiationLastReply';

const getNegotiationStatusLabel = (status: NegotiationStatus, hasUnsettledSubjects: boolean) => {
  switch (status) {
    case NegotiationStatus.Recap:
      return hasUnsettledSubjects ? 'Negotiation Recap on Subjects' : 'Clean Negotiation Recap';
    case NegotiationStatus.Cancelled:
      return 'Cancelled Negotiation';
    default:
      return 'Negotiation';
  }
};

type NegotiationPrintExportProps = {
  printContainerRef: React.RefObject<HTMLDivElement>;
  negotiation: NegotiationSummaryType;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  currentRoundResult?: NegotiationRoundResult;
  attachments: NegotiationAttachment[];
};

export const NegotiationPrintExport: FC<NegotiationPrintExportProps> = ({
  printContainerRef,
  negotiation,
  roundResultSummaries,
  currentRoundResult,
  attachments,
}) => {
  const {title, status, creator, counterpart} = negotiation;

  if (!roundResultSummaries || !currentRoundResult) {
    return null;
  }

  const subjects = currentRoundResult.subjects.filter(subject =>
    [NegotiationSubjectStatus.Accepted, NegotiationSubjectStatus.Lifted, NegotiationSubjectStatus.Failed].includes(
      subject.status
    )
  );
  const hasUnsettledSubjects = subjects.some(subject => subject.status === NegotiationSubjectStatus.Failed);
  const terms = currentRoundResult.terms.filter(term => term.status !== NegotiationTermStatus.Rejected);

  const showSubjects = status === NegotiationStatus.Recap;
  const titleLabel = getNegotiationStatusLabel(status, hasUnsettledSubjects);

  return (
    <NegotiationPrintExportContainer ref={printContainerRef}>
      <SeaboHeader>
        <SeaboLogoWithText style={{height: 24, display: 'inline-block'}} />
      </SeaboHeader>
      <h1>
        {titleLabel}: {title}
      </h1>
      <p>
        Last change: <NegotiationLastReply roundResultSummaries={roundResultSummaries} format="dateTime" />
      </p>
      <div>
        <h2>Parties:</h2>
        <ul>
          <li>
            <NegotiationActorString actor={creator} />
          </li>
          <li>
            <NegotiationActorString actor={counterpart} />
          </li>
        </ul>
      </div>
      {attachments.length > 0 && (
        <div>
          <h2>Attachments:</h2>
          <ul>
            {attachments.map(attachment => (
              <li key={attachment.id}>{attachment.title}</li>
            ))}
          </ul>
        </div>
      )}
      {showSubjects && (
        <div>
          <h2>Subjects:</h2>
          <ul>
            {subjects.map(subject => (
              <li key={subject.id}>
                <b>[{getSubjectPrintStatusLabel(subject.status)}]</b>
                {' - '}
                <NegotiationActorString actor={negotiation[subject.party]} />: <br />
                {subject.text}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div>
        <h2>Terms:</h2>
        <ol>
          {terms.map(term => (
            <li key={term.id}>
              {term.type ? `${termTypeLabels[term.type]} - ` : ''}
              {term.text}
            </li>
          ))}
        </ol>
      </div>
    </NegotiationPrintExportContainer>
  );
};

const NegotiationPrintExportContainer = styled.div`
  display: none;
  flex-direction: column;
  padding: 20px 40px;
  color: var(--color-black);

  h1 {
    font-size: var(--font-size-xl);
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: var(--font-size-lg);
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  ul,
  ol {
    padding-left: 20px;

    li {
      margin-bottom: 4px;
    }
  }

  @media print {
    display: flex;
  }
`;

const SeaboHeader = styled.div`
  position: absolute;
  top: 0;
  right: 25px;
  opacity: 0.5;
`;
