import {MapState} from './Types';
import {openweathermapNoLayer} from '../layers/mapbox/OpenweathermapLayerType';
import {MapSwitches} from '../MapDetails/utils/types';
import {DEFAULT_MAP_STYLE} from '../const';

export const blankState: MapState = {
  selectedItems: {type: '', items: []},
  mapReady: false,
  isFullScreen: false,
  isSelectMode: false,
  showSideContent: false,
  showSettingView: false,
  switches: {} as MapSwitches,
  mapSize: null,
  settings: {
    vesselIconSizeCapacityRelation: false,
    vesselIconStyle: 'simple',
    vesselIconSizeMultiplicator: 2.5,
    mapStyle: DEFAULT_MAP_STYLE,
    showOpenSeaMapGeoJSON: false,
    openWeatherMapLayer: openweathermapNoLayer,
    switches: {},
    mapResolution: 2,
  },
  viewState: {
    altitude: 1.5,
    latitude: 52,
    longitude: 15,
    zoom: 3.3,
    minZoom: 1.4,
    maxZoom: 13,
    pitch: 0,
    bearing: 0,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
};
