import styled from 'styled-components';

export type Color = 'gray' | 'green' | 'red' | 'orange' | 'purple' | 'blue';

const colors: string[] = ['gray', 'green', 'red', 'orange', 'purple', 'blue'];

type Size = 'small' | 'medium' | 'large';

export const Dot = styled.div<{$color?: string; $size?: Size}>`
  position: relative;
  display: inline-block;
  align-self: middle;
  border-radius: 50%;

  ${({$color}) => {
    if (!$color) {
      $color = 'gray';
    }
    if (colors.includes($color)) {
      if ($color === 'gray') {
        $color = 'gray-3';
      }
      return `background-color: var(--color-${$color});`;
    }
    return `background-color: ${$color};`;
  }}

  ${({$size}) => {
    switch ($size) {
      case 'small':
        return `
          height: var(--font-size-md);
          width: var(--font-size-md);
          border: 2px solid var(--color-white);
        `;
      case 'large':
        return `
          height: var(--font-size-lg);
          width: var(--font-size-lg);
        `;
      case 'medium':
      default:
        return `
          top: 2px;
          height: var(--font-size-md);
          width: var(--font-size-md);
        `;
    }
  }}
`;
