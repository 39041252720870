import {Col, Modal, Row, Steps} from 'antd';
import {FC, useState} from 'react';
import {useImmer} from 'use-immer';
import {FastVesselImportModalContent, FastVesselImportModalStep} from './FastVesselImportModalContent';
import {FastVesselImportParsedValues} from './ImportExcel';

const blankImport: FastVesselImportParsedValues = {
  vessels: [],
  predictedImoColumn: null,
  availableColumns: [],
};

export const FastVesselImportModal: FC<{visible: boolean; onCancel: () => void; onSuccess: () => void}> = ({
  visible,
  onSuccess,
  onCancel,
}) => {
  const [currentStep, setCurrentStep] = useState<FastVesselImportModalStep>(FastVesselImportModalStep.ImportExcel);
  const [values, updateValues] = useImmer<FastVesselImportParsedValues>(blankImport);

  const goToNext = () => {
    if (currentStep === FastVesselImportModalStep.Preview) {
      onSuccess();
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <Modal
      wrapProps={{id: 'root'}}
      width={1200}
      footer={null}
      open={visible}
      afterClose={() => {
        setCurrentStep(0);
        updateValues(blankImport);
      }}
      onCancel={onCancel}
      title={'Import Vessels'}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Steps
            current={currentStep}
            items={[{title: 'Import Excel'}, {title: 'Select imo-column'}, {title: 'Preview'}]}
          />
        </Col>
        <Col span={24}>
          <FastVesselImportModalContent
            values={values}
            onChangeImoColumn={imoColumn => {
              updateValues(draft => {
                draft.predictedImoColumn = imoColumn;
              });
            }}
            onChangeAvailableColumns={columns => {
              updateValues(draft => {
                draft.availableColumns = columns;
              });
            }}
            onChangeVesselGrid={grid => {
              updateValues(draft => {
                draft.vessels = grid;
              });
            }}
            step={currentStep}
            onGoToNextStep={goToNext}
          />
        </Col>
      </Row>
    </Modal>
  );
};
