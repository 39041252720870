export type WeatherDay = {
  dt: number;
  sunrise: number;
  sunset: number;
  moonrise: number;
  moonset: number;
  moon_phase: number;
  temp: {
    day: number;
    min: number;
    max: number;
    night: number;
    eve: number;
    morn: number;
  };
  feels_like: {
    day: number;
    night: number;
    eve: number;
    morn: number;
  };
  pressure: number;
  humidity: number;
  dew_point: number;
  wind_speed: number;
  wind_deg: number;
  wind_gust: number;
  weather: [
    {
      id: number;
      main: WeatherBackground;
      description: string;
      icon: string;
    },
  ];
  clouds: number;
  pop: number;
  uvi: number;
};

enum WeatherBackground {
  Thunderstorm = 'thunderstorm',
  Drizzle = 'drizzle',
  Rain = 'rain',
  Snow = 'snow',
  Atmosphere = 'atmosphere',
  Clouds = 'clouds',
  Sunny = 'sunny',
}

export const renderWeatherBackground = (id: number): WeatherBackground => {
  switch (true) {
    case isThunderstorm(id):
      return WeatherBackground.Thunderstorm;
    case isDrizzle(id):
      return WeatherBackground.Drizzle;
    case isRain(id):
      return WeatherBackground.Rain;
    case isSnow(id):
      return WeatherBackground.Snow;
    case isAtmosphere(id):
      return WeatherBackground.Atmosphere;
    case isClouds(id):
      return WeatherBackground.Clouds;
    default:
      return WeatherBackground.Sunny;
  }
};

const isThunderstorm = (id: number) => id >= 200 && id <= 299;
const isDrizzle = (id: number) => id >= 300 && id <= 399;
const isRain = (id: number) => id >= 500 && id <= 599;
const isSnow = (id: number) => id >= 600 && id <= 699;
const isAtmosphere = (id: number) => id >= 700 && id <= 799;
const isClouds = (id: number) => id >= 801 && id <= 899;
