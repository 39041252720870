import Flag from '../Flags';
import {TODO} from '../../utils/TODO';
import './style.scss';
import {NotAvailable} from '../../utils/NotAvailable';

type Props = {
  vessel: {
    name?: string | null;
    countryFlag?: string;
  };
  exName?: TODO;
};

export const VesselName = ({vessel, exName}: Props) => {
  return vessel.name ? (
    <div className={'dropdown-display__title-wrapper'}>
      <span className={'dropdown-display__title-name'}>{vessel.name}</span>{' '}
      <span style={{marginRight: 5}}>
        <Flag countryCode={vessel.countryFlag} shadow width={13} height={10} />
      </span>
      {exName && <span className={'dropdown-display__title-ex-name'}>(Ex name: {exName})</span>}
    </div>
  ) : (
    <>{NotAvailable}</>
  );
};
