import produce from 'immer';
import {useContext} from 'react';
import {VoyagePoint} from '../../../../VoyageInformation/VoyageTypes';
import {InputState} from '../../../../utils/voyageChartercalculation/voyageChartercalculation';
import {VoyageCharterContext} from '../../../VoyageCharterContext';

type UpdateVoyagePointParams = {voyagePoint: Partial<VoyagePoint>; voyagePointIndex: number};

export type UpdateVoyagePoint = (params: UpdateVoyagePointParams) => void;

export const updateVoyagePoint = ({
  setInputState,
  inputState,
  voyagePointIndex,
  voyagePoint,
}: UpdateVoyagePointParams & {
  inputState: InputState;
  setInputState: (inputState: InputState) => void;
}) => {
  const oldPoint = inputState.voyage.points[voyagePointIndex];
  const newPoint = {
    ...oldPoint,
    ...voyagePoint,
  };
  if (voyagePoint.consumption) {
    newPoint.consumption = {
      ...oldPoint.consumption,
      ...voyagePoint.consumption,
    };
  }

  setInputState(
    produce(inputState, draftState => {
      draftState.voyage.points[voyagePointIndex] = newPoint;
      draftState.voyage.routesCalculated = false;
      return draftState;
    })
  );
};

export const useUpdateVoyagePoint = (): UpdateVoyagePoint => {
  const {state, setInputState} = useContext(VoyageCharterContext);
  const {inputState} = state;
  return ({voyagePoint, voyagePointIndex}) =>
    updateVoyagePoint({
      voyagePointIndex,
      voyagePoint,
      setInputState,
      inputState,
    });
};
