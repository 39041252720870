import {useEffect, FC} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import EmptyStateArchive from '../../atoms/EmptyState/Archive/EmptyStateArchive';
import ProjectEmptyState from '../../atoms/EmptyState/Project';
import omit from 'lodash/omit';
import Loading from '../../atoms/Loading';
import ProjectActions from '../../redux/ApiService/ProjectService';
import {ModalActions} from '../../redux/Modal';
import {setListGridState} from '../../redux/Projects';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {TODO} from '../../utils/TODO';
import {FetchingForE2ETests} from '../DataGrid/FetchingForE2ETests';
import {Pagination} from '../DataGrid/Pagination/Pagination';
import {TotalItemsForE2ETests} from '../DataGrid/TotalItemsForE2ETests';
import {useDeleteProjectMutation} from '../ProjectDetailsScreen/useDeleteProjectMutation';
import ProjectOverviewCard from './src/ProjectOverviewCard';
import {ScreenHeader} from '../ScreenHeader/ScreenHeader';
import {useLocalStorage} from '../../utils/useLocalStorage';
import {useUser} from '../UserContext/useUser';
import CreateProject from '../Modal/CreateProject';
import {Delete} from '../Modal/Delete/DeleteModal';
import {Archive} from '../Modal/Archive';
import {Space} from 'antd';
import {ProjectsTourDefinition} from './ProjectsTourDefinition';

const LoadingState = () => (
  <div className="empty-state">
    <div className="empty-state__content">
      <Loading />
    </div>
  </div>
);

interface Props {
  create?: boolean;
}

const ProjectsQueryKey = 'ProjectActions.getAllProjects';

export const ProjectCreateScreen = (props: Props) => <ProjectsListScreen {...props} create />;

export const ProjectsListScreen: FC<Props> = ({create}) => {
  const gridState = useSelector(state => state.projects.gridState);
  const user = useUser();
  const workspace = user.payload.workspace;
  const dispatch = useDispatch();
  const [, setLocalStorageLayoutIsExpanded] = useLocalStorage('project-overview-layout', false);
  const [, setLocalStoragePageSize] = useLocalStorage('grid_page_size-projects_list', 10);

  const queryClient = useQueryClient();

  const deleteProject = useDeleteProjectMutation({
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      reload();
    },
  });

  const isArchive = /\/archive\/?$/.test(window.location.pathname);

  useEffect(() => {
    if (create) {
      showCreateProjectModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const showLoadProjectsModal = (page = 1, pageSize = 5) => {
    dispatch(setListGridState({pageIndex: page, pageSize: pageSize}));
  };

  const projectsQuerykeys = [ProjectsQueryKey, gridState.pageIndex, gridState.pageSize, isArchive];
  const projectsQuery = useQuery({
    queryKey: projectsQuerykeys,
    queryFn: async () => {
      const response = await dispatch(
        ProjectActions.getAllProjects(gridState.pageIndex, gridState.pageSize, null, isArchive ? 1 : undefined)
      );
      return response.data;
    },
  });

  const isArchivePath = /\/archive?$/.test(window.location.pathname);

  const handleExpandCollapse = (isExpanded: boolean) => {
    if (isExpanded !== gridState.isExpanded) {
      dispatch(setListGridState({isExpanded: isExpanded}));
      setLocalStorageLayoutIsExpanded(isExpanded);
    }
  };

  const reload = () =>
    queryClient.invalidateQueries({
      queryKey: projectsQuerykeys,
    });

  const showCreateProjectModal = () => {
    dispatch(ModalActions.show(<CreateProject callback={reload} />));
  };

  const onEdit = (project: TODO) => {
    dispatch(ModalActions.show(<CreateProject project={project} callback={reload} />));
  };

  const onDelete = (project: TODO) => {
    dispatch(
      ModalActions.show(
        <Delete
          target={{
            name: project.name,
            id: project.id,
          }}
          onDelete={(id: $TSFixMe, _: $TSFixMe, close: $TSFixMe) => {
            deleteProject.mutate(id);
            close();
          }}
        />
      )
    );
  };

  const onUpdate = async (project: TODO) => {
    const onlyProject = omit(project, ['cargo', 'vessel']);
    await dispatch(ProjectActions.updateProjectList(parseInt(project.id), onlyProject));
    await reload();
  };

  const onArchive = (project: TODO) => {
    dispatch(
      ModalActions.show(
        <Archive
          type="project"
          target={project}
          update={(id: TODO, body: TODO, close: () => void, setError: TODO) =>
            dispatch(ProjectActions.updateProjectList(id, body))
              .then(() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                reload();
                close();
              })
              .catch(() => {
                setError(true);
              })
          }
        />
      )
    );
  };

  const markAsFavorite = (project: TODO) => {
    const onlyProject = omit(project, ['cargo', 'vessel']);
    const updatedProject = {
      ...onlyProject,
      isSticky: !project.isSticky,
    };
    dispatch(ProjectActions.updateProjectList(project.id, updatedProject)).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      reload();
    });
  };

  const setRating = (project: TODO, rating: TODO) => {
    const onlyProject = omit(project, ['cargo', 'vessel']);
    const updatedProject = {
      ...onlyProject,
      rating: rating,
    };
    return dispatch(ProjectActions.updateProjectList(project.id, updatedProject));
  };

  return (
    <div className="projects">
      <ScreenHeader
        helmetTitle="Projects"
        breadcrumbs={
          isArchivePath ? [{title: 'Projects', href: '/projects'}, {title: 'Archive'}] : [{title: 'Projects'}]
        }
        tourDefinition={ProjectsTourDefinition}
        actions={
          <Space size={4}>
            {!isArchive && (
              <ScreenHeader.Actions.Link
                id="archiveButton"
                ghost={true}
                to="/projects/archive"
                data-cy="ProjectListToArchiveBTN">
                Go to archive
              </ScreenHeader.Actions.Link>
            )}
            <ScreenHeader.Actions.Button onClick={showCreateProjectModal} data-cy="ProjectListCreateBTN">
              Create project
            </ScreenHeader.Actions.Button>
          </Space>
        }
        features={
          <>
            <ScreenHeader.Features.Toggle
              value={gridState.isExpanded}
              onChange={event => handleExpandCollapse(event.target.value)}
              options={[
                {
                  id: 'expand-button',
                  label: 'Full view',
                  value: true,
                  props: {
                    'data-cy': 'ProjectListExpandBTN',
                  },
                },
                {
                  id: 'collapse-button',
                  label: 'Condensed',
                  value: false,
                  props: {
                    'data-cy': 'ProjectListCollapseBTN',
                  },
                },
              ]}
            />
          </>
        }
      />
      <TotalItemsForE2ETests totalItems={projectsQuery.data?.totalItems} />
      <FetchingForE2ETests fetching={projectsQuery.isFetching} />
      {projectsQuery.isLoading && <LoadingState />}
      {projectsQuery.isSuccess && projectsQuery.data!.totalItems === 0 && isArchive && (
        <EmptyStateArchive type={'project'} location={'projects'} />
      )}
      {projectsQuery.isSuccess && projectsQuery.data!.totalItems === 0 && !isArchive && (
        <ProjectEmptyState onClick={() => showCreateProjectModal()} />
      )}
      {projectsQuery.isSuccess && projectsQuery.data.totalItems > 0 && (
        <>
          {projectsQuery
            .data!.items.sort((a: $TSFixMe, b: $TSFixMe) => b.isSticky - a.isSticky)
            .map((project: $TSFixMe) => (
              <ProjectOverviewCard
                project={project}
                key={project.id}
                onDelete={() => onDelete(project)}
                onEdit={() => onEdit(project)}
                onUpdate={updatedProject => onUpdate(updatedProject)}
                onArchive={() => onArchive(project)}
                markAsFavorite={() => markAsFavorite(project)}
                isExpanded={gridState.isExpanded}
                isFavorite={project.isSticky}
                onRating={(project: $TSFixMe, rating: $TSFixMe) => setRating(project, rating)}
                workspace={workspace}
              />
            ))}
          <Pagination
            pageSize={gridState.pageSize}
            totalCount={projectsQuery.data?.totalItems}
            onPageChange={(pageIndex: $TSFixMe) => showLoadProjectsModal(pageIndex, gridState.pageSize)}
            onPageSizeChange={(pageSize: $TSFixMe) => {
              setLocalStoragePageSize(pageSize);
              showLoadProjectsModal(1, pageSize);
            }}
            showPageSizeOptions
            page={gridState.pageIndex}
            pages={Math.ceil((projectsQuery.data?.totalItems ?? 1) / gridState.pageSize)}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
        </>
      )}
    </div>
  );
};
