import {Button, Collapse} from 'antd';
import {OneCalcResult} from './oneCalc';
import {useGoToUrl} from '../../../../utils/useChangeBrowserUrl';
import styled from 'styled-components';

const {Panel} = Collapse;

type Props = {
  testId: string;
  oneCalcResult: OneCalcResult;
  isInMatrix?: boolean;
};

export const ErrorAlert = ({testId, oneCalcResult, isInMatrix}: Props) => {
  const goToUrl = useGoToUrl();

  const {cargo, cargoProject, vesselProject, vessel} = oneCalcResult;
  const cargoName =
    cargo.chartererName === null ? `Cargo with ID ${oneCalcResult.cargo.id}` : oneCalcResult.cargo.chartererName;

  const vesselName = vessel.name;
  /*
  if both vessel project and cargo project are given, throw
   */
  if (oneCalcResult.vesselProject?.id !== undefined && oneCalcResult.cargoProject?.id !== undefined) {
    throw new Error('in multicalc only one project (vessel or cargo) can be included per oneCalcResult');
  }

  return (
    <StyledCollapse
      $isInMatrix={isInMatrix}
      defaultActiveKey={oneCalcResult.id}
      items={[
        {
          key: oneCalcResult.id,
          label: '',
          children: (
            <StyledPanel
              header={
                <ErrorHeader data-testid={'NestedTableErrorAlert_' + testId}>
                  Calculation for "{vesselName}" with "{cargoName}" failed
                </ErrorHeader>
              }
              key={oneCalcResult.id}>
              <div>
                <p>This combination has raised following errors:</p>
                <ErrorUL data-cy={'error-list_' + testId}>
                  {oneCalcResult.voyageCalculationOutput.warning.list.map(warning => {
                    return (
                      <li key={warning.code}>
                        {warning.type === 'error' ? <b>{warning.message}</b> : warning.message}
                      </li>
                    );
                  })}
                </ErrorUL>
                <p>To get a result, try creating a new single Voyage Calculation below or edit the items directly.</p>
                <StyledButton
                  onClick={() => {
                    goToUrl(
                      `/chartercalculator/voyagecharter?cargoes_ids=${cargo.id}&vessel_id=${vessel.id}&project_id=${
                        vesselProject?.id ?? cargoProject?.id
                      }`
                    );
                  }}
                  type={'primary'}>
                  Single Voyage Calculation
                </StyledButton>
                <StyledButton
                  onClick={() => {
                    goToUrl(`/cargo/${cargo.id}`);
                  }}>
                  Edit cargo "{cargoName}"
                </StyledButton>
                <StyledButton
                  onClick={() => {
                    goToUrl(`/vessel/${vessel.id}`);
                  }}>
                  Edit vessel "{vessel.name}"
                </StyledButton>
              </div>
            </StyledPanel>
          ),
        },
      ]}></StyledCollapse>
  );
};

const ErrorHeader = styled.span`
  color: var(--color-gray-2);
`;

const ErrorUL = styled.ul`
  list-style: disc;
  padding-left: 20px;
`;

const StyledCollapse = styled(Collapse)<{$isInMatrix?: boolean}>`
  ${({$isInMatrix}) =>
    $isInMatrix
      ? `
    height: 100%;
    width: 100%;
    background: transparent;
    border: 0;

    .ant-collapse-content {
      border-top: 0;
      background: transparent;
    }

    ${StyledPanel} {
      border: 0;

      .ant-collapse-content > .ant-collapse-content-box {
        margin: 0;
        padding: 0;
      }

      ${StyledButton} {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  `
      : ''}
`;

const StyledPanel = styled(Panel)`
  & > .ant-collapse-content > .ant-collapse-content-box {
    overflow: auto;
    padding: 20px;
  }
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;
