import {CSSProperties} from 'react';
import classNames from 'classnames';
import {cleanIdentifier} from '../../../utils/helper';
import Input from '../../Input';
import {withMarginMapping} from '../../../components/withMargin';

import './style.scss';

type Props = {
  id?: string;
  onClick?: $TSFixMeFunction;
  onChange?: $TSFixMeFunction;
  onBlur?: $TSFixMeFunction;
  value?: string;
  prepend?: $TSFixMe;
  append?: $TSFixMe;
  hasError?: boolean;
  style?: CSSProperties;
  inputComponent?: $TSFixMe;
  groupStyle?: CSSProperties;
  dataCy?: string;
};

const InputGroup = ({
  id,
  onClick,
  value,
  prepend,
  append,
  onChange,
  hasError,
  style = {},
  dataCy,
  groupStyle = {},
  inputComponent,
  ...rest
}: Props) => {
  // @ts-expect-error ts-migrate(2559) FIXME: Type '{ onBlur?: $TSFixMeFunction | undefined; }' ... Remove this comment to see the full error message
  const [spaceClasses] = withMarginMapping(rest);
  return (
    <div
      data-cy={dataCy}
      className={classNames('input-group', {'input-group--has-error': hasError}, spaceClasses)}
      style={style}>
      {prepend && <div className="input-group__element input-group__element--prepended">{prepend}</div>}
      <div className="input-group__element" style={groupStyle}>
        {inputComponent ? (
          inputComponent
        ) : (
          <Input
            id={cleanIdentifier(id, 'group')}
            onClick={onClick}
            value={value}
            type={'text'}
            onChange={onChange}
            onBlur={onChange}
            noBorder
            hasError={hasError}
          />
        )}
      </div>
      {append && <div className="input-group__element input-group__element--appended">{append}</div>}
    </div>
  );
};
export default InputGroup;
