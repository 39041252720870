import {FC} from 'react';
import {checkIsOrder} from '../../../../../screens/MyFleetScreens/MyFleetScreen/MatchingOrderBookVessels/checkIsOrder';
import {useGetVesselByImoQuery} from '../../../../../queries/useGetVesselByImoQuery';
import {PortfolioVessel} from '../../../../../redux/Portfolio';
import {SeaboVessel} from '../../../../../api/node-backend/generated';

export type OnSyncWithSpireParams = {vessel: PortfolioVessel; spireData: SeaboVessel};

export const MatchingVesselOrderButton: FC<{
  vessel: PortfolioVessel;
  onSyncWithSpire?: (params: OnSyncWithSpireParams) => void;
}> = ({vessel, onSyncWithSpire}) => {
  const isOrder = checkIsOrder(vessel);

  const spireDataQuery = useGetVesselByImoQuery(
    {imo: vessel.imo || 0},
    {
      enabled: isOrder && !!vessel.imo,
    }
  );

  const found = !!spireDataQuery.data;
  return (
    <>
      {isOrder && (
        <span style={{display: 'inline-block', marginRight: 4}}>
          {found && (
            <img
              style={{cursor: 'pointer'}}
              src={'/static/images/imoVessel.svg'}
              onClick={() => {
                onSyncWithSpire?.({vessel, spireData: spireDataQuery.data!});
              }}
            />
          )}
        </span>
      )}
    </>
  );
};
