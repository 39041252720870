import React, {useLayoutEffect} from 'react';
import {animated, useSpring} from 'react-spring';

interface MapHoverContainerProps {
  closedHeight?: number;
  openedHeight: number;
  children: JSX.Element;
  isOpen: boolean;
}

export const MapHoverContainer = ({closedHeight = 0, openedHeight, children, isOpen}: MapHoverContainerProps) => {
  const [props, api] = useSpring(() => ({height: closedHeight}));

  useLayoutEffect(() => {
    api.start({height: isOpen ? openedHeight : closedHeight});
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isOpen, openedHeight, closedHeight]);

  return <animated.div style={{overflow: 'hidden', ...props}}>{children}</animated.div>;
};
