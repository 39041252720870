import {Consumption, newConsumption} from './ConsumptionRows';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

export const sortAndExtendConsumptionValues = (consumptions: Consumption[]): Consumption[] => {
  const newConsumptions = [...consumptions];

  const usedTypes = uniq(consumptions.map(consumption => consumption.type));

  if (!usedTypes.includes('portidle')) {
    newConsumptions.push({...newConsumption, type: 'portidle'});
  }
  if (!usedTypes.includes('portworking')) {
    newConsumptions.push({...newConsumption, type: 'portworking'});
  }
  if (!usedTypes.includes('laden')) {
    newConsumptions.push({...newConsumption, type: 'laden'});
  }
  if (!usedTypes.includes('ladeneco')) {
    newConsumptions.push({...newConsumption, type: 'ladeneco'});
  }
  if (!usedTypes.includes('ballast')) {
    newConsumptions.push({...newConsumption, type: 'ballast'});
  }
  if (!usedTypes.includes('ballasteco')) {
    newConsumptions.push({...newConsumption, type: 'ballasteco'});
  }

  const sortedConsumptions = sortBy(newConsumptions, consumption => consumption.type);

  return sortedConsumptions;
};
