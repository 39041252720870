import {Timeline} from 'antd';
import {VoyagePoint, CalcTimeLinePoint} from '../../VoyageTypes';
import DragHandle from '../../../../../../atoms/ReactSortableHoc/DragHandle';
import {AimOutlined} from '@ant-design/icons';
import {VesselConsumptionMode} from '../../../VesselInformation/VesselTypes';
import styled from 'styled-components';
import {PointEditView} from './PointEditView/PointEditView';
import {PointStaticView} from './PointStaticView';
import {useGetMinHeightWithWarnings, WarningsInVoyage} from '../../../Warning/WarningsInVoyage';
import {getWarningAreaForVoyagePoint} from '../../../utils/calcWarnings/calcWarnings';
import {
  transformPortToVoyagePointPartial,
  voyagePointPartialWithEmptyPortInformations,
} from '../../utils/transformPortToVoyagePointPartial';
import {focusField} from '../../utils/focusField';
import Icon from '../../../../../../atoms/Icon';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useGetCongestionLightReport} from '../../../../../../queries/useGetCongestionLightReport';
import {AreaType} from '../../../../../../api/node-backend/generated';
import {usePortDAForVoyagePointQuery} from './usePortDAForVoyagePointQuery';

interface PointTimelineItemProps {
  consumptionModes: VesselConsumptionMode[];
  item: CalcTimeLinePoint;
  onChangeEditing: (isEditing: boolean) => void;
  onRemovePoint: () => void;
  isEditing: boolean;
  onChangePoint: (point: Partial<VoyagePoint>) => void;
  id: number;
}

export const PointTimelineItem = (props: PointTimelineItemProps) => {
  const point = props.item.item;
  const warningTag = getWarningAreaForVoyagePoint(props.item.indexInOldArray);
  const minHeight = useGetMinHeightWithWarnings({
    area: warningTag,
  });
  const isEditing = props.isEditing;
  const setIsEditing = (isEditing: boolean) => {
    props.onChangeEditing(isEditing);
  };

  // Preload portDAQuery for edit mode, because the request is slow.
  // And so the user can see the PortDAs immediately when he clicks on the edit button.
  usePortDAForVoyagePointQuery(props.item);

  const portCongestionQuery = useGetCongestionLightReport(
    {
      areaId: point.originalPort?.id ?? 0,
      areaType: AreaType.Port,
    },
    {
      enabled: !!point.originalPort?.id,
    }
  );

  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: props.id});
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 3 : 2,
    position: 'relative' as const,
  };

  const Dot = (
    <div style={{paddingTop: 0, backgroundColor: 'transparent'}} ref={setNodeRef} {...attributes} {...listeners}>
      <DragHandle>
        <div style={{paddingTop: 20, backgroundColor: 'transparent'}}>
          <div style={{backgroundColor: 'var(--color-gray-7)', borderRadius: 5, padding: 5, cursor: 'grab'}}>
            {point.types?.includes('nextOpen') ? (
              <AimOutlined style={{fontSize: '15px'}} />
            ) : (
              <Icon type="port" style={{fontSize: '15px', color: 'var(--color-blue)'}} />
            )}
          </div>
        </div>
      </DragHandle>
    </div>
  );

  return (
    <div style={{...style}}>
      <StyledTimelineItem data-cy="timelineItem" $minHeight={minHeight} dot={Dot}>
        <WarningsInVoyage
          data-cy="timelineWarnings"
          area={warningTag}
          onClickWarning={item => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            focusField({
              dataFieldId: item.dataFieldId,
              openEditMode: () => setIsEditing(true),
            });
          }}
        />

        <TimelineContent>
          {isEditing ? (
            <PointEditView
              avgDurationLast10VesselsInMinutes={portCongestionQuery.data?.avgDurationLast10Vessels ?? undefined}
              area={getWarningAreaForVoyagePoint(props.item.indexInOldArray)}
              {...props}
              isFirstLoadingLeg={props.item.isFirstLoadingLeg}
              onChangePort={port => {
                props.onChangePoint(
                  port ? transformPortToVoyagePointPartial(port) : voyagePointPartialWithEmptyPortInformations
                );
              }}
              onDeactivateEditMode={() => setIsEditing(false)}
            />
          ) : (
            <PointStaticView
              avgDurationLast10VesselsInDays={portCongestionQuery.data?.avgDurationLast10Vessels ?? undefined}
              isFirstLoadingLeg={props.item.isFirstLoadingLeg}
              onActivateEditMode={() => setIsEditing(true)}
              {...props}
            />
          )}
        </TimelineContent>
      </StyledTimelineItem>
    </div>
  );
};

const StyledTimelineItem = styled(Timeline.Item)<{$minHeight?: number}>`
  padding-top: 0px;
  & > div.ant-timeline-item-content {
    padding-top: 0px;
    background: transparent;
    padding-bottom: 0px;
    min-height: ${({$minHeight}) => ($minHeight ? `${$minHeight}px` : 'unset')};
  }

  & > div.ant-timeline-item-head {
    background: transparent;
  }

  .ant-list-item-meta {
    background: var(--color-white);
  }

  .ant-timeline-item-head-blue {
    top: 10px !important;
  }
`;

export const TimelineContent = styled.div`
  flex: 1;
`;
