import {StepResult} from './StepResult';
import {PreparedAisVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {FilterParams} from '../filterParams';
import {Step2Result} from './step2';

export type Step3Vessel = PreparedAisVessel & {
  estimatedDurationInDestinationPortInDays: number;
  aisDetails?: {
    lastPort?: {
      name: string;
      country?: string;
    };
  };
};

export type Step3Result = StepResult<Step3Vessel>;

const averagePortDischargeTerm = 15000;

/**
 *  extend vessel  -calc and add estimatedDurationInDestinationPortInDays
 */
export const step3 = ({params, preResult}: {preResult: Step2Result; params: FilterParams}): Step3Result => {
  const vessels: Step3Vessel[] = preResult.vessels.map(vessel => {
    let estimatedDurationInDestinationPortInDays = 0;
    if (vessel.destinationPort) {
      if (vessel.destinationPort.code !== params.portCode) {
        estimatedDurationInDestinationPortInDays = Math.ceil((vessel.dwt ?? params.minDWT) / averagePortDischargeTerm);
      }
    }

    return {...vessel, estimatedDurationInDestinationPortInDays};
  });
  return {
    vessels,
    loggedFilters: preResult.loggedFilters,
  };
};
