import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Coordinates} from '../utils/Coordinates';

interface PortBackendDetailsState {
  coordinatesToFocus: Coordinates[] | undefined;
  highlightedAreaId: number | undefined;
}

const initialState: PortBackendDetailsState = {
  coordinatesToFocus: undefined,
  highlightedAreaId: undefined,
};

const portBackendDetailsSlice = createSlice({
  name: 'PortBackendDetails',
  initialState,
  reducers: {
    setHighlightedAreaId(state, action: PayloadAction<number | undefined>) {
      state.highlightedAreaId = action.payload;
    },
    setCoordinatesToFocus(state, action: PayloadAction<Coordinates[] | undefined>) {
      state.coordinatesToFocus = action.payload;
    },
  },
});

export const portBackendDetailsReducer = portBackendDetailsSlice.reducer;

export const PortBackendDetailsActions = portBackendDetailsSlice.actions;
