const formattedVesselType = new Map(
  Object.entries({
    container: 'Container',
    bulker: 'Bulker',
    mpp: 'MPP',
    tanker: 'Tanker',
    other: 'Other',
  })
);

export const getFormattedVesselType = (vesselType: string | null | undefined): string | null => {
  if (!vesselType) {
    return null;
  }
  const result = formattedVesselType.get(vesselType);
  if (!result) {
    // We got some unexepected vesselType. Display it as is.
    return vesselType;
  }
  return result;
};
