import {useMemo, FC} from 'react';
import sumBy from 'lodash/sumBy';
import {NumberStatistics} from '../../../components/NumberStatistics';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {getTeuQuantity} from '../utils/getTeuQuantity';

export const TiedUpContainerVessels: FC<{
  currentContainerVisits: MergedVisits[];
}> = ({currentContainerVisits}) => {
  const tiedUpTeu = useMemo(() => {
    return sumBy(currentContainerVisits, visit => getTeuQuantity(visit.intakes) ?? 0);
  }, [currentContainerVisits]);

  return <NumberStatistics title={'Tied up TEU'} suffix={'TEU'} value={tiedUpTeu} />;
};
