/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  Tag,
  TagFromJSON,
  TagToJSON,
  TagListResponse,
  TagListResponseFromJSON,
  TagListResponseToJSON,
  TagType,
  TagTypeFromJSON,
  TagTypeToJSON,
  TagWrite,
  TagWriteFromJSON,
  TagWriteToJSON,
} from '../models';

export interface CreateTagRequest {
  tagWrite: TagWrite;
}

export interface DeleteTagRequest {
  id: number;
}

export interface GetTagRequest {
  id: number;
}

export interface ListTagsRequest {
  type?: TagType;
}

export interface UpdateTagRequest {
  id: number;
  tagWrite: TagWrite;
}

/**
 *
 */
export class TagApi extends runtime.BaseAPI {
  /**
   */
  async createTagRaw(requestParameters: CreateTagRequest): Promise<runtime.ApiResponse<Tag>> {
    if (requestParameters.tagWrite === null || requestParameters.tagWrite === undefined) {
      throw new runtime.RequiredError(
        'tagWrite',
        'Required parameter requestParameters.tagWrite was null or undefined when calling createTag.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/tags`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TagWriteToJSON(requestParameters.tagWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => TagFromJSON(jsonValue));
  }

  /**
   */
  async createTag(requestParameters: CreateTagRequest): Promise<Tag> {
    const response = await this.createTagRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async deleteTagRaw(requestParameters: DeleteTagRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteTag.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteTag(requestParameters: DeleteTagRequest): Promise<void> {
    await this.deleteTagRaw(requestParameters);
  }

  /**
   */
  async getTagRaw(requestParameters: GetTagRequest): Promise<runtime.ApiResponse<Tag>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getTag.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => TagFromJSON(jsonValue));
  }

  /**
   */
  async getTag(requestParameters: GetTagRequest): Promise<Tag> {
    const response = await this.getTagRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listTagsRaw(requestParameters: ListTagsRequest): Promise<runtime.ApiResponse<TagListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/tags`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => TagListResponseFromJSON(jsonValue));
  }

  /**
   */
  async listTags(requestParameters: ListTagsRequest): Promise<TagListResponse> {
    const response = await this.listTagsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateTagRaw(requestParameters: UpdateTagRequest): Promise<runtime.ApiResponse<Tag>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateTag.'
      );
    }

    if (requestParameters.tagWrite === null || requestParameters.tagWrite === undefined) {
      throw new runtime.RequiredError(
        'tagWrite',
        'Required parameter requestParameters.tagWrite was null or undefined when calling updateTag.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: TagWriteToJSON(requestParameters.tagWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => TagFromJSON(jsonValue));
  }

  /**
   */
  async updateTag(requestParameters: UpdateTagRequest): Promise<Tag> {
    const response = await this.updateTagRaw(requestParameters);
    return await response.value();
  }
}
