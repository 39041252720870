export class InvalidApiRequestPayload extends Error {
  validationErrors: string[];

  constructor(validationErrors: string[]) {
    super();
    this.name = 'InvalidApiPayload';
    this.message = 'Invalid Api Payload';
    this.validationErrors = validationErrors;
  }
}
