import styled from 'styled-components';
import {Card} from 'antd';
import LoadingAnimation from '../../../atoms/Loading';
import Lighthouse from '../../../atoms/EmptyState/LightHouse';
import {mapNegotiationColorSet} from '../utils/colors';
import {ComponentProps} from 'react';

const colorSelf = mapNegotiationColorSet('blue');
const colorPartner = mapNegotiationColorSet('gray');
const colorRecap = mapNegotiationColorSet('orange');
const colorAccepted = mapNegotiationColorSet('green');
const colorRejected = mapNegotiationColorSet('red');

export const NegotiationScreenContainer = styled.div`
  &,
  ::after,
  ::before {
    --color-self: var(--${colorSelf.base});
    --color-self-bg: var(--${colorSelf.background});
    --color-self-border: var(--${colorSelf.border});
    --color-partner: var(--${colorPartner.base});
    --color-partner-bg: var(--${colorPartner.background});
    --color-recap: var(--${colorRecap.base});
    --color-recap-bg: var(--${colorRecap.background});
    --color-partner-border: var(--${colorPartner.border});
    --color-accepted: var(--${colorAccepted.base});
    --color-accepted-bg: var(--${colorAccepted.background});
    --color-accepted-border: var(--${colorAccepted.border});
    --color-rejected: var(--${colorRejected.base});
    --color-rejected-bg: var(--${colorRejected.background});
    --color-rejected-border: var(--${colorRejected.border});
    --color-new: var(--${colorSelf.base});

    --meta-width: 200px;
    --main-width: 75%;
    --sidebar-width: 0.225 * var(--main-width);
    --border-radius: var(--border-radius-lg);
    --border-width-active: 2px;
  }

  @media print {
    @page {
      size: A4 portrait;
      margin: 15mm 10mm 15mm 5mm;
    }

    html,
    body {
      height: 100vh; /* Use 100% here to support printing more than a single page*/
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
`;

export const BoxFull = styled.div`
  position: relative;
  flex: 100%;
`;

export const BoxHalf = styled.div`
  position: relative;
  flex: 50%;
`;

export const BoxThird = styled.div`
  position: relative;
  flex: 33%;
`;

export const NegotiationCard = styled(Card)`
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.1);

  .ant-card-body {
    padding: 10px 20px;
  }
`;

export const GhostButton = styled.button`
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const LoadingDots = () => (
  <LoadingDotsContainer>
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </LoadingDotsContainer>
);

const LoadingDotsContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin: 0 auto;

  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

export const LoadingBanner = (props: {'data-testid'?: string}) => (
  <FlexBox style={{justifyContent: 'center'}} data-testid={props['data-testid']}>
    <LoadingAnimation />
  </FlexBox>
);

export const EmptyBanner = ({
  'data-testid': dataTestd,
  ...props
}: ComponentProps<typeof Lighthouse> & {'data-testid'?: string}) => (
  <FlexBox style={{justifyContent: 'center'}} data-testid={dataTestd}>
    <Lighthouse {...props} />
  </FlexBox>
);
