import {useContext, useEffect} from 'react';
import {MapContext} from '../MapContext/MapContext';

/**
 * when the prop.centerPoint changed, this
 * fly to the the centerPoint
 */
export const useHandleCenterPoint = () => {
  const mapContext = useContext(MapContext);

  useEffect(() => {
    if (!mapContext.state.mapReady) {
      return;
    }
    if (mapContext.props.centerPoint !== undefined && mapContext.props.centerPoint !== null) {
      mapContext.setViewState({
        ...mapContext.state.viewState,
        latitude: mapContext.props.centerPoint[1],
        longitude: mapContext.props.centerPoint[0],
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [mapContext.props.centerPoint, mapContext.state.mapReady]);
};
