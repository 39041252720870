import {Card} from 'antd';
import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
`;

export const StyledCard = styled(Card)`
  height: 100%;
  margin-bottom: 20px;
  .ant-card-body {
    padding: 0;
  }
`;

export const FlexBoxVert = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  flex-direction: column;
  height: 100%;
`;

export const BoxHalf = styled.div`
  width: calc(50% - 10px);
  flex-grow: 1;
`;

export const BoxTop = styled.div`
  height: auto;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 5px;
`;

export const BoxBottom = styled.div`
  height: auto;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 5px;
`;

export const LeadText = styled.h2`
  text-transform: uppercase;
  color: var(--color-gray-2);
  margin-bottom: 0;
`;

export const LocationText = styled.p`
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: var(--font-size-lg);
`;

export const FallbackImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-azure);
  border-radius: var(--border-radius-sm);
  height: calc(100% - 5px);
`;
