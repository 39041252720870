import {useQuery} from '@tanstack/react-query';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {ApiError} from '../../../../api/utils/ApiError';
import {NegotiationSummary} from '../../../../api/node-backend/generated';
import {negotiationNegotiationApi} from '../../../../api/node-backend/nodeBackendApis';

export const FIND_NEGOTIATION_BY_ID_QUERY_KEY = (negotiationId?: string) => [
  'negotiationNegotiationApi.findNegotiationById',
  negotiationId,
];

export const useFindNegotiationByIdQuery = (
  negotiationId?: string,
  options?: UseQueryOptions<NegotiationSummary, ApiError>
) =>
  useQuery({
    queryKey: FIND_NEGOTIATION_BY_ID_QUERY_KEY(negotiationId),
    queryFn: async () => {
      const result = await negotiationNegotiationApi.findNegotiationById({negotiationId: negotiationId!});
      return result;
    },
    refetchOnWindowFocus: true,
    ...options,
  });
