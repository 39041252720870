/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {PortVersionUser, PortVersionUserFromJSON, PortVersionUserFromJSONTyped, PortVersionUserToJSON} from './';

/**
 *
 * @export
 * @interface PortVersionSummary
 */
export interface PortVersionSummary {
  /**
   *
   * @type {PortVersionUser}
   * @memberof PortVersionSummary
   */
  editor: PortVersionUser;
  /**
   *
   * @type {number}
   * @memberof PortVersionSummary
   */
  version: number;
  /**
   *
   * @type {Date}
   * @memberof PortVersionSummary
   */
  loggedAt: Date;
  /**
   *
   * @type {number}
   * @memberof PortVersionSummary
   */
  areas: number;
}

export function PortVersionSummaryFromJSON(json: any): PortVersionSummary {
  return PortVersionSummaryFromJSONTyped(json, false);
}

export function PortVersionSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortVersionSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    editor: PortVersionUserFromJSON(json['editor']),
    version: json['version'],
    loggedAt: new Date(json['loggedAt']),
    areas: json['areas'],
  };
}

export function PortVersionSummaryToJSON(value?: PortVersionSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    editor: PortVersionUserToJSON(value.editor),
    version: value.version,
    loggedAt: value.loggedAt.toISOString(),
    areas: value.areas,
  };
}
