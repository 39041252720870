import {numberFormat} from '../../../../utils/formatter';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {SeaboVessel} from '../../../SearchPicker/Type';

export const aisVesselDwtColumn: CustomColumnDef<SeaboVessel> = {
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        Size
        <br />
        (DWT)
      </div>
    </div>
  ),
  id: 'dwtSummer',
  accessorKey: 'dwtSummer',
  accessorFn: props => (
    <div data-sort={props.dwtSummer}>
      <div>{numberFormat(props.dwtSummer, {emptyString: '-'})}</div>
    </div>
  ),
  cell: info => info.getValue(),
  minWidth: 2,
  enableSorting: true,
  sortingFn: (a, b) => {
    return a.original.dwtSummer - b.original.dwtSummer;
  },
};
