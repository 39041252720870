import {GridMetaData} from '../GridMetaData';
import {MarketVesselActionButton} from '../ClipboardButton/MarketVesselActionButton';
import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import animateScrollTo from 'animated-scroll-to';
import {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useMarketVesselsQuery} from '../../../queries/market/useMarketVesselsQuery';
import {vesselOfferCollectionSelector} from '../../../redux/ApiService/MarketService';
import {MarketActions, VesselGridLayoutMode} from '../../../redux/Market';
import {useSelector} from '../../../redux/react-redux';
import {VesselGrid} from './VesselGrid/VesselGrid';
import {useGetEasySharingState} from '../../../hooks/useGetEasySharingState';
import {useSetGridMetaData} from '../utils/useSetGridMetaData';
import {MarketVesselFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';

export const MarketVesselGrid: FC<{
  vesselFilterProviderApi: FilterProviderApi<typeof MarketVesselFilterBranchDefinitions>;
  displayedLayoutMode: VesselGridLayoutMode;
  onVesselsLoadeded: () => void;
}> = ({vesselFilterProviderApi, displayedLayoutMode, onVesselsLoadeded}) => {
  const dispatch = useDispatch();

  const clipboardState = useSelector(state => state.gridClipboard.market);
  const vesselOffers = useSelector(state => vesselOfferCollectionSelector(state));
  const {vessel: vesselMeta} = useSelector(state => state.market);
  const {vesselFilterSettings} = useSelector(state => state.marketFilters);
  const {isEasySharingLoading, isEasySharingSetupComplete} = useGetEasySharingState();

  const setVesselGridMetaData = useSetGridMetaData('vessel');
  const setFilterUiOpen = () => dispatch(MarketActions.setFilterUiOpen(true));
  const goToFilters = () => {
    setFilterUiOpen();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    animateScrollTo(0);
  };

  const vesselsQueryResult = useMarketVesselsQuery({
    page: vesselMeta.page,
    pageSize: vesselMeta.pageSize,
    sortedBy: vesselMeta.sortedBy,
    filterSettings: vesselFilterSettings,
  });

  useEffect(() => {
    if (vesselsQueryResult.isSuccess && vesselsQueryResult.data) {
      onVesselsLoadeded();
    }
  }, [vesselsQueryResult.isSuccess, vesselsQueryResult.data, onVesselsLoadeded]);

  return (
    <>
      <VesselGrid
        onSettingChange={(gridMetaData: GridMetaData) => {
          setVesselGridMetaData(gridMetaData);
        }}
        isFiltered={vesselFilterProviderApi.isAnyFilterApplied}
        isSubMarket={!!vesselFilterProviderApi.activeFilterId}
        vesselOffers={vesselOffers}
        displayedLayoutMode={displayedLayoutMode}
        gotoFilter={goToFilters}
        sharedCircularsBlurred={isEasySharingLoading || !isEasySharingSetupComplete}
        onGridDataChange={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          vesselsQueryResult.refetch();
        }}
      />
      <MarketVesselActionButton
        items={clipboardState.vessels}
        reloadGrid={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          vesselsQueryResult.refetch();
          setVesselGridMetaData({page: 1});
        }}
      />
    </>
  );
};
