import React, {FC, ReactNode} from 'react';
import FixtureEmptyState from '../../../atoms/EmptyState/Fixture/FixtureEmptyState';
import FixtureService from '../../../redux/ApiService/FixtureService';
import {GridState} from '../../../redux/FixtureData';
import {ModalActions} from '../../../redux/Modal';
import {useDispatch} from '../../../redux/react-redux';
import NoResults from '../../../screens/market/EmptyState/NoResults';
import LoadingComponent from '../../DataGrid/LoadingComponent';
import {getFixtureGridColumns} from './getFixtureGridColumns';
import {FixturesQueryResult, invalidateFixturesQuery} from './useFixturesQuery';
import {CustomColumnDef, DataGrid} from '../../DataGrid/DataGrid';
import {useQueryClient} from '@tanstack/react-query';
import {TODO} from '../../../utils/TODO';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import {Fixture} from '../../../screens/VesselDetailScreen/VesselFixtures/fixtureType';
import ManageFixtureModal, {ManageFixtureProps} from '../../Modal/ManageFixture/ManageFixtureModal';

export const FixtureGrid: FC<{
  vesselId?: number;
  modalCallback?: (event: TODO) => void;
  gridState: GridState;
  setGridState: (payload: TODO) => void;
  className?: string;
  appliedFilters: undefined | Record<string, boolean>;
  fetching: boolean;
  fixtureQuery: FixturesQueryResult;
}> = ({modalCallback, fixtureQuery, className, appliedFilters, fetching, gridState, setGridState}) => {
  const [, setLocalStoragePageSize] = useLocalStorage('seabo_fixtures_page_size', 50);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const deleteFixture = async (id: number) => {
    const response = await dispatch(FixtureService.deleteFixture(id));
    await invalidateFixturesQuery(queryClient);
    return response;
  };

  const gridColumns = getFixtureGridColumns({
    modalCallback: async (params: TODO) => {
      modalCallback?.(params);
      await invalidateFixturesQuery(queryClient);
    },
    showModal,
    showManageFixtureModal: (props: ManageFixtureProps) => {
      return dispatch(ModalActions.show(<ManageFixtureModal {...props} />));
    },
    deleteFixture,
  }) as CustomColumnDef<Fixture>[];

  const getEmptyState = () => {
    if (fixtureQuery.isLoading) {
      return <LoadingComponent style={{textAlign: 'center'}} />;
    }
    if (fixtureQuery.data?.items.length) {
      return null;
    }

    if (
      appliedFilters &&
      Object.values(appliedFilters).filter(v => v).length > 0 &&
      fixtureQuery.data?.items.length === 0
    ) {
      return <NoResults type={'fixture'} />;
    }
    return <FixtureEmptyState />;
  };

  return (
    <div className={className} data-cy={'VesselFixtureGrid'}>
      <DataGrid<Fixture>
        className={'datagrid-fixtures'}
        columns={gridColumns}
        data={fixtureQuery.data?.items || []}
        totalCount={fixtureQuery.data?.totalItems}
        pageSize={gridState.pageSize}
        page={gridState.pageIndex}
        loading={fixtureQuery.isLoading}
        fetching={fetching}
        TbodyComponent={getEmptyState()}
        responsive
        showJumper
        zeroBasedPageIndex={false}
        sorted={[{id: gridState.sortField, desc: gridState.sortOrder === 'desc'}]}
        onPageChange={page => {
          setGridState({pageIndex: page});
        }}
        onSortedChange={sorted => {
          const {id, desc} = sorted;
          setGridState({sortField: id, sortOrder: desc ? 'desc' : 'asc', pageIndex: 1});
        }}
        onPageSizeChange={pageSize => {
          setLocalStoragePageSize(pageSize);
          setGridState({pageSize, pageIndex: 1});
        }}
        noHoverStatePointer
      />
    </div>
  );
};
