export const commodityCategories = [
  {
    slug: 'stone-aggregate',
    name: 'Aggregate',
    isArchived: false,
  },
  {
    slug: 'agricultural',
    name: 'Agricultural Products',
    isArchived: false,
  },
  {
    slug: 'ash',
    name: 'Ash',
    isArchived: false,
  },
  {
    slug: 'chemical',
    name: 'Chemical',
    isArchived: false,
  },
  {
    slug: 'coal',
    name: 'Coal',
    isArchived: false,
  },
  {
    slug: 'concentrate',
    name: 'Concentrate',
    isArchived: false,
  },
  {
    slug: 'feedstock',
    name: 'Feedstuff',
    isArchived: false,
  },
  {
    slug: 'fertilizer',
    name: 'Fertilizer',
    isArchived: false,
  },
  {
    slug: 'grain',
    name: 'Grain',
    isArchived: false,
  },
  {
    slug: 'iron-ore',
    name: 'Iron Ore Products',
    isArchived: false,
  },
  {
    slug: 'metal',
    name: 'Metal',
    isArchived: false,
  },
  {
    slug: 'minerals',
    name: 'Minerals',
    isArchived: false,
  },
  {
    slug: 'ore',
    name: 'Ore',
    isArchived: false,
  },
  {
    slug: 'other',
    name: 'Other',
    isArchived: false,
  },
  {
    slug: 'petcoke',
    name: 'Petcoke',
    isArchived: false,
  },
  {
    slug: 'salt',
    name: 'Salt',
    isArchived: false,
  },
  {
    slug: 'scrap',
    name: 'Scrap',
    isArchived: false,
  },
  {
    slug: 'slag',
    name: 'Slag',
    isArchived: false,
  },
  {
    slug: 'steels-generals',
    name: 'Steels / Generals',
    isArchived: false,
  },
  {
    slug: 'sugar',
    name: 'Sugar',
    isArchived: false,
  },
  {
    slug: 'sulphur',
    name: 'Sulphur',
    isArchived: false,
  },
  {
    slug: 'vehicles',
    name: 'Vehicles',
    isArchived: false,
  },
  {
    slug: 'forest-products',
    name: 'Wood Products',
    isArchived: false,
  },
];
