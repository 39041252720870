/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationActor,
  NegotiationActorFromJSON,
  NegotiationActorFromJSONTyped,
  NegotiationActorToJSON,
  NegotiationParty,
  NegotiationPartyFromJSON,
  NegotiationPartyFromJSONTyped,
  NegotiationPartyToJSON,
  NegotiationStatus,
  NegotiationStatusFromJSON,
  NegotiationStatusFromJSONTyped,
  NegotiationStatusToJSON,
  NegotiationTerm,
  NegotiationTermFromJSON,
  NegotiationTermFromJSONTyped,
  NegotiationTermToJSON,
  TermModification,
  TermModificationFromJSON,
  TermModificationFromJSONTyped,
  TermModificationToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationTermHistoryItem
 */
export interface NegotiationTermHistoryItem {
  /**
   *
   * @type {NegotiationTerm}
   * @memberof NegotiationTermHistoryItem
   */
  term: NegotiationTerm;
  /**
   *
   * @type {Date}
   * @memberof NegotiationTermHistoryItem
   */
  committedAt: Date | null;
  /**
   *
   * @type {NegotiationActor}
   * @memberof NegotiationTermHistoryItem
   */
  committedBy: NegotiationActor | null;
  /**
   *
   * @type {NegotiationStatus}
   * @memberof NegotiationTermHistoryItem
   */
  negotiationStatus: NegotiationStatus;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationTermHistoryItem
   */
  party: NegotiationParty;
  /**
   * The term has changed since the last round.
   * @type {boolean}
   * @memberof NegotiationTermHistoryItem
   */
  changed: boolean;
  /**
   *
   * @type {TermModification}
   * @memberof NegotiationTermHistoryItem
   */
  termModification: TermModification;
  /**
   *
   * @type {string}
   * @memberof NegotiationTermHistoryItem
   */
  roundResultId: string;
}

export function NegotiationTermHistoryItemFromJSON(json: any): NegotiationTermHistoryItem {
  return NegotiationTermHistoryItemFromJSONTyped(json, false);
}

export function NegotiationTermHistoryItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationTermHistoryItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    term: NegotiationTermFromJSON(json['term']),
    committedAt: json['committedAt'],
    committedBy: NegotiationActorFromJSON(json['committedBy']),
    negotiationStatus: NegotiationStatusFromJSON(json['negotiationStatus']),
    party: NegotiationPartyFromJSON(json['party']),
    changed: json['changed'],
    termModification: TermModificationFromJSON(json['termModification']),
    roundResultId: json['roundResultId'],
  };
}

export function NegotiationTermHistoryItemToJSON(value?: NegotiationTermHistoryItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    term: NegotiationTermToJSON(value.term),
    committedAt: value.committedAt,
    committedBy: NegotiationActorToJSON(value.committedBy),
    negotiationStatus: NegotiationStatusToJSON(value.negotiationStatus),
    party: NegotiationPartyToJSON(value.party),
    changed: value.changed,
    termModification: TermModificationToJSON(value.termModification),
    roundResultId: value.roundResultId,
  };
}
