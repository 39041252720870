import {FC, useMemo} from 'react';
import {Breakpoint, BREAKPOINT_COLUMNS, BREAKPOINTS, WidgetsApi} from './useWidgetsApi';
import {ResponsiveGridLayout} from '../widgets/components/ResponsiveGridLayout';
import {ErrorBoundary} from '../../../components/Sentry/ErrorBoundary';
import WidgetLoader from './WidgetLoader';
import {assert} from '../../../utils/assert';

export const DashboardWidgets: FC<{widgetsApi: WidgetsApi}> = ({widgetsApi}) => {
  const memoizedWidgets = useMemo(
    () =>
      widgetsApi.installedWidgets.map(widget => (
        <div key={widget.id!.toString()}>
          <ErrorBoundary location={`DashboardScreen/${widget.id}`}>
            <WidgetLoader
              widgetIdentifier={widget.identifier}
              id={widget.id}
              settings={widget.settings}
              onRemove={() => widgetsApi.removeWidget(widget.id!)}
            />
          </ErrorBoundary>
        </div>
      )),
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [widgetsApi.installedWidgets]
  );

  if (widgetsApi.loading) {
    return null;
  }

  assert(widgetsApi.layouts);
  assert(Object.keys(widgetsApi.layouts).length > 0);
  assert(widgetsApi.installedWidgets.length === widgetsApi.layouts['xxs'].length);

  return (
    <ResponsiveGridLayout
      className="dashboard__grid"
      draggableHandle=".draggable"
      rowHeight={300}
      layouts={widgetsApi.layouts}
      onBreakpointChange={breakpoint => {
        widgetsApi.setCurrentBreakpoint(breakpoint as Breakpoint);
      }}
      breakpoints={BREAKPOINTS}
      cols={BREAKPOINT_COLUMNS}
      margin={[10, 10]}
      containerPadding={[0, 0]}
      onLayoutChange={layouts => {
        widgetsApi.updateLayouts(layouts);
      }}>
      {memoizedWidgets}
    </ResponsiveGridLayout>
  );
};
