/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {AisVesselPort, AisVesselPortFromJSON, AisVesselPortFromJSONTyped, AisVesselPortToJSON} from './';

/**
 *
 * @export
 * @interface AisVessel
 */
export interface AisVessel {
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  mmsi?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  imo?: number;
  /**
   *
   * @type {string}
   * @memberof AisVessel
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof AisVessel
   */
  type?: string | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  course?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  heading?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  speed?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  dwt?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  teu?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  loa?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  beam?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  d?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  dm?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  o?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AisVessel
   */
  coordinates?: Array<number>;
  /**
   *
   * @type {AisVesselPort}
   * @memberof AisVessel
   */
  lastPort?: AisVesselPort | null;
  /**
   *
   * @type {AisVesselPort}
   * @memberof AisVessel
   */
  destinationPort?: AisVesselPort | null;
  /**
   *
   * @type {Date}
   * @memberof AisVessel
   */
  eta?: Date | null;
  /**
   *
   * @type {number}
   * @memberof AisVessel
   */
  vesselId?: number | null;
  /**
   *
   * @type {Date}
   * @memberof AisVessel
   */
  updatedAt?: Date | null;
}

export function AisVesselFromJSON(json: any): AisVessel {
  return AisVesselFromJSONTyped(json, false);
}

export function AisVesselFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisVessel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mmsi: !exists(json, 'mmsi') ? undefined : json['mmsi'],
    imo: !exists(json, 'imo') ? undefined : json['imo'],
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    course: !exists(json, 'course') ? undefined : json['course'],
    heading: !exists(json, 'heading') ? undefined : json['heading'],
    speed: !exists(json, 'speed') ? undefined : json['speed'],
    dwt: !exists(json, 'dwt') ? undefined : json['dwt'],
    teu: !exists(json, 'teu') ? undefined : json['teu'],
    loa: !exists(json, 'loa') ? undefined : json['loa'],
    beam: !exists(json, 'beam') ? undefined : json['beam'],
    d: !exists(json, 'd') ? undefined : json['d'],
    dm: !exists(json, 'dm') ? undefined : json['dm'],
    o: !exists(json, 'o') ? undefined : json['o'],
    coordinates: !exists(json, 'coordinates') ? undefined : json['coordinates'],
    lastPort: !exists(json, 'lastPort') ? undefined : AisVesselPortFromJSON(json['lastPort']),
    destinationPort: !exists(json, 'destinationPort') ? undefined : AisVesselPortFromJSON(json['destinationPort']),
    eta: !exists(json, 'eta') ? undefined : json['eta'] === null ? null : new Date(json['eta']),
    vesselId: !exists(json, 'vesselId') ? undefined : json['vesselId'],
    updatedAt: !exists(json, 'updatedAt') ? undefined : json['updatedAt'] === null ? null : new Date(json['updatedAt']),
  };
}

export function AisVesselToJSON(value?: AisVessel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mmsi: value.mmsi,
    imo: value.imo,
    name: value.name,
    type: value.type,
    course: value.course,
    heading: value.heading,
    speed: value.speed,
    dwt: value.dwt,
    teu: value.teu,
    loa: value.loa,
    beam: value.beam,
    d: value.d,
    dm: value.dm,
    o: value.o,
    coordinates: value.coordinates,
    lastPort: AisVesselPortToJSON(value.lastPort),
    destinationPort: AisVesselPortToJSON(value.destinationPort),
    eta: value.eta === undefined ? undefined : value.eta === null ? null : value.eta.toISOString(),
    vesselId: value.vesselId,
    updatedAt:
      value.updatedAt === undefined ? undefined : value.updatedAt === null ? null : value.updatedAt.toISOString(),
  };
}
