import {FC} from 'react';
import {TODO} from '../../../utils/TODO';
import {DeliveredProcedureOutlined} from '@ant-design/icons';
import GridTooltip from '../GridTooltip/index';
import {CircularSenderCompanyUser} from './CircularSenderCompanyUser';
import styled from 'styled-components';

interface Props {
  circular: TODO;
}

export const CircularSender: FC<Props> = ({circular}) => {
  const isPostedByUser = !!circular.user?.id;

  if (isPostedByUser) {
    return (
      <StyledRow>
        <IconContainer>
          <DeliveredProcedureOutlined style={{fontSize: 18}} />
        </IconContainer>
        <CircularSenderCompanyUser circularSenderUser={circular.user} />
      </StyledRow>
    );
  }
  return (
    <GridTooltip title={circular.originSender}>
      <SenderContainer>{circular.originSender}</SenderContainer>
    </GridTooltip>
  );
};

const SenderContainer = styled.div`
  max-width: 100%;
  line-height: 1.125;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  width: 26px;
  height: 24px;
  padding-left: 2px;
  align-items: center;
  margin-right: 5px;
`;
