import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {defaultVoyageTimelineFilters, VoyageTimelineFilters} from './utils/filters';
import {VoyageTimelineTypeFilter} from './VoyageTimelineTypeFilter';
import {VesselDetailsVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import Loading from '../../../atoms/Loading';
import {VoyageTimelineList} from './VoyageTimelineList';
import dayjs, {Dayjs} from 'dayjs';
import {calculateVoyageTimeline} from './utils/calculateVoyageTimeline';
import {useGetVoyageTimelineQuery} from './utils/useGetVoyageTimelineQuery';
import {useGetDraftChartQuery} from './utils/useGetDraftChartQuery';
import {calculateVoyageTimeSeries, VoyageTimelineRange} from './utils/calculateVoyageTimeSeries';
import {Center} from '../../../components/Center/Center';
import styled from 'styled-components';
import {TimelineEvent} from './utils/types';
import {getEventDate, getMinVoyageTimelineDate, initialVoyageTimelineRange} from './utils/utils';
import {VoyageTimelineAnimation} from '../VoyageTimeline/VoyageTimelineAnimation/VoyageTimelineAnimation/VoyageTimelineAnimation';
import {CoordinatesObject} from '../../../utils/Coordinates';

type VoyageTimelineProps = {
  vessel: VesselDetailsVessel;
};

export const VoyageTimeline: FC<VoyageTimelineProps> = ({vessel}) => {
  const [voyageTimelineFilters, setVoyageTimelineFilters] =
    useState<VoyageTimelineFilters>(defaultVoyageTimelineFilters);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs().endOf('day'));
  const [selectedEvent, setSelectedEvent] = useState<TimelineEvent | null>(null);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const voyageTimelineMaxRange = useRef<VoyageTimelineRange | null>(null);
  const minDate = voyageTimelineMaxRange.current?.[0] ?? dayjs().endOf('day');
  const queryRange = voyageTimelineMaxRange.current ?? initialVoyageTimelineRange;

  const voyageTimelineQuery = useGetVoyageTimelineQuery(vessel.imo, queryRange);

  const draftChartQuery = useGetDraftChartQuery(vessel.imo);

  useEffect(() => {
    if (voyageTimelineQuery.isSuccess && voyageTimelineQuery.data && !voyageTimelineMaxRange.current) {
      const minDate = getMinVoyageTimelineDate(voyageTimelineQuery.data);
      const maxDate = dayjs().endOf('day');
      voyageTimelineMaxRange.current = [minDate, maxDate];
      // Added because useRef was used, which does not trigger a re-render.
      setForceUpdate(forceUpdate + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voyageTimelineQuery.data, voyageTimelineQuery.isSuccess]);

  const {voyageTimelineItems, eventsAvailable, maxDraft, maxDuration} = useMemo(() => {
    return calculateVoyageTimeline(voyageTimelineQuery.data, voyageTimelineFilters);
  }, [voyageTimelineQuery.data, voyageTimelineFilters]);

  const voyageTimeSeries = useMemo(() => {
    return calculateVoyageTimeSeries(draftChartQuery.data);
  }, [draftChartQuery.data]);

  const onToggleFilter = (filter: keyof VoyageTimelineFilters) => {
    setVoyageTimelineFilters({
      ...voyageTimelineFilters,
      [filter]: !voyageTimelineFilters[filter],
    });
  };

  const handleSelectEvent = (event: TimelineEvent | null) => {
    setIsPlaying(false);
    setSelectedEvent(event);

    // Synchronize the selected date with the selected event
    setSelectedDate(getEventDate(event));

    if (autoPlay) {
      // Give player and user some time to adjust to the selected event
      setTimeout(() => {
        setIsPlaying(true);
      }, 300);
    }
  };

  const findEventAtDate = (date: Dayjs) => {
    // Synchronize the selected event with the selected date
    // Note: voyageTimelineItems are sorted by timestamps in descending order
    const targetDate = date;
    const eventBeforeTargetDateIndex = voyageTimelineItems.findIndex(({enterTimestamp}) =>
      dayjs(enterTimestamp).isBefore(targetDate)
    );
    const eventAtDate = voyageTimelineItems[eventBeforeTargetDateIndex] ?? voyageTimelineItems[0];
    return eventAtDate;
  };

  const handleChangeSelectedDate = (selectedDate: Dayjs) => {
    setSelectedDate(selectedDate);

    // Synchronize the selected event with the selected date
    // Note: voyageTimelineItems are sorted by timestamps in descending order
    const targetDate = selectedDate.endOf('day');
    const eventBeforeTargetDateIndex = voyageTimelineItems.findIndex(({enterTimestamp}) =>
      dayjs(enterTimestamp).isBefore(targetDate)
    );
    const eventAtDate = voyageTimelineItems[eventBeforeTargetDateIndex] ?? voyageTimelineItems[0];
    if (eventAtDate) {
      setSelectedEvent(eventAtDate.type === 'port' ? eventAtDate.portVisit : eventAtDate.route);
    }
  };

  const animationTimeframe = useMemo(() => {
    const animationStartDate = selectedDate;
    let animationEndDate = animationStartDate.add(1, 'year');
    if (animationEndDate.isAfter(dayjs())) {
      animationEndDate = dayjs();
    }
    return {start: animationStartDate, end: animationEndDate};
  }, [selectedDate]);
  const vesselCoordinates: CoordinatesObject | null = vessel.aisDetails
    ? {lat: vessel.aisDetails.latitude, lon: vessel.aisDetails.longitude}
    : null;

  return (
    <div data-testid="VoyageTimeline">
      <VoyageTimelineTypeFilter
        imo={vessel.imo}
        eventsAvailable={eventsAvailable}
        voyageTimelineFilters={voyageTimelineFilters}
        onToggleFilter={onToggleFilter}
      />
      <TimelineContainer>
        {voyageTimelineQuery.isSuccess && draftChartQuery.isSuccess ? (
          <>
            <ChartContainer>
              <VoyageTimelineList
                voyageTimelineItems={voyageTimelineItems}
                filters={voyageTimelineFilters}
                maxDuration={maxDuration}
                maxDraft={maxDraft}
                style={{overflowY: 'scroll'}}
                selectedEvent={selectedEvent}
                onSelectEvent={handleSelectEvent}
              />
            </ChartContainer>
            <MapContainer>
              <VoyageTimelineAnimation
                imo={vessel.imo}
                vesselType={vessel.vesselType}
                vesselCoordinates={vesselCoordinates}
                timeframe={animationTimeframe}
                portVisits={voyageTimelineQuery.data.portVisits}
                isPlaying={isPlaying}
                autoPlay={autoPlay}
                voyageTimeSeries={voyageTimeSeries}
                minDate={minDate}
                selectedDate={selectedDate}
                onChangeSelectedDate={handleChangeSelectedDate}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onToggleAutoPlay={() => setAutoPlay(!autoPlay)}
                onAnimationTimestampUpdate={timestamp => {
                  const date = dayjs(timestamp);
                  const eventAtDate = findEventAtDate(date);
                  setSelectedEvent(eventAtDate.type === 'port' ? eventAtDate.portVisit : eventAtDate.route);
                }}
              />
            </MapContainer>
          </>
        ) : (
          <Center style={{height: '100%'}}>
            <Loading margin={'0 auto'} height="100%" data-testid="VoyageTimelineAnimationLoading" />
          </Center>
        )}
      </TimelineContainer>
    </div>
  );
};

const TimelineContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: calc(100vh - 400px);
`;

const ChartContainer = styled.div`
  flex: 50%;
  min-width: 700px;
  max-width: 800px;
  height: 100%;
`;

const MapContainer = styled.div`
  flex: 50%;
  height: 100%;
`;
