import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../api/utils/fetchJSON';

export type DraftItem = {
  imo: number;
  minDraft: number;
  maxDraft: number;
  draft25: number;
  draft50: number;
  draft75: number;
};

export const useDraftStatsQuery = () =>
  useQuery({
    queryKey: ['draftStats'],
    queryFn: async () => {
      const draftData = await fetchJSON<DraftItem[]>(
        'https://srv20110.dus4.fastwebserver.de/static/minMax25erDraft.json'
      );
      return draftData;
    },
    refetchOnMount: false,
    staleTime: Infinity,
  });
