import {DownloadOutlined, UploadOutlined} from '@ant-design/icons';
import {Col, Form, Row, Select} from 'antd';
import numeral from 'numeral';
import {useContext} from 'react';
import {InputNumberAcceptCommaAndDot} from '../../../../antd/components/InputNumberAcceptCommaAndDot';
import {ContractType} from '../../../../api/symfony/generated';
import Flag from '../../../../atoms/Flags';
import {useSelector} from '../../../../redux/react-redux';
import {getIsPayingCustomer} from '../../../../redux/selectors';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {regexNotIncludeMinusSign} from '../../../../utils/regexExpressions';
import {CargoPickerWithHexagon} from '../../../CargoPicker/CargoPickerWithHexagon';
import {StyledRangePicker} from '../../../RangePicker/StyledRangePicker';
import {OfferMailModalButton} from '../components/OfferMailModalButton';
import {PropertyBox} from '../components/PropertyBox';
import {StyledCard} from '../components/StyledCard';
import {StyledFormItem} from '../components/StyledFormItem';
import dayjs from 'dayjs';
import {
  WidgetTitle,
  WidgetTitleBasicInformation,
  WidgetTitleBasicProperty,
  WidgetTitleMailContainer,
  WidgetTitleProperties,
} from '../components/WidgetTitle';
import {useRemoveCargoFromCargoInputAndVoyage} from '../Context/utils/useCharterCalculationState/useRemoveCargoFromCargoInputAndVoyage';
import {useSetCargoToCargoInputAndVoyageInput} from '../Context/utils/useCharterCalculationState/useSetCargoToCargoInputAndVoyageInput';
import {useUpdateCargoInput} from '../Context/utils/useCharterCalculationState/useUpdateCargoInput';
import {useUpdateVoyageInput} from '../Context/utils/useCharterCalculationState/useUpdateVoyageInput';
import {useGetDischargeVoyagePointsAndIndexes} from '../Context/utils/useCharterCalculationState/VoyagePoint/useGetDischargeVoyagePointsAndIndexes';
import {useGetLoadingVoyagePointsAndIndex} from '../Context/utils/useCharterCalculationState/VoyagePoint/useGetLoadingVoyagePointsAndIndex';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {CargoInput, FreightUnit} from '../utils/CargoTypes';
import {mapCargoToCargoInput} from '../utils/mapCargoToCargoInput';
import {mapCargoToVoyageInput} from '../utils/mapCargoToVoyageInput/mapCargoToVoyageInput';
import {Warning} from '../Warning/Warning';
import {getPortParamsForFirst, PortsEdit} from './PortsEdit';
import {StowageFactorInput} from './StowageFactorInput';
import styled from 'styled-components';
import {NotAvailable} from '../../../../utils/NotAvailable';
import {useStowageFactorsQuery} from '../../../StowageFactorPicker/useStowageFactorsQuery';
import {assert} from '../../../../utils/assert';

export const CargoInformation = (props: {showBody: boolean; onChangeShowBody: (showBody: boolean) => void}) => {
  const isPayingCustomer = useSelector(getIsPayingCustomer);
  const voyageCharterContext = useContext(VoyageCharterContext);
  const stowageFactorsQuery = useStowageFactorsQuery();

  const {inputState} = voyageCharterContext.state;

  const [form] = Form.useForm();
  const removeCargoFromCargoInputAndVoyage = useRemoveCargoFromCargoInputAndVoyage();
  const setCargoToCargoInputAndVoyageInput = useSetCargoToCargoInputAndVoyageInput();
  const updateVoyageInput = useUpdateVoyageInput();
  const updateCargoInput = useUpdateCargoInput();
  const {cargo, voyage} = inputState;

  const loadingVoyagePoints = useGetLoadingVoyagePointsAndIndex();
  const dischargeVoyagePoints = useGetDischargeVoyagePointsAndIndexes();

  const loadingPort = getPortParamsForFirst(loadingVoyagePoints);
  const dischargePort = getPortParamsForFirst(dischargeVoyagePoints);

  const laycanFrom = inputState.voyage.laycanFrom ? dayjs(inputState.voyage.laycanFrom?.toDate()) : null;
  const laycanTo = inputState.voyage.laycanTo ? dayjs(inputState.voyage.laycanTo?.toDate()) : null;

  return (
    <CustomStyledCard
      loading={stowageFactorsQuery.isLoading}
      $showBody={props.showBody}
      style={{width: '100%', paddingTop: 10}}
      title={
        <WidgetTitle
          icon="box-open"
          title="Cargo"
          showBody={props.showBody}
          onToggleBody={() => {
            props.onChangeShowBody(!props.showBody);
          }}>
          <WidgetTitleBasicInformation
            name={cargo.cargoProduct || 'Select a cargo...'}
            link={cargo.id ? `/cargo/${cargo.id}` : undefined}
          />
          {isPayingCustomer && (
            <WidgetTitleMailContainer>
              <OfferMailModalButton id={cargo.id} type={'cargo'} />
            </WidgetTitleMailContainer>
          )}
          <WidgetTitleProperties>
            <WidgetTitleBasicProperty data-cy="quantityDisplay">
              {(cargo.cargoQuantity ? numeral(cargo.cargoQuantity).format('0,0') : NotAvailable) + ' MTS'}
            </WidgetTitleBasicProperty>
            <WidgetTitleBasicProperty data-cy="laycanDisplay" style={{textAlign: 'right'}}>
              {voyage.laycanFrom ? voyage.laycanFrom.format(DateTimeFormat.Date) : NotAvailable} -{' '}
              {voyage.laycanTo ? voyage.laycanTo.format(DateTimeFormat.Date) : NotAvailable}
            </WidgetTitleBasicProperty>
          </WidgetTitleProperties>
          <WidgetTitleProperties style={{marginTop: '17px'}}>
            <PropertyBox
              data-cy="loadingDisplay"
              title="Loading"
              backgroundColor="var(--color-gray-5)"
              icon={<DownloadOutlined style={{fontSize: 18, color: 'var(--color-gray-2)'}} />}>
              {loadingPort ? (
                <span>
                  <Flag countryCode={loadingPort.countryObject.code} border />
                  <span style={{marginLeft: 4}}> {loadingPort.name}</span>
                </span>
              ) : (
                NotAvailable
              )}
            </PropertyBox>
            <PropertyBox
              data-cy="dischargingDisplay"
              title="Discharging"
              backgroundColor="var(--color-gray-5)"
              icon={<UploadOutlined style={{fontSize: 18, color: 'var(--color-gray-2)'}} />}>
              {dischargePort ? (
                <span>
                  <Flag countryCode={dischargePort.countryObject.code} border />
                  <span style={{marginLeft: 4}}> {dischargePort.name}</span>
                </span>
              ) : (
                NotAvailable
              )}
            </PropertyBox>
          </WidgetTitleProperties>
        </WidgetTitle>
      }>
      <Form<CargoInput> initialValues={cargo} form={form} style={{flex: 1}} layout={'vertical'}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <StyledFormItem label={'Cargo:'}>
              <CargoPickerWithHexagon
                indexNames={'cargoes_portfolio'}
                paramContractType={ContractType.Vc}
                cargo={
                  cargo.cargoProduct
                    ? {
                        name: cargo.cargoProduct,
                        contractType: cargo.contractType,
                      }
                    : undefined
                }
                onChange={async newCargo => {
                  if (newCargo === undefined) {
                    removeCargoFromCargoInputAndVoyage();
                    return;
                  }
                  assert(stowageFactorsQuery.data);
                  setCargoToCargoInputAndVoyageInput({
                    voyageInput: mapCargoToVoyageInput({cargo: newCargo}),
                    cargoInput: mapCargoToCargoInput({cargo: newCargo, stowageFactors: stowageFactorsQuery.data}),
                  });
                }}
              />
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem
              label={'Quantity:'}
              rules={[
                {
                  message: 'Quantity must be greater than or equal to 0.',
                  pattern: regexNotIncludeMinusSign,
                },
              ]}>
              <InputNumberAcceptCommaAndDot
                min={0}
                data-cy={'cargoQuantity'}
                value={cargo.cargoQuantity}
                onChange={value => {
                  updateCargoInput({
                    cargoInput: {
                      cargoQuantity: value,
                    },
                  });
                }}
                type={'number'}
                addonAfter={'mts'}
              />
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem
              label={'Stowage factor  (SF):'}
              rules={[
                {
                  message: 'Stowage factor must be greater than or equal to 0.',
                  pattern: regexNotIncludeMinusSign,
                },
              ]}>
              <StowageFactorInput
                onChangeStowageFactor={value => {
                  updateCargoInput({
                    cargoInput: {
                      stowageFactor: value,
                    },
                  });
                }}
                stowageFactor={cargo.stowageFactor}
                stowageFactorDisplayUnit={cargo.stowageFactorDisplayUnit}
                onChangeDisplayUnit={unit => {
                  updateCargoInput({
                    cargoInput: {
                      stowageFactorDisplayUnit: unit,
                    },
                  });
                }}
              />
            </StyledFormItem>
          </Col>
          <Col span={24}>
            <StyledFormItem label={'From/Laydays - To/Cancelling'}>
              <StyledRangePicker
                data-cy={'laycanFrom laycanTo'}
                id={'filter-dateopen-date filter-dateopen-date-to'}
                format={DateTimeFormat.Date}
                value={[laycanFrom, laycanTo]}
                getPopupContainer={() => {
                  return document.getElementById('laycanFromTo')!;
                }}
                placeholder={['From/Laydays', 'To/Canceling']}
                onChange={value => {
                  const [from, to] = (value ?? [null, null]).values();
                  const fromMoment = from ? from : undefined;
                  const toMoment = to ? to : undefined;
                  updateVoyageInput({
                    voyageInput: {
                      laycanFrom: fromMoment,
                      laycanTo: toMoment,
                    },
                  });
                }}
              />
              <div id="laycanFromTo" />
            </StyledFormItem>
          </Col>

          <PortsEdit />
          <Col span={12}>
            <StyledFormItem label={'Freight idea:'} rules={[]}>
              <InputNumberAcceptCommaAndDot
                value={cargo.freightIdea}
                data-cy={'freightIdea'}
                prefix={'$'}
                min={0}
                onChange={value => {
                  updateCargoInput({
                    cargoInput: {
                      freightIdea: value,
                    },
                  });
                }}
                type={'number'}
                addonAfter={
                  <Select<FreightUnit>
                    data-cy="freightUnitSelect"
                    onSelect={(value: FreightUnit) => {
                      updateCargoInput({
                        cargoInput: {
                          freightUnit: value,
                        },
                      });
                    }}
                    value={cargo.freightUnit}
                    options={[
                      {label: 'per mts', value: 'perMts'},
                      {label: 'per Day', value: 'perDay'},
                      {label: 'lumpsum', value: 'lumpsum'},
                    ]}
                    size="small"
                  />
                }
              />
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem label={'Total commission:'} rules={[]}>
              <InputNumberAcceptCommaAndDot
                value={cargo.totalCommission}
                min={0}
                data-cy={'totalCommission'}
                onChange={value => {
                  updateCargoInput({
                    cargoInput: {
                      totalCommission: value,
                    },
                  });
                }}
                type={'number'}
                addonAfter={'%'}
              />
            </StyledFormItem>
          </Col>
        </Row>
        <Warning area={'cargo'} />
      </Form>
    </CustomStyledCard>
  );
};

const CustomStyledCard = styled(StyledCard)<{$showBody?: boolean}>`
  overflow: initial;
`;
