import {FC} from 'react';
import {isTest} from '../../utils/environment';
import {ErrorBoundaryForTest} from './ErrorBoundaryForTest';
import {ErrorBoundaryForProduction} from './ErrorBoundaryForProduction';

interface ErrorBoundaryProps {
  /**
   * The location of the error boundary in our source code.
   * This helps us to tell multiple boundaries apart.
   */
  location: string;
  children: React.ReactNode;
}

/**
 * The error boundary to use in our application.
 */
export const ErrorBoundary: FC<ErrorBoundaryProps> = ({location, children}) =>
  isTest() ? (
    <ErrorBoundaryForTest>{children}</ErrorBoundaryForTest>
  ) : (
    <ErrorBoundaryForProduction location={location}>{children}</ErrorBoundaryForProduction>
  );
