import {actionTypes as portfolioActionTypes} from './ApiService/PortfolioService';
import {actionTypes as vesselActionTypes} from './ApiService/vesselService';
import {actionTypes as cargoActionTypes} from './ApiService/cargoService';
import {assertUnreachable} from '../utils/assert';
import {TODO} from '../utils/TODO';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DesignSubType} from '../screens/VesselDatabaseScreen/VesselDatabaseGrid/VesselGridActionMenu/getDesignSubType';
import {getLocalStorage} from '../utils/useLocalStorage';
import {z} from 'zod';
import {
  PortfolioCargo,
  PortfolioGroup,
} from '../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {VesselStatus} from '../screens/CargoVesselForm/helper';

export type IntakeType = 'teu' | 'teu14' | 'feu' | '45f';

export type Intake = {
  type: IntakeType;
  quantity: number;
  isHighCube: boolean;
};

export interface SubPortfolio {
  id: number;
  name: string;
  sortOrder: number;
}

const VesselTypeSchema = z.union([z.literal('bulker'), z.literal('container'), z.literal('mpp')]);
export type VesselType = z.infer<typeof VesselTypeSchema>;
/**
 * The layout mode that the user chooses.
 * With 'auto', the grid will choose the best layout itself.
 */

export const VesselGridLayoutModeSchema = z.union([VesselTypeSchema, z.literal('auto')]);
export type VesselGridLayoutMode = z.infer<typeof VesselGridLayoutModeSchema>;

type StatsVessel = TODO | null;
type SelectableVesselSubPortfolio = TODO;
type SelectableCargoSubPortfolio = TODO;

export interface Consumption {
  fuelConsumption: number | undefined;
  fuelType: string;
  speed: number | undefined;
  type: string | undefined;
  id?: number;
  extraFuelConsumption?: null | number;
  extraFuelType?: string;
}

export interface PortfolioVessel {
  id: number;
  name: string;
  vesselType?: VesselType;
  imo?: number;
  status: VesselStatus;
  intakes?: Intake[];
  portfolioGroups?: PortfolioGroup[];
  _original?: TODO;

  designSubType?: DesignSubType | null;
  isArchived?: boolean;

  /**
   * The redelivery notice is in days and came from the active fixture for the vessel.
   * Its related to the next open date.
   */
  redeliveryNotice?: number | null;
  consumptions?: Consumption[];
  originVesselId?: number;
  targetType?: 'database' | 'portfolio';
  target: 'portfolio';
  offer?: {
    id: number;
    isArchived: boolean;
  };
  error?: {
    code: number;
  };
  createdAt?: string;
  spotState?: boolean;
  spotDate?: Date | string | null;
  nextOpenFrom?: string | null;
  nextOpenTo?: string | null;
  color: number | null;
  // TODO: More attributes
}

export type SubPortfolioId = 'all' | number;

export type ScreenState = {
  vessels: PortfolioVessel[] | null;
  cargoesTotal: number;
  cargoes: PortfolioCargo[] | null;
  vesselsTotal: number;

  /**
   * The id of the active cargo sub portfolio.
   */
  activeTabCargo: SubPortfolioId;
  /**
   * The id of the active vessel sub portfolio.
   */
  activeTabVessel: SubPortfolioId;
};

export type SortOrder = 'asc' | 'desc';

export interface SortParams {
  sortField: string;
  sortOrder: SortOrder;
}

export interface VesselGridState {
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortOrder: SortOrder;
  gridLayoutMode: VesselGridLayoutMode;
}

export interface CargoGridState {
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortOrder: SortOrder;
}

export interface PortfolioState {
  cargo: PortfolioCargo | null;
  vessel: PortfolioVessel | null;
  statsVessel: StatsVessel;
  selectableVesselSubPortfolios: SelectableVesselSubPortfolio[];
  selectableCargoSubPortfolios: SelectableCargoSubPortfolio[];
  screenState: ScreenState;
  vesselGridState: VesselGridState;
  cargoGridState: CargoGridState;
}

const initialState: PortfolioState = {
  cargo: null,
  vessel: null,
  statsVessel: null,
  selectableVesselSubPortfolios: [],
  selectableCargoSubPortfolios: [],
  screenState: {
    vessels: null,
    cargoesTotal: 0,
    cargoes: null,
    vesselsTotal: 0,
    activeTabCargo: 'all',
    activeTabVessel: 'all',
  },
  vesselGridState: {
    pageNumber: 1,
    pageSize: getLocalStorage('portfolio_vessel_page_size', 10),
    sortField: 'updatedAt',
    sortOrder: 'desc',
    gridLayoutMode: getLocalStorage<VesselGridLayoutMode>(
      'portfolio_vessel_grid_layout',
      'auto',
      VesselGridLayoutModeSchema
    ),
  },
  cargoGridState: {
    pageNumber: 1,
    pageSize: getLocalStorage('seabo_portfolio_cargo_page_size', 50),
    sortField: 'updatedAt',
    sortOrder: 'desc',
  },
};

export type PortfolioType = 'vessel' | 'cargo';

export type PartialGridState = Partial<VesselGridState> | Partial<CargoGridState>;

const PortfolioSlice = createSlice({
  name: 'Portfolio',
  initialState,
  reducers: {
    setScreenState(state, action: PayloadAction<Partial<ScreenState>>) {
      state.screenState = {
        ...state.screenState,
        ...action.payload,
      };
    },
    setGridState(state, action: PayloadAction<{portfolioType: PortfolioType; gridState: PartialGridState}>) {
      switch (action.payload.portfolioType) {
        case 'cargo':
          state.cargoGridState = {
            ...state.cargoGridState,
            ...action.payload.gridState,
          };
          break;
        case 'vessel':
          state.vesselGridState = {
            ...state.vesselGridState,
            ...action.payload.gridState,
          };
          break;
        default:
          assertUnreachable(action.payload.portfolioType);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(vesselActionTypes.getStatsVessel.SUCCESS, (state, action: PayloadAction<StatsVessel>) => {
      state.statsVessel = action.payload;
    });
    builder.addCase(vesselActionTypes.get.SUCCESS, (state, action: PayloadAction<PortfolioVessel | null>) => {
      state.vessel = action.payload;
    });
    builder.addCase(vesselActionTypes.patch.SUCCESS, (state, action: PayloadAction<PortfolioVessel | null>) => {
      state.vessel = action.payload;
    });
    builder.addCase(cargoActionTypes.getCargo.SUCCESS, (state, action: PayloadAction<PortfolioCargo | null>) => {
      state.cargo = action.payload;
    });
    builder.addCase(cargoActionTypes.patch.SUCCESS, (state, action: PayloadAction<PortfolioCargo | null>) => {
      state.cargo = action.payload;
    });
    builder.addCase(
      portfolioActionTypes.getCargoSubData.SUCCESS,
      (state, action: PayloadAction<{id?: number; data: {items: SubPortfolio[]}}>) => {
        state.screenState.activeTabCargo = action.payload.id ? action.payload.id : state.screenState.activeTabCargo;
      }
    );
    builder.addCase(
      portfolioActionTypes.getVesselGroups.SUCCESS,
      (state, action: PayloadAction<{data: SubPortfolio[]}>) => {
        state.selectableVesselSubPortfolios = action.payload.data;
      }
    );
    builder.addCase(
      portfolioActionTypes.getCargoGroups.SUCCESS,
      (state, action: PayloadAction<{data: SubPortfolio[]}>) => {
        state.selectableCargoSubPortfolios = action.payload.data;
      }
    );
  },
});

export const portfolioReducer = PortfolioSlice.reducer;

export const PortfolioActions = {
  ...PortfolioSlice.actions,
  setPageNumber: (portfolioType: PortfolioType, pageNumber: number) =>
    PortfolioSlice.actions.setGridState({portfolioType, gridState: {pageNumber}}),
  setPageSize: (portfolioType: PortfolioType, pageSize: number) =>
    PortfolioSlice.actions.setGridState({portfolioType, gridState: {pageSize}}),
  setSortParams: ({portfolioType, sortParams}: {portfolioType: PortfolioType; sortParams: SortParams}) =>
    PortfolioSlice.actions.setGridState({portfolioType, gridState: {...sortParams}}),
  setVessels: ({vessels, vesselsTotal}: {vessels: PortfolioVessel[] | null; vesselsTotal: number}) =>
    PortfolioSlice.actions.setScreenState({vessels, vesselsTotal}),
  setCargoes: ({cargoes, cargoesTotal}: {cargoes: PortfolioCargo[] | null; cargoesTotal: number}) =>
    PortfolioSlice.actions.setScreenState({cargoes, cargoesTotal}),
  selectSubPortfolio: (activeType: PortfolioType, subPortfolioId: SubPortfolioId) =>
    PortfolioSlice.actions.setScreenState({
      [activeType === 'vessel' ? 'activeTabVessel' : 'activeTabCargo']: subPortfolioId,
    }),
};
