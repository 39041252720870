import React, {FC} from 'react';
import {NegotiationTermAreaCard} from '../TermArea/NegotiationTermArea';
import {Button, Popconfirm} from 'antd';
import {DataGrid} from '../../../../../components/DataGrid/DataGrid';
import {UserDateTime} from '../../../../../components/DateTime/UserDateTime';
import {transformFileSize} from '../../../../../utils/helper';
import Upload, {acceptDocsWithoutRtf, acceptImages} from '../../../../../components/Upload';
import {NegotiationAttachment, NegotiationParty, NegotiationSummary} from '../../../../../api/node-backend/generated';
import {AttachmentActions} from './useAttachmentActions';
import {NegotiationActorLabel} from '../../../Components/NegotiationActorLabel';

export const NegotiationAttachments: FC<{
  negotiation: NegotiationSummary;
  attachments: NegotiationAttachment[];
  attachmentActions: AttachmentActions;
}> = ({negotiation, attachments, attachmentActions}) => {
  const {downloadAttachment, deleteAttachment, uploadAttachments} = attachmentActions;

  const {party, counterpart, creator, latestRound} = negotiation;
  const currentParty = negotiation[party];
  const otherParty = party === NegotiationParty.Creator ? counterpart : creator;
  const isOwnTurn = latestRound.committedAt === null;

  return (
    <NegotiationTermAreaCard data-testid="NegotiationAttachments">
      <DataGrid<NegotiationAttachment>
        responsive
        data={attachments}
        unrounded
        emptyMessage="Upload documents below."
        columns={[
          {
            id: 'type',
            header: 'Type',
            minWidth: 5,
            accessorFn: (row: NegotiationAttachment) => row.type.toUpperCase(),
            cell: info => info.getValue(),
          },
          {
            id: 'name',
            header: 'Name',
            minWidth: 50,
            accessorFn: (row: NegotiationAttachment) => row.title,
            cell: info => info.getValue(),
          },
          {
            id: 'size',
            header: 'Size',
            minWidth: 15,
            accessorFn: (row: NegotiationAttachment) => transformFileSize(row.fileSize),
            cell: info => info.getValue(),
          },
          {
            id: 'uploaded',
            header: 'Uploaded',
            minWidth: 12,
            accessorFn: (row: NegotiationAttachment) => (
              <div>
                <NegotiationActorLabel actor={row.uploader === party ? currentParty : otherParty} />
                <br />
                <UserDateTime value={row.createdAt.toISOString()} format={'date'} />
              </div>
            ),
            cell: info => info.getValue(),
          },
          {
            id: 'actions',
            header: 'Actions',
            minWidth: 14,
            accessorFn: (row: NegotiationAttachment) => (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    downloadAttachment?.(row);
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{marginRight: 10}}
                  data-testid="DocumentViewButton">
                  Download
                </Button>
                {isOwnTurn && (
                  <Popconfirm
                    okType={row.uploader === party ? 'danger' : 'primary'}
                    title={
                      row.uploader === party
                        ? 'Are you sure you want to delete this document?'
                        : "You can't delete documents uploaded by the other party."
                    }
                    showCancel={row.uploader === party}
                    overlayInnerStyle={{padding: 8}}
                    onConfirm={() => {
                      if (row.uploader !== party) {
                        return;
                      }
                      deleteAttachment?.(row);
                    }}>
                    <Button size="small" data-testid="DocumentDeleteButton" disabled={row.uploader !== party}>
                      Delete
                    </Button>
                  </Popconfirm>
                )}
              </>
            ),
            cell: info => info.getValue(),
          },
        ]}
      />
      {isOwnTurn && (
        <Upload
          headline="Add files"
          subline="Drag and drop your files here or click to upload"
          accept={{...acceptDocsWithoutRtf, ...acceptImages}}
          multiple
          loading={false}
          onDrop={files => uploadAttachments?.(files)}
          uploadErrors={[]}
        />
      )}
    </NegotiationTermAreaCard>
  );
};
