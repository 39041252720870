import dayjs, {Dayjs} from 'dayjs';
import {AisVessel} from '../../api/symfony/generated';

export const isEtaOlderThan30Days = ({vessel, currentTime}: {vessel: AisVessel; currentTime: Dayjs}) => {
  const before30Days = currentTime.clone().subtract(30, 'day');
  const eta = dayjs(vessel.eta);

  const isBefore = before30Days.isBefore(eta);
  return isBefore;
};
