import React, {ReactElement, useRef} from 'react';
import {StyledMapCtrlButton} from '../StyledMapCtrlButton';
import {LayerDropdown, otherLayers, tradingAreaLayers} from './LayerDropdown';
import classNames from 'classnames';
import {MapSwitches, MapSwitchesKey} from '../../MapDetails/utils/types';
import {TODO} from '../../../../utils/TODO';
import Icon from '../../../../atoms/Icon';
import useOnClickOutside from 'use-onclickoutside';
import {isTargetAntdOption} from '../isTargetAntdOption';

type Props = {
  switches: MapSwitches;
  onChange: (switchId: MapSwitchesKey, value: TODO) => void;
  onOpen?: () => void;
  mapHeight: number;
  isOpen?: boolean;
  otherLayersDropDown?: ReactElement;
  zIndex?: number;
};

export const LayerSelect = (props: Props) => {
  const {isOpen, onOpen} = props;
  const active = [...tradingAreaLayers, ...otherLayers].some(item => {
    return props.switches[item.fieldId as MapSwitchesKey]?.state;
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, event => {
    if (isTargetAntdOption(event.target as HTMLElement)) {
      return;
    }
    if (isOpen) {
      onOpen?.();
    }
  });

  return (
    <div ref={containerRef}>
      <StyledMapCtrlButton className={classNames({active, isOpen})} onClick={() => onOpen?.()}>
        <Icon type="layers" size="medium" color={'white'} />
      </StyledMapCtrlButton>
      {isOpen && <LayerDropdown {...props} />}
    </div>
  );
};
