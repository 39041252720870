import dayjs from 'dayjs';
import {Fragment} from 'react';
import {MultiValueProps, components} from 'react-select';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import Icon from '../../../../atoms/Icon';
import Input from '../../../../atoms/Input';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {MultiSelect} from '../../../../atoms/Select/MultiSelect';
import {GroupHeading} from '../../../../atoms/Select/otherComponents';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {StyledRangePicker} from '../../../RangePicker/StyledRangePicker';
import {DatePickerPairLayout} from '../utils/DatePickerPairLayout';
import {filterBranchDefinition, QualityOfEmails} from './PostedDefinition';
import {RangePickerProps} from 'antd/es/date-picker';
import {NamedValue} from '../FilterConversion/utils/NamedValue';
import '../style.scss';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
  type: 'cargo' | 'vessel';
}

const Posted = ({filterProviderApi}: Props) => {
  const past30days = dayjs().subtract(30, 'days');

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current.isBefore(past30days);
  };

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size={'big'}>
      {({handleChange, values, handleRangePickerChange}) => (
        <Fragment>
          <div className="container">
            <div className="row">
              <div className="scol-6">
                <LabelWrapper label={'Posted on'}>
                  <DatePickerPairLayout>
                    <StyledRangePicker
                      id={'filter-postedOnFromTo'}
                      format={DateTimeFormat.Date}
                      value={[values.from, values.to]}
                      placeholder={['from', 'to']}
                      disabledDate={disabledDate}
                      onChange={value => {
                        const [from, to] = [...(value ?? [null, null])];
                        handleRangePickerChange({from, to});
                      }}
                    />
                  </DatePickerPairLayout>
                </LabelWrapper>
                <LabelWrapper
                  label={'Parsing confidence score'}
                  htmlFor={'filter-features-parsing-score'}
                  contentWrapperStyle={{marginBottom: 0}}>
                  <MultiSelect
                    id={'filter-features-parsing-score'}
                    value={values.confidenceScoreEmailQuality}
                    onChange={value => handleChange('confidenceScoreEmailQuality', [...value])}
                    options={QualityOfEmails}
                    getOptionLabel={o => DropdownSelectionOptionQualityOfEmails(o)}
                    getOptionValue={option => option.value}
                    components={{GroupHeading, MultiValue: DropdownMultiValueQualityOfEmails}}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-6">
                <LabelWrapper label={'Sent from'} htmlFor={`filter-posted-postedByEmail`}>
                  <Input
                    id={'filter-posted-postedByEmail'}
                    name={'filter-posted-postedByEmail'}
                    value={values.postedByEmail ?? ''}
                    onChange={value => handleChange('postedByEmail', value || null)}
                  />
                </LabelWrapper>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </FilterButton>
  );
};

export const DropdownSelectionOptionQualityOfEmails = (props: {value: string; name: string}) => (
  <div className={'filter-email-display filter-email-display--option'}>
    <Icon type={'sent'} className={props.value} />
    <span className={'title'}>{props.name}</span>
  </div>
);

export const DropdownMultiValueQualityOfEmails = <OptionType extends NamedValue<string>>(
  props: MultiValueProps<OptionType>
) => (
  <components.MultiValue {...props}>
    <div className={'filter-email-display'}>
      <Icon type={'sent'} className={props.data.value} />
      <span className={'title'}>{props.data.name}</span>
    </div>
  </components.MultiValue>
);

export default Posted;
