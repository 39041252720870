import {useState, FC} from 'react';
import {MenuItem} from '../../../../atoms/Menu/MenuItem';
import {ActionMenu, ItemTypeWithDataCy} from '../../../../components/ActionMenu/ActionMenu';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {AddFromVesselDBToMyFleetModal} from './AddFromVesselDBToMyFleetModal';
import {useCurrentUserQuery} from '../../../../queries/useCurrentUserQuery';
import {FeaturePermission} from '../../../../api/symfony/generated';
import {ExportVoyageTimelineModal} from '../../../VesselDetailScreen/VoyageTimeline/ExportVoyageTimeline/ExportVoyageTimelineModal';

interface VesselGridActionMenuProps {
  vessel: SeaboVessel;
}

export const VesselGridActionMenu: FC<VesselGridActionMenuProps> = ({vessel}) => {
  const [showAddToMyFleetModal, setShowAddToMyFleetModal] = useState(false);
  const currentUserQuery = useCurrentUserQuery();
  const canExportVoyageTimeline = currentUserQuery.data?.permissions.includes(FeaturePermission.VoyageTimelineExport);

  const [showExportVoyageTimelineModal, setShowExportVoyageTimelineModal] = useState(false);

  const items: ItemTypeWithDataCy[] = [
    {
      key: 'Add to My Fleet',
      label: (
        <MenuItem
          label="Add to My Fleet"
          onClick={() => {
            setShowAddToMyFleetModal(true);
          }}
        />
      ),
    },
  ];

  if (canExportVoyageTimeline) {
    items.push({
      key: 'Download voyage timeline',
      label: (
        <MenuItem
          label="Download voyage timeline"
          onClick={() => {
            setShowExportVoyageTimelineModal(true);
          }}
        />
      ),
    });
  }

  return (
    <>
      <ExportVoyageTimelineModal
        visible={showExportVoyageTimelineModal}
        imo={vessel.imo}
        onClose={() => {
          setShowExportVoyageTimelineModal(false);
        }}
      />
      {showAddToMyFleetModal && (
        <AddFromVesselDBToMyFleetModal
          onSuccess={() => {}}
          onCancel={() => setShowAddToMyFleetModal(false)}
          vesselDatabaseVesselId={vessel.id}
        />
      )}
      <ActionMenu items={items} />
    </>
  );
};
