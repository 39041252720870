import React, {FC} from 'react';
import {Col, Flex, Form, Input, Row} from 'antd';
import {User} from './User';
import Icon from '../../../../atoms/Icon';
import {AddRowButton} from '../../../../atoms/AddRowButton/AddRowButton';
import {useAddMembersMutation} from './useAddMembersMutation';
import {TODO} from '../../../../utils/TODO';
import InfoBox from '../../../../atoms/InfoBox';
import {useSelector} from '../../../../redux/react-redux';
import {isApiError} from '../../../../api/utils/ApiError';
import {Button} from '../../../../atoms/Button/Button';
import {showNotification} from '../../../../utils/notification';

type FormValues = {
  newUsers: User[];
};

export const AddUsersForm: FC<{
  companyId: number;
  onUsersAdded: () => void;
}> = ({companyId, onUsersAdded}) => {
  const addMembersMutation = useAddMembersMutation({
    onError: error => {
      if (isApiError(error)) {
        if (error.status === 409) {
          return;
        }
      }
      throw error;
    },
    onSuccess: () => {
      showNotification('Users added successfully.', 'success');
      form.resetFields();
      onUsersAdded();
    },
  });
  const errorUser = useSelector(state => state.company.errorUser);

  const [form] = Form.useForm<FormValues>();

  return (
    <Form<FormValues>
      form={form}
      initialValues={{
        newUsers: [{firstName: '', lastName: '', email: ''}],
      }}
      onFinish={values => {
        addMembersMutation.mutate({
          companyId,
          users: values.newUsers,
        });
      }}
      layout={'vertical'}>
      <Form.List name="newUsers">
        {(fields, {add, remove}) => {
          return (
            <div>
              {fields.map(({name, ...field}, index) => {
                const canDelete = name !== 0;
                const showLabel = name === 0;
                return (
                  <Flex key={index}>
                    <Row gutter={[8, 8]} style={{flexGrow: 1}}>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[name, 'firstName']}
                          label={showLabel ? 'First name' : undefined}
                          rules={[{required: true, message: 'First name is required'}]}>
                          <Input placeholder="First name" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[name, 'lastName']}
                          label={showLabel ? 'Last name' : undefined}
                          rules={[{required: true, message: 'Last name is required'}]}>
                          <Input placeholder="Last name" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[name, 'email']}
                          label={showLabel ? 'Work email' : undefined}
                          rules={[
                            {required: true, message: 'Work email is required'},
                            {
                              type: 'email',
                              message: 'Please enter a valid email',
                            },
                          ]}>
                          <Input placeholder={'Work email'} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Flex
                      vertical={false}
                      justify={'flex-end'}
                      align={'center'}
                      style={{
                        marginLeft: 4,
                        width: 22,
                        height: 36,
                      }}>
                      {canDelete ? (
                        <Icon
                          type={'clear'}
                          size={'medium'}
                          color={'gray'}
                          onClick={() => {
                            remove(name);
                          }}
                          style={{cursor: 'pointer'}}
                        />
                      ) : (
                        <div />
                      )}
                    </Flex>
                  </Flex>
                );
              })}
              <AddRowButton label={'ADD ROW'} onClick={() => add()} />
            </div>
          );
        }}
      </Form.List>
      {errorUser.map((user: TODO, index: number) => (
        <InfoBox
          key={index}
          message={`${user.firstName} ${user.lastName} (${user.email}): ${user.message}`}
          type={'error'}
          style={{marginBottom: '20px'}}
        />
      ))}
      <Row
        gutter={[8, 8]}
        style={{
          marginTop: 20,
        }}>
        <Col span={24}>
          <Button
            label="Add users now"
            bold
            primary
            upper
            type={'submit'}
            id={'settings-section__button-save'}
            disabled={addMembersMutation.isPending}
          />
        </Col>
      </Row>
    </Form>
  );
};
