import {useEffect, useState} from 'react';
import {FilterBranchDefinition} from '../../components/FilterProvider/FilterBranchDefinition';
import {ValidateFunctionOnlyValueAndSectionValues} from '../../utils/Validator';
import {assert} from '../../utils/assert';
import {FilterButtonFilterProviderApi} from './FilterButton';
import {FilterTypeDefault, ValidatorsTypeDefault, RangePickerValues, ValidationErrors} from './types';

export const useFilterState = <
  BranchKey extends string,
  InternalType extends FilterTypeDefault,
  DatabaseType extends FilterTypeDefault = FilterTypeDefault,
  ContextType extends FilterTypeDefault = FilterTypeDefault,
  ValidatorsType extends ValidatorsTypeDefault<InternalType> = ValidatorsTypeDefault<InternalType>,
  FilterButtonFilterProviderApiType extends FilterButtonFilterProviderApi<
    FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType>
  > = FilterButtonFilterProviderApi<
    FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType>
  >,
>({
  filterProviderApi,
  filterBranchDefinition,
}: {
  filterProviderApi: FilterButtonFilterProviderApiType;
  filterBranchDefinition: FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType>;
}) => {
  const {branch, defaults, validators} = filterBranchDefinition;
  const {internalFilterSettings, appliedFilters, onApplyClick, onResetClick} = filterProviderApi;

  const [values, setValues] = useState<InternalType>(() => defaults);
  const [touched, setTouched] = useState<Partial<Record<keyof InternalType, boolean>>>({});

  const isApplied = appliedFilters[branch];

  /**
   * Load the filter settings when the branch or filterProvider state changes and reset the touched fields.
   */
  useEffect(() => {
    setValues({...defaults, ...internalFilterSettings[branch]});
    setTouched({});
  }, [branch, defaults, internalFilterSettings]);

  const onChange = <KeyType extends keyof InternalType>(field: KeyType, value: InternalType[KeyType] | null) => {
    setValues({...values, [field]: value});
    setTouched({...touched, [field]: true});
  };

  const onChangeRangePicker = (newValues: RangePickerValues) => {
    setValues({...values, ...newValues});
  };

  const onApply = () => {
    onApplyClick(branch, {...defaults, ...values});
  };

  const onReset = () => {
    setValues(defaults);
    onResetClick(branch);
  };

  /**
   * Returns true if all entered values are valid.
   */
  const checkIfValid = (): boolean => {
    const validationErrors = getValidationErrors();
    return Object.keys(validationErrors).length === 0;
  };

  /**
   * Returns the validation errors that should be displayed.
   */
  const getValidationErrors = (): ValidationErrors<InternalType> => {
    const validationErrors: ValidationErrors<InternalType> = {};
    const touchedFields = Object.keys(touched);
    for (const field of touchedFields as (keyof InternalType)[]) {
      const errorMessage = getValidationErrorForField(field);
      if (errorMessage && touched[field]) {
        validationErrors[field] = errorMessage;
      }
    }
    return validationErrors;
  };

  const getValidationErrorForField = <KeyType extends keyof InternalType>(
    field: KeyType
  ): string | false | undefined => {
    const fieldValidators = validators?.[field];
    if (!fieldValidators) {
      return undefined;
    }
    const value = values[field];
    if (typeof fieldValidators === 'function') {
      const errorMessage = (fieldValidators as ValidateFunctionOnlyValueAndSectionValues<InternalType[KeyType]>)(
        value,
        values
      );
      return errorMessage;
    }

    // We have a validator array
    assert(Array.isArray(fieldValidators));
    // Return the first validator error that we get.
    for (const validator of fieldValidators) {
      const errorMessage = validator(value, values);
      if (errorMessage) {
        return errorMessage;
      }
    }
    // No validation error found.
    return undefined;
  };

  const isValid = checkIfValid();
  const validationErrors = getValidationErrors();

  return {
    values,
    touched,
    isApplied,
    isValid,
    validationErrors,
    onChange,
    onChangeRangePicker,
    onApply,
    onReset,
  };
};
