import {Descriptions} from 'antd';
import {InlineNumberEditor} from '../../../atoms/InlineNumberEditor/InlineNumberEditor';
import produce from 'immer';
import numeral from 'numeral';
import {Assumptions} from './types';
import {DistanceCalcOptions} from '../Voyagecharter/PageHeader/DistanceCalcOptions';
import {DEFAULT_SEA_MARGIN} from '../Voyagecharter/Context/blankState';

type Props = {
  assumptions: Assumptions;
  isLoading?: boolean;
  onChange: (assumptions: Assumptions) => void;
};

export const AssumptionsList = (props: Props) => {
  const {assumptions, onChange} = props;

  return (
    <Descriptions size={'small'} bordered>
      <Descriptions.Item label="IFO price" style={{width: '16.5%', height: 50}}>
        <InlineNumberEditor
          block
          min={0}
          closeOnEnter={true}
          editModeAvailable={true}
          value={assumptions.costIfoPerMts ?? 0}
          formattedValue={<span>${assumptions.costIfoPerMts}/ MTS</span>}
          onChange={value => {
            onChange(
              produce(assumptions, draftAssumptions => {
                draftAssumptions.costIfoPerMts = value;
              })
            );
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item label="MDO price" style={{width: '16.5%', height: 50}}>
        <InlineNumberEditor
          block
          min={0}
          closeOnEnter={true}
          editModeAvailable={true}
          value={assumptions.costMdoPerMts ?? 0}
          formattedValue={<span>${assumptions.costMdoPerMts}/ MTS</span>}
          onChange={value => {
            onChange(
              produce(assumptions, draftAssumptions => {
                draftAssumptions.costMdoPerMts = value;
              })
            );
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item label="MGO price" style={{width: '16.5%', height: 50}}>
        <InlineNumberEditor
          block
          min={0}
          editModeAvailable={true}
          closeOnEnter={true}
          value={assumptions.costMdoPerMts ?? 0}
          formattedValue={<span>${assumptions.costMgoPerMts}/ MTS</span>}
          onChange={value => {
            onChange(
              produce(assumptions, draftAssumptions => {
                draftAssumptions.costMgoPerMts = value;
              })
            );
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Sea margin" style={{width: '16.5%', height: 50}}>
        <InlineNumberEditor
          block
          closeOnEnter={true}
          value={assumptions.seaMargin ?? DEFAULT_SEA_MARGIN}
          editModeAvailable={true}
          formattedValue={<span>{assumptions.seaMargin} %</span>}
          onChange={value => {
            onChange(
              produce(assumptions, draftAssumptions => {
                draftAssumptions.seaMargin = value;
              })
            );
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Port costs" style={{width: '16.5%', height: 50}}>
        <InlineNumberEditor
          block
          min={0}
          closeOnEnter={true}
          editModeAvailable={true}
          value={assumptions.portCosts ?? 0}
          formattedValue={<span>{numeral(assumptions.portCosts).format('$0,0[.]00')}</span>}
          onChange={value => {
            onChange(
              produce(assumptions, draftAssumptions => {
                draftAssumptions.portCosts = value;
              })
            );
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item label={'Distance Calc options'} style={{width: '16.5%', height: 50}}>
        <DistanceCalcOptions
          block
          isLoadingRoutes={props.isLoading}
          distanceCalculatorValues={assumptions.distanceCalculator}
          onChange={values => {
            onChange(
              produce(assumptions, draftAssumptions => {
                draftAssumptions.distanceCalculator = {
                  ...draftAssumptions.distanceCalculator,
                  ...values,
                };
              })
            );
          }}
          key={'distanceCalcOptions'}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};
