/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CargoOffersForLocationResponseDataItems,
  CargoOffersForLocationResponseDataItemsFromJSON,
  CargoOffersForLocationResponseDataItemsFromJSONTyped,
  CargoOffersForLocationResponseDataItemsToJSON,
} from './';

/**
 *
 * @export
 * @interface CargoOffersForLocationResponseData
 */
export interface CargoOffersForLocationResponseData {
  /**
   *
   * @type {Array<CargoOffersForLocationResponseDataItems>}
   * @memberof CargoOffersForLocationResponseData
   */
  items: Array<CargoOffersForLocationResponseDataItems>;
}

export function CargoOffersForLocationResponseDataFromJSON(json: any): CargoOffersForLocationResponseData {
  return CargoOffersForLocationResponseDataFromJSONTyped(json, false);
}

export function CargoOffersForLocationResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CargoOffersForLocationResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(CargoOffersForLocationResponseDataItemsFromJSON),
  };
}

export function CargoOffersForLocationResponseDataToJSON(value?: CargoOffersForLocationResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(CargoOffersForLocationResponseDataItemsToJSON),
  };
}
