/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VesselFilterGearedOption {
  Geared = 'geared',
  Gearless = 'gearless',
}

export function VesselFilterGearedOptionFromJSON(json: any): VesselFilterGearedOption {
  return VesselFilterGearedOptionFromJSONTyped(json, false);
}

export function VesselFilterGearedOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselFilterGearedOption {
  return json as VesselFilterGearedOption;
}

export function VesselFilterGearedOptionToJSON(value?: VesselFilterGearedOption | null): any {
  return value as any;
}
