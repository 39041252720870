import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import {FC} from 'react';
import PortAttributes from '../../../components/FilterProvider/Filters/PortAttributes/PortAttributes';
import LocationPort from '../../../components/FilterProvider/Filters/LocationPort/LocationPort';
import {FilterBar} from '../../../components/FilterBar/FilterBar';
import {MarketPortFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketPortFilterBranchDefinitions';

interface Props {
  portFilterProviderApi: FilterProviderApi<typeof MarketPortFilterBranchDefinitions>;
}

export const PortFilters: FC<Props> = ({portFilterProviderApi}) => (
  <FilterBar
    id="portFilters"
    filters={[
      <PortAttributes filterProviderApi={portFilterProviderApi} />,
      <LocationPort filterProviderApi={portFilterProviderApi} />,
    ]}
  />
);
