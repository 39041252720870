import dayjs from 'dayjs';
import {useMemo} from 'react';
import {OfferBucket} from '../../../api/symfony/generated/models';
import {getBucketDate} from './getBucketDate';

export const useFilterOfferBucketsByDate = ({
  buckets,
  startDate,
  endDate,
}: {
  buckets: OfferBucket[];
  startDate?: string;
  endDate?: string;
}) => {
  const minDate = useMemo(() => {
    if (startDate) {
      return dayjs(startDate);
    }
    if (buckets.length === 0) {
      return dayjs();
    }
    return dayjs(getBucketDate(buckets[0]));
  }, [startDate, buckets]);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const maxDate = useMemo(() => dayjs(endDate), [endDate, buckets]);

  return useMemo(() => {
    const filteredBuckets = buckets.filter(bucket => {
      const date = getBucketDate(bucket);
      return date.isBetween(minDate, maxDate) || date.isSame(minDate, 'day') || date.isSame(maxDate, 'day');
    });

    /*
    It is possible that there are no buckets on the min or max date.
    Then the period would be shortened, which has a negative effect on visualizations.
    To prevent this, an empty bucket is added at the min and max date,
    if none is available there.
     */
    if (!filteredBuckets.some(bucket => getBucketDate(bucket).isSame(minDate))) {
      const minBucket: OfferBucket = {
        tags: [
          {name: 'calendarWeek', value: minDate.weekYear().toString()},
          {name: 'dayOfMonth', value: minDate.format('DD')},
          {name: 'month', value: minDate.format('MM')},
          {name: 'year', value: minDate.format('YYYY')},
        ],
        cargoOfferIds: [] as unknown as Set<number>,
        vesselOfferIds: [] as unknown as Set<number>,
        numberOfCargoOffers: 0,
        numberOfVesselOffers: 0,
        numberOfOffers: 0,
      };
      filteredBuckets.push(minBucket);
    }
    if (!filteredBuckets.some(bucket => getBucketDate(bucket).isSame(maxDate))) {
      const maxBucket: OfferBucket = {
        tags: [
          {name: 'calendarWeek', value: maxDate.weekYear().toString()},
          {name: 'dayOfMonth', value: maxDate.format('DD')},
          {name: 'month', value: maxDate.format('MM')},
          {name: 'year', value: maxDate.format('YYYY')},
        ],
        cargoOfferIds: [] as unknown as Set<number>,
        vesselOfferIds: [] as unknown as Set<number>,
        numberOfCargoOffers: 0,
        numberOfVesselOffers: 0,
        numberOfOffers: 0,
      };
      filteredBuckets.push(maxBucket);
    }
    return filteredBuckets;
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [buckets, startDate, endDate]);
};
