import {FilterItem} from '../../../../api/symfony/generated';
import {PortfolioVesselDatabaseFilter} from './PortfolioVesselFilterBranchDefinitions';
import {TODO} from '../../../../utils/TODO';

/**
 * Checks if a filter is in an invalid format.
 *
 * @see https://seanexxt.atlassian.net/browse/FRIN-3345
 */
export const hasInvalidFilterSettings = (filter: FilterItem): boolean => {
  if (filter.filterSettings === undefined || filter.filterSettings === null) {
    // Missing filter settings are ok.
    return false;
  }
  const filterSettings = filter.filterSettings as PortfolioVesselDatabaseFilter;
  return (
    anyAttributeIsNull(filterSettings.intake || {}, ['baleVolume', 'grainVolume']) ||
    anyAttributeIsNull(filterSettings.size || {}, [
      'teu',
      'holds',
      'teu14',
      'designs',
      'hatches',
      'deadWeight',
      'designTypes',
    ]) ||
    anyAttributeIsNull(filterSettings.dimensions || {}, [
      'beam',
      'draft',
      'length',
      'netTonnage',
      'grossTonnage',
      'tonnesPerCentimetre',
    ])
  );
};

/**
 * Resets all filter settings of the given filter, but keeps id, category etc.
 *
 * Does not modify the original filter, but returns the new one.
 */
export const resetFilterSettings = (filter: FilterItem): FilterItem => {
  const newFilter = {...filter, filterSettings: {}};
  return newFilter;
};

/**
 * Checks if any of the listed attributes in the given object is null.
 *
 * If a listed attribute is missing, it is ignored.
 *
 * Implementation note: The signature uses any instead of unknown as value type to be compatible
 * to the DimensionsDefinition.DatabaseType interface.
 * The underlying problem seems to be a bug or at least unintuitive behavior in TypeScript.
 *
 * @see https://github.com/microsoft/TypeScript/issues/15300
 */
const anyAttributeIsNull = (value: Record<string, TODO>, attributesThatShouldNotBeNull: string[]) => {
  return attributesThatShouldNotBeNull.some(attribute => value[attribute] === null);
};
