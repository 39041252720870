import styled from 'styled-components';
import Icon, {IconType} from '../../../atoms/Icon';

type Props = {
  type: 'vessel' | 'cargo';
  name: string;
  description: string;
};

export const ProjectTitle = ({type, name, description}: Props) => {
  const iconType: IconType = type === 'vessel' ? 'vessel-complex' : 'cargo';

  return (
    <ProjectTitleContainer>
      <Icon type={iconType} size="medium-large" color="gray" />
      <ProjectTitleContent>
        <ProjectName>{name}</ProjectName>
        <ProjectDescription>{description}</ProjectDescription>
      </ProjectTitleContent>
    </ProjectTitleContainer>
  );
};

const ProjectTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  gap: 20px;
`;

const ProjectTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const ProjectName = styled.span`
  color: var(--color-black);
  font-size: var(--font-size-lg);
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
`;

const ProjectDescription = styled.span`
  color: var(--color-gray-2);
  font-size: var(--font-size-md);
`;
