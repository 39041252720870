import {Middleware, ResponseContext} from '../../api/symfony/generated';
import {setAjaxCsrfToken} from './api';

/**
 * This middleware for the generated API extracts the 'X-Ajax-Csrf-Token' header from the response.
 */
export const apiUpdateCsrfTokenMiddleware: Middleware = {
  async post(context: ResponseContext): Promise<Response | void> {
    const csrfToken = context.response?.headers?.get('X-Ajax-Csrf-Token');
    if (csrfToken) {
      setAjaxCsrfToken(csrfToken);
    }
  },
};
