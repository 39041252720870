import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationTermApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {ApiError} from '../../../../../../api/utils/ApiError';
import {RejectRejectionRequestRequest, NegotiationRoundResult} from '../../../../../../api/node-backend/generated';
import {batchPatchRequests} from './batchPatchRequests';

export const useRejectRejectionRequestMultipleTermsMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, string[]>
) =>
  useMutation({
    mutationFn: async (termIds: string[]) => {
      const requestParameters: RejectRejectionRequestRequest[] = termIds.map(termId => ({termId}));

      const roundResult = await batchPatchRequests<RejectRejectionRequestRequest, NegotiationRoundResult>(
        requestParameters,
        params => negotiationNegotiationTermApi.rejectRejectionRequest(params)
      );

      return roundResult;
    },
    ...options,
  });
