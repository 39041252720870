import {Button} from 'antd';
import {useChangeBrowserUrl} from '../../../utils/useChangeBrowserUrl';

export const DashboardButton = () => {
  const changeBrowserUrl = useChangeBrowserUrl();

  return (
    <Button
      size="large"
      onClick={() => {
        changeBrowserUrl('/dashboard');
      }}
      data-testid="DashboardButton">
      To Dashboard
    </Button>
  );
};
