import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../api/utils/fetchJSON';
import {Coordinates} from '../../../utils/Coordinates';
import {getStationLocationsFromCargo} from './getStationLocationsFromCargo';
import {getArcLayer, getIconLayer, getScatterplotLayer} from './layers';
import {RGBColor} from '../../../utils/deck.gl/deck.gl-types';

export type CountryCentroid = {
  longName: string;
  shortName: string;
  centerLat: number;
  centerLng: number;
};

type StationCoordinates = {
  from?: Coordinates;
  via?: Coordinates;
  to?: Coordinates;
};

export type StationLocation = {
  coordinates: Coordinates;
  type: string;
  code: string;
};

export type Station = {
  locations: StationLocation[];
  type: string;
};

const useCountryCentroidsQuery = () =>
  useQuery({
    queryKey: ['countryCentroids'],
    queryFn: async () => {
      const response = await fetchJSON<CountryCentroid[]>('/static/jsons/countryCentroids.json');
      return response;
    },
  });

export const useGetCoordinatesFromLocation = (): {
  isLoading: boolean;
  getCoordinatesFromLocation: (location?: StationLocation) => Coordinates | undefined;
} => {
  const countryCentroidsQuery = useCountryCentroidsQuery();
  if (!countryCentroidsQuery.data) {
    return {
      isLoading: true,
      getCoordinatesFromLocation: () => undefined,
    };
  }
  const countryCentroids = countryCentroidsQuery.data;

  return {
    isLoading: false,
    getCoordinatesFromLocation: (location?: StationLocation) => {
      if (!location) {
        return undefined;
      }
      if (location.type === 'country') {
        const centroid = countryCentroids.find(({shortName}) => shortName === location.code);
        return centroid ? ([centroid.centerLng, centroid.centerLat] as Coordinates) : undefined;
      }
      return location.coordinates;
    },
  };
};

export const useGetCargoRouteLayer = (
  stations: Station[],
  color: RGBColor,
  fromIndex: number,
  viaIndex: number,
  toIndex: number
) => {
  const {isLoading, getCoordinatesFromLocation} = useGetCoordinatesFromLocation();

  const {fromLocations, viaLocations, toLocations} = getStationLocationsFromCargo({stations});

  const stationCoordinates: StationCoordinates = {
    from: getCoordinatesFromLocation(fromLocations[fromIndex]),
    via: getCoordinatesFromLocation(viaLocations[viaIndex]),
    to: getCoordinatesFromLocation(toLocations[toIndex]),
  };

  const layers = [];

  if (stationCoordinates.from) {
    layers.push(getScatterplotLayer({index: 1, color, coordinates: stationCoordinates.from}));
    fromLocations.forEach((location: StationLocation) => {
      const coordinates = getCoordinatesFromLocation(location);
      if (coordinates) {
        layers.push(getScatterplotLayer({index: 2, color, coordinates}));
      }
    });
  }
  if (stationCoordinates.to) {
    layers.push(getIconLayer({index: 1, color, coordinates: stationCoordinates.to, type: 'marker'}));
    toLocations.forEach((location: StationLocation) => {
      const coordinates = getCoordinatesFromLocation(location);
      if (coordinates) {
        layers.push(getIconLayer({index: 2, color, coordinates, type: 'marker'}));
      }
    });
  }
  if (stationCoordinates.from && stationCoordinates.to) {
    if (stationCoordinates.via) {
      layers.push(getScatterplotLayer({index: 3, color, coordinates: stationCoordinates.via}));
      layers.push(getArcLayer({index: 1, color, coordinates: [stationCoordinates.from, stationCoordinates.via]}));
      layers.push(getArcLayer({index: 2, color, coordinates: [stationCoordinates.via, stationCoordinates.to]}));
    } else {
      layers.push(getArcLayer({index: 3, color, coordinates: [stationCoordinates.from, stationCoordinates.to]}));
    }
  }

  if (isLoading) {
    return {
      isLoading,
      stationCoordinates: null,
      layers: [],
    };
  }

  return {stationCoordinates, layers};
};
