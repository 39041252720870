import {useQuery} from '@tanstack/react-query';
import {socialApi} from '../api/symfony/symfonyApi';
import {UseQueryOptions} from '../api/utils/ReactQuery';
import {UserProfile} from '../api/symfony/generated';

export const SOCIAL_USER_QUERY_KEY = 'socialApi.getUser';

export const useSocialUserQuery = (userId: number, options?: UseQueryOptions<UserProfile>) =>
  useQuery({
    queryKey: [SOCIAL_USER_QUERY_KEY, userId],
    queryFn: () => socialApi.getUser({userId}),
    ...options,
  });
