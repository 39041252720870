import {StyledFlag} from '../StyledFlag';
import {Country} from '../Type';
import {StyledTag} from './StyledTag';

type Props = {
  country: Country;
  onClose: () => void;
};

export const CountryTag = (props: Props) => {
  return (
    <StyledTag
      data-testid="countrysPickerSelectTag"
      key={props.country.id}
      closable
      onClose={() => {
        props.onClose();
      }}>
      <StyledFlag countryCode={props.country.code} />
      {props.country.name}
    </StyledTag>
  );
};
