import {LonLatCoordinates} from '../../../../../utils/Coordinates';

const DEGREES_TO_RADIANS_FACTOR = Math.PI / 180;
const RADIANS_TO_DEGREES_FACTOR = 180 / Math.PI;

/**
 * Calculates the bearing between two coordinates as a value from 0-360.
 */
export const calculateBearing = (coord1: LonLatCoordinates, coord2: LonLatCoordinates): number => {
  // Convert degrees to radians
  const lat1 = coord1[1] * DEGREES_TO_RADIANS_FACTOR;
  const lon1 = coord1[0] * DEGREES_TO_RADIANS_FACTOR;
  const lat2 = coord2[1] * DEGREES_TO_RADIANS_FACTOR;
  const lon2 = coord2[0] * DEGREES_TO_RADIANS_FACTOR;

  const dLon = lon2 - lon1;

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  let bearing = Math.atan2(y, x);

  // Convert the output to degrees from radians
  bearing = bearing * RADIANS_TO_DEGREES_FACTOR;

  // Normalize to 0 - 360
  bearing = (bearing + 360) % 360;

  return bearing;
};
