import styled, {css} from 'styled-components';
import {FC, ReactNode} from 'react';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';

type TermIndexProps = {
  label: number | string | ReactNode;
  isHighlighted?: boolean;
  indexAddon?: ReactNode;
  dragListeners?: SyntheticListenerMap;
};

export const TermIndex: FC<TermIndexProps> = ({label, isHighlighted, indexAddon, dragListeners}) => (
  <TermIndexContainer
    data-testid="TermIndex"
    $isHighlighted={isHighlighted}
    $isInteractive={!!indexAddon}
    {...dragListeners}>
    {indexAddon && <TermIndexActions data-testid="TermIndexActions">{indexAddon}</TermIndexActions>}
    <TermIndexDisplay data-testid="TermIndexDisplay">
      <TermIndexDisplayNumber>{label}</TermIndexDisplayNumber>
      {indexAddon && (
        <TermIndexControlsIndicator data-testid="TermIndexControlsIndicator">...</TermIndexControlsIndicator>
      )}
    </TermIndexDisplay>
  </TermIndexContainer>
);

export const TermIndexContainer = styled.div<{
  $isHighlighted?: boolean;
  $isInteractive?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 32px;
  padding: 6px 0;
  border-radius: var(--border-radius);
  border: var(--border-width-active) solid transparent;
  background: var(--color-white);
  transition:
    background-color 0.2s,
    color 0.2s,
    width 0.2s;

  .icon {
    color: inherit !important;
  }

  ${({$isHighlighted}) => {
    if ($isHighlighted) {
      return css`
        background: var(--term-color-base);
        color: var(--color-white);
      `;
    } else {
      return css`
        border-color: var(--term-color-base);
        color: var(--term-color-base);
      `;
    }
  }}

  ${({$isInteractive}) =>
    $isInteractive &&
    css`
      cursor: pointer;

      &:hover {
        ${TermIndexActions} {
          display: block;
        }
        ${TermIndexDisplay} {
          display: none;
        }
      }
    `}
`;

const TermIndexActions = styled.div`
  display: none;
  padding: 0 5px 0 3px;
`;

const TermIndexDisplay = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 0.95;
  text-align: center;
`;

export const TermIndexDisplayNumber = styled.span``;

const TermIndexControlsIndicator = styled.div`
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  line-height: 0;
  margin-top: -2px;
  font-weight: bold;
  font-size: 12px;
  user-select: none;
`;
