import {InputState} from '../../Voyagecharter/utils/voyageChartercalculation/voyageChartercalculation';
import {OneCalcResult, oneCalcRecalculate} from './oneCalc';
import produce from 'immer';

type Props = {
  oneCalcResults: OneCalcResult[] | undefined;
  setOneCalcResults: (calcs: OneCalcResult[] | undefined) => void;
};

type UpdateSingleCalc = (oneCalc: OneCalcResult, inputState: InputState) => boolean;

export const useUpdateSingleCalc = (props: Props): UpdateSingleCalc => {
  const {setOneCalcResults, oneCalcResults} = props;

  /**
   * @param index
   * @param inputState
   *
   * cange the calculation in calcs array on index with a new calculation based on the inputState
   */
  const updateSingleCalc = (oneCalc: OneCalcResult, inputState: InputState) => {
    const calc = oneCalcRecalculate({oneCalc, voyageCalculationInput: inputState});

    const index = oneCalcResults?.findIndex(calc => calc.id === oneCalc.id);

    if (index !== undefined) {
      setOneCalcResults(
        produce(oneCalcResults!, draftCalcs => {
          draftCalcs[index] = calc;
          return draftCalcs;
        })
      );
      return true;
    }
    return false;
  };
  return updateSingleCalc;
};
