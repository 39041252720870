import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError, isApiErrorWithStatus} from '../../api/utils/ApiError';
import {sessionApi} from '../../api/symfony/symfonyApi';

export type SessionStatus = 'SessionAlive' | 'SessionExpired' | 'LostNetworkConnectivity';

export const useSessionKeepaliveMutation = (options?: UseMutationOptions<SessionStatus, ApiError>) =>
  useMutation({
    mutationFn: async () => {
      try {
        await sessionApi.ping();
        return 'SessionAlive';
      } catch (error) {
        if (sessionExpired(error)) {
          // eslint-disable-next-line no-console
          console.error('SessionTimeout', error);
          return 'SessionExpired';
        }

        if (lostNetworkConnectivity(error)) {
          // eslint-disable-next-line no-console
          console.error('LostNetworkConnectivity', error);
          // Don't show an error modal.
          return 'LostNetworkConnectivity';
        }

        // We have a real error.
        throw error;
      }
    },
    ...options,
  });

const sessionExpired = (error: unknown): boolean => isApiErrorWithStatus(error, 401);

const lostNetworkConnectivity = (error: unknown): boolean =>
  // In production we get a "Failed to fetch" or "Fetch failed" and in development we get a 504 because of the dev server proxy.
  error instanceof TypeError || isApiErrorWithStatus(error, 504);
