import {useState} from 'react';
import Icon from '../../../../../atoms/Icon';
import styled from 'styled-components';
import {EditROBModal} from './EditROBModal';
import {Visualization} from './Visualization';

export const ROBInput = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <EditROBModal onClose={() => setShowModal(false)} />}
      <ROBInputContainer onClick={() => setShowModal(true)}>
        <Visualization />

        <div>
          <Icon className={'editMarker'} size={'unset'} style={{verticalAlign: 'bottom'}} type={'edit'} />
        </div>
      </ROBInputContainer>
    </>
  );
};

const ROBInputContainer = styled.div`
  vertical-align: top;
  display: flex;
  margin-right: 16px;
`;
