import {MulticalcCargoInput, MulticalcVesselInput} from '../../../types';
import {Popover, Table} from 'antd';
import {MultiCalculation} from '../../../useMultiCalculation';
import numeral from 'numeral';
import {FreightIdeaCell} from '../../../utils/FreightIdeaCell';
import styled from 'styled-components';
import {OneCalcResult} from '../../../utils/oneCalc';
import {isCalculationWithResult} from '../../../utils/isCalculationWithResult';
import {ErrorAlert} from '../../../utils/ErrorAlert';
import {ActionDropdown} from '../../../utils/ActionDropdown';
import {TceCell} from '../../../utils/TceCell';
import {calcBreakEvenFreightIdea} from '../../../utils/calcBreakEvenFreightIdea';
import {Route} from '../../../utils/Route';
import uniqBy from 'lodash/uniqBy';
import NoResults from '../../../../../../screens/market/EmptyState/NoResults';
import {Icon} from '../../../../../../atoms/Icon';
import {TableCargoDetail} from '../../components';

type Props = {
  testId: string;
  vessel: MulticalcVesselInput;
  cargoes: MulticalcCargoInput[];
  multiCalculation: MultiCalculation;
};

/**
 * This component shows the nested table for a row in the VesselToCargo.
 * It represents one vessel with the different cargoes in detail.
 */
export const NestedTable = ({testId, vessel, multiCalculation}: Props) => {
  const oneCalcResultsForThisProject: OneCalcResult[] = (multiCalculation.oneCalcResults ?? []).filter(
    calc => calc.vessel.id === vessel.vessel.id && calc.vesselProject?.id === vessel.project?.id
  );

  const arrayOfCargosWithCalculationCantCalc = oneCalcResultsForThisProject.filter(
    oneCalcResult => !isCalculationWithResult(oneCalcResult)
  );
  const arrayOfCargosWithCalculationCalcOK = oneCalcResultsForThisProject.filter(oneCalcResult =>
    isCalculationWithResult(oneCalcResult)
  );
  return (
    <Cell data-cy={'VesselToCargoTableNestedCargoCell_' + testId} data-testid={'NestedTableCell_' + testId}>
      <BorderBox>
        <Table<OneCalcResult>
          data-testid={'NestedTableTable_' + testId}
          locale={{emptyText: <NoResults type={'combination'} secondLine={''} />}}
          loading={multiCalculation.oneCalcResults === undefined}
          dataSource={arrayOfCargosWithCalculationCalcOK}
          rowKey={oneCalcResult => oneCalcResult.id}
          scroll={{x: '100%'}}
          columns={[
            {
              key: 'cargo',
              width: 4,
              title: 'Cargo',
              render: (v, oneCalcResult) => {
                return <TableCargoDetail cargo={oneCalcResult.cargo} />;
              },
            },
            {
              key: 'route',
              width: 5,
              title: 'Route',
              render: (v, oneCalcResult) => <Route cargo={oneCalcResult.cargo} />,
            },
            {
              align: 'right',
              key: 'freightIdea',
              width: 2,
              dataIndex: ['voyageCalculationInput', 'cargo', 'freightIdea'],
              title: 'Freight idea',
              render: (freightIdea, oneCalcResult) => {
                return (
                  <FreightIdeaCell
                    freightIdea={freightIdea}
                    onChange={newFreightIdea => multiCalculation.updateFreightIdea(oneCalcResult, newFreightIdea)}
                  />
                );
              },
            },
            {
              key: 'tce',
              align: 'right',
              dataIndex: ['voyageCalculationOutput', 'cargo', 'tce'],
              title: 'TCE',
              width: 2,
              render: (tce, oneCalcResult) => (
                <TCE data-testid={'NestedTableTableTCECell_' + testId}>
                  <TceCell
                    tce={Math.round(tce)}
                    onChange={v => {
                      const newFreightIdea = calcBreakEvenFreightIdea({
                        oneCalc: oneCalcResult,
                        tceTarget: v,
                      });
                      multiCalculation.updateFreightIdea(oneCalcResult, newFreightIdea);
                    }}
                  />
                </TCE>
              ),
            },
            {
              key: 'revenueNet',
              align: 'right',
              width: 2,
              dataIndex: ['voyageCalculationOutput', 'cargo', 'revenueNet'],
              title: 'Revenue net',
              render: revenueNet => numeral(revenueNet).format('$0,0'),
            },
            {
              key: 'revenueTotal',
              align: 'right',
              width: 2,
              dataIndex: ['voyageCalculationOutput', 'cargo', 'revenueTotal'],
              title: 'Profit',
              render: revenueTotal => numeral(revenueTotal).format('$0,0'),
            },
            {
              key: 'duration',
              width: 2,
              align: 'right',
              dataIndex: ['voyageCalculationOutput', 'cargo', 'duration'],
              title: 'Duration',
              render: duration => `${duration.toFixed(1)} days`,
            },
            {
              key: 'warnings',
              width: 8,
              title: 'Warnings',
              render: (v, oneCalcResult) => {
                return (
                  <Popover
                    trigger={'hover'}
                    content={
                      <ErrorUL>
                        {uniqBy(oneCalcResult.voyageCalculationOutput.warning.list, 'code').map(warning => {
                          return <li key={warning.code + warning.dataFieldId}>{warning.message}</li>;
                        })}
                      </ErrorUL>
                    }>
                    <Icon type={'block'} size={'small'} />{' '}
                    {uniqBy(oneCalcResult.voyageCalculationOutput.warning.list, 'code').length} warnings
                  </Popover>
                );
              },
            },
            {
              key: 'options',
              title: '',
              width: 1,
              render: (v, oneCalcResult) => {
                return <ActionDropdown oneCalcResult={oneCalcResult} />;
              },
            },
          ]}
          pagination={false}
          size={'small'}
        />
      </BorderBox>
      {arrayOfCargosWithCalculationCantCalc.map(oneCalcResult => {
        return <ErrorAlert key={oneCalcResult.id} testId={testId} oneCalcResult={oneCalcResult} />;
      })}
    </Cell>
  );
};

const Cell = styled.div`
  margin-bottom: 50px;
`;

const ErrorUL = styled.ul`
  list-style: disc;
  padding-left: 20px;
  margin: 0;
`;

const TCE = styled.span`
  font-weight: 800;
  text-decoration: underline;
`;

const BorderBox = styled.div`
  border: var(--border-light);
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-bottom: 20px;
`;
