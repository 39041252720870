/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {InvitationStatus, InvitationStatusFromJSON, InvitationStatusFromJSONTyped, InvitationStatusToJSON} from './';

/**
 *
 * @export
 * @interface InviteeInfo
 */
export interface InviteeInfo {
  /**
   *
   * @type {InvitationStatus}
   * @memberof InviteeInfo
   */
  status: InvitationStatus;
  /**
   *
   * @type {string}
   * @memberof InviteeInfo
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof InviteeInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof InviteeInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof InviteeInfo
   */
  email: string;
}

export function InviteeInfoFromJSON(json: any): InviteeInfo {
  return InviteeInfoFromJSONTyped(json, false);
}

export function InviteeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteeInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: InvitationStatusFromJSON(json['status']),
    message: !exists(json, 'message') ? undefined : json['message'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
  };
}

export function InviteeInfoToJSON(value?: InviteeInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: InvitationStatusToJSON(value.status),
    message: value.message,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
  };
}
