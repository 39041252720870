import {WidgetIdentifiers} from '../screens/dashboard/widgets/utils/availableWidgets';
import {SubscriptionPlan, SubscriptionPlans} from '../redux/ApiService/CompanyService/Company';

export type Plan = {
  title: string;
  slug: SubscriptionPlan;
  /** Price per month when contract is monthly */
  priceMonthly: number;
  /** Price per month when contract is yearly */
  priceYearly: number;
};

export const plans = [
  {
    title: 'Free',
    slug: SubscriptionPlans.Free,
    priceMonthly: 0,
    priceYearly: 0,
  },
  {
    title: 'Standard',
    slug: SubscriptionPlans.Standard,
    priceMonthly: 99,
    priceYearly: 84.15,
  },
  {
    title: 'Pro',
    slug: SubscriptionPlans.Pro,
    priceMonthly: 149,
    priceYearly: 126.65,
  },
];

export type PlanLimits = {
  workspaceLimit: number;
  marketGridLimit: number;
  widgetLimit: number;
  restrictedWidgets: WidgetIdentifiers[];
  mapDetailsPromotionProbability: number;
};

export const planLimits: Record<SubscriptionPlan, PlanLimits> = {
  [SubscriptionPlans.Free]: {
    workspaceLimit: 1,
    marketGridLimit: Infinity,
    widgetLimit: 8,
    restrictedWidgets: [
      'fixtureHelper',
      'parser',
      'newVessel',
      'bigNumbersNewVessels',
      'circularStatsHighscore',
      'scrappedVessel',
      'tiedUpVessels',
      'circularStats',
      'bigNumbersScrappedVessels',
      'bunkerPrice',
      'covid19',
      'fixturesBigNumbers',
    ],
    mapDetailsPromotionProbability: 0.3,
  },
  [SubscriptionPlans.Standard]: {
    workspaceLimit: Infinity,
    marketGridLimit: Infinity,
    widgetLimit: Infinity,
    restrictedWidgets: [],
    mapDetailsPromotionProbability: 0,
  },
  [SubscriptionPlans.Pro]: {
    workspaceLimit: Infinity,
    marketGridLimit: Infinity,
    widgetLimit: Infinity,
    restrictedWidgets: [],
    mapDetailsPromotionProbability: 0,
  },
};

export const planLabels = {
  [SubscriptionPlans.Free]: 'Free',
  [SubscriptionPlans.Standard]: 'Standard',
  [SubscriptionPlans.Pro]: 'Pro',
};

export const getSubscriptionPlanNameFromSlug = (subscriptionPlanSlug: SubscriptionPlan): string => {
  return planLabels[subscriptionPlanSlug];
};
