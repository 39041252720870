import {OneCalcResult} from '../../utils/oneCalc';
import {isCalculationWithResult} from '../../utils/isCalculationWithResult';
import {ErrorAlert} from '../../utils/ErrorAlert';
import {FreightIdeaCell} from '../../utils/FreightIdeaCell';
import {MultiCalculation} from '../../useMultiCalculation';
import {TceCell} from '../../utils/TceCell';
import {ActionDropdown} from '../../utils/ActionDropdown';
import {Button, Popover} from 'antd';
import {useGoToUrl} from '../../../../../utils/useChangeBrowserUrl';
import {Attribute, AttributeContainer} from '../../../../../atoms/Attribute/Attribute';
import styled from 'styled-components';
import {Icon} from '../../../../../atoms/Icon';
import {ArrowRightOutlined, ClockCircleOutlined} from '@ant-design/icons';
import {Circle} from '../components';
import {NotAvailable} from '../../../../../utils/NotAvailable';

type OneResultProps = {
  multiCalculation: MultiCalculation;
  oneCalcResult: OneCalcResult;
  score: number;
  hideError?: boolean;
};

export const OneResult = ({multiCalculation, oneCalcResult, score, hideError}: OneResultProps) => {
  const goToUrl = useGoToUrl();

  if (!isCalculationWithResult(oneCalcResult)) {
    return hideError ? (
      <Popover
        trigger={'hover'}
        content={
          <span>
            This combination could not be computed. <br />
            Click "Show errors" or see other tabs for more info.
          </span>
        }>
        <StyledIcon type={'block'} /> {NotAvailable}
      </Popover>
    ) : (
      <ErrorAlert data-cy={'MatrixOneResultError'} oneCalcResult={oneCalcResult} testId={''} isInMatrix={true} />
    );
  }

  return (
    <ResultContainer data-cy={'MatrixOneResult'}>
      <AttributeRow>
        <Circle>$</Circle>
        <Attribute
          tab={'m'}
          label={'TCE'}
          value={
            <TceCell
              tce={oneCalcResult.voyageCalculationOutput.cargo.tce}
              onChange={tceTarget => multiCalculation.updateFreightIdeaByTce(oneCalcResult, tceTarget)}
            />
          }
        />
      </AttributeRow>
      <AttributeRow>
        <Circle>$</Circle>
        <Attribute
          tab={'m'}
          label={'Freight idea'}
          value={
            <FreightIdeaCell
              freightIdea={oneCalcResult.voyageCalculationInput.cargo.freightIdea ?? Infinity}
              onChange={newFreightIdea => multiCalculation.updateFreightIdea(oneCalcResult, newFreightIdea)}
            />
          }
        />
      </AttributeRow>
      <AttributeRow>
        <Circle>
          <ClockCircleOutlined />
        </Circle>
        <Attribute
          tab={'m'}
          label={'Duration'}
          value={`${oneCalcResult.voyageCalculationOutput.cargo.duration.toFixed(1)} days`}
        />
      </AttributeRow>
      {oneCalcResult.vesselProject && (
        <StyledButton
          block
          ghost={true}
          size="small"
          onClick={() => {
            goToUrl(
              `/chartercalculator/voyagecharter?cargoes_ids=${oneCalcResult.cargo.id}&vessel_id=${
                oneCalcResult.vessel.id
              }&project_id=${oneCalcResult.vesselProject!.id}`
            );
          }}>
          <ArrowRightOutlined /> Create new Voyage Calculation
        </StyledButton>
      )}
      {oneCalcResult.cargoProject && (
        <StyledButton
          block
          ghost={true}
          size="small"
          onClick={() => {
            goToUrl(
              `/chartercalculator/voyagecharter?cargoes_ids=${oneCalcResult.cargo.id}&vessel_id=${
                oneCalcResult.vessel.id
              }&project_id=${oneCalcResult.cargoProject!.id}`
            );
          }}>
          <ArrowRightOutlined /> Create new Voyage Calculation
        </StyledButton>
      )}
      {!oneCalcResult.cargoProject && !oneCalcResult.vesselProject && (
        <StyledButton
          block
          ghost={true}
          size="small"
          onClick={() => {
            goToUrl(
              `/chartercalculator/voyagecharter?cargoes_ids=${oneCalcResult.cargo.id}&vessel_id=${oneCalcResult.vessel.id}`
            );
          }}>
          <ArrowRightOutlined /> Single calculation without Project
        </StyledButton>
      )}
      <DropdownContainer>
        <ScoreBox score={score}>{score * 10}/10</ScoreBox>
        <ActionDropdown oneCalcResult={oneCalcResult} />
      </DropdownContainer>
    </ResultContainer>
  );
};

const ResultContainer = styled.div`
  position: relative;
  margin: -16px;
  padding: 18px 16px 16px;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
  display: flex;
`;

const ScoreBox = styled.div<{score: number}>`
  width: 44px;
  padding: 2px 4px;
  background: rgba(22, 166, 0, ${({score}) => score / 4});
  font-size: var(--font-size-sm);
  text-align: center;
  border: var(--border-width) solid rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
`;

const StyledIcon = styled(Icon)`
  vertical-align: bottom;
  color: var(--color-gray-3) !important;
  margin-right: 1px;
`;

const StyledButton = styled(Button)`
  margin-top: 5px;
  background: var(--color-gray-5);
  color: var(--color-gray-1);
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: none;
  text-transform: initial !important;
`;

const AttributeRow = styled.div`
  display: flex;
  align-items: center;
  height: 26px;

  ${AttributeContainer} {
    margin: 0;
  }
`;
