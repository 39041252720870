import React, {ReactElement} from 'react';
import classNames from 'classnames';
import Flag from '../../../atoms/Flags';
import './station-port-option.scss';
import {TODO} from '../../../utils/TODO';

type Props = {
  className?: string;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  onFocus?: $TSFixMeFunction;
  onSelect?: (option: TODO, event: React.MouseEvent) => void;
  option: TODO;
  innerProps: Record<string, TODO>;
  innerRef: React.Ref<HTMLDivElement>;
  data: {
    searchTitle?: string;
    countryObject?: {
      code: string;
      name: string;
    };
  };
};
export const StationPortOption = (props: Props): ReactElement => {
  const {
    data: {searchTitle, countryObject},
    innerProps,
    innerRef,
    isFocused,
    isDisabled,
  } = props;
  const css = classNames('station-port-option', {
    'station-port-option--focused': isFocused,
    'station-port-option--disabled': isDisabled,
  });
  return (
    <div
      ref={innerRef}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        props.onSelect?.(props.option, event);
      }}
      {...innerProps}
      className={css}>
      <Flag keepWidthWhenEmpty shadow={true} countryCode={countryObject?.code} />
      <span className={'station-port-option__name'}>{searchTitle}</span>
      <span className={'station-port-option__country'}>({countryObject?.name})</span>
    </div>
  );
};

export default StationPortOption;
