/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  MailAsJsonApiModel,
  MailAsJsonApiModelFromJSON,
  MailAsJsonApiModelToJSON,
  OfferStatistics,
  OfferStatisticsFromJSON,
  OfferStatisticsToJSON,
} from '../models';

export interface GetMailAnalysisRequest {
  s3ObjectKey: string;
  download?: boolean;
}

export interface GetMailAsJsonRequest {
  s3ObjectKey: string;
}

export interface GetMailAsJsonByCargoRequest {
  cargo: number;
}

export interface GetMailAsJsonByVesselRequest {
  vessel: number;
}

export interface GetMailTextRequest {
  s3ObjectKey: string;
  download?: boolean;
}

export interface GetMailTextByCargoRequest {
  cargo: number;
}

export interface GetMailTextByVesselRequest {
  vessel: number;
}

export interface GetOfferStatisticsByWorkspaceRequest {
  workspace: number;
  from?: string;
  to?: string;
  tags?: Array<string>;
}

export interface GetOriginalMailRequest {
  s3ObjectKey: string;
  download?: boolean;
}

/**
 *
 */
export class InboxApi extends runtime.BaseAPI {
  /**
   * Fetches the information that the parser extracted from the mail with the given S3 object key.
   */
  async getMailAnalysisRaw(requestParameters: GetMailAnalysisRequest): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.s3ObjectKey === null || requestParameters.s3ObjectKey === undefined) {
      throw new runtime.RequiredError(
        's3ObjectKey',
        'Required parameter requestParameters.s3ObjectKey was null or undefined when calling getMailAnalysis.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.download !== undefined) {
      queryParameters['download'] = requestParameters.download;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/{s3ObjectKey}/analysis`.replace(
        `{${'s3ObjectKey'}}`,
        encodeURIComponent(String(requestParameters.s3ObjectKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Fetches the information that the parser extracted from the mail with the given S3 object key.
   */
  async getMailAnalysis(requestParameters: GetMailAnalysisRequest): Promise<any> {
    const response = await this.getMailAnalysisRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches a mail by S3 object key and returns its data in JSON format.
   */
  async getMailAsJsonRaw(requestParameters: GetMailAsJsonRequest): Promise<runtime.ApiResponse<MailAsJsonApiModel>> {
    if (requestParameters.s3ObjectKey === null || requestParameters.s3ObjectKey === undefined) {
      throw new runtime.RequiredError(
        's3ObjectKey',
        'Required parameter requestParameters.s3ObjectKey was null or undefined when calling getMailAsJson.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/{s3ObjectKey}.json`.replace(
        `{${'s3ObjectKey'}}`,
        encodeURIComponent(String(requestParameters.s3ObjectKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => MailAsJsonApiModelFromJSON(jsonValue));
  }

  /**
   * Fetches a mail by S3 object key and returns its data in JSON format.
   */
  async getMailAsJson(requestParameters: GetMailAsJsonRequest): Promise<MailAsJsonApiModel> {
    const response = await this.getMailAsJsonRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the data of the mail that was used to create the offer related to the given cargo.
   */
  async getMailAsJsonByCargoRaw(
    requestParameters: GetMailAsJsonByCargoRequest
  ): Promise<runtime.ApiResponse<MailAsJsonApiModel>> {
    if (requestParameters.cargo === null || requestParameters.cargo === undefined) {
      throw new runtime.RequiredError(
        'cargo',
        'Required parameter requestParameters.cargo was null or undefined when calling getMailAsJsonByCargo.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/by-cargo/{cargo}.json`.replace(
        `{${'cargo'}}`,
        encodeURIComponent(String(requestParameters.cargo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => MailAsJsonApiModelFromJSON(jsonValue));
  }

  /**
   * Returns the data of the mail that was used to create the offer related to the given cargo.
   */
  async getMailAsJsonByCargo(requestParameters: GetMailAsJsonByCargoRequest): Promise<MailAsJsonApiModel> {
    const response = await this.getMailAsJsonByCargoRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the data of the mail that was used to create the offer related to the given vessel.
   */
  async getMailAsJsonByVesselRaw(
    requestParameters: GetMailAsJsonByVesselRequest
  ): Promise<runtime.ApiResponse<MailAsJsonApiModel>> {
    if (requestParameters.vessel === null || requestParameters.vessel === undefined) {
      throw new runtime.RequiredError(
        'vessel',
        'Required parameter requestParameters.vessel was null or undefined when calling getMailAsJsonByVessel.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/by-vessel/{vessel}.json`.replace(
        `{${'vessel'}}`,
        encodeURIComponent(String(requestParameters.vessel))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => MailAsJsonApiModelFromJSON(jsonValue));
  }

  /**
   * Returns the data of the mail that was used to create the offer related to the given vessel.
   */
  async getMailAsJsonByVessel(requestParameters: GetMailAsJsonByVesselRequest): Promise<MailAsJsonApiModel> {
    const response = await this.getMailAsJsonByVesselRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches the text that has been extracted from the mail with the given S3 object key.
   */
  async getMailTextRaw(requestParameters: GetMailTextRequest): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.s3ObjectKey === null || requestParameters.s3ObjectKey === undefined) {
      throw new runtime.RequiredError(
        's3ObjectKey',
        'Required parameter requestParameters.s3ObjectKey was null or undefined when calling getMailText.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.download !== undefined) {
      queryParameters['download'] = requestParameters.download;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/{s3ObjectKey}/text`.replace(
        `{${'s3ObjectKey'}}`,
        encodeURIComponent(String(requestParameters.s3ObjectKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Fetches the text that has been extracted from the mail with the given S3 object key.
   */
  async getMailText(requestParameters: GetMailTextRequest): Promise<string> {
    const response = await this.getMailTextRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the text of the mail that was used to create the offer related to the given cargo.
   */
  async getMailTextByCargoRaw(requestParameters: GetMailTextByCargoRequest): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.cargo === null || requestParameters.cargo === undefined) {
      throw new runtime.RequiredError(
        'cargo',
        'Required parameter requestParameters.cargo was null or undefined when calling getMailTextByCargo.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/by-cargo/{cargo}/text`.replace(
        `{${'cargo'}}`,
        encodeURIComponent(String(requestParameters.cargo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Returns the text of the mail that was used to create the offer related to the given cargo.
   */
  async getMailTextByCargo(requestParameters: GetMailTextByCargoRequest): Promise<string> {
    const response = await this.getMailTextByCargoRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the text of the mail that was used to create the offer related to the given vessel.
   */
  async getMailTextByVesselRaw(requestParameters: GetMailTextByVesselRequest): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.vessel === null || requestParameters.vessel === undefined) {
      throw new runtime.RequiredError(
        'vessel',
        'Required parameter requestParameters.vessel was null or undefined when calling getMailTextByVessel.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/by-vessel/{vessel}/text`.replace(
        `{${'vessel'}}`,
        encodeURIComponent(String(requestParameters.vessel))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Returns the text of the mail that was used to create the offer related to the given vessel.
   */
  async getMailTextByVessel(requestParameters: GetMailTextByVesselRequest): Promise<string> {
    const response = await this.getMailTextByVesselRaw(requestParameters);
    return await response.value();
  }

  /**
   * Provides offer statistics for the given workspace within the provided time range.
   */
  async getOfferStatisticsByWorkspaceRaw(
    requestParameters: GetOfferStatisticsByWorkspaceRequest
  ): Promise<runtime.ApiResponse<OfferStatistics>> {
    if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
      throw new runtime.RequiredError(
        'workspace',
        'Required parameter requestParameters.workspace was null or undefined when calling getOfferStatisticsByWorkspace.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = requestParameters.from;
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = requestParameters.to;
    }

    if (requestParameters.tags) {
      queryParameters['tags[]'] = requestParameters.tags;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/statistics/{workspace}/offers`.replace(
        `{${'workspace'}}`,
        encodeURIComponent(String(requestParameters.workspace))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => OfferStatisticsFromJSON(jsonValue));
  }

  /**
   * Provides offer statistics for the given workspace within the provided time range.
   */
  async getOfferStatisticsByWorkspace(
    requestParameters: GetOfferStatisticsByWorkspaceRequest
  ): Promise<OfferStatistics> {
    const response = await this.getOfferStatisticsByWorkspaceRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches an original mail by S3 object key.
   */
  async getOriginalMailRaw(requestParameters: GetOriginalMailRequest): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.s3ObjectKey === null || requestParameters.s3ObjectKey === undefined) {
      throw new runtime.RequiredError(
        's3ObjectKey',
        'Required parameter requestParameters.s3ObjectKey was null or undefined when calling getOriginalMail.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.download !== undefined) {
      queryParameters['download'] = requestParameters.download;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/inbox/mail/{s3ObjectKey}`.replace(
        `{${'s3ObjectKey'}}`,
        encodeURIComponent(String(requestParameters.s3ObjectKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Fetches an original mail by S3 object key.
   */
  async getOriginalMail(requestParameters: GetOriginalMailRequest): Promise<string> {
    const response = await this.getOriginalMailRaw(requestParameters);
    return await response.value();
  }
}
