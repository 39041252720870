import styled from 'styled-components';
import Icon from '../../../atoms/Icon';
import {SubscriptionPlan} from '../../../redux/ApiService/CompanyService/Company';
import {plans} from '../../../utils/Plans';
import {PlanWithUvps} from './PlanUvpComparison/planUvps';
import {Badge} from '../../../atoms/Badge/Badge';

export const PlanBadge = ({
  targetPlan,
  subscribedPlanSlug,
}: {
  targetPlan: PlanWithUvps;
  subscribedPlanSlug: SubscriptionPlan;
}) => {
  const currentPlanIndex = plans.findIndex(p => p.slug === targetPlan.slug);
  const subscribedPlanIndex = plans.findIndex(p => p.slug === subscribedPlanSlug);
  const isCurrent = subscribedPlanSlug === targetPlan.slug;

  if (currentPlanIndex < subscribedPlanIndex) {
    return null;
  }
  return <BadgeWrapper>{isCurrent ? <CurrentBadge /> : targetPlan.badge}</BadgeWrapper>;
};

const BadgeWrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: 20px;
`;

export const CurrentBadge = () => {
  return (
    <BadgeBlueBordered>
      <Icon type="checkmark" />
      <div>Current</div>
    </BadgeBlueBordered>
  );
};

export const MostPopularBadge = () => {
  return (
    <BadgeGreyBordered>
      <Icon type="thumb-up" />
      <div>Most popular</div>
    </BadgeGreyBordered>
  );
};

export const BestValueBadge = () => {
  return (
    <BadgeGreyBordered>
      <Icon type="star" />
      <div>Best value</div>
    </BadgeGreyBordered>
  );
};

export const BadgeBlueBordered = styled(Badge)`
  background-color: var(--color-white);
  border: 1px solid var(--color-blue);
  color: var(--color-blue);

  & .icon {
    color: var(--color-blue);
  }
`;

export const BadgeGreyBordered = styled(Badge)`
  background-color: var(--color-gray-6);
  border: 1px solid var(--color-gray-4);
  color: var(--color-gray-1);

  & .icon {
    color: var(--color-gray-1);
  }
`;

export const BadgeLightBlue = styled(Badge)`
  background-color: var(--color-blue-bg);
  color: var(--color-blue);

  & .icon {
    color: var(--color-blue);
  }
`;

export const BadgeBlue = styled(Badge)`
  background-color: var(--color-blue);
  color: var(--color-white);

  & .icon {
    color: var(--color-white);
  }
`;

export const BadgeGrey = styled(Badge)`
  background-color: var(--color-gray-5);
  color: var(--color-gray-1);

  & .icon {
    color: var(--color-white);
  }
`;
