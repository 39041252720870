import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../api/utils/fetchJSON';
import {Coordinates} from '../utils/Coordinates';
import {isApiErrorWithStatus} from '../api/utils/ApiError';
import {UseQueryOptions} from '../api/utils/ReactQuery';

export type VesselHistoryRoutePoint = {
  coordinates: Coordinates;

  speed: number;
  timestamp: number;
};

type VesselHistory = {
  ports: number[];
  route: VesselHistoryRoutePoint[];
};

/**
 * We are currently suppressing an error caused by the fact that we have a sync problem between the different vessels.
 * Until this is fixed permanently, we have to use this fix to not compromise the user experience.
 */
export const useVesselHistoryQuery = (imo: number | undefined, options: UseQueryOptions<VesselHistory>) =>
  useQuery({
    queryKey: ['vesselHistory', imo],
    queryFn: async () => {
      try {
        const response = await fetchJSON<{data: VesselHistory}>(`/api/map/vessel/${imo}/history`);
        return response.data;
      } catch (error) {
        if (isApiErrorWithStatus(error, 404)) {
          return {ports: [], route: []};
        }
        throw error;
      }
    },
    ...options,
  });
