import {useEffect, useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';

function openFullscreen() {
  if (document.documentElement?.requestFullscreen) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    document.documentElement.requestFullscreen();
  }
}

function closeFullscreen() {
  // When fullscreen exist
  if (document.exitFullscreen) {
    // When is fullscreen
    if (document.fullscreenElement !== null) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      document.exitFullscreen();
    }
  }
}
export const useFullScreenHandler = () => {
  // If pressed key is our target key then set to true
  const mapContext = useContext(MapContext);

  const closeHandler = () => {
    // When the change was earlier
    if (document.fullscreenElement === null) {
      // fullscreen deactivate
      document.body.setAttribute('style', '');
      mapContext.setFullscreen(false);
    } else {
      // fullscreen activate
      document.body.setAttribute('style', 'overflow:hidden;');
    }
  };

  useEffect(() => {
    mapContext.state.isFullScreen ? openFullscreen() : closeFullscreen();
  }, [mapContext.state.isFullScreen]);

  useEffect(() => {
    window.addEventListener('fullscreenchange', closeHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('fullscreenchange', closeHandler);
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
};
