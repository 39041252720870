import {InfoCircleOutlined} from '@ant-design/icons';
import {Form, Flex, Tabs, FormInstance, Input} from 'antd';
import LabelWrapper from '../../../atoms/LabelWrapper';
import StationsSelector from '../../../components/StationsSelector/StationSelector';
import {DistanceCalculatorFormValues, DistanceCalculatorType} from './DistanceCalculatorFormValues';
import {DistanceCalculatorSpeed} from './DistanceCalculatorSpeed';
import {RouteOptionsFormItem} from './RouteOptions';
import {initialState, stationRules} from '../const';
import styled from 'styled-components';
import {FC, useState} from 'react';
import {TODO} from '../../../utils/TODO';
import {Dayjs} from 'dayjs';

type CalculatorSettingsProps = {
  form: FormInstance<DistanceCalculatorFormValues>;
  routes: TODO[];
  resultETD: Dayjs | null;
  resultETA: Dayjs | null;
  resultSpeed: number | null;
  isCalculating: boolean;
  onFinish: (values: DistanceCalculatorFormValues) => void;
  onReset: () => void;
};

export const CalculatorSettings: FC<CalculatorSettingsProps> = ({
  form,
  routes,
  resultETD,
  resultETA,
  resultSpeed,
  isCalculating,
  onFinish,
  onReset,
}) => {
  const [activeTab, setActiveTab] = useState<DistanceCalculatorType>('ETA');

  return (
    <>
      <StyledLabel>
        <LabelWrapper label={'Estimate'} />
      </StyledLabel>
      <Form<DistanceCalculatorFormValues>
        form={form}
        layout="vertical"
        initialValues={initialState}
        onFinish={values => onFinish({...values, type: activeTab})}
        style={{height: 'calc(100% - 63px)', maxHeight: 'calc(90vh - 164px)'}}
        data-testid="CalculatorSettings">
        <Flex vertical justify="start" style={{height: '100%'}}>
          <DistanceTabs
            hideAdd
            defaultActiveKey="ETA"
            onChange={tab => {
              setActiveTab(tab as DistanceCalculatorType);
              onReset();
            }}
            items={[
              {key: 'ETA', label: <div data-testid="TabLabel-ETA">ETA</div>},
              {key: 'ETD', label: <div data-testid="TabLabel-ETD">ETD</div>},
              {key: 'SPEED', label: <div data-testid="TabLabel-SPEED">SPEED</div>},
            ]}
            data-testid="DistanceCalculatorTabs"
          />
          <Form.Item name="type" valuePropName={'type'} hidden>
            <Input />
          </Form.Item>
          <p style={{paddingLeft: 56, color: 'var(--color-gray-2)'}}>
            <InfoCircleOutlined /> All times and dates are in local time at the corresponding location
          </p>
          <div style={{width: 'calc(50% - 20px)', paddingLeft: '56px', margin: '10px 0 -30px'}}>
            <DistanceCalculatorSpeed
              showInput={activeTab !== 'SPEED'}
              result={resultSpeed}
              isCalculating={isCalculating}
              onCalculate={() => form.submit()}
              onClear={onReset}
            />
          </div>
          <StyledFormItem
            name="stations"
            valuePropName={'stations'}
            style={{margin: '13px 0', height: '100%', overflow: 'auto'}}>
            <StationsSelector
              activeTab={activeTab}
              routes={routes}
              resultETD={resultETD}
              resultETA={resultETA}
              isCalculating={isCalculating}
              onChange={value => {
                form.setFieldsValue({stations: value});
                onReset();
              }}
              onCalculate={() => form.submit()}
              onReset={() => {
                form.resetFields();
                onReset();
              }}
              onClearCalculation={onReset}
            />
            <StyledFormItem name="stations" rules={stationRules}>
              <Input type="hidden" />
            </StyledFormItem>
          </StyledFormItem>
          <Form.Item name="options" valuePropName={'options'} style={{marginBottom: 0, marginTop: 'auto'}}>
            <RouteOptionsFormItem />
          </Form.Item>
        </Flex>
      </Form>
    </>
  );
};

const StyledLabel = styled.div`
  padding: 18px 32px;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-explain-error {
    padding: 0 0 5px 105px;
    transform: translateY(-100%);
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }
`;

const DistanceTabs = styled(Tabs)`
  border: none;
  .ant-tabs-nav-wrap {
    width: 100%;
    flex: none !important;
    display: inline-block !important;
    padding: 0 32px 0 32px;
    border: none;
  }
  .ant-tabs {
    display: flex;
    flex: 1;
  }
  .ant-tabs-tab {
    display: flex;
    flex: 1;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-content-holder {
    margin-top: 0;
    height: 100%;
  }

  .ant-tabs-nav-list {
    div:nth-child(3) {
      margin-right: 0 !important;
    }
  }
`;
