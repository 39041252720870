import {FuelType, VesselConsumptionMode, VesselConsumptionModeUseCase} from '../VesselInformation/VesselTypes';

export const filterVesselConsumptionModes = (props: {
  consumptionModes: VesselConsumptionMode[];
  useCase?: VesselConsumptionModeUseCase;
  mainFuelType?: FuelType;
  isEco?: boolean;
}): VesselConsumptionMode[] => {
  let modes = props.consumptionModes;

  if (props.useCase) {
    modes = modes.filter(consumption => consumption.useCase === props.useCase);
  }

  if (props.mainFuelType) {
    modes = modes.filter(consumption => consumption.mainFuelType === props.mainFuelType);
  }

  return modes;
};
