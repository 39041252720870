import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import {TextArea} from '../../../atoms/TextArea/TextArea';

const Wrapper = BindToProvider(
  'Note / comment ',
  'comment',
  {
    comment: '',
  },
  {},
  {},
  true
)(WrappedDetailsSection);

const Comment = (props: $TSFixMe) => (
  <Wrapper {...props}>
    {({onChange, values}: $TSFixMe) => (
      <div className="container-fluid">
        <div className="row">
          <div className="scol-12 scol-sm-12">
            <TextArea
              id={'form-comment-comment'}
              onChange={(e: $TSFixMe) => onChange('comment', e.target.value)}
              value={values.comment}
              noResize
              rows={8}
              large={false}
              limit={1000}
            />
          </div>
        </div>
      </div>
    )}
  </Wrapper>
);

export default Comment;
