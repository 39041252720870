import {InputState, OutputState} from './voyageChartercalculation';

export const calcRevenueNet = (params: {input: InputState; output: OutputState}): number => {
  const freightUnit = params.input.cargo.freightUnit;
  switch (freightUnit) {
    case 'lumpsum':
      return params.input.cargo.freightIdea ?? 0;
    case 'perDay':
      return (params.input.cargo.freightIdea ?? 0) * (params.output.cargo.duration ?? 0);
    case 'perMts':
    // Fallthrough
    default:
      return (params.input.cargo.freightIdea ?? 0) * (params.input.cargo.cargoQuantity ?? 0);
  }
};
