import {Select} from '../../../../antd/components/Select';
import {VesselSizeType, vesselSizeTypes} from '../../../../hooks/useGetCompetitors/VesselSizeType';

const Option = Select.Option;

export const VesselSizeSelect = (props: {onChange: (vesselSizeType: VesselSizeType) => void}) => {
  return (
    <Select
      data-testid={'vesselSizeSelect'}
      onChange={value => {
        const item = vesselSizeTypes.find(item => item.value === value);
        props.onChange(item!);
      }}
      options={vesselSizeTypes}>
      {vesselSizeTypes.map(item => (
        <Option data-testid={'vesselSizeSelectOption'} value={item.value} key={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};
