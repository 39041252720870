import {useState, FC} from 'react';
import {TagSelection} from '../../TagSelection/TagSelection';
import {TagsView} from '../../TagSelection/TagsView';
import {Tag, TagType} from '../../../api/symfony/generated';
import {TODO} from '../../../utils/TODO';
import {useAvailableTagsQuery} from '../../../queries/useAvailableTagsQuery';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

interface Props {
  project: TODO;
  onUpdate: (updatedProject: TODO) => void;
}

export const ProjectTags: FC<Props> = ({project, onUpdate}) => {
  const availableTagsQueryResult = useAvailableTagsQuery({type: TagType.Project});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onChange = (tagsSelected: Tag[]) => {
    const updatedProject = {...project, tags: tagsSelected};
    onUpdate(updatedProject);
  };

  if (!availableTagsQueryResult.isSuccess) {
    return null;
  }

  const sortedSelectedTags = sortTags(project.tags);
  const sortedAvailableTags = sortTags(availableTagsQueryResult.data.items);

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}>
      <TagSelection
        onOpenChange={open => setIsModalOpen(open)}
        selectedTags={sortedSelectedTags}
        availableTags={sortedAvailableTags}
        onChange={newSelectedTags => onChange(newSelectedTags)}>
        <FlexBox>
          <TagsView modalOpen={isModalOpen} selectedTags={sortedSelectedTags} />
        </FlexBox>
      </TagSelection>
    </div>
  );
};

const sortTags = (tags: Tag[]): Tag[] => {
  const sortedTags = sortBy(tags, tag => tag.name.toLowerCase());
  return sortedTags;
};

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
