import {OneCalcResult} from '../../utils/oneCalc';
import {FC} from 'react';
import styled from 'styled-components';
import {Route} from '../../utils/Route';
import {TableCargoDetail, TableProjectDetail} from '../components';

export const OneCargo: FC<{oneCalcResult: OneCalcResult}> = ({oneCalcResult}) => {
  const cargo = oneCalcResult.cargo;
  const cargoProject = oneCalcResult.cargoProject;

  return (
    <CargoHeaderContainer data-cy={'MatrixOneCargo'}>
      {cargoProject && (
        <CargoHeaderBox>
          <TableProjectDetail project={cargoProject} />
        </CargoHeaderBox>
      )}
      <CargoHeaderBox>
        <TableCargoDetail cargo={cargo} />
      </CargoHeaderBox>
      <CargoHeaderRow>
        <Route cargo={cargo} />
      </CargoHeaderRow>
    </CargoHeaderContainer>
  );
};

const CargoHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
`;

const CargoHeaderRow = styled.div`
  width: 100%;
`;

const CargoHeaderBox = styled(CargoHeaderRow)`
  display: flex;
  align-items: center;
  min-height: 54px;
  padding: 10px;
  margin-bottom: 5px;
  background: var(--color-gray-5);
  border-radius: var(--border-radius);
`;
