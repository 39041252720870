/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
  /**
   *
   * @type {string}
   * @memberof AccessToken
   */
  tokenType: AccessTokenTokenTypeEnum;
  /**
   * Lifetime of the new access token in seconds.
   * @type {number}
   * @memberof AccessToken
   */
  expiresIn: number;
  /**
   * The access token.
   * @type {string}
   * @memberof AccessToken
   */
  accessToken: string;
  /**
   * The refresh token, to refresh tokens issued via "password" and "refresh_token" grant types.
   * @type {string}
   * @memberof AccessToken
   */
  refreshToken?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AccessTokenTokenTypeEnum {
  Bearer = 'Bearer',
}

export function AccessTokenFromJSON(json: any): AccessToken {
  return AccessTokenFromJSONTyped(json, false);
}

export function AccessTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessToken {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tokenType: json['token_type'],
    expiresIn: json['expires_in'],
    accessToken: json['access_token'],
    refreshToken: !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
  };
}

export function AccessTokenToJSON(value?: AccessToken | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token_type: value.tokenType,
    expires_in: value.expiresIn,
    access_token: value.accessToken,
    refresh_token: value.refreshToken,
  };
}
