import compact from 'lodash/compact';

export interface NamedValue<ValueType> {
  name: string;
  value: ValueType;
}

export const convertValueToNamedValue = <ValueType, DefaultType>(params: {
  value: ValueType | undefined | null;
  available: NamedValue<ValueType>[];
  defaultValue: DefaultType;
}): NamedValue<ValueType> | DefaultType => {
  const {value, available, defaultValue} = params;
  if (!value) {
    return defaultValue;
  }
  const found = available.find(namedValue => namedValue.value === value);
  return found ? found : defaultValue;
};

export const convertValuesToNamedValues = <ValueType>(
  values: ValueType[],
  availableNamedValues: NamedValue<ValueType>[]
): NamedValue<ValueType>[] => {
  const mapped: (NamedValue<ValueType> | null)[] = values.map(value =>
    convertValueToNamedValue({value, available: availableNamedValues, defaultValue: null})
  );
  const withoutNulls = compact(mapped);
  return withoutNulls;
};
