import {Station} from '../../../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {Location, possibleLocationTypes} from '../../../../../components/SearchPicker/Type';

export const getLocations = (params: {stationTypes: string[]; stations: Station[]}) => {
  return params.stations
    .filter(station => params.stationTypes.includes(station.type))
    .map(station => station.locations)
    .flat()
    .filter(location => possibleLocationTypes.includes(location.type)) as Location[];
};
