export class ApiError extends Error {
  name = 'ApiError';
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, status: number, payload?: any) {
    super(message);
    this.status = status;
    this.payload = payload;
  }
}

export const isApiError = (error: unknown): error is ApiError => {
  return error instanceof ApiError;
};

export const isApiErrorWithStatus = (error: unknown, status: number): error is ApiError => {
  if (!isApiError(error)) {
    return false;
  }

  return status === error.status || !status;
};
