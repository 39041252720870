import {ReactNode, FC, Fragment} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {ModalActions} from '../../../../redux/Modal';
import {useDispatch} from '../../../../redux/react-redux';
import {OnBoardingItem} from './OnBoardingItem';
import {RemoveOnBoardingBanner} from './RemoveOnBoardingBanner';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import AddCompanyMembers from '../../../../components/Modal/AddCompanyMembers';
import UploadCompanyLogo from '../../../../components/Modal/UploadCompanyLogo';
import EditCompanyDescription from '../../../../components/Modal/EditCompanyDesciption';

interface CollapsibleContentProps {
  isVerified: boolean;
  data: Company;
  hasLogo: boolean;
  hasUsers: boolean;
  hasDescription: boolean;
  onRemove: () => Promise<void>;
  onRefresh: () => Promise<void>;
  isFinished: boolean;
}

export const CollapsibleContent: FC<CollapsibleContentProps> = ({
  isVerified,
  data,
  hasLogo,
  hasUsers,
  hasDescription,
  onRemove,
  onRefresh,
  isFinished,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  return (
    <Fragment>
      <Row className="onBoarding-content">
        <OnBoardingItem
          className={'verify'}
          onClick={() => {
            navigate(`/company/${data.id}/verify`);
          }}
          done={isVerified}
          icon={'company'}
          label={'Verify company data'}
        />

        <OnBoardingItem
          className={'logo'}
          onClick={() => {
            showModal(
              <UploadCompanyLogo
                id={data.id}
                callback={() => {
                  void onRefresh();
                }}
              />
            );
          }}
          done={hasLogo}
          icon={'cloud-upload'}
          label={'Upload logo'}
        />

        <OnBoardingItem
          className={'description'}
          onClick={() => {
            showModal(
              <EditCompanyDescription
                companyData={data}
                callback={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  onRefresh();
                }}
              />
            );
          }}
          done={hasDescription}
          icon={'edit'}
          label={'Add company description'}
        />

        <OnBoardingItem
          className={'users'}
          onClick={() => {
            showModal(<AddCompanyMembers callback={onRefresh} />);
          }}
          done={hasUsers}
          icon={'tour-add-contacts'}
          label={'Add users'}
        />
      </Row>
      {isFinished && <RemoveOnBoardingBanner onRemove={onRemove} />}
    </Fragment>
  );
};
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
