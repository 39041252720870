import {ChartLabel} from './ChartLabel';
import React, {FC} from 'react';
import {BasicBox} from './BasicBox';
import {LoggedFilters} from '../../../../../hooks/useGetCompetitors/filterVesselsAndAddDistance/steps/StepResult';
import {filterOptions} from '../../../../../hooks/useGetCompetitors/filterOptions';

export const Content: FC<{
  resultsAfterFilter?: LoggedFilters;
}> = ({resultsAfterFilter}) => {
  return (
    <div style={{position: 'absolute', top: 86, width: 'calc(100% - 48px)'}}>
      {filterOptions.map((option, index) => {
        const right = index % 2 === 1;
        const appliedFilter = resultsAfterFilter?.find(item => item.id === option.id);

        if (!appliedFilter) {
          return <BasicBox key={option.id} />;
        }
        const text = appliedFilter.label + ` (${appliedFilter.count} vessels)`;

        return (
          <ChartLabel key={option.id} borderColor={option.borderColor} text={text} disable={false} right={right} />
        );
      })}
    </div>
  );
};
