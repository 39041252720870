import React, {useMemo} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {OfferStatisticsElement} from './OfferStatistic';
import uniq from 'lodash/uniq';
import {UndefinedLabel} from '../UndefinedLabel';

const getValue = ({buckets}: {buckets: OfferBucket[]}) => {
  const senders = buckets.map(bucket => bucket.tags?.find(tag => tag.name === 'sender')?.value);

  const uniqueSenders = uniq(senders);
  return uniqueSenders.length;
};

export const NumberDifferentSenders = ({buckets}: {buckets: OfferBucket[]}) => {
  const value = useMemo(() => getValue({buckets}), [buckets]);

  return (
    <OfferStatisticsElement
      title={'Number of different senders'}
      trend={null}
      value={value ? `${Math.round(value)}` : UndefinedLabel}
    />
  );
};
