import dayjs from 'dayjs';
import {Notification} from '../../api/symfony/generated';
import Avatar from '../../atoms/Avatar';
import {MarkAsReadButton} from './MarkAsReadButton';
import {CustomColumnDef} from '../../components/DataGrid/DataGrid';
import {getNotificationClickTarget} from '../../atoms/Layout/FullPageHeader/HeaderNotificationButton/getNotificationClickTarget';

export const notificationColumns = (goToUrl: (link: string) => void): CustomColumnDef<Notification>[] => [
  {
    header: undefined,
    id: 'user',
    cell: info => info.getValue(),
    accessorFn: notification => {
      const isClickable = !!getNotificationClickTarget(notification);
      return (
        <div
          style={{cursor: isClickable ? 'pointer' : 'default'}}
          className={'notifications-grid__user'}
          onClick={() => {
            const link = getNotificationClickTarget(notification);
            if (link) {
              goToUrl(link);
            }
          }}>
          <Avatar imageUrl={notification.authors[0]?.avatar ?? undefined} size={'large'} />
          <div>
            <span className={'notifications-grid__user-name'}>
              {!notification.isRead && <span className="notifications-grid__badge">New</span>}
              {notification.message}
            </span>
            {!notification.time ? null : (
              <div>{dayjs.duration(dayjs().diff(dayjs(notification.time))).humanize()} ago</div>
            )}
          </div>
        </div>
      );
    },
    minWidth: 14,
  },
  {
    header: undefined,
    id: 'button',
    cell: info => info.getValue(),
    accessorFn: notification => !notification.isRead && <MarkAsReadButton notification={notification} />,
    minWidth: 32,
    maxWidth: 60,
  },
];
