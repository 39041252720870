/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ReportCircularParsingProblemBody,
  ReportCircularParsingProblemBodyFromJSON,
  ReportCircularParsingProblemBodyToJSON,
} from '../models';

export interface ReportCircularParsingProblemRequest {
  reportCircularParsingProblemBody: ReportCircularParsingProblemBody;
}

/**
 *
 */
export class ReportProblemApi extends runtime.BaseAPI {
  /**
   */
  async reportCircularParsingProblemRaw(
    requestParameters: ReportCircularParsingProblemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.reportCircularParsingProblemBody === null ||
      requestParameters.reportCircularParsingProblemBody === undefined
    ) {
      throw new runtime.RequiredError(
        'reportCircularParsingProblemBody',
        'Required parameter requestParameters.reportCircularParsingProblemBody was null or undefined when calling reportCircularParsingProblem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/report-problem/circular-parsing`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ReportCircularParsingProblemBodyToJSON(requestParameters.reportCircularParsingProblemBody),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async reportCircularParsingProblem(requestParameters: ReportCircularParsingProblemRequest): Promise<void> {
    await this.reportCircularParsingProblemRaw(requestParameters);
  }
}
