import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {FilterItem, UpdateFilterRequest} from '../../api/symfony/generated';
import {ApiError} from '../../api/utils/ApiError';
import {filterApi} from '../../api/symfony/symfonyApi';

export const useUpdateFilterMutation = (options?: UseMutationOptions<FilterItem, ApiError, UpdateFilterRequest>) =>
  useMutation({
    mutationFn: (request: UpdateFilterRequest) => filterApi.updateFilter(request),
    ...options,
  });
