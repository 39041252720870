import {useState, FC} from 'react';

type Props = {
  src: string;
  alt?: string;
  fallbackSrc: string;
  className?: string;
};

/**
 * A replacement for <img> that support a fallback image in case the intended URL can't be loaded.
 */
export const Image: FC<Props> = props => {
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  const {fallbackSrc, ...imgProps} = props;
  const effectiveSrc = error ? fallbackSrc : props.src;
  return <img {...imgProps} src={effectiveSrc} onError={onError} />;
};
