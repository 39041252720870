import {Input} from 'antd';
import React, {FC, useState} from 'react';
import {Modal} from '../../antd/components/Modal';

type TextEditModalProps = {
  title: string;
  currentValue?: string | null;
  onSave: (value: string) => void;
  onCancel: () => void;
};

export const EditPrivateNoteModal: FC<TextEditModalProps> = ({title, currentValue, onSave, onCancel}) => {
  const [text, setText] = useState(currentValue ?? '');
  return (
    <Modal title={title} open={true} onOk={() => onSave(text)} onCancel={onCancel} okText="Update">
      <Input.TextArea autoFocus autoSize rows={8} value={text} onChange={event => setText(event.currentTarget.value)} />
    </Modal>
  );
};
