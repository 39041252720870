import {MapSwitchesKey} from '../MapDetails/utils/types';
import {SWITCHES} from '../mapOptions/switches';
import {CargoHexagon} from '../../../atoms/CargoHexagon/CargoHexagon';

export const renderSpecialControl = (option: $TSFixMe, switches: $TSFixMe) => {
  const subSwitch = `${option.id}_sub` as MapSwitchesKey;

  switch (option.id) {
    case SWITCHES.CARGO_CONTRACT_TYPE:
      return (
        <div className="map-menu__section-special">
          {option.subMenu.map((menu: $TSFixMe, i: $TSFixMe) => (
            <CargoHexagon key={i} type={switches[subSwitch][menu.id] ? menu.id : 'empty'} />
          ))}
        </div>
      );
    case SWITCHES.CARGO_COMMODITIES: {
      const numEnabledOptions = Object.values(switches[subSwitch]).filter(v => v).length;
      return (
        <div className="map-menu__section-special">
          {numEnabledOptions > 0 && `${numEnabledOptions}/${option.subMenu.length}`}
        </div>
      );
    }
    default:
      return null;
  }
};
