import {NegotiationSubjectStatus} from '../../../../../../api/node-backend/generated';
import {assertUnreachable} from '../../../../../../utils/assert';

export const getSubjectStatusLabel = (status: NegotiationSubjectStatus): string => {
  switch (status) {
    case NegotiationSubjectStatus.Ongoing:
      return 'Ongoing subject';
    case NegotiationSubjectStatus.Accepted:
      return 'Accepted subject';
    case NegotiationSubjectStatus.Rejected:
      return 'Rejected subject';
    case NegotiationSubjectStatus.RejectionRequested:
      return 'Subject with rejection request';
    case NegotiationSubjectStatus.Lifted:
      return 'Lifted subject';
    case NegotiationSubjectStatus.Failed:
      return 'Failed subject';
    default:
      assertUnreachable(status);
  }
};

export const getSubjectPrintStatusLabel = (status: NegotiationSubjectStatus): string => {
  switch (status) {
    case NegotiationSubjectStatus.Ongoing:
      return 'Ongoing';
    case NegotiationSubjectStatus.Accepted:
      return 'Accepted';
    case NegotiationSubjectStatus.Rejected:
      return 'Rejected';
    case NegotiationSubjectStatus.RejectionRequested:
      return 'Reject requested';
    case NegotiationSubjectStatus.Lifted:
      return 'Lifted';
    case NegotiationSubjectStatus.Failed:
      return 'Failed';
    default:
      assertUnreachable(status);
  }
};
