import {FC} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import {Breadcrumb} from '../../../atoms/Breadcrumb';
import {NotFound} from '../../../atoms/EmptyState/404';
import {LoadingIndicator} from '../../../atoms/Loading';
import {useMapApi} from '../../../components/SeaboMap/useMapApi';
import {RequestState} from '../../../redux/ApiService/ApiService';
import {getPort} from '../../../redux/ApiService/ports';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {PortHeader} from './PortHeader';
import {VesselsExpectedSection} from './sections/VesselsExpectedSection';
import {VesselsInPortSection} from './sections/VesselsInPortSection';
import {usePortExpectedVesselsQuery} from './usePortExpectedVesselsQuery';
import {useVesselsInPortQuery} from './useVesselsInPortQuery';
import {MapVesselElementObject} from '../../../components/SeaboMap/MapDetails/utils/types';

export const PortDetailScreen = () => {
  const params = useParams<'portCode'>();
  return (
    <>
      <Breadcrumb items={[{title: 'Ports', href: '/ports'}, {title: 'Port details'}]} />
      <PortContent portCode={params.portCode || ''} />
    </>
  );
};

const PortContent: FC<{portCode: string}> = ({portCode}) => {
  const dispatch = useDispatch();

  const portNotFound = useSelector(state => state.api.ports.getPort.state === RequestState.ERROR);
  const expectedVesselsQuery = usePortExpectedVesselsQuery({portCode});
  const vesselsInPortQuery = useVesselsInPortQuery({portCode});
  const portQuery = useQuery({
    queryKey: [portCode],
    queryFn: () => dispatch(getPort(portCode)),
  });
  const aisVessels = (vesselsInPortQuery.data ?? []).map((vessel: MapVesselElementObject) => ({
    ...vessel,
    coordinates: [vessel.longitude, vessel.latitude],
    type: vessel.class,
  }));

  const mapApi = useMapApi();

  if (portQuery.isLoading) {
    return <LoadingIndicator />;
  }
  if (portNotFound) {
    return <NotFound title={'Port not found '} />;
  }

  return (
    <div className={'port-screen'}>
      <PortHeader
        map={mapApi}
        port={portQuery.data}
        totalMoored={vesselsInPortQuery.data?.length ?? 0}
        totalExpected={expectedVesselsQuery.data?.length ?? 0}
        aisVessels={aisVessels}
      />
      <VesselsInPortSection portCode={portCode} />
      <VesselsExpectedSection portCode={portCode} />
    </div>
  );
};
