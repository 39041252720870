import {FC, ReactNode, useState, useMemo, CSSProperties} from 'react';
import classNames from 'classnames';
import {Pagination} from './Pagination/Pagination';
import prepareColumns from './helper';
import {TotalItemsForE2ETests} from './TotalItemsForE2ETests';
import {FetchingForE2ETests} from './FetchingForE2ETests';
import {NoDataComponentDefault} from './NoDataComponentDefault';
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  ColumnDef,
  ColumnSort,
  RowData,
  TableState,
  Cell,
  Row,
  PaginationState,
  SortingOptions,
} from '@tanstack/react-table';
import styled from 'styled-components';
import LoadingComponent from './LoadingComponent';
import {DataGridHeader} from './DataGridHeader';
import {DataGridBody} from './DataGridBody';
import './style.scss';

const defaultPageSizeOptions = [5, 10, 25, 50, 100];
const noop = () => {};

export type CustomColumnDef<T> = ColumnDef<T> & {
  className?: string;
  headerClassName?: string;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  style?: CSSProperties;
  onMouseOver?: (original?: RowData) => void;
  onClick?: (original?: RowData) => void;
};

export interface DataGridProps<T> {
  data?: T[];
  columns: CustomColumnDef<T>[];
  enableStickyHeader?: boolean;
  id?: string;
  responsive?: boolean;
  totalCount?: number;
  fetching?: boolean;
  onSettingChange?: (settings: {page: number; pageSize: number; sortedBy?: ColumnSort}) => void;
  noHoverStatePointer?: boolean;
  noHoverStateBackgroundEffect?: boolean;
  unrounded?: boolean;
  unroundedTopLeft?: boolean;
  unroundedTopRight?: boolean;
  emptyMessage?: string;
  NoDataComponent?: ReactNode;
  TbodyComponent?: ReactNode;
  CustomLoadingComponent?: FC;
  // Render component to be visible when row is expanded
  renderSubComponent?: (row: Row<T>) => ReactNode;
  PromotionComponent?: ReactNode;
  className?: string;
  zeroBasedPageIndex?: boolean;
  loading?: boolean;
  hideHeader?: boolean;
  showPageSizeOptions?: boolean;
  pageSizeOptions?: number[];
  multiSort?: boolean;
  pagination?: PaginationState;
  sortable?: boolean;
  showPagination?: boolean;
  showJumper?: boolean;
  manualSorting?: boolean;
  manualPagination?: boolean;
  pageSize?: number;
  page?: number;
  onPageChange?: (pageIndex: number) => void;
  onPageSizeChange?: (pageSize: number, pageIndex: number) => void;
  onSortedChange?: (sorting: ColumnSort) => void;
  onExpandedChange?: (expanded: ExpandedState) => void;
  sorted?: SortingState;
  getTrProps?: (state: TableState, rowInfo: Row<T>) => Partial<CustomColumnDef<T>>;
  getTdProps?: (state: TableState, rowInfo: Row<T>, cell: Cell<T, unknown>) => Partial<CustomColumnDef<T>>;
}

export const DataGrid = <T,>({
  columns = [],
  className,
  totalCount = 0,
  loading = false,
  fetching,
  showPageSizeOptions = true,
  pageSize = 50,
  page = 1,
  onPageChange = noop,
  onPageSizeChange = noop,
  onSortedChange = noop,
  onSettingChange = noop,
  sorted,
  manualSorting = true,
  manualPagination = true,
  responsive,
  noHoverStatePointer = false,
  noHoverStateBackgroundEffect = false,
  enableStickyHeader = true,
  showPagination = true,
  showJumper,
  emptyMessage,
  NoDataComponent = null,
  TbodyComponent = null,
  pageSizeOptions = defaultPageSizeOptions,
  PromotionComponent = null,
  CustomLoadingComponent = LoadingComponent,
  hideHeader = false,
  data = [],
  pagination = {
    pageIndex: 0,
    pageSize: pageSize,
  },
  getTrProps,
  getTdProps,
  renderSubComponent,
  zeroBasedPageIndex = true,
  unrounded,
  unroundedTopLeft,
  unroundedTopRight,
}: DataGridProps<T>) => {
  const [sorting, _setSorting] = useState<SortingState>(sorted || []);

  const memoColumns = useMemo(() => prepareColumns<CustomColumnDef<T>>({columns}), [columns]);

  const handleSortingChange: SortingOptions<T>['onSortingChange'] = updaterOrValue => {
    const newSorting = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;

    onSettingChange({
      page: 1,
      pageSize,
      sortedBy: newSorting[0],
    });

    onSortedChange(newSorting[0]);
    _setSorting(newSorting);
  };

  const table = useReactTable({
    data,
    columns: memoColumns,
    state: {
      sorting,
      pagination: manualPagination
        ? pagination
        : {
            pageIndex: zeroBasedPageIndex ? page : page - 1,
            pageSize,
          },
    },
    getExpandedRowModel: getExpandedRowModel(),
    enableSortingRemoval: false,
    onSortingChange: handleSortingChange,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false,
    manualSorting: manualSorting,
    pageCount: totalCount ? Math.ceil(totalCount / pageSize) : 0,
  });

  const pages = table.getPageCount();

  const wrappedNoDataComponent = () => {
    if (NoDataComponent) {
      return NoDataComponent;
    }
    if (TbodyComponent) {
      return TbodyComponent;
    }
    return <NoDataComponentDefault message={emptyMessage} />;
  };
  return (
    <DataGridWrapper className="datagrid-wrapper" data-cy="DataGrid">
      <TotalItemsForE2ETests totalItems={totalCount} />
      <FetchingForE2ETests fetching={fetching} />
      <div
        className={classNames(
          'datagrid',
          {'datagrid--responsive': responsive},
          {'datagrid--no-hover-state-pointer': noHoverStatePointer},
          {'datagrid--no-hover-state-background-effect': noHoverStateBackgroundEffect},
          {unrounded},
          {'unrounded-top-left': unroundedTopLeft},
          {'unrounded-top-right': unroundedTopRight},
          className
        )}
        role="grid">
        <div className="rt-table">
          {!hideHeader && <DataGridHeader table={table} enableStickyHeader={enableStickyHeader} />}
          {data.length === 0 && !loading ? (
            wrappedNoDataComponent()
          ) : (
            <DataGridBody
              table={table}
              renderSubComponent={renderSubComponent}
              getTrProps={getTrProps}
              getTdProps={getTdProps}
            />
          )}
        </div>
      </div>
      {showPagination && (
        <Pagination
          isZeroBased={zeroBasedPageIndex}
          pages={pages}
          page={page}
          showPageSizeOptions={showPageSizeOptions}
          pageSizeOptions={pageSizeOptions}
          showJumper={showJumper}
          pageSize={pageSize}
          totalCount={totalCount}
          onPageChange={pageIndex => {
            onSettingChange({page: pageIndex, pageSize, sortedBy: sorting?.[0]});
            onPageChange(pageIndex);
          }}
          onPageSizeChange={pageSize => {
            !manualPagination && table.setPageSize(pageSize);
            onSettingChange({page: zeroBasedPageIndex ? 0 : 1, pageSize, sortedBy: sorting?.[0]});
            onPageSizeChange(pageSize, zeroBasedPageIndex ? 0 : 1);
          }}
        />
      )}
      {PromotionComponent}
      {loading && (
        <LoadingWrapper>
          <LoadingContainer>
            <CustomLoadingComponent />
          </LoadingContainer>
        </LoadingWrapper>
      )}
    </DataGridWrapper>
  );
};

const DataGridWrapper = styled.div`
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 50px;
  left: 50%;
  width: 100%;
  height: 100%;
  justify-content: center;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.5);
`;

const LoadingContainer = styled.div`
  width: 200px;
  height: 150px;
`;
