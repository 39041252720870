import {StepResult} from './StepResult';
import {PreparedAisVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {FilterParams} from '../filterParams';

export type Step0Result = StepResult<PreparedAisVessel>;

/**
 *  initial step - show all vessels with the following properties:
 *  1. only current vessels
 *     remove all vessels that have not sent their AIS data for more than 10 days.
 *  2. only vessels with current ETA
 *     remove all vessels whose ETA is older than 30 days.
 */
export const allVessels = ({params, vessels}: {vessels: PreparedAisVessel[]; params: FilterParams}): Step0Result => {
  return {
    vessels: vessels,
    loggedFilters: [
      {
        label: 'All Vessels',
        count: params.vessels.length,
        id: 'allVessels',
      },
    ],
  };
};
