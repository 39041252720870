import {useState} from 'react';

export const useArray = <T>(initial: T[]) => {
  const [value, setValue] = useState(initial);

  const push = (item: T) => setValue(a => [...a, item]);

  const clear = () => setValue([]);

  const addIndex = (index: number, item: T) => {
    const draft = [...value];
    draft.splice(index, 0, item);
    setValue(draft);
  };

  const removeIndex = (index: number) => {
    const draft = [...value];
    draft.splice(index, 1);
    setValue(draft);
  };

  const toggleItem = (item: T) => {
    const draft = [...value];
    if (draft.includes(item)) {
      draft.splice(draft.indexOf(item), 1);
    } else {
      draft.push(item);
    }
    setValue(draft);
  };

  return [
    value,
    {
      set: setValue,
      push,
      clear,
      addIndex,
      removeIndex,
      toggleItem,
    },
  ] as const;
};
