import React, {FC} from 'react';
import styled from 'styled-components';
import {NotificationListControls} from './NotificationListControls';
import {NotificationListEntry} from './NotificationListEntry';
import {Spacer} from '../../../Spacer/Spacer';
import {Notification} from '../../../../api/symfony/generated/models/Notification';
import Icon from '../../../Icon';

export const notificationShownInDropdown = 10;

export const NotificationDropdownContent: FC<{
  notifications: Notification[];
  onClick?: (notification: Notification) => void;
  onMarkAsRead?: (notification: Notification) => void;
  onMarkAllAsRead?: (notifications: Notification[]) => void;
  onSettings?: () => void;
  onSeeAll?: () => void;
}> = ({notifications, onClick, onMarkAsRead, onMarkAllAsRead, onSettings, onSeeAll}) => {
  const anyData = notifications.length > 0;
  const hasMoreNotificationsAsShown = notifications.length > notificationShownInDropdown;

  const notificationsToDisplay = [...notifications].slice(0, notificationShownInDropdown);

  return (
    <NotificationDropdownContentStyled data-testid="NotificationDropdownContent">
      <NotificationListControls
        notifications={notificationsToDisplay}
        onSettings={onSettings}
        onMarkAllAsRead={onMarkAllAsRead}
      />
      {anyData && (
        <NotificationList
          notifications={notificationsToDisplay}
          onClick={onClick}
          hasMoreNotificationsAsShown={hasMoreNotificationsAsShown}
          onMarkAsRead={onMarkAsRead}
        />
      )}
      {!anyData && <NoDataMessage />}
      <ActionLink onClick={onSeeAll} data-testid="SeeAll">
        See all notifications
      </ActionLink>
    </NotificationDropdownContentStyled>
  );
};

const NotificationDropdownContentStyled = styled.div`
  width: 350px;
  padding: 0 15px 0px 15px;
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow:
    0 1px 0px rgba(0, 0, 0, 0.16),
    0 1px 3px rgba(0, 0, 0, 0.23);
`;

const ActionLink = styled.a`
  display: block;
  height: 50px;
  font-family: var(--font-family-base);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: bold;
  line-height: 50px;
  text-transform: uppercase;
`;

const NotificationList: FC<{
  notifications: Notification[];
  onClick?: (notification: Notification) => void;
  hasMoreNotificationsAsShown: boolean;
  onMarkAsRead?: (notification: Notification) => void;
}> = ({notifications, onClick, hasMoreNotificationsAsShown, onMarkAsRead}) => (
  <NotificationListStyled data-testid="NotificationList">
    <Separator />
    {hasMoreNotificationsAsShown && (
      <div
        style={{
          textAlign: 'center',
        }}>
        ...
      </div>
    )}
    <Spacer height={10} />
    {notifications.map(notification => (
      <NotificationListEntry
        key={notification.id}
        notification={notification}
        onClick={() => {
          onClick?.(notification);
        }}
        onRead={() => {
          onMarkAsRead?.(notification);
        }}
      />
    ))}

    <Spacer height={4} />

    <Separator />
  </NotificationListStyled>
);

const NotificationListStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const Separator = styled.div`
  border-bottom: 1px solid var(--color-gray-4);
  margin: 0 -10px 0px -10px;
`;

const NoDataMessage: FC = () => (
  <NoDataMessageStyled>
    <Icon type="notifications" size="large" color="light-gray" />
    <Spacer height={12} />
    <p>
      <b>
        There are currently no
        <br />
        notifications for you
      </b>
    </p>
  </NoDataMessageStyled>
);

const NoDataMessageStyled = styled.div`
  padding: 24px 0;
  margin: 0 -15px;
  background-color: var(--color-gray-6);
  text-align: center;
`;
