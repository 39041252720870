import {FC, useState} from 'react';
import {
  NegotiationRoundResult,
  NegotiationRoundResultSummary,
  NegotiationStatus,
  NegotiationSummary,
} from '../../../api/node-backend/generated';
import {MenuItem} from '../../../atoms/Menu/MenuItem';
import {ActionMenu} from '../../../components/ActionMenu/ActionMenu';
import {NegotiationLastReply} from './NegotiationLastReply';
import {NegotiationStatusBadge, StatusBadge} from './NegotiationStatusBadge';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {negotiationNegotiationApi} from '../../../api/node-backend/nodeBackendApis';
import {FIND_ALL_NEGOTIATIONS_QUERY_KEY} from '../NegotiationOverview/useFindAllNegotiationsQuery';
import {FlexBox} from './shared';
import {
  NegotiationRoundStatsContainer,
  NegotiationRoundSummaryTermStats,
} from './RoundTermStats/NegotiationRoundTermStats';
import {EditPrivateNoteModal} from '../../../components/TextEditModal/EditPrivateNoteModal';
import {FIND_NEGOTIATION_BY_ID_QUERY_KEY} from '../NegotiationDetail/utils/useFindNegotiationByIdQuery';
import {TruncatedTextWithHint} from '../../../atoms/TruncatedTextWithHint/TruncatedTextWithHint';
import {useInvalidateNegotiationQueries} from '../utils/useInvalidateNegotiationQueries';
import {ConfirmCancelNegotiationModal} from '../NegotiationDetail/Components/CommitArea/ConfirmCancelNegotiationModal';
import {useCancelNegotiationMutation} from '../NegotiationDetail/Components/CommitArea/utils/useCancelNegotiationMutation';
import {useCreateNegotiationMutation} from '../NegotiationCreate/utils/useCreateNegotiationMutation';
import {getNegotiationCreateValues} from '../NegotiationDetail/Components/CommitArea/utils/getNegotiationCreateValues';
import {useGoToUrl} from '../../../utils/useChangeBrowserUrl';

type NegotiationSummaryHeaderProps = {
  negotiation: NegotiationSummary;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  currentRoundResult?: NegotiationRoundResult;
  isFirstRecapRound?: boolean;
};

export const NegotiationSummaryHeader: FC<NegotiationSummaryHeaderProps> = ({
  negotiation,
  roundResultSummaries,
  currentRoundResult,
  isFirstRecapRound,
}) => {
  const {id, title, status, counterpart, privateNote, latestRound} = negotiation;
  const {ongoingTerms, acceptedTerms, rejectedTerms, committedAt} = latestRound;

  const isSettled = status === NegotiationStatus.Settled;
  const isCancelled = status === NegotiationStatus.Cancelled;
  const isOwnTurn = !isSettled && !isCancelled && committedAt === null;

  const [showPrivateNoteEditModal, setShowPrivateNoteEditModal] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);

  const goToUrl = useGoToUrl();

  const queryClient = useQueryClient();
  const invalidateNegotiationQueries = useInvalidateNegotiationQueries(id, latestRound.id);
  const cancelNegotiationMutation = useCancelNegotiationMutation({onSuccess: invalidateNegotiationQueries});
  const createNegotiationMutation = useCreateNegotiationMutation({onSuccess: () => invalidateNegotiationQueries()});
  const cancelNegotiation = (cancellationReason?: string, shouldCreateNew?: boolean) => {
    cancelNegotiationMutation.mutate(
      {negotiationId: id, cancellationReason},
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: FIND_ALL_NEGOTIATIONS_QUERY_KEY,
          });
          void queryClient.invalidateQueries({
            queryKey: [FIND_NEGOTIATION_BY_ID_QUERY_KEY, id],
          });

          if (shouldCreateNew && negotiation && currentRoundResult) {
            createNegotiationMutation.mutate(getNegotiationCreateValues(negotiation, currentRoundResult), {
              onSuccess: newNegotiation => goToUrl(`/negotiations/${newNegotiation.id}`),
            });
          }
        },
      }
    );
    setShowConfirmCancelModal(false);
  };

  const updatePrivateNoteMutation = useMutation({
    mutationFn: async (privateNote: string) => {
      const response = await negotiationNegotiationApi.updatePrivateNote({
        negotiationId: id,
        updatePrivateNoteRequest: {
          privateNote,
        },
      });
      return response;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: FIND_ALL_NEGOTIATIONS_QUERY_KEY,
      });
      void queryClient.invalidateQueries({
        queryKey: [FIND_NEGOTIATION_BY_ID_QUERY_KEY, id],
      });
    },
  });

  const openNoteModal = () => {
    setShowPrivateNoteEditModal(true);
  };

  const onSavePrivateNote = (privateNote: string) => {
    updatePrivateNoteMutation.mutate(privateNote);
    setShowPrivateNoteEditModal(false);
  };

  // The first recap round is a special case where the status is already Recap, but the round should be displayed as Ongoing
  const adjustedStatus = isFirstRecapRound && !isOwnTurn ? NegotiationStatus.Ongoing : status;

  return (
    <>
      {showPrivateNoteEditModal && (
        <EditPrivateNoteModal
          title={`Edit private note for "${negotiation.title.trim()}"`}
          currentValue={privateNote}
          onSave={value => onSavePrivateNote(value)}
          onCancel={() => setShowPrivateNoteEditModal(false)}
        />
      )}
      {showConfirmCancelModal && (
        <ConfirmCancelNegotiationModal
          open={showConfirmCancelModal}
          showCreateNewCheckbox={!!currentRoundResult}
          onOk={(reason, shouldCreateNew) => cancelNegotiation(reason, shouldCreateNew)}
          onCancel={() => setShowConfirmCancelModal(false)}
        />
      )}
      <SummaryHeaderContainer data-testid="NegotiationSummaryHeader" data-cy="NegotiationSummaryHeader">
        <FlexLink to={`/negotiations/${id}`}>
          <StatusIndicator>
            <NegotiationStatusBadge status={adjustedStatus} active />
            <NegotiationRoundSummaryTermStats
              ongoingTerms={ongoingTerms}
              acceptedTerms={acceptedTerms}
              rejectedTerms={rejectedTerms}
              showOngoing={!isSettled}
              showRejected={false}
              grouped
            />
            {isOwnTurn && <NotificationDot />}
          </StatusIndicator>
          <NegotiationTitleBox>
            <NegotiationTitle>
              <TruncatedTextWithHint text={title || 'No name specified'} />
            </NegotiationTitle>
            <NegotiationSubtitle>
              {isSettled || isCancelled ? (
                <>Ended</>
              ) : (
                <>Last edited by {committedAt === null ? 'counterpart' : 'you'}</>
              )}
              <NegotiationLastReply
                roundResultSummaries={roundResultSummaries}
                notStartedYetText=""
                prefix=", "
                timeAgoSuffix=" ago"
              />
            </NegotiationSubtitle>
          </NegotiationTitleBox>
        </FlexLink>
        <NegotiationNote $hasNote={!!privateNote} onClick={openNoteModal}>
          {privateNote ?? 'Add private note'}
        </NegotiationNote>
        <ActionMenu
          items={[
            // eslint-disable-next-line no-restricted-globals
            {key: '1', label: <MenuItem label="Send reminder" onClick={() => open(`mailto:${counterpart.email}`)} />},
            {
              key: '2',
              label: <MenuItem label="Cancel negotiation" onClick={() => setShowConfirmCancelModal(true)} />,
            },
          ]}
        />
      </SummaryHeaderContainer>
    </>
  );
};

const SummaryHeaderContainer = styled(FlexBox)`
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  max-width: 100%;
`;

const FlexLink = styled(Link)`
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 1 50%;
`;

const StatusIndicator = styled.span`
  position: relative;
  display: block;
  margin: 0;
  flex-shrink: 0;

  ${StatusBadge} {
    min-width: 95px;
  }
  ${NegotiationRoundStatsContainer} {
    min-width: 95px;
  }
`;

const NotificationDot = styled.span`
  --radius: 6px;

  position: absolute;
  top: calc(1px + var(--radius) * -1);
  right: calc(var(--radius) * -1);
  width: calc(var(--radius) * 2);
  height: calc(var(--radius) * 2);
  border-radius: 50%;
  background-color: var(--color-red);
  box-shadow: var(--box-shadow);
`;

const NegotiationTitleBox = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 0 20px;
  line-height: 1.4;
`;

const NegotiationTitle = styled.span`
  display: grid;
  color: var(--color-black);
  font-size: var(--font-size-lg);
  font-weight: bold;
  cursor: pointer;
`;

const NegotiationSubtitle = styled.span`
  color: var(--color-gray-2);
  cursor: pointer;
`;

const NegotiationNote = styled.div<{$hasNote: boolean}>`
  height: 40px;
  flex: 0 0 calc(50% - 36px);
  margin-right: 15px;
  padding: 5px 10px;
  background: var(--color-gray-6);
  font-size: var(--font-size-sm);
  line-height: 1.12;
  border: var(--border-base);
  border-radius: var(--border-radius-lg);
  overflow: auto;

  ${({$hasNote}) => ($hasNote ? 'color: var(--color-gray-1);' : 'color: var(--color-gray-2);')}
`;
