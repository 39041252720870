import React, {ReactNode, useState} from 'react';
import Checkbox from '../../../atoms/Checkbox';
import EmptyStateArchive from '../../../atoms/EmptyState/Archive/EmptyStateArchive';
import EmptyStateMainPortfolio from '../../../atoms/EmptyState/Portfolio/Cargo/EmptyStateMainPortfolio';
import EmptyStateSubPortfolio from '../../../atoms/EmptyState/Portfolio/Cargo/EmptyStateSubPortfolio';
import Icon from '../../../atoms/Icon';
import {MenuItem} from '../../../atoms/Menu/MenuItem';
import DateTime from '../../../model/DateTime';
import cargoService from '../../../redux/ApiService/cargoService';
import {GridClipboardActions} from '../../../redux/GridClipboard';
import {ModalActions} from '../../../redux/Modal';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {useOpenCargoForm} from '../../../screens/CargoVesselForm/utils/useOpenCargoForm';
import NoResults from '../../../screens/market/EmptyState/NoResults';
import {TODO} from '../../../utils/TODO';
import {ActionMenu} from '../../ActionMenu/ActionMenu';
import LoadingComponent from '../../DataGrid/LoadingComponent';
import {cargoGrid, updatedByColumn} from '../../GridLayout';
import {colorColumn} from '../../GridLayout/columns/shared/colorColumn';
import {useUser} from '../../UserContext/useUser';
import './style.scss';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {DataGrid} from '../../DataGrid/DataGrid';
import {ManageSubPortfolioModal} from '../../Modal/ManageSubPortfolio/ManageSubPortfolioModal';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import ManageFixtureModal from '../../Modal/ManageFixture/ManageFixtureModal';
import {MoveToWorkspace} from '../../Modal/MoveToWorkspace';
import {ClonePortfolioEntry} from '../../Modal/ClonePortfolioEntry';
import PortfolioColorEdit from '../../Modal/PortfolioColorEdit';
import {AddToProject} from '../../Modal/AddToProject/AddToProject';
import {Delete} from '../../Modal/Delete/DeleteModal';
import {Archive} from '../../Modal/Archive';

const noop = () => {};
type OwnCargoGridProps = {
  cargoes?: $TSFixMe[];
  isSub?: boolean;
  isArchive?: boolean;
  loading?: boolean;
  showPageSizeOptions?: boolean;
  pages?: number;
  totalCount?: number;
  pageSize?: number;
  page?: number;
  addAllItemsToClipboard?: $TSFixMeFunction;
  removeAllItemsFromClipboard?: $TSFixMeFunction;
  pageSizeOptions?: $TSFixMe[];
  onPageChange?: $TSFixMeFunction;
  onPageSizeChange?: $TSFixMeFunction;
  reloadGrid?: $TSFixMeFunction;
  goToAll?: $TSFixMeFunction;
  onSortedChange?: $TSFixMeFunction;
  onReloadGrid?: $TSFixMeFunction;
};
// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'CargoGridProps' circularly references ... Remove this comment to see the full error message
type CargoGridProps = OwnCargoGridProps & typeof CargoGrid.defaultProps;
// @ts-expect-error ts-migrate(7022) FIXME: 'CargoGrid' implicitly has type 'any' because it d... Remove this comment to see the full error message
const CargoGrid = ({
  cargoes,
  isSub = false,
  isArchive = false,
  loading,
  onGoToAll,
  onReloadGrid,
  showPageSizeOptions,
  totalCount,
  pageSize,
  page,
  pageSizeOptions,
  isFiltered,
  sorted,
  onPageChange = noop,
  onPageSizeChange = noop,
  onSortedChange = noop,
}: CargoGridProps) => {
  const dispatch = useDispatch();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));
  const deleteCargo = (cargoId: $TSFixMe) => dispatch(cargoService.deleteCargo(cargoId));
  const updateCargo = (cargoId: $TSFixMe, body: $TSFixMe) => dispatch(cargoService.patch(cargoId, body));
  const addAllItemsToClipboard = () =>
    dispatch(GridClipboardActions.addAllGridItemsToClipboard('cargoes', 'portfolio'));
  const removeAllItemsFromClipboard = () =>
    dispatch(GridClipboardActions.removeAllItemsFromClipboard('cargoes', 'portfolio'));
  const addItemToClipboard = (item: $TSFixMe) =>
    dispatch(
      GridClipboardActions.addItem({
        section: 'portfolio',
        subsection: 'cargoes',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );
  const removeItemFromClipboard = (item: $TSFixMe) =>
    dispatch(
      GridClipboardActions.removeItem({
        section: 'portfolio',
        subsection: 'cargoes',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );

  const clipboardState = useSelector(state => state.gridClipboard.portfolio.cargoes);
  const checkAllCargoes = useSelector(state => state.gridClipboard.portfolio.checkAllCargoes);

  const displayEmptyState = () => {
    if (loading || cargoes === null) {
      return <LoadingComponent style={{textAlign: 'center'}} />;
    }
    if (cargoes.length) {
      return;
    }
    if (isArchive && cargoes.length === 0) {
      return <EmptyStateArchive type={'cargo'} location={'portfolio'} />;
    }
    if (isFiltered && cargoes.length === 0) {
      return <NoResults type={'cargoes'} />;
    }
    return isSub ? <EmptyStateSubPortfolio goToAll={onGoToAll} /> : <EmptyStateMainPortfolio />;
  };
  const colorPickerFunction = (d: $TSFixMe) => {
    if (d.color !== null) {
      return d.color;
    }
    const todayString = DateTime.fromLocalDate().moment.format(DateTimeFormat.IsoDate) + 'T00:00:00+00:00';
    if (
      (d.laycanTo === null && d.laycanFrom && todayString.toLocaleString() > d.laycanFrom.toLocaleString()) ||
      (d.laycanTo !== null && todayString.toLocaleString() > d.laycanTo.toLocaleString())
    ) {
      return 5;
    }
  };
  const [manageSubPortfolioModalOpen, setManageSubPortfolioModalOpen] = useState<boolean>(false);
  const [activeCargo, setActiveCargo] = useState<PortfolioCargo | undefined>(undefined);

  const openCargoForm = useOpenCargoForm();
  const user = useUser();

  return (
    <React.Fragment>
      {activeCargo && (
        <ManageSubPortfolioModal
          open={manageSubPortfolioModalOpen}
          type="cargo"
          cargo={activeCargo}
          onCancel={() => {
            setManageSubPortfolioModalOpen(false);
          }}
          onSuccess={() => {
            setManageSubPortfolioModalOpen(false);
          }}
          onClosed={() => {
            setActiveCargo(undefined);
            onReloadGrid();
          }}
        />
      )}

      <DataGrid
        className={'portfolio-grid portfolio-grid--cargo'}
        showPageSizeOptions={showPageSizeOptions}
        showJumper={true}
        totalCount={totalCount}
        pageSize={pageSize}
        page={page}
        pageSizeOptions={pageSizeOptions}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        columns={[
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(d: $TSFixMe) => any' is not ass... Remove this comment to see the full error message
          colorColumn(colorPickerFunction),
          {
            header: () => (
              <Checkbox
                id={`clipboard-checkbox-all`}
                checked={checkAllCargoes && (cargoes?.length ?? 0) > 0}
                onChange={e => {
                  e.target.checked ? addAllItemsToClipboard() : removeAllItemsFromClipboard();
                }}
              />
            ),
            id: 'clipBoard',
            enableSorting: false,
            className: 'datagrid-td-clipboard',
            headerClassName: 'datagrid-th-clipboard',
            cell: d => (
              <Checkbox
                id={`clipboard-checkbox-${d.row.original.id}`}
                checked={!!clipboardState.find((cbi: TODO) => cbi.id === d.row.original.id)}
                onChange={e => {
                  e.target.checked ? addItemToClipboard(d.row.original) : removeItemFromClipboard(d.row.original);
                }}
              />
            ),
            width: 24,
          },
          ...cargoGrid('portfolio'),
          updatedByColumn({minWidth: 5}),
          {
            id: 'cargo.actions',
            header: '',
            enableSorting: false,
            headerClassName: 'datagrid-th-action',
            maxWidth: 48,
            className: 'datagrid-td-action',
            accessorFn: (portfolioCargo: PortfolioCargo) => (
              <ActionMenu
                actionIcon={<Icon type={'more-vert'} />}
                items={[
                  {
                    key: 'edit cargo',
                    label: (
                      <MenuItem label="Edit cargo" onClick={() => openCargoForm({id: portfolioCargo.id})} withDivider />
                    ),
                  },
                  {
                    key: 'archive cargo',
                    label: (
                      <MenuItem
                        label={!isArchive ? 'Archive cargo' : 'Restore cargo'}
                        onClick={() => {
                          showModal(
                            <Archive
                              type="cargo"
                              target={portfolioCargo}
                              reload={onReloadGrid}
                              update={(id: $TSFixMe, body: $TSFixMe, close: $TSFixMe, setError: $TSFixMe) => {
                                updateCargo(id, body).then(
                                  () => {
                                    onReloadGrid(1);
                                    close();
                                  },
                                  () => {
                                    setError(true);
                                  }
                                );
                              }}
                            />
                          );
                        }}
                      />
                    ),
                  },

                  {
                    key: 'Delete cargo',
                    isValid: !isArchive,
                    label: (
                      <MenuItem
                        label="Delete cargo"
                        onClick={() => {
                          const name = portfolioCargo.chartererName ?? portfolioCargo.name;

                          showModal(
                            <Delete
                              target={{
                                name: name!,
                                id: portfolioCargo.id,
                              }}
                              onDelete={async (id: number, callback: (params: TODO) => void, close: () => void) => {
                                try {
                                  await deleteCargo(id);
                                  onReloadGrid(1);
                                  close();
                                  const c = clipboardState.find((cs: $TSFixMe) => cs.id === id);
                                  if (c) {
                                    removeItemFromClipboard(portfolioCargo);
                                  }
                                } catch (e: TODO) {
                                  const errors: $TSFixMe = [];
                                  if (e.payload && e.payload.error && e.payload.error.errors) {
                                    e.payload.error.errors.forEach((err: $TSFixMe) => {
                                      const {type, id, name} = err.data;
                                      const link = type === 'project' ? `/${type}s/${id}` : `/${type}s/${name}`;
                                      errors.push({title: type, msg: name, link: link});
                                    });
                                    const bonusMsg =
                                      'The cargo you want to delete is currently used in projects. Please delete the\nfollowing projects first.';
                                    callback({errors, bonusMsg});
                                  }
                                }
                              }}
                            />
                          );
                        }}
                      />
                    ),
                  },
                  {
                    key: 'Add to My Cargoes',
                    isValid: !isArchive,
                    label: (
                      <MenuItem
                        label="Add to My Cargoes"
                        onClick={() => {
                          showModal(
                            <ClonePortfolioEntry type={'cargo'} cargo={portfolioCargo} reload={onReloadGrid} />
                          );
                        }}
                      />
                    ),
                  },
                  {
                    key: 'Add to project',
                    isValid: !isArchive,
                    label: (
                      <MenuItem
                        label="Add to project"
                        onClick={() => {
                          showModal(<AddToProject type="cargo" id={portfolioCargo.id} />);
                        }}
                        withDivider
                      />
                    ),
                  },
                  {
                    key: 'Move to workspace',
                    isValid: !isArchive && !!(user.payload.company && user.payload.company.id),
                    label: (
                      <MenuItem
                        label="Move to workspace"
                        onClick={() => {
                          showModal(
                            <MoveToWorkspace
                              type="portfolioCargo"
                              data={portfolioCargo}
                              reload={() => onReloadGrid(1)}
                              update={updateCargo}
                            />
                          );
                        }}
                      />
                    ),
                  },
                  {
                    key: 'Create fixture',
                    label: (
                      <MenuItem
                        withDivider
                        label={'Create fixture'}
                        onClick={() => {
                          dispatch(
                            ModalActions.show(
                              <ManageFixtureModal
                                cargo={portfolioCargo}
                                callback={() => {
                                  onReloadGrid();
                                }}
                              />
                            )
                          );
                        }}
                      />
                    ),
                  },
                  {
                    key: 'manage cargo',
                    isValid: !isArchive,
                    label: (
                      <MenuItem
                        label="Manage cargo lists"
                        onClick={() => {
                          setActiveCargo(portfolioCargo);
                          setManageSubPortfolioModalOpen(true);
                        }}
                      />
                    ),
                  },

                  {
                    key: 'cargo',
                    isValid: !isArchive,
                    label: (
                      <MenuItem
                        label="Set colour marker"
                        onClick={() => {
                          showModal(<PortfolioColorEdit type={'cargo'} data={portfolioCargo} reload={onReloadGrid} />);
                        }}
                      />
                    ),
                  },
                ]}
              />
            ),
            cell: info => info.getValue(),
          },
        ]}
        sortable={false}
        data={cargoes || []}
        loading={loading}
        zeroBasedPageIndex={false}
        TbodyComponent={displayEmptyState()}
        onSortedChange={onSortedChange}
        unroundedTopLeft
        noHoverStatePointer
        sorted={sorted}
      />
    </React.Fragment>
  );
};

export default CargoGrid;
