/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ActionValidationViolationList,
  ActionValidationViolationListFromJSON,
  ActionValidationViolationListToJSON,
  CargoOffersForLocationResponse,
  CargoOffersForLocationResponseFromJSON,
  CargoOffersForLocationResponseToJSON,
  Port,
  PortFromJSON,
  PortToJSON,
  PortItemRead,
  PortItemReadFromJSON,
  PortItemReadToJSON,
  PortList,
  PortListFromJSON,
  PortListToJSON,
  PortVersionList,
  PortVersionListFromJSON,
  PortVersionListToJSON,
  VesselOffersForLocationResponse,
  VesselOffersForLocationResponseFromJSON,
  VesselOffersForLocationResponseToJSON,
} from '../models';

export interface CreatePortRequest {
  port: Port;
}

export interface DeletePortRequest {
  id: number;
}

export interface GetCargoOffersForLocationRequest {
  id: string;
  from?: Date;
  to?: Date;
}

export interface GetVesselOffersForLocationRequest {
  id: string;
  from?: Date;
  to?: Date;
}

export interface ListPortVersionsRequest {
  id: number;
}

export interface ListPortsRequest {
  bounds?: Array<Array<number>>;
}

export interface UpdatePortRequest {
  id: number;
  port: Port;
}

/**
 *
 */
export class PortApi extends runtime.BaseAPI {
  /**
   */
  async createPortRaw(requestParameters: CreatePortRequest): Promise<runtime.ApiResponse<PortItemRead>> {
    if (requestParameters.port === null || requestParameters.port === undefined) {
      throw new runtime.RequiredError(
        'port',
        'Required parameter requestParameters.port was null or undefined when calling createPort.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/ports`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PortToJSON(requestParameters.port),
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortItemReadFromJSON(jsonValue));
  }

  /**
   */
  async createPort(requestParameters: CreatePortRequest): Promise<PortItemRead> {
    const response = await this.createPortRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async deletePortRaw(requestParameters: DeletePortRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deletePort.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/ports/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deletePort(requestParameters: DeletePortRequest): Promise<void> {
    await this.deletePortRaw(requestParameters);
  }

  /**
   * Provides cargo statistics in a trading area.
   */
  async getCargoOffersForLocationRaw(
    requestParameters: GetCargoOffersForLocationRequest
  ): Promise<runtime.ApiResponse<CargoOffersForLocationResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getCargoOffersForLocation.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0, 10);
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/ports/statistics/{id}/cargo-offers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CargoOffersForLocationResponseFromJSON(jsonValue));
  }

  /**
   * Provides cargo statistics in a trading area.
   */
  async getCargoOffersForLocation(
    requestParameters: GetCargoOffersForLocationRequest
  ): Promise<CargoOffersForLocationResponse> {
    const response = await this.getCargoOffersForLocationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Provides vessel statistics in a trading area.
   */
  async getVesselOffersForLocationRaw(
    requestParameters: GetVesselOffersForLocationRequest
  ): Promise<runtime.ApiResponse<VesselOffersForLocationResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getVesselOffersForLocation.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0, 10);
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/ports/statistics/{id}/vessel-offers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => VesselOffersForLocationResponseFromJSON(jsonValue));
  }

  /**
   * Provides vessel statistics in a trading area.
   */
  async getVesselOffersForLocation(
    requestParameters: GetVesselOffersForLocationRequest
  ): Promise<VesselOffersForLocationResponse> {
    const response = await this.getVesselOffersForLocationRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listPortVersionsRaw(requestParameters: ListPortVersionsRequest): Promise<runtime.ApiResponse<PortVersionList>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling listPortVersions.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/ports/{id}/versions`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortVersionListFromJSON(jsonValue));
  }

  /**
   */
  async listPortVersions(requestParameters: ListPortVersionsRequest): Promise<PortVersionList> {
    const response = await this.listPortVersionsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listPortsRaw(requestParameters: ListPortsRequest): Promise<runtime.ApiResponse<PortList>> {
    const queryParameters: any = {};

    if (requestParameters.bounds) {
      queryParameters['bounds'] = requestParameters.bounds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/ports`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortListFromJSON(jsonValue));
  }

  /**
   */
  async listPorts(requestParameters: ListPortsRequest): Promise<PortList> {
    const response = await this.listPortsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updatePortRaw(requestParameters: UpdatePortRequest): Promise<runtime.ApiResponse<PortItemRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updatePort.'
      );
    }

    if (requestParameters.port === null || requestParameters.port === undefined) {
      throw new runtime.RequiredError(
        'port',
        'Required parameter requestParameters.port was null or undefined when calling updatePort.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/ports/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: PortToJSON(requestParameters.port),
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortItemReadFromJSON(jsonValue));
  }

  /**
   */
  async updatePort(requestParameters: UpdatePortRequest): Promise<PortItemRead> {
    const response = await this.updatePortRaw(requestParameters);
    return await response.value();
  }
}
