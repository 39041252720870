/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FilterCategory {
  Fixtures = 'fixtures',
  Market = 'market',
  Portfolio = 'portfolio',
  Spotifind = 'spotifind',
  Vesseldatabase = 'vesseldatabase',
}

export function FilterCategoryFromJSON(json: any): FilterCategory {
  return FilterCategoryFromJSONTyped(json, false);
}

export function FilterCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterCategory {
  return json as FilterCategory;
}

export function FilterCategoryToJSON(value?: FilterCategory | null): any {
  return value as any;
}
