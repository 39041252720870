import {memo} from 'react';
import {UserSelectDisabled} from '../../../components/UserSelectDisabled/UserSelectDisabled';

export const generateBlurredMailAddress = () => {
  let address = 'xx';
  let length = address.length + Math.random() * 9;
  while (address.length < length) {
    address += 'x';
  }
  address += '@xx';
  length = address.length + Math.random() * 9;
  while (address.length < length) {
    address += 'x';
  }
  address += '.xxx';
  return address;
};

export const generateBlurredDate = () => {
  let date = Math.floor(Math.random() * 30) + 1 + '';
  date += '/' + (Math.floor(Math.random() * 12) + 1);
  date += '/' + (Math.floor(Math.random() * 2) + 2021);
  return date;
};

export const DummyData = memo(() => {
  return (
    <UserSelectDisabled>
      {generateBlurredMailAddress()} <br /> {generateBlurredDate()}
    </UserSelectDisabled>
  );
});
