import {useQuery} from '@tanstack/react-query';
import {negotiationNegotiationTemplateApi} from '../../../../api/node-backend/nodeBackendApis';

export const GET_NEGOTIATION_TEMPLATE_SUMMARIES_QUERY_KEY = [
  'negotiationNegotiationTemplateApi.getNegotiationTemplateSummaries',
];

export const useGetNegotiationTemplateSummariesQuery = (isGuest?: boolean) =>
  useQuery({
    queryKey: GET_NEGOTIATION_TEMPLATE_SUMMARIES_QUERY_KEY,
    queryFn: async () => {
      const result = await negotiationNegotiationTemplateApi.getNegotiationTemplateSummaries();
      return result;
    },
    enabled: !isGuest,
  });
