import styled, {css} from 'styled-components';

export type TabLinkProps = {
  id?: string;
  active?: boolean;
  subTabLink?: boolean;
  buttonGroupTab?: boolean;
  onClick?: () => void;
  label?: string;
  noMinWidth?: boolean;
};

const DefaultTabLink = ({id, active, onClick, label, subTabLink, buttonGroupTab, noMinWidth}: TabLinkProps) => (
  <StyledDefaultTabLink
    $subTabLink={subTabLink}
    $buttonGroupTab={buttonGroupTab}
    $noMinWidth={noMinWidth}
    $active={active}
    id={`tab-${id}`}
    role="tab"
    aria-controls={id}
    aria-selected={active}
    onClick={onClick}>
    {label}
  </StyledDefaultTabLink>
);

export default DefaultTabLink;

const StyledDefaultTabLink = styled.div<{
  $subTabLink?: boolean;
  $noMinWidth?: boolean;
  $buttonGroupTab?: boolean;
  $active?: boolean;
}>`
  display: inline-block;
  margin: 0 40px;
  padding: 5px 5px;
  color: var(--color-gray-2);
  font-size: var(--font-size-lg);
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    color: var(--color-black);
    text-decoration: none;
  }

  ${({$subTabLink}) =>
    $subTabLink &&
    css`
      background-color: var(--color-gray-4);
      padding: 9px 18px;
      border: none;
      border-top-left-radius: var(--border-radius-tab);
      border-top-right-radius: var(--border-radius-tab);
      box-shadow: var(--box-shadow-tab);
      color: var(--color-gray-1);
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      text-decoration: none;
      font-size: var(--font-size-md);
      line-height: 16px;
      min-width: 110px;
      margin: 0 7px 0 0;
    `}

  ${({$noMinWidth}) =>
    $noMinWidth &&
    css`
      min-width: 0;
    `}
  ${({$buttonGroupTab}) =>
    $buttonGroupTab &&
    css`
      border: var(--border-base);
      margin: 0;
      font-size: var(--font-size-md);
      padding: 8px 34px;
      border-right: none;
      &:first-child {
        border-top-left-radius: var(--border-radius-button);
        border-bottom-left-radius: var(--border-radius-button);
      }
      &:last-child {
        border-top-right-radius: var(--border-radius-button);
        border-bottom-right-radius: var(--border-radius-button);
        border-right: var(--border-base);
      }
    `}
  ${({$active}) =>
    $active &&
    css`
      border-bottom: 3px solid var(--color-azure);
      color: var(--color-azure);
      font-weight: 600;
      cursor: default;
      &:hover {
        color: var(--color-azure);
      }
    `}
`;
