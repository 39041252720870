import {MutationOptions, useMutation} from '@tanstack/react-query';
import {ApiError} from '../../../api/utils/ApiError';
import {useDispatch} from '../../../redux/react-redux';
import {TODO} from '../../../utils/TODO';
import ProjectService from '../../../redux/ApiService/ProjectService';

interface Params {
  id: number;
  types: 'vessels' | 'cargoes';
  body: TODO;
}

export const useAddTypeToProjectMutation = (options: MutationOptions<void, ApiError, Params>) => {
  const dispatch = useDispatch();
  const addTypeToProject = (id: number, types: 'vessels' | 'cargoes', body: number[]) =>
    dispatch(ProjectService.addTypeToProject(id, types, body));
  return useMutation({
    mutationFn: async (params: Params) => await addTypeToProject(params.id, params.types, params.body),
    ...options,
  });
};
