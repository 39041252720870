import Station, {StationObject} from '../../../../model/Station';
import Vessel from '../../../../model/Vessel';
import {numberUnitFormat, numberFormat} from '../../../../utils/formatter';
import {
  getManagementManagingOwner,
  getManagementDisponentOwner,
  getDesignType,
  getManagementHeadOwner,
} from '../../../CargoVesselForm/helper';
import {TabSize} from '../../../../atoms/Attribute/Attribute';
import {
  VesselDetailsVessel,
  VesselTypeWithTanker,
} from '../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {ReactNode} from 'react';
import {NotAvailable} from '../../../../utils/NotAvailable';

export type AttributeMetaData = {
  label: string;
  field?: string | ((vessel: VesselDetailsVessel) => string | ReactNode | null);
  fixedValue?: string | ReactNode | null;
  tab?: TabSize;
  props?: Record<string, unknown>;
};

export type AttributeData = AttributeMetaData & {
  value: string | ReactNode | null;
};

export const leftBoxAttributes = (vessel: VesselDetailsVessel): AttributeMetaData[] => {
  const columns: AttributeMetaData[] = [
    {label: 'Type', field: 'vesselType', props: {upper: true}},
    ...vesselSizeAttributes(vessel),
    ...generalInfoAdditionalColumns(vessel.vesselType),
    {label: 'Built', field: 'builtYear'},
    {label: 'Ship Builder', field: 'shipBuilder'},
    {label: 'Imo', field: 'imo', tab: 'xl'},
  ];
  if (vessel.target !== 'database') {
    const location: ReactNode = Station.getLocationByStationType(vessel.stations as StationObject[], Station.NEXTOPEN, {
      short: true,
    });
    columns.push(
      {
        label: 'Date open',
        fixedValue: Vessel.formatDateOpen(vessel),
      },
      {
        label: 'Location Open',
        fixedValue: location,
      }
    );
  }
  return columns;
};

export const generalInfoAdditionalColumns = (
  vesselType: VesselTypeWithTanker | undefined | null
): AttributeMetaData[] => {
  switch (vesselType) {
    case 'bulker':
      return [{label: 'Sub type', field: 'designSubType', props: {upper: true}}];
    case 'container':
      return [{label: 'Design', field: getDesignType, props: {upper: true}}];
    default:
      return [];
  }
};

export const rightBoxAttributes = (_vessel: VesselDetailsVessel): AttributeMetaData[] => [
  {label: 'Owner', field: getManagementManagingOwner},
  {label: 'Charterer / Disp. Owner', field: getManagementDisponentOwner},
  {label: 'Commercial Manager', field: getManagementHeadOwner, tab: 'xl'},
];

const vesselSizeAttributes = (vessel: VesselDetailsVessel): AttributeMetaData[] => {
  const isContainer = vessel.vesselType === 'container';
  const teu = vessel.intakes.find(d => d.type === 'teu') || null;
  const teu14 = vessel.intakes.find(d => d.type === 'teu14') || null;

  const sizeAttributes: AttributeMetaData[] = [];
  if (!isContainer) {
    sizeAttributes.push({
      label: 'Size',
      fixedValue: numberUnitFormat(vessel.dwtSummer, 'dwt'),
      tab: 'xl',
    });
    return sizeAttributes;
  }
  if (teu) {
    sizeAttributes.push({
      label: 'TEU',
      fixedValue: numberFormat(teu.quantity),
      tab: 'xl',
    });
  }
  if (teu14) {
    sizeAttributes.push({
      label: 'TEU14',
      fixedValue: numberFormat(teu14.quantity),
      tab: 'xl',
    });
  }
  return sizeAttributes;
};

export const insertValuesForAttributes = (
  vessel: VesselDetailsVessel,
  attributeMetaDatas: AttributeMetaData[]
): AttributeData[] => {
  const attributeDatas: AttributeData[] = attributeMetaDatas.map(
    (attributeMetaData: AttributeMetaData): AttributeData => {
      return insertValueForAttribute(vessel, attributeMetaData);
    }
  );
  return attributeDatas;
};

const insertValueForAttribute = (vessel: VesselDetailsVessel, attributeMetaData: AttributeMetaData): AttributeData => {
  const {fixedValue, field, label, tab, props} = attributeMetaData;

  let value: AttributeData['value'] = null;
  if (fixedValue) {
    value = fixedValue;
  } else if (typeof field === 'function') {
    value = field(vessel);
  } else if (field !== undefined && field in vessel) {
    value = vessel[field as keyof VesselDetailsVessel] as typeof value;
  }
  value ??= NotAvailable;

  return {label, value, tab, props};
};
