/*
to add a term, the terms must be included once in the type below and in the array.
Also, it must be deposited under the path:
frontend/src/components/CharterCalculation/Voyagecharter/utils/updatesWaitingtimesOnInputstate/checkIsWorking.ts
 */

export type VoyagePointTerm =
  | 'SSHEX'
  | 'SSINC'
  | 'SATPM SHEX'
  | 'SHEX'
  | 'SHINC'
  | 'SSHINC'
  | 'SSEX'
  | 'FHEX'
  | 'FINC'
  | 'PHEX';
export const voyagePointTerms: VoyagePointTerm[] = [
  'SSHEX',
  'SSINC',
  'SHINC',
  'SATPM SHEX',
  'SHEX',
  'SSHINC',
  'SSEX',
  'FINC',
  'PHEX',
  'FHEX',
];
