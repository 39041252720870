/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanyCreatedByResponse
 */
export interface CompanyCreatedByResponse {
  /**
   * User ID who signed up the Company
   * @type {number}
   * @memberof CompanyCreatedByResponse
   */
  id?: number;
  /**
   * The way how the company signed up.:
   *  * SIGNUP-BY-SELF-REGISTRATION - Company created via registration process
   *  * SIGNUP-BY-SEABO-ADMIN - Company signed up by seabo platform admin
   * @type {string}
   * @memberof CompanyCreatedByResponse
   */
  signupBy: CompanyCreatedByResponseSignupByEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyCreatedByResponseSignupByEnum {
  SelfRegistration = 'SIGNUP-BY-SELF-REGISTRATION',
  SeaboAdmin = 'SIGNUP-BY-SEABO-ADMIN',
}

export function CompanyCreatedByResponseFromJSON(json: any): CompanyCreatedByResponse {
  return CompanyCreatedByResponseFromJSONTyped(json, false);
}

export function CompanyCreatedByResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyCreatedByResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    signupBy: json['signupBy'],
  };
}

export function CompanyCreatedByResponseToJSON(value?: CompanyCreatedByResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    signupBy: value.signupBy,
  };
}
