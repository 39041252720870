import React, {useEffect, useState} from 'react';
import withErrorHandler from '../widgets/components/withErrorHandler';
import {NotFoundWidget} from '../widgets/components/ErrorWidget';
import {TODO} from '../../../utils/TODO';

interface Props {
  widgetIdentifier: string;
  [key: string]: TODO;
}

const WidgetLoader = ({widgetIdentifier, ...widgetProps}: Props) => {
  const [{WidgetComponent}, setWidgetComponent] = useState<{WidgetComponent: null | TODO}>({WidgetComponent: null});
  useEffect(() => {
    let didCancel = false;

    const loadWidgetComponent = async () => {
      const widgetFilename = `${widgetIdentifier.charAt(0).toUpperCase()}${widgetIdentifier.slice(1)}`;
      try {
        const WidgetComponent = withErrorHandler((await import(`../widgets/${widgetFilename}.tsx`)).default);
        if (!didCancel) {
          setWidgetComponent({WidgetComponent});
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        // eslint-disable-next-line no-console
        console.warn(`Missing widget: ${widgetFilename}`);
        if (!didCancel) {
          setWidgetComponent({WidgetComponent: NotFoundWidget});
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadWidgetComponent();
    return () => {
      didCancel = true;
    };
  }, [widgetIdentifier]);

  if (!WidgetComponent) {
    return null;
  }

  return <WidgetComponent identifier={widgetIdentifier} {...widgetProps} />;
};

export default WidgetLoader;
