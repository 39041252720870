import {StepResult} from './StepResult';
import {FilterParams} from '../filterParams';
import {Step5Result, Step5Vessel} from './step5';
import {airlineDistanceFilter} from '../filters/airlineDistanceFilter';

export type Step6Result = StepResult<Step5Vessel>;

/**
 * filter vessels - only vessels that are close enough as the crow flies
 *
 * vessels that are close enough according to Euclidean distance,
 *
 * for vessels with destination port 2 distances are added, position of vessel => destination port ;
 * destination port => Laycan port

 */
export const step6 = ({params, preResult}: {preResult: Step5Result; params: FilterParams}): Step6Result => {
  const vesselList: Step5Vessel[] = airlineDistanceFilter<Step5Vessel>({vessels: preResult.vessels, filter: params});

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: 'Within maximum beeline distance',
        count: vesselList.length,
        id: 'beelineDistance',
      },
    ],
  };
};
