import React, {FC, useState} from 'react';
import {ProfileInput} from '../../../../components/ProfileInput';
import {CompanyDetailedInformation} from '../../../../components/Company/components/DetailedInformation/CompanyDetailedInformation';
import {showNotification} from '../../../../utils/notification';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {TextArea} from '../../../../atoms/TextArea/TextArea';
import {useUpdateCompanyMutation} from './useUpdateCompanyMutation';
import {CompanyLogo} from './CompanyLogo';
import {SettingsCard} from '../../../../components/SettingsCard/SettingsCard';
import {SettingsCardHeader} from '../../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCardBody} from '../../../../components/SettingsCard/SettingsCardBody';
import {SettingsButtons} from '../../../../components/SettingsCard/SettingsButtons';
import {
  SettingsSection,
  SettingsSectionBody,
  SettingsSectionTitle,
} from '../../../../components/SettingsCard/SettingsSection';
import {Button} from 'antd';

interface Props {
  company: Company;
}

export const CompanySettingsGeneral: FC<Props> = ({company: originalCompany}) => {
  const [company, setCompany] = useState<Company>(originalCompany);

  const {name, business, foundYear, companyType, description, employees, vesselsManaged, companyLogo, taxId} = company;
  const [hasLogo, setHasLogo] = useState<boolean>(!!companyLogo);
  const [logoFile, setLogoFile] = useState<File>();
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const onSelect = (changeState: string, changedValue: HTMLInputElement) => {
    setCompany((prevCompany: Company) => ({...prevCompany, [changeState]: changedValue.value}));
  };

  const onChange = (changeState: string, changedValue: HTMLImageElement | string) => {
    setCompany((prevCompany: Company) => ({...prevCompany, [changeState]: changedValue}));
  };

  const updateCompanyMutation = useUpdateCompanyMutation({
    onSuccess: async () => {
      setDisableButton(false);
      await showNotification('Company has been saved');
    },
    onError: () => {
      setDisableButton(false);
    },
  });

  return (
    <SettingsCard>
      <SettingsCardHeader title={'General'} />

      <SettingsCardBody>
        <div data-testid="CompanySettingsGeneral">
          <SettingsSection>
            <SettingsSectionTitle>Main information</SettingsSectionTitle>
            <SettingsSectionBody>
              <ProfileInput label={'Company name'} selectedName={'name'} selectedValue={name} readOnly />
              <ProfileInput
                label={'Business description'}
                selectedName={'business'}
                selectedValue={business}
                onChange={onChange}
              />
            </SettingsSectionBody>
          </SettingsSection>

          <SettingsSection>
            <SettingsSectionTitle>Company logo</SettingsSectionTitle>
            <SettingsSectionBody>
              <div style={{height: 5}} />
              <CompanyLogo
                company={company}
                hasLogo={hasLogo}
                onLogoFileChange={setLogoFile}
                onHasLogoChange={setHasLogo}
              />
            </SettingsSectionBody>
          </SettingsSection>

          <SettingsSection>
            <SettingsSectionTitle>Detailed information</SettingsSectionTitle>
            <SettingsSectionBody>
              <CompanyDetailedInformation
                onChange={onChange}
                onSelect={onSelect}
                foundYear={foundYear}
                taxId={taxId}
                companyType={companyType}
                employees={employees}
                vesselsManaged={vesselsManaged}
              />
            </SettingsSectionBody>
          </SettingsSection>

          <SettingsSection>
            <SettingsSectionTitle>Description</SettingsSectionTitle>
            <SettingsSectionBody>
              <div style={{height: 4}} />
              <TextArea
                className={'company-settings-general-textarea'}
                value={description !== null ? description : ''}
                onChange={event => onChange('description', event.target.value)}
                rows={8}
                style={{marginBottom: 0}}
              />
            </SettingsSectionBody>
          </SettingsSection>
        </div>

        <SettingsButtons>
          <Button
            disabled={disableButton}
            type="primary"
            onClick={() => {
              setDisableButton(true);
              updateCompanyMutation.mutate({
                company,
                logoFile,
                hasLogo,
              });
            }}>
            SAVE
          </Button>
        </SettingsButtons>
      </SettingsCardBody>
    </SettingsCard>
  );
};
