import {makeApiService, ApiServiceEntry} from './ApiService';
import {TODO} from '../../utils/TODO';

type Id = TODO;

const entries: ApiServiceEntry[] = [
  {
    fn: 'createFixture',
    route: '/api/vessel-fixtures',
    method: 'POST',
  },
  {
    fn: 'patchFixture',
    route: '/api/workspaces/{workspaceId}/vessel-fixtures/{id}',
    method: 'PATCH',
  },
  {
    fn: 'getFilterData',
    route: '/api/fixtures/filters/vessel',
    storeData: false,
    method: 'GET',
  },
  {
    fn: 'updateFilterData',
    route: '/api/fixtures/filters/vessel/{id}',
    method: 'PUT',
    storeData: false,
  },
  {
    fn: 'deleteFixture',
    route: '/api/vessel-fixtures/{id}',
    method: 'DELETE',
  },
  {
    fn: 'fixtureHelper',
    route: '/api/workspaces/{workspaceId}/vessel-fixtures/helper',
    method: 'POST',
  },
];

const fixturesVessel = makeApiService(entries, {apiName: 'fixturesVessel'});

export const reducers = fixturesVessel.reducers;
export const actions = fixturesVessel.actions;
export const actionTypes = fixturesVessel.actionTypes;

export const FixtureServiceActions = {
  deleteFixture: (id: Id) => actions.deleteFixture({params: {id}}),
  getFilterData: actions.getFilterData(),
  updateFilterData: (id: Id, filters: TODO) =>
    actions.updateFilterData({
      params: {id},
      queryParams: {filters: JSON.stringify(filters)},
      body: {id},
    }),
};

export default FixtureServiceActions;
