import {notificationApi} from '../../../../api/symfony/symfonyApi';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {invalidateNotificationsQuery} from '../../../../queries/useNotificationsQuery';
import {ApiError} from '../../../../api/utils/ApiError';

export const useAcknowledgeNotificationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<void, ApiError, number>({
    mutationFn: (notificationId: number): Promise<void> =>
      notificationApi.acknowledgeNotification({id: notificationId}),

    onSuccess: async () => {
      // Reload notifications
      await invalidateNotificationsQuery(queryClient);
    },
  });
};

export const useAcknowledgeAllNotificationsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<void, ApiError>({
    mutationFn: (): Promise<void> => notificationApi.acknowledgeAllNotifications(),

    onSuccess: async () => {
      // Reload notifications
      await invalidateNotificationsQuery(queryClient);
    },
  });
};
