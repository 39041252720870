import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {UserWidgetWrite} from '../../../api/symfony/generated';
import {widgetApi} from '../../../api/symfony/symfonyApi';
import {ApiError} from '../../../api/utils/ApiError';
import {queryClient} from '../../../api/utils/reactQueryClient';
import {invalidateWidgetQuery} from './useWidgetQuery';

export interface UpdateWidgetMutationParams {
  id: number;
  userWidgetWrite: UserWidgetWrite;
}

export const useUpdateWidgetMutation = (
  options: UseMutationOptions<void, ApiError, UpdateWidgetMutationParams> = {
    onSuccess: async () => {
      await invalidateWidgetQuery(queryClient);
    },
  }
) => {
  return useMutation({
    mutationFn: async ({id, userWidgetWrite}: UpdateWidgetMutationParams) => {
      await widgetApi.updateWidget({id, userWidgetWrite});
    },
    ...options,
  });
};

export interface UpdateWidgetsMutationParams {
  widgets: UpdateWidgetMutationParams[];
}

export const useUpdateWidgetsMutation = (options?: UseMutationOptions<void, ApiError, UpdateWidgetsMutationParams>) => {
  return useMutation({
    mutationFn: async (params: UpdateWidgetsMutationParams) => {
      const promises = params.widgets.map(widget => widgetApi.updateWidget(widget));
      await Promise.all(promises);
    },
    ...options,
  });
};
