/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AreaType,
  AreaTypeFromJSON,
  AreaTypeToJSON,
  HistoricCongestionLightReport,
  HistoricCongestionLightReportFromJSON,
  HistoricCongestionLightReportToJSON,
} from '../models';

export interface GetHistoricCongestionLightReportRequest {
  areaType: AreaType;
  areaId: number;
}

/**
 *
 */
export class HistoricCongestionLightReportApi extends runtime.BaseAPI {
  /**
   */
  async getHistoricCongestionLightReportRaw(
    requestParameters: GetHistoricCongestionLightReportRequest
  ): Promise<runtime.ApiResponse<HistoricCongestionLightReport>> {
    if (requestParameters.areaType === null || requestParameters.areaType === undefined) {
      throw new runtime.RequiredError(
        'areaType',
        'Required parameter requestParameters.areaType was null or undefined when calling getHistoricCongestionLightReport.'
      );
    }

    if (requestParameters.areaId === null || requestParameters.areaId === undefined) {
      throw new runtime.RequiredError(
        'areaId',
        'Required parameter requestParameters.areaId was null or undefined when calling getHistoricCongestionLightReport.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/congestion-light/{areaType}/{areaId}/historic-congestion-light-report`
        .replace(`{${'areaType'}}`, encodeURIComponent(String(requestParameters.areaType)))
        .replace(`{${'areaId'}}`, encodeURIComponent(String(requestParameters.areaId))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => HistoricCongestionLightReportFromJSON(jsonValue));
  }

  /**
   */
  async getHistoricCongestionLightReport(
    requestParameters: GetHistoricCongestionLightReportRequest
  ): Promise<HistoricCongestionLightReport> {
    const response = await this.getHistoricCongestionLightReportRaw(requestParameters);
    return await response.value();
  }
}
