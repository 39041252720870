import createDebug from 'debug';

/**
 * Debug log for ServerPush.
 *
 * To enable log output, run this in your JS console:
 *
 * ```
 *   localStorage.debug = "ServerPush";
 * ```
 *
 * @see https://github.com/debug-js/debug
 */
export const debugLog = createDebug('ServerPush');
// eslint-disable-next-line no-console
debugLog.log = console.log.bind(console);
