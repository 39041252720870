import {VesselTags} from './VesselTags';
import {CustomColumnDef} from '../../../../DataGrid/DataGrid';
import {SeaboVessel} from '../../../../../api/node-backend/generated';

export const tagsColumn = ({
  minWidth = 10,
  options,
}: {
  minWidth?: number;
  options?: () => void;
}): CustomColumnDef<SeaboVessel> => ({
  header: 'Tags',
  id: 'tag',
  accessorFn: d => <VesselTags vessel={d} onChange={options!} />,
  cell: info => info.getValue(),
  minWidth,
  enableSorting: false,
});
