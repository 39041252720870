/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CharterCalculation,
  CharterCalculationFromJSON,
  CharterCalculationFromJSONTyped,
  CharterCalculationToJSON,
} from './';

/**
 *
 * @export
 * @interface CharterCalculationCollection
 */
export interface CharterCalculationCollection {
  /**
   *
   * @type {number}
   * @memberof CharterCalculationCollection
   */
  totalItems: number;
  /**
   *
   * @type {Array<CharterCalculation>}
   * @memberof CharterCalculationCollection
   */
  items: Array<CharterCalculation>;
}

export function CharterCalculationCollectionFromJSON(json: any): CharterCalculationCollection {
  return CharterCalculationCollectionFromJSONTyped(json, false);
}

export function CharterCalculationCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CharterCalculationCollection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalItems: json['totalItems'],
    items: (json['items'] as Array<any>).map(CharterCalculationFromJSON),
  };
}

export function CharterCalculationCollectionToJSON(value?: CharterCalculationCollection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalItems: value.totalItems,
    items: (value.items as Array<any>).map(CharterCalculationToJSON),
  };
}
