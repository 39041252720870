import {apiErrorMiddleware} from '../utils/apiErrorMiddleware';
import {apiTransactionIdMiddleware} from '../utils/apiTransactionIdMiddleware';
import {
  ChokepointCongestionApi,
  Configuration,
  FastVesselImportApi,
  PortCongestionApi,
  SimilarVesselsApi,
  TiedUpVesselsStatisticApi,
  VesselRouteApi,
  VesselStopsApi,
  ReportProblemApi,
  AreaVisitsApi,
  PredictedConsumptionApi,
  VesselDBVesselsApi,
  VesselDBOwnersReportApi,
  VesselDBContainerVesselDesignsApi,
  VesselDBLoaBeamReportApi,
  VesselDBBuiltYearDwtReportApi,
  VesselDBBuiltYearTEUReportApi,
  VesselDBMostVisitedContainerPortReportApi,
  VesselDBMostVisitedBulkerPortReportApi,
  NegotiationNegotiationApi,
  NegotiationNegotiationActorApi,
  NegotiationNegotiationTermApi,
  NegotiationNegotiationTermHistoryApi,
  NegotiationNegotiationRoundResultApi,
  VesselDBMostVisitedAreaReportApi,
  HistoricCongestionLightReportApi,
  CongestionLightReportApi,
  SubscriptionApi,
  VesselVoyageTimelineApi,
  NegotiationNegotiationSubjectApi,
  AISRecordApi,
  PortDisbursementAccountApi,
  CompanyApi,
  NegotiationNegotiationAttachmentApi,
  NegotiationNegotiationTemplateApi,
  VesselVoyageTimelineExcelReportsApi,
  HelloApi,
  ConsentContractsApi,
  ConsentContractVersionsApi,
  ConsentContractVersionsConsentsApi,
} from './generated';
import {apiAccessTokenMiddleware} from '../utils/apiAccessTokenMiddleware';
import {NodeBackendBaseUrl} from '../utils/NodeBackendBaseUrl';

const configuration = new Configuration({
  basePath: NodeBackendBaseUrl,
  middleware: [apiTransactionIdMiddleware, apiErrorMiddleware, apiAccessTokenMiddleware],
});

export const similarVesselsApi = new SimilarVesselsApi(configuration);
export const fastVesselImportApi = new FastVesselImportApi(configuration);
export const subscriptionApi = new SubscriptionApi(configuration);
export const vesselDBMostVisitedAreaReportApi = new VesselDBMostVisitedAreaReportApi(configuration);
export const vesselRouteApi = new VesselRouteApi(configuration);
export const chokepointCongestionApi = new ChokepointCongestionApi(configuration);
export const tiedUpVesselsStatisticApi = new TiedUpVesselsStatisticApi(configuration);
export const portCongestionApi = new PortCongestionApi(configuration);
export const vesselStopsApi = new VesselStopsApi(configuration);
export const reportProblemApi = new ReportProblemApi(configuration);
export const vesselDBVesselsApi = new VesselDBVesselsApi(configuration);
export const vesselDBOwnersReportApi = new VesselDBOwnersReportApi(configuration);
export const vesselDBLoaBeamReportApi = new VesselDBLoaBeamReportApi(configuration);
export const vesselDBBuiltYearDwtReportApi = new VesselDBBuiltYearDwtReportApi(configuration);
export const vesselDBBuiltYearTEUReportApi = new VesselDBBuiltYearTEUReportApi(configuration);
export const vesselDBContainerVesselDesignsApi = new VesselDBContainerVesselDesignsApi(configuration);
export const vesselDBMostVisitedContainerPortReportApi = new VesselDBMostVisitedContainerPortReportApi(configuration);
export const vesselDBMostVisitedBulkerPortReportApi = new VesselDBMostVisitedBulkerPortReportApi(configuration);
export const vesselVoyageTimelineApi = new VesselVoyageTimelineApi(configuration);
export const vesselVoyageTimelineExcelReportsApi = new VesselVoyageTimelineExcelReportsApi(configuration);
export const areaVisitsApi = new AreaVisitsApi(configuration);
export const aisRecordApi = new AISRecordApi(configuration);

export const companyApi = new CompanyApi(configuration);

export const congestionLightReportApi = new CongestionLightReportApi(configuration);
export const historicCongestionLightReportApi = new HistoricCongestionLightReportApi(configuration);
export const predictedConsumptionApi = new PredictedConsumptionApi(configuration);

export const negotiationNegotiationApi = new NegotiationNegotiationApi(configuration);
export const negotiationNegotiationActorApi = new NegotiationNegotiationActorApi(configuration);
export const negotiationNegotiationTermApi = new NegotiationNegotiationTermApi(configuration);
export const negotiationNegotiationTermHistoryApi = new NegotiationNegotiationTermHistoryApi(configuration);
export const negotiationNegotiationRoundResultApi = new NegotiationNegotiationRoundResultApi(configuration);
export const negotiationNegotiationSubjectApi = new NegotiationNegotiationSubjectApi(configuration);
export const negotiationAttachmentApi = new NegotiationNegotiationAttachmentApi(configuration);

export const negotiationNegotiationTemplateApi = new NegotiationNegotiationTemplateApi(configuration);

export const portDisbursementAccountApi = new PortDisbursementAccountApi(configuration);

export const helloApi = new HelloApi(configuration);

export const consentContractsApi = new ConsentContractsApi(configuration);
export const consentContractVersionsApi = new ConsentContractVersionsApi(configuration);
export const consentContractVersionsConsentsApi = new ConsentContractVersionsConsentsApi(configuration);
