import {useMemo, FC} from 'react';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import sumBy from 'lodash/sumBy';
import {NumberStatistics} from './NumberStatistics';
import {getTeuQuantity} from '../../utils/getTeuQuantity';

export const TiedUpTeu: FC<{
  currentContainerVisits: MergedPortCongestionVisit[];
}> = ({currentContainerVisits}) => {
  const boundedTeu = useMemo(() => {
    return sumBy(currentContainerVisits, visit => getTeuQuantity(visit.intakes) ?? 0);
  }, [currentContainerVisits]);

  return <NumberStatistics title={'Tied up TEU'} suffix={'TEU'} value={boundedTeu} />;
};
