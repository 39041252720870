/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  UserWidget,
  UserWidgetFromJSON,
  UserWidgetToJSON,
  UserWidgetWrite,
  UserWidgetWriteFromJSON,
  UserWidgetWriteToJSON,
  WidgetDispatchRequest,
  WidgetDispatchRequestFromJSON,
  WidgetDispatchRequestToJSON,
  WidgetListResponse,
  WidgetListResponseFromJSON,
  WidgetListResponseToJSON,
} from '../models';

export interface CreateWidgetRequest {
  userWidgetWrite: UserWidgetWrite;
}

export interface DeleteWidgetRequest {
  id: number;
}

export interface DispatchWidgetRequest {
  id: number;
  widgetDispatchRequest: WidgetDispatchRequest;
}

export interface UpdateWidgetRequest {
  id: number;
  userWidgetWrite: UserWidgetWrite;
}

/**
 *
 */
export class WidgetApi extends runtime.BaseAPI {
  /**
   */
  async createWidgetRaw(requestParameters: CreateWidgetRequest): Promise<runtime.ApiResponse<UserWidget>> {
    if (requestParameters.userWidgetWrite === null || requestParameters.userWidgetWrite === undefined) {
      throw new runtime.RequiredError(
        'userWidgetWrite',
        'Required parameter requestParameters.userWidgetWrite was null or undefined when calling createWidget.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/dashboard/widget`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: UserWidgetWriteToJSON(requestParameters.userWidgetWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => UserWidgetFromJSON(jsonValue));
  }

  /**
   */
  async createWidget(requestParameters: CreateWidgetRequest): Promise<UserWidget> {
    const response = await this.createWidgetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async deleteWidgetRaw(requestParameters: DeleteWidgetRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteWidget.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/dashboard/widget/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteWidget(requestParameters: DeleteWidgetRequest): Promise<void> {
    await this.deleteWidgetRaw(requestParameters);
  }

  /**
   */
  async dispatchWidgetRaw(requestParameters: DispatchWidgetRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling dispatchWidget.'
      );
    }

    if (requestParameters.widgetDispatchRequest === null || requestParameters.widgetDispatchRequest === undefined) {
      throw new runtime.RequiredError(
        'widgetDispatchRequest',
        'Required parameter requestParameters.widgetDispatchRequest was null or undefined when calling dispatchWidget.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/dashboard/widget/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: WidgetDispatchRequestToJSON(requestParameters.widgetDispatchRequest),
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async dispatchWidget(requestParameters: DispatchWidgetRequest): Promise<object> {
    const response = await this.dispatchWidgetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getWidgetsRaw(): Promise<runtime.ApiResponse<WidgetListResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/dashboard/widget`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => WidgetListResponseFromJSON(jsonValue));
  }

  /**
   */
  async getWidgets(): Promise<WidgetListResponse> {
    const response = await this.getWidgetsRaw();
    return await response.value();
  }

  /**
   */
  async resetWidgetsRaw(): Promise<runtime.ApiResponse<WidgetListResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/dashboard/widget/reset-widgets`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => WidgetListResponseFromJSON(jsonValue));
  }

  /**
   */
  async resetWidgets(): Promise<WidgetListResponse> {
    const response = await this.resetWidgetsRaw();
    return await response.value();
  }

  /**
   */
  async updateWidgetRaw(requestParameters: UpdateWidgetRequest): Promise<runtime.ApiResponse<UserWidget>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateWidget.'
      );
    }

    if (requestParameters.userWidgetWrite === null || requestParameters.userWidgetWrite === undefined) {
      throw new runtime.RequiredError(
        'userWidgetWrite',
        'Required parameter requestParameters.userWidgetWrite was null or undefined when calling updateWidget.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/dashboard/widget/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UserWidgetWriteToJSON(requestParameters.userWidgetWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => UserWidgetFromJSON(jsonValue));
  }

  /**
   */
  async updateWidget(requestParameters: UpdateWidgetRequest): Promise<UserWidget> {
    const response = await this.updateWidgetRaw(requestParameters);
    return await response.value();
  }
}
