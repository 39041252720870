import dayjs, {Dayjs} from 'dayjs';
import {
  TimelineEvent,
  TimelineItem,
  TimelineItemPort,
  TimelineItemType,
  TimelineItemVoyage,
  getIsEventFrame,
  getIsEventWithDraft,
} from './types';
import {
  PortVisit,
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineResponse,
  VoyageTimelineRoute,
} from '../../../../api/node-backend/generated';
import {VoyageTimelineEventKey, VoyageTimelineFilters, defaultVoyageTimelineFilters} from './filters';
import {getEventStart, getIsBallastFromDraft} from './utils';
import sortBy from 'lodash/sortBy';

/**
 * Groups events into PortVisits and Routes chronological (descending) and adds a virtual Route in case the latest event is an ended PortVisit
 */
export const calculateVoyageTimeline = (
  timelineEvents?: VesselVoyageTimelineResponse,
  filters: VoyageTimelineFilters = defaultVoyageTimelineFilters
): {
  voyageTimelineItems: TimelineItem[];
  eventsAvailable: VoyageTimelineFilters;
  maxDuration: number;
  minDraft: number;
  maxDraft: number;
} => {
  if (!timelineEvents) {
    return {
      voyageTimelineItems: [],
      eventsAvailable: defaultVoyageTimelineFilters,
      maxDuration: 0,
      minDraft: 0,
      maxDraft: 0,
    };
  }

  // Merge all events into one array, so they can be sorted by timestamp and filtered
  const eventsMerged = mergeEvents(timelineEvents);

  // Make some aggregations for the UI
  const minDraft = getMinDraftFromEvents(eventsMerged);
  const maxDraft = getMaxDraftFromEvents(eventsMerged);
  const maxDuration = getMaxDurationFromEvents(eventsMerged);

  // For the UI, we only need to show the events that are currently visible and which are available in the response
  const filteredEvents = eventsMerged.filter(event => filters[event.eventType]);
  const eventsAvailable = getEventsAvailable(eventsMerged);

  // Group events into PortVisits and Routes (adding a virtual Route in case the latest event is an ended PortVisit)
  const {portVisits, routes} = timelineEvents;
  const eventsGrouped = groupTimelineEvents(portVisits, routes, filteredEvents, maxDraft);

  // Sort by index, first port, then voyage
  const eventsSorted = sortBy(eventsGrouped, ['index', 'type']);

  return {
    voyageTimelineItems: eventsSorted,
    eventsAvailable,
    maxDuration,
    minDraft,
    maxDraft,
  };
};

export const mergeEvents = (voyageTimelineRaw: Partial<VesselVoyageTimelineResponse>): TimelineEvent[] => {
  let eventsMerged: TimelineEvent[] = [];

  for (const eventKey of Object.keys(voyageTimelineRaw)) {
    const eventsOfType = voyageTimelineRaw[eventKey as VoyageTimelineEventKey];
    eventsMerged = [...eventsMerged, ...eventsOfType!];
  }

  return eventsMerged;
};

/**
 * This function groups the events into TimelineItems and adds according indices to the items.
 */
export const groupTimelineEvents = (
  portVisits: PortVisit[],
  routes: VoyageTimelineRoute[],
  otherEvents: TimelineEvent[],
  maxDraft: number
): TimelineItem[] => {
  // The VesselVoyageTimeline API works by calculating PortVisits and then adding Routes between the PortVisits.
  // The API response therefore always ends with a PortVisit, it can be either ended or ongoing (exitTimestamp = null).
  // If the latest event is an ended PortVisit, a virtual current Route is added at the start.

  const timelineItems: TimelineItem[] = [];

  // count down index from last port to achieve descending order
  let index = portVisits.length;
  for (const portVisit of portVisits) {
    const enterTimestamp = portVisit.enterTimestamp;
    const exitTimestamp = portVisit.exitTimestamp ?? new Date();
    const portItem: TimelineItemPort = {
      index,
      type: TimelineItemType.Port,
      portVisit,
      enterTimestamp,
      exitTimestamp,
      subEvents: getEventsInTimeframe(otherEvents, enterTimestamp, exitTimestamp),
      isBallast: getIsBallastFromDraft(portVisit.exitDraft ?? 0, maxDraft),
    };

    timelineItems.push(portItem);
    index--;
  }

  // reset index to one before last port, so that the first route gets index 0
  index = portVisits.length - 1;
  for (const route of routes) {
    const enterTimestamp = route.enterTimestamp;
    const exitTimestamp = route.exitTimestamp ?? new Date();
    const voyageItem: TimelineItemVoyage = {
      index,
      type: TimelineItemType.Voyage,
      route,
      enterTimestamp,
      exitTimestamp,
      subEvents: getEventsInTimeframe(otherEvents, enterTimestamp, exitTimestamp),
    };

    for (const portVisit of portVisits) {
      if (portVisit.exitTimestamp && dayjs(enterTimestamp).isSame(portVisit.exitTimestamp)) {
        voyageItem.departurePortVisit = portVisit;
      }
      if (portVisit.enterTimestamp && dayjs(exitTimestamp).isSame(portVisit.enterTimestamp)) {
        voyageItem.arrivalPortVisit = portVisit;
        voyageItem.isBallast = getIsBallastFromDraft(portVisit.enterDraft ?? 0, maxDraft);
      }
    }

    timelineItems.push(voyageItem);
    index--;
  }

  // add voyage to index portVists.length if there are events before first port was entered
  const firstPortVisit = portVisits[0];
  const firstPortEnterTimestamp = firstPortVisit?.enterTimestamp ?? new Date();
  const eventsBeforeFirstPort = getEventsInTimeframe(otherEvents, new Date(0), firstPortEnterTimestamp);
  if (eventsBeforeFirstPort.length > 0) {
    const enterTimestamp = getIsEventFrame(eventsBeforeFirstPort[0])
      ? eventsBeforeFirstPort[0].enterTimestamp
      : eventsBeforeFirstPort[0].timestamp;
    const exitTimestamp = firstPortEnterTimestamp;
    const voyageItem: TimelineItemVoyage = {
      index: portVisits.length + 1,
      type: TimelineItemType.Voyage,
      route: {
        isOngoing: true,
        waypoints: [],
        eventType: VesselVoyageTimelineEventType.Route,
        enterTimestamp,
        startPort: firstPortVisit?.port,
        endPort: firstPortVisit?.port,
        avgDraft: null,
        exitTimestamp: new Date(),
        avgSpeed: 0,
        distance: 0,
        startPortVisitId: 0,
        endPortVisitId: null,
      },
      enterTimestamp,
      exitTimestamp,
      subEvents: eventsBeforeFirstPort,
      arrivalPortVisit: firstPortVisit,
      isBallast: getIsBallastFromDraft(firstPortVisit?.enterDraft ?? 0, maxDraft),
    };
    timelineItems.push(voyageItem);
  }

  return timelineItems;
};

const getEventsAvailable = (events: TimelineEvent[]): VoyageTimelineFilters => {
  const eventsAvailable = {...defaultVoyageTimelineFilters};

  for (const eventType of Object.keys(eventsAvailable)) {
    eventsAvailable[eventType as VesselVoyageTimelineEventType] = events.some(event => event.eventType === eventType);
  }

  return eventsAvailable;
};

const getMinDraftFromEvents = (events: TimelineEvent[]): number => {
  return Math.min(
    ...events.map(event => {
      if (getIsEventWithDraft(event)) {
        return Math.min(event.enterDraft ?? 0, event.exitDraft ?? 0);
      }
      return 0;
    })
  );
};

export const getMaxDraftFromEvents = (events: TimelineEvent[]): number => {
  return Math.max(
    ...events.map(event => {
      if (getIsEventWithDraft(event)) {
        return Math.max(event.enterDraft, event.exitDraft ?? -Infinity);
      }
      return -Infinity;
    })
  );
};

const getMaxDurationFromEvents = (events: TimelineEvent[]): number => {
  return Math.max(
    ...events.map(event => {
      if (!getIsEventFrame(event) || !event.exitTimestamp || !event.enterTimestamp) {
        return 0;
      }
      return dayjs(event.exitTimestamp).diff(event.enterTimestamp);
    })
  );
};

const getEventsInTimeframe = (events: TimelineEvent[], start: Date, end: Date): TimelineEvent[] => {
  const eventsInTimeframe = events
    .filter(Boolean)
    .filter(event => !isPortOrRouteEvent(event) && isEventInTimeframe(event, start, end));
  return sortBy(eventsInTimeframe, sortByDate<TimelineEvent>(getEventStart, false));
};

export const sortByDate = <T>(dateAccessor: (event: T) => string | Date | Dayjs, ascending = true) => {
  return (event: T) => dayjs(dateAccessor(event)).valueOf() * (ascending ? 1 : -1);
};

const isPortOrRouteEvent = (event: TimelineEvent): boolean => {
  return [VesselVoyageTimelineEventType.PortVisit, VesselVoyageTimelineEventType.Route].includes(event.eventType);
};

const isEventInTimeframe = (event: TimelineEvent, start: Date, end: Date): boolean => {
  return dayjs(getEventStart(event)).isBetween(start, end, null, '[]');
};
