import {Button} from 'antd';
import React, {FC} from 'react';
import Image404 from './404-image.svg';
import styled from 'styled-components';
import {useUserLoggedIn} from '../../../utils/useUserLoggedIn';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from '../../../redux/react-redux';

type RedirectTarget = {
  href: string;
  label: string;
};

type NotFoundProps = {
  title?: string;

  /**
   * Renders the redirect button depending on the value:
   * - `false` - no redirect button
   * - `{href: string, label: string}` - custom redirect button
   * - `undefined` - default redirect button
   */
  redirectTarget?: RedirectTarget | false;
};

export const NotFound: FC<NotFoundProps> = ({title = 'Page not found', redirectTarget}) => {
  const location = useLocation();
  const currentUser = useSelector(state => state.user);
  const subject = encodeURI(
    `Page "${location.pathname}" not found | My company: ${currentUser?.company} | My workspace: ${currentUser?.workspace?.name} | My email: ${currentUser?.email} (ID: ${currentUser?.id})`
  );

  const showRedirectButton = redirectTarget !== false;

  return (
    <NotFoundStyled>
      <Image>
        <img src={Image404} alt="not Found" />
      </Image>
      <Title>{title}</Title>
      <Description>
        <p>
          If there is a problem you want us to know about,{' '}
          <a href={`mailto:support@seabo.com?subject=${subject}`}>contact</a> us.
        </p>
      </Description>
      <br />
      {showRedirectButton && <RedirectButton redirectTarget={redirectTarget} />}
    </NotFoundStyled>
  );
};

const RedirectButton = ({redirectTarget}: {redirectTarget: RedirectTarget | undefined}) => {
  if (redirectTarget) {
    return <CustomRedirectButton {...redirectTarget} />;
  }
  return <DefaultRedirectButton />;
};

const CustomRedirectButton = ({href, label}: {href: string; label: string}) => (
  <Link to={href}>
    <Button className="uppercase" type="primary">
      {label}
    </Button>
  </Link>
);

const DefaultRedirectButton = () => {
  const userLoggedIn = useUserLoggedIn();
  const label = userLoggedIn ? 'To Dashboard' : 'To Home';
  const href = userLoggedIn ? '/dashboard' : '/';

  return (
    <Link to={href}>
      <Button className="uppercase" type="primary">
        {label}
      </Button>
    </Link>
  );
};

const NotFoundStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.div`
  max-width: 500px;
  img {
    min-width: 450px;
  }
`;

const Title = styled.h2`
  margin-top: 32px;
  font-size: var(--font-size-2xl);
  font-weight: bold;
  color: var(--color-gray-1);
`;

const Description = styled.div`
  font-size: var(--font-size-lg);
  margin-top: 24px;
  color: var(--color-gray-2);
  text-align: center;
`;
