import sum from 'lodash/sum';
import {OneDayStatistics, TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import sumBy from 'lodash/sumBy';

export type TiedUpTradingAreaWithDistributionAt = {
  tradingAreaName: string;
  distributionAt: number;
  percentagePointsDifference: number;
  dataSeries: {value: number; date: string}[];
};

export type ValueRepresentation = 'absolute' | 'percentage';

export const getDatasource = ({
  tradingAreaTiedUpVesselsStatistics,
  valueRepresentation,
  basicQuantity,
  accessor,
}: {
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[];
  basicQuantity: number;
  accessor: (day: OneDayStatistics) => number;
  valueRepresentation: ValueRepresentation;
}): {
  tiedUpTradingAreasWithDistributionAt: TiedUpTradingAreaWithDistributionAt[];
  notTiedUp: number;
} => {
  const tiedUpToday: number[] = [];

  const tiedUpTradingAreasWithDistributionAt = tradingAreaTiedUpVesselsStatistics.map(tradingareas => {
    const dataSeries = getValues({
      valueRepresentation: valueRepresentation,
      oneDayStatistics: tradingareas.oneDayStatistics,
      basicQuantity,
      accessor,
    });
    const lastSevenDays = dataSeries.slice(-7);
    const distributionAt = sumBy(lastSevenDays, 'value') / lastSevenDays.length;
    tiedUpToday.push(distributionAt);
    const valuesReferencePeriod = dataSeries.slice(-30, -7);
    const avgReferenceValue = sumBy(valuesReferencePeriod, 'value') / valuesReferencePeriod.length;
    const percentagePointsDifference = distributionAt - avgReferenceValue;
    const item: TiedUpTradingAreaWithDistributionAt = {
      tradingAreaName: tradingareas.tradingAreaName,
      distributionAt,
      percentagePointsDifference,
      dataSeries,
    };
    return item;
  });

  const tiedUpTodaySum = sum(tiedUpToday);

  const all = basicQuantity;

  let notTiedUp = 1;

  if (valueRepresentation === 'percentage') {
    notTiedUp = Math.round(100 - tiedUpTodaySum);
  } else {
    notTiedUp = Math.round(all - tiedUpTodaySum);
  }

  return {tiedUpTradingAreasWithDistributionAt, notTiedUp};
};

const getValues = ({
  oneDayStatistics,
  valueRepresentation,
  basicQuantity,
  accessor,
}: {
  oneDayStatistics: OneDayStatistics[];
  basicQuantity: number;
  valueRepresentation: ValueRepresentation;

  accessor: (day: OneDayStatistics) => number;
}): {value: number; date: string}[] => {
  return oneDayStatistics.map(day => {
    let value = accessor(day);

    if (valueRepresentation === 'percentage') {
      value = Math.round((value / basicQuantity) * 10000) / 100;
    }
    return {value, date: day.date};
  });
};
