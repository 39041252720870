import React, {FC} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

type FilterBarProps = {
  id: string;
  filters: JSX.Element[];
  className?: string;
};

export const FilterBar: FC<FilterBarProps> = ({id, className, filters}) => (
  <StyledFilterBar id={id} className={classNames(['row', className])}>
    {filters.map((filter, index) => (
      <div key={index} className={'scol-6 scol-sm-4 scol-md-3 scol-lg-2'}>
        {filter}
      </div>
    ))}
  </StyledFilterBar>
);

/**
 * Workaround for grid row not getting filled correctly.
 */
const StyledFilterBar = styled.div`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;
