import {Vessel} from '../../useMyFleetQuery/useMyFleetQuery';
import table from 'text-table';

export const getSize = (vessel: Vessel): string => {
  let list: [string, number][] = [];
  switch (vessel.vesselType) {
    // 1,717 TEU nominal / about 1,257 TEU at 14mts homogeneous
    case 'container':
      list = vessel.intakes.map(intake => [
        `\t${intake.type.toUpperCase()} ${intake.isHighCube ? 'HQ' : ''} ${intake.isEmpty ? 'Empty' : ''}`,
        intake.quantity,
      ]);
      return `Intakes:
${table(list, {align: ['l', 'r', 'r']})}`;
    default:
      return '***** NOT IMPLEMENTED';
  }
};
