import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FilterProviderState} from '../components/FilterProvider/FilterProviderState';
import {
  MarketVesselFilterBranchDefinitions,
  MarketVesselDatabaseFilter,
} from '../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';
import {
  MarketCargoFilterBranchDefinitions,
  MarketCargoDatabaseFilter,
} from '../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';
import {
  MarketPortFilterBranchDefinitions,
  MarketPortDatabaseFilter,
} from '../components/FilterProvider/Filters/Market/MarketPortFilterBranchDefinitions';

interface MarketFiltersState {
  vesselFilterSettings: MarketVesselDatabaseFilter | null;
  cargoFilterSettings: MarketCargoDatabaseFilter | null;
  portFilterSettings: MarketPortDatabaseFilter | null;
  vesselFilterProviderState: FilterProviderState<typeof MarketVesselFilterBranchDefinitions> | null;
  cargoFilterProviderState: FilterProviderState<typeof MarketCargoFilterBranchDefinitions> | null;
  portFilterProviderState: FilterProviderState<typeof MarketPortFilterBranchDefinitions> | null;
}

const initialState: MarketFiltersState = {
  vesselFilterSettings: null,
  cargoFilterSettings: null,
  portFilterSettings: null,
  vesselFilterProviderState: null,
  cargoFilterProviderState: null,
  portFilterProviderState: null,
};

const MarketFiltersSlice = createSlice({
  name: 'MarketFilters',
  initialState,
  reducers: {
    setVesselFilterSettings(state, action: PayloadAction<MarketVesselDatabaseFilter | null>) {
      state.vesselFilterSettings = action.payload;
    },
    setCargoFilterSettings(state, action: PayloadAction<MarketCargoDatabaseFilter | null>) {
      state.cargoFilterSettings = action.payload;
    },
    setPortFilterSettings(state, action: PayloadAction<MarketPortDatabaseFilter | null>) {
      state.portFilterSettings = action.payload;
    },
    setVesselFilterProviderState(
      state,
      action: PayloadAction<FilterProviderState<typeof MarketVesselFilterBranchDefinitions> | null>
    ) {
      state.vesselFilterProviderState = action.payload;
    },
    setCargoFilterProviderState(
      state,
      action: PayloadAction<FilterProviderState<typeof MarketCargoFilterBranchDefinitions> | null>
    ) {
      state.cargoFilterProviderState = action.payload;
    },
    setPortFilterProviderState(
      state,
      action: PayloadAction<FilterProviderState<typeof MarketPortFilterBranchDefinitions> | null>
    ) {
      state.portFilterProviderState = action.payload;
    },
  },
});

export const MarketFiltersActions = MarketFiltersSlice.actions;
export const marketFiltersReducer = MarketFiltersSlice.reducer;
