import React, {FC, useState} from 'react';
import styled from 'styled-components';
import {TextAreaKeyControlled} from '../../../../../../antd/components/TextAreaKeyControlled';
import {defaultTermColors} from '../../../../utils/colors';
import {TermItemDumb} from '../TermItemDumb';
import {TermItemCreateActions} from './TermItemCreateActions';
import {NegotiationTermType} from '../../../../../../api/node-backend/generated';
import {TermTypeSelect} from '../Components/TermType/TermTypeSelect';

type TermItemCreateProps = {
  focusOnMount?: boolean;
  disabled?: boolean;
  onAdd: (term: {text: string; type: NegotiationTermType | null}) => void;
  onCancel?: () => void;
};

export const TermItemCreate: FC<TermItemCreateProps> = ({focusOnMount, disabled, onAdd, onCancel}) => {
  const [termText, setTermText] = useState('');
  const [termType, setTermType] = useState<NegotiationTermType | null>(null);
  const [isHighlighted, setIsHighlighted] = useState(false);

  const resetOrCancel = () => {
    if (!termText) {
      onCancel?.();
      return;
    }
    setTermText('');
    setTermType(null);
  };
  const save = () => {
    onAdd({text: termText, type: termType});
    resetOrCancel?.();
  };

  return (
    <TermItemDumb
      data-testid="TermItemCreate"
      indexLabel={<span style={{userSelect: 'none'}}>+</span>}
      isHighlighted={isHighlighted}
      colors={defaultTermColors}
      beforeContentAddon={<TermTypeSelect termType={termType} onTermTypeChange={setTermType} disabled={disabled} />}
      contentReplacement={
        <>
          <TermItemCreateTextArea
            data-testid="TermItemCreateTextArea"
            size="small"
            placeholder="Add new term"
            autoSize={{minRows: 1, maxRows: 10}}
            focusOnMount={focusOnMount}
            value={termText}
            disabled={disabled}
            onSave={save}
            onFocus={() => setIsHighlighted(true)}
            onBlur={() => setIsHighlighted(false)}
            onCancel={resetOrCancel}
            onChange={event => setTermText(event.target.value)}
          />
          <TermItemCreateActions onSave={save} onCancel={resetOrCancel} disabled={!isHighlighted && !termText} />
        </>
      }
    />
  );
};

const TermItemCreateTextArea = styled(TextAreaKeyControlled)`
  max-width: unset !important;
  width: 100%;
  min-height: 31px !important;
  margin: 0;
  padding: 6px 80px 6px 10px;
  background: var(--color-self-bg);
  border: 0 solid transparent !important;
  outline: none !important;
  border-radius: var(--border-radius) !important;
  box-shadow: none !important;
  z-index: 0;
  cursor: text;
  transition: none !important;

  &:focus {
    outline: none !important;
    padding: 4px 8px;
    border: var(--border-width-active) solid var(--color-self) !important;
  }
`;
