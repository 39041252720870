/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {ProjectVessel, ProjectVesselFromJSON, ProjectVesselFromJSONTyped, ProjectVesselToJSON} from './';

/**
 *
 * @export
 * @interface AddVesselsToProjectViaOffersResponse
 */
export interface AddVesselsToProjectViaOffersResponse {
  /**
   *
   * @type {number}
   * @memberof AddVesselsToProjectViaOffersResponse
   */
  offersSentCount: number;
  /**
   *
   * @type {number}
   * @memberof AddVesselsToProjectViaOffersResponse
   */
  vesselsAddedCount: number;
  /**
   *
   * @type {Array<ProjectVessel>}
   * @memberof AddVesselsToProjectViaOffersResponse
   */
  items: Array<ProjectVessel>;
}

export function AddVesselsToProjectViaOffersResponseFromJSON(json: any): AddVesselsToProjectViaOffersResponse {
  return AddVesselsToProjectViaOffersResponseFromJSONTyped(json, false);
}

export function AddVesselsToProjectViaOffersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddVesselsToProjectViaOffersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    offersSentCount: json['offersSentCount'],
    vesselsAddedCount: json['vesselsAddedCount'],
    items: (json['items'] as Array<any>).map(ProjectVesselFromJSON),
  };
}

export function AddVesselsToProjectViaOffersResponseToJSON(value?: AddVesselsToProjectViaOffersResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    offersSentCount: value.offersSentCount,
    vesselsAddedCount: value.vesselsAddedCount,
    items: (value.items as Array<any>).map(ProjectVesselToJSON),
  };
}
