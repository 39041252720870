import {stringLengthLessThanOrEqualTo} from '../../../../utils/validators';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export type DatabaseType = {
  name?: string;
  code?: string;
};
export type InternalType = {
  name: string;
  code: string;
};

export const Defaults: InternalType = {
  name: '',
  code: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {name, code} = database;
  return {
    name: name ?? '',
    code: code ?? '',
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {name, code} = internal;
  return {
    name: name || undefined,
    code: code || undefined,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Attributes',
  branch: 'attributes',
  defaults: Defaults,
  validators: {
    name: stringLengthLessThanOrEqualTo(126),
    code: stringLengthLessThanOrEqualTo(5),
  },
  fromDatabase,
  toDatabase,
});
