import {Fragment} from 'react';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import Input from '../../../../../atoms/Input';
import {AsyncSelect} from '../../../../../atoms/Select/AsyncSelect';
import {hasError} from '../../../../../utils/helper';
import Checkbox from '../../../../../atoms/Checkbox';
import HorizontalLine from '../../../../../atoms/HorizontalLine';
import {designSubTypes} from '../../../../../screens/CargoVesselForm/helper';
import autocompleteApi from '../../../../../redux/ApiService/autocomplete';
import NumberRangeInput from '../../../../RangeInput/NumberRangeInput';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './FixtureVesselDefinition';
import {MultiSelect} from '../../../../../atoms/Select/MultiSelect';
import {AsyncMultiCreatable} from '../../../../../atoms/Select/AsyncMultiCreatable';
import {useDispatch, useSelector} from '../../../../../redux/react-redux';
import {PortfolioVessel} from '../../../../../redux/Portfolio';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const FixtureVessel = ({filterProviderApi}: Props) => {
  const dispatch = useDispatch();
  const searchPortfolioVessels = (query: string) =>
    dispatch(autocompleteApi.searchPortfolioVessels(query)) as Promise<PortfolioVessel[]>;
  const loadDesignTypeOptions = (query: string) => dispatch(autocompleteApi.searchDesignTypes(query));
  const preFillDesignTypes = useSelector(state => state.preFill.data);

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
      {({handleChange, values, validations}) => (
        <div className="container">
          <div className="row">
            <div className="scol-12">
              <LabelWrapper label={'Name'} htmlFor={'filter-general-name'} hasError={hasError('name', validations)}>
                <Input
                  id={'filter-general-name'}
                  onChange={value => handleChange('name', value)}
                  value={values.name}
                  hasError={hasError('name', validations)}
                  maxLength={191}
                />
              </LabelWrapper>
            </div>
          </div>
          <div className="row">
            <div className="scol-12">
              <LabelWrapper label={'IMO'} htmlFor={'filter-general-imo'} hasError={hasError('imo', validations)}>
                <AsyncSelect<{imo?: number; name?: string; vesselType?: string; value?: string}>
                  id={`filter-general-imo`}
                  isMulti={false}
                  value={values.imo}
                  onChange={value => handleChange('imo', value ? {imo: value.imo!} : null)}
                  getOptionLabel={option => (
                    <span>
                      {option.name ? (
                        <Fragment>
                          {option.name}{' '}
                          <span style={{color: 'var(--color-gray-2)'}}>
                            ({option.imo} {option.vesselType && <span>, {option.vesselType.toUpperCase()}</span>})
                          </span>
                        </Fragment>
                      ) : (
                        option.value
                      )}
                    </span>
                  )}
                  getOptionValue={option => option.imo}
                  loadOptions={q => {
                    if (q.length < 2) {
                      return Promise.resolve([]);
                    }
                    if (isNaN(parseInt(q))) {
                      return Promise.resolve([]);
                    }
                    return searchPortfolioVessels(q);
                  }}
                  isClearable
                  loadingMessage={() => 'Searching ...'}
                />
              </LabelWrapper>
            </div>
          </div>
          <HorizontalLine large topSpace={'small'} />
          <div className="row">
            <div className="scol-12">
              <Checkbox
                id={'filter-fixture-general-ismpp'}
                checked={values.isMPP}
                label={'MPP'}
                onChange={e => handleChange('isMPP', e.target.checked)}
              />
            </div>
            <div className="scol-12">
              <Checkbox
                id={'filter-fixture-general-isbulker'}
                checked={values.isBulker}
                label={'Bulker'}
                onChange={e => handleChange('isBulker', e.target.checked)}
                spMT={'s'}
              />
            </div>
            <div className="scol-12">
              <Checkbox
                id={'filter-fixture-general-iscontainer'}
                checked={values.isContainer}
                label={'Container'}
                onChange={e => handleChange('isContainer', e.target.checked)}
                spMT={'s'}
              />
            </div>
          </div>
          <HorizontalLine large topSpace={'large'} />
          <div className="row">
            <div className="scol-12">
              <NumberRangeInput
                label={'Year built'}
                onFromChange={value => handleChange('minimumAge', value)}
                onToChange={value => handleChange('maximumAge', value)}
                fromValue={values.minimumAge}
                toValue={values.maximumAge}
                allowEqualMinMax
                fromProps={{
                  placeholder: 'min year',
                  hasError: hasError('minimumAge', validations),
                }}
                toProps={{
                  placeholder: 'max year',
                  hasError: hasError('maximumAge', validations),
                }}
              />
            </div>
          </div>
          <HorizontalLine large topSpace={'small'} />
          <div className="row bottom-fix">
            <div className="scol-12">
              <LabelWrapper label={'Bulker sub type'} htmlFor={'filter-vesselsize-design'}>
                <MultiSelect
                  id={'filter-vesselsize-design'}
                  value={values.vesselDesigns}
                  onChange={value => handleChange('vesselDesigns', [...value])}
                  options={Object.keys(designSubTypes).map(value => ({
                    value,
                    name: designSubTypes[value as keyof typeof designSubTypes],
                  }))}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.value}
                />
              </LabelWrapper>
            </div>
            <div className="scol-12">
              <LabelWrapper label={'Container design type'} htmlFor={'filter-vesselsize-subtype-container'}>
                <AsyncMultiCreatable
                  id={'filter-vesselsize-subtype-container'}
                  name={'filter-vesselsize-subtype-container'}
                  value={values.vesselDesignTypes}
                  onChange={value => handleChange('vesselDesignTypes', [...value])}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.label}
                  loadOptions={q => (q.length >= 2 ? loadDesignTypeOptions(q) : Promise.resolve([]))}
                  defaultOptions={
                    preFillDesignTypes.map(p => ({
                      value: p.slug,
                      label: p.name,
                    })) || []
                  }
                  placeholder={'Type to search...'}
                  loadingMessage={() => 'Searching ...'}
                  isClearable
                  cacheOptions
                  createLabel={value => value}
                  createOptionPosition={'first'}
                />
              </LabelWrapper>
            </div>
          </div>
        </div>
      )}
    </FilterButton>
  );
};

export default FixtureVessel;
