import styled, {css} from 'styled-components';
import {useState, FC} from 'react';
import {Tag} from '../../api/symfony/generated';
import Icon from '../../atoms/Icon';
import {Tag as AntTag} from 'antd';
import Tooltip from '../../atoms/Tooltip';
import {Trigger} from 'react-tippy';

export const TagsView: FC<{selectedTags: Tag[]; modalOpen: boolean}> = ({selectedTags, modalOpen}) => {
  const [hover, setHover] = useState(false);

  // We show the edit icon when the user hovers over the tags or the modal is open.
  const isEditing = hover || modalOpen;

  if (selectedTags.length === 0) {
    return (
      <Wrapper
        $showEditMode={isEditing}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}>
        <TagsContainer>
          <NoTags />
        </TagsContainer>
        <EditContainer>
          <Icon type="edit" size="medium" />
        </EditContainer>
      </Wrapper>
    );
  }

  return (
    <StyledTooltip
      position={'top'}
      trigger={'mouseenter focus' as Trigger}
      title={selectedTags.map(tag => tag.name).join(', ')}>
      <Wrapper
        $showEditMode={isEditing}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}>
        <TagsContainer>
          {selectedTags.length > 2 ? (
            <>
              <TagView tagName={selectedTags[0].name} />
              <TagView tagName={`+ ${selectedTags.length - 1} more`} />
            </>
          ) : (
            selectedTags.map(tag => <TagView key={tag.name} tagName={tag.name} />)
          )}
        </TagsContainer>
        <EditContainer>
          <Icon type="edit" size="medium" />
        </EditContainer>
      </Wrapper>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  display: flex !important;
  max-width: 100%;
`;

const TagView: FC<{tagName: string}> = ({tagName}) => {
  return <StyledTag>{tagName}</StyledTag>;
};

const StyledTag = styled(AntTag)`
  width: fit-content;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
`;

const NoTags: FC = () => {
  return <div style={{width: 80, height: 20}}>-</div>;
};

const TagsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 55px;
  cursor: pointer;
  width: 100%;
  margin-bottom: -5px;
`;

const EditContainer = styled.div`
  height: auto;
  width: 0;
  overflow: hidden;
  text-align: right;
`;

const Wrapper = styled.div<{$showEditMode: boolean}>`
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  width: 100%;

  ${({$showEditMode}) => {
    if ($showEditMode) {
      return css`
        ${EditContainer} {
          width: 30px;
        }
        ${TagsContainer} {
          width: calc(100% - 30px);
        }
      `;
    }
    return '';
  }}
`;
