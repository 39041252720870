import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as CargoTypeDefinition from '../CargoType/CargoTypeDefinition';
import * as ChartererDefinition from '../Charterer/ChartererDefinition';
import * as ContractTypeDefinition from '../ContractType/ContractTypeDefinition';
import * as VesselSizeDefinition from '../VesselSize/VesselSizeDefinition';
import * as QuantityDefinition from '../Quantity/QuantityDefinition';
import * as FeaturesDefinition from '../Features/FeaturesDefinition';
import * as LaycanDefinition from '../Laycan/LaycanDefinition';
import * as DurationDefinition from '../Duration/DurationDefinition';
import * as LocationCargoDefinition from '../LocationCargo/LocationCargoDefinition';
import * as CommoditiesDefinition from '../Commodities/CommoditiesDefinition';

export const PortfolioCargoFilterBranchDefinitions = makeFilterBranchDefinitions([
  CargoTypeDefinition.filterBranchDefinition,
  ChartererDefinition.filterBranchDefinition,
  ContractTypeDefinition.filterBranchDefinition,
  VesselSizeDefinition.filterBranchDefinition,
  QuantityDefinition.filterBranchDefinition,
  FeaturesDefinition.filterBranchDefinition,
  LaycanDefinition.filterBranchDefinition,
  DurationDefinition.filterBranchDefinition,
  LocationCargoDefinition.filterBranchDefinition,
  CommoditiesDefinition.filterBranchDefinition,
] as const);

export type PortfolioCargoInternalFilter = Partial<FilterInternalType<typeof PortfolioCargoFilterBranchDefinitions>>;
export type PortfolioCargoDatabaseFilter = Partial<FilterDatabaseType<typeof PortfolioCargoFilterBranchDefinitions>>;
