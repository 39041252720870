import {UserColumn} from '../../../atoms/UserColumn/UserColumn';
import {TODO} from '../../../utils/TODO';
import {CustomColumnDef} from '../../DataGrid/DataGrid';

const vesselPostedByAccessor = (d: TODO) =>
  d.vessel && d.vessel.user && d.vessel.user.id ? (
    <UserColumn
      userId={d.vessel.user.id}
      title={d.vessel.user.fullName}
      subTitle={d.vessel.company && d.vessel.company.name}
    />
  ) : (
    <span> {d.vessel.offer && d.vessel.offer.originSender ? d.vessel.offer.originSender : '-'} </span>
  );

export const vesselPostedByColumn = ({
  minWidth = 4,
  sortable = true,
}: {
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<TODO> => ({
  header: 'Posted by',
  id: 'user',
  enableSorting: sortable,
  minWidth,
  accessorFn: d => vesselPostedByAccessor(d),
  cell: info => info.getValue(),
});
