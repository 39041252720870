import {FC, useState} from 'react';
import IconButton from '../../../../atoms/IconButton';
import styled from 'styled-components';
import {useOfferMailAsJsonQuery} from '../utils/useOfferMailAsJsonQuery';
import {NotAvailable} from '../../../../utils/NotAvailable';
import {OfferMailModal} from '../../../OfferMailModal/OfferMailModal';

type Props = {
  id?: number;
  type: 'cargo' | 'vessel';
};

export const OfferMailModalButton: FC<Props> = ({id, type}) => {
  const [showMailTextModal, setShowMailTextModal] = useState(false);
  const offerMailQuery = useOfferMailAsJsonQuery({id, type}, {enabled: !!id});

  if (!offerMailQuery.data) {
    return (
      <MailTextWrapper style={{opacity: 0.3}}>
        <IconButton buttonStyle={{marginTop: '-3px', marginLeft: '-5px'}} iconSize={'medium-small'} type={'sent'} />
        Mail {NotAvailable}
      </MailTextWrapper>
    );
  }

  return (
    <>
      <OfferMailModal
        offerMail={offerMailQuery.data}
        onCancel={() => setShowMailTextModal(false)}
        open={showMailTextModal}
        title={'Original email'}
      />
      <MailTextWrapper style={{cursor: 'pointer'}} onClick={() => setShowMailTextModal(true)}>
        <IconButton buttonStyle={{marginTop: '-3px', marginLeft: '-5px'}} iconSize={'medium-small'} type={'sent'} />
        Show original email
      </MailTextWrapper>
    </>
  );
};

const MailTextWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  color: var(--color-gray-2);
`;
