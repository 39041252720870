import {ViewBounds} from '../../redux/ViewBounds';
import {SpotifindVessel} from './useGetSpotifindVesselsQuery';

export const getVesselsInBoundingBox = ({
  vessels,
  boundingBox,
}: {
  vessels: SpotifindVessel[];
  boundingBox: ViewBounds | null;
}): SpotifindVessel[] => {
  if (!boundingBox) {
    // We don't filter by bounding box.
    return vessels;
  }

  const newVessels: SpotifindVessel[] = [];

  // For performance optimization we destructure here, so that we don't do it in the loop.
  const {south: boundingBoxSouth, north: boundingBoxNorth, west: boundingBoxWest, east: boundingBoxEast} = boundingBox;

  for (let vesselIndex = 0; vesselIndex < vessels.length; vesselIndex++) {
    const vessel = vessels[vesselIndex];
    const [latitude, longitude] = vessel.coordinates;
    const isVisible =
      longitude > boundingBoxSouth &&
      longitude < boundingBoxNorth &&
      latitude < boundingBoxWest &&
      latitude > boundingBoxEast;
    if (isVisible) {
      newVessels.push(vessel);
    }
  }
  return newVessels;
};
