import {assert} from '../../../utils/assert';

export const Mapping: Record<string, string> = {
  vc: 'VC',
  tct: 'TCT',
  pc: 'PC',
  bc: 'BBC',
  sale: 'SALE',
};

export const formatContractType = (contractType: string): string => {
  if (!contractType) {
    return '-';
  }
  const formatted = Mapping[contractType];
  assert(formatted, `Unknown contractType ${contractType}`);
  return formatted;
};
