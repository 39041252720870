import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Talk from 'talkjs';
import {createSelector} from 'reselect';
import {RootState} from './store';

interface ChatState {
  session: Talk.Session | null;
  popup: Talk.Popup | null;
  unreadConversationsCount: number;
}

const initialState: ChatState = {
  session: null,
  popup: null,
  unreadConversationsCount: 0,
};

const chatSlice = createSlice({
  name: 'Chat',
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<Talk.Session | null>) {
      state.session = action.payload;
    },
    setPopup(state, action: PayloadAction<Talk.Popup | null>) {
      state.popup = action.payload;
    },
    setUnreadConversationsCount(state, action: PayloadAction<number>) {
      state.unreadConversationsCount = action.payload;
    },
  },
});

export const talkChatIsActiveSelector = createSelector(
  (state: RootState) => state.chat.session,
  session => {
    return !!session;
  }
);

export const chatReducer = chatSlice.reducer;

export const ChatActions = chatSlice.actions;
