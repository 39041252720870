import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import {FC, useMemo} from 'react';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {StatCard} from '../sharedComponents/StatCard';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';

export const FinishedVisitsTotalTimePerDate: FC<{
  visits: MergedVisits[];
  onSetVisits: (vessels: MergedVisits[]) => void;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const perDateGrouped = groupBy(visits, vessel => dayjs(vessel.endTimestamp).format(DateTimeFormat.Date));

    const labels = uniq(
      visits
        .map(vessel => vessel.endTimestamp)
        .sort()
        .map(timeString => dayjs(timeString).format(DateTimeFormat.Date))
    );

    const values = labels.map(value => {
      return sortBy(perDateGrouped[value].map(v => v.visitDuration));
    });

    return {values, labels, perDateGrouped};
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perDateGrouped[event.name]);
              onSetAppliedFilter('Total time per vessel by leave date', event.name);
            }
          },
        }}
        option={{
          title: [
            {
              left: 'center',
              text: 'Total time per vessel by leave date',
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: dataSource.labels,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              type: 'boxplot',
              data: dataSource.values,
              showBackground: false,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)',
              },
              itemStyle: {
                color: '#b8c5f2',
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};
