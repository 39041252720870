import React, {FC, useRef, useState} from 'react';
import {NegotiationSubjectStatus} from '../../../../../api/node-backend/generated';
import {SubjectItemDumb} from './SubjectItemDumb';
import {SubjectEditorAutoComplete} from './SubjectEditorAutoComplete';
import {TermEditorActions} from '../TermItem/Components/TermEditor/TermEditorActions';
import {getSubjectColors} from '../../../utils/colors';
import {EditorContainer, EditorContent, EditorEditorContainer} from '../TermItem/Components/shared';

type SubjectItemCreateProps = {
  disabled?: boolean;
  onAdd: (sub: {text: string}) => void;
  onCancel?: () => void;
};

export const SubjectItemCreate: FC<SubjectItemCreateProps> = ({disabled, onAdd, onCancel}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [subjectText, setSubjectText] = useState('');
  const [isHighlighted, setIsHighlighted] = useState(false);

  const resetOrCancel = () => {
    if (!subjectText) {
      onCancel?.();
      return;
    }
    setSubjectText('');
  };

  const createSubject = () => {
    if (!subjectText) {
      return;
    }
    onAdd({text: subjectText});
    setSubjectText('');
  };

  const colors = getSubjectColors(NegotiationSubjectStatus.Ongoing, false);

  return (
    <SubjectItemDumb
      data-testid="SubjectItemCreate"
      containerRef={containerRef}
      text={''}
      isHighlighted={isHighlighted}
      status={NegotiationSubjectStatus.Ongoing}
      colors={colors}
      contentReplacement={
        <EditorContainer data-testid="SubjectItemCreateEditor" $disabled={disabled}>
          <EditorContent>
            <EditorEditorContainer>
              <SubjectEditorAutoComplete
                disabled={disabled}
                value={subjectText}
                placeholder={'Add new subject'}
                onSelect={data => setSubjectText(data as string)}
                onSearch={text => setSubjectText(text)}
                onFocus={() => setIsHighlighted(true)}
                onBlur={() => setIsHighlighted(false)}
                // Save on enter
                onKeyDown={event => {
                  if (!event.shiftKey && event.key === 'Enter') {
                    event.preventDefault();
                    createSubject();
                  }
                  if (event.key === 'Escape') {
                    event.preventDefault();
                    resetOrCancel();
                  }
                }}
              />
              <TermEditorActions
                disabled={!isHighlighted && !subjectText}
                onSave={createSubject}
                onCancel={resetOrCancel}
              />
            </EditorEditorContainer>
          </EditorContent>
        </EditorContainer>
      }
    />
  );
};
