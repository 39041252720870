/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PortfolioGroupType,
  PortfolioGroupTypeFromJSON,
  PortfolioGroupTypeFromJSONTyped,
  PortfolioGroupTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface PortfolioGroupStub
 */
export interface PortfolioGroupStub {
  /**
   *
   * @type {string}
   * @memberof PortfolioGroupStub
   */
  name: string;
  /**
   *
   * @type {PortfolioGroupType}
   * @memberof PortfolioGroupStub
   */
  type: PortfolioGroupType;
  /**
   *
   * @type {number}
   * @memberof PortfolioGroupStub
   */
  sortOrder?: number;
}

export function PortfolioGroupStubFromJSON(json: any): PortfolioGroupStub {
  return PortfolioGroupStubFromJSONTyped(json, false);
}

export function PortfolioGroupStubFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioGroupStub {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    type: PortfolioGroupTypeFromJSON(json['type']),
    sortOrder: !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
  };
}

export function PortfolioGroupStubToJSON(value?: PortfolioGroupStub | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: PortfolioGroupTypeToJSON(value.type),
    sortOrder: value.sortOrder,
  };
}
