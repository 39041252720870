/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Tag, TagFromJSON, TagFromJSONTyped, TagToJSON} from './';

/**
 *
 * @export
 * @interface TagListResponse
 */
export interface TagListResponse {
  /**
   *
   * @type {number}
   * @memberof TagListResponse
   */
  totalItems: number;
  /**
   *
   * @type {Array<Tag>}
   * @memberof TagListResponse
   */
  items: Array<Tag>;
}

export function TagListResponseFromJSON(json: any): TagListResponse {
  return TagListResponseFromJSONTyped(json, false);
}

export function TagListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalItems: json['totalItems'],
    items: (json['items'] as Array<any>).map(TagFromJSON),
  };
}

export function TagListResponseToJSON(value?: TagListResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalItems: value.totalItems,
    items: (value.items as Array<any>).map(TagToJSON),
  };
}
