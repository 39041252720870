import {InlineNumberEditor} from '../../../../atoms/InlineNumberEditor/InlineNumberEditor';
import {EditableHeaderBox} from './EditableHeaderBox';
import {EditorFormattedValueLabel} from './EditorFormattedValueLabel';

export const TotalCommission = (props: {value: number | undefined; onChange: (value: number | undefined) => void}) => {
  return (
    <EditableHeaderBox
      childWidth={'80%'}
      hideRadiusRight
      title="Total commission"
      renderInput={(editing, setEditMode) => (
        <InlineNumberEditor
          min={0}
          closeOnBlur={false}
          size={'large'}
          editing={editing}
          onEditingChanged={setEditMode}
          closeOnEnter={true}
          editModeAvailable={false}
          editButtonAlignment="text-bottom"
          value={props.value ?? 0}
          formattedValue={
            <EditorFormattedValueLabel data-cy={'totalCommission'}>{props.value}%</EditorFormattedValueLabel>
          }
          onChange={totalCommission => props.onChange(totalCommission)}
        />
      )}
    />
  );
};
