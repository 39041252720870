/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VesselTypeEnum, VesselTypeEnumFromJSON, VesselTypeEnumFromJSONTyped, VesselTypeEnumToJSON} from './';

/**
 *
 * @export
 * @interface NegotiationVessel
 */
export interface NegotiationVessel {
  /**
   *
   * @type {number}
   * @memberof NegotiationVessel
   */
  id: number | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationVessel
   */
  name: string | null;
  /**
   *
   * @type {VesselTypeEnum}
   * @memberof NegotiationVessel
   */
  type: VesselTypeEnum;
  /**
   * The IMO of the vessel.
   * @type {number}
   * @memberof NegotiationVessel
   */
  imo: number | null;
  /**
   *
   * @type {number}
   * @memberof NegotiationVessel
   */
  size: number | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationVessel
   */
  sizeUnit: string | null;
  /**
   * The date when the vessel is open. Format: YYYY-MM-DD
   * @type {string}
   * @memberof NegotiationVessel
   */
  dateOpen: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationVessel
   */
  locationOpenLabel: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationVessel
   */
  locationOpenCountryCode: string | null;
}

export function NegotiationVesselFromJSON(json: any): NegotiationVessel {
  return NegotiationVesselFromJSONTyped(json, false);
}

export function NegotiationVesselFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationVessel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    type: VesselTypeEnumFromJSON(json['type']),
    imo: json['imo'],
    size: json['size'],
    sizeUnit: json['sizeUnit'],
    dateOpen: json['dateOpen'],
    locationOpenLabel: json['locationOpenLabel'],
    locationOpenCountryCode: json['locationOpenCountryCode'],
  };
}

export function NegotiationVesselToJSON(value?: NegotiationVessel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    type: VesselTypeEnumToJSON(value.type),
    imo: value.imo,
    size: value.size,
    sizeUnit: value.sizeUnit,
    dateOpen: value.dateOpen,
    locationOpenLabel: value.locationOpenLabel,
    locationOpenCountryCode: value.locationOpenCountryCode,
  };
}
