/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {UserWidget, UserWidgetFromJSON, UserWidgetFromJSONTyped, UserWidgetToJSON} from './';

/**
 *
 * @export
 * @interface WidgetListResponse
 */
export interface WidgetListResponse {
  /**
   *
   * @type {object}
   * @memberof WidgetListResponse
   */
  available: object;
  /**
   *
   * @type {Array<UserWidget>}
   * @memberof WidgetListResponse
   */
  installed: Array<UserWidget>;
}

export function WidgetListResponseFromJSON(json: any): WidgetListResponse {
  return WidgetListResponseFromJSONTyped(json, false);
}

export function WidgetListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    available: json['available'],
    installed: (json['installed'] as Array<any>).map(UserWidgetFromJSON),
  };
}

export function WidgetListResponseToJSON(value?: WidgetListResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    available: value.available,
    installed: (value.installed as Array<any>).map(UserWidgetToJSON),
  };
}
