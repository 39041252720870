import {IdentifierProp, MapActions} from '../../redux/Map';
import {MapSettings} from './MapContext/Types';
import {UseQueryOptions} from '../../api/utils/ReactQuery';
import {useDispatch} from '../../redux/react-redux';
import {keepPreviousData, useMutation, useQuery} from '@tanstack/react-query';
import {blankState} from './MapContext/blankstate';
import {SAVE_FORMAT_VERSION} from './const';
import {useThrottle} from '../../utils/useThrottle';
import {getInitialMapSwitches} from './defaultMapSwitches';
import {assert} from '../../utils/assert';

export type UseSettingsPersistenceReturn = {
  settingsLoaded: boolean;
  settings: Partial<MapSettings> | undefined;
  saveSettings: (mapSettings: MapSettings) => void;
};

export const useSettingsPersistence = ({
  settingIdentifier = 'defaults',
  initialMapSettings,
  settingsPersistent = true,
}: {
  settingIdentifier?: IdentifierProp;
  /**
   * When settingsPersistent is true, the map settings are saved on the server.
   * More informations about the settingsPersistent can be found in the "settingsPersistent"-prop in MapContext-Props.
   */
  settingsPersistent?: boolean;
  initialMapSettings?: Partial<MapSettings>;
}): UseSettingsPersistenceReturn => {
  const settingsQuery = useSettingsQuery(
    {
      settingIdentifier,
      initialMapSettings,
      settingsPersistent,
    },
    {
      refetchOnMount: 'always',
      placeholderData: keepPreviousData,
    }
  );

  const saveMutation = useSaveMapSettingsMutation(settingIdentifier);

  const settingsLoaded = settingsQuery.isSuccess && settingsQuery.isFetchedAfterMount;

  const saveSettings = useThrottle(
    settingsPersistent ? saveMutation.mutate : () => {},
    [settingsPersistent, saveMutation.mutate],
    1000,
    {
      leading: true,
      trailing: true,
    }
  );

  const settings = settingsQuery.data;

  return {settingsLoaded, settings, saveSettings};
};

const useSaveMapSettingsMutation = (settingIdentifier: IdentifierProp) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async (mapSettings: MapSettings) => {
      const dataToUpdate = {...mapSettings, version: SAVE_FORMAT_VERSION};
      return dispatch(MapActions.saveSettings(settingIdentifier, dataToUpdate));
    },
  });
};

const useSettingsQuery = (
  {
    settingIdentifier,
    initialMapSettings,
    settingsPersistent,
  }: {
    settingIdentifier?: IdentifierProp;
    settingsPersistent: boolean;
    initialMapSettings?: Partial<MapSettings>;
  },
  options: UseQueryOptions<MapSettings>
) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['mapSettings', settingIdentifier],
    queryFn: async (): Promise<MapSettings> => {
      const defaultSwitches = getInitialMapSwitches(initialMapSettings?.switches);

      const defaultSettings = {
        ...blankState.settings,
        ...initialMapSettings,
        switches: defaultSwitches,
      };

      if (!settingsPersistent) {
        return defaultSettings;
      }

      const settings = await dispatch(MapActions.getSettings(settingIdentifier));
      assert(settings, 'settings should be defined');

      if (settings.version === SAVE_FORMAT_VERSION) {
        return {...defaultSettings, ...settings, switches: {...defaultSettings.switches, ...settings.switches}};
      }
      return defaultSettings;
    },
    ...options,
  });
};
