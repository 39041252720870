import {FC} from 'react';
import {Asterisk, CtaButton, InfoIcon, InputLabel, Instruction} from '../utils/shared-components';
import Tooltip from '../../../atoms/Tooltip';
import {Checkbox, Form, FormInstance, Input} from 'antd';
import {BankOutlined, UserOutlined} from '@ant-design/icons';
import {useServerSideValidator} from '../../../utils/serverSideValidator';
import {CompanySignupRequest} from '../../../api/symfony/generated';
import {PhoneInputFormItem, phoneNumberValidationRules} from '../../../atoms/PhoneInputFormItem';

const TERMS_AND_CONDITIONS_URL = 'https://seabo.com/terms-and-conditions';
const DPA_URL = 'https://seabo.com/dpa';

export const SignupFormStep1: FC<{
  visible: boolean;
  submitError?: Error | null;
  isSubmitting: boolean;

  form: FormInstance<CompanySignupRequest>;
  formValuesAtSubmit: CompanySignupRequest | null;
}> = ({visible, submitError, formValuesAtSubmit, form, isSubmitting}) => {
  const serverSideCompanyValidator = useServerSideValidator<CompanySignupRequest>(
    'name',
    submitError,
    formValuesAtSubmit,
    form
  );

  return (
    <div hidden={!visible}>
      <Instruction>Please enter your data and click sign up to finish your registration.</Instruction>
      <InputLabel>
        <span>
          Company<Asterisk>*</Asterisk>
        </span>
        <Tooltip
          position="top"
          title="Your company name is needed to assign your colleagues to a unified workspace. In the case of unverifiable or dubious information, we reserve the right to immediately deactivate your account. Therefore, please make sure that the information you provide is correct. If you are a student, please state the university and the degree programme you are enrolled in."
          trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input your company.',
          },
          {validator: serverSideCompanyValidator},
        ]}>
        <Input prefix={<BankOutlined />} autoComplete="organization" placeholder="Company" data-testid="companyName" />
      </Form.Item>
      <InputLabel>
        <span>
          First name<Asterisk>*</Asterisk>
        </span>
        <Tooltip
          position="top"
          title="Your first name is needed for salutation and billing if you upgrade to a paid plan."
          trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: 'Please input your first name.',
          },
        ]}>
        <Input prefix={<UserOutlined />} autoComplete="given-name" placeholder="First name" data-testid="firstName" />
      </Form.Item>
      <InputLabel>
        <span>
          Last name<Asterisk>*</Asterisk>
        </span>
        <Tooltip
          position="top"
          title="Your last name is needed for salutation and billing if you upgrade to a paid plan."
          trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            message: 'Please input your last name.',
          },
        ]}>
        <Input prefix={<UserOutlined />} autoComplete="family-name" placeholder="Last name" data-testid="lastName" />
      </Form.Item>
      <InputLabel>
        <span>Phone number</span>
        <Tooltip
          position="top"
          title="You can enter your phone number, so that we can reach back to you in case you need help."
          trigger="mouseenter">
          <InfoIcon>i</InfoIcon>
        </Tooltip>
      </InputLabel>
      <Form.Item name={'contactPhone'} rules={[...phoneNumberValidationRules]}>
        <PhoneInputFormItem placeholder="Phone number" defaultCountry="DE" dataTestId="contactPhone" />
      </Form.Item>
      <Form.Item name="termsAndConditions" valuePropName="checked">
        <Checkbox data-testid="termsAndConditions">
          I agree to the
          <a href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
            {' '}
            Terms & Conditions{' '}
          </a>
          of Next Logistics Ventures GmbH.
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="dpa"
        valuePropName="checked"
        hasFeedback
        dependencies={['termsAndConditions']}
        validateDebounce={100000}
        rules={[
          ({getFieldValue}) => ({
            validator(_, value) {
              if (value && getFieldValue('termsAndConditions')) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  'You must agree to the Terms & Conditions and the Data Processing Agreement of Next Logistics Ventures GmbH to complete your transaction.'
                )
              );
            },
          }),
        ]}>
        <Checkbox data-testid="dataProcessingAgreement">
          I agree to the
          <a href={DPA_URL} target="_blank" rel="noreferrer">
            {' '}
            Data Processing Agreement{' '}
          </a>
          of Next Logistics Ventures GmbH.
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <CtaButton
          type="primary"
          htmlType="submit"
          size="large"
          disabled={isSubmitting}
          loading={isSubmitting}
          data-testid="Submit">
          Sign up
        </CtaButton>
      </Form.Item>
    </div>
  );
};
