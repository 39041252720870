import {Form} from 'antd';
import styled from 'styled-components';

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    width: 100%;

    & > label {
      color: var(--color-gray-2);
    }
  }
`;
