import dayjs from 'dayjs';
import {FC} from 'react';
import styled from 'styled-components';
import {ExtLinkIcon} from '../../../components/ExtLinkIcon/ExtLinkIcon';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {MapCard} from '../MapCard/MapCard';
import {portMetadata} from '../PortCongestionScreen/utils/portMetadata';
import {useCongestionVisitsWithVesselInformationsQuery} from '../PortCongestionScreen/utils/useCongestionVisitsWithVesselInformationsQuery';
import {PortKey} from '../PortCongestionScreen/utils/usePortCongestionQuery';

type PortCongestionCardProps = {
  portKey: PortKey;
};

export const PortCongestionCard: FC<PortCongestionCardProps> = ({portKey}) => {
  const portCongestionQuery = useCongestionVisitsWithVesselInformationsQuery(portKey, {});
  const {title, centerPoint, zoom} = portMetadata[portKey];

  if (!portCongestionQuery.isSuccess) {
    return (
      <MapCard
        data-testid="PortCongestionCard"
        url={`/lab/congestion/port/${portKey}`}
        isLoading={true}
        centerPoint={centerPoint}
        mapStyle={'SAT'}
        zoom={zoom}>
        <TileTitle>{portCongestionQuery.isError ? `Error retrieving ${title}.` : `${title} loading...`}</TileTitle>
      </MapCard>
    );
  }

  const {currentVisits, queryTimestamp} = portCongestionQuery.data;

  return (
    <MapCard
      testId={'PortCongestionCard'}
      url={`/lab/congestion/port/${portKey}`}
      currentVisits={currentVisits}
      centerPoint={centerPoint}
      mapStyle={'SAT'}
      zoom={zoom}>
      <TileTitle>
        {title}
        <ExtLinkIcon />
      </TileTitle>
      <SubTitleContainer>
        <StatusLabel></StatusLabel>
      </SubTitleContainer>
      <FooterContainer data-testid="lastUpdated">
        last updated: {dayjs(queryTimestamp).format(DateTimeFormat.DateTime)}
      </FooterContainer>
    </MapCard>
  );
};

const TileTitle = styled.h2`
  width: 100%;
  margin: 0 0 5px;
  color: var(--color-white);
  font-size: var(--font-size-lg);
  font-weight: bold;
  text-shadow: 0 0 15px var(--color-black);
`;

const SubTitleContainer = styled.div`
  width: 100%;
  margin: 0 0 auto;
  color: var(--color-white);
`;

const StatusLabel = styled.p`
  margin: 0 0 5px;
  font-weight: bold;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin: auto 0 0;
  color: var(--color-gray-3);
  text-shadow: 0 0 2px var(--color-black);
`;
