import {AccountSegmentsSettings} from '../../../components/AccountSegmentsSelect/AccountSegmentsSettings';
import {OnboardingStepWrapper} from '../OnboardingStepWrapper';
import {OnboardingStepProps} from '../useUserOnboarding';
import {Card} from 'antd';

export const SetupSegments = (props: OnboardingStepProps) => {
  return (
    <OnboardingStepWrapper
      title="Setup your personal profile"
      subTitle="You can set up your preferences now to receive personalized results within your seabo experience."
      dataTestId="SetupSegments"
      {...props}>
      <Card style={{width: '100%'}}>
        <AccountSegmentsSettings type="steps" onSave={() => props.onNext?.()} />
      </Card>
    </OnboardingStepWrapper>
  );
};
