/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ConnectedState,
  ConnectedStateFromJSON,
  ConnectedStateFromJSONTyped,
  ConnectedStateToJSON,
  Reference,
  ReferenceFromJSON,
  ReferenceFromJSONTyped,
  ReferenceToJSON,
} from './';

/**
 *
 * @export
 * @interface UserCard
 */
export interface UserCard {
  /**
   *
   * @type {number}
   * @memberof UserCard
   */
  userID: number;
  /**
   *
   * @type {string}
   * @memberof UserCard
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserCard
   */
  avatar: string;
  /**
   *
   * @type {Reference}
   * @memberof UserCard
   */
  company?: Reference | null;
  /**
   *
   * @type {ConnectedState}
   * @memberof UserCard
   */
  currentUserFriendStatus: ConnectedState;
  /**
   *
   * @type {string}
   * @memberof UserCard
   */
  email: string | null;
}

export function UserCardFromJSON(json: any): UserCard {
  return UserCardFromJSONTyped(json, false);
}

export function UserCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCard {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userID: json['userID'],
    fullName: json['fullName'],
    avatar: json['avatar'],
    company: !exists(json, 'company') ? undefined : ReferenceFromJSON(json['company']),
    currentUserFriendStatus: ConnectedStateFromJSON(json['currentUserFriendStatus']),
    email: json['email'],
  };
}

export function UserCardToJSON(value?: UserCard | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userID: value.userID,
    fullName: value.fullName,
    avatar: value.avatar,
    company: ReferenceToJSON(value.company),
    currentUserFriendStatus: ConnectedStateToJSON(value.currentUserFriendStatus),
    email: value.email,
  };
}
