import Icon from '../Icon';
import React, {FC} from 'react';
import styled from 'styled-components';

type SearchIndicatorProps = {
  onClick?: () => void;
  innerProps: React.HTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
};

export const SearchIndicator: FC<SearchIndicatorProps> = ({
  children = <Icon type="search" />,
  innerProps,
  onClick = () => {},
}) => (
  <div {...innerProps} style={{display: 'flex', justifyContent: 'center'}} onClick={onClick}>
    {children}
  </div>
);

type MultiValueRemoveProps = {
  innerProps: React.HTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
};

export const MultiValueRemove: FC<MultiValueRemoveProps> = ({
  children = <Icon type="clear" size={'small'} centered />,
  innerProps,
}) => (
  <div {...innerProps} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    {children}
  </div>
);

type ArrowIndicatorProps = {
  innerProps: React.HTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
};

export const ArrowIndicator: FC<ArrowIndicatorProps> = ({
  children = <Icon type="angle-down" centered />,
  innerProps,
}) => (
  <div {...innerProps} style={{display: 'flex', justifyContent: 'center'}}>
    {children}
  </div>
);

type ClearIndicatorProps = {
  innerProps: React.HTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
};

export const ClearIndicator: FC<ClearIndicatorProps> = ({
  children = <StyledDeleteIcon type="clear" color={'light-gray'} size={'medium-small'} centered hovered />,
  innerProps,
}) => (
  <div
    {...innerProps}
    onMouseDown={e => {
      e.stopPropagation();
      innerProps.onMouseDown?.(e);
    }}
    className={'seabo-react-select__clear-indicator'}
    style={{display: 'flex', justifyContent: 'center'}}>
    {children}
  </div>
);

const StyledDeleteIcon = styled(Icon)`
  display: flex;
  align-items: center;
`;
