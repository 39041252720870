import {FC} from 'react';
import styled from 'styled-components';
import {NegotiationCard} from '../Components/shared';
import {NegotiationSummary} from '../Components/NegotiationSummary';
import {NegotiationStatus, NegotiationSummary as NegotiationSummaryType} from '../../../api/node-backend/generated';
import {NegotiationSummaryHeader} from '../Components/NegotiationSummaryHeader';
import {useFindRoundResultsByNegotiationIdQuery} from '../NegotiationDetail/utils/useFindRoundResultsByNegotiationIdQuery';
import {useGetRoundResultModificationsQuery} from '../NegotiationDetail/utils/useGetRoundResultModificationsQuery';

export const NegotiationPreviewCard: FC<{negotiation: NegotiationSummaryType}> = ({negotiation}) => {
  const roundResultSummariesQuery = useFindRoundResultsByNegotiationIdQuery(negotiation.id, {
    enabled: !!negotiation.id,
  });
  const roundResultSummaries = roundResultSummariesQuery.data;

  const roundResultModificationsQuery = useGetRoundResultModificationsQuery(negotiation.latestRound.id);
  const roundResultModifications = roundResultModificationsQuery.data;

  const {status} = negotiation;
  const isFirstRecapRound =
    roundResultModifications?.newNegotiationStatus === NegotiationStatus.Ongoing && status === NegotiationStatus.Recap;

  return (
    <StyledNegotiationCard data-testid="NegotiationPreviewCard" $color={'gray-3'}>
      <CardHeader>
        <NegotiationSummaryHeader
          negotiation={negotiation}
          roundResultSummaries={roundResultSummaries}
          isFirstRecapRound={isFirstRecapRound}
        />
      </CardHeader>
      <CardBody>
        <NegotiationSummary
          negotiation={negotiation}
          roundResultSummaries={roundResultSummaries}
          isFirstRecapRound={isFirstRecapRound}
        />
      </CardBody>
    </StyledNegotiationCard>
  );
};

const StyledNegotiationCard = styled(NegotiationCard)<{$color: string}>`
  position: relative;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;

  .ant-card-body {
    padding: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--border-radius-card);
    height: 100%;
    background-color: var(--color-${({$color}) => $color});
  }
`;

const CardHeader = styled.div`
  padding: 10px 15px 10px calc(15px + var(--border-radius-card));
  border-bottom: var(--border-base);
`;

const CardBody = styled.div`
  padding: 10px 15px 10px calc(15px + var(--border-radius-card));
`;
