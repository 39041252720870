import {FC, useMemo} from 'react';

export const WebGlDetection: FC<{children: React.ReactNode}> = ({children}) => {
  const webGLAvailable = useMemo(isWebGLAvailable, []);
  return webGLAvailable ? <>{children}</> : <WebGlNotAvailable />;
};

const isWebGLAvailable = (): boolean => {
  try {
    const canvas = document.createElement('canvas');
    return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
  } catch (e) {
    return false;
  }
};

const WebGlNotAvailable: FC = () => (
  <div className="seabo-map seabo-map--error">
    <div>
      <h2>The map cannot be displayed.</h2>
      Your browser seems not to support WebGL which is required to display the map.
      <br />
      Please check your browser configuration or contact your helpdesk.
    </div>
  </div>
);
