/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselBuiltYearDwt
 */
export interface VesselBuiltYearDwt {
  /**
   *
   * @type {number}
   * @memberof VesselBuiltYearDwt
   */
  builtYear: number;
  /**
   *
   * @type {number}
   * @memberof VesselBuiltYearDwt
   */
  dwt: number;
}

export function VesselBuiltYearDwtFromJSON(json: any): VesselBuiltYearDwt {
  return VesselBuiltYearDwtFromJSONTyped(json, false);
}

export function VesselBuiltYearDwtFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselBuiltYearDwt {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    builtYear: json['builtYear'],
    dwt: json['dwt'],
  };
}

export function VesselBuiltYearDwtToJSON(value?: VesselBuiltYearDwt | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    builtYear: value.builtYear,
    dwt: value.dwt,
  };
}
