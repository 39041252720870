import {Coordinates} from '../../../../../utils/Coordinates';
import ngeohash from 'ngeohash';

export const getGeohash = (coords: Coordinates): string => {
  return ngeohash.encode(coords[1], coords[0], 4);
};

export const getRouteKey = (fromGeohash: string, toGeohash: string) =>
  `${fromGeohash.slice(0, 4)}_${toGeohash.slice(0, 4)}`;

export const getGeoHashesFromRouteKey = (routeKey: string) => {
  const geohashes = routeKey.split('_');
  return {
    fromGeohash: geohashes[0],
    toGeohash: geohashes[1],
  };
};
