import {CompositeLayer} from '@deck.gl/core/typed';
import {
  CAP_LEFT_SYMBOL,
  CAP_RIGHT_SYMBOL,
  CARGO_SYMBOL,
  VESSEL_SYMBOL,
} from '../../../../components/SeaboMap/layers/const';
import {DEFAULT_CHAR_SET} from '../../../../components/SeaboMap/layers/helper/BgFontAtlasManager';
import BackgroundLayerJS from './BackgroundLayer';
import ForegroundTextLayerJS from './ForegroundTextLayer';
import {TODO} from '../../../../utils/TODO';
import {Coordinates} from '../../../../utils/Coordinates';

const BackgroundLayer = BackgroundLayerJS as TODO;
const ForegroundTextLayer = ForegroundTextLayerJS as TODO;

type Props = {
  id?: string;
  getPickingInfo?: (obj: TODO) => TODO;
  data: TODO[];
  isShowMarketVessels: boolean;
  isShowMarketCargoes: boolean;
  getSize: number;
  opacity: number;
  pickable: boolean;
  visible: boolean;
  getPixelOffset: [number, number];
  getPosition: (obj: TODO) => Coordinates;
  getPolygonOffset: (obj: TODO) => [number, number];
  getItemAmount: (object: TODO) => {amountVessels: number; amountCargoes: number};
};

export class AggregateCircularsLayer extends CompositeLayer<Props> {
  static layerName = 'AggregateCircularsLayer';

  getPickingInfo(obj: TODO) {
    return this.props.getPickingInfo?.(obj);
  }
  renderLayers() {
    const {data, isShowMarketVessels, isShowMarketCargoes} = this.props;
    const getText = (object: TODO) => {
      const {amountVessels, amountCargoes} = this.props.getItemAmount(object);

      const vesselPart = `${VESSEL_SYMBOL}     ${amountVessels}`;

      const cargoPart = `${CARGO_SYMBOL}  ${amountCargoes}`;

      const connectPart = '      ';

      return `${isShowMarketVessels ? vesselPart : ''}${isShowMarketCargoes && isShowMarketVessels ? connectPart : ''}${
        isShowMarketCargoes ? cargoPart : ''
      }`;
    };

    if (isShowMarketVessels || isShowMarketCargoes) {
      return [
        new BackgroundLayer({
          ...this.props,
          id: `${this.props.id}-background-bottom`,
          capLeftSymbol: CAP_LEFT_SYMBOL,
          capRightSymbol: CAP_RIGHT_SYMBOL,
          characterSet: DEFAULT_CHAR_SET,
          fontFamily: 'Akkurat, monospace',
          data,
          isShowMarketVessels,
          isShowMarketCargoes,
          clearSeparator: false,
          getColor: [8, 97, 180],
          getText,
          getSize: 16,
        }),
        new ForegroundTextLayer({
          ...this.props,
          id: `${this.props.id}-text`,
          fontFamily: 'Akkurat, monospace',
          data,
          getColor: [255, 255, 255],
          getText,
          getSize: 16,
        }),
      ];
    }
    return [];
  }
}
