/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  Account,
  AccountFromJSON,
  AccountToJSON,
  AccountCreateRequest,
  AccountCreateRequestFromJSON,
  AccountCreateRequestToJSON,
  AccountList,
  AccountListFromJSON,
  AccountListToJSON,
  AccountSegments,
  AccountSegmentsFromJSON,
  AccountSegmentsToJSON,
  ContactDetails,
  ContactDetailsFromJSON,
  ContactDetailsToJSON,
  ModifyAccount,
  ModifyAccountFromJSON,
  ModifyAccountToJSON,
  Settings,
  SettingsFromJSON,
  SettingsToJSON,
  SortDirection,
  SortDirectionFromJSON,
  SortDirectionToJSON,
  UpdateUserOnboardingState,
  UpdateUserOnboardingStateFromJSON,
  UpdateUserOnboardingStateToJSON,
  ValidationProblem,
  ValidationProblemFromJSON,
  ValidationProblemToJSON,
} from '../models';

export interface CreateAccountRequest {
  accountCreateRequest: AccountCreateRequest;
}

export interface GetAccountDetailsRequest {
  id: number;
}

export interface ListAccountsRequest {
  page?: number;
  sortField?: ListAccountsSortFieldEnum;
  sortOrder?: SortDirection;
  search?: string;
  email?: string;
  companyId?: number;
}

export interface ModifyAccountRequest {
  id: number;
  accountCreateRequest: AccountCreateRequest;
}

export interface UpdateAccountRequest {
  modifyAccount: ModifyAccount;
}

export interface UpdateAccountSegmentsRequest {
  accountSegments: AccountSegments;
}

export interface UpdateAvatarRequest {
  file?: Blob;
}

export interface UpdateContactDetailsRequest {
  contactDetails: ContactDetails;
}

export interface UpdateSettingsRequest {
  settings: Settings;
}

export interface UpdateUserOnboardingStateRequest {
  updateUserOnboardingState: UpdateUserOnboardingState;
}

/**
 *
 */
export class AccountManagementApi extends runtime.BaseAPI {
  /**
   * Create a new user account.
   */
  async createAccountRaw(requestParameters: CreateAccountRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.accountCreateRequest === null || requestParameters.accountCreateRequest === undefined) {
      throw new runtime.RequiredError(
        'accountCreateRequest',
        'Required parameter requestParameters.accountCreateRequest was null or undefined when calling createAccount.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/accounts`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AccountCreateRequestToJSON(requestParameters.accountCreateRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create a new user account.
   */
  async createAccount(requestParameters: CreateAccountRequest): Promise<void> {
    await this.createAccountRaw(requestParameters);
  }

  /**
   * Detailed information on a specific user account.
   */
  async getAccountDetailsRaw(requestParameters: GetAccountDetailsRequest): Promise<runtime.ApiResponse<Account>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAccountDetails.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/accounts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AccountFromJSON(jsonValue));
  }

  /**
   * Detailed information on a specific user account.
   */
  async getAccountDetails(requestParameters: GetAccountDetailsRequest): Promise<Account> {
    const response = await this.getAccountDetailsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getAccountSegmentsRaw(): Promise<runtime.ApiResponse<AccountSegments>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/segments`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AccountSegmentsFromJSON(jsonValue));
  }

  /**
   */
  async getAccountSegments(): Promise<AccountSegments> {
    const response = await this.getAccountSegmentsRaw();
    return await response.value();
  }

  /**
   * Fetch the current user\'s contact details.
   */
  async getContactDetailsRaw(): Promise<runtime.ApiResponse<ContactDetails>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/contact-details`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ContactDetailsFromJSON(jsonValue));
  }

  /**
   * Fetch the current user\'s contact details.
   */
  async getContactDetails(): Promise<ContactDetails> {
    const response = await this.getContactDetailsRaw();
    return await response.value();
  }

  /**
   * Get the current user\'s account settings.
   */
  async getSettingsRaw(): Promise<runtime.ApiResponse<Settings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/settings`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => SettingsFromJSON(jsonValue));
  }

  /**
   * Get the current user\'s account settings.
   */
  async getSettings(): Promise<Settings> {
    const response = await this.getSettingsRaw();
    return await response.value();
  }

  /**
   * List all user accounts.
   */
  async listAccountsRaw(requestParameters: ListAccountsRequest): Promise<runtime.ApiResponse<AccountList>> {
    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters['sortField'] = requestParameters.sortField;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.companyId !== undefined) {
      queryParameters['companyId'] = requestParameters.companyId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/accounts`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AccountListFromJSON(jsonValue));
  }

  /**
   * List all user accounts.
   */
  async listAccounts(requestParameters: ListAccountsRequest): Promise<AccountList> {
    const response = await this.listAccountsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Modify a user account.
   */
  async modifyAccountRaw(requestParameters: ModifyAccountRequest): Promise<runtime.ApiResponse<Account>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling modifyAccount.'
      );
    }

    if (requestParameters.accountCreateRequest === null || requestParameters.accountCreateRequest === undefined) {
      throw new runtime.RequiredError(
        'accountCreateRequest',
        'Required parameter requestParameters.accountCreateRequest was null or undefined when calling modifyAccount.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/accounts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: AccountCreateRequestToJSON(requestParameters.accountCreateRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => AccountFromJSON(jsonValue));
  }

  /**
   * Modify a user account.
   */
  async modifyAccount(requestParameters: ModifyAccountRequest): Promise<Account> {
    const response = await this.modifyAccountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Modify the current account.
   */
  async updateAccountRaw(requestParameters: UpdateAccountRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.modifyAccount === null || requestParameters.modifyAccount === undefined) {
      throw new runtime.RequiredError(
        'modifyAccount',
        'Required parameter requestParameters.modifyAccount was null or undefined when calling updateAccount.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/modify-account`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ModifyAccountToJSON(requestParameters.modifyAccount),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Modify the current account.
   */
  async updateAccount(requestParameters: UpdateAccountRequest): Promise<void> {
    await this.updateAccountRaw(requestParameters);
  }

  /**
   */
  async updateAccountSegmentsRaw(requestParameters: UpdateAccountSegmentsRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.accountSegments === null || requestParameters.accountSegments === undefined) {
      throw new runtime.RequiredError(
        'accountSegments',
        'Required parameter requestParameters.accountSegments was null or undefined when calling updateAccountSegments.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/segments`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: AccountSegmentsToJSON(requestParameters.accountSegments),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async updateAccountSegments(requestParameters: UpdateAccountSegmentsRequest): Promise<void> {
    await this.updateAccountSegmentsRaw(requestParameters);
  }

  /**
   * Change the current user\'s avatar picture.
   */
  async updateAvatarRaw(requestParameters: UpdateAvatarRequest): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{contentType: 'multipart/form-data'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request({
      path: `/api/account-management/avatar`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Change the current user\'s avatar picture.
   */
  async updateAvatar(requestParameters: UpdateAvatarRequest): Promise<void> {
    await this.updateAvatarRaw(requestParameters);
  }

  /**
   * Update the current user\'s contact details.
   */
  async updateContactDetailsRaw(
    requestParameters: UpdateContactDetailsRequest
  ): Promise<runtime.ApiResponse<ContactDetails>> {
    if (requestParameters.contactDetails === null || requestParameters.contactDetails === undefined) {
      throw new runtime.RequiredError(
        'contactDetails',
        'Required parameter requestParameters.contactDetails was null or undefined when calling updateContactDetails.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/contact-details`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ContactDetailsToJSON(requestParameters.contactDetails),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ContactDetailsFromJSON(jsonValue));
  }

  /**
   * Update the current user\'s contact details.
   */
  async updateContactDetails(requestParameters: UpdateContactDetailsRequest): Promise<ContactDetails> {
    const response = await this.updateContactDetailsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Modify the current user\'s account settings.
   */
  async updateSettingsRaw(requestParameters: UpdateSettingsRequest): Promise<runtime.ApiResponse<Settings>> {
    if (requestParameters.settings === null || requestParameters.settings === undefined) {
      throw new runtime.RequiredError(
        'settings',
        'Required parameter requestParameters.settings was null or undefined when calling updateSettings.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/settings`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: SettingsToJSON(requestParameters.settings),
    });

    return new runtime.JSONApiResponse(response, jsonValue => SettingsFromJSON(jsonValue));
  }

  /**
   * Modify the current user\'s account settings.
   */
  async updateSettings(requestParameters: UpdateSettingsRequest): Promise<Settings> {
    const response = await this.updateSettingsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateUserOnboardingStateRaw(
    requestParameters: UpdateUserOnboardingStateRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.updateUserOnboardingState === null ||
      requestParameters.updateUserOnboardingState === undefined
    ) {
      throw new runtime.RequiredError(
        'updateUserOnboardingState',
        'Required parameter requestParameters.updateUserOnboardingState was null or undefined when calling updateUserOnboardingState.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/account-management/update-onboarding-state`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateUserOnboardingStateToJSON(requestParameters.updateUserOnboardingState),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async updateUserOnboardingState(requestParameters: UpdateUserOnboardingStateRequest): Promise<void> {
    await this.updateUserOnboardingStateRaw(requestParameters);
  }
}

/**
 * @export
 * @enum {string}
 */
export enum ListAccountsSortFieldEnum {
  LastName = 'lastName',
  RegistrationDate = 'registrationDate',
  LastActivity = 'lastActivity',
}
