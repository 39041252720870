import styled from 'styled-components';
import {ClockCircleOutlined} from '@ant-design/icons';
import {HeaderBox} from './HeaderBox';
import {useContext, FC} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {formatWithThousandSeperator} from '../../../../utils/formatWithThousandSeperator';

const ValueSpan = styled.span`
  color: var(--color-black);
  font-size: var(--font-size-lg);
  font-weight: 700;
  padding-top: 5px;

  .ant-affix & {
    font-size: var(--font-size-lg);
  }
`;

export const CO2EmissionBox: FC = () => {
  const voyageCalcContext = useContext(VoyageCharterContext);

  const emission = voyageCalcContext.state.outputState.voyage.cO2Emission;
  return (
    <HeaderBox
      title="CO2 - Emission"
      isMarginRight={true}
      renderIcon={color => <ClockCircleOutlined style={{fontSize: 18, color}} />}>
      <ValueSpan data-cy={'co2'}>{formatWithThousandSeperator(emission)} mts</ValueSpan>
    </HeaderBox>
  );
};
