import React, {CSSProperties, FC, useMemo} from 'react';
import Loading from '../../../atoms/Loading';
import {calculateLastDestinations} from './calculateLastDestinations';
import {VoyageTimelineList} from '../VoyageTimeline/VoyageTimelineList';
import {useGetVoyageTimelineQuery} from '../VoyageTimeline/utils/useGetVoyageTimelineQuery';
import dayjs from 'dayjs';

type LastDestinationsProps = {
  imo?: number | null;
  style?: CSSProperties;
};

export const LastDestinations: FC<LastDestinationsProps> = ({imo, style}) => {
  const voyageTimelineQuery = useGetVoyageTimelineQuery(imo, [dayjs().subtract(3, 'years'), dayjs()]);
  const {lastDestinations} = useMemo(
    () => calculateLastDestinations(voyageTimelineQuery.data),
    [voyageTimelineQuery.data]
  );

  if (voyageTimelineQuery.isLoading) {
    return <Loading />;
  }

  return <VoyageTimelineList isReduced voyageTimelineItems={lastDestinations} style={style} />;
};
