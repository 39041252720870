import {TODO} from '../../utils/TODO';
import {makeApiService} from './ApiService';

type Data = Record<
  TODO,
  {
    items: TODO[];
    searchTerm: string;
  }
> & {
  items: TODO[];
};

interface Options extends TODO {
  port?: boolean;
  country?: boolean;
  area?: boolean;
}

const api = makeApiService(
  [
    {
      fn: 'search',
      route: '/api/search',
      method: 'GET',
      abortPrevCalls: true,
    },
  ],
  {apiName: 'location'}
);

export const actions = api.actions;
export const reducers = api.reducers;
export const actionTypes = api.actionTypes;

const getExactMatch = (data: Data, type: TODO, options?: Options) => {
  const {port = true, country = true, area = true} = options || {};
  const possibleLocations = [];
  if (port) {
    possibleLocations.push('port');
  }
  if (country) {
    possibleLocations.push('country');
  }
  if (area) {
    possibleLocations.push('tradingarea');
    type !== 'vc' && possibleLocations.push('collection');
  }

  const result = possibleLocations.map(
    k =>
      data[k].items.find(i => {
        if (i._source.name.toLowerCase() === data[k].searchTerm.toLowerCase()) {
          return true;
        }
        return i._source.code ? i._source.code.toLowerCase() === data[k].searchTerm.toLowerCase() : false;
      })?._source
  );

  return {
    label: 'Exact match',
    options: (result || []).filter(r => r),
  };
};

export const LocationServiceActions = {
  searchAll: (query: TODO, type: TODO = null, params: TODO = {}) =>
    actions.search({
      queryParams: {query, indexName: 'locations', ...params},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => [
          {...getExactMatch(data, type)},
          {
            label: 'Port',
            options:
              data.port && data.port.items
                ? data.port.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
          {
            label: 'Country',
            options:
              data.country && data.country.items
                ? data.country.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
          {
            label: 'Area',
            options:
              data.tradingarea && data.tradingarea.items
                ? data.tradingarea.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
          {
            label: 'Area range',
            options:
              type !== 'vc' && data.collection && data.collection.items
                ? data.collection.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
        ],
      },
    }),
  searchCountryOrArea: (query: TODO, type: TODO = null, params: TODO = {}) =>
    actions.search({
      queryParams: {query, indexName: 'locations', ...params},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => [
          {...getExactMatch(data, type, {port: false})},
          {
            label: 'Country',
            options:
              data.country && data.country.items
                ? data.country.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
          {
            label: 'Area',
            options:
              data.tradingarea && data.tradingarea.items
                ? data.tradingarea.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
          {
            label: 'Area range',
            options:
              type !== 'vc' && data.collection && data.collection.items
                ? data.collection.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
        ],
      },
    }),
  searchAreaWithRange: (query: TODO, type = null, params = {}) =>
    actions.search({
      queryParams: {query, indexName: 'locations', ...params},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => [
          {...getExactMatch(data, type, {port: false, country: false})},
          {
            label: 'Area',
            options:
              data.tradingarea && data.tradingarea.items
                ? data.tradingarea.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
          {
            label: 'Area range',
            options:
              type !== 'vc' && data.collection && data.collection.items
                ? data.collection.items.map(({highlight, _source}) => ({highlight, ..._source}))
                : [],
          },
        ],
      },
    }),
  searchPorts: (query: TODO) =>
    actions.search({
      queryParams: {query, indexName: 'location_port'},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => (data.items ? data.items.map(p => ({id: p._source.id, ...p._source})) : []),
      },
    }),
  searchTradingAreas: (query: TODO) =>
    actions.search({
      queryParams: {query, indexName: 'location_tradingArea'},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => (data.items ? data.items.map(p => ({id: p._source.id, ...p._source})) : []),
      },
    }),
};

export default LocationServiceActions;
