/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateOrUpdateNegotiationTemplateDTO,
  CreateOrUpdateNegotiationTemplateDTOFromJSON,
  CreateOrUpdateNegotiationTemplateDTOToJSON,
  NegotiationTemplate,
  NegotiationTemplateFromJSON,
  NegotiationTemplateToJSON,
  NegotiationTemplateSummary,
  NegotiationTemplateSummaryFromJSON,
  NegotiationTemplateSummaryToJSON,
} from '../models';

export interface CreateNegotiationTemplateRequest {
  createOrUpdateNegotiationTemplateDTO: CreateOrUpdateNegotiationTemplateDTO;
}

export interface DeleteNegotiationTemplateRequest {
  templateId: number;
}

export interface GetNegotiationTemplateRequest {
  templateId: number;
}

export interface UpdateNegotiationTemplateRequest {
  templateId: number;
  createOrUpdateNegotiationTemplateDTO: CreateOrUpdateNegotiationTemplateDTO;
}

/**
 *
 */
export class NegotiationNegotiationTemplateApi extends runtime.BaseAPI {
  /**
   */
  async createNegotiationTemplateRaw(
    requestParameters: CreateNegotiationTemplateRequest
  ): Promise<runtime.ApiResponse<NegotiationTemplate>> {
    if (
      requestParameters.createOrUpdateNegotiationTemplateDTO === null ||
      requestParameters.createOrUpdateNegotiationTemplateDTO === undefined
    ) {
      throw new runtime.RequiredError(
        'createOrUpdateNegotiationTemplateDTO',
        'Required parameter requestParameters.createOrUpdateNegotiationTemplateDTO was null or undefined when calling createNegotiationTemplate.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/templates`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateOrUpdateNegotiationTemplateDTOToJSON(requestParameters.createOrUpdateNegotiationTemplateDTO),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationTemplateFromJSON(jsonValue));
  }

  /**
   */
  async createNegotiationTemplate(requestParameters: CreateNegotiationTemplateRequest): Promise<NegotiationTemplate> {
    const response = await this.createNegotiationTemplateRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async deleteNegotiationTemplateRaw(
    requestParameters: DeleteNegotiationTemplateRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
      throw new runtime.RequiredError(
        'templateId',
        'Required parameter requestParameters.templateId was null or undefined when calling deleteNegotiationTemplate.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/templates/{templateId}`.replace(
        `{${'templateId'}}`,
        encodeURIComponent(String(requestParameters.templateId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteNegotiationTemplate(requestParameters: DeleteNegotiationTemplateRequest): Promise<void> {
    await this.deleteNegotiationTemplateRaw(requestParameters);
  }

  /**
   */
  async getNegotiationTemplateRaw(
    requestParameters: GetNegotiationTemplateRequest
  ): Promise<runtime.ApiResponse<NegotiationTemplate>> {
    if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
      throw new runtime.RequiredError(
        'templateId',
        'Required parameter requestParameters.templateId was null or undefined when calling getNegotiationTemplate.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/templates/{templateId}`.replace(
        `{${'templateId'}}`,
        encodeURIComponent(String(requestParameters.templateId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationTemplateFromJSON(jsonValue));
  }

  /**
   */
  async getNegotiationTemplate(requestParameters: GetNegotiationTemplateRequest): Promise<NegotiationTemplate> {
    const response = await this.getNegotiationTemplateRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getNegotiationTemplateSummariesRaw(): Promise<runtime.ApiResponse<Array<NegotiationTemplateSummary>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/templates`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(NegotiationTemplateSummaryFromJSON));
  }

  /**
   */
  async getNegotiationTemplateSummaries(): Promise<Array<NegotiationTemplateSummary>> {
    const response = await this.getNegotiationTemplateSummariesRaw();
    return await response.value();
  }

  /**
   */
  async updateNegotiationTemplateRaw(
    requestParameters: UpdateNegotiationTemplateRequest
  ): Promise<runtime.ApiResponse<NegotiationTemplate>> {
    if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
      throw new runtime.RequiredError(
        'templateId',
        'Required parameter requestParameters.templateId was null or undefined when calling updateNegotiationTemplate.'
      );
    }

    if (
      requestParameters.createOrUpdateNegotiationTemplateDTO === null ||
      requestParameters.createOrUpdateNegotiationTemplateDTO === undefined
    ) {
      throw new runtime.RequiredError(
        'createOrUpdateNegotiationTemplateDTO',
        'Required parameter requestParameters.createOrUpdateNegotiationTemplateDTO was null or undefined when calling updateNegotiationTemplate.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/templates/{templateId}`.replace(
        `{${'templateId'}}`,
        encodeURIComponent(String(requestParameters.templateId))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: CreateOrUpdateNegotiationTemplateDTOToJSON(requestParameters.createOrUpdateNegotiationTemplateDTO),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationTemplateFromJSON(jsonValue));
  }

  /**
   */
  async updateNegotiationTemplate(requestParameters: UpdateNegotiationTemplateRequest): Promise<NegotiationTemplate> {
    const response = await this.updateNegotiationTemplateRaw(requestParameters);
    return await response.value();
  }
}
