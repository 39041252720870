import {useSearchQuery} from '../SearchPicker/useSearchQuery';
import {SeaboVesselOption} from './Type';
import {makeSeaboVesselOption} from './makeOption/makeSeaboVesselOption';
import {SearchHit, VesselType} from '../../api/symfony/generated/models';

export type VesselPickerIndexName = 'ais_vessels' | 'vessels_portfolio' | 'vessels_market';

type Params = {
  searchTag: string;
  indexNames?: VesselPickerIndexName[] | VesselPickerIndexName;
  makeOption?: (searchHit: SearchHit) => SeaboVesselOption;
  vesselTypes?: VesselType[];
};

/**
 * Performs a search request for the given searchTag and returns
 * the result as options for an Antd <AutoComplete> component.
 */
export const useOptionsFromSeaboVesselSearch = (params: Params): SeaboVesselOption[] => {
  const {searchTag} = params;

  const indexNames = Array.isArray(params.indexNames) ? params.indexNames : [params.indexNames ?? 'seabo_vessels'];

  const indexNamesString = indexNames.join(',');

  const searchQuery = useSearchQuery({searchTag, indexNames: indexNamesString, vesselTypes: params.vesselTypes});
  if (!searchQuery.isSuccess) {
    return [];
  }
  const options: SeaboVesselOption[] = indexNames.flatMap(indexName => {
    return searchQuery.data.data.items[indexName].items.map(params.makeOption ?? makeSeaboVesselOption);
  });

  // To make AntD's autocomplete work, we need to have unique values
  // So we dedupe the options and keep the original values in option.name instead of option.value
  // see https://github.com/ant-design/ant-design/issues/31686
  const optionsWithValuesDeduped = dedupeOptionValues(options);

  return optionsWithValuesDeduped;
};

const dedupeOptionValues = (options: SeaboVesselOption[]): SeaboVesselOption[] => {
  const values: Record<string, number> = {};
  return options.map(option => {
    if (option.value in values) {
      values[option.value]++;
      option.value = `${option.value}_${values[option.value]}`;
    } else {
      values[option.value] = 1;
    }
    return option;
  });
};
