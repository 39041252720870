import {IconType} from '../../../../atoms/Icon';

export type WidgetIdentifiers = keyof typeof availableWidgetsRaw;

export interface AvailableWidget {
  identifier: WidgetIdentifiers;
  name: string;
  icon: IconType;
  description: string;
  minWidth: number;
  maxWidth: number;
  defaultWidth: number;
  minHeight: number;
  maxHeight: number;
  defaultHeight: number;
  resizeable: boolean;
  allowMultipleInstances: boolean;
}

const availableWidgetsRaw: Record<string, AvailableWidget> = {
  // ! News widget unavailable until somebody takes care of uploads
  // news: {
  //   identifier: 'news',
  //   name: 'Market Reports',
  //   icon: 'news',
  //   description: 'Market Reports',
  //   minWidth: 1,
  //   maxWidth: 2,
  //   defaultWidth: 2,
  //   minHeight: 1,
  //   maxHeight: 3,
  //   defaultHeight: 1,
  //   resizeable: true,
  //   allowMultipleInstances: false,
  // },
  tiedUpVessels: {
    identifier: 'tiedUpVessels',
    name: 'Tied up vessels',
    icon: 'table',
    description: 'Currently tied up vessels worldwide',
    minWidth: 1,
    maxWidth: 4,
    defaultWidth: 1,
    minHeight: 1,
    maxHeight: 1,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: true,
  },
  // twitter: {
  //   identifier: 'twitter',
  //   name: 'Twitter',
  //   icon: 'social-twitter',
  //   description: 'Twitter Widget',
  //   minWidth: 1,
  //   maxWidth: 2,
  //   defaultWidth: 2,
  //   minHeight: 1,
  //   maxHeight: 2,
  //   defaultHeight: 2,
  //   resizeable: true,
  //   allowMultipleInstances: true,
  // },
  clock: {
    identifier: 'clock',
    name: 'Clock',
    icon: 'time-outline',
    description: 'Clock Widget',
    minWidth: 1,
    maxWidth: 1,
    defaultWidth: 1,
    minHeight: 1,
    maxHeight: 1,
    defaultHeight: 1,
    resizeable: false,
    allowMultipleInstances: true,
  },
  weather: {
    identifier: 'weather',
    name: 'Weather',
    icon: 'weather',
    description: 'Weather Widget',
    minWidth: 2,
    maxWidth: 3,
    defaultWidth: 3,
    minHeight: 2,
    maxHeight: 2,
    defaultHeight: 2,
    resizeable: true,
    allowMultipleInstances: true,
  },
  marketCargo: {
    identifier: 'marketCargo',
    name: 'Market Cargo',
    icon: 'markets',
    description: 'Market Cargo Widget',
    minWidth: 2,
    maxWidth: 5,
    defaultWidth: 4,
    minHeight: 1,
    maxHeight: 2,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: true,
  },
  marketVessel: {
    identifier: 'marketVessel',
    name: 'Market Vessel',
    icon: 'markets',
    description: 'Market Vessel Widget',
    minWidth: 2,
    maxWidth: 4,
    defaultWidth: 4,
    minHeight: 1,
    maxHeight: 2,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: true,
  },
  portfolioCargo: {
    identifier: 'portfolioCargo',
    name: 'My Cargoes',
    icon: 'portfolio',
    description: 'My Cargoes Widget',
    minWidth: 2,
    maxWidth: 5,
    defaultWidth: 3,
    minHeight: 1,
    maxHeight: 3,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: true,
  },
  nextOpenVessels: {
    identifier: 'nextOpenVessels',
    name: 'Next Open Vessels',
    icon: 'portfolio',
    description: 'Next Open Vessel Widget',
    minWidth: 2,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 2,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: true,
  },
  portfolioVessel: {
    identifier: 'portfolioVessel',
    name: 'My Fleet',
    icon: 'portfolio',
    description: 'My Fleet Widget',
    minWidth: 2,
    maxWidth: 5,
    defaultWidth: 3,
    minHeight: 1,
    maxHeight: 3,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: true,
  },
  worktray: {
    identifier: 'worktray',
    name: 'Projects',
    icon: 'work-tray',
    description: 'Projects',
    minWidth: 1,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 1,
    defaultHeight: 1,
    resizeable: false,
    allowMultipleInstances: false,
  },
  circularStats: {
    identifier: 'circularStats',
    name: 'Circular Stats',
    icon: 'table-grid',
    description: 'circular stats',
    minWidth: 2,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 1,
    defaultHeight: 1,
    resizeable: false,
    allowMultipleInstances: true,
  },
  circularStatsHighscore: {
    identifier: 'circularStatsHighscore',
    name: 'Circular Highscores',
    icon: 'table',
    description: 'Circular Highscores',
    minWidth: 1,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 3,
    defaultHeight: 2,
    resizeable: true,
    allowMultipleInstances: true,
  },
  bunkerPrice: {
    identifier: 'bunkerPrice',
    name: 'Bunkerprice',
    icon: 'stock',
    description: 'Bunkerprices Widget',
    minWidth: 2,
    maxWidth: 3,
    defaultWidth: 3,
    minHeight: 2,
    maxHeight: 3,
    defaultHeight: 2,
    resizeable: true,
    allowMultipleInstances: false,
  },
  sanctionsList: {
    identifier: 'sanctionsList',
    name: 'Sanctions List',
    icon: 'block',
    description: 'Sanctions List',
    minWidth: 2,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 2,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: false,
  },
  holiday: {
    identifier: 'holiday',
    name: 'Holidays',
    icon: 'date',
    description: 'Holidays',
    minWidth: 1,
    maxWidth: 5,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 2,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: true,
  },
  covid19: {
    identifier: 'covid19',
    name: 'Covid-19',
    icon: 'table',
    description: 'Covid-19',
    minWidth: 2,
    maxWidth: 5,
    defaultWidth: 3,
    minHeight: 2,
    maxHeight: 3,
    defaultHeight: 2,
    resizeable: true,
    allowMultipleInstances: true,
  },
  // ! Temporarily removed until interaction with fixture modal is corrected
  // fixtureHelper: {
  //   identifier: 'fixtureHelper',
  //   name: 'Fixture Helper',
  //   icon: 'tools',
  //   description: 'fixture helper',
  //   minWidth: 1,
  //   maxWidth: 2,
  //   defaultWidth: 2,
  //   minHeight: 1,
  //   maxHeight: 1,
  //   defaultHeight: 1,
  //   resizeable: false,
  //   allowMultipleInstances: false,
  // },
  parser: {
    identifier: 'parser',
    name: 'Mail Parser',
    icon: 'sent',
    description: 'Mail Parser Widget',
    minWidth: 1,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 2,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: false,
  },
  newVessel: {
    identifier: 'newVessel',
    name: 'List: New Vessels',
    icon: 'vessel-complex',
    description: 'List: New Vessels',
    minWidth: 2,
    maxWidth: 5,
    defaultWidth: 3,
    minHeight: 1,
    maxHeight: 3,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: false,
  },
  bigNumbersNewVessels: {
    identifier: 'bigNumbersNewVessels',
    name: 'New Vessels in database',
    icon: 'vessel-complex',
    description: 'New Vessels',
    minWidth: 2,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 1,
    defaultHeight: 1,
    resizeable: false,
    allowMultipleInstances: false,
  },
  scrappedVessel: {
    identifier: 'scrappedVessel',
    name: 'List: Scrapped Vessels',
    icon: 'vessel-disabled',
    description: 'Scrapped Vessels Widget in grid view',
    minWidth: 2,
    maxWidth: 5,
    defaultWidth: 3,
    minHeight: 1,
    maxHeight: 3,
    defaultHeight: 1,
    resizeable: true,
    allowMultipleInstances: false,
  },
  bigNumbersScrappedVessels: {
    identifier: 'bigNumbersScrappedVessels',
    name: 'Scrapped Vessels',
    icon: 'vessel-disabled',
    description: 'Scrapped Vessels',
    minWidth: 2,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 1,
    defaultHeight: 1,
    resizeable: false,
    allowMultipleInstances: false,
  },
  /*
  Deactivated but not removed widgets.
  See: https://seanexxt.atlassian.net/browse/FRIN-5593
  stockWidget: {
    identifier: 'stockWidget',
    name: 'Stock',
    icon: 'stock',
    description: 'Stock widget',
    minWidth: 2,
    maxWidth: 2,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 3,
    defaultHeight: 2,
    resizeable: false,
    allowMultipleInstances: false,
  },
  stockTickerWidget: {
    identifier: 'stockTickerWidget',
    name: 'Stock ticker',
    icon: 'stock',
    description: 'Stock ticker widget',
    minWidth: 2,
    maxWidth: 4,
    defaultWidth: 2,
    minHeight: 1,
    maxHeight: 1,
    defaultHeight: 1,
    resizeable: false,
    allowMultipleInstances: false,
  },
  globalExportsWidget: {
    identifier: 'globalExportsWidget',
    name: 'Global exports',
    icon: 'stock',
    description: 'Global exports widget',
    minWidth: 2,
    maxWidth: 4,
    defaultWidth: 2,
    minHeight: 2,
    maxHeight: 2,
    defaultHeight: 2,
    resizeable: false,
    allowMultipleInstances: false,
  },

   */
};

export const availableWidgets = availableWidgetsRaw as Record<WidgetIdentifiers, AvailableWidget>;

export const isWidgetAvailable = (identifier: string) => {
  const availableWidgetsArray = Object.values(availableWidgets).map(value => value.identifier);
  const isAvailable = availableWidgetsArray.includes(identifier);
  return isAvailable;
};
