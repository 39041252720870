import {JSONAllMultiData} from '../../../../../api/parser/generated';
import {getStationLocations} from '../utils/getStationLocations';
import {PortfolioCargo} from '../../../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {assert} from '../../../../../utils/assert';
import {getIntakes} from './getIntakes';
import {TODO} from '../../../../../utils/TODO';

type TransformedCargo = PortfolioCargo;

export const transformParserCargoToPlattformCargo = async (
  parsedMailData: JSONAllMultiData
): Promise<TransformedCargo> => {
  const cargoData = parsedMailData.cargoDataList?.[0]?.parsedCargoData;
  assert(cargoData);

  const isContainer = !!cargoData.teu || !!cargoData.teu14;
  const intakes = getIntakes(cargoData);

  const cargo: TransformedCargo = {
    cargoType: cargoData.tct ? 'container' : 'drybulk',
    contractType: cargoData.tct ? 'tct' : 'vc',
    commodityType: cargoData.cargo?.[0],
    commodityCategory: cargoData.cargoCategories?.[0],
    commissionTotal: cargoData.commission?.totalCommission,
    commissionAddress: cargoData.commission?.addressCommission,
    commissionBrokerage: cargoData.commission?.brokerFee,
    confidenceScore: cargoData.confidence,
    laycanFrom: cargoData.laycan?.start?.toISOString(),
    laycanTo: cargoData.laycan?.end?.toISOString(),
    maxVesselAge: cargoData.maxVesselAge ?? null,
    gear: cargoData.vesselRequirement?.geared ? 'geared' : 'gearless',
    reeferPlugs: cargoData.reefer?.maximum ?? cargoData.reefer?.minimum,
    commodityStowageFactor: cargoData.stowageFactor?.[0] ?? null,

    chartererName: cargoData.charterer ?? null,
    commodity: cargoData.cargo?.[0],
    name: cargoData.charterer,

    commodityStowageFactorUnit: null,

    quantityMin: cargoData.quantity?.minimum,
    quantityMax: cargoData.quantity?.maximum,
    quantityUnit: isContainer ? 'teu' : 'mt',

    stations: [
      {
        id: -1,
        type: 'loading',
        locations: await getStationLocations({
          portNames: cargoData.loadPort ?? undefined,
          cargoData,
          locationType: 'loading',
        }),
        isRange: false,
      },
      {
        id: -1,
        type: 'discharge',
        locations: await getStationLocations({
          portNames: cargoData.dischargePort ?? undefined,
          cargoData,
          locationType: 'discharge',
        }),
        isRange: false,
      },
    ],
    teuQuantity:
      cargoData.teu?.maximum ?? cargoData.teu?.minimum ?? cargoData.teu14?.maximum ?? cargoData.teu14?.minimum,

    // TODO: Fix the typing of the vessel edit form: should be like TransformedCargo['intakes'] but PortfolioVessel["intakes"] is different
    intakes: intakes as TODO,

    tolerance: null,
    toleranceUnit: null,
    quantityOption: null,
    comment: null,
    color: null,
    documents: [],
    isArchived: false,
    target: 'portfolio',
    id: -1,
  };

  return cargo;
};
