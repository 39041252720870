import {convertToInt, convertToIntOrEmptyString} from '../FilterConversion/utils/FilterConversionUtils';
import {convertValueToNamedValue, NamedValue} from '../FilterConversion/utils/NamedValue';
import {numberBetween, numberBetweenAndGreaterOrEqual} from '../../../../utils/validators';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export interface DatabaseType {
  quantity: {
    from?: number;
    to?: number;
  };
  unit?: QuantityUnit;
  isHighcube: boolean;
  isEmpty: boolean;
}

type QuantityUnit = 'mt' | 'cbm' | 'teu' | 'feu' | '45f';

export interface InternalType {
  quantityFrom: number | '';
  quantityTo: number | '';
  unit: NamedValue<QuantityUnit> | '';
  isHighcube: boolean;
  isEmpty: boolean;
}

export const Defaults: InternalType = {
  quantityFrom: '',
  quantityTo: '',
  unit: '',
  isHighcube: false,
  isEmpty: false,
};

export const QuantityUnits: NamedValue<QuantityUnit>[] = [
  {name: 'MT', value: 'mt'},
  {name: 'CBM', value: 'cbm'},
  {name: 'TEU', value: 'teu'},
];

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    quantityFrom: convertToIntOrEmptyString(database.quantity.from),
    quantityTo: convertToIntOrEmptyString(database.quantity.to),
    isEmpty: database.isEmpty,
    isHighcube: database.isHighcube,
    unit: convertValueToNamedValue<QuantityUnit, ''>({
      value: database.unit,
      available: QuantityUnits,
      defaultValue: '',
    }),
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {quantityFrom, quantityTo, unit, isEmpty, isHighcube} = internal;
  return {
    quantity: {
      from: convertToInt(quantityFrom),
      to: convertToInt(quantityTo),
    },
    unit: unit ? unit.value : undefined,
    isHighcube,
    isEmpty,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Quantity',
  branch: 'quantity',
  defaults: Defaults,
  validators: {
    quantityFrom: numberBetween(0, 500000),
    quantityTo: numberBetweenAndGreaterOrEqual(0, 500000, 'quantityFrom'),
  },
  fromDatabase,
  toDatabase,
});
