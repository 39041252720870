import {FC, ReactNode} from 'react';
import {useAccessTokenManager} from './useAccessTokenManager';
import {FullScreenLoadingAnimation} from '../../atoms/FullScreenLoadingAnimation/FullScreenLoadingAnimation';

export const AccessTokenManager: FC<{
  children: ReactNode;
}> = ({children}) => {
  const isSuccess = useAccessTokenManager();

  if (!isSuccess) {
    return <FullScreenLoadingAnimation />;
  }

  return <>{children}</>;
};
