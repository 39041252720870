import React, {ReactNode} from 'react';
import BaseModal from '../../atoms/BaseModal';
import {ModalFooter} from '../../atoms/BaseModal/ModalFooter';
import {Button} from 'antd';

const Confirmation = ({
  title,
  confirmButtonLabel = 'Yes',
  danger = false,
  description,
  onConfirm,
}: {
  title: ReactNode;
  confirmButtonLabel?: ReactNode;
  danger?: boolean;
  description?: ReactNode;
  onConfirm: () => void;
}) => (
  <BaseModal title={null} middle>
    {({close}: $TSFixMe) => (
      <>
        <div style={{fontSize: 'var(--font-size-xl)'}}>{title}</div>
        {description && <div style={{fontSize: 'var(--font-size-lg)'}}>{description}</div>}
        <ModalFooter>
          <Button
            onClick={() => {
              onConfirm();
              close();
            }}
            type="primary"
            danger={danger}
            data-cy="confirmation-modal-yes-button">
            {confirmButtonLabel}
          </Button>
        </ModalFooter>
      </>
    )}
  </BaseModal>
);

export default Confirmation;
