import React, {FC} from 'react';
import styled from 'styled-components';
import {FlexBox} from '../../../Components/shared';

type ListHeaderProps = {
  indexLabel?: string;
  itemLabel?: string;
  additionalContent?: React.ReactNode;
};

export const ListHeader: FC<ListHeaderProps> = ({indexLabel = '#', itemLabel = 'Terms', additionalContent}) => {
  return (
    <ListHeaderContainer>
      <div>{indexLabel}</div>
      <div>
        {itemLabel}
        {additionalContent}
      </div>
      <div>Info / Actions</div>
    </ListHeaderContainer>
  );
};

export const ListHeaderContainer = styled(FlexBox)`
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: var(--color-gray-2);
  margin-top: -16px;

  div {
    &:nth-of-type(1) {
      width: 31px;
      margin: 0;
      font-weight: bold;
      text-align: center;
    }
    &:nth-of-type(2) {
      width: auto;
      margin: 0 auto 0 0;
      padding-left: 5px;
    }
    &:nth-of-type(3) {
      width: 110px;
      margin: 0 0 0 auto;
      text-align: center;
    }
  }
`;
