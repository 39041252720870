import {FC} from 'react';
import styled from 'styled-components';
import {Icon} from '../../../../atoms/Icon';
import Tooltip from '../../../../atoms/Tooltip';

type NegotiationRoundSummaryTermStatsProps = {
  ongoingTerms: number | string;
  acceptedTerms: number | string;
  rejectedTerms: number | string;
  showOngoing?: boolean;
  showAccepted?: boolean;
  showRejected?: boolean;
  badgeColor?: string | [string, string, string];
  /** Connects the term stats badges visually when true, similar to ButtonGroup */
  grouped?: boolean;
};

export const NegotiationRoundSummaryTermStats: FC<NegotiationRoundSummaryTermStatsProps> = ({
  ongoingTerms,
  acceptedTerms,
  rejectedTerms,
  showOngoing = true,
  showAccepted = true,
  showRejected = true,
  badgeColor = ['blue', 'accepted', 'rejected'],
  grouped,
}) => {
  const [pendingColor, acceptedColor, rejectedColor] = Array.isArray(badgeColor)
    ? badgeColor
    : [badgeColor, badgeColor, badgeColor];

  return (
    <NegotiationRoundStatsContainer $grouped={grouped} data-testid="NegotiationRoundStats">
      {showOngoing && (
        <Tooltip title="Pending terms" trigger="mouseenter" position="bottom">
          <StatBadge $color={pendingColor} data-testid="OngoingTerms">
            <span>{ongoingTerms}</span>
            <IconSizeFix color="white" type="time-full" />
          </StatBadge>
        </Tooltip>
      )}
      {showAccepted && (
        <Tooltip title="Accepted terms" trigger="mouseenter" position="bottom">
          <StatBadge $color={acceptedColor} data-testid="AcceptedTerms">
            <span>{acceptedTerms}</span>
            <IconSized color="white" type="checkmark" />
          </StatBadge>
        </Tooltip>
      )}
      {showRejected && (
        <Tooltip title="Rejected terms" trigger="mouseenter" position="bottom">
          <StatBadge $color={rejectedColor} data-testid="RejectedTerms">
            <span>{rejectedTerms}</span>
            <IconSized color="white" type="close" />
          </StatBadge>
        </Tooltip>
      )}
    </NegotiationRoundStatsContainer>
  );
};

export const NegotiationRoundStatsContainer = styled.div<{$grouped?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({$grouped}) => !$grouped && 'gap: 3px;'}

  & > div {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    flex: 1 1 33%;
    min-width: 40px;
    white-space: pre;

    ${({$grouped}) =>
      $grouped &&
      `
      ${StatBadge} {
        border-radius: 0;
      }
      &:first-child ${StatBadge} {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }
      &:last-child ${StatBadge} {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
    `}
  }
`;

const StatBadge = styled.span<{$color: string}>`
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 100%;
  height: 18px;
  padding: 2px 5px;
  background-color: var(--color-${({$color}) => ($color === 'gray' ? 'gray-2' : $color)});
  border-radius: var(--border-radius);

  span {
    min-width: 14px;
    text-align: center;
    font-size: var(--font-size-sm);
    line-height: 1;
    font-weight: bold;
    color: var(--color-white);
  }
`;

const IconSized = styled(Icon)`
  font-size: 12px !important;
  line-height: 1 !important;
  font-weight: normal !important;
`;
const IconSizeFix = styled(IconSized)`
  font-size: 13px !important;
  font-weight: lighter !important;
  margin-top: -0.5px;
`;
