import {IconLayer, LineLayer} from '@deck.gl/layers/typed';
import memoize from 'lodash/memoize';
import bearing from '@turf/bearing';
import midpoint from '@turf/midpoint';
import iconArrow from '../icons/arrow.svg';
import {getTransparentLevelForLines} from './getTransparentLevelForLines';
import {getTransparentLevelForIcons} from './getTransparentLevelForIcons';
import {CompositeLayerProps} from '@deck.gl/core/typed';
import {Coordinates} from '../../../../utils/Coordinates';
import {CompositeLayer, Position} from '@deck.gl/core/typed';
import {useVesselHistoryQuery} from '../../../../queries/useVesselHistoryQuery';
import {Layer} from '@deck.gl/core/typed';
import {TODO} from '../../../../utils/TODO';

const getData = memoize(function (data) {
  const lastIndex = data.length - 1;
  return data
    .map((e: InternalObject, i: number, a: InternalObject[]) =>
      i < lastIndex
        ? {
            start: e.coordinates,
            end: a[i + 1].coordinates,
            speed: a[i + 1].speed,
          }
        : null
    )
    .slice(0, lastIndex);
});

type InternalObject = {
  start: Coordinates;
  coordinates: Coordinates;
  end: Coordinates;
  speed: number;
};

type QueryProps = Omit<Props, 'data' | 'id'> & {imo?: number};

export const useVesselRouteLayer = (props: QueryProps) => {
  const historyQuery = useVesselHistoryQuery(props.imo, {
    enabled: !!props.imo,
  });
  if (!historyQuery.isSuccess) {
    return [];
  }

  return [new VesselRouteLayer({...props, data: historyQuery.data.route})];
};

type Props = CompositeLayerProps & {
  hideArrows?: boolean;
  arrowOpacity?: number;
  arrowSize?: number;
  zoom: number;
};

export class VesselRouteLayer extends CompositeLayer<Props> {
  renderLayers() {
    const layers: Layer<TODO>[] = [
      new LineLayer<InternalObject>({
        id: 'vessel-route',
        data: getData(this.props.data),
        getSourcePosition: d => d.start,
        getTargetPosition: d => d.end,
        // @ts-ignore deck.gl does not support this
        getColor: (object: InternalObject, context: TODO): RGBAColor => {
          if (object.speed < 5) {
            return [
              255,
              0,
              0,
              getTransparentLevelForLines({
                object,
                context,
              }),
            ];
          }
          return [
            72,
            171,
            255,
            getTransparentLevelForLines({
              object,
              context,
            }),
          ];
        },
        getWidth: 3,
      }),
    ];

    if (!this.props.hideArrows) {
      layers.push(
        new IconLayer<InternalObject>({
          id: 'vessel-route-arrow',
          data: getData(this.props.data),
          sizeMinPixels: this.props.arrowSize ?? 16,
          // @ts-ignore deck.gl does not support this
          getColor: (object: InternalObject, context: TODO) => [
            255,
            255,
            255,
            this.props.arrowOpacity
              ? this.props.arrowOpacity * 255
              : getTransparentLevelForIcons({
                  object,
                  context,
                  zoomLevel: this.props.zoom,
                }),
          ],
          getAngle: d => -bearing(d.start, d.end),
          getIcon: () => 'route-arrow',
          getPosition: d => {
            return midpoint(d.start, d.end).geometry.coordinates as Position;
          },
          updateTriggers: {
            getColor: [this.props.zoom],
          },
          iconAtlas: iconArrow,
          iconMapping: {
            'route-arrow': {
              x: 0,
              y: 0,
              // anchorX: 40,
              // anchorY: 50,
              width: 81,
              height: 101,
              mask: true,
            },
          },
        })
      );
    }
    return layers;
  }
}
