import {CompositeLayer, IconLayer} from 'deck.gl';
import iconAtlas from './data/comm-icon-atlas.png';
import iconMapping from './data/comm-icon-mapping.json';

const TOP_OFFSET = 22;
const ICON_WIDTH = 29;
const ICON_HEIGHT = 25;
const HALF_ICON_WIDTH = ICON_WIDTH / 2;
const OFFSETS = [
  // 1 icon
  [[0, TOP_OFFSET]],
  // 2 icons
  [
    [-HALF_ICON_WIDTH, TOP_OFFSET],
    [HALF_ICON_WIDTH, TOP_OFFSET],
  ],
  // 3 icons
  [
    [-ICON_WIDTH, TOP_OFFSET],
    [0, TOP_OFFSET],
    [ICON_WIDTH, TOP_OFFSET],
  ],
  // 4 icons
  [
    [-ICON_WIDTH + -HALF_ICON_WIDTH, TOP_OFFSET],
    [-HALF_ICON_WIDTH, TOP_OFFSET],
    [HALF_ICON_WIDTH, TOP_OFFSET],
    [HALF_ICON_WIDTH + ICON_WIDTH, TOP_OFFSET],
  ],
  // 5 icons
  [
    [-ICON_WIDTH, TOP_OFFSET],
    [0, TOP_OFFSET],
    [ICON_WIDTH, TOP_OFFSET],
    [-HALF_ICON_WIDTH, ICON_HEIGHT + TOP_OFFSET],
    [HALF_ICON_WIDTH, ICON_HEIGHT + TOP_OFFSET],
  ],
  // 6 icons
  [
    [-ICON_WIDTH + -HALF_ICON_WIDTH, TOP_OFFSET],
    [-HALF_ICON_WIDTH, TOP_OFFSET],
    [HALF_ICON_WIDTH, TOP_OFFSET],
    [HALF_ICON_WIDTH + ICON_WIDTH, TOP_OFFSET],
    [-ICON_WIDTH, ICON_HEIGHT + TOP_OFFSET],
    [0, ICON_HEIGHT + TOP_OFFSET],
  ],
  // 7 icons
  [
    [-ICON_WIDTH + -HALF_ICON_WIDTH, TOP_OFFSET],
    [-HALF_ICON_WIDTH, TOP_OFFSET],
    [HALF_ICON_WIDTH, TOP_OFFSET],
    [HALF_ICON_WIDTH + ICON_WIDTH, TOP_OFFSET],
    [-ICON_WIDTH, ICON_HEIGHT + TOP_OFFSET],
    [0, ICON_HEIGHT + TOP_OFFSET],
    [ICON_WIDTH, ICON_HEIGHT + TOP_OFFSET],
  ],
];
const MAX_ICONS = OFFSETS.length;

const getPixelOffset = d => OFFSETS[d.len - 1][d.index];

const getCargoTypeColor = type => {
  switch (type.toLowerCase()) {
    case 'tct':
      return [115, 210, 230];
    case 'bc':
      return [101, 73, 242];
    case 'pc':
      return [239, 60, 180];
    case 'coa':
      return [255, 191, 0];
    case 'vc':
      return [255, 142, 35];
    default:
      return [0, 0, 0, 128];
  }
};

class PortHexagonLayer extends CompositeLayer {
  updateState({changeFlags}) {
    if (changeFlags.dataChanged) {
      const {data, cargoSubPortfolioFilterOn} = this.props;

      const subLayerData = [];

      data.forEach((object, index) => {
        const icons = [];
        const getIterator =
          (filterSubs = true) =>
          d => {
            let numIcons;
            if (filterSubs) {
              let subFound = false;
              const cargoIds = [];
              for (const sub of Object.keys(d.subs || [])) {
                cargoIds.push(...d.subs[sub]);
                subFound = true;
              }
              numIcons = new Set(cargoIds).size;
              if (!subFound) {
                return null;
              }
            } else {
              numIcons = d.amountCargoes;
            }

            for (let i = 0; i < numIcons && icons.length < MAX_ICONS; i++) {
              icons.push({
                icon: d.commodityCategory,
                color: getCargoTypeColor(d.contractType),
              });
            }
          };
        object.circulars.portfolio.cargoes.forEach(getIterator(cargoSubPortfolioFilterOn));
        object.circulars.market.cargoes.forEach(getIterator(false));

        let i = 0;
        const iconValues = Object.values(icons);
        const len = iconValues.length;
        for (const {icon, color} of iconValues) {
          subLayerData.push(
            this.getSubLayerRow(
              {
                icon,
                color,
                index: i++,
                len,
              },
              object,
              index
            )
          );
        }
      });

      this.setState({data: subLayerData});
    }
  }

  renderLayers() {
    const {getPosition, getSize} = this.props;
    const {data} = this.state;
    return [
      new IconLayer(
        this.getSubLayerProps({
          id: 'bg',
          data,
          getPosition: this.getSubLayerAccessor(getPosition),
          getPixelOffset,
          getSize,
          iconAtlas,
          iconMapping,
          getIcon: () => '_honeycomb',
          getColor: d => d.color,
        })
      ),
      new IconLayer(
        this.getSubLayerProps({
          id: 'icon',
          data,
          getPosition: this.getSubLayerAccessor(getPosition),
          getPixelOffset,
          getSize,
          iconAtlas,
          iconMapping,
          getIcon: d => (iconMapping[d.icon] ? d.icon : 'misc'),
        })
      ),
    ];
  }
}
PortHexagonLayer.layerName = 'PortHexagonLayer';

export default PortHexagonLayer;
