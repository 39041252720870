/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PortDisbursementAccountItem,
  PortDisbursementAccountItemFromJSON,
  PortDisbursementAccountItemFromJSONTyped,
  PortDisbursementAccountItemToJSON,
} from './';

/**
 *
 * @export
 * @interface PortDisbursementAccount
 */
export interface PortDisbursementAccount {
  /**
   * The sum of all the costs of the disbursement account in USD
   * @type {number}
   * @memberof PortDisbursementAccount
   */
  totalCost: number;
  /**
   * The items of the disbursement account
   * @type {Array<PortDisbursementAccountItem>}
   * @memberof PortDisbursementAccount
   */
  items: Array<PortDisbursementAccountItem>;
}

export function PortDisbursementAccountFromJSON(json: any): PortDisbursementAccount {
  return PortDisbursementAccountFromJSONTyped(json, false);
}

export function PortDisbursementAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortDisbursementAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalCost: json['totalCost'],
    items: (json['items'] as Array<any>).map(PortDisbursementAccountItemFromJSON),
  };
}

export function PortDisbursementAccountToJSON(value?: PortDisbursementAccount | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalCost: value.totalCost,
    items: (value.items as Array<any>).map(PortDisbursementAccountItemToJSON),
  };
}
