/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface NegotiationReplyTime
 */
export interface NegotiationReplyTime {
  /**
   *
   * @type {Date}
   * @memberof NegotiationReplyTime
   */
  timestamp: Date | null;
  /**
   * Duration in milliseconds since the commit of the related round.
   * @type {number}
   * @memberof NegotiationReplyTime
   */
  duration: number | null;
}

export function NegotiationReplyTimeFromJSON(json: any): NegotiationReplyTime {
  return NegotiationReplyTimeFromJSONTyped(json, false);
}

export function NegotiationReplyTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationReplyTime {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timestamp: json['timestamp'],
    duration: json['duration'],
  };
}

export function NegotiationReplyTimeToJSON(value?: NegotiationReplyTime | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timestamp: value.timestamp,
    duration: value.duration,
  };
}
