/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Coordinates,
  CoordinatesFromJSON,
  CoordinatesFromJSONTyped,
  CoordinatesToJSON,
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineEventTypeFromJSON,
  VesselVoyageTimelineEventTypeFromJSONTyped,
  VesselVoyageTimelineEventTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface NameChange
 */
export interface NameChange {
  /**
   *
   * @type {VesselVoyageTimelineEventType}
   * @memberof NameChange
   */
  eventType: VesselVoyageTimelineEventType;
  /**
   *
   * @type {number}
   * @memberof NameChange
   */
  imo: number;
  /**
   *
   * @type {Date}
   * @memberof NameChange
   */
  timestamp: Date;
  /**
   *
   * @type {string}
   * @memberof NameChange
   */
  newName: string;
  /**
   *
   * @type {Coordinates}
   * @memberof NameChange
   */
  coordinates: Coordinates;
}

export function NameChangeFromJSON(json: any): NameChange {
  return NameChangeFromJSONTyped(json, false);
}

export function NameChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameChange {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeFromJSON(json['eventType']),
    imo: json['imo'],
    timestamp: new Date(json['timestamp']),
    newName: json['newName'],
    coordinates: CoordinatesFromJSON(json['coordinates']),
  };
}

export function NameChangeToJSON(value?: NameChange | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeToJSON(value.eventType),
    imo: value.imo,
    timestamp: value.timestamp.toISOString(),
    newName: value.newName,
    coordinates: CoordinatesToJSON(value.coordinates),
  };
}
