import {FilterProviderState} from '../../../components/FilterProvider/FilterProviderState';
import {
  MarketPortDatabaseFilter,
  MarketPortFilterBranchDefinitions,
} from '../../../components/FilterProvider/Filters/Market/MarketPortFilterBranchDefinitions';
import {useFilterProvider} from '../../../components/FilterProvider/useFilterProvider';
import {MarketFiltersActions} from '../../../redux/MarketFilters';
import {useDispatch, useSelector} from '../../../redux/react-redux';

interface Props {
  onChangePortFilter: (filter: MarketPortDatabaseFilter) => void;
}

export const usePortListFilterProvider = (props: Props) => {
  const dispatch = useDispatch();

  const {portFilterProviderState} = useSelector(state => state.marketFilters);

  const setPortFilterProviderState = (filters: FilterProviderState<typeof MarketPortFilterBranchDefinitions>) =>
    dispatch(MarketFiltersActions.setPortFilterProviderState(filters));

  const filterProviderApi = useFilterProvider({
    name: 'market/port',
    filterBranchDefinitions: MarketPortFilterBranchDefinitions,
    filterProviderState: portFilterProviderState,
    onFilterSettingsChange: props.onChangePortFilter,
    onFilterProviderStateChange: setPortFilterProviderState,
  });

  return filterProviderApi;
};
