import {AisVessel} from '../api/symfony/generated/models';
import {useAisVesselsQuery, UseAisVesselsQueryParams} from './useAisVesselsQuery';
import {useMemo} from 'react';

export type ImoToAisVesselMap = Record<number, AisVessel>;

export const useImoToAisVesselMap = (
  params: UseAisVesselsQueryParams = {}
): {
  imoToAisVesselMap: ImoToAisVesselMap | null;
  isLoading: boolean;
} => {
  const aisVesselQuery = useAisVesselsQuery(params);

  const imoToAisVesselMap = useMemo(() => {
    if (!aisVesselQuery.data) {
      return null;
    }
    const map: Record<number, AisVessel> = {};
    const items: AisVessel[] = aisVesselQuery.data ?? [];
    items.forEach((item: AisVessel) => {
      map[item.imo!] = item;
    });
    return map;
  }, [aisVesselQuery.data]);

  return {
    imoToAisVesselMap,
    isLoading: imoToAisVesselMap === null,
  };
};
