import clamp from 'lodash/clamp';
import {interpolate} from './interpolate';
import {assert} from '../../../../../utils/assert';

export type Color = [number, number, number] | [number, number, number, number];

/**
 * Interpolates between two colors.
 */
export const interpolateColor = (color1: Color, color2: Color, percentage: number): Color => {
  const clampedPercentage = clamp(percentage, 0, 1);
  assert(color1.length === color2.length);
  const result = color1.map((_, index) => interpolate(color1[index], color2[index], clampedPercentage));

  return result as Color;
};
