import {Form, Select} from 'antd';
import React, {useContext} from 'react';
import {MapContext} from '../../MapContext/MapContext';
import {OPENWEATHERMAP_LAYERS} from '../../const';
import {openweathermapNoLayer} from '../../layers/mapbox/OpenweathermapLayerType';

export const OpenWeatherMapLayer = () => {
  const mapContext = useContext(MapContext);

  const options = [
    {
      value: openweathermapNoLayer,
      label: 'No layer',
    },
    ...OPENWEATHERMAP_LAYERS,
  ];

  const changeOpenWeatherMapLayer = (value: string) => {
    mapContext.setSetting('openWeatherMapLayer', value);
  };

  return (
    <Form
      colon={false}
      size={'small'}
      labelCol={{span: 12}}
      wrapperCol={{span: 12}}
      layout="horizontal"
      labelAlign="left">
      <Form.Item
        style={{
          marginRight: 8,
        }}
        label={'Weather layer'}>
        <Select
          value={mapContext.state.settings.openWeatherMapLayer}
          onChange={value => {
            changeOpenWeatherMapLayer(value);
          }}>
          {options.map(item => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
