import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationTermApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {NegotiationRoundResult, NewTerm} from '../../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useCreateTermsMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {negotiationId: string; newTerms: NewTerm[]}>
) =>
  useMutation({
    mutationFn: async ({negotiationId, newTerms}) => {
      const result = await negotiationNegotiationTermApi.newTerms({
        newTermsRequest: {
          negotiationId,
          newTerms,
        },
      });
      return result;
    },
    ...options,
  });
