import {CompositeLayer} from 'deck.gl';
import {SEPARATOR_SYMBOL} from '../../../../../components/SeaboMap/layers/const';
import BackgroundLayer from './BackgroundLayer';
import ForegroundTextLayer from './ForegroundTextLayer';

class PortLabelLayer extends CompositeLayer {
  renderLayers() {
    const {getAmountVessels, marketVesselsOn, portfolioVesselsOn, marketCargoesOn, portfolioCargoesOn, data} =
      this.props;

    let getText;

    if (marketVesselsOn || portfolioVesselsOn) {
      getText = d => {
        const portName = d.name.trim();
        const value =
          (marketVesselsOn ? d.circulars.market.amountVessels : 0) +
          (portfolioVesselsOn ? getAmountVessels(d.circulars.portfolio) : 0);

        if (value === 0) {
          return portName;
        }
        return `${portName}  ${SEPARATOR_SYMBOL}     ${value}`;
      };
    } else {
      getText = d => d.name.trim();
    }

    return [
      new BackgroundLayer({
        ...this.props,
        id: `${this.props.id}-background`,
        altColor: [6, 81, 145],
        clearSeparator: false,
        data,
        getColor: d => d.color || [255, 255, 255],
        getText,
        getSize: 16,
        updateTriggers: {
          getText: [marketVesselsOn, portfolioVesselsOn, marketCargoesOn, portfolioCargoesOn],
        },
      }),
      new ForegroundTextLayer({
        ...this.props,
        id: `${this.props.id}-text`,
        altColor: [255, 255, 255],
        data,
        getColor: d => d.color || [0, 0, 0],
        getText,
        updateTriggers: {
          getText: [marketVesselsOn, portfolioVesselsOn, marketCargoesOn, portfolioCargoesOn],
        },
        getSize: 16,
      }),
    ];
  }
}

PortLabelLayer.layerName = 'PortLabelLayer';
export default PortLabelLayer;
