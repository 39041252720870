import {Coordinates} from '../../../../../utils/Coordinates';
import {getUniqueRouteKeys} from './getUniqueRouteKeys';
import {getGeohash} from './utils';
import {generateDistanceMap} from './generateDistanceMap';
import {addSeaDistanceToResultVessels} from './addSeaDistanceToResultVessels';
import {Step7Vessel} from '../../steps/step7/vesselWithEstimatedDestination/vesselWithEstimatedDestination';

export const addSeaDistance = async ({
  portCoordinates,
  onChangeProgressInPercent,
  vessels,
}: {
  vessels: Step7Vessel[];
  onChangeProgressInPercent: (percentage: number) => void;

  portCoordinates: Coordinates;
  maxDistance: number;
}) => {
  const portGeohash = getGeohash(portCoordinates);

  const uniqueRoutes = getUniqueRouteKeys({
    portGeohash,
    vessels,
  });

  const distanceMap = await generateDistanceMap({routeKeys: uniqueRoutes, onChangeProgressInPercent});

  return addSeaDistanceToResultVessels({
    vessels,
    portGeohash,
    distanceMap,
  });
};
