import find from 'lodash/find';
import map from 'lodash/map';
import {QueryClient} from '@tanstack/react-query';
import {CommodityCategory} from '../../../../api/symfony/generated';
import {commodityApi} from '../../../../api/symfony/symfonyApi';
import {CommodityCategoriesQueryKey} from '../../../../queries/useCommodityCategoriesQuery';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export type DatabaseType = string[];

export interface InternalType {
  categories: CommodityCategory[];
}

export const Defaults: InternalType = {
  categories: [],
};

export interface CommoditiesContext {
  commodities: CommodityCategory[];
}

const loadBranchContext = async (queryClient: QueryClient): Promise<CommoditiesContext> => {
  const response = await queryClient.fetchQuery({
    queryKey: [CommodityCategoriesQueryKey],
    queryFn: () => commodityApi.getCommodityCategories({includeArchived: true}),
  });
  const commodities = response.data.items as CommodityCategory[];
  return {
    commodities,
  };
};

export const fromDatabase = (database: DatabaseType, context: CommoditiesContext): InternalType => {
  const categories: CommodityCategory[] = [];
  for (const slug of database) {
    const category = find(context.commodities, {slug});
    if (category) {
      categories.push(category);
    } else {
      categories.push({
        id: -1,
        isArchived: false,
        name: `*** Unknown ${slug} ***`,
        slug,
      });
    }
  }
  return {
    categories,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => map(internal.categories, 'slug');

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Commodities',
  branch: 'commodities',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
  loadBranchContext,
});
