import {ArrowRightOutlined} from '@ant-design/icons';
import {Button, List, Row, Col, Card, Tooltip} from 'antd';
import React, {FC} from 'react';
import styled from 'styled-components';
import {NegotiationTemplateSummary} from '../../../api/node-backend/generated/models/NegotiationTemplateSummary';
import Icon from '../../../atoms/Icon';

type ContractTemplateSelectorListProps = {
  templateSummaries: NegotiationTemplateSummary[];
  selectedTemplateId?: number;
  isBackendScreen?: boolean;
  onSelectTemplate: (template: NegotiationTemplateSummary) => void;
  onCreateTemplate: () => void;
};

export const ContractTemplateSelectorList: FC<ContractTemplateSelectorListProps> = ({
  templateSummaries,
  selectedTemplateId,
  isBackendScreen,
  onSelectTemplate,
  onCreateTemplate,
}) => {
  return (
    <ListCard>
      <List
        dataSource={templateSummaries}
        size={'small'}
        rowKey={'id'}
        renderItem={item => (
          <ListItem $isSelected={selectedTemplateId === item.id} onClick={() => onSelectTemplate(item)}>
            <Row justify={'space-between'} align="middle" gutter={[16, 16]}>
              <Col>
                <span style={{fontWeight: selectedTemplateId === item.id ? 'bold' : undefined}}>
                  {item.name}
                  {!item.companyId && (
                    <Tooltip title="template provided by seabo">
                      <div style={{display: 'inline-block'}}>
                        <Icon type="logo-wave" color="blue" style={{position: 'relative', top: 2, marginLeft: 5}} />
                      </div>
                    </Tooltip>
                  )}
                </span>
                {item.id !== -1 && (
                  <>
                    <br />({item.termCount} terms)
                  </>
                )}
              </Col>
              <Col>
                <Button size={'small'} type={'default'}>
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </ListItem>
        )}
      />
      <ListFooter>
        <Button type={'primary'} block onClick={() => onCreateTemplate()}>
          Create new{isBackendScreen ? ' global' : ''} template
        </Button>
      </ListFooter>
    </ListCard>
  );
};

const ListCard = styled(Card)`
  .ant-card-body {
    padding: 0;
    overflow: hidden;
  }
`;

const ListItem = styled.div<{$isSelected?: boolean}>`
  padding: 8px 16px;
  background-color: ${({$isSelected}) => ($isSelected ? 'var(--color-blue-bg)' : 'var(--color-white)')};
  cursor: pointer;
  &:hover {
    background-color: var(--color-gray-6);
  }
`;

const ListFooter = styled.div`
  width: 100%;
  padding: 10px;
`;
