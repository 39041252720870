import {useContext} from 'react';
import {getViewStateFromBounds} from '../utils/getViewStateFromBounds';
import {MapContext} from '../MapContext/MapContext';
import {Coordinates} from '../../../utils/Coordinates';

type FocusMapOnCoordinatesParams = {
  coordinates: Array<Coordinates>;
  zoomIfSinglePoint?: number;
};

type FocusMapOnCoordinates = (params: FocusMapOnCoordinatesParams) => void;

const defaultZoomIfSinglePoint = 13;
/*
this hook returns a function to focus the map on giving coordinates
 */
export const useFocusMapOnCoordinates = (): FocusMapOnCoordinates => {
  const mapContext = useContext(MapContext);

  const focusMapOnCoordinates = (params: FocusMapOnCoordinatesParams) => {
    if (params.coordinates.length === 0) {
      return;
    }

    if (params.coordinates.length === 1) {
      mapContext.setViewState({
        ...mapContext.state.viewState,
        latitude: params.coordinates[0][1],
        longitude: params.coordinates[0][0],
        zoom: params.zoomIfSinglePoint ?? defaultZoomIfSinglePoint,
      });
      return;
    }
    mapContext.setViewState(
      getViewStateFromBounds({
        coords: params.coordinates,
        mapSize: mapContext.state.mapSize || {width: 0, height: 0},
        viewState: mapContext.state.viewState,
        options: {
          padding: 50,
        },
      })
    );
  };

  return focusMapOnCoordinates;
};
