import {TooltipProps} from 'react-tippy';
import Tooltip from '../../../../../../../atoms/Tooltip';
import styled from 'styled-components';
import {FC} from 'react';
import {StatefulIcon} from '../shared';
import {GhostButton} from '../../../../../Components/shared';

type TermEditorActionsProps = {
  helpText?: string;
  disabled?: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const TermEditorActions: FC<TermEditorActionsProps> = ({helpText, disabled, onSave, onCancel}) => {
  const tooltipProps: TooltipProps = {
    delay: 200,
    hideDelay: 0,
    trigger: 'mouseenter',
  };

  return (
    <TermEditorActionsContainer data-testid="TermEditorActions">
      <Tooltip title={`Finish editing`} {...tooltipProps}>
        <GhostButton data-testid="TermSaveButton" onClick={onSave} disabled={disabled}>
          <StatefulIcon type="check-mark-line" $disabled={disabled} />
        </GhostButton>
      </Tooltip>
      <Tooltip title="Cancel editing" {...tooltipProps}>
        <GhostButton data-testid="TermCancelButton" onClick={onCancel} disabled={disabled}>
          <StatefulIcon type="clear" $disabled={disabled} />
        </GhostButton>
      </Tooltip>
      <Tooltip
        title={helpText || `Press SHIFT + ENTER for newline, ENTER to save or ESCAPE to cancel`}
        {...tooltipProps}>
        <GhostButton>
          <StatefulIcon type="help" />
        </GhostButton>
      </Tooltip>
    </TermEditorActionsContainer>
  );
};

const TermEditorActionsContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 81.5px;
  border-left: var(--border-base);
  line-height: 1;

  > * {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    text-align: center;

    .icon {
      position: relative;
      top: 1px;
      font-size: 20px;
    }
  }
`;
