import styled from 'styled-components';
import {DetailSectionContent, DetailSectionFirstBlock, StyledDetailsSection} from '../../atoms/DetailsSection';
import {FormHTMLAttributes} from 'react';

/** Form Generals */
export const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  @media all and (min-width: 1440px) {
    max-width: 75%;
  }

  @media all and (min-width: 1980px) {
    max-width: 60%;
  }
`;
export const Wrapper = styled.div`
  position: relative;
`;
export const StyledForm = styled.form<FormHTMLAttributes<HTMLFormElement>>`
  ${StyledDetailsSection} {
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
  ${DetailSectionContent} {
    flex: 0 0 76%;
    align-self: center;
  }

  ${DetailSectionFirstBlock} {
    flex: 0 0 20%;
  }
`;

export const FormManual = styled.div`
  text-align: center;
  padding: 10px 0;
  margin-top: 5px;

  > span {
    color: var(--color-blue);
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;

/** Buttons */
export const FormButtonGroup = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 22px;
  justify-content: center;

  @media all and (min-width: 768px) {
    width: auto;
    flex-direction: row;
    button {
      &:nth-child(n + 2) {
        margin-left: 16px;
      }
    }
  }
`;

/** SUMMARY */
export const FormSummary = styled.div`
  padding: 30px 0;
  margin-bottom: 14px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-card);
`;
export const SummaryHeader = styled.div`
  max-width: 800px;
  margin: 0 auto;

  .title {
    margin-bottom: 20px;

    span:not(:first-child):not(:empty)::before {
      content: ' – ';
    }
  }
`;
export const SummaryContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 10px;
  margin-top: 16px;
`;
export const SummaryLabel = styled.div`
  font-size: var(--font-size-sm);
  color: var(--color-gray-2);
  text-transform: uppercase;
`;
export const SummaryValue = styled.div`
  font-size: var(--font-size-md);
  color: var(--color-gray-1);
`;

/** Feature Sections */
export const FeatureSection = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 130px;
    height: 34px;
  }
`;
