/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AisCountryApiModel
 */
export interface AisCountryApiModel {
  /**
   * The country code.
   * @type {string}
   * @memberof AisCountryApiModel
   */
  code?: string;
  /**
   * The name of the country.
   * @type {string}
   * @memberof AisCountryApiModel
   */
  name?: string;
}

export function AisCountryApiModelFromJSON(json: any): AisCountryApiModel {
  return AisCountryApiModelFromJSONTyped(json, false);
}

export function AisCountryApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisCountryApiModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, 'code') ? undefined : json['code'],
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function AisCountryApiModelToJSON(value?: AisCountryApiModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    name: value.name,
  };
}
