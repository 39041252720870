import styled from 'styled-components';

export const EditorFormattedValueLabel = styled.span`
  display: block;
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-gray-1);
  line-height: 1.7;
  border-bottom: 1px solid var(--color-gray-2);
  transition: all 0.1s;

  .ant-affix & {
    font-size: var(--font-size-lg);
  }
`;
