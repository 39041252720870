import {FC} from 'react';
import {Button, Col, Form, InputNumber, Row} from 'antd';
import {PortPickerWithFlagForVoyageLeg} from '../../utils/PortPickerWithFlagForVoyageLeg';
import {VoyagePoint} from '../../VoyageInformation/VoyageTypes';
import {SplitCellsOutlined} from '@ant-design/icons';
import {defaultVoyagePoint} from '../../VoyageInformation/Timeline/AddItem';

type Props = {
  legsType: 'loadingLegs' | 'dischargeLegs';
  title: string;
  voyagePoints: Array<VoyagePoint>;
  onSplitAlternativePortsOnVoyagePoint: (point: number) => void;
};

export const LegFormItems: FC<Props> = ({legsType, title, voyagePoints, onSplitAlternativePortsOnVoyagePoint}) => {
  const voyagePointsType = legsType === 'loadingLegs' ? 'loading' : 'discharge';

  const relevantVoyagePoints = voyagePoints
    .map((leg, oldIndex) => ({...leg, oldIndex}))
    .filter(leg => leg.types.includes(voyagePointsType));
  const rateField = legsType === 'loadingLegs' ? 'loadingRate' : 'dischargingRate';

  if (relevantVoyagePoints.length === 0) {
    relevantVoyagePoints.push({oldIndex: 0, ...defaultVoyagePoint});
  }

  return (
    <>
      {relevantVoyagePoints.map((voyagePoint, index) => {
        const hasAlternativePorts = (voyagePoint.alternativePorts?.length ?? 0) > 0;

        return (
          <Row gutter={[4, 4]}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    validator: (_, point: Partial<VoyagePoint>) => {
                      if (point?.name) {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                    message: 'Port is required',
                  },
                ]}
                name={[legsType, index]}
                label={title}>
                <PortPickerWithFlagForVoyageLeg />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[{required: true, message: 'Rate is required'}]}
                label={'Rate pwwd'}
                name={[legsType, index, rateField]}>
                <InputNumber min={1} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[{required: true, message: 'Turn time is required'}]}
                label={'Turn time'}
                name={[legsType, index, 'turnTimeInHours']}>
                <InputNumber min={1} />
              </Form.Item>
            </Col>
            <Col span={7}>
              {hasAlternativePorts && (
                <Button
                  data-cy="splitIntoSeparateButton"
                  onClick={() => onSplitAlternativePortsOnVoyagePoint(voyagePoint.oldIndex)}
                  icon={<SplitCellsOutlined />}
                  ghost={true}>
                  Split into separate legs
                </Button>
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
};
