import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {PageHeader} from './PageHeader';
import {FullPageFooter} from '../Layout/FullPageFooter';
import {SeaboLogo} from '../SeaboLogo/SeaboLogo';

/**
 * Renders the dialog given as `children` as fullscreen, with header and footer.
 */
export const FullscreenDialog: FC<{topRightChildren?: ReactNode; children: ReactNode}> = ({
  children,
  topRightChildren,
}) => {
  return (
    <div>
      <DialogLayout>
        <BackgroundLogo fill="white" />
        <PageHeader rightChildren={topRightChildren} />
        {children}
        <FullPageFooter type="condensed" />
      </DialogLayout>
    </div>
  );
};

const DialogLayout = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--color-azure-new-dark);
  overflow: hidden;
`;

export const BackgroundLogo = styled(SeaboLogo)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.08;
  z-index: 0;
  pointer-events: none;

  g {
    fill: var(--color-white);
  }
`;

export const HorizontalBorder = styled.div`
  padding-left: 10vw;
  padding-right: 10vw;
`;

export const CenteredContentArea = styled.main`
  flex-basis: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
`;

export const ContentArea = styled.main`
  flex-basis: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
