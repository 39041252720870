/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ApiErrorDTO,
  ApiErrorDTOFromJSON,
  ApiErrorDTOToJSON,
  CargoOfferListResponse,
  CargoOfferListResponseFromJSON,
  CargoOfferListResponseToJSON,
  CargoOfferUpdateRequest,
  CargoOfferUpdateRequestFromJSON,
  CargoOfferUpdateRequestToJSON,
  SortDirection,
  SortDirectionFromJSON,
  SortDirectionToJSON,
} from '../models';

export interface GetMarketCargoOffersRequest {
  pageIndex?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: SortDirection;
  filters?: string;
}

export interface MoveCargoOfferToWorkspaceRequest {
  offerId: number;
  workspaceId: number;
}

export interface ShareCargoToMarketRequest {
  body: object;
}

export interface UpdateCargoOfferRequest {
  offer: number;
  cargoOfferUpdateRequest: CargoOfferUpdateRequest;
}

/**
 *
 */
export class CargoOfferApi extends runtime.BaseAPI {
  /**
   * Lists offers in the market.
   */
  async getMarketCargoOffersRaw(
    requestParameters: GetMarketCargoOffersRequest
  ): Promise<runtime.ApiResponse<CargoOfferListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.pageIndex !== undefined) {
      queryParameters['pageIndex'] = requestParameters.pageIndex;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters['sortField'] = requestParameters.sortField;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters['filters'] = requestParameters.filters;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/market/cargo-offers`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CargoOfferListResponseFromJSON(jsonValue));
  }

  /**
   * Lists offers in the market.
   */
  async getMarketCargoOffers(requestParameters: GetMarketCargoOffersRequest): Promise<CargoOfferListResponse> {
    const response = await this.getMarketCargoOffersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Move cargo offer to workspace
   */
  async moveCargoOfferToWorkspaceRaw(
    requestParameters: MoveCargoOfferToWorkspaceRequest
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
      throw new runtime.RequiredError(
        'offerId',
        'Required parameter requestParameters.offerId was null or undefined when calling moveCargoOfferToWorkspace.'
      );
    }

    if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling moveCargoOfferToWorkspace.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/market/cargo-offers/{offerId}/workspace/{workspaceId}`
        .replace(`{${'offerId'}}`, encodeURIComponent(String(requestParameters.offerId)))
        .replace(`{${'workspaceId'}}`, encodeURIComponent(String(requestParameters.workspaceId))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Move cargo offer to workspace
   */
  async moveCargoOfferToWorkspace(requestParameters: MoveCargoOfferToWorkspaceRequest): Promise<object> {
    const response = await this.moveCargoOfferToWorkspaceRaw(requestParameters);
    return await response.value();
  }

  /**
   * Shares a cargo from the user\'s portfolio with the market.
   */
  async shareCargoToMarketRaw(requestParameters: ShareCargoToMarketRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling shareCargoToMarket.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/market/cargo-offers`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Shares a cargo from the user\'s portfolio with the market.
   */
  async shareCargoToMarket(requestParameters: ShareCargoToMarketRequest): Promise<void> {
    await this.shareCargoToMarketRaw(requestParameters);
  }

  /**
   */
  async updateCargoOfferRaw(requestParameters: UpdateCargoOfferRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.offer === null || requestParameters.offer === undefined) {
      throw new runtime.RequiredError(
        'offer',
        'Required parameter requestParameters.offer was null or undefined when calling updateCargoOffer.'
      );
    }

    if (requestParameters.cargoOfferUpdateRequest === null || requestParameters.cargoOfferUpdateRequest === undefined) {
      throw new runtime.RequiredError(
        'cargoOfferUpdateRequest',
        'Required parameter requestParameters.cargoOfferUpdateRequest was null or undefined when calling updateCargoOffer.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/market/cargo-offers/{offer}`.replace(
        `{${'offer'}}`,
        encodeURIComponent(String(requestParameters.offer))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: CargoOfferUpdateRequestToJSON(requestParameters.cargoOfferUpdateRequest),
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async updateCargoOffer(requestParameters: UpdateCargoOfferRequest): Promise<object> {
    const response = await this.updateCargoOfferRaw(requestParameters);
    return await response.value();
  }
}
