import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import TabPane from '../../atoms/Tabs/TabPane';
import {Tabs} from '../../atoms/Tabs/Tabs';
import ShareHistory from '../../components/ShareHistory';
import {TODO} from '../../utils/TODO';
import {getWhichTabsShouldBeVisible, VesselDetailTab} from './getWhichTabsShouldBeVisible';
import {VesselGraphSpeedDraught} from './graphs/SpeedDraughtGraph';
import {StyledCard} from './shared';
import {GeneralTechnicalTab} from './Tabs/GeneralTechnicalTab/GeneralTechnicalTab';
import {SisterVesselsTab} from './Tabs/SisterVesselsTab/SisterVesselsTab';
import {VesselFixtureTab} from './Tabs/VesselFixtureTab';
import {VesselSaleFixtureTab} from './Tabs/VesselSaleFixtureTab';
import {VoyageTimelineTab} from './Tabs/VoyageTimelineTab';
import {VesselHistory} from './VesselHistory';
import {VesselServiceRequestInformation} from '../../redux/ApiService/vesselService';
import {ErrorBoundary} from '../../components/Sentry/ErrorBoundary';
import {VesselDetailsVessel} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

export interface Fixture {
  contractType: 'sale' | string;
}

const VesselDetailBody = ({
  vessel,
  statsVessel,
  vesselStatisticRequestInformation,
  onVesselChanged,
}: {
  vessel: VesselDetailsVessel;
  statsVessel: TODO;
  vesselStatisticRequestInformation: VesselServiceRequestInformation<unknown>;
  onVesselChanged: () => void;
}) => {
  const location = useLocation();
  const whichTabsShouldBeVisible = getWhichTabsShouldBeVisible({
    vessel,
  });
  const isMyVessel = 'relatedOffers' in vessel;
  const visibleTabs = Object.keys(whichTabsShouldBeVisible).filter(
    key => whichTabsShouldBeVisible[key as VesselDetailTab]
  );

  const defaultIndexBasedOnLocationState = visibleTabs.findIndex(key => key === location.state?.tabKey);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    defaultIndexBasedOnLocationState !== -1 ? defaultIndexBasedOnLocationState : 0
  );

  const countOfVisibleTabs = visibleTabs.length;

  useEffect(() => {
    if (activeTabIndex >= countOfVisibleTabs) {
      setActiveTabIndex(0);
    }
  }, [activeTabIndex, countOfVisibleTabs]);

  const switchTab = (index: number) => () => setActiveTabIndex(index - 1);
  return (
    <div className="vessel-content__content">
      <Tabs
        active={activeTabIndex}
        onChangeActive={activeTabIndex => {
          setActiveTabIndex(activeTabIndex);
        }}
        subTabLink>
        {whichTabsShouldBeVisible.generalTechnicalTab && (
          <TabPane id={'general-technical'} key={'general-technical'} label="General & technical">
            <ErrorBoundary location="general-technical">
              <GeneralTechnicalTab
                vessel={vessel}
                showVoyagePreview={whichTabsShouldBeVisible.voyageTimelineTab}
                showVoyageDetails={switchTab(visibleTabs.findIndex(key => key === 'voyageTimelineTab'))}
              />
            </ErrorBoundary>
          </TabPane>
        )}
        {whichTabsShouldBeVisible.vesselHistoryTab && (
          <TabPane id={'vessel-history'} key={'vessel-history'} label={'Ex-names'}>
            <ErrorBoundary location="vessel-history">
              <VesselHistory vesselId={vessel.id} />
            </ErrorBoundary>
          </TabPane>
        )}
        {whichTabsShouldBeVisible.voyageTimelineTab && (
          <TabPane id={'voyage-timeline'} key={'voyage-timeline'} label={'Voyage timeline'}>
            <ErrorBoundary location="voyage-timeline">
              <VoyageTimelineTab vessel={vessel} />
            </ErrorBoundary>
          </TabPane>
        )}
        {whichTabsShouldBeVisible.vesselStatsTab && vesselStatisticRequestInformation.state !== 'ERROR' && (
          <TabPane id={'vessel-stats'} key={'vessel-stats'} label={'Statistics'}>
            <ErrorBoundary location="vessel-stats">
              <StyledCard bodyStyle={{padding: 20}}>
                <VesselGraphSpeedDraught
                  vessel={vessel}
                  statsVessel={statsVessel}
                  statsVesselLoading={vesselStatisticRequestInformation.loading}
                />
              </StyledCard>
            </ErrorBoundary>
          </TabPane>
        )}
        {whichTabsShouldBeVisible.sharingHistoryTab && (
          <TabPane id={'sharing-history'} key={'sharing-history'} label="Sharing history">
            <ErrorBoundary location="sharing-history">
              <ShareHistory history={isMyVessel ? vessel.relatedOffers : undefined} type={'vessel'} />
            </ErrorBoundary>
          </TabPane>
        )}
        {whichTabsShouldBeVisible.vesselFixtureTab && (
          <TabPane id={'vessel-fixture'} key={'vessel-fixture'} label="Commercial history">
            <ErrorBoundary location="vessel-fixture">
              <VesselFixtureTab onVesselChanged={onVesselChanged} vessel={vessel} />
            </ErrorBoundary>
          </TabPane>
        )}
        {whichTabsShouldBeVisible.vesselSaleFixtureTab && (
          <TabPane id={'vessel-sale-fixture'} key={'vessel-sale-fixture'} label="Sales history">
            <ErrorBoundary location="vessel-sale-fixture">
              <VesselSaleFixtureTab onVesselChanged={onVesselChanged} vessel={vessel} />
            </ErrorBoundary>
          </TabPane>
        )}
        {whichTabsShouldBeVisible.sisterVesselsTab && (
          <TabPane id={'sisterVessels'} label={'Sister vessels'}>
            <ErrorBoundary location="'sisterVessels'">
              <SisterVesselsTab imo={vessel.imo} />
            </ErrorBoundary>
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

VesselDetailBody.propTypes = {
  vessel: PropTypes.object,
};

export default VesselDetailBody;
