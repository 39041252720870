import {FC, useRef, useState} from 'react';
import {
  NegotiationRoundResult,
  NegotiationStatus,
  NegotiationSummary,
  NegotiationTerm,
  NegotiationTermStatus,
  NegotiationTermWasAddedIn,
} from '../../../../../api/node-backend/generated';
import {TermItemDumb} from './TermItemDumb';
import {TermToggles} from './Components/TermToggles';
import {getTermColors} from '../../../utils/colors';
import {getDerivedTermState} from './utils/getDerivedTermState';
import {useToggle} from '../../../../../utils/useToggle';
import {RoundResultTermModification} from '../../../../../api/node-backend/generated/models/RoundResultTermModification';
import {TermHistory} from './Components/TermHistory/TermHistory';
import {TermMeta} from './Components/TermMeta';
import {TermEditor} from './Components/TermEditor/TermEditor';
import {notification} from 'antd';
import {checkIsValidRecapEdit} from './utils/checkIsValidRecapEdit';
import {TermDiff} from './Components/TermDiff';
import {TermActionsHook} from './utils/useGetTermActionsWithMutations';
import {TermActionArea} from './Components/TermActionArea';
import {TermSubjects} from './Components/TermSubjects/TermSubjects';
import {AddTermSubButton} from './Components/TermSubjects/AddTermSubButton';
import {InlineTermTypeSelect} from './Components/TermType/TermTypeSelect';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import {InlineTermTypeLabel} from './Components/TermType/TermTypeLabel';
import {SubjectActionsHook} from '../Subjects/utils/useGetSubjectActionsWithMutations';
import {NegotiationActions} from '../CommitArea/utils/useGetNegotiationActionsWithMutations';

type TermItemProps = {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  term: NegotiationTerm;
  termModifications?: RoundResultTermModification;
  isFirstRecapRound?: boolean;
  dragListeners?: SyntheticListenerMap;
  negotiationActions: NegotiationActions;
  actionsBusy?: boolean;
  getTermActions: TermActionsHook;
  getSubjectActions: SubjectActionsHook;
};

export const TermItem: FC<TermItemProps> = ({
  negotiation,
  roundResult,
  term,
  termModifications,
  isFirstRecapRound,
  dragListeners,
  negotiationActions,
  actionsBusy,
  getTermActions,
  getSubjectActions,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {status: negotiationStatus, party, roundCount} = negotiation;
  const isRecap = negotiationStatus === NegotiationStatus.Recap;
  const negotiationCanBeEdited = ![NegotiationStatus.Cancelled, NegotiationStatus.Settled].includes(negotiationStatus);

  const {committedAt} = roundResult;
  const roundCanBeEdited = negotiationCanBeEdited && committedAt === null;

  const {text, type, status: termStatus, sortIndex, lastModifiedBy, lastModifiedAt, wasAddedIn} = term;
  const lastModifiedByActor = negotiation[lastModifiedBy];
  const {oldText, termModification, subjectModificationReports} = termModifications ?? {};

  const isAccepted = termStatus === NegotiationTermStatus.Accepted;
  const isRejected = termStatus === NegotiationTermStatus.Rejected;
  const isRejectRequested = termStatus === NegotiationTermStatus.RejectionRequested;
  const isSettled = isAccepted || isRejected;
  const canAddSubjects =
    roundCanBeEdited &&
    ![NegotiationStatus.Recap, NegotiationStatus.Cancelled, NegotiationStatus.Settled].includes(negotiationStatus);

  const termState = getDerivedTermState({
    party,
    roundCanBeEdited,
    term,
    termModification,
    isRecap,
    isFirstRecapRound,
  });
  const {isNew, isModified, isModifiedByCurrentParty, isCountered, canBeEdited} = termState;

  const wasAddedInRecap = wasAddedIn === NegotiationTermWasAddedIn.Recap;
  const termStateColors = getTermColors(termStatus, negotiationStatus, isCountered, wasAddedInRecap);

  const termActions = getTermActions(term);
  const {updateTermText, updateTermType} = termActions;

  const [showHistory, _toggleHistory] = useToggle(false);
  const toggleHistory = roundCount > 1 ? _toggleHistory : undefined;

  const [showDiff, _toggleDiff] = useToggle(false);
  const toggleDiff = oldText && oldText !== text ? _toggleDiff : undefined;

  const hasToggles = [toggleHistory, toggleDiff].some(Boolean);

  const [showEditor, setShowEditor] = useState(false);
  const startEditMode = canBeEdited ? () => setShowEditor(true) : undefined;
  const endEditMode = () => {
    setShowEditor(false);
  };
  const saveEdit = (newText: string) => {
    if (!canBeEdited) {
      return;
    }
    if (!checkIsValidRecapEdit(newText, text)) {
      notification.error({
        message: 'Invalid edit',
        description: 'The changes you made are not valid. Please check the help text below the editor.',
      });
      return;
    }
    endEditMode();
    updateTermText(newText);
  };

  const {createSubjects} = negotiationActions;
  const createTermSubject = () => createSubjects([{termId: term.id, text: ''}]);

  const isHighlighted = roundCanBeEdited && ((isRecap && !isSettled) || (!isModifiedByCurrentParty && !isSettled));

  return (
    <TermItemDumb
      containerRef={containerRef}
      termId={term.id}
      indexLabel={sortIndex + 1}
      text={text}
      previousText={oldText}
      colors={termStateColors}
      isHighlighted={isHighlighted}
      isRejected={isRejected}
      isRejectRequested={isRejectRequested}
      isExpanded={showHistory}
      dragListeners={dragListeners}
      onTextClick={startEditMode}
      indexAddon={hasToggles ? <TermToggles onToggleHistory={toggleHistory} onToggleDiff={toggleDiff} /> : null}
      aboveContentAddon={
        showHistory ? (
          <TermHistory termId={term.id} party={party} isOtherUsersLastStateVisible={roundCanBeEdited && !isModified} />
        ) : null
      }
      contentReplacement={
        showEditor ? (
          <TermEditor
            containerRef={containerRef}
            currentText={text}
            showDiff={!!oldText && showDiff}
            onSave={saveEdit}
            onCancel={endEditMode}
            helpText={isRecap ? 'Please keep edits to a minimum for recap.' : undefined}
          />
        ) : null
      }
      aboveTextAddon={
        isNew && roundCanBeEdited ? (
          <InlineTermTypeSelect termType={type} onTermTypeChange={updateTermType} />
        ) : (
          <InlineTermTypeLabel termType={type} />
        )
      }
      textReplacement={isModified && showDiff ? <TermDiff previousText={oldText!} currentText={text} /> : null}
      afterTextAddon={
        <>
          <TermMeta actor={lastModifiedByActor} isSelf={lastModifiedBy === party} committedAt={lastModifiedAt} />
          {roundCanBeEdited && (
            <>
              <TermActionArea
                termActions={termActions}
                termState={termState}
                actionsBusy={actionsBusy}
                onEdit={startEditMode}
              />
              {canAddSubjects && <AddTermSubButton onClick={createTermSubject} />}
            </>
          )}
        </>
      }
      afterTermAddon={
        <TermSubjects
          subjects={term.subjects}
          subjectModificationReports={subjectModificationReports}
          negotiation={negotiation}
          roundCanBeEdited={roundCanBeEdited}
          actionsBusy={actionsBusy}
          getSubjectActions={getSubjectActions}
        />
      }
    />
  );
};
