/* eslint-disable no-nested-ternary */
import React, {Component, Fragment} from 'react';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import {acceptFloatOrEmpty} from '../../../utils/formatter';
import {Button} from '../../../atoms/Button/Button';
import Input from '../../../atoms/Input';
import Icon from '../../../atoms/Icon';
import IconButton from '../../../atoms/IconButton';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import HorizontalLine from '../../../atoms/HorizontalLine';
import Checkbox from '../../../atoms/Checkbox';
import {gearTypes, iceClasses, newGear, vesselFeatures, vesselFeatureSet} from '../helper';
import {numberBetween, alwaysValid} from '../../../utils/validators';
import {FeatureSection} from '../CargoVesselForm.styled';
import Select from '../../../atoms/Select/Select';
import {TODO} from '../../../utils/TODO';

const quantityOptions = Array(20)
  .fill('_')
  .map((_, index) => ({
    value: index + 1,
    name: index + 1 + 'x',
  }));
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const gearOptions = Object.keys(gearTypes).map(value => ({value, name: gearTypes[value]}));
const Wrapper = BindToProvider(
  'Features',
  'features',
  {
    gears: [],
    iceClass: '',
    ...Object.keys(vesselFeatureSet).reduce((values: Record<string, TODO>, field) => {
      values[field] = false;
      return values;
    }, {}),
  },
  {
    gears: alwaysValid,
    row: {
      capacity: numberBetween(1, 9999, undefined),
    },
  },
  {
    toApi: (fields: $TSFixMe) => ({
      ...fields,
      gears: fields.gears
        ? fields.gears.map((gear: $TSFixMe) => ({
            ...gear,
            capacity: parseFloat(gear.capacity),
          }))
        : [],
    }),
    fromApi: (fields: $TSFixMe) => ({
      ...fields,
      gears: fields.gears
        ? fields.gears.map((gear: Record<string, TODO>) => ({
            ...newGear,
            ...Object.keys(gear).reduce((fields: Record<string, TODO>, field) => {
              if (gear[field] || gear[field] === 0) {
                fields[field] = gear[field];
              }
              return fields;
            }, {}),
          }))
        : [],
    }),
  },
  true
)(WrappedDetailsSection);

type Props = {
  vesselType: string;
  validation: TODO;
  validateRow: () => void;
  gears?: TODO[];
  onChange: (values: TODO) => void;
};

class GearRows extends Component<Props> {
  onRemove = (gears: $TSFixMe, i: $TSFixMe) => {
    (this.props as $TSFixMe).onChange(gears.filter((v: $TSFixMe, idx: $TSFixMe) => idx !== i));
  };
  onRowChange = (field: $TSFixMe, value: $TSFixMe, i: $TSFixMe) => {
    (this.props as $TSFixMe).onChange([
      ...(this.props as $TSFixMe).gears.slice(0, i),
      {
        ...(this.props as $TSFixMe).gears[i],
        [field]: value,
      },
      ...(this.props as $TSFixMe).gears.slice(i + 1),
    ]);
  };
  shouldComponentUpdate(nextProps: TODO) {
    return (
      nextProps.gears !== (this.props as $TSFixMe).gears ||
      nextProps.vesselType !== (this.props as $TSFixMe).vesselType ||
      nextProps.validation !== (this.props as $TSFixMe).validation
    );
  }
  render() {
    const {vesselType, validation, validateRow, gears = []} = this.props;
    return (
      <div className="features-rows">
        {gears.length > 0 && <FeatureRowLabels vesselType={vesselType} />}
        {gears.map((values: $TSFixMe, i: $TSFixMe) => (
          <FeatureRowInputs
            key={i}
            vesselType={vesselType}
            i={i}
            values={values}
            validateRow={validateRow}
            validation={validation}
            gears={gears}
            onRemove={this.onRemove}
            onRowChange={this.onRowChange}
          />
        ))}
      </div>
    );
  }
}
const FeatureRowLabels = ({vesselType}: $TSFixMe) => (
  <div className="row features-row-labels">
    <div className="scol-12 scol-sm-4 scol-lg-3">
      <LabelWrapper label={vesselType === 'container' ? 'Gear' : 'Gear / Grabs'} />
    </div>
    <div className="scol-12 scol-sm-2">
      <LabelWrapper label={'Amount'} />
    </div>
    <div className="scol-12 scol-sm-2">
      <LabelWrapper label={'Capacity'}></LabelWrapper>
    </div>
    <div className="scol-12 scol-sm-3 scol-lg-4">
      <LabelWrapper label={'Type'}></LabelWrapper>
    </div>
    <div className="scol-12 scol-sm-1"></div>
  </div>
);
const FeatureRowInputs = ({vesselType, i, values, validateRow, validation, gears, onRowChange, onRemove}: $TSFixMe) => (
  <div className="row features-row-inputs" key={i}>
    <div className="scol-12 scol-sm-4 scol-lg-3">
      <Select
        id={`form-features-type-${i}`}
        name={`form-features-type-${i}`}
        options={vesselType === 'container' ? [{value: 'gear', name: gearTypes['gear']}] : gearOptions}
        /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; name: string; options: { value... Remove this comment to see the full error message */
        value={{value: values.type, name: gearTypes[values.type]}}
        /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; name: string; options: { value... Remove this comment to see the full error message */
        defaultValue={{value: values.type, name: gearTypes[values.type]}}
        onChange={(o: $TSFixMe) => onRowChange('type', o.value, i)}
        getOptionLabel={(o: $TSFixMe) => o.name}
        getOptionValue={(o: $TSFixMe) => o.value}
        components={{DropdownIndicator: ArrowIndicator}}
        isSearchable={false}
      />
    </div>
    <div className="scol-12 scol-sm-2">
      <Select
        id={`form-features-quantity-${i}`}
        name={`form-features-quantity-${i}`}
        options={quantityOptions}
        value={{value: values.quantity, name: `${values.quantity}x`}}
        defaultValue={{value: values.quantity, name: `${values.quantity}x`}}
        onChange={(o: $TSFixMe) => onRowChange('quantity', o.value, i)}
        getOptionLabel={(o: $TSFixMe) => o.name}
        getOptionValue={(o: $TSFixMe) => o.value}
        components={{DropdownIndicator: ArrowIndicator}}
        isSearchable={false}
      />
    </div>
    <div className="scol-12 scol-sm-2">
      <div style={{display: 'grid', gridTemplateColumns: '1fr 34px'}}>
        <Input
          id={`form-features-capacity-${i}`}
          onChange={v => acceptFloatOrEmpty(v, v => onRowChange('capacity', v, i), 4, 2)}
          onKeyUp={() => validateRow('gears', 'capacity', i)}
          value={values.capacity}
          hasError={validation[i] && validation[i].capacity && validation[i].capacity.invalid}
        />{' '}
        <div className="label-after-field" style={{width: 30}}>
          {values.type === 'gear' ? ' mts' : ' cbm'}
        </div>
      </div>
      {validation[i] && validation[i].capacity && validation[i].capacity.error && (
        <p className="text-danger text-danger--no-margin">{validation[i].capacity.error}</p>
      )}
    </div>
    <div className="scol-12 scol-sm-3 scol-lg-4">
      <Input
        id={`form-features-description-${i}`}
        onChange={value => onRowChange('description', value, i)}
        value={values.description}
        maxLength={255}
      />
    </div>
    <div className="scol-12 scol-sm-1">
      <div className="cargo-vessel-form__v-centered-field-without-label">
        <IconButton
          id={`form-features-gear-remove-${i}`}
          type={'scrap'}
          iconStyle={{cursor: 'pointer'}}
          onClick={() => onRemove(gears, i)}
          title={'Remove'}
        />
      </div>
    </div>
  </div>
);
const Features = (props: $TSFixMe) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const iceClassOptions = Object.keys(iceClasses).map(value => ({value, name: iceClasses[value]}));
  return (
    <Wrapper {...props}>
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {({onChange, values, validations, validate, validateRow, form}: $TSFixMe) => (
        <div className="container-fluid">
          {form.general && form.general.vesselType && (
            <GearRows
              gears={values.gears}
              vesselType={form.general.vesselType}
              onChange={(values: $TSFixMe) => onChange('gears', values)}
              validateRow={validateRow}
              validation={validations.gears}
            />
          )}
          <div className={'row'}>
            <div className={'scol-12 cargo-vessel-form__icon-button'}>
              <Button
                id={'form-features-gear-add'}
                icon
                label={
                  <Fragment>
                    <Icon
                      type={'item-add-selection-solid'}
                      color="blue"
                      style={{marginRight: 6, verticalAlign: 'bottom'}}
                    />
                    {values.gears.length === 0
                      ? form.general && form.general.vesselType === 'container'
                        ? 'ADD GEAR'
                        : 'ADD GEAR / GRABS'
                      : 'ADD MORE'}
                  </Fragment>
                }
                onClick={() => {
                  onChange('gears', [...values.gears, newGear]);
                }}
              />
            </div>
          </div>
          <HorizontalLine large />
          <div className={'row'}>
            <div className={'scol-12'}>
              <FeatureSection>
                {form.general &&
                  form.general.vesselType &&
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  vesselFeatures[form.general.vesselType].map((feature: $TSFixMe, i: $TSFixMe) => (
                    <Checkbox
                      id={`form-features-feature-${i}`}
                      key={i}
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      label={vesselFeatureSet[feature]}
                      checked={values[feature]}
                      onChange={e => onChange(feature, e.target.checked)}
                    />
                  ))}
              </FeatureSection>
            </div>
          </div>
          <HorizontalLine large />
          <div className={'row'}>
            <div className={'scol-12 scol-sm-8 scol-md-7 scol-lg-5'}>
              <LabelWrapper label={'Ice class'} htmlFor={'form-features-iceclass'}>
                <Select
                  id={'form-features-iceclass'}
                  name={'form-features-iceclass'}
                  options={iceClassOptions}
                  /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; name: string; options: { value... Remove this comment to see the full error message */
                  value={{value: values.iceClass, name: iceClasses[values.iceClass]}}
                  /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; name: string; options: { value... Remove this comment to see the full error message */
                  defaultValue={{value: values.iceClass, name: iceClasses[values.iceClass]}}
                  onChange={(o: $TSFixMe) => onChange('iceClass', (o && o.value) || '')}
                  getOptionLabel={(o: $TSFixMe) => o.name}
                  getOptionValue={(o: $TSFixMe) => o.value}
                  components={{DropdownIndicator: ArrowIndicator}}
                  isSearchable={false}
                  isClearable
                />
              </LabelWrapper>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};
export default Features;
