import {FC} from 'react';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import General from '../../components/FilterProvider/Filters/General/General';
import {Size} from '../../components/FilterProvider/Filters/Size/Size';
import Dimensions from '../../components/FilterProvider/Filters/Dimensions/Dimensions';
import Intake from '../../components/FilterProvider/Filters/Intake/Intake';
import VesselTypeFilter from '../../components/FilterProvider/Filters/VesselType/VesselType';
import Features from '../../components/FilterProvider/Filters/Features/Features';
import {useSearchContainerDesignsForAutocomplete} from './queries/useSearchContainerDesignsForAutocomplete';
import {FilterBar} from '../../components/FilterBar/FilterBar';
import {FeaturePermission} from '../../api/symfony/generated';
import {LabelBadge} from '../../atoms/LabelBadge';
import {useIsPermitted} from '../../utils/useIsPermitted';
import {VesselDatabaseFilterBranchDefinitions} from '../../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';

interface Props {
  filterProviderApi: FilterProviderApi<typeof VesselDatabaseFilterBranchDefinitions>;
}

export const VesselDatabaseFilters: FC<Props> = ({filterProviderApi}) => {
  const searchContainerDesignsForAutocomplete = useSearchContainerDesignsForAutocomplete();
  const hasFullVesselDatabasePermission = useIsPermitted(FeaturePermission.AccessFullVesselDb);

  return (
    <FilterBar
      id="vesselFilters"
      filters={[
        <VesselTypeFilter filterProviderApi={filterProviderApi} />,
        <General filterProviderApi={filterProviderApi} />,
        <Size
          badge={<PremiumBadge hasFullVesselDatabasePermission={hasFullVesselDatabasePermission} right={44} />}
          disabled={!hasFullVesselDatabasePermission}
          filterProviderApi={filterProviderApi}
          loadContainerDesignsForAutocomplete={searchContainerDesignsForAutocomplete}
        />,
        <Dimensions
          badge={<PremiumBadge hasFullVesselDatabasePermission={hasFullVesselDatabasePermission} right={24} />}
          disabled={!hasFullVesselDatabasePermission}
          filterProviderApi={filterProviderApi}
        />,
        <Intake
          badge={<PremiumBadge hasFullVesselDatabasePermission={hasFullVesselDatabasePermission} right={36} />}
          disabled={!hasFullVesselDatabasePermission}
          filterProviderApi={filterProviderApi}
        />,
        <Features
          badge={<PremiumBadge hasFullVesselDatabasePermission={hasFullVesselDatabasePermission} right={30} />}
          disabled={!hasFullVesselDatabasePermission}
          filterProviderApi={filterProviderApi}
        />,
      ]}
    />
  );
};

const PremiumBadge = ({
  right,
  hasFullVesselDatabasePermission,
}: {
  right: number;
  hasFullVesselDatabasePermission: boolean;
}) => {
  if (hasFullVesselDatabasePermission) {
    return null;
  }
  return (
    <LabelBadge
      style={{
        backgroundColor: 'var(--color-upgrade)',
        color: 'var(--color-white)',
        position: 'absolute',
        right: right,
        top: 12,
      }}>
      Upgrade
    </LabelBadge>
  );
};
