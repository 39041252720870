import {ParsedVesselData} from '../../../../../api/parser/generated';
import {Intake} from '../../../../CargoVesselForm/utils/IntakeSection/IntakeTypes';

export const getIntakes = (vesselData: ParsedVesselData) => {
  const intakes: Intake[] = [];
  if (vesselData.teu) {
    intakes.push({
      type: 'teu',
      quantity: vesselData.teu.toString(),
      isHighCube: false,
    });
  }
  if (vesselData.teu14) {
    intakes.push({
      type: 'teu14',
      quantity: vesselData.teu14.toString(),
      isHighCube: false,
    });
  }
  if (vesselData.feu) {
    intakes.push({
      type: 'feu',
      quantity: vesselData.feu.toString(),
      isHighCube: false,
    });
  }

  return intakes;
};
