import {Button, Col, Row, Tabs} from 'antd';
import styled from 'styled-components';
import React, {FC, useState} from 'react';
import {useImmer} from 'use-immer';
import {AccountSegments} from '../../../api/symfony/generated';
import {CommoditySelector} from './CommoditySelector';
import {RoleSelector} from './RoleSelector';
import {SizeSelector} from './SizeSelector';
import {TradingAreaSelector} from './TradingAreaSelector/TradingAreaSelector';
import {VesselTypeSelector} from './VesselTypeSelector';

export enum AccountSegmentTitles {
  userSelfCategorization = 'Organisational role',
  types = 'Vessel types',
  sizes = 'Sizes',
  commodities = 'Commodities',
  tradingAreas = 'Trading areas',
}

export const AccountSegmentSelectorTabs: FC<{
  values: AccountSegments;
  onSubmit: (values: AccountSegments) => void;
}> = ({values, onSubmit}) => {
  const [segmentData, setSegmentData] = useImmer<AccountSegments>(values);
  const [activeKey, setActiveKey] = useState<keyof typeof AccountSegmentTitles>('userSelfCategorization');

  const emptyAccountSegments: AccountSegmentTitles[] = [];
  const hasCommodity = segmentData.types.includes('bulker');
  (Object.keys(segmentData) as Array<keyof AccountSegments>).forEach(segmentKey => {
    if (segmentKey === 'commodities' && !hasCommodity) {
      return;
    }
    if (!segmentData[segmentKey].length) {
      emptyAccountSegments.push(AccountSegmentTitles[segmentKey]);
    }
  });
  return (
    <Row style={{minHeight: 548}} gutter={[16, 16]}>
      <Col span={24}>
        <Tabs
          data-testid="AccountSegmentSelectorTabs"
          defaultActiveKey="userSelfCategorization"
          activeKey={activeKey}
          onTabClick={key => setActiveKey(key as keyof typeof AccountSegmentTitles)}
          items={[
            {label: AccountSegmentTitles.userSelfCategorization, key: 'userSelfCategorization'},
            {label: AccountSegmentTitles.types, key: 'types'},
            {label: AccountSegmentTitles.sizes, key: 'sizes'},
            ...(hasCommodity ? [{label: AccountSegmentTitles.commodities, key: 'commodities'}] : []),
            {label: AccountSegmentTitles.tradingAreas, key: 'tradingAreas'},
          ]}
        />
      </Col>
      <Col span={24}>
        {activeKey === 'userSelfCategorization' && (
          <RoleSelector
            selectedRoles={segmentData.userSelfCategorization}
            onChange={userSelfCategorization => {
              setSegmentData(segmentData => {
                segmentData.userSelfCategorization = userSelfCategorization;
              });
            }}
          />
        )}
        {activeKey === 'types' && (
          <VesselTypeSelector
            segment={segmentData.types}
            onChange={segment => {
              setSegmentData(segmentData => {
                segmentData.types = segment;
              });
            }}
          />
        )}
        {activeKey === 'sizes' && (
          <SizeSelector
            selectedSizes={segmentData.sizes}
            segment={segmentData.types}
            onChange={newSizes => {
              setSegmentData(segmentData => {
                segmentData.sizes = newSizes;
              });
            }}
          />
        )}
        {activeKey === 'commodities' && (
          <CommoditySelector
            values={segmentData.commodities}
            onChange={commodities => {
              setSegmentData(segmentData => {
                segmentData.commodities = commodities;
              });
            }}
          />
        )}
        {activeKey === 'tradingAreas' && (
          <TradingAreaSelector
            selectedTradingAreaCodes={segmentData.tradingAreas}
            onSelect={tradingAreas => {
              setSegmentData(segmentData => {
                segmentData.tradingAreas = tradingAreas;
              });
            }}
          />
        )}
      </Col>
      <Col span={24} style={{textAlign: 'center'}}>
        {emptyAccountSegments.length > 0 && (
          <ErrorContainer>
            <div>
              Please select at least one option of following segments to continue: <br />
              {emptyAccountSegments.join(', ')}
            </div>
          </ErrorContainer>
        )}
        <Button
          data-testid="SaveButton"
          type={'primary'}
          size={'large'}
          style={{marginLeft: 8}}
          disabled={emptyAccountSegments.length > 0}
          onClick={() => onSubmit(segmentData)}>
          Save Segments
        </Button>
      </Col>
    </Row>
  );
};

const ErrorContainer = styled.div`
  margin-bottom: 20px;
  color: var(--color-red);
`;
