import React, {Fragment} from 'react';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import Input from '../../../../atoms/Input';
import {hasError} from '../../../../utils/helper';
import autocomplete from '../../../../redux/ApiService/autocomplete';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './OwnershipDefinition';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {AsyncMultiCreatable} from '../../../../atoms/Select/AsyncMultiCreatable';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const Ownership = ({filterProviderApi}: Props) => {
  const preFillChartererData = useSelector(
    state => state.api.autoComplete.preFillCharterer.data?.data?.items || []
  ) as {slug: string; name: string}[];
  const dispatch = useDispatch();
  const searchCharterer = (query: string) => dispatch(autocomplete.searchCharterer(query));

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
      {({handleChange, values, validations}) => (
        <Fragment>
          <div className="container">
            <div className="row bottom-fix">
              <div className="scol-12">
                <LabelWrapper label={'Commercial manager'} htmlFor={'filter-ownership-registered-owner'}>
                  <Input
                    id={'filter-ownership-registered-owner'}
                    onChange={value => handleChange('registeredOwner', value)}
                    value={values.registeredOwner}
                    hasError={hasError('registeredOwner', validations)}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12">
                <LabelWrapper label={'Owner'} htmlFor={'filter-ownership-managing-owner'}>
                  <Input
                    id={'filter-ownership-managing-owner'}
                    onChange={value => handleChange('managingOwner', value)}
                    value={values.managingOwner}
                    type={'text'}
                    hasError={hasError('managingOwner', validations)}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12">
                <LabelWrapper label={'Charterer / Disponent owner'} htmlFor={'filter-ownership-disponent-owner'}>
                  <AsyncMultiCreatable
                    id={`filter-ownership-disponent-owner`}
                    name={`filter-ownership-disponent-owner`}
                    value={values.disponentOwners}
                    onChange={value => handleChange('disponentOwners', [...value])}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.label}
                    loadOptions={q => (q.length > 1 ? searchCharterer(q) : Promise.resolve([]))}
                    defaultOptions={
                      preFillChartererData?.map(p => ({
                        value: p.slug,
                        label: p.name,
                      })) || []
                    }
                    createLabel={value => value}
                    createOptionPosition={'first'}
                    placeholder={'Type to search...'}
                    loadingMessage={() => 'Searching ...'}
                  />
                </LabelWrapper>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </FilterButton>
  );
};

export default Ownership;
