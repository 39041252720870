import {ConsumptionTransaction} from '../../../ConsumptionTransaction';

export type StorageItem = {
  price: number;
  quantity: number;
};

export const prepareForTheMomentOfConsumption = ({
  filteredTransactions,
}: {
  filteredTransactions: ConsumptionTransaction[];
}): StorageItem[] => {
  let stack: {price: number; quantity: number}[] = [];

  let internalConsumptionCounter = 0;

  filteredTransactions.forEach(transaction => {
    internalConsumptionCounter += transaction.quantity;

    if (internalConsumptionCounter < 0) {
      internalConsumptionCounter = 0;
      stack = [];
      return;
    }

    // Add bunker
    if (transaction.quantity > 0) {
      stack.push({price: transaction.pricePerUnit ?? 0, quantity: transaction.quantity});
      return;
    }

    // Consume bunker
    let quantityToConsume = -transaction.quantity;
    while (quantityToConsume > 0) {
      if (stack.length === 0) {
        throw new Error('Not enough quantity available');
      }
      const topOfStack = stack[stack.length - 1];
      if (topOfStack.quantity > quantityToConsume) {
        topOfStack.quantity -= quantityToConsume;
        quantityToConsume = 0;
        continue;
      }

      quantityToConsume -= topOfStack.quantity;
      stack.pop();
    }
  });
  return stack;
};
