import {FC, createRef} from 'react';
import styled from 'styled-components';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: React.ReactElement;
  dataCy?: string;
}

const inputRef = createRef<HTMLInputElement>();

const Radio: FC<Props> = ({id, name, checked, onChange, disabled, label, dataCy, ...rest}) => (
  <StyledLabel $theme={disabled} data-cy={dataCy}>
    {label}
    <StyledInput
      id={`radio-${id}`}
      name={name}
      type="radio"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      {...rest}
      ref={inputRef}
    />
    <RadioButton />
  </StyledLabel>
);

export default Radio;

const RadioButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var(--color-gray-6);
  border: var(--border-base);
  border-radius: 50%;
  box-shadow: var(--box-shadow-inset-input);
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-white);
    display: none;
    &:checked {
      display: block;
    }
  }
`;

const StyledInput = styled.input`
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  left: -9999px;
  &:checked ~ ${RadioButton} {
    background-color: var(--color-blue);
    display: block;
  }
  &:checked ~ ${RadioButton}:after {
    display: block;
  }
  &:disabled:checked ~ ${RadioButton} {
    background-color: var(--color-gray-4);
  }
`;

const StyledLabel = styled.label<{$theme?: boolean}>`
  display: inline-block;
  position: relative;
  padding-left: 24px;
  font-size: var(--font-size-md);
  line-height: 19px;
  user-select: none;
  margin: 0 55px 5px 0;
  left: 0 !important;
  cursor: default !important;
  ${({$theme}) => ($theme ? 'color:var(--color-gray-3)' : 'color: var(--color-gray-2)')};
  &:before {
    display: none !important;
  }
`;
