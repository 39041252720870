import {FC, KeyboardEvent, useEffect, useRef} from 'react';
import {TextAreaProps, TextAreaRef} from 'antd/es/input/TextArea';
import {Input} from 'antd';

type TextAreaKeyControlledProps = Omit<TextAreaProps, 'ref'> & {
  focusOnMount?: boolean;
  onMount?: (textArea: HTMLTextAreaElement) => void;
  onSaveTrigger?: (event: KeyboardEvent<HTMLTextAreaElement>) => boolean;
  onSave: () => void;
  onCancelTrigger?: (event: KeyboardEvent<HTMLTextAreaElement>) => boolean;
  onCancel: () => void;
};

/**
 * Extends the AntD TextArea component.
 *
 * Trigger `onSave` by pressing "Enter" by default (Shift + Enter for newline) or using your own `onSaveTrigger` function using a keydown-event as parameter.
 *
 * Analog to it `onCancel` with default key "Escape" and `onCancelTrigger`.
 *
 * Also allows to focus the textarea on mount by setting `focusOnMount`.
 */
export const TextAreaKeyControlled: FC<TextAreaKeyControlledProps> = ({
  focusOnMount,
  onMount = textArea => textArea.select(),
  onSaveTrigger = event => !event.shiftKey && event.key === 'Enter',
  onSave,
  onCancelTrigger = event => event.key === 'Escape',
  onCancel,
  ...restProps
}) => {
  const textAreaRef = useRef<TextAreaRef>(null);
  useEffect(() => {
    if (!focusOnMount) {
      return;
    }
    const textArea = textAreaRef.current?.resizableTextArea?.textArea;
    if (!textArea) {
      return;
    }
    textArea.focus();
    onMount?.(textArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusOnMount]);

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (onSaveTrigger?.(event)) {
      event.preventDefault();
      onSave();
    }
    if (onCancelTrigger?.(event)) {
      event.preventDefault();
      onCancel();
    }
    restProps.onKeyDown?.(event);
  };

  return <Input.TextArea ref={textAreaRef} onKeyDown={onKeyDown} {...restProps} />;
};
