import {ThemeConfig} from 'antd';
import {RequiredMarkAfterLabel} from './components/RequiredMarkAfterLabel';
import {ConfigConsumerProps} from 'antd/es/config-provider/context';

export const AntdTheme: ThemeConfig = {
  // Remove css-dev-only-do-not-override class - see https://github.com/ant-design/ant-design/discussions/38753
  hashed: false,
  token: {
    colorPrimary: '#40a2ff',
    fontFamily: 'Akkurat, Arial, Helvetica, sans-serif',
    fontSize: 14,
    lineHeight: 1.48,
  },
};

export const AntdFormConfig: ConfigConsumerProps['form'] = {
  requiredMark: RequiredMarkAfterLabel,
};
