import numeral from 'numeral';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import {PortCongestionVisit} from '../../../../api/node-backend/generated';

export const durationColumn = (): CustomColumnDef<PortCongestionVisit> => ({
  header: () => (
    <span>
      Waiting
      <br />
      Working
    </span>
  ),
  id: 'duration',
  accessorFn: d => (
    <span style={{textAlign: 'right'}}>
      {numeral(d.waitingDuration / 24).format('0[.]0')} d
      <br />
      {numeral(d.portDuration / 24).format('0[.]0')} d
    </span>
  ),
  cell: info => info.getValue(),
  minWidth: 4,
});
