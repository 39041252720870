import {accountManagementApi} from '../../../api/symfony/symfonyApi';
import {MutationOptions, useMutation} from '@tanstack/react-query';
import {ApiError} from '../../../api/utils/ApiError';

export const useRemoveProfilePictureMutation = (options?: MutationOptions<void, ApiError>) => {
  return useMutation({
    mutationFn: () =>
      accountManagementApi.updateAvatar({
        file: undefined,
      }),
    ...options,
  });
};
