import {Col, Row} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {Button} from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon';
import {useCommodityCategoriesQuery} from '../../../queries/useCommodityCategoriesQuery';
import {getIconForCommodity} from '../../../utils/helper';
import {AccountSegmentCheckboxButton} from './AccountSegmentCheckboxButton';
import {SegmentCenterContainer} from './sharedComponents/SegmentCenterContainer';
import {SegmentHeadline} from './sharedComponents/SegmentHeadline';
import {SegmentSubHeadline} from './sharedComponents/SegmentSubHeadline';

export const CommoditySelector: FC<{
  values: string[];
  onChange: (values: string[]) => void;
}> = ({values, onChange}) => {
  const commodityQuery = useCommodityCategoriesQuery();
  const commodities = commodityQuery.data?.items ?? [];
  const hasSelectedElements = values.length !== 0;

  const unselectAll = () => {
    onChange([]);
  };

  const selectAll = () => {
    const newList: string[] = commodities.map(commodity => commodity.slug);
    onChange(newList);
  };

  return (
    <>
      <SegmentCenterContainer>
        <div>
          <SegmentHeadline>What commodities do you focus on?</SegmentHeadline>
        </div>
        <div>
          <SegmentSubHeadline>Select all that apply</SegmentSubHeadline>
        </div>
        <Row gutter={[16, 16]}>
          {commodities.map(commodity => {
            const isActive = values.includes(commodity.slug);
            return (
              <Col key={commodity.slug} md={12}>
                {
                  <AccountSegmentCheckboxButton
                    isSmall
                    dataTestid={`CommoditySelectorButton_${commodity.slug}`}
                    onClick={() => {
                      if (isActive) {
                        onChange(values.filter(key => key !== commodity.slug));
                        return;
                      }
                      onChange([...values, commodity.slug]);
                    }}
                    checked={isActive}>
                    <StyledDivRow>
                      <StyledIconCell>
                        <StyledIcon type={getIconForCommodity(commodity.slug)} color={isActive ? 'blue' : 'white'} />
                      </StyledIconCell>
                      <StyledDivCell>
                        <StyledSpan>{commodity.name}</StyledSpan>
                      </StyledDivCell>
                    </StyledDivRow>
                  </AccountSegmentCheckboxButton>
                }
              </Col>
            );
          })}
        </Row>
        <StyledRow gutter={[16, 16]} style={{justifyContent: 'center'}}>
          <ButtonContainer>
            <Button
              label={hasSelectedElements ? 'Unselect all' : 'Select all'}
              bold
              onClick={hasSelectedElements ? unselectAll : selectAll}
            />
          </ButtonContainer>
        </StyledRow>
      </SegmentCenterContainer>
    </>
  );
};

const StyledIcon = styled(Icon)`
  font-size: var(--font-size-xl);
`;

const StyledSpan = styled.span`
  font-size: var(--font-size-md);
`;

const StyledRow = styled(Row)`
  padding: 0 40px;
`;

const ButtonContainer = styled.div`
  margin: 16px 10px 48px 10px;
  display: flex;
`;

const StyledDivRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledDivCell = styled.div`
  display: flex;
  align-items: center;
`;
const StyledIconCell = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;
