import dayjs, {Dayjs} from 'dayjs';
import {PreparedAisVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {FilterParams} from '../filterParams';
import {Step0Result} from './allVessels';
import {StepResult} from './StepResult';

export type Step0aResult = StepResult<PreparedAisVessel>;

export const step0a = ({
  params,
  preResult,
  currentTime,
}: {
  preResult: Step0Result;
  params: FilterParams;
  currentTime?: Dayjs;
}): Step0aResult => {
  let vesselList = [...preResult.vessels];

  if (!params.enabledFilters.dataActuality) {
    return {
      vessels: vesselList,
      loggedFilters: [...preResult.loggedFilters],
    };
  }

  vesselList = vesselList.filter(vessel => {
    return dayjs(currentTime).subtract(10, 'day').isBefore(dayjs(vessel.updatedAt));
  });

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: `Data updated w. 10d`,
        count: vesselList.length,
        id: 'updatedAt',
      },
    ],
  };
};
