import React, {FC, useState} from 'react';
import {ListHeader} from '../NegotiationDetail/Components/TermArea/ListHeader';
import {TermListDivider} from '../NegotiationDetail/Components/TermArea/CreateArea';
import {EmptyBanner} from '../Components/shared';
import {TermParserModalButton} from '../NegotiationDetail/Components/TermArea/Terms/TermParser/TermParserModalButton';
import {NegotiationTemplate} from '../../../api/node-backend/generated';
import {DragAndDropList} from '../../../components/DragAndDropList/DragAndDropList';
import {createMockTerm} from '../NegotiationDetail/Components/TermItem/test-utils/createMockTerm';
import {NegotiationActions} from '../NegotiationDetail/Components/CommitArea/utils/useGetNegotiationActionsWithMutations';
import {TermActions} from '../NegotiationDetail/Components/TermItem/utils/useGetTermActionsWithMutations';
import {TermItemTemplate} from './TermItemTemplate/TermItemTemplate';
import {TermItemTemplateCreate} from './TermItemTemplate/TermItemTemplateCreate';
import {InfoCircleOutlined} from '@ant-design/icons';
import {PlaceholderInfoModal} from './PlaceholderInfoModal';
import {TermsContainer} from '../NegotiationDetail/Components/TermArea/shared';

type TemplateTermsProps = {
  template: NegotiationTemplate;
  canBeEdited: boolean;
  negotiationActions: NegotiationActions;
  getTermActions: (id: number) => TermActions;
};

export const TemplateTerms: FC<TemplateTermsProps> = ({template, canBeEdited, negotiationActions, getTermActions}) => {
  const {createTerms, updateSortIndex} = negotiationActions;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <PlaceholderInfoModal open={showModal} onClose={() => setShowModal(false)} />
      <ListHeader
        additionalContent={
          canBeEdited ? (
            <span style={{marginLeft: 40, cursor: 'pointer'}} onClick={() => setShowModal(true)}>
              <InfoCircleOutlined /> How to use placeholders
            </span>
          ) : null
        }
      />
      <TermsContainer>
        {template.terms.length ? (
          <DragAndDropList
            items={template.terms}
            canUpdateSort={canBeEdited}
            onUpdateSort={updateSortIndex}
            renderTermItem={(idx, item, dragListeners) => (
              <TermItemTemplate
                idx={idx}
                term={createMockTerm({...item, id: idx.toString()})}
                dragListeners={dragListeners}
                canBeEdited={canBeEdited}
                termActions={getTermActions(idx)}
              />
            )}
          />
        ) : (
          <EmptyBanner
            block
            hideImage
            headline="No terms yet"
            subHeadline={
              <>
                <TermParserModalButton onCreateTerms={createTerms} />
                <b>or add terms below</b>
              </>
            }
          />
        )}
      </TermsContainer>
      {canBeEdited && (
        <>
          <TermListDivider />
          <TermItemTemplateCreate onAdd={term => createTerms([{...term, sortIndex: template.terms.length}])} />
        </>
      )}
    </>
  );
};
