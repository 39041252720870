/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {NewsItemRead, NewsItemReadFromJSON, NewsItemReadFromJSONTyped, NewsItemReadToJSON} from './';

/**
 *
 * @export
 * @interface NewsItemListResponse
 */
export interface NewsItemListResponse {
  /**
   *
   * @type {Array<NewsItemRead>}
   * @memberof NewsItemListResponse
   */
  items: Array<NewsItemRead>;
  /**
   *
   * @type {number}
   * @memberof NewsItemListResponse
   */
  totalItems: number;
}

export function NewsItemListResponseFromJSON(json: any): NewsItemListResponse {
  return NewsItemListResponseFromJSONTyped(json, false);
}

export function NewsItemListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItemListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(NewsItemReadFromJSON),
    totalItems: json['totalItems'],
  };
}

export function NewsItemListResponseToJSON(value?: NewsItemListResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(NewsItemReadToJSON),
    totalItems: value.totalItems,
  };
}
