import {CookieManagement, UserManagement} from '@piwikpro/react-piwik-pro';
import {useEffect} from 'react';
import {useUserOrNull} from '../UserContext/useUser';

export const PiwikManager = () => {
  const user = useUserOrNull();

  useEffect(() => {
    const trackUser = async () => {
      if (user) {
        if ((await UserManagement.getUserId()) !== user.id.toString()) {
          UserManagement.setUserId(user.id.toString());
          CookieManagement.enableCookies();
        }
      } else {
        UserManagement.resetUserId();
      }
    };
    void trackUser();
  }, [user]);

  return null;
};
