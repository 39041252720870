import React, {Component} from 'react';
import debounce from 'lodash/debounce';

import cx from 'classnames';
import Icon from '../../atoms/Icon';
import {clamp} from '../../utils/helper';
import './speed-input.scss';
import {parseFloatTS} from '../../utils/parseNumberTS';

const fixedFloat = (value: $TSFixMe, fractionDigits = 2) => parseFloatTS(value).toFixed(fractionDigits);

type OwnSpeedInputProps = {
  onChange?: (value: number) => void;
  value?: number;
};

type SpeedInputState = $TSFixMe;

type Props = OwnSpeedInputProps & typeof SpeedInput.defaultProps;

class SpeedInput extends Component<Props, SpeedInputState> {
  static defaultProps = {
    unit: 'kts',
    step: 0.5,
    fractionDigits: 2,
    min: 0,
    max: 99.99,
    'data-testid': 'SpeedInput',
  };

  state = {
    value: this.props.value,
    focused: false,
  };
  _handleDebouncedOnchange = debounce((changedValue, fractionDigits, onChange) => {
    const value = clamp(fixedFloat(changedValue, fractionDigits));
    onChange(value);
    this.setState({value});
  }, 500);

  static getDerivedStateFromProps(props: $TSFixMe, state: $TSFixMe) {
    if (!state.focused && props.value !== state.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  _handleChange = (changedValue: $TSFixMe) => {
    const {fractionDigits, onChange} = this.props;
    this.setState({value: clamp(changedValue)});
    this._handleDebouncedOnchange(changedValue, fractionDigits, onChange);
  };

  onKeyDown = (e: $TSFixMe, changedValue: $TSFixMe) => {
    if (e.key === 'Enter') {
      this._handleChange(changedValue);
      e.preventDefault();
    }
  };

  render() {
    const {unit, step, fractionDigits, min = 0, max = 99.99, onChange, 'data-testid': dataTestId} = this.props;
    const {value} = this.state;
    return (
      <div className={cx('speed-input')} data-testid={dataTestId}>
        <div className="speed-input__value">
          <input
            id={'speed-input__input'}
            className={'speed-input__input'}
            value={value}
            onFocus={() => this.setState({focused: true})}
            onKeyDown={e => this.onKeyDown(e, value)}
            onBlur={() => {
              this._handleChange(clamp(fixedFloat(value, fractionDigits)));
              this.setState({focused: false});
            }}
            onChange={e => this._handleChange(e.target.value)}
            type={'number'}
            min={min}
            max={max}
            step={'any'}
          />
          <span className={'speed-input__unit'}>{unit}</span>
        </div>
        <button
          id="button-plus"
          className="speed-input__btn"
          onClick={event => {
            event.preventDefault();
            onChange?.(clamp(fixedFloat(parseFloatTS(value) + step, fractionDigits)));
          }}>
          <Icon type={'add'} />
        </button>
        <button
          id={'button-minus'}
          className={'speed-input__btn'}
          onClick={event => {
            event.preventDefault();
            onChange?.(clamp(fixedFloat(parseFloatTS(value) - step, fractionDigits)));
          }}>
          <Icon type={'minus'} />
        </button>
      </div>
    );
  }
}

export default SpeedInput;
