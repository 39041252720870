import './image-thumbnail.scss';
import Icon from '../../atoms/Icon';

export const ImageThumbnail = ({filename, url, deletable, onRemove}: $TSFixMe) => (
  <div className="image-thumbnail">
    <div className="image-thumbnail__media">
      <a href={url}>
        <img className="image-thumbnail__image" alt={filename} src={url} />
      </a>
    </div>
    <div className="image-thumbnail__description">
      <div className="image-thumbnail__title">{filename}</div>
      <div className="image-thumbnail__link">
        {deletable ? (
          <Icon type={'clear'} onClick={onRemove} style={{cursor: 'pointer'}} />
        ) : (
          <a href={url} target="_blank" rel="noopener noreferrer">
            View
          </a>
        )}
      </div>
    </div>
  </div>
);

type ImageThumbnailGridProps = {
  images?: $TSFixMe[];
  noText?: boolean;
  deletable?: boolean;
  onRemove?: (idx: number) => void;
};

const ImageThumbnailGrid = ({images, noText = false, deletable = false, onRemove}: ImageThumbnailGridProps) => {
  if (images && images.length === 0) {
    if (!noText) {
      return <span className="vessel-content__text">No images available</span>;
    } else {
      return null;
    }
  }
  return (
    <div className="image-thumbnail__grid">
      {images?.map((image, key) => (
        <ImageThumbnail key={key} {...image} deletable={deletable} onRemove={() => onRemove?.(key)} />
      ))}
    </div>
  );
};

export default ImageThumbnailGrid;
