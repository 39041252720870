import {FC, useEffect, useState} from 'react';
import {Timeframe} from '../model/Timeframe';
import {VoyageAnimationPortVisit} from '../model/VoyageAnimationPortVisit';
import {useAISRecordsQuery} from '../queries/aisRecordsQuery/useAISRecordsQuery';
import {useVoyageAnimationApi} from '../VoyageAnimation/VoyageAnimationApi';
import {TimeControl} from '../model/TimeControl';
import {DEFAULT_SPEED} from '../VoyageAnimationDemo/forms/TimeControlForm/TimeControlForm';
import {VoyageAnimation} from '../VoyageAnimation/VoyageAnimation';
import {PortVisit} from '../../../../../api/node-backend/generated';
import {useVoyageAnimationPortVisits} from '../queries/useVoyageAnimationPortVisits';
import {VesselTypeKey} from '../../../../../components/consts/vesselTypes';
import {getVesselTypeKey} from '../layers/VesselIconLayer/getVesselIcon';
import {getAisRecordPlaceholders} from '../VoyageAnimation/PlaceholderAisRecords';
import {CoordinatesObject} from '../../../../../utils/Coordinates';
import styled from 'styled-components';
import {AnimationControls} from './AnimationControls/AnimationControls';
import {VoyageTimeSeriesEntry} from '../../../VoyageTimeline/utils/calculateVoyageTimeSeries';
import {Dayjs} from 'dayjs';

export const VoyageTimelineAnimation: FC<{
  imo: number | null;
  timeframe: Timeframe;
  portVisits: PortVisit[];
  vesselType: string | null;
  vesselCoordinates: CoordinatesObject | null;
  isPlaying?: boolean;
  autoPlay: boolean;
  voyageTimeSeries: VoyageTimeSeriesEntry[];
  minDate: Dayjs;
  selectedDate?: Dayjs;
  onChangeSelectedDate: (selectedDate: Dayjs) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onToggleAutoPlay: () => void;
  onAnimationTimestampUpdate?: (timestamp: number) => void;
}> = ({
  imo,
  vesselType,
  vesselCoordinates,
  timeframe,
  portVisits,
  isPlaying,
  autoPlay,
  voyageTimeSeries,
  minDate,
  selectedDate,
  onChangeSelectedDate,

  onPlay,
  onPause,
  onToggleAutoPlay,
  onAnimationTimestampUpdate,
}) => {
  if (!imo) {
    // eslint-disable-next-line no-console
    console.warn('Missing imo');
    return null;
  }
  const vesselTypeKey = getVesselTypeKey(vesselType);

  return (
    <StyledVoyageTimelineAnimation data-testid="VoyageTimelineAnimation">
      <VoyageTimelineAnimationBody
        imo={imo}
        vesselType={vesselTypeKey}
        vesselCoordinates={vesselCoordinates}
        timeframe={timeframe}
        portVisits={portVisits}
        isPlaying={isPlaying}
        autoPlay={autoPlay}
        voyageTimeSeries={voyageTimeSeries}
        minDate={minDate}
        selectedDate={selectedDate}
        onChangeSelectedDate={onChangeSelectedDate}
        onPlay={onPlay}
        onPause={onPause}
        onToggleAutoPlay={onToggleAutoPlay}
        onAnimationTimestampUpdate={onAnimationTimestampUpdate}
      />
    </StyledVoyageTimelineAnimation>
  );
};

const VoyageTimelineAnimationBody: FC<{
  imo: number;
  vesselType: VesselTypeKey;
  vesselCoordinates: CoordinatesObject | null;
  timeframe: Timeframe;
  portVisits: PortVisit[];
  isPlaying?: boolean;
  autoPlay: boolean;
  voyageTimeSeries: VoyageTimeSeriesEntry[];
  minDate: Dayjs;
  selectedDate?: Dayjs;
  onChangeSelectedDate: (selectedDate: Dayjs) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onToggleAutoPlay: () => void;
  onAnimationTimestampUpdate?: (timestamp: number) => void;
}> = ({
  imo,
  vesselType,
  vesselCoordinates,
  timeframe,
  portVisits,
  isPlaying,
  autoPlay,
  voyageTimeSeries,
  minDate,
  selectedDate,
  onChangeSelectedDate,
  onPlay,
  onPause,
  onToggleAutoPlay,
  onAnimationTimestampUpdate,
}) => {
  const voyageAnimationApi = useVoyageAnimationApi();
  const probeAisRecordsQuery = useAISRecordsQuery({imo, timeframe});
  const [animationTimeframe, setAnimationTimeframe] = useState<Timeframe>(timeframe);
  const [animationAisRecords, setAnimationAisRecords] = useState(() => getAisRecordPlaceholders(vesselCoordinates));
  const [timeControl, _setTimeControl] = useState<TimeControl>(() => {
    const initialValue: TimeControl = {speed: DEFAULT_SPEED, playing: false};
    return initialValue;
  });
  // const timeframeSelected = !timeframe.start.isSame(timeframe.end, 'minute');

  const voyageAnimationPortVisits: VoyageAnimationPortVisit[] = useVoyageAnimationPortVisits({
    portVisits,
    timeframe: animationTimeframe,
  });

  const probeAisRecordsLength = probeAisRecordsQuery.isSuccess ? probeAisRecordsQuery.data.length : 0;

  // When the timeframe changes, we check first if we get enough data to build an animation from.
  // If yes, we build a new animation.
  useEffect(
    () => {
      if (timeframe === animationTimeframe) {
        return;
      }
      if (!probeAisRecordsQuery.isSuccess) {
        return;
      }
      if (probeAisRecordsLength >= 2) {
        // Build a new animation
        setAnimationTimeframe(timeframe);
        setAnimationAisRecords(probeAisRecordsQuery.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeframe, probeAisRecordsQuery.isSuccess, probeAisRecordsLength, animationTimeframe]
  );

  const onDateChangeForced = (date: Dayjs | null) => {
    if (!date) {
      return;
    }
    onPause?.();
    onChangeSelectedDate(date);
  };

  useEffect(() => {
    if (!isPlaying) {
      voyageAnimationApi.animeTimeline?.pause();
    } else {
      voyageAnimationApi.animeTimeline?.play();
    }
  }, [isPlaying, voyageAnimationApi.animeTimeline]);

  return (
    <VoyageTimelineAnimationContainer>
      <AnimationControls
        voyageTimeSeries={voyageTimeSeries}
        minDate={minDate}
        selectedDate={selectedDate}
        onDateChange={onDateChangeForced}
        isPlaying={isPlaying}
        autoPlay={autoPlay}
        onPlay={onPlay}
        onPause={onPause}
        onToggleAutoPlay={onToggleAutoPlay}
      />
      <VoyageAnimation
        voyageAnimationApi={voyageAnimationApi}
        vesselType={vesselType}
        timeControl={timeControl}
        aisRecords={animationAisRecords}
        portVisits={voyageAnimationPortVisits}
        tripsLayerVisible
        portAreaLayerVisible
        routeLayerVisible={false}
        staticAnimationEventsLayerVisible={false}
        autoplay={autoPlay}
        onPortEntered={() => {}}
        onLegClick={() => {}}
        onAimationEventClick={() => {}}
        onVesselClick={() => {}}
        onAnimationReady={() => {}}
        onAnimationTimestampUpdate={onAnimationTimestampUpdate}
      />
    </VoyageTimelineAnimationContainer>
  );
};

const VoyageTimelineAnimationContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-lg);
  will-change: height, width;
  transition:
    height 0.3s,
    width 0.3s;
  background-color: var(--color-blue-dark);
  overflow: hidden;
`;

const StyledVoyageTimelineAnimation = styled.div`
  width: 100%;
  height: 100%;
`;
