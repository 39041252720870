/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateNotificationRequestEventEnum,
  CreateNotificationRequestEventEnumFromJSON,
  CreateNotificationRequestEventEnumToJSON,
  TestValidatorBody,
  TestValidatorBodyFromJSON,
  TestValidatorBodyToJSON,
} from '../models';

export interface NotifyMeRequest {
  event: CreateNotificationRequestEventEnum;
}

export interface TestValidatorRequest {
  testValidatorBody: TestValidatorBody;
}

/**
 *
 */
export class HelloApi extends runtime.BaseAPI {
  /**
   */
  async helloControllerHelloRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/node-backend`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async helloControllerHello(): Promise<void> {
    await this.helloControllerHelloRaw();
  }

  /**
   */
  async helloControllerHelloMailRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/hello/mail`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async helloControllerHelloMail(): Promise<void> {
    await this.helloControllerHelloMailRaw();
  }

  /**
   */
  async notifyMeRaw(requestParameters: NotifyMeRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.event === null || requestParameters.event === undefined) {
      throw new runtime.RequiredError(
        'event',
        'Required parameter requestParameters.event was null or undefined when calling notifyMe.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.event !== undefined) {
      queryParameters['event'] = requestParameters.event;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/hello/notify-me`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async notifyMe(requestParameters: NotifyMeRequest): Promise<void> {
    await this.notifyMeRaw(requestParameters);
  }

  /**
   */
  async testValidatorRaw(requestParameters: TestValidatorRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.testValidatorBody === null || requestParameters.testValidatorBody === undefined) {
      throw new runtime.RequiredError(
        'testValidatorBody',
        'Required parameter requestParameters.testValidatorBody was null or undefined when calling testValidator.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/node-backend/test-validator`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TestValidatorBodyToJSON(requestParameters.testValidatorBody),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async testValidator(requestParameters: TestValidatorRequest): Promise<void> {
    await this.testValidatorRaw(requestParameters);
  }
}
