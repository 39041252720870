import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {stringLengthLessThanOrEqualTo} from '../../../../../utils/validators';
import {convertLabelToSlug} from '../../FilterConversion/utils/FilterConversionUtils';
import {LabeledValue} from '../../../../../utils/LabeledValue';

export interface DatabaseType {
  registeredOwner?: string;
  managingOwner?: string;
  disponentOwner: string[];
  broker?: string;
}

export interface InternalType {
  registeredOwner: string;
  disponentOwner: LabeledValue[];
  managingOwner: string;
  broker: LabeledValue | '';
}

export const Defaults: InternalType = {
  registeredOwner: '',
  disponentOwner: [],
  managingOwner: '',
  broker: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    broker: database.broker ? {label: database.broker, value: database.broker} : '',
    managingOwner: database.managingOwner ?? '',
    registeredOwner: database.registeredOwner ?? '',
    disponentOwner: database.disponentOwner.map(label => ({
      label,
      value: convertLabelToSlug(label),
    })),
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const database: DatabaseType = {
    ...internal,
    disponentOwner: (internal.disponentOwner ?? []).map(disponent => disponent.label),
    broker: internal.broker === '' ? undefined : (internal.broker.value as string),
  };

  if (internal.broker === undefined) {
    delete database.broker;
  }
  if (internal.managingOwner === '') {
    delete database.managingOwner;
  }
  if (internal.registeredOwner === '') {
    delete database.registeredOwner;
  }

  return database;
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Owner / Charterer',
  branch: 'fixtureCargo',
  defaults: Defaults,

  fromDatabase,
  toDatabase,
  validators: {
    registeredOwner: stringLengthLessThanOrEqualTo(191),
    managingOwner: stringLengthLessThanOrEqualTo(191),
  },
});
