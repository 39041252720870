import React, {FC} from 'react';
import Icon from '../../../../../../../atoms/Icon';
import styled from 'styled-components';
import {Tooltip} from 'antd';

export const AddTermSubButton: FC<{onClick: () => void}> = ({onClick}) => {
  return (
    <Tooltip title="Add subject" trigger="hover">
      <SubButton onClick={onClick}>
        <Icon type="block" color="light-gray" size="small" />
      </SubButton>
    </Tooltip>
  );
};

export const SubButton = styled.button`
  position: absolute;
  right: -22px;
  top: 5px;
  display: none; // shows on hover
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  padding: 0;
  background-color: var(--color-bg);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: background-color 0.1s;
`;
