import {QueryOptions, UseQueryResult, useQuery} from '@tanstack/react-query';
import {CommodityCategoryResponseData} from '../api/symfony/generated/models';
import {commodityApi} from '../api/symfony/symfonyApi';

export const CommodityCategoriesQueryKey = 'commodityApi.getCommodityCategories';

type Params = {
  includeArchived?: boolean;
  options?: QueryOptions<CommodityCategoryResponseData>;
};

export const useCommodityCategoriesQuery = (params?: Params): UseQueryResult<CommodityCategoryResponseData> =>
  useQuery({
    queryKey: [CommodityCategoriesQueryKey, !!params?.includeArchived],
    queryFn: async () => {
      const response = await commodityApi.getCommodityCategories({
        includeArchived: params?.includeArchived,
      });
      return response.data || null;
    },
    ...params?.options,
  });
