/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ContactDetails
 */
export interface ContactDetails {
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  streetNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  zipCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  country?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  website?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  telephone?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  fax?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactDetails
   */
  skype?: string | null;
}

export function ContactDetailsFromJSON(json: any): ContactDetails {
  return ContactDetailsFromJSONTyped(json, false);
}

export function ContactDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    street: !exists(json, 'street') ? undefined : json['street'],
    streetNumber: !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
    zipCode: !exists(json, 'zipCode') ? undefined : json['zipCode'],
    city: !exists(json, 'city') ? undefined : json['city'],
    country: !exists(json, 'country') ? undefined : json['country'],
    website: !exists(json, 'website') ? undefined : json['website'],
    telephone: !exists(json, 'telephone') ? undefined : json['telephone'],
    fax: !exists(json, 'fax') ? undefined : json['fax'],
    skype: !exists(json, 'skype') ? undefined : json['skype'],
  };
}

export function ContactDetailsToJSON(value?: ContactDetails | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    street: value.street,
    streetNumber: value.streetNumber,
    zipCode: value.zipCode,
    city: value.city,
    country: value.country,
    website: value.website,
    telephone: value.telephone,
    fax: value.fax,
    skype: value.skype,
  };
}
