import React, {FC, Fragment, useState} from 'react';
import cx from 'classnames';
import Icon from '../../../atoms/Icon';
import './style.scss';
import {VesselLegend} from './VesselLegend';
import {CargoesLegend} from './CargoesLegend';
import {CommoditiesLegend} from './CommoditiesLegend';

const MapLegend: FC<{vesselIconStyle: string}> = ({vesselIconStyle}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <button className="map-legend-btn" onClick={() => setIsOpen(!isOpen)}>
        <Icon type="table" /> Legend
      </button>
      <div className={cx('map-legend', {'map-legend--visible': isOpen})}>
        <div className="map-legend__header">
          LEGEND
          <button className="map-legend__close-btn" onClick={() => setIsOpen(false)}>
            <Icon type="clear" />
          </button>
        </div>
        <div className="map-legend__content">
          <VesselLegend vesselIconStyle={vesselIconStyle} />
          <CargoesLegend />
          <CommoditiesLegend />
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(MapLegend);
