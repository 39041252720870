import dayjs from 'dayjs';
import {FC} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import {socialApi} from '../../api/symfony/symfonyApi';
import Avatar from '../../atoms/Avatar';
import {NotFound} from '../../atoms/EmptyState/404';
import LoadingComponent from '../../components/DataGrid/LoadingComponent';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {assert} from '../../utils/assert';
import {ContactDetail} from './ContactDetail';
import './style.scss';
import {MemberAtCompany} from './MemberAtCompany';
import {useUser} from '../../components/UserContext/useUser';
import {Space} from 'antd';
import {MenuItem, MenuItemLink} from '../../atoms/Menu/MenuItem';

interface MatchParams {
  id: string;
}

export const UserProfileScreenWithoutContacts: FC = () => {
  const currentUser = useUser();
  const loggedInUser = currentUser.payload;
  const params = useParams<keyof MatchParams>();
  const userIdString = params.id || '';
  assert(userIdString.match(/^(\d+)$/), 'User id must be a number');
  const userId = parseInt(userIdString, 10);
  const userQuery = useQuery({
    queryKey: ['user', userId],
    queryFn: () => socialApi.getUser({userId}),
  });

  if (userQuery.isLoading) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }

  if (!userQuery.isSuccess) {
    return <NotFound />;
  }
  const user = userQuery.data;

  const isOwnProfile = loggedInUser?.id === user.userID;
  const {registrationDate, contactDetails} = user;

  return (
    <div className="user-profile">
      <ScreenHeader
        helmetTitle={user.fullName}
        breadcrumbs={[{title: 'User profile'}]}
        actions={
          <Space size={4}>
            <ScreenHeader.Actions.Menu
              position={'right'}
              items={[
                {
                  key: 'profile-picture',
                  isValid: isOwnProfile,
                  label: <MenuItemLink label="Profile picture" href="/user-account/profile-picture" />,
                },
                {
                  key: 'edit-profile',
                  isValid: isOwnProfile,
                  label: <MenuItemLink label="Edit profile" href="/user-account/profile" />,
                },
                {
                  key: 'send-email',
                  isValid: !isOwnProfile && !!user.email,
                  label: (
                    <MenuItem
                      label="Send email"
                      onClick={() => {
                        window.location.href = `mailto:${user.email && encodeURIComponent(user.email)}`;
                      }}
                    />
                  ),
                },
              ]}>
              New Negotiation
            </ScreenHeader.Actions.Menu>
          </Space>
        }
      />
      <div className="user-profile__top">
        <div className="user-profile__top-left">
          <Avatar imageUrl={user.avatar} title={user.fullName} size="huge" />
          <div className="user-profile__main-info">
            <div>
              <h1 className="user-profile__title">{user.fullName}</h1>
              <MemberAtCompany user={user} />
            </div>
          </div>
        </div>

        <div className="user-profile__top-right">
          <ContactDetail label="MEMBER SINCE" value={dayjs(registrationDate).format('MMM YYYY')} />
          <ContactDetail label="E-MAIL" value={user.email} />
          <ContactDetail label="WEBSITE" value={contactDetails.website} />
          <ContactDetail label="PHONE" value={contactDetails.telephone} />
          <ContactDetail label="FAX" value={contactDetails.fax} />
          <ContactDetail label="SKYPE" value={contactDetails.skype} />
          <ContactDetail
            label="POSTAL ADDRESS"
            value={[
              [contactDetails.country],
              [contactDetails.zipCode, contactDetails.city],
              [contactDetails.street, contactDetails.streetNumber],
            ]
              .map(line => line.filter(column => !!column))
              .filter(line => line.length > 0)
              .map(line => line.join(' '))
              .join('\n')}
          />
          {[...Object.values(contactDetails), user.email].filter(e => !!e).length === 0 && (
            <span>No contact details</span>
          )}
        </div>
      </div>
    </div>
  );
};
