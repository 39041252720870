import {FC, useRef, useState} from 'react';
import styled from 'styled-components';
import {TourStepMediaVideo, TourStepMediaVideoKeyframe} from '../TourDefinition';
import {Button} from 'antd';

export const defaultPlaceholderImageUrl = '/static/images/seanexxt/tour/placeholder.jpg';
const PLAY_NEXT_DELAY = 2;

type TourStepVideoProps = {
  media: TourStepMediaVideo;
  autoPlay?: boolean;
  muted?: boolean;
  hasNext?: boolean;
  onChangeMuted?: (muted: boolean) => void;
  onChangeAutoPlay?: (autoPlay: boolean) => void;
  onPlayNext?: () => void;
};

export const TourStepVideo: FC<TourStepVideoProps> = ({
  media,
  autoPlay,
  muted,
  hasNext,
  onChangeMuted,
  onChangeAutoPlay,
  onPlayNext,
}) => {
  const {url, placeholderImageUrl, keyframes} = media;
  const videoRef = useRef<HTMLVideoElement>(null);

  // We give the user a moment to see the end of the video before moving on
  const [countdown, setCountdown] = useState<number | null>(null);
  const [currentKeyframeIndex, setCurrentKeyframeIndex] = useState<number>(0);

  const onTimeUpdate = () => {
    const video = videoRef.current!;
    if (!keyframes) {
      return;
    }
    const currentKeyframes = keyframes.filter(keyframe => keyframe.timestampSeconds <= video.currentTime);
    setCurrentKeyframeIndex(currentKeyframes.length - 1);
  };

  const onEnded = () => {
    if (!autoPlay || !hasNext) {
      return;
    }
    setCountdown(PLAY_NEXT_DELAY);
    let i = PLAY_NEXT_DELAY;
    const interval = setInterval(() => {
      setCountdown(t => t! - 1);
      i--;
      if (i <= 0) {
        clearInterval(interval);
        setCountdown(null);
        onPlayNext?.();
      }
    }, 1000);
  };

  const selectKeyframe = (keyframe: TourStepMediaVideoKeyframe) => {
    setCurrentKeyframeIndex(keyframes!.indexOf(keyframe));
    videoRef.current!.currentTime = keyframe.timestampSeconds;
  };

  const showNextStepAnnouncement = hasNext && autoPlay && countdown !== null;

  return (
    <>
      <VideoContainer>
        <Video
          key={url}
          ref={videoRef}
          controls
          autoPlay={autoPlay}
          muted={muted}
          poster={placeholderImageUrl ?? defaultPlaceholderImageUrl}
          onTimeUpdate={onTimeUpdate}
          onEnded={onEnded}
          onVolumeChange={e => onChangeMuted?.(e.currentTarget.muted)}
          style={{width: '100%'}}>
          <source src={url} type="video/mp4" />
        </Video>
        {keyframes && (
          <KeyframeContainer>
            <KeyframeBar>
              {keyframes.map((keyframe, index) => (
                <KeyframeButton
                  key={index}
                  $isActive={index <= currentKeyframeIndex}
                  $width={(1 / keyframes.length) * 100}
                  onClick={() => selectKeyframe(keyframe)}>
                  {keyframe.label} <br />
                  {Math.floor(keyframe.timestampSeconds / 60)}:
                  {Math.floor(keyframe.timestampSeconds % 60)
                    .toString()
                    .padStart(2, '0')}
                </KeyframeButton>
              ))}
            </KeyframeBar>
            <KeyframeText>{keyframes?.[currentKeyframeIndex]?.text}</KeyframeText>
          </KeyframeContainer>
        )}
        {showNextStepAnnouncement && (
          <NextStepAnnouncement>Next step in {Math.ceil(countdown)} seconds</NextStepAnnouncement>
        )}
      </VideoContainer>
      {onChangeAutoPlay && hasNext && (
        <AutoPlayButton
          size="small"
          type={autoPlay ? 'primary' : 'default'}
          onClick={() => onChangeAutoPlay(!autoPlay)}>
          Auto-play {autoPlay ? 'on' : 'off'}
        </AutoPlayButton>
      )}
    </>
  );
};

const VideoContainer = styled.div`
  position: relative;
`;

const Video = styled.video`
  width: 100%;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
`;

const KeyframeContainer = styled.div`
  padding-bottom: 1px;
  background: var(--color-gray-5);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
`;

const KeyframeBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: -6px 0 10px;
`;

const KeyframeButton = styled.button<{$isActive: boolean; $width: number}>`
  width: ${({$width}) => `${$width}%`};
  padding: 2px 0 4px;
  background: var(--color-gray-4);
  font-size: 12px;
  line-height: 1;
  color: var(--color-black);
  border: none;
  border-top: 5px solid ${({$isActive}) => ($isActive ? 'var(--color-blue)' : 'var(--color-gray-3)')};
  cursor: pointer;
`;

const KeyframeText = styled.p`
  margin: 10px;
  font-size: var(--font-size-md);
`;

const NextStepAnnouncement = styled.div`
  position: absolute;
  bottom: 100px;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
`;

const AutoPlayButton = styled(Button)`
  width: 100%;
`;
