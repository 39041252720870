import React, {useContext, useState} from 'react';
import {ConfigProvider, Form, Table} from 'antd';
import OtherCostDrawer from './OtherCostDrawer';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {FuelEditor} from './FuelEditor';
import {OtherCostSpan} from './OtherCostSpan';
import {StyledCard} from '../components/StyledCard';
import Icon from '../../../../atoms/Icon';
import {StyledTitleFormItem} from '../components/StyledTitleFormItem';
import styled from 'styled-components';
import {Button} from '../../../../atoms/Button/Button';
import {WidgetTitle} from '../components/WidgetTitle';
import {CostIndicator} from './CostIndicator';
import {useBunkerPriceProposalsQuery} from '../utils/useBunkerPriceProposalsQuery';
import {CostInput} from './CostTypes';
import {InlineNumberEditor} from '../../../../atoms/InlineNumberEditor/InlineNumberEditor';
import numeral from 'numeral';
import {ROBInput} from './ROBInput/ROBInput';
import {TODO} from '../../../../utils/TODO';
import {Warning} from '../Warning/Warning';

export const CostInformation = (props: {showBody: boolean; onChangeShowBody: (showBody: boolean) => void}) => {
  const voyageContext = useContext(VoyageCharterContext);

  const {setInputState} = voyageContext;
  const {inputState, outputState} = voyageContext.state;

  const data = inputState.cost;

  const updateCostInputState = (newCostInput: Partial<CostInput>) => {
    setInputState({...inputState, cost: {...inputState.cost, ...newCostInput}});
  };

  const onUpdate = (key: string, value: TODO) => {
    updateCostInputState({[key]: value});
  };

  const bunkerPriceProposalsQuery = useBunkerPriceProposalsQuery({voyagePoints: inputState.voyage.points});

  /*
   * null and number are possible values of the prop index of the OtherCostDrawer,
   * and undefined is to not render the modal
   */
  const [showOtherCostIndex, setShowOtherCostIndex] = useState<number | undefined | null>(undefined);

  return (
    <StyledCard
      $showBody={props.showBody}
      style={{width: '100%', paddingTop: 10}}
      title={
        <>
          <WidgetTitle
            icon="donut"
            title="Expenses"
            showBody={props.showBody}
            onToggleBody={() => {
              props.onChangeShowBody(!props.showBody);
            }}>
            <>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <CostIndicator name="IFO">
                  <FuelEditor
                    options={bunkerPriceProposalsQuery.data?.filter(price =>
                      ['IFO380', 'IFO180', 'HSFO', 'IFO'].includes(price.grade!)
                    )}
                    editModeAvailable={true}
                    value={inputState.cost.costIfoPerMts ?? 0}
                    onChange={value => onUpdate('costIfoPerMts', value)}
                  />
                </CostIndicator>
                <CostIndicator name="VLSFO">
                  <FuelEditor
                    options={bunkerPriceProposalsQuery.data?.filter(price => price.grade === 'VLSFO')}
                    editModeAvailable={true}
                    value={inputState.cost.costVlsfoPerMts ?? 0}
                    onChange={value => onUpdate('costVlsfoPerMts', value)}
                  />
                </CostIndicator>
                <CostIndicator name="MGO/MDO">
                  <FuelEditor
                    options={bunkerPriceProposalsQuery.data?.filter(price => price.fuelType === 'mgo')}
                    editModeAvailable={true}
                    value={inputState.cost.costMgoPerMts ?? 0}
                    onChange={value => {
                      updateCostInputState({
                        costMdoPerMts: value,
                        costMgoPerMts: value,
                      });
                    }}
                  />
                </CostIndicator>
                <CostIndicator name="CO2" style={{paddingRight: 16}}>
                  <InlineNumberEditor
                    formattedValue={<span>{numeral(inputState.cost.costCo2PerMts ?? 0).format('$ 0,0')} /MT</span>}
                    editModeAvailable={true}
                    value={inputState.cost.costCo2PerMts ?? 0}
                    onChange={value => {
                      updateCostInputState({
                        costCo2PerMts: value,
                      });
                    }}
                  />
                </CostIndicator>
                <CostIndicator name="ROB" style={{height: 50}}>
                  <ROBInput />
                </CostIndicator>
                <CostIndicator name="Other">
                  <OtherCostSpan
                    value={outputState.cost.otherCostsSum}
                    editModeAvailable={!props.showBody}
                    onOpenCard={() => props.onChangeShowBody(true)}
                  />
                </CostIndicator>
              </div>
              <Warning area={'costs'} />
              <Warning area={'costs-other'} />
            </>
          </WidgetTitle>
        </>
      }>
      <div>
        <Form layout="vertical">
          <StyledTitleFormItem label={'Expenses'}>
            <ConfigProvider renderEmpty={() => null}>
              <StyledTable
                data-cy="expensesTable"
                size={'small'}
                dataSource={data.otherCosts}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      setShowOtherCostIndex(rowIndex);
                    }, // click row
                  };
                }}
                columns={[
                  {
                    key: 'name',
                    dataIndex: 'name',
                    title: 'Name',
                  },
                  {
                    key: 'cost',
                    dataIndex: 'cost',
                    title: 'Cost',
                  },
                ]}
                pagination={false}
              />
            </ConfigProvider>
          </StyledTitleFormItem>
        </Form>
        <div style={{marginBottom: 15}} className={'cargo-vessel-form__icon-button'}>
          <Button
            data-cy="addButton"
            label={
              <>
                <Icon type={'item-add-selection-solid'} color="blue" style={{marginRight: 6}} />
                {data.otherCosts.length === 0 ? 'ADD EXPENSE' : 'ADD ANOTHER EXPENSE'}
              </>
            }
            icon
            onClick={() => {
              setShowOtherCostIndex(null);
            }}
          />
        </div>
      </div>

      {showOtherCostIndex !== undefined && (
        <OtherCostDrawer index={showOtherCostIndex} onClose={() => setShowOtherCostIndex(undefined)} />
      )}
    </StyledCard>
  );
};

export const StyledTable = styled<typeof Table>(Table)`
  margin-top: 2px;

  .ant-table-thead {
    > tr {
      padding: 4px 0;
      height: 38px;

      > th {
        font-weight: 700;
        font-size: var(--font-size-sm);
        color: var(--color-gray-2);
        text-transform: uppercase;
      }
    }
  }
` as typeof Table;
