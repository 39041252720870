import Papa from 'papaparse';
import dayjs from 'dayjs';
import {AISRecord} from './AISRecord';
import {isApiErrorWithStatus} from '../../../../../../api/utils/ApiError';
import {aisRecordApi} from '../../../../../../api/node-backend/nodeBackendApis';

interface CSVRow {
  lat: string;
  lon: string;
  draft: string;
  course: string;
  heading: string;
  speed: string;
  timestamp: string;
  isOUtlier: boolean;
}

const parseCSV = async (csvString: string): Promise<CSVRow[]> => {
  const promise = new Promise<Papa.ParseResult<CSVRow>>((resolve, reject) => {
    Papa.parse<CSVRow>(csvString, {
      header: true,
      download: false,
      skipEmptyLines: true,
      worker: true,
      complete: resolve,
      error: reject,
    });
  });
  const parsed = await promise;
  if (parsed.errors.length === 0) {
    return parsed.data;
  } else {
    throw new Error(parsed.errors[0].message);
  }
};

const convertCSVRowsToAISRecords = (csvRows: CSVRow[]): AISRecord[] => {
  const aisRecords = csvRows.map((csvRow, index): AISRecord => {
    const longitude = parseFloat(csvRow.lon);
    const latitude = parseFloat(csvRow.lat);
    const heading = parseFloat(csvRow.heading);
    const speed = parseFloat(csvRow.speed);
    const timestamp = dayjs(csvRow.timestamp);
    return {
      csvRow: index + 2,
      timestamp,
      coordinates: [longitude, latitude],
      heading,
      speed,
    };
  });
  return aisRecords;
};

export const fetchAISRecords = async (imo: number, year: number): Promise<AISRecord[]> => {
  let csvString: string | null | ArrayBuffer;
  try {
    const blob = await aisRecordApi.getAISRecordsForYear({imo, year});
    csvString = await blob.text();
  } catch (error) {
    if (isApiErrorWithStatus(error, 404)) {
      return [];
    }
    throw error;
  }

  const aisRecordsFromCSV: CSVRow[] = await parseCSV(csvString);
  const aisRecords = convertCSVRowsToAISRecords(aisRecordsFromCSV);
  // eslint-disable-next-line no-console
  console.debug(`Loaded AIS data for ${imo}/${year}:`, aisRecords.length, 'records');
  return aisRecords;
};
