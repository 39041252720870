import React, {FC} from 'react';
import {getCargoContractTypeLabel} from '../../../utils/helper';
import {Attribute, TabSize} from '../../../atoms/Attribute/Attribute';
import {getCommissionTotal, getVesselType} from '../../CargoVesselForm/helper';
import {numberRangeUnitFormat, numberUnitFormat} from '../../../utils/formatter';
import styled from 'styled-components';
import {Card} from 'antd';
import {CargoItemHeader} from './CargoItemHeader';
import Cargo from '../../../model/Cargo';
import {CargoRouteMap} from './CargoRouteMap';
import {TODO} from '../../../utils/TODO';

export const VoyageHeader = ({cargo, tab = 'xl'}: {cargo: TODO; tab?: TabSize}) => {
  const isContainer = cargo.cargoType === 'container';
  const teu = cargo.intakes.find((d: $TSFixMe) => d.type === 'teu') || null;
  const vesselSize =
    cargo.vesselSizeMin !== cargo.vesselSizeMax
      ? `${numberUnitFormat(cargo.vesselSizeMin, cargo.vesselSizeUnit)} - ${numberUnitFormat(
          cargo.vesselSizeMax,
          cargo.vesselSizeUnit
        )}`
      : `${numberUnitFormat(cargo.vesselSizeMax, cargo.vesselSizeUnit)}`;

  return (
    <>
      <Attribute label="Contract type" value={getCargoContractTypeLabel(cargo.contractType)} tab={tab} />
      <Attribute label="Vessel type" value={getVesselType(cargo)} tab={tab} />
      <Attribute label="Vessel size" value={vesselSize} tab={tab} />

      <Attribute
        tab={tab}
        label="Commission"
        value={getCommissionTotal(cargo) !== '-' ? getCommissionTotal(cargo) + ' %' : null}
      />
      <Attribute
        tab={tab}
        label="Quantity"
        value={
          isContainer
            ? teu && numberUnitFormat(teu.quantity, 'teu')
            : numberRangeUnitFormat(cargo.quantityMin, cargo.quantityMax, cargo.quantityUnit)
        }
      />
      <br />
    </>
  );
};

export const OtherContractTypeHeader = ({cargo, tab = 'xl'}: {cargo: TODO; tab?: TabSize}) => {
  const vesselSize =
    cargo.vesselSizeMin !== cargo.vesselSizeMax
      ? `${numberUnitFormat(cargo.vesselSizeMin, cargo.vesselSizeUnit)} - ${numberUnitFormat(
          cargo.vesselSizeMax,
          cargo.vesselSizeUnit
        )}`
      : `${numberUnitFormat(cargo.vesselSizeMax, cargo.vesselSizeUnit)}`;

  return (
    <>
      <Attribute label="Contract type" value={getCargoContractTypeLabel(cargo.contractType)} tab={tab} />
      <Attribute
        label="Commission"
        value={getCommissionTotal(cargo) !== '-' ? getCommissionTotal(cargo) + ' %' : null}
        tab={tab}
      />
      <br />
      <Attribute label="Vessel type" value={getVesselType(cargo)} tab={tab} />
      <Attribute label="Vessel size" value={vesselSize} tab={tab} />
      <Attribute label="Max vessel age" value={cargo.maxVesselAge && cargo.maxVesselAge + ' years'} tab={tab} />
    </>
  );
};

type CargoDetailHeaderProps = {
  cargo: Cargo;
  showDetailLink?: boolean;
};

export const CargoDetailHeader: FC<CargoDetailHeaderProps> = ({cargo, showDetailLink}) => {
  return (
    <CargoHeaderContainer>
      <BoxThird>
        <CargoItemHeader cargo={cargo} showDetailLink={showDetailLink} />
        {cargo.contractType === 'vc' ? <VoyageHeader cargo={cargo} /> : <OtherContractTypeHeader cargo={cargo} />}
      </BoxThird>
      <BoxTwoThird>
        <CargoRouteMap cargo={cargo} />
      </BoxTwoThird>
    </CargoHeaderContainer>
  );
};

const CargoHeaderContainer = styled(Card)`
  min-height: 200px;
  margin-bottom: 20px;

  .ant-card-body {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px 3px;
    gap: 20px;
  }
`;

const Box = styled.div`
  height: auto;
  align-self: stretch;
`;

const BoxThird = styled(Box)`
  width: 33%;
`;

const BoxTwoThird = styled(Box)`
  width: 67%;
`;
