import {Card} from 'antd';
import {useRef} from 'react';
import styled from 'styled-components';
import {UpgradeButton} from '../../atoms/Button/UpgradeButton';
import {Icon} from '../../atoms/Icon';
import {SupportAgentsBar} from '../../components/SupportAgentsBar/SupportAgentsBar';
import {useSelector} from '../../redux/react-redux';
import {getIsPayingCustomer} from '../../redux/selectors';
import {MarketImportInstructions} from './MarketImportInstructions';

export const MarketImportInstructionsScreen = () => {
  const supportRef = useRef<HTMLDivElement>(null);

  const isPayingCustomer = useSelector(getIsPayingCustomer);

  const scrollToSupport = () => {
    supportRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Card>
      <MarketImportInstructions scrollToSupport={scrollToSupport} />
      {!isPayingCustomer && (
        <>
          <HorizontalLine />
          <FlexBox>
            <UpgradeButton style={{margin: '20px auto 10px', height: 'auto'}} to={'/subscription'}>
              Upgrade now to take full advantage <br />
              of your connected Market <StyledIcon type="arrow-right" />
            </UpgradeButton>
          </FlexBox>
        </>
      )}
      <SupportAgentsBar scrollRef={supportRef} />
    </Card>
  );
};

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

const HorizontalLine = styled.hr`
  border: var(--border-width) solid transparent;
  border-top-color: var(--border-color);
  margin: 20px 0 40px;
`;

const StyledIcon = styled(Icon)`
  font-size: var(--font-size-lg) !important;
  color: var(--color-white) !important;
  vertical-align: sub;
`;
