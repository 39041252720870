import {Fragment, FC} from 'react';
import {MapOptionChangeValue, MapSwitch, MapSwitches, MapSwitchesKey} from '../MapDetails/utils/types';
import {useMapContext} from '../MapContext/MapContext';
import {MapToggleBtn} from './MapToggleBtn';
import {renderSpecialControl} from './renderSpecialControl';
import {SubMenu} from './SubMenu';
import {SWITCHES} from '../mapOptions/switches';
import {AisFilter} from './AisFilter';
import {MapOptionSectionItem} from '../mapOptions/mapOptions';

const isChecked = (switches: MapSwitches, id: MapSwitchesKey) => switches[id]?.vState ?? switches[id]?.state;

const setAllPropsTo = (o: MapSwitch, value: boolean) => {
  const result: Record<string, boolean> = {};
  Object.keys(o)
    .filter(key => !['state', 'vState', 'vDisabled'].includes(key))
    .forEach(k => (result[k] = value));
  return result;
};

type SingleOptionProps = {
  option: MapOptionSectionItem;
  isSubMenuOpen: boolean;
  onChange: (switchId: MapSwitchesKey, value: MapOptionChangeValue) => void;
  onFilterChange: (filter: Record<string, string | string[]>) => void;
  onChangeSubMenuIsOpen: (newValue: boolean) => void;
};

export const SingleOption: FC<SingleOptionProps> = ({
  option,
  isSubMenuOpen,
  onChangeSubMenuIsOpen,
  onChange,
  onFilterChange,
}) => {
  const mapContext = useMapContext();
  const switches = mapContext.state.switches;
  const subSwitch = `${option.id}_sub` as MapSwitchesKey;
  const values = switches[subSwitch] as MapSwitch;

  return (
    <Fragment key={option.id}>
      {option.headline && <div className={'map-menu__section-headline'}>{option.headline}</div>}
      <MapToggleBtn
        id={option.id}
        checked={isChecked(switches, option.id)}
        visualDisabled={switches[option.id]?.vDisabled}
        onChange={() => {
          onChange(option.id, null);
          if (option.subMenu && !switches[option.id as MapSwitchesKey]?.state && !isSubMenuOpen) {
            onChangeSubMenuIsOpen(true);
          }
        }}
        label={option.label}
        hasSubMenu={!!option.subMenu?.length}
        isSubMenuOpen={isSubMenuOpen}
        onToggleSubMenu={() => {
          onChangeSubMenuIsOpen(!isSubMenuOpen);
        }}
        specialControl={renderSpecialControl(option, switches)}
      />
      {!!option.subMenu?.length && isSubMenuOpen && (
        <SubMenu
          options={option.subMenu}
          values={values}
          label={option.subMenuLabel}
          header={option.subMenuHeader !== false}
          onChange={subId => {
            onChange(subSwitch, {
              ...values,
              [subId]: !values?.[subId],
            });
          }}
          selectAll={() => {
            const allOn = setAllPropsTo(values, true);
            onChange(subSwitch, allOn);
          }}
          unSelectAll={() => {
            const allOff = setAllPropsTo(values, false);
            onChange(subSwitch, allOff);
          }}
          visualDisabled={!switches[option.id]?.state}
        />
      )}
      {option.id === SWITCHES.VESSEL_AIS && isSubMenuOpen
        ? switches[SWITCHES.VESSEL_AIS]?.filters && (
            <AisFilter
              disabled={!switches[SWITCHES.VESSEL_AIS]?.state}
              filters={switches[SWITCHES.VESSEL_AIS]?.filters}
              onChange={onFilterChange}
            />
          )
        : null}
    </Fragment>
  );
};
