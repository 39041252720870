/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Notification, NotificationFromJSON, NotificationFromJSONTyped, NotificationToJSON} from './';

/**
 *
 * @export
 * @interface NotificationCollection
 */
export interface NotificationCollection {
  /**
   *
   * @type {Array<Notification>}
   * @memberof NotificationCollection
   */
  items: Array<Notification>;
  /**
   *
   * @type {number}
   * @memberof NotificationCollection
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof NotificationCollection
   */
  unreadItems: number;
}

export function NotificationCollectionFromJSON(json: any): NotificationCollection {
  return NotificationCollectionFromJSONTyped(json, false);
}

export function NotificationCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationCollection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(NotificationFromJSON),
    totalItems: json['totalItems'],
    unreadItems: json['unreadItems'],
  };
}

export function NotificationCollectionToJSON(value?: NotificationCollection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(NotificationToJSON),
    totalItems: value.totalItems,
    unreadItems: value.unreadItems,
  };
}
