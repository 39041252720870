import {CargoInput, DefaultStowageFactorDisplayUnit} from './CargoTypes';
import {mapCargoToQuantityInMts} from './mapperParts/cargo/mapCargoToQuantityInMts';
import {getCommodityCategoryFormatted, getCommodityTypeFormatted} from '../../../../screens/CargoVesselForm/helper';
import {TODO} from '../../../../utils/TODO';
import {StowageFactor} from '../../../StowageFactorPicker/useStowageFactorsQuery';
import Fuse from 'fuse.js';

type Params = {
  cargo: TODO;

  /**
   * All potential stowage factors.
   */
  stowageFactors: StowageFactor[];
};

export const mapCargoToCargoInput = ({cargo, stowageFactors}: Params): CargoInput => {
  let cargoProduct = cargo.commodityCategory ?? '' + cargo.commodityType ?? '';
  if (cargo.commodityCategory !== null && cargo.commodityType !== null) {
    const commodityCategoryFormatted = getCommodityCategoryFormatted(cargo);
    const commodityTypeFormatted = getCommodityTypeFormatted(cargo);

    cargoProduct = `${commodityCategoryFormatted ? commodityCategoryFormatted : ''} (${
      commodityTypeFormatted ? commodityTypeFormatted : ''
    })`;
  }
  cargoProduct = `${(cargo.chartererName ?? 'TBN').toUpperCase()} - ${cargoProduct}`;
  const data: CargoInput = {
    cargoProduct: cargoProduct,
    stowageFactorDisplayUnit: DefaultStowageFactorDisplayUnit,
    cargoQuantity: 0,
    stowageFactor: 0,
    freightUnit: 'perMts',
    totalCommission: 0,
    freightIdea: 0,
    chartererName: cargo.chartererName ?? undefined,
  };

  data.cargoQuantity = mapCargoToQuantityInMts(cargo)!;

  data.stowageFactor = getStowageFactor({
    commodityCategory: cargo.commodityCategory,
    commodityStowageFactor: cargo.commodityStowageFactor,
    commodityType: cargo.commodityType,
    stowageFactors: stowageFactors,
  });

  data.totalCommission = cargo.commissionTotal ? Math.round(cargo.commissionTotal * 100) / 100 : undefined;

  data.contractType = cargo.contractType === null ? undefined : cargo.contractType;
  data.id = cargo.id;

  return data;
};

const getStowageFactor = ({
  stowageFactors,
  commodityCategory,
  commodityStowageFactor,
  commodityType,
}: {
  commodityCategory?: string;
  commodityType?: string;
  commodityStowageFactor?: number;
  stowageFactors: StowageFactor[];
}): number | undefined => {
  if (commodityStowageFactor) {
    return commodityStowageFactor;
  }

  const fuse = new Fuse(stowageFactors, {
    includeScore: true,
    keys: ['name'],
  });
  const result = fuse.search(`${commodityCategory} ${commodityType}`);

  const bestMatch = result[0];
  return bestMatch?.item?.stowageFactor;
};
