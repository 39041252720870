/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NegotiationSubjectModification {
  Unmodified = 'unmodified',
  NewSubject = 'newSubject',
  SubjectTextUpdated = 'subjectTextUpdated',
  SubjectAccepted = 'subjectAccepted',
  SubjectRejected = 'subjectRejected',
  RejectionRequested = 'rejectionRequested',
  RejectionRequestRejected = 'rejectionRequestRejected',
  SubjectLifted = 'subjectLifted',
  SubjectFailed = 'subjectFailed',
}

export function NegotiationSubjectModificationFromJSON(json: any): NegotiationSubjectModification {
  return NegotiationSubjectModificationFromJSONTyped(json, false);
}

export function NegotiationSubjectModificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationSubjectModification {
  return json as NegotiationSubjectModification;
}

export function NegotiationSubjectModificationToJSON(value?: NegotiationSubjectModification | null): any {
  return value as any;
}
