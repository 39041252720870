import {Tooltip} from 'antd';
import {FC} from 'react';
import {NegotiationActor} from '../../../api/node-backend/generated';

import {NotAvailable} from '../../../utils/NotAvailable';

export const NegotiationActorLabel: FC<{actor: NegotiationActor; link?: boolean; className?: string}> = ({
  actor,
  link,
  className,
}) => {
  const {email} = actor;
  const name = actor.name || NotAvailable;

  return (
    <Tooltip title={<NegotiationActorString actor={actor} />}>
      <span className={className}>{link ? <a href={`mailto:${email}`}>{name}</a> : <span>{name}</span>}</span>
    </Tooltip>
  );
};

export const NegotiationActorString = ({actor}: {actor: NegotiationActor}) => {
  const {email, companyName} = actor;
  const name = actor.name || NotAvailable;

  return (
    <>
      <span>{name.trim()}</span>
      {companyName && <span>, {companyName}</span>}
      {email && <span> ({email})</span>}
    </>
  );
};
