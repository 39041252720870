/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  JSONAllCargoData,
  JSONAllCargoDataFromJSON,
  JSONAllCargoDataFromJSONTyped,
  JSONAllCargoDataToJSON,
  JSONAllVesselData,
  JSONAllVesselDataFromJSON,
  JSONAllVesselDataFromJSONTyped,
  JSONAllVesselDataToJSON,
  OriginalSender,
  OriginalSenderFromJSON,
  OriginalSenderFromJSONTyped,
  OriginalSenderToJSON,
} from './';

/**
 *
 * @export
 * @interface JSONAllMultiData
 */
export interface JSONAllMultiData {
  /**
   *
   * @type {Array<JSONAllCargoData>}
   * @memberof JSONAllMultiData
   */
  cargoDataList?: Array<JSONAllCargoData> | null;
  /**
   *
   * @type {Array<JSONAllVesselData>}
   * @memberof JSONAllMultiData
   */
  vesselDataList?: Array<JSONAllVesselData> | null;
  /**
   *
   * @type {number}
   * @memberof JSONAllMultiData
   */
  timeOverall?: number;
  /**
   *
   * @type {string}
   * @memberof JSONAllMultiData
   */
  outputMask?: string;
  /**
   *
   * @type {string}
   * @memberof JSONAllMultiData
   */
  essence?: string;
  /**
   *
   * @type {OriginalSender}
   * @memberof JSONAllMultiData
   */
  originalSender?: OriginalSender;
  /**
   *
   * @type {string}
   * @memberof JSONAllMultiData
   */
  requestId?: string;
  /**
   *
   * @type {Array<JSONAllVesselData>}
   * @memberof JSONAllMultiData
   */
  originalVesselSynopses?: Array<JSONAllVesselData>;
  /**
   *
   * @type {Array<JSONAllVesselData>}
   * @memberof JSONAllMultiData
   */
  originalVesselSynopsis?: Array<JSONAllVesselData>;
}

export function JSONAllMultiDataFromJSON(json: any): JSONAllMultiData {
  return JSONAllMultiDataFromJSONTyped(json, false);
}

export function JSONAllMultiDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JSONAllMultiData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cargoDataList: !exists(json, 'cargoDataList')
      ? undefined
      : json['cargoDataList'] === null
      ? null
      : (json['cargoDataList'] as Array<any>).map(JSONAllCargoDataFromJSON),
    vesselDataList: !exists(json, 'vesselDataList')
      ? undefined
      : json['vesselDataList'] === null
      ? null
      : (json['vesselDataList'] as Array<any>).map(JSONAllVesselDataFromJSON),
    timeOverall: !exists(json, 'timeOverall') ? undefined : json['timeOverall'],
    outputMask: !exists(json, 'outputMask') ? undefined : json['outputMask'],
    essence: !exists(json, 'essence') ? undefined : json['essence'],
    originalSender: !exists(json, 'originalSender') ? undefined : OriginalSenderFromJSON(json['originalSender']),
    requestId: !exists(json, 'requestId') ? undefined : json['requestId'],
    originalVesselSynopses: !exists(json, 'originalVesselSynopses')
      ? undefined
      : (json['originalVesselSynopses'] as Array<any>).map(JSONAllVesselDataFromJSON),
    originalVesselSynopsis: !exists(json, 'originalVesselSynopsis')
      ? undefined
      : (json['originalVesselSynopsis'] as Array<any>).map(JSONAllVesselDataFromJSON),
  };
}

export function JSONAllMultiDataToJSON(value?: JSONAllMultiData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cargoDataList:
      value.cargoDataList === undefined
        ? undefined
        : value.cargoDataList === null
        ? null
        : (value.cargoDataList as Array<any>).map(JSONAllCargoDataToJSON),
    vesselDataList:
      value.vesselDataList === undefined
        ? undefined
        : value.vesselDataList === null
        ? null
        : (value.vesselDataList as Array<any>).map(JSONAllVesselDataToJSON),
    timeOverall: value.timeOverall,
    outputMask: value.outputMask,
    essence: value.essence,
    originalSender: OriginalSenderToJSON(value.originalSender),
    requestId: value.requestId,
    originalVesselSynopses:
      value.originalVesselSynopses === undefined
        ? undefined
        : (value.originalVesselSynopses as Array<any>).map(JSONAllVesselDataToJSON),
    originalVesselSynopsis:
      value.originalVesselSynopsis === undefined
        ? undefined
        : (value.originalVesselSynopsis as Array<any>).map(JSONAllVesselDataToJSON),
  };
}
