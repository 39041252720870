import {FC} from 'react';
import {AisFormItem, OnChangeSingleFormValue} from '../AisFormItem';
import {aisInitialFilters} from '../../../mapOptions/aisInitialFilters';
import {MinMaxRangeFilter} from './MinMaxRangeFilter';

export const SpeedFormItem: FC<{onSetValuesViaApi: OnChangeSingleFormValue; disabled: boolean}> = ({
  onSetValuesViaApi,
  disabled,
}) => {
  return (
    <AisFormItem
      name={'speed'}
      defaultValue={aisInitialFilters.speed}
      label={'Speed'}
      labelCol={{span: 24}}
      onChangeSingleFormValue={onSetValuesViaApi}>
      <MinMaxRangeFilter disabled={disabled} precision={2} min={0} max={40} step={1} />
    </AisFormItem>
  );
};
