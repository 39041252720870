import styled from 'styled-components';
import {Card} from 'antd';

export const MapControlMenuDropdownContainer = styled(Card)<{
  mapHeight: number;
}>`
  position: absolute;
  top: 48px;
  right: 0;
  width: 286px;
  background-color: var(--color-blue-dark) !important;
  color: var(--color-white);
  border: none;
  box-shadow: var(--box-shadow-dropdown);

  /*
  * We need to set a max-height to the dropdown to avoid it to be bigger than the map.
  * We need to substract 100px to the map height to account for the padding in the top and bottom of the dropdown.
   */
  max-height: ${({mapHeight}) => mapHeight - 100}px;

  overflow-y: auto;
  .ant-card-body {
    padding: 11px 0 11px;
  }

  .ant-form {
    label,
    span {
      color: var(--color-white-fixed);
      font-size: var(--font-size-md);
      line-height: 1;
    }

    .ant-form-item {
      margin: 0 !important;
    }

    .ant-form-item-row {
      padding: 5px 22px;
    }

    .ant-form-item-label {
      white-space: pre-wrap;

      > label {
        color: var(--color-white-fixed);
        font-weight: 700;
        text-align: left;
      }
    }

    .ant-select-selection-search,
    .ant-select-selection-item {
      color: var(--color-black-fixed);
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker,
    .ant-picker-range,
    .ant-input-group-addon,
    .ant-input-number,
    .ant-input-text,
    .ant-input {
      color: var(--color-black-fixed);
      background: var(--color-gray-6-fixed);
    }

    .ant-select .ant-select-arrow .ant-select-suffix {
      color: var(--color-gray-1-fixed) !important;
      line-height: 12px;
    }

    .ant-select-dropdown,
    .ant-dropdown-menu {
      background: var(--color-white-fixed);
      color: var(--color-black-fixed);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: var(--color-blue);
    opacity: 0.7;
  }
`;
