import {Col, Form, FormListFieldData, Row} from 'antd';
import {DurationField} from './DurationField';
import {FC} from 'react';
import {FormValues} from '../../../FormValues';
import {AntDValidator, greaterThan} from '../../../../../../../utils/AntDValidator';
import {RuleObject} from 'antd/es/form';

export const periodMinMax = (periodMin: number | null, periodMax: number | null): AntDValidator => {
  const validator = async (_rule: RuleObject, _value: number | undefined) => {
    if (periodMin === null || periodMin === undefined || periodMax === null || periodMax === undefined) {
      return;
    }
    if (periodMin < 0 || periodMax < 0) {
      return;
    }
    if (periodMin > periodMax) {
      throw new Error('Min must be <= max');
    }
  };
  return validator;
};

export const Duration: FC<{
  field: FormListFieldData;
}> = ({field}) => {
  const form = Form.useFormInstance<FormValues>();
  const periodMin = Form.useWatch<number | null>(['options', field.name, 'periodMin'], form);
  const periodMax = Form.useWatch<number | null>(['options', field.name, 'periodMax'], form);

  return (
    <Row gutter={4}>
      <Col span={12}>
        <Form.Item
          name={[field.name, 'periodMin']}
          noStyle
          rules={[
            {type: 'number'},
            {validator: greaterThan(0), message: 'Min must be > 0'},
            {validator: periodMinMax(periodMin, periodMax)},
          ]}
          dependencies={[['options', field.name, 'periodMax']]}>
          <DurationField maxLength={4} placeholder={'min'} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[field.name, 'periodMax']}
          noStyle
          rules={[{type: 'number'}, {validator: greaterThan(0), message: 'Max must be > 0'}]}
          dependencies={[['options', field.name, 'periodMin']]}>
          <DurationField maxLength={4} placeholder={'max'} />
        </Form.Item>
      </Col>
    </Row>
  );
};
