/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CommodityCategory
 */
export interface CommodityCategory {
  /**
   *
   * @type {number}
   * @memberof CommodityCategory
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CommodityCategory
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof CommodityCategory
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CommodityCategory
   */
  isArchived: boolean;
}

export function CommodityCategoryFromJSON(json: any): CommodityCategory {
  return CommodityCategoryFromJSONTyped(json, false);
}

export function CommodityCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommodityCategory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    slug: json['slug'],
    name: json['name'],
    isArchived: json['isArchived'],
  };
}

export function CommodityCategoryToJSON(value?: CommodityCategory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    slug: value.slug,
    name: value.name,
    isArchived: value.isArchived,
  };
}
