/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AreaType {
  Port = 'PORT',
  Anchorage = 'ANCHORAGE',
  Pier = 'PIER',
  DryDock = 'DRY_DOCK',
  Chokepoint = 'CHOKEPOINT',
  HighRiskArea = 'HIGH_RISK_AREA',
  Upriver = 'UPRIVER',
  TradingArea = 'TRADING_AREA',
}

export function AreaTypeFromJSON(json: any): AreaType {
  return AreaTypeFromJSONTyped(json, false);
}

export function AreaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaType {
  return json as AreaType;
}

export function AreaTypeToJSON(value?: AreaType | null): any {
  return value as any;
}
