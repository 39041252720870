import {Select} from 'antd';
import styled from 'styled-components';

export type StyleType = 'line' | 'bar';

export const StyleTypeOption = ({value, onChange}: {onChange: (per: StyleType) => void; value: StyleType}) => {
  return (
    <Container>
      <Select
        showSearch={true}
        defaultValue={value}
        style={{width: '100%'}}
        onChange={newValue => {
          onChange(newValue);
        }}>
        <Select.Option value={'bar'} key={'bar'}>
          Bar
        </Select.Option>
        <Select.Option value={'line'} key={'line'}>
          Line
        </Select.Option>
      </Select>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100px;
`;
