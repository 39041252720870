import {RequestContext} from '../../api/symfony/generated';

export const addHeaderOnContext = (context: RequestContext, name: string, value: string): void => {
  const {init} = context;
  if (!init.headers) {
    init.headers = {};
  }
  addHeader(init.headers, name, value);
};

export const addHeader = (headers: HeadersInit, name: string, value: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((headers as any).append) {
    const headersInstance = headers as Headers;
    headersInstance.append(name, value);
    return;
  }

  if (Array.isArray(headers)) {
    headers.push([name, value]);
    return;
  }

  const obj = headers as Record<string, string>;
  obj[name] = value;
};
