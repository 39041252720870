import {AimOutlined, CalendarOutlined} from '@ant-design/icons/lib';
import {Col, Form, Row, Switch} from 'antd';
import dayjs from 'dayjs';
import numeral from 'numeral';
import {useContext} from 'react';
import styled from 'styled-components';
import {Input} from '../../../../antd/components/Input';
import {VesselType} from '../../../../api/symfony/generated/models';
import Flag from '../../../../atoms/Flags/index';
import {useSelector} from '../../../../redux/react-redux';
import {getIsPayingCustomer} from '../../../../redux/selectors';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {regexNotIncludeMinusSign} from '../../../../utils/regexExpressions';
import {PortPickerWithFlag} from '../../../PortPicker/PortPickerWithFlag';
import {SeaboVesselPickerWithFlag} from '../../../SeaboVesselPicker/SeaboVesselPickerWithFlag';
import {OfferMailModalButton} from '../components/OfferMailModalButton';
import {PropertyBox} from '../components/PropertyBox';
import {StyledCard} from '../components/StyledCard';
import {StyledDatePicker} from '../components/StyledDatePicker';
import {StyledFormItem} from '../components/StyledFormItem';
import {
  WidgetTitle,
  WidgetTitleBasicInformation,
  WidgetTitleBasicProperty,
  WidgetTitleMailContainer,
  WidgetTitleProperties,
} from '../components/WidgetTitle';
import {useUpdateVoyagePoint} from '../Context/utils/useCharterCalculationState/VoyagePoint/useUpdateVoyagePoint';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {mapVesselToVesselInput} from '../utils/mapVesselToVesselInput';
import {
  transformPortToVoyagePointPartial,
  voyagePointPartialWithEmptyPortInformations,
} from '../VoyageInformation/utils/transformPortToVoyagePointPartial';
import {transformVoyagePointToPortParam} from '../VoyageInformation/utils/transformVoyagePointToPortParam';
import {makeCustomVesselPickerOption} from './makeCustomVesselPickerOption';
import {SpeedAndConsumption} from './SpeedAndConsumtion/SpeedAndConsumption';
import {VesselInput} from './VesselTypes';
import {NotAvailable} from '../../../../utils/NotAvailable';

export const VesselInformation = (props: {showBody: boolean; onChangeShowBody: (showBody: boolean) => void}) => {
  const isPayingCustomer = useSelector(getIsPayingCustomer);
  const voyageContext = useContext(VoyageCharterContext);

  const {setVesselInput, updateVesselInput} = voyageContext;
  const onUpdate = (partialChange: Partial<VesselInput>) => {
    updateVesselInput(partialChange);
  };

  const {inputState} = voyageContext.state;

  const [form] = Form.useForm();

  const {vessel} = inputState;
  const updateVoyagePoint = useUpdateVoyagePoint();

  const nextOpenPointIndex = inputState.voyage.points.findIndex(point => point.types?.includes('nextOpen'));
  const nextOpenPoint = inputState.voyage.points[nextOpenPointIndex];
  const openDate = dayjs(inputState.vessel.openDate);

  return (
    <StyledCard
      data-cy="vesselInformation"
      $showBody={props.showBody}
      style={{width: '100%', paddingTop: 10}}
      title={
        <WidgetTitle
          icon="vessel-complex"
          title="Vessel"
          showBody={props.showBody}
          onToggleBody={() => {
            props.onChangeShowBody(!props.showBody);
          }}>
          <WidgetTitleBasicInformation
            name={vessel.name || 'Select a vessel...'}
            link={vessel.id ? `/vessel/${vessel.id}` : undefined}
          />
          {isPayingCustomer && (
            <WidgetTitleMailContainer>
              <OfferMailModalButton id={vessel.id} type={'vessel'} />
            </WidgetTitleMailContainer>
          )}
          <WidgetTitleProperties>
            <WidgetTitleBasicProperty data-cy="dwatDisplay">
              {(vessel.dwat ? numeral(vessel.dwat).format('0,0') : NotAvailable) + ' MTS'}
            </WidgetTitleBasicProperty>
            <WidgetTitleBasicProperty data-cy="capacityDisplay" style={{textAlign: 'right'}}>
              {(vessel.capacity ? numeral(vessel.capacity).format('0,0') : NotAvailable) + ' CBM'}
            </WidgetTitleBasicProperty>
          </WidgetTitleProperties>
          <WidgetTitleProperties style={{marginTop: '17px'}}>
            <PropertyBox
              data-cy="openDate"
              title="Date open"
              backgroundColor="var(--color-gray-5)"
              icon={<CalendarOutlined style={{fontSize: 18, color: 'var(--color-gray-2)'}} />}>
              {vessel.openDate ? dayjs(vessel.openDate).format(DateTimeFormat.Date) : NotAvailable}
            </PropertyBox>
            <PropertyBox
              data-cy="locationOpen"
              title="Port open"
              backgroundColor="var(--color-gray-5)"
              icon={<AimOutlined style={{fontSize: 18, color: 'var(--color-gray-2)'}} />}>
              {nextOpenPoint ? (
                <span>
                  <Flag countryCode={nextOpenPoint.countryCode} border />
                  <span data-cy="nextOpenName" style={{marginLeft: 4}}>
                    {nextOpenPoint.name}
                  </span>
                </span>
              ) : (
                NotAvailable
              )}
            </PropertyBox>
          </WidgetTitleProperties>
        </WidgetTitle>
      }>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <StyledFormItem label={'Vessel name'}>
            <SeaboVesselPickerWithFlag
              size="middle"
              vesselTypes={[VesselType.Bulker]}
              makeOption={makeCustomVesselPickerOption}
              seaboVessel={
                inputState.vessel.name
                  ? {name: inputState.vessel.name, countryFlag: inputState.vessel.countryCode}
                  : undefined
              }
              indexNames={['vessels_portfolio', 'vessels_market']}
              onChange={async vessel => {
                if (vessel) {
                  const vesselInput = await mapVesselToVesselInput({vessel});

                  setVesselInput(vesselInput);
                  form.setFieldsValue(vesselInput);
                } else {
                  const vesselInput: VesselInput = setVesselInput(undefined);
                  form.setFieldsValue(vesselInput);
                }
              }}
            />
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem
            label={'DWT'}
            rules={[
              {
                message: 'DWT must be greater than or equal to 0.',
                pattern: regexNotIncludeMinusSign,
              },
            ]}>
            <Input
              size="middle"
              addonAfter={'mts'}
              value={inputState.vessel.dwat}
              data-cy={'dwat'}
              onChange={v => {
                const value = parseFloat(v.currentTarget.value);

                onUpdate({dwat: isNaN(value) ? undefined : value});
              }}
              type={'number'}
            />
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem
            label={'Grain capacity'}
            data-cy={'vesselCapacity'}
            rules={[
              {
                message: 'Capacity must be greater than or equal to 0.',
                pattern: regexNotIncludeMinusSign,
              },
            ]}>
            <Input
              value={inputState.vessel.capacity}
              addonAfter={'cbm'}
              onChange={v => {
                const value = parseFloat(v.currentTarget.value);
                onUpdate({capacity: isNaN(value) ? undefined : value});
              }}
              type={'number'}
            />
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem label={'Date open'} rules={[]}>
            <StyledDatePicker
              style={{width: '100%'}}
              size="middle"
              data-cy={'openDate'}
              format={DateTimeFormat.Date}
              value={openDate}
              onChange={v => {
                let x = undefined;
                if (v !== null) {
                  x = v.format(DateTimeFormat.IsoDate);
                }
                onUpdate({openDate: x});
              }}
            />
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem label={'Port open'} rules={[]}>
            {nextOpenPoint ? (
              <PortPickerWithFlag
                size={'middle'}
                onChange={port => {
                  updateVoyagePoint({
                    voyagePointIndex: nextOpenPointIndex,
                    voyagePoint: port
                      ? transformPortToVoyagePointPartial(port)
                      : voyagePointPartialWithEmptyPortInformations,
                  });
                }}
                port={transformVoyagePointToPortParam(nextOpenPoint)}
              />
            ) : (
              NotAvailable
            )}
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem label={'Scrubber fitted'}>
            <Switch
              data-cy="scrubberFittedSwitch"
              checked={inputState.vessel.isScrubberFitted}
              onChange={newValue => {
                updateVesselInput({
                  isScrubberFitted: newValue,
                });
              }}
            />
          </StyledFormItem>
        </Col>
      </Row>
      <SpeedAndConsumption
        consumptionModes={inputState.vessel.consumptionModes.map((item, index) => ({index: index, ...item}))}
        onChange={consumptions => {
          updateVesselInput({
            consumptionModes: consumptions,
          });
        }}
      />
    </StyledCard>
  );
};

export const StyledCardTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  border-radius: var(--border-radius-button);
  background-color: var(--color-gray-6);
  height: 42px;
  font-size: var(--font-size-lg);
  font-weight: 400;
  cursor: pointer;
  .editMarker {
    opacity: 0;
  }
  :hover {
    .editMarker {
      opacity: 1;
    }
  }
`;
