import cx from 'classnames';
import {FC, ReactNode} from 'react';

type MapToggleCheckboxProps = {
  id: string;
  label: string | ReactNode;
  visualDisabled: boolean;
  checked: boolean;
  onChange: () => void;
};

export const MapToggleCheckbox: FC<MapToggleCheckboxProps> = ({id, label, visualDisabled, ...rest}) => (
  <label className={cx('map-checkbox', visualDisabled && 'map-checkbox--disabled')} htmlFor={id}>
    <input className="map-checkbox__input" type="checkbox" id={id} {...rest} />
    {label}
    <span className="map-checkbox__checker" />
  </label>
);
