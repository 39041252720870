import React, {useContext} from 'react';

import {StyledButton} from './StyledButton';
import {useFocusMapOnCoordinates} from '../Hooks/useFocusMapOnCoordinates';
import {MapContext} from '../MapContext/MapContext';
import {AimOutlined} from '@ant-design/icons';

export const FocusOnCoordinatesButton = () => {
  const focusMapOnCoordinates = useFocusMapOnCoordinates();
  const mapContext = useContext(MapContext);

  if (!mapContext.props.showFocusBtn || (mapContext.props.coordinatesToFocus ?? []).length === 0) {
    return <></>;
  }
  return (
    <StyledButton
      onClick={() =>
        focusMapOnCoordinates({
          coordinates: mapContext.props.coordinatesToFocus!,
        })
      }>
      <AimOutlined />
    </StyledButton>
  );
};
