import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import {getEnvironment, isLocalDevEnvironment} from '../../utils/environment';
import {assert} from '../../utils/assert';
import {BrowserOptions} from '@sentry/react';

/**
 * Setup Sentry.
 *
 * See https://docs.sentry.io/platforms/javascript/
 */
export const initSentry = () => {
  const environment = window.PLATFORM_ENV;
  if (isLocalDevEnvironment()) {
    return;
  }
  if (!isValidSentryEnv(environment)) {
    return;
  }

  const dsn = window.SENTRY_DSN;
  if (!dsn) {
    // eslint-disable-next-line no-console
    console.error('SENTRY_DSN not provided', dsn);
    return;
  }

  const sessionReplayEnabled = ['Beta', 'Staging'].includes(getEnvironment());

  const frontendVersion = window.__FRONTEND_VERSION__;
  assert(frontendVersion, 'Missing window.__FRONTEND_VERSION__ from vitePluginVersionMark');

  const integrations: BrowserOptions['integrations'] = [new Integrations.BrowserTracing()];
  if (sessionReplayEnabled) {
    integrations.push(
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      })
    );
  }

  Sentry.init({
    environment,
    // Find our DSN here: https://sentry.io/settings/seabo/projects/platform/keys/
    dsn,
    replaysOnErrorSampleRate: sessionReplayEnabled ? 1 : 0,

    integrations,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    release: frontendVersion,
    ignoreErrors: [/^Failed to fetch$/i],
  });

  Sentry.setTag('service', 'frontend');
};

const isValidSentryEnv = (environment?: string): boolean => {
  if (!environment) {
    return false;
  }
  const isValid = !!environment.match(/^\w+$/);
  return isValid;
};
