import {FC} from 'react';
import {Result} from 'antd';
import useGlobalEvent from 'beautiful-react-hooks/useGlobalEvent';
import {isLocalDevEnvironment, isTest} from './environment';
import {notification} from '../components/Notification';

/**
 * Display the given error to the user
 */
export const showError = (error: unknown, test: boolean = isTest()) => {
  if (test) {
    throw error;
  }

  const key = Date.now().toString();
  notification.open({
    key,
    message: <ErrorResult error={error as Error} onClose={() => notification.destroy(key)} />,
    duration: 0,
  });
};

export const ErrorResult: FC<{error: Error; onClose: () => void}> = ({error, onClose}) => {
  const onKeyboardEvent = useGlobalEvent<KeyboardEvent>('keydown');

  onKeyboardEvent(({key}: KeyboardEvent) => {
    if (key === 'Escape' || key === 'Enter') {
      onClose();
    }
  });

  return (
    <Result
      status="500"
      title="An error occurred"
      subTitle={
        <div data-testid="ErrorResult">
          <p>Our team has been notified of the problem.</p>
          <p>{error?.message || 'Unknown error'}</p>
          <p>
            <small>{new Date().toISOString()}</small>
          </p>
          {isLocalDevEnvironment() && <pre>{JSON.stringify(error)}</pre>}
        </div>
      }
    />
  );
};
