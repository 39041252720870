import dayjs, {Dayjs} from 'dayjs';
import map from 'lodash/map';
import {convertValuesToNamedValues, NamedValue} from '../FilterConversion/utils/NamedValue';
import {dateEarlierThanOrEqualTo} from '../../../../utils/validators';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

type ConfidenceScore = 'green' | 'red' | 'yellow' | 'brown';

export const QualityOfEmails: NamedValue<ConfidenceScore>[] = [
  {name: 'Good', value: 'green'},
  {name: 'Medium', value: 'yellow'},
  {name: 'Low', value: 'red'},
  {name: 'Poor', value: 'brown'},
];

type ReceivedBy = 'mail' | 'platform';

export const IsMailOptions: NamedValue<ReceivedBy>[] = [
  {name: 'Parsed emails', value: 'mail'},
  {name: 'Platform', value: 'platform'},
];

export type DatabaseType = {
  on: {
    from?: string;
    to?: string;
  };
  postedByEmail?: string | null;
  confidenceScoreEmailQuality?: ConfidenceScore[] | null;
};

export type InternalType = {
  from: Dayjs | null;
  to: Dayjs | null;
  postedByEmail: string | null;
  confidenceScoreEmailQuality: NamedValue<ConfidenceScore>[];
};

export const Defaults: InternalType = {
  from: null,
  to: null,
  postedByEmail: null,
  confidenceScoreEmailQuality: [],
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {on, postedByEmail, confidenceScoreEmailQuality} = database;

  return {
    from: on && on.from ? dayjs(on.from) : null,
    to: on && on.to ? dayjs(on.to) : null,
    postedByEmail: postedByEmail ?? null,

    confidenceScoreEmailQuality: confidenceScoreEmailQuality
      ? convertValuesToNamedValues(confidenceScoreEmailQuality, QualityOfEmails)
      : [],
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {from, to, postedByEmail, confidenceScoreEmailQuality} = internal;
  return {
    on: {
      from: from ? from.format(DateTimeFormat.IsoDate) : undefined,
      to: to ? to.format(DateTimeFormat.IsoDate) : undefined,
    },
    postedByEmail: postedByEmail ?? undefined,
    confidenceScoreEmailQuality: map(confidenceScoreEmailQuality, 'value'),
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Posted',
  branch: 'posted',
  defaults: Defaults,
  validators: {
    from: dateEarlierThanOrEqualTo('to'),
  },
  fromDatabase,
  toDatabase,
});
