import {makeApiService, ApiServiceEntry} from './ApiService';
import {actionsTypes} from '../Contrib';
import {TODO} from '../../utils/TODO';

type Query = TODO;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Payload = any;
type Filters = TODO;

const entries: ApiServiceEntry[] = [
  {
    fn: 'imo',
    route: '/api/contrib/vessels/{query}',
    method: 'GET',
    abortPrevCalls: true,
  },
  {
    fn: 'list',
    route: '/api/contrib/vessels/list',
    method: 'GET',
    abortPrevCalls: true,
  },
  {
    fn: 'delete',
    route: '/api/vessels/{vessel}',
    method: 'DELETE',
  },
];

const contrib = makeApiService(entries, {apiName: 'contrib'});

// wire up in Store
export const reducers = contrib.reducers;
export const actions = contrib.actions;
export const actionTypes = contrib.actionTypes;

export const ContribServiceActions = {
  getImoVessel: (query: Query) => actions.imo({params: {query}}),
  getVesselDbList: (pageIndex = 1, pageSize = 20, sortField: TODO, sortOrder: TODO, filters: Filters) =>
    actions.list({
      queryParams: {
        pageIndex,
        pageSize,
        sortField,
        sortOrder,
        filters: filters ? JSON.stringify({backendVesselsParams: filters}) : undefined,
      },
    }),
  deleteVesselDb: (vessel: TODO) => actions.delete({params: {vessel}}),
  updateSortOrder: (payload: Payload) => ({
    type: actionsTypes.VESSEL_DB_UPDATE_SORTORDER,
    payload,
  }),
  updatePage: (payload: Payload) => ({
    type: actionsTypes.VESSEL_DB_UPDATE_PAGE,
    payload,
  }),
  updatePageSize: (payload: Payload) => ({
    type: actionsTypes.VESSEL_DB_UPDATE_PAGE_SIZE,
    payload,
  }),
  filterReviewedVessels: (payload: Payload) => ({
    type: actionsTypes.VESSEL_DB_FILTER_REVIEWED,
    payload,
  }),
  filterDisplayedVessels: (payload: Payload) => ({
    type: actionsTypes.VESSEL_DB_FILTER_DISPLAYED,
    payload,
  }),
  filterInputVessels: (payload: Payload) => ({
    type: actionsTypes.VESSEL_DB_FILTER_INPUT,
    payload,
  }),
};

export default ContribServiceActions;
