import React, {FC, CSSProperties} from 'react';

/**
 * A component that consumes the given amount of space.
 * Using <Spacer> allows us to be explicit about distances between components,
 * instead of having to attach distances as margin to an arbitrarily selected component.
 */
export const Spacer: FC<{
  width?: string | number;
  height?: string | number;
  block?: boolean;
  style?: CSSProperties;
}> = ({width = 1, height = 1, block, style}) => (
  <div
    className="Spacer"
    style={{
      display: block ? 'block' : 'inline-block',
      width,
      height,
      ...style,
    }}
  />
);
