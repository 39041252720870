import produce from 'immer';
import pull from 'lodash/pull';

/**
 * Maps sections to fields.
 */
export const sectionMapping: Record<string, string[]> = {
  general: [
    'id',
    'vesselType',
    'designType',
    'designSubType',
    'dryDock',
    'name',
    'charterName',
    'imo',
    'builtYear',
    'countryFlag',
    'shipBuilder',
    'status',
  ],
  dryDock: ['dryDockFrom', 'dryDockTo', 'dryDockNote'],
  backend: ['reviewed', 'displayed', 'mmsi'],
  dimensions: ['lengthOverall', 'beam', 'draft', 'grossTonnage', 'netTonnage', 'tonnesPerCentimetre'],
  intake: [
    'intakes',
    'dwtSummer',
    'reeferPlugs',
    'holdsAmount',
    'hatchesAmount',
    'grain',
    'grainUnit',
    'bale',
    'baleUnit',
  ],
  features: [
    'gears',
    'iceClass',
    'featureLakesFitted',
    'featureCellular',
    'featureSternThrust',
    'featureOpenHatch',
    'featureItfFitted',
    'featureBowThrust',
    'featureLogFitted',
    'featureTweenDecks',
    'featureA60Bulkhead',
    'featureCo2Fitted',
    'featureBoxShape',
    'featureScrubberFitted',
  ],
  consumptions: ['consumptions'],
  images: ['images'],
  documents: ['documents'],
  nextOpen: ['nextOpenFrom', 'nextOpenTo', 'stations', 'spotState'],
  ownership: ['managementHeadOwner', 'managementManagingOwner', 'managementDisponentOwner'],
  comment: ['comment'],
};

export interface Step {
  label: string;
  sections: string[];
}

const steps: Step[] = [
  {
    label: 'Enter information',
    sections: [
      'general',
      'backend',
      'dimensions',
      'intake',
      'features',
      'dryDock',
      'consumptions',
      'images',
      'documents',
      'nextOpen',
      'ownership',
      'comment',
    ],
  },
  {
    label: 'Review',
    sections: [],
  },
];

export const StepLabels = steps.map(step => step.label);

interface Context {
  /**
   * Whether the form is in the backend.
   */
  isBackend: boolean;
  /**
   * Whether this is a tanker or an 'other' vessel type.
   */
  isOtherType: boolean;
  vesselType?: 'bulker' | 'container' | 'mpp' | 'tanker' | 'other';
}

/**
 * Returns steps with only the sections that apply for the given context.
 */
export const getSteps = ({isBackend, isOtherType, vesselType}: Context) =>
  produce(steps, draftSteps => {
    let sections = draftSteps[0].sections;
    if (!isBackend) {
      sections = pull(sections, 'backend');
    }
    if (isOtherType) {
      sections = pull(sections, 'intake', 'features', 'consumptions');
    }
    if (vesselType === 'bulker') {
      sections = pull(sections, 'intake');
    }
    draftSteps[0].sections = sections;
  });
