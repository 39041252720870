import {Helmet} from 'react-helmet-async';
import {useLocation, useParams} from 'react-router-dom';
import {RouteParams} from './RouteParams';
import VesselForm from './VesselForm';

export const VesselFormScreen = () => {
  const params = useParams<RouteParams>();
  const location = useLocation();
  const defaultVesselValues = location.state?.defaultVesselValues;

  return (
    <>
      <Helmet title={params.id ? 'Edit vessel' : 'Add vessel'} />
      <VesselForm key={defaultVesselValues?.imo} newVesselFromExternal={defaultVesselValues} />
    </>
  );
};
