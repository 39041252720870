import {useSelector} from '../../../../redux/react-redux';
import {QueryOptions, useQuery} from '@tanstack/react-query';
import {Vessel, Cargo} from '../types';
import {fetchJSON} from '../../../../api/utils/fetchJSON';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {fetchChunked} from '../../../../utils/fetchChunked';

export const useCargoesFromGridClipboard = (options?: QueryOptions<Cargo[]>) => {
  const gridClipboard = useSelector(state => state.gridClipboard);

  const cargoIds: number[] = gridClipboard.market.cargoes.map((cargo: {id: number}) => cargo.id);
  const cargoesQuery = useQuery({
    queryKey: ['market/cargo-offers', cargoIds],
    queryFn: () => loadCargoes(cargoIds),
    ...options,
  });

  return cargoesQuery;
};

export const useMarketVesselsFromGridClipboard = (options?: UseQueryOptions<Vessel[]>) => {
  const gridClipboard = useSelector(state => state.gridClipboard);

  const vesselIds: number[] = gridClipboard.market.vessels.map((vessel: {id: number}) => vessel.id);
  const vesselsQuery = useQuery({
    queryKey: ['market/vessel-offers', vesselIds],
    queryFn: () => loadVesselOffers(vesselIds),
    ...options,
  });

  return vesselsQuery;
};

const loadCargoes = async (cargoIds: number[]): Promise<Cargo[]> => {
  return fetchChunked<number, Cargo>({
    chunkSize: 10,
    fetchSingle: loadCargo,
    ids: cargoIds,
  });
};

const loadCargo = async (cargoId: number): Promise<Cargo> => {
  const json: {cargo: Cargo} = await fetchJSON(`/api/market/cargo-offers/${cargoId}`);
  return json.cargo;
};

const loadVesselOffers = async (vesselIds: number[]): Promise<Vessel[]> => {
  return fetchChunked<number, Vessel>({
    chunkSize: 10,
    fetchSingle: loadVessel,
    ids: vesselIds,
  });
};

const loadVessel = async (vesselId: number): Promise<Vessel> => {
  const json: {vessel: Vessel} = await fetchJSON(`/api/market/vessel-offers/${vesselId}`);
  return json.vessel;
};
