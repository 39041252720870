import {QueryClient} from '@tanstack/react-query';
import {FilterTypeDefault, ValidatorsTypeDefault} from '../../atoms/Filter/types';

/**
 * Specifies the logical information for a filter box.
 */
export type FilterBranchDefinition<
  BranchKey extends string,
  InternalType extends FilterTypeDefault,
  DatabaseType extends FilterTypeDefault,
  ContextType extends FilterTypeDefault,
  ValidatorsType extends ValidatorsTypeDefault<InternalType>,
> = Readonly<{
  /**
   * The title displayed in the filter box, for example "Open date".
   */
  name: string;
  /**
   * The object property name used in filter settings, for example "openDate".
   */
  branch: BranchKey;
  /**
   * Default values for the filter settings, in internal format.
   */
  defaults: InternalType;
  /**
   * Optional validation functions.
   */
  validators?: ValidatorsType;
  /**
   * Converts from internal format to api format ("database format").
   */
  toDatabase: (internal: InternalType) => DatabaseType;
  /**
   * Converts from api format ("database format") to internal format.
   */
  fromDatabase: (database: DatabaseType, context: ContextType) => InternalType;
  /**
   * Optional function that loads the data required by fromDatabase() to work.
   */
  loadBranchContext?: (queryClient: QueryClient) => Promise<ContextType>;
}>;

/**
 * A helper type to extract the union of InternalType types from filter branch definitions by inferring the ReturnType from the `fromDatabase` attribute.
 */
export type FilterInternalType<FilterBranchDefinitions extends FilterBranchDefinitionsDefault> = {
  [K in keyof FilterBranchDefinitions]: ExtractReturnType<FilterBranchDefinitions[K]['fromDatabase']>;
};

/**
 * A helper type to extract the union of DatabaseType types from filter branch definitions by inferring the ReturnType from the `toDatabase` attribute.
 */
export type FilterDatabaseType<FilterBranchDefinitions extends FilterBranchDefinitionsDefault> = {
  [K in keyof FilterBranchDefinitions]: ExtractReturnType<FilterBranchDefinitions[K]['toDatabase']>;
};

/**
 * A helper type to extract the context types from the filter branch definitions.
 */
export type FilterContextType<FilterBranchDefinitions extends FilterBranchDefinitionsDefault> = {
  [K in keyof FilterBranchDefinitions]: FilterBranchContext<FilterBranchDefinitions[K]>;
};

/**
 * A helper type to extract the context type from a single filter branch definition.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FilterBranchContext<FilterBranch extends FilterBranchDefinition<string, any, any, any, any>> = Awaited<
  ReturnType<NonNullable<FilterBranch['loadBranchContext']>>
>;

/**
 * A helper type to extract the return type of a function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractReturnType<T> = T extends (...args: any[]) => infer R ? R : never;

/**
 * Creates a well-typed filter branch definition by inferring the types from the provided definition.
 * @param definition The filter branch definition.
 * @template BranchKey The branch key type inferred from the "branch" attribute.
 * @template InternalType The internal filter type inferred from the "fromDatabase" return type.
 * @template DatabaseType The database filter type inferred from the "fromDatabase" return type.
 * @template ContextType The context type inferred from the "loadBranchContext" return type.
 * @template ValidatorsType The validators type inferred from the "validators" attribute.
 * @example
 * const filterBranchDefinition: Readonly<{
    name: string;
    branch: "charterer";
    defaults: InternalType;
    validators?: Partial<Record<"chartererNames", Validator | Validator[]>> | undefined;
    loadBranchContext?: ((queryClient: QueryClient) => Promise<...>) | undefined;
    toDatabase: (internal: InternalType) => DatabaseType;
    fromDatabase: (database: DatabaseType, context: FilterTypeDefault) => InternalType;
  }>
 */
export const makeFilterBranchDefinition = <
  BranchKey extends string = string,
  InternalType extends FilterTypeDefault = FilterTypeDefault,
  DatabaseType extends FilterTypeDefault = FilterTypeDefault,
  ContextType extends FilterTypeDefault = FilterTypeDefault,
  ValidatorsType extends ValidatorsTypeDefault<InternalType> = ValidatorsTypeDefault<InternalType>,
>(
  definition: FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType>
): FilterBranchDefinition<BranchKey, InternalType, DatabaseType, ContextType, ValidatorsType> => definition;

/**
 * Default type to extend from when defining FilterBranchDefinitions.
 */
export type FilterBranchDefinitionsDefault = Readonly<
  Record<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FilterBranchDefinition<string, any, any, any, any>
  >
>;

/**
 * Creates a well-typed, readonly object of FilterBranchDefinitions by inferring the types from the provided definitions.
 * @param definitions The filter branch definitions.
 * @template FilterBranchDefinitions The array type of filter branch definitions.
 * @example
 * const MarketPortFilterBranchDefinitions: {
    attributes: Readonly<{
      name: string;
      branch: "attributes";
      defaults: PortAttributes.InternalType;
      validators?: Partial<Record<keyof PortAttributes.InternalType, Validator | Validator[]>> | undefined;
      loadBranchContext?: ((queryClient: QueryClient) => Promise<...>) | undefined;
      toDatabase: (internal: PortAttributes.InternalType) => PortAttributes.DatabaseType;
      fromDatabase: (database: PortAttributes.DatabaseType, context: FilterTypeDefault) => PortAttributes.InternalType;
    }>;
    location: Readonly<...>;
   }
 */
export const makeFilterBranchDefinitions = <
  FilterBranchDefinitions extends ReadonlyArray<FilterBranchDefinitionsDefault[string]>,
>(
  definitions: FilterBranchDefinitions
) => {
  return definitions.reduce(
    (acc, definition) => ({
      ...acc,
      [definition.branch]: definition,
    }),
    {} as {[K in FilterBranchDefinitions[number]['branch']]: Extract<FilterBranchDefinitions[number], {branch: K}>}
  );
};
