import {FormInstance} from 'antd/es/form';
import {FormValues} from '../../FormValues';
import {VesselFromApi} from './VesselFromApi';

export const setHiddenVesselFields = (
  form: FormInstance<FormValues>,
  contractType: FormValues['contractType'],
  vessel: VesselFromApi
) => {
  form.setFieldsValue({
    vesselName: vessel.name,
    vesselType: vessel.vesselType as FormValues['vesselType'],
    designSubType: vessel.designSubType,
    designType: vessel.designType,
    vessel: {
      id: vessel.id,
      targetType: vessel.target,
    },
    dwat: vessel.dwtSummer,
    teuQuantity: vessel.intakes.find(d => d.type === 'teu')?.quantity,
    isGeared: vessel.gears.length > 0,
    sellerName: vessel.managementManagingOwner,
  });
};
