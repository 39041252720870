import {FC, ReactNode} from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  content: ReactNode | string;
}
export const SideNote: FC<Props> = ({title, content}) => {
  return (
    <>
      {content && (
        <StyledSideNote>
          <StyledSideNoteTitle>{title}</StyledSideNoteTitle>
          <StyledSideNoteContent>{content}</StyledSideNoteContent>
        </StyledSideNote>
      )}
    </>
  );
};

const StyledSideNote = styled.div`
  margin-bottom: 20px;
`;

const StyledSideNoteTitle = styled.div`
  font-size: var(--font-size-sm);
  color: var(--color-gray-2);
  text-transform: uppercase;
`;

const StyledSideNoteContent = styled.div`
  font-size: var(--font-size-md);
`;
