import styled from 'styled-components';
import {Attribute} from '../../../../atoms/Attribute/Attribute';
import {getIconById} from '../../../../atoms/WheaterIcons';
import {useGetWeatherAndLocationByLatLonQuery} from '../../../../queries/useGetWeatherAndLocationByLatLonQuery';
import {getCompassDirection} from '../../../../utils/helper';
import {renderWeatherBackground} from '../../../WeatherWidget/utils/weatherUtils';

type MapDetailsWeatherProps = {
  weatherCoordinates: {
    lat: number;
    lon: number;
  };
};

export const MapDetailsWeather = ({weatherCoordinates}: MapDetailsWeatherProps) => {
  const weatherQuery = useGetWeatherAndLocationByLatLonQuery(weatherCoordinates);

  if (weatherQuery.isLoading) {
    return (
      <WeatherContainer>
        <WeatherLoadingText>Loading weather...</WeatherLoadingText>
      </WeatherContainer>
    );
  }

  if (weatherQuery.isError) {
    return <WeatherContainer>No weather data available</WeatherContainer>;
  }

  const {temp} = weatherQuery.data.current;
  const windSpeed = weatherQuery.data.current.wind_speed;
  const windDeg = weatherQuery.data.current.wind_deg;
  const windDir = getCompassDirection(windDeg);

  const {id, description} = weatherQuery.data.current.weather[0];
  const WeatherIcon = getIconById(renderWeatherBackground(id));

  return (
    <WeatherContainer>
      <WeatherStatus>
        <WeatherIconWrapper>{WeatherIcon && <WeatherIcon />}</WeatherIconWrapper>
        <WeatherTitle>{description}</WeatherTitle>
      </WeatherStatus>
      <Spacer />
      <WeatherDetails>
        <Attribute small tab={'l'} label="Air Temperature" value={temp + ' °C'} />
        <Attribute small tab={'l'} label="Wind Speed" value={windSpeed + ' KPH'} />
        <Attribute small tab={'l'} label="Wind Direction" value={windDir + ' (' + windDeg + '°)'} />
      </WeatherDetails>
    </WeatherContainer>
  );
};

const WeatherContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`;

const WeatherStatus = styled.div`
  width: 100px;
  padding: 4px 2px 2px;
  background: var(--color-blue-dark);
  border-radius: var(--border-radius);
  overflow: hidden;
`;

const WeatherIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: auto;
  svg {
    height: 25px;
    margin-bottom: 2px;
    width: auto;
  }
`;

const WeatherTitle = styled.div`
  font-size: var(--font-size-xs);
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: var(--color-white-fixed);
`;

const WeatherLoadingText = styled.p`
  margin: 0;
  line-height: 1;
`;

const WeatherDetails = styled.div`
  width: auto;
`;

const Spacer = styled.div`
  width: auto;
  min-width: 10px;
  flex: 1;
`;
