import {RuleObject} from 'antd/es/form';
import {SearchPickerPortSchema} from '../../components/SearchPicker/SearchPickerPort';

export const initialRoute = {
  routes: [],
  hasError: [],
  distance: null,
  seca: null,
  piracy: null,
};

export const initialState = {
  from: null,
  to: null,
  stations: [null, null],
  routes: null,
  distance: null,
  duration: null,
  speed: 12,
  ETD: null,
  ETA: null,
  hasResult: false,
  options: {
    hra: {
      label: 'Avoid High Risk Areas (HRA)',
      value: false,
    },
    seca: {
      label: 'Avoid Emission Control Area (ECA)',
      value: false,
    },
    panama: {
      label: 'Avoid Panama Canal',
      value: false,
    },
    suez: {
      label: 'Avoid Suez Canal',
      value: false,
    },
    kiel: {
      label: 'Avoid Kiel Canal',
      value: false,
    },
  },
  type: 'ETA',
  hasError: [] as string[],
  errorMessage: '',
  seca: null,
  piracy: null,
  isCalculating: false,
};

export const stationRules = [
  {
    required: true,
    validator: (_: RuleObject, value: unknown[]) => {
      try {
        value.forEach(port => SearchPickerPortSchema.parse(port));
      } catch (e) {
        return Promise.reject('Please fill in all stations of the voyage.');
      }
      if (value.length < 2) {
        return Promise.reject('Please select at least two stations.');
      }

      return Promise.resolve();
    },
  },
];
