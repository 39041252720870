import React, {CSSProperties, FC, useRef} from 'react';
import useOnClickOutside from 'use-onclickoutside';

interface Props {
  style?: CSSProperties;
  onClickOutside: (event: MouseEvent | TouchEvent) => void;
  children: React.ReactNode;
}

/**
 * This components converts the hook useOnClickOutside() into a component
 * so that in can be used with class components.
 *
 * @see https://github.com/Andarist/use-onclickoutside
 */
export const ClickOutside: FC<Props> = ({children, style, onClickOutside}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onClickOutside);
  return (
    <div ref={ref} style={style}>
      {children}
    </div>
  );
};
