import {FC, useCallback, useEffect} from 'react';
import {FilterCategory, FilterItem, FilterType} from '../../../api/symfony/generated';
import {useFilterProvider} from '../../../components/FilterProvider/useFilterProvider';
import {FilterProviderState} from '../../../components/FilterProvider/FilterProviderState';
import {
  PortfolioCargoDatabaseFilter,
  PortfolioCargoFilterBranchDefinitions,
} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioCargoFilterBranchDefinitions';
import {useLoadOrCreateFilterQuery} from '../../../queries/filter/useLoadOrCreateFilter';
import {useUpdateFilterMutation} from '../../../queries/filter/useUpdateFilterMutation';
import {GridClipboardActions} from '../../../redux/GridClipboard';
import {PortfolioActions as PortfolioRedux} from '../../../redux/Portfolio';
import {PortfolioFiltersActions} from '../../../redux/PortfolioFilters';
import {useDispatch, useSelector, useStore} from '../../../redux/react-redux';
import {assert} from '../../../utils/assert';
import {MyCargoesBody} from './MyCargoesBody';
import '../../portfolio/shared/style.scss';

export const MyCargoesScreen: FC = () => {
  // Needed to get cargoFilterSettings lookup right:
  const store = useStore();
  const dispatch = useDispatch();

  const {cargoFilterId, cargoFilterSettings, renewedCargoFilter, cargoFilterProvider} = useSelector(
    state => state.portfolioFilters
  );
  const {activeTabCargo} = useSelector(state => state.portfolio.screenState);

  const setCargoFilterFromApi = useCallback(
    (filter: FilterItem) => dispatch(PortfolioFiltersActions.setCargoFilterFromApi(filter)),
    [dispatch]
  );
  const setCargoFilterProvider = (
    filterProviderState: FilterProviderState<typeof PortfolioCargoFilterBranchDefinitions>
  ) => dispatch(PortfolioFiltersActions.setCargoFilterProviderState(filterProviderState));
  const setCargoFilterSettings = (filterSettings: PortfolioCargoDatabaseFilter) =>
    dispatch(PortfolioFiltersActions.setCargoFilterSettings(filterSettings));
  const setPageNumber = (pageNumber: number) =>
    dispatch(PortfolioRedux.setGridState({portfolioType: 'cargo', gridState: {pageNumber}}));

  // Load filter from API
  const filterQuery = useLoadOrCreateFilterQuery({
    category: FilterCategory.Portfolio,
    type: FilterType.Cargo,
  });

  const filterLoaded = filterQuery.isSuccess && !!cargoFilterSettings;

  useEffect(() => {
    if (filterQuery.isSuccess && filterQuery.data) {
      setCargoFilterFromApi(filterQuery.data);
    }
  }, [filterQuery.data, filterQuery.isSuccess, setCargoFilterFromApi]);

  useEffect(() => {
    dispatch(GridClipboardActions.removeAllItemsFromClipboard('cargoes', 'portfolio'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabCargo]);

  const updateFilterMutation = useUpdateFilterMutation();

  const handleFilterChange = (filterSettings: PortfolioCargoDatabaseFilter) => {
    setCargoFilterSettings(filterSettings);
    saveFilterInBackend();
  };

  const onFilterSettingsChangeByUser = () => {
    setPageNumber(1);
  };

  const saveFilterInBackend = () => {
    if (!filterLoaded) {
      return;
    }

    assert(cargoFilterId);

    const currentFilterSettings = store.getState().portfolioFilters.cargoFilterSettings;
    updateFilterMutation.mutate({
      id: cargoFilterId,
      filterItemWrite: {
        category: FilterCategory.Portfolio,
        type: FilterType.Cargo,
        filterSettings: currentFilterSettings ?? undefined,
      },
    });
  };

  const filterProviderApi = useFilterProvider({
    name: 'MyCargoes',
    filterBranchDefinitions: PortfolioCargoFilterBranchDefinitions,
    filterData: {
      id: cargoFilterId,
      filterSettings: cargoFilterSettings,
      shouldReload: renewedCargoFilter,
    },
    filterProviderState: cargoFilterProvider,
    onFilterSettingsChange: handleFilterChange,
    onFilterSettingsChangeByUser,
    onFilterProviderStateChange: setCargoFilterProvider,
  });

  return <MyCargoesBody filterProviderApi={filterProviderApi} />;
};
