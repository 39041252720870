export const negotiationPlaceholderKeys = {
  PLACEHOLDER: 'placeholder',
  'creator.name': 'creator name',
  'creator.email': 'creator email',
  'creator.companyName': 'creator company',
  'counterpart.name': 'counterpart name',
  'counterpart.email': 'counterpart email',
  'counterpart.companyName': 'counterpart company',
  'cargo.commodityCategory': 'cargo commodity category',
  'cargo.commodityType': 'cargo commodity type',
  'cargo.contractType': 'cargo contract type',
  'cargo.laycanFrom': 'laycan from',
  'cargo.laycanTo': 'laycan to',
  'cargo.quantityMin': 'cargo quantity min',
  'cargo.quantityMax': 'cargo quantity max',
  'cargo.quantityUnit': 'cargo quantity unit',
  'cargo.loadingLabel': 'cargo loading / delivery',
  'cargo.dischargeLabel': 'cargo discharge / redelivery',
  'vessel.name': 'vessel name',
  'vessel.type': 'vessel type',
  'vessel.imo': 'vessel imo',
  'vessel.size': 'vessel size',
  'vessel.sizeUnit': 'vessel size unit',
  'vessel.dateOpen': 'vessel date open',
  'vessel.locationOpenLabel': 'vessel location open',
};

export type NegotiationPlaceholderKey = keyof typeof negotiationPlaceholderKeys;

export const isNegotiationPlaceholderKey = (key: string): key is NegotiationPlaceholderKey => {
  return key in negotiationPlaceholderKeys;
};

export const isNegotiationPlaceholderLabel = (label: string): label is NegotiationPlaceholderKey => {
  return Object.values(negotiationPlaceholderKeys).includes(label);
};
