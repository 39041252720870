/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface PortfolioGroupListDataItems
 */
export interface PortfolioGroupListDataItems {
  /**
   *
   * @type {number}
   * @memberof PortfolioGroupListDataItems
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PortfolioGroupListDataItems
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PortfolioGroupListDataItems
   */
  sortOrder: number;
}

export function PortfolioGroupListDataItemsFromJSON(json: any): PortfolioGroupListDataItems {
  return PortfolioGroupListDataItemsFromJSONTyped(json, false);
}

export function PortfolioGroupListDataItemsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PortfolioGroupListDataItems {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    sortOrder: json['sortOrder'],
  };
}

export function PortfolioGroupListDataItemsToJSON(value?: PortfolioGroupListDataItems | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    sortOrder: value.sortOrder,
  };
}
