import {FC} from 'react';
import {TourStepImage} from './TourStepImage';
import {TourStepVideo} from './TourStepVideo';
import {TourStepMedia} from '../TourDefinition';

type TourStepHeaderProps = {
  media: TourStepMedia;
  autoPlay?: boolean;
  muted?: boolean;
  hasNext?: boolean;
  onChangeAutoPlay?: (autoPlay: boolean) => void;
  onChangeMuted?: (muted: boolean) => void;
  onPlayNext?: () => void;
};

export const TourStepHeader: FC<TourStepHeaderProps> = ({
  media,
  autoPlay,
  muted,
  hasNext,
  onChangeAutoPlay,
  onChangeMuted,
  onPlayNext,
}) => {
  if (media.type === 'image') {
    return <TourStepImage media={media} />;
  }
  if (media.type === 'video') {
    return (
      <TourStepVideo
        media={media}
        autoPlay={autoPlay}
        muted={muted}
        hasNext={hasNext}
        onChangeMuted={onChangeMuted}
        onChangeAutoPlay={onChangeAutoPlay}
        onPlayNext={onPlayNext}
      />
    );
  }
  return null;
};
