import classNames from 'classnames';
import Icon from '../../atoms/Icon';

import './style.scss';

const ProgressTab = ({label, onClick, active, completed, disable}: $TSFixMe) => (
  <li
    role="presentation"
    onClick={disable ? undefined : onClick}
    className={classNames('progress-tab-bar__tab', {
      'progress-tab-bar__tab--is-active': active,
      'progress-tab-bar__tab--disabled': disable,
      'progress-tab-bar__tab--completed': completed,
    })}>
    <div className="progress-tab-bar__link">{label}</div>
    {completed && (
      <div className={'progress-tab-bar__icon'}>
        <Icon type={'check-mark-line'} size={'medium'} />
      </div>
    )}
  </li>
);

type ProgressTabBarProps = {
  tabs?: {
    label?: string;
    onClick?: $TSFixMeFunction;
    active?: boolean;
    completed?: boolean;
    disable?: boolean;
  }[];
};

const ProgressTabBar = ({tabs = []}: ProgressTabBarProps) => (
  <ul className="progress-tab-bar">
    {tabs.map((tab, i) => (
      <ProgressTab key={i} {...tab} />
    ))}
  </ul>
);

export default ProgressTabBar;
