import {ReactNode} from 'react';
import {GroupBase, Props} from 'react-select';
import AsyncCreateableSelect from 'react-select/async-creatable';
import {SelectComponents} from 'react-select/dist/declarations/src/components';
import {ClearIndicator, SearchIndicator} from './Indicators';
import {
  clearIndicator,
  control,
  indicatorsContainer,
  menu,
  menuList,
  option,
  singleValue,
  valueContainer,
} from './styles';

interface SelectProps<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends Props<OptionType, IsMulti, GroupType> {
  isDisabled?: boolean;
  width?: number | string;
  components?: Partial<SelectComponents<OptionType, IsMulti, GroupType>>;
  hasError?: boolean;
  allowCreateWhileLoading?: boolean;
  createLabel: (inputValue: string) => ReactNode;
  defaultOptions: OptionType[];
  loadOptions: (inputValue: string) => Promise<OptionType[]>;
  createOptionPosition?: 'first' | 'last';
  maxLength?: number;
}

export const AsyncCreateable = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
  id,
  isDisabled,
  name,
  width = undefined,
  components = undefined,
  hasError = undefined,
  allowCreateWhileLoading = true,
  getOptionLabel,
  createLabel,
  ...props
}: SelectProps<OptionType, IsMulti, GroupType>) => (
  <AsyncCreateableSelect
    isDisabled={isDisabled}
    classNamePrefix={'seabo-react-select'}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator: SearchIndicator,
      ClearIndicator: ClearIndicator,
      ...components,
    }}
    id={id}
    isMulti={false as IsMulti}
    name={`select-${name}`}
    getOptionLabel={getOptionLabel}
    allowCreateWhileLoading={allowCreateWhileLoading}
    formatCreateLabel={createLabel}
    styles={{
      option,
      control: control(width, hasError),
      menu,
      menuList,
      singleValue,
      valueContainer,
      indicatorsContainer,
      clearIndicator,
    }}
    {...props}
  />
);
