/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselOffersForLocationResponseDataItems
 */
export interface VesselOffersForLocationResponseDataItems {
  /**
   *
   * @type {string}
   * @memberof VesselOffersForLocationResponseDataItems
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof VesselOffersForLocationResponseDataItems
   */
  container: number;
  /**
   *
   * @type {number}
   * @memberof VesselOffersForLocationResponseDataItems
   */
  bulker: number;
  /**
   *
   * @type {number}
   * @memberof VesselOffersForLocationResponseDataItems
   */
  mpp: number;
}

export function VesselOffersForLocationResponseDataItemsFromJSON(json: any): VesselOffersForLocationResponseDataItems {
  return VesselOffersForLocationResponseDataItemsFromJSONTyped(json, false);
}

export function VesselOffersForLocationResponseDataItemsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselOffersForLocationResponseDataItems {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: json['date'],
    container: json['container'],
    bulker: json['bulker'],
    mpp: json['mpp'],
  };
}

export function VesselOffersForLocationResponseDataItemsToJSON(
  value?: VesselOffersForLocationResponseDataItems | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    container: value.container,
    bulker: value.bulker,
    mpp: value.mpp,
  };
}
