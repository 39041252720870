import {FC} from 'react';
import {AisFormItem, OnChangeSingleFormValue} from '../AisFormItem';
import {aisInitialFilters} from '../../../mapOptions/aisInitialFilters';
import {MinMaxRangeFilter} from './MinMaxRangeFilter';

export const BeamFormItem: FC<{onSetValuesViaApi: OnChangeSingleFormValue; disabled: boolean}> = ({
  onSetValuesViaApi,
  disabled,
}) => {
  return (
    <AisFormItem
      name={'beam'}
      defaultValue={aisInitialFilters.beam}
      label={'Beam'}
      labelCol={{span: 24}}
      onChangeSingleFormValue={onSetValuesViaApi}>
      <MinMaxRangeFilter disabled={disabled} precision={2} min={5} max={70} step={5} />
    </AisFormItem>
  );
};
