import {TourDefinition, TourId, getFinalStep} from '../../components/OnboardingTour/TourDefinition';

export const DashboardTourDefinition: TourDefinition = {
  id: TourId.Dashboard,
  steps: [
    {
      title: "Let's meet: Dashboard",
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/1%20Onboarding%20Dashboard.mp4',
      },
      pdfDownloadUrl:
        "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/02%20Dashboard/Let's%20meet%20the%20Dashboard.pdf",
      placement: 'center',
    },
    {
      title: 'How to add a widget',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/1%20How%20To%20Dashboard/1.1%20How%20to%20add%20a%20widget.mp4',
      },
      pdfDownloadUrl: 'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/02%20Dashboard/Add%20a%20widget.pdf',
      placement: 'center',
    },
    {
      title: 'How to change sizes and location',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/1%20How%20To%20Dashboard/1.2%20How%20to%20change%20sizes%20and%20location.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/02%20Dashboard/change%20sizes%20and%20locations.pdf',
      placement: 'center',
    },
    {
      title: 'How to change widget settings',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/1%20How%20To%20Dashboard/1.3%20%20How%20to%20change%20widget%20settings.mp4',
      },
      pdfDownloadUrl: 'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/02%20Dashboard/Change%20settings.pdf',
      placement: 'center',
    },
    getFinalStep('Dashboard'),
  ],
};
