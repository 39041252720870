import {useNavigate} from 'react-router-dom';
import {CargoFormLocationState} from './CargoFormLocationState';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

type OpenCargoFormParams = {defaultCargoValues?: PortfolioCargo; id?: number};

type OpenCargoForm = ({id, defaultCargoValues}: OpenCargoFormParams) => void;

/**
 * That hook returns a function that redirects to the vessel form and initializes it with the given vessel.
 * If no id is given, it redirects to add vessel otherwise to edit vessel.
 */
export const useOpenCargoForm = (): OpenCargoForm => {
  const navigate = useNavigate();

  return ({id, defaultCargoValues}: OpenCargoFormParams) => {
    const state: CargoFormLocationState = {
      defaultCargoValues: defaultCargoValues,
    };
    if (id) {
      navigate(`/cargo/${id}/edit`, {state});
    } else {
      navigate('/cargo/create', {state});
    }
  };
};
