import arrayMove from 'array-move';
import {
  NegotiationParty,
  NegotiationRoundResult,
  NegotiationTermType,
  NegotiationStatus,
  NegotiationTemplate,
} from '../../../../api/node-backend/generated';
import {
  NegotiationActions,
  negotiationActionsNoop,
} from '../../NegotiationDetail/Components/CommitArea/utils/useGetNegotiationActionsWithMutations';
import {createMockTerm} from '../../NegotiationDetail/Components/TermItem/test-utils/createMockTerm';
import {
  TermActions,
  termActionsNoop,
} from '../../NegotiationDetail/Components/TermItem/utils/useGetTermActionsWithMutations';
import {createMockNegotiation} from '../../test-utils/createMockNegotiation';
import {getSummaryFromRoundResult} from '../../test-utils/getSummaryFromRoundResult';
import uniqueId from 'lodash/uniqueId';

export const getTemplateNegotiation = (
  template: NegotiationTemplate,
  onChangeTemplate: (template: NegotiationTemplate | ((template: NegotiationTemplate) => NegotiationTemplate)) => void
) => {
  const {negotiation, roundResults} = createMockNegotiation({
    party: NegotiationParty.Creator,
    status: NegotiationStatus.Offer,
    allTermsSettled: false,
    allSubjectsAccepted: false,
    allSubjectsSettled: false,
    latestRoundCommitted: false,
  });

  const currentRoundResult: NegotiationRoundResult = {
    ...roundResults[0],
    terms: template.terms.map(templateTerm => createMockTerm({...templateTerm, id: templateTerm.id.toString()})) ?? [],
  };

  const roundResultSummaries = [currentRoundResult, ...roundResults.slice(1)].map(getSummaryFromRoundResult);

  const negotiationActions: NegotiationActions = {
    ...negotiationActionsNoop,
    createTerms: (terms: {text: string; type: NegotiationTermType | null}[]) => {
      onChangeTemplate(draft => ({
        ...draft,
        terms: [
          ...draft.terms,
          ...terms.map(term => ({
            ...term,
            id: parseInt(uniqueId()), // This is to make sure the numeric id is unique
          })),
        ],
      }));
    },
    updateSortIndex: (_: string, from: number, to: number) => {
      onChangeTemplate(draft => ({
        ...draft,
        terms: arrayMove(draft.terms, from, to),
      }));
    },
  };

  const getTermActions = (idx: number): TermActions => ({
    ...termActionsNoop,
    updateTermText: (text: string) => {
      if (text === template.terms[idx].text) {
        return;
      }
      onChangeTemplate(draft => ({
        ...draft,
        terms: draft.terms.map((term, termIdx) => {
          if (termIdx === idx) {
            return {
              ...term,
              text,
            };
          }
          return term;
        }),
      }));
    },
    updateTermType: (type: NegotiationTermType | null) => {
      if (type === template.terms[idx].type) {
        return;
      }
      onChangeTemplate(draft => ({
        ...draft,
        terms: draft.terms.map((term, termIdx) => {
          if (termIdx === idx) {
            return {
              ...term,
              type,
            };
          }
          return term;
        }),
      }));
    },
    deleteTerm: () => {
      onChangeTemplate(draft => ({
        ...draft,
        terms: draft.terms.filter((_, termIdx) => termIdx !== idx),
      }));
    },
  });

  return {
    negotiation,
    roundResultSummaries,
    negotiationActions,
    getTermActions,
  };
};
