import {Col, DatePicker, Form, FormInstance, Input, InputNumber, Row} from 'antd';
import {FC} from 'react';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {LocationsPickerPopover} from '../../../../../components/LocationPicker/LocationsPickerPopover';
import {InputNumberAcceptCommaAndDot} from '../../../../../antd/components/InputNumberAcceptCommaAndDot';
import {SeaboVesselPicker} from '../../../../../components/SeaboVesselPicker/SeaboVesselPicker';
import {SeaboVessel} from '../../../../../components/SearchPicker/Type';
import {confirmOverwriteImo} from './confirmOverwriteImo';

export const VesselBulkerFormItems: FC<{form: FormInstance}> = ({form}) => {
  const onSelectVessel = (vessel: SeaboVessel) => {
    if (!!form.getFieldValue('imo') && !!vessel.imo && form.getFieldValue('imo') !== vessel.imo) {
      confirmOverwriteImo({
        onOk: () => {
          form.setFieldsValue({
            imo: vessel.imo,
            name: vessel.name,
          });
        },
        onCancel: () => {
          form.setFieldsValue({
            name: vessel.name,
          });
        },
      });
      return;
    }
    form.setFieldsValue({
      imo: vessel.imo,
      name: vessel.name,
    });
  };

  return (
    <Row gutter={[8, 8]} data-testid={'VesselBulkerFormItems'}>
      <Col span={12}>
        <Form.Item label={'Shipname'} name={'name'}>
          <SeaboVesselPicker onSelect={onSelectVessel} indexNames={['ais_vessels']} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={'IMO'} name={'imo'}>
          <Input data-testid={'SeaboVesselPickerInputImo'} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name={'dwtSummer'}
          rules={[{min: 0, type: 'number', message: 'DWT cannot be less than 0.'}]}
          label={'DWT'}>
          <InputNumber style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={'LOA'}
          rules={[{min: 0, type: 'number', message: 'LOA cannot be less than 0.'}]}
          name={'lengthOverall'}>
          <InputNumberAcceptCommaAndDot style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={'Beam'}
          rules={[{min: 0, type: 'number', message: 'Beam cannot be less than 0.'}]}
          name={'beam'}>
          <InputNumberAcceptCommaAndDot style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={'Draft'}
          rules={[{min: 0, type: 'number', message: 'Draft cannot be less than 0.'}]}
          name={'draft'}>
          <InputNumberAcceptCommaAndDot style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={8} />
      <Col span={12}>
        <Form.Item label={'Open date'} name={'nextOpen'}>
          <DatePicker.RangePicker style={{width: '100%'}} format={DateTimeFormat.Date} allowEmpty={[true, true]} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={'Location open'} name={'nextOpenLocations'} style={{marginBottom: 4}}>
          <LocationsPickerPopover
            value={form.getFieldValue('nextOpenLocations')}
            onSelect={locations => {
              form.setFieldsValue({
                nextOpenLocations: locations,
              });
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
