import {TODO} from '../../../../utils/TODO';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {NotAvailable} from '../../../../utils/NotAvailable';

const ballastAccessor = (): AccessorFn<TODO> => rowValue => {
  const vessel = getVesselFromRowValue(rowValue, undefined);
  let label;
  switch (vessel.isBallast) {
    case true:
      label = <span style={{color: 'var(--color-red)'}}>Ballast</span>;
      break;
    case false:
      label = <span style={{color: 'var(--color-green)'}}>Laden</span>;

      break;
    case undefined:
    default:
      label = NotAvailable;
      break;
  }
  return (
    <div className={'market-grid-cell'} data-cy="cell-size">
      <div className={'market-grid-cell-content'}>{label}</div>
    </div>
  );
};

export const ballastColumn = ({
  minWidth = 5,
  sortable = true,
}: {
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<TODO> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-ballast">
      <div>Ballast / Laden</div>
    </div>
  ),
  id: 'isBallast',
  accessorFn: ballastAccessor(),
  cell: info => info.getValue(),
  minWidth,
  enableSorting: sortable,
});
