/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface NewSubject
 */
export interface NewSubject {
  /**
   *
   * @type {string}
   * @memberof NewSubject
   */
  termId: string | null;
  /**
   *
   * @type {string}
   * @memberof NewSubject
   */
  text: string;
}

export function NewSubjectFromJSON(json: any): NewSubject {
  return NewSubjectFromJSONTyped(json, false);
}

export function NewSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewSubject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    termId: json['termId'],
    text: json['text'],
  };
}

export function NewSubjectToJSON(value?: NewSubject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    termId: value.termId,
    text: value.text,
  };
}
