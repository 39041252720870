import {useMemo, useState} from 'react';
import styled from 'styled-components';
import Lighthouse from '../../../../atoms/EmptyState/LightHouse';
import Icon from '../../../../atoms/Icon';
import {ActionMenu} from '../../../../components/ActionMenu/ActionMenu';
import {MenuItem} from '../../../../atoms/Menu/MenuItem';
import {
  FilterDatabaseType,
  makeFilterBranchDefinitions,
} from '../../../../components/FilterProvider/FilterBranchDefinition';
import VesselType from '../../../../components/FilterProvider/Filters/VesselType/VesselType';
import * as VesselTypeDefinition from '../../../../components/FilterProvider/Filters/VesselType/VesselTypeDefinition';
import {useFilterProvider} from '../../../../components/FilterProvider/useFilterProvider';
import {FilterProviderState} from '../../../../components/FilterProvider/FilterProviderState';
import {draftColumn} from '../../../../components/GridLayout/columns/vessel/draftColumn';
import {dwtColumn} from '../../../../components/GridLayout/columns/vessel/dwtColumn';
import {gearsColumn} from '../../../../components/GridLayout/columns/vessel/gearsColumn';
import {lengthColumn} from '../../../../components/GridLayout/columns/vessel/lengthColumn';
import {ownerColumn} from '../../../../components/GridLayout/columns/vessel/ownerColumn';
import {shipBuilderColumn} from '../../../../components/GridLayout/columns/vessel/shipBuilderColumn';
import {shipNameColumn} from '../../../../components/GridLayout/columns/vessel/shipNameColumn/shipNameColumn';
import {teuColumn} from '../../../../components/GridLayout/columns/vessel/teuColumn';
import {useGetNewVesselsFromSpireQuery} from '../../../../queries/queriesToSpire/useGetNewVesselsFromSpireQuery';
import {useOpenVesselForm} from '../../../CargoVesselForm/utils/useOpenVesselForm';
import {sortVessels} from './sortVessels';
import {transformSpireVesselToPlattformVessel} from './transformSpireVesselToPlattformVessel';
import {filterVesselByType} from './utils/filterVesselByType';
import {DataGrid} from '../../../../components/DataGrid/DataGrid';
import {ColumnSort} from '@tanstack/react-table';

const PAGESIZE = 5;
const filterId = 123;

const NewVesselsFilter = makeFilterBranchDefinitions([VesselTypeDefinition.filterBranchDefinition] as const);
type NewVesselsDatabaseFilter = Partial<FilterDatabaseType<typeof NewVesselsFilter>>;

export const VesselsFromSpireBody = () => {
  const [page, setPage] = useState(1);
  const [sortByColumns, setSortByColumns] = useState<ColumnSort[]>([{id: 'spireAddedAt', desc: true}]);
  const [filterSettings, setFilterSettings] = useState<NewVesselsDatabaseFilter>({});
  const [renewedFilter, setRenewedFilter] = useState(true);
  const [filterProviderState, setFilterProviderState] = useState<FilterProviderState<typeof NewVesselsFilter> | null>(
    null
  );
  const openVesselForm = useOpenVesselForm();

  const newVesselsQuery = useGetNewVesselsFromSpireQuery();

  const dataSource = useMemo(() => {
    const filteredVessels = filterVesselByType({
      vessels: (newVesselsQuery.data ?? []).map(transformSpireVesselToPlattformVessel),
      filter: filterSettings.vesselTypes ?? [],
    });

    const sortedVessels = sortVessels({
      sortByColumns,
      vessels: filteredVessels,
    });

    return sortedVessels;
  }, [newVesselsQuery.data, filterSettings, sortByColumns]);

  const filterProviderApi = useFilterProvider({
    name: 'TestScreen',
    filterBranchDefinitions: NewVesselsFilter,
    filterData: {
      id: filterId,
      filterSettings,
      shouldReload: renewedFilter,
    },
    filterProviderState: filterProviderState,
    onFilterSettingsChange: filterSettings => {
      setFilterSettings(filterSettings);
      setPage(1);
      setRenewedFilter(false);
    },
    onFilterProviderStateChange: state => {
      setFilterProviderState(state);
    },
  });

  return (
    <div>
      <div className={'scol-6 scol-sm-4 scol-md-3 scol-lg-2'}>
        <VesselType filterProviderApi={filterProviderApi} />
      </div>
      {dataSource.length > 0 ? (
        <StyledDataGrid
          loading={newVesselsQuery.isLoading}
          showPageSizeOptions={false}
          totalCount={dataSource.length}
          pageSize={PAGESIZE}
          onPageChange={page => {
            setPage(page);
          }}
          page={page}
          manualPagination={false}
          onSortedChange={sortByColumns => {
            setSortByColumns([sortByColumns]);
          }}
          sorted={sortByColumns}
          zeroBasedPageIndex={false}
          data={dataSource}
          columns={[
            shipNameColumn({options: {isLink: false}}),
            teuColumn({}),
            dwtColumn({}),
            gearsColumn({}),
            lengthColumn({}),
            draftColumn({}),
            ownerColumn({}),
            shipBuilderColumn({}),
            {
              id: 'vessel.actions',
              enableSorting: false,
              minWidth: 32,
              maxWidth: 40,
              className: 'datagrid-td-action',
              header: '',
              cell: info => info.getValue(),
              accessorFn: vessel => {
                return (
                  <ActionMenu
                    dataTestid={'actionBtn'}
                    actionIcon={<Icon type={'more-vert'} />}
                    items={[
                      {
                        key: 'Add to My Fleet',
                        label: (
                          <MenuItem
                            label="Add to My Fleet"
                            dataTestid={'addToPortfolioBtn'}
                            onClick={() => {
                              openVesselForm({
                                defaultVesselValues: vessel,
                              });
                            }}
                            withDivider
                          />
                        ),
                      },
                    ]}
                  />
                );
              },
            },
          ]}
        />
      ) : (
        <Lighthouse
          small={true}
          showCallbackButton={false}
          headline={'Please change the filters to show results.'}
          subHeadline={'We can not find suitable vessels.'}
        />
      )}
    </div>
  );
};

export const StyledDataGrid = styled(DataGrid)`
  .pagination {
    background: transparent !important;
  }
` as unknown as typeof DataGrid;
