import {MapElement} from '../MapDetails/utils/types';
import * as MapDetailsActions from '../../../redux/MapDetails';
import {useDispatch} from '../../../redux/react-redux';

export const useSetMapDetailElement = () => {
  const dispatch = useDispatch();

  const setMapDetailElement = (element: MapElement | null) => dispatch(MapDetailsActions.setMapDetailElement(element));

  return setMapDetailElement;
};
