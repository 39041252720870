import {FC, useState} from 'react';
import {Input, Modal, ModalProps} from 'antd';
import {ModalWidth} from '../../../../../antd/components/Modal';

type RetractNegotiationOfferModalProps = Omit<ModalProps, 'title' | 'width' | 'okType' | 'onOk' | 'cancelText'> & {
  onOk: (reason?: string) => void;
};

export const RetractNegotiationOfferModal: FC<RetractNegotiationOfferModalProps> = ({onOk, ...restProps}) => {
  const [reason, setReason] = useState<string>('');

  return (
    <Modal
      title={'Are you sure you want to retract this offer?'}
      okType="danger"
      width={ModalWidth.Middle}
      onOk={() => onOk(reason)}
      cancelText="Abort"
      {...restProps}>
      <p>
        By retracting this offer, you cancel this negotiation and create a new one. <br />
        Your counterpart will receive an email, that this negotiation is cancelled. You will be able to amend and send
        your offer again via the new negotiation.
      </p>
      <p>Do you want to add a reason for the cancellation?</p>
      <Input
        type="text"
        placeholder="Reason for cancellation (optional)"
        value={reason}
        onChange={e => setReason(e.target.value)}
      />
    </Modal>
  );
};
