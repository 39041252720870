import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import displayGridUtils from '../../../../screens/market/GridDisplay/displayGridUtils';
import {AccessorFn} from '@tanstack/react-table';
import classNames from 'classnames';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {SeaboVessel} from '../../../../api/node-backend/generated';

const teuAccessor =
  (type: RowValueType): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'} data-cy="cell-teu">
        <div className={'market-grid-cell-content'}>{displayGridUtils.getIntake(vessel.intakes, 'teu')}</div>
        <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
          {displayGridUtils.getIntake(vessel.intakes, 'teu14')}
        </div>
      </div>
    );
  };
export const teuColumn = ({
  type,
  minWidth = 5,
  enableSorting = true,
}: {
  type?: RowValueType;
  minWidth?: number;
  enableSorting?: boolean;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div className={classNames({'datagrid-sortable-header': enableSorting})} data-cy="header-teu">
      <div>
        TEU
        <br />
        TEU14
      </div>
    </div>
  ),
  id: 'teuAmount',
  accessorFn: teuAccessor(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
  enableSorting,
});
