import {useQuery} from '@tanstack/react-query';
import {useDispatch} from '../../../redux/react-redux';
import {getPortMooredVessels} from '../../../redux/ApiService/ports';

export const useVesselsInPortQuery = ({portCode}: {portCode: string}) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['VesselsInPort', portCode],
    queryFn: () => {
      return dispatch(getPortMooredVessels(portCode));
    },
  });
};
