import {FC, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import './style.scss';
import {FilterCategory, FilterType} from '../../api/symfony/generated';
import {FilterDebugger} from '../../components/FilterProvider/FilterDebugger';
import {FilterProviderState} from '../../components/FilterProvider/FilterProviderState';
import {
  VesselFixturesDatabaseFilter,
  VesselFixturesFilterBranchDefinitions,
} from '../../components/FilterProvider/Filters/Fixtures/VesselFixturesFilterBranchDefinitions';
import {useFilterProvider} from '../../components/FilterProvider/useFilterProvider';
import {useLoadOrCreateFilterQuery} from '../../queries/filter/useLoadOrCreateFilter';
import {useUpdateFilterMutation} from '../../queries/filter/useUpdateFilterMutation';
import autocompleteApi from '../../redux/ApiService/autocomplete';
import {FixtureDataActions} from '../../redux/FixtureData';
import {ModalActions} from '../../redux/Modal';
import {useDispatch, useSelector, useStore} from '../../redux/react-redux';
import {VesselFixtureBody} from './VesselFixtureBody';
import ManageFixtureModal from '../../components/Modal/ManageFixture/ManageFixtureModal';
import './style.scss';

export const VesselFixtureScreen: FC = () => {
  const location = useLocation();

  const filterState = useSelector(state => state.fixtureData.filterState);

  const dispatch = useDispatch();
  const setFilterProviderState = (
    filterProviderState: FilterProviderState<typeof VesselFixturesFilterBranchDefinitions>
  ) => {
    dispatch(FixtureDataActions.setFilterProvider(filterProviderState));
  };
  const preFillCharterer = () => dispatch(autocompleteApi.preFillCharterer());
  const preFillSearch = (indexName: string) => dispatch(autocompleteApi.preFillSearch(indexName));

  // Load filter from API
  const filterQuery = useLoadOrCreateFilterQuery({
    category: FilterCategory.Fixtures,
    type: FilterType.Vessel,
  });

  useEffect(() => {
    if (filterQuery.isSuccess && filterQuery.data) {
      dispatch(FixtureDataActions.loadFilterSuccess(filterQuery.data));
    }
  }, [filterQuery.isSuccess, filterQuery.data, dispatch]);

  useEffect(() => {
    preFillCharterer();
    preFillSearch('design_type');
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.openNewFixtureModal) {
      dispatch(ModalActions.show(<ManageFixtureModal />));
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [location.state?.openNewFixtureModal]);

  const store = useStore();
  const updateFilterMutation = useUpdateFilterMutation();

  const updateFixtureFilters = (filterSettings: VesselFixturesDatabaseFilter) => {
    const filterState = store.getState().fixtureData.filterState;

    // Detect race condition
    if (!filterState.filterId) {
      throw new Error("Missing fixtureData.filterState.filterId - can't perform request");
    }

    updateFilterMutation.mutate({
      id: filterState.filterId!,
      filterItemWrite: {
        category: FilterCategory.Fixtures,
        type: FilterType.Vessel,
        filterSettings,
      },
    });

    dispatch(FixtureDataActions.setGridState({pageIndex: 1}));
    dispatch(FixtureDataActions.setFilter(filterSettings));
  };

  const filterProviderApi = useFilterProvider({
    name: 'Fixtures',
    filterBranchDefinitions: VesselFixturesFilterBranchDefinitions,
    filterData: {
      id: filterState.filterId,
      filterSettings: filterState.filter,
    },
    filterProviderState: filterState.filterProvider,
    onFilterProviderStateChange: setFilterProviderState,
    onFilterSettingsChange: filterSettings => {
      if (!filterState.filterId) {
        // This is a race condition.
        // We can't store the filter on the server because we haven't finished loading our fixtures yet.
        // Hack around the problem by not sending the update.
        // eslint-disable-next-line no-console
        console.warn('filterId not available - not sending fixture filters to the server');
        return;
      }
      updateFixtureFilters(filterSettings);
    },
  });

  if (!filterQuery.isSuccess) {
    return null;
  }

  return (
    <div data-testid="VesselFixtureScreen">
      <VesselFixtureBody filterProviderApi={filterProviderApi} />
      <FilterDebugger currentFilters={filterState.filter} filterProviderState={filterState.filterProvider} />
    </div>
  );
};
