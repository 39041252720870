import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {UpdateWorkspaceRequest} from '../api/symfony/generated';
import {workspaceApi} from '../api/symfony/symfonyApi';
import {ApiError} from '../api/utils/ApiError';

export const useUpdateWorkspaceMutation = (options: UseMutationOptions<void, ApiError, UpdateWorkspaceRequest>) => {
  return useMutation({
    mutationFn: async (workspace: UpdateWorkspaceRequest): Promise<void> => {
      await workspaceApi.updateWorkspace(workspace);
    },
    ...options,
  });
};
