import {FC, ReactNode} from 'react';
import {ActiveSubMarketList} from './ActiveSubMarketList';
import {FilterItem} from '../../../api/symfony/generated';
import {MarketType} from '../../../redux/Market';
import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import {MarketVesselFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';
import {MarketCargoFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';

interface Props {
  marketType: MarketType;
  tabBarExtraContent?: ReactNode;
  filterProviderApi:
    | FilterProviderApi<typeof MarketVesselFilterBranchDefinitions>
    | FilterProviderApi<typeof MarketCargoFilterBranchDefinitions>;
}

export const SubMarketList: FC<Props> = props => {
  const onDelete = () => {
    props.filterProviderApi.resetAll();
  };

  const onSelectTab = async (subMarket: FilterItem | null) => {
    if (subMarket) {
      await props.filterProviderApi.loadFilterItem(subMarket);
    } else {
      props.filterProviderApi.resetAll();
    }
  };

  return (
    <ActiveSubMarketList
      marketType={props.marketType}
      tabBarExtraContent={props.tabBarExtraContent}
      onDelete={onDelete}
      onSelectTab={onSelectTab}
    />
  );
};
