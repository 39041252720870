/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Owner, OwnerFromJSON, OwnerFromJSONTyped, OwnerToJSON} from './';

/**
 *
 * @export
 * @interface ManagementManagingOwners
 */
export interface ManagementManagingOwners {
  /**
   *
   * @type {Array<Owner>}
   * @memberof ManagementManagingOwners
   */
  owners: Array<Owner>;
  /**
   *
   * @type {number}
   * @memberof ManagementManagingOwners
   */
  vesselsWithoutInformation: number;
}

export function ManagementManagingOwnersFromJSON(json: any): ManagementManagingOwners {
  return ManagementManagingOwnersFromJSONTyped(json, false);
}

export function ManagementManagingOwnersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ManagementManagingOwners {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    owners: (json['owners'] as Array<any>).map(OwnerFromJSON),
    vesselsWithoutInformation: json['vesselsWithoutInformation'],
  };
}

export function ManagementManagingOwnersToJSON(value?: ManagementManagingOwners | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    owners: (value.owners as Array<any>).map(OwnerToJSON),
    vesselsWithoutInformation: value.vesselsWithoutInformation,
  };
}
