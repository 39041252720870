import {useRef, useState, FC} from 'react';
import {Alert, Divider} from 'antd';
import styled from 'styled-components';
import {CheckCircleOutlined} from '@ant-design/icons';
import {MarketImportInstructions} from '../../MarketImportInstructionsScreen/MarketImportInstructions';
import {UpgradeButton} from '../../../atoms/Button/UpgradeButton';
import {SupportAgentsBar} from '../../../components/SupportAgentsBar/SupportAgentsBar';

export const CompanySubscriptionFlowFeatureSetup: FC<{
  isSubscribed: boolean;
  onFinished: () => void;
}> = ({isSubscribed, onFinished}) => {
  const supportRef = useRef<HTMLDivElement>(null);
  const [animateSupport, setAnimateSupport] = useState(false);

  const scrollToSupport = () => {
    supportRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setAnimateSupport(true);
    setTimeout(() => setAnimateSupport(false), 500);
  };

  return (
    <StepContainer data-testid="SubscriptionFlowFeatureSetup">
      {isSubscribed && (
        <Alert
          type="success"
          message={
            <>
              <CheckCircleOutlined /> <b>Congratulations! You are now subscribed to seabo Premium.</b>
              <br />
              Make the most of your Premium features by setting up your market connection below.
            </>
          }
        />
      )}
      <MarketImportInstructions scrollToSupport={scrollToSupport} />
      <Divider />
      <p>Notice: You will receive an email per workspace, confirming that your forwarding setup works.</p>
      <UpgradeButton size="large" block onClick={onFinished} to={'/subscription'}>
        4. Done
      </UpgradeButton>
      <SupportAgentsBar scrollRef={supportRef} animate={animateSupport} />
    </StepContainer>
  );
};

const StepContainer = styled.div`
  padding: 40px;
  text-align: center;
`;
