import {useContext, FC, useEffect} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {ListOfSelectedVessels, SideContentRendererProps} from './ListOfSelectedVessels';
import {useQuery} from '@tanstack/react-query';
import {TODO} from '../../../utils/TODO';
import {useDraftStatsMapQuery} from '../../../utils/vessel/useDraftStatsMapQuery';
import {getIsBallast} from '../../../utils/vessel/getIsBallast';
import {MapElement, MapSwitches} from '../MapDetails/utils/types';

type SideContentContainerParams = {
  switches: MapSwitches;
  className?: string;
  render?: (props: SideContentRendererProps) => React.ReactNode;
  onSelectElement: (element: MapElement) => void;
};

export const SideContentContainer: FC<SideContentContainerParams> = props => {
  const mapContext = useContext(MapContext);
  const {selectedItems, showSideContent} = mapContext.state;

  const draftStatsMapQuery = useDraftStatsMapQuery();
  const imos: number[] = selectedItems.items.map(item => item.object.imo);
  const dataSourceQuery = useQuery({
    queryKey: ['mapSidebarGrid', imos],
    queryFn: () => {
      const itemsWithoutSpire: TODO[] = selectedItems.items.map(item => {
        const dwt = item.object.dwt ?? item.object.dwtSummer;
        const teu = item.object.teu ?? item.object.teuQuantity;

        const draftItem = draftStatsMapQuery.data?.[item.object.imo];
        const isBallast = getIsBallast({vessel: {d: item.object.d}, draftItem});

        return {
          ...item,
          ...item.object,
          key: item.object.imo,
          capacity: item.type === 'container' ? teu : dwt,
          draftItem,
          isBallast,
        };
      });

      return itemsWithoutSpire;
    },
    enabled: draftStatsMapQuery.isSuccess && imos.length > 0,
  });

  const onClose = () => {
    mapContext.setSelectedItems({type: '', items: []});
    mapContext.setShowSideContent(false);
  };
  const onVesselFilterChanged = () => {
    mapContext.setSelectedItems({type: '', items: []});
    mapContext.setShowSideContent(false);
  };

  useEffect(() => {
    /*
    The vessel_ais switches contain the filters of the displayed vessels.
    If these are changed, the selected vessels are not correct, so they are reset and the list is closed.
     */
    onVesselFilterChanged();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.switches.vessel_ais]);

  if (!showSideContent) {
    return <></>;
  }

  if (props.render) {
    return (
      <>
        {props.render!({
          items: dataSourceQuery.data ?? [],
          className: props.className,
          onClose,
          onSelectElement: props.onSelectElement,
        })}
      </>
    );
  }

  return (
    <ListOfSelectedVessels
      items={dataSourceQuery.data ?? []}
      className={props.className}
      onSelectElement={props.onSelectElement}
      onClose={onClose}
    />
  );
};
