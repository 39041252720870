import {FC} from 'react';
import Icon from '../../../../atoms/Icon';
import {PlanWithUvps} from './planUvps';
import {ContractDuration} from '../ContractDurationPicker';
import {SubscriptionPlan, SubscriptionPlans} from '../../../../redux/ApiService/CompanyService/Company';
import {PlanBadge} from '../PlanBadge';
import styled from 'styled-components';
import {ChangePlanButton} from '../ChangePlanButton';
import {formatEUR} from '../../../../utils/formatCurrency';

type PlanBoxProps = {
  targetPlan: PlanWithUvps;
  showActionButton?: boolean;
  subscribedPlanSlug: SubscriptionPlan;
  contractDuration: ContractDuration;
};

export const PlanBox: FC<PlanBoxProps> = ({targetPlan, showActionButton, subscribedPlanSlug, contractDuration}) => {
  const {title, slug, priceMonthly, priceYearly, description, uvpLabel: featureLabel, uvps: features} = targetPlan;
  const isCurrent = subscribedPlanSlug === targetPlan.slug;
  const price = contractDuration === 'monthly' ? priceMonthly : priceYearly;
  const priceMonthlyString = formatEUR(priceMonthly);
  const priceString = formatEUR(price);

  return (
    <Container $isCurrent={isCurrent}>
      <PlanBadge targetPlan={targetPlan} subscribedPlanSlug={subscribedPlanSlug} />
      <Header>
        <Title>{title}</Title>
        <Price>
          {contractDuration === 'yearly' && slug !== SubscriptionPlans.Free && (
            <PriceValueStriked>€{priceMonthlyString}</PriceValueStriked>
          )}
          <PriceValue>€{priceString}</PriceValue>
          <PriceUnit>per user/month</PriceUnit>
        </Price>
        <Description>{description}</Description>
        {showActionButton && (
          <ChangePlanButton
            targetPlan={targetPlan}
            subscribedPlanSlug={subscribedPlanSlug}
            contractDuration={contractDuration}
          />
        )}
      </Header>
      <Divider />
      <Features>
        <FeatureHeader>{featureLabel}</FeatureHeader>
        {features.map((feature, idx) => (
          <Feature key={idx}>
            <Icon type="check-mark-line" color="azure" />
            <FeatureLabel>{feature}</FeatureLabel>
          </Feature>
        ))}
      </Features>
    </Container>
  );
};

const Container = styled.div<{$isCurrent?: boolean}>`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-4);
  ${({$isCurrent}) => ($isCurrent ? 'border-color: var(--color-blue);' : '')}
`;

const Header = styled.div<{$isCurrent?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 20px;
  padding: 0 20px;
`;

const Title = styled.div`
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  color: var(--color-black);
`;

const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 8px;
`;

const PriceValue = styled.div`
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  color: var(--color-black);
`;

const PriceValueStriked = styled.div`
  position: relative;
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  color: var(--color-gray-2);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--color-upgrade);
    transform: translateY(-50%) rotate(-20deg);
  }
`;

const PriceUnit = styled.div`
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-md);
  line-height: 1.4;
  color: var(--color-gray-2);
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-md);
  line-height: 1.4;
  color: var(--color-black);
`;

const Divider = styled.div`
  height: 0;
  margin: 20px 0;
  border-top: 1px solid var(--color-gray-4);
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 20px 0;
`;

const FeatureHeader = styled.div`
  color: #262626;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-md);
  text-transform: uppercase;
`;

const Feature = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const FeatureLabel = styled.div`
  text-align: start;
  color: var(--color-black);
  font-size: var(--font-size-md);
`;
