import {Button, Col, Row, Upload} from 'antd';
import {assert} from '../../../../utils/assert';
import {UploadOutlined} from '@ant-design/icons';
import {useState, FC} from 'react';
import {useMutation} from '@tanstack/react-query';
import {UploadRequestOption} from 'rc-upload/lib/interface';
import {fastVesselImportApi} from '../../../../api/node-backend/nodeBackendApis';
import {ParseExcelResponse} from '../../../../api/node-backend/generated';
import styled from 'styled-components';
import {ModalFooter} from '../../../../antd/components/ModalFooter';

export interface FastVesselImportParsedValues extends ParseExcelResponse {
  vessels: Record<string, string | null>[];
}

type Props = {
  onChange: (result: FastVesselImportParsedValues) => void;
};
export const ImportExcel: FC<Props> = ({onChange}) => {
  const [errors, setErrors] = useState<null | string[]>(null);
  const uploadMutation = useMutation({
    mutationFn: async (options: UploadRequestOption) => {
      const file = options.file as Blob;
      assert(file);
      return (await fastVesselImportApi.parseExcel({file})) as FastVesselImportParsedValues;
    },

    onSuccess: values => {
      onChange(values);
    },
  });
  return (
    <>
      <Row style={{marginTop: 32}} gutter={[16, 16]}>
        <Col span={12}>
          <p>Here you can upload a spreadsheet containing the vessels you want to import.</p>
          <p>
            The spreadsheet must contain a column with the IMO for each vessel.
            <br />
            The simplest sheet would have a header row and a single column with IMO numbers.
            <br />
            The sheet must be in Excel format.
          </p>
          <p>
            We have a template that you can use. You can download it here.
            <br />
            <a href="/static/exampleFiles/VesselImport.xlsx" referrerPolicy={'no-referrer'} target={'_blank'}>
              Vessel import spreadsheet template
            </a>
          </p>
        </Col>
      </Row>
      <ModalFooter>
        <Upload
          name="file"
          showUploadList={false}
          multiple={false}
          customRequest={uploadMutation.mutate}
          beforeUpload={async file => {
            const validationErrors: string[] = [];
            assert(file.type);
            if (
              !'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'.includes(
                file.type
              )
            ) {
              validationErrors.push('You can only upload Excel files');
            }
            assert(file.size);
            // TODO: What is max size from Backend?
            if (file.size / 1024 > 4096) {
              validationErrors.push('File must be smaller than 4MB');
            }
            setErrors(validationErrors.length === 0 ? null : validationErrors);
            return validationErrors.length === 0;
          }}
          data-testid="Upload">
          <Button type="primary" loading={uploadMutation.isPending}>
            <UploadOutlined />
            Upload document
          </Button>
        </Upload>
        {errors?.map(error => <Error key={error}>{error}</Error>)}
      </ModalFooter>
    </>
  );
};

const Error = styled.div`
  padding-top: 12px;
  font-weight: bold;
  color: var(--color-red);
`;
