const conversionFactorFromKmToNm = 1.852;

export const seaVoyageDistanceFilter = <Vessel extends {distanceSeaVoyageInNm?: number}>({
  vessels,
  maxDistanceInKM,
}: {
  vessels: Vessel[];
  maxDistanceInKM: number;
}): Vessel[] => {
  return vessels.filter(vessel => {
    if (vessel.distanceSeaVoyageInNm === undefined) {
      return false;
    }
    return vessel.distanceSeaVoyageInNm <= maxDistanceInKM / conversionFactorFromKmToNm;
  });
};
