import {Tabs} from 'antd';
import React, {FC} from 'react';
import {TermListDivider} from '../NegotiationDetail/Components/TermArea/CreateArea';
import {
  NegotiationRoundResultSummary,
  NegotiationSummary as NegotiationSummaryType,
} from '../../../api/node-backend/generated';
import {NegotiationSummary} from '../Components/NegotiationSummary';

type DummyNegotiationSummaryProps = {
  negotiation: NegotiationSummaryType;
  roundResultSummaries: NegotiationRoundResultSummary[];
  isPlaceholdersReplaced: boolean;
  onChangeIsPlaceholdersReplaced: (isPlaceholdersReplaced: boolean) => void;
};

export const DummyNegotiationSummary: FC<DummyNegotiationSummaryProps> = ({
  negotiation,
  roundResultSummaries,
  isPlaceholdersReplaced,
  onChangeIsPlaceholdersReplaced,
}) => {
  return (
    <>
      <Tabs
        onChange={value => onChangeIsPlaceholdersReplaced(value === 'preview')}
        tabBarStyle={{marginTop: -10, marginBottom: 20, borderBottom: 'var(--border-base)'}}
        items={[
          {key: 'edit', label: 'Edit terms'},
          {
            key: 'preview',
            label: 'Preview',
          },
        ]}
      />
      {isPlaceholdersReplaced && (
        <div style={{marginTop: -5}}>
          <NegotiationSummary negotiation={negotiation} roundResultSummaries={roundResultSummaries} />
          <TermListDivider />
        </div>
      )}
    </>
  );
};
