import {useContext} from 'react';
import {VoyageCharterContext} from '../VoyageCharterContext';
import {VoyageConsumption} from '../../VoyageInformation/VoyageTypes';
import produce from 'immer';
import {InputState} from '../../utils/voyageChartercalculation/voyageChartercalculation';

export const changeConsumptionInVoyagePointOrVoyageRoute = ({
  nodeType,
  index,
  consumption,
  inputState,
  setInputState,
}: {
  nodeType: 'point' | 'route';
  index: number;
  consumption: VoyageConsumption | undefined;
  inputState: InputState;
  setInputState: (inputState: InputState) => void;
}) => {
  setInputState(
    produce(inputState, draftInputState => {
      if (nodeType === 'point') {
        draftInputState.voyage.points[index].consumption = consumption;
      } else {
        draftInputState.voyage.routes[index].consumption = consumption;
      }
      if (!consumption?.manuallyAdded) {
        draftInputState.voyage.routesCalculated = false;
      }
    })
  );
};

export const useChangeConsumptionInVoyagePointOrVoyageRoute = () => {
  const {setInputState, state} = useContext(VoyageCharterContext);

  return (params: {consumption: VoyageConsumption | undefined; nodeType: 'point' | 'route'; index: number}): void => {
    changeConsumptionInVoyagePointOrVoyageRoute({
      consumption: params.consumption,
      setInputState,
      inputState: state.inputState,
      nodeType: params.nodeType,
      index: params.index,
    });
  };
};
