import dayjs from 'dayjs';
import {useQuery} from '@tanstack/react-query';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {VoyageTimelineRange} from './calculateVoyageTimeSeries';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {VesselVoyageTimelineResponse} from '../../../../api/node-backend/generated';
import {vesselVoyageTimelineApi} from '../../../../api/node-backend/nodeBackendApis';

export const useGetVoyageTimelineQuery = (
  imo?: number | null,
  timeRange?: VoyageTimelineRange,
  options?: UseQueryOptions<VesselVoyageTimelineResponse>
) => {
  const [from, to] = timeRange ?? [dayjs().subtract(1, 'month'), dayjs()];

  return useQuery({
    queryKey: [
      'vesselVoyageTimelineApi.getVoyageTimeline',
      imo,
      from?.format(DateTimeFormat.Date),
      to?.format(DateTimeFormat.Date),
    ],
    queryFn: async () => {
      const response = await vesselVoyageTimelineApi.getVoyageTimeline({
        imo: imo!,
        from: from?.toDate() ?? new Date(),
        to: to?.toDate() ?? new Date(),
      });
      return response;
    },
    enabled: !!from && !!to && !!imo,
    ...options,
  });
};
