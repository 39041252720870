import {TourDefinition, TourId} from '../../../../components/OnboardingTour/TourDefinition';

const STEPS_SELECTOR = '[data-testid="NegotiationSteps"]';
const HEADER_SELECTOR = '[data-testid="NegotiationHeader"]';
const TERM_LIST_SELECTOR = '[data-testid="NegotiationTermArea"]';
const TERM_ACTIONS_SELECTOR = '[data-testid="TermActions"]';
const TERM_ITEM_CREATE_SELECTOR = '[data-testid="TermItemCreate"]';
const NEGOTIATION_SUBJECTS_SELECTOR = '[data-testid="SubjectItemCreate"]';
const REPLY_TIME_SELECTOR = '[data-testid="NegotiationReplyTimeArea"]';
const ROUND_DIFF_SELECTOR = '[data-testid="NegotiationRoundDiff"]';
const ATTACHMENTS_SELECTOR = '[data-testid="NegotiationAttachments"]';
const HISTORY_SELECTOR = '[data-testid="NegotiationHistory"]';
const COMMIT_AREA_SELECTOR = '[data-testid="NegotiationCommitArea"]';

export const tourDefinitionNegotiation: TourDefinition = {
  id: TourId.Negotiation,
  steps: [
    {
      target: null,
      title: 'Welcome to our negotiations tutorial in seabo!',
      placement: 'center',
      content: (
        <>
          <p>Here, you will see the key functionalities of the Negotiation feature.</p>
        </>
      ),
    },
    {
      target: HEADER_SELECTOR,
      placement: 'top',
      title: 'Here, you can see the basic information on the negotiation.',
      content: (
        <>
          <p>
            Who do you negotiate with, what is the current status and more. <br />
            The vessel and cargo details stated are for reference. <br />
            The red dot near the "ongoing" indicates that it is your turn to counter.
          </p>
        </>
      ),
    },
    {
      target: ROUND_DIFF_SELECTOR,
      placement: 'top',
      title: "Here you find the last round's changes.",
      content: (
        <>
          <p>
            When you receive an update from your counterpart, review the changes here. <br />
            Text changes are highlighted so you can easily spot them.
          </p>
        </>
      ),
      onEnterCallback: () => {
        const roundDiffCollapseHeader = document
          .querySelector(ROUND_DIFF_SELECTOR)
          ?.querySelector('.ant-collapse-header') as HTMLElement | null;
        if (!roundDiffCollapseHeader) {
          return;
        }
        if (roundDiffCollapseHeader.getAttribute('aria-expanded') === 'false') {
          roundDiffCollapseHeader.click();
        }
      },
    },
    {
      target: STEPS_SELECTOR,
      placement: 'top',
      isLarge: true,
      title: 'Overall process',
      content: (
        <>
          <p>Here, you can see a visualisation of the current status of the overall negotiation progress.</p>
        </>
      ),
    },
    {
      target: TERM_LIST_SELECTOR,
      placement: 'top',
      title: 'Here is where the action happens!',
      content: (
        <>
          <p>
            Create, edit, accept, and reject terms and conditions. <br />
            The legend on the left provides a detailed explanation of the colour markers.
          </p>
        </>
      ),
    },
    {
      target: TERM_ACTIONS_SELECTOR,
      placement: 'top',
      title: 'Here are the "term actions".',
      content: (
        <>
          <p>
            Via these buttons, you can edit a term, accept a term, or reject a term. <br />
            To view the change history, simply click on the term number.
          </p>
        </>
      ),
    },
    {
      target: TERM_ITEM_CREATE_SELECTOR,
      placement: 'top',
      title: 'Here is where you can create a new term.',
      content: (
        <>
          <p>Categorise it by clicking on the term type and selecting it from the drop-down menu.</p>
        </>
      ),
    },
    {
      target: NEGOTIATION_SUBJECTS_SELECTOR,
      placement: 'top',
      title: 'Here, you can create and negotiate subjects.',
      content: (
        <>
          <p>A default subject can be selected from the dropdown menu or simply use "free text" mode.</p>
        </>
      ),
    },
    {
      target: REPLY_TIME_SELECTOR,
      placement: 'top',
      title: 'Here, you can set a reply time for your counterpart',
    },
    {
      target: COMMIT_AREA_SELECTOR,
      placement: 'top',
      title: 'Here is the commit area.',
      content: (
        <>
          <p>
            This is the place where you commit, go to recap, and settle the negotiation. <br />
            Ensure you always finish your round so your counterpart gets your updates.
          </p>
        </>
      ),
    },
    {
      target: ATTACHMENTS_SELECTOR,
      placement: 'top',
      title: 'Here, you can attach files to your negotiation.',
      content: (
        <>
          <p>Add documents to the negotiation, which remain saved for easy reference.</p>
        </>
      ),
    },
    {
      target: HISTORY_SELECTOR,
      placement: 'top',
      title: 'Here is the negotiation history.',
      content: (
        <>
          <p>
            Review the negotiation history to see the changes made by you and your counterpart. <br />
            You can also see the time and date of the changes.
          </p>
        </>
      ),
    },
    {
      target: null,
      placement: 'center',
      title: 'We wish you a happy fixing!',
      content: (
        <>
          <p>
            If you have questions and comments, please get in touch with us via{' '}
            <a href="mailto:support@seabo.com">support@seabo.com</a>.
          </p>
        </>
      ),
    },
  ],
};
