import {useMutation, MutateOptions} from '@tanstack/react-query';
import {TODO} from '../../utils/TODO';
import {ApiError} from '../../api/utils/ApiError';
import {useDispatch} from '../../redux/react-redux';
import {actions as fixtureApi} from '../../redux/ApiService/FixtureService';

interface Request {
  body: TODO;
}

export const useUpdateFixtureMutation = (options: MutateOptions<void, ApiError, TODO>) => {
  const dispatch = useDispatch();
  const updateFixture = (body: TODO) => dispatch(fixtureApi.patchFixture({params: {id: body.id}, body}));

  return useMutation({
    mutationFn: async (request: Request) => {
      return await updateFixture(request);
    },
    ...options,
  });
};
