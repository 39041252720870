import {GeoJsonLayer} from '@deck.gl/layers/typed';
import {ecaAreaGeoJsonProps} from '../const';
import geoJsonEca from '../geoJson-Eca';

export const useEcaLayer = ({visible}: {visible: boolean}) => {
  if (!visible) {
    return [];
  }
  return [
    new GeoJsonLayer({
      ...ecaAreaGeoJsonProps,
      id: `eca-lines`,
      data: geoJsonEca,
    }),
    new GeoJsonLayer({
      id: `eca-outline`,
      ...ecaAreaGeoJsonProps,
      data: geoJsonEca,
      filled: false,
    }),
  ];
};
