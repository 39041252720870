import {FC} from 'react';
import {VoyageRoute} from '../../VoyageTypes';
import {getUsedFuelType} from '../../../utils/getUsedFuelType';
import {useSelector} from '../../../../../../redux/react-redux';

type Params = {route: VoyageRoute; type: 'main' | 'aux'};

const getLabel = ({route, isScrubberFitted, type}: Params & {isScrubberFitted: boolean}) => {
  const consumptionFuelType = type === 'main' ? route.consumption?.mainFuelType : route.consumption?.auxFuelType;

  const isPartlyInSECA = (route.distanceInSeca ?? 0) > 0;
  const isPartlyOutsideSECA = route.distance !== route.distanceInSeca;

  const isMixedVoyage = isPartlyInSECA && isPartlyOutsideSECA;

  const fuelTypeInSECA = getUsedFuelType({
    isSECA: true,
    fuelType: consumptionFuelType!,
    isScrubberFitted: isScrubberFitted,
  });

  const fuelTypeOutsideSECA = getUsedFuelType({
    isSECA: false,
    fuelType: consumptionFuelType!,
    isScrubberFitted: isScrubberFitted,
  });

  if (isMixedVoyage) {
    if (fuelTypeInSECA === fuelTypeOutsideSECA) {
      return fuelTypeOutsideSECA;
    }
    return 'VLSFO/MGO';
  }

  if (isPartlyOutsideSECA) {
    return fuelTypeOutsideSECA;
  }

  return fuelTypeInSECA;
};

export const ConsumptionFuelTypeLabel: FC<Params> = ({route, type}) => {
  const isScrubberFitted = useSelector(state => state.charterCalculation.inputState.vessel.isScrubberFitted);
  return <span>{getLabel({route, isScrubberFitted, type})?.toLocaleUpperCase()}</span>;
};
