import {useEffect, useState, FC} from 'react';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import {CompanyWorkspace} from '../../api/symfony/generated';
import User from '../../model/User';
import {Button} from '../../atoms/Button/Button';
import Input from '../../atoms/Input';
import Tooltip from '../../atoms/Tooltip';
import {WorkspaceList} from '../../atoms/WorkspaceSelect/WorkspaceList';
import {Workspace} from '../../atoms/WorkspaceSelect/WorkspaceSelectTypes';
import CompanyActions, {workspaceSelector} from '../../redux/ApiService/CompanyService/CompanyService';
import {ModalActions} from '../../redux/Modal';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {MailForwardingHelpLinkList} from '../CompanySubscriptionScreen/components/MailForwardingHelpLinkList';
import {Typography} from 'antd';
import {Company} from '../../redux/ApiService/CompanyService/Company';
import {useUser} from '../../components/UserContext/useUser';
import CreateWorkspace from '../../components/Modal/CreateWorkspace';

export const MarketImportInstructions: FC<{scrollToSupport: () => void}> = ({scrollToSupport}) => {
  const user: User = useUser();

  const dispatch = useDispatch();

  const company: Company = useSelector(state => state.company.data);
  const workspaces: CompanyWorkspace[] = useSelector(state => workspaceSelector(state));
  const getWorkspaceList = (id: number) => dispatch(CompanyActions.getWorkspaceList(id));

  useEffect(() => {
    if (!company?.id) {
      return;
    }
    getWorkspaceList(company.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id]);

  const preselectedWorkspace = workspaces[0] ?? null;
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(preselectedWorkspace);
  const [copyLabel, setCopyLabel] = useState('Copy to clipboard');

  const openCreateWorkspaceModal = () => dispatch(ModalActions.show(<CreateWorkspace method="add" />));

  const onWorkspaceSelected = (workspace: Workspace) => {
    setSelectedWorkspace(workspace);
    copyEmail();
  };

  const copyEmail = () => {
    copy(selectedWorkspace?.email ?? '');
    setCopyLabel('Copied!');
    setTimeout(() => setCopyLabel('Copy to clipboard'), 3000);
  };

  return (
    <>
      <Typography.Title level={4} style={{textAlign: 'center', margin: '40px auto 0'}}>
        Connect your Mail-Inbox to your seabo Account
      </Typography.Title>
      <p style={{textAlign: 'center', margin: '20px auto 40px'}}>
        Complete these steps to receive Circulars directly in the seabo Market.
      </p>
      <InstructionContainer>
        <InstructionStep>
          <InstructionHeadline>1. Select the workspace you want to connect:</InstructionHeadline>
          <InstructionHint>
            You can set up separate inboxes for every workspace and also create new ones on the spot.
          </InstructionHint>
          <WorkspaceList
            workspaces={workspaces}
            user={user}
            value={selectedWorkspace?.id}
            onSelect={onWorkspaceSelected}
          />
          <Button primary block onClick={openCreateWorkspaceModal} styleOverride={{marginTop: 10}}>
            Create new Workspace
          </Button>
        </InstructionStep>
        <InstructionStep>
          <InstructionHeadline>2. Copy this email address for use in the next step:</InstructionHeadline>
          <InstructionHint>
            This address is used to receive and allocate the emails you forward to seabo to your workspace.
          </InstructionHint>
          <Label htmlFor="workspace-email">Workspace forwarding email:</Label>
          <Input
            id="workspace-email"
            readOnly
            placeholder="Select a workspace to the left to start"
            value={selectedWorkspace?.email ?? ''}
            onClick={copyEmail}
          />
          <Button primary disabled={!selectedWorkspace} block onClick={copyEmail} styleOverride={{marginTop: 10}}>
            {copyLabel}
          </Button>
        </InstructionStep>
        <InstructionStep>
          <InstructionHeadline>3. Create a forwarding rule in your own Inbox:</InstructionHeadline>
          <InstructionHint>
            Select your email provider for further instructions or contact our support professionals below.
          </InstructionHint>
          <FlexBox>
            <Tooltip
              title="Come back here to switch between workspaces and finish the setup below."
              trigger="mouseenter"
              position="right">
              <MailForwardingHelpLinkList onSupportLinkClick={scrollToSupport} />
            </Tooltip>
          </FlexBox>
        </InstructionStep>
      </InstructionContainer>
    </>
  );
};

const InstructionContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 50px;
  width: 100%;
  margin-top: 20px;
  text-align: start;

  div[data-testid='WorkspaceList'] {
    width: 100%;
    max-height: 130px;
    margin-top: 10px;
    border: var(--border-base);
    border-radius: var(--border-radius);
    overflow-y: scroll;
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: start;
`;

const Label = styled.label`
  display: block;
  margin: 15px 0 10px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
`;

const InstructionStep = styled.div`
  width: 33.3%;
  padding: 0 0 20px;
`;

const InstructionHeadline = styled.p`
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
`;

const InstructionHint = styled.p`
  width: 100%;
  margin-bottom: 20px;
  color: var(--color-gray-2);
  text-align: center;
`;
