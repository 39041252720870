import Icon from '../../../atoms/Icon';
import {getIconForCommodity} from '../../../utils/helper';
import styled from 'styled-components';

export const MapCommodityIcon = ({label, type}: {type: string; label: string | React.ReactElement}) => (
  <span className="map-option-commodity">
    <StyledIcon type={getIconForCommodity(type)} color={'white-fixed'} />
    <StyledSpan>{label}</StyledSpan>
  </span>
);

const StyledIcon = styled(Icon)`
  /* margin-top: 1px; */

  &:before {
    font-size: var(--font-size-xl);
  }
`;

const StyledSpan = styled.span`
  font-size: var(--font-size-md);
`;
