import {TODO} from '../utils/TODO';

export default class BaseModel {
  payload: TODO;

  constructor(payload: TODO) {
    if (this.constructor === BaseModel) {
      throw new TypeError('Cannot construct a BaseModel instances directly');
    }
    this.payload = {...payload};
  }
}
