import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {NegotiationRoundResult, NewSubject} from '../../../../../../api/node-backend/generated';
import {negotiationNegotiationSubjectApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useCreateSubjectsMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {negotiationId: string; newSubjects: NewSubject[]}>
) =>
  useMutation({
    mutationFn: async ({negotiationId, newSubjects}) => {
      const result = await negotiationNegotiationSubjectApi.newSubjects({
        newSubjectsRequest: {
          negotiationId,
          newSubjects,
        },
      });
      return result;
    },
    ...options,
  });
