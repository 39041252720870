import {Row, Col, Button} from 'antd';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {TermListDivider} from '../NegotiationDetail/Components/TermArea/CreateArea';
import {TemplateTerms} from './TemplateTerms';
import {TemplateHeader} from './TemplateHeader';
import {NegotiationTemplate} from '../../../api/node-backend/generated';
import {replaceNegotiationPlaceholdersWithValues} from './utils/replaceNegotiationPlaceholdersWithValues';
import {getTemplateNegotiation} from './utils/getTemplateNegotiation';
import {DummyNegotiationSummary} from './DummyNegotiationSummary';

type NegotiationTemplateBodyProps = {
  templateDraft: NegotiationTemplate;
  hasChanges: boolean;
  onChangeTemplate: (
    newTemplateDraft: NegotiationTemplate | ((newTemplateDraft: NegotiationTemplate) => NegotiationTemplate)
  ) => void;
  onRevertTemplate: () => void;
  onSaveTemplate: () => void;
  onDuplicateTemplate: () => void;
  onRemoveTemplate: () => void;
};

export const NegotiationTemplateBody: FC<NegotiationTemplateBodyProps> = ({
  templateDraft,
  hasChanges,
  onChangeTemplate,
  onRevertTemplate,
  onSaveTemplate,
  onDuplicateTemplate,
  onRemoveTemplate,
}) => {
  const [isPlaceholdersReplaced, setIsPlaceholdersReplaced] = useState(false);

  useEffect(() => {
    setIsPlaceholdersReplaced(false);
  }, [templateDraft]);

  const {negotiation, roundResultSummaries, negotiationActions, getTermActions} = useMemo(() => {
    return getTemplateNegotiation(templateDraft, onChangeTemplate);
  }, [templateDraft, onChangeTemplate]);

  const templateWithPlaceholdersReplaced: NegotiationTemplate = useMemo(() => {
    return {
      ...templateDraft,
      terms: templateDraft.terms.map(term => ({
        ...term,
        text: replaceNegotiationPlaceholdersWithValues(term.text, negotiation),
      })),
    };
  }, [templateDraft, negotiation]);

  return (
    <>
      <TemplateHeader
        template={isPlaceholdersReplaced ? templateWithPlaceholdersReplaced : templateDraft}
        hasChanges={hasChanges}
        onChangeTemplate={onChangeTemplate}
        onRevertTemplate={onRevertTemplate}
        onSaveTemplate={onSaveTemplate}
        onDuplicateTemplate={onDuplicateTemplate}
        onRemoveTemplate={onRemoveTemplate}
      />
      <TermListDivider />
      <DummyNegotiationSummary
        negotiation={negotiation}
        roundResultSummaries={roundResultSummaries}
        isPlaceholdersReplaced={isPlaceholdersReplaced}
        onChangeIsPlaceholdersReplaced={setIsPlaceholdersReplaced}
      />
      <TemplateTerms
        template={isPlaceholdersReplaced ? templateWithPlaceholdersReplaced : templateDraft}
        canBeEdited={!isPlaceholdersReplaced}
        negotiationActions={negotiationActions}
        getTermActions={getTermActions}
      />
      {hasChanges && (
        <>
          <TermListDivider />
          <Row justify={'center'} gutter={[10, 10]}>
            <Col>
              <Button type="default" onClick={onRevertTemplate}>
                Revert
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onSaveTemplate}>
                Save changes
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
