/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AccountListItemCompanyData,
  AccountListItemCompanyDataFromJSON,
  AccountListItemCompanyDataFromJSONTyped,
  AccountListItemCompanyDataToJSON,
} from './';

/**
 *
 * @export
 * @interface AccountListItem
 */
export interface AccountListItem {
  /**
   *
   * @type {AccountListItemCompanyData}
   * @memberof AccountListItem
   */
  company: AccountListItemCompanyData;
  /**
   *
   * @type {number}
   * @memberof AccountListItem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AccountListItem
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AccountListItem
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AccountListItem
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AccountListItem
   */
  avatarUrl: string;
  /**
   *
   * @type {Date}
   * @memberof AccountListItem
   */
  registrationDate: Date | null;
  /**
   *
   * @type {Date}
   * @memberof AccountListItem
   */
  lastActivity?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof AccountListItem
   */
  isDisabled?: boolean | null;
}

export function AccountListItemFromJSON(json: any): AccountListItem {
  return AccountListItemFromJSONTyped(json, false);
}

export function AccountListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountListItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    company: AccountListItemCompanyDataFromJSON(json['company']),
    id: json['id'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    avatarUrl: json['avatarUrl'],
    registrationDate: json['registrationDate'] === null ? null : new Date(json['registrationDate']),
    lastActivity: !exists(json, 'lastActivity')
      ? undefined
      : json['lastActivity'] === null
      ? null
      : new Date(json['lastActivity']),
    isDisabled: !exists(json, 'isDisabled') ? undefined : json['isDisabled'],
  };
}

export function AccountListItemToJSON(value?: AccountListItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    company: AccountListItemCompanyDataToJSON(value.company),
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    avatarUrl: value.avatarUrl,
    registrationDate: value.registrationDate === null ? null : value.registrationDate.toISOString(),
    lastActivity:
      value.lastActivity === undefined
        ? undefined
        : value.lastActivity === null
        ? null
        : value.lastActivity.toISOString(),
    isDisabled: value.isDisabled,
  };
}
