import DetailTable from '../DetailTable';
import DetailTableBody from '../DetailTable/DetailTableBody';
import DetailsTableCell from '../DetailTable/DetailTableCell';
import DetailTableRow from '../DetailTable/DetailTableRow';

import dayjs from 'dayjs';
import Icon from '../../atoms/Icon';
import {transformFileSize} from '../../utils/helper';
import './documents.scss';
import {DateTimeFormat} from '../../utils/DateTimeFormat';

type Props = {
  documents?: $TSFixMe[];
  noText?: boolean;
  deletable?: boolean;
  onRemove?: (key: number) => void;
};

const DocumentsGrid = ({documents, noText = false, deletable = false, onRemove}: Props) => {
  if (documents && documents.length === 0) {
    if (!noText) {
      return <span className="documents-grid__text">No documents available</span>;
    } else {
      return null;
    }
  }

  return (
    <DetailTable fullWidth>
      <DetailTableRow headerWrapper>
        <DetailsTableCell width={'10%'} value={'Type'} />
        <DetailsTableCell width={'50%'} value={'Name'} />
        <DetailsTableCell width={'10%'} value={'Size'} />
        <DetailsTableCell width={'20%'} value={'Uploaded'} />
        <DetailsTableCell width={'10%'} value={' '} />
      </DetailTableRow>
      <DetailTableBody>
        {documents &&
          documents.map((document, key) => (
            <DetailTableRow key={`document-row-${key}`}>
              <DetailsTableCell width={'10%'} value={document.fileExtension.toUpperCase()} />
              <DetailsTableCell width={'50%'} value={document.filename} />
              <DetailsTableCell width={'10%'} value={transformFileSize(document.size)} />
              <DetailsTableCell width={'20%'} value={dayjs(document.date).format(DateTimeFormat.Date)} />
              <DetailsTableCell width={'10%'}>
                {deletable ? (
                  <Icon type={'clear'} onClick={() => onRemove?.(key)} style={{cursor: 'pointer'}} />
                ) : (
                  <a href={document.url} target="_blank" rel="noopener noreferrer">
                    VIEW
                  </a>
                )}
              </DetailsTableCell>
            </DetailTableRow>
          ))}
      </DetailTableBody>
    </DetailTable>
  );
};

export default DocumentsGrid;
