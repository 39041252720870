import {Card} from 'antd';
import React, {Component} from 'react';
import {SideNote} from '../../../atoms/SideNote/SideNote';
import './style.scss';
import {CompanyAddress, CompanyContact} from '../../../api/symfony/generated';

interface Props {
  address: CompanyAddress;
  contact: CompanyContact;
  taxId?: string | null;
}

class ContactDetails extends Component<Props> {
  render() {
    const {city, country, name, street, street2, streetNumber, zip} = (this.props as Props).address;
    const {email, fax, phone, website, firstName, lastName} = (this.props as Props).contact;
    const {taxId} = this.props;
    return (
      <Card className={'contact-details'}>
        <div className={'contact-details__headline'}>Contact details</div>
        {firstName && <SideNote title={'first name'} content={<div>{firstName}</div>} />}
        {lastName && <SideNote title={'last name'} content={<div>{lastName}</div>} />}
        <SideNote title={'e-mail'} content={<a href={`mailto: ${email}`}>{email}</a>} />
        <SideNote title={'website'} content={<a href={website ?? undefined}>{website}</a>} />
        <SideNote title={'phone'} content={<a href={`tel:${phone}`}>{phone}</a>} />
        <SideNote title={'fax'} content={fax} />
        <SideNote
          title={'postal address'}
          content={
            <React.Fragment>
              <div>{name}</div>
              <div>
                {street} {streetNumber}
              </div>
              <div>{street2}</div>
              <div>
                {zip} {city}
              </div>
              <div>{country}</div>
            </React.Fragment>
          }
        />
        {taxId && <SideNote title={'Tax Id'} content={<div>{taxId}</div>} />}
      </Card>
    );
  }
}
export default ContactDetails;
