/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ReportMissingPortNotificationRequest,
  ReportMissingPortNotificationRequestFromJSON,
  ReportMissingPortNotificationRequestToJSON,
  VesselDataListResponse,
  VesselDataListResponseFromJSON,
  VesselDataListResponseToJSON,
  VesselType,
  VesselTypeFromJSON,
  VesselTypeToJSON,
} from '../models';

export interface ListVesselDataForAllVesselsRequest {
  vesselTypes?: Array<VesselType>;
  teuFrom?: number;
  teuTo?: number;
  deadweightFrom?: number;
  deadweightTo?: number;
}

export interface ReportMissingPortRequest {
  vessel: number;
  reportMissingPortNotificationRequest: ReportMissingPortNotificationRequest;
}

export interface UpdateVesselRequest {
  id: number;
  body: object;
}

/**
 *
 */
export class VesselApi extends runtime.BaseAPI {
  /**
   */
  async listVesselDataForAllVesselsRaw(
    requestParameters: ListVesselDataForAllVesselsRequest
  ): Promise<runtime.ApiResponse<VesselDataListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.vesselTypes) {
      queryParameters['vesselTypes[]'] = requestParameters.vesselTypes;
    }

    if (requestParameters.teuFrom !== undefined) {
      queryParameters['teuFrom'] = requestParameters.teuFrom;
    }

    if (requestParameters.teuTo !== undefined) {
      queryParameters['teuTo'] = requestParameters.teuTo;
    }

    if (requestParameters.deadweightFrom !== undefined) {
      queryParameters['deadweightFrom'] = requestParameters.deadweightFrom;
    }

    if (requestParameters.deadweightTo !== undefined) {
      queryParameters['deadweightTo'] = requestParameters.deadweightTo;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/page/vessel-accumulation/vessel-information`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => VesselDataListResponseFromJSON(jsonValue));
  }

  /**
   */
  async listVesselDataForAllVessels(
    requestParameters: ListVesselDataForAllVesselsRequest
  ): Promise<VesselDataListResponse> {
    const response = await this.listVesselDataForAllVesselsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async reportMissingPortRaw(requestParameters: ReportMissingPortRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.vessel === null || requestParameters.vessel === undefined) {
      throw new runtime.RequiredError(
        'vessel',
        'Required parameter requestParameters.vessel was null or undefined when calling reportMissingPort.'
      );
    }

    if (
      requestParameters.reportMissingPortNotificationRequest === null ||
      requestParameters.reportMissingPortNotificationRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'reportMissingPortNotificationRequest',
        'Required parameter requestParameters.reportMissingPortNotificationRequest was null or undefined when calling reportMissingPort.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/vessels/{vessel}/report-missing-port`.replace(
        `{${'vessel'}}`,
        encodeURIComponent(String(requestParameters.vessel))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ReportMissingPortNotificationRequestToJSON(requestParameters.reportMissingPortNotificationRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async reportMissingPort(requestParameters: ReportMissingPortRequest): Promise<void> {
    await this.reportMissingPortRaw(requestParameters);
  }

  /**
   */
  async updateVesselRaw(requestParameters: UpdateVesselRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateVessel.'
      );
    }

    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling updateVessel.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/vessels/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async updateVessel(requestParameters: UpdateVesselRequest): Promise<object> {
    const response = await this.updateVesselRaw(requestParameters);
    return await response.value();
  }
}
