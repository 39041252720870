import {PortVisit, VesselVoyageTimelineResponse, VoyageTimelineRoute} from '../../../../api/node-backend/generated';
import {VoyageTimelineEventKey} from './filters';

export type TimelineEvent = VesselVoyageTimelineResponse[VoyageTimelineEventKey][number];

export type FilterEventsWithKey<ISearchKey extends string> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [IEventKey in VoyageTimelineEventKey]: VesselVoyageTimelineResponse[IEventKey][number] extends Record<ISearchKey, any>
    ? IEventKey
    : never;
}[VoyageTimelineEventKey];

export type TimelineEventMoment = VesselVoyageTimelineResponse[FilterEventsWithKey<'timestamp'>][number];
export type TimelineEventFrame = VesselVoyageTimelineResponse[FilterEventsWithKey<'enterTimestamp'>][number];
export type TimelineEventWithDraft = VesselVoyageTimelineResponse[FilterEventsWithKey<'enterDraft'>][number];

export const getIsEventMoment = (event: TimelineEvent): event is TimelineEventMoment => {
  return 'timestamp' in event;
};
export const getIsEventFrame = (event: TimelineEvent | null): event is TimelineEventFrame => {
  return 'enterTimestamp' in (event ?? {});
};
export const getIsEventWithDraft = (event: TimelineEvent | null): event is TimelineEventWithDraft => {
  return 'enterDraft' in (event ?? {});
};
export const getIsEventWithCoordinates = (event: TimelineEvent): boolean => {
  return 'port' in event || 'pier' in event || 'anchorage' in event;
};

export const getEventHasCoordinates = (event: TimelineEvent): boolean => {
  return (
    ('port' in event && !!event.port.polygon?.length) ||
    ('pier' in event && !!event.pier.polygon?.length) ||
    ('anchorage' in event && !!event.anchorage.polygon?.length)
  );
};

export enum TimelineItemType {
  Port = 'port',
  Voyage = 'voyage',
}

export type TimelineItemPort = {
  index: number;
  type: TimelineItemType.Port;
  portVisit: PortVisit;
  enterTimestamp: Date;
  exitTimestamp: Date;
  subEvents: TimelineEvent[];
  isBallast?: boolean;
};

export type TimelineItemVoyage = {
  index: number;
  type: TimelineItemType.Voyage;
  route: VoyageTimelineRoute;
  enterTimestamp: Date;
  exitTimestamp: Date;
  departurePortVisit?: PortVisit;
  arrivalPortVisit?: PortVisit;
  subEvents: TimelineEvent[];
  isBallast?: boolean;
};

export type TimelineItem = TimelineItemPort | TimelineItemVoyage;
