/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum StatusEnum {
  Active = 'active',
  Scrapped = 'scrapped',
  Employed = 'employed',
  Yard = 'yard',
  Drydock = 'drydock',
  Onorder = 'onorder',
}

export function StatusEnumFromJSON(json: any): StatusEnum {
  return StatusEnumFromJSONTyped(json, false);
}

export function StatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusEnum {
  return json as StatusEnum;
}

export function StatusEnumToJSON(value?: StatusEnum | null): any {
  return value as any;
}
