import dayjs from 'dayjs';
import {CargoSortOptions, VesselSortOptions} from '../../../../redux/MapDetails';
import {MapDetailItems} from './types';

export const getSortedCargoes = (
  portAreaItems: MapDetailItems,
  cargoSort: CargoSortOptions,
  cargoSortDesc: boolean
) => ({
  items: portAreaItems.cargoes.items.sort((a, b) => {
    switch (cargoSort) {
      case 'laycan':
        if (!a.laycanFrom) {
          return 1;
        }
        if (!b.laycanFrom) {
          return -1;
        }
        return dayjs(b.laycanFrom).diff(dayjs(a.laycanFrom)) * (cargoSortDesc ? 1 : -1);
      case 'size':
        if (!a.quantityMin) {
          return 1;
        }
        if (!b.quantityMin) {
          return -1;
        }
        return (b.quantityMin - a.quantityMin) * (cargoSortDesc ? 1 : -1);
      default:
        return cargoSortDesc ? 1 : -1;
    }
  }),
  totalItems: portAreaItems.cargoes.totalItems,
});

export const getSortedVessels = (
  portAreaItems: MapDetailItems,
  vesselSort: VesselSortOptions,
  vesselSortDesc: boolean
) => ({
  items: portAreaItems.vessels.items.sort((a, b) => {
    switch (vesselSort) {
      case 'dateOpen':
        if (!a.nextOpenFrom) {
          return 1;
        }
        if (!b.nextOpenFrom) {
          return -1;
        }
        return dayjs(b.nextOpenFrom).diff(dayjs(a.nextOpenFrom)) * (vesselSortDesc ? 1 : -1);
      case 'size':
        if (!a.dwtSummer) {
          return 1;
        }
        if (!b.dwtSummer) {
          return -1;
        }
        return (b.dwtSummer - a.dwtSummer) * (vesselSortDesc ? 1 : -1);
      case 'year':
        if (!a.builtYear) {
          return 1;
        }
        if (!b.builtYear) {
          return -1;
        }
        return (b.builtYear - a.builtYear) * (vesselSortDesc ? 1 : -1);
      default:
        return vesselSortDesc ? 1 : -1;
    }
  }),
  totalItems: portAreaItems.vessels.totalItems,
});
