export const focusField = (params: {dataFieldId?: string; openEditMode?: () => void}) => {
  return new Promise<void>(resolve => {
    if (params.dataFieldId === undefined) {
      return;
    }
    if (document.querySelector(`[data-field-id="${params.dataFieldId}"]`) === null) {
      params.openEditMode?.();
    }
    setTimeout(() => {
      (document.querySelector(`[data-field-id="${params.dataFieldId}"]`) as HTMLInputElement)?.focus();
      resolve();
    }, 0);
  });
};
