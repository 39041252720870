import {Fragment} from 'react';
import NumberRangeInput from '../../../RangeInput/NumberRangeInput';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import {hasError} from '../../../../utils/helper';
import Checkbox from '../../../../atoms/Checkbox';
import {filterBranchDefinition, QuantityUnits} from './QuantityDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import Select from '../../../../atoms/Select/Select';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const Quantity = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="scol-12">
              <NumberRangeInput
                flex
                id={'filter-quantity'}
                inputSubId="Quantity"
                allowEqualMinMax
                fromValue={values.quantityFrom}
                onFromChange={value => handleChange('quantityFrom', value)}
                fromProps={{
                  min: 0,
                  max: 999999,
                  placeholder: 'min',
                  hasError: hasError('quantityFrom', validations),
                }}
                toValue={values.quantityTo}
                onToChange={value => handleChange('quantityTo', value)}
                toProps={{
                  min: 0,
                  max: 999999,
                  placeholder: 'max',
                  hasError: hasError('quantityTo', validations),
                }}
              />
              <Select
                isClearable
                id={'filter-quantity-unit'}
                value={values.unit === '' ? null : values.unit}
                onChange={value => handleChange('unit', value)}
                options={QuantityUnits}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.value}
                components={{
                  DropdownIndicator: ArrowIndicator,
                }}
              />
            </div>
          </div>
          {values.unit &&
            (values.unit.value === 'teu' || values.unit.value === 'feu' || values.unit.value === '45f') && (
              <div className="row">
                <div className="scol-6">
                  <Checkbox
                    spMT={'s'}
                    label={'High cube'}
                    id={'filter-quantity-isHighcube'}
                    checked={values.isHighcube}
                    onChange={e => handleChange('isHighcube', e.target.checked)}
                  />
                </div>
                <div className="scol-6">
                  <Checkbox
                    spMT={'s'}
                    label={'Empty'}
                    id={'filter-quantity-isEmpty'}
                    checked={values.isEmpty}
                    onChange={e => handleChange('isEmpty', e.target.checked)}
                  />
                </div>
              </div>
            )}
        </div>
      </Fragment>
    )}
  </FilterButton>
);

export default Quantity;
