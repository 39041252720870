import {DependencyList, useCallback} from 'react';
import debounce from 'lodash/debounce';

// Here I copy from @types/lodash in order to avoid an "import {DebounceSettings} from 'lodash'".
// I suspect that this could bundle the whole lodash package.
interface DebounceSettings {
  /**
   * @see _.leading
   */
  leading?: boolean | undefined;
  /**
   * @see _.maxWait
   */
  maxWait?: number | undefined;
  /**
   * @see _.trailing
   */
  trailing?: boolean | undefined;
}
/**
 * Returns a debounced callback function.
 *
 * @param handler the callback function that should be debounced
 * @param deps a dependency array that is used to update the handler when any dependency changes
 * @param wait the number of milliseconds to debounce invocations to
 * @param options the options object from lodash/debounce
 * @returns the debounced function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any) => any>(
  handler: T,
  deps: DependencyList,
  waitTime?: number,
  options?: DebounceSettings
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(handler, waitTime, options), deps);
};
