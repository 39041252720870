/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface SimilarVesselImosResponse
 */
export interface SimilarVesselImosResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof SimilarVesselImosResponse
   */
  items: Array<number>;
}

export function SimilarVesselImosResponseFromJSON(json: any): SimilarVesselImosResponse {
  return SimilarVesselImosResponseFromJSONTyped(json, false);
}

export function SimilarVesselImosResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SimilarVesselImosResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: json['items'],
  };
}

export function SimilarVesselImosResponseToJSON(value?: SimilarVesselImosResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: value.items,
  };
}
