import {FC} from 'react';
import {NegotiationParty, NegotiationTermHistoryItem} from '../../../../../../../api/node-backend/generated';
import {TermHistoryItem} from './TermHistoryItem';
import {useGetTermHistoryQuery} from './useGetTermHistoryQuery';

const getTermHistoryWithoutCurrentState = (
  termHistory: NegotiationTermHistoryItem[],
  isOtherUsersLastStateVisible: boolean
) => {
  // Note: getTermHistoryQuery always responds with all rounds including the current round (editable or not).
  // The term item always shows the current state of the term, so we need to filter it out from the history

  // If the the term item already shows the counterparts's last state (-1), we slice to the index before that (-2)
  // This is the case when the current user can modify the term but has not modified it yet
  if (isOtherUsersLastStateVisible) {
    return termHistory.slice(0, -2);
  }

  // Otherwise we slice only until the current round (-1)
  return termHistory.slice(0, -1);
};

type TermHistoryProps = {
  termId: string;
  party: NegotiationParty;
  isOtherUsersLastStateVisible: boolean;
};

export const TermHistory: FC<TermHistoryProps> = ({termId, party, isOtherUsersLastStateVisible}) => {
  const getTermHistoryQuery = useGetTermHistoryQuery(termId);
  const termHistory = getTermHistoryQuery.data?.items ?? [];

  const termHistoryWithoutCurrentState = getTermHistoryWithoutCurrentState(termHistory, isOtherUsersLastStateVisible);
  const termHistoryWithRoundIndex = termHistoryWithoutCurrentState.map((item, idx) => ({...item, roundIndex: idx}));
  const termHistoryWithOnlyChanges = termHistoryWithRoundIndex.filter(item => item.changed);

  return (
    <div data-testid="TermHistory">
      {termHistoryWithOnlyChanges.map((termHistoryItem, idx) => (
        <TermHistoryItem key={idx} termHistoryItem={termHistoryItem} party={party} />
      ))}
    </div>
  );
};
