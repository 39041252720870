/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ListImosResponse
 */
export interface ListImosResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof ListImosResponse
   */
  imos: Array<number>;
}

export function ListImosResponseFromJSON(json: any): ListImosResponse {
  return ListImosResponseFromJSONTyped(json, false);
}

export function ListImosResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListImosResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    imos: json['imos'],
  };
}

export function ListImosResponseToJSON(value?: ListImosResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    imos: value.imos,
  };
}
