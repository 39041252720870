import {cargoAccessor} from '../../utils/cargoAccessorResult';
import CargoUtil from '../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';

const dischargeRedeliveryAccessor = (
  type: $TSFixMe,
  {showFlag}: {showFlag?: boolean}
): AccessorFn<CargoGridColumnsProps> =>
  cargoAccessor(
    (d: $TSFixMe) => (
      <div className="market-grid-cell-content__discharge">{CargoUtil.dischargeLocation(d, '-', showFlag)}</div>
    ),
    type
  );
export const dischargeRedeliveryColumn = ({
  type,
  minWidth = 8,
  showFlag,
}: {
  type: $TSFixMe;
  minWidth?: number;
  showFlag?: boolean;
}): CustomColumnDef<CargoGridColumnsProps> => ({
  header: 'Disch. / redlvry',
  id: 'discharge',
  enableSorting: false,
  accessorFn: dischargeRedeliveryAccessor(type, {showFlag}),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
