import sortBy from 'lodash/sortBy';
import {NegotiationRoundResultSummary} from '../../../../api/node-backend/generated';
import last from 'lodash/last';

export const getLastCommittedRound = (roundResults: NegotiationRoundResultSummary[]) => {
  const comittedRounds = roundResults.filter(round => !!round.committedAt);

  if (comittedRounds.length === 0) {
    return null;
  }

  const sortedRounds = sortBy(comittedRounds, result => result.committedAt);
  return last(sortedRounds);
};
