import {CurrentUser, Reference} from '../api/symfony/generated';
import {assert} from '../utils/assert';
import {TODO} from '../utils/TODO';

type UserOptionKey = 'timezone';

class User {
  payload: CurrentUser;

  constructor(payload: CurrentUser | undefined) {
    // This weird construct is in place to keep old ITS code working.
    this.payload = payload ? {...payload} : ({} as CurrentUser);
  }

  get id() {
    return this.payload.id;
  }

  get fullName(): string {
    return this.payload.fullName || '';
  }

  get imageUrl(): string {
    return '/scf/api/UserAvatar?userid=' + this.id;
  }

  get locale(): string {
    return 'en-gb';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get options(): Record<UserOptionKey, any> {
    return (this.payload as TODO).options;
  }

  get company(): string {
    assert(this.payload.company);
    return this.payload.company.name ?? '';
  }

  get email(): string {
    return this.payload.email || '';
  }

  get avatar(): string | null {
    return this.payload.avatar ?? null;
  }

  get workspace(): Reference {
    assert(this.payload.workspace);
    return this.payload.workspace;
  }

  get isOnboarded(): boolean | null {
    return this.payload.isOnboarded ?? null;
  }

  get onboardingSkippedAt(): string | null {
    return this.payload.onboardingSkippedAt ?? null;
  }

  isEqual(user: User): boolean {
    return this.id === user.id;
  }
}

export default User;
