import {NegotiationReplyTime} from '../../../../api/node-backend/generated';
import dayjs, {Dayjs} from 'dayjs';
import {setupDayjs} from '../../../../utils/setupDayjs';

setupDayjs();

const MINUTE_MS = 1000 * 60;
const DAY_MS = MINUTE_MS * 60 * 24;
const MONTH_MS = DAY_MS * 30;

export const formatReplyTimeDuration = (durationMs: number, clockStyle = true): string => {
  const formatString = clockStyle ? 'HH:mm:ss' : 'H[h] m[m]';
  if (Math.abs(durationMs) < MINUTE_MS) {
    return 'a few seconds';
  }
  if (Math.abs(durationMs) < DAY_MS) {
    return dayjs.duration(durationMs).format(formatString);
  }
  if (Math.abs(durationMs) < MONTH_MS) {
    // humanize truncates days at 30 days, so we need to do it manually
    return (
      dayjs.duration(durationMs, 'ms').asDays().toFixed(0) + 'd ' + dayjs.duration(durationMs).format(formatString)
    );
  }
  return dayjs.duration(durationMs, 'ms').humanize();
};

export const getTimeLeftMs = (expiry: Dayjs | Date): number => dayjs(expiry).diff(dayjs());

export const getIsReplyTimeSet = (replyTime?: NegotiationReplyTime | null): replyTime is NegotiationReplyTime => {
  return !!replyTime?.duration || !!replyTime?.timestamp;
};

export const getExpiryDateFromReplyTime = (replyTime: NegotiationReplyTime, committedAt: Date): Dayjs => {
  const {duration, timestamp} = replyTime;
  return timestamp ? dayjs(timestamp) : dayjs(committedAt).add(duration!, 'ms');
};

export const getIsReplyTimeExpired = (replyTime: NegotiationReplyTime, committedAt?: Date | null): boolean => {
  if (!committedAt) {
    return false;
  }
  const expiryDate = getExpiryDateFromReplyTime(replyTime, committedAt);
  return getIsExpired(expiryDate);
};

export const getIsExpired = (expiryDate: Dayjs | Date): boolean => {
  return dayjs().isAfter(expiryDate);
};
