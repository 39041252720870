import React, {FC, useState} from 'react';
import {TermEditorActions} from '../TermItem/Components/TermEditor/TermEditorActions';
import {SubjectEditorAutoComplete} from './SubjectEditorAutoComplete';
import {EditorContainer, EditorContent, EditorEditorContainer} from '../TermItem/Components/shared';

type SubjectEditorProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  currentText: string;
  disabled?: boolean;
  onSave: (termText: string) => void;
  onCancel: () => void;
};

export const SubjectEditor: FC<SubjectEditorProps> = ({containerRef, currentText, disabled, onSave, onCancel}) => {
  const [newText, setNewText] = useState(currentText);

  const finishEditing = () => {
    if (!newText || newText === currentText) {
      onCancel();
      return;
    }
    onSave(newText);
  };

  return (
    <EditorContainer data-testid="SubjectEditor" $disabled={disabled}>
      <EditorContent>
        <EditorEditorContainer>
          <SubjectEditorAutoComplete
            autoFocus
            disabled={disabled}
            value={newText}
            placeholder={'Edit subject'}
            getPopupContainer={() => containerRef.current || document.body}
            onSelect={setNewText}
            onSearch={setNewText}
            onFocus={event => event.currentTarget.querySelector('input')?.select()}
            onBlur={finishEditing}
            onKeyDown={event => {
              if (!event.shiftKey && event.key === 'Enter') {
                event.preventDefault();
                finishEditing();
              }
              if (event.key === 'Escape') {
                event.preventDefault();
                onCancel();
              }
            }}
          />
          <TermEditorActions onSave={finishEditing} onCancel={onCancel} />
        </EditorEditorContainer>
      </EditorContent>
    </EditorContainer>
  );
};
