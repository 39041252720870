/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CargoOfferListResponseData,
  CargoOfferListResponseDataFromJSON,
  CargoOfferListResponseDataFromJSONTyped,
  CargoOfferListResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface CargoOfferListResponse
 */
export interface CargoOfferListResponse {
  /**
   *
   * @type {CargoOfferListResponseData}
   * @memberof CargoOfferListResponse
   */
  data: CargoOfferListResponseData;
}

export function CargoOfferListResponseFromJSON(json: any): CargoOfferListResponse {
  return CargoOfferListResponseFromJSONTyped(json, false);
}

export function CargoOfferListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CargoOfferListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: CargoOfferListResponseDataFromJSON(json['data']),
  };
}

export function CargoOfferListResponseToJSON(value?: CargoOfferListResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: CargoOfferListResponseDataToJSON(value.data),
  };
}
