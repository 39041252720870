import {VoyagePoint} from '../../VoyageInformation/VoyageTypes';

export const calcPointLoadDisDuration = ({
  voayagePoint,
}: {
  voayagePoint: VoyagePoint;
}): {
  durationDischarging: number;
  durationLoad: number;
  durationLoadDis: number;
} => {
  const durationLoad = voayagePoint.loadingRate ? voayagePoint.load / voayagePoint.loadingRate : 0;

  const durationDischarging = voayagePoint.dischargingRate ? voayagePoint.dis / voayagePoint.dischargingRate : 0;
  const durationLoadDis = durationDischarging + durationLoad;

  return {durationLoad, durationDischarging, durationLoadDis};
};
