import {InterpolatedArrow} from '../OfferStatic/InterpolatedArrow';
import React, {FC} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated';
import {List} from 'antd';
import {getDayToDayTrend} from '../../utils/getDayToDayTrend';

export const OneTradingArea: FC<{
  vessel: {
    properties: {
      name: string;
      countOffers: number;
      offers: OfferBucket[];
    };
  };
}> = ({vessel}) => {
  return (
    <List.Item>
      <List.Item.Meta title={vessel.properties.name} />
      <div
        style={{
          paddingRight: 8,
        }}>
        {vessel.properties.countOffers} <small style={{color: 'var(--color-gray-2)'}}>Circulars</small>
      </div>
      <InterpolatedArrow size={16} trend={getDayToDayTrend(vessel.properties.offers)} />
    </List.Item>
  );
};
