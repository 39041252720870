import {PortfolioVessel, VesselGridLayoutMode} from '../../../../redux/Portfolio';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {FC, useMemo} from 'react';
import {PortfolioActions} from '../../../../redux/Portfolio';
import {VesselGridLayoutSelect} from '../../../../components/VesselGridLayoutSelect/VesselGridLayoutSelect';
import {selectVesselGridLayout} from '../../../../components/VesselGridLayoutSelect/selectVesselGridLayout';
import {useLocalStorage} from '../../../../utils/useLocalStorage';

interface Props {
  vessels: PortfolioVessel[] | null;
}

export const MyFleetGridLayoutSelect: FC<Props> = ({vessels}) => {
  const gridLayoutMode = useSelector(state => state.portfolio.vesselGridState.gridLayoutMode);
  const [, setLocalStorageValue] = useLocalStorage('portfolio_vessel_grid_layout', 'auto');

  const dispatch = useDispatch();
  const setGridLayoutMode = (gridLayoutMode: VesselGridLayoutMode) =>
    dispatch(PortfolioActions.setGridState({portfolioType: 'vessel', gridState: {gridLayoutMode}}));

  const vesselGridLayout = useMemo(
    () => selectVesselGridLayout({userSelectedLayoutMode: gridLayoutMode, vessels}),
    [gridLayoutMode, vessels]
  );

  const onChange = (mode: VesselGridLayoutMode) => {
    setLocalStorageValue(mode);
    setGridLayoutMode(mode);
  };

  return (
    <VesselGridLayoutSelect
      userSelectedLayoutMode={gridLayoutMode}
      displayedLayoutMode={vesselGridLayout}
      onChangeUserSelectedLayoutMode={onChange}
    />
  );
};
