import produce from 'immer';
import {VesselInput} from '../../VesselInformation/VesselTypes';
import {CargoInput} from '../CargoTypes';
import {VoyageInput, VoyagePoint} from '../../VoyageInformation/VoyageTypes';
import {isVesselNextOpenPortFirstPoint} from './isVesselNextOpenPortFirstPoint';
import {defaultVoyagePoint} from '../../VoyageInformation/Timeline/AddItem';

export const addNextOpenLegToVoyage = (input: {
  vessel?: VesselInput;
  cargo?: CargoInput;
  voyage: VoyageInput;
}): VoyageInput => {
  if (!input.vessel?.nextOpenPoint) {
    return input.voyage;
  }

  return produce(input.voyage, draftVoyage => {
    const nextOpenPoint = input.vessel!.nextOpenPoint;
    const firstPoint: VoyagePoint | undefined = input.voyage.points[0];

    if (!firstPoint && !nextOpenPoint) {
      draftVoyage.points = [];
      return draftVoyage;
    }

    if (!firstPoint) {
      draftVoyage.points = [
        {
          ...defaultVoyagePoint,
          ...nextOpenPoint,
          types: ['nextOpen'],
        },
      ];
      draftVoyage.routesCalculated = false;
      return draftVoyage;
    }

    // When the nextopen port is the same as the first port
    if (isVesselNextOpenPortFirstPoint(input.voyage, input.vessel!)) {
      if (firstPoint.types.includes('nextOpen')) {
        return draftVoyage;
      }
      firstPoint.types.push('nextOpen');

      return draftVoyage;
    }

    // If the first point is a nextopen but the port is different from the nextopen port of the vessel
    if (firstPoint.types?.includes('nextOpen')) {
      // push the nextopen point to the first position
      draftVoyage.points.splice(0, 1, nextOpenPoint!);

      draftVoyage.routesCalculated = false;
      return draftVoyage;
    }

    // If the first point is not a nextopen, add the nextopen port of the vessel to the first position
    draftVoyage.points.unshift(nextOpenPoint!);
    draftVoyage.routesCalculated = false;

    return draftVoyage;
  });
};
