import {useDispatch} from '../../../redux/react-redux';
import PortfolioService from '../../../redux/ApiService/PortfolioService';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError} from '../../../api/utils/ApiError';
import {TODO} from '../../../utils/TODO';

export const useAssignSubPortfoliosMutation = (
  {type, id}: {type: 'cargo' | 'vessel'; id: number},
  options?: UseMutationOptions<number[], ApiError, TODO>
) => {
  const dispatch = useDispatch();

  const assignToGroups = (subPortfolioIds: number[]) =>
    dispatch(PortfolioService.assignToGroups(type, id, subPortfolioIds));

  return useMutation({
    mutationFn: assignToGroups,
    ...options,
  });
};
