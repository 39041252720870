import dayjs from 'dayjs';
import LabelWrapper from '../../../atoms/LabelWrapper';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import {StyledRangePicker} from '../../../components/RangePicker/StyledRangePicker';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {dateEarlierThanOrEqualTo} from '../../../utils/validators';
import {LaycanSectionValues} from './LaycanSectionValues';

const Wrapper = BindToProvider(
  'Laycan',
  'laycan',
  {
    laycanFrom: null,
    laycanTo: null,
  },
  {
    laycanFrom: dateEarlierThanOrEqualTo('laycanTo'),
  },
  {
    toApi: ({laycanFrom, laycanTo}: LaycanSectionValues) => ({
      laycanFrom: laycanFrom && laycanFrom.format(DateTimeFormat.IsoDate),
      laycanTo: laycanTo && laycanTo.format(DateTimeFormat.IsoDate),
    }),
    fromApi: ({laycanFrom, laycanTo}: $TSFixMe): LaycanSectionValues => ({
      laycanFrom: laycanFrom ? dayjs(laycanFrom) : null,
      laycanTo: laycanTo ? dayjs(laycanTo) : null,
    }),
  },
  true
)(WrappedDetailsSection);

const Laycan = (props: $TSFixMe) => {
  return (
    <Wrapper {...props}>
      {(sectionProps: $TSFixMe) => {
        const {validations, onChangeRangePicker} = sectionProps;
        const values: LaycanSectionValues = sectionProps.values;
        return (
          <div className="container-fluid">
            <div className="row">
              <div className="scol-10">
                <LabelWrapper
                  label={'From/Laydays - To/Cancelling'}
                  htmlFor={'form-laycan-from'}
                  hasError={validations.laycanFrom.invalid}>
                  <StyledRangePicker
                    id={'form-laycan-from form-laycan-to'}
                    format={DateTimeFormat.Date}
                    value={[values.laycanFrom, values.laycanTo]}
                    getPopupContainer={() => {
                      return document.getElementById('fromLaydaysToCancelling')!;
                    }}
                    placeholder={['From/Laydays', 'To/Cancelling']}
                    onChange={value => {
                      const [from, to] = (value ?? [null, null]).values();
                      onChangeRangePicker({
                        laycanFrom: from,
                        laycanTo: to,
                      });
                    }}
                  />
                </LabelWrapper>
                <div id="fromLaydaysToCancelling" />
              </div>
            </div>
          </div>
        );
      }}
    </Wrapper>
  );
};

export default Laycan;
