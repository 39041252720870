import React, {PropsWithChildren} from 'react';

type Props = {
  className?: string;
};
export const HeaderRow = (props: PropsWithChildren<Props>) => {
  return (
    <thead className={props.className}>
      <tr>{props.children}</tr>
    </thead>
  );
};
