import {CharterCalculation} from '../../../../api/symfony/generated/models';
import {charterCalculationApi} from '../../../../api/symfony/symfonyApi';
import {message} from 'antd';
import {useContext} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {useCalcName} from './useCalcName';
import {useSelector} from '../../../../redux/react-redux';
import {RootState} from '../../../../redux/store';
import {queryKey} from '../../../../atoms/CharterCalculationList';
import {useQueryClient} from '@tanstack/react-query';

export const useSaveVoyageCalculation = ({onSuccess}: {onSuccess?: () => void}) => {
  const voyageContext = useContext(VoyageCharterContext);
  const workspace = useSelector((state: RootState) => state.user!.workspace);

  const queryClient = useQueryClient();

  const calcName = useCalcName();

  const prepareDataBeforeSave = (): CharterCalculation => {
    const charterCalculation: CharterCalculation = {
      name: calcName,
      input: voyageContext.state.inputState,
      result: voyageContext.state.outputState,
      workspace: workspace ?? null,
    };

    if (voyageContext.state.inputState.vessel.id !== undefined) {
      charterCalculation.vessel = {
        id: voyageContext.state.inputState.vessel.id,
      };
    }

    if (voyageContext.state.inputState.cargo.id !== undefined) {
      charterCalculation.cargoes = [
        {
          id: voyageContext.state.inputState.cargo.id,
        },
      ];
    }
    if (voyageContext.state.inputState.calculator.projectId !== undefined) {
      charterCalculation.project = {
        id: voyageContext.state.inputState.calculator.projectId,
      };
    }
    return charterCalculation;
  };

  const saveVoyageCalculation = async () => {
    const charterCalculation = prepareDataBeforeSave();
    const calculation = await charterCalculationApi.createCharterCalculation({
      charterCalculation,
    });

    voyageContext.setMeta({
      ...voyageContext.state.metaState,
      savedLastChanges: true,
      calculation: {...voyageContext.state.metaState.calculation, id: calculation.id as number},
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.success('Saved as new Calculation');
    await queryClient.invalidateQueries({
      queryKey: [queryKey],
    });
    onSuccess?.();
    return calculation;
  };

  const saveExistingVoyageCalculation = async (params: {id: number}) => {
    const charterCalculation = prepareDataBeforeSave();
    const calculation = await charterCalculationApi.editCharterCalculation({
      id: params.id,
      charterCalculation,
    });
    voyageContext.setMeta({...voyageContext.state.metaState, savedLastChanges: true});
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.success('Saved calculation');

    await queryClient.invalidateQueries({
      queryKey: [queryKey],
    });
    onSuccess?.();
    return calculation;
  };

  return {prepareDataBeforeSave, saveVoyageCalculation, saveExistingVoyageCalculation};
};
