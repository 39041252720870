import {CharterCalculationList} from '../../atoms/CharterCalculationList';
import {useSelector} from '../../redux/react-redux';
import {canDoVoyageCalc} from './canDoVoyageCalc';
import {ProjectDetailCard} from './ProjectDetailCard';
import {useNavigate} from 'react-router';

export const CharterCalculations = () => {
  const navigate = useNavigate();
  const project = useSelector(state => state.projects.project);

  if (!canDoVoyageCalc(project)) {
    return null;
  }

  return (
    <ProjectDetailCard noPadding header={'Charter calculations'}>
      <CharterCalculationList
        hideFields={['project', project.vessel ? 'vessel' : 'cargoes']}
        emptyMessage="You can add calculations within a shortlist item's action menu"
        filter={{
          projectId: project.id,
        }}
        onSelect={item => {
          navigate(`/chartercalculator/voyagecharter?id=${item.id}`);
        }}
      />
    </ProjectDetailCard>
  );
};
