import {FC, ReactNode, useState} from 'react';
import {ActionMenu, ItemTypeWithDataCy} from '../../../../components/ActionMenu/ActionMenu';
import Icon from '../../../../atoms/Icon';
import {MenuItem, MenuItemLink} from '../../../../atoms/Menu/MenuItem';
import {ModalActions} from '../../../../redux/Modal';
import {TODO} from '../../../../utils/TODO';
import {useDispatch} from '../../../../redux/react-redux';
import {showNotification} from '../../../../utils/notification';
import {EditCircularModal} from '../../EditCircular/EditCircularModal';
import {useDetachOfferMutation} from '../../utils/useDetachOfferMutation';
import {AddToMyFleetModal} from '../../../../components/Modal/ClonePortfolioEntry/AddToMyFleetModal';
import {ReportMailParsingProblemModal} from '../../ReportMailParsingProblemModal';
import {useUser} from '../../../../components/UserContext/useUser';
import {AddToProject} from '../../../../components/Modal/AddToProject/AddToProject';
import {MoveToWorkspace} from '../../../../components/Modal/MoveToWorkspace';

export const MarketVesselGridActionMenu: FC<{
  offer: TODO;
  isSub: boolean;
  onGridDataChange: () => void;
}> = ({offer, onGridDataChange, isSub}) => {
  const [showAddToMyFleetModal, setShowAddToMyFleetModal] = useState(false);
  const [showReportMailParsingProblemModal, setShowReportMailParsingProblemModal] = useState(false);

  const user = useUser();
  const dispatch = useDispatch();

  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const [showSmartInBoxModal, setShowSmartInBoxModal] = useState<boolean>(false);
  const detachOfferMutation = useDetachOfferMutation({
    onSuccess: () => {
      onGridDataChange();
    },
  });
  const isShared = offer.shared;

  return (
    <>
      {showSmartInBoxModal && (
        <EditCircularModal
          onSuccess={() => {
            showNotification('Circular was updated.');
            onGridDataChange();
            setShowSmartInBoxModal(false);
          }}
          offer={offer}
          onCancel={() => setShowSmartInBoxModal(false)}
        />
      )}
      {showReportMailParsingProblemModal && (
        <ReportMailParsingProblemModal offer={offer} onCancel={() => setShowReportMailParsingProblemModal(false)} />
      )}
      {showAddToMyFleetModal && (
        <AddToMyFleetModal
          onCancel={() => setShowAddToMyFleetModal(false)}
          vessel={offer.vessel}
          onSuccess={() => {}}
        />
      )}
      <ActionMenu
        actionIcon={<Icon type={'more-vert'} />}
        items={
          [
            {
              key: 'view',
              label: (
                <MenuItemLink label="View vessel" href={`/vessel/${offer.vessel.id}`} dataCy="MenuItemViewVessel" />
              ),
            },
            !isShared && {
              key: 'edit',
              label: (
                <MenuItem
                  label="Edit circular"
                  onClick={() => {
                    setShowSmartInBoxModal(true);
                  }}
                  dataCy="MenuItemEditOffer"
                />
              ),
            },
            {
              key: 'add to project',
              label: (
                <MenuItem
                  label="Add to project"
                  onClick={() => {
                    //  reload={onGridDataChange}
                    showModal(<AddToProject type="vessel" id={offer.vessel.id} />);
                  }}
                  dataCy="MenuItemAddToProject"
                />
              ),
            },
            {
              key: 'reportParsingProblem',
              isValid: offer.isParserOffer,
              label: (
                <MenuItem
                  label="Report a parsing problem"
                  onClick={() => {
                    setShowReportMailParsingProblemModal(true);
                  }}
                  dataCy="MenuItemReportParsingProblem"
                />
              ),
            },
            {
              key: 'add to my fleet',
              label: (
                <MenuItem
                  label="Add to My Fleet"
                  onClick={() => {
                    setShowAddToMyFleetModal(true);
                  }}
                  dataCy="MenuItemAddToPortfolio"
                />
              ),
            },
            {
              key: 'move to workspace',
              isValid: !!user?.payload?.company?.id,
              label: (
                <MenuItem
                  label="Move to workspace"
                  onClick={() => {
                    showModal(<MoveToWorkspace type="marketVessel" data={offer} reload={onGridDataChange} />);
                  }}
                  dataCy="MenuItemMoveToWorkspace"
                />
              ),
            },
            isSub && {
              key: 'Mark as non duplicate',
              label: (
                <MenuItem
                  label="Mark as non duplicate"
                  onClick={() => {
                    detachOfferMutation.mutate({id: offer.id, type: 'vessel'});
                  }}
                  dataCy="MenuItemMarkAsNonDuplicate"
                />
              ),
            },
          ].filter(Boolean) as ItemTypeWithDataCy[]
        }
      />
    </>
  );
};
