/* eslint-disable camelcase */
export const iconMapping = {
  empty_bulker: {
    x: 228,
    y: 110,
    width: 45,
    height: 135,
  },
  empty_container: {
    x: 382,
    y: 110,
    width: 62,
    height: 135,
  },
  empty_tanker: {
    x: 556,
    y: 102,
    width: 50,
    height: 135,
  },
  empty_mpp: {
    x: 723,
    y: 102,
    width: 56,
    height: 135,
  },
  empty_other: {
    x: 901,
    y: 102,
    width: 69,
    height: 135,
  },

  simple_bulker: {
    x: 228,
    y: 518,
    width: 45,
    height: 135,
  },
  simple_container: {
    x: 382,
    y: 518,
    width: 62,
    height: 135,
  },
  simple_tanker: {
    x: 556,
    y: 518,
    width: 50,
    height: 135,
  },
  simple_mpp: {
    x: 723,
    y: 518,
    width: 56,
    height: 135,
  },
  simple_other: {
    x: 901,
    y: 518,
    width: 69,
    height: 135,
  },

  filled_bulker: {
    x: 228,
    y: 927,
    width: 45,
    height: 135,
  },
  filled_container: {
    x: 382,
    y: 927,
    width: 62,
    height: 135,
  },
  filled_tanker: {
    x: 556,
    y: 927,
    width: 50,
    height: 135,
  },
  filled_mpp: {
    x: 723,
    y: 927,
    width: 56,
    height: 135,
  },
  filled_other: {
    x: 901,
    y: 927,
    width: 69,
    height: 135,
  },

  detailed_bulker: {
    x: 228,
    y: 1356,
    width: 45,
    height: 135,
  },
  detailed_container: {
    x: 382,
    y: 1356,
    width: 62,
    height: 135,
  },
  detailed_tanker: {
    x: 556,
    y: 1356,
    width: 50,
    height: 135,
  },
  detailed_mpp: {
    x: 723,
    y: 1356,
    width: 56,
    height: 135,
  },
  detailed_other: {
    x: 901,
    y: 1356,
    width: 69,
    height: 135,
  },
};
