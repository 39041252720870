import {FC, ReactNode, RefObject, useRef} from 'react';
import {Col, Row, Space} from 'antd';
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver';
import {ModalFooter} from '../../../../../antd/components/ModalFooter';

export const EditCircularFormLayout: FC<{
  mailView: ReactNode;
  formItems: ReactNode;
  actionButtons: ReactNode;
}> = ({mailView, formItems, actionButtons}) => {
  const formItemsRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const formItemsDomRect = useResizeObserver(formItemsRef);
  const formItemsHeight = formItemsDomRect?.height ?? 26;
  const mailViewHeight = formItemsHeight - 26;
  return (
    <>
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <div className="MailViewContainer" style={{height: mailViewHeight}}>
            {mailView}
          </div>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={32}>
            <div className="FormItemsContainer" ref={formItemsRef}>
              {formItems}
            </div>
          </Space>
        </Col>
      </Row>
      <ModalFooter>{actionButtons}</ModalFooter>
    </>
  );
};
