import {CostInput} from '../../CostInformation/CostTypes';
import {ConsumptionFuelType, getUsedFuelType} from '../../utils/getUsedFuelType';
import {calculateConsumptionTakingIntoAccountFuelSubstitution} from '../../utils/calculateConsumptionTakingIntoAccountFuelSubstitution';
import {getBunkerPriceByFuelType} from '../../utils/getBunkerPriceByFuelType';
import {ConsumptionType, VoyagePoint} from '../VoyageTypes';
import {calculateCO2Emission} from '../../utils/calculateCO2Emission';

export type ConsumptionResult = {
  fuelType: ConsumptionFuelType;
  dailyConsumption: number;
  duration: number;
  inSeca: boolean;
  cO2Emission: number;
  consumption: number;
  pricePerMts: number;
  costs: number;
};

export const getConsumptionForPoint = ({
  point,
  duration,
  type,
  isScrubberFitted,
  costInput,
}: {
  point: VoyagePoint;
  isScrubberFitted: boolean;
  duration: number;
  type: ConsumptionType;
  costInput: CostInput;
}): ConsumptionResult => {
  const fuelType = (type === 'main' ? point.consumption?.mainFuelType : point.consumption?.auxFuelType) ?? 'ifo';
  const consumptionFuel = getUsedFuelType({
    isSECA: point.isInSECA,
    fuelType,
    isScrubberFitted: isScrubberFitted,
  });

  const fuelConsumption =
    (type === 'main' ? point.consumption?.mainConsumption : point.consumption?.auxConsumption) ?? 0;

  const dailyConsumption = calculateConsumptionTakingIntoAccountFuelSubstitution({
    orginalConsumption: fuelConsumption,
    orginalFuelType: fuelType,
    substitutionFuelType: consumptionFuel,
  });

  const consumption = dailyConsumption * duration;

  const pricePerMts = getBunkerPriceByFuelType({costInput: costInput, fuelType: consumptionFuel});
  return {
    fuelType: consumptionFuel,
    dailyConsumption: dailyConsumption,
    duration: duration,
    inSeca: point.isInSECA,
    consumption: consumption,
    pricePerMts: pricePerMts,
    costs: pricePerMts * consumption,
    cO2Emission: calculateCO2Emission({consumption, fuelType: consumptionFuel}),
  };
};
