import {FC, Fragment, ReactElement, useState} from 'react';
import cx from 'classnames';
import {LayerSelect} from './LayerSelect/LayerSelect';
import {MapOptionChangeValue, MapSwitches, MapSwitchesKey} from '../MapDetails/utils/types';
import {MapControlPortButton} from './MapControlPortButton';
import {MapOption, MapOptionSection} from '../mapOptions/mapOptions';
import {MapControlVesselButton} from './MapControlVesselButton';
import {MapControlCargoButton} from './MapControlCargoButton';
import {useMapContext} from '../MapContext/MapContext';
import {MapContextValue, MapControlButton, MapControlMenuSectionsToHide} from '../MapContext/Types';
import Tooltip from '../../../atoms/Tooltip';
import './map-ctrl.scss';

type MapCtrlProps = {
  switches: MapSwitches;
  mapOptions: MapOption[];
  otherLayersDropDown?: ReactElement;
  mapMenuZIndex?: number;
  mapHeight: number;
  moveDown?: boolean;
  onChange: (switchId: MapSwitchesKey, value: MapOptionChangeValue) => void;
};

export const MapCtrl: FC<MapCtrlProps> = ({
  switches,
  mapOptions,
  otherLayersDropDown,
  mapMenuZIndex,
  mapHeight,
  moveDown,
  onChange,
}) => {
  const [openId, setOpenId] = useState<string | null>(null);

  const mapContext = useMapContext();

  const showVesselButton = isMapControlButtonVisible(mapContext, MapControlButton.VESSELS);
  const showCargoButton = isMapControlButtonVisible(mapContext, MapControlButton.CARGOES);
  const showPortButton = isMapControlButtonVisible(mapContext, MapControlButton.PORTS);
  const showLayersButton = isMapControlButtonVisible(mapContext, MapControlButton.LAYERS);

  const filteredMapOptions = getFilteredMapOptions(mapOptions, mapContext.props.mapControlMenuSectionsToHide);

  return (
    <Fragment>
      <div className={cx('map-ctrl', {'map-ctrl--move-down': moveDown})}>
        {showVesselButton && (
          <div className="map-ctrl__btn-group">
            <Tooltip trigger={'mouseenter'} title="Vessels">
              <MapControlVesselButton
                switches={switches}
                mapOption={filteredMapOptions.find(o => o.id === MapControlButton.VESSELS)!}
                isOpen={openId === MapControlButton.VESSELS}
                mapHeight={mapHeight}
                onChange={onChange}
                onToggleOpen={() => {
                  setOpenId(openId === MapControlButton.VESSELS ? null : MapControlButton.VESSELS);
                }}
              />
            </Tooltip>
          </div>
        )}
        {showCargoButton && (
          <div className="map-ctrl__btn-group">
            <Tooltip trigger={'mouseenter'} title="Cargoes">
              <MapControlCargoButton
                switches={switches}
                mapOption={filteredMapOptions.find(o => o.id === MapControlButton.CARGOES)!}
                isOpen={openId === MapControlButton.CARGOES}
                mapHeight={mapHeight}
                onChange={onChange}
                onToggleOpen={() => {
                  setOpenId(openId === MapControlButton.CARGOES ? null : MapControlButton.CARGOES);
                }}
              />
            </Tooltip>
          </div>
        )}
        {showPortButton && (
          <div className="map-ctrl__btn-group">
            <Tooltip trigger={'mouseenter'} title="Ports">
              <MapControlPortButton />
            </Tooltip>
          </div>
        )}
        {showLayersButton && (
          <Tooltip trigger={'mouseenter'} title="Map layers">
            <LayerSelect
              mapHeight={mapHeight}
              switches={switches}
              zIndex={mapMenuZIndex}
              otherLayersDropDown={otherLayersDropDown}
              isOpen={openId === MapControlButton.LAYERS}
              onChange={onChange}
              onOpen={() => {
                setOpenId(openId === MapControlButton.LAYERS ? null : MapControlButton.LAYERS);
              }}
            />
          </Tooltip>
        )}
      </div>
    </Fragment>
  );
};

const isMapControlButtonVisible = (mapContext: MapContextValue, key: MapControlButton) => {
  const mapControlButtonsToHide = mapContext.props.mapControlButtonsToHide ?? [];
  return !mapControlButtonsToHide.includes(key);
};

const getFilteredMapOptions = (
  mapOptions: MapOption[],
  mapControlMenuSectionsToHide?: MapControlMenuSectionsToHide[]
) => {
  if (!mapControlMenuSectionsToHide?.length) {
    return mapOptions;
  }

  const newMapOptions = mapOptions.map(option => {
    if (!option.sections) {
      return option;
    }

    return {
      ...option,
      sections: getFilteredSections(option.sections, mapControlMenuSectionsToHide),
    };
  });

  return newMapOptions;
};

const getFilteredSections = (
  sections: MapOptionSection[],
  mapControlMenuSectionsToHide: MapControlMenuSectionsToHide[]
) => {
  return sections
    .map(section => {
      return getFilteredSection(section, mapControlMenuSectionsToHide);
    })
    .filter(section => section.length > 0);
};

const getFilteredSection = (
  section: MapOptionSection,
  mapControlMenuSectionsToHide: MapControlMenuSectionsToHide[]
) => {
  return section.filter(sectionItem => {
    const isToHide = mapControlMenuSectionsToHide?.includes(sectionItem.id as MapControlMenuSectionsToHide);
    return !isToHide;
  });
};
