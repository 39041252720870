import React, {useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {Radio, Form, Switch, Slider, Space} from 'antd';
import {VESSEL_ICON_STYLES} from '../const';

type Props = {
  className?: string;
};

export const VesselIcons = (props: Props) => {
  const mapContext = useContext(MapContext);

  const layout = {
    labelCol: {span: 12},
    wrapperCol: {span: 12},
  };
  const options = VESSEL_ICON_STYLES as Array<{value: string; label: string}>;

  return (
    <Form className={props.className} layout="horizontal" {...layout} name="control-hooks">
      <Form.Item label="Icon style">
        <Radio.Group
          onChange={v => {
            mapContext.setSetting('vesselIconStyle', v.target.value);
          }}
          value={mapContext.state.settings.vesselIconStyle}
          style={{marginTop: 6, boxShadow: 'none'}}>
          <Space direction="vertical">
            {options.map(item => {
              return (
                <Radio key={item.value} style={{}} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Size relative to capacity">
        <Switch
          checked={mapContext.state.settings.vesselIconSizeCapacityRelation}
          onChange={v => {
            mapContext.setSetting('vesselIconSizeCapacityRelation', v);
          }}
        />
      </Form.Item>

      <Form.Item label="Size multiplicator">
        <Slider
          range={false}
          onChange={v => {
            mapContext.setSetting('vesselIconSizeMultiplicator', v);
          }}
          min={1}
          max={10}
          step={0.2}
          value={mapContext.state.settings.vesselIconSizeMultiplicator}
        />
      </Form.Item>
    </Form>
  );
};
