/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  JSONAllMultiData,
  JSONAllMultiDataFromJSON,
  JSONAllMultiDataToJSON,
  Query,
  QueryFromJSON,
  QueryToJSON,
} from '../models';

export interface ParseRequest {
  query?: Query;
}

/**
 *
 */
export class ParserApi extends runtime.BaseAPI {
  /**
   * Parse mail
   */
  async parseRaw(requestParameters: ParseRequest): Promise<runtime.ApiResponse<JSONAllMultiData>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/parser`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: QueryToJSON(requestParameters.query),
    });

    return new runtime.JSONApiResponse(response, jsonValue => JSONAllMultiDataFromJSON(jsonValue));
  }

  /**
   * Parse mail
   */
  async parse(requestParameters: ParseRequest): Promise<JSONAllMultiData> {
    const response = await this.parseRaw(requestParameters);
    return await response.value();
  }
}
