import React, {FC, useState} from 'react';
import {PulsatingGridClipboardLayer} from './PulsatingGridClipboardLayer';
import {useExtendPortsAndTradingAreasWithCirculars} from './useExtendPortsAndTradingAreasWithCirculars';
import {useGetCircularsLayerForCurrentZoomLevel} from './useGetCircularsLayerForCurrentZoomLevel';
import {WebMercatorViewport} from '@deck.gl/core/typed';
import {
  isMapAreaElement,
  MapAreaElement,
  MapElement,
  MapSwitches,
} from '../../../components/SeaboMap/MapDetails/utils/types';
import {SeaboMap} from '../../../components/SeaboMap/SeaboMap';
import * as GeoJSON from 'geojson';
import {useMapApi} from '../../../components/SeaboMap/useMapApi';
import {useMatchClipboardWithAisVessels} from './useMatchClipboardWithAisVessels';
import {ClipboardVesselsCantMatch} from './ClipboardVesselsCantMatch';
import {useThrottle} from '../../../utils/useThrottle';
import {LAYERS} from '../../../components/SeaboMap/const';
import {SelectedTradingAreaBanner} from './SelectedTradingAreaBanner';
import {useSetMapDetailElement} from '../../../components/SeaboMap/Hooks/useSetMapDetailElement';
import {useSelector} from '../../../redux/react-redux';
import {TA_LABEL_ZOOM_LEVEL} from '../../../components/SeaboMap/layers/PortLayer';
import {assert} from '../../../utils/assert';
import {ViewState} from '../../../components/SeaboMap/ViewState';
import {makePartialMapSwitches} from '../../../components/SeaboMap/utils/makePartialMapSwitches';

export const MarketMap: FC = () => {
  const matchClipboardWithAisVessels = useMatchClipboardWithAisVessels();
  const points = matchClipboardWithAisVessels.points;

  const [currentZoom, setCurrentZoom] = useState<number>(0);
  const [boundingBox, setBoundingBox] = useState<GeoJSON.BBox>([-180, -85, 180, 85]);
  const [mapSwitches, setMapSwitches] = useState<MapSwitches | undefined>(undefined);
  const [defaultOpenPort, setDefaultOpenPort] = useState<MapElement | null>(null);
  const [selectedTradingAreaMapElement, setSelectedTradingAreaMapElement] = useState<MapAreaElement | null>(null);

  const {mapElement} = useSelector(({mapDetails}) => mapDetails);

  const isShowMarketVessels = mapSwitches?.vessel_market.state ?? true;
  const isShowMarketCargoes = mapSwitches?.cargo_market.state ?? true;

  const {ports, tradingAreas} = useExtendPortsAndTradingAreasWithCirculars({
    isShowMarketVessels,
    isShowMarketCargoes,
  });

  const circularsLayer = useGetCircularsLayerForCurrentZoomLevel({
    currentZoom,
    boundingBox,
    ports,
    tradingAreas,
    isShowMarketVessels,
    isShowMarketCargoes,
    onClickPortOrPortCluster: portOrPortCluster => {
      if (portOrPortCluster.object.cluster) {
        return;
      }
      setDefaultOpenPort({...portOrPortCluster, layer: {id: 'PORT'}});
    },
  });

  const onChangeViewState = (viewState: ViewState) => {
    setCurrentZoom(viewState.zoom);
    const viewport = new WebMercatorViewport(viewState);
    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    setBoundingBox([nw[0], se[1], se[0], nw[1]]);
  };

  const onChangeViewStateDebounced = useThrottle(onChangeViewState, [], 250, {leading: true, trailing: true});

  const mapApi = useMapApi();
  const setMapDetailElement = useSetMapDetailElement();
  return (
    <SeaboMap
      mapApi={mapApi}
      showSearch
      showLegend
      mapControlMenuSectionsToHide={['vessel_portfolio', 'cargo_portfolio', 'cargo_commodities']}
      showTradingAreaPopup
      onSelectElement={element => {
        if (element?.layer.id === LAYERS.TRADING_AREA) {
          assert(isMapAreaElement(element));
          if (selectedTradingAreaMapElement) {
            if (selectedTradingAreaMapElement.object.properties.id === element.object.properties.id) {
              setSelectedTradingAreaMapElement(null);
              return;
            }
            setSelectedTradingAreaMapElement(element);
            if (mapElement) {
              setMapDetailElement(element);
            }
            return;
          }
          setSelectedTradingAreaMapElement(element);
        }
      }}
      showPopups
      canFullScreen
      initialViewState={{zoom: 1}}
      defaults="market"
      settingIdentifier="market"
      defaultSelectedElement={defaultOpenPort}
      layers={[circularsLayer]}
      componentOnTop={
        <>
          <ClipboardVesselsCantMatch
            key={'clipbardVessels'}
            notMatchedVessels={matchClipboardWithAisVessels.notMatchedVessels}
          />
          {selectedTradingAreaMapElement && TA_LABEL_ZOOM_LEVEL > currentZoom && (
            <SelectedTradingAreaBanner
              onClick={() => {
                setMapDetailElement(selectedTradingAreaMapElement as MapElement);
              }}
              key={'selectedTradingAreaBanner'}
              mapElement={selectedTradingAreaMapElement}
              isReduced={!!mapElement}
            />
          )}
        </>
      }
      initialMapSettings={{
        switches: {
          ...makePartialMapSwitches({
            vesselMyFleet: false,
            vesselMarket: true,
            vesselAll: true,
            cargoCommodities: false,
            cargoMyCargo: false,
            cargoMarket: true,
            ports: false,
            tradingAreaNames: true,
            tradingArea: true,
            highRiskAreas: false,
            emissionControlAreas: false,
            cargoAll: true,
          }),

          // eslint-disable-next-line camelcase
          vessel_ais_sub: {
            vDisabled: false,
            state: true,
            vState: true,
            bulker: true,
            tanker: false,
            container: false,
            other: false,
            mpp: false,
          },
        },
      }}
      layersOnTopOfMapBox={[<PulsatingGridClipboardLayer mapApi={mapApi} key={1} points={points} />]}
      onViewStateChange={onChangeViewStateDebounced}
      onChangeSwitches={switches => setMapSwitches(switches)}
    />
  );
};
