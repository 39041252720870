import React, {FC} from 'react';
import {Button} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

export const RemoveAvatarButton: FC<{disabled: boolean; removing: boolean; onRemove: () => void}> = ({
  disabled,
  onRemove,
  removing,
}) => {
  return (
    <Button data-testid="RemoveAvatarButton" disabled={disabled} loading={removing} onClick={onRemove}>
      <DeleteOutlined /> Remove
    </Button>
  );
};
