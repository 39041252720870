/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NewsDocument,
  NewsDocumentFromJSON,
  NewsDocumentFromJSONTyped,
  NewsDocumentToJSON,
  NewsImage,
  NewsImageFromJSON,
  NewsImageFromJSONTyped,
  NewsImageToJSON,
} from './';

/**
 *
 * @export
 * @interface NewsItemRead
 */
export interface NewsItemRead {
  /**
   *
   * @type {number}
   * @memberof NewsItemRead
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof NewsItemRead
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof NewsItemRead
   */
  description: string;
  /**
   *
   * @type {Date}
   * @memberof NewsItemRead
   */
  publishedAt: Date | null;
  /**
   *
   * @type {NewsImage}
   * @memberof NewsItemRead
   */
  newsImage?: NewsImage | null;
  /**
   *
   * @type {NewsDocument}
   * @memberof NewsItemRead
   */
  newsDocument?: NewsDocument | null;
}

export function NewsItemReadFromJSON(json: any): NewsItemRead {
  return NewsItemReadFromJSONTyped(json, false);
}

export function NewsItemReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItemRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    title: json['title'],
    description: json['description'],
    publishedAt: json['publishedAt'] === null ? null : new Date(json['publishedAt']),
    newsImage: !exists(json, 'newsImage') ? undefined : NewsImageFromJSON(json['newsImage']),
    newsDocument: !exists(json, 'newsDocument') ? undefined : NewsDocumentFromJSON(json['newsDocument']),
  };
}

export function NewsItemReadToJSON(value?: NewsItemRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    description: value.description,
    publishedAt: value.publishedAt === null ? null : value.publishedAt.toISOString(),
    newsImage: NewsImageToJSON(value.newsImage),
    newsDocument: NewsDocumentToJSON(value.newsDocument),
  };
}
