/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UserWidget
 */
export interface UserWidget {
  /**
   *
   * @type {number}
   * @memberof UserWidget
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserWidget
   */
  identifier: string;
  /**
   *
   * @type {object}
   * @memberof UserWidget
   */
  settings: object;
  /**
   *
   * @type {number}
   * @memberof UserWidget
   */
  posX: number;
  /**
   *
   * @type {number}
   * @memberof UserWidget
   */
  posY: number;
  /**
   *
   * @type {number}
   * @memberof UserWidget
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof UserWidget
   */
  height: number;
}

export function UserWidgetFromJSON(json: any): UserWidget {
  return UserWidgetFromJSONTyped(json, false);
}

export function UserWidgetFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWidget {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    identifier: json['identifier'],
    settings: json['settings'],
    posX: json['posX'],
    posY: json['posY'],
    width: json['width'],
    height: json['height'],
  };
}

export function UserWidgetToJSON(value?: UserWidget | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    identifier: value.identifier,
    settings: value.settings,
    posX: value.posX,
    posY: value.posY,
    width: value.width,
    height: value.height,
  };
}
