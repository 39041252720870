/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Port, PortFromJSON, PortFromJSONTyped, PortToJSON} from './';

/**
 *
 * @export
 * @interface PortList
 */
export interface PortList {
  /**
   *
   * @type {any}
   * @memberof PortList
   */
  data: any | null;
  /**
   *
   * @type {Port}
   * @memberof PortList
   */
  type?: Port;
}

export function PortListFromJSON(json: any): PortList {
  return PortListFromJSONTyped(json, false);
}

export function PortListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: json['data'],
    type: !exists(json, 'type') ? undefined : PortFromJSON(json['type']),
  };
}

export function PortListToJSON(value?: PortList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data,
    type: PortToJSON(value.type),
  };
}
