import {FC, useContext} from 'react';
import {VoyageCharterContext, VoyageCharterContextProvider} from './Context/VoyageCharterContext';
import {PageHeaderBox} from './PageHeader/PageHeaderBox';
import {useCalcRoutesQuery} from './utils/useCalcRoutesQuery';
import {usePortWorkingConsumptionFromVessel} from './utils/usePortWorkingConsumptionFromVessel';
import {WidgetsContainer} from './WidgetsContainer/WidgetsContainer';
import {useCheckIfVesselExist} from './Context/utils/useCheckIfVesselExist';
import {useCheckIfCargoExist} from './Context/utils/useCheckIfCargoExist';
import {AssistantModal} from './AssistantModal/AssistantModal';
import {ScreenHeader} from '../../ScreenHeader/ScreenHeader';
import {useInitialLoad} from './utils/useInitialLoad';
import {LoadingModal} from '../../LoadingModal/LoadingModal';
import {VoyageCharterTourDefinition} from './VoyageCharterTourDefinition';

interface Props {
  id?: number;
  projectId?: number;
  vesselId?: number;
  cargoesIds?: number[];
}

const Comp: FC<{id?: number}> = ({id}) => {
  const isLoaded = useInitialLoad();
  const loadedExistingCalculationComplete = useLoadedExistingCalculationComplete();
  const calcRoutesQuery = useCalcRoutesQuery();
  usePortWorkingConsumptionFromVessel();
  useCheckIfVesselExist();
  useCheckIfCargoExist();

  return (
    <div data-cy={'VoyageCharter'}>
      <LoadingModal isLoading={!isLoaded} />
      {loadedExistingCalculationComplete && <AssistantModal />}
      <ScreenHeader
        helmetTitle="Voyage Calculation"
        breadcrumbs={[{title: 'Tools'}, {title: 'Voyage Calculation'}]}
        tourDefinition={VoyageCharterTourDefinition}
      />
      <PageHeaderBox id={id} />
      <WidgetsContainer isLoadingRoutes={calcRoutesQuery.isFetching} />
    </div>
  );
};

export const VoyageCharter = (props: Props) => {
  return (
    <VoyageCharterContextProvider props={{...props}}>
      <Comp id={props.id} />
    </VoyageCharterContextProvider>
  );
};

const useLoadedExistingCalculationComplete = (): boolean => {
  const voyageContext = useContext(VoyageCharterContext);

  if (voyageContext.props.id === undefined) {
    return true;
  }
  return !!voyageContext.state.metaState.calculation.id;
};
