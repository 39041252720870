import {ComponentType, FC} from 'react';
import {NotFound} from '../../atoms/EmptyState/404';
import {FeaturePermission} from '../../api/symfony/generated';
import {useUser} from '../UserContext/useUser';

interface Props {
  component: ComponentType;
  permissions: FeaturePermission[];
  path?: string;
}

export const ProtectedRoute: FC<Props> = ({component: Component, permissions}) => {
  const user = useUser();
  const userPermissions = user.payload.permissions ?? [];
  const isPermitted = permissions.some(permission => userPermissions.includes(permission));
  const Element = isPermitted ? Component : NotFound;
  return <Element />;
};
