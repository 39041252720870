import styled from 'styled-components';
import {NegotiationColorSet, mapNegotiationColorSet} from '../../../utils/colors';
import {SubButton} from './Components/TermSubjects/AddTermSubButton';

export const TermStyleProvider = styled.div<{
  $colors?: NegotiationColorSet;
}>`
  ${({$colors}) =>
    Object.entries($colors ?? mapNegotiationColorSet('partner'))
      .map(([key, value]) => `--term-color-${key}: var(--${value});`)
      .join('\n')}

  margin-right: -20px;
  padding-right: 20px;

  &:hover ${SubButton} {
    display: flex;
  }
`;
