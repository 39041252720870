/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   *
   * @type {boolean}
   * @memberof LoginResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  redirectUrl?: string | null;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
  return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: !exists(json, 'success') ? undefined : json['success'],
    redirectUrl: !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
  };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    redirectUrl: value.redirectUrl,
  };
}
