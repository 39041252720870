import {
  NegotiationRoundResult,
  NegotiationRoundResultSummary,
  NegotiationSubjectStatus,
  NegotiationTermStatus,
} from '../../../api/node-backend/generated';

/**
 * Creates NegotiationRoundResultSummary from a NegotiationRoundResult by reducing term counts.
 */
export const getSummaryFromRoundResult = (roundResult: NegotiationRoundResult): NegotiationRoundResultSummary => {
  const termCounts = {
    ongoingTerms: 0,
    acceptedTerms: 0,
    rejectedTerms: 0,
    rejectionRequestedTerms: 0,
  };

  roundResult.terms.forEach(term => {
    switch (term.status) {
      case NegotiationTermStatus.Ongoing:
        termCounts.ongoingTerms++;
        break;
      case NegotiationTermStatus.RejectionRequested:
        termCounts.rejectionRequestedTerms++;
        break;
      case NegotiationTermStatus.Accepted:
        termCounts.acceptedTerms++;
        break;
      case NegotiationTermStatus.Rejected:
        termCounts.rejectedTerms++;
        break;
    }
  });

  const subjects = [...roundResult.subjects];

  roundResult.terms.forEach(term => {
    subjects.push(...term.subjects);
  });

  // terms and committedBy are not included in the summary
  const roundResultSummary: NegotiationRoundResultSummary = {
    id: roundResult.id,
    committedAt: roundResult.committedAt,
    party: roundResult.party,
    ongoingTerms: termCounts.ongoingTerms,
    termsWithRequestedRejection: termCounts.rejectionRequestedTerms,
    acceptedTerms: termCounts.acceptedTerms,
    rejectedTerms: termCounts.rejectedTerms,
    acceptedSubjects: subjects.filter(subject => subject.status === NegotiationSubjectStatus.Accepted).length,
    subjectsWithRequestedRejection: subjects.filter(
      subject => subject.status === NegotiationSubjectStatus.RejectionRequested
    ).length,
    failedSubjects: subjects.filter(subject => subject.status === NegotiationSubjectStatus.Failed).length,
    liftedSubjects: subjects.filter(subject => subject.status === NegotiationSubjectStatus.Lifted).length,
    ongoingSubjects: subjects.filter(subject => subject.status === NegotiationSubjectStatus.Ongoing).length,
    rejectedSubjects: subjects.filter(subject => subject.status === NegotiationSubjectStatus.Rejected).length,
    replyTime: roundResult.replyTime,
    negotiationStatus: roundResult.negotiationStatus,
  };

  return roundResultSummary;
};
