/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {BulkerPort, BulkerPortFromJSON, BulkerPortFromJSONTyped, BulkerPortToJSON} from './';

/**
 *
 * @export
 * @interface MostVisitedBulkerPortsReport
 */
export interface MostVisitedBulkerPortsReport {
  /**
   * List of most visited bulker ports
   * @type {Array<BulkerPort>}
   * @memberof MostVisitedBulkerPortsReport
   */
  bulkerPorts: Array<BulkerPort>;
  /**
   * The report does not include non-bulkers. This is the number of non-bulker vessels that matched the given filter.
   * @type {number}
   * @memberof MostVisitedBulkerPortsReport
   */
  nonBulkers: number;
  /**
   * Number of bulkers, these have influenced the report.
   * @type {number}
   * @memberof MostVisitedBulkerPortsReport
   */
  bulkers: number;
}

export function MostVisitedBulkerPortsReportFromJSON(json: any): MostVisitedBulkerPortsReport {
  return MostVisitedBulkerPortsReportFromJSONTyped(json, false);
}

export function MostVisitedBulkerPortsReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MostVisitedBulkerPortsReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bulkerPorts: (json['bulkerPorts'] as Array<any>).map(BulkerPortFromJSON),
    nonBulkers: json['nonBulkers'],
    bulkers: json['bulkers'],
  };
}

export function MostVisitedBulkerPortsReportToJSON(value?: MostVisitedBulkerPortsReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bulkerPorts: (value.bulkerPorts as Array<any>).map(BulkerPortToJSON),
    nonBulkers: value.nonBulkers,
    bulkers: value.bulkers,
  };
}
