import animateScrollTo from 'animated-scroll-to';
import React, {useEffect, useState} from 'react';
import {batch, connect} from 'react-redux';
import {createSelector} from 'reselect';
import {NotFound} from '../../../atoms/EmptyState/404';
import {LoadingIndicator} from '../../../atoms/Loading';
import FormProvider from '../../../components/FormProvider';
import {actions as cargoApi} from '../../../redux/ApiService/cargoService';
import {showNotification} from '../../../utils/notification';
import {RouteParams} from '../RouteParams';
import {useNavigate, useParams} from 'react-router-dom';
import '../style.scss';
import {CargoFormBody} from './CargoFormBody';
import {steps} from './steps';
import {RootState} from '../../../redux/store';
import {parseIntTS} from '../../../utils/parseNumberTS';
import {TODO} from '../../../utils/TODO';
import {transformCargoFormData} from './transformCargoFormData';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

const sectionMapping = {
  marketSegment: ['id', 'cargoType'],
  contractType: ['contractType'],
  charterer: ['chartererName', 'maxVesselAgeVc'],
  commodity: ['commodityCategory', 'commodityType', 'commodityStowageFactor', 'commodityStowageFactorUnit'],
  quantity: ['intakes', 'quantityMin', 'quantityMax', 'quantityUnit', 'tolerance', 'toleranceUnit', 'quantityOption'],
  vesselFeatures: [
    'gears',
    'gear',
    'vesselSizeMin',
    'vesselSizeMax',
    'vesselSizeUnit',
    'vesselType',
    'vesselSubtype',
    'maxVesselAge',
    'reeferPlugs',
    'iceClass',
    'lakesFitted',
    'cellular',
    'sternThrust',
    'openHatch',
    'itfFitted',
    'bowThrust',
    'logFitted',
    'tweenDecks',
    'a60Bulkhead',
    'co2Fitted',
    'boxShape',
    'scrubberFitted',
  ],
  duration: [
    'durationAbout',
    'durationUnit',
    'periodFrom',
    'periodTo',
    'durationMin',
    'durationMax',
    'periodAbout',
    'ladenMin',
    'ladenMax',
    'ladenOption',
  ],
  laycan: ['laycanFrom', 'laycanTo'],
  stations: ['stations', 'loading', 'discharge', 'delivery', 'deliveryvia', 'redelivery'],
  documents: ['documents'],
  comment: ['comment'],
  commission: ['commissionAddress', 'commissionTotal', 'commissionBrokerage'],
};

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {newCargoFromExternal?: PortfolioCargo};

const CargoForm = ({
  getCargo,
  validate,
  createCargo,
  updateCargo,
  resetCargoErrors,
  formData,
  formLoading,
  formValidationErrors,
  submitting,
  uploading,
  newCargoFromExternal,
}: Props) => {
  // const {currentStep, completedSteps, gotoStep, findStepWithFirstError} = useStepper(steps.map(s => s.label));
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const isEdit = !!params.id;
  let scrollTimeout: number | null = null;

  useEffect(() => {
    const isEdit = !!params.id;
    if (isEdit) {
      setNotFound(false);
      getCargo(params.id).catch(() => setNotFound(true));
    }
  }, [params, getCargo]);

  useEffect(
    () => () => {
      if (scrollTimeout) {
        window.clearTimeout(scrollTimeout);
      }
    },
    [scrollTimeout]
  );

  const prepareForApi = (form: TODO, sections = Object.keys(sectionMapping)) =>
    Object.keys(form).reduce(
      (toApi, section) =>
        sections.includes(section)
          ? {
              ...toApi,
              ...Object.keys(form[section]).reduce((acc, value) => ({...acc, [value]: form[section][value]}), {}),
            }
          : toApi,
      {}
    );

  const validateAndGoToStep = async (formProps: TODO) => {
    try {
      await resetCargoErrors();
      const form = formProps.runToApiTransformer(formProps.form);
      await validate({
        ...prepareForApi(form, steps.sections),
        contractType: form.contractType.contractType,
      });
    } catch (e) {
      scrollToFirstError();
    }
  };

  const handleFormSubmit = async (form: TODO) => {
    try {
      await resetCargoErrors();

      if (isEdit) {
        const cargo = await updateCargo(prepareForApi(form));
        await showNotification('Cargo has been saved.');
        navigate(`/cargo/${cargo.id}`);
      } else {
        await createCargo(prepareForApi(form));
        await showNotification('Cargo has been created.');
        navigate('/my-cargoes');
      }
    } catch (e) {
      scrollToFirstError();
    }
  };

  const scrollToFirstError = () => {
    scrollTimeout = window.setTimeout(() => {
      const firstEle = document.querySelector('.cargo-vessel-form .labeled-wrapper--hasError');
      if (firstEle) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        animateScrollTo(firstEle, {verticalOffset: -90});
      }
    }, 100);
  };

  if (isEdit) {
    if (!formData) {
      return null;
    }

    if (formLoading) {
      return <LoadingIndicator />;
    }

    if (notFound) {
      return <NotFound />;
    }
  }

  let defaultFormData = undefined;
  if (newCargoFromExternal) {
    defaultFormData = newCargoFromExternal;
  } else {
    if (isEdit && formData) {
      defaultFormData = formData;
    }
  }

  return (
    <FormProvider
      key={params.id}
      onFormSubmit={formData => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleFormSubmit(formData);
      }}
      sectionMapping={sectionMapping}
      formData={defaultFormData}
      formValidationErrors={formValidationErrors}>
      {({onSubmit, validateSections, ...formProps}: TODO) => {
        return (
          <CargoFormBody
            id={parseIntTS(params.id)}
            formProps={formProps}
            validateAndGoToStep={validateAndGoToStep}
            validateSections={validateSections}
            submitting={submitting}
            onSubmit={onSubmit}
            scrollToFirstError={scrollToFirstError}
            uploading={uploading}
          />
        );
      }}
    </FormProvider>
  );
};

const formDataSelector = createSelector(
  (state: RootState) => state,
  (cargo: TODO) => {
    if (cargo) {
      return transformCargoFormData({...cargo, maxVesselAgeVc: cargo.maxVesselAge});
    }
  }
);

const mapStateToProps = ({api, portfolio}: TODO) => ({
  formData: formDataSelector(portfolio.cargo),
  formLoading: api.cargoes.getCargo.loading,
  submitting: api.cargoes.validate.loading || api.cargoes.patch.loading || api.cargoes.post.loading,
  uploading: api.attachmentService.uploadCargoDocuments.loading,
  formValidationErrors:
    (api.cargoes.validate.data && api.cargoes.validate.data.error && api.cargoes.validate.data.error.errors) ||
    (api.cargoes.post.data && api.cargoes.post.data.error && api.cargoes.post.data.error.errors) ||
    (api.cargoes.patch.data && api.cargoes.patch.data.error && api.cargoes.patch.data.error.errors) ||
    [],
});

const mapDispatchToProps = (dispatch: TODO) => ({
  getCargo: (cargoId: TODO) => dispatch(cargoApi.getCargo({params: {id: cargoId}})),
  createCargo: (body: TODO) => dispatch(cargoApi.post({body})),
  updateCargo: (body: TODO) => dispatch(cargoApi.patch({params: {cargoId: body.id}, body})),
  validate: (body: TODO) => dispatch(cargoApi.validate({body})),
  resetCargoErrors: () =>
    batch(() => {
      dispatch({type: '@API_CARGOAPI_VALIDATE_ERROR', payload: {}});
      dispatch({type: '@API_CARGOAPI_POST_ERROR', payload: {}});
      dispatch({type: '@API_CARGOAPI_PATCH_ERROR', payload: {}});
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CargoForm);
