import React, {useMemo} from 'react';
import {Card} from 'antd';
import {useMultiCalculation} from './useMultiCalculation';
import {Visualization} from './Visualization/Visualization';
import {MultiCalcStateFromLocation, useMultiCalcStateFromLocation} from './utils/useMultiCalcStateFromLocation';
import {useSetAssumptions} from './utils/useSetAssumptions';
import {Assumptions} from './types';
import {AssumptionsList} from './AssumptionsList';
import {blankState} from '../Voyagecharter/Context/blankState';
import {ScreenHeader} from '../../ScreenHeader/ScreenHeader';

type Props = {
  setAssumptions?: (assumptions: Assumptions) => void;
  multiCalcStateFromLocation?: MultiCalcStateFromLocation;
};

export const MultiCalcScreen = (props: Props) => {
  const multiCalcStateFromLocationFromHook = useMultiCalcStateFromLocation();

  const setAssumptionsFromLocation = useSetAssumptions();
  const setAssumptions = props.setAssumptions ?? setAssumptionsFromLocation;

  const multiCalcStateFromLocation = useMemo(() => {
    if (props.multiCalcStateFromLocation) {
      return props.multiCalcStateFromLocation;
    }

    if (multiCalcStateFromLocationFromHook.isLoading) {
      return {
        data: {
          cargoes: [],
          vessels: [],
          assumptions: {
            seaMargin: 15,
            portCosts: 13,
            costMgoPerMts: 12,
            costIfoPerMts: 13,
            costMdoPerMts: 13,
            distanceCalculator: blankState.inputState.calculator.distanceCalculator,
          },
        },
        isLoading: true,
      };
    }
    return multiCalcStateFromLocationFromHook;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.multiCalcStateFromLocation,
    multiCalcStateFromLocationFromHook.isLoading,
    multiCalcStateFromLocationFromHook.data?.assumptions,
  ]);

  const multiCalculation = useMultiCalculation({
    multiCharterCalculationState: multiCalcStateFromLocation.data!,
  });
  return (
    <>
      <ScreenHeader
        helmetTitle="Multi calculation (VC)"
        breadcrumbs={[{title: 'Tools'}, {title: 'Calculation'}, {title: 'Multi calculation (VC)'}]}
      />
      <Card data-cy={'MultiCalculationCard'}>
        <p>Manipulate your calculation by setting your assumptions below.</p>
        <AssumptionsList
          isLoading={multiCalculation.isLoading}
          data-cy={'AssumptionsList'}
          assumptions={multiCalcStateFromLocation.data!.assumptions}
          onChange={setAssumptions}
        />
        <div style={{height: 20}}></div>
        <Visualization
          cargoes={multiCalcStateFromLocation.data!.cargoes}
          vessels={multiCalcStateFromLocation.data!.vessels}
          multiCalculation={multiCalculation}
          isLoading={multiCalcStateFromLocation.isLoading || multiCalculation.isLoading}
        />
      </Card>
    </>
  );
};
