import React, {Component} from 'react';
import {connect} from 'react-redux';
import './style.scss';
import {showNotification} from '../../../../../utils/notification';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../../../atoms/Select/Indicators';
import {Button} from '../../../../../atoms/Button/Button';
import BaseModal from '../../../../../atoms/BaseModal';
import vesselService from '../../../../../redux/ApiService/vesselService';
import cargoService from '../../../../../redux/ApiService/cargoService';
import PortfolioService, {Workspace} from '../../../../../redux/ApiService/PortfolioService';
import companyService from '../../../../../redux/ApiService/CompanyService/CompanyService';
import {GridClipboardActions} from '../../../../../redux/GridClipboard';
import {RootState} from '../../../../../redux/store';
import {TODO} from '../../../../../utils/TODO';
import {SubPortfolio} from '../../../../../redux/Portfolio';
import Select from '../../../../../atoms/Select/Select';
import {MultiSelect} from '../../../../../atoms/Select/MultiSelect';

type Props = {
  type: 'vessel' | 'cargo';
  company: string;
  title: string;
  vesselSubPortfolios?: SubPortfolio[];
  cargoSubPortfolios?: SubPortfolio[];
  multiCreateCargoes: (cargoes: $TSFixMe, workspace: number, subPortfolio: number[]) => Promise<void>;
  multiCreateVessel: (vessels: $TSFixMe, workspace: number, subPortfolio: number[]) => Promise<void>;
  workspaces: TODO;
  data: TODO;
  workspace: TODO;
  getSubData: TODO;
  activeTabCargo: number;
  activeTabVessel: number;
  getUserWorkspaceList: TODO;
  resetSelection: (section: 'market') => void;
  close: () => void;
};

type MultiPortfolioAddModalState = {
  selectedWorkspace: Workspace | null;
  subPortfolio: SubPortfolio[] | null;
  name: string;
  disabled: boolean;
};

class MultiPortfolioAddModalBody extends Component<Props, MultiPortfolioAddModalState> {
  state: MultiPortfolioAddModalState = {
    name: '',
    selectedWorkspace: null,
    subPortfolio: null,
    disabled: false,
  };
  componentDidMount() {
    const {getSubData, type, workspace, getUserWorkspaceList} = this.props;
    getSubData(type, workspace);
    if ((this.props as $TSFixMe).company) {
      getUserWorkspaceList();
    }
    const currentSubPortfolio = this._getCurrentSubPortfolio();
    this.setState({
      selectedWorkspace: workspace,
      subPortfolio: currentSubPortfolio ? [currentSubPortfolio] : null,
    });
  }
  clone = async () => {
    const {type, multiCreateVessel, multiCreateCargoes, data} = this.props;
    const {selectedWorkspace} = this.state;
    const subPortfolio = this.state.subPortfolio ?? [];
    this.setState({disabled: true});
    if (type === 'vessel') {
      await multiCreateVessel(
        data,
        selectedWorkspace!.id,
        subPortfolio.map((portfolios: $TSFixMe) => portfolios.id)
      );
      this.props.resetSelection('market');
      showNotification('Vessels added to  my fleet.');
      this.props.close();
    } else if (type === 'cargo') {
      await multiCreateCargoes(
        data,
        selectedWorkspace!.id,
        subPortfolio.map((portfolio: SubPortfolio) => portfolio.id)
      );
      this.props.resetSelection('market');

      showNotification('Cargoes added to  my cargoes.');
      (this.props as $TSFixMe).close();
    }
  };
  _getCurrentSubPortfolio = (): SubPortfolio | null => {
    const {activeTabVessel, vesselSubPortfolios, type, activeTabCargo, cargoSubPortfolios} = this.props;
    return type === 'vessel'
      ? vesselSubPortfolios!.find((subPortfolio: SubPortfolio) => subPortfolio.id === activeTabVessel) ?? null
      : cargoSubPortfolios!.find((subPortfolio: SubPortfolio) => subPortfolio.id === activeTabCargo) ?? null;
  };
  render() {
    const {type, vesselSubPortfolios, cargoSubPortfolios, workspaces, getSubData} = this.props;
    const {subPortfolio, selectedWorkspace} = this.state;
    let items = [];
    if (workspaces.data) {
      items = workspaces.data.items;
    }
    return (
      <div className={'clone-portfolio'}>
        <div className={'clone-portfolio__body'}>
          {workspaces && workspaces?.data && (
            <LabelWrapper label={'Select workspace'}>
              <Select
                onChange={(workspace: $TSFixMe, meta: $TSFixMe) => {
                  this.setState({
                    subPortfolio: [],
                    selectedWorkspace: meta.action === 'select-option' ? workspace : null,
                  });
                  getSubData(type, workspace);
                }}
                components={{DropdownIndicator: ArrowIndicator}}
                options={items}
                getOptionLabel={(option: $TSFixMe) => option.name}
                getOptionValue={(option: $TSFixMe) => option.id}
                value={selectedWorkspace}
              />
            </LabelWrapper>
          )}
          {((vesselSubPortfolios?.length ?? 0) > 0 || (cargoSubPortfolios?.length ?? 0) > 0) && (
            <LabelWrapper label={`Select ${type} list`}>
              <MultiSelect
                isDisabled={(this.props as $TSFixMe).loadingSubPortfolios}
                value={subPortfolio ?? undefined}
                onChange={(type: $TSFixMe) => this.setState({subPortfolio: type ? type : []})}
                components={{DropdownIndicator: ArrowIndicator}}
                options={type === 'vessel' ? vesselSubPortfolios : cargoSubPortfolios}
                getOptionLabel={(option: $TSFixMe) => option.name}
                getOptionValue={(option: $TSFixMe) => option.id}
                isClearable={true}
              />
            </LabelWrapper>
          )}
        </div>
        <Button
          disabled={this.state.disabled}
          primary
          bold
          upper
          label={'Add to My ' + (type === 'vessel' ? 'Fleet' : 'Cargoes')}
          styleOverride={{marginTop: '35px'}}
          onClick={() => this.clone()}
        />
      </div>
    );
  }
}

const AddPortfolioEntry = (props: $TSFixMe) => {
  const {type} = props;
  const title = 'Add to My ' + (type === 'vessel' ? 'Fleet' : 'Cargoes');

  return (
    <BaseModal title={title} middle>
      {modalProps => <MultiPortfolioAddModalBody {...props} {...modalProps} />}
    </BaseModal>
  );
};

const mapStateToProps = ({portfolio, user, company, api}: RootState) => ({
  company: user!.company,
  workspace: user!.payload.workspace,
  workspaces: company.workspaces,
  vesselSubPortfolios: portfolio.selectableVesselSubPortfolios,
  activeTabVessel: portfolio.screenState.activeTabVessel,
  cargoSubPortfolios: portfolio.selectableCargoSubPortfolios,
  activeTabCargo: portfolio.screenState.activeTabCargo,
  loadingSubPortfolios: api.portfolio.getVesselGroups.loading || api.portfolio.getVesselGroups.loading,
});

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  multiCreateVessel: (vessels: $TSFixMe, workspace: number, subPortfolio: number[]) =>
    dispatch(vesselService.multiCreate(vessels, workspace, subPortfolio)),
  multiCreateCargoes: (cargoes: $TSFixMe, workspace: number, subPortfolio: number[]) =>
    dispatch(cargoService.multiCreate(cargoes, workspace, subPortfolio)),

  getUserWorkspaceList: () => dispatch(companyService.getUserWorkspaceList()),

  getSubData: (type: $TSFixMe, workspace: $TSFixMe) =>
    type === 'vessel'
      ? dispatch(PortfolioService.getVesselGroups(workspace))
      : dispatch(PortfolioService.getCargoGroups(workspace)),
  resetSelection: (payload: $TSFixMe) => dispatch(GridClipboardActions.resetSelection(payload)),
});
export const MultiPortfolioAddModal = connect(mapStateToProps, mapDispatchToProps)(AddPortfolioEntry);
