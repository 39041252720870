import {MulticalcCargoInput, MulticalcVesselInput} from '../../../types';
import {Popover, Table} from 'antd';
import {MultiCalculation} from '../../../useMultiCalculation';
import numeral from 'numeral';
import {FreightIdeaCell} from '../../../utils/FreightIdeaCell';
import styled from 'styled-components';
import {OneCalcResult} from '../../../utils/oneCalc';
import {isCalculationWithResult} from '../../../utils/isCalculationWithResult';
import {ErrorAlert} from '../../../utils/ErrorAlert';
import {ActionDropdown} from '../../../utils/ActionDropdown';
import {TceCell} from '../../../utils/TceCell';
import {calcBreakEvenFreightIdea} from '../../../utils/calcBreakEvenFreightIdea';
import uniqBy from 'lodash/uniqBy';
import NoResults from '../../../../../../screens/market/EmptyState/NoResults';
import {Icon} from '../../../../../../atoms/Icon';
import {TableVesselDetail} from '../../components';

type Props = {
  testId: string;
  cargo: MulticalcCargoInput;
  vessels: MulticalcVesselInput[];
  multiCalculation: MultiCalculation;
};

/**
 * This component shows the nested table for a row in the CargoToVesselTable.
 * It represents one cargo  with the different vessels in detail.
 */
export const NestedTable = ({testId, cargo, multiCalculation}: Props) => {
  const oneCalcResultsForThisCargo: OneCalcResult[] = (multiCalculation.oneCalcResults ?? []).filter(
    calc => calc.cargo.id === cargo.cargo.id && calc.cargoProject?.id === cargo.project?.id
  );
  const arrayOfCargosWithCalculationCantCalc = oneCalcResultsForThisCargo.filter(
    oneCalcResult => !isCalculationWithResult(oneCalcResult)
  );
  const arrayOfCargosWithCalculationCalcOK = oneCalcResultsForThisCargo.filter(oneCalcResult =>
    isCalculationWithResult(oneCalcResult)
  );
  return (
    <Cell data-cy={'CargoToVesselTableNestedVesselCell_' + testId} data-testid={'NestedTableCell_' + testId}>
      <BorderBox>
        <Table<OneCalcResult>
          data-testid={'NestedTableTable_' + testId}
          loading={multiCalculation.oneCalcResults === undefined}
          dataSource={arrayOfCargosWithCalculationCalcOK}
          locale={{emptyText: <NoResults type={'combination'} secondLine={''} />}}
          rowKey={oneCalcResult => oneCalcResult.id}
          columns={[
            {
              key: 'vessel',
              title: 'Vessel',
              render: (_, item) => {
                return <TableVesselDetail vessel={item.vessel} />;
              },
            },
            {
              align: 'right',
              key: 'freightIdea',
              dataIndex: ['voyageCalculationInput', 'cargo', 'freightIdea'],
              title: 'Freight idea',
              render: (freightIdea, oneCalcResult) => {
                return (
                  <FreightIdeaCell
                    freightIdea={freightIdea}
                    onChange={newFreightIdea => multiCalculation.updateFreightIdea(oneCalcResult, newFreightIdea)}
                  />
                );
              },
            },
            {
              key: 'tce',
              align: 'right',
              dataIndex: ['voyageCalculationOutput', 'cargo', 'tce'],
              title: 'TCE',
              render: (tce, oneCalcResult) => (
                <TCE data-testid={'NestedTableTableTCECell_' + testId}>
                  <TceCell
                    tce={tce}
                    onChange={v => {
                      const newFreightIdea = calcBreakEvenFreightIdea({
                        oneCalc: oneCalcResult,
                        tceTarget: v,
                      });
                      multiCalculation.updateFreightIdea(oneCalcResult, newFreightIdea);
                    }}
                  />
                </TCE>
              ),
            },
            {
              key: 'revenueNet',
              align: 'right',
              dataIndex: ['voyageCalculationOutput', 'cargo', 'revenueNet'],
              title: 'Revenue net',
              render: revenueNet => numeral(revenueNet).format('$0,0'),
            },
            {
              key: 'revenueTotal',
              align: 'right',
              dataIndex: ['voyageCalculationOutput', 'cargo', 'revenueTotal'],
              title: 'Profit',
              render: revenueTotal => numeral(revenueTotal).format('$0,0'),
            },
            {
              key: 'duration',
              align: 'right',
              dataIndex: ['voyageCalculationOutput', 'cargo', 'duration'],
              title: 'Duration',
              render: duration => `${duration.toFixed(1)} days`,
            },
            {
              key: 'warnings',
              title: 'Warnings',
              render: (_, oneCalcResult) => {
                return (
                  <Popover
                    trigger={'hover'}
                    content={
                      <ErrorUL>
                        {uniqBy(oneCalcResult.voyageCalculationOutput.warning.list, 'code').map(warning => {
                          return <li key={warning.code + warning.dataFieldId}>{warning.message}</li>;
                        })}
                      </ErrorUL>
                    }>
                    <Icon type={'block'} size={'small'} />{' '}
                    {uniqBy(oneCalcResult.voyageCalculationOutput.warning.list, 'code').length} warnings
                  </Popover>
                );
              },
            },
            {
              key: 'options',
              title: '',
              width: 50,
              render: (v, oneCalcResult) => {
                return <ActionDropdown oneCalcResult={oneCalcResult} />;
              },
            },
          ]}
          pagination={false}
          size={'small'}
        />
      </BorderBox>
      {arrayOfCargosWithCalculationCantCalc.map(oneCalcResult => {
        return <ErrorAlert key={oneCalcResult.id} oneCalcResult={oneCalcResult} testId={testId} />;
      })}
    </Cell>
  );
};

const Cell = styled.div`
  margin-bottom: -10px;
`;

const ErrorUL = styled.ul`
  list-style: disc;
  padding-left: 20px;
  margin: 0;
`;

const TCE = styled.span`
  font-weight: 800;
  text-decoration: underline;
`;

const BorderBox = styled.div`
  border: var(--border-light);
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-bottom: 20px;
`;
