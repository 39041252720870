import React, {FC} from 'react';
import {NegotiationRoundResultSummary, NegotiationSummary} from '../../../../../../api/node-backend/generated';
import {LoadingBanner} from '../../../../Components/shared';
import {useFindRoundResultByIdQuery} from '../../../utils/useFindRoundResultByIdQuery';
import {HistoryDetailHeader} from './HistoryDetailHeader';
import {useGetRoundResultModificationsQuery} from '../../../utils/useGetRoundResultModificationsQuery';
import {HistoryDetailBody} from './HistoryDetailBody';
import {Button} from 'antd';
import Icon from '../../../../../../atoms/Icon';
import styled from 'styled-components';
import {useLocalStorage} from '../../../../../../utils/useLocalStorage';

type HistoryDetailProps = {
  negotiation: NegotiationSummary;
  selectedRound: NegotiationRoundResultSummary;
  onDismissClick: () => void;
};

export const HistoryDetail: FC<HistoryDetailProps> = ({negotiation, selectedRound, onDismissClick}) => {
  const [hideUnchanged, setHideUnchanged] = useLocalStorage('negotiation-history-hide-unchanged', true);
  const onToggleHideUnchanged = () => setHideUnchanged(show => !show);

  const [hideSettled, setHideSettled] = useLocalStorage('negotiation-history-hide-settled', true);
  const onToggleHideSettled = () => setHideSettled(show => !show);

  const roundResultQuery = useFindRoundResultByIdQuery(selectedRound.id);
  const roundResultModificationsQuery = useGetRoundResultModificationsQuery(selectedRound.id);

  if (roundResultQuery.isLoading || roundResultModificationsQuery.isLoading) {
    return <LoadingBanner />;
  }

  if (!roundResultQuery.isSuccess || !roundResultModificationsQuery.isSuccess) {
    return <div>Error</div>;
  }

  const roundResult = roundResultQuery.data;
  const committedBy = roundResult.committedBy!;
  const committedAt = roundResult.committedAt!;
  const roundResultModifications = roundResultModificationsQuery.data;

  return (
    <div data-testid="HistoryDetail">
      <CollapseButton size="small" onClick={onDismissClick}>
        <Icon type="angle-up" />
      </CollapseButton>
      <HistoryDetailHeader
        committedBy={committedBy}
        committedAt={committedAt}
        hideUnchanged={hideUnchanged}
        hideSettled={hideSettled}
        onToggleHideUnchanged={onToggleHideUnchanged}
        onToggleHideSettled={onToggleHideSettled}
        onDismissClick={onDismissClick}
      />
      <HistoryDetailBody
        negotiation={negotiation}
        roundResult={roundResult}
        roundResultModifications={roundResultModifications}
        hideUnchanged={hideUnchanged}
        hideSettled={hideSettled}
      />
    </div>
  );
};

const CollapseButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 40px);
  margin: -2px -20px 10px;
  border-radius: 0;
`;
