import {MapPort, useMapPortsQuery} from '../../../queries/useMapPortsQuery';
import {MapTradingArea, useMapTradingAreasQuery} from '../../../queries/useMapTradingAreasQuery';
import {CircularItem, useCircularItemsMapQuery} from './useCircularItemsMapQuery';
import produce from 'immer';
import {useMemo} from 'react';

export type ExtendPortsAndTradingAreasWithCirculars = {
  ports: MapPortWithMarketCirculars[];
  tradingAreas: MapTradingAreaWithMarketCirculars[];
};

export const useExtendPortsAndTradingAreasWithCirculars = ({
  isShowMarketCargoes,
  isShowMarketVessels,
}: {
  isShowMarketVessels: boolean;
  isShowMarketCargoes: boolean;
}): ExtendPortsAndTradingAreasWithCirculars => {
  const portsQuery = useMapPortsQuery();
  const tradingAreasQuery = useMapTradingAreasQuery();
  const circularItemsQuery = useCircularItemsMapQuery();

  const extendPortsAndTradingAreasWithCirculars = useMemo((): ExtendPortsAndTradingAreasWithCirculars => {
    const enabled = portsQuery.isSuccess && tradingAreasQuery.isSuccess && circularItemsQuery.isSuccess;
    if (!enabled) {
      return {
        tradingAreas: [],
        ports: [],
      };
    }

    const tradingAreas: MapTradingAreaWithMarketCirculars[] = (tradingAreasQuery.data?.data.items ?? [])
      .map(area => {
        const circulars: CircularItem | undefined = circularItemsQuery.data?.tradingAreaMap[area.id];
        return {...area, circulars};
      })
      .filter(area => !!area.circulars) as MapTradingAreaWithMarketCirculars[];

    const ports: MapPortWithMarketCirculars[] = (portsQuery.data?.data.items ?? [])
      .map(port => {
        const circulars: CircularItem | undefined = circularItemsQuery.data?.portMap[port.id];
        return {...port, circulars};
      })
      .filter(port => !!port.circulars) as MapPortWithMarketCirculars[];

    return {
      ports: ports.map(port =>
        disableCircularsByMapSwitch<MapPortWithMarketCirculars>({port, isShowMarketVessels, isShowMarketCargoes})
      ),
      tradingAreas: tradingAreas.map(port =>
        disableCircularsByMapSwitch<MapTradingAreaWithMarketCirculars>({
          port,
          isShowMarketVessels,
          isShowMarketCargoes,
        })
      ),
    };
  }, [
    portsQuery.isSuccess,
    tradingAreasQuery.isSuccess,
    circularItemsQuery.isSuccess,
    portsQuery.data?.data.items,
    tradingAreasQuery.data?.data.items,
    circularItemsQuery.data?.tradingAreaMap,
    circularItemsQuery.data?.portMap,
    isShowMarketVessels,
    isShowMarketCargoes,
  ]);

  return extendPortsAndTradingAreasWithCirculars;
};

const disableCircularsByMapSwitch = <Item extends {circulars: CircularItem}>({
  port,
  isShowMarketVessels,
  isShowMarketCargoes,
}: {
  port: Item;
  isShowMarketVessels: boolean;
  isShowMarketCargoes: boolean;
}): Item => {
  return produce(port, draftPort => {
    if (!isShowMarketCargoes) {
      draftPort.circulars.amountCargoes = 0;
      draftPort.circulars.market.amountCargoes = 0;
    }
    if (!isShowMarketVessels) {
      draftPort.circulars.amountVessels = 0;
      draftPort.circulars.market.amountVessels = 0;
    }
  });
};

export interface MapTradingAreaWithMarketCirculars extends MapTradingArea {
  circulars: CircularItem;
}
export interface MapPortWithMarketCirculars extends MapPort {
  circulars: CircularItem;
}
