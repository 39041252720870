import {FC} from 'react';
import styled from 'styled-components';
import LoadingComponent from '../../../../components/DataGrid/LoadingComponent';
import {Collapse} from 'antd';

export const InstructionsSeparator = styled.div`
  border-top: 1px solid var(--color-gray-3);
`;

export const StyledSettingsSection = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const StyledSettingsSectionWrapper = styled.div`
  line-height: 24px;
`;

export const InstructionsContainer = styled.div<{$color: string}>`
  padding: 17px 40px 10px;
  background: var(--color-${({$color}) => $color});
  border-top: 1px solid var(--color-gray-3);
`;

export const InstructionsCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0 !important;
  }
`;

InstructionsCollapse.Panel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const InstructionsStepList = styled.ol`
  padding-left: 15px;
`;

interface LoadingProps {
  isLoading: boolean;
  children?: React.ReactNode;
}

export const Loading: FC<LoadingProps> = ({children, isLoading}) => {
  if (isLoading) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }
  return <>{children}</>;
};
