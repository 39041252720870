import {useMutation} from '@tanstack/react-query';
import {TourId} from './TourDefinition';
import {getFetchHeaders} from '../../api/utils/api';
import {fetchJSON} from '../../api/utils/fetchJSON';

export interface UpdateTourUserDataRequestData {
  tourId: number;
  step: number;
  done?: boolean; // if this is set (independent of its value), the tour is marked as done (PHP legacy)
}

export const useUpdateTourUserDataMutation = (tourId: TourId) => {
  return useMutation({
    mutationFn: async (body: UpdateTourUserDataRequestData) => {
      // This PHP API is not yet OpenAPI generated, therefore we use fetchJSON and getFetchHeaders
      const response = await fetchJSON(`/api/tour/${tourId}`, {
        method: 'POST',
        headers: getFetchHeaders(),
        body: JSON.stringify(body),
      });
      return response;
    },
  });
};
