/* tslint:disable */
/* eslint-disable */
/**
 * BunkerService
 * BunkerService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface FuturePrice
 */
export interface FuturePrice {
  /**
   *
   * @type {string}
   * @memberof FuturePrice
   */
  port?: string;
  /**
   *
   * @type {string}
   * @memberof FuturePrice
   */
  grade?: string;
  /**
   *
   * @type {string}
   * @memberof FuturePrice
   */
  yearMonth?: string;
  /**
   *
   * @type {Date}
   * @memberof FuturePrice
   */
  receivedAt?: Date;
  /**
   *
   * @type {number}
   * @memberof FuturePrice
   */
  price?: number;
}

export function FuturePriceFromJSON(json: any): FuturePrice {
  return FuturePriceFromJSONTyped(json, false);
}

export function FuturePriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuturePrice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    port: !exists(json, 'port') ? undefined : json['port'],
    grade: !exists(json, 'grade') ? undefined : json['grade'],
    yearMonth: !exists(json, 'yearMonth') ? undefined : json['yearMonth'],
    receivedAt: !exists(json, 'receivedAt') ? undefined : new Date(json['receivedAt']),
    price: !exists(json, 'price') ? undefined : json['price'],
  };
}

export function FuturePriceToJSON(value?: FuturePrice | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    port: value.port,
    grade: value.grade,
    yearMonth: value.yearMonth,
    receivedAt: value.receivedAt === undefined ? undefined : value.receivedAt.toISOString(),
    price: value.price,
  };
}
