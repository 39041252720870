import {AsyncSelect} from '../../../../atoms/Select/AsyncSelect';
import {TODO} from '../../../../utils/TODO';
import {CountryLocation} from '../../../../components/LocationOutput/LocationOutputRow';
import React from 'react';
import autocompleteApi from '../../../../redux/ApiService/autocomplete';
import {useDispatch} from '../../../../redux/react-redux';

export const FlagSelector = ({
  value,
  onChange,
}: {
  value: TODO;

  onChange: (newValue: TODO) => void;
}) => {
  const dispatch = useDispatch();

  const loadCountryFlagOptions = (query: TODO) => dispatch(autocompleteApi.searchCountries(query));

  return (
    <div data-testId={`FlagSelector`}>
      <AsyncSelect
        id={`form-general-country-flag`}
        name={`form-general-country-flag`}
        value={value}
        onChange={(value: TODO) => onChange(value)}
        getOptionLabel={(option: TODO) => <CountryLocation location={option} />}
        getOptionValue={(option: TODO) => option.code}
        loadOptions={(q: TODO) => (q.length > 1 ? loadCountryFlagOptions(q) : Promise.resolve([]))}
        isClearable
        placeholder={'Type to search...'}
        loadingMessage={() => 'Searching ...'}
      />
    </div>
  );
};
