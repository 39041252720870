/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AccessToken,
  AccessTokenFromJSON,
  AccessTokenToJSON,
  CompanySignupRequest,
  CompanySignupRequestFromJSON,
  CompanySignupRequestToJSON,
  LoginRequest,
  LoginRequestFromJSON,
  LoginRequestToJSON,
  LoginResponse,
  LoginResponseFromJSON,
  LoginResponseToJSON,
  PasswordRecoveryRequest,
  PasswordRecoveryRequestFromJSON,
  PasswordRecoveryRequestToJSON,
  PasswordResetRequest,
  PasswordResetRequestFromJSON,
  PasswordResetRequestToJSON,
  Problem,
  ProblemFromJSON,
  ProblemToJSON,
  RecoveryToken,
  RecoveryTokenFromJSON,
  RecoveryTokenToJSON,
  ValidationProblem,
  ValidationProblemFromJSON,
  ValidationProblemToJSON,
} from '../models';

export interface FetchRecoveryTokenRequest {
  token: string;
}

export interface GenerateAccessTokenRequest {
  grantType: GenerateAccessTokenGrantTypeEnum;
  clientId?: string;
  clientSecret?: string;
  scope?: string;
  username?: string;
  password?: string;
  refreshToken?: string;
  code?: string;
  redirectUri?: string;
  codeVerifier?: string;
}

export interface LoginOperationRequest {
  loginRequest: LoginRequest;
}

export interface RecoverPasswordRequest {
  passwordRecoveryRequest: PasswordRecoveryRequest;
}

export interface ResetPasswordRequest {
  passwordResetRequest: PasswordResetRequest;
}

export interface SignupCompanyRequest {
  companySignupRequest: CompanySignupRequest;
}

/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
  /**
   * Fetches information on a specific password recovery token.
   */
  async fetchRecoveryTokenRaw(
    requestParameters: FetchRecoveryTokenRequest
  ): Promise<runtime.ApiResponse<RecoveryToken>> {
    if (requestParameters.token === null || requestParameters.token === undefined) {
      throw new runtime.RequiredError(
        'token',
        'Required parameter requestParameters.token was null or undefined when calling fetchRecoveryToken.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/password/token/{token}`.replace(`{${'token'}}`, encodeURIComponent(String(requestParameters.token))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => RecoveryTokenFromJSON(jsonValue));
  }

  /**
   * Fetches information on a specific password recovery token.
   */
  async fetchRecoveryToken(requestParameters: FetchRecoveryTokenRequest): Promise<RecoveryToken> {
    const response = await this.fetchRecoveryTokenRaw(requestParameters);
    return await response.value();
  }

  /**
   * Generate a new OAuth access token.
   */
  async generateAccessTokenRaw(
    requestParameters: GenerateAccessTokenRequest
  ): Promise<runtime.ApiResponse<AccessToken>> {
    if (requestParameters.grantType === null || requestParameters.grantType === undefined) {
      throw new runtime.RequiredError(
        'grantType',
        'Required parameter requestParameters.grantType was null or undefined when calling generateAccessToken.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
    }
    const consumes: runtime.Consume[] = [{contentType: 'application/x-www-form-urlencoded'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.grantType !== undefined) {
      formParams.append('grant_type', requestParameters.grantType as any);
    }

    if (requestParameters.clientId !== undefined) {
      formParams.append('client_id', requestParameters.clientId as any);
    }

    if (requestParameters.clientSecret !== undefined) {
      formParams.append('client_secret', requestParameters.clientSecret as any);
    }

    if (requestParameters.scope !== undefined) {
      formParams.append('scope', requestParameters.scope as any);
    }

    if (requestParameters.username !== undefined) {
      formParams.append('username', requestParameters.username as any);
    }

    if (requestParameters.password !== undefined) {
      formParams.append('password', requestParameters.password as any);
    }

    if (requestParameters.refreshToken !== undefined) {
      formParams.append('refresh_token', requestParameters.refreshToken as any);
    }

    if (requestParameters.code !== undefined) {
      formParams.append('code', requestParameters.code as any);
    }

    if (requestParameters.redirectUri !== undefined) {
      formParams.append('redirect_uri', requestParameters.redirectUri as any);
    }

    if (requestParameters.codeVerifier !== undefined) {
      formParams.append('code_verifier', requestParameters.codeVerifier as any);
    }

    const response = await this.request({
      path: `/api/access-token`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AccessTokenFromJSON(jsonValue));
  }

  /**
   * Generate a new OAuth access token.
   */
  async generateAccessToken(requestParameters: GenerateAccessTokenRequest): Promise<AccessToken> {
    const response = await this.generateAccessTokenRaw(requestParameters);
    return await response.value();
  }

  /**
   * Authenticate a user.
   */
  async loginRaw(requestParameters: LoginOperationRequest): Promise<runtime.ApiResponse<LoginResponse>> {
    if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
      throw new runtime.RequiredError(
        'loginRequest',
        'Required parameter requestParameters.loginRequest was null or undefined when calling login.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/login`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: LoginRequestToJSON(requestParameters.loginRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => LoginResponseFromJSON(jsonValue));
  }

  /**
   * Authenticate a user.
   */
  async login(requestParameters: LoginOperationRequest): Promise<LoginResponse> {
    const response = await this.loginRaw(requestParameters);
    return await response.value();
  }

  /**
   * Terminates the current session.
   */
  async logoutRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/logout`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Terminates the current session.
   */
  async logout(): Promise<void> {
    await this.logoutRaw();
  }

  /**
   * Initiates the password recovery process.
   */
  async recoverPasswordRaw(requestParameters: RecoverPasswordRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.passwordRecoveryRequest === null || requestParameters.passwordRecoveryRequest === undefined) {
      throw new runtime.RequiredError(
        'passwordRecoveryRequest',
        'Required parameter requestParameters.passwordRecoveryRequest was null or undefined when calling recoverPassword.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/password/recover`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PasswordRecoveryRequestToJSON(requestParameters.passwordRecoveryRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Initiates the password recovery process.
   */
  async recoverPassword(requestParameters: RecoverPasswordRequest): Promise<void> {
    await this.recoverPasswordRaw(requestParameters);
  }

  /**
   * Resets the password for the user.
   */
  async resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.passwordResetRequest === null || requestParameters.passwordResetRequest === undefined) {
      throw new runtime.RequiredError(
        'passwordResetRequest',
        'Required parameter requestParameters.passwordResetRequest was null or undefined when calling resetPassword.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/password/reset`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PasswordResetRequestToJSON(requestParameters.passwordResetRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Resets the password for the user.
   */
  async resetPassword(requestParameters: ResetPasswordRequest): Promise<void> {
    await this.resetPasswordRaw(requestParameters);
  }

  /**
   */
  async signupCompanyRaw(requestParameters: SignupCompanyRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.companySignupRequest === null || requestParameters.companySignupRequest === undefined) {
      throw new runtime.RequiredError(
        'companySignupRequest',
        'Required parameter requestParameters.companySignupRequest was null or undefined when calling signupCompany.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/signup`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CompanySignupRequestToJSON(requestParameters.companySignupRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async signupCompany(requestParameters: SignupCompanyRequest): Promise<void> {
    await this.signupCompanyRaw(requestParameters);
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GenerateAccessTokenGrantTypeEnum {
  ClientCredentials = 'client_credentials',
  Password = 'password',
  RefreshToken = 'refresh_token',
  AuthorizationCode = 'authorization_code',
}
