import {Card, Col, Row, Tabs} from 'antd';
import {FC, useMemo, useState} from 'react';
import styled from 'styled-components';
import {TradingAreaTiedUpVesselsStatistic} from '../../../api/node-backend/generated';
import {bulkerMap, ClassInformation, containerMap} from './classInformation';
import {GlobalTiedUpTrendChart} from './GlobalTiedUpTrendChart/GlobalTiedUpTrendChart';
import {Map} from './Map/Map';
import {ValueRepresentation} from './TiedUpVesselsSummary/getDatasource';
import {TiedUpVesselsSummary} from './TiedUpVesselsSummary/TiedUpVesselsSummary';
import {VesselType} from './VesselType';

export const Section: FC<{
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[];
  vesselType: VesselType;
}> = ({tradingAreaTiedUpVesselsStatistics, vesselType}) => {
  const [valueRepresentation, setValueRepresentation] = useState<ValueRepresentation>('percentage');
  const [selectedClassKey, setSelectedClassKey] = useState<string>(
    vesselType === 'container' ? 'allContainers' : 'allBulkers'
  );

  const informationMap = useMemo(
    () => (vesselType === 'container' ? containerMap : bulkerMap),
    [vesselType]
  ) as unknown as Record<string, ClassInformation>;

  const items = Object.keys(informationMap).map(key => {
    const information = informationMap[key];
    return {
      key,
      label: information.label,
      children: <div></div>,
    };
  });

  return (
    <>
      <StyledTabs type="card" onChange={v => setSelectedClassKey(v)} destroyInactiveTabPane={true} items={items} />
      <SectionContent
        classInformation={informationMap[selectedClassKey]}
        valueRepresentation={valueRepresentation}
        onChangeValueRepresentation={setValueRepresentation}
        vesselType={vesselType}
        tradingAreaTiedUpVesselsStatistics={tradingAreaTiedUpVesselsStatistics}
      />
    </>
  );
};

const SectionContent: FC<{
  vesselType: VesselType;
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[];
  classInformation?: ClassInformation;
  onChangeValueRepresentation: (value: ValueRepresentation) => void;
  valueRepresentation: ValueRepresentation;
}> = ({
  classInformation,
  valueRepresentation,
  onChangeValueRepresentation,
  vesselType,
  tradingAreaTiedUpVesselsStatistics,
}) => {
  const [selectedTradingArea, setSelectedTradingArea] = useState<string>('');
  const [hoveredTradingArea, setHoveredTradingArea] = useState<string>('');

  if (!classInformation) {
    return null;
  }

  return (
    <StyledCard>
      <Row gutter={[20, 20]}>
        <Col xl={24} xxl={12} style={{height: 730}}>
          <Map
            vesselClass={classInformation}
            tradingAreas={tradingAreaTiedUpVesselsStatistics}
            selectedTradingArea={selectedTradingArea}
            onSelectTradingArea={setSelectedTradingArea}
            hoveredTradingArea={hoveredTradingArea}
            onHoverTradingArea={setHoveredTradingArea}
          />
        </Col>
        <Col xl={24} xxl={12} style={{height: 730}}>
          <GlobalTiedUpTrendChart
            tradingAreaTiedUpVesselsStatistics={tradingAreaTiedUpVesselsStatistics}
            vesselClass={classInformation}
          />
          <TiedUpVesselsSummary
            onChangeValueRepresentation={value => onChangeValueRepresentation(value)}
            accessor={classInformation.accessor}
            vesselType={vesselType}
            basicQuantity={classInformation.size}
            tradingAreaTiedUpVesselsStatistics={tradingAreaTiedUpVesselsStatistics}
            valueRepresentation={valueRepresentation}
            selectedTradingArea={selectedTradingArea}
            onSelectTradingArea={setSelectedTradingArea}
            hoveredTradingArea={hoveredTradingArea}
            onHoverTradingArea={setHoveredTradingArea}
          />
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  border-top-left-radius: 0;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: -1px;
  }
`;
