import {Col} from 'antd';
import React, {ReactNode, useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';

export const Ticker = ({children, span, addPanel}: {children: ReactNode; span: number; addPanel?: string}) => {
  const colRef = useRef<HTMLDivElement>(null);
  const movingRef = useRef<HTMLDivElement>(null);
  const [moveText, setMoveText] = useState(false);

  const handleMesurement = () => {
    const colWidth = colRef.current?.getBoundingClientRect().width || 0;
    const movingWidth = movingRef.current?.getBoundingClientRect().width || 0;
    setMoveText(colWidth < movingWidth);
  };

  useEffect(() => {
    handleMesurement();
    window.addEventListener('resize', handleMesurement);

    return () => {
      window.removeEventListener('resize', handleMesurement);
    };
  }, []);

  return (
    <Col span={span} ref={colRef}>
      <ColContainer>
        <ColStyle $isAnimating={moveText}>
          <ColRow ref={movingRef}>{children}</ColRow>
          {moveText && <ColRow>{children}</ColRow>}
          {moveText && <ColRow>{children}</ColRow>}
        </ColStyle>
      </ColContainer>
      {addPanel && <div id={addPanel} />}
    </Col>
  );
};

const moveAnimation = keyframes`
  from {
    transform: translateX(0%);
  }
  
  to {
    transform: translateX(-33.3%);
  }
`;

const ColStyle = styled.div<{$isAnimating: boolean}>`
  position: absolute;
  height: 38px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  animation: ${moveAnimation} 12s linear infinite;
  animation-play-state: ${({$isAnimating}) => (!$isAnimating ? 'paused' : 'running')};
`;

const ColContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-white);
  border: 1px solid #d9d9d9;
  background-color: var(--color-gray-6);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-inset-input);
  overflow: hidden;
`;

const ColRow = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0 12px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;
