import autocomplete from '../../../../redux/ApiService/autocomplete';
import {useDispatch} from 'react-redux';
import {filterBranchDefinition} from './ChartererDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {AsyncMultiCreatable} from '../../../../atoms/Select/AsyncMultiCreatable';
import {useSelector} from '../../../../redux/react-redux';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const Charterer = (props: Props) => {
  const dispatch = useDispatch();
  const preFillChartererData = useSelector(state => state.api.autoComplete.preFillCharterer.data?.data?.items) as {
    slug: string;
    name: string;
  }[];
  const searchCharterer = (query: string) => dispatch(autocomplete.searchCharterer(query));

  return (
    <FilterButton
      filterProviderApi={props.filterProviderApi}
      filterBranchDefinition={filterBranchDefinition}
      size={'medium'}>
      {({handleChange, values}) => (
        <div className="container">
          <AsyncMultiCreatable
            id={`filter-charterer-charterer-names`}
            name={`filter-charterer-charterer-names`}
            value={values.chartererNames}
            onChange={value => handleChange('chartererNames', [...value])}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.label}
            loadOptions={q => (q.length > 1 ? searchCharterer(q) : Promise.resolve([]))}
            defaultOptions={
              preFillChartererData?.map(p => ({
                value: p.slug,
                label: p.name,
              })) || []
            }
            createLabel={value => value}
            createOptionPosition={'first'}
            placeholder={'Type to search...'}
            loadingMessage={() => 'Searching ...'}
          />
        </div>
      )}
    </FilterButton>
  );
};

export default Charterer;
