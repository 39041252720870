/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AisTradingAreaApiModel
 */
export interface AisTradingAreaApiModel {
  /**
   * The internal ID of the area.
   * @type {number}
   * @memberof AisTradingAreaApiModel
   */
  id?: number;
  /**
   * The name of the area.
   * @type {string}
   * @memberof AisTradingAreaApiModel
   */
  name?: string;
  /**
   * Code of the area.
   * @type {string}
   * @memberof AisTradingAreaApiModel
   */
  code?: string;
}

export function AisTradingAreaApiModelFromJSON(json: any): AisTradingAreaApiModel {
  return AisTradingAreaApiModelFromJSONTyped(json, false);
}

export function AisTradingAreaApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisTradingAreaApiModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    code: !exists(json, 'code') ? undefined : json['code'],
  };
}

export function AisTradingAreaApiModelToJSON(value?: AisTradingAreaApiModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    code: value.code,
  };
}
