/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyWorkspaceCollectionData,
  CompanyWorkspaceCollectionDataFromJSON,
  CompanyWorkspaceCollectionDataFromJSONTyped,
  CompanyWorkspaceCollectionDataToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyWorkspaceCollection
 */
export interface CompanyWorkspaceCollection {
  /**
   *
   * @type {CompanyWorkspaceCollectionData}
   * @memberof CompanyWorkspaceCollection
   */
  data: CompanyWorkspaceCollectionData;
}

export function CompanyWorkspaceCollectionFromJSON(json: any): CompanyWorkspaceCollection {
  return CompanyWorkspaceCollectionFromJSONTyped(json, false);
}

export function CompanyWorkspaceCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyWorkspaceCollection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: CompanyWorkspaceCollectionDataFromJSON(json['data']),
  };
}

export function CompanyWorkspaceCollectionToJSON(value?: CompanyWorkspaceCollection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: CompanyWorkspaceCollectionDataToJSON(value.data),
  };
}
