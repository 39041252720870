import {FC, useRef, useState} from 'react';
import {Modal, ModalWidth} from '../../../../../../../antd/components/Modal';
import {NewTerm} from '../../../../../../../api/node-backend/generated';
import {TermParser} from './TermParser';

type TermParserModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (terms: NewTerm[]) => void;
};

export const TermParserModal: FC<TermParserModalProps> = ({open, onClose, onSuccess}) => {
  // Local Ref is used as Modal container to inherit negotiation CSS variables
  // Be careful when using this component outside of NegotiationDetailBody
  const ref = useRef<HTMLDivElement>(null);

  const [terms, setTerms] = useState<NewTerm[]>([]);

  return (
    <div ref={ref}>
      <Modal
        getContainer={ref.current!}
        open={open}
        title={'Paste & parse contract'}
        width={ModalWidth.Large}
        onCancel={onClose}
        onOk={() => onSuccess(terms)}
        okText={
          terms.length
            ? `Add ${terms.length} term${terms.length === 1 ? '' : 's'} from pasted text`
            : `Paste a contract to create terms`
        }
        okButtonProps={{
          disabled: !terms.length,
        }}>
        <TermParser onChangeTerms={setTerms} showPreviewIfEmpty />
      </Modal>
    </div>
  );
};
