import {useContext, useEffect, useState} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {message} from 'antd';
import {mapVesselToVesselInput} from './mapVesselToVesselInput';
import {mapCargoToCargoInput} from './mapCargoToCargoInput';
import {mapCargoToVoyageInput} from './mapCargoToVoyageInput/mapCargoToVoyageInput';
import {addNextOpenLegToVoyage} from './addNextOpenLegToVoyage/addNextOpenLegToVoyage';
import produce from 'immer';
import {handleError} from '../../../../api/utils/reactQueryClient';
import {blankState} from '../Context/blankState';
import {fetchJSON} from '../../../../api/utils/fetchJSON';
import {appendBunkerPricesToInputState} from './appendBunkerPricesToInputState';
import {TODO} from '../../../../utils/TODO';

/*
 * this function is used to make the inital load of the calclation
 */
export const useInitialLoad = () => {
  const voyageContext = useContext(VoyageCharterContext);

  const inputState = {...voyageContext.state.inputState};
  const [loaded, setLoaded] = useState(false);

  const loadVesselAndCargo = async () => {
    const inputStateManipulated = await produce(inputState, async inputStateManipulated => {
      let vessel = undefined;
      if (voyageContext.props.vesselId) {
        try {
          vessel = await fetchJSON<TODO>(`/api/vessels/${voyageContext.props.vesselId}`);
          inputStateManipulated.vessel = await mapVesselToVesselInput({
            vessel,
          });
          inputStateManipulated.vessel.id = vessel.id;
        } catch (e) {
          handleError(e);
        }
      }

      if (voyageContext.props.cargoesIds) {
        try {
          const cargo = await fetchJSON<TODO>(`/api/cargoes/${voyageContext.props.cargoesIds[0]}`);
          inputStateManipulated.cargo = await mapCargoToCargoInput({
            cargo,
            stowageFactors: [],
          });
          inputStateManipulated.voyage = mapCargoToVoyageInput({cargo});
          inputStateManipulated.cargo.id = cargo.id;
        } catch (e) {
          handleError(e);
        }
      }
      if (voyageContext.props.projectId) {
        inputStateManipulated.calculator.projectId = voyageContext.props.projectId;
      }

      inputStateManipulated.voyage = addNextOpenLegToVoyage(inputStateManipulated);
    });
    const newInputState = await appendBunkerPricesToInputState(inputStateManipulated);

    voyageContext.setInputState(newInputState);
  };

  /**
   * performs the loading of an existing caclulation if the props.id isn't undefined
   * otherwise, it trys load Vessel and Cargo and set the projectId
   */

  const load = async () => {
    if (voyageContext.props.id !== undefined) {
      await voyageContext.loadFromCloud(voyageContext.props.id).catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(e.message);
      });
      setLoaded(true);
      return;
    } else {
      const wasChanged = !!voyageContext.props.projectId;

      if (wasChanged) {
        const newInputState = produce(voyageContext.state.inputState, draftInputState => {
          draftInputState.calculator.projectId = voyageContext.props.projectId;
          return draftInputState;
        });
        if (newInputState.calculator.distanceCalculator === undefined) {
          newInputState.calculator.distanceCalculator = blankState.inputState.calculator.distanceCalculator;
        }
        voyageContext.setInputState(newInputState);
      }

      await loadVesselAndCargo();
    }
    setLoaded(true);
  };
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded;
};
