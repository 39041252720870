import numeral from 'numeral';
import {Table} from 'antd';
import {MulticalcVesselInput, MulticalcCargoInput} from '../../types';
import {NestedTable} from './NestedTable/NestedTable';
import {MultiCalculation} from '../../useMultiCalculation';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import {OneCalcResult} from '../../utils/oneCalc';
import {TableVesselDetail, TableProjectDetail} from '../components';

type Props = {
  testId: string;
  vessels: MulticalcVesselInput[];
  multiCalculation: MultiCalculation;
  breakEvenCalcs: OneCalcResult[];
  cargoes: MulticalcCargoInput[];
};

export const VesselToCargoTable = ({testId, vessels, multiCalculation, breakEvenCalcs, cargoes}: Props) => {
  return (
    <Table<MulticalcVesselInput>
      data-testid={'VesselToCargoTable_' + testId}
      data-cy={'VesselToCargoTable_' + testId}
      loading={multiCalculation.oneCalcResults === undefined}
      dataSource={vessels}
      rowKey={input => `${input.vessel.id}_${input.project?.id ?? ''}_${testId}`}
      columns={[
        {
          key: 'project',
          dataIndex: ['project'],
          title: 'Project',
          width: 4,
          render: (value, item) => {
            if (item.project === undefined) {
              return '';
            }
            return <TableProjectDetail project={item.project} />;
          },
        },
        {
          key: 'vessel',
          title: 'Vessel',
          width: 4,
          render: (value, project) => {
            return (
              <span data-testid={'VesselToCargoTableVesselColumn_' + testId}>
                <TableVesselDetail vessel={project.vessel} />
              </span>
            );
          },
        },
        {
          key: 'freightIdea_min',
          align: 'right',
          title: 'Min. Freight idea on Break Even',
          width: 2,
          render: (_, item) => {
            const val = minBy(
              breakEvenCalcs.filter(calc => calc.vessel.id === item.vessel.id),
              calc => calc.voyageCalculationInput.cargo.freightIdea
            )?.voyageCalculationInput.cargo.freightIdea;
            return (
              <span data-testid={'VesselToCargoTableFreightIdeaColumn_' + testId}>
                {numeral(val).format('$0,0[.]000')}
              </span>
            );
          },
        },
        {
          key: 'revenue_net_max',
          align: 'right',
          title: 'Max. Net Revenue on Break Even',
          width: 2,
          render: (_, item) => {
            const val = maxBy(
              breakEvenCalcs.filter(calc => calc.vessel.id === item.vessel.id),

              calc => calc.voyageCalculationOutput.cargo.revenueNet
            )?.voyageCalculationOutput.cargo.revenueNet;
            return numeral(val).format('$0,0');
          },
        },
        {
          align: 'right',
          key: 'duration_max',
          title: 'Max. Duration',
          width: 2,
          render: (_, item) => {
            const val = maxBy(
              breakEvenCalcs.filter(calc => calc.vessel.id === item.vessel.id),
              calc => calc.voyageCalculationOutput.cargo.duration
            )?.voyageCalculationOutput.cargo.duration;

            return val ? `${val.toFixed(1)} days` : '';
          },
        },
        {
          key: 'duration_min',
          align: 'right',
          title: 'Min. Duration',
          width: 2,
          render: (_, item) => {
            const val = minBy(
              breakEvenCalcs.filter(calc => calc.vessel.id === item.vessel.id),
              calc => calc.voyageCalculationOutput.cargo.duration
            )?.voyageCalculationOutput.cargo.duration;

            return val ? `${val.toFixed(1)} days` : '';
          },
        },
      ]}
      pagination={false}
      expandable={{
        expandedRowRender: vesselInput => {
          return (
            <NestedTable testId={testId} vessel={vesselInput} multiCalculation={multiCalculation} cargoes={cargoes} />
          );
        },
      }}
      scroll={{x: '100%'}}
    />
  );
};
