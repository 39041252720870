import React, {FC, useRef, useState} from 'react';
import {NegotiationSubjectStatus, NegotiationSummary} from '../../../../../api/node-backend/generated';
import {getSubjectColors} from '../../../utils/colors';
import {SubjectActionArea} from './SubjectActionArea';
import {SubjectEditor} from './SubjectEditor';
import {SubjectItemDumb} from './SubjectItemDumb';
import {SubjectMeta} from './SubjectMeta';
import {getDerivedSubjectState} from './utils/getDerivedSubjectState';
import {SubjectActionsHook} from './utils/useGetSubjectActionsWithMutations';
import {SubjectWithSubjectModifications} from '../TermArea/utils';

type SubjectItemProps = {
  subjectWithModifications: SubjectWithSubjectModifications;
  negotiation: NegotiationSummary;
  roundCanBeEdited: boolean;
  actionsBusy?: boolean;
  getSubjectActions: SubjectActionsHook;
};

export const SubjectItem: FC<SubjectItemProps> = ({
  subjectWithModifications,
  negotiation,
  roundCanBeEdited,
  actionsBusy,
  getSubjectActions,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {party} = negotiation;
  const {subject, subjectModifications} = subjectWithModifications;
  const {text, status, party: subjectParty, textLastUpdatedBy} = subject;
  const subjectState = getDerivedSubjectState({party, roundCanBeEdited, subject, subjectModifications});
  const subjectActions = getSubjectActions(subject);

  const {isOwnSubject, canBeEdited, canBeSettled, canBeAccepted, canBeRejectRequestSettled, canBeRejectionRequested} =
    subjectState;
  const isCountered = textLastUpdatedBy !== party;
  const isHighlighted =
    roundCanBeEdited &&
    ([canBeSettled, canBeAccepted, canBeRejectRequestSettled, canBeRejectionRequested].some(Boolean) ||
      (isCountered && canBeEdited));

  const {updateSubjectText} = subjectActions;

  const [showEditor, setShowEditor] = useState(false);
  const startEditMode = canBeEdited ? () => setShowEditor(true) : undefined;
  const endEditMode = () => {
    setShowEditor(false);
  };
  const saveEdit = (newText: string) => {
    if (!canBeEdited || newText === text) {
      return;
    }
    endEditMode();
    updateSubjectText(newText);
  };

  const colors = getSubjectColors(status, isCountered);

  return (
    <SubjectItemDumb
      containerRef={containerRef}
      text={text}
      status={status}
      colors={colors}
      isHighlighted={isHighlighted}
      onTextClick={startEditMode}
      contentReplacement={
        !text || (canBeEdited && showEditor) ? (
          <SubjectEditor containerRef={containerRef} currentText={text} onSave={saveEdit} onCancel={endEditMode} />
        ) : null
      }
      afterTextAddon={
        <>
          <SubjectMeta
            ownerActor={negotiation[subjectParty]}
            isOwnSubject={!!isOwnSubject}
            lastEditActor={negotiation[textLastUpdatedBy]}
            isOwnEdit={!isCountered}
            isEditable={[NegotiationSubjectStatus.Ongoing, NegotiationSubjectStatus.RejectionRequested].includes(
              status
            )}
          />
          {roundCanBeEdited ? (
            <SubjectActionArea
              subjectState={subjectState}
              subjectActions={subjectActions}
              actionsBusy={actionsBusy}
              onEdit={startEditMode}
            />
          ) : null}
        </>
      }
    />
  );
};
