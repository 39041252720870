import ArrowToFilters from './svgs/ArrowToFilters.svg';
import NoFiltersSvg from './svgs/NoFilters.svg';

export const NoFilters = () => {
  return (
    <>
      <div>
        <img src={ArrowToFilters} />
      </div>
      <div style={{marginLeft: 'calc(50% - 143px)', marginTop: 40}}>
        <img src={NoFiltersSvg} />
      </div>
    </>
  );
};
