import {AISRecord} from './AISRecord';
import {useQueries, UseQueryResult} from '@tanstack/react-query';
import {useMemo} from 'react';
import {AISRecordsQueryResult} from './AISRecordsQueryResult';
import {fetchAISRecords} from './fetchAISRecords';

/**
 * Merges the given AIS records into a single array.
 */
const mergeAISRecords = (queries: UseQueryResult<AISRecord[], unknown>[]): AISRecord[] => {
  const aisRecordsArray = queries.map(query => query.data!);
  const result: AISRecord[] = [];
  for (const aisRecords of aisRecordsArray) {
    result.push(...aisRecords);
  }
  return result;
};

const createCompositeQueryResult = (
  isSuccess: boolean,
  queries: UseQueryResult<AISRecord[], unknown>[]
): AISRecordsQueryResult => {
  if (isSuccess) {
    const aisRecords = mergeAISRecords(queries);
    return {isSuccess: true, data: aisRecords};
  } else {
    // We are still waiting for queries to finished
    return {
      isSuccess: false,
    };
  }
};

/**
 * Loads AIS records for a given vessel for the given years.
 */
export const useAISRecordsForYearsQuery = ({imo, years}: {imo: number; years: number[]}): AISRecordsQueryResult => {
  // Create a query for each year in the selected timeframe
  const queryConfigurations = years.map(year => ({
    queryKey: ['aisRecordApi.getAISRecordsForYear', {imo, year}],
    queryFn: async (): Promise<AISRecord[]> => {
      return await fetchAISRecords(imo, year);
    },
    staleTime: Infinity,
    keepPreviousData: true,
    retry: false,
  }));
  const queries = useQueries({queries: queryConfigurations});
  const isSuccess = queries.every(query => query.isSuccess);
  const compositeQueryResult = useMemo(() => {
    return createCompositeQueryResult(isSuccess, queries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imo, years, isSuccess]);
  return compositeQueryResult;
};
