/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselRequirement
 */
export interface VesselRequirement {
  /**
   *
   * @type {boolean}
   * @memberof VesselRequirement
   */
  geared?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselRequirement
   */
  gearless?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselRequirement
   */
  bowThruster?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselRequirement
   */
  sternThruster?: boolean;
}

export function VesselRequirementFromJSON(json: any): VesselRequirement {
  return VesselRequirementFromJSONTyped(json, false);
}

export function VesselRequirementFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselRequirement {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    geared: !exists(json, 'geared') ? undefined : json['geared'],
    gearless: !exists(json, 'gearless') ? undefined : json['gearless'],
    bowThruster: !exists(json, 'bowThruster') ? undefined : json['bowThruster'],
    sternThruster: !exists(json, 'sternThruster') ? undefined : json['sternThruster'],
  };
}

export function VesselRequirementToJSON(value?: VesselRequirement | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    geared: value.geared,
    gearless: value.gearless,
    bowThruster: value.bowThruster,
    sternThruster: value.sternThruster,
  };
}
