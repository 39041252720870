import {SeaboVessel} from '../../../api/node-backend/generated';
import chunk from 'lodash/chunk';
import {vesselDBVesselsApi} from '../../../api/node-backend/nodeBackendApis';

export const getVesselsFromVesselDatabaseByImos = async (imos: number[]): Promise<SeaboVessel[]> => {
  // Because the vessel database API has a limit of 100 vessels per request, we need to chunk the imos.
  const imoChunks = chunk(imos, 100);
  const allVesselsFromVesselDatabase = await Promise.all(
    imoChunks.map(async imoChunk => {
      const result = await vesselDBVesselsApi.listVessels({
        listVesselsRequest: {
          filters: {
            imo: imoChunk,
          },
          pageIndex: 0,
          pageSize: 100,
        },
      });
      return result.items;
    })
  );

  return allVesselsFromVesselDatabase.flat();
};
