import {FC, RefObject, useEffect, useRef} from 'react';
import {getIsEventFrame, TimelineEvent, TimelineItem, TimelineItemType} from '../utils/types';
import {VoyageTimelineItemPort} from './VoyageTimelineItemPort';
import {VoyageTimelineItemVoyage} from './VoyageTimelineItemVoyage';
import {assertUnreachable} from '../../../../utils/assert';
import {VoyageTimelineFilters} from '../utils/filters';
import {VoyageTimelinePlaceholder} from './VoyageTimelinePlaceholder';
import {VesselVoyageTimelineEventType} from '../../../../api/node-backend/generated';
import dayjs from 'dayjs';
import animateScrollTo from 'animated-scroll-to';

const isFilterEnabled = (item: TimelineItem, filters?: VoyageTimelineFilters) => {
  if (!filters) {
    return true;
  }
  switch (item.type) {
    case TimelineItemType.Port:
      return filters[VesselVoyageTimelineEventType.PortVisit];
    case TimelineItemType.Voyage:
      return filters[VesselVoyageTimelineEventType.Route];
    default:
      assertUnreachable(item);
  }
};

const getItemScrollOffset = (itemRef: HTMLLIElement, containerRef: HTMLDivElement) => {
  const itemTop = itemRef.offsetTop;
  const itemCenter = itemTop + itemRef.offsetHeight / 2 - containerRef.offsetHeight / 2;
  const isItemLargerThanContainer = itemRef.offsetHeight > containerRef.offsetHeight;
  return isItemLargerThanContainer ? itemTop : itemCenter;
};

type VoyageTimelineItemNewProps = {
  containerRef: RefObject<HTMLDivElement>;
  index?: number;
  timelineItem: TimelineItem;
  filters?: VoyageTimelineFilters;
  maxDraft?: number;
  maxDuration?: number;
  isReduced?: boolean;
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: (id: TimelineEvent | null) => void;
};

export const VoyageTimelineItem: FC<VoyageTimelineItemNewProps> = ({
  containerRef,
  index,
  timelineItem,
  filters,
  maxDraft,
  maxDuration,
  isReduced,
  selectedEvent,
  onSelectEvent,
}) => {
  const itemRef = useRef<HTMLLIElement>(null);

  const today = dayjs().endOf('day');
  const isSelected =
    !!selectedEvent &&
    getIsEventFrame(selectedEvent) &&
    dayjs(selectedEvent.exitTimestamp ?? today).isBetween(
      dayjs(timelineItem.enterTimestamp),
      dayjs(timelineItem.exitTimestamp ?? today),
      'second',
      '(]'
    );

  useEffect(() => {
    if (!itemRef.current || !containerRef.current) {
      return;
    }
    if (!isSelected) {
      return;
    }
    const y = getItemScrollOffset(itemRef.current, containerRef.current);
    void animateScrollTo(y, {
      elementToScroll: containerRef.current,
      speed: 100,
    });
    // containerRef.current.scrollTo({
    //   top: getItemScrollOffset(itemRef.current, containerRef.current),
    //   behavior: 'smooth',
    // });
  }, [itemRef, containerRef, isSelected]);

  if (!isFilterEnabled(timelineItem, filters)) {
    return <VoyageTimelinePlaceholder item={timelineItem} />;
  }

  switch (timelineItem.type) {
    case TimelineItemType.Port:
      return (
        <VoyageTimelineItemPort
          itemRef={itemRef}
          index={index}
          item={timelineItem}
          maxDraft={maxDraft}
          maxDuration={maxDuration}
          isReduced={isReduced}
          isSelected={isSelected}
          selectedEvent={selectedEvent}
          onSelectEvent={onSelectEvent}
        />
      );
    case TimelineItemType.Voyage:
      return (
        <VoyageTimelineItemVoyage
          itemRef={itemRef}
          index={index}
          item={timelineItem}
          maxDraft={maxDraft}
          maxDuration={maxDuration}
          isReduced={isReduced}
          isSelected={isSelected}
          selectedEvent={selectedEvent}
          onSelectEvent={onSelectEvent}
        />
      );
    default:
      assertUnreachable(timelineItem);
  }
};
