import {Button, Flex, Form, Input, List} from 'antd';
import React, {ReactNode, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import sortBy from 'lodash/sortBy';
import {Tag, TagType, TagWrite} from '../../../../api/symfony/generated/models';
import LoadingComponent from '../../../../components/DataGrid/LoadingComponent';
import {useDeleteTagMutation} from './useDeleteTagMutations';
import {useUpdateTagMutation} from './useUpdateTagMutation';
import {useCreateTagMutation} from './useCreateTagMutation';
import Icon from '../../../../atoms/Icon';
import styled from 'styled-components';
import {invalidateAvailableTagsQuery, useAvailableTagsQuery} from '../../../../queries/useAvailableTagsQuery';
import {useDispatch} from '../../../../redux/react-redux';
import {ModalActions} from '../../../../redux/Modal';
import {Delete} from '../../../../components/Modal/Delete/DeleteModal';

type Props = {
  type: TagType;
};

export const CompanyTagsByType = (props: Props) => {
  const queryClient = useQueryClient();

  const [editingId, setEditingId] = useState<number | undefined>(undefined);
  const [creating, setCreating] = useState<boolean>(false);

  const availableTagsQuery = useAvailableTagsQuery({
    type: props.type,
  });

  const deleteTagMutation = useDeleteTagMutation({
    onSuccess: async () => {
      await invalidateAvailableTagsQuery(queryClient, props.type);
    },
  });
  const createTagMutation = useCreateTagMutation({
    onSuccess: async () => {
      await invalidateAvailableTagsQuery(queryClient, props.type);

      setEditingId(undefined);
      setCreating(false);
    },
  });
  const updateTagMutation = useUpdateTagMutation({
    onSuccess: async () => {
      await invalidateAvailableTagsQuery(queryClient, props.type);
      setEditingId(undefined);
    },
  });

  const onAddAnotherClick = () => {
    setCreating(true);
    setEditingId(undefined);
  };

  const onCancel = () => {
    if (creating) {
      setCreating(false);
    }
    setEditingId(undefined);
  };

  const onSubmit = (name: string, tag: Tag) => {
    if (creating) {
      const newTag: TagWrite = {name: name, type: tag.type};
      createTagMutation.mutate({tagWrite: newTag});
    } else if (editingId && tag.id !== undefined) {
      const updatedTag: TagWrite = {
        name: name,
        type: tag.type,
      };
      updateTagMutation.mutate({tagWrite: updatedTag, id: tag.id!});
    } else {
      onCancel();
    }
  };

  const dispatch = useDispatch();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const onDelete = (tag: Tag) => {
    showModal(
      <Delete
        fullTitle={`Are you sure you want to delete "${tag.name}" from all tagged objects?`}
        target={{id: tag.id!, name: undefined}}
        onDelete={(id: number, _callback: $TSFixMe, close: () => void) => {
          handleDelete(id);
          close();
        }}
      />
    );
  };

  const handleDelete = (id: number) => {
    deleteTagMutation.mutate({id: id});
  };

  if (!availableTagsQuery.isSuccess) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }

  const sortSelectedTags = sortTags(availableTagsQuery.data.items);

  const newTag = {id: undefined, name: '', type: props.type};

  return (
    <List
      bordered
      size={'small'}
      children={
        availableTagsQuery.data.items.length === 0 && (
          <List.Item>
            <i>No tags</i>
          </List.Item>
        )
      }
      style={{width: '340px'}}
      dataSource={creating ? [...sortSelectedTags, newTag] : sortSelectedTags}
      renderItem={tag => (
        <List.Item>
          <ListItemComponent
            tag={tag}
            editing={editingId === tag.id}
            isLoading={createTagMutation.isPending || updateTagMutation.isPending}
            onEdit={(value: number) => {
              setEditingId(value ? value : undefined);
            }}
            onSubmit={(name: string) => onSubmit(name.trim(), tag)}
            existingTags={creating ? [...sortSelectedTags, newTag] : sortSelectedTags}
            onCancel={() => onCancel()}
            onDelete={() => onDelete(tag)}
            disabled={editingId !== undefined && editingId !== tag.id}
          />
        </List.Item>
      )}
      footer={
        <>
          <AddAnotherButton
            onClick={onAddAnotherClick}
            disabled={editingId !== undefined}
            theme={{disabled: editingId !== undefined}}>
            <Icon
              style={{marginRight: 10}}
              disabled={editingId !== undefined}
              type={'item-add-selection-solid'}
              size={'medium'}
              color={'blue'}
            />
            ADD ANOTHER
          </AddAnotherButton>
        </>
      }
    />
  );
};

const ListItemComponent = ({
  tag,
  editing,
  isLoading,
  onEdit,
  onSubmit,
  onCancel,
  existingTags,
  onDelete,
  disabled,
}: {
  tag: Tag;
  editing: boolean;
  disabled: boolean;
  isLoading: boolean;
  existingTags: Tag[];
  onEdit: (value: number) => void;
  onSubmit: (value: string) => void;
  onCancel: () => void;
  onDelete: () => void;
}) => {
  return editing ? (
    <Form<{name: string}>
      onFinish={formValues => {
        onSubmit(formValues.name);
      }}
      style={{width: '100%'}}>
      <Flex justify="space-between" gap={4}>
        <StyledFormItem
          name="name"
          initialValue={tag.name}
          rules={[
            {
              message: 'There is already a tag with that name',
              validator: (_, fieldValue) => {
                if (fieldValue !== tag.name) {
                  const findOne = existingTags.find(myTag => {
                    return myTag.name === fieldValue;
                  });
                  if (findOne) {
                    return Promise.reject();
                  }
                }
                return Promise.resolve();
              },
            },
            {
              required: true,
              message: 'Name is required',
            },
          ]}>
          <Input
            autoFocus
            placeholder={tag.name === '' ? 'Enter a tag name' : tag.name}
            size={'middle'}
            style={{margin: '0px'}}
            autoComplete={'off'}
            onKeyDown={event => {
              if (event.key === 'Escape') {
                onCancel();
              }
            }}
          />
        </StyledFormItem>
        <Form.Item style={{margin: 0}}>
          <Button htmlType="submit" type="primary" disabled={disabled || isLoading}>
            Add
          </Button>
        </Form.Item>
        <Button disabled={disabled} icon={<StyledPlusOutlined />} onClick={() => onCancel()} />
      </Flex>
    </Form>
  ) : (
    <TagView>
      <div
        onClick={() => {
          if (!disabled) {
            onEdit(tag.id!);
          }
        }}
        style={
          disabled
            ? {color: 'gray', cursor: 'not-allowed', width: '100%'}
            : {color: 'black', cursor: 'text', width: '100%'}
        }>
        {tag.name}
      </div>
      <Button disabled={disabled} icon={<DeleteOutlined />} onClick={() => onDelete()} />
    </TagView>
  );
};

const sortTags = (tags: Tag[]): Tag[] => {
  const sortedTags = sortBy(tags, tag => tag.name.toLowerCase());
  return sortedTags;
};

const AddAnotherButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  background-color: var(--color-white);
  color: ${props => (props.theme.disabled === false ? 'var(--color-blue)' : '#d6ebff')};
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: ${props => (props.theme.disabled === false ? 'pointer' : 'not-allowed')};
`;

const TagView = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
`;

const StyledFormItem = styled(Form.Item)`
  flex: 1;
  margin-bottom: 0;
`;
const StyledPlusOutlined = styled(PlusOutlined)`
  transform: rotate(45deg);
`;
