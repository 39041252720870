import {ChokepointCongestionSummaryReport} from '../../../api/node-backend/generated';
import {ChokepointKey} from './utils/useChokepointCongestionQuery';

export const isSlowStreaming = ({
  chokepointKey,
  report,
}: {
  report: ChokepointCongestionSummaryReport;
  chokepointKey: ChokepointKey;
}): boolean => {
  if (chokepointKey === ChokepointKey.Suez) {
    // Magic heuristic threshold for Suez
    return report.percentageVesselsStuckInPassage > 30;
  }
  return false;
};
