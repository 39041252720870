/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Coordinates,
  CoordinatesFromJSON,
  CoordinatesFromJSONTyped,
  CoordinatesToJSON,
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineEventTypeFromJSON,
  VesselVoyageTimelineEventTypeFromJSONTyped,
  VesselVoyageTimelineEventTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface Drift
 */
export interface Drift {
  /**
   *
   * @type {VesselVoyageTimelineEventType}
   * @memberof Drift
   */
  eventType: VesselVoyageTimelineEventType;
  /**
   *
   * @type {number}
   * @memberof Drift
   */
  imo: number;
  /**
   *
   * @type {Date}
   * @memberof Drift
   */
  timestamp: Date;
  /**
   *
   * @type {Coordinates}
   * @memberof Drift
   */
  coordinates: Coordinates;
}

export function DriftFromJSON(json: any): Drift {
  return DriftFromJSONTyped(json, false);
}

export function DriftFromJSONTyped(json: any, ignoreDiscriminator: boolean): Drift {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeFromJSON(json['eventType']),
    imo: json['imo'],
    timestamp: new Date(json['timestamp']),
    coordinates: CoordinatesFromJSON(json['coordinates']),
  };
}

export function DriftToJSON(value?: Drift | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeToJSON(value.eventType),
    imo: value.imo,
    timestamp: value.timestamp.toISOString(),
    coordinates: CoordinatesToJSON(value.coordinates),
  };
}
