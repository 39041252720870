import {Button, Input, Spin} from 'antd';
import React, {FC, useEffect, useState} from 'react';
import {useMyFleetQuery} from './useMyFleetQuery/useMyFleetQuery';
import {getPostionListString} from './getPositionListString/getPostionListString';
import copy from 'copy-to-clipboard';
import {useSelector} from '../../../../redux/react-redux';
import {showNotification} from '../../../../utils/notification';
import {Modal} from '../../../../antd/components/Modal';

const {TextArea} = Input;

export const VesselPositionListModal: FC<{visible: boolean; onClose: () => void}> = ({visible, onClose}) => {
  const [positionString, setPositionString] = useState('');
  const gridClipboard = useSelector(state => state.gridClipboard);

  const vesselIds = gridClipboard.portfolio.vessels.map((vessel: {id: number}) => vessel.id);

  const vesselsQuery = useMyFleetQuery({
    vesselIds,
    options: {
      enabled: visible,
    },
  });

  useEffect(() => {
    if (vesselsQuery.isSuccess && vesselsQuery.data) {
      setPositionString(getPostionListString(vesselsQuery.data ?? []));
    }
  }, [vesselsQuery.data, vesselsQuery.isSuccess]);

  return (
    <Modal
      width={800}
      footer={
        <Button
          loading={vesselsQuery.isLoading}
          onClick={() => {
            copy(positionString);
            showNotification('Vessel position list was copied.', 'success');
          }}
          type={'primary'}>
          Copy to Clipboard
        </Button>
      }
      open={visible}
      centered
      title={'Position list'}
      onCancel={onClose}>
      <Spin spinning={vesselsQuery.isLoading}>
        <TextArea rows={30} value={positionString} onChange={event => setPositionString(event.target.value)} />
      </Spin>
    </Modal>
  );
};
