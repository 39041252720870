import {Button, List, Tooltip} from 'antd';
import {VoyageRoute} from '../../VoyageTypes';
import NoteIcon from '../../../utils/NoteIcon';
import numeral from 'numeral';
import {blankVoyageRouteOutput, CalcTimeLineRoute} from '../../VoyageTypes';
import {VesselConsumptionMode} from '../../../VesselInformation/VesselTypes';
import {getFormattedDateOrNotAvailable} from '../../../../../../utils/DateTime';
import {Definitions} from '../../Definitions/Definitions';
import {RouteHeaderRow} from './RouteHeaderRow';
import {Value} from '../../Definitions/Value';
import {ValueRow} from '../../Definitions/ValueRow';
import {DurationCell} from '../../utils/DurationCell';
import * as LegRow from '../../LegRow';
import Icon from '../../../../../../atoms/Icon';
import {ConsumptionFuelTypeLabel} from './ConsumptionFuelTypeLabel';
import {ConsumptionTooltip} from '../ConsumptionTooltip';
import {Co2EmissionValueCell} from '../Co2EmissionValueCell';

export const RouteStaticView = (props: {
  item: CalcTimeLineRoute;
  consumptionModes: VesselConsumptionMode[];
  onActivateEditMode: () => void;

  onChangeRoute: (route: Partial<VoyageRoute>) => void;
}) => {
  const {item} = props;
  const route = item.item as VoyageRoute;

  const outputItem = item.outputItem ?? blankVoyageRouteOutput;

  return (
    <LegRow.Wrapper>
      <List.Item.Meta
        title={
          <LegRow.Header>
            <div key={'name'} style={{color: 'var(--color-blue)', fontWeight: 700}} onClick={props.onActivateEditMode}>
              Sea voyage
            </div>
            {route.note !== undefined && route.note.length > 0 && (
              <Tooltip title={route.note}>
                <NoteIcon key={'icon'} />
              </Tooltip>
            )}
            <LegRow.Actions>
              <Button
                size="small"
                type="text"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={props.onActivateEditMode}>
                <Icon type={'edit'} />
                <span className="action-label"> Edit</span>
              </Button>
            </LegRow.Actions>
          </LegRow.Header>
        }
        description={
          <div>
            <Definitions>
              <RouteHeaderRow />
              <ValueRow
                onClick={() => {
                  props.onActivateEditMode();
                }}>
                <Value>
                  <ConsumptionTooltip routeOutput={outputItem} route={route}>
                    <ConsumptionFuelTypeLabel route={route} type={'main'} /> {route.consumption?.mainConsumption || '0'}{' '}
                    mts/d
                  </ConsumptionTooltip>
                </Value>
                <Value>
                  <ConsumptionTooltip routeOutput={outputItem} route={route}>
                    <ConsumptionFuelTypeLabel route={route} type={'aux'} /> {route.consumption?.auxConsumption || '0'}{' '}
                    mts/d{' '}
                  </ConsumptionTooltip>
                </Value>
                <Value>{route.consumption?.speed} knots</Value>
                <Value>{numeral(route.seaMargin).format('0,0[.]00')} %</Value>
                <Value calculated>{numeral(route.distance).format('0,0[.]00')} nm</Value>
                <Value calculated>{numeral(route.distanceInSeca).format('0,0[.]00')} nm</Value>
                <Value calculated>{numeral(route.distanceInPiracy).format('0,0[.]00')} nm</Value>
                <DurationCell route={item} />
                <Co2EmissionValueCell route={route} routeOutput={outputItem} />
                <Value calculated>{getFormattedDateOrNotAvailable(outputItem.startDate)}</Value>
                <Value calculated>{getFormattedDateOrNotAvailable(outputItem.endDate)}</Value>
              </ValueRow>
            </Definitions>
          </div>
        }
      />
    </LegRow.Wrapper>
  );
};
