import dayjs, {Dayjs} from 'dayjs';
import {QueryClient} from '@tanstack/react-query';
import {VoyagePointTerm} from '../../VoyageInformation/VoyagePointTerm';
import {VoyagePointOutput} from '../../VoyageInformation/VoyageTypes';
import {checkIsWorking} from './checkIsWorking';
import {getInitialWaitingTimeBasedOnLaycan} from './getInitialWaitingTimeBasedOnLaycan';

/**
 * Array with 7 entries. 0 is monday and 6 is sunday.
 * Each entry is a day represented by a 2 element array.
 *
 * First element is morning, second is afternoon.
 */
export type TangentialHalvesOfTheDay = {
  list: TangentialList;
  waitingTime: number;
  interWaitingTimeBasedOnTerm: number;
  initalWaitingTime: number;
};

export type TangentialList = [number, number][];

export const calcTangentialHalvesOfTheDayAndWaitingTime = async ({
  totalDuration,
  laycanFrom,
  isFirstLoadingLeg,
  startDate,
  queryClient,
  term,
  countryCode,
}: {
  term: VoyagePointTerm;
  countryCode?: string;
  isFirstLoadingLeg: boolean;
  laycanFrom?: Dayjs;
  queryClient: QueryClient;
  /**
   * in days
   */
  totalDuration: number;
} & Pick<VoyagePointOutput, 'startDate'>): Promise<TangentialHalvesOfTheDay> => {
  let start = dayjs(startDate);

  const initalWaitingTime = getInitialWaitingTimeBasedOnLaycan({
    startDate: start,
    laycanFrom,
    isFirstLoadingLeg,
  });
  start = dayjs(start).add(initalWaitingTime, 'hours');

  start = dayjs(start).set({hours: 0, minutes: 0});

  const dayList = Array(7)
    .fill(0)
    .map((): [number, number] => {
      return [0, 0];
    });

  const slots = Array(Math.ceil(totalDuration * 2)).fill(0);

  let index = 0;
  let interWaitingTimeBasedOnTerm = 0;
  while (index < slots.length) {
    const isMorning = index % 2 === 0;
    const dayHalvesIndex = isMorning ? 0 : 1;
    const dayInArray = start.weekday();
    if (await checkIsWorking({term, isMorning, date: start, countryCode, queryClient})) {
      dayList[dayInArray][dayHalvesIndex] = dayList[dayInArray][dayHalvesIndex] + 1;

      ++index;
    } else {
      interWaitingTimeBasedOnTerm = interWaitingTimeBasedOnTerm + 12;
    }
    start = dayjs(start).add(12, 'hours');
  }

  const waitingTime = interWaitingTimeBasedOnTerm + initalWaitingTime;
  return {list: dayList, waitingTime, interWaitingTimeBasedOnTerm, initalWaitingTime};
};
