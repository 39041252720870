import DetailsSection from '../../../atoms/DetailsSection';
import DetailsColumn from '../../../atoms/DetailsSection/DetailsColumn';
import {Attribute} from '../../../atoms/Attribute/Attribute';

const MultiColAttributesSection = ({columns, label, vessel, tab, size = 4, ...otherProps}: $TSFixMe) => (
  <DetailsSection {...otherProps} title={label}>
    {columns.map((col: $TSFixMe, key: $TSFixMe) => {
      const props = col.props || {};
      return (
        <DetailsColumn {...props} key={`multi-col-${key}`} size={size}>
          {col.content.map((attr: $TSFixMe, key: $TSFixMe) => {
            const props = attr.props || {};
            const value = typeof attr.field === 'function' ? attr.field(vessel) : vessel[attr.field];
            return <Attribute key={`${attr.label}-${key}`} label={attr.label} value={value} tab={tab} {...props} />;
          })}
        </DetailsColumn>
      );
    })}
  </DetailsSection>
);

export default MultiColAttributesSection;
