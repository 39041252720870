import {ReactNode} from 'react';
import {Link} from 'react-router-dom';

export const LinkComponent = ({
  url,
  children,
  isExternalLink,
}: {
  url: string;
  children: ReactNode;
  isExternalLink?: boolean;
}) =>
  isExternalLink ? (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={url}>{children}</Link>
  );
