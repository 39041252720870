import {useMemo} from 'react';
import {Collapse} from '../../../atoms/Collapse/Collapse';
import {TODO} from '../../../utils/TODO';
import styled from 'styled-components';

export const CalculationError = ({
  stations,
  errors = [],
  errorMessage,
}: {
  stations: TODO[];
  errors: TODO[];
  errorMessage?: string;
}) => {
  const routes = useMemo(() => {
    const routes: {from: string; to: string}[] = [];
    for (let i = 0; i < stations.length - 1; i++) {
      routes.push({
        from: stations[i].name,
        to: stations[i + 1].name,
      });
    }
    return routes;
  }, [stations]);

  return (
    <Collapse isOpen={errors.length > 0}>
      <ErrorContainer>
        A route could not be calculated.
        {errors.map(key => {
          if (key === 'serverError' || key === 'serverTimeout') {
            if (errorMessage) {
              return <span key={`error-${key}`}>{errorMessage}.</span>;
            }
            return null;
          } else {
            return (
              <span key={`error-${key}`}>
                From <strong>{routes[key].from}</strong> To <strong>{routes[key].to}</strong>
              </span>
            );
          }
        })}
      </ErrorContainer>
    </Collapse>
  );
};

const ErrorContainer = styled.div`
  margin: 13px auto 0;
  width: calc(100% - 26px);
  border-radius: 3px;
  background-color: var(--color-white);
  color: var(--color-red);
  font-size: var(--font-size-lg);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 99;

  span {
    font-size: var(--font-size-md);
  }
`;
