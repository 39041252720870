/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanyStatisticsRow
 */
export interface CompanyStatisticsRow {
  /**
   *
   * @type {number}
   * @memberof CompanyStatisticsRow
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof CompanyStatisticsRow
   */
  cargo: number;
  /**
   *
   * @type {number}
   * @memberof CompanyStatisticsRow
   */
  vessel: number;
}

export function CompanyStatisticsRowFromJSON(json: any): CompanyStatisticsRow {
  return CompanyStatisticsRowFromJSONTyped(json, false);
}

export function CompanyStatisticsRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyStatisticsRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json['total'],
    cargo: json['cargo'],
    vessel: json['vessel'],
  };
}

export function CompanyStatisticsRowToJSON(value?: CompanyStatisticsRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    cargo: value.cargo,
    vessel: value.vessel,
  };
}
