import {actionTypes} from './ApiService/autocomplete';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {TODO} from '../utils/TODO';

interface PrefillDataEntry {
  slug: string;
  name: string;
}

export interface PreFillState {
  vessels: TODO[];
  cargoes: TODO[];
  data: PrefillDataEntry[];
  page: number;
  pageSize: number;
  loading: boolean;
  error: boolean;
}

const initialState: PreFillState = {
  vessels: [],
  cargoes: [],
  data: [],
  page: 1,
  pageSize: 10,
  loading: false,
  error: false,
};

export const preFillReducer = (state = initialState, action: ActionWithPayload): PreFillState => {
  switch (action.type) {
    case actionTypes.preFillSearch.PENDING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.preFillFixtureCargoes.SUCCESS: {
      return {
        ...state,
        cargoes: action.payload,
      };
    }
    case actionTypes.preFillFixtureVessels.SUCCESS: {
      return {
        ...state,
        vessels: action.payload,
      };
    }
    case actionTypes.preFillSearch.SUCCESS: {
      let data: TODO[] = action.payload;
      if (action.payload?.data?.items.find((p: TODO) => p._source)) {
        data = action.payload.data?.items.map((p: TODO) => p._source);
      }
      if (action.payload?.data?.items) {
        data = action.payload.data.items.map((i: TODO) => i);
      }
      const isVessel =
        data.find(i => i.indexName === 'seabo_vessels') ||
        data.find(i => i.options?.find((j: TODO) => j.indexName === 'seabo_vessels'));
      const isCargo =
        data.find(i => i.indexName === 'seabo_cargoes') ||
        data.find(i => i.options?.find((j: TODO) => j.indexName === 'seabo_cargoes'));
      return {
        ...state,
        vessels: isVessel ? data : state.vessels,
        cargoes: isCargo ? data : state.cargoes,
        data: !isVessel && !isCargo ? data : state.data,
        page: action.payload.page || state.page,
        pageSize: action.payload.pageSize || state.pageSize,
        loading: false,
        error: false,
      };
    }
    case actionTypes.preFillSearch.ERROR:
      return {
        ...state,
        error: true,
        vessels: [],
        cargoes: [],
        data: [],
      };
    default:
      return state;
  }
};
