import {FC, useState} from 'react';
import {ExportFixturesModal} from './ExportFixturesModal/ExportFixturesModal';
import {ModalActions} from '../../redux/Modal';
import {FixtureGrid} from '../../components/Tools/FixtureGrid/FixtureGrid';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {invalidateFixturesQuery, useFixturesQuery} from '../../components/Tools/FixtureGrid/useFixturesQuery';
import {FixtureDataActions, GridState} from '../../redux/FixtureData';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {FixtureFilters} from './FixtureFilters';
import {HeroFilterMapCollapse} from '../../components/HeroFilterMapCollapse/HeroFilterMapCollapse';
import {FeaturePermission} from '../../api/symfony/generated';
import {getWorkspaceId} from '../../redux/selectors';
import styled from 'styled-components';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {PremiumFeatureProtection} from '../../components/PremiumFeatureProtection/PremiumFeatureProtection';
import {useQueryClient} from '@tanstack/react-query';
import ManageFixtureModal from '../../components/Modal/ManageFixture/ManageFixtureModal';
import {Space} from 'antd';
import {VesselFixtureTourDefinition} from './VesselFixtureTourDefintion';
import {VesselFixturesFilterBranchDefinitions} from '../../components/FilterProvider/Filters/Fixtures/VesselFixturesFilterBranchDefinitions';

export const VesselFixtureBody: FC<{
  filterProviderApi: FilterProviderApi<typeof VesselFixturesFilterBranchDefinitions>;
}> = ({filterProviderApi}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [exportFixturesModalVisible, setExportFixturesModalVisible] = useState(false);

  const filter = useSelector(state => state.fixtureData.filterState.filter);
  const workspaceId = useSelector(getWorkspaceId);
  const gridState = useSelector(state => state.fixtureData.gridState);
  const setGridState = (payload: GridState) => dispatch(FixtureDataActions.setGridState(payload));
  const appliedFilters = useSelector(state => state.fixtureData.filterState.filterProvider?.appliedFilters);

  const fixturesQuery = useFixturesQuery({
    workspaceId,
    gridState,
    filter,
  });

  const exportButtonIsDisabled = !fixturesQuery.isSuccess || fixturesQuery.data.totalItems === 0;

  return (
    <div className="fixtures" data-testid="VesselFixtureScreen">
      <ExportFixturesModal
        visible={exportFixturesModalVisible}
        onClose={() => setExportFixturesModalVisible(false)}
        filter={filter || {}}
        fixturesTotal={fixturesQuery.data?.totalItems}
      />
      <ScreenHeader
        helmetTitle="Fixtures"
        breadcrumbs={[{title: 'Tools'}, {title: 'Fixtures'}]}
        tourDefinition={VesselFixtureTourDefinition}
        actions={
          <Space size={4}>
            <PremiumFeatureProtection permission={FeaturePermission.ExportFromFixtures}>
              <ScreenHeader.Actions.Button
                data-cy="FixturesExportBTN"
                disabled={exportButtonIsDisabled}
                onClick={() => setExportFixturesModalVisible(true)}>
                Export
              </ScreenHeader.Actions.Button>
            </PremiumFeatureProtection>
            <ScreenHeader.Actions.Button
              id="newFixture"
              data-cy="FixturesNewBTN"
              onClick={() =>
                dispatch(
                  ModalActions.show(
                    <ManageFixtureModal
                      callback={() => {
                        void invalidateFixturesQuery(queryClient);
                      }}
                    />
                  )
                )
              }>
              New fixture
            </ScreenHeader.Actions.Button>
          </Space>
        }
        features={
          filterProviderApi.isAnyFilterApplied && (
            <ScreenHeader.Features.Button data-cy="FixturesResetFiltersBTN" onClick={filterProviderApi.onResetAllClick}>
              Reset filters
            </ScreenHeader.Features.Button>
          )
        }
      />
      <HeroFilterMapCollapse filters={<FixtureFilters filterProviderApi={filterProviderApi} />} />
      <StyledFixtureGrid
        fixtureQuery={fixturesQuery}
        setGridState={setGridState}
        appliedFilters={appliedFilters}
        fetching={fixturesQuery.isFetching}
        gridState={gridState}
      />
    </div>
  );
};

const StyledFixtureGrid = styled(FixtureGrid)`
  margin-top: 20px;
`;
