import {cargoAccessor} from '../../utils/cargoAccessorResult';
import CargoUtil from '../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';

import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {ColumnHeader} from '../../../DataGrid/ColumnHeader/ColumnHeader';
import {AccessorFn} from '@tanstack/react-table';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';

const commissionAccessor = (type: $TSFixMe): AccessorFn<CargoGridColumnsProps> =>
  cargoAccessor(
    (d: CargoGridColumnsProps) => (
      <div className="market-grid-cell-content__commission" data-cy="cell-commission">
        {CargoUtil.formatCommissionTotal(d)}
      </div>
    ),
    type
  );
export const commissionColumn = ({
  type,
  minWidth = 4,
}: {
  type: $TSFixMe;
  minWidth?: number;
}): CustomColumnDef<CargoGridColumnsProps> => ({
  header: () => (
    <ColumnHeader sortable data-cy="header-commission">
      Comm.
    </ColumnHeader>
  ),
  id: 'commissionTotal',
  enableSorting: true,
  accessorFn: commissionAccessor(type),
  cell: info => info.getValue(),
  sortingFn: (a, b) => {
    return (a.original.cargo.commissionTotal || 0) - (b.original.cargo.commissionTotal || 0);
  },
  minWidth,
  minSize: minWidth,
});
