import {useQuery} from '@tanstack/react-query';
import {portCongestionApi} from '../../../../api/node-backend/nodeBackendApis';

export enum PortKey {
  LosAngeles = 'los-angeles',
  PortHedland = 'port-hedland',
  Rotterdam = 'rotterdam',
  Shanghai = 'shanghai',
  Santos = 'santos',
  Konstanza = 'konstanza',
  NewYork = 'new-york',
}

export const usePortCongestionQuery = (port: PortKey) =>
  useQuery({
    queryKey: ['portCongestion', port],
    queryFn: async () =>
      await portCongestionApi.portCongestionGetAreaStatistics({
        port,
      }),
  });
