import {Col, Form, FormInstance, Input, Row, Select} from 'antd';
import {FC} from 'react';
import {InputNumberAcceptCommaAndDot} from '../../../../../antd/components/InputNumberAcceptCommaAndDot';
import {LocationsPickerPopover} from '../../../../../components/LocationPicker/LocationsPickerPopover';
import {useCommodityCategoriesQuery} from '../../../../../queries/useCommodityCategoriesQuery';
import {MarketCargoOffer} from '../../../MarketCargo/MarketCargoOffer';
import {DurationFormItem} from './DurationFormItem';
import {SizeFormItem} from './SizeFormItem';
import {SmartInboxRangePicker} from '../shared/FormDatePicker';

export const CargoDryBulkFormItems: FC<{
  form: FormInstance;
  cargoOffer: MarketCargoOffer;
}> = ({form, cargoOffer}) => {
  const commoditiesQuery = useCommodityCategoriesQuery();
  const isVC = cargoOffer.cargo.contractType === 'vc';

  return (
    <Row data-testid={'CargoBulkerFormItems'} gutter={[8, 8]}>
      <Col span={12}>
        <Form.Item label={'Charterer'} name={'chartererName'}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <SizeFormItem cargoOffer={cargoOffer} />
      </Col>
      <Col span={12}>
        <Form.Item label={'Commodity'} name={'commodityCategory'}>
          <Select
            options={commoditiesQuery.data?.items.map(comm => {
              return {value: comm.slug, label: comm.name};
            })}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <DurationFormItem cargoOffer={cargoOffer} />
      </Col>
      <Col span={12}>
        <Form.Item label={'Laycan'} name={'laycan'}>
          <SmartInboxRangePicker />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          rules={[
            {min: 0, type: 'number', message: 'Commission cannot be less than 0.'},
            {
              max: 99.99,
              type: 'number',
              message: 'Commission cannot be more than 99.99.',
            },
          ]}
          label={'Commisson'}
          name={'commission'}>
          <InputNumberAcceptCommaAndDot />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={isVC ? 'Load' : 'Delivery'} name={'loadLocations'} style={{marginBottom: 4}}>
          <LocationsPickerPopover
            value={form.getFieldValue('loadLocations')}
            onSelect={locations => {
              form.setFieldsValue({
                loadLocations: locations,
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={isVC ? 'Discharge' : 'Redelivery'} name={'dischargeLocations'} style={{marginBottom: 4}}>
          <LocationsPickerPopover
            value={form.getFieldValue('dischargeLocations')}
            onSelect={locations => {
              form.setFieldsValue({
                dischargeLocations: locations,
              });
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
