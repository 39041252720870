import {useLocation} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {Assumptions, MulticalcCargoInput, MulticalcVesselInput, MultiCharterCalculationState, Project} from '../types';
import uniq from 'lodash/uniq';
import {blankState, DEFAULT_SEA_MARGIN} from '../../Voyagecharter/Context/blankState';
import {getDistanceCalcOptionsFromURLParams} from './URLParamsAssumptionDistanceCalcConnection';
import {fetchJSON} from '../../../../api/utils/fetchJSON';

const queryKeyProjects = 'useMultiCalcStateFromLocationProjects';
const queryKeyVessels = 'useMultiCalcStateFromLocationVessels';
const queryKeyCargoes = 'useMultiCalcStateFromLocationCargoes';

const getAssumptions = (urlParams: URLSearchParams): Assumptions => {
  const seaMargin = parseFloat(urlParams.get('seaMargin') ?? DEFAULT_SEA_MARGIN.toString());
  const costIfoPerMts = parseFloat(urlParams.get('costIfoPerMts') ?? '275');
  const costMgoPerMts = parseFloat(urlParams.get('costMgoPerMts') ?? '340');
  const costMdoPerMts = parseFloat(urlParams.get('costMdoPerMts') ?? '340');
  const portCosts = parseFloat(urlParams.get('portCosts') ?? '0');

  return {
    costIfoPerMts,
    costMgoPerMts,
    costMdoPerMts,
    portCosts,
    seaMargin,
    distanceCalculator: {
      ...blankState.inputState.calculator.distanceCalculator,
      ...getDistanceCalcOptionsFromURLParams(urlParams),
    },
  };
};

export type MultiCalcStateFromLocationLoading = {
  isLoading: true;
  data: undefined;
};
export type MultiCalcStateFromLocationFinished = {
  isLoading: false;
  data: MultiCharterCalculationState;
};

export type MultiCalcStateFromLocation = MultiCalcStateFromLocationLoading | MultiCalcStateFromLocationFinished;

export const useMultiCalcStateFromLocation = (): MultiCalcStateFromLocation => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const assumptions = useMemo(() => {
    return getAssumptions(urlParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const projectParam = urlParams.get('projectIds') ?? '';
  const projectIds = projectParam === '' ? [] : projectParam.split(',');
  if (projectIds.length !== uniq(projectIds).length) {
    throw new Error('ProjectIds includes duplicates');
  }
  const projectQuery = useQuery({
    queryKey: [queryKeyProjects, projectIds],
    queryFn: async () => {
      const projectsPromises: Promise<Project>[] = projectIds.map(id => fetchJSON(` /api/projects/${id}`));
      return await Promise.all(projectsPromises);
    },
  });

  const vesselParam = urlParams.get('vesselIds') ?? '';
  const vesselIds = vesselParam === '' ? [] : vesselParam.split(',');
  if (vesselIds.length !== uniq(vesselIds).length) {
    throw new Error('VesselIds includes duplicates');
  }
  const vesselQuery = useQuery({
    queryKey: [queryKeyVessels, vesselIds],
    queryFn: async () => {
      const vesselPromises: Promise<MulticalcVesselInput>[] = vesselIds.map(id =>
        fetchJSON(`/api/vessels/${id}`).then(json => {
          return {
            vessel: json,
          };
        })
      );

      const vessels = await Promise.all(vesselPromises);

      const bulkerVessels = vessels.filter(vessel => vessel.vessel.vesselType === 'bulker');
      return bulkerVessels;
    },
  });

  const cargoParam = urlParams.get('cargoIds') ?? '';
  const cargoIds = cargoParam === '' ? [] : cargoParam.split(',');
  if (cargoIds.length !== uniq(cargoIds).length) {
    throw new Error('CargoIds includes duplicates');
  }
  const cargoQuery = useQuery({
    queryKey: [queryKeyCargoes, cargoIds],

    queryFn: async () => {
      const cargoesPromises: Promise<MulticalcCargoInput>[] = cargoIds.map(id =>
        fetchJSON(`/api/cargoes/${id}`).then(json => {
          return {cargo: json};
        })
      );
      const cargoes = await Promise.all(cargoesPromises);

      const drybulkCargoes = cargoes.filter(cargo => cargo.cargo.cargoType === 'drybulk');
      return drybulkCargoes;
    },
  });

  const [vessels, cargoes] = useMemo(() => {
    const projects = [...(projectQuery.data ?? [])];

    const cargoes: MulticalcCargoInput[] = [...(cargoQuery.data ?? [])];
    const vessels: MulticalcVesselInput[] = [...(vesselQuery.data ?? [])];
    projects.forEach(project => {
      if (project.type === 'vessel') {
        vessels.push({
          vessel: project.vessel,
          project: project,
        });
      }
      if (project.type === 'cargo') {
        cargoes.push({
          cargo: project.cargo,
          project,
        });
      }
    });
    return [vessels, cargoes];
  }, [cargoQuery.data, vesselQuery.data, projectQuery.data]);

  const multiCalc: MultiCalcStateFromLocation = useMemo(() => {
    return {
      isLoading: false,
      data: {
        assumptions,
        cargoes,
        vessels,
      },
    };
  }, [cargoes, vessels, assumptions]);

  if (!cargoQuery.isSuccess || !vesselQuery.isSuccess || !projectQuery.isSuccess) {
    return {
      data: undefined,
      isLoading: true,
    };
  }

  return multiCalc;
};
