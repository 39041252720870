import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ActionValidationViolation} from '../../api/symfony/generated';
import {ApiError} from '../../api/utils/ApiError';
import {handleError} from '../../api/utils/reactQueryClient';
import ProjectService from '../../redux/ApiService/ProjectService';
import {useDispatch} from '../../redux/react-redux';
import {showNotification} from '../../utils/notification';

export const useDeleteProjectMutation = (options: UseMutationOptions<void, ApiError, number>) => {
  const dispatch = useDispatch();
  const extendedOptions: UseMutationOptions<void, ApiError, number> = {
    ...options,
    onError(error: {
      status: number;
      payload: {
        violations: ActionValidationViolation[];
      };
    }) {
      if (error.status === 422) {
        showNotification(
          `${error.payload.violations[0].message}. ${error.payload.violations[0].possibleSolution}`,
          'error',
          5
        );
      } else {
        handleError(error);
      }
    },
  };

  return useMutation({
    mutationFn: (id: number) => dispatch(ProjectService.deleteProject(id)),
    ...extendedOptions,
  });
};
