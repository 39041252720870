import {VesselConsumptionMode, VesselConsumptionModeUseCase} from '../VesselInformation/VesselTypes';
import {getVoyageConsumption, transformVesselConsumptionToVoyageConsumption} from './getVoyageConsumption';
import {filterVesselConsumptionModes} from './filterVesselConsumptionModes';

export const getDefaultRouteConsumption = (props: {
  consumptionModes: VesselConsumptionMode[];
  useCase: VesselConsumptionModeUseCase;
  inSeca: boolean;
}) => {
  if (!props.inSeca) {
    return getVoyageConsumption({
      consumptionModes: props.consumptionModes,
      useCase: props.useCase,
    });
  }

  const consumptionsForSeca = [
    ...filterVesselConsumptionModes({
      consumptionModes: props.consumptionModes,
      useCase: props.useCase,
      mainFuelType: 'mdo',
    }),
    ...filterVesselConsumptionModes({
      consumptionModes: props.consumptionModes,
      useCase: props.useCase,
      mainFuelType: 'mgo',
    }),
  ];

  if (consumptionsForSeca.length > 0) {
    return transformVesselConsumptionToVoyageConsumption(consumptionsForSeca[0]);
  }

  return getVoyageConsumption({
    consumptionModes: props.consumptionModes,
    useCase: props.useCase,
  });
};
