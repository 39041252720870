import {FC, useMemo} from 'react';
import {HeroFilterMapCollapse} from '../../components/HeroFilterMapCollapse/HeroFilterMapCollapse';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {TabListLayout} from '../../components/TabList/TabList';
import {
  VesselWithVesselType,
  selectVesselGridLayout,
} from '../../components/VesselGridLayoutSelect/selectVesselGridLayout';
import {VesselGridLayoutSelect} from '../../components/VesselGridLayoutSelect/VesselGridLayoutSelect';
import {VesselMap} from '../../components/VesselMap/VesselMap';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {VesselDatabaseActions} from '../../redux/VesselDatabase';
import {FilterControls} from './FilterControls';
import {invalidateListVesselsQuery, useListVesselsQuery} from './queries/useListVesselsQuery';
import {VesselDatabaseClipboardButton} from './VesselDatabaseClipboardButton';
import {VesselDatabaseFilterList} from './VesselDatabaseFilterList';
import {VesselDatabaseFilters} from './VesselDatabaseFilters';
import {VesselDatabaseGrid} from './VesselDatabaseGrid/VesselDatabaseGrid';
import {keepPreviousData, useQueryClient} from '@tanstack/react-query';
import {useVesselDatabaseFilterProvider} from './useVesselDatabaseFilterProvider';
import {MapControlButton} from '../../components/SeaboMap/MapContext/Types';
import {makePartialMapSwitches} from '../../components/SeaboMap/utils/makePartialMapSwitches';
import {DEFAULT_MAP_STYLE} from '../../components/SeaboMap/const';
import {VesselDatabaseTourDefinition} from './VesselDatabaseTourDefinition';

export const VesselDatabaseListScreen: FC = () => {
  const {pageSize, pageIndex, sortingRule, userSelectedLayoutMode, filterSettings, isFilterUiOpen} = useSelector(
    state => state.vesselDatabase
  );

  const dispatch = useDispatch();

  const vesselsQuery = useListVesselsQuery(
    {
      pageIndex,
      pageSize,
      sortingRule,
      filterSettings,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const vessels = vesselsQuery.isSuccess ? vesselsQuery.data.items : null;

  const displayedLayout = useMemo(
    () =>
      selectVesselGridLayout({
        userSelectedLayoutMode,
        vessels: vessels as VesselWithVesselType[] | null,
      }),
    [userSelectedLayoutMode, vessels]
  );

  const queryClient = useQueryClient();
  const onReloadGrid = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    invalidateListVesselsQuery(queryClient, {
      pageIndex,
      pageSize,
      sortingRule,
      filterSettings,
    });
  };

  const filterProviderApi = useVesselDatabaseFilterProvider();

  return (
    <div className="vessel-database" data-testid="VesselDatabaseBody">
      <ScreenHeader
        helmetTitle="Vessel Database"
        breadcrumbs={[{title: 'Databases'}, {title: 'Vessels'}]}
        tourDefinition={VesselDatabaseTourDefinition}
        features={<FilterControls filterProviderApi={filterProviderApi} />}
      />
      <HeroFilterMapCollapse
        viewOpen={isFilterUiOpen ? 'filters' : 'map'}
        filters={<VesselDatabaseFilters filterProviderApi={filterProviderApi} />}
        map={
          <VesselMap
            settingIdentifier={'vessel_database'}
            mapControlButtonsToHide={[MapControlButton.CARGOES, MapControlButton.VESSELS]}
            mapControlMenuSectionsToHide={['vessel_portfolio', 'vessel_market', 'vessel_on']}
            initialMapSettings={{
              switches: {
                ...makePartialMapSwitches({
                  ports: true,
                  tradingArea: false,
                  tradingAreaNames: false,
                  highRiskAreas: true,
                  emissionControlAreas: true,
                }),

                // eslint-disable-next-line camelcase
                vessel_ais_sub: {
                  bulker: true,
                  tanker: false,
                  container: false,
                  mpp: false,
                  vState: false,
                  other: false,
                  vDisabled: false,
                  state: false,
                },
              },
              mapStyle: DEFAULT_MAP_STYLE,
              openWeatherMapLayer: 'precipitation_new',
            }}
            vessels={vessels}
          />
        }
      />
      <TabListLayout>
        <VesselDatabaseFilterList filterProviderApi={filterProviderApi} />
        <VesselGridLayoutSelect
          displayedLayoutMode={displayedLayout}
          userSelectedLayoutMode={userSelectedLayoutMode}
          onChangeUserSelectedLayoutMode={mode => {
            dispatch(VesselDatabaseActions.setUserSelectedLayoutMode(mode));
          }}
        />
      </TabListLayout>
      <VesselDatabaseGrid
        loading={vesselsQuery.isLoading}
        vessels={vessels}
        vesselsTotal={vesselsQuery.data?.totalItems ?? 0}
        displayedLayout={displayedLayout}
      />
      <VesselDatabaseClipboardButton onReloadGrid={onReloadGrid} />
    </div>
  );
};
