import {CharterCalculationState} from '../../../../redux/CharterCalculation';
import {OutputState} from '../utils/voyageChartercalculation/voyageChartercalculation';
import {RemainingOnBoard} from '../VesselInformation/VesselTypes';
import {DefaultStowageFactorDisplayUnit} from '../utils/CargoTypes';

export const defaultRemainingOnBoards: RemainingOnBoard[] = [
  {bunkerType: 'ifo', quantity: undefined, price: undefined},
  {bunkerType: 'mgo', quantity: undefined, price: undefined},
  {bunkerType: 'vlsfo', quantity: undefined, price: undefined},
];

export const blankState: CharterCalculationState = {
  metaState: {
    savedLastChanges: false,
    calculation: {
      id: null,
      version: 2,
    },
  },
  inputState: {
    calculator: {
      isFavorite: false,
      distanceCalculator: {
        allowKielCanal: false,
        allowPanamaCanal: true,
        allowSuezCanal: true,
        avoidSECA: false,
        avoidPiracyAreas: false,
      },
    },
    vessel: {
      consumptionModes: [],
      name: undefined,
      dwat: undefined,
      capacity: undefined,
      countryCode: undefined,
      openDate: undefined,
      id: undefined,
      isScrubberFitted: false,
      imo: undefined,
      nextOpenPoint: undefined,
      remainingOnBoards: defaultRemainingOnBoards,
    },
    voyage: {
      points: [],
      routes: [],
      routesCalculated: false,
    },

    cost: {
      costMdoPerMts: 1300,
      costIfoPerMts: 700,
      costVlsfoPerMts: 1000,
      costMgoPerMts: 1300,
      otherCosts: [],
    },
    cargo: {
      stowageFactorDisplayUnit: DefaultStowageFactorDisplayUnit,
      cargoProduct: '',
      cargoQuantity: undefined,
      totalCommission: undefined,
      stowageFactor: undefined,
      freightIdea: 0,
      freightUnit: 'perMts',
    },
  },
};

export const blankOutputState: OutputState = {
  voyage: {
    points: [],
    routes: [],
    cO2Emission: 0,
    consumptionTransactions: [],
  },
  warning: {
    list: [],
  },
  cargo: {
    revenueNet: 0,
    duration: 0,
    tce: 0,
    revenueTotal: 0,
  },
  cost: {
    costCo2: 0,
    costConsumption: 0,
    sumPortCosts: 0,
    otherCostsSum: 0,
    costCommission: 0,
  },
};

export const DEFAULT_SEA_MARGIN = 7;
