import User from '../../model/User';
import {useUser} from './useUser';

interface Props {
  children: (user: User) => JSX.Element | string | null;
}

/**
 * @deprecated
 */
export const UserConsumer = ({children}: Props): JSX.Element | null => {
  const user: User | null = useUser();
  return <>{children(user)}</>;
};
