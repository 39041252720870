/* tslint:disable */
/* eslint-disable */
/**
 * BunkerService
 * BunkerService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {string}
   * @memberof Location
   */
  type?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof Location
   */
  coordinates?: Array<number>;
}

export function LocationFromJSON(json: any): Location {
  return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: !exists(json, 'type') ? undefined : json['type'],
    coordinates: !exists(json, 'coordinates') ? undefined : json['coordinates'],
  };
}

export function LocationToJSON(value?: Location | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    coordinates: value.coordinates,
  };
}
