import {ViewState} from '../../ViewState';
import {useEcaLayer} from '../useEcaLayer';
import {MapElement} from '../../MapDetails/utils/types';
import {usePortfolioVesselLayer} from '../usePortfolioVesselLayer';
import {useSelectedLayer} from '../useSelectedLayer';
import {useMapContext} from '../../MapContext/MapContext';
import {useWatermarkLayer} from '../WatermarkLayer';
import {useVesselRouteLayer} from '../VesselRouteLayer/VesselRouteLayer';
import {AisVessel} from '../../../../api/symfony/generated';
import {useVesselLayer} from '../useVesselLayer/useVesselLayer';
import {TODO} from '../../../../utils/TODO';

type Layer = TODO;

type Props = {
  children: (layers: Layer[]) => React.ReactElement;
  viewState: ViewState;
  layerFromOutside?: Layer[];
  promoLayerEnabled?: boolean;
  searchElement: MapElement | null;
  selectedElement: MapElement | null;
  onSelectElement: (item: MapElement) => void;
};
export const LayerProvider: React.FC<Props> = props => {
  const mapContext = useMapContext();

  const switches = mapContext.state.switches;

  const watermarkLayer = useWatermarkLayer();
  const selectedLayer = useSelectedLayer({
    items: mapContext.state.selectedItems.items.map(item => ({coordinates: item.object.coordinates})),
    color: [255, 0, 0, 140],
  });
  const vesselLayer = useVesselLayer({
    switches: switches,
    onSelectElement: props.onSelectElement,
    searchElement: props.searchElement,
  });
  const ecaLayer = useEcaLayer({visible: switches.layer_eca.state ?? false});
  const portfolioVesselLayer = usePortfolioVesselLayer({
    switches: switches,
    onSelectElement: props.onSelectElement,
  });

  const vesselRouteLayer = useVesselRouteLayer({
    zoom: mapContext.state.viewState.zoom,
    imo: (props.selectedElement?.object as AisVessel)?.imo,
  });

  const layers: Layer[] = [];

  if (props.promoLayerEnabled) {
    layers.push(watermarkLayer);
  }

  layers.push(...[vesselRouteLayer, selectedLayer, vesselLayer, ecaLayer, portfolioVesselLayer]);

  if (props.layerFromOutside) {
    layers.push(props.layerFromOutside);
  }

  return props.children(layers);
};
