import {FC} from 'react';
import DetailTable from '../../../components/DetailTable';
import DetailTableRow from '../../../components/DetailTable/DetailTableRow';
import DetailsTableCell from '../../../components/DetailTable/DetailTableCell';
import DetailTableBody from '../../../components/DetailTable/DetailTableBody';
import AttributeIcon from '../../../atoms/Attribute/AttributeIcon';
import {intakeTypes} from '../../CargoVesselForm/utils/IntakeSection/IntakeTypes';
import {numberFormat} from '../../../utils/formatter';
import {IntakeType} from '../../../redux/Portfolio';

interface Props {
  intakes?: Array<{
    type: IntakeType;
    quantity: number;
    isEmpty: boolean;
    isHighCube: boolean;
  }>;
  noText?: boolean;
  type?: 'vessel' | 'cargo';
}

export const IntakeGrid: FC<Props> = ({intakes = [], noText = false, type = 'vessel'}) => {
  if (intakes.length === 0) {
    if (!noText) {
      return <span className="vessel-content__text">N/A</span>;
    } else {
      return null;
    }
  }
  const withIsEmptyColumn = intakes.filter(intake => intake.isEmpty !== undefined).length > 0;
  return (
    <DetailTable fullWidth>
      <DetailTableRow headerWrapper>
        <DetailsTableCell value={'Container type'} />
        <DetailsTableCell value={'Quantity'} />
        <DetailsTableCell value={'High cube'} />
        {withIsEmptyColumn && type !== 'vessel' && <DetailsTableCell value={'Empty'} />}
      </DetailTableRow>
      <DetailTableBody>
        {intakes.map((intake, idx) => (
          <DetailTableRow key={idx}>
            <DetailsTableCell value={intakeTypes[intake.type]} />
            <DetailsTableCell value={numberFormat(intake.quantity)} />
            <DetailsTableCell value={<AttributeIcon check={intake.isHighCube} />} />
            {withIsEmptyColumn && type !== 'vessel' && (
              <DetailsTableCell value={<AttributeIcon check={intake.isEmpty} />} />
            )}
          </DetailTableRow>
        ))}
      </DetailTableBody>
    </DetailTable>
  );
};

export default IntakeGrid;
