import React, {useState} from 'react';
import {Divider, Form, FormInstance} from 'antd';
import {assert} from '../../../../utils/assert';
import {FormValues} from './FormValues';
import {SubmitAction, SubmitButtons} from './sections/SubmitButtons';
import {CommentSection} from './sections/CommentSection';
import {FixtureFromListRequest} from './FixtureFromListRequestSchema/FixtureFromListRequestSchema';
import {UpdateVesselInMyFleetSection} from './sections/UpdateVesselInMyFleetSection';
import {GeneralSection} from './sections/GeneralSection/GeneralSection';
import {StationsSection} from './sections/StationsSection/StationsSection';
import {OptionsSection} from './sections/OptionsSection/OptionsSection';
import styled from 'styled-components';
import {HiddenFields} from './sections/HiddenFields';
import {CurrentCommercialDetails} from './sections/CurrentCommercialDetails';
import {ValidateErrorEntity} from 'rc-field-form/lib/interface';
import {DefaultFormValues} from './DefaultFormValues';
import {preventDefaultOnEnterPress} from '../../../../utils/preventDefaultOnEnterPress';

interface Props {
  form: FormInstance<FormValues>;
  fixtureFromListRequest: FixtureFromListRequest | null;
  isVesselEdit: boolean;
  readOnly: boolean;
  gridUpdate: boolean;
  submitting: boolean;
  onSubmit: (formValues: FormValues, submitAction: SubmitAction) => void;
  onSubmitFailed: (errorInfo: ValidateErrorEntity<FormValues>) => void;
  updateVessel: boolean;
}

export const ManageFixtureForm = (props: Props) => {
  const {
    form,
    fixtureFromListRequest = null,
    isVesselEdit,
    updateVessel,
    readOnly,
    gridUpdate,
    submitting,
    onSubmit,
    onSubmitFailed,
  } = props;
  const isEdit = !!fixtureFromListRequest?.id;
  const initialValues =
    isEdit && fixtureFromListRequest ? {...fixtureFromListRequest, updateVessel} : {...DefaultFormValues, updateVessel};
  const [submitAction, setSubmitAction] = useState<SubmitAction | null>(null);

  const onFinish = (values: FormValues) => {
    assert(submitAction, 'submitAction is null');
    onSubmit(values, submitAction);
  };

  return (
    <>
      <Form
        name="ManageFixtureForm"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        autoComplete="off"
        data-cy="ManageFixtureForm"
        onFinish={onFinish}
        onFinishFailed={onSubmitFailed}
        {...preventDefaultOnEnterPress()}>
        <HiddenFields />
        <CurrentCommercialDetails form={form} />
        <Divider />
        <GeneralSection form={form} />
        <DividerWithoutTopMargin />
        <StationsSection />
        <DividerWithoutTopMargin />
        <OptionsSection />
        <DividerWithoutTopMargin />
        <CommentSection form={form} />
        <DividerWithoutTopMargin />
        <UpdateVesselInMyFleetSection form={form} />
        {!readOnly && (
          <SubmitButtons
            submitting={submitting}
            gridUpdate={gridUpdate}
            isVesselEdit={isVesselEdit}
            isEdit={isEdit}
            onSubmitActionUpdate={setSubmitAction}
          />
        )}
      </Form>
    </>
  );
};

const DividerWithoutTopMargin = styled(Divider)`
  margin-top: 0;
`;
