import React, {Fragment, useEffect, FC} from 'react';
import {Button} from '../../../../atoms/Button/Button';
import Icon from '../../../../atoms/Icon';
import {ConsumptionWrapper} from './ConsumptionWrapper';
import {Consumption, ConsumptionRows, newConsumption} from './ConsumptionRows';
import {TODO} from '../../../../utils/TODO';
import {sortAndExtendConsumptionValues} from './sortAndExtendConsumptionValues';

const ConsumptionSection = (props: $TSFixMe) => {
  const isContainer =
    props.form.general && props.form.general.vesselType && props.form.general.vesselType === 'container';

  return (
    <ConsumptionWrapper {...props}>
      {({onChange, values, validations, form}: $TSFixMe) => (
        <ConsumptionContent
          isContainer={isContainer}
          onChange={(values: Consumption[]) => onChange('consumptions', values)}
          values={values}
          validations={validations}
          form={form}
        />
      )}
    </ConsumptionWrapper>
  );
};

const ConsumptionContent: FC<{
  onChange: (consumptions: Consumption[]) => void;
  values: {consumptions: Consumption[]};
  validations: TODO;
  form: TODO;
  isContainer: boolean;
}> = ({onChange, validations, values, isContainer, form}) => {
  useEffect(() => {
    const sortedAndDefaultValues = sortAndExtendConsumptionValues(values.consumptions);

    onChange(sortedAndDefaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      {form.general && form.general.vesselType && (
        <ConsumptionRows
          consumptions={values.consumptions}
          vesselType={form.general.vesselType}
          onChange={(values: $TSFixMe) => onChange(values)}
          validation={validations.consumptions}
          isContainer={isContainer}
        />
      )}
      <div className={'row'}>
        <div className={'scol-12 cargo-vessel-form__icon-button'}>
          <Button
            id={'form-consumptions-consumption-add'}
            icon
            label={
              <Fragment>
                <Icon
                  type={'item-add-selection-solid'}
                  color="blue"
                  style={{marginRight: 6, verticalAlign: 'bottom'}}
                />
                {values.consumptions.length === 0 ? 'ADD CONSUMPTION' : 'ADD ANOTHER CONSUMPTION'}
              </Fragment>
            }
            onClick={() => {
              onChange([
                ...values.consumptions,
                isContainer ? {...newConsumption, type: 'designdraft'} : newConsumption,
              ]);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConsumptionSection;
