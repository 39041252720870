import {FC} from 'react';
import styled from 'styled-components';
import ActionMenuButton from '../../atoms/ActionMenuButton/ActionMenuButton';
import Icon from '../../atoms/Icon';
import {MenuItem} from '../../atoms/Menu/MenuItem';
import {VesselGridLayoutMode} from '../../redux/Portfolio';
import {assertUnreachable} from '../../utils/assert';

export const VesselGridLayoutSelect: FC<{
  userSelectedLayoutMode: VesselGridLayoutMode;
  displayedLayoutMode: VesselGridLayoutMode;
  onChangeUserSelectedLayoutMode: (mode: VesselGridLayoutMode) => void;
}> = ({displayedLayoutMode, userSelectedLayoutMode, onChangeUserSelectedLayoutMode}) => {
  const gridLayoutModeLabel =
    userSelectedLayoutMode === 'auto'
      ? 'Auto (' + getVesselGridLayoutModeLabel(displayedLayoutMode) + ')'
      : getVesselGridLayoutModeLabel(userSelectedLayoutMode);
  return (
    <StyledVesselGridLayoutSelect>
      <LayoutSwitchButton
        position={'right'}
        label={
          <>
            Grid layout:
            <br />
            <b>{gridLayoutModeLabel}</b>
          </>
        }
        styleOverride={{}}
        actionIcon={<Icon type={'caret-down'} />}
        items={[
          {
            key: 'auto',
            label: <MenuItem label={'Automatic'} onClick={() => onChangeUserSelectedLayoutMode('auto')} />,
          },
          {
            key: 'container',
            label: <MenuItem label={'Container'} onClick={() => onChangeUserSelectedLayoutMode('container')} />,
          },
          {
            key: 'bulker',
            label: <MenuItem label={'Bulker'} onClick={() => onChangeUserSelectedLayoutMode('bulker')} />,
          },
          {
            key: 'mpp',
            label: <MenuItem label={'MPP'} onClick={() => onChangeUserSelectedLayoutMode('mpp')} />,
          },
        ]}></LayoutSwitchButton>
    </StyledVesselGridLayoutSelect>
  );
};

const getVesselGridLayoutModeLabel = (layoutMode: VesselGridLayoutMode): string => {
  switch (layoutMode) {
    case 'auto':
      return 'Auto';
    case 'bulker':
      return 'Bulker';
    case 'container':
      return 'Container';
    case 'mpp':
      return 'MPP';
    default:
      assertUnreachable(layoutMode);
  }
};

const StyledVesselGridLayoutSelect = styled.div`
  align-self: flex-end;
  margin: 0 0 0 5px;

  span {
    font-size: var(--font-size-xs);
  }

  .ant-btn {
    border-radius: var(--border-radius-tab) var(--border-radius-tab) 0 0;
  }
`;

const LayoutSwitchButton = styled(ActionMenuButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 2px 10px;
  background: var(--color-white) !important;
  color: var(--color-gray-2) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: normal;
  line-height: 1.1;
  white-space: nowrap;
  text-align: start;
  text-shadow: none;
  border: none;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
`;
