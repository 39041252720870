import {FC} from 'react';
import {Dayjs} from 'dayjs';
import {Button, Form} from 'antd';
import {DateTimePickerFormItem} from '../../../atoms/DateTimePicker';
import {ResultDisplay} from '../../../components/StationsSelector/ResultDisplay';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';

type DistanceCalculatorDateProps = {
  type: 'ETA' | 'ETD';
  result: Dayjs | null;
  timezone?: string;
  showInput: boolean;
  isCalculating: boolean;
  onChange: (date: Dayjs | null) => void;
  onCalculate: () => void;
  onClear?: () => void;
};

export const DistanceCalculatorDate: FC<DistanceCalculatorDateProps> = ({
  type,
  result,
  timezone,
  showInput,
  isCalculating,
  onChange,
  onCalculate,
  onClear,
}) => {
  const width = '100%';

  if (showInput) {
    return (
      <Form.Item name={type} noStyle rules={[{required: true}]}>
        <DateTimePickerFormItem
          style={{width}}
          id={`${type}-dateTimePicker`}
          timezone={timezone}
          onChange={onChange}
          data-testid={`CalculatorInput-${type}`}
        />
      </Form.Item>
    );
  }

  if (result) {
    return (
      <ResultDisplay
        style={{width}}
        value={result.format(DateTimeFormat.DateTime)}
        onClear={onClear}
        data-testid={`CalculatorResult-${type}`}
      />
    );
  }

  return (
    <Button
      type="primary"
      style={{width}}
      onClick={onCalculate}
      disabled={isCalculating}
      data-testid={`CalculatorCalculate-${type}`}>
      Calculat{!isCalculating ? 'e' : 'ing'}
    </Button>
  );
};
