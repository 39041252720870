import LabelWrapper from '../../atoms/LabelWrapper';
import Input, {InputProps} from '../../atoms/Input';
import React, {HTMLInputTypeAttribute} from 'react';

export const ProfileInput = ({
  selectedValue,
  selectedName,
  label,
  labelContentWrapperStyle,
  onChange,
  maxLength,
  children,
  type,
  readOnly,
  min,
  hasError,
}: Pick<InputProps, 'maxLength' | 'hasError' | 'readOnly' | 'min'> & {
  label: string;
  selectedValue?: null | InputProps['value'];
  selectedName: string;
  type?: HTMLInputTypeAttribute;
  labelContentWrapperStyle?: React.CSSProperties;
  onChange?: (key: string, value: string) => void;
  children?: React.ReactNode;
}) => {
  return (
    <LabelWrapper label={label} contentWrapperStyle={labelContentWrapperStyle}>
      {children ?? (
        <Input
          hasError={hasError}
          id={label}
          min={min}
          value={selectedValue !== null ? selectedValue : ''}
          onChange={input => onChange?.(selectedName, input)}
          maxLength={maxLength ? maxLength : 50}
          type={type ?? 'text'}
          readOnly={!!readOnly}
        />
      )}
    </LabelWrapper>
  );
};
