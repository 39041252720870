import classNames from 'classnames';
import Flag from '../Flags';

import './style.scss';
import styled from 'styled-components';

type Props = {
  title?: string;
  country?: string;
  countryCode?: string;
  margin?: number;
  small?: boolean;
  shadow?: boolean;
};

const TitleHeader = ({country, title, countryCode, small, margin = 0, shadow}: Props) => {
  const flagSize = small
    ? {
        width: 20,
        height: 15,
      }
    : {
        width: 35,
        height: 26,
      };

  return (
    <div
      style={{marginBottom: margin}}
      className={classNames('title-header', {
        'title-header--small': small,
      })}>
      <FlexBox>
        <Flag shadow={shadow} countryCode={countryCode} {...flagSize} />
        <Title>{title}</Title>
      </FlexBox>
      <Subtitle>{country}</Subtitle>
    </div>
  );
};

const FlexBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: var(--font-size-xl);
  font-weight: bold;
  line-height: 1.125;
`;

const Subtitle = styled.p`
  text-transform: uppercase;
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
  font-weight: bold;
  margin-bottom: 20px;
`;

export default TitleHeader;
