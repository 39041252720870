import {vesselMenu, VesselTypeKey} from '../utils/vesselMenu';
import React, {useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {MapVesselIcon} from '../MapVesselIcon';
import {types} from './types';
import {SWITCHES} from './switches';
import {aisInitialFilters} from './aisInitialFilters';
import {MapOptionSectionItem} from './mapOptions';

const MenuVesselIcon = ({type}: {type: VesselTypeKey}) => {
  const mapContext = useContext(MapContext);

  return (
    <MapVesselIcon
      iconCSS={{transform: 'rotate(35deg)'}}
      vesselIconStyle={mapContext.state.settings.vesselIconStyle}
      type={type}
      label={vesselMenu[type]}
    />
  );
};

/**
 * In the map there are buttons on the top right that lead to drop down menus. Under the item vessels there is the item "vessel types". The options come from there.
 */
export const aisSubSection: MapOptionSectionItem = {
  id: SWITCHES.VESSEL_AIS,
  type: types.SECTION_SUBMENU_TOGGLE,
  label: 'AIS vessels',
  turnsOnSub: ['bulker', 'container'],
  subMenuLabel: 'Vessel types',
  subMenu: [
    ...(Object.keys(vesselMenu) as VesselTypeKey[]).map(vesselType => ({
      id: vesselType,
      label: <MenuVesselIcon type={vesselType} />,
      default: true,
    })),
  ],
  filters: {...aisInitialFilters},
};
