/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ParseExcelResponse
 */
export interface ParseExcelResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof ParseExcelResponse
   */
  vessels: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof ParseExcelResponse
   */
  availableColumns: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ParseExcelResponse
   */
  predictedImoColumn: string | null;
}

export function ParseExcelResponseFromJSON(json: any): ParseExcelResponse {
  return ParseExcelResponseFromJSONTyped(json, false);
}

export function ParseExcelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParseExcelResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vessels: json['vessels'],
    availableColumns: json['availableColumns'],
    predictedImoColumn: json['predictedImoColumn'],
  };
}

export function ParseExcelResponseToJSON(value?: ParseExcelResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vessels: value.vessels,
    availableColumns: value.availableColumns,
    predictedImoColumn: value.predictedImoColumn,
  };
}
