import {assert} from './assert';

/**
 * Returns the configuration value of the requested key, if it exists.
 *
 * It searches the environment variables first, then the global window object.
 * Environment variables are only available during local development.
 * In production, the configuration is passed via window object.
 *
 * If no configuration value is available, then undefined will be returned.
 */
export const getConfiguration = (key: keyof Window): string | undefined => {
  const env = typeof process === 'undefined' ? {} : process.env;
  const value = env[key] ?? window[key];

  if (!(typeof value === 'string')) {
    // The configuration value is neither defined in the environment variables, nor in the window object.
    return undefined;
  }

  if (value.trim() === '') {
    // The requested configuration exists, but it is empty.
    // We consider that undefined.
    // It may happen if the external configuration value does not exist and
    // its placeholder in frontend/index.html is replaced by an empty string.
    return undefined;
  }

  if (value === '${' + key + '}') {
    // The configuration value still contains its placeholder.
    // By convention, that the placeholder has the same name of the key.
    // See frontend/index.html for details.
    // We may encounter this case, if a production build is served without
    // the nginx processor that replaces the configuration placeholders.
    return undefined;
  }

  return value;
};

/**
 * Returns the configuration value of the requested key.
 *
 * The value must exist. If it is missing, an error will be thrown.
 */
export const requireConfiguration = (key: keyof Window): string => {
  const conf = getConfiguration(key);
  assert(conf !== undefined, `Expected configuration '${key}' to exist, but no value was found.`);
  return conf;
};
