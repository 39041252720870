import {Card} from 'antd';
import styled from 'styled-components';

export const StatCard = styled(Card)<{$center?: boolean}>`
  height: auto;
  background: var(--color-gray-6);
  border-radius: var(--border-radius-card);
  border: var(--border-base);
  box-shadow: var(--box-shadow-card);
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  ${({$center}) => ($center ? 'text-align: center;' : '')}
`;
