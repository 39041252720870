import {negotiationAttachmentApi} from '../../../../../api/node-backend/nodeBackendApis';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

export const useUploadAttachmentsMutation = ({
  options,
}: {
  options?: UseMutationOptions<void[], unknown, {negotiationId: string; files: File[]}>;
}) => {
  return useMutation({
    mutationFn: async ({negotiationId, files}: {negotiationId: string; files: File[]}) => {
      const promises = files.map(file => {
        return negotiationAttachmentApi.newAttachment({
          negotiationId: negotiationId,
          file: file,
        });
      });
      return Promise.all(promises);
    },
    ...options,
  });
};
