import {AutoComplete} from 'antd';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {Property} from 'csstype';
import {useState, FC, ReactNode, ReactElement} from 'react';
import styled from 'styled-components';
import Icon from '../../../../atoms/Icon';

export type InlineNumberEditorWithAutocompleteOptionsProps = {
  value: number;
  min?: number;
  size?: SizeType;
  block?: boolean;
  closeOnEnter?: boolean;
  closeOnBlur?: boolean;
  formattedValue: ReactElement;
  editModeAvailable?: boolean;
  editButtonAlignment?: Property.VerticalAlign;
  onChange: (newValue: number) => void;
  editing?: boolean;
  onEditingChanged?: (editing: boolean) => void;
  copyright?: ReactNode;
  options?: {value: string; label: ReactNode}[];
};

export const InlineNumberEditorWithAutocompleteOptions = (props: InlineNumberEditorWithAutocompleteOptionsProps) => {
  const [editingState, setEditingState] = useState(false);

  const closeOnBlur = props.closeOnBlur ?? true;

  if (props.editing || editingState) {
    return (
      <EditModeWithOptions
        copyright={props.copyright}
        min={props.min}
        size={props.size}
        closeOnEnter={props.closeOnEnter ?? true}
        options={props.options}
        onChange={props.onChange}
        value={props.value}
        closeOnBlur={closeOnBlur}
        onClose={() => {
          if (props.editing !== undefined && props.onEditingChanged) {
            props.onEditingChanged?.(false);
          } else {
            setEditingState(false);
          }
        }}
      />
    );
  }

  if (!props.editModeAvailable) {
    return <span>{props.formattedValue}</span>;
  }

  return (
    <ViewMode
      editButtonAlignment={props.editButtonAlignment}
      formattedValue={props.formattedValue}
      block={props.block}
      onEditingChanged={() => {
        if (props.editing !== undefined && props.onEditingChanged) {
          props.onEditingChanged(true);
        } else {
          setEditingState(true);
        }
      }}
    />
  );
};

const ViewMode: FC<{
  onEditingChanged: () => void;
  block?: boolean;
  formattedValue: ReactElement;
  editButtonAlignment?: Property.VerticalAlign;
}> = ({onEditingChanged, block, editButtonAlignment, formattedValue}) => {
  return (
    <IdleValueContainer onClick={onEditingChanged}>
      <IdleValue block={block}>{formattedValue} </IdleValue>
      <Icon
        className={'editMarker'}
        size={'unset'}
        style={{verticalAlign: editButtonAlignment || 'bottom'}}
        type={'edit'}
      />
    </IdleValueContainer>
  );
};

const EditModeWithOptions: FC<{
  options?: {value: string; label: ReactNode}[];
  closeOnBlur: boolean;
  closeOnEnter: boolean;
  onClose: () => void;
  value: number;
  onChange: (newValue: number) => void;
  copyright?: ReactNode;

  min?: number;
  size?: SizeType;
}> = ({options, closeOnBlur, copyright, onClose, min, size, value, onChange, closeOnEnter}) => {
  const [tempValue, setTempValue] = useState(value.toString() ?? '');

  const closeAndUpdate = (value?: string) => {
    const stringValue = (value ?? tempValue).replace(',', '.');
    const numberValue = parseFloat(stringValue);

    if (isNaN(numberValue)) {
      onClose();
      return;
    }
    if (min !== undefined && min > numberValue) {
      onClose();
      return;
    }

    onChange(numberValue);
    onClose();
  };
  return (
    <Container>
      <AutoComplete
        dropdownMatchSelectWidth={350}
        autoFocus={true}
        defaultOpen={true}
        size={size}
        style={{width: 200}}
        value={tempValue}
        onBlur={() => {
          if (closeOnBlur) {
            closeAndUpdate();
          }
        }}
        onKeyDown={value => {
          if (value.key === 'Enter' && closeOnEnter) {
            closeAndUpdate();
          }
        }}
        onSelect={(value: string) => {
          setTempValue(value);
          closeAndUpdate(value);
        }}
        onChange={(value: string) => {
          setTempValue(value);
        }}>
        {options?.map(item => (
          <AutoComplete.Option key={item.value} value={item.value}>
            {item.label}
          </AutoComplete.Option>
        ))}
      </AutoComplete>
      {copyright && <CopyrightContent>{copyright}</CopyrightContent>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const CopyrightContent = styled.div`
  cursor: auto;
  left: -12px;
  top: 0px;
  height: calc(100% + 10px);
  padding: 5px 24px;
  position: absolute;
  text-align: right;
  width: calc(100% + 12px);
`;

const IdleValueContainer = styled.span`
  cursor: pointer;

  .editMarker {
    opacity: 0;
  }
  :hover {
    .editMarker {
      opacity: 1;
    }
  }
`;

const IdleValue = styled.span<{block?: boolean}>`
  ${({block}) =>
    block &&
    `
    display: inline-block;
    width: calc(100% - 24px);
  `}
`;
