import {Dayjs} from 'dayjs';
import {FilterParams} from './filterParams';
import {allVessels} from './steps/allVessels';
import {step0a} from './steps/step0a';
import {step0b} from './steps/step0b';
import {step1} from './steps/step1';
import {step10, Step10Result} from './steps/step10';
import {step2} from './steps/step2';
import {step3} from './steps/step3';
import {step4} from './steps/step4';
import {step5} from './steps/step5';
import {step6} from './steps/step6';
import {step7} from './steps/step7/step7';
import {step8} from './steps/step8';
import {step9} from './steps/step9';

/**
 * To extract possible competitor vessels, different filters are applied one after another to a vessel list.
 *
 * The individual filters can be switched on or off.
 *
 * the structure of the filters and an explanation can be found under:
 * https://seanexxt.atlassian.net/l/c/ocu7CdUR
 */
export const filterVesselsAndAddDistance = async (params: FilterParams, currentTime?: Dayjs): Promise<Step10Result> => {
  const step0Result = allVessels({vessels: params.vessels, params});

  const step0aResult = step0a({preResult: step0Result, params, currentTime});

  const step0bResult = step0b({preResult: step0aResult, params, currentTime});

  const step1Result = step1({preResult: step0bResult, params});

  const step2Result = step2({preResult: step1Result, params});

  const step3Result = step3({preResult: step2Result, params});

  const step4Result = step4({preResult: step3Result, params});

  const step5Result = step5({preResult: step4Result, params});

  const step6Result = step6({preResult: step5Result, params});

  const step7Result = await step7({preResult: step6Result, params, currentTime});

  const step8Result = await step8({preResult: step7Result, params, currentTime: currentTime});

  const step9Result = step9({preResult: step8Result, params, currentTime: currentTime});

  const step10Result = step10({preResult: step9Result, params, currentTime: currentTime});

  return step10Result;
};
