import {Col, Row} from 'antd';
import React, {FC} from 'react';
import {AccountSegmentCheckboxButton} from './AccountSegmentCheckboxButton';
import {SegmentHeadline} from './sharedComponents/SegmentHeadline';
import styled from 'styled-components';
import {SegmentSubHeadline} from './sharedComponents/SegmentSubHeadline';
import {SegmentCenterContainer} from './sharedComponents/SegmentCenterContainer';
import {Button} from '../../../atoms/Button/Button';

const availableSegments: {
  label: string;
  iconUrl: string;
}[] = [
  {label: 'Bulker', iconUrl: '/static/icon/accountSegment/Segment/Bulker.svg'},
  {label: 'Container', iconUrl: '/static/icon/accountSegment/Segment/Container.svg'},
];

export const VesselTypeSelector: FC<{
  onChange: (segment: string[]) => void;
  segment?: string[];
  // default value for segment because saved Accounts will have segment === undefined
}> = ({onChange, segment = []}) => {
  const changeOneItem = (key: string) => {
    const newList = segment.filter(item => item !== key);
    if (newList.length === segment.length) {
      newList.push(key.toLowerCase());
    }
    onChange(newList);
  };

  const hasSelectedElements = segment.length !== 0;

  const unselectAll = () => {
    onChange([]);
  };

  const selectAll = () => {
    const newList = availableSegments.map(availableSegment => availableSegment.label.toLocaleLowerCase());
    onChange(newList);
  };

  return (
    <>
      <SegmentCenterContainer>
        <div>
          <SegmentHeadline>Which vessel segment are you interested in?</SegmentHeadline>
        </div>
        <div>
          <SegmentSubHeadline>Select all applicable</SegmentSubHeadline>
        </div>
      </SegmentCenterContainer>
      <StyledRow gutter={[16, 16]} style={{justifyContent: 'center'}}>
        {availableSegments.map(avSegment => {
          const key = avSegment.label.toLowerCase();
          return (
            <Col key={key} span={12} xxl={10}>
              <AccountSegmentCheckboxButton
                dataTestid={`VesselTypeSelectorButton_${key}`}
                checked={segment.includes(key)}
                onClick={() => changeOneItem(key)}>
                <StyledIconContainer>
                  <StyledIcon src={avSegment.iconUrl} />
                </StyledIconContainer>
                {avSegment.label}
              </AccountSegmentCheckboxButton>
            </Col>
          );
        })}
      </StyledRow>
      <StyledRow gutter={[16, 16]} style={{justifyContent: 'center'}}>
        <ButtonContainer>
          <Button
            label={hasSelectedElements ? 'Unselect all' : 'Select all'}
            bold
            onClick={hasSelectedElements ? unselectAll : selectAll}
          />
        </ButtonContainer>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  padding: 0 40px;
`;

const ButtonContainer = styled.div`
  margin: 16px 10px 48px 10px;
  display: flex;
`;

const StyledIconContainer = styled.span`
  display: inline-block;
  width: 92px;
  height: 60px;
  margin-right: 12px;
  background-color: var(--color-gray-7);
`;

const StyledIcon = styled.img`
  width: 100%;
  height: 100%;
`;
