/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Coordinates, CoordinatesFromJSON, CoordinatesFromJSONTyped, CoordinatesToJSON} from './';

/**
 *
 * @export
 * @interface VesselRouteItem
 */
export interface VesselRouteItem {
  /**
   *
   * @type {number}
   * @memberof VesselRouteItem
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof VesselRouteItem
   */
  speed: number;
  /**
   *
   * @type {Coordinates}
   * @memberof VesselRouteItem
   */
  coordinates: Coordinates;
}

export function VesselRouteItemFromJSON(json: any): VesselRouteItem {
  return VesselRouteItemFromJSONTyped(json, false);
}

export function VesselRouteItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselRouteItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timestamp: json['_timestamp'],
    speed: json['speed'],
    coordinates: CoordinatesFromJSON(json['coordinates']),
  };
}

export function VesselRouteItemToJSON(value?: VesselRouteItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    _timestamp: value.timestamp,
    speed: value.speed,
    coordinates: CoordinatesToJSON(value.coordinates),
  };
}
