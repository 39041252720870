/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Visit
 */
export interface Visit {
  /**
   *
   * @type {number}
   * @memberof Visit
   */
  imo: number;
  /**
   *
   * @type {number}
   * @memberof Visit
   */
  passageDuration: number;
  /**
   *
   * @type {number}
   * @memberof Visit
   */
  waitingDuration: number;
  /**
   *
   * @type {number}
   * @memberof Visit
   */
  visitDuration: number;
  /**
   *
   * @type {string}
   * @memberof Visit
   */
  vesselType: string;
  /**
   *
   * @type {string}
   * @memberof Visit
   */
  endTimestamp: string;
  /**
   *
   * @type {string}
   * @memberof Visit
   */
  startTimestamp: string;
  /**
   *
   * @type {boolean}
   * @memberof Visit
   */
  isCurrentlyInTheArea: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Visit
   */
  hasPassed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Visit
   */
  isStuckInPassage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Visit
   */
  inPassage: boolean;
  /**
   *
   * @type {string}
   * @memberof Visit
   */
  passingDirection?: VisitPassingDirectionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum VisitPassingDirectionEnum {
  NorthToSouth = 'NorthToSouth',
  SouthToNorth = 'SouthToNorth',
  WestToEast = 'WestToEast',
  EastToWest = 'EastToWest',
}

export function VisitFromJSON(json: any): Visit {
  return VisitFromJSONTyped(json, false);
}

export function VisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Visit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    imo: json['imo'],
    passageDuration: json['passageDuration'],
    waitingDuration: json['waitingDuration'],
    visitDuration: json['visitDuration'],
    vesselType: json['vesselType'],
    endTimestamp: json['endTimestamp'],
    startTimestamp: json['startTimestamp'],
    isCurrentlyInTheArea: json['isCurrentlyInTheArea'],
    hasPassed: json['hasPassed'],
    isStuckInPassage: json['isStuckInPassage'],
    inPassage: json['inPassage'],
    passingDirection: !exists(json, 'passingDirection') ? undefined : json['passingDirection'],
  };
}

export function VisitToJSON(value?: Visit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    imo: value.imo,
    passageDuration: value.passageDuration,
    waitingDuration: value.waitingDuration,
    visitDuration: value.visitDuration,
    vesselType: value.vesselType,
    endTimestamp: value.endTimestamp,
    startTimestamp: value.startTimestamp,
    isCurrentlyInTheArea: value.isCurrentlyInTheArea,
    hasPassed: value.hasPassed,
    isStuckInPassage: value.isStuckInPassage,
    inPassage: value.inPassage,
    passingDirection: value.passingDirection,
  };
}
