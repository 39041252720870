import {FC, useRef} from 'react';
import {MapControlButton} from './MapControlButton';
import {useMapContext} from '../MapContext/MapContext';
import {MapContextValue, MapControlMenuSectionsToHide} from '../MapContext/Types';
import {MapOptionChangeValue, MapSwitches, MapSwitchesKey} from '../MapDetails/utils/types';
import useOnClickOutside from 'use-onclickoutside';
import {MapControlMenuDropdownContainer} from './MapControlMenuDropdownContainer';
import {MapMenu} from '../MapMenu/MapMenu';
import styled from 'styled-components';
import {MapOption} from '../mapOptions/mapOptions';

type MapControlVesselButtonProps = {
  switches: MapSwitches;
  mapOption: MapOption;
  isOpen: boolean;
  mapHeight: number;
  onChange: (switchId: MapSwitchesKey, value: MapOptionChangeValue) => void;
  onToggleOpen: () => void;
};

export const MapControlVesselButton: FC<MapControlVesselButtonProps> = ({
  isOpen,
  onToggleOpen,
  mapHeight,
  mapOption,
  switches,
  onChange,
}) => {
  const context = useMapContext();

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => {
    if (isOpen) {
      onToggleOpen?.();
    }
  });

  return (
    <div ref={containerRef}>
      <MapControlButton
        active={isActive(context)}
        open={isOpen}
        iconActive={'vessel-complex'}
        iconInactive={'vessel-disabled'}
        onClick={onToggleOpen}
      />
      {isOpen && (
        <StyledMapMenuDropdownContainer mapHeight={mapHeight}>
          <MapMenu
            option={mapOption}
            switches={switches}
            onChange={onChange}
            onFilterChange={filters => {
              context.updateSwitches(draft => {
                draft.vessel_ais.filters = filters;
              });
            }}
          />
        </StyledMapMenuDropdownContainer>
      )}
    </div>
  );
};

const isActive = (context: MapContextValue) => {
  const isShowVesselOn = !context.props.mapControlMenuSectionsToHide?.includes('vessel_on');

  if (isShowVesselOn) {
    return context?.state.switches.vessel_on?.state;
  }

  const potentialVesselTypes: MapControlMenuSectionsToHide[] = [
    'vessel_on',
    'vessel_market',
    'vessel_portfolio',
    'vessel_ais',
  ];

  const visibleVesselTypes = potentialVesselTypes.filter(
    vesselType => !context.props.mapControlMenuSectionsToHide?.includes(vesselType)
  );

  return visibleVesselTypes.some(vesselType => context.state.switches[vesselType]?.state);
};

export const StyledMapMenuDropdownContainer = styled(MapControlMenuDropdownContainer)`
  .ant-card-body {
    padding: 0;
  }

  .ant-form {
    .ant-form-item-row {
      padding: 0;
    }
  }
`;
