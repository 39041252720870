import produce from 'immer';
import {CargoInput} from '../../../utils/CargoTypes';
import {useContext} from 'react';
import {VoyageCharterContext} from '../../VoyageCharterContext';
import {InputState} from '../../../utils/voyageChartercalculation/voyageChartercalculation';

type UpdateCargoInputParams = {cargoInput: Partial<CargoInput>};

export type UpdateCargoInput = (params: UpdateCargoInputParams) => void;

export const updateCargoInput = ({
  setInputState,
  inputState,
  cargoInput,
}: UpdateCargoInputParams & {
  inputState: InputState;
  setInputState: (inputState: InputState) => void;
}) => {
  setInputState(
    produce(inputState, draftState => {
      draftState.cargo = {...draftState.cargo, ...cargoInput};
      return draftState;
    })
  );
};

export const useUpdateCargoInput = (): UpdateCargoInput => {
  const {state, setInputState} = useContext(VoyageCharterContext);
  const {inputState} = state;
  return ({cargoInput}) =>
    updateCargoInput({
      cargoInput,
      setInputState,
      inputState,
    });
};
