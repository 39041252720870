import {
  convertKeysWithBooleansToStringArray,
  convertStringArrayToKeysWithBooleans,
} from '../FilterConversion/utils/FilterConversionUtils';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export type VesselType = 'mpp' | 'container' | 'bulker';

export type DatabaseType = VesselType[];
export type InternalType = Record<VesselType, boolean>;

export const Defaults: InternalType = {
  bulker: false,
  container: false,
  mpp: false,
};

export const fromDatabase = (database: DatabaseType): InternalType =>
  convertStringArrayToKeysWithBooleans(database, Defaults);

export const toDatabase = (internal: InternalType): DatabaseType => convertKeysWithBooleansToStringArray(internal);

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel type',
  branch: 'vesselTypes',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
