import {Divider, Popover, Table} from 'antd';
import numeral from 'numeral';
import {FC, useContext} from 'react';
import {useSelector} from '../../../../../redux/react-redux';
import {assertUnreachable} from '../../../../../utils/assert';
import {VoyageCharterContext} from '../../Context/VoyageCharterContext';
import {
  BunkerBalanceAnalysisResult,
  getBunkerBalanceForTimePeriod,
} from '../../utils/consumptionSequence/getBunkerBalanceForTimePeriod';
import {ConsumptionFuelType} from '../../utils/getUsedFuelType';
import {ConsumptionType, VoyagePoint, VoyagePointOutput, VoyageRoute, VoyageRouteOutput} from '../VoyageTypes';
import {ConsumptionResult, getConsumptionForPoint} from './getConsumptionForPoint';
import {getConsumptionForRoute} from './getConsumptionForRoute';

export type VoyagePointProps = {point: VoyagePoint; pointOutput: VoyagePointOutput};
export type VoyageRouteProps = {route: VoyageRoute; routeOutput: VoyageRouteOutput};

type Props = (VoyagePointProps | VoyageRouteProps) & {
  children?: React.ReactNode;
};

export const ConsumptionTooltip: FC<Props> = ({children, ...props}) => {
  return (
    <Popover
      color={'white'}
      trigger={['hover']}
      placement={'bottom'}
      overlayInnerStyle={{width: 950}}
      title={
        <div style={{color: 'var(--color-black)'}}>
          <Divider orientation={'center'}>Consumption</Divider>
          <ConsumptionDetailsOneEngine {...props} type={'main'} />
          <Divider>Additional consumption</Divider>
          <ConsumptionDetailsOneEngine {...props} type={'aux'} />
        </div>
      }>
      {children}
    </Popover>
  );
};

type ConsumptionDetailsOneEngineProps = Props & {type: ConsumptionType};

const ConsumptionDetailsOneEngine: FC<ConsumptionDetailsOneEngineProps> = props => {
  const isScrubberFitted = useSelector(state => state.charterCalculation.inputState.vessel.isScrubberFitted);
  const costInput = useSelector(state => state.charterCalculation.inputState.cost);

  const voyageContext = useContext(VoyageCharterContext);

  const consumptions = voyageContext.state.outputState.voyage.consumptionTransactions;

  let startDate: Date | null = null;
  let endDate: Date | null = null;

  const dataSource = [];
  if ('point' in props) {
    dataSource.push(
      getConsumptionForPoint({
        point: props.point,
        duration: props.pointOutput.duration,
        type: props.type,
        isScrubberFitted: isScrubberFitted,
        costInput,
      })
    );
    endDate = new Date(props.pointOutput.endDate!);
    startDate = new Date(props.pointOutput.startDate!);
  } else {
    dataSource.push(
      ...getConsumptionForRoute({
        route: props.route,
        duration: props.routeOutput.duration,
        isScrubberFitted,
        type: props.type,
        costInput,
      })
    );
    endDate = new Date(props.routeOutput.endDate!);
    startDate = new Date(props.routeOutput.startDate!);
  }

  const consumptionMdo = getBunkerBalanceForTimePeriod({
    transactions: consumptions,
    fuelType: 'mgo',
    start: startDate,
    end: endDate,
    costPerUncoveredUnit: costInput.costMgoPerMts ?? 0,
  });
  const consumptionVlsfo = getBunkerBalanceForTimePeriod({
    transactions: consumptions,
    fuelType: 'vlsfo',
    start: startDate,
    end: endDate,
    costPerUncoveredUnit: costInput.costVlsfoPerMts ?? 0,
  });
  const consumptionIfo = getBunkerBalanceForTimePeriod({
    transactions: consumptions,
    fuelType: 'ifo',
    start: startDate,
    end: endDate,
    costPerUncoveredUnit: costInput.costIfoPerMts ?? 0,
  });

  const getConsumptionForBunkerType = (bunkerType: ConsumptionFuelType): BunkerBalanceAnalysisResult => {
    switch (bunkerType) {
      case 'vlsfo':
        return consumptionVlsfo;
      case 'ifo':
        return consumptionIfo;
      case 'mgo':
        return consumptionMdo;
      default:
        assertUnreachable(bunkerType);
    }
  };

  return (
    <Table<ConsumptionResult>
      pagination={false}
      size={'small'}
      columns={[
        {
          title: '',
          key: 'inSeca',
          width: 80,
          dataIndex: 'inSeca',
          render: inSeca => {
            return inSeca ? 'ECA' : 'Non-ECA';
          },
        },
        {
          title: 'Fuel type',
          width: 70,
          key: 'fuelType',
          dataIndex: 'fuelType',
          render: (_, value) => value.fuelType.toUpperCase(),
        },
        {
          dataIndex: 'dailyConsumption',
          width: 100,
          key: 'dailyConsumption',
          title: 'Daily consumption',
          align: 'right',
          render: v => `${numeral(v).format('0,0[.]00')} mts/d`,
        },
        {
          dataIndex: 'duration',
          key: 'duration',
          width: 100,
          align: 'right',
          title: 'Duration',
          render: v => `${numeral(v).format('0,0[.]00')} days`,
        },
        {
          dataIndex: 'consumption',
          width: 100,
          key: 'consumption',
          align: 'right',
          title: 'Total consumption',
          render: v => `${numeral(v).format('0,0[.]00')} mts`,
        },
        {
          title: 'Not covered by ROB',
          width: 100,
          key: 'rob',
          dataIndex: 'fuelType',
          align: 'right',
          render: (_, value) => {
            const consumption = getConsumptionForBunkerType(value.fuelType);
            return `${numeral((consumption.uncoveredUsage * 100) / consumption.totalUsage).format('0,0[.]00')} %`;
          },
        },
        {
          dataIndex: 'pricePerMts',
          width: 110,
          key: 'pricePerMts',
          align: 'right',
          title: 'Bunker price',
          render: (_, value) => {
            const consumption = getConsumptionForBunkerType(value.fuelType);

            const price = consumption.totalCosts / consumption.totalUsage;
            return `$ ${numeral(price).format('0,0[.]00')} / mts`;
          },
        },
        {
          dataIndex: 'costs',
          key: 'costs',
          width: 100,
          align: 'right',
          title: 'Bunker cost',
          render: (_, value) => {
            const consumption = getConsumptionForBunkerType(value.fuelType);

            const price = consumption.totalCosts / consumption.totalUsage;
            const costs = price * value.consumption;
            return `$ ${numeral(costs).format('0,0[.]00')}`;
          },
        },
        {
          dataIndex: 'cO2Emission',
          key: 'cO2Emission',
          width: 100,
          align: 'right',
          title: 'CO2 Emissions',
          render: v => `${numeral(v).format('0,0')} mts`,
        },
      ]}
      dataSource={dataSource}
    />
  );
};
