import LabelWrapper from '../../../../atoms/LabelWrapper';
import {vesselStatusOptions} from '../../../../screens/CargoVesselForm/helper';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './VesselStatusDefinition';
import {MultiSelect} from '../../../../atoms/Select/MultiSelect';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const VesselStatus = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values}) => (
      <div className="container">
        <div className="row bottom-fix">
          <div className="scol-12">
            <LabelWrapper label={'Status'} htmlFor={'filter-vesselstatus'}>
              <MultiSelect
                id={'filter-vesselstatus'}
                value={values.vesselStatuses}
                onChange={value => handleChange('vesselStatuses', [...value])}
                options={
                  Object.keys(vesselStatusOptions).map(value => ({
                    value,
                    name: vesselStatusOptions[value as keyof typeof vesselStatusOptions],
                  })) as typeof values.vesselStatuses
                }
                getOptionLabel={option => option.name}
                getOptionValue={option => option.value}
              />
            </LabelWrapper>
          </div>
        </div>
      </div>
    )}
  </FilterButton>
);

export default VesselStatus;
