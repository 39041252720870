import {TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import {color as d3color, rgb, RGBColor} from 'd3-color';
import {interpolateRgb} from 'd3-interpolate';
import {ClassInformation} from '../classInformation';
import {RGBAColor} from '../../../../utils/deck.gl/deck.gl-types';

const ColorInterpolator = interpolateRgb('white', 'red');

type DailyValue = {
  day: string;
  value: number;
};

export const getFillColorOfOneTradingArea = (
  item: {
    properties: {
      name: string;
      statistic: TradingAreaTiedUpVesselsStatistic;
    };
  },
  vesselClass: ClassInformation,
  dateIndex: number,
  hoveredTradingArea: string
): RGBAColor => {
  const statistic: TradingAreaTiedUpVesselsStatistic = item.properties.statistic;

  // An array, each element is an object with a tag and a value, the value is the number of dwt or teu of the vessel class that is in the trading area for 24 hours
  const dailyValues: DailyValue[] = statistic.oneDayStatistics.map(day => {
    return {
      day: day.date,
      value: vesselClass.accessor(day),
    };
  });

  const relativeValue = getRelativeValue(dateIndex, dailyValues);

  const isHovered = item.properties.name === hoveredTradingArea;
  const alphaFromHover = isHovered ? 100 : 0;

  const alphaFromValue = 100 * relativeValue;

  const alpha = alphaFromHover + alphaFromValue;
  const color: RGBColor = d3color(ColorInterpolator(relativeValue))?.rgb() ?? rgb(0, 0, 0);
  return [color.r, color.g, color.b, alpha];
};

const getRelativeValue = (selectedIndex: number, dailyValues: DailyValue[]) => {
  const maxValue = Math.max(...dailyValues.map(item => item.value));
  const minValue = Math.min(...dailyValues.map(item => item.value));

  const selectedDateValue = dailyValues[selectedIndex].value;

  const relativeValue = (selectedDateValue - minValue) / (maxValue - minValue);
  if (isNaN(relativeValue)) {
    return 0;
  }
  return relativeValue;
};
