import {FetchParams, Middleware, RequestContext} from '../../api/symfony/generated';
import {addHeaderOnContext} from './middlewareAddHeader';

// Keep this variable module-private to avoid security risks!
let accessToken: string | undefined;

/**
 * Sets the access token for the API access.
 */
export const setAccessTokenForApi = (newAccessToken: string | undefined) => {
  accessToken = newAccessToken;
};

/**
 * This middleware adds the access token to the request header.
 */
export const apiAccessTokenMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    if (!accessToken) {
      return;
    }
    addHeaderOnContext(context, 'Authorization', `Bearer ${accessToken}`);
  },
};
