/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselBuiltYearTeu,
  VesselBuiltYearTeuFromJSON,
  VesselBuiltYearTeuFromJSONTyped,
  VesselBuiltYearTeuToJSON,
} from './';

/**
 *
 * @export
 * @interface BuiltYearTeuReport
 */
export interface BuiltYearTeuReport {
  /**
   *
   * @type {Array<VesselBuiltYearTeu>}
   * @memberof BuiltYearTeuReport
   */
  vessels: Array<VesselBuiltYearTeu>;
  /**
   *
   * @type {number}
   * @memberof BuiltYearTeuReport
   */
  minBuiltYear: number;
  /**
   *
   * @type {number}
   * @memberof BuiltYearTeuReport
   */
  maxBuiltYear: number;
  /**
   *
   * @type {number}
   * @memberof BuiltYearTeuReport
   */
  minTeu: number;
  /**
   *
   * @type {number}
   * @memberof BuiltYearTeuReport
   */
  maxTeu: number;
  /**
   * Number of container vessels that lack a built year or TEU value.
   * @type {number}
   * @memberof BuiltYearTeuReport
   */
  containerVesselsWithoutValue: number;
  /**
   * The report does not include non-container vessels. This is the number of non-container vessels that matched the given filter.
   * @type {number}
   * @memberof BuiltYearTeuReport
   */
  nonContainerVessels: number;
}

export function BuiltYearTeuReportFromJSON(json: any): BuiltYearTeuReport {
  return BuiltYearTeuReportFromJSONTyped(json, false);
}

export function BuiltYearTeuReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuiltYearTeuReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vessels: (json['vessels'] as Array<any>).map(VesselBuiltYearTeuFromJSON),
    minBuiltYear: json['minBuiltYear'],
    maxBuiltYear: json['maxBuiltYear'],
    minTeu: json['minTeu'],
    maxTeu: json['maxTeu'],
    containerVesselsWithoutValue: json['containerVesselsWithoutValue'],
    nonContainerVessels: json['nonContainerVessels'],
  };
}

export function BuiltYearTeuReportToJSON(value?: BuiltYearTeuReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vessels: (value.vessels as Array<any>).map(VesselBuiltYearTeuToJSON),
    minBuiltYear: value.minBuiltYear,
    maxBuiltYear: value.maxBuiltYear,
    minTeu: value.minTeu,
    maxTeu: value.maxTeu,
    containerVesselsWithoutValue: value.containerVesselsWithoutValue,
    nonContainerVessels: value.nonContainerVessels,
  };
}
