/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ContactRequest,
  ContactRequestFromJSON,
  ContactRequestToJSON,
  ValidationProblem,
  ValidationProblemFromJSON,
  ValidationProblemToJSON,
} from '../models';

export interface SendContactRequestRequest {
  contactRequest?: ContactRequest;
}

/**
 *
 */
export class ContactApi extends runtime.BaseAPI {
  /**
   * Receives public contact requests.
   */
  async sendContactRequestRaw(requestParameters: SendContactRequestRequest): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/contact-request`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ContactRequestToJSON(requestParameters.contactRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Receives public contact requests.
   */
  async sendContactRequest(requestParameters: SendContactRequestRequest): Promise<void> {
    await this.sendContactRequestRaw(requestParameters);
  }
}
