import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {
  floatBetween,
  floatBetweenAndGreaterOrEqual,
  numberBetween,
  numberBetweenAndGreaterOrEqual,
} from '../../../../../utils/validators';
import {convertValueToNamedValue, NamedValue} from '../../FilterConversion/utils/NamedValue';
import {sizesToSizeObject} from '../../Size/SizeDefinition';

type Geared = 'geared' | 'gearless';
export const gearedOptions: NamedValue<Geared>[] = [
  {name: 'Geared', value: 'geared'},
  {name: 'Gearless', value: 'gearless'},
];

export interface DatabaseType {
  logFitted: boolean;
  lakesFitted: boolean;
  tweenDecks: boolean;
  a60Bulkhead: boolean;
  co2Fitted: boolean;
  boxShaped: boolean;
  openHatched: boolean;
  cellular: boolean;
  sternThrusted: boolean;
  bowThrusted: boolean;
  itfFitted: boolean;
  scrubberFitted: boolean;
  length: {
    from?: number;
    to?: number;
  };
  beam: {
    from?: number;
    to?: number;
  };
  draft: {
    from?: number;
    to?: number;
  };
  deadWeight: {
    from?: number;
    to?: number;
  };
  teu: {
    from?: number;
    to?: number;
  };
  reeferPlugs: {
    from?: number;
    to?: number;
  };
  geared?: boolean;
}

export interface InternalType {
  lengthFrom: string;
  lengthTo: string;
  beamFrom: string;
  beamTo: string;
  draftFrom: string;
  draftTo: string;
  deadWeightFrom: number | '';
  deadWeightTo: number | '';
  teuFrom: number | '';
  teuTo: number | '';
  plugsFrom: number | '';
  plugsTo: number | '';
  logFitted: boolean;
  lakesFitted: boolean;
  tweenDecks: boolean;
  a60Bulkhead: boolean;
  co2Fitted: boolean;
  boxShaped: boolean;
  openHatched: boolean;
  cellular: boolean;
  sternThrusted: boolean;
  bowThrusted: boolean;
  itfFitted: boolean;
  scrubberFitted: boolean;
  geared: NamedValue<Geared> | false;
}

export const Defaults: InternalType = {
  lengthFrom: '',
  lengthTo: '',
  beamFrom: '',
  beamTo: '',
  draftFrom: '',
  draftTo: '',
  deadWeightFrom: '',
  deadWeightTo: '',
  teuFrom: '',
  teuTo: '',
  plugsFrom: '',
  plugsTo: '',
  logFitted: false,
  lakesFitted: false,
  tweenDecks: false,
  a60Bulkhead: false,
  co2Fitted: false,
  boxShaped: false,
  openHatched: false,
  cellular: false,
  sternThrusted: false,
  bowThrusted: false,
  itfFitted: false,
  scrubberFitted: false,
  geared: false,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  let geared: NamedValue<Geared> | false = false;
  if (database.geared !== undefined) {
    geared = convertValueToNamedValue({
      value: database.geared ? 'geared' : 'gearless',
      available: gearedOptions,
      defaultValue: false,
    });
  }

  return {
    a60Bulkhead: database.a60Bulkhead,
    beamFrom: database.beam.from?.toString() ?? '',
    beamTo: database.beam.to?.toString() ?? '',
    bowThrusted: database.bowThrusted,
    boxShaped: database.boxShaped,
    cellular: database.cellular,
    co2Fitted: database.co2Fitted,
    deadWeightFrom: database.deadWeight.from ?? '',
    deadWeightTo: database.deadWeight.to ?? '',
    draftFrom: database.draft.from?.toString() ?? '',
    draftTo: database.draft.to?.toString() ?? '',
    geared,
    itfFitted: database.itfFitted,
    lakesFitted: database.lakesFitted,
    lengthFrom: database.length.from?.toString() ?? '',
    lengthTo: database.length.to?.toString() ?? '',
    logFitted: database.logFitted,
    openHatched: database.openHatched,
    plugsFrom: database.reeferPlugs.from ?? '',
    plugsTo: database.reeferPlugs.to ?? '',
    scrubberFitted: database.scrubberFitted,
    sternThrusted: database.sternThrusted,
    teuFrom: database.teu.from ?? '',
    teuTo: database.teu.to ?? '',
    tweenDecks: database.tweenDecks,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  let geared = undefined;

  if (internal.geared) {
    geared = internal.geared.value === 'geared';
  }

  return {
    a60Bulkhead: internal.a60Bulkhead,
    beam: sizesToSizeObject({
      from: internal.beamFrom,
      to: internal.beamTo,
    }),
    bowThrusted: internal.bowThrusted,
    boxShaped: internal.boxShaped,
    cellular: internal.cellular,
    co2Fitted: internal.co2Fitted,
    deadWeight: sizesToSizeObject({
      from: internal.deadWeightFrom,
      to: internal.deadWeightTo,
    }),
    draft: sizesToSizeObject({
      from: internal.draftFrom,
      to: internal.draftTo,
    }),
    itfFitted: internal.itfFitted,
    lakesFitted: internal.lakesFitted,
    logFitted: internal.logFitted,
    openHatched: internal.openHatched,
    reeferPlugs: sizesToSizeObject({
      from: internal.plugsFrom,
      to: internal.plugsTo,
    }),
    scrubberFitted: internal.scrubberFitted,
    sternThrusted: internal.sternThrusted,
    teu: sizesToSizeObject({
      from: internal.teuFrom,
      to: internal.teuTo,
    }),
    tweenDecks: internal.tweenDecks,
    length: sizesToSizeObject({
      from: internal.lengthFrom,
      to: internal.lengthTo,
    }),
    geared,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel details',
  branch: 'fixtureVesselDetails',
  defaults: Defaults,
  validators: {
    lengthFrom: floatBetween(0, 99999999),
    lengthTo: floatBetweenAndGreaterOrEqual(0, 99999999, 'lengthFrom'),
    beamFrom: floatBetween(0, 99999999),
    beamTo: floatBetweenAndGreaterOrEqual(0, 99999999, 'beamFrom'),
    draftFrom: floatBetween(0, 99999999),
    draftTo: floatBetweenAndGreaterOrEqual(0, 99999999, 'draftFrom'),
    deadWeightFrom: numberBetween(0, 999999),
    deadWeightTo: numberBetweenAndGreaterOrEqual(0, 999999, 'deadWeightFrom'),
    teuFrom: numberBetween(0, 99999),
    teuTo: numberBetweenAndGreaterOrEqual(0, 99999, 'teuFrom'),
    plugsFrom: numberBetween(0, 99999),
    plugsTo: numberBetweenAndGreaterOrEqual(0, 99999, 'plugsFrom'),
  },
  fromDatabase,
  toDatabase,
});
