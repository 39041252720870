import {useEffect, FC} from 'react';
import DateOpen from '../../../components/FilterProvider/Filters/DateOpen/DateOpen';
import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import VesselType from '../../../components/FilterProvider/Filters/VesselType/VesselType';
import General from '../../../components/FilterProvider/Filters/General/General';
import {Size} from '../../../components/FilterProvider/Filters/Size/Size';
import Dimensions from '../../../components/FilterProvider/Filters/Dimensions/Dimensions';
import Intake from '../../../components/FilterProvider/Filters/Intake/Intake';
import Features from '../../../components/FilterProvider/Filters/Features/Features';
import Ownership from '../../../components/FilterProvider/Filters/Ownership/Ownership';
import PositionOpen from '../../../components/FilterProvider/Filters/PositionOpen/PositionOpen';
import VesselStatus from '../../../components/FilterProvider/Filters/VesselStatus/VesselStatus';
import {useDispatch} from 'react-redux';
import {AutocompleteActions} from '../../../redux/ApiService/autocomplete';
import {FilterBar} from '../../../components/FilterBar/FilterBar';
import {PortfolioVesselFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';

export const MyFleetFilters: FC<{
  filterProviderApi: FilterProviderApi<typeof PortfolioVesselFilterBranchDefinitions>;
}> = ({filterProviderApi}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Load options for one of the filter boxes.
    dispatch(AutocompleteActions.preFillSearch('design_type'));
  }, [dispatch]);

  return (
    <FilterBar
      id="vesselFilters"
      filters={[
        <VesselType filterProviderApi={filterProviderApi} />,
        <General filterProviderApi={filterProviderApi} />,
        <Size filterProviderApi={filterProviderApi} />,
        <Dimensions filterProviderApi={filterProviderApi} />,
        <Intake filterProviderApi={filterProviderApi} />,
        <Features filterProviderApi={filterProviderApi} />,
        <DateOpen filterProviderApi={filterProviderApi} />,
        <PositionOpen filterProviderApi={filterProviderApi} />,
        <VesselStatus filterProviderApi={filterProviderApi} />,
        <Ownership filterProviderApi={filterProviderApi} />,
      ]}
    />
  );
};
