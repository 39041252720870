import {useContext, useEffect} from 'react';
import {VoyageCharterContext} from '../VoyageCharterContext';
import produce from 'immer';

const isCargoExistingOnPlatform = async (plattformId: number): Promise<boolean> => {
  const response = await fetch('/api/cargoes/' + plattformId);
  return response.status === 200;
};

/**
 Problem:
 User has selected a cargo and then went to Portfolio and deleted the cargo.
 Afterwards he went back to Voyage Calc, in this case the data of the cargo should be preserved,
 but the link to the cargo should be removed
 */
export const useCheckIfCargoExist = () => {
  const {setInputState, state} = useContext(VoyageCharterContext);
  const cargoId = state.inputState.cargo.id;

  const removeCargo = () => {
    setInputState(
      produce(state.inputState, draftState => {
        draftState.cargo.id = undefined;
      })
    );
  };

  const checkIfCargoExist = async () => {
    if (cargoId) {
      const vesselExistingOnPlatform = await isCargoExistingOnPlatform(cargoId);
      if (!vesselExistingOnPlatform) {
        removeCargo();
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkIfCargoExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
