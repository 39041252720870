import React, {FC} from 'react';
import {PromotionPopover} from '../PromotionPopover/PromotionPopover';
import {PostedByBlur} from '../../screens/market/PostedBy/PostedByBlur/PostedByBlur';
import {generateBlurredMailAddress} from '../../screens/market/PostedBy/DummyData';
import {UserSelectDisabled} from '../UserSelectDisabled/UserSelectDisabled';
import {useSelector} from '../../redux/react-redux';
import {getIsPayingCustomer} from '../../redux/selectors';

interface Props {
  sender: string;
}

export const PostedBy: FC<Props> = ({sender}) => {
  const isPayingCustomer = useSelector(getIsPayingCustomer);
  return isPayingCustomer ? (
    <div>{sender}</div>
  ) : (
    <PromotionPopover active>
      <PostedByBlur>
        <UserSelectDisabled>{generateBlurredMailAddress()}</UserSelectDisabled>
      </PostedByBlur>
    </PromotionPopover>
  );
};
