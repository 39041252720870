import {StyledFlag} from '../StyledFlag';
import {SeaboVessel, SeaboVesselOption} from '../Type';
import {SearchHit} from '../../../api/symfony/generated/models/SearchHit';
import {List} from 'antd';

export const makeSeaboVesselOption = (searchHit: SearchHit): SeaboVesselOption => {
  const vessel = searchHit.source as SeaboVessel;

  return {
    id: vessel.id,
    key: `${vessel.type}_${vessel.imo}_${vessel.id}`,
    type: vessel.type,
    source: vessel,
    value: vessel.name,
    name: vessel.name,
    label: (
      <List.Item
        key={vessel.id}
        data-testid="seaboVesselPickerOption"
        data-cy="seaboVesselPickerOption"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <List.Item.Meta
          title={
            <span>
              <StyledFlag countryCode={vessel.countryFlag} shadow width={13} height={10} />
              <span data-testid="OptionName">{vessel.name}</span>
            </span>
          }
          description={
            <small>
              IMO: <span data-testid="OptionImo">{vessel.imo}</span>
            </small>
          }
        />
      </List.Item>
    ),
  };
};
