import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {numberUnitFormat} from '../../../../utils/formatter';
import {AccessorFn} from '@tanstack/react-table';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {Blurred} from '../../utils/Blurred';

export interface GrainColumnAccessorOptions {
  blurred?: boolean;
}

const grainAccessor =
  (type: RowValueType, options: GrainColumnAccessorOptions): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'} data-cy="cell-grain">
        <Blurred blurred={options.blurred}>
          <div className={'market-grid-cell-content'}>
            {numberUnitFormat(vessel.grain, vessel.grainUnit, {emptyString: '-'})}
          </div>
          <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
            {numberUnitFormat(vessel.bale, vessel.baleUnit, {emptyString: '-'})}
          </div>
        </Blurred>
      </div>
    );
  };

export const grainColumn = ({
  type,
  minWidth = 6,
  sortable = true,
  options = {},
}: {
  type?: RowValueType;
  minWidth?: number;
  sortable?: boolean;
  options?: GrainColumnAccessorOptions;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-grain">
      <div>
        Grain
        <br />
        Bale
      </div>
    </div>
  ),

  id: 'grain',
  accessorFn: grainAccessor(type, options),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
  enableSorting: sortable,
});
