import {Select} from 'antd';
import styled from 'styled-components';
import {GroupedPerUnit} from '../utils/groupBucketsByDate';

export const GroupedPerUnitFilter = ({
  value,
  onChange,
}: {
  onChange: (per: GroupedPerUnit) => void;
  value: GroupedPerUnit;
}) => {
  return (
    <Container>
      <Select
        showSearch={true}
        defaultValue={value}
        style={{width: '100%'}}
        onChange={newValue => {
          onChange(newValue);
        }}>
        <Select.Option value={'month'} key={'month'}>
          Month
        </Select.Option>
        <Select.Option value={'day'} key={'day'}>
          Day
        </Select.Option>
        <Select.Option value={'week'} key={'week'}>
          Week
        </Select.Option>
      </Select>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100px;
  margin-right: 8px;
`;
