// @ts-expect-error No declaration file found
import {parse} from 'content-disposition-attachment';

interface ContentDisposition {
  attachment: boolean;
  filename?: string;
}

/**
 * Returns the filename suggested by the server.
 */
export const getRecommendedFilename = (response: Response): string | null => {
  const contentDisposition: string | null = response.headers.get('Content-Disposition');
  if (!contentDisposition) {
    return null;
  }
  const parsedContentDisposition: ContentDisposition = parse(contentDisposition);
  if (!parsedContentDisposition.attachment) {
    return null;
  }
  return parsedContentDisposition?.filename ?? null;
};
