import React, {FC} from 'react';
import Title from '../../atoms/Title';
import styled from 'styled-components';

interface ChildrenProps {
  onClick?: () => void;
}

interface Props {
  bordered?: boolean;
  title: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  children?: (props: ChildrenProps) => React.ReactNode;
}

export const SettingsCardHeader: FC<Props> = props => {
  const {children, title, icon, bordered = false, onClick} = props;

  return (
    <StyledCardHeader $theme={bordered}>
      <Title medium>{title}</Title>
      {icon}
      {children &&
        props.children?.({
          onClick: onClick,
        })}
    </StyledCardHeader>
  );
};

const StyledCardHeader = styled.div<{$theme: boolean}>`
  padding: 16px 40px;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  ${({$theme}) => ($theme ? 'border-bottom: var(--border-base);' : '')};
`;
