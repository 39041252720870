/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationActor,
  NegotiationActorFromJSON,
  NegotiationActorFromJSONTyped,
  NegotiationActorToJSON,
  NegotiationParty,
  NegotiationPartyFromJSON,
  NegotiationPartyFromJSONTyped,
  NegotiationPartyToJSON,
  NegotiationReplyTime,
  NegotiationReplyTimeFromJSON,
  NegotiationReplyTimeFromJSONTyped,
  NegotiationReplyTimeToJSON,
  NegotiationStatus,
  NegotiationStatusFromJSON,
  NegotiationStatusFromJSONTyped,
  NegotiationStatusToJSON,
  NegotiationSubject,
  NegotiationSubjectFromJSON,
  NegotiationSubjectFromJSONTyped,
  NegotiationSubjectToJSON,
  NegotiationTerm,
  NegotiationTermFromJSON,
  NegotiationTermFromJSONTyped,
  NegotiationTermToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationRoundResult
 */
export interface NegotiationRoundResult {
  /**
   *
   * @type {string}
   * @memberof NegotiationRoundResult
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof NegotiationRoundResult
   */
  committedAt: Date | null;
  /**
   *
   * @type {NegotiationActor}
   * @memberof NegotiationRoundResult
   */
  committedBy: NegotiationActor | null;
  /**
   *
   * @type {Array<NegotiationTerm>}
   * @memberof NegotiationRoundResult
   */
  terms: Array<NegotiationTerm>;
  /**
   * Global subjects that are not related to a term.
   * @type {Array<NegotiationSubject>}
   * @memberof NegotiationRoundResult
   */
  subjects: Array<NegotiationSubject>;
  /**
   *
   * @type {Date}
   * @memberof NegotiationRoundResult
   */
  sentAt: Date | null;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationRoundResult
   */
  party: NegotiationParty;
  /**
   * Time the opponent has to send the next round.
   * @type {NegotiationReplyTime}
   * @memberof NegotiationRoundResult
   */
  replyTime: NegotiationReplyTime | null;
  /**
   *
   * @type {NegotiationStatus}
   * @memberof NegotiationRoundResult
   */
  negotiationStatus: NegotiationStatus;
}

export function NegotiationRoundResultFromJSON(json: any): NegotiationRoundResult {
  return NegotiationRoundResultFromJSONTyped(json, false);
}

export function NegotiationRoundResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationRoundResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    committedAt: json['committedAt'],
    committedBy: NegotiationActorFromJSON(json['committedBy']),
    terms: (json['terms'] as Array<any>).map(NegotiationTermFromJSON),
    subjects: (json['subjects'] as Array<any>).map(NegotiationSubjectFromJSON),
    sentAt: json['sentAt'],
    party: NegotiationPartyFromJSON(json['party']),
    replyTime: NegotiationReplyTimeFromJSON(json['replyTime']),
    negotiationStatus: NegotiationStatusFromJSON(json['negotiationStatus']),
  };
}

export function NegotiationRoundResultToJSON(value?: NegotiationRoundResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    committedAt: value.committedAt,
    committedBy: NegotiationActorToJSON(value.committedBy),
    terms: (value.terms as Array<any>).map(NegotiationTermToJSON),
    subjects: (value.subjects as Array<any>).map(NegotiationSubjectToJSON),
    sentAt: value.sentAt,
    party: NegotiationPartyToJSON(value.party),
    replyTime: NegotiationReplyTimeToJSON(value.replyTime),
    negotiationStatus: NegotiationStatusToJSON(value.negotiationStatus),
  };
}
