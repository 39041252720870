import {FC, ReactNode} from 'react';
import {TermContent} from './Components/TermContent/TermContent';
import {TermIndex} from './Components/TermIndex';
import {NegotiationColorSet, defaultTermColors} from '../../../utils/colors';
import styled from 'styled-components';
import {TermStyleProvider} from './TermStyleProvider';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';

type TermItemDumbProps = {
  containerRef?: React.RefObject<HTMLDivElement>;
  termId?: string | number;
  indexLabel: number | string | ReactNode;
  text?: string;
  previousText?: string | null;
  colors?: NegotiationColorSet;
  isHighlighted?: boolean;
  isRejectRequested?: boolean;
  isRejected?: boolean;
  isExpanded?: boolean;
  dragListeners?: SyntheticListenerMap;
  onTextClick?: () => void;
  indexAddon?: ReactNode;
  beforeContentAddon?: ReactNode;
  aboveContentAddon?: ReactNode;
  belowContentAddon?: ReactNode;
  contentReplacement?: ReactNode;
  aboveTextAddon?: ReactNode;
  beforeTextAddon?: ReactNode;
  afterTextAddon?: ReactNode;
  textReplacement?: ReactNode;
  afterTermAddon?: ReactNode;
  'data-testid'?: string;
};

/**
 * Provides the basic UI for a single term in the negotiation.
 */
export const TermItemDumb: FC<TermItemDumbProps> = ({
  containerRef,
  termId,
  indexLabel,
  text,
  colors = defaultTermColors,
  isHighlighted,
  isRejectRequested,
  isRejected,
  isExpanded,
  dragListeners,
  onTextClick,
  indexAddon,
  beforeContentAddon,
  aboveContentAddon,
  belowContentAddon,
  contentReplacement,
  aboveTextAddon,
  beforeTextAddon,
  afterTextAddon,
  textReplacement,
  afterTermAddon,
  'data-testid': dataTestId = 'TermItem',
}) => {
  return (
    <TermStyleProvider $colors={colors}>
      <TermItemContainer
        ref={containerRef}
        id={`term-${termId}`}
        $isExpanded={isExpanded}
        data-testid={dataTestId || 'TermItem'}>
        <TermIndex
          label={indexLabel}
          isHighlighted={isHighlighted}
          indexAddon={indexAddon}
          dragListeners={dragListeners}
        />
        <TermBody>
          {beforeContentAddon}
          <TermContentContainer>
            {aboveContentAddon}
            {contentReplacement ?? (
              <TermContent
                text={text}
                onTextClick={onTextClick}
                aboveTextAddon={aboveTextAddon}
                beforeTextAddon={beforeTextAddon}
                afterTextAddon={afterTextAddon}
                textReplacement={textReplacement}
                isRejected={isRejected}
                isRejectRequested={isRejectRequested}
              />
            )}
            {belowContentAddon}
          </TermContentContainer>
        </TermBody>
      </TermItemContainer>
      {afterTermAddon}
    </TermStyleProvider>
  );
};

const TermItemContainer = styled.div<{$isExpanded?: boolean}>`
  --term-item-gap: 10px;

  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: space-between;
  gap: var(--term-item-gap);
  width: 100%;
  ${({$isExpanded}) => ($isExpanded ? 'padding: 15px 0;' : '')}
  padding-bottom: 5px;
  transition:
    padding 0.2s ease-in-out,
    height 0.2s ease-in-out;
`;

const TermBody = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: stretch;
  width: 100%;
  height: auto;
`;

export const TermContentContainer = styled.div`
  flex-grow: 1;
  height: auto;
  width: auto;
  padding: 0;
  border-width: var(--border-width-active);
  transition: height 0.2s ease-in-out;
`;
