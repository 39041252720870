/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ApiErrorDTO,
  ApiErrorDTOFromJSON,
  ApiErrorDTOToJSON,
  UserProfile,
  UserProfileFromJSON,
  UserProfileToJSON,
} from '../models';

export interface AddFriendRequest {
  userId: number;
}

export interface ConfirmFriendRequest {
  userId: number;
}

export interface DenyFriendRequest {
  userId: number;
}

export interface GetFriendRecommendationsRequest {
  pageSize?: number;
}

export interface GetUserRequest {
  userId: number;
}

export interface GetUserByEmailAddressRequest {
  emailAddress: string;
}

export interface RemoveFriendRequest {
  userId: number;
}

export interface WithdrawFriendRequest {
  userId: number;
}

/**
 *
 */
export class SocialApi extends runtime.BaseAPI {
  /**
   * Send a friend request.
   */
  async addFriendRaw(requestParameters: AddFriendRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling addFriend.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/{userId}/connect`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send a friend request.
   */
  async addFriend(requestParameters: AddFriendRequest): Promise<void> {
    await this.addFriendRaw(requestParameters);
  }

  /**
   * Confirm a friend request.
   */
  async confirmFriendRaw(requestParameters: ConfirmFriendRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling confirmFriend.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/{userId}/confirm`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Confirm a friend request.
   */
  async confirmFriend(requestParameters: ConfirmFriendRequest): Promise<void> {
    await this.confirmFriendRaw(requestParameters);
  }

  /**
   * Deny a friend request.
   */
  async denyFriendRaw(requestParameters: DenyFriendRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling denyFriend.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/{userId}/deny`.replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deny a friend request.
   */
  async denyFriend(requestParameters: DenyFriendRequest): Promise<void> {
    await this.denyFriendRaw(requestParameters);
  }

  /**
   */
  async getFriendRecommendationsRaw(
    requestParameters: GetFriendRecommendationsRequest
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {};

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/contacts/recommendations`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async getFriendRecommendations(requestParameters: GetFriendRecommendationsRequest): Promise<object> {
    const response = await this.getFriendRecommendationsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetch details about a given user by their user id.
   */
  async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UserProfile>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getUser.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => UserProfileFromJSON(jsonValue));
  }

  /**
   * Fetch details about a given user by their user id.
   */
  async getUser(requestParameters: GetUserRequest): Promise<UserProfile> {
    const response = await this.getUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetch details about a given user by their email address (case insensitive).
   */
  async getUserByEmailAddressRaw(
    requestParameters: GetUserByEmailAddressRequest
  ): Promise<runtime.ApiResponse<UserProfile>> {
    if (requestParameters.emailAddress === null || requestParameters.emailAddress === undefined) {
      throw new runtime.RequiredError(
        'emailAddress',
        'Required parameter requestParameters.emailAddress was null or undefined when calling getUserByEmailAddress.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/by/email/{emailAddress}`.replace(
        `{${'emailAddress'}}`,
        encodeURIComponent(String(requestParameters.emailAddress))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => UserProfileFromJSON(jsonValue));
  }

  /**
   * Fetch details about a given user by their email address (case insensitive).
   */
  async getUserByEmailAddress(requestParameters: GetUserByEmailAddressRequest): Promise<UserProfile> {
    const response = await this.getUserByEmailAddressRaw(requestParameters);
    return await response.value();
  }

  /**
   * Remove a friend.
   */
  async removeFriendRaw(requestParameters: RemoveFriendRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling removeFriend.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/{userId}/disconnect`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove a friend.
   */
  async removeFriend(requestParameters: RemoveFriendRequest): Promise<void> {
    await this.removeFriendRaw(requestParameters);
  }

  /**
   * Withdraw a friend request.
   */
  async withdrawFriendRaw(requestParameters: WithdrawFriendRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling withdrawFriend.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/{userId}/withdraw`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Withdraw a friend request.
   */
  async withdrawFriend(requestParameters: WithdrawFriendRequest): Promise<void> {
    await this.withdrawFriendRaw(requestParameters);
  }
}
