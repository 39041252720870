import {Card, ConfigProvider, DatePicker, Input, Select, Table} from 'antd';
import {TimeCharterCalculationProp, TimeCharterCalculationResult} from './timeCharterCalculation';
import dayjs from 'dayjs';
import {Icon} from '../../../atoms/Icon';
import {Button} from '../../../atoms/Button/Button';
import styled from 'styled-components';
import {numberFormat} from '../../../utils/formatter';
import clone from 'lodash/clone';
import {FC} from 'react';
import {NotAvailable} from '../../../utils/NotAvailable';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';

const {Option} = Select;

export type LegType = 'ballast' | 'waiting' | 'on-hire';

export interface VoyageLeg {
  type: LegType;
  dateFrom: string;
  dateTo: string;
  hirePerDay: number;
  commission: number;
}

export interface VoyageInput {
  legs: Array<VoyageLeg>;
}

export interface VesselProps {
  onChange: (obj: VoyageInput) => void;
  inputState: TimeCharterCalculationProp;
  outputState: TimeCharterCalculationResult;
}

const voyageLegTemplate: VoyageLeg = {
  type: 'on-hire',
  dateFrom: dayjs().format(DateTimeFormat.IsoDate),
  dateTo: dayjs().format(DateTimeFormat.IsoDate),
  hirePerDay: 0,
  commission: 0,
};

export const VoyageInformation: FC<VesselProps> = ({onChange, inputState, outputState}) => {
  const updateLeg = (index: number, update: Partial<VoyageLeg>) => {
    const legs = [...inputState.voyage.legs];
    legs[index] = {...legs[index], ...update};
    onChange({...inputState.voyage, legs});
  };

  const deleteLeg = (index: number) => {
    const legs = [...inputState.voyage.legs];
    legs.splice(index, 1);
    onChange({...inputState.voyage, legs});
  };

  return (
    <VoyageInformationContainer title={<CardTitle>Voyage information</CardTitle>}>
      <ConfigProvider renderEmpty={() => null}>
        <Table
          pagination={false}
          dataSource={[...inputState.voyage.legs]}
          columns={[
            {
              key: 'type',
              dataIndex: 'type',
              title: 'Leg type',
              render: (v, leg, index) => {
                return (
                  <Select
                    style={{width: '100px'}}
                    onChange={value => {
                      updateLeg(index, {type: value});
                    }}
                    value={v}>
                    <Option value="on-hire">on-hire</Option>
                    <Option value="ballast">ballast</Option>
                    <Option value="waiting">waiting</Option>
                  </Select>
                );
              },
            },
            {
              key: 'dateRange',
              title: 'From - To',
              render: (v, leg, index) => (
                <DatePicker.RangePicker
                  style={{width: 270}}
                  onChange={(_, range) => {
                    if (!range) {
                      updateLeg(index, {dateFrom: '', dateTo: ''});
                      return;
                    }
                    const [from, to] = range;
                    updateLeg(index, {dateFrom: from, dateTo: to});
                  }}
                  value={leg.dateFrom && leg.dateTo ? [dayjs(leg.dateFrom), dayjs(leg.dateTo)] : null}
                />
              ),
            },
            {
              key: 'days',
              dataIndex: 'days',
              title: 'Days total',
              render: (_, __, index) => {
                return outputState.voyage.legs[index]?.days;
              },
            },
            {
              key: 'hirePerDay',
              dataIndex: 'hirePerDay',
              title: 'Hire per Day',
              render: (v, leg, index) => (
                <Input
                  type={'number'}
                  addonAfter={'$'}
                  value={v}
                  disabled={leg.type !== 'on-hire'}
                  onChange={event => updateLeg(index, {hirePerDay: parseFloat(event.currentTarget.value)})}
                />
              ),
            },
            {
              key: 'commission',
              dataIndex: 'commission',
              title: 'Commission',
              render: (v, leg, index) => (
                <Input
                  type={'number'}
                  addonAfter={'%'}
                  value={v}
                  disabled={leg.type !== 'on-hire'}
                  onChange={event => updateLeg(index, {commission: parseFloat(event.currentTarget.value)})}
                />
              ),
            },
            {
              key: 'gross',
              dataIndex: 'gross',
              title: 'Gross total',
              render: (_, leg, index) => (
                <ResultTotal>
                  {leg.type === 'on-hire' ? '$' + numberFormat(outputState.voyage.legs[index]?.gross) : NotAvailable}
                </ResultTotal>
              ),
            },
            {
              key: 'net',
              dataIndex: 'net',
              title: 'Net total',
              render: (_, leg, index) => (
                <ResultTotal>${numberFormat(outputState.voyage.legs[index]?.net)}</ResultTotal>
              ),
            },
            {
              key: 'remove',
              dataIndex: 'remove',
              title: '',
              render: (_, __, index) => <Icon type="close" onClick={() => deleteLeg(index)} />,
            },
          ]}
        />
      </ConfigProvider>
      <AddButton
        onClick={() => {
          onChange({
            ...inputState.voyage,
            legs: [...inputState.voyage.legs, clone(voyageLegTemplate)],
          });
        }}
        icon
        label={
          <>
            <Icon type={'item-add-selection-solid'} color="blue" />
            Add leg
          </>
        }
      />
      <Help>
        <p>Note:</p>
        <ul>
          <li>Legs that are 'on-hire' affect freight, but not bunker cost</li>
          <li>Legs that are 'ballast' raise bunker cost by selected ballast consumption</li>
          <li>Legs that are 'waiting' raise bunker cost by selected waiting consumption</li>
        </ul>
      </Help>
    </VoyageInformationContainer>
  );
};

const VoyageInformationContainer = styled(Card)`
  .ant-card-head-wrapper {
    min-height: unset !important;
  }
`;

const CardTitle = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`;

const AddButton = styled(Button)`
  margin-top: 20px;
  text-transform: uppercase;

  .icon {
    vertical-align: middle;
    margin-right: 6px;
  }
`;

const ResultTotal = styled.div`
  width: 90px;
  white-space: pre;
`;

const Help = styled.div`
  margin-top: 20px;
  color: var(--color-gray-2);
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;
