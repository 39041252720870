import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError} from '../../../../api/utils/ApiError';
import {annotationApi} from '../../../../api/annotations/annotationsApi';
import {DeleteAnnotationByIdRequest} from '../../../../api/annotations/generated';

export const useRemoveAnnotationMutation = (
  options: UseMutationOptions<void, ApiError, DeleteAnnotationByIdRequest>
) => {
  const deleteAnnotation = async (requestData: DeleteAnnotationByIdRequest) => {
    await annotationApi.deleteAnnotationById(requestData);
  };
  return useMutation({
    mutationFn: deleteAnnotation,
    ...options,
  });
};
