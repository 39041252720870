import {Button, Checkbox, Form, Popover} from 'antd';
import styled from 'styled-components';

export type EnabledFilters = Record<
  'size' | 'type' | 'etaActuality' | 'dataActuality' | 'history' | 'etaTooLate',
  boolean
>;

export const defaultFilters: EnabledFilters = {
  size: true,
  type: true,
  etaActuality: true,
  dataActuality: true,
  etaTooLate: true,
  history: true,
};

export const FilterOptionDropdown = (props: {
  onChange: (distanceCalculatorValues: EnabledFilters) => void;
  value: EnabledFilters;
}) => {
  return (
    <Popover
      placement={'bottom'}
      title={'Filter options'}
      trigger={['click']}
      content={
        <Form<EnabledFilters>
          onValuesChange={changed => {
            props.onChange({...props.value, ...changed});
          }}
          initialValues={props.value}
          style={{padding: '0 10px'}}>
          <StyledFormItem valuePropName={'checked'} name={'dataActuality'}>
            <Checkbox>Data updated w. 10d</Checkbox>
          </StyledFormItem>
          <StyledFormItem valuePropName={'checked'} name={'etaActuality'}>
            <Checkbox>ETA updated w. 30d</Checkbox>
          </StyledFormItem>
          <StyledFormItem valuePropName={'checked'} name={'size'}>
            <Checkbox>Filter by size</Checkbox>
          </StyledFormItem>
          <StyledFormItem valuePropName={'checked'} name={'etaTooLate'}>
            <Checkbox>ETA past "To date"</Checkbox>
          </StyledFormItem>
        </Form>
      }>
      <Button
        style={{
          backgroundColor: 'var(--color-azure)',
          borderColor: 'var(--color-azure)',
        }}
        type={'primary'}>
        Filter options
      </Button>
    </Popover>
  );
};

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 5px;
  }
`;
