import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {StyledRangePicker} from '../../../RangePicker/StyledRangePicker';
import {filterBranchDefinition} from './LaycanDefinition';
import {getErrorMessage} from '../../../../utils/helper';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const Laycan = ({filterProviderApi}: Props) => {
  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size={'medium'}>
      {({handleRangePickerChange, values, validations}) => {
        const fromValue = values.from;
        const toValue = values.to;
        const fromError = getErrorMessage('from', validations);
        const toError = getErrorMessage('to', validations);
        const error = !!fromError || !!toError;
        return (
          <div className="container">
            <div className="row bottom-fix">
              <div className="scol-12">
                <LabelWrapper label="Date" id={'filter-laycan-date'} hasError={error}>
                  <StyledRangePicker
                    id={'form-laycan-from form-laycan-to'}
                    format={DateTimeFormat.Date}
                    value={[fromValue, toValue]}
                    getPopupContainer={() => {
                      return document.getElementById('laycanFilter')!;
                    }}
                    placeholder={['From/Laydays', 'To/Cancelling']}
                    onChange={value => {
                      const [from, to] = [...(value ?? [null, null])];
                      handleRangePickerChange({from, to});
                    }}
                  />
                  {fromError && <div className={'input-info input-info--hasError'}>{fromError}</div>}
                  {toError && <div className={'input-info input-info--hasError'}>{toError}</div>}
                  <div id="laycanFilter" />
                </LabelWrapper>
              </div>
            </div>
          </div>
        );
      }}
    </FilterButton>
  );
};

export default Laycan;
