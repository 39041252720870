/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyStatisticsRow,
  CompanyStatisticsRowFromJSON,
  CompanyStatisticsRowFromJSONTyped,
  CompanyStatisticsRowToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyStatisticsMarket
 */
export interface CompanyStatisticsMarket {
  /**
   *
   * @type {CompanyStatisticsRow}
   * @memberof CompanyStatisticsMarket
   */
  created: CompanyStatisticsRow;
  /**
   *
   * @type {CompanyStatisticsRow}
   * @memberof CompanyStatisticsMarket
   */
  received: CompanyStatisticsRow;
  /**
   *
   * @type {{ [key: string]: CompanyStatisticsRow; }}
   * @memberof CompanyStatisticsMarket
   */
  history?: {[key: string]: CompanyStatisticsRow};
}

export function CompanyStatisticsMarketFromJSON(json: any): CompanyStatisticsMarket {
  return CompanyStatisticsMarketFromJSONTyped(json, false);
}

export function CompanyStatisticsMarketFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyStatisticsMarket {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    created: CompanyStatisticsRowFromJSON(json['created']),
    received: CompanyStatisticsRowFromJSON(json['received']),
    history: !exists(json, 'history') ? undefined : mapValues(json['history'], CompanyStatisticsRowFromJSON),
  };
}

export function CompanyStatisticsMarketToJSON(value?: CompanyStatisticsMarket | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    created: CompanyStatisticsRowToJSON(value.created),
    received: CompanyStatisticsRowToJSON(value.received),
    history: value.history === undefined ? undefined : mapValues(value.history, CompanyStatisticsRowToJSON),
  };
}
