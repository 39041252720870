import capitalize from 'lodash/capitalize';
import {FC} from 'react';
import styled from 'styled-components';
import LoadingAnimation from '../../../atoms/Loading';
import {useGetWeatherAndLocationByLatLonQuery} from '../../../queries/useGetWeatherAndLocationByLatLonQuery';
import {TODO} from '../../../utils/TODO';
import {WeatherDay} from './WeatherDay';
import {WeatherIcon} from './WeatherIcon';

export const PortWeather: FC<{lat: number; lon: number}> = ({lat, lon}) => {
  const getWeatherAndLocationByLatLonQuery = useGetWeatherAndLocationByLatLonQuery({lat: lat, lon: lon});
  const {isInitialLoading: isLoading, data: weatherData} = getWeatherAndLocationByLatLonQuery;

  if (isLoading) {
    return <LoadingAnimation />;
  }

  const {current, daily} = weatherData;
  const {id, description} = current.weather[0];

  return (
    <WeatherDetailContainer>
      <WeatherLabel>Current weather</WeatherLabel>
      <FlexBox>
        <WeatherDescription>
          <WeatherIcon id={id} isLarge />
          {capitalize(description)}
        </WeatherDescription>
      </FlexBox>
      <FlexBox>
        <WeatherValue>{Math.round(current.temp)} °C Temp.</WeatherValue>
        <WeatherValue>{current.wind_speed} KPH wind</WeatherValue>
        <WeatherValue>{Math.round(current.humidity)} % Humidity</WeatherValue>
      </FlexBox>
      <WeatherLabel>Upcoming weather</WeatherLabel>
      <WeatherList>{daily?.map((day: TODO) => <WeatherDay data={day} key={day.dt} />)}</WeatherList>
    </WeatherDetailContainer>
  );
};

const WeatherDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
`;

const WeatherValue = styled.div`
  position: relative;
  width: 33%;
  margin-bottom: 15px;

  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    width: 1px;
    background: var(--color-gray-3);
  }
`;

const WeatherLabel = styled.h3`
  width: 100%;
  margin-bottom: 2px;
  color: var(--color-gray-2);
  font-weight: bold;
  text-transform: uppercase;
`;

const WeatherDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
`;

const WeatherList = styled.div`
  flex-grow: 1;
  height: auto;
  max-height: 110px;
  width: 100%;
  border: var(--border-base);
  border-radius: var(--border-radius);
  overflow-y: scroll;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.06);
`;
