import React, {FC} from 'react';
import cx from 'classnames';

import './detailtable.scss';

interface Props {
  fullWidth?: boolean;
  fixedLayout?: boolean;
  children: React.ReactNode;
}

export const DetailTable: FC<Props> = ({fullWidth, children, fixedLayout = true}) => (
  <table
    className={cx(
      'detail-table__table',
      {'detail-table__table--full-width': fullWidth},
      {'detail-table__table--fixed-layout': fixedLayout}
    )}>
    {children}
  </table>
);

export default DetailTable;
