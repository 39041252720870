import React, {FC, ReactNode} from 'react';
import {NewTerm} from '../../../../../../api/node-backend/generated';
import styled from 'styled-components';
import {mapNegotiationColorSet} from '../../../../utils/colors';
import {InlineTermTypeLabel} from '../../TermItem/Components/TermType/TermTypeLabel';
import {TermItemDumb} from '../../TermItem/TermItemDumb';

type TermPreviewProps = {
  terms: NewTerm[];
  showPreviewIfEmpty?: boolean;
  children: ReactNode;
};

export const TermPreview: FC<TermPreviewProps> = ({terms, showPreviewIfEmpty, children}) => {
  return (
    <TermPreviewContainer>
      <FlexBox>
        <OverflowBox>{children}</OverflowBox>
        {showPreviewIfEmpty || terms.length ? (
          <OverflowBox>
            <PreviewArea>
              <PreviewLabel>Preview:</PreviewLabel>
              {terms.length ? (
                terms.map((term, idx) => (
                  <TermItemDumb
                    key={idx}
                    isHighlighted
                    colors={mapNegotiationColorSet('self')}
                    indexLabel={term.sortIndex + 1}
                    aboveTextAddon={<InlineTermTypeLabel termType={term.type} />}
                    text={term.text}
                  />
                ))
              ) : (
                <p>No terms</p>
              )}
            </PreviewArea>
          </OverflowBox>
        ) : null}
      </FlexBox>
    </TermPreviewContainer>
  );
};

const TermPreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 8px auto;
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
`;

const OverflowBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  height: 300px;
  border: var(--border-base);
  border-radius: var(--border-radius-button);
  overflow: hidden;
`;

const PreviewArea = styled.div`
  width: 100%;
  height: 300px !important;
  padding: 5px 10px 10px;
  color: var(--color-black);
  text-align: start;
  overflow-x: hidden;
  overflow-y: auto;
`;

const PreviewLabel = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 5px;
`;
