import styled from 'styled-components';

export const BannerContainer = styled.div`
  position: relative;
  padding: 30px;
  border-radius: 8px;
  overflow: hidden;
`;

export const BannerContent = styled.div`
  position: relative;
  z-index: 1;
`;

export const BannerTitle = styled.div`
  margin: 0 0 5px;
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;
