import {FC} from 'react';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import {getMedian} from '../../utils/getMedian';
import {NumberStatistics} from './NumberStatistics';

export const MedianServiceTime: FC<{visits: MergedPortCongestionVisit[]; title: string; dataTestId?: string}> = ({
  visits,
  title,
  dataTestId,
}) => {
  const passageDurationMedian = getMedian(visits.map(visit => visit.portDuration));

  return (
    <NumberStatistics
      precision={2}
      title={title}
      dataTestId={dataTestId}
      value={passageDurationMedian / 24}
      suffix={'d'}
    />
  );
};
