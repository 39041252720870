import {CurrentUser, FeaturePermission} from '../api/symfony/generated';

/**
 * Returns whether the given users has the required permission.
 *
 */
export const isPermittedToUser = (requiredPermission: FeaturePermission | undefined, user: CurrentUser): boolean => {
  if (!requiredPermission) {
    // If the feature does not require a permission, it is permitted.
    return true;
  }

  const permitted = user.permissions.includes(requiredPermission);
  return permitted;
};
