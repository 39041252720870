import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

type Props = {
  calculated?: boolean;
  width?: number;
  className?: string;
  children: ReactNode;
};

export const Header = ({calculated, width, className, children}: Props) => {
  return (
    <StyledTH $calculated={calculated} $width={width} className={className}>
      {children}
    </StyledTH>
  );
};

const StyledTH = styled.th<{$calculated?: boolean; $width?: number}>`
  padding: 0 2px;
  ${props =>
    props.$width &&
    css`
      width: ${props.$width}px;
      max-width: ${props.$width}px;
    `}

  ${props =>
    props.$calculated &&
    css`
      color: var(--transparent-extradark);
    `}
`;
