import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {NegotiationRoundResult} from '../../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../../api/utils/ApiError';
import {CancelNegotiationRequest} from '../../../../../../api/node-backend/generated/models/CancelNegotiationRequest';

export const useCancelNegotiationMutation = (
  options?: UseMutationOptions<
    NegotiationRoundResult,
    ApiError,
    {negotiationId: string; cancellationReason?: CancelNegotiationRequest['cancellationReason']}
  >
) =>
  useMutation({
    mutationFn: async ({negotiationId, cancellationReason}) => {
      const result = await negotiationNegotiationApi.cancelNegotiation({
        negotiationId,
        cancelNegotiationRequest: {
          cancellationReason: cancellationReason ?? null,
        },
      });

      return result;
    },
    ...options,
  });
