import dayjs from 'dayjs';
import {InputState, OutputState} from '../voyageChartercalculation/voyageChartercalculation';
import {Warning, WarningOutput} from '../../Warning/WarningTypes';
import {isFittingCapacity} from './isFittingCapacity';

export const getDataFieldId = (params: {area: string; field: string}) => `${params.area}/${params.field}`;

export const getWarningAreaForVoyagePoint = (pointIndex: number) => `voyage/points/${pointIndex}`;
export const getWarningAreaForVoyageRoute = (routeIndex: number) => `voyage/routes/${routeIndex}`;

export const calcWarnings = (inputState: InputState, outputState: OutputState): WarningOutput => {
  const list: Array<Warning> = [];

  const load = inputState.voyage.points.reduce((total, item) => {
    return total + item.load;
  }, 0);
  const dis = inputState.voyage.points.reduce((total, item) => {
    return total + item.dis;
  }, 0);

  if (!inputState.cargo.cargoQuantity) {
    list.push({
      type: 'error',
      message: 'Please add cargo quantity.',
      code: 'cargo-has-no-quantity',
      areas: ['cargo'],
    });
  }

  if (
    inputState.voyage.laycanTo &&
    inputState.voyage.laycanFrom &&
    inputState.voyage.laycanFrom > inputState.voyage.laycanTo
  ) {
    list.push({
      type: 'error',
      message: 'Laycan from / laydays must be earlier than laycan to / cancelling.',
      code: 'cargo-laycan-to-befor-from',
      areas: ['cargo'],
    });
  }

  if (load !== dis) {
    list.push({
      type: 'error',
      message: 'The loading and discharging quantity does not match.',
      code: 'voyage-load-dis-not-equal',
      areas: ['voyage'],
    });
  }

  if (!isFittingCapacity(inputState)) {
    list.push({
      type: 'error',
      message: 'Vessel cargo capacity exceeded.',
      code: 'cargo-quantity-stowage-factor-too-high',
      areas: ['vessel', 'cargo'],
    });
  }

  if (load !== inputState.cargo.cargoQuantity) {
    list.push({
      type: 'error',
      message: 'Cargo quantity does not match quantities loaded / discharged.',
      code: 'voyage-quantity-cargo-quantity-not-equal',
      areas: ['voyage', 'cargo'],
    });
  }

  if (inputState.cost.costIfoPerMts === undefined) {
    list.push({
      type: 'error',
      code: 'expenses-ifo-undefined',
      message: 'Please set a bunker price for IFO.',
      areas: ['costs'],
    });
  }
  if (inputState.cost.costIfoPerMts === 0) {
    list.push({
      type: 'warning',
      code: 'expenses-ifo-is-0',
      message: 'Please set a bunker price for IFO.',
      areas: ['costs'],
    });
  }

  if (inputState.cost.costMdoPerMts === undefined) {
    list.push({
      type: 'error',
      code: 'expenses-mdo-undefined',
      message: 'Please set a bunker price for MDO.',
      areas: ['costs'],
    });
  }
  if (inputState.cost.costMdoPerMts === 0) {
    list.push({
      type: 'warning',
      code: 'expenses-mdo-is-0',
      message: 'Please set a price for MDO.',
      areas: ['costs'],
    });
  }
  if (inputState.cost.costMgoPerMts === 0) {
    list.push({
      type: 'warning',
      code: 'expenses-mgo-is-0',
      message: 'Please set a bunker price for MGO.',
      areas: ['costs'],
    });
  }

  if (inputState.vessel.consumptionModes.length === 0) {
    list.push({
      type: 'error',
      code: 'vessel-consumption-not-set',
      message: 'Please set the consumption modes.',
      areas: ['vessel-consumption'],
    });
  }
  if (inputState.voyage.points.length < 2) {
    list.push({
      type: 'error',
      code: 'voyage-add-legs',
      message: 'Please add voyage legs.',
      areas: ['voyage'],
    });
  }

  inputState.voyage.points.forEach((item, pointIndex) => {
    if (item.dis !== 0 && typeof item.dischargingRate !== 'number') {
      const area = getWarningAreaForVoyagePoint(pointIndex);
      list.push({
        type: 'warning',
        code: 'check-dis-rates',
        dataFieldId: getDataFieldId({area, field: 'dischargingRate'}),
        message: 'Please add discharging rates.',
        areas: [area],
      });
    }

    if (item.load !== 0 && typeof item.loadingRate !== 'number') {
      const area = getWarningAreaForVoyagePoint(pointIndex);

      list.push({
        type: 'warning',
        code: 'check-load-rates',
        dataFieldId: getDataFieldId({area, field: 'loadingRate'}),
        message: 'Please add loading rates.',
        areas: [area],
      });
    }

    if (item.consumption?.mainConsumption === undefined) {
      const area = getWarningAreaForVoyagePoint(pointIndex);

      list.push({
        type: 'error',
        code: 'voyage-point-add-consumption',
        dataFieldId: getDataFieldId({area, field: 'consumption.consumption'}),

        message: 'Please add vessel consumption details.',
        areas: [area],
      });
    }
    if (item.portCosts === undefined) {
      const area = getWarningAreaForVoyagePoint(pointIndex);

      list.push({
        type: 'warning',
        code: 'voyage-add-port-expenses',
        dataFieldId: getDataFieldId({area, field: 'portCosts'}),
        message: 'Please add port expenses.',
        areas: [area],
      });
    }

    const termsAtVoyagePoint = item.termsDischarging ?? item.termsLoading;

    if (item.rawTerm !== undefined && item.rawTerm !== termsAtVoyagePoint) {
      const area = getWarningAreaForVoyagePoint(pointIndex);

      list.push({
        type: 'warning',
        code: 'voyage-point-term-could-not-be-interpreted',
        dataFieldId: getDataFieldId({
          area,
          field: item.types.includes('loading') ? 'termsLoading' : 'termsDischarging',
        }),

        message: 'Please add loading / discharging terms.',
        areas: [area],
      });
    }

    // Add Loading terms
    if (item.loadingRate !== undefined && item.termsLoading === undefined) {
      const area = getWarningAreaForVoyagePoint(pointIndex);

      list.push({
        type: 'warning',
        code: 'voyage-point-add-loading-terms',
        dataFieldId: getDataFieldId({
          area,
          field: 'termsLoading',
        }),

        message: 'Please add loading terms.',
        areas: [area],
      });
    }

    // Add Discharging terms
    if (item.dischargingRate !== undefined && item.termsDischarging === undefined) {
      const area = getWarningAreaForVoyagePoint(pointIndex);

      list.push({
        type: 'warning',
        code: 'voyage-point-add-discharging-terms',
        dataFieldId: getDataFieldId({
          area,
          field: 'termsDischarging',
        }),

        message: 'Please add discharging terms.',
        areas: [area],
      });
    }

    const pointOnOutput = outputState.voyage.points[pointIndex];

    if (inputState.voyage.laycanTo && item.types?.includes('loading')) {
      const laycanTo = inputState.voyage.laycanTo;
      const startDate = dayjs(pointOnOutput.startDate);
      const isNotInLaycan = laycanTo.diff(startDate) < 0;
      if (isNotInLaycan) {
        list.push({
          type: 'warning',
          code: 'vessel-arrives-too-late-to-meet-laycan-dates',
          message: 'Vessel will not meet the laycan.',
          areas: [getWarningAreaForVoyagePoint(pointIndex)],
        });
      }
    }
  });

  outputState.voyage.points.forEach((item, pointIndex) => {
    if (item.usedDwat < 0) {
      list.push({
        type: 'warning',
        code: 'dwt-temporarily-below-0',
        message: 'Discharge quantity exceeds loading quantity.',
        areas: ['vessel', getWarningAreaForVoyagePoint(pointIndex)],
      });
    }
    if (item.usedDwat > (inputState.vessel.dwat || 0)) {
      list.push({
        type: 'error',
        code: 'dwt-temporarily-over-capacity',
        message: 'DWT is exceeded.',
        areas: ['vessel', getWarningAreaForVoyagePoint(pointIndex), 'cargo'],
      });
    }
    const area = getWarningAreaForVoyagePoint(pointIndex);

    // longer than 250 days
    if (item.duration > 250) {
      list.push({
        type: 'warning',
        code: 'duration-voyage-point-too-long',
        message: 'Duration of a leg appears too long.',

        areas: [area],
      });
    }
  });

  inputState.voyage.routes.forEach((routeInput, routeIndex) => {
    if (routeInput.consumption?.mainConsumption === undefined) {
      const area = getWarningAreaForVoyageRoute(routeIndex);
      list.push({
        type: 'error',
        code: 'voyage-route-add-consumption',
        message: 'Please set consumption.',
        dataFieldId: getDataFieldId({area, field: 'consumption.consumption'}),

        areas: [area],
      });
    }
    if (routeInput.consumption?.speed === undefined) {
      const area = getWarningAreaForVoyageRoute(routeIndex);

      list.push({
        type: 'error',
        code: 'voyage-route-add-speed',
        message: 'Please set speed.',
        dataFieldId: getDataFieldId({area, field: 'consumption.speed'}),

        areas: [area],
      });
    }
    if (
      routeInput.consumption &&
      routeInput.consumption.mainFuelType === 'ifo' &&
      !routeInput.consumption.manuallyAdded &&
      (routeInput.distanceInSeca ?? 0) > 0
    ) {
      const area = getWarningAreaForVoyageRoute(routeIndex);

      list.push({
        type: 'warning',
        dataFieldId: getDataFieldId({area, field: 'consumptionMode'}),
        code: 'voyage-route-use-ifo-in-seca',
        message: 'Vessel passes through ECA zone and consumes IFO.',
        areas: [area],
      });
    }
    if (routeInput.consumption?.mainFuelType !== 'ifo' && (routeInput.distanceInSeca ?? 0) === 0) {
      const area = getWarningAreaForVoyageRoute(routeIndex);

      list.push({
        type: 'warning',
        code: 'voyage-route-use-mdo-not-in-seca',
        dataFieldId: getDataFieldId({area, field: 'consumptionMode'}),
        message: 'Vessel consumes MDO / MGO outside ECA zone.',
        areas: [area],
      });
    }
  });

  outputState.voyage.routes.forEach((routeOutput, routeIndex) => {
    if (routeOutput.duration > 6000) {
      const area = getWarningAreaForVoyageRoute(routeIndex);

      list.push({
        type: 'warning',
        code: 'duration-voyage-route-too-long',
        message: 'Duration of the voyage appears too long.',
        areas: [area],
      });
    }
  });

  if (inputState.cargo.freightIdea === undefined) {
    list.push({
      type: 'error',
      code: 'no-freight-idea',
      message: 'Please set freight.',
      areas: ['cargo'],
    });
  }

  return {
    list,
  };
};

export default calcWarnings;
