import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import HorizontalLine from '../../../../../atoms/HorizontalLine';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {AsyncMultiSelect} from '../../../../../atoms/Select/AsyncMultiSelect';
import {GroupHeading} from '../../../../../atoms/Select/otherComponents';
import locationApi from '../../../../../redux/ApiService/locationService';
import {useDispatch} from '../../../../../redux/react-redux';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {hasError} from '../../../../../utils/helper';
import {DropdownMultiValueLocation, getLocationSelectOption} from '../../../../LocationOutput/LocationOutputRow';
import {StyledRangePicker} from '../../../../RangePicker/StyledRangePicker';
import {filterBranchDefinition} from './FixtureLocationLaycanDefinition';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

export const FixtureLocationLaycan = ({filterProviderApi}: Props) => {
  const dispatch = useDispatch();
  const searchAll = (search: string) => dispatch(locationApi.searchAll(search));

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size="medium">
      {({handleChange, values, handleRangePickerChange, validations}) => {
        return (
          <div className="container">
            <div className="row">
              <div className="scol-12">
                <LabelWrapper label={'Load / Delivery'} htmlFor={'filter-fixtures-stations-fixturedelivery'}>
                  <AsyncMultiSelect
                    id={`form-stations-fixturedelivery`}
                    name={`form-stations-fixturedelivery`}
                    value={values.fixturedelivery}
                    onChange={value => handleChange('fixturedelivery', [...value])}
                    getOptionLabel={o => getLocationSelectOption(o, true)}
                    getOptionValue={option => option.id.toString()}
                    loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
                    cacheOptions
                    defaultOptions
                    placeholder="Enter port, country or area"
                    components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                  />
                </LabelWrapper>
              </div>
            </div>
            <div className="row">
              <div className="scol-12">
                <LabelWrapper label={'Trade / Via'} htmlFor={'filter-fixtures-stations-fixturedeliveryvia'}>
                  <AsyncMultiSelect
                    id={`form-stations-fixturedeliveryvia`}
                    name={`form-stations-fixturedeliveryvia`}
                    value={values.fixturedeliveryvia}
                    onChange={value => handleChange('fixturedeliveryvia', [...value])}
                    getOptionLabel={o => getLocationSelectOption(o, true)}
                    getOptionValue={option => option.id.toString()}
                    loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
                    cacheOptions
                    defaultOptions
                    placeholder="Enter port, country or area"
                    components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                  />
                </LabelWrapper>
              </div>
            </div>
            <div className="row">
              <div className="scol-12">
                <LabelWrapper label={'Disch. / Redelivery'} htmlFor={'filter-fixtures-stations-fixtureredelivery'}>
                  <AsyncMultiSelect
                    id={`form-stations-fixtureredelivery`}
                    name={`form-stations-fixtureredelivery`}
                    value={values.fixtureredelivery}
                    onChange={value => handleChange('fixtureredelivery', [...value])}
                    getOptionLabel={o => getLocationSelectOption(o, true)}
                    getOptionValue={option => option.id.toString()}
                    loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
                    cacheOptions
                    defaultOptions
                    placeholder="Enter port, country or area"
                    components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                  />
                </LabelWrapper>
              </div>
            </div>
            <HorizontalLine large topSpace={'small'} />
            <div className="row">
              <div className="scol-12 scol-sm-12">
                <LabelWrapper
                  label={'From/Laydays - To/Cancelling'}
                  htmlFor={'form-stations-laycan-from'}
                  hasError={hasError('laycanFrom', validations)}>
                  <StyledRangePicker
                    data-cy={'DatePickerFromDate DatePickerToDate'}
                    format={DateTimeFormat.Date}
                    value={[values.laycanFrom || null, values.laycanTo || null]}
                    getPopupContainer={() => {
                      return document.getElementById('laysPicker')!;
                    }}
                    placeholder={['From/Laydays', 'To/Cancelling']}
                    onChange={value => {
                      const [from, to] = [...(value ?? [null, null])];
                      handleRangePickerChange({
                        laycanFrom: from,
                        laycanTo: to,
                      });
                    }}
                  />
                  <div id="laysPicker" />
                </LabelWrapper>
              </div>
            </div>
          </div>
        );
      }}
    </FilterButton>
  );
};
