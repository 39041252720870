/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum StationTypeEnum {
  Deliveryvia = 'deliveryvia',
  Discharge = 'discharge',
  Nextopen = 'nextopen',
  Loading = 'loading',
  Redelivery = 'redelivery',
  Fixturedelivery = 'fixturedelivery',
  Fixtureredelivery = 'fixtureredelivery',
  Fixturedeliveryvia = 'fixturedeliveryvia',
}

export function StationTypeEnumFromJSON(json: any): StationTypeEnum {
  return StationTypeEnumFromJSONTyped(json, false);
}

export function StationTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationTypeEnum {
  return json as StationTypeEnum;
}

export function StationTypeEnumToJSON(value?: StationTypeEnum | null): any {
  return value as any;
}
