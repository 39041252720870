import React, {FC, ReactNode, useState} from 'react';
import {Popover} from 'antd';
import {RestrictedAccessPromotion} from './RestrictedAccessPromotion';
import styled from 'styled-components';
import {Icon} from '../../atoms/Icon';

type PopoverTrigger = 'click' | 'hover';

interface Props {
  active: boolean;
  trigger?: PopoverTrigger | PopoverTrigger[];
  headline?: string | ReactNode;
  children: ReactNode;
}

export const PromotionPopover: FC<Props> = ({active, trigger, headline, children}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const toggleVisible = () => {
    if (active) {
      setPopoverVisible(!popoverVisible);
    }
  };

  return (
    <Popover
      content={
        <Container>
          <CloseIcon type="close" onClick={toggleVisible} />
          <RestrictedAccessPromotion headline={headline} />
        </Container>
      }
      trigger={trigger ?? 'click'}
      open={popoverVisible}
      onOpenChange={setPopoverVisible}
      overlayInnerStyle={{padding: '4px 8px 8px'}}>
      <div onClick={toggleVisible}>{children}</div>
    </Popover>
  );
};

const Container = styled.div`
  position: relative;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: -5px;
  width: auto;
  color: var(--color-gray-4);
  cursor: pointer;
  z-index: 1;
`;
