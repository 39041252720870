import {FC, useState} from 'react';
import {Collapse} from '../../../atoms/Collapse/Collapse';
import {Icon} from '../../../atoms/Icon';
import {Checkbox} from '../../../atoms/Checkbox';
import {TODO} from '../../../utils/TODO';
import styled from 'styled-components';

interface Options {
  [key: string]: {
    label: string;
    value: boolean;
  };
}

const RouteOptions = ({options, onChange}: {options: Options; onChange: (key: string, checked: boolean) => void}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RouteOptionsContainer>
      <Header onClick={() => setIsOpen(!isOpen)}>
        <HeaderLabel>Route options</HeaderLabel>
        <div>
          <Icon type={`angle-${isOpen ? 'up' : 'down'}`} />
        </div>
      </Header>
      <RouteOptionsContent isOpen={isOpen}>
        {Object.keys(options).map((key, i) => (
          <Checkbox
            spMT={i === 0 ? undefined : 's'}
            key={`${key}-${i}`}
            id={key}
            label={options[key].label}
            checked={options[key].value}
            onChange={e => onChange(key, e.target.checked)}
          />
        ))}
      </RouteOptionsContent>
    </RouteOptionsContainer>
  );
};

export const RouteOptionsFormItem: FC<{
  onChange?: (date: TODO | null) => void;
  options?: TODO;
}> = ({options, onChange}) => {
  return (
    <RouteOptions
      onChange={(key: string, checked: boolean) => {
        onChange?.({
          ...options,
          [key]: {
            ...options[key],
            value: checked,
          },
        });
      }}
      options={options || {}}
    />
  );
};

const RouteOptionsContainer = styled.div`
  width: 100%;
  background-color: var(--color-gray-6);
  border-top: var(--border-base);
  border-bottom: var(--border-base);
`;

const Header = styled.div`
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  cursor: pointer;
  color: var(--color-gray-2);

  &:hover {
    cursor: pointer;
    color: var(--color-gray-1);

    .icon {
      color: var(--color-gray-1);
    }
  }
`;

const HeaderLabel = styled.h3`
  font-size: var(--font-size-md);
  font-weight: bold;
  line-height: 19px;
`;

const RouteOptionsContent = styled(Collapse)<{isOpen: boolean}>`
  padding: 0 32px;
  width: 100%;

  ${({isOpen}) => isOpen && `padding-bottom: 16px;`}
`;
