import {numberUnitFormat} from '../../../../utils/formatter';
import {percentCalc} from '../../../../utils/helper';
import {FC, RefObject} from 'react';
import styled from 'styled-components';
import {DraftChangeIcon, TimelineItemAttributeRow, TimelineItemAttribute, PortLocation} from './shared';
import {TimelineItemContainer} from './TimelineItemContainer';
import {TimelineEvent, TimelineItemPort} from '../utils/types';
import dayjs from 'dayjs';
import {TradeFlowPortActivity} from '../../../../api/node-backend/generated';

type VoyageTimelineItemPortProps = {
  itemRef: RefObject<HTMLLIElement>;
  index?: number;
  item: TimelineItemPort;
  maxDraft?: number;
  maxDuration?: number;
  isReduced?: boolean;
  isSelected?: boolean;
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: (id: TimelineEvent | null) => void;
};

export const VoyageTimelineItemPort: FC<VoyageTimelineItemPortProps> = ({
  itemRef,
  index,
  item,
  maxDraft,
  maxDuration,
  isReduced,
  isSelected,
  selectedEvent,
  onSelectEvent,
}) => {
  const {
    enterTimestamp,
    exitTimestamp,
    portVisit: {eventType, port, enterDraft, exitDraft},
    subEvents,
    isBallast,
  } = item;

  const duration = enterTimestamp && exitTimestamp ? dayjs(exitTimestamp).diff(enterTimestamp, 'seconds') : null;

  const enterDraftFormatted = numberUnitFormat(enterDraft / 10, 'meters', {toUpperCase: false});
  const enterDraftPercent = numberUnitFormat(percentCalc(enterDraft, maxDraft ?? null), '%', {
    toUpperCase: false,
    unitSpace: false,
    emptyString: '-',
  });

  const exitDraftFormatted = numberUnitFormat((exitDraft ?? enterDraft) / 10, 'meters', {toUpperCase: false});
  const exitDraftPercent = numberUnitFormat(percentCalc(exitDraft ?? enterDraft, maxDraft ?? null), '%', {
    toUpperCase: false,
    unitSpace: false,
    emptyString: '-',
  });

  const handleSelect = () => {
    if (onSelectEvent) {
      onSelectEvent(isSelected ? null : item.portVisit);
    }
  };

  const margin = duration && maxDuration ? Math.floor((duration / maxDuration) * 100) : 0;

  return (
    <TimelineItemContainer
      itemRef={itemRef}
      index={item.index}
      id={`port-call-${index}`}
      data-testid="VoyageTimelineItemPort"
      startDate={enterTimestamp}
      endDate={exitTimestamp}
      markerIcon={'port'}
      type={eventType}
      description={!isReduced && <TradeFlowActivity tradeFlow={item.portVisit.tradeFlow} />}
      isBallast={isBallast}
      isReduced={isReduced}
      isSelected={isSelected}
      margin={margin}
      selectedEvent={selectedEvent}
      onSelectEvent={handleSelect}
      onSelectSubEvent={onSelectEvent}
      subEvents={subEvents}
      header={
        <>
          <TimelineItemAttributeRow>
            <TimelineItemAttribute
              data-testid="port"
              tab={'l'}
              label={''}
              value={<PortLocation port={port} showSubtitle />}
            />
            {isReduced ? null : (
              <>
                <TimelineItemAttribute
                  data-testid="arrival-draft"
                  tab={'m'}
                  label={'Arrival draft'}
                  value={enterDraft && `${enterDraftFormatted} (${enterDraftPercent})`}
                />
                <TimelineItemAttribute
                  data-testid="departure-draft"
                  tab={'m'}
                  label={'Departure draft'}
                  value={exitDraft && `${exitDraftFormatted} (${exitDraftPercent})`}
                />
                <TimelineItemAttribute
                  data-testid="draft-change"
                  tab={'s'}
                  label={'Draft change'}
                  value={
                    <DraftIndicator enterDraft={enterDraft} exitDraft={exitDraft ?? enterDraft} maxDraft={maxDraft} />
                  }
                />
              </>
            )}
          </TimelineItemAttributeRow>
        </>
      }
    />
  );
};

const TradeFlowActivity = ({tradeFlow}: {tradeFlow: TradeFlowPortActivity | null}) => {
  if (!tradeFlow) {
    return null;
  }

  const commodity = tradeFlow.commodityProduct ?? tradeFlow.commodity ?? tradeFlow.commodityClass;

  return (
    <TradeFlowLabel>
      {tradeFlow.type.toUpperCase()} - {commodity?.toUpperCase()}
    </TradeFlowLabel>
  );
};

const TradeFlowLabel = styled.span`
  display: inline;
  width: auto;
  margin: 0 0 0 auto;
  padding: 2px 5px;
  background: var(--color-gray-1);
  color: var(--color-white);
  font-size: var(--font-size-xs);
  font-weight: bold;
  line-height: 1.125;
  text-transform: uppercase;
  border-radius: var(--border-radius-lg);
`;

const DraftIndicator: FC<{enterDraft: number; exitDraft: number; maxDraft?: number}> = ({
  enterDraft,
  exitDraft,
  maxDraft,
}) => {
  const draftChange = enterDraft - exitDraft;
  const draftChangePercent = numberUnitFormat(
    percentCalc(draftChange, maxDraft ?? null),
    '%',
    {
      toUpperCase: false,
      unitSpace: false,
      emptyString: '-',
    },
    {
      signDisplay: 'never',
    }
  );

  return (
    <DraftLabel>
      <span>{draftChangePercent}</span>
      <DraftChangeIcon draftChange={draftChange} />
    </DraftLabel>
  );
};

const DraftLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
`;
