import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {GetContainerVesselDesignsResponse} from '../../../api/node-backend/generated';
import {vesselDBContainerVesselDesignsApi} from '../../../api/node-backend/nodeBackendApis';
import {ApiError} from '../../../api/utils/ApiError';

const QUERY_KEY = 'vesselDBContainerVesselDesignsApi.getContainerVesselDesigns()';

export const useContainerVesselDesignsQuery = (
  options?: UseQueryOptions<GetContainerVesselDesignsResponse, ApiError>
) => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => vesselDBContainerVesselDesignsApi.getContainerVesselDesigns(),
    ...options,
  });
};
