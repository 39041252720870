import {Notification} from '../../../../api/symfony/generated/models/Notification';
import {EventType} from '../../../../api/symfony/generated/models/EventType';

/**
 * Returns where a click on the notification message should lead the user to.
 */
export const getNotificationClickTarget = (notification: Notification): string | null => {
  const link = notification.link ?? null;
  switch (notification.eventType) {
    case EventType.Generic:
      return link;
    case EventType.FriendRequest:
      return '/contacts/manage-friends';
    default:
      return link;
  }
};
