/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ApiErrorDTO,
  ApiErrorDTOFromJSON,
  ApiErrorDTOToJSON,
  VesselOfferUpdateRequest,
  VesselOfferUpdateRequestFromJSON,
  VesselOfferUpdateRequestToJSON,
} from '../models';

export interface MoveVesselOfferToWorkspaceRequest {
  offerId: number;
  workspaceId: number;
}

export interface ShareVesselToMarketRequest {
  body: object;
}

export interface UpdateVesselOfferRequest {
  offer: number;
  vesselOfferUpdateRequest: VesselOfferUpdateRequest;
}

/**
 *
 */
export class VesselOfferApi extends runtime.BaseAPI {
  /**
   * Move vessel offer to workspace
   */
  async moveVesselOfferToWorkspaceRaw(
    requestParameters: MoveVesselOfferToWorkspaceRequest
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
      throw new runtime.RequiredError(
        'offerId',
        'Required parameter requestParameters.offerId was null or undefined when calling moveVesselOfferToWorkspace.'
      );
    }

    if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling moveVesselOfferToWorkspace.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/market/vessel-offers/{offerId}/workspace/{workspaceId}`
        .replace(`{${'offerId'}}`, encodeURIComponent(String(requestParameters.offerId)))
        .replace(`{${'workspaceId'}}`, encodeURIComponent(String(requestParameters.workspaceId))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Move vessel offer to workspace
   */
  async moveVesselOfferToWorkspace(requestParameters: MoveVesselOfferToWorkspaceRequest): Promise<object> {
    const response = await this.moveVesselOfferToWorkspaceRaw(requestParameters);
    return await response.value();
  }

  /**
   * Shares a vessel from the user\'s portfolio with the market.
   */
  async shareVesselToMarketRaw(requestParameters: ShareVesselToMarketRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling shareVesselToMarket.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/market/vessel-offers`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Shares a vessel from the user\'s portfolio with the market.
   */
  async shareVesselToMarket(requestParameters: ShareVesselToMarketRequest): Promise<void> {
    await this.shareVesselToMarketRaw(requestParameters);
  }

  /**
   */
  async updateVesselOfferRaw(requestParameters: UpdateVesselOfferRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.offer === null || requestParameters.offer === undefined) {
      throw new runtime.RequiredError(
        'offer',
        'Required parameter requestParameters.offer was null or undefined when calling updateVesselOffer.'
      );
    }

    if (
      requestParameters.vesselOfferUpdateRequest === null ||
      requestParameters.vesselOfferUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'vesselOfferUpdateRequest',
        'Required parameter requestParameters.vesselOfferUpdateRequest was null or undefined when calling updateVesselOffer.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/market/vessel-offers/{offer}`.replace(
        `{${'offer'}}`,
        encodeURIComponent(String(requestParameters.offer))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: VesselOfferUpdateRequestToJSON(requestParameters.vesselOfferUpdateRequest),
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async updateVesselOffer(requestParameters: UpdateVesselOfferRequest): Promise<object> {
    const response = await this.updateVesselOfferRaw(requestParameters);
    return await response.value();
  }
}
