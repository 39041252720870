import {useQuery} from '@tanstack/react-query';
import {getTradingAreas} from '../../../../redux/ApiService/MapService/MapService';
import {useMemo} from 'react';
import sumBy from 'lodash/sumBy';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import {TradingAreaLayer} from '../../../../components/SeaboMap/layers/index';
import {TODO} from '../../../../utils/TODO';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {OfferType} from '../../utils/transformBucketsToTimeSeries';
import {interpolateRgb} from 'd3-interpolate';
import {color as d3color, rgb, RGBColor} from 'd3-color';
import {Card, Col, List} from 'antd';
import styled from 'styled-components';
import {SeaboMap} from '../../../../components/SeaboMap/SeaboMap';
import {TradingArea} from '../../../../redux/ApiService/MapService/GetTradingAreasResponse';
import {useDispatch} from '../../../../redux/react-redux';
import {MapControlButton} from '../../../../components/SeaboMap/MapContext/Types';
import {OneTradingArea} from './OneTradingArea';

const colorRed = getComputedStyle(document.documentElement).getPropertyValue('--color-white');
const colorGreen = getComputedStyle(document.documentElement).getPropertyValue('--color-green');

const ColorInterpolator = interpolateRgb(colorRed, colorGreen);

export const TradingAreaSection = ({buckets, by}: {buckets: OfferBucket[]; by: OfferType}) => {
  const dispatch = useDispatch();

  const tradingAreasQuery = useQuery({
    queryKey: ['tradingAreas'],
    queryFn: async (): Promise<TradingArea[]> => {
      const response = await dispatch(getTradingAreas());

      return response.items;
    },
  });

  const data = useMemo(() => {
    return tradingAreasQuery.data?.map(item => {
      const allBucketsFromTradingArea = buckets.filter(
        bucket => bucket.tags?.some(tag => tag.name === 'tradingArea' && tag.value === item.name)
      );
      return {
        type: 'Feature',
        properties: {
          ...item,
          countOffers: sumBy(allBucketsFromTradingArea, bucket => {
            switch (by) {
              case 'vessel':
                return bucket.numberOfVesselOffers ?? 0;
              case 'cargo':
                return bucket.numberOfCargoOffers ?? 0;
              case 'all':
                return bucket.numberOfOffers ?? 0;
            }
          }),
          offers: allBucketsFromTradingArea,
        },
        geometry: {type: 'Polygon', coordinates: item.polygon},
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingAreasQuery.data, buckets]);

  const maxCount = maxBy(data, item => item.properties.countOffers)?.properties.countOffers ?? 1;
  const multiplicator = 1000000 / maxCount;
  return (
    <>
      <Col span={16}>
        <div style={{height: 400, width: '100%'}}>
          <SeaboMap
            key={'map'}
            vesselCargoMode={false}
            showPopups={false}
            initialViewState={{
              zoom: 0.7,
              minZoom: 0.2,
              maxZoom: 1,
            }}
            pitch={30}
            vesselsToVisualize={{
              data: [],
            }}
            mapControlButtonsToHide={[
              MapControlButton.VESSELS,
              MapControlButton.LAYERS,
              MapControlButton.CARGOES,
              MapControlButton.PORTS,
            ]}
            initialMapSettings={{
              mapStyle: 'DARK_MODE',
            }}
            lassoSelectionEnabled={false}
            settingsButtonVisible={false}
            settingsPersistent={false}
            layers={[
              new TradingAreaLayer({
                id: 'NON_TRADING_AREA',
                data: data,
                extruded: true,
                filled: true,
                wireframe: true,
                showLabels: true,
                getElevation: (d: TODO) => d.properties.countOffers * multiplicator,
                getLineColor: () => [0, 0, 0, 0],
                getFillColor: (d: TODO) => {
                  const color: RGBColor =
                    d3color(ColorInterpolator(d.properties.countOffers / maxCount))?.rgb() ?? rgb(0, 0, 0);
                  return [
                    color.r,
                    color.g,
                    color.b,
                    d.properties.countOffers > 0 ? (d.properties.countOffers / maxCount) * 255 : 0,
                  ];
                },
              }),
            ]}
            defaults={'potential_competitors'}
          />
        </div>
      </Col>
      <Col span={8}>
        <StyledCard>
          <StyledList
            dataSource={sortBy(data, area => area.properties.countOffers * -1)}
            renderItem={(vessel: TODO) => {
              return <OneTradingArea vessel={vessel} />;
            }}
          />
        </StyledCard>
      </Col>
    </>
  );
};

const StyledList = styled(List)`
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 12px 24px;
    height: 400px;
    overflow-y: scroll;
  }
`;
