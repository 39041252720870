/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface NegotiationTemplateSummary
 */
export interface NegotiationTemplateSummary {
  /**
   *
   * @type {number}
   * @memberof NegotiationTemplateSummary
   */
  id: number;
  /**
   *
   * @type {Date}
   * @memberof NegotiationTemplateSummary
   */
  updatedBy: Date;
  /**
   *
   * @type {Date}
   * @memberof NegotiationTemplateSummary
   */
  createdBy: Date;
  /**
   *
   * @type {string}
   * @memberof NegotiationTemplateSummary
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NegotiationTemplateSummary
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof NegotiationTemplateSummary
   */
  companyId: number | null;
  /**
   *
   * @type {number}
   * @memberof NegotiationTemplateSummary
   */
  termCount: number;
}

export function NegotiationTemplateSummaryFromJSON(json: any): NegotiationTemplateSummary {
  return NegotiationTemplateSummaryFromJSONTyped(json, false);
}

export function NegotiationTemplateSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationTemplateSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    updatedBy: new Date(json['updatedBy']),
    createdBy: new Date(json['createdBy']),
    name: json['name'],
    description: json['description'],
    companyId: json['companyId'],
    termCount: json['termCount'],
  };
}

export function NegotiationTemplateSummaryToJSON(value?: NegotiationTemplateSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    updatedBy: value.updatedBy.toISOString(),
    createdBy: value.createdBy.toISOString(),
    name: value.name,
    description: value.description,
    companyId: value.companyId,
    termCount: value.termCount,
  };
}
