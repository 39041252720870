import {Coordinates} from '../../../../utils/Coordinates';

type Params = {
  object: Line;
  context: {
    index: number;
    data: Line[];
  };
};

type Line = {
  start: Coordinates;
  end: Coordinates;
  speed: number;
};

export const getTransparentLevelForLines = (params: Params): number => {
  const indexFromLast = params.context.data.length - params.context.index;

  const fix = 0.3;

  return 255 * fix + ((1 - fix) * 255) / indexFromLast;
};
