import React, {useContext} from 'react';
import {VoyageProps} from './VoyageTypes';
import {Warning} from '../Warning/Warning';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import produce from 'immer';
import Card from '../../Card';
import {DistanceCalcOptions} from '../PageHeader/DistanceCalcOptions';
import {VoyageTimeline} from './VoyageTimeline';

export const VoyageInformation = (props: VoyageProps) => {
  const voyageContext = useContext(VoyageCharterContext);
  return (
    <div data-cy={'VoyageInformationBox'}>
      <Card
        key={'1'}
        title={
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <span style={{textTransform: 'uppercase', fontWeight: 700}}>Voyage information</span>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <DistanceCalcOptions
                distanceCalculatorValues={voyageContext.state.inputState.calculator.distanceCalculator}
                onChange={values => {
                  voyageContext.setInputState(
                    produce(voyageContext.state.inputState, draftInputState => {
                      draftInputState.calculator.distanceCalculator = {
                        ...draftInputState.calculator.distanceCalculator,
                        ...values,
                      };
                      draftInputState.voyage.routesCalculated = false;
                    })
                  );
                }}
                isLoadingRoutes={props.isLoadingRoutes}
                key={'distanceCalcOptions'}
              />
            </div>
          </div>
        }>
        <VoyageTimeline />
        <br />
        <Warning data-cy="voyageWarnings" area={'voyage'} />
      </Card>
    </div>
  );
};
