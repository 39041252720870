/* eslint-disable no-restricted-properties */
import {useEffect, VFC} from 'react';
import {useStore} from 'react-redux';
import {useNavigate} from 'react-router-dom';

/**
 * Makes some objects available to Cypress tests and for debugging in the browser console.
 *
 * DO NOT use these global variables in your code!!
 */
export const GlobalVariablesForTestsAndDebugging: VFC = () => {
  const navigate = useNavigate();
  const store = useStore();
  useEffect(() => {
    // Make React Router's history available to Cypress tests.
    // Instead use useHistory().
    window._reactRouterNavigate = navigate;
    // Make the Redux store accessible for debugging purposes. Don't use it in your code!
    window._store = store;
  }, [store, navigate]);
  return null;
};
