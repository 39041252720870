import {Dayjs} from 'dayjs';
import {QueryClient} from '@tanstack/react-query';
import {getHolidays} from './getHolidays';

export const isDayAHoliday = async ({
  queryClient,
  date,
  countryCode,
}: {
  date: Dayjs;
  countryCode?: string;
  queryClient: QueryClient;
}): Promise<boolean> => {
  const holidays = await getHolidays({countryCode, queryClient, date});
  return holidays.some(holiday => date.isSame(holiday.date, 'date'));
};
