/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {NegotiationActor, NegotiationActorFromJSON, NegotiationActorToJSON} from '../models';

export interface GetActorByIdRequest {
  id: string;
}

/**
 *
 */
export class NegotiationNegotiationActorApi extends runtime.BaseAPI {
  /**
   * Gets the actor by ID.
   */
  async getActorByIdRaw(requestParameters: GetActorByIdRequest): Promise<runtime.ApiResponse<NegotiationActor>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getActorById.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/actors/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationActorFromJSON(jsonValue));
  }

  /**
   * Gets the actor by ID.
   */
  async getActorById(requestParameters: GetActorByIdRequest): Promise<NegotiationActor> {
    const response = await this.getActorByIdRaw(requestParameters);
    return await response.value();
  }
}
