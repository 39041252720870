import {TODO} from '../../../../utils/TODO';
import {Coordinates} from '../../../../utils/Coordinates';

export interface SearchElement {
  id: number;
  type: 'ais-vessel' | 'port';
  longitude: number;
  latitude: number;
}

interface Port {
  id: number;
  coordinates: Coordinates;
}

export const toSearchElement = ({object, ports}: {object: TODO; ports: Port[]}): SearchElement | null => {
  switch (object.type) {
    case 'port': {
      const findPortInList = ports.find(port => port.id === object.id);

      if (!findPortInList) {
        return null;
      }
      return {
        ...findPortInList,
        ...object,
        longitude: findPortInList.coordinates[0],
        latitude: findPortInList.coordinates[1],
      };
    }

    default:
      return object;
  }
};
