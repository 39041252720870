import {StyledFlag} from '../StyledFlag';
import {Country, CountryOption} from '../Type';
import {SearchHit} from '../../../api/symfony/generated/models/SearchHit';

export const makeCountryOption = (searchHit: SearchHit): CountryOption => {
  const source = searchHit.source as Country;
  return {
    id: source.id,
    key: source.type + '_' + source.id,
    type: source.type,
    source: source,
    value: `${source.name}`,
    label: (
      <div
        data-testid="countryPickerOption"
        data-cy="countryPickerOption"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <span>
          <StyledFlag countryCode={source.code} shadow width={13} height={10} /> {source.name}
        </span>
      </div>
    ),
  };
};
