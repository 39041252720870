/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VesselFilters, VesselFiltersFromJSON, VesselFiltersFromJSONTyped, VesselFiltersToJSON} from './';

/**
 *
 * @export
 * @interface ListVesselsRequest
 */
export interface ListVesselsRequest {
  /**
   * Page size for pagination
   * @type {number}
   * @memberof ListVesselsRequest
   */
  pageSize?: number;
  /**
   * Zero based page index for pagination
   * @type {number}
   * @memberof ListVesselsRequest
   */
  pageIndex?: number;
  /**
   *
   * @type {string}
   * @memberof ListVesselsRequest
   */
  sortField?: string;
  /**
   *
   * @type {string}
   * @memberof ListVesselsRequest
   */
  sortOrder?: ListVesselsRequestSortOrderEnum;
  /**
   *
   * @type {VesselFilters}
   * @memberof ListVesselsRequest
   */
  filters?: VesselFilters;
}

/**
 * @export
 * @enum {string}
 */
export enum ListVesselsRequestSortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export function ListVesselsRequestFromJSON(json: any): ListVesselsRequest {
  return ListVesselsRequestFromJSONTyped(json, false);
}

export function ListVesselsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListVesselsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pageSize: !exists(json, 'pageSize') ? undefined : json['pageSize'],
    pageIndex: !exists(json, 'pageIndex') ? undefined : json['pageIndex'],
    sortField: !exists(json, 'sortField') ? undefined : json['sortField'],
    sortOrder: !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
    filters: !exists(json, 'filters') ? undefined : VesselFiltersFromJSON(json['filters']),
  };
}

export function ListVesselsRequestToJSON(value?: ListVesselsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pageSize: value.pageSize,
    pageIndex: value.pageIndex,
    sortField: value.sortField,
    sortOrder: value.sortOrder,
    filters: VesselFiltersToJSON(value.filters),
  };
}
