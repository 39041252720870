/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CumulatedTiedUpBulkerVessels
 */
export interface CumulatedTiedUpBulkerVessels {
  /**
   *
   * @type {number}
   * @memberof CumulatedTiedUpBulkerVessels
   */
  vessels: number;
  /**
   *
   * @type {number}
   * @memberof CumulatedTiedUpBulkerVessels
   */
  dwt: number;
}

export function CumulatedTiedUpBulkerVesselsFromJSON(json: any): CumulatedTiedUpBulkerVessels {
  return CumulatedTiedUpBulkerVesselsFromJSONTyped(json, false);
}

export function CumulatedTiedUpBulkerVesselsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CumulatedTiedUpBulkerVessels {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vessels: json['vessels'],
    dwt: json['dwt'],
  };
}

export function CumulatedTiedUpBulkerVesselsToJSON(value?: CumulatedTiedUpBulkerVessels | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vessels: value.vessels,
    dwt: value.dwt,
  };
}
