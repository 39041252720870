import {ReactElement, FC} from 'react';
import Country from '../../model/Country';
import {CountryLocation} from '../../components/LocationOutput/LocationOutputRow';
import {numberFormat} from '../../utils/formatter';
import styled, {css} from 'styled-components';
import {TODO} from '../../utils/TODO';
import {assertUnreachable} from '../../utils/assert';
import {NotAvailable} from '../../utils/NotAvailable';

export type TabSize = 'auto' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

type AttributeProps = {
  vertical?: boolean;
  label: string | ReactElement;
  value: string | TODO;
  small?: boolean;
  labelMin?: boolean;
  tab?: TabSize;
  showCountry?: boolean;
  upper?: boolean;
  displayInline?: boolean;
  formatted?: boolean;
  testId?: string;
  className?: string;
};

export const Attribute = ({
  vertical,
  label,
  value,
  small = false,
  labelMin = false,
  tab = 'auto',
  showCountry = false,
  upper = false,
  displayInline,
  formatted = false,
  testId,
  className,
}: AttributeProps) => {
  return (
    <AttributeContainer $small={small} className={className} data-testid={testId} $vertical={vertical}>
      <AttributeLabel data-testid={'attributeLabel'} $min={labelMin} $tabSize={tab}>
        {label}
      </AttributeLabel>
      <AttributeValue data-testid={'attributeValue'} $displayInline={displayInline} $upper={upper} $small={small}>
        <ValueVisualization value={value} showCountry={showCountry} formatted={formatted} />
      </AttributeValue>
    </AttributeContainer>
  );
};

const ValueVisualization: FC<{
  value: TODO;
  showCountry: boolean;
  formatted: boolean;
}> = ({value, showCountry, formatted}) => {
  if (value === null || value === undefined) {
    return <span>{NotAvailable}</span>;
  }
  if (showCountry) {
    const location = typeof value === 'string' ? {code: value, name: Country.getName(value), type: 'country'} : value;
    if (!location.code) {
      return <span>{NotAvailable}</span>;
    }
    return <CountryLocation location={location} />;
  }
  return <span>{formatted ? numberFormat(value) : value || NotAvailable}</span>;
};

export const AttributeContainer = styled.div<{$small: boolean; $vertical?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({$small}) => ($small ? '0' : '8px')};

  ${({$vertical}) =>
    $vertical
      ? css`
          flex-wrap: wrap;
          ${AttributeLabel}, ${AttributeValue} {
            width: 100% !important;
            min-width: 100% !important;
          }
          ${AttributeValue} {
            margin-left: 0;
          }
        `
      : ''}

  ${({$small}) =>
    $small
      ? css`
          ${AttributeLabel} {
            font-size: var(--font-size-xs);
          }
          ${AttributeValue} {
            font-size: var(--font-size-sm);
          }
        `
      : ''};
`;

type AttributeLabelProps = {
  $tabSize: TabSize;
  $min: boolean;
};

export const AttributeLabel = styled.span<AttributeLabelProps>`
  display: inline-block;
  color: var(--color-gray-2);
  font-size: var(--font-size-sm);
  line-height: 14px;
  text-transform: uppercase;
  padding: 0;
  flex-shrink: 0;

  ${(props: AttributeLabelProps) =>
    props.$min &&
    css`
      min-width: 112px;
    `}

  ${(props: AttributeLabelProps) => {
    switch (props.$tabSize) {
      case 'auto':
        return css`
          width: auto;
        `;
      case 'xs':
        return css`
          width: 45px;
        `;
      case 's':
        return css`
          width: 60px;
        `;
      case 'm':
        return css`
          width: 80px;
        `;
      case 'l':
        return css`
          width: 100px;
        `;
      case 'xl':
        return css`
          width: 120px;
        `;
      case 'xxl':
        return css`
          width: 160px;
        `;
      default:
        assertUnreachable(props.$tabSize);
    }
  }}
`;

type AttributeValueProps = {
  $upper: boolean;
  $small: boolean;
  $displayInline?: boolean;
};

export const AttributeValue = styled.span<AttributeValueProps>`
  margin-left: 14px;
  color: var(--color-black);
  font-size: var(--font-size-md);
  line-height: 18px;
  word-break: break-word;

  ${(props: AttributeValueProps) =>
    props.$small &&
    css`
      font-size: var(--font-size-sm);
      line-height: 13px;
      margin-left: 6px;
    `}
  ${(props: AttributeValueProps) =>
    props.$upper &&
    css`
      text-transform: uppercase;
    `}  
  ${(props: AttributeValueProps) =>
    props.$displayInline &&
    css`
      display: inline-block;
    `}
`;
