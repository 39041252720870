import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../../../../api/utils/fetchJSON';
import {PortAreaCV} from '../../../utils/types';
import {useSelector} from '../../../../../../redux/react-redux';

export const usePortItemsQuery = (id: number) => {
  const {vesselFilterSettings, cargoFilterSettings} = useSelector(state => state.marketFilters);

  const queryParameters = new URLSearchParams();
  queryParameters.append('pageSize', '2000');
  // Apply active vessel/cargo filters when loading circular data for port.
  // https://seanexxt.atlassian.net/browse/FRIN-5045
  if (vesselFilterSettings !== null) {
    queryParameters.append('vesselFilters', JSON.stringify(vesselFilterSettings));
  }
  if (cargoFilterSettings !== null) {
    queryParameters.append('cargoFilters', JSON.stringify(cargoFilterSettings));
  }

  return useQuery({
    queryKey: ['mapDetailPortQuery', id, vesselFilterSettings, cargoFilterSettings],
    queryFn: async () => {
      const portItems = await fetchJSON<{data: PortAreaCV}>(`/api/map/port/${id}?${queryParameters.toString()}`);
      return portItems.data;
    },
    enabled: !!id,
  });
};
