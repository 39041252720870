import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {SubscriptionMailPurpose} from '../../../api/node-backend/generated';
import {subscriptionApi} from '../../../api/node-backend/nodeBackendApis';
import {CompanyEditSubscriptionPlanEnum} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';

export const useSendChangeSubscriptionMailMutation = (
  options?: UseMutationOptions<
    void,
    ApiError,
    {
      purpose: SubscriptionMailPurpose;
      targetPlan?: CompanyEditSubscriptionPlanEnum;
    }
  >
) =>
  useMutation({
    mutationFn: async ({purpose, targetPlan}) => {
      return await subscriptionApi.sendSubscriptionMail({
        sendSubscriptionMailRequest: {
          purpose,
          targetPlan,
        },
      });
    },
    ...options,
  });
