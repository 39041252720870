import React, {useState} from 'react';
import './style.scss';
import Icon from '../../atoms/Icon';
import NamedAvatar from '../../atoms/NamedAvatar';
import Avatar from '../../atoms/Avatar';
import {UserDateTime} from '../DateTime/UserDateTime';
import {PostedBy} from './PostedBy';
import {Card} from 'antd';

const ListSharedUser = ({users}: $TSFixMe) => {
  const [unfolded, setUnfolded] = useState(false);
  const data = !unfolded ? [...users].slice(0, 6) : users;
  const moreCount = users.length > 6 && users.length - 6;
  return (
    <div className={'offer-details__share-users'}>
      {data.map((d: $TSFixMe) => d.fullName).join(', ')}
      {users.length > 6 && (
        <span className={'offer-details__link'} onClick={() => setUnfolded(!unfolded)}>
          show {unfolded ? 'less' : `${moreCount} more`}
        </span>
      )}
    </div>
  );
};

type OfferDetailsProps = {
  offer?: $TSFixMe;
  isMe?: boolean;
};

const OfferDetails = ({offer, isMe = false}: OfferDetailsProps) => {
  return (
    <Card style={{marginBottom: 20}}>
      <div className="offer-details">
        <div className="offer-details__content">
          <div className="offer-details__headline">
            {isMe ? <span>You posted this offer on seabo at</span> : <span>This circular was posted on seabo at</span>}
          </div>
          <div className="offer-details__info">
            {isMe ? (
              <Avatar imageUrl={`/scf/api/UserAvatar?userid=${offer.user.id}`} styleOverride={{marginRight: '5px'}} />
            ) : (
              <Icon type={'date'} style={{marginRight: '5px'}} />
            )}
            <UserDateTime format={'dateTime'} value={offer.createdAt} />
          </div>
        </div>

        <div className="offer-details__content">
          <div className="offer-details__headline">{isMe ? <span>Shared with</span> : <span>Posted by</span>}</div>
          <div className="offer-details__info">
            {/* eslint-disable-next-line no-nested-ternary */}
            {isMe ? (
              offer.public ? (
                <span>Public on seabo market</span>
              ) : (
                <ListSharedUser users={offer.sharedWith.users} />
              )
            ) : !offer.originSender ? (
              <NamedAvatar imageUrl={`/scf/api/UserAvatar?userid=${offer.user.id}`} name={offer.user.fullName} />
            ) : (
              <PostedBy sender={offer.originSender} />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OfferDetails;
