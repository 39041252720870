import React, {useState} from 'react';
import {CenteredContentArea, FullscreenDialog} from '../../../atoms/FullscreenDialog/FullscreenDialog';
import {Center} from '../../../components/Center/Center';
import {Helmet} from 'react-helmet-async';
import {SignupDialog} from './SignupDialog';
import {showNotification} from '../../../utils/notification';
import {useLocation, useNavigate} from 'react-router-dom';

export const SignupScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [signupSource] = useState<string | undefined>(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('source') ?? undefined;
  });

  return (
    <>
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <div data-testid="SignupScreen">
        <FullscreenDialog>
          <CenteredContentArea>
            <Center>
              <SignupDialog
                source={signupSource}
                onSuccess={() => {
                  navigate('/login');
                  showNotification('You signed up successfully. Please login to seabo.');
                }}
              />
            </Center>
          </CenteredContentArea>
        </FullscreenDialog>
      </div>
    </>
  );
};
