import {Col, Form, Input, InputNumber, Row} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import produce from 'immer';
import {FC, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from '../../../../../redux/react-redux';
import {getIsPayingCustomer} from '../../../../../redux/selectors';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {VesselConsumptionMode, VesselInput} from '../../VesselInformation/VesselTypes';
import {defaultVoyagePoint} from '../../VoyageInformation/Timeline/AddItem';
import {OfferMailCollapse} from '../../components/OfferMailCollapse';
import {StyledDatePicker} from '../../components/StyledDatePicker';
import {PortPickerWithFlagForVoyageLeg} from '../../utils/PortPickerWithFlagForVoyageLeg';
import {geoJsonLatLngToGeolibCoordinates} from '../../utils/geoJsonLatLngToGeolibCoordinates';
import {isPointInSECA} from '../../utils/isPointInSECA';
import {BorderBox, FullWidthButton, Headline} from '../utils/components';
import {ConsumptionsFormItem} from './ConsumptionsFormItem/ConsumptionsFormItem';

export const VesselAddition: FC<{
  vessel: VesselInput;
  onSubmit: (vesselInput: VesselInput) => void;
  onBack: () => void;
  onSkip: () => void;
}> = ({vessel, onSubmit, onBack, onSkip}) => {
  const isPayingCustomer = useSelector(getIsPayingCustomer);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...vessel,
      openDate: vessel.openDate ? dayjs(vessel.openDate) : null,
    });
  }, [vessel, form]);

  return (
    <Form<VesselInput>
      onFinish={newVessel => {
        const openDate = newVessel.openDate ? (newVessel.openDate as unknown as Dayjs).toISOString() : undefined;

        const newVesselState: VesselInput = produce({...vessel, ...newVessel}, draftVessel => {
          const consumptionModes: VesselConsumptionMode[] = draftVessel.consumptionModes;

          draftVessel.consumptionModes = consumptionModes;

          draftVessel.nextOpenPoint = {
            ...defaultVoyagePoint,
            ...vessel.nextOpenPoint,
            ...newVessel.nextOpenPoint,
            types: ['nextOpen'],
          };

          draftVessel.nextOpenPoint.isInSECA = isPointInSECA({
            point: geoJsonLatLngToGeolibCoordinates(draftVessel.nextOpenPoint.coordinates),
          });

          draftVessel.openDate = openDate;
        });

        onSubmit(newVesselState);
      }}
      labelCol={{span: 10}}
      wrapperCol={{span: 14}}
      form={form}>
      <Row gutter={[16, 16]} justify={'center'}>
        <Col span={24}>
          <Headline>Edit vessel details</Headline>
        </Col>
        <Col span={7}>
          <BorderBox
            style={{
              minHeight: 'calc(100% - 10px)',
            }}>
            <VesselEditHeader>General</VesselEditHeader>
            <div style={{height: 15}}></div>
            <StyledFormItem rules={[{required: true, message: 'Name is required'}]} label={'Vessel name'} name={'name'}>
              <Input data-cy="nameInput" />
            </StyledFormItem>
            <StyledFormItem rules={[{required: true, message: 'IMO is required'}]} label={'IMO'} name={'imo'}>
              <Input data-cy="imoInput" />
            </StyledFormItem>
            <StyledFormItem rules={[{required: true, message: 'DWT is required'}]} label={'DWT'} name={'dwat'}>
              <InputNumber min={1} data-cy="dwtInput" />
            </StyledFormItem>
            <StyledFormItem
              rules={[{required: true, message: 'Capacity is required'}]}
              label={'Capacity'}
              name={'capacity'}>
              <InputNumber min={1} data-cy="capacityInput" />
            </StyledFormItem>
            <StyledFormItem
              rules={[{required: true, message: 'Open date is required'}]}
              name={'openDate'}
              label={'Open date'}>
              <StyledDatePicker
                style={{width: '100%'}}
                size="middle"
                data-cy={'openDate'}
                format={DateTimeFormat.Date}
              />
            </StyledFormItem>
            <StyledFormItem
              rules={[{required: true, message: 'Next open is required'}]}
              name={'nextOpenPoint'}
              label={'Next open'}>
              <PortPickerWithFlagForVoyageLeg />
            </StyledFormItem>
          </BorderBox>
        </Col>
        <Col span={17}>
          <BorderBox
            style={{
              minHeight: 'calc(100% - 10px)',
            }}>
            <VesselEditHeader>Speed and Consumption</VesselEditHeader>
            <ConsumptionsFormItem form={form} imo={vessel.imo} />
          </BorderBox>
        </Col>
        {isPayingCustomer && (
          <Col span={24}>
            <OfferMailCollapse id={vessel.id} type="vessel" />
          </Col>
        )}
        <Col span={12}>
          <FullWidthButton data-cy="backButton" onClick={onBack}>
            Back
          </FullWidthButton>
        </Col>
        <Col span={12}>
          <FullWidthButton
            data-cy="nextButton"
            type={'primary'}
            onSubmit={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              form.validateFields();
            }}
            htmlType={'submit'}>
            Next
          </FullWidthButton>
        </Col>
        <Col span={24}>
          <FullWidthButton data-cy="skipButton" onClick={onSkip}>
            Skip
          </FullWidthButton>
        </Col>
      </Row>
    </Form>
  );
};

const VesselEditHeader = styled.h2`
  font-size: var(--font-size-md);
  font-weight: bold;
  text-align: center;
`;

const StyledFormItem = styled(Form.Item)`
  margin: 10px auto;
`;
