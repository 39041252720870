import {PredictedConsumption} from '../../../../../../api/node-backend/generated';
import {VesselConsumptionMode} from '../../../VesselInformation/VesselTypes';
import {roundNumberToDecimals} from '../../../../../../utils/roundNumber';

const defaultConsumptionMode: VesselConsumptionMode = {
  auxConsumption: 0,
  auxFuelType: 'ifo',
  mainConsumption: 0,
  mainFuelType: 'mgo',
  speed: 0,
  useCase: 'portidle',
};

export const transformVesselConsumptionModesFromPredictedConsumptions = (
  predictedConsumption: PredictedConsumption[]
): VesselConsumptionMode[] => {
  const newConsumptions: (VesselConsumptionMode | null)[] = predictedConsumption.map(
    (consumption: PredictedConsumption | null): VesselConsumptionMode | null => {
      if (consumption === null) {
        return null;
      }
      const vesselConsumption: VesselConsumptionMode = {
        auxConsumption: 0,
        auxFuelType: consumption.arrangement.fuelType === 'ifo' ? 'mdo' : 'ifo',
        mainConsumption: roundNumberToDecimals(consumption.consumption, 1),
        mainFuelType: consumption.arrangement.fuelType,
        speed: consumption.arrangement.speed,
        useCase: consumption.arrangement.mode,
      };

      return vesselConsumption;
    }
  );

  const existsPortIdle = !!newConsumptions.find(
    (consumption: VesselConsumptionMode | null): boolean => consumption !== null && consumption.useCase === 'portidle'
  );

  const existsPortWorking = !!newConsumptions.find(
    (consumption: VesselConsumptionMode | null): boolean =>
      consumption !== null && consumption.useCase === 'portworking'
  );

  const existsBallast = !!newConsumptions.find(
    (consumption: VesselConsumptionMode | null): boolean => consumption !== null && consumption.useCase === 'ballast'
  );

  const existsLaden = !!newConsumptions.find(
    (consumption: VesselConsumptionMode | null): boolean => consumption !== null && consumption.useCase === 'laden'
  );

  if (!existsPortIdle) {
    newConsumptions.push({
      ...defaultConsumptionMode,
      useCase: 'portidle',
    });
  }

  if (!existsPortWorking) {
    newConsumptions.push({
      ...defaultConsumptionMode,
      useCase: 'portworking',
    });
  }

  if (!existsBallast) {
    newConsumptions.push({
      ...defaultConsumptionMode,
      useCase: 'ballast',
    });
  }

  if (!existsLaden) {
    newConsumptions.push({
      ...defaultConsumptionMode,
      useCase: 'laden',
    });
  }

  return newConsumptions.filter(
    (consumption: VesselConsumptionMode | null): boolean => consumption !== null
  ) as VesselConsumptionMode[];
};
