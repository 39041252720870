import {FC, HTMLAttributes} from 'react';
import styled from 'styled-components';
import {TimelinePart} from './TimelineItemContainer';
import {VesselVoyageTimelineEventType} from '../../../../api/node-backend/generated';
import {TimelineItem, TimelineItemType} from '../utils/types';
import {TimelineEventColor, getEventColor} from '../utils/utils';

export const VoyageTimelinePlaceholder: FC<{item: TimelineItem}> = ({item}) => {
  const type =
    item.type === TimelineItemType.Port ? VesselVoyageTimelineEventType.PortVisit : VesselVoyageTimelineEventType.Route;
  const color = getEventColor(type);
  return (
    <Placeholder $color={color} data-testid="VoyageTimelinePlaceholder">
      <PlaceholderBubble />
      <TimelinePart />
      <Ellipsis>
        <Dot />
        <Dot />
        <Dot />
      </Ellipsis>
    </Placeholder>
  );
};

const Placeholder = styled.li<HTMLAttributes<'li'> & {$color: TimelineEventColor}>`
  --color: var(--timeline-color-${({$color}) => $color});
  --color-bg: var(--timeline-color-${({$color}) => $color}-bg);

  position: relative;
  width: 100%;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-bg);
  color: var(--color);
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
`;

const PlaceholderBubble = styled.div`
  position: absolute;
  top: calc(var(--timeline-gap) / 4);
  left: 6.5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color);
  z-index: 3;
`;

const Ellipsis = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100%;
  width: 100%;
`;

const Dot = styled.span`
  height: 5px;
  width: 5px;
  background: var(--color);
  border-radius: 50%;
`;
