/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ConnectionSettings,
  ConnectionSettingsFromJSON,
  ConnectionSettingsFromJSONTyped,
  ConnectionSettingsToJSON,
  GeneralSettings,
  GeneralSettingsFromJSON,
  GeneralSettingsFromJSONTyped,
  GeneralSettingsToJSON,
  VisibilitySettings,
  VisibilitySettingsFromJSON,
  VisibilitySettingsFromJSONTyped,
  VisibilitySettingsToJSON,
} from './';

/**
 *
 * @export
 * @interface Settings
 */
export interface Settings {
  /**
   *
   * @type {GeneralSettings}
   * @memberof Settings
   */
  general: GeneralSettings;
  /**
   *
   * @type {VisibilitySettings}
   * @memberof Settings
   */
  visibility: VisibilitySettings;
  /**
   *
   * @type {ConnectionSettings}
   * @memberof Settings
   */
  connections: ConnectionSettings;
}

export function SettingsFromJSON(json: any): Settings {
  return SettingsFromJSONTyped(json, false);
}

export function SettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Settings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    general: GeneralSettingsFromJSON(json['general']),
    visibility: VisibilitySettingsFromJSON(json['visibility']),
    connections: ConnectionSettingsFromJSON(json['connections']),
  };
}

export function SettingsToJSON(value?: Settings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    general: GeneralSettingsToJSON(value.general),
    visibility: VisibilitySettingsToJSON(value.visibility),
    connections: ConnectionSettingsToJSON(value.connections),
  };
}
