import {isLocalDevEnvironment} from './environment';

export enum VoyageAnimationAnimatedPortVisits {
  Disabled = 'disabled',
  New = 'new',
  Old = 'old',
  Safe = 'safe',
}

export const FeatureToggles = {
  filterDebugger: true,
  userProfileConnections: false,
  easySharing: false,
  get newVesselDatabaseDetailsPage(): boolean {
    return isLocalDevEnvironment();
  },
  negotiations: true,
  voyageAnimationAnimatedPortVisits: VoyageAnimationAnimatedPortVisits.Safe,
  consentServiceAllowToAddNewContracts: false,
};
