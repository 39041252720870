import {StyledTitleFormItem} from '../../components/StyledTitleFormItem';
import {ConfigProvider} from 'antd';
import {StyledTable} from '../../components/StyledTable';
import numeral from 'numeral';
import {useState, FC} from 'react';
import {EditModal} from './EditModal';
import {Button} from '../../../../../atoms/Button/Button';
import Icon from '../../../../../atoms/Icon';
import {Warning} from '../../Warning/Warning';
import {getVesselConsumptionUseCaseLabel} from '../getVesselConsumptionUseCaseLabel';
import {VesselConsumptionMode} from '../VesselTypes';

export const SpeedAndConsumption: FC<{
  consumptionModes: VesselConsumptionMode[];
  onChange: (consumptions: VesselConsumptionMode[]) => void;
}> = ({consumptionModes, onChange}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <StyledTitleFormItem label={'Speed & Consumption'}>
        {show && (
          <EditModal
            consumptionModes={consumptionModes}
            onClose={() => setShow(false)}
            onChange={consumptions => {
              onChange(consumptions);
            }}
          />
        )}
        <ConfigProvider renderEmpty={() => null}>
          <StyledTable<VesselConsumptionMode>
            style={{minWidth: '400px', width: '100%'}}
            size={'small'}
            rowKey={'key'}
            onRow={() => {
              return {
                onClick: () => {
                  setShow(true);
                },
              };
            }}
            columns={[
              {
                key: 'useCase',
                dataIndex: 'useCase',
                title: 'Condition',
                render: value => getVesselConsumptionUseCaseLabel(value),
              },
              {
                key: 'speed',
                dataIndex: 'speed',
                render: (speed, consumption) =>
                  consumption.useCase.includes('port') ? '' : numeral(speed).format('0,0[.]00'),
                title: 'Speed',
              },
              {
                key: 'mainConsumption',
                dataIndex: 'mainConsumption',
                render: (_, item) => {
                  return (
                    <span>
                      {item.mainFuelType?.toLocaleUpperCase()} {numeral(item.mainConsumption).format('0,0[.]00')} mt/d
                    </span>
                  );
                },
                title: 'Consumption',
              },
              {
                key: 'auxConsumption',
                dataIndex: 'auxConsumption',
                render: (_, item) => {
                  return (
                    <span>
                      {item.auxFuelType?.toLocaleUpperCase()} {numeral(item.auxConsumption).format('0,0[.]00')} mt/d
                    </span>
                  );
                },
                title: 'Additional consumption',
              },
            ]}
            pagination={false}
            dataSource={consumptionModes}
          />
        </ConfigProvider>
      </StyledTitleFormItem>
      <div style={{marginBottom: 15}} className={'cargo-vessel-form__icon-button'}>
        <Button
          data-cy="addButton"
          icon
          label={
            <>
              <Icon type={'item-add-selection-solid'} color="blue" style={{marginRight: 6}} />
              {consumptionModes.length === 0 ? 'ADD CONSUMPTION MODE' : 'ADD ANOTHER CONSUMPTION MODE'}
            </>
          }
          onClick={() => {
            setShow(true);
          }}
        />
      </div>
      <Warning area={'vessel'} /> <Warning area={'vessel-consumption'} />
    </>
  );
};
