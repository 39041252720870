import ReactECharts from 'echarts-for-react';
import {FC} from 'react';
import styled from 'styled-components';
import {DateTiedUpPercentage} from './useGlobalTiedUpVesselsTrendChartDataSource';

export type ChartProps = {
  dataSource: DateTiedUpPercentage[];
};

export const Chart: FC<ChartProps> = ({dataSource}) => {
  return (
    <StyledChart
      style={{height: 80, width: '100%'}}
      option={{
        dataset: [
          {
            id: 'dataSource',
            source: dataSource,
          },
        ],
        grid: {
          top: 10,
          bottom: 10,
          left: 5,
          right: 5,
        },
        xAxis: {
          type: 'category',
          show: false,
        },
        yAxis: {
          type: 'value',
          position: 'left',
          min: 20,
          max: 80,
          interval: 20,
          offset: -20,
        },
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            type: 'line',
            datasetId: 'dataSource',
            showSymbol: false,
            encode: {
              x: 'dateString',
              y: 'tiedUpPercentage',
              itemName: 'dateString',
              tooltip: ['tiedUpPercentage'],
            },
          },
        ],
      }}
    />
  );
};

const StyledChart = styled(ReactECharts)`
  margin: 0;
  background: var(--color-white);
  border: var(--border-base);
  border-radius: var(--border-radius);
`;
