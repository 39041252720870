import produce from 'immer';
import {addNextOpenLegToVoyage} from '../../../utils/addNextOpenLegToVoyage/addNextOpenLegToVoyage';
import {blankState} from '../../blankState';
import {useContext} from 'react';
import {VoyageCharterContext} from '../../VoyageCharterContext';
import {InputState} from '../../../utils/voyageChartercalculation/voyageChartercalculation';

export type RemoveCargoFromCargoInputAndVoyage = () => void;

export const removeCargoFromCargoInputAndVoyage = ({
  setInputState,
  inputState,
}: {
  inputState: InputState;
  setInputState: (inputState: InputState) => void;
}): void => {
  setInputState(
    produce(inputState, draftState => {
      draftState.voyage = {...blankState.inputState.voyage};
      draftState.cargo = {...blankState.inputState.cargo};
      draftState.voyage = {...addNextOpenLegToVoyage(draftState)};
      draftState.voyage.routesCalculated = false;

      return draftState;
    })
  );
};

export const useRemoveCargoFromCargoInputAndVoyage = (): RemoveCargoFromCargoInputAndVoyage => {
  const {state, setInputState} = useContext(VoyageCharterContext);
  const {inputState} = state;
  return () =>
    removeCargoFromCargoInputAndVoyage({
      inputState,
      setInputState,
    });
};
