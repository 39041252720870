import MarketService, {actionTypes as ServiceActions} from '../../../redux/ApiService/MarketService';
import {MarketType} from '../../../redux/Market';
import {useDispatch} from '../../../redux/react-redux';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError, isApiError} from '../../../api/utils/ApiError';

type DetachProps = {id: number; type: MarketType};

export const useDetachOfferMutation = (options: UseMutationOptions<void, ApiError, DetachProps, void>) => {
  const dispatch = useDispatch();
  const _detachOffer = ({id, type}: DetachProps) => {
    dispatch({type: ServiceActions[type].PENDING});
    return dispatch(MarketService.detachOffer(id, type));
  };

  const detachOffer = async ({id, type}: DetachProps) => {
    try {
      await _detachOffer({id, type});
    } catch (e) {
      if (isApiError(e)) {
        dispatch({type: ServiceActions[type].PENDING, payload: {state: false}});
      } else {
        throw e;
      }
    }

    return;
  };

  return useMutation({
    mutationFn: detachOffer,
    ...options,
  });
};
