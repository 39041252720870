import {FC, ReactNode, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Button} from '../../../atoms/Button/Button';
import {getIsPayingCustomer} from '../../../redux/selectors';
import {RestrictedAccessPromotion} from '../../PromotionPopover/RestrictedAccessPromotion';
import {useSelector} from '../../../redux/react-redux';

export const MapDetailsPromotionWrapper: FC<{children: ReactNode}> = ({children}) => {
  const isPayingCustomer = useSelector(getIsPayingCustomer);
  const {mapElement} = useSelector(({mapDetails}) => mapDetails);

  const [accessRequested, setAccessRequested] = useState(false);

  useEffect(() => {
    setAccessRequested(false);
  }, [mapElement?.x, mapElement?.y]);

  const randomAccessGrant = useMemo(() => Math.random() > 0.33, []);
  const hasAccess = isPayingCustomer || accessRequested || randomAccessGrant;
  const Wrapper = hasAccess ? ClearWrapper : BlurredWrapper;

  return (
    <>
      <Wrapper>{children}</Wrapper>
      {!hasAccess && <Promotion close={() => setAccessRequested(true)} />}
    </>
  );
};

const Promotion: FC<{close: () => void}> = ({close}) => (
  <PromotionContainer>
    <RestrictedAccessPromotion
      headline={
        <>
          With seabo Premium you have direct <br />
          and instant access to the Market
        </>
      }
    />
    <AccessButtonWrapper>
      <Button secondary upper bold block onClick={close}>
        Continue
      </Button>
    </AccessButtonWrapper>
  </PromotionContainer>
);

const PromotionContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, var(--color-white) 0%, var(--color-white) 35%, rgba(0, 0, 0, 0) 100%);
  z-index: 3;

  & > div,
  & > div > ul {
    width: 320px;
  }
`;

const AccessButtonWrapper = styled.div`
  width: 308px;
  margin-top: 10px;
`;

const ClearWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const BlurredWrapper = styled(ClearWrapper)`
  --blur-radius: 7px;
  height: calc(100% - var(--blur-radius) - 2px);
  width: calc(100% - var(--blur-radius) - 2px);
  filter: blur(var(--blur-radius));
`;
