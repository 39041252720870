import {FC} from 'react';
import styled from 'styled-components';
import LocationOutputRow from '../../../components/LocationOutput/LocationOutputRow';
import Cargo from '../../../model/Cargo';
import {TODO} from '../../../utils/TODO';
import {getStationLocationsFromCargo} from './getStationLocationsFromCargo';

export const CargoStations: FC<{
  cargo: Cargo;
  fromIndex?: number;
  viaIndex?: number;
  toIndex?: number;
  setFromIndex?: (index: number) => void;
  setViaIndex?: (index: number) => void;
  setToIndex?: (index: number) => void;
}> = ({
  cargo,
  fromIndex,
  viaIndex,
  toIndex,
  setFromIndex = () => {},
  setViaIndex = () => {},
  setToIndex = () => {},
}) => {
  const {fromLocations, viaLocations, toLocations} = getStationLocationsFromCargo(cargo);

  const showFromLocations = fromLocations.length > 0;
  const showViaLocations = viaLocations.length > 0;
  const showToLocations = toLocations.length > 0;

  return (
    <CargoStationsContainer>
      {cargo.contractType === 'vc' ? (
        <>
          <BoxVert $height={50}>
            <FlexBoxVert>
              <StationTitle>Load / delivery</StationTitle>
              <StationList>
                {showFromLocations ? (
                  fromLocations.map((location: TODO, index: number) => (
                    <StationListItem key={index} $active={fromIndex === index} onClick={() => setFromIndex(index)}>
                      <LocationOutputRow vertical stationType={'loading'} location={location} type={location.type} />
                    </StationListItem>
                  ))
                ) : (
                  <EmptyStation>No location stated</EmptyStation>
                )}
              </StationList>
            </FlexBoxVert>
          </BoxVert>
          <BoxVert $height={50}>
            <FlexBoxVert>
              <StationTitle>Discharge / redelivery</StationTitle>
              <StationList>
                {showToLocations ? (
                  toLocations.map((location: TODO, index: number) => (
                    <StationListItem key={index} $active={toIndex === index} onClick={() => setToIndex(index)}>
                      <LocationOutputRow vertical stationType={'discharge'} location={location} type={location.type} />
                    </StationListItem>
                  ))
                ) : (
                  <EmptyStation>No location stated</EmptyStation>
                )}
              </StationList>
            </FlexBoxVert>
          </BoxVert>
        </>
      ) : (
        <>
          <BoxVert $height={showViaLocations ? 33 : 50}>
            <FlexBoxVert>
              <StationTitle>Load / delivery</StationTitle>
              <StationList>
                {showFromLocations ? (
                  fromLocations.map((location: TODO, index: number) => (
                    <StationListItem key={index} $active={fromIndex === index} onClick={() => setFromIndex(index)}>
                      <LocationOutputRow vertical stationType={'delivery'} location={location} type={location.type} />
                    </StationListItem>
                  ))
                ) : (
                  <EmptyStation>No location stated</EmptyStation>
                )}
              </StationList>
            </FlexBoxVert>
          </BoxVert>
          {showViaLocations && (
            <BoxVert $height={33}>
              <FlexBoxVert>
                <StationTitle>Trade / Via</StationTitle>
                <StationList>
                  {viaLocations.map((location: TODO, index: number) => (
                    <StationListItem key={index} $active={viaIndex === index} onClick={() => setViaIndex(index)}>
                      <LocationOutputRow
                        vertical
                        stationType={'deliveryvia'}
                        location={location}
                        type={location.type}
                      />
                    </StationListItem>
                  ))}
                </StationList>
              </FlexBoxVert>
            </BoxVert>
          )}
          <BoxVert $height={showViaLocations ? 33 : 50}>
            <FlexBoxVert>
              <StationTitle>Discharge / redelivery</StationTitle>
              <StationList>
                {showToLocations ? (
                  toLocations.map((location: TODO, index: number) => (
                    <StationListItem key={index} $active={toIndex === index} onClick={() => setToIndex(index)}>
                      <LocationOutputRow vertical stationType={'redelivery'} location={location} type={location.type} />
                    </StationListItem>
                  ))
                ) : (
                  <EmptyStation>No location stated</EmptyStation>
                )}
              </StationList>
            </FlexBoxVert>
          </BoxVert>
        </>
      )}
    </CargoStationsContainer>
  );
};

const FlexBoxVert = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: stretch;
  gap: 0;
`;

const CargoStationsContainer = styled(FlexBoxVert)`
  gap: 20px;
`;

const BoxVert = styled.div<{$height: number}>`
  height: ${({$height}) => $height}%;
`;

const StationTitle = styled.h3`
  margin-bottom: 5px;
  color: var(--color-gray-2);
  font-weight: bold;
  text-transform: uppercase;
`;

const StationList = styled.ul`
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  border: var(--border-base);
  border-radius: var(--border-radius);
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.06);
  list-style: none;
  overflow-y: auto;
`;

const StationListItem = styled.li<{$active?: boolean}>`
  cursor: pointer;
  padding: 6px 10px 1px;

  ${({$active}) =>
    $active &&
    `
    background-color: rgba(0, 0, 0, 0.05);
    .location-output__title {
      font-weight: bold;
    }
  `}
`;

const EmptyStation = styled.div`
  padding: 6px 10px;
  color: var(--color-gray-3);
`;
