import {FC} from 'react';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import {NumberStatistics} from './NumberStatistics';

export const CurrentVisits: FC<{currentVisits: MergedPortCongestionVisit[]; title: string; dataTestId?: string}> = ({
  currentVisits,
  title,
  dataTestId,
}) => {
  return <NumberStatistics dataTestId={dataTestId} value={currentVisits.length} title={title} />;
};
