import {useQueryClient} from '@tanstack/react-query';
import {FIND_NEGOTIATION_BY_ID_QUERY_KEY} from '../NegotiationDetail/utils/useFindNegotiationByIdQuery';
import {FIND_ROUND_RESULT_BY_ID_QUERY_KEY} from '../NegotiationDetail/utils/useFindRoundResultByIdQuery';
import {GET_ROUND_RESULTS_MODIFICATIONS_QUERY_KEY} from '../NegotiationDetail/utils/useGetRoundResultModificationsQuery';
import {FIND_ROUND_RESULTS_BY_NEGOTIATION_ID_QUERY_KEY} from '../NegotiationDetail/utils/useFindRoundResultsByNegotiationIdQuery';
import {FIND_ALL_NEGOTIATIONS_QUERY_KEY} from '../NegotiationOverview/useFindAllNegotiationsQuery';
import {NegotiationRoundResult} from '../../../api/node-backend/generated';

export const useInvalidateNegotiationQueries = (negotiationId?: string, roundResultId?: string) => {
  const queryClient = useQueryClient();

  const invalidateNegotiationQueries = async (data?: NegotiationRoundResult) => {
    await queryClient.refetchQueries({
      queryKey: FIND_ALL_NEGOTIATIONS_QUERY_KEY,
    });
    await queryClient.refetchQueries({
      queryKey: FIND_NEGOTIATION_BY_ID_QUERY_KEY(negotiationId),
    });
    await queryClient.refetchQueries({
      queryKey: FIND_ROUND_RESULTS_BY_NEGOTIATION_ID_QUERY_KEY(negotiationId),
    });
    await queryClient.refetchQueries({
      queryKey: GET_ROUND_RESULTS_MODIFICATIONS_QUERY_KEY(roundResultId),
    });

    if (data) {
      queryClient.setQueryData(FIND_ROUND_RESULT_BY_ID_QUERY_KEY(roundResultId), data);
    } else {
      await queryClient.refetchQueries({
        queryKey: FIND_ROUND_RESULT_BY_ID_QUERY_KEY(roundResultId),
      });
    }
  };

  return invalidateNegotiationQueries;
};
