import {FC} from 'react';
import {Modal} from '../../antd/components/Modal';
import {OfferMailView} from './OfferMailView';
import {OfferMail} from './OfferMail';

export const OfferMailModal: FC<{
  open: boolean;
  offerMail: OfferMail;
  title: string;
  onCancel: () => void;
}> = ({offerMail, open, title, onCancel}) => {
  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      open={open}
      title={title}
      style={{
        whiteSpace: 'break-spaces',
      }}>
      <OfferMailView offerMail={offerMail} />
    </Modal>
  );
};
