import PropTypes from 'prop-types';
import defaults from './default';
import {TODO} from '../../utils/TODO';

type ReactComponent = TODO;

/** @deprecated */
export type MarginName = 'zero' | 'auto' | 'xs' | 's' | 'm' | 'l' | 'xl';

/** @deprecated */
export interface MarginMappingProps {
  spM?: MarginName;
  spMT?: MarginName;
  spMR?: MarginName;
  spMB?: MarginName;
  spML?: MarginName;
  spMV?: MarginName;
  spMH?: MarginName;
  spConfig?: unknown;
}

interface ReactComponentPropsWithMarginMapping extends MarginMappingProps {
  [otherPropName: string]: TODO;
}

/** @deprecated */
export interface MarginConfig {
  prefix?: string;
}

/** @deprecated */
export const withMarginMapping = (
  marginMapping: MarginMappingProps,
  config: MarginConfig = {}
): [Record<string, boolean>, Record<string, TODO>] => {
  const {spM, spMT, spMR, spMB, spML, spMV, spMH, spConfig: _spConfig, ...rest} = marginMapping;
  const settings: MarginConfig = {...defaults, ...config};
  const mapping = {
    [`${settings.prefix}-m-${spM}`]: !!spM,
    [`${settings.prefix}-mv-${spMV}`]: !!spMV,
    [`${settings.prefix}-mh-${spMH}`]: !!spMH,
    [`${settings.prefix}-mt-${spMT}`]: !!spMT,
    [`${settings.prefix}-mr-${spMR}`]: !!spMR,
    [`${settings.prefix}-mb-${spMB}`]: !!spMB,
    [`${settings.prefix}-ml-${spML}`]: !!spML,
  };
  return [mapping, rest];
};
/** withMargin is used to provide spacing between components using margins.
 * Different sizes allow you to easily construct consistent layouts of components.
 * The provided SpacerMarginMapping function is used by different components to archive the functionality.
 * The Props will be available on those components
 **/
/** @deprecated */
export const WithMargin = (SpacedComponent: ReactComponent) => (props: ReactComponentPropsWithMarginMapping) => {
  const [mapping, rest] = withMarginMapping(props);
  return <SpacedComponent {...rest} spacerClasses={mapping} />;
};

WithMargin.propTypes = {
  spM: PropTypes.oneOf(['zero', 'auto', 'xs', 's', 'm', 'l', 'xl']),
  spMT: PropTypes.oneOf(['zero', 'auto', 'xs', 's', 'm', 'l', 'xl']),
  spMR: PropTypes.oneOf(['zero', 'auto', 'xs', 's', 'm', 'l', 'xl']),
  spMB: PropTypes.oneOf(['zero', 'auto', 'xs', 's', 'm', 'l', 'xl']),
  spML: PropTypes.oneOf(['zero', 'auto', 'xs', 's', 'm', 'l', 'xl']),
  spMV: PropTypes.oneOf(['zero', 'auto', 'xs', 's', 'm', 'l', 'xl']),
  spMH: PropTypes.oneOf(['zero', 'auto', 'xs', 's', 'm', 'l', 'xl']),
  spConfig: PropTypes.object,
};

/** @component */
/** @deprecated */
export default WithMargin;
