import {FC, useMemo} from 'react';
import {
  NegotiationRoundResultModification,
  NegotiationSubject,
  NegotiationSummary,
} from '../../../../../../api/node-backend/generated';
import {TermContent} from '../../TermItem/Components/TermContent/TermContent';
import {SubjectItemHistory} from './SubjectItemHistory';
import {TermsContainer} from '../../TermArea/shared';
import {
  SubjectWithSubjectModifications,
  combineWithSubjectModification,
  getIsSubjectChanged,
  getIsSubjectSettledAndCommitted,
} from '../../TermArea/utils';

type HistoryDetailSubjectListProps = {
  negotiation: NegotiationSummary;
  subjects: NegotiationSubject[];
  roundResultModifications?: NegotiationRoundResultModification;
  hideUnchanged?: boolean;
  hideSettled?: boolean;
};

export const HistoryDetailSubjectList: FC<HistoryDetailSubjectListProps> = ({
  negotiation,
  subjects,
  roundResultModifications,
  hideUnchanged,
  hideSettled,
}) => {
  const subjectList = useMemo(() => {
    let subjectList: SubjectWithSubjectModifications[] = subjects.map(
      combineWithSubjectModification(roundResultModifications)
    );
    if (hideUnchanged) {
      subjectList = subjectList.filter(item => !getIsSubjectChanged(item));
    }
    if (hideSettled) {
      subjectList = subjectList.filter(item => !getIsSubjectSettledAndCommitted(item));
    }
    return subjectList;
  }, [subjects, roundResultModifications, hideUnchanged, hideSettled]);

  return (
    <TermsContainer>
      {subjectList.length ? (
        subjectList.map(({subject, subjectModifications}) => (
          <SubjectItemHistory
            key={subject.id}
            subject={subject}
            subjectModifications={subjectModifications}
            party={negotiation.party}
            ownerActor={negotiation[subject.party]}
            lastEditActor={negotiation[subject.textLastUpdatedBy]}
          />
        ))
      ) : (
        <TermContent text="No subject changes" />
      )}
    </TermsContainer>
  );
};
