import {actionTypes as contribService} from './ApiService/contribService';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {TODO} from '../utils/TODO';

type Payload = TODO;

interface ContribState extends TODO {
  items: TODO;
  pageIndex: number;
  vesselList?: TODO;
  pageSize: number;
  sorted: {id: string; desc: boolean}[];
  filters: {
    displayed: boolean;
    reviewed: boolean;
    input: TODO;
  };
  totalItems: number;
  loading: boolean;
  doReload: boolean;
  remoteLoading: boolean;
  progress: number;
}

const initialState: ContribState = {
  items: [],
  pageIndex: 1,
  pageSize: 100,
  sorted: [{id: 'name', desc: false}],
  filters: {
    displayed: true,
    reviewed: false,
    input: null,
  },
  totalItems: 0,
  loading: false,
  doReload: false,
  remoteLoading: false,
  progress: 0,
};

export const actionsTypes = {
  VESSEL_DB_UPDATE_SORTORDER: 'VESSEL_DB_UPDATE_SORTORDER',
  VESSEL_DB_UPDATE_PAGE: 'VESSEL_DB_UPDATE_PAGE',
  VESSEL_DB_UPDATE_PAGE_SIZE: 'VESSEL_DB_UPDATE_PAGE_SIZE',
  VESSEL_DB_FILTER_DISPLAYED: 'VESSEL_DB_FILTER_DISPLAYED',
  VESSEL_DB_FILTER_REVIEWED: 'VESSEL_DB_FILTER_REVIEWED',
  VESSEL_DB_FILTER_INPUT: 'VESSEL_DB_FILTER_INPUT',
  VESSEL_DB_SET_PROGRESS: 'VESSEL_DB_SET_PROGRESS',
};

export const contribReducer = (state = initialState, action: ActionWithPayload): ContribState => {
  switch (action.type) {
    case actionsTypes.VESSEL_DB_UPDATE_SORTORDER:
      return {
        ...state,
        sorted: [
          {
            id: action.payload.sorted[0].id,
            desc: action.payload.sorted[0].desc,
          },
        ],
        loading: true,
        doReload: true,
      };
    case actionsTypes.VESSEL_DB_UPDATE_PAGE:
      return {
        ...state,
        pageIndex: action.payload,
        loading: true,
        doReload: true,
      };
    case actionsTypes.VESSEL_DB_UPDATE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
        pageIndex: 1,
        loading: true,
        doReload: true,
      };
    case actionsTypes.VESSEL_DB_FILTER_DISPLAYED:
      return {
        ...state,
        doReload: true,
        filters: {
          ...state.filters,
          displayed: action.payload,
        },
      };
    case actionsTypes.VESSEL_DB_FILTER_REVIEWED:
      return {
        ...state,
        doReload: true,
        filters: {
          ...state.filters,
          reviewed: action.payload,
        },
      };
    case actionsTypes.VESSEL_DB_FILTER_INPUT:
      return {
        ...state,
        filters: {
          ...state.filters,
          input: action.payload,
        },
      };

    case contribService.list.PENDING:
      return {
        ...state,
        loading: true,
        doReload: false,
      };
    case contribService.list.SUCCESS:
      return {
        ...state,
        ...state.vesselList,
        items: action.payload.data.items,
        totalItems: action.payload.data.totalItems,
        loading: false,
      };
    case contribService.delete.PENDING:
      return {
        ...state,
        loading: true,
      };

    case actionsTypes.VESSEL_DB_SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };

    default:
      return state;
  }
};

export const setProgress = (payload: Payload) => (dispatch: TODO) =>
  dispatch({type: actionsTypes.VESSEL_DB_SET_PROGRESS, payload});
