import {FC} from 'react';
import styled from 'styled-components';

export const RemoveOnBoardingBanner: FC<{onRemove: () => Promise<void>}> = ({onRemove}) => {
  return (
    <StyledOnboardingCompleted>
      <StyledOnboardingCompletedCheckmark>
        <i className="icon-check-mark-line" />
      </StyledOnboardingCompletedCheckmark>
      <div className="text">Your company is all set up. You can remove the widget now.</div>
      <StyledOnbordingCompletedRemove>
        <button onClick={() => onRemove()}>Remove widget</button>
      </StyledOnbordingCompletedRemove>
    </StyledOnboardingCompleted>
  );
};
const StyledOnboardingCompleted = styled.div`
  background-color: var(--color-green);
  color: var(--color-white);
  display: flex;
  width: 100%;
  height: 95px;
  line-height: 95px;
  border-top: 1px solid var(--color-gray-5);

  .text {
    width: inherit;
    font-size: var(--font-size-md);
    line-height: 20px;
    margin-top: 35px;
  }
`;
const StyledOnboardingCompletedCheckmark = styled.div`
  font-size: var(--font-size-5xl);
  margin-left: 40px;
  margin-right: 20px;
`;
const StyledOnbordingCompletedRemove = styled.div`
  min-width: 155px;
  padding-right: 15px;
  button {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-gray-1);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;
