import {useContext, useEffect, useState, FC} from 'react';
import {Modal, Row, Steps, Col} from 'antd';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {getIsVesselReady} from './utils/getIsVesselReady';
import {getIsCargoImported, getIsCargoReady} from './utils/getIsCargoReady';
import {VesselImport} from './VesselImport/VesselImport';
import {mapVesselToVesselInput} from '../utils/mapVesselToVesselInput';
import {VesselAddition} from './VesselAddition/VesselAddition';
import {CargoImport} from './CargoImport/CargoImport';
import {mapCargoToCargoInput} from '../utils/mapCargoToCargoInput';
import {mapCargoToVoyageInput} from '../utils/mapCargoToVoyageInput/mapCargoToVoyageInput';
import produce from 'immer';
import {addNextOpenLegToVoyage} from '../utils/addNextOpenLegToVoyage/addNextOpenLegToVoyage';
import {CargoAddition} from './CargoAddition/CargoAddition';
import {AssistantModalIntro} from './AssistantModalIntro/AssistantModalIntro';
import {applyPreviousVoyageInformation} from './utils/applyPreviousVoyageInformation';
import {appendBunkerPricesToInputState} from '../utils/appendBunkerPricesToInputState';
import {useStowageFactorsQuery} from '../../../StowageFactorPicker/useStowageFactorsQuery';
import {assert} from '../../../../utils/assert';

export const AssistantModal: FC = () => {
  const voyageContext = useContext(VoyageCharterContext);
  const isExistingVoyageCalculation = voyageContext.props.id !== undefined;

  const [visible, setVisible] = useState(!isExistingVoyageCalculation);

  const {vessel, cargo, voyage} = voyageContext.state.inputState;

  const isVesselImported = !!vessel.imo || !!vessel.name;
  const isVesselReady = getIsVesselReady(vessel);
  const isCargoImported = getIsCargoImported({cargo, voyage});
  const isCargoReady = getIsCargoReady({cargo, voyage});

  const [showIntro, setShowIntro] = useState<boolean>(!(isVesselReady && isCargoReady));
  const [currentStep, setCurrentStep] = useState(0);
  const stowageFactorsQuery = useStowageFactorsQuery();

  useEffect(() => {
    if (isExistingVoyageCalculation) {
      if (isVesselReady && isCargoReady) {
        setVisible(false);
      }
    }
  }, [isExistingVoyageCalculation, isCargoReady, isVesselReady]);

  useEffect(() => {
    if (isVesselImported && currentStep === 0) {
      setCurrentStep(1);
      return;
    }
    if (isCargoImported && currentStep === 2) {
      setCurrentStep(3);
      return;
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [vessel, cargo, voyage]);

  if (!stowageFactorsQuery.isSuccess) {
    return null;
  }

  return (
    <Modal
      onCancel={() => setVisible(false)}
      footer={null}
      width={1200}
      title={'Voyage Calculation assistant'}
      open={visible}>
      {showIntro === true && (
        <AssistantModalIntro
          isPrefilled={isVesselReady && isCargoReady}
          onNext={() => setVisible(false)}
          onSkip={() => {
            setShowIntro(false);
            if (isVesselImported) {
              setCurrentStep(1);
            }
          }}
        />
      )}
      {showIntro === false && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Steps
              current={currentStep}
              items={[
                {title: 'Vessel import', status: isVesselImported ? 'finish' : undefined},
                {title: 'Edit vessel details', status: isVesselReady ? 'finish' : undefined},
                {title: 'Cargo import', status: isCargoImported ? 'finish' : undefined},
                {title: 'Edit cargo details', status: isCargoReady ? 'finish' : undefined},
              ]}
            />
          </Col>
          <Col span={24}>
            {currentStep === 0 && (
              <VesselImport
                vesselId={vessel.id}
                onSkip={() => setCurrentStep(2)}
                onSubmit={async vessel => {
                  const vesselState = await mapVesselToVesselInput({
                    vessel,
                  });

                  const newInputState = produce(voyageContext.state.inputState, draftInputState => {
                    draftInputState.vessel = vesselState;
                    draftInputState.voyage = addNextOpenLegToVoyage(draftInputState);
                  });

                  voyageContext.setInputState(newInputState);
                }}
              />
            )}
            {currentStep === 1 && (
              <VesselAddition
                vessel={vessel}
                onBack={() => setCurrentStep(0)}
                onSkip={() => setCurrentStep(2)}
                onSubmit={vesselState => {
                  const newInputState = produce(voyageContext.state.inputState, draftInputState => {
                    draftInputState.vessel = vesselState;
                    draftInputState.voyage = addNextOpenLegToVoyage(draftInputState);
                  });

                  voyageContext.setInputState(newInputState);
                  setCurrentStep(isCargoImported ? 3 : 2);
                }}
              />
            )}
            {currentStep === 2 && (
              <CargoImport
                cargoId={cargo.id}
                onBack={() => setCurrentStep(isVesselImported ? 1 : 0)}
                onSkip={() => setVisible(false)}
                onSubmit={async cargo => {
                  assert(stowageFactorsQuery.data);
                  const cargoState = mapCargoToCargoInput({
                    cargo,
                    stowageFactors: stowageFactorsQuery.data,
                  });
                  const voyageInput = mapCargoToVoyageInput({cargo});
                  const newVoyageState = produce(voyageContext.state.inputState, draftInputState => {
                    draftInputState.cargo = cargoState;
                    draftInputState.voyage = voyageInput;
                    draftInputState.voyage = addNextOpenLegToVoyage(draftInputState);
                  });
                  const inputStateWithBunkerPrices = await appendBunkerPricesToInputState(newVoyageState);
                  voyageContext.setInputState(inputStateWithBunkerPrices);
                }}
              />
            )}
            {currentStep === 3 && (
              <CargoAddition
                vessel={vessel}
                voyage={voyage}
                cargo={cargo}
                onBack={() => setCurrentStep(2)}
                onSubmit={async ({cargo, voyage}) => {
                  const newVoyageState = produce(voyageContext.state.inputState, draftInputState => {
                    draftInputState = applyPreviousVoyageInformation(draftInputState, cargo, voyage);
                    draftInputState.voyage = addNextOpenLegToVoyage(draftInputState);
                  });
                  const inputStateWithBunkerPrices = await appendBunkerPricesToInputState(newVoyageState);
                  voyageContext.setInputState(inputStateWithBunkerPrices);
                }}
                onClose={() => setVisible(false)}
              />
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
};
