import * as Sentry from '@sentry/browser';
import {showError} from '../../utils/showError';
import {QueryCache, QueryClient} from '@tanstack/react-query';
import {isApiErrorWithStatus} from './ApiError';
import {isUrlWorkspaceDependent} from '../../atoms/WorkspaceSelect/useReloadPageAndGoToSafeUrl';
import {showNofificationAfterPageReload} from '../../components/FlashNotification/FlashNotification';
import {isAbortError} from './AbortError';
import {ReactQueryWrapperError} from '../../queries/utils/wrapperForOnSuccessAndOnError';
import isNetworkError from 'is-network-error';
import {message} from '../../components/Notification';

/**
 * The default error handling for react-query.
 */
export const handleError = (error: unknown) => {
  // ApiService.ts aborts requests when pages are changed, so we don't want to show an error for that
  if (isAbortError(error)) {
    return;
  }

  // We don't want to show an error for ReactQueryWrapperError
  if (error instanceof ReactQueryWrapperError) {
    return;
  }

  // We don't want to act on network errors because they don't provide useful information
  if (isNetworkError(error) || isApiErrorWithStatus(error, 504)) {
    void message.error({
      content: 'You are offline, please check your connection and try again.',
    });
    return;
  }

  if (isApiErrorWithStatus(error, 403)) {
    showNofificationAfterPageReload({
      type: 'error',
      message: 'You were not authorized to access this workspace',
      description: 'Please contact your administrator',
      duration: 7,
    });
    if (!isUrlWorkspaceDependent(window.location.pathname)) {
      window.location.reload();
    }
    window.location.href = '/dashboard';
    return;
  }

  Sentry.captureException(error);

  showError(error);
};

const queryCache = new QueryCache({
  onError: handleError,
});

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      onError: handleError,
    },
  },
});
