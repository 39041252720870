import {MenuItemProps as AntDMenuItemProps} from 'antd';
import {FC} from 'react';
import {FeaturePermission} from '../../api/symfony/generated';
import {PremiumFeatureProtection} from '../../components/PremiumFeatureProtection/PremiumFeatureProtection';
import {useIsPermitted} from '../../utils/useIsPermitted';

interface ExtendedAntDMenuItemProps extends AntDMenuItemProps {
  'data-testid'?: string;
  'data-tour-id'?: string;
}

type MenuItemProps = ExtendedAntDMenuItemProps & {
  premiumFeature?: boolean;
  requiredPermission?: FeaturePermission;
};

export const MenuItemWithPermission: FC<MenuItemProps> = props => {
  const {requiredPermission, premiumFeature = false, ...menuItemProps} = props;
  const permitted = useIsPermitted(requiredPermission);

  if (premiumFeature) {
    return (
      <PremiumFeatureProtection permission={requiredPermission}>
        <div
          className={menuItemProps.className}
          data-testid={menuItemProps['data-testid']}
          data-tour-id={menuItemProps['data-tour-id']}>
          {menuItemProps.children}
        </div>
      </PremiumFeatureProtection>
    );
  }

  // If not permitted hide the menu item completely.
  return permitted ? (
    <div
      className={menuItemProps.className}
      data-testid={menuItemProps['data-testid']}
      data-tour-id={menuItemProps['data-tour-id']}>
      {menuItemProps.children}
    </div>
  ) : null;
};
