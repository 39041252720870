/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {CompanyApiKeyResponse, CompanyApiKeyResponseFromJSON, CompanyApiKeyResponseToJSON} from '../models';

export interface GetCompanyApiKeyRequest {
  companyId: number;
}

/**
 *
 */
export class CompanyApi extends runtime.BaseAPI {
  /**
   * Returns all fast vessel import entries from a specific company.
   * Fast vessel imports from this company
   */
  async getCompanyApiKeyRaw(
    requestParameters: GetCompanyApiKeyRequest
  ): Promise<runtime.ApiResponse<CompanyApiKeyResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling getCompanyApiKey.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/company/{companyId}/api-key`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(requestParameters.companyId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyApiKeyResponseFromJSON(jsonValue));
  }

  /**
   * Returns all fast vessel import entries from a specific company.
   * Fast vessel imports from this company
   */
  async getCompanyApiKey(requestParameters: GetCompanyApiKeyRequest): Promise<CompanyApiKeyResponse> {
    const response = await this.getCompanyApiKeyRaw(requestParameters);
    return await response.value();
  }
}
