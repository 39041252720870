import {OfferBucket} from '../../../api/symfony/generated';
import {getBucketDayList} from './getBucketDayList';
import lodashMap from 'lodash/map';
import sumBy from 'lodash/sumBy';

export const getDayToDayTrend = (offerBuckets: OfferBucket[]): number => {
  const bucketsPerDay = getBucketDayList({buckets: offerBuckets});

  const source: number[] = lodashMap(bucketsPerDay, buckets => {
    return sumBy(buckets, bucket => bucket.numberOfOffers ?? 0);
  });

  if (source.length < 2) {
    return 0;
  }

  const [dayBeforeLast, lastDay] = source.slice(-2);

  if (dayBeforeLast === 0 && lastDay > 0) {
    // Infinity would be right here, but we want to show a big number instead.
    return 2; // 200 % increase
  }
  const trend = (lastDay - dayBeforeLast) / dayBeforeLast;

  return trend;
};
