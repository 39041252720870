/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ConsentContractVersionSummary
 */
export interface ConsentContractVersionSummary {
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersionSummary
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersionSummary
   */
  title: string;
}

export function ConsentContractVersionSummaryFromJSON(json: any): ConsentContractVersionSummary {
  return ConsentContractVersionSummaryFromJSONTyped(json, false);
}

export function ConsentContractVersionSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ConsentContractVersionSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
  };
}

export function ConsentContractVersionSummaryToJSON(value?: ConsentContractVersionSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
  };
}
