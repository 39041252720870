import {FC} from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import styled from 'styled-components';

type TermDiffProps = {
  currentText: string;
  previousText?: string;
};

export const TermDiff: FC<TermDiffProps> = ({currentText, previousText}) => {
  return (
    <TermDiffContainer>
      <DiffViewer data-testid="TermDiff">
        <ReactDiffViewer
          newValue={currentText}
          oldValue={previousText}
          splitView={false}
          hideLineNumbers
          codeFoldMessageRenderer={totalFoldedLines => <>Expand {totalFoldedLines} hidden line(s)</>}
        />
      </DiffViewer>
    </TermDiffContainer>
  );
};

export const TermDiffContainer = styled.div`
  width: calc(100% + 20px);
  margin: -5px -11px;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
`;

const DiffViewer = styled.div`
  table {
    font-size: var(--font-size-sm);
    background: transparent;

    tr[class*='-code-fold'] {
      height: unset;
      padding: 2px 0;
      font-family: var(--font-family-base);
      font-size: var(--font-size-sm);
    }

    tr td {
      &[class*='-diff-removed'] {
        background-color: rgba(240, 0, 70, 0.1);
      }
      &[class*='-diff-added'] {
        background-color: transparent;
      }

      pre {
        line-height: 14px;

        [class*='-word-removed'] {
          background-color: rgba(240, 0, 70, 0.1);
        }
        [class*='-word-added'] {
          background-color: rgba(60, 200, 107, 0.3);
        }
      }
    }
  }
`;
