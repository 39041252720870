/**
 * A mapping of async action situations to concrete action types.
 */
export interface AsyncActionTypes {
  PENDING: string;
  SUCCESS: string;
  ERROR: string;
}

export type AsyncActionName = keyof AsyncActionTypes;

/**
 * A factory for action types.
 */
export const asyncActions = (type: string) => ({
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  ERROR: `${type}_ERROR`,
});
