import {VesselDatabaseDatabaseFilter} from '../../../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';
import {
  VesselFilters,
  IntakeUnitEnum,
  BulkVesselDesignEnum,
  VesselTypeEnum,
  FlagsModeEnum,
} from '../../../api/node-backend/generated';

export const convertFilterSettings = (
  filterSettings: VesselDatabaseDatabaseFilter | undefined
): VesselFilters | undefined => {
  if (!filterSettings) {
    return undefined;
  }

  const flags = getNonEmptyArrayOrUndefined(filterSettings.generalInformation?.flags);
  return {
    vesselTypes: getNonEmptyArrayOrUndefined(filterSettings.vesselTypes as VesselTypeEnum[]),
    name: filterSettings.generalInformation?.name,
    imo: filterSettings.generalInformation?.imo ? [filterSettings.generalInformation?.imo] : undefined,
    flags: flags,
    // Only send flagsMode if we are filtering by flags.
    flagsMode: flags ? getFlagsMode(filterSettings.generalInformation?.includeFlags) : undefined,
    minimumAge: filterSettings.generalInformation?.minimumAge,
    maximumAge: filterSettings.generalInformation?.maximumAge,
    deadweightFrom: filterSettings.size?.deadWeight?.from,
    deadweightTo: filterSettings.size?.deadWeight?.to,
    hatchesFrom: filterSettings.size?.hatches?.from,
    hatchesTo: filterSettings.size?.hatches?.to,
    holdsFrom: filterSettings.size?.holds?.from,
    holdsTo: filterSettings.size?.holds?.to,
    teuFrom: filterSettings.size?.teu?.from,
    teuTo: filterSettings.size?.teu?.to,
    teu14From: filterSettings.size?.teu14?.from,
    teu14To: filterSettings.size?.teu14?.to,
    containerVesselDesigns: getNonEmptyArrayOrUndefined(filterSettings.size?.designTypes),
    bulkVesselDesigns: getNonEmptyArrayOrUndefined(filterSettings.size?.designs) as BulkVesselDesignEnum[] | undefined,
    grossTonnageFrom: filterSettings.dimensions?.grossTonnage?.from,
    grossTonnageTo: filterSettings.dimensions?.grossTonnage?.to,
    netTonnageFrom: filterSettings.dimensions?.netTonnage?.from,
    netTonnageTo: filterSettings.dimensions?.netTonnage?.to,
    lengthFrom: filterSettings.dimensions?.length?.from,
    lengthTo: filterSettings.dimensions?.length?.to,
    beamFrom: filterSettings.dimensions?.beam?.from,
    beamTo: filterSettings.dimensions?.beam?.to,
    tonnesPerCentimetreFrom: filterSettings.dimensions?.tonnesPerCentimetre?.from,
    tonnesPerCentimetreTo: filterSettings.dimensions?.tonnesPerCentimetre?.to,
    draftFrom: filterSettings.dimensions?.draft?.from,
    draftTo: filterSettings.dimensions?.draft?.to,
    grainVolumeFrom: filterSettings.intake?.grainVolume?.from,
    grainVolumeTo: filterSettings.intake?.grainVolume?.to,
    grainUnit: filterSettings.intake?.grainUnit as IntakeUnitEnum,
    baleVolumeFrom: filterSettings.intake?.baleVolume?.from,
    baleVolumeTo: filterSettings.intake?.baleVolume?.to,
    baleUnit: filterSettings.intake?.baleUnit as IntakeUnitEnum,
    reeferPlugsFrom: filterSettings.intake?.reeferPlugs.from,
    reeferPlugsTo: filterSettings.intake?.reeferPlugs.to,
    geared: filterSettings.features?.geared,
    bowThrusted: getTrueOrUndefined(filterSettings.features?.bowThrusted),
    sternThrusted: getTrueOrUndefined(filterSettings.features?.sternThrusted),
    logFitted: getTrueOrUndefined(filterSettings.features?.logFitted),
    lakesFitted: getTrueOrUndefined(filterSettings.features?.lakesFitted),
    tweenDecks: getTrueOrUndefined(filterSettings.features?.tweenDecks),
    a60Bulkhead: getTrueOrUndefined(filterSettings.features?.a60Bulkhead),
    co2Fitted: getTrueOrUndefined(filterSettings.features?.co2Fitted),
    boxShape: getTrueOrUndefined(filterSettings.features?.boxShaped),
    openHatch: getTrueOrUndefined(filterSettings.features?.openHatched),
    itfFitted: getTrueOrUndefined(filterSettings.features?.itfFitted),
    celled: getTrueOrUndefined(filterSettings.features?.cellular),
    iceClass: getTrueOrUndefined(filterSettings.features?.iceClass),
    grabFitted: getTrueOrUndefined(filterSettings.features?.grabbed),
    scrubberFitted: getTrueOrUndefined(filterSettings.features?.scrubberFitted),
  };
};

/**
 * Returns the given array only if it is present and not empty.
 */
const getNonEmptyArrayOrUndefined = <T>(a: T[] | undefined): T[] | undefined => {
  return a && a.length > 0 ? a : undefined;
};

const getTrueOrUndefined = (b: boolean | undefined): true | undefined => {
  switch (b) {
    case true:
      return true;
    case false:
      return undefined;
    default:
      return undefined;
  }
};

const getFlagsMode = (includeFlags: boolean | undefined): FlagsModeEnum | undefined => {
  return includeFlags ? FlagsModeEnum.Inclusive : FlagsModeEnum.Exclusive;
};
