/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationActorType,
  NegotiationActorTypeFromJSON,
  NegotiationActorTypeFromJSONTyped,
  NegotiationActorTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationActor
 */
export interface NegotiationActor {
  /**
   *
   * @type {string}
   * @memberof NegotiationActor
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NegotiationActor
   */
  name: string;
  /**
   * The email is the email from the actor. We send emails to this address.
   * @type {string}
   * @memberof NegotiationActor
   */
  email: string;
  /**
   *
   * @type {NegotiationActorType}
   * @memberof NegotiationActor
   */
  type: NegotiationActorType;
  /**
   *
   * @type {string}
   * @memberof NegotiationActor
   */
  companyName: string;
}

export function NegotiationActorFromJSON(json: any): NegotiationActor {
  return NegotiationActorFromJSONTyped(json, false);
}

export function NegotiationActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationActor {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    email: json['email'],
    type: NegotiationActorTypeFromJSON(json['type']),
    companyName: json['companyName'],
  };
}

export function NegotiationActorToJSON(value?: NegotiationActor | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    email: value.email,
    type: NegotiationActorTypeToJSON(value.type),
    companyName: value.companyName,
  };
}
