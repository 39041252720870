import styled from 'styled-components';
import {Attribute} from '../../atoms/Attribute/Attribute';
import {getIconById} from '../../atoms/WheaterIcons';
import {useGetWeatherAndLocationByLatLonQuery} from '../../queries/useGetWeatherAndLocationByLatLonQuery';
import {getCompassDirection} from '../../utils/helper';
import {renderWeatherBackground} from '../../components/WeatherWidget/utils/weatherUtils';

type WeatherPartialProps = {
  weatherCoordinates: {
    lat: number;
    lon: number;
  };
};

export const WeatherPartial = ({weatherCoordinates}: WeatherPartialProps) => {
  const weatherQuery = useGetWeatherAndLocationByLatLonQuery(weatherCoordinates);

  if (weatherQuery.isError) {
    return null;
  } else if (weatherQuery.isSuccess) {
    const {temp, humidity} = weatherQuery.data.current;
    const windSpeed = weatherQuery.data.current.wind_speed;
    const windDeg = weatherQuery.data.current.wind_deg;
    const windDir = getCompassDirection(windDeg);

    const {id, description} = weatherQuery.data.current.weather[0];
    const WeatherIcon = getIconById(renderWeatherBackground(id));

    return (
      <WeatherContainer>
        <WeatherStatus>
          <WeatherIconWrapper>{WeatherIcon && <WeatherIcon />}</WeatherIconWrapper>
          <WeatherTitle>{description}</WeatherTitle>
        </WeatherStatus>
        <Spacer />
        <WeatherDetails>
          <Attribute tab="l" small label="Air temperature" value={temp + ' °C'} />
          <Attribute tab="l" small label="Humidity" value={humidity + ' %'} />
          <Attribute tab="l" small label="Wind speed" value={windSpeed + ' KPH'} />
          <Attribute tab="l" small label="Wind direction" value={windDir + ' (' + windDeg + '°)'} />
        </WeatherDetails>
      </WeatherContainer>
    );
  } else {
    return <WeatherLoadingText>Loading weather...</WeatherLoadingText>;
  }
};

const WeatherContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  padding-top: 15px;
`;

const WeatherStatus = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  margin-top: 2.5px;
  height: 100%;
  background: var(--color-blue-dark);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
`;

const WeatherTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 5px 2px 5px 2px;
  color: var(--color-white-fixed);
  line-height: 1.125;
`;

const WeatherIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: auto;
  padding: 10px 7.5px 2.5px 7.5px;

  svg {
    height: 50px;
    width: auto;
  }
`;

const WeatherLoadingText = styled.p`
  margin-top: 10px;
`;

const WeatherDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 5px;
  height: 100%;
  width: 230px;
`;

const Spacer = styled.div`
  width: auto;
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;
`;
