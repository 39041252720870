import styled, {css} from 'styled-components';
import {Card} from 'antd';

export const StyledCard = styled(Card)<{$showBody?: boolean}>`
  .ant-card-head-title,
  .ant-card-extra {
    padding: 0px;
  }

  .ant-card-body {
    display: none;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: var(--border-radius-card);
  overflow: hidden;

  ${({$showBody}) =>
    $showBody &&
    css`
      height: 100%;
      .ant-card-body {
        display: flex;
        flex-direction: column;

        flex: 1;
      }
    `}

  .ant-card-head-wrapper {
    align-items: start;
    flex: 1;
    min-height: 150px;
    max-width: 100%;

    @media (min-width: 1300px) {
      min-height: 130px;
    }
    @media (min-width: 1400px) {
      min-height: 110px;
    }
    @media (min-width: 1600px) {
      min-height: 94px;
    }

    @media (min-width: 1800px) {
      min-height: 80px;
    }
    @media (min-width: 2030px) {
      min-height: 68px;
    }
  }

  .ant-card-head {
    display: flex;
  }
`;
