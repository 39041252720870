import {useQuery} from '@tanstack/react-query';
import {isApiError} from '../../../../../api/utils/ApiError';
import {TODO} from '../../../../../utils/TODO';
import {getVesselByImo} from '../../../../../queries/useGetVesselByImoQuery';

type Vessel = TODO;

export type CorrectResult = {correctImport: true; vessel: Vessel};
export type WrongResult = {correctImport: false; message: string; imo: number};
export type Result = CorrectResult | WrongResult;

export const useFastVesselImportMapQuery = ({imos}: {imos: number[]}) => {
  return useQuery({
    queryKey: [`fastVesselImport`, imos],
    queryFn: async () => {
      const vesselPromises = imos.map(async (imo): Promise<Result> => {
        if (isNaN(imo)) {
          return {correctImport: false, message: 'IMO is no number.', imo};
        }
        try {
          const vessel = await getVesselByImo(imo);
          if (!vessel) {
            return {correctImport: false, message: 'Vessel not found.', imo};
          }
          return {
            correctImport: true,
            vessel: vessel,
          };
        } catch (e) {
          if (isApiError(e)) {
            if (e.message.startsWith('Vessel not found.')) {
              return {correctImport: false, message: 'Vessel not found.', imo};
            }
          }
          throw e;
        }
      });
      const vessels = await Promise.all(vesselPromises);
      return vessels;
    },
  });
};
