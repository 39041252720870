import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {CSSTransition} from 'react-transition-group';
import {useSelector} from '../../../../../../redux/react-redux';
import {MapDetailItem, MapDetailItemList, MapDetailItemType, PortAreaCV} from '../../../utils/types';
import {MapDetailPortAreaItemDetail} from './MapDetailPortAreaItemDetail';
import {getPortAreaItems, getPortItemListHeaderLabel, OnMapElementChange} from '../../../utils/utils';
import {MapDetailPortAreaListItem} from './MapDetailPortAreaListItem';
import {BoxHalf, FlexBox} from './shared-components';
import {ColumnOptions} from './ColumnOptions';
import Lighthouse from '../../../../../../atoms/EmptyState/LightHouse';
import capitalize from 'lodash/capitalize';
import {getSortedCargoes, getSortedVessels} from '../../../utils/getSortedItems';
import {useMapContext} from '../../../../MapContext/MapContext';
import {getItemView} from '../../../utils/itemView';
import {Tooltip} from 'antd';

type MapDetailPortAreaContentProps = {
  portCV: PortAreaCV;
  onVesselLocationClicked?: OnMapElementChange;
};

export const MapDetailPortAreaContent: React.FC<MapDetailPortAreaContentProps> = ({
  portCV,
  onVesselLocationClicked,
}) => {
  const {
    portPreviewItemType: detailType,
    cargoSort,
    cargoSortDesc,
    vesselSort,
    vesselSortDesc,
  } = useSelector(({mapDetails}) => mapDetails);
  const {
    state: {
      settings: {switches},
      selectedItems,
    },
  } = useMapContext();
  const itemView = getItemView(switches);

  const {portAreaItems, sortedCargoes, sortedVessels} = useMemo(() => {
    const portAreaItems = getPortAreaItems(portCV);
    const sortedCargoes = cargoSort ? getSortedCargoes(portAreaItems, cargoSort, cargoSortDesc) : portAreaItems.cargoes;
    const sortedVessels = vesselSort
      ? getSortedVessels(portAreaItems, vesselSort, vesselSortDesc)
      : portAreaItems.vessels;
    return {
      portAreaItems,
      sortedCargoes,
      sortedVessels,
    };
  }, [portCV, cargoSort, cargoSortDesc, vesselSort, vesselSortDesc]);

  const cargoHeaderLabel = getPortItemListHeaderLabel(itemView, 'cargo', portAreaItems.cargoes.totalItems);
  const vesselHeaderLabel = getPortItemListHeaderLabel(itemView, 'vessel', portAreaItems.vessels.totalItems);

  const isFullView = !selectedItems.items.length;

  return (
    <>
      <Tooltip
        trigger="hover"
        placement="left"
        title={'Adjust which items are shown by using the map settings above this message'}>
        <PortContentHeader>
          {isFullView && detailType !== 'vessel' && (
            <MapDetailPortAreaColumnHeader>
              <ColumnHeader>{cargoHeaderLabel}</ColumnHeader>
              <ColumnOptions type="cargo" />
            </MapDetailPortAreaColumnHeader>
          )}
          {detailType !== 'cargo' && (
            <MapDetailPortAreaColumnHeader>
              <ColumnHeader>{vesselHeaderLabel}</ColumnHeader>
              <ColumnOptions type="vessel" />
            </MapDetailPortAreaColumnHeader>
          )}
        </PortContentHeader>
      </Tooltip>
      <PortContentBody>
        {isFullView && (
          <MapDetailPortAreaColumn
            columnType="cargo"
            columnItems={sortedCargoes}
            onVesselLocationClicked={onVesselLocationClicked}
          />
        )}
        <MapDetailPortAreaColumn
          columnType="vessel"
          columnItems={sortedVessels}
          onVesselLocationClicked={onVesselLocationClicked}
        />
      </PortContentBody>
    </>
  );
};

type MapDetailPortAreaColumnProps = {
  columnType: MapDetailItemType;
  columnItems: MapDetailItemList<MapDetailItem>;
  onVesselLocationClicked?: OnMapElementChange;
};
export const MapDetailPortAreaColumn: React.FC<MapDetailPortAreaColumnProps> = ({
  columnType,
  columnItems,
  onVesselLocationClicked,
}) => {
  const {portPreviewItem: detailItem, portPreviewItemType: detailType} = useSelector(({mapDetails}) => mapDetails);
  const isDetailView = !!detailItem && !!detailType && detailType !== columnType;

  // keep item in state for correct exit animation
  const [cachedItem, setCachedItem] = useState(detailItem);
  useEffect(() => {
    if (detailItem === null) {
      return;
    }
    setCachedItem(detailItem);
  }, [detailItem]);

  return (
    <BoxHalf>
      <MapDetailsListColumn>
        {columnItems.items.map((item: MapDetailItem) => (
          <MapDetailPortAreaListItem
            key={item.id}
            item={item}
            columnType={columnType}
            onVesselLocationClicked={onVesselLocationClicked}
          />
        ))}
        {!columnItems.totalItems && <ColumnEmpty headline={`No ${capitalize(columnType)} found`} small />}
      </MapDetailsListColumn>
      <CSSTransition in={isDetailView} timeout={300} classNames="slide" unmountOnExit>
        <MapDetailPortAreaItemDetail item={cachedItem} />
      </CSSTransition>
    </BoxHalf>
  );
};

const PortContentHeader = styled(FlexBox)`
  height: 40px;

  ${BoxHalf}:first-of-type:not(:last-of-type) {
    border-right: var(--border-width) solid var(--border-color-light);
  }
`;

const ColumnHeader = styled.div`
  margin: 0 auto;
`;

const MapDetailPortAreaColumnHeader = styled(BoxHalf)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 40px;
  padding: 5px 10px;
  background: var(--map-subheader-color);
  color: var(--color-gray-2);
  font-weight: bold;
  text-align: center;
  border-bottom: var(--border-width) solid var(--border-color-light);
  box-shadow: var(--map-subheader-shadow);
  z-index: var(--map-subheader-z);
`;

const PortContentBody = styled(FlexBox)`
  height: calc(100% - 40px);

  ${BoxHalf}:first-of-type:not(:last-of-type) {
    border-right: var(--border-width) solid var(--border-color-light);
  }
`;

const MapDetailsListColumn = styled.div`
  display: block;
  width: 100%;
  padding: 0px;
  overflow-y: auto;
`;

const ColumnEmpty = styled(Lighthouse)`
  margin-top: 20%;
  opacity: 0.3;
`;
