/**
 * Validates the given IMO number.
 *
 * @see https://de.wikipedia.org/wiki/IMO-Nummer
 */
export const isImoValid = (imo: number | string): boolean => {
  const imoStr = imo.toString();
  if (imoStr.length !== 7) {
    return false;
  }

  const digits = getDigits(imoStr);
  const checksum = calculateImoChecksum(digits);
  const checkDigit = digits[6];
  const expectedCheckDigit = checksum % 10;

  return checkDigit === expectedCheckDigit;
};

export const getDigits = (imoStr: string): number[] => Array.from(imoStr).map(s => parseInt(s, 10));

export const calculateImoChecksum = (digits: number[]): number => {
  let checksum = 0;
  for (let index = 0; index < 6; index++) {
    checksum += digits[index] * (7 - index);
  }

  return checksum;
};
