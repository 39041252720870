import {Layer, Source} from 'react-map-gl';
import {useMapContext} from './MapContext/MapContext';

const VECTOR_SOURCE_URL = 'mapbox://intradesys.9ctw056m';
const SOURCE_LAYER = 'hra_v4_eca';

export const HighRiskArea = () => {
  const mapContext = useMapContext();

  const switches = mapContext.state.switches;

  const visible = switches.layer_piracy.state ?? false;

  return (
    <Source id="highRiskArea" type="vector" url={VECTOR_SOURCE_URL}>
      {visible && (
        <Layer
          id={'highRiskArea'}
          source={'highRiskArea'}
          source-layer={SOURCE_LAYER}
          type={'fill-extrusion'}
          paint={{
            'fill-extrusion-color': '#F20307',
            'fill-extrusion-opacity': 0.3,
          }}
        />
      )}
    </Source>
  );
};
