import dayjs from 'dayjs';
import {useCallback, useState} from 'react';
import {Notification} from '../../../../api/symfony/generated/models/Notification';
import useInterval from 'beautiful-react-hooks/useInterval';

const BELL_ANIMATION_DURATION_IN_SECONDS = 45;
const TIME_TO_NEXT_CHECK_IN_MS = 5000;

/**
 * Returns true if the ringing bell animation should happen and schedules a periodic
 * check to be able to turn it on or off.
 */
export const useRingingBellAnimation = (notifications: Notification[]): boolean => {
  const [animate, setAnimate] = useState<boolean>(() => isBellAnimationEnabled(notifications));
  const checkIfShouldAnimate = useCallback(() => {
    const animationEnabled = isBellAnimationEnabled(notifications);
    // setAnimate() will trigger a rerender if the value changes, which is what we want.
    setAnimate(animationEnabled);
  }, [notifications]);

  useInterval(checkIfShouldAnimate, TIME_TO_NEXT_CHECK_IN_MS);

  return animate;
};

export const isBellAnimationEnabled = (notifications: Notification[]): boolean => notifications.some(isRecentAndUnread);

const isRecentAndUnread = (notification: Notification): boolean => {
  const seconds = dayjs(Date.now()).diff(dayjs(notification.time), 'seconds');
  const recent = seconds < BELL_ANIMATION_DURATION_IN_SECONDS;
  const unread = !notification.isRead;

  return recent && unread;
};
