/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationStatus,
  NegotiationStatusFromJSON,
  NegotiationStatusFromJSONTyped,
  NegotiationStatusToJSON,
  NegotiationSubjectModificationReport,
  NegotiationSubjectModificationReportFromJSON,
  NegotiationSubjectModificationReportFromJSONTyped,
  NegotiationSubjectModificationReportToJSON,
  RoundResultTermModification,
  RoundResultTermModificationFromJSON,
  RoundResultTermModificationFromJSONTyped,
  RoundResultTermModificationToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationRoundResultModification
 */
export interface NegotiationRoundResultModification {
  /**
   *
   * @type {string}
   * @memberof NegotiationRoundResultModification
   */
  roundResultId: string;
  /**
   *
   * @type {Array<RoundResultTermModification>}
   * @memberof NegotiationRoundResultModification
   */
  termModifications: Array<RoundResultTermModification>;
  /**
   *
   * @type {NegotiationStatus}
   * @memberof NegotiationRoundResultModification
   */
  oldNegotiationStatus?: NegotiationStatus;
  /**
   *
   * @type {NegotiationStatus}
   * @memberof NegotiationRoundResultModification
   */
  newNegotiationStatus: NegotiationStatus;
  /**
   * The modifications of the global subjects since the previous round.
   * @type {Array<NegotiationSubjectModificationReport>}
   * @memberof NegotiationRoundResultModification
   */
  subjectModificationReports: Array<NegotiationSubjectModificationReport>;
  /**
   * The negotiation status has changed since the last round.
   * @type {boolean}
   * @memberof NegotiationRoundResultModification
   */
  negotiationStatusChanged: boolean;
}

export function NegotiationRoundResultModificationFromJSON(json: any): NegotiationRoundResultModification {
  return NegotiationRoundResultModificationFromJSONTyped(json, false);
}

export function NegotiationRoundResultModificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationRoundResultModification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    roundResultId: json['roundResultId'],
    termModifications: (json['termModifications'] as Array<any>).map(RoundResultTermModificationFromJSON),
    oldNegotiationStatus: !exists(json, 'oldNegotiationStatus')
      ? undefined
      : NegotiationStatusFromJSON(json['oldNegotiationStatus']),
    newNegotiationStatus: NegotiationStatusFromJSON(json['newNegotiationStatus']),
    subjectModificationReports: (json['subjectModificationReports'] as Array<any>).map(
      NegotiationSubjectModificationReportFromJSON
    ),
    negotiationStatusChanged: json['negotiationStatusChanged'],
  };
}

export function NegotiationRoundResultModificationToJSON(value?: NegotiationRoundResultModification | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    roundResultId: value.roundResultId,
    termModifications: (value.termModifications as Array<any>).map(RoundResultTermModificationToJSON),
    oldNegotiationStatus: NegotiationStatusToJSON(value.oldNegotiationStatus),
    newNegotiationStatus: NegotiationStatusToJSON(value.newNegotiationStatus),
    subjectModificationReports: (value.subjectModificationReports as Array<any>).map(
      NegotiationSubjectModificationReportToJSON
    ),
    negotiationStatusChanged: value.negotiationStatusChanged,
  };
}
