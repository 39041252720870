import React, {ReactNode} from 'react';
import {Trigger} from 'react-tippy';
import Tooltip from '../atoms/Tooltip';
import lodashMap from 'lodash/map';

import {LocationType, StationType} from '../api/symfony/generated';
import Flag from '../atoms/Flags';
import styled from 'styled-components';

export interface StationObject {
  type: StationType;
  locations: Array<Location>;
}

interface Location {
  type: LocationType | 'more';
  name: string;
  country: string;
  code?: string;
}

interface Options {
  short?: boolean;
  highlight?: boolean;
  emptyState?: ReactNode;
  showFlag?: boolean;
}

const isNotNull = <TValue,>(value: TValue | null): value is TValue => value !== null;

class Station {
  static DELIVERY = StationType.Delivery;
  static LOADING = StationType.Loading;
  static REDELIVERY = StationType.Redelivery;
  static DISCHARGE = StationType.Discharge;
  static NEXTOPEN = StationType.Nextopen;
  static FIXTUREDELVIERY = StationType.Fixturedelivery;
  static FIXTUREREDELIVERY = StationType.Fixtureredelivery;

  static getLocationByStationType(
    stations: StationObject[],
    stationType: StationType | undefined,
    options: Options
  ): ReactNode {
    const {short = false, highlight = false, emptyState = null, showFlag = false} = options ?? {};
    const locations = stations?.reduce((tally: Array<Location>, station) => {
      const {type, locations} = station;
      // return type === stationType ? [...tally, ...locations.map(({type, name}) => ({type, name}))] : tally;
      // We are using lodashMap instead of Array#map as workaround for a backend problem. Currently the backend doesn't send locations as a JSON array, but as an object. lodashMap can deal with this.
      // Let's use Array#map once the endpoint is fixed.
      return type === stationType
        ? [...tally, ...lodashMap(locations, ({type, name, country, code}) => ({type, name, country: country ?? code}))]
        : tally;
    }, []);

    if (!locations || locations.length === 0) {
      return emptyState;
    }

    let moreThanTwoItems: Location | null = null;
    let shortLocations: Array<Location> = [];
    if (short) {
      const firstItem = locations[0];
      const secondItem = locations.length > 1 ? locations[1] : null;
      moreThanTwoItems = locations.length > 2 ? {type: 'more', name: '...', country: ''} : null;
      shortLocations = secondItem ? [firstItem, secondItem, moreThanTwoItems].filter(isNotNull) : [firstItem];
    }

    return moreThanTwoItems ? (
      <Tooltip position={'top'} trigger={'mouseenter focus' as Trigger} title={locations.map(l => l.name).join(', ')}>
        {shortLocations.map((l, idx) => (
          <span
            key={idx}
            style={{color: highlight && l.type !== 'port' ? 'var(--color-gray-2)' : 'var(--color-black)'}}>
            <LocationLabel>
              {showFlag && (
                <>
                  <Flag countryCode={l.country} />{' '}
                </>
              )}
              {l.name}
            </LocationLabel>
            {idx !== shortLocations.length - 1 ? ', ' : ''}
          </span>
        ))}
      </Tooltip>
    ) : (
      locations.map((l, idx) => (
        <span key={idx} style={{color: highlight && l.type !== 'port' ? 'var(--color-gray-2)' : 'var(--color-black)'}}>
          <LocationLabel>
            {showFlag && (
              <>
                <Flag countryCode={l.country} />{' '}
              </>
            )}
            {l.name}
          </LocationLabel>
          {idx !== locations.length - 1 ? ', ' : ''}
        </span>
      ))
    );
  }
}

const LocationLabel = styled.span`
  white-space: pre;
`;

export default Station;
