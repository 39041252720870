import FixtureType from '../../components/FilterProvider/Filters/Fixtures/FixtureType/FixtureType';
import FixtureVessel from '../../components/FilterProvider/Filters/Fixtures/FixtureVessel/FixtureVessel';
import FixtureCargo from '../../components/FilterProvider/Filters/Fixtures/FixtureCargo/FixtureCargo';
import FixtureDate from '../../components/FilterProvider/Filters/Fixtures/FixtureDate/FixtureDate';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import FixtureOptions from '../../components/FilterProvider/Filters/Fixtures/FixtureOptions/FixtureOptions';
import {FixtureLocationLaycan} from '../../components/FilterProvider/Filters/Fixtures/FixtureLocationLaycan/FixtureLocationLaycan';
import FixtureVesselDetails from '../../components/FilterProvider/Filters/Fixtures/FixtureVesselDetails/FixtureVesselDetails';
import {FilterBar} from '../../components/FilterBar/FilterBar';
import UpdatedAt from '../../components/FilterProvider/Filters/Fixtures/UpdatedAt/UpdatedAt';
import {VesselFixturesFilterBranchDefinitions} from '../../components/FilterProvider/Filters/Fixtures/VesselFixturesFilterBranchDefinitions';

export const FixtureFilters = ({
  filterProviderApi,
}: {
  filterProviderApi: FilterProviderApi<typeof VesselFixturesFilterBranchDefinitions>;
}) => (
  <FilterBar
    id="fixtureFilters"
    filters={[
      <FixtureType filterProviderApi={filterProviderApi} />,
      <FixtureVessel filterProviderApi={filterProviderApi} />,
      <FixtureVesselDetails filterProviderApi={filterProviderApi} />,
      <FixtureCargo filterProviderApi={filterProviderApi} />,
      <FixtureOptions filterProviderApi={filterProviderApi} />,
      <FixtureLocationLaycan filterProviderApi={filterProviderApi} />,
      <FixtureDate filterProviderApi={filterProviderApi} />,
      <UpdatedAt filterProviderApi={filterProviderApi} />,
    ]}
  />
);
