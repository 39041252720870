import {TextLayer} from '@deck.gl/layers/typed';
import {VoyageAnimationPortVisit} from '../../model/VoyageAnimationPortVisit';
import {transformLonLatFromObject} from '../../../../../../utils/Coordinates';
import uniqBy from 'lodash/uniqBy';
import {useMemo} from 'react';

export const usePortNameLayer = (portVisits: VoyageAnimationPortVisit[]) => {
  // Prevent ports from being rendered multiple times, because it looks bad.
  const portVisitsWithUniquePorts = useMemo(() => uniqBy(portVisits, portVisit => portVisit.port.id), [portVisits]);

  const layer = new TextLayer<VoyageAnimationPortVisit>({
    id: 'port-name-layer',
    data: portVisitsWithUniquePorts,

    /* props from TextLayer class */

    // background: false,
    // backgroundPadding: [0, 0, 0, 0],
    // billboard: true,
    // characterSet: " !\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^_`abcdefghijklmnopqrstuvwxyz{|}~",
    fontFamily: 'Arial',
    // fontSettings: {},
    // fontWeight: 'normal',
    getAngle: 0,
    // getBackgroundColor: [255, 255, 255, 255],
    // getBorderColor: [0, 0, 0, 255],
    // getBorderWidth: 0,
    getColor: [255, 128, 0],
    // getPixelOffset: [0, 0],
    getPosition: d => transformLonLatFromObject(d.port.coordinates),
    getSize: 18,
    getText: d => `${d.port.name}`,
    // Horizonal alignment:
    getTextAnchor: 'start',
    // Vertical alignment:
    getAlignmentBaseline: 'top',
    // lineHeight: 1,
    // maxWidth: -1,
    // outlineColor: [0, 0, 0, 255],
    // outlineWidth: 0,
    // sizeMaxPixels: Number.MAX_SAFE_INTEGER,
    // sizeMinPixels: 0,
    sizeScale: 1,
    // sizeUnits: 'pixels',
    // wordBreak: 'break-word',

    /* props inherited from Layer class */

    getPixelOffset: [25, -30],

    // autoHighlight: false,
    // coordinateOrigin: [0, 0, 0],
    // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    // highlightColor: [0, 0, 128, 128],
    // modelMatrix: null,
    // opacity: 1,
    pickable: true,
    // visible: true,
    // wrapLongitude: false,
  });
  return layer;
};
