import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';
import {FilterItem, FilterType} from '../api/symfony/generated';
import {assertUnreachable} from '../utils/assert';

export type SubMarketType = FilterType.Vessel | FilterType.Cargo;

interface SubMarketState {
  subMarkets: FilterItem[];
  //* The id of the active submarket tab in cargoes.
  activeTabCargo: number | 'all';
  //* The id of the active submarket tab in vessels.
  activeTabVessel: number | 'all';
}

const initialState: SubMarketState = {
  subMarkets: [],
  activeTabCargo: 'all',
  activeTabVessel: 'all',
};

interface UpdateSortKeyPayload {
  id: number;
  newIndex: number;
}

interface SelectPayload {
  id: number | 'all';
  subMarketType: SubMarketType;
}

interface DeletePayload {
  id: number;
  subMarketType: SubMarketType;
}

const subMarketSlice = createSlice({
  name: 'SubMarket',
  initialState,
  reducers: {
    set(state, action: PayloadAction<FilterItem[]>) {
      state.subMarkets = action.payload;
    },
    add(state, action: PayloadAction<FilterItem>) {
      const fieldName = getActiveTabField(convertToSubMarketType(action.payload.type!));
      state.subMarkets.push(action.payload);
      state[fieldName] = action.payload.id;
    },
    delete(state, action: PayloadAction<DeletePayload>) {
      const index = findIndex(state.subMarkets, {id: action.payload.id});
      if (index >= 0) {
        state.subMarkets.splice(index, 1);
        const fieldName = getActiveTabField(action.payload.subMarketType);
        state[fieldName] = 'all';
      }
    },
    update(state, action: PayloadAction<FilterItem>) {
      const index = findIndex(state.subMarkets, {id: action.payload.id});
      if (index >= 0) {
        state.subMarkets[index] = action.payload;
      }
    },
    updateSortKey(state, action: PayloadAction<UpdateSortKeyPayload>) {
      const index = findIndex(state.subMarkets, {id: action.payload.id});
      if (index >= 0) {
        state.subMarkets[index].sortKey = action.payload.newIndex;
      }
    },
    select(state, action: PayloadAction<SelectPayload>) {
      const fieldName = getActiveTabField(action.payload.subMarketType);
      state[fieldName] = action.payload.id;
    },
  },
});

const convertToSubMarketType = (type: FilterType): SubMarketType => {
  switch (type) {
    case FilterType.Vessel:
    case FilterType.Cargo:
      return type;
    default:
      throw new Error(`Bad type ${type}}`);
  }
};

const getActiveTabField = (type: SubMarketType) => {
  switch (type) {
    case FilterType.Vessel:
      return 'activeTabVessel';
    case FilterType.Cargo:
      return 'activeTabCargo';
    default:
      assertUnreachable(type);
  }
};

export const subMarketReducer = subMarketSlice.reducer;

export const SubMarketActions = subMarketSlice.actions;
