import {Rule} from 'rc-field-form/lib/interface';
import {TODO} from '../../../../../../utils/TODO';

export const validateConsumption: Rule = form => {
  return {
    validator: async rule => {
      const fullField = (rule as TODO).fullField as string;
      const index = fullField.split('.')[1];

      const useCaseField = ['consumptionModes', index, 'useCase'];
      const mainConsumptionField = ['consumptionModes', index, 'mainConsumption'];
      const auxFuelTypeField = ['consumptionModes', index, 'auxFuelType'];
      const mainFuelTypeField = ['consumptionModes', index, 'mainFuelType'];
      const auxConsumptionField = ['consumptionModes', index, 'auxConsumption'];

      const mainConsumption = form.getFieldValue(mainConsumptionField);
      const auxFuelType = form.getFieldValue(auxFuelTypeField);
      const mainFuelType = form.getFieldValue(mainFuelTypeField);
      const auxConsumption = form.getFieldValue(auxConsumptionField);
      const useCase = form.getFieldValue(useCaseField);

      if (useCase?.startsWith('port')) {
        // Port consumption can be empty
        // See: https://seanexxt.atlassian.net/browse/FRIN-5503
        return true;
      }

      if (auxConsumption && auxFuelType) {
        return true;
      }
      if (mainConsumption && mainFuelType) {
        return true;
      }

      throw new Error('Consumption value required');
    },
  };
};
