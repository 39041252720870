/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselBuiltYearTeu
 */
export interface VesselBuiltYearTeu {
  /**
   *
   * @type {number}
   * @memberof VesselBuiltYearTeu
   */
  builtYear: number;
  /**
   *
   * @type {number}
   * @memberof VesselBuiltYearTeu
   */
  teu: number;
}

export function VesselBuiltYearTeuFromJSON(json: any): VesselBuiltYearTeu {
  return VesselBuiltYearTeuFromJSONTyped(json, false);
}

export function VesselBuiltYearTeuFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselBuiltYearTeu {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    builtYear: json['builtYear'],
    teu: json['teu'],
  };
}

export function VesselBuiltYearTeuToJSON(value?: VesselBuiltYearTeu | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    builtYear: value.builtYear,
    teu: value.teu,
  };
}
