/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {AreaVisitsResponse, AreaVisitsResponseFromJSON, AreaVisitsResponseToJSON} from '../models';

export interface GetAreaVisitsRequest {
  pageSize?: number;
  pageIndex?: number;
  imo?: number;
  isOngoing?: boolean;
  areaId?: number;
  areaType?: string;
  durationMinimum?: number;
}

/**
 *
 */
export class AreaVisitsApi extends runtime.BaseAPI {
  /**
   */
  async getAreaVisitsRaw(requestParameters: GetAreaVisitsRequest): Promise<runtime.ApiResponse<AreaVisitsResponse>> {
    const queryParameters: any = {};

    if (requestParameters.pageSize !== undefined) {
      queryParameters['page-size'] = requestParameters.pageSize;
    }

    if (requestParameters.pageIndex !== undefined) {
      queryParameters['page-index'] = requestParameters.pageIndex;
    }

    if (requestParameters.imo !== undefined) {
      queryParameters['imo'] = requestParameters.imo;
    }

    if (requestParameters.isOngoing !== undefined) {
      queryParameters['is-ongoing'] = requestParameters.isOngoing;
    }

    if (requestParameters.areaId !== undefined) {
      queryParameters['area-id'] = requestParameters.areaId;
    }

    if (requestParameters.areaType !== undefined) {
      queryParameters['area-type'] = requestParameters.areaType;
    }

    if (requestParameters.durationMinimum !== undefined) {
      queryParameters['duration-minimum'] = requestParameters.durationMinimum;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/area-visits`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AreaVisitsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getAreaVisits(requestParameters: GetAreaVisitsRequest): Promise<AreaVisitsResponse> {
    const response = await this.getAreaVisitsRaw(requestParameters);
    return await response.value();
  }
}
