import {FC, useMemo, useState} from 'react';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {VesselGrid} from '../../../components/Portfolio/VesselGrid/VesselGrid';
import {PortfolioActions, PortfolioType, PortfolioVessel, SortParams, SubPortfolioId} from '../../../redux/Portfolio';
import {MatchingOrderBookVesselModal} from './MatchingOrderBookVessels/MatchingOrderBookVesselModal';
import {selectVesselGridLayout} from '../../../components/VesselGridLayoutSelect/selectVesselGridLayout';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import {transformSeaboVesselToPortfolioVessel} from './VesselsFromSpire/transformSeaboVesselToPlattformVessel';

const TYPE: PortfolioType = 'vessel';

export const MyFleetVesselGrid: FC<{
  isFiltered: boolean;
  isArchive: boolean;
  loading: boolean;
  fetching?: boolean;
  vessels: PortfolioVessel[] | null;
  vesselsTotal: number;
  onReloadGrid: (pageNumber?: number) => void;
}> = ({isFiltered, isArchive, loading, fetching, vessels, vesselsTotal, onReloadGrid}) => {
  const [, setLocalStoragePageSize] = useLocalStorage('seabo_portfolio_vessel_page_size', 10);
  const {activeTabVessel} = useSelector(state => state.portfolio.screenState);
  const {pageSize, pageNumber, sortField, sortOrder, gridLayoutMode} = useSelector(
    state => state.portfolio.vesselGridState
  );
  const [spireMatchingModalProps, setSpireMatchingModalProps] = useState<null | {
    old: PortfolioVessel;
    new: PortfolioVessel;
  }>(null);
  const displayedGridLayout = useMemo(
    () => selectVesselGridLayout({userSelectedLayoutMode: gridLayoutMode, vessels}),
    [gridLayoutMode, vessels]
  );

  const dispatch = useDispatch();

  const setPageNumber = (pageNumber: number) => {
    dispatch(PortfolioActions.setPageNumber(TYPE, pageNumber));
  };
  const setPageSize = (pageSize: number) => {
    setLocalStoragePageSize(pageSize);
    dispatch(PortfolioActions.setPageSize(TYPE, pageSize));
  };
  const setSortParams = (sortParams: SortParams) => {
    dispatch(PortfolioActions.setSortParams({portfolioType: TYPE, sortParams}));
  };
  const selectSubPortfolio = (id: SubPortfolioId) => {
    return dispatch(PortfolioActions.selectSubPortfolio(TYPE, id));
  };

  return (
    <>
      {spireMatchingModalProps && (
        <MatchingOrderBookVesselModal
          oldVessel={spireMatchingModalProps.old}
          newVessel={spireMatchingModalProps.new}
          onCancel={() => {
            setSpireMatchingModalProps(null);
          }}
        />
      )}

      <VesselGrid
        vessels={vessels}
        isSub={activeTabVessel !== 'all'}
        isArchive={isArchive}
        loading={loading}
        fetching={fetching}
        isFiltered={isFiltered}
        showPageSizeOptions
        gridLayout={displayedGridLayout}
        pages={Math.ceil(vesselsTotal / pageSize)}
        totalCount={vesselsTotal}
        pageSize={pageSize}
        page={pageNumber}
        gridOptions={{
          shipNameColumn: {
            onSyncWithSpire: ({vessel, spireData}) => {
              setSpireMatchingModalProps({
                old: vessel,
                new: {
                  ...vessel,
                  ...transformSeaboVesselToPortfolioVessel(spireData),
                },
              });
            },
          },
        }}
        sorted={[{id: sortField, desc: sortOrder === 'desc'}]}
        onPageChange={pageNumber => {
          setPageNumber(pageNumber);
        }}
        onPageSizeChange={pageSize => {
          setPageSize(pageSize);
          setPageNumber(1);
        }}
        reloadGrid={onReloadGrid}
        goToAll={() => {
          selectSubPortfolio('all');
        }}
        onSortedChange={sorted => {
          setSortParams({
            sortField: sorted.id,
            sortOrder: sorted.desc ? 'desc' : 'asc',
          });
        }}
      />
    </>
  );
};
