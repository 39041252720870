/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * Raw data for a vessel offer
 * @export
 * @interface RawVesselData
 */
export interface RawVesselData {
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  vesselName?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  flag?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  yearBuilt?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  imoNumber?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  draft?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  tpc?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  grainCapacity?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  baleCapacity?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  length?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  beam?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  holds?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  hatches?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  cranes?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  grt?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  nrt?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  speedLaden?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  consumptionLaden?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  speedBallast?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  consumptionBallast?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  consumptionPort?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  extraFittings?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  vesselType?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  port?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  consumptionPortWorking?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  ecoConsumptionBallast?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  ecoConsumptionLaden?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  ecoSpeedBallast?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  ecoSpeedLaden?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  forSale?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  teu?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  teu14?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  reefer?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  builder?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  design?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  craneCapacity?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  grabs?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  grabCapacity?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  lastCargoes?: string;
  /**
   *
   * @type {string}
   * @memberof RawVesselData
   */
  feu?: string;
}

export function RawVesselDataFromJSON(json: any): RawVesselData {
  return RawVesselDataFromJSONTyped(json, false);
}

export function RawVesselDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawVesselData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vesselName: !exists(json, 'vesselName') ? undefined : json['vesselName'],
    flag: !exists(json, 'flag') ? undefined : json['flag'],
    yearBuilt: !exists(json, 'yearBuilt') ? undefined : json['yearBuilt'],
    imoNumber: !exists(json, 'imoNumber') ? undefined : json['imoNumber'],
    size: !exists(json, 'size') ? undefined : json['size'],
    draft: !exists(json, 'draft') ? undefined : json['draft'],
    tpc: !exists(json, 'tpc') ? undefined : json['tpc'],
    grainCapacity: !exists(json, 'grainCapacity') ? undefined : json['grainCapacity'],
    baleCapacity: !exists(json, 'baleCapacity') ? undefined : json['baleCapacity'],
    length: !exists(json, 'length') ? undefined : json['length'],
    beam: !exists(json, 'beam') ? undefined : json['beam'],
    holds: !exists(json, 'holds') ? undefined : json['holds'],
    hatches: !exists(json, 'hatches') ? undefined : json['hatches'],
    cranes: !exists(json, 'cranes') ? undefined : json['cranes'],
    grt: !exists(json, 'grt') ? undefined : json['grt'],
    nrt: !exists(json, 'nrt') ? undefined : json['nrt'],
    speedLaden: !exists(json, 'speedLaden') ? undefined : json['speedLaden'],
    consumptionLaden: !exists(json, 'consumptionLaden') ? undefined : json['consumptionLaden'],
    speedBallast: !exists(json, 'speedBallast') ? undefined : json['speedBallast'],
    consumptionBallast: !exists(json, 'consumptionBallast') ? undefined : json['consumptionBallast'],
    consumptionPort: !exists(json, 'consumptionPort') ? undefined : json['consumptionPort'],
    extraFittings: !exists(json, 'extraFittings') ? undefined : json['extraFittings'],
    vesselType: !exists(json, 'vesselType') ? undefined : json['vesselType'],
    port: !exists(json, 'port') ? undefined : json['port'],
    date: !exists(json, 'date') ? undefined : json['date'],
    consumptionPortWorking: !exists(json, 'consumptionPortWorking') ? undefined : json['consumptionPortWorking'],
    ecoConsumptionBallast: !exists(json, 'ecoConsumptionBallast') ? undefined : json['ecoConsumptionBallast'],
    ecoConsumptionLaden: !exists(json, 'ecoConsumptionLaden') ? undefined : json['ecoConsumptionLaden'],
    ecoSpeedBallast: !exists(json, 'ecoSpeedBallast') ? undefined : json['ecoSpeedBallast'],
    ecoSpeedLaden: !exists(json, 'ecoSpeedLaden') ? undefined : json['ecoSpeedLaden'],
    forSale: !exists(json, 'forSale') ? undefined : json['forSale'],
    teu: !exists(json, 'teu') ? undefined : json['teu'],
    teu14: !exists(json, 'teu14') ? undefined : json['teu14'],
    reefer: !exists(json, 'reefer') ? undefined : json['reefer'],
    builder: !exists(json, 'builder') ? undefined : json['builder'],
    design: !exists(json, 'design') ? undefined : json['design'],
    craneCapacity: !exists(json, 'craneCapacity') ? undefined : json['craneCapacity'],
    grabs: !exists(json, 'grabs') ? undefined : json['grabs'],
    grabCapacity: !exists(json, 'grabCapacity') ? undefined : json['grabCapacity'],
    lastCargoes: !exists(json, 'lastCargoes') ? undefined : json['lastCargoes'],
    feu: !exists(json, 'feu') ? undefined : json['feu'],
  };
}

export function RawVesselDataToJSON(value?: RawVesselData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vesselName: value.vesselName,
    flag: value.flag,
    yearBuilt: value.yearBuilt,
    imoNumber: value.imoNumber,
    size: value.size,
    draft: value.draft,
    tpc: value.tpc,
    grainCapacity: value.grainCapacity,
    baleCapacity: value.baleCapacity,
    length: value.length,
    beam: value.beam,
    holds: value.holds,
    hatches: value.hatches,
    cranes: value.cranes,
    grt: value.grt,
    nrt: value.nrt,
    speedLaden: value.speedLaden,
    consumptionLaden: value.consumptionLaden,
    speedBallast: value.speedBallast,
    consumptionBallast: value.consumptionBallast,
    consumptionPort: value.consumptionPort,
    extraFittings: value.extraFittings,
    vesselType: value.vesselType,
    port: value.port,
    date: value.date,
    consumptionPortWorking: value.consumptionPortWorking,
    ecoConsumptionBallast: value.ecoConsumptionBallast,
    ecoConsumptionLaden: value.ecoConsumptionLaden,
    ecoSpeedBallast: value.ecoSpeedBallast,
    ecoSpeedLaden: value.ecoSpeedLaden,
    forSale: value.forSale,
    teu: value.teu,
    teu14: value.teu14,
    reefer: value.reefer,
    builder: value.builder,
    design: value.design,
    craneCapacity: value.craneCapacity,
    grabs: value.grabs,
    grabCapacity: value.grabCapacity,
    lastCargoes: value.lastCargoes,
    feu: value.feu,
  };
}
