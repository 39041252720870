import {FC} from 'react';
import {vesselDisplay} from '../../../atoms/DropdownDisplay/vesselDisplay';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {SearchIndicator} from '../../../atoms/Select/Indicators';
import {DropdownSingleValue} from '../../../atoms/Select/otherComponents';
import Select from '../../../atoms/Select/Select';
import {TODO} from '../../../utils/TODO';

export const VesselTabPane: FC<{
  selectedValue: TODO;
  isLoading: boolean;
  searchDataVessel: TODO[];
  onChange: (data: TODO) => void;
  onSearch: (query: string, index: string) => void;
  onBlur: () => void;
}> = ({selectedValue, isLoading, onChange, onSearch, searchDataVessel, onBlur}) => {
  return (
    <LabelWrapper label="Vessel" style={{marginTop: '10px'}}>
      <Select<TODO>
        height="auto"
        id={'create-project-vessel-select'}
        value={selectedValue}
        isLoading={isLoading}
        options={searchDataVessel}
        onBlur={onBlur}
        components={{
          DropdownIndicator: SearchIndicator,
          SingleValue: DropdownSingleValue,
        }}
        onInputChange={(data: $TSFixMe) => onSearch(data, 'vessels_portfolio')}
        onChange={onChange}
        getOptionLabel={option => vesselDisplay(option, option.highlight)}
        getOptionValue={option => option.id}
        placeholder={'Search for vessels in My Fleet'}
        filterOption={() => true} // Disable client side filtering
        isClearable
      />
    </LabelWrapper>
  );
};
