import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {z} from 'zod';
import {getLocalStorage, setLocalStorage} from '../utils/useLocalStorage';
import {ColumnSort} from '@tanstack/react-table';

export const MarketTypeSchema = z.enum(['vessel', 'cargo']);

export type MarketType = z.infer<typeof MarketTypeSchema>;

const VesselTypeSchema = z.union([z.literal('bulker'), z.literal('container'), z.literal('mpp')]);
export type VesselType = z.infer<typeof VesselTypeSchema>;
/**
 * The layout mode that the user chooses.
 * With 'auto', the grid will choose the best layout itself.
 */
const VesselGridLayoutModeSchema = z.union([VesselTypeSchema, z.literal('auto')]);
export type VesselGridLayoutMode = z.infer<typeof VesselGridLayoutModeSchema>;

export interface MarketSubState {
  /**
   * Whether the last time in the market we showed live or archived circulars.
   * Used to detect switches between live and archive.
   */
  latestIsArchive: boolean;
  page: number;
  pageSize: number;
  sortedBy: ColumnSort;
}

interface MarketVesselSubState extends MarketSubState {
  userSelectedLayout: VesselGridLayoutMode;
}

interface MarketState {
  isFilterUiOpen: boolean;
  /**
   * The market type that the market had when the user last visited it.
   */
  marketType: MarketType;
  vessel: MarketVesselSubState;
  cargo: MarketSubState;
}

const COOKIE_MARKET_VESSEL_GRID_PAGE_SIZE = 'market-grid-vessel-page-size';
const COOKIE_MARKET_CARGO_GRID_PAGE_SIZE = 'market-grid-cargo-page-size';

export const pageSizeStorageName = (marketType: MarketType) => {
  return marketType === 'vessel' ? COOKIE_MARKET_VESSEL_GRID_PAGE_SIZE : COOKIE_MARKET_CARGO_GRID_PAGE_SIZE;
};

const DEFAULT_PAGE_SIZE = 10;

const getPageSizeFromCookie = (marketType: MarketType) => {
  return getLocalStorage(pageSizeStorageName(marketType), DEFAULT_PAGE_SIZE);
};

const setPageSizeInCookie = (marketType: MarketType, pageSize: number) => {
  MarketTypeSchema.parse(marketType);
  setLocalStorage(pageSizeStorageName(marketType), pageSize);
};

const getVesselGridLayoutFromCookie = () =>
  getLocalStorage<VesselGridLayoutMode>(`market_vessel_grid_layout`, 'bulker', VesselGridLayoutModeSchema);

const DEFAULT_MARKET_TYPE = 'cargo';

const initialState: MarketState = {
  isFilterUiOpen: true,
  marketType: DEFAULT_MARKET_TYPE,
  vessel: {
    latestIsArchive: false,
    page: 1,
    pageSize: getPageSizeFromCookie('vessel'),
    sortedBy: {id: 'createdAt', desc: true},
    userSelectedLayout: getVesselGridLayoutFromCookie(),
  },
  cargo: {
    latestIsArchive: false,
    page: 1,
    pageSize: getPageSizeFromCookie('cargo'),
    sortedBy: {id: 'createdAt', desc: true},
  },
};

interface SetLatestIsArchivePayload {
  marketType: MarketType;
  latestIsArchive: boolean;
}

interface SetPagePayload {
  marketType: MarketType;
  page: number;
}

interface SetPageSizePayload {
  marketType: MarketType;
  pageSize: number;
}

interface SetSortedByPayload {
  marketType: MarketType;
  sortedBy: ColumnSort;
}

const marketSlice = createSlice({
  name: 'Market',
  initialState,
  reducers: {
    setFilterUiOpen(state, action: PayloadAction<boolean>) {
      state.isFilterUiOpen = action.payload;
    },
    setMarketType(state, action: PayloadAction<MarketType>) {
      state.marketType = action.payload;
    },
    setLatestIsArchive(state, action: PayloadAction<SetLatestIsArchivePayload>) {
      state[action.payload.marketType].latestIsArchive = action.payload.latestIsArchive;
    },
    setPage(state, action: PayloadAction<SetPagePayload>) {
      state[action.payload.marketType].page = action.payload.page;
    },
    setPageSize(state, action: PayloadAction<SetPageSizePayload>) {
      state[action.payload.marketType].pageSize = action.payload.pageSize;
      setPageSizeInCookie(action.payload.marketType, action.payload.pageSize);
    },
    setSortedBy(state, action: PayloadAction<SetSortedByPayload>) {
      state[action.payload.marketType].sortedBy = action.payload.sortedBy;
    },
    setUserSelectedVesselGridLayout(state, action: PayloadAction<VesselGridLayoutMode>) {
      state.vessel.userSelectedLayout = action.payload;
    },
  },
});

export const marketReducer = marketSlice.reducer;

export const MarketActions = marketSlice.actions;
