import {FC, useMemo} from 'react';
import {TermItemHistory} from './TermItemHistory';
import sortBy from 'lodash/sortBy';
import {
  NegotiationParty,
  NegotiationRoundResultModification,
  NegotiationStatus,
  NegotiationTerm,
} from '../../../../../../api/node-backend/generated';
import {combineWithTermModification, getIsTermChanged, getIsTermSettledAndCommitted} from '../../TermArea/utils';
import {TermContent} from '../../TermItem/Components/TermContent/TermContent';
import {TermsContainer} from '../../TermArea/shared';

type HistoryDetailTermListProps = {
  party: NegotiationParty;
  status: NegotiationStatus;
  terms: NegotiationTerm[];
  roundResultModifications?: NegotiationRoundResultModification;
  hideUnchanged?: boolean;
  hideSettled?: boolean;
};

export const HistoryDetailTermList: FC<HistoryDetailTermListProps> = ({
  party,
  status,
  terms,
  roundResultModifications,
  hideUnchanged,
  hideSettled,
}) => {
  const termList = useMemo(() => {
    let termList = terms.map(combineWithTermModification(roundResultModifications));
    if (hideUnchanged) {
      termList = termList.filter(item => !getIsTermChanged(item));
    }
    if (hideSettled) {
      termList = termList.filter(item => !getIsTermSettledAndCommitted(item));
    }
    termList = sortBy(termList, ({term}) => term.sortIndex);
    return termList;
  }, [terms, roundResultModifications, hideUnchanged, hideSettled]);

  return (
    <TermsContainer>
      {termList.length ? (
        termList.map(({term, termModifications}) => (
          <TermItemHistory
            key={term.originId}
            party={party}
            status={status}
            term={term}
            termModifications={termModifications}
          />
        ))
      ) : (
        <TermContent text="No term changes" />
      )}
    </TermsContainer>
  );
};
