import {TODO} from '../../../../utils/TODO';
import Checkbox from '../../../../atoms/Checkbox';
import {SharingIcon} from './SharingIcon';
import {ClipboardItem} from '../../../../redux/GridClipboard';

export const clipBoardColumn = ({
  checkAllVessels,
  addAllItemsToClipboard,
  removeAllItemsFromClipboard,
  addItemToClipboard,
  removeItemFromClipboard,
  clipboardState,
}: {
  checkAllVessels: boolean | undefined;
  clipboardState: ClipboardItem[];
  addAllItemsToClipboard: () => void;
  removeAllItemsFromClipboard: () => void;
  addItemToClipboard: (item: TODO) => void;
  removeItemFromClipboard: (item: TODO) => void;
}) => {
  return {
    header: ({table}: TODO) => (
      <Checkbox
        id={`clipboard-checkbox-all`}
        checked={checkAllVessels}
        onChange={e => {
          table.getToggleAllRowsSelectedHandler();
          e.target.checked ? addAllItemsToClipboard() : removeAllItemsFromClipboard();
        }}
      />
    ),
    id: 'clipBoard',
    enableSorting: false,
    className: 'datagrid-td-clipboard-market',
    headerClassName: 'datagrid-th-clipboard-market',
    cell: (d: $TSFixMe) => {
      return (
        <div>
          <Checkbox
            id={`clipboard-checkbox-${d.row.original.id}`}
            checked={!!clipboardState.find((cbi: $TSFixMe) => cbi.id === d.row.original.id)}
            onChange={e => {
              e.target.checked ? addItemToClipboard(d.row.original) : removeItemFromClipboard(d.row.original);
            }}
          />
          {'shared' in d.row.original && d.row.original.shared && (
            <SharingIcon type="inbox" $activeTab={false} $isActive={true} />
          )}
        </div>
      );
    },
    size: 32,
    width: 32,
  };
};
