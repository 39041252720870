import {Tooltip} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {GhostButton} from '../../../../Components/shared';
import {StatefulIcon} from '../Components/shared';

type TermItemCreateActionsProps = {
  disabled?: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const TermItemCreateActions: FC<TermItemCreateActionsProps> = ({disabled, onSave, onCancel}) => {
  return (
    <TermItemCreateActionsContainer>
      {onSave && (
        <Tooltip title={`Finish editing`}>
          <GhostButton data-testid="SaveButton" onClick={onSave} disabled={disabled}>
            <StatefulIcon type="check-mark-line" $disabled={disabled} />
          </GhostButton>
        </Tooltip>
      )}
      {onCancel && (
        <Tooltip title="Cancel editing">
          <GhostButton data-testid="CancelButton" onClick={onCancel} disabled={disabled}>
            <StatefulIcon type="clear" $disabled={disabled} />
          </GhostButton>
        </Tooltip>
      )}
      <Tooltip title={`Press SHIFT + ENTER for newline, ENTER to save or ESCAPE to cancel`}>
        <GhostButton>
          <StatefulIcon type="help" />
        </GhostButton>
      </Tooltip>
    </TermItemCreateActionsContainer>
  );
};

const TermItemCreateActionsContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 81.5px;
  border-left: var(--border-base);
  line-height: 1;

  > * {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    text-align: center;

    .icon {
      position: relative;
      top: 1px;
      font-size: 20px;
    }
  }
`;
