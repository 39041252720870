/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {AccountListItem, AccountListItemFromJSON, AccountListItemFromJSONTyped, AccountListItemToJSON} from './';

/**
 *
 * @export
 * @interface AccountList
 */
export interface AccountList {
  /**
   *
   * @type {number}
   * @memberof AccountList
   */
  totalItems: number;
  /**
   *
   * @type {Array<AccountListItem>}
   * @memberof AccountList
   */
  items: Array<AccountListItem>;
}

export function AccountListFromJSON(json: any): AccountList {
  return AccountListFromJSONTyped(json, false);
}

export function AccountListFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalItems: json['totalItems'],
    items: (json['items'] as Array<any>).map(AccountListItemFromJSON),
  };
}

export function AccountListToJSON(value?: AccountList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalItems: value.totalItems,
    items: (value.items as Array<any>).map(AccountListItemToJSON),
  };
}
