import React, {ReactNode, useState} from 'react';
import BaseModal, {BaseModalChildrenProps} from '../../../atoms/BaseModal';
import './delete.scss';
import {Button} from 'antd';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';

export type DeleteModalProps = {
  fullTitle?: string;
  title?: string;
  target: {name: ReactNode; id: number};
  buttonLabel?: string;
  onDelete: (id: number, setErrors: (errors: unknown) => void, close: () => void) => void;
};

export const Delete = ({
  onDelete,
  target: {name, id},
  fullTitle = undefined,
  title = 'Are you sure you want to delete ',
  buttonLabel = 'Yes, Delete!',
}: DeleteModalProps) => {
  const [errors, setErrors] = useState<unknown>([]);
  const modalTitle = fullTitle ?? `${title} "${name ?? ''}"?`;
  return (
    <BaseModal middle narrow subTitle="Warning: This cannot be undone." title={modalTitle}>
      {({close}: BaseModalChildrenProps) => (
        <>
          <div className="decline-request">
            {(errors as $TSFixMe).bonusMsg && <p className={'errorList__info'}>{(errors as $TSFixMe).bonusMsg}</p>}
            {(errors as $TSFixMe).errors &&
              (errors as $TSFixMe).errors.map((err: $TSFixMe) => (
                <div key={err.title + err.msg} className={`errorList__div ${!err.title && 'errorList__div--no-title'}`}>
                  {err.title && <span className={'errorList__type'}>{err.title}</span>}
                  {err.link ? (
                    <a className={'errorList__link'} href={err.link} rel="noopener noreferrer">
                      {err.msg}
                    </a>
                  ) : (
                    <span className={'errorList__link'}>{err.msg}</span>
                  )}
                </div>
              ))}
          </div>

          <ModalFooter>
            <Button
              type="primary"
              danger
              disabled={
                errors &&
                (((errors as $TSFixMe).errors && (errors as $TSFixMe).errors.length > 0) ||
                  (errors as $TSFixMe).bonusMsg)
              }
              id={'decline-button'}
              onClick={() => onDelete(id, setErrors, close)}>
              {buttonLabel}
            </Button>
          </ModalFooter>
        </>
      )}
    </BaseModal>
  );
};
