/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyStatisticsMarket,
  CompanyStatisticsMarketFromJSON,
  CompanyStatisticsMarketFromJSONTyped,
  CompanyStatisticsMarketToJSON,
  CompanyStatisticsPortfolio,
  CompanyStatisticsPortfolioFromJSON,
  CompanyStatisticsPortfolioFromJSONTyped,
  CompanyStatisticsPortfolioToJSON,
  CompanyStatisticsProjects,
  CompanyStatisticsProjectsFromJSON,
  CompanyStatisticsProjectsFromJSONTyped,
  CompanyStatisticsProjectsToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyStatistics
 */
export interface CompanyStatistics {
  /**
   *
   * @type {CompanyStatisticsMarket}
   * @memberof CompanyStatistics
   */
  market: CompanyStatisticsMarket;
  /**
   *
   * @type {CompanyStatisticsPortfolio}
   * @memberof CompanyStatistics
   */
  portfolio: CompanyStatisticsPortfolio;
  /**
   *
   * @type {CompanyStatisticsProjects}
   * @memberof CompanyStatistics
   */
  projects: CompanyStatisticsProjects;
}

export function CompanyStatisticsFromJSON(json: any): CompanyStatistics {
  return CompanyStatisticsFromJSONTyped(json, false);
}

export function CompanyStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyStatistics {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    market: CompanyStatisticsMarketFromJSON(json['market']),
    portfolio: CompanyStatisticsPortfolioFromJSON(json['portfolio']),
    projects: CompanyStatisticsProjectsFromJSON(json['projects']),
  };
}

export function CompanyStatisticsToJSON(value?: CompanyStatistics | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    market: CompanyStatisticsMarketToJSON(value.market),
    portfolio: CompanyStatisticsPortfolioToJSON(value.portfolio),
    projects: CompanyStatisticsProjectsToJSON(value.projects),
  };
}
