import Icon, {IconType} from '../../atoms/Icon';
import classNames from 'classnames';
import {FC, ReactNode} from 'react';
import styled from 'styled-components';

const getType = (indexName: string): [IconType, string] => {
  switch (indexName) {
    case 'ports':
    case 'location_port':
      return ['port', 'Ports'];
    case 'vessels_market':
      return ['markets', 'Market vessels'];
    case 'cargoes_market':
      return ['markets', 'Market cargoes'];
    case 'vessels_portfolio':
      return ['portfolio', 'My Fleet'];
    case 'cargoes_portfolio':
      return ['portfolio', 'My Cargoes'];
    case 'projects':
      return ['work-tray', 'Projects'];
    case 'contacts':
      return ['person', 'Contacts'];
    case 'users':
      return ['person', 'Seabo users'];
    case 'negotiations':
      return ['negotiations', 'Negotiations'];
    case 'ais_vessels':
      return ['vessel', 'AIS vessels'];
    case 'exact-match':
      return ['location', 'Exact match'];
    default:
      return ['help', ''];
  }
};

export const AutoCompleteHeader: FC<{children?: ReactNode; className?: string}> = ({children, className}) => {
  const [iconType, title] = getType((children as string) ?? '');
  if (children === 'overviewPage') {
    return null;
  }
  return (
    <AutoCompleteHeaderStyled className={classNames(className, 'autocomplete-header')}>
      <span className={'autocomplete-header__icon'}>
        <Icon type={iconType} />
      </span>
      <span className={'autocomplete-header__text'}>{title}</span>
    </AutoCompleteHeaderStyled>
  );
};
const AutoCompleteHeaderStyled = styled.div`
  color: var(--color-gray-2);
  padding: 5px 0px;
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 5px;
  margin-right: 25px;
  margin-left: 25px;
`;

export default AutoCompleteHeader;
