import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {assertUnreachable} from '../../utils/assert';
import {ApiError} from '../../api/utils/ApiError';
import {projectApi} from '../../api/symfony/symfonyApi';

type AddItemToProjectProps =
  | {
      projectType: 'vessel';
      projectId: number;
      cargoIds: number[];
    }
  | {
      projectType: 'cargo';
      projectId: number;
      vesselIds: number[];
    };

export const useAddItemToProjectMutation = (options: UseMutationOptions<void, ApiError, AddItemToProjectProps>) => {
  const addItemToProject = async (props: AddItemToProjectProps) => {
    const types = props.projectType;
    switch (types) {
      case 'cargo':
        await projectApi.addVesselsToProjectViaOffers({id: props.projectId, requestBody: props.vesselIds});
        break;
      case 'vessel':
        await projectApi.addCargoesToProjectViaOffers({id: props.projectId, requestBody: props.cargoIds});

        break;
      default:
        assertUnreachable(types);
    }
    return;
  };

  return useMutation({
    mutationFn: addItemToProject,
    ...options,
  });
};
