import {CSSProperties, FC} from 'react';
import classNames from 'classnames';

import './style.scss';

type AvatarSize = 'small' | 'medium' | 'large' | 'extra-large' | 'huge';

interface User {
  imageUrl?: string;
  fullName?: string;
}

interface Props {
  user?: User;
  imageUrl?: string;
  title?: string;
  size?: AvatarSize;
  onlineStatus?: boolean;
  styleOverride?: CSSProperties;
}

const Avatar: FC<Props> = ({user, imageUrl, title, size, onlineStatus, styleOverride}) => {
  imageUrl = user ? user.imageUrl : imageUrl;
  title = user ? user.fullName : title;

  return (
    <div
      className={classNames('avatar', {[`avatar--${size}`]: size, [`avatar--online`]: onlineStatus})}
      style={styleOverride}>
      {imageUrl && (
        <img
          alt={title}
          title={title}
          src={imageUrl}
          className="avatar__image"
          onError={e => {
            const imgElement = e.target as HTMLImageElement;
            imgElement.setAttribute('src', '/static/images/avatars/avatar-default.jpg');
          }}
        />
      )}
    </div>
  );
};

export default Avatar;
