import {App} from 'antd';
import {MessageInstance} from 'antd/es/message/interface';
import {ModalStaticFunctions} from 'antd/es/modal/confirm';
import {NotificationInstance} from 'antd/es/notification/interface';
import {FC} from 'react';

let message: MessageInstance;
let notification: NotificationInstance;
let modal: Omit<ModalStaticFunctions, 'warn'>;

const NotificationsSetup: FC = () => {
  const staticFunction = App.useApp();
  message = staticFunction.message;
  modal = staticFunction.modal;
  notification = staticFunction.notification;
  return null;
};

export {message, notification, modal, NotificationsSetup};
