import {
  NegotiationActor,
  NegotiationActorType,
  NegotiationCargo,
  NegotiationParty,
  NegotiationStatus,
  NegotiationSummary,
  NegotiationVessel,
  VesselTypeEnum,
} from '../../../api/node-backend/generated';
import dayjs from 'dayjs';

export const mockNegotiationCreator: NegotiationActor = {
  id: 'bf44b802-a9f6-4429-b783-12c033c1bd9c',
  name: 'Example Creator',
  email: 'creator@email.com',
  type: NegotiationActorType.User,
  companyName: 'Creator Company',
};

export const mockNegotiationCounterpart: NegotiationActor = {
  id: '25e83199-d888-4588-8eb6-e85417358ef9',
  name: 'Example Counterpart',
  email: 'counterpart@email.com',
  type: NegotiationActorType.Guest,
  companyName: 'Counterpart Company',
};

export const mockNegotiationCargo: NegotiationCargo = {
  id: 0,
  commodityCategory: 'Test',
  commodityType: 'Example Cargo',
  contractType: 'vc',
  quantityMin: 60000,
  quantityMax: 70000,
  quantityUnit: 'DWT',
  laycanFrom: '2023-04-20',
  laycanTo: '2023-04-20',
  loadingLabel: 'Barcelona',
  loadingCountryCode: 'ES',
  dischargeLabel: 'London',
  dischargeCountryCode: 'GB',
};

export const mockNegotiationVessel: NegotiationVessel = {
  id: 0,
  name: 'Example Vessel',
  type: VesselTypeEnum.Bulker,
  imo: 123456789,
  size: 100000,
  sizeUnit: 'DWT',
  dateOpen: '2023-04-20',
  locationOpenLabel: 'Barcelona',
  locationOpenCountryCode: 'ES',
};

export const mockNegotiationSummary: NegotiationSummary = {
  id: 'dfb9129b-dbbd-4fcd-b520-da3feadca85b',
  title: 'test',
  status: NegotiationStatus.Ongoing,
  party: NegotiationParty.Creator,
  createdAt: dayjs('2021-09-01T12:00:00.000Z').toDate(),
  privateNote: 'test',
  creator: mockNegotiationCreator,
  counterpart: mockNegotiationCounterpart,
  cargo: mockNegotiationCargo,
  cancellationReason: null,
  vessel: mockNegotiationVessel,
  latestRound: {
    id: 'round2',
    committedAt: null,
    party: NegotiationParty.Creator,
    ongoingTerms: 2,
    termsWithRequestedRejection: 0,
    subjectsWithRequestedRejection: 0,
    acceptedSubjects: 1,
    failedSubjects: 1,
    acceptedTerms: 1,
    rejectedTerms: 1,
    liftedSubjects: 1,
    rejectedSubjects: 1,
    ongoingSubjects: 1,
    replyTime: null,
    negotiationStatus: NegotiationStatus.Ongoing,
  },
  /** TODO: canceledBy should accept null */
  canceledBy: NegotiationParty.Creator,
  canceledAt: null,
  roundCount: 3,
};
