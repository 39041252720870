/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface MailAsJsonApiModel
 */
export interface MailAsJsonApiModel {
  /**
   * Date when the mail was sent.
   * @type {Date}
   * @memberof MailAsJsonApiModel
   */
  date: Date;
  /**
   * Mail address of the sender.
   * @type {string}
   * @memberof MailAsJsonApiModel
   */
  sender: string;
  /**
   * Recipients of the mail. The list includes 'To' and 'Cc' recipients.
   * @type {Array<string>}
   * @memberof MailAsJsonApiModel
   */
  recipients: Array<string>;
  /**
   * The subject of the mail. Never null, but may be empty.
   * @type {string}
   * @memberof MailAsJsonApiModel
   */
  subject: string;
  /**
   * The text that has been extracted from the mail. This is *not* necessarily the original text.
   * @type {string}
   * @memberof MailAsJsonApiModel
   */
  extractedText: string | null;
}

export function MailAsJsonApiModelFromJSON(json: any): MailAsJsonApiModel {
  return MailAsJsonApiModelFromJSONTyped(json, false);
}

export function MailAsJsonApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailAsJsonApiModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: new Date(json['date']),
    sender: json['sender'],
    recipients: json['recipients'],
    subject: json['subject'],
    extractedText: json['extractedText'],
  };
}

export function MailAsJsonApiModelToJSON(value?: MailAsJsonApiModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date.toISOString(),
    sender: value.sender,
    recipients: value.recipients,
    subject: value.subject,
    extractedText: value.extractedText,
  };
}
