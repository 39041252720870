import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {FilterCategory, FilterItem, FilterType} from '../../api/symfony/generated';
import {ApiError} from '../../api/utils/ApiError';
import {filterApi} from '../../api/symfony/symfonyApi';
import {assertAndWarn} from '../../utils/assert';
import {UseQueryOptions} from '../../api/utils/ReactQuery';

interface LoadOrCreateFilterRequest {
  category: FilterCategory;
  type: FilterType;
}

/**
 * A hook that loads the first filter with the given properties.
 * If it finds none, it creates a new one.
 *w
 */
export const useLoadOrCreateFilterQuery = (
  request: LoadOrCreateFilterRequest,
  options?: UseQueryOptions<FilterItem, ApiError>
): UseQueryResult<FilterItem, ApiError> =>
  useQuery({
    queryKey: ['filter', request],
    queryFn: () => loadOrCreateFilter(request),
    ...options,
  });

const loadOrCreateFilter = async (request: LoadOrCreateFilterRequest): Promise<FilterItem> => {
  const {category, type} = request;
  const response = await filterApi.getFilters(request);
  const filters = response.items;
  assertAndWarn(filters.length <= 1, `Found too many filters - ${filters.length}`);

  // We found an existing filter.
  if (filters.length > 0) {
    return filters[0];
  }

  // There is no filter yet. Make a new one.
  const newFilter = await filterApi.createFilter({
    filterItemWrite: {
      category,
      type,
      filterSettings: {},
    },
  });
  return newFilter;
};
