import {Button, Form, Input} from 'antd';
import React, {FC, ReactElement} from 'react';
import {Modal} from '../../../antd/components/Modal';
import {ModalFooter} from '../../../antd/components/ModalFooter';
import styled from 'styled-components';

interface Props {
  visible: boolean;
  title: string;
  description: ReactElement;
  namePlaceholder: string;
  onSave: (name: string) => void;
  onClose: () => void;
}

interface FormValues {
  name: string;
}

export const CreateFilterModal: FC<Props> = ({visible, title, description, namePlaceholder, onSave, onClose}) => {
  return (
    <Modal title={title} open={visible} destroyOnClose onCancel={onClose} footer={null}>
      <Description>{description}</Description>
      <Form<FormValues>
        name="CreateFilter"
        layout="vertical"
        initialValues={{name: ''}}
        onFinish={values => {
          onSave(values.name);
        }}
        autoComplete="off">
        <Form.Item label="Name" name="name" rules={[{required: true, message: 'Name is required'}]}>
          <Input placeholder={namePlaceholder} />
        </Form.Item>
        <ModalFooter>
          <Button key="1" type="primary" className="uppercase" htmlType="submit">
            Save filters
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const Description = styled.p`
  text-align: center;
`;
