/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ExtraFittings
 */
export interface ExtraFittings {
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  logFitted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  lakesFitted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  tweenDecks?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  a60Bulkhead?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  co2Fitted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  boxShape?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  openHatch?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  itfFitted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  geared?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  bowThruster?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  sternThruster?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  celled?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  grabFitted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  scrubberFitted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ExtraFittings
   */
  gearless?: boolean;
}

export function ExtraFittingsFromJSON(json: any): ExtraFittings {
  return ExtraFittingsFromJSONTyped(json, false);
}

export function ExtraFittingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtraFittings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    logFitted: !exists(json, 'logFitted') ? undefined : json['logFitted'],
    lakesFitted: !exists(json, 'lakesFitted') ? undefined : json['lakesFitted'],
    tweenDecks: !exists(json, 'tweenDecks') ? undefined : json['tweenDecks'],
    a60Bulkhead: !exists(json, 'a60Bulkhead') ? undefined : json['a60Bulkhead'],
    co2Fitted: !exists(json, 'co2Fitted') ? undefined : json['co2Fitted'],
    boxShape: !exists(json, 'boxShape') ? undefined : json['boxShape'],
    openHatch: !exists(json, 'openHatch') ? undefined : json['openHatch'],
    itfFitted: !exists(json, 'itfFitted') ? undefined : json['itfFitted'],
    geared: !exists(json, 'geared') ? undefined : json['geared'],
    bowThruster: !exists(json, 'bowThruster') ? undefined : json['bowThruster'],
    sternThruster: !exists(json, 'sternThruster') ? undefined : json['sternThruster'],
    celled: !exists(json, 'celled') ? undefined : json['celled'],
    grabFitted: !exists(json, 'grabFitted') ? undefined : json['grabFitted'],
    scrubberFitted: !exists(json, 'scrubberFitted') ? undefined : json['scrubberFitted'],
    gearless: !exists(json, 'gearless') ? undefined : json['gearless'],
  };
}

export function ExtraFittingsToJSON(value?: ExtraFittings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    logFitted: value.logFitted,
    lakesFitted: value.lakesFitted,
    tweenDecks: value.tweenDecks,
    a60Bulkhead: value.a60Bulkhead,
    co2Fitted: value.co2Fitted,
    boxShape: value.boxShape,
    openHatch: value.openHatch,
    itfFitted: value.itfFitted,
    geared: value.geared,
    bowThruster: value.bowThruster,
    sternThruster: value.sternThruster,
    celled: value.celled,
    grabFitted: value.grabFitted,
    scrubberFitted: value.scrubberFitted,
    gearless: value.gearless,
  };
}
