import LabelWrapper from '../../atoms/LabelWrapper';
import {CSSProperties} from 'react';
import {greaterThan, greaterThanOrEqualTo} from '../../utils/validators';
import {MarginMappingProps} from '../withMargin';
import {parseIntTS} from '../../utils/parseNumberTS';
import {ValidationResult} from '../../utils/Validator';
import {Input} from 'antd';
import omit from 'lodash/omit';
import {InputProps} from '../../atoms/Input';

interface Props extends MarginMappingProps {
  id?: string;
  label?: string;
  fromValue: number | string | undefined;
  fromProps: Omit<InputProps, 'onChange' | 'value' | 'disabled' | 'maxLength'>;
  fromError?: ValidationResult;
  toValue: number | string | undefined;
  toProps: Omit<InputProps, 'onChange' | 'value' | 'disabled' | 'maxLength'>;
  toError?: ValidationResult;
  onFromChange: (newValue: number | '') => void;
  onToChange: (newValue: number | '') => void;
  allowEqualMinMax?: boolean;
  flex?: boolean;
  inputSubId?: string;
  style?: CSSProperties;
}

/** @deprecated use AntD Input instead */
const NumberRangeInput = (props: Props) => {
  const {
    id,
    label,
    fromValue,
    fromProps,
    toProps,
    toValue,
    fromError,
    toError,
    onFromChange,
    onToChange,
    allowEqualMinMax,
    inputSubId,
    ...otherProps
  } = props;

  let hasError: ValidationResult;
  if (fromError || toError) {
    // First we display the errors of individuals fields.
    hasError = false;
  } else if (allowEqualMinMax) {
    hasError = greaterThanOrEqualTo(
      parseIntTS(fromValue),
      `Max value must be greater than or equal to min value ${fromValue}`
    )(parseIntTS(toValue));
  } else {
    hasError = greaterThan(
      parseIntTS(fromValue),
      `Max value must be greater than min value ${fromValue}`
    )(parseIntTS(toValue));
  }

  const fromPropsHTMLClean = omit(fromProps, 'hasError');
  const toPropsHTMLClean = omit(toProps, 'hasError');

  return (
    <LabelWrapper flex label={label} id={id} hasError={!!hasError} {...otherProps}>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8}}>
        <div>
          <Input
            id={`filter-size-${inputSubId ?? label}-from`}
            style={{width: '100%'}}
            onChange={event => {
              const value = removeCommaAndDot(event.target.value);
              onFromChange(isNaN(parseIntTS(value)) ? '' : parseIntTS(value));
            }}
            value={fromValue?.toString()}
            type={'number'}
            {...fromPropsHTMLClean}
            status={!!hasError || fromProps.hasError ? 'error' : undefined}
          />
          {!!fromError && <div className={'input-info input-info--hasError'}>{fromError}</div>}
        </div>
        <div>
          <Input
            id={`filter-size-${inputSubId ?? label}-to`}
            style={{width: '100%'}}
            onChange={event => {
              const value = removeCommaAndDot(event.target.value);
              onToChange(isNaN(parseInt(value)) ? '' : parseInt(value));
            }}
            value={toValue?.toString()}
            type={'number'}
            {...toPropsHTMLClean}
            status={!!hasError || toProps.hasError ? 'error' : undefined}
          />
          {!!toError && <div className={'input-info input-info--hasError'}>{toError}</div>}
        </div>
      </div>
      {!!hasError && <div className={'input-info input-info--hasError'}> {hasError}</div>}
    </LabelWrapper>
  );
};

const removeCommaAndDot = (value: string) => value.replace(',', '').replace('.', '');

/** @deprecated use AntD Input instead */
export default NumberRangeInput;
