import {FeaturePermission} from '../../api/symfony/generated';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {useIsPermitted} from '../../utils/useIsPermitted';
import CompanyActions, {
  memberSelector,
  allWorkspaceSelector,
} from '../../redux/ApiService/CompanyService/CompanyService';
import {useEffect, useMemo, useState} from 'react';
import {useAccountSegmentsQuery} from '../../queries/useAccountSegmentsQuery';
import {useQueryClient} from '@tanstack/react-query';
import {OnboardingStep, getLastNotCompletedOnboardingStep} from './getLastNotCompletedStep';
import {useUser} from '../../components/UserContext/useUser';
import {getCompany, getHasProPlan} from '../../redux/selectors';
import {invalidateCurrentUserQuery} from '../../queries/useCurrentUserQuery';
import {
  useSetAccountIsOnboardedMutation,
  useSetAccountSkippedOnboardingMutation,
} from './useUpdateAccountOnboardingStateMutation';

export type OnboardingStepProps = {
  isLoading?: boolean;
  previousEnabled?: boolean;
  nextEnabled?: boolean;
  hasNext?: boolean;
  onSkip?: () => void;
  onFinish?: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
};

export const useUserOnboarding = (): [OnboardingStep | null, OnboardingStepProps] => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isReduxLoading, setIsReduxLoading] = useState(true);

  const user = useUser();
  const isCompanyAdmin = useIsPermitted(FeaturePermission.ManageOwnCompany);
  const {isOnboarded, onboardingSkippedAt} = user;
  const isUserOnboarded = !!isOnboarded;
  const userSkippedOnboarding = !!onboardingSkippedAt;
  const accountSegmentsQuery = useAccountSegmentsQuery();
  const hasSelfCategorization = !!accountSegmentsQuery.data?.userSelfCategorization;

  const {data: company} = useSelector(getCompany);
  const {isOnboarded: isCompanyOnboarded, isVerified} = company;
  const hasMembers = useSelector(memberSelector).length > 1;
  const hasWorkspaces = useSelector(allWorkspaceSelector).length > 1;
  const hasProPlan = useSelector(getHasProPlan);

  const getMembers = () => dispatch(CompanyActions.getMembers(company.id));
  const getWorkspaces = () => dispatch(CompanyActions.getWorkspaceList(company.id));

  const isLoading = isReduxLoading || !accountSegmentsQuery.isSuccess;

  const setAccountIsOnboardedMutation = useSetAccountIsOnboardedMutation({
    onSuccess: () => {
      void dispatch(CompanyActions.getCompany(company.id));
      void invalidateCurrentUserQuery(queryClient);
    },
  });

  const setAccountOnboardingSkippedMutation = useSetAccountSkippedOnboardingMutation({
    onSuccess: () => {
      void dispatch(CompanyActions.getCompany(company.id));
      void invalidateCurrentUserQuery(queryClient);
    },
  });

  // We check for different onboarding steps based on the user's role and state and the company's state
  // This is used only as the starting point for the onboarding process and will then be handled by the currentStep state
  // If the user has finished or skipped onboarding it returns null and the onboarding will not be shown
  const lastNotCompletedStep = useMemo(
    () =>
      getLastNotCompletedOnboardingStep({
        userSkippedOnboarding,
        isCompanyAdmin,
        isCompanyOnboarded,
        isVerified,
        hasProPlan,
        hasMembers,
        hasWorkspaces,
        isUserOnboarded,
        hasSelfCategorization,
      }),
    [
      userSkippedOnboarding,
      isCompanyAdmin,
      isCompanyOnboarded,
      isVerified,
      hasProPlan,
      hasMembers,
      hasWorkspaces,
      isUserOnboarded,
      hasSelfCategorization,
    ]
  );

  const [currentStep, setCurrentStep] = useState<OnboardingStep | null>(lastNotCompletedStep);

  // Fetch company, members and workspaces
  useEffect(() => {
    if (company.id === undefined) {
      return;
    }
    if (!isCompanyAdmin || isCompanyOnboarded) {
      setIsReduxLoading(false);
      return;
    }
    const fetchData = async () => {
      await Promise.all([getMembers(), getWorkspaces()]);
      setIsReduxLoading(false);
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id]);

  // Reset onboarding step when loading is done
  useEffect(() => {
    if (!isLoading) {
      setCurrentStep(lastNotCompletedStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // Since seabo admins can setup a company manually, we need to sync the onboarding state
  useEffect(() => {
    if (!isCompanyAdmin || company.isOnboarded) {
      return;
    }
    // If lastNotCompletedStep is null or higher than OnboardingStep.UpgradePlan, we mark the company as onboarded
    if (!lastNotCompletedStep || lastNotCompletedStep > OnboardingStep.UpgradePlan) {
      setAccountIsOnboardedMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNotCompletedStep]);

  const isFinished = currentStep === null;

  // Early return if onboarding is finished to avoid unnecessary complexity
  if (isFinished) {
    return [currentStep, {}];
  }

  const previousEnabled = currentStep > 0;
  const hasNext = currentStep < OnboardingStep.Finished;
  const nextEnabled = !isFinished;

  const onSkip = () => {
    setAccountOnboardingSkippedMutation.mutate();
    // Reload the page to make sure the changes are applied.
    window.location.reload();
  };

  const onFinish = () => {
    setAccountIsOnboardedMutation.mutate();
    // Reload the page to make sure the changes are applied.
    window.location.reload();
  };

  const onPrevious = () => {
    if (currentStep === OnboardingStep.SetupSegments && !isCompanyAdmin) {
      return;
    }
    window.scrollTo(0, 0);
    setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    if (!hasNext) {
      setCurrentStep(null);
      onSkip();
      return;
    }
    window.scrollTo(0, 0);
    setCurrentStep(currentStep + 1);
  };

  return [currentStep, {isLoading, previousEnabled, nextEnabled, hasNext, onSkip, onFinish, onPrevious, onNext}];
};
