import dayjs, {Dayjs} from 'dayjs';
import sumBy from 'lodash/sumBy';
import {Step8Vessel} from '../steps/step8';

export const addSeaVoyageArrivalDate = ({
  vessels,
  currentTime,
}: {
  vessels: Step8Vessel[];
  currentTime?: Dayjs;
}): Step8Vessel[] => {
  return vessels.map(vessel => {
    const now = currentTime ? currentTime.clone() : dayjs();
    const startDate = now.isBefore(vessel.eta) ? dayjs(vessel.eta) : now;

    const estimatedArrivalDate = startDate
      .add(sumBy(vessel.distanceRoutes, 'duration'), 'millisecond')
      .add(vessel.estimatedDurationInDestinationPortInDays, 'day')
      .toISOString();

    return {...vessel, estimatedArrivalDate};
  });
};
