import {Assumptions} from '../types';
import {useNavigate, useLocation} from 'react-router-dom';
import {updateUrlSearchParamsWithAssumptions} from './updateUrlSearchParamsWithAssumptions';

type SetAssumptions = (assumptions: Assumptions) => void;

export const useSetAssumptions = (): SetAssumptions => {
  const location = useLocation();
  const navigate = useNavigate();

  return (assumptions: Assumptions) => {
    const urlParams = new URLSearchParams(location.search);
    updateUrlSearchParamsWithAssumptions(urlParams, assumptions);
    location.search = urlParams.toString();
    navigate(location.pathname + '?' + urlParams.toString());
  };
};
