import React, {Component} from 'react';
import './style.scss';
import Dropzone from 'react-dropzone';
import {Button} from '../../atoms/Button/Button';
import styled from 'styled-components';
import {TODO} from '../../utils/TODO';
import {acceptImages} from '../Upload';

type Props = {
  headline?: string;
  subline?: string;
  width?: number;
  height?: number;
  callback?: (binaryFile: TODO) => void;
  maxSize?: number;
};
type State = $TSFixMe;
class ImageUpload extends Component<Props, State> {
  state = {
    files: [],
    binaryFile: '',
    imageTooBig: false,
    imageError: false,
    disableButton: false,
  };
  onDrop = (files: $TSFixMe, rejected: $TSFixMe) => {
    if (rejected.length > 0) {
      this.setState({imageTooBig: true});
    } else {
      this.setState({imageTooBig: false});
    }
    this.setState({
      files: files.map((file: $TSFixMe) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    files.forEach((file: $TSFixMe) => {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({binaryFile: file});
        const image = new Image();
        image.onload = () => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            me.imageHasErrors(image);
          };
        };
        image.src = file.preview;
      };
      reader.readAsBinaryString(file);
    });
  };
  imageHasErrors(image: $TSFixMe) {
    const {width, height} = this.props;
    if (image.width < (width ?? 0) || image.height < (height ?? 0)) {
      this.setState({imageError: true});
    } else {
      this.setState({imageError: false});
    }
  }
  submit() {
    this.setState({disableButton: true});
    this.props.callback?.(this.state.binaryFile);
  }
  render() {
    const {imageError, files, disableButton, imageTooBig} = this.state;
    const maxSizeInMb = 5;
    const {headline, subline, width, height, maxSize = maxSizeInMb * 1000 * 1000} = this.props;
    const dropzoneRef = React.createRef();
    return (
      <div className={'upload-image'}>
        <div className={'header'}>
          <div className={'header__headline'}>{headline}</div>
          <div className={'header__subline'}>{subline}</div>
        </div>

        <div className={'upload-image-dropzone'}>
          <Dropzone
            /* @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message */
            ref={dropzoneRef}
            onDrop={(files, rejected) => {
              this.onDrop(files, rejected);
            }}
            maxSize={maxSize}
            disableClick
            accept={acceptImages}
            multiple={false}>
            {({getRootProps, getInputProps, open: _}) => (
              <div {...getRootProps()} className={'upload-image-dropzone--dragzone'}>
                <input {...getInputProps()} />
                <img
                  /* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | any[]' is not assignable to type 's... Remove this comment to see the full error message */
                  src={
                    files.length > 0
                      ? files.map(f => (f as $TSFixMe).preview)
                      : '/static/images/seanexxt/company-placeholder.png'
                  }
                  className={
                    imageError
                      ? 'upload-image-dropzone--errorImage upload-image-dropzone--image'
                      : 'upload-image-dropzone--image'
                  }
                  alt={''}
                />
                <div className={'upload-image-dropzone--info'}>Drop image here or click to upload or</div>
                <Button upper secondary type="button" onClick={() => {}} label={'Select image'} />
              </div>
            )}
          </Dropzone>

          <div
            className={
              imageError || imageTooBig
                ? 'upload-image-dropzone--error upload-image-dropzone--note'
                : 'upload-image-dropzone--note'
            }>
            {imageError && <div className={'upload-image-dropzone--error'}>The image dimensions are wrong.</div>}
            {imageTooBig && <div className={'upload-image-dropzone--error'}>The image file is too big.</div>}
            The image file should be JPG, JPEG or PNG, maximum size of {maxSizeInMb}mb and minimum dimensions of {width}
            x{height} pixels.
          </div>
        </div>

        <StyledButtonContainer>
          <Button disabled={disableButton} primary bold upper label={'save'} onClick={() => this.submit()} />
        </StyledButtonContainer>
      </div>
    );
  }
}
export default ImageUpload;

const StyledButtonContainer = styled.div`
  margin-top: 40px;
`;
