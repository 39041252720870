import {FC} from 'react';
import {NegotiationScreenContainer} from '../Components/shared';
import {NegotiationOverviewList} from './NegotiationOverviewList';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {Space} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';

export const NegotiationOverviewScreen: FC = () => {
  return (
    <NegotiationScreenContainer data-testid="NegotiationOverview">
      <ScreenHeader
        helmetTitle="Negotiations"
        breadcrumbs={[{title: 'Negotiations'}]}
        actions={
          <Space size={4}>
            <ScreenHeader.Actions.Link to="/negotiations/templates" data-testid="NegotiationTemplatesLink">
              Templates
            </ScreenHeader.Actions.Link>
            <ScreenHeader.Actions.Link to="/negotiations/create" data-testid="NegotiationCreateLink">
              New Negotiation
            </ScreenHeader.Actions.Link>
            <ScreenHeader.Actions.Link to="/negotiations/tutorial" data-testid="NegotiationTutorialLink">
              <QuestionCircleOutlined />
            </ScreenHeader.Actions.Link>
          </Space>
        }
        features={<></>}
      />
      <NegotiationOverviewList />
    </NegotiationScreenContainer>
  );
};
