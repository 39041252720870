import Supercluster from 'supercluster';
import {MapPortWithMarketCirculars} from '../useExtendPortsAndTradingAreasWithCirculars';

export const getOnlyClusterChildren = ({clusterId, cluster}: {clusterId: number; cluster: Supercluster}) => {
  const children = clusterId ? cluster.getChildren(clusterId) : [];
  return children.filter(child => child.type === 'Feature');
};

export const hasClusterChildren = ({clusterId, cluster}: {clusterId: number; cluster: Supercluster}) => {
  const children = clusterId ? cluster.getChildren(clusterId) : [];
  return !!children.find(child => child.type === 'Feature');
};

export const filterNonClusterChildren = ({clusterId, cluster}: {clusterId: number; cluster: Supercluster}) => {
  const children = clusterId ? cluster.getChildren(clusterId) : [];
  return children.filter(child => child.type !== 'Feature');
};

export const getClusterPortNames = ({clusterId, cluster}: {clusterId: number; cluster: Supercluster}) => {
  const children: Supercluster.PointFeature<MapPortWithMarketCirculars>[] = getNestedClusterChildren({
    clusterId,
    cluster,
  });
  return getPortNames(children);
};

export const getPortNames = (children: Supercluster.PointFeature<MapPortWithMarketCirculars>[]) => {
  const names = children?.map(child => {
    return child.properties.name;
  });
  return names.join(', ');
};

const getNestedClusterChildren = ({
  clusterId,
  cluster,
}: {
  clusterId: number;
  cluster: Supercluster;
}): Supercluster.PointFeature<MapPortWithMarketCirculars>[] => {
  return cluster.getLeaves(clusterId, Infinity) as Supercluster.PointFeature<MapPortWithMarketCirculars>[];
};

export const getPortClusterItemAmount = ({clusterId, cluster}: {clusterId: number; cluster: Supercluster}) => {
  const filteredChildren = getNestedClusterChildren({clusterId, cluster});

  let amountCargoes = 0;
  let amountVessels = 0;

  filteredChildren.forEach(child => {
    amountCargoes += child.properties.circulars.amountCargoes || 0;
    amountVessels += child.properties.circulars.amountVessels || 0;
  });

  return {
    amountCargoes,
    amountVessels,
  };
};
