import Checkbox from '../../../../atoms/Checkbox';
import {cleanIdentifier} from '../../../../utils/helper';
import {filterBranchDefinition} from './CargoTypeDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const CargoType = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values}) => (
      <div className={'container'}>
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('drybulk', 'filter-option')}
              checked={values.drybulk}
              label={'Dry bulk'}
              onChange={e => handleChange('drybulk', e.target.checked)}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('container', 'filter-option')}
              checked={values.container}
              label={'Container'}
              onChange={e => handleChange('container', e.target.checked)}
              spMT={'s'}
            />
          </div>
        </div>
      </div>
    )}
  </FilterButton>
);

export default CargoType;
