import {ReactNode, useState} from 'react';
import {TODO} from '../../../../utils/TODO';
import {RowValueType} from '../../../../components/GridLayout/utils/RowValueType';
import {useAnnotationQuery} from './useAnnotationQuery';
import {getVesselFromRowValue} from '../../../../components/GridLayout/utils/getVesselFromRowValue';
import {Button} from 'antd';
import {AddOrUpdateAnnotationModal} from './AddOrUpdateAnnotationModal';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';

const annotationAccessor =
  (type: RowValueType): AccessorFn<TODO> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);

    return <AnnotationCell vessel={vessel} />;
  };

const AnnotationCell = ({vessel}: {vessel: TODO}) => {
  const [showModal, setShowModal] = useState(false);

  const annotationQuery = useAnnotationQuery({imo: vessel.imo});

  const annotation = annotationQuery.data;
  const isNew = annotation === null;

  if (!annotationQuery.isSuccess) {
    return null;
  }

  return (
    <div className={'market-grid-cell'} data-cy="cell-draft">
      <AddOrUpdateAnnotationModal
        imo={vessel.imo}
        onCancel={() => setShowModal(false)}
        visible={showModal}
        annotation={annotationQuery.data}
        isNew={isNew}
      />
      <div className={'market-grid-cell-content'}>
        {isNew && (
          <Button onClick={() => setShowModal(true)} size={'small'}>
            Add annotation
          </Button>
        )}
        {!isNew && (
          <Button onClick={() => setShowModal(true)} size={'small'}>
            Edit annotation
          </Button>
        )}
      </div>
    </div>
  );
};

export const annotationColumn = (type?: RowValueType): CustomColumnDef<TODO> => ({
  header: () => <div data-cy="header-annotation">ANNOTATION</div>,

  id: 'annotation',
  accessorFn: annotationAccessor(type),
  cell: info => info.getValue(),
  minWidth: 20,
  enableSorting: false,
});
