import dayjs from 'dayjs';
import {NegotiationParty, NegotiationStatus, NegotiationSummary} from '../../../../api/node-backend/generated';
import {createMockNegotiation} from '../../test-utils/createMockNegotiation';
import {getSummaryFromRoundResult} from '../../test-utils/getSummaryFromRoundResult';
import {mockNegotiationSummary} from '../../test-utils/mockNegotiationSummary';
import {getTutorialRoundResult, getTutorialRoundResultModifications} from './getTutorialRoundResult';

export const getTutorialNegotiation = () => {
  const party = NegotiationParty.Creator;
  const status = NegotiationStatus.Ongoing;

  const {negotiation, roundResults} = createMockNegotiation({
    party,
    status,
    allTermsSettled: false,
    allSubjectsAccepted: false,
    allSubjectsSettled: false,
    latestRoundCommitted: false,
    overwriteCounterparty: {
      name: 'John Doe',
      email: 'johndoe@example-shipping-company.com',
    },
    overwriteCommitDate: dayjs().subtract(23, 'hours').subtract(30, 'minutes').toDate(), // 30 minutes before expiry
  });

  const tutorialRoundResult = getTutorialRoundResult(roundResults[roundResults.length - 1].id, true);
  const tutorialRoundResultModification = getTutorialRoundResultModifications(tutorialRoundResult.id);

  const tutorialRoundResultPrevious = getTutorialRoundResult(roundResults[roundResults.length - 2].id, false);
  const tutorialRoundResultModificationPrevious = getTutorialRoundResultModifications(tutorialRoundResultPrevious.id);

  const tutorialroundResultSummaries = roundResults.map(getSummaryFromRoundResult);
  const tutorialRoundResultSummary = getSummaryFromRoundResult(tutorialRoundResult);

  const tutorialNegotiationSummary: NegotiationSummary = {
    ...negotiation,
    title: 'Negotiation tutorial',
    status: NegotiationStatus.Ongoing,
    latestRound: tutorialRoundResultSummary,
    roundCount: 4,
    vessel: mockNegotiationSummary.vessel,
    cargo: mockNegotiationSummary.cargo,
  };

  return {
    tutorialNegotiationSummary,
    tutorialroundResultSummaries,
    tutorialRoundResult,
    tutorialRoundResultModification,
    tutorialRoundResultPrevious,
    tutorialRoundResultModificationPrevious,
  };
};
