import {MarketCargoActionButton} from '../ClipboardButton/MarketCargoActionButton';
import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import {FC, useEffect} from 'react';
import {CargoGrid} from './CargoGrid/CargoGrid';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {MarketActions} from '../../../redux/Market';
import {invalidateMarketCargoesQuery, useMarketCargoesQuery} from '../../../queries/market/useMarketCargoesQuery';
import {useSetGridMetaData, useSetPage} from '../utils/useSetGridMetaData';
import animateScrollTo from 'animated-scroll-to';
import {useQueryClient} from '@tanstack/react-query';
import {useGetEasySharingState} from '../../../hooks/useGetEasySharingState';
import {MarketCargoFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';

export const MarketCargoGrid: FC<{
  cargoFilterProviderApi: FilterProviderApi<typeof MarketCargoFilterBranchDefinitions>;
  _changeAllSelection: () => void;
}> = ({_changeAllSelection, cargoFilterProviderApi}) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const clipboardState = useSelector(state => state.gridClipboard.market);
  const {cargoFilterSettings} = useSelector(state => state.marketFilters);
  const {cargo: cargoMeta} = useSelector(state => state.market);
  const {isEasySharingLoading, isEasySharingSetupComplete} = useGetEasySharingState();

  const setGridMetaData = useSetGridMetaData('cargo');
  const setPage = useSetPage('cargo');

  const setFilterUiOpen = () => dispatch(MarketActions.setFilterUiOpen(true));
  const goToFilters = () => {
    setFilterUiOpen();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    animateScrollTo(0);
  };

  const marketCargoesQuery = useMarketCargoesQuery({
    page: cargoMeta.page,
    pageSize: cargoMeta.pageSize,
    sortedBy: cargoMeta.sortedBy,
    filterSettings: cargoFilterSettings,
  });

  useEffect(() => {
    if (marketCargoesQuery.isSuccess && marketCargoesQuery.data) {
      _changeAllSelection(); // todo api: 'cargoes' | 'vessels' => cargoes
    }
  }, [marketCargoesQuery.isSuccess, marketCargoesQuery.data, _changeAllSelection]);

  return (
    <>
      <CargoGrid
        onSettingChange={gridMetaData => setGridMetaData(gridMetaData)}
        isFiltered={cargoFilterProviderApi.isAnyFilterApplied}
        isSubMarket={!!cargoFilterProviderApi.activeFilterId}
        gotoFilter={goToFilters}
        sharedCircularsBlurred={isEasySharingLoading || !isEasySharingSetupComplete}
        onGridDataChange={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          invalidateMarketCargoesQuery(queryClient);
        }}
        unroundedTopLeft
      />
      <MarketCargoActionButton
        items={clipboardState.cargoes}
        onGridDataChange={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          invalidateMarketCargoesQuery(queryClient);
          setPage(1);
        }}
      />
    </>
  );
};
