import {useQuery} from '@tanstack/react-query';
import {MailAsJsonApiModel} from '../../../../api/symfony/generated';
import {inboxApi} from '../../../../api/symfony/symfonyApi';
import {isApiError} from '../../../../api/utils/ApiError';
import {assertUnreachable} from '../../../../utils/assert';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';

export const useOfferMailAsJsonQuery = (
  {id, type}: {id?: number; type: 'cargo' | 'vessel'},
  options?: UseQueryOptions<MailAsJsonApiModel | null>
) =>
  useQuery({
    queryKey: ['offerMailAsJson', id, type],
    queryFn: async () => {
      try {
        switch (type) {
          case 'vessel':
            return await inboxApi.getMailAsJsonByVessel({
              vessel: id!,
            });
          case 'cargo':
            return await inboxApi.getMailAsJsonByCargo({
              cargo: id!,
            });
          default:
            assertUnreachable(type);
        }
      } catch (error) {
        if (isApiError(error)) {
          if (error.status === 404) {
            return null;
          }

          // If the mail is existing but the user is not allowed to see it, we return null
          // https://seanexxt.atlassian.net/browse/FRIN-5126
          if (error.status === 403) {
            return null;
          }
        }
        throw error;
      }
    },
    enabled: !!id,
    ...options,
  });
