import React, {useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {Button, Modal} from 'antd';
import styled from 'styled-components';
import {VesselIcons} from './VesselIcons';
import {ModalFooter} from '../../../antd/components/ModalFooter';

type SettingsViewParams = {
  className?: string;
};

export const SettingsView = styled((props: SettingsViewParams) => {
  const mapContext = useContext(MapContext);

  return (
    <Modal
      wrapClassName={props.className}
      zIndex={3000}
      title={'Map settings'}
      className={props.className}
      open={mapContext.state.showSettingView}
      onCancel={() => {
        mapContext.setShowSettingView(false);
      }}
      footer={
        <ModalFooter>
          <Button
            type="primary"
            onClick={() => {
              mapContext.setShowSettingView(false);
            }}>
            Save settings
          </Button>
        </ModalFooter>
      }>
      <BorderBox>
        <BorderBoxTitle>Vessel icons</BorderBoxTitle>
        <VesselIcons />
      </BorderBox>
    </Modal>
  );
})`
  z-index: 3000;
  .ant-drawer-mask {
    background-color: transparent;
  }
`;

const BorderBox = styled.div`
  border: var(--border-base);
  border-radius: var(--border-radius-card);
  padding: 1rem 1rem 0 1rem;
  margin-bottom: 1rem;
`;

const BorderBoxTitle = styled.span`
  display: block;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
`;

export default SettingsView;
