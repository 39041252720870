import {Vessel} from '../useMyFleetQuery/useMyFleetQuery';
import {getNextOpen} from './parts/getNextOpen';

import {NotAvailable} from '../../../../../utils/NotAvailable';

const getBuildYear = (vessel: Vessel) => {
  return vessel.builtYear ? vessel.builtYear.toString().substr(2) : NotAvailable;
};

export const getPostionLineForShortList = (vessel: Vessel) => {
  switch (vessel.vesselType) {
    case 'bulker':
      return `${vessel.name} ${vessel.dwtSummer ?? NotAvailable} / ${getBuildYear(vessel)} ${getNextOpen(vessel)}`;
    case 'container': {
      let teuIntake = vessel.intakes.find(intake => intake.type === 'teu');
      if (!teuIntake) {
        teuIntake = vessel.intakes[0];
      }
      return `${vessel.name} ${teuIntake.quantity ?? NotAvailable} / ${getBuildYear(vessel)} ${getNextOpen(vessel)}`;
    }
    default:
      return `${vessel.name} ${vessel.dwtSummer ?? NotAvailable} / ${getBuildYear(vessel)} ${getNextOpen(vessel)}`;
  }
};
