import {RowValueType} from '../../utils/RowValueType';
import {TODO} from '../../../../utils/TODO';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import Tooltip from '../../../../atoms/Tooltip';
import Icon from '../../../../atoms/Icon';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';

const commentAccessor =
  (type: RowValueType): AccessorFn<SeaboVessel> =>
  (rowValue: TODO): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'} style={{textAlign: 'center'}}>
        <div className={'market-grid-cell-content'}>
          {vessel.comment ? (
            <Tooltip position={'top'} trigger={'mouseenter focus'} style={{paddingTop: '20px'}} title={vessel.comment}>
              <Icon type={'block'} />
            </Tooltip>
          ) : (
            '-'
          )}
          {vessel.redeliveryNotice && <div>{vessel.redeliveryNotice}</div>}
        </div>
      </div>
    );
  };

export const noteColumn = ({
  type,
  minWidth = 4,
  sortable = false,
}: {
  type: RowValueType;
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<SeaboVessel> => ({
  header: 'Note',
  id: 'comment',
  accessorFn: commentAccessor(type),
  cell: info => info.getValue(),
  enableSorting: sortable,
  minWidth,
  minSize: minWidth,
});
export const commentRedelNoteColumn = ({
  type,
  minWidth = 5,
  sortable = false,
}: {
  type: RowValueType;
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<TODO> => ({
  header: () => <div>Redel. Note</div>,
  id: 'comment',
  accessorFn: commentAccessor(type),
  cell: info => info.getValue(),
  enableSorting: sortable,
  minWidth,
  minSize: minWidth,
});
