import React, {Component} from 'react';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import Checkbox from '../../../atoms/Checkbox';
import LabelWrapper from '../../../atoms/LabelWrapper';
import Input from '../../../atoms/Input';
import {numberBetween} from '../../../utils/validators';
import {FeatureSection} from '../CargoVesselForm.styled';

const Wrapper = BindToProvider(
  'Backend',
  'backend',
  {
    reviewed: false,
    displayed: true,
    mmsi: '',
  },
  {
    mmsi: numberBetween(200000000, 7999999999, 'Must be a valid MMSI, start with 2-7 followed by 7-8 digits'),
  },
  {
    toApi: (fields: $TSFixMe) => ({
      ...fields,
      mmsi: parseInt(fields.mmsi),
    }),
  },
  true
)(WrappedDetailsSection);

class Backend extends Component {
  render() {
    return (
      <Wrapper {...this.props}>
        {({onChange, values, validations, validate}: $TSFixMe) => (
          <div className="container-fluid">
            <div className={'row'}>
              <div className={'scol-12'}>
                <FeatureSection>
                  <Checkbox
                    id={`form-backend-reviewed`}
                    label={'Reviewed'}
                    checked={values.reviewed}
                    onChange={e => onChange('reviewed', e.target.checked)}
                  />
                </FeatureSection>
                <FeatureSection>
                  <Checkbox
                    id={`form-backend-displayed`}
                    label={'Show in frontend'}
                    checked={values.displayed}
                    onChange={e => onChange('displayed', e.target.checked)}
                  />
                </FeatureSection>
              </div>
              <div className="scol-12 scol-sm-6">
                <LabelWrapper label={'MMSI'} htmlFor={'form-backend-mmsi'} hasError={validations.mmsi.invalid}>
                  <Input
                    id={'form-backend-mmsi'}
                    onChange={value => onChange('mmsi', value)}
                    onKeyUp={() => validate('mmsi')}
                    value={values.mmsi}
                    hasError={validations.mmsi.invalid}
                    maxLength={10}
                  />
                </LabelWrapper>
                {validations.mmsi.error && (
                  <p className="text-danger text-danger--no-margin">{validations.mmsi.error}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}

export default Backend;
