import {DateFormat, formatMapping} from './formatMapping';
import {DateTimeSource, sourceMapping} from './sourceMapping';

export const getFormattedString = ({
  source = 'server',
  value,
  format = 'dateTime',
  tz,
}: {
  source?: DateTimeSource;
  value: string;
  format?: DateFormat;
  tz: string;
}): string => formatMapping(format, sourceMapping(source, value, tz), tz);
