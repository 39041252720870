import cx from 'classnames';
import Avatar from '../Avatar';

import './style.scss';
import styled from 'styled-components';

type Props = {
  name: string;
  isMe?: boolean;
  imageUrl: string;
  small?: boolean;
  companyName?: string;
  email?: string | null;
};

const NamedAvatar = ({name, imageUrl, isMe = false, small = false, email, companyName}: Props) => {
  return (
    <div className={cx('named-avatar', {'named-avatar--small': small})}>
      <Avatar imageUrl={imageUrl} size={small ? 'small' : 'medium'} />
      <div className="named-avatar__info">
        <span className="named-avatar__name">
          {name} {isMe ? '(thats you)' : ''}
        </span>
        {companyName && <span className="named-avatar__sub-title">{companyName ? companyName : 'N/A'}</span>}
        {email && <EmailSpan>{email.toLowerCase()}</EmailSpan>}
      </div>
    </div>
  );
};

export default NamedAvatar;

const EmailSpan = styled.span`
  display: block;
  color: var(--color-gray-2);
  font-size: var(--font-size-xs);
`;
