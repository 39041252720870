import {VoyagePoint} from '../../../VoyageTypes';
import {Input} from 'antd';
import {regexNotIncludeMinusSign} from '../../../../../../../utils/regexExpressions';
import {getDataFieldId} from '../../../../utils/calcWarnings/calcWarnings';
import {NotApplicable} from '../../../utils/NotApplicable';
import {StyledFormItem} from '../../StyledFormItem';

type Props = {
  point: VoyagePoint;
  area: string;
};
export const Quantity = (props: Props) => {
  const isLoading = props.point.types?.includes('loading');
  const isDischarge = props.point.types?.includes('discharge');
  const active = isLoading || isDischarge;

  if (!active) {
    return <NotApplicable />;
  }

  return (
    <span style={{display: 'flex', alignItems: 'center'}}>
      <StyledFormItem
        rules={[
          {
            message: 'Quantity must be greater than or equal to 0.',
            pattern: regexNotIncludeMinusSign,
          },
        ]}
        name={isLoading ? 'load' : 'dis'}
        normalize={s => {
          const n = parseInt(s, 10);
          return isNaN(n) ? undefined : n;
        }}>
        <Input
          size="small"
          type={'number'}
          data-field-id={getDataFieldId({field: isLoading ? 'load' : 'dis', area: props.area})}
          style={{width: 130}}
          suffix={'mts'}
        />
      </StyledFormItem>
    </span>
  );
};
