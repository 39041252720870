import React, {Component} from 'react';
import {connect} from 'react-redux';
import {actions as attachmentApi} from '../../../redux/ApiService/AttachmentService';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import Upload, {acceptImages} from '../../../components/Upload';
import ImageThumbnailGrid from '../../../components/ImageThumbnailGrid';
import {RootState} from '../../../redux/store';

const Wrapper = BindToProvider(
  'Images',
  'images',
  {
    images: [],
  },
  {},
  {},
  true
)(WrappedDetailsSection);
type ImagesState = $TSFixMe;

class Images extends Component<{maxSize?: number}, ImagesState> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props: {}) {
    super(props);
    this.state = {
      data: (props as $TSFixMe).isEdit ? (props as $TSFixMe).form.images.images : [],
      errors: [],
    };
  }
  _handleUpload = (files: $TSFixMe, onChange: $TSFixMe) => {
    const formData = new FormData();
    files.forEach((image: $TSFixMe, key: $TSFixMe) => {
      formData.append(`fileName${key}`, image);
    });
    (this.props as $TSFixMe).upload(formData).then((res: $TSFixMe) => {
      if (res?.data?.items?.length > 0 || res?.errors) {
        this.setState(
          (prev: $TSFixMe) => ({
            data: [...prev.data, ...res.data.items],
            errors: res.errors,
          }),
          () => onChange('images', this.state.data)
        );
      }
    });
  };
  _handleRemove = (idx: $TSFixMe, onChange: $TSFixMe) => {
    this.setState(
      (prev: $TSFixMe) => ({
        data: prev.data.filter((item: $TSFixMe, key: $TSFixMe) => key !== idx),
      }),
      () => onChange('images', this.state.data)
    );
  };
  render() {
    return (
      <Wrapper {...this.props}>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {({onChange, values, validations}: $TSFixMe) => {
          return (
            <div className="container-fluid">
              <div className="row">
                <div className="scol-12 scol-sm-12">
                  <ImageThumbnailGrid
                    images={values.images}
                    noText
                    deletable
                    onRemove={(idx: $TSFixMe) => this._handleRemove(idx, onChange)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="scol-12">
                  <Upload
                    headline="Add image"
                    subline={'Drag and drop your files here or click to upload'}
                    accept={acceptImages}
                    multiple
                    maxSize={this.props.maxSize}
                    onDrop={(files: $TSFixMe) => this._handleUpload(files, onChange)}
                    loading={(this.props as $TSFixMe).uploadState.loading}
                    uploadErrors={this.state.errors}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Wrapper>
    );
  }
}
const mapStateToProps = ({api}: RootState) => ({
  uploadState: api.attachmentService.uploadVesselImages,
});
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  upload: (body: $TSFixMe) => dispatch(attachmentApi.uploadVesselImages({body})),
});
export default connect(mapStateToProps, mapDispatchToProps)(Images);
