import {useState, FC} from 'react';
import {Row, Col, Button} from 'antd';
import {VisitTimestampsWaterfall} from './BarStats';
import {StatCard} from '../sharedComponents/StatCard';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {VisitDetails} from './VisitDetails';
import {FeatureCollection} from 'geojson';
import styled from 'styled-components';
import {useDispatch} from '../../../redux/react-redux';
import {setMapDetailElement} from '../../../redux/MapDetails';

export const VisitTimeGraph: FC<{
  finishedVisits: MergedVisits[];
  polygon?: FeatureCollection;
}> = ({finishedVisits, polygon}) => {
  const dispatch = useDispatch();
  const [selectedVisit, _setSelectedVisit] = useState<MergedVisits | null>(null);
  const [sortKey, setSortKey] = useState<'startTimestamp' | 'endTimestamp'>('startTimestamp');

  const setSelectedVisit = (visit: MergedVisits | null) => {
    dispatch(setMapDetailElement(null));
    _setSelectedVisit(visit);
  };

  return (
    <StyledStatCard $center>
      <Row wrap={false}>
        <Col flex={'auto'}>
          <VisitTimestampsWaterfall
            finishedVisits={finishedVisits}
            sortKey={sortKey}
            setSelectedVisit={setSelectedVisit}
          />
        </Col>
        {selectedVisit ? (
          <Col span={12} style={{marginLeft: 20}}>
            <VisitDetails visit={selectedVisit} polygon={polygon} onClose={() => setSelectedVisit(null)} />
          </Col>
        ) : null}
      </Row>
      <Button.Group style={{justifySelf: 'center'}}>
        <Button
          onClick={() => setSortKey('startTimestamp')}
          type={sortKey === 'startTimestamp' ? 'primary' : 'default'}>
          Align by start timestamp
        </Button>
        <Button onClick={() => setSortKey('endTimestamp')} type={sortKey === 'endTimestamp' ? 'primary' : 'default'}>
          Align by end timestamp
        </Button>
      </Button.Group>
    </StyledStatCard>
  );
};

const StyledStatCard = styled(StatCard)`
  --graph-height: calc(100vh - 300px);
`;
