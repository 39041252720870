import {ChangeEvent, FC, useEffect, useState} from 'react';
import {Button, Form, Input} from 'antd';
import {Store, ValidateErrorEntity} from 'rc-field-form/lib/interface';
import {AccountFormValues, FieldName} from './AccountFormValues';
import {ApiErrorMessage} from '../../../components/ApiErrorMessage/ApiErrorMessage';
import {useServerSideValidator} from '../../../utils/serverSideValidator';
import {Link} from 'react-router-dom';
import {
  SettingsSection,
  SettingsSectionBody,
  SettingsSectionTitle,
} from '../../../components/SettingsCard/SettingsSection';
import {SettingsButtons} from '../../../components/SettingsCard/SettingsButtons';

/**
 * Fields that show server side validation fields inline.
 */
const INLINE_VALIDATION_FIELDS: FieldName[] = ['currentPassword', 'email'];

const onFinishFailed = (_errorInfo: ValidateErrorEntity) => {};

export const AccountForm: FC<{
  initialValues: AccountFormValues;
  isSubmitting: boolean;
  onSubmit: (formValues: AccountFormValues) => void;
  submitError?: Error | null;
}> = ({initialValues, isSubmitting, onSubmit, submitError}) => {
  const [form] = Form.useForm();
  const [checkNewPasswordConfirmation, setCheckNewPasswordConfirmation] = useState(false);
  const [formValuesAtSubmit, setFormValuesAtSubmit] = useState<AccountFormValues | null>(null);
  const serverSidePasswordValidator = useServerSideValidator<AccountFormValues>(
    'currentPassword',
    submitError,
    formValuesAtSubmit,
    form
  );
  const serverSideEmailValidator = useServerSideValidator<AccountFormValues>(
    'email',
    submitError,
    formValuesAtSubmit,
    form
  );

  useEffect(() => {
    if (submitError) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      form.validateFields(INLINE_VALIDATION_FIELDS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitError]);

  const onNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckNewPasswordConfirmation(!!e.target.value);
  };

  const onFinish = (values: Store) => {
    const formValues = values as AccountFormValues;
    setFormValuesAtSubmit(formValues);
    onSubmit(formValues);
  };

  return (
    <div data-testid="AccountForm">
      {submitError && (
        <>
          <ApiErrorMessage error={submitError} inlineValidationFields={INLINE_VALIDATION_FIELDS} />
          <br />
        </>
      )}

      <Form
        form={form}
        name="Account"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError>
        <SettingsSection $antForm>
          <SettingsSectionTitle>Password</SettingsSectionTitle>

          <SettingsSectionBody>
            <Form.Item
              label="Current password"
              name="currentPassword"
              extra={
                <div>
                  <div style={{height: 8}} />
                  Please confirm changes with your <u>current</u> password!
                  <br />
                  <Link to="/authentication/lost-password">Forgot your password?</Link>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
                {
                  validator: serverSidePasswordValidator,
                },
              ]}
              style={{marginBottom: 12}}>
              <Input.Password data-testid="currentPassword" />
            </Form.Item>
          </SettingsSectionBody>
        </SettingsSection>

        <SettingsSection $antForm>
          <SettingsSectionTitle>Change name</SettingsSectionTitle>
          <SettingsSectionBody>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                {
                  type: 'string',
                },
                {
                  required: true,
                  message: 'Please input your first name',
                },
                {
                  max: 50,
                  message: 'First name cannot be longer than 50 characters',
                },
              ]}>
              <Input data-testid="firstName" />
            </Form.Item>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                {
                  type: 'string',
                },
                {
                  required: true,
                  message: 'Please input your last name',
                },
                {
                  max: 50,
                  message: 'Last name cannot be longer than 50 characters',
                },
              ]}>
              <Input />
            </Form.Item>
          </SettingsSectionBody>
        </SettingsSection>

        <SettingsSection $antForm>
          <SettingsSectionTitle>Change password</SettingsSectionTitle>
          <SettingsSectionBody>
            <Form.Item
              label="New password"
              name="newPassword"
              rules={[
                {
                  required: false,
                  message: 'Please input your new password',
                },
                {min: 8, message: 'New password must be minimum 8 characters.'},
              ]}>
              <Input.Password onChange={onNewPasswordChange} />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              name="newPasswordConfirmation"
              dependencies={['newPassword']}
              rules={[
                {
                  required: checkNewPasswordConfirmation,
                  message: 'Please input your new password again',
                },

                ({getFieldValue}) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two new passwords must match');
                  },
                }),
              ]}>
              <Input.Password />
            </Form.Item>
          </SettingsSectionBody>
        </SettingsSection>

        <SettingsSection $antForm>
          <SettingsSectionTitle>Change email address</SettingsSectionTitle>
          <SettingsSectionBody>
            <Form.Item
              name="email"
              label="New email address"
              rules={[
                {
                  type: 'email',
                  message: 'The input is no valid email',
                },
                {
                  required: true,
                  message: 'Please input your new email',
                },
                {
                  validator: serverSideEmailValidator,
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="emailConfirmation"
              label="Confirm email"
              dependencies={['email']}
              rules={[
                {
                  required: true,
                  message: 'Please input your new email again',
                },

                ({getFieldValue}) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two new email addresses must match');
                  },
                }),
              ]}>
              <Input />
            </Form.Item>
          </SettingsSectionBody>
        </SettingsSection>

        <SettingsButtons>
          <Button type="primary" htmlType="submit" disabled={isSubmitting} data-testid="Submit">
            Save
          </Button>
        </SettingsButtons>
      </Form>
    </div>
  );
};
