import {Vessel} from '../useMyFleetQuery/useMyFleetQuery';
import {charTime} from './utils';
import {getPositionStringBulker} from './getPositionStringBulker';
import {getPositionStringContainer} from './getPositionStringContainer';
import {getPositionStringOther} from './getPositionStringOther';
import {getPostionLineForShortList} from './getPostionLineForShortList';

const getPositionStringForOne = (vessel: Vessel, short?: boolean): string => {
  if (short) {
    return getPostionLineForShortList(vessel);
  }

  switch (vessel.vesselType) {
    case 'bulker':
      return getPositionStringBulker(vessel);
    case 'container':
      return getPositionStringContainer(vessel);
    default:
      return getPositionStringOther(vessel);
  }
};

export const getPostionListString = (vessels: Vessel[]): string => {
  return `
Abstract

${vessels.map(vessel => getPositionStringForOne(vessel, true)).join('\n')}

_________________

Details
${vessels.map(vessel => getPositionStringForOne(vessel)).join('\n\n' + charTime('#', 20) + '\n\n')}
`;
};
