/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Weekday, WeekdayFromJSON, WeekdayFromJSONTyped, WeekdayToJSON} from './';

/**
 *
 * @export
 * @interface Holiday
 */
export interface Holiday {
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  observed?: string;
  /**
   *
   * @type {boolean}
   * @memberof Holiday
   */
  _public?: boolean;
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  uuid?: string;
  /**
   *
   * @type {Weekday}
   * @memberof Holiday
   */
  weekday?: Weekday;
}

export function HolidayFromJSON(json: any): Holiday {
  return HolidayFromJSONTyped(json, false);
}

export function HolidayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Holiday {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    date: !exists(json, 'date') ? undefined : json['date'],
    observed: !exists(json, 'observed') ? undefined : json['observed'],
    _public: !exists(json, 'public') ? undefined : json['public'],
    country: !exists(json, 'country') ? undefined : json['country'],
    uuid: !exists(json, 'uuid') ? undefined : json['uuid'],
    weekday: !exists(json, 'weekday') ? undefined : WeekdayFromJSON(json['weekday']),
  };
}

export function HolidayToJSON(value?: Holiday | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    date: value.date,
    observed: value.observed,
    public: value._public,
    country: value.country,
    uuid: value.uuid,
    weekday: WeekdayToJSON(value.weekday),
  };
}
