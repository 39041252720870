/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Query
 */
export interface Query {
  /**
   *
   * @type {string}
   * @memberof Query
   */
  text: string;
}

export function QueryFromJSON(json: any): Query {
  return QueryFromJSONTyped(json, false);
}

export function QueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Query {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: json['text'],
  };
}

export function QueryToJSON(value?: Query | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
  };
}
