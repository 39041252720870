/* eslint-disable no-nested-ternary */
import {Card} from 'antd';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Station from '../../../model/Station';
import Vessel from '../../../model/Vessel';
import {numberFormat, numberRangeUnitFormat, numberUnitFormat} from '../../../utils/formatter';
import {TODO} from '../../../utils/TODO';
import {ProjectOverviewDescription} from './ProjectOverviewDescription';
import {ProjectOverviewHeader} from './ProjectOverviewHeader';
import {NotAvailable} from '../../../utils/NotAvailable';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';

type Props = {
  project: $TSFixMe;
  markAsFavorite?: $TSFixMeFunction;
  onEdit?: $TSFixMeFunction;
  onDelete?: $TSFixMeFunction;
  isExpanded?: boolean;
  onRating?: (project: TODO, rating: TODO) => TODO;
  onUpdate: (updatedProject: TODO) => void;
  onArchive: () => TODO;
  workspace?: $TSFixMe;
  isFavorite: boolean;
};
const ProjectOverviewCard = ({
  project,
  onDelete,
  onEdit,
  markAsFavorite,
  isExpanded,
  isFavorite,
  onRating,
  onUpdate,
  workspace,
  onArchive,
}: Props) => {
  let attributes = {};
  const contractType = {
    tct: 'Time charter trip',
    pc: 'Period charter',
    bc: 'Bareboat charter',
  };
  const {cargo, vessel} = project;
  const lastUpdated = dayjs(project.updatedAt).startOf('minutes').fromNow();
  if (project.type === 'cargo') {
    switch (cargo.contractType) {
      case 'vc':
        attributes = {
          type: 'vc',
          contractType: 'Voyage charter',
          size: numberRangeUnitFormat(cargo.quantityMin, cargo.quantityMax, cargo.quantityUnit),
          laycan:
            cargo.laycanFrom && cargo.laycanTo
              ? dayjs(cargo.laycanFrom).format(DateTimeFormat.Date) +
                '-' +
                dayjs(cargo.laycanTo).format(DateTimeFormat.Date)
              : null,
          commission: `${cargo.commissionTotal}%`,
          loadingLocation: Station.getLocationByStationType(cargo.stations, Station.LOADING, {short: true}),
          dischargeLocation: Station.getLocationByStationType(cargo.stations, Station.DISCHARGE, {short: true}),
          commodity:
            cargo.commodityCategory && cargo.commodityType
              ? cargo.commodityCategory + ' ' + cargo.commodityType
              : cargo.commodityCategory
              ? cargo.commodityCategory
              : cargo.commodityType
              ? cargo.commodityType
              : null,
        };
        break;
      case 'tct':
      case 'pc':
      case 'bc':
        attributes = {
          type: cargo.contractType,
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          contractType: contractType[cargo.contractType],
          vesselType: cargo.vesselType,
          laycan:
            cargo.laycanFrom && cargo.laycanTo
              ? dayjs(cargo.laycanFrom).format(DateTimeFormat.Date) +
                '-' +
                dayjs(cargo.laycanTo).format(DateTimeFormat.Date)
              : null,
          duration: numberRangeUnitFormat(cargo.durationMin, cargo.durationMax, cargo.durationUnit, {
            toUpperCase: false,
          }),
          delivery: Station.getLocationByStationType(cargo.stations, Station.DELIVERY, {short: true}),
          redelivery: Station.getLocationByStationType(cargo.stations, Station.REDELIVERY, {short: true}),
          commission: cargo.commissionTotal ? `${cargo.commissionTotal}%` : null,
          minSize: numberUnitFormat(cargo.vesselSizeMin, cargo.vesselSizeUnit),
          maxSize: numberUnitFormat(cargo.vesselSizeMax, cargo.vesselSizeUnit),
          maxYears: cargo.maxVesselAge,
        };
        break;
      default:
    }
  } else if (project.type === 'vessel') {
    const isContainer = vessel.vesselType === 'container';
    const teu = vessel.intakes.find((d: $TSFixMe) => d.type === 'teu');
    const teu14 = vessel.intakes.find((d: $TSFixMe) => d.type === 'teu14');
    attributes = {
      type: 'vessel',
      size: numberUnitFormat(vessel.dwtSummer, 'dwt'),
      teu14: teu14 ? numberFormat(teu14.quantity) : null,
      teu: teu ? numberFormat(teu.quantity) : null,
      isContainer,
      vessel: vessel.name,
      dateOpen: vessel ? Vessel.formatDateOpen(vessel) : NotAvailable,
      locationOpen: Station.getLocationByStationType(vessel.stations, Station.NEXTOPEN, {short: true}),
    };
  }
  return (
    <ProjectOverviewCardContainer $color={project.color}>
      <Link to={`/projects/${project.id}`}>
        <ProjectOverviewHeader
          type={project.type}
          name={project.name}
          isArchived={project.isArchived}
          description={`${project.readableProjectId} Last update: ${lastUpdated}`}
          isFavorite={isFavorite}
          negotiations={project.negotiationCount}
          items={project.shortlistItemCount}
          rating={project.rating}
          project={project}
          onFavorite={markAsFavorite}
          onEdit={onEdit}
          onUpdate={(updatedProject: TODO) => onUpdate(updatedProject)}
          onDelete={onDelete}
          onArchive={onArchive}
          onRating={(rating: number) => onRating?.(project, rating)}
          createdUser={project.user}
          workspace={workspace}
        />
        {isExpanded && <ProjectOverviewDescription attributes={attributes} type={(attributes as $TSFixMe).type} />}
      </Link>
    </ProjectOverviewCardContainer>
  );
};

const ProjectOverviewCardContainer = styled(Card)<{$color?: string}>`
  --timeline-marker-width: var(--border-radius-card);

  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--timeline-marker-width);
    background: ${({$color}) => ($color ? $color : 'var(--color-gray-3)')};
  }

  .ant-card-body {
    padding: 0 0 0 var(--timeline-marker-width);
  }

  &:hover .ant-card-body {
    background: var(--color-gray-6);
  }
`;

export default ProjectOverviewCard;
