import {CompositeLayer, CompositeLayerProps} from '@deck.gl/core/typed';
import {GeoJsonLayer, GeoJsonLayerProps} from '@deck.gl/layers/typed';
import {COLORS, geoJsonLayerPropsDefaults} from '../../const';
import {TODO} from '../../../../utils/TODO';
import {Feature} from '@turf/helpers';
import {portToGeoJson} from './portToGeoJson';
import {LayerInputHandler} from '../../../../utils/deck.gl/deck.gl-types';

const style: Partial<CompositeLayerProps & GeoJsonLayerProps> = {
  filled: true,
  pointRadiusMinPixels: 4,
  pointRadiusMaxPixels: 4,
  getLineWidth: d => (d.geometry.type === 'Polygon' ? 1 : 2),
  getLineColor: d =>
    // eslint-disable-next-line  no-nested-ternary
    d.geometry.type === 'Polygon'
      ? d.properties!.type === 'port'
        ? COLORS.PORT_POLYGON_LINE
        : COLORS.POI_POLYGON_LINE
      : d.properties!.type === 'port'
      ? COLORS.PORT_POINT_LINE
      : COLORS.POI_POINT_LINE,
  getFillColor: d =>
    // eslint-disable-next-line  no-nested-ternary
    d.geometry.type === 'Polygon'
      ? d.properties!.type === 'port'
        ? COLORS.PORT_POLYGON_FILL
        : COLORS.POI_POLYGON_FILL
      : COLORS.PORT_POINT_FILL,
};

type Props = {
  port: TODO;
  data?: TODO;
  onHover?: LayerInputHandler;
};
export class SinglePortLayer extends CompositeLayer<Props> {
  constructor(props: Props) {
    super(props);
    this.id = this.props.port.id;
  }

  renderLayers() {
    const featureCollection = portToGeoJson(this.props.port);

    return [
      new GeoJsonLayer<Feature>({
        id: `port-polygons_${this.props.port.id}`,
        data: featureCollection,
        onHover: this.props.onHover,
        pickable: !!this.props.onHover,
        ...geoJsonLayerPropsDefaults,
        ...style,
      }),
    ];
  }
}

SinglePortLayer.layerName = 'SinglePortLayer';
