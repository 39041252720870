import {EffectCallback, useEffect, useRef} from 'react';

/**
 * This hook is a workaround for the fact that useEffect is called twice in StrictMode.
 * It will only run the effect once, and then never again.
 *
 * In general this is not needed - you should setup your cleanup function in useEffect
 * so that it can be called multiple times without issue.
 */
export const useStrictModeUnsafeEffect = (effect: EffectCallback): void => {
  const effectRan = useRef<boolean>(false);
  useEffect(() => {
    if (effectRan.current) {
      return;
    }
    const destructor = effect();
    const wrappedDestructor = () => {
      effectRan.current = true;
      destructor?.();
    };
    return wrappedDestructor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
