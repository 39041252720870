import {FC, useRef} from 'react';
import {useParams} from 'react-router';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {LoadingBanner, NegotiationScreenContainer} from '../Components/shared';
import {NotFound} from '../../../atoms/EmptyState/404';
import {useFindNegotiationByIdQuery} from './utils/useFindNegotiationByIdQuery';
import {NegotiationDetailBody} from './NegotiationDetailBody';
import {useFindRoundResultsByNegotiationIdQuery} from './utils/useFindRoundResultsByNegotiationIdQuery';
import {useFindRoundResultByIdQuery} from './utils/useFindRoundResultByIdQuery';
import {useGetRoundResultModificationsQuery} from './utils/useGetRoundResultModificationsQuery';
import {useGetTermActionsWithMutations} from './Components/TermItem/utils/useGetTermActionsWithMutations';
import {useGetSubjectActionsWithMutations} from './Components/Subjects/utils/useGetSubjectActionsWithMutations';
import {useGetNegotiationActionsWithMutations} from './Components/CommitArea/utils/useGetNegotiationActionsWithMutations';
import {useGetSidebarActionsWithMutations} from './Components/TermArea/Sidebar/useGetSidebarActionsWithMutations';
import {useAttachmentsQuery} from './Components/Attachments/useAttachmentsQuery';
import {useGetAttachmentActionsWithMutations} from './Components/Attachments/useAttachmentActions';
import {useGetNegotiationTemplateQuery} from '../NegotiationTemplates/utils/useGetNegotiationTemplateQuery';
import {useGetTemplateActionsWithMutations} from '../NegotiationTemplates/utils/useTemplateActions';
import {useGetNegotiationTemplateSummariesQuery} from '../NegotiationTemplates/utils/useGetNegotiationTemplateSummariesQuery';
import {Space} from 'antd';

export const NegotiationDetailScreen: FC<{isGuest?: boolean}> = ({isGuest}) => {
  const {negotiationId} = useParams<{negotiationId: string}>();

  // Due to complexity of the tool, we are using a network layer model to fetch and mutate the backend data from outside.
  // This means the implementation details of the queries are abstracted away from the components
  // and the components are only concerned with the data they need to render.
  const findNegotiationByIdQuery = useFindNegotiationByIdQuery(negotiationId!, {
    enabled: !!negotiationId,
  });
  const roundResultsQuery = useFindRoundResultsByNegotiationIdQuery(negotiationId!, {
    enabled: !!negotiationId,
  });

  const currentRoundResultId = roundResultsQuery.data?.[roundResultsQuery.data?.length - 1]?.id;
  const findRoundResultByIdQuery = useFindRoundResultByIdQuery(currentRoundResultId);
  const roundResultModificationsQuery = useGetRoundResultModificationsQuery(currentRoundResultId);

  const previousRoundResultId = roundResultsQuery.data?.[roundResultsQuery.data?.length - 2]?.id;
  const previousRoundResultQuery = useFindRoundResultByIdQuery(previousRoundResultId);
  const previousRoundResultModificationsQuery = useGetRoundResultModificationsQuery(previousRoundResultId);

  const templateSummariesQuery = useGetNegotiationTemplateSummariesQuery(isGuest);
  const attachmentsQuery = useAttachmentsQuery(negotiationId!);

  const isLoading = findNegotiationByIdQuery.isLoading;
  const negotiation = findNegotiationByIdQuery.data;

  const useGetTemplateDetail = useGetNegotiationTemplateQuery;

  const printContainerRef = useRef<HTMLDivElement>(null);
  const {getNegotiationActions, negotiationActionsBusy} = useGetNegotiationActionsWithMutations(
    negotiationId,
    currentRoundResultId,
    printContainerRef
  );

  const {getTermActions, termActionsBusy} = useGetTermActionsWithMutations(
    negotiationId,
    currentRoundResultId,
    negotiation?.party
  );

  const {getSubjectActions, subjectActionsBusy} = useGetSubjectActionsWithMutations(
    negotiationId,
    currentRoundResultId,
    negotiation?.party
  );
  const {getSidebarActions, sidebarActionBusy} = useGetSidebarActionsWithMutations(negotiationId, currentRoundResultId);

  const {getTemplateActions} = useGetTemplateActionsWithMutations();

  const {getAttachmentActions, attachmentActionsBusy} = useGetAttachmentActionsWithMutations(
    negotiationId,
    currentRoundResultId
  );

  const breadcrumbs = [{title: 'Negotiations', href: '/negotiations'}, {title: 'Negotiation detail'}];

  if (isLoading) {
    return (
      <NegotiationScreenContainer data-testid="NegotiationDetailLoading">
        <ScreenHeader helmetTitle="Negotiation" breadcrumbs={breadcrumbs} />
        <LoadingBanner />
      </NegotiationScreenContainer>
    );
  }

  if (!negotiation) {
    return (
      <NegotiationScreenContainer data-testid="NegotiationDetailNotFound">
        <ScreenHeader helmetTitle="Negotiation" breadcrumbs={breadcrumbs} />
        <NotFound redirectTarget={{label: 'Back to all negotiations', href: '/negotiations'}} />
      </NegotiationScreenContainer>
    );
  }

  const roundResultSummaries = roundResultsQuery.data;
  const currentRoundResult = findRoundResultByIdQuery.data;
  const currentRoundResultModifications = roundResultModificationsQuery.data;
  const previousRoundResult = previousRoundResultQuery.data;
  const previousRoundResultModifications = previousRoundResultModificationsQuery.data;
  const templateSummaries = templateSummariesQuery.data;
  const attachments = attachmentsQuery.data;
  const actionsBusy =
    termActionsBusy || subjectActionsBusy || negotiationActionsBusy || sidebarActionBusy || attachmentActionsBusy;

  return (
    <NegotiationScreenContainer data-testid="NegotiationDetail">
      <ScreenHeader
        breadcrumbs={breadcrumbs}
        helmetTitle={`${negotiation.title} - Negotiations`}
        actions={
          <Space size={4}>
            <ScreenHeader.Actions.Link to="/negotiations/tutorial" data-testid="NegotiationTutorialLink">
              Tutorial
            </ScreenHeader.Actions.Link>
          </Space>
        }
      />
      <NegotiationDetailBody
        isGuest={isGuest}
        negotiation={negotiation}
        roundResultSummaries={roundResultSummaries}
        currentRoundResult={currentRoundResult}
        currentRoundResultModifications={currentRoundResultModifications}
        previousRoundResult={previousRoundResult}
        previousRoundResultModifications={previousRoundResultModifications}
        templateSummaries={templateSummaries}
        printContainerRef={printContainerRef}
        attachments={attachments}
        actionsBusy={actionsBusy}
        useGetTemplateDetail={useGetTemplateDetail}
        getTermActions={getTermActions}
        getSubjectActions={getSubjectActions}
        getNegotiationActions={getNegotiationActions}
        getSidebarActions={getSidebarActions}
        getTemplateActions={getTemplateActions}
        getAttachmentActions={getAttachmentActions}
      />
    </NegotiationScreenContainer>
  );
};
