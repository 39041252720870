import {useEffect, useCallback} from 'react';
import {PushMessagePayload} from './ServerPushSocket';
import {useServerPushSocket} from './ServerPushContext';
import {debugLog} from './ServerPushDebugLog';
import {ServerToClientEventType} from './SocketIOTypes';

/**
 * A hook that subscribes a given function to push messages from ServerPush/socket.io.
 *
 * Handles deregistration automatically.
 */
export const usePushMessages = (event: ServerToClientEventType, onMessage: (payload: PushMessagePayload) => void) => {
  const serverPushSocket = useServerPushSocket();
  const callback = useCallback(
    (payload: PushMessagePayload) => {
      debugLog('Received event: %j with payload: %j', event, payload);
      onMessage(payload);
    },
    [event, onMessage]
  );
  useEffect(() => {
    const removeListener = serverPushSocket.onMessage(event, callback);
    return removeListener;
  }, [serverPushSocket, callback, event]);
};
