import {Card} from 'antd';
import React, {Component} from 'react';
import {SideNote} from '../../../atoms/SideNote/SideNote';
import {TODO} from '../../../utils/TODO';
import './style.scss';
import {companyTypesOptions} from '../../../components/Company/components/DetailedInformation/CompanyDetailedInformation';

interface Props {
  description: TODO;
  employees: TODO;
  foundYear: TODO;
  companyType: TODO;
}

class Description extends Component<Props> {
  render() {
    const {description, employees, foundYear, companyType} = this.props;

    const companyTypeLabel = companyTypesOptions.find(item => item.value === companyType)?.label;

    return (
      <Card className={'profile-description'}>
        <div className={'description'}>
          <div className={'description__headline'}>Description & details</div>
          <div className={'description__description'}>{description}</div>
        </div>
        <div className={'profile-description__notes'}>
          <SideNote title={'Founded'} content={foundYear} />
          <SideNote title={'company type'} content={companyTypeLabel} />
          <SideNote title={'employees'} content={employees} />
        </div>
      </Card>
    );
  }
}

export default Description;
