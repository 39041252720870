import {FC, MouseEventHandler} from 'react';
import {
  EasySharingActiveInboxPopover,
  EasySharingPromotionPopover,
} from '../../../components/EasySharing/EasySharingPromotion';
import {SharingIcon} from '../../../components/EasySharing/shared';
import {Tooltip} from 'antd';
import {FeaturePermission} from '../../../api/symfony/generated';
import {useIsPermitted} from '../../../utils/useIsPermitted';

type EasySharingInboxProps = {
  isInboxActive?: boolean;
  onInboxClick?: () => void;
};

export const EasySharingInbox: FC<EasySharingInboxProps> = ({isInboxActive, onInboxClick}) => {
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);

  const captureClick: MouseEventHandler = event => {
    event.stopPropagation();
  };

  if (isInboxActive) {
    return (
      <span onClick={captureClick}>
        <EasySharingActiveInboxPopover>
          <div>
            <SharingIcon type="inbox" $isActive={isInboxActive} onClick={onInboxClick} />
          </div>
        </EasySharingActiveInboxPopover>
      </span>
    );
  }

  if (!manageOwnCompanyPermitted) {
    return (
      <span>
        <Tooltip title="Please ask a company administrator to change Easy Sharing settings for this workspace">
          <div>
            <SharingIcon type="inbox" $isActive={isInboxActive} />
          </div>
        </Tooltip>
      </span>
    );
  }

  return (
    <span onClick={captureClick}>
      <EasySharingPromotionPopover>
        <div>
          <SharingIcon type="inbox" $isActive={isInboxActive} onClick={onInboxClick} />
        </div>
      </EasySharingPromotionPopover>
    </span>
  );
};
