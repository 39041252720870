import LabelWrapper from '../../../../atoms/LabelWrapper';
import {GroupHeading} from '../../../../atoms/Select/otherComponents';
import locationService from '../../../../redux/ApiService/locationService';
import {DropdownMultiValueLocation, getLocationSelectOption} from '../../../LocationOutput/LocationOutputRow';
import {getLocationValues} from '../../../LocationOutput/getLocationValues';
import {useDispatch} from '../../../../redux/react-redux';
import {filterBranchDefinition} from './LocationPortDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {AsyncMultiSelect} from '../../../../atoms/Select/AsyncMultiSelect';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const LocationPort = ({filterProviderApi}: Props) => {
  const dispatch = useDispatch();
  const searchAll = (search: string) => dispatch(locationService.searchAll(search));

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size={'big'}>
      {({handleChange, values}) => (
        <div className="container">
          <div className="row bottom-fix">
            <div className="scol-12">
              <LabelWrapper label={'Location'} htmlFor={'filter-locations'}>
                <AsyncMultiSelect
                  id={'filter-locations'}
                  name={'filter-locations'}
                  value={values.stations}
                  onChange={value => handleChange('stations', getLocationValues(value))}
                  getOptionLabel={o => getLocationSelectOption(o, true)}
                  getOptionValue={option => option.id.toString()}
                  loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
                  cacheOptions
                  defaultOptions
                  placeholder="Enter port, country or area"
                  components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                />
              </LabelWrapper>
            </div>
          </div>
        </div>
      )}
    </FilterButton>
  );
};

export default LocationPort;
