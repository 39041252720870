import {useMemo, FC} from 'react';
import {useAisVesselsQuery} from '../../queries/useAisVesselsQuery';
import {AisVessel} from '../../api/symfony/generated';
import {Coordinates} from '../../utils/Coordinates';
import {SeaboVessel} from '../../api/node-backend/generated';
import styled from 'styled-components';
import {SeaboMap} from '../SeaboMap/SeaboMap';
import {MapControlButton, MapControlMenuSectionsToHide, MapSettings} from '../SeaboMap/MapContext/Types';
import {IdentifierProp} from '../../redux/Map';
import {PortfolioVessel} from '../../redux/Portfolio';

export interface VesselMapProps {
  initialMapSettings?: Partial<MapSettings>;
  mapControlButtonsToHide?: MapControlButton[];
  mapControlMenuSectionsToHide?: MapControlMenuSectionsToHide[];
  vessels: SeaboVessel[] | PortfolioVessel[] | null;
  settingIdentifier: IdentifierProp | undefined;
}

export const VesselMap: FC<VesselMapProps> = ({
  vessels,
  mapControlButtonsToHide,
  mapControlMenuSectionsToHide,
  initialMapSettings,
  settingIdentifier,
}) => {
  const aisVesselsQuery = useAisVesselsQuery();

  const aisVesselsMap = useMemo(() => {
    const map: Record<number, AisVessel> = {};
    aisVesselsQuery.data?.forEach(vessel => {
      map[vessel.imo!] = vessel;
    });
    return map;
  }, [aisVesselsQuery.data]);

  const imos = useMemo(() => (vessels ? vessels.map(vessel => vessel.imo) : []), [vessels]);
  const cleanImos = imos.filter(imo => imo !== undefined) as number[];
  const vesselsInMap = cleanImos
    .filter(imo => imo !== undefined)
    .map(imo => aisVesselsMap[imo!])
    .filter(vessels => !!vessels);
  const coordsToFocus = vesselsInMap.map(vessel => vessel.coordinates as Coordinates);

  return (
    <VesselMapContainer>
      <SeaboMap
        settingIdentifier={settingIdentifier}
        showPopups={true}
        mapControlButtonsToHide={mapControlButtonsToHide ?? [MapControlButton.CARGOES, MapControlButton.VESSELS]}
        mapControlMenuSectionsToHide={mapControlMenuSectionsToHide}
        coordinatesToFocus={coordsToFocus}
        settingsButtonVisible={false}
        minimumZoomLevelToShowPorts={0}
        vesselsToVisualize={{data: vesselsInMap, opacity: 1, constantIconSize: 8}}
        initialMapSettings={initialMapSettings}
      />
    </VesselMapContainer>
  );
};

const VesselMapContainer = styled.div`
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
