/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {PortDisbursementAccount, PortDisbursementAccountFromJSON, PortDisbursementAccountToJSON} from '../models';

export interface GetDisbursementAccountRequest {
  portUNLoCode: string;
  eta: string;
  imo: number;
  quantity?: number;
}

/**
 *
 */
export class PortDisbursementAccountApi extends runtime.BaseAPI {
  /**
   * For a given vessel, predicts its consumption in certain situations.
   */
  async getDisbursementAccountRaw(
    requestParameters: GetDisbursementAccountRequest
  ): Promise<runtime.ApiResponse<PortDisbursementAccount>> {
    if (requestParameters.portUNLoCode === null || requestParameters.portUNLoCode === undefined) {
      throw new runtime.RequiredError(
        'portUNLoCode',
        'Required parameter requestParameters.portUNLoCode was null or undefined when calling getDisbursementAccount.'
      );
    }

    if (requestParameters.eta === null || requestParameters.eta === undefined) {
      throw new runtime.RequiredError(
        'eta',
        'Required parameter requestParameters.eta was null or undefined when calling getDisbursementAccount.'
      );
    }

    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getDisbursementAccount.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.eta !== undefined) {
      queryParameters['eta'] = requestParameters.eta;
    }

    if (requestParameters.imo !== undefined) {
      queryParameters['imo'] = requestParameters.imo;
    }

    if (requestParameters.quantity !== undefined) {
      queryParameters['quantity'] = requestParameters.quantity;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/port/{portUNLoCode}/disbursement-account`.replace(
        `{${'portUNLoCode'}}`,
        encodeURIComponent(String(requestParameters.portUNLoCode))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortDisbursementAccountFromJSON(jsonValue));
  }

  /**
   * For a given vessel, predicts its consumption in certain situations.
   */
  async getDisbursementAccount(requestParameters: GetDisbursementAccountRequest): Promise<PortDisbursementAccount> {
    const response = await this.getDisbursementAccountRaw(requestParameters);
    return await response.value();
  }
}
