/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PredictionInputVessel,
  PredictionInputVesselFromJSON,
  PredictionInputVesselFromJSONTyped,
  PredictionInputVesselToJSON,
} from './';

/**
 *
 * @export
 * @interface PredictedConsumption
 */
export interface PredictedConsumption {
  /**
   *
   * @type {number}
   * @memberof PredictedConsumption
   */
  consumption: number;
  /**
   *
   * @type {PredictionInputVessel}
   * @memberof PredictedConsumption
   */
  arrangement: PredictionInputVessel;
}

export function PredictedConsumptionFromJSON(json: any): PredictedConsumption {
  return PredictedConsumptionFromJSONTyped(json, false);
}

export function PredictedConsumptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictedConsumption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    consumption: json['consumption'],
    arrangement: PredictionInputVesselFromJSON(json['arrangement']),
  };
}

export function PredictedConsumptionToJSON(value?: PredictedConsumption | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    consumption: value.consumption,
    arrangement: PredictionInputVesselToJSON(value.arrangement),
  };
}
