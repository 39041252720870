/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ModelDate
 */
export interface ModelDate {
  /**
   *
   * @type {string}
   * @memberof ModelDate
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ModelDate
   */
  numeric?: number;
}

export function ModelDateFromJSON(json: any): ModelDate {
  return ModelDateFromJSONTyped(json, false);
}

export function ModelDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelDate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    numeric: !exists(json, 'numeric') ? undefined : json['numeric'],
  };
}

export function ModelDateToJSON(value?: ModelDate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    numeric: value.numeric,
  };
}
