import {Alert, DatePicker} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {CSSProperties, FC, useState} from 'react';
import styled from 'styled-components';
import DateTime from '../../model/DateTime';
import {TODO} from '../../utils/TODO';
import './date-time-picker.scss';
import {DateTimeFormat} from '../../utils/DateTimeFormat';

const DateTimePicker: FC<{
  validateDateOnClickDate?: (date: Dayjs) => TODO;
  onSelect: (date: Dayjs) => void;
  value?: Dayjs;
  timezone?: string;
  id: string;
  showFormat: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  'data-testid'?: string;
}> = props => {
  const [validationError, setValidationError] = useState();

  const _handleDateChange = (newDate: Dayjs) => {
    const dayJsInstance = dayjs(newDate.toISOString());

    setValidationError(props.validateDateOnClickDate?.(dayJsInstance));

    props.onSelect(dayJsInstance);
  };

  const hasError = !!validationError;

  const valueAsDayJs = props.value ? dayjs(props.value.toISOString()) : null;

  return (
    <Container data-testid={props['data-testid']}>
      <DatePicker
        allowClear={false}
        id={props.id}
        value={props.timezone ? valueAsDayJs?.tz(props.timezone) : valueAsDayJs}
        panelRender={(panelNode: TODO) => {
          return (
            <div>
              <div>{panelNode}</div>
              <div>
                {validationError && (
                  <AlertContainer>
                    <Alert style={{fontSize: 11}} type={'error'} message={validationError} />
                  </AlertContainer>
                )}
              </div>
            </div>
          );
        }}
        format={props.showFormat ? DateTime.dateTimeFormat : DateTimeFormat.Date}
        status={hasError ? 'error' : undefined}
        onChange={date => {
          const newDate = props.timezone ? date.tz(props.timezone, true) : date;
          _handleDateChange(newDate);
        }}
        picker="date"
        getPopupContainer={() => {
          return document.getElementById(`${props.id}-panel`)!;
        }}
        showTime={props.showFormat ? {format: 'HH:mm'} : undefined}
        disabled={props.disabled}
        style={props.style}
      />
      <div id={`${props.id}-panel`} />
    </Container>
  );
};

export const DateTimePickerFormItem: FC<{
  onChange?: (date: Dayjs | null) => void;
  value?: Dayjs | null;
  timezone?: string;
  id: string;
  showFormat?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  'data-testid'?: string;
}> = ({value, onChange, timezone, id, showFormat = true, disabled, style, 'data-testid': dataTestId}) => {
  return (
    <DateTimePicker
      id={id}
      timezone={timezone}
      showFormat={showFormat}
      onSelect={(date: TODO) => {
        onChange?.(date);
      }}
      value={value ?? undefined}
      disabled={disabled}
      style={style}
      data-testid={dataTestId}
    />
  );
};

export default DateTimePicker;

const Container = styled.div`
  position: relative;
`;

const AlertContainer = styled.div`
  padding: 12px;
`;
