import {Button, Form} from 'antd';
import dayjs from 'dayjs';
import {FC, useEffect} from 'react';
import {MarketCargo, MarketCargoOffer} from '../../../MarketCargo/MarketCargoOffer';
import {getLocations} from '../utils/getLocations';
import {CargoContainerFormItems} from './CargoContainerFormItems';
import {CargoDryBulkFormItems} from './CargoDryBulkFormItems';
import {CargoFormValues, useUpdateCargoOfferMutation} from './useUpdateCargoOfferMutation';
import {EditCircularFormLayout} from '../shared/EditCircularFormLayout';
import {MailView} from '../shared/MailView';
import {preventDefaultOnEnterPress} from '../../../../../utils/preventDefaultOnEnterPress';

export const CargoForm: FC<{
  cargoOffer: MarketCargoOffer;
  onSuccess: () => void;
}> = ({cargoOffer, onSuccess}) => {
  const isContainer = cargoOffer.cargo.cargoType === 'container';
  const [form] = Form.useForm();

  const initialValues: CargoFormValues = getCargoFormValues(cargoOffer.cargo);

  const updateCargoOfferMutation = useUpdateCargoOfferMutation(
    {cargoOffer},
    {
      onSuccess,
    }
  );

  useEffect(() => {
    // I don't know why, but this is needed to make the form work
    form.setFieldValue('loadLocations', initialValues.loadLocations);
    form.setFieldValue('dischargeLocations', initialValues.dischargeLocations);

    // eslint grumbles because we use initialValues, but it is an aggregate of vesselOffer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoOffer, form]);

  const isSaving = updateCargoOfferMutation.isPending;

  return (
    <Form<CargoFormValues>
      onFinish={values => {
        updateCargoOfferMutation.mutate(values);
      }}
      form={form}
      layout={'vertical'}
      initialValues={initialValues}
      {...preventDefaultOnEnterPress()}>
      <EditCircularFormLayout
        mailView={<MailView offer={cargoOffer} />}
        formItems={
          isContainer ? (
            <CargoContainerFormItems cargoOffer={cargoOffer} form={form} />
          ) : (
            <CargoDryBulkFormItems cargoOffer={cargoOffer} form={form} />
          )
        }
        actionButtons={
          <Button data-testid={'SubmitBtn'} disabled={isSaving} htmlType={'submit'} type={'primary'}>
            Update circular
          </Button>
        }
      />
    </Form>
  );
};

const getCargoFormValues = (cargo: MarketCargo): CargoFormValues => {
  const loadLocations = getLocations({
    stations: cargo.stations,
    stationTypes: ['deliveryvia', 'delivery', 'loading'],
  });
  const laycanFrom = cargo.laycanFrom ? dayjs(cargo.laycanFrom) : null;
  const laycanTo = cargo.laycanTo ? dayjs(cargo.laycanTo) : null;

  const commission = getCommission(cargo);

  const dischargeLocations = getLocations({
    stations: cargo.stations,
    stationTypes: ['redelivery', 'discharge'],
  });

  return {
    ...cargo,
    loadLocations,
    dischargeLocations,
    laycan: [laycanFrom, laycanTo],
    commission,
  };
};

const getCommission = (cargo: MarketCargo) => {
  if (cargo.commissionTotal) {
    return cargo.commissionTotal;
  }
  if (cargo.commissionAddress === null && cargo.commissionBrokerage === null) {
    return null;
  }
  return (cargo.commissionAddress ?? 0) + (cargo.commissionBrokerage ?? 0);
};
