import {Dropdown, Tooltip} from 'antd';
import {FC, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {UserAvatar} from '../../UserAvatar/UserAvatar';
import {useUserMenuItems} from './useUserMenuItems';
import {useUser} from '../../../components/UserContext/useUser';

export const HeaderUserMenu: FC = () => {
  const currentUser = useUser();
  const items = useUserMenuItems();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div data-testid="UserMenu" data-tour-id="UserMenu">
      <Dropdown menu={{items}} trigger={['click']} arrow overlayClassName="header-user-menu inHeader">
        <div onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
          <Tooltip placement="bottom" title={'Control panel'} open={isTooltipOpen}>
            <OpenButton
              $size={32}
              data-testid="HeaderUserMenuOpenButton"
              onClick={() => {
                setIsTooltipOpen(prev => !prev);
              }}>
              <UserAvatar size={30} user={currentUser.payload} />
            </OpenButton>
          </Tooltip>
        </div>
      </Dropdown>
      <UserMenuDropdownStyle />
    </div>
  );
};

const UserMenuDropdownStyle = createGlobalStyle`
  .ant-dropdown.header-user-menu {
    min-width: 200px !important;

    .ant-dropdown-menu-item-group-title, .ant-dropdown-menu-item {
      font-weight: bold;
      padding: 8px 12px;

      &.menu-item-logout {
        padding: 0;
      }

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        color: var(--color-black) !important;
      }

      &:hover {
        background-color: var(--color-gray-5);
      }
    }

    .ant-dropdown-menu-item:not(:last-of-type) {
      border-bottom: var(--border-base);
    }

    .ant-dropdown-menu-item-group {
      padding-bottom: 8px !important;
      border-bottom: var(--border-base);

      .ant-dropdown-menu-item {
        padding: 2px 8px;
        font-weight: normal;
        border-bottom: none;
        border-radius: var(--border-radius);
      }
    }

    .empty-group-header > div {
      display: none;
    }
    .group-header {
      border-bottom: none !important;
    }
  }
`;

const OpenButton = styled.div<{$size: number}>`
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  cursor: pointer;
`;
