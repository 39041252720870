/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  FilterCategory,
  FilterCategoryFromJSON,
  FilterCategoryToJSON,
  FilterItem,
  FilterItemFromJSON,
  FilterItemToJSON,
  FilterItemWrite,
  FilterItemWriteFromJSON,
  FilterItemWriteToJSON,
  FilterListResponse,
  FilterListResponseFromJSON,
  FilterListResponseToJSON,
  FilterType,
  FilterTypeFromJSON,
  FilterTypeToJSON,
} from '../models';

export interface CreateFilterRequest {
  filterItemWrite: FilterItemWrite;
}

export interface DeleteFilterRequest {
  id: number;
}

export interface GetFilterRequest {
  id: number;
}

export interface GetFiltersRequest {
  category: FilterCategory;
  type?: FilterType;
}

export interface UpdateFilterRequest {
  id: number;
  filterItemWrite: FilterItemWrite;
}

/**
 *
 */
export class FilterApi extends runtime.BaseAPI {
  /**
   */
  async createFilterRaw(requestParameters: CreateFilterRequest): Promise<runtime.ApiResponse<FilterItem>> {
    if (requestParameters.filterItemWrite === null || requestParameters.filterItemWrite === undefined) {
      throw new runtime.RequiredError(
        'filterItemWrite',
        'Required parameter requestParameters.filterItemWrite was null or undefined when calling createFilter.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/filters`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FilterItemWriteToJSON(requestParameters.filterItemWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => FilterItemFromJSON(jsonValue));
  }

  /**
   */
  async createFilter(requestParameters: CreateFilterRequest): Promise<FilterItem> {
    const response = await this.createFilterRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async deleteFilterRaw(requestParameters: DeleteFilterRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteFilter.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/filters/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteFilter(requestParameters: DeleteFilterRequest): Promise<void> {
    await this.deleteFilterRaw(requestParameters);
  }

  /**
   */
  async getFilterRaw(requestParameters: GetFilterRequest): Promise<runtime.ApiResponse<FilterItem>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getFilter.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/filters/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => FilterItemFromJSON(jsonValue));
  }

  /**
   */
  async getFilter(requestParameters: GetFilterRequest): Promise<FilterItem> {
    const response = await this.getFilterRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getFiltersRaw(requestParameters: GetFiltersRequest): Promise<runtime.ApiResponse<FilterListResponse>> {
    if (requestParameters.category === null || requestParameters.category === undefined) {
      throw new runtime.RequiredError(
        'category',
        'Required parameter requestParameters.category was null or undefined when calling getFilters.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.category !== undefined) {
      queryParameters['category'] = requestParameters.category;
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/filters`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => FilterListResponseFromJSON(jsonValue));
  }

  /**
   */
  async getFilters(requestParameters: GetFiltersRequest): Promise<FilterListResponse> {
    const response = await this.getFiltersRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateFilterRaw(requestParameters: UpdateFilterRequest): Promise<runtime.ApiResponse<FilterItem>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateFilter.'
      );
    }

    if (requestParameters.filterItemWrite === null || requestParameters.filterItemWrite === undefined) {
      throw new runtime.RequiredError(
        'filterItemWrite',
        'Required parameter requestParameters.filterItemWrite was null or undefined when calling updateFilter.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/filters/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: FilterItemWriteToJSON(requestParameters.filterItemWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => FilterItemFromJSON(jsonValue));
  }

  /**
   */
  async updateFilter(requestParameters: UpdateFilterRequest): Promise<FilterItem> {
    const response = await this.updateFilterRaw(requestParameters);
    return await response.value();
  }
}
