import {FC} from 'react';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import CargoType from '../../components/FilterProvider/Filters/CargoType/CargoType';
import Laycan from '../../components/FilterProvider/Filters/Laycan/Laycan';
import Quantity from '../../components/FilterProvider/Filters/Quantity/Quantity';
import Duration from '../../components/FilterProvider/Filters/Duration/Duration';
import Commodities from '../../components/FilterProvider/Filters/Commodities/Commodities';
import VesselSize from '../../components/FilterProvider/Filters/VesselSize/VesselSize';
import LocationCargo from '../../components/FilterProvider/Filters/LocationCargo/LocationCargo';
import Charterer from '../../components/FilterProvider/Filters/Charterer/Charterer';
import ContractType from '../../components/FilterProvider/Filters/ContractType/ContractType';
import Features from '../../components/FilterProvider/Filters/Features/Features';
import {FilterBar} from '../../components/FilterBar/FilterBar';
import Posted from '../../components/FilterProvider/Filters/Posted/Posted';
import {MarketCargoFilterBranchDefinitions} from '../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';

interface Props {
  cargoFilterProviderApi: FilterProviderApi<typeof MarketCargoFilterBranchDefinitions>;
}

export const MarketCargoFilters: FC<Props> = ({cargoFilterProviderApi}) => (
  <FilterBar
    id="cargoFilters"
    filters={[
      <Charterer filterProviderApi={cargoFilterProviderApi} />,
      <CargoType filterProviderApi={cargoFilterProviderApi} />,
      <ContractType filterProviderApi={cargoFilterProviderApi} />,
      <VesselSize filterProviderApi={cargoFilterProviderApi} />,
      <Quantity filterProviderApi={cargoFilterProviderApi} />,
      <Features filterProviderApi={cargoFilterProviderApi} />,
      <Laycan filterProviderApi={cargoFilterProviderApi} />,
      <Duration filterProviderApi={cargoFilterProviderApi} />,
      <LocationCargo filterProviderApi={cargoFilterProviderApi} />,
      <Commodities filterProviderApi={cargoFilterProviderApi} />,
      <Posted filterProviderApi={cargoFilterProviderApi} type={'cargo'} />,
    ]}
  />
);
