export const isTest = (): boolean => {
  return process.env.NODE_ENV === 'test';
};

export const isLocalDevEnvironment = (): boolean => {
  return window.location.hostname.includes('localhost');
};

type Environment = 'Beta' | 'Staging' | 'Prod' | 'dev' | string;
export const getEnvironment = (): Environment => {
  if (!isLocalDevEnvironment()) {
    const platformEnv = window.PLATFORM_ENV;
    if (!platformEnv) {
      throw new Error('Missing window.PLATFORM_ENV');
    }
    return window.PLATFORM_ENV!;
  }

  const hostname = window.location.hostname;
  const strippedHostname = hostname.replace('.localhost', '');

  if (strippedHostname === 'beta.seabo.com') {
    return 'Beta';
  }
  if (strippedHostname === 'staging.seabo.com') {
    return 'Staging';
  }
  if (strippedHostname === 'app.seabo.com') {
    return 'Prod';
  }
  if (strippedHostname === 'www.seabo.com') {
    return 'Prod';
  }
  if (strippedHostname === 'seabo.com') {
    return 'Prod';
  }
  if (strippedHostname === 'demo.seabo.com') {
    return 'demo';
  }
  if (strippedHostname === 'demo2.seanexxt.com') {
    return 'Demo2';
  }
  if (strippedHostname.endsWith('.integration.seabo.services')) {
    return hostname.replace('.integration.seabo.services', '');
  }
  if (strippedHostname.endsWith('.previews.seabo.services')) {
    const tag = strippedHostname.replace('.previews.seabo.services', '').replace('p-', '');
    return `seabo-${tag}-preview`;
  }
  if (hostname.endsWith('localhost')) {
    return `dev`;
  }
  throw new Error(`Unknown URL Pattern.:"${strippedHostname}"`);
};
