/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationSubjectModification,
  NegotiationSubjectModificationFromJSON,
  NegotiationSubjectModificationFromJSONTyped,
  NegotiationSubjectModificationToJSON,
  NegotiationSubjectStatus,
  NegotiationSubjectStatusFromJSON,
  NegotiationSubjectStatusFromJSONTyped,
  NegotiationSubjectStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationSubjectModificationReport
 */
export interface NegotiationSubjectModificationReport {
  /**
   *
   * @type {string}
   * @memberof NegotiationSubjectModificationReport
   */
  subjectId: string;
  /**
   *
   * @type {string}
   * @memberof NegotiationSubjectModificationReport
   */
  termId: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationSubjectModificationReport
   */
  oldText: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationSubjectModificationReport
   */
  newText: string;
  /**
   *
   * @type {NegotiationSubjectStatus}
   * @memberof NegotiationSubjectModificationReport
   */
  oldStatus: NegotiationSubjectStatus;
  /**
   *
   * @type {NegotiationSubjectStatus}
   * @memberof NegotiationSubjectModificationReport
   */
  newStatus: NegotiationSubjectStatus;
  /**
   * The subject has changed since the previous round.
   * @type {boolean}
   * @memberof NegotiationSubjectModificationReport
   */
  changed: boolean;
  /**
   *
   * @type {NegotiationSubjectModification}
   * @memberof NegotiationSubjectModificationReport
   */
  subjectModification: NegotiationSubjectModification;
  /**
   *
   * @type {Date}
   * @memberof NegotiationSubjectModificationReport
   */
  lastModifiedAt: Date;
}

export function NegotiationSubjectModificationReportFromJSON(json: any): NegotiationSubjectModificationReport {
  return NegotiationSubjectModificationReportFromJSONTyped(json, false);
}

export function NegotiationSubjectModificationReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationSubjectModificationReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subjectId: json['subjectId'],
    termId: json['termId'],
    oldText: json['oldText'],
    newText: json['newText'],
    oldStatus: NegotiationSubjectStatusFromJSON(json['oldStatus']),
    newStatus: NegotiationSubjectStatusFromJSON(json['newStatus']),
    changed: json['changed'],
    subjectModification: NegotiationSubjectModificationFromJSON(json['subjectModification']),
    lastModifiedAt: new Date(json['lastModifiedAt']),
  };
}

export function NegotiationSubjectModificationReportToJSON(value?: NegotiationSubjectModificationReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subjectId: value.subjectId,
    termId: value.termId,
    oldText: value.oldText,
    newText: value.newText,
    oldStatus: NegotiationSubjectStatusToJSON(value.oldStatus),
    newStatus: NegotiationSubjectStatusToJSON(value.newStatus),
    changed: value.changed,
    subjectModification: NegotiationSubjectModificationToJSON(value.subjectModification),
    lastModifiedAt: value.lastModifiedAt.toISOString(),
  };
}
