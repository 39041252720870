import {z} from 'zod';
import {CoordinatesSchema} from '../../../utils/Coordinates';

export const MapPortSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  isLarge: z.boolean(),
  points: z.array(z.array(CoordinatesSchema)).nullable(),
  country: z.string().nullable(),
  coordinates: CoordinatesSchema,
});

export type MapPort = z.infer<typeof MapPortSchema>;

export const GetPortsResponseSchema = z.object({
  data: z.object({
    items: z.array(MapPortSchema),
    totalItems: z.number(),
  }),
});

export type GetPortsResponse = z.infer<typeof GetPortsResponseSchema>;

export type GetPortsResponseData = GetPortsResponse['data'];
