import React, {FC} from 'react';
import {Alert} from 'antd';
import styled from 'styled-components';
import {isValidationError, ValidationError} from '../../api/utils/ValidationError';
import {ValidationProblemViolations} from '../../api/symfony/generated';

/**
 * Displays an error that occured when using the API.
 */
export const ApiErrorMessage: FC<{error: Error; inlineValidationFields?: string[]}> = ({
  error,
  inlineValidationFields = [],
}) => {
  if (!error) {
    return null;
  }

  const message = isValidationError(error) ? (
    <ValidationErrorMessage error={error} inlineValidationFields={inlineValidationFields} />
  ) : (
    error.message
  );
  return <Alert message={message} type="error" data-testid="ApiErrorMessage" />;
};

const ValidationErrorMessage: FC<{error: ValidationError; inlineValidationFields: string[]}> = ({
  error,
  inlineValidationFields,
}) => {
  let violations = error.validationProblem.violations ?? [];
  const ignoredFieldNames = new Set(inlineValidationFields);
  violations = violations.filter(violation => !ignoredFieldNames.has(violation.propertyPath));

  if (violations.length === 0) {
    // The server sent us validation errors and we already show all of them inline.
    // Therefore present a generic message.
    return <>Correct the errors below</>;
  }

  return (
    <ValidationProblemList>
      {violations.map((violation: ValidationProblemViolations, index: number) => (
        <ValidationProblemItem key={index} data-testid="ValidationProblem">
          {violation.title}
        </ValidationProblemItem>
      ))}
    </ValidationProblemList>
  );
};

const ValidationProblemList = styled.ul`
  margin: 0;
  list-style-type: disc;
`;

const ValidationProblemItem = styled.li`
  margin-left: 16px;
`;
