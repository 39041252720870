import {Attribute} from '../../../atoms/Attribute/Attribute';
import {VoyageHeader, OtherContractTypeHeader} from '../../../screens/CargoDetail/CargoDetailHeader/CargoDetailHeader';
import {getStationValue} from './helpers';

export const CargoSection = ({cargo}: $TSFixMe) => {
  if (cargo.contractType === 'vc') {
    return (
      <>
        <VoyageHeader cargo={cargo} tab="xxl" />
        <br />
        <Attribute tab="xxl" label="Loading" value={getStationValue(cargo, 'loading')} />
        <Attribute tab="xxl" label="Discharge" value={getStationValue(cargo, 'discharge')} />
      </>
    );
  }

  return (
    <>
      <OtherContractTypeHeader cargo={cargo} tab="xxl" />
      <br />
      <Attribute tab="xxl" label="Delivery" value={getStationValue(cargo, 'delivery')} />
      <Attribute tab="xxl" label="Redelivery" value={getStationValue(cargo, 'redelivery')} />
    </>
  );
};
