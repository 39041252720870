import {Timeline} from 'antd';
import {blankVoyageRouteOutput, CalcTimeLineRoute, VoyageRoute, VoyageRouteOutput} from '../../VoyageTypes';
import DragHandle from '../../../../../../atoms/ReactSortableHoc/DragHandle';
import {VesselConsumptionMode} from '../../../VesselInformation/VesselTypes';
import styled, {css} from 'styled-components';
import {RouteEditView} from './RouteEditView';
import {RouteStaticView} from './RouteStaticView';
import {useGetMinHeightWithWarnings, WarningsInVoyage} from '../../../Warning/WarningsInVoyage';
import {getWarningAreaForVoyageRoute} from '../../../utils/calcWarnings/calcWarnings';
import {TimelineContent} from '../PointTimelineItem/PointTimelineItem';
import {focusField} from '../../utils/focusField';

export const RouteTimelineItem = (props: {
  id: number;
  item: CalcTimeLineRoute;
  consumptionModes: VesselConsumptionMode[];
  onChangeEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  onChangeRoute: (route: Partial<VoyageRoute>) => void;
}) => {
  const outputItem: VoyageRouteOutput = props.item.outputItem ?? blankVoyageRouteOutput;

  const warningTag = getWarningAreaForVoyageRoute(props.item.indexInOldArray);
  const minHeight = useGetMinHeightWithWarnings({
    area: warningTag,
  });

  const isEditing = props.isEditing;

  const setIsEditing = (isEditing: boolean) => {
    props.onChangeEditing(isEditing);
  };

  if (outputItem === undefined) {
    return (
      <div style={{backgroundColor: 'var(--color-gray-6)', borderRadius: 5, padding: 5, cursor: 'grab'}}>
        <DragHandle />
      </div>
    );
  }

  return (
    <StyledTimelineItem
      $minHeight={minHeight}
      dot={
        <div style={{background: 'var(--color-gray-6)', borderRadius: 5, padding: 5, cursor: 'grab'}}>
          <Dot $isLaden={!outputItem.isBallast} />
        </div>
      }>
      <TimelineDot />
      <WarningsInVoyage
        onClickWarning={item => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          focusField({
            dataFieldId: item.dataFieldId,
            openEditMode: () => setIsEditing(true),
          });
        }}
        area={warningTag}
      />
      <TimelineContent>
        {isEditing ? (
          <RouteEditView
            area={getWarningAreaForVoyageRoute(props.item.indexInOldArray)}
            onDeactivateEditMode={() => setIsEditing(false)}
            {...props}
          />
        ) : (
          <RouteStaticView onActivateEditMode={() => setIsEditing(true)} {...props} />
        )}
      </TimelineContent>
    </StyledTimelineItem>
  );
};

const StyledTimelineItem = styled(Timeline.Item)<{$minHeight?: number}>`
  & > div.ant-timeline-item-content {
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 1px 0px 1px 0px;
    border-color: rgba(0, 0, 0, 0.06);
    border-style: solid;
    background-color: var(--color-gray-6);
  }
  min-height: ${({$minHeight}) => ($minHeight ? `${$minHeight}px` : 'unset')};

  div.ant-card {
    background-color: var(--color-blue-bg);
  }

  .ant-timeline-item-head-blue {
    top: 18px !important;
  }
`;

const Dot = styled.div<{$isLaden: boolean}>`
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  border: 1.5px solid var(--color-blue);
  ${({$isLaden}) =>
    $isLaden
      ? css`
          background-color: var(--color-blue);
        `
      : ''}
`;

// fake dot on timeline
const TimelineDot = styled.div`
  position: absolute;
  left: -52px;
  top: 18px;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: 3px solid transparent;
  border-radius: 50%;
  border-color: #40a2ff;
`;
