/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PortfolioGroupListData,
  PortfolioGroupListDataFromJSON,
  PortfolioGroupListDataFromJSONTyped,
  PortfolioGroupListDataToJSON,
} from './';

/**
 *
 * @export
 * @interface PortfolioGroupList
 */
export interface PortfolioGroupList {
  /**
   *
   * @type {PortfolioGroupListData}
   * @memberof PortfolioGroupList
   */
  data: PortfolioGroupListData;
}

export function PortfolioGroupListFromJSON(json: any): PortfolioGroupList {
  return PortfolioGroupListFromJSONTyped(json, false);
}

export function PortfolioGroupListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioGroupList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: PortfolioGroupListDataFromJSON(json['data']),
  };
}

export function PortfolioGroupListToJSON(value?: PortfolioGroupList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: PortfolioGroupListDataToJSON(value.data),
  };
}
