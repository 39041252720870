import {FC} from 'react';
import {Select} from '../../../../../../antd/components/Select';
import {designSubTypes} from '../../../../../../screens/CargoVesselForm/helper';

export const DesignSubTypePicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={Object.entries(designSubTypes).map(([value, label]) => {
        return {
          label,
          value,
        };
      })}
    />
  );
};
