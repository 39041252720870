import {FC, useState} from 'react';
import {TagSelection} from '../../../../TagSelection/TagSelection';
import {TagsView} from '../../../../TagSelection/TagsView';
import {TODO} from '../../../../../utils/TODO';
import {useAvailableTagsQuery} from '../../../../../queries/useAvailableTagsQuery';
import {useUpdateVesselMutation} from './useUpdateVesselMutation';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import {Tag, TagType} from '../../../../../api/symfony/generated';

interface Props {
  vessel: TODO;
  onChange: () => void;
}

export const VesselTags: FC<Props> = props => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const updateVesselQuery = useUpdateVesselMutation({
    onSuccess: () => props.onChange(),
  });
  const availableTagsQueryResult = useAvailableTagsQuery({type: TagType.Vessel});

  const updateVesselTag = (vessel: TODO, tagsSelected: Tag[]) => {
    const updatedVessel = {...vessel, tags: tagsSelected};
    updateVesselQuery.mutate({id: vessel.id, body: updatedVessel});
  };

  const onChange = (newSelectedTags: Tag[]) => {
    updateVesselTag(props.vessel, newSelectedTags);
  };

  if (!availableTagsQueryResult.isSuccess) {
    return null;
  }

  const sortedSelectedTags = sortTags(getSelectedVesselsTags(props.vessel));
  const sortedAvailableTags = sortTags(availableTagsQueryResult.data.items);

  return (
    <TagSelection
      onOpenChange={open => {
        setIsModalOpen(open);
      }}
      selectedTags={sortedSelectedTags}
      availableTags={sortedAvailableTags}
      onChange={newSelectedTags => onChange(newSelectedTags)}>
      <FlexBox>
        <TagsView modalOpen={isModalOpen} selectedTags={sortedSelectedTags} />
      </FlexBox>
    </TagSelection>
  );
};

const getSelectedVesselsTags = (vessel: TODO): Tag[] => {
  return vessel.tags;
};

const sortTags = (tags: Tag[]): Tag[] => {
  const sortedTags = sortBy(tags, tag => tag.name.toLowerCase());
  return sortedTags;
};

const FlexBox = styled.div`
  display: flex;
  max-width: 100%;
`;
