import {CompositeLayer} from '@deck.gl/core/typed';
import {SingleMarketPortLayer as SingleMarketPortLayerJS} from './SingleMarketPortLayer';
import {AggregateCircularsLayer} from '../AggregateCircularsLayer/AggregateCircularsLayer';
import {TODO} from '../../../../utils/TODO';
import Supercluster from 'supercluster';
import {MapPortWithMarketCirculars} from '../useExtendPortsAndTradingAreasWithCirculars';
import {getClusterPortNames, getPortClusterItemAmount, hasClusterChildren} from './helpers';

const SingleMarketPortLayer = SingleMarketPortLayerJS as TODO;

type Props = {
  singlePortFeatures: Supercluster.PointFeature<MapPortWithMarketCirculars>[];
  clusterFeatures: Supercluster.ClusterFeature<MapPortWithMarketCirculars>[];
  portCluster: Supercluster;
  zoom: number;
  isShowMarketVessels: boolean;
  isShowMarketCargoes: boolean;
};

export class PortClusterLayer extends CompositeLayer<Props> {
  renderLayers() {
    const props: Props = (this as TODO).props as Props;
    return [
      new SingleMarketPortLayer({
        id: 'SingleMarketPortLayer',
        data: props.singlePortFeatures.map(feature => feature.properties),
        isShowMarketVessels: props.isShowMarketVessels,
        isShowMarketCargoes: props.isShowMarketCargoes,
      }),
      new AggregateCircularsLayer({
        data: props.clusterFeatures,
        getPosition: d => {
          return d.geometry.coordinates;
        },
        getSize: 18,
        opacity: 1,
        isShowMarketVessels: props.isShowMarketVessels,
        isShowMarketCargoes: props.isShowMarketCargoes,
        pickable: true,
        visible: true,
        getPixelOffset: [0, -10],

        getItemAmount: object => {
          return getPortClusterItemAmount({cluster: props.portCluster, clusterId: object.properties.cluster_id});
        },
        getPickingInfo: obj => {
          const hasChildCluster = hasClusterChildren({cluster: props.portCluster, clusterId: obj.info.object?.id});
          const portNames = obj.info.object?.id
            ? getClusterPortNames({cluster: props.portCluster, clusterId: obj.info.object.id})
            : 'no name';
          const name = hasChildCluster ? portNames + ', ...' : portNames;
          const properties = obj.info.object?.properties || {};

          return {
            ...obj.info,
            object: {
              ...properties,
              name,
            },
            polygon: undefined,
            noHighlight: true,
            noTooltip: true,
          };
        },
        getPolygonOffset: ({layerIndex}: TODO) => [0, -layerIndex * 10000],
      }),
    ];
  }
}
