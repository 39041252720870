import {FC} from 'react';
import {Notification} from '../../api/symfony/generated';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {ApiError} from '../../api/utils/ApiError';
import {notificationApi} from '../../api/symfony/symfonyApi';
import {invalidateNotificationsQuery} from '../../queries/useNotificationsQuery';
import Icon from '../../atoms/Icon';

export const MarkAsReadButton: FC<{notification: Notification}> = ({notification}) => {
  const queryClient = useQueryClient();
  const acknowledgeMutation = useMutation<void, ApiError, number>({
    mutationFn: id => notificationApi.acknowledgeNotification({id}),
    onSuccess: async () => {
      await invalidateNotificationsQuery(queryClient);
    },
  });

  return (
    <Icon
      className={'notifications-grid__check'}
      onClick={() => {
        acknowledgeMutation.mutate(notification.id);
      }}
      disabled={acknowledgeMutation.isPending || acknowledgeMutation.isSuccess}
      type={'check-mark-line'}
    />
  );
};
