import {useState, FC, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {isApiError} from '../../api/utils/ApiError';
import {NotFound} from '../../atoms/EmptyState/404';
import {LoadingIndicator} from '../../atoms/Loading';
import FormProvider from '../../components/FormProvider';
import {useGetVesselQuery} from '../../queries/useGetVesselQuery';
import {actions as vesselApi} from '../../redux/ApiService/vesselService';
import {PortfolioVessel} from '../../redux/Portfolio';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {showNotification} from '../../utils/notification';
import {TODO} from '../../utils/TODO';
import {prepareForApi} from './prepareForApi';
import {resetVesselErrors} from './resetVesselErrors';
import {RouteParams} from './RouteParams';
import './style.scss';
import {useScrollToFirstError} from './useScrollToFirstError';
import {VesselFormBody} from './VesselFormBody';
import {sectionMapping} from './VesselFormSteps';

const VesselForm: FC<{newVesselFromExternal?: TODO}> = props => {
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const location = useLocation();
  const isEdit = !!params.id;
  const isBackend = location.pathname.includes('backend');

  const vesselId = params.id ? parseInt(params.id) : undefined;

  const vesselQuery = useGetVesselQuery({
    vesselId,
    options: {
      enabled: vesselId !== undefined,
    },
  });

  const vessel = vesselQuery.data;

  useEffect(() => {
    if (vesselQuery.isSuccess && vesselQuery.data) {
      setNotFound(false);
    }
  }, [vesselQuery.isSuccess, vesselQuery.data]);

  const formLoading: boolean = useSelector(state => state.api.vessels.get.loading);
  const formValidationErrors = useSelector(
    state =>
      (state.api.vessels.validate.data &&
        state.api.vessels.validate.data.error &&
        state.api.vessels.validate.data.error.errors) ||
      (state.api.vessels.post.data && state.api.vessels.post.data.error && state.api.vessels.post.data.error.errors) ||
      (state.api.vessels.patch.data &&
        state.api.vessels.patch.data.error &&
        state.api.vessels.patch.data.error.errors) ||
      []
  );

  const dispatch = useDispatch();
  const createVessel = (body: TODO) => dispatch(vesselApi.post({body}));
  const updateVessel = (body: TODO) => dispatch(vesselApi.patch({params: {vesselId: body.id}, body}));

  const [notFound, setNotFound] = useState(false);
  const [vesselTemplate, setVesselTemplate] = useState<PortfolioVessel | null>(null);

  const scrollToFirstError = useScrollToFirstError();

  const onIMOSelect = (vessel: PortfolioVessel) => {
    setVesselTemplate(vessel);
  };

  const handleFormSubmit = async (form: TODO) => {
    try {
      await resetVesselErrors(dispatch);

      if (isBackend) {
        if (location.pathname.includes('add')) {
          await createVessel({...prepareForApi(form), targetType: 'database'});
        } else {
          await updateVessel({...prepareForApi(form), targetType: 'database'});
        }
        await showNotification('Vessel has been saved.');
        navigate(`/backend/vessels`);
      } else if (isEdit) {
        const vessel = await updateVessel(prepareForApi(form));
        await showNotification('Vessel has been saved.');
        navigate(`/vessel/${vessel.id}`);
      } else {
        await createVessel(prepareForApi(form));
        await showNotification('Vessel has been created.');
        navigate('/my-fleet');
      }
    } catch (e: unknown) {
      if (isApiError(e)) {
        scrollToFirstError();
        return;
      }

      throw e;
    }
  };

  if (isEdit) {
    if (!vessel) {
      return null;
    }

    if (formLoading) {
      return <LoadingIndicator />;
    }

    if (notFound) {
      return <NotFound />;
    }
  }

  let formData;

  const isEditingAnExistingVessel = isEdit && vessel;
  if (props.newVesselFromExternal) {
    formData = props.newVesselFromExternal;
  } else {
    if (isEditingAnExistingVessel) {
      formData = vessel;
    } else {
      // Add a new Vessel
      formData = vesselTemplate ?? undefined;
    }
  }

  return (
    <FormProvider
      onFormSubmit={handleFormSubmit}
      sectionMapping={sectionMapping}
      formData={formData}
      formValidationErrors={formValidationErrors}>
      {(formApi: TODO) => (
        <VesselFormBody
          key={vesselId}
          vessel={vessel}
          manual={!!props.newVesselFromExternal}
          formApi={formApi}
          isBackend={isBackend}
          isEdit={isEdit}
          formValidationErrors={formValidationErrors}
          onIMOSelect={onIMOSelect}
        />
      )}
    </FormProvider>
  );
};

export default VesselForm;
