import {sectionMapping} from './VesselFormSteps';
import {TODO} from '../../utils/TODO';

export const prepareForApi = (form: TODO, sections: string[] = Object.keys(sectionMapping)) =>
  Object.keys(form).reduce(
    (toApi, section) =>
      sections.includes(section)
        ? {
            ...toApi,
            ...Object.keys(form[section]).reduce((acc, value) => ({...acc, [value]: form[section][value]}), {}),
          }
        : toApi,
    {}
  );
