import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationTermApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {NegotiationRoundResult} from '../../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useRequestTermRejectionMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {termId: string}>
) =>
  useMutation({
    mutationFn: async ({termId}: {termId: string}) => {
      const result = await negotiationNegotiationTermApi.requestTermRejection({termId});
      return result;
    },
    ...options,
  });
