import {useState, FC} from 'react';
import {Form, Button, Input} from 'antd';
import styled from 'styled-components';
import {ParseMailMutationParams} from '../../screens/dashboard/widgets/ParserWidget/utils/useParseMailMutation';
import Icon from '../../atoms/Icon';

const {TextArea} = Input;

export const ParserForm: FC<{
  onSubmit: (values: ParseMailMutationParams) => void;
  height?: number;
  submitLabel?: string;
  stretchButton?: boolean;
  showBackgroundIcon?: boolean;
}> = ({onSubmit, height, stretchButton, submitLabel = 'Parse', showBackgroundIcon = true}) => {
  const [form] = Form.useForm<ParseMailMutationParams>();
  const [hasValue, setHasValue] = useState(false);

  return (
    <StyledForm
      onFinish={values => {
        onSubmit(values as ParseMailMutationParams);
      }}
      layout={'vertical'}
      form={form}>
      <TextAreaContainer
        name={'text'}
        height={height}
        rules={[
          {
            required: true,
            message: 'Please add a mail text.',
          },
        ]}>
        <StyledParserTextArea
          onChange={() => setHasValue(true)}
          data-testid={'mailTextInput'}
          placeholder="Insert your vessel or cargo circular text here and parse the content to the “My Fleet” or “My Cargoes” page by clicking “parse”"
        />
      </TextAreaContainer>
      <ButtonContainer>
        <StyledButton
          data-testid={'submitButton'}
          htmlType={'submit'}
          type={'primary'}
          block={stretchButton}
          disabled={!hasValue}>
          {submitLabel}
        </StyledButton>
      </ButtonContainer>
      {showBackgroundIcon && <StyledIcon type={'sent'} />}
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px;
`;

const TextAreaContainer = styled(Form.Item)<{height?: number}>`
  position: relative;
  width: 100%;
  margin-bottom: 10px;

  .ant-form-item-control {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: stretch;
    ${({height}) => `height: ${height !== undefined ? height + 'px' : '100%'};`}
  }
  .ant-form-item-control-input {
    height: 100%;
    width: 100%;
    flex: auto;
  }
  .ant-form-item-control-input-content {
    height: 100%;
  }
`;

export const StyledParserTextArea = styled(TextArea)`
  height: 100% !important;
  padding: 10px 15px;
  resize: none;

  &:visited:valid {
    border-color: var(--color-blue);
  }
`;

const ButtonContainer = styled(Form.Item)`
  height: 100%;
  width: 100%;
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: calc(50% + 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  pointer-events: none;
  font-size: 100px !important;
`;
