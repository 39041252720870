import {Button} from 'antd';
import styled from 'styled-components';

export const NoThanksButton = styled(Button)`
  color: var(--color-gray-2);
`;

export const YesPleaseButton = styled(Button)``;

export const StepContainer = styled.div`
  padding: 250px 40px;
  text-align: center;
`;
