import {Dayjs} from 'dayjs';
import {actions as monitorApi, actionTypes as monitorApiTypes} from './ApiService/monitor';
import {AppThunk} from './AppThunk';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {TODO} from '../utils/TODO';
import {ErrorPayload} from './ApiService/ApiService';

type MonitorParameters = {
  query?: string;
  filters: ParserLogFilters;
  pageIndex?: number;
  pageSize?: number;
};

type Status = 1 | 2 | 3 | 4 | 5;

type ParserLogFilters = {
  /**
   * Accepted status values.
   */
  status?: Status[];
  from?: Dayjs;
  to?: Dayjs;
};

interface MonitorBackendState {
  parserEmailList: {
    items: TODO[];
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    query: string;
    filters: ParserLogFilters | null;
  };
  statistics: {
    items: TODO[];
  };
  error: ErrorPayload | null;
  screenLoading: boolean;
}

const initialState: MonitorBackendState = {
  parserEmailList: {
    items: [],
    pageIndex: 1,
    pageSize: 10,
    totalItems: 0,
    query: '',
    filters: null,
  },
  statistics: {
    items: [],
  },
  error: null,
  screenLoading: true,
};

const actionTypes = {
  MONITOR_SET_PARSER_EMAIL_LIST_PARAMS: 'MONITOR_SET_PARSER_EMAIL_LIST_PARAMS',
  MONITOR_SET_QUERY: 'MONITOR_SET_QUERY',
};

export const monitorBackendReducer = (state = initialState, action: ActionWithPayload): MonitorBackendState => {
  const {type, payload} = action;
  switch (type) {
    case monitorApiTypes.getParserEmailList.SUCCESS:
    case monitorApiTypes.getParserEmailListPre.SUCCESS:
      return {
        ...state,
        parserEmailList: {
          ...state.parserEmailList,
          items: payload.data.items,
          totalItems: payload.data.totalItems,
          query: payload.data.searchTerm || '',
          pageIndex: payload.data.totalItems < payload.data.pageSize ? 1 : state.parserEmailList.pageIndex,
        },
        screenLoading: false,
      };
    case actionTypes.MONITOR_SET_PARSER_EMAIL_LIST_PARAMS:
      return ((monitorParameters: MonitorParameters): MonitorBackendState => ({
        ...state,
        parserEmailList: {
          ...state.parserEmailList,
          query: monitorParameters.query || state.parserEmailList.query,
          pageIndex: monitorParameters.pageIndex || state.parserEmailList.pageIndex,
          pageSize: monitorParameters.pageSize || state.parserEmailList.pageSize,
          filters: 'filters' in monitorParameters ? monitorParameters.filters : state.parserEmailList.filters,
        },
      }))(payload);
    case monitorApiTypes.getParserStatistics.SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          items: payload.data.items,
        },
        screenLoading: false,
      };
    case monitorApiTypes.getParserStatistics.ERROR:
    case monitorApiTypes.getParserEmailList.ERROR:
    case monitorApiTypes.getParserEmailListPre.ERROR:
      return {
        ...state,
        error: payload,
        screenLoading: false,
      };
    default:
      return state;
  }
};

export const setParserEmailListParams =
  (params: MonitorParameters): AppThunk =>
  dispatch => {
    dispatch({
      type: actionTypes.MONITOR_SET_PARSER_EMAIL_LIST_PARAMS,
      payload: params,
    });

    if (params.query) {
      dispatch(getParserEmailList());
    } else {
      dispatch(getParserEmailListPre());
    }
  };

const formatFiltersAsQueryParams = (filters: ParserLogFilters | null): Record<string, string> => {
  const queryParams: Record<string, string> = {};
  if (filters) {
    if (filters.status) {
      queryParams['param[status]'] = filters.status.toString();
    }
    if (filters.from) {
      queryParams['param[from]'] = filters.from.startOf('day').toISOString();
    }

    if (filters.to) {
      queryParams['param[to]'] = filters.to.endOf('day').toISOString();
    }
  }

  return queryParams;
};

export const getParserEmailList = (): AppThunk => (dispatch, getState) => {
  const {query, pageIndex, pageSize, filters} = getState().monitorBackend.parserEmailList;

  dispatch(
    monitorApi.getParserEmailList({
      queryParams: {
        indexName: 'parser-messagelogs',
        query,
        pageIndex,
        pageSize,
        ...formatFiltersAsQueryParams(filters),
      },
    })
  );
};

export const getParserEmailListPre = (): AppThunk => (dispatch, getState) => {
  const {pageIndex, pageSize, filters} = getState().monitorBackend.parserEmailList;
  const queryParams = {
    indexName: 'parser-messagelogs',
    pageIndex,
    pageSize,
    ...formatFiltersAsQueryParams(filters),
  };
  dispatch(monitorApi.getParserEmailListPre({queryParams}));
};

export const getParserStatistics = (): AppThunk => dispatch => {
  dispatch(monitorApi.getParserStatistics());
};
