import {ReactNode} from 'react';
import RSelect, {GroupBase, Props} from 'react-select';
import {SelectComponents} from 'react-select/dist/declarations/src/components';
import {ClearIndicator, SearchIndicator} from './Indicators';
import {
  clearIndicator,
  control,
  indicatorsContainer,
  menu,
  menuList,
  option,
  singleValue,
  valueContainer,
} from './styles';

// @ts-expect-error Type 'Element' is not assignable to type 'string'.
interface SelectProps<OptionType> extends Props<OptionType, false, GroupBase<OptionType>> {
  isDisabled?: boolean;
  width?: number | string;
  components?: Partial<SelectComponents<OptionType, false, GroupBase<OptionType>>>;
  hasError?: boolean;
  clearable?: boolean;
  getOptionLabel?: (option: OptionType) => ReactNode;
  isOptionsDisabled?: (option: OptionType) => boolean;
  height?: number | string;
  cacheOptions?: boolean;
  defaultOptions?: boolean | OptionType;
}

export const Select = <OptionType,>({
  id,
  name,
  isDisabled,
  width = undefined,
  components,
  hasError = undefined,
  onChange,
  isSearchable = true,
  getOptionLabel,
  ...props
}: SelectProps<OptionType>) => {
  const _components = {
    IndicatorSeparator: () => null,
    ClearIndicator: ClearIndicator,
  };
  if (isSearchable) {
    (_components as $TSFixMe).DropdownIndicator = SearchIndicator;
  }
  return (
    <RSelect
      id={id}
      isDisabled={isDisabled}
      name={`select-${name}`}
      classNamePrefix={'seabo-react-select'}
      onChange={(value, type) => {
        onChange?.(value, type);
      }}
      // !! react18 - package types only allow string as return. But element is needed in CargoTabPane and works - /projects -> create project
      // @ts-expect-error Type '((option: OptionType) => string | Element) | undefined' is not assignable to type 'GetOptionLabel<OptionType> | undefined'.
      getOptionLabel={getOptionLabel}
      styles={{
        option,
        control: control(width, hasError),
        menu,
        menuList,
        singleValue,
        valueContainer,
        indicatorsContainer,
        clearIndicator,
      }}
      components={{..._components, ...components}}
      isSearchable={isSearchable}
      {...props}
    />
  );
};
export default Select;
