import {RefObject} from 'react';
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver';
import {DOMRectValues} from 'beautiful-react-hooks/useResizeObserver';

/**
 * Returns an element's width if possible.
 */
export const useElementWidth = (ref: RefObject<HTMLElement>): number | undefined => {
  try {
    // eslint doesn't like it when I wrap a hook with try/catch:
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const domRectValues: DOMRectValues | undefined = useResizeObserver(ref);
    return domRectValues?.width;
  } catch (error) {
    // Workaround for error "ResizeObserver loop limit exceeded" - https://seanexxt.atlassian.net/browse/FRIN-4149
    if (error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error('Got Error:', error);
      if (error.message === 'ResizeObserver loop limit exceeded') {
        // This error is not a real issue according to this link:https://stackoverflow.com/a/50387233/1333046
        // > This error means that ResizeObserver was not able to deliver all observations within a single animation frame.
        // > It is benign (your site will not break). – Aleksandar Totic Apr 15 at 3:14
        // So let's suppress it.
        // eslint-disable-next-line no-console
        console.info(`Ignoring error ${error.message}`);
        return undefined;
      }
    }
    throw error;
  }
};
