import {findNearestPointAtTimestamp} from './findNearestPointAtTimestamp';
import {VesselHistoryRoutePoint} from '../../../../queries/useVesselHistoryQuery';

export const getSelectedCoordinates = ({timestamp, route}: {route?: VesselHistoryRoutePoint[]; timestamp?: number}) => {
  if (!timestamp) {
    return undefined;
  }
  if (!route) {
    return undefined;
  }

  const coordinates = findNearestPointAtTimestamp({
    timestamp,
    route,
  }).coordinates;
  return coordinates;
};
