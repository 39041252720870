import React, {useState, FC} from 'react';
import {FastVesselImportParsedValues} from '../ImportExcel';
import {parseIntTS} from '../../../../../utils/parseNumberTS';
import {CorrectResult, useFastVesselImportMapQuery, WrongResult} from './useFastVesselImportMapQuery';
import {TODO} from '../../../../../utils/TODO';
import {CorrectVesselGrid} from './CorrectVesselGrid';
import {Radio} from 'antd';
import {WrongVesselGrid} from './WrongVesselGrid';

type Vessel = TODO;

export const Preview: FC<{
  values: FastVesselImportParsedValues;
  onGoToNextStep: () => void;
}> = ({values, onGoToNextStep}) => {
  const [viewGrid, setViewGrid] = useState<'wrongVessels' | 'correctVessel'>('correctVessel');
  const imos = values.vessels.map(vessel => parseIntTS(vessel[values.predictedImoColumn!]));

  const dataSourceQuery = useFastVesselImportMapQuery({imos});

  const correctVesselResults: Vessel[] = (dataSourceQuery.data ?? []).filter(
    result => result.correctImport
  ) as CorrectResult[];

  const wrongResults: WrongResult[] = (dataSourceQuery.data ?? []).filter(
    result => !result.correctImport
  ) as WrongResult[];

  return (
    <div>
      <Radio.Group
        options={[
          {label: 'Importable vessles', value: 'correctVessel'},
          {label: 'Vessels with problems', value: 'wrongVessels', disabled: wrongResults.length === 0},
        ]}
        onChange={evnent => {
          setViewGrid(evnent.target.value);
        }}
        value={viewGrid}
        optionType="button"
        buttonStyle="solid"
        style={{marginTop: 36, marginBottom: 36}}
      />
      {viewGrid === 'correctVessel' ? (
        <CorrectVesselGrid
          correctVesselResults={correctVesselResults}
          isLoadingQuery={dataSourceQuery.isLoading}
          onSuccess={onGoToNextStep}
        />
      ) : (
        <WrongVesselGrid results={wrongResults} />
      )}
    </div>
  );
};
