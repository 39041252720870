import {StyledTag} from './StyledTag';
import {TradingArea} from '../../../redux/ApiService/MapService/GetTradingAreasResponse';

type Props = {
  tradingArea: TradingArea;
  onClose: () => void;
};

export const TradingAreaTag = (props: Props) => {
  return (
    <StyledTag
      data-testid="countrysPickerSelectTag"
      key={props.tradingArea.id}
      closable
      onClose={() => {
        props.onClose();
      }}>
      {props.tradingArea.name}
    </StyledTag>
  );
};
