import {createContext, useContext} from 'react';
import {ServerPushSocket} from './ServerPushSocket';
import {assert} from '../../utils/assert';

const ServerPushContext = createContext<ServerPushSocket | null>(null);

export const ServerPushSocketProvider = ServerPushContext.Provider;

export const useServerPushSocket = (): ServerPushSocket => {
  const serverPush = useContext(ServerPushContext);
  assert(serverPush);
  return serverPush;
};
