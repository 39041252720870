import {useQuery} from '@tanstack/react-query';
import {similarVesselsApi} from '../../../../api/node-backend/nodeBackendApis';

export const useGetSisterVesselsQuery = ({imo}: {imo: number | null}) => {
  return useQuery({
    queryKey: ['sisterVessels_' + imo],
    queryFn: async () => {
      const sisterVessels = await similarVesselsApi.getSimilarVesselsForImo({
        imo: imo?.toString() || '',
      });
      return sisterVessels.items;
    },
    enabled: imo !== null,
  });
};
