import {Dayjs} from 'dayjs';
import {FilterParams} from '../filterParams';
import {filterToDate} from '../filters/filterToDate';
import {seaVoyageDistanceFilter} from '../filters/seaVoyageDistanceFilter';
import {Step8Result, Step8Vessel} from './step8';
import {StepResult} from './StepResult';

export type Step9Result = StepResult<Step8Vessel>;

/**
 * filter vessels - only vessels whose Sea Voyage fits to the Laycan (upper bound)
 *
 * Now the calculations from (6 and 7) are repeated, but with distances from the Distance Calculation,
 * instead of Euclidean.
 */
export const step9 = ({
  params,
  preResult,
}: {
  preResult: Step8Result;
  params: FilterParams;
  currentTime?: Dayjs;
}): Step9Result => {
  let vesselList = seaVoyageDistanceFilter<Step8Vessel>({
    vessels: preResult.vessels,
    maxDistanceInKM: params.maxDistanceInKM,
  });

  vesselList = filterToDate({resultVessels: vesselList, toDate: params.toDate});

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: 'Arrives in time',
        count: vesselList.length,
        id: 'arrivesInTime',
      },
    ],
  };
};
