/* eslint-disable no-nested-ternary */
import {FC} from 'react';
import styled from 'styled-components';
import {Attribute, AttributeContainer} from '../../../atoms/Attribute/Attribute';
import HorizontalLine from '../../../atoms/HorizontalLine';
import {TODO} from '../../../utils/TODO';

type ProjectOverviewDescriptionProps = {
  attributes?: TODO;
  type?: string;
};

export const ProjectOverviewDescription: FC<ProjectOverviewDescriptionProps> = ({attributes, type}) => {
  return (
    <div className="project-overview-description">
      <HorizontalLine />
      <DescriptionAttributes attributes={attributes} type={type} />
    </div>
  );
};

const DescriptionAttributes = ({attributes, type}: ProjectOverviewDescriptionProps) => {
  switch (type) {
    case 'vc':
      return <AttributesCargoVc attributes={attributes} />;
    case 'tct':
    case 'pc':
    case 'bc':
      return <AttributesCargoOther attributes={attributes} />;
    case 'vessel':
      return <AttributesVessel attributes={attributes} />;
    default:
      return null;
  }
};

const AttributesCargoVc = ({attributes}: {attributes: TODO}) => {
  return (
    <AttributesContainer>
      <div>
        <Attribute tab={'l'} label="Contract type" value={attributes.contractType} />
        <Attribute tab={'l'} label="Commodity" value={attributes.commodity} />
        <Attribute tab={'l'} label="Commission" value={attributes.commission} />
      </div>
      <div>
        <Attribute tab={'l'} label="Laycan" value={attributes.laycan} />
        <Attribute tab={'l'} label="Loading" value={attributes.loadingLocation} />
        <Attribute tab={'l'} label="Discharge" value={attributes.dischargeLocation} />
      </div>
      <div>
        <Attribute tab={'l'} label="Size" value={attributes.size} />
      </div>
    </AttributesContainer>
  );
};

const AttributesCargoOther = ({attributes}: {attributes: TODO}) => {
  return (
    <AttributesContainer>
      <div>
        <Attribute tab={'l'} label="Contract type" value={`${attributes.contractType} (${attributes.duration})`} />
        <Attribute tab={'l'} label="Vessel type" value={attributes.vesselType.toUpperCase()} />
        <Attribute tab={'l'} label="Commission" value={attributes.commission} />
      </div>
      <div>
        <Attribute tab={'l'} label="Laycan" value={attributes.laycan} />
        <Attribute tab={'l'} label="Delivery" value={attributes.delivery} />
        <Attribute tab={'l'} label="Redelivery" value={attributes.redelivery} />
      </div>
      <div>
        <Attribute tab={'l'} label="Minimum size" value={attributes.minSize} />
        <Attribute tab={'l'} label="Maximum size" value={attributes.maxSize} />
        <Attribute tab={'l'} label="Max age" value={attributes.maxYears} />
      </div>
    </AttributesContainer>
  );
};

const AttributesVessel = ({attributes}: {attributes: TODO}) => {
  return (
    <AttributesContainer>
      <div>
        <Attribute tab={'l'} label="Vessel" value={attributes.vessel} />
        {attributes.isContainer ? (
          attributes.teu ? (
            <Attribute tab={'l'} label="Teu" value={attributes.teu} />
          ) : (
            <Attribute tab={'l'} label="Teu14" value={attributes.teu14} />
          )
        ) : (
          <Attribute tab={'l'} label="Size" value={attributes.size} />
        )}
      </div>
      <div>
        <Attribute tab={'l'} label="Date open" value={attributes.dateOpen} />
        <Attribute tab={'l'} label="Location open" value={attributes.locationOpen} />
      </div>
      <div></div>
    </AttributesContainer>
  );
};

const AttributesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  gap: 40px;
  padding: 20px;

  > div {
    flex: 1 1 33%;

    ${AttributeContainer}:last-of-type {
      margin-bottom: 0;
    }
  }
`;
