import {useMemo} from 'react';
import {Timeframe} from '../model/Timeframe';
import {VoyageAnimationPortVisit} from '../model/VoyageAnimationPortVisit';
import dayjs from 'dayjs';
import {PortVisit} from '../../../../../api/node-backend/generated';
import {assert} from '../../../../../utils/assert';

export const useVoyageAnimationPortVisits = ({
  portVisits,
  timeframe,
}: {
  portVisits: PortVisit[];
  timeframe: Timeframe;
}): VoyageAnimationPortVisit[] => {
  const voyageAnimationPortVisits = useMemo((): VoyageAnimationPortVisit[] => {
    const filteredPortVisits = portVisits.filter(portVisit => {
      return dayjs(portVisit.enterTimestamp).isBetween(timeframe.start, timeframe.end, 'second', '[]');
    });

    return filteredPortVisits.map((portVisit, index): VoyageAnimationPortVisit => {
      assert(portVisit);
      const durationDays = portVisit.exitTimestamp
        ? dayjs(portVisit.exitTimestamp).diff(dayjs(portVisit.enterTimestamp), 'day')
        : null;
      return {
        id: index,
        type: 'portvisit',
        name: portVisit.port.name,
        portId: portVisit.port.id,
        enterTimestamp: dayjs(portVisit.enterTimestamp),
        exitTimestamp: portVisit.exitTimestamp ? dayjs(portVisit.exitTimestamp) : null,
        durationDays,
        port: portVisit.port,
      };
    });
  }, [portVisits, timeframe.end, timeframe.start]);

  return voyageAnimationPortVisits;
};
