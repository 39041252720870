import {useEffect} from 'react';
import {ValidationProblemViolations} from '../api/symfony/generated';
import {isValidationError, violationForField} from '../api/utils/ValidationError';
import {FormInstance, RuleObject} from 'antd/es/form';
import {StoreValue} from 'antd/es/form/interface';

type Validator = (rule: RuleObject, value: StoreValue) => Promise<void>;

/**
 * A hook that injects server side validation errors into a Form.item's validation errors.
 *
 * The hook returns a validator that can be use in a <Form.item> rule.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useServerSideValidator = <FormValues extends Record<string, any>>(
  fieldName: string,
  submitError: Error | null | undefined,
  formValuesAtSubmit: FormValues | null,
  form: FormInstance<FormValues>
): Validator => {
  // Every time we get an error on submit, we want to trigger our field's validations
  // so this validation will show.
  useEffect(() => {
    if (submitError) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      form.validateFields([fieldName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitError]);

  // The user hasn't even begun to edit.
  if (!formValuesAtSubmit) {
    return HappyValidator;
  }

  // No server side validation problems.
  if (!submitError || !isValidationError(submitError)) {
    return HappyValidator;
  }

  const violation: ValidationProblemViolations | undefined = violationForField(submitError, fieldName);

  // No server side error for this field.
  if (!violation) {
    return HappyValidator;
  }

  // Here we *do* have a server side validation error for this field.

  const validator = (_rule: RuleObject, value: string | null | undefined) => {
    const oldValueAtSubmit: string = formValuesAtSubmit[fieldName]!;
    const hasUserEditedFieldSinceSubmit = value !== oldValueAtSubmit;
    if (hasUserEditedFieldSinceSubmit) {
      // The user has edited the field since the server checked it.
      // We assume that the user has fixed the problem.
      return Promise.resolve();
    }

    // The user hasn' fixed the problem yet.
    const errorMessage = violation.title;
    return Promise.reject(errorMessage);
  };

  return validator;
};

/**
 * A validator that always validates fine.
 */
export const HappyValidator: Validator = () => Promise.resolve();
