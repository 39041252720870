import React, {FC, ReactNode, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from '../../../../../../redux/react-redux';
import {setMapDetailLastPort, setMapDetailPortPreviewItem} from '../../../../../../redux/MapDetails';
import {ModalActions} from '../../../../../../redux/Modal';
import {ActionMenu} from '../../../../../ActionMenu/ActionMenu';
import {
  DetailCargo,
  DetailVessel,
  isMapPortElement,
  MapDetailItem,
  MapDetailItemType,
  MapVesselElementObject,
} from '../../../utils/types';
import {MenuItem} from '../../../../../../atoms/Menu/MenuItem';
import {StationType} from '../../../../../../api/symfony/generated';
import {Attribute} from '../../../../../../atoms/Attribute/Attribute';
import {CargoHexagon} from '../../../../../../atoms/CargoHexagon/CargoHexagon';
import Flag from '../../../../../../atoms/Flags';
import Cargo from '../../../../../../model/Cargo';
import Station from '../../../../../../model/Station';
import Vessel from '../../../../../../model/Vessel';
import CargoUtil from '../../../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {TBNString} from '../../../../../Placeholder/Placeholder';
import {BoxHalf} from './shared-components';
import Tooltip from '../../../../../../atoms/Tooltip';
import {Icon} from '../../../../../../atoms/Icon';
import extLink from '../../../assets/ext-link.svg';
import {Link} from 'react-router-dom';
import {BallastStatusQueried} from '../../../../../BallastStatus/BallastStatus';
import {EnvironmentFilled, LoadingOutlined} from '@ant-design/icons';
import {useSearchQuery} from '../../../../../SearchPicker/useSearchQuery';
import {showNotification} from '../../../../../../utils/notification';
import capitalize from 'lodash/capitalize';
import {useMapContext} from '../../../../MapContext/MapContext';
import {getItemView} from '../../../utils/itemView';
import {OnMapElementChange} from '../../../utils/utils';
import {NotAvailable} from '../../../../../../utils/NotAvailable';
import {ClonePortfolioEntry} from '../../../../../Modal/ClonePortfolioEntry';
import {assertUnreachable} from '../../../../../../utils/assert';
import {AddToProject} from '../../../../../Modal/AddToProject/AddToProject';

const isVessel = (item: MapDetailItem): item is DetailVessel => {
  return 'imo' in item;
};
const isCargo = (item: MapDetailItem): item is DetailCargo => {
  return 'cargoType' in item;
};

type MapDetailPortAreaListItemProps = {
  item: MapDetailItem;
  columnType: MapDetailItemType;
  onVesselLocationClicked?: OnMapElementChange;
};

export const MapDetailPortAreaListItem: FC<MapDetailPortAreaListItemProps> = ({
  item,
  columnType,
  onVesselLocationClicked,
}) => {
  const dispatch = useDispatch();
  const {portPreviewItem: detailItem} = useSelector(({mapDetails}) => mapDetails);

  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));
  const showProjectModal = () => showModal(<AddToProject type={columnType} id={item.id} />);
  const showPortfolioModal = () => {
    switch (columnType) {
      case 'vessel':
        showModal(<ClonePortfolioEntry type="vessel" vessel={item} />);
        break;
      case 'cargo':
        showModal(<ClonePortfolioEntry type="cargo" cargo={item} />);
        break;
      default:
        assertUnreachable(columnType);
    }
  };

  const setPortAreaDetail = (item: MapDetailItem) => {
    if (detailItem?.id === item.id) {
      return dispatch(setMapDetailPortPreviewItem(null, null));
    }
    dispatch(setMapDetailPortPreviewItem(item, columnType));
  };

  const isCircular = 'offer' in item;

  return (
    <MapDetailPortAreaListItemContainer
      key={item.id}
      onClick={() => setPortAreaDetail(item)}
      $isActive={detailItem?.id === item.id}>
      <ActionMenuContainer onClick={event => event.stopPropagation()}>
        {!!onVesselLocationClicked && isVessel(item) && item.imo && (
          <SwitchToVesselItem imo={item.imo} onVesselLocationClicked={onVesselLocationClicked} />
        )}
        <VesselDetailLink to={`/${columnType}/${item.id}/`} type={columnType} />
        <ActionMenu
          actionIcon={<MoreIcon type="more-vert" />}
          items={[
            {key: 'project', label: <MenuItem label="Add to Project" onClick={showProjectModal} />},
            ...(isCircular
              ? [
                  {
                    key: 'portfolio',
                    label: (
                      <MenuItem
                        key="portfolio"
                        label={`Add to ${columnType === 'vessel' ? 'My Fleet' : 'My Cargoes'}`}
                        onClick={showPortfolioModal}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </ActionMenuContainer>
      {isVessel(item) && <MapDetailPortAreaListVessel vessel={item} />}
      {isCargo(item) && <MapDetailPortAreaListCargo cargo={item} />}
    </MapDetailPortAreaListItemContainer>
  );
};

const SwitchToVesselItem: FC<{imo: number; onVesselLocationClicked: OnMapElementChange}> = ({
  imo,
  onVesselLocationClicked,
}) => {
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const {mapElement} = useSelector(({mapDetails}) => mapDetails);
  const searchQuery = useSearchQuery({
    searchTag: imo.toString(),
    indexNames: 'ais_vessels',
    options: {
      enabled: isClicked,
    },
  });
  if (!isClicked) {
    return (
      <Tooltip title="Go to vessel detail on map" trigger="mouseenter" delay={600} hideDelay={0}>
        <PositionSearchIcon onClick={() => setIsClicked(true)} />
      </Tooltip>
    );
  }
  if (!searchQuery.isSuccess) {
    return <PositionLoadingIcon spin />;
  }
  if (!searchQuery.data?.data?.items.ais_vessels?.items.length) {
    showNotification('Sorry, vessel position could not be found.', 'error');
    return null;
  }
  // we searched for imo, search result must be vessel object
  const element = searchQuery.data.data.items.ais_vessels.items[0].source as MapVesselElementObject;
  // prevent re-renders with vessel as new mapElement
  if (!isMapPortElement(mapElement)) {
    return null;
  }
  dispatch(setMapDetailLastPort(mapElement));
  onVesselLocationClicked!({
    ...element,
    type: 'ais-vessel',
    longitude: element.longitude,
    latitude: element.latitude,
  });
  return null;
};

const VesselDetailLink: FC<{to: string; type: MapDetailItemType}> = ({to, type}) => (
  <Link to={to}>
    <Tooltip title={`Go to ${capitalize(type)} Detail Page`} trigger="mouseenter" delay={600} hideDelay={0}>
      <DetailIcon src={extLink} />
    </Tooltip>
  </Link>
);

const getPortfolioLabel = (type: MapDetailItemType) => (type === 'vessel' ? 'My Fleet' : 'My Cargoes');
const MarketIndicator: FC<{isCircular: boolean; type: MapDetailItemType}> = ({isCircular, type}) => (
  <Tooltip trigger="mouseenter" title={isCircular ? 'Circular' : getPortfolioLabel(type)} delay={1000} hideDelay={0}>
    <MarketIndicatorIcon size="small" type={isCircular ? 'markets' : 'portfolio'} />
  </Tooltip>
);

const MapDetailPortAreaListCargo: FC<{cargo: DetailCargo}> = ({cargo}) => {
  const {contractType, chartererName, cargoType, commodityCategory} = cargo;
  const {
    state: {
      settings: {switches},
    },
  } = useMapContext();
  const itemView = getItemView(switches);
  const isCircular = 'offer' in cargo;
  return (
    <>
      <ItemHeader>
        <ItemTitle>
          <Tooltip title={contractType.toUpperCase()} trigger="mouseenter" position="top">
            <CargoHexagon type={contractType} commodityCategory={commodityCategory} />
          </Tooltip>
          {chartererName || TBNString}
        </ItemTitle>
        <ItemTopInfo>
          <span>
            {itemView.cargoes === 'all' && <MarketIndicator isCircular={isCircular} type="cargo" />}
            {Cargo.getLocationLoadDelShortValue(cargo, true, true)}
          </span>
          <Icon type="arrow-right" size="small" />
          <span>{Cargo.getLocationDischRedelShortValue(cargo, true, true)}</span>
        </ItemTopInfo>
      </ItemHeader>
      <Attributes>
        <AttributeContainer>
          <StyledAttribute tab="xs" small label="sgmnt" value={cargoType?.toUpperCase()} />
          <StyledAttribute tab="xs" small label="cmmdt" value={Cargo.getCommodityCategoryForDetails(cargo)} />
        </AttributeContainer>
        <AttributeContainer>
          <StyledAttribute tab="xs" small label="size" value={Cargo.getSizeValue(cargo, '-')} />
          <StyledAttribute tab="xs" small label="laycan" value={CargoUtil.formatLaycan(cargo)} />
        </AttributeContainer>
      </Attributes>
    </>
  );
};

const MapDetailPortAreaListVessel: FC<{vessel: DetailVessel}> = ({vessel}) => {
  const {imo, countryFlag, name, vesselType, builtYear, teuQuantity, teu14Quantity, dwtSummer, stations, draft} =
    vessel;
  const {
    state: {
      settings: {switches},
    },
  } = useMapContext();
  const itemView = getItemView(switches);
  const isCircular = 'offer' in vessel;
  return (
    <>
      <ItemHeader>
        <ItemTitle>
          <Tooltip title={countryFlag ?? NotAvailable} trigger="mouseenter" position="top">
            <StyledFlag countryCode={countryFlag} showFallbackFlag border />
          </Tooltip>
          <span>{name}</span>
          <BallastStatus imo={imo} draft={draft} type={vesselType} />
        </ItemTitle>
        <ItemTopInfo>
          <span>
            {itemView.vessels === 'all' && <MarketIndicator isCircular={isCircular} type="vessel" />}
            {builtYear} {vesselType?.toUpperCase()}
          </span>
        </ItemTopInfo>
      </ItemHeader>
      <Attributes>
        <AttributeContainer>
          {teuQuantity && <StyledAttribute tab="xs" small label="teu" value={teuQuantity} />}
          {teu14Quantity && <StyledAttribute tab="xs" small label="teu14" value={teu14Quantity} />}
          {dwtSummer && <StyledAttribute tab="xs" small label="dwt" value={dwtSummer} />}
          <StyledAttribute tab="xs" small label="imo" value={imo} />
        </AttributeContainer>
        <AttributeContainer>
          <StyledAttribute
            tab="xs"
            small
            label="lctn"
            value={Station.getLocationByStationType(stations, StationType.Nextopen, {short: true})}
          />
          <StyledAttribute tab="xs" small label="date open" value={Vessel.formatDateOpen(vessel)} />
        </AttributeContainer>
      </Attributes>
    </>
  );
};

const MapDetailPortAreaListItemContainer = styled.div<{$isActive: boolean}>`
  position: relative;
  padding: 5px 10px;
  background: ${({$isActive}) => ($isActive ? 'var(--map-list-item-active-color)' : 'var(--color-white)')};
  border-bottom: var(--border-width) solid var(--border-color-light);
  cursor: pointer;

  &:hover {
    background: ${({$isActive}) =>
      $isActive ? 'var(--map-list-item-active-color)' : 'var(--map-list-item-hover-color)'};
  }
`;

const MarketIndicatorIcon = styled(Icon)`
  position: relative;
  top: 1px;
  margin: 0 5px 0 1px;
  color: var(--color-gray-3) !important;
`;

const PositionSearchIcon = styled(EnvironmentFilled)`
  position: relative;
  top: -1px;
  left: -4px;
  color: var(--color-gray-3);
  &:hover {
    color: var(--color-gray-2);
  }
`;

const PositionLoadingIcon = styled(LoadingOutlined)`
  position: relative;
  top: -1px;
  left: -4px;
  color: var(--color-gray-3);
`;

const DetailIcon = styled.img`
  position: relative;
  top: -3px;
  left: 2px;
  display: inline-block;
  height: 14px;
  width: 16px;
  opacity: 0.5;
  &:hover {
    opacity: 0.8;
  }
`;

const MoreIcon = styled(Icon)`
  font-size: var(--font-size-md);
  color: var(--color-gray-3) !important;
  &:hover {
    color: var(--color-gray-2) !important;
  }
`;

const ActionMenuContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 6px;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const ItemHeader = styled.div`
  padding: 5px 10px;
`;

const ItemTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin: 2px 0;
  line-height: 1.1;
  color: var(--color-gray-1);
  font-weight: bold;

  span {
    display: block;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BallastStatus = styled(BallastStatusQueried)`
  margin-right: 50px;
`;

const StyledFlag = styled(Flag)`
  position: relative;
  top: -0.5px;
`;

const ItemTopInfo = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;

  & > span {
    display: block;
    width: fit-content;
    flex-shrink: 1;
    color: var(--color-gray-2) !important;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    & > *:not(${MarketIndicatorIcon}) {
      color: inherit !important;
    }

    .react-tooltip span {
      color: inherit !important;
    }
  }
`;

const Attributes = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
`;

const AttributeContainer = styled(BoxHalf)`
  flex-wrap: wrap;
  padding: 4px 8px 8px;
  overflow: hidden;

  &:first-of-type {
    flex: 0 0 45% !important;
  }
  &:last-of-type {
    flex: 0 0 55% !important;
  }
`;

const StyledAttribute = styled(Attribute)`
  width: 100%;
`;
