import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {negotiationNegotiationRoundResultApi} from '../../../../api/node-backend/nodeBackendApis';
import {ApiError} from '../../../../api/utils/ApiError';
import {NegotiationRoundResultModification} from '../../../../api/node-backend/generated';

export const GET_ROUND_RESULTS_MODIFICATIONS_QUERY_KEY = (roundResultId?: string) => [
  'negotiationNegotiationRoundResultApi.getRoundResultModifications',
  roundResultId,
];

export const useGetRoundResultModificationsQuery = (
  roundResultId?: string,
  options?: UseQueryOptions<NegotiationRoundResultModification, ApiError>
) =>
  useQuery({
    queryKey: GET_ROUND_RESULTS_MODIFICATIONS_QUERY_KEY(roundResultId),
    queryFn: async () => {
      const result = await negotiationNegotiationRoundResultApi.getRoundResultModifications({
        roundResultId: roundResultId!,
      });
      return result;
    },
    enabled: !!roundResultId,
    refetchOnWindowFocus: true,
    ...options,
  });
