import groupBy from 'lodash/groupBy';
import {VesselType} from '../../../../redux/Market';
import {
  CongestionVisitsWithVesselInformationsResult,
  MergedVisits,
} from './useCongestionVisitsWithVesselInformationsQuery';
import {getMedian} from '../../utils/getMedian';

export const getBaseInformation = (data: CongestionVisitsWithVesselInformationsResult) => {
  const {
    report: {normalPassedVisitsPerDay, passedVisitsLast48h},
    currentVisits,
  } = data;
  const delta = Math.round(normalPassedVisitsPerDay - passedVisitsLast48h / 2);

  const medianWaiting = Math.round((getMedian(currentVisits.map(visit => visit.waitingDuration)) / 24) * 10) / 10;
  const medianPassage = Math.round((getMedian(currentVisits.map(visit => visit.passageDuration)) / 24) * 10) / 10;

  const perTypeGrouped = groupBy(currentVisits, vessel => vessel.vesselType.toLowerCase()) as Record<
    VesselType,
    MergedVisits[]
  >;

  return {
    delta,
    medianWaiting,
    medianPassage,
    total: currentVisits.length,
    ...perTypeGrouped,
  };
};
