import {FC, useEffect} from 'react';
import {Route, Routes, useSearchParams} from 'react-router-dom';
import {NotFound} from '../../atoms/EmptyState/404';
import {GuestLayout} from './GuestLayout';
import {NegotiationDetailGuestScreen} from '../Negotiation/NegotiationDetail/NegotiationDetailGuestScreen';
import {setAccessTokenForApi} from '../../api/utils/apiAccessTokenMiddleware';

export const GuestScreens: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      return;
    }
    setAccessTokenForApi(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token) {
    return <NotFound />;
  }

  return (
    <GuestLayout>
      <Routes>
        <Route path="/negotiations/:negotiationId" element={<NegotiationDetailGuestScreen />} />
      </Routes>
    </GuestLayout>
  );
};
