import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import Radio from '../../../atoms/Radio';
import {TODO} from '../../../utils/TODO';

export type ContractTypeCode = 'vc' | 'tct' | 'pc' | 'bc';

export const contractTypes: Record<ContractTypeCode, string> = {
  vc: 'Voyage charter (VC)',
  tct: 'Time charter trip (TCT)',
  pc: 'Period charter (PC)',
  bc: 'Bareboat charter (BBC)',
};

const Wrapper = BindToProvider(
  'Contract type',
  'contractType',
  {
    contractType: '',
  },
  {},
  {},
  true,
  true,
  true
)(WrappedDetailsSection);

const ContractType = (props: TODO) => {
  const handleContractTypeChange = (value: TODO) => {
    const marketSegment = {...props.form['marketSegment']};
    const contractType = {...props.form['contractType'], contractType: value};

    props.resetForm(() => {
      props.onChange('marketSegment', marketSegment);
      props.onChange('contractType', contractType);
    });
  };

  return (
    <Wrapper {...props}>
      {({values}: TODO) => (
        <div className="container-fluid">
          <div className="row">
            <div className="scol-12">
              {Object.keys(contractTypes).map((type, i) => (
                <Radio
                  key={i}
                  label={<>{contractTypes[type as ContractTypeCode]}</>}
                  checked={values.contractType === type}
                  onChange={() => handleContractTypeChange(type)}
                  id={`form-contract-type-contract-type-${i}`}
                  name={'contractType'}
                  disabled={props.isEdit}
                  dataCy={`contract-type-${type}`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default ContractType;
