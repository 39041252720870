import React, {FC, Fragment, useEffect} from 'react';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import Input from '../../../../atoms/Input';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import autocompleteApi from '../../../../redux/ApiService/autocomplete';
import {
  BulkerDesignType,
  designSubTypes,
  VesselStatus,
  vesselStatusOptions,
  VesselTypeKey,
  vesselTypes,
} from '../../helper';
import {acceptIntOrEmpty} from '../../../../utils/formatter';
import {WrapperProps} from '../../../../components/FormProvider/WrapperProps';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {Wrapper} from './Wrapper';
import {NamedValue} from '../../../../components/FilterProvider/Filters/FilterConversion/utils/NamedValue';
import Select from '../../../../atoms/Select/Select';
import {AsyncCreateable} from '../../../../atoms/Select/AsyncCreateable';
import {InputNumber} from 'antd';
import styled from 'styled-components';
import {TODO} from '../../../../utils/TODO';
import {FlagSelector} from './FlagSelector';

export const General: FC<TODO> = props => {
  const usedVesselType = vesselTypes;
  const {isBackend} = props;
  if (isBackend) {
    usedVesselType['tanker'] = 'Tanker';
    usedVesselType['other'] = 'Other';
  }

  const dispatch = useDispatch();
  const preFillSearch = (indexName: string, page?: number, pageSize?: number) =>
    dispatch(autocompleteApi.preFillSearch(indexName, page, pageSize));
  const preFillData = useSelector(state => state.preFill.data);

  const loadDesignTypeOptions = (query: TODO) => dispatch(autocompleteApi.searchDesignTypes(query));

  useEffect(() => {
    preFillSearch('design_type');
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper {...props}>
      {({onChange, values, validations, validate}: WrapperProps<string, TODO>) => {
        const isNew = !props.isEdit;
        const isVesselTypeOther = props.vessel?.vesselType === null;
        const vesselTypeIsEnable = isNew || isBackend || isVesselTypeOther;
        return (
          <div className="container-fluid">
            <div className="row">
              <div className="scol-12 scol-sm-6">
                <LabelWrapper
                  label={'Vessel type'}
                  htmlFor={'form-general-vessel-type'}
                  hasError={validations.vesselType.invalid}>
                  <Select<NamedValue<VesselTypeKey>>
                    id={'form-general-vessel-type'}
                    name={'form-general-vessel-type'}
                    value={{
                      value: values.vesselType,
                      name: usedVesselType[values.vesselType as VesselTypeKey]!,
                    }}
                    defaultValue={{
                      value: values.vesselType,
                      name: usedVesselType[values.vesselType as VesselTypeKey]!,
                    }}
                    onChange={o => onChange('vesselType', o?.value)}
                    onBlur={() => validate('vesselType')}
                    options={Object.keys(usedVesselType).map(
                      (value): NamedValue<VesselTypeKey> => ({
                        value: value as VesselTypeKey,
                        name: usedVesselType[value as VesselTypeKey]!,
                      })
                    )}
                    getOptionLabel={option => option.name}
                    getOptionValue={(option: {value: TODO}) => option.value}
                    components={{
                      DropdownIndicator: ArrowIndicator,
                    }}
                    isSearchable={false}
                    isDisabled={!vesselTypeIsEnable}
                    hasError={validations.vesselType.invalid}
                  />
                </LabelWrapper>
                {validations.vesselType.error && (
                  <p className="text-danger text-danger--no-margin">{validations.vesselType.error}</p>
                )}
              </div>
              <div className="scol-12 scol-sm-6">
                {values.vesselType && values.vesselType === 'bulker' && (
                  <LabelWrapper label={'Sub type'} htmlFor={'form-general-design-sub-type'}>
                    <Select
                      id={'form-general-design-sub-type'}
                      name={'form-general-design-sub-type'}
                      value={{
                        value: values.designSubType,
                        name: designSubTypes[values.designSubType as BulkerDesignType],
                      }}
                      defaultValue={{
                        value: values.designSubType,
                        name: designSubTypes[values.designSubType as BulkerDesignType],
                      }}
                      onChange={(o: TODO) => onChange('designSubType', (o && o.value) || '')}
                      options={Object.keys(designSubTypes).map(value => ({
                        value,
                        name: designSubTypes[value as BulkerDesignType],
                      }))}
                      getOptionLabel={(option: TODO) => option.name}
                      getOptionValue={(option: TODO) => option.value}
                      components={{
                        DropdownIndicator: ArrowIndicator,
                      }}
                      isSearchable={false}
                      isClearable
                    />
                  </LabelWrapper>
                )}
                {values.vesselType && values.vesselType === 'container' && (
                  <Fragment>
                    <LabelWrapper label={'Design type'} htmlFor={'form-general-design-type'}>
                      <AsyncCreateable
                        id={`form-general-design-type`}
                        name={`form-general-design-type`}
                        value={values.designType}
                        onChange={(value: TODO) => onChange('designType', value)}
                        getOptionLabel={(option: TODO) => option.label}
                        getOptionValue={(option: TODO) => option.label}
                        loadOptions={(q: TODO) => (q.length > 1 ? loadDesignTypeOptions(q) : Promise.resolve([]))}
                        defaultOptions={
                          preFillData.map((p: TODO) => ({
                            value: p.slug,
                            label: p.name,
                          })) || []
                        }
                        isClearable
                        createLabel={(value: TODO) => value}
                        createOptionPosition={'first'}
                        placeholder={'Type to search...'}
                        loadingMessage={() => 'Searching ...'}
                      />
                    </LabelWrapper>
                    {validations.designType.error && (
                      <p className="text-danger text-danger--no-margin">{validations.designType.error}</p>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="row">
              <div className="scol-12 scol-sm-6">
                <LabelWrapper
                  label={'Current name'}
                  required
                  htmlFor={'form-general-name'}
                  hasError={validations.name.invalid}>
                  <Input
                    id={'form-general-name'}
                    data-testid={'vesselNameInput'}
                    onChange={value => onChange('name', value, () => validate('name'))}
                    onBlur={() => validate('name')}
                    value={values.name}
                    hasError={validations.name.invalid}
                    maxLength={127}
                  />
                </LabelWrapper>
                {validations.name.error && (
                  <p className="text-danger text-danger--no-margin">{validations.name.error}</p>
                )}
              </div>
              <div className="scol-12 scol-sm-6">
                <LabelWrapper
                  label={'Charter name'}
                  htmlFor={'form-general-charter-name'}
                  hasError={validations.charterName.invalid}>
                  <Input
                    id={'form-general-charter-name'}
                    onChange={value => onChange('charterName', value)}
                    onKeyUp={() => validate('charterName')}
                    value={values.charterName}
                    hasError={validations.charterName.invalid}
                    maxLength={191}
                  />
                </LabelWrapper>
                {validations.charterName.error && (
                  <p className="text-danger text-danger--no-margin">{validations.charterName.error}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="scol-12 scol-sm-6">
                <LabelWrapper label={'IMO'} htmlFor={'form-general-imo'} hasError={validations.imo.invalid}>
                  <StyledInputNumber
                    id={'form-general-imo'}
                    onChange={value => onChange('imo', value, () => validate('imo'))}
                    onKeyUp={() => validate('imo')}
                    onBlur={() => validate('imo')}
                    value={values.imo}
                    maxLength={8}
                    min={0}
                    max={99999999}
                  />
                </LabelWrapper>
                {validations.imo.error && <p className="text-danger text-danger--no-margin">{validations.imo.error}</p>}
              </div>
              <div className="scol-12 scol-sm-6">
                <LabelWrapper
                  label={'Year built'}
                  htmlFor={'form-general-built-year'}
                  hasError={validations.builtYear.invalid}>
                  <Input
                    id={'form-general-built-year'}
                    data-testid={'builtYearInput'}
                    onChange={v => acceptIntOrEmpty(v as string, v => onChange('builtYear', v))}
                    onKeyUp={() =>
                      (values.builtYear.length === 0 || values.builtYear.length >= 4) && validate('builtYear')
                    }
                    value={values.builtYear}
                    hasError={validations.builtYear.invalid}
                    maxLength={4}
                  />
                </LabelWrapper>
                {validations.builtYear.error && (
                  <p className="text-danger text-danger--no-margin">{validations.builtYear.error}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="scol-12 scol-sm-6">
                <LabelWrapper label={'Flag'} htmlFor={'form-general-country-flag'}>
                  <FlagSelector
                    value={values.countryFlag}
                    onChange={value => {
                      onChange('countryFlag', value);
                    }}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12 scol-sm-6">
                <LabelWrapper
                  label={'Ship builder'}
                  htmlFor={'form-general-ship-builder'}
                  hasError={validations.shipBuilder.invalid}>
                  <Input
                    id={'form-general-ship-builder'}
                    onChange={value => onChange('shipBuilder', value)}
                    onKeyUp={() => validate('shipBuilder')}
                    value={values.shipBuilder}
                    hasError={validations.shipBuilder.invalid}
                    maxLength={127}
                  />
                </LabelWrapper>
                {validations.shipBuilder.error && (
                  <p className="text-danger text-danger--no-margin">{validations.shipBuilder.error}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="scol-12 scol-sm-6">
                <LabelWrapper label={'Status'} htmlFor={'form-general-status'}>
                  <Select
                    id={'form-general-status'}
                    name={'form-general-status'}
                    value={{
                      value: values.status,
                      name: vesselStatusOptions[values.status as VesselStatus],
                    }}
                    defaultValue={{
                      value: values.status,
                      name: vesselStatusOptions[values.status as VesselStatus],
                    }}
                    onChange={(o: TODO) => onChange('status', (o && o.value) || '')}
                    options={Object.keys(vesselStatusOptions).map(value => ({
                      value,
                      name: vesselStatusOptions[value as VesselStatus],
                    }))}
                    getOptionLabel={(option: TODO) => option.name}
                    getOptionValue={(option: TODO) => option.value}
                    components={{
                      DropdownIndicator: ArrowIndicator,
                    }}
                    isSearchable={false}
                    isClearable
                  />
                </LabelWrapper>
              </div>
            </div>
          </div>
        );
      }}
    </Wrapper>
  );
};

export default General;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
