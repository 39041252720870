import {GeoJsonLayer} from '@deck.gl/layers/typed';
import {CompositeLayer} from '@deck.gl/core/typed';
import {Feature, FeatureCollection} from 'geojson';
import {Point, Polygon} from '@turf/helpers';
import {RGBAColor} from '../../../utils/deck.gl/deck.gl-types';
import {TODO} from '../../../utils/TODO';

const makeOneFeatureLayer = ({
  feature,
  index,
  color,
}: {
  feature: Feature<Polygon | Point>;
  index: number;
  color?: RGBAColor;
}) => {
  return new GeoJsonLayer<Feature<Polygon | Point>>({
    data: feature,
    pickable: true,
    stroked: true,
    filled: true,
    extruded: true,
    id: 'mapcard-map-polygon-' + (index ?? 0),
    lineWidthScale: 20,
    lineWidthMinPixels: 2,
    getFillColor: _ => color ?? [160, 160, 180, transparentLvl * 256],
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 0,
  });
};

type Props = {
  polygon: FeatureCollection;
  polygonColor?: RGBAColor;
};

const transparentLvl = 0.4; // 0 = hide | 1 = 100%

export class PolygonLayer extends CompositeLayer<Props> {
  static layerName = 'PolygonLayer';

  renderLayers() {
    const features = this.props.polygon.features;

    return features.map((feature: TODO, index: number) =>
      makeOneFeatureLayer({
        feature,
        index,
        color: this.props.polygonColor,
      })
    );
  }
}
