import React, {FC} from 'react';
import Icon, {IconProps} from '../../../../../../../atoms/Icon';
import {NegotiationTermType} from '../../../../../../../api/node-backend/generated';
import {termTypeIcons} from './termTypeIcons';

type TermTypeIconProps = Omit<IconProps, 'type'> & {
  termType: NegotiationTermType | null;
};

export const TermTypeIcon: FC<TermTypeIconProps> = ({termType, ...iconProps}) => {
  const termTypeIcon = termType ? termTypeIcons[termType] : null;
  return <Icon type={termTypeIcon ?? 'angle-down'} {...iconProps} />;
};
