import {routesApi} from '../../../../api/symfony/symfonyApi';
import {CoordinatesObject} from '../../../../utils/Coordinates';
import {DistanceCalculator} from './voyageChartercalculation/voyageChartercalculation';
import {DistanceCalculation} from '../../../../api/symfony/generated';

export type CalcRoutesPropsRoute = {
  coordinates: {
    start: CoordinatesObject;
    end: CoordinatesObject;
  };
};

export interface CalcRoutesProps {
  routes: Array<CalcRoutesPropsRoute>;
  avoidPiracy?: boolean;
  avoidSeca?: boolean;
  options: DistanceCalculator;
}

export interface CalcRoutesOutput extends DistanceCalculation {
  index: number;
}

export const calcRoutes = async (props: CalcRoutesProps): Promise<Array<CalcRoutesOutput>> => {
  const wait = props.routes.map(async (item, index): Promise<CalcRoutesOutput> => {
    const startLatitude = item.coordinates.start.lat;
    const startLongitude = item.coordinates.start.lon;
    const endLatitude = item.coordinates.end.lat;
    const endLongitude = item.coordinates.end.lon;

    const route = await routesApi.getRouteFromTo({
      startLatitude,
      startLongitude,
      endLatitude,
      endLongitude,
      allowedAreas: {
        panama: props.options.allowPanamaCanal ? 1 : 0,
        suez: props.options.allowSuezCanal ? 1 : 0,
        kiel: props.options.allowKielCanal ? 1 : 0,
      },
      avoidPiracyArea: props.options.avoidPiracyAreas ? 1 : 0,
      avoidSeca: props.options.avoidSECA ? 1 : 0,
    });

    return {
      ...route,
      index,
    };
  });

  return await Promise.all(wait);
};

export default calcRoutes;
