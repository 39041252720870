import React, {FC} from 'react';
import {SpotifindActions, SpotifindPageSizeCookie} from '../../../redux/Spotifind';
import NoResults from '../../../screens/market/EmptyState/NoResults';
import {aisVesselSpotifindGrid} from '../../GridLayout';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {SpotifindVessel} from '../../../screens/Spotifind/useGetSpotifindVesselsQuery';
import {CustomColumnDef, DataGrid} from '../../DataGrid/DataGrid';
import {TODO} from '../../../utils/TODO';
import {useLocalStorage} from '../../../utils/useLocalStorage';

export const SpotifindGrid: FC<{
  vessels: SpotifindVessel[];
  selectedVessel?: SpotifindVessel;
  loading?: boolean;
  onClickRow?: (vessel: SpotifindVessel) => void;
}> = ({vessels, loading, onClickRow, selectedVessel}) => {
  const [, setLocalStoragePageSize] = useLocalStorage(SpotifindPageSizeCookie, 50);

  const {page, pageSize, sorted} = useSelector(state => state.spotifind.gridState);
  const dispatch = useDispatch();

  return (
    <DataGrid<SpotifindVessel>
      className={'datagrid-spotifind'}
      data={vessels}
      manualPagination={false}
      columns={aisVesselSpotifindGrid as CustomColumnDef<SpotifindVessel>[]}
      totalCount={vessels.length}
      page={page}
      zeroBasedPageIndex={false}
      manualSorting={false}
      pageSize={pageSize}
      showJumper
      TbodyComponent={loading || vessels.length ? null : <NoResults type={'vessels'} />}
      responsive
      unroundedTopLeft
      unroundedTopRight
      noHoverStatePointer
      getTrProps={(_: TODO, rowInfo) => {
        let style;
        if (selectedVessel?.imo === rowInfo?.original.imo) {
          style = {backgroundColor: 'var(--color-blue-bg)'};
        }

        return {
          onClick: () => {
            if (rowInfo?.original) {
              onClickRow?.(rowInfo?.original as SpotifindVessel);
            }
          },
          style,
        };
      }}
      sorted={sorted}
      onPageChange={pageIndex => {
        dispatch(SpotifindActions.updateGridState({page: pageIndex}));
      }}
      onSortedChange={sorted => {
        dispatch(SpotifindActions.updateGridState({sorted: [sorted], page: 1}));
      }}
      onPageSizeChange={pageSize => {
        setLocalStoragePageSize(pageSize);
        dispatch(SpotifindActions.updateGridState({pageSize, page: 1}));
      }}
    />
  );
};

export default SpotifindGrid;
