import React, {FC} from 'react';
import {TODO} from '../../../utils/TODO';
import {CircularSender} from './CircularSender';
import {UserDateTime} from '../../../components/DateTime/UserDateTime';
import {Source} from '../../../atoms/Source/Source';
import styled from 'styled-components';

interface Props {
  circular: TODO;
}

export const PostedByContent: FC<Props> = ({circular}) => {
  const isVessel = 'vessel' in circular;

  const circularTypeScore = isVessel
    ? `${circular.vessel && circular.vessel.confidenceScore}`
    : `${circular.cargo && circular.cargo.confidenceScore}`;

  return (
    <PostedByColumn data-cy="cell-posted">
      <CircularSender circular={circular} />
      <MarketGridContentSub>
        <MarketSourceProfileData>
          <Source
            isEmail={circular.isParserOffer}
            score={parseInt(circularTypeScore) || 0}
            type={isVessel ? 'vessel' : 'cargo'}
            offerId={circular.id}
            normalize
          />
          <MarketSourceProfileDataTime>
            <UserDateTime value={circular.createdAt} source={'server'} format={'dateTime'} />
          </MarketSourceProfileDataTime>
        </MarketSourceProfileData>
      </MarketGridContentSub>
    </PostedByColumn>
  );
};

const PostedByColumn = styled.div`
  width: 100%;
`;

const MarketSourceProfileData = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const MarketSourceProfileDataTime = styled.span`
  margin-left: 5px;
`;

const MarketGridContentSub = styled.div`
  margin-bottom: -5px;
  color: var(--color-gray-2);
  font-size: var(--font-size-sm);

  img {
    margin-right: 5px;
  }
`;
