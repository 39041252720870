import {useSelector} from '../../../redux/react-redux';
import {useSyncSidebarModeToLocalStorage} from './sidebarModePersistence';
import {getSidebarMode} from '../../../redux/selectors';
import * as SidebarLayout from './SidebarLayout';
import {SidebarLowerAreaContent} from './SidebarLowerAreaContent';
import {RevalSidebarButton} from './SidebarControls';
import {SidebarUpperAreaContent} from './SidebarUpperAreaContent';

export const Sidebar = () => {
  useSyncSidebarModeToLocalStorage();
  const sidebarMode = useSelector(getSidebarMode);

  if (sidebarMode === 'hidden') {
    return <RevalSidebarButton />;
  }

  return (
    <SidebarLayout.Container dataTourId="Sidebar">
      <SidebarLayout.Layout>
        <SidebarLayout.UpperArea>
          <SidebarUpperAreaContent />
        </SidebarLayout.UpperArea>

        <SidebarLayout.LowerArea>
          <SidebarLowerAreaContent />
        </SidebarLayout.LowerArea>
      </SidebarLayout.Layout>
    </SidebarLayout.Container>
  );
};
