import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {numberBetween, numberBetweenAndGreaterOrEqual} from '../../../../utils/validators';
import {BulkerDesignType, designSubTypes} from '../../../../screens/CargoVesselForm/helper';
import {convertValuesToNamedValues, NamedValue} from '../FilterConversion/utils/NamedValue';
import {convertLabelToSlug, convertToFloat} from '../FilterConversion/utils/FilterConversionUtils';

export type DatabaseType = {
  deadWeight: {
    to?: number;
    from?: number;
  };
  holds: {
    to?: number;
    from?: number;
  };
  hatches: {
    to?: number;
    from?: number;
  };
  teu: {
    to?: number;
    from?: number;
  };
  teu14: {
    to?: number;
    from?: number;
  };
  designs: string[];
  designTypes: string[];
};

interface DesignType {
  label: string;
  value: string;
}

export type InternalType = {
  deadWeightFrom: '' | number;
  deadWeightTo: '' | number;
  designs: NamedValue<string>[];
  designTypes?: DesignType[];
  holdsFrom: '' | number;
  holdsTo: '' | number;
  hatchesFrom: '' | number;
  hatchesTo: '' | number;
  teuFrom: '' | number;
  teuTo: '' | number;
  teu14From: '' | number;
  teu14To: '' | number;
};

export const Defaults: InternalType = {
  deadWeightFrom: '',
  deadWeightTo: '',
  designs: [],
  designTypes: [],
  holdsFrom: '',
  holdsTo: '',
  hatchesFrom: '',
  hatchesTo: '',
  teuFrom: '',
  teuTo: '',
  teu14From: '',
  teu14To: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const availableDesigns = (Object.keys(designSubTypes) as BulkerDesignType[]).map(value => ({
    value,
    name: designSubTypes[value],
  }));
  const designTypes = database.designTypes
    ? database.designTypes.map(vesselDesignType => ({
        value: convertLabelToSlug(vesselDesignType),
        label: vesselDesignType,
      }))
    : [];
  return {
    deadWeightFrom: database.deadWeight.from ?? '',
    deadWeightTo: database.deadWeight.to ?? '',
    holdsFrom: database.holds.from ?? '',
    holdsTo: database.holds.to ?? '',
    hatchesFrom: database.hatches.from ?? '',
    hatchesTo: database.hatches.to ?? '',
    teuFrom: database.teu.from ?? '',
    teuTo: database.teu.to ?? '',
    teu14From: database.teu14.from ?? '',
    teu14To: database.teu14.to ?? '',
    designs: convertValuesToNamedValues(database.designs, availableDesigns),
    designTypes,
  };
};

export const sizesToSizeObject = (params: {from: string | number; to: string | number}) => {
  const from = convertToFloat(params.from);
  const to = convertToFloat(params.to);

  if (from === undefined && to === undefined) {
    return {};
  }
  if (from === undefined) {
    return {to};
  }
  if (to === undefined) {
    return {from};
  }
  return {from, to};
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const designTypes = internal.designTypes ? internal.designTypes.map(designType => designType.label) : [];

  return {
    deadWeight: sizesToSizeObject({
      from: internal.deadWeightFrom,
      to: internal.deadWeightTo,
    }),

    designs: internal.designs ? internal.designs.map(design => design.value) : [],

    // because of freetext
    designTypes,

    holds: sizesToSizeObject({
      from: internal.holdsFrom,
      to: internal.holdsTo,
    }),

    hatches: sizesToSizeObject({
      from: internal.hatchesFrom,
      to: internal.hatchesTo,
    }),

    teu: sizesToSizeObject({
      from: internal.teuFrom,
      to: internal.teuTo,
    }),

    teu14: sizesToSizeObject({
      from: internal.teu14From,
      to: internal.teu14To,
    }),
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Size',
  branch: 'size',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
  validators: {
    deadWeightFrom: numberBetween(0, 999999),
    deadWeightTo: numberBetweenAndGreaterOrEqual(0, 999999, 'deadWeightFrom'),
    holdsFrom: numberBetween(0, 999),
    holdsTo: numberBetweenAndGreaterOrEqual(0, 999, 'holdsFrom'),
    hatchesFrom: numberBetween(0, 999),
    hatchesTo: numberBetweenAndGreaterOrEqual(0, 999, 'hatchesFrom'),
    teuFrom: numberBetween(0, 99999),
    teuTo: numberBetweenAndGreaterOrEqual(0, 99999, 'teuFrom'),
    teu14From: numberBetween(0, 99999),
    teu14To: numberBetweenAndGreaterOrEqual(0, 99999, 'teu14From'),
  },
});
