import sumBy from 'lodash/sumBy';
import React, {useMemo} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {getBucketDayList} from '../../utils/getBucketDayList';
import {OfferStatisticsElement} from './OfferStatistic';
import {UndefinedLabel} from '../UndefinedLabel';

const getValue = ({buckets}: {buckets: OfferBucket[]}) => {
  const bucketsPerDay = getBucketDayList({buckets});

  const numberOfOffers = sumBy(buckets, bucket => bucket.numberOfOffers ?? 0);
  const numberOfDays = Object.keys(bucketsPerDay).length;

  return numberOfOffers / numberOfDays;
};

export const AvgCircularsPerDay = ({buckets}: {buckets: OfferBucket[]}) => {
  const value = useMemo(() => getValue({buckets}), [buckets]);

  return (
    <OfferStatisticsElement
      title={'Average circulars per day'}
      value={value ? `${Math.round(value)}` : UndefinedLabel}
      trend={null}
    />
  );
};
