/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  BulkVesselDesignEnum,
  BulkVesselDesignEnumFromJSON,
  BulkVesselDesignEnumFromJSONTyped,
  BulkVesselDesignEnumToJSON,
  FlagsModeEnum,
  FlagsModeEnumFromJSON,
  FlagsModeEnumFromJSONTyped,
  FlagsModeEnumToJSON,
  IntakeUnitEnum,
  IntakeUnitEnumFromJSON,
  IntakeUnitEnumFromJSONTyped,
  IntakeUnitEnumToJSON,
  VesselTypeEnum,
  VesselTypeEnumFromJSON,
  VesselTypeEnumFromJSONTyped,
  VesselTypeEnumToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselFilters
 */
export interface VesselFilters {
  /**
   *
   * @type {Array<VesselTypeEnum>}
   * @memberof VesselFilters
   */
  vesselTypes?: Array<VesselTypeEnum>;
  /**
   * Name - substring, case insensitive
   * @type {string}
   * @memberof VesselFilters
   */
  name?: string;
  /**
   * Year built - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  minimumAge?: number;
  /**
   * Year built - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  maximumAge?: number;
  /**
   * IMOs - exact match
   * @type {Array<number>}
   * @memberof VesselFilters
   */
  imo?: Array<number>;
  /**
   * Registration country - list of two letter country codes
   * @type {Array<string>}
   * @memberof VesselFilters
   */
  flags?: Array<string>;
  /**
   *
   * @type {FlagsModeEnum}
   * @memberof VesselFilters
   */
  flagsMode?: FlagsModeEnum;
  /**
   * Deadweight tons - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  deadweightFrom?: number;
  /**
   * Deadweight tons - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  deadweightTo?: number;
  /**
   * Holds - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  holdsFrom?: number;
  /**
   * Holds - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  holdsTo?: number;
  /**
   * Matches - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  hatchesFrom?: number;
  /**
   * Matches - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  hatchesTo?: number;
  /**
   * TEU containers - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  teuFrom?: number;
  /**
   * TEU containers - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  teuTo?: number;
  /**
   * TEU14 containers - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  teu14From?: number;
  /**
   * TEU14 containers - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  teu14To?: number;
  /**
   *
   * @type {Array<BulkVesselDesignEnum>}
   * @memberof VesselFilters
   */
  bulkVesselDesigns?: Array<BulkVesselDesignEnum>;
  /**
   * Container vessel designs
   * @type {Array<string>}
   * @memberof VesselFilters
   */
  containerVesselDesigns?: Array<string>;
  /**
   * Gross tonnage - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  grossTonnageFrom?: number;
  /**
   * Gross tonnage - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  grossTonnageTo?: number;
  /**
   * Net tonnage - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  netTonnageFrom?: number;
  /**
   * Net tonnage - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  netTonnageTo?: number;
  /**
   * Length - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  lengthFrom?: number;
  /**
   * Length - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  lengthTo?: number;
  /**
   * Beam - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  beamFrom?: number;
  /**
   * Beam - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  beamTo?: number;
  /**
   * Tonnes per centimeter - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  tonnesPerCentimetreFrom?: number;
  /**
   * Tonnes per centimeter - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  tonnesPerCentimetreTo?: number;
  /**
   * Draft - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  draftFrom?: number;
  /**
   * Draft - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  draftTo?: number;
  /**
   * Grain volume - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  grainVolumeFrom?: number;
  /**
   * Grain volume - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  grainVolumeTo?: number;
  /**
   *
   * @type {IntakeUnitEnum}
   * @memberof VesselFilters
   */
  grainUnit?: IntakeUnitEnum;
  /**
   * Bale volume - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  baleVolumeFrom?: number;
  /**
   * Bale volume - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  baleVolumeTo?: number;
  /**
   *
   * @type {IntakeUnitEnum}
   * @memberof VesselFilters
   */
  baleUnit?: IntakeUnitEnum;
  /**
   * Reefer plugs - minimum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  reeferPlugsFrom?: number;
  /**
   * Reefer plugs - maximum, inclusive
   * @type {number}
   * @memberof VesselFilters
   */
  reeferPlugsTo?: number;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  logFitted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  lakesFitted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  tweenDecks?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  a60Bulkhead?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  co2Fitted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  boxShape?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  openHatch?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  celled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  sternThrusted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  bowThrusted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  iceClass?: boolean;
  /**
   * Whether there is any gear
   * @type {boolean}
   * @memberof VesselFilters
   */
  geared?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  grabFitted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  itfFitted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselFilters
   */
  scrubberFitted?: boolean;
}

export function VesselFiltersFromJSON(json: any): VesselFilters {
  return VesselFiltersFromJSONTyped(json, false);
}

export function VesselFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselFilters {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vesselTypes: !exists(json, 'vesselTypes')
      ? undefined
      : (json['vesselTypes'] as Array<any>).map(VesselTypeEnumFromJSON),
    name: !exists(json, 'name') ? undefined : json['name'],
    minimumAge: !exists(json, 'minimumAge') ? undefined : json['minimumAge'],
    maximumAge: !exists(json, 'maximumAge') ? undefined : json['maximumAge'],
    imo: !exists(json, 'imo') ? undefined : json['imo'],
    flags: !exists(json, 'flags') ? undefined : json['flags'],
    flagsMode: !exists(json, 'flagsMode') ? undefined : FlagsModeEnumFromJSON(json['flagsMode']),
    deadweightFrom: !exists(json, 'deadweightFrom') ? undefined : json['deadweightFrom'],
    deadweightTo: !exists(json, 'deadweightTo') ? undefined : json['deadweightTo'],
    holdsFrom: !exists(json, 'holdsFrom') ? undefined : json['holdsFrom'],
    holdsTo: !exists(json, 'holdsTo') ? undefined : json['holdsTo'],
    hatchesFrom: !exists(json, 'hatchesFrom') ? undefined : json['hatchesFrom'],
    hatchesTo: !exists(json, 'hatchesTo') ? undefined : json['hatchesTo'],
    teuFrom: !exists(json, 'teuFrom') ? undefined : json['teuFrom'],
    teuTo: !exists(json, 'teuTo') ? undefined : json['teuTo'],
    teu14From: !exists(json, 'teu14From') ? undefined : json['teu14From'],
    teu14To: !exists(json, 'teu14To') ? undefined : json['teu14To'],
    bulkVesselDesigns: !exists(json, 'bulkVesselDesigns')
      ? undefined
      : (json['bulkVesselDesigns'] as Array<any>).map(BulkVesselDesignEnumFromJSON),
    containerVesselDesigns: !exists(json, 'containerVesselDesigns') ? undefined : json['containerVesselDesigns'],
    grossTonnageFrom: !exists(json, 'grossTonnageFrom') ? undefined : json['grossTonnageFrom'],
    grossTonnageTo: !exists(json, 'grossTonnageTo') ? undefined : json['grossTonnageTo'],
    netTonnageFrom: !exists(json, 'netTonnageFrom') ? undefined : json['netTonnageFrom'],
    netTonnageTo: !exists(json, 'netTonnageTo') ? undefined : json['netTonnageTo'],
    lengthFrom: !exists(json, 'lengthFrom') ? undefined : json['lengthFrom'],
    lengthTo: !exists(json, 'lengthTo') ? undefined : json['lengthTo'],
    beamFrom: !exists(json, 'beamFrom') ? undefined : json['beamFrom'],
    beamTo: !exists(json, 'beamTo') ? undefined : json['beamTo'],
    tonnesPerCentimetreFrom: !exists(json, 'tonnesPerCentimetreFrom') ? undefined : json['tonnesPerCentimetreFrom'],
    tonnesPerCentimetreTo: !exists(json, 'tonnesPerCentimetreTo') ? undefined : json['tonnesPerCentimetreTo'],
    draftFrom: !exists(json, 'draftFrom') ? undefined : json['draftFrom'],
    draftTo: !exists(json, 'draftTo') ? undefined : json['draftTo'],
    grainVolumeFrom: !exists(json, 'grainVolumeFrom') ? undefined : json['grainVolumeFrom'],
    grainVolumeTo: !exists(json, 'grainVolumeTo') ? undefined : json['grainVolumeTo'],
    grainUnit: !exists(json, 'grainUnit') ? undefined : IntakeUnitEnumFromJSON(json['grainUnit']),
    baleVolumeFrom: !exists(json, 'baleVolumeFrom') ? undefined : json['baleVolumeFrom'],
    baleVolumeTo: !exists(json, 'baleVolumeTo') ? undefined : json['baleVolumeTo'],
    baleUnit: !exists(json, 'baleUnit') ? undefined : IntakeUnitEnumFromJSON(json['baleUnit']),
    reeferPlugsFrom: !exists(json, 'reeferPlugsFrom') ? undefined : json['reeferPlugsFrom'],
    reeferPlugsTo: !exists(json, 'reeferPlugsTo') ? undefined : json['reeferPlugsTo'],
    logFitted: !exists(json, 'logFitted') ? undefined : json['logFitted'],
    lakesFitted: !exists(json, 'lakesFitted') ? undefined : json['lakesFitted'],
    tweenDecks: !exists(json, 'tweenDecks') ? undefined : json['tweenDecks'],
    a60Bulkhead: !exists(json, 'a60Bulkhead') ? undefined : json['a60Bulkhead'],
    co2Fitted: !exists(json, 'co2Fitted') ? undefined : json['co2Fitted'],
    boxShape: !exists(json, 'boxShape') ? undefined : json['boxShape'],
    openHatch: !exists(json, 'openHatch') ? undefined : json['openHatch'],
    celled: !exists(json, 'celled') ? undefined : json['celled'],
    sternThrusted: !exists(json, 'sternThrusted') ? undefined : json['sternThrusted'],
    bowThrusted: !exists(json, 'bowThrusted') ? undefined : json['bowThrusted'],
    iceClass: !exists(json, 'iceClass') ? undefined : json['iceClass'],
    geared: !exists(json, 'geared') ? undefined : json['geared'],
    grabFitted: !exists(json, 'grabFitted') ? undefined : json['grabFitted'],
    itfFitted: !exists(json, 'itfFitted') ? undefined : json['itfFitted'],
    scrubberFitted: !exists(json, 'scrubberFitted') ? undefined : json['scrubberFitted'],
  };
}

export function VesselFiltersToJSON(value?: VesselFilters | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vesselTypes:
      value.vesselTypes === undefined ? undefined : (value.vesselTypes as Array<any>).map(VesselTypeEnumToJSON),
    name: value.name,
    minimumAge: value.minimumAge,
    maximumAge: value.maximumAge,
    imo: value.imo,
    flags: value.flags,
    flagsMode: FlagsModeEnumToJSON(value.flagsMode),
    deadweightFrom: value.deadweightFrom,
    deadweightTo: value.deadweightTo,
    holdsFrom: value.holdsFrom,
    holdsTo: value.holdsTo,
    hatchesFrom: value.hatchesFrom,
    hatchesTo: value.hatchesTo,
    teuFrom: value.teuFrom,
    teuTo: value.teuTo,
    teu14From: value.teu14From,
    teu14To: value.teu14To,
    bulkVesselDesigns:
      value.bulkVesselDesigns === undefined
        ? undefined
        : (value.bulkVesselDesigns as Array<any>).map(BulkVesselDesignEnumToJSON),
    containerVesselDesigns: value.containerVesselDesigns,
    grossTonnageFrom: value.grossTonnageFrom,
    grossTonnageTo: value.grossTonnageTo,
    netTonnageFrom: value.netTonnageFrom,
    netTonnageTo: value.netTonnageTo,
    lengthFrom: value.lengthFrom,
    lengthTo: value.lengthTo,
    beamFrom: value.beamFrom,
    beamTo: value.beamTo,
    tonnesPerCentimetreFrom: value.tonnesPerCentimetreFrom,
    tonnesPerCentimetreTo: value.tonnesPerCentimetreTo,
    draftFrom: value.draftFrom,
    draftTo: value.draftTo,
    grainVolumeFrom: value.grainVolumeFrom,
    grainVolumeTo: value.grainVolumeTo,
    grainUnit: IntakeUnitEnumToJSON(value.grainUnit),
    baleVolumeFrom: value.baleVolumeFrom,
    baleVolumeTo: value.baleVolumeTo,
    baleUnit: IntakeUnitEnumToJSON(value.baleUnit),
    reeferPlugsFrom: value.reeferPlugsFrom,
    reeferPlugsTo: value.reeferPlugsTo,
    logFitted: value.logFitted,
    lakesFitted: value.lakesFitted,
    tweenDecks: value.tweenDecks,
    a60Bulkhead: value.a60Bulkhead,
    co2Fitted: value.co2Fitted,
    boxShape: value.boxShape,
    openHatch: value.openHatch,
    celled: value.celled,
    sternThrusted: value.sternThrusted,
    bowThrusted: value.bowThrusted,
    iceClass: value.iceClass,
    geared: value.geared,
    grabFitted: value.grabFitted,
    itfFitted: value.itfFitted,
    scrubberFitted: value.scrubberFitted,
  };
}
