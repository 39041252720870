import * as Sentry from '@sentry/browser';
import {Middleware, RequestContext, FetchParams} from '../../api/symfony/generated';
import {addHeaderOnContext} from './middlewareAddHeader';

/**
 * This middleware for the generated API sets the 'X-Transaction-ID' header so that we get a correlation id in Sentry.
 *
 * See also https://blog.sentry.io/2019/04/04/trace-errors-through-stack-using-unique-identifiers-in-sentry
 */
export const apiTransactionIdMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    const transactionId = newTransactionId();
    Sentry.configureScope(scope => {
      scope.setTag('transaction_id', transactionId);
    });

    addHeaderOnContext(context, 'X-Transaction-ID', transactionId);
  },
};

export const newTransactionId = () => Math.random().toString(36).substr(2, 9);
