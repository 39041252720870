import z from 'zod';

// Warning!!!
//
// To work on this schema. you must use the test data repo together with GetVesselDetailsResponseSchema.separate-test-data.test.ts
// Please see that test for instructions.
//
//
// This file is duplicated at
//   * frontend/src/api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema.ts
//   * node-backend/src/vessel-db/vessels/controller/GetVesselDetailsResponseSchema.ts
// You must keep the copies in sync.

const VesselTypeSchema = z.enum(['bulker', 'container', 'mpp', 'tanker']);
const VesselTypeWithTankerSchema = z.enum(['bulker', 'container', 'mpp', 'tanker']);
const CoordinatesTupleSchema = z.tuple([z.number(), z.number()]);
const CountryCodeSchema = z.string().length(2);
const CountryObjectSchema = z.object({code: CountryCodeSchema, name: z.string()});
const CompanySchema = z.object({id: z.number(), name: z.string()});
const UserSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  fullName: z.string(),
  avatar: z.string(),
  newsletter: z.boolean().nullable(),
  company: CompanySchema,
});
const FuelTypeSchema = z.enum(['ifo', 'mgo', 'mdo']);
const EngineSchema = z.object({
  mainEngineDesigner: z.string().nullish(),
  engineDesignation: z.string().nullish(),
  mcoHp: z.number().nullish(),
  mcoKw: z.number().nullish(),
  mcoRpm: z.number().nullish(),
});
const LocationPortSchema = z.object({
  type: z.literal('port'),
  id: z.number(),
  name: z.string(),
  code: z.string(),
  country: CountryCodeSchema,
  countryObject: CountryObjectSchema,
  timezone: z.string().nullable(),
  lat: z.number(),
  lon: z.number(),
  coordinates: CoordinatesTupleSchema,
});
const LocationUnknownSchema = z.object({
  type: z.literal('unknown'),
  id: z.number(),
  name: z.string(),
  source: z.string(),
  unknownType: z.string(),
});
const LocationSchema = z.discriminatedUnion('type', [LocationPortSchema, LocationUnknownSchema]);
const LocationForStationSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string().optional(),
  country: CountryCodeSchema.optional(),
  countryObject: CountryObjectSchema.optional(),
  timezone: z.string().nullish(),
  lat: z.number().optional(),
  lon: z.number().optional(),
  coordinates: CoordinatesTupleSchema.optional(),
  type: z.enum(['tradingarea', 'port', 'country', 'unknown', 'collection']),
  aliases: z.array(z.object({id: z.number(), name: z.string()})).optional(),
  details: z.unknown().nullish(),
  polygon: z.array(z.array(CoordinatesTupleSchema)).nullish(),
  tradingArea: z.string().optional(),
  tradingAreaName: z.string().optional(),
  tradingAreaId: z.number().optional(),
  isTiny: z.boolean().optional(),
  isLarge: z.boolean().optional(),
  size: z.string().optional(),
  portType: z.enum(['sea_port', 'river_port']).nullish(),
  pois: z.unknown().optional(),
  important: z.boolean().optional(),
  importantReviewed: z.boolean().optional(),
  draftRestriction: z.unknown().nullish(),
  beamRestriction: z.unknown().nullish(),
  loaRestriction: z.unknown().nullish(),
  dwtRestriction: z.unknown().nullish(),
  airdraftRestriction: z.unknown().nullish(),
  holidays: z.array(z.string()).optional(),
  portTerm: z.unknown().nullish(),
  updatedAt: z.string().optional(),
  createdAt: z.string().optional(),
  contactAddress: z.ostring().nullable(),
  contactPhone: z.ostring().nullable(),
  contactEmail: z.ostring().nullable(),
  contactWebsite: z.ostring().nullable(),
  source: z.string().optional(),
  unknownType: z.string().optional(),
  additionalInfo: z.object({
    infoId: z.number(),
    rate: z.number().nullish(),
    unit: z.string().nullish(),
    terms: z.string().nullish(),
    timeToTurn: z.unknown().nullish(),
    cqd: z.boolean().optional(),
  }),
});
const IntakeSchema = z.object({
  id: z.number().optional(),
  type: z.enum(['teu', 'teu14', 'feu', '45f']),
  quantity: z.number(),
  isHighCube: z.boolean(),
  isEmpty: z.boolean(),
});
const StationSchema = z.object({
  id: z.number(),
  type: z.enum([
    'deliveryvia',
    'discharge',
    'nextopen',
    'loading',
    'redelivery',
    'fixturedelivery',
    'fixtureredelivery',
    'fixturedeliveryvia',
  ]),
  locations: z.array(LocationForStationSchema),
  isRange: z.boolean(),
});
const AreaSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  type: z.enum(['tradingarea']),
});
const TagsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    type: z.enum(['fixture', 'vessel']),
  })
);
const ConsumptionSchema = z.object({
  id: z.number(),
  type: z.enum([
    'ballast',
    'ballasteco',
    'laden',
    'ladeneco',
    'portworking',
    'portidle',
    'designdraft',
    'scantlingdraft',
  ]),
  speed: z.number(),
  fuelConsumption: z.number(),
  fuelType: FuelTypeSchema,
  extraFuelConsumption: z.number().nullable(),
  extraFuelType: FuelTypeSchema.nullable(),
});
const GearSchema = z.object({
  id: z.number(),
  type: z.enum(['gear', 'grab']),
  quantity: z.number(),
  capacity: z.number().nullable(),
  description: z.string().nullable(),
});
const FixtureSchema = z.object({
  id: z.number(),
  fixtureDate: z.string(),
  commissionTotal: z.number().nullable(),
  redeliveryNotice: z.number().nullable(),
  broker: z.string().nullable(),
  buyerName: z.string().nullable(),
  cargo: z.unknown().nullable(),
  dwat: z.number().nullable(),
  isExtension: z.boolean(),
  chartererName: z.string().nullable(),
  designType: z.string().nullable(),
  designSubType: z.string().nullable(),
  isGeared: z.boolean().nullable(),
  isOptionDeclared: z.boolean(),
  laycanFrom: z.string().nullable(),
  tags: TagsSchema,
  laycanTo: z.string().nullable(),
  updatedBy: UserSchema.nullable(),
  remark: z.string().nullable(),
  sellerName: z.string().nullable(),
  source: z.string().nullable(),
  stations: z.array(StationSchema).nullable(),
  contractType: z.enum(['sale', 'tct', 'pc', 'vc', 'bc']).nullable(),
  teuQuantity: z.number().nullable(),
  updatedAt: z.string(),
  user: z.unknown(),
  vessel: z.unknown(),
  vesselName: z.string().nullable(),
  vesselType: VesselTypeSchema,
  company: CompanySchema,
  options: z.array(
    z.object({
      id: z.number(),
      periodMin: z.number().nullable(),
      periodMax: z.number().nullable(),
      periodUnit: z.enum(['months', 'days', 'years', 'weeks', 'month']).nullable(),
      rate: z.number().nullable(),
      rateCurrency: z.string().length(3).nullable(),
      rateTerms: z.enum(['day', 'lumpsum', 'mt', 'cbm', 'teu', 'feu']).nullable(),
      ladenLegsMin: z.number().nullable(),
      ladenLegsMax: z.number().nullable(),
    })
  ),
  createdAt: z.string(),
});
const AisDetailsSchema = z.object({
  mmsi: z.number(),
  imo: z.number(),
  vesselId: z.number().nullable(),
  country: z.string().nullable(),
  course: z.number().nullish(),
  currentPort: LocationPortSchema.nullish(),
  currentArea: AreaSchema.nullable(),
  name: z.string().nullable(),
  postime: z.string(),
  speed: z.number().nullable(),
  statusText: z.string().nullable(),
  teuQuantity: z.number().nullable(),
  teu14Quantity: z.number().nullable(),
  destination: z.string().nullable(),
  destinationPort: z
    .object({
      id: z.number(),
      name: z.string(),
      code: z.string(),
      type: z.enum(['port']),
      country: z.string().nullable(),
      countryObject: CountryObjectSchema.nullable(),
      timezone: z.string().nullable(),
      lat: z.number(),
      lon: z.number(),
      coordinates: CoordinatesTupleSchema,
    })
    .nullable(),
  destinationArea: AreaSchema.nullable(),
  lastArea: AreaSchema.nullable(),
  lastPort: LocationPortSchema.nullable(),
  draught: z.number(),
  dwtSummer: z.number().nullable(),
  heading: z.number().nullable(),
  eta: z.string().nullable(),
  latitude: z.number(),
  longitude: z.number(),
  coordinates: z.string(),
  class: z.enum(['bulker', 'other', 'tanker', 'container', 'mpp']),
  loa: z.number().nullable(),
  beam: z.number().nullable(),
});
const CurrentServiceSchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  carriers: z.array(z.object({id: z.number(), code: z.string(), name: z.string()})),
  vesselAmount: z.number(),
  vesselSizes: z.array(z.object({size: z.number(), type: z.enum(['teu']), amount: z.number()})),
  teuMin: z.number(),
  teuMax: z.number(),
  teuSum: z.number(),
  routeAreas: z.array(z.object({id: z.number(), name: z.string(), code: z.string(), type: z.enum(['tradingarea'])})),
  routeLocations: z.array(LocationSchema),
  updatedAt: z.string(),
  createdAt: z.string(),
  // Recursion: vessels: MyFleetVessel[]
  vessels: z.array(z.unknown()),
});

const BaseVesselSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  exName: z.string().nullable(),
  user: UserSchema.nullable(),
  charterName: z.string().nullable(),
  company: CompanySchema.nullable(),
  callSign: z.string().nullable(),
  imo: z.number().nullable(),
  mmsi: z.number().nullable(),
  builtYear: z.number().nullable(),
  shipBuilder: z.string().nullable(),
  confidenceScore: z.number().nullable(),
  comment: z.string().nullable(),
  nextOpenFrom: z.string().nullable(),
  nextOpenTo: z.string().nullable(),
  dryDockFrom: z.string().nullable(),
  dryDockTo: z.string().nullable(),
  dryDockNote: z.string().nullable(),
  spotState: z.boolean(),
  spotDate: z.string().nullable(),
  designType: z.string().nullable(),
  designSubType: z.string().nullable(),
  tonnesPerCentimetre: z.number().nullable(),
  lengthOverall: z.number().nullable(),
  beam: z.number().nullable(),
  draft: z.number().nullable(),
  holdsAmount: z.number().nullable(),
  hatchesAmount: z.number().nullable(),
  dwtSummer: z.number().nullable(),
  grossTonnage: z.number().nullable(),
  netTonnage: z.number().nullable(),
  grain: z.number().nullable(),
  grainUnit: z.enum(['cbm', 'cft']).nullable(),
  bale: z.number().nullable(),
  baleUnit: z.enum(['cbm', 'cft']).nullable(),
  featureLogFitted: z.boolean().nullable(),
  featureLakesFitted: z.boolean().nullable(),
  featureCo2Fitted: z.boolean().nullable(),
  featureItfFitted: z.boolean().nullable(),
  featureTweenDecks: z.boolean().nullable(),
  featureBoxShape: z.boolean().nullable(),
  featureOpenHatch: z.boolean().nullable(),
  featureA60Bulkhead: z.boolean().nullable(),
  featureCellular: z.boolean().nullable(),
  featureBowThrust: z.boolean().nullable(),
  featureSternThrust: z.boolean().nullable(),
  featureScrubberFitted: z.boolean().nullable(),
  managementHeadOwner: z.string().nullable(),
  managementManagingOwner: z.string().nullable(),
  managementDisponentOwner: z.string().nullable(),
  countryFlag: z.string().nullable(),
  iceClass: z.string().nullable(),
  reeferPlugs: z.number().nullable(),
  status: z.enum(['active', 'scrapped', 'employed', 'yard', 'drydock', 'onorder', 'inlayup']).nullable(),
  fixtureCount: z.number(),
  gears: z.array(GearSchema),
  intakes: z.array(IntakeSchema),
  consumptions: z.array(ConsumptionSchema),
  stations: z.array(StationSchema),
  fixtures: z.array(FixtureSchema),
  selectedFixture: FixtureSchema.nullable(),
  rate: z.number().nullable(),
  rateCurrency: z.string().length(3).nullable(),
  rateTerms: z.string().nullable(),
  redeliveryNotice: z.number().nullable(),
  color: z.number().nullable(),
  documents: z.array(
    z.object({
      uuid: z.string(),
      filename: z.string(),
      fileExtension: z.string(),
      size: z.number(),
      mimeType: z.string(),
      url: z.string(),
      context: z.string(),
      uploaded: z.boolean(),
      assigned: z.boolean(),
      created: z.string(),
      downloadCount: z.number(),
    })
  ),
  images: z.array(
    z.object({
      uuid: z.string(),
      filename: z.string(),
      fileExtension: z.string(),
      size: z.number(),
      mimeType: z.string(),
      url: z.string(),
      context: z.string(),
      uploaded: z.boolean(),
      assigned: z.boolean(),
      created: z.string(),
      downloadCount: z.number(),
    })
  ),
  isArchived: z.boolean(),
  updatedAt: z.string(),
  createdAt: z.string(),
  usesFixtures: z.boolean(),
  aisDetails: AisDetailsSchema.nullish(),
  currentService: CurrentServiceSchema.nullish(),
  engine: EngineSchema.nullish(),
  bowThrusterCount: z.number().nullish(),
  sternThrusterCount: z.number().nullish(),
});

export const VesselDatabaseVesselSchema = BaseVesselSchema.extend({
  target: z.literal('database'),
  vesselType: VesselTypeWithTankerSchema.nullable(),
  displayed: z.boolean(),
  reviewed: z.boolean(),
  source: z.string().optional(),
});

const RelatedOfferSchema = z.object({
  id: z.number(),
  user: UserSchema,
  company: CompanySchema,
  workspace: z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    email: z.string(),
    isDefault: z.boolean(),
    isDisabled: z.boolean(),
    easySharing: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
  }),
  updatedAt: z.string(),
  createdAt: z.string(),
  public: z.boolean(),
  shared: z.boolean(),
  isArchived: z.boolean(),
  originSender: z.string().nullable(),
  vessel: z.object({id: z.number(), name: z.string(), targetType: z.enum(['market'])}),
  sharedWith: z.object({users: z.array(z.unknown())}),
});

export const MyFleetVesselSchema = BaseVesselSchema.extend({
  target: z.literal('portfolio'),
  vesselType: VesselTypeSchema.nullable(),
  portfolioGroups: z.array(z.object({id: z.number(), name: z.string()})),
  tags: TagsSchema,
  updatedBy: UserSchema.nullable(),
  relatedOffers: z.array(RelatedOfferSchema),
});

export const MarketVesselSchema = BaseVesselSchema.extend({
  target: z.literal('market'),
  vesselType: VesselTypeSchema.nullable(),
  offer: z.object({
    id: z.number(),
    user: UserSchema.nullable(),
    company: CompanySchema.nullable(),
    // workspace is null in most cases, but sometimes it is populated and I think that this is bad data.
    workspace: z.unknown(),
    updatedAt: z.string(),
    createdAt: z.string(),
    public: z.boolean(),
    shared: z.boolean(),
    isArchived: z.boolean(),
    originSender: z.string().nullable(),
    vessel: z.object({
      id: z.number(),
      name: z.string(),
      targetType: z.literal('market'),
    }),
    sharedWith: z.object({users: z.array(UserSchema)}),
  }),
});

export const GetVesselDetailsResponseSchema = z.discriminatedUnion('target', [
  VesselDatabaseVesselSchema,
  MyFleetVesselSchema,
  MarketVesselSchema,
]);

export type Gear = z.infer<typeof GearSchema>;
export type Intake = z.infer<typeof IntakeSchema>;
export type Consumption = z.infer<typeof ConsumptionSchema>;
export type VesselType = z.infer<typeof VesselTypeSchema>;
export type VesselTypeWithTanker = z.infer<typeof VesselTypeWithTankerSchema>;
export type AisDetails = z.infer<typeof AisDetailsSchema>;
export type Station = z.infer<typeof StationSchema>;
export type Engine = z.infer<typeof EngineSchema>;
export type VesselDatabaseVessel = z.infer<typeof VesselDatabaseVesselSchema>;
export type MyFleetVessel = z.infer<typeof MyFleetVesselSchema>;
export type MarketVessel = z.infer<typeof MarketVesselSchema>;
export type VesselDetailsVessel = z.infer<typeof GetVesselDetailsResponseSchema>;
