import {FC} from 'react';
import {
  NegotiationSubject,
  NegotiationSubjectModificationReport,
  NegotiationSummary,
} from '../../../../../../../api/node-backend/generated';
import styled from 'styled-components';
import {SubjectActionsHook} from '../../../Subjects/utils/useGetSubjectActionsWithMutations';
import {SubjectItem} from '../../../Subjects/SubjectItem';
import {combineWithTermSubjectModification} from '../../../TermArea/utils';

type TermSubjectsProps = {
  subjects: NegotiationSubject[];
  subjectModificationReports?: NegotiationSubjectModificationReport[];
  negotiation: NegotiationSummary;
  roundCanBeEdited: boolean;
  actionsBusy?: boolean;
  getSubjectActions: SubjectActionsHook;
};

export const TermSubjects: FC<TermSubjectsProps> = ({
  subjects,
  subjectModificationReports,
  negotiation,
  roundCanBeEdited,
  actionsBusy,
  getSubjectActions,
}) => {
  if (!subjects?.length) {
    return null;
  }

  const subjectsWithModifications = subjects.map(combineWithTermSubjectModification(subjectModificationReports));

  return (
    <TermSubjectsContainer>
      {subjectsWithModifications.map(subjectWithModifications => (
        <SubjectItem
          key={subjectWithModifications.subject.id}
          subjectWithModifications={subjectWithModifications}
          negotiation={negotiation}
          roundCanBeEdited={roundCanBeEdited}
          actionsBusy={actionsBusy}
          getSubjectActions={getSubjectActions}
        />
      ))}
    </TermSubjectsContainer>
  );
};

const TermSubjectsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 0 0 42px;
`;
