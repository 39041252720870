/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface HolidayModel
 */
export interface HolidayModel {
  /**
   *
   * @type {number}
   * @memberof HolidayModel
   */
  id?: number | null;
  /**
   *
   * @type {string}
   * @memberof HolidayModel
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof HolidayModel
   */
  dayOfMonth: number;
  /**
   *
   * @type {number}
   * @memberof HolidayModel
   */
  month: number;
}

export function HolidayModelFromJSON(json: any): HolidayModel {
  return HolidayModelFromJSONTyped(json, false);
}

export function HolidayModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidayModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    dayOfMonth: json['dayOfMonth'],
    month: json['month'],
  };
}

export function HolidayModelToJSON(value?: HolidayModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    dayOfMonth: value.dayOfMonth,
    month: value.month,
  };
}
