import Checkbox from '../../../../atoms/Checkbox';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {getErrorMessage} from '../../../../utils/helper';
import {StyledRangePicker} from '../../../RangePicker/StyledRangePicker';
import {filterBranchDefinition} from './DateOpenDefinition';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const DateOpen = ({filterProviderApi}: Props) => {
  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size={'medium'}>
      {({handleChange, handleRangePickerChange, values, validations}) => {
        const fromValue = values.from;
        const toValue = values.to;
        const fromError = getErrorMessage('from', validations);
        const toError = getErrorMessage('to', validations);
        const error = !!fromError || !!toError;
        return (
          <div>
            <div className="row">
              <div className="scol-12">
                <LabelWrapper label="Next open" id={'filter-dateopen-date'} hasError={error}>
                  <StyledRangePicker
                    id={'filter-dateopen-date filter-dateopen-date-to'}
                    format={DateTimeFormat.Date}
                    value={[fromValue, toValue]}
                    getPopupContainer={() => {
                      return document.getElementById('openDateFromTo')!;
                    }}
                    placeholder={['from date', 'to date']}
                    disabled={values.spotOnly}
                    onChange={value => {
                      const [from, to] = [...(value ?? [null, null])];
                      handleRangePickerChange({from, to});
                    }}
                  />
                  <div id="openDateFromTo" />
                  {fromError && <div className={'input-info input-info--hasError'}>{fromError}</div>}
                  {toError && <div className={'input-info input-info--hasError'}>{toError}</div>}
                </LabelWrapper>
              </div>
              <div className="scol-12">
                <Checkbox
                  label={'Include spot vessels'}
                  id={'filter-dateopen-spotstate'}
                  checked={values.spotState}
                  onChange={e => handleChange('spotState', e.target.checked)}
                  disabled={values.spotOnly}
                />
              </div>
            </div>
            <HorizontalLine large topSpace={'large'} />
            <div className="row">
              <div className="scol-12">
                <Checkbox
                  label={'Show only spot vessels'}
                  id={'filter-dateopen-spotonly'}
                  checked={values.spotOnly}
                  onChange={e => handleChange('spotOnly', e.target.checked)}
                />
              </div>
            </div>
          </div>
        );
      }}
    </FilterButton>
  );
};

export default DateOpen;
