/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {ObjectCollection, ObjectCollectionFromJSON, ObjectCollectionFromJSONTyped, ObjectCollectionToJSON} from './';

/**
 *
 * @export
 * @interface ObjectCollectionWrapper
 */
export interface ObjectCollectionWrapper {
  /**
   *
   * @type {ObjectCollection}
   * @memberof ObjectCollectionWrapper
   */
  data: ObjectCollection;
}

export function ObjectCollectionWrapperFromJSON(json: any): ObjectCollectionWrapper {
  return ObjectCollectionWrapperFromJSONTyped(json, false);
}

export function ObjectCollectionWrapperFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectCollectionWrapper {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ObjectCollectionFromJSON(json['data']),
  };
}

export function ObjectCollectionWrapperToJSON(value?: ObjectCollectionWrapper | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ObjectCollectionToJSON(value.data),
  };
}
