import {FC} from 'react';
import styled from 'styled-components';
import {TourStepMediaImage} from '../TourDefinition';

export const TourStepImage: FC<{media: TourStepMediaImage}> = ({media}) => {
  return (
    <ImageContainer>
      <img
        src={media.url}
        style={{
          width: '100%',
        }}
        alt="Tour header"
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
`;
