import React, {useEffect, useMemo} from 'react';
import './distance-calculator-map.scss';
import {Coordinates} from '../../utils/Coordinates';
import {SeaboMap} from '../SeaboMap/SeaboMap';
import {useMapApi} from '../SeaboMap/useMapApi';
import StationsLayer from '../SeaboMap/layers/StationsLayer';
import {MapControlButton} from '../SeaboMap/MapContext/Types';
import {DEFAULT_MAP_STYLE} from '../SeaboMap/const';
import {makePartialMapSwitches} from '../SeaboMap/utils/makePartialMapSwitches';

const DistanceCalculatorMap = ({stations, children, routes}: $TSFixMe) => {
  const positions = useMemo(() => stations.filter((s: $TSFixMe) => s !== null), [stations]);
  const centerPoint = useMemo<Coordinates | undefined>(
    () => (positions.length > 0 ? [parseFloat(positions[0].longitude), parseFloat(positions[0].latitude)] : undefined),
    [positions]
  );
  const mapApi = useMapApi();

  const layers = [new StationsLayer({id: 'stations-Layer', routes, stations: positions})];

  useEffect(() => {
    if (positions.length === 0) {
      return;
    }
    if (Array.isArray(routes) && routes.length > 0) {
      mapApi.fitBounds(routes.reduce((t, r) => [...t, ...r.coords], []));
    } else if (positions.length > 1) {
      mapApi.fitBounds(positions.map((p: $TSFixMe) => [parseFloat(p.longitude), parseFloat(p.latitude)]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, positions]);

  return (
    <div className="distance-calculator-map">
      <SeaboMap
        mapApi={mapApi}
        defaults={'distance_calc'}
        settingIdentifier={'distance_calc'}
        saveMapState={false}
        centerPoint={centerPoint}
        vesselsToVisualize={{data: []}}
        settingsPersistent={false}
        lassoSelectionEnabled={false}
        mapControlButtonsToHide={[MapControlButton.VESSELS, MapControlButton.CARGOES]}
        initialMapSettings={{
          switches: {
            ...makePartialMapSwitches({
              ports: true,
              emissionControlAreas: true,
              highRiskAreas: true,
              tradingAreaNames: false,
            }),
          },
          mapStyle: DEFAULT_MAP_STYLE,
          openWeatherMapLayer: 'noLayer',
        }}
        layers={layers}
        overlays={children}
      />
    </div>
  );
};

export default DistanceCalculatorMap;
