import {FC} from 'react';
import {useVesselDBFindVesselByIdQuery} from '../../../../queries/useVesselDBFindVesselByIdQuery';
import {AddToMyFleetModal} from '../../../../components/Modal/ClonePortfolioEntry/AddToMyFleetModal';
import {makeCloneableVessel} from './makeCloneableVessel';
import {SeaboVessel} from '../../../../api/node-backend/generated';

export const AddFromVesselDBToMyFleetModal: FC<{
  onCancel: () => void;
  onSuccess: () => void;
  vesselDatabaseVesselId: number;
}> = ({onCancel, onSuccess, vesselDatabaseVesselId}) => {
  const query = useVesselDBFindVesselByIdQuery(vesselDatabaseVesselId);
  if (!query.isSuccess) {
    return null;
  }
  const cloneableVessel = makeCloneableVessel(query.data as unknown as SeaboVessel);
  return <AddToMyFleetModal onCancel={onCancel} onSuccess={onSuccess} vessel={cloneableVessel} />;
};
