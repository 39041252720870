import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

export const favIconURL = '/favicon.ico';

const generateImage = (baseURL: string, count: number): Promise<string> => {
  if (count === 0) {
    return Promise.resolve(baseURL);
  }
  return new Promise(resolve => {
    const faviconSize = 48;

    const canvas = document.createElement('canvas');
    canvas.width = faviconSize;
    canvas.height = faviconSize;

    const context = canvas.getContext('2d');
    const img = document.createElement('img');
    img.src = baseURL;
    if (context === null) {
      resolve(baseURL);
    }

    img.onload = () => {
      // Draw Original Favicon as Background
      context!.drawImage(img, 0, 0, faviconSize, faviconSize);

      // Draw Notification Circle
      context!.beginPath();
      context!.arc(canvas.width - faviconSize / 3, canvas.height - faviconSize / 3, faviconSize / 3, 0, 2 * Math.PI);
      context!.fillStyle = '#FF0000';
      context!.fill();

      // Draw Notification Number
      context!.font = '24px "Akkurat"';
      context!.textAlign = 'center';
      context!.textBaseline = 'middle';
      context!.fillStyle = 'var(--color-white)';
      context!.fillText(count.toString(), canvas.width - faviconSize / 3, canvas.height - faviconSize / 3);

      // Replace favicon
      resolve(canvas.toDataURL('image/png'));
    };
  });
};

type Props = {
  count: number;
};
export const FavIcon = (props: Props) => {
  const [favIcon, setFavIcon] = useState(favIconURL);

  const {count} = props;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    generateImage(favIconURL, count).then(imageURL => {
      setFavIcon(imageURL);
    });
  }, [count]);

  return (
    <Helmet>
      <link href={favIcon} rel="icon" id="favicon" type="image/x-icon" />
    </Helmet>
  );
};
