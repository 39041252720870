import {AreaType, GetCongestionLightReportRequest} from '../../../api/node-backend/generated';
import {useGetCongestionLightReport} from '../../../queries/useGetCongestionLightReport';

export const CurrentAveragePortstay = ({portId}: {portId?: number}) => {
  const queryRequest: GetCongestionLightReportRequest = {
    areaId: portId!,
    areaType: AreaType.Port,
  };

  const historicPortCongestionQuery = useGetCongestionLightReport(queryRequest, {
    enabled: !!portId,
  });

  if (!historicPortCongestionQuery.isSuccess) {
    return null;
  }

  const data = historicPortCongestionQuery.data;
  if (!data.avgDurationLast10Vessels) {
    return null;
  }

  const avgPortstayLast10VesselsInDays = (data.avgDurationLast10Vessels ?? 0) / (60 * 24);
  return <span>{avgPortstayLast10VesselsInDays.toFixed(2)} days</span>;
};
