import {Coordinates} from '../../../utils/Coordinates';
import {useState} from 'react';
import {ScatterplotLayer} from '@deck.gl/layers/typed';
import {TODO} from '../../../utils/TODO';
import useInterval from 'beautiful-react-hooks/useInterval';
import {RGBAColor} from '../../../utils/deck.gl/deck.gl-types';

export const useSelectedLayer = ({items, color}: {items: {coordinates: Coordinates}[]; color: RGBAColor}) => {
  const radiusMinPixels = 3000;
  const radiusMaxPixels = 5000;
  const duration = 500;

  const value = useUpAndDown({
    radiusMinPixels,
    radiusMaxPixels,
    duration,
    isEnabled: items.length > 0,
  });

  return new ScatterplotLayer({
    id: 'scatterplot-layer',
    data: items,
    pickable: true,
    opacity: 0.8,
    stroked: true,
    filled: true,
    radiusScale: 10,
    lineWidthMinPixels: 1,
    getPosition: (d: TODO) => d.coordinates,
    getRadius: () => value,
    getFillColor: () => color,
    getLineColor: [0, 0, 0, 0],
    transitions: {
      getRadius: {
        type: 'interpolation',
        duration,
      },
    },
  });
};

const useUpAndDown = ({
  radiusMinPixels,
  radiusMaxPixels,
  duration,
  isEnabled,
}: {
  radiusMinPixels: number;
  radiusMaxPixels: number;
  duration: number;
  isEnabled: boolean;
}) => {
  const [value, setValue] = useState<number>(radiusMinPixels);
  useInterval(() => {
    if (!isEnabled) {
      return;
    }
    setValue(value === radiusMinPixels ? radiusMaxPixels : radiusMinPixels);
  }, duration);
  return value;
};
