import {
  CreateNegotiationOperationRequest,
  NegotiationParty,
  NegotiationRoundResult,
  NegotiationSummary,
} from '../../../../../../api/node-backend/generated';

export const getNegotiationCreateValues = (
  negotiation: NegotiationSummary,
  roundResult: NegotiationRoundResult
): CreateNegotiationOperationRequest => {
  const counterparty = negotiation.party === NegotiationParty.Creator ? negotiation.counterpart : negotiation.creator;

  return {
    createNegotiationRequest: {
      title: negotiation.title ?? undefined,
      privateNote: negotiation.privateNote ?? undefined,
      vessel: {
        id: negotiation.vessel.id ?? undefined,
        name: negotiation.vessel.name ?? undefined,
        imo: negotiation.vessel.imo ?? undefined,
        type: negotiation.vessel.type ?? undefined,
        size: negotiation.vessel.size ?? undefined,
        sizeUnit: negotiation.vessel.sizeUnit ?? undefined,
        dateOpen: negotiation.vessel.dateOpen ?? undefined,
        locationOpenLabel: negotiation.vessel.locationOpenLabel ?? undefined,
        locationOpenCountryCode: negotiation.vessel.locationOpenCountryCode ?? undefined,
      },
      cargo: {
        id: negotiation.cargo.id ?? undefined,
        commodityCategory: negotiation.cargo.commodityCategory ?? undefined,
        contractType: negotiation.cargo.contractType ?? undefined,
        commodityType: negotiation.cargo.commodityType ?? undefined,
        quantityMin: negotiation.cargo.quantityMin ?? undefined,
        quantityMax: negotiation.cargo.quantityMax ?? undefined,
        quantityUnit: negotiation.cargo.quantityUnit ?? undefined,
        laycanFrom: negotiation.cargo.laycanFrom ?? undefined,
        laycanTo: negotiation.cargo.laycanTo ?? undefined,
        loadingLabel: negotiation.cargo.loadingLabel ?? undefined,
        loadingCountryCode: negotiation.cargo.loadingCountryCode ?? undefined,
        dischargeLabel: negotiation.cargo.dischargeLabel ?? undefined,
        dischargeCountryCode: negotiation.cargo.dischargeCountryCode ?? undefined,
      },
      counterpart: {
        name: counterparty.name ?? undefined,
        email: counterparty.email ?? undefined,
        company: counterparty.companyName ?? undefined,
      },
      terms: roundResult.terms.map(term => term.text),
    },
  };
};
