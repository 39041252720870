import {LineLayer} from '@deck.gl/layers/typed';
import {AnimationEvent} from '../../model/AnimationEvent';

type Props = {
  animationEvents: AnimationEvent[];
  staticAnimationEventsLayerVisible: boolean;
  onAimationEventClick: (animationEvent: AnimationEvent) => void;
};

export const useStaticAnimationEventsLayer = ({
  animationEvents,
  onAimationEventClick,
  staticAnimationEventsLayerVisible,
}: Props) => {
  return new LineLayer<AnimationEvent>({
    id: 'static-animation-events-layer',
    visible: staticAnimationEventsLayerVisible,
    data: animationEvents,
    getWidth: () => 4,
    getColor: d => {
      switch (d.type) {
        case 'sea-segment':
          if (d.id.includes('split-1')) {
            return [164, 80, 167];
          }
          return d.durationInMinutes > 30 ? [200, 0, 0] : [0, 200, 0];
        case 'port-entry':
          return [0, 0, 0];
        case 'port-exit':
          return [0, 0, 0];
        default:
          throw new Error("Can't handle type");
      }
    },
    getSourcePosition: d => {
      switch (d.type) {
        case 'sea-segment':
          return d.from.coordinates;
        case 'port-entry':
          return [0, 0];
        case 'port-exit':
          return [0, 0];
        default:
          throw new Error("Can't handle type");
      }
    },
    getTargetPosition: (d: AnimationEvent) => {
      switch (d.type) {
        case 'sea-segment':
          return d.to.coordinates;
        case 'port-entry':
          return [0, 0];
        case 'port-exit':
          return [0, 0];
        default:
          throw new Error("Can't handle type");
      }
    },

    autoHighlight: true,
    highlightColor: [0, 0, 128, 128],
    pickable: true,
    wrapLongitude: true,
    onClick: info => {
      const leg: AnimationEvent = info.object;
      // eslint-disable-next-line no-console
      console.log('onClick', leg);
      onAimationEventClick(leg);
    },
  });
};
