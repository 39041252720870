import {FC} from 'react';
import {useParseMailMutation} from '../../../../../screens/dashboard/widgets/ParserWidget/utils/useParseMailMutation';
import {Spin} from 'antd';
import {transformParserCargoToPlattformCargo} from '../../../../../screens/dashboard/widgets/ParserWidget/transformParserCargoToPlattformCargo/transformParserCargoToPlattformCargo';
import {TODO} from '../../../../../utils/TODO';
import {ParserForm} from '../../../../ParserForm/ParserForm';

export const ImportCargoWithParser: FC<{onSubmit: (cargo: TODO) => void}> = ({onSubmit}) => {
  const parseMutation = useParseMailMutation({
    onSuccess: async parsedData => {
      if (parsedData) {
        const platformCargo = await transformParserCargoToPlattformCargo(parsedData);
        onSubmit(platformCargo);
      }
    },
  });

  return (
    <Spin spinning={parseMutation.isPending}>
      <ParserForm
        onSubmit={({text}) => {
          parseMutation.mutate({text});
        }}
        height={252}
        submitLabel={'Parse & Next'}
        stretchButton
        showBackgroundIcon
      />
    </Spin>
  );
};
