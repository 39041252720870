import Icon, {IconSize} from '../Icon';
import {Input} from '../../antd/components/Input';
import {InputProps} from 'antd';
import {ReactElement} from 'react';

export const BlindInputFieldToReset = (props: {
  size?: InputProps['size'];
  value: string;
  dataTestIdPrefix: string;
  iconSize?: IconSize;
  onReset: () => void;
  prefix: ReactElement;
}) => {
  const iconSize: IconSize | undefined = props.size === 'middle' ? 'medium' : props.size;

  return (
    <Input
      readOnly={true}
      data-testid={`${props.dataTestIdPrefix}BlindInputField`}
      prefix={props.prefix}
      size={props.size}
      value={props.value}
      suffix={
        <Icon
          dataTestid={`${props.dataTestIdPrefix}ResetVesselBtn`}
          onClick={props.onReset}
          type={'close'}
          size={iconSize}
        />
      }
    />
  );
};
