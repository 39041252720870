import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {Blurred} from '../../utils/Blurred';

export interface ManagementManagingOwnerAccessorOptions {
  blurred?: boolean;
}

const managementManagingOwnerAccessor =
  (type: RowValueType, options: ManagementManagingOwnerAccessorOptions): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return <Blurred blurred={options.blurred}>{vessel.managementManagingOwner}</Blurred>;
  };

export const managementManagingOwnerColumn = ({
  type,
  minWidth = 10,
  sortable = false,
  options = {},
}: {
  type?: RowValueType;
  minWidth?: number;
  sortable?: boolean;
  options?: ManagementManagingOwnerAccessorOptions;
}): CustomColumnDef<SeaboVessel> => ({
  header: 'Owner',
  id: 'owner',
  accessorFn: managementManagingOwnerAccessor(type, options),
  cell: info => info.getValue(),
  minWidth,
  enableSorting: sortable,
});
