import {FC} from 'react';
import {Result, Button, Modal} from 'antd';
import {useSessionKeepaliveMutation} from './useSessionKeepaliveMutation';
import useInterval from 'beautiful-react-hooks/useInterval';

const KEEPALIVE_INTERVAL = 5 * 60 * 1000; // 5 minutes

/**
 * Keeps the user session alive by performing a "ping" request every 5 minutes.
 */
export const SessionKeepalive: FC = () => {
  const sessionKeepaliveMutation = useSessionKeepaliveMutation();
  const sessionExpired = sessionKeepaliveMutation.data === 'SessionExpired';

  // We repeat session keepalive requests until the session expired.
  useInterval(() => {
    if (!sessionExpired) {
      sessionKeepaliveMutation.mutate();
    }
  }, KEEPALIVE_INTERVAL);

  return sessionExpired ? <SessionExpiredModal /> : null;
};

const SessionExpiredModal: FC = () => (
  <Modal title={null} footer={null} open onCancel={reloadPage}>
    <Result
      status="warning"
      title={
        <div>
          Your session has expired,
          <br />
          please reload this page.
        </div>
      }
      extra={
        <Button type="primary" className="uppercase" onClick={reloadPage}>
          Reload
        </Button>
      }
    />
  </Modal>
);

const reloadPage = () => {
  window.location.reload();
};
