import Icon from '../Icon';

import './style.scss';

type Props = {
  onClick?: $TSFixMe;
  label: string;
  width?: string;
};

export const AddRowButton = ({onClick, label, width = '300px'}: Props) => {
  return (
    <div className="add-row-btn" onClick={onClick} style={{cursor: 'pointer', width: width}}>
      <Icon type={'item-add-selection-solid'} size={'medium'} color={'blue'} />
      <span className="add-row-btn__label">{label}</span>
    </div>
  );
};
