import {useUpdateVoyagePoint} from '../Context/utils/useCharterCalculationState/VoyagePoint/useUpdateVoyagePoint';
import {useGetLoadingVoyagePointsAndIndex} from '../Context/utils/useCharterCalculationState/VoyagePoint/useGetLoadingVoyagePointsAndIndex';
import {useGetDischargeVoyagePointsAndIndexes} from '../Context/utils/useCharterCalculationState/VoyagePoint/useGetDischargeVoyagePointsAndIndexes';
import {Col} from 'antd';
import {transformVoyagePointToPortParam} from '../VoyageInformation/utils/transformVoyagePointToPortParam';
import {PortPickerWithFlag} from '../../../PortPicker/PortPickerWithFlag';
import {
  transformPortToVoyagePointPartial,
  voyagePointPartialWithEmptyPortInformations,
} from '../VoyageInformation/utils/transformPortToVoyagePointPartial';
import {VoyagePoint} from '../VoyageInformation/VoyageTypes';
import {StyledFormItem} from '../components/StyledFormItem';
import {NotAvailable} from '../../../../utils/NotAvailable';

export const getPortParamsForFirst = (list: {point: VoyagePoint; index: number}[]) => {
  if (list.length === 0) {
    return undefined;
  }
  const point = list[0].point;

  return (
    transformVoyagePointToPortParam(point) ?? {
      name: '',
      countryObject: {code: ''},
    }
  );
};

export const PortsEdit = () => {
  const updateVoyagePoint = useUpdateVoyagePoint();
  const loadingVoyagePoints = useGetLoadingVoyagePointsAndIndex();
  const dischargeVoyagePoints = useGetDischargeVoyagePointsAndIndexes();

  const loadingPort = getPortParamsForFirst(loadingVoyagePoints);
  const dischargePort = getPortParamsForFirst(dischargeVoyagePoints);

  return (
    <>
      <Col span={12}>
        <StyledFormItem label={'Loading port:'} rules={[]}>
          {loadingPort ? (
            <PortPickerWithFlag
              size={'middle'}
              onChange={port => {
                updateVoyagePoint({
                  voyagePointIndex: loadingVoyagePoints[0].index,
                  voyagePoint: port
                    ? transformPortToVoyagePointPartial(port)
                    : voyagePointPartialWithEmptyPortInformations,
                });
              }}
              port={loadingPort}
            />
          ) : (
            NotAvailable
          )}
        </StyledFormItem>
      </Col>
      <Col span={12}>
        <StyledFormItem label={'Discharging port:'} rules={[]}>
          {dischargePort ? (
            <PortPickerWithFlag
              size={'middle'}
              port={dischargePort}
              onChange={port => {
                updateVoyagePoint({
                  voyagePointIndex: dischargeVoyagePoints[0].index,
                  voyagePoint: port
                    ? transformPortToVoyagePointPartial(port)
                    : voyagePointPartialWithEmptyPortInformations,
                });
              }}
            />
          ) : (
            NotAvailable
          )}
        </StyledFormItem>
      </Col>
    </>
  );
};
