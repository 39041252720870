/**
 * Scrolls to the given element with offset of the header
 */
export const scrollIntoViewWithHeaderOffset = (element: HTMLElement) => {
  const headerHeight = document.getElementById('full-page-header')?.clientHeight || 0;
  scrollIntoViewWithOffset(element, headerHeight + 10);
};

/**
 * Scrolls to the given element with given offset
 */
export const scrollIntoViewWithOffset = (element: HTMLElement, offset: number) => {
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};
