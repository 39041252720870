import {Dayjs} from 'dayjs';
import {QueryClient} from '@tanstack/react-query';
import {isDayAHoliday} from '../isDayAHoliday';

export const fhex = async ({
  isoWeekday,
  countryCode,
  date,
  queryClient,
}: {
  isMorning: boolean;
  isoWeekday: number;
  date: Dayjs;
  countryCode?: string;
  queryClient: QueryClient;
}): Promise<boolean> => {
  if (await isDayAHoliday({date, countryCode, queryClient})) {
    return false;
  }

  return isoWeekday !== 5;
};
