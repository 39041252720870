import React, {FC} from 'react';
import {Intake, IntakeTypeOptions, intakeTypes, IntakeValidation} from './IntakeTypes';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import Input from '../../../../atoms/Input';
import {acceptIntOrEmpty} from '../../../../utils/formatter';
import Checkbox from '../../../../atoms/Checkbox';
import HorizontalLine from '../../../../atoms/HorizontalLine';

import IconButton from '../../../../atoms/IconButton';
import Select from '../../../../atoms/Select/Select';
import {TODO} from '../../../../utils/TODO';

export const IntakeRow: FC<{
  intake: Intake;
  validation?: IntakeValidation;
  index: number;
  possibleIntakeTypeOptions: IntakeTypeOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (field: keyof Intake, value: any) => void;
  onValidate: (field: keyof Intake) => void;
  onRemove: () => void;
}> = ({index, intake, validation, onValidate, onRemove, possibleIntakeTypeOptions, onChange}) => {
  return (
    <div className="row">
      <div className="scol-12 scol-sm-3">
        <LabelWrapper label={'Container type'} htmlFor={`form-intake-type-${index}`}>
          <Select
            width={'100%'}
            hasError={false}
            id={`form-intake-type-${index}`}
            name={`form-intake-type-${index}`}
            options={possibleIntakeTypeOptions}
            value={{value: intake.type, name: intakeTypes[intake.type]}}
            defaultValue={{value: intake.type, name: intakeTypes[intake.type]}}
            onChange={o => onChange('type', o?.value)}
            getOptionLabel={(o: TODO) => o.name}
            getOptionValue={(o: TODO) => o.value}
            components={{DropdownIndicator: ArrowIndicator}}
            isSearchable={false}
          />
        </LabelWrapper>
      </div>
      <div className="scol-12 scol-sm-4">
        <LabelWrapper
          label={'Quantity'}
          required
          htmlFor={`form-intake-quantity-${index}`}
          hasError={validation?.quantity?.invalid}>
          <Input
            id={`form-intake-quantity-${index}`}
            onChange={v => acceptIntOrEmpty(v.toString(), v => onChange('quantity', v), 5)}
            onKeyUp={() => onValidate('quantity')}
            onBlur={() => onValidate('quantity')}
            value={intake.quantity}
            hasError={validation?.quantity?.invalid}
          />
        </LabelWrapper>
        {validation?.quantity?.error && (
          <p className="text-danger text-danger--no-margin">{validation?.quantity?.error}</p>
        )}
      </div>
      <div className="scol-12 scol-sm-3 offset-sm-1">
        <div className="cargo-vessel-form__v-centered-field">
          <Checkbox
            id={`form-intake-highcube-${index}`}
            label={'High cube'}
            checked={intake.isHighCube}
            onChange={e => onChange('isHighCube', e.target.checked)}
          />
        </div>
      </div>
      <div className="scol-12 scol-sm-1">
        <div className="cargo-vessel-form__v-centered-field">
          <IconButton
            id={`form-intake-intake-remove-${index}`}
            type={'scrap'}
            iconStyle={{cursor: 'pointer'}}
            onClick={() => onRemove()}
            title={'Remove'}
          />
        </div>
      </div>
      <HorizontalLine large topSpace={'small'} />
    </div>
  );
};
