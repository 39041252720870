import {useState} from 'react';
import {FilterCategory, FilterItem} from '../../api/symfony/generated';
import {useDispatch} from '../../redux/react-redux';
import {SubMarketActions, SubMarketType} from '../../redux/SubMarket';
import {newSortKey} from '../../components/TabList/newSortKey';
import {SaveFilterModal} from '../../components/Modal/SaveFilters/SaveFilterModal';
import styled from 'styled-components';
import {useSubMarkets} from './useSubMarkets';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {UseCurrentFiltersReturnType} from './utils/useCurrentFilters';

type SaveFilterButtonProps = UseCurrentFiltersReturnType & {
  disabled: boolean;
};

export const SaveFilterButton = ({
  activeTabId,
  filterType,
  currentFilterObject,
  currentFilterSettings,
  filterProviderApi,
  disabled,
}: SaveFilterButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const filters = useSubMarkets(filterType);

  const dispatch = useDispatch();

  if (disabled) {
    return null;
  }

  const loadFilterItem = async (filter: FilterItem) => {
    await filterProviderApi.loadFilterItem(filter, {
      onFilterSettingsChangeEnabled: false,
    });
  };

  return (
    <>
      <ScreenHeader.Features.Button
        data-cy="MarketSaveFiltersBTN"
        type="primary"
        onClick={() => {
          setModalVisible(true);
        }}>
        Save filters
      </ScreenHeader.Features.Button>
      <SaveFilterModal
        visible={modalVisible}
        createOrUpdateModal={{
          title: 'Save filters as a submarket',
          description: (
            <>
              Do you want to update the current submarket "{currentFilterObject?.name}"<br />
              or do you want to create a new one?
            </>
          ),
        }}
        createModal={{
          title: 'Save filters as a new submarket',
          description: (
            <Description>
              You can save your filters as a new submarket
              <br />
              and then access it from the tab bar.
            </Description>
          ),
          namePlaceholder: 'Name for the submarket',
        }}
        filterSettings={currentFilterSettings}
        category={FilterCategory.Market}
        type={filterType}
        nextSortKey={newSortKey(filters)}
        currentFilterObject={currentFilterObject}
        openAsNew={activeTabId === 'all'}
        onCreateSuccess={createdFilter => {
          dispatch(SubMarketActions.add(createdFilter));
          dispatch(
            SubMarketActions.select({id: createdFilter.id, subMarketType: createdFilter.type! as SubMarketType})
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          loadFilterItem(createdFilter);
        }}
        onUpdateSuccess={updatedFilter => {
          dispatch(SubMarketActions.update(updatedFilter));
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          loadFilterItem(updatedFilter);
        }}
        onClose={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

const Description = styled.div`
  text-align: center;
`;
