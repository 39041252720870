import {useCommitRoundMutation} from './useCommitRoundMutation';
import {useMoveToRecapMutation} from './useMoveToRecapMutation';
import {useMoveToSettledMutation} from './useMoveToSettledMutation';
import {RefObject} from 'react';
import {useInvalidateNegotiationQueries} from '../../../../utils/useInvalidateNegotiationQueries';
import {useSetReplyTimeMutation} from '../../ReplyTimeArea/utils/useSetReplyTimeMutation';
import {
  NegotiationReplyTime,
  NegotiationRoundResult,
  NegotiationSummary,
  NewSubject,
  NewTerm,
} from '../../../../../../api/node-backend/generated';
import {useCreateTermsMutation} from '../../TermItem/utils/useCreateTermMutation';
import {useUpdateSortIndexMutation} from '../../TermItem/utils/useUpdateSortIndexMutation';
import {useCreateSubjectsMutation} from '../../Subjects/utils/useCreateSubjectsMutation';
import {useReactToPrint} from 'react-to-print';
import {useCancelNegotiationMutation} from './useCancelNegotiationMutation';
import {useGoToUrl} from '../../../../../../utils/useChangeBrowserUrl';
import {useCreateNegotiationMutation} from '../../../../NegotiationCreate/utils/useCreateNegotiationMutation';
import {getNegotiationCreateValues} from './getNegotiationCreateValues';
import {useReopenNegotiationMutation} from './useReopenNegotiationMutation';

export type NegotiationActionsHook = () => NegotiationActions;

export type NegotiationActions = {
  createTerms: (terms: NewTerm[]) => void;
  createSubjects: (subjects: NewSubject[]) => void;
  updateSortIndex: (termId: string, from: number, to: number) => void;
  setReplyTime: (replyTime: NegotiationReplyTime | null) => void;
  sendOffer: () => void;
  commitRound: () => void;
  moveToRecap: () => void;
  moveToSettled: () => void;
  cancelNegotiation: (
    reason?: string,
    recreateNegotiationTemplate?: {negotiation: NegotiationSummary; roundResult: NegotiationRoundResult}
  ) => void;
  reopenNegotiation: () => void;
  exportToPdf?: () => void;
  exitTutorial?: () => void;
  negotiationActionsBusy?: boolean;
};

export const negotiationActionsNoop = {
  createTerms: () => {},
  createSubjects: () => {},
  updateSortIndex: () => {},
  setReplyTime: () => {},
  sendOffer: () => {},
  commitRound: () => {},
  moveToRecap: () => {},
  moveToSettled: () => {},
  cancelNegotiation: () => {},
  reopenNegotiation: () => {},
  retractOffer: () => {},
  onExitTutorial: () => {},
  negotiationActionsBusy: false,
};

export const useGetNegotiationActionsWithMutations = (
  negotiationId?: string,
  roundResultId?: string,
  printContainerRef?: RefObject<HTMLDivElement> | undefined,
  onExitTutorial?: () => void
): {
  getNegotiationActions: NegotiationActionsHook;
  negotiationActionsBusy: boolean;
} => {
  const goToUrl = useGoToUrl();
  const invalidateNegotiationQueries = useInvalidateNegotiationQueries(negotiationId, roundResultId);

  const createNegotiationMutation = useCreateNegotiationMutation();
  const createTermsMutation = useCreateTermsMutation({onSuccess: invalidateNegotiationQueries});
  const createSubjectsMutation = useCreateSubjectsMutation({onSuccess: invalidateNegotiationQueries});
  const updateSortIndexMutation = useUpdateSortIndexMutation({onSuccess: invalidateNegotiationQueries});
  const setReplyTimeMutation = useSetReplyTimeMutation({onSuccess: invalidateNegotiationQueries});
  const commitRoundMutation = useCommitRoundMutation({onSuccess: invalidateNegotiationQueries});
  const moveToRecapMutation = useMoveToRecapMutation({onSuccess: invalidateNegotiationQueries});
  const moveToSettledMutation = useMoveToSettledMutation({onSuccess: invalidateNegotiationQueries});
  const cancelNegotiationMutation = useCancelNegotiationMutation({onSuccess: invalidateNegotiationQueries});
  const reopenNegotiationMutation = useReopenNegotiationMutation({onSuccess: invalidateNegotiationQueries});
  const _exportToPdf = useReactToPrint({content: () => printContainerRef?.current ?? null});

  const negotiationActionsBusy =
    createTermsMutation.isPending ||
    createSubjectsMutation.isPending ||
    updateSortIndexMutation.isPending ||
    setReplyTimeMutation.isPending ||
    commitRoundMutation.isPending ||
    moveToRecapMutation.isPending ||
    moveToSettledMutation.isPending;

  if (!negotiationId || !roundResultId) {
    return {
      getNegotiationActions: () => negotiationActionsNoop,
      negotiationActionsBusy,
    };
  }

  const getNegotiationActions: NegotiationActionsHook = () => {
    const createTerms = (newTerms: NewTerm[]) => createTermsMutation.mutate({negotiationId, newTerms});
    const createSubjects = (newSubjects: NewSubject[]) => createSubjectsMutation.mutate({negotiationId, newSubjects});
    const updateSortIndex = (termId: string, from: number, to: number) =>
      updateSortIndexMutation.mutate({roundResultId, termId, from, to});
    const setReplyTime = (replyTime: NegotiationReplyTime | null) =>
      setReplyTimeMutation.mutate({
        negotiationId,
        replyTime: {
          ...replyTime,
          timestamp: replyTime?.timestamp?.toISOString() ?? null,
        },
      });
    const sendOffer = () => commitRoundMutation.mutate({negotiationId});
    const commitRound = () => commitRoundMutation.mutate({negotiationId});
    const moveToRecap = () => moveToRecapMutation.mutate({negotiationId});
    const moveToSettled = () => moveToSettledMutation.mutate({negotiationId});
    const createNegotiationFromTemplate = (negotiationTemplate: {
      negotiation: NegotiationSummary;
      roundResult: NegotiationRoundResult;
    }) =>
      createNegotiationMutation.mutate(
        getNegotiationCreateValues(negotiationTemplate.negotiation, negotiationTemplate.roundResult),
        {
          onSuccess: newNegotiation => goToUrl(`/negotiations/${newNegotiation.id}`),
        }
      );
    /**
     * Cancels the negotiation with the given reason
     * If recreateNegotiationTemplate is provided, the negotiation will be recreated from the template after the cancellation and the user will be redirected to the new negotiation
     */
    const cancelNegotiation = async (
      cancellationReason?: string,
      recreateNegotiationTemplate?: {negotiation: NegotiationSummary; roundResult: NegotiationRoundResult}
    ) =>
      cancelNegotiationMutation.mutateAsync(
        {
          negotiationId,
          cancellationReason,
        },
        {
          onSuccess: recreateNegotiationTemplate
            ? () => createNegotiationFromTemplate(recreateNegotiationTemplate)
            : undefined,
        }
      );
    const reopenNegotiation = () => reopenNegotiationMutation.mutate({negotiationId});
    const exportToPdf = _exportToPdf as () => void;

    return {
      createTerms,
      createSubjects,
      updateSortIndex,
      setReplyTime,
      sendOffer,
      commitRound,
      moveToRecap,
      moveToSettled,
      cancelNegotiation,
      reopenNegotiation,
      exportToPdf,
      onExitTutorial,
      negotiationActionsBusy,
    };
  };

  return {
    getNegotiationActions,
    negotiationActionsBusy,
  };
};
