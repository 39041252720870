import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {useDispatch} from '../../redux/react-redux';
import ProjectService from '../../redux/ApiService/ProjectService';
import {ApiError} from '../../api/utils/ApiError';

export interface Params {
  projectId: number;
  type: 'cargoes' | 'vessels';
  shortlistItemId: number;
}

export const useDeleteShortlistMutation = (options: UseMutationOptions<void, ApiError, Params>) => {
  const dispatch = useDispatch();
  const onDeleteFromShortlist = (projectId: number, type: 'cargoes' | 'vessels', shortlistItemId: number) =>
    dispatch(ProjectService.deleteFromShortlist(projectId, type, shortlistItemId));

  return useMutation({
    mutationFn: async (params: Params) =>
      await onDeleteFromShortlist(params.projectId, params.type, params.shortlistItemId),
    ...options,
  });
};
