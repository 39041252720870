import {Step8Vessel} from '../../../../hooks/useGetCompetitors/filterVesselsAndAddDistance/steps/step8';
import {InputState} from '../utils/voyageChartercalculation/voyageChartercalculation';
import {VoyageRoute} from '../VoyageInformation/VoyageTypes';
import dayjs from 'dayjs';
import {calcSingleRoute} from '../utils/voyageChartercalculation/subCalcRoutes';
import {assert} from '../../../../utils/assert';
import {getExtraWaitingTimeAfterArrive} from './getExtraWaitingTimeAfterArrive';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {ResultVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';

export type DataSourceVessel = {
  resultVessels: ResultVessel;
  seaboVessel?: SeaboVessel;

  distanceDifferenceMoreThanReferenceVessel: number;
  extraConsumptionCosts: number;
  extraVoyageDurationMoreThanReferenceVessel: number;
  extraWaitingTimeMoreThanReferenceVessel: number;
  /**
   * TCE of this vessel when the freight idea is constant
   */
  tce: number;
};
export const generateDataSourceVessel = async ({
  vessel,
  inputState,
  yourBallastDistance,
  yourEtaAtLoadingPoint,
  seaboVessel,
  yourSpeed,
  yourRevenueTotal,
  ballastRoute,
  yourConsumptionCostOnBallastVoyage,
  yourDuration,
}: {
  vessel: Step8Vessel;
  seaboVessel: SeaboVessel;
  inputState: InputState;
  yourBallastDistance: number;
  ballastRoute: VoyageRoute | undefined;
  yourEtaAtLoadingPoint: dayjs.Dayjs;
  yourRevenueTotal: number;
  yourDuration: number;
  yourConsumptionCostOnBallastVoyage: number;
  yourSpeed: number;
}) => {
  const distanceDifferenceMoreThanReferenceVessel = vessel.distanceSeaVoyageInNm - yourBallastDistance;

  const ballastVoyageResult = calcSingleRoute({
    inputState: inputState,

    voyageRoute: {...ballastRoute, distance: distanceDifferenceMoreThanReferenceVessel},
  });

  // costs
  const extraConsumptionCosts = ballastVoyageResult.consumptionCosts - yourConsumptionCostOnBallastVoyage;
  const distancePerDay = yourSpeed * 24;
  const extraVoyageDuration = distanceDifferenceMoreThanReferenceVessel / distancePerDay;

  // We assume that the costs are the same for the laden voyage, and therefor we add the extra costs of consumption of the ballast voyage.
  const revenueTotal = yourRevenueTotal - extraConsumptionCosts;

  assert(inputState.voyage.laycanFrom);

  // if the vessel is arriving before the laycan, we need to wait
  const extraWaitingTimeAfterArrive = getExtraWaitingTimeAfterArrive({
    competitorEta: dayjs(vessel.estimatedArrivalDate),
    subjectVesselEta: yourEtaAtLoadingPoint,
    laycanFrom: inputState.voyage.laycanFrom,
  });

  const vesselDepartureDate = dayjs(vessel.estimatedArrivalDate).subtract(
    vessel.distanceSeaVoyageInNm / distancePerDay,
    'days'
  );

  const now = dayjs();
  const waitingTimeBeforeDeparture = Math.max(vesselDepartureDate.diff(now, 'hours') / 24, 0);

  const extraWaitingTime = extraWaitingTimeAfterArrive + waitingTimeBeforeDeparture;

  const tce = revenueTotal / (yourDuration + extraVoyageDuration + extraWaitingTime);

  const result: DataSourceVessel = {
    seaboVessel,
    resultVessels: vessel,
    distanceDifferenceMoreThanReferenceVessel: distanceDifferenceMoreThanReferenceVessel,
    extraConsumptionCosts: extraConsumptionCosts,
    extraVoyageDurationMoreThanReferenceVessel: extraVoyageDuration,
    tce,
    extraWaitingTimeMoreThanReferenceVessel: extraWaitingTime,
  };

  return result;
};
