/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {City, CityFromJSON, CityToJSON} from '../models';

export interface FindCityRequest {
  countryCode?: string;
  search?: string;
  locationId?: number;
}

export interface GetWeatherForecastRequest {
  lat?: string;
  lon?: string;
  locationId?: number;
}

/**
 *
 */
export class WeatherApi extends runtime.BaseAPI {
  /**
   */
  async findCityRaw(requestParameters: FindCityRequest): Promise<runtime.ApiResponse<Array<City>>> {
    const queryParameters: any = {};

    if (requestParameters.countryCode !== undefined) {
      queryParameters['countryCode'] = requestParameters.countryCode;
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search;
    }

    if (requestParameters.locationId !== undefined) {
      queryParameters['locationId'] = requestParameters.locationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/city/find`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(CityFromJSON));
  }

  /**
   */
  async findCity(requestParameters: FindCityRequest): Promise<Array<City>> {
    const response = await this.findCityRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getWeatherForecastRaw(requestParameters: GetWeatherForecastRequest): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {};

    if (requestParameters.lat !== undefined) {
      queryParameters['lat'] = requestParameters.lat;
    }

    if (requestParameters.lon !== undefined) {
      queryParameters['lon'] = requestParameters.lon;
    }

    if (requestParameters.locationId !== undefined) {
      queryParameters['locationId'] = requestParameters.locationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/weather/forecast`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async getWeatherForecast(requestParameters: GetWeatherForecastRequest): Promise<object> {
    const response = await this.getWeatherForecastRaw(requestParameters);
    return await response.value();
  }
}
