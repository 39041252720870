import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as PortAttributes from '../PortAttributes/PortAttributesDefinition';
import * as LocationPort from '../LocationPort/LocationPortDefinition';

export const MarketPortFilterBranchDefinitions = makeFilterBranchDefinitions([
  PortAttributes.filterBranchDefinition,
  LocationPort.filterBranchDefinition,
] as const);

export type MarketPortInternalFilter = Partial<FilterInternalType<typeof MarketPortFilterBranchDefinitions>>;
export type MarketPortDatabaseFilter = Partial<FilterDatabaseType<typeof MarketPortFilterBranchDefinitions>>;
