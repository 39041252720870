/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ReportCircularParsingProblemBody
 */
export interface ReportCircularParsingProblemBody {
  /**
   *
   * @type {string}
   * @memberof ReportCircularParsingProblemBody
   */
  mailText: string;
  /**
   * A message of the user who reported the problem.
   * @type {string}
   * @memberof ReportCircularParsingProblemBody
   */
  message: string;
  /**
   * The parsed offer object.
   * @type {object}
   * @memberof ReportCircularParsingProblemBody
   */
  circular: object;
  /**
   *
   * @type {number}
   * @memberof ReportCircularParsingProblemBody
   */
  workspaceId: number;
}

export function ReportCircularParsingProblemBodyFromJSON(json: any): ReportCircularParsingProblemBody {
  return ReportCircularParsingProblemBodyFromJSONTyped(json, false);
}

export function ReportCircularParsingProblemBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReportCircularParsingProblemBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mailText: json['mailText'],
    message: json['message'],
    circular: json['circular'],
    workspaceId: json['workspaceId'],
  };
}

export function ReportCircularParsingProblemBodyToJSON(value?: ReportCircularParsingProblemBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mailText: value.mailText,
    message: value.message,
    circular: value.circular,
    workspaceId: value.workspaceId,
  };
}
