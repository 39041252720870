export type UrlWithTitle = {
  title: string;
  url: string;
};

export const mailForwardingHelpLinkList: UrlWithTitle[] = [
  {
    title: 'Google Mail',
    url: 'https://seabo.com/help#google-mail',
  },
  {
    title: 'Microsoft Exchange',
    url: 'https://seabo.com/help#microsoft-exchange',
  },
];
