import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type SidebarMode = 'wide' | 'narrow' | 'hidden';

export const SidebarModes: SidebarMode[] = ['wide', 'narrow', 'hidden'];

// The shape of this Redux partition
interface SidebarState {
  mode: SidebarMode;
}

const initialState: SidebarState = {
  mode: 'wide',
};

const SidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarMode(state, action: PayloadAction<SidebarMode>) {
      state.mode = action.payload;
    },
  },
});

export const SidebarActions = SidebarSlice.actions;
export const sidebarReducer = SidebarSlice.reducer;

/**
 * Validates that the given string is a SidebarMode and returns the matching instance, or undefined if not.
 */
export const getSidebarModeFromString = (s: string): SidebarMode | undefined => SidebarModes.find(mode => mode === s);
