import styled from 'styled-components';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';
import {OnboardingStepWrapper} from '../OnboardingStepWrapper';
import {OnboardingStepProps} from '../useUserOnboarding';
import {Button, Result} from 'antd';

export const Finished = (props: OnboardingStepProps) => {
  return (
    <OnboardingStepWrapper dataTestId="Finished" {...props}>
      <Center>
        <SettingsCard>
          <SettingsCardBody>
            <Result
              status="success"
              title="You're all set up!"
              subTitle="You can start exploring seabo now."
              extra={
                <Button type="primary" onClick={props.onFinish}>
                  Go to your personal Dashboard
                </Button>
              }
            />
          </SettingsCardBody>
        </SettingsCard>
      </Center>
    </OnboardingStepWrapper>
  );
};

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 260px);
`;
