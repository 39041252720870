import {FC, useEffect} from 'react';
import {validateTour} from '../../../../components/OnboardingTour/TourUtils';
import {NegotiationTourOverlay} from './NegotiationTourOverlay';
import {NegotiationTourState} from './tourState';
import {tourDefinitionNegotiation} from './tourDefinition';

type NegotiationTourProps = {
  tourState: NegotiationTourState;
  onChangeTourStep: (step: number) => void;
  onExitTutorial: () => void;
};

export const NegotiationTour: FC<NegotiationTourProps> = ({tourState, onChangeTourStep, onExitTutorial}) => {
  // validate the tour when temporary round result is set
  useEffect(() => {
    if (!tourState.isActive) {
      return;
    }
    validateTour(tourDefinitionNegotiation);
  }, [tourState.isActive]);

  if (!tourState.showOverlay || !tourState.isActive) {
    return null;
  }

  const handleChangeTourStep = (step: number) => {
    const currentStep = tourDefinitionNegotiation.steps[tourState.step];
    if (currentStep.onExitCallback) {
      currentStep.onExitCallback();
    }
    const nextStep = tourDefinitionNegotiation.steps[step];
    if (nextStep.onEnterCallback) {
      nextStep.onEnterCallback();
    }
    onChangeTourStep(step);
  };

  return (
    <NegotiationTourOverlay
      tourDefinition={tourDefinitionNegotiation}
      tourState={tourState}
      onChangeTourStep={handleChangeTourStep}
      onExitTutorial={onExitTutorial}
    />
  );
};
