/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Commodity
 */
export interface Commodity {
  /**
   *
   * @type {number}
   * @memberof Commodity
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Commodity
   */
  name: string;
  /**
   * The string may contain multiple abbreviations, separated by ";".
   * @type {string}
   * @memberof Commodity
   */
  abbrevs?: string | null;
  /**
   *
   * @type {string}
   * @memberof Commodity
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof Commodity
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Commodity
   */
  hazard?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Commodity
   */
  isArchived: boolean;
  /**
   *
   * @type {string}
   * @memberof Commodity
   */
  status?: CommodityStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Commodity
   */
  category: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CommodityStatusEnum {
  Draft = 'draft',
  Junk = 'junk',
}

export function CommodityFromJSON(json: any): Commodity {
  return CommodityFromJSONTyped(json, false);
}

export function CommodityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Commodity {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    abbrevs: !exists(json, 'abbrevs') ? undefined : json['abbrevs'],
    slug: json['slug'],
    description: !exists(json, 'description') ? undefined : json['description'],
    hazard: !exists(json, 'hazard') ? undefined : json['hazard'],
    isArchived: json['isArchived'],
    status: !exists(json, 'status') ? undefined : json['status'],
    category: json['category'],
  };
}

export function CommodityToJSON(value?: Commodity | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    abbrevs: value.abbrevs,
    slug: value.slug,
    description: value.description,
    hazard: value.hazard,
    isArchived: value.isArchived,
    status: value.status,
    category: value.category,
  };
}
