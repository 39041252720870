export const getVesselWithoutNull = <Vessel>(vessel: Vessel): Vessel => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newVesselWithoutNull = {...vessel} as Record<string, any>;
  Object.keys(newVesselWithoutNull).forEach(key => {
    if (newVesselWithoutNull[key] === null) {
      delete newVesselWithoutNull[key];
    }
  });
  return newVesselWithoutNull as Vessel;
};
