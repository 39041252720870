import {Responsive, ResponsiveProps} from 'react-grid-layout';
import React, {FC, useRef, ReactNode} from 'react';
import styled from 'styled-components';
import {useElementWidth} from './useElementWidth';

interface ResponsiveGridLayoutProps extends Omit<ResponsiveProps, 'width'> {
  children: ReactNode;
}

const ResponsiveGridLayoutUnstyled: FC<ResponsiveGridLayoutProps> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const width = useElementWidth(ref) ?? 1280;

  return (
    <div ref={ref} className="ResponsiveGridLayout" data-width={width}>
      <Responsive {...props} width={width} />
    </div>
  );
};

export const ResponsiveGridLayout = styled(ResponsiveGridLayoutUnstyled)`
  width: 100%;

  .react-grid-item.react-grid-placeholder {
    background: var(--color-gray-2);
    border-radius: var(--border-radius-widget);
  }
  .react-grid-item {
    .react-resizable-handle {
      bottom: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      overflow: hidden;
      background-image: unset;
      padding: 0;

      &::after {
        background-image: unset;
        padding: 0;
        bottom: -3px;
        right: 6px;
        height: 20px;
        width: 1px;
        border: none;
        border-left: var(--border-width) solid rgba(0, 0, 0, 0.15);
        transform: rotate(45deg);
      }
    }
    &:hover .react-resizable-handle {
      opacity: 1;
    }
  }
`;
