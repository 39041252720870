import {FC, useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {FilterCategory, FilterItem, FilterType} from '../../../api/symfony/generated';
import {FilterProviderState} from '../../../components/FilterProvider/FilterProviderState';
import {
  PortfolioVesselDatabaseFilter,
  PortfolioVesselFilterBranchDefinitions,
} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';
import {
  hasInvalidFilterSettings,
  resetFilterSettings,
} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterValidation';
import {useFilterProvider} from '../../../components/FilterProvider/useFilterProvider';
import {useLoadOrCreateFilterQuery} from '../../../queries/filter/useLoadOrCreateFilter';
import {useUpdateFilterMutation} from '../../../queries/filter/useUpdateFilterMutation';
import {GridClipboardActions} from '../../../redux/GridClipboard';
import {PortfolioActions, PortfolioType} from '../../../redux/Portfolio';
import {PortfolioFiltersActions} from '../../../redux/PortfolioFilters';
import {useDispatch, useSelector, useStore} from '../../../redux/react-redux';
import '../../portfolio/shared/style.scss';
import {MyFleetBody} from './MyFleetBody';

const TYPE: PortfolioType = 'vessel';

export const MyFleetScreen: FC = () => {
  // Needed to get vesselFilters lookup right:
  const store = useStore();
  const dispatch = useDispatch();
  const location = useLocation();

  const {vesselFilterSettings, vesselFilterId, renewedVesselFilter, vesselFilterProvider} = useSelector(
    state => state.portfolioFilters
  );
  const {activeTabVessel} = useSelector(state => state.portfolio.screenState);

  const setPageNumber = (pageNumber: number) => {
    dispatch(PortfolioActions.setPageNumber(TYPE, pageNumber));
  };
  const setVesselFilterSettings = (filterSettings: PortfolioVesselDatabaseFilter) =>
    dispatch(PortfolioFiltersActions.setVesselFilterSettings(filterSettings));
  const setVesselDeeplinkFilters = (filterSettings: PortfolioVesselDatabaseFilter) =>
    dispatch(PortfolioFiltersActions.setVesselDeeplinkFilterSettings(filterSettings));
  const setVesselFilterFromApi = useCallback(
    (filter: FilterItem) => dispatch(PortfolioFiltersActions.setVesselFilterFromApi(filter)),
    [dispatch]
  );
  const setVesselFilterProviderState = (
    filterProviderState: FilterProviderState<typeof PortfolioVesselFilterBranchDefinitions>
  ) => dispatch(PortfolioFiltersActions.setVesselFilterProviderState(filterProviderState));

  useEffect(() => {
    loadFilterFromURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchParams = new URLSearchParams(location.search);
  const filterFromUrl = searchParams.get('filters');
  const isFilterInURL = !!filterFromUrl;

  // Load filter from API
  const filterQuery = useLoadOrCreateFilterQuery(
    {
      category: FilterCategory.Portfolio,
      type: FilterType.Vessel,
    },
    {
      enabled: !isFilterInURL,
    }
  );

  const loadFilterFromURL = () => {
    if (isFilterInURL) {
      // Use a non persistent filter passed in the URL.
      setVesselDeeplinkFilters(JSON.parse(filterFromUrl));
    }
  };

  const filterLoaded = (filterQuery.isSuccess || isFilterInURL) && !!vesselFilterSettings;

  useEffect(() => {
    dispatch(GridClipboardActions.removeAllItemsFromClipboard('vessels', 'portfolio'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabVessel]);

  const updateFilterMutation = useUpdateFilterMutation();

  const handleFilterChange = (filters: PortfolioVesselDatabaseFilter) => {
    setVesselFilterSettings(filters);
    saveFilterInBackend();
  };

  const onFilterSettingsChangeByUser = () => {
    setPageNumber(1);
  };

  const saveFilterInBackend = () => {
    if (!filterLoaded || isFilterInURL) {
      // If the user is modifying a filter passed in via the URL then we don't save it in the backend.
      return;
    }

    const currentFilterSettings = store.getState().portfolioFilters.vesselFilterSettings;
    updateFilterMutation.mutate({
      id: vesselFilterId!,
      filterItemWrite: {
        category: FilterCategory.Portfolio,
        type: FilterType.Vessel,
        filterSettings: currentFilterSettings ?? undefined,
      },
    });
  };

  const filterProviderApi = useFilterProvider({
    name: 'MyFleet',
    filterBranchDefinitions: PortfolioVesselFilterBranchDefinitions,
    filterData: {
      id: vesselFilterId,
      filterSettings: vesselFilterSettings,
      shouldReload: renewedVesselFilter,
    },
    filterProviderState: vesselFilterProvider,
    onFilterSettingsChange: handleFilterChange,
    onFilterSettingsChangeByUser: onFilterSettingsChangeByUser,
    onFilterProviderStateChange: setVesselFilterProviderState,
  });

  useEffect(() => {
    if (filterQuery.isSuccess && filterQuery.data) {
      if (hasInvalidFilterSettings(filterQuery.data)) {
        // eslint-disable-next-line no-console
        console.warn('Invalid filter settings received - resetting', filterQuery.data);
        // Invalid filter settings received. Discard the filter settings and fall back to default filter.
        resetFilterSettings(filterQuery.data);
      }
      setVesselFilterFromApi(filterQuery.data);
    }
  }, [filterQuery.data, filterQuery.isSuccess, setVesselFilterFromApi]);

  return (
    <div className={'portfolio'} data-testid="PortfolioVesselScreen">
      <MyFleetBody
        filterProviderApi={filterProviderApi}
        filterLoaded={filterLoaded}
        filterFetching={filterQuery.isFetching}
      />
    </div>
  );
};
