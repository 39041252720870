import {useMutation} from '@tanstack/react-query';
import {FilterItem, FilterItemWrite} from '../../api/symfony/generated';
import {ApiError} from '../../api/utils/ApiError';
import {filterApi} from '../../api/symfony/symfonyApi';

export interface PatchFilterRequest {
  id: number;
  filterItemWrite: Partial<FilterItemWrite>;
}

/**
 * patchFilter allows us to modify invidual attributes of a filter.
 *
 * This is important because we may want to rename a filter, but may not yet have saved the filterSettings.
 * Therefore we can't save the whole object from memory.
 */
export const usePatchFilterMutation = () =>
  useMutation<FilterItem, ApiError, PatchFilterRequest>({
    mutationFn: patchFilter,
  });

export const patchFilter = async (request: PatchFilterRequest): Promise<FilterItem> => {
  const id = request.id;
  const filterItem = await filterApi.getFilter({id});

  const filterItemWrite: FilterItemWrite = {
    ...filterItem,
    // Compability issue between FilterItem and FilterItemWrite with respect to null versus undefined:
    filterSettings: filterItem.filterSettings ?? undefined,
    ...request.filterItemWrite,
  };
  const result = await filterApi.updateFilter({
    id,
    filterItemWrite,
  });
  return result;
};
