import React, {useContext} from 'react';
import {Alert} from 'antd';

import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {WarningArea, WarningProps} from './WarningTypes';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  margin-bottom: 5px;
  background: transparent;
  padding-left: 6px;
  padding-right: 6px;
`;

export const Warning = (props: WarningProps) => {
  const voyageContext = useContext(VoyageCharterContext);

  const {outputState} = voyageContext.state;

  const list = props.area
    ? outputState.warning.list.filter(x => x.areas.includes(props.area as WarningArea))
    : outputState.warning.list;
  return (
    <>
      {list.map((item, index) => {
        return <StyledAlert data-testid={'warning-item'} banner message={item.message} key={index} type={item.type} />;
      })}
    </>
  );
};
