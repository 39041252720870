import {Form} from 'antd';
import styled from 'styled-components';

export const StyledTitleFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: var(--color-black);
    font-size: var(--font-size-md);
    font-weight: 700;
  }
`;
