import {cargoAccessor} from '../../utils/cargoAccessorResult';
import {Link} from 'react-router-dom';
import {TBN} from '../../../Placeholder/Placeholder';
import Cargo from '../../../../model/Cargo';
import {RowValueType} from '../../utils/RowValueType';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {sortingFnForStrings} from '../../utils/sortingFnForStrings';
import {CargoFromApi} from '../../../Modal/ManageFixture/ManageFixtureForm/sections/GeneralSection/CargoFromApi';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';

const chartererNameAccessor = (type: RowValueType): AccessorFn<CargoGridColumnsProps> => {
  return cargoAccessor(
    (cargo: CargoFromApi) => (
      <div className={'market-grid-cell'}>
        <div className={'market-grid-cell-content'}>
          <Link to={`/cargo/${cargo.id}`}>{cargo.chartererName ? cargo.chartererName : <TBN />}</Link>
        </div>
        <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
          {cargo.contractType ? Cargo.formatContractType(cargo, true) : '-'}
        </div>
      </div>
    ),
    type
  );
};
export const chartererNameColumn = ({
  type,
  minWidth = 4,
}: {
  type: RowValueType;
  minWidth?: number;
}): CustomColumnDef<CargoGridColumnsProps> => {
  return {
    header: () => (
      <div className="datagrid-sortable-header">
        <div>
          Charterer
          <br />
          Contract type
        </div>
      </div>
    ),
    id: 'chartererName',
    enableSorting: true,
    sortingFn: (a, b) => {
      return sortingFnForStrings(a.original.cargo.chartererName, b.original.cargo.chartererName);
    },
    accessorFn: chartererNameAccessor(type),
    cell: info => info.getValue(),
    minWidth,
    minSize: minWidth,
  };
};
