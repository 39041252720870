type Lazy<T> = () => T;

interface ResponsiveCell {
  min?: number;
  max?: number;
}

export interface ResponsiveCellObject {
  xs: ResponsiveCell;
  s: ResponsiveCell;
  m: ResponsiveCell;
  l: ResponsiveCell;
  xl: ResponsiveCell;
  xxl: ResponsiveCell;
}

export const isSm = () => window.matchMedia('(max-width: 768px)').matches;
export const isMd = () => window.matchMedia('(min-width: 769px) and (max-width: 1366px)').matches;
export const isLg = () => window.matchMedia('(min-width: 1367px)').matches;

export const mediaQuery = (match: Lazy<boolean>, callback: Lazy<number>) => (match() ? callback() : undefined);

export const sm = (callback: Lazy<number>) => mediaQuery(isSm, callback);
export const md = (callback: Lazy<number>) => mediaQuery(isMd, callback);
export const lg = (callback: Lazy<number>) => mediaQuery(isLg, callback);

const responsiveCellObject: Lazy<ResponsiveCellObject> = () => ({
  xs: {
    min: sm(() => 32) || md(() => 32) || lg(() => 48),
    max: sm(() => 32) || md(() => 32) || lg(() => 48),
  },
  s: {
    min: sm(() => 50) || md(() => 50) || lg(() => 80),
    max: sm(() => 70) || md(() => 70) || lg(() => 104),
  },
  m: {
    min: sm(() => 70) || md(() => 70) || lg(() => 80),
    max: sm(() => 96) || md(() => 96) || lg(() => 100),
  },
  l: {
    min: sm(() => 96) || md(() => 96) || lg(() => 100),
    max: sm(() => 120) || md(() => 120) || lg(() => 148),
  },
  xl: {
    min: sm(() => 120) || md(() => 120) || lg(() => 120),
    max: sm(() => 158) || md(() => 158) || lg(() => 198),
  },
  xxl: {
    min: sm(() => 158) || md(() => 158) || lg(() => 198),
    max: sm(() => 194) || md(() => 194) || lg(() => 244),
  },
});

export default responsiveCellObject;
