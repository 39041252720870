import {Col, Row} from 'antd';
import {VoyageInformation} from '../VoyageInformation/VoyageInformation';
import {VoyageInput} from '../VoyageInformation/VoyageTypes';
import React, {useContext} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import styled from 'styled-components';
import {MapResultRow} from './MapResultRow';
import {VesselCargoExpensesRow} from './VesselCargoExpensesRow';
import {CompetitorsCard} from '../CompetitorsCard/CompetitorsCard';

export const WidgetsContainer = (props: {isLoadingRoutes: boolean}) => {
  const voyageContext = useContext(VoyageCharterContext);

  const {setInputState} = voyageContext;
  const {inputState, outputState} = voyageContext.state;

  return (
    <InputBox>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CompetitorsCard />
        </Col>
        <Col span={24}>
          <VesselCargoExpensesRow />
        </Col>
        <Col span={24}>
          <MapResultRow />
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <VoyageInformation
                isLoadingRoutes={props.isLoadingRoutes}
                open={true}
                inputState={inputState}
                outputState={outputState}
                onChange={(obj: VoyageInput) => {
                  setInputState({...inputState, voyage: obj});
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </InputBox>
  );
};

const InputBox = styled.div`
  .ant-form-item-label > label {
    width: 100%;
    white-space: pre-line;
  }
  margin-top: 16px;
`;
