import {cargoAccessor} from '../../utils/cargoAccessorResult';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';
import {EditCommentModal} from './EditCommentModal';

const commentAccessor = (type: $TSFixMe, editable: boolean): AccessorFn<CargoGridColumnsProps> =>
  cargoAccessor(
    (d: $TSFixMe) => (
      <div className={'market-grid-cell'} style={{textAlign: 'center', display: 'flex'}}>
        <div className={'market-grid-cell-content'}>
          {d.comment ? <EditCommentModal cargo={d} editable={editable} /> : '-'}
        </div>
      </div>
    ),
    type
  );
export const commentColumn = ({
  type,
  editable = false,
  minWidth = 4,
  sortable = false,
}: {
  type: $TSFixMe;
  minWidth?: number;
  sortable?: boolean;
  editable?: boolean;
}): CustomColumnDef<CargoGridColumnsProps> => ({
  header: 'Note',
  id: 'comment',
  enableSorting: sortable,
  accessorFn: commentAccessor(type, editable),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
