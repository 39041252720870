import {sessionApi} from '../api/symfony/symfonyApi';
import {useQuery, UseQueryResult, QueryClient} from '@tanstack/react-query';
import {CurrentUser} from '../api/symfony/generated';
import {ApiError, isApiErrorWithStatus} from '../api/utils/ApiError';
import {UseQueryOptions} from '../api/utils/ReactQuery';

const HTTP_STATUS_UNAUTHORIZED = 401;

export const CURRENT_USER_QUERY_KEY = 'currentUser';

type Result = CurrentUser | null;

/**
 * A hook that loads the current user.
 */
export const useCurrentUserQuery = (
  queryOptions?: UseQueryOptions<Result, ApiError>
): UseQueryResult<Result, ApiError> =>
  useQuery({
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: getCurrentUser,
    ...(queryOptions ?? {}),
  });

/**
 * Loads the current user or returns null if the user is not logged in.
 */
const getCurrentUser = async (): Promise<Result> => {
  try {
    return await sessionApi.getCurrentUser();
  } catch (error) {
    if (isApiErrorWithStatus(error, HTTP_STATUS_UNAUTHORIZED)) {
      // User is not logged in. That's not an error - but we signal that to the caller by returning null.
      return null;
    }
    throw error;
  }
};

/**
 * Reloads the current user.
 */
export const invalidateCurrentUserQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [CURRENT_USER_QUERY_KEY],
  });
};
