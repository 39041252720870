import React, {FC, ReactNode} from 'react';
import styled, {css} from 'styled-components';

type Props = {
  center?: boolean;
  children: string | number | ReactNode;
};

export const TourStepContent: FC<Props> = ({children, center = false}: Props) => {
  return <ContentContainer $center={center}>{children}</ContentContainer>;
};

const ContentContainer = styled.div<{$center?: boolean}>`
  background-color: var(--color-white);
  color: var(--color-gray-1);
  padding: 15px 30px;
  font-size: 14px;
  text-align: left;

  em {
    font-style: normal;
    text-decoration-line: underline;
  }

  h2 {
    color: var(--color-gray-1);
    font-size: 22px;
    margin-bottom: 20px;
  }

  ${({$center}) =>
    $center &&
    css`
      padding: 40px 45px 0px;
      text-align: center;
      p {
        font-size: 16px;
        line-height: 25px;
        color: var(--color-gray-2);
      }
    `}
`;
