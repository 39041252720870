/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Country,
  CountryFromJSON,
  CountryFromJSONTyped,
  CountryToJSON,
  Holiday,
  HolidayFromJSON,
  HolidayFromJSONTyped,
  HolidayToJSON,
  PortTerm,
  PortTermFromJSON,
  PortTermFromJSONTyped,
  PortTermToJSON,
  TradingArea,
  TradingAreaFromJSON,
  TradingAreaFromJSONTyped,
  TradingAreaToJSON,
} from './';

/**
 *
 * @export
 * @interface PortItemRead
 */
export interface PortItemRead {
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  code: string | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  name: string;
  /**
   *
   * @type {Country}
   * @memberof PortItemRead
   */
  country?: Country | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  portType: string | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  latitude: string | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  longitude: string | null;
  /**
   *
   * @type {TradingArea}
   * @memberof PortItemRead
   */
  tradingArea?: TradingArea | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  timezone?: string | null;
  /**
   *
   * @type {Array<object>}
   * @memberof PortItemRead
   */
  aliases?: Array<object>;
  /**
   * This should be in sync with the center coordinates.
   * @type {object}
   * @memberof PortItemRead
   */
  location?: object | null;
  /**
   *
   * @type {object}
   * @memberof PortItemRead
   */
  searouteLocation?: object | null;
  /**
   * Each point is represented by an array, containing the longitude as first (index 0)
   * and latitude as second (index 1) element.
   * @type {Array<Array<number>>}
   * @memberof PortItemRead
   */
  points?: Array<Array<number>>;
  /**
   *
   * @type {number}
   * @memberof PortItemRead
   */
  draftRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof PortItemRead
   */
  beamRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof PortItemRead
   */
  loaRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof PortItemRead
   */
  dwtRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof PortItemRead
   */
  airdraftRestriction: number | null;
  /**
   *
   * @type {Array<Holiday>}
   * @memberof PortItemRead
   */
  holidays: Array<Holiday>;
  /**
   *
   * @type {PortTerm}
   * @memberof PortItemRead
   */
  portTerm: PortTerm | null;
  /**
   *
   * @type {number}
   * @memberof PortItemRead
   */
  reviewStatus?: number;
  /**
   *
   * @type {boolean}
   * @memberof PortItemRead
   */
  polygonReviewed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortItemRead
   */
  important?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortItemRead
   */
  importantReviewed?: boolean;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  contactAddress: string | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  contactPhone: string | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  contactEmail: string | null;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  contactWebsite: string | null;
  /**
   *
   * @type {number}
   * @memberof PortItemRead
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PortItemRead
   */
  size: string;
}

export function PortItemReadFromJSON(json: any): PortItemRead {
  return PortItemReadFromJSONTyped(json, false);
}

export function PortItemReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortItemRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
    name: json['name'],
    country: !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
    portType: json['portType'],
    latitude: json['latitude'],
    longitude: json['longitude'],
    tradingArea: !exists(json, 'tradingArea') ? undefined : TradingAreaFromJSON(json['tradingArea']),
    timezone: !exists(json, 'timezone') ? undefined : json['timezone'],
    aliases: !exists(json, 'aliases') ? undefined : json['aliases'],
    location: !exists(json, 'location') ? undefined : json['location'],
    searouteLocation: !exists(json, 'searouteLocation') ? undefined : json['searouteLocation'],
    points: !exists(json, 'points') ? undefined : json['points'],
    draftRestriction: json['draftRestriction'],
    beamRestriction: json['beamRestriction'],
    loaRestriction: json['loaRestriction'],
    dwtRestriction: json['dwtRestriction'],
    airdraftRestriction: json['airdraftRestriction'],
    holidays: (json['holidays'] as Array<any>).map(HolidayFromJSON),
    portTerm: PortTermFromJSON(json['portTerm']),
    reviewStatus: !exists(json, 'reviewStatus') ? undefined : json['reviewStatus'],
    polygonReviewed: !exists(json, 'polygonReviewed') ? undefined : json['polygonReviewed'],
    important: !exists(json, 'important') ? undefined : json['important'],
    importantReviewed: !exists(json, 'importantReviewed') ? undefined : json['importantReviewed'],
    contactAddress: json['contactAddress'],
    contactPhone: json['contactPhone'],
    contactEmail: json['contactEmail'],
    contactWebsite: json['contactWebsite'],
    id: !exists(json, 'id') ? undefined : json['id'],
    size: json['size'],
  };
}

export function PortItemReadToJSON(value?: PortItemRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    name: value.name,
    country: CountryToJSON(value.country),
    portType: value.portType,
    latitude: value.latitude,
    longitude: value.longitude,
    tradingArea: TradingAreaToJSON(value.tradingArea),
    timezone: value.timezone,
    aliases: value.aliases,
    location: value.location,
    searouteLocation: value.searouteLocation,
    points: value.points,
    draftRestriction: value.draftRestriction,
    beamRestriction: value.beamRestriction,
    loaRestriction: value.loaRestriction,
    dwtRestriction: value.dwtRestriction,
    airdraftRestriction: value.airdraftRestriction,
    holidays: (value.holidays as Array<any>).map(HolidayToJSON),
    portTerm: PortTermToJSON(value.portTerm),
    reviewStatus: value.reviewStatus,
    polygonReviewed: value.polygonReviewed,
    important: value.important,
    importantReviewed: value.importantReviewed,
    contactAddress: value.contactAddress,
    contactPhone: value.contactPhone,
    contactEmail: value.contactEmail,
    contactWebsite: value.contactWebsite,
    id: value.id,
    size: value.size,
  };
}
