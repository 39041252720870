import {FC} from 'react';
import BaseModal, {BaseModalChildrenProps} from '../../../atoms/BaseModal/index';
import CompanyUser from '../../../model/company/CompanyUser';
import {Member, Role} from '../../../api/symfony/generated';
import {useUpdateCompanyMembersMutation} from './useUpdateCompanyMembersMutation';
import {Button, Checkbox, Form, Input} from 'antd';
import styled from 'styled-components';
import {updateRoles} from './updateRoles';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {ModalWidth} from '../../../antd/components/Modal';

interface EditCompanyMembersModalProps {
  companyID: number;
  user: CompanyUser;
  callback: () => void;
  close: () => void;
}

interface FormValues {
  companyPosition: string;
  companyVisibility: boolean;
  isCompanyManager: boolean;
}

const EditCompanyMemberModal: FC<EditCompanyMembersModalProps> = ({companyID, user, callback, close}) => {
  const updateCompanyMembersMutation = useUpdateCompanyMembersMutation({
    onSuccess: () => {
      callback();
      close();
    },
  });

  const onFinish = (formValues: FormValues) => {
    // CompanyApi.updateCompanyMembers() can update a single member, by sending it an array with that one member.
    // Weird but works.
    const members: Member[] = [
      {
        userID: user.payload.id,
        companyPosition: formValues.companyPosition,
        companyVisibility: formValues.companyVisibility,
        roles: updateRoles(user.payload.roles, formValues.isCompanyManager),
      },
    ];
    updateCompanyMembersMutation.mutate({
      company: companyID,
      member: members,
    });
  };

  return (
    <>
      <div className={'company-edit-member-modal'} style={{textAlign: 'left'}}>
        <Form<FormValues>
          name="basic"
          initialValues={{
            companyPosition: user.payload.companyPosition,
            companyVisibility: user.payload.companyVisibility,
            isCompanyManager: user.payload.roles.includes(Role.CompanyManager),
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical">
          <FormItemForTextInput label="Email">
            <Input value={user.email!} readOnly={true} disabled={true} />
          </FormItemForTextInput>

          <FormItemForTextInput label="Position" name="companyPosition" rules={[{required: true, message: 'Required'}]}>
            <Input />
          </FormItemForTextInput>

          <Form.Item name="companyVisibility" valuePropName="checked" style={{marginBottom: 0}}>
            <Checkbox>Publicly visible</Checkbox>
          </Form.Item>

          <Form.Item name="isCompanyManager" valuePropName="checked" extra="Can add users and manage subscriptions">
            <Checkbox>Company manager</Checkbox>
          </Form.Item>

          <ModalFooter>
            <Button
              id={'company-edit-member__save'}
              type="primary"
              htmlType="submit"
              className="bold upper"
              disabled={updateCompanyMembersMutation.isPending}>
              EDIT USER
            </Button>
          </ModalFooter>
        </Form>
      </div>
    </>
  );
};

export const EditCompanyMember = (props: $TSFixMe) => (
  <BaseModal title="Edit user" width={ModalWidth.Middle}>
    {(modalProps: BaseModalChildrenProps) => <EditCompanyMemberModal {...props} {...modalProps} />}
  </BaseModal>
);

const FormItemForTextInput = styled(Form.Item)`
  [role='alert'] {
    display: flex;
  }
`;
