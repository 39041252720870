import {Col, Row} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {Modal} from '../../../antd/components/Modal';
import {UpgradeButton} from '../../../atoms/Button/UpgradeButton';
import Icon from '../../../atoms/Icon';
import {useSelector} from '../../../redux/react-redux';
import {getSubscriptionPlanLimits} from '../../../redux/selectors';
import {useWidgetsQuery} from '../queries/useWidgetQuery';
import {AvailableWidget} from '../widgets/utils/availableWidgets';
import {Spacer} from '../../../atoms/Spacer/Spacer';

type Props = {
  visible: boolean;
  onClose: () => void;
  onChoose: (widget: AvailableWidget) => void;
};

export const AddWidgetModal: FC<Props> = ({visible, onChoose, onClose}) => {
  const widgetQuery = useWidgetsQuery();
  const installableWidgets = widgetQuery.data?.installable || [];

  const installedWidget = widgetQuery.data?.installed || [];
  const widgetCount = installedWidget.length;
  const {widgetLimit, restrictedWidgets} = useSelector(getSubscriptionPlanLimits);
  const widgetsLeft = Math.max(widgetLimit - widgetCount, 0);

  const onSelectOneWidget = (widget: AvailableWidget) => {
    onChoose(widget);
    onClose();
  };

  return (
    <Modal
      width={680}
      footer={null}
      onCancel={onClose}
      open={visible}
      title={'Add widget'}
      subtitle={'Choose a widget to add'}
      destroyOnClose>
      <Spacer />
      {widgetLimit !== Infinity && (
        <PromotionContainer>
          <PromotionHeadline>Get more widgets with a paid plan.</PromotionHeadline>
          <UpgradeButton to={`/subscription`}>See all plans now</UpgradeButton>
        </PromotionContainer>
      )}
      <WidgetGridWrapper $block={widgetsLeft <= 0}>
        <Row gutter={[16, 16]}>
          {Object.values(installableWidgets).map(widget => {
            return (
              <Col span={6} key={widget.identifier}>
                <WidgetTile
                  widget={widget}
                  onSelectOneWidget={onSelectOneWidget}
                  isRestricted={restrictedWidgets.includes(widget.identifier)}
                />
              </Col>
            );
          })}
        </Row>
      </WidgetGridWrapper>
    </Modal>
  );
};

const WidgetTile: FC<{
  widget: AvailableWidget;
  onSelectOneWidget: (widget: AvailableWidget) => void;
  isRestricted: boolean;
}> = ({onSelectOneWidget, widget, isRestricted}) => {
  return (
    <div key={widget.identifier}>
      <WidgetTileBackground
        $isRestricted={isRestricted}
        onClick={() => (isRestricted ? null : onSelectOneWidget(widget))}>
        <StyledIcon type={widget.icon} color="gray" size="large" />
        <PromoLabel>Subscribe to a paid plan to unlock this widget</PromoLabel>
      </WidgetTileBackground>
      <WidgetTileLabel>{widget.name}</WidgetTileLabel>
    </div>
  );
};

const PromotionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 25px;
`;

const PromotionHeadline = styled.p`
  width: 100%;
  margin-bottom: 10px;
  font-size: var(--font-size-lg);
  font-weight: bold;
  line-height: 1.25;
`;

const PromoLabel = styled.span`
  display: none;
  font-weight: bold;
  text-align: center;
  cursor: default;
`;

const StyledIcon = styled(Icon)``;

const WidgetGridWrapper = styled.div<{$block: boolean}>`
  ${({$block}) =>
    $block
      ? `
    opacity: .8;
    pointer-events: none;
    filter: blur(2px);
  `
      : ''}
`;

const WidgetTileBackground = styled.div<{$isRestricted: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  border-radius: 4px;

  i {
    font-size: 50px;
  }

  ${({$isRestricted}) =>
    $isRestricted
      ? `
    background: var(--color-upgrade-light);
    color: var(--color-white);
    &:hover {
      ${StyledIcon} {
        display: none;
      }
      ${PromoLabel} {
        display: block;
      }
    }
  `
      : `
    background: var(--color-gray-4);
    color: var(--color-gray-2);
    cursor: pointer;
    &:hover {
      background-color: var(--color-gray-3);
    }
  `}
`;

const WidgetTileLabel = styled.div`
  text-align: center;
  color: var(--color-gray-2);
`;
