/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  MostVisitedBulkerPortsReport,
  MostVisitedBulkerPortsReportFromJSON,
  MostVisitedBulkerPortsReportToJSON,
  VesselDbReportRequest,
  VesselDbReportRequestFromJSON,
  VesselDbReportRequestToJSON,
} from '../models';

export interface GetMostVisitedBulkerPortsReportRequest {
  vesselDbReportRequest: VesselDbReportRequest;
}

/**
 *
 */
export class VesselDBMostVisitedBulkerPortReportApi extends runtime.BaseAPI {
  /**
   */
  async getMostVisitedBulkerPortsReportRaw(
    requestParameters: GetMostVisitedBulkerPortsReportRequest
  ): Promise<runtime.ApiResponse<MostVisitedBulkerPortsReport>> {
    if (requestParameters.vesselDbReportRequest === null || requestParameters.vesselDbReportRequest === undefined) {
      throw new runtime.RequiredError(
        'vesselDbReportRequest',
        'Required parameter requestParameters.vesselDbReportRequest was null or undefined when calling getMostVisitedBulkerPortsReport.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel-db/most-visited-bulker-port-report`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: VesselDbReportRequestToJSON(requestParameters.vesselDbReportRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => MostVisitedBulkerPortsReportFromJSON(jsonValue));
  }

  /**
   */
  async getMostVisitedBulkerPortsReport(
    requestParameters: GetMostVisitedBulkerPortsReportRequest
  ): Promise<MostVisitedBulkerPortsReport> {
    const response = await this.getMostVisitedBulkerPortsReportRaw(requestParameters);
    return await response.value();
  }
}
