import {ReactNode} from 'react';
import {RequiredMark} from 'antd/es/form/Form';
import styled from 'styled-components';

/**
 * Shows an asterisk after the label if the field is required.
 */
export const RequiredMarkAfterLabel: RequiredMark = (label: ReactNode, {required}: {required: boolean}) => (
  <>
    {label}
    {required ? <Asterisk /> : null}
  </>
);

const Asterisk = styled.span`
  padding-left: 4px;
  color: var(--color-red-dark);
  &::after {
    content: '*';
  }
`;
