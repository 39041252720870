/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NegotiationAttachmentType {
  Pdf = 'Pdf',
  Excel = 'Excel',
  Image = 'Image',
  Word = 'Word',
  Other = 'Other',
}

export function NegotiationAttachmentTypeFromJSON(json: any): NegotiationAttachmentType {
  return NegotiationAttachmentTypeFromJSONTyped(json, false);
}

export function NegotiationAttachmentTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationAttachmentType {
  return json as NegotiationAttachmentType;
}

export function NegotiationAttachmentTypeToJSON(value?: NegotiationAttachmentType | null): any {
  return value as any;
}
