import {InputState} from '../voyageChartercalculation/voyageChartercalculation';
import {getCargoCapacity} from '../getCargoCapacity';

export const isFittingCapacity = (inputState: InputState): boolean => {
  if (!inputState.vessel.capacity) {
    // If vessel capacity is not set, we can't check if the cargo fits. So we assume it fits.
    return true;
  }

  const cargoCapacity = getCargoCapacity(inputState.cargo);

  return cargoCapacity <= inputState.vessel.capacity;
};
