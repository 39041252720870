import Timeline, {ActiveEditsMap} from './Timeline/Timeline';
import produce from 'immer';
import {VoyagePoint} from './VoyageTypes';
import {assertUnreachable} from '../../../../utils/assert';
import {doLegMovement} from './utils/doLegMovement';
import React, {useContext, useState} from 'react';
import {calcTimelineListFunc} from '../utils/calcChronicalTimeLineList';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {isPointInSECA} from '../utils/isPointInSECA';
import {geoJsonLatLngToGeolibCoordinates} from '../utils/geoJsonLatLngToGeolibCoordinates';

export const VoyageTimeline = () => {
  const voyageContext = useContext(VoyageCharterContext);
  const [activeEditsMap, setActiveEditsMap] = useState<ActiveEditsMap>({});

  const setIsEditingForEveryNode = (isEditing: boolean) => {
    setActiveEditsMap(
      produce(activeEditsMap, draftMap => {
        Object.keys(activeEditsMap).forEach(key => {
          draftMap[key] = isEditing;
        });
      })
    );
  };

  const stopEditingAnyNode = () => setIsEditingForEveryNode(false);
  const calcTimelineList = calcTimelineListFunc(voyageContext.state);
  return (
    <Timeline
      activeEditsMap={activeEditsMap}
      onChangeEditing={(key: string, isEditing: boolean) => {
        const newActiveEditsMap = produce(activeEditsMap, draft => {
          draft[key] = isEditing;
        });
        setActiveEditsMap(newActiveEditsMap);
      }}
      vesselInput={voyageContext.state.inputState.vessel}
      onChangePoint={(index, point) => {
        const oldPoint = voyageContext.state.inputState.voyage.points[index];
        const newPoint: VoyagePoint = {
          ...oldPoint,
          ...point,
        };

        newPoint.isInSECA = isPointInSECA({
          point: geoJsonLatLngToGeolibCoordinates(newPoint.coordinates),
        });
        const newInputState = produce(voyageContext.state.inputState, draftInputState => {
          draftInputState.voyage.points[index] = newPoint;
          draftInputState.voyage.routesCalculated = false;
          return draftInputState;
        });

        voyageContext.setInputState(newInputState);
      }}
      onRemovePoint={removeIndex => {
        voyageContext.setInputState(
          produce(voyageContext.state.inputState, draftInputState => {
            draftInputState.voyage.points.splice(removeIndex, 1);
            draftInputState.voyage.routesCalculated = false;
          })
        );
        stopEditingAnyNode();
      }}
      onChangeRoute={(index, route) => {
        const oldRoute = voyageContext.state.inputState.voyage.routes[index];
        const newRoute = {...oldRoute, ...route};
        voyageContext.setInputState(
          produce(voyageContext.state.inputState, draftInputState => {
            draftInputState.voyage.routes[index] = newRoute;
          })
        );
      }}
      onAddPoint={(voyagePoint: VoyagePoint, position) => {
        voyageContext.setInputState(
          produce(voyageContext.state.inputState, draftInputState => {
            switch (position) {
              case 'start':
                draftInputState.voyage.points.unshift({...voyagePoint});
                break;
              case 'end':
                draftInputState.voyage.points.push({...voyagePoint});
                break;
              default:
                assertUnreachable(position);
            }

            draftInputState.voyage.routesCalculated = false;
          })
        );
        stopEditingAnyNode();
      }}
      items={calcTimelineList}
      onSortEnd={({newIndex, oldIndex}) => {
        const newInputState = produce(voyageContext.state.inputState, draftInputState => {
          draftInputState.voyage = doLegMovement({
            voyage: voyageContext.state.inputState.voyage,
            newIndex,
            oldIndex,
          });
        });
        voyageContext.setInputState(newInputState);
        stopEditingAnyNode();
      }}
    />
  );
};
