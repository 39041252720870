import {VesselGridOptions, vesselMppGrid} from '../../../../components/GridLayout';
import {ownerColumn} from '../../../../components/GridLayout/columns/vessel/ownerColumn';
import {shipBuilderColumn} from '../../../../components/GridLayout/columns/vessel/shipBuilderColumn';
import {engineColumn} from '../../../../components/GridLayout/columns/vessel/engineColumn';
import {imoColumn} from '../../../../components/GridLayout/columns/vessel/imoColumn';
import {arriveDateColumn} from './arriveDateColumn';
import {durationColumn} from './durationColumn';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import {TODO} from '../../../../utils/TODO';

const gridOptions: VesselGridOptions = {
  shipNameColumn: {
    isLink: false,
  },
};

export const gridColumns: CustomColumnDef<TODO>[] = [
  ...vesselMppGrid('congestion', gridOptions),
  ownerColumn({}),
  shipBuilderColumn({}),
  engineColumn({}),
  imoColumn({
    minWidth: 4,
  }),
  arriveDateColumn(),
  durationColumn(),
];
