import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {numberFormat} from '../../../../utils/formatter';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {Blurred} from '../../utils/Blurred';

export interface DraftColumnAccessorOptions {
  blurred?: boolean;
}

export const draftAccessor =
  (type: RowValueType, options: DraftColumnAccessorOptions): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'} data-cy="cell-draft">
        <div className={'market-grid-cell-content'}>
          <Blurred blurred={options.blurred}>
            {numberFormat(
              vessel.draft,
              {emptyString: '-'},
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </Blurred>
        </div>
      </div>
    );
  };
export const draftColumn = ({
  type,
  minWidth = 3.5,
  options = {},
}: {
  type?: RowValueType;
  minWidth?: number;
  options?: DraftColumnAccessorOptions;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-draft">
      <div>DRAFT</div>
    </div>
  ),

  id: 'draft',
  accessorFn: draftAccessor(type, options),
  cell: info => info.getValue(),
  enableSorting: true,
  minWidth,
  minSize: minWidth,
});
