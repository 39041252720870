import {ViewState} from '../ViewState';
import {lineString} from '@turf/helpers';
import {clamp} from '../../../utils/helper';
import {geoBounds} from 'd3-geo';
import {WebMercatorViewport} from '@deck.gl/core/typed';
import {getTransitionInterpolator} from './getTransitionInterpolator';
import {Coordinates} from '../../../utils/Coordinates';
import {TODO} from '../../../utils/TODO';

type GetViewStateFromBoundsProps = {
  viewState: ViewState;
  coords: Array<Coordinates>;
  mapSize: {
    width: number;
    height: number;
  };
  options: {
    padding?: number;
  };
};

export const getViewStateFromBounds = (props: GetViewStateFromBoundsProps): ViewState => {
  if (props.coords.length === 1) {
    return {
      ...props.viewState,
      latitude: props.coords[0][1],
      longitude: props.coords[0][0],
      zoom: 10,
    };
  }
  const isMapToSmall = props.mapSize.height < 100 || props.mapSize.width < 200;
  if (isMapToSmall) {
    const newViewstate = {...props.viewState};

    if (props.coords[0]) {
      newViewstate.latitude = props.coords[0][1];
      newViewstate.longitude = props.coords[0][0];
    }
    return newViewstate;
  }

  const minimalBbox = geoBounds(lineString(props.coords));

  if (minimalBbox[0][0] >= 0 && minimalBbox[1][0] < 0) {
    minimalBbox[1][0] += 360;
  }

  const {width, height} = props.mapSize;
  const viewport = new WebMercatorViewport({...props.viewState, width, height});

  const {latitude, longitude, zoom} = viewport.fitBounds(minimalBbox, props.options) as TODO;

  return {
    ...props.viewState,
    ...getTransitionInterpolator(),
    latitude,
    longitude,
    zoom: clamp(zoom, props.viewState.minZoom, props.viewState.maxZoom),
  };
};
