import {FC} from 'react';
import Icon from '../../../../../../atoms/Icon';
import styled from 'styled-components';

type TermTogglesProps = {
  onToggleDiff?: () => void;
  onToggleHistory?: () => void;
};

export const TermToggles: FC<TermTogglesProps> = ({onToggleDiff, onToggleHistory}) => {
  const hasToggles = !!onToggleDiff || !!onToggleHistory;

  if (!hasToggles) {
    return null;
  }

  return (
    <TermTogglesContainer data-testid="TermToggles">
      {onToggleHistory && <Icon type="history" onClick={onToggleHistory} />}
      {onToggleDiff && <Icon type="tips" onClick={onToggleDiff} />}
    </TermTogglesContainer>
  );
};

const TermTogglesContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
`;
