import React, {Component} from 'react';
import {connect} from 'react-redux';
import BaseModal from '../../../atoms/BaseModal/index';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import vesselService from '../../../redux/ApiService/vesselService';
import companyService from '../../../redux/ApiService/CompanyService/CompanyService';
import Loading from '../../../atoms/Loading';
import {Link} from 'react-router-dom';
import {GridClipboardActions} from '../../../redux/GridClipboard';
import cargoService from '../../../redux/ApiService/cargoService';
import {RootState} from '../../../redux/store';
import Select from '../../../atoms/Select/Select';
import styled from 'styled-components';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {Button} from 'antd';

type MultiMoveToWorkspaceModalState = $TSFixMe;
// eslint-disable-next-line @typescript-eslint/ban-types
class MultiMoveToWorkspaceModal extends Component<{}, MultiMoveToWorkspaceModalState> {
  state = {
    disabled: true,
    loading: true,
    workspaceId: null,
    error: false,
    errorMsg: '',
  };
  componentDidMount() {
    if ((this.props as $TSFixMe).userCompany) {
      (this.props as $TSFixMe).getUserWorkspaceList().then(() => {
        this.setState({loading: false});
      });
    }
  }
  moveToWorkspace() {
    this.setState({disabled: true});
    const {workspaceId} = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const {data, close, reload, type} = this.props;
    if (type === 'portfolioVessel') {
      (this.props as $TSFixMe)
        .addToWorkspaceVessels({workspaceID: workspaceId, vessels: data})
        .then(() => {
          reload();
          this.setState({disabled: false});
          (this.props as $TSFixMe).resetSelection('portfolio');
          close();
        })
        .catch((e: $TSFixMe) => {
          this.setState({
            errorMsg: e.payload.error.message || 'Something went wrong',
            error: true,
            errorItems: e.payload.error?.items,
          });
        });
    } else if (type === 'portfolioCargo') {
      (this.props as $TSFixMe)
        .addToWorkspaceCargoes({workspaceID: workspaceId, cargoes: data})
        .then(() => {
          reload();
          this.setState({disabled: false});
          (this.props as $TSFixMe).resetSelection('portfolio');
          close();
        })
        .catch((e: $TSFixMe) => {
          this.setState({
            errorMsg: e.payload.error.message || 'Something went wrong',
            error: true,
            errorItems: e.payload.error?.items,
          });
        });
    }
  }
  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'errorItems' does not exist on type '{ di... Remove this comment to see the full error message
    const {loading, error, errorMsg, errorItems} = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'workspaces' does not exist on type 'Read... Remove this comment to see the full error message
    const {workspaces, data, user, close} = this.props;
    let items = [];
    if (workspaces.data) {
      items = workspaces.data.items.filter((w: $TSFixMe) => w.id !== user.workspace?.id);
    }
    return (
      <>
        {!loading && items ? (
          <div className={'move-to-workspace'}>
            <Select
              className={'move-to-workspace__select'}
              onChange={(type: $TSFixMe, meta: $TSFixMe) => {
                meta.action === 'select-option'
                  ? this.setState({workspaceId: type.id, disabled: false})
                  : this.setState({workspaceId: null, disabled: true});
              }}
              components={{DropdownIndicator: ArrowIndicator}}
              options={items}
              getOptionLabel={(option: $TSFixMe) => option.name}
              getOptionValue={(option: $TSFixMe) => option.id}
              isClearable={true}
            />

            {data && !data.public && (
              <Warning>
                <strong>Warning:</strong> Vessel will be removed from current workspace
              </Warning>
            )}
            {error && (
              <div className="error">
                {errorMsg}
                <div>
                  <div>
                    {errorItems &&
                      errorItems.map((errorItem: $TSFixMe) => (
                        <div>
                          {errorItem.type === 'nego' ? (
                            <Link to={`/negotiations/${errorItem.id}`} onClick={close}>
                              Negotiation {errorItem.id}
                            </Link>
                          ) : (
                            <Link to={`/projects/${errorItem.id}`} onClick={close}>
                              Project {errorItem.id}
                            </Link>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}

            <ModalFooter>
              <Button disabled={this.state.disabled} onClick={() => this.moveToWorkspace()} type="primary">
                move to workspace
              </Button>
            </ModalFooter>
          </div>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}
const Warning = styled.div`
  margin-top: 8px;
`;
const MultiMoveToWorkspace = (props: $TSFixMe) => (
  <BaseModal middle narrow title="Move to workspace">
    {(modalProps: $TSFixMe) => <MultiMoveToWorkspaceModal {...props} {...modalProps} />}
  </BaseModal>
);
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  addToWorkspaceVessels: (body: $TSFixMe) => dispatch(vesselService.multiPatch(body)),
  addToWorkspaceCargoes: (body: $TSFixMe) => dispatch(cargoService.multiPatch(body)),
  getUserWorkspaceList: () => dispatch(companyService.getUserWorkspaceList()),
  resetSelection: (payload: $TSFixMe) => dispatch(GridClipboardActions.resetSelection(payload)),
});
const mapStateToProps = (state: RootState) => ({
  userCompany: state.user!.payload.company,
  workspaces: state.company.workspaces,
  user: state.user!.payload,
});
export default connect(mapStateToProps, mapDispatchToProps)(MultiMoveToWorkspace);
