import {FC} from 'react';
import Flag from '../Flags';
import {TODO} from '../../utils/TODO';
import './style.scss';

interface AIS {
  name?: string;
  country?: string;
  imo?: number;
  mmsi?: number;
  destination: TODO;
}

type Props = {
  ais: AIS;
};

export const AisVesselDisplay: FC<Props> = ({ais}) => (
  <div className={'dropdown-display dropdown-display--ais'}>
    <span className={'dropdown-display__title'}>
      {ais.name || 'N/A'}
      {ais.country && <Flag countryCode={ais.country} shadow width={13} height={10} />}
    </span>
    <span className={'dropdown-display__sub-title'}>
      (IMO: {ais.imo || 'N/A'}, MMSI: {ais.mmsi || 'N/A'}
      {ais.destination ? `, Dest: ${ais.destination}` : ')'})
    </span>
  </div>
);
