import {useQuery} from '@tanstack/react-query';
import {negotiationAttachmentApi} from '../../../../../api/node-backend/nodeBackendApis';

export const FIND_NEGOTIATION_ATTACHMENTS_BY_ID_QUERY_KEY = (negotiationId?: string) => [
  'negotiationAttachmentApi.getNegotiationAttachments',
  negotiationId,
];

export const useAttachmentsQuery = (negotiationId: string) => {
  return useQuery({
    queryKey: FIND_NEGOTIATION_ATTACHMENTS_BY_ID_QUERY_KEY(negotiationId),
    queryFn: async () => {
      return negotiationAttachmentApi.getNegotiationAttachments({
        negotiationId: negotiationId,
      });
    },
  });
};
