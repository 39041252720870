/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselConsumptionDTO,
  VesselConsumptionDTOFromJSON,
  VesselConsumptionDTOFromJSONTyped,
  VesselConsumptionDTOToJSON,
  VesselGearDTO,
  VesselGearDTOFromJSON,
  VesselGearDTOFromJSONTyped,
  VesselGearDTOToJSON,
  VesselIntakeDTO,
  VesselIntakeDTOFromJSON,
  VesselIntakeDTOFromJSONTyped,
  VesselIntakeDTOToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselDTO
 */
export interface VesselDTO {
  /**
   * Primary key in the Node Backend vessel DB, NOT the Symfony DB.
   * @type {number}
   * @memberof VesselDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  exName: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  charterName: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  callSign: string | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  imo: number;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  mmsi: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  builtYear: number | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  shipBuilder: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  designType: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  designSubType: string | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  tonnesPerCentimetre: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  lengthOverall: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  beam: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  draft: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  holdsAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  hatchesAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  dwtSummer: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  grossTonnage: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  netTonnage: number | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  grain: number | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  grainUnit: VesselDTOGrainUnitEnum;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  bale: number | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  baleUnit: VesselDTOBaleUnitEnum;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureLogFitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureLakesFitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureCo2Fitted: boolean | null;
  /**
   * International Transport Workers' Federation Standard.
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureItfFitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureTweenDecks: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureBoxShape: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureOpenHatch: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureA60Bulkhead: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureCellular: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureSternThrust: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureBowThrust: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VesselDTO
   */
  featureScrubberFitted: boolean | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  managementHeadOwner: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  managementManagingOwner: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  managementDisponentOwner: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  countryFlag: string | null;
  /**
   *
   * @type {number}
   * @memberof VesselDTO
   */
  reeferPlugs: number | null;
  /**
   * Ice class in arbitrary classification (previously, only Finnish-Swedish classification was allowed)
   * @type {string}
   * @memberof VesselDTO
   */
  iceClass: string | null;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  vesselType: VesselDTOVesselTypeEnum;
  /**
   *
   * @type {string}
   * @memberof VesselDTO
   */
  spireVesselId: string | null;
  /**
   *
   * @type {Array<VesselGearDTO>}
   * @memberof VesselDTO
   */
  gears: Array<VesselGearDTO>;
  /**
   *
   * @type {Array<VesselIntakeDTO>}
   * @memberof VesselDTO
   */
  intakes: Array<VesselIntakeDTO>;
  /**
   *
   * @type {Array<VesselConsumptionDTO>}
   * @memberof VesselDTO
   */
  consumptions: Array<VesselConsumptionDTO>;
}

/**
 * @export
 * @enum {string}
 */
export enum VesselDTOGrainUnitEnum {
  Cbm = 'cbm',
  Cft = 'cft',
} /**
 * @export
 * @enum {string}
 */
export enum VesselDTOBaleUnitEnum {
  Cbm = 'cbm',
  Cft = 'cft',
} /**
 * @export
 * @enum {string}
 */
export enum VesselDTOVesselTypeEnum {
  Bulker = 'bulker',
  Container = 'container',
  Mpp = 'mpp',
}

export function VesselDTOFromJSON(json: any): VesselDTO {
  return VesselDTOFromJSONTyped(json, false);
}

export function VesselDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    exName: json['exName'],
    charterName: json['charterName'],
    callSign: json['callSign'],
    imo: json['imo'],
    mmsi: json['mmsi'],
    builtYear: json['builtYear'],
    shipBuilder: json['shipBuilder'],
    designType: json['designType'],
    designSubType: json['designSubType'],
    tonnesPerCentimetre: json['tonnesPerCentimetre'],
    lengthOverall: json['lengthOverall'],
    beam: json['beam'],
    draft: json['draft'],
    holdsAmount: json['holdsAmount'],
    hatchesAmount: json['hatchesAmount'],
    dwtSummer: json['dwtSummer'],
    grossTonnage: json['grossTonnage'],
    netTonnage: json['netTonnage'],
    grain: json['grain'],
    grainUnit: json['grainUnit'],
    bale: json['bale'],
    baleUnit: json['baleUnit'],
    featureLogFitted: json['featureLogFitted'],
    featureLakesFitted: json['featureLakesFitted'],
    featureCo2Fitted: json['featureCo2Fitted'],
    featureItfFitted: json['featureItfFitted'],
    featureTweenDecks: json['featureTweenDecks'],
    featureBoxShape: json['featureBoxShape'],
    featureOpenHatch: json['featureOpenHatch'],
    featureA60Bulkhead: json['featureA60Bulkhead'],
    featureCellular: json['featureCellular'],
    featureSternThrust: json['featureSternThrust'],
    featureBowThrust: json['featureBowThrust'],
    featureScrubberFitted: json['featureScrubberFitted'],
    managementHeadOwner: json['managementHeadOwner'],
    managementManagingOwner: json['managementManagingOwner'],
    managementDisponentOwner: json['managementDisponentOwner'],
    countryFlag: json['countryFlag'],
    reeferPlugs: json['reeferPlugs'],
    iceClass: json['iceClass'],
    vesselType: json['vesselType'],
    spireVesselId: json['spireVesselId'],
    gears: (json['gears'] as Array<any>).map(VesselGearDTOFromJSON),
    intakes: (json['intakes'] as Array<any>).map(VesselIntakeDTOFromJSON),
    consumptions: (json['consumptions'] as Array<any>).map(VesselConsumptionDTOFromJSON),
  };
}

export function VesselDTOToJSON(value?: VesselDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    exName: value.exName,
    charterName: value.charterName,
    callSign: value.callSign,
    imo: value.imo,
    mmsi: value.mmsi,
    builtYear: value.builtYear,
    shipBuilder: value.shipBuilder,
    designType: value.designType,
    designSubType: value.designSubType,
    tonnesPerCentimetre: value.tonnesPerCentimetre,
    lengthOverall: value.lengthOverall,
    beam: value.beam,
    draft: value.draft,
    holdsAmount: value.holdsAmount,
    hatchesAmount: value.hatchesAmount,
    dwtSummer: value.dwtSummer,
    grossTonnage: value.grossTonnage,
    netTonnage: value.netTonnage,
    grain: value.grain,
    grainUnit: value.grainUnit,
    bale: value.bale,
    baleUnit: value.baleUnit,
    featureLogFitted: value.featureLogFitted,
    featureLakesFitted: value.featureLakesFitted,
    featureCo2Fitted: value.featureCo2Fitted,
    featureItfFitted: value.featureItfFitted,
    featureTweenDecks: value.featureTweenDecks,
    featureBoxShape: value.featureBoxShape,
    featureOpenHatch: value.featureOpenHatch,
    featureA60Bulkhead: value.featureA60Bulkhead,
    featureCellular: value.featureCellular,
    featureSternThrust: value.featureSternThrust,
    featureBowThrust: value.featureBowThrust,
    featureScrubberFitted: value.featureScrubberFitted,
    managementHeadOwner: value.managementHeadOwner,
    managementManagingOwner: value.managementManagingOwner,
    managementDisponentOwner: value.managementDisponentOwner,
    countryFlag: value.countryFlag,
    reeferPlugs: value.reeferPlugs,
    iceClass: value.iceClass,
    vesselType: value.vesselType,
    spireVesselId: value.spireVesselId,
    gears: (value.gears as Array<any>).map(VesselGearDTOToJSON),
    intakes: (value.intakes as Array<any>).map(VesselIntakeDTOToJSON),
    consumptions: (value.consumptions as Array<any>).map(VesselConsumptionDTOToJSON),
  };
}
