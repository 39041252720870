import HorizontalLine from '../../../../../atoms/HorizontalLine';
import {acceptFloatOrEmpty} from '../../../../../utils/formatter';
import {hasError} from '../../../../../utils/helper';
import FloatRangeInput from '../../../../RangeInput/FloatRangeInput';
import {filterBranchDefinition} from './SpotifindParametersDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

export const SpotifindParameters = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <div className="row bottom-fix">
        <div className="scol-12">
          <FloatRangeInput
            label={'Speed (in knots)'}
            onFromChange={value => acceptFloatOrEmpty(value, v => handleChange('speedMin', v), 2, 2)}
            onToChange={value => acceptFloatOrEmpty(value, v => handleChange('speedMax', v), 2, 2)}
            fromValue={values.speedMin}
            toValue={values.speedMax}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 99,
              placeholder: 'min',
              hasError: hasError('speedMin', validations),
            }}
            toProps={{
              min: 0,
              max: 99,
              placeholder: 'max',
              hasError: hasError('speedMax', validations),
            }}
          />
          <HorizontalLine large topSpace={'medium'} />
          <FloatRangeInput
            label={'Draft (in meters)'}
            onFromChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtMin', v), 2, 1)}
            onToChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtMax', v), 2, 1)}
            fromValue={values.draughtMin}
            toValue={values.draughtMax}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 99,
              placeholder: 'min',
              hasError: hasError('draughtMin', validations),
            }}
            toProps={{
              min: 0,
              max: 99,
              placeholder: 'max',
              hasError: hasError('draughtMax', validations),
            }}
          />
          <FloatRangeInput
            label={'Draft (in percent)'}
            onFromChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtPercentMin', v), 3, 2)}
            onToChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtPercentMax', v), 3, 2)}
            fromValue={values.draughtPercentMin}
            toValue={values.draughtPercentMax}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 100,
              placeholder: 'min',
              hasError: hasError('draughtPercentMin', validations),
            }}
            toProps={{
              min: 0,
              max: 100,
              placeholder: 'max',
              hasError: hasError('draughtPercentMax', validations),
            }}
          />
          <HorizontalLine large topSpace={'medium'} />
          <FloatRangeInput
            label={'Draft change (in meters)'}
            onFromChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtChangeMin', v), 2, 1)}
            onToChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtChangeMax', v), 2, 1)}
            fromValue={values.draughtChangeMin}
            toValue={values.draughtChangeMax}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 99,
              placeholder: 'min',
              hasError: hasError('draughtChangeMin', validations),
            }}
            toProps={{
              min: 0,
              max: 99,
              placeholder: 'max',
              hasError: hasError('draughtChangeMax', validations),
            }}
          />
          <FloatRangeInput
            label={'Draft change (in percent)'}
            onFromChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtChangePercentMin', v), 3, 2)}
            onToChange={v => acceptFloatOrEmpty(v, v => handleChange('draughtChangePercentMax', v), 3, 2)}
            fromValue={values.draughtChangePercentMin}
            toValue={values.draughtChangePercentMax}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 100,
              placeholder: 'min',
              hasError: hasError('draughtChangePercentMin', validations),
            }}
            toProps={{
              min: 0,
              max: 100,
              placeholder: 'max',
              hasError: hasError('draughtChangePercentMax', validations),
            }}
          />
        </div>
      </div>
    )}
  </FilterButton>
);
