import dayjs from 'dayjs';
import {
  NegotiationParty,
  NegotiationRoundResult,
  NegotiationRoundResultModification,
  NegotiationStatus,
  NegotiationSubjectStatus,
  NegotiationTermStatus,
} from '../../../../api/node-backend/generated';
import {createMockTerm} from '../../NegotiationDetail/Components/TermItem/test-utils/createMockTerm';

export const getTutorialRoundResult = (roundResultId: string, isCreator: boolean): NegotiationRoundResult => ({
  id: roundResultId,
  committedAt: null,
  committedBy: null,
  sentAt: null,
  party: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
  replyTime: {
    duration: dayjs.duration(3, 'days').asMilliseconds(),
    timestamp: null,
  },
  negotiationStatus: NegotiationStatus.Ongoing,
  terms: [
    createMockTerm({
      originId: '1',
      id: '1',
      sortIndex: 0,
      text: `This term was sent by your counterpart.\nYou can now counter the term yourself, accept it or mark it for rejection.`,
      lastEditedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      lastModifiedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
    }),
    createMockTerm({
      originId: '2',
      id: '2',
      sortIndex: 1,
      text: `Blue terms are terms that have been last edited by you. You can only edit them, your counterpart can only accept or reject them.`,
      lastEditedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      lastModifiedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
    }),
    createMockTerm({
      originId: null,
      id: '3',
      sortIndex: 2,
      text: `This term was created by you in the current round and only can be deleted in this round. After you commit the current round, you will not be able to delete it anymore.`,
      lastEditedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      lastModifiedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      status: NegotiationTermStatus.Ongoing,
    }),
    createMockTerm({
      originId: '4',
      id: '4',
      sortIndex: 3,
      text: `This term has been accepted by both sides.\nThis term is ready for recap, where you can make final edits.`,
      lastEditedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      lastModifiedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      status: NegotiationTermStatus.Accepted,
    }),
    createMockTerm({
      originId: '5',
      id: '5',
      sortIndex: 4,
      text: `This term has been marked rejected. You can decide if you want to accept the rejection or keep it.`,
      lastEditedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      lastModifiedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      status: NegotiationTermStatus.RejectionRequested,
    }),
    createMockTerm({
      originId: '6',
      id: '6',
      sortIndex: 5,
      text: `This term has been accepted as rejected. It will not show up in the recap.`,
      lastEditedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      lastModifiedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      status: NegotiationTermStatus.Rejected,
    }),
  ],
  subjects: [
    {
      id: '69c7662e-02db-46f8-b759-72ebcdf5900a',
      text: 'Subject to agreement on CP details',
      status: 'ongoing' as NegotiationSubjectStatus,
      party: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      originId: '9b3d5a22-a4f2-4fb6-8aa0-67bd21ae20bf',
      termId: null,
      roundResultId: '0aa0680e-ef31-48b0-84a9-52b6a5eb1ece',
      textLastUpdatedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      negotiationId: '45f979e3-b637-4a57-949e-fb231a4b26e8',
      lastModifiedBy: isCreator ? NegotiationParty.Counterpart : NegotiationParty.Creator,
      lastModifiedAt: dayjs().subtract(2, 'hours').toDate(),
    },
    {
      id: '72b32523-46f6-42a6-b74c-1a870a1fb8d6',
      text: 'Subject to owners reconfirmation / bod approval',
      status: 'ongoing' as NegotiationSubjectStatus,
      party: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      originId: '60b2d736-6ef5-4324-a4b7-66c06cdade84',
      termId: null,
      roundResultId: '0aa0680e-ef31-48b0-84a9-52b6a5eb1ece',
      textLastUpdatedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      negotiationId: '45f979e3-b637-4a57-949e-fb231a4b26e8',
      lastModifiedBy: isCreator ? NegotiationParty.Creator : NegotiationParty.Counterpart,
      lastModifiedAt: dayjs().subtract(2, 'hours').toDate(),
    },
  ],
});

export const getTutorialRoundResultModifications = (roundResultId: string): NegotiationRoundResultModification => ({
  roundResultId,
  termModifications: [],
  oldNegotiationStatus: NegotiationStatus.Ongoing,
  subjectModificationReports: [],
  newNegotiationStatus: NegotiationStatus.Ongoing,
  negotiationStatusChanged: true,
});
