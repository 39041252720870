import LabelWrapper from '../../../../atoms/LabelWrapper';
import {DropdownMultiValueLocation, getLocationSelectOption} from '../../../LocationOutput/LocationOutputRow';
import {getLocationValues} from '../../../LocationOutput/getLocationValues';
import locationService from '../../../../redux/ApiService/locationService';
import {GroupHeading} from '../../../../atoms/Select/otherComponents';
import {filterBranchDefinition} from './LocationCargoDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {useDispatch} from '../../../../redux/react-redux';
import {AsyncMultiSelect} from '../../../../atoms/Select/AsyncMultiSelect';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
  withVia?: boolean;
}

const LocationCargo = ({withVia, filterProviderApi}: Props) => {
  const dispatch = useDispatch();
  const searchAll = (search: string) => dispatch(locationService.searchAll(search));

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size={'medium'}>
      {({handleChange, values}) => (
        <div className="container">
          <div className="row bottom-fix">
            <div className="scol-12">
              <LabelWrapper label={'Load / Delivery'} htmlFor={'filter-load-delivery'}>
                <AsyncMultiSelect
                  id={`filter-load-delivery`}
                  name={`filter-load-delivery`}
                  value={values.loaddelivery}
                  onChange={value => handleChange('loaddelivery', getLocationValues(value))}
                  getOptionLabel={o => getLocationSelectOption(o, true)}
                  getOptionValue={option => option.id.toString()}
                  loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
                  cacheOptions
                  defaultOptions
                  placeholder="Enter port, country or area"
                  components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                />
              </LabelWrapper>
            </div>
            {withVia && (
              <div className="scol-12">
                <LabelWrapper label={'Trade / Via'} htmlFor={'filter-delivery-via'}>
                  <AsyncMultiSelect
                    id={`filter-delivery-via`}
                    name={`filter-delivery-via`}
                    value={values.deliveryvia}
                    onChange={value => handleChange('deliveryvia', getLocationValues(value))}
                    getOptionLabel={o => getLocationSelectOption(o, true)}
                    getOptionValue={option => option.id.toString()}
                    loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
                    cacheOptions
                    defaultOptions
                    placeholder="Enter port, country or area"
                    components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                  />
                </LabelWrapper>
              </div>
            )}
            <div className="scol-12">
              <LabelWrapper label={'Disch. / Redelivery'} htmlFor={'filter-discharge-redelivery'}>
                <AsyncMultiSelect
                  id={`filter-discharge-redelivery`}
                  name={`filter-discharge-redelivery`}
                  value={values.dischargeredelivery}
                  onChange={value => handleChange('dischargeredelivery', getLocationValues(value))}
                  getOptionLabel={o => getLocationSelectOption(o, true)}
                  getOptionValue={option => option.id.toString()}
                  loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
                  cacheOptions
                  defaultOptions
                  placeholder="Enter port, country or area"
                  components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                />
              </LabelWrapper>
            </div>
          </div>
        </div>
      )}
    </FilterButton>
  );
};

LocationCargo.defaultProps = {
  withVia: true,
};

export default LocationCargo;
