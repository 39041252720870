import {TourDefinition, TourId, getFinalStep} from '../../../components/OnboardingTour/TourDefinition';

const FILTERS_SELECTOR = '[data-debugname="Filters"]';

export const PortsTourDefinition: TourDefinition = {
  id: TourId.Ports,
  steps: [
    {
      title: "Let's meet: Ports",
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/4.%20Onboarding%20Ports.mp4',
      },
      pdfDownloadUrl: "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/05%20Ports/Let's%20meet%20ports.pdf",
      placement: 'center',
    },
    {
      title: 'How to use filters',
      target: FILTERS_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/4%20How%20to%20Ports/4.1%20How%20to%20filter%20ports%20by%20country%20&%20see%20expected%20vessels.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/05%20Ports/Filter%20by%20country%20and%20see%20vessel%20information.pdf',
      placement: 'bottom',
    },
    {
      title: 'How to see port details on the map',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/4%20How%20to%20Ports/4.2%20How%20to%20see%20port%20details%20on%20map.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/05%20Ports/See%20details%20on%20the%20map.pdf',
      placement: 'center',
    },
    getFinalStep('Ports'),
  ],
};
