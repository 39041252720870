import {UserDateTime} from '../../../../../../components/DateTime/UserDateTime';
import React, {FC} from 'react';
import {
  NegotiationSummary,
  NegotiationRoundResultSummary,
  NegotiationStatus,
} from '../../../../../../api/node-backend/generated';
import {CurrentRoundBubble, RoundResultBubble} from './RoundResultBubble';
import {EventContainer, EventBubble} from './shared';
import {NegotiationHistoryEventType} from '../getNegotiationHistoryEvents';
import styled from 'styled-components';
import {assertUnreachable} from '../../../../../../utils/assert';
import {NegotiationStatusColors, NegotiationColor} from '../../../../utils/colors';

type TimelineEventBubbleProps = {
  negotiation: NegotiationSummary;
  roundResult?: NegotiationRoundResultSummary;
  type: NegotiationHistoryEventType;
  date: Date | null;
  roundIndex?: number;
  isSelected: boolean;
  onSelectEvent?: (id: string | null) => void;
};

export const TimelineEventBubble: FC<TimelineEventBubbleProps> = ({
  roundIndex,
  negotiation,
  roundResult,
  type,
  date,
  isSelected,
  onSelectEvent,
}) => {
  const {party} = negotiation;
  const actor = negotiation[party];
  const {committedAt} = roundResult || {};
  const roundActor = roundResult ? negotiation[roundResult.party] : actor;
  const isSelf = committedAt === null || roundActor === actor;

  switch (type) {
    case NegotiationStatus.Offer:
      return (
        <GenericEventBubble
          event="Offer"
          label="Offer drafted"
          color={NegotiationStatusColors[NegotiationStatus.Offer]}
          date={date}
          isLarge
        />
      );
    case NegotiationStatus.Ongoing:
      return (
        <GenericEventBubble
          event="Ongoing"
          label="Negotiation started"
          color={NegotiationStatusColors[NegotiationStatus.Ongoing]}
          date={date}
          isLarge
        />
      );
    case NegotiationStatus.Recap:
      return (
        <GenericEventBubble
          event="Recap"
          label="Recap entered"
          color={NegotiationStatusColors[NegotiationStatus.Recap]}
          date={date}
          isLarge
        />
      );
    case NegotiationStatus.Settled:
      return (
        <GenericEventBubble
          event="Settled"
          label="Negotiation clean"
          color={NegotiationStatusColors[NegotiationStatus.Settled]}
          date={date}
          isLarge
        />
      );
    case NegotiationStatus.Cancelled:
      return (
        <GenericEventBubble
          event="Cancelled"
          label="Negotiation cancelled"
          color={NegotiationStatusColors[NegotiationStatus.Cancelled]}
          date={date}
          isLarge
        />
      );
    case 'waiting':
      return <GenericEventBubble event="Waiting" label="Waiting for reply" color="gray" date={date} />;
    case 'current':
      return <CurrentRoundBubble roundResult={roundResult!} />;
    case 'round':
      return (
        <RoundResultBubble
          actor={roundActor}
          isSelf={isSelf}
          roundResult={roundResult!}
          roundIndex={roundIndex!}
          isSelected={isSelected}
          onSelect={() => onSelectEvent?.(roundResult!.id)}
        />
      );
    default:
      assertUnreachable(type);
  }
};

type GenericEventBubbleProps = {
  event: string;
  label: string;
  color: NegotiationColor;
  date: Date | null;
  isLarge?: boolean;
  onSelect?: () => void;
};

const GenericEventBubble: FC<GenericEventBubbleProps> = ({event, label, color, date, isLarge, onSelect}) => (
  <EventContainer $color={color} onClick={onSelect} data-testid={`TimelineEventBubble${event}`}>
    <EventBubble $isLarge={isLarge}>
      <Label data-testid="Label">{label}</Label>
      {date && (
        <DateLabel data-testid="Date">
          <UserDateTime value={new Date(date).toISOString()} format="dateTime" />
        </DateLabel>
      )}
    </EventBubble>
  </EventContainer>
);

const Label = styled.span`
  font-weight: bold;
  white-space: pre;
`;

const DateLabel = styled.div`
  font-size: var(--font-size-sm);
  margin-top: 2px;
`;
