/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanyContact
 */
export interface CompanyContact {
  /**
   *
   * @type {string}
   * @memberof CompanyContact
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyContact
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyContact
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyContact
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyContact
   */
  fax?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyContact
   */
  website?: string | null;
}

export function CompanyContactFromJSON(json: any): CompanyContact {
  return CompanyContactFromJSONTyped(json, false);
}

export function CompanyContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyContact {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    fax: !exists(json, 'fax') ? undefined : json['fax'],
    website: !exists(json, 'website') ? undefined : json['website'],
  };
}

export function CompanyContactToJSON(value?: CompanyContact | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    phone: value.phone,
    fax: value.fax,
    website: value.website,
  };
}
