import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {DeleteTagRequest} from '../../../../api/symfony/generated';
import {ApiError} from '../../../../api/utils/ApiError';
import {tagApi} from '../../../../api/symfony/symfonyApi';

export const useDeleteTagMutation = (options: UseMutationOptions<void, ApiError, DeleteTagRequest>) => {
  return useMutation<void, ApiError, {id: number}>({
    mutationFn: async (request: DeleteTagRequest) => await tagApi.deleteTag(request),
    ...options,
  });
};
