import React, {FC} from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import {WorkspaceIcon} from './WorkspaceIcon';
import {Workspace} from './WorkspaceSelectTypes';
import {Spacer} from '../Spacer/Spacer';

/**
 * This is the button that displays the current value and triggers the popover.
 */
export const WorkspaceSelectButtonNarrow = styled(Button)`
  width: 100%;
  height: 55px;
  border: none;
  border-radius: 0 !important;

  font-weight: bold;
  line-height: 17px;

  padding: 4px 4px;

  color: var(--color-white-fixed) !important;
  background: var(--color-blue) !important;

  svg {
    fill: currentColor;
  }
`;

/**
 * The label that displays the current value.
 */
export const ButtonLabelNarrow: FC<{workspace: Workspace}> = ({workspace}) => {
  return (
    <ButtonLabelStyled>
      <IconWrapper>
        <WorkspaceIcon />
      </IconWrapper>
      <Spacer height={3} />
      <TitleWrapper>
        <Title>{workspace.name}</Title>
      </TitleWrapper>
    </ButtonLabelStyled>
  );
};

const ButtonLabelStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: var(--color-white-fixed) !important;
  background: var(--color-blue) !important;

  & > * {
    flex-shrink: 0;
  }
`;

const IconWrapper = styled.div`
  height: 23px;
  width: 24px;
  color: inherit !important;
`;

const TitleWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
`;

const Title = styled.div`
  max-width: 72px;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: Akkurat;
  font-style: normal;
  font-weight: bold;
  font-size: var(--font-size-xs);
  line-height: 12px;
  height: 12px;
  white-space: nowrap;
`;
