import styled from 'styled-components';

export const SelectorCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 10px 5px;
  padding: 10px 20px;
  margin: 0;
  border: var(--border-base);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);
`;
