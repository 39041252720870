import React, {FC, useEffect, useMemo, useState} from 'react';
import {NewTerm} from '../../../../../../../api/node-backend/generated';
import {Input, Radio} from 'antd';
import styled from 'styled-components';
import {parseTermsFromText} from './parseTerms';
import {TermPreview} from '../TermPreview';

type TermParserProps = {
  showPreviewIfEmpty?: boolean;
  onChangeTerms: (terms: NewTerm[]) => void;
};

export const TermParser: FC<TermParserProps> = ({showPreviewIfEmpty, onChangeTerms}) => {
  const [lineBreak, setLineBreak] = useState<'single' | 'double'>('single');
  const [text, setText] = useState('');

  const parsedTerms = useMemo(() => {
    return parseTermsFromText(text, lineBreak);
  }, [text, lineBreak]);

  useEffect(() => {
    onChangeTerms(parsedTerms);
  }, [onChangeTerms, parsedTerms]);

  return (
    <TermPreview terms={parsedTerms} showPreviewIfEmpty={showPreviewIfEmpty}>
      <TextArea
        value={text}
        onChange={event => setText(event.currentTarget.value)}
        placeholder="Copy and paste a contract here"
      />
      <RadioGroup
        options={[
          {label: 'Single line break', value: 'single'},
          {label: 'Double line break', value: 'double'},
        ]}
        onChange={event => setLineBreak(event.target.value)}
        value={lineBreak}
        optionType="button"
        size="small"
        buttonStyle="solid"
      />
    </TermPreview>
  );
};

const RadioGroup = styled(Radio.Group)`
  width: 100%;

  &.ant-radio-group-small .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
    &:first-of-type {
      border-start-start-radius: 0;
      border-end-start-radius: var(--border-radius-button);
    }
    &:last-of-type {
      border-end-end-radius: var(--border-radius-button);
      border-start-end-radius: 0;
    }
  }
`;

const TextArea = styled(Input.TextArea)`
  height: 100% !important;
  width: 100% !important;
  margin: 0;
  padding: 5px 10px;
  transition: none !important;
  border-radius: var(--border-radius-button) var(--border-radius-button) 0 0 !important;
`;
