import isEqual from 'lodash/isEqual';
import Cargo from '../../../../model/Cargo';
import {getStationLocationsFromCargo} from '../../../../screens/CargoDetail/CargoDetailHeader/getStationLocationsFromCargo';
import {getArcLayer, getIconLayer, getScatterplotLayer} from '../../../../screens/CargoDetail/CargoDetailHeader/layers';
import {StationLocation} from '../../../../screens/CargoDetail/CargoDetailHeader/useGetCargoRouteLayer';
import {Coordinates} from '../../../../utils/Coordinates';
import {hexToRgb} from '../../../SeaboMap/helper';
import {ProjectMapLayer} from '../../types';
import {RGBColor} from '../../../../utils/deck.gl/deck.gl-types';

type ShortListCargoCoordinates = {
  from?: Coordinates;
  to?: Coordinates;
  color: RGBColor;
  id: number;
};

export const getCargoesLayer = ({
  selectedShortlistItemId,
  shortlistCargoes,
  onShortlistItemClick,
  getCoordinatesFromLocation,
}: {
  showAisVessel?: boolean;
  selectedShortlistItemId: number | null;
  shortlistCargoes?: {cargo: Cargo}[];
  onShortlistItemClick?: (id: number) => void;
  getCoordinatesFromLocation: (location?: StationLocation | undefined) => Coordinates | undefined;
}): {
  cargoesCoordinates: ShortListCargoCoordinates[];
  layers: (ProjectMapLayer | null)[];
} => {
  const cargoesCoordinates: ShortListCargoCoordinates[] =
    shortlistCargoes?.map(({cargo}: {cargo: Cargo}) => {
      const {fromLocations, toLocations} = getStationLocationsFromCargo(cargo);
      return {
        from: getCoordinatesFromLocation(fromLocations[0]),
        to: getCoordinatesFromLocation(toLocations[0]),
        color: hexToRgb(Cargo.getColor(cargo.contractType)) ?? [255, 255, 255],
        id: cargo.id,
      };
    }) ?? [];

  const cargoLayers: ProjectMapLayer[] = [];
  cargoesCoordinates.forEach(({from, to, color, id}: ShortListCargoCoordinates, index) => {
    if (from && !cargoLayers.some(layer => Array.isArray(layer?.props.data) && isEqual(layer?.props.data[0], from))) {
      cargoLayers.push(
        getScatterplotLayer({
          index,
          color,
          coordinates: from,
          active: selectedShortlistItemId === id,
          onClick: () => onShortlistItemClick?.(id),
        })
      );
    }
    if (to && !cargoLayers.some(layer => Array.isArray(layer?.props.data) && isEqual(layer?.props.data[0], to))) {
      cargoLayers.push(
        getIconLayer({
          index,
          color,
          coordinates: to,
          active: selectedShortlistItemId === id,
          type: 'marker',
          onClick: () => onShortlistItemClick?.(id),
        })
      );
    }
    if (from && to) {
      cargoLayers.push(getArcLayer({index, color, coordinates: [from, to]}));
    }
  });

  const layers = cargoLayers;

  return {
    layers,
    cargoesCoordinates,
  };
};
