import {AISRecord} from './AISRecord';
import dayjs, {Dayjs} from 'dayjs';
import {AISRecordsQueryResult} from './AISRecordsQueryResult';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {aisRecordApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {AisRecordResponse, AisRecordsResponse} from '../../../../../../api/node-backend/generated';
import {LonLatCoordinates} from '../../../../../../utils/Coordinates';

export const useFreshAisRecordsQuery = ({
  imo,
  from,
  options,
}: {
  imo: number;
  from: Dayjs | undefined;
  options: {enabled: boolean};
}): AISRecordsQueryResult => {
  const fromString = from?.toISOString();
  return useQuery({
    queryKey: ['freshAisRecords', imo, fromString],
    queryFn: async () => {
      if (!fromString) {
        // That happens if the ship is no longer in operation since this feature has been available.
        return [];
      }

      const response: AisRecordsResponse = await aisRecordApi.getFreshAISRecords({imo, from: fromString});
      const aisRecords: AISRecord[] = response.items.map(mapAisRecordResponseToAisRecord);
      return aisRecords;
    },
    staleTime: Infinity,
    placeholderData: keepPreviousData,
    retry: false,
    enabled: options.enabled,
  }) as AISRecordsQueryResult;
};

const mapAisRecordResponseToAisRecord = (record: AisRecordResponse): AISRecord => ({
  ...record,
  coordinates: record.coordinates as LonLatCoordinates,
  timestamp: dayjs(record.timestamp),
});
