export enum ContractCalculationQuantity {
  TIME = 'TIME',
  'QUANTITY' = 'QUANTITY',
}

export const getContractCalculationQuantity = (contactType: string): ContractCalculationQuantity => {
  switch (contactType) {
    case 'tct':
    // Fallthrough
    case 'tc':
    // Fallthrough
    case 'bbc':
    // Fallthrough
    case 'pc':
      return ContractCalculationQuantity.TIME;
    case 'vc':
      return ContractCalculationQuantity.QUANTITY;
    default:
      return ContractCalculationQuantity.QUANTITY;
  }
};
