import {FC} from 'react';
import {FastVesselImportParsedValues, ImportExcel} from './ImportExcel';
import {Preview} from './Preview/Preview';
import {SelectImoColumn} from './SelectImoColumn';
import {assertUnreachable} from '../../../../utils/assert';

export enum FastVesselImportModalStep {
  'ImportExcel' = 0,
  'SelectImoColumn' = 1,
  'Preview' = 2,
}

export const FastVesselImportModalContent: FC<{
  values: FastVesselImportParsedValues;
  step: FastVesselImportModalStep;
  onChangeImoColumn: (columnKey: FastVesselImportParsedValues['predictedImoColumn']) => void;
  onChangeVesselGrid: (grid: FastVesselImportParsedValues['vessels']) => void;
  onChangeAvailableColumns: (grid: FastVesselImportParsedValues['availableColumns']) => void;
  onGoToNextStep: () => void;
}> = ({step, values, onChangeAvailableColumns, onGoToNextStep, onChangeImoColumn, onChangeVesselGrid}) => {
  switch (step) {
    case FastVesselImportModalStep.ImportExcel:
      return (
        <ImportExcel
          onChange={values => {
            onChangeImoColumn(values.predictedImoColumn);
            onChangeVesselGrid(values.vessels);
            onChangeAvailableColumns(values.availableColumns);
            onGoToNextStep();
          }}
        />
      );
    case FastVesselImportModalStep.SelectImoColumn:
      return <SelectImoColumn onGoToNextStep={onGoToNextStep} onChangeImoColumn={onChangeImoColumn} values={values} />;
    case FastVesselImportModalStep.Preview:
      return <Preview values={values} onGoToNextStep={onGoToNextStep} />;
    default:
      assertUnreachable(step);
  }
};
