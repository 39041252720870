/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PortSize {
  Tiny = 'tiny',
  Small = 'small',
  Large = 'large',
}

export function PortSizeFromJSON(json: any): PortSize {
  return PortSizeFromJSONTyped(json, false);
}

export function PortSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortSize {
  return json as PortSize;
}

export function PortSizeToJSON(value?: PortSize | null): any {
  return value as any;
}
