import {QueryClient} from '@tanstack/react-query';
import {
  FilterBranchContext,
  FilterContextType,
  FilterBranchDefinitionsDefault,
  FilterDatabaseType,
  FilterInternalType,
} from '../FilterBranchDefinition';

/**
 * Returns a filterSettings object with default values.
 *
 */
export const makeDefaultFilter = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  filterBranchDefinitions: FilterBranchDefinitions
): FilterInternalType<FilterBranchDefinitions> => {
  const internal = {} as FilterInternalType<FilterBranchDefinitions>;
  for (const branchKey of Object.keys(filterBranchDefinitions) as (keyof FilterBranchDefinitions)[]) {
    const filterBranchDefinition = filterBranchDefinitions[branchKey];
    const {defaults} = filterBranchDefinition;
    internal[branchKey] = defaults;
  }
  return internal;
};

/**
 * Loads the lookup data required to convert a filter from database to internal format.
 */
export const loadFilterContext = async <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  queryClient: QueryClient,
  filterBranchDefinitions: FilterBranchDefinitions
): Promise<FilterContextType<FilterBranchDefinitions>> => {
  const allBranches: (keyof FilterBranchDefinitions)[] = [];
  const allPromises: (
    | Promise<FilterBranchContext<FilterBranchDefinitions[keyof FilterBranchDefinitions]>>
    | undefined
  )[] = [];
  for (const branchKey of Object.keys(filterBranchDefinitions) as (keyof FilterBranchDefinitions)[]) {
    const filterBranchDefinition = filterBranchDefinitions[branchKey];
    const {branch, loadBranchContext} = filterBranchDefinition;
    if (!loadBranchContext) {
      continue;
    }
    const promise = loadBranchContext(queryClient);
    allPromises.push(promise);
    allBranches.push(branch);
  }
  const allContexts = await Promise.all(allPromises);
  const filterContext = {} as FilterContextType<FilterBranchDefinitions>;
  for (const [index, branch] of allBranches.entries()) {
    const context = allContexts[index];
    if (!context) {
      continue;
    }
    filterContext[branch] = context;
  }
  return filterContext;
};

export const transformFilterToDatabase = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  internal: Partial<FilterInternalType<FilterBranchDefinitions>>,
  filterBranchDefinitions: FilterBranchDefinitions
) => {
  const database: Partial<FilterDatabaseType<FilterBranchDefinitions>> = {};
  for (const branchKey of Object.keys(filterBranchDefinitions) as ReadonlyArray<keyof FilterBranchDefinitions>) {
    const {toDatabase} = filterBranchDefinitions[branchKey];
    const currentValue = internal[branchKey];
    if (currentValue === null || currentValue === undefined) {
      // Don't call toDatabase on null values, it may throw.
      // We don't store the branch in the database and assume
      // that later when loading from database it will be filled
      // with the default.
      continue;
    }
    database[branchKey] = toDatabase(currentValue);
  }
  return database;
};

export const transformFilterFromDatabase = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  database: Partial<FilterDatabaseType<FilterBranchDefinitions>> | undefined,
  filterBranchContexts: FilterContextType<FilterBranchDefinitions>,
  filterBranchDefinitions: FilterBranchDefinitions
) => {
  const internal: Partial<FilterInternalType<FilterBranchDefinitions>> = {};
  if (!database) {
    return internal;
  }
  for (const branchKey of Object.keys(filterBranchDefinitions) as (keyof FilterBranchDefinitions)[]) {
    const {fromDatabase} = filterBranchDefinitions[branchKey];
    const branchValue = database[branchKey];
    if (!branchValue) {
      // Nothing to convert. Will be filled with the defaults later on.
      continue;
    }
    internal[branchKey] = fromDatabase(branchValue, filterBranchContexts[branchKey]);
  }
  return internal;
};
