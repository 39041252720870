import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {CurrentUser} from '../../../api/symfony/generated';
import {Breadcrumb} from '../../../atoms/Breadcrumb';
import {Button} from '../../../atoms/Button/Button';
import {SideNote} from '../../../atoms/SideNote/SideNote';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {ModalActions} from '../../../redux/Modal';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {RootState} from '../../../redux/store';
import {CompanyVerifyRequestBody, useUpdateCompanyMutation} from './useUpdateCompanyMutation';
import {useLocation} from 'react-router-dom';
import {Company} from '../../../redux/ApiService/CompanyService/Company';
import {VerifyEditCompanyModal} from '../../../components/Modal/VerifyEditCompany';

const regexForCompanySettings = /^\/company\/\d+\/settings/;

export const VerifyCompanyScreen = () => {
  const dispatch = useDispatch();
  const company: Company = useSelector((state: RootState) => state.company.data);

  const location = useLocation();

  const isCompanyAdministrationScreen = regexForCompanySettings.test(location.pathname);

  const updateCompanyMutation = useUpdateCompanyMutation({
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(CompanyActions.getCompany(company.id));
    },
  });
  const loading: CurrentUser | undefined = useSelector((state: RootState) => state.user?.payload);
  const companyError: boolean = useSelector((state: RootState) => state.company.error);
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const {name, business, contact, address} = company;

  const hasContent = (content: string | null | undefined): string | ReactNode => {
    if (content) {
      return content;
    } else {
      return <Missing>Missing</Missing>;
    }
  };

  const verifyCompany = async () => {
    const updatedCompany: CompanyVerifyRequestBody = {isVerified: true};
    await updateCompanyMutation.mutateAsync({id: company.id, updatedCompanyInformation: updatedCompany});
    await dispatch(CompanyActions.getCompany(company.id));
  };

  const onCompanyEditModalShow = () => {
    showModal(<VerifyEditCompanyModal companyData={company} />);
  };

  return (
    <React.Fragment>
      <Breadcrumb items={[{title: 'Company'}]} />

      {loading && (
        <StyledVerifyCompany>
          <VerifyCompanyHeadline>
            <Header>Please check your company information</Header>
            <SubHeader>We have prefilled your information. Please check and complete it now.</SubHeader>
          </VerifyCompanyHeadline>

          <Verification>
            <VerificationCompanyDetails
              name={name}
              business={hasContent(business)}
              addressCity={hasContent(address.city)}
              addressCountry={hasContent(address.country)}
            />
            <VerificationCompanyContact
              firstName={hasContent(contact.firstName)}
              lastName={hasContent(contact.lastName)}
              email={hasContent(contact.email)}
              website={hasContent(contact.website)}
              phone={hasContent(contact.phone)}
              fax={hasContent(contact.fax)}
              addressName={hasContent(address.name)}
              addressStreet={hasContent(address.street)}
              addressNumber={hasContent(address.streetNumber)}
              addressStreet2={hasContent(address.street2)}
              addressZip={hasContent(address.zip)}
              addressCity={hasContent(address.city)}
              addressCountry={hasContent(address.country)}
              taxId={hasContent(company.taxId)}
            />
          </Verification>
          <ButtonsContainer>
            <Button label={'Edit'} bold primary upper onClick={() => onCompanyEditModalShow()} />
            <Button
              disabled={updateCompanyMutation.isError || updateCompanyMutation.isPending}
              label={
                isCompanyAdministrationScreen
                  ? 'Verify and proceed to Company administration'
                  : 'Verify and proceed to Company profile'
              }
              bold
              primary
              upper
              styleOverride={{marginLeft: 20}}
              onClick={() => verifyCompany()}
            />
          </ButtonsContainer>
        </StyledVerifyCompany>
      )}
      {(companyError || updateCompanyMutation.isError) && (
        <StyledError>
          Something went wrong, please reload the site or <a href="https://www.seabo.com/contact">contact us</a>
        </StyledError>
      )}
    </React.Fragment>
  );
};

const VerificationCompanyDetails = ({
  name,
  business,
  addressCity,
  addressCountry,
}: {
  name: string | ReactNode;
  business: string | ReactNode;
  addressCity: string | ReactNode;
  addressCountry: string | ReactNode;
}) => {
  return (
    <CompanyDetails>
      <CompanyDetailsHeadline>Company details</CompanyDetailsHeadline>
      <CompanyNames>
        <CompanyNamesHeadline>{name}</CompanyNamesHeadline>
        <CompanyNamesBusiness>{business}</CompanyNamesBusiness>
        <CompanyNamesLocation>
          {addressCity}, {addressCountry}
        </CompanyNamesLocation>
      </CompanyNames>
    </CompanyDetails>
  );
};

const VerificationCompanyContact = ({
  firstName,
  lastName,
  email,
  website,
  phone,
  fax,
  addressName,
  addressStreet,
  addressNumber,
  addressStreet2,
  addressZip,
  addressCity,
  addressCountry,
  taxId,
}: {
  firstName: string | ReactNode;
  lastName: string | ReactNode;
  email: string | ReactNode;
  website: string | ReactNode;
  phone: string | ReactNode;
  fax: string | ReactNode;
  addressName: string | ReactNode;
  addressStreet: string | ReactNode;
  addressNumber: string | ReactNode;
  addressStreet2: string | ReactNode;
  addressZip: string | ReactNode;
  addressCity: string | ReactNode;
  addressCountry: string | ReactNode;
  taxId: string | ReactNode;
}) => {
  return (
    <ContactDetails>
      <CompanyDetailsHeadline>Contact details</CompanyDetailsHeadline>
      <SideNote title={'first name'} content={firstName} />
      <SideNote title={'last name'} content={lastName} />
      <SideNote title={'e-mail'} content={email} />
      <SideNote title={'website'} content={website} />
      <SideNote title={'phone'} content={phone} />
      <SideNote title={'fax'} content={fax} />
      <SideNote
        title={'postal address'}
        content={
          <React.Fragment>
            <div>{addressName}</div>
            <div>
              {addressStreet} {addressNumber}
            </div>
            <div>{addressStreet2}</div>
            <div>
              {addressZip} {addressCity}
            </div>
            <div>{addressCountry}</div>
          </React.Fragment>
        }
      />
      <SideNote title={'taxId'} content={taxId} />
    </ContactDetails>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

const VerifyCompanyHeadline = styled.div`
  margin-bottom: 40px;
`;

const Header = styled.div`
  font-size: var(--font-size-2xl);
  font-weight: bold;
`;

const SubHeader = styled.div`
  font-size: var(--font-size-lg);
  color: var(--color-gray-2);
`;

const Missing = styled.div`
  font-weight: bold;
  color: var(--color-red);
  display: inline;
`;

const CompanyNames = styled.div`
  margin-bottom: 20px;
`;

const CompanyNamesHeadline = styled.div`
  font-size: var(--font-size-lg);
`;
const CompanyNamesLocation = styled.div`
  color: var(--color-gray-2);
  display: inline;
`;
const CompanyNamesBusiness = styled.div`
  color: var(--color-gray-2);
`;

const StyledError = styled.div`
  color: var(--color-red);
`;

const CompanyDetails = styled.div`
  margin-top: 20px;
  padding: 30px;
  min-width: 65%;
`;
const ContactDetails = styled.div`
  margin-top: 20px;
  background-color: var(--color-white);
  padding: 30px;
  min-width: 35%;
`;

const CompanyDetailsHeadline = styled.div`
  font-size: var(--font-size-lg);
  font-weight: bold;
  margin-bottom: 25px;
`;

const StyledVerifyCompany = styled.div`
  position: relative;
  min-width: 1024px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Verification = styled.div`
  background-color: var(--color-white);
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;
