import {SeaboMap} from '../../../../components/SeaboMap/SeaboMap';
import {TODO} from '../../../../utils/TODO';
import {useMemo, FC} from 'react';
import {TradingAreaLayer} from '../../../../components/SeaboMap/layers';
import {TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import {ClassInformation} from '../classInformation';
import {MapPlayerBox} from './MapPlayerBox';
import {usePlayer} from './usePlayer';
import {useGetTradingAreasQuery} from './useGetTradingAreasQuery';
import {useMapApi} from '../../../../components/SeaboMap/useMapApi';
import LoadingOverlay from '../../../../atoms/Loading/LoadingOverlay';
import {getFillColorOfOneTradingArea} from './getFillColorOfOneTradingArea';

export type TiedUpMapProps = {
  tradingAreas: TradingAreaTiedUpVesselsStatistic[];
  vesselClass: ClassInformation;
  selectedTradingArea: string;
  onSelectTradingArea: (tradingArea: string) => void;
  hoveredTradingArea: string;
  onHoverTradingArea: (tradingArea: string) => void;
};

export const Map: FC<TiedUpMapProps> = ({
  tradingAreas,
  vesselClass,
  selectedTradingArea,
  onSelectTradingArea,
  hoveredTradingArea,
  onHoverTradingArea,
}) => {
  const {isPlaying, setIsPlaying, setDateIndex, dateIndex} = usePlayer({
    lastIndexAtTheTimeSeries: tradingAreas[0].oneDayStatistics.length - 1,
  });
  const mapApi = useMapApi();

  const tradingAreasQuery = useGetTradingAreasQuery();

  const data = useMemo(() => {
    return tradingAreasQuery.data?.map(item => {
      const statistic = tradingAreas.find(statistic => item.name === statistic.tradingAreaName);

      if (!statistic) {
        throw new Error('Trading area not found for ' + item.name);
      }

      return {
        type: 'Feature',
        properties: {
          ...item,
          statistic,
        },
        geometry: {type: 'Polygon', coordinates: item.polygon},
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingAreasQuery.data, tradingAreas, vesselClass]);

  return (
    <>
      {!mapApi.state.mapReady && <LoadingOverlay />}
      <SeaboMap
        mapApi={mapApi}
        initialViewState={{
          zoom: 1,
          maxZoom: 2,
          minZoom: 0.5,
          latitude: 0,
          longitude: 0,
        }}
        vesselsToVisualize={{
          data: [],
        }}
        initialMapSettings={{
          mapStyle: 'DARK_MODE',
          switches: {
            // eslint-disable-next-line camelcase
            trading_area_names: {state: true, vState: true, vDisabled: false},
            // eslint-disable-next-line camelcase
            layer_trading_area: {
              state: true,
              vState: true,
              vDisabled: false,
            },
          },
        }}
        defaults={'tied_up_vessels'}
        settingsPersistent={false}
        showPopups={false}
        showControl={false}
        showLegend={false}
        showNavigation={false}
        componentOnTop={
          <MapPlayerBox
            tradingAreas={tradingAreas}
            onChangeDateIndex={setDateIndex}
            dateIndex={dateIndex}
            isPlaying={isPlaying}
            onToggleIsPlay={() => {
              setDateIndex(0);
              setIsPlaying(!isPlaying);
            }}
          />
        }
        layers={[
          new TradingAreaLayer({
            id: 'TradingAreaTiedUpLayer',
            data: data,
            extruded: true,
            filled: true,
            wireframe: true,
            showLabels: true,
            selected: selectedTradingArea,
            onClick: (e: TODO) => onSelectTradingArea(e.object.properties.name),
            onHover: (e: TODO) => onHoverTradingArea(e.object?.properties.name ?? ''),
            getLineColor: () => [0, 0, 0, 16],
            getFillColor: (item: TODO) =>
              getFillColorOfOneTradingArea(item, vesselClass, dateIndex, hoveredTradingArea),
          }),
        ]}
      />
    </>
  );
};
