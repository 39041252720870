import {WritableDraft} from 'immer/dist/internal';
import {InputState} from '../../utils/voyageChartercalculation/voyageChartercalculation';
import {CargoInput} from '../../utils/CargoTypes';
import {VoyageInput} from '../../VoyageInformation/VoyageTypes';

export const applyPreviousVoyageInformation = (
  inputState: WritableDraft<InputState>,
  cargo: CargoInput,
  voyage: VoyageInput
) => {
  inputState.cargo = {...inputState.cargo, ...cargo};
  inputState.voyage = {...inputState.voyage, ...voyage};
  return inputState;
};
