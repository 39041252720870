import {Form, Input, List, Space, Tooltip} from 'antd';
import {VoyageRoute} from '../../VoyageTypes';
import NoteIcon from '../../../utils/NoteIcon';
import {blankVoyageRouteOutput, CalcTimeLineRoute} from '../../VoyageTypes';
import {VesselConsumptionMode} from '../../../VesselInformation/VesselTypes';
import {getFormattedDateOrNotAvailable} from '../../../../../../utils/DateTime';
import {Definitions} from '../../Definitions/Definitions';
import {RouteHeaderRow} from './RouteHeaderRow';
import {Value} from '../../Definitions/Value';
import {ValueRow} from '../../Definitions/ValueRow';
import {DurationCell} from '../../utils/DurationCell';
import numeral from 'numeral';
import {useChangeConsumptionInVoyagePointOrVoyageRoute} from '../../../Context/utils/useChangeConsumptionInVoyagePointOrVoyageRoute';
import * as LegRow from '../../LegRow';
import {FuelTypeSelect} from '../../../VesselInformation/SpeedAndConsumtion/EditModal';
import {ConsumptionOrSpeedField} from '../../utils/ConsumptionOrSpeedField';
import {Co2EmissionValueCell} from '../Co2EmissionValueCell';
import {StyledFormItem} from '../StyledFormItem';
import {VoyageCalculationButton} from '../../../utils/VoyageCalculationButton';

export const RouteEditView = (props: {
  item: CalcTimeLineRoute;
  consumptionModes: VesselConsumptionMode[];
  area: string;
  onDeactivateEditMode: () => void;
  onChangeRoute: (route: Partial<VoyageRoute>) => void;
}) => {
  const {item} = props;
  const route = item.item;
  const [form] = Form.useForm();

  const outputItem = item.outputItem ?? blankVoyageRouteOutput;

  useChangeConsumptionInVoyagePointOrVoyageRoute();

  return (
    <Form<VoyageRoute>
      size={'middle'}
      onValuesChange={v => {
        const changes: Partial<VoyageRoute> = form.getFieldsValue();
        /*
         If the consumption is changed via the form,
         this is always to be interpreted as a manual change,
         which implies that it cannot be said if it is eco.
           */
        if (v.consumption) {
          changes.consumption = {...route.consumption, ...v.consumption, manuallyAdded: true, isEco: undefined};
        }

        props.onChangeRoute(changes);
      }}
      form={form}
      initialValues={route}
      onFinish={() => {
        props.onDeactivateEditMode();
      }}>
      <LegRow.Wrapper>
        <List.Item.Meta
          title={
            <LegRow.Header>
              <div key={'name'} style={{color: 'var(--color-blue)', fontWeight: 700}}>
                Sea voyage
              </div>
              {route.note && route.note.length > 0 && (
                <Tooltip title={route.note}>
                  <NoteIcon />
                </Tooltip>
              )}
              <VoyageCalculationButton
                type={'primary'}
                size="small"
                data-cy="legRowSubmitEdit"
                onClick={() => form.submit()}>
                Save
              </VoyageCalculationButton>
            </LegRow.Header>
          }
          description={
            <>
              <Definitions>
                <RouteHeaderRow />
                <ValueRow>
                  <Value>
                    <Space.Compact>
                      <StyledFormItem
                        noStyle
                        name={['consumption', 'mainConsumption']}
                        normalize={s => {
                          const n = parseFloat(s);
                          if (isNaN(n)) {
                            return undefined;
                          }
                          if (n < 0) {
                            return 0;
                          }
                          return n;
                        }}>
                        <ConsumptionOrSpeedField
                          field={'consumption.mainConsumption'}
                          area={props.area}
                          item={props.item}
                          style={{width: 80}}
                          consumptionModes={props.consumptionModes}
                          onChangeCompleteConsumption={consumption => {
                            form.setFieldsValue({consumption});
                            props.onChangeRoute({
                              ...route,
                              consumption,
                            });
                          }}
                        />
                      </StyledFormItem>
                      <StyledFormItem noStyle name={['consumption', 'mainFuelType']}>
                        <FuelTypeSelect dataCy={'mainFuelType'} size="small" />
                      </StyledFormItem>
                    </Space.Compact>
                  </Value>
                  <Value>
                    <Space.Compact>
                      <StyledFormItem
                        noStyle
                        name={['consumption', 'auxConsumption']}
                        normalize={s => {
                          const n = parseFloat(s);
                          if (isNaN(n)) {
                            return undefined;
                          }
                          if (n < 0) {
                            return 0;
                          }
                          return n;
                        }}>
                        <ConsumptionOrSpeedField
                          field={'consumption.auxConsumption'}
                          area={props.area}
                          item={props.item}
                          style={{width: 80}}
                          consumptionModes={props.consumptionModes}
                          onChangeCompleteConsumption={consumption => {
                            form.setFieldsValue({consumption});
                            props.onChangeRoute({
                              ...route,
                              consumption,
                            });
                          }}
                        />
                      </StyledFormItem>
                      <StyledFormItem noStyle name={['consumption', 'auxFuelType']}>
                        <FuelTypeSelect dataCy={'auxFuelType'} size="small" />
                      </StyledFormItem>
                    </Space.Compact>
                  </Value>
                  <Value>
                    <StyledFormItem
                      name={['consumption', 'speed']}
                      normalize={s => {
                        const n = parseFloat(s);
                        if (isNaN(n)) {
                          return undefined;
                        }
                        if (n < 0) {
                          return 0;
                        }
                        return n;
                      }}>
                      <ConsumptionOrSpeedField
                        area={props.area}
                        field={'consumption.speed'}
                        style={{width: 130}}
                        consumptionModes={props.consumptionModes}
                        onChangeCompleteConsumption={consumption => {
                          form.setFieldsValue({consumption});
                        }}
                        item={props.item}
                      />
                    </StyledFormItem>
                  </Value>
                  <Value>
                    <StyledFormItem
                      name={'seaMargin'}
                      normalize={s => {
                        const n = parseFloat(s);
                        return isNaN(n) ? undefined : n;
                      }}>
                      <Input type={'number'} style={{width: 220}} suffix={'%'} size="small" />
                    </StyledFormItem>
                  </Value>
                  <Value>{numeral(route.distance).format('0,0[.]00')} nm</Value>
                  <Value>{numeral(route.distanceInSeca).format('0,0[.]00')} nm</Value>
                  <Value>{numeral(route.distanceInPiracy).format('0,0[.]00')} nm</Value>
                  <DurationCell route={item} />
                  <Co2EmissionValueCell route={route} routeOutput={outputItem} />
                  <Value>{getFormattedDateOrNotAvailable(outputItem.startDate)}</Value>
                  <Value>{getFormattedDateOrNotAvailable(outputItem.endDate)}</Value>
                </ValueRow>
              </Definitions>
            </>
          }
        />
      </LegRow.Wrapper>
    </Form>
  );
};
