import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationApi} from '../../../../api/node-backend/nodeBackendApis';
import {CreateNegotiationOperationRequest, Negotiation} from '../../../../api/node-backend/generated';
import {ApiError} from '../../../../api/utils/ApiError';

export const useCreateNegotiationMutation = (
  options?: UseMutationOptions<Negotiation, ApiError, CreateNegotiationOperationRequest>
) =>
  useMutation({
    mutationFn: async ({createNegotiationRequest}: CreateNegotiationOperationRequest) => {
      const result = negotiationNegotiationApi.createNegotiation({createNegotiationRequest});
      return result;
    },
    ...options,
  });
