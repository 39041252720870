import {Col, Row} from 'antd';
import React, {FC} from 'react';
import {AccountSegmentCheckboxButton} from '../AccountSegmentCheckboxButton';
import {availableSizes} from './availableSizes';

export const SegmentSizeSelector: FC<{
  segment: string;
  onChangeItem: (item: string) => void;
  values: string[];
  colSpanXxl?: number;
}> = ({values, segment, onChangeItem, colSpanXxl = 8}) => {
  const isBunker = segment === 'bulker';
  const options = isBunker ? availableSizes.bulker : availableSizes.container;
  return (
    <Row gutter={[16, 16]} style={{marginBottom: 12, flexGrow: 1}}>
      {options.map(sizeKey => {
        const isActive = values.includes(sizeKey);
        const label = sizeKey.replace('_', ' ');
        return (
          <Col key={sizeKey} md={24} xl={24} xxl={colSpanXxl}>
            <AccountSegmentCheckboxButton
              isSmall
              dataTestid={`SegmentSizeButton_${sizeKey}`}
              checked={isActive}
              onClick={() => onChangeItem(sizeKey)}>
              {label}
            </AccountSegmentCheckboxButton>
          </Col>
        );
      })}
    </Row>
  );
};
