import {Button} from '../../Button/Button';
import EmptyImage from './emptyStateProjects.png';

type Props = {
  onClick?: $TSFixMeFunction;
};

const ProjectEmptyState = ({onClick}: Props) => {
  return (
    <div className="empty-state">
      <div className="empty-state__content">
        <img src={EmptyImage} alt={'Empty state project'} />
        <div className="empty-state__headline">Let's create your first project!</div>
        <div className="empty-state__sub-headline">There are currently no projects in your list.</div>
        <Button label="CREATE PROJECT" primary large onClick={onClick} bold />
      </div>
    </div>
  );
};

export default ProjectEmptyState;
