import {Tooltip} from 'antd';
import {FC} from 'react';
import {ActionsContainer, StatefulIcon} from './shared';
import {GhostButton} from '../../../../Components/shared';
import {TermActions as TermActionsType} from '../utils/useGetTermActionsWithMutations';
import {DerivedNegotiationTermState} from '../utils/getDerivedTermState';

type TermActionAreaProps = {
  termActions: TermActionsType;
  termState: Partial<DerivedNegotiationTermState>;
  actionsBusy?: boolean;
  onEdit?: () => void;
};

export const TermActionArea: FC<TermActionAreaProps> = ({termActions, termState, actionsBusy, onEdit}) => {
  const {canBeEdited, canBeDeleted, canBeAccepted, canBeRejectionRequested, canBeRejectRequestSettled, canBeReset} =
    termState;

  const {acceptTerm, requestTermRejection, rejectTermRejection, acceptTermRejection, resetTerm, deleteTerm} =
    termActions;

  return (
    <ActionsContainer data-testid="TermActions">
      {canBeEdited && (
        <Tooltip title="Edit term" trigger="hover">
          <GhostButton data-testid="TermEditButton" onClick={onEdit} disabled={actionsBusy}>
            <StatefulIcon type="edit" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeAccepted && (
        <Tooltip title="Accept term" trigger="hover">
          <GhostButton data-testid="TermAcceptButton" onClick={acceptTerm} disabled={actionsBusy}>
            <StatefulIcon type="checkmark" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeRejectionRequested && (
        <Tooltip title="Request to reject term" trigger="hover">
          <GhostButton data-testid="TermRequestRejectionButton" onClick={requestTermRejection} disabled={actionsBusy}>
            <StatefulIcon type="close" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeRejectRequestSettled && (
        <Tooltip title="Accept to reject term" trigger="hover">
          <GhostButton data-testid="TermAcceptRejectionButton" onClick={acceptTermRejection} disabled={actionsBusy}>
            <StatefulIcon type="checkmark" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeRejectRequestSettled && (
        <Tooltip title="Reset to ongoing (reject term rejection)" trigger="hover">
          <GhostButton data-testid="TermRejectRejectionButton" onClick={rejectTermRejection} disabled={actionsBusy}>
            <StatefulIcon type="close" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeReset && (
        <Tooltip title="Undo changes" trigger="hover">
          <GhostButton data-testid="ResetButton" onClick={resetTerm} disabled={actionsBusy}>
            <StatefulIcon type="undo" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeDeleted && (
        <Tooltip title="Delete term" trigger="hover">
          <GhostButton data-testid="TermDeleteButton" onClick={deleteTerm} disabled={actionsBusy}>
            <StatefulIcon type="delete" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
    </ActionsContainer>
  );
};
