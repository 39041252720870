import {ConsumptionTransaction} from '../../ConsumptionTransaction';
import sumBy from 'lodash/sumBy';

/**
 * Returns the cost for a given quantity at the end of the time period, according to the avg principle
 */
export const avg = ({
  filteredTransactions,
  quantity,
}: {
  filteredTransactions: ConsumptionTransaction[];
  quantity: number;
}): number => {
  const positiveTransactions = filteredTransactions.filter(transaction => transaction.quantity > 0);
  const valueOfAllPositiveTransactions = sumBy(
    positiveTransactions,
    transaction => transaction.quantity * (transaction.pricePerUnit ?? 0)
  );
  const quantityOfAllPositiveTransactions = sumBy(positiveTransactions, transaction => transaction.quantity);
  const avgPrice = valueOfAllPositiveTransactions / quantityOfAllPositiveTransactions;
  return quantity * avgPrice;
};
