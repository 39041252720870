import React, {CSSProperties} from 'react';
import classNames from 'classnames';
import Icon, {IconColor, IconSize, IconType} from '../Icon';
import {cleanIdentifier} from '../../utils/helper';

import './style.scss';

type Props = {
  id?: string;
  title?: string;
  onClick?: $TSFixMeFunction;
  primary?: boolean;
  disabled?: boolean;
  buttonStyle?: CSSProperties;
  type: IconType;
  iconSize?: IconSize;
  iconStyle?: $TSFixMe;
  iconColor?: IconColor;
  'data-cy'?: string;
};

const IconButton = ({
  id,
  primary,
  title,
  onClick,
  disabled,
  type,
  iconColor,
  iconStyle,
  iconSize,
  buttonStyle = {},
  'data-cy': dataCy,
}: Props) => {
  const css = classNames('icon-button', {
    [`icon-button--primary`]: primary,
  });
  return (
    <button
      id={cleanIdentifier(id, 'icon-button')}
      style={buttonStyle}
      className={css}
      onClick={onClick}
      disabled={disabled}
      type={'button'}
      data-cy={dataCy}>
      <Icon type={type} color={iconColor} size={iconSize} style={iconStyle} title={title} />
    </button>
  );
};

export default IconButton;
