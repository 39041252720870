/* eslint-disable prefer-rest-params */
import BaseModel from '../BaseModel';

class CompanyUser extends BaseModel {
  constructor(_payload = {}) {
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 0 or more.
    super(...arguments);
  }

  get id() {
    return this.payload.userID;
  }

  get fullName() {
    return this.payload.fullName;
  }

  get firstName() {
    return this.payload.firstName;
  }

  get lastName() {
    return this.payload.lastName;
  }

  get companyPosition() {
    return this.payload.companyPosition;
  }

  get added() {
    return this.payload.companyJoinTime;
  }
  get email(): string | null {
    return this.payload.email;
  }

  get avatarUrl() {
    return this.payload.userID ? '/scf/api/UserAvatar?userid=' + this.payload.userID : '';
  }

  get status() {
    return this.payload.status ? this.payload.status : 'inactive';
  }

  get workspaces() {
    return this.payload.workspaces;
  }

  toJson() {
    return {
      fullName: this.fullName,
      avatarUrl: this.avatarUrl,
      firstName: this.firstName,
      workspaces: this.workspaces,
      companyPosition: this.companyPosition,
      lastName: this.lastName,
      added: this.added,
      email: this.email,
    };
  }

  toApi() {
    return {
      userID: this.id,
    };
  }
}

export default CompanyUser;
