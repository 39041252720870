import styled from 'styled-components';
import {Input as AntInput} from 'antd';

export const Input = styled(AntInput)`
  input,
  input[type='text'].ant-input,
  input[type='password'].ant-input,
  .ant-input-affix-wrapper,
  .ant-input-group-addon,
  .ant-select {
    background-color: var(--color-gray-6);
    color: var(--color-black);
  }

  input {
    border: var(--border-base);
    color: var(--color-black);

    ::placeholder {
      color: var(--color-placeholder);
    }
  }

  .ant-input-affix-wrapper {
    border: var(--border-base);
    border-radius: var(--border-radius-button);
    box-shadow: var(--box-shadow-inset-input);
  }

  .ant-input-group-addon {
    color: var(--color-black);
    border: var(--border-base);
    border-left-width: 0px;
    box-shadow: var(--box-shadow-inset-input);
  }
`;
