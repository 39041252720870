import React, {FC} from 'react';
import styled from 'styled-components';
import {formatTimestamp} from './formatTimeStamp';
import {UserAvatar} from '../../../UserAvatar/UserAvatar';
import {Tooltip} from 'antd';
import {Notification} from '../../../../api/symfony/generated/models/Notification';
import {FormattedNotificationMessage} from './FormattedNotificationMessage';
import Icon from '../../../Icon';

export const NotificationListEntry: FC<{
  notification: Notification;
  onClick?: () => void;
  onRead?: () => void;
}> = ({notification, onClick, onRead}) => {
  return (
    <NotificationListEntryStyled onClick={onClick} data-testid={`Notification-${notification.id}`}>
      <ListEntryLayout>
        <NotificationEntryIcon notification={notification} />
        <Message notification={notification} />
        <IsRead notification={notification} onClick={onRead} />
        <Timestamp notification={notification} />
      </ListEntryLayout>
    </NotificationListEntryStyled>
  );
};

const NotificationEntryIcon: FC<{notification: Notification}> = ({notification}) => {
  if (notification.authors.length === 1) {
    return <AuthorIcon notification={notification} />;
  }

  return null;
};

const NotificationListEntryStyled = styled.div`
  padding: 10px 5px;
  margin: 0 -5px;
  background-color: var(--color-white) !important;

  font-style: normal;
  font-size: var(--font-size-md);
  line-height: 19px;
  color: var(--color-black);
  border-radius: var(--border-radius-card) !important;

  &:hover {
    background-color: var(--color-gray-6) !important;
    color: var(--color-black) !important;
  }

  &:active {
    background-color: var(--color-gray-5) !important;
  }
`;

const ListEntryLink = styled.a`
  color: var(--color-black);
  &:hover {
    color: var(--color-black);
  }
`;

const ListEntryLayout = styled.div`
  display: grid;
  grid-template-columns: 34px 252px 20px;
  grid-template-rows: 1fr 17px;
  grid-template-areas:
    'authorIcon message    isRead'
    'authorIcon timestamp    .';
  column-gap: 7px;
  row-gap: 5px;

  font-weight: normal !important;
  text-align: left !important;
`;

const AuthorIcon: FC<{notification: Notification; onClick?: () => void}> = ({notification, onClick}) => {
  let icon = null;
  if (notification.authors.length === 1) {
    const author = notification.authors[0];
    icon = <UserAvatar user={author} />;
  }
  return (
    <AuthorIconStyled>
      <ListEntryLink onClick={onClick}>{icon}</ListEntryLink>
    </AuthorIconStyled>
  );
};

const AuthorIconStyled = styled.div`
  grid-area: authorIcon;
  justify-self: center;
  align-self: start;
`;

const IsRead: FC<{notification: Notification; onClick?: () => void}> = ({notification, onClick}) => {
  if (notification.isRead) {
    return null;
  }

  return (
    <Tooltip title="Mark as Read" placement="right">
      <IconButtonStyled
        onClick={event => {
          onClick?.();

          event.stopPropagation();
        }}
        data-testid="MarkAsRead">
        <StyledIcon type="check-mark-line" />
      </IconButtonStyled>
    </Tooltip>
  );
};

const IconButtonStyled = styled.a`
  display: inline-block;

  grid-area: isRead;
  justify-self: end;
`;

const StyledIcon = styled(Icon)`
  display: block;
  width: 16px;
  font-weight: normal !important;
  font-size: 1.25em !important;
  line-height: 16px !important;
  color: var(--color-gray-2) !important;

  &:hover::before {
    color: var(--color-blue);
  }
`;

export const Message: FC<{notification: Notification; onClick?: () => void}> = ({notification, onClick}) => (
  <MessageStyled>
    <PseudoLink onClick={onClick}>
      <FormattedNotificationMessage notification={notification} />
    </PseudoLink>
  </MessageStyled>
);

const MessageStyled = styled.div`
  grid-area: message;

  font-size: var(--font-size-md);
  line-height: 19px;
  font-family: Akkurat;

  b {
    color: var(--color-black);
    font-weight: bold;
  }

  a {
    color: var(--color-black);
    font-weight: bold;
  }

  a:hover {
    color: var(--color-blue);
  }
`;

const Timestamp: FC<{notification: Notification; onClick?: () => void}> = ({notification, onClick}) => (
  <TimestampStyled>
    <ListEntryLink onClick={onClick}>{formatTimestamp(notification.time)}</ListEntryLink>
  </TimestampStyled>
);

const TimestampStyled = styled.div`
  grid-area: timestamp;

  color: var(--color-gray-2);

  font-size: var(--font-size-md);
  line-height: 19px;
  font-family: Akkurat;
`;

// We can't use a real <a> around Message
// because then we would get nested a tags,
// which produces a warning in React.
const PseudoLink = styled.div`
  cursor: pointer;
`;
