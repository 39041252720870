/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationTermHistoryItem,
  NegotiationTermHistoryItemFromJSON,
  NegotiationTermHistoryItemFromJSONTyped,
  NegotiationTermHistoryItemToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationTermHistory
 */
export interface NegotiationTermHistory {
  /**
   *
   * @type {Array<NegotiationTermHistoryItem>}
   * @memberof NegotiationTermHistory
   */
  items: Array<NegotiationTermHistoryItem>;
}

export function NegotiationTermHistoryFromJSON(json: any): NegotiationTermHistory {
  return NegotiationTermHistoryFromJSONTyped(json, false);
}

export function NegotiationTermHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationTermHistory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(NegotiationTermHistoryItemFromJSON),
  };
}

export function NegotiationTermHistoryToJSON(value?: NegotiationTermHistory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(NegotiationTermHistoryItemToJSON),
  };
}
