import {FilterType} from '../../../api/symfony/generated';
import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import {assertUnreachable} from '../../../utils/assert';
import {useSelector} from '../../../redux/react-redux';
import {MarketType} from '../../../redux/Market';
import {MarketVesselFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';
import {MarketCargoFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';

export type UseCurrentFiltersReturnType = ReturnType<typeof useCurrentFilters>;

export const useCurrentFilters = <Type extends MarketType>({
  type,
  vesselFilterProviderApi,
  cargoFilterProviderApi,
}: {
  type: Type;
  vesselFilterProviderApi: FilterProviderApi<typeof MarketVesselFilterBranchDefinitions>;
  cargoFilterProviderApi: FilterProviderApi<typeof MarketCargoFilterBranchDefinitions>;
}) => {
  const {activeTabCargo, activeTabVessel} = useSelector(state => state.subMarkets);
  const {vesselFilterSettings, cargoFilterSettings} = useSelector(state => state.marketFilters);

  const currentVesselFilterObject = useSelector(state =>
    state.subMarkets.subMarkets.find(subMarket => subMarket.id === state.subMarkets.activeTabVessel)
  );
  const currentCargoFilterObject = useSelector(state =>
    state.subMarkets.subMarkets.find(subMarket => subMarket.id === state.subMarkets.activeTabCargo)
  );

  switch (type) {
    case 'vessel':
      return {
        activeTabId: activeTabVessel,
        filterType: FilterType.Vessel,
        currentFilterObject: currentVesselFilterObject,
        currentFilterSettings: vesselFilterSettings,
        filterProviderApi: vesselFilterProviderApi,
      };
    case 'cargo':
      return {
        activeTabId: activeTabCargo,
        filterType: FilterType.Cargo,
        currentFilterObject: currentCargoFilterObject,
        currentFilterSettings: cargoFilterSettings,
        filterProviderApi: cargoFilterProviderApi,
      };
    default:
      assertUnreachable(type);
  }
};
