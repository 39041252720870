import {FC, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import ActionMenuButton, {ActionMenuButtonProps} from '../../atoms/ActionMenuButton/ActionMenuButton';
import {Button, ButtonProps} from 'antd';

export type ActionButtonProps = {
  type?: ButtonProps['type'];
  ghost?: ButtonProps['ghost'];
  id?: string;
  disabled?: boolean;
  href?: string;
  'data-cy'?: string;
  'data-testid'?: string;
  onClick?: () => void;
  children: ReactNode;
};

export const ScreenActionButton: FC<ActionButtonProps> = props => {
  return <ActionButton {...props} />;
};

type ScreenActionLinkProps = Omit<ActionButtonProps, 'onClick'> & {
  to: string;
};

export const ScreenActionLink: FC<ScreenActionLinkProps> = props => {
  const {id, type, disabled, to, children, 'data-cy': dataCy, 'data-testid': dataTestId} = props;
  return (
    <Link to={to} data-cy={dataCy} data-testid={dataTestId}>
      <ActionButton id={id} type={type} disabled={disabled}>
        {children}
      </ActionButton>
    </Link>
  );
};

export const ScreenActionMenuButton: FC<ActionMenuButtonProps> = props => {
  return <ActionMenuButton {...props} className="ant-btn ant-btn-sm ant-btn-default" />;
};

const ActionButton: FC<ActionButtonProps> = ({
  id,
  type,
  disabled,
  onClick,
  children,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}) => {
  return (
    <Button
      id={id}
      type={type ?? 'default'}
      size="small"
      data-cy={dataCy}
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </Button>
  );
};
