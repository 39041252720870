import {useMemo, useState} from 'react';
import EmptyStateMarketFiltersVessel from '../../EmptyState/EmptyStateMarketFiltersVessel';
import NoResults from '../../EmptyState/NoResults';
import {VesselOfferCollection} from '../../../../redux/ApiService/MarketService';
import EmptyStateMarket from '../../EmptyState/EmptyStateMarket';
import {MarketExpander, MarketSubComponent} from '../../SubComponents';
import {vesselBulkerGrid, vesselContainerGrid, vesselMppGrid} from '../../../../components/GridLayout';
import {GridClipboardActions} from '../../../../redux/GridClipboard';
import {GridMetaData} from '../../GridMetaData';
import {TODO} from '../../../../utils/TODO';
import {assertUnreachable} from '../../../../utils/assert';
import {getSubscriptionPlanLimits} from '../../../../redux/selectors';
import {getCircularsLimitedByPlan} from '../../utils/getCircularsLimitedByPlan';
import {easySharingPromotionColumn} from '../../../../components/GridLayout/columns/shared/easySharingPromotionColumn';
import {getMarketGridTdProps} from '../../utils/getMarketGridTdProps';
import {CustomColumnDef, DataGrid} from '../../../../components/DataGrid/DataGrid';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {getVesselColumns} from './GetVesselColumns';
import {clipBoardColumn} from './ClipBoardColumn';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {VesselGridLayoutMode} from '../../../../redux/Portfolio';

interface VesselGridProps {
  totalCount?: number;
  vesselOffers: VesselOfferCollection;
  isFiltered: boolean;
  isSubMarket: boolean;
  displayedLayoutMode: VesselGridLayoutMode;
  sharedCircularsBlurred?: boolean;
  onGridDataChange: () => void;
  gotoFilter: () => void;
  onPageChange?: (pageIndex: number) => void;
  onPageSizeChange?: (pageSize: number, pageIndex: number) => void;
  onSettingChange?: (gridMetaData: GridMetaData) => void;
  showProjectModal?: () => void;
  showPortfolioModal?: () => void;
}

export const VesselGrid = (props: VesselGridProps) => {
  const dispatch = useDispatch();

  const vesselMeta = useSelector(state => state.market.vessel);
  const loading = useSelector(state => state.api.market.vessel.loading);
  const clipboardState = useSelector(state => state.gridClipboard.market.vessels);
  const checkAllVessels = useSelector(state => state.gridClipboard.market.checkAllVessels);
  const marketGridLimit = useSelector(state => getSubscriptionPlanLimits(state).marketGridLimit);

  const addAllItemsToClipboard = () => dispatch(GridClipboardActions.addAllGridItemsToClipboard('vessels', 'market'));
  const removeAllItemsFromClipboard = () =>
    dispatch(GridClipboardActions.removeAllItemsFromClipboard('vessels', 'market'));

  const addItemToClipboard = (item: TODO) =>
    dispatch(
      GridClipboardActions.addItem({
        section: 'market',
        subsection: 'vessels',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );

  const removeItemFromClipboard = (item: TODO) =>
    dispatch(
      GridClipboardActions.removeItem({
        section: 'market',
        subsection: 'vessels',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );

  const {
    vesselOffers,
    onGridDataChange,
    onSettingChange,
    isFiltered,
    isSubMarket,
    gotoFilter,
    displayedLayoutMode,
    sharedCircularsBlurred,
    ...restProps
  } = props;

  const [_expanded, setExpanded] = useState<TODO>({});

  const isNewSubMarket = isSubMarket && !isFiltered;
  const {totalCountLimited, itemsLimited} = getCircularsLimitedByPlan<typeof vesselOffers.items>(
    isNewSubMarket,
    loading,
    marketGridLimit,
    vesselOffers,
    vesselMeta!
  );

  const getGridColumns = (): CustomColumnDef<SeaboVessel>[] => {
    switch (displayedLayoutMode) {
      case 'bulker':
        return vesselBulkerGrid('market');
      case 'mpp':
        return vesselMppGrid('market');
      case 'container':
        return vesselContainerGrid('market');
      case 'auto':
        throw new Error('auto?');
      default:
        assertUnreachable(displayedLayoutMode);
    }
  };

  const displayEmptyState = () => {
    if (vesselOffers.initial) {
      return null;
    }
    if (isSubMarket && !isFiltered) {
      return <EmptyStateMarketFiltersVessel onClick={gotoFilter} />;
    }
    if (isFiltered && vesselOffers.totalItems === 0) {
      return <NoResults type={'vessels'} />;
    }
    if (!isSubMarket && !isFiltered && vesselOffers.totalItems === 0) {
      return <EmptyStateMarket type={'vessels'} />;
    }
    return null;
  };

  const columns = useMemo(() => {
    const columns: CustomColumnDef<TODO>[] = [
      easySharingPromotionColumn(sharedCircularsBlurred),
      clipBoardColumn({
        addAllItemsToClipboard,
        addItemToClipboard,
        checkAllVessels,
        removeAllItemsFromClipboard,
        clipboardState,
        removeItemFromClipboard,
      }),
      {
        size: 45,
        width: 45,
        id: 'expander',
        header: '',
        cell: ({row}) => {
          return (
            <div
              onClick={() => {
                row.toggleExpanded();
              }}>
              <MarketExpander
                isExpanded={row.getIsExpanded()}
                number={row.original && row.original.attachedOffers.length}
              />
            </div>
          );
        },
      },
      ...getVesselColumns({
        isSub: false,
        onGridDataChange,
        getGridColumns,
      }),
    ];
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedLayoutMode, sharedCircularsBlurred, clipboardState]);

  // useMemo is required because the columns are being recreated on every render.
  // Without memoization this means the "open" state of the Add to My Cargos modal would be lost.
  //
  // See also FRIN-5431
  const subColumns = useMemo(
    () => [
      clipBoardColumn({
        addAllItemsToClipboard,
        addItemToClipboard,
        checkAllVessels,
        removeAllItemsFromClipboard,
        clipboardState,
        removeItemFromClipboard,
      }),
      ...getVesselColumns({
        isSub: true,
        onGridDataChange,
        getGridColumns: getGridColumns,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clipboardState]
  );

  return (
    <>
      <DataGrid
        className={'market-grid market-grid--vessel'}
        columns={columns}
        loading={loading}
        data={itemsLimited}
        totalCount={totalCountLimited}
        page={vesselMeta!.page}
        pageSize={vesselMeta!.pageSize}
        showPagination={!isNewSubMarket}
        showJumper={!isNewSubMarket}
        sorted={[vesselMeta!.sortedBy]}
        zeroBasedPageIndex={false}
        responsive
        unroundedTopLeft
        unroundedTopRight
        noHoverStatePointer
        getTdProps={getMarketGridTdProps(sharedCircularsBlurred)}
        TbodyComponent={displayEmptyState()}
        renderSubComponent={({original}) => {
          return <MarketSubComponent original={original} columns={subColumns} />;
        }}
        onSettingChange={onSettingChange as TODO}
        onExpandedChange={expanded => setExpanded(expanded)}
        {...restProps}
      />
    </>
  );
};
