import styled from 'styled-components';
import Icon from '../../atoms/Icon';

export const PopoverContent = styled.div`
  max-width: 350px;
  text-align: center;
`;

export const Quote = styled.p`
  padding: 10px;
  margin: 20px;
  font-weight: bold;
  border: var(--border-base);
  border-left-color: transparent;
  border-right-color: transparent;
`;

export const SharingIcon = styled(Icon)<{$isActive?: boolean; $isActiveTab?: boolean}>`
  position: relative;
  top: 2px;
  font-size: 18px !important;
  opacity: 1;

  color: ${props => {
    if (props.$isActive) {
      return 'var(--color-orange)';
    }
    if (props.$isActiveTab) {
      return 'var(--color-white)';
    }
    return 'var(--color-gray)';
  }} !important;
`;
