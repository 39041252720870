import {useMutation, MutateOptions} from '@tanstack/react-query';
import {TODO} from '../../../../../utils/TODO';
import {ApiError} from '../../../../../api/utils/ApiError';
import {useDispatch} from '../../../../../redux/react-redux';
import vesselService from '../../../../../redux/ApiService/vesselService';
import {UpdateVesselRequest} from '../../../../../api/symfony/generated';

export const useUpdateVesselMutation = (options: MutateOptions<void, ApiError, UpdateVesselRequest>) => {
  const dispatch = useDispatch();
  const updateVessel = (id: number, body: TODO) => dispatch(vesselService.patch(id, body));
  return useMutation({
    mutationFn: async (request: UpdateVesselRequest) => {
      return await updateVessel(request.id, request.body);
    },
    ...options,
  });
};
