import {useMemo, FC} from 'react';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import {StatCard} from '../../sharedComponents/StatCard';
import {bulker, container, mpp} from '../../../../components/consts/vesselTypes';

export const FinishedVisitsPerVesselType: FC<{
  visits: MergedPortCongestionVisit[];
  onSetVisits: (visits: MergedPortCongestionVisit[]) => void;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const perTypeGrouped = groupBy(visits, vessel => vessel.vesselType);

    const data = sortBy(
      map(perTypeGrouped, (visits, key) => {
        return {value: visits.length, name: key};
      }),
      'name'
    );

    return {
      data,
      perTypeGrouped,
    };
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perTypeGrouped[event.name]);
              onSetAppliedFilter('Served by vessel type', event.name);
            }
          },
        }}
        option={{
          color: [bulker.fillColor, container.fillColor, mpp.fillColor, 'black'],

          title: {
            text: 'Served by vessel type',
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            orient: 'vertical',
            left: 'left',
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: dataSource.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};

export const FinishedVisitsFromBulkersPerVesselSize: FC<{
  visits: MergedPortCongestionVisit[];
  onSetVisits: (vessels: MergedPortCongestionVisit[]) => void;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const filteredVisits = visits.filter(vessel => vessel.vesselType.toLowerCase() === 'bulker');

    const perTypeGrouped = groupBy(filteredVisits, 'label');

    const data = sortBy(
      map(perTypeGrouped, visits => {
        return {value: visits.length, name: visits[0].label, itemStyle: {color: visits[0].color}};
      }),
      'value'
    );

    return {data, perTypeGrouped};
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perTypeGrouped[event.name]);
              onSetAppliedFilter('Bulkers by type', event.name);
            }
          },
        }}
        style={{
          height: 300,
        }}
        option={{
          title: {
            text: 'Served bulkers by type',
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: dataSource.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};
