import React, {useEffect} from 'react';
import {CenteredContentArea, FullscreenDialog} from '../../../atoms/FullscreenDialog/FullscreenDialog';
import {LoginDialog} from './LoginDialog';
import {DesktopRequired} from '../DesktopRequired';
import {Center} from '../../../components/Center/Center';
import {useChangeBrowserUrl} from '../../../utils/useChangeBrowserUrl';
import {useUserLoggedIn} from '../../../utils/useUserLoggedIn';
import {Helmet} from 'react-helmet-async';

export const LoginScreen = () => {
  const userLoggedIn = useUserLoggedIn();
  const changeBrowserUrl = useChangeBrowserUrl();

  useEffect(() => {
    if (userLoggedIn) {
      changeBrowserUrl('dashboard');
    }
  }, [changeBrowserUrl, userLoggedIn]);

  if (userLoggedIn) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div data-testid="LoginScreen">
        <FullscreenDialog>
          <CenteredContentArea>
            <Center>
              <DesktopRequired>
                <LoginDialog />
              </DesktopRequired>
            </Center>
          </CenteredContentArea>
        </FullscreenDialog>
      </div>
    </>
  );
};
