import {AimOutlined, ArrowDownOutlined, ArrowUpOutlined, BgColorsOutlined, PlusOutlined} from '@ant-design/icons';
import {Dayjs} from 'dayjs';
import {Coordinates} from '../../../../utils/Coordinates';
import {FuelType, VesselConsumptionMode, VesselConsumptionModeUseCase} from '../VesselInformation/VesselTypes';
import {ConsumptionTransaction} from '../utils/consumptionSequence/ConsumptionTransaction';
import {InputState, OutputState} from '../utils/voyageChartercalculation/voyageChartercalculation';
import {VoyagePointTerm} from './VoyagePointTerm';

import {SearchPickerPort} from '../../../SearchPicker/SearchPickerPort';

export interface VoyagePointDurationItem {
  vesselModeIndex: number;
  duration: number;
  name: string;
}

export type VoyagePointType = 'loading' | 'discharge' | 'bunkering' | 'extraCall' | 'nextOpen';

export const voyagePointTypeOptions: Record<VoyagePointType, {label: string; iconReactElement?: React.ReactElement}> = {
  loading: {
    label: 'Loading',
    iconReactElement: <ArrowDownOutlined />,
  },

  nextOpen: {
    label: 'Port open',
    iconReactElement: <AimOutlined />,
  },

  discharge: {
    label: 'Discharge',
    iconReactElement: <ArrowUpOutlined />,
  },
  extraCall: {
    label: 'Extra call',
    iconReactElement: <PlusOutlined />,
  },
  bunkering: {
    label: 'Bunkering',
    iconReactElement: <BgColorsOutlined />,
  },
};

export type AlternativePort = {
  coordinates: Coordinates;
  name?: string;
  countryCode?: string;
  termsLoading?: VoyagePointTerm;
  turnTimeInHours?: number;
  /**
   * rawTerm is the raw cargo term, which is not interpreted or overwritten with a default. Only uppercase was used.
   */
  rawTerm?: string;
  originalPort?: SearchPickerPort;
  termsDischarging?: VoyagePointTerm;
  dischargingRate?: number;
  loadingRate?: number;
};
export interface VoyagePoint {
  isPort: boolean;
  alternativePorts?: AlternativePort[];
  coordinates: Coordinates;
  originalPort?: SearchPickerPort;
  termsLoading?: VoyagePointTerm;
  termsDischarging?: VoyagePointTerm;
  /**
   * rawTerm is the raw cargo term, which is not interpreted or overwritten with a default. Only uppercase was used.
   */
  rawTerm?: string;
  name?: string;
  countryCode?: string;
  types: VoyagePointType[];
  consumption?: VoyageConsumption;
  isInSECA: boolean;
  portId?: number;
  loadingRate?: number;
  note?: string;
  waitingDurationInHours?: number;
  turnTimeInHours?: number;
  otherDuration: Array<VoyagePointDurationItem>;
  dischargingRate?: number;
  portCosts?: number;
  vesselModeIndex?: number;
  load: number;
  dis: number;
}
export interface VoyageRoute {
  distance: number;
  distanceInSeca?: number;
  distanceInPiracy?: number;
  note?: string;
  consumption?: VoyageConsumption;

  seaMargin?: number;

  waypoints?: Array<Coordinates>;
}

export const blankVoyagePointOutput: VoyagePointOutput = {
  duration: 0,
  consumptionCosts: 0,
  durationDischarging: 0,
  durationLoad: 0,
  usedDwat: 0,
  cO2Emission: 0,
};
export const blankVoyageRouteOutput: VoyageRouteOutput = {
  consumptionCosts: 0,
  duration: 0,
  endPoints: [],
  cO2Emission: 0,
};

export interface VoyagePointOutput {
  consumptionCosts: number;
  durationLoad: number;
  durationDischarging: number;
  usedDwat: number;
  duration: number;
  startDate?: string;
  cO2Emission: number;
  endDate?: string;
}
export interface VoyageRouteOutput {
  isBallast?: boolean;

  duration: number;
  endPoints: Array<Coordinates>;
  startDate?: string;
  endDate?: string;
  cO2Emission: number;
  consumptionCosts: number;
}

export type VoyageConsumption = {
  useCase: VesselConsumptionModeUseCase;
  isEco?: boolean;
  speed: number;
  mainFuelType?: FuelType;
  mainConsumption: number;
  auxConsumption: number;
  auxFuelType?: FuelType;
  connectedVesselConsumptionMode?: VesselConsumptionMode;
  manuallyAdded?: boolean;
};

export interface VoyageInput {
  routes: Array<VoyageRoute>;
  points: Array<VoyagePoint>;
  routesCalculated: boolean;
  laycanTo?: Dayjs;
  laycanFrom?: Dayjs;
}

export interface VoyageOutput {
  routes: Array<VoyageRouteOutput>;
  points: Array<VoyagePointOutput>;
  cO2Emission: number;
  consumptionTransactions: ConsumptionTransaction[];
}

export interface VoyageProps {
  onChange: (obj: VoyageInput) => void;
  open?: boolean;
  inputState: InputState;
  isLoadingRoutes: boolean;
  outputState: OutputState;
}

export type CalcTimeLine = CalcTimeLinePoint | CalcTimeLineRoute;

export type CalcTimeLinePoint = {
  nodeType: 'point';
  indexInOldArray: number;
  item: VoyagePoint;
  isFirstLoadingLeg: boolean;
  outputItem?: VoyagePointOutput;
  id?: number;
};
export type CalcTimeLineRoute = {
  nodeType: 'route';
  indexInOldArray: number;
  item: VoyageRoute;
  outputItem?: VoyageRouteOutput;
  id?: number;
};

export type CalcTimeLineList = Array<CalcTimeLine>;

export type ConsumptionType = 'main' | 'aux';
