import {InputState, OutputState} from '../voyageChartercalculation/voyageChartercalculation';
import {VoyagePoint, VoyagePointOutput} from '../../VoyageInformation/VoyageTypes';
import {calcNewInputState} from './calcNewInputState';
import {QueryClient} from '@tanstack/react-query';

/*
extracts certain fields from a point that are relevant
for the calculation of the waiting time, or for those of the following points.
 */
const getTermsKey = ({inputPoint, outputPoint}: {outputPoint: VoyagePointOutput; inputPoint?: VoyagePoint}): string => {
  return [
    outputPoint.startDate,
    inputPoint?.turnTimeInHours,

    inputPoint?.dis,
    inputPoint?.dischargingRate,
    inputPoint?.termsDischarging,

    inputPoint?.loadingRate,
    inputPoint?.load,
    inputPoint?.termsLoading,
  ].join(',');
};

/*
Creates an array where each entry represents a VoyagePoint.
The representation is done by a string as generated by getTermsKey
 */
export const generateTermsArray = ({
  outputState,
  inputState,
}: {
  outputState: OutputState;
  inputState: InputState;
}): string[] => {
  const pointTerms = outputState.voyage.points.map((outputPoint, index) => {
    const inputPoint = inputState.voyage.points[index];
    return getTermsKey({inputPoint, outputPoint});
  });

  const laycanString = inputState.voyage.laycanFrom?.toISOString() ?? '';

  return [laycanString, ...pointTerms];
};

export const updatesWaitingtimesOnInputstate = async ({
  oldKeyList,
  newKeyList,
  queryClient,
  inputState,
}: {
  oldKeyList: string[];
  newKeyList: string[];
  queryClient: QueryClient;
  inputState: InputState;
}): Promise<InputState> => {
  const findFistChangeIndex = newKeyList.findIndex((keyInNewArray, keyIndex) => keyInNewArray !== oldKeyList[keyIndex]);

  if (findFistChangeIndex !== -1) {
    const result = await calcNewInputState({inputState, firstIndex: findFistChangeIndex, queryClient});

    /*
    Changes will only be accepted if there are changes in the sequence or duration of the points.
     */
    return result.inputState;
  }
  return inputState;
};
