import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as ChartererDefinition from '../Charterer/ChartererDefinition';
import * as CargoTypeDefinition from '../CargoType/CargoTypeDefinition';
import * as ContractTypeDefinition from '../ContractType/ContractTypeDefinition';
import * as VesselSizeDefinition from '../VesselSize/VesselSizeDefinition';
import * as QuantityDefinition from '../Quantity/QuantityDefinition';
import * as CargoPrivacyDefinition from '../CargoPrivacy/CargoPrivacyDefinition';
import * as FeaturesDefinition from '../Features/FeaturesDefinition';
import * as LaycanDefinition from '../Laycan/LaycanDefinition';
import * as CommoditiesDefinition from '../Commodities/CommoditiesDefinition';
import * as DurationDefinition from '../Duration/DurationDefinition';
import * as LocationCargoDefinition from '../LocationCargo/LocationCargoDefinition';
import * as PostedDefinition from '../Posted/PostedDefinition';

export const MarketCargoFilterBranchDefinitions = makeFilterBranchDefinitions([
  ChartererDefinition.filterBranchDefinition,
  CargoTypeDefinition.filterBranchDefinition,
  ContractTypeDefinition.filterBranchDefinition,
  VesselSizeDefinition.filterBranchDefinition,
  QuantityDefinition.filterBranchDefinition,
  CargoPrivacyDefinition.filterBranchDefinition,
  FeaturesDefinition.filterBranchDefinition,
  LaycanDefinition.filterBranchDefinition,
  CommoditiesDefinition.filterBranchDefinition,
  DurationDefinition.filterBranchDefinition,
  LocationCargoDefinition.filterBranchDefinition,
  PostedDefinition.filterBranchDefinition,
] as const);

export type MarketCargoInternalFilter = Partial<FilterInternalType<typeof MarketCargoFilterBranchDefinitions>>;
export type MarketCargoDatabaseFilter = Partial<FilterDatabaseType<typeof MarketCargoFilterBranchDefinitions>>;
