import React, {FC} from 'react';
import styled from 'styled-components';
import {Account} from './Account/Account';
import {Profile} from './Profile/Profile';
import {ProfilePicture} from './ProfilePicture/ProfilePicture';
import {Timezone} from './Timezone/Timezone';
import {Typography, Row, Col} from 'antd';

import {Breadcrumb} from '../../atoms/Breadcrumb';
import {NotFound} from '../../atoms/EmptyState/404';
import {SidebarMenu, SidebarMenuItem} from '../../atoms/SidebarMenu/SidebarMenu';
import {FixedWidthLayout} from '../../atoms/FixedWidthLayout/FixedWidthLayout';
import {MarketSegments} from './MarketSegments/MarketSegments';
import {Navigate, Route, Routes} from 'react-router-dom';

const {Title} = Typography;

const Sidebar: FC = () => (
  <SidebarStyled>
    <Title level={4}>User account</Title>
    <SidebarMenu>
      <SidebarMenuItem to="account">Account</SidebarMenuItem>
      <SidebarMenuItem to="profile">Profile</SidebarMenuItem>
      <SidebarMenuItem to="profile-picture">Profile picture</SidebarMenuItem>
      <SidebarMenuItem to="timezone">Time zone</SidebarMenuItem>
      <SidebarMenuItem to="segments">Market segments</SidebarMenuItem>
    </SidebarMenu>
  </SidebarStyled>
);

const SidebarStyled = styled.aside`
  /* Make sure we have some whitespace when FormArea is displayed below Sidebar: */
  padding-bottom: 32px;
`;

const FormArea: FC = () => {
  return (
    <FixedWidthLayout>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={{pathname: '/user-account/account'}} state={{openNewFixtureModal: true}} />}
        />
        <Route path="/account" element={<Account />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile-picture" element={<ProfilePicture />} />
        <Route path="/timezone" element={<Timezone />} />
        <Route path="/segments" element={<MarketSegments />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </FixedWidthLayout>
  );
};

export const UserAccountScreen: FC = () => {
  return (
    <UserAccountScreenStyled>
      <Breadcrumb items={[{title: 'User account'}]} />
      <Row>
        <Col flex="220px">
          <Sidebar />
        </Col>
        <Col flex="auto">
          <FormArea />
        </Col>
      </Row>
    </UserAccountScreenStyled>
  );
};

const UserAccountScreenStyled = styled.section`
  min-height: 400px;
  padding: 32px;
`;
