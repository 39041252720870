import {
  NegotiationRoundResultModification,
  NegotiationSubject,
  NegotiationSubjectModification,
  NegotiationSubjectModificationReport,
  NegotiationSubjectStatus,
  NegotiationTerm,
  NegotiationTermStatus,
  RoundResultTermModification,
  TermModification,
} from '../../../../../api/node-backend/generated';

export type TermWithTermModifications = {
  term: NegotiationTerm;
  termModifications?: RoundResultTermModification;
};

export const combineWithTermModification =
  (roundResultModifications?: NegotiationRoundResultModification) =>
  (term: NegotiationTerm): TermWithTermModifications => {
    const termModifications = roundResultModifications?.termModifications.find(t => t.termId === term.id);
    return {
      term,
      termModifications,
    };
  };

export type SubjectWithSubjectModifications = {
  subject: NegotiationSubject;
  subjectModifications?: NegotiationSubjectModificationReport;
};

export const combineWithSubjectModification =
  (roundResultModifications?: NegotiationRoundResultModification) =>
  (subject: NegotiationSubject): SubjectWithSubjectModifications => {
    const subjectModifications = roundResultModifications?.subjectModificationReports.find(
      t => t.subjectId === subject.id
    );
    return {
      subject,
      subjectModifications,
    };
  };

export const combineWithTermSubjectModification =
  (subjectModificationReports?: NegotiationSubjectModificationReport[]) =>
  (subject: NegotiationSubject): SubjectWithSubjectModifications => {
    const subjectModifications = subjectModificationReports?.find(t => t.subjectId === subject.id);
    return {
      subject,
      subjectModifications,
    };
  };

export const getIsTermSettledAndCommitted = ({term, termModifications}: TermWithTermModifications) => {
  const termModification = termModifications?.termModification ?? TermModification.Unmodified;
  return (
    [NegotiationTermStatus.Rejected, NegotiationTermStatus.Accepted].includes(term.status) &&
    termModification === TermModification.Unmodified
  );
};

export const getIsTermRejectedAndCommitted = ({term, termModifications}: TermWithTermModifications) => {
  const termModification = termModifications?.termModification ?? TermModification.Unmodified;
  return term.status === NegotiationTermStatus.Rejected && termModification === TermModification.Unmodified;
};

export const getIsSubjectSettledAndCommitted = ({subject, subjectModifications}: SubjectWithSubjectModifications) => {
  const subjectModification = subjectModifications?.subjectModification ?? NegotiationSubjectModification.Unmodified;
  return (
    [NegotiationSubjectStatus.Rejected, NegotiationSubjectStatus.Lifted, NegotiationSubjectStatus.Failed].includes(
      subject.status
    ) && subjectModification === NegotiationSubjectModification.Unmodified
  );
};

export const getIsTermChanged = ({termModifications}: TermWithTermModifications) => {
  return ![TermModification.Unmodified, undefined].includes(termModifications?.termModification);
};

export const getIsSubjectChanged = ({subjectModifications}: SubjectWithSubjectModifications) => {
  return ![NegotiationSubjectModification.Unmodified, undefined].includes(subjectModifications?.subjectModification);
};
