/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum GrainVolumeUnit {
  Cbm = 'cbm',
  Cft = 'cft',
}

export function GrainVolumeUnitFromJSON(json: any): GrainVolumeUnit {
  return GrainVolumeUnitFromJSONTyped(json, false);
}

export function GrainVolumeUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrainVolumeUnit {
  return json as GrainVolumeUnit;
}

export function GrainVolumeUnitToJSON(value?: GrainVolumeUnit | null): any {
  return value as any;
}
