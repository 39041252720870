/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CancelNegotiationRequest,
  CancelNegotiationRequestFromJSON,
  CancelNegotiationRequestToJSON,
  CreateNegotiationRequest,
  CreateNegotiationRequestFromJSON,
  CreateNegotiationRequestToJSON,
  ExportToPdfResponse,
  ExportToPdfResponseFromJSON,
  ExportToPdfResponseToJSON,
  Negotiation,
  NegotiationFromJSON,
  NegotiationToJSON,
  NegotiationRoundResult,
  NegotiationRoundResultFromJSON,
  NegotiationRoundResultToJSON,
  NegotiationRoundResultSummary,
  NegotiationRoundResultSummaryFromJSON,
  NegotiationRoundResultSummaryToJSON,
  NegotiationSummary,
  NegotiationSummaryFromJSON,
  NegotiationSummaryToJSON,
  SetReplyTimeRequest,
  SetReplyTimeRequestFromJSON,
  SetReplyTimeRequestToJSON,
  UpdatePrivateNoteRequest,
  UpdatePrivateNoteRequestFromJSON,
  UpdatePrivateNoteRequestToJSON,
} from '../models';

export interface CancelNegotiationOperationRequest {
  negotiationId: string;
  cancelNegotiationRequest: CancelNegotiationRequest;
}

export interface CommitRoundRequest {
  negotiationId: string;
}

export interface CreateNegotiationOperationRequest {
  createNegotiationRequest: CreateNegotiationRequest;
}

export interface ExportToPDFRequest {
  negotiationId: string;
}

export interface FindLatestRoundResultRequest {
  negotiationId: string;
}

export interface FindNegotiationByIdRequest {
  negotiationId: string;
}

export interface FindRoundResultsByNegotiationIdRequest {
  negotiationId: string;
}

export interface MoveToRecapRequest {
  negotiationId: string;
}

export interface MoveToSettledRequest {
  negotiationId: string;
}

export interface ReopenNegotiationRequest {
  negotiationId: string;
}

export interface SetReplyTimeOperationRequest {
  negotiationId: string;
  setReplyTimeRequest: SetReplyTimeRequest;
}

export interface UpdatePrivateNoteOperationRequest {
  negotiationId: string;
  updatePrivateNoteRequest: UpdatePrivateNoteRequest;
}

/**
 *
 */
export class NegotiationNegotiationApi extends runtime.BaseAPI {
  /**
   * Cancels the negotiations. Every party can cancel the negotiation. The cancellation is not revocable. It is the last and final round.
   */
  async cancelNegotiationRaw(
    requestParameters: CancelNegotiationOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling cancelNegotiation.'
      );
    }

    if (
      requestParameters.cancelNegotiationRequest === null ||
      requestParameters.cancelNegotiationRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'cancelNegotiationRequest',
        'Required parameter requestParameters.cancelNegotiationRequest was null or undefined when calling cancelNegotiation.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/cancel`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CancelNegotiationRequestToJSON(requestParameters.cancelNegotiationRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Cancels the negotiations. Every party can cancel the negotiation. The cancellation is not revocable. It is the last and final round.
   */
  async cancelNegotiation(requestParameters: CancelNegotiationOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.cancelNegotiationRaw(requestParameters);
    return await response.value();
  }

  /**
   * This closes the current round and starts a new one. The inactive party becomes the active party of the new round and receives a notification.
   */
  async commitRoundRaw(requestParameters: CommitRoundRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling commitRound.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/commit-round`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * This closes the current round and starts a new one. The inactive party becomes the active party of the new round and receives a notification.
   */
  async commitRound(requestParameters: CommitRoundRequest): Promise<NegotiationRoundResult> {
    const response = await this.commitRoundRaw(requestParameters);
    return await response.value();
  }

  /**
   * Creates a new negotiation. Create the counterpart and create Creator if not exist. Add the first round result. Set the status to Offer.
   */
  async createNegotiationRaw(
    requestParameters: CreateNegotiationOperationRequest
  ): Promise<runtime.ApiResponse<Negotiation>> {
    if (
      requestParameters.createNegotiationRequest === null ||
      requestParameters.createNegotiationRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'createNegotiationRequest',
        'Required parameter requestParameters.createNegotiationRequest was null or undefined when calling createNegotiation.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateNegotiationRequestToJSON(requestParameters.createNegotiationRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationFromJSON(jsonValue));
  }

  /**
   * Creates a new negotiation. Create the counterpart and create Creator if not exist. Add the first round result. Set the status to Offer.
   */
  async createNegotiation(requestParameters: CreateNegotiationOperationRequest): Promise<Negotiation> {
    const response = await this.createNegotiationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Exports the complete negotiation to PDF.
   */
  async exportToPDFRaw(requestParameters: ExportToPDFRequest): Promise<runtime.ApiResponse<ExportToPdfResponse>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling exportToPDF.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/pdf`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ExportToPdfResponseFromJSON(jsonValue));
  }

  /**
   * Exports the complete negotiation to PDF.
   */
  async exportToPDF(requestParameters: ExportToPDFRequest): Promise<ExportToPdfResponse> {
    const response = await this.exportToPDFRaw(requestParameters);
    return await response.value();
  }

  /**
   * Gets all NegotiationSummary[] of the current user. Only possible for platform users.
   */
  async findAllNegotiationsRaw(): Promise<runtime.ApiResponse<Array<NegotiationSummary>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(NegotiationSummaryFromJSON));
  }

  /**
   * Gets all NegotiationSummary[] of the current user. Only possible for platform users.
   */
  async findAllNegotiations(): Promise<Array<NegotiationSummary>> {
    const response = await this.findAllNegotiationsRaw();
    return await response.value();
  }

  /**
   * Returns the latest NegotiationRoundResult of the negotiation. The latest NegotiationRoundResult is either the ongoing round or the final round depending on the negotiation status.
   */
  async findLatestRoundResultRaw(
    requestParameters: FindLatestRoundResultRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling findLatestRoundResult.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/latest-round-result`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Returns the latest NegotiationRoundResult of the negotiation. The latest NegotiationRoundResult is either the ongoing round or the final round depending on the negotiation status.
   */
  async findLatestRoundResult(requestParameters: FindLatestRoundResultRequest): Promise<NegotiationRoundResult> {
    const response = await this.findLatestRoundResultRaw(requestParameters);
    return await response.value();
  }

  /**
   * Gets a NegotiationSummary by id.
   */
  async findNegotiationByIdRaw(
    requestParameters: FindNegotiationByIdRequest
  ): Promise<runtime.ApiResponse<NegotiationSummary>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling findNegotiationById.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationSummaryFromJSON(jsonValue));
  }

  /**
   * Gets a NegotiationSummary by id.
   */
  async findNegotiationById(requestParameters: FindNegotiationByIdRequest): Promise<NegotiationSummary> {
    const response = await this.findNegotiationByIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Gets all NegotiationRoundResultSummary[] of a negotiation. If its not settled, the latest round you can receive is the last round you was the active party.
   */
  async findRoundResultsByNegotiationIdRaw(
    requestParameters: FindRoundResultsByNegotiationIdRequest
  ): Promise<runtime.ApiResponse<Array<NegotiationRoundResultSummary>>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling findRoundResultsByNegotiationId.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/round-result-summaries`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(NegotiationRoundResultSummaryFromJSON));
  }

  /**
   * Gets all NegotiationRoundResultSummary[] of a negotiation. If its not settled, the latest round you can receive is the last round you was the active party.
   */
  async findRoundResultsByNegotiationId(
    requestParameters: FindRoundResultsByNegotiationIdRequest
  ): Promise<Array<NegotiationRoundResultSummary>> {
    const response = await this.findRoundResultsByNegotiationIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Moves the negotiation from ongoing to the recap phase. In this phase, only existing terms may be edited, but no new ones may be added.
   */
  async moveToRecapRaw(requestParameters: MoveToRecapRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling moveToRecap.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/move-to-recap`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Moves the negotiation from ongoing to the recap phase. In this phase, only existing terms may be edited, but no new ones may be added.
   */
  async moveToRecap(requestParameters: MoveToRecapRequest): Promise<NegotiationRoundResult> {
    const response = await this.moveToRecapRaw(requestParameters);
    return await response.value();
  }

  /**
   * Moves the negotiation from ongoing to the settled phase. This concludes the negotiation. It is the last and final round.
   */
  async moveToSettledRaw(
    requestParameters: MoveToSettledRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling moveToSettled.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/move-to-settled`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Moves the negotiation from ongoing to the settled phase. This concludes the negotiation. It is the last and final round.
   */
  async moveToSettled(requestParameters: MoveToSettledRequest): Promise<NegotiationRoundResult> {
    const response = await this.moveToSettledRaw(requestParameters);
    return await response.value();
  }

  /**
   * Reopens the negotiations. Every party can reopen the negotiation. The negotiation is reopened in the last round.
   */
  async reopenNegotiationRaw(
    requestParameters: ReopenNegotiationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling reopenNegotiation.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/reopen`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Reopens the negotiations. Every party can reopen the negotiation. The negotiation is reopened in the last round.
   */
  async reopenNegotiation(requestParameters: ReopenNegotiationRequest): Promise<NegotiationRoundResult> {
    const response = await this.reopenNegotiationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sets or deletes a reply time. The active party can set the reply time for the next round. \"Delete\" works by setting duration and timestamp to null.
   */
  async setReplyTimeRaw(
    requestParameters: SetReplyTimeOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling setReplyTime.'
      );
    }

    if (requestParameters.setReplyTimeRequest === null || requestParameters.setReplyTimeRequest === undefined) {
      throw new runtime.RequiredError(
        'setReplyTimeRequest',
        'Required parameter requestParameters.setReplyTimeRequest was null or undefined when calling setReplyTime.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/reply-time`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: SetReplyTimeRequestToJSON(requestParameters.setReplyTimeRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Sets or deletes a reply time. The active party can set the reply time for the next round. \"Delete\" works by setting duration and timestamp to null.
   */
  async setReplyTime(requestParameters: SetReplyTimeOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.setReplyTimeRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updatePrivateNoteRaw(
    requestParameters: UpdatePrivateNoteOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationSummary>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling updatePrivateNote.'
      );
    }

    if (
      requestParameters.updatePrivateNoteRequest === null ||
      requestParameters.updatePrivateNoteRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'updatePrivateNoteRequest',
        'Required parameter requestParameters.updatePrivateNoteRequest was null or undefined when calling updatePrivateNote.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/negotiations/{negotiationId}/private-note`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdatePrivateNoteRequestToJSON(requestParameters.updatePrivateNoteRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationSummaryFromJSON(jsonValue));
  }

  /**
   */
  async updatePrivateNote(requestParameters: UpdatePrivateNoteOperationRequest): Promise<NegotiationSummary> {
    const response = await this.updatePrivateNoteRaw(requestParameters);
    return await response.value();
  }
}
