/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  NewsItemListResponse,
  NewsItemListResponseFromJSON,
  NewsItemListResponseToJSON,
  NewsItemRead,
  NewsItemReadFromJSON,
  NewsItemReadToJSON,
  NewsItemWrite,
  NewsItemWriteFromJSON,
  NewsItemWriteToJSON,
} from '../models';

export interface GetNewsItemRequest {
  id: number;
}

export interface ListNewsItemsRequest {
  pageNumber?: number;
  pageSize?: number;
}

export interface ManageAddDocumentToNewsItemRequest {
  id: string;
  file?: Blob;
}

export interface ManageAddImageToNewsItemRequest {
  id: string;
  file?: Blob;
}

export interface ManageCreateNewsItemRequest {
  newsItemWrite: NewsItemWrite;
}

export interface ManageDeleteNewsItemRequest {
  id: number;
}

export interface ManageGetNewsItemRequest {
  id: number;
}

export interface ManageListNewsItemsRequest {
  pageNumber?: number;
  pageSize?: number;
}

export interface ManageUpdateNewsItemRequest {
  id: string;
  newsItemWrite: NewsItemWrite;
}

/**
 *
 */
export class NewsApi extends runtime.BaseAPI {
  /**
   * Get single news item.
   */
  async getNewsItemRaw(requestParameters: GetNewsItemRequest): Promise<runtime.ApiResponse<NewsItemRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getNewsItem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/news-items/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NewsItemReadFromJSON(jsonValue));
  }

  /**
   * Get single news item.
   */
  async getNewsItem(requestParameters: GetNewsItemRequest): Promise<NewsItemRead> {
    const response = await this.getNewsItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Lists News.
   */
  async listNewsItemsRaw(requestParameters: ListNewsItemsRequest): Promise<runtime.ApiResponse<NewsItemListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.pageNumber !== undefined) {
      queryParameters['pageNumber'] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/news-items`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NewsItemListResponseFromJSON(jsonValue));
  }

  /**
   * Lists News.
   */
  async listNewsItems(requestParameters: ListNewsItemsRequest): Promise<NewsItemListResponse> {
    const response = await this.listNewsItemsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Add document to a news item.
   */
  async manageAddDocumentToNewsItemRaw(
    requestParameters: ManageAddDocumentToNewsItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageAddDocumentToNewsItem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{contentType: 'multipart/form-data'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request({
      path: `/api/manage/news-items/{id}/document`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add document to a news item.
   */
  async manageAddDocumentToNewsItem(requestParameters: ManageAddDocumentToNewsItemRequest): Promise<void> {
    await this.manageAddDocumentToNewsItemRaw(requestParameters);
  }

  /**
   * Add / Delete header image to a news item.
   */
  async manageAddImageToNewsItemRaw(
    requestParameters: ManageAddImageToNewsItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageAddImageToNewsItem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{contentType: 'multipart/form-data'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request({
      path: `/api/manage/news-items/{id}/image`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add / Delete header image to a news item.
   */
  async manageAddImageToNewsItem(requestParameters: ManageAddImageToNewsItemRequest): Promise<void> {
    await this.manageAddImageToNewsItemRaw(requestParameters);
  }

  /**
   * Create news item.
   */
  async manageCreateNewsItemRaw(
    requestParameters: ManageCreateNewsItemRequest
  ): Promise<runtime.ApiResponse<NewsItemRead>> {
    if (requestParameters.newsItemWrite === null || requestParameters.newsItemWrite === undefined) {
      throw new runtime.RequiredError(
        'newsItemWrite',
        'Required parameter requestParameters.newsItemWrite was null or undefined when calling manageCreateNewsItem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/manage/news-items`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: NewsItemWriteToJSON(requestParameters.newsItemWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NewsItemReadFromJSON(jsonValue));
  }

  /**
   * Create news item.
   */
  async manageCreateNewsItem(requestParameters: ManageCreateNewsItemRequest): Promise<NewsItemRead> {
    const response = await this.manageCreateNewsItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete news item.
   */
  async manageDeleteNewsItemRaw(requestParameters: ManageDeleteNewsItemRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageDeleteNewsItem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/manage/news-items/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete news item.
   */
  async manageDeleteNewsItem(requestParameters: ManageDeleteNewsItemRequest): Promise<void> {
    await this.manageDeleteNewsItemRaw(requestParameters);
  }

  /**
   * Get single news item.
   */
  async manageGetNewsItemRaw(requestParameters: ManageGetNewsItemRequest): Promise<runtime.ApiResponse<NewsItemRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageGetNewsItem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/manage/news-items/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NewsItemReadFromJSON(jsonValue));
  }

  /**
   * Get single news item.
   */
  async manageGetNewsItem(requestParameters: ManageGetNewsItemRequest): Promise<NewsItemRead> {
    const response = await this.manageGetNewsItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Lists News.
   */
  async manageListNewsItemsRaw(
    requestParameters: ManageListNewsItemsRequest
  ): Promise<runtime.ApiResponse<NewsItemListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.pageNumber !== undefined) {
      queryParameters['pageNumber'] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/manage/news-items`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NewsItemListResponseFromJSON(jsonValue));
  }

  /**
   * Lists News.
   */
  async manageListNewsItems(requestParameters: ManageListNewsItemsRequest): Promise<NewsItemListResponse> {
    const response = await this.manageListNewsItemsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update news item.
   */
  async manageUpdateNewsItemRaw(requestParameters: ManageUpdateNewsItemRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageUpdateNewsItem.'
      );
    }

    if (requestParameters.newsItemWrite === null || requestParameters.newsItemWrite === undefined) {
      throw new runtime.RequiredError(
        'newsItemWrite',
        'Required parameter requestParameters.newsItemWrite was null or undefined when calling manageUpdateNewsItem.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/manage/news-items/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: NewsItemWriteToJSON(requestParameters.newsItemWrite),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update news item.
   */
  async manageUpdateNewsItem(requestParameters: ManageUpdateNewsItemRequest): Promise<void> {
    await this.manageUpdateNewsItemRaw(requestParameters);
  }
}
