/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineEventTypeFromJSON,
  VesselVoyageTimelineEventTypeFromJSONTyped,
  VesselVoyageTimelineEventTypeToJSON,
  VoyagePort,
  VoyagePortFromJSON,
  VoyagePortFromJSONTyped,
  VoyagePortToJSON,
} from './';

/**
 *
 * @export
 * @interface VoyageTimelineRoute
 */
export interface VoyageTimelineRoute {
  /**
   *
   * @type {VesselVoyageTimelineEventType}
   * @memberof VoyageTimelineRoute
   */
  eventType: VesselVoyageTimelineEventType;
  /**
   *
   * @type {Date}
   * @memberof VoyageTimelineRoute
   */
  enterTimestamp: Date;
  /**
   *
   * @type {boolean}
   * @memberof VoyageTimelineRoute
   */
  isOngoing: boolean;
  /**
   *
   * @type {VoyagePort}
   * @memberof VoyageTimelineRoute
   */
  startPort: VoyagePort;
  /**
   *
   * @type {number}
   * @memberof VoyageTimelineRoute
   */
  startPortVisitId: number;
  /**
   *
   * @type {VoyagePort}
   * @memberof VoyageTimelineRoute
   */
  endPort: VoyagePort | null;
  /**
   *
   * @type {number}
   * @memberof VoyageTimelineRoute
   */
  endPortVisitId: number | null;
  /**
   *
   * @type {Date}
   * @memberof VoyageTimelineRoute
   */
  exitTimestamp: Date;
  /**
   *
   * @type {number}
   * @memberof VoyageTimelineRoute
   */
  distance: number | null;
  /**
   * The order is longitude and then latitude
   * @type {Array<Array<Array<number>>>}
   * @memberof VoyageTimelineRoute
   */
  waypoints: Array<Array<Array<number>>> | null;
  /**
   *
   * @type {number}
   * @memberof VoyageTimelineRoute
   */
  avgSpeed: number | null;
  /**
   *
   * @type {number}
   * @memberof VoyageTimelineRoute
   */
  avgDraft: number | null;
}

export function VoyageTimelineRouteFromJSON(json: any): VoyageTimelineRoute {
  return VoyageTimelineRouteFromJSONTyped(json, false);
}

export function VoyageTimelineRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoyageTimelineRoute {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeFromJSON(json['eventType']),
    enterTimestamp: new Date(json['enterTimestamp']),
    isOngoing: json['isOngoing'],
    startPort: VoyagePortFromJSON(json['startPort']),
    startPortVisitId: json['startPortVisitId'],
    endPort: VoyagePortFromJSON(json['endPort']),
    endPortVisitId: json['endPortVisitId'],
    exitTimestamp: new Date(json['exitTimestamp']),
    distance: json['distance'],
    waypoints: json['waypoints'],
    avgSpeed: json['avgSpeed'],
    avgDraft: json['avgDraft'],
  };
}

export function VoyageTimelineRouteToJSON(value?: VoyageTimelineRoute | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeToJSON(value.eventType),
    enterTimestamp: value.enterTimestamp.toISOString(),
    isOngoing: value.isOngoing,
    startPort: VoyagePortToJSON(value.startPort),
    startPortVisitId: value.startPortVisitId,
    endPort: VoyagePortToJSON(value.endPort),
    endPortVisitId: value.endPortVisitId,
    exitTimestamp: value.exitTimestamp.toISOString(),
    distance: value.distance,
    waypoints: value.waypoints,
    avgSpeed: value.avgSpeed,
    avgDraft: value.avgDraft,
  };
}
