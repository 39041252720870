import {FC, useMemo} from 'react';
import {StatCard} from '../sharedComponents/StatCard';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import ReactECharts from 'echarts-for-react';
import {getDurationScatterPlotByProp} from './utils/getDurationScatterPlotByProp';
import {vesselTypeByName} from '../../../components/consts/vesselTypes';
import {getVesselSizeTypeByLabel} from '../../../hooks/useGetCompetitors/VesselSizeType';

export const TimePerVesselType: FC<{
  visits: MergedVisits[];
  onSetVisits?: (visits: MergedVisits[]) => void;
  onSetAppliedFilter?: (type: string, value: string) => void;
}> = ({visits}) => {
  const getColor = (label: string) => {
    const vesselType = vesselTypeByName(label);
    return vesselType?.fillColor ?? 'black';
  };
  const {title, singleAxis, series} = useMemo(
    () => getDurationScatterPlotByProp(visits, 'vesselType', getColor),
    [visits]
  );

  return (
    <StatCard>
      <ReactECharts
        option={{
          tooltip: {
            trigger: 'item',
            position: 'top',
          },
          title,
          singleAxis,
          series,
        }}
      />
    </StatCard>
  );
};

export const TimePerVesselSubType: FC<{
  visits: MergedVisits[];
  onSetVisits?: (visits: MergedVisits[]) => void;
  onSetAppliedFilter?: (type: string, value: string) => void;
}> = ({visits}) => {
  const getColor = (label: string) => {
    const vesselType = getVesselSizeTypeByLabel(label);
    return vesselType ? vesselType.color : '#000';
  };
  const {title, singleAxis, series} = useMemo(() => {
    const filteredVisits = visits.filter(vessel => vessel.vesselType.toLowerCase() === 'bulker');
    return getDurationScatterPlotByProp(filteredVisits, 'label', getColor);
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        style={{height: 600}}
        option={{
          tooltip: {
            trigger: 'item',
            position: 'top',
          },
          title,
          singleAxis,
          series,
        }}
      />
    </StatCard>
  );
};
