import {SeaboVessel, SeaboVesselOption} from '../SearchPicker/Type';
import Flag from '../../atoms/Flags';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {SeaboVesselPicker} from './SeaboVesselPicker';
import {BlindInputFieldToReset} from '../../atoms/BlindInputFieldToReset/BlindInputFieldToReset';
import {SearchHit, VesselType} from '../../api/symfony/generated/models';
import {VesselPickerIndexName} from '../SearchPicker/useOptionsFromSeaboVesselSearch';

interface SeaboVesselProp {
  name: string;
  countryFlag?: string | null;
}

type Props = {
  seaboVessel?: SeaboVesselProp | undefined;
  onChange?: (seaboVessel: SeaboVessel | undefined) => void;
  size?: SizeType;
  defaultValue?: string;
  makeOption?: (searchHit: SearchHit) => SeaboVesselOption;
  vesselTypes?: VesselType[];
  indexNames?: VesselPickerIndexName[] | VesselPickerIndexName;
};

export const SeaboVesselPickerWithFlag = (props: Props) => {
  const {seaboVessel} = props;

  if (seaboVessel) {
    return (
      <BlindInputFieldToReset
        dataTestIdPrefix={'SeaboVesselPicker'}
        prefix={<Flag countryCode={seaboVessel.countryFlag} />}
        onReset={() => {
          props.onChange?.(undefined);
        }}
        size={props.size || 'small'}
        value={seaboVessel.name}
      />
    );
  }
  return (
    <SeaboVesselPicker
      makeOption={props.makeOption}
      indexNames={props.indexNames}
      vesselTypes={props.vesselTypes}
      size={props.size}
      prefix={<span style={{width: 17}}></span>}
      defaultValue={props.defaultValue}
      onSelect={seaboVessel => {
        props.onChange && props.onChange(seaboVessel);
      }}
    />
  );
};
