/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {AisVessel, AisVesselFromJSON, AisVesselFromJSONTyped, AisVesselToJSON} from './';

/**
 *
 * @export
 * @interface AisVesselResponseData
 */
export interface AisVesselResponseData {
  /**
   * The vessels.
   * @type {Array<AisVessel>}
   * @memberof AisVesselResponseData
   */
  items: Array<AisVessel>;
  /**
   * The total number of returned vessels.
   * @type {number}
   * @memberof AisVesselResponseData
   */
  totalItems: number;
}

export function AisVesselResponseDataFromJSON(json: any): AisVesselResponseData {
  return AisVesselResponseDataFromJSONTyped(json, false);
}

export function AisVesselResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisVesselResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(AisVesselFromJSON),
    totalItems: json['totalItems'],
  };
}

export function AisVesselResponseDataToJSON(value?: AisVesselResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(AisVesselToJSON),
    totalItems: value.totalItems,
  };
}
