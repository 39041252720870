/* tslint:disable */
/* eslint-disable */
/**
 * PetridishService
 * PetridishService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  Annotation,
  AnnotationFromJSON,
  AnnotationToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  NewAnnotationRequest,
  NewAnnotationRequestFromJSON,
  NewAnnotationRequestToJSON,
} from '../models';

export interface CreateAnnotationRequest {
  newAnnotationRequest?: NewAnnotationRequest;
}

export interface DeleteAnnotationByIdRequest {
  annotationId: string;
}

export interface GetAnnotationByIMOAndUserIdRequest {
  imo: number;
  userId: number;
}

export interface GetAnnotationByIdRequest {
  annotationId: string;
}

export interface GetAnnotationsByUserIdRequest {
  userId: number;
}

export interface UpdateAnnotationByIdRequest {
  annotationId: string;
  newAnnotationRequest?: NewAnnotationRequest;
}

/**
 *
 */
export class AnnotationApi extends runtime.BaseAPI {
  /**
   * Create annotation
   */
  async createAnnotationRaw(requestParameters: CreateAnnotationRequest): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearerAuth', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/annotations`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: NewAnnotationRequestToJSON(requestParameters.newAnnotationRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create annotation
   */
  async createAnnotation(requestParameters: CreateAnnotationRequest): Promise<void> {
    await this.createAnnotationRaw(requestParameters);
  }

  /**
   * Delete annotation by ID
   */
  async deleteAnnotationByIdRaw(requestParameters: DeleteAnnotationByIdRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
      throw new runtime.RequiredError(
        'annotationId',
        'Required parameter requestParameters.annotationId was null or undefined when calling deleteAnnotationById.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearerAuth', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/annotations/{annotationId}`.replace(
        `{${'annotationId'}}`,
        encodeURIComponent(String(requestParameters.annotationId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete annotation by ID
   */
  async deleteAnnotationById(requestParameters: DeleteAnnotationByIdRequest): Promise<void> {
    await this.deleteAnnotationByIdRaw(requestParameters);
  }

  /**
   * Get annotation by IMO
   */
  async getAnnotationByIMOAndUserIdRaw(
    requestParameters: GetAnnotationByIMOAndUserIdRequest
  ): Promise<runtime.ApiResponse<Annotation>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getAnnotationByIMOAndUserId.'
      );
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getAnnotationByIMOAndUserId.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearerAuth', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/annotations/imo/{imo}/{userId}`
        .replace(`{${'imo'}}`, encodeURIComponent(String(requestParameters.imo)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AnnotationFromJSON(jsonValue));
  }

  /**
   * Get annotation by IMO
   */
  async getAnnotationByIMOAndUserId(requestParameters: GetAnnotationByIMOAndUserIdRequest): Promise<Annotation> {
    const response = await this.getAnnotationByIMOAndUserIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get annotation by ID
   */
  async getAnnotationByIdRaw(requestParameters: GetAnnotationByIdRequest): Promise<runtime.ApiResponse<Annotation>> {
    if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
      throw new runtime.RequiredError(
        'annotationId',
        'Required parameter requestParameters.annotationId was null or undefined when calling getAnnotationById.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearerAuth', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/annotations/{annotationId}`.replace(
        `{${'annotationId'}}`,
        encodeURIComponent(String(requestParameters.annotationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AnnotationFromJSON(jsonValue));
  }

  /**
   * Get annotation by ID
   */
  async getAnnotationById(requestParameters: GetAnnotationByIdRequest): Promise<Annotation> {
    const response = await this.getAnnotationByIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get annotations by userId
   */
  async getAnnotationsByUserIdRaw(
    requestParameters: GetAnnotationsByUserIdRequest
  ): Promise<runtime.ApiResponse<Annotation>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getAnnotationsByUserId.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearerAuth', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/annotations/user/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(requestParameters.userId))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AnnotationFromJSON(jsonValue));
  }

  /**
   * Get annotations by userId
   */
  async getAnnotationsByUserId(requestParameters: GetAnnotationsByUserIdRequest): Promise<Annotation> {
    const response = await this.getAnnotationsByUserIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update annotation by ID
   */
  async updateAnnotationByIdRaw(requestParameters: UpdateAnnotationByIdRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.annotationId === null || requestParameters.annotationId === undefined) {
      throw new runtime.RequiredError(
        'annotationId',
        'Required parameter requestParameters.annotationId was null or undefined when calling updateAnnotationById.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearerAuth', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/annotations/{annotationId}`.replace(
        `{${'annotationId'}}`,
        encodeURIComponent(String(requestParameters.annotationId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: NewAnnotationRequestToJSON(requestParameters.newAnnotationRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update annotation by ID
   */
  async updateAnnotationById(requestParameters: UpdateAnnotationByIdRequest): Promise<void> {
    await this.updateAnnotationByIdRaw(requestParameters);
  }
}
