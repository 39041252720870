export const setupFetch = () => {
  // A global override of the fetch function. This is needed to make better sentry messages.
  window.fetch = wrapFetchWithExtendedLogging(window.fetch);
};

type FetchFunction = typeof window.fetch;
export const wrapFetchWithExtendedLogging = (oldFetch: FetchFunction): FetchFunction => {
  const wrappedFetch: FetchFunction = async (input, init): Promise<Response> => {
    let response = null;
    try {
      response = await oldFetch(input, init);
      if (!response.ok) {
        await logFetchNotOk(input, init, response);
      }
      return response;
    } catch (error) {
      if (error instanceof Error && error.message === 'Failed to fetch') {
        await logFetchError(input, init, response, error);
      }
      throw error;
    }
  };
  return wrappedFetch;
};

const logFetchNotOk = async (input: RequestInfo | URL, init: RequestInit | undefined, response: Response | null) => {
  const requestBody = JSON.stringify(init?.body ?? {}, null, 2);
  const responseBody = JSON.stringify(tryJsonParseFailedResponseBody(await response?.clone().text()), null, 2);

  // eslint-disable-next-line no-console
  console.error(`Wrapped fetch request: Not ok - ${init?.method ?? 'GET'} ${input.toString()}
Status: ${response?.status}/${response?.statusText}
Request body: ${requestBody}
Response body: ${responseBody}
`);
};

const logFetchError = async (
  input: RequestInfo | URL,
  init: RequestInit | undefined,
  response: Response | null,
  error: Error
) => {
  const requestBody = JSON.stringify(init?.body ?? {}, null, 2);
  const responseBody = JSON.stringify(tryJsonParseFailedResponseBody(await response?.clone().text()), null, 2);

  // eslint-disable-next-line no-console
  console.error(
    `Wrapped fetch request: Error - ${init?.method ?? 'GET'} ${input.toString()}
Status: ${response?.status}/${response?.statusText}
Error: ${error.message}
Request body: ${requestBody}
Response body: ${responseBody}
`,
    error
  );
};

/**
 * Tries to parse the given response body as JSON. Useful for logging failed API responses that are of unknown type.
 * @param responseBody The response body to parse.
 * @returns The parsed response body or the original response body if it couldn't be parsed.
 */
export const tryJsonParseFailedResponseBody = (responseBody?: string | null): object | string | undefined | null => {
  if (!responseBody || typeof responseBody !== 'string') {
    return responseBody;
  }
  try {
    return JSON.parse(responseBody);
  } catch (e) {
    return responseBody;
  }
};
