import {FC} from 'react';
import {Intake, PortfolioVessel} from '../../../../redux/Portfolio';
import {Checkbox, Form} from 'antd';
import uniqBy from 'lodash/uniqBy';

const getIntakeTag = (intake: Intake): string => {
  return `${intake.quantity}_${intake.type}_${intake.isHighCube ? 'high' : ''}`;
};

const getIntakeLabel = (intake: Intake) => {
  return (
    <span>
      {intake.quantity} {intake.type} {intake.isHighCube && 'HIGH'}
    </span>
  );
};

export const IntakeDiffs: FC<{
  onChange: (newIntake: Intake[]) => void;
  newVessel: PortfolioVessel;
  oldvessel: PortfolioVessel;
  value: Intake[];
  hasIntakeDiffs: boolean;
}> = ({newVessel, oldvessel, value, onChange, hasIntakeDiffs}) => {
  if (!hasIntakeDiffs) {
    return null;
  }
  const possibleIntakes = [...(newVessel.intakes ?? []), ...(oldvessel.intakes ?? [])];
  const possibleIntakeMap: Record<string, Intake> = {};
  possibleIntakes.forEach(intake => {
    possibleIntakeMap[getIntakeTag(intake)] = intake;
  });

  const valueArray = value.map(intakte => getIntakeTag(intakte));

  return (
    <Form.Item label={'Intakes'}>
      <Checkbox.Group
        style={{paddingLeft: 32}}
        value={valueArray}
        onChange={newSelection => {
          const newIntakes = newSelection.map(key => possibleIntakeMap[key as string]);
          // use Reverse to remove the older selected
          const uniqIntakes = uniqBy(newIntakes.reverse(), 'type');

          onChange(uniqIntakes);
        }}>
        {possibleIntakes.map(intake => {
          return (
            <>
              <Checkbox value={getIntakeTag(intake)}>{getIntakeLabel(intake)}</Checkbox>
              <br />
            </>
          );
        })}
      </Checkbox.Group>
    </Form.Item>
  );
};
