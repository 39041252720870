import sumBy from 'lodash/sumBy';
import React, {useMemo} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {OfferStatisticsElement} from './OfferStatistic';
import {getDayToDayTrend} from '../../utils/getDayToDayTrend';

export const VesselOfferStatic = ({buckets}: {buckets: OfferBucket[]}) => {
  const vesselBuckets = useMemo(() => buckets.filter(bucket => (bucket.numberOfVesselOffers ?? 0) > 0), [buckets]);

  const value = sumBy(vesselBuckets, bucket => bucket.numberOfVesselOffers ?? 0);

  const trend = getDayToDayTrend(buckets);

  return <OfferStatisticsElement trend={trend} title={'Vessel'} value={value} />;
};
