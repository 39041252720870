import {CSSProperties, FC, ReactNode} from 'react';
import {Button, Dropdown, Menu} from 'antd';
import styled from 'styled-components';
import {CaretDownOutlined} from '@ant-design/icons';
import {ItemTypeWithDataCy} from '../../components/ActionMenu/ActionMenu';

export interface ActionMenuButtonProps {
  actionIcon?: ReactNode;
  position: 'left' | 'right';
  below?: boolean;
  label?: ReactNode;
  styleOverride?: CSSProperties;
  className?: string;
  items: ItemTypeWithDataCy[];
  children?: ReactNode;
}

export const ActionMenuButton: FC<ActionMenuButtonProps> = ({label = 'ACTIONS', className, items}) => {
  const filteredItems = items
    .filter(item => item.isValid !== false)
    .map(item => {
      // delete isValid Property to prevent passing it as attribute to the dom element
      delete item.isValid;
      return item;
    });

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: '1',
            label: <StyledMenu items={filteredItems} />,
          },
        ],
      }}>
      <Button type={'primary'} className={className}>
        {label}
        <span>
          <CaretDownOutlined
            style={{
              marginLeft: 8,
              fontSize: 11,
            }}
          />
        </span>
      </Button>
    </Dropdown>
  );
};

const StyledMenu = styled(Menu)`
  min-width: 155px;

  .ant-dropdown-menu-item {
    padding: 0;
  }
`;

export default ActionMenuButton;
