import {FC} from 'react';
import {UserDateTime} from '../../../components/DateTime/UserDateTime';
import {getLastCommittedRound} from './ReplyTime/getLastCommitedRound';
import {formatReplyTimeDuration, getTimeLeftMs} from './ReplyTime/utils';
import {NegotiationRoundResultSummary} from '../../../api/node-backend/generated';
import {LoadingDots} from './shared';

type NegotiationLastReplyProps = {
  roundResultSummaries?: NegotiationRoundResultSummary[];
  format?: 'date' | 'dateTime' | 'timeAgo';
  notStartedYetText?: string;
  prefix?: string;
  timeAgoSuffix?: string;
};

export const NegotiationLastReply: FC<NegotiationLastReplyProps> = ({
  roundResultSummaries,
  format = 'timeAgo',
  notStartedYetText = 'Not started yet',
  prefix,
  timeAgoSuffix,
}) => {
  if (!roundResultSummaries?.length) {
    return <LoadingDots />;
  }

  const lastCommittedRound = getLastCommittedRound(roundResultSummaries);

  if (!lastCommittedRound?.committedAt) {
    return <>{notStartedYetText}</>;
  }

  const {committedAt} = lastCommittedRound;

  if (format === 'timeAgo') {
    const timeAgoMs = getTimeLeftMs(committedAt) * -1;

    return (
      <span data-testid="NegotiationLastReplyAgo">
        {prefix}
        {formatReplyTimeDuration(timeAgoMs, false)}
        {timeAgoSuffix}
      </span>
    );
  }

  return (
    <span data-testid="NegotiationLastReplyDate">
      {prefix}
      <UserDateTime value={committedAt!.toString()} format={format} />
    </span>
  );
};
