import React, {Fragment} from 'react';
import {getNameFromIceClass} from '../../utils/helper';
import {Attribute} from '../../atoms/Attribute/Attribute';
import AttributeIcon from '../../atoms/Attribute/AttributeIcon';
import HorizontalLine from '../../atoms/HorizontalLine';
import DetailTableRow from '../DetailTable/DetailTableRow';
import DetailsTableCell from '../DetailTable/DetailTableCell';
import DetailTable from '../DetailTable';
import DetailTableBody from '../DetailTable/DetailTableBody';
import {
  vesselFeatures,
  vesselFeatureSet,
  cargoFeatureSet,
  cargoFeatures,
  gearTypes,
} from '../../screens/CargoVesselForm/helper';
import './detailFeatures.scss';
import {numberUnitFormat} from '../../utils/formatter';
import {FeatureSection} from '../../screens/CargoVesselForm/CargoVesselForm.styled';
import {
  Gear,
  Vessel,
} from '../../screens/MyFleetScreens/MyFleetScreen/VesselPositionListModal/useMyFleetQuery/useMyFleetQuery';
import {
  VesselDetailsVessel,
  VesselTypeWithTanker,
} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

export const GearsTable = ({gears = []}: {gears: Gear[]}) => {
  if (gears.length === 0) {
    return null;
  }
  return (
    <div style={{paddingTop: '5px', marginBottom: '20px'}}>
      <DetailTable fullWidth>
        <DetailTableRow headerWrapper>
          <DetailsTableCell value={'Gear / Grab'} />
          <DetailsTableCell value={'Amount'} />
          <DetailsTableCell value={'Capacity'} />
          <DetailsTableCell value={'Type'} />
        </DetailTableRow>
        <DetailTableBody>
          {gears.map((item, idx) => {
            const unit = (item as $TSFixMe).type === 'gear' ? 'mts' : 'cbm';
            return (
              <DetailTableRow key={idx}>
                {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                <DetailsTableCell value={gearTypes[(item as $TSFixMe).type]} />
                <DetailsTableCell value={(item as $TSFixMe).quantity + 'x'} />
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message */}
                <DetailsTableCell value={numberUnitFormat((item as $TSFixMe).capacity, unit, {emptyString: null})} />
                <DetailsTableCell value={(item as $TSFixMe).description} />
              </DetailTableRow>
            );
          })}
        </DetailTableBody>
      </DetailTable>
    </div>
  );
};
type DetailFeaturesProps = {
  detailObject?: Vessel | VesselDetailsVessel;
  iceClass: string | null;
  segment: VesselTypeWithTanker | null;
  type: 'vessel' | 'cargo';
  small?: boolean;
  className?: string;
};
const DetailFeatures = ({detailObject, iceClass, segment, type, small, className}: DetailFeaturesProps) => {
  const featureSet = type === 'vessel' ? vesselFeatureSet : cargoFeatureSet;
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const features = type === 'vessel' ? vesselFeatures[segment] : cargoFeatures[segment];
  const iceClassLabel = getNameFromIceClass(iceClass!);
  return (
    <div className={className}>
      {detailObject?.gears && <GearsTable {...detailObject} />}
      {features && (
        <FeatureSection>
          {features.map((f: $TSFixMe, idx: $TSFixMe) => (
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            <AttributeIcon key={idx} check={detailObject[f]} label={featureSet[f]} small={small} />
          ))}
        </FeatureSection>
      )}
      {iceClassLabel && (
        <>
          <HorizontalLine large />
          <Attribute label="Ice class" value={iceClassLabel} tab={'m'} />
        </>
      )}
    </div>
  );
};
export default DetailFeatures;
