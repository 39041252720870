/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface PortVersionUser
 */
export interface PortVersionUser {
  /**
   *
   * @type {number}
   * @memberof PortVersionUser
   */
  userID: number;
  /**
   *
   * @type {string}
   * @memberof PortVersionUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PortVersionUser
   */
  fullName: string;
}

export function PortVersionUserFromJSON(json: any): PortVersionUser {
  return PortVersionUserFromJSONTyped(json, false);
}

export function PortVersionUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortVersionUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userID: json['userID'],
    email: json['email'],
    fullName: json['fullName'],
  };
}

export function PortVersionUserToJSON(value?: PortVersionUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userID: value.userID,
    email: value.email,
    fullName: value.fullName,
  };
}
