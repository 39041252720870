import React, {FC} from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import {Spacer} from '../../../Spacer/Spacer';
import {Notification} from '../../../../api/symfony/generated/models/Notification';
import Icon from '../../../Icon';

export const NotificationListControls: FC<{
  notifications: Notification[];
  onMarkAllAsRead?: (notifications: Notification[]) => void;
  onSettings?: () => void;
}> = ({
  notifications,
  onMarkAllAsRead,
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  onSettings,
}) => (
  <ControlsLayout>
    {/*
    <Settings onClick={onSettings} />
    */}
    {notifications.length > 0 && (
      <>
        <Spacer width={8} />
        <MarkAllReadButton
          onClick={() => {
            onMarkAllAsRead?.(notifications);
          }}
        />
      </>
    )}
  </ControlsLayout>
);

const ControlsLayout = styled.div`
  height: 50px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MarkAllReadButton: FC<{onClick?: () => void}> = ({onClick}) => {
  return (
    <Tooltip title="Mark all as Read">
      <IconButtonStyled onClick={onClick} data-testid="MarkAllAsRead">
        <StyledIcon type="check-mark-line" />
      </IconButtonStyled>
    </Tooltip>
  );
};

const IconButtonStyled = styled.a`
  display: inline-block;
`;

const StyledIcon = styled(Icon)`
  display: block;
  width: 16px;
  font-weight: normal !important;
  font-size: 1.25em !important;
  line-height: 16px !important;
  color: var(--color-gray-2) !important;

  &:hover::before {
    color: var(--color-blue);
  }
`;
