/*
  In the SECA area, ships consume 5% less if they have to consume MGO instead of IFO.
 */

import {ConsumptionFuelType} from './getUsedFuelType';
import {FuelType} from '../VesselInformation/VesselTypes';

type Params = {
  orginalConsumption: number;
  orginalFuelType: FuelType;
  substitutionFuelType: ConsumptionFuelType;
};
export const calculateConsumptionTakingIntoAccountFuelSubstitution = ({
  orginalConsumption,
  orginalFuelType,
  substitutionFuelType,
}: Params): number => {
  if (substitutionFuelType === 'mgo') {
    if (orginalFuelType === 'ifo') {
      return orginalConsumption * 0.95;
    }
  }

  return orginalConsumption;
};
