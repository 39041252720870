import CompanyActions from '../../../../redux/ApiService/CompanyService/CompanyService';
import {useQuery} from '@tanstack/react-query';
import {useDispatch} from '../../../../redux/react-redux';
import {ApiError} from '../../../../api/utils/ApiError';
import {TODO} from '../../../../utils/TODO';

export const useCompanyDataQuery = (id: number) => {
  const dispatch = useDispatch();
  const getCompanyData = (id: number) => dispatch(CompanyActions.getCompanyData(id));
  return useQuery<TODO, ApiError>({
    queryKey: ['company data overview'],
    queryFn: async () => await getCompanyData(id),
  });
};
