/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AddCargoesToProjectViaOffersResponse,
  AddCargoesToProjectViaOffersResponseFromJSON,
  AddCargoesToProjectViaOffersResponseToJSON,
  AddVesselsToProjectViaOffersResponse,
  AddVesselsToProjectViaOffersResponseFromJSON,
  AddVesselsToProjectViaOffersResponseToJSON,
  ProjectCargo,
  ProjectCargoFromJSON,
  ProjectCargoToJSON,
  ProjectVessel,
  ProjectVesselFromJSON,
  ProjectVesselToJSON,
} from '../models';

export interface AddCargoToProjectRequest {
  project: string;
  requestBody: Array<number>;
}

export interface AddCargoesToProjectViaOffersRequest {
  id: number;
  requestBody: Array<number>;
}

export interface AddVesselToProjectRequest {
  project: string;
  requestBody: Array<number>;
}

export interface AddVesselsToProjectViaOffersRequest {
  id: number;
  requestBody: Array<number>;
}

export interface CreateProjectRequest {
  body: object;
}

export interface DeleteProjectRequest {
  id: number;
}

export interface UpdateProjectRequest {
  id: string;
  body: object;
}

/**
 *
 */
export class ProjectApi extends runtime.BaseAPI {
  /**
   */
  async addCargoToProjectRaw(
    requestParameters: AddCargoToProjectRequest
  ): Promise<runtime.ApiResponse<Array<ProjectCargo>>> {
    if (requestParameters.project === null || requestParameters.project === undefined) {
      throw new runtime.RequiredError(
        'project',
        'Required parameter requestParameters.project was null or undefined when calling addCargoToProject.'
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling addCargoToProject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects/{project}/cargoes`.replace(
        `{${'project'}}`,
        encodeURIComponent(String(requestParameters.project))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ProjectCargoFromJSON));
  }

  /**
   */
  async addCargoToProject(requestParameters: AddCargoToProjectRequest): Promise<Array<ProjectCargo>> {
    const response = await this.addCargoToProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async addCargoesToProjectViaOffersRaw(
    requestParameters: AddCargoesToProjectViaOffersRequest
  ): Promise<runtime.ApiResponse<AddCargoesToProjectViaOffersResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling addCargoesToProjectViaOffers.'
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling addCargoesToProjectViaOffers.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects/{id}/cargoes-via-offers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AddCargoesToProjectViaOffersResponseFromJSON(jsonValue));
  }

  /**
   */
  async addCargoesToProjectViaOffers(
    requestParameters: AddCargoesToProjectViaOffersRequest
  ): Promise<AddCargoesToProjectViaOffersResponse> {
    const response = await this.addCargoesToProjectViaOffersRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async addVesselToProjectRaw(
    requestParameters: AddVesselToProjectRequest
  ): Promise<runtime.ApiResponse<Array<ProjectVessel>>> {
    if (requestParameters.project === null || requestParameters.project === undefined) {
      throw new runtime.RequiredError(
        'project',
        'Required parameter requestParameters.project was null or undefined when calling addVesselToProject.'
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling addVesselToProject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects/{project}/vessels`.replace(
        `{${'project'}}`,
        encodeURIComponent(String(requestParameters.project))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ProjectVesselFromJSON));
  }

  /**
   */
  async addVesselToProject(requestParameters: AddVesselToProjectRequest): Promise<Array<ProjectVessel>> {
    const response = await this.addVesselToProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async addVesselsToProjectViaOffersRaw(
    requestParameters: AddVesselsToProjectViaOffersRequest
  ): Promise<runtime.ApiResponse<AddVesselsToProjectViaOffersResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling addVesselsToProjectViaOffers.'
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling addVesselsToProjectViaOffers.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects/{id}/vessels-via-offers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AddVesselsToProjectViaOffersResponseFromJSON(jsonValue));
  }

  /**
   */
  async addVesselsToProjectViaOffers(
    requestParameters: AddVesselsToProjectViaOffersRequest
  ): Promise<AddVesselsToProjectViaOffersResponse> {
    const response = await this.addVesselsToProjectViaOffersRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async createProjectRaw(requestParameters: CreateProjectRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling createProject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async createProject(requestParameters: CreateProjectRequest): Promise<object> {
    const response = await this.createProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async deleteProjectRaw(requestParameters: DeleteProjectRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteProject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteProject(requestParameters: DeleteProjectRequest): Promise<void> {
    await this.deleteProjectRaw(requestParameters);
  }

  /**
   */
  async getProjectsRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async getProjects(): Promise<void> {
    await this.getProjectsRaw();
  }

  /**
   */
  async updateProjectRaw(requestParameters: UpdateProjectRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateProject.'
      );
    }

    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling updateProject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/projects/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async updateProject(requestParameters: UpdateProjectRequest): Promise<object> {
    const response = await this.updateProjectRaw(requestParameters);
    return await response.value();
  }
}
