/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {DayStatistics, DayStatisticsFromJSON, DayStatisticsFromJSONTyped, DayStatisticsToJSON} from './';

/**
 *
 * @export
 * @interface CongestionLightDay
 */
export interface CongestionLightDay {
  /**
   *
   * @type {string}
   * @memberof CongestionLightDay
   */
  date: string;
  /**
   *
   * @type {DayStatistics}
   * @memberof CongestionLightDay
   */
  statistic: DayStatistics;
  /**
   *
   * @type {DayStatistics}
   * @memberof CongestionLightDay
   */
  statisticBulker: DayStatistics;
  /**
   *
   * @type {DayStatistics}
   * @memberof CongestionLightDay
   */
  statisticContainer: DayStatistics;
  /**
   *
   * @type {DayStatistics}
   * @memberof CongestionLightDay
   */
  statisticMpp: DayStatistics;
}

export function CongestionLightDayFromJSON(json: any): CongestionLightDay {
  return CongestionLightDayFromJSONTyped(json, false);
}

export function CongestionLightDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): CongestionLightDay {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: json['date'],
    statistic: DayStatisticsFromJSON(json['statistic']),
    statisticBulker: DayStatisticsFromJSON(json['statisticBulker']),
    statisticContainer: DayStatisticsFromJSON(json['statisticContainer']),
    statisticMpp: DayStatisticsFromJSON(json['statisticMpp']),
  };
}

export function CongestionLightDayToJSON(value?: CongestionLightDay | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    statistic: DayStatisticsToJSON(value.statistic),
    statisticBulker: DayStatisticsToJSON(value.statisticBulker),
    statisticContainer: DayStatisticsToJSON(value.statisticContainer),
    statisticMpp: DayStatisticsToJSON(value.statisticMpp),
  };
}
