import {Form, Select} from 'antd';
import {FC} from 'react';
import {useWorkspacesQuery} from '../../../../queries/useWorkspacesQuery';
import {Workspace} from '../../../../redux/ApiService/PortfolioService';

export const CloneWorkspaceSelector: FC<{
  selectedWorkspace: Workspace;
  onChange: (newWorkspace: Workspace) => void;
}> = ({selectedWorkspace, onChange}) => {
  const workspacesQuery = useWorkspacesQuery();

  return (
    <Form.Item label="Select workspace">
      <Select
        value={selectedWorkspace.id}
        showSearch
        optionFilterProp="children"
        onChange={(value: number) => {
          const workspace = workspacesQuery.data?.find(item => item.id === value);
          if (workspace) {
            onChange(workspace);
          }
        }}
        filterOption={(input, option) => {
          const userInput = input.toLowerCase().trim();
          const workspaceName = (option?.children as string | undefined)?.toLowerCase();
          return workspaceName?.includes(userInput) ?? false;
        }}>
        {workspacesQuery.data?.map(item => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
