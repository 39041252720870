import dayjs from 'dayjs';
import numeral from 'numeral';
import {useState} from 'react';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {CharterCalculation, CharterCalculationCollection} from '../../api/symfony/generated/models';
import {charterCalculationApi} from '../../api/symfony/symfonyApi';
import {ApiError} from '../../api/utils/ApiError';
import {TBN} from '../../components/Placeholder/Placeholder';
import {useSelector} from '../../redux/react-redux';
import {Button} from '../Button/Button';
import {CustomColumnDef, DataGrid} from '../../components/DataGrid/DataGrid';

type Fields = 'name' | 'project' | 'cargoes' | 'vessel' | 'updated' | 'tce';

export const queryKey = 'charterCalculationListQueryKey';

export interface CharterCalculationListProps {
  selectedItemId?: number;
  hideFields?: Fields[];
  emptyMessage?: string;
  filter?: {
    projectId?: number;
    cargoId?: number;
    workspaceId?: number;
    tag?: string;
  };
  onSelect?: (item: CharterCalculation) => void;
  onOverwriteCalculation?: (item: CharterCalculation) => void;
  onOpenCalculation?: (item: CharterCalculation) => void;
}

export const CharterCalculationList = (props: CharterCalculationListProps) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const workspace = useSelector(state => state.user?.workspace);

  const calculationsQuery = useQuery<CharterCalculationCollection, ApiError>({
    queryKey: [queryKey, pageIndex, pageSize, props.filter],
    queryFn: () =>
      charterCalculationApi.getCalculations({
        ...props.filter,
        pageIndex: pageIndex,
        workspaceId: workspace?.id ?? 0,
        pageSize: pageSize,
      }),
    placeholderData: keepPreviousData,
  });

  const data = (calculationsQuery.data?.items || []).map(x => ({...x, key: x.id + ''}));

  const columns: CustomColumnDef<CharterCalculation>[] = [
    {
      id: 'name',
      header: 'Name',
      enableSorting: false,
      minWidth: 10,
      accessorFn: (item: $TSFixMe) => {
        return <span>{item.name}</span>;
      },
      cell: info => info.getValue(),
    },
    {
      id: 'project',
      header: 'Projects',
      enableSorting: false,
      minWidth: 10,
      accessorFn: (item: $TSFixMe) => {
        if (item.project === null || item.project === undefined) {
          return '';
        }
        return (
          <Link data-cy="projectLink" target={'_blank'} rel="noreferrer" to={`/projects/${item.project.id}`}>
            {item.project.name}
          </Link>
        );
      },
      cell: info => info.getValue(),
    },
    {
      id: 'cargoes',
      header: 'Cargoes',
      enableSorting: false,
      minWidth: 10,
      accessorFn: (item: $TSFixMe) => {
        return item.cargoes.map((cargo: {id: number; name?: string}) => (
          <Link data-cy="cargoLink" key={cargo.id} rel="noreferrer" target={'_blank'} to={`/cargo/${cargo.id}`}>
            {cargo.name ?? <TBN />}
          </Link>
        ));
      },
      cell: info => info.getValue(),
    },
    {
      id: 'vessel',
      header: 'Vessels',
      enableSorting: false,
      minWidth: 10,
      accessorFn: (item: $TSFixMe) => {
        if (item.vessel?.name === null || item.vessel?.name === undefined) {
          return '';
        }
        return (
          <Link data-cy="vesselLink" target={'_blank'} rel="noreferrer" to={`/vessel/${item.vessel.id}`}>
            {item.vessel.name}
          </Link>
        );
      },
      cell: info => info.getValue(),
    },
    {
      id: 'updated',
      header: 'Last Update',
      enableSorting: false,
      minWidth: 10,
      accessorFn: (item: $TSFixMe) => {
        return (
          <span data-cy="lastUpdated">
            {dayjs(item.updated).fromNow()} ({dayjs(item.updated).format('LLL')})
          </span>
        );
      },
      cell: info => info.getValue(),
    },
    {
      id: 'tce',
      header: 'TCE',
      enableSorting: false,
      minWidth: 5,
      accessorFn: (item: $TSFixMe) => {
        if (item.result?.cargo?.tce === undefined) {
          return <span data-cy="tce">not set</span>;
        }
        return <span data-cy="tce">{numeral(item.result?.cargo?.tce).format('$ 0,0')}</span>;
      },
      cell: info => info.getValue(),
    },
  ];

  if (props.onOverwriteCalculation) {
    columns.push({
      id: 'overwrite',
      header: 'Overwrite',
      enableSorting: false,
      minWidth: 8,
      accessorFn: (item: $TSFixMe) => (
        <Button
          data-cy="overwriteButton"
          className="overwrite-btn"
          secondary
          onClick={() => {
            props.onOverwriteCalculation!(item);
            props.onSelect?.(item);
          }}>
          Overwrite
        </Button>
      ),
      cell: info => info.getValue(),
    });
  }

  if (props.onOpenCalculation) {
    columns.push({
      id: 'open',
      header: '',
      enableSorting: false,
      minWidth: 5,
      accessorFn: (item: $TSFixMe) => (
        <Button
          data-cy="openButton"
          className="open-btn"
          secondary
          onClick={() => {
            props.onOpenCalculation!(item);
          }}>
          Open
        </Button>
      ),
      cell: info => info.getValue(),
    });
  }

  const filteredColumns: CustomColumnDef<CharterCalculation>[] = columns.filter(
    item => !(props.hideFields ?? []).includes(item.id as Fields)
  );

  return (
    <div data-testid={'charter-calculation-list-table'}>
      <StyledDataGrid
        getTrProps={(_state: $TSFixMe, rowInfo: $TSFixMe) => {
          if (rowInfo && rowInfo.row) {
            return {
              className: rowInfo.original.id === props.selectedItemId ? 'active' : '',
              style: {
                background: rowInfo.original.id === props.selectedItemId ? '#f9f9f9' : 'white',
              },
            };
          } else {
            return {};
          }
        }}
        columns={filteredColumns}
        data={data}
        unrounded
        emptyMessage={props.emptyMessage ?? "We are sorry, we couldn't find any results."}
        loading={calculationsQuery.isLoading}
        zeroBasedPageIndex={false}
        page={pageIndex}
        pageSize={pageSize}
        totalCount={calculationsQuery.data?.totalItems}
        showPagination={true}
        onPageChange={(pageIndex: number) => setPageIndex(pageIndex)}
        onPageSizeChange={(pageSize: number) => {
          setPageSize(pageSize);
          setPageIndex(1);
        }}
      />
    </div>
  );
};

const StyledDataGrid = styled(DataGrid<CharterCalculation>)`
  .pagination__entries {
    padding-left: 12px !important;
  }

  .pagination {
    border-top: 1px solid var(--color-gray-3);
    background: transparent !important;
  }

  .rt-tr {
    &:hover {
      background-color: var(--color-gray-5) !important;
    }
  }
`;
