import numeral from 'numeral';
import styled from 'styled-components';
import {HeaderBox} from './HeaderBox';

const ValueSpan = styled.span`
  color: var(--color-black);
  font-size: var(--font-size-lg);
  font-weight: 700;
  padding-top: 8px;

  &.isNegative {
    color: var(--color-red);
  }

  .ant-affix & {
    font-size: var(--font-size-lg);
  }
`;

export const TCEBox = (props: {value: number}) => {
  return (
    <HeaderBox
      title="TCE"
      isMarginRight={false}
      renderIcon={color => <span style={{fontSize: 18, fontWeight: 'bold', color}}>$</span>}>
      <ValueSpan data-cy={'TCEBox'} className={props.value < 0 ? 'isNegative' : ''}>
        ${numeral(props.value).format('0,0')}
      </ValueSpan>
    </HeaderBox>
  );
};
