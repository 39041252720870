import {convertLabelToSlug, convertToInt} from '../FilterConversion/utils/FilterConversionUtils';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {numberBetween, numberBetweenAndGreaterOrEqual} from '../../../../utils/validators';
import {BulkerDesignType, designSubTypes} from '../../../../screens/CargoVesselForm/helper';
import {convertValuesToNamedValues, NamedValue} from '../FilterConversion/utils/NamedValue';

export const BulkerDesigns: NamedValue<BulkerDesignType>[] = Object.entries(designSubTypes).map(
  (designSubType: [string, string]) => {
    const value = designSubType[0] as BulkerDesignType;
    const name = designSubType[1];
    return {name, value};
  }
);

export interface DatabaseType {
  deadWeight?: {
    from?: number;
    to?: number;
  };
  vesselDesigns?: BulkerDesignType[];
  vesselDesignTypes?: string[];
  teu?: {
    from?: number;
    to?: number;
  };
  teu14?: {
    from?: number;
    to?: number;
  };
}

export interface ContainerDesignType {
  label: string;
  value: string;
}

export interface InternalType {
  deadWeightFrom: number | '';
  deadWeightTo: number | '';
  vesselDesigns: NamedValue<BulkerDesignType>[];
  vesselDesignTypes: ContainerDesignType[];
  teuFrom: number | '';
  teuTo: number | '';
  teu14From: number | '';
  teu14To: number | '';
}

export const Defaults: InternalType = {
  deadWeightFrom: '',
  deadWeightTo: '',
  vesselDesigns: [],
  vesselDesignTypes: [],
  teuFrom: '',
  teuTo: '',
  teu14From: '',
  teu14To: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {deadWeight, vesselDesigns, vesselDesignTypes, teu, teu14} = database;
  return {
    deadWeightFrom: deadWeight?.from ?? '',
    deadWeightTo: deadWeight?.to ?? '',
    teuFrom: teu?.from ?? '',
    teuTo: teu?.to ?? '',
    teu14From: teu14?.from ?? '',
    teu14To: teu14?.to ?? '',
    vesselDesigns: vesselDesigns ? convertValuesToNamedValues(vesselDesigns, BulkerDesigns) : [],
    vesselDesignTypes:
      vesselDesignTypes?.map(vesselDesignType => ({
        value: convertLabelToSlug(vesselDesignType),
        label: vesselDesignType,
      })) ?? [],
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {deadWeightFrom, deadWeightTo, vesselDesigns, vesselDesignTypes, teuFrom, teuTo, teu14From, teu14To} = internal;
  return {
    deadWeight: {
      from: convertToInt(deadWeightFrom),
      to: convertToInt(deadWeightTo),
    },
    vesselDesigns: vesselDesigns ? vesselDesigns.map(design => design.value) : [],
    vesselDesignTypes: vesselDesignTypes ? vesselDesignTypes.map(designType => designType.label) : [],
    teu: {
      from: convertToInt(teuFrom),
      to: convertToInt(teuTo),
    },
    teu14: {
      from: convertToInt(teu14From),
      to: convertToInt(teu14To),
    },
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel size',
  branch: 'vesselSize',
  defaults: Defaults,
  validators: {
    deadWeightFrom: numberBetween(0, 999999),
    deadWeightTo: numberBetweenAndGreaterOrEqual(0, 999999, 'deadWeightFrom'),
    teuFrom: numberBetween(0, 99999),
    teuTo: numberBetweenAndGreaterOrEqual(0, 99999, 'teuFrom'),
    teu14From: numberBetween(0, 99999),
    teu14To: numberBetweenAndGreaterOrEqual(0, 99999, 'teu14From'),
  },
  fromDatabase,
  toDatabase,
});
