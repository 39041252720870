import Icon from '../../atoms/Icon';
import './shareHistory.scss';
import {UserDateTime} from '../DateTime/UserDateTime';
import {StyledCard} from '../ProjectDetailsScreen/ProjectDetailItem/shared';
import {Link} from 'react-router-dom';
import {DataGrid} from '../DataGrid/DataGrid';

type Props = {
  history?: $TSFixMe[];
  style?: $TSFixMe;
  type?: 'vessel' | 'cargo';
};

const ShareHistory = ({history, type, style}: Props) => {
  return (
    <StyledCard>
      <div className={'sharing-history'} style={style}>
        <DataGrid
          responsive
          id={'project-detail-shortlist__data-grid'}
          columns={[
            {
              header: () => <div>Share history</div>,
              id: 'chartererName',
              cell: info => info.getValue(),
              accessorFn: d => (
                <div className={'sharing-history__row'}>
                  <Icon type={'logo-wave'} color={'blue'} />
                  <span className={'sharing-history__item'}>
                    {d && !d.public
                      ? d.sharedWith.users.length > 0 &&
                        d.sharedWith.users.map((share: $TSFixMe) => share.fullName).join(', ')
                      : 'Public share'}
                  </span>
                </div>
              ),
              enableSorting: false,
              minWidth: 150,
            },
            {
              header: () => <div>Date send</div>,
              id: 'createdAt',
              cell: info => info.getValue(),
              accessorFn: d => <UserDateTime format={'dateTime'} value={d.createdAt} />,
              enableSorting: false,
              minWidth: 150,
            },
            {
              id: 'viewDetails',
              header: '',
              cell: info => info.getValue(),
              accessorFn: d => (
                <>
                  {type === 'vessel' && <Link to={d.vessel ? `/vessel/${d.vessel.id}` : ''}>VIEW DETAILS</Link>}
                  {type === 'cargo' && <Link to={d.cargo ? `/cargo/${d.cargo.id}` : ''}>VIEW DETAILS</Link>}
                </>
              ),
              minWidth: 30,
            },
          ]}
          data={history}
          loading={false}
        />
      </div>
    </StyledCard>
  );
};

export default ShareHistory;
