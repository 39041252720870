import {SeaboVessel} from '../../../../api/node-backend/generated';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';

export const imoColumn = ({minWidth = 4}: {minWidth?: number}): CustomColumnDef<SeaboVessel> => ({
  header: 'Imo',
  id: 'imo',
  accessorFn: d => d.imo,
  cell: info => info.getValue(),
  minWidth,
  enableSorting: true,
});
