import {ArrowRightOutlined} from '@ant-design/icons';
import {useMemo, FC} from 'react';
import styled from 'styled-components';
import {interpolateNumber} from 'd3-interpolate';
import {Tooltip} from 'antd';

type Props = {
  // eslint-disable-next-line spaced-comment
  /***
   * number between -1 and 1
   */
  trend: number | null;

  size?: number;
  iconSize?: number;
};

export const InterpolatedArrow: FC<Props> = ({trend, size = 28, iconSize = 14}) => {
  const trendStyle = useMemo(() => {
    const deg = interpolateNumber(45, -45)(((trend ?? 0) + 1) / 2);
    if (trend === null) {
      return {color: 'var(--color-azure)', deg};
    }
    switch (true) {
      case trend > 0:
        return {
          color: '#62d388',
          deg,
        };
      case trend < 0:
        return {
          color: '#f00046',
          deg,
        };
      default:
        return {
          color: 'var(--color-azure)',
          deg,
        };
    }
  }, [trend]);

  if (trend === null) {
    return null;
  }

  return (
    <Tooltip trigger={['hover']} title={`${Math.round(trend * 100)} % day on day`}>
      <Container size={size} backgroundColor={trendStyle.color} rotation={trendStyle.deg}>
        <ArrowRightOutlined style={{fontSize: iconSize}} />
      </Container>
    </Tooltip>
  );
};

const Container = styled.div<{rotation: number; backgroundColor: string; size: number}>`
  color: var(--color-white);

  background-color: ${props => props.backgroundColor};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 3px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    transform: rotate(${props => props.rotation}deg);
  }
`;
