/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ConsumptionTypeEnum,
  ConsumptionTypeEnumFromJSON,
  ConsumptionTypeEnumFromJSONTyped,
  ConsumptionTypeEnumToJSON,
  FuelTypeEnum,
  FuelTypeEnumFromJSON,
  FuelTypeEnumFromJSONTyped,
  FuelTypeEnumToJSON,
} from './';

/**
 *
 * @export
 * @interface Consumption
 */
export interface Consumption {
  /**
   *
   * @type {number}
   * @memberof Consumption
   */
  id: number;
  /**
   *
   * @type {ConsumptionTypeEnum}
   * @memberof Consumption
   */
  type: ConsumptionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof Consumption
   */
  speed: number;
  /**
   *
   * @type {number}
   * @memberof Consumption
   */
  fuelConsumption: number;
  /**
   *
   * @type {FuelTypeEnum}
   * @memberof Consumption
   */
  fuelType: FuelTypeEnum;
  /**
   *
   * @type {number}
   * @memberof Consumption
   */
  extraFuelConsumption: number | null;
  /**
   *
   * @type {FuelTypeEnum}
   * @memberof Consumption
   */
  extraFuelType: FuelTypeEnum;
}

export function ConsumptionFromJSON(json: any): Consumption {
  return ConsumptionFromJSONTyped(json, false);
}

export function ConsumptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Consumption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: ConsumptionTypeEnumFromJSON(json['type']),
    speed: json['speed'],
    fuelConsumption: json['fuelConsumption'],
    fuelType: FuelTypeEnumFromJSON(json['fuelType']),
    extraFuelConsumption: json['extraFuelConsumption'],
    extraFuelType: FuelTypeEnumFromJSON(json['extraFuelType']),
  };
}

export function ConsumptionToJSON(value?: Consumption | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: ConsumptionTypeEnumToJSON(value.type),
    speed: value.speed,
    fuelConsumption: value.fuelConsumption,
    fuelType: FuelTypeEnumToJSON(value.fuelType),
    extraFuelConsumption: value.extraFuelConsumption,
    extraFuelType: FuelTypeEnumToJSON(value.extraFuelType),
  };
}
