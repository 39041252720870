import {cargoQuantitySectionDefaultValues} from '../CargoSections/Quantity/Quantity';

interface CargoFormData {
  quantityUnit: string | undefined | null;
}

export const transformCargoFormData = (data: CargoFormData | undefined): CargoFormData | undefined => {
  if (!data) {
    return undefined;
  }
  const newData = {...data};

  // Unfortunate workaround for setting the default value of quantityUnit.
  // Its needed because the old forms with formprovider do not set the default value correctly.
  // https://seanexxt.atlassian.net/browse/FRIN-5241
  if (!newData.quantityUnit) {
    newData.quantityUnit = cargoQuantitySectionDefaultValues.quantityUnit;
  }
  return newData;
};
