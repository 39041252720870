import {ReactNode} from 'react';
import {numberFormat} from '../../../../utils/formatter';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SpeedDraughtHistoryItems} from '../../../../api/symfony/generated';

const speedAccessor =
  (): AccessorFn<SpeedDraughtHistoryItems> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, undefined);
    return (
      <div className={'market-grid-cell'} data-cy="cell-size">
        <div className={'market-grid-cell-content'}>
          {numberFormat(Math.round(vessel.speed), {emptyString: '-'})} knots
        </div>
      </div>
    );
  };

export const speedColumn = ({
  minWidth = 5,
  sortable = true,
}: {
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<SpeedDraughtHistoryItems> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-shipname">
      <div>Speed </div>
    </div>
  ),
  id: 'speed',
  accessorFn: speedAccessor(),
  cell: info => info.getValue(),
  minWidth,
  enableSorting: sortable,
});
