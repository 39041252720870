import {AisVessel} from '../../../../api/symfony/generated';
import {getVesselSizeScalar} from '../../utils/getVesselSizeScalar';
import {AisVesselWithIconAndSize} from './AisVesselWithIconAndSize';

export const addSizeAndIconToVessels = ({
  vessels,
  vesselIconStyle,
  vesselIconSizeMultiplicator,
  vesselIconSizeCapacityRelation,
  constantIconSize,
}: {
  vessels: AisVessel[];
  vesselIconStyle: string;
  vesselIconSizeCapacityRelation: boolean;
  vesselIconSizeMultiplicator: number;
  constantIconSize?: number;
}): AisVesselWithIconAndSize[] => {
  const getIcon = (item: AisVessel) => {
    if (['tanker', 'mpp', 'container', 'bulker'].includes(item.type ?? '')) {
      return vesselIconStyle + '_' + item.type;
    }
    return vesselIconStyle + '_other';
  };

  const getSize = (item: AisVessel): number => {
    if (constantIconSize) {
      return constantIconSize;
    }
    const scalar = !vesselIconSizeCapacityRelation
      ? 1
      : getVesselSizeScalar({
          item,
        });
    return vesselIconSizeMultiplicator * scalar;
  };

  return vessels.map(vessel => {
    const vesselWithIconAndSize: AisVesselWithIconAndSize = {...vessel, icon: getIcon(vessel), size: getSize(vessel)};
    return vesselWithIconAndSize;
  });
};
