import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AisVessel} from '../api/symfony/generated/models';
import {mapApi} from '../api/symfony/symfonyApi';

const QUERY_KEY = 'AIS_VESSELS';

export interface UseAisVesselsQueryParams {
  format?: 'extended';
  updatedAtMaxAge?: string;
  etaMaxAge?: string;
  onStart?: () => void;
}

export const useAisVesselsQuery = (
  params: UseAisVesselsQueryParams = {},
  options?: Omit<UseQueryOptions<AisVessel[]>, 'queryKey'>
) =>
  useQuery({
    queryKey: [QUERY_KEY, params],
    queryFn: async () => {
      params.onStart?.();
      const response = await mapApi.getAisVessels(params);
      const items: AisVessel[] = response.data.items;

      return items;
    },
    ...options,
  });
