/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Coordinate
 */
export interface Coordinate {
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lon?: number;
}

export function CoordinateFromJSON(json: any): Coordinate {
  return CoordinateFromJSONTyped(json, false);
}

export function CoordinateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coordinate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    lat: !exists(json, 'lat') ? undefined : json['lat'],
    lon: !exists(json, 'lon') ? undefined : json['lon'],
  };
}

export function CoordinateToJSON(value?: Coordinate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    lat: value.lat,
    lon: value.lon,
  };
}
