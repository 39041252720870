import numeral from 'numeral';
import {ResultVessel} from '../types';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';

export const dwtColumn: CustomColumnDef<ResultVessel> = {
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-shipname">
      <div>
        Size
        <br />
        (DWT)
      </div>
    </div>
  ),
  id: 'dwt',
  accessorFn: v => {
    return <div>{numeral(v.dwt).format('0,0')} MTS</div>;
  },
  cell: info => info.getValue(),
  minWidth: 12,
  enableSorting: true,
};
