import {useMutation, useQueryClient} from '@tanstack/react-query';
import {portfolioGroupApi} from '../../../api/symfony/symfonyApi';
import {
  CreateSubPortfolioRequest,
  PortfolioGroupDetails,
  PortfolioGroupList,
  PortfolioGroupListDataItems,
  PortfolioGroupStub,
} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';
import produce from 'immer';
import {invalidateGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';

interface CreateSubPortfolioParams {
  portfolioGroupStub: PortfolioGroupStub;
}

export const useCreateSubPortfolio = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<PortfolioGroupDetails, ApiError, CreateSubPortfolioRequest>({
    mutationFn: request => portfolioGroupApi.createSubPortfolio(request),
  });

  const createSubPortfolio = async ({portfolioGroupStub}: CreateSubPortfolioParams) => {
    const subPortfoliosQueryKey = ['subPortfolios', portfolioGroupStub.type];
    await queryClient.cancelQueries({
      queryKey: subPortfoliosQueryKey,
    });

    // Calculate our new state so that we can display it immediately
    const portfolioGroupList = queryClient.getQueryData(subPortfoliosQueryKey) as PortfolioGroupList;
    const updatedSubPortfolioGroupList = produce(portfolioGroupList, draftPortfolioGroupList => {
      const subPortfolios: PortfolioGroupListDataItems[] = draftPortfolioGroupList.data.items;
      const temporaryNewSubPortfolio = {...portfolioGroupStub, id: -1, sortOrder: portfolioGroupStub.sortOrder ?? 0};
      subPortfolios.push(temporaryNewSubPortfolio);
    });

    // Optimistically update to the state
    queryClient.setQueryData(subPortfoliosQueryKey, updatedSubPortfolioGroupList);

    // Talk to the API
    const result = await mutation.mutateAsync(
      {portfolioGroupStub},
      {
        onSettled: async () => {
          // Reload
          await invalidateGetSubPortfoliosQuery(queryClient, portfolioGroupStub.type);
        },
      }
    );
    return result;
  };

  return createSubPortfolio;
};
