import sum from 'lodash/sum';
import {VesselType, VesselGridLayoutMode} from '../../redux/Portfolio';

export interface VesselWithVesselType {
  vesselType?: VesselType;
}

type VesselTypeOccurences = Record<VesselType, number>;

export const selectVesselGridLayout = ({
  userSelectedLayoutMode,
  vessels,
}: {
  userSelectedLayoutMode: VesselGridLayoutMode;
  vessels: VesselWithVesselType[] | null;
}): VesselType => {
  const isManualMode = userSelectedLayoutMode !== 'auto';
  if (isManualMode) {
    // Use whatever the user selected - bulker, MPP or container Mode.
    return userSelectedLayoutMode;
  }

  // Automatic mode.

  if (!vessels || vessels.length === 0) {
    return 'container';
  }

  const vesselTypeOccurrences = countVesselTypeOccurences(vessels);
  return selectGridLayoutForVesselTypeOccurences(vesselTypeOccurrences);
};

const countVesselTypeOccurences = (vessels: VesselWithVesselType[]): VesselTypeOccurences => {
  const vesselTypeOccurrences: VesselTypeOccurences = {
    container: 0,
    bulker: 0,
    mpp: 0,
  };
  for (const vessel of vessels) {
    const vesselType: VesselType | undefined = vessel.vesselType;
    if (!vesselType) {
      continue;
    }
    vesselTypeOccurrences[vesselType] += 1;
  }
  return vesselTypeOccurrences;
};

const selectGridLayoutForVesselTypeOccurences = (vesselTypeOccurrences: VesselTypeOccurences): VesselType => {
  const totalVesselCount = sum(Object.values(vesselTypeOccurrences));

  // Is there a single vessel type displayed?

  const vesselTypes = Object.keys(vesselTypeOccurrences) as VesselType[];
  const singleVesselType = vesselTypes.find(vesselType => vesselTypeOccurrences[vesselType] === totalVesselCount);
  if (singleVesselType) {
    return singleVesselType;
  }

  if (vesselTypeOccurrences['mpp'] >= 1) {
    return 'mpp';
  }

  if (vesselTypeOccurrences['container'] >= 1 && vesselTypeOccurrences['bulker'] >= 1) {
    // In a mixed situation, we use 'mpp'.
    return 'mpp';
  }

  return 'container';
};
