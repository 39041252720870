import {FC} from 'react';
import {NegotiationStatus} from '../../../../../api/node-backend/generated';
import styled from 'styled-components';
import {assertUnreachable} from '../../../../../utils/assert';

type CommitAreaHeadlineProps = {
  negotiationStatus: NegotiationStatus;
  isOwnTurn: boolean;
  allTermsSettled: boolean;
  allSubjectsAccepted: boolean;
  allSubjectsSettled: boolean;
  isFirstRecapRound?: boolean;
  canOnlyCancel: boolean;
  isTutorial?: boolean;
};

export const CommitAreaHeadline: FC<CommitAreaHeadlineProps> = ({
  negotiationStatus,
  isOwnTurn,
  allTermsSettled,
  allSubjectsAccepted,
  allSubjectsSettled,
  isFirstRecapRound,
  canOnlyCancel,
  isTutorial,
}) => {
  return (
    <CommitAreaHeadlineContainer data-testid="CommitAreaHeadline">
      <HeadlineText
        negotiationStatus={negotiationStatus}
        isOwnTurn={isOwnTurn}
        allTermsSettled={allTermsSettled}
        allSubjectsAccepted={allSubjectsAccepted}
        allSubjectsSettled={allSubjectsSettled}
        isFirstRecapRound={isFirstRecapRound}
        canOnlyCancel={canOnlyCancel}
        isTutorial={isTutorial}
      />
    </CommitAreaHeadlineContainer>
  );
};

const HeadlineText: FC<{
  negotiationStatus: NegotiationStatus;
  isOwnTurn: boolean;
  allTermsSettled: boolean;
  allSubjectsAccepted: boolean;
  allSubjectsSettled: boolean;
  isFirstRecapRound?: boolean;
  canOnlyCancel?: boolean;
  isTutorial?: boolean;
}> = ({
  negotiationStatus,
  isOwnTurn,
  allTermsSettled,
  allSubjectsAccepted,
  allSubjectsSettled,
  isFirstRecapRound,
  canOnlyCancel,
  isTutorial,
}) => {
  if (isTutorial) {
    return (
      <>
        <h3>Finish tutorial</h3>
        <p>Click below to get back to your current negotiation.</p>
      </>
    );
  }

  if (canOnlyCancel) {
    return (
      <>
        <h3>You have failed a subject</h3>
        <p>You can not edit this negotiation anymore, but you can start a new one.</p>
      </>
    );
  }

  switch (negotiationStatus) {
    case NegotiationStatus.Offer:
      return isOwnTurn ? (
        <>
          <h3>Create your offer</h3>
          <p>Design your contract with a template or single terms above.</p>
        </>
      ) : (
        <>
          <h3>Waiting for counterpart to reply to your offer</h3>
          <p>You will be able to edit once the other party has made their counter.</p>
        </>
      );
    case NegotiationStatus.Ongoing:
      if (!allTermsSettled) {
        return isOwnTurn ? (
          <>
            <h3>Review terms</h3>
            <p>Edit or add terms of your contract.</p>
          </>
        ) : (
          <>
            <h3>Waiting for counterpart to finish round</h3>
            <p>You will be able to edit again once the other party has made their counter.</p>
          </>
        );
      }
      if (!allSubjectsAccepted) {
        return isOwnTurn ? (
          <>
            <h3>Review subjects</h3>
            <p>Accept or reject the subjects of your contract.</p>
          </>
        ) : (
          <>
            <h3>Waiting for counterpart to finish round</h3>
            <p>You will be able to edit again once the other party has made their counter.</p>
          </>
        );
      }
      return (
        <>
          <h3>All terms and subjects have been accepted</h3>
          <p>
            Ready to start the recap. When you hit 'Accept' your counterpart can make final edits for your review or
            directly settle the negotiation
          </p>
        </>
      );
    case NegotiationStatus.Recap:
      if (isFirstRecapRound) {
        return isOwnTurn ? (
          <>
            <h3>Create the recap for your negotiation</h3>
            <p>
              When finished, your counterpart has to review your changes once again. <br />
              You can clear the negotiation as soon as all terms are accepted and all subjects are lifted.
            </p>
          </>
        ) : (
          <>
            <h3>Waiting for counterpart to send recap</h3>
            <p>
              Your counterpart has accepted all terms and subjects. You will be able to review once again and then clear
              the negotiation as soon as all subjects are lifted.
            </p>
          </>
        );
      }
      if (!allTermsSettled) {
        return isOwnTurn ? (
          <>
            <h3>Clear your terms and subjects</h3>
            <p>
              You can clear the negotiation as soon as all terms are accepted and all subjects are lifted. If you want
              to make edits, your counterpart has to review them once more.
            </p>
          </>
        ) : (
          <>
            <h3>Waiting for counterpart to finalize this negotiation</h3>
            <p>
              If your counterpart can not clear the negotiation based on your last recap or makes changes themselves,
              you will be able to review once again and then clear the negotiation as soon as all terms are accepted and
              all subjects are lifted.
            </p>
          </>
        );
      }
      if (!allSubjectsSettled) {
        return isOwnTurn ? (
          <>
            <h3>Lift your subjects</h3>
            <p>Lift all subjects to continue.</p>
          </>
        ) : (
          <>
            <h3>Waiting for counterpart to finish recap</h3>
            <p>
              If your counterpart does not settle the negotiation based on your terms, you will be able to review once
              again and then settle the negotiation yourself if you agree.
            </p>
          </>
        );
      }
      return (
        <>
          <h3>Settle negotiation</h3>
          <p>All terms and subjects have been cleared.</p>
        </>
      );
    case NegotiationStatus.Settled:
      return (
        <>
          <h3>Negotiation is clean</h3>
          <p>Well done. You can now export this negotiation.</p>
        </>
      );
    case NegotiationStatus.Cancelled:
      return (
        <>
          <h3>Negotiation has been cancelled</h3>
          <p>You can not edit this negotiation anymore, but you can start a new one.</p>
        </>
      );
    default:
      assertUnreachable(negotiationStatus);
  }
};

const CommitAreaHeadlineContainer = styled.div`
  width: 66%;
  margin: -10px auto 0;

  h3 {
    margin: 5px 0 1px;
    font-size: var(--font-size-lg);
    font-weight: bold;
  }

  p {
    margin: 0 0 5px;
    font-size: var(--font-size-md);
    color: var(--color-gray-2);
  }
`;
