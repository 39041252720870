import {Button} from 'antd';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import CargoIcon from '../../../atoms/CargoIcon';
import {getCommodityCategoryFormatted} from '../../../components/CharterCalculation/MultiCalc/MultiCalcCargoModal/ProjectCargoes';
import {getCommodityTypeFormatted} from '../../CargoVesselForm/helper';

type CargoDetailHeaderProps = {
  cargo: $TSFixMe;
  showDetailLink?: boolean;
};

export const CargoItemHeader: FC<CargoDetailHeaderProps> = ({cargo, showDetailLink}) => {
  const url = `/cargo/${cargo.id}`;
  const charterer = cargo.chartererName ?? 'N/A';

  return (
    <>
      <ItemTitle>
        <StyledCargoIcon cargoType={cargo.contractType} commodityType={cargo.commodityCategory} small />
        <span>{charterer}</span>
        {showDetailLink && (
          <Link to={url}>
            <DetailsButton type="default">View details</DetailsButton>
          </Link>
        )}
      </ItemTitle>
      <ItemSubTitle>
        <CommodityLabel cargo={cargo} />
      </ItemSubTitle>
    </>
  );
};

const CommodityLabel: FC<{cargo: $TSFixMe}> = ({cargo}) => {
  const commodityCategory = getCommodityCategoryFormatted(cargo) ?? cargo.cargoType ?? 'N/A';
  const commodityType = getCommodityTypeFormatted(cargo);
  const commodityLabel = commodityType ? ` (${commodityType})` : '';
  return (
    <span>
      {commodityCategory}
      {commodityLabel}
    </span>
  );
};

const ItemTitle = styled.h2`
  text-transform: uppercase;
  font-size: var(--font-size-2xl);
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1;

  span {
    vertical-align: middle;
  }
`;

const StyledCargoIcon = styled(CargoIcon)`
  margin: -5px 5px -6px 0;
`;

const DetailsButton = styled(Button)`
  margin-top: 3px;
  margin-left: 10px;
  float: right;
`;

const ItemSubTitle = styled.p`
  text-transform: uppercase;
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
  font-weight: bold;
  margin-bottom: 20px;
`;
