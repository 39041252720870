import dayjs, {Dayjs} from 'dayjs';
import {Step8Vessel} from '../steps/step8';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

type Props = {
  fromDate: Dayjs;
  vessels: Step8Vessel[];
};

export const filterFromDate = ({fromDate, vessels}: Props): Step8Vessel[] => {
  const fromDateString = fromDate.format(DateTimeFormat.IsoDate);

  return vessels.filter(vessel => {
    const vesselArriveDate = dayjs(vessel.estimatedArrivalDate).format(DateTimeFormat.IsoDate);
    return vesselArriveDate >= fromDateString;
  });
};
