import {useQuery} from '@tanstack/react-query';
import {doFirstCalcWithBreakEven} from './doFirstCalcWithBreakEven';
import {MulticalcCargoInput, MulticalcVesselInput, MultiCharterCalculationState} from '../types';
import {fetchChunked} from '../../../../utils/fetchChunked';
import {useStowageFactorsQuery} from '../../../StowageFactorPicker/useStowageFactorsQuery';

type Props = {
  multiCharterCalculationState: MultiCharterCalculationState;
};

const queryKey = 'MULTICALC_INITIAL_CALCULATION';

export const useBuildInitialOneCalcResults = (props: Props) => {
  const stowageFactorsQuery = useStowageFactorsQuery();

  const {multiCharterCalculationState} = props;

  /**
   * triggers when the multiCalculation > projects or cargoes in redux changed, and
   * performs a first calculation (which includes getting data from API)
   */
  const cargoLength = multiCharterCalculationState.cargoes.length;
  const vesselIds: number[] = multiCharterCalculationState.vessels.map(vessel => vessel.vessel.id);
  const cargoIds: number[] = multiCharterCalculationState.cargoes.map(cargoes => cargoes.cargo.id);
  return useQuery({
    queryKey: [queryKey, vesselIds, cargoIds, multiCharterCalculationState.assumptions.distanceCalculator],
    queryFn: async () => {
      const paramsArrary: {
        multiCalcCargoInput: MulticalcCargoInput;
        multiCalcVesselInput: MulticalcVesselInput;
        vesselIndex: number;
        cargoIndex: number;
      }[] = [];
      multiCharterCalculationState.vessels.forEach((multiCalcVesselInput, vesselIndex) => {
        multiCharterCalculationState.cargoes.forEach((multiCalcCargoInput, cargoIndex) => {
          paramsArrary.push({
            multiCalcCargoInput,
            multiCalcVesselInput,
            vesselIndex,
            cargoIndex,
          });
        });
      });

      return await fetchChunked({
        ids: paramsArrary,
        fetchSingle: async ({multiCalcCargoInput, multiCalcVesselInput, vesselIndex, cargoIndex}) => {
          return doFirstCalcWithBreakEven({
            vessel: multiCalcVesselInput.vessel,
            cargo: multiCalcCargoInput.cargo,
            cargoProject: multiCalcCargoInput.project,
            stowageFactors: stowageFactorsQuery.data!,
            vesselProject: multiCalcVesselInput.project,
            id: vesselIndex * cargoLength + cargoIndex,
            assumptions: multiCharterCalculationState.assumptions,
          });
        },
        chunkSize: 10,
      });
      /*

       */
    },

    enabled: stowageFactorsQuery.isSuccess,
  });
};
