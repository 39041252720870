import dayjs, {Dayjs} from 'dayjs';
import {DurationUnitType} from 'dayjs/plugin/duration';
import {assertUnreachable} from '../../../../utils/assert';
import {TimelineEvent, getIsEventMoment, getIsEventFrame} from './types';
import {VesselVoyageTimelineEventType, VesselVoyageTimelineResponse} from '../../../../api/node-backend/generated';
import {VoyageTimelineRange} from './calculateVoyageTimeSeries';

export const initialVoyageTimelineRange: VoyageTimelineRange = [
  dayjs().endOf('day').subtract(6, 'years'),
  dayjs().endOf('day'),
];

export const getEventDate = (event: TimelineEvent | null) => {
  if (!event) {
    return dayjs();
  }
  return getIsEventFrame(event) ? dayjs(event.enterTimestamp) : dayjs(event.timestamp);
};

export const getMinVoyageTimelineDate = (voyageTimelineResponse: VesselVoyageTimelineResponse) => {
  let minDate = dayjs();
  for (const eventType of Object.values(voyageTimelineResponse)) {
    for (const event of eventType) {
      const eventDate = getIsEventFrame(event) ? dayjs(event.enterTimestamp) : dayjs(event.timestamp);
      if (eventDate.isBefore(minDate)) {
        minDate = eventDate;
      }
    }
  }
  return minDate;
};

export const getEventStart = (event: TimelineEvent): Date => {
  if (getIsEventMoment(event)) {
    return event.timestamp;
  }
  if (getIsEventFrame(event)) {
    return event.enterTimestamp;
  }
  assertUnreachable(event);
};

export const getEventEnd = (event?: TimelineEvent): Date | null => {
  if (!event) {
    return null;
  }
  if (getIsEventMoment(event)) {
    return null;
  }
  if (getIsEventFrame(event)) {
    return event.exitTimestamp;
  }
  assertUnreachable(event);
};

export enum TimelineEventColor {
  Port = 'port',
  PortEvent = 'port-event',
  Route = 'route',
  RouteEvent = 'route-event',
  SpecEvent = 'spec-event',
  Outage = 'outage',
}

export const getEventColor = (eventType: VesselVoyageTimelineEventType): TimelineEventColor => {
  switch (eventType) {
    case VesselVoyageTimelineEventType.PortVisit:
      return TimelineEventColor.Port;
    case VesselVoyageTimelineEventType.PassingPortVisit:
    case VesselVoyageTimelineEventType.PierVisit:
    case VesselVoyageTimelineEventType.AnchorageVisit:
    case VesselVoyageTimelineEventType.DryDockVisit:
      return TimelineEventColor.PortEvent;
    case VesselVoyageTimelineEventType.Route:
      return TimelineEventColor.Route;
    case VesselVoyageTimelineEventType.NameChange:
    case VesselVoyageTimelineEventType.FlagChange:
      return TimelineEventColor.SpecEvent;
    case VesselVoyageTimelineEventType.DestinationChange:
    case VesselVoyageTimelineEventType.DraftChange:
    case VesselVoyageTimelineEventType.EtaChange:
    case VesselVoyageTimelineEventType.Drift:
      return TimelineEventColor.RouteEvent;
    case VesselVoyageTimelineEventType.AisOutage:
      return TimelineEventColor.Outage;
  }
};

export const formatDuration = (duration: number, durationUnit: DurationUnitType) => {
  return dayjs.duration(duration, durationUnit).humanize();
};

export const renderEstimationToActualDiff = (estimatedTime?: string | Dayjs, actualTime?: string | Dayjs) => {
  if (!estimatedTime || !actualTime) {
    return 'N/A';
  }
  const diff = dayjs(actualTime).diff(dayjs(estimatedTime));
  return dayjs.duration(diff).humanize();
};

const BALLAST_DRAFT_PERCENTAGE = 0.68;

export const getIsBallastFromDraft = (draft?: number, maxDraft?: number) => {
  if (!draft || !maxDraft) {
    return undefined;
  }
  const draftPercentage = draft / maxDraft;
  return draftPercentage <= BALLAST_DRAFT_PERCENTAGE;
};

export const getIsEventSelected = (event: TimelineEvent, selectedEvent?: TimelineEvent | null): boolean => {
  if (!selectedEvent) {
    return false;
  }

  if ('timestamp' in event && 'timestamp' in selectedEvent) {
    return selectedEvent?.timestamp === event.timestamp;
  }

  if ('enterTimestamp' in event && 'enterTimestamp' in selectedEvent) {
    return (
      selectedEvent?.enterTimestamp === event.enterTimestamp && selectedEvent?.exitTimestamp === event.exitTimestamp
    );
  }

  return false;
};
