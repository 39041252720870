import React, {useState} from 'react';
import {LocationPicker} from './LocationPicker';
import {Location} from '../SearchPicker/Type';
import {LocationTag} from './utils/LocationTag';
import {addLocationToLocations} from './utils/addLocationToLocations';
import {removeLocationFromLocations} from './utils/removeLocationFromLocations';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {TODO} from '../../utils/TODO';

interface LocationsPickerProps {
  onSelect: (locations: Location[]) => void;
  value?: Location[];
  inputSize?: SizeType;
}

export const LocationsPicker = (props: LocationsPickerProps) => {
  const [locations, setLocations] = useState<Location[]>(props.value ?? []);

  const updateLocations = (locations: Array<Location>) => {
    setLocations(locations);
    props.onSelect(locations);
  };

  const addLocation = (location: Location) => updateLocations(addLocationToLocations({location: location, locations}));

  const removeLocation = (location: Location): void =>
    updateLocations(removeLocationFromLocations({location, locations}));

  return (
    <div>
      {locations.map(location => {
        return <LocationTag key={location.id} location={location} onRemoveLocation={() => removeLocation(location)} />;
      })}
      <LocationPicker
        inputSize={props.inputSize}
        optionsFilter={searchHit => {
          // finds location duplicate which is already selected
          return !locations.some(location => {
            return location.type === (searchHit.source as TODO).type && location.id === (searchHit.source as TODO).id;
          });
        }}
        key={locations.length}
        autoFocus={true}
        defaultValue={''}
        onSelect={location => {
          addLocation(location);
        }}
      />
    </div>
  );
};
