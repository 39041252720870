import dayjs from 'dayjs';
import {Attribute} from '../../../../atoms/Attribute/Attribute';
import {getVesselSize} from '../../../../hooks/useGetCompetitors/VesselSizeType';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {MergedVisits} from './useCongestionVisitsWithVesselInformationsQuery';

export const visitAttributes = {
  Start: ({startTimestamp}: MergedVisits) => dayjs(startTimestamp).format(DateTimeFormat.DateTime),
  End: ({endTimestamp}: MergedVisits) => dayjs(endTimestamp).format(DateTimeFormat.DateTime),
  Waiting: ({waitingDuration}: MergedVisits) => waitingDuration + 'h',
  Passage: ({passageDuration}: MergedVisits) => passageDuration + 'h',
  Total: ({visitDuration}: MergedVisits) => visitDuration + 'h',
};
export const vesselBuildAttributes = {
  Type: ({vesselType}: MergedVisits) => vesselType,
  Subtype: ({dwtSummer}: MergedVisits) => (dwtSummer ? getVesselSize(dwtSummer)?.label : undefined),
  Built: ({builtYear}: MergedVisits) => builtYear,
  Country: ({countryFlag}: MergedVisits) => countryFlag,
  Owner: ({managementHeadOwner}: MergedVisits) => managementHeadOwner,
  Builder: ({shipBuilder}: MergedVisits) => shipBuilder,
};
export const vesselSizeAttributes = {
  'DWT Summer': ({dwtSummer}: MergedVisits) => dwtSummer,
  Beam: ({beam}: MergedVisits) => valSuffixOrUndef(beam, ' m'),
  LOA: ({lengthOverall}: MergedVisits) => valSuffixOrUndef(lengthOverall, ' m'),
  Draft: ({draft}: MergedVisits) => valSuffixOrUndef(draft, ' m'),
};

export const mapAttributes = (
  visit: MergedVisits,
  attributes: Record<string, (visit: MergedVisits) => string | number | undefined | null>
) =>
  Object.keys(attributes).map((key, idx) => <Attribute key={idx} tab="m" label={key} value={attributes[key](visit)} />);

export const valSuffixOrUndef = (value: string | number | undefined | null, suffix: string) =>
  value ? value + suffix : undefined;
