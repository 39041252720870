/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyStatisticsRow,
  CompanyStatisticsRowFromJSON,
  CompanyStatisticsRowFromJSONTyped,
  CompanyStatisticsRowToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyStatisticsProjects
 */
export interface CompanyStatisticsProjects {
  /**
   *
   * @type {CompanyStatisticsRow}
   * @memberof CompanyStatisticsProjects
   */
  created: CompanyStatisticsRow;
  /**
   *
   * @type {CompanyStatisticsRow}
   * @memberof CompanyStatisticsProjects
   */
  shortlist: CompanyStatisticsRow;
  /**
   *
   * @type {{ [key: string]: CompanyStatisticsRow; }}
   * @memberof CompanyStatisticsProjects
   */
  history?: {[key: string]: CompanyStatisticsRow};
}

export function CompanyStatisticsProjectsFromJSON(json: any): CompanyStatisticsProjects {
  return CompanyStatisticsProjectsFromJSONTyped(json, false);
}

export function CompanyStatisticsProjectsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyStatisticsProjects {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    created: CompanyStatisticsRowFromJSON(json['created']),
    shortlist: CompanyStatisticsRowFromJSON(json['shortlist']),
    history: !exists(json, 'history') ? undefined : mapValues(json['history'], CompanyStatisticsRowFromJSON),
  };
}

export function CompanyStatisticsProjectsToJSON(value?: CompanyStatisticsProjects | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    created: CompanyStatisticsRowToJSON(value.created),
    shortlist: CompanyStatisticsRowToJSON(value.shortlist),
    history: value.history === undefined ? undefined : mapValues(value.history, CompanyStatisticsRowToJSON),
  };
}
