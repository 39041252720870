import {FC} from 'react';
import {ArrayFilter} from '../../../../atoms/Filter/ArrayFilter';
import Checkbox from '../../../../atoms/Checkbox';
import {NegotiationStatus} from '../../../../api/node-backend/generated';
import {negotiationStatusOrder as statusFilterOptions} from '../../Components/NegotiationSteps';
import {NegotiationStatusBadge} from '../../Components/NegotiationStatusBadge';

type NegotiationStatusFilterProps = {
  values: NegotiationStatus[];
  onChange: (values: NegotiationStatus[]) => void;
};

export const NegotiationStatusFilter: FC<NegotiationStatusFilterProps> = ({values, onChange}) => {
  return (
    <ArrayFilter
      dataCy="negotiation-contract-type"
      dataTestid="negotiation-contract-type"
      title="Status"
      values={values}
      onApply={onChange}
      children={({values, handleChange}) =>
        statusFilterOptions.map((key, index) => (
          <Checkbox
            key={key}
            data-cy={key}
            label={<NegotiationStatusBadge status={key} active />}
            checked={values[key] ?? false}
            onChange={e => handleChange(key, e.target.checked)}
            spMT={index === 0 ? 'zero' : 's'}
          />
        ))
      }
    />
  );
};
