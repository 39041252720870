import dayjs, {Dayjs} from 'dayjs';
import {getEventColor, getIsBallastFromDraft} from './utils';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {interpolate} from '../../../../utils/interpolate';
import {getCSSVariable} from '../../../../utils/getCSSVariable';
import {
  DraftChartPortVisit,
  DraftChartResponse,
  VesselVoyageTimelineEventType,
} from '../../../../api/node-backend/generated';

export type VoyageTimelineRange = [Dayjs | null, Dayjs | null];

export type VoyageTimeSeriesEntryBase = {
  date: Dayjs;
  draft: number;
  color: string;
};

export type VoyageTimeSeriesEntryPortVisit = VoyageTimeSeriesEntryBase & {
  type: VesselVoyageTimelineEventType.PortVisit;
  portVisit: DraftChartPortVisit;
};

export type VoyageTimeSeriesEntryRoute = VoyageTimeSeriesEntryBase & {
  type: VesselVoyageTimelineEventType.Route;
  route: [DraftChartPortVisit, DraftChartPortVisit];
};

export type VoyageTimeSeriesEntry = VoyageTimeSeriesEntryPortVisit | VoyageTimeSeriesEntryRoute;

export const calculateVoyageTimeSeries = (draftChartResponse?: DraftChartResponse): VoyageTimeSeriesEntry[] => {
  if (!draftChartResponse?.portVisits?.length) {
    return [];
  }

  const {portVisits, maxDraft} = draftChartResponse;

  // draftChartResponse can only start with a port visit
  const voyageTimeSeries: VoyageTimeSeriesEntry[] = [];
  for (let i = 0; i < portVisits.length; i++) {
    const {enterTimestamp, exitTimestamp, enterDraft, exitDraft} = portVisits[i];

    // Add days in port
    const daysInStatus = dayjs(exitTimestamp ?? dayjs().endOf('day')).diff(
      dayjs(enterTimestamp).startOf('day'),
      'days'
    );
    for (let j = 0; j <= daysInStatus; j++) {
      const date = dayjs(enterTimestamp).startOf('day').add(j, 'days');
      const draftInterpolated = interpolate(0, daysInStatus + 1, enterDraft, exitDraft ?? enterDraft, j);
      const entry = getTimeSeriesEntryPortVisit(
        date,
        VesselVoyageTimelineEventType.PortVisit,
        draftInterpolated,
        maxDraft,
        portVisits[i]
      );
      voyageTimeSeries.push(entry);
    }

    // skip connecting voyage if port has no exit
    if (!exitTimestamp) {
      continue;
    }

    // add days until next port or current date
    const nextItem = portVisits[i + 1];
    const nextDate = nextItem ? dayjs(nextItem.enterTimestamp).startOf('day') : dayjs().endOf('day');

    const exitTimestampOrTodayDayjs = exitTimestamp ? dayjs(exitTimestamp) : dayjs();
    const daysUntilNext = dayjs(nextDate).startOf('day').diff(exitTimestampOrTodayDayjs.endOf('day'), 'days');

    for (let j = 0; j < daysUntilNext; j++) {
      const date = dayjs(exitTimestamp)
        .startOf('day')
        .add(j + 1, 'days');
      const draftInterpolated = interpolate(0, daysUntilNext, exitDraft!, nextItem?.enterDraft ?? exitDraft!, j);
      const entry = getTimeSeriesEntryRoute(
        date,
        VesselVoyageTimelineEventType.Route,
        draftInterpolated,
        maxDraft,
        portVisits[i],
        nextItem!
      );
      voyageTimeSeries.push(entry);
    }
  }

  return voyageTimeSeries.sort((a, b) => dayjs(a.date, DateTimeFormat.Date).diff(dayjs(b.date, DateTimeFormat.Date)));
};

const getTimeSeriesEntryBase = (
  date: Dayjs,
  type: VesselVoyageTimelineEventType.PortVisit | VesselVoyageTimelineEventType.Route,
  draft: number,
  maxDraft: number
): VoyageTimeSeriesEntryBase => {
  let color;
  if (type !== VesselVoyageTimelineEventType.Route) {
    color = getCSSVariable(`--timeline-color-${getEventColor(type)}`);
  } else {
    const isBallast = getIsBallastFromDraft(draft, maxDraft * 10);
    color = getCSSVariable(`--color-${isBallast ? 'ballast' : 'laden'}`);
  }

  return {
    date,
    draft: isNaN(draft) ? 0 : draft,
    color,
  };
};

const getTimeSeriesEntryPortVisit = (
  date: Dayjs,
  type: VesselVoyageTimelineEventType.PortVisit,
  draft: number,
  maxDraft: number,
  currentPort: DraftChartPortVisit
): VoyageTimeSeriesEntryPortVisit => {
  const baseEntry = getTimeSeriesEntryBase(date, type, draft, maxDraft);
  return {
    ...baseEntry,
    type,
    portVisit: currentPort,
  };
};

const getTimeSeriesEntryRoute = (
  date: Dayjs,
  type: VesselVoyageTimelineEventType.Route,
  draft: number,
  maxDraft: number,
  previousPort: DraftChartPortVisit,
  nextPort: DraftChartPortVisit
): VoyageTimeSeriesEntryRoute => {
  const baseEntry = getTimeSeriesEntryBase(date, type, draft, maxDraft);
  return {
    ...baseEntry,
    type,
    route: [previousPort, nextPort],
  };
};
