/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ConnectedState {
  Strangers = 'strangers',
  Pending = 'pending',
  Received = 'received',
  Friends = 'friends',
}

export function ConnectedStateFromJSON(json: any): ConnectedState {
  return ConnectedStateFromJSONTyped(json, false);
}

export function ConnectedStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectedState {
  return json as ConnectedState;
}

export function ConnectedStateToJSON(value?: ConnectedState | null): any {
  return value as any;
}
