import dayjs from 'dayjs';
import {Vessel} from '../../useMyFleetQuery/useMyFleetQuery';

export const getNextOpen = (vessel: Vessel): string => {
  const nextopenLocation = vessel.stations.find(station => station.type === 'nextopen')?.locations[0];

  if (vessel.nextOpenFrom === null && vessel.nextOpenTo === null) {
    return '';
  }
  return `open: ${vessel.nextOpenFrom ? dayjs(vessel.nextOpenFrom).format('L') : ''} -> ${
    vessel.nextOpenFrom ? dayjs(vessel.nextOpenFrom).format('L') : ''
  } ${nextopenLocation ? '(' + nextopenLocation.name + ')' : ''}`;
};
