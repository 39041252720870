import NumberRangeInput from '../../../../RangeInput/NumberRangeInput';
import {hasError} from '../../../../../utils/helper';
import {filterBranchDefinition} from './SpotifindVesselAttributesDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

export const SpotifindVesselAttributes = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <div className="row bottom-fix">
        <div className="scol-12">
          <NumberRangeInput
            label={'DWT (Summer)'}
            onFromChange={value => handleChange('deadWeightFrom', value)}
            onToChange={value => handleChange('deadWeightTo', value)}
            fromValue={values.deadWeightFrom}
            toValue={values.deadWeightTo}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 999999,
              placeholder: 'min',
              hasError: hasError('deadWeightFrom', validations),
            }}
            toProps={{
              min: 0,
              max: 999999,
              placeholder: 'max',
              hasError: hasError('deadWeightTo', validations),
            }}
          />
          <NumberRangeInput
            label={'TEU'}
            onFromChange={value => handleChange('teuFrom', value)}
            onToChange={value => handleChange('teuTo', value)}
            fromValue={values.teuFrom}
            toValue={values.teuTo}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 999,
              placeholder: 'min',
              hasError: hasError('teuFrom', validations),
            }}
            toProps={{
              min: 0,
              max: 999,
              placeholder: 'max',
              hasError: hasError('teuTo', validations),
            }}
          />
          <NumberRangeInput
            label={'TEU14'}
            onFromChange={value => handleChange('teu14From', value)}
            onToChange={value => handleChange('teu14To', value)}
            fromValue={values.teu14From}
            toValue={values.teu14To}
            allowEqualMinMax
            fromProps={{
              min: 0,
              max: 999,
              placeholder: 'min',
              hasError: hasError('teu14From', validations),
            }}
            toProps={{
              min: 0,
              max: 999,
              placeholder: 'max',
              hasError: hasError('teu14To', validations),
            }}
          />
        </div>
      </div>
    )}
  </FilterButton>
);
