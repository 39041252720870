/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {AreaVisit, AreaVisitFromJSON, AreaVisitFromJSONTyped, AreaVisitToJSON} from './';

/**
 *
 * @export
 * @interface AreaVisitsResponse
 */
export interface AreaVisitsResponse {
  /**
   *
   * @type {Array<AreaVisit>}
   * @memberof AreaVisitsResponse
   */
  areaVisits: Array<AreaVisit>;
  /**
   * Total number of visits.
   * @type {number}
   * @memberof AreaVisitsResponse
   */
  total: number;
}

export function AreaVisitsResponseFromJSON(json: any): AreaVisitsResponse {
  return AreaVisitsResponseFromJSONTyped(json, false);
}

export function AreaVisitsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaVisitsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    areaVisits: (json['areaVisits'] as Array<any>).map(AreaVisitFromJSON),
    total: json['total'],
  };
}

export function AreaVisitsResponseToJSON(value?: AreaVisitsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    areaVisits: (value.areaVisits as Array<any>).map(AreaVisitToJSON),
    total: value.total,
  };
}
