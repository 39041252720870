import {
  MapPortWithMarketCirculars,
  MapTradingAreaWithMarketCirculars,
} from './useExtendPortsAndTradingAreasWithCirculars';
import {useMemo} from 'react';
import {useGetMarketPortClusters} from './PortsLayer/useGetMarketPortClusters';
import {getTradingAreaMarketLayer} from './TradingAreaLayer/getTradingAreaMarketLayer';
import {getPortMarketLayer} from './PortsLayer/getPortMarketLayer';
import {TODO} from '../../../utils/TODO';
import * as GeoJSON from 'geojson';

type Params = {
  currentZoom: number;
  boundingBox: GeoJSON.BBox;
  ports: MapPortWithMarketCirculars[];
  tradingAreas: MapTradingAreaWithMarketCirculars[];
  isShowMarketVessels: boolean;
  isShowMarketCargoes: boolean;
  onClickPortOrPortCluster: (portOrPortCluster: TODO) => void;
};

export const useGetCircularsLayerForCurrentZoomLevel = ({
  currentZoom,
  boundingBox,
  ports,
  tradingAreas,
  isShowMarketVessels,
  isShowMarketCargoes,
  onClickPortOrPortCluster,
}: Params) => {
  const portCluster = useGetMarketPortClusters({ports});

  return useMemo(() => {
    if (currentZoom < 3) {
      return getTradingAreaMarketLayer({
        tradingAreas,
        isShowMarketVessels,
        isShowMarketCargoes,
      });
    } else {
      return getPortMarketLayer({
        currentZoom,
        boundingBox,
        portCluster,
        isShowMarketVessels,
        isShowMarketCargoes,
        onClick: onClickPortOrPortCluster,
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [currentZoom, portCluster, tradingAreas, onClickPortOrPortCluster, boundingBox]);
};
