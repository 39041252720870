import {VesselGridActionMenu} from './VesselGridActionMenu/VesselGridActionMenu';
import {CustomColumnDef} from '../../../components/DataGrid/DataGrid';
import {SeaboVessel} from '../../../api/node-backend/generated';

export const actionsColumn: CustomColumnDef<SeaboVessel> = {
  id: 'vessel.actions',
  header: '',
  enableSorting: false,
  maxWidth: 48,
  className: 'datagrid-td-action',
  accessorFn: d => <VesselGridActionMenu vessel={d} />,
  cell: info => info.getValue(),
};
