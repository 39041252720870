import {ConsumptionTransaction} from '../../../ConsumptionTransaction';
import {prepareForTheMomentOfConsumption} from './prepareForTheMomentOfConsumption';
import {calculateCosts} from './calculateCosts';

/**
 * Returns the cost for a given quantity at the end of the time period, according to the lifo principle
 */
export const lifo = ({
  filteredTransactions,
  quantity,
}: {
  filteredTransactions: ConsumptionTransaction[];
  quantity: number;
}): number => {
  const stack = prepareForTheMomentOfConsumption({filteredTransactions});

  const costs = calculateCosts({storageItems: stack, quantity});

  return costs;
};
