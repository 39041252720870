import {VerifyEditCompanySettings} from '../../../components/Modal/VerifyEditCompany';
import {useSelector} from '../../../redux/react-redux';
import {OnboardingStepWrapper} from '../OnboardingStepWrapper';
import {OnboardingStepProps} from '../useUserOnboarding';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsArea} from '../../../components/SettingsCard/SettingsArea';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';

export const VerifyCompany = (props: OnboardingStepProps) => {
  const company = useSelector(state => state.company.data);

  return (
    <OnboardingStepWrapper
      title="Setup your company"
      subTitle="Please verify your company and set up your market inbox to receive your circulars inside seabo."
      dataTestId="VerifyCompany"
      {...props}>
      <SettingsCard>
        <SettingsCardHeader title={'Verify company data'} />
        <SettingsCardBody>
          <SettingsArea>
            <VerifyEditCompanySettings company={company} onFinished={props.onNext} />
          </SettingsArea>
        </SettingsCardBody>
      </SettingsCard>
    </OnboardingStepWrapper>
  );
};
