import {CompositeLayer, CompositeLayerProps} from '@deck.gl/core/typed';
import {GeoJsonLayer, GeoJsonLayerProps, TextLayer} from '@deck.gl/layers/typed';
import {COLORS, geoJsonLayerPropsDefaults} from '../const';
import {RGBAColor} from '../../../utils/deck.gl/deck.gl-types';
import {RenderTradingAreaLayerProps} from '../MapContext/Types';

type TradingAreaLayerProps = Omit<CompositeLayerProps & GeoJsonLayerProps, 'onClick'> & RenderTradingAreaLayerProps;

export class TradingAreaLayer extends CompositeLayer<TradingAreaLayerProps> {
  static layerName = 'TradingAreaLayer';

  getPickingInfo: CompositeLayer['getPickingInfo'] = ({info, mode, sourceLayer}) => {
    if (info.object && mode === 'hover' && sourceLayer instanceof TextLayer) {
      return {
        ...info,
        object: {
          ...info.object.properties,
          name: info.object.properties.name,
        },
      };
    }

    return info;
  };

  renderLayers() {
    const {
      id,
      data,
      selected,
      extruded,
      wireframe,
      pickable = true,
      filled = false,
      showLabels,
      getFillColor,
      getLineColor,
      fontColorOnLayer,
      getElevation = () => 0,
      onClick,
      onHover,
    } = this.props;

    return [
      new GeoJsonLayer({
        ...geoJsonLayerPropsDefaults,
        id: `${id}-outline`,
        data,
        filled: filled,
        getElevation,
        extruded,
        wireframe,
        onClick,
        onHover,
        getFillColor:
          getFillColor ||
          (d => (selected === d.properties?.id ? COLORS.TA_POLYGON_FILL_SELECTED : COLORS.TA_POLYGON_FILL)),
        getLineColor: getLineColor || (d => (selected === d.properties?.id ? COLORS.TA_LINE_SELECTED : COLORS.TA_LINE)),
        getLineWidth: d => (selected === d.properties?.id ? 2 : 1),
        pickable: pickable,
        updateTriggers: {
          getFillColor: [selected, getFillColor],
          getLineColor: [selected],
          getLineWidth: [selected],
        },
      }),
      new TextLayer({
        id: `${id}-label`,
        data: data.features,
        fontFamily: 'Akkurat, monospace',
        getPosition: d => d.properties.coordinates,
        getPixelOffset: [0, 24],
        getText: d => d.properties.code,
        getColor: fontColorOnLayer ?? [255, 255, 255],
        getSize: 16,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        pickable: pickable,
        getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 10000],
        visible: showLabels,
      }),
      new TextLayer({
        id: `${id}-label-selected`,
        data: data.features,
        fontFamily: 'Akkurat, monospace',
        getPosition: d => d.properties.coordinates,
        getText: d => d.properties.code,
        getColor: d => [...(fontColorOnLayer ?? [255, 255, 255]), d.properties.id === selected ? 255 : 0] as RGBAColor,
        getSize: 16,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        pickable: pickable,
        getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 100000],
        visible: showLabels,
        updateTriggers: {
          getColor: [selected],
        },
      }),
    ];
  }
}

export default TradingAreaLayer;
