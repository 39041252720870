/* eslint-disable camelcase */
import {MapSwitches} from './MapDetails/utils/types';
import {MapControlButton} from './MapContext/Types';

export const defaultMapSwitches: MapSwitches = {
  vessel_on: {state: true, vState: true, vDisabled: false},
  vessel_market: {state: false, vState: false, vDisabled: false},
  vessel_portfolio_sub: {
    '11': false,
    '14': false,
    '15': false,
    '16': false,
    '17': false,
    '18': false,
    '19': false,
    '20': false,
    '54': false,
    '55': false,
    '57': false,
    '59': false,
    '66': false,
    '67': false,
    '70': false,
    '71': false,
    '72': false,
    '75': false,
    '76': false,
    '78': false,
    '79': false,
    '80': false,
    '86': false,
    '91': false,
    '92': false,
    '105': false,
    '106': false,
    '107': false,
    '111': false,
    '128': false,
  },
  vessel_portfolio: {state: true, vState: true, vDisabled: false},
  vessel_ais_sub: {bulker: true, container: true, tanker: false, mpp: false, other: false},
  vessel_ais: {
    state: true,
    vState: true,
    vDisabled: false,
    filters: {
      intakeUnit: 'dwt',
      intake: ['', ''],
      loa: ['', ''],
      beam: ['', ''],
      draftUnit: 'meter',
      draft: ['', ''],
      speed: ['', ''],
    },
  },
  cargo_on: {state: true, vState: true, vDisabled: false},
  cargo_market: {state: false, vState: false, vDisabled: false},
  cargo_portfolio_sub: {
    '27': false,
    '28': false,
    '29': false,
    '40': false,
    '41': false,
    '42': false,
    '43': false,
    '60': false,
    '103': false,
    '132': false,
  },
  cargo_portfolio: {state: true, vState: true, vDisabled: false},
  cargo_contract_type_sub: {vc: false, tct: false, pc: false, bc: false},
  cargo_contract_type: {state: false, vState: false, vDisabled: false},
  cargo_commodities_sub: {
    agricultural: false,
    ash: false,
    chemical: false,
    coal: false,
    concentrate: false,
    feedstock: false,
    fertilizer: false,
    grain: false,
    'iron-ore': false,
    metal: false,
    minerals: false,
    ore: false,
    petcoke: false,
    salt: false,
    scrap: false,
    slag: false,
    'steels-generals': false,
    'stone-aggregate': false,
    sugar: false,
    sulphur: false,
    vehicles: false,
    'forest-products': false,
    other: false,
  },
  cargo_commodities: {state: false, vState: false, vDisabled: false},
  [MapControlButton.PORTS]: {state: false},
  layer_trading_area: {state: false, vState: false, vDisabled: false},
  trading_area_names: {state: false, vState: false, vDisabled: false},
  layer_satellite: {state: false, vState: false, vDisabled: false},
  layer_piracy: {state: false, vState: false, vDisabled: false},
  layer_eca: {state: false, vState: false, vDisabled: false},
  [MapControlButton.VESSELS]: {state: false, vState: false, vDisabled: false},
  [MapControlButton.CARGOES]: {state: false, vState: false, vDisabled: false},
  [MapControlButton.LAYERS]: {state: false, vState: false, vDisabled: false},
} as MapSwitches;

export const getInitialMapSwitches = (initialMapSwitches?: Partial<MapSwitches>): MapSwitches => {
  return {
    ...defaultMapSwitches,
    ...initialMapSwitches,
  };
};
