/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
  /**
   *
   * @type {number}
   * @memberof CompanyUser
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CompanyUser
   */
  userID: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUser
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyUser
   */
  companyPosition: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CompanyUser
   */
  companyVisibility: boolean;
  /**
   *
   * @type {Date}
   * @memberof CompanyUser
   */
  companyJoinTime: Date;
}

export function CompanyUserFromJSON(json: any): CompanyUser {
  return CompanyUserFromJSONTyped(json, false);
}

export function CompanyUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    userID: json['userID'],
    fullName: json['fullName'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    companyPosition: json['companyPosition'],
    companyVisibility: json['companyVisibility'],
    companyJoinTime: new Date(json['companyJoinTime']),
  };
}

export function CompanyUserToJSON(value?: CompanyUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    userID: value.userID,
    fullName: value.fullName,
    firstName: value.firstName,
    lastName: value.lastName,
    companyPosition: value.companyPosition,
    companyVisibility: value.companyVisibility,
    companyJoinTime: value.companyJoinTime.toISOString(),
  };
}
