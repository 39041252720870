import {RuleObject} from 'antd/es/form';
import {isPresent} from './Presence';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AntDValidator = (rule: RuleObject, value: any) => Promise<void>;

export const greaterThan = (limit: number): AntDValidator => {
  const validator = async (_rule: RuleObject, value: number | undefined) => {
    if (isPresent(value) && value! <= 0) {
      throw new Error(`Must be greater than ${limit}`);
    }
  };
  return validator;
};

export const greaterThanOrEqualTo = (limit: number): AntDValidator => {
  const validator = async (_rule: RuleObject, value: number | undefined) => {
    if (isPresent(value) && value! < 0) {
      throw new Error(`Must be greater than or equal to ${limit}`);
    }
  };
  return validator;
};

export const numberBetween = (fieldLabel: string, min: number, max: number) => () => ({
  async validator(_: unknown, value: string | number) {
    if (!value) {
      return;
    }

    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      throw new Error(`${fieldLabel} must be a number`);
    }

    if (numberValue < min) {
      throw new Error(`${fieldLabel} must be >= ${min}`);
    }

    if (numberValue > max) {
      throw new Error(`${fieldLabel} must be <= ${max}`);
    }
  },
});
