import {NegotiationStatus, NegotiationSubjectStatus, NegotiationTermStatus} from '../../../api/node-backend/generated';

export const negotiationColors = [
  'self',
  'partner',
  'azure',
  'gray',
  'light-gray',
  'red',
  'green',
  'yellow',
  'blue',
  'purple',
  'pink',
  'orange',
] as const;

export type NegotiationColor = (typeof negotiationColors)[number];

export type NegotiationColorSet = ReturnType<typeof mapNegotiationColorSet>;

export const mapNegotiationColorSet = (color: NegotiationColor) => {
  switch (color) {
    case 'self':
    case 'partner':
      return {
        base: `color-${color}`,
        background: `color-${color}-bg`,
        border: `color-${color}-border`,
      } as const;
    case 'gray':
      return {
        base: `color-placeholder-dark`,
        background: `color-gray-5`,
        border: `border-color-dark`,
      } as const;
    case 'light-gray':
      return {
        base: `color-placeholder`,
        background: `color-gray-6`,
        border: `border-color`,
      } as const;
    default:
      return {
        base: `color-${color}`,
        background: `color-${color}-bg`,
        border: `color-${color}-light`,
      } as const;
  }
};

export const NegotiationStatusColors: Record<NegotiationStatus, NegotiationColor> = {
  [NegotiationStatus.Offer]: 'light-gray',
  [NegotiationStatus.Ongoing]: 'blue',
  [NegotiationStatus.Recap]: 'orange',
  [NegotiationStatus.Settled]: 'green',
  [NegotiationStatus.Cancelled]: 'red',
} as const;

export const getNegotiationStatusColors = (status: NegotiationStatus): NegotiationColorSet =>
  mapNegotiationColorSet(NegotiationStatusColors[status]);

export const defaultTermColors = mapNegotiationColorSet('self');

export const getTermColors = (
  termStatus = NegotiationTermStatus.Ongoing,
  negotiationStatus?: NegotiationStatus,
  isCountered?: boolean,
  wasAddedInRecap?: boolean
): NegotiationColorSet => {
  if (
    !wasAddedInRecap &&
    termStatus === NegotiationTermStatus.Ongoing &&
    negotiationStatus === NegotiationStatus.Recap
  ) {
    return getNegotiationStatusColors(NegotiationStatus.Recap);
  }

  let baseColor: NegotiationColor = 'self';
  if (termStatus === NegotiationTermStatus.Accepted) {
    baseColor = 'green';
  } else if (termStatus === NegotiationTermStatus.RejectionRequested) {
    baseColor = 'red';
  } else if (termStatus === NegotiationTermStatus.Rejected) {
    baseColor = 'green';
  } else if (isCountered) {
    baseColor = 'partner';
  }

  return mapNegotiationColorSet(baseColor);
};

export const getSubjectColors = (
  subjectStatus = NegotiationSubjectStatus.Ongoing,
  isCountered?: boolean
): NegotiationColorSet => {
  let baseColor: NegotiationColor = 'self';
  if (subjectStatus === NegotiationSubjectStatus.Lifted) {
    baseColor = 'green';
  } else if (subjectStatus === NegotiationSubjectStatus.Failed) {
    baseColor = 'red';
  } else if (subjectStatus === NegotiationSubjectStatus.Accepted) {
    baseColor = 'yellow';
  } else if (subjectStatus === NegotiationSubjectStatus.RejectionRequested) {
    baseColor = 'red';
  } else if (subjectStatus === NegotiationSubjectStatus.Rejected) {
    baseColor = 'green';
  } else if (isCountered) {
    baseColor = 'partner';
  }

  return mapNegotiationColorSet(baseColor);
};
