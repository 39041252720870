import {FeatureCollection} from 'geojson';
import {Coordinates} from '../../../../utils/Coordinates';
import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../../api/utils/fetchJSON';
import {PortKey} from './usePortCongestionQuery';
import {RGBAColor} from '../../../../utils/deck.gl/deck.gl-types';

export const RED: RGBAColor = [125, 0, 0, 0.4 * 256];
export const GREEN: RGBAColor = [0, 125, 0, 0.4 * 256];

export type PortMetadata = Record<
  PortKey,
  {
    title: string;
    centerPoint?: Coordinates;
    zoom?: number;
  }
>;

export const portMetadata: PortMetadata = {
  [PortKey.NewYork]: {
    title: 'New York',
    centerPoint: [-73.9, 40.7],
    zoom: 7,
  },
  [PortKey.LosAngeles]: {
    title: 'Los Angeles',
    centerPoint: [-118.6919134, 33.5201613],
    zoom: 7.5,
  },
  [PortKey.Shanghai]: {
    title: 'Shanghai',
    centerPoint: [121.4011452, 31.2503632],
    zoom: 6,
  },
  [PortKey.PortHedland]: {
    title: 'Port Hedland',
    centerPoint: [118.5946902, -20.336272],
    zoom: 7,
  },
  [PortKey.Rotterdam]: {
    title: 'Rotterdam',
    centerPoint: [4.4207889, 51.9279653],
    zoom: 6.5,
  },
  [PortKey.Santos]: {
    title: 'Santos',
    centerPoint: [-46.4207889, -23.9279653],
    zoom: 8.5,
  },
  [PortKey.Konstanza]: {
    title: 'Konstanza',
    centerPoint: [29.0207889, 44.2279653],
    zoom: 8.5,
  },
};

export const useChokepointPolygonQuery = () =>
  useQuery({
    queryKey: ['chokepointPolygons'],
    queryFn: async () => {
      return await fetchJSON<Record<PortKey, FeatureCollection>>('/static/jsons/chokepointPolygons.json');
    },
  });
