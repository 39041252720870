import styled, {css} from 'styled-components';
import Title from '../Title';
import {TODO} from '../../utils/TODO';
import {FC} from 'react';

export const StyledDetailsSection = styled.div<{
  hasBorderBottom: boolean;
  vertical: boolean;
  noPadding: boolean;
  required: boolean;
  center: boolean;
}>`
  padding: 30px;
  background-color: var(--color-white);

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    padding: 30px;
  }

  ${({hasBorderBottom}) =>
    hasBorderBottom &&
    css`
      border-bottom: var(--border-base);
    `}

  ${({vertical}) =>
    vertical &&
    css`
      flex-direction: column;
    `}

    ${({noPadding}) =>
    noPadding &&
    css`
      padding: 0;
    `}

    ${({center}) =>
    center &&
    css`
      justify-content: center !important;
    `}

    ${({required}) =>
    required &&
    css`
      .title {
        &:after {
          content: '*';
          color: var(--color-red);
          margin-left: 5px;
        }
      }
    `}
`;

export const DetailSectionFirstBlock = styled.div`
  font-size: var(--font-size-lg);
  color: var(--color-blue-bg);

  @media (min-width: 768px) {
    flex: 0 0 27%;
  }
`;

export const DetailSectionContent = styled.div<{hasFirstBlock: boolean}>`
  @media (min-width: 768px) {
    flex: 0 0 73%;
    display: flex;
    flex-wrap: wrap;

    ${({hasFirstBlock}) =>
      hasFirstBlock &&
      css`
        flex: auto;
      `}
  }
`;

const DetailsSection: FC<TODO> = ({title, center, children, hasBorderBottom, vertical, noPadding, style, required}) => (
  <StyledDetailsSection
    center={center}
    hasBorderBottom={hasBorderBottom}
    vertical={vertical}
    noPadding={noPadding}
    required={required}
    className="details-section"
    style={style}>
    {title && (
      <DetailSectionFirstBlock className="details-section__first-block">
        <Title Tag="h4">{title}</Title>
      </DetailSectionFirstBlock>
    )}
    <DetailSectionContent hasFirstBlock={Boolean(title)} className="details-section__content">
      {children}
    </DetailSectionContent>
  </StyledDetailsSection>
);

export default DetailsSection;
