import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {NegotiationRoundResult} from '../../../../../../api/node-backend/generated';
import {FIND_ROUND_RESULT_BY_ID_QUERY_KEY} from '../../../utils/useFindRoundResultByIdQuery';
import {negotiationNegotiationTermApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {arrayMove} from '@dnd-kit/sortable';

type UpdateTermSort = {roundResultId: string; termId: string; from: number; to: number};

export const useUpdateSortIndexMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, unknown, UpdateTermSort, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({termId, to}) => {
      const response = await negotiationNegotiationTermApi.updateTermSortIndex({
        termId,
        updateTermSortIndexRequest: {
          sortIndex: to,
        },
      });
      return response;
    },
    // eagerly update the cache to avoid flickering
    onMutate: ({roundResultId, from, to}) => {
      queryClient.setQueryData<NegotiationRoundResult | undefined>(
        FIND_ROUND_RESULT_BY_ID_QUERY_KEY(roundResultId),
        roundResult => {
          if (!roundResult) {
            return;
          }
          const terms = [...roundResult.terms];
          const newTerms = arrayMove(terms, from, to);
          return {
            ...roundResult,
            terms: newTerms.map((term, index) => ({...term, sortIndex: index})),
          };
        }
      );
    },
    ...options,
  });
};
