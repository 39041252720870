import React, {FC} from 'react';
import {Button} from 'antd';
import {ModalFooter} from '../../../../../atoms/BaseModal/ModalFooter';

export type SubmitAction = 'SaveAsNewFixture' | 'SaveAndClose' | 'SaveAndManageNext';

export const SubmitButtons: FC<{
  isEdit: boolean;
  isVesselEdit: boolean;
  gridUpdate: boolean;
  submitting: boolean;
  onSubmitActionUpdate: (submitAction: SubmitAction) => void;
}> = ({isEdit, gridUpdate, submitting, isVesselEdit, onSubmitActionUpdate}) => {
  return (
    <ModalFooter data-cy="SubmitButtons">
      {!isEdit && !gridUpdate && (
        <Button
          onClick={() => {
            onSubmitActionUpdate('SaveAndManageNext');
          }}
          type="default"
          htmlType="submit"
          disabled={submitting}
          data-cy="FixtureSaveAndManageNextButton">
          Save and manage next
        </Button>
      )}
      {isEdit && (
        <Button
          type="default"
          htmlType="submit"
          disabled={submitting}
          data-cy={'FixtureSaveAsNewButton'}
          onClick={() => {
            onSubmitActionUpdate('SaveAsNewFixture');
          }}>
          Save as new fixture
        </Button>
      )}
      <Button
        type="primary"
        htmlType="submit"
        className="uppercase"
        disabled={submitting}
        data-cy={'FixtureSaveAndCloseButton'}
        onClick={() => {
          onSubmitActionUpdate('SaveAndClose');
        }}>
        {isVesselEdit ? 'Save updated fixture' : 'Save and close'}
      </Button>
    </ModalFooter>
  );
};
