import {FC, memo} from 'react';
import Icon from '../../../atoms/Icon';
import styled, {css} from 'styled-components';
import {LAYERS} from '../const';
import {MapDetailVessel} from './Components/Views/MapDetailVessel/MapDetailVessel';
import {
  MapDetailPort,
  MapDetailPortless,
  MapDetailTradingArea,
} from './Components/Views/MapDetailPortArea/MapDetailPortArea';
import {MapDetailsPromotionWrapper} from './MapDetailsPromotionWrapper';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {setMapDetailPortPreviewItem, setMapDetailElement} from '../../../redux/MapDetails';
import {MapDetailsClose} from './Components/shared-components';
import {OnMapElementChange} from './utils/utils';
import {SelectedItems} from '../MapContext/Types';

export type MapDetailsProps = {
  selectedItems: SelectedItems;
  onMapElementChange?: OnMapElementChange;
};

export const MapDetails: FC<MapDetailsProps> = memo(({selectedItems, onMapElementChange}) => {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(setMapDetailElement(null));
    dispatch(setMapDetailPortPreviewItem(null, null));
  };

  return (
    <MapDetailsContainer $isFullView={!selectedItems.items.length}>
      <MapDetailsClose onClick={close}>
        <Icon type={'clear'} />
      </MapDetailsClose>
      <MapDetailsPromotionWrapper>
        <MapDetailsItem onMapElementChange={onMapElementChange} />
      </MapDetailsPromotionWrapper>
      <Arrow />
    </MapDetailsContainer>
  );
});

const MapDetailsItem: FC<{onMapElementChange?: OnMapElementChange}> = ({onMapElementChange}) => {
  const {layerId} = useSelector(({mapDetails}) => mapDetails);

  switch (layerId) {
    case LAYERS.AIS:
      return <MapDetailVessel onPortLocationClicked={onMapElementChange} />;
    case LAYERS.PORT:
      return <MapDetailPort onVesselLocationClicked={onMapElementChange} />;
    case LAYERS.PORTLESS:
      return <MapDetailPortless onVesselLocationClicked={onMapElementChange} />;
    case LAYERS.TRADING_AREA:
      return <MapDetailTradingArea onVesselLocationClicked={onMapElementChange} />;

    default:
      return null;
  }
};

const MapDetailsContainer = styled.div<{$isFullView?: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 360px;
  ${({$isFullView}) =>
    $isFullView
      ? css`
          width: 50%;
          min-width: 680px;
          max-width: 900px;
          border-radius: 0 var(--border-radius-card) var(--border-radius-card) 0;
        `
      : css`
          width: 320px;
          border-radius: 0;
        `};
  background-color: var(--color-white);
  z-index: 30;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  left: -39px;
  width: 0;
  border: 20px solid transparent;
  border-right-color: var(--color-white);
`;
