/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VesselVoyageTimelineEventType {
  NameChange = 'NameChange',
  FlagChange = 'FlagChange',
  DestinationChange = 'DestinationChange',
  DraftChange = 'DraftChange',
  EtaChange = 'EtaChange',
  AisOutage = 'AisOutage',
  Drift = 'Drift',
  PortVisit = 'PortVisit',
  PassingPortVisit = 'PassingPortVisit',
  DryDockVisit = 'DryDockVisit',
  PierVisit = 'PierVisit',
  AnchorageVisit = 'AnchorageVisit',
  Route = 'Route',
}

export function VesselVoyageTimelineEventTypeFromJSON(json: any): VesselVoyageTimelineEventType {
  return VesselVoyageTimelineEventTypeFromJSONTyped(json, false);
}

export function VesselVoyageTimelineEventTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselVoyageTimelineEventType {
  return json as VesselVoyageTimelineEventType;
}

export function VesselVoyageTimelineEventTypeToJSON(value?: VesselVoyageTimelineEventType | null): any {
  return value as any;
}
