/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  NegotiationRoundResult,
  NegotiationRoundResultFromJSON,
  NegotiationRoundResultToJSON,
  NewTermsRequest,
  NewTermsRequestFromJSON,
  NewTermsRequestToJSON,
  UpdateTermSortIndexRequest,
  UpdateTermSortIndexRequestFromJSON,
  UpdateTermSortIndexRequestToJSON,
  UpdateTermTextRequest,
  UpdateTermTextRequestFromJSON,
  UpdateTermTextRequestToJSON,
  UpdateTermTypeRequest,
  UpdateTermTypeRequestFromJSON,
  UpdateTermTypeRequestToJSON,
} from '../models';

export interface AcceptTermRequest {
  termId: string;
}

export interface DeleteTermRequest {
  termId: string;
}

export interface NewTermsOperationRequest {
  newTermsRequest: NewTermsRequest;
}

export interface RejectRejectionRequestRequest {
  termId: string;
}

export interface RejectTermRequest {
  termId: string;
}

export interface RequestTermRejectionRequest {
  termId: string;
}

export interface ResetTermRequest {
  termId: string;
}

export interface UpdateTermSortIndexOperationRequest {
  termId: string;
  updateTermSortIndexRequest: UpdateTermSortIndexRequest;
}

export interface UpdateTermTextOperationRequest {
  termId: string;
  updateTermTextRequest: UpdateTermTextRequest;
}

export interface UpdateTermTypeOperationRequest {
  termId: string;
  updateTermTypeRequest: UpdateTermTypeRequest;
}

/**
 *
 */
export class NegotiationNegotiationTermApi extends runtime.BaseAPI {
  /**
   * Accepts a term. Only possible if the negotiation is in \"ongoing\" or \"recap\" state and, the current user is the active party and was not the last party how edit the term. Only possible if the term is not accepted or rejected.
   */
  async acceptTermRaw(requestParameters: AcceptTermRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling acceptTerm.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/accept`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Accepts a term. Only possible if the negotiation is in \"ongoing\" or \"recap\" state and, the current user is the active party and was not the last party how edit the term. Only possible if the term is not accepted or rejected.
   */
  async acceptTerm(requestParameters: AcceptTermRequest): Promise<NegotiationRoundResult> {
    const response = await this.acceptTermRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a term. Only possible if the negotiation is in \"ongoing\" or \"offer\" state, and the current user is the active party, and the term was added this round.
   */
  async deleteTermRaw(requestParameters: DeleteTermRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling deleteTerm.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/delete`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Deletes a term. Only possible if the negotiation is in \"ongoing\" or \"offer\" state, and the current user is the active party, and the term was added this round.
   */
  async deleteTerm(requestParameters: DeleteTermRequest): Promise<NegotiationRoundResult> {
    const response = await this.deleteTermRaw(requestParameters);
    return await response.value();
  }

  /**
   * Creates new Terms. Only possible if the negotiation is in \"ongoing\", \"offer\" or \"recap\" state and the current user is the active party.
   */
  async newTermsRaw(requestParameters: NewTermsOperationRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.newTermsRequest === null || requestParameters.newTermsRequest === undefined) {
      throw new runtime.RequiredError(
        'newTermsRequest',
        'Required parameter requestParameters.newTermsRequest was null or undefined when calling newTerms.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: NewTermsRequestToJSON(requestParameters.newTermsRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Creates new Terms. Only possible if the negotiation is in \"ongoing\", \"offer\" or \"recap\" state and the current user is the active party.
   */
  async newTerms(requestParameters: NewTermsOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.newTermsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Request a term rejection. Only possible if the term is requested for rejection.
   */
  async rejectRejectionRequestRaw(
    requestParameters: RejectRejectionRequestRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling rejectRejectionRequest.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/reject-rejection-request`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Request a term rejection. Only possible if the term is requested for rejection.
   */
  async rejectRejectionRequest(requestParameters: RejectRejectionRequestRequest): Promise<NegotiationRoundResult> {
    const response = await this.rejectRejectionRequestRaw(requestParameters);
    return await response.value();
  }

  /**
   * Accept the rejection request for a term. Only possible if the other party requested a rejection and the current user is the active party. Only possible if the term is not accepted or rejected.
   */
  async rejectTermRaw(requestParameters: RejectTermRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling rejectTerm.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/reject`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Accept the rejection request for a term. Only possible if the other party requested a rejection and the current user is the active party. Only possible if the term is not accepted or rejected.
   */
  async rejectTerm(requestParameters: RejectTermRequest): Promise<NegotiationRoundResult> {
    const response = await this.rejectTermRaw(requestParameters);
    return await response.value();
  }

  /**
   * Request a term rejection. Only possible if the negotiation is in \"ongoing\" or \"recap\" state, and the current user is the active party. Only possible if the term has status ongoing.
   */
  async requestTermRejectionRaw(
    requestParameters: RequestTermRejectionRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling requestTermRejection.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/request-rejection`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Request a term rejection. Only possible if the negotiation is in \"ongoing\" or \"recap\" state, and the current user is the active party. Only possible if the term has status ongoing.
   */
  async requestTermRejection(requestParameters: RequestTermRejectionRequest): Promise<NegotiationRoundResult> {
    const response = await this.requestTermRejectionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Resets a term of the current active round to its origin term. Only possible if the negotiation is in \"ongoing\" or \"recap\" state and the current user is the active party.
   */
  async resetTermRaw(requestParameters: ResetTermRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling resetTerm.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/reset`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Resets a term of the current active round to its origin term. Only possible if the negotiation is in \"ongoing\" or \"recap\" state and the current user is the active party.
   */
  async resetTerm(requestParameters: ResetTermRequest): Promise<NegotiationRoundResult> {
    const response = await this.resetTermRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates the sortIndex of a term. And updates the sortIndex of all following terms.
   */
  async updateTermSortIndexRaw(
    requestParameters: UpdateTermSortIndexOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling updateTermSortIndex.'
      );
    }

    if (
      requestParameters.updateTermSortIndexRequest === null ||
      requestParameters.updateTermSortIndexRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'updateTermSortIndexRequest',
        'Required parameter requestParameters.updateTermSortIndexRequest was null or undefined when calling updateTermSortIndex.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/sort-index`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateTermSortIndexRequestToJSON(requestParameters.updateTermSortIndexRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Updates the sortIndex of a term. And updates the sortIndex of all following terms.
   */
  async updateTermSortIndex(requestParameters: UpdateTermSortIndexOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.updateTermSortIndexRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates the text of a term. The \"lastEditedByQ attribute is set to this Actor. Only possible if the negotiation is in ongoing, recap or offer state and the current user is the active party and the term is not accepted or rejected.
   */
  async updateTermTextRaw(
    requestParameters: UpdateTermTextOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling updateTermText.'
      );
    }

    if (requestParameters.updateTermTextRequest === null || requestParameters.updateTermTextRequest === undefined) {
      throw new runtime.RequiredError(
        'updateTermTextRequest',
        'Required parameter requestParameters.updateTermTextRequest was null or undefined when calling updateTermText.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateTermTextRequestToJSON(requestParameters.updateTermTextRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Updates the text of a term. The \"lastEditedByQ attribute is set to this Actor. Only possible if the negotiation is in ongoing, recap or offer state and the current user is the active party and the term is not accepted or rejected.
   */
  async updateTermText(requestParameters: UpdateTermTextOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.updateTermTextRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateTermTypeRaw(
    requestParameters: UpdateTermTypeOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling updateTermType.'
      );
    }

    if (requestParameters.updateTermTypeRequest === null || requestParameters.updateTermTypeRequest === undefined) {
      throw new runtime.RequiredError(
        'updateTermTypeRequest',
        'Required parameter requestParameters.updateTermTypeRequest was null or undefined when calling updateTermType.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/type`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateTermTypeRequestToJSON(requestParameters.updateTermTypeRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async updateTermType(requestParameters: UpdateTermTypeOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.updateTermTypeRaw(requestParameters);
    return await response.value();
  }
}
