import {StyledButton} from './StyledButton';
import {PlusOutlined} from '@ant-design/icons/lib';
import {useHandleZoom} from '../Hooks/useHandleZoom';

export const ZoomIncreaseButton = () => {
  const {increaseZoomBy, canIncrease} = useHandleZoom();

  return (
    <StyledButton onClick={() => increaseZoomBy(1)} disabled={!canIncrease}>
      <PlusOutlined />
    </StyledButton>
  );
};
