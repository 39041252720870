import {ReactElement} from 'react';
import {FeaturePermission} from './../../../api/symfony/generated/models/FeaturePermission';
import {assert} from '../../../utils/assert';
import {IconType} from '../../Icon';

export type MenuSet = MenuDef[];

export type MenuDef = MenuElement[];

export type MenuElement = MenuItemDef | SubMenuDef;

export interface MenuItemDef {
  type: 'item';
  key: string;
  label: string;
  icon?: IconType | ReactElement;
  iconTestId?: string;
  url: string;
  tourId?: string;
  isBetaFeature?: boolean;
  testId?: string;
  hidden?: boolean;
  hideIfNotPermitted?: boolean;
  requiredPermission?: FeaturePermission;
}

export interface SubMenuDef {
  type: 'subMenu';
  key: string;
  label: string;
  icon?: string | ReactElement;
  iconTestId?: string;
  isBetaFeature?: boolean;
  badge?: string;
  tourId?: string;
  requiredPermission?: FeaturePermission;
  testId?: string;
  hideIfNotPermitted?: boolean;
  items: MenuItemDef[];
}

const getAllMenuElements = (menuSet: MenuSet): MenuElement[] => {
  const flatMenuElements: MenuElement[] = menuSet.flat(2);
  const menuElementsFromSubMenus = flatMenuElements
    .map(element => (element.type === 'subMenu' ? element.items : []))
    .flat();
  return flatMenuElements.concat(menuElementsFromSubMenus);
};

export const findMenuElementByKey = (menuSet: MenuSet, key: string): MenuElement | undefined => {
  const menuElements = getAllMenuElements(menuSet);
  return menuElements.find(element => element.key === key);
};

export const getFirstMenuItemDef = (menuSet: MenuSet, subMenuKey: string): MenuItemDef | undefined => {
  const subMenuDef = findMenuElementByKey(menuSet, subMenuKey);
  if (!subMenuDef) {
    return undefined;
  }
  assert(subMenuDef.type === 'subMenu');
  const menuItemDef = subMenuDef.items[0];
  return menuItemDef;
};

export const getFirstMenuItemKey = (menuSet: MenuSet, subMenuKey: string): string | undefined => {
  const menuItemDef = getFirstMenuItemDef(menuSet, subMenuKey);
  return menuItemDef ? menuItemDef.key : undefined;
};

export const findMenuItemByPath = (menuSet: MenuSet, path: string): MenuElement | undefined => {
  const menuElements = getAllMenuElements(menuSet);
  const menuItems: MenuItemDef[] = menuElements.filter(element => element.type === 'item') as MenuItemDef[];
  const exactMatch = menuItems.find(menuItem => menuItem.url.startsWith(path));
  if (exactMatch) {
    return exactMatch;
  }
  // Maybe there is a subpath match
  const subPathMatch = menuItems.find(menuItem => path.startsWith(menuItem.url));
  return subPathMatch;
};

const findMenuItemInSubMenu = (subMenu: SubMenuDef, key: string): MenuItemDef | undefined =>
  subMenu.items.find(item => item.key === key);

const getAllSubMenusFromMenu = (menu: MenuDef): SubMenuDef[] =>
  menu.filter(menuElement => menuElement.type === 'subMenu') as SubMenuDef[];

const getAllSubMenus = (menuSet: MenuSet): SubMenuDef[] => {
  return menuSet.map(menu => getAllSubMenusFromMenu(menu)).flat(1);
};

export const findSubMenuByMenuItem = (menuSet: MenuSet, key: string): MenuElement | undefined => {
  const subMenus: SubMenuDef[] = getAllSubMenus(menuSet);
  return subMenus.find(subMenu => !!findMenuItemInSubMenu(subMenu, key));
};
