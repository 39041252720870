import {
  NegotiationSubject,
  NegotiationSubjectStatus,
  NegotiationParty,
} from '../../../../../../api/node-backend/generated';
import {useAcceptSubjectMutation} from './useAcceptSubjectMutation';
import {useRequestSubjectRejectionMutation} from './useRequestSubjectRejectionMutation';
import {useResetSubjectMutation} from './useResetSubjectMutation';
import {useFailSubjectMutation} from './useFailSubjectMutation';
import {useUpdateSubjectTextMutation} from './useUpdateSubjectTextMutation';
import {useLiftSubjectMutation} from './useLiftSubjectMutation';
import {useDeleteSubjectMutation} from './useDeleteSubjectMutation';
import {useAcceptSubjectRejectionMutation} from './useAcceptSubjectRejectionMutation';
import {useRejectSubjectRejectionMutation} from './useRejectSubjectRejectionMutation';
import {useInvalidateNegotiationQueries} from '../../../../utils/useInvalidateNegotiationQueries';
import {
  updateSubject,
  updateSubjectModification,
  useEagerUpdateNegotiationQueryData,
} from '../../../../utils/useEagerUpdateNegotiationQueryData';

export type SubjectActionsHook = (subject: NegotiationSubject) => SubjectActions;

export type SubjectActions = {
  updateSubjectText: (newText: string) => void;
  resetSubject: () => void;
  deleteSubject: () => void;
  acceptSubject: () => void;
  requestSubjectRejection: () => void;
  acceptSubjectRejection: () => void;
  rejectSubjectRejection: () => void;
  liftSubject: () => void;
  failSubject: () => void;
  subjectActionsBusy?: boolean;
};

export const subjectActionsNoop = {
  createSubject: () => {},
  updateSubjectText: () => {},
  resetSubject: () => {},
  deleteSubject: () => {},
  acceptSubject: () => {},
  requestSubjectRejection: () => {},
  acceptSubjectRejection: () => {},
  rejectSubjectRejection: () => {},
  liftSubject: () => {},
  failSubject: () => {},
  subjectActionsBusy: false,
};

export const useGetSubjectActionsWithMutations = (
  negotiationId?: string,
  roundResultId?: string,
  party?: NegotiationParty
): {
  getSubjectActions: SubjectActionsHook;
  subjectActionsBusy: boolean;
} => {
  const eagerUpdateQueryData = useEagerUpdateNegotiationQueryData(negotiationId, roundResultId);
  const invalidateNegotiationQueries = useInvalidateNegotiationQueries(negotiationId, roundResultId);

  const updateSubjectTextMutation = useUpdateSubjectTextMutation({
    onMutate: ({subjectId, text}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateSubject({
            roundResult,
            subjectId,
            subjectUpdate: {text},
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: true},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const deleteSubjectMutation = useDeleteSubjectMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult => {
          const subjects = [...roundResult.subjects];
          const subjectIndex = subjects.findIndex(subject => subject.id === subjectId);
          if (subjectIndex !== -1) {
            subjects.splice(subjectIndex, 1);
            return {
              ...roundResult,
              subjects,
            };
          }
          return {
            ...roundResult,
            terms: [...roundResult.terms].map(term => ({
              ...term,
              subjects: term.subjects.filter(subject => subject.id !== subjectId),
            })),
          };
        },
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const acceptSubjectMutation = useAcceptSubjectMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateSubject({
            roundResult,
            subjectId,
            subjectUpdate: {status: NegotiationSubjectStatus.Accepted},
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: true},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const requestSubjectRejectionMutation = useRequestSubjectRejectionMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateSubject({
            roundResult,
            subjectId,
            subjectUpdate: {status: NegotiationSubjectStatus.RejectionRequested},
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: true},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const acceptSubjectRejectionMutation = useAcceptSubjectRejectionMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateSubject({
            roundResult,
            subjectId,
            subjectUpdate: {status: NegotiationSubjectStatus.Rejected},
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: true},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const rejectSubjectRejectionMutation = useRejectSubjectRejectionMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateSubject({
            roundResult,
            subjectId,
            subjectUpdate: {status: NegotiationSubjectStatus.Ongoing},
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: true},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const liftSubjectMutation = useLiftSubjectMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateSubject({
            roundResult,
            subjectId,
            subjectUpdate: {status: NegotiationSubjectStatus.Lifted},
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: true},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const failSubjectMutation = useFailSubjectMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateSubject({
            roundResult,
            subjectId,
            subjectUpdate: {status: NegotiationSubjectStatus.Failed},
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: true},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const resetSubjectMutation = useResetSubjectMutation({
    onMutate: ({subjectId}) => {
      eagerUpdateQueryData({
        roundResultModificationUpdate: roundResultModifications =>
          updateSubjectModification({
            roundResultModifications,
            subjectId,
            subjectModificationUpdate: {changed: false},
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });

  const subjectActionsBusy =
    updateSubjectTextMutation.isPending ||
    resetSubjectMutation.isPending ||
    deleteSubjectMutation.isPending ||
    acceptSubjectMutation.isPending ||
    requestSubjectRejectionMutation.isPending ||
    acceptSubjectRejectionMutation.isPending ||
    rejectSubjectRejectionMutation.isPending ||
    failSubjectMutation.isPending ||
    liftSubjectMutation.isPending;

  if (!negotiationId || !roundResultId || !party) {
    return {
      getSubjectActions: () => subjectActionsNoop,
      subjectActionsBusy,
    };
  }

  const getSubjectActions = (subject: NegotiationSubject) => {
    const subjectId = subject.id;

    const updateSubjectText = (text: string) => updateSubjectTextMutation.mutate({subjectId, text});
    const resetSubject = () => resetSubjectMutation.mutate({subjectId});
    const deleteSubject = () => deleteSubjectMutation.mutate({subjectId});
    const acceptSubject = () => acceptSubjectMutation.mutate({subjectId});
    const requestSubjectRejection = () => requestSubjectRejectionMutation.mutate({subjectId});
    const acceptSubjectRejection = () => acceptSubjectRejectionMutation.mutate({subjectId});
    const rejectSubjectRejection = () => rejectSubjectRejectionMutation.mutate({subjectId});
    const failSubject = () => failSubjectMutation.mutate({subjectId});
    const liftSubject = () => liftSubjectMutation.mutate({subjectId});

    return {
      updateSubjectText,
      resetSubject,
      deleteSubject,
      acceptSubject,
      requestSubjectRejection,
      acceptSubjectRejection,
      rejectSubjectRejection,
      liftSubject,
      failSubject,
      subjectActionsBusy,
    };
  };

  return {
    getSubjectActions,
    subjectActionsBusy,
  };
};
