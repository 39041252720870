/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ConsentParty {
  User = 'USER',
  Company = 'COMPANY',
}

export function ConsentPartyFromJSON(json: any): ConsentParty {
  return ConsentPartyFromJSONTyped(json, false);
}

export function ConsentPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentParty {
  return json as ConsentParty;
}

export function ConsentPartyToJSON(value?: ConsentParty | null): any {
  return value as any;
}
