import {InputState} from '../../utils/voyageChartercalculation/voyageChartercalculation';
import produce from 'immer';
import {getVoyageConsumption} from '../../utils/getVoyageConsumption';

/*
Selects the initial value for the consumptions of the voyage components, if not present
 */
export const addInitialConsumptionToVoyage = (inputState: InputState) =>
  produce(inputState, draftInputState => {
    draftInputState.voyage.points.forEach(voyagePoint => {
      if (!voyagePoint.consumption) {
        voyagePoint.consumption = getVoyageConsumption({
          consumptionModes: inputState.vessel.consumptionModes,
          useCase: 'portidle',
        });
      }
    });

    draftInputState.voyage.routes.forEach((voyageRoute, index) => {
      const prePoint = inputState.voyage.points[index];

      if (!voyageRoute.consumption) {
        voyageRoute.consumption = getVoyageConsumption({
          consumptionModes: inputState.vessel.consumptionModes,
          useCase: prePoint?.types?.includes('loading') ? 'laden' : 'ballast',
        });
      }
    });
  });
