import {FC} from 'react';
import styled from 'styled-components';
import extLink from '../SeaboMap/MapDetails/assets/ext-link.svg';

export const ExtLinkIcon: FC<{alt?: string}> = ({alt}) => <ExtLinkIconImg src={extLink} alt={alt ?? 'Link to Item'} />;

const ExtLinkIconImg = styled.img`
  width: 12px;
  height: 12px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  left: 5px;
`;
