import locationApi from '../../../../redux/ApiService/locationService';
import {DropdownMultiValueLocation, getLocationSelectOption} from '../../../LocationOutput/LocationOutputRow';
import {GroupHeading} from '../../../../atoms/Select/otherComponents';
import {filterBranchDefinition} from './PositionOpenDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {useDispatch} from '../../../../redux/react-redux';
import {AsyncMultiSelect} from '../../../../atoms/Select/AsyncMultiSelect';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const PositionOpen = ({filterProviderApi}: Props) => {
  const dispatch = useDispatch();
  const searchAll = (search: string) => dispatch(locationApi.searchAll(search));

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size={'big'}>
      {({handleChange, values}) => (
        <div className="container">
          <AsyncMultiSelect
            id={'filter-locations'}
            name={'filter-locations'}
            value={values.stations}
            onChange={value => handleChange('stations', [...value.values()])}
            getOptionLabel={o => getLocationSelectOption(o, true)}
            getOptionValue={option => option.id.toString()}
            loadOptions={q => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
            cacheOptions
            defaultOptions
            placeholder="Enter port, country or area"
            components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
          />
        </div>
      )}
    </FilterButton>
  );
};

export default PositionOpen;
