import sortBy from 'lodash/sortBy';
import {QueryClient, keepPreviousData, useQuery} from '@tanstack/react-query';
import {UserWidget} from '../../../api/symfony/generated';
import {widgetApi} from '../../../api/symfony/symfonyApi';
import {AvailableWidget, availableWidgets, isWidgetAvailable} from '../widgets/utils/availableWidgets';

export const GETWIDGETS_QUERY_KEY = 'getWidgets';

export type AvailableWidgetsMap = Record<string, AvailableWidget>;

interface UseWidgetQueryResponse {
  installable: AvailableWidget[];
  installed: UserWidget[];
  available: AvailableWidgetsMap;
}

export const useWidgetsQuery = () => {
  return useQuery<UseWidgetQueryResponse>({
    queryKey: [GETWIDGETS_QUERY_KEY],
    queryFn: async () => {
      const result = await widgetApi.getWidgets();
      // installed widgets always respond with map widget which has to be filtered currently. This should be remove in the backend
      const installedWidgets = result.installed.filter(widget => isWidgetAvailable(widget.identifier));
      const installableWidgets = getInstallableWidgets(installedWidgets);
      return {
        // available widgets depending if  multiple instances are allowed and/or widget is already installed
        installable: installableWidgets,
        // list of all installed widgets
        installed: installedWidgets,
        // static object of all available widgets configurations
        available: availableWidgets,
      };
    },
    placeholderData: keepPreviousData,
  });
};

interface UseWidgetByIdQueryResponse {
  currentWidget: UserWidget | undefined;
}

export const useWidgetByIdQuery = (id: number): UseWidgetByIdQueryResponse => {
  const widgetQuery = useWidgetsQuery();
  const currentWidget = getCurrentWidgetById(widgetQuery.data?.installed || [], id);
  return {
    currentWidget,
  };
};

const getInstallableWidgets = (installedWidgets: UserWidget[]) => {
  const installableWidgets = Object.values(availableWidgets).filter(availableWidget => {
    const allowMultipleInstances = availableWidget.allowMultipleInstances;
    const isInstalled = installedWidgets.some(
      installedWidget => availableWidget.identifier === installedWidget.identifier
    );
    return !isInstalled || allowMultipleInstances;
  });

  return sortBy(installableWidgets, installableWidget => installableWidget.name);
};

const getCurrentWidgetById = (installedWidgets: UserWidget[], id?: number) => {
  return installedWidgets.find(widget => widget.id === id);
};
export const invalidateWidgetQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [GETWIDGETS_QUERY_KEY],
  });
};
