import {numberUnitFormat} from '../../../../utils/formatter';
import {getIsBallastFromDraft} from '../utils/utils';
import {TimelineEvent, TimelineItemVoyage} from '../utils/types';
import {FC} from 'react';
import {DraftChangeIcon, TimelineItemAttributeRow, TimelineItemAttribute, PortLocation} from './shared';
import {TimelineItemContainer} from './TimelineItemContainer';
import Icon from '../../../../atoms/Icon';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {NotAvailable} from '../../../../utils/NotAvailable';
import {PortVisit} from '../../../../api/node-backend/generated';

type VoyageTimelineItemVoyageProps = {
  itemRef: React.RefObject<HTMLLIElement>;
  index?: number;
  item: TimelineItemVoyage;
  maxDraft?: number;
  maxDuration?: number;
  isReduced?: boolean;
  isSelected?: boolean;
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: (id: TimelineEvent | null) => void;
};

export const VoyageTimelineItemVoyage: FC<VoyageTimelineItemVoyageProps> = ({
  itemRef,
  index,
  item,
  maxDuration,
  maxDraft,
  isReduced,
  isSelected,
  selectedEvent,
  onSelectEvent,
}) => {
  const {
    enterTimestamp,
    exitTimestamp,
    route: {eventType, distance, avgSpeed},
    arrivalPortVisit,
    departurePortVisit,
    subEvents,
    isBallast,
  } = item;

  const duration = dayjs(exitTimestamp).diff(enterTimestamp, 'seconds');

  const margin = maxDuration ? Math.floor((duration / maxDuration) * 100) : 0;
  const voyageSubType = getIsBallastFromDraft(arrivalPortVisit?.enterDraft, maxDraft) ? 'ballast' : 'laden';
  let draftChange = 0;
  if (arrivalPortVisit?.enterDraft && arrivalPortVisit?.exitDraft) {
    draftChange = voyageSubType === 'ballast' ? 1 : -1;
  }

  const handleSelect = () => {
    if (onSelectEvent) {
      onSelectEvent(isSelected ? null : item.route);
    }
  };

  return (
    <TimelineItemContainer
      itemRef={itemRef}
      id={`voyage-${index}`}
      data-testid="VoyageTimelineItemVoyage"
      startDate={enterTimestamp}
      endDate={exitTimestamp}
      markerIcon={'vessel-complex'}
      type={eventType}
      isBallast={isBallast}
      isReduced={isReduced}
      isSelected={isSelected}
      description={
        <BallastLadenTag $type={voyageSubType}>
          <DraftChangeIcon draftChange={draftChange} color="white" /> {voyageSubType}
        </BallastLadenTag>
      }
      margin={margin}
      selectedEvent={selectedEvent}
      onSelectEvent={handleSelect}
      onSelectSubEvent={onSelectEvent}
      subEvents={subEvents}
      header={<RouteHeader start={departurePortVisit} end={arrivalPortVisit} distance={distance} avgSpeed={avgSpeed} />}
    />
  );
};

const RouteHeader: FC<{
  start?: PortVisit;
  end?: PortVisit;
  distance: number | null;
  avgSpeed: number | null;
}> = ({start, end, distance, avgSpeed}) => {
  const hasStartPort = !!start?.port;
  const hasEndPort = !!end?.port;

  if (hasStartPort && hasEndPort) {
    return (
      <TimelineItemAttributeRow>
        <TimelineItemAttribute
          data-testid="route"
          tab={'l'}
          label=""
          value={
            <>
              <PortLocation port={end.port} />
              <RouteArrow type="arrow-right" size="small" />
              <PortLocation port={start.port} />
            </>
          }
        />
        <TimelineItemAttribute
          data-testid="sea-voyage"
          tab={'m'}
          label={'Distance'}
          value={(distance ?? 0) > 0 ? numberUnitFormat((distance! * 0.5399568).toFixed(0), 'nm') : NotAvailable}
        />
        <TimelineItemAttribute
          data-testid="average-speed"
          tab={'m'}
          label={'Average speed'}
          value={(avgSpeed ?? 0) > 0 ? numberUnitFormat(avgSpeed!.toFixed(2), 'kn') : NotAvailable}
        />
      </TimelineItemAttributeRow>
    );
  }

  if (hasStartPort) {
    return (
      <TimelineItemAttribute
        data-testid="route"
        tab={'l'}
        label="Ongoing voyage from"
        value={<PortLocation port={start.port} />}
      />
    );
  }

  return <TimelineItemAttribute data-testid="route" tab={'l'} label="Unspecified voyage" value={' '} />;
};

const RouteArrow = styled(Icon)`
  &::before {
    position: relative;
    top: 2px;
    left: -0.5px;
    display: inline-block;
    transform: rotate(-90deg);
    font-size: 18px;
    color: var(--timeline-color-route);
  }
`;

const BallastLadenTag = styled.div<{$type?: 'ballast' | 'laden'}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  margin: 0 -8px 0 auto;
  padding: 2px 8px 2px 5px;
  background: var(
    --color-${({$type}) => {
        if ($type === undefined) {
          return 'blue';
        }
        return $type === 'ballast' ? 'red' : 'green';
      }}
  );
  color: var(--color-white);
  font-size: var(--font-size-xs);
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  border-radius: var(--border-radius-lg);
`;
