import {CSSProperties, ChangeEventHandler, FC, ReactNode} from 'react';
import classNames from 'classnames';
import {cleanIdentifier} from '../../utils/helper';
import {MarginMappingProps, withMarginMapping} from '../../components/withMargin';
import styled from 'styled-components';

interface Props extends MarginMappingProps {
  id?: string;
  label?: string | ReactNode;
  title?: string;
  styleOverride?: CSSProperties;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

export const Checkbox: FC<Props> = ({id, label, styleOverride, title, ...rest}) => {
  const [spaceClasses, props] = withMarginMapping(rest);
  return (
    <StyledCheckBox
      className={classNames('checkbox', spaceClasses)}
      id={cleanIdentifier(id, 'checkboxLabel')}
      title={title}
      style={styleOverride}>
      <Input type="checkbox" id={cleanIdentifier(id, 'checkboxInput')} {...props} />
      <Checker />
      {label && <CheckboxLabelAfter>{label}</CheckboxLabelAfter>}
    </StyledCheckBox>
  );
};

export default Checkbox;

const StyledCheckBox = styled.label`
  color: var(--color-black);
  font-size: var(--font-size-md);
  position: relative;
  display: flex;
  text-align: left;
  align-items: center;
`;

const CheckboxLabelAfter = styled.span`
  line-height: 16px;
  padding-left: 8px;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
`;
const CheckboxLabelBefore = styled.span`
  line-height: 16px;
  margin-right: 8px;
  &:disabled {
    opacity: 0.3;
  }
`;

const Checker = styled.span`
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  background-color: var(--color-gray-6);
  border: var(--border-base);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-inset-input);
  cursor: pointer;

  ${StyledCheckBox}:focus-within & {
    border-color: var(--color-blue);
  }

  &::after {
    opacity: 0;
    transition: opacity 0.15s;
    content: '';
    position: absolute;
    width: 11px;
    height: 5px;
    background: transparent;
    top: 5px;
    left: 3px;
    transform: rotate(-45deg);
    border: 3px solid var(--color-blue);
    border-top: none;
    border-right: none;
    box-sizing: border-box;
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  left: 0;

  &:checked ~ ${Checker}:after {
    opacity: 1;
    transition: opacity 0.15s;
  }

  &:disabled ~ ${CheckboxLabelAfter} ~ ${CheckboxLabelBefore} {
    opacity: 0.3;
  }

  &:disabled ~ ${Checker}:after {
    border-left: 3px solid var(--color-gray-4);
    border-bottom: 3px solid var(--color-gray-4);
  }
`;
