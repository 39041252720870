import {TourDefinition, TourId, getFinalStep} from '../../../components/OnboardingTour/TourDefinition';

const FILTERS_SELECTOR = '[data-debugname="Filters"]';

export const MyFleetTourDefinition: TourDefinition = {
  id: TourId.MyFleet,
  steps: [
    {
      title: 'My Fleet',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/5.%20Onboarding%20My%20Fleet.mp4',
      },
      pdfDownloadUrl:
        "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/06%20My%20Fleet/Let's%20meet%20my%20fleet.pdf",
      placement: 'center',
    },
    {
      title: 'How add a vessel to My Fleet',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/5%20How%20to%20My%20Fleet/5.1%20%20How%20to%20push%20a%20%20vessel%20to%20My%20Fleet.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/06%20My%20Fleet/Add%20a%20vessel%20to%20my%20fleet.pdf',
      placement: 'center',
    },
    {
      title: 'How to add a sub fleet',
      target: FILTERS_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/5%20How%20to%20My%20Fleet/5.2%20How%20to%20add%20a%20sub%20fleet%20(&%20use%20filter).mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/06%20My%20Fleet/Add%20a%20subfleet%20and%20filter.pdf',
      placement: 'bottom',
    },
    {
      title: 'How to see My Fleet vessels on the map',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/5%20How%20to%20My%20Fleet/5.3%20How%20to%20see%20my%20fleet%20vessels%20on%20the%20map.mp4',
      },
      placement: 'center',
    },
    {
      title: 'How to work with vessels in My Fleet (action button)',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/5%20How%20to%20My%20Fleet/5.4%20%20How%20to%20work%20with%20vessels%20in%20my%20fleet%20(Action%20Button).mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/06%20My%20Fleet/Take%20several%20actions%20in%20my%20fleet.pdf',
      placement: 'center',
    },
    getFinalStep('My Fleet'),
  ],
};
