import {
  CreateNegotiationTemplateRequest,
  UpdateNegotiationTemplateRequest,
  DeleteNegotiationTemplateRequest,
} from '../../../../api/node-backend/generated/apis/NegotiationNegotiationTemplateApi';
import {NegotiationTemplate} from '../../../../api/node-backend/generated/models/NegotiationTemplate';
import {useCreateNegotiationTemplateMutation} from './useCreateNegotiationTemplateMutation';
import {useDeleteNegotiationTemplateMutation} from './useDeleteNegotiationTemplateMutation';
import {useInvalidateNegotiationTemplateQueries} from './useInvalidateNegotiationTemplateQueries';
import {useUpdateNegotiationTemplateMutation} from './useUpdateNegotiationTemplateMutation';

export type TemplateActionsHook = () => TemplateActions;

export type TemplateActions = {
  createTemplate: (
    request: CreateNegotiationTemplateRequest,
    onSuccess?: (data: NegotiationTemplate) => void
  ) => Promise<NegotiationTemplate | void>;
  updateTemplate: (
    request: UpdateNegotiationTemplateRequest,
    onSuccess?: (data: NegotiationTemplate) => void
  ) => Promise<NegotiationTemplate | void>;
  deleteTemplate: (request: DeleteNegotiationTemplateRequest) => Promise<void>;
  templateActionsBusy?: boolean;
};

export const templateActionsNoop: TemplateActions = {
  createTemplate: async () => {},
  updateTemplate: async () => {},
  deleteTemplate: async () => {},
  templateActionsBusy: false,
};

export const useGetTemplateActionsWithMutations = () => {
  const createTemplateMutation = useCreateNegotiationTemplateMutation();
  const updateTemplateMutation = useUpdateNegotiationTemplateMutation();
  const deleteTemplateMutation = useDeleteNegotiationTemplateMutation();

  const templateActionsBusy =
    createTemplateMutation.isPending || updateTemplateMutation.isPending || deleteTemplateMutation.isPending;

  const invalidateQueries = useInvalidateNegotiationTemplateQueries();

  const getTemplateActions: TemplateActionsHook = () => {
    const createTemplate = async (
      request: CreateNegotiationTemplateRequest,
      onSuccess?: (data: NegotiationTemplate) => void
    ) => {
      createTemplateMutation.mutate(request, {
        onSuccess: data => {
          onSuccess?.(data);
          void invalidateQueries();
        },
      });
    };
    const updateTemplate = async (
      request: UpdateNegotiationTemplateRequest,
      onSuccess?: (data: NegotiationTemplate) => void
    ) =>
      updateTemplateMutation.mutate(request, {
        onSuccess: data => {
          onSuccess?.(data);
          void invalidateQueries(request.templateId);
        },
      });
    const deleteTemplate = async (request: DeleteNegotiationTemplateRequest) =>
      deleteTemplateMutation.mutate(request, {onSuccess: () => invalidateQueries(request.templateId)});

    return {
      createTemplate,
      updateTemplate,
      deleteTemplate,
      templateActionsBusy,
    };
  };

  return {
    getTemplateActions,
    templateActionsBusy,
  };
};
