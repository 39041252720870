import React, {FC} from 'react';
import {Col, Row} from 'antd';
import {TradingAreaSelectorMap} from './TradingAreaSelectorMap';
import {SegmentCenterContainer} from '../sharedComponents/SegmentCenterContainer';
import {SegmentHeadline} from '../sharedComponents/SegmentHeadline';
import {SegmentSubHeadline} from '../sharedComponents/SegmentSubHeadline';
import {TradingAreaSelectorTags} from './TradingAreaSelectorTags';
import styled from 'styled-components';
import {useDispatch} from '../../../../redux/react-redux';
import {useQuery} from '@tanstack/react-query';
import {getTradingAreas} from '../../../../redux/ApiService/MapService/MapService';
import {Coordinates} from '../../../../utils/Coordinates';
import {Button} from '../../../../atoms/Button/Button';
import {TradingArea} from '../../../../redux/ApiService/MapService/GetTradingAreasResponse';

export const TradingAreaSelector: FC<{
  selectedTradingAreaCodes: string[];
  onSelect: (tradingAreaCodes: string[]) => void;
}> = ({selectedTradingAreaCodes, onSelect}) => {
  const changeItem = (changingAreaCode: string) => {
    const newList = selectedTradingAreaCodes.filter(oldItem => oldItem !== changingAreaCode);
    if (newList.length === selectedTradingAreaCodes.length) {
      newList.push(changingAreaCode);
    }
    onSelect(newList);
  };

  const dispatch = useDispatch();
  const tradingAreasQuery = useQuery({
    queryKey: ['tradingAreas'],
    queryFn: async () => {
      const response = await dispatch(getTradingAreas());
      return response.items as (TradingArea & {polygon: Coordinates[][]})[];
    },
  });

  if (!tradingAreasQuery.isSuccess) {
    return null;
  }

  const allTradingAreaCodes = tradingAreasQuery.data?.map(tradingArea => tradingArea.code) ?? [];
  const allAreSelected = tradingAreasQuery.data?.length === selectedTradingAreaCodes.length;

  const handleAllSelect = () => {
    if (allAreSelected) {
      onSelect([]);
    } else {
      onSelect(allTradingAreaCodes);
    }
  };

  return (
    <SegmentCenterContainer>
      <div>
        <SegmentHeadline>Which trading areas are you interested in?</SegmentHeadline>
      </div>
      <div>
        <SegmentSubHeadline>Select all that apply by choosing on the map or from the tags below</SegmentSubHeadline>
      </div>
      <Row gutter={[0, 8]}>
        <StyledCol span={24}>
          <TradingAreaSelectorMap onChangeOneItem={changeItem} selectedTradingAreaCodes={selectedTradingAreaCodes} />
        </StyledCol>
        <Col span={24}>
          <TradingAreaSelectorTags selectedTradingAreaCodes={selectedTradingAreaCodes} onChangeItem={changeItem} />
        </Col>
      </Row>

      <ButtonContainer>
        <Button
          data-testid={'AllTradingAreasButton'}
          label={allAreSelected ? 'Unselect all' : 'Select all'}
          bold
          onClick={handleAllSelect}
        />
      </ButtonContainer>
    </SegmentCenterContainer>
  );
};

const ButtonContainer = styled.div`
  margin: 16px 10px 48px 10px;
`;

const StyledCol = styled(Col)`
  min-height: 400px;
`;
