import {useQuery} from '@tanstack/react-query';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {PortKey, usePortCongestionQuery} from './usePortCongestionQuery';
import {PortCongestionVisit, SeaboVessel} from '../../../../api/node-backend/generated';
import {getVesselsFromVesselDatabaseByImos} from '../../utils/getVesselsFromVesselDatabaseByImos';
import {getVesselSize} from '../../../../hooks/useGetCompetitors/VesselSizeType';

export type MergedPortCongestionVisit = Omit<SeaboVessel, 'vesselType' | 'color'> &
  PortCongestionVisit & {label: string; color: string};

export interface CongestionVisitsWithVesselInformationsResult {
  finishedVisits: MergedPortCongestionVisit[];
  currentVisits: MergedPortCongestionVisit[];
  queryTimestamp: string;
}

export const useCongestionVisitsWithVesselInformationsQuery = (
  portKey: PortKey,
  options?: UseQueryOptions<CongestionVisitsWithVesselInformationsResult>
) => {
  const congestionQuery = usePortCongestionQuery(portKey);

  return useQuery({
    queryKey: ['useCongestionVisitsWithVesselInformationQuery', portKey],
    queryFn: async () => {
      const congestionAreaStatistics = congestionQuery.data!;

      const allVisitsWithSpireInformation = await extendVisitsWithSpireInformations(congestionAreaStatistics.visits);

      // Split all visits in current and finished visits
      const finishedVisits = allVisitsWithSpireInformation.filter(vessel => !vessel.isOngoing);
      const currentVisits = allVisitsWithSpireInformation.filter(vessel => vessel.isOngoing);
      return {
        finishedVisits,
        currentVisits,
        queryTimestamp: congestionAreaStatistics.queryTimestamp,
      };
    },
    enabled: congestionQuery.isSuccess,
    ...options,
  });
};

const extendVisitsWithSpireInformations = async (
  visits: PortCongestionVisit[]
): Promise<MergedPortCongestionVisit[]> => {
  const allVesselsStatsImos = visits.map(vessel => vessel.imo);

  const allVessels = await getVesselsFromVesselDatabaseByImos(allVesselsStatsImos);

  const allVisitsWithVesselInformation: MergedPortCongestionVisit[] = visits.map(statVessel => {
    const vessel = allVessels.find(vessel => vessel.imo === statVessel.imo);
    const vesselSize = vessel?.dwtSummer ? getVesselSize(vessel.dwtSummer) : undefined;
    const label = vesselSize?.label ?? 'Other';
    const color = vesselSize?.color ?? 'black';
    return {
      ...vessel!,
      ...statVessel,
      label,
      color,
    };
  });

  return allVisitsWithVesselInformation;
};
