import {FC, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Flag from '../../../../atoms/Flags';
import Icon, {IconProps} from '../../../../atoms/Icon';
import {VoyagePort} from '../../../../api/node-backend/generated';

export const DraftChangeIcon: FC<Omit<IconProps, 'type'> & {draftChange: number}> = ({draftChange, ...iconProps}) => {
  let rotation = 0;
  if (draftChange > 0) {
    rotation = -90;
  } else if (draftChange < 0) {
    rotation = 90;
  }
  return <DraftIcon type="arrow-right" $rotation={rotation} {...iconProps} />;
};

const DraftIcon = styled(Icon)<{$rotation: number}>`
  line-height: 1;
  margin: -1px 0 0;
  ${({$rotation}) => $rotation && `transform: rotate(${$rotation}deg);`}
`;

export const PortLocation: FC<{
  port: VoyagePort;
  showSubtitle?: boolean;
}> = ({port, showSubtitle}) => {
  const {id, name, code, countryName, countryCode} = port;
  return (
    <Link to={`/ports/${id}`}>
      <PortTitle>
        <Flag countryCode={countryCode} border />
        <span>
          {name}
          {!showSubtitle && code && ` (${code})`}
        </span>
      </PortTitle>
      {showSubtitle && (countryName || code) && <PortSubtitle>({[countryName, code].join(', ')})</PortSubtitle>}
    </Link>
  );
};

const PortTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
`;

const PortSubtitle = styled.div`
  color: var(--color-gray-2);
`;

export const TimelineItemAttributeRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin: -5px 0;
`;

type TabSize = 'xs' | 's' | 'm' | 'l' | 'block';

type TimelineItemAttributeProps = {
  label: string;
  value?: ReactNode;
  tab?: TabSize;
  showLabel?: boolean;
  'data-testid'?: string;
  onClick?: () => void;
};

export const TimelineItemAttribute: FC<TimelineItemAttributeProps> = ({
  label,
  value,
  tab,
  showLabel = true,
  'data-testid': testId,
  onClick,
}) => (
  <Details data-testid={testId} onClick={onClick} $tab={tab}>
    {showLabel && <Label>{label}</Label>}
    <Value>{value || 'N/A'}</Value>
  </Details>
);

const Details = styled.dl<{$tab?: TabSize}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: ${({$tab}) => {
    switch ($tab) {
      case 'xs':
        return '0 0 80px';
      case 's':
        return '0 0 120px';
      case 'l':
        return '0 0 180px';
      case 'block':
        return '0 1 100%';
      case 'm':
      default:
        return '0 0 150px';
    }
  }};
  margin: 0;
`;

const Label = styled.dd`
  display: block;
  width: 100%;
  margin: 0;
  font-size: var(--font-size-sm);
  line-height: 1.385;
  color: var(--color-gray-2);
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Value = styled.dt`
  display: block;
  width: 100%;
  font-size: var(--font-size-md);
  line-height: 1.385;
  font-weight: bold;
  color: var(--color-black);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
