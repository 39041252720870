import {useEffect, useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';

export const useKeyHandler = () => {
  // If pressed key is our target key then set to true
  const mapContext = useContext(MapContext);

  const hasSelectLayer = mapContext.props.lassoSelectionEnabled ?? true;

  const downHandler = ({key}: {key: string}) => {
    // Activate Select Mode
    if (hasSelectLayer && key === 'Alt') {
      mapContext.setSelectMode(true);
    }
  };

  // If released key is our target key then set to false
  const upHandler = ({key}: {key: string}) => {
    // Deactivate Select Mode
    if (hasSelectLayer && key === 'Alt') {
      mapContext.setSelectMode(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
};
