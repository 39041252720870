/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Reference, ReferenceFromJSON, ReferenceFromJSONTyped, ReferenceToJSON} from './';

/**
 *
 * @export
 * @interface CharterCalculation
 */
export interface CharterCalculation {
  /**
   *
   * @type {number}
   * @memberof CharterCalculation
   */
  id?: number | null;
  /**
   *
   * @type {Date}
   * @memberof CharterCalculation
   */
  created?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CharterCalculation
   */
  updated?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CharterCalculation
   */
  visited?: Date | null;
  /**
   *
   * @type {string}
   * @memberof CharterCalculation
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CharterCalculation
   */
  favorite?: boolean;
  /**
   *
   * @type {Reference}
   * @memberof CharterCalculation
   */
  workspace?: Reference | null;
  /**
   *
   * @type {Reference}
   * @memberof CharterCalculation
   */
  project?: Reference | null;
  /**
   *
   * @type {Reference}
   * @memberof CharterCalculation
   */
  vessel?: Reference | null;
  /**
   *
   * @type {Array<Reference>}
   * @memberof CharterCalculation
   */
  cargoes?: Array<Reference>;
  /**
   *
   * @type {Array<Reference>}
   * @memberof CharterCalculation
   */
  tags?: Array<Reference>;
  /**
   *
   * @type {object}
   * @memberof CharterCalculation
   */
  input: object;
  /**
   *
   * @type {object}
   * @memberof CharterCalculation
   */
  result: object;
}

export function CharterCalculationFromJSON(json: any): CharterCalculation {
  return CharterCalculationFromJSONTyped(json, false);
}

export function CharterCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CharterCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    created: !exists(json, 'created') ? undefined : json['created'] === null ? null : new Date(json['created']),
    updated: !exists(json, 'updated') ? undefined : json['updated'] === null ? null : new Date(json['updated']),
    visited: !exists(json, 'visited') ? undefined : json['visited'] === null ? null : new Date(json['visited']),
    name: json['name'],
    favorite: !exists(json, 'favorite') ? undefined : json['favorite'],
    workspace: !exists(json, 'workspace') ? undefined : ReferenceFromJSON(json['workspace']),
    project: !exists(json, 'project') ? undefined : ReferenceFromJSON(json['project']),
    vessel: !exists(json, 'vessel') ? undefined : ReferenceFromJSON(json['vessel']),
    cargoes: !exists(json, 'cargoes') ? undefined : (json['cargoes'] as Array<any>).map(ReferenceFromJSON),
    tags: !exists(json, 'tags') ? undefined : (json['tags'] as Array<any>).map(ReferenceFromJSON),
    input: json['input'],
    result: json['result'],
  };
}

export function CharterCalculationToJSON(value?: CharterCalculation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created: value.created === undefined ? undefined : value.created === null ? null : value.created.toISOString(),
    updated: value.updated === undefined ? undefined : value.updated === null ? null : value.updated.toISOString(),
    visited: value.visited === undefined ? undefined : value.visited === null ? null : value.visited.toISOString(),
    name: value.name,
    favorite: value.favorite,
    workspace: ReferenceToJSON(value.workspace),
    project: ReferenceToJSON(value.project),
    vessel: ReferenceToJSON(value.vessel),
    cargoes: value.cargoes === undefined ? undefined : (value.cargoes as Array<any>).map(ReferenceToJSON),
    tags: value.tags === undefined ? undefined : (value.tags as Array<any>).map(ReferenceToJSON),
    input: value.input,
    result: value.result,
  };
}
