import React, {FC} from 'react';
import {Tooltip as RTooltip, TooltipProps, Trigger} from 'react-tippy';

import './style.scss';

type OwnTrigger = Trigger | 'mouseenter focus';

type Props = Omit<TooltipProps, 'trigger'> & {trigger?: OwnTrigger; children: React.ReactNode};

/**
 * @deprecated use antd Tooltip instead
 */
const Tooltip: FC<Props> = ({position = 'bottom', title, disabled = false, trigger = 'click focus', ...props}) => {
  return (
    <RTooltip
      title={title}
      position={position}
      disabled={disabled}
      trigger={trigger as Trigger}
      className={'react-tooltip'}
      arrow
      {...props}
    />
  );
};

export default Tooltip;
