/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CommodityCategoryResponse,
  CommodityCategoryResponseFromJSON,
  CommodityCategoryResponseToJSON,
  CommodityResponse,
  CommodityResponseFromJSON,
  CommodityResponseToJSON,
} from '../models';

export interface GetAllCommoditiesRequest {
  includeArchived?: boolean;
}

export interface GetCommoditiesRequest {
  categorySlug: string;
  includeArchived?: boolean;
}

export interface GetCommodityCategoriesRequest {
  includeArchived?: boolean;
}

/**
 *
 */
export class CommodityApi extends runtime.BaseAPI {
  /**
   * Lists all commodities.
   */
  async getAllCommoditiesRaw(
    requestParameters: GetAllCommoditiesRequest
  ): Promise<runtime.ApiResponse<CommodityResponse>> {
    const queryParameters: any = {};

    if (requestParameters.includeArchived !== undefined) {
      queryParameters['includeArchived'] = requestParameters.includeArchived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/contrib/commodity-names`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CommodityResponseFromJSON(jsonValue));
  }

  /**
   * Lists all commodities.
   */
  async getAllCommodities(requestParameters: GetAllCommoditiesRequest): Promise<CommodityResponse> {
    const response = await this.getAllCommoditiesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Lists commodities of a specific category.
   */
  async getCommoditiesRaw(requestParameters: GetCommoditiesRequest): Promise<runtime.ApiResponse<CommodityResponse>> {
    if (requestParameters.categorySlug === null || requestParameters.categorySlug === undefined) {
      throw new runtime.RequiredError(
        'categorySlug',
        'Required parameter requestParameters.categorySlug was null or undefined when calling getCommodities.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.includeArchived !== undefined) {
      queryParameters['includeArchived'] = requestParameters.includeArchived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/contrib/commodity-names/{categorySlug}`.replace(
        `{${'categorySlug'}}`,
        encodeURIComponent(String(requestParameters.categorySlug))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CommodityResponseFromJSON(jsonValue));
  }

  /**
   * Lists commodities of a specific category.
   */
  async getCommodities(requestParameters: GetCommoditiesRequest): Promise<CommodityResponse> {
    const response = await this.getCommoditiesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Lists all commodity categories.
   */
  async getCommodityCategoriesRaw(
    requestParameters: GetCommodityCategoriesRequest
  ): Promise<runtime.ApiResponse<CommodityCategoryResponse>> {
    const queryParameters: any = {};

    if (requestParameters.includeArchived !== undefined) {
      queryParameters['includeArchived'] = requestParameters.includeArchived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/contrib/commodity-categories`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CommodityCategoryResponseFromJSON(jsonValue));
  }

  /**
   * Lists all commodity categories.
   */
  async getCommodityCategories(requestParameters: GetCommodityCategoriesRequest): Promise<CommodityCategoryResponse> {
    const response = await this.getCommodityCategoriesRaw(requestParameters);
    return await response.value();
  }
}
