/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationParty,
  NegotiationPartyFromJSON,
  NegotiationPartyFromJSONTyped,
  NegotiationPartyToJSON,
  NegotiationReplyTime,
  NegotiationReplyTimeFromJSON,
  NegotiationReplyTimeFromJSONTyped,
  NegotiationReplyTimeToJSON,
  NegotiationStatus,
  NegotiationStatusFromJSON,
  NegotiationStatusFromJSONTyped,
  NegotiationStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationRoundResultSummary
 */
export interface NegotiationRoundResultSummary {
  /**
   *
   * @type {string}
   * @memberof NegotiationRoundResultSummary
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof NegotiationRoundResultSummary
   */
  committedAt: Date | null;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationRoundResultSummary
   */
  party: NegotiationParty;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  ongoingTerms: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  acceptedTerms: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  rejectedTerms: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  termsWithRequestedRejection: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  ongoingSubjects: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  liftedSubjects: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  rejectedSubjects: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  failedSubjects: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  subjectsWithRequestedRejection: number;
  /**
   *
   * @type {number}
   * @memberof NegotiationRoundResultSummary
   */
  acceptedSubjects: number;
  /**
   *
   * @type {NegotiationReplyTime}
   * @memberof NegotiationRoundResultSummary
   */
  replyTime: NegotiationReplyTime | null;
  /**
   *
   * @type {NegotiationStatus}
   * @memberof NegotiationRoundResultSummary
   */
  negotiationStatus: NegotiationStatus;
}

export function NegotiationRoundResultSummaryFromJSON(json: any): NegotiationRoundResultSummary {
  return NegotiationRoundResultSummaryFromJSONTyped(json, false);
}

export function NegotiationRoundResultSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationRoundResultSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    committedAt: json['committedAt'],
    party: NegotiationPartyFromJSON(json['party']),
    ongoingTerms: json['ongoingTerms'],
    acceptedTerms: json['acceptedTerms'],
    rejectedTerms: json['rejectedTerms'],
    termsWithRequestedRejection: json['termsWithRequestedRejection'],
    ongoingSubjects: json['ongoingSubjects'],
    liftedSubjects: json['liftedSubjects'],
    rejectedSubjects: json['rejectedSubjects'],
    failedSubjects: json['failedSubjects'],
    subjectsWithRequestedRejection: json['subjectsWithRequestedRejection'],
    acceptedSubjects: json['acceptedSubjects'],
    replyTime: NegotiationReplyTimeFromJSON(json['replyTime']),
    negotiationStatus: NegotiationStatusFromJSON(json['negotiationStatus']),
  };
}

export function NegotiationRoundResultSummaryToJSON(value?: NegotiationRoundResultSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    committedAt: value.committedAt,
    party: NegotiationPartyToJSON(value.party),
    ongoingTerms: value.ongoingTerms,
    acceptedTerms: value.acceptedTerms,
    rejectedTerms: value.rejectedTerms,
    termsWithRequestedRejection: value.termsWithRequestedRejection,
    ongoingSubjects: value.ongoingSubjects,
    liftedSubjects: value.liftedSubjects,
    rejectedSubjects: value.rejectedSubjects,
    failedSubjects: value.failedSubjects,
    subjectsWithRequestedRejection: value.subjectsWithRequestedRejection,
    acceptedSubjects: value.acceptedSubjects,
    replyTime: NegotiationReplyTimeToJSON(value.replyTime),
    negotiationStatus: NegotiationStatusToJSON(value.negotiationStatus),
  };
}
