import {forwardRef} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

type Props = {
  identifier?: string;
  color: 'light' | 'dark' | 'blue-light' | 'blue-dark';
  children: React.ReactNode;
};

const WidgetContainer = forwardRef<HTMLDivElement, Props>(({color, children, identifier}, ref) => {
  return (
    <GridContainer ref={ref}>
      <WidgetWrapper
        className={classNames({[color]: true}, `widget-${identifier}`)}
        data-testid={`${identifier}-widget`}>
        {children}
      </WidgetWrapper>
    </GridContainer>
  );
});

const GridContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  padding: 7.5px;
`;

const WidgetWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  border-radius: var(--border-radius-widget);
  box-shadow: var(--box-shadow-widget);
  transition: box-shadow 0.1s;
  overflow: hidden;

  .react-draggable-dragging & {
    box-shadow: var(--box-shadow-widget-dragging);
  }

  &.light {
    background-color: var(--color-white);
    color: var(--color-black);
  }

  &.dark {
    background-color: var(--color-black);
    color: var(--color-white);
  }

  &.blue-light {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  &.blue-dark {
    background-color: var(--color-azure);
    color: var(--color-white);
  }
`;

export default WidgetContainer;
