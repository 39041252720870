import {useState, FC} from 'react';
import {Icon} from '../../atoms/Icon';
import {getLocationTitle, LocationObject} from '../../components/ProjectDetailsScreen/ProjectDetailItem/helpers';
import {MapPartial} from './MapPartial';
import {
  FlexBox,
  BoxHalf,
  LeadText,
  LocationText,
  FlexBoxVert,
  BoxTop,
  FallbackImage,
  BoxBottom,
} from '../../components/ProjectDetailsScreen/ProjectDetailItem/shared';
import {VesselLocationPartial} from './VesselLocationPartial';
import {WeatherPartial} from './WeatherPartial';
import {
  AisDetails,
  VesselDetailsVessel,
} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

type LocationSectionProps = {
  vessel: VesselDetailsVessel;
};

export const VesselLocationSection: FC<LocationSectionProps> = ({vessel}) => {
  const {aisDetails} = vessel;

  const [mapIsFocused, setMapIsFocused] = useState(false);

  const locationObj = aisDetails ? getVesselLocation(aisDetails) : undefined;
  const locationTitle = getLocationTitle(locationObj);

  const toggleMapIsFocused = () => {
    setMapIsFocused(mapIsFocused => !mapIsFocused);
  };

  return (
    <FlexBox style={{paddingTop: 5, paddingBottom: mapIsFocused ? 10 : 0}}>
      {!mapIsFocused && (
        <BoxHalf>
          <LeadText>Current location</LeadText>
          <LocationText>{locationTitle}</LocationText>
          <VesselLocationPartial vessel={vessel} />
        </BoxHalf>
      )}
      <BoxHalf>
        <FlexBoxVert>
          <BoxTop>
            {locationObj?.lat ? (
              <MapPartial centerPoint={locationObj} aisDetails={aisDetails} toggleMapIsFocused={toggleMapIsFocused} />
            ) : (
              <FallbackImage>
                <Icon type={'no-vessel'} color={'white'} size={'large'} />
              </FallbackImage>
            )}
          </BoxTop>
          {!mapIsFocused && (
            <BoxBottom>{locationObj?.lat && <WeatherPartial weatherCoordinates={locationObj} />}</BoxBottom>
          )}
        </FlexBoxVert>
      </BoxHalf>
    </FlexBox>
  );
};

const getVesselLocation = (aisDetails: AisDetails): LocationObject => {
  const {latitude, longitude, currentPort, currentArea} = aisDetails;

  const locationObj: LocationObject = {
    lat: latitude,
    lon: longitude,
  };

  Object.assign(locationObj, currentPort ?? currentArea ?? {});

  locationObj.name = locationObj.name ?? locationObj.title;
  locationObj.country = locationObj.countryObject?.name;

  return locationObj;
};
