import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import {acceptFloatOrEmpty} from '../../../utils/formatter';
import LabelWrapper from '../../../atoms/LabelWrapper';
import Input from '../../../atoms/Input';
import {numberBetween} from '../../../utils/validators';

const Wrapper = BindToProvider(
  'Commission',
  'commission',
  {
    commissionAddress: '',
    commissionBrokerage: '',
    commissionTotal: '',
  },
  {
    commissionAddress: numberBetween(0, 99.99),
    commissionBrokerage: numberBetween(0, 99.99),
    commissionTotal: numberBetween(0, 100),
  },
  {},
  true
)(WrappedDetailsSection);

const Commission = (props: $TSFixMe) => (
  <Wrapper {...props}>
    {({values, onChange, validations}: $TSFixMe) => {
      const total = (parseFloat(values.commissionAddress) || 0) + (parseFloat(values.commissionBrokerage) || 0);

      return (
        <div className="container-fluid">
          <div className="row">
            <div className="scol-12 scol-md-4">
              <LabelWrapper
                hasError={validations.commissionAddress.invalid}
                label={'Address (%)'}
                htmlFor={'form-commission-address'}>
                <Input
                  id={'form-commission-address'}
                  onChange={v => acceptFloatOrEmpty(v, v => onChange('commissionAddress', v), 2, 2)}
                  value={values.commissionAddress}
                />
              </LabelWrapper>
              {validations.commissionAddress.error && (
                <p className="text-danger text-danger--no-margin">{validations.commissionAddress.error}</p>
              )}
            </div>
            <div className="scol-12 scol-md-4">
              <LabelWrapper
                hasError={validations.commissionBrokerage.invalid}
                label={'Brokerage (%)'}
                htmlFor={'form-commission-brokerage'}>
                <Input
                  id={'form-commission-brokerage'}
                  onChange={v => acceptFloatOrEmpty(v, v => onChange('commissionBrokerage', v), 2, 2)}
                  value={values.commissionBrokerage}
                />
              </LabelWrapper>
              {validations.commissionBrokerage.error && (
                <p className="text-danger text-danger--no-margin">{validations.commissionBrokerage.error}</p>
              )}
            </div>
            <div className="scol-12 scol-md-4">
              <LabelWrapper
                label={'Total (%)'}
                htmlFor={'form-commission-total'}
                hasError={validations.commissionTotal.invalid}>
                <Input id={'form-commission-total'} value={total.toFixed(2)} readOnly={true} />
              </LabelWrapper>
              {validations.commissionTotal.error && (
                <p className="text-danger text-danger--no-margin">Should be smaller than 100</p>
              )}
            </div>
          </div>
        </div>
      );
    }}
  </Wrapper>
);

export default Commission;
