import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import LabelWrapper from '../../../atoms/LabelWrapper';
import Input from '../../../atoms/Input';
import {acceptFloatOrEmpty, acceptIntOrEmpty} from '../../../utils/formatter';
import {floatBetween, numberBetween} from '../../../utils/validators';

const Wrapper = BindToProvider(
  'Dimensions',
  'dimensions',
  {
    lengthOverall: '',
    beam: '',
    draft: '',
    grossTonnage: '',
    netTonnage: '',
    tonnesPerCentimetre: '',
  },
  {
    lengthOverall: floatBetween(0, 99999999),
    beam: floatBetween(0, 99999999),
    draft: floatBetween(0, 99999999),
    grossTonnage: numberBetween(0, 999999),
    netTonnage: numberBetween(0, 999999),
    tonnesPerCentimetre: floatBetween(0, 99999999),
  },
  {
    toApi: (fields: $TSFixMe) => ({
      ...fields,
      lengthOverall: fields.lengthOverall && parseFloat(fields.lengthOverall),
      beam: fields.beam && parseFloat(fields.beam),
      draft: fields.draft && parseFloat(fields.draft),
      grossTonnage: fields.grossTonnage && parseInt(fields.grossTonnage),
      netTonnage: fields.netTonnage && parseInt(fields.netTonnage),
      tonnesPerCentimetre: fields.tonnesPerCentimetre && parseFloat(fields.tonnesPerCentimetre),
    }),
  },
  true
)(WrappedDetailsSection);

const Dimension = (props: $TSFixMe) => (
  <Wrapper {...props}>
    {({onChange, values, validations, validate: _, form}: $TSFixMe) => (
      <div className="container-fluid">
        <div className="row">
          <div className="scol-12 scol-sm-2">
            <LabelWrapper
              label={'LOA (m)'}
              htmlFor={'form-dimensions-length-overall'}
              hasError={validations.lengthOverall.invalid}>
              <Input
                id={'form-dimensions-length-overall'}
                onChange={v => acceptFloatOrEmpty(v, v => onChange('lengthOverall', v), 8, 5)}
                value={values.lengthOverall}
                hasError={validations.lengthOverall.invalid}
              />
            </LabelWrapper>
            {validations.lengthOverall.error && (
              <p className="text-danger text-danger--no-margin">{validations.lengthOverall.error}</p>
            )}
          </div>
          <div className="scol-12 scol-sm-2">
            <LabelWrapper label={'Beam (m)'} htmlFor={'form-dimensions-beam'} hasError={validations.beam.invalid}>
              <Input
                id={'form-dimensions-beam'}
                onChange={v => acceptFloatOrEmpty(v, v => onChange('beam', v), 8, 5)}
                value={values.beam}
                hasError={validations.beam.invalid}
              />
            </LabelWrapper>
            {validations.beam.error && <p className="text-danger text-danger--no-margin">{validations.beam.error}</p>}
          </div>
          <div className="scol-12 scol-sm-2">
            <LabelWrapper label={'Draft (m)'} htmlFor={'form-dimensions-draft'} hasError={validations.draft.invalid}>
              <Input
                id={'form-dimensions-draft'}
                onChange={v => acceptFloatOrEmpty(v, v => onChange('draft', v), 8, 5)}
                value={values.draft}
                hasError={validations.draft.invalid}
              />
            </LabelWrapper>
            {validations.draft.error && <p className="text-danger text-danger--no-margin">{validations.draft.error}</p>}
          </div>
          <div className="scol-12 scol-sm-2">
            <LabelWrapper
              label={'GT (t)'}
              htmlFor={'form-dimensions-gross-tonnage'}
              hasError={validations.grossTonnage.invalid}>
              <Input
                id={'form-dimensions-gross-tonnage'}
                onChange={v => acceptIntOrEmpty(v, v => onChange('grossTonnage', v), 6)}
                value={values.grossTonnage}
                hasError={validations.grossTonnage.invalid}
              />
            </LabelWrapper>
            {validations.grossTonnage.error && (
              <p className="text-danger text-danger--no-margin">{validations.grossTonnage.error}</p>
            )}
          </div>
          <div className="scol-12 scol-sm-2">
            <LabelWrapper
              label={'NT (t)'}
              htmlFor={'form-dimensions-net-tonnage'}
              hasError={validations.netTonnage.invalid}>
              <Input
                id={'form-dimensions-net-tonnage'}
                onChange={v => acceptIntOrEmpty(v, v => onChange('netTonnage', v), 6)}
                value={values.netTonnage}
                hasError={validations.netTonnage.invalid}
              />
            </LabelWrapper>
            {validations.netTonnage.error && (
              <p className="text-danger text-danger--no-margin">{validations.netTonnage.error}</p>
            )}
          </div>
          {form.general && form.general.vesselType !== 'container' && (
            <div className="scol-12 scol-sm-2">
              <LabelWrapper
                label={'TPC (t)'}
                htmlFor={'form-dimensions-tonnes-per-centimetre'}
                hasError={validations.tonnesPerCentimetre.invalid}>
                <Input
                  id={'form-dimensions-tonnes-per-centimetre'}
                  onChange={v => acceptFloatOrEmpty(v, v => onChange('tonnesPerCentimetre', v), 8, 5)}
                  value={values.tonnesPerCentimetre}
                  hasError={validations.tonnesPerCentimetre.invalid}
                />
              </LabelWrapper>
              {validations.tonnesPerCentimetre.error && (
                <p className="text-danger text-danger--no-margin">{validations.tonnesPerCentimetre.error}</p>
              )}
            </div>
          )}
        </div>
      </div>
    )}
  </Wrapper>
);

export default Dimension;
