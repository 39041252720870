import {Row} from 'antd';
import React, {FC} from 'react';
import styled from 'styled-components';
import {SegmentCenterContainer} from './sharedComponents/SegmentCenterContainer';
import {SegmentHeadline} from './sharedComponents/SegmentHeadline';
import {SegmentSubHeadline} from './sharedComponents/SegmentSubHeadline';
import {BulkerContainerSizeSelector} from './SizeSelector/BulkerContainerSizeSelector';
import {Button} from '../../../atoms/Button/Button';
import {availableSizes} from './SizeSelector/availableSizes';

export const SizeSelector: FC<{selectedSizes: string[]; onChange: (sizes: string[]) => void; segment: string[]}> = ({
  selectedSizes,
  onChange,
  segment,
}) => {
  const filteredSegment = segment.filter(seg => seg !== 'mmp');
  const onChangeItem = (key: string) => {
    const newList = selectedSizes.filter(item => item !== key);
    if (newList.length === selectedSizes.length) {
      newList.push(key);
    }
    onChange(newList);
  };

  const hasSelectedElements = selectedSizes.length !== 0;

  const unselectAll = () => {
    onChange([]);
  };

  const selectAll = () => {
    const newList: string[] = [];
    segment.forEach(seg => {
      newList.push(...availableSizes[seg as keyof typeof availableSizes]);
    });
    onChange(newList);
  };

  if (filteredSegment.length === 0) {
    return <CenterContainer>You have to select at least one vessel type to continue</CenterContainer>;
  }

  return (
    <CenterContainer>
      <div>
        <SegmentHeadline>{`What size of ${filteredSegment?.join(' / ')} do you focus on?`}</SegmentHeadline>
      </div>
      <div>
        <SegmentSubHeadline>Select all that apply</SegmentSubHeadline>
      </div>
      <BulkerContainerSizeSelector
        segments={filteredSegment}
        selectedSizes={selectedSizes}
        onChangeItem={onChangeItem}
      />
      <StyledRow gutter={[16, 16]} style={{justifyContent: 'center'}}>
        <ButtonContainer>
          <Button
            label={hasSelectedElements ? 'Unselect all' : 'Select all'}
            bold
            onClick={hasSelectedElements ? unselectAll : selectAll}
          />
        </ButtonContainer>
      </StyledRow>
    </CenterContainer>
  );
};

export const CenterContainer = styled(SegmentCenterContainer)`
  padding: 0 40px;
`;

const StyledRow = styled(Row)`
  padding: 0 40px;
`;

const ButtonContainer = styled.div`
  margin: 16px 10px 48px 10px;
  display: flex;
`;

export const TypeHeadline = styled(SegmentSubHeadline)`
  color: var(--color-gray-1);
  text-align: center;
  margin-bottom: 12px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-gray-3);
`;
