import {IconLayer} from '@deck.gl/layers/typed';
import {useGetIconSizeByZoom} from './useGetIconSizeByZoom';
import {getVesselIcon} from './getVesselIcon';
import {LonLatCoordinates} from '../../../../../../utils/Coordinates';
import {VesselTypeKey} from '../../../../../../components/consts/vesselTypes';

export interface VesselSprite {
  coordinate: LonLatCoordinates;
  angle: number;
}

export const useVesselIconLayer = (
  vesselType: VesselTypeKey,
  zoom: number,
  vesselSprite: VesselSprite,
  onClick: (vesselSprite: VesselSprite) => void
) => {
  const icon = getVesselIcon(vesselType);
  const getIconSizeByZoom = useGetIconSizeByZoom();

  const layer = new IconLayer<VesselSprite>({
    id: 'vessel-icon-layer',
    data: [vesselSprite],
    getPosition: vessel => [...vessel.coordinate, 0],
    // Rotation of the icon in degrees
    // 0 -> north, 90 -> west - not east!
    getAngle: vessel => vessel.angle * -1,
    opacity: 1,
    getIcon: () => icon,
    getSize: () => getIconSizeByZoom(zoom),
    // sizeScale: zoom,
    sizeScale: 5,
    pickable: true,
    updateTriggers: {
      getAngle: [vesselSprite.angle],
      getSize: [zoom],
      getIcon: [icon],
    },
    onClick: info => {
      const vesselSprite: VesselSprite = info.object;
      // eslint-disable-next-line no-console
      console.log('onClick', vesselSprite);
      onClick(vesselSprite);
    },
  });

  return layer;
};
