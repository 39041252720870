import {AppThunk} from './AppThunk';
import remove from 'lodash/remove';
import {RootState} from './store';
import {assert} from '../utils/assert';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface Vessel {
  id: number;
  name: string;
}

export interface Cargo {
  id: number;
  name: string;
}

export interface ClipboardItem {
  id: number;
  name?: string;
}

interface GridClipboardSection {
  vessels: ClipboardItem[];
  checkAllVessels: boolean;
  cargoes: ClipboardItem[];
  checkAllCargoes: boolean;
}

export interface GridClipboardState {
  portfolio: GridClipboardSection;
  market: GridClipboardSection;
}

const initialState: GridClipboardState = {
  portfolio: {
    vessels: [],
    checkAllVessels: false,
    checkAllCargoes: false,
    cargoes: [],
  },
  market: {
    vessels: [],
    checkAllVessels: false,
    checkAllCargoes: false,
    cargoes: [],
  },
};

export type Section = 'portfolio' | 'market';
export type Subsection = 'vessels' | 'cargoes';

interface AddItemPayload {
  section: Section;
  subsection: Subsection;
  item: ClipboardItem;
}

interface RemoveItemPayload {
  section: Section;
  subsection: Subsection;
  item: ClipboardItem;
}

interface RemoveItemsPayload {
  section: Section;
  subsection: Subsection;
  items: Vessel[] | Cargo[];
}

export interface ChangeAllItemsPayload {
  section: Section;
  checkAllVessels?: boolean | null;
  checkAllCargoes?: boolean | null;
}

export interface ChangeAllItemsPayload {
  section: Section;
  subsection: Subsection;
  newValue: boolean;
}

const gridClipboardSlice = createSlice({
  name: 'GridClipboard',
  initialState,
  reducers: {
    resetSelection(state, action: PayloadAction<Section>) {
      const section = action.payload;
      state[section] = initialState[section];
    },
    addItem(state, action: PayloadAction<AddItemPayload>) {
      const payload = action.payload;
      const {section, subsection} = payload;
      state[section][subsection].push(payload.item);
    },
    removeItem(state, action: PayloadAction<RemoveItemPayload>) {
      const payload = action.payload;
      const {section, subsection} = payload;
      remove(state[section][subsection], e => e.id === payload.item.id);
      const checkAllField = getCheckAllFieldForSubsection(subsection);
      state[section][checkAllField] = false;
    },
    removeItems(state, action: PayloadAction<RemoveItemsPayload>) {
      const {section, subsection, items} = action.payload;
      items.forEach(item => {
        remove(state[section][subsection], e => e.id === item.id);
      });
      const checkAllField = getCheckAllFieldForSubsection(subsection);
      state[section][checkAllField] = false;
    },
    changeAllItems(state, action: PayloadAction<ChangeAllItemsPayload>) {
      const {section, subsection, newValue} = action.payload;
      const checkAllField = getCheckAllFieldForSubsection(subsection);
      state[section][checkAllField] = newValue;
    },
  },
});

const getCheckAllFieldForSubsection = (subsection: Subsection): 'checkAllVessels' | 'checkAllCargoes' =>
  subsection === 'vessels' ? 'checkAllVessels' : 'checkAllCargoes';

/**
 * Adds all items in the grid to the clipboard.
 */
const addAllGridItemsToClipboard =
  (subsection: Subsection, section: Section): AppThunk =>
  (dispatch, getState) => {
    assert(
      subsection === 'vessels' || subsection === 'cargoes',
      `addAllItemsToClipboard: Unexpected subsection ${subsection}`
    );

    const allItems = getAllItemsInTheGrid(subsection, section, getState());
    dispatch(addAllItemsToClipboard(subsection, section, allItems));
  };

/**
 * Adds the given items to the clipboard and marks them as 'all' items.
 */
const addAllItemsToClipboard =
  (subsection: Subsection, section: Section, itemsToAdd: Vessel[] | Cargo[]): AppThunk =>
  (dispatch, getState) => {
    assert(
      subsection === 'vessels' || subsection === 'cargoes',
      `addAllItemsToClipboard: Unexpected type ${subsection}`
    );

    // Change the 'All' checkbox
    dispatch(GridClipboardActions.changeAllItems({subsection: subsection, section, newValue: true}));

    // Add all items not in the clipboard
    const allItems = itemsToAdd;
    const itemsInClipboard = getState().gridClipboard[section][subsection] ?? [];
    const itemsNotInClipboard = allItems.filter(a => !itemsInClipboard.find(c => c.id === a.id));

    itemsNotInClipboard.forEach(item => {
      dispatch(
        GridClipboardActions.addItem({
          section,
          subsection: subsection,
          item: {
            id: item.id,
            name: item.name,
          },
        })
      );
    });
  };

const getAllItemsInTheGrid = (subsection: Subsection, section: Section, rootState: RootState): Vessel[] | Cargo[] => {
  switch (section) {
    case 'market': {
      const marketType = subsection === 'vessels' ? 'vessel' : 'cargo';
      const items = rootState.api.market[marketType].data.data.items;
      return items;
    }

    case 'portfolio': {
      const items = rootState.portfolio.screenState[subsection]!;
      return items as Cargo[] | Vessel[];
    }

    default:
      throw new Error(`Unknown section ${section}`);
  }
};

/**
 * Removes all clipboard items from the clipboard and clears the 'all' flag.
 */
const removeAllItemsFromClipboard =
  (subsection: Subsection, section: Section): AppThunk =>
  (dispatch, getState) => {
    if (subsection !== 'vessels' && subsection !== 'cargoes') {
      throw new Error(`removeAllItemsFromClipboard: Unexpected subsection ${subsection}`);
    }

    const allItems = getState().gridClipboard[section][subsection] as Vessel[] | Cargo[];
    dispatch(gridClipboardSlice.actions.removeItems({subsection, section, items: allItems}));
  };

export const GridClipboardActions = {
  ...gridClipboardSlice.actions,
  removeAllItemsFromClipboard,
  addAllItemsToClipboard,
  addAllGridItemsToClipboard,
};

export const gridClipboardReducer = gridClipboardSlice.reducer;
