import {LonLatCoordinates} from '../../../../../utils/Coordinates';
import {interpolate} from './interpolate';

export const interpolateGeoCoordinates = (
  start: LonLatCoordinates,
  end: LonLatCoordinates,
  percentage: number
): LonLatCoordinates => {
  const newLongitude = interpolate(start[0], end[0], percentage);
  const newLatitude = interpolate(start[1], end[1], percentage);
  return [newLongitude, newLatitude];
};
