import {OneCalcResult} from './oneCalc';

type Params = {
  oneCalc: OneCalcResult;
  profitTarget?: number;
  tceTarget?: number;
};

/**
 * calculates the freight idea where the voyage reach the break-evan, (TCE is 0) and (revenueTotal is 0)
 * @param params
 */
export const calcBreakEvenFreightIdea = (params: Params): number => {
  /**
   * derivation
   *
   *given:
   *  Q = Quantity
   *  K = fix costs (costs without commisson)
   *  C = Commisson
   *  U = sales alias revenueNet
   *  G = revenue alias revenueTotal
   *  Cp = Commission percentage
   *  F = FreightIdea
   *
   *  1) G = U - C - K
   *  1' )   U = G + C + K
   *  2) U = F * Q
   *  3) C = Cp * U
   *
   *  revenue (G)  should be 0
   *
   *  U = F * Q
   *  <=> F = U / Q     |insert 1' for U
   *  F = (G+C+K) / Q
   *  = G/Q + C/Q + K/Q       |revenue Should be 0
   *  = C/Q + K/Q             |insert 3 for C
   *  = (Cp*U)/Q + K/Q        | insert 2 for U
   *  (Cp*F*Q)/Q +K/Q
   *  F = Cp*F + K/Q          | - Cp *F
   *  F(1-Cp) = K/Q           | / (1-Cp)
   *  F = (K/Q) (1-Cp)

   */

  let profit = 0;
  if (params.tceTarget) {
    profit = params.tceTarget * params.oneCalc.voyageCalculationOutput.cargo.duration;
  }
  if (params.profitTarget) {
    profit = params.profitTarget;
  }

  const otherCosts =
    params.oneCalc.voyageCalculationOutput.cargo.revenueNet -
    params.oneCalc.voyageCalculationOutput.cargo.revenueTotal -
    params.oneCalc.voyageCalculationOutput.cost.costCommission;

  let freight = (otherCosts + profit) / (params.oneCalc.voyageCalculationInput.cargo.cargoQuantity ?? 0.001);
  freight = freight / (1 - (params.oneCalc.voyageCalculationInput.cargo.totalCommission ?? 1) / 100);
  freight = Math.abs(isNaN(freight) ? 0 : freight);

  return parseFloat(freight.toFixed(6));
};
