import React, {ReactElement} from 'react';
import {Form} from 'antd';
import {StyledDivider} from '../StyledDivider';
import {MapBoxLayer} from './MapBoxLayer';
import {OpenWeatherMapLayer} from './OpenWeatherMapLayer';
import {MapSwitches, MapSwitchesKey} from '../../MapDetails/utils/types';
import {TODO} from '../../../../utils/TODO';
import {MapToggleBtn} from '../../MapMenu/MapToggleBtn';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {MapControlMenuDropdownContainer} from '../MapControlMenuDropdownContainer';

type Props = {
  switches: MapSwitches;
  onChange: (switchId: MapSwitchesKey, value: TODO) => void;
  mapHeight: number;
  onOpen?: () => void;
  otherLayersDropDown?: ReactElement;
};

export const tradingAreaLayers: {label: string; fieldId: string}[] = [
  {
    label: 'Trading areas',
    fieldId: 'layer_trading_area',
  },
  {
    label: 'Trading area names',
    fieldId: 'trading_area_names',
  },
];

export const otherLayers: {label: string; fieldId: string}[] = [
  {
    label: 'High risk areas',
    fieldId: 'layer_piracy',
  },
  {
    label: 'Emission control areas',
    fieldId: 'layer_eca',
  },
];

export const LayerDropdown = (props: Props) => {
  const switches = props.switches;

  return (
    <MapControlMenuDropdownContainer mapHeight={props.mapHeight}>
      <Form colon={false} size={'small'} labelCol={{span: 0}} wrapperCol={{span: 24}} layout="horizontal">
        {switches.layer_trading_area && (
          <>
            {tradingAreaLayers.map(item => (
              <Form.Item key={item.fieldId}>
                <MapToggleBtn
                  id={item.fieldId}
                  key={item.fieldId}
                  label={item.label}
                  onChange={(event: CheckboxChangeEvent) =>
                    props.onChange(item.fieldId as MapSwitchesKey, event.target.checked)
                  }
                  checked={switches[item.fieldId as MapSwitchesKey]!.state}
                />
              </Form.Item>
            ))}
            <StyledDivider />
          </>
        )}
        {otherLayers.map(item => (
          <Form.Item key={item.fieldId}>
            <MapToggleBtn
              id={item.fieldId}
              key={item.fieldId}
              label={item.label}
              onChange={(event: CheckboxChangeEvent) =>
                props.onChange(item.fieldId as MapSwitchesKey, event.target.checked)
              }
              checked={switches[item.fieldId as MapSwitchesKey]!.state}
            />
          </Form.Item>
        ))}
      </Form>
      <StyledDivider />
      <MapBoxLayer />
      <StyledDivider />
      <OpenWeatherMapLayer />
      {props.otherLayersDropDown}
    </MapControlMenuDropdownContainer>
  );
};
