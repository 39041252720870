import {useMemo, FC} from 'react';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {getVesselSize} from '../../../hooks/useGetCompetitors/VesselSizeType';
import {StatCard} from '../sharedComponents/StatCard';
import startCase from 'lodash/startCase';
import {bulker, container, mpp} from '../../../components/consts/vesselTypes';

export const FinishedVisitsPerVesselType: FC<{
  visits: MergedVisits[];
  onSetVisits: (visits: MergedVisits[]) => void;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const perTypeGrouped = groupBy(visits, vessel => vessel.vesselType);

    const data = sortBy(
      map(perTypeGrouped, (visits, key) => {
        return {value: visits.length, name: key};
      }),
      'name'
    );

    return {
      data,
      perTypeGrouped,
    };
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perTypeGrouped[event.name]);
              onSetAppliedFilter('Transits by vessel type', event.name);
            }
          },
        }}
        option={{
          color: [bulker.fillColor, container.fillColor, mpp.fillColor, 'black'],
          title: {
            text: 'Transits by vessel type',
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            orient: 'vertical',
            left: 'left',
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: dataSource.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};

export const FinishedVisitsFromBulkersPerVesselSize: FC<{
  visits: MergedVisits[];
  onSetVisits: (vessels: MergedVisits[]) => void;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const filteredVisits = visits
      .filter(vessel => vessel.vesselType.toLowerCase() === 'bulker')
      .map(vessel => {
        const vesselSize = vessel.dwtSummer ? getVesselSize(vessel.dwtSummer) : undefined;
        const label = vesselSize?.label ?? 'Other';
        const color = vesselSize?.color ?? 'black';
        return {...vessel, label, color};
      });

    const perTypeGrouped = groupBy(filteredVisits, 'label');

    const data = sortBy(
      map(perTypeGrouped, (items, key) => {
        return {value: items.length, name: key, itemStyle: {color: items[0].color}};
      }),
      'value'
    );

    return {data, perTypeGrouped};
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perTypeGrouped[event.name] as unknown as MergedVisits[]);
              onSetAppliedFilter('Bulkers by Type', event.name);
            }
          },
        }}
        style={{
          height: 300,
        }}
        option={{
          title: {
            text: 'Finished bulker transits by type',
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: dataSource.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};

export const FinishedVisitsByDirection: FC<{
  visits: MergedVisits[];
}> = ({visits}) => {
  const dataSource = useMemo(() => {
    const perTypeGrouped = groupBy(visits, 'passingDirection');

    const data = sortBy(
      map(perTypeGrouped, (items, key) => {
        return {value: items.length, name: startCase(key)};
      }),
      'value'
    );

    return {data, perTypeGrouped};
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        style={{
          height: 240,
        }}
        option={{
          title: {
            text: 'Direction',
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
            formatter: (params: {name: string; percent: number}) => {
              return `${params.name}: ${params.percent.toFixed(0)}%`;
            },
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: dataSource.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};
