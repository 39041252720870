import dayjs from 'dayjs';
import {ColorColumnColor} from '../../GridLayout/columns/shared/colorColumn';

// See:  https://seanexxt.atlassian.net/wiki/spaces/SHIP/pages/3016982529/MyFleet+Colour+Markers

// colors see here: frontend/src/components/GridLayout/styles.scss

interface Colorable {
  color: number | null;
  nextOpenFrom?: string | null;
  redeliveryNotice?: number | null;
  spotState?: boolean;
}

export const colorPickerFunctionForMyFleetVessel = <ColorColumnType extends Colorable>(
  portfolioVessel: ColorColumnType
): ColorColumnColor | null => {
  const colorBasedOnSpot = getColorWhenColorBasedOnSpot({portfolioVessel});
  if (colorBasedOnSpot !== null) {
    return colorBasedOnSpot;
  }

  if (portfolioVessel.color !== null) {
    // If it has a color, it is a color between 0 and 5
    if (ColorColumnColor[portfolioVessel.color] !== undefined) {
      return portfolioVessel.color;
    }
    return null;
  }

  const colorBasedOnNextOpenDate = getColorWhenColorBasedOnNextOpenDate({portfolioVessel});

  return colorBasedOnNextOpenDate;
};

const getColorWhenColorBasedOnSpot = ({portfolioVessel}: {portfolioVessel: Colorable}): ColorColumnColor | null => {
  if (portfolioVessel.spotState) {
    return ColorColumnColor.RED;
  }
  return null;
};

const getColorWhenColorBasedOnNextOpenDate = ({
  portfolioVessel,
}: {
  portfolioVessel: Colorable;
}): ColorColumnColor | null => {
  if (!portfolioVessel.nextOpenFrom) {
    return null;
  }

  return getColorWhenHasNextOpenDate(portfolioVessel);
};

const getColorWhenHasNextOpenDate = (portfolioVessel: Colorable): ColorColumnColor | null => {
  const today = dayjs();
  const nextOpenFrom = dayjs(portfolioVessel.nextOpenFrom);

  if (nextOpenFrom.isSameOrBefore(today, 'day')) {
    return ColorColumnColor.YELLOW;
  }

  return getColorWhenHasFutureNextOpenDate({
    portfolioVessel,
    nextOpenFrom,
    today,
  });
};

const getColorWhenHasFutureNextOpenDate = ({
  portfolioVessel,
  nextOpenFrom,
  today,
}: {
  portfolioVessel: Colorable;
  today: dayjs.Dayjs;
  nextOpenFrom: dayjs.Dayjs;
}): ColorColumnColor | null => {
  if (!portfolioVessel.redeliveryNotice) {
    return null;
  }

  // day of redelivery notice
  const redeliveryNoticeDay = nextOpenFrom.subtract(portfolioVessel.redeliveryNotice, 'days');

  if (redeliveryNoticeDay.isSameOrBefore(today, 'day')) {
    return ColorColumnColor.YELLOW;
  }

  return null;
};
