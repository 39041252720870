import {CheckCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Button} from '../../atoms/Button/Button';
import {useSelector} from '../../redux/react-redux';

export const MarketImportPromotion = () => {
  const {showConnectMarketplaceBanner} = useSelector(state => state.company.data);

  if (!showConnectMarketplaceBanner) {
    return null;
  }

  return (
    <PromotionContainer>
      <div>
        <PromotionHeadline>
          Connect your Circular inbox to seabo Market to make your Workflow even easier.
        </PromotionHeadline>
        <PromotionText>
          When you connect your Email to seabo, you can see all your Circulars directly here.
        </PromotionText>
      </div>
      <PromotionUvpList>
        <PromotionUvpListItem>
          <CheckCircleOutlined /> No manual copying of emails needed - ever
        </PromotionUvpListItem>
        <PromotionUvpListItem>
          <CheckCircleOutlined /> AI reads the data from text automatically
        </PromotionUvpListItem>
      </PromotionUvpList>
      <Link to={`/setup/market-import`}>
        <Button primary>Connect your Market</Button>
      </Link>
    </PromotionContainer>
  );
};

const PromotionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
  padding: 10px 20px;
  background: var(--color-white);
  border-radius: var(--border-radius-card);
`;

const PromotionHeadline = styled.h3`
  margin: 0 0 2px;
  color: var(--color-gray-2);
  font-weight: bold;
`;

const PromotionText = styled.p`
  margin: 0;
  color: var(--color-gray-2);
`;

const PromotionUvpList = styled.ul`
  width: auto;
  margin: 0;
  padding: 0;
  color: var(--color-gray-2);
  list-style: none;
`;

const PromotionUvpListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;
