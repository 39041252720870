import {OneCalcResult} from '../../utils/oneCalc';
import {FC} from 'react';
import styled from 'styled-components';
import {TableVesselDetail, TableProjectDetail} from '../components';

export const OneVessel: FC<{oneCalcResult: OneCalcResult | undefined}> = ({oneCalcResult}) => {
  if (!oneCalcResult) {
    return (
      <VesselHeaderContainer data-cy={'MatrixOneVessel'}>
        <VesselHeaderBox>For this vessel is no calculation available.</VesselHeaderBox>
      </VesselHeaderContainer>
    );
  }

  const vessel = oneCalcResult.vessel;
  const vesselProject = oneCalcResult.vesselProject;

  return (
    <VesselHeaderContainer data-cy={'MatrixOneVessel'}>
      {vesselProject && (
        <VesselHeaderBox>
          <TableProjectDetail project={vesselProject} />
        </VesselHeaderBox>
      )}
      <VesselHeaderBox>
        <TableVesselDetail vessel={vessel} />
      </VesselHeaderBox>
    </VesselHeaderContainer>
  );
};

const VesselHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
`;

const VesselHeaderBox = styled.div`
  display: flex;
  align-items: center;
  min-height: 54px;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  background: var(--color-gray-5);
  border-radius: var(--border-radius);

  &:last-of-type {
    margin-bottom: 0;
  }
`;
