import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import {acceptIntOrEmpty} from '../../../utils/formatter';
import {numberBetween, required} from '../../../utils/validators';
import LabelWrapper from '../../../atoms/LabelWrapper';
import Input from '../../../atoms/Input';
import autocomplete from '../../../redux/ApiService/autocomplete';
import {RootState} from '../../../redux/store';
import {AsyncCreateable} from '../../../atoms/Select/AsyncCreateable';

const Wrapper = BindToProvider(
  'Charterer',
  'charterer',
  {
    chartererName: '',
    maxVesselAgeVc: '',
  },
  {
    chartererName: required(),
    maxVesselAgeVc: numberBetween(0, 99),
  },
  {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
    toApi: (fields, sections) => ({
      ...fields,

      maxVesselAge:
        sections.contractType.contractType === 'vc'
          ? parseInt(fields.maxVesselAgeVc)
          : parseInt(sections.vesselFeatures.maxVesselAge),

      maxVesselAgeVc: null,
      chartererName: fields.chartererName ? fields.chartererName.label : '',
    }),
    fromApi: (fields: $TSFixMe) => ({
      ...fields,
      chartererName:
        typeof fields.chartererName === 'string'
          ? {label: fields.chartererName, value: fields.chartererName}
          : fields.chartererName,
    }),
  },
  true
)(WrappedDetailsSection);

const Charterer = (props: $TSFixMe) => {
  const {preFillCharterer} = props;
  useEffect(() => {
    preFillCharterer();
  }, [preFillCharterer]);

  return (
    <Wrapper {...props}>
      {({onChange, values, validations, validate, form}: $TSFixMe) => (
        <div className="container-fluid">
          <div className="row">
            <div className="scol-12 scol-sm-6">
              <LabelWrapper
                required
                label={'Charterer'}
                htmlFor={'form-charterer-charterer-name'}
                hasError={validations.chartererName.invalid}>
                <AsyncCreateable
                  id={`form-charterer-charterer-name`}
                  name={`form-charterer-charterer-name`}
                  value={values.chartererName}
                  onChange={(value: $TSFixMe) => onChange('chartererName', value, () => validate('chartererName'))}
                  getOptionLabel={(option: $TSFixMe) => option.label}
                  getOptionValue={(option: $TSFixMe) => option.value}
                  loadOptions={(q: $TSFixMe) => (q.length > 1 ? props.searchCharterer(q) : Promise.resolve([]))}
                  defaultOptions={
                    props.preFillChartererData.map((p: $TSFixMe) => ({
                      value: p.slug,
                      label: p.name,
                    })) || []
                  }
                  isClearable
                  createLabel={(value: $TSFixMe) => value}
                  createOptionPosition={'first'}
                  placeholder={'Type to search...'}
                  loadingMessage={() => 'Searching ...'}
                  maxLength={127}
                  onBlur={() => validate('chartererName')}
                  hasError={validations.chartererName.invalid}
                />
              </LabelWrapper>
              {validations.chartererName.error && (
                <p className="text-danger text-danger--no-margin">{validations.chartererName.error}</p>
              )}
            </div>
            {form.contractType.contractType && form.contractType.contractType === 'vc' && (
              <div className="scol-12 scol-sm-6">
                <LabelWrapper
                  label={'Max vessel age (years)'}
                  htmlFor={'form-charterer-max-vessel-age-vc'}
                  hasError={validations.maxVesselAgeVc.invalid}>
                  <Input
                    id={'form-charterer-max-vessel-age-vc'}
                    onChange={v => acceptIntOrEmpty(v, v => onChange('maxVesselAgeVc', v), 2)}
                    value={values.maxVesselAgeVc}
                    maxLength={2}
                    hasError={validations.maxVesselAgeVc.invalid}
                  />
                </LabelWrapper>
                {validations.maxVesselAgeVc.error && (
                  <p className="text-danger text-danger--no-margin">{validations.maxVesselAgeVc.error}</p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const mapStateToProps = ({api}: RootState) => ({
  preFillChartererData: api.autoComplete.preFillCharterer.data?.data?.items || [],
});

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  searchCharterer: (query: $TSFixMe) => dispatch(autocomplete.searchCharterer(query)),
  preFillCharterer: () => dispatch(autocomplete.preFillCharterer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Charterer);
