import {FC} from 'react';
import {ReplyTimeLabel} from './ReplyTimeLabel';
import {getLastCommittedRound} from './getLastCommitedRound';
import {getIsReplyTimeSet} from './utils';
import {
  NegotiationRoundResultSummary,
  NegotiationStatus,
  NegotiationSummary,
} from '../../../../api/node-backend/generated';
import {LoadingDots} from '../shared';

type NegotiationReplyTimeProps = {
  negotiation: NegotiationSummary;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  dateTimePrefix?: string;
  dateTimeSuffix?: string;
  countdownPrefix?: string;
  countdownSuffix?: string;
  notStartedYetText?: string;
  expiredText?: string;
  emptyText?: string;
  settledText?: string;
  cancelledText?: string;
};

export const NegotiationReplyTime: FC<NegotiationReplyTimeProps> = ({
  negotiation,
  roundResultSummaries,
  dateTimePrefix,
  dateTimeSuffix,
  countdownPrefix,
  countdownSuffix,
  notStartedYetText = 'Not started yet',
  expiredText = 'Expired',
  emptyText = 'No reply time',
  settledText = '-',
  cancelledText = '-',
}) => {
  if (!roundResultSummaries?.length) {
    return <LoadingDots />;
  }

  const {status} = negotiation;
  const lastCommittedRound = getLastCommittedRound(roundResultSummaries);

  if (!lastCommittedRound) {
    return <>{notStartedYetText}</>;
  }

  const {replyTime, committedAt} = lastCommittedRound;

  if (status === NegotiationStatus.Settled) {
    return <>{settledText}</>;
  }

  if (status === NegotiationStatus.Cancelled) {
    return <>{cancelledText}</>;
  }

  if (!getIsReplyTimeSet(replyTime)) {
    return <>{emptyText}</>;
  }

  return (
    <ReplyTimeLabel
      replyTime={replyTime}
      committedAt={committedAt}
      dateTimePrefix={dateTimePrefix}
      dateTimeSuffix={dateTimeSuffix}
      countdownPrefix={countdownPrefix}
      countdownSuffix={countdownSuffix}
      emptyText={emptyText}
      expiredText={expiredText}
    />
  );
};
