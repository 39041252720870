import {useEffect, useState, FC} from 'react';
import {gridColumns} from './gridColumns';
import {MergedVisits} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import styled from 'styled-components';
import {DataGrid} from '../../../../components/DataGrid/DataGrid';

export const Grid: FC<{vessels: MergedVisits[]}> = ({vessels}) => {
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(1);
  }, [vessels]);
  const vesselsTotal = vessels.length;

  if (vesselsTotal === 0) {
    return <div />;
  }

  return (
    <GridContainer>
      <GridTitle>Reported vessels</GridTitle>
      <DataGrid
        data-testid="ChokepointCongestionGrid"
        showPageSizeOptions
        totalCount={vesselsTotal}
        pageSize={pageSize}
        manualPagination={false}
        manualSorting={false}
        zeroBasedPageIndex={false}
        page={page}
        pagination={{
          pageIndex: page,
          pageSize,
        }}
        onPageChange={pageIndex => {
          setPage(pageIndex);
        }}
        onPageSizeChange={pageSize => {
          setPageSize(pageSize);
          setPage(1);
        }}
        columns={gridColumns}
        data={vessels}
        showPagination
        noHoverStatePointer
      />
    </GridContainer>
  );
};

const GridContainer = styled.div`
  padding: 20px;
  margin-top: 20px;
  background: var(--color-gray-6);
  border: var(--border-base);
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-card);

  .ant-card & .rt-table {
    box-shadow: var(--box-shadow-card);
  }
`;

const GridTitle = styled.p`
  text-align: center;
  font-size: var(--font-size-lg);
  font-weight: bold;
`;
