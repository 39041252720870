import {Tooltip} from 'antd';
import {MouseEventHandler, useState} from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

export type MenuItemProps = {
  label: string;
  active?: boolean;
  disabled?: boolean;
  disabledHint?: string;
  withDivider?: boolean;
  dataCy?: string;
  dataTestid?: string;
  className?: string;
  onClick?: () => void;
};

export const MenuItem = ({label, disabled, disabledHint, dataCy, dataTestid, onClick, ...restProps}: MenuItemProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const clickHandler: MouseEventHandler = event => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    return onClick?.();
  };

  const menuItem = (
    <StyledMenuItem
      {...restProps}
      onClick={clickHandler}
      data-cy={dataCy}
      data-testid={dataTestid}
      disabled={!!disabled}>
      {label}
    </StyledMenuItem>
  );

  if (disabled) {
    return (
      <Tooltip title={disabledHint} open={showTooltip} placement={'bottom'} trigger={['focus', 'hover']}>
        <div
          onMouseEnter={() => {
            setShowTooltip(true);
          }}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}>
          {menuItem}
        </div>
      </Tooltip>
    );
  }
  return menuItem;
};

export const MenuItemLink = (props: Omit<MenuItemProps, 'onClick'> & {href: string}) => {
  const {href} = props;
  return (
    <Link to={href}>
      <MenuItem {...props} />
    </Link>
  );
};

const StyledMenuItem = styled.div<{disabled: boolean}>`
  cursor: pointer;
  position: relative;

  ${({disabled}) =>
    disabled &&
    css`
      color: var(--color-gray-3);
    `}
`;
