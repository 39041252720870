import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {Portal} from '../Portal/Portal';

/**
 * Renders an action bar.
 */
export const ActionBar: FC<{children: ReactNode}> = ({children}) => {
  return (
    <Portal targetNodeId="action-bar-portal">
      <ActionBarStyled className="react-app-outer" data-cy="ActionBar">
        {children}
      </ActionBarStyled>
    </Portal>
  );
};

const ActionBarStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-btn,
  a .ant-btn {
    border-radius: 0;
    border-right-width: 0;
    transition:
      background-color 0.1s,
      border-color 0.1s,
      border-width 0s;

    &:first-of-type {
      border-top-left-radius: var(--border-radius-lg);
      border-bottom-left-radius: var(--border-radius-lg);
    }

    &:last-of-type {
      border-top-right-radius: var(--border-radius-lg);
      border-bottom-right-radius: var(--border-radius-lg);
      border-right-width: var(--border-width);
    }

    &:hover {
      border-right-width: var(--border-width);

      + .ant-btn {
        border-left-width: 0;
      }
    }
  }
`;

export default ActionBar;
