import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {Location} from '../utils/Location';

export interface DatabaseType {
  stations: Location[];
}

export interface InternalType {
  stations: Location[];
}

export const Defaults: InternalType = {
  stations: [],
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return database;
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  return internal;
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Position open',
  branch: 'positionOpen',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
