import {StepResult} from './StepResult';
import {FilterParams} from '../filterParams';
import {Step3Result, Step3Vessel} from './step3';
import {vesselsLastPortWasSelectedPortFilter} from '../filters/vesselsLastPortWasSelectedPortFilter';

export type Step4Result = StepResult<Step3Vessel>;

/**
 * filter vessels -  only vessels which have not visited the port last
 *
 * Remove all vessels which have not visited the port last
 *
 */
export const step4 = ({params, preResult}: {preResult: Step3Result; params: FilterParams}): Step4Result => {
  let vesselList = [...preResult.vessels];

  if (params.enabledFilters.history) {
    vesselList = vesselsLastPortWasSelectedPortFilter({vessels: vesselList, filter: params});
  }

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: 'Last visited port not destination port',
        count: vesselList.length,
        id: 'destination',
      },
    ],
  };
};
