import {Button} from 'antd';
import styled from 'styled-components';

export const VoyageCalculationButton = styled(Button)`
  height: 32px;
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);

  display: inline-flex;
  align-items: center;

  font-family: 'Akkurat';
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 13px;
  text-align: center;
  transition: none;

  span {
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
  }

  &:focus {
    background-color: ${p => (p.type === 'primary' ? 'var(--color-blue) !important' : '#EFEFEF')};
  }

  &:hover {
    background-color: ${p => (p.type === 'primary' ? 'var(--color-azure) !important' : 'var(--color-white)')};
    border-color: var(--color-azure) !important;
  }
`;
