/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface PortfolioGroupDetails
 */
export interface PortfolioGroupDetails {
  /**
   *
   * @type {number}
   * @memberof PortfolioGroupDetails
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PortfolioGroupDetails
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PortfolioGroupDetails
   */
  sortOrder: number;
  /**
   *
   * @type {Date}
   * @memberof PortfolioGroupDetails
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof PortfolioGroupDetails
   */
  updatedAt?: Date;
  /**
   *
   * @type {Array<object>}
   * @memberof PortfolioGroupDetails
   */
  cargoes?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof PortfolioGroupDetails
   */
  vessels?: Array<object>;
}

export function PortfolioGroupDetailsFromJSON(json: any): PortfolioGroupDetails {
  return PortfolioGroupDetailsFromJSONTyped(json, false);
}

export function PortfolioGroupDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioGroupDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    sortOrder: json['sortOrder'],
    createdAt: !exists(json, 'createdAt') ? undefined : new Date(json['createdAt']),
    updatedAt: !exists(json, 'updatedAt') ? undefined : new Date(json['updatedAt']),
    cargoes: !exists(json, 'cargoes') ? undefined : json['cargoes'],
    vessels: !exists(json, 'vessels') ? undefined : json['vessels'],
  };
}

export function PortfolioGroupDetailsToJSON(value?: PortfolioGroupDetails | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    sortOrder: value.sortOrder,
    createdAt: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updatedAt: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    cargoes: value.cargoes,
    vessels: value.vessels,
  };
}
