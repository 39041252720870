import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import React, {Fragment} from 'react';
import Input from '../../../../atoms/Input';
import {acceptFloatOrEmpty} from '../../../../utils/formatter';
import CompoundInput from '../../../../atoms/CompoundInput';
import IconButton from '../../../../atoms/IconButton';
import {ConsumptionRowLabels} from './ConsumptionRowLabels';
import Select from '../../../../atoms/Select/Select';

export const modes = {
  laden: 'Laden',
  ballast: 'Ballast',
  ballasteco: 'Ballast eco',
  ladeneco: 'Laden eco',
  ladenballast: 'Laden ballast',
  portidle: 'Port idle',
  portworking: 'Port working',
  designdraft: 'Design draft',
  scantlingdraft: 'Scantling draft',
};

export const vesselModes = {
  container: ['designdraft', 'scantlingdraft', 'portidle', 'portworking'],
  bulker: ['laden', 'ballast', 'ballasteco', 'ladeneco', 'portidle', 'portworking'],
  mpp: ['laden', 'ballast', 'ballasteco', 'ladeneco', 'portidle', 'portworking'],
};

export const fuelTypes = {
  ifo: 'IFO',
  mdo: 'MDO',
  mgo: 'MGO',
};

export type Consumption = {
  type: keyof typeof modes | '';
  speed: number | '';
  fuelConsumption: number | '';
  fuelType: keyof typeof fuelTypes | '';
  extraFuelConsumption: number | '';
  extraFuelType: keyof typeof fuelTypes | '';
};

export const newConsumption: Consumption = {
  type: '',
  speed: '',
  fuelConsumption: '',
  fuelType: Object.keys(fuelTypes)[0] as keyof typeof fuelTypes,
  extraFuelConsumption: '',
  extraFuelType: Object.keys(fuelTypes)[1] as keyof typeof fuelTypes,
};

export const fuelTypeOptions = Object.keys(fuelTypes).map(value => ({
  value,
  name: fuelTypes[value as keyof typeof fuelTypes],
}));

export const ConsumptionRows = ({consumptions = [], validation, vesselType, onChange}: $TSFixMe) => {
  const onRowChange = (field: $TSFixMe, value: $TSFixMe, i: $TSFixMe) => {
    onChange([
      ...consumptions.slice(0, i),
      {
        ...consumptions[i],
        [field]: value,
        speed:
          // eslint-disable-next-line no-nested-ternary
          field === 'speed'
            ? value
            : field === 'type' && ['portidle', 'portworking'].includes(value)
            ? ''
            : consumptions[i].speed,
      },
      ...consumptions.slice(i + 1),
    ]);
  };

  const onRemove = (i: $TSFixMe) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
    onChange(consumptions.filter((v, idx) => idx !== i));
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const vesselOptions = vesselModes[vesselType].map((value: $TSFixMe) => ({
    value,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    name: modes[value],
  }));

  return (
    <div className="consumptions-rows">
      {consumptions.length > 0 && <ConsumptionRowLabels />}
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'values' implicitly has an 'any' type. */}
      {consumptions.map((values, i) => (
        <ConsumptionRowInputs
          key={i}
          values={values}
          vesselOptions={vesselOptions}
          i={i}
          onRowChange={onRowChange}
          validation={validation}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};

const ConsumptionRowInputs = ({values, vesselOptions, i, onRowChange, validation, onRemove}: $TSFixMe) => (
  <div className="row consumption-row-inputs" key={i}>
    <div className="scol-12 scol-sm-6 scol-lg-3">
      <Select
        id={`form-consumptions-type-${i}`}
        name={`form-consumptions-type-${i}`}
        options={vesselOptions}
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        value={{value: values.type, name: modes[values.type]}}
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        defaultValue={{value: values.type, name: modes[values.type]}}
        onChange={(o: $TSFixMe) => onRowChange('type', o.value, i)}
        getOptionLabel={(o: $TSFixMe) => o.name}
        getOptionValue={(o: $TSFixMe) => o.value}
        components={{DropdownIndicator: ArrowIndicator}}
        isSearchable={false}
        hasError={validation[i] && validation[i].type && validation[i].type.invalid}
      />
      {validation[i] && validation[i].type && validation[i].type.error && (
        <p className="text-danger text-danger--no-margin">{validation[i].type.error}</p>
      )}
    </div>
    <div className="scol-12 scol-sm-5 scol-lg-2">
      <Fragment>
        {!['portidle', 'portworking'].includes(values.type) && (
          <Input
            id={`form-consumptions-speed-${i}`}
            onChange={v => acceptFloatOrEmpty(v, v => onRowChange('speed', v, i), 2, 2)}
            value={values.speed}
            hasError={validation[i] && validation[i].speed && validation[i].speed.invalid}
          />
        )}
        {validation[i] && validation[i].speed && validation[i].speed.error && (
          <p className="text-danger text-danger--no-margin">{validation[i].speed.error}</p>
        )}
      </Fragment>
    </div>
    <div className="scol-12 scol-sm-11 scol-lg-3">
      <CompoundInput>
        <Input
          id={`form-consumptions-fuel-consumption-${i}`}
          onChange={v => acceptFloatOrEmpty(v, v => onRowChange('fuelConsumption', v, i), 3, 2)}
          value={values.fuelConsumption}
          hasError={validation[i] && validation[i].fuelConsumption && validation[i].fuelConsumption.invalid}
        />
        <Select
          id={`form-consumptions-fuel-type-${i}`}
          name={`form-consumptions-fuel-type-${i}`}
          options={fuelTypeOptions}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          value={{value: values.fuelType, name: fuelTypes[values.fuelType]}}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          defaultValue={{value: values.fuelType, name: fuelTypes[values.fuelType]}}
          onChange={(o: $TSFixMe) => onRowChange('fuelType', o.value, i)}
          getOptionLabel={(o: $TSFixMe) => o.name}
          getOptionValue={(o: $TSFixMe) => o.value}
          components={{DropdownIndicator: ArrowIndicator}}
          isSearchable={false}
        />
      </CompoundInput>
      {validation[i] && validation[i].fuelConsumption && validation[i].fuelConsumption.error && (
        <p className="text-danger">{validation[i].fuelConsumption.error}</p>
      )}
    </div>
    <div className="scol-12 scol-sm-11 scol-lg-3">
      <CompoundInput>
        <Input
          id={`form-consumptions-extra-fuel-consumption-${i}`}
          onChange={v => acceptFloatOrEmpty(v, v => onRowChange('extraFuelConsumption', v, i), 3, 2)}
          value={values.extraFuelConsumption}
          hasError={validation[i] && validation[i].extraFuelConsumption && validation[i].extraFuelConsumption.invalid}
        />
        <Select
          id={`form-consumptions-extra-fuel-type-${i}`}
          name={`form-consumptions-extra-fuel-type-${i}`}
          options={fuelTypeOptions}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          value={{value: values.extraFuelType, name: fuelTypes[values.extraFuelType]}}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          defaultValue={{value: values.extraFuelType, name: fuelTypes[values.extraFuelType]}}
          onChange={(o: $TSFixMe) => onRowChange('extraFuelType', o.value, i)}
          getOptionLabel={(o: $TSFixMe) => o.name}
          getOptionValue={(o: $TSFixMe) => o.value}
          components={{DropdownIndicator: ArrowIndicator}}
          isSearchable={false}
        />
      </CompoundInput>
      {validation[i] && validation[i].extraFuelConsumption && validation[i].extraFuelConsumption.error && (
        <p className="text-danger">{validation[i].extraFuelConsumption.error}</p>
      )}
    </div>
    <div className="scol-12 scol-sm-1">
      <div className={'cargo-vessel-form__v-centered-field-without-label'}>
        <IconButton
          id={`form-consumptions-gear-remove-${i}`}
          type={'scrap'}
          iconStyle={{cursor: 'pointer'}}
          onClick={() => onRemove(i)}
          title={'Remove'}
        />
      </div>
    </div>
  </div>
);
