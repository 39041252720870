import {FC} from 'react';
import {AisFormItem, OnChangeSingleFormValue} from '../AisFormItem';
import {aisInitialFilters} from '../../../mapOptions/aisInitialFilters';
import {MinMaxRangeFilter} from './MinMaxRangeFilter';

export const LoaFormItem: FC<{onSetValuesViaApi: OnChangeSingleFormValue; disabled: boolean}> = ({
  onSetValuesViaApi,
  disabled,
}) => {
  return (
    <AisFormItem
      name={'loa'}
      defaultValue={aisInitialFilters.loa}
      label={'LOA'}
      labelCol={{span: 24}}
      onChangeSingleFormValue={onSetValuesViaApi}>
      <MinMaxRangeFilter disabled={disabled} min={50} max={500} step={10} />
    </AisFormItem>
  );
};
