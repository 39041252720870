import {AISRecord} from './AISRecord';
import {LonLatCoordinates} from '../../../../../../utils/Coordinates';
import {calculateBearing} from '../../utils/bearing';

/**
 * Calculates the heading between two AIS records and returns the first AIS record with the calculated heading.
 */
export const calculateHeading = (fromAisRecord: AISRecord, toAisRecord: AISRecord): number => {
  // Don't trust the heading, calculate it ourselves.
  const firstCoordinates: LonLatCoordinates = fromAisRecord.coordinates;
  const secondCoordinates: LonLatCoordinates = toAisRecord.coordinates;
  const bearing = calculateBearing(firstCoordinates, secondCoordinates);
  return bearing;
};
