/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface DayStatistics
 */
export interface DayStatistics {
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  exitCount: number;
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  exitAvgDuration: number;
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  exitMaxDuration: number;
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  exitMinDuration: number;
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  ongoingCount: number;
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  ongoingAvgDuration: number;
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  ongoingMaxDuration: number;
  /**
   *
   * @type {number}
   * @memberof DayStatistics
   */
  ongoingMinDuration: number;
}

export function DayStatisticsFromJSON(json: any): DayStatistics {
  return DayStatisticsFromJSONTyped(json, false);
}

export function DayStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayStatistics {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    exitCount: json['exitCount'],
    exitAvgDuration: json['exitAvgDuration'],
    exitMaxDuration: json['exitMaxDuration'],
    exitMinDuration: json['exitMinDuration'],
    ongoingCount: json['ongoingCount'],
    ongoingAvgDuration: json['ongoingAvgDuration'],
    ongoingMaxDuration: json['ongoingMaxDuration'],
    ongoingMinDuration: json['ongoingMinDuration'],
  };
}

export function DayStatisticsToJSON(value?: DayStatistics | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    exitCount: value.exitCount,
    exitAvgDuration: value.exitAvgDuration,
    exitMaxDuration: value.exitMaxDuration,
    exitMinDuration: value.exitMinDuration,
    ongoingCount: value.ongoingCount,
    ongoingAvgDuration: value.ongoingAvgDuration,
    ongoingMaxDuration: value.ongoingMaxDuration,
    ongoingMinDuration: value.ongoingMinDuration,
  };
}
