import React, {FC} from 'react';
import {TODO} from '../../../../utils/TODO';
import {DummyData} from '../../PostedBy/DummyData';
import {PostedByBlur} from '../../PostedBy/PostedByBlur/PostedByBlur';
import {PromotionPopover} from '../../../../components/PromotionPopover/PromotionPopover';
import {PostedByContent} from '../../PostedBy/PostedByContent';
import {useIsPermitted} from '../../../../utils/useIsPermitted';
import {FeaturePermission} from '../../../../api/symfony/generated';

interface Props {
  circular: TODO;
}

export const PostedBy: FC<Props> = ({circular}) => {
  const isPermitted = useIsPermitted(FeaturePermission.ViewFullMarketLiveFeed);
  return isPermitted ? (
    <PostedByContent circular={circular} />
  ) : (
    <PromotionPopover active>
      <PostedByBlur>
        <DummyData />
      </PostedByBlur>
    </PromotionPopover>
  );
};
