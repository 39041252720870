import styled from 'styled-components';
import Icon from '../../../../../../atoms/Icon';
import {TermTextContainer} from '../../TermItem/Components/TermContent/TermContent';
import {TermDiffContainer} from '../../TermItem/Components/TermDiff';

export const DiffIcon = styled(Icon)`
  position: relative;
  display: inline-block;
  text-align: middle;
  margin: 0 10px 0 1px;
  z-index: 1;

  & + ${TermTextContainer} > ${TermDiffContainer} {
    margin-left: 0;
  }
`;

export const EditIcon = styled(DiffIcon)<{$bgColor: string}>`
  margin-left: 2px;
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--color-${({$bgColor}) => $bgColor});
    z-index: -1;
  }
`;
