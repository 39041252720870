import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
  KEY_ENTER_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  COMMAND_PRIORITY_LOW,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import {FC, useLayoutEffect} from 'react';
import {convertLexicalNodesToText} from '../utils/utils';

/**
 * Fires a given callback on enter while making new lines available on shift+enter
 */
export const CallbackOnEnterPlugin: FC<{callback?: (text: string) => void}> = ({callback}) => {
  const [editor] = useLexicalComposerContext();
  useLayoutEffect(() => {
    if (!callback) {
      return;
    }

    return editor.registerCommand<KeyboardEvent | null>(
      KEY_ENTER_COMMAND,
      event => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return false;
        }
        if (event !== null) {
          event.preventDefault();
          if (event.shiftKey) {
            return editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
          }
        }
        const state = editor.getEditorState().toJSON();
        const text = convertLexicalNodesToText(state.root);
        callback(text);
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  return null;
};
