/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ConsentContract,
  ConsentContractFromJSON,
  ConsentContractToJSON,
  CreateConsentContractDTO,
  CreateConsentContractDTOFromJSON,
  CreateConsentContractDTOToJSON,
  UpdateConsentContractDTO,
  UpdateConsentContractDTOFromJSON,
  UpdateConsentContractDTOToJSON,
} from '../models';

export interface CreateConsentContractRequest {
  createConsentContractDTO: CreateConsentContractDTO;
}

export interface GetConsentContractRequest {
  id: string;
}

export interface UpdateConsentContractRequest {
  id: string;
  updateConsentContractDTO: UpdateConsentContractDTO;
}

/**
 *
 */
export class ConsentContractsApi extends runtime.BaseAPI {
  /**
   */
  async createConsentContractRaw(
    requestParameters: CreateConsentContractRequest
  ): Promise<runtime.ApiResponse<ConsentContract>> {
    if (
      requestParameters.createConsentContractDTO === null ||
      requestParameters.createConsentContractDTO === undefined
    ) {
      throw new runtime.RequiredError(
        'createConsentContractDTO',
        'Required parameter requestParameters.createConsentContractDTO was null or undefined when calling createConsentContract.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateConsentContractDTOToJSON(requestParameters.createConsentContractDTO),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractFromJSON(jsonValue));
  }

  /**
   */
  async createConsentContract(requestParameters: CreateConsentContractRequest): Promise<ConsentContract> {
    const response = await this.createConsentContractRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getConsentContractRaw(
    requestParameters: GetConsentContractRequest
  ): Promise<runtime.ApiResponse<ConsentContract>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getConsentContract.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractFromJSON(jsonValue));
  }

  /**
   */
  async getConsentContract(requestParameters: GetConsentContractRequest): Promise<ConsentContract> {
    const response = await this.getConsentContractRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listConsentContractsRaw(): Promise<runtime.ApiResponse<Array<ConsentContract>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ConsentContractFromJSON));
  }

  /**
   */
  async listConsentContracts(): Promise<Array<ConsentContract>> {
    const response = await this.listConsentContractsRaw();
    return await response.value();
  }

  /**
   */
  async updateConsentContractRaw(
    requestParameters: UpdateConsentContractRequest
  ): Promise<runtime.ApiResponse<ConsentContract>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateConsentContract.'
      );
    }

    if (
      requestParameters.updateConsentContractDTO === null ||
      requestParameters.updateConsentContractDTO === undefined
    ) {
      throw new runtime.RequiredError(
        'updateConsentContractDTO',
        'Required parameter requestParameters.updateConsentContractDTO was null or undefined when calling updateConsentContract.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateConsentContractDTOToJSON(requestParameters.updateConsentContractDTO),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractFromJSON(jsonValue));
  }

  /**
   */
  async updateConsentContract(requestParameters: UpdateConsentContractRequest): Promise<ConsentContract> {
    const response = await this.updateConsentContractRaw(requestParameters);
    return await response.value();
  }
}
