import {actionTypes} from './ApiService/ProjectService';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {Project} from '../components/CharterCalculation/MultiCalc/types';
import {TODO} from '../utils/TODO';
import {getLocalStorage} from '../utils/useLocalStorage';

export type ProjectComment = {
  color: string;
  comment: string;
  id: number;
  name: string;
  project: Project;
  rating: null | number;
};

export const actionsTypes = {
  SET_GRIDSTATE: 'PROJECTS_LIST_SET_GRIDSTATE',
  SET_SCREENSTATE: 'PROJECTS_LIST_SET_SCREENSTATE',
};

export interface ProjectsState {
  project: TODO;
  shortList: TODO[];
  comments: ProjectComment[];
  screenState: TODO;
  gridState: TODO;
}

const initialState: ProjectsState = {
  project: null,
  shortList: [],
  comments: [],
  screenState: {
    projects: [],
    projectsTotal: 0,
    isArchive: false,
  },
  gridState: {
    pageIndex: 1,
    pageSize: getLocalStorage('grid_page_size-projects_list', 10),
    sortField: '',
    sortOrder: '',
    isExpanded: getLocalStorage('project-overview-layout', false),
  },
};

export const projectsReducer = (state = initialState, action: ActionWithPayload): ProjectsState => {
  switch (action.type) {
    case actionTypes.getAllProjects.SUCCESS:
      return {
        ...state,
        screenState: {
          ...state.screenState,
          projects: action.payload.data.items,
          projectsTotal: action.payload.data.totalItems,
        },
      };
    case actionTypes.updateProjectList.SUCCESS:
      return {
        ...state,
        screenState: {
          ...state.screenState,
          projects: state.screenState.projects.map((p: TODO) =>
            p.id === action.payload.id ? {...p, ...action.payload} : p
          ),
        },
      };
    case actionTypes.getProject.PENDING:
      return {
        ...state,
        shortList: initialState.shortList,
        comments: initialState.comments,
      };
    case actionTypes.getProject.SUCCESS:
    case actionTypes.updateProject.SUCCESS:
      return {
        ...state,
        project: action.payload,
      };
    case actionTypes.deleteProjectDocument.SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          documents: state.project.documents.filter((a: TODO) => a.uuid !== action.payload.uuid),
        },
      };
    case actionTypes.deleteProject.SUCCESS:
      return {
        ...state,
        project: null,
        screenState: {
          ...state.screenState,
          projects: state.screenState.projects.filter((p: TODO) => p.id !== action.payload.id),
          projectsTotal: state.screenState.projectsTotal - 1,
        },
      };
    case actionTypes.getShortlistVessels.SUCCESS:
    case actionTypes.getShortlistCargoes.SUCCESS:
      return {
        ...state,
        shortList: action.payload.data,
      };
    case actionTypes.addToShortlist.SUCCESS:
      return {
        ...state,
        shortList: [...state.shortList, ...action.payload],
      };
    case actionTypes.updateShortlistItem.SUCCESS:
      return {
        ...state,
        shortList: state.shortList.map((item: TODO) => {
          if (item.id === action.payload.id) {
            return {...item, ...action.payload};
          } else {
            return item;
          }
        }),
      };
    case actionTypes.deleteFromShortlist.SUCCESS:
      return {
        ...state,
        shortList: state.shortList.filter((item: TODO) => item.id !== action.payload.id),
      };
    case actionTypes.getProjectComments.SUCCESS:
      return {
        ...state,
        comments: action.payload.data,
      };
    case actionTypes.addProjectComment.SUCCESS:
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    case actionTypes.updateProjectComment.SUCCESS:
      return {
        ...state,
        comments: state.comments.map((c: TODO) => (c.id === action.payload.id ? {...c, ...action.payload} : c)),
      };
    case actionTypes.removeProjectComment.SUCCESS:
      return {
        ...state,
        comments: state.comments.filter((c: TODO) => c.id !== action.payload.id),
      };
    case actionsTypes.SET_SCREENSTATE: {
      return {
        ...state,
        screenState: {
          ...state.screenState,
          ...action.payload,
        },
      };
    }
    case actionsTypes.SET_GRIDSTATE: {
      return {
        ...state,
        gridState: {
          ...state.gridState,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const setListScreenState = (payload: TODO) => ({
  type: actionsTypes.SET_SCREENSTATE,
  payload,
});

export const setListGridState = (payload: TODO) => ({
  type: actionsTypes.SET_GRIDSTATE,
  payload,
});
