import styled, {css} from 'styled-components';

export const SettingsSection = styled.div<{$antForm?: boolean}>`
  display: flex;
  border-top: 1px solid var(--color-gray-3);
  ${({$antForm = false}) =>
    $antForm
      ? css`
          padding: 32px 0 24px;
        `
      : css`
          padding: 32px 0 32px;
        `}
`;

export const SettingsSectionTitle = styled.div`
  width: 25%;
  font-weight: bold;
  font-size: var(--font-size-md);
  line-height: 19px;
`;

export const SettingsSectionBody = styled.div`
  width: 75%;
  .company-settings-general-textarea {
    resize: none;
  }
`;
