import Checkbox from '../../../../../atoms/Checkbox';
import {cleanIdentifier} from '../../../../../utils/helper';
import HorizontalLine from '../../../../../atoms/HorizontalLine';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './FixtureTypeDefinition';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const FixtureType = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values}) => (
      <div className={'container'}>
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('Voyage', 'filter-option')}
              checked={values.VC}
              label={'Voyage'}
              onChange={e => handleChange('VC', e.target.checked)}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('TCT', 'filter-option')}
              checked={values.TCT}
              label={'TCT'}
              onChange={e => handleChange('TCT', e.target.checked)}
              spMT={'s'}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('Period', 'filter-option')}
              checked={values.PC}
              label={'Period'}
              onChange={e => handleChange('PC', e.target.checked)}
              spMT={'s'}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('Bareboat', 'filter-option')}
              checked={values.BC}
              label={'Bareboat'}
              onChange={e => handleChange('BC', e.target.checked)}
              spMT={'s'}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('Sale', 'filter-option')}
              checked={values.SALE}
              label={'Sale'}
              onChange={e => handleChange('SALE', e.target.checked)}
              spMT={'s'}
            />
          </div>
        </div>
        <HorizontalLine large topSpace={'large'} />
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={'filter-fixtures-options-isextension'}
              checked={values.isExtension}
              label={'Extension'}
              onChange={e => handleChange('isExtension', e.target.checked)}
            />
          </div>
        </div>
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={'filter-fixtures-options-isoptiondeclared'}
              checked={values.isOptionDeclared}
              label={'Option declared'}
              onChange={e => handleChange('isOptionDeclared', e.target.checked)}
              spMT={'s'}
            />
          </div>
        </div>
      </div>
    )}
  </FilterButton>
);

export default FixtureType;
