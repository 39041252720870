import {cleanIdentifier} from '../../utils/helper';
import {preventDefaultOnEnterPress} from '../../utils/preventDefaultOnEnterPress';
import {Button} from '../Button/Button';
import {FilterTypeDefault, FilterBoxRenderFunction, ValidationErrors, RangePickerValues} from './types';
import styled from 'styled-components';

type FilterBoxProps<FilterType extends FilterTypeDefault> = {
  title: string;
  values: FilterType;
  validations: ValidationErrors<FilterType>;
  valid: boolean;
  isApplied?: boolean;
  dataCy?: string;
  dataTestid?: string;
  onApply: () => void;
  onChange: <KeyType extends keyof FilterType>(field: KeyType, value: FilterType[KeyType] | null) => void;
  onChangeRangePicker: (values: RangePickerValues) => void;
  onCancel?: () => void;
  onReset: () => void;
  children: FilterBoxRenderFunction<FilterType>;
};

/**
 * The dropdown overlay that is displayed when a `FilterButton` is clicked.
 */
export const FilterBox = <FilterType extends FilterTypeDefault>({
  title,
  values,
  validations,
  valid,
  dataCy,
  dataTestid,
  onApply,
  onChange,
  onChangeRangePicker,
  onCancel,
  onReset,
  children,
}: FilterBoxProps<FilterType>) => {
  return (
    <FilterBoxForm
      data-cy={dataCy}
      data-testid={dataTestid}
      className={'filter-box'}
      autoComplete={'off'}
      noValidate={true}
      onSubmit={event => {
        onApply();

        event.preventDefault();
      }}
      {...preventDefaultOnEnterPress()}>
      <FilterBoxHeader>
        <FilterBoxTitle>{title}</FilterBoxTitle>
        <FilterBoxReset onClick={onReset} id={cleanIdentifier(title, 'filter-reset')} data-testid="FilterBoxReset">
          reset
        </FilterBoxReset>
      </FilterBoxHeader>
      <FilterBoxBody>
        {children({
          values,
          validations,
          handleChange: onChange,
          handleRangePickerChange: onChangeRangePicker,
        })}
      </FilterBoxBody>
      <FilterBoxFooter>
        {!!onCancel && (
          <Button
            id={cleanIdentifier(title, 'filter-cancel')}
            label={'cancel'}
            upper
            bold
            onClick={onCancel}
            dataCy="FilterBoxCancel"
            dataTestId="FilterBoxCancel"
          />
        )}
        <Button
          id={cleanIdentifier(title, 'filter-apply')}
          label={'Apply'}
          upper
          bold
          styleOverride={{color: 'var(--color-blue)'}}
          disabled={!valid}
          type="submit"
          dataCy="FilterBoxApply"
          dataTestId="FilterBoxApply"
        />
      </FilterBoxFooter>
    </FilterBoxForm>
  );
};

const FilterBoxForm = styled.form`
  display: flex;
  flex-direction: column;
  color: var(--color-black);
  min-width: 290px;

  .row.bottom-fix {
    margin-bottom: -15px;
  }
`;

const FilterBoxHeader = styled.div`
  border-bottom: var(--border-base);
  min-width: 0;
  outline: none;
  padding: 16px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterBoxTitle = styled.h3`
  border-width: 0;
  color: var(--color-black);
  font-size: var(--font-size-lg);
  font-weight: 400;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 0;
`;

const FilterBoxReset = styled.span`
  color: var(--color-gray-2);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-sm);
  min-width: 0;
  outline: none;
`;

const FilterBoxBody = styled.div`
  flex: 1 1 0;
  min-width: 0;
  outline: none;
  padding: 20px 21px 7px 21px;
`;

const FilterBoxFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7px;
`;
