import {FC} from 'react';
import {VoyagePoint, VoyagePointOutput, VoyageRoute, VoyageRouteOutput} from '../VoyageTypes';
import {ConsumptionTooltip} from './ConsumptionTooltip';
import {Value} from '../Definitions/Value';

export type VoyagePointProps = {point: VoyagePoint; pointOutput: VoyagePointOutput};
export type VoyageRouteProps = {route: VoyageRoute; routeOutput: VoyageRouteOutput};

type Props = VoyagePointProps | VoyageRouteProps;

export const Co2EmissionValueCell: FC<Props> = props => {
  const isPoint = 'point' in props;

  const co2Emission = isPoint ? props.pointOutput.cO2Emission : props.routeOutput.cO2Emission;

  return (
    <ConsumptionTooltip {...props}>
      <Value calculated data-cy="co2Emission">
        {Math.round(co2Emission)} mts
      </Value>
    </ConsumptionTooltip>
  );
};
