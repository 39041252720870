import {makeApiService, ApiServiceEntry} from './ApiService';
import {GetVesselDetailsResponseSchema} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {TODO} from '../../utils/TODO';

const entries: ApiServiceEntry[] = [
  {
    fn: 'get',
    route: '/api/vessels/{vesselId}',
    method: 'GET',
    storeData: false,
    responseSchema: GetVesselDetailsResponseSchema,
  },
  {
    fn: 'post',
    route: '/api/vessels',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'validate',
    route: '/api/vessels/validate',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'patch',
    route: '/api/vessels/{vesselId}',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'deleteVessel',
    route: '/api/vessels/{vesselId}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'getStatsVessel',
    route: '/api/stats/vessel',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getVesselHistory',
    route: '/api/vessels/{vesselId}/history',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getPortHistory',
    route: '/api/vessels/{vesselId}/portcalls',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getVesselVoyages',
    route: '/api/vessels/{vesselId}/voyages',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getLatestFixture',
    route: '/api/vessels/{vesselId}/latestFixture',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'multiPatch',
    route: '/api/vessels/multiPatch',
    method: 'PUT',
    storeData: false,
  },
  {
    fn: 'workspaceUpdate',
    route: '/api/vessels/workspaceUpdate/{vesselId}',
    method: 'PUT',
  },
  {
    fn: 'removeFixtureDetails',
    route: '/api/vessels/removeFixtureDetails/{vesselId}',
    method: 'PUT',
  },
  {
    fn: 'createMultipleVessels',
    route: '/api/vessels/multiCreate',
    method: 'POST',
  },
];

export type VesselServiceRequestInformation<ResultType> = {
  data: ResultType;
  loading: boolean;
  initial: boolean;
  state: 'ERROR' | 'SUCCESS' | 'LOADING' | 'INITIAL';
  statusCode?: number;
};

const vesselApi = makeApiService(entries, {apiName: 'vessel'});
export const reducers = vesselApi.reducers;
export const actions = vesselApi.actions;
export const actionTypes = vesselApi.actionTypes;

export const VesselServiceActions = {
  get: (vesselId: number) => actions.get({params: {vesselId}}),
  createVessel: (body: TODO) => actions.post({body}),
  patch: (vesselId: TODO, body: TODO) => actions.patch({params: {vesselId}, body}),
  deleteVessel: (vesselId: TODO) => actions.deleteVessel({params: {vesselId}}),
  getStatsVessel: (imo: TODO) => actions.getStatsVessel({queryParams: {imo}}),
  getLatestFixture: (vesselId: TODO) => actions.getLatestFixture({params: {vesselId}}),
  multiPatch: (body: TODO) => actions.multiPatch({body}),
  workspaceUpdate: (vesselId: TODO, workspaceId: TODO) =>
    actions.workspaceUpdate({params: {vesselId}, body: workspaceId}),
  removeFixtureDetails: (vesselId: TODO) => actions.removeFixtureDetails({params: {vesselId}}),
  multiCreate: (vessels: TODO, workspace: TODO, subPortfolio: TODO) =>
    actions.createMultipleVessels({body: {vessels, workspace, subPortfolio}}),
};

export default VesselServiceActions;
