import React, {FC} from 'react';
import {NegotiationSummary, NegotiationParty, NegotiationStatus} from '../../../../../api/node-backend/generated';
import {NegotiationActorLabel} from '../../../Components/NegotiationPartyLabel';
import styled from 'styled-components';

type TermAreaHeadlineProps = {
  negotiation: NegotiationSummary;
  isFirstRecapRound?: boolean;
};

export const TermAreaHeadline: FC<TermAreaHeadlineProps> = ({negotiation, isFirstRecapRound}) => (
  <Headline>
    <HeadlineText negotiation={negotiation} isFirstRecapRound={isFirstRecapRound} />
  </Headline>
);

const HeadlineText: FC<TermAreaHeadlineProps> = ({negotiation, isFirstRecapRound}) => {
  const {status, party, creator, counterpart, latestRound} = negotiation;
  const {committedAt} = latestRound;

  const roundIsEditable = committedAt === null;
  const isCreator = party === NegotiationParty.Creator;

  const SelfLabel = () => <NegotiationActorLabel actor={isCreator ? creator : counterpart} isSelf={true} />;
  const OtherLabel = () => <NegotiationActorLabel actor={isCreator ? counterpart : creator} isSelf={false} />;

  switch (status) {
    case NegotiationStatus.Offer:
      return roundIsEditable ? (
        <>
          Create offer for <OtherLabel />
        </>
      ) : (
        <>
          Waiting for <OtherLabel />
          's answer on offer
        </>
      );
    case NegotiationStatus.Ongoing:
      return roundIsEditable ? (
        <>
          Make counters and corrections for <OtherLabel />
        </>
      ) : (
        <>
          Waiting for <OtherLabel />
          's answer
        </>
      );
    case NegotiationStatus.Recap:
      if (isFirstRecapRound) {
        return roundIsEditable ? (
          <>
            Create the recap of negotiation with <OtherLabel />
          </>
        ) : (
          <>
            <OtherLabel /> has accepted the terms. Wait for their recap
          </>
        );
      }
      return roundIsEditable ? (
        <>
          Finalize recap of negotiation with <OtherLabel />
        </>
      ) : (
        <>
          Waiting for <OtherLabel />
          's answer on recap
        </>
      );
    case NegotiationStatus.Settled:
      return (
        <>
          Settled negotiation between <SelfLabel /> and <OtherLabel />
        </>
      );
    case NegotiationStatus.Cancelled:
      return (
        <>
          Cancelled negotiation between <SelfLabel /> and <OtherLabel />
        </>
      );
  }
};

const Headline = styled.h2`
  width: 100%;
  padding: 10px 0;
  margin: 10px 0 0;
  text-align: center;
  font-size: var(--font-size-md);
  line-height: 1;
  font-weight: bold;
  background: var(--color-gray-5);
  border-radius: var(--border-radius-tab);
`;
