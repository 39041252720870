import {Col, Popover, Row, Statistic} from 'antd';
import {FC} from 'react';
import {TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import {ClassInformation} from '../classInformation';
import {useGlobalTiedUpVesselsTrendChartDataSource} from './useGlobalTiedUpVesselsTrendChartDataSource';
import {Chart} from './Chart';
import {StatCard} from '../../../CongestionScreens/sharedComponents/StatCard';

export const GlobalTiedUpTrendChart: FC<{
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[];
  vesselClass: ClassInformation;
}> = ({tradingAreaTiedUpVesselsStatistics, vesselClass}) => {
  const dataSource = useGlobalTiedUpVesselsTrendChartDataSource(tradingAreaTiedUpVesselsStatistics, vesselClass);

  const lastDay = dataSource[dataSource.length - 1];

  return (
    <StatCard style={{marginBottom: 12}}>
      <Row gutter={[20, 0]} align={'middle'}>
        <Col span={18}>
          <Chart dataSource={dataSource} />
        </Col>
        <Col span={6}>
          <Popover content={'Vessels that stayed at the same location from 00:00 to 23:59'}>
            <Statistic title={'Tied up yesterday (24h)'} suffix={'%'} value={lastDay.tiedUpPercentage} />
          </Popover>
        </Col>
      </Row>
    </StatCard>
  );
};
