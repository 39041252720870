import React, {CSSProperties} from 'react';
import {Alert} from 'antd';

type Props = {
  message?: string;
  type?: 'info' | 'warning' | 'success' | 'error';
  style?: CSSProperties;
};

const InfoBox = ({message, type, style}: Props) => <Alert style={style} message={message} type={type} />;

export default InfoBox;
