import {Vessel} from '../../useMyFleetQuery/useMyFleetQuery';

export const getVesselType = (vessel: Vessel): string => {
  const designType = vessel.designSubType ? `(${vessel.designSubType}) ` : '';
  switch (vessel.vesselType) {
    case 'bulker':
      return `Bulker ${designType}`;
    case 'container':
      return `Container ${designType}`;
    default:
      return `Other ${designType}`;
  }
};
