/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyEditSubscriptionPlanEnum,
  CompanyEditSubscriptionPlanEnumFromJSON,
  CompanyEditSubscriptionPlanEnumFromJSONTyped,
  CompanyEditSubscriptionPlanEnumToJSON,
  SubscriptionMailPurpose,
  SubscriptionMailPurposeFromJSON,
  SubscriptionMailPurposeFromJSONTyped,
  SubscriptionMailPurposeToJSON,
} from './';

/**
 *
 * @export
 * @interface SendSubscriptionMailRequest
 */
export interface SendSubscriptionMailRequest {
  /**
   *
   * @type {SubscriptionMailPurpose}
   * @memberof SendSubscriptionMailRequest
   */
  purpose: SubscriptionMailPurpose;
  /**
   *
   * @type {CompanyEditSubscriptionPlanEnum}
   * @memberof SendSubscriptionMailRequest
   */
  targetPlan?: CompanyEditSubscriptionPlanEnum;
}

export function SendSubscriptionMailRequestFromJSON(json: any): SendSubscriptionMailRequest {
  return SendSubscriptionMailRequestFromJSONTyped(json, false);
}

export function SendSubscriptionMailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SendSubscriptionMailRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    purpose: SubscriptionMailPurposeFromJSON(json['purpose']),
    targetPlan: !exists(json, 'targetPlan') ? undefined : CompanyEditSubscriptionPlanEnumFromJSON(json['targetPlan']),
  };
}

export function SendSubscriptionMailRequestToJSON(value?: SendSubscriptionMailRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    purpose: SubscriptionMailPurposeToJSON(value.purpose),
    targetPlan: CompanyEditSubscriptionPlanEnumToJSON(value.targetPlan),
  };
}
