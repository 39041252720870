import {hasError} from '../../../../../utils/helper';
import FloatRangeInput from '../../../../RangeInput/FloatRangeInput';
import NumberRangeInput from '../../../../RangeInput/NumberRangeInput';
import HorizontalLine from '../../../../../atoms/HorizontalLine';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../../../atoms/Select/Indicators';
import Checkbox from '../../../../../atoms/Checkbox';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition, gearedOptions} from './FixtureVesselDetailsDefinition';
import Select from '../../../../../atoms/Select/Select';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const FixtureVesselDetails = ({filterProviderApi}: Props) => (
  <FilterButton size={'big'} filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => {
      return (
        <div className="container">
          <div className="row">
            <div className="scol-4">
              <FloatRangeInput
                label={'LOA'}
                onFromChange={v => handleChange('lengthFrom', v)}
                onToChange={v => handleChange('lengthTo', v)}
                fromValue={values.lengthFrom}
                toValue={values.lengthTo}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 99999999,
                  placeholder: 'min',
                  hasError: hasError('lengthFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 99999999,
                  placeholder: 'max',
                  hasError: hasError('lengthTo', validations),
                }}
              />
            </div>
            <div className="scol-4">
              <FloatRangeInput
                label={'Beam'}
                onFromChange={v => handleChange('beamFrom', v)}
                onToChange={v => handleChange('beamTo', v)}
                fromValue={values.beamFrom}
                toValue={values.beamTo}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 99999999,
                  placeholder: 'min',
                  hasError: hasError('beamFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 99999999,
                  placeholder: 'max',
                  hasError: hasError('beamTo', validations),
                }}
              />
            </div>
            <div className="scol-4">
              <FloatRangeInput
                label={'Draft'}
                onFromChange={v => handleChange('draftFrom', v)}
                onToChange={v => handleChange('draftTo', v)}
                fromValue={values.draftFrom}
                toValue={values.draftTo}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 99999999,
                  placeholder: 'min',
                  hasError: hasError('draftFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 99999999,
                  placeholder: 'max',
                  hasError: hasError('draftTo', validations),
                }}
              />
            </div>
          </div>
          <HorizontalLine large topSpace={'small'} />
          <div className="row">
            <div className="scol-5">
              <NumberRangeInput
                label={'DWT (Summer)'}
                onFromChange={value => handleChange('deadWeightFrom', value)}
                onToChange={value => handleChange('deadWeightTo', value)}
                fromValue={values.deadWeightFrom}
                toValue={values.deadWeightTo}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 999999,
                  placeholder: 'min',
                  hasError: hasError('deadWeightFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 999999,
                  placeholder: 'max',
                  hasError: hasError('deadWeightTo', validations),
                }}
              />
            </div>
            <div className="scol-2"></div>
            <div className="scol-5">
              <NumberRangeInput
                label={'TEU'}
                onFromChange={value => handleChange('teuFrom', value)}
                onToChange={value => handleChange('teuTo', value)}
                fromValue={values.teuFrom}
                toValue={values.teuTo}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 999,
                  placeholder: 'min',
                  hasError: hasError('teuFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 999,
                  placeholder: 'max',
                  hasError: hasError('teuTo', validations),
                }}
              />
            </div>
          </div>
          <HorizontalLine large topSpace={'small'} bottomSpace="medium" />
          <div className="row">
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Cellular'}
                id={'filter-feature-cellular'}
                checked={values.cellular}
                onChange={e => handleChange('cellular', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'ITF-fitted'}
                id={'filter-feature-itfFitted'}
                checked={values.itfFitted}
                onChange={e => handleChange('itfFitted', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Bow thruster'}
                id={'filter-feature-bowThrusted'}
                checked={values.bowThrusted}
                onChange={e => handleChange('bowThrusted', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Stern thruster'}
                id={'filter-feature-sternThrusted'}
                checked={values.sternThrusted}
                onChange={e => handleChange('sternThrusted', e.target.checked)}
              />
            </div>
          </div>
          <div className="row">
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Open-hatch'}
                id={'filter-feature-openHatched'}
                checked={values.openHatched}
                onChange={e => handleChange('openHatched', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Lakes-fitted'}
                id={'filter-feature-lakesFitted'}
                checked={values.lakesFitted}
                onChange={e => handleChange('lakesFitted', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Log-fitted'}
                id={'filter-feature-logFitted'}
                checked={values.logFitted}
                onChange={e => handleChange('logFitted', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'CO2-fitted'}
                id={'filter-feature-co2Fitted'}
                checked={values.co2Fitted}
                onChange={e => handleChange('co2Fitted', e.target.checked)}
              />
            </div>
          </div>
          <div className="row">
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Box-shape'}
                id={'filter-feature-boxShaped'}
                checked={values.boxShaped}
                onChange={e => handleChange('boxShaped', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Tween-decks'}
                id={'filter-feature-tweenDecks'}
                checked={values.tweenDecks}
                onChange={e => handleChange('tweenDecks', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'A60 bulkhead'}
                id={'filter-feature-a60Bulkhead'}
                checked={values.a60Bulkhead}
                onChange={e => handleChange('a60Bulkhead', e.target.checked)}
              />
            </div>
            <div className="scol-3">
              <Checkbox
                spMT={'s'}
                label={'Scrubber-fitted'}
                id={'filter-feature-scrubberFitted'}
                checked={values.scrubberFitted}
                onChange={e => handleChange('scrubberFitted', e.target.checked)}
              />
            </div>
          </div>
          <HorizontalLine large topSpace={'large'} />
          <div className="row bottom-fix">
            <div className="scol-5">
              <LabelWrapper label={'Geared'} htmlFor={'filter-feature-geared'}>
                <Select
                  id={'filter-feature-geared'}
                  name={'filter-feature-geared'}
                  value={values.geared ? values.geared : null}
                  isClearable
                  onChange={value => handleChange('geared', value)}
                  options={gearedOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.value}
                  components={{
                    DropdownIndicator: ArrowIndicator,
                  }}
                />
              </LabelWrapper>
            </div>
            <div className="scol-2"></div>
            <div className="scol-5">
              <NumberRangeInput
                flex
                label={'Reefer plugs'}
                id={'filter-plugs'}
                fromValue={values.plugsFrom}
                onFromChange={value => handleChange('plugsFrom', value)}
                toValue={values.plugsTo}
                onToChange={value => handleChange('plugsTo', value)}
                fromProps={{
                  min: 0,
                  max: 99999,
                  placeholder: 'min',
                  hasError: hasError('plugsFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 99999,
                  placeholder: 'max',
                  hasError: hasError('plugsTo', validations),
                }}
              />
            </div>
          </div>
        </div>
      );
    }}
  </FilterButton>
);

export default FixtureVesselDetails;
