import Flag from '../../../../atoms/Flags/index';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import {ResultVessel} from '../types';

export const lastPortColumn = ({
  minWidth = 12,
  sortable = true,
}: {
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<ResultVessel> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-lastport">
      <div>Last port</div>
    </div>
  ),
  id: 'lastport',
  accessorFn: v => {
    const lastPort = v.aisDetails?.lastPort;

    if (lastPort) {
      return (
        <span>
          <Flag countryCode={lastPort.country} /> {lastPort.name}
        </span>
      );
    }
    return <span>-</span>;
  },
  minWidth,
  cell: info => info.getValue(),
  enableSorting: sortable,
});
