import {FC} from 'react';
import {Row, Col} from 'antd';
import styled from 'styled-components';

export const AssistantModalIntro: FC<{
  onSkip: () => void;
  onNext: () => void;
  isPrefilled: boolean;
}> = ({onSkip, onNext, isPrefilled}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Headline>Use assisted import or skip and enter manually?</Headline>
      </Col>
      <Col span={12}>
        <StyledButton data-cy="useAssistantButton" onClick={onSkip}>
          <SkipHeadline>Use Voyage Calculation assistant</SkipHeadline>
          <SkipSubline>
            {isPrefilled ? 'Edit imported calculation' : 'Parse emails or search for your vessel and cargo'}
          </SkipSubline>
        </StyledButton>
      </Col>
      <Col span={12}>
        <StyledButton data-cy="skipButton" onClick={onNext}>
          <SkipHeadline>Skip</SkipHeadline>
          <SkipSubline>
            {isPrefilled ? 'Use imported calculation without editing' : 'Enter vessel and cargo manually'}
          </SkipSubline>
        </StyledButton>
      </Col>
    </Row>
  );
};

const Headline = styled.h2`
  width: 100%;
  font-size: var(--font-size-lg);
  font-weight: bold;
  text-align: center;
`;

const SkipHeadline = styled.span`
  width: 100%;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  font-weight: bold;
`;

const SkipSubline = styled.span`
  width: 100%;
  font-size: var(--font-size-lg);
  color: var(--color-gray-2);
`;

const StyledButton = styled.button`
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: var(--color-gray-6);
  border: var(--border-base);
  border-radius: var(--border-radius-lg);
  cursor: pointer;

  &:hover {
    background: var(--color-gray-5);
  }
`;
