import cx from 'classnames';
import Icon, {IconType} from '../../../atoms/Icon';
import React, {ButtonHTMLAttributes} from 'react';

export const MapControlButton = ({
  active,
  open,
  iconActive,
  iconInactive,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  iconActive: IconType;
  iconInactive: IconType;
  open?: boolean;
  active?: boolean;
}) => (
  <button
    type={'button'}
    className={cx('map-ctrl__btn', {
      'map-ctrl__btn--active': open || active,
      'map-ctrl__btn--open': open,
    })}
    {...props}>
    <Icon type={active ? iconActive : iconInactive} size="medium" color={'white'} />
  </button>
);
