import {FC} from 'react';
import styled from 'styled-components';
import {Breadcrumb, BreadcrumbProps} from 'antd';
import {BreadcrumbItem} from './BreadcrumbItem';
import {Link} from 'react-router-dom';
import {type ItemType} from 'antd/es/breadcrumb/Breadcrumb';
import {assert} from '../../utils/assert';

const SeaboBreadcrumb: BreadcrumbItem = {title: 'seabo', href: '/dashboard'};

/**
 * Displays the breadcrumbs at the top of the screen.
 */
export const BreadcrumbsBar: FC<{items: BreadcrumbItem[]}> = ({items}) => {
  const itemsToRender = [SeaboBreadcrumb, ...items];

  return (
    <BreadcrumbStyled separator={null} data-testid="BreadcrumbsBar" items={itemsToRender} itemRender={itemRender} />
  );
};

const itemRender: BreadcrumbProps['itemRender'] = (item, _params, _items, _paths) => {
  assert(isBreadcrumbItem(item), 'Can  render BreadcrumbItem only');
  return <BreadcrumbItemBox item={item} />;
};

const isBreadcrumbItem = (itemType: ItemType): itemType is BreadcrumbItem => 'title' in itemType;

const BreadcrumbItemBox: FC<{item: BreadcrumbItem}> = ({item}) => {
  const {title, href} = item;

  if (href) {
    return (
      <Link className={'breadcrumb-item'} to={href}>
        <span className={'router-link'}>{title}</span>
      </Link>
    );
  }

  return (
    <span className={'breadcrumb-item'}>
      <span>{title}</span>
    </span>
  );
};

const BreadcrumbStyled = styled(Breadcrumb)`
  height: 24px;
  margin: 0;
  padding: 0;
  border: var(--border-width) solid #d3d3d3;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-button);
  overflow: hidden;

  a {
    margin-inline: unset;
  }

  ol {
    display: flex;
    height: 100%;
    list-style-type: none;

    li {
      position: relative;
      height: 100%;
      transition: background-color 0.1s linear;

      &:has(a) {
        &:hover {
          background: var(--color-gray-4);

          a {
            cursor: pointer;

            &::after {
              background: var(--color-gray-4);
            }
          }
        }
      }

      .breadcrumb-item {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 4px;
        font-size: var(--font-size-sm);
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
        border: 0;
        border-radius: 0;
        transition: background-color 0.1s linear;
        cursor: default;
        color: var(--color-gray-1);

        > span {
          padding: 5px 10px 5px 14px;
          color: inherit !important;
          text-decoration: none;
          line-height: 1;
          z-index: 2;
        }

        > p {
          padding: 5px 10px 5px 14px;
          margin: 0;
          color: inherit !important;
          text-decoration: none;
          line-height: 1;
          z-index: 2;
        }

        .route-link {
          display: block;
        }

        &::after,
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: calc(100% - 2px);
          display: block;
          width: 8px;
          clip-path: polygon(0 0, 30% 0, 100% 50%, 30% 100%, 0 100%);
          background: var(--color-gray-5);
          transition: background-color 0.1s linear;
          z-index: 1;
        }
        &::before {
          background: #d3d3d3;
          margin-left: calc(var(--border-width) + 0.25px);
        }

        &:hover {
          background: none;
        }
      }

      &:last-child .breadcrumb-item {
        color: var(--color-blue-hover);
        background: var(--color-blue-transparent);

        &::after,
        &::before {
          display: none;
        }
      }
    }
  }
`;
