/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ConsentContractSummary,
  ConsentContractSummaryFromJSON,
  ConsentContractSummaryFromJSONTyped,
  ConsentContractSummaryToJSON,
  ConsentContractVersionSummary,
  ConsentContractVersionSummaryFromJSON,
  ConsentContractVersionSummaryFromJSONTyped,
  ConsentContractVersionSummaryToJSON,
  ConsentProcessType,
  ConsentProcessTypeFromJSON,
  ConsentProcessTypeFromJSONTyped,
  ConsentProcessTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface ConsentContractVersionConsent
 */
export interface ConsentContractVersionConsent {
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersionConsent
   */
  id: string;
  /**
   *
   * @type {ConsentContractSummary}
   * @memberof ConsentContractVersionConsent
   */
  contract: ConsentContractSummary;
  /**
   *
   * @type {ConsentContractVersionSummary}
   * @memberof ConsentContractVersionConsent
   */
  version: ConsentContractVersionSummary;
  /**
   *
   * @type {number}
   * @memberof ConsentContractVersionConsent
   */
  recipientUserId: number | null;
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersionConsent
   */
  recipientMail: string | null;
  /**
   *
   * @type {number}
   * @memberof ConsentContractVersionConsent
   */
  companyId: number;
  /**
   *
   * @type {Date}
   * @memberof ConsentContractVersionConsent
   */
  consentAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersionConsent
   */
  documentationText: string;
  /**
   *
   * @type {number}
   * @memberof ConsentContractVersionConsent
   */
  consentBySeaboUserId: number | null;
  /**
   *
   * @type {ConsentProcessType}
   * @memberof ConsentContractVersionConsent
   */
  consentProcessType: ConsentProcessType;
}

export function ConsentContractVersionConsentFromJSON(json: any): ConsentContractVersionConsent {
  return ConsentContractVersionConsentFromJSONTyped(json, false);
}

export function ConsentContractVersionConsentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ConsentContractVersionConsent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    contract: ConsentContractSummaryFromJSON(json['contract']),
    version: ConsentContractVersionSummaryFromJSON(json['version']),
    recipientUserId: json['recipientUserId'],
    recipientMail: json['recipientMail'],
    companyId: json['companyId'],
    consentAt: json['consentAt'] === null ? null : new Date(json['consentAt']),
    documentationText: json['documentationText'],
    consentBySeaboUserId: json['consentBySeaboUserId'],
    consentProcessType: ConsentProcessTypeFromJSON(json['consentProcessType']),
  };
}

export function ConsentContractVersionConsentToJSON(value?: ConsentContractVersionConsent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    contract: ConsentContractSummaryToJSON(value.contract),
    version: ConsentContractVersionSummaryToJSON(value.version),
    recipientUserId: value.recipientUserId,
    recipientMail: value.recipientMail,
    companyId: value.companyId,
    consentAt: value.consentAt === null ? null : value.consentAt.toISOString(),
    documentationText: value.documentationText,
    consentBySeaboUserId: value.consentBySeaboUserId,
    consentProcessType: ConsentProcessTypeToJSON(value.consentProcessType),
  };
}
