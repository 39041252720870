import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {NegotiationRoundResult} from '../../../../../../api/node-backend/generated';
import {negotiationNegotiationSubjectApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useRejectSubjectRejectionMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {subjectId: string}>
) =>
  useMutation({
    mutationFn: async ({subjectId}: {subjectId: string}) => {
      const result = await negotiationNegotiationSubjectApi.rejectSubjectRejectionRequest({
        subjectId,
      });
      return result;
    },
    ...options,
  });
