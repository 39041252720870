import {FC, useMemo, useState} from 'react';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {NegotiationScreenContainer} from '../Components/shared';
import {NegotiationDetailBody} from '../NegotiationDetail/NegotiationDetailBody';
import {NegotiationTour} from './Tour/NegotiationTour';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {NegotiationTourState} from './Tour/tourState';
import {
  SubjectActionsHook,
  subjectActionsNoop,
} from '../NegotiationDetail/Components/Subjects/utils/useGetSubjectActionsWithMutations';
import {
  TermActionsHook,
  termActionsNoop,
} from '../NegotiationDetail/Components/TermItem/utils/useGetTermActionsWithMutations';
import {
  NegotiationActionsHook,
  negotiationActionsNoop,
} from '../NegotiationDetail/Components/CommitArea/utils/useGetNegotiationActionsWithMutations';
import {
  SidebarActionsHook,
  sidebarActionsNoop,
} from '../NegotiationDetail/Components/TermArea/Sidebar/useGetSidebarActionsWithMutations';
import {templateActionsNoop} from '../NegotiationTemplates/utils/useTemplateActions';
import {attachmentActionsNoop} from '../NegotiationDetail/Components/Attachments/useAttachmentActions';
import {getTutorialNegotiation} from './utils/getTutorialNegotiation';

export const NegotiationTutorialScreen: FC<{isTourActive?: boolean}> = ({isTourActive = true}) => {
  const [searchParams] = useSearchParams();
  const redirectTarget = searchParams.get('redirectTarget');
  const navigate = useNavigate();

  const {
    tutorialNegotiationSummary,
    tutorialroundResultSummaries,
    tutorialRoundResult,
    tutorialRoundResultModification,
    tutorialRoundResultPrevious,
    tutorialRoundResultModificationPrevious,
  } = useMemo(() => getTutorialNegotiation(), []);

  const [tourState, setTourState] = useState<NegotiationTourState>({
    isActive: isTourActive,
    showOverlay: isTourActive,
    step: 0,
  });

  const breadcrumbs = [{title: 'Negotiations', href: '/negotiations'}, {title: 'Negotiation tutorial'}];

  const onTourStateChange = (newTourState: Partial<NegotiationTourState>) => {
    setTourState(tourState => ({...tourState, ...newTourState}));
  };

  const showOverlay = () => {
    onTourStateChange({showOverlay: true});
  };

  const exitTutorial = () => {
    navigate(redirectTarget ?? `/negotiations`);
  };

  const getTermActions: TermActionsHook = () => ({
    ...termActionsNoop,
    isDisabled: false,
  });
  const getSubjectActions: SubjectActionsHook = () => ({
    ...subjectActionsNoop,
    isDisabled: false,
  });
  const getNegotiationActions: NegotiationActionsHook = () => ({
    ...negotiationActionsNoop,
    isDisabled: false,
  });
  const getSidebarActions: SidebarActionsHook = () => ({
    ...sidebarActionsNoop,
    isDisabled: false,
  });
  const getTemplateActions = () => ({
    ...templateActionsNoop,
  });
  const getAttachmentActions = () => ({
    ...attachmentActionsNoop,
  });

  return (
    <NegotiationScreenContainer data-testid="NegotiationTutorial">
      <ScreenHeader
        breadcrumbs={breadcrumbs}
        helmetTitle={`${tutorialNegotiationSummary.title} - Negotiations`}
        features={
          <>
            <ScreenHeader.Features.Button onClick={showOverlay}>Show tutorial again</ScreenHeader.Features.Button>
            <ScreenHeader.Features.Button onClick={exitTutorial}>
              {redirectTarget ? 'Back to current negotiation' : 'Back to all negotiations'}
            </ScreenHeader.Features.Button>
          </>
        }
      />
      <NegotiationDetailBody
        negotiation={tutorialNegotiationSummary}
        roundResultSummaries={tutorialroundResultSummaries}
        currentRoundResult={tutorialRoundResult}
        currentRoundResultModifications={tutorialRoundResultModification}
        previousRoundResult={tutorialRoundResultPrevious}
        previousRoundResultModifications={tutorialRoundResultModificationPrevious}
        useGetTemplateDetail={() => ({})}
        getTermActions={getTermActions}
        getSubjectActions={getSubjectActions}
        getNegotiationActions={getNegotiationActions}
        getSidebarActions={getSidebarActions}
        getTemplateActions={getTemplateActions}
        getAttachmentActions={getAttachmentActions}
      />
      <NegotiationTour
        tourState={tourState}
        onChangeTourStep={newStep => onTourStateChange({step: newStep})}
        onExitTutorial={exitTutorial!}
      />
    </NegotiationScreenContainer>
  );
};
