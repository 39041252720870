import {useEffect, useState, FC} from 'react';
import {Input} from './Input';
import {InputProps} from 'antd';

type Props = Omit<InputProps, 'value' | 'onChange'> & {
  value?: number | undefined;
  onChange?: (value: number | undefined) => void;
  min?: number;
  dataCy?: string;
};

/**
 *  A number input component that supports both comma and dot as decimal separator.
 */
export const InputNumberAcceptCommaAndDot: FC<Props> = ({value, min, onChange, ...props}) => {
  const [tempValue, setTempValue] = useState<string>('');

  useEffect(() => {
    setTempValue(value?.toString() ?? '');
  }, [value]);

  const closeAndUpdate = (value?: string) => {
    const stringValue = (value ?? tempValue).replace(',', '.');
    const numberValue = parseFloat(stringValue);

    if (stringValue[stringValue.length - 1] === '.') {
      return;
    }

    if (isNaN(numberValue)) {
      return;
    }
    if (min !== undefined && min > numberValue) {
      return;
    }

    onChange?.(numberValue);
  };

  return (
    <Input
      {...props}
      type={'text'}
      addonAfter={props.addonAfter}
      value={tempValue}
      onChange={event => {
        const value = event.target.value;
        if (includesNonNumberOrDot(value)) {
          return;
        }
        setTempValue(value);
        closeAndUpdate(value);
      }}
    />
  );
};

const includesNonNumberOrDot = (value: string) => {
  return /[^0-9.,]/g.test(value);
};
