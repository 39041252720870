import {actions as locationApi, actionTypes as locationApiTypes} from './ApiService/ports';
import {AppThunk} from './AppThunk';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {TODO} from '../utils/TODO';

type UnknownLocation = TODO;

interface UnknownLocationState {
  list: {
    items: UnknownLocation[];
    pageIndex: number;
    pageSize: number;
    totalItems: number;
  };
}

const initialState: UnknownLocationState = {
  list: {
    items: [],
    pageIndex: 1,
    pageSize: 10,
    totalItems: 0,
  },
};

const actionTypes = {
  UNKNOWN_LOCATIONS_LIST_SET_PARAMS: 'UNKNOWN_LOCATIONS_LIST_SET_PARAMS',
};

export const unknownLocationReducer = (state = initialState, action: ActionWithPayload): UnknownLocationState => {
  const {type, payload} = action;
  switch (type) {
    case locationApiTypes.getUnknownLocationList.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          items: payload.data.items,
          totalItems: payload.data.totalItems,
          pageIndex: payload.data.totalItems < payload.data.pageSize ? 1 : state.list.pageIndex,
        },
      };
    case actionTypes.UNKNOWN_LOCATIONS_LIST_SET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          pageIndex: payload.pageIndex || state.list.pageIndex,
          pageSize: payload.pageSize || state.list.pageSize,
        },
      };
    default:
      return state;
  }
};

export const setUnknownLocationsListParams =
  (params: TODO): AppThunk =>
  dispatch => {
    dispatch({
      type: actionTypes.UNKNOWN_LOCATIONS_LIST_SET_PARAMS,
      payload: params,
    });

    dispatch(getUnknownLocationsList());
  };

export const getUnknownLocationsList = (): AppThunk => (dispatch, getState) => {
  const {pageIndex, pageSize} = getState().unknownLocation.list;

  dispatch(
    locationApi.getUnknownLocationList({
      queryParams: {pageIndex, pageSize},
    })
  );
};
