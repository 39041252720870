import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {NegotiationRoundResult} from '../../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useCommitRoundMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {negotiationId: string}>
) =>
  useMutation({
    mutationFn: async ({negotiationId}) => {
      const result = await negotiationNegotiationApi.commitRound({negotiationId});
      return result;
    },
    ...options,
  });
