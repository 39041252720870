/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CongestionLightReportDay,
  CongestionLightReportDayFromJSON,
  CongestionLightReportDayFromJSONTyped,
  CongestionLightReportDayToJSON,
} from './';

/**
 *
 * @export
 * @interface CongestionLightReport
 */
export interface CongestionLightReport {
  /**
   * Average duration of last 10 vessels in port in minutes
   * @type {number}
   * @memberof CongestionLightReport
   */
  avgDurationLast10Vessels: number | null;
  /**
   *
   * @type {Array<CongestionLightReportDay>}
   * @memberof CongestionLightReport
   */
  days: Array<CongestionLightReportDay>;
}

export function CongestionLightReportFromJSON(json: any): CongestionLightReport {
  return CongestionLightReportFromJSONTyped(json, false);
}

export function CongestionLightReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): CongestionLightReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    avgDurationLast10Vessels: json['avgDurationLast10Vessels'],
    days: (json['days'] as Array<any>).map(CongestionLightReportDayFromJSON),
  };
}

export function CongestionLightReportToJSON(value?: CongestionLightReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    avgDurationLast10Vessels: value.avgDurationLast10Vessels,
    days: (value.days as Array<any>).map(CongestionLightReportDayToJSON),
  };
}
