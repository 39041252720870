import {Col, Form, InputNumber, Row, Select} from 'antd';
import {FC} from 'react';
import {MarketCargoOffer} from '../../../MarketCargo/MarketCargoOffer';
import {ContractCalculationQuantity, getContractCalculationQuantity} from './getContractCalculationQuantity';

export const SizeFormItem: FC<{cargoOffer: MarketCargoOffer}> = ({cargoOffer}) => {
  const contractBase = getContractCalculationQuantity(cargoOffer.cargo.contractType);

  switch (contractBase) {
    case ContractCalculationQuantity.QUANTITY:
      return <SizeFormItemQuantity />;
    case ContractCalculationQuantity.TIME:
      return <SizeFormItemVesselSize />;
    default:
      return null;
  }
};

const SizeFormItemQuantity: FC = () => (
  <Form.Item label={'Quantity'}>
    <Row gutter={[4, 4]}>
      <Col span={8}>
        <Form.Item
          data-testid={'SizeFormItemQuantity'}
          rules={[{min: 0, type: 'number', message: 'Min. Quantity cannot be less than 0.'}]}
          name={'quantityMin'}
          style={{marginBottom: 0}}>
          <InputNumber placeholder={'min quantity'} style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{min: 0, type: 'number', message: 'Max. Quantity cannot be less than 0.'}]}
          name={'quantityMax'}
          style={{marginBottom: 0}}>
          <InputNumber placeholder={'max quantity'} style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name={'quantityUnit'} style={{marginBottom: 0}}>
          <Select style={{width: '100%'}}>
            <Select.Option value={'teu'}>TEU</Select.Option>
            <Select.Option value={'mt'}>MT</Select.Option>
            <Select.Option value={'cbm'}>CBM</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </Form.Item>
);

const SizeFormItemVesselSize: FC = () => (
  <Form.Item label={'Vessel size'} data-testid={'SizeFormItemVesselSize'}>
    <Row gutter={[4, 4]}>
      <Col span={8}>
        <Form.Item
          rules={[{min: 0, type: 'number', message: 'Min vessel size cannot be less than 0.'}]}
          name={'vesselSizeMin'}
          style={{marginBottom: 0}}>
          <InputNumber placeholder={'min vessel size'} style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{min: 0, type: 'number', message: 'Max vessel size cannot be less than 0.'}]}
          name={'vesselSizeMax'}
          style={{marginBottom: 0}}>
          <InputNumber placeholder={'max vessel size'} style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name={'vesselSizeUnit'} style={{marginBottom: 0}}>
          <Select style={{width: '100%'}}>
            <Select.Option value={'teu'}>TEU</Select.Option>
            <Select.Option value={'mt'}>MT</Select.Option>
            <Select.Option value={'dwt'}>DWT</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </Form.Item>
);
