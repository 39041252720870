import useInterval from 'beautiful-react-hooks/useInterval';
import dayjs, {Dayjs} from 'dayjs';
import {useEffect, useMemo, useState} from 'react';
import {RouteItem, useRouteTripLayer} from '../../../components/SeaboMap/layers/useRouteTripLayer/useRouteTripLayer';
import {getFirstLastTimestampsFromRouteItems} from '../../../components/SeaboMap/layers/useRouteTripLayer/utils/getFirstLastTimestampsFromRouteItems';
import {getRouteItemForDistanceCalculation} from '../../../components/SeaboMap/layers/useRouteTripLayer/utils/getRouteItemForDistanceCalculation';
import {ResultVessel} from './types';
import {TODO} from '../../../utils/TODO';

type HoveredVesselRouteLayer = {
  layer: TODO;
  timestamp: Dayjs | null;
};
export const useHoveredVesselRouteLayer = ({vessel}: {vessel?: ResultVessel}): HoveredVesselRouteLayer => {
  const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);

  const routeItems: RouteItem[] = useMemo(() => {
    const routes: RouteItem[] = [];
    if (!vessel?.distanceRoutes) {
      return [];
    }
    vessel.distanceRoutes.forEach((calculatedRoute, index) => {
      const isFirst = index === 0;
      let startTimestamp = 0;
      if (isFirst) {
        startTimestamp = Date.now();
      } else {
        startTimestamp =
          dayjs(Math.round(routes[0]?.firstLast.first))
            .add(vessel.estimatedDurationInDestinationPortInDays, 'days')
            .unix() * 1000;
      }

      routes.push(
        getRouteItemForDistanceCalculation({
          distanceCalculationResult: calculatedRoute,
          startTimestamp,
          type: index.toString(),
        })
      );
    });

    return routes;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vessel?.distanceRoutes]);

  const {first, last} = useMemo(() => {
    return getFirstLastTimestampsFromRouteItems(routeItems);
  }, [routeItems]);

  useEffect(() => {
    setCurrentTimestamp(first);
  }, [first]);

  useInterval(() => {
    if (currentTimestamp > last) {
      setCurrentTimestamp(first);
      return;
    }
    setCurrentTimestamp(currentTimestamp + 1000 * 60 * 60);
  }, 20);

  const layer = useRouteTripLayer({
    currentTime: currentTimestamp,
    id: vessel?.imo?.toString(),
    routes: routeItems,
    trailLength: 150000,
    color: (d: RouteItem) => {
      switch (d.type) {
        case '0':
          return [180, 0, 0];
        case '1':
          return [0, 180, 0];
      }
      return [180, 0, 0];
    },
  });
  if (!vessel) {
    return {
      layer: null,
      timestamp: null,
    };
  }
  return {layer, timestamp: dayjs(currentTimestamp)};
};
