import {useQueryClient} from '@tanstack/react-query';
import {NegotiationAttachment} from '../../../../../api/node-backend/generated';
import {useDeleteAttachmentMutation} from './useDeleteAttachmentMutation';
import {useDownloadAttachmentMutation} from './useDownloadAttachmentMutation';
import {useUploadAttachmentsMutation} from './useUploadAttachmentsMutation';
import {FIND_NEGOTIATION_ATTACHMENTS_BY_ID_QUERY_KEY} from './useAttachmentsQuery';
import {message} from '../../../../../components/Notification';

export type AttachmentActionsHook = () => AttachmentActions;

export type AttachmentActions = {
  downloadAttachment?: (attachment: NegotiationAttachment) => void;
  deleteAttachment?: (attachment: NegotiationAttachment) => void;
  uploadAttachments?: (files: File[]) => void;
  attachmentActionsBusy?: boolean;
};

export const attachmentActionsNoop = {
  downloadAttachment: () => {},
  deleteAttachment: () => {},
  uploadAttachments: () => {},
  attachmentActionsBusy: false,
};

export const useGetAttachmentActionsWithMutations = (
  negotiationId?: string,
  roundResultId?: string
): {
  getAttachmentActions: AttachmentActionsHook;
  attachmentActionsBusy: boolean;
} => {
  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    void queryClient.invalidateQueries({
      queryKey: FIND_NEGOTIATION_ATTACHMENTS_BY_ID_QUERY_KEY(negotiationId),
    });
  };

  const downloadAttachmentMutation = useDownloadAttachmentMutation({
    onSuccess: () => {
      invalidateQueries();
    },
  });
  const deleteAttachmentMutation = useDeleteAttachmentMutation({
    onSuccess: () => {
      void message.success(`File was removed.`);
      invalidateQueries();
    },
  });
  const uploadAttachmentsMutation = useUploadAttachmentsMutation({
    options: {
      onSuccess: uploads => {
        void message.success(`${uploads.length} files uploaded.`);
        invalidateQueries();
      },
    },
  });

  const attachmentActionsBusy =
    downloadAttachmentMutation.isPending || deleteAttachmentMutation.isPending || uploadAttachmentsMutation.isPending;

  if (!negotiationId || !roundResultId) {
    return {
      getAttachmentActions: () => attachmentActionsNoop,
      attachmentActionsBusy,
    };
  }

  const getAttachmentActions: AttachmentActionsHook = () => {
    const downloadAttachment = (attachment: NegotiationAttachment) =>
      downloadAttachmentMutation.mutate({negotiationId, attachment});
    const deleteAttachment = (attachment: NegotiationAttachment) =>
      deleteAttachmentMutation.mutate({negotiationId, attachment});
    const uploadAttachments = (files: File[]) => uploadAttachmentsMutation.mutate({negotiationId, files});

    return {
      downloadAttachment,
      deleteAttachment,
      uploadAttachments,
      attachmentActionsBusy,
    };
  };

  return {
    getAttachmentActions,
    attachmentActionsBusy,
  };
};
