import {MarketVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {UserColumn} from '../../../atoms/UserColumn/UserColumn';
import {CustomColumnDef} from '../../DataGrid/DataGrid';

const cargoPostedByAccessor = (d: $TSFixMe) =>
  d.cargo && d.cargo.user && d.cargo.user.id ? (
    <UserColumn
      userId={d.cargo.user.id}
      title={d.cargo.user.fullName}
      subTitle={d.cargo.company && d.cargo.company.name}
    />
  ) : (
    <span> {d.cargo.offer && d.cargo.offer.originSender ? d.cargo.offer.originSender : '-'} </span>
  );

export const cargoPostedByColumn = ({
  minWidth = 4,
  sortable = true,
}: {
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<MarketVessel> => ({
  header: 'Posted by',
  id: 'user',
  enableSorting: sortable,
  minWidth,
  accessorFn: (d: $TSFixMe) => cargoPostedByAccessor(d),
  cell: info => info.getValue(),
});
