import {Button, Modal} from 'antd';
import {useState} from 'react';
import styled from 'styled-components';
import {useMutation} from '@tanstack/react-query';
import {showNotification} from '../../../utils/notification';
import {vesselApi} from '../../../api/symfony/symfonyApi';
import {ReportMissingPortRequest} from '../../../api/symfony/generated/apis';

export const ReportMissingPort = ({vesselId}: {vesselId: number}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const reportMutation = useMutation({
    mutationFn: async () => {
      const request: ReportMissingPortRequest = {
        reportMissingPortNotificationRequest: {
          message: '',
        },
        vessel: vesselId,
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      vesselApi.reportMissingPort(request);
    },
    onSuccess: async () => {
      setModalVisible(false);
      showNotification('The missing port was reported.');
    },
  });
  return (
    <>
      <Modal
        open={modalVisible}
        cancelButtonProps={{disabled: reportMutation.isPending}}
        onCancel={() => {
          reportMutation.reset();
          setModalVisible(false);
        }}
        okText={'Report'}
        onOk={() => reportMutation.mutate()}
        confirmLoading={reportMutation.isPending}
        title={'Report a missing port'}>
        <p>
          You are in the process of reporting a missing port. When you click the button, we will send the selected
          vessel to our team and check if we can add or complete a port.
        </p>
        <p>Thank you for your help!</p>
      </Modal>
      <StyledButton onClick={() => setModalVisible(true)} size={'small'} type={'link'}>
        Report a missing Port
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button)`
  color: var(--color-gray-3);
  position: absolute;
  right: 16px;
  z-index: 100;
`;
