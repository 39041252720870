import dayjs from 'dayjs';
import {NegotiationSummary, NegotiationStatus} from '../../../../api/node-backend/generated';

const {Offer, Ongoing, Recap, Settled, Cancelled} = NegotiationStatus;

type NegotationBaseState = {
  roundIsEditable: boolean;
  hasReplyTime: boolean;
  hasOngoingTerms: boolean;
  hasAcceptedTerms: boolean;
  hasUnsettledSubjects: boolean;
  hasOngoingSubjects: boolean;
  hasFailedSubjects: boolean;
  isOfferSentLessThan15MinutesAgo: boolean;
};

type NegotiationActionState = {
  canAddTerms: boolean;
  canAddSubjects: boolean;
  canSendOffer: boolean;
  canCommitRound: boolean;
  canMoveToRecap: boolean;
  canMoveToSettled: boolean;
  canRetractOffer: boolean;
  canOnlyCancel: boolean;
  canReopen: boolean;
  canExportToPdf: boolean;
};

export type DerivedNegotiationState = NegotationBaseState & NegotiationActionState;

/**
 * Calculates permitted actions and state for valid, distinct input states of a negotiation (see getMockNegotiation.ts),
 * plus one for empty offers and one for a cancelled negotiation
 */
export const getDerivedNegotiationState = (negotiation: NegotiationSummary): DerivedNegotiationState => {
  const baseState = getNegotiationBaseState(negotiation);
  const actionState = getNegotiationActionState(negotiation, baseState);

  return {
    ...baseState,
    ...actionState,
  };
};

const getNegotiationBaseState = (negotiation: NegotiationSummary): NegotationBaseState => {
  const {status, latestRound} = negotiation;
  const {
    committedAt,
    replyTime,
    ongoingTerms,
    termsWithRequestedRejection,
    acceptedTerms,
    ongoingSubjects,
    subjectsWithRequestedRejection,
    acceptedSubjects,
    failedSubjects,
  } = latestRound;

  const hasReplyTime = replyTime !== null;
  const hasOngoingTerms = ongoingTerms > 0 || termsWithRequestedRejection > 0;
  const hasAcceptedTerms = acceptedTerms > 0;

  // For subjects we have to distinguish between ongoing and unsettled because when a subject is accepted, it is still not settled
  // Ongoing is okay for Recap, but not for Settled
  const hasOngoingSubjects = ongoingSubjects > 0 || subjectsWithRequestedRejection > 0;
  const hasUnsettledSubjects = ongoingSubjects > 0 || subjectsWithRequestedRejection > 0 || acceptedSubjects > 0;
  const hasFailedSubjects = failedSubjects > 0;

  const roundIsEditable = committedAt === null && ![Settled, Cancelled].includes(status);

  const isOfferSentLessThan15MinutesAgo =
    status === Ongoing &&
    negotiation.roundCount === 1 &&
    dayjs(negotiation.latestRound.committedAt).isAfter(dayjs().subtract(15, 'minutes'));

  return {
    roundIsEditable,
    hasReplyTime,
    hasOngoingTerms,
    hasAcceptedTerms,
    hasUnsettledSubjects,
    hasOngoingSubjects,
    hasFailedSubjects,
    isOfferSentLessThan15MinutesAgo,
  };
};

const getNegotiationActionState = (
  negotiation: NegotiationSummary,
  baseState: NegotationBaseState
): NegotiationActionState => {
  const {status} = negotiation;
  const {
    roundIsEditable,
    hasOngoingTerms,
    hasAcceptedTerms,
    hasUnsettledSubjects,
    hasOngoingSubjects,
    hasFailedSubjects,
    isOfferSentLessThan15MinutesAgo,
  } = baseState;

  const canOnlyCancel = roundIsEditable && hasFailedSubjects;
  const canExportToPdf = [Recap, Settled, Cancelled].includes(status);

  const noActionsAllowedState: NegotiationActionState = {
    canAddTerms: false,
    canAddSubjects: false,
    canSendOffer: false,
    canCommitRound: false,
    canMoveToRecap: false,
    canMoveToSettled: false,
    canRetractOffer: false,
    canOnlyCancel: false,
    canReopen: false,
    canExportToPdf: false,
  };

  if (!roundIsEditable) {
    return {
      ...noActionsAllowedState,
      canExportToPdf,
      canRetractOffer: isOfferSentLessThan15MinutesAgo,
      canReopen: status === Cancelled,
    };
  }

  if (canOnlyCancel) {
    return {
      ...noActionsAllowedState,
      canOnlyCancel: true,
      canExportToPdf,
    };
  }

  switch (status) {
    case Offer:
      return {
        ...noActionsAllowedState,
        canAddTerms: true,
        canAddSubjects: true,
        canSendOffer: hasOngoingTerms,
      };
    case Ongoing:
      return {
        ...noActionsAllowedState,
        canAddTerms: true,
        canAddSubjects: true,
        canCommitRound: hasOngoingTerms || hasOngoingSubjects,
        canMoveToRecap: hasAcceptedTerms && !hasOngoingTerms && !hasOngoingSubjects,
      };
    case Recap:
      return {
        ...noActionsAllowedState,
        canAddTerms: true,
        canCommitRound: hasOngoingTerms || hasUnsettledSubjects,
        canMoveToSettled: !hasOngoingTerms && !hasUnsettledSubjects,
        canExportToPdf: true,
      };
    case Settled: // roundIsEditable must be false
    case Cancelled: // roundIsEditable must be false
    default:
      throw new UnhandledNegotiationStateError(negotiation);
  }
};

class UnhandledNegotiationStateError extends Error {
  constructor(public negotiation: NegotiationSummary) {
    super('Unhandled negotiation state');
    this.name = 'UnhandledNegotiationStateError';
    // eslint-disable-next-line no-console
    console.error({
      negotiation,
    });
  }
}
