import React, {FC} from 'react';
import {cleanIdentifier} from '../../utils/helper';
import {MarginMappingProps} from '../withMargin';
import styled from 'styled-components';

interface Props extends MarginMappingProps {
  id?: string;
  children: React.ReactNode;
}

const ButtonGroup: FC<Props> = ({id = 'ButtonLabel', children}) => {
  return <StyledButtonGroup id={cleanIdentifier(id, 'button-group')}>{children}</StyledButtonGroup>;
};

export default ButtonGroup;

const StyledButtonGroup = styled.div`
  display: flex;
  flex: 1 1 auto;
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);

  > * {
    box-shadow: none !important;
  }
`;
