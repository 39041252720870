import {Card} from 'antd';
import dayjs from 'dayjs';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelProps,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {CategoricalChartProps} from 'recharts/types/chart/generateCategoricalChart';
import {Margin} from 'recharts/types/util/types';
import Lighthouse from '../../../atoms/EmptyState/LightHouse';
import LoadingComponent from '../../../components/DataGrid/LoadingComponent';
import {UserDateTime} from '../../../components/DateTime/UserDateTime';
import {useDispatch} from '../../../redux/react-redux';
import {setSelectedTimestamp} from '../../../redux/VesselDetails';
import {TODO} from '../../../utils/TODO';
import {useDraftStatsQuery} from '../../../utils/vessel/useDraftStatsQuery';
import {ReportMissingPort} from './ReportMissingPort';
import {smoothDraftItems, TimeseriesItem} from './smoothDraftItems';
import {VesselDetailsVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

type VesselGraphSpeedDraughtBaseProps = {
  statsVessel: TODO;
  statsVesselLoading: boolean;
  vessel?: VesselDetailsVessel;
  vesselId?: number;
  options?: {
    chartHeight?: number | string;
    chartMargin?: Margin;
    legendHeight?: number;
    areaLabelProps?: LabelProps & {angle: number};
    legendLabelProps?: LabelProps & {angle: number};
    dateFormat?: string;
    hideReportLink?: boolean;
    hasContainer?: boolean;
    onMouseMove?: CategoricalChartProps['onMouseMove'];
    onMouseLeave?: CategoricalChartProps['onMouseLeave'];
    customTooltip?: (props: TODO) => JSX.Element | null;
  };
};

type VesselGraphSpeedDraughtProps = VesselGraphSpeedDraughtBaseProps &
  ({vessel: VesselDetailsVessel} | {vesselId: number});

export const VesselGraphSpeedDraught = ({
  statsVessel,
  statsVesselLoading,
  vessel,
  vesselId,
  options = {},
}: VesselGraphSpeedDraughtProps) => {
  const items: TimeseriesItem[] = smoothDraftItems(statsVessel?.data?.items ?? []);
  const maxDraftQuery = useDraftStatsQuery();

  const draftItem = maxDraftQuery.data?.find(item => item.imo === vessel?.aisDetails?.imo);
  const dispatch = useDispatch();

  if (statsVesselLoading) {
    return (
      <Card>
        <LoadingComponent style={{textAlign: 'center'}} />
      </Card>
    );
  }

  const {hideReportLink, chartHeight, legendHeight} = options;

  const chartMargin = {...{top: 10, right: 0, bottom: 10, left: 0}, ...options?.chartMargin};
  const areaLabelProps: LabelProps = {...{offset: 15, position: 'insideTopLeft', angle: 90}, ...options.areaLabelProps};
  const legendLabelProps: LabelProps = {
    ...{offset: 15, position: 'insideBottomLeft', angle: -90},
    ...options.legendLabelProps,
  };

  const Container: React.FC<{children: React.ReactNode}> = options.hasContainer
    ? Card
    : ({children}) => <>{children}</>;
  const TooltipContent = options.customTooltip ?? CustomTooltip;

  const onMouseMove =
    options.onMouseMove ?? ((event: TODO) => dispatch(setSelectedTimestamp(event.activePayload?.[0]?.payload?.date)));
  const onMouseLeave = options.onMouseLeave ?? (() => dispatch(setSelectedTimestamp(undefined)));

  const reportId = (vessel?.id ?? vesselId)!;

  return (
    <Container>
      {!hideReportLink && <ReportMissingPort vesselId={reportId} />}
      <ResponsiveContainer width="100%" height={chartHeight ?? 350}>
        {statsVessel?.data?.items?.length > 0 ? (
          <ComposedChart onMouseMove={onMouseMove} onMouseLeave={onMouseLeave} data={items} margin={chartMargin}>
            {statsVessel.data.portTimeTable &&
              statsVessel.data.portTimeTable.map((port: TODO) => (
                <ReferenceArea
                  key={port.id}
                  x1={new Date(port.arrivalDate).getTime()}
                  x2={new Date(port.departureDate).getTime()}
                  y1={30}
                  y2={-30}
                  strokeOpacity={0.3}
                  stroke={'#333'}>
                  <Label value={port.port.name} {...areaLabelProps} />
                </ReferenceArea>
              ))}
            <Line type="monotone" dataKey="speed" name={'Speed Ø'} stroke="var(--color-blue)" />
            <ReferenceLine y={statsVessel.data.maxValues.speedMax} stroke="#e8e8e8" />
            <ReferenceLine y={statsVessel.data.maxValues.draughtMax} stroke="#e8e8e8" />
            <Area type="monotone" dataKey="draught" name={'Draft Ø'} stroke="#ff8e23" fill="#ff8e23" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis
              name="Date"
              domain={['dataMin', 'dataMax']}
              type="number"
              dataKey="date"
              tickFormatter={tickItem => dayjs(tickItem).format(options.dateFormat ?? 'DD.MM.YY HH:mm')}
            />
            <YAxis domain={[-30, 30]}>
              <Label value="Speed (kn) / Draft (m)" {...legendLabelProps} />
            </YAxis>
            <Tooltip content={<TooltipContent />} />
            <Legend verticalAlign="top" height={legendHeight ?? 36} />
            {draftItem && [
              <ReferenceLine key={'maxDraft'} y={-1 * draftItem.maxDraft} stroke="red">
                <Label value="Max Draft (m)" position={'bottom'} />
              </ReferenceLine>,
              <ReferenceLine key={'draft50'} y={-1 * draftItem.draft50} stroke="red">
                <Label value="50% Draft (m)" position={'top'} />
              </ReferenceLine>,
            ]}
          </ComposedChart>
        ) : (
          <Lighthouse headline={"We are sorry, we couldn't find any vessel statistic."} style={{marginTop: 0}} />
        )}
      </ResponsiveContainer>
    </Container>
  );
};

const CustomTooltip = ({active, payload, label}: TODO) => {
  // Workaround for a bug in Reacharts 2.0.3:
  // https://www.gitmemory.com/issue/recharts/recharts/2394/760807555
  if (!payload) {
    return null;
  }

  if (!active) {
    return null;
  }

  return (
    <div
      style={{
        margin: 0,
        padding: 10,
        backgroundColor: 'var(--color-white)',
        border: '1px solid #ccc',
        whiteSpace: 'nowrap',
      }}>
      <p>
        <UserDateTime value={label} format={'dateTime'} />
      </p>
      <p className="speed" style={{color: payload[0].color}}>{`Speed avg: ${payload[0].value}`}</p>
      <p className="speed-max" style={{color: payload[0].color}}>{`Speed max: ${payload[0].payload.speedMax}`}</p>
      <p className="draft" style={{color: payload[1].color}}>{`Draft avg: ${payload[1].value}`}</p>
      <p className="speed-max" style={{color: payload[1].color}}>{`Draft max: ${payload[1].payload.draughtMax}`}</p>
    </div>
  );
};
