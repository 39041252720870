/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  FastVesselImportResponse,
  FastVesselImportResponseFromJSON,
  FastVesselImportResponseToJSON,
  ParseExcelResponse,
  ParseExcelResponseFromJSON,
  ParseExcelResponseToJSON,
} from '../models';

export interface GetColumnHeadersRequest {
  companyId: string;
}

export interface ParseExcelRequest {
  file: Blob;
}

/**
 *
 */
export class FastVesselImportApi extends runtime.BaseAPI {
  /**
   * Returns all fast vessel import entries from a specific company.
   * Fast vessel imports from this company
   */
  async getColumnHeadersRaw(
    requestParameters: GetColumnHeadersRequest
  ): Promise<runtime.ApiResponse<FastVesselImportResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling getColumnHeaders.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/fast-vessel-import/backend/company/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(requestParameters.companyId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => FastVesselImportResponseFromJSON(jsonValue));
  }

  /**
   * Returns all fast vessel import entries from a specific company.
   * Fast vessel imports from this company
   */
  async getColumnHeaders(requestParameters: GetColumnHeadersRequest): Promise<FastVesselImportResponse> {
    const response = await this.getColumnHeadersRaw(requestParameters);
    return await response.value();
  }

  /**
   * The uploaded Excel file will be parsed. Returns the contained vessels and a detected IMO column.
   * Parsing Excel file
   */
  async parseExcelRaw(requestParameters: ParseExcelRequest): Promise<runtime.ApiResponse<ParseExcelResponse>> {
    if (requestParameters.file === null || requestParameters.file === undefined) {
      throw new runtime.RequiredError(
        'file',
        'Required parameter requestParameters.file was null or undefined when calling parseExcel.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{contentType: 'multipart/form-data'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request({
      path: `/node-backend/fast-vessel-import`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ParseExcelResponseFromJSON(jsonValue));
  }

  /**
   * The uploaded Excel file will be parsed. Returns the contained vessels and a detected IMO column.
   * Parsing Excel file
   */
  async parseExcel(requestParameters: ParseExcelRequest): Promise<ParseExcelResponse> {
    const response = await this.parseExcelRaw(requestParameters);
    return await response.value();
  }
}
