import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {ApiError} from '../../../../api/utils/ApiError';
import {negotiationNegotiationTemplateApi} from '../../../../api/node-backend/nodeBackendApis';
import {DeleteNegotiationTemplateRequest} from '../../../../api/node-backend/generated/apis/NegotiationNegotiationTemplateApi';

export const useDeleteNegotiationTemplateMutation = (
  options?: UseMutationOptions<void, ApiError, DeleteNegotiationTemplateRequest>
) =>
  useMutation({
    mutationFn: async ({templateId}) => {
      const result = await negotiationNegotiationTemplateApi.deleteNegotiationTemplate({templateId});
      return result;
    },
    ...options,
  });
