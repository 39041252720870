import {createSelector} from 'reselect';
import {useSelector} from '../../../../redux/react-redux';
import {InlineNumberEditor} from '../../../../atoms/InlineNumberEditor/InlineNumberEditor';
import numeral from 'numeral';
import produce from 'immer';
import {InputState} from '../utils/voyageChartercalculation/voyageChartercalculation';
import {RootState} from '../../../../redux/store';
import {EditableHeaderBox} from './EditableHeaderBox';
import {EditorFormattedValueLabel} from './EditorFormattedValueLabel';

export const seaMarginSelector = createSelector(
  (state: RootState) => state.charterCalculation.inputState.voyage.routes,
  routes => {
    let sumSeaMaringTimesDistance = 0;
    let sumDistance = 0;
    routes.forEach(route => {
      sumDistance += route.distance;
      sumSeaMaringTimesDistance += route.distance * (route.seaMargin ?? 0);
    });

    if (sumDistance === 0) {
      return 0;
    }
    return Math.round((sumSeaMaringTimesDistance / sumDistance) * 100) / 100;
  }
);

export const SeaMarginBox = (props: {inputState: InputState; setInputState: (inputState: InputState) => void}) => {
  const seaMargin = useSelector(seaMarginSelector);

  return (
    <EditableHeaderBox
      childWidth={'80%'}
      hideRadiusRight
      title="Sea margin"
      renderInput={(editing, setEditMode) => (
        <InlineNumberEditor
          size={'large'}
          closeOnBlur={false}
          editing={editing}
          onEditingChanged={setEditMode}
          closeOnEnter={true}
          editModeAvailable={false}
          editButtonAlignment="text-bottom"
          value={seaMargin}
          formattedValue={
            <EditorFormattedValueLabel>{numeral(seaMargin).format('0,0[.]00')}%</EditorFormattedValueLabel>
          }
          onChange={seaMargin => {
            props.setInputState(
              produce(props.inputState, draftInput => {
                draftInput.voyage.routes.forEach(route => {
                  route.seaMargin = seaMargin;
                });
              })
            );
          }}
        />
      )}
    />
  );
};
