import {useSearchQuery} from './useSearchQuery';
import {OptionGroups} from './AutocompleteOptions';
import {ContractType, SearchContainer, SearchHit} from '../../api/symfony/generated';
import {UseQueryResult} from '@tanstack/react-query';
import {CargoOption} from './Type';
import {makeCargoOption} from './makeOption/makeCargoOption';

export type CargoPickerIndexNames = 'cargoes_portfolio' | 'cargoes_market';

type Params = {
  searchTag: string;
  filter?: (locationItem: SearchHit) => boolean;
  indexNames?: CargoPickerIndexNames | CargoPickerIndexNames[];
  paramContractType?: ContractType;
};

export type OptionsFromCargoSearch = {
  options: OptionGroups<CargoOption>;
  searchQuery: UseQueryResult<SearchContainer, Error>;
};

/**
 * Performs a search request for the given searchTag and returns
 * the result as options for an Antd <AutoComplete> component.
 */
export const useOptionsFromCargoSearch = (params: Params): OptionsFromCargoSearch => {
  const {searchTag, paramContractType} = params;
  const indexNames = Array.isArray(params.indexNames)
    ? params.indexNames
    : [params.indexNames ?? 'cargoes_market,cargoes_portfolio'];

  const indexNamesString = indexNames.join(',');

  const searchQuery = useSearchQuery({searchTag, indexNames: indexNamesString, paramContractType});
  if (!searchQuery.isSuccess) {
    return {
      options: [],
      searchQuery,
    };
  }

  const marketCargoOptions: CargoOption[] = (searchQuery.data.data.items.cargoes_market?.items ?? [])
    .filter(item => (params.filter ? params.filter(item) : true))
    .map(makeCargoOption);
  const portfolioCargoOptions: CargoOption[] = (searchQuery.data.data.items.cargoes_portfolio?.items ?? [])
    .filter(item => (params.filter ? params.filter(item) : true))
    .map(makeCargoOption);

  const optionGroups: OptionGroups<CargoOption> = [];
  if (marketCargoOptions.length > 0) {
    optionGroups.push({
      label: 'Market Cargoes',
      options: marketCargoOptions,
    });
  }
  if (portfolioCargoOptions.length > 0) {
    optionGroups.push({
      label: 'My Cargoes',
      options: portfolioCargoOptions,
    });
  }

  return {options: optionGroups, searchQuery};
};
