/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AisRouteResponse,
  AisRouteResponseFromJSON,
  AisRouteResponseToJSON,
  AisVesselDetailsApiModel,
  AisVesselDetailsApiModelFromJSON,
  AisVesselDetailsApiModelToJSON,
  AisVesselResponse,
  AisVesselResponseFromJSON,
  AisVesselResponseToJSON,
} from '../models';

export interface GetAisVesselsRequest {
  format?: string;
  updatedAtMaxAge?: string;
  subfleetIds?: number;
}

export interface GetAppApiMapGetvesselaisdetailsRequest {
  imo: string;
}

export interface GetRouteRequest {
  imo: number;
}

/**
 *
 */
export class MapApi extends runtime.BaseAPI {
  /**
   * Finds vessels that should be displayed on the map.
   */
  async getAisVesselsRaw(requestParameters: GetAisVesselsRequest): Promise<runtime.ApiResponse<AisVesselResponse>> {
    const queryParameters: any = {};

    if (requestParameters.format !== undefined) {
      queryParameters['format'] = requestParameters.format;
    }

    if (requestParameters.updatedAtMaxAge !== undefined) {
      queryParameters['updatedAtMaxAge'] = requestParameters.updatedAtMaxAge;
    }

    if (requestParameters.subfleetIds !== undefined) {
      queryParameters['subfleetIds'] = requestParameters.subfleetIds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/map/ais-vessels`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AisVesselResponseFromJSON(jsonValue));
  }

  /**
   * Finds vessels that should be displayed on the map.
   */
  async getAisVessels(requestParameters: GetAisVesselsRequest): Promise<AisVesselResponse> {
    const response = await this.getAisVesselsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getAppApiMapGetvesselaisdetailsRaw(
    requestParameters: GetAppApiMapGetvesselaisdetailsRequest
  ): Promise<runtime.ApiResponse<AisVesselDetailsApiModel>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getAppApiMapGetvesselaisdetails.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/map/vessels/{imo}`.replace(`{${'imo'}}`, encodeURIComponent(String(requestParameters.imo))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AisVesselDetailsApiModelFromJSON(jsonValue));
  }

  /**
   */
  async getAppApiMapGetvesselaisdetails(
    requestParameters: GetAppApiMapGetvesselaisdetailsRequest
  ): Promise<AisVesselDetailsApiModel> {
    const response = await this.getAppApiMapGetvesselaisdetailsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the latest route of a vessel.
   */
  async getRouteRaw(requestParameters: GetRouteRequest): Promise<runtime.ApiResponse<AisRouteResponse>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getRoute.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/map/vessel/{imo}/history`.replace(`{${'imo'}}`, encodeURIComponent(String(requestParameters.imo))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AisRouteResponseFromJSON(jsonValue));
  }

  /**
   * Returns the latest route of a vessel.
   */
  async getRoute(requestParameters: GetRouteRequest): Promise<AisRouteResponse> {
    const response = await this.getRouteRaw(requestParameters);
    return await response.value();
  }
}
