import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {SpotifindActions} from '../../redux/Spotifind';
import {useSelector} from '../../redux/react-redux';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';

export const SyncMapWithGridCheckbox: FC = () => {
  const {syncMapWithGrid} = useSelector(state => state.spotifind);
  const dispatch = useDispatch();

  return (
    <ScreenHeader.Features.Toggle
      data-cy="SpotifindSyncMapWithGridCheckbox"
      value={syncMapWithGrid}
      onChange={event => {
        dispatch(SpotifindActions.setSyncMapWithGrid(event.target.value));
      }}
      options={[
        {
          label: 'Sync map with grid',
          value: true,
        },
        {
          label: 'No sync',
          value: false,
        },
      ]}
    />
  );
};
