import {useEffect, useMemo, FC} from 'react';
import styled from 'styled-components';
import {ProjectCargo} from '../../types';
import {getCargoLayer} from './getCargoLayer';
import {SeaboMap} from '../../../SeaboMap/SeaboMap';
import {useMapApi} from '../../../SeaboMap/useMapApi';
import {useProjectDetails} from '../../useProjectDetails';
import {useGetCoordinatesFromLocation} from '../../../../screens/CargoDetail/CargoDetailHeader/useGetCargoRouteLayer';
import ProjectService from '../../../../redux/ApiService/ProjectService';
import {useQuery} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import {useAisVesselsQuery} from '../../../../queries/useAisVesselsQuery';
import PulsatingCirclesLayer from '../../../SeaboMap/layers/mapbox/PulsatingCirclesLayer';
import {Record} from 'immutable';
import {AisVessel} from '../../../../api/symfony/generated';
import {Coordinates, LonLatCoordinates, transformObjectFromLonLat} from '../../../../utils/Coordinates';
import {TODO} from '../../../../utils/TODO';
import {getBoundsFromCoordinates} from '../../../SeaboMap/utils/getBoundsFromCoordinates';
import {MapControlButton} from '../../../SeaboMap/MapContext/Types';

type ProjectCargoMapProps = {
  cargo: ProjectCargo;
  selectedShortlistItemId: number | null;
  onShortlistItemClick?: (id: number) => void;
};

export const ProjectCargoMap: FC<ProjectCargoMapProps> = ({cargo, selectedShortlistItemId, onShortlistItemClick}) => {
  const {
    project: {id},
  } = useProjectDetails();

  const dispatch = useDispatch();
  const mapApi = useMapApi();
  const aisVesselsQuery = useAisVesselsQuery();
  const {getCoordinatesFromLocation} = useGetCoordinatesFromLocation();

  const shortListQuery = useQuery({
    queryKey: ['shortlist', id],
    queryFn: async () => {
      const result = await dispatch(ProjectService.getShortlistVessels(id));
      return result.data as {vessel: {id: number; imo: number}}[];
    },
    enabled: !!id,
  });
  const vesselsToVisualize = useMemo(() => {
    const aisVesselMap: Record<number, AisVessel> = {};
    aisVesselsQuery.data?.forEach(vessel => {
      if (!vessel.imo) {
        throw new Error('Vessel without imo');
      }
      aisVesselMap[vessel.imo] = vessel;
    });
    const vesselsWithCoordinates =
      shortListQuery.data?.map(entry => {
        return {
          ...aisVesselMap[entry.vessel.imo],
          ...entry.vessel,
        };
      }) ?? [];
    return vesselsWithCoordinates.filter(vessel => vessel?.coordinates?.length === 2);
  }, [shortListQuery.data, aisVesselsQuery.data]);

  const selectedVessel = useMemo(() => {
    if (!selectedShortlistItemId) {
      return null;
    }
    return vesselsToVisualize.find(vessel => vessel.id === selectedShortlistItemId);
  }, [vesselsToVisualize, selectedShortlistItemId]);

  const {layers, cargoCoordinates} = useMemo(
    () =>
      getCargoLayer({
        cargo,
        getCoordinatesFromLocation,
      }),
    [cargo, getCoordinatesFromLocation]
  );

  const mapBoxLayers = [];
  if (selectedVessel) {
    mapBoxLayers.push(
      <PulsatingCirclesLayer
        key={1}
        points={[transformObjectFromLonLat(selectedVessel.coordinates as LonLatCoordinates)]}
        color={'yellow'}
      />
    );
  }

  const fitBounds = () => {
    const coords: Coordinates[] = [];
    vesselsToVisualize.forEach(v => {
      coords.push(v.coordinates! as Coordinates);
    });
    coords.push(...cargoCoordinates);
    if (coords.length === 0) {
      return;
    }

    mapApi.fitBounds(getBoundsFromCoordinates(coords));
  };

  useEffect(() => {
    if (!mapApi.state.mapReady || !shortListQuery.isSuccess) {
      return;
    }

    fitBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapApi.state.mapReady, vesselsToVisualize, shortListQuery.isSuccess]);

  return (
    <MapContainer>
      <SeaboMap
        mapControlButtonsToHide={[MapControlButton.VESSELS, MapControlButton.CARGOES, MapControlButton.PORTS]}
        mapApi={mapApi}
        vesselsToVisualize={{
          data: vesselsToVisualize,
          opacity: 1,
          constantIconSize: 6,
          onClick: element => {
            const id = (element?.object as TODO)?.id;
            if (id) {
              onShortlistItemClick?.(id);
            }
          },
        }}
        initialMapSettings={{
          mapStyle: 'SAT',
        }}
        layers={layers}
        initialViewState={{
          zoom: 0.1,
          minZoom: 0,
          maxZoom: 12,
        }}
        settingsButtonVisible={false}
        lassoSelectionEnabled={false}
        saveMapState={false}
        layersOnTopOfMapBox={mapBoxLayers}
      />
    </MapContainer>
  );
};

const MapContainer = styled.div`
  height: 100%;
  min-height: 400px;

  .seabo-mapContainer {
    border-radius: var(--border-radius-card);
  }
`;
