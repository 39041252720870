import styled from 'styled-components';
import React, {FC, CSSProperties} from 'react';

export interface Props {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

export const BasicBox: FC<Props> = props => {
  return (
    <Container className={props.className}>
      <ChildrenBox
        data-testid={'labelLoggedFilters'}
        className={props.className}
        style={props.style}
        children={props.children}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border: none;
  margin-bottom: 10px;
  &.right {
    justify-content: flex-end;
  }
`;
const ChildrenBox = styled.div<Props>`
  border: 0.8px solid transparent;
  box-sizing: border-box;
  width: 50%;
  height: 22px;
  padding-left: 8px;
  padding-right: 8px;

  &.right {
    text-align: right;
  }
`;
