import {FC, MouseEventHandler, CSSProperties, ReactNode} from 'react';
import {cleanIdentifier} from '../../utils/helper';
import {ReactComponent} from '../../utils/getElementType';
import {withMarginMapping, MarginMappingProps} from '../../components/withMargin';
import classNames from 'classnames';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {MutuallyExclusiveProps, mutuallyExclusivePropsCheck} from '../../utils/MutuallyExclusiveProps';
import './style.scss';

export type ButtonSize = 'large' | 'small';
export type ButtonColorProp = 'primary' | 'secondary' | 'color';
export type ButtonColor = 'red' | 'green' | 'blue' | 'gray' | 'transparent';

export type ButtonProps = {
  id?: string;
  overrideId?: string;
  disabled?: boolean;
  wide?: boolean;
  upper?: boolean;
  block?: boolean;
  bold?: boolean;
  round?: boolean;
  outline?: boolean;
  flexCenter?: boolean;
  styleOverride?: CSSProperties;
  style?: CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  as?: ReactComponent;
  icon?: boolean;
  className?: string;
  dataTestId?: string;
  'data-testid'?: string;
  dataCy?: string;
  'data-cy'?: string;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
} & MarginMappingProps &
  MutuallyExclusiveProps<ButtonSize, boolean> &
  MutuallyExclusiveProps<ButtonColorProp, boolean | ButtonColor> &
  MutuallyExclusiveProps<'label' | 'children', ReactNode> &
  MutuallyExclusiveProps<'to' | 'href', string>;

/** @deprecated use AntD Button instead */
export const Button: FC<ButtonProps> = ({
  id,
  primary,
  label,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled,
  large,
  small,
  secondary,
  wide,
  upper,
  block,
  bold,
  color,
  round,
  styleOverride,
  outline,
  flexCenter,
  overrideId,
  icon,
  href,
  to,
  type = 'button',
  className,
  ...rest
}) => {
  mutuallyExclusivePropsCheck({large, small});
  mutuallyExclusivePropsCheck({primary, secondary, color});
  mutuallyExclusivePropsCheck({label, children});
  mutuallyExclusivePropsCheck({to, href});
  const dataCy = rest.dataCy ?? rest['data-cy'];
  const dataTestId = rest.dataTestId ?? rest['data-testid'];

  const [spaceClasses] = withMarginMapping(rest);
  const css = classNames(
    {
      ButtonIcon: icon,
      primary,
      secondary,
      large,
      wide,
      small,
      disabled,
      upper,
      block,
      bold,
      round,
      outline,
      flexCenter,
      ButtonColorRed: color === 'red',
      ButtonColorBlue: color === 'blue',
      ButtonColorGreen: color === 'green',
      ButtonColorGray: color === 'gray',
      ButtonColorTransparent: color === 'transparent',
    },
    className,
    spaceClasses
  );

  return (
    <StyledButton
      type={type}
      data-testid={dataTestId}
      data-cy={dataCy}
      id={overrideId || cleanIdentifier(id, 'button')}
      style={styleOverride}
      onClick={!disabled ? onClick : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={css}>
      <LinkWrapperComponent to={to} href={href}>
        {label || children}
      </LinkWrapperComponent>
    </StyledButton>
  );
};

type LinkWrapperProps = {
  to?: string;
  href?: string;
  className?: string;
  dataTestId?: string;
  'data-testid'?: string;
  dataCy?: string;
  'data-cy'?: string;
  children: ReactNode;
};

export const LinkWrapperComponent: FC<LinkWrapperProps> = props => {
  const dataCy = props.dataCy ?? props['data-cy'];
  const dataTestId = props.dataTestId ?? props['data-testid'];

  const {children} = props;
  if (props.to && props.href) {
    throw new Error('You cannot use both href and to props at the same time');
  }
  if (props.to) {
    return (
      <Link to={props.to} className={props.className} data-cy={dataCy} data-testid={dataTestId}>
        {children}
      </Link>
    );
  }
  if (props.href) {
    return (
      <a href={props.href} className={props.className} data-cy={dataCy} data-testid={dataTestId}>
        {children}
      </a>
    );
  }
  return (
    <span className={props.className} data-cy={dataCy} data-testid={dataTestId}>
      {children}
    </span>
  );
};

const StyledButton = styled.button`
  font-size: var(--font-size-md);
  background-color: var(--color-white);
  border-radius: var(--border-radius-button);
  border: 1px solid transparent;
  color: var(--color-gray-2);
  cursor: pointer;
  display: inline-block;
  outline: none;
  text-align: center;
  width: fit-content;
  line-height: 18px;
  padding: 8px 18px;

  a {
    background: none;
    color: inherit;
    font-size: inherit;
    text-decoration: none;
    width: 100%;
    height: 100%;
  }

  .icon {
    color: inherit !important;
  }

  &.ButtonIcon {
    color: var(--color-blue);
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 700;
  }

  &.large {
    font-size: var(--font-size-md);
    line-height: 16px;
    text-align: center;
    padding: 13px 34px;
  }

  &.small {
    font-size: var(--font-size-md);
    line-height: 14px;
    text-align: center;
    padding: 5px 8px;
  }

  &.wide {
    padding-left: 50px;
    padding-right: 50px;
  }

  &.upper {
    text-transform: uppercase !important;
  }

  &.block {
    width: 100%;
  }

  &.bold {
    font-weight: 700;
  }

  &.ButtonColorRed {
    background-color: var(--color-red);
    color: var(--color-white-fixed) !important;
    box-shadow: var(--box-shadow-button);

    &:hover,
    &:active {
      background-color: var(--color-red-dark);
    }
  }

  &.ButtonColorBlue,
  &.primary {
    background-color: var(--color-blue);
    color: var(--color-white-fixed) !important;
    box-shadow: var(--box-shadow-button);

    &:hover,
    &:active {
      background-color: var(--color-blue-dark);
      color: var(--color-white-fixed) !important;
    }
  }

  &.ButtonColorTransparent {
    background-color: transparent;

    &.outline {
      box-shadow: var(--box-shadow-button);
    }
    &:hover,
    &.small:hover {
      background-color: var(--color-azure);
      border-color: var(--color-azure);
      color: var(--color-white-fixed) !important;
    }
  }

  &.ButtonColorGray,
  &.secondary {
    background-color: var(--color-gray-6);
    border-color: var(--border-color);
    color: var(--color-gray-1) !important;
    box-shadow: var(--box-shadow-button);

    &.disabled {
      opacity: 0.33;
    }
  }

  &.ButtonColorGreen {
    background-color: var(--color-green);
    color: var(--color-white-fixed) !important;
    box-shadow: var(--box-shadow-button);

    &:active,
    &:hover {
      background-color: var(--color-green-dark) !important;
    }
  }

  &.round {
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0;
  }

  &.outline {
    border: var(--border-base);
  }

  &.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.disabled {
    border: var(--border-base);
    cursor: not-allowed;
    opacity: 0.75;
  }
`;
