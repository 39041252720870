import {useDispatch, useSelector} from '../../../redux/react-redux';
import React, {useState} from 'react';
import {Button, Dropdown, Menu} from 'antd';
import {ModalActions} from '../../../redux/Modal';
import {ClipboardItem, GridClipboardActions} from '../../../redux/GridClipboard';
import {AddToSubPortfoliosModal} from '../../../components/Modal/Multiselect/AddToSubPortfoliosModal';
import {MultiRemoveSubPortfolio} from '../../../components/Modal/Multiselect/MultiRemoveSubPortfolio';
import {useUser} from '../../../components/UserContext/useUser';
import MultiMoveToWorkspace from '../../../components/Modal/Multiselect/MoveToWorkspace';
import PortfolioColorEdit from '../../../components/Modal/PortfolioColorEdit';
import MultiArchive from '../../../components/Modal/Multiselect/MultiArchive';

type Props = {
  archive?: boolean;
  items: ClipboardItem[];
  onReloadGrid: (pageIndex?: number) => void;
};

export const MyCargoesActionButton = (props: Props) => {
  const [addToSubPortfoliosVisible, setAddToSubPortfoliosVisible] = useState(false);
  const [show, setShow] = useState(false);

  const count = props.items.length;

  if (props.items.length === 0) {
    return null;
  }

  return (
    <>
      <Dropdown
        trigger={['click']}
        onOpenChange={visible => {
          setShow(visible);
        }}
        open={show}
        menu={{
          items: [
            {
              key: '1',
              label: (
                <DropdownMenu
                  items={props.items}
                  archive={props.archive}
                  onReloadGrid={props.onReloadGrid}
                  onClick={() => {
                    setShow(false);
                  }}
                  onAddToSubPortfolios={() => {
                    setAddToSubPortfoliosVisible(true);
                  }}
                />
              ),
            },
          ],
        }}>
        <Button className="uppercase" type={'primary'}>
          {count} {count === 1 ? 'cargo' : 'cargoes'} selected
        </Button>
      </Dropdown>
      {addToSubPortfoliosVisible && (
        <AddToSubPortfoliosModal
          type="cargo"
          items={props.items}
          onClose={() => {
            setAddToSubPortfoliosVisible(false);
          }}
          onOk={() => {
            props.onReloadGrid();
          }}
        />
      )}
    </>
  );
};

type DropdownMenuProps = Props & {
  onClick: () => void;
  onAddToSubPortfolios: () => void;
};

const DropdownMenu = (props: DropdownMenuProps) => {
  const dispatch = useDispatch();
  const currentUser = useUser();
  const user = currentUser.payload;
  const activeTab = useSelector(state => state.portfolio.screenState.activeTabCargo);

  const [showMultiRemovePortfolioModal, setShowMultiRemovePortfolioModal] = useState(false);

  return (
    <>
      <MultiRemoveSubPortfolio
        onCancel={() => {
          setShowMultiRemovePortfolioModal(false);
        }}
        onSubmit={() => {
          setShowMultiRemovePortfolioModal(false);
          props.onReloadGrid(1);
        }}
        visible={showMultiRemovePortfolioModal}
        subsection={'cargoes'}
      />
      <Menu
        selectedKeys={[]}
        items={[
          {
            key: 'moveToWorkspace',
            disabled: props.archive || (user && user.company === null),
            label: 'Move to workspace',
            onClick: () => {
              dispatch(
                ModalActions.show(
                  <MultiMoveToWorkspace
                    type={'portfolioCargo'}
                    data={props.items}
                    reload={() => props.onReloadGrid(1)}
                    multiItems={true}
                  />
                )
              );
              props.onClick();
            },
          },
          {
            key: 'addToSubPortfolios',
            label: 'Add to cargo lists',
            disabled: props.archive,
            onClick: () => {
              props.onAddToSubPortfolios();
              props.onClick();
            },
          },
          ...(activeTab !== 'all'
            ? [
                {
                  key: 'removeFromSubPortfolios',
                  disabled: props.archive,
                  label: 'Remove from cargo lists',
                  onClick: () => {
                    setShowMultiRemovePortfolioModal(true);
                    props.onClick();
                  },
                },
              ]
            : []),
          {
            key: 'archive',
            label: !props.archive ? 'Archive cargoes' : 'Restore cargoes',
            onClick: () => {
              dispatch(
                ModalActions.show(
                  <MultiArchive
                    archive={props.archive!}
                    type="cargo"
                    targets={props.items}
                    section="portfolio"
                    reload={() => props.onReloadGrid(1)}
                  />
                )
              );
              props.onClick();
            },
          },
          {
            key: 'setColorMaker',
            disabled: props.archive,
            label: 'Set colour marker',
            onClick: () => {
              dispatch(
                ModalActions.show(
                  <PortfolioColorEdit type={'multiCargo'} targets={props.items} reload={props.onReloadGrid} />
                )
              );
              props.onClick();
            },
          },
          {
            key: 'unselect',
            disabled: props.archive,
            label: 'Unselect all cargoes',
            onClick: () => {
              dispatch(GridClipboardActions.removeAllItemsFromClipboard('cargoes', 'portfolio'));
              props.onClick();
            },
          },
        ]}
      />
    </>
  );
};
