import {components} from 'react-select';

const StationSelectMenuList = (props: $TSFixMe) => {
  const noOptions = props.options.reduce((tally: $TSFixMe, curr: $TSFixMe) => {
    if (!curr.isOverViewPageLink && curr.options) {
      return tally + curr.options.length;
    }
    return tally;
  }, 0);
  return (
    <components.MenuList {...props}>
      {props.options.length > 0 && noOptions === 0 ? (
        <div className={'default-options-container__no-options'}>No options</div>
      ) : (
        props.children
      )}
    </components.MenuList>
  );
};

export default StationSelectMenuList;
