import dayjs, {Dayjs} from 'dayjs';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {fetchJSON} from '../../../api/utils/fetchJSON';
import {TODO} from '../../../utils/TODO';

const multiNewlineRegex = /(\r?\n)+/;

export type CircularEmail = {
  text: string;
  sender: string;
  subject: string;
  postedAt: Dayjs;
};

export const useCircularEmailQuery = (
  {id, type}: {id?: number; type: 'cargo' | 'vessel'},
  options?: Omit<UseQueryOptions<CircularEmail>, 'queryKey'>
) =>
  useQuery<CircularEmail>({
    queryKey: ['circularEmail', id, type],
    queryFn: async () => {
      const data = await fetchJSON<TODO>(`/api/market/${type}-offers/${id}`);
      const text = (data.rawMessage.text as string).replace(multiNewlineRegex, '\n');

      const result: CircularEmail = {
        text,
        sender: data.rawMessage.sender,
        subject: data.rawMessage.subject,
        postedAt: dayjs(data.createdAt),
      };
      return result;
    },
    ...options,
  });
