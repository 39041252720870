import {numberUnitFormat} from '../../utils/formatter';
import {VesselName} from './VesselName';
import './style.scss';

export const vesselDisplay = (vessel: $TSFixMe, options?: {exName: []}) => {
  const isContainer = vessel.vesselType === 'container';
  const teu = vessel.intakes?.find((d: $TSFixMe) => d.type === 'teu') || null;
  const teu14 = vessel.intakes?.find((d: $TSFixMe) => d.type === 'teu14') || null;
  const exName = options ? options.exName?.find((f: $TSFixMe) => f) : null;

  return (
    <div className={'dropdown-display'}>
      <span className={'dropdown-display__title'}>
        <VesselName vessel={vessel} exName={exName} />
      </span>
      <span className={'dropdown-display__sub-title'}>
        (Size:{' '}
        {
          // eslint-disable-next-line  no-nested-ternary
          isContainer
            ? // eslint-disable-next-line  no-nested-ternary
              teu
              ? numberUnitFormat(teu.quantity, 'teu')
              : teu14
              ? numberUnitFormat(teu14.quantity, 'teu14')
              : 'N/A'
            : numberUnitFormat(vessel.dwtSummer, 'dwt')
        }
        , Owner: {vessel.managementManagingOwner || 'N/A'}, Type:{' '}
        {vessel.vesselType ? vessel.vesselType.toUpperCase() : 'N/A'})
      </span>
    </div>
  );
};
