import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';

export interface DatabaseType {
  contractTypes: string[];
  isExtension: boolean;
  isOptionDeclared: boolean;
}

export interface InternalType {
  VC: boolean;
  TCT: boolean;
  PC: boolean;
  BC: boolean;
  SALE: boolean;
  isExtension: boolean;
  isOptionDeclared: boolean;
}

export const Defaults: InternalType = {
  VC: false,
  TCT: false,
  PC: false,
  BC: false,
  SALE: false,
  isExtension: false,
  isOptionDeclared: false,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    VC: database.contractTypes.includes('VC'),
    TCT: database.contractTypes.includes('TCT'),
    PC: database.contractTypes.includes('PC'),
    BC: database.contractTypes.includes('BC'),
    SALE: database.contractTypes.includes('SALE'),
    isOptionDeclared: database.isOptionDeclared,
    isExtension: database.isExtension,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  return {
    contractTypes: ['VC', 'TCT', 'PC', 'BC', 'SALE'].filter(field => internal[field as keyof InternalType]),
    isExtension: internal.isExtension,
    isOptionDeclared: internal.isOptionDeclared,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Type',
  branch: 'fixtureType',
  defaults: Defaults,

  fromDatabase,
  toDatabase,
});
