import sortBy from 'lodash/sortBy';
import {VesselVoyageTimelineResponse} from '../../../api/node-backend/generated';
import {TimelineItemPort, TimelineItemType} from '../VoyageTimeline/utils/types';
import {sortByDate} from '../VoyageTimeline/utils/calculateVoyageTimeline';

export const calculateLastDestinations = (
  timelineEvents?: VesselVoyageTimelineResponse
): {
  lastDestinations: TimelineItemPort[];
} => {
  const lastDestinations = sortBy(
    timelineEvents?.portVisits,
    sortByDate(event => event.enterTimestamp, false)
  )
    .slice(0, 5)
    .map(
      (portVisit, index): TimelineItemPort => ({
        index: index + 1,
        type: TimelineItemType.Port,
        portVisit,
        enterTimestamp: portVisit.enterTimestamp,
        exitTimestamp: portVisit.exitTimestamp ?? new Date(),
        subEvents: [],
      })
    );

  return {
    lastDestinations,
  };
};
