import {InputWithDataFieldId} from '../../../utils/InputWithDataFieldId';
import React, {ReactNode} from 'react';
import {Button, Popover, Tooltip} from 'antd';
import styled from 'styled-components';
import {CalcTimeLinePoint} from '../../../VoyageTypes';
import {usePortDAForVoyagePointQuery} from '../usePortDAForVoyagePointQuery';

export const PortDAField = ({
  area,
  value,
  legItem,
  onChange,
}: {
  area: string;
  legItem: CalcTimeLinePoint;

  onChange?: (value: string) => void;

  value?: string;
}) => {
  return (
    <ApplyTooltip
      legItem={legItem}
      onChange={value => {
        onChange?.(value.toString());
      }}>
      <InputWithDataFieldId
        value={value}
        onChange={event => onChange?.(event.target.value)}
        area={area}
        field={'portCosts'}
        style={{width: 110}}
        type={'number'}
        prefix={'$'}
      />
    </ApplyTooltip>
  );
};

const ApplyTooltip = ({
  onChange,
  legItem,
  children,
}: {
  onChange: (value: number) => void | undefined;
  legItem: CalcTimeLinePoint;
  children: ReactNode;
}) => {
  const portDAQuery = usePortDAForVoyagePointQuery(legItem);

  if (!portDAQuery.isSuccess) {
    return <>{children}</>;
  }

  return (
    <Popover
      open={true}
      placement="bottom"
      content={
        <Tooltip
          overlayInnerStyle={{
            width: '380px',
            whiteSpace: 'pre-wrap',
          }}
          placement={'bottom'}
          title={
            <div>
              <table
                style={{
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: 16,
                }}>
                {portDAQuery.data.items.map(item => (
                  <tr>
                    <td>{item.description}</td>
                    <td style={{textAlign: 'right'}}>$ {item.cost}</td>
                  </tr>
                ))}
                <tr>
                  <td>Total</td>
                  <td style={{textAlign: 'right'}}>$ {portDAQuery.data.totalCost}</td>
                </tr>
              </table>
              Apply this value by clicking below
            </div>
          }>
          <StyledButton
            onClick={() => {
              onChange(portDAQuery.data.totalCost);
            }}
            size={'small'}
            type={'link'}>
            Apply ({portDAQuery.data.totalCost} $)
          </StyledButton>
        </Tooltip>
      }>
      {children}
    </Popover>
  );
};

const StyledButton = styled(Button)`
  padding: 0px;
`;
