/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Reference,
  ReferenceFromJSON,
  ReferenceFromJSONTyped,
  ReferenceToJSON,
  Role,
  RoleFromJSON,
  RoleFromJSONTyped,
  RoleToJSON,
} from './';

/**
 *
 * @export
 * @interface AccountCreateRequest
 */
export interface AccountCreateRequest {
  /**
   *
   * @type {Reference}
   * @memberof AccountCreateRequest
   */
  company: Reference;
  /**
   *
   * @type {string}
   * @memberof AccountCreateRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AccountCreateRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AccountCreateRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AccountCreateRequest
   */
  newPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountCreateRequest
   */
  timezone: string;
  /**
   *
   * @type {Array<Role>}
   * @memberof AccountCreateRequest
   */
  roles?: Array<Role>;
  /**
   *
   * @type {boolean}
   * @memberof AccountCreateRequest
   */
  isDisabled?: boolean | null;
}

export function AccountCreateRequestFromJSON(json: any): AccountCreateRequest {
  return AccountCreateRequestFromJSONTyped(json, false);
}

export function AccountCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    company: ReferenceFromJSON(json['company']),
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    newPassword: !exists(json, 'newPassword') ? undefined : json['newPassword'],
    timezone: json['timezone'],
    roles: !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(RoleFromJSON),
    isDisabled: !exists(json, 'isDisabled') ? undefined : json['isDisabled'],
  };
}

export function AccountCreateRequestToJSON(value?: AccountCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    company: ReferenceToJSON(value.company),
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    newPassword: value.newPassword,
    timezone: value.timezone,
    roles: value.roles === undefined ? undefined : (value.roles as Array<any>).map(RoleToJSON),
    isDisabled: value.isDisabled,
  };
}
