import {Card} from 'antd';
import React, {ReactNode} from 'react';
import Icon, {IconType} from '../../../atoms/Icon';
import {TODO} from '../../../utils/TODO';
import './style.scss';

interface Props {
  name: string;
  business: string | null;
  city: string | null;
  country: string | null;
  vessels: TODO;
  createdAt: TODO;
  logoUrl: TODO;
  users: TODO;
}

const IconDisplay = ({icon, number, title}: {icon: IconType; number: number; title: string}) => (
  <div className={'profile-data'}>
    <Icon type={icon} className="profile-data__icon" />
    <div className={'profile-data__number'}>{number}</div>
    <div className={'profile-data__title'}>{title}</div>
  </div>
);

const ProfileDisplay = ({
  imageSrc,
  name,
  subline1,
  subline2,
}: {
  imageSrc: string;
  name: string | null;
  subline1: ReactNode;
  subline2: ReactNode;
}) => (
  <div className={'profile-display'}>
    <img src={imageSrc} className={'profile-display__image'} alt={''} />
    <div className={'display'}>
      <div className={'display__headline'}>{name}</div>
      <div className={'display__subline'}>{subline1}</div>
      <div className={'display__subline'}>{subline2}</div>
    </div>
  </div>
);

const CompanyHeader = ({name, business, city, country, vessels, createdAt, logoUrl, users}: Props) => (
  <Card className={'company-profile'}>
    <ProfileDisplay
      imageSrc={logoUrl ? logoUrl : '/static/images/seanexxt/company-placeholder.png'}
      name={name}
      subline1={business}
      subline2={
        <>
          {city} {country && `, ${country}`}
        </>
      }
    />
    <div className={'company-profile__data'}>
      <IconDisplay icon={'network'} number={users.length} title={'Users'} />
      <IconDisplay icon={'vessel-complex'} number={vessels ? vessels : 0} title={'Vessels'} />
      <IconDisplay icon={'company'} number={new Date(createdAt).getFullYear()} title={'Member since'} />
    </div>
  </Card>
);

export default CompanyHeader;
