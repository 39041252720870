import {SeaboMap} from '../../../components/SeaboMap/SeaboMap';
import React, {useEffect, FC} from 'react';
import {useMapApi} from '../../../components/SeaboMap/useMapApi';
import Port from '../../../model/Port';
import {Coordinates} from '../../../utils/Coordinates';
import {MapControlButton} from '../../../components/SeaboMap/MapContext/Types';

export const PortListMap: FC<{ports?: Port[]}> = ({ports}) => {
  const mapApi = useMapApi();

  useEffect(() => {
    if (!mapApi.state.mapReady) {
      return;
    }
    const coordsToFocus: Coordinates[] = (ports ?? []).map(port => port.coordinates as Coordinates);
    mapApi?.fitBounds(coordsToFocus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ports, mapApi.state.mapReady]);

  return (
    <SeaboMap
      settingsPersistent={false}
      showPopups={true}
      mapControlButtonsToHide={[MapControlButton.PORTS, MapControlButton.CARGOES, MapControlButton.VESSELS]}
      showPortIds={ports?.map(port => port.id)}
      mapApi={mapApi}
    />
  );
};
