import {apiErrorMiddleware} from '../utils/apiErrorMiddleware';
import {apiTransactionIdMiddleware} from '../utils/apiTransactionIdMiddleware';
import {Configuration, HolidayServiceApi} from './generated';
import {apiAccessTokenMiddleware} from '../utils/apiAccessTokenMiddleware';
import {isLocalDevEnvironment, isTest} from '../../utils/environment';

export const HolidayServiceBasePathForTest: string = 'http://localhost/dummyholidayservice';
const HolidayServiceBasePathForLocalDevelopment: string = 'https://holidays.service.beta.seabo.com';
/**
 * Returns the base path for the Holiday Service API based on the current environment.
 *
 * The function determines the appropriate base path for the Holiday Service API by checking
 * the current environment.
 */
const getHolidayServiceBasePath = (): string => {
  // Check if the current environment is a test environment.
  if (isTest()) {
    return HolidayServiceBasePathForTest;
  }
  // Check if the current environment is a local development environment.
  if (isLocalDevEnvironment()) {
    return HolidayServiceBasePathForLocalDevelopment;
  }
  // Check if the base path in the production environment is not correctly set.
  if (window.HOLIDAY_SERVICE_BASE_PATH?.startsWith('$')) {
    throw new Error(
      'Base path for the Holiday Service API is not correctly configured. ' +
        'It should not contain a placeholder value.'
    );
  }
  // Return the base path for the Holiday Service API.
  return window.HOLIDAY_SERVICE_BASE_PATH;
};

const configuration = new Configuration({
  basePath: getHolidayServiceBasePath(),
  middleware: [apiTransactionIdMiddleware, apiErrorMiddleware, apiAccessTokenMiddleware],
});

export const holidayApi = new HolidayServiceApi(configuration);
