import {VesselFormLocationState} from './VesselFormLocationState';
import {PortfolioVessel} from '../../../redux/Portfolio';
import {useNavigate} from 'react-router-dom';

type OpenVesselFormParams = {defaultVesselValues?: PortfolioVessel; id?: number};

type OpenVesselForm = ({id, defaultVesselValues}: OpenVesselFormParams) => void;

/**
 * That hook returns a function that redirects to the vessel form and initializes it with the given vessel.
 * If no id is given, it redirects to add vessel otherwise to edit vessel.
 */
export const useOpenVesselForm = (): OpenVesselForm => {
  const navigate = useNavigate();

  return ({id, defaultVesselValues}: OpenVesselFormParams) => {
    const state: VesselFormLocationState = {
      defaultVesselValues,
    };
    if (id) {
      navigate(`/vessel/${id}/edit`, {state});
    } else {
      navigate('/vessel/create', {state});
    }
  };
};
