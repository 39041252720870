/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ConsentProcessType {
  Self = 'SELF',
  SeaboTeam = 'SEABO_TEAM',
}

export function ConsentProcessTypeFromJSON(json: any): ConsentProcessType {
  return ConsentProcessTypeFromJSONTyped(json, false);
}

export function ConsentProcessTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentProcessType {
  return json as ConsentProcessType;
}

export function ConsentProcessTypeToJSON(value?: ConsentProcessType | null): any {
  return value as any;
}
