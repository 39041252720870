import {FilterParams} from '../filterParams';
import {Step3Vessel} from '../steps/step3';

export const vesselsLastPortWasSelectedPortFilter = ({
  vessels,
  filter,
}: {
  filter: FilterParams;
  vessels: Step3Vessel[];
}): Step3Vessel[] => {
  return vessels.filter(vessel => {
    if (filter.portCode && vessel.lastPort?.code !== undefined) {
      return vessel.lastPort?.code !== filter.portCode;
    }
    return true;
  });
};
