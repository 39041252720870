import {ModalActions} from '../../../redux/Modal';
import {Button} from '../../Button/Button';
import {useDispatch} from '../../../redux/react-redux';
import './style.scss';
import ManageFixtureModal from '../../../components/Modal/ManageFixture/ManageFixtureModal';
import {invalidateFixturesQuery} from '../../../components/Tools/FixtureGrid/useFixturesQuery';
import {useQueryClient} from '@tanstack/react-query';

const FixtureEmptyState = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return (
    <div id="fixture-empty-state-sub-container" className="empty-state-container-main">
      <img
        src="/static/images/seanexxt/market/empty-market-vessel.png"
        className="empty-state-container-main__image"
        alt="emptyStateFixture"
      />
      <div className={'empty-state-container-main__header'}>Fixtures</div>
      <div className={'empty-state-container-main__subline'}>Let's create your first fixture!</div>
      <Button
        id={'empty-state-action-button'}
        onClick={() => {
          dispatch(
            ModalActions.show(
              <ManageFixtureModal
                callback={() => {
                  void invalidateFixturesQuery(queryClient);
                }}
              />
            )
          );
        }}
        label={'Create fixture'}
        bold
        upper
        primary
      />
    </div>
  );
};

export default FixtureEmptyState;
