import React, {FC} from 'react';
import styled from 'styled-components';
import {getInitialsFromFullName} from './UserInitials';
import {Image} from './Image';

const FALLBACK_IMAGE = '/static/images/avatars/avatar-default.jpg';

interface UserAvatarProps {
  user: {avatar?: string | null; fullName: string};
  size?: number;
}

/**
 * Renders a user's avatar image and his initials as a fallback if there is no avatar image.
 */
export const UserAvatar: FC<UserAvatarProps> = ({user, size = 35}) => {
  const initials = getInitialsFromFullName(user.fullName);
  return (
    <UserAvatarStyled $size={size}>
      {user.avatar && <UserAvatarImg src={user.avatar!} fallbackSrc={FALLBACK_IMAGE} alt={user.fullName} />}
      <UserInitials $size={size}>{initials}</UserInitials>
    </UserAvatarStyled>
  );
};

const UserAvatarStyled = styled.div<{$size: number}>`
  display: inline-block;
  position: relative;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  border-radius: 50%;
  border: var(--border-base);
  box-shadow: var(--box-shadow-button);
  overflow: hidden;
  isolation: isolate;
`;

const UserAvatarImg = styled(Image)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: transparent;
`;

const UserInitials = styled.div<{$size: number}>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  color: var(--color-gray-2);
  background-color: var(--color-gray-5);

  font-family: Akkurat;
  font-style: normal;
  font-weight: bold;
  font-size: var(--font-size-md);
  line-height: ${props => props.$size + 2}px;
  text-align: center;
`;
