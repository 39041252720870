import {ConsumptionTransaction} from '../consumptionSequence/ConsumptionTransaction';
import {ConsumptionResult} from '../../VoyageInformation/Timeline/getConsumptionForPoint';
import {ConsumptionFuelType} from '../getUsedFuelType';

export const transformVoyageItemConsumptionToConsumptionTransactions = ({
  consumptions,
  description,
  date,
}: {
  consumptions: ConsumptionResult[];
  date: Date;
  description: string;
  isScrubberFitted: boolean;
}): ConsumptionTransaction[] => {
  const transactions = consumptions.map(consumptionItem => {
    const transaction: ConsumptionTransaction = {
      quantity: -consumptionItem.consumption,
      fuelType: consumptionItem.fuelType.toLowerCase() as ConsumptionFuelType,
      date,
      description,
    };

    return transaction;
  });

  return transactions;
};
