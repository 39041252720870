import {ReduxStore, store} from './store';
import {SidebarActions, SidebarMode, getSidebarModeFromString} from './Sidebar';
import {useEffect} from 'react';

/**
 *  This module loads some user settings from local storage into the Redux store upon application start.
 *
 */

export const SIDEBAR_MODE_KEY = 'sidebar-mode';

export const saveSidebarInLocalStorage = (sidebarMode: SidebarMode) => {
  localStorage.setItem(SIDEBAR_MODE_KEY, sidebarMode);
};

const restoreSidebar = (store: ReduxStore) => {
  const s: string | null = localStorage.getItem(SIDEBAR_MODE_KEY);
  if (!s) {
    return;
  }
  const sidebarMode: SidebarMode | undefined = getSidebarModeFromString(s);
  if (!sidebarMode) {
    return;
  }
  store.dispatch(SidebarActions.setSidebarMode(sidebarMode));
};

/**
 * Restores the Redux store from local storage, so that local user settings can survive
 * across multiple browser sessions.
 */
export const restoreReduxStore = (store: ReduxStore) => {
  restoreSidebar(store);
};

export const useReduxPersistence = () => {
  useEffect(() => {
    restoreReduxStore(store);
  }, []);
};
