/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Company,
  CompanyFromJSON,
  CompanyFromJSONTyped,
  CompanyToJSON,
  Role,
  RoleFromJSON,
  RoleFromJSONTyped,
  RoleToJSON,
} from './';

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {Company}
   * @memberof Account
   */
  company: Company;
  /**
   *
   * @type {number}
   * @memberof Account
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  avatarUrl: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  timezone: string;
  /**
   *
   * @type {Array<Role>}
   * @memberof Account
   */
  roles?: Array<Role>;
  /**
   *
   * @type {Date}
   * @memberof Account
   */
  registrationDate: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Account
   */
  lastActivity?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Account
   */
  isDisabled?: boolean | null;
}

export function AccountFromJSON(json: any): Account {
  return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    company: CompanyFromJSON(json['company']),
    id: json['id'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    avatarUrl: json['avatarUrl'],
    timezone: json['timezone'],
    roles: !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(RoleFromJSON),
    registrationDate: json['registrationDate'] === null ? null : new Date(json['registrationDate']),
    lastActivity: !exists(json, 'lastActivity')
      ? undefined
      : json['lastActivity'] === null
      ? null
      : new Date(json['lastActivity']),
    isDisabled: !exists(json, 'isDisabled') ? undefined : json['isDisabled'],
  };
}

export function AccountToJSON(value?: Account | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    company: CompanyToJSON(value.company),
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    avatarUrl: value.avatarUrl,
    timezone: value.timezone,
    roles: value.roles === undefined ? undefined : (value.roles as Array<any>).map(RoleToJSON),
    registrationDate: value.registrationDate === null ? null : value.registrationDate.toISOString(),
    lastActivity:
      value.lastActivity === undefined
        ? undefined
        : value.lastActivity === null
        ? null
        : value.lastActivity.toISOString(),
    isDisabled: value.isDisabled,
  };
}
