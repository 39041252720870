import React, {useState, FC} from 'react';
import styled from 'styled-components';
import {NegotiationRoundResultSummary, NegotiationSummary} from '../../../../../api/node-backend/generated';
import {HistoryDetail} from './Detail/HistoryDetail';
import {HistoryTimeline} from './Timeline/HistoryTimeline';
import {LoadingBanner, NegotiationCard} from '../../../Components/shared';
import {Collapse} from 'antd';

type NegotiationHistoryProps = {
  negotiation: NegotiationSummary;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  onSelectCurrentRound?: () => void;
};

export const NegotiationHistory: FC<NegotiationHistoryProps> = ({negotiation, roundResultSummaries}) => {
  const [selectedRound, setSelectedRound] = useState<NegotiationRoundResultSummary | null>(null);

  if (!roundResultSummaries) {
    return (
      <HistoryCard data-testid="NegotiationHistoryLoading">
        <LoadingBanner />
      </HistoryCard>
    );
  }

  const selectRound = (id: string | null) => {
    if (id === null || selectedRound?.id === id) {
      setSelectedRound(null);
    } else {
      const index = roundResultSummaries.findIndex(round => round.id === id);
      setSelectedRound(roundResultSummaries[index]);
    }
  };

  return (
    <HistoryCard data-testid="NegotiationHistory">
      <ContainerLabel>
        History
        {roundResultSummaries.length
          ? ` (${roundResultSummaries.length} iteration${roundResultSummaries.length !== 1 ? 's' : ''})`
          : ''}
      </ContainerLabel>
      <HistoryDetailCollapse
        activeKey={selectedRound ? '0' : undefined}
        ghost
        items={[
          {
            key: '0',
            showArrow: false,
            label: (
              <HistoryTimeline
                negotiation={negotiation}
                roundResultSummaries={roundResultSummaries}
                selectedRound={selectedRound}
                onSelectRound={selectRound}
              />
            ),
            children: selectedRound ? (
              <HistoryDetail
                key={selectedRound.id}
                negotiation={negotiation}
                selectedRound={selectedRound!}
                onDismissClick={() => selectRound(null)}
              />
            ) : null,
          },
        ]}
      />
    </HistoryCard>
  );
};

const HistoryCard = styled(NegotiationCard)`
  position: relative;
  width: var(--main-width);
  margin: 0 auto 10px;
  overflow: hidden;

  .ant-card-body {
    padding: 0;
  }
`;

const ContainerLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  width: 300px;
  color: var(--color-gray-2);
`;

const HistoryDetailCollapse = styled(Collapse)`
  width: 100%;
  background: var(--color-white);
  margin: 0;
  padding: 0;

  .ant-collapse-item {
    padding: 0;
    width: 100%;

    &.ant-collapse-item-disabled {
      .ant-collapse-header {
      }
    }

    .ant-collapse-header {
      display: block;
      width: 100%;
      padding: 0 20px !important;

      .ant-collapse-header-text {
        cursor: default;
      }
    }
    .ant-collapse-content {
      margin-top: -10px;
      z-index: -1;
    }
  }
`;
