import {useMutation} from '@tanstack/react-query';
import {widgetApi} from '../../../api/symfony/symfonyApi';
import {ApiError} from '../../../api/utils/ApiError';
import {queryClient} from '../../../api/utils/reactQueryClient';
import {InstalledWidget} from '../../../redux/DashboardWidgets/InstalledWidget';
import {WidgetSettings} from '../../../redux/DashboardWidgets/WidgetSettings';
import {invalidateWidgetQuery} from './useWidgetQuery';
import {UserWidget} from '../../../api/symfony/generated';

interface AddWidgetMutationParams {
  widget: InstalledWidget<WidgetSettings>;
}

export const useAddWidgetMutation = () => {
  return useMutation<UserWidget, ApiError, AddWidgetMutationParams>({
    mutationFn: async ({widget}: AddWidgetMutationParams) => {
      return await widgetApi.createWidget({userWidgetWrite: widget});
    },
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateWidgetQuery(queryClient);
    },
  });
};
