/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Company2,
  Company2FromJSON,
  Company2FromJSONTyped,
  Company2ToJSON,
  CompanyWorkspace2,
  CompanyWorkspace2FromJSON,
  CompanyWorkspace2FromJSONTyped,
  CompanyWorkspace2ToJSON,
  FilterCategory,
  FilterCategoryFromJSON,
  FilterCategoryFromJSONTyped,
  FilterCategoryToJSON,
  FilterType,
  FilterTypeFromJSON,
  FilterTypeFromJSONTyped,
  FilterTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface FilterItem
 */
export interface FilterItem {
  /**
   *
   * @type {number}
   * @memberof FilterItem
   */
  id: number;
  /**
   *
   * @type {FilterCategory}
   * @memberof FilterItem
   */
  category: FilterCategory;
  /**
   *
   * @type {FilterType}
   * @memberof FilterItem
   */
  type?: FilterType;
  /**
   *
   * @type {boolean}
   * @memberof FilterItem
   */
  outbox?: boolean;
  /**
   *
   * @type {string}
   * @memberof FilterItem
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof FilterItem
   */
  sortKey?: number;
  /**
   *
   * @type {object}
   * @memberof FilterItem
   */
  filterSettings?: object | null;
  /**
   *
   * @type {CompanyWorkspace2}
   * @memberof FilterItem
   */
  workspace?: CompanyWorkspace2 | null;
  /**
   *
   * @type {object}
   * @memberof FilterItem
   */
  user?: object | null;
  /**
   *
   * @type {Company2}
   * @memberof FilterItem
   */
  company?: Company2 | null;
}

export function FilterItemFromJSON(json: any): FilterItem {
  return FilterItemFromJSONTyped(json, false);
}

export function FilterItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    category: FilterCategoryFromJSON(json['category']),
    type: !exists(json, 'type') ? undefined : FilterTypeFromJSON(json['type']),
    outbox: !exists(json, 'outbox') ? undefined : json['outbox'],
    name: !exists(json, 'name') ? undefined : json['name'],
    sortKey: !exists(json, 'sortKey') ? undefined : json['sortKey'],
    filterSettings: !exists(json, 'filterSettings') ? undefined : json['filterSettings'],
    workspace: !exists(json, 'workspace') ? undefined : CompanyWorkspace2FromJSON(json['workspace']),
    user: !exists(json, 'user') ? undefined : json['user'],
    company: !exists(json, 'company') ? undefined : Company2FromJSON(json['company']),
  };
}

export function FilterItemToJSON(value?: FilterItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    category: FilterCategoryToJSON(value.category),
    type: FilterTypeToJSON(value.type),
    outbox: value.outbox,
    name: value.name,
    sortKey: value.sortKey,
    filterSettings: value.filterSettings,
    workspace: CompanyWorkspace2ToJSON(value.workspace),
    user: value.user,
    company: Company2ToJSON(value.company),
  };
}
