import {DependencyList, useCallback} from 'react';
import throttle from 'lodash/throttle';

// Here I copy from @types/lodash in order to avoid an "import {ThrottleSettings} from 'lodash'".
// I suspect that this could bundle the whole lodash package.
interface ThrottleSettings {
  /**
   * @see _.leading
   */
  leading?: boolean;
  /**
   * @see _.trailing
   */
  trailing?: boolean;
}

/**
 * Returns a throttled callback function.
 *
 * @param handler the callback function that should be throttled
 * @param deps a dependency array that is used to update the handler when any dependency changes
 * @param wait the number of milliseconds to throttle invocations to
 * @param options the options object from lodash/throttle
 * @returns the throttled function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useThrottle = <T extends (...args: any) => any>(
  handler: T,
  deps: DependencyList,
  waitTime?: number,
  options?: ThrottleSettings
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(throttle(handler, waitTime, options), deps);
};
