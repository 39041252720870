/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AisRouteResponseData,
  AisRouteResponseDataFromJSON,
  AisRouteResponseDataFromJSONTyped,
  AisRouteResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface AisRouteResponse
 */
export interface AisRouteResponse {
  /**
   *
   * @type {AisRouteResponseData}
   * @memberof AisRouteResponse
   */
  data: AisRouteResponseData;
}

export function AisRouteResponseFromJSON(json: any): AisRouteResponse {
  return AisRouteResponseFromJSONTyped(json, false);
}

export function AisRouteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisRouteResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: AisRouteResponseDataFromJSON(json['data']),
  };
}

export function AisRouteResponseToJSON(value?: AisRouteResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: AisRouteResponseDataToJSON(value.data),
  };
}
