import {CalcTimeLineList} from '../VoyageInformation/VoyageTypes';
import {InputState, OutputState} from './voyageChartercalculation/voyageChartercalculation';

interface CalcTimelineListFunc {
  inputState: InputState;
  outputState?: OutputState;
}

export const calcTimelineListFunc = (props: CalcTimelineListFunc): CalcTimeLineList => {
  const list: CalcTimeLineList = [];
  const indexFirstLoadingLeg = props.inputState.voyage.points.findIndex(point => point.types?.includes('loading'));

  props.inputState.voyage.points.forEach((item, index) => {
    list.push({
      nodeType: 'point',
      item,
      outputItem: props.outputState?.voyage.points[index],
      isFirstLoadingLeg: indexFirstLoadingLeg === index,
      indexInOldArray: index,
    });
    if (props.inputState.voyage.routes[index] !== undefined) {
      list.push({
        nodeType: 'route',
        item: props.inputState.voyage.routes[index],
        outputItem: props.outputState?.voyage.routes[index],
        indexInOldArray: index,
      });
    }
  });

  if (list[list.length - 1]?.nodeType === 'route') {
    list.pop();
  }

  return list;
};

export const calcChronicalTimeLineList = calcTimelineListFunc;

export const ModuleFunctions = {
  calcChronicalTimeLineList,
  calcTimelineListFunc,
};

export default ModuleFunctions;
