import {FuelType, VesselConsumptionMode, VesselConsumptionModeUseCase} from '../VesselInformation/VesselTypes';
import {filterVesselConsumptionModes} from './filterVesselConsumptionModes';
import {VoyageConsumption} from '../VoyageInformation/VoyageTypes';

export const getDefaultVoyageConsumption = (params: {
  useCase: VesselConsumptionModeUseCase;
  fuelType?: FuelType;
}): VoyageConsumption => {
  const mainFuelType = params.fuelType ?? 'ifo';

  return {
    mainFuelType: mainFuelType,
    auxConsumption: 0,
    auxFuelType: mainFuelType === 'ifo' ? 'mdo' : 'ifo',
    speed: 0,
    mainConsumption: 0,
    useCase: params.useCase,
    isEco: false,
  };
};

export const transformVesselConsumptionToVoyageConsumption = (
  consumptionMode: VesselConsumptionMode
): VoyageConsumption => {
  const consumption: VoyageConsumption = {
    auxConsumption: consumptionMode.auxConsumption,
    auxFuelType: consumptionMode.auxFuelType,
    mainConsumption: consumptionMode.mainConsumption,
    useCase: consumptionMode.useCase,
    mainFuelType: consumptionMode.mainFuelType,
    speed: consumptionMode.speed,
  };
  return consumption;
};

export const getVoyageConsumption = (props: {
  consumptionModes: VesselConsumptionMode[];
  useCase: VesselConsumptionModeUseCase;
  fuelType?: FuelType;
  isEco?: boolean;
}): VoyageConsumption => {
  const bestModes = filterVesselConsumptionModes({
    consumptionModes: props.consumptionModes,
    useCase: props.useCase,
    mainFuelType: props.fuelType,
    isEco: props.isEco,
  });
  if (bestModes.length === 1) {
    return transformVesselConsumptionToVoyageConsumption(bestModes[0]);
  }

  const secondBestModes = filterVesselConsumptionModes({
    consumptionModes: props.consumptionModes,
    useCase: props.useCase,
    mainFuelType: props.fuelType,
  });
  if (secondBestModes.length === 1) {
    return transformVesselConsumptionToVoyageConsumption(secondBestModes[0]);
  }
  const thirdBestModes = filterVesselConsumptionModes({
    consumptionModes: props.consumptionModes,
    useCase: props.useCase,
  });
  if (thirdBestModes.length > 0) {
    return transformVesselConsumptionToVoyageConsumption(thirdBestModes[0]);
  }

  return getDefaultVoyageConsumption({useCase: props.useCase});
};
