/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  HolidayModel,
  HolidayModelFromJSON,
  HolidayModelFromJSONTyped,
  HolidayModelToJSON,
  PointOfInterest,
  PointOfInterestFromJSON,
  PointOfInterestFromJSONTyped,
  PointOfInterestToJSON,
  PortAlias,
  PortAliasFromJSON,
  PortAliasFromJSONTyped,
  PortAliasToJSON,
  PortSize,
  PortSizeFromJSON,
  PortSizeFromJSONTyped,
  PortSizeToJSON,
  PortTerm,
  PortTermFromJSON,
  PortTermFromJSONTyped,
  PortTermToJSON,
  PortType,
  PortTypeFromJSON,
  PortTypeFromJSONTyped,
  PortTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface Port
 */
export interface Port {
  /**
   *
   * @type {string}
   * @memberof Port
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Port
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof Port
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof Port
   */
  tradingArea: string;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  reviewStatus: PortReviewStatusEnum;
  /**
   *
   * @type {PortSize}
   * @memberof Port
   */
  size: PortSize | null;
  /**
   *
   * @type {boolean}
   * @memberof Port
   */
  isTiny: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Port
   */
  isLarge: boolean;
  /**
   *
   * @type {PortType}
   * @memberof Port
   */
  portType: PortType | null;
  /**
   *
   * @type {boolean}
   * @memberof Port
   */
  polygonReviewed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Port
   */
  important: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Port
   */
  importantReviewed: boolean;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  longitude: number;
  /**
   *
   * @type {string}
   * @memberof Port
   */
  contactAddress: string | null;
  /**
   *
   * @type {string}
   * @memberof Port
   */
  contactPhone: string | null;
  /**
   *
   * @type {string}
   * @memberof Port
   */
  contactEmail: string | null;
  /**
   *
   * @type {string}
   * @memberof Port
   */
  contactWebsite: string | null;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  draftRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  beamRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  loaRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  dwtRestriction: number | null;
  /**
   *
   * @type {number}
   * @memberof Port
   */
  airdraftRestriction: number | null;
  /**
   *
   * @type {Array<HolidayModel>}
   * @memberof Port
   */
  holidays: Array<HolidayModel>;
  /**
   *
   * @type {PortTerm}
   * @memberof Port
   */
  portTerm: PortTerm | null;
  /**
   *
   * @type {Array<PointOfInterest>}
   * @memberof Port
   */
  pois?: Array<PointOfInterest>;
  /**
   *
   * @type {Array<Array<Array<number>>>}
   * @memberof Port
   */
  polygon?: Array<Array<Array<number>>>;
  /**
   *
   * @type {Array<PortAlias>}
   * @memberof Port
   */
  aliases?: Array<PortAlias>;
}

/**
 * @export
 * @enum {string}
 */
export enum PortReviewStatusEnum {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
}

export function PortFromJSON(json: any): Port {
  return PortFromJSONTyped(json, false);
}

export function PortFromJSONTyped(json: any, ignoreDiscriminator: boolean): Port {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    code: json['code'],
    country: json['country'],
    tradingArea: json['tradingArea'],
    reviewStatus: json['reviewStatus'],
    size: PortSizeFromJSON(json['size']),
    isTiny: json['isTiny'],
    isLarge: json['isLarge'],
    portType: PortTypeFromJSON(json['portType']),
    polygonReviewed: json['polygonReviewed'],
    important: json['important'],
    importantReviewed: json['importantReviewed'],
    latitude: json['latitude'],
    longitude: json['longitude'],
    contactAddress: json['contactAddress'],
    contactPhone: json['contactPhone'],
    contactEmail: json['contactEmail'],
    contactWebsite: json['contactWebsite'],
    draftRestriction: json['draftRestriction'],
    beamRestriction: json['beamRestriction'],
    loaRestriction: json['loaRestriction'],
    dwtRestriction: json['dwtRestriction'],
    airdraftRestriction: json['airdraftRestriction'],
    holidays: (json['holidays'] as Array<any>).map(HolidayModelFromJSON),
    portTerm: PortTermFromJSON(json['portTerm']),
    pois: !exists(json, 'pois') ? undefined : (json['pois'] as Array<any>).map(PointOfInterestFromJSON),
    polygon: !exists(json, 'polygon') ? undefined : json['polygon'],
    aliases: !exists(json, 'aliases') ? undefined : (json['aliases'] as Array<any>).map(PortAliasFromJSON),
  };
}

export function PortToJSON(value?: Port | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    code: value.code,
    country: value.country,
    tradingArea: value.tradingArea,
    reviewStatus: value.reviewStatus,
    size: PortSizeToJSON(value.size),
    isTiny: value.isTiny,
    isLarge: value.isLarge,
    portType: PortTypeToJSON(value.portType),
    polygonReviewed: value.polygonReviewed,
    important: value.important,
    importantReviewed: value.importantReviewed,
    latitude: value.latitude,
    longitude: value.longitude,
    contactAddress: value.contactAddress,
    contactPhone: value.contactPhone,
    contactEmail: value.contactEmail,
    contactWebsite: value.contactWebsite,
    draftRestriction: value.draftRestriction,
    beamRestriction: value.beamRestriction,
    loaRestriction: value.loaRestriction,
    dwtRestriction: value.dwtRestriction,
    airdraftRestriction: value.airdraftRestriction,
    holidays: (value.holidays as Array<any>).map(HolidayModelToJSON),
    portTerm: PortTermToJSON(value.portTerm),
    pois: value.pois === undefined ? undefined : (value.pois as Array<any>).map(PointOfInterestToJSON),
    polygon: value.polygon,
    aliases: value.aliases === undefined ? undefined : (value.aliases as Array<any>).map(PortAliasToJSON),
  };
}
