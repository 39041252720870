import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import {connect} from 'react-redux';
import {
  indicatorsContainer,
  option,
  singleValue,
  stationSelectControlBase,
  stationSelectMenuBase,
  stationSelectMenuList,
  valueContainer,
} from '../../atoms/Select/styles';
import autocompleteService from '../../redux/ApiService/autocomplete';
import StationSelectMenuList from './StationSelectMenuList';
import {StationPortOption} from '../SelectOption/StationPortOption';
import GroupHeader from './GroupHeader';
import SingleValue from './SingleValue';
import Select from '../../atoms/Select/Select';

type StationSelectProps = $TSFixMe;
type StationSelectState = $TSFixMe;

class StationSelect extends Component<StationSelectProps, StationSelectState> {
  state = {
    searchData: [],
    query: '',
    isLoading: false,
  };
  handleQuery = debounce(query => {
    if (query.length < 2) {
      return;
    }
    this.setState({isLoading: true, query});
    this.props
      .onQueryChange(query)
      .then(({searchTerm, searchData}: $TSFixMe) => {
        if (searchTerm === this.state.query) {
          this.setState({searchData, isLoading: false});
        }
      })
      .catch(() => this.setState({isLoading: false}));
  }, 400);
  handleChange = (data: $TSFixMe, {action}: $TSFixMe) => {
    if (action === 'select-option') {
      this.props.onChange(data);
    }
  };
  onKeyDown = (e: $TSFixMe) => {
    this.props.onKeyDown(e);
  };
  render() {
    const {id, placeholder, width, value, previousStationId} = this.props;
    const {searchData, isLoading} = this.state;
    return (
      <Select
        id={id}
        name={`station-${id}`}
        width={width}
        // id < 0 is fake (used for drag and drop operations)
        value={value.id >= 0 ? value : null}
        isLoading={isLoading}
        options={searchData}
        onBlur={() => this.setState({menuIsOpen: false})}
        components={{
          // @ts-expect-error I don't know what the problem is
          Option: StationPortOption,
          // @ts-expect-error I don't know what the problem is
          GroupHeading: GroupHeader,
          MenuList: StationSelectMenuList,
          SingleValue: SingleValue,
        }}
        onInputChange={this.handleQuery}
        onChange={this.handleChange}
        onKeyDown={this.onKeyDown}
        getOptionLabel={option => `${option.name}`}
        getOptionValue={option => option.id}
        placeholder={placeholder}
        filterOption={() => true} // disable client side filtering
        isOptionDisabled={option => option.id === previousStationId}
        styles={{
          option,
          control: stationSelectControlBase(width),
          menu: stationSelectMenuBase,
          menuList: stationSelectMenuList,
          singleValue,
          valueContainer,
          indicatorsContainer,
        }}
      />
    );
  }
}
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  onQueryChange: (query: $TSFixMe) => dispatch(autocompleteService.searchPorts(query)),
});
export default connect(null, mapDispatchToProps)(StationSelect);
