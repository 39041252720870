import React, {useEffect, useRef} from 'react';
import {InputNumber} from 'antd';

type Props = {
  value: number;
  min?: number;
  onChange: (value: number) => void;
  onBlur: () => void;
};

export const CellEditor = (props: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (ref.current as unknown as HTMLInputElement)!.focus();
  }, []);

  return (
    <InputNumber
      min={props.min}
      data-testid="CellEditor"
      ref={ref}
      onBlur={() => props.onBlur()}
      onPressEnter={() => props.onBlur()}
      value={props.value}
      onChange={value => {
        if (typeof value === 'number') {
          props.onChange(value);
        }
      }}
    />
  );
};
