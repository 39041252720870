import dayjs from 'dayjs';

export const getYearsInRange = (start: dayjs.Dayjs, end: dayjs.Dayjs): number[] => {
  const years: number[] = [];
  let currentYear = start.year();

  while (currentYear <= end.year()) {
    years.push(currentYear);
    currentYear++;
  }

  return years;
};
