export type TimeseriesItem = {
  date: number;
  draught: number;
  draughtMax: number;
  speed: number;
  speedMax: number;
};

export const smoothDraftItems = (items: TimeseriesItem[]): TimeseriesItem[] => {
  let lastDraught = 0;
  let lastDraughtMax = 0;
  return items.map((point: TimeseriesItem) => {
    let draught = point.draught;
    let draughtMax = point.draughtMax;

    if (draught === 0) {
      draught = lastDraught;
      draughtMax = lastDraughtMax;
    } else {
      lastDraught = draught;
      lastDraughtMax = draughtMax;
    }
    return {...point, draught, draughtMax};
  });
};
