import {Button, Col, Row, Steps} from 'antd';
import React, {useState, FC} from 'react';
import {useImmer} from 'use-immer';
import {AccountSegments} from '../../../api/symfony/generated';
import {CommoditySelector} from './CommoditySelector';
import {RoleSelector} from './RoleSelector';
import {VesselTypeSelector} from './VesselTypeSelector';
import {SizeSelector} from './SizeSelector';
import {TradingAreaSelector} from './TradingAreaSelector/TradingAreaSelector';
import {getOkButtonDisabled, Step} from './utils/getOkButtonDisabled';

const steps: Step[] = ['userSelfCategorization', 'segment', 'sizes', 'commodities', 'tradingAreas'];

export const AccountSegmentSelectorSteps: FC<{
  values: AccountSegments;
  onSubmit: (values: AccountSegments) => void;
}> = ({values, onSubmit}) => {
  const [currentStep, setCurrentStep] = useState<Step>('userSelfCategorization');
  const [segmentData, setSegmentData] = useImmer<AccountSegments>(values);
  const stepIndex = steps.indexOf(currentStep);
  const okButtonDisabled = getOkButtonDisabled({currentStep, segmentData});
  const hasBackButton = stepIndex > 0;
  const hasCommodity = segmentData.types.includes('bulker');

  const onNext = () => {
    let nextStep = steps[stepIndex + 1];
    if (nextStep === 'commodities' && !hasCommodity) {
      nextStep = steps[stepIndex + 2];
    }

    if (!nextStep) {
      onSubmit(segmentData);
      return;
    }

    setCurrentStep(nextStep);
  };

  const goBack = () => {
    if (!hasBackButton) {
      return;
    }
    let preStep = steps[stepIndex - 1];
    if (preStep === 'commodities' && !hasCommodity) {
      preStep = steps[stepIndex - 2];
    }

    setCurrentStep(preStep);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Steps
            data-testid="AccountSegmentSelectorSteps"
            size="small"
            current={stepIndex}
            items={[
              {title: 'Organisational role'},
              {title: 'Vessel segments'},
              {title: 'Sizes'},
              ...(hasCommodity ? [{title: 'Commodities'}] : []),
              {title: 'Trading areas'},
            ]}
          />
        </Col>
        <Col span={24}>
          {currentStep === 'userSelfCategorization' && (
            <RoleSelector
              selectedRoles={segmentData.userSelfCategorization}
              onChange={userSelfCategorization => {
                setSegmentData(segmentData => {
                  segmentData.userSelfCategorization = userSelfCategorization;
                });
              }}
            />
          )}
          {currentStep === 'segment' && (
            <VesselTypeSelector
              segment={segmentData.types}
              onChange={segment => {
                setSegmentData(segmentData => {
                  segmentData.types = segment;
                });
              }}
            />
          )}
          {currentStep === 'sizes' && (
            <SizeSelector
              selectedSizes={segmentData.sizes}
              segment={segmentData.types}
              onChange={newSizes => {
                setSegmentData(segmentData => {
                  segmentData.sizes = newSizes;
                });
              }}
            />
          )}
          {currentStep === 'commodities' && (
            <CommoditySelector
              values={segmentData.commodities}
              onChange={commodities => {
                setSegmentData(segmentData => {
                  segmentData.commodities = commodities;
                });
              }}
            />
          )}
          {currentStep === 'tradingAreas' && (
            <TradingAreaSelector
              selectedTradingAreaCodes={segmentData.tradingAreas}
              onSelect={tradingareas => {
                setSegmentData(segmentData => {
                  segmentData.tradingAreas = tradingareas;
                });
              }}
            />
          )}
        </Col>
      </Row>
      <Row align="middle" justify="center" gutter={[16, 16]}>
        <Col>
          <Button disabled={!hasBackButton} onClick={goBack}>
            Back
          </Button>
        </Col>
        <Col>
          <Button data-testid={'NextButton'} disabled={okButtonDisabled} type={'primary'} onClick={onNext}>
            {currentStep === 'tradingAreas' ? 'Save' : 'Next'}
          </Button>
        </Col>
      </Row>
    </>
  );
};
