import {ReactNode} from 'react';
import RSelect, {GroupBase, Props, SelectComponentsConfig} from 'react-select';
import {ClearIndicator, SearchIndicator} from './Indicators';
import {
  clearIndicator,
  control,
  indicatorsContainer,
  menu,
  menuList,
  multiValue,
  multiValueLabel,
  multiValueRemove,
  option,
  singleValue,
  valueContainer,
} from './styles';

// @ts-expect-error Type 'Element' is not assignable to type 'string'.
interface MultiSelectProps<OptionType> extends Props<OptionType, true, GroupBase<OptionType>> {
  isDisabled?: boolean;
  width?: number | string;
  components?: Partial<SelectComponentsConfig<OptionType, true, GroupBase<OptionType>>>;
  hasError?: boolean;
  clearable?: boolean;
  getOptionLabel?: (option: OptionType) => ReactNode;
  isOptionsDisabled?: (option: OptionType) => boolean;
  height?: number | string;
  id?: string;
  name?: string;
  value?: OptionType[];
  options?: OptionType[];
  allowCreateWhileLoading?: boolean;
  createLabel?: (value: string) => ReactNode;
  noOptionsMessage?: () => ReactNode;
  maxLength?: number;
  formatCreateLabel?: (inputValue: string) => string;
  loadOptions?: (value: string) => Promise<OptionType[]>;
  cacheOptions?: boolean;
  defaultOptions?: boolean | OptionType[];
  createOptionPosition?: 'first' | 'last';
}

export const MultiSelect = <OptionType,>({
  id,
  name,
  width: _,
  components,
  hasError,
  ...props
}: MultiSelectProps<OptionType>) => (
  <RSelect
    id={id}
    name={`select-${name}`}
    classNamePrefix={'seabo-react-select'}
    isMulti={true}
    isClearable
    isSearchable={true}
    styles={{
      option,
      control: (base, state) => ({
        ...control('100%', hasError)(base, state),
      }),
      menu,
      menuList,
      singleValue,
      valueContainer,
      indicatorsContainer,
      multiValue,
      multiValueLabel,
      multiValueRemove,
      clearIndicator,
    }}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator: SearchIndicator,
      MultiValueRemove: ClearIndicator,
      ClearIndicator: () => {
        return (
          <ClearIndicator
            innerProps={{
              onClick: () => {
                props?.onChange?.([], {action: 'clear', removedValues: []});
              },
              onMouseDown: event => {
                event.stopPropagation();
              },
            }}
          />
        );
      },
      ...components,
    }}
    // !! react18 - package types only allow string as return. But element is needed in CargoTabPane and works - /projects -> create project
    // @ts-expect-error Type '((option: OptionType) => string | Element) | undefined' is not assignable to type 'GetOptionLabel<OptionType> | undefined'.
    getOptionLabel={props.getOptionLabel}
    {...props}
  />
);
