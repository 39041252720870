/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Coordinates, CoordinatesFromJSON, CoordinatesFromJSONTyped, CoordinatesToJSON} from './';

/**
 *
 * @export
 * @interface HeatmapGeohashTile
 */
export interface HeatmapGeohashTile {
  /**
   * number between 0 = no visits and 1 = max visits
   * @type {number}
   * @memberof HeatmapGeohashTile
   */
  standardizedValue: number;
  /**
   *
   * @type {string}
   * @memberof HeatmapGeohashTile
   */
  geohash: string;
  /**
   *
   * @type {Coordinates}
   * @memberof HeatmapGeohashTile
   */
  coordinates: Coordinates;
}

export function HeatmapGeohashTileFromJSON(json: any): HeatmapGeohashTile {
  return HeatmapGeohashTileFromJSONTyped(json, false);
}

export function HeatmapGeohashTileFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeatmapGeohashTile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    standardizedValue: json['standardizedValue'],
    geohash: json['geohash'],
    coordinates: CoordinatesFromJSON(json['coordinates']),
  };
}

export function HeatmapGeohashTileToJSON(value?: HeatmapGeohashTile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    standardizedValue: value.standardizedValue,
    geohash: value.geohash,
    coordinates: CoordinatesToJSON(value.coordinates),
  };
}
