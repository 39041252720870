import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {actions as fixtureApi} from '../../../redux/ApiService/FixtureService';
import autocompleteApi from '../../../redux/ApiService/autocomplete';
import BaseModal, {BaseModalChildrenProps} from '../../../atoms/BaseModal/index';
import animateScrollTo from 'animated-scroll-to';
import LoadingOverlay from '../../../atoms/Loading/LoadingOverlay';
import './style.scss';
import {ManageFixtureForm} from './ManageFixtureForm/ManageFixtureForm';
import {
  UpdateFixtureRequest,
  UpdateFixtureRequestSchema,
} from './ManageFixtureForm/UpdateFixtureRequestSchema/UpdateFixtureRequestSchema';
import {FixtureFromListRequest} from './ManageFixtureForm/FixtureFromListRequestSchema/FixtureFromListRequestSchema';
import {FormValues} from './ManageFixtureForm/FormValues';
import {SubmitAction} from './ManageFixtureForm/sections/SubmitButtons';
import {assert, assertUnreachable} from '../../../utils/assert';
import {vesselFixtureApi} from '../../../api/symfony/symfonyApi';
import {useUser} from '../../UserContext/useUser';
import {Form} from 'antd';
import {gentleZodValidation} from '../../../api/utils/gentleZodValidation';
import {useMutation} from '@tanstack/react-query';
import {setHiddenVesselFields} from './ManageFixtureForm/sections/GeneralSection/setHiddenVesselFields';
import {DefaultFormValues} from './ManageFixtureForm/DefaultFormValues';
import {setHiddenCargoFields} from './ManageFixtureForm/sections/GeneralSection/setHiddenCargoFields';
import produce from 'immer';
import {CargoDetailsResponse} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {VesselFromApi} from './ManageFixtureForm/sections/GeneralSection/VesselFromApi';
import {VesselDetailsVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

export type ManageFixtureProps = {
  formData?: Partial<FixtureFromListRequest> | null;
  isVesselEdit?: boolean;
  // set this fixture to be the default for the vessel
  updateVessel?: boolean;
  gridUpdate?: boolean;
  readOnly?: boolean;
  // Overwrite formData.vessel
  vessel?: Partial<VesselFromApi | VesselDetailsVessel>;
  // Overwrite formData.cargo
  cargo?: Partial<CargoDetailsResponse>;
  callback?: () => void;
};

const ManageFixtureModalBody = (props: $TSFixMe) => {
  const {
    close,
    vessel = null,
    cargo = null,
    callback,
    isVesselEdit = false,
    gridUpdate = false,
    /**
     * If true, the related vessel will be updated and this fixture is the active fixture of the vessel
     */
    updateVessel = false,
  } = props;

  const formData: FixtureFromListRequest | null = props.formData || null;
  const isEdit = !!(formData && formData.id);
  const readOnly = !!props.readOnly;

  const [form] = Form.useForm<FormValues>();
  const user = useUser();
  const dispatch = useDispatch();

  const preFillSearchVessels = () => dispatch(autocompleteApi.preFillSearchVessels());
  const createFixture = (body: UpdateFixtureRequest) => dispatch(fixtureApi.createFixture({body}));

  useEffect(() => {
    if (vessel) {
      setHiddenVesselFields(form, DefaultFormValues.contractType, vessel);
    }
    if (cargo) {
      setHiddenCargoFields(form, cargo, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToFirstError = () => {
    window.setTimeout(() => {
      const firstErrorElement = document.querySelector('.modal__content .ant-form-item-has-error');
      if (!firstErrorElement) {
        // eslint-disable-next-line no-console
        console.warn('No error to scroll to');
        return;
      }
      const modalContent = document.querySelector('.modal__content');
      assert(modalContent);
      void animateScrollTo(firstErrorElement, {
        elementToScroll: modalContent,
        verticalOffset: -40,
      });
    }, 100);
  };

  const scrollToFixtureDate = () => {
    window.setTimeout(() => {
      const fixtureDateElement = document.querySelector('.modal__content #fixture-date');
      assert(fixtureDateElement);
      const modalContent = document.querySelector('.modal__content');
      assert(modalContent);
      void animateScrollTo(fixtureDateElement, {
        elementToScroll: modalContent,
        verticalOffset: -250,
      });
    }, 100);
  };

  const submitMutation = useMutation({
    mutationFn: async ({formValues, submitAction}: {formValues: FormValues; submitAction: SubmitAction}) => {
      gentleZodValidation<FormValues>(UpdateFixtureRequestSchema, formValues);

      switch (submitAction) {
        case 'SaveAndManageNext':
          await createFixture(formValues);
          form.resetFields();
          preFillSearchVessels();
          scrollToFixtureDate();
          break;

        case 'SaveAndClose':
          if (isEdit) {
            assert(props.formData.id);
            const patchedFixture = workAroundPHPBackendIssue(formValues);
            await vesselFixtureApi.updateVesselFixture({
              workspaceId: user.workspace.id,
              fixture: props.formData.id,
              body: patchedFixture,
            });
          } else {
            await createFixture(formValues);
          }
          break;

        case 'SaveAsNewFixture':
          await createFixture(formValues);
          break;

        default:
          assertUnreachable(submitAction);
      }

      callback?.();
      if (submitAction !== 'SaveAndManageNext') {
        close();
      }
    },
  });

  const onSubmitFailed = () => {
    scrollToFirstError();
  };

  return (
    <div className={'fixture-modal'}>
      {submitMutation.isPending && <LoadingOverlay />}

      <ManageFixtureForm
        form={form}
        updateVessel={updateVessel}
        fixtureFromListRequest={formData}
        isVesselEdit={isVesselEdit}
        readOnly={readOnly}
        gridUpdate={gridUpdate}
        submitting={submitMutation.isPending}
        onSubmit={async (formValues, submitAction) => {
          submitMutation.mutate({formValues, submitAction});
        }}
        onSubmitFailed={onSubmitFailed}
      />
    </div>
  );
};

/**
 * Works around a PHP issue where the server crashes when receiving existing station ids.
 *
 * @see https://seanexxt.atlassian.net/browse/FRIN-5051
 */
const workAroundPHPBackendIssue = (formValues: FormValues): FormValues => {
  // Delete all station ids:
  const patched = produce(formValues, draft => {
    for (const station of draft.stations) {
      station.id = undefined;
    }
  });

  return patched;
};

const ManageFixtureModal = (props: ManageFixtureProps) => (
  <BaseModal maxWidth={1000} title={props.formData?.id ? 'Fixture details' : 'New fixture'}>
    {(modalProps: BaseModalChildrenProps) => <ManageFixtureModalBody {...props} {...modalProps} />}
  </BaseModal>
);

export default ManageFixtureModal;
