import {Dayjs} from 'dayjs';
import {ViewState} from '../ViewState';
import {OpenweathermapLayer, openweathermapNoLayer} from '../layers/mapbox/OpenweathermapLayerType';
import React, {CSSProperties, ReactElement} from 'react';
import {DraftFunction} from 'use-immer';
import {AisVessel} from '../../../api/symfony/generated/models';
import {IdentifierProp} from '../../../redux/Map';
import {Coordinates, CoordinatesObject} from '../../../utils/Coordinates';
import {TODO} from '../../../utils/TODO';
import {MapApi, MapApiState} from '../MapApi';
import {MapDetailItemView, MapElement, MapSwitches} from '../MapDetails/utils/types';
import {SideContentRendererProps} from '../SideContent/ListOfSelectedVessels';
import {VesselPositionHistory} from '../../../redux/ApiService/MapService/MapService';
import {PickingInfo} from '@deck.gl/core/typed';
import {MarketCargoInternalFilter} from '../../FilterProvider/Filters/Market/MarketCargoFilterDefinition';
import {MarketVesselInternalFilter} from '../../FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';

export type SelectedItem = TODO;

export type MapProfile =
  | 'potential_competitors'
  | 'port_editor'
  | 'port_backend_details'
  | 'distance_calc'
  | 'live_circulars'
  | 'cargo_detail'
  | 'port_detail'
  | 'dashboard'
  | 'market'
  | 'congestion_report'
  | 'tied_up_vessels'
  | 'segment_selection'
  | 'vessel_database';

export enum MapControlButton {
  CARGOES = 'm_cargo',
  PORTS = 'm_port',
  VESSELS = 'm_vessel',
  LAYERS = 'm_layer',
}

export interface MapSettings {
  version?: number;
  mapResolution: 0 | 2;
  vesselIconSizeCapacityRelation: boolean;
  vesselIconStyle: string;
  vesselIconSizeMultiplicator: number;
  mapStyle: MapStyle;
  openWeatherMapLayer: typeof openweathermapNoLayer | OpenweathermapLayer;
  showOpenSeaMapGeoJSON: boolean;
  switches: Partial<MapSwitches>;
  map?: CoordinatesObject & {
    zoom: number;
  };
}

export type MapSize = {
  width: number;
  height: number;
} | null;

export type SelectedItems = {
  items: Array<SelectedItem>;
  type: string;
};

/**
 * The internal state of the map implementation.
 */
export interface MapState extends MapApiState {
  mapReady: boolean;
  selectedItems: SelectedItems;
  isFullScreen: boolean;
  isSelectMode: boolean;
  showSideContent: boolean;
  showSettingView: boolean;
  viewState: ViewState;
  switches: MapSwitches;
  mapSize: MapSize;
  settings: MapSettings;
}

export interface RenderTradingAreaLayerProps {
  id: string;
  key: string;
  onClick: (info: PickingInfo) => void;
  selected: string | number | null;
  showLabels: boolean | undefined;
  data: TODO;
  fontColorOnLayer: TODO;
  switches: MapSwitches;
}

export type MapControlMenuSectionsToHide =
  | 'vessel_market'
  | 'vessel_portfolio'
  | 'cargo_portfolio'
  | 'cargo_commodities'
  | 'cargo_market'
  | 'cargo_on'
  | 'vessel_on'
  | 'vessel_ais';

export interface SeaboMapProps {
  className?: string;
  canFullScreen?: boolean;
  isDragPanEnabled?: boolean;
  isScrollZoomEnabled?: boolean;
  pitch?: TODO;
  mapMenuZIndex?: number;
  bearing?: TODO;
  /**
   * This decides if the map has a select mode (the lasso mode to select multiple vessels via rectangle)
   * @default true
   */
  lassoSelectionEnabled?: boolean;

  /**
   * When settingsPersistent is true, the map settings are saved on the server. Every user has several private settings profiles.
   * The user himself cannot select the profile.
   * The profile is selected by the developer using the settingIdentifier props on the map.
   *
   * If settingsPersistent is false or undefined, the map uses default settings and the settings are not saved on the server.
   *
   */
  settingsPersistent?: boolean;
  /**
   * In Overview pages we could have multiple maps, but to we can't have so many webGL-contexts.
   * But among other layers the MapBox Layers need them, so we have to work around them.
   * This option reduces the number of webGL-contexts per map by 1.
   * By using this option, some sub layers of the mapbox layer will not be rendered.
   */
  staticMapBoxLayer?: boolean;
  mapApi?: MapApi;
  initialMapSettings?: Partial<MapSettings>;
  initialViewState?: Partial<ViewState>;
  centerPoint?: Coordinates;
  coordinatesToFocus?: Coordinates[];
  saveMapState?: boolean;
  settingsButtonVisible?: boolean;
  otherLayersDropDown?: ReactElement;
  /**
   * Layers that are loaded directly above the background map are not React components but DeckGL layers instances
   */
  layers?: TODO[];
  mapControlButtonsToHide?: MapControlButton[];
  mapControlMenuSectionsToHide?: MapControlMenuSectionsToHide[];
  layersOnBottom?: ReactElement[];
  layersOnTop?: ReactElement[];
  layersOnTopOfMapBox?: ReactElement[];
  moveCtrlDown?: boolean;
  effects?: TODO[];
  sideContentRenderer?: (props: SideContentRendererProps) => React.ReactNode;
  filters?: {
    cargoFilters?: MarketCargoInternalFilter | null;
    vesselFilters?: MarketVesselInternalFilter | null;
  };
  defaultSelectedElement?: MapElement | null;
  onSelectElement?: (element: MapElement | null) => void;
  componentOnTop?: ReactElement;
  showPortIds?: number[];
  minimumZoomLevelToShowPorts?: number;
  overlays?: ReactElement[];
  componentsAsRightSiblings?: ReactElement[];
  onViewStateChange?: (viewState: ViewState) => void;
  onChangeMapSize?: (mapSize: MapSize) => void;
  onChangeSwitches?: (mapSwitches: MapSwitches) => void;
  onReady?: () => void;
  componentsIn?: boolean;
  onSaveSettings?: (settings: Partial<MapSettings>) => void;
  vesselsToVisualize?: {
    /**
     * Optional array of AisVessels to show at VesselLayer
     */
    data?: AisVessel[];
    /**
     * opacity of vessels - between 0 and 1
     */
    opacity?: number;
    constantIconSize?: number;

    onClick?: (item: PickingInfo) => void;
  };
  /**
   * Defines the default settings
   */
  defaults?: MapProfile;
  render?: () => void;
  /**
   * To enable the search bar in the top-left-corner
   */
  showSearch?: boolean;
  showControl?: boolean;
  showNavigation?: boolean;
  showPopups?: boolean;
  promoLayerEnabled?: boolean;
  /**
   * The identifier for save settings profiles on the server.
   * It only works if settingsPersistent is true.
   * For more information see the settingsPersistent prop.
   *
   * @default 'defaults'
   */
  settingIdentifier?: IdentifierProp;
  // For differentiation between market and portfolio items
  mapDetailItemView?: MapDetailItemView;
  showLegend?: boolean;
  vesselCargoMode?: boolean;
  onPortSelect?: (portId: number) => void;
  onSettingsChanged?: (settings: MapSettings) => void;
  showFocusBtn?: boolean;
  showTradingAreaPopup?: boolean;
  tradingAreaStatisticsProps?: {
    timeRange?: [Dayjs, Dayjs];
    cargoType?: 'drybulk' | 'container';
    mode?: 1 | 0;
  };
  dragRotate?: boolean;
  cursor?: string;
  extAisVessels?: TODO;
  /**
   * If this array is set, the AIS vessels from Redux are not used
   */
  aisVessels?: AisVessel[];
  refresh?: boolean;
  cursorHover?: string;
  tooltip?: string | null;
  defaultVesselIconSizeMultiplicator?: number;
  style?: CSSProperties;
  vesselPositionHistory?: VesselPositionHistory;
  showAisVesselRoute?: boolean;
}

export type MapStyle = 'DARK_MODE' | 'FRANK' | 'SAT';

/**
 *
 */
export interface MapInstance extends MapApi {
  state: MapState;
  setFullscreen: (fullscreen: boolean) => void;
  setSelectMode: (selectMode: boolean) => void;
  setShowSettingView: (v: boolean) => void;
  setSelectedItems: (selectedItems: {type: string; items: Array<TODO>}) => void;
  setShowSideContent: (v: boolean) => void;
  setViewState: (viewState: ViewState) => void;
  setMapSize: (mapSize: MapSize) => void;
  setMapReady: (mapReady: boolean) => void;
  setSettings: (settings: MapSettings) => void;
  setSwitches: (switches: MapSwitches) => void;
  updateSwitches: (updateFunction: DraftFunction<MapSwitches>) => void;
  setSetting: (key: keyof MapSettings, value: TODO) => void;
  setMapResolution: (resolution: 0 | 2) => void;
}

export interface MapContextValue extends MapInstance {
  props: SeaboMapProps;
}
