import {useRef, useState} from 'react';
import styled from 'styled-components';
import Icon from '../../../../atoms/Icon';
import useOnClickOutside from 'use-onclickoutside';

type EditableHeaderBoxVariant = 'default' | 'primary' | 'secondary' | 'other';

interface EditableHeaderBoxProps {
  title: string;
  renderInput: (editMode: boolean, onChangeMode: (editing: boolean) => void) => React.ReactNode;
  variant?: EditableHeaderBoxVariant;
  hideRadiusRight?: boolean;
  hideRadiusLeft?: boolean;
  childWidth?: string;
  closeOnClickOutside?: boolean;
  canEdit?: boolean;
}

const variantToColor: Record<EditableHeaderBoxVariant, string> = {
  default: '#F7F7F7',
  primary: '#DBE3F7',
  secondary: '#D8F8E8',
  other: '#F1EDE9',
};

const StyledBox = styled.div<{
  $hideRadiusRight?: boolean;
  $hideRadiusLeft?: boolean;
  $variant?: EditableHeaderBoxVariant;
}>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-right: ${({$hideRadiusRight}) => ($hideRadiusRight ? '0' : '18px')};
  background-color: ${({$variant}) => variantToColor[$variant || 'default']};
  border-color: ${({$variant}) => ($variant === 'default' ? 'var(--color-gray-3)' : undefined)};
  border-top-left-radius: ${({$hideRadiusLeft}) => ($hideRadiusLeft ? undefined : 'var(--border-radius)')};
  border-top-right-radius: ${({$hideRadiusRight}) => ($hideRadiusRight ? undefined : 'var(--border-radius)')};
  border-bottom-right-radius: ${({$hideRadiusRight}) => ($hideRadiusRight ? undefined : 'var(--border-radius)')};
  border-bottom-left-radius: ${({$hideRadiusLeft}) => ($hideRadiusLeft ? undefined : 'var(--border-radius)')};
  flex-grow: 1;
  height: 100%;
  cursor: pointer;

  .ant-affix & {
    padding: 6px 12px;
  }

  input,
  .ant-select-selector,
  .ant-select-selection-item {
    height: 29px !important;
  }

  .ant-select-selection-item {
    line-height: 29px;
  }
`;

const StyledSpan = styled.span`
  white-space: nowrap;
`;
const StyledSpanContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const StyledIconContainer = styled.div`
  width: 24px;
`;

export const EditableHeaderBox: React.FC<EditableHeaderBoxProps> = props => {
  const [editMode, setEditMode] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setTimeout(() => {
      if (props.closeOnClickOutside ?? true) {
        setEditMode(false);
      }
    }, 50);
  });

  const toggleEditMode = () => {
    if (props.canEdit === false) {
      setEditMode(false);
      return;
    }
    setEditMode(!editMode);
  };

  return (
    <StyledBox
      $hideRadiusRight={props.hideRadiusRight}
      $hideRadiusLeft={props.hideRadiusLeft}
      $variant={props.variant}
      onClick={() => {
        toggleEditMode();
      }}
      ref={ref}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'}}>
        <StyledSpanContainer>
          <StyledSpan>{props.title}</StyledSpan>
        </StyledSpanContainer>
        <StyledIconContainer>
          {props.canEdit !== false && (
            <Icon
              className={'editMarker'}
              size={'unset'}
              color="gray"
              style={{verticalAlign: 'bottom'}}
              type={editMode ? 'checkmark' : 'edit'}
            />
          )}
        </StyledIconContainer>
      </div>
      <div
        style={{marginTop: 5, width: props.childWidth ?? '100%', display: 'inline-block'}}
        onClick={event => {
          if (editMode) {
            event.stopPropagation();
          }
        }}>
        {props.renderInput(editMode, setEditMode)}
      </div>
    </StyledBox>
  );
};
