import {Form} from 'antd';
import {FC} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Modal} from '../../antd/components/Modal';
import {reportProblemApi} from '../../api/node-backend/nodeBackendApis';
import MarketActions from '../../redux/ApiService/MarketService';
import {useDispatch} from '../../redux/react-redux';
import {assert} from '../../utils/assert';
import {showNotification} from '../../utils/notification';
import {TODO} from '../../utils/TODO';
import {Input} from '../../antd/components/Input';
import {useUser} from '../../components/UserContext/useUser';

const {TextArea} = Input;

const FormItem = Form.Item;

export const ReportMailParsingProblemModal: FC<{
  onCancel: () => void;
  offer: TODO;
}> = ({offer, onCancel}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const user = useUser();
  const workspaceId = user.workspace?.id;

  const isVesselOffer = !!offer.vessel;

  const mailTextQuery = useQuery({
    queryKey: ['offerMailText', offer.id, isVesselOffer],
    queryFn: async () => {
      if (isVesselOffer) {
        const response = await dispatch(MarketActions.getVesselOffer(offer.id));
        return response.rawMessage.text;
      }
      const response = await dispatch(MarketActions.getCargoOffer(offer.id));
      return response.rawMessage.text;
    },
    enabled: !!offer.id,
  });

  const reportMutation = useMutation({
    mutationFn: async () => {
      assert(workspaceId, 'workspaceId is not defined');
      assert(offer, 'offer is not defined');
      assert(mailTextQuery.data, 'mail text is not defined');

      const message = form.getFieldValue('message');
      return reportProblemApi.reportCircularParsingProblem({
        reportCircularParsingProblemBody: {
          circular: offer,
          mailText: mailTextQuery.data,
          workspaceId: workspaceId,
          message,
        },
      });
    },
    onSuccess: () => {
      showNotification('Report was sent. Our service team will handle it.');
      onCancel();
    },
  });

  return (
    <Modal
      okButtonProps={{
        'data-testid': 'MailParsingProblemModalSubmitButton',
        loading: !mailTextQuery.isSuccess || reportMutation.isPending,
      }}
      onOk={() => form.submit()}
      okText={'Report problem'}
      cancelText="oisdjf"
      onCancel={onCancel}
      open={true}
      title={'Report a parsing problem'}
      width={1000}>
      <Form form={form} data-testid="mailParsingProblemModalForm" onFinish={() => reportMutation.mutate()}>
        <FormItem name={'message'} rules={[{required: true, message: 'You need to add a description.'}]}>
          <TextArea data-testid={'messageTextArea'} placeholder={'Please describe the problem'} rows={4} />
        </FormItem>
      </Form>
    </Modal>
  );
};
