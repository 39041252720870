export const speedInKMPerHour = (distanceInKilometers: number, durationInMinutes: number): number => {
  const speedInKpH = (distanceInKilometers / durationInMinutes) * 60;
  return speedInKpH;
};

export const speedInKnots = (distanceInKilometers: number, durationInMinutes: number): number => {
  if (distanceInKilometers === 0) {
    return 0;
  }
  // Protect against division by zero.
  if (durationInMinutes === 0) {
    return 0;
  }
  const speedInKpH = speedInKMPerHour(distanceInKilometers, durationInMinutes);
  const knots = speedInKpH / 1.852;
  return knots;
};
