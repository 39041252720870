import {MapViewState} from '@deck.gl/core/typed';
import {WebMercatorViewport} from '@deck.gl/core/typed';
import {MapSize} from '../MapContext/Types';
import {Polygon} from '@turf/helpers';
import {ViewBounds} from '../../../redux/ViewBounds';

export const getViewBounds = ({
  mapSize,
  paddingWidth = 0,
  paddingHeight = 0,
  viewState,
}: {
  mapSize: MapSize;
  viewState: MapViewState;
  paddingHeight?: number;
  paddingWidth?: number;
}): ViewBounds => {
  const defaultMapSize = {width: paddingWidth + 100, height: paddingHeight + 100};
  const mapSizeOrDefaultMapSize = mapSize ?? defaultMapSize;
  const {width, height} = mapSizeOrDefaultMapSize;
  const viewport = new WebMercatorViewport({...viewState, width, height});
  const [east, north] = viewport.unproject([paddingWidth, paddingHeight]);
  const [west, south] = viewport.unproject([width - paddingWidth, height - paddingHeight]);
  return {north, east, south, west};
};

type GetViewBoundsPolygonParams = {
  paddingWidth?: number;
  paddingHeight?: number;
  mapSize: MapSize;
  viewState: MapViewState;
};
export const getViewBoundsPolygon = ({
  paddingHeight = 0,
  paddingWidth = 0,
  mapSize,
  viewState,
}: GetViewBoundsPolygonParams): Polygon => {
  const {north, east, south, west} = getViewBounds({
    mapSize: mapSize,
    viewState: viewState,
    paddingWidth,
    paddingHeight,
  });
  return {
    type: 'Polygon',
    coordinates: [
      [
        [east, south],
        [west, south],
        [west, north],
        [east, north],
        [east, south],
      ],
    ],
  };
};
