import React, {FC} from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import {VesselStatus, vesselStatusOptions} from '../../CargoVesselForm/helper';

const vesselStatusAbbreviations: Record<VesselStatus, string> = {
  active: 'A',
  scrapped: 'S',
  employed: 'E',
  yard: 'Y',
  drydock: 'D',
  onorder: 'O',
  inlayup: 'L',
};

const getVesselStatusLabel = (status: VesselStatus) => {
  return vesselStatusOptions[status] || 'unknown';
};

const getVesselStatusAbbreviation = (status: VesselStatus) => {
  return vesselStatusAbbreviations[status] || '/';
};

type VesselStatusProps = {
  status: VesselStatus;
};

export const VesselStatusLabel: FC<VesselStatusProps> = ({status}) => {
  return <Status>{getVesselStatusLabel(status)}</Status>;
};

export const VesselStatusIcon: FC<VesselStatusProps> = ({status}) => {
  return (
    <Tooltip title={getVesselStatusLabel(status)}>
      <StatusIcon>{getVesselStatusAbbreviation(status)}</StatusIcon>
    </Tooltip>
  );
};

const Status = styled.span`
  color: var(--color-gray-2);
  vertical-align: initial !important;
  font-weight: normal;
`;

const StatusIcon = styled(Status)`
  width: 1.1em;
  height: 1.1em;
  display: inline-block;
  background: var(--color-gray-5);
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1em;
  border-radius: var(--border-radius-sm);
  margin-left: 0.25em;
`;
