export const geoJsonEca = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        ECA: 'Caribbean_Sea',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5372222222, 17.3102777778],
            [-67.4458333333, 19.1872222222],
            [-65.28, 19.5077777778],
            [-65.1022222222, 19.2069444444],
            [-65.0061111111, 18.7536111111],
            [-64.9925, 18.6872222222],
            [-64.8975, 18.4894444444],
            [-64.8894444444, 18.4597222222],
            [-64.8775, 18.4225],
            [-64.8719444444, 18.4083333333],
            [-64.86388888890001, 18.3975],
            [-64.8563888889, 18.395],
            [-64.83805555560001, 18.3933333333],
            [-64.8280555556, 18.3966666667],
            [-64.8166666667, 18.403055555599998],
            [-64.7991666667, 18.4077777778],
            [-64.7836111111, 18.405],
            [-64.7769444444, 18.3869444444],
            [-64.7555555556, 18.3769444444],
            [-64.745, 18.3775],
            [-64.7433333333, 18.3783333333],
            [-64.74, 18.3769444444],
            [-64.7283333333, 18.3775],
            [-64.7266666667, 18.375],
            [-64.71611111109999, 18.3736111111],
            [-64.7077777778, 18.3738888889],
            [-64.7008333333, 18.3708333333],
            [-64.6833333333, 18.3727777778],
            [-64.6708333333, 18.3658333333],
            [-64.6397222222, 18.3641666667],
            [-64.6377777778, 18.3561111111],
            [-64.6425, 18.3441666667],
            [-64.63722222220001, 18.320833333299998],
            [-64.6377777778, 18.318611111099997],
            [-64.6605555556, 18.2897222222],
            [-64.6613888889, 18.2786111111],
            [-64.64944444439999, 18.1925],
            [-64.6341666667, 18.0505555556],
            [-64.49305555560001, 18.048888888900002],
            [-64.4505555556, 18.0475],
            [-64.3522222222, 18.0416666667],
            [-64.33555555560001, 18.041944444400002],
            [-64.2658333333, 18.0341666667],
            [-64.0413888889, 18.0033333333],
            [-64.01777777779999, 17.9994444444],
            [-63.9502777778, 17.9797222222],
            [-63.8983333333, 17.964166666700002],
            [-63.8891666667, 17.943888888900002],
            [-63.914722222200005, 17.6611111111],
            [-63.91944444439999, 17.618888888900003],
            [-63.932222222200004, 17.5058333333],
            [-63.9658333333, 17.1933333333],
            [-63.9780555556, 17.0833333333],
            [-63.9883333333, 16.9969444444],
            [-67.5372222222, 17.3102777778],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ECA: 'Baltic_Sea',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.82, 62],
            [18, 66.64],
            [31, 66.64],
            [31, 53.2],
            [10.5, 53.2],
            [9.3, 54.175],
            [9, 55.5],
            [10.4, 57.629],
            [10.57, 57.743],
            [13.82, 57.743],
            [13.82, 62],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ECA: 'Hawaiian_Islands',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-153.00916666700002, 22.5483333333],
            [-152.523611111, 22.1161111111],
            [-151.997222222, 21.4858333333],
            [-151.526666667, 20.6452777778],
            [-151.382777778, 20.1283333333],
            [-151.32972222200002, 19.5794444444],
            [-151.371388889, 19.0463888889],
            [-151.50666666700002, 18.5044444444],
            [-152.008888889, 17.561666666700003],
            [-152.819722222, 16.7741666667],
            [-154.218055556, 15.925555555599999],
            [-154.776944444, 15.7294444444],
            [-155.371111111, 15.6266666667],
            [-156.35166666700002, 15.6769444444],
            [-157.293055556, 15.9991666667],
            [-157.990277778, 16.4302777778],
            [-158.50805555600002, 16.901666666700002],
            [-158.948611111, 17.521388888900002],
            [-159.235555556, 18.1780555556],
            [-159.938055556, 18.4919444444],
            [-160.641666667, 18.5086111111],
            [-161.32055555600002, 18.6544444444],
            [-162.343055556, 19.1619444444],
            [-162.896666667, 19.6508333333],
            [-163.320277778, 20.2261111111],
            [-163.628888889, 20.923888888900002],
            [-163.7675, 21.6125],
            [-163.744722222, 22.3358333333],
            [-163.549444444, 23.055555555599998],
            [-163.216666667, 23.680555555599998],
            [-162.718888889, 24.2647222222],
            [-162.220277778, 24.6802777778],
            [-161.6425, 25.0016666667],
            [-160.664722222, 25.364722222199998],
            [-159.905833333, 25.508611111100002],
            [-159.16305555600002, 25.5219444444],
            [-158.51, 25.4252777778],
            [-157.903611111, 25.2280555556],
            [-157.371388889, 24.9591666667],
            [-156.4575, 24.6963888889],
            [-155.853611111, 24.363611111100003],
            [-154.61333333299999, 23.8630555556],
            [-154.03666666700002, 23.5363888889],
            [-153.47666666700002, 23.101388888899997],
            [-153.00916666700002, 22.5483333333],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ECA: 'US_East_Coast',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-64.16, 60],
            [-56.7166666667, 60],
            [-55.634722222200004, 58.9002777778],
            [-55.0630555556, 57.8477777778],
            [-54.0163888889, 57.5869444444],
            [-53.132777777799994, 57.2388888889],
            [-52.3913888889, 56.8025],
            [-51.8283333333, 56.3036111111],
            [-50.295555555600004, 54.3891666667],
            [-50.1213888889, 53.748333333299996],
            [-50.168055555600006, 53.083055555600005],
            [-49.9525, 52.335],
            [-48.8791666667, 51.572222222200004],
            [-48.2677777778, 50.6708333333],
            [-48.1175, 50.041111111099994],
            [-48.1597222222, 49.4008333333],
            [-47.921388888900005, 48.656111111099996],
            [-47.782222222200005, 47.4069444444],
            [-48.015, 46.5866666667],
            [-48.72444444439999, 45.3291666667],
            [-49.2805555556, 44.727222222200005],
            [-49.85638888890001, 44.2772222222],
            [-50.566944444399994, 43.8875],
            [-51.344722222200005, 43.6016666667],
            [-52.2894444444, 43.399722222200005],
            [-53.3369444444, 43.33055555560001],
            [-54.1555555556, 43.3538888889],
            [-55.12805555560001, 43.494722222200004],
            [-55.528888888900006, 42.67],
            [-56.159444444399995, 41.9719444444],
            [-57.0869444444, 41.3391666667],
            [-58.048611111099994, 40.9261111111],
            [-59.0883333333, 40.6938888889],
            [-60.20555555560001, 40.6425],
            [-61.2341666667, 40.762777777800004],
            [-62.2969444444, 41.0811111111],
            [-63.1802777778, 40.6152777778],
            [-64.1436111111, 40.292222222199996],
            [-64.99194444439999, 40.1294444444],
            [-65.8852777778, 40.0955555556],
            [-65.9975, 39.968055555599996],
            [-66.3538888889, 39.4733333333],
            [-66.8091666667, 39.0316666667],
            [-67.3497222222, 38.6544444444],
            [-68.0336111111, 38.3222222222],
            [-68.7819444444, 38.0913888889],
            [-69.5686111111, 37.9705555556],
            [-70.4025, 37.9630555556],
            [-70.6305555556, 37.8794444444],
            [-71.1425, 37.3102777778],
            [-71.56638888890001, 36.5402777778],
            [-71.4338888889, 35.582777777800004],
            [-71.6177777778, 34.5527777778],
            [-71.87638888890001, 33.9136111111],
            [-72.2866666667, 33.3230555556],
            [-72.9013888889, 32.7586111111],
            [-74.2005555556, 31.920277777800003],
            [-75.2555555556, 31.4538888889],
            [-75.855, 31.0544444444],
            [-76.52722222220001, 30.761666666700002],
            [-77.3080555556, 30.2133333333],
            [-76.945, 29.421388888899997],
            [-76.8, 28.616388888899998],
            [-76.6694444444, 28.2869444444],
            [-79.1897222222, 28.2866666667],
            [-79.4763888889, 27.8822222222],
            [-79.52722222220001, 27.433611111100003],
            [-79.5716666667, 27.2702777778],
            [-79.5822222222, 27.1983333333],
            [-79.5886111111, 27.099722222199997],
            [-79.58805555560001, 27.0077777778],
            [-79.5775, 26.9211111111],
            [-79.5741666667, 26.8994444444],
            [-79.54472222220001, 26.762777777800004],
            [-79.5397222222, 26.741666666700002],
            [-79.5388888889, 26.727777777800004],
            [-79.5336111111, 26.686666666700003],
            [-79.52555555560001, 26.6369444444],
            [-79.51833333329999, 26.6083333333],
            [-79.5138888889, 26.589166666700002],
            [-79.5127777778, 26.5808333333],
            [-79.5105555556, 26.5697222222],
            [-79.5041666667, 26.52],
            [-79.4980555556, 26.4847222222],
            [-79.4994444444, 26.4252777778],
            [-79.49861111109999, 26.3913888889],
            [-79.4983333333, 26.3891666667],
            [-79.5319444444, 26.3158333333],
            [-79.5547222222, 26.2572222222],
            [-79.5563888889, 26.2536111111],
            [-79.5980555556, 26.1358333333],
            [-79.6025, 26.129722222199998],
            [-79.6097222222, 26.116388888899998],
            [-79.6394444444, 26.0477777778],
            [-79.6675, 25.991666666700002],
            [-79.6688888889, 25.9877777778],
            [-79.6772222222, 25.9633333333],
            [-79.685, 25.938333333299997],
            [-79.6938888889, 25.9011111111],
            [-79.6961111111, 25.89],
            [-79.69972222220001, 25.865],
            [-79.7044444444, 25.8258333333],
            [-79.70638888890001, 25.8066666667],
            [-79.7066666667, 25.805555555599998],
            [-79.7122222222, 25.7738888889],
            [-79.7125, 25.7711111111],
            [-79.7163888889, 25.727777777800004],
            [-79.7133333333, 25.7086111111],
            [-79.7075, 25.6769444444],
            [-79.7075, 25.6233333333],
            [-79.7075, 25.6188888889],
            [-79.7033333333, 25.5175],
            [-79.7030555556, 25.4663888889],
            [-79.7033333333, 25.4011111111],
            [-79.7055555556, 25.3725],
            [-79.7022222222, 25.3580555556],
            [-79.69, 25.281111111100003],
            [-79.6919444444, 25.265833333299998],
            [-79.6919444444, 25.1775],
            [-79.6933333333, 25.1641666667],
            [-79.6958333333, 25.1508333333],
            [-79.7080555556, 25.065277777800002],
            [-79.7155555556, 25.05],
            [-79.7347222222, 25.008333333299998],
            [-79.7466666667, 24.984166666700002],
            [-79.7658333333, 24.9244444444],
            [-79.8233333333, 24.738333333299998],
            [-79.8272222222, 24.717777777800002],
            [-79.8472222222, 24.71],
            [-79.8825, 24.6963888889],
            [-79.9994444444, 24.642222222199997],
            [-80.0641666667, 24.6075],
            [-80.21194444439999, 24.555],
            [-80.2225, 24.5513888889],
            [-80.2544444444, 24.5369444444],
            [-80.2819444444, 24.5241666667],
            [-80.2963888889, 24.515833333299998],
            [-80.3225, 24.503888888899997],
            [-80.3288888889, 24.5016666667],
            [-80.3513888889, 24.4938888889],
            [-80.4097222222, 24.4716666667],
            [-80.4194444444, 24.4683333333],
            [-80.4555555556, 24.456388888899998],
            [-80.4916666667, 24.4416666667],
            [-80.53944444439999, 24.418611111100002],
            [-80.6025, 24.3916666667],
            [-80.6488888889, 24.375833333299997],
            [-80.6641666667, 24.3686111111],
            [-80.7558333333, 24.325277777800004],
            [-80.7630555556, 24.321111111100002],
            [-80.7802777778, 24.3105555556],
            [-80.7816666667, 24.309722222199998],
            [-80.9963888889, 24.1641666667],
            [-80.9975, 24.1633333333],
            [-81.0186111111, 24.1494444444],
            [-81.0308333333, 24.1416666667],
            [-81.0325, 24.1405555556],
            [-81.0516666667, 24.1244444444],
            [-81.1513888889, 24.038888888899997],
            [-81.1877777778, 24],
            [-81.2152777778, 23.9255555556],
            [-81.3286111111, 23.897777777800002],
            [-81.4997222222, 23.8477777778],
            [-81.6663888889, 23.8338888889],
            [-81.8330555556, 23.8180555556],
            [-82.0030555556, 23.8180555556],
            [-82.1663888889, 23.828333333299998],
            [-82.4163888889, 23.8538888889],
            [-82.6663888889, 23.8538888889],
            [-82.8147222222, 23.828333333299998],
            [-82.85305555560001, 23.8255555556],
            [-82.9997222222, 23.8233333333],
            [-83.2497222222, 23.8311111111],
            [-83.4302777778, 23.856111111100002],
            [-83.55027777779999, 23.874166666700003],
            [-83.6930555556, 23.9011111111],
            [-83.80305555560001, 23.9297222222],
            [-83.9997222222, 23.977222222199998],
            [-84.4908333333, 24.1602777778],
            [-84.6441666667, 24.2222222222],
            [-84.7686111111, 24.278055555599998],
            [-84.9997222222, 24.3916666667],
            [-85.1052777778, 24.4436111111],
            [-85.5316666667, 24.6491666667],
            [-85.7197222222, 24.7380555556],
            [-85.99972222219999, 24.8991666667],
            [-86.50194444440001, 25.178888888899998],
            [-86.35388888889999, 25.7208333333],
            [-86.1125, 26.220277777800003],
            [-86.22083333329999, 26.4561111111],
            [-86.61861111110001, 26.5627777778],
            [-87.4930555556, 26.0233333333],
            [-88.55, 25.7069444444],
            [-90.4947222222, 25.7816666667],
            [-90.78472222219999, 25.7441666667],
            [-91.8805555556, 25.8619444444],
            [-93.0663888889, 26.2955555556],
            [-93.56444444440001, 25.9986111111],
            [-95.6575, 26.0088888889],
            [-96.8083333333, 26.0091666667],
            [-96.92444444440001, 25.9755555556],
            [-96.9780555556, 25.9708333333],
            [-97.0316666667, 25.966111111100002],
            [-97.0855555556, 25.9613888889],
            [-97.1391666667, 25.956666666700002],
            [-97.14638888889999, 25.956666666700002],
            [-101.5, 34.41],
            [-64.16, 60],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ECA: 'US_West_Coast',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.103055556, 32.5361111111],
            [-117.124722222, 32.5344444444],
            [-117.238888889, 32.5275],
            [-117.263888889, 32.553611111100004],
            [-117.366944444, 32.5725],
            [-117.464722222, 32.589722222199995],
            [-117.826111111, 32.6272222222],
            [-118.605833333, 31.1330555556],
            [-121.79138888899999, 30.5569444444],
            [-123.289444444, 31.7697222222],
            [-123.845555556, 32.366111111100004],
            [-124.196388889, 32.9441666667],
            [-124.45416666700001, 33.67],
            [-125.281111111, 34.524444444400004],
            [-125.723055556, 35.243888888899995],
            [-126.31472222200001, 35.7333333333],
            [-126.758333333, 36.2736111111],
            [-127.12166666700001, 37.0263888889],
            [-127.633888889, 37.7608333333],
            [-127.883333333, 38.4188888889],
            [-128.523055556, 39.4180555556],
            [-128.762777778, 40.3130555556],
            [-128.67277777799998, 41.2275],
            [-129.010555556, 42.2136111111],
            [-129.095, 42.792777777800005],
            [-129.023888889, 43.439444444399996],
            [-128.689722222, 44.41194444439999],
            [-128.66722222200002, 45.511944444399994],
            [-128.816944444, 46.1836111111],
            [-129.07472222200002, 46.5652777778],
            [-131.261388889, 47.6652777778],
            [-132.68333333299998, 48.5422222222],
            [-133.246388889, 48.96305555560001],
            [-134.264166667, 49.3775],
            [-135.316944444, 50.031111111099996],
            [-136.7625, 51.055],
            [-137.698333333, 51.90111111109999],
            [-138.337222222, 52.7533333333],
            [-138.676666667, 53.4888888889],
            [-138.814722222, 53.6775],
            [-139.543888889, 54.2291666667],
            [-139.938611111, 54.6569444444],
            [-140.929166667, 55.3383333333],
            [-141.605, 56.12],
            [-142.288611111, 56.4755555556],
            [-142.815833333, 56.62194444439999],
            [-153.250833333, 58.851111111099996],
            [-155.64, 60.28],
            [-149.26, 65.07],
            [-122, 58.851111111099996],
            [-117.103055556, 32.5361111111],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ECA: 'North_Sea',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4, 62],
            [13.82, 62],
            [13.82, 57.743],
            [10.57, 57.743],
            [10.4, 57.629],
            [9, 55.5],
            [9.3, 54.175],
            [10.5, 53.2],
            [9.5, 48.5],
            [-5, 48.5],
            [-5, 50.2],
            [-4.57, 50.53],
            [-2.07, 51.18],
            [-2.36, 55.41],
            [-4, 55.41],
            [-5, 57.25],
            [-4, 58.324],
            [-4, 62],
          ],
        ],
      },
    },
  ],
};

export default geoJsonEca;
