/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ConsentContractVersionConsent,
  ConsentContractVersionConsentFromJSON,
  ConsentContractVersionConsentToJSON,
  DocumentManualConsentDTO,
  DocumentManualConsentDTOFromJSON,
  DocumentManualConsentDTOToJSON,
} from '../models';

export interface DocumentManualConsentRequest {
  companyId: number;
  consentId: string;
  documentManualConsentDTO: DocumentManualConsentDTO;
}

export interface ListCompanyConsentsRequest {
  companyId: number;
}

export interface ListConsentContractVersionConsentsByContractVersionRequest {
  consentContractId: string;
  consentContractVersionId: string;
}

/**
 *
 */
export class ConsentContractVersionsConsentsApi extends runtime.BaseAPI {
  /**
   */
  async documentManualConsentRaw(
    requestParameters: DocumentManualConsentRequest
  ): Promise<runtime.ApiResponse<ConsentContractVersionConsent>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling documentManualConsent.'
      );
    }

    if (requestParameters.consentId === null || requestParameters.consentId === undefined) {
      throw new runtime.RequiredError(
        'consentId',
        'Required parameter requestParameters.consentId was null or undefined when calling documentManualConsent.'
      );
    }

    if (
      requestParameters.documentManualConsentDTO === null ||
      requestParameters.documentManualConsentDTO === undefined
    ) {
      throw new runtime.RequiredError(
        'documentManualConsentDTO',
        'Required parameter requestParameters.documentManualConsentDTO was null or undefined when calling documentManualConsent.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/company/{companyId}/consents/{consentId}/manual-consent`
        .replace(`{${'companyId'}}`, encodeURIComponent(String(requestParameters.companyId)))
        .replace(`{${'consentId'}}`, encodeURIComponent(String(requestParameters.consentId))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: DocumentManualConsentDTOToJSON(requestParameters.documentManualConsentDTO),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractVersionConsentFromJSON(jsonValue));
  }

  /**
   */
  async documentManualConsent(requestParameters: DocumentManualConsentRequest): Promise<ConsentContractVersionConsent> {
    const response = await this.documentManualConsentRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listCompanyConsentsRaw(
    requestParameters: ListCompanyConsentsRequest
  ): Promise<runtime.ApiResponse<Array<ConsentContractVersionConsent>>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling listCompanyConsents.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/company/{companyId}/consents`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(requestParameters.companyId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ConsentContractVersionConsentFromJSON));
  }

  /**
   */
  async listCompanyConsents(
    requestParameters: ListCompanyConsentsRequest
  ): Promise<Array<ConsentContractVersionConsent>> {
    const response = await this.listCompanyConsentsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listConsentContractVersionConsentsByContractVersionRaw(
    requestParameters: ListConsentContractVersionConsentsByContractVersionRequest
  ): Promise<runtime.ApiResponse<Array<ConsentContractVersionConsent>>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling listConsentContractVersionConsentsByContractVersion.'
      );
    }

    if (
      requestParameters.consentContractVersionId === null ||
      requestParameters.consentContractVersionId === undefined
    ) {
      throw new runtime.RequiredError(
        'consentContractVersionId',
        'Required parameter requestParameters.consentContractVersionId was null or undefined when calling listConsentContractVersionConsentsByContractVersion.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/contracts/{consentContractId}/versions/{consentContractVersionId}/consents`
        .replace(`{${'consentContractId'}}`, encodeURIComponent(String(requestParameters.consentContractId)))
        .replace(
          `{${'consentContractVersionId'}}`,
          encodeURIComponent(String(requestParameters.consentContractVersionId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ConsentContractVersionConsentFromJSON));
  }

  /**
   */
  async listConsentContractVersionConsentsByContractVersion(
    requestParameters: ListConsentContractVersionConsentsByContractVersionRequest
  ): Promise<Array<ConsentContractVersionConsent>> {
    const response = await this.listConsentContractVersionConsentsByContractVersionRaw(requestParameters);
    return await response.value();
  }
}
