import {ContractDuration} from './ContractDurationPicker';
import {getPaymentLink} from '../getPaymentLink';
import {Plan, plans} from '../../../utils/Plans';
import {Button} from 'antd';
import {SubscriptionPlan, SubscriptionPlans} from '../../../redux/ApiService/CompanyService/Company';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Icon} from '../../../atoms/Icon';

export const ChangePlanButton = ({
  targetPlan,
  subscribedPlanSlug,
  contractDuration,
  size,
}: {
  targetPlan: Plan;
  subscribedPlanSlug: SubscriptionPlan;
  contractDuration: ContractDuration;
  size?: 'small' | 'middle' | 'large';
}) => {
  const {slug: targetPlanSlug, title: currentPlanTitle} = targetPlan;
  const targetPlanIndex = plans.findIndex(p => p.slug === targetPlanSlug);
  const subscribedPlanIndex = plans.findIndex(p => p.slug === subscribedPlanSlug);
  const isHigherPlan = targetPlanIndex > subscribedPlanIndex;
  const isTargetPlanSameAsSubscribed = targetPlanSlug === subscribedPlanSlug;

  // If target plan and currently subscribed plan are the same, the button does not have an action
  if (isTargetPlanSameAsSubscribed) {
    return (
      <Button type="default" block size={size} data-testid="CurrentPlanButton">
        <Icon type="check-mark-line" /> Current Plan
      </Button>
    );
  }

  // Every action apart from switching from Free to Standard or Pro requires manual action.
  // see https://seanexxt.atlassian.net/wiki/spaces/SHIP/pages/3078750210/Subscription+Change+Flows

  // Switch from Paid to Free plan (= cancel subscription)
  if (targetPlanSlug === SubscriptionPlans.Free) {
    return (
      <ButtonLink to={'/subscription/cancel'}>
        <Button type="dashed" block size={size} data-testid="CancelPlanButton">
          Cancel Subscription
        </Button>
      </ButtonLink>
    );
  }

  // Switch from Free to Paid plan (= start subscription) uses the payment link
  if (subscribedPlanSlug === SubscriptionPlans.Free) {
    const paymentLink = getPaymentLink(targetPlanSlug, contractDuration);
    return (
      <ButtonLink to={paymentLink} target="_blank">
        <Button type="primary" block size={size} data-testid={`Subscribe${currentPlanTitle}Button`}>
          Upgrade to {currentPlanTitle}
        </Button>
      </ButtonLink>
    );
  }

  // Downgrade between paid plans
  if (!isHigherPlan) {
    return (
      <ButtonLink to={`/subscription/downgrade?plan=${targetPlanSlug}`}>
        <Button type="dashed" block size={size} data-testid={`Subscribe${currentPlanTitle}Button`}>
          Downgrade to {currentPlanTitle}
        </Button>
      </ButtonLink>
    );
  }

  // Upgrade between paid plans
  return (
    <ButtonLink to={`/subscription/upgrade?plan=${targetPlanSlug}`}>
      <Button type="primary" block size={size} data-testid={`Subscribe${currentPlanTitle}Button`}>
        Upgrade to {currentPlanTitle}
      </Button>
    </ButtonLink>
  );
};

const ButtonLink = styled(Link)`
  display: block;
  width: 100%;
`;
