import {FC, ReactNode} from 'react';
import styled from 'styled-components';

/**
 * Shows table cell content blurred.
 *
 * @param blurred whether blurring is active
 * @param children the cell content
 */
export const Blurred: FC<{blurred?: boolean; children: ReactNode}> = ({blurred, children}) => {
  return blurred ? <StyledBlur>{children}</StyledBlur> : <>{children}</>;
};

const StyledBlur = styled.div`
  filter: blur(5px);
  user-select: none;
`;
