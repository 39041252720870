import {useEffect, FC} from 'react';
import CompanyListUser from './CompanyListUser/CompanyListUser';
import CompanyActions from '../../../../redux/ApiService/CompanyService/CompanyService';
import CompanyUser from '../../../../model/company/CompanyUser';
import LoadingComponent from '../../../../components/DataGrid/LoadingComponent';
import {ModalActions} from '../../../../redux/Modal';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {AddUsersForm} from './AddUsersForm';
import {EditCompanyMember} from '../../../../components/Modal/EditCompanyMember/EditCompanyMember';
import {SettingsCard} from '../../../../components/SettingsCard/SettingsCard';
import {SettingsCardBody} from '../../../../components/SettingsCard/SettingsCardBody';
import {SettingsCardHeader} from '../../../../components/SettingsCard/SettingsCardHeader';
import {
  SettingsArea,
  SettingsAreaSubtitle,
  SettingsAreaTitle,
  SettingsAreaTitleWrapper,
  SettingsAreaWrapper,
} from '../../../../components/SettingsCard/SettingsArea';

interface EditCompanyMemberProps {
  callback: () => void;
  companyID: number;
  user: CompanyUser;
}

export const CompanySettingsUsers: FC<{company: Company}> = ({company}) => {
  const dispatch = useDispatch();
  const members = useSelector(state => state.company.users.map(user => new CompanyUser(user)));
  const userLoading: boolean = useSelector(state => state.company.userLoading);
  const showEditCompanyMemberModal = (props: EditCompanyMemberProps) => {
    dispatch(ModalActions.show(<EditCompanyMember {...props} />));
  };
  const getMembers = (id: number) => dispatch(CompanyActions.getMembers(id));
  const removeMember = (id: number, userID: [{userID: string}]) => dispatch(CompanyActions.removeMember(id, userID));

  useEffect(() => {
    getMembers(company.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id]);

  const removeUser = async (userID: string) => {
    await removeMember(company.id, [{userID: userID}]);
    getMembers(company.id);
  };

  const editUser = (user: CompanyUser) => {
    showEditCompanyMemberModal({
      user,
      companyID: company.id,
      callback: () => {
        getMembers(company.id);
      },
    });
  };

  return (
    <SettingsCard data-testid="CompanySettingsUsers">
      <SettingsCardHeader title={'Users'} data-testid="users" />
      <SettingsCardBody>
        <SettingsArea>
          <SettingsAreaWrapper>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>Add users to your company</SettingsAreaTitle>
              <SettingsAreaSubtitle>
                A seabo account will be created for your users. They will get notified via email and will need to
                confirm their email
              </SettingsAreaSubtitle>
            </SettingsAreaTitleWrapper>
            <div>
              <AddUsersForm
                companyId={company.id}
                onUsersAdded={() => {
                  getMembers(company.id);
                }}
              />
            </div>
          </SettingsAreaWrapper>
        </SettingsArea>

        <SettingsArea>
          <SettingsAreaWrapper>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>Users currently in your company</SettingsAreaTitle>
            </SettingsAreaTitleWrapper>
            <div style={{minHeight: '250px'}}>
              {userLoading ? (
                <LoadingComponent style={{textAlign: 'center'}} />
              ) : (
                <CompanyListUser
                  users={members}
                  onRemove={(user: CompanyUser) => removeUser(user.id)}
                  onEdit={(user: CompanyUser) => editUser(user)}
                />
              )}
            </div>
          </SettingsAreaWrapper>
        </SettingsArea>
      </SettingsCardBody>
    </SettingsCard>
  );
};
