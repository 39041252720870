import {ModalActions} from '../../redux/Modal';
import {useDispatch} from 'react-redux';
import Icon from '../../atoms/Icon';
import styled from 'styled-components';
import classNames from 'classnames';
import {numberFormat} from '../../utils/formatter';
import {TODO} from '../../utils/TODO';
import {FixtureRateTerm, fixtureRateTermTypes} from '../Modal/ManageFixture/ManageFixtureForm/utils';
import ManageFixtureModal from '../Modal/ManageFixture/ManageFixtureModal';

export const RateFixtureColumn = (props: {
  fixture: TODO;
  updateVessel?: boolean;
  onFixtureChanged?: (params?: TODO) => void;
}) => {
  const {fixture} = props;
  const dispatch = useDispatch();
  const {options} = fixture;

  const rateTerms = options[0] && options[0].rateTerms;

  return (
    <Cell
      onClick={() => {
        dispatch(
          ModalActions.show(
            <ManageFixtureModal
              updateVessel={props.updateVessel}
              formData={fixture}
              callback={props.onFixtureChanged}
            />
          )
        );
      }}
      data-cy={'FixtureGridRateCell'}
      className={'market-grid-cell fixture-grid-cell__rate'}>
      <div>
        <div
          className={classNames('market-grid-cell-content', {
            'market-grid-cell-content--no-sub': !(options[0] && options[0].rate),
          })}>
          {options[0] && options[0].rate ? numberFormat(options[0].rate) : '-'}
        </div>
        <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
          {options[0] && options[0].rate && (
            <span>
              <span style={{textTransform: 'uppercase'}}>{options[0].rateCurrency}</span>{' '}
              {fixtureRateTermTypes[rateTerms as FixtureRateTerm]}
            </span>
          )}
        </div>
      </div>
      <div style={{display: 'flex', marginLeft: 8, alignItems: 'center'}}>
        <Icon className={'editIcon'} type={'edit'} />
      </div>
    </Cell>
  );
};

const Cell = styled.div`
  cursor: pointer;
  display: flex;
  .editIcon {
    visibility: hidden;
  }
  &:hover {
    .editIcon {
      visibility: visible;
    }
  }
`;
