import React, {useState, FC, useEffect} from 'react';
import useInterval from 'beautiful-react-hooks/useInterval';
import styled from 'styled-components';
import {Icon} from '../../../../atoms/Icon';
import {UserDateTime} from '../../../../components/DateTime/UserDateTime';
import {formatReplyTimeDuration, getIsExpired, getTimeLeftMs} from './utils';
import {Dayjs} from 'dayjs';
import {Switch} from 'antd';

type CountdownLabelProps = {
  expiryDate: Dayjs;
  dateTimePrefix?: string;
  dateTimeSuffix?: string;
  countdownPrefix?: string;
  countdownSuffix?: string;
  expiredText?: string;
  countdownModeDefault?: boolean;
  isSwitchVisible?: boolean;
};

export const CountdownLabel: FC<CountdownLabelProps> = ({
  expiryDate,
  dateTimePrefix,
  dateTimeSuffix,
  countdownPrefix,
  countdownSuffix,
  expiredText = 'expired',
  countdownModeDefault = false,
  isSwitchVisible = true,
}) => {
  const [timeLeftMs, setTimeLeftMs] = useState<number>(getTimeLeftMs(expiryDate));
  const [countdownMode, setCountdownMode] = useState(false);

  useEffect(() => {
    setCountdownMode(countdownModeDefault);
  }, [countdownModeDefault]);

  const [_, clearInterval] = useInterval(() => {
    if (!expiryDate) {
      clearInterval();
      return;
    }
    if (getIsExpired(expiryDate)) {
      // set countdownMode true to show expiredText when time is up
      setCountdownMode(true);
      setTimeLeftMs(0);
      clearInterval();
      return;
    }
    setTimeLeftMs(getTimeLeftMs(expiryDate));
  }, 1000);

  const toggleCountdownMode = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCountdownMode(mode => !mode);
  };

  const isExpired = getIsExpired(expiryDate);

  const CountdownText = () =>
    isExpired ? (
      <CountdownContainer>{expiredText}</CountdownContainer>
    ) : (
      <>
        {countdownPrefix}
        <CountdownContainer>{formatReplyTimeDuration(timeLeftMs)}</CountdownContainer>
        {countdownSuffix}
      </>
    );

  const DateTimeText = () => (
    <>
      {dateTimePrefix}
      <UserDateTime value={expiryDate.toISOString()} format="dateTime" />
      {dateTimeSuffix}
    </>
  );

  return (
    <CountdownLabelContainer
      data-testid="CountdownLabel"
      $color={isExpired ? 'red' : 'black'}
      $bold={countdownMode}
      onClickCapture={toggleCountdownMode}>
      <NoWrap data-testid={countdownMode ? 'TimeLeft' : 'ExpiryDate'}>
        {countdownMode ? <CountdownText /> : <DateTimeText />}
      </NoWrap>
      {isSwitchVisible && (
        <CountdownSwitch
          size="small"
          checked={countdownMode}
          checkedChildren={<ToggleIcon type="time-outline" />}
          unCheckedChildren={<ToggleIcon type="date" />}
        />
      )}
    </CountdownLabelContainer>
  );
};

const CountdownLabelContainer = styled.span<{$color?: 'red' | 'black'; $bold?: boolean}>`
  color: var(--color-${({$color}) => $color});
  font-weight: ${({$bold}) => ($bold ? 'bold' : 'normal')};
  cursor: pointer;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const CountdownContainer = styled.span`
  user-select: none;
`;

const CountdownSwitch = styled(Switch)`
  margin-left: 5px;
  margin-bottom: 2px;
  width: 32px;
  &,
  &.ant-switch-checked {
    background: var(--color-gray-3);
    &:hover:not(.ant-switch-disabled) {
      background: var(--color-gray-2);
    }
  }
`;

const ToggleIcon = styled(Icon)`
  position: relative;
  top: 0.1em;
  margin-left: -2px;
  vertical-align: baseline;
  font-size: inherit !important;
  color: inherit !important;
`;
