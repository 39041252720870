import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {accountManagementApi} from '../../api/symfony/symfonyApi';
import {UpdateUserOnboardingState} from '../../api/symfony/generated';
import {ApiError} from '../../api/utils/ApiError';

export const useUpdateAccountOnboardingStateMutation = (
  options?: UseMutationOptions<void, ApiError, UpdateUserOnboardingState>
) =>
  useMutation({
    mutationFn: async (updateUserOnboardingState: UpdateUserOnboardingState) => {
      return await accountManagementApi.updateUserOnboardingState({
        updateUserOnboardingState,
      });
    },
    ...options,
  });

export const useSetAccountIsOnboardedMutation = (options?: UseMutationOptions<void>) =>
  useMutation({
    mutationFn: async () => {
      return await accountManagementApi.updateUserOnboardingState({
        updateUserOnboardingState: {
          isOnboarded: true,
        },
      });
    },
    ...options,
  });

export const useSetAccountSkippedOnboardingMutation = (options?: UseMutationOptions<void>) =>
  useMutation({
    mutationFn: async () => {
      return await accountManagementApi.updateUserOnboardingState({
        updateUserOnboardingState: {
          onboardingSkippedAt: new Date(),
        },
      });
    },
    ...options,
  });
