import {Coordinates} from '../../../utils/Coordinates';
import {geoBounds} from 'd3-geo';
import {polygon} from '@turf/helpers';

export const getBoundsFromCoordinates = (coords: Coordinates[]): [Coordinates, Coordinates] => {
  if (coords.length === 0) {
    throw new Error('Cannot fit to empty coordinates');
  }
  if (coords.length === 1) {
    return [coords[0], coords[0]];
  }
  if (coords.length === 2) {
    return [coords[0], coords[1]];
  }

  coords.push(coords[0]);
  const bounds = geoBounds(polygon([coords]));
  if (bounds[0][0] >= 0 && bounds[1][0] < 0) {
    bounds[1][0] += 360;
  }

  return bounds;
};
