import {FeatureCollection} from 'geojson';
import {Coordinates} from '../../../../utils/Coordinates';
import {ChokepointKey} from './useChokepointCongestionQuery';
import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../../api/utils/fetchJSON';
import {RGBAColor} from '../../../../utils/deck.gl/deck.gl-types';

export const RED: RGBAColor = [255, 0, 0, 0.6 * 255];
export const GREEN: RGBAColor = [0, 255, 0, 0.6 * 255];

export type ChokepointMeta = Record<
  ChokepointKey,
  {
    title: string;
    centerPoint?: Coordinates;
    zoom?: number;
  }
>;

export const chokepointMeta: ChokepointMeta = {
  bosphorus: {
    title: 'Bosphorus',
    centerPoint: [29.05381, 41.12519],
    zoom: 8.5,
  },
  suez: {
    title: 'Suez Canal',
    centerPoint: [32.36924, 30.57801],
    zoom: 6.5,
  },
  panama: {
    title: 'Panama Canal',
    centerPoint: [-79.75, 9.15],
    zoom: 8,
  },
};

export const useChokepointPolygonQuery = () =>
  useQuery({
    queryKey: ['chokepointPolygons'],
    queryFn: async () => {
      return await fetchJSON<Record<ChokepointKey, FeatureCollection>>('/static/jsons/chokepointPolygons.json');
    },
  });
