import {CalcTimeLinePoint} from '../../VoyageTypes';
import {useSelector} from '../../../../../../redux/react-redux';
import {parseIntTS} from '../../../../../../utils/parseNumberTS';
import {useGetPortDAQuery} from './PointEditView/useGetPortDAQuery';

export const usePortDAForVoyagePointQuery = (point: CalcTimeLinePoint) => {
  const vesselImoString = useSelector(state => state.charterCalculation.inputState.vessel.imo);
  const vesselImo = vesselImoString ? parseIntTS(vesselImoString) : undefined;
  const eta = point.outputItem?.startDate;
  const quantity = point.item.load + point.item.dis;
  const portUNLoCode = point.item.originalPort?.code;

  return useGetPortDAQuery({
    portUNLoCode,
    eta,
    quantity,
    imo: vesselImo,
    options: {
      additionalEnabled: !!quantity,
    },
  });
};
