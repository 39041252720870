import {ChokepointCongestionSummaryReport, SeaboVessel, Visit} from '../../../../api/node-backend/generated';
import {useQuery} from '@tanstack/react-query';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {ChokepointKey, useChokepointCongestionQuery} from './useChokepointCongestionQuery';
import {getVesselsFromVesselDatabaseByImos} from '../../utils/getVesselsFromVesselDatabaseByImos';
import {getVesselSize} from '../../../../hooks/useGetCompetitors/VesselSizeType';

export type MergedVisits = Omit<SeaboVessel, 'vesselType' | 'color'> & Visit & {label: string; color: string};

export interface CongestionVisitsWithVesselInformationsResult {
  finishedVisits: MergedVisits[];
  currentVisits: MergedVisits[];
  report: ChokepointCongestionSummaryReport;
  queryTimestamp: string;
}

export const useCongestionVisitsWithVesselInformationsQuery = (
  chokepointKey: ChokepointKey,
  options?: UseQueryOptions<CongestionVisitsWithVesselInformationsResult>
) => {
  const congestionQuery = useChokepointCongestionQuery(chokepointKey);

  return useQuery({
    queryKey: ['useCongestionVisitsWithVesselInformationQuery', chokepointKey],
    queryFn: async () => {
      const congestionAreaStatistics = congestionQuery.data!;

      const allVisitsWithSpireInformation = await extendVisitsWithSpireInformations(congestionAreaStatistics.visits);

      // Split all visits in current and historical visits
      const finishedVisits = allVisitsWithSpireInformation.filter(vessel => !vessel.isCurrentlyInTheArea);
      const currentVisits = allVisitsWithSpireInformation.filter(vessel => vessel.isCurrentlyInTheArea);

      return {
        finishedVisits,
        report: congestionAreaStatistics.report,
        currentVisits,
        queryTimestamp: congestionAreaStatistics.queryTimestamp,
      };
    },
    ...options,
    enabled: congestionQuery.isSuccess && options?.enabled,
  });
};

const extendVisitsWithSpireInformations = async (visits: Visit[]): Promise<MergedVisits[]> => {
  const allVesselsStatsImos = visits.map(vessel => vessel.imo);

  const allVessels = await getVesselsFromVesselDatabaseByImos(allVesselsStatsImos);

  const allVisitsWithVesselInformation: MergedVisits[] = visits.map(statVessel => {
    const vessel = allVessels.find(vessel => vessel.imo === statVessel.imo);
    const vesselSize = vessel?.dwtSummer ? getVesselSize(vessel.dwtSummer) : undefined;
    const label = vesselSize?.label ?? 'Other';
    const color = vesselSize?.color ?? 'black';
    return {
      ...vessel!,
      ...statVessel,
      label,
      color,
    };
  });

  return allVisitsWithVesselInformation;
};
