/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordResetRequest
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof PasswordResetRequest
   */
  password: string;
}

export function PasswordResetRequestFromJSON(json: any): PasswordResetRequest {
  return PasswordResetRequestFromJSONTyped(json, false);
}

export function PasswordResetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: json['token'],
    password: json['password'],
  };
}

export function PasswordResetRequestToJSON(value?: PasswordResetRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
    password: value.password,
  };
}
