import Title from '../../../atoms/Title';
import {numberFormat} from '../../../utils/formatter';
import {FormSummary, SummaryContent, SummaryHeader, SummaryLabel, SummaryValue} from '../CargoVesselForm.styled';

const getSummaryData = (general: $TSFixMe, intake: $TSFixMe) => {
  const teu = intake.intakes.find((d: $TSFixMe) => d.type === 'teu');
  const teu14 = intake.intakes.find((d: $TSFixMe) => d.type === 'teu14');

  if (general) {
    switch (general.vesselType) {
      case 'bulker':
        return [
          {
            label: 'IMO',
            value: general.imo ? general.imo : 'N/A',
          },
          {
            label: 'TYPE',
            value: 'Bulker',
          },
          {
            label: 'DWT',
            value: numberFormat(intake.dwtSummer),
          },
        ];
      case 'container':
        return [
          {
            label: 'IMO',
            value: general.imo ? general.imo : 'N/A',
          },
          {
            label: 'TYPE',
            value: 'Container',
          },
          {
            /* prettier-ignore */
            label: teu ? 'TEU' : 'TEU14',
            /* prettier-ignore */
            value: teu ? numberFormat(teu.quantity) : teu14 && numberFormat(teu14.quantity),
          },
        ];
      case 'mpp':
        return [
          {
            label: 'IMO',
            value: general.imo ? general.imo : 'N/A',
          },
          {
            label: 'TYPE',
            value: 'MPP',
          },
          {
            label: 'DWT',
            value: numberFormat(intake.dwtSummer),
          },
        ];
      default:
        return [
          {
            label: 'IMO',
            value: 'N/A',
          },
          {
            label: 'TYPE',
            value: 'N/A',
          },
          {
            label: 'DWT',
            value: 'N/A',
          },
        ];
    }
  }
};

const Summary = ({form}: $TSFixMe) => {
  if (form && form.general && form.intake) {
    return (
      <FormSummary>
        <SummaryHeader>
          <Title center large>
            {form.general && form.general.name}
          </Title>
          <SummaryContent>
            {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
            {getSummaryData(form.general, form.intake).map(({label, value}, i) => (
              <div key={i}>
                <SummaryLabel>{label}</SummaryLabel>
                <SummaryValue>{value || 'N/A'}</SummaryValue>
              </div>
            ))}
          </SummaryContent>
        </SummaryHeader>
      </FormSummary>
    );
  }
  return null;
};

export default Summary;
