import {Modal, Select, Form} from 'antd';
import {useState, FC} from 'react';
import styled from 'styled-components';
import {isLocalDevEnvironment} from '../../utils/environment';

type FormValues = {
  isUsingLocalNodeBackend: 'TRUE' | 'FALSE';
};

export const isUsingLocalNodeBackendLocalStorageKey = 'isUsingLocalNodeBackend';

export const isUsingLocalNodeBackend = (): boolean =>
  window.localStorage.getItem(isUsingLocalNodeBackendLocalStorageKey) === 'TRUE';

export const NodeBackendConfigModal: FC = () => {
  const [configModalVisible, setConfigModalVisible] = useState<boolean>(false);

  const [form] = Form.useForm();

  if (!isLocalDevEnvironment()) {
    return null;
  }

  return (
    <>
      <NodeBackendConfigButton onClick={() => setConfigModalVisible(true)}>
        <UseLocalOrRemoteLabel>{isUsingLocalNodeBackend() ? 'Local' : 'Remote'}</UseLocalOrRemoteLabel>🐶
      </NodeBackendConfigButton>

      <Modal
        title={'Node backend configuration'}
        open={configModalVisible}
        onCancel={() => setConfigModalVisible(false)}
        okText={'Save and reload'}
        okButtonProps={{
          onClick: () => {
            form.submit();
          },
        }}>
        <Form<FormValues>
          form={form}
          onFinish={({isUsingLocalNodeBackend}: FormValues) => {
            window.localStorage.setItem(isUsingLocalNodeBackendLocalStorageKey, isUsingLocalNodeBackend);
            window.location.reload();
          }}
          initialValues={{
            isUsingLocalNodeBackend: isUsingLocalNodeBackend() ? 'TRUE' : 'FALSE',
          }}>
          <Form.Item label={'Node backend origin'} name={'isUsingLocalNodeBackend'}>
            <Select>
              <Select.Option value={'FALSE'}>Remote node backend (for example: beta)</Select.Option>
              <Select.Option value={'TRUE'}>Local node backend </Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const NodeBackendConfigButton = styled.a`
  z-index: 101;
  position: fixed;
  bottom: 8px;
  text-align: center;
  right: 12px;
  font-size: var(--font-size-5xl);
`;

const UseLocalOrRemoteLabel = styled.span`
  font-size: var(--font-size-lg);
  color: var(--color-gray-2);
  display: block;
  margin-bottom: -8px;
  background-color: var(--color-white);
  line-height: 1;
  padding: 2px;
`;
