import cx from 'classnames';
import {MapToggleCheckbox} from './MapToggleCheckbox';
import {MapSwitch} from '../MapDetails/utils/types';
import {FC} from 'react';
import {SubMenuOption} from '../mapOptions/mapOptions';

type SubMenuProps = {
  options: SubMenuOption[];
  values: MapSwitch;
  label?: string;
  onChange: (subMenuOptionId: string) => void;
  selectAll: () => void;
  unSelectAll: () => void;
  visualDisabled: boolean;
  header?: boolean;
};

export const SubMenu: FC<SubMenuProps> = ({
  options,
  values,
  label,
  onChange,
  selectAll,
  unSelectAll,
  visualDisabled,
  header = true,
}) => {
  const someAreUnchecked = Object.values(values).some(v => !v);

  return (
    <div className={cx('map-sub-options', visualDisabled && 'map-sub-options--disabled')}>
      {header && (
        <div className="map-sub-options__header">
          {label}
          <span className={'map-sub-options__deselect-all'} onClick={someAreUnchecked ? selectAll : unSelectAll}>
            {someAreUnchecked ? ' Select all' : ' Deselect all'}
          </span>
        </div>
      )}
      {!header && <div className={'map-sub-options__header_spacer'} />}
      {options.map((option, idx) => (
        <MapToggleCheckbox
          key={idx}
          label={option.label}
          id={`map-menu-sub-option-${option.id}`}
          visualDisabled={visualDisabled}
          checked={values[option.id]}
          onChange={() => onChange(option.id)}
        />
      ))}
    </div>
  );
};
