import {AsyncMultiSelect} from '../../../../../../atoms/Select/AsyncMultiSelect';
import {DropdownMultiValueLocation, getLocationSelectOption} from '../../../../../LocationOutput/LocationOutputRow';
import {GroupHeading} from '../../../../../../atoms/Select/otherComponents';
import {FC} from 'react';
import {Form} from 'antd';
import {FormValues} from '../../FormValues';
import {useDispatch} from '../../../../../../redux/react-redux';
import locationApi from '../../../../../../redux/ApiService/locationService';
import {Station} from '../../UpdateFixtureRequestSchema/UpdateFixtureRequestSchema';
import {TODO} from '../../../../../../utils/TODO';

export const StationPicker: FC<{
  stationType: string;
}> = ({stationType}) => {
  const form = Form.useFormInstance<FormValues>();

  const dispatch = useDispatch();
  const searchAll = (search: string) => dispatch(locationApi.searchAll(search));

  const stations = Form.useWatch<FormValues['stations']>('stations', form) ?? [];
  const stationIndex = stations.findIndex(station => station.type === stationType);
  const station = stationIndex >= 0 ? stations[stationIndex] : undefined;
  const locations = station?.locations ?? [];

  return (
    <AsyncMultiSelect
      id={`form-stations-${stationType}`}
      name={`form-stations-${stationType}`}
      value={locations}
      // @ts-expect-error Type is not assignable to type.
      onChange={(newLocations: {id: number; type: string}[]) => {
        const newStation: Station = {
          type: stationType as Station['type'],
          locations: newLocations,
        };
        const newStations: Station[] = [...stations];

        if (stationIndex === -1) {
          newStations.push(newStation);
        } else {
          newStations[stationIndex] = newStation;
        }

        form.setFieldsValue({stations: newStations});
      }}
      getOptionLabel={(o: TODO) => getLocationSelectOption(o, true)}
      getOptionValue={(option: TODO) => option.id}
      loadOptions={(q: TODO) => (q.length > 1 ? searchAll(q) : Promise.resolve([]))}
      cacheOptions
      defaultOptions
      placeholder="Enter port, country or area"
      components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
    />
  );
};
