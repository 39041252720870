import DateTime from '../../../../model/DateTime';
import Station from '../../../../model/Station';
import displayGridUtils from '../../GridDisplay/displayGridUtils';
import {numberRangeUnitFormat} from '../../../../utils/formatter';
import {ContractType} from '../../../../api/symfony/generated';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

export const getDurationUnit = (unit: $TSFixMe, value: $TSFixMe) => {
  switch (unit) {
    case 'years':
      return `year${value !== 1 ? 's' : ''}`;
    case 'months':
      return `month${value !== 1 ? 's' : ''}`;
    case 'weeks':
      return `week${value !== 1 ? 's' : ''}`;
    case 'days':
      return `day${value !== 1 ? 's' : ''}`;
    default:
      return unit;
  }
};

const returnNAIfEmpty = (text: $TSFixMe) => (text.trim().length === 0 ? '-' : text);

const _getYear = (date: $TSFixMe) => DateTime.fromServerResponse(date).getFormattedDate('YYYY');

const dateRangeString = (fromDate: $TSFixMe, toDate: $TSFixMe, sep = ' - ') => {
  let fromString = fromDate ? DateTime.fromServerResponse(fromDate).getFormattedDate() : '';
  const toString = toDate ? DateTime.fromServerResponse(toDate).getFormattedDate() : '';

  if (_getYear(fromDate) === _getYear(toDate)) {
    fromString = fromDate
      ? DateTime.fromServerResponse(fromDate).getFormattedDate(toDate ? DateTimeFormat.Date : DateTimeFormat.Date)
      : '';
    return returnNAIfEmpty(`${fromString}${fromDate && toDate ? sep : ''}${toString}`);
  }

  return returnNAIfEmpty(`${fromString}${fromDate && toDate ? sep : ''}${toString}`);
};

const formatMinMax = (min: $TSFixMe, max: $TSFixMe, unit: $TSFixMe) => {
  return numberRangeUnitFormat(min, max, unit, {emptyString: '-'});
};

class CargoUtil {
  static formatDuration({contractType, durationMin, durationMax, durationUnit, durationAbout}: $TSFixMe) {
    let string = '-';
    if (contractType !== ContractType.Vc) {
      string = '';
      if (durationAbout && (durationMin || durationMax)) {
        string += '~';
      }
      string += numberRangeUnitFormat(
        durationMin,
        durationMax,
        getDurationUnit(durationUnit, Math.max(durationMin, durationMax)),
        {
          toUpperCase: false,
          emptyString: '-',
        }
      );
    }

    return string;
  }
  static formatLaycan({laycanFrom, laycanTo}: $TSFixMe) {
    return dateRangeString(laycanFrom, laycanTo);
  }
  static formatPeriod({periodFrom, periodTo}: $TSFixMe) {
    return dateRangeString(periodFrom, periodTo);
  }

  static formatSizeQuantity = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contractType,
    vesselSizeMin,
    vesselSizeMax,
    quantityMin,
    quantityMax,
    quantityUnit,
    vesselSizeUnit,
    intakes,
    cargoType,
  }: $TSFixMe) => {
    let string = '-';
    if (cargoType === 'container') {
      if (intakes.length > 0) {
        string = displayGridUtils.getIntake(intakes, 'teu');
        string += ' teu';
      } else if (vesselSizeMin || vesselSizeMax) {
        string = formatMinMax(vesselSizeMin, vesselSizeMax, vesselSizeUnit);
      }
    } else if (cargoType === 'drybulk') {
      if (vesselSizeMin || vesselSizeMax) {
        string = formatMinMax(vesselSizeMin, vesselSizeMax, vesselSizeUnit);
      } else if (quantityMin || quantityMax) {
        string = formatMinMax(quantityMin, quantityMax, quantityUnit);
      }
    }

    return string;
  };

  static formatCommissionTotal({commissionTotal}: $TSFixMe) {
    if (commissionTotal !== null && !isNaN(parseFloat(commissionTotal))) {
      return commissionTotal + '%';
    }

    return '-';
  }

  static loadingLocation({contractType, stations}: $TSFixMe, emptyState: $TSFixMe, showFlag?: boolean) {
    const stationType =
      // eslint-disable-next-line no-nested-ternary
      [ContractType.Pc, ContractType.Tct, ContractType.Bc].indexOf(contractType) > -1
        ? Station.DELIVERY
        : contractType === ContractType.Vc
        ? Station.LOADING
        : '';
    return Station.getLocationByStationType(stations, stationType === '' ? undefined : stationType, {
      short: true,
      emptyState,
      highlight: true,
      showFlag,
    });
  }

  static dischargeLocation({contractType, stations}: $TSFixMe, emptyState: $TSFixMe, showFlag?: boolean) {
    const stationType =
      // eslint-disable-next-line no-nested-ternary
      [ContractType.Pc, ContractType.Tct, ContractType.Bc].indexOf(contractType) > -1
        ? Station.REDELIVERY
        : contractType === ContractType.Vc
        ? Station.DISCHARGE
        : '';
    return Station.getLocationByStationType(stations, stationType === '' ? undefined : stationType, {
      short: true,
      emptyState,
      highlight: true,
      showFlag,
    });
  }
}

export default CargoUtil;
