/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ReplyTimeUpdate
 */
export interface ReplyTimeUpdate {
  /**
   *
   * @type {string}
   * @memberof ReplyTimeUpdate
   */
  timestamp?: string | null;
  /**
   * Duration in milliseconds since the commit of the related round.
   * @type {number}
   * @memberof ReplyTimeUpdate
   */
  duration?: number | null;
}

export function ReplyTimeUpdateFromJSON(json: any): ReplyTimeUpdate {
  return ReplyTimeUpdateFromJSONTyped(json, false);
}

export function ReplyTimeUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplyTimeUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timestamp: !exists(json, 'timestamp') ? undefined : json['timestamp'],
    duration: !exists(json, 'duration') ? undefined : json['duration'],
  };
}

export function ReplyTimeUpdateToJSON(value?: ReplyTimeUpdate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timestamp: value.timestamp,
    duration: value.duration,
  };
}
