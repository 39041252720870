import {VesselConsumptionModeUseCase} from './VesselTypes';
import {assertUnreachable} from '../../../../utils/assert';

export const getVesselConsumptionUseCaseLabel = (useCase: VesselConsumptionModeUseCase): string => {
  switch (useCase) {
    case 'portidle':
      return 'port idle';
    case 'portworking':
      return 'port working';
    // Fallthrough
    case 'ballast':
    case 'laden':
      return useCase;
    default:
      assertUnreachable(useCase);
  }
};
