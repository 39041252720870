import React, {ReactNode, FC} from 'react';
import styled from 'styled-components';
import {SeaboLogoWithText} from '../SeaboLogo/SeaboLogo';
import {Row} from 'antd';

export const PageHeader: FC<{rightChildren?: ReactNode}> = ({rightChildren}) => {
  return (
    <Layout>
      <RowStyled align="middle" justify="space-between">
        <SeaboLogoWithLink />
        {rightChildren ?? <div />}
      </RowStyled>
    </Layout>
  );
};

export const Layout = styled.header`
  padding-left: 10vw;
  padding-right: 10vw;
  z-index: 1;
`;

const RowStyled = styled(Row)`
  height: 80px;
`;

const SeaboLogoWithLink = () => {
  return (
    <a href="/">
      <SeaboLogoStyled />
    </a>
  );
};

const SeaboLogoStyled = styled(SeaboLogoWithText)`
  margin-top: 10px;
  width: 200px;
  display: block;
  color: var(--color-white);
  fill: var(--color-white);
`;
