import {Table} from 'antd';
import styled from 'styled-components';

export const StyledTable = styled<typeof Table>(Table)`
  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: var(--font-size-sm);
    color: var(--color-gray-2);
    text-transform: uppercase;
  }
` as typeof Table;
