import styled from 'styled-components';
import Flag from '../../atoms/Flags';
import Country from '../../model/Country';

import {NotAvailable} from '../../utils/NotAvailable';

type PortTitleWithFlagProps = {
  port: {
    country?: string;
    name?: string;
    code?: string;
  };
  label?: string;
};

export const PortTitleWithFlag = ({port, label}: PortTitleWithFlagProps) => {
  const country = Country.getName(port?.country) || Country.getFromMid(port?.code);
  const countryLabel = country ? `${country}, ` : '';
  const codeLabel = port?.code ? `${port?.code}` : '';
  const bracketsLabel = countryLabel || codeLabel ? ` (${countryLabel}${port?.code})` : '';
  const defaultLabel = port ? `${port.name}${bracketsLabel}` : NotAvailable;

  return (
    <>
      {port.country && <StyledFlag shadow={true} countryCode={port?.country} />}
      {label || defaultLabel}
    </>
  );
};

const StyledFlag = styled(Flag)`
  margin-right: 5px;
`;
