import React, {useContext, useMemo} from 'react';
import {Tooltip} from 'antd';

import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {WarningArea, WarningProps} from './WarningTypes';
import styled from 'styled-components';
import Icon from '../../../../atoms/Icon';

export const useGetMinHeightWithWarnings = (props: WarningProps) => {
  const voyageContext = useContext(VoyageCharterContext);

  const {outputState} = voyageContext.state;

  return useMemo(() => {
    const list = props.area
      ? outputState.warning.list.filter(x => x.areas.includes(props.area as WarningArea))
      : outputState.warning.list;

    return 28 + list.length * 24;
  }, [props.area, outputState.warning.list]);
};

export const WarningsInVoyage = (props: WarningProps) => {
  const voyageContext = useContext(VoyageCharterContext);

  const {outputState} = voyageContext.state;

  const list = props.area
    ? outputState.warning.list.filter(x => x.areas.includes(props.area as WarningArea))
    : outputState.warning.list;
  return (
    <StyledContainer>
      {list.map(item => {
        return (
          <Tooltip key={item.code} placement={'right'} title={item.message}>
            <li onClick={() => props.onClickWarning?.(item)}>
              <Icon size={'medium'} color={item.type === 'warning' ? 'gray' : 'red'} type={'block'} />
            </li>
          </Tooltip>
        );
      })}
    </StyledContainer>
  );
};

const StyledContainer = styled.ul`
  position: absolute;
  top: 40px;
  left: -30px;
  list-style-type: none;
  padding: 0;
  width: 20px;
  li {
    margin: 0;
    cursor: pointer;
  }
`;
