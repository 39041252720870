import {ArrowsAltOutlined} from '@ant-design/icons';
import React, {FC, useEffect, useMemo} from 'react';
import {getVesselPositionHistory} from '../../redux/ApiService/MapService/MapService';
import styled from 'styled-components';
import {AisVessel} from '../../api/symfony/generated';
import {LoadingIndicator} from '../../atoms/Loading';
import {PortLocation} from '../../components/ProjectDetailsScreen/types';
import {PulsatingCirclesLayer} from '../../components/SeaboMap/layers/mapbox/PulsatingCirclesLayer';
import {SinglePortLayer} from '../../components/SeaboMap/layers/SinglePortLayer/SinglePortLayer';
import {useVesselRouteLayer} from '../../components/SeaboMap/layers/VesselRouteLayer/VesselRouteLayer';
import {SeaboMap} from '../../components/SeaboMap/SeaboMap';
import {useVesselHistoryQuery} from '../../queries/useVesselHistoryQuery';
import {useSelector} from '../../redux/react-redux';
import {
  CoordinatesObject,
  LonLatCoordinates,
  transformLonLatFromObject,
  transformObjectFromLonLat,
} from '../../utils/Coordinates';
import {getSelectedCoordinates} from './sections/MapSection/getSelectedCoordinates';
import {AisDetails} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {TODO} from '../../utils/TODO';

type MapPartialProps = {
  centerPoint?: CoordinatesObject;
  aisDetails?: AisDetails | null;
  portDetails?: PortLocation[];
  toggleMapIsFocused?: () => void;
};

export const MapPartial: FC<MapPartialProps> = ({centerPoint, aisDetails, portDetails, toggleMapIsFocused}) => {
  const selectedTimestamp = useSelector(state => state.vesselDetails.selectedTimestamp);
  const historyQuery = useVesselHistoryQuery(aisDetails?.imo, {enabled: !!aisDetails?.imo});

  const selectedRoutePointCoordinates = useMemo(
    () =>
      getSelectedCoordinates({
        timestamp: selectedTimestamp,
        route: historyQuery.data?.route,
      }),
    [historyQuery.data?.route, selectedTimestamp]
  );

  const vesselCoordinates: LonLatCoordinates = useMemo(() => {
    if (!centerPoint) {
      return [0, 0];
    }
    return transformLonLatFromObject(centerPoint);
  }, [centerPoint]);

  const coordinatesToFocus = useMemo(() => {
    const historicalRoute = historyQuery.data?.route.map(routeItem => routeItem.coordinates) ?? [];
    const coordinatesToFocus = [vesselCoordinates, ...historicalRoute];

    return coordinatesToFocus;
  }, [historyQuery.data?.route, vesselCoordinates]);

  useEffect(() => {
    if (aisDetails?.imo) {
      getVesselPositionHistory(aisDetails.imo, 30);
    }
  }, [aisDetails]);

  const vesselRouteLayer = useVesselRouteLayer({
    imo: aisDetails?.imo,
    arrowSize: 8,
    zoom: 4,
    arrowOpacity: 0.33,
  });

  const layers = useMemo(() => {
    const layers: TODO[] = portDetails?.map((port: PortLocation) => new SinglePortLayer({port})) ?? [];
    layers.push(vesselRouteLayer);
    return layers;
  }, [vesselRouteLayer, portDetails]);

  if (!historyQuery.isSuccess) {
    return (
      <MapWrapper>
        <LoadingWrapper>
          <LoadingIndicator width={'80px'} />
        </LoadingWrapper>
      </MapWrapper>
    );
  }

  const aisVessel = convertAisDetailsToVessel(aisDetails);

  return (
    <MapWrapper>
      <StyledSeaboMap
        layersOnTopOfMapBox={[
          <PulsatingCirclesLayer
            key={'vesselPostion'}
            points={selectedRoutePointCoordinates ? [transformObjectFromLonLat(selectedRoutePointCoordinates)] : []}
            color={'red'}
          />,
        ]}
        vesselsToVisualize={{
          data: [aisVessel],
          constantIconSize: 8,
        }}
        initialViewState={{
          zoom: 2,
          minZoom: 0.3,
          maxZoom: 19,
        }}
        componentOnTop={
          toggleMapIsFocused ? (
            <MapFocusButton onClick={toggleMapIsFocused}>
              <IconWrapper>
                <ArrowsAltOutlined />
              </IconWrapper>
            </MapFocusButton>
          ) : undefined
        }
        initialMapSettings={{
          mapStyle: 'SAT',
        }}
        settingsPersistent={false}
        settingIdentifier={'vesselDetailsMap'}
        saveMapState={false}
        centerPoint={[centerPoint?.lon || 0, centerPoint?.lat || 0]}
        coordinatesToFocus={coordinatesToFocus}
        layers={layers}
        isDragPanEnabled={true}
        isScrollZoomEnabled={true}
        showLegend={false}
        showSearch={false}
        showControl={false}
        showFocusBtn={false}
        showNavigation={false}
        settingsButtonVisible={false}
      />
    </MapWrapper>
  );
};

const StyledSeaboMap = styled(SeaboMap)``;

const MapFocusButton = styled.button`
  position: absolute;
  bottom: 7px;
  right: 7px;
  width: 26px;
  height: 26px;
  padding-left: 0;
  padding-right: 0;
  background: var(--color-blue);
  border: none;
  border-radius: var(--border-radius-lg);
  color: var(--color-white-fixed);
  font-size: var(--font-size-2xl);
  line-height: 24px;
  text-align: center;
  cursor: pointer;
`;

const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-dark);
  border-radius: var(--border-radius-lg);
`;

const IconWrapper = styled.div`
  font-size: 1.5rem;
`;

const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-lg);
  will-change: height, width;
  transition:
    height 0.3s,
    width 0.3s;
  background-color: var(--color-blue-dark);
  ${StyledSeaboMap}:not(.seabo-mapContainer--fullscreen) {
    .ant-btn:not(.map-fullscreen-btn) {
      display: none;
    }

    .map-fullscreen-btn {
      height: 40px;
      width: 40px;
      position: absolute;
      top: unset;
      left: unset;
      right: -5px;
      bottom: -26px;
      background-color: var(--color-blue) !important;
    }

    .mapboxgl-ctrl-attrib {
      display: none;
    }
  }

  // prettier-ignore
  ${StyledSeaboMap}.seabo-mapContainer--fullscreen ${MapFocusButton} {
    display: none;
  }
`;

const convertAisDetailsToVessel = (aisDetails: AisDetails | null | undefined): AisVessel => {
  const aisVessel: AisVessel = {
    ...(aisDetails! as unknown as AisVessel),
    coordinates: [aisDetails?.longitude ?? 0, aisDetails?.latitude ?? 0],
    type: aisDetails?.class,
  };
  return aisVessel;
};
