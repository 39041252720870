import {useQuery} from '@tanstack/react-query';
import {Coordinates} from '../utils/Coordinates';
import {fetchJSON} from '../api/utils/fetchJSON';

export type MapPort = {
  id: number;
  name: string;
  code: string;
  country: string;
  coordinates: Coordinates;
  isLarge: boolean;
};

export const mapPortsQueryFunction = () => {
  return fetchJSON<{
    data: {
      items: MapPort[];
    };
  }>('/api/map/ports');
};

export const MapPortsQueryKey = 'mapPorts';

export const useMapPortsQuery = () =>
  useQuery({
    queryKey: [MapPortsQueryKey],
    queryFn: mapPortsQueryFunction,
  });
