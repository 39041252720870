import classNames from 'classnames';
import {easySharingPromotionColumnId} from '../../../components/GridLayout/columns/shared/easySharingPromotionColumn';
import {DataGridProps} from '../../../components/DataGrid/DataGrid';
import {TODO} from '../../../utils/TODO';

const noop = () => {};

export const getMarketGridTdProps =
  (blurSharedCirculars?: boolean): DataGridProps<TODO>['getTdProps'] =>
  (_, rowInfo, cell) => {
    const hasAttachedOffers = rowInfo?.original?.attachedOffers.length;
    const isSharedCircular = rowInfo?.original?.shared;
    const rowBlurred = blurSharedCirculars && isSharedCircular;
    const isPromotionColumn = cell?.id.includes(easySharingPromotionColumnId);

    const className = classNames({
      'show-pointer': hasAttachedOffers,
      blurred: rowBlurred && !isPromotionColumn,
      'overflow-visible': rowBlurred && isPromotionColumn,
    });

    if (!hasAttachedOffers) {
      return {
        className,
        onClick: noop,
      };
    }

    return {
      className,
    };
  };
