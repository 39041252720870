import React, {CSSProperties, FC, useState} from 'react';
import {Badge, Dropdown, Tooltip} from 'antd';
import styled from 'styled-components';
import {NotificationDropdownContent} from './NotificationDropdownContent';
import {Notification} from '../../../../api/symfony/generated/models/Notification';
import {BellIcon} from './BellIcon';

/**
 * The notifications button displayed in the header, in "dumb" form.
 * It is supposed to display data and generate events. It is not supposed to
 * handle events or talk to the API.
 */
export const NotificationButtonDumb: FC<{
  loading?: boolean;
  ringingBellAnimation?: boolean;
  notifications: Notification[];
  style?: CSSProperties;
  onClick?: (notification: Notification) => void;
  onMarkAsRead?: (notification: Notification) => void;
  onMarkAllAsRead?: (notifications: Notification[]) => void;
  onSettings?: () => void;
  onSeeAll?: () => void;
  unreadCount: number;
}> = ({
  loading = false,
  ringingBellAnimation = false,
  notifications,
  style,
  onClick,
  onMarkAsRead,
  onMarkAllAsRead,
  onSettings,
  onSeeAll,
  unreadCount,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <NotificationButtonStyled
      style={style}
      data-tour-id="NotificationButton"
      data-testid="HeaderNotificationButton"
      data-loading={loading}>
      <Dropdown
        onOpenChange={visible => {
          setDropdownVisible(visible);
        }}
        arrow
        overlayClassName={'inHeader'}
        open={dropdownVisible}
        placement="bottomRight"
        menu={{
          items: [
            {
              key: 'notifications',
              label: (
                <NotificationDropdownContent
                  notifications={notifications}
                  onClick={notification => {
                    setDropdownVisible(false);
                    onClick?.(notification);
                  }}
                  onMarkAsRead={notification => {
                    setDropdownVisible(false);
                    onMarkAsRead?.(notification);
                  }}
                  onMarkAllAsRead={notifications => {
                    setDropdownVisible(false);
                    onMarkAllAsRead?.(notifications);
                  }}
                  onSettings={() => {
                    setDropdownVisible(false);
                    onSettings?.();
                  }}
                  onSeeAll={() => {
                    setDropdownVisible(false);
                    onSeeAll?.();
                  }}
                />
              ),
            },
          ],
        }}
        trigger={['click']}>
        <div onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
          <Badge count={unreadCount} size="small" offset={[-3, 2]}>
            <LinkStyled data-testid="NotificationButtonLink" onClick={e => e.preventDefault()}>
              <BellIcon ringingBellAnimation={ringingBellAnimation} />
            </LinkStyled>
          </Badge>
        </div>
      </Dropdown>
      <ToolTipContainer>
        <Tooltip title="Notifications" placement="bottom" open={isTooltipOpen} />
      </ToolTipContainer>
    </NotificationButtonStyled>
  );
};

const NotificationButtonStyled = styled.div`
  /* Move everything 2 pixels down so that the icon sits better with the other elements in the header. */
  position: relative;
  top: 2px;
`;

const LinkStyled = styled.a`
  display: inline-block;
`;

const ToolTipContainer = styled.div`
  transform: translateX(50%);
`;
