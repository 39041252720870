/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ConnectionSettings
 */
export interface ConnectionSettings {
  /**
   *
   * @type {boolean}
   * @memberof ConnectionSettings
   */
  showConnectionsInProfile: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ConnectionSettings
   */
  connectionsCanViewProfile: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ConnectionSettings
   */
  connectionsCanViewOnlineStatus: boolean;
}

export function ConnectionSettingsFromJSON(json: any): ConnectionSettings {
  return ConnectionSettingsFromJSONTyped(json, false);
}

export function ConnectionSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    showConnectionsInProfile: json['showConnectionsInProfile'],
    connectionsCanViewProfile: json['connectionsCanViewProfile'],
    connectionsCanViewOnlineStatus: json['connectionsCanViewOnlineStatus'],
  };
}

export function ConnectionSettingsToJSON(value?: ConnectionSettings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    showConnectionsInProfile: value.showConnectionsInProfile,
    connectionsCanViewProfile: value.connectionsCanViewProfile,
    connectionsCanViewOnlineStatus: value.connectionsCanViewOnlineStatus,
  };
}
