import {TourDefinition, TourId, getFinalStep} from '../../components/OnboardingTour/TourDefinition';

const GRID_ROW_SELECTOR = '.datagrid .rt-tbody .rt-tr';

export const VesselFixtureTourDefinition: TourDefinition = {
  id: TourId.Fixtures,
  steps: [
    {
      title: 'Fixtures',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/9.%20Onboarding%20Fixtures.mp4',
      },
      pdfDownloadUrl: "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/09%20Fixtures/Let's%20meet%20fixtures.pdf",
      placement: 'center',
    },
    {
      title: 'How to create a fixture',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/9%20How%20to%20Fixtures/9.1%20How%20to%20create%20a%20fixture.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/09%20Fixtures/Create%20and%20work%20with%20fixtures.pdf',
      placement: 'center',
    },
    {
      title: 'How to use tags in fixtures',
      target: GRID_ROW_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/9%20How%20to%20Fixtures/9.2%20How%20to%20use%20tags%20in%20fixtures%20.mp4',
      },
      placement: 'bottom',
    },
    {
      title: 'How to export fixtures',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/9%20How%20to%20Fixtures/9.3%20How%20to%20export%20fixtures.mp4',
      },
      placement: 'center',
    },
    getFinalStep('Fixtures'),
  ],
};
