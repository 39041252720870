import {Col, Divider, Row} from 'antd';
import {useState, FC} from 'react';
import styled from 'styled-components';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import ReactECharts from 'echarts-for-react';
import Icon from '../../../atoms/Icon';
import {getChartColorFromVisit} from './BarStats';
import {mapAttributes, vesselBuildAttributes, vesselSizeAttributes, visitAttributes} from './utils/visitDetails';
import {MapCardMap} from '../MapCard/MapCardMap';
import {FeatureCollection} from 'geojson';
import {ArrowsAltOutlined} from '@ant-design/icons';

export const VisitDetails: FC<{
  visit: MergedVisits;
  polygon?: FeatureCollection;
  onClose: () => void;
}> = ({visit, polygon, onClose}) => {
  const [mapIsFocused, setMapIsFocused] = useState(false);
  const toggleMapIsFocused = () => {
    setMapIsFocused(mapIsFocused => !mapIsFocused);
  };
  const color = getChartColorFromVisit(visit);
  const durationPie = [
    {name: 'Passage', value: visit.passageDuration / visit.visitDuration, itemStyle: {color: color + 'FF'}},
    {name: 'Waiting', value: visit.waitingDuration / visit.visitDuration, itemStyle: {color: color + '33'}},
  ];
  return (
    <VisitDetailsContainer>
      <CloseIcon onClick={onClose} type={'close'} />
      <Headline>Selected vessel:</Headline>
      <VesselTitle>{visit.name ?? `(No vessel details available)`}</VesselTitle>
      <VisitDetailMap
        staticMapBoxLayer={false}
        $isFocused={mapIsFocused}
        vessels={[visit]}
        selectedVisit={visit}
        polygon={polygon}
        polygonColor={[255, 255, 255, 0.8 * 255]}
        zoom={2}
        componentOnTop={
          <MapFocusButton onClick={toggleMapIsFocused}>
            <ArrowsAltOutlined />
          </MapFocusButton>
        }
      />
      {!mapIsFocused && (
        <>
          <Divider>Visit details</Divider>
          <AttributesContainer>
            <Row>
              <Col span={12}>{mapAttributes(visit, visitAttributes)}</Col>
              <Col span={12}>
                <ReactECharts
                  style={{height: 160, margin: '-20px 0 -30px'}}
                  option={{
                    grid: 0,
                    series: [
                      {
                        type: 'pie',
                        radius: '50%',
                        data: durationPie,
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
            <Divider>Vessel details</Divider>
            <Row>
              <Col span={14}>{mapAttributes(visit, vesselBuildAttributes)}</Col>
              <Col span={8}>{mapAttributes(visit, vesselSizeAttributes)}</Col>
            </Row>
          </AttributesContainer>
        </>
      )}
    </VisitDetailsContainer>
  );
};

const VisitDetailsContainer = styled.div`
  position: relative;
  margin: 10px;
  padding: 20px 40px;
  background: var(--color-white);
  border-radius: var(--border-radius);
`;

const VesselTitle = styled.p`
  font-size: var(--font-size-lg);
  font-weight: bold;
`;

const Headline = styled.p`
  margin-bottom: 5px;
  color: var(--color-gray-2);
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const VisitDetailMap = styled(MapCardMap)<{$isFocused: boolean}>`
  width: calc(100% + 20px);
  height: ${({$isFocused}) => ($isFocused ? 'calc(var(--graph-height) - 130px)' : '210px')};
  margin: -10px -10px 0;
`;

const AttributesContainer = styled.div`
  text-align: start;
`;

const MapFocusButton = styled.button`
  position: absolute;
  bottom: 38px;
  right: 7px;
  width: 26px;
  height: 26px;
  padding-left: 0;
  padding-right: 0;
  background: var(--color-blue);
  border: none;
  border-radius: var(--border-radius-lg);
  color: var(--color-white-fixed);
  font-size: var(--font-size-2xl);
  line-height: 24px;
  text-align: center;
  cursor: pointer;
`;
