/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UpdateTermSortIndexRequest
 */
export interface UpdateTermSortIndexRequest {
  /**
   * The new sort index of the term. Starts with 0.
   * @type {number}
   * @memberof UpdateTermSortIndexRequest
   */
  sortIndex: number;
}

export function UpdateTermSortIndexRequestFromJSON(json: any): UpdateTermSortIndexRequest {
  return UpdateTermSortIndexRequestFromJSONTyped(json, false);
}

export function UpdateTermSortIndexRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateTermSortIndexRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    sortIndex: json['sortIndex'],
  };
}

export function UpdateTermSortIndexRequestToJSON(value?: UpdateTermSortIndexRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    sortIndex: value.sortIndex,
  };
}
