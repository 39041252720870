import React, {FC, useEffect} from 'react';
import {Col, Form, FormInstance, Row} from 'antd';
import {FormValues} from '../../FormValues';
import {FixtureDatePicker} from './FixtureDatePicker';
import {VesselPicker} from './VesselPicker';
import {VesselTypePicker} from './VesselTypePicker';
import {DesignSubTypePicker} from './DesignSubTypePicker';
import {DesignTypePicker} from './DesignTypePicker';
import {Input} from '../../../../../../antd/components/Input';
import {GearedPicker} from './GearedPicker';
import {ChartererPicker} from './ChartererPicker';
import {ContractTypePicker} from './ContractTypePicker/ContractTypePicker';
import {numberBetween} from '../../../../../../utils/AntDValidator';
import {InputNumber} from '../../../../../../antd/components/InputNumber';
import produce from 'immer';

export const GeneralSection: FC<{form: FormInstance<FormValues>}> = ({form}) => {
  const vesselType = Form.useWatch<FormValues['vesselType']>('vesselType', form) ?? '';
  const contractType = Form.useWatch<FormValues['contractType']>('contractType', form) ?? '';

  useUpdateTermFieldsToLumpSum(form);

  return (
    <div data-cy="GeneralSection">
      <Row gutter={8}>
        <Col span={6}>
          <Form.Item
            label="Fixture date"
            rules={[{required: true, message: 'Fixture date is required'}]}
            required={true}
            name={'fixtureDate'}
            data-cy="fixtureDate">
            <FixtureDatePicker />
          </Form.Item>
        </Col>

        <Col span={18}>
          <Form.Item label="Vessel name / IMO" name={'vesselName'} rules={[{max: 191}]} data-cy="vesselName">
            <VesselPicker />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={6}>
          <Form.Item label={'Vessel type'} name={'vesselType'} data-cy="vesselType">
            <VesselTypePicker />
          </Form.Item>
        </Col>
        <Col span={6}>
          {vesselType === 'bulker' && (
            <Form.Item label={'Sub type'} name={'designSubType'} data-cy="designSubType">
              <DesignSubTypePicker />
            </Form.Item>
          )}
          {vesselType === 'container' && (
            <Form.Item label={'Design type'} name={'designType'} data-cy="designType">
              <DesignTypePicker />
            </Form.Item>
          )}
        </Col>
        <Col span={6}>
          {(vesselType === 'bulker' || vesselType === 'mpp') && (
            <Form.Item
              label={'DWT'}
              name={'dwat'}
              rules={[{type: 'number'}, numberBetween('DWT', 0, 999999)]}
              data-cy="dwt">
              <InputNumber precision={0} />
            </Form.Item>
          )}
          {vesselType === 'container' && (
            <Form.Item
              label={'TEU'}
              name={'teuQuantity'}
              rules={[{type: 'number'}, numberBetween('TEU', 0, 999999)]}
              data-cy="teu">
              <InputNumber precision={0} />
            </Form.Item>
          )}
        </Col>
        <Col span={6}>
          <Form.Item label={'Geared'} name={'isGeared'} data-cy="geared">
            <GearedPicker />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={15}>
          {contractType === 'sale' ? (
            <Form.Item label={'Buyer'} name={'buyerName'} rules={[{max: 191}]} data-cy="buyer">
              <Input placeholder="Type in new owner" />
            </Form.Item>
          ) : (
            <Form.Item label={'Charterer'} name={'chartererName'} rules={[{max: 191}]} data-cy="charterer">
              <ChartererPicker />
            </Form.Item>
          )}
        </Col>
        <Col span={9}>
          <Form.Item label={'Contract type'} name={'contractType'} data-cy="contractType">
            <ContractTypePicker />
          </Form.Item>
        </Col>
      </Row>
      {contractType === 'sale' && (
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label={'Seller'} name={'sellerName'} rules={[{max: 191}]} data-cy="seller">
              <Input placeholder="Type in former owner" />
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
};

const useUpdateTermFieldsToLumpSum = (form: FormInstance<FormValues>) => {
  // When the user selects contract type "sale", then all terms fields should be updated to "lump sum" (FRIN-5207).
  const contractType = Form.useWatch<FormValues['contractType']>('contractType', form) ?? '';
  useEffect(() => {
    if (contractType !== 'sale') {
      return;
    }
    const options: FormValues['options'] = form.getFieldValue('options');
    const newOptions = produce(options, draftOptions => {
      for (const option of draftOptions) {
        option.rateTerms = 'lumpsum';
      }
    });
    form.setFieldsValue({options: newOptions});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType]);
};
