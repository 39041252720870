import {ReactElement, useEffect, useState} from 'react';
import {CreateFilterModal} from './CreateFilterModal';
import {CreateOrUpdateFilterChoiceModal} from './CreateOrUpdateFilterChoiceModal';
import {FilterCategory, FilterItem, FilterItemWrite, FilterType} from '../../../api/symfony/generated';
import {useCreateFilterMutation} from '../../../queries/filter/useCreateFilterMutation';
import {assert} from '../../../utils/assert';
import {useUpdateFilterMutation} from '../../../queries/filter/useUpdateFilterMutation';
import {FilterBranchDefinitionsDefault, FilterInternalType} from '../../FilterProvider/FilterBranchDefinition';

export interface SaveFilterModalProps<FilterBranchDefinitions extends FilterBranchDefinitionsDefault> {
  visible: boolean;
  createOrUpdateModal: {
    title: string;
    description: ReactElement;
  };
  createModal: {
    title: string;
    description: ReactElement;
    namePlaceholder: string;
  };
  category: FilterCategory;
  type: FilterType;
  nextSortKey: number;
  filterSettings: FilterInternalType<FilterBranchDefinitions> | null;
  currentFilterObject: FilterItem | undefined;
  openAsNew: boolean;
  onCreateSuccess: (createdFilter: FilterItem) => void;
  onUpdateSuccess: (updatedFilter: FilterItem) => void;
  onClose: () => void;
}

export const SaveFilterModal = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>({
  visible,
  currentFilterObject,
  filterSettings,
  type,
  category,
  nextSortKey,
  createModal,
  createOrUpdateModal,
  openAsNew,
  onCreateSuccess,
  onUpdateSuccess,
  onClose,
}: SaveFilterModalProps<FilterBranchDefinitions>) => {
  const [userWantsToSaveAsNew, setUserWantsToSaveAsNew] = useState<boolean>(openAsNew);

  useEffect(() => {
    // When re-opening the modal, we reinitialize userWantsToSaveAsNew.
    if (visible) {
      setUserWantsToSaveAsNew(openAsNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onCreate = async (name: string) => {
    const createdFilter = await createFilter(name);
    onCreateSuccess(createdFilter);
    onClose();
  };

  const createFilterMutation = useCreateFilterMutation();

  const createFilter = async (name: string): Promise<FilterItem> => {
    const filterItemWrite: FilterItemWrite = {
      category,
      type,
      sortKey: nextSortKey,
      name,
      filterSettings: filterSettings ?? undefined,
    };
    const createdFilter = await createFilterMutation.mutateAsync({
      filterItemWrite,
    });
    return createdFilter;
  };

  const onUpdate = async () => {
    const updatedFilter = await updateFilter();
    onUpdateSuccess(updatedFilter);
    onClose();
  };

  const updateFilterMutation = useUpdateFilterMutation();

  const updateFilter = async (): Promise<FilterItem> => {
    assert(currentFilterObject, 'Need a currentFilterObject to update');
    const filterItemWrite: FilterItemWrite = {
      ...currentFilterObject,
      filterSettings: filterSettings ?? undefined,
    };

    const updatedFilter = await updateFilterMutation.mutateAsync({
      id: currentFilterObject.id,
      filterItemWrite,
    });

    return updatedFilter;
  };

  return (
    <>
      <CreateOrUpdateFilterChoiceModal
        visible={visible && !userWantsToSaveAsNew}
        title={createOrUpdateModal.title}
        description={createOrUpdateModal.description}
        onClickCreate={() => setUserWantsToSaveAsNew(true)}
        onClickUpdate={onUpdate}
        onClose={onClose}
      />
      <CreateFilterModal
        visible={visible && userWantsToSaveAsNew}
        title={createModal.title}
        description={createModal.description}
        namePlaceholder={createModal.namePlaceholder}
        onSave={onCreate}
        onClose={onClose}
      />
    </>
  );
};
