import React, {FC, useState} from 'react';
import BaseModal from '../../../atoms/BaseModal';
import {useDispatch} from 'react-redux';
import ContactsService from '../../../redux/ApiService/ContactsService';
import {socialApi} from '../../../api/symfony/symfonyApi';

import './addContact.scss';
import {AsyncMultiSelect} from '../../../atoms/Select/AsyncMultiSelect';
import {TODO} from '../../../utils/TODO';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {Button} from 'antd';
import {ModalWidth} from '../../../antd/components/Modal';

interface PlatformUser {
  id: number;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  avatar?: string | string;
  company: string | null;
}

interface Props {
  afterUpdate: () => void;
}

export const AddContact: FC<Props> = props => {
  const [selectedPlatformUsers, setSelectedPlatformUsers] = useState<PlatformUser[]>([]);

  const dispatch = useDispatch();

  const searchUser = (search: string): Promise<void> => {
    return dispatch(ContactsService.searchUser(search));
  };

  const handleFriendUpdateActions = async (close: () => void) => {
    await Promise.all(selectedPlatformUsers.map((user: PlatformUser) => socialApi.addFriend({userId: user.id})));
    props.afterUpdate();
    close();
  };

  const handleChange = (data: PlatformUser[]) => setSelectedPlatformUsers(data ? data : []);

  const canSendAddForm = selectedPlatformUsers.length > 0;

  const AsyncMultiSelectTODO: TODO = AsyncMultiSelect;

  return (
    <BaseModal title="Add contacts" width={ModalWidth.Middle}>
      {({close}: {close: () => void}) => (
        <div className={'add-contact'}>
          <div>
            <AsyncMultiSelectTODO
              id={'add-contact__multi-select'}
              name={'add-contact__multi-select'}
              value={selectedPlatformUsers}
              loadOptions={(query: string) => (query.length > 2 ? searchUser(query) : Promise.resolve([]))}
              onChange={handleChange}
              getOptionLabel={(option: PlatformUser) => option.fullName}
              getOptionValue={(option: PlatformUser) => option.id}
              placeholder={'Search for a seabo user'}
            />
          </div>

          <ModalFooter>
            <Button onClick={() => handleFriendUpdateActions(close)} type="primary" disabled={!canSendAddForm}>
              Send connection request
            </Button>
          </ModalFooter>
        </div>
      )}
    </BaseModal>
  );
};
