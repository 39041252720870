import {ProjectCargo} from '../types';
import Tooltip from '../../../atoms/Tooltip';
import {Trigger} from 'react-tippy';
import {PortTitleWithFlag} from '../../PortTitleWithFlag';
import {LocationType} from '../../../api/symfony/generated';
import {NotAvailable} from '../../../utils/NotAvailable';
import {TODO} from '../../../utils/TODO';

export type LocationObject = {
  title?: string;
  name?: string;
  country?: string;
  code?: string;
  lat: number;
  lon: number;
  countryObject?: {
    name: string;
  };
  type?: LocationType;
};

export const getLocationTitle = (locationObj: LocationObject | undefined): string => {
  if (!locationObj?.name) {
    return NotAvailable;
  }
  const nameLabel = locationObj.name ? locationObj.name + ' ' : '';
  const countryLabel = locationObj.country ? locationObj.country + ', ' : '';
  const codeLabel = locationObj.code ?? '';
  const locationLabel = countryLabel || codeLabel ? `(${countryLabel}${codeLabel})` : '';
  return `${nameLabel}${locationLabel}` ?? NotAvailable;
};

export const getStationValue = (cargo: ProjectCargo, type: string) => {
  const station = cargo.stations.find((station: TODO) => station.type === type);
  if (!station || !station.locations.length) {
    return null;
  }

  const locations = station.locations.map((p: LocationObject) => {
    return getLocationTitle(p);
  });

  return locations.length ? (
    <Tooltip position={'top'} trigger={'mouseenter focus' as Trigger} title={locations.join(', ')}>
      <PortTitleWithFlag
        port={station.locations[0]}
        label={locations[0] + (locations.length > 1 ? ` ...(+${locations.length - 1})` : '')}
      />
    </Tooltip>
  ) : (
    'N/A'
  );
};
