import numeral from 'numeral';
import Icon from '../../../../atoms/Icon';

type Props = {
  value: number;
  editModeAvailable?: boolean;

  onOpenCard: () => void;
};

export const OtherCostSpan = (props: Props) => {
  if (!props.editModeAvailable) {
    return (
      <span
        style={{
          marginRight: 43.5,
          display: 'inline-block',
        }}>
        {numeral(props.value).format('$ 0,0')}{' '}
      </span>
    );
  }

  return (
    <div
      style={{
        marginRight: 16,
        display: 'inline-block',
        cursor: 'pointer',
      }}
      onClick={() => {
        props.onOpenCard();
      }}>
      {numeral(props.value).format('$ 0,0')}{' '}
      <Icon className={'editMarker'} size={'unset'} style={{verticalAlign: 'bottom'}} type={'edit'} />
    </div>
  );
};
