import {Modal} from '../../../../antd/components/Modal';
import {VesselsFromSpireBody} from './VesselsFromSpireBody';

export const VesselsFromSpireModal = ({visible, onCancel}: {visible: boolean; onCancel: () => void}) => {
  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={onCancel}
      wrapProps={{id: 'root'}}
      width={1400}
      title={'Show new vessels'}>
      <VesselsFromSpireBody />
    </Modal>
  );
};
