import {notificationApi} from '../api/symfony/symfonyApi';
import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import {NotificationCollection} from '../api/symfony/generated/models/NotificationCollection';

export const NOTIFICATIONS_QUERY_KEY = 'notifications';

export const useNotificationsQuery = ({
  pageNumber,
  pageSize = 10,
  acknowledged,
}: {
  pageNumber: number;
  acknowledged?: boolean;
  pageSize?: number;
}): UseQueryResult<NotificationCollection> =>
  useQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEY, pageSize, pageNumber, acknowledged],
    queryFn: () =>
      notificationApi.getUserNotifications({
        pageSize,
        pageNumber,
        acknowledged,
      }),
  });

export const invalidateNotificationsQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [NOTIFICATIONS_QUERY_KEY],
  });
};
