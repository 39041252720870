import {useEffect, VFC} from 'react';
import {NotificationType, showNotification} from '../../utils/notification';

const FlashNotificationKey = 'flashNotification';

interface Notification {
  type?: NotificationType;
  message?: string;
  description?: string;
  duration?: number;
}

/**
 * Shows a notification after an imminent page reload.
 */
export const showNofificationAfterPageReload = (notification: Notification) => {
  const serialized = JSON.stringify(notification);
  sessionStorage.setItem(FlashNotificationKey, serialized);
};

/**
 * Shows flash notifications after a page reload.
 * Only to be used by the App component.
 *
 */
export const FlashNotification: VFC = () => {
  useEffect(showSerializedNotification, []);
  return null;
};

const showSerializedNotification = (): void => {
  // Load
  const serialized = sessionStorage.getItem(FlashNotificationKey);
  sessionStorage.removeItem(FlashNotificationKey);
  if (!serialized) {
    return;
  }

  // Parse
  let notification: Notification;
  try {
    notification = JSON.parse(serialized);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Flash notification deserialization failed', error);
    throw error;
  }

  // Show
  const {message, type, duration, description} = notification;
  showNotification(message, type, duration, description);
};
