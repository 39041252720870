import {StyledFlag} from '../StyledFlag';
import {StyledTag} from './StyledTag';
import {SearchPickerPort} from '../SearchPickerPort';

type Props = {
  port: SearchPickerPort;
  onClose: () => void;
};

export const PortTag = (props: Props) => {
  return (
    <StyledTag
      data-testid="portsPickerSelectTag"
      key={props.port.id}
      closable
      onClose={() => {
        props.onClose();
      }}>
      <StyledFlag countryCode={props.port.countryObject?.code} />
      {props.port.name}
    </StyledTag>
  );
};
