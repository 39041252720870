import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {ApiError} from '../../../../api/utils/ApiError';
import {CreateNegotiationTemplateRequest, NegotiationTemplate} from '../../../../api/node-backend/generated';
import {negotiationNegotiationTemplateApi} from '../../../../api/node-backend/nodeBackendApis';

export const useCreateNegotiationTemplateMutation = (
  options?: UseMutationOptions<NegotiationTemplate, ApiError, CreateNegotiationTemplateRequest>
) =>
  useMutation({
    mutationFn: async ({createOrUpdateNegotiationTemplateDTO}) => {
      const result = await negotiationNegotiationTemplateApi.createNegotiationTemplate({
        createOrUpdateNegotiationTemplateDTO,
      });
      return result;
    },
    ...options,
  });
