import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {SpotifindActions} from '../../redux/Spotifind';
import {useSelector} from '../../redux/react-redux';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';

export const LayoutSelect: FC = () => {
  const {userSelectedLayout} = useSelector(state => state.spotifind);
  const dispatch = useDispatch();

  return (
    <ScreenHeader.Features.Toggle
      data-cy="SpotifindLayoutSelect"
      value={userSelectedLayout}
      onChange={event => {
        dispatch(SpotifindActions.setUserSelectedLayout(event.target.value));
      }}
      options={[
        {label: 'One column', value: 'oneColumn'},
        {label: 'Two columns', value: 'twoColumn'},
      ]}
    />
  );
};
