import {useUpdateTermTextMutation} from './useUpdateTermTextMutation';
import {useAcceptTermMutation} from './useAcceptTermMutation';
import {useAcceptTermRejectionMutation} from './useAcceptTermRejectionMutation';
import {useDeleteTermMutation} from './useDeleteTermMutation';
import {useResetTermMutation} from './useResetTermMutation';
import {
  NegotiationParty,
  NegotiationTerm,
  NegotiationTermStatus,
  NegotiationTermType,
  TermModification,
} from '../../../../../../api/node-backend/generated';
import sortBy from 'lodash/sortBy';
import {useUpdateTermTypeMutation} from './useUpdateTermTypeMutation';
import {useRequestTermRejectionMutation} from './useRequestTermRejectionMutation';
import {useRejectTermRejectionMutation} from './useRejectTermRejectionMutation';
import {ApiError, isApiError} from '../../../../../../api/utils/ApiError';
import {useInvalidateNegotiationQueries} from '../../../../utils/useInvalidateNegotiationQueries';
import {
  updateTerm,
  updateTermModification,
  useEagerUpdateNegotiationQueryData,
} from '../../../../utils/useEagerUpdateNegotiationQueryData';

export type TermActionsHook = (term: NegotiationTerm) => TermActions;

export type TermActions = {
  updateTermText: (newText: string) => void;
  updateTermType: (type: NegotiationTermType | null) => void;
  acceptTerm: () => void;
  requestTermRejection: () => void;
  rejectTermRejection: () => void;
  acceptTermRejection: () => void;
  resetTerm: () => void;
  deleteTerm: () => void;
  termActionsBusy?: boolean;
};

export const termActionsNoop = {
  createTerm: () => {},
  updateTermText: () => {},
  updateTermType: () => {},
  acceptTerm: () => {},
  requestTermRejection: () => {},
  rejectTermRejection: () => {},
  acceptTermRejection: () => {},
  resetTerm: () => {},
  deleteTerm: () => {},
  termActionsBusy: false,
};

export const useGetTermActionsWithMutations = (
  negotiationId?: string,
  roundResultId?: string,
  party?: NegotiationParty
): {
  getTermActions: TermActionsHook;
  termActionsBusy: boolean;
} => {
  const eagerUpdateQueryData = useEagerUpdateNegotiationQueryData(negotiationId, roundResultId);
  const invalidateNegotiationQueries = useInvalidateNegotiationQueries(negotiationId, roundResultId);

  const updateTermTextMutation = useUpdateTermTextMutation({
    onMutate: ({termId, text}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateTerm({
            roundResult,
            termId,
            termUpdate: {
              text: text,
              lastEditedBy: party!,
              lastModifiedBy: party!,
              lastModifiedAt: new Date(),
            },
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateTermModification({
            roundResultModifications,
            termId,
            termModificationUpdate: {
              newText: text,
              termModification: TermModification.UpdateTermText,
              changed: true,
            },
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const updateTermTypeMutation = useUpdateTermTypeMutation({
    onMutate: ({termId, type}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateTerm({
            roundResult,
            termId,
            termUpdate: {
              type,
              lastModifiedBy: party!,
              lastModifiedAt: new Date(),
            },
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const acceptTermMutation = useAcceptTermMutation({
    onMutate: ({termId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateTerm({
            roundResult,
            termId,
            termUpdate: {
              status: NegotiationTermStatus.Accepted,
              lastModifiedBy: party!,
              lastModifiedAt: new Date(),
            },
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateTermModification({
            roundResultModifications,
            termId,
            termModificationUpdate: {
              newStatus: NegotiationTermStatus.Accepted,
              termModification: TermModification.AcceptTerm,
              changed: true,
            },
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const requestTermRejectionMutation = useRequestTermRejectionMutation({
    onMutate: ({termId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateTerm({
            roundResult,
            termId,
            termUpdate: {
              status: NegotiationTermStatus.RejectionRequested,
              lastModifiedBy: party!,
              lastModifiedAt: new Date(),
            },
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateTermModification({
            roundResultModifications,
            termId,
            termModificationUpdate: {
              newStatus: NegotiationTermStatus.RejectionRequested,
              termModification: TermModification.RequestRejection,
              changed: true,
            },
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const rejectTermRejectionMutation = useRejectTermRejectionMutation({
    onMutate: ({termId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateTerm({
            roundResult,
            termId,
            termUpdate: {
              status: NegotiationTermStatus.Ongoing,
              lastModifiedBy: party!,
              lastModifiedAt: new Date(),
            },
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateTermModification({
            roundResultModifications,
            termId,
            termModificationUpdate: {
              newStatus: NegotiationTermStatus.Ongoing,
              termModification: TermModification.RejectRejectionRequest,
              changed: true,
            },
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const acceptTermRejectionMutation = useAcceptTermRejectionMutation({
    onMutate: ({termId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult =>
          updateTerm({
            roundResult,
            termId,
            termUpdate: {
              status: NegotiationTermStatus.Rejected,
              lastModifiedBy: party!,
              lastModifiedAt: new Date(),
            },
          }),
        roundResultModificationUpdate: roundResultModifications =>
          updateTermModification({
            roundResultModifications,
            termId,
            termModificationUpdate: {
              newStatus: NegotiationTermStatus.Rejected,
              termModification: TermModification.RejectTerm,
              changed: true,
            },
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const deleteTermMutation = useDeleteTermMutation({
    onMutate: ({termId}) => {
      eagerUpdateQueryData({
        roundResultUpdate: roundResult => {
          const termIndex = roundResult.terms.findIndex(term => term.id === termId);
          roundResult.terms.splice(termIndex, 1);
          roundResult.terms = sortBy(roundResult.terms, 'sortIndex').map((term, index) => ({
            ...term,
            sortIndex: index,
          }));
          return roundResult;
        },
        roundResultModificationUpdate: roundResultModifications =>
          updateTermModification({
            roundResultModifications,
            termId,
            termModificationUpdate: {
              newStatus: NegotiationTermStatus.Rejected,
              termModification: TermModification.RejectTerm,
              changed: true,
            },
          }),
      });
    },
    onError: (error: ApiError) => {
      // Suppress 404 errors, since they are expected when a term is deleted repeatedly in quick succession
      if (isApiError(error) && error.status === 404) {
        return;
      }
      throw error;
    },
    onSuccess: invalidateNegotiationQueries,
  });
  const resetTermMutation = useResetTermMutation({
    onMutate: ({termId}) => {
      eagerUpdateQueryData({
        roundResultModificationUpdate: roundResultModifications =>
          updateTermModification({
            roundResultModifications,
            termId,
            termModificationUpdate: {
              termModification: TermModification.Unmodified,
              changed: false,
            },
          }),
      });
    },
    onSuccess: invalidateNegotiationQueries,
  });

  const termActionsBusy =
    updateTermTextMutation.isPending ||
    updateTermTypeMutation.isPending ||
    acceptTermMutation.isPending ||
    requestTermRejectionMutation.isPending ||
    rejectTermRejectionMutation.isPending ||
    acceptTermRejectionMutation.isPending ||
    deleteTermMutation.isPending ||
    resetTermMutation.isPending;

  if (!negotiationId || !roundResultId || !party) {
    return {
      getTermActions: () => termActionsNoop,
      termActionsBusy,
    };
  }

  const getTermActions = (term: NegotiationTerm): TermActions => {
    const termId = term.id;

    const updateTermText = (newText: string) => updateTermTextMutation.mutate({termId, text: newText});
    const updateTermType = (type: NegotiationTermType | null) => updateTermTypeMutation.mutate({termId, type});
    const acceptTerm = () => acceptTermMutation.mutate({termId});
    const requestTermRejection = () => requestTermRejectionMutation.mutate({termId});
    const rejectTermRejection = () => rejectTermRejectionMutation.mutate({termId});
    const acceptTermRejection = () => acceptTermRejectionMutation.mutate({termId});
    const deleteTerm = () => deleteTermMutation.mutate({termId});
    const resetTerm = () => resetTermMutation.mutate({termId});

    return {
      updateTermText,
      updateTermType,
      acceptTerm,
      requestTermRejection,
      rejectTermRejection,
      acceptTermRejection,
      resetTerm,
      deleteTerm,
      termActionsBusy,
    };
  };

  return {
    getTermActions,
    termActionsBusy,
  };
};
