import React, {FC, CSSProperties} from 'react';
import styled from 'styled-components';

interface Props {
  style?: CSSProperties;
  children: React.ReactNode;
  'data-testid'?: string;
}

export const SettingsCard: FC<Props> = ({children, style, 'data-testid': testID}) => (
  <StyledCard data-testid={testID} style={style}>
    {children}
  </StyledCard>
);

const StyledCard = styled.div`
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-card);
  overflow: hidden;
`;
