import {useQueryClient} from '@tanstack/react-query';
import {
  NegotiationRoundResult,
  NegotiationRoundResultModification,
  NegotiationSubject,
  NegotiationSubjectModificationReport,
  NegotiationTerm,
  RoundResultTermModification,
} from '../../../api/node-backend/generated';
import {FIND_NEGOTIATION_BY_ID_QUERY_KEY} from '../NegotiationDetail/utils/useFindNegotiationByIdQuery';
import {FIND_ROUND_RESULT_BY_ID_QUERY_KEY} from '../NegotiationDetail/utils/useFindRoundResultByIdQuery';
import {GET_ROUND_RESULTS_MODIFICATIONS_QUERY_KEY} from '../NegotiationDetail/utils/useGetRoundResultModificationsQuery';
import {FIND_ROUND_RESULTS_BY_NEGOTIATION_ID_QUERY_KEY} from '../NegotiationDetail/utils/useFindRoundResultsByNegotiationIdQuery';
import {updateAbstractNegotiationState} from './updateNegotiationState';

/**
 * Updates the query cache before the server-side mutation is performed to create more reactive UI.
 *
 * @param negotiationId The id of the negotiation to update.
 * @param roundResultId The id of the round result to update.
 * @returns An object with functions to update the query cache for a term or subject
 *
 * Update-Functions always take two arguments, because we also need to delete and add elements to the array:
 * 1. The full array from the query cache in which the element to update is located
 * 2. The index of the element to update
 *
 * The update function should return the updated array.
 *
 * @see updateAbstractNegotiationState
 */
export const useEagerUpdateNegotiationQueryData = (negotiationId?: string, roundResultId?: string) => {
  const queryClient = useQueryClient();

  const cancelQueries = async () => {
    await queryClient.cancelQueries({
      queryKey: FIND_NEGOTIATION_BY_ID_QUERY_KEY(negotiationId),
    });
    await queryClient.cancelQueries({
      queryKey: FIND_ROUND_RESULTS_BY_NEGOTIATION_ID_QUERY_KEY(negotiationId),
    });
    await queryClient.cancelQueries({
      queryKey: GET_ROUND_RESULTS_MODIFICATIONS_QUERY_KEY(roundResultId),
    });
    await queryClient.cancelQueries({
      queryKey: FIND_ROUND_RESULT_BY_ID_QUERY_KEY(roundResultId),
    });
  };

  return updateAbstractNegotiationState({
    setRoundResultState: async roundResultUpdater => {
      await cancelQueries();
      queryClient.setQueryData<NegotiationRoundResult | undefined>(
        FIND_ROUND_RESULT_BY_ID_QUERY_KEY(roundResultId),
        roundResultUpdater
      );
    },
    setRoundResultModificationState: async roundResultModificationUpdater => {
      await cancelQueries();
      queryClient.setQueryData<NegotiationRoundResultModification | undefined>(
        GET_ROUND_RESULTS_MODIFICATIONS_QUERY_KEY(roundResultId),
        roundResultModificationUpdater
      );
    },
  });
};

export const updateTerm = ({
  roundResult,
  termId,
  termUpdate,
}: {
  roundResult: NegotiationRoundResult;
  termId: string;
  termUpdate: Partial<NegotiationTerm>;
}) => {
  const terms = [...roundResult.terms];
  const termIndex = terms.findIndex(term => term.id === termId);
  terms[termIndex] = {
    ...terms[termIndex],
    ...termUpdate,
  };
  return {
    ...roundResult,
    terms,
  };
};

export const updateTermModification = ({
  roundResultModifications,
  termId,
  termModificationUpdate,
}: {
  roundResultModifications: NegotiationRoundResultModification;
  termId: string;
  termModificationUpdate: Partial<RoundResultTermModification>;
}) => {
  const termModifications = [...roundResultModifications.termModifications];
  const termIndex = termModifications.findIndex(termModification => termModification.termId === termId);
  termModifications[termIndex] = {
    ...termModifications[termIndex],
    ...termModificationUpdate,
  };
  return {
    ...roundResultModifications,
    termModifications,
  };
};

export const updateSubject = ({
  roundResult,
  subjectId,
  subjectUpdate,
}: {
  roundResult: NegotiationRoundResult;
  subjectId: string;
  subjectUpdate: Partial<NegotiationSubject>;
}) => {
  const subjects = [...roundResult.subjects];
  const subjectIndex = subjects.findIndex(subject => subject.id === subjectId);

  // Subject can be in the root of the round result or in a term
  if (subjectIndex !== -1) {
    subjects[subjectIndex] = {
      ...subjects[subjectIndex],
      ...subjectUpdate,
    };
    return {
      ...roundResult,
      subjects,
    };
  }

  return {
    ...roundResult,
    terms: [...roundResult.terms].map(term => ({
      ...term,
      subjects: term.subjects.map(subject => {
        if (subject.id === subjectId) {
          return {
            ...subject,
            ...subjectUpdate,
          };
        }
        return subject;
      }),
    })),
  };
};

export const updateSubjectModification = ({
  roundResultModifications,
  subjectId,
  subjectModificationUpdate,
}: {
  roundResultModifications: NegotiationRoundResultModification;
  subjectId: string;
  subjectModificationUpdate: Partial<NegotiationSubjectModificationReport>;
}) => {
  const subjectModifications = [...roundResultModifications.subjectModificationReports];
  const subjectIndex = subjectModifications.findIndex(
    subjectModification => subjectModification.subjectId === subjectId
  );

  // If a subject is found, we have to look in terms
  if (subjectIndex !== -1) {
    subjectModifications[subjectIndex] = {
      ...subjectModifications[subjectIndex],
      ...subjectModificationUpdate,
    };
    return {
      ...roundResultModifications,
      subjectModifications,
    };
  }

  return {
    ...roundResultModifications,
    termModifications: [...roundResultModifications.termModifications].map(termModification => ({
      ...termModification,
      subjectModificationReports: termModification.subjectModificationReports.map(subjectModification => {
        if (subjectModification.subjectId === subjectId) {
          return {
            ...subjectModification,
            ...subjectModificationUpdate,
          };
        }
        return subjectModification;
      }),
    })),
  };
};
