import {FC, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {selectVesselGridLayout} from '../../components/VesselGridLayoutSelect/selectVesselGridLayout';
import {useClearClipboardOnToggleArchive} from '../../components/useClearClipboardOnToggleArchive/useClearClipboardOnToggleArchive';
import {cargoOfferCollectionSelector, vesselOfferCollectionSelector} from '../../redux/ApiService/MarketService';
import autocompleteApi from '../../redux/ApiService/autocomplete';
import {ChangeAllItemsPayload, GridClipboardActions, Subsection} from '../../redux/GridClipboard';
import {MarketActions} from '../../redux/Market';
import {VesselGridLayoutMode} from '../../redux/Portfolio';
import {useSelector} from '../../redux/react-redux';
import {TODO} from '../../utils/TODO';
import {MarketBody} from './MarketBody';
import {useMarketCargoFilterProvider} from './MarketCargo/useMarketCargoFilterProvider';
import {useMarketVesselFilterProvider} from './MarketVessel/useMarketVesselFilterProvider';
import {useLocalStorage} from '../../utils/useLocalStorage';
import './market.scss';

export const MarketScreen: FC = () => {
  const [, setLocalStorageLayout] = useLocalStorage('market_vessel_grid_layout', 'auto');

  const onChangeVesselGridMode = (mode: VesselGridLayoutMode) => {
    // To cookie
    setLocalStorageLayout(mode);
    // To redux
    dispatch(MarketActions.setUserSelectedVesselGridLayout(mode));
  };

  const onVesselsLoadeded = () => {
    _changeAllSelection('vessels');
  };

  const marketType = useSelector(state => state.market.marketType);
  const clipboardState = useSelector(state => state.gridClipboard.market);
  const vesselOffers = useSelector(state => vesselOfferCollectionSelector(state));
  const cargoOffers = useSelector(state => cargoOfferCollectionSelector(state));
  const {userSelectedLayout} = useSelector(state => state.market.vessel);

  const dispatch = useDispatch();

  useClearClipboardOnToggleArchive({
    section: 'market',
    subsection: marketType === 'vessel' ? 'vessels' : 'cargoes',
  });

  const preFillSearch = (indexName: string, page?: number, pageSize?: number) =>
    dispatch(autocompleteApi.preFillSearch(indexName, page, pageSize));
  const preFillCharterer = () => dispatch(autocompleteApi.preFillCharterer());
  const changeSelectedItems = (payload: ChangeAllItemsPayload) =>
    dispatch(GridClipboardActions.changeAllItems(payload));

  useEffect(() => {
    preFillSearch('design_type');
    preFillCharterer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayedLayout = useMemo(() => {
    const offers = vesselOffers?.items ?? [];
    const vessels = offers.map(offer => offer.vessel);
    const displayedLayout: VesselGridLayoutMode = selectVesselGridLayout({
      userSelectedLayoutMode: userSelectedLayout,
      vessels,
    });
    return displayedLayout;
  }, [userSelectedLayout, vesselOffers]);

  /**
   * Updates the 'all' checkbox in the data grid such that it reflects the current
   * clipboard selection.
   */
  const _changeAllSelection = (api: Subsection) => {
    const items = api === 'vessels' ? vesselOffers.items : cargoOffers.items;
    changeSelectedItems({
      section: 'market',
      subsection: api,
      newValue: _getIfAllSelected(api, items),
    });
  };

  const _getIfAllSelected = (api: Subsection, screenData: TODO[]): boolean => {
    if (api === 'vessels') {
      const {vessels} = clipboardState;
      if (screenData) {
        const res = vessels.filter((v: TODO) => screenData.map((sd: TODO) => sd.id).includes(v.id));
        return res && (res.length === 0 || screenData.length === 0) ? false : res.length === screenData.length;
      }
    }

    if (api === 'cargoes') {
      const {cargoes} = clipboardState;
      if (screenData) {
        const res = cargoes.filter((c: TODO) => screenData.map((sd: TODO) => sd.id).includes(c.id));
        return res && (res.length === 0 || screenData.length === 0) ? false : res.length === screenData.length;
      }
    }

    throw new Error(`Unhandled api ${api}`);
  };

  const cargoFilterProviderApi = useMarketCargoFilterProvider();
  const vesselFilterProviderApi = useMarketVesselFilterProvider();

  return (
    <MarketBody
      marketType={marketType}
      cargoFilterProviderApi={cargoFilterProviderApi}
      vesselFilterProviderApi={vesselFilterProviderApi}
      userSelectedLayoutMode={userSelectedLayout}
      displayedLayoutMode={displayedLayout}
      onChangeVesselGridMode={onChangeVesselGridMode}
      _changeAllSelection={_changeAllSelection}
      onVesselsLoadeded={onVesselsLoadeded}
    />
  );
};
