interface Filter {
  maxDWT: number;
  minDWT: number;
}

export const dwtFilter = <Vessel extends {dwt?: number | null}>({
  vessels,
  filter,
}: {
  filter: Filter;
  vessels: Vessel[];
}): Vessel[] => {
  return vessels.filter(vessel => {
    if (!vessel.dwt) {
      return false;
    }
    return vessel.dwt >= filter.minDWT && vessel.dwt <= filter.maxDWT;
  });
};
