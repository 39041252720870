import {Fragment, FC, useEffect, useState} from 'react';
import Input from '../../../../../atoms/Input';
import Icon from '../../../../../atoms/Icon';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {AddRowButton} from '../../../../../atoms/AddRowButton/AddRowButton';
import {email} from '../../../../../utils/validators';
import styled from 'styled-components';
import produce from 'immer';
import {DeletableUser, User} from '../User';

type NewUsers = Record<string, DeletableUser>;

interface Props {
  onChangeUsers: (users: DeletableUser[]) => void;
  children?: ({onReset}: {onReset: () => void}) => void;
}

const initialUser: DeletableUser = {
  firstName: '',
  lastName: '',
  email: '',
  deletable: true,
  hasError: false,
};

const initialValues: DeletableUser = {
  ...initialUser,
  deletable: false,
  hasError: false,
};

export const CompanyAddUser: FC<Props> = ({children, onChangeUsers}) => {
  const [newUsers, setNewUsers] = useState<NewUsers>({0: initialValues});

  useEffect(() => {
    onChangeUsers(Object.values(newUsers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUsers]);

  const handleChange = (value: string, field: keyof User, id: string) => {
    if (field === 'email') {
      setNewUsers(
        produce(draftNewUsers => {
          draftNewUsers[id].email = value;
          draftNewUsers[id].hasError = email()(value);
        })
      );
    } else {
      setNewUsers(
        produce(draftNewUsers => {
          draftNewUsers[id][field] = value;
        })
      );
    }
  };

  const deleteRow = (id: string) => {
    setNewUsers(
      produce(draftNewUsers => {
        delete draftNewUsers[id];
      })
    );
  };

  const addUserRow = () =>
    setNewUsers(
      produce(draftNewUsers => {
        draftNewUsers[`${Date.now()}`] = initialUser;
      })
    );

  const handleReset = () => {
    setNewUsers({
      0: initialValues,
    });
  };

  return (
    <Fragment>
      <>
        <div className="company-user-input">
          <div className="company-user-input__label">
            <div className="row">
              <div className="scol-11">
                <div className="row">
                  <div className="scol-4">
                    <LabelWrapper label={'First name'} />
                  </div>
                  <div className="scol-4">
                    <LabelWrapper label={'Last name'} />
                  </div>
                  <div className="scol-4">
                    <LabelWrapper label={'Work email'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Object.keys(newUsers).map(userId => {
            return (
              <StyledInputRow key={`${userId}`}>
                <AddUserRow
                  onChange={(value: string, field: keyof User) => handleChange(value, field, userId)}
                  onRemove={() => deleteRow(userId)}
                  id={`${userId}`}
                  {...newUsers[userId]}
                />
              </StyledInputRow>
            );
          })}

          <StyledInputRow>
            <AddRowButton onClick={addUserRow} label={'add another'} width={'130px'} />
          </StyledInputRow>
        </div>
        {children ? children({onReset: handleReset}) : null}
      </>
    </Fragment>
  );
};

interface AddUserRowProps {
  onChange: (value: string, field: keyof User) => void;
  firstName: string;
  lastName: string;
  email: string | undefined;
  id: string;
  deletable: boolean;
  onRemove: () => void;
  hasError: string | false | undefined;
}

const AddUserRow: FC<AddUserRowProps> = ({
  onChange,
  firstName = '',
  lastName = '',
  email = '',
  id,
  deletable,
  onRemove,
  hasError = false,
}) => {
  return (
    <div className="row">
      <div className="scol-11">
        <div className="row">
          <div className="scol-4">
            <Input
              id={`firstName-${id}`}
              onChange={input => onChange(input, `firstName`)}
              type={'text'}
              placeholder={'First name'}
              value={firstName}
              maxLength={'50'}
            />
          </div>
          <div className="scol-4">
            <Input
              id={`lastName-${id}`}
              onChange={input => onChange(input, `lastName`)}
              type={'text'}
              placeholder={'Last name'}
              value={lastName}
              maxLength={'50'}
            />
          </div>
          <div className="scol-4">
            <StyledInputWrapper>
              <Input
                id={`email-${id}`}
                onChange={input => onChange(input, `email`)}
                type={'text'}
                placeholder={'Work email'}
                value={email}
                hasError={hasError === 'string' ? true : false}
                maxLength={50}
              />
              {hasError && <StyledInputError>Please enter a valid email</StyledInputError>}
            </StyledInputWrapper>
          </div>
        </div>
      </div>
      <div className="scol-1" style={{display: 'flex', alignItems: 'center'}}>
        {deletable && (
          <Icon type={'clear'} size={'medium'} color={'gray'} onClick={onRemove} style={{cursor: 'pointer'}} />
        )}
      </div>
    </div>
  );
};

const StyledInputRow = styled.div`
  margin-top: 25px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledInputWrapper = styled.div`
  display: block;
  position: relative;
`;

const StyledInputError = styled.p`
  position: absolute;
  color: var(--color-red);
  font-size: var(--font-size-sm);
`;
