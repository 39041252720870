import {SettingFilled} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';
import {FC} from 'react';
import {createGlobalStyle} from 'styled-components';

const makeContextMenu = ({onRename, onDelete}: {onRename: () => void; onDelete: () => void}): JSX.Element => (
  <Menu
    style={{minWidth: 130}}
    onClick={e => {
      switch (e.key) {
        case 'rename':
          onRename();
          break;
        case 'delete':
          onDelete();
          break;
      }
    }}
    data-testid="TabContextMenu"
    items={[
      {
        key: 'rename',
        label: 'Rename',
      },
      {
        key: 'delete',
        label: 'Delete',
      },
    ].filter(item => item)}
  />
);

export const TabContextMenuButton: FC<{onRename: () => void; onDelete: () => void}> = ({onRename, onDelete}) => (
  <>
    <Dropdown
      menu={{
        items: [
          {
            key: '1',
            label: makeContextMenu({onRename, onDelete}),
          },
        ],
      }}
      overlayClassName="tablist-context-menu"
      trigger={['click']}>
      <a
        className="ant-dropdown-link tab-context-menu-trigger"
        onClick={e => {
          e.stopPropagation();
        }}
        style={{display: 'inline-block', width: 12, marginLeft: 4}}
        data-testid="TabContextMenuButton">
        <SettingFilled />
      </a>
    </Dropdown>
    <GlobalStyles />
  </>
);

const GlobalStyles = createGlobalStyle`
 /* Hide the context menu button in our dropdown */
 .ant-tabs-dropdown-menu-item .tab-context-menu-trigger * {
    display: none;
  }
`;
