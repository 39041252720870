import {useSelector} from '../../../../redux/react-redux';
import {ReactNode} from 'react';
import styled from 'styled-components';
import {ContractDuration, ContractDurationPicker} from '../ContractDurationPicker';
import {plans} from '../../../../utils/Plans';
import {BadgeLightBlue} from '../PlanBadge';
import {ChangePlanButton} from '../ChangePlanButton';
import {Table} from 'antd';
import {getSubscriptionPlan} from '../../../../redux/selectors';
import Icon from '../../../../atoms/Icon';
import {planFeatures} from './planFeatures';
import {SubscriptionPlan} from '../../../../redux/ApiService/CompanyService/Company';
import {formatEUR} from '../../../../utils/formatCurrency';

export const PlanFeatureComparison = ({
  showActionButtons = true,
  contractDuration = 'monthly',
  onChangeContractDuration,
}: {
  showActionButtons?: boolean;
  contractDuration?: ContractDuration;
  onChangeContractDuration?: (contractDuration: ContractDuration) => void;
}) => {
  const subscribedPlan = useSelector(getSubscriptionPlan);

  return (
    <StyledTable
      size="small"
      tableLayout="fixed"
      pagination={false}
      dataSource={planFeatures}
      onRow={(_, rowIndex) => ({
        style: {
          background: rowIndex! % 2 === 0 ? 'var(--color-gray-6)' : '',
        },
      })}
      rowKey={record => record.title}
      columns={[
        {
          key: 'header',
          title: onChangeContractDuration ? (
            <HeaderColumnHeader $isWithButton={showActionButtons}>
              <ContractDurationPickerContainer>
                <ContractDurationPicker
                  contractDuration={contractDuration}
                  setContractDuration={onChangeContractDuration}
                />
                Pay yearly
                <BadgeLightBlue $isSmall>Save 15%</BadgeLightBlue>
              </ContractDurationPickerContainer>
            </HeaderColumnHeader>
          ) : null,
          render: (record: {title: string; badge?: ReactNode}) => (
            <HeaderWrapper>
              {record.title} {record.badge}
            </HeaderWrapper>
          ),
        },
        ...plans.map(plan => {
          const {title, priceMonthly, priceYearly} = plan;
          const price = contractDuration === 'monthly' ? priceMonthly : priceYearly;
          const priceString = formatEUR(price);
          return {
            key: plan.slug,
            title: (
              <ColumnHeader $isWithButton={showActionButtons}>
                <PlanTitle>{title}</PlanTitle>
                <PriceContainer>
                  <PriceValue>€{priceString}</PriceValue>
                  <PriceUnit>per user/month</PriceUnit>
                </PriceContainer>
                {showActionButtons && (
                  <ChangePlanButton
                    targetPlan={plan}
                    subscribedPlanSlug={subscribedPlan}
                    contractDuration={contractDuration}
                  />
                )}
              </ColumnHeader>
            ),
            render: (record: Record<SubscriptionPlan, boolean | string>) => (
              <FeatureWrapper>
                <FeatureValue value={record[plan.slug]} />
              </FeatureWrapper>
            ),
          };
        }),
      ]}
    />
  );
};

const FeatureValue = ({value}: {value: boolean | string}) => {
  if (typeof value === 'boolean') {
    return value ? <Icon type="check-mark-line" color="black" size="medium" /> : null;
  }
  return <>{value}</>;
};

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: var(--color-white);
  }

  // disable row hover
  .ant-table-tbody > tr:hover > td {
    background: none;
  }
`;

const ColumnHeader = styled.div<{$isWithButton?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  gap: 12px;
  height: ${({$isWithButton}) => ($isWithButton ? '150px' : '110px')};
  margin-bottom: 8px;
  padding: ${({$isWithButton}) => ($isWithButton ? '0 12px' : '0 40px')};
`;

const HeaderColumnHeader = styled(ColumnHeader)`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  padding: 0 12px;
`;

const ContractDurationPickerContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  font-weight: var(--font-weight-md);
`;

const PlanTitle = styled.div`
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-xl);
  line-height: 1.4;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 5px;
`;

const PriceValue = styled.div`
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  color: var(--color-black);
`;

const PriceUnit = styled.div`
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-md);
  line-height: 1.4;
  color: var(--color-gray-2);
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 24px;
`;

const FeatureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
