import {FC} from 'react';
import styled from 'styled-components';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {Input} from '../../../../antd/components/Input';
import {CopyOutlined, EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import {Button} from 'antd';
import {showNotification} from '../../../../utils/notification';
import {useGetCompanyApiKeyQuery} from './useGetCompanyApiKeyQuery';
import {SettingsCardBody} from '../../../../components/SettingsCard/SettingsCardBody';
import {SettingsCardHeader} from '../../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCard} from '../../../../components/SettingsCard/SettingsCard';
import {
  SettingsArea,
  SettingsAreaTitle,
  SettingsAreaTitleWrapper,
  SettingsAreaWrapper,
} from '../../../../components/SettingsCard/SettingsArea';

const CompanySettingsApi: FC<{company: Company}> = ({company}) => {
  const apiKeyQuery = useGetCompanyApiKeyQuery(company.id);

  const linkToApiDocumentation = `${window.location.origin}/node-backend/external-api`;
  const apiKey = apiKeyQuery.data?.apiKey;
  return (
    <SettingsCard>
      <SettingsCardHeader title={'Seabo API'} />
      <SettingsCardBody>
        <SettingsArea>
          <SettingsAreaWrapper>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>Company ID</SettingsAreaTitle>
              <Input
                readOnly={true}
                value={company.id.toString()}
                addonAfter={
                  <Button
                    type={'text'}
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(company.id.toString());
                      showNotification('Copied to clipboard', 'success');
                    }}
                    size={'small'}
                    icon={<CopyOutlined />}
                  />
                }
              />
            </SettingsAreaTitleWrapper>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>API-Key</SettingsAreaTitle>
              {apiKey && (
                <Input.Password
                  readOnly={true}
                  value={apiKey}
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  addonAfter={
                    <Button
                      type={'text'}
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                      onClick={async () => {
                        await navigator.clipboard.writeText(apiKey);
                        showNotification('Copied to clipboard', 'success');
                      }}
                      size={'small'}
                      icon={<CopyOutlined />}
                    />
                  }
                />
              )}
            </SettingsAreaTitleWrapper>
          </SettingsAreaWrapper>
          <Separator />

          <SettingsAreaWrapper style={{paddingBottom: 0}}>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>Documentation</SettingsAreaTitle>
            </SettingsAreaTitleWrapper>
            <div>
              API documentation is available at
              <br />
              <a target={'_blank'} href={linkToApiDocumentation} rel="noreferrer">
                {linkToApiDocumentation}
              </a>
            </div>
          </SettingsAreaWrapper>
        </SettingsArea>
      </SettingsCardBody>
    </SettingsCard>
  );
};

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-top: var(--border-base);
  margin-top: 32px;
  margin-bottom: 24px;
`;

export default CompanySettingsApi;
