import React, {FC, Fragment} from 'react';
import {Row} from 'antd';
import styled from 'styled-components';
import {WorkspaceIcon} from './WorkspaceIcon';
import {User, Workspace} from './WorkspaceSelectTypes';
import {Spacer} from '../Spacer/Spacer';
import {UserAvatar} from '../UserAvatar/UserAvatar';
import {EasySharingIcon} from './EasySharingIcon';
import {FeatureToggles} from '../../utils/FeatureToggles';

export const WorkspaceList: FC<{
  workspaces: Workspace[];
  user: User;
  value?: number;
  onSelect?: (workspace: Workspace) => void;
}> = ({workspaces, user, value, onSelect}) => (
  <div data-testid="WorkspaceList">
    {workspaces.map((workspace, index) => (
      <Fragment key={workspace.id}>
        <WorkspaceListEntry workspace={workspace} user={user} selected={value === workspace.id} onSelect={onSelect} />
        {index === 0 && <Separator />}
      </Fragment>
    ))}
  </div>
);

export const WorkspaceListEntry: FC<{
  workspace: Workspace;
  user: User;
  selected?: boolean;
  onSelect?: (workspace: Workspace) => void;
}> = ({workspace, user, selected, onSelect}) => {
  const icon = getIcon(workspace, user);
  return (
    <WorkspaceListEntryStyled>
      <ListEntryButton
        className={selected ? 'ListEntryButton--selected' : undefined}
        onClick={() => {
          onSelect?.(workspace);
        }}>
        <Row justify="start" align="middle" style={{flexWrap: 'nowrap'}}>
          {icon}
          <Spacer width="9px" />
          <TextStack>
            <Title>
              {workspace.name} {FeatureToggles.easySharing && <EasySharingIcon isActive={workspace.easySharing} />}
            </Title>
            {workspace.description && <Text>{workspace.description}</Text>}
            <Text>{workspace.email}</Text>
          </TextStack>
        </Row>
      </ListEntryButton>
    </WorkspaceListEntryStyled>
  );
};

const WorkspaceListEntryStyled = styled.div`
  width: 100%;
  min-width: 294px;
  color: var(--color-gray-2);
  background-color: var(--color-white) !important;

  svg {
    fill: var(--color-blue);
  }
`;

const TextStack = styled.div`
  width: 213px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  & > * {
    max-width: 213px;
  }
`;

const Separator = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: var(--border-base);
`;

const ListEntryButton = styled.button`
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 20px;
  background-color: var(--color-white);

  font-style: normal;
  font-size: var(--font-size-md);
  line-height: 19px;
  color: var(--color-black);
  cursor: pointer;
  text-transform: none !important;

  &:hover {
    background-color: var(--color-gray-5) !important;
    color: var(--color-black) !important;
  }

  &.ListEntryButton--selected {
    background-color: var(--color-gray-6) !important;
  }

  &:active {
    background-color: var(--color-gray-6) !important;
  }
`;

const getIcon = (workspace: Workspace, user: User): React.ReactNode => {
  if (workspace.id !== 0) {
    return (
      <IconCircle>
        <WorkspaceIcon />
      </IconCircle>
    );
  }
  return <UserAvatar user={user} />;
};

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #979797;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
`;

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-gray-2);
  font-weight: normal;
`;
