import {Helmet} from 'react-helmet-async';
import {AccountSegmentsSettings} from '../../../components/AccountSegmentsSelect/AccountSegmentsSettings';
import {showNotification} from '../../../utils/notification';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';
import {SettingsSection} from '../../../components/SettingsCard/SettingsSection';

export const MarketSegments = () => {
  return (
    <>
      <Helmet title="Segments - User account" />
      <SettingsCard data-testid="Segments">
        <SettingsCardHeader title={'Market segments'} />
        <SettingsCardBody>
          <SettingsSection $antForm>
            <div style={{marginTop: -8}}>
              <AccountSegmentsSettings
                type="tabs"
                onSave={() => {
                  showNotification('Segments saved.');
                }}
              />
            </div>
          </SettingsSection>
        </SettingsCardBody>
        <br />
      </SettingsCard>
    </>
  );
};
