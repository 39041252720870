import {VoyagePoint} from '../VoyageInformation/VoyageTypes';
import {useQuery} from '@tanstack/react-query';
import {bunkerServiceApi} from '../../../../api/bunkerservice/bunkerserviceApi';
import {Price} from '../../../../api/bunkerservice/generated';
import {Coordinates} from '../../../../utils/Coordinates';
import {queryClient} from '../../../../api/utils/reactQueryClient';

const bunkerTypeMapping: Record<string, 'ifo' | 'mgo'> = {
  IFO380: 'ifo',
  MGO: 'mgo',
  VLSFO: 'ifo',
  ULSFO: 'ifo',
  IFO180: 'ifo',
};

export type PriceAndFuelType = Price & {fuelType: 'ifo' | 'mgo' | undefined};

export const queryKey = 'bunkerPriceProposals';

export const useBunkerPriceProposalsQuery = ({voyagePoints}: {voyagePoints: VoyagePoint[]}) => {
  return useQuery({
    queryKey: [queryKey, voyagePoints.map(point => point.coordinates)],
    queryFn: () => {
      return getBunkerPriceProposals({voyagePoints});
    },
  });
};

export const getBunkerPriceProposalsByQuery = ({voyagePoints}: {voyagePoints: VoyagePoint[]}) =>
  queryClient.fetchQuery({
    queryKey: [queryKey, voyagePoints.map(point => point.coordinates)],
    queryFn: () => {
      return getBunkerPriceProposals({voyagePoints});
    },
  });

const fetchBunkerPricesForBoundingBox = async ({
  coordinates,
  grades,
  boundingBoxDifference,
}: {
  coordinates: Coordinates;
  grades: string[];
  boundingBoxDifference: number;
}): Promise<PriceAndFuelType[]> => {
  const prices = await bunkerServiceApi.postBunkerservice({
    query: {
      grades: grades,

      latitudeBottomLeft: coordinates[1] - boundingBoxDifference,
      latitudeUpperRight: coordinates[1] + boundingBoxDifference,

      longitudeBottomLeft: coordinates[0] - boundingBoxDifference,
      longitudeUpperRight: coordinates[0] + boundingBoxDifference,
    },
  });

  return (prices.priceList ?? []).map((item): PriceAndFuelType => {
    return {...item, fuelType: bunkerTypeMapping[item.grade ?? '']};
  });
};

const FETCH_BUNKERPRICES_ATTEMPTS = 10;

const getFuelTypesForSinglePoint = async ({
  voyagePoint,
  grades,
}: {
  voyagePoint: VoyagePoint;
  grades: string[];
}): Promise<PriceAndFuelType[]> => {
  let boundingBoxDistance = 0.5;

  /*
    We query the prices inside the bounding box around the voyage point,
    if we don't find any prices inside the box,
    we double the size of the bounding box until we find a price.
   */
  for (let iteration = 0; iteration < FETCH_BUNKERPRICES_ATTEMPTS; ++iteration) {
    const result = await fetchBunkerPricesForBoundingBox({
      grades,
      coordinates: voyagePoint.coordinates,
      boundingBoxDifference: boundingBoxDistance,
    });
    if (result.length > 0) {
      return result;
    }
    boundingBoxDistance = boundingBoxDistance * 2;
  }
  return [];
};

const fetchPricesForOneVoyagePoint = async ({voyagePoint}: {voyagePoint: VoyagePoint}): Promise<PriceAndFuelType[]> => {
  const ifoGrades = ['IFO380', 'ULSFO', 'IFO180', 'HSFO'];

  const ifoPrices = await getFuelTypesForSinglePoint({voyagePoint, grades: ifoGrades});

  const mgoGrades = ['MGO'];
  const mgoPrices = await getFuelTypesForSinglePoint({voyagePoint, grades: mgoGrades});

  const vlsfoGrades = ['VLSFO'];
  const vlsfoPrices = await getFuelTypesForSinglePoint({voyagePoint, grades: vlsfoGrades});

  return [...ifoPrices, ...mgoPrices, ...vlsfoPrices];
};

const getBunkerPriceProposals = async ({voyagePoints}: {voyagePoints: VoyagePoint[]}): Promise<PriceAndFuelType[]> => {
  const priceProposals = voyagePoints.flatMap(async point => {
    return fetchPricesForOneVoyagePoint({voyagePoint: point});
  });

  const allProposals = await Promise.all(priceProposals);

  const flatProposals = allProposals.flatMap(item => item);

  return flatProposals.map(item => {
    const newItem = {...item};
    if (newItem.grade === 'HSFO') {
      newItem.grade = 'IFO';
    }
    return newItem;
  });
};
