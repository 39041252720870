import {
  NegotiationParty,
  NegotiationSubject,
  NegotiationSubjectModification,
  NegotiationSubjectModificationReport,
  NegotiationSubjectStatus,
} from '../../../../../../api/node-backend/generated';

const {Ongoing, Accepted, Rejected, RejectionRequested, Lifted, Failed} = NegotiationSubjectStatus;

const {Unmodified, NewSubject} = NegotiationSubjectModification;

export type DerivedSubjectState = Partial<{
  isNewSubject: boolean;
  isOwnSubject: boolean;
  isCountered: boolean;
  isModified: boolean;
  isModifiedByCurrentParty: boolean;
  canBeEdited: boolean;
  canBeReset: boolean;
  canBeDeleted: boolean;
  canBeAccepted: boolean;
  canBeRejectionRequested: boolean;
  canBeRejectRequestSettled: boolean;
  canBeSettled: boolean;
  canBeLifted: boolean;
  canBeFailed: boolean;
}>;

export const getDerivedSubjectState = ({
  party,
  roundCanBeEdited,
  subject,
  subjectModifications,
}: {
  party: NegotiationParty;
  roundCanBeEdited: boolean;
  subject: NegotiationSubject;
  subjectModifications?: NegotiationSubjectModificationReport;
}) => {
  const {party: subjectParty, status, originId} = subject;
  const subjectModification = subjectModifications?.subjectModification;

  const isNewSubject = originId === null;
  const isModified = ![Unmodified, NewSubject, undefined].includes(subjectModification);
  // lastModifiedBy only reflects who last changed subject status
  const isModifiedByCurrentParty = subject.lastModifiedBy === party;
  // lastEditedBy only reflects who last changed the subject text
  const isCountered = subject.textLastUpdatedBy !== party;

  const isOwnSubject = subjectParty === party;

  const isOngoing = status === Ongoing;
  const isAccepted = status === Accepted;
  const isRejectRequested = status === RejectionRequested;
  const isRejected = status === Rejected;
  const isLifted = status === Lifted;
  const isFailed = status === Failed;

  const baseState = {
    isNewSubject,
    isOwnSubject,
    isCountered,
    isModified,
    isModifiedByCurrentParty,
  };

  const noActionsAllowedState = {
    canBeEdited: false,
    canBeDeleted: false,
    canBeReset: false,
    canBeAccepted: false,
    canBeRejectionRequested: false,
    canBeRejectRequestSettled: false,
    canBeSettled: false,
    canBeLifted: false,
    canBeFailed: false,
  };

  if (!roundCanBeEdited) {
    return {
      ...baseState,
      ...noActionsAllowedState,
    };
  }

  if (isNewSubject) {
    return {
      ...baseState,
      ...noActionsAllowedState,
      canBeEdited: true,
      canBeDeleted: true,
    };
  }

  const canBeReset = isModified && isModifiedByCurrentParty;

  if (isRejected || isLifted || isFailed) {
    return {
      ...baseState,
      ...noActionsAllowedState,
      canBeReset,
    };
  }

  if (isOngoing) {
    return {
      ...baseState,
      ...noActionsAllowedState,
      canBeReset,
      canBeEdited: true,
      canBeAccepted: !canBeReset && isCountered,
      canBeRejectionRequested: !canBeReset && isCountered,
    };
  }

  if (isRejectRequested) {
    return {
      ...baseState,
      ...noActionsAllowedState,
      canBeReset,
      canBeEdited: !canBeReset,
      canBeRejectRequestSettled: !isModifiedByCurrentParty,
    };
  }

  if (isAccepted) {
    return {
      ...baseState,
      ...noActionsAllowedState,
      canBeReset,
      canBeSettled: !canBeReset && isOwnSubject,
      canBeLifted: !canBeReset && isOwnSubject,
      canBeFailed: !canBeReset && isOwnSubject,
    };
  }

  throw new UnhandledSubjectStateError(subject, subjectModification, party, roundCanBeEdited);
};

class UnhandledSubjectStateError extends Error {
  constructor(
    public subject: NegotiationSubject,
    public subjectModification?: NegotiationSubjectModification,
    public party?: NegotiationParty,
    public roundCanBeEdited?: boolean
  ) {
    super('Unhandled subject state');
    this.name = 'InvalidSubjectStateError';
    // eslint-disable-next-line no-console
    console.error({
      subject,
      subjectModification,
      party,
      roundCanBeEdited,
    });
  }
}
