import {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {PortfolioType, PortfolioVessel, PortfolioActions} from '../../../redux/Portfolio';
import {GridClipboardActions, ClipboardItem, ChangeAllItemsPayload} from '../../../redux/GridClipboard';
import {MyFleetVesselGrid} from './MyFleetGrid';
import {MyFleetGridLayoutSelect} from './MyFleetGridLayoutSelect/MyFleetGridLayoutSelect';
import {
  invalidateGetPortfolioVesselsQuery,
  useGetPortfolioVesselsQuery,
} from './useGetPortfolioVesselsQuery/useGetPortfolioVesselsQuery';
import {ArchiveSubPortfolioList} from '../../../components/Portfolio/SubPortfolioList/ArchiveSubPortfolioList';
import {MyFleetActionButton} from './MyFleetActionButton';
import {PortfolioVesselDatabaseFilter} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {useOpenCargoForm} from '../../CargoVesselForm/utils/useOpenCargoForm';
import {useOpenVesselForm} from '../../CargoVesselForm/utils/useOpenVesselForm';
import '../../portfolio/shared/style.scss';
import {useQueryClient} from '@tanstack/react-query';

const TYPE: PortfolioType = 'vessel';

const ArchiveFilter: PortfolioVesselDatabaseFilter = {archive: {isArchived: true}};

export const MyFleetArchiveScreen: FC = () => {
  const dispatch = useDispatch();
  const openVesselForm = useOpenVesselForm();
  const openCargoForm = useOpenCargoForm();

  const queryClient = useQueryClient();
  const vesselFilters = ArchiveFilter;

  const vesselGridState = useSelector(state => state.portfolio.vesselGridState);
  const clipboardState = useSelector(state => state.gridClipboard.portfolio);

  const setPageIndex = (pageIndex: number) => {
    dispatch(PortfolioActions.setPageNumber(TYPE, pageIndex));
  };
  const setVessels = useCallback(
    ({vessels, vesselsTotal}: {vessels: PortfolioVessel[]; vesselsTotal: number}) =>
      dispatch(PortfolioActions.setVessels({vessels, vesselsTotal})),
    [dispatch]
  );
  const changeSelectedItems = useCallback(
    (payload: ChangeAllItemsPayload) => dispatch(GridClipboardActions.changeAllItems(payload)),
    [dispatch]
  );

  useEffect(() => {
    setPageIndex(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vesselsQuery = useGetPortfolioVesselsQuery({
    filters: vesselFilters,
    pageNumber: vesselGridState.pageNumber,
    pageSize: vesselGridState.pageSize,
    sortField: vesselGridState.sortField,
    isArchive: true,
    sortOrder: vesselGridState.sortOrder,
  });

  const loading = vesselsQuery.isLoading;
  const vessels: PortfolioVessel[] | null = vesselsQuery.isSuccess ? vesselsQuery.data.data.items : null;
  const vesselsTotal = vesselsQuery.isSuccess ? vesselsQuery.data.data.totalItems : 0;

  const getIfAllSelected = useCallback(
    (screenData: PortfolioVessel[]) => {
      const {vessels} = clipboardState;
      const isSelected = (vessel: ClipboardItem) => screenData.map((sd: PortfolioVessel) => sd.id).includes(vessel.id);
      const selectedVessels = vessels.filter((vessel: ClipboardItem) => isSelected(vessel));
      return selectedVessels.length === 0 || screenData.length === 0
        ? false
        : selectedVessels.length === screenData.length;
    },
    [clipboardState]
  );

  const reloadGrid = (pageIndex?: number) => {
    if (pageIndex) {
      setPageIndex(pageIndex);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    invalidateGetPortfolioVesselsQuery(queryClient);
  };

  useEffect(() => {
    if (vesselsQuery.isSuccess && vesselsQuery.data) {
      setVessels({
        vessels: vesselsQuery.data.data.items,
        vesselsTotal: vesselsQuery.data.data.totalItems,
      });
      changeSelectedItems({
        section: 'portfolio',
        subsection: 'vessels',
        newValue: getIfAllSelected(vesselsQuery.data.data.items),
      });
    }
  }, [vesselsQuery.isSuccess, vesselsQuery.data, setVessels, changeSelectedItems, getIfAllSelected]);

  return (
    <div className={'portfolio'}>
      <ScreenHeader
        helmetTitle="Archived My Fleet"
        breadcrumbs={[{title: 'My Fleet', href: '/my-fleet'}, {title: 'Archive'}]}
        actions={
          <>
            <ScreenHeader.Actions.Button data-cy="PortfolioVesselOpenExportModalBTN" disabled={true}>
              Export
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button
              id="addVessel"
              data-cy="PortfolioVesselAddBTN"
              onClick={() => openVesselForm({})}>
              Add vessel
            </ScreenHeader.Actions.Button>
            <ScreenHeader.Actions.Button id="addCargo" data-cy="PortfolioCargoAddBTN" onClick={() => openCargoForm({})}>
              Add cargo
            </ScreenHeader.Actions.Button>
          </>
        }
      />
      <div className="portfolio__sub-container" style={{display: 'grid', gridTemplateColumns: '1fr auto'}}>
        <ArchiveSubPortfolioList type={TYPE} />
        <MyFleetGridLayoutSelect vessels={vessels} />
      </div>
      <MyFleetVesselGrid
        isFiltered={false}
        isArchive={true}
        loading={loading}
        vessels={vessels}
        vesselsTotal={vesselsTotal}
        onReloadGrid={reloadGrid}
      />
      <MyFleetActionButton items={clipboardState.vessels} archive={true} onReloadGrid={reloadGrid} />
    </div>
  );
};
