import {VoyagePointTerm} from '../../VoyageInformation/VoyagePointTerm';
import {fhex} from './terms/fhex';
import {sshex} from './terms/sshex';
import {satpmShex} from './terms/satpmShex';
import {ssex} from './terms/ssex';
import {shex} from './terms/shex';
import {finc} from './terms/finc';
import {phex} from './terms/phex';
import {shinc} from './terms/shinc';
import {sshinc} from './terms/sshinc';
import {ssinc} from './terms/ssinc';
import {QueryClient} from '@tanstack/react-query';
import {Dayjs} from 'dayjs';

export const checkIsWorking = async ({
  term,
  isMorning,
  date,
  countryCode,
  queryClient,
}: {
  date: Dayjs;
  term: VoyagePointTerm;
  countryCode?: string;
  queryClient: QueryClient;
  isMorning: boolean;
}) => {
  const isoWeekday = date.isoWeekday(); // 1 = Monday 7 = Sunday

  switch (term) {
    case 'FINC':
      return finc({isoWeekday, isMorning});
    case 'PHEX':
      return phex({isoWeekday, isMorning, date, countryCode, queryClient});

    case 'SHINC':
      return shinc({isoWeekday, isMorning});

    case 'SSHINC':
      return sshinc({isoWeekday, isMorning});
    case 'SSINC':
      return ssinc({isoWeekday, isMorning});

    case 'FHEX':
      return fhex({isoWeekday, isMorning, date, countryCode, queryClient});
    case 'SSHEX':
      return sshex({isoWeekday, isMorning, date, countryCode, queryClient});
    case 'SATPM SHEX':
      return satpmShex({isoWeekday, isMorning, date, queryClient, countryCode});
    case 'SSEX':
      return ssex({isoWeekday, isMorning});
    case 'SHEX':
      return shex({isoWeekday, isMorning, date, countryCode, queryClient});

    default:
      return true;
  }
};
