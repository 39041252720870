/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Subdivision
 */
export interface Subdivision {
  /**
   *
   * @type {string}
   * @memberof Subdivision
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Subdivision
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Subdivision
   */
  languages?: Array<string>;
}

export function SubdivisionFromJSON(json: any): Subdivision {
  return SubdivisionFromJSONTyped(json, false);
}

export function SubdivisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subdivision {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, 'code') ? undefined : json['code'],
    name: !exists(json, 'name') ? undefined : json['name'],
    languages: !exists(json, 'languages') ? undefined : json['languages'],
  };
}

export function SubdivisionToJSON(value?: Subdivision | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    name: value.name,
    languages: value.languages,
  };
}
