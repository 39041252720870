import {ReactElement, FC} from 'react';
import {GridAndMapLayoutType} from '../../../redux/Spotifind';
import {assertUnreachable} from '../../../utils/assert';
import {OneColumnLayout} from './OneColumnLayout';
import {TwoColumnLayout} from './TwoColumnLayout';

export const GridAndMapLayout: FC<{layout: GridAndMapLayoutType; map: ReactElement; grid: ReactElement}> = ({
  layout,
  map,
  grid,
}) => {
  switch (layout) {
    case 'oneColumn':
      return <OneColumnLayout map={map} grid={grid} />;
    case 'twoColumn':
      return <TwoColumnLayout map={map} grid={grid} />;
    default:
      assertUnreachable(layout);
  }
};
