import dayjs, {Dayjs} from 'dayjs';
import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {dateEarlierThanOrEqualTo} from '../../../../../utils/validators';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';

type DateString = string;

export interface DatabaseType {
  from: DateString | null;
  to: DateString | null;
}

export interface InternalType {
  from: Dayjs | null;
  to: Dayjs | null;
}

export const Defaults: InternalType = {
  from: null,
  to: null,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    from: database.from ? dayjs(database.from) : null,
    to: database.to ? dayjs(database.to) : null,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const database: DatabaseType = {
    from: null,
    to: null,
  };

  if (internal.from) {
    database.from = internal.from.format(DateTimeFormat.IsoDate);
  }
  if (internal.to) {
    database.to = internal.to.format(DateTimeFormat.IsoDate);
  }

  return database;
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Updated at',
  branch: 'updatedAtRange',
  defaults: Defaults,
  validators: {
    from: dateEarlierThanOrEqualTo('to'),
  },
  fromDatabase,
  toDatabase,
});
