import {FC} from 'react';
import styled from 'styled-components';

export const ClipboardVesselsCantMatch: FC<{notMatchedVessels: number}> = ({notMatchedVessels}) => {
  if (notMatchedVessels === 0) {
    return null;
  }

  return <Container id={'toLook'}>{notMatchedVessels} vessels could not be found on the map.</Container>;
};

const Container = styled.div`
  position: absolute;
  bottom: 33px;
  left: 8px;
  padding: 8px;
  background-color: var(--color-white);
`;
