import {DndContext, PointerSensor, closestCenter, useSensor, useSensors} from '@dnd-kit/core';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import {restrictToVerticalAxis, restrictToWindowEdges} from '@dnd-kit/modifiers';
import {SortableContext, verticalListSortingStrategy, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React, {CSSProperties, FC, ReactNode} from 'react';
import {TODO} from '../../utils/TODO';
import styled from 'styled-components';

type DragAndDropListProps<TItemType> = {
  items: TItemType[];
  canUpdateSort?: boolean;
  onUpdateSort?: (id: string, from: number, to: number) => void;
  renderTermItem: (idx: number, item: TItemType, dragListeners?: SyntheticListenerMap) => ReactNode;
};

export const DragAndDropList = <TItemType extends {id: string | number}>({
  items,
  canUpdateSort,
  onUpdateSort,
  renderTermItem,
}: DragAndDropListProps<TItemType>) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragEnd = async (event: TODO) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      const from = active.data.current.sortable.index;
      const to = over.data.current.sortable.index;
      onUpdateSort?.(active.id, from, to);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
      <SortableContext items={items.map(({id}) => id)} strategy={verticalListSortingStrategy}>
        {items.map((item, idx) => (
          <FocusElevatedContainer key={item.id} className="drag-item">
            <Draggable id={item.id} active={canUpdateSort} key={item.id}>
              {dragListeners => renderTermItem(idx, item, dragListeners)}
            </Draggable>
          </FocusElevatedContainer>
        ))}
      </SortableContext>
    </DndContext>
  );
};

const Draggable: FC<{
  id: string | number;
  active?: boolean;
  children: (listeners?: SyntheticListenerMap) => ReactNode;
}> = ({id, active, children}) => {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id});
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 3 : 2,
    position: 'relative',
  };

  if (!active) {
    return <div>{children(listeners)}</div>;
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {children(listeners)}
    </div>
  );
};

const FocusElevatedContainer = styled.div`
  position: relative;
  isolation: isolate;
  z-index: 1;

  &:focus-within {
    z-index: 2;
  }
`;
