import React, {FC} from 'react';
import styled from 'styled-components';
import {
  NegotiationRoundResult,
  NegotiationRoundResultModification,
  NegotiationSummary,
} from '../../../../../../api/node-backend/generated';
import {HistoryDetailSubjectList} from './HistoryDetailSubjectList';
import {HistoryDetailTermList} from './HistoryDetailTermList';

type HistoryDetailBodyProps = {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  roundResultModifications: NegotiationRoundResultModification;
  hideUnchanged?: boolean;
  hideSettled?: boolean;
};

export const HistoryDetailBody: FC<HistoryDetailBodyProps> = ({
  negotiation,
  roundResult,
  roundResultModifications,
  hideUnchanged,
  hideSettled,
}) => {
  return (
    <>
      <Label>Terms:</Label>
      <HistoryDetailTermList
        party={negotiation.party}
        status={negotiation.status}
        terms={roundResult.terms}
        roundResultModifications={roundResultModifications}
        hideUnchanged={hideUnchanged}
        hideSettled={hideSettled}
      />
      <Label>Subjects:</Label>
      <HistoryDetailSubjectList
        negotiation={negotiation}
        subjects={roundResult.subjects}
        roundResultModifications={roundResultModifications}
        hideUnchanged={hideUnchanged}
        hideSettled={hideSettled}
      />
    </>
  );
};

const Label = styled.div`
  font-size: var(--font-size-md);
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;
