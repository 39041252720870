/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {NewTerm, NewTermFromJSON, NewTermFromJSONTyped, NewTermToJSON} from './';

/**
 *
 * @export
 * @interface NewTermsRequest
 */
export interface NewTermsRequest {
  /**
   * In which negotiation the new term should be added.
   * @type {string}
   * @memberof NewTermsRequest
   */
  negotiationId: string;
  /**
   * Terms to be added to the negotiation.
   * @type {Array<NewTerm>}
   * @memberof NewTermsRequest
   */
  newTerms: Array<NewTerm>;
}

export function NewTermsRequestFromJSON(json: any): NewTermsRequest {
  return NewTermsRequestFromJSONTyped(json, false);
}

export function NewTermsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewTermsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    negotiationId: json['negotiationId'],
    newTerms: (json['newTerms'] as Array<any>).map(NewTermFromJSON),
  };
}

export function NewTermsRequestToJSON(value?: NewTermsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    negotiationId: value.negotiationId,
    newTerms: (value.newTerms as Array<any>).map(NewTermToJSON),
  };
}
