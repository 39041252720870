import {QueryClient, useQuery} from '@tanstack/react-query';
import {companyApi} from '../api/symfony/symfonyApi';
import {UseQueryOptions} from '../api/utils/ReactQuery';
import {assert} from '../utils/assert';
import CompanyService from '../redux/ApiService/CompanyService/CompanyService';
import {useDispatch} from '../redux/react-redux';
import {Company} from '../redux/ApiService/CompanyService/Company';
import {CompanySummary} from '../api/symfony/generated';

const QUERY_KEY = 'company';

export const useGetCompanyQuery = (companyId: number, options?: UseQueryOptions<Company>) => {
  const dispatch = useDispatch();
  const getCompany = (id: number): Promise<Company> => {
    assert(id, 'Missing companyId');
    return dispatch(CompanyService.getCompany(id));
  };
  return useQuery({
    queryKey: [QUERY_KEY, companyId],
    queryFn: () => getCompany(companyId),
    ...options,
  });
};

export const useGetCompaniesQuery = () =>
  useQuery({
    queryKey: [QUERY_KEY, 'list'],
    queryFn: () => companyApi.getCompanies({}),
  });

export const invalidateCompaniesQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [QUERY_KEY, 'list'],
  });
};

export const useCompanyIdToCompanyMapQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEY, 'map'],
    queryFn: async () => {
      const companies = await companyApi.getCompanies({});
      const companyIdToCompanyMap = new Map<number, CompanySummary>();
      companies.items.forEach(company => {
        companyIdToCompanyMap.set(company.id, company);
      });
      return companyIdToCompanyMap;
    },
  });
};
