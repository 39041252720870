import {FilterBranchDefinitionsDefault, FilterDatabaseType, FilterInternalType} from './FilterBranchDefinition';
import {FilterProviderProps} from './FilterProviderProps';

/**
 * The state of the FilterProvider component.
 * This state is used to manage the filter settings and UI and converting values between internal and API formats.
 * @param FilterBranchDefinitions The filter branch definitions that the FilterProviderProps receive.
 */
export type FilterProviderState<FilterBranchDefinitions extends FilterBranchDefinitionsDefault> = {
  /**
   * The current filter values.
   */
  internalFilterSettings: Partial<FilterInternalType<FilterBranchDefinitions>>;
  /**
   * Which filter branches are applied (true) and which are not (false).
   */
  appliedFilters: Record<keyof FilterBranchDefinitions, boolean>;
  /**
   * The API id of the current filter.
   */
  activeFilterId: number | null;
  /**
   * The filter after transformation into internal format at the moment when loadFilter() was called.
   */
  loadedFilter: Partial<FilterDatabaseType<FilterBranchDefinitions>>;
  /**
   * Seems to be 'name' all the time.
   */
  activeFilterName: string | undefined | null;
  /**
   * Whether the user has clicked the global 'reset'.
   */
  hasResetted: boolean;
  /**
   * Whether the "Save" button should be disabled.
   */
  disableSaveButton: boolean;
};

export const getFilterProviderState = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  props: FilterProviderProps<FilterBranchDefinitions>
): FilterProviderState<FilterBranchDefinitions> => {
  return {
    internalFilterSettings: Object.entries(props.filterBranchDefinitions).reduce(
      (acc, [branchKey, branch]) => ({
        ...acc,
        [branchKey as keyof FilterBranchDefinitions]: branch.defaults,
      }),
      {}
    ),
    appliedFilters: Object.keys(props.filterBranchDefinitions).reduce(
      (acc, branchKey) => ({...acc, [branchKey as keyof FilterBranchDefinitions]: false}),
      {} as Record<keyof FilterBranchDefinitions, boolean>
    ),
    activeFilterId: null,
    loadedFilter: {},
    activeFilterName: null,
    hasResetted: false,
    disableSaveButton: true,
  };
};
