import BindToProvider, {WrappedDetailsSection} from '../../../../components/FormProvider/BindToProvider';
import {newConsumption} from './ConsumptionRows';
import {alwaysValid} from '../../../../utils/validators';

export const ConsumptionWrapper = BindToProvider(
  'Speed and consumption',
  'consumptions',
  {
    consumptions: [],
  },
  {
    consumptions: alwaysValid,
  },
  {
    toApi: (fields: $TSFixMe) => ({
      ...fields,

      consumptions: fields.consumptions
        .filter((c: $TSFixMe) => c.speed || c.fuelConsumption || c.extraFuelConsumption)
        .map((consumption: $TSFixMe) => ({
          ...consumption,
          speed: parseFloat(consumption.speed),
          fuelConsumption: parseFloat(consumption.fuelConsumption),
          extraFuelConsumption: parseFloat(consumption.extraFuelConsumption),
        })),
    }),
    fromApi: (fields: $TSFixMe) => ({
      ...fields,

      consumptions: fields.consumptions
        ? fields.consumptions.map((consumption: $TSFixMe) => ({
            ...newConsumption,

            ...Object.keys(consumption).reduce((fields, field) => {
              if (consumption[field] || consumption[field] === 0) {
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                fields[field] = consumption[field];
              }
              return fields;
            }, {}),
          }))
        : [],
    }),
  },
  true
)(WrappedDetailsSection);
