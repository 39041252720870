import {FC, useLayoutEffect} from 'react';
import {COMMAND_PRIORITY_NORMAL, LexicalCommand} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {convertLexicalNodesToText} from '../utils/utils';

type GetTemplateTextPluginProps = {
  events: LexicalCommand<unknown>[];
  callback: (text: string) => void;
};

/**
 * Registers for each given event a LexicalEditor command that will trigger the callback with the current template text
 */
export const GetTemplateTextPlugin: FC<GetTemplateTextPluginProps> = ({events, callback}) => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    return mergeRegister(
      ...events.map(event => {
        return editor.registerCommand(
          event,
          () => {
            const state = editor.getEditorState().toJSON();
            const text = convertLexicalNodesToText(state.root);
            callback(text);
            return false;
          },
          COMMAND_PRIORITY_NORMAL
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, callback]);

  return null;
};
