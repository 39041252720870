import {TODO} from '../../../../utils/TODO';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import Checkbox from '../../../../atoms/Checkbox';
import {ClipboardItem} from '../../../../redux/GridClipboard';
import {SharingIcon} from '../../MarketVessel/VesselGrid/SharingIcon';

export const getClipBoardColumn = ({
  clipboardState,
  checkAllCargoes,
  removeItemFromClipboard,
  addItemToClipboard,
  removeAllItemsFromClipboard,
  addAllItemsToClipboard,
}: {
  clipboardState: ClipboardItem[];
  checkAllCargoes: boolean;
  removeItemFromClipboard: (item: TODO) => void;
  addItemToClipboard: (item: TODO) => void;
  addAllItemsToClipboard: () => void;
  removeAllItemsFromClipboard: () => void;
}): CustomColumnDef<TODO> => {
  return {
    header: ({table}) => (
      <Checkbox
        id={`clipboard-checkbox-all`}
        checked={checkAllCargoes}
        onChange={e => {
          table.getToggleAllRowsSelectedHandler();
          e.target.checked ? addAllItemsToClipboard() : removeAllItemsFromClipboard();
        }}
      />
    ),
    id: 'clipBoard',
    enableSorting: false,
    className: 'datagrid-td-clipboard-market',
    headerClassName: 'datagrid-th-clipboard-market',
    cell: d => {
      return (
        <div>
          <Checkbox
            id={`clipboard-checkbox-${d.row.original.id}`}
            checked={!!clipboardState.find((cbi: $TSFixMe) => cbi.id === d.row.original.id)}
            onChange={e => {
              e.target.checked ? addItemToClipboard(d.row.original) : removeItemFromClipboard(d.row.original);
            }}
          />
          {'shared' in d.row.original && d.row.original.shared && (
            <SharingIcon type="inbox" $activeTab={false} $isActive={true} />
          )}
        </div>
      );
    },
    width: 32,
  };
};
