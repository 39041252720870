import {useQuery} from '@tanstack/react-query';
import ImageUpload from '../../../../../components/ImageUpload';
import {VesselType} from '../../../../../api/symfony/generated';
import {StationObject} from '../../../../../model/Station';
import {fetchJSON} from '../../../../../api/utils/fetchJSON';
import {TODO} from '../../../../../utils/TODO';
import {AISDetails} from '../../../../../components/ProjectDetailsScreen/types';
import {UseQueryOptions} from '../../../../../api/utils/ReactQuery';

export type Consumption = {
  id: number;
  type: string;
  speed: number;
  fuelConsumption: number;
  fuelType: string;
  extraFuelConsumption: number | null;
  extraFuelType: string | null;
};

export type Gear = {
  id: number;
  type: string;
  quantity: number;
  capacity: number | null;
  description: string | null;
};

export interface Vessel {
  name: string;
  id: number;
  builtYear?: number;
  nextOpenFrom: string | null;
  nextOpenTo: string | null;
  countryFlag: string;
  iceClass: string | null;
  draft: number;
  beam: number;
  tonnesPerCentimetre: number | null;
  dwtSummer: number;
  grossTonnage?: number;
  netTonnage?: number;
  lengthOverall: null | number;
  bale: number | null;
  baleUnit: string | null;
  grain: number | null;
  grainUnit: string | null;
  vesselType: VesselType;
  shipBuilder: string | null;
  gears: Gear[];
  intakes: {
    type: string;
    quantity: number;
    isHighCube: boolean;
    isEmpty: boolean;
  }[];
  designSubType: string | null;
  stations: StationObject[];
  consumptions: Consumption[];
  documents?: Document[];
  images?: ImageUpload[];
  aisDetails?: AISDetails;
}

export const useMyFleetQuery = (props: {vesselIds: number[]; options?: UseQueryOptions<Vessel[]>}) => {
  const vesselsQuery = useQuery({
    queryKey: ['portfolio/vessels', props.vesselIds],
    queryFn: () => loadVessels(props.vesselIds),
    ...props.options,
  });

  return vesselsQuery;
};

const loadVessels = async (vesselIds: number[]): Promise<Vessel[]> => {
  const vessels: Vessel[] = await Promise.all(vesselIds.map(loadVessel));
  return vessels;
};

const loadVessel = async (vesselId: number): Promise<Vessel> => {
  const json = await fetchJSON<TODO>(`/api/vessels/${vesselId}`);
  return json;
};
