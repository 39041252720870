import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {FullPageFooter} from './FullPageFooter';
import {HeaderLogo} from './HeaderLogo';

type MinimalLayoutProps = {
  children: ReactNode;
  navbarAddon?: ReactNode;
  backgroundColor?: string;
};

export const MinimalLayout: FC<MinimalLayoutProps> = ({children, navbarAddon, backgroundColor = 'gray-5'}) => {
  return (
    <PageContainer>
      <FullPageHeaderStyled id="full-page-header">
        <FullPageHeaderLayout>
          <HeaderLogo />
          {navbarAddon}
        </FullPageHeaderLayout>
      </FullPageHeaderStyled>
      <div className="userNotice layoutBoundary" style={{display: 'block'}}></div>
      <ContentContainer $backgroundColor={backgroundColor}>
        <div>{children}</div>
      </ContentContainer>
      <FullPageFooter type="condensed" />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  min-height: 100vh;
`;

const ContentContainer = styled.div<{$backgroundColor?: string}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 112px 40px 40px;
  background: var(--color-${({$backgroundColor}) => $backgroundColor});
`;

const FullPageHeaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;

  width: 100%;
  height: 72px;

  box-shadow: 0px 20px 20px rgba(177, 177, 177, 0.25);
  background-color: var(--color-white);
`;

const FullPageHeaderLayout = styled.div`
  width: calc(100vw - 30px);
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
