import {mapVesselToVesselInput} from '../../Voyagecharter/utils/mapVesselToVesselInput';
import {mapCargoToVoyageInput} from '../../Voyagecharter/utils/mapCargoToVoyageInput/mapCargoToVoyageInput';
import {mapCargoToCargoInput} from '../../Voyagecharter/utils/mapCargoToCargoInput';
import {CostInput} from '../../Voyagecharter/CostInformation/CostTypes';
import {
  InputState as VoyageCalculationInput,
  OutputState as VoyageCalculationOuput,
  voyageChartercalculation,
} from '../../Voyagecharter/utils/voyageChartercalculation/voyageChartercalculation';
import calcRoutes, {
  CalcRoutesOutput as WaypointsForRoute,
  CalcRoutesPropsRoute,
} from '../../Voyagecharter/utils/calcRoutes';
import {Assumptions, Cargo, CargoProject, Vessel, VesselProject} from '../types';
import {fixesRouteConsumptionIfNeeded} from '../../Voyagecharter/utils/fixesRouteConsumptionIfNeeded';
import {blankState} from '../../Voyagecharter/Context/blankState';
import {Coordinates} from '../../../../utils/Coordinates';
import {StowageFactor} from '../../../StowageFactorPicker/useStowageFactorsQuery';

export type OneCalcParamsFirst = {
  cargo: Cargo;
  vessel: Vessel;
  vesselProject?: VesselProject;
  cargoProject?: CargoProject;
  assumptions: Assumptions;
  id: number;
  stowageFactors: StowageFactor[];
};

export type OneCalcResult = {
  cargo: Cargo;
  vessel: Vessel;
  vesselProject?: VesselProject;
  cargoProject?: CargoProject;

  voyageCalculationOutput: VoyageCalculationOuput;
  voyageCalculationInput: VoyageCalculationInput;
  id: number;
};

/**
 * Performs a voyage calcuation for a existing vessel project and a cargo from API,
 * therefore it transfrom the input into a InputState for a voyageCalc,
 * after that it gets the waypoints, duration and distance of the voyage route,
 * and calculate the OutputState
 * and returns the result from type OneCalc.
 */
export const oneCalcFirstStep = async (params: OneCalcParamsFirst): Promise<OneCalcResult> => {
  const cargoInput = await mapCargoToCargoInput({cargo: params.cargo, stowageFactors: params.stowageFactors});
  const voyageInput = mapCargoToVoyageInput({cargo: params.cargo});
  const vesselInput = await mapVesselToVesselInput({vessel: params.vessel});
  const costInput: CostInput = {
    otherCosts: [],
    costIfoPerMts: params.assumptions.costIfoPerMts,
    costVlsfoPerMts: params.assumptions.costIfoPerMts,
    costMdoPerMts: params.assumptions.costMdoPerMts,
    costMgoPerMts: params.assumptions.costMdoPerMts,
  };
  const voyageCalculationInput: VoyageCalculationInput = {
    cargo: cargoInput,
    vessel: vesselInput,
    voyage: voyageInput,
    cost: costInput,
    calculator: {
      isFavorite: false,
      distanceCalculator: blankState.inputState.calculator.distanceCalculator,
    },
  };
  let voyageCalculationOutput = voyageChartercalculation(voyageCalculationInput);

  const routes = voyageCalculationOutput.voyage.routes.map(routeItem => {
    const route: CalcRoutesPropsRoute = {
      coordinates: {
        start: {lat: routeItem.endPoints[0][1], lon: routeItem.endPoints[0][0]},
        end: {lat: routeItem.endPoints[1][1], lon: routeItem.endPoints[1][0]},
      },
    };
    return route;
  });

  const calcRoutesOutputs: WaypointsForRoute[] = await calcRoutes({
    routes,
    options: voyageCalculationInput.calculator.distanceCalculator,
  });

  calcRoutesOutputs.forEach(item => {
    voyageCalculationInput.voyage.routes[item.index] = fixesRouteConsumptionIfNeeded({
      route: voyageCalculationInput.voyage.routes[item.index],
      routeOutput: voyageCalculationOutput.voyage.routes[item.index],
      routeIndex: item.index,
      inputState: voyageCalculationInput,
      outputState: voyageCalculationOutput,
    });

    voyageCalculationInput.voyage.routes[item.index].waypoints = item.waypoints as Coordinates[];
    voyageCalculationInput.voyage.routes[item.index].distance = item.distance;
  });

  voyageCalculationOutput = voyageChartercalculation(voyageCalculationInput);

  return {
    cargo: params.cargo,
    cargoProject: params.cargoProject,
    vesselProject: params.vesselProject,
    vessel: params.vessel,
    voyageCalculationOutput: voyageCalculationOutput,
    voyageCalculationInput: voyageCalculationInput,
    id: params.id,
  };
};

type OneCalcWithInputStateParams = {
  voyageCalculationInput: VoyageCalculationInput;
  oneCalc: OneCalcResult;
};

/**
 * Performs a voyage calcuation for a existing inputState and returns
 * the result of type OneCalcResult.
 */
export const oneCalcRecalculate = (params: OneCalcWithInputStateParams): OneCalcResult => {
  const voyageCalculationOutput = voyageChartercalculation(params.voyageCalculationInput);

  return {
    ...params.oneCalc,
    voyageCalculationOutput,
    voyageCalculationInput: params.voyageCalculationInput,
  };
};
