import {components} from 'react-select';

const groupStyles = {
  background: 'var(--color-white)',
  color: '#333',
  fontSize: 13,
  marginTop: 15,
};

const AutoCompleteContent = (props: $TSFixMe) => {
  if (props.label === 'overviewPage') {
    return null;
  }
  return (
    <div style={groupStyles}>
      <components.Group {...props} />
    </div>
  );
};

export default AutoCompleteContent;
