import {AccountForm} from './AccountForm';
import {AccountFormValues} from './AccountFormValues';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import animateScrollTo from 'animated-scroll-to';
import {isPresent} from '../../../utils/Presence';
import {CurrentUser, ModifyAccount} from '../../../api/symfony/generated';
import {accountManagementApi} from '../../../api/symfony/symfonyApi';
import {invalidateCurrentUserQuery, useCurrentUserQuery} from '../../../queries/useCurrentUserQuery';
import {assert} from '../../../utils/assert';
import {ApiError} from '../../../api/utils/ApiError';
import {Helmet} from 'react-helmet-async';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';
import {showNotification} from '../../../utils/notification';

export const Account = () => {
  const currentUserQuery = useCurrentUserQuery();

  const queryClient = useQueryClient();

  // The mutation that saves the data to be edited
  const modifyAccountMutation = useMutation<void, ApiError, ModifyAccount>({
    mutationFn: (modifyAccount: ModifyAccount): Promise<void> => accountManagementApi.updateAccount({modifyAccount}),
    onError: () => {
      // Scroll to top in order to show the error message
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      animateScrollTo(0);
    },
    onSuccess: async () => {
      // Reload account data
      await invalidateCurrentUserQuery(queryClient);

      await showNotification('Your settings have been saved');

      // Scroll to top in order to show the success message
      await animateScrollTo(0);
    },
  });

  if (!currentUserQuery.isSuccess) {
    return <span style={{visibility: 'hidden'}}>Loading account data</span>;
  }

  const currentUser: CurrentUser | null = currentUserQuery.data;
  assert(currentUser, 'user must be logged in');

  const initialValues: AccountFormValues = {
    firstName: currentUser.firstName ?? '',
    lastName: currentUser.lastName ?? '',
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    email: currentUser.email,
    emailConfirmation: currentUser.email,
    timezone: currentUser.options?.timezone,
  };

  const onSubmit = (formValues: AccountFormValues) => {
    const data = getModifyAccountRequest({...formValues, timezone: currentUser.options?.timezone});
    modifyAccountMutation.mutate(data);
  };

  return (
    <>
      <Helmet title="Account - Account management" />
      <div>
        <SettingsCard>
          <SettingsCardHeader title={'Account'} />

          <SettingsCardBody>
            <AccountForm
              initialValues={initialValues}
              onSubmit={onSubmit}
              isSubmitting={modifyAccountMutation.isPending}
              submitError={modifyAccountMutation.error}
            />
          </SettingsCardBody>
        </SettingsCard>
      </div>
    </>
  );
};

const getModifyAccountRequest = (formValues: AccountFormValues): ModifyAccount => ({
  currentPassword: formValues.currentPassword,
  firstName: formValues.firstName,
  lastName: formValues.lastName,
  // newPassword is not null => Server will change the password.
  newPassword: isPresent(formValues.newPassword) ? formValues.newPassword : undefined,
  email: formValues.email,
  timezone: formValues.timezone!,
});
