/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface NegotiationCargo
 */
export interface NegotiationCargo {
  /**
   *
   * @type {number}
   * @memberof NegotiationCargo
   */
  id: number | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  commodityCategory: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  commodityType: string | null;
  /**
   *
   * @type {number}
   * @memberof NegotiationCargo
   */
  quantityMin: number | null;
  /**
   *
   * @type {number}
   * @memberof NegotiationCargo
   */
  quantityMax: number | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  quantityUnit: string | null;
  /**
   * Format: YYYY-MM-DD
   * @type {string}
   * @memberof NegotiationCargo
   */
  laycanFrom: string | null;
  /**
   * Format: YYYY-MM-DD
   * @type {string}
   * @memberof NegotiationCargo
   */
  laycanTo: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  loadingLabel: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  loadingCountryCode: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  dischargeLabel: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  contractType: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationCargo
   */
  dischargeCountryCode: string | null;
}

export function NegotiationCargoFromJSON(json: any): NegotiationCargo {
  return NegotiationCargoFromJSONTyped(json, false);
}

export function NegotiationCargoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationCargo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    commodityCategory: json['commodityCategory'],
    commodityType: json['commodityType'],
    quantityMin: json['quantityMin'],
    quantityMax: json['quantityMax'],
    quantityUnit: json['quantityUnit'],
    laycanFrom: json['laycanFrom'],
    laycanTo: json['laycanTo'],
    loadingLabel: json['loadingLabel'],
    loadingCountryCode: json['loadingCountryCode'],
    dischargeLabel: json['dischargeLabel'],
    contractType: json['contractType'],
    dischargeCountryCode: json['dischargeCountryCode'],
  };
}

export function NegotiationCargoToJSON(value?: NegotiationCargo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    commodityCategory: value.commodityCategory,
    commodityType: value.commodityType,
    quantityMin: value.quantityMin,
    quantityMax: value.quantityMax,
    quantityUnit: value.quantityUnit,
    laycanFrom: value.laycanFrom,
    laycanTo: value.laycanTo,
    loadingLabel: value.loadingLabel,
    loadingCountryCode: value.loadingCountryCode,
    dischargeLabel: value.dischargeLabel,
    contractType: value.contractType,
    dischargeCountryCode: value.dischargeCountryCode,
  };
}
