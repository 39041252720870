/* eslint-disable camelcase */
import ad from './svg/ad.svg';
import ae from './svg/ae.svg';
import af from './svg/af.svg';
import ag from './svg/ag.svg';
import ai from './svg/ai.svg';
import al from './svg/al.svg';
import am from './svg/am.svg';
import ao from './svg/ao.svg';
import aq from './svg/aq.svg';
import ar from './svg/ar.svg';
import as from './svg/as.svg';
import at from './svg/at.svg';
import au from './svg/au.svg';
import aw from './svg/aw.svg';
import ax from './svg/ax.svg';
import az from './svg/az.svg';
import ba from './svg/ba.svg';
import bb from './svg/bb.svg';
import bd from './svg/bd.svg';
import be from './svg/be.svg';
import bf from './svg/bf.svg';
import bg from './svg/bg.svg';
import bh from './svg/bh.svg';
import bi from './svg/bi.svg';
import bj from './svg/bj.svg';
import bl from './svg/bl.svg';
import bm from './svg/bm.svg';
import bn from './svg/bn.svg';
import bo from './svg/bo.svg';
import bq from './svg/bq.svg';
import br from './svg/br.svg';
import bs from './svg/bs.svg';
import bt from './svg/bt.svg';
import bv from './svg/bv.svg';
import bw from './svg/bw.svg';
import by from './svg/by.svg';
import bz from './svg/bz.svg';
import ca from './svg/ca.svg';
import cc from './svg/cc.svg';
import cd from './svg/cd.svg';
import cf from './svg/cf.svg';
import cg from './svg/cg.svg';
import ch from './svg/ch.svg';
import ci from './svg/ci.svg';
import ck from './svg/ck.svg';
import cl from './svg/cl.svg';
import cm from './svg/cm.svg';
import cn from './svg/cn.svg';
import co from './svg/co.svg';
import cr from './svg/cr.svg';
import cu from './svg/cu.svg';
import cv from './svg/cv.svg';
import cw from './svg/cw.svg';
import cx from './svg/cx.svg';
import cy from './svg/cy.svg';
import cz from './svg/cz.svg';
import da from './svg/da.svg';
import de from './svg/de.svg';
import dj from './svg/dj.svg';
import dk from './svg/dk.svg';
import dm from './svg/dm.svg';
import dom from './svg/do.svg';
import dz from './svg/dz.svg';
import ec from './svg/ec.svg';
import ee from './svg/ee.svg';
import eg from './svg/eg.svg';
import eh from './svg/eh.svg';
import er from './svg/er.svg';
import es from './svg/es.svg';
import es_ct from './svg/es-ct.svg';
import et from './svg/et.svg';
import eu from './svg/eu.svg';
import fa from './svg/fa.svg';
import fi from './svg/fi.svg';
import fj from './svg/fj.svg';
import fk from './svg/fk.svg';
import fm from './svg/fm.svg';
import fo from './svg/fo.svg';
import fr from './svg/fr.svg';
import ga from './svg/ga.svg';
import gb from './svg/gb.svg';
import gb_eng from './svg/gb-eng.svg';
import gb_nir from './svg/gb-nir.svg';
import gb_sct from './svg/gb-sct.svg';
import gb_wls from './svg/gb-wls.svg';
import gd from './svg/gd.svg';
import ge from './svg/ge.svg';
import gf from './svg/gf.svg';
import gg from './svg/gg.svg';
import gh from './svg/gh.svg';
import gi from './svg/gi.svg';
import gl from './svg/gl.svg';
import gm from './svg/gm.svg';
import gn from './svg/gn.svg';
import gp from './svg/gp.svg';
import gq from './svg/gq.svg';
import gr from './svg/gr.svg';
import gs from './svg/gs.svg';
import gt from './svg/gt.svg';
import gu from './svg/gu.svg';
import gw from './svg/gw.svg';
import gy from './svg/gy.svg';
import he from './svg/he.svg';
import hk from './svg/hk.svg';
import hm from './svg/hm.svg';
import hn from './svg/hn.svg';
import hr from './svg/hr.svg';
import ht from './svg/ht.svg';
import hu from './svg/hu.svg';
import id from './svg/id.svg';
import ie from './svg/ie.svg';
import il from './svg/il.svg';
import im from './svg/im.svg';
import india from './svg/in.svg';
import io from './svg/io.svg';
import iq from './svg/iq.svg';
import ir from './svg/ir.svg';
import is from './svg/is.svg';
import it from './svg/it.svg';
import je from './svg/je.svg';
import jm from './svg/jm.svg';
import jo from './svg/jo.svg';
import jp from './svg/jp.svg';
import ke from './svg/ke.svg';
import kg from './svg/kg.svg';
import kh from './svg/kh.svg';
import ki from './svg/ki.svg';
import km from './svg/km.svg';
import kn from './svg/kn.svg';
import kp from './svg/kp.svg';
import kr from './svg/kr.svg';
import kw from './svg/kw.svg';
import ky from './svg/ky.svg';
import kz from './svg/kz.svg';
import la from './svg/la.svg';
import lb from './svg/lb.svg';
import lc from './svg/lc.svg';
import li from './svg/li.svg';
import lk from './svg/lk.svg';
import lr from './svg/lr.svg';
import ls from './svg/ls.svg';
import lt from './svg/lt.svg';
import lu from './svg/lu.svg';
import lv from './svg/lv.svg';
import ly from './svg/ly.svg';
import ma from './svg/ma.svg';
import mc from './svg/mc.svg';
import md from './svg/md.svg';
import me from './svg/me.svg';
import mf from './svg/mf.svg';
import mg from './svg/mg.svg';
import mh from './svg/mh.svg';
import mk from './svg/mk.svg';
import ml from './svg/ml.svg';
import mm from './svg/mm.svg';
import mn from './svg/mn.svg';
import mo from './svg/mo.svg';
import mp from './svg/mp.svg';
import mq from './svg/mq.svg';
import mr from './svg/mr.svg';
import ms from './svg/ms.svg';
import mt from './svg/mt.svg';
import mu from './svg/mu.svg';
import mv from './svg/mv.svg';
import mw from './svg/mw.svg';
import mx from './svg/mx.svg';
import my from './svg/my.svg';
import mz from './svg/mz.svg';
import na from './svg/na.svg';
import nc from './svg/nc.svg';
import ne from './svg/ne.svg';
import nf from './svg/nf.svg';
import ng from './svg/ng.svg';
import ni from './svg/ni.svg';
import nl from './svg/nl.svg';
import no from './svg/no.svg';
import np from './svg/np.svg';
import nr from './svg/nr.svg';
import nu from './svg/nu.svg';
import nz from './svg/nz.svg';
import om from './svg/om.svg';
import pa from './svg/pa.svg';
import pe from './svg/pe.svg';
import pf from './svg/pf.svg';
import pg from './svg/pg.svg';
import ph from './svg/ph.svg';
import pk from './svg/pk.svg';
import pl from './svg/pl.svg';
import pm from './svg/pm.svg';
import pn from './svg/pn.svg';
import pr from './svg/pr.svg';
import ps from './svg/ps.svg';
import pt from './svg/pt.svg';
import pw from './svg/pw.svg';
import py from './svg/py.svg';
import qa from './svg/qa.svg';
import re from './svg/re.svg';
import ro from './svg/ro.svg';
import rs from './svg/rs.svg';
import ru from './svg/ru.svg';
import rw from './svg/rw.svg';
import sa from './svg/sa.svg';
import sb from './svg/sb.svg';
import sc from './svg/sc.svg';
import sd from './svg/sd.svg';
import se from './svg/se.svg';
import sg from './svg/sg.svg';
import sh from './svg/sh.svg';
import si from './svg/si.svg';
import sj from './svg/sj.svg';
import sk from './svg/sk.svg';
import sl from './svg/sl.svg';
import sm from './svg/sm.svg';
import sn from './svg/sn.svg';
import so from './svg/so.svg';
import sr from './svg/sr.svg';
import ss from './svg/ss.svg';
import st from './svg/st.svg';
import sv from './svg/sv.svg';
import sx from './svg/sx.svg';
import sy from './svg/sy.svg';
import sz from './svg/sz.svg';
import tc from './svg/tc.svg';
import td from './svg/td.svg';
import tf from './svg/tf.svg';
import tg from './svg/tg.svg';
import th from './svg/th.svg';
import tj from './svg/tj.svg';
import tk from './svg/tk.svg';
import tl from './svg/tl.svg';
import tm from './svg/tm.svg';
import tn from './svg/tn.svg';
import to from './svg/to.svg';
import tr from './svg/tr.svg';
import tt from './svg/tt.svg';
import tv from './svg/tv.svg';
import tw from './svg/tw.svg';
import tz from './svg/tz.svg';
import ua from './svg/ua.svg';
import ug from './svg/ug.svg';
import um from './svg/um.svg';
import un from './svg/un.svg';
import us from './svg/us.svg';
import uy from './svg/uy.svg';
import uz from './svg/uz.svg';
import va from './svg/va.svg';
import vc from './svg/vc.svg';
import ve from './svg/ve.svg';
import vg from './svg/vg.svg';
import vi from './svg/vi.svg';
import vn from './svg/vn.svg';
import vu from './svg/vu.svg';
import wf from './svg/wf.svg';
import ws from './svg/ws.svg';
import ye from './svg/ye.svg';
import yt from './svg/yt.svg';
import za from './svg/za.svg';
import zm from './svg/zm.svg';
import zw from './svg/zw.svg';

export const countryCodeToFlag: Record<string, string> = {
  ad: ad,
  ae: ae,
  af: af,
  ag: ag,
  ai: ai,
  al: al,
  am: am,
  ao: ao,
  aq: aq,
  ar: ar,
  as: as,
  at: at,
  au: au,
  aw: aw,
  ax: ax,
  az: az,
  ba: ba,
  bb: bb,
  bd: bd,
  be: be,
  bf: bf,
  bg: bg,
  bh: bh,
  bi: bi,
  bj: bj,
  bl: bl,
  bm: bm,
  bn: bn,
  bo: bo,
  bq: bq,
  br: br,
  bs: bs,
  bt: bt,
  bv: bv,
  bw: bw,
  by: by,
  bz: bz,
  ca: ca,
  cc: cc,
  cd: cd,
  cf: cf,
  cg: cg,
  ch: ch,
  ci: ci,
  ck: ck,
  cl: cl,
  cm: cm,
  cn: cn,
  co: co,
  cr: cr,
  cu: cu,
  cv: cv,
  cw: cw,
  cx: cx,
  cy: cy,
  cz: cz,
  da: da,
  de: de,
  dj: dj,
  dk: dk,
  dm: dm,
  do: dom,
  dz: dz,
  ec: ec,
  ee: ee,
  eg: eg,
  eh: eh,
  er: er,
  es: es,
  'es-ct': es_ct,
  et: et,
  eu: eu,
  fa: fa,
  fi: fi,
  fj: fj,
  fk: fk,
  fm: fm,
  fo: fo,
  fr: fr,
  ga: ga,
  gb: gb,
  'gb-eng': gb_eng,
  'gb-nir': gb_nir,
  'gb-sct': gb_sct,
  'gb-wls': gb_wls,
  gd: gd,
  ge: ge,
  gf: gf,
  gg: gg,
  gh: gh,
  gi: gi,
  gl: gl,
  gm: gm,
  gn: gn,
  gp: gp,
  gq: gq,
  gr: gr,
  gs: gs,
  gt: gt,
  gu: gu,
  gw: gw,
  gy: gy,
  he: he,
  hk: hk,
  hm: hm,
  hn: hn,
  hr: hr,
  ht: ht,
  hu: hu,
  id: id,
  ie: ie,
  il: il,
  im: im,
  in: india,
  io: io,
  iq: iq,
  ir: ir,
  is: is,
  it: it,
  je: je,
  jm: jm,
  jo: jo,
  jp: jp,
  ke: ke,
  kg: kg,
  kh: kh,
  ki: ki,
  km: km,
  kn: kn,
  kp: kp,
  kr: kr,
  kw: kw,
  ky: ky,
  kz: kz,
  la: la,
  lb: lb,
  lc: lc,
  li: li,
  lk: lk,
  lr: lr,
  ls: ls,
  lt: lt,
  lu: lu,
  lv: lv,
  ly: ly,
  ma: ma,
  mc: mc,
  md: md,
  me: me,
  mf: mf,
  mg: mg,
  mh: mh,
  mk: mk,
  ml: ml,
  mm: mm,
  mn: mn,
  mo: mo,
  mp: mp,
  mq: mq,
  mr: mr,
  ms: ms,
  mt: mt,
  mu: mu,
  mv: mv,
  mw: mw,
  mx: mx,
  my: my,
  mz: mz,
  na: na,
  nc: nc,
  ne: ne,
  nf: nf,
  ng: ng,
  ni: ni,
  nl: nl,
  no: no,
  np: np,
  nr: nr,
  nu: nu,
  nz: nz,
  om: om,
  pa: pa,
  pe: pe,
  pf: pf,
  pg: pg,
  ph: ph,
  pk: pk,
  pl: pl,
  pm: pm,
  pn: pn,
  pr: pr,
  ps: ps,
  pt: pt,
  pw: pw,
  py: py,
  qa: qa,
  re: re,
  ro: ro,
  rs: rs,
  ru: ru,
  rw: rw,
  sa: sa,
  sb: sb,
  sc: sc,
  sd: sd,
  se: se,
  sg: sg,
  sh: sh,
  si: si,
  sj: sj,
  sk: sk,
  sl: sl,
  sm: sm,
  sn: sn,
  so: so,
  sr: sr,
  ss: ss,
  st: st,
  sv: sv,
  sx: sx,
  sy: sy,
  sz: sz,
  tc: tc,
  td: td,
  tf: tf,
  tg: tg,
  th: th,
  tj: tj,
  tk: tk,
  tl: tl,
  tm: tm,
  tn: tn,
  to: to,
  tr: tr,
  tt: tt,
  tv: tv,
  tw: tw,
  tz: tz,
  ua: ua,
  ug: ug,
  um: um,
  un: un,
  us: us,
  uy: uy,
  uz: uz,
  va: va,
  vc: vc,
  ve: ve,
  vg: vg,
  vi: vi,
  vn: vn,
  vu: vu,
  wf: wf,
  ws: ws,
  ye: ye,
  yt: yt,
  za: za,
  zm: zm,
  zw: zw,
};
