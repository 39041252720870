import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {authenticationApi} from '../../api/symfony/symfonyApi';
import {AccessToken, GenerateAccessTokenGrantTypeEnum} from '../../api/symfony/generated';
import {oAuthClientId} from './oAuthClientId';
import {scope} from './scope';
import {ApiError} from '../../api/utils/ApiError';

type MutationParams = {
  authorizationCode: string;
  sourceHref: string;
};

export const useGenerateRefreshTokenMutation = (
  options?: UseMutationOptions<AccessToken['refreshToken'], ApiError, MutationParams>
) =>
  useMutation({
    mutationFn: generateRefreshToken,
    ...options,
  });

const generateRefreshToken = async ({
  authorizationCode,
  sourceHref,
}: MutationParams): Promise<AccessToken['refreshToken']> => {
  const {refreshToken} = await authenticationApi.generateAccessToken({
    grantType: GenerateAccessTokenGrantTypeEnum.AuthorizationCode,
    code: authorizationCode,
    clientId: oAuthClientId,
    scope,
    redirectUri: sourceHref,
  });
  return refreshToken;
};
