import dayjs from 'dayjs';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {fetchJSON} from '../../api/utils/fetchJSON';
import {TODO} from '../../utils/TODO';
import {VesselFromSpire} from './VesselFromSpire';

export const useGetNewVesselsFromSpireQuery = (options?: UseQueryOptions<VesselFromSpire[]>) =>
  useQuery({
    queryKey: ['getNewVesselsFromSpire'],
    queryFn: async () => {
      const fromString = dayjs().subtract(31, 'day').toISOString();
      const json = await fetchJSON<TODO>('https://srv20110.dus4.fastwebserver.de/spireserviceEvents', {
        method: 'POST',
        body: JSON.stringify({
          eventTypeList: ['NEW_ORDER'],
          extended: true,
          from: fromString,
          to: dayjs().toISOString(),
        }),
      });

      if (json.errorCode === -2) {
        return undefined;
      }

      return json.spireVesselEventList.map(({newVessel}: {newVessel: VesselFromSpire}) => newVessel).reverse();
    },
    ...options,
  });
