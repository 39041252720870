import {FC, ReactNode, useState} from 'react';
import {NegotiationReplyTime} from '../../../../../../api/node-backend/generated';
import {NegotiationReplyTimeEditor} from './NegotiationReplyTimeEditor';
import {Popover} from 'antd';

type NegotiationReplyTimeEditorPopoverProps = {
  replyTime?: NegotiationReplyTime | null;
  disabled?: boolean;
  onReplyTimeChange?: (replyTime: NegotiationReplyTime | null) => void;
  children: ReactNode;
};

export const NegotiationReplyTimeEditorPopover: FC<NegotiationReplyTimeEditorPopoverProps> = ({
  replyTime,
  disabled,
  onReplyTimeChange,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleReplyTimeChange = (replyTime: NegotiationReplyTime | null) => {
    if (disabled) {
      return;
    }
    setOpen(false);
    onReplyTimeChange?.(replyTime);
  };

  return (
    <Popover
      open={open}
      trigger="click"
      onOpenChange={newOpen => (disabled ? null : setOpen(newOpen))}
      content={<NegotiationReplyTimeEditor replyTime={replyTime} onReplyTimeChange={handleReplyTimeChange} />}>
      {children}
    </Popover>
  );
};
