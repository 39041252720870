import {FC} from 'react';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {VesselDatabaseActions} from '../../redux/VesselDatabase';
import {SaveFilterButton} from './SaveFilterButton';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {VesselDatabaseFilterBranchDefinitions} from '../../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';

export const FilterControls: FC<{
  filterProviderApi: FilterProviderApi<typeof VesselDatabaseFilterBranchDefinitions>;
}> = ({filterProviderApi}) => {
  const {filterSettings, isFilterUiOpen, activeFilterId} = useSelector(state => state.vesselDatabase);
  const filterObject = useSelector(state => state.vesselDatabase.filters.find(filter => filter.id === activeFilterId));
  const activeTabId: number | 'all' = activeFilterId;

  const dispatch = useDispatch();
  return (
    <>
      {filterProviderApi.isAnyFilterApplied && (
        <SaveFilterButton
          disabled={filterProviderApi.disableSaveButton}
          filterProviderApi={filterProviderApi}
          currentFilterObject={filterObject}
          currentFilterSettings={filterSettings}
          activeTabId={activeTabId}
        />
      )}
      {filterProviderApi.isAnyFilterApplied && (
        <ScreenHeader.Features.Button onClick={filterProviderApi.onResetAllClick}>
          Reset filters
        </ScreenHeader.Features.Button>
      )}
      <ScreenHeader.Features.Toggle
        value={isFilterUiOpen}
        onChange={event => dispatch(VesselDatabaseActions.setFilterUiOpen(event.target.value))}
        options={[
          {
            id: 'show-filters',
            label: `Filters${filterProviderApi.appliedFilterCount ? ` (${filterProviderApi.appliedFilterCount})` : ''}`,
            value: true,
            props: {
              'data-cy': 'VesselDBShowFiltersBTN',
            },
          },
          {
            id: 'show-map',
            label: 'Map',
            value: false,
            props: {
              'data-cy': 'VesselDBShowMapBTN',
            },
          },
        ]}
      />
    </>
  );
};
