import {useState} from 'react';
import {AsyncSelect} from '../../atoms/Select/AsyncSelect';
import AutoCompleteOption from '../SelectOption/AutocompleteOption';
import {SearchIndicator} from '../../atoms/Select/Indicators';
import AutoCompleteHeader from './AutoCompleteHeader';
import AutoCompleteContent from './AutoCompleteContent';
import AutoCompleteMenuList from './AutoCompleteMenuList';
import {prepareData} from './prepareResult';
import {actions as autocompleteActions} from '../../redux/ApiService/autocomplete';
import {
  autoCompleteControl,
  autoCompleteMenu,
  autoCompleteMenuList,
  indicatorsContainer,
  option,
  singleValue,
  valueContainer,
} from '../../atoms/Select/styles';
import './style.scss';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {useNavigate} from 'react-router-dom';
import {fetchGetAppApiMapGetvesselaisdetails} from '../../queries/useGetAppApiMapGetvesselaisdetailsQuery';

export const SearchBar = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch();

  const loading = useSelector(state => state.api.autoComplete.search.loading);

  const search = (query: string) =>
    dispatch(
      autocompleteActions.search({
        queryParams: {
          query,
          indexNames: [
            'vessels_market',
            'vessels_portfolio',
            'cargoes_market',
            'cargoes_portfolio',
            'projects',
            'ais_vessels',
            'ports',
            'contacts',
            'users',
          ],
          pageSize: 6,
        },
      })
    );

  const navigate = useNavigate();

  const loadOptions = (query: string) => {
    if (query.length < 2) {
      return Promise.resolve([]);
    }
    return search(query).then(prepareData);
  };

  return (
    <div id="react-search" data-testid="SearchBar">
      <div className="search-bar">
        <AsyncSelect
          value={null}
          isLoading={loading}
          loadOptions={loadOptions}
          menuIsOpen={menuIsOpen}
          onBlur={() => {
            setMenuIsOpen(false);
          }}
          components={{
            DropdownIndicator: SearchIndicator,
            // @ts-expect-error I don't know what the problem is
            Option: AutoCompleteOption,
            Group: AutoCompleteContent,
            GroupHeading: AutoCompleteHeader,
            MenuList: AutoCompleteMenuList,
          }}
          onInputChange={(q: string) => {
            setInputValue(q);
            setMenuIsOpen(q.length > 1);
          }}
          onChange={(data: $TSFixMe, {action}: $TSFixMe) => {
            if (action === 'select-option') {
              setMenuIsOpen(false);
              if (data.platformLink && data.platformLink !== '/search/') {
                navigate(data.platformLink);
                return;
              } else if (data.overViewPageLink && inputValue) {
                const link = `/search/${inputValue}`;
                navigate(link);
                return;
              }

              fetchGetAppApiMapGetvesselaisdetails({imo: data.imo})
                .then(res => {
                  const link = `/vessel/${res.vesselId}`;
                  navigate(link);
                })
                .catch(() => {});
            }
          }}
          placeholder="Search in market, cargoes, vessels, projects…"
          styles={{
            option,
            control: autoCompleteControl,
            menu: autoCompleteMenu,
            menuList: autoCompleteMenuList,
            singleValue,
            valueContainer,
            indicatorsContainer,
          }}
        />
      </div>
    </div>
  );
};
