import cloneDeep from 'lodash/cloneDeep';
import {basicOptions, cargoOption, MapOption, vesselOption} from './mapOptions';

export const getInitialOptions = (): MapOption[] => {
  const basic = cloneDeep(basicOptions);
  const vessel = cloneDeep(vesselOption);
  const cargo = cloneDeep(cargoOption);

  return [vessel, cargo, ...basic];
};
