/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CargoOfferUpdateRequestCargo
 */
export interface CargoOfferUpdateRequestCargo {
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  chartererName?: string;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  quantityMin?: number;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  quantityMax?: number;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  quantityUnit?: string;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  vesselSizeMin?: number;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  vesselSizeMax?: number;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  vesselSizeUnit?: string;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  durationMin?: number;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  durationMax?: number;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  durationUnit?: string;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  durationAbout?: number;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  laycanFrom?: string;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  laycanTo?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CargoOfferUpdateRequestCargo
   */
  load?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CargoOfferUpdateRequestCargo
   */
  discharge?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CargoOfferUpdateRequestCargo
   */
  delivery?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CargoOfferUpdateRequestCargo
   */
  redelivery?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  commissionAddress?: number;
  /**
   *
   * @type {number}
   * @memberof CargoOfferUpdateRequestCargo
   */
  commissionBrokerage?: number;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  commodity?: string;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  commodityType?: string;
  /**
   *
   * @type {string}
   * @memberof CargoOfferUpdateRequestCargo
   */
  commodityCategory?: string;
}

export function CargoOfferUpdateRequestCargoFromJSON(json: any): CargoOfferUpdateRequestCargo {
  return CargoOfferUpdateRequestCargoFromJSONTyped(json, false);
}

export function CargoOfferUpdateRequestCargoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CargoOfferUpdateRequestCargo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    chartererName: !exists(json, 'chartererName') ? undefined : json['chartererName'],
    quantityMin: !exists(json, 'quantityMin') ? undefined : json['quantityMin'],
    quantityMax: !exists(json, 'quantityMax') ? undefined : json['quantityMax'],
    quantityUnit: !exists(json, 'quantityUnit') ? undefined : json['quantityUnit'],
    vesselSizeMin: !exists(json, 'vesselSizeMin') ? undefined : json['vesselSizeMin'],
    vesselSizeMax: !exists(json, 'vesselSizeMax') ? undefined : json['vesselSizeMax'],
    vesselSizeUnit: !exists(json, 'vesselSizeUnit') ? undefined : json['vesselSizeUnit'],
    durationMin: !exists(json, 'durationMin') ? undefined : json['durationMin'],
    durationMax: !exists(json, 'durationMax') ? undefined : json['durationMax'],
    durationUnit: !exists(json, 'durationUnit') ? undefined : json['durationUnit'],
    durationAbout: !exists(json, 'durationAbout') ? undefined : json['durationAbout'],
    laycanFrom: !exists(json, 'laycanFrom') ? undefined : json['laycanFrom'],
    laycanTo: !exists(json, 'laycanTo') ? undefined : json['laycanTo'],
    load: !exists(json, 'load') ? undefined : json['load'],
    discharge: !exists(json, 'discharge') ? undefined : json['discharge'],
    delivery: !exists(json, 'delivery') ? undefined : json['delivery'],
    redelivery: !exists(json, 'redelivery') ? undefined : json['redelivery'],
    commissionAddress: !exists(json, 'commissionAddress') ? undefined : json['commissionAddress'],
    commissionBrokerage: !exists(json, 'commissionBrokerage') ? undefined : json['commissionBrokerage'],
    commodity: !exists(json, 'commodity') ? undefined : json['commodity'],
    commodityType: !exists(json, 'commodityType') ? undefined : json['commodityType'],
    commodityCategory: !exists(json, 'commodityCategory') ? undefined : json['commodityCategory'],
  };
}

export function CargoOfferUpdateRequestCargoToJSON(value?: CargoOfferUpdateRequestCargo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    chartererName: value.chartererName,
    quantityMin: value.quantityMin,
    quantityMax: value.quantityMax,
    quantityUnit: value.quantityUnit,
    vesselSizeMin: value.vesselSizeMin,
    vesselSizeMax: value.vesselSizeMax,
    vesselSizeUnit: value.vesselSizeUnit,
    durationMin: value.durationMin,
    durationMax: value.durationMax,
    durationUnit: value.durationUnit,
    durationAbout: value.durationAbout,
    laycanFrom: value.laycanFrom,
    laycanTo: value.laycanTo,
    load: value.load,
    discharge: value.discharge,
    delivery: value.delivery,
    redelivery: value.redelivery,
    commissionAddress: value.commissionAddress,
    commissionBrokerage: value.commissionBrokerage,
    commodity: value.commodity,
    commodityType: value.commodityType,
    commodityCategory: value.commodityCategory,
  };
}
