/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  DistanceCalculation,
  DistanceCalculationFromJSON,
  DistanceCalculationToJSON,
  DistanceCalculationAllowedAreas,
  DistanceCalculationAllowedAreasFromJSON,
  DistanceCalculationAllowedAreasToJSON,
  GeolocationCoordinates,
  GeolocationCoordinatesFromJSON,
  GeolocationCoordinatesToJSON,
} from '../models';

export interface GetGeocodingRequest {
  port: string;
}

export interface GetRouteFromToRequest {
  startLatitude: number;
  startLongitude: number;
  endLatitude: number;
  endLongitude: number;
  maxSpeed?: number;
  allowedAreas?: DistanceCalculationAllowedAreas;
  avoidPiracyArea?: number;
  avoidSeca?: number;
}

/**
 *
 */
export class RoutesApi extends runtime.BaseAPI {
  /**
   * Get geolocation coordinates.
   */
  async getGeocodingRaw(requestParameters: GetGeocodingRequest): Promise<runtime.ApiResponse<GeolocationCoordinates>> {
    if (requestParameters.port === null || requestParameters.port === undefined) {
      throw new runtime.RequiredError(
        'port',
        'Required parameter requestParameters.port was null or undefined when calling getGeocoding.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.port !== undefined) {
      queryParameters['port'] = requestParameters.port;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/routes/geocoding`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => GeolocationCoordinatesFromJSON(jsonValue));
  }

  /**
   * Get geolocation coordinates.
   */
  async getGeocoding(requestParameters: GetGeocodingRequest): Promise<GeolocationCoordinates> {
    const response = await this.getGeocodingRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Route path.
   */
  async getRouteFromToRaw(requestParameters: GetRouteFromToRequest): Promise<runtime.ApiResponse<DistanceCalculation>> {
    if (requestParameters.startLatitude === null || requestParameters.startLatitude === undefined) {
      throw new runtime.RequiredError(
        'startLatitude',
        'Required parameter requestParameters.startLatitude was null or undefined when calling getRouteFromTo.'
      );
    }

    if (requestParameters.startLongitude === null || requestParameters.startLongitude === undefined) {
      throw new runtime.RequiredError(
        'startLongitude',
        'Required parameter requestParameters.startLongitude was null or undefined when calling getRouteFromTo.'
      );
    }

    if (requestParameters.endLatitude === null || requestParameters.endLatitude === undefined) {
      throw new runtime.RequiredError(
        'endLatitude',
        'Required parameter requestParameters.endLatitude was null or undefined when calling getRouteFromTo.'
      );
    }

    if (requestParameters.endLongitude === null || requestParameters.endLongitude === undefined) {
      throw new runtime.RequiredError(
        'endLongitude',
        'Required parameter requestParameters.endLongitude was null or undefined when calling getRouteFromTo.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.startLatitude !== undefined) {
      queryParameters['startLatitude'] = requestParameters.startLatitude;
    }

    if (requestParameters.startLongitude !== undefined) {
      queryParameters['startLongitude'] = requestParameters.startLongitude;
    }

    if (requestParameters.endLatitude !== undefined) {
      queryParameters['endLatitude'] = requestParameters.endLatitude;
    }

    if (requestParameters.endLongitude !== undefined) {
      queryParameters['endLongitude'] = requestParameters.endLongitude;
    }

    if (requestParameters.maxSpeed !== undefined) {
      queryParameters['maxSpeed'] = requestParameters.maxSpeed;
    }

    if (requestParameters.allowedAreas !== undefined) {
      queryParameters['allowedAreas'] = requestParameters.allowedAreas;
    }

    if (requestParameters.avoidPiracyArea !== undefined) {
      queryParameters['avoidPiracyArea'] = requestParameters.avoidPiracyArea;
    }

    if (requestParameters.avoidSeca !== undefined) {
      queryParameters['avoidSeca'] = requestParameters.avoidSeca;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/routes/fromto`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => DistanceCalculationFromJSON(jsonValue));
  }

  /**
   * Get Route path.
   */
  async getRouteFromTo(requestParameters: GetRouteFromToRequest): Promise<DistanceCalculation> {
    const response = await this.getRouteFromToRaw(requestParameters);
    return await response.value();
  }
}
