import {VoyageInput} from '../VoyageTypes';

type Params = {
  voyage: VoyageInput;
  newIndex: number;
  oldIndex: number;
};

export const doLegMovement = (params: Params): VoyageInput => {
  const indexToPortIndex = (index: number) => index / 2;

  if (params.oldIndex === params.newIndex) {
    return params.voyage;
  }

  const points = [...params.voyage.points];
  const routes = params.voyage.routes;

  const portOnOldPos = {...points[indexToPortIndex(params.oldIndex)]};
  const portOnNewPos = {...points[indexToPortIndex(params.newIndex)]};

  points[indexToPortIndex(params.newIndex)] = portOnOldPos;
  points[indexToPortIndex(params.oldIndex)] = portOnNewPos;

  return {
    ...params.voyage,
    routesCalculated: false,
    points,
    routes,
  };
};
