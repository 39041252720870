import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const SidebarStyled = styled.aside`
  /* Make sure we have some whitespace when FormArea is displayed below Sidebar: */
  padding-bottom: 32px;
`;

export const SidebarMenu: FC<{children: React.ReactNode}> = ({children}) => (
  <SidebarMenuStyled>{children}</SidebarMenuStyled>
);

export const SidebarMenuItem: FC<{to: string; children: React.ReactNode; 'data-testid'?: string}> = ({
  to,
  children,
  'data-testid': dataTestId,
}) => (
  <StyledSidebarMenuItem>
    <StyledLink to={to} className={({isActive}) => (isActive ? 'active' : '')} data-testid={dataTestId ?? to}>
      {children}
    </StyledLink>
  </StyledSidebarMenuItem>
);

const SidebarMenuStyled = styled.ul`
  padding-left: 0;
`;

const StyledSidebarMenuItem = styled.li`
  list-style: none;
`;

const StyledLink = styled(NavLink)`
  color: var(--color-gray-2);
  line-height: 24px;

  &:hover {
    cursor: pointer;
  }

  &.active {
    margin-left: -7px;
    padding-left: 5px;
    color: var(--color-azure);
    border-left: 2px solid var(--color-azure);
    font-weight: bold;
  }
`;
