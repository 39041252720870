import {MutationOptions, useMutation} from '@tanstack/react-query';
import {useDispatch} from '../../../../redux/react-redux';
import CompanyActions from '../../../../redux/ApiService/CompanyService/CompanyService';
import {User} from './User';
import {ApiError} from '../../../../api/utils/ApiError';

interface AddMembersProps {
  companyId: number;
  users: User[];
}

export const useAddMembersMutation = (options: MutationOptions<void, ApiError, AddMembersProps>) => {
  const dispatch = useDispatch();
  const addMembers = (companyId: number, users: User[]) => dispatch(CompanyActions.addMembers(companyId, users));
  return useMutation<void, ApiError, AddMembersProps>({
    mutationFn: async ({companyId, users}) => {
      const result = await addMembers(companyId, users);

      const hasError = result.data.items.some((user: {status: string}) => user.status === 'error');

      if (hasError) {
        throw new ApiError(result.data, 409);
      }

      return result;
    },
    ...options,
  });
};
