import {AisVessel} from '../../api/symfony/generated/models';

type DraftItem = {imo: number; minDraft: number; maxDraft: number; draft25: number; draft50: number; draft75: number};

interface AisVesselWithDraft {
  d?: number;
}

export const getIsBallast = ({
  vessel,
  draftItem,
}: {
  vessel: AisVessel | AisVesselWithDraft;
  draftItem?: DraftItem;
}): boolean | undefined => {
  if (!draftItem) {
    return undefined;
  }
  if (!vessel.d) {
    return undefined;
  }
  return vessel.d / 10 < draftItem.draft50;
};
