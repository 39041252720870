import styled from 'styled-components';
import Icon from '../../../../atoms/Icon';

export const SharingIcon = styled(Icon)<{$activeTab: boolean; $isActive: boolean}>`
  font-size: 18px !important;
  vertical-align: middle;
  opacity: 1;
  ${({$activeTab}) => ($activeTab ? 'color: var(--color-white) !important;' : '')}
  ${({$isActive}) => ($isActive ? 'color: var(--color-orange) !important;' : '')}

  &:hover {
    opacity: 0.8;
  }
`;
