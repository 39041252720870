import {useContext, useEffect} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';

export const usePortWorkingConsumptionFromVessel = () => {
  const charterContext = useContext(VoyageCharterContext);

  const {inputState} = charterContext.state;

  useEffect(() => {
    const vesselConsumptionPortWorkingIndex = inputState.vessel.consumptionModes.findIndex(
      item => item.useCase === 'portworking'
    );
    if (vesselConsumptionPortWorkingIndex === -1) {
      return;
    }

    let wasChange = false;

    const points = inputState.voyage.points.map(point => {
      if (point.vesselModeIndex === undefined && point.vesselModeIndex !== vesselConsumptionPortWorkingIndex) {
        wasChange = true;
        return {...point, vesselModeIndex: vesselConsumptionPortWorkingIndex};
      }
      return point;
    });

    if (wasChange) {
      charterContext.setInputState({...inputState, voyage: {...inputState.voyage, points}});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState.vessel.consumptionModes, inputState.voyage.points]);
};
