/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  TradingAreaTiedUpVesselsStatistic,
  TradingAreaTiedUpVesselsStatisticFromJSON,
  TradingAreaTiedUpVesselsStatisticFromJSONTyped,
  TradingAreaTiedUpVesselsStatisticToJSON,
} from './';

/**
 *
 * @export
 * @interface TiedUpVesselsStatisticResponse
 */
export interface TiedUpVesselsStatisticResponse {
  /**
   *
   * @type {Array<TradingAreaTiedUpVesselsStatistic>}
   * @memberof TiedUpVesselsStatisticResponse
   */
  tradingAreas: Array<TradingAreaTiedUpVesselsStatistic>;
}

export function TiedUpVesselsStatisticResponseFromJSON(json: any): TiedUpVesselsStatisticResponse {
  return TiedUpVesselsStatisticResponseFromJSONTyped(json, false);
}

export function TiedUpVesselsStatisticResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TiedUpVesselsStatisticResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tradingAreas: (json['tradingAreas'] as Array<any>).map(TradingAreaTiedUpVesselsStatisticFromJSON),
  };
}

export function TiedUpVesselsStatisticResponseToJSON(value?: TiedUpVesselsStatisticResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tradingAreas: (value.tradingAreas as Array<any>).map(TradingAreaTiedUpVesselsStatisticToJSON),
  };
}
