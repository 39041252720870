import {SWITCHES} from '../mapOptions/switches';
import {TODO} from '../../../utils/TODO';
import {AisVessel} from '../../../api/symfony/generated';

export const filterAisVessels = ({
  aisVessels,
  switches,
  searchElement,
}: {
  aisVessels: AisVessel[];
  switches: TODO;
  searchElement: TODO;
}) => {
  let vessels: AisVessel[];

  if (switches) {
    if (switches[SWITCHES.VESSEL_AIS]?.state) {
      vessels = aisVessels.filter((d: TODO) => switches[SWITCHES.VESSEL_AIS_SUB][d.type]);

      if (switches[SWITCHES.VESSEL_AIS].filters) {
        const {
          intakeUnit,
          intake: [intakeMin, intakeMax],
          loa: [loaMin, loaMax],
          beam: [beamMin, beamMax],
          speed: [speedMin, speedMax],
          draftUnit,
          draft: [draftMin, draftMax],
        } = switches[SWITCHES.VESSEL_AIS].filters;

        if (intakeMin !== '' || intakeMax !== '') {
          vessels = vessels.filter(
            (v: TODO) =>
              v[intakeUnit] !== null &&
              (intakeMin === '' || v[intakeUnit] >= intakeMin) &&
              (intakeMax === '' || v[intakeUnit] <= intakeMax)
          );
        }

        if (loaMin !== '' || loaMax !== '') {
          vessels = vessels.filter(
            (v: TODO) => v.loa !== null && (loaMin === '' || v.loa >= loaMin) && (loaMax === '' || v.loa <= loaMax)
          );
        }

        if (beamMin !== '' || beamMax !== '') {
          vessels = vessels.filter(
            (v: TODO) =>
              v.beam !== null && (beamMin === '' || v.beam >= beamMin) && (beamMax === '' || v.beam <= beamMax)
          );
        }
        if (speedMin !== '' || speedMax !== '') {
          vessels = vessels.filter(
            (v: TODO) =>
              v.speed !== null && (speedMin === '' || v.speed >= speedMin) && (speedMax === '' || v.speed <= speedMax)
          );
        }

        if (draftMin !== '' || draftMax !== '') {
          if (draftUnit === 'meter') {
            vessels = vessels.filter(
              (v: TODO) =>
                v.d !== null && (draftMin === '' || v.d >= draftMin * 10) && (draftMax === '' || v.d <= draftMax * 10)
            );
          } else if (draftUnit === 'percent') {
            vessels = vessels.filter((v: TODO) => {
              if (v.d !== null && v.dm !== null && v.dm > 0) {
                const dPercent = (v.d / v.dm) * 100;
                return (draftMin === '' || dPercent >= draftMin) && (draftMax === '' || dPercent <= draftMax);
              }
              return false;
            });
          }
        }
      }
    } else {
      vessels = [];
    }
  } else {
    vessels = aisVessels;
  }

  if (searchElementIsAisVessel(searchElement)) {
    const searchedVessel = transformSearchElementToAisVessel(searchElement);

    return [
      ...vessels.filter((vessel: TODO) => vessel.imo !== searchElement.imo),
      {
        mmsi: searchedVessel.mmsi,
        imo: searchedVessel.imo,
        name: searchedVessel.name,
        type: searchedVessel.class,
        course: searchedVessel.course,
        coordinates: [searchedVessel.longitude, searchedVessel.latitude],
      },
    ];
  }

  return vessels;
};

// Its a bit of a mess, but i don't know if we have old dependencies.
// The checks try to reduce the risk of breaking something.
const transformSearchElementToAisVessel = (searchElement: TODO) => {
  if (searchElement.longitude) {
    return searchElement;
  }
  if (searchElement.object) {
    return searchElement.object;
  }
  return searchElement;
};

const searchElementIsAisVessel = (searchElement: TODO | undefined) => {
  if (!searchElement) {
    return false;
  }
  if (searchElement.type === 'ais-vessel') {
    return true;
  }
  if (searchElement.layer?.id === 'AIS') {
    return true;
  }

  return false;
};
