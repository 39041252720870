import {Button} from '../../../Button/Button';
import '../style.scss';

const EmptyStateMainPortfolio = () => {
  return (
    <div id="cargo-empty-state-sub-container" className="empty-state-container-main">
      <img
        src="/static/images/seanexxt/dashboard/onboarding/12onboarding_illlustr-cargo-hover.png"
        className="empty-state-container-main__image"
        height={110}
        alt="emptyStateCargo"
      />
      <div className={'empty-state-container-main__header'}>Let's add your first cargo!</div>
      <div className={'empty-state-container-main__subline'}>There are currently no cargoes in your list.</div>
      <div className={'empty-state-container-main__subline'}>Add a cargo from the Market or create a new one.</div>
      <Button id={'empty-state-action-button'} to={'/cargo/create'} label={'Create cargo'} bold upper primary />
    </div>
  );
};

export default EmptyStateMainPortfolio;
