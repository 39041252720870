import {useEffect, useState} from 'react';
import {MapInstance, MapSettings, MapSize, MapState, SelectedItems} from './Types';
import {ViewState} from '../ViewState';
import {blankState} from './blankstate';
import {fitMapToBounds} from '../utils/fitBounds';
import {Coordinates} from '../../../utils/Coordinates';
import {PrivateMapApi} from '../useMapApi';
import {MapApi} from '../MapApi';
import {MapSwitches} from '../MapDetails/utils/types';
import produce from 'immer';
import {DraftFunction} from 'use-immer';
import {useLocalStorage} from '../../../utils/useLocalStorage';
import {TODO} from '../../../utils/TODO';
import {DEFAULT_MAP_STYLE, MAPBOX_STYLES} from '../const';
import {z} from 'zod';

export type UseMapInstanceParams = {
  mapApi?: MapApi;
  initialMapSettings?: Partial<MapSettings>;
  initialViewState?: Partial<ViewState>;
  isInitialized: boolean;
  onInitializationDone: () => void;
};

/**
 * Note: This hook is an implementation detail of SeaboMap. It is not part of its public interface.
 *
 * Returns the MapInstance used by SeaboMap. An existing MapInstance passed in from a parent is reused.
 *
 * @param mapApi an optional instance of MapApi
 * @returns the MapInstance
 */
export const useMapInstance = ({
  mapApi,
  initialMapSettings,
  initialViewState,
  isInitialized,
  onInitializationDone,
}: UseMapInstanceParams): MapInstance => {
  const mapInstance: MapInstance = useMapInstanceImplementation({
    initialMapSettings,
    initialViewState,
  });

  // Patch the map instance into the map api.
  (mapApi as PrivateMapApi | undefined)?.setMapInstance(mapInstance);

  useEffect(() => {
    if (!isInitialized) {
      onInitializationDone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mapInstance;
};

const useMapInstanceImplementation = ({
  initialMapSettings,
  initialViewState,
}: {
  initialMapSettings?: Partial<MapSettings>;
  initialViewState?: Partial<ViewState>;
}): MapInstance => {
  const [mapSize, setMapSize] = useState<MapSize>(blankState.mapSize);
  const [isSelectMode, setSelectMode] = useState<boolean>(blankState.isSelectMode);
  const [mapReady, setMapReady] = useState<boolean>(blankState.mapReady);
  const [showSettingView, setShowSettingView] = useState<boolean>(blankState.showSettingView);
  const [isFullScreen, setFullscreen] = useState<boolean>(blankState.isFullScreen);
  const [showSideContent, setShowSideContent] = useState<boolean>(blankState.showSideContent);
  const [viewState, setViewState] = useState<ViewState>({...blankState.viewState, ...initialViewState});
  const [selectedItems, setSelectedItems] = useState<SelectedItems>(blankState.selectedItems);
  const [mapResolution, setMapResolution_] = useLocalStorage<0 | 2>(
    'mapResolution',
    blankState.settings.mapResolution,
    z.union([z.literal(0), z.literal(2)])
  );

  const [settings, setSettings_] = useState<MapSettings>(() => {
    const firstSettings = {
      ...blankState.settings,
      ...initialMapSettings,
      mapResolution,
    };
    const possibleMapStyles = Object.keys(MAPBOX_STYLES);

    // This is the migration, because we remove some styles from the list
    if (!possibleMapStyles.includes(firstSettings.mapStyle)) {
      firstSettings.mapStyle = DEFAULT_MAP_STYLE;
    }
    return firstSettings;
  });

  const switches = settings.switches as MapSwitches;

  const mapState: MapState = {
    mapSize,
    viewState,
    isSelectMode,
    showSideContent,
    isFullScreen,
    mapReady,
    showSettingView,
    selectedItems,
    settings,
    switches,
  };

  const setMapResolution = (mapResolution: 0 | 2) => {
    setMapResolution_(mapResolution);
    setSetting('mapResolution', mapResolution);
  };

  const setSettings = (s: MapSettings) => {
    setSettings_(s);
  };

  const setSetting = (key: keyof MapSettings, value: TODO) => {
    setSettings({...settings, [key]: value});
  };

  const setSwitches = (switches: MapSwitches) => {
    setSetting('switches', switches);
  };

  const fitBounds = (coords: Coordinates[]) => {
    fitMapToBounds(coords, mapState, setViewState);
  };

  const updateSwitches = (updateFunction: DraftFunction<MapSwitches>) => {
    setSwitches(produce(switches, updateFunction));
  };

  const value: MapInstance = {
    state: mapState,
    setFullscreen,
    setSelectMode,
    setMapReady,
    setSelectedItems,
    setShowSideContent,
    setViewState,
    setMapSize,
    setShowSettingView,
    setSettings,
    setSwitches,
    setSetting,
    fitBounds,
    updateSwitches,
    setMapResolution,
  };

  return value;
};
