import {NegotiationTermType} from '../../../../../../../api/node-backend/generated';
import {IconType} from '../../../../../../../atoms/Icon';

export const termTypeIcons: Record<NegotiationTermType, IconType> = {
  [NegotiationTermType.Account]: 'person',
  [NegotiationTermType.Owners]: 'person',
  [NegotiationTermType.Vessel]: 'vessel-complex',
  [NegotiationTermType.Delivery]: 'port',
  [NegotiationTermType.LoadPort]: 'port',
  [NegotiationTermType.Laycan]: 'date',
  [NegotiationTermType.Duration]: 'time-outline',
  [NegotiationTermType.Cargo]: 'box-open',
  [NegotiationTermType.CharterHire]: 'portfolio',
  [NegotiationTermType.Freight]: 'portfolio',
  [NegotiationTermType.Demurrage]: 'portfolio',
  [NegotiationTermType.Dispatch]: 'received',
  [NegotiationTermType.CargoHolds]: 'received',
  [NegotiationTermType.CargoGear]: 'received',
  [NegotiationTermType.Redelivery]: 'port',
  [NegotiationTermType.DischargePort]: 'port',
  [NegotiationTermType.Ilohc]: 'block',
  [NegotiationTermType.CargoExclusions]: 'block',
  [NegotiationTermType.TradingExclusions]: 'block',
  [NegotiationTermType.BunkerClause]: 'block',
  [NegotiationTermType.LawArbitration]: 'verified-badge',
  [NegotiationTermType.OtherwiseAsPerBaseCharterParty]: 'reply',
  [NegotiationTermType.Addcom]: 'portfolio',
  [NegotiationTermType.Brokerage]: 'portfolio',
};
