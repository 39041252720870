export const wrapperForOnSuccessAndOnError = async <TData>({
  onSuccess,
  onError,
  queryFunction,
}: {
  queryFunction: () => Promise<TData>;
  onSuccess?: (data: TData) => void;
  onError?: (error: unknown) => void;
}) => {
  try {
    const data = await queryFunction();
    onSuccess?.(data);
    return data;
  } catch (error) {
    if (onError) {
      onError(error);
      throw new ReactQueryWrapperError({
        message: 'Error in wrapperForOnSuccessAndOnError',
        error,
      });
    }

    throw error;
  }
};

export class ReactQueryWrapperError extends Error {
  error: unknown;

  constructor(errorParams?: {message?: string; error: unknown}) {
    super(errorParams?.message);
    this.error = errorParams?.error;
  }

  name = 'ReactQueryWrapperError' as const;
}
