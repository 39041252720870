import {InputState} from '../../utils/voyageChartercalculation/voyageChartercalculation';
import produce from 'immer';
import {DEFAULT_SEA_MARGIN} from '../blankState';

export const doPortRouteRelation = (inputState: InputState): InputState => {
  return produce(inputState, draftInputState => {
    let pointRouteDiff = draftInputState.voyage.points.length - draftInputState.voyage.routes.length;
    while (pointRouteDiff !== 1 && draftInputState.voyage.points.length >= 1) {
      if (pointRouteDiff < 1) {
        draftInputState.voyage.routes.pop();
      } else {
        draftInputState.voyage.routes.push({
          distance: 0,
          seaMargin: DEFAULT_SEA_MARGIN,
        });
      }
      pointRouteDiff = draftInputState.voyage.points.length - draftInputState.voyage.routes.length;
    }

    return draftInputState;
  });
};
