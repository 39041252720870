/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {SimilarVesselImosResponse, SimilarVesselImosResponseFromJSON, SimilarVesselImosResponseToJSON} from '../models';

export interface GetSimilarVesselsForImoRequest {
  imo: string;
}

/**
 *
 */
export class SimilarVesselsApi extends runtime.BaseAPI {
  /**
   */
  async getSimilarVesselsForImoRaw(
    requestParameters: GetSimilarVesselsForImoRequest
  ): Promise<runtime.ApiResponse<SimilarVesselImosResponse>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getSimilarVesselsForImo.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/node-backend/similar-vessels/imo/{imo}`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => SimilarVesselImosResponseFromJSON(jsonValue));
  }

  /**
   */
  async getSimilarVesselsForImo(requestParameters: GetSimilarVesselsForImoRequest): Promise<SimilarVesselImosResponse> {
    const response = await this.getSimilarVesselsForImoRaw(requestParameters);
    return await response.value();
  }
}
