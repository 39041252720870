import React, {FC, useEffect, useMemo} from 'react';
import {ExportReportsModal} from '../../../components/ExportReportsModal/ExportReportsModal';
import {Typography} from 'antd';
import {getFixtureReports} from '../getFixtureReports';
import {AllReports, ReportDisableable} from '../../../components/ExportReportsModal/Report';
import {useSelector} from '../../../redux/react-redux';
import {useCanExportSaleFixturesQuery} from './useCanExportSaleFixturesQuery';
import {getWorkspaceId} from '../../../redux/selectors';
import {TODO} from '../../../utils/TODO';

const {Paragraph} = Typography;
export const ExportFixturesModal: FC<{
  visible: boolean;
  filter?: TODO;
  fixturesTotal?: number;
  vesselId?: number;
  onClose: () => void;
}> = ({visible, vesselId, fixturesTotal, onClose, filter}) => {
  const fixtureFilters = vesselId ? {fixtureVessel: {id: vesselId}} : filter;
  const workspaceId = useSelector(getWorkspaceId);
  const canExportSaleFixtures = useCanExportSaleFixturesQuery({filter: fixtureFilters});

  useEffect(() => {
    if (visible) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      canExportSaleFixtures.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const availableReports = useMemo(() => {
    return AllReports.map<ReportDisableable>(report => {
      const newReport: ReportDisableable = {
        ...report,
        disabled: report.id === 'sp-report' && !canExportSaleFixtures.value,
      };

      return newReport;
    });
  }, [canExportSaleFixtures.value]);

  return (
    <ExportReportsModal
      visible={visible}
      title="Export fixtures"
      description={
        vesselId ? (
          <Paragraph>You are about to download the fixtures of the viewed vessel as an Excel file.</Paragraph>
        ) : (
          <Paragraph>
            You are about to download the current view with <b>{fixturesTotal}</b> fixtures as an Excel file.
            <br />
            You can use the filters on the <a onClick={onClose}>Fixture page</a> to narrow down that list (e.g. to a
            specific month or year).
          </Paragraph>
        )
      }
      availableReports={availableReports}
      buttonLabel="Export fixtures"
      loadReportsFromServer={(reportList: string[]) =>
        getFixtureReports({
          workspaceId,
          fixtureFilters,
          reportList: reportList,
        })
      }
      onClose={onClose}
    />
  );
};
