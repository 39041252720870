import {FC} from 'react';
import {useParseMailMutation} from '../../../../../screens/dashboard/widgets/ParserWidget/utils/useParseMailMutation';
import {Spin} from 'antd';
import {transformParserVesselToPlatformVessel} from '../../../../../screens/dashboard/widgets/ParserWidget/transformParserVesselToPlatformVessel/transformParserVesselToPlatformVessel';
import {ParserForm} from '../../../../ParserForm/ParserForm';
import {SeaboVessel} from '../../../../../api/node-backend/generated';

export const ImportVesselWithParser: FC<{onSubmit: (vessel: SeaboVessel) => void}> = ({onSubmit}) => {
  const parseMutation = useParseMailMutation({
    onSuccess: async parsedData => {
      if (parsedData) {
        const platformVessel = await transformParserVesselToPlatformVessel(parsedData);
        onSubmit(platformVessel as SeaboVessel);
      }
    },
  });

  return (
    <Spin spinning={parseMutation.isPending}>
      <ParserForm
        onSubmit={({text}) => {
          parseMutation.mutate({text});
        }}
        height={252}
        submitLabel={'Parse & Next'}
        stretchButton
        showBackgroundIcon
      />
    </Spin>
  );
};
