import {FC, useEffect} from 'react';
import {usePortfolioGridsReset} from './usePortfolioGridsReset';
import * as Sentry from '@sentry/react';
import {Navigate, Route, Routes} from 'react-router-dom';

export const PortfolioScreens: FC = () => {
  usePortfolioGridsReset();

  useEffect(() => {
    Sentry.captureMessage('The portfolio screen was requested, although it was move to "My Fleet" and "My Cargoes".');
  }, []);
  return (
    <Routes>
      <Route path="/vessel" element={<Navigate to={'/my-fleet'} />} />
      <Route path="/vessel/archive" element={<Navigate to={'/my-fleet/archive'} />} />
      <Route path="/cargo" element={<Navigate to={'/my-cargoes'} />} />
      <Route path="/cargo/archive" element={<Navigate to={'/my-cargoes/archive'} />} />
      <Route path="/" element={<Navigate to={'/my-fleet'} />} />
    </Routes>
  );
};
