import styled from 'styled-components';

export const SettingsArea = styled.div`
  border-top: 1px solid var(--color-gray-3);
  padding-top: 20px;
`;

export const SettingsAreaWrapper = styled.div`
  padding: 0 0;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const SettingsAreaTitleWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SettingsAreaTitle = styled.h3`
  font-weight: bold;
  color: var(--color-gray-1);
  font-size: var(--font-size-lg);
`;

export const SettingsAreaSubtitle = styled.p`
  color: var(--color-gray-2);
  font-size: var(--font-size-md);
`;
