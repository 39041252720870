import {FC} from 'react';
import classNames from 'classnames';
import Loading from '../../atoms/Loading';

interface Props {
  className?: string;
}

const LoadingOverlay: FC<Props> = ({className}) => {
  return (
    <div className={classNames('loading-overlay__overlay', className)}>
      <div className="loading-overlay__content">
        <Loading />
      </div>
    </div>
  );
};

export default LoadingOverlay;
