import {FC} from 'react';
import {Collapse} from '../../atoms/Collapse/Collapse';

type HeroFilterMapCollapseProps = {
  viewOpen?: 'filters' | 'map';
  filters: JSX.Element;
  map?: JSX.Element;
};

export const HeroFilterMapCollapse: FC<HeroFilterMapCollapseProps> = ({viewOpen = 'filters', filters, map}) => {
  return (
    <>
      {map ? (
        <>
          <Collapse name="Filters" isOpen={viewOpen === 'filters'}>
            {filters}
          </Collapse>
          <Collapse name="Map" isOpen={viewOpen === 'map'}>
            <div style={{height: 630}}>{map}</div>
          </Collapse>
        </>
      ) : (
        filters
      )}
    </>
  );
};
