import {Button} from '../../../Button/Button';

import '../style.scss';

type Props = {
  goToAll: () => void;
};

const EmptyStateSubPortfolio = ({goToAll}: Props) => (
  <div id="cargo-empty-state-sub-container" className="empty-state-container-sub">
    <div className={'empty-state-container-sub__header'}>Adding cargoes to a cargo list</div>
    <div className={'empty-state-container-sub__images'}>
      <div className={'empty-state-context-container'}>
        <img
          className={'empty-state-image-container-sub__image'}
          src="/static/images/seanexxt/portfolio/empty-sub-portfolio-cargo-1.png"
          alt="empty-sub-portfolio-cargo-1"
          style={{margin: 'auto'}}
        />
      </div>
      <div className={'empty-state-context-container'}>
        <img
          className={'empty-state-container-sub__image'}
          src="/static/images/seanexxt/portfolio/empty-sub-portfolio-cargo-2.png"
          alt="empty-sub-portfolio-cargo-2"
          style={{margin: 'auto'}}
        />
      </div>
      <div className={'empty-state-context-container'}>
        <img
          className={'empty-state-container-sub__image'}
          src="/static/images/seanexxt/portfolio/empty-sub-portfolio-cargo-3.png"
          alt="empty-sub-portfolio-cargo-3"
          style={{margin: 'auto'}}
        />
      </div>
      <div className={'empty-state-context-container'}>
        Go to <br />
        All cargoes
      </div>
      <div className={'empty-state-context-container'}>
        On a selected cargo, <br /> choose "Manage cargo lists"
        <br />
        from the 3-dot menu
      </div>
      <div className={'empty-state-context-container'}>
        Select a cargo list
        <br /> to add a cargo to
      </div>
    </div>
    <Button id={'empty-state-action-button'} onClick={goToAll} label={'GO TO ALL CARGOES'} bold upper primary />
  </div>
);

export default EmptyStateSubPortfolio;
