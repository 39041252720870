import {Fragment} from 'react';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import Input from '../../../../../atoms/Input';
import {hasError} from '../../../../../utils/helper';
import HorizontalLine from '../../../../../atoms/HorizontalLine';
import autocomplete from '../../../../../redux/ApiService/autocomplete';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './FixtureCargoDefinition';
import {brokerOptions} from '../../../../Modal/ManageFixture/ManageFixtureForm/utils';
import {SelectCreatable} from '../../../../../atoms/Select/SelectCreatable';
import {AsyncMultiCreatable} from '../../../../../atoms/Select/AsyncMultiCreatable';
import {useDispatch, useSelector} from '../../../../../redux/react-redux';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const FixtureCargo = ({filterProviderApi}: Props) => {
  const dispatch = useDispatch();
  const preFillChartererData = useSelector(state => state.api.autoComplete.preFillCharterer.data?.data?.items) as
    | {
        slug: string;
        name: string;
      }[]
    | undefined;
  const searchCharterer = (query: string) => dispatch(autocomplete.searchCharterer(query));

  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
      {({handleChange, values, validations}) => (
        <Fragment>
          <div className="container">
            <div className="row bottom-fix">
              <div className="scol-12">
                <LabelWrapper label={'Charterer (Cargo)'} htmlFor={'filter-fixture-disponent-owner'}>
                  <AsyncMultiCreatable
                    id={'filter-fixture-disponent-owner'}
                    name={'filter-fixture-disponent-owner'}
                    value={values.disponentOwner}
                    onChange={value => handleChange('disponentOwner', [...value])}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.label}
                    loadOptions={q => (q.length > 1 ? searchCharterer(q) : Promise.resolve([]))}
                    defaultOptions={
                      preFillChartererData?.map(p => ({
                        value: p.slug,
                        label: p.name,
                      })) || []
                    }
                    createLabel={value => value}
                    createOptionPosition={'first'}
                    placeholder={'Type to search...'}
                    loadingMessage={() => 'Searching ...'}
                  />
                </LabelWrapper>
              </div>
              <HorizontalLine large topSpace={'small'} />
              <div className="scol-12">
                <LabelWrapper label={'Owner (Vessel)'} htmlFor={'filter-ownership-managing-owner'}>
                  <Input
                    id={'filter-ownership-managing-owner'}
                    onChange={value => handleChange('managingOwner', value)}
                    value={values.managingOwner}
                    type={'text'}
                    hasError={hasError('managingOwner', validations)}
                    maxLength={191}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12">
                <LabelWrapper label={'Commercial manager (Vessel)'} htmlFor={'filter-ownership-registered-owner'}>
                  <Input
                    id={'filter-ownership-registered-owner'}
                    onChange={value => handleChange('registeredOwner', value)}
                    value={values.registeredOwner}
                    hasError={hasError('registeredOwner', validations)}
                    maxLength={191}
                  />
                </LabelWrapper>
              </div>
              <HorizontalLine large topSpace={'small'} />
              <div className="scol-12">
                <LabelWrapper label={'Broker'} htmlFor={'filter-ownership--broker'}>
                  <SelectCreatable
                    id={`filter-ownership--broker`}
                    name={`filter-ownership--broker`}
                    value={values.broker}
                    onChange={value => handleChange('broker', value ?? '')}
                    options={brokerOptions}
                    isClearable
                    placeholder={'Select or search...'}
                    maxLength={127}
                    hasError={hasError('broker', validations)}
                    formatCreateLabel={userInput => `Search for ${userInput}`}
                  />
                </LabelWrapper>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </FilterButton>
  );
};

export default FixtureCargo;
