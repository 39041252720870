import {FC} from 'react';
import styled from 'styled-components';
import {CompanyTagsByType} from './CompanyTagsByType';
import {TagType} from '../../../../api/symfony/generated/models';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {SettingsCardBody} from '../../../../components/SettingsCard/SettingsCardBody';
import {SettingsCardHeader} from '../../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCard} from '../../../../components/SettingsCard/SettingsCard';
import {
  SettingsArea,
  SettingsAreaTitle,
  SettingsAreaTitleWrapper,
  SettingsAreaWrapper,
} from '../../../../components/SettingsCard/SettingsArea';

const CompanySettingsTags: FC<{company: Company}> = () => {
  return (
    <SettingsCard>
      <SettingsCardHeader title={'Tags'} />
      <SettingsCardBody>
        <SettingsArea>
          <SettingsAreaWrapper>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>Vessel tags</SettingsAreaTitle>
            </SettingsAreaTitleWrapper>
            <CompanyTagsByType type={TagType.Vessel}></CompanyTagsByType>
          </SettingsAreaWrapper>

          <Separator />

          <SettingsAreaWrapper>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>Fixture tags</SettingsAreaTitle>
            </SettingsAreaTitleWrapper>
            <CompanyTagsByType type={TagType.Fixture}></CompanyTagsByType>
          </SettingsAreaWrapper>

          <Separator />

          <SettingsAreaWrapper>
            <SettingsAreaTitleWrapper>
              <SettingsAreaTitle>Project tags</SettingsAreaTitle>
            </SettingsAreaTitleWrapper>
            <CompanyTagsByType type={TagType.Project}></CompanyTagsByType>
          </SettingsAreaWrapper>
        </SettingsArea>
      </SettingsCardBody>
    </SettingsCard>
  );
};

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-top: var(--border-base);
  margin-top: 32px;
  margin-bottom: 24px;
`;

export default CompanySettingsTags;
