import {Component} from 'react';
import {connect} from 'react-redux';
import BaseModal from '../../../atoms/BaseModal/index';
import LabelWrapper from '../../../atoms/LabelWrapper';
import ItsInput from '../../../atoms/Input';
import {actions as autocompleteActions} from '../../../redux/ApiService/autocomplete';
import {Tabs} from '../../../atoms/Tabs/Tabs';
import debounce from 'lodash/debounce';
import ProjectService from '../../../redux/ApiService/ProjectService';
import {showNotification} from '../../../utils/notification';
import {VesselTabPane} from './VesselTabPane';
import {CargoTabPane} from './CargoTabPane';
import TabPane from '../../../atoms/Tabs/TabPane';
import {TODO} from '../../../utils/TODO';
import {Input, Button} from 'antd';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import styled from 'styled-components';
import {ColorPicker, colorOptions} from '../../ColorPicker/ColorPicker';

const {TextArea} = Input;

type Project = {
  id?: number;
  name?: string;
  comment?: string;
  color?: string;
  cargo?: TODO;
  vessel?: TODO;
  type?: TODO;
};

type RequestProject = Omit<Project, 'color'> & {
  color: string | undefined;
};

type OwnCreateProjectModalProps = {
  project?: Project;
  createProject: (project: RequestProject) => Promise<void>;
  updateProject: (id: number, project: RequestProject) => Promise<void>;
  updateProjectSingle: (id: number, project: RequestProject) => Promise<void>;
  callback?: () => void;
  close: () => void;
  siteType: TODO;
};

interface CreateProjectModalState {
  project: Project;
  buttonText: string;
  searchDataVessel: TODO[];
  searchDataCargo: TODO[];
  query: TODO;
  isLoading: boolean;
  buttonDisabled: boolean;
  reset: boolean;
  selectedValue: TODO | null;
  projectDetail: TODO;
  shortlistItemId: null | TODO;
  menuIsOpen: boolean;
  currentType: 'vessel' | 'cargo';
}

type CreateProjectModalProps = OwnCreateProjectModalProps & typeof CreateProjectModalBody.defaultProps;
class CreateProjectModalBody extends Component<CreateProjectModalProps, CreateProjectModalState> {
  static defaultProps = {
    callback: () => {},
  };
  mounted: $TSFixMe;

  constructor(props: CreateProjectModalProps) {
    super(props);
    this.state = {
      buttonText: 'Create project',
      searchDataVessel: [],
      menuIsOpen: false,
      searchDataCargo: [],
      query: '',
      isLoading: false,
      shortlistItemId: null,
      buttonDisabled: true,
      reset: false,
      selectedValue: null,
      project: {
        name: '',
        comment: '',
        color: undefined,
      },
      projectDetail: false,
      currentType: 'vessel',
    };
  }

  componentDidMount() {
    this.mounted = true;
    (this.props as $TSFixMe)
      .preFillSearch('vessels_portfolio')
      .then((res: $TSFixMe) => this.handleSearchResult(res, 'vessel'));
    if (this.props.siteType) {
      this.setState({projectDetail: true});
    }
    if (this.props.project) {
      this.setState({
        buttonText: 'Update project',
        project: {
          name: this.props.project.name,
          comment: this.props.project.comment,
          color: this.props.project.color,
        },
        buttonDisabled: false,
        selectedValue: this.props.project.cargo
          ? {
              ...this.props.project.cargo,
              type: 'cargo',
            }
          : {
              ...this.props.project.vessel,
              type: 'vessel',
            },
      });
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleSearchResult = (res: $TSFixMe, currentType: $TSFixMe) => {
    if (res.data && res.data.items && res.data.items.length > 0) {
      if (this.mounted) {
        this.setState((prev: $TSFixMe) => ({
          currentType,
          searchDataVessel: res.data.items.find(({_source}: $TSFixMe) => _source.aliasName === 'seabo_vessels')
            ? res.data.items.map((item: $TSFixMe) => {
                return {
                  id: item._source.id,
                  name: item._source.name,
                  type: item._source.aliasName === 'seabo_vessels' ? 'vessel' : 'cargo',
                  ...item._source,
                };
              })
            : prev.searchDataVessel,
          searchDataCargo: res.data.items.find(({_source}: $TSFixMe) => _source.aliasName === 'seabo_cargoes')
            ? res.data.items.map((item: $TSFixMe) => {
                return {
                  id: item._source.id,
                  name: item._source.name,
                  type: item._source.aliasName === 'seabo_vessels' ? 'vessel' : 'cargo',
                  ...item._source,
                };
              })
            : prev.searchDataCargo,
          isLoading: false,
        }));
      }
    } else {
      this.setState({searchDataVessel: [], searchDataCargo: []});
    }
  };
  handleQuery = debounce((query, indexName) => {
    if (query.length === 0) {
      (this.props as $TSFixMe)
        .preFillSearch(indexName)
        .then((res: $TSFixMe) => this.handleSearchResult(res, this.state.currentType));
    }
    if (query.length < 2) {
      return;
    }
    this.setState({isLoading: true, query});
    (this.props as $TSFixMe)
      .loadSearchSingle(query, indexName, 1, 5)
      .then((res: $TSFixMe) => {
        if (res.data && res.data.items.length > 0 && this.mounted) {
          if (this.state.currentType === 'vessel') {
            this.setState({
              searchDataVessel: res.data.items.map((item: $TSFixMe) => {
                return {
                  id: item._source.id,
                  name: item._source.name,
                  type: item._source.aliasName === 'seabo_vessels' ? 'vessel' : 'cargo',
                  ...item._source,
                  highlight: item.highlight,
                };
              }),
              isLoading: false,
            });
          } else {
            this.setState({
              searchDataCargo: res.data.items.map((item: $TSFixMe) => {
                return {
                  id: item._source.id,
                  name: item._source.name,
                  type: item._source.aliasName === 'seabo_vessels' ? 'vessel' : 'cargo',
                  ...item._source,
                  highlight: item.highlight,
                };
              }),
              isLoading: false,
            });
          }
        } else {
          this.setState({searchDataVessel: [], searchDataCargo: [], isLoading: false, query});
        }
      })
      .catch(() => this.setState({isLoading: false}));
  }, 400);
  handleChange = (data: $TSFixMe) => {
    this.setState({
      selectedValue: data,
      buttonDisabled: this.state.project.name?.length === 0 || data === null,
    });
  };
  handleValue = (value: $TSFixMe, field: $TSFixMe) => {
    this.setState((prev: $TSFixMe) => {
      const toCheckValue = field === 'name' ? value : prev.project.name;
      return {
        ...prev,
        buttonDisabled: toCheckValue.length === 0 || this.state.selectedValue === null,
        project: {
          ...prev.project,
          [field]: value,
        },
      };
    });
  };
  handleReset = (key: $TSFixMe) => {
    this.setState(
      {
        shortlistItemId: null,
        selectedValue: null,
        buttonDisabled: true,
        isLoading: true,
        currentType: key ? 'cargo' : 'vessel',
      },
      () => {
        // true = cargo
        // false = vessel
        if (key) {
          (this.props as $TSFixMe)
            .preFillSearch('cargoes_portfolio')
            .then((res: $TSFixMe) => this.handleSearchResult(res, 'cargo'));
        } else {
          (this.props as $TSFixMe)
            .preFillSearch('vessels_portfolio')
            .then((res: $TSFixMe) => this.handleSearchResult(res, 'vessel'));
        }
      }
    );
  };
  handleCreateProject = async () => {
    const {project, selectedValue, projectDetail} = this.state;
    if (!selectedValue) {
      return;
    }
    this.setState({
      buttonDisabled: true,
    });

    if (this.props.project) {
      if (projectDetail) {
        await this.props.updateProjectSingle(this.props.project.id!, {
          ...project,
          color: project.color,
          type: selectedValue.type,
          [selectedValue.type]: selectedValue.id,
        });
      } else {
        await this.props.updateProject(this.props.project.id!, {
          ...project,
          color: project.color,
          type: selectedValue.type,
          [selectedValue.type]: selectedValue.id,
        });
      }
    } else {
      await this.props.createProject({
        ...project,
        color: project.color,
        type: selectedValue.type,
        [selectedValue.type]: selectedValue.id,
      });
    }
    this.props.callback();
    this.props.close();
    showNotification();
  };
  render() {
    const {isLoading, project, selectedValue, searchDataCargo, searchDataVessel} = this.state;

    const isNew = !this.props.project;
    const isCargoProject = this.state.currentType === 'cargo';

    return (
      <>
        <ProjectModalContainer className="projects-modal__container">
          <LabelWrapper label="Project name">
            <ItsInput
              onChange={data => this.handleValue(data, 'name')}
              value={project.name}
              maxLength={100}
              id="projects-modal__projects-name"
            />
          </LabelWrapper>
          {isNew && (
            <Tabs
              disabled={!isNew}
              buttonGroupTab
              onChangeActive={key => this.handleReset(key)}
              active={isCargoProject ? 1 : 0}>
              <TabPane label="Vessel">
                <VesselTabPane
                  onBlur={() => this.setState({menuIsOpen: false})}
                  onChange={this.handleChange}
                  searchDataVessel={searchDataVessel}
                  isLoading={isLoading}
                  selectedValue={selectedValue}
                  onSearch={(query, index) => this.handleQuery(query, index)}
                />
              </TabPane>
              <TabPane label="Cargo">
                <CargoTabPane
                  selectedValue={selectedValue}
                  searchDataCargo={searchDataCargo}
                  isLoading={isLoading}
                  onBlur={() => this.setState({menuIsOpen: false})}
                  onChange={this.handleChange}
                  onSearch={this.handleQuery}
                />
              </TabPane>
            </Tabs>
          )}
          <LabelWrapper label="Note / comment" style={{marginBottom: 0}}>
            <TextArea
              style={{resize: 'none'}}
              onChange={e => this.handleValue(e.target.value, 'comment')}
              maxLength={500}
              value={project.comment}
              rows={3}
              id="projects-modal__projects-comments"
            />
          </LabelWrapper>

          {/*
            TODO: Reconsider hiding the color picker on first render when refactoring this component to be a functional one
            
            There are 3 states for the project.color value:
              1. undefined - the color picker is not shown, first render
              2. null - the color picker is shown, but no color is selected
              3. string - the color picker is shown, and a color is selected
            Went with this approach to avoid the color picker being shown on first render since it was not being updated correctly
          */}
          {project.color !== undefined && (
            <LabelWrapper label="Marker colour" contentWrapperStyle={{marginBottom: 0}}>
              <ColorPicker
                id={'projects-modal__color-select'}
                colorId={colorOptions.find(option => option.value === project.color)?.colorId as number}
                onChange={colorOption => this.handleValue(colorOption.value === null ? '' : colorOption.value, 'color')}
              />
            </LabelWrapper>
          )}

          <ModalFooter>
            <Button
              disabled={this.state.buttonDisabled}
              onClick={() => this.handleCreateProject()}
              type="primary"
              size="large">
              {this.state.buttonText}
            </Button>
          </ModalFooter>
        </ProjectModalContainer>
      </>
    );
  }
}

const ProjectModalContainer = styled.div`
  text-align: left;
`;

const CreateProjectModal = (props: $TSFixMe) => {
  const title = props.project ? 'Edit project' : 'Create project';

  return (
    <BaseModal middle title={title}>
      {(modalProps: $TSFixMe) => <CreateProjectModalBody {...props} {...modalProps} />}
    </BaseModal>
  );
};
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  createProject: (body: RequestProject) => dispatch(ProjectService.createProject(body)),
  updateProject: (id: $TSFixMe, body: RequestProject) => dispatch(ProjectService.updateProjectList(id, body)),
  updateProjectSingle: (id: $TSFixMe, body: RequestProject) => dispatch(ProjectService.updateProject(id, body)),
  loadSearchSingle: (query: $TSFixMe, indexName: $TSFixMe, pageIndex: $TSFixMe, pageSize: $TSFixMe) =>
    dispatch(autocompleteActions.singleSearch({queryParams: {query, pageIndex, pageSize, indexName}})),
  preFillSearch: (indexName: $TSFixMe) => dispatch(autocompleteActions.preFillSearch({queryParams: {indexName}})),
});
export default connect(null, mapDispatchToProps)(CreateProjectModal);
