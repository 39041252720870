import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {VesselStatus, vesselStatusOptions} from '../../../../screens/CargoVesselForm/helper';
import {convertValuesToNamedValues, NamedValue} from '../FilterConversion/utils/NamedValue';

export type DatabaseType = {
  statuses: VesselStatus[];
};
export type InternalType = {
  vesselStatuses: NamedValue<VesselStatus>[];
};

export const Defaults: InternalType = {
  vesselStatuses: [],
};

const VesselStatusList: NamedValue<VesselStatus>[] = Object.entries(vesselStatusOptions).map(
  (designSubType: [string, string]) => {
    const value = designSubType[0] as VesselStatus;
    const name = designSubType[1];
    return {name, value};
  }
);

export const fromDatabase = ({statuses}: DatabaseType): InternalType => ({
  vesselStatuses: statuses ? convertValuesToNamedValues(statuses, VesselStatusList) : [],
});

export const toDatabase = ({vesselStatuses}: InternalType): DatabaseType => ({
  statuses: vesselStatuses ? vesselStatuses.map(status => status.value) : [],
});

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel status',
  branch: 'vesselStatuses',
  defaults: Defaults,
  validators: {},
  fromDatabase,
  toDatabase,
});
