import isPlainObject from 'lodash/isPlainObject';
import Api, {API} from '../Api';
import {InvalidApiRequestPayload} from './InvalidApiRequestPayload';
import {Middleware, MiddlewareAPI, Dispatch} from 'redux';
import {ApiRequestPayload, HttpMethod, Credentials} from './ApiRequestAction';
import {getAjaxCsrfToken} from '../../api/utils/api';
import {loadResponseJson} from '../../api/utils/loadResponseJson';
import {TODO} from '../../utils/TODO';

/**
 * A set of action ids that got canceled.
 */
export const canceled: Record<string, boolean> = {};

const validCallAPIKeys: (keyof ApiRequestPayload)[] = [
  'endpoint',
  'method',
  'body',
  'headers',
  'credentials',
  'types',
  'next',
];

const validHttpMethods: HttpMethod[] = ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'];

const validCredentials: Credentials[] = ['omit', 'same-origin', 'include'];

/**
 * Validates at runtime that the given payload is an ApiRequestPayload.
 * Once we have converted our code to TypeScript, we can delete this function.
 */
function validateAPIPayload(payload: ApiRequestPayload) {
  const validationErrors: string[] = [];

  if (!isPlainObject(payload)) {
    validationErrors.push('[RSAA] property must be a plain JavaScript object');
  }

  for (const key in payload) {
    if (!validCallAPIKeys.includes(key as keyof ApiRequestPayload)) {
      validationErrors.push(`Invalid [RSAA] key: ${key}`);
    }
  }

  const {endpoint, method, headers, credentials} = payload;

  if (typeof endpoint === 'undefined') {
    validationErrors.push('[RSAA] must have an endpoint property');
  } else if (typeof endpoint !== 'string' && typeof endpoint !== 'function') {
    validationErrors.push('[RSAA].endpoint property must be a string or a function');
  }

  if (typeof method === 'undefined') {
    validationErrors.push('[RSAA] must have a method property');
  } else if (typeof method !== 'string') {
    validationErrors.push('[RSAA].method property must be a string');
  } else if (!validHttpMethods.includes(method.toUpperCase() as HttpMethod)) {
    validationErrors.push(`Invalid [RSAA].method: ${method.toUpperCase()}`);
  }

  if (typeof headers !== 'undefined' && !isPlainObject(headers) && typeof headers !== 'function') {
    validationErrors.push('[RSAA].headers property must be undefined, a plain JavaScript object, or a function');
  }
  if (typeof credentials !== 'undefined') {
    if (typeof credentials !== 'string') {
      validationErrors.push('[RSAA].credentials property must be undefined, or a string');
    } else if (!validCredentials.includes(credentials)) {
      validationErrors.push(`Invalid [RSAA].credentials: ${credentials}`);
    }
  }

  return validationErrors;
}

export const apiMiddleware: Middleware =
  ({dispatch}: MiddlewareAPI) =>
  (next: Dispatch) =>
  action => {
    // Move to next Reducer if not API-RELATED

    if ([API.REQUEST, API.CANCEL_REQUEST].indexOf(action.type) === -1) {
      return next(action);
    }

    const handleError = (responseBody: TODO) => {
      dispatch({type: action.payload.next.ERROR, payload: responseBody});
    };

    const handleServerError = (statusCode: number) => {
      dispatch(Api.error(statusCode));
    };

    const handleResponse = (data: TODO) => {
      if (action.cancelable && canceled[action.cancelable]) {
        return;
      }
      dispatch({type: action.payload.next.SUCCESS, payload: data});
    };

    const processResponse = (response: Response) => {
      const {endpoint, method = 'GET'} = action.payload;
      switch (true) {
        case response.status >= 300 && response.status <= 399:
        case response.status >= 500 && response.status <= 599:
          handleServerError(response.status);
          break;
        case response.status >= 400 && response.status <= 499:
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          loadResponseJson(response, method, endpoint).then(handleError); // switch with specific handling for 400 errors
          break;
        default:
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          loadResponseJson(response, method, endpoint).then(handleResponse);
      }
    };

    if (action.type === API.REQUEST) {
      const validationErrors = validateAPIPayload(action.payload);
      if (validationErrors.length) {
        return next({
          type: API.VALIDATION_ERROR,
          payload: new InvalidApiRequestPayload(validationErrors),
          error: true,
        });
      }
      const {endpoint, method = 'GET', headers = {}, body} = action.payload;
      const isForm = headers['Content-Type'] === 'application/x-www-form-urlencoded; charset=UTF-8';

      dispatch({type: action.payload.next.PENDING});
      fetch(endpoint, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Ajax-Csrf-Token': getAjaxCsrfToken(),
          ...headers,
        },
        credentials: 'same-origin',
        body: isForm ? body : JSON.stringify(body),
      })
        .then(processResponse)
        .catch(handleError);
    }

    if (action.type === API.CANCEL_REQUEST) {
      canceled[action.id] = true;
      setTimeout(() => delete canceled[action.id], 5000);
    }

    return next(action);
  };
