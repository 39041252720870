import {FC, useEffect, useState} from 'react';
import {ExportReportsForm} from './ExportReportsForm';
import {useQuery} from '@tanstack/react-query';
import {LoadingIndicator} from '../../atoms/Loading';
import styled from 'styled-components';
import {File} from './File';
import {startBrowserDownload} from './startBrowserDownload';
import {LoadReportsFromServerFunc} from './LoadReportsFromServerFunc';
import {ReportDisableable} from './Report';
import {Modal} from '../../antd/components/Modal';

/**
 * A modal for downloading reports from the server.
 *
 * The user can select which reports she wants and then the modal will download them from the server
 * and send them to the browser.
 */
export const ExportReportsModal: FC<{
  visible: boolean;
  title: string;
  description: JSX.Element;
  availableReports: ReportDisableable[];
  buttonLabel: JSX.Element | string;

  loadReportsFromServer: LoadReportsFromServerFunc;
  onClose: () => void;
}> = ({visible, title, description, availableReports, buttonLabel, loadReportsFromServer, onClose}) => (
  <Modal title={title} open={visible} onCancel={onClose} footer={null} destroyOnClose>
    <ModalContent
      description={description}
      availableReports={availableReports}
      buttonLabel={buttonLabel}
      loadReportsFromServer={loadReportsFromServer}
      onClose={onClose}
    />
  </Modal>
);

const ModalContent: FC<{
  description: JSX.Element;
  availableReports: ReportDisableable[];
  buttonLabel: JSX.Element | string;
  loadReportsFromServer: LoadReportsFromServerFunc;
  onClose: () => void;
}> = ({description, availableReports, buttonLabel, loadReportsFromServer, onClose}) => {
  const [reportList, setReportList] = useState<string[] | null>(null);

  return reportList === null ? (
    <ExportReportsForm
      description={description}
      availableReports={availableReports}
      buttonLabel={buttonLabel}
      onSubmit={(reportList: string[]) => {
        setReportList(reportList);
      }}
    />
  ) : (
    <Download loadFileFromServer={() => loadReportsFromServer(reportList)} onComplete={onClose} />
  );
};

const Download: FC<{
  loadFileFromServer: () => Promise<File>;
  onComplete: () => void;
}> = ({loadFileFromServer, onComplete}) => {
  const {data, isSuccess} = useQuery({
    queryKey: ['reports-export'],
    queryFn: loadFileFromServer,
    gcTime: 0, // disable cache for export data request to avoid returning non-actual data after filter applying. see S20-271
  });

  useEffect(() => {
    if (isSuccess && data) {
      void startBrowserDownload(data);
      onComplete();
    }
  }, [data, onComplete, isSuccess]);

  return <LoadingIndicatorStyled text="Creating export, this could take a while." />;
};

const LoadingIndicatorStyled = styled(LoadingIndicator)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;
