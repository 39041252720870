import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import commodityApi from '../../../redux/ApiService/commodity';
import {acceptFloatOrEmpty} from '../../../utils/formatter';
import {floatBetween, selectUnitFor} from '../../../utils/validators';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import CompoundInput from '../../../atoms/CompoundInput';
import Input from '../../../atoms/Input';
import capitalize from 'lodash/capitalize';
import {commodityCategories} from '../../../utils/commodityCategories';
import {RootState} from '../../../redux/store';
import Select from '../../../atoms/Select/Select';

const stowageUnits = {
  cbm: 'CBM',
  mt: 'MT',
};
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const stowageUnitOptions = Object.keys(stowageUnits).map(value => ({value, name: stowageUnits[value]}));

const extractValue = (values: string | string[]) => (Array.isArray(values) ? values[0] : values);

const getCommodityCategoryFormatted = (slug: string) => {
  return commodityCategories.find(category => category.slug === slug)?.name;
};

const formatCommodityName = (slug: string) => {
  return capitalize(slug).replaceAll('-', ' ').trim();
};

const Wrapper = BindToProvider(
  'Commodity',
  'commodity',
  {
    commodityCategory: null,
    commodityType: null,
    commodityStowageFactor: '',
    commodityStowageFactorUnit: '',
  },
  {
    commodityStowageFactor: floatBetween(0, 99),
    commodityStowageFactorUnit: selectUnitFor('commodityStowageFactor'),
  },
  {
    toApi: (fields: $TSFixMe) => ({
      ...fields,
      commodityCategory: fields.commodityCategory ? fields.commodityCategory.slug : null,
      commodityType: fields.commodityType ? fields.commodityType.slug : null,
    }),
    fromApi: (fields: $TSFixMe) => ({
      ...fields,
      commodityCategory: fields.commodityCategory
        ? {slug: fields.commodityCategory, name: getCommodityCategoryFormatted(extractValue(fields.commodityCategory))}
        : null,
      commodityType: fields.commodityType
        ? {slug: fields.commodityType, name: formatCommodityName(extractValue(fields.commodityType))}
        : null,
    }),
  },
  true
)(WrappedDetailsSection);
const Commodity = (props: $TSFixMe) => {
  const {form, getCommodities, getSpecificCommodities} = props;
  const commodityCategory = form && form.commodity && form.commodity.commodityCategory;
  useEffect(() => {
    getCommodities();
  }, [getCommodities]);
  useEffect(() => {
    if (commodityCategory && commodityCategory.slug) {
      getSpecificCommodities(commodityCategory.slug);
    }
  }, [commodityCategory, getSpecificCommodities]);
  const handleCategoryChange = (value: $TSFixMe) => {
    props.onChange('commodity', {...props.form.commodity, commodityCategory: value || '', commodityType: null});
  };
  return (
    <Wrapper {...props}>
      {({onChange, values, validations, validate}: $TSFixMe) => (
        <div className="container-fluid">
          <div className="row">
            <div className="scol-12 scol-sm-6">
              <LabelWrapper
                label={'Category'}
                htmlFor={'form-commodity-category'}
                hasError={validations.commodityCategory.invalid}>
                <Select
                  id={`form-commodity-category`}
                  name={`form-commodity-category`}
                  value={values.commodityCategory}
                  onChange={handleCategoryChange}
                  options={props.commodities}
                  getOptionLabel={(o: $TSFixMe) => o.name}
                  getOptionValue={(o: $TSFixMe) => o.slug}
                  components={{DropdownIndicator: ArrowIndicator}}
                  isLoading={props.getCommoditiesLoading}
                  isClearable
                />
              </LabelWrapper>
              {validations.commodityCategory.error && (
                <p className="text-danger text-danger--no-margin">{validations.commodityCategory.error}</p>
              )}
            </div>
            <div className="scol-12 scol-sm-6">
              <LabelWrapper label={'Type'} htmlFor={'form-commodity-type'} hasError={validations.commodityType.invalid}>
                {values.commodityCategory && values.commodityCategory.slug !== 'other' ? (
                  <Select
                    id={`form-commodity-type`}
                    name={`form-commodity-type`}
                    value={values.commodityType}
                    onChange={(value: $TSFixMe) => onChange('commodityType', value || '')}
                    options={props.commoditiesSpecific}
                    getOptionLabel={(o: $TSFixMe) => o.name}
                    getOptionValue={(o: $TSFixMe) => o.slug}
                    components={{DropdownIndicator: ArrowIndicator}}
                    isLoading={props.getSpecificCommoditiesLoading}
                    isClearable
                  />
                ) : (
                  <Input
                    id={`form-commodity-type`}
                    name={`form-commodity-type`}
                    maxLength={127}
                    value={values.commodityType?.name}
                    onChange={value =>
                      onChange('commodityType', {
                        name: value,
                        slug: (value as $TSFixMe).replace(' ', '-'),
                        category: 'other',
                      })
                    }
                  />
                )}
              </LabelWrapper>
              {validations.commodityType.error && (
                <p className="text-danger text-danger--no-margin">{validations.commodityType.error}</p>
              )}
            </div>
          </div>
          {form.contractType.contractType === 'vc' && (
            <div className={'row'}>
              <div className="scol-12 scol-sm-6">
                <LabelWrapper
                  label={'Stowage factor'}
                  htmlFor={'form-commodity-stowage'}
                  hasError={
                    validations.commodityStowageFactor.invalid || validations.commodityStowageFactorUnit.invalid
                  }>
                  <CompoundInput>
                    <Input
                      id={'form-intake-stowage'}
                      onChange={v =>
                        acceptFloatOrEmpty(
                          v,
                          v => onChange('commodityStowageFactor', v, () => validate('commodityStowageFactorUnit')),
                          2,
                          2
                        )
                      }
                      value={values.commodityStowageFactor}
                      hasError={validations.commodityStowageFactor.invalid}
                    />
                    <Select
                      id={`form-intake-stowage-unit`}
                      name={`form-intake-stowage-unit`}
                      options={stowageUnitOptions}
                      value={{
                        value: values.commodityStowageFactorUnit,
                        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        name: stowageUnits[values.commodityStowageFactorUnit],
                      }}
                      defaultValue={{
                        value: values.commodityStowageFactorUnit,
                        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        name: stowageUnits[values.commodityStowageFactorUnit],
                      }}
                      onChange={(o: $TSFixMe) =>
                        onChange('commodityStowageFactorUnit', o.value, () => validate('commodityStowageFactorUnit'))
                      }
                      getOptionLabel={(o: $TSFixMe) => o.name}
                      getOptionValue={(o: $TSFixMe) => o.value}
                      components={{DropdownIndicator: ArrowIndicator}}
                      isSearchable={false}
                    />
                  </CompoundInput>
                </LabelWrapper>
                {validations.commodityStowageFactor.error && (
                  <p className="text-danger text-danger--no-margin">{validations.commodityStowageFactor.error}</p>
                )}
                {validations.commodityStowageFactorUnit.error && (
                  <p className="text-danger text-danger--no-margin">{validations.commodityStowageFactorUnit.error}</p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};
const mapStateToProps = ({api}: RootState) => ({
  commodities: api.commodity.get.data || [],
  commoditiesSpecific: api.commodity.getSpecific.data || [],
  getCommoditiesLoading: api.commodity.get.loading,
  getSpecificCommoditiesLoading: api.commodity.getSpecific.loading,
});
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  getCommodities: () => dispatch(commodityApi.get()),
  getSpecificCommodities: (slug: $TSFixMe) => dispatch(commodityApi.getSpecific(slug)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Commodity);
