import {TourDefinition, TourId, getFinalStep} from '../../OnboardingTour/TourDefinition';

export const VoyageCharterTourDefinition: TourDefinition = {
  id: TourId.VoyageCalc,
  steps: [
    {
      title: 'Voyage Calculation',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/10.%20Tools%20Onboarding.mp4',
      },
      pdfDownloadUrl:
        "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/10%20Tools/Voyage%20Calc/Let's%20meet%20the%20voyage%20calculation.pdf",
      placement: 'center',
    },
    {
      title: 'How to do a Voyage Calculation',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/11%20How%20to%20Voyage%20Calc/%2011.%201%20How%20to%20do%20a%20Voyage%20Calculation%20Kopie.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/10%20Tools/Voyage%20Calc/Do%20a%20calculation.pdf',
      placement: 'center',
    },
    getFinalStep('Voyage Calculation'),
  ],
};
