import uniq from 'lodash/uniq';
import {getGeohash, getRouteKey} from './utils';
import {Step5Vessel} from '../../steps/step5';

export const getUniqueRouteKeys = ({portGeohash, vessels}: {portGeohash: string; vessels: Step5Vessel[]}): string[] => {
  const routeKeys: string[] = [];

  vessels.forEach(vessel => {
    const vesselGeohash = getGeohash([vessel.coordinates![0], vessel.coordinates![1]]);

    if (vessel.destinationPort) {
      const destinationGeohash = getGeohash([vessel.destinationPort!.latitude!, vessel.destinationPort!.longitude!]);
      routeKeys.push(getRouteKey(vesselGeohash, destinationGeohash));
      routeKeys.push(getRouteKey(destinationGeohash, portGeohash));
    } else {
      routeKeys.push(getRouteKey(vesselGeohash, portGeohash));
    }
  });
  return uniq(routeKeys);
};
