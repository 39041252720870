import {FC} from 'react';
import {TODO} from '../../../utils/TODO';
import GridTooltip from '../GridTooltip/';
import {UserColumn} from '../../../atoms/UserColumn/UserColumn';

interface Props {
  circularSenderUser: TODO;
}

export const CircularSenderCompanyUser: FC<Props> = ({circularSenderUser}) => {
  if (!circularSenderUser.company) {
    return <UserColumn userId={circularSenderUser.id} title={circularSenderUser.fullName} />;
  }

  return (
    <GridTooltip title={circularSenderUser.company.name}>
      <UserColumn userId={circularSenderUser.id} title={circularSenderUser.fullName} />
    </GridTooltip>
  );
};
