import {Location} from '../SearchPicker/Type';
import {LocationPicker} from './LocationPicker';
import {LocationTag} from './utils/LocationTag';
import {Popover, Tag} from 'antd';
import {addLocationToLocations} from './utils/addLocationToLocations';
import {removeLocationFromLocations} from './utils/removeLocationFromLocations';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {TODO} from '../../utils/TODO';
import styled from 'styled-components';

interface LocationsPickerProps {
  onSelect: (locations: Location[]) => void;
  value?: Location[];
  inputSize?: SizeType;
}

export const LocationsPickerPopover = (props: LocationsPickerProps) => {
  const locations = props.value ?? [];
  const updateLocations = (locations: Array<Location>) => {
    props.onSelect(locations);
  };

  const addLocation = (location: Location) => updateLocations(addLocationToLocations({location: location, locations}));

  const removeLocation = (location: Location): void =>
    updateLocations(removeLocationFromLocations({location, locations}));

  const hasLocation = locations.length > 0;

  const moreLocationsCount = locations.length - 1;
  return (
    <div>
      <Container>
        {hasLocation ? (
          <LocationTag location={locations[0]} onRemoveLocation={() => removeLocation(locations[0])} />
        ) : (
          <TagContainer>
            <Tag>No location selected</Tag>
          </TagContainer>
        )}
        {moreLocationsCount > 0 && (
          <Popover
            trigger={['click']}
            title={'Selected locations'}
            content={
              <PopupContainer>
                {locations.map(location => (
                  <LocationTag location={location} onRemoveLocation={() => removeLocation(location)} />
                ))}
              </PopupContainer>
            }>
            <TagContainer>
              <Tag style={{cursor: 'pointer'}}>+ {moreLocationsCount} other locations ...</Tag>
            </TagContainer>
          </Popover>
        )}
      </Container>
      <LocationPicker
        inputSize={props.inputSize}
        optionsFilter={searchHit => {
          // finds location duplicate which is already selected
          return !locations.some(location => {
            return location.type === (searchHit.source as TODO).type && location.id === (searchHit.source as TODO).id;
          });
        }}
        key={locations.length}
        autoFocus={true}
        defaultValue={''}
        onSelect={location => {
          addLocation(location);
        }}
      />
    </div>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const TagContainer = styled.div`
  display: flex;
  height: 22px;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 10px 0px;
`;
