import {ApiServiceEntry, makeApiService} from './ApiService';
import {TODO} from '../../utils/TODO';

export type Query = TODO;
type Item = TODO;

interface Data extends TODO {
  portfolio: {
    items: Item[];
  };
  market: {
    items: Item[];
  };
  items: Item[];
}

const entries: ApiServiceEntry[] = [
  {
    fn: 'search',
    route: '/api/search',
    method: 'GET',
    abortPrevCalls: true,
  },
  {
    fn: 'singleSearch',
    route: '/api/search',
    method: 'GET',
    abortPrevCalls: true,
  },
  {
    fn: 'distanceCalcSearch',
    route: '/api/search',
    method: 'GET',
  },
  {
    fn: 'preFillSearch',
    route: '/api/search/pre',
    method: 'GET',
  },
  {
    fn: 'preFillCharterer',
    route: '/api/search/pre',
    method: 'GET',
  },
  {
    fn: 'preFillFixtureVessels',
    route: '/api/search/pre',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'preFillFixtureCargoes',
    route: '/api/search/pre',
    method: 'GET',
    storeData: false,
  },
];

const searchAutocomplete = makeApiService(entries, {apiName: 'searchAutocomplete'});

export const reducers = searchAutocomplete.reducers;
export const actions = searchAutocomplete.actions;
export const actionTypes = searchAutocomplete.actionTypes;

export const preparePorts = (newData: TODO) => {
  return [
    {
      label: 'ports',
      options: Object.keys(newData.data.items).map(label => ({
        ...newData.data.items[label]._source,
        resultType: 'port',
        type: 'port',
        latitude: newData.data.items[label]._source.searouteLat
          ? newData.data.items[label]._source.searouteLat
          : newData.data.items[label]._source.lat,
        longitude: newData.data.items[label]._source.searouteLon
          ? newData.data.items[label]._source.searouteLon
          : newData.data.items[label]._source.lon,
        searchTitle: newData.data.items[label]._source.name,
      })),
    },
  ];
};

const handlePortfolioMarketSuccess = (data: Data, label: string) => {
  const isVessel = !!data.portfolio.items[0]?._index.includes('seabo_vessels');
  return [
    {
      label: isVessel ? 'My Fleet' : 'My Cargoes',
      options:
        data.portfolio && data.portfolio.items
          ? data.portfolio.items.map(p => ({id: p._id, ...p._source, highlight: p.highlight}))
          : [],
    },
    {
      label: 'Market ' + label,
      options:
        data.market && data.market.items
          ? data.market.items.map(p => ({id: p._id, ...p._source, highlight: p.highlight}))
          : [],
    },
  ];
};

export const AutocompleteActions = {
  searchPorts: (query: Query) =>
    actions.distanceCalcSearch({
      queryParams: {query, indexName: 'ports'},
      transformers: {SUCCESS: (data: Data) => ({searchData: preparePorts(data), searchTerm: data.data.searchTerm})},
    }),
  preFillSearch: (indexName: TODO, page = 1, pageSize = 10) =>
    actions.preFillSearch({queryParams: {indexName, page, pageSize}}),
  preFillCharterer: (page = 1, pageSize = 10) =>
    actions.preFillCharterer({queryParams: {indexName: 'charterer', page, pageSize}}),
  searchCountries: (query: Query) =>
    actions.singleSearch({
      queryParams: {query, indexName: 'location_country', pageIndex: 1, pageSize: 5},
      transformers: {SUCCESS: ({data}: {data: Data}) => data.items && data.items.map(p => ({id: p._id, ...p._source}))},
    }),
  searchDesignTypes: (query: Query) =>
    actions.singleSearch({
      queryParams: {query, indexName: 'design_type', pageIndex: 1, pageSize: 10},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => data.items && data.items.map(p => ({value: p.slug, label: p.name})),
      },
    }),
  searchCharterer: (query: Query) =>
    actions.singleSearch({
      queryParams: {query, indexName: 'charterer', pageIndex: 1, pageSize: 10},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => data.items && data.items.map(p => ({value: p.slug, label: p.name})),
      },
    }),
  searchPortfolioVessels: (query: Query) =>
    actions.singleSearch({
      queryParams: {query, indexName: 'vessels_portfolio', pageIndex: 1, pageSize: 10},
      transformers: {
        SUCCESS: ({data}: {data: Data}) =>
          data.items && data.items.map(({_source, highlight}) => ({..._source, highlight})),
      },
    }),
  preFillSearchPortfolioVessels: () =>
    actions.preFillSearch({
      queryParams: {indexName: 'vessels_portfolio'},
      transformers: {
        SUCCESS: ({data}: {data: Data}) =>
          data.items && data.items.map(({_source, highlight}) => ({..._source, highlight})),
      },
    }),
  preFillSearchVessels: () =>
    actions.preFillFixtureVessels({
      queryParams: {indexName: 'vessels'},
      transformers: {SUCCESS: ({data}: {data: Data}) => handlePortfolioMarketSuccess(data, 'vessels')},
    }),
  preFillSearchCargoes: () =>
    actions.preFillFixtureCargoes({
      queryParams: {indexName: 'cargoes', pageSize: 6},
      transformers: {SUCCESS: ({data}: {data: Data}) => handlePortfolioMarketSuccess(data, 'cargoes')},
    }),
  searchAllCargoes: (query: Query, queryParams: TODO) =>
    actions.singleSearch({
      queryParams: {query, indexName: 'cargoes', pageIndex: 1, pageSize: 5, ...queryParams},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => handlePortfolioMarketSuccess(data, 'cargoes'),
      },
    }),
  searchAllVessels: (query: Query, queryParams: TODO) =>
    actions.singleSearch({
      queryParams: {query, indexName: 'vessels', pageIndex: 1, pageSize: 10, ...queryParams},
      transformers: {
        SUCCESS: ({data}: {data: Data}) => handlePortfolioMarketSuccess(data, 'vessels'),
      },
    }),
  loadSingleSearchProjects: (query: Query, queryParams: TODO) =>
    actions.singleSearch({
      queryParams: {query, ...queryParams},
      transformers: {
        SUCCESS: (data: {data: Data}) =>
          data &&
          data.data &&
          data.data.items.map(d => ({
            type: d._source.aliasName === 'seabo_vessels' ? 'vessel' : 'cargo',
            ...d._source,
            highlight: d.highlight,
          })),
      },
    }),
};

export default AutocompleteActions;
