export const getInitialsFromFullName = (fullName: string): string => {
  const names = fullName
    .toUpperCase()
    .split(' ')
    .map(name => name.trim())
    .filter(name => name !== '');
  if (names.length === 0) {
    return '-';
  }
  const firstName = names[0];
  if (names.length === 1) {
    return firstName[0];
  }
  const lastName = names[names.length - 1];
  const initials = `${firstName[0]}${lastName[0]}`;
  return initials;
};
