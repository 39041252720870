import classNames from 'classnames';
import Loading from '../../atoms/Loading';
import React, {FC} from 'react';

interface Props {
  className?: string;
  loading?: boolean;
  style?: React.CSSProperties;
}

const LoadingComponent: FC<Props> = ({className, loading, style}) => (
  <div data-testid="LoadingComponent" className={classNames('-loading', {'-active': loading}, className)} style={style}>
    <div className="-loading-inner">
      <Loading />
    </div>
  </div>
);

export default LoadingComponent;
