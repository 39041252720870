import {Statistic} from 'antd';
import {FC} from 'react';
import {StatCard} from '../screens/CongestionScreens/sharedComponents/StatCard';

export const NumberStatistics: FC<{value: number; title: string; suffix?: string}> = ({value, title, suffix}) => {
  return (
    <StatCard>
      <Statistic title={title} value={value} suffix={suffix} precision={0} />
    </StatCard>
  );
};
