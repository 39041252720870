import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {default as isoWeek, default as isoWeekday} from 'dayjs/plugin/isoWeek';
import localizableFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

export const setupDayjs = () => {
  // https://day.js.org/docs/en/plugin/utc
  dayjs.extend(utc);

  // https://day.js.org/docs/en/plugin/timezone
  dayjs.extend(timezone);

  dayjs.extend(weekday);
  dayjs.extend(localizableFormat);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  dayjs.extend(isBetween);
  dayjs.extend(duration);
  dayjs.extend(isoWeekday);
  dayjs.extend(weekday);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(relativeTime);
  dayjs.extend(objectSupport);
  dayjs.extend(isoWeek);
  dayjs.extend(isSameOrBefore);
};
