import {useQuery} from '@tanstack/react-query';
import {tiedUpVesselsStatisticApi} from '../../api/node-backend/nodeBackendApis';
import sortBy from 'lodash/sortBy';

export const useTiedUpVesselsStatisticsQuery = () =>
  useQuery({
    queryKey: ['tiedUpStatisticApi.getTiedUpVesselsStatistics'],
    queryFn: async () => {
      const result = await tiedUpVesselsStatisticApi.getTiedUpVesselsStatistics();
      return sortBy(result.tradingAreas, 'name');
    },
  });
