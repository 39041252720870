import DateTime from '../../../../model/DateTime';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {sortingFnForStrings} from '../../utils/sortingFnForStrings';
import {TODO} from '../../../../utils/TODO';

export const aisVesselStatusColumn: CustomColumnDef<TODO> = {
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        AIS last update
        <br />
        AIS status
      </div>
    </div>
  ),
  id: 'postime',
  accessorKey: 'postime',
  cell: info => info.getValue(),
  accessorFn: (row: $TSFixMe) => (
    <div data-sort={row.statusText}>
      <div>{row.postime ? DateTime.fromServerResponse(row.postime).getFormattedDateTime() : '-'}</div>
      <div>{row.statusText || '-'}</div>
    </div>
  ),
  minWidth: 4,
  sortingFn: (a, b) => {
    return sortingFnForStrings(a.original.postime, b.original.postime);
  },
  enableSorting: true,
};
