import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as FixtureTypeDefinition from './FixtureType/FixtureTypeDefinition';
import * as FixtureVesselDefinition from './FixtureVessel/FixtureVesselDefinition';
import * as FixtureVesselDetailsDefinition from './FixtureVesselDetails/FixtureVesselDetailsDefinition';
import * as FixtureCargoDefinition from './FixtureCargo/FixtureCargoDefinition';
import * as FixtureOptionsDefinition from './FixtureOptions/FixtureOptionsDefinition';
import * as FixtureStationsDefinition from './FixtureLocationLaycan/FixtureLocationLaycanDefinition';
import * as FixtureDateDefinition from './FixtureDate/FixtureDateDefinition';
import * as LastUpdatedDefinition from './UpdatedAt/UpdatedAtDefinition';

export const VesselFixturesFilterBranchDefinitions = makeFilterBranchDefinitions([
  FixtureTypeDefinition.filterBranchDefinition,
  FixtureVesselDefinition.filterBranchDefinition,
  FixtureVesselDetailsDefinition.filterBranchDefinition,
  FixtureCargoDefinition.filterBranchDefinition,
  FixtureOptionsDefinition.filterBranchDefinition,
  FixtureStationsDefinition.filterBranchDefinition,
  FixtureDateDefinition.filterBranchDefinition,
  LastUpdatedDefinition.filterBranchDefinition,
]);

export type VesselFixturesInternalFilter = Partial<FilterInternalType<typeof VesselFixturesFilterBranchDefinitions>>;
export type VesselFixturesDatabaseFilter = Partial<FilterDatabaseType<typeof VesselFixturesFilterBranchDefinitions>>;
