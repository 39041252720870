import styled, {css} from 'styled-components';
import {Row, Divider} from 'antd';
import {SeaboLogoWithText} from '../SeaboLogo/SeaboLogo';

type FooterType = {
  type?: 'full' | 'condensed';
};

export const FullPageFooter: React.FC<FooterType> = ({type = 'full'}) => {
  const year = new Date().getFullYear();
  return (
    <StyledFooter type={type} className="FullPageFooter">
      {type === 'full' && <Divider />}

      <RowStyled justify="space-between" align="middle">
        <SeaboLogoStyled />
        <Text>
          ©{year} All Rights Reserved. seabo® is a registered trademark of <em>Next Logistics Ventures GmbH</em>.
        </Text>

        <Menu>
          <MenuItem href="https://www.seabo.com/contact">
            <Text>Contact Us</Text>
          </MenuItem>
          <MenuItem href="https://www.seabo.com/privacy-policy">
            <Text>Privacy Policy</Text>
          </MenuItem>
          <MenuItem href="https://www.seabo.com/terms-and-conditions">
            <Text>Terms & Conditions</Text>
          </MenuItem>
        </Menu>
      </RowStyled>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer<FooterType>`
  margin-top: auto;
  padding: 22px 0 38px;
  background-color: transparent;
  z-index: 1;

  ${({type}) =>
    type === 'condensed' &&
    css`
      padding-left: 10vw;
      padding-right: 10vw;
      background-color: var(--color-white);
    `}
`;

const Text = styled.span`
  color: var(--color-gray-2);
  font-family: 'Akkurat';
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 24px;

  em {
    font-weight: bold;
    font-style: normal;
    color: var(--color-black);
  }
`;

const RowStyled = styled(Row)`
  height: 46px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const MenuItem = styled.a`
  &:hover {
    color: var(--color-azure);
  }
`;

const SeaboLogoStyled = styled(SeaboLogoWithText)`
  width: 100px;
  display: block;
  color: var(--color-gray-2);
  fill: var(--color-gray-2);
`;
