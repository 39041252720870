import {ConsumptionFuelType} from './getUsedFuelType';
import {CostInput} from '../CostInformation/CostTypes';
import {assertUnreachable} from '../../../../utils/assert';

export const getBunkerPriceByFuelType = ({
  fuelType,
  costInput,
}: {
  fuelType: ConsumptionFuelType;
  costInput: CostInput;
}): number => {
  switch (fuelType) {
    case 'ifo':
      return costInput.costIfoPerMts ?? 0;
    case 'mgo':
      return costInput.costMgoPerMts ?? 0;
    case 'vlsfo':
      return costInput.costVlsfoPerMts ?? 0;

    default:
      assertUnreachable(fuelType);
  }
};
