import {CSSProperties, FC, MouseEvent} from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import {withMarginMapping, MarginMappingProps} from '../../components/withMargin';
import {cleanIdentifier} from '../../utils/helper';
import './style.scss';
import styled, {css} from 'styled-components';

export interface FilterBtnProps extends MarginMappingProps {
  label: string;
  isApplied?: boolean;
  active?: boolean;
  dark?: boolean;
  save?: boolean;
  showIcon?: boolean;
  dataTestid?: string;
  dataCy?: string;
  disabled?: boolean;
  styleOverride?: CSSProperties;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const FilterBtn: FC<FilterBtnProps> = ({
  label,
  isApplied,
  active,
  onClick,
  dark,
  save,
  dataTestid,
  dataCy,
  showIcon = true,
  disabled = false,
  styleOverride,
  ...rest
}) => {
  const [spaceClasses, otherProps] = withMarginMapping(rest);
  return (
    <StyledButton
      disabled={disabled}
      style={styleOverride}
      id={cleanIdentifier(label, 'filter-btn')}
      className={classNames(
        'filter-btn',
        {
          'filter-btn--active': active,
          'filter-btn--filled': isApplied,
          'filter-btn--dark': dark,
          'filter-btn--save': save,
        },
        spaceClasses
      )}
      data-testid={dataTestid}
      data-cy={dataCy}
      onClick={onClick}
      {...otherProps}>
      <span className="filter-btn__label">{label}</span>{' '}
      {showIcon && !disabled && <Icon type={active ? 'angle-up' : 'angle-down'} />}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  ${props => {
    if (props.disabled) {
      return css`
        cursor: not-allowed !important;
      `;
    }
  }}
`;

export default FilterBtn;
