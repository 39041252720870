/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PortfolioGroupListDataItems,
  PortfolioGroupListDataItemsFromJSON,
  PortfolioGroupListDataItemsFromJSONTyped,
  PortfolioGroupListDataItemsToJSON,
} from './';

/**
 *
 * @export
 * @interface PortfolioGroupListData
 */
export interface PortfolioGroupListData {
  /**
   *
   * @type {number}
   * @memberof PortfolioGroupListData
   */
  totalItems: number;
  /**
   *
   * @type {Array<PortfolioGroupListDataItems>}
   * @memberof PortfolioGroupListData
   */
  items: Array<PortfolioGroupListDataItems>;
}

export function PortfolioGroupListDataFromJSON(json: any): PortfolioGroupListData {
  return PortfolioGroupListDataFromJSONTyped(json, false);
}

export function PortfolioGroupListDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioGroupListData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalItems: json['totalItems'],
    items: (json['items'] as Array<any>).map(PortfolioGroupListDataItemsFromJSON),
  };
}

export function PortfolioGroupListDataToJSON(value?: PortfolioGroupListData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalItems: value.totalItems,
    items: (value.items as Array<any>).map(PortfolioGroupListDataItemsToJSON),
  };
}
