import {QueryClient, useQuery} from '@tanstack/react-query';
import {TourId} from './TourDefinition';
import {getFetchHeaders} from '../../api/utils/api';
import {fetchJSON} from '../../api/utils/fetchJSON';
import {useSelector} from '../../redux/react-redux';
import {getUser} from '../../redux/selectors';

export const TOUR_QUERY_KEY = (tourId: TourId) => ['tourUserData', tourId];

// "" is the legacy PHP (+JSON.parse) way of saying that the user has done the tour
type ToursResponse =
  | ''
  | {
      data: {
        items: Tour[];
      };
    };

type Tour = {
  id: number;
  title: string;
  steps: [];
  userData: TourUserData;
  maxSteps: number;
};

export type TourUserData = {
  tourId: number;
  userId: number;
  stepsDone: number;
  hasDone: boolean;
};

export const useTourUserDataQuery = (tourId: TourId) => {
  const userId = useSelector(getUser)?.id;
  return useQuery({
    queryKey: TOUR_QUERY_KEY(tourId),
    queryFn: async () => {
      // This PHP API is not yet OpenAPI generated, therefore we use fetchJSON and getFetchHeaders
      const response = await fetchJSON<ToursResponse>(`/api/tour/${tourId}`, {headers: getFetchHeaders()});

      // If the user has done the tour, the response is an empty string (see above)
      // We put on a little make-up here to make it look like a proper response
      if (response === '') {
        return {
          tourId,
          userId,
          stepsDone: 0,
          hasDone: true,
        } as TourUserData;
      }

      const userData = response.data.items[0].userData;
      return userData;
    },
  });
};

export const invalidateTourUserDataQuery = async (tourId: TourId, queryClient: QueryClient) => {
  return await queryClient.invalidateQueries({
    queryKey: TOUR_QUERY_KEY(tourId),
  });
};
