import capitalize from 'lodash/capitalize';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {isNaNTS, parseIntTS} from '../../../../utils/parseNumberTS';
import {CreateNegotiationFormValues} from './types';

export const formSteps = [{label: 'Enter information'}, {label: 'Create offer terms'}, {label: 'Review'}];

export const defaultFormValues: CreateNegotiationFormValues = {
  title: '',
  terms: [],
  counterpart: {
    name: '',
    email: '',
    company: '',
  },
  vessel: {
    id: undefined,
    name: undefined,
    imo: undefined,
    type: undefined,
    size: undefined,
    sizeUnit: 'DWT',
    dateOpen: undefined,
    locationOpenLabel: undefined,
    locationOpenCountryCode: undefined,
  },
  cargo: {
    id: undefined,
    contractType: 'vc',
    commodityCategory: undefined,
    commodityType: undefined,
    quantityMin: undefined,
    quantityMax: undefined,
    quantityUnit: 'MT',
    laycan: [undefined, undefined],
    loadingLabel: undefined,
    loadingCountryCode: undefined,
    dischargeLabel: undefined,
    dischargeCountryCode: undefined,
  },
};

// To comply both with AntD's Form and the NegotiationCreate API, we can only use number as id
// We therefore convert everything else (i.e. NaN) to undefined
export const getValidNumberOrUndefined = (number: number | string | null | undefined) =>
  !!number && !isNaNTS(number) ? parseIntTS(number) : undefined;

export const getPresetTitle = (presetValues: CreateNegotiationFormValues) => {
  const laycanLabel =
    presetValues.cargo?.laycan?.filter(Boolean).length === 2
      ? `${presetValues.cargo.laycan[0]?.format(DateTimeFormat.Date)} - ${presetValues.cargo.laycan[1]?.format(
          DateTimeFormat.Date
        )}`
      : '';

  const stationsLabel =
    presetValues.cargo?.loadingLabel && presetValues.cargo?.dischargeLabel
      ? `${presetValues.cargo.loadingLabel} -> ${presetValues.cargo.dischargeLabel}`
      : `${presetValues.cargo?.loadingLabel || presetValues.cargo?.dischargeLabel || ''}`;

  const vesselLabel = presetValues.vessel?.name ? `${presetValues.vessel.name}` : '';

  const commodityLabel = presetValues.cargo?.commodityType ? `${capitalize(presetValues.cargo.commodityType)}` : '';

  return [laycanLabel, stationsLabel, vesselLabel, commodityLabel].filter(Boolean).join(', ');
};
