import {FC} from 'react';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import Select from '../../../../atoms/Select/Select';
import {VesselStatus, vesselStatusOptions} from '../../../../screens/CargoVesselForm/helper';
import {NamedValue} from '../../../FilterProvider/Filters/FilterConversion/utils/NamedValue';

export const StatusFormItem: FC<{
  onChange: (value: VesselStatus) => void;
  value: VesselStatus;
}> = ({onChange, value}) => {
  return (
    <LabelWrapper label={'Status'} flex>
      <Select<NamedValue<VesselStatus>>
        id={'portfolio-location-edit-status'}
        name={'portfolio-location-edit-status'}
        isSearchable={false}
        value={{value, name: vesselStatusOptions[value]}}
        components={{
          DropdownIndicator: ArrowIndicator,
        }}
        options={(Object.keys(vesselStatusOptions) as VesselStatus[]).map(value => ({
          value,
          name: vesselStatusOptions[value],
        }))}
        onChange={option => {
          onChange(option!.value);
        }}
        getOptionLabel={(option: $TSFixMe) => option.name}
        getOptionValue={(option: $TSFixMe) => option.value}
      />
    </LabelWrapper>
  );
};
