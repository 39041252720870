/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  TiedUpVesselsStatisticResponse,
  TiedUpVesselsStatisticResponseFromJSON,
  TiedUpVesselsStatisticResponseToJSON,
} from '../models';

/**
 *
 */
export class TiedUpVesselsStatisticApi extends runtime.BaseAPI {
  /**
   * Returns a statistic for the tied up vessels of any trading area.
   */
  async getTiedUpVesselsStatisticsRaw(): Promise<runtime.ApiResponse<TiedUpVesselsStatisticResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/tied-up-vessels/statistics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => TiedUpVesselsStatisticResponseFromJSON(jsonValue));
  }

  /**
   * Returns a statistic for the tied up vessels of any trading area.
   */
  async getTiedUpVesselsStatistics(): Promise<TiedUpVesselsStatisticResponse> {
    const response = await this.getTiedUpVesselsStatisticsRaw();
    return await response.value();
  }
}
