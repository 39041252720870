import React, {FC} from 'react';
import {useQuery} from '@tanstack/react-query';
import {getTradingAreas} from '../../../../redux/ApiService/MapService/MapService';
import {Coordinates} from '../../../../utils/Coordinates';
import {useDispatch} from '../../../../redux/react-redux';
import styled from 'styled-components';
import {Tag} from 'antd';
import {SelectionContainer} from '../sharedComponents/SelectionContainer';
import {TradingArea} from '../../../../redux/ApiService/MapService/GetTradingAreasResponse';

export const TradingAreaSelectorTags: FC<{
  selectedTradingAreaCodes: string[];
  onChangeItem: (tradingAreaCode: string) => void;
}> = ({selectedTradingAreaCodes, onChangeItem}) => {
  const dispatch = useDispatch();

  const tradingAreasQuery = useQuery({
    queryKey: ['tradingAreas'],
    queryFn: async () => {
      const response = await dispatch(getTradingAreas());
      return response.items as (TradingArea & {polygon: Coordinates[][]})[];
    },
  });

  if (!tradingAreasQuery.isSuccess) {
    return null;
  }

  const allTradingAreaCodes = tradingAreasQuery.data?.map(tradingArea => tradingArea.code).sort() ?? [];

  const isSelected = (code: string) => selectedTradingAreaCodes.some(tradingArea => tradingArea === code);

  return (
    <SelectionContainer>
      {allTradingAreaCodes.map(code => (
        <StyledTag
          key={code}
          data-testid={isSelected(code) ? 'TradingAreaCheckboxButtonChecked' : 'TradingAreaCheckboxButton'}
          color={isSelected(code) ? 'blue' : 'default'}
          onClick={() => onChangeItem(code)}>
          {code}
        </StyledTag>
      ))}
    </SelectionContainer>
  );
};

const StyledTag = styled(Tag)`
  width: calc(15% - 20px);
  text-align: center;

  &.ant-tag-default {
    background: var(--color-gray-5);
  }
  &.ant-tag-blue {
    background: var(--color-blue-bg);
  }

  @media screen and (max-width: 1920px) {
    width: calc(20% - 30px);
  }
`;
