import {useDraftStatsMapQuery} from '../utils/vessel/useDraftStatsMapQuery';
import {getIsBallast} from '../utils/vessel/getIsBallast';
import {useQuery} from '@tanstack/react-query';
import {assert} from '../utils/assert';
import {DraftItem} from '../utils/vessel/useDraftStatsQuery';

export const useIsBallastQuery = ({imo, draft}: {imo?: number; draft?: number}) => {
  const draftStatsMapQuery = useDraftStatsMapQuery();
  return useQuery({
    queryKey: ['isBallast', imo],
    queryFn: () => {
      assert(imo, 'imo is required');
      assert(draft, 'draft is required');
      const draftsMap = draftStatsMapQuery.data;
      assert(draftsMap, 'draftsMap is required');

      const draftItem: DraftItem | undefined = draftsMap[imo] ?? null;

      if (!draftItem) {
        return null;
      }

      return getIsBallast({vessel: {d: draft! * 10}, draftItem}) ?? null;
    },
    enabled: draftStatsMapQuery.isSuccess && !!imo && !!draft,
  });
};
