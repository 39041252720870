import {RootState} from './store';
import {SidebarMode} from './Sidebar';
import {CompanyState} from './Company';
import User from '../model/User';
import {getSubscriptionPlanNameFromSlug, planLimits} from '../utils/Plans';
import {createSelector} from 'reselect';
import {assert} from '../utils/assert';
import {SubscriptionPlan, SubscriptionPlans} from './ApiService/CompanyService/Company';
import {FeaturePermission} from '../api/symfony/generated';
import dayjs from 'dayjs';

/**
 * Redux selectors.
 *
 * Can be used via useSelector().
 */

export const getSidebarMode = (state: RootState): SidebarMode => state.sidebar.mode;

export const getUser = (state: RootState): User | null => state.user;
export const getUserLoggedIn = (state: RootState): boolean => !!state.user;
export const getIsPayingCustomer = (state: RootState): boolean => state.company.data.isPayingCustomer;
export const getUserPermissions = (state: RootState): FeaturePermission[] => state.user?.payload.permissions ?? [];

export const getSubscriptionPlan = (state: RootState): SubscriptionPlan => state.company.data.subscriptionPlan;
export const getSubscriptionPlanName = createSelector(getSubscriptionPlan, getSubscriptionPlanNameFromSlug);

export const getIsSubscribed = (state: RootState): boolean =>
  state.company.data.subscriptionPlan !== SubscriptionPlans.Free;

export const getHasProPlan = (state: RootState): boolean =>
  state.company.data.subscriptionPlan === SubscriptionPlans.Pro;
export const getHasFreePlan = (state: RootState): boolean =>
  state.company.data.subscriptionPlan === SubscriptionPlans.Free;

export const getIsInPromotionPhase = (state: RootState): boolean => {
  const {promotionUntil} = state.company.data;
  return promotionUntil === null ? false : dayjs().isBefore(promotionUntil);
};

export const getPromotionDaysLeft = (state: RootState): number => {
  const {promotionUntil} = state.company.data;
  return promotionUntil === null ? 30 : Math.max(0, dayjs.duration(dayjs(promotionUntil).diff(dayjs())).asDays());
};

export const getSubscriptionPlanLimits = createSelector(getSubscriptionPlan, (subscriptionPlan: SubscriptionPlan) => {
  assert(planLimits[subscriptionPlan], `Subscription plan ${subscriptionPlan} not found`);
  return planLimits[subscriptionPlan];
});

export const getCompany = (state: RootState): CompanyState => state.company;

export const getWorkspaceId = (state: RootState) => {
  assert(state.user, 'There must be a user logged in');
  return state.user.workspace.id;
};
