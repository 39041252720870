export interface Report {
  id: string;
  label: string;
  filename: string;
}

export interface ReportDisableable extends Report {
  disabled?: boolean;
}

export const AllReports: Report[] = [
  {
    label: 'Full Report',
    id: 'fixture-list-full',
    filename: 'Fixture-list-full.xlsx',
  },
  {
    label: 'Internal Report',
    id: 'fixture-list-internal',
    filename: 'Fixture-list-internal.xlsx',
  },
  {
    label: 'External Report',
    id: 'fixture-list-external',
    filename: 'Fixture-list-external.xlsx',
  },
  {
    label: 'S&P Report',
    id: 'sp-report',
    filename: 'S-P-report.xlsx',
  },
];
