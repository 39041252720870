import {FormInstance, Input, Typography} from 'antd';
import {FC} from 'react';
import {FormSection, FormSectionBody, FormSectionHeader, FormSectionItem} from './shared';

const {Title} = Typography;

type NegotiationFormCounterpartSectionProps = {
  form: FormInstance;
};

export const NegotiationFormCounterpartSection: FC<NegotiationFormCounterpartSectionProps> = ({form}) => {
  return (
    <FormSection>
      <FormSectionHeader>
        <Title level={4}>Counterpart</Title>
      </FormSectionHeader>
      <FormSectionBody>
        <FormSectionItem
          $span={12}
          name={['counterpart', 'name']}
          label="Counterpart's name"
          rules={[{required: true, message: "Your counterpart's name is required"}]}>
          <Input placeholder="Counterpart's name" />
        </FormSectionItem>
        <FormSectionItem
          $span={12}
          name={['counterpart', 'company']}
          label="Counterpart's company"
          rules={[{required: true, message: "Your counterpart's company is required"}]}>
          <Input autoComplete="company" placeholder="Counterpart's company" />
        </FormSectionItem>
        <FormSectionItem
          $span={12}
          name={['counterpart', 'email']}
          label="Counterpart's email"
          rules={[
            {
              required: true,
              message: "Your counterpart's email is required",
            },
            {
              type: 'email',
              message: 'Please use a valid email address',
            },
          ]}>
          <Input autoComplete="email" type="email" placeholder="Counterpart's email" />
        </FormSectionItem>
        <FormSectionItem
          $span={12}
          name={['counterpart', 'email-repeat']}
          label="Repeat counterpart's email"
          rules={[
            {
              required: true,
              message: 'Please repeat the counterpart email',
            },
            {
              validator: (_, value, callback) => {
                if (!value || !form.getFieldValue(['counterpart', 'email'])) {
                  return callback();
                }
                if (value !== form.getFieldValue(['counterpart', 'email'])) {
                  return callback('The emails do not match');
                }
                return callback();
              },
            },
          ]}>
          <Input
            autoComplete="email"
            type="email"
            placeholder="Repeat counterpart's email"
            disabled={!form.getFieldValue(['counterpart', 'email'])}
          />
        </FormSectionItem>
      </FormSectionBody>
    </FormSection>
  );
};
