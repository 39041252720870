import {FC} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const PromoBanner: FC = () => (
  <PromoLink to={`/subscription`}>
    <PromoClaim>Get full access to seabo</PromoClaim>
    <PromoCTASmall>Premium upgrade</PromoCTASmall>
    <PromoCTA>Upgrade to Pro</PromoCTA>
  </PromoLink>
);

const PromoCTA = styled.p`
  width: 100%;
  margin: 0;
  padding: 2px;
  background: var(--color-upgrade);
  border: 0;
  color: var(--color-white);
  font-weight: bold;
  text-transform: uppercase;
`;

const PromoCTASmall = styled(PromoCTA)`
  display: none;
  padding: 2px;
  line-height: 1.125;
`;

const PromoClaim = styled.p`
  margin: 0;
  padding: 2px;
  background: var(--color-white);
  color: var(--color-azure);
`;

const PromoLink = styled(Link)`
  display: block;
  height: 48px;
  margin: 10px;
  background: var(--color-upgrade);
  border: 2px solid var(--color-upgrade);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);
  font-size: var(--font-size-sm);
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;

  transition:
    opacity 0.1s,
    height 0.1s;

  &:hover {
    opacity: 0.8;
  }

  .sidebarMode-narrow & {
    height: 30px;
    font-size: var(--font-size-xs);

    ${PromoClaim}, ${PromoCTA} {
      display: none;
    }
    ${PromoCTASmall} {
      display: block;
    }
  }
`;
