import {useEffect} from 'react';
import {coordAll} from '@turf/meta';
import {PORT_CATEGORY_NAMES, PORT_LAYER_NAMES, PORT_LAYERS} from './const';
import {Coordinates} from '../../utils/Coordinates';
import {MapApi} from './MapApi';
import {Feature, LineString} from '@turf/helpers';
import {RGBColor} from '../../utils/deck.gl/deck.gl-types';

export const getOptionById = (id: $TSFixMe, mapOptions: $TSFixMe) => {
  let option = mapOptions.find((o: $TSFixMe) => o.id === id);
  if (!option) {
    for (const entry of mapOptions) {
      if (entry.sections) {
        option = entry.sections.flat(1).find((o: $TSFixMe) => o.id === id);
        if (option) {
          break;
        }
      }
    }
  }
  return option;
};

export const getVesselColor = (v: $TSFixMe) => {
  const alpha = (v.o || 1) * 255;
  if (typeof v.type === 'string') {
    switch (v.type.toLowerCase()) {
      case 'bulker':
        return [98, 211, 136, alpha];
      case 'container':
        return [64, 162, 255, alpha];
      case 'tanker':
        return [240, 0, 70, alpha];
      case 'mpp':
        return [228, 234, 155, alpha];
    }
  }
  return [142, 142, 142, alpha];
};

const getCargoLabel = (amount: number) => 'Cargo' + (amount === 1 ? '' : 'es');
const getVesselLabel = (amount: number) => 'Vessel' + (amount === 1 ? '' : 's');

const getCargoAmountLabel = (amount: number) => (amount > 0 ? `${amount} ${getCargoLabel(amount)}` : '');
const getVesselAmountLabel = (amount: number) => (amount > 0 ? `${amount} ${getVesselLabel(amount)}` : '');

const getItemAmountLabel = (amountVessels: number, amountCargoes: number, isReduced?: boolean) => {
  switch (true) {
    case amountVessels > 0 && amountCargoes > 0:
      return `${getCargoAmountLabel(amountCargoes)} ${isReduced ? '&' : 'and'} ${getVesselAmountLabel(amountVessels)}`;
    case amountVessels > 0:
      return getVesselAmountLabel(amountVessels);
    case amountCargoes > 0:
      return getCargoAmountLabel(amountCargoes);
    default:
      return 'No Items';
  }
};

export const getPortlessSummary = (name: string, amountVessels: number, amountCargoes: number, isReduced?: boolean) => {
  return isReduced
    ? `${getItemAmountLabel(amountVessels, amountCargoes, true)} w/o allocation`
    : `${getItemAmountLabel(amountVessels, amountCargoes)} without allocation found in ${name}`;
};

// Data to be used by the LineLayer
export const getGeoJsonLine = <Properties>(
  coordinates: Coordinates[],
  properties: Properties
): Feature<LineString, Properties> => ({
  type: 'Feature',
  properties,

  geometry: {
    type: 'LineString',
    coordinates: coordinates,
  },
});

export type CargoMenuItemKey = 'vc' | 'tct' | 'pc' | 'bc';

export const cargoMenu: Record<CargoMenuItemKey, string> = {
  vc: 'Voyage charter',
  tct: 'Time charter trip',
  pc: 'Period charter',
  bc: 'Bareboat charter',
};

export const commodityCategories = {
  agricultural: 'Agricultural Products',
  ash: 'Ash',
  chemical: 'Chemical',
  coal: 'Coal',
  concentrate: 'Concentrate',
  feedstock: 'Feedstuff',
  fertilizer: 'Fertilizer',
  grain: 'Grain',
  'iron-ore': 'Iron Ore Products',
  metal: 'Metal',
  minerals: 'Minerals',
  ore: 'Ore',
  petcoke: 'Petcoke',
  salt: 'Salt',
  scrap: 'Scrap',
  slag: 'Slag',
  'steels-generals': 'Steels / Generals',
  'stone-aggregate': 'Aggregate',
  sugar: 'Sugar',
  sulphur: 'Sulphur',
  vehicles: 'Vehicles',
  'forest-products': 'Wood Products',
  other: 'Other',
};

export function hexToRgb(hex: string): RGBColor | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return null;
  }
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return [r, g, b];
}

export const useFitToPort = (map: MapApi, port: $TSFixMe) => {
  useEffect(() => {
    if (!map.state.mapReady) {
      return;
    }
    if (!port) {
      return;
    }

    const coords = [
      ...(port.pois
        ? port.pois.reduce((coords: $TSFixMe, poi: $TSFixMe) => {
            if (poi.polygon?.length > 0) {
              return [...coords, ...poi.polygon[0]];
            }
            return coords;
          }, [])
        : []),
      ...(port.polygon?.length > 0 ? port.polygon[0] : []),
    ];

    if (coords.length > 0) {
      map.fitBounds(coords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [port, map.state.mapReady]);
};

export const useFitToGeoJson = (map: MapApi, geoJson: $TSFixMe) => {
  useEffect(() => {
    if (!map.state.mapReady) {
      return;
    }

    if (!geoJson || !Array.isArray(geoJson.features)) {
      return;
    }

    const coords = coordAll(geoJson) as Coordinates[];

    if (coords.length > 0) {
      map.fitBounds(coords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoJson, map.state.mapReady]);
};

export const getPortLayerCategoryName = (c: $TSFixMe) => PORT_CATEGORY_NAMES[c] ?? c;

export const getPortLayerLabel = ({type, category, name}: $TSFixMe) => {
  let label = PORT_LAYER_NAMES[type];
  if (type === PORT_LAYERS.TERMINAL) {
    label += ` (${getPortLayerCategoryName(category)})`;
  }
  if (name) {
    label += ` "${name}"`;
  }
  return label;
};
