import React, {Fragment} from 'react';
import NumberRangeInput from '../../../RangeInput/NumberRangeInput';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import {hasError} from '../../../../utils/helper';
import {filterBranchDefinition, DurationUnits} from './DurationDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {LabelWrapper} from '../../../../atoms/LabelWrapper';
import Select from '../../../../atoms/Select/Select';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const Duration = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <Fragment>
        <div className="container">
          <div className="row bottom-fix">
            <div className="scol-12">
              <LabelWrapper>
                <NumberRangeInput
                  flex
                  label={'Range'}
                  id={'filter-range'}
                  fromValue={values.rangeFrom}
                  onFromChange={value => handleChange('rangeFrom', value)}
                  toValue={values.rangeTo}
                  onToChange={value => handleChange('rangeTo', value)}
                  allowEqualMinMax
                  fromProps={{
                    min: 0,
                    max: 99,
                    placeholder: 'min',
                    hasError: hasError('rangeFrom', validations),
                  }}
                  toProps={{
                    min: 0,
                    max: 99,
                    placeholder: 'max',
                    hasError: hasError('rangeTo', validations),
                  }}
                />
                <Select
                  isClearable={false}
                  id={'filter-range-unit-select'}
                  value={values.unit}
                  onChange={value => handleChange('unit', value)}
                  options={DurationUnits}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.value}
                  components={{
                    DropdownIndicator: ArrowIndicator,
                  }}
                />
              </LabelWrapper>
            </div>
          </div>
        </div>
      </Fragment>
    )}
  </FilterButton>
);

export default Duration;
