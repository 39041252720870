import {FC} from 'react';
import {Space, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {Spacer} from '../../../atoms/Spacer/Spacer';
import {useSelector} from '../../../redux/react-redux';
import {getSubscriptionPlanName} from '../../../redux/selectors';
import {useSendCancelSubscriptionMail} from './utils';
import {StepContainer, NoThanksButton} from './shared';
import {modal} from '../../../components/Notification';

const {Title} = Typography;

export const CompanySubscriptionCancelScreen: FC = () => {
  const currentPlanTitle = useSelector(getSubscriptionPlanName);

  const sendCancelSubscriptionMail = useSendCancelSubscriptionMail();

  const onConfirmClick = async () => {
    const {destroy} = modal.confirm({
      title: 'Are you sure?',
      content: `You will lose access to all premium features after this payment period ends.`,
      okText: 'Unsubscribe',
      okButtonProps: {danger: true},
      onOk: () => {
        sendCancelSubscriptionMail();
        destroy();
      },
      cancelText: 'Keep subscription',
    });
  };

  return (
    <StepContainer data-testid="CompanySubscriptionCancel">
      <Title level={2}>We are sad you are cancelling your subscription!</Title>
      <Title level={4}>We will contact you shortly to confirm the end of your premium subscription.</Title>
      <Spacer height={48} />
      <Space direction="vertical">
        <NoThanksButton type={'primary'} danger onClick={onConfirmClick}>
          Yes, cancel my subscription
        </NoThanksButton>
        <Link to={'/subscription'}>
          <NoThanksButton type={'link'}>No, keep me subscribed to {currentPlanTitle}</NoThanksButton>
        </Link>
      </Space>
    </StepContainer>
  );
};
