import {useQuery} from '@tanstack/react-query';
import {portDisbursementAccountApi} from '../../../../../../../api/node-backend/nodeBackendApis';
import {PortDisbursementAccount} from '../../../../../../../api/node-backend/generated';
import {UseQueryOptions} from '../../../../../../../api/utils/ReactQuery';
import {wrapperForOnSuccessAndOnError} from '../../../../../../../queries/utils/wrapperForOnSuccessAndOnError';
import {isApiError} from '../../../../../../../api/utils/ApiError';
import dayjs from 'dayjs';

export const useGetPortDAQuery = ({
  portUNLoCode,
  imo,
  quantity,
  eta,
  options,
}: {
  portUNLoCode?: string;
  imo?: number;
  quantity?: number;
  eta?: string;
  options?: Omit<UseQueryOptions<PortDisbursementAccount>, 'enabled'> & {
    // It can extend the enabled condition.
    additionalEnabled?: boolean;
  };
}) =>
  useQuery({
    queryKey: ['portDisbursementAccountApi.getDisbursementAccount', eta, imo, quantity, portUNLoCode],
    queryFn: async () => {
      return await wrapperForOnSuccessAndOnError<PortDisbursementAccount>({
        queryFunction: () => {
          return portDisbursementAccountApi.getDisbursementAccount({
            eta: eta!,
            imo: imo!,
            quantity: quantity,
            portUNLoCode: portUNLoCode!,
          });
        },
        onError: (error: unknown) => {
          if (isApiError(error)) {
            if (error.status === 404) {
              return;
            }
            if (error.status === 400) {
              // That happens if the eta is in the past.
              // Need that to suppress the error popup evan if the user changes the "enabled"-condition.
              if (dayjs(eta).isBefore(dayjs())) {
                return;
              }
            }
          }
          throw error;
        },
      });
    },
    ...options,

    enabled: !!imo && !!portUNLoCode && !!eta && dayjs(eta).isAfter(dayjs()) && (options?.additionalEnabled ?? true),
  });
