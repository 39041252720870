/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The current draft of the vessel in meters
 * @export
 * @enum {string}
 */
export enum VesselCurrentSituationActivity {
  AtAnchorage = 'AT_ANCHORAGE',
  AtPier = 'AT_PIER',
  AtDryDock = 'AT_DRY_DOCK',
  Moored = 'MOORED',
  ManeuveringInPort = 'MANEUVERING_IN_PORT',
  SlowSteaming = 'SLOW_STEAMING',
  FullSteaming = 'FULL_STEAMING',
  Drifting = 'DRIFTING',
  Unknown = 'UNKNOWN',
}

export function VesselCurrentSituationActivityFromJSON(json: any): VesselCurrentSituationActivity {
  return VesselCurrentSituationActivityFromJSONTyped(json, false);
}

export function VesselCurrentSituationActivityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselCurrentSituationActivity {
  return json as VesselCurrentSituationActivity;
}

export function VesselCurrentSituationActivityToJSON(value?: VesselCurrentSituationActivity | null): any {
  return value as any;
}
