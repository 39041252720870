/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface TradeFlowPortActivityLocation
 */
export interface TradeFlowPortActivityLocation {
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivityLocation
   */
  code: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivityLocation
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivityLocation
   */
  countryCode: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivityLocation
   */
  countryName: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivityLocation
   */
  terminal: string | null;
}

export function TradeFlowPortActivityLocationFromJSON(json: any): TradeFlowPortActivityLocation {
  return TradeFlowPortActivityLocationFromJSONTyped(json, false);
}

export function TradeFlowPortActivityLocationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TradeFlowPortActivityLocation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
    name: json['name'],
    countryCode: json['countryCode'],
    countryName: json['countryName'],
    terminal: json['terminal'],
  };
}

export function TradeFlowPortActivityLocationToJSON(value?: TradeFlowPortActivityLocation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    name: value.name,
    countryCode: value.countryCode,
    countryName: value.countryName,
    terminal: value.terminal,
  };
}
