// /api/contrib/commodity-categories

import {makeApiService, ApiServiceEntry} from './ApiService';
import {TODO} from '../../utils/TODO';

const entries: ApiServiceEntry[] = [
  {
    fn: 'get',
    route: '/api/contrib/commodity-categories',
    method: 'GET',
  },
  {
    fn: 'getSpecific',
    route: '/api/contrib/commodity-names/{slug}',
    method: 'GET',
  },
];

const commodity = makeApiService(entries, {apiName: 'commodity'});

// wire up in Store
export const reducers = commodity.reducers;
// use these to Dispatch Actions
export const actions = commodity.actions;
// if you need The actionTypes in your custom reducer use these to match the types
// actionTypes.$fn.PENDING
// actionTypes.$fn.SUCCESS
// actionTypes.$fn.ERROR
// where fn is the string you provided as fn in the entries array
export const actionTypes = commodity.actionTypes;

export const CommodityActions = {
  get: () => actions.get({transformers: {SUCCESS: ({data}: {data: TODO}) => data.items}}),
  getSpecific: (slug: string) =>
    actions.getSpecific({
      params: {slug},
      transformers: {
        SUCCESS: (res: TODO) => {
          if (res) {
            return res.data.items;
          }
        },
        ERROR: () => {},
      },
    }),
};

export default CommodityActions;
