import {useEffect, useState} from 'react';
import {FilterButtonDumb, FilterButtonDumbProps} from './FilterButtonDumb';

type ArrayFilterProps<KeyType extends string> = Omit<
  FilterButtonDumbProps<Record<KeyType, boolean>>,
  'values' | 'isApplied' | 'isValid' | 'validationErrors' | 'onApply' | 'onChange' | 'onChangeRangePicker' | 'onReset'
> & {
  values: KeyType[];
  onApply: (values: KeyType[]) => void;
};

/**
 * Wraps the Filter component to make it usable with array filters.
 * @see `/frontend/src/atoms/Filter/Filter.tsx`
 */
export const ArrayFilter = <KeyType extends string>({values, onApply, ...props}: ArrayFilterProps<KeyType>) => {
  const [tempValues, setTempValues] = useState(arrayFilterToObject(values));

  useEffect(() => {
    setTempValues(arrayFilterToObject(values));
  }, [values]);

  const isApplied = values.length > 0;

  const handleApply = () => {
    onApply(objectToArrayFilter(tempValues));
  };

  const handleReset = () => {
    onApply([]);
  };

  return (
    <FilterButtonDumb
      {...props}
      values={tempValues}
      isApplied={isApplied}
      isValid={true}
      validationErrors={{}} // No validation errors for array filters
      onChange={(key, value) => setTempValues({...tempValues, [key]: value ?? false})}
      onChangeRangePicker={() => {}}
      onApply={handleApply}
      onReset={handleReset}
    />
  );
};

/**
 * Makes the Filter component usable with arrays by converting the Array<string> to Record<string, boolean>.
 */
export const arrayFilterToObject = <KeyType extends string>(arrayFilter: KeyType[]): Record<KeyType, boolean> =>
  arrayFilter.reduce((acc, key) => ({...acc, [key]: true}), {} as Record<KeyType, boolean>);

/**
 * Makes the Filter component usable with arrays by converting the Record<string, boolean> to Array<string>.
 */
export const objectToArrayFilter = <KeyType extends string>(recordFilter: Record<KeyType, boolean>): KeyType[] =>
  Object.entries(recordFilter)
    .filter(([, value]) => value)
    .map(([key]) => key as KeyType);
