/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {ConsentParty, ConsentPartyFromJSON, ConsentPartyFromJSONTyped, ConsentPartyToJSON} from './';

/**
 *
 * @export
 * @interface ConsentContract
 */
export interface ConsentContract {
  /**
   *
   * @type {string}
   * @memberof ConsentContract
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ConsentContract
   */
  title: string;
  /**
   *
   * @type {Date}
   * @memberof ConsentContract
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ConsentContract
   */
  updatedAt: Date;
  /**
   *
   * @type {ConsentParty}
   * @memberof ConsentContract
   */
  consentParty: ConsentParty;
  /**
   *
   * @type {string}
   * @memberof ConsentContract
   */
  internalNote: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ConsentContract
   */
  archived: boolean;
  /**
   *
   * @type {string}
   * @memberof ConsentContract
   */
  currentVersionId: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ConsentContract
   */
  isTitlePlural: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ConsentContract
   */
  isConsentOnSignup: boolean;
}

export function ConsentContractFromJSON(json: any): ConsentContract {
  return ConsentContractFromJSONTyped(json, false);
}

export function ConsentContractFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentContract {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    createdAt: new Date(json['createdAt']),
    updatedAt: new Date(json['updatedAt']),
    consentParty: ConsentPartyFromJSON(json['consentParty']),
    internalNote: json['internalNote'],
    archived: json['archived'],
    currentVersionId: json['currentVersionId'],
    isTitlePlural: json['isTitlePlural'],
    isConsentOnSignup: json['isConsentOnSignup'],
  };
}

export function ConsentContractToJSON(value?: ConsentContract | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    createdAt: value.createdAt.toISOString(),
    updatedAt: value.updatedAt.toISOString(),
    consentParty: ConsentPartyToJSON(value.consentParty),
    internalNote: value.internalNote,
    archived: value.archived,
    currentVersionId: value.currentVersionId,
    isTitlePlural: value.isTitlePlural,
    isConsentOnSignup: value.isConsentOnSignup,
  };
}
