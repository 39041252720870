import {CustomColumnDef} from '../../DataGrid/DataGrid';
import {TODO} from '../../../utils/TODO';

export const cargoActionsColumn = ({
  accessorFn,
  minWidth = 4,
  sortable = false,
}: {
  accessorFn: (d: TODO) => React.ReactElement;
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<TODO> => ({
  id: 'cargo.actions',
  header: '',
  enableSorting: sortable,
  minWidth,
  className: 'datagrid-td-action',
  accessorFn,
  cell: info => info.getValue(),
});
