/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {PortCongestionStatistics, PortCongestionStatisticsFromJSON, PortCongestionStatisticsToJSON} from '../models';

export interface PortCongestionGetAreaStatisticsRequest {
  port: string;
}

/**
 *
 */
export class PortCongestionApi extends runtime.BaseAPI {
  /**
   * Returns all valid visits for the last 60 days of one port.
   */
  async portCongestionGetAreaStatisticsRaw(
    requestParameters: PortCongestionGetAreaStatisticsRequest
  ): Promise<runtime.ApiResponse<PortCongestionStatistics>> {
    if (requestParameters.port === null || requestParameters.port === undefined) {
      throw new runtime.RequiredError(
        'port',
        'Required parameter requestParameters.port was null or undefined when calling portCongestionGetAreaStatistics.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.port !== undefined) {
      queryParameters['port'] = requestParameters.port;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/port-congestion/statistics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PortCongestionStatisticsFromJSON(jsonValue));
  }

  /**
   * Returns all valid visits for the last 60 days of one port.
   */
  async portCongestionGetAreaStatistics(
    requestParameters: PortCongestionGetAreaStatisticsRequest
  ): Promise<PortCongestionStatistics> {
    const response = await this.portCongestionGetAreaStatisticsRaw(requestParameters);
    return await response.value();
  }
}
