/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ModifyAccount
 */
export interface ModifyAccount {
  /**
   *
   * @type {string}
   * @memberof ModifyAccount
   */
  currentPassword: string;
  /**
   *
   * @type {string}
   * @memberof ModifyAccount
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ModifyAccount
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ModifyAccount
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ModifyAccount
   */
  newPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof ModifyAccount
   */
  timezone: string;
}

export function ModifyAccountFromJSON(json: any): ModifyAccount {
  return ModifyAccountFromJSONTyped(json, false);
}

export function ModifyAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifyAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currentPassword: json['currentPassword'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    newPassword: !exists(json, 'newPassword') ? undefined : json['newPassword'],
    timezone: json['timezone'],
  };
}

export function ModifyAccountToJSON(value?: ModifyAccount | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    currentPassword: value.currentPassword,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    newPassword: value.newPassword,
    timezone: value.timezone,
  };
}
