/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {IntakeTypeEnum, IntakeTypeEnumFromJSON, IntakeTypeEnumFromJSONTyped, IntakeTypeEnumToJSON} from './';

/**
 *
 * @export
 * @interface Intake
 */
export interface Intake {
  /**
   *
   * @type {number}
   * @memberof Intake
   */
  id: number;
  /**
   *
   * @type {IntakeTypeEnum}
   * @memberof Intake
   */
  type: IntakeTypeEnum;
  /**
   *
   * @type {number}
   * @memberof Intake
   */
  quantity: number;
  /**
   *
   * @type {boolean}
   * @memberof Intake
   */
  isHighCube: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Intake
   */
  isEmpty: boolean;
}

export function IntakeFromJSON(json: any): Intake {
  return IntakeFromJSONTyped(json, false);
}

export function IntakeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Intake {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: IntakeTypeEnumFromJSON(json['type']),
    quantity: json['quantity'],
    isHighCube: json['isHighCube'],
    isEmpty: json['isEmpty'],
  };
}

export function IntakeToJSON(value?: Intake | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: IntakeTypeEnumToJSON(value.type),
    quantity: value.quantity,
    isHighCube: value.isHighCube,
    isEmpty: value.isEmpty,
  };
}
