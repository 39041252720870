import {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';
import {NotFound} from '../../atoms/EmptyState/404';
import {ChokepointCongestionScreen} from './ChokepointCongestionScreen/ChokepointCongestionScreen';
import {CongestionOverviewScreen} from './CongestionOverviewScreen/CongestionOverviewScreen';
import {PortCongestionScreen} from './PortCongestionScreen/PortCongestionScreen';

export const CongestionScreens: FC = () => {
  return (
    <>
      <Helmet title={'Congestion report'} />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<CongestionOverviewScreen />} />
        <Route path="/chokepoint/:chokepointKey" element={<ChokepointCongestionScreen />} />
        <Route path="/port/:portKey" element={<PortCongestionScreen />} />
      </Routes>
    </>
  );
};
