import {Button} from 'antd';
import cx from 'classnames';
import {FC, useEffect, useRef, useState} from 'react';
import {TODO} from '../../utils/TODO';
import Station from './Station';
import {getPlaceholder} from './utils';
import {DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToWindowEdges} from '@dnd-kit/modifiers';
import {Dayjs} from 'dayjs';
import {DistanceCalculatorType} from '../../screens/DistanceCalculator/CalculatorSettings/DistanceCalculatorFormValues';
import './stations-selector.scss';

type Props = {
  activeTab: DistanceCalculatorType;
  stations?: TODO[];
  routes: TODO;
  resultETD: Dayjs | null;
  resultETA: Dayjs | null;
  isCalculating: boolean;
  onChange?: (stations: TODO) => void;
  onCalculate: () => void;
  onClearCalculation: () => void;
  onReset: () => void;
};

const StationsSelector: FC<Props> = ({
  activeTab,
  stations: stationsDefault,
  routes,
  resultETD,
  resultETA,
  isCalculating,
  onChange,
  onCalculate,
  onClearCalculation,
  onReset,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [sorting, setSorting] = useState(false);

  // Because we can have a station multiple times, we need to add a dragID to the station object to not have duplicate identifiers
  const [stations, setStations] = useState(
    stationsDefault?.every(Boolean)
      ? stationsDefault.map(s => (s === null ? {id: -(s?.id + 1), dragID: -(s?.id + 1)} : s))
      : [
          {id: -1, dragID: -1},
          {id: -2, dragID: -2},
        ]
  );
  const [idCounter, setIdCounter] = useState(-3);

  const [disableRemove, setDisableRemove] = useState(true);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setDisableRemove(stations.length <= 2);
    onChange?.(stations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stations]);

  const handleAdd = () => {
    setStations(prevStations => [...prevStations, {id: idCounter, dragID: idCounter}]);
    setIdCounter(idCounter - 1);
  };

  const handleRemove = (index: number) => {
    const newStations = stations.filter((_, i) => i !== index);
    setStations(newStations);
  };

  const handleSelect = (station: TODO, key: TODO) => {
    const n = [...stations];
    n.splice(key, 1, station);
    // A station is added and gets a dragID
    setStations(n.map((s, index) => ({...s, dragID: s.dragID || s.id * index + 1})));
  };

  const handleDragEnd = (event: TODO) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setStations(items => {
        const oldIndex = items.map(e => e.dragID).indexOf(active.id);
        const newIndex = items.map(e => e.dragID).indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setSorting(false);
  };

  return (
    <div className={cx('stations-selector')}>
      <div className={cx('timeline', {'timeline--is-sorting': sorting})} ref={ref}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={() => {
            setSorting(true);
          }}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
          {/* Items is the list of of ids provided to the SortableContext. So we want to change it to the dragIDs */}
          <SortableContext items={stations.map(item => item.dragID)} strategy={verticalListSortingStrategy}>
            {stations.map((item, key, array) => {
              return (
                <Station
                  id={item.id}
                  // The dragID is passed to Station component to be used in useSortable hook and the drag handle
                  dragID={item.dragID}
                  key={item.dragID}
                  index={key}
                  station={item}
                  disableRemove={disableRemove}
                  placeholder={getPlaceholder(key, array.length)}
                  isFirst={0 === key}
                  isLast={array.length === key + 1}
                  activeTab={activeTab}
                  resultETD={resultETD}
                  resultETA={resultETA}
                  seca={routes && routes[key] && routes[key].seca}
                  piracy={routes && routes[key] && routes[key].piracy}
                  isCalculating={isCalculating}
                  hasError={routes && routes[key] && !routes[key].success}
                  previousHasError={routes && routes[key - 1] && !routes[key - 1].success}
                  previousStationId={key > 0 && !!array[key - 1] ? (array[key - 1] as TODO).id : null}
                  onChangeStation={(s: TODO) => handleSelect(s, key)}
                  onChangeDate={() => setStations([...stations])} // This is a workaround to trigger a re-render
                  onCalculate={onCalculate}
                  onClearCalculation={onClearCalculation}
                  onRemove={() => handleRemove(key)}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <li className="timeline__item timeline__item--add">
          <div onClick={handleAdd} className="timeline__line">
            <span className={'timeline__line-before'} />
            <span className={'timeline__line-after'} />
          </div>
          <div className="timeline__content">
            <Button onClick={handleAdd} data-testid="Button-Add">
              Add port
            </Button>
            <Button onClick={onReset} data-testid="Button-Reset">
              Reset
            </Button>
          </div>
        </li>
      </div>
    </div>
  );
};

export default StationsSelector;
