/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PortCongestionVisit,
  PortCongestionVisitFromJSON,
  PortCongestionVisitFromJSONTyped,
  PortCongestionVisitToJSON,
} from './';

/**
 *
 * @export
 * @interface PortCongestionStatistics
 */
export interface PortCongestionStatistics {
  /**
   *
   * @type {Array<PortCongestionVisit>}
   * @memberof PortCongestionStatistics
   */
  visits: Array<PortCongestionVisit>;
  /**
   *
   * @type {string}
   * @memberof PortCongestionStatistics
   */
  queryTimestamp: string;
}

export function PortCongestionStatisticsFromJSON(json: any): PortCongestionStatistics {
  return PortCongestionStatisticsFromJSONTyped(json, false);
}

export function PortCongestionStatisticsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PortCongestionStatistics {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    visits: (json['visits'] as Array<any>).map(PortCongestionVisitFromJSON),
    queryTimestamp: json['queryTimestamp'],
  };
}

export function PortCongestionStatisticsToJSON(value?: PortCongestionStatistics | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    visits: (value.visits as Array<any>).map(PortCongestionVisitToJSON),
    queryTimestamp: value.queryTimestamp,
  };
}
