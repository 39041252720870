import {FC, ReactNode} from 'react';
import {assert} from '../../utils/assert';
import {getAjaxCsrfToken} from '../../api/utils/api';
import {useCurrentUserQuery} from '../../queries/useCurrentUserQuery';

const CSRF_TOKEN_REFRESH_INTERVAL_MS = 1000 * 60; // 1 minute

/**
 * Makes sure that the CSRF token is refreshed every minute.
 * This is necessary because the CSRF token expires after a certain time.
 *
 * We render the children only after we have received the very first CSRF token
 * to make sure that we don't render anything before.
 *
 * @see FRIN-4069 - 403 error when logging in with "left open" browser tab
 */
export const CsrfTokenRefreshLoop: FC<{children: ReactNode}> = ({children}) => {
  const currentUserQuery = useCurrentUserQuery({
    // When the user closes his laptop and later reopens it, we need a new CSRF token.
    refetchOnWindowFocus: true,
    // We have to refresh the CSRF token before it expires.
    refetchInterval: CSRF_TOKEN_REFRESH_INTERVAL_MS,
  });

  // Wait until the current user query has loaded at least once.
  if (!currentUserQuery.isSuccess) {
    return null;
  }

  // Now that the current user query has loaded, we have a valid CSRF token.
  // Note that the presence of a CSRF token is not enough to detect that we can proceed,
  // because the CSRF token might be invalid (because it could be expired).
  assert(getAjaxCsrfToken(), 'Missing CSRF token');

  // Because we now have a valid CSRF token, we can proceed with everything else.
  return <>{children}</>;
};
