import {useQuery} from '@tanstack/react-query';
import {Coordinates} from '../utils/Coordinates';
import {fetchJSON} from '../api/utils/fetchJSON';
import {TODO} from '../utils/TODO';

export type MapTradingArea = {
  id: number;
  name: string;
  code: string;
  coordinates: Coordinates[][];
  polygon: Coordinates;
  type: 'tradingarea';
  market: {
    amountCargoes: number;
    amountVessels: number;
    cargoes: TODO[];
    portlessAmountCargoes: number;
    portlessAmountVessels: number;
    portlessCargoes: TODO[];
    portlessVesselIds: TODO[];
  };
};

export const useMapTradingAreasQuery = () =>
  useQuery({
    queryKey: ['mapTradingAreas'],
    queryFn: () => {
      return fetchJSON<{
        data: {
          items: MapTradingArea[];
        };
      }>('/api/map/trading-areas');
    },
  });
