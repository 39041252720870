/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PortAreaType {
  SubPort = 'SUB_PORT',
  Anchorage = 'ANCHORAGE',
  Terminal = 'TERMINAL',
  DryDock = 'DRY_DOCK',
  PilotStation = 'PILOT_STATION',
  Crane = 'CRANE',
  Other = 'OTHER',
}

export function PortAreaTypeFromJSON(json: any): PortAreaType {
  return PortAreaTypeFromJSONTyped(json, false);
}

export function PortAreaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortAreaType {
  return json as PortAreaType;
}

export function PortAreaTypeToJSON(value?: PortAreaType | null): any {
  return value as any;
}
