import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationTermApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {NegotiationRoundResult} from '../../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useUpdateTermTextMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {termId: string; text: string}>
) =>
  useMutation({
    mutationFn: async ({termId, text}: {termId: string; text: string}) => {
      const result = await negotiationNegotiationTermApi.updateTermText({termId, updateTermTextRequest: {text}});
      return result;
    },
    ...options,
  });
