import React, {FC} from 'react';
import styled from 'styled-components';

export const OneColumnLayout: FC<{map: React.ReactElement; grid: React.ReactElement}> = ({map, grid}) => {
  return (
    <OneColumnContainer>
      <div className="spotifind-map">{map}</div>
      <div className="spotifind-grid">{grid}</div>
    </OneColumnContainer>
  );
};

const OneColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  [data-cy='DataGrid'],
  & > div {
    width: 100%;
  }

  .spotifind-map .seabo-mapContainer {
    border-radius: var(--border-radius-card) var(--border-radius-card) 0 0 !important;
  }
`;
