import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import {UseQueryOptions} from '../api/utils/ReactQuery';
import CargoServiceActions from '../redux/ApiService/cargoService';
import {useDispatch} from '../redux/react-redux';
import {
  CargoDetailsResponse,
  GetCargoDetailsResponseSchema,
} from '../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {gentleZodValidation} from '../api/utils/gentleZodValidation';

export const useGetCargoQuery = <T = CargoDetailsResponse>({
  cargoId,
  options,
}: {
  cargoId: number | undefined;
  options?: UseQueryOptions<T | null>;
}): UseQueryResult<T | null> => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['getCargo', cargoId],
    queryFn: async () => {
      const result = (await dispatch(CargoServiceActions.getCargo(cargoId))) as CargoDetailsResponse;
      return gentleZodValidation<T>(GetCargoDetailsResponseSchema, result);
    },
    ...options,
  });
};

export const invalidateGetCargoQuery = async (queryClient: QueryClient, cargoId: number) => {
  await queryClient.invalidateQueries({
    queryKey: ['getCargo', cargoId],
  });
};
