/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ContactRequest
 */
export interface ContactRequest {
  /**
   *
   * @type {string}
   * @memberof ContactRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ContactRequest
   */
  company: string;
  /**
   *
   * @type {string}
   * @memberof ContactRequest
   */
  role?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ContactRequest
   */
  category: ContactRequestCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof ContactRequest
   */
  message: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ContactRequestCategoryEnum {
  Support = 'support',
  Sales = 'sales',
  Pr = 'pr',
  Billing = 'billing',
  Feedback = 'feedback',
}

export function ContactRequestFromJSON(json: any): ContactRequest {
  return ContactRequestFromJSONTyped(json, false);
}

export function ContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    company: json['company'],
    role: !exists(json, 'role') ? undefined : json['role'],
    email: json['email'],
    category: json['category'],
    message: json['message'],
  };
}

export function ContactRequestToJSON(value?: ContactRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    company: value.company,
    role: value.role,
    email: value.email,
    category: value.category,
    message: value.message,
  };
}
