import {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import {ContactRequestsScreen} from './ContactRequestsScreen/ContactRequestsScreen';
import {ContactList} from './List/ContactList';

import {Route, Routes} from 'react-router-dom';
import './List/style.scss';

export const ContactsScreens: FC = () => (
  <>
    <Helmet title={'Contacts'} />
    <Routes>
      <Route path="*" element={<ContactList />} />
      <Route path="/manage-friends" element={<ContactRequestsScreen />} />
    </Routes>
  </>
);
