/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  NegotiationRoundResult,
  NegotiationRoundResultFromJSON,
  NegotiationRoundResultToJSON,
  NegotiationRoundResultModification,
  NegotiationRoundResultModificationFromJSON,
  NegotiationRoundResultModificationToJSON,
} from '../models';

export interface FindRoundResultByIdRequest {
  roundResultId: string;
}

export interface GetRoundResultModificationsRequest {
  roundResultId: string;
}

/**
 *
 */
export class NegotiationNegotiationRoundResultApi extends runtime.BaseAPI {
  /**
   * Finds a round result by ID.
   */
  async findRoundResultByIdRaw(
    requestParameters: FindRoundResultByIdRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.roundResultId === null || requestParameters.roundResultId === undefined) {
      throw new runtime.RequiredError(
        'roundResultId',
        'Required parameter requestParameters.roundResultId was null or undefined when calling findRoundResultById.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/round-results/{roundResultId}`.replace(
        `{${'roundResultId'}}`,
        encodeURIComponent(String(requestParameters.roundResultId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Finds a round result by ID.
   */
  async findRoundResultById(requestParameters: FindRoundResultByIdRequest): Promise<NegotiationRoundResult> {
    const response = await this.findRoundResultByIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the modification of a round result compared to the previous round.
   */
  async getRoundResultModificationsRaw(
    requestParameters: GetRoundResultModificationsRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResultModification>> {
    if (requestParameters.roundResultId === null || requestParameters.roundResultId === undefined) {
      throw new runtime.RequiredError(
        'roundResultId',
        'Required parameter requestParameters.roundResultId was null or undefined when calling getRoundResultModifications.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/round-results/{roundResultId}/modifications`.replace(
        `{${'roundResultId'}}`,
        encodeURIComponent(String(requestParameters.roundResultId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultModificationFromJSON(jsonValue));
  }

  /**
   * Returns the modification of a round result compared to the previous round.
   */
  async getRoundResultModifications(
    requestParameters: GetRoundResultModificationsRequest
  ): Promise<NegotiationRoundResultModification> {
    const response = await this.getRoundResultModificationsRaw(requestParameters);
    return await response.value();
  }
}
