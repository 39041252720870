/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {SearchResult, SearchResultFromJSON, SearchResultFromJSONTyped, SearchResultToJSON} from './';

/**
 *
 * @export
 * @interface MetaSearchResult
 */
export interface MetaSearchResult {
  /**
   *
   * @type {{ [key: string]: SearchResult; }}
   * @memberof MetaSearchResult
   */
  items: {[key: string]: SearchResult};
  /**
   *
   * @type {number}
   * @memberof MetaSearchResult
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof MetaSearchResult
   */
  totalHits: number;
  /**
   *
   * @type {string}
   * @memberof MetaSearchResult
   */
  searchTerm: string;
}

export function MetaSearchResultFromJSON(json: any): MetaSearchResult {
  return MetaSearchResultFromJSONTyped(json, false);
}

export function MetaSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaSearchResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: mapValues(json['items'], SearchResultFromJSON),
    totalItems: json['totalItems'],
    totalHits: json['totalHits'],
    searchTerm: json['searchTerm'],
  };
}

export function MetaSearchResultToJSON(value?: MetaSearchResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: mapValues(value.items, SearchResultToJSON),
    totalItems: value.totalItems,
    totalHits: value.totalHits,
    searchTerm: value.searchTerm,
  };
}
