import {ReactNode, useEffect, useState} from 'react';
import {cleanIdentifier} from '../../utils/helper';
import {ClickOutside} from '../../components/ClickOutside/ClickOutside';
import {FilterBoxSize} from './types';
import styled, {css} from 'styled-components';

export type FilterProps = {
  title: string;
  size?: FilterBoxSize;
  dataTestid?: string;
  dataCy?: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  trigger: ReactNode;
  content: ReactNode;
};

/**
 * A filter component that can be used to filter data.
 * Displays a button that opens a filter box with the given children.
 * The filter box can be used to enter filter values with the inferred type of `values` and apply them.
 */
export const FilterDropdown = ({
  title,
  size,
  dataTestid,
  dataCy,
  open,
  onOpenChange,
  trigger,
  content,
}: FilterProps) => {
  useEffect(() => {
    window.addEventListener('keydown', onEscKey);
    return () => {
      window.removeEventListener('keydown', onEscKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (isClickInAntDropdown(event)) {
      // The user clicked inside an Ant Dropdown such as a DatePicker.
      // In this case we don't want to close the filter.
      return;
    }
    onOpenChange(false);
  };

  const onEscKey = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      onOpenChange(false);
    }
  };

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <FilterContainer id={cleanIdentifier(title, 'filter')} data-cy={dataCy} data-testid={dataTestid}>
        {trigger}
        {open && (
          <DropdownContainer
            title={title}
            size={size}
            dataCy={`${dataCy}-Dropdown`}
            dataTestid={`${dataTestid}-Dropdown`}>
            {content}
          </DropdownContainer>
        )}
      </FilterContainer>
    </ClickOutside>
  );
};

const FilterContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 8px;
`;

const DropdownContainer = ({
  title,
  size,
  dataCy,
  dataTestid,
  children,
}: {
  title: string;
  size?: FilterBoxSize;
  dataCy?: string;
  dataTestid?: string;
  children: ReactNode;
}) => {
  const [el, setEl] = useState<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<'left' | 'right'>('left');
  const reactContent = document.getElementById('content')!.getBoundingClientRect();

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRef = (el: HTMLDivElement) => {
    setEl(el);
    calculatePosition();
  };

  const calculatePosition = () => {
    if (!el) {
      return;
    }
    const rect = el.getBoundingClientRect();
    if (rect.right > reactContent.right) {
      setPosition('right');
    }
    if (reactContent.left > rect.left) {
      setPosition('left');
    }
  };

  return (
    <FilterDropdownContainer
      ref={handleRef}
      id={cleanIdentifier(title, 'filter-dropdown')}
      data-cy={dataCy}
      data-testid={dataTestid}
      $size={size}
      $position={position}>
      {children}
    </FilterDropdownContainer>
  );
};

const FilterDropdownContainer = styled.div<{$size?: FilterBoxSize; $position: 'left' | 'right'}>`
  position: absolute;
  z-index: 101;
  top: 100%;
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-dropdown);
  background-color: var(--color-white);

  ${({$position}) => {
    switch ($position) {
      case 'left':
        return css`
          left: 0;
        `;
      case 'right':
        return css`
          right: 0;
        `;
    }
  }}

  min-width: ${({$size}) => {
    switch ($size) {
      case 'small':
        return '0';
      case 'medium':
        return '360px';
      case 'big':
        return '580px';
    }
  }};
`;

/**
 * Returns true if the given click event targeted an Ant dropdown, which includes a DatePicker.
 */
const isClickInAntDropdown = (event: MouseEvent | TouchEvent): boolean => {
  let element = event.target as HTMLElement | null;

  // Continue walking up the DOM tree until we find class 'ant-picker-dropdown' or null.
  while (element) {
    if (element.classList && element.classList.contains('ant-picker-dropdown')) {
      return true;
    }
    element = (element as Node).parentElement;
  }

  return false;
};
