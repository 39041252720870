import styled from 'styled-components';
import {Modal as DD} from '../../antd/components/Modal';

/**
 * @deprecated Use `Modal` from `antd/components/Modal` instead.
 */
export const Modal: typeof DD = styled(DD)`
  .ant-drawer-content {
    background-color: #fffffff2;
  }
`;
