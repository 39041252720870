import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import lodashDebounce from 'lodash/debounce';
import {useEffect, useMemo, useRef} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {TODO} from '../../../../utils/TODO';
import {GroupedPerUnit} from '../../utils/groupBucketsByDate';
import {OfferType, PointInTimeSeries, transformBucketsToTimeSeries} from '../../utils/transformBucketsToTimeSeries';
import capitalize from 'lodash/capitalize';

export const TimeserieChart = ({
  offerType,
  buckets,
  onZoomIn,
  per,
  styleType,
  grid = {},
  zoomRange,
}: {
  buckets: OfferBucket[];
  per?: GroupedPerUnit;
  styleType: 'bar' | 'line';
  onZoomIn: (params: {start: string; end: string}) => void;
  offerType?: OfferType;
  grid?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  zoomRange?: [string, string];
}) => {
  const timeSerie = useMemo<PointInTimeSeries[]>(() => {
    return transformBucketsToTimeSeries({buckets, per, offerType: offerType});
  }, [buckets, per, offerType]);
  const boundaryGap = styleType === 'bar';

  const ref = useRef<TODO>(null);

  const debouncedOnZoomIn = lodashDebounce(
    (params: {start: string; end: string}) => {
      onZoomIn(params);
    },
    500,
    {maxWait: 1000}
  );

  const getDateFormat = (value: string) => {
    switch (per) {
      case 'month':
        return dayjs(value).format('MM.YYYY');
      case 'week':
        return `${dayjs(value).year()}\nweek ${dayjs(value).format('w')}`;
    }
    return dayjs(value).format(DateTimeFormat.Date + ' (ddd)');
  };

  const option = useMemo(() => {
    return {
      title: {
        text: capitalize(`${offerType ?? 'all'} circulars`),
        left: 'center',
        align: 'right',
      },
      grid: {
        bottom: 80,
        ...grid,
      },
      toolbox: {
        feature: {},
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          animation: false,
          label: {
            backgroundColor: '#505765',
          },
        },
      },

      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          end: 100,
        },
        {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100,
        },
      ],
      xAxis: [
        {
          type: 'category',
          boundaryGap,
          axisLine: {onZero: false},
          data: timeSerie.map(({date}) => getDateFormat(date)),
        },
      ],
      yAxis: [
        {
          name: 'Offers / Day',
          type: 'value',
        },
      ],
      series: [
        {
          name: '',
          type: styleType,
          areaStyle: {},
          lineStyle: {
            width: 1,
          },
          emphasis: {
            focus: 'series',
          },
          step: per === 'day' ? undefined : 'middle',
          data: timeSerie.map(({count}) => count),
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSerie, offerType, per, styleType]);

  useEffect(() => {
    if (zoomRange) {
      const instance = ref.current.getEchartsInstance();
      const option = instance.getOption();
      const xAxisData = option.xAxis[0].data;

      // get xAxis value of passed zoomRange and find index
      const startValue = getDateFormat(zoomRange[0]);
      const endValue = getDateFormat(zoomRange[1]);
      const startIndex = xAxisData.findIndex((element: string) => element === startValue);
      const endIndex = xAxisData.findIndex((element: string) => element === endValue);
      // then zoom chart to found
      instance.dispatchAction({
        type: 'dataZoom',
        startValue: startIndex,
        endValue: endIndex,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRange]);

  const Chart = useMemo(
    () => (
      <ReactECharts
        ref={ref}
        style={{minHeight: 300}}
        onEvents={{
          datazoom: () => {
            const dataZoom = ref.current.getEchartsInstance().getOption().dataZoom[0];
            const start = dataZoom.startValue;
            const end = dataZoom.endValue;

            debouncedOnZoomIn({
              start: timeSerie[start].date,
              end: timeSerie[end].date,
            });
          },
        }}
        option={option}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [option]
  );

  return <>{Chart}</>;
};
