import React, {FC, ReactElement} from 'react';
import Flag from '../atoms/Flags';
import Vessel from '../model/Vessel';
import {TODO} from '../utils/TODO';
import styled from 'styled-components';
import {SeaboVessel} from '../api/node-backend/generated';
import {Link} from 'react-router-dom';

/**
 * `VesselNameLabel` is a functional component that displays the name of a vessel, its flag, built year, and type.
 * It also allows for a custom label to be passed in.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {SeaboVessel | TODO} props.vessel - The vessel object containing the details of the vessel.
 * @param {ReactElement} [props.customVesselNameLabel] - An optional custom label for the vessel name.
 *
 */
export const VesselNameLabel: FC<{
  vessel: SeaboVessel | TODO | undefined;
  customVesselNameLabel?: ReactElement;
}> = ({vessel, customVesselNameLabel}) => {
  if (!vessel) {
    return null;
  }
  const flagCode = vessel.countryFlag || vessel.country;
  const to = `/vessel/${vessel.id}`;

  return (
    <div className={'market-grid-cell'} data-cy="cell-shipname">
      <div className={'market-grid-cell-content'}>
        {customVesselNameLabel ? (
          customVesselNameLabel
        ) : (
          <Link target={'_blank'} data-cy="shipnameLink" to={to}>
            {vessel.name ? vessel.name : 'No name'}
          </Link>
        )}
      </div>
      <GreyContainer>
        <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
          {flagCode && (
            <FlagContainer>
              <Flag data-cy="flag" countryCode={flagCode} shadow keepWidthWhenEmpty />
            </FlagContainer>
          )}
          {vessel.builtYear && (
            <YearContainer>
              <span data-cy="builtYear" className={'market-grid-cell-content__build'}>
                {vessel.builtYear}
              </span>
            </YearContainer>
          )}
          <span className={'market-grid-cell-content__type'} data-cy={'vesselType'}>
            {Vessel.formatVesselType(vessel)}
          </span>
        </div>
      </GreyContainer>
    </div>
  );
};

const FlagContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -1px;
`;

const YearContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const GreyContainer = styled.div`
  color: var(--color-gray-2);
`;
