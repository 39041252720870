import {useDispatch, useSelector} from '../../../redux/react-redux';
import React, {useState} from 'react';
import {Button, Dropdown, Menu} from 'antd';
import {ModalActions} from '../../../redux/Modal';
import {ClipboardItem, GridClipboardActions} from '../../../redux/GridClipboard';
import {AddToSubPortfoliosModal} from '../../../components/Modal/Multiselect/AddToSubPortfoliosModal';
import {ExportVesselsModal} from './ExportVesselsModal/ExportVesselsModal';
import {VesselPositionListModal} from './VesselPositionListModal/VesselPositionListModal';
import {MultiRemoveSubPortfolio} from '../../../components/Modal/Multiselect/MultiRemoveSubPortfolio';
import {useUser} from '../../../components/UserContext/useUser';
import MultiMoveToWorkspace from '../../../components/Modal/Multiselect/MoveToWorkspace';
import MultiArchive from '../../../components/Modal/Multiselect/MultiArchive';
import PortfolioColorEdit from '../../../components/Modal/PortfolioColorEdit';

type Props = {
  archive: boolean;
  items: ClipboardItem[];
  onReloadGrid: () => void;
};

export const MyFleetActionButton = (props: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [exportVesselsModalVisible, setExportVesselsModalVisible] = useState(false);
  const [addToSubPortfoliosVisible, setAddToSubPortfoliosVisible] = useState(false);
  const [vesselPositionListModal, setVesselPositionListModal] = useState(false);

  const [showMultiRemovePortfolioModal, setShowMultiRemovePortfolioModal] = useState(false);

  const count = props.items.length;

  if (!count) {
    return null;
  }

  return (
    <>
      <Dropdown
        trigger={['click']}
        onOpenChange={visible => {
          setMenuVisible(visible);
        }}
        open={menuVisible}
        menu={{
          items: [
            {
              key: '1',
              label: (
                <DropdownMenu
                  items={props.items}
                  reloadGrid={props.onReloadGrid}
                  archive={props.archive}
                  onClick={() => {
                    setMenuVisible(false);
                  }}
                  onExportVessels={() => {
                    setExportVesselsModalVisible(true);
                  }}
                  onAddToSubPortfolios={() => {
                    setAddToSubPortfoliosVisible(true);
                  }}
                  onCopyVesselPositionList={() => {
                    setVesselPositionListModal(true);
                  }}
                  onRemoveFromSubPortfolio={() => {
                    setShowMultiRemovePortfolioModal(true);
                  }}
                />
              ),
            },
          ],
        }}>
        <Button className="uppercase" type={'primary'}>
          {count} {count === 1 ? 'vessel' : 'vessels'} selected
        </Button>
      </Dropdown>

      <ExportVesselsModal
        visible={exportVesselsModalVisible}
        scope="selection"
        onClose={() => {
          setExportVesselsModalVisible(false);
        }}
      />
      <VesselPositionListModal
        visible={vesselPositionListModal}
        onClose={() => {
          setVesselPositionListModal(false);
        }}
      />
      <MultiRemoveSubPortfolio
        onCancel={() => {
          setShowMultiRemovePortfolioModal(false);
        }}
        onSubmit={() => {
          setShowMultiRemovePortfolioModal(false);
          props.onReloadGrid();
        }}
        visible={showMultiRemovePortfolioModal}
        subsection={'vessels'}
      />

      {addToSubPortfoliosVisible && (
        <AddToSubPortfoliosModal
          type="vessel"
          items={props.items}
          onClose={() => {
            setAddToSubPortfoliosVisible(false);
          }}
          onOk={() => {
            props.onReloadGrid();
          }}
        />
      )}
    </>
  );
};

type DropdownMenuProps = {
  items: ClipboardItem[];
  reloadGrid: (pageIndex?: number) => void;
  archive?: boolean;
  onClick: () => void;
  onExportVessels: () => void;
  onAddToSubPortfolios: () => void;
  onCopyVesselPositionList: () => void;
  onRemoveFromSubPortfolio: () => void;
};

const DropdownMenu = (props: DropdownMenuProps) => {
  const dispatch = useDispatch();
  const currentUser = useUser();
  const user = currentUser.payload;
  const activeTab = useSelector(state => state.portfolio.screenState.activeTabVessel);

  return (
    <Menu
      selectedKeys={[]}
      items={[
        {
          key: 'moveToWorkspace',
          disabled: props.archive || (user && user.company === null),
          label: 'Move to workspace',
          onClick: () => {
            dispatch(
              ModalActions.show(
                <MultiMoveToWorkspace
                  type="portfolioVessel"
                  data={props.items}
                  reload={() => props.reloadGrid(1)}
                  multiItems={true}
                />
              )
            );
            props.onClick();
          },
        },
        {
          key: 'addToSubPortfolios',
          disabled: props.archive,
          label: 'Add to vessel lists',
          onClick: () => {
            props.onAddToSubPortfolios();
            props.onClick();
          },
        },
        {
          key: 'archive',
          label: !props.archive ? 'Archive vessels' : 'Restore vessels',
          onClick: () => {
            dispatch(
              ModalActions.show(
                <MultiArchive
                  archive={!!props.archive}
                  type={'vessel'}
                  targets={props.items}
                  section={'portfolio'}
                  reload={() => props.reloadGrid(1)}
                />
              )
            );
            props.onClick();
          },
        },
        {
          key: 'setColorMarker',
          disabled: props.archive,
          label: 'Set colour marker',
          onClick: () => {
            dispatch(
              ModalActions.show(
                <PortfolioColorEdit
                  type={'multiVessel'}
                  targets={props.items}
                  reload={() => {
                    props.reloadGrid();
                  }}
                />
              )
            );
            props.onClick();
          },
        },
        {
          key: 'exportVessels',
          disabled: props.archive,
          label: 'Export vessels',
          onClick: () => {
            props.onExportVessels();
            props.onClick();
          },
        },
        {
          key: 'vesselPositionList',
          disabled: props.archive,
          label: 'Vessel position list',
          onClick: () => {
            props.onCopyVesselPositionList();
            props.onClick();
          },
        },
        {
          key: 'unselect',
          label: 'Unselect all vessels',
          disabled: props.archive,
          onClick: () => {
            dispatch(GridClipboardActions.removeAllItemsFromClipboard('vessels', 'portfolio'));
            props.onClick();
          },
        },
        ...(activeTab !== 'all'
          ? [
              {
                key: 'removeFromSubPortfolios',
                disabled: props.archive,
                label: 'Remove from vessel lists',
                onClick: () => {
                  props.onRemoveFromSubPortfolio();
                  props.onClick();
                },
              },
            ]
          : []),
      ]}
    />
  );
};
