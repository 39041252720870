/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface DocumentManualConsentDTO
 */
export interface DocumentManualConsentDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentManualConsentDTO
   */
  documentationText: string;
  /**
   *
   * @type {Date}
   * @memberof DocumentManualConsentDTO
   */
  consentAt: Date;
}

export function DocumentManualConsentDTOFromJSON(json: any): DocumentManualConsentDTO {
  return DocumentManualConsentDTOFromJSONTyped(json, false);
}

export function DocumentManualConsentDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DocumentManualConsentDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    documentationText: json['documentationText'],
    consentAt: new Date(json['consentAt']),
  };
}

export function DocumentManualConsentDTOToJSON(value?: DocumentManualConsentDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    documentationText: value.documentationText,
    consentAt: value.consentAt.toISOString(),
  };
}
