import {CargoInput} from '../../../utils/CargoTypes';
import produce from 'immer';
import {VoyageConsumption, VoyageInput, VoyagePoint} from '../../../VoyageInformation/VoyageTypes';
import {defaultVoyagePoint} from '../../../VoyageInformation/Timeline/AddItem';
import {blankState} from '../../../Context/blankState';

export type FormValues = {
  voyage?: VoyageInput;
  cargo: CargoInput;
  loadingLegs: Partial<VoyagePoint>[];
  dischargeLegs: Partial<VoyagePoint>[];
};

type Params = {
  cargo: CargoInput;
  voyage: VoyageInput;
  formValues: FormValues;
  defaultPortConsumption: VoyageConsumption;
};

export const transformFormValuesToVoyageCalcState = ({cargo, voyage, formValues, defaultPortConsumption}: Params) => {
  const formCargo = formValues.cargo;
  const formVoyage = formValues.voyage;
  const newCargo: CargoInput = {...blankState.inputState.cargo, ...cargo, ...formCargo};

  const newVoyage = produce({...voyage, ...(formVoyage ?? blankState.inputState.voyage)}, draftVoyageState => {
    const pointsWithoutLoadingDischarge = draftVoyageState.points.filter(leg => {
      if (leg.types.includes('discharge')) {
        return false;
      }
      if (leg.types.includes('loading')) {
        return false;
      }

      return true;
    });

    const updatedLoadingLegs: VoyagePoint[] = formValues.loadingLegs.map(leg => {
      return {
        ...defaultVoyagePoint,
        types: ['loading'],
        ...leg,
        consumption: defaultPortConsumption,
        load: Math.round(newCargo.cargoQuantity! / formValues.loadingLegs.length),
      };
    });
    const updatedDischargeLegs: VoyagePoint[] = formValues.dischargeLegs.map(leg => {
      return {
        ...defaultVoyagePoint,
        types: ['discharge'],
        ...leg,
        consumption: defaultPortConsumption,
        dis: Math.round(newCargo.cargoQuantity! / formValues.dischargeLegs.length),
      };
    });

    draftVoyageState.points = [...pointsWithoutLoadingDischarge, ...updatedLoadingLegs, ...updatedDischargeLegs];
  });

  return {voyage: newVoyage, cargo: newCargo};
};
