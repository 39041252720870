/* tslint:disable */
/* eslint-disable */
/**
 * BunkerService
 * BunkerService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {Prices, PricesFromJSON, PricesToJSON, Query, QueryFromJSON, QueryToJSON} from '../models';

export interface PostBunkerserviceRequest {
  query?: Query;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Get bunkerservice
   */
  async getBunkerserviceRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/bunkerservice`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get bunkerservice
   */
  async getBunkerservice(): Promise<void> {
    await this.getBunkerserviceRaw();
  }

  /**
   * Get current bunker prices
   * Post bunkerservice
   */
  async postBunkerserviceRaw(requestParameters: PostBunkerserviceRequest): Promise<runtime.ApiResponse<Prices>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/bunkerservice`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: QueryToJSON(requestParameters.query),
    });

    return new runtime.JSONApiResponse(response, jsonValue => PricesFromJSON(jsonValue));
  }

  /**
   * Get current bunker prices
   * Post bunkerservice
   */
  async postBunkerservice(requestParameters: PostBunkerserviceRequest): Promise<Prices> {
    const response = await this.postBunkerserviceRaw(requestParameters);
    return await response.value();
  }
}
