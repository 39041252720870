import {useCallback, useEffect, useState} from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedValue = <Value>({value, debounceWait}: {value: Value; debounceWait: number}): Value => {
  const [debouncedValue, _setDebouncedValue] = useState<Value>(value);

  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const setDebouncedValue = useCallback(debounce(_setDebouncedValue, debounceWait), [debounceWait, _setDebouncedValue]);

  useEffect(() => {
    setDebouncedValue(value);
  }, [value, setDebouncedValue]);

  return debouncedValue;
};
