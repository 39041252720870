import dayjs, {Dayjs} from 'dayjs';
import getBoundsOfDistance from 'geolib/es/getBoundsOfDistance';
import {EnabledFilters} from '../../screens/Analytics/PotentialCompetitors/FilterOptionDropdown';
import {PotentialCompetitorsFormData, PreparedAisVessel} from '../../screens/Analytics/PotentialCompetitors/types';
import {calcRadiusInKM} from './calcRadiusInKM';
import {filterVesselsAndAddDistance} from './filterVesselsAndAddDistance/filterVesselsAndAddDistance';

const bufferForAirlineDistance = 1.2;

export const getRadius = (data: {speed: number; currentTime: Dayjs; toDate: Dayjs}) =>
  calcRadiusInKM({
    days: data.toDate!.diff(data.currentTime, 'days') + 1,
    speed: data.speed!,
  });

export const doFilter = async ({
  formData,
  onProgress,
  filterOptions,
  preparedAisVessels,
  currentTime,
}: {
  formData: PotentialCompetitorsFormData;
  currentTime?: Dayjs;
  onProgress: (progress: number) => void;
  filterOptions: EnabledFilters;
  preparedAisVessels?: PreparedAisVessel[];
}) => {
  const radius = getRadius({
    toDate: formData.toDate!,
    speed: formData.speed!,
    currentTime: currentTime ?? dayjs(),
  });

  const coordsTopLeftBottomRight = getBoundsOfDistance(
    [formData.port!.coordinates[1], formData.port!.coordinates[0]],
    radius * bufferForAirlineDistance
  );
  onProgress(0);

  const filterResults = await filterVesselsAndAddDistance(
    {
      coordsTopLeftBottomRight,
      vessels: preparedAisVessels ?? [],
      maxDistanceInKM: radius,
      minDWT: formData.minDwt!,
      maxDWT: formData.maxDwt!,
      onChangeProgressInPercent: onProgress,
      enabledFilters: filterOptions,
      vesselType: 'bulker',
      toDate: formData.toDate!,
      fromDate: formData.fromDate!,
      speed: formData.speed!,
      portCoordinates: formData.port!.coordinates,
      portCode: formData.port!.code,
    },
    currentTime
  );

  return filterResults;
};
