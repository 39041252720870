import React, {FC, useEffect, useMemo, useState} from 'react';
import {NegotiationTemplateBody} from './NegotiationTemplateBody';
import {LoadingBanner, NegotiationScreenContainer} from '../Components/shared';
import {Row, Col, Card} from 'antd';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import confirm from 'antd/es/modal/confirm';
import {useGetNegotiationTemplateQuery} from './utils/useGetNegotiationTemplateQuery';
import {useGetTemplateActionsWithMutations} from './utils/useTemplateActions';
import {ContractTemplateSelectorList} from './ContractTemplateSelectorList';
import {useGetNegotiationTemplateSummariesQuery} from './utils/useGetNegotiationTemplateSummariesQuery';
import {
  CreateOrUpdateNegotiationTemplateDTO,
  NegotiationTemplate,
  NegotiationTemplateSummary,
} from '../../../api/node-backend/generated';
import Title from 'antd/es/typography/Title';
import {TermListDivider} from '../NegotiationDetail/Components/TermArea/CreateArea';
import Link from 'antd/es/typography/Link';
import {ModalWidth} from '../../../antd/components/Modal';
import isEqual from 'lodash/isEqual';

const emptyContractTemplate: CreateOrUpdateNegotiationTemplateDTO = {
  name: 'New Template',
  description: '',
  isGlobalTemplate: false,
  negotiationTemplateTerms: [],
};

type NegotiationTemplatesScreenProps = {
  // If we are on the backend screen, we configure global templates
  isBackendScreen?: boolean;
};

export const NegotiationTemplatesScreen: FC<NegotiationTemplatesScreenProps> = ({isBackendScreen}) => {
  const {getTemplateActions} = useGetTemplateActionsWithMutations();
  const {createTemplate, updateTemplate, deleteTemplate} = getTemplateActions();

  // We need to keep track of the selected contract template and the draft separately to be able to revert changes
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
  const [templateDraft, setTemplateDraft] = useState<NegotiationTemplate>();

  // We need updateKey to force a flush of the terms' Lexical Editor states when the template changes from outside
  const [updateKey, setUpdateKey] = useState(0);

  const getTemplateSummariesQuery = useGetNegotiationTemplateSummariesQuery();
  const templateSummaries = getTemplateSummariesQuery.data;

  const getTemplateQuery = useGetNegotiationTemplateQuery(selectedTemplateId);
  const selectedTemplate = getTemplateQuery.data;

  const hasChanges = useMemo(() => !isEqual(selectedTemplate, templateDraft), [selectedTemplate, templateDraft]);

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }
    setTemplateDraft(selectedTemplate ? {...selectedTemplate} : undefined);
  }, [selectedTemplate]);

  const onChangeTemplate = (
    newTemplateDraft: NegotiationTemplate | ((newTemplateDraft: NegotiationTemplate) => NegotiationTemplate)
  ) => {
    const newTemplate = typeof newTemplateDraft === 'function' ? newTemplateDraft(templateDraft!) : newTemplateDraft;
    setTemplateDraft(newTemplate);
  };

  const onRevertTemplate = async () => {
    await getTemplateQuery.refetch();
    setUpdateKey(updateKey + 1);
  };

  const onSaveTemplate = async () => {
    await updateTemplate({
      templateId: selectedTemplateId!,
      createOrUpdateNegotiationTemplateDTO: {
        name: templateDraft!.name,
        description: templateDraft!.description,
        negotiationTemplateTerms: templateDraft!.terms,
      },
    });
  };

  const onCreateTemplate = async (createOrUpdateNegotiationTemplateDTO = {...emptyContractTemplate}) => {
    await createTemplate(
      {
        createOrUpdateNegotiationTemplateDTO: {
          ...createOrUpdateNegotiationTemplateDTO,
          // backend expects null for global templates
          isGlobalTemplate: isBackendScreen,
        },
      },
      newTemplate => {
        setSelectedTemplateId(newTemplate!.id);
      }
    );
  };

  const onDuplicateTemplate = async () => {
    await onCreateTemplate({
      name: `${templateDraft!.name} (copy)`,
      description: templateDraft!.description,
      negotiationTemplateTerms: templateDraft!.terms,
    });
  };

  const onRemoveTemplate = () => {
    confirm({
      title: 'Are you sure you want to delete this template?',
      okText: 'Yes',
      cancelText: 'Cancel',
      okCancel: true,
      onOk: async () => {
        setSelectedTemplateId(undefined);
        setTemplateDraft(undefined);
        void deleteTemplate({templateId: selectedTemplateId!});
      },
    });
  };

  const onSelectTemplate = (template: NegotiationTemplateSummary) => {
    if (selectedTemplateId === template.id) {
      return;
    }
    if (hasChanges) {
      confirm({
        width: ModalWidth.Middle,
        title: 'Do you want to save changes?',
        content: 'You have unsaved changes. Do you want to save them before switching templates?',
        okText: 'Save changes',
        cancelText: 'Leave without saving',
        okCancel: true,
        onOk: async () => {
          await onSaveTemplate();
          setSelectedTemplateId(template.id);
        },
        onCancel: () => {
          setSelectedTemplateId(template.id);
        },
      });
    } else {
      setSelectedTemplateId(template.id);
    }
  };

  const breadcrumbs = [
    isBackendScreen ? {title: 'Backend', href: '/backend'} : {title: 'Negotiations', href: '/negotiations'},
    {title: 'Negotiation templates'},
  ];

  const filteredTemplateSummaries =
    templateSummaries?.filter(template =>
      isBackendScreen ? template.companyId === null : template.companyId !== null
    ) ?? [];

  return (
    <NegotiationScreenContainer data-testid="NegotiationTemplateConfiguration">
      <ScreenHeader
        helmetTitle={`${isBackendScreen ? 'Global ' : ''}Negotiation templates`}
        breadcrumbs={breadcrumbs}
      />
      <p>Create and manage templates to pre-fill your negotiation offers.</p>
      {getTemplateSummariesQuery.isFetching ? (
        <LoadingBanner />
      ) : (
        <Row gutter={[16, 16]} wrap={false}>
          <Col flex="300px">
            <ContractTemplateSelectorList
              isBackendScreen={isBackendScreen}
              templateSummaries={filteredTemplateSummaries}
              selectedTemplateId={selectedTemplateId}
              onCreateTemplate={onCreateTemplate}
              onSelectTemplate={onSelectTemplate}
            />
            {getTemplateQuery.isFetching && <LoadingBanner />}
          </Col>
          <Col flex={'auto'}>
            <Card>
              {!templateDraft || getTemplateQuery.isFetching ? (
                <>
                  <Title level={3}>Edit templates</Title>
                  <TermListDivider />
                  Please select a template on the left to edit it or{' '}
                  <Link onClick={() => onCreateTemplate()}>create a new one</Link>
                </>
              ) : (
                <NegotiationTemplateBody
                  key={updateKey}
                  templateDraft={templateDraft}
                  hasChanges={hasChanges}
                  onChangeTemplate={onChangeTemplate}
                  onRevertTemplate={onRevertTemplate}
                  onSaveTemplate={onSaveTemplate}
                  onDuplicateTemplate={onDuplicateTemplate}
                  onRemoveTemplate={onRemoveTemplate}
                />
              )}
            </Card>
          </Col>
        </Row>
      )}
    </NegotiationScreenContainer>
  );
};
