import {InputState, OutputState} from './voyageChartercalculation';
import {VoyageRoute} from '../../VoyageInformation/VoyageTypes';
import {getConsumptionForRoute} from '../../VoyageInformation/Timeline/getConsumptionForRoute';
import sumBy from 'lodash/sumBy';

export const subCalcRoutes = (params: {inputState: InputState; outputState: OutputState}) => {
  const {outputState, inputState} = params;

  params.inputState.voyage.routes.forEach((voyageRoute, index) =>
    calcSingleRouteAndSet({voyageRoute, index, inputState, outputState})
  );
};

const calcSingleRouteAndSet = ({
  inputState,
  outputState,
  voyageRoute,
  index,
}: {
  inputState: InputState;
  outputState: OutputState;
  index: number;
  voyageRoute: VoyageRoute;
}) => {
  const {sumAuxCo2Emisson, sumMainCo2Emisson, consumptionCosts, duration} = calcSingleRoute({
    inputState,
    voyageRoute,
  });

  outputState.cargo.duration += duration;

  outputState.voyage.routes[index] = {
    duration: duration,
    consumptionCosts,
    cO2Emission: sumAuxCo2Emisson + sumMainCo2Emisson,
    endPoints: [],
  };
};

export const calcSingleRoute = ({inputState, voyageRoute}: {inputState: InputState; voyageRoute: VoyageRoute}) => {
  const etmal = (voyageRoute.consumption?.speed ?? 0.0001) * 24;

  // add sea margin
  const seaMarginPercentage = (100 + (voyageRoute.seaMargin ?? 0)) / 100;

  const distance = seaMarginPercentage * voyageRoute.distance;

  const duration = distance / etmal;

  const aux = getConsumptionForRoute({
    type: 'aux',
    isScrubberFitted: inputState.vessel.isScrubberFitted,
    costInput: inputState.cost,
    route: voyageRoute,
    duration: duration,
  });
  const main = getConsumptionForRoute({
    type: 'main',
    isScrubberFitted: inputState.vessel.isScrubberFitted,
    costInput: inputState.cost,
    route: voyageRoute,
    duration: duration,
  });

  const sumAuxConsumptionCosts = sumBy(aux, row => row.costs);
  const sumMainConsumptionCosts = sumBy(main, row => row.costs);
  const sumAuxCo2Emisson = sumBy(aux, row => row.cO2Emission);
  const sumMainCo2Emisson = sumBy(main, row => row.cO2Emission);

  const consumptionCosts = sumAuxConsumptionCosts + sumMainConsumptionCosts;

  return {
    sumAuxCo2Emisson,
    sumMainCo2Emisson,
    consumptionCosts,
    duration,
  };
};
