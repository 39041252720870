import {Tooltip} from 'antd';
import {FC} from 'react';
import {GhostButton} from '../../../Components/shared';
import {ActionsContainer, StatefulIcon} from '../TermItem/Components/shared';
import {SubjectActions} from './utils/useGetSubjectActionsWithMutations';
import {DerivedSubjectState} from './utils/getDerivedSubjectState';

type SubjectActionAreaProps = {
  subjectState: DerivedSubjectState;
  subjectActions: SubjectActions;
  actionsBusy?: boolean;
  onEdit?: () => void;
};

export const SubjectActionArea: FC<SubjectActionAreaProps> = ({subjectState, subjectActions, actionsBusy, onEdit}) => {
  const {
    canBeEdited,
    canBeDeleted,
    canBeReset,
    canBeAccepted,
    canBeRejectionRequested,
    canBeRejectRequestSettled,
    canBeLifted,
    canBeFailed,
  } = subjectState;
  const {
    resetSubject,
    deleteSubject,
    acceptSubject,
    requestSubjectRejection,
    acceptSubjectRejection,
    rejectSubjectRejection,
    liftSubject,
    failSubject,
  } = subjectActions;

  return (
    <ActionsContainer data-testid="SubjectActions">
      {canBeEdited && (
        <Tooltip title="Edit subject" trigger="hover">
          <GhostButton data-testid="SubjectEditButton" onClick={onEdit} disabled={actionsBusy}>
            <StatefulIcon type="edit" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeDeleted && (
        <Tooltip title="Delete subject" trigger="hover">
          <GhostButton data-testid="SubjectDeleteButton" onClick={deleteSubject} disabled={actionsBusy}>
            <StatefulIcon type="delete" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeReset && (
        <Tooltip title="Undo changes" trigger="hover">
          <GhostButton data-testid="SubjectResetButton" onClick={resetSubject} disabled={actionsBusy}>
            <StatefulIcon type="undo" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeAccepted && (
        <Tooltip title="Accept subject" trigger="hover">
          <GhostButton data-testid="SubjectAcceptButton" onClick={acceptSubject} disabled={actionsBusy}>
            <StatefulIcon type="checkmark" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeRejectionRequested && (
        <Tooltip title="Request to reject subject" trigger="hover">
          <GhostButton
            data-testid="SubjectRequestRejectionButton"
            onClick={requestSubjectRejection}
            disabled={actionsBusy}>
            <StatefulIcon type="close" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeRejectRequestSettled && (
        <Tooltip title="Accept to reject subject" trigger="hover">
          <GhostButton
            data-testid="SubjectAcceptRejectionButton"
            onClick={acceptSubjectRejection}
            disabled={actionsBusy}>
            <StatefulIcon type="checkmark" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeRejectRequestSettled && (
        <Tooltip title="Reset to ongoing (reject subject rejection)" trigger="hover">
          <GhostButton
            data-testid="SubjectRejectRejectionButton"
            onClick={rejectSubjectRejection}
            disabled={actionsBusy}>
            <StatefulIcon type="close" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeLifted && (
        <Tooltip title="Lift subject" trigger="hover">
          <GhostButton data-testid="SubjectLiftButton" onClick={liftSubject} disabled={actionsBusy}>
            <StatefulIcon type="checkmark" color="green" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
      {canBeFailed && (
        <Tooltip title="Fail subject" trigger="hover">
          <GhostButton data-testid="SubjectFailButton" onClick={failSubject} disabled={actionsBusy}>
            <StatefulIcon type="close" color="red" $disabled={actionsBusy} />
          </GhostButton>
        </Tooltip>
      )}
    </ActionsContainer>
  );
};
