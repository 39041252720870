import memoize from 'lodash/memoize';
import {TODO} from '../../../utils/TODO';

export const filterPortfolioVessels = memoize((subPortfolios: null | Record<string, TODO>) =>
  memoize((portfolioVessels?: TODO[]) =>
    memoize((aisVessels?: TODO[]) => {
      let filteredPortfolioVessels;
      if (!subPortfolios) {
        return [];
      }
      if (Object.values(subPortfolios).find(v => v)) {
        filteredPortfolioVessels = portfolioVessels?.filter(
          v => v.subs && v.subs.find((id: TODO) => subPortfolios[id])
        );
      } else {
        filteredPortfolioVessels = portfolioVessels;
      }
      const portfolioVesselIdByImo = filteredPortfolioVessels?.reduce((acc, {imo, id, type}) => {
        if (imo !== null) {
          acc[imo] = {id, type};
        }
        return acc;
      }, {});
      return (aisVessels ?? [])
        .filter((d: TODO) => !!portfolioVesselIdByImo?.[d.imo])
        .slice(0, 100) // PLT-2969: Limit max amount of vessels to 100
        .map((v: TODO) => ({...v, id: portfolioVesselIdByImo[v.imo].id, type: portfolioVesselIdByImo[v.imo].type}));
    })
  )
);
