import {QueryObserverResult, useQuery} from '@tanstack/react-query';
import {ContractType, SearchContainer, VesselType} from '../../api/symfony/generated';
import {ApiError} from '../../api/utils/ApiError';
import {searchApi} from '../../api/symfony/symfonyApi';
import {UseQueryOptions} from '../../api/utils/ReactQuery';

const searchPickerQueryKey = 'searchPickerQuery';

type Params = {
  indexNames: string;
  searchTag: string;
  options?: UseQueryOptions<SearchContainer, ApiError>;
  paramContractType?: ContractType;
  vesselTypes?: VesselType[];
};

export const useSearchQuery = (params: Params): QueryObserverResult<SearchContainer, ApiError> => {
  const {searchTag, indexNames, paramContractType} = params;
  const searchQuery = useQuery({
    queryKey: [searchPickerQueryKey, indexNames, searchTag],
    queryFn: () => {
      return searchApi.getAutoComplete({
        indexNames,
        pageIndex: 1,
        pageSize: 10,
        paramContractType,
        query: searchTag,
        vesselTypes: params.vesselTypes?.join(','),
      });
    },
    enabled: searchTag.trim().length > 0,
    ...params.options,
  });

  return searchQuery;
};
