/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {ObjectCollection, ObjectCollectionFromJSON, ObjectCollectionToJSON} from '../models';

export interface GetPortfolioCargoesRequest {
  portfolioGroup?: number;
  pageNumber?: number;
  pageSize?: number;
  sorts?: Array<string>;
  ids?: Array<number>;
  cargoTypes?: Array<string>;
  contractTypes?: Array<string>;
  vesselSizeDeadweightMin?: number;
  vesselSizeDeadweightMax?: number;
  vesselSizeTeuMin?: number;
  vesselSizeTeuMax?: number;
  vesselSizeTeu14Min?: number;
  vesselSizeTeu14Max?: number;
  commodityTypes?: Array<string>;
  commodityCategories?: Array<string>;
  bulkerSubTypes?: Array<string>;
  containerDesignTypes?: Array<string>;
  chartererNames?: Array<string>;
  durationMin?: number;
  durationMax?: number;
  durationUnit?: GetPortfolioCargoesDurationUnitEnum;
  quantityMin?: number;
  quantityMax?: number;
  quantityUnit?: GetPortfolioCargoesQuantityUnitEnum;
  quantityHighCube?: number;
  quantityEmpty?: number;
  cellulared?: number;
  openHatch?: number;
  boxShape?: number;
  itfFitted?: number;
  lakesFitted?: number;
  tweenDecks?: number;
  bowThrusted?: number;
  logFitted?: number;
  sternThrusted?: number;
  co2Fitted?: number;
  scrubberFitted?: number;
  archived?: number;
  a60Bulkhead?: number;
  gear?: GetPortfolioCargoesGearEnum;
  iceClass?: number;
  laycanFrom?: Date;
  laycanTo?: Date;
  locationLoadPorts?: Array<number>;
  locationLoadTradingAreas?: Array<number>;
  locationLoadCountries?: Array<number>;
  locationViaPorts?: Array<number>;
  locationViaTradingAreas?: Array<number>;
  locationViaCountries?: Array<number>;
  locationDischargePorts?: Array<number>;
  locationDischargeTradingAreas?: Array<number>;
  locationDischargeCountries?: Array<number>;
  newFilter?: number;
}

/**
 *
 */
export class PortfolioCargoApi extends runtime.BaseAPI {
  /**
   */
  async getPortfolioCargoesRaw(
    requestParameters: GetPortfolioCargoesRequest
  ): Promise<runtime.ApiResponse<ObjectCollection>> {
    const queryParameters: any = {};

    if (requestParameters.portfolioGroup !== undefined) {
      queryParameters['portfolioGroup'] = requestParameters.portfolioGroup;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters['pageNumber'] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.sorts) {
      queryParameters['sorts[]'] = requestParameters.sorts;
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids;
    }

    if (requestParameters.cargoTypes) {
      queryParameters['cargoTypes[]'] = requestParameters.cargoTypes;
    }

    if (requestParameters.contractTypes) {
      queryParameters['contractTypes[]'] = requestParameters.contractTypes;
    }

    if (requestParameters.vesselSizeDeadweightMin !== undefined) {
      queryParameters['vesselSizeDeadweightMin'] = requestParameters.vesselSizeDeadweightMin;
    }

    if (requestParameters.vesselSizeDeadweightMax !== undefined) {
      queryParameters['vesselSizeDeadweightMax'] = requestParameters.vesselSizeDeadweightMax;
    }

    if (requestParameters.vesselSizeTeuMin !== undefined) {
      queryParameters['vesselSizeTeuMin'] = requestParameters.vesselSizeTeuMin;
    }

    if (requestParameters.vesselSizeTeuMax !== undefined) {
      queryParameters['vesselSizeTeuMax'] = requestParameters.vesselSizeTeuMax;
    }

    if (requestParameters.vesselSizeTeu14Min !== undefined) {
      queryParameters['vesselSizeTeu14Min'] = requestParameters.vesselSizeTeu14Min;
    }

    if (requestParameters.vesselSizeTeu14Max !== undefined) {
      queryParameters['vesselSizeTeu14Max'] = requestParameters.vesselSizeTeu14Max;
    }

    if (requestParameters.commodityTypes) {
      queryParameters['commodityTypes[]'] = requestParameters.commodityTypes;
    }

    if (requestParameters.commodityCategories) {
      queryParameters['commodityCategories[]'] = requestParameters.commodityCategories;
    }

    if (requestParameters.bulkerSubTypes) {
      queryParameters['bulkerSubTypes[]'] = requestParameters.bulkerSubTypes;
    }

    if (requestParameters.containerDesignTypes) {
      queryParameters['containerDesignTypes[]'] = requestParameters.containerDesignTypes;
    }

    if (requestParameters.chartererNames) {
      queryParameters['chartererNames[]'] = requestParameters.chartererNames;
    }

    if (requestParameters.durationMin !== undefined) {
      queryParameters['durationMin'] = requestParameters.durationMin;
    }

    if (requestParameters.durationMax !== undefined) {
      queryParameters['durationMax'] = requestParameters.durationMax;
    }

    if (requestParameters.durationUnit !== undefined) {
      queryParameters['durationUnit'] = requestParameters.durationUnit;
    }

    if (requestParameters.quantityMin !== undefined) {
      queryParameters['quantityMin'] = requestParameters.quantityMin;
    }

    if (requestParameters.quantityMax !== undefined) {
      queryParameters['quantityMax'] = requestParameters.quantityMax;
    }

    if (requestParameters.quantityUnit !== undefined) {
      queryParameters['quantityUnit'] = requestParameters.quantityUnit;
    }

    if (requestParameters.quantityHighCube !== undefined) {
      queryParameters['quantityHighCube'] = requestParameters.quantityHighCube;
    }

    if (requestParameters.quantityEmpty !== undefined) {
      queryParameters['quantityEmpty'] = requestParameters.quantityEmpty;
    }

    if (requestParameters.cellulared !== undefined) {
      queryParameters['cellulared'] = requestParameters.cellulared;
    }

    if (requestParameters.openHatch !== undefined) {
      queryParameters['openHatch'] = requestParameters.openHatch;
    }

    if (requestParameters.boxShape !== undefined) {
      queryParameters['boxShape'] = requestParameters.boxShape;
    }

    if (requestParameters.itfFitted !== undefined) {
      queryParameters['itfFitted'] = requestParameters.itfFitted;
    }

    if (requestParameters.lakesFitted !== undefined) {
      queryParameters['lakesFitted'] = requestParameters.lakesFitted;
    }

    if (requestParameters.tweenDecks !== undefined) {
      queryParameters['tweenDecks'] = requestParameters.tweenDecks;
    }

    if (requestParameters.bowThrusted !== undefined) {
      queryParameters['bowThrusted'] = requestParameters.bowThrusted;
    }

    if (requestParameters.logFitted !== undefined) {
      queryParameters['logFitted'] = requestParameters.logFitted;
    }

    if (requestParameters.sternThrusted !== undefined) {
      queryParameters['sternThrusted'] = requestParameters.sternThrusted;
    }

    if (requestParameters.co2Fitted !== undefined) {
      queryParameters['co2Fitted'] = requestParameters.co2Fitted;
    }

    if (requestParameters.scrubberFitted !== undefined) {
      queryParameters['scrubberFitted'] = requestParameters.scrubberFitted;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters['archived'] = requestParameters.archived;
    }

    if (requestParameters.a60Bulkhead !== undefined) {
      queryParameters['a60Bulkhead'] = requestParameters.a60Bulkhead;
    }

    if (requestParameters.gear !== undefined) {
      queryParameters['gear'] = requestParameters.gear;
    }

    if (requestParameters.iceClass !== undefined) {
      queryParameters['iceClass'] = requestParameters.iceClass;
    }

    if (requestParameters.laycanFrom !== undefined) {
      queryParameters['laycanFrom'] = (requestParameters.laycanFrom as any).toISOString().substr(0, 10);
    }

    if (requestParameters.laycanTo !== undefined) {
      queryParameters['laycanTo'] = (requestParameters.laycanTo as any).toISOString().substr(0, 10);
    }

    if (requestParameters.locationLoadPorts) {
      queryParameters['locationLoad[ports][]'] = requestParameters.locationLoadPorts;
    }

    if (requestParameters.locationLoadTradingAreas) {
      queryParameters['locationLoad[tradingAreas][]'] = requestParameters.locationLoadTradingAreas;
    }

    if (requestParameters.locationLoadCountries) {
      queryParameters['locationLoad[countries][]'] = requestParameters.locationLoadCountries;
    }

    if (requestParameters.locationViaPorts) {
      queryParameters['locationVia[ports][]'] = requestParameters.locationViaPorts;
    }

    if (requestParameters.locationViaTradingAreas) {
      queryParameters['locationVia[tradingAreas][]'] = requestParameters.locationViaTradingAreas;
    }

    if (requestParameters.locationViaCountries) {
      queryParameters['locationVia[countries][]'] = requestParameters.locationViaCountries;
    }

    if (requestParameters.locationDischargePorts) {
      queryParameters['locationDischarge[ports][]'] = requestParameters.locationDischargePorts;
    }

    if (requestParameters.locationDischargeTradingAreas) {
      queryParameters['locationDischarge[tradingAreas][]'] = requestParameters.locationDischargeTradingAreas;
    }

    if (requestParameters.locationDischargeCountries) {
      queryParameters['locationDischarge[countries][]'] = requestParameters.locationDischargeCountries;
    }

    if (requestParameters.newFilter !== undefined) {
      queryParameters['newFilter'] = requestParameters.newFilter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/cargoes`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ObjectCollectionFromJSON(jsonValue));
  }

  /**
   */
  async getPortfolioCargoes(requestParameters: GetPortfolioCargoesRequest): Promise<ObjectCollection> {
    const response = await this.getPortfolioCargoesRaw(requestParameters);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetPortfolioCargoesDurationUnitEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}
/**
 * @export
 * @enum {string}
 */
export enum GetPortfolioCargoesQuantityUnitEnum {
  Mt = 'mt',
  Cbm = 'cbm',
  Teu = 'teu',
  Feu = 'feu',
  _45f = '45f',
}
/**
 * @export
 * @enum {string}
 */
export enum GetPortfolioCargoesGearEnum {
  Geared = 'geared',
  Gearless = 'gearless',
}
