import {FormValues} from './FormValues';
import {newPeriodOption} from './utils';
import {Option} from './UpdateFixtureRequestSchema/UpdateFixtureRequestSchema';
import dayjs from 'dayjs';

export const DefaultFormValues: FormValues = {
  contractType: 'pc',
  fixtureDate: dayjs().toISOString(),
  isExtension: false,
  isOptionDeclared: false,
  stations: [],
  vesselType: 'bulker',
  options: [
    {
      rateTerms: newPeriodOption.rateTerms as Option['rateTerms'],
      rateCurrency: newPeriodOption.rateCurrency,
      periodUnit: 'months',
    },
  ],
};
