import {CompositeLayer} from 'deck.gl';
import ForegroundTextLayer from '../../../screens/market/MarketMap/PortsLayer/PortLabelLayer/ForegroundTextLayer';
import BackgroundLayer from '../../../screens/market/MarketMap/PortsLayer/PortLabelLayer/BackgroundLayer';

class LabelLayer extends CompositeLayer {
  renderLayers() {
    return [
      new BackgroundLayer(
        this.getSubLayerProps({
          id: 'background',
          data: this.props.data,
          fontFamily: this.props.fontFamily,
          getColor: [255, 255, 255],
          getPosition: this.props.getPosition,
          getSize: this.props.getSize,
          getText: this.props.getText,
          opacity: this.props.opacity,
          getPixelOffset: this.props.getPixelOffset,
        })
      ),
      new ForegroundTextLayer(
        this.getSubLayerProps({
          id: 'text',
          data: this.props.data,
          fontFamily: this.props.fontFamily,
          getColor: [0, 0, 0],
          getPosition: this.props.getPosition,
          getSize: this.props.getSize,
          getText: this.props.getText,
          opacity: this.props.opacity,
          getPixelOffset: this.props.getPixelOffset,
        })
      ),
    ];
  }
}

LabelLayer.defaultProps = {
  getPixelOffset: [0, -30],
};
LabelLayer.layerName = 'LabelLayer';
export default LabelLayer;
