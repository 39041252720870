export const isPresent = (s: string | number | null | undefined): boolean => {
  if (typeof s === 'string') {
    return !!s && s.trim().length > 0;
  }
  if (typeof s === 'number') {
    return true;
  }
  // null | undefined
  return false;
};
