import {useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {clamp} from '../../../utils/helper';

interface UseHandleZoom {
  setZoom: (zoom: number) => void;
  increaseZoomBy: (increaseBy: number) => void;
  canIncrease: boolean;
  canDecrease: boolean;
}

export const useHandleZoom = (): UseHandleZoom => {
  const mapContext = useContext(MapContext);
  const {viewState} = mapContext.state;

  return {
    setZoom: zoom => {
      const newZoom = clamp(zoom, viewState.minZoom, viewState.maxZoom);
      mapContext.setViewState({...viewState, zoom: newZoom});
    },
    increaseZoomBy: increaseBy => {
      const newZoom = clamp(
        Math.round(Math.max(viewState.zoom, viewState.minZoom) + increaseBy),
        viewState.minZoom,
        viewState.maxZoom
      );
      mapContext.setViewState({...viewState, zoom: newZoom});
    },
    canDecrease: viewState.zoom > viewState.minZoom,
    canIncrease: viewState.zoom < viewState.maxZoom,
  };
};
