import {FC} from 'react';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import {getMedian} from '../../utils/getMedian';
import {NumberStatistics} from './NumberStatistics';

export const MedianWaitingTime: FC<{visits: MergedPortCongestionVisit[]; title: string; dataTestId?: string}> = ({
  visits,
  dataTestId,
  title,
}) => {
  const waitingDurationMedian = getMedian(visits.map(visit => visit.waitingDuration));
  return (
    <NumberStatistics
      dataTestId={dataTestId}
      title={title}
      value={waitingDurationMedian / 24}
      precision={2}
      suffix={'d'}
    />
  );
};
