import {useQueryClient} from '@tanstack/react-query';
import {GET_NEGOTIATION_TEMPLATE_QUERY_KEY} from './useGetNegotiationTemplateQuery';
import {GET_NEGOTIATION_TEMPLATE_SUMMARIES_QUERY_KEY} from './useGetNegotiationTemplateSummariesQuery';

export const useInvalidateNegotiationTemplateQueries = () => {
  const queryClient = useQueryClient();

  const invalidateNegotiationTemplateSummaries = async (templateId?: number) => {
    await queryClient.invalidateQueries({
      queryKey: GET_NEGOTIATION_TEMPLATE_SUMMARIES_QUERY_KEY,
    });
    await queryClient.invalidateQueries({
      queryKey: GET_NEGOTIATION_TEMPLATE_QUERY_KEY(templateId),
    });
  };
  return invalidateNegotiationTemplateSummaries;
};
