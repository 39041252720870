import {MutationOptions, useMutation} from '@tanstack/react-query';
import {useDispatch} from '../../../redux/react-redux';
import ProjectService from '../../../redux/ApiService/ProjectService';
import {ApiError} from '../../../api/utils/ApiError';
import {TODO} from '../../../utils/TODO';

interface Params {
  color: string;
  comment: string;
  name: string;
  type: 'cargo' | 'vessel';
}

export const useCreateProjectMutation = (options: MutationOptions<TODO, ApiError, Params>) => {
  const dispatch = useDispatch();
  const createProject = (body: Params) => dispatch(ProjectService.createProject(body));
  return useMutation({
    mutationFn: async (params: Params) => await createProject(params),
    ...options,
  });
};
