import {KeyboardEventHandler} from 'react';

export const preventDefaultOnEnterPress = (props?: {
  onKeyDown?: KeyboardEventHandler;
  onKeyUp?: KeyboardEventHandler;
}): {
  onKeyDown: KeyboardEventHandler;
  onKeyUp: KeyboardEventHandler;
} => ({
  onKeyDown: e => {
    if (e.key === 'Enter') {
      e.preventDefault?.();
      e.stopPropagation?.();
    }
    props?.onKeyDown?.(e);
  },
  onKeyUp: e => {
    if (e.key === 'Enter') {
      e.preventDefault?.();
      e.stopPropagation?.();
    }
    props?.onKeyUp?.(e);
  },
});
