import numeral from 'numeral';
import {PriceAndFuelType} from '../utils/useBunkerPriceProposalsQuery';
import {List} from 'antd';
import {InlineNumberEditorWithAutocompleteOptions} from './InlineNumberEditorWithAutocompleteOptions';
import Flag from '../../../../atoms/Flags/index';

type Props = {
  value: number;
  onChange: (value: number) => void;
  editModeAvailable?: boolean;
  options?: PriceAndFuelType[];
};

export const FuelEditor = (props: Props) => {
  return (
    <span style={{marginRight: 16, display: 'inline-block'}}>
      <InlineNumberEditorWithAutocompleteOptions
        copyright={
          <img
            style={{height: 32, width: 'auto', top: 0, right: 0, position: 'absolute'}}
            src={'/static/images/bunkerEx/bunkerExBlack.png'}
          />
        }
        options={props.options?.map(price => {
          let locationLabel = price.port;
          if (price.region) {
            locationLabel += ` - ${price.region}`;
          }
          const countryCode = price.country?.substring(0, 2);
          return {
            value: price.currentPrice!.toFixed(1),
            label: (
              <List.Item actions={[<span>{numeral(price.currentPrice).format('$ 0,0')} /MT</span>]}>
                <List.Item.Meta
                  avatar={<Flag countryCode={countryCode} />}
                  title={<span>{price.grade}</span>}
                  description={locationLabel}
                />
              </List.Item>
            ),
          };
        })}
        value={props.value}
        min={0}
        editModeAvailable={props.editModeAvailable}
        formattedValue={<span>{numeral(props.value).format('$ 0,0')} /MT</span>}
        onChange={v => props.onChange(v)}
      />
    </span>
  );
};
