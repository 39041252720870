import {Button, Form} from 'antd';
import dayjs from 'dayjs';
import {FC, useEffect} from 'react';
import {parseIntTS} from '../../../../../utils/parseNumberTS';
import {MarketVessel, MarketVesselOffer} from '../../../MarketVessel/MarketVesselOffer';
import {getLocations} from '../utils/getLocations';
import {VesselBulkerFormItems} from './VesselBulkerFormItems';
import {VesselContainerFormItems} from './VesselContainerFormItems';
import {useUpdateVesselOfferMutation, VesselFormValues} from './useUpdateVesselOfferMutation';
import {EditCircularFormLayout} from '../shared/EditCircularFormLayout';
import {MailView} from '../shared/MailView';
import {preventDefaultOnEnterPress} from '../../../../../utils/preventDefaultOnEnterPress';

export const VesselForm: FC<{
  vesselOffer: MarketVesselOffer;
  onSuccess: () => void;
}> = ({vesselOffer, onSuccess}) => {
  const isContainer = vesselOffer.vessel.vesselType === 'container';
  const [form] = Form.useForm();

  const initialValues: VesselFormValues = getVesselFormValues(vesselOffer.vessel);

  useEffect(() => {
    // I don't know why, but this is needed to make the form work
    form.setFieldValue('nextOpenLocations', initialValues.nextOpenLocations);

    // eslint grumbles because we use initialValues, but it is an aggregate of vesselOffer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vesselOffer, form]);

  const updateVesselOfferMutation = useUpdateVesselOfferMutation(
    {vesselOffer},
    {
      onSuccess,
    }
  );
  const isSaving = updateVesselOfferMutation.isPending;

  return (
    <Form<VesselFormValues>
      onFinish={values => {
        updateVesselOfferMutation.mutate(values);
      }}
      form={form}
      layout={'vertical'}
      initialValues={initialValues}
      {...preventDefaultOnEnterPress()}>
      <EditCircularFormLayout
        mailView={<MailView offer={vesselOffer} />}
        formItems={isContainer ? <VesselContainerFormItems form={form} /> : <VesselBulkerFormItems form={form} />}
        actionButtons={
          <Button data-testid={'SubmitBtn'} disabled={isSaving} htmlType={'submit'} type={'primary'}>
            Update circular
          </Button>
        }
      />
    </Form>
  );
};

const getVesselFormValues = (vessel: MarketVessel): VesselFormValues => {
  const nextOpenFrom = vessel.nextOpenFrom ? dayjs(vessel.nextOpenFrom) : null;
  const nextOpenTo = vessel.nextOpenTo ? dayjs(vessel.nextOpenTo) : null;

  const nextOpenLocations = getLocations({
    stations: vessel.stations,
    stationTypes: ['nextopen'],
  });
  return {...vessel, teu: getTEUValue(vessel), nextOpen: [nextOpenFrom, nextOpenTo], nextOpenLocations};
};

const getTEUValue = (vessel: MarketVessel): number | undefined => {
  const value = parseIntTS(vessel.intakes.find(intake => intake.type === 'teu')?.quantity);

  if (isNaN(value)) {
    return undefined;
  }
  return value;
};
