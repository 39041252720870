import {Button} from 'antd';
import React, {FC, useState} from 'react';
import styled from 'styled-components';
import {
  NegotiationSummary,
  NegotiationTemplate,
  NegotiationTemplateSummary,
  NewTerm,
} from '../../../../../../../api/node-backend/generated';
import {SelectContractTemplateModal} from './SelectContractTemplateModal';

type SelectContractTemplateModalButtonProps = {
  negotiation: NegotiationSummary;
  templateSummaries: NegotiationTemplateSummary[];
  useGetTemplateDetail: (id?: number) => {data?: NegotiationTemplate};
  onCreateTerms: (terms: NewTerm[]) => void;
};

export const SelectContractTemplateModalButton: FC<SelectContractTemplateModalButtonProps> = ({
  negotiation,
  templateSummaries,
  useGetTemplateDetail,
  onCreateTerms,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SelectContractTemplateModal
        negotiation={negotiation}
        templateSummaries={templateSummaries}
        useGetTemplateDetail={useGetTemplateDetail}
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={onCreateTerms}
      />
      <ButtonContainer>
        <Button type="primary" onClick={() => setShowModal(true)}>
          Use a contract template
        </Button>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0;
`;
