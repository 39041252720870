/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CancelNegotiationRequest
 */
export interface CancelNegotiationRequest {
  /**
   * The reason for the cancellation of the negotiation.
   * @type {string}
   * @memberof CancelNegotiationRequest
   */
  cancellationReason: string | null;
}

export function CancelNegotiationRequestFromJSON(json: any): CancelNegotiationRequest {
  return CancelNegotiationRequestFromJSONTyped(json, false);
}

export function CancelNegotiationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancelNegotiationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cancellationReason: json['cancellationReason'],
  };
}

export function CancelNegotiationRequestToJSON(value?: CancelNegotiationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cancellationReason: value.cancellationReason,
  };
}
