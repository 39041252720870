import {FC} from 'react';
import {getTermColors} from '../../../../../utils/colors';
import {getDerivedTermState} from '../../utils/getDerivedTermState';
import {TermContent} from '../TermContent/TermContent';
import {TermMeta} from '../TermMeta';
import {
  NegotiationParty,
  NegotiationTermHistoryItem,
  NegotiationTermStatus,
} from '../../../../../../../api/node-backend/generated';
import {TermModificationIcon} from '../../../History/Detail/TermModificationIcon';
import {TermStyleProvider} from '../../TermStyleProvider';
import {InlineTermTypeLabel} from '../TermType/TermTypeLabel';

type TermHistoryItemProps = {
  termHistoryItem: NegotiationTermHistoryItem & {roundIndex: number};
  party: NegotiationParty;
};

export const TermHistoryItem: FC<TermHistoryItemProps> = ({termHistoryItem, party}) => {
  const {term, roundIndex, termModification, committedAt, committedBy, negotiationStatus} = termHistoryItem;

  const {status: termStatus, type} = term;
  const {isCountered} = getDerivedTermState({
    party,
    roundCanBeEdited: false,
    term,
    termModification,
  });

  const colors = getTermColors(termStatus, negotiationStatus, isCountered);

  return (
    <TermStyleProvider $colors={colors}>
      <TermContent
        text={term.text}
        compact
        isRejected={termStatus === NegotiationTermStatus.Rejected}
        isRejectRequested={termStatus === NegotiationTermStatus.RejectionRequested}
        aboveTextAddon={<InlineTermTypeLabel termType={type} />}
        beforeTextAddon={<TermModificationIcon termModification={termModification} isOwnEdit={!isCountered} />}
        afterTextAddon={
          <TermMeta
            committedAt={new Date(committedAt!)}
            actor={committedBy!}
            isSelf={term.lastModifiedBy === party}
            roundIndex={roundIndex}
          />
        }
      />
    </TermStyleProvider>
  );
};
