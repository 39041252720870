import styled, {css} from 'styled-components';

export const Badge = styled.div<{$isSmall?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  ${({$isSmall}) =>
    $isSmall
      ? css`
          height: 1.5rem;
          padding: 0 0.5rem;
          font-size: var(--font-size-sm);
          line-height: 1rem;
        `
      : css`
          height: 2rem;
          padding: 0 0.75rem;
        `}
  border-radius: 1rem;

  & .icon {
    font-size: 1rem;
  }
`;
