import {FC} from 'react';
import {DatePicker} from '../../../../../../antd/components/DatePicker';
import dayjs from 'dayjs';
import {Form} from 'antd';
import {FormValues} from '../../FormValues';
import {DateTimeFormat} from '../../../../../../utils/DateTimeFormat';

export const LaycanDateRangePicker: FC = () => {
  const form = Form.useFormInstance<FormValues>();

  const laycanFrom = Form.useWatch<FormValues['laycanFrom']>('laycanFrom', form);
  const laycanTo = Form.useWatch<FormValues['laycanTo']>('laycanTo', form);

  const value: [dayjs.Dayjs | null, dayjs.Dayjs | null] = [
    laycanFrom ? dayjs(laycanFrom) : null,
    laycanTo ? dayjs(laycanTo) : null,
  ];

  return (
    <DatePicker.RangePicker
      allowClear
      format={DateTimeFormat.Date}
      style={{
        width: '100%',
      }}
      value={value}
      placeholder={['From/Laydays', 'To/Cancelling']}
      onChange={range => {
        if (!range) {
          form.setFieldsValue({
            laycanFrom: null,
            laycanTo: null,
          });
          return;
        }

        const [from, to] = range;
        const fromValue = from ? from.format(DateTimeFormat.IsoDate) + 'T00:00:00' : null;
        const toValue = to ? to.format(DateTimeFormat.IsoDate) + 'T00:00:00' : null;

        form.setFieldsValue({
          laycanFrom: fromValue,
          laycanTo: toValue,
        });
      }}
    />
  );
};
