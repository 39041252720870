import {asyncActions} from './utils/asyncActions';
import {API} from './Api';

const WEATHER = {
  GET: asyncActions('WEATHER_GET'),
};

const initialState = {
  name: '',
  country: '',
  temp: null,
  precipitation: null,
  conditions: [
    {
      description: '',
    },
  ],
  windspeed: null,
  winddeg: null,
  humidity: null,
  loading: false,
};

export const weatherReducer = (state = initialState, action: $TSFixMe) => {
  switch (action.type) {
    case WEATHER.GET.SUCCESS:
      return {
        ...action.payload,
        loading: false,
      };
    case WEATHER.GET.PENDING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

const apiEndpoint = (lat: number, lng: number) => `/api/weather/${lat},${lng}`;

const loadWeather = (lat: number, lng: number) => (dispatch: $TSFixMe) => {
  const endpoint = apiEndpoint(lat, lng);
  dispatch({
    type: API.REQUEST,
    payload: {
      method: 'GET',
      endpoint,
      next: WEATHER.GET,
    },
  });
};

const Actions = {
  loadWeather,
};

export default Actions;
