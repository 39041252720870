import {useMemo, useState} from 'react';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import NoResults from '../../screens/market/EmptyState/NoResults';
import {Intake} from '../../screens/CargoVesselForm/utils/IntakeSection/IntakeTypes';
import {vesselDBVesselsApi} from '../../api/node-backend/nodeBackendApis';
import {CustomColumnDef, DataGrid, DataGridProps} from '../DataGrid/DataGrid';
import {TODO} from '../../utils/TODO';
import {ColumnSort} from '@tanstack/react-table';
import orderBy from 'lodash/orderBy';
import {getMapVesselDetailByImo} from '../SeaboMap/MapDetails/utils/getMapVesselDetailByImo';
import {SeaboVessel} from '../../api/node-backend/generated';

type Props = Omit<DataGridProps<TODO>, 'columns'> & {
  imos: number[];
  id: string;
  columns: CustomColumnDef<TODO>[];
};

const getImoToVesselMap = async (imos: number[]): Promise<Record<number, SeaboVessel>> => {
  const vesselsResponse = await vesselDBVesselsApi.listVessels({
    listVesselsRequest: {
      filters: {
        imo: imos,
      },
    },
  });
  const map: Record<number, SeaboVessel> = {};
  vesselsResponse.items.forEach(vessel => {
    map[vessel.imo] = vessel;
  });
  return map;
};

export const IMOVesselGrid = (props: Props) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState<ColumnSort[]>([
    {
      id: 'name',
      desc: false,
    },
  ]);

  const totalCount = props.imos.length;

  const vesselImosInGrid: number[] = props.imos;

  const dataSourceQuery = useQuery({
    queryKey: ['IMOVesselGrid', props.id, vesselImosInGrid],
    queryFn: async () => {
      const imoToVesselMap = await getImoToVesselMap(vesselImosInGrid);
      const vesselPromises = vesselImosInGrid.map(async imo => {
        const detailsData = await getMapVesselDetailByImo(imo);

        const vessel: SeaboVessel | undefined = imoToVesselMap[imo];

        const intakes: Intake[] = [
          {type: 'teu', quantity: '' + detailsData?.teuQuantity, isHighCube: false},
          {type: 'teu14', quantity: '' + detailsData?.teu14Quantity, isHighCube: false},
        ];

        return {
          ...detailsData,
          ...vessel,
          id: detailsData?.vesselId,
          lengthOverall: detailsData?.loa,
          intakes,
        };
      });
      return Promise.all(vesselPromises);
    },
    placeholderData: keepPreviousData,
  });

  const sortedVessels = useMemo(
    () => orderBy(dataSourceQuery.data || [], sort[0].id, sort[0].desc ? 'desc' : 'asc'),
    [sort, dataSourceQuery.data]
  );

  const gridData = sortedVessels.slice(pageSize * pageNumber, pageSize * (pageNumber + 1));

  return (
    <>
      <DataGrid
        {...props}
        totalCount={totalCount}
        pageSize={pageSize}
        page={pageNumber}
        sorted={sort}
        onPageChange={newPage => {
          setPageNumber(newPage);
        }}
        onPageSizeChange={newPageSize => {
          setPageSize(newPageSize);
          setPageNumber(0);
        }}
        columns={props.columns}
        onSortedChange={sorted => {
          setSort([sorted]);
        }}
        data={gridData}
        loading={dataSourceQuery.fetchStatus === 'fetching'}
        TbodyComponent={dataSourceQuery.data?.length ? null : <NoResults type={'vessels'} />}
      />
    </>
  );
};
