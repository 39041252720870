import {useRef, FC} from 'react';
import styled from 'styled-components';
import {
  NegotiationRoundResult,
  NegotiationRoundResultSummary,
  NegotiationSummary as NegotiationSummaryType,
} from '../../../../api/node-backend/generated';
import {NegotiationCard} from '../../Components/shared';
import {NegotiationSummary} from '../../Components/NegotiationSummary';
import {NegotiationSummaryHeader} from '../../Components/NegotiationSummaryHeader';

type NegotiationHeaderProps = {
  negotiation: NegotiationSummaryType;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  currentRoundResult?: NegotiationRoundResult;
  isFirstRecapRound?: boolean;
};

export const NegotiationHeader: FC<NegotiationHeaderProps> = ({
  negotiation,
  roundResultSummaries,
  currentRoundResult,
  isFirstRecapRound,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <NegotiationHeaderContainer $isSticky ref={containerRef}>
      <NegotiationCard data-testid="NegotiationHeader">
        <NegotiationSummaryHeader
          negotiation={negotiation}
          roundResultSummaries={roundResultSummaries}
          currentRoundResult={currentRoundResult}
          isFirstRecapRound={isFirstRecapRound}
        />
        <DetailContainer>
          <NegotiationSummary
            data-testid="NegotiationHeaderCollapse"
            negotiation={negotiation}
            roundResultSummaries={roundResultSummaries}
            isFirstRecapRound={isFirstRecapRound}
          />
        </DetailContainer>
      </NegotiationCard>
    </NegotiationHeaderContainer>
  );
};

const NegotiationHeaderContainer = styled.div<{$isSticky?: boolean}>`
  ${({$isSticky}) =>
    $isSticky &&
    `
    position: sticky;
    top: calc(var(--header-height) - 55px);
    z-index: 40;

    ${NegotiationCard} {
      box-shadow: var(--box-shadow-widget-dragging);
    }
  `}
`;

const DetailContainer = styled.div`
  margin: 10px -10px 0;
  padding: 5px 10px 3px;
  background: var(--color-gray-6);
  border-radius: var(--border-radius-tab) !important;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
`;
