import minBy from 'lodash/minBy';
import {getBucketDate} from './getBucketDate';
import maxBy from 'lodash/maxBy';
import {getAllDaysInPeriod} from './getAllDaysInPeriod/getAllDaysInPeriod';
import {OfferBucket} from '../../../api/symfony/generated/models';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';

export const getBucketDayList = ({buckets}: {buckets: OfferBucket[]}) => {
  const allDaysInPeriod: Record<string, OfferBucket[]> = {};
  const start = minBy(buckets, bucket => getBucketDate(bucket));
  const end = maxBy(buckets, bucket => getBucketDate(bucket));

  if (!start || !end) {
    return {};
  }
  getAllDaysInPeriod({
    start: getBucketDate(start),
    end: getBucketDate(end),
  }).forEach(date => {
    allDaysInPeriod[date] = [];
  });

  buckets.forEach(bucket => {
    const date = getBucketDate(bucket);
    allDaysInPeriod[date.format(DateTimeFormat.IsoDate)].push(bucket);
  });

  return allDaysInPeriod;
};
