import {fetchJSON} from '../../../../../../api/utils/fetchJSON';
import {Step5Vessel} from '../../step5';

type PortPredictionResponse = {
  errorCode?: number;
  predictionNotes: string;
  portPredictionList?: Array<PredictedPort>;
};

export type PredictedPort = {
  alternativePortNames: string[];
  latitude: number;
  longitude: number;
  port: string;
  probability: number;
  visits: number;
};

export const getPredictedPort = async (vessel: Step5Vessel): Promise<PredictedPort | undefined> => {
  if (!vessel.lastPort) {
    return undefined;
  }
  const response = await fetchJSON<PortPredictionResponse>(
    'https://srv20110.dus4.fastwebserver.de/portPredictionLight',
    {
      method: 'POST',
      body: JSON.stringify({lastPortCode: vessel.lastPort.code}),
    }
  );

  return response.portPredictionList?.[0];
};
