import {Coordinates} from '../../../../utils/Coordinates';

type Params = {
  object: Icon;
  context: {
    index: number;
    data: Icon[];
  };
  zoomLevel: number;
};

type Icon = {
  start: Coordinates;
  end: Coordinates;
  speed: number;
};

export const getTransparentLevelForIcons = (params: Params): number => {
  const indexFromLast = params.context.data.length - params.context.index;

  /*
  The fixed part is influenced by the zoom level, the zoom level is between 0 = whole world and 10 = high zoom level.

  The higher the zoom level, the higher the fixed portion should be valued
   */
  const fix = (0.3 * params.zoomLevel) / 10;

  return 255 * fix + ((1 - fix) * 255) / indexFromLast;
};
