import {Cargo, CargoOption} from '../Type';
import {SearchHit} from '../../../api/symfony/generated/models/SearchHit';
import {List} from 'antd';
import styled from 'styled-components';
import {CargoDisplay} from '../../../atoms/DropdownDisplay/CargoDisplay';

export const makeCargoOption = (searchHit: SearchHit): CargoOption => {
  const source = searchHit.source as Cargo;
  return {
    id: source.id,
    key: source.id + '',
    type: 'cargo',
    source: source,
    value: `${source.id} ${source.chartererName}`,
    label: (
      <StyledListItem
        data-testid="CargoPickerOption"
        data-cy="CargoPickerOption"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        {<CargoDisplay cargo={source} />}
      </StyledListItem>
    ),
  };
};

const StyledListItem = styled(List.Item)`
  .dropdown-display {
    width: 100%;
  }
  .dropdown-display__sub-title {
    white-space: normal;
  }
`;
