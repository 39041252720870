import {StepResult} from './StepResult';
import {PreparedAisVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {FilterParams} from '../filterParams';
import {typeFilter} from '../filters/typeFilter';
import {Step0bResult} from './step0b';

export type Step1Result = StepResult<PreparedAisVessel>;

/**
 * filter only Bulker Vessels
 *
 * removes all containers, tankers and co from the calculation.
 */
export const step1 = ({params, preResult}: {preResult: Step0bResult; params: FilterParams}): Step1Result => {
  let vesselList = [...preResult.vessels];

  if (params.enabledFilters.type) {
    vesselList = typeFilter({vessels: vesselList, vesselType: params.vesselType});
  }

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: `Vessel of type: ${params.vesselType}`,
        count: vesselList.length,
        id: 'vesselType',
      },
    ],
  };
};
