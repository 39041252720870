import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React, {FC, useMemo} from 'react';
import {vesselTypeByName} from '../../../components/consts/vesselTypes';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {StatCard} from '../sharedComponents/StatCard';
import {getMedian} from '../utils/getMedian';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {getEachDateInRange} from '../utils/getEachDateInRange';

export const FinishedVisitsPerDate: FC<{
  visits: MergedVisits[];
  maxDate: string;
  onSetVisits: (vessels: MergedVisits[]) => void;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, maxDate, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const perDateGrouped = groupBy(visits, vessel => dayjs(vessel.endTimestamp).format(DateTimeFormat.Date));

    const min = dayjs(minBy(visits, 'endTimestamp')!.endTimestamp);
    const max = dayjs(maxDate);

    const daysBetween = getEachDateInRange(min, max);
    const labels: string[] = daysBetween.map(day => day.format(DateTimeFormat.Date));

    const values: Record<string, number[]> = {};

    const perTypeGrouped = groupBy(visits, vessel => vessel.vesselType);
    const types = Object.keys(perTypeGrouped);
    for (const type of types) {
      const perDateGrouped = groupBy(perTypeGrouped[type], vessel =>
        dayjs(vessel.endTimestamp).format(DateTimeFormat.Date)
      );
      const valuesByType = labels.map(value => perDateGrouped[value]?.length ?? 0);
      values[type] = valuesByType;
    }
    return {values, labels, perDateGrouped};
  }, [visits, maxDate]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perDateGrouped[event.name]);
              onSetAppliedFilter('Vessels by transit date', event.name);
            }
          },
        }}
        option={{
          title: [
            {
              left: 'center',
              text: 'Vessels by transit date',
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: dataSource.labels,
          },
          yAxis: {
            type: 'value',
          },
          series: Object.keys(dataSource.values).map((key: string) => {
            return {
              name: key,
              data: dataSource.values[key],
              color: vesselTypeByName(key)?.fillColor,
              type: 'bar',
              stack: 'total',
              showBackground: false,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)',
              },
            };
          }),
        }}
      />
    </StatCard>
  );
};

export const FinishedVisitsMedianWaitingTimePerDate: FC<{
  visits: MergedVisits[];
  onSetVisits: (vessels: MergedVisits[]) => void;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const perDateGrouped = groupBy(visits, vessel => dayjs(vessel.endTimestamp).format(DateTimeFormat.Date));

    const labels = uniq(
      visits
        .map(vessel => vessel.endTimestamp)
        .sort()
        .map(timeString => dayjs(timeString).format(DateTimeFormat.Date))
    );

    const values = labels.map(label =>
      getMedian(perDateGrouped[label].map(finishedVisit => finishedVisit.waitingDuration))
    );

    return {values, labels, perDateGrouped};
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perDateGrouped[event.name]);
              onSetAppliedFilter('Total transit time', event.name);
            }
          },
        }}
        option={{
          title: [
            {
              left: 'center',
              text: 'Total transit time',
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: dataSource.labels,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: dataSource.values,
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)',
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};

export enum ChartColors {
  'BULKER' = '#5470c6',
  'MPP' = '#91cc75',
  'CONTAINER' = '#fac858',
}
export const getChartColorFromVisit = (visit: MergedVisits) =>
  ChartColors[visit.vesselType as keyof typeof ChartColors];
const getHoursFromTimestamp = (timestamp: string) => +new Date(timestamp) / 1000 / 60 / 60;

export const VisitTimestampsWaterfall = React.memo(
  ({
    finishedVisits,
    sortKey,
    setSelectedVisit,
  }: {
    finishedVisits: MergedVisits[];
    sortKey: 'startTimestamp' | 'endTimestamp';
    setSelectedVisit: (visit: MergedVisits) => void;
  }) => {
    const sortedVisits = useMemo(() => sortBy(finishedVisits, visit => visit[sortKey]), [finishedVisits, sortKey]);
    const referenceTimestamp = getHoursFromTimestamp(sortedVisits[0].startTimestamp);
    const getBarColor =
      (hexOpacity: string) =>
      ({dataIndex}: {dataIndex: number}) =>
        getChartColorFromVisit(sortedVisits[dataIndex]) + hexOpacity;

    return (
      <ReactECharts
        style={{
          height: 'var(--graph-height)',
        }}
        onEvents={{
          click: ({dataIndex}: {dataIndex: number}) => {
            setSelectedVisit(sortedVisits[dataIndex]);
          },
        }}
        option={{
          title: [
            {
              left: 'center',
              text: 'Transit waterfall',
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '3%',
            right: 90,
            bottom: '3%',
            containLabel: true,
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                xAxisIndex: 'none',
              },
            },
          },
          dataZoom: [
            {
              yAxisIndex: 0,
              inside: true,
              type: 'inside',
              filterMode: 'weakFilter',
            },
            {
              yAxisIndex: 0,
              type: 'slider',
              filterMode: 'weakFilter',
              showDataShadow: false,
            },
            {
              xAxisIndex: 0,
              show: false,
            },
          ],
          yAxis: {
            type: 'category',
            inverse: true,
            data: sortedVisits.map(visit => dayjs(visit.startTimestamp).format(DateTimeFormat.DateTime)),
          },
          xAxis: {
            type: 'value',
            name: 'days ago',
            nameLocation: 'left',
            axisLine: {onZero: false},
            minInterval: 12,
            maxInterval: 48,
            axisLabel: {
              formatter: (value: number) => `${Math.round(60 - value / 24)}`,
            },
          },
          series: [
            {
              name: 'Start',
              type: 'bar',
              suffix: 'h',
              stack: 'total',
              itemStyle: {
                color: getBarColor('00'),
              },
              data: sortedVisits.map(visit => getHoursFromTimestamp(visit.startTimestamp) - referenceTimestamp),
            },
            {
              name: 'Waiting',
              type: 'bar',
              suffix: 'h',
              stack: 'total',
              itemStyle: {
                color: getBarColor('33'),
              },
              data: sortedVisits.map(visit => visit.waitingDuration),
            },
            {
              name: 'Passage',
              type: 'bar',
              suffix: 'h',
              stack: 'total',
              itemStyle: {
                color: getBarColor('FF'),
              },
              data: sortedVisits.map(visit => visit.passageDuration),
            },
          ],
        }}
      />
    );
  }
);
