import {useEffect, useState, FC} from 'react';
import {Attribute} from '../../../../../../atoms/Attribute/Attribute';
import styled, {css} from 'styled-components';
import {MapDetailData, MapDetailItem} from '../../../utils/types';
import {useDispatch, useSelector} from '../../../../../../redux/react-redux';
import {setMapDetailPortPreviewItem} from '../../../../../../redux/MapDetails';
import {cargoDetailData, vesselDetailData} from '../../../utils/utils';
import {getIsPayingCustomer} from '../../../../../../redux/selectors';
import {CaretLeftOutlined, CaretRightOutlined} from '@ant-design/icons';

type MapDetailPortAreaItemDetailProps = {
  item: MapDetailItem | null;
};

export const MapDetailPortAreaItemDetail: FC<MapDetailPortAreaItemDetailProps> = ({item}) => {
  const dispatch = useDispatch();
  const {portPreviewItemType: detailType} = useSelector(({mapDetails}) => mapDetails);
  const isPayingCustomer = useSelector(getIsPayingCustomer);

  // keep "side" in state and only update on new !!detailType for correct exit animation
  const [side, setSide] = useState<'left' | 'right'>(detailType === 'vessel' ? 'right' : 'left');
  useEffect(() => {
    if (!detailType) {
      return;
    }
    setSide(detailType === 'vessel' ? 'right' : 'left');
  }, [detailType]);

  if (!item) {
    return null;
  }

  const unsetDetailData = () => dispatch(setMapDetailPortPreviewItem(null, null));
  const data: MapDetailData =
    detailType === 'vessel' ? vesselDetailData(item, isPayingCustomer) : cargoDetailData(item, isPayingCustomer);

  return (
    <MapDetailPortAreaItemDetailContainer $side={side}>
      <BackBar onClick={unsetDetailData}>{side === 'left' ? <CaretRight /> : <CaretLeft />}</BackBar>
      {Object.keys(data)
        .filter(block => block !== 'meta')
        .map((block, idx) => (
          <AttributesContainer key={idx}>
            <AttributeList>
              {Object.keys(data[block]).map((item, idx) => (
                <AttributeContainer key={idx}>
                  <Attribute labelMin small label={item} value={data[block][item] || '-'} tab={'auto'} />
                </AttributeContainer>
              ))}
            </AttributeList>
          </AttributesContainer>
        ))}
    </MapDetailPortAreaItemDetailContainer>
  );
};

const CaretRight = styled(CaretRightOutlined)``;
const CaretLeft = styled(CaretLeftOutlined)``;

const BackBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  background: var(--map-list-item-active-color);
  cursor: pointer;

  ${CaretRight}, ${CaretLeft} {
    color: var(--color-gray-2);
  }
`;

const opposite = (side: 'left' | 'right') => (side === 'left' ? 'right' : 'left');
const MapDetailPortAreaItemDetailContainer = styled.div<{$side: 'left' | 'right'}>`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: var(--color-white);
  overflow-y: auto;
  z-index: 1;

  will-change: left, right;
  transition:
    left 0.2s,
    right 0.2s;

  ${({$side}) => css`
    padding-${opposite($side)}: 20px;
    padding-${$side}: 50px;

    &.slide-enter { ${opposite($side)}: -100%; }
    &.slide-enter-active { ${opposite($side)}: 0; }
    ${opposite($side)}: 0;
    &.slide-exit { ${opposite($side)}: 0; }
    &.slide-exit-active { ${opposite($side)}: -100%; }

    ${BackBar} {
      ${$side}: 0;
      border-${opposite($side)}: 1px solid var(--border-color-light);
      box-shadow: ${$side === 'left' ? '-' : ''}2px 0 5px -2px rgba(0,0,0,.1);
    }
  `}
`;

const AttributesContainer = styled.div`
  display: block;
  width: 100%;
  padding: 10px 0;
  border-bottom: var(--border-width) solid var(--border-color-light);

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const AttributeList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const AttributeContainer = styled.li`
  padding: 0;
  margin: 0;
`;
