import isEqual from 'lodash/isEqual';
import {useEffect, useMemo, useState} from 'react';
import {FilterItem} from '../../api/symfony/generated';
import {queryClient} from '../../api/utils/reactQueryClient';
import {removeEmpty} from '../../utils/removeEmpty';
import {FilterBranchDefinitionsDefault, FilterDatabaseType, FilterInternalType} from './FilterBranchDefinition';
import {
  loadFilterContext,
  transformFilterFromDatabase,
  transformFilterToDatabase,
} from './FilterConversion/FilterConversion';
import {getAppliedFilterBranches} from './getAppliedFilterBranches';
import {FilterProviderApi, LoadFilterItemOptions, LoadFilterItemOptionsDefault} from './FilterProviderApi';
import {FilterProviderState, getFilterProviderState} from './FilterProviderState';
import {FilterProviderProps} from './FilterProviderProps';

/**
 * Custom hook to manage filter state, API conversion and UI interaction.
 * The resulting `FilterProviderApi` is used by `FilterButton` components to sync filter state between app and remote.
 *
 * @template FilterBranchDefinitions - The type of filter branch definitions.
 * @param {FilterProviderProps<FilterBranchDefinitions>} props - The properties for the filter provider.
 * @returns {FilterProviderApi<FilterBranchDefinitions>} The API for interacting with the filters.
 */
export const useFilterProvider = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  props: FilterProviderProps<FilterBranchDefinitions>
): FilterProviderApi<FilterBranchDefinitions> => {
  type InternalType = FilterInternalType<FilterBranchDefinitions>;
  type DatabaseType = FilterDatabaseType<FilterBranchDefinitions>;

  const {name, filterProviderState: filterProviderStateDefault, filterBranchDefinitions, filterData} = props;

  // If an existing state was passed as prop, use it.
  const [filterProviderState, setFilterProviderState] = useState(
    filterProviderStateDefault ?? getFilterProviderState(props)
  );

  const getDefaultsForBranch = <BranchKey extends keyof FilterBranchDefinitions>(
    branch: BranchKey
  ): InternalType[BranchKey] => {
    return filterBranchDefinitions[branch]?.defaults as FilterInternalType<FilterBranchDefinitions>[BranchKey];
  };

  const getDefaults = (): InternalType => {
    const defaults = {} as InternalType;
    for (const branchKey of Object.keys(filterBranchDefinitions) as (keyof FilterBranchDefinitions)[]) {
      defaults[branchKey] = getDefaultsForBranch(branchKey);
    }
    return defaults;
  };

  const getAppliedFilters = (filter: InternalType): Record<keyof FilterBranchDefinitions, boolean> => {
    return getAppliedFilterBranches(filter, getDefaults());
  };

  /**
   * Converts the internal filter state to API format.
   */
  const runToApiTransformer = (filter: Partial<InternalType>): Partial<DatabaseType> => {
    return transformFilterToDatabase(filter, filterBranchDefinitions);
  };

  /**
   * Converts the API filter response to internal format using the filter context.
   */
  const runFromApiTransformer = async (filter: Partial<DatabaseType>): Promise<Partial<InternalType>> => {
    const filterContext = await loadFilterContext(queryClient, filterBranchDefinitions);
    return transformFilterFromDatabase(filter, filterContext, filterBranchDefinitions);
  };

  /**
   * Replaces the current filter with another one.
   */
  const loadFilter = async ({
    filterSettings,
    filterId,
    name,
    options,
  }: {
    filterSettings: Partial<DatabaseType> | undefined | null;
    filterId: number;
    name: string | undefined | null;
    options?: LoadFilterItemOptions;
    callsite?: string; // For debugging purposes
  }): Promise<void> => {
    const nonEmptyFilter = filterSettings ? removeEmpty(filterSettings) : {};
    const internalFilter = await runFromApiTransformer(nonEmptyFilter);
    const internalFilterSettings: InternalType = {
      ...getDefaults(),
      ...internalFilter,
    };
    const appliedFilters = getAppliedFilters(internalFilterSettings);
    const newFilterState: FilterProviderState<FilterBranchDefinitions> = {
      ...filterProviderState,
      activeFilterId: filterId,
      activeFilterName: name,
      internalFilterSettings,
      appliedFilters,
      loadedFilter: internalFilterSettings,
      disableSaveButton: true,
      hasResetted: true,
    };
    setFilterProviderState(newFilterState);

    const defaultedOptions: LoadFilterItemOptions = {...LoadFilterItemOptionsDefault, ...options};
    const {onFilterSettingsChangeEnabled} = defaultedOptions;

    if (onFilterSettingsChangeEnabled) {
      props.onFilterSettingsChange(runToApiTransformer(newFilterState.internalFilterSettings));
    }
    props.onFilterProviderStateChange(newFilterState);
  };

  /**
   * On mount loads filter context and stores it in state so every consecutive call will be deduped.
   * Also loads the filter if it was passed as prop.
   */
  useEffect(() => {
    if (!filterData?.filterSettings) {
      return;
    }
    void loadFilterContext(queryClient, filterBranchDefinitions);
    void loadFilter({
      callsite: 'useEffect/onMount',
      filterSettings: filterData.filterSettings,
      filterId: filterData.id!,
      name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Stringified version of the filter settings to prevent useEffect loops below from filterSettings that change reference every render.
   */
  const filterSettingsString = useMemo(() => JSON.stringify(filterData?.filterSettings), [filterData?.filterSettings]);

  /**
   * Loads a new filter that was passed as prop.
   */
  useEffect(() => {
    const isFilterReset = filterProviderState.hasResetted;
    const shouldLoadFilter = filterData?.filterSettings && !filterProviderState.activeFilterId;
    const shouldReloadFilter = filterData?.shouldReload;
    if (isFilterReset || (!shouldLoadFilter && !shouldReloadFilter)) {
      return;
    }
    void loadFilter({
      callsite: 'useEffect/onPropsChange',
      filterSettings: filterData.filterSettings,
      filterId: filterData.id!,
      name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterSettingsString,
    filterData?.shouldReload,
    filterProviderState.hasResetted,
    filterProviderState.activeFilterId,
  ]);

  /**
   * Replaces all filter branches with the default values from their corresponding definitions.
   */
  const resetAll = (): void => {
    setFilterProviderState(prev => {
      const internalFilterSettings = getDefaults();
      const appliedFilters = getAppliedFilters(internalFilterSettings);
      const newFilterState: FilterProviderState<FilterBranchDefinitions> = {
        ...prev,
        activeFilterId: null,
        internalFilterSettings,
        appliedFilters,
        hasResetted: true,
        disableSaveButton: true,
        loadedFilter: {},
      };

      props.onFilterSettingsChange(runToApiTransformer(newFilterState.internalFilterSettings));
      props.onFilterProviderStateChange(newFilterState);

      return newFilterState;
    });
  };

  /**
   * Applies the filter settings to the internal state and calls the onFilterSettingsChange callback using the database type.
   */
  const onApplyClick = <BranchKey extends keyof FilterBranchDefinitions>(
    branch: BranchKey,
    branchValues: Partial<InternalType[BranchKey]>
  ): void => {
    setFilterProviderState(prev => {
      const internalFilterSettings: InternalType = {
        ...prev.internalFilterSettings,
        [branch]: branchValues,
      };
      const appliedFilters = {
        ...prev.appliedFilters,
        [branch]: !isEqual(branchValues, getDefaultsForBranch(branch)),
      };
      const newFilterState: FilterProviderState<FilterBranchDefinitions> = {
        ...prev,
        internalFilterSettings,
        appliedFilters,
        disableSaveButton: isEqual(internalFilterSettings, prev.loadedFilter),
      };

      const transformedFilterSettings = runToApiTransformer(newFilterState.internalFilterSettings);
      props.onFilterSettingsChange(transformedFilterSettings);
      props.onFilterProviderStateChange(newFilterState);
      props.onFilterSettingsChangeByUser?.();

      return newFilterState;
    });
  };

  /**
   * Replaces the current filter branch with the default values from the filter branch definitions.
   */
  const onResetClick = <BranchKey extends keyof FilterBranchDefinitions>(branch: BranchKey): void => {
    setFilterProviderState(prev => {
      const branchDefaults = getDefaultsForBranch(branch);
      const internalFilterSettings: InternalType = {
        ...prev.internalFilterSettings,
        [branch]: branchDefaults,
      };
      const appliedFilters = getAppliedFilters(internalFilterSettings);
      const newFilterState: FilterProviderState<FilterBranchDefinitions> = {
        ...prev,
        internalFilterSettings,
        appliedFilters,
        disableSaveButton: isEqual(internalFilterSettings, prev.loadedFilter),
      };

      props.onFilterSettingsChange(runToApiTransformer(newFilterState.internalFilterSettings));
      props.onFilterProviderStateChange(newFilterState);
      props.onFilterSettingsChangeByUser?.();

      return newFilterState;
    });
  };

  /**
   * User trigger to replace all filter branches with the default values from their corresponding definitions.
   */
  const onResetAllClick = () => {
    resetAll();
    props.onFilterSettingsChangeByUser?.();
  };

  /**
   * Loads a new filter item by its id.
   */
  const loadFilterItem = async (filter: FilterItem, options?: LoadFilterItemOptions): Promise<void> => {
    const {id: filterId, name, filterSettings} = filter;
    await loadFilter({
      callsite: 'loadFilterItem',
      filterSettings,
      filterId,
      name,
      options,
    });
  };

  const {internalFilterSettings, appliedFilters, disableSaveButton, activeFilterId, activeFilterName} =
    filterProviderState;

  const defaults = getDefaults();
  const appliedFilterCount = Object.values(appliedFilters).filter(Boolean).length;
  const isAnyFilterApplied = appliedFilterCount > 0;

  return {
    name,
    internalFilterSettings,
    defaults,
    appliedFilterCount,
    isAnyFilterApplied,
    appliedFilters,
    activeFilterName,
    activeFilterId,
    disableSaveButton,
    resetAll,
    onApplyClick,
    onResetClick,
    onResetAllClick,
    loadFilterItem,
  };
};
