/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationActor,
  NegotiationActorFromJSON,
  NegotiationActorFromJSONTyped,
  NegotiationActorToJSON,
  NegotiationCargo,
  NegotiationCargoFromJSON,
  NegotiationCargoFromJSONTyped,
  NegotiationCargoToJSON,
  NegotiationRoundResult,
  NegotiationRoundResultFromJSON,
  NegotiationRoundResultFromJSONTyped,
  NegotiationRoundResultToJSON,
  NegotiationVessel,
  NegotiationVesselFromJSON,
  NegotiationVesselFromJSONTyped,
  NegotiationVesselToJSON,
} from './';

/**
 *
 * @export
 * @interface Negotiation
 */
export interface Negotiation {
  /**
   *
   * @type {string}
   * @memberof Negotiation
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Negotiation
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Negotiation
   */
  status: NegotiationStatusEnum;
  /**
   *
   * @type {NegotiationVessel}
   * @memberof Negotiation
   */
  vessel: NegotiationVessel;
  /**
   *
   * @type {NegotiationCargo}
   * @memberof Negotiation
   */
  cargo: NegotiationCargo;
  /**
   *
   * @type {NegotiationActor}
   * @memberof Negotiation
   */
  creator: NegotiationActor;
  /**
   *
   * @type {NegotiationActor}
   * @memberof Negotiation
   */
  counterpart: NegotiationActor;
  /**
   *
   * @type {Array<NegotiationRoundResult>}
   * @memberof Negotiation
   */
  roundResults: Array<NegotiationRoundResult>;
  /**
   *
   * @type {number}
   * @memberof Negotiation
   */
  roundCount: number;
  /**
   *
   * @type {string}
   * @memberof Negotiation
   */
  privateNote: string | null;
  /**
   * The reason for the cancellation of the negotiation. This is only provided but not required when the negotiation has been cancelled.
   * @type {string}
   * @memberof Negotiation
   */
  cancellationReason: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum NegotiationStatusEnum {
  Offer = 'offer',
  Ongoing = 'ongoing',
  Recap = 'recap',
  Settled = 'settled',
  Cancelled = 'cancelled',
}

export function NegotiationFromJSON(json: any): Negotiation {
  return NegotiationFromJSONTyped(json, false);
}

export function NegotiationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Negotiation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    status: json['status'],
    vessel: NegotiationVesselFromJSON(json['vessel']),
    cargo: NegotiationCargoFromJSON(json['cargo']),
    creator: NegotiationActorFromJSON(json['creator']),
    counterpart: NegotiationActorFromJSON(json['counterpart']),
    roundResults: (json['roundResults'] as Array<any>).map(NegotiationRoundResultFromJSON),
    roundCount: json['roundCount'],
    privateNote: json['privateNote'],
    cancellationReason: json['cancellationReason'],
  };
}

export function NegotiationToJSON(value?: Negotiation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    status: value.status,
    vessel: NegotiationVesselToJSON(value.vessel),
    cargo: NegotiationCargoToJSON(value.cargo),
    creator: NegotiationActorToJSON(value.creator),
    counterpart: NegotiationActorToJSON(value.counterpart),
    roundResults: (value.roundResults as Array<any>).map(NegotiationRoundResultToJSON),
    roundCount: value.roundCount,
    privateNote: value.privateNote,
    cancellationReason: value.cancellationReason,
  };
}
