import {ConfigProvider, Input, InputNumber, Table} from 'antd';
import {TimeCharterCalculationProp, TimeCharterCalculationResult} from './timeCharterCalculation';
import {Icon} from '../../../atoms/Icon';
import styled from 'styled-components';
import {Button} from '../../../atoms/Button/Button';
import clone from 'lodash/clone';
import {TODO} from '../../../utils/TODO';

export interface OtherExpensesItem {
  description: string;
  costs: number;
}

export interface OtherExpensesInput {
  items: Array<OtherExpensesItem>;
}

export interface VesselProps {
  onChange: (obj: OtherExpensesInput) => void;
  inputState: TimeCharterCalculationProp;
  outputState: TimeCharterCalculationResult;
}

const otherExpensesItemTemplate: OtherExpensesItem = {
  description: '',
  costs: 0,
};

export const OtherExpenses = (props: VesselProps) => {
  const onUpdateItem = (index: number, field: keyof OtherExpensesItem, value: TODO) => {
    const items = [...props.inputState.otherExpenses.items];
    const item = {...items[index], [field]: value};

    if (field === 'costs' && value === 0) {
      items.splice(index, 1);
    } else {
      items[index] = item;
    }

    props.onChange({...props.inputState.otherExpenses, items});
  };

  return (
    <ConfigProvider renderEmpty={() => null}>
      <ExpensesTable
        pagination={false}
        size={'small'}
        dataSource={[...props.inputState.otherExpenses.items]}
        columns={[
          {
            key: 'description',
            title: 'Description',
            render: (v, _, index) => (
              <Input
                type={'text'}
                value={v}
                onChange={event => onUpdateItem(index, 'description', event.currentTarget.value)}
              />
            ),
            dataIndex: 'description',
          },
          {
            key: 'costs',
            title: 'Costs',
            render: (v, _, index) => (
              <InputNumber
                addonAfter={'$'}
                value={v}
                onChange={event => onUpdateItem(index, 'costs', parseFloat(event))}
              />
            ),
            dataIndex: 'costs',
          },
          {
            key: 'remove',
            title: '',
            render: (_, __, index) => <Icon type="close" onClick={() => onUpdateItem(index, 'costs', 0)} />,
            dataIndex: 'remove',
          },
        ]}
      />
      <AddButton
        onClick={() => {
          props.onChange({
            ...props.inputState.otherExpenses,
            items: [...props.inputState.otherExpenses.items, clone(otherExpensesItemTemplate)],
          });
        }}
        icon
        label={
          <>
            <Icon type={'item-add-selection-solid'} color="blue" />
            Add expense
          </>
        }
      />
    </ConfigProvider>
  );
};

const ExpensesTable = styled(Table)`
  margin-top: 2px;

  .ant-table-thead {
    > tr {
      padding: 4px 0;
      height: 38px;

      > th {
        font-weight: 700;
        font-size: var(--font-size-sm);
        color: var(--color-gray-2);
        text-transform: uppercase;
      }
    }
  }

  .ant-table-tbody .ant-table-row .ant-table-cell {
    padding: 5px;
    min-width: 0;

    .icon {
      line-height: 30px !important;
      margin-right: 5px;
    }
  }
`;

const AddButton = styled(Button)`
  margin-top: 20px;
  text-transform: uppercase;

  .icon {
    vertical-align: middle;
    margin-right: 6px;
  }
`;
