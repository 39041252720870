import {useSelector} from '../../redux/react-redux';
import User from '../../model/User';
import {getUser} from '../../redux/selectors';
import {assert} from '../../utils/assert';

/**
 * Returns the current user.
 * The user is guaranteed to be available.
 */
export const useUser = (): User => {
  const user: User | null = useSelector(getUser);
  assert(user, 'user cant be null at this point');
  return user;
};

// Used in parts of the platform where guests have access.
export const useUserOrNull = (): User | null => {
  const user: User | null = useSelector(getUser);
  return user;
};
