import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import dayjs from 'dayjs';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {PortCongestionVisit} from '../../../../api/node-backend/generated';

export const arriveDateColumn = (): CustomColumnDef<PortCongestionVisit> => ({
  header: () => (
    <span>
      Arrive
      <br />
      Departure
    </span>
  ),
  id: 'arrive',
  accessorFn: d => (
    <span>
      {dayjs(d.startTimestamp).format(DateTimeFormat.DateTime)}
      <br />
      {dayjs(d.endTimestamp).format(DateTimeFormat.DateTime)}
    </span>
  ),
  cell: info => info.getValue(),
  minWidth: 4,
});
