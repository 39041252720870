import styled from 'styled-components';

export const DatePickerPairLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  column-gap: 8px;

  > div {
    flex: 1 1 auto;
  }
`;
