import {OneCalcResult, oneCalcFirstStep, oneCalcRecalculate} from './oneCalc';
import produce from 'immer';
import {calcBreakEvenFreightIdea} from './calcBreakEvenFreightIdea';
import {Assumptions, Cargo, CargoProject, Vessel, VesselProject} from '../types';
import {StowageFactor} from '../../../StowageFactorPicker/useStowageFactorsQuery';

type DoFirstCalcWithBreakEvenParams = {
  cargo: Cargo;
  cargoProject?: CargoProject;
  vesselProject?: VesselProject;
  vessel: Vessel;
  stowageFactors: StowageFactor[];
  assumptions: Assumptions;
  id: number;
};

export const doFirstCalcWithBreakEven = async (params: DoFirstCalcWithBreakEvenParams): Promise<OneCalcResult> => {
  const oneCalc = await oneCalcFirstStep({
    vesselProject: params.vesselProject,
    cargoProject: params.cargoProject,
    vessel: params.vessel,
    cargo: params.cargo,
    assumptions: params.assumptions,
    id: params.id,
    stowageFactors: params.stowageFactors,
  });
  const inputState = produce(oneCalc.voyageCalculationInput, draftInputState => {
    draftInputState.cargo.freightIdea = calcBreakEvenFreightIdea({oneCalc});
    return draftInputState;
  });
  return oneCalcRecalculate({oneCalc, voyageCalculationInput: inputState});
};
