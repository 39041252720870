import {HeaderRow} from '../../Definitions/HeaderRow';
import {Header} from '../../Definitions/Header';

export const RouteHeaderRow = () => {
  return (
    <HeaderRow>
      <Header width={160}>Consumption</Header>
      <Header width={160}>Aux consumption</Header>
      <Header width={140}>Speed</Header>
      <Header width={230}>Sea margin</Header>
      <Header calculated width={120}>
        Distance
      </Header>
      <Header calculated width={120}>
        ECA
      </Header>
      <Header calculated width={120}>
        HRA
      </Header>
      <Header calculated width={120}>
        Duration
      </Header>
      <Header calculated width={120}>
        CO2 emissions
      </Header>
      <Header calculated width={120}>
        Start date
      </Header>
      <Header calculated width={120}>
        End date
      </Header>
    </HeaderRow>
  );
};
