/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Engine
 */
export interface Engine {
  /**
   *
   * @type {string}
   * @memberof Engine
   */
  mainEngineDesigner?: string;
  /**
   *
   * @type {string}
   * @memberof Engine
   */
  engineDesignation?: string;
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  mcoHp?: number;
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  mcoKw?: number;
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  mcoRpm?: number;
}

export function EngineFromJSON(json: any): Engine {
  return EngineFromJSONTyped(json, false);
}

export function EngineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Engine {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mainEngineDesigner: !exists(json, 'mainEngineDesigner') ? undefined : json['mainEngineDesigner'],
    engineDesignation: !exists(json, 'engineDesignation') ? undefined : json['engineDesignation'],
    mcoHp: !exists(json, 'mcoHp') ? undefined : json['mcoHp'],
    mcoKw: !exists(json, 'mcoKw') ? undefined : json['mcoKw'],
    mcoRpm: !exists(json, 'mcoRpm') ? undefined : json['mcoRpm'],
  };
}

export function EngineToJSON(value?: Engine | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mainEngineDesigner: value.mainEngineDesigner,
    engineDesignation: value.engineDesignation,
    mcoHp: value.mcoHp,
    mcoKw: value.mcoKw,
    mcoRpm: value.mcoRpm,
  };
}
