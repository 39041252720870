import {FC} from 'react';
import {DatePicker} from '../../../../../../antd/components/DatePicker';
import dayjs from 'dayjs';
import {DateTimeFormat} from '../../../../../../utils/DateTimeFormat';

export const FixtureDatePicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  const dateValue = value ? dayjs(value) : undefined;

  return (
    <DatePicker
      id="fixture-date"
      format={DateTimeFormat.Date}
      defaultValue={dayjs()}
      value={dateValue}
      onChange={newDate => {
        if (!newDate) {
          onChange?.(null);
          return;
        }
        const stringValue = newDate.format(DateTimeFormat.IsoDate) + 'T00:00:00+00:00';

        onChange?.(stringValue);
      }}
    />
  );
};
