import {FC} from 'react';
import {Marker} from 'react-map-gl';
import {VoyageAnimationPortVisit} from '../../model/VoyageAnimationPortVisit';
import MarkerImage from './pngwing.com.png';
import uniqBy from 'lodash/uniqBy';

export const PortMarkers: FC<{
  portVisits: VoyageAnimationPortVisit[];
}> = ({portVisits}) => {
  const ports = portVisits.map(portVisit => portVisit.port);
  const uniquePorts = uniqBy(ports, port => port.id);
  return (
    <>
      {uniquePorts.map((port, index) => (
        <Marker
          key={index}
          longitude={port.coordinates.lon}
          latitude={port.coordinates.lat}
          anchor="bottom"
          scale={0.1}>
          <img src={MarkerImage} height="35" alt={port.name} />
        </Marker>
      ))}
    </>
  );
};
