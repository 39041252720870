import React, {FC, CSSProperties} from 'react';
import classNames from 'classnames';
import './style.scss';

interface Props {
  style?: CSSProperties;
  paddingLarge?: boolean;
  paddingVert?: boolean;
  flex?: boolean;
  isTransparent?: boolean;
  children: React.ReactNode;
  testID?: string;
  className?: string;
}

const Card: FC<Props> = ({
  children,
  style,
  paddingLarge,
  paddingVert,
  flex,
  testID,
  className,
  isTransparent = false,
}) => (
  <div
    data-testid={testID}
    style={style}
    className={classNames('card', className, {
      'card--padding-large': paddingLarge,
      'card--padding-vert': paddingVert,
      'card--flex': flex,
      'card--transparent': isTransparent,
    })}>
    {children}
  </div>
);

export default Card;
