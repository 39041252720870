import {FC} from 'react';
import {Tooltip} from 'antd';
import styled from 'styled-components';
import {useIsBallastQuery} from '../../queries/useIsBallastQuery';

const getIsBallastLabel = (isBallast?: boolean | null) => {
  if (isBallast === undefined || isBallast === null) {
    return {ballastLabel: ' ', ballastDescription: 'No Laden data'};
  }
  return isBallast
    ? {ballastLabel: 'B', ballastDescription: 'Ballast'}
    : {ballastLabel: 'L', ballastDescription: 'Laden'};
};

export const BallastStatusQueried: FC<{
  imo?: number | null;
  type?: string;
  draft?: number | null;
  className?: string;
}> = ({imo, type, draft, className}) => {
  if (imo && draft && type === 'bulker') {
    return <BallastStatusWithQuery imo={imo} draft={draft} className={className} />;
  }
  return <BallastStatus isBallast={undefined} className={className} />;
};

export const BallastStatusWithQuery: FC<{
  imo?: number;
  draft?: number;
  className?: string;
}> = ({imo, draft, className}) => {
  const isBallastQuery = useIsBallastQuery({imo, draft});

  const isBallast = isBallastQuery.data;
  return <BallastStatus isBallast={isBallast} className={className} />;
};

export const BallastStatus: FC<{
  isBallast: boolean | undefined | null;
  className?: string;
}> = ({isBallast, className}) => {
  const {ballastLabel, ballastDescription} = getIsBallastLabel(isBallast);
  return (
    <Tooltip title={ballastDescription} placement="bottom" mouseEnterDelay={1}>
      <BallastStatusDot $isBallast={isBallast} className={className}>
        <span>{ballastLabel}</span>
      </BallastStatusDot>
    </Tooltip>
  );
};

export const BallastStatusDot = styled.div<{$isBallast?: boolean | null}>`
  --circumference: 12px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--circumference);
  width: var(--circumference);
  border-radius: 50%;

  ${({$isBallast}) => {
    if ($isBallast === undefined || $isBallast === null) {
      return 'background: var(--color-gray-3);';
    }
    return 'background: ' + ($isBallast ? 'var(--color-red);' : 'var(--color-green-dark);');
  }}

  span {
    position: relative;
    top: 0.5px;
    font-size: var(--font-size-xs) !important;
    color: var(--color-white) !important;
    line-height: 1;
    opacity: 0.5;
    text-decoration: none !important;
  }
`;
