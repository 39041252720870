import dayjs, {Dayjs} from 'dayjs';
import {PreparedAisVessel} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {isEtaOlderThan30Days} from '../../isEtaOlderThan30Days';
import {FilterParams} from '../filterParams';
import {Step0aResult} from './step0a';
import {StepResult} from './StepResult';

export type Step0bResult = StepResult<PreparedAisVessel>;

export const step0b = ({
  params,
  preResult,
  currentTime,
}: {
  preResult: Step0aResult;
  params: FilterParams;
  currentTime?: Dayjs;
}): Step0bResult => {
  let vesselList = [...preResult.vessels];

  if (!params.enabledFilters.etaActuality) {
    return {
      vessels: vesselList,
      loggedFilters: [...preResult.loggedFilters],
    };
  }

  vesselList = vesselList.filter(vessel => {
    if (!vessel.eta) {
      return true;
    }
    return isEtaOlderThan30Days({vessel: vessel, currentTime: dayjs(currentTime)});
  });

  return {
    vessels: vesselList,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: `ETA updated w. 30d`,
        count: vesselList.length,
        id: 'etaUpdated',
      },
    ],
  };
};
