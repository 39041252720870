import capitalize from 'lodash/capitalize';
import {FC} from 'react';
import {ArrayFilter} from '../../../../atoms/Filter/ArrayFilter';
import {contractTypes, ContractTypeCode} from '../../../CargoVesselForm/CargoSections/ContractType';
import styled from 'styled-components';
import {CargoContractType, CargoHexagon} from '../../../../atoms/CargoHexagon/CargoHexagon';
import Checkbox from '../../../../atoms/Checkbox';

const contractTypeFilterOptions: CargoContractType[] = ['vc', 'tct', 'pc', 'bc'];

type NegotiationContractTypeFilterProps = {
  values: CargoContractType[];
  onChange: (values: CargoContractType[]) => void;
};

export const NegotiationContractTypeFilter: FC<NegotiationContractTypeFilterProps> = ({values, onChange}) => {
  return (
    <ArrayFilter
      dataCy="negotiation-contract-type"
      dataTestid="negotiation-contract-type"
      title="Contract Type"
      values={values}
      onApply={onChange}
      children={({values, handleChange}) =>
        contractTypeFilterOptions.map((key, index) => (
          <Checkbox
            key={key}
            data-cy={key}
            label={
              <ContractTypeBadge>
                <StyledCargoHexagon type={key} />
                {capitalize(contractTypes[key as ContractTypeCode])}
              </ContractTypeBadge>
            }
            checked={values[key] ?? false}
            onChange={e => handleChange(key, e.target.checked)}
            spMT={index === 0 ? 'zero' : 's'}
          />
        ))
      }
    />
  );
};

const StyledCargoHexagon = styled(CargoHexagon)`
  height: 20px;
`;

const ContractTypeBadge = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;
