import dayjs from 'dayjs';
import lodashMap from 'lodash/map';
import {Location} from '../../../../CargoPicker/CargoPicker';
import {SearchPickerPort} from '../../../../SearchPicker/SearchPickerPort';
import {DEFAULT_SEA_MARGIN} from '../../Context/blankState';
import {AlternativePort, VoyageInput, VoyagePoint} from '../../VoyageInformation/VoyageTypes';
import {geoJsonLatLngToGeolibCoordinates} from '../geoJsonLatLngToGeolibCoordinates';
import {getPwwdRateInMt} from '../getPwwdRateInMt';
import {isPointInSECA} from '../isPointInSECA';
import {mapCargoToQuantityInMts} from '../mapperParts/cargo/mapCargoToQuantityInMts';
import {getTerm} from './getTerm';
import {getTurnTimeFromLocation} from './getTurnTimeFromLocation';
import {TODO} from '../../../../../utils/TODO';

type LocationWithStationType = Location & SearchPickerPort & {stationType: string};

type Cargo = TODO;

type Params = {cargo: Cargo};

const getRawTerm = (location: Location): string | undefined => {
  if (location.additionalInfo?.terms) {
    return location.additionalInfo.terms.toUpperCase();
  }
  return undefined;
};

export const mapCargoToVoyageInput = ({cargo}: Params): VoyageInput => {
  const inputState: VoyageInput = {
    routes: [],
    points: [],
    routesCalculated: false,
  };

  const cargoQuantity = mapCargoToQuantityInMts(cargo);

  const stowageFactor = cargo.commodityStowageFactor ?? 1;

  if (cargo.laycanTo) {
    inputState.laycanTo = dayjs(cargo.laycanTo);
  }
  if (cargo.laycanFrom) {
    inputState.laycanFrom = dayjs(cargo.laycanFrom);
  }

  const locations: LocationWithStationType[] = cargo.stations
    .map((station: TODO) => {
      const stationLocations: LocationWithStationType[] = lodashMap(station.locations, (location: TODO) => ({
        ...location,
        stationType: station.type,
      }));
      return stationLocations;
    })
    .flat();
  const locationsDischage = locations.filter(
    location =>
      location.coordinates !== undefined && location.coordinates !== null && location.stationType === 'discharge'
  );

  const locationsLoading = locations.filter(
    location =>
      location.coordinates !== undefined && location.coordinates !== null && location.stationType !== 'discharge'
  );

  const dischargeAlternativePorts: AlternativePort[] = locationsDischage.map((location): AlternativePort => {
    const term = getTerm(location);
    const turnTimeInHours = getTurnTimeFromLocation(location);

    const alternativePort: AlternativePort = {
      name: location.name,
      turnTimeInHours,
      coordinates: location.coordinates!,
      countryCode: location.country,
      dischargingRate:
        location.stationType === 'discharge'
          ? getPwwdRateInMt({
              additionalInfo: location.additionalInfo,
              stowageFactor,
              quantityInMt: cargoQuantity,
            })
          : undefined,
      loadingRate:
        location.stationType === 'loading'
          ? getPwwdRateInMt({
              additionalInfo: location.additionalInfo,
              stowageFactor,
              quantityInMt: cargoQuantity,
            })
          : undefined,
      termsLoading: location.stationType === 'loading' ? term : undefined,
      rawTerm: getRawTerm(location),
      termsDischarging: location.stationType === 'discharge' ? term : undefined,
      originalPort: location,
    };

    return alternativePort;
  });

  const loadingAlternativePorts: AlternativePort[] = locationsLoading.map((location): AlternativePort => {
    const term = getTerm(location);
    const turnTimeInHours = getTurnTimeFromLocation(location);

    return {
      name: location.name,
      coordinates: location.coordinates!,
      countryCode: location.country,
      turnTimeInHours,
      dischargingRate:
        location.stationType === 'discharge'
          ? getPwwdRateInMt({
              additionalInfo: location.additionalInfo,
              stowageFactor,
              quantityInMt: cargoQuantity,
            })
          : undefined,
      loadingRate:
        location.stationType === 'loading'
          ? getPwwdRateInMt({
              additionalInfo: location.additionalInfo,
              quantityInMt: cargoQuantity,
              stowageFactor,
            })
          : undefined,
      termsLoading: location.stationType === 'loading' ? term : undefined,
      rawTerm: getRawTerm(location),

      termsDischarging: location.stationType === 'discharge' ? term : undefined,
    };
  });

  const locationToVoyagePoint = (location: LocationWithStationType, type: 'discharge' | 'loading'): VoyagePoint => {
    const term = getTerm(location);
    const turnTimeInHours = getTurnTimeFromLocation(location);

    let alternativePorts: AlternativePort[] | undefined = undefined;
    if (type === 'discharge' && dischargeAlternativePorts.length > 1) {
      alternativePorts = dischargeAlternativePorts;
    }
    if (type === 'loading' && loadingAlternativePorts.length > 1) {
      alternativePorts = loadingAlternativePorts;
    }

    const load = type === 'loading' ? cargoQuantity : 0;
    const dis = type === 'discharge' ? cargoQuantity : 0;

    const point: VoyagePoint = {
      otherDuration: [],
      isInSECA: isPointInSECA({
        point: geoJsonLatLngToGeolibCoordinates(location.coordinates!),
      }),
      portCosts: 0,
      load: load,
      turnTimeInHours,
      dis: dis,
      alternativePorts,
      dischargingRate:
        location.stationType === 'discharge'
          ? getPwwdRateInMt({
              additionalInfo: location.additionalInfo,
              quantityInMt: cargoQuantity,
              stowageFactor,
            })
          : undefined,
      loadingRate:
        location.stationType === 'loading'
          ? getPwwdRateInMt({
              additionalInfo: location.additionalInfo,
              quantityInMt: cargoQuantity,
              stowageFactor,
            })
          : undefined,
      termsLoading: location.stationType === 'loading' ? term : undefined,
      termsDischarging: location.stationType === 'discharge' ? term : undefined,
      rawTerm: getRawTerm(location),
      isPort: location.type === 'port',
      types: [type],
      name: location.name,
      coordinates: location.coordinates!,
      countryCode: location.country,
      originalPort: location,
    };

    return point;
  };

  inputState.points = [];

  if (locationsLoading.length > 0) {
    inputState.points.push(locationToVoyagePoint(locationsLoading[0], 'loading'));
  }
  if (locationsDischage.length > 0) {
    inputState.points.push(locationToVoyagePoint(locationsDischage[0], 'discharge'));
  }

  const routeAmount = inputState.points.length - 1;

  for (let i = 0; i < routeAmount; i++) {
    inputState.routes.push({
      distance: 0,
      seaMargin: DEFAULT_SEA_MARGIN,
    });
  }

  return inputState;
};
