import {authenticationApi} from '../../../api/symfony/symfonyApi';
import {useMutation} from '@tanstack/react-query';
import {LoginRequest, LoginResponse} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';
import React, {useState} from 'react';
import {Center} from '../../../components/Center/Center';
import {LoginForm, LoginFormValues} from './LoginForm';
import {assert} from '../../../utils/assert';
import {useChangeBrowserUrl} from '../../../utils/useChangeBrowserUrl';
import {
  BottomArea,
  BottomLabel,
  BottomLink,
  Dialog,
  DividerStyled,
  StyledIcon,
  TitleStyled,
} from '../utils/shared-components';
import {Link} from 'react-router-dom';

export const LoginDialog = () => {
  const changeBrowserUrl = useChangeBrowserUrl();
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const loginMutation = useMutation<LoginResponse, ApiError, LoginRequest>({
    mutationFn: async (loginRequest: LoginRequest): Promise<LoginResponse> =>
      await authenticationApi.login({loginRequest}),
    onSuccess: response => {
      if (response.success) {
        assert(response.redirectUrl);
        changeBrowserUrl(response.redirectUrl);
      } else {
        setErrorMessageVisible(true);
      }
    },
  });

  const onSubmit = (formValues: LoginFormValues) => {
    loginMutation.mutate(formValues);
  };

  const onChange = () => {
    setErrorMessageVisible(false);
  };

  const errorMessage = errorMessageVisible ? 'Email/Password is not correct' : undefined;

  return (
    <Dialog>
      <Center>
        <TitleStyled level={3}>Log in to seabo</TitleStyled>
      </Center>
      <DividerStyled />
      <LoginForm
        errorMessage={errorMessage}
        submitting={loginMutation.isPending}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <BottomArea>
        <BottomLabel>No account yet?</BottomLabel>
        <Link to="/signup">
          <BottomLink block>
            <StyledIcon type="arrow-right" /> <span>Try seabo for free. No credit card required</span>
          </BottomLink>
        </Link>
      </BottomArea>
    </Dialog>
  );
};
