// From https://www.geodatasource.com/developers/javascript

import {LonLatCoordinates} from '../../../../../utils/Coordinates';

const DEGREES_TO_RADIANS_FACTOR = Math.PI / 180;

/**
 * Radius of the Earth in kilometers
 */
const R = 6371;

/**
 * Returns the distance between two points on the Earth's surface specified by their latitude and longitude in decimal degrees.
 *
 * Uses the Haversine formula.
 *
 * @return the distance in kilometers.
 */
export const distance = (coord1: LonLatCoordinates, coord2: LonLatCoordinates): number => {
  // Convert degrees to radians
  const lon1 = coord1[0] * DEGREES_TO_RADIANS_FACTOR;
  const lat1 = coord1[1] * DEGREES_TO_RADIANS_FACTOR;
  const lon2 = coord2[0] * DEGREES_TO_RADIANS_FACTOR;
  const lat2 = coord2[1] * DEGREES_TO_RADIANS_FACTOR;

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};
