import {ApiServiceEntry, makeApiService} from '../ApiService';
import {createSelector} from 'reselect';
import debounce from 'lodash/debounce';
import {Coordinates} from '../../../utils/Coordinates';
import produce from 'immer';
import {TODO} from '../../../utils/TODO';
import {SeaboThunkAction} from '../SeaboThunkAction';
import {GetTradingAreasResponseData, GetTradingAreasResponseSchema} from './GetTradingAreasResponse';
import {GetPortsResponseData, GetPortsResponseSchema} from './GetPortsResponse';
import {MarketCargoDatabaseFilter} from '../../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';
import {MarketVesselDatabaseFilter} from '../../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';

type Filters = {
  cargoFilters?: MarketCargoDatabaseFilter | null;
  vesselFilters?: MarketVesselDatabaseFilter | null;
};

const entries: ApiServiceEntry[] = [
  {
    fn: 'getPorts',
    route: '/api/map/ports',
    method: 'GET',
    responseSchema: GetPortsResponseSchema,
  },
  {
    fn: 'getTradingAreas',
    route: '/api/map/trading-areas',
    method: 'GET',
    responseSchema: GetTradingAreasResponseSchema,
  },
  {
    fn: 'getItems',
    route: '/api/map/items',
    method: 'GET',
  },
  {
    fn: 'getPortItems',
    route: '/api/map/port/{portId}',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getAreaItems',
    route: '/api/map/area-items',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getAisVessels',
    route: '/api/map/ais-vessels',
    method: 'GET',
  },
  {
    fn: 'getVessel',
    route: '/api/map/vessels/{identifier}',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getVesselPortCalls',
    route: '/api/map/vessel/{identifier}/portcalls',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getVesselPositionHistory',
    route: '/api/map/vessel/{identifier}/history',
    method: 'GET',
    abortPrevCalls: true,
  },
  {
    fn: 'getPortfolioGroups',
    route: '/api/map/portfolio-groups',
    method: 'GET',
  },
  {
    fn: 'getSettings',
    route: '/api/users/mapsettings/{identifier}',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'saveSettings',
    route: '/api/users/mapsettings/{identifier}',
    method: 'POST',
    storeData: false,
    abortPrevCalls: true,
  },
];

const mapApi = makeApiService(entries, {apiName: 'map'});

export const reducers = mapApi.reducers;
export const actions = mapApi.actions;
export const actionTypes = mapApi.actionTypes;

const transformer = {transformers: {SUCCESS: (r: {data: TODO}) => r.data}};

export const getMarketCircularItems = (filters: Filters = {}) => {
  let queryParams;
  if (filters.cargoFilters) {
    queryParams = {
      cargoFilters: JSON.stringify(filters.cargoFilters),
    };
  }
  if (filters.vesselFilters) {
    queryParams = {
      ...queryParams,
      vesselFilters: JSON.stringify(filters.vesselFilters),
    };
  }
  return actions.getItems({queryParams, ...transformer});
};

export const getPortItems = (portId: TODO, filters: Filters = {}, queryParams: TODO = {}) => {
  if (filters.cargoFilters) {
    queryParams = {
      ...queryParams,
      cargoFilters: JSON.stringify(filters.cargoFilters),
    };
  }
  if (filters.vesselFilters) {
    queryParams = {
      ...queryParams,
      vesselFilters: JSON.stringify(filters.vesselFilters),
    };
  }
  return actions.getPortItems({
    params: {portId},
    queryParams,
    ...transformer,
  });
};

export const getAreaItems = (areas: TODO, filters: Filters = {}, params: TODO = {}) => {
  let queryParams = {areas, ...params};
  if (filters.cargoFilters) {
    queryParams = {
      ...queryParams,
      cargoFilters: JSON.stringify(filters.cargoFilters),
    };
  }
  if (filters.vesselFilters) {
    queryParams = {
      ...queryParams,
      vesselFilters: JSON.stringify(filters.vesselFilters),
    };
  }
  return actions.getAreaItems({queryParams, ...transformer});
};

// Get ais vessels not more often than every 3 minutes
const getAisVesselsDebounced = debounce(dispatch => dispatch(actions.getAisVessels(transformer)), 180 * 1000, {
  leading: true,
  trailing: false,
});

export const getPorts = (): SeaboThunkAction<GetPortsResponseData> => actions.getPorts(transformer);

export const getTradingAreas = (): SeaboThunkAction<GetTradingAreasResponseData> =>
  actions.getTradingAreas(transformer);
export const getAisVessels = () => getAisVesselsDebounced;
export const getPortfolioGroups = () => actions.getPortfolioGroups(transformer);
export const getVessel = (identifier: TODO) => actions.getVessel({params: {identifier}});
export const getVesselPortCalls = (identifier: TODO, queryParams: TODO = {}) =>
  actions.getVesselPortCalls({params: {identifier}, queryParams, ...transformer});

export type VesselPositionHistory = {
  ports: number[];
  route: {coordinates: Coordinates; spped: number}[];
} | null;

export const getVesselPositionHistory = (identifier: TODO, days: TODO) =>
  actions.getVesselPositionHistory({
    ...(days ? {queryParams: {days}} : {}),
    params: {identifier},
    transformers: {
      SUCCESS: (r: TODO) => {
        let meridianFix = 0;
        const route = r.data.route.map((position: TODO, i: TODO, route: TODO) => {
          if (i > 0) {
            const lastLng = route[i - 1].coordinates[0];
            if (lastLng < -90 || lastLng > 90) {
              const {coordinates} = position;
              if (lastLng > 0 && coordinates[0] < 0) {
                meridianFix += 360;
              } else if (lastLng < 0 && coordinates[0] > 0) {
                meridianFix -= 360;
              }
              return {...position, coordinates: [coordinates[0] + meridianFix, coordinates[1]]};
            }
          }
          return position;
        });
        return {route, ports: r.data.ports};
      },
    },
  });

const itemSelector = (map: TODO) => map.getItems.data || [];
const portSelector = (map: TODO) => map.getPorts.data?.items || [];
const tradingAreaSelector = (map: TODO) => map.getTradingAreas.data?.items || [];

const itemToSubReducer = (idToSub: TODO, g: TODO) => {
  if (g.subs) {
    idToSub[g.id] = g.subs;
  }
  return idToSub;
};

const subPortfolioSelector = createSelector(
  (map: TODO) => map.getPortfolioGroups.data || null,
  data => {
    if (data) {
      const {cargoes, vessels} = data;
      return {
        cargoes: cargoes.reduce(itemToSubReducer, {}),
        vessels: vessels.reduce(itemToSubReducer, {}),
      };
    }
    return null;
  }
);

const cargoSubToIdGenerator = (cargoSubPortfolios: TODO) => (c: TODO) => {
  c.subs = {};
  c.cargoIds.forEach((id: TODO) => {
    if (cargoSubPortfolios[id]) {
      for (const subId of cargoSubPortfolios[id]) {
        if (!c.subs[subId]) {
          c.subs[subId] = [id];
        } else {
          c.subs[subId].push(id);
        }
      }
    }
  });
};

const vesselSubToId = (vesselSubPortfolios: TODO, ids: TODO) => {
  const vesselSubs: TODO = {};
  for (const id of ids) {
    if (vesselSubPortfolios[id]) {
      for (const subId of vesselSubPortfolios[id]) {
        if (!vesselSubs[subId]) {
          vesselSubs[subId] = [id];
        } else {
          vesselSubs[subId].push(id);
        }
      }
    }
  }
  return vesselSubs;
};

const portsWithItemsSelector = createSelector(
  portSelector,
  itemSelector,
  subPortfolioSelector,
  (ports, portItems, subPortfolios) => {
    const portsWithItems: TODO = [];
    if (ports.length > 0 && portItems.ports && subPortfolios) {
      portItems.ports.forEach((portItem: TODO) => {
        const port = ports.find((port: TODO) => port.id === portItem.id);
        if (port) {
          const {vesselIds, ...portfolioRest} = portItem.portfolio;
          portfolioRest.cargoes.forEach(cargoSubToIdGenerator(subPortfolios.cargoes));
          portsWithItems.push({
            ...port,
            ...{
              ...portItem,
              portfolio: {...portfolioRest, vesselSubs: vesselSubToId(subPortfolios.vessels, vesselIds)},
            },
          });
        }
      });
    }
    return portsWithItems;
  }
);

export const smallPortsWithItems = createSelector(
  portsWithItemsSelector,
  () => []
  // ports => ports.filter(p => !p.isLarge)
);

export const largePortsWithItems = createSelector(
  portsWithItemsSelector,
  ports => ports
  // ports => ports.filter(p => p.isLarge)
);

export const largePorts = createSelector(
  portSelector,
  ports => ports
  // ports => ports.filter(p => p.isLarge)
);

export const smallPorts = createSelector(
  portSelector,
  () => []
  // ports => ports.filter(p => !p.isLarge)
);

export const tradingAreasGeoJson = createSelector(
  tradingAreaSelector,
  itemSelector,
  subPortfolioSelector,
  (tradingAreas, portItems, subPortfolios) => {
    const areaItems = portItems.areas || [];
    return {
      type: 'FeatureCollection',
      features: tradingAreas.map(
        ({
          id,
          name,
          code,
          coordinates,
          polygon,
        }: {
          id: TODO;
          name: string;
          code: TODO;
          coordinates: TODO;
          polygon: TODO;
        }) => {
          let areaItem = areaItems.find((a: TODO) => a.id === id);
          if (subPortfolios && areaItem) {
            areaItem = produce(areaItem, (draftAreaItem: TODO) => {
              draftAreaItem.portfolio.cargoes.forEach(cargoSubToIdGenerator(subPortfolios.cargoes));
              draftAreaItem.portfolio.portlessCargoes.forEach(cargoSubToIdGenerator(subPortfolios.cargoes));
              draftAreaItem.portfolio.vesselSubs = vesselSubToId(
                subPortfolios.vessels,
                draftAreaItem.portfolio.vesselIds
              );
              draftAreaItem.portfolio.portlessVesselSubs = vesselSubToId(
                subPortfolios.vessels,
                draftAreaItem.portfolio.portlessVesselIds
              );
            });
          }
          return {
            type: 'Feature',
            properties: {id, name, code, coordinates, ...(areaItem || {market: {}, portfolio: {}})},
            geometry: {type: 'Polygon', coordinates: polygon},
          };
        }
      ),
    };
  }
);
