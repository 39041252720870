/* eslint-disable no-console */
import {io, Socket} from 'socket.io-client';
import {NodeBackendBaseUrl} from '../../api/utils/NodeBackendBaseUrl';
import {ClientToServerEvents, ServerToClientEvents, ServerToClientEventType} from './SocketIOTypes';
import {debugLog} from './ServerPushDebugLog';
import {TODO} from '../../utils/TODO';

type RemoveListenerFunction = () => void;

/**
 * The client side of ServerPush / socket.io.
 *
 * ServerPush allows the server to send message to the client.
 */
export interface ServerPushSocket {
  /**
   * Registers a callback for receiving messages from the server.
   *
   * @return a function that deregisters the callback.
   */
  onMessage(event: ServerToClientEventType, callback: (message: PushMessagePayload) => void): RemoveListenerFunction;

  /**
   * Sends an "echo" message to the server. The server will respond with a string.
   *
   * Makes it easier to test socket.io communication.
   *
   * @param data some extra data sent to the server.
   * @param callback called with the server's reply.
   */
  emitEcho(data: string, callback: (reply: string) => void): void;

  disconnect(): void;
}

/**
 * A message is a JSON structured object message.
 */
export type PushMessagePayload = unknown;

export class ServerPushSocketImpl implements ServerPushSocket {
  private socket: Socket<ServerToClientEvents, ClientToServerEvents>;

  public constructor(accessToken: string) {
    this.socket = io(NodeBackendBaseUrl, {
      path: '/node-backend/socket.io',
      // See https://socket.io/docs/v4/middlewares/#sending-credentials
      auth: cb => {
        return cb({token: accessToken});
      },
    });

    this.listen();
  }

  private listen() {
    this.socket.on('connect', () => {
      debugLog('Connected with socket id %s', this.socket.id);
      this.emitEcho('Dancing in the rain', (reply: string) => {
        debugLog('echo: %j', reply);
      });
    });

    this.socket.on('connect_error', error => {
      console.error('connect error:', error.message, '/ data: ', (error as TODO).data);
    });

    this.socket.io.on('reconnect', () => {
      debugLog('reconnect');
    });

    this.socket.on('disconnect', reason => {
      debugLog(`disconnect - reason: %j`, reason);
    });
  }

  /**
   * Registers a callback for receiving messages from the server.
   */
  public onMessage(
    event: ServerToClientEventType,
    callback: (payload: PushMessagePayload) => void
  ): RemoveListenerFunction {
    debugLog('Listening to %j', event);
    this.socket.on(event, callback);
    const removeListener: RemoveListenerFunction = () => {
      this.socket.off(event, callback);
    };
    return removeListener;
  }

  emitEcho(data: string, callback: (reply: string) => void): void {
    this.socket.emit('echo', data, callback);
  }

  disconnect() {
    this.socket.disconnect();
  }
}

/**
 * Stub implementation used for tests.
 */
export class ServerPushSocketStub implements ServerPushSocket {
  public onMessage(
    _event: ServerToClientEventType,
    _callback: (message: PushMessagePayload) => void
  ): RemoveListenerFunction {
    return () => {};
  }

  emitEcho(_data: string, _callback: (reply: string) => void): void {}

  disconnect() {}
}
