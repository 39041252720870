import {ReactElement, useState, FC} from 'react';
import DefaultTabLink, {TabLinkProps} from './LinkComponents/DefaultTabLink';
import {TabPaneProps} from './TabPane';
import styled from 'styled-components';

type TabsProps = {
  id?: string;
  TabLinkComponent?: FC<TabLinkProps>;
  center?: boolean;
  subTabLink?: boolean;
  buttonGroupTab?: boolean;
  noMinWidth?: boolean;
  onChangeActive?: (active: number) => void;
  active?: number;
  className?: string;
  children: (boolean | ReactElement<TabPaneProps>)[];
  disabled?: boolean;
};

export const Tabs: FC<TabsProps> = props => {
  const [internActive, setInternActive] = useState<number>(props.active ?? 0);

  const onChangeActive = (value: number) => {
    props.onChangeActive?.(value);
    setInternActive(value);
  };
  const active = props.active ?? internActive;

  const {
    id,
    TabLinkComponent,
    center,
    subTabLink = false,
    buttonGroupTab = false,
    noMinWidth = false,
    children,
    disabled,
  } = props;

  const LinkComponent = TabLinkComponent ? TabLinkComponent : DefaultTabLink;

  const tabPanes: ReactElement<TabPaneProps>[] = children.filter(
    child => typeof child === 'object'
  ) as ReactElement<TabPaneProps>[];

  return (
    <StyledTabs $center={center} $disabled={disabled}>
      <StyledTabsList id={`tabs-list-${id}`} role="tablist">
        {tabPanes.map((tabPane, key) => {
          return (
            <LinkComponent
              key={key}
              id={tabPane.props.id}
              active={key === active}
              label={tabPane.props.label}
              onClick={() => {
                if (!props.disabled) {
                  onChangeActive(key);
                }
              }}
              subTabLink={subTabLink}
              noMinWidth={noMinWidth}
              buttonGroupTab={buttonGroupTab}
            />
          );
        })}
      </StyledTabsList>
      <StyledTabsContent id="nav-tabContent">{tabPanes[active]}</StyledTabsContent>
    </StyledTabs>
  );
};

const StyledTabs = styled.div<{$center?: boolean; $disabled?: boolean}>`
  ${({$center}) =>
    $center
      ? `
    margin-bottom: 30px;

    ${StyledTabsList} {
      justify-content: space-between;
      padding: 0 30px; 
      text-align: center;
    }
    ${StyledTabsContent} {
      margin-top: 24px;
    }
  `
      : ''}
`;

const StyledTabsList = styled.div`
  display: flex;
`;

const StyledTabsContent = styled.div``;
