import styled from 'styled-components';

interface CostIndicatorProps {
  name: string;
  color?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const NameText = styled.span`
  font-size: var(--font-size-lg);
  color: var(--color-gray-2);
`;

export const CostIndicator: React.FC<CostIndicatorProps> = ({name, color, children, style}) => (
  <div
    data-cy={'costIndicator' + name}
    style={{
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 37.25,
      ...style,
    }}>
    <div data-cy="name" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      {color ? <div style={{width: 10, height: 10, borderRadius: 2, backgroundColor: color, marginRight: 3}} /> : null}
      <NameText>{name}</NameText>
    </div>
    <div data-cy="value">{children}</div>
  </div>
);
