/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Coordinate, CoordinateFromJSON, CoordinateFromJSONTyped, CoordinateToJSON} from './';

/**
 *
 * @export
 * @interface City
 */
export interface City {
  /**
   *
   * @type {number}
   * @memberof City
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof City
   */
  objectID: string;
  /**
   *
   * @type {string}
   * @memberof City
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof City
   */
  objectCountry: string;
  /**
   *
   * @type {Coordinate}
   * @memberof City
   */
  coordinate: Coordinate;
}

export function CityFromJSON(json: any): City {
  return CityFromJSONTyped(json, false);
}

export function CityFromJSONTyped(json: any, ignoreDiscriminator: boolean): City {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    objectID: json['objectID'],
    text: json['text'],
    objectCountry: json['objectCountry'],
    coordinate: CoordinateFromJSON(json['coordinate']),
  };
}

export function CityToJSON(value?: City | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    objectID: value.objectID,
    text: value.text,
    objectCountry: value.objectCountry,
    coordinate: CoordinateToJSON(value.coordinate),
  };
}
