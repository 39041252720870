import React, {Fragment, ReactElement, useEffect} from 'react';
import DetailsSection from '../../atoms/DetailsSection';
import {Validator} from '../../utils/Validator';
import {TODO} from '../../utils/TODO';

interface Validators {
  [key: string]: Validator | Record<string, Validator>;
}

const BindToProvider =
  (
    title: string,
    section: string,
    defaults: TODO,
    validators: Validators = {},
    transformers = {},
    hasBorderBottom = false,
    returnDetailSection = true,
    required = false
  ) =>
  (WrappedComponent: $TSFixMe) =>
  ({
    form,
    validations,
    validateFields,
    validateDynamicRows,
    onChange,
    registerForm,
    unRegisterForm,
    ...rest
  }: $TSFixMe) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      registerForm(section, defaults, transformers, validators);
      return () => {
        unRegisterForm(section);
      };
    }, [registerForm, unRegisterForm]);

    if (!form[section] || !rest.defaults[section]) {
      return null;
    }

    return (
      <WrappedComponent
        key={`form-${section}`}
        title={title}
        values={form[section]}
        validations={validations[section]}
        onChange={(field: $TSFixMe, value: $TSFixMe, callback: $TSFixMe) => {
          onChange(section, {...form[section], [field]: value}, callback);
        }}
        onChangeRangePicker={(value: $TSFixMe, callback: $TSFixMe) => {
          onChange(section, {...form[section], ...value}, callback);
        }}
        validate={(field: $TSFixMe) => validateFields(section, field)}
        validateRow={(wrapper: $TSFixMe, field: $TSFixMe, i: $TSFixMe) =>
          validateDynamicRows(section, wrapper, field, i)
        }
        form={form}
        hasBorderBottom={hasBorderBottom}
        returnDetailSection={returnDetailSection}
        required={required}
        {...rest}
      />
    );
  };

export type ValidateRowFunction = (field: string, subField: string, index: number) => void;

export type BindToProviderChildrenProps = {
  onChange?: TODO;
  onChangeRangePicker?: TODO;
  values?: TODO;
  validations?: TODO;
  validate?: (fieldKey: string) => void;
  validateRow?: ValidateRowFunction;
  form?: TODO;
};

type Props = {
  title?: $TSFixMe;
  children: (props: BindToProviderChildrenProps) => ReactElement;
  onChange?: $TSFixMe;
  onChangeRangePicker?: $TSFixMe;
  values?: $TSFixMe;
  validations?: $TSFixMe;
  validate?: $TSFixMeFunction;
  validateRow?: $TSFixMeFunction;
  form?: $TSFixMe;
  hasBorderBottom?: boolean;
};

export const WrappedDetailsSection = ({
  title,
  children,
  onChange,
  onChangeRangePicker,
  values,
  validations,
  validate,
  validateRow,
  form,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'show' does not exist on type 'Props'.
  show,
  hasBorderBottom,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'show' does not exist on type 'Props'.
  returnDetailSection,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'show' does not exist on type 'Props'.
  required,
}: Props) => {
  if (!show) {
    return null;
  }
  return returnDetailSection ? (
    <DetailsSection title={title} hasBorderBottom={hasBorderBottom} required={required}>
      {children({onChange, onChangeRangePicker, values: {...values}, validations, validate, validateRow, form})}
    </DetailsSection>
  ) : (
    <Fragment>
      {children({onChange, onChangeRangePicker, values: {...values}, validations, validate, validateRow, form})}
    </Fragment>
  );
};

export default BindToProvider;
