import LabelWrapper from '../../../../atoms/LabelWrapper';
import {DropdownSingleValue} from '../../../../atoms/Select/otherComponents';
import {vesselDisplay} from '../../../../atoms/DropdownDisplay/vesselDisplay';
import {SearchIndicator} from '../../../../atoms/Select/Indicators';
import {ReactElement, FC} from 'react';
import {TODO} from '../../../../utils/TODO';
import Select from '../../../../atoms/Select/Select';

type VesselOption = {label: ReactElement; id: number; highlight?: {exName: []}};

export const VesselPicker: FC<{
  vessel: TODO;
  fromMarket: boolean;
  marketOneWasSelected: boolean;
  onChangeVessel: (vessel: TODO) => void;
  onSelectVesselViaMarket: (vessel: TODO) => void;
  vesselOption: TODO;
  handleQuery: (data: string) => void;
  isLoading: boolean;
}> = ({
  vessel,
  fromMarket,
  marketOneWasSelected,
  onChangeVessel,
  onSelectVesselViaMarket,
  vesselOption,
  isLoading,
  handleQuery,
}) => {
  return (
    <LabelWrapper label={'Vessel name'} style={{width: '390px', paddingRight: '10px'}}>
      <Select<VesselOption>
        id={`next-location-shipname`}
        placeholder={'Search for vessels in My Fleet'}
        value={vessel}
        hasError={fromMarket && !marketOneWasSelected}
        isClearable
        autoFocus={!vessel}
        getOptionLabel={option => vesselDisplay(option, option.highlight)}
        getOptionValue={option => option!.id.toString()}
        onChange={vessel => (fromMarket ? onSelectVesselViaMarket(vessel) : onChangeVessel(vessel))}
        onInputChange={(data: string) => handleQuery(data)}
        filterOption={() => true}
        isLoading={isLoading}
        options={vesselOption}
        components={{
          DropdownIndicator: SearchIndicator,
          // @ts-expect-error I don't know what the problem is
          SingleValue: DropdownSingleValue,
        }}
      />
    </LabelWrapper>
  );
};
