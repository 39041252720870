import React, {Component} from 'react';
import {connect} from 'react-redux';
import BaseModal from '../../../atoms/BaseModal/index';
import {Button} from '../../../atoms/Button/Button';
import {CompanyAddUser} from '../../../screens/CompanyScreens/CompanySettingsScreen/CompanySettingsUsers/CompanyAddUser/CompanyAddUser';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import './style.scss';
import {RootState} from '../../../redux/store';

type AddCompanyMembersModalState = $TSFixMe;
// eslint-disable-next-line @typescript-eslint/ban-types
class AddCompanyMembersModal extends Component<{}, AddCompanyMembersModalState> {
  state = {
    error: false,
    users: {},
    disableButton: true,
  };
  _handleChange = (data: $TSFixMe) => {
    let isValid = true;
    for (const user of data) {
      if (user.hasError) {
        isValid = false;
        break;
      }
      if (
        (user.email === '' || user.lastName === '' || user.firstName === '') &&
        !(user.email === '' && user.lastName === '' && user.firstName === '')
      ) {
        isValid = false;
        break;
      }
    }
    this.setState({users: data, disableButton: !isValid});
  };
  addMembers = () => {
    this.setState({disableButton: true});
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'addMembers' does not exist on type 'Read... Remove this comment to see the full error message
    const {addMembers, company, callback, close} = this.props;
    addMembers(company.id, this.state.users).then(() => {
      callback();
      close();
    });
  };
  render() {
    const {error, disableButton} = this.state;
    return (
      <>
        <div className={'company-add-member-headline'}>
          <div className={'company-add-member-headline__headline'}>Add members</div>
          <div className={'company-add-member-headline__subline'}>
            A seabo account will be created for your users. They will get notified via email.
          </div>
        </div>

        <div className={'company-add-member-modal'}>
          <div className={'company-add-member-modal__section'}>
            <CompanyAddUser onChangeUsers={this._handleChange} />
          </div>

          {error && (
            <div className={'company-add-member-modal__error'}>
              Something went wrong, please reload the site or <a href="https://www.seabo.com/contact">contact us</a>
            </div>
          )}
          <div className={'buttons'}>
            <Button
              label={'cancel'}
              bold
              upper
              id={'company-add-member__cancel'}
              onClick={(this.props as $TSFixMe).close}
            />
            <Button
              disabled={disableButton}
              label={'add users'}
              bold
              primary
              upper
              id={'company-add-member__save'}
              onClick={this.addMembers}
            />
          </div>
        </div>
      </>
    );
  }
}
const AddCompanyMembers = (props: $TSFixMe) => (
  <BaseModal noHeader noPaddingSideBody>
    {(modalProps: $TSFixMe) => <AddCompanyMembersModal {...props} {...modalProps} />}
  </BaseModal>
);
const mapStateToProps = (state: RootState) => ({
  company: state.company.data,
  users: state.company.users,
});
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  addMembers: (id: $TSFixMe, data: $TSFixMe) => dispatch(CompanyActions.addMembers(id, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyMembers);
