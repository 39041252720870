/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  DryDockVisitDryDock,
  DryDockVisitDryDockFromJSON,
  DryDockVisitDryDockFromJSONTyped,
  DryDockVisitDryDockToJSON,
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineEventTypeFromJSON,
  VesselVoyageTimelineEventTypeFromJSONTyped,
  VesselVoyageTimelineEventTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface DryDockVisit
 */
export interface DryDockVisit {
  /**
   *
   * @type {VesselVoyageTimelineEventType}
   * @memberof DryDockVisit
   */
  eventType: VesselVoyageTimelineEventType;
  /**
   *
   * @type {number}
   * @memberof DryDockVisit
   */
  imo: number;
  /**
   *
   * @type {DryDockVisitDryDock}
   * @memberof DryDockVisit
   */
  dryDock: DryDockVisitDryDock;
  /**
   *
   * @type {Date}
   * @memberof DryDockVisit
   */
  enterTimestamp: Date;
  /**
   *
   * @type {Date}
   * @memberof DryDockVisit
   */
  exitTimestamp: Date | null;
  /**
   * The draft of the vessel when it entered the dry dock
   * @type {number}
   * @memberof DryDockVisit
   */
  enterDraft: number;
  /**
   * The draft of the vessel when it exited the dry dock
   * @type {number}
   * @memberof DryDockVisit
   */
  exitDraft: number | null;
}

export function DryDockVisitFromJSON(json: any): DryDockVisit {
  return DryDockVisitFromJSONTyped(json, false);
}

export function DryDockVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): DryDockVisit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeFromJSON(json['eventType']),
    imo: json['imo'],
    dryDock: DryDockVisitDryDockFromJSON(json['dryDock']),
    enterTimestamp: new Date(json['enterTimestamp']),
    exitTimestamp: json['exitTimestamp'] === null ? null : new Date(json['exitTimestamp']),
    enterDraft: json['enterDraft'],
    exitDraft: json['exitDraft'],
  };
}

export function DryDockVisitToJSON(value?: DryDockVisit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeToJSON(value.eventType),
    imo: value.imo,
    dryDock: DryDockVisitDryDockToJSON(value.dryDock),
    enterTimestamp: value.enterTimestamp.toISOString(),
    exitTimestamp: value.exitTimestamp === null ? null : value.exitTimestamp.toISOString(),
    enterDraft: value.enterDraft,
    exitDraft: value.exitDraft,
  };
}
