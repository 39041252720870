import React, {FC, useRef, useState} from 'react';
import {NegotiationTermType} from '../../../../api/node-backend/generated';
import {TermItemDumb} from '../../NegotiationDetail/Components/TermItem/TermItemDumb';
import {defaultTermColors} from '../../utils/colors';
import {TermItemCreateActions} from '../../NegotiationDetail/Components/TermItem/TermItemCreate/TermItemCreateActions';
import {TermTemplateEditor} from './TermTemplateEditor/TermTemplateEditor';
import {focusTermText} from './TermTemplateEditor/utils/utils';

type TermItemTemplateCreateProps = {
  onAdd: (term: {text: string; type: NegotiationTermType | null}) => void;
};

export const TermItemTemplateCreate: FC<TermItemTemplateCreateProps> = ({onAdd}) => {
  const termContainerRef = useRef<HTMLDivElement>(null);

  const [key, setKey] = useState<number>(0); // Used to reset the TermTemplateEditor
  const [termText, setTermText] = useState('');
  const [termType, setTermType] = useState<NegotiationTermType | null>(null);

  const reset = () => {
    setTermText('');
    setTermType(null);
    setKey(key + 1);
    // Defer focusing the new term after the reset to make sure the new term is rendered
    setTimeout(() => {
      focusTermText(termContainerRef.current);
    }, 10);
  };

  const save = (text: string, type: NegotiationTermType | null) => {
    onAdd({text, type});
    reset();
  };

  return (
    <TermItemDumb
      containerRef={termContainerRef}
      indexLabel="+"
      colors={defaultTermColors}
      textReplacement={
        <TermTemplateEditor
          containerRef={termContainerRef}
          key={key}
          id={'new'}
          placeholder="Add new term"
          text={termText}
          type={termType}
          onChangeText={setTermText}
          onChangeType={setTermType}
          onFinishEditing={text => {
            if (!text.trim()) {
              return;
            }
            save(text.trim(), termType);
          }}
        />
      }
      afterTextAddon={
        <TermItemCreateActions
          disabled={!termText.trim()}
          onSave={() => {
            if (!termText.trim()) {
              return;
            }
            save(termText, termType);
          }}
          onCancel={reset}
        />
      }
    />
  );
};
