import {MulticalcCargoInput} from '../../types';
import {OneCalcResult} from '../../utils/oneCalc';
import xor from 'lodash/xor';
import intersection from 'lodash/intersection';

export const getCalcCargoSets = (filteredBreakEvenCalcs: OneCalcResult[], cargoes: MulticalcCargoInput[]) => {
  const successfulCalcCargoes: MulticalcCargoInput[] = [];
  const otherCalcCargoes: MulticalcCargoInput[] = [];
  for (const calc of filteredBreakEvenCalcs) {
    const calcCargo = cargoes.find(c => c.cargo.id === calc.cargo.id)!;
    if (
      calc.voyageCalculationOutput.cargo.revenueNet &&
      !successfulCalcCargoes.find(c => c.cargo.id === calcCargo.cargo.id)
    ) {
      successfulCalcCargoes.push(calcCargo);
    } else if (!otherCalcCargoes.find(c => c.cargo.id === calcCargo.cargo.id)) {
      otherCalcCargoes.push(calcCargo);
    }
  }
  const erroneousCalcCargoes = xor(otherCalcCargoes, intersection(otherCalcCargoes, successfulCalcCargoes));
  const hiddenCalcCargoes = xor(cargoes, [...successfulCalcCargoes, ...erroneousCalcCargoes]) ?? [];
  return {successfulCalcCargoes, erroneousCalcCargoes, hiddenCalcCargoes};
};
