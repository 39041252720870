import {useEffect, FC} from 'react';
import PulsatingCirclesLayer from '../../../components/SeaboMap/layers/mapbox/PulsatingCirclesLayer';
import {
  CoordinatesObject,
  LatLonCoordinates,
  transformLonLatFromObject,
  transformObjectFromLatLon,
} from '../../../utils/Coordinates';
import {MapApi} from '../../../components/SeaboMap/MapApi';

type PulsatingGridClipboardProps = {
  points: Array<LatLonCoordinates>;
  mapApi: MapApi;
};

export const PulsatingGridClipboardLayer: FC<PulsatingGridClipboardProps> = ({points, mapApi}) => {
  const coordinateObjects: CoordinatesObject[] = points.map(transformObjectFromLatLon);

  // fitting bounds to the points
  useEffect(() => {
    if (!mapApi.state.mapReady) {
      return;
    }
    if (points.length === 0) {
      return;
    }
    mapApi.fitBounds(coordinateObjects.map(coordinateObject => transformLonLatFromObject(coordinateObject)));
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [points, mapApi.state.mapReady]);

  if (points.length === 0) {
    return null;
  }

  return <PulsatingCirclesLayer points={coordinateObjects} color={'#ff70ea'} />;
};
