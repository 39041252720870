/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {VesselStops, VesselStopsFromJSON, VesselStopsToJSON} from '../models';

export interface VesselStopsInGeoHashesRequest {
  geoHashes: Array<string>;
}

/**
 *
 */
export class VesselStopsApi extends runtime.BaseAPI {
  /**
   * Returns all vessel stops.
   */
  async vesselStopsRaw(): Promise<runtime.ApiResponse<VesselStops>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel-stops`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => VesselStopsFromJSON(jsonValue));
  }

  /**
   * Returns all vessel stops.
   */
  async vesselStops(): Promise<VesselStops> {
    const response = await this.vesselStopsRaw();
    return await response.value();
  }

  /**
   * Returns all vessel stops in the given geohashes. (maximum 100 geo hashes at once)
   */
  async vesselStopsInGeoHashesRaw(
    requestParameters: VesselStopsInGeoHashesRequest
  ): Promise<runtime.ApiResponse<VesselStops>> {
    if (requestParameters.geoHashes === null || requestParameters.geoHashes === undefined) {
      throw new runtime.RequiredError(
        'geoHashes',
        'Required parameter requestParameters.geoHashes was null or undefined when calling vesselStopsInGeoHashes.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.geoHashes) {
      queryParameters['geoHashes'] = requestParameters.geoHashes;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel-stops/geohashes`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => VesselStopsFromJSON(jsonValue));
  }

  /**
   * Returns all vessel stops in the given geohashes. (maximum 100 geo hashes at once)
   */
  async vesselStopsInGeoHashes(requestParameters: VesselStopsInGeoHashesRequest): Promise<VesselStops> {
    const response = await this.vesselStopsInGeoHashesRaw(requestParameters);
    return await response.value();
  }
}
