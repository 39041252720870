import {useDispatch} from '../../redux/react-redux';
import {useEffect, useState} from 'react';
import {GridClipboardActions, Section, Subsection} from '../../redux/GridClipboard';
import {useLocation} from 'react-router-dom';
import {PortfolioActions} from '../../redux/Portfolio';
import {MarketActions} from '../../redux/Market';

/*
 * This hook clears the clipboard when the user toggles between the archive and non-archive.
 */
export const useClearClipboardOnToggleArchive = ({section, subsection}: {subsection: Subsection; section: Section}) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const isArchive = /\/archive$/.test(location.pathname);

  const [isArchiveOldState, setIsArchiveOldState] = useState<boolean>(isArchive);

  const clearClipboard = () => {
    dispatch(GridClipboardActions.removeAllItemsFromClipboard(subsection, section));
  };

  const setPageNumber = (pageNumber: number) => {
    if (section === 'portfolio') {
      dispatch(PortfolioActions.setPageNumber(subsection === 'vessels' ? 'vessel' : 'cargo', pageNumber));
    } else {
      dispatch(MarketActions.setPage({marketType: subsection === 'vessels' ? 'vessel' : 'cargo', page: pageNumber}));
    }
  };

  useEffect(() => {
    if (isArchiveOldState !== isArchive) {
      clearClipboard();
      setPageNumber(1);
    }

    setIsArchiveOldState(isArchive);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchive]);
};
