import {cargoAccessor} from '../../utils/cargoAccessorResult';
import Station from '../../../../model/Station';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';

const loadDeliveryFixtureAccessor = (type: $TSFixMe) =>
  cargoAccessor(
    (d: $TSFixMe) => (
      <div className="fixture-grid-cell__load">
        {Station.getLocationByStationType(d.stations, Station.FIXTUREDELVIERY, {
          short: true,
          highlight: true,
          emptyState: '-',
        })}
      </div>
    ),
    type
  );
export const loadDeliveryFixtureColumn = ({
  type,
  minWidth = 16,
}: {
  type: $TSFixMe;
  minWidth?: number;
}): CustomColumnDef<$TSFixMe> => ({
  header: 'Load / delivery',
  id: 'open',
  enableSorting: false,
  accessorFn: loadDeliveryFixtureAccessor(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
