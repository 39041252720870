import {BreadcrumbsBar} from './BreadcrumbsBar';
import {BreadcrumbItem} from './BreadcrumbItem';
import {Portal} from '../Portal/Portal';

/**
 * Renders the breadcrumbs at the top of the screen.
 */
export const Breadcrumb = ({items}: {items: BreadcrumbItem[]}) => (
  <Portal targetNodeId="breadcrumbs-portal">
    <BreadcrumbsBar items={items} />
  </Portal>
);
