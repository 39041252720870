import {FC} from 'react';
import {useQuery} from '@tanstack/react-query';
import styled from 'styled-components';
import {getTradingAreas} from '../../../../redux/ApiService/MapService/MapService';
import {useDispatch} from '../../../../redux/react-redux';
import {Coordinates} from '../../../../utils/Coordinates';
import {TradingAreaLayer} from '../../../SeaboMap/layers';
import {SeaboMap} from '../../../SeaboMap/SeaboMap';
import nonTradingAreaGeoJSON from './nonTradingAreas.json';
import {TradingArea} from '../../../../redux/ApiService/MapService/GetTradingAreasResponse';

export const TradingAreaSelectorMap: FC<{
  selectedTradingAreaCodes: string[];
  onChangeOneItem: (tradingAreaCodes: string) => void;
}> = ({selectedTradingAreaCodes, onChangeOneItem}) => {
  const dispatch = useDispatch();

  const tradingAreasQuery = useQuery({
    queryKey: ['tradingAreas'],
    queryFn: async () => {
      const response = await dispatch(getTradingAreas());
      return response.items as (TradingArea & {polygon: Coordinates[][]})[];
    },
  });

  if (!tradingAreasQuery.isSuccess) {
    return null;
  }

  const isSelected = (item: TradingArea) => selectedTradingAreaCodes.some(tradingArea => tradingArea === item.code);

  return (
    <MapContainer>
      <SeaboMap
        componentsIn
        showSearch={false}
        showLegend={false}
        showPopups={false}
        showNavigation={false}
        showControl={false}
        showFocusBtn={false}
        showTradingAreaPopup={false}
        vesselsToVisualize={{
          data: [],
        }}
        promoLayerEnabled={false}
        vesselCargoMode={false}
        initialViewState={{
          zoom: 0.3,
          minZoom: 0.1,
        }}
        layers={[
          new TradingAreaLayer({
            id: 'NON_TRADING_AREA',
            pickable: false,
            selected: undefined,
            showLabels: true,
            data: nonTradingAreaGeoJSON,
            getLineColor: () => [0, 0, 0, 0],
            getFillColor: () => [21, 21, 21, 100],
          }),
          new TradingAreaLayer({
            id: 'SELECTABLE_TRADING_AREA',
            data:
              tradingAreasQuery.data?.map(item => ({
                type: 'Feature',
                properties: item,
                geometry: {type: 'Polygon', coordinates: item.polygon},
              })) ?? [],
            pickable: true,
            filled: true,
            showLabels: true,
            onClick: item => {
              onChangeOneItem(item.object.properties.code);
            },
            getFillColor: item => {
              if (isSelected(item.properties as TradingArea)) {
                return [21, 244, 21, 100];
              }
              return [21, 21, 21, 0];
            },
          }),
        ]}
        defaults="segment_selection"
      />
    </MapContainer>
  );
};

const MapContainer = styled.div`
  display: inline;
  min-height: 400px;
  background: #001e36;
`;
