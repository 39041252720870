import {FC} from 'react';
import styled from 'styled-components';
import {NegotiationCargo, NegotiationVessel} from '../../../api/node-backend/generated';
import {Attribute, AttributeContainer} from '../../../atoms/Attribute/Attribute';
import CargoIcon from '../../../atoms/CargoIcon';
import Flag from '../../../atoms/Flags';
import {numberFormat, numberUnitFormat} from '../../../utils/formatter';
import Icon from '../../../atoms/Icon';
import {FlexBox} from './shared';
import {NotAvailable} from '../../../utils/NotAvailable';
import {UserDateTime} from '../../../components/DateTime/UserDateTime';

type NegotiationItemsPreviewProps = {
  cargo?: NegotiationCargo;
  vessel?: NegotiationVessel;
};

export const NegotiationItemsPreview: FC<NegotiationItemsPreviewProps> = ({cargo, vessel}) => {
  return (
    <ItemPreviewContainer data-testid="NegotiationItems">
      <ItemContainer>
        <NegotiationVesselPreview vessel={vessel} />
      </ItemContainer>
      <ItemContainer>
        <NegotiationCargoPreview cargo={cargo} />
      </ItemContainer>
    </ItemPreviewContainer>
  );
};

export const NegotiationVesselPreview = ({vessel}: {vessel?: NegotiationVessel}) => {
  if (!vessel) {
    return (
      <div data-testid="NegotiationItemVesselNA">
        <EmptyLabel>No vessel attached</EmptyLabel>
      </div>
    );
  }

  const imoLabel = vessel.imo ? ` (${vessel.imo})` : '';
  const vesselLabel = (vessel.name || 'Vessel') + imoLabel;
  const sizeLabel = numberUnitFormat(vessel.size, vessel.sizeUnit || undefined);
  const dateOpenLabel = vessel.dateOpen ? <UserDateTime value={vessel.dateOpen} format="date" /> : NotAvailable;
  const locationOpenLabel = vessel.locationOpenLabel ?? NotAvailable;

  return (
    <div data-testid="NegotiationItemVessel">
      <ItemTitle>{vesselLabel}</ItemTitle>
      <Attribute tab="m" label="size" value={sizeLabel} />
      <Attribute tab="m" label="date open" value={dateOpenLabel} />
      <Attribute
        tab="m"
        label="port open"
        value={
          <>
            <Flag countryCode={vessel.locationOpenCountryCode} /> {locationOpenLabel}
          </>
        }
      />
    </div>
  );
};

export const NegotiationCargoPreview = ({cargo}: {cargo?: NegotiationCargo}) => {
  if (!cargo) {
    return (
      <div data-testid="NegotiationItemCargoNA">
        <EmptyLabel>No cargo attached</EmptyLabel>
      </div>
    );
  }

  const commodityLabel = cargo.commodityType
    ? `${cargo.commodityType} (${cargo.commodityCategory ?? NotAvailable})`
    : 'Cargo';
  const sizeLabel =
    cargo.quantityMin === cargo.quantityMax
      ? `${numberUnitFormat(cargo.quantityMax, cargo.quantityUnit || 'DWT')}`
      : `${numberFormat(cargo.quantityMin)} - ${numberUnitFormat(cargo.quantityMax, cargo.quantityUnit || 'DWT')}`;
  const laycanLabel = (
    <>
      {cargo.laycanFrom ? <UserDateTime value={cargo.laycanFrom} format="date" /> : NotAvailable}
      {` - `}
      {cargo.laycanTo ? <UserDateTime value={cargo.laycanTo} format="date" /> : NotAvailable}
    </>
  );
  const stationsLabel = (
    <>
      <Flag countryCode={cargo.loadingCountryCode} /> {cargo.loadingLabel ?? NotAvailable}
      <Icon type="arrow-right" size="small" style={{verticalAlign: 'middle', margin: '0 3px'}} />
      <Flag countryCode={cargo.dischargeCountryCode} /> {cargo.dischargeLabel ?? NotAvailable}
    </>
  );

  return (
    <div data-testid="NegotiationItemCargo">
      <ItemIcon>
        <CargoIcon small commodityType={cargo.commodityType} cargoType={cargo.contractType} />
      </ItemIcon>
      <div>
        <ItemTitle>{commodityLabel}</ItemTitle>
        <Attribute tab="m" label="size" value={sizeLabel} />
        <Attribute tab="m" label="laycan" value={laycanLabel} />
        <Attribute tab="m" label="stations" value={stationsLabel} />
      </div>
    </div>
  );
};

const ItemPreviewContainer = styled(FlexBox)`
  flex-grow: 1;
  flex-wrap: nowrap;
`;

const ItemContainer = styled.div`
  position: relative;
  border-left: var(--border-base);

  &:first-of-type {
    flex: 1 1 42%;
    padding: 0 10px;
  }

  &:last-of-type {
    flex: 1 1 58%;
    padding-left: 10px;
  }

  ${() => ItemTitle} {
    margin-bottom: 0;
  }

  ${AttributeContainer} {
    margin-bottom: 0;
  }
`;

const ItemIcon = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  width: 40px;

  .cargo-icon {
    margin-top: -5px;
  }
`;

export const ItemTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: pre;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const EmptyLabel = styled.div`
  color: var(--color-gray-3);
  text-align: center;
  padding: 10px 0;
`;
