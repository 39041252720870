import React, {FC} from 'react';
import {TourDefinition} from '../../../../components/OnboardingTour/TourDefinition';
import {NegotiationTourState} from './tourState';
import {Tour, TourStepProps} from 'antd';
import {TourStepContent} from '../../../../components/OnboardingTour/TourStep/TourStepContent';
import {TourStepHeader} from '../../../../components/OnboardingTour/TourStep/TourStepHeader';

const root = document.getElementById('root');

type NegotiationTourOverlayProps = {
  tourDefinition: TourDefinition;
  tourState: NegotiationTourState;
  onChangeTourStep: (step: number) => void;
  onExitTutorial: () => void;
};

export const NegotiationTourOverlay: FC<NegotiationTourOverlayProps> = ({
  tourDefinition,
  tourState,
  onChangeTourStep,
  onExitTutorial,
}) => {
  const tourSteps: TourStepProps[] = tourDefinition.steps.map((definitionStep, idx) => {
    const {title, content, media, placement, target} = definitionStep;
    const step: TourStepProps = {
      target: target ? root?.querySelector<HTMLElement>(target) : null,
      placement,
      title,
      description: (
        <TourStepContent center={idx === 0 || idx === tourDefinition.steps.length - 1}>{content}</TourStepContent>
      ),
      cover: media ? <TourStepHeader media={media} /> : null,
      nextButtonProps: {
        children: idx !== tourDefinition.steps.length - 1 ? 'Next' : 'End tutorial',
        onClick: () => (idx !== tourDefinition.steps.length - 1 ? onChangeTourStep(idx + 1) : onExitTutorial()),
      },
      prevButtonProps: {
        style: idx === 0 ? {display: 'none'} : {},
        children: 'Back',
      },
      scrollIntoViewOptions: {block: 'center', inline: 'center'},
    };
    return step;
  });

  const currentStep = tourDefinition.steps[tourState.step];

  return (
    <Tour
      open={tourState.isActive}
      steps={tourSteps}
      current={tourState.step}
      arrow={currentStep.placement !== 'center'}
      onChange={onChangeTourStep}
      onClose={onExitTutorial}
      gap={{radius: 10, offset: 10}}
      scrollIntoViewOptions={{block: 'center', inline: 'center'}}
    />
  );
};
