/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VesselTypeEnum, VesselTypeEnumFromJSON, VesselTypeEnumFromJSONTyped, VesselTypeEnumToJSON} from './';

/**
 *
 * @export
 * @interface CreateNegotiationVesselDto
 */
export interface CreateNegotiationVesselDto {
  /**
   * The vessel ID is the ID from a vessel on the platform.
   * @type {number}
   * @memberof CreateNegotiationVesselDto
   */
  id?: number;
  /**
   * The name of the vessel.
   * @type {string}
   * @memberof CreateNegotiationVesselDto
   */
  name?: string;
  /**
   * The IMO of the vessel.
   * @type {number}
   * @memberof CreateNegotiationVesselDto
   */
  imo?: number;
  /**
   *
   * @type {VesselTypeEnum}
   * @memberof CreateNegotiationVesselDto
   */
  type?: VesselTypeEnum;
  /**
   * The size of the vessel.
   * @type {number}
   * @memberof CreateNegotiationVesselDto
   */
  size?: number;
  /**
   * The size unit of the vessel.
   * @type {string}
   * @memberof CreateNegotiationVesselDto
   */
  sizeUnit?: string;
  /**
   * The date when the vessel is open. Format: YYYY-MM-DD
   * @type {string}
   * @memberof CreateNegotiationVesselDto
   */
  dateOpen?: string;
  /**
   * The location where the vessel is open.
   * @type {string}
   * @memberof CreateNegotiationVesselDto
   */
  locationOpenLabel?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNegotiationVesselDto
   */
  locationOpenCountryCode?: string;
}

export function CreateNegotiationVesselDtoFromJSON(json: any): CreateNegotiationVesselDto {
  return CreateNegotiationVesselDtoFromJSONTyped(json, false);
}

export function CreateNegotiationVesselDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNegotiationVesselDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    imo: !exists(json, 'imo') ? undefined : json['imo'],
    type: !exists(json, 'type') ? undefined : VesselTypeEnumFromJSON(json['type']),
    size: !exists(json, 'size') ? undefined : json['size'],
    sizeUnit: !exists(json, 'sizeUnit') ? undefined : json['sizeUnit'],
    dateOpen: !exists(json, 'dateOpen') ? undefined : json['dateOpen'],
    locationOpenLabel: !exists(json, 'locationOpenLabel') ? undefined : json['locationOpenLabel'],
    locationOpenCountryCode: !exists(json, 'locationOpenCountryCode') ? undefined : json['locationOpenCountryCode'],
  };
}

export function CreateNegotiationVesselDtoToJSON(value?: CreateNegotiationVesselDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    imo: value.imo,
    type: VesselTypeEnumToJSON(value.type),
    size: value.size,
    sizeUnit: value.sizeUnit,
    dateOpen: value.dateOpen,
    locationOpenLabel: value.locationOpenLabel,
    locationOpenCountryCode: value.locationOpenCountryCode,
  };
}
