/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {StationTypeEnum, StationTypeEnumFromJSON, StationTypeEnumFromJSONTyped, StationTypeEnumToJSON} from './';

/**
 *
 * @export
 * @interface Station
 */
export interface Station {
  /**
   *
   * @type {number}
   * @memberof Station
   */
  id: number;
  /**
   *
   * @type {StationTypeEnum}
   * @memberof Station
   */
  type: StationTypeEnum;
  /**
   *
   * @type {Array<object>}
   * @memberof Station
   */
  locations: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof Station
   */
  isRange: boolean;
}

export function StationFromJSON(json: any): Station {
  return StationFromJSONTyped(json, false);
}

export function StationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Station {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: StationTypeEnumFromJSON(json['type']),
    locations: json['locations'],
    isRange: json['isRange'],
  };
}

export function StationToJSON(value?: Station | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: StationTypeEnumToJSON(value.type),
    locations: value.locations,
    isRange: value.isRange,
  };
}
