import {Checkbox} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {voyageTimelineFilterLabels, VoyageTimelineFilters} from './utils/filters';
import {getEventColor, TimelineEventColor} from './utils/utils';
import {useCurrentUserQuery} from '../../../queries/useCurrentUserQuery';
import {FeaturePermission} from '../../../api/symfony/generated';
import {ExportVoyageTimeline} from './ExportVoyageTimeline/ExportVoyageTimeline';

const objectKeys = Object.keys as <T>(o: T) => Array<keyof T>;

type VoyageTimelineTypeFilterProps = {
  eventsAvailable: VoyageTimelineFilters;
  voyageTimelineFilters: VoyageTimelineFilters;
  onToggleFilter: (filter: keyof VoyageTimelineFilters) => void;
  imo: number | null;
};

export const VoyageTimelineTypeFilter: FC<VoyageTimelineTypeFilterProps> = ({
  eventsAvailable,
  voyageTimelineFilters,
  imo,
  onToggleFilter,
}) => {
  const currentUserQuery = useCurrentUserQuery();
  const canExportVoyageTimeline = currentUserQuery.data?.permissions.includes(FeaturePermission.VoyageTimelineExport);

  return (
    <VoyageTimelineTypeFilterContainer data-testid="VoyageTimelineTypeFilter">
      <ScrollContainer>
        {objectKeys(voyageTimelineFilters).map(type => (
          <CheckboxContainer key={type} $color={getEventColor(type)}>
            <Checkbox
              id={`${type}-filter`}
              checked={voyageTimelineFilters[type]}
              onChange={() => onToggleFilter(type)}
              disabled={!eventsAvailable[type]}>
              {voyageTimelineFilterLabels[type]}
            </Checkbox>
          </CheckboxContainer>
        ))}
      </ScrollContainer>
      {canExportVoyageTimeline && imo && <ExportVoyageTimeline imo={imo} />}
    </VoyageTimelineTypeFilterContainer>
  );
};

const VoyageTimelineTypeFilterContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  margin-bottom: 10px;
  background: var(--color-gray-6);
  border: var(--border-base);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  height: 100%;
  width: calc(100% + 26px);
  padding: 10px 12px;
  margin: -10px -13px;
  border-radius: var(--border-radius-lg);

  &:not(:last-child) {
    width: 100%;
    margin: -10px 2px -10px -13px;
    border-right: var(--border-base);
  }
`;

const CheckboxContainer = styled.div<{$color?: TimelineEventColor}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 60px;
  gap: 5px;
  height: 40px;
  padding: 5px 10px;
  background: var(--timeline-color-${({$color}) => $color});
  font-weight: bold;
  border-radius: var(--border-radius-lg);
  user-select: none;

  .ant-checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: var(--timeline-color-${({$color}) => $color}-text);
    line-height: 1.125;
  }

  .ant-checkbox + span {
    height: auto;
  }

  .ant-checkbox-disabled + span {
    color: var(--timeline-color-${({$color}) => $color}-text);
    opacity: 0.66;
  }
`;
