import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {authenticationApi} from '../../api/symfony/symfonyApi';
import {
  AccessToken as GenerateAccessTokenResponse,
  GenerateAccessTokenGrantTypeEnum,
} from '../../api/symfony/generated';
import {oAuthClientId} from './oAuthClientId';
import {scope} from './scope';
import {ApiError} from '../../api/utils/ApiError';

type MutationParams = {refreshToken: string};

export const useGenerateAccessTokenMutation = (
  options?: UseMutationOptions<GenerateAccessTokenResponse, ApiError, MutationParams>
) =>
  useMutation({
    mutationFn: generateAccessToken,
    ...options,
  });

const generateAccessToken = async ({refreshToken}: MutationParams): Promise<GenerateAccessTokenResponse> => {
  const response = await authenticationApi.generateAccessToken({
    grantType: GenerateAccessTokenGrantTypeEnum.RefreshToken,
    scope,
    clientId: oAuthClientId,
    refreshToken,
  });
  return response;
};
