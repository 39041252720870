import {VesselInput} from '../../VesselInformation/VesselTypes';

export const getIsVesselReady = (vessel: VesselInput): boolean => {
  if (vessel.consumptionModes.length === 0) {
    return false;
  }

  if (!vessel.consumptionModes.some(mode => mode.useCase === 'ballast')) {
    return false;
  }
  if (!vessel.consumptionModes.some(mode => mode.useCase === 'laden')) {
    return false;
  }
  if (!vessel.consumptionModes.some(mode => mode.useCase === 'portworking')) {
    return false;
  }

  if (!vessel.capacity) {
    return false;
  }

  if (!vessel.dwat) {
    return false;
  }
  return true;
};
