import Checkbox from '../../../../atoms/Checkbox';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {cleanIdentifier} from '../../../../utils/helper';
import {filterBranchDefinition} from './ContractTypeDefinition';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
  dark?: boolean;
}

const ContractType = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values}) => (
      <div className={'container'}>
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('Voyage', 'filter-option')}
              checked={values.VC}
              label={'Voyage'}
              onChange={e => handleChange('VC', e.target.checked)}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('TCT', 'filter-option')}
              checked={values.TCT}
              label={'TCT'}
              onChange={e => handleChange('TCT', e.target.checked)}
              spMT={'s'}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('Period', 'filter-option')}
              checked={values.PC}
              label={'Period'}
              onChange={e => handleChange('PC', e.target.checked)}
              spMT={'s'}
            />
          </div>
          <div className="scol-12">
            <Checkbox
              id={cleanIdentifier('Bareboat', 'filter-option')}
              checked={values.BC}
              label={'Bareboat'}
              onChange={e => handleChange('BC', e.target.checked)}
              spMT={'s'}
            />
          </div>
        </div>
      </div>
    )}
  </FilterButton>
);

export default ContractType;
