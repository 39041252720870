import {Form, FormInstance} from 'antd';
import {Dayjs} from 'dayjs';
import {PotentialCompetitorsFormData} from '../types';
import {PotentialCompetitorsFormBody} from './PotentialCompetitorsFormBody';
import {EnabledFilters} from '../FilterOptionDropdown';

export const PotentialCompetitorsForm = ({
  form,
  onFinish,
  filterOptions,
  canSubmit,
  currentTime,
}: {
  filterOptions: EnabledFilters;
  form: FormInstance<PotentialCompetitorsFormData>;
  onFinish: (values: PotentialCompetitorsFormData) => void;
  currentTime?: Dayjs;
  canSubmit: boolean;
}) => {
  return (
    <Form<PotentialCompetitorsFormData>
      form={form}
      onFinish={onFinish}
      initialValues={{
        speed: 12,
        allowToArriveDaysLater: 1,
        allowToArriveDaysBefore: 0,
      }}
      layout={'vertical'}
      size={'small'}>
      <PotentialCompetitorsFormBody
        currentTime={currentTime}
        filterOptions={filterOptions}
        canSubmit={canSubmit}
        form={form}
      />
    </Form>
  );
};
