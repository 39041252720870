import {Statistic} from 'antd';
import {FC} from 'react';
import {StatCard} from '../../sharedComponents/StatCard';

export const NumberStatistics: FC<{
  value: number;
  title: string;
  suffix?: string;
  dataTestId?: string;
  precision?: number;
}> = ({value, dataTestId, title, suffix, precision = 0}) => {
  return (
    <StatCard data-testid={dataTestId}>
      <Statistic title={title} value={value} suffix={suffix} precision={precision} />
    </StatCard>
  );
};
