/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  DraftChartResponse,
  DraftChartResponseFromJSON,
  DraftChartResponseToJSON,
  VesselVoyageTimelineResponse,
  VesselVoyageTimelineResponseFromJSON,
  VesselVoyageTimelineResponseToJSON,
} from '../models';

export interface GetDraftChartRequest {
  imo: number;
}

export interface GetVoyageTimelineRequest {
  imo: number;
  from: Date;
  to: Date;
}

/**
 *
 */
export class VesselVoyageTimelineApi extends runtime.BaseAPI {
  /**
   */
  async getDraftChartRaw(requestParameters: GetDraftChartRequest): Promise<runtime.ApiResponse<DraftChartResponse>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getDraftChart.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel/{imo}/draft-chart`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => DraftChartResponseFromJSON(jsonValue));
  }

  /**
   */
  async getDraftChart(requestParameters: GetDraftChartRequest): Promise<DraftChartResponse> {
    const response = await this.getDraftChartRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getVoyageTimelineRaw(
    requestParameters: GetVoyageTimelineRequest
  ): Promise<runtime.ApiResponse<VesselVoyageTimelineResponse>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getVoyageTimeline.'
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        'from',
        'Required parameter requestParameters.from was null or undefined when calling getVoyageTimeline.'
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        'to',
        'Required parameter requestParameters.to was null or undefined when calling getVoyageTimeline.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel/{imo}/voyage-timeline`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => VesselVoyageTimelineResponseFromJSON(jsonValue));
  }

  /**
   */
  async getVoyageTimeline(requestParameters: GetVoyageTimelineRequest): Promise<VesselVoyageTimelineResponse> {
    const response = await this.getVoyageTimelineRaw(requestParameters);
    return await response.value();
  }
}
