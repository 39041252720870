import {combineReducers, Reducer, ReducersMapObject, Dispatch} from 'redux';
import {template, NestedNamedValues} from '../utils/templating';
import {AsyncActionTypes} from '../utils/asyncActions';
import {BodyData} from '../middlware/ApiRequestAction';
import {ActionWithPayload} from '../middlware/ActionWithPayload';
import * as Sentry from '@sentry/browser';
import {newTransactionId} from '../../api/utils/apiTransactionIdMiddleware';
import {ApiError} from '../../api/utils/ApiError';
import {GetState} from '../react-redux';
import {RootState} from '../store';
import {getWorkspaceId} from '../selectors';
import {getAjaxCsrfToken} from '../../api/utils/api';
import {UnauthorizedError} from '../../api/utils/UnauthorizedError';
import {removeNullishProperties} from './removeNullishProperties';
import {loadResponseJson} from '../../api/utils/loadResponseJson';
import z, {ZodError} from 'zod';
import {TODO} from '../../utils/TODO';
import {tryJsonParseFailedResponseBody, wrapFetchWithExtendedLogging} from '../../utils/setupFetch';

export enum RequestState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface ApiServiceConfiguration {
  apiName: string;
}

export interface ApiService {
  actionTypes: ActionTypeMapping;
  actions: ActionCreatorMapping;
  reducers: Reducer<TODO, ActionWithPayload>;
}

interface ActionTypeMapping {
  [apiRequestName: string]: AsyncActionTypes;
}

type Action = TODO;

type ActionCreator = (...args: TODO[]) => Action;

interface ActionCreatorMapping {
  [apiRequestName: string]: ActionCreator;
}

interface ApiPartitionState {
  loading: boolean;
  state: RequestState;
  statusCode: number | null;
  data: TODO;
  initial: boolean;
}

/**
 * Makes a reducer for the given api request.
 */
const reducer = (
  name: string,
  actionTypes: ActionTypeMapping,
  storeData = true
): Reducer<ApiPartitionState, ActionWithPayload> => {
  const initialState: ApiPartitionState = {
    loading: false,
    state: RequestState.IDLE,
    statusCode: null,
    data: null,
    initial: true,
  };

  return (state = initialState, {type, payload}: ActionWithPayload) => {
    switch (type) {
      case actionTypes[name].PENDING:
        return {
          ...state,
          state: RequestState.PENDING,
          statusCode: null,
          loading: !!payload && Object.prototype.hasOwnProperty.call(payload, 'state') ? payload.state : true,
        };
      case actionTypes[name].SUCCESS:
        return {
          ...state,
          state: RequestState.SUCCESS,
          statusCode: null,
          loading: false,
          data: storeData ? payload : null,
          initial: false,
        };
      case actionTypes[name].ERROR:
        return {
          ...state,
          state: RequestState.ERROR,
          statusCode: payload && payload.status ? payload.status : null,
          loading: false,
          data: payload && payload.data ? payload.data : null,
          initial: false,
        };
      default:
        return state;
    }
  };
};

const generateUrl = (
  route: string,
  params: NestedNamedValues | undefined,
  queryParams: Record<string, string>,
  contextParams: Record<string, string>
) => {
  const variables = {
    ...contextParams,
    ...params,
  };
  let url = template(route, variables);
  if (queryParams) {
    const qs = Object.keys(removeNullishProperties(queryParams))
      .map(key => key + '=' + encodeURIComponent(queryParams[key]))
      .join('&');
    if (qs) {
      url += '?' + qs;
    }
  }
  return url;
};

/**
 * A function that converts one payload into another.
 */
type PayloadTransformer<PayloadType> = (payload: PayloadType) => TODO;

interface PayloadTransformers {
  PENDING?: PayloadTransformer<PendingPayload>;
  SUCCESS?: PayloadTransformer<SuccessPayload>;
  ERROR?: PayloadTransformer<ErrorPayload>;
}

/**
 * A function that aborts an ongoing request.
 */
type AbortRequestFunction = () => void;

/**
 * Tracks the state of an API service.
 *
 * The state is shared across multiple requests to the same endpoint and across the PENDING/SUCCESS/ERROR actions.
 *
 * This exists so that we don't have to put a promise and a function in Redux state.
 */
interface ApiServiceState {
  /**
   * Promise of the previous, ongoing request.
   */
  ongoingRequest: Promise<unknown> | null;

  /**
   * Cancels an ongoing request.
   */
  abortOngoingRequest: AbortRequestFunction | null;
}
/**
 * Performs an API request with the given parameters. This is where the actual fetch() happens.
 *
 * @param params named values for url template placeholder, like {id: '23'} for '/api/users/{id}'.
 */
const performApiRequest = async (
  body: BodyData,
  params: NestedNamedValues | undefined,
  queryParams: Record<string, string>,
  contextParams: Record<string, string>,
  transformers: PayloadTransformers,
  apiServiceEntry: ApiServiceEntry,
  actionTypes: ActionTypeMapping,
  config: ApiServiceConfiguration,
  dispatch: Dispatch,
  apiServiceState: ApiServiceState
): Promise<unknown> => {
  const {fn, route, method = 'GET', headers: customHeaders = {}, isFormData, requestSchema} = apiServiceEntry;

  if (requestSchema) {
    requestSchema.parse(body);
  }

  const url = generateUrl(route, params, queryParams, contextParams);
  transformers = {...apiServiceEntry.transformers, ...transformers};

  if (apiServiceState.ongoingRequest) {
    if (apiServiceEntry.abortPrevCalls && apiServiceState.abortOngoingRequest) {
      apiServiceState.abortOngoingRequest();
    }

    // Wait for the ongoing request to finish to prevent a race condition.
    // Otherwise the ongoing request might update Redux state later on.
    try {
      await apiServiceState.ongoingRequest;
    } catch (e) {
      // Exception for this promise are further down already.
    }

    // Reset the state to prevent a memory leak.
    apiServiceState.abortOngoingRequest = null;
    apiServiceState.ongoingRequest = null;
  }

  const transactionId = newTransactionId();
  Sentry.configureScope(scope => {
    scope.setTag('transaction_id', transactionId);
  });

  const headers: Record<string, string | null | undefined> = {
    Accept: !isFormData ? 'application/json' : undefined,
    'X-Ajax-Csrf-Token': getAjaxCsrfToken(),
    'Accept-Encoding': 'gzip, deflate, br',
    'Content-Type': !isFormData || ['PUT', 'PATCH', 'DELETE'].indexOf(method) > -1 ? 'application/json' : undefined,
    'X-Requested-With': 'XMLHttpRequest',
    'X-Transaction-ID': transactionId,
    ...customHeaders,
  };
  if (isFormData) {
    delete headers['Content-Type'];
  }

  dispatchRequestPending({url, method, body, params, queryParams}, fn, actionTypes, transformers, dispatch);

  const isForm = headers['Content-Type'] === 'application/x-www-form-urlencoded; charset=UTF-8';

  let isAborted = false;
  let abortSignal: AbortSignal | null = null;
  if (apiServiceEntry.abortPrevCalls) {
    const abortController = new AbortController();
    const abort: AbortRequestFunction = () => {
      if (isAborted) {
        return;
      }
      isAborted = true;
      abortController.abort();
    };
    apiServiceState.abortOngoingRequest = abort;
    abortSignal = abortController.signal;
  }

  const wrappedFetch = wrapFetchWithExtendedLogging(fetch);

  const promise = wrappedFetch(url, {
    method,
    headers: new Headers(headers as Record<string, string>),
    credentials: 'same-origin',
    body: isForm || isFormData ? (body as FormData) : JSON.stringify(body),
    signal: abortSignal,
  });

  apiServiceState.ongoingRequest = promise;

  try {
    const response = await promise;
    const data = await processResponse(response, url, apiServiceEntry, transformers, actionTypes, dispatch);
    return data;
  } catch (e) {
    const requestAborted = e instanceof DOMException && e.message.match(/The user aborted a request/i);
    if (requestAborted) {
      // eslint-disable-next-line no-console
      console.info(`Request to ${apiServiceEntry.route} was aborted`);
      return undefined;
    }
    throw e;
  } finally {
    // Reset the state to prevent a memory leak.
    apiServiceState.abortOngoingRequest = null;
    apiServiceState.ongoingRequest = null;
  }
};

const processResponse = async (
  response: Response,
  url: string,
  apiServiceEntry: ApiServiceEntry,
  transformers: PayloadTransformers,
  actionTypes: ActionTypeMapping,
  dispatch: Dispatch
): Promise<unknown> => {
  const {fn} = apiServiceEntry;

  let data;
  if (response.ok) {
    if (response.status === 204) {
      // No Content
      data = {};
    } else {
      data = await loadResponseJson(response, apiServiceEntry.method ?? 'GET', apiServiceEntry.route);
    }
    data = sanitizeResponseAgainstSchema(data, url, response, apiServiceEntry, transformers, actionTypes, dispatch);
    if (transformers.SUCCESS) {
      data = transformers.SUCCESS(data);
    }
    dispatchRequestSuccess(data, fn, actionTypes, dispatch);
    return data;
  }

  // Unauthorized
  if (response.status === 401) {
    // A 401 means that the user lost her session -  possibly because she got logged out forcefully.
    // Send her to the login page.
    // I have tried to do this in a more central place, but none of the usual techniques worked.
    // eslint-disable-next-line no-console
    console.warn('No session - redirecting to login page.');
    window.location.pathname = '/login';
    throw new UnauthorizedError(`Unauthorized fetch("${response.url}")`);
  }

  if (response.status >= 400 && response.status < 500) {
    data = await loadResponseJson(response, apiServiceEntry.method ?? 'GET', apiServiceEntry.route);
    const error = new ApiError(
      `API fetch("${response.url}") failed: ${response.status}/${response.statusText}`,
      response.status,
      data
    );
    dispatchRequestError(
      {
        status: response.status,
        statusText: response.statusText,
        data,
        error,
      },
      fn,
      actionTypes,
      transformers,
      dispatch
    );
    throw error;
  }

  data = tryJsonParseFailedResponseBody(await response.clone().text());
  const error = new ApiError(
    `API fetch("${response.url}") failed: ${response.status}/${response.statusText}`,
    response.status,
    data
  );
  dispatchRequestError(
    {
      status: response.status,
      statusText: response.statusText,
      data,
      error,
    },
    fn,
    actionTypes,
    transformers,
    dispatch
  );
  throw error;
};

export type PendingPayload = {
  url: string;
  method: string;
  body: unknown;
  params: NestedNamedValues | undefined;
  queryParams: Record<string, string>;
};
const dispatchRequestPending = (
  payload: PendingPayload,
  fn: string,
  actionTypes: ActionTypeMapping,
  transformers: PayloadTransformers,
  dispatch: Dispatch
) => {
  dispatch({
    type: actionTypes[fn].PENDING,
    payload: transformers.PENDING ? transformers.PENDING(payload) : payload,
  });
};

const sanitizeResponseAgainstSchema = (
  data: unknown,
  url: string,
  response: Response,
  apiServiceEntry: ApiServiceEntry,
  transformers: PayloadTransformers,
  actionTypes: ActionTypeMapping,
  dispatch: Dispatch
) => {
  const {fn, responseSchema} = apiServiceEntry;

  if (!responseSchema) {
    return data;
  }

  try {
    const d = responseSchema.parse(data);
    return d;
  } catch (e) {
    if (e instanceof ZodError) {
      // eslint-disable-next-line no-console
      console.error('Invalid response', JSON.stringify(data), 'error:', e);
      const error = new ApiError(
        `Response from ${apiServiceEntry.method ?? 'GET'} ${url} doesn't match response schema - ${e.message}`,
        response.status
      );
      dispatchRequestError(
        {status: response.status, statusText: response.statusText, data, error},
        fn,
        actionTypes,
        transformers,
        dispatch
      );
      throw error;
    }
    throw e;
  }
};

export type SuccessPayload = TODO;
const dispatchRequestSuccess = (
  payload: SuccessPayload,
  fn: string,
  actionTypes: ActionTypeMapping,
  dispatch: Dispatch
) => {
  dispatch({
    type: actionTypes[fn].SUCCESS,
    payload,
  });
};

export type ErrorPayload = {
  status: number;
  statusText: string;
  data?: unknown;
  error?: Error;
};
const dispatchRequestError = (
  payload: ErrorPayload,
  fn: string,
  actionTypes: ActionTypeMapping,
  transformers: PayloadTransformers,
  dispatch: Dispatch
) => {
  dispatch({
    type: actionTypes[fn].ERROR,
    payload: transformers.ERROR ? transformers.ERROR(payload) : payload,
  });
};

/**
 * Returns params that are automatically available to any route.
 */
const getContextParams = (state: RootState) => ({
  workspaceId: getWorkspaceId(state).toString(),
});

const generateActionCreatorMapping = (
  entries: ApiServiceEntry[],
  actionTypes: ActionTypeMapping,
  config: ApiServiceConfiguration
): ActionCreatorMapping => {
  const actionCreatorMapping: ActionCreatorMapping = {};
  for (const entry of entries) {
    const actionCreator = generateActionCreator(entry, actionTypes, config);
    actionCreatorMapping[entry.fn] = actionCreator;
  }
  return actionCreatorMapping;
};

const generateActionCreator = (
  entry: ApiServiceEntry,
  actionTypes: ActionTypeMapping,
  config: ApiServiceConfiguration
) => {
  const apiServiceState: ApiServiceState = {
    ongoingRequest: null,
    abortOngoingRequest: null,
  };
  const actionCreator = ({body = undefined, params = undefined, queryParams = {}, transformers = {}} = {}) => {
    const thunkAction = (dispatch: Dispatch, getState: GetState) => {
      const contextParams = getContextParams(getState());
      return performApiRequest(
        body,
        params,
        queryParams,
        contextParams,
        transformers,
        entry,
        actionTypes,
        config,
        dispatch,
        apiServiceState
      );
    };
    return thunkAction;
  };
  return actionCreator;
};

const generateReducers = (
  entries: ApiServiceEntry[],
  actionTypes: ActionTypeMapping
): Reducer<TODO, ActionWithPayload> =>
  combineReducers(
    entries.reduce((reducers: ReducersMapObject<TODO, ActionWithPayload>, entry: ApiServiceEntry) => {
      reducers[entry.fn] = reducer(entry.fn, actionTypes, entry.storeData);
      return reducers;
    }, {})
  );

const generateActionTypes = (entries: ApiServiceEntry[], config: ApiServiceConfiguration): ActionTypeMapping => {
  const actionTypes: ActionTypeMapping = {};
  for (const entry of entries) {
    const name = `@API_${config.apiName}_${entry.fn.toUpperCase()}`;
    actionTypes[entry.fn] = {
      PENDING: `${name}_PENDING`,
      SUCCESS: `${name}_SUCCESS`,
      ERROR: `${name}_ERROR`,
    };
  }
  return actionTypes;
};

export interface ApiServiceEntry {
  fn: string;
  route: string;
  method?: string;
  storeData?: boolean;
  transformers?: PayloadTransformers;
  headers?: Record<string, string>;
  isFormData?: boolean;
  abortPrevCalls?: boolean;
  requestSchema?: z.ZodType<unknown>;
  responseSchema?: z.ZodType<unknown>;
}

export const makeApiService = (
  entries: ApiServiceEntry[] = [],
  apiServiceConfiguration: ApiServiceConfiguration
): ApiService => {
  const config = {
    ...apiServiceConfiguration,
  };
  config.apiName = config.apiName.toUpperCase();

  const actionTypes: ActionTypeMapping = generateActionTypes(entries, config);
  return {
    actionTypes,
    actions: generateActionCreatorMapping(entries, actionTypes, config),
    reducers: generateReducers(entries, actionTypes),
  };
};
