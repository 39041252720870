import React, {FC} from 'react';
import {AutoComplete} from 'antd';

const defaultBroker = [
  'Clarksons',
  'Containerland',
  'FH Bertling',
  'Harper Petersen',
  'Howe Robinson',
  'Maersk Broker',
  'Martini Chartering',
  'Maxbridge',
  'Mentz Decker',
  'Seapiece',
];

export const BrokerPicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  return (
    <AutoComplete
      id={`form-options-broker`}
      value={value}
      onChange={(value: string | null) => onChange?.(value)}
      options={defaultBroker.map((value: string) => ({value, label: value}))}
      allowClear={true}
      placeholder={'Select or add...'}
      maxLength={127}
    />
  );
};
