import React, {ReactNode} from 'react';
import styled from 'styled-components';
import Flag from '../../../../atoms/Flags';
import Country from '../../../../model/Country';
import {VesselDetailImage} from '../../VesselDetailHeader';
import {useDispatch} from '../../../../redux/react-redux';
import {ModalActions} from '../../../../redux/Modal';
import {Button} from 'antd';
import {VesselDetailImageModal} from '../../../../components/Modal/VesselDetailImage';
import {Link} from 'react-router-dom';
import {VesselDetailsVessel} from '../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {NotAvailable} from '../../../../utils/NotAvailable';
import {VesselStatusIcon} from '../../components/VesselStatusLabel';

type VesselItemHeaderProps = {
  vessel: VesselDetailsVessel;
  images?: VesselDetailImage[];
  showDetailLink?: boolean;
};

export const VesselItemHeader = ({vessel, images, showDetailLink}: VesselItemHeaderProps) => {
  const dispatch = useDispatch();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const title = vessel.name || NotAvailable;
  const flag = vessel.countryFlag;
  const subTitle = Country.getName(flag) || NotAvailable;
  const url = `/vessel/${vessel.id}`;

  return (
    <>
      <ItemTitle>
        {flag && (
          <span>
            <ItemTitleFlag shadow={true} width={30} height={22} countryCode={flag} />
          </span>
        )}
        <span>
          {title}
          {vessel.status && <VesselStatusIcon status={vessel.status} />}
        </span>
        {images && images.length > 0 && (
          <DetailsButton
            onClick={() => showModal(<VesselDetailImageModal images={images} title={title} />)}
            type="default">
            Vessel images
          </DetailsButton>
        )}
        {showDetailLink && (
          <Link to={url}>
            <DetailsButton type="default">View details</DetailsButton>
          </Link>
        )}
      </ItemTitle>
      <ItemLocation>{subTitle}</ItemLocation>
    </>
  );
};

const ItemTitle = styled.h2`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: var(--font-size-2xl);
  font-weight: bold;
  margin-top: 3px;
  margin-bottom: 10px;
`;

const ItemTitleFlag = styled(Flag)`
  margin-top: -3px;
  margin-right: 10px;
`;

const DetailsButton = styled(Button)`
  margin-top: 5px;
  margin-left: 10px;
  float: right;
`;

const ItemLocation = styled.p`
  text-transform: uppercase;
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
  font-weight: bold;
  margin-bottom: 20px;
`;
