/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  Problem,
  ProblemFromJSON,
  ProblemToJSON,
  SortDirection,
  SortDirectionFromJSON,
  SortDirectionToJSON,
} from '../models';

export interface ExportVesselFixtureListRequest {
  workspaceId: number;
  exportType?: string;
  filters?: string;
}

export interface GetVesselFixtureListRequest {
  workspaceId: number;
  pageIndex?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: SortDirection;
  filters?: string;
}

export interface RemoveVesselFixtureRequest {
  fixture: number;
}

export interface UpdateVesselFixtureRequest {
  workspaceId: number;
  fixture: number;
  body: object;
}

/**
 *
 */
export class VesselFixtureApi extends runtime.BaseAPI {
  /**
   */
  async createVesselFixtureRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/vessel-fixtures`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async createVesselFixture(): Promise<void> {
    await this.createVesselFixtureRaw();
  }

  /**
   */
  async exportVesselFixtureListRaw(
    requestParameters: ExportVesselFixtureListRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling exportVesselFixtureList.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.exportType !== undefined) {
      queryParameters['export-type'] = requestParameters.exportType;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters['filters'] = requestParameters.filters;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/workspaces/{workspaceId}/vessel-fixtures/export`.replace(
        `{${'workspaceId'}}`,
        encodeURIComponent(String(requestParameters.workspaceId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async exportVesselFixtureList(requestParameters: ExportVesselFixtureListRequest): Promise<void> {
    await this.exportVesselFixtureListRaw(requestParameters);
  }

  /**
   */
  async getVesselFixtureListRaw(requestParameters: GetVesselFixtureListRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling getVesselFixtureList.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.pageIndex !== undefined) {
      queryParameters['pageIndex'] = requestParameters.pageIndex;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters['sortField'] = requestParameters.sortField;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters['filters'] = requestParameters.filters;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/workspaces/{workspaceId}/vessel-fixtures`.replace(
        `{${'workspaceId'}}`,
        encodeURIComponent(String(requestParameters.workspaceId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async getVesselFixtureList(requestParameters: GetVesselFixtureListRequest): Promise<object> {
    const response = await this.getVesselFixtureListRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async removeVesselFixtureRaw(requestParameters: RemoveVesselFixtureRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.fixture === null || requestParameters.fixture === undefined) {
      throw new runtime.RequiredError(
        'fixture',
        'Required parameter requestParameters.fixture was null or undefined when calling removeVesselFixture.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/vessel-fixtures/{fixture}`.replace(
        `{${'fixture'}}`,
        encodeURIComponent(String(requestParameters.fixture))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async removeVesselFixture(requestParameters: RemoveVesselFixtureRequest): Promise<void> {
    await this.removeVesselFixtureRaw(requestParameters);
  }

  /**
   */
  async updateVesselFixtureRaw(requestParameters: UpdateVesselFixtureRequest): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
      throw new runtime.RequiredError(
        'workspaceId',
        'Required parameter requestParameters.workspaceId was null or undefined when calling updateVesselFixture.'
      );
    }

    if (requestParameters.fixture === null || requestParameters.fixture === undefined) {
      throw new runtime.RequiredError(
        'fixture',
        'Required parameter requestParameters.fixture was null or undefined when calling updateVesselFixture.'
      );
    }

    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling updateVesselFixture.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/workspaces/{workspaceId}/vessel-fixtures/{fixture}`
        .replace(`{${'workspaceId'}}`, encodeURIComponent(String(requestParameters.workspaceId)))
        .replace(`{${'fixture'}}`, encodeURIComponent(String(requestParameters.fixture))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async updateVesselFixture(requestParameters: UpdateVesselFixtureRequest): Promise<object> {
    const response = await this.updateVesselFixtureRaw(requestParameters);
    return await response.value();
  }
}
