import {FC} from 'react';
import {
  NegotiationRoundResult,
  NegotiationRoundResultSummary,
  NegotiationStatus,
  NegotiationSummary,
} from '../../../../../api/node-backend/generated';
import styled from 'styled-components';
import {ReplyTimeLabel} from '../../../Components/ReplyTime/ReplyTimeLabel';
import {ReplyTimeDraftLabel} from '../../../Components/ReplyTime/ReplyTimeDraftLabel';
import {Button} from 'antd';
import Icon from '../../../../../atoms/Icon';
import {NegotiationReplyTimeEditorPopover} from './ReplyTimeEditor/NegotiationReplyTimeEditorPopover';
import {getIsReplyTimeExpired, getIsReplyTimeSet} from '../../../Components/ReplyTime/utils';
import {NegotiationCard} from '../../../Components/shared';
import {NegotiationActions} from '../CommitArea/utils/useGetNegotiationActionsWithMutations';

type NegotiationReplyTimeAreaProps = {
  negotiation: NegotiationSummary;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  currentRoundResult?: NegotiationRoundResult;
  actionsBusy?: boolean;
  negotiationActions: NegotiationActions;
};

export const NegotiationReplyTimeArea: FC<NegotiationReplyTimeAreaProps> = ({
  negotiation,
  roundResultSummaries,
  currentRoundResult,
  actionsBusy,
  negotiationActions,
}) => {
  if (!currentRoundResult) {
    return null;
  }

  const {status, party} = negotiation;
  const {replyTime, committedAt} = currentRoundResult;
  const isCommitted = !!committedAt;
  const isCurrentReplyTimeSet = getIsReplyTimeSet(replyTime);
  const isCurrentExpired = isCurrentReplyTimeSet && getIsReplyTimeExpired(replyTime, committedAt);

  const latestCounterRound = roundResultSummaries?.findLast(round => round.party !== party);
  const previousReplyTime = latestCounterRound?.replyTime ?? null;
  const previousCommittedAt = latestCounterRound?.committedAt ?? null;
  const isPreviousReplyTimeSet = getIsReplyTimeSet(previousReplyTime);
  const isPreviousExpired = isPreviousReplyTimeSet && getIsReplyTimeExpired(previousReplyTime, previousCommittedAt);

  const {setReplyTime} = negotiationActions;

  return (
    <ReplyTimeAreaCard data-testid="NegotiationReplyTimeArea">
      {status !== NegotiationStatus.Offer && !isCommitted && (
        <ReplyTimeContainer $isReplyTimeSet={isPreviousReplyTimeSet} $isReplyTimeExpired={isPreviousExpired}>
          <ReplyTimeLabel
            replyTime={previousReplyTime}
            committedAt={previousCommittedAt}
            dateTimePrefix="Respond by "
            countdownPrefix="You have "
            countdownSuffix=" left"
            expiredText="Your reply time has expired"
            emptyText="No reply time set by counterpart"
          />
        </ReplyTimeContainer>
      )}
      <ReplyTimeContainer $isReplyTimeSet={isCurrentReplyTimeSet} $isReplyTimeExpired={isCurrentExpired}>
        {isCommitted ? (
          <ReplyTimeLabel
            replyTime={replyTime}
            committedAt={committedAt}
            dateTimePrefix="Counterpart has until "
            dateTimeSuffix=" to respond"
            countdownPrefix="Your counterpart has "
            countdownSuffix=" left"
            expiredText="Counterpart's reply time has expired"
            emptyText="No reply time set by you"
          />
        ) : (
          <>
            <ReplyTimeDraftLabel emptyText="" replyTime={replyTime} />
            <NegotiationReplyTimeEditorPopover
              replyTime={replyTime}
              disabled={actionsBusy}
              onReplyTimeChange={setReplyTime}>
              <EditButton type="primary" size="small">
                {isCurrentReplyTimeSet ? 'Edit' : 'Set'} reply time {isCurrentReplyTimeSet ? '' : 'for counterpart'}
                <Icon type="edit" color="white" />
              </EditButton>
            </NegotiationReplyTimeEditorPopover>
          </>
        )}
      </ReplyTimeContainer>
    </ReplyTimeAreaCard>
  );
};

const ReplyTimeAreaCard = styled(NegotiationCard)`
  width: var(--main-width);
  margin: 0 auto 10px;
  overflow: initial;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
  }
`;

const ReplyTimeContainer = styled.div<{$isReplyTimeSet?: boolean; $isReplyTimeExpired?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 5px;
  background: ${({$isReplyTimeSet, $isReplyTimeExpired}) => {
    if ($isReplyTimeExpired) {
      return 'var(--color-red-bg)';
    }
    if ($isReplyTimeSet) {
      return 'var(--color-blue-bg)';
    }
    return 'var(--color-gray-5)';
  }};
  font-size: var(--font-size);
  font-weight: bold;
  border-radius: var(--border-radius-tab);
`;

const EditButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  gap: 2px;
  height: unset !important;
  padding: 1px 3px 1px 8px !important;
  margin-left: 5px;
  line-height: 1;
`;
