/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NegotiationTermStatus {
  Ongoing = 'ongoing',
  Accepted = 'accepted',
  Rejected = 'rejected',
  RejectionRequested = 'rejection_requested',
}

export function NegotiationTermStatusFromJSON(json: any): NegotiationTermStatus {
  return NegotiationTermStatusFromJSONTyped(json, false);
}

export function NegotiationTermStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationTermStatus {
  return json as NegotiationTermStatus;
}

export function NegotiationTermStatusToJSON(value?: NegotiationTermStatus | null): any {
  return value as any;
}
