import {Link} from 'react-router-dom';
import {components} from 'react-select';
import Icon from '../../atoms/Icon';

const AutoCompleteMenuList = (props: $TSFixMe) => {
  const noOptions = props.options.reduce((tally: $TSFixMe, curr: $TSFixMe) => {
    if (!curr.isOverViewPageLink && curr.options) {
      return tally + curr.options.length;
    }
    return tally;
  }, 0);

  return (
    <components.MenuList {...props}>
      {props.selectProps.inputValue && (
        <div className={'default-options-container'}>
          <div id="overviewLink" />
          <Link to={`/search/${props.selectProps.inputValue}`} className={'autocomplete-menu-list'}>
            <span className={'autocomplete-menu-list__text'}>Show all search results</span>
            <span className={'autocomplete-menu-list__icon'}>
              <Icon type={'angle-right'} color={'white'} />
            </span>
          </Link>
        </div>
      )}
      {props.options.length > 0 && noOptions === 0 ? (
        <div className={'default-options-container__no-options'}>No options</div>
      ) : (
        props.children
      )}
    </components.MenuList>
  );
};

export default AutoCompleteMenuList;
