import {PortLocation} from '../LocationOutput/PortLocation';
import {vesselDisplay} from '../../atoms/DropdownDisplay/vesselDisplay';
import {AisVesselDisplay} from '../../atoms/DropdownDisplay/AisVesselDisplay';
import {CargoDisplay} from '../../atoms/DropdownDisplay/CargoDisplay';

export const prepareData = (newData: $TSFixMe) => {
  const data = Object.keys(newData.data.items).map(label => ({
    label,
    options: !newData.data.items[label].items
      ? []
      : newData.data.items[label].items.map((entry: $TSFixMe) => {
          switch (entry._source?.aliasName || entry._index) {
            case 'seabo_ports':
              return {
                searchTitle: <PortLocation location={{highlight: entry.highlight, ...entry._source}} renderAlias />,
                searchSubTitle: '',
                platformLink: entry._source.code ? '/ports/' + entry._source.code : '#',
                ...entry._source,
              };
            case 'seabo_vessels':
              return {
                searchTitle: vesselDisplay(entry._source, entry.highlight),
                searchSubTitle: '',
                platformLink: '/vessel/' + entry._source.id || '#',
                ...entry._source,
              };
            case 'seabo_cargoes':
              return {
                searchTitle: <CargoDisplay cargo={entry._source} />,
                searchSubTitle: '',
                platformLink: '/cargo/' + entry._source.id || '#',
                ...entry._source,
              };
            case 'seabo_projects':
              return {
                searchTitle: entry._source.name,
                searchSubTitle: entry._source.readAbleProjectId,
                platformLink: '/projects/' + entry._source.id || '#',
                ...entry._source,
              };
            case 'seabo_contacts':
              return {
                searchTitle: entry._source.fullName,
                searchSubTitle: entry._source.email,
                platformLink: entry._source.platformUserId
                  ? '/user/' + entry._source.platformUserId
                  : '/contacts#contacts',
                ...entry._source,
              };
            case 'seabo_users':
              return {
                searchTitle: entry._source.fullName,
                searchSubTitle: entry._source.email,
                platformLink: entry._source.id ? '/user/' + entry._source.id : '/contacts',
                ...entry._source,
              };
            case 'seabo_negotiations':
              return {
                searchTitle: entry._source.shortId,
                searchSubTitle: '',
                platformLink: '/negotiations/' + entry._source.shortId || '#',
                ...entry._source,
              };
            case 'seabo_ais_vessels':
              return {
                searchTitle: <AisVesselDisplay ais={entry._source} />,
                searchSubTitle: '',
                platformLink: '',
                type: 'ais-vessel',
                ...entry._source,
              };
            default: {
              return entry._source;
            }
          }
        }),
  }));
  return [
    {
      label: 'overviewPage',
      isOverViewPageLink: true,
      options: [
        {
          overViewPageLink: true,
          platformLink: '/search/',
        },
      ],
    },
    ...data,
  ];
};
