import classNames from 'classnames';
import Icon from '../../../atoms/Icon';
import {easySharingPromotionColumn} from '../../../components/GridLayout/columns/shared/easySharingPromotionColumn';
import {CustomColumnDef, DataGrid} from '../../../components/DataGrid/DataGrid';
import {TODO} from '../../../utils/TODO';
import {useMemo} from 'react';

export const MarketExpander = ({isExpanded, number}: $TSFixMe) =>
  number !== 0 ? (
    <div
      className={classNames('datagrid-expander datagrid-expander--market', {
        'datagrid-expander--expanded': isExpanded,
      })}>
      {!isExpanded ? number : <Icon type="angle-up" />}
    </div>
  ) : null;

export const MarketSubComponent = ({original, columns}: $TSFixMe) => {
  // useMemo is required because the columns are being recreated on every render.
  // Without memoization this means the "open" state of the Add to My Cargos modal would be lost.
  //
  // See also FRIN-5431
  const gridColumns: CustomColumnDef<TODO>[] = useMemo(() => {
    const clipBoard = columns.find((column: $TSFixMe) => column.id && column.id === 'clipBoard');
    const filterColumns = columns.filter((column: $TSFixMe) => column.id && column.id !== 'clipBoard');
    return [
      easySharingPromotionColumn(),
      clipBoard,
      {
        width: 45,
        header: '',
        id: 'datagrid-sub-component',
        className: 'datagrid-sub-component-indicator',
        accessorFn: () => (
          <div className="datagrid-sub-component-indicator__line">
            <span className={'datagrid-sub-component-indicator__line-before'} />
            <span className={'datagrid-sub-component-indicator__line-after'} />
          </div>
        ),
        cell: (info: TODO) => info.getValue(),
      },
      ...filterColumns,
    ].filter(column => column);
  }, [columns]);

  return original.attachedOffers.length > 0 ? (
    <div className="datagrid-sub-component">
      <DataGrid responsive columns={gridColumns} data={original.attachedOffers} hideHeader={true} />
    </div>
  ) : null;
};
