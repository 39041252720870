/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanyWorkspace
 */
export interface CompanyWorkspace {
  /**
   *
   * @type {number}
   * @memberof CompanyWorkspace
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CompanyWorkspace
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompanyWorkspace
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyWorkspace
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CompanyWorkspace
   */
  isDefault: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CompanyWorkspace
   */
  isDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CompanyWorkspace
   */
  easySharing: boolean;
  /**
   *
   * @type {Date}
   * @memberof CompanyWorkspace
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof CompanyWorkspace
   */
  updatedAt: Date;
}

export function CompanyWorkspaceFromJSON(json: any): CompanyWorkspace {
  return CompanyWorkspaceFromJSONTyped(json, false);
}

export function CompanyWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyWorkspace {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    email: !exists(json, 'email') ? undefined : json['email'],
    isDefault: json['isDefault'],
    isDisabled: json['isDisabled'],
    easySharing: json['easySharing'],
    createdAt: new Date(json['createdAt']),
    updatedAt: new Date(json['updatedAt']),
  };
}

export function CompanyWorkspaceToJSON(value?: CompanyWorkspace | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    email: value.email,
    isDefault: value.isDefault,
    isDisabled: value.isDisabled,
    easySharing: value.easySharing,
    createdAt: value.createdAt.toISOString(),
    updatedAt: value.updatedAt.toISOString(),
  };
}
