import styled from 'styled-components';
import BaseModal from '../../../atoms/BaseModal';
import {VesselDetailImage} from '../../../screens/VesselDetailScreen/VesselDetailHeader';

export interface VesselDetailImageModalProps {
  title: string;
  images: VesselDetailImage[];
}

export const VesselDetailImageModal = ({title, images}: VesselDetailImageModalProps) => {
  return (
    <BaseModal title={title} subTitle="Vessel images">
      {() => (
        <FlexBox>
          {images.map(image => (
            <VesselImage src={image.url} alt={''} />
          ))}
        </FlexBox>
      )}
    </BaseModal>
  );
};

const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const VesselImage = styled.img`
  min-height: 50px;
  height: auto;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  margin-bottom: 10px;
  border: var(--border-base);
  border-radius: var(--border-radius);
`;
