import {Tooltip} from 'antd';
import {FC, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

type TruncatedTextWithHintProps = {
  text: string;
  maxCharacters?: number;
};

export const TruncatedTextWithHint: FC<TruncatedTextWithHintProps> = ({text, maxCharacters}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    if (maxCharacters) {
      return;
    }

    const calculateOverflow = () => {
      const hasOverflow = (textRef.current?.scrollWidth ?? 0) > (textRef.current?.clientWidth ?? 0);
      setHasOverflow(hasOverflow);
    };

    window.addEventListener('resize', calculateOverflow);
    return () => {
      window.removeEventListener('resize', calculateOverflow);
    };
  }, [textRef, text, maxCharacters]);

  if (!maxCharacters) {
    if (hasOverflow) {
      return (
        <Tooltip title={text} trigger={'hover'}>
          <TruncatedText ref={textRef}>{text}</TruncatedText>
        </Tooltip>
      );
    }
    return <TruncatedText ref={textRef}>{text}</TruncatedText>;
  }

  if (text.length > maxCharacters) {
    return (
      <Tooltip title={text} trigger={'hover'}>
        <span ref={textRef}>{text.slice(0, 25)}...</span>
      </Tooltip>
    );
  }

  return <span ref={textRef}>{text}</span>;
};

const TruncatedText = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
