import React, {FC} from 'react';
import {Checkbox, Form} from 'antd';
import {FormValues} from '../FormValues';
import {FormInstance} from 'antd';

export const UpdateVesselInMyFleetSection: FC<{form: FormInstance<FormValues>}> = ({form}) => {
  const vessel = Form.useWatch<FormValues['vessel']>('vessel', form);
  const isPortfolio = vessel?.targetType === 'portfolio';

  return (
    <div data-cy="UpdateVesselInMyFleetSection">
      <Form.Item name="updateVessel" valuePropName="checked" data-cy="updateVesselInMyFleet" style={{marginBottom: 0}}>
        <Checkbox disabled={!isPortfolio}>Update vessel in My fleet</Checkbox>
      </Form.Item>
    </div>
  );
};
