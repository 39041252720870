import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export interface DatabaseType {
  isArchived?: boolean;
}

export interface InternalType {
  isArchived?: boolean;
}

export const Defaults: InternalType = {
  isArchived: false,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {isArchived} = database;
  return {
    isArchived: isArchived ?? undefined,
  };
};

export const toDatabase = ({isArchived}: InternalType): DatabaseType => ({
  isArchived: isArchived ?? undefined,
});

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Archive',
  branch: 'archive',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
