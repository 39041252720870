import {actions as contactApi, actionTypes as contactApiTypes} from './ApiService/ContactsService';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {RootState} from './store';
import {TODO} from '../utils/TODO';
import {getLocalStorage} from '../utils/useLocalStorage';

const actionTypes = {
  CONTACTS_SET_LIST_PARAMS: 'CONTACTS_SET_LIST_PARAMS',
  CONTACTS_GROUPS_SET_LIST_PARAMS: 'CONTACTS_GROUPS_SET_LIST_PARAMS',
  CONTACTS_GROUPS_DETAILS_SET_LIST_PARAMS: 'CONTACTS_GROUPS_DETAILS_SET_LIST_PARAMS',
};

interface PlatformUser {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  avatar: null | string;
  company: TODO | null;
}

type Image = TODO;

interface ContactGroup {
  id: number;
  name: string;
  description: string;
  image: Image;
  images: Image[];
}

interface Contact {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string | null;
  isConnected: boolean;
  isPlatformUser: boolean;
  isInvited: boolean;
  connectedState: number;
  lastInvite: null;
  platformUser: PlatformUser;
  groups: ContactGroup[];
}

interface Group {
  id: number;
  name: string;
  description: string;
  image: Image;
  images: Image[];
  contacts: Contact[];
}

interface ContactsState {
  contactsList: {
    items: Contact[];
    pageIndex: TODO;
    pageSize: number;
    totalItems: number;
    type: TODO;
    search: TODO;
  };
  screenLoading: boolean;
  screenLoadingGroups: boolean;
  groupsList: {
    group?: TODO;
    items: Group[];
    pageIndex: TODO;
    pageSize: number;
    totalItems: number;
    type: TODO;
    search: TODO;
  };
  groupsDetailsList: {
    search: string;
    group: TODO;
    contacts: TODO;
    pageIndex?: TODO;
    pageSize?: number;
    totalItems?: number;
  };
}

const initialState: ContactsState = {
  contactsList: {
    items: [],
    pageIndex: 1,
    pageSize: getLocalStorage('contact_page_size', 10),
    totalItems: 0,
    type: 'all',
    search: '',
  },
  screenLoading: true,
  screenLoadingGroups: true,
  groupsList: {
    items: [],
    pageIndex: 1,
    pageSize: getLocalStorage('group_page_size', 10),
    totalItems: 0,
    type: 'all',
    search: '',
  },
  groupsDetailsList: {
    search: '',
    group: null,
    contacts: [],
  },
};

export const contactsReducer = (state = initialState, {type, payload}: ActionWithPayload): ContactsState => {
  switch (type) {
    case actionTypes.CONTACTS_SET_LIST_PARAMS:
      return {
        ...state,
        contactsList: {
          ...state.contactsList,
          pageIndex: payload.pageIndex || state.contactsList.pageIndex,
          pageSize: payload.pageSize || state.contactsList.pageSize,
          type: payload.type || state.contactsList.type,
          search: payload.search || state.contactsList.search,
        },
      };
    case contactApiTypes.getContacts.SUCCESS:
      return {
        ...state,
        screenLoading: false,
        contactsList: {
          ...state.contactsList,
          items: payload.data.items || [],
          totalItems: payload.data.totalItems || state.contactsList.totalItems,
          search: '',
        },
      };
    case actionTypes.CONTACTS_GROUPS_SET_LIST_PARAMS:
      return {
        ...state,
        groupsList: {
          ...state.groupsList,
          pageIndex: payload.pageIndex || state.groupsList.pageIndex,
          pageSize: payload.pageSize || state.groupsList.pageSize,
          type: payload.type || state.groupsList.type,
          search: payload.search || state.groupsList.search,
        },
      };
    case contactApiTypes.getGroups.SUCCESS: {
      let contacts = [];

      if (state.groupsDetailsList.group) {
        contacts = payload.data.items.find(({id}: TODO) => id === state.groupsDetailsList.group)?.contacts;
      }

      return {
        ...state,
        screenLoadingGroups: false,
        groupsList: {
          ...state.groupsList,
          items: payload.data.items || [],
          totalItems: payload.data.totalItems || state.groupsList.totalItems,
          search: '',
        },
        groupsDetailsList: {
          ...state.groupsDetailsList,
          contacts,
        },
      };
    }
    case actionTypes.CONTACTS_GROUPS_DETAILS_SET_LIST_PARAMS: {
      return {
        ...state,
        groupsDetailsList: {
          ...state.groupsDetailsList,
          pageIndex: payload.pageIndex || state.groupsDetailsList.pageIndex,
          pageSize: payload.pageSize || state.groupsDetailsList.pageSize,
          search: payload.search || state.groupsDetailsList.search,
          group: payload.group || state.groupsDetailsList.group,
          contacts: payload.contacts || state.groupsDetailsList.contacts,
        },
      };
    }
    default:
      return state;
  }
};

export const setListParams = (params: TODO) => (dispatch: TODO) => {
  dispatch({
    type: actionTypes.CONTACTS_SET_LIST_PARAMS,
    payload: params,
  });

  dispatch(getContacts());
};

export const getContacts = () => (dispatch: TODO, getState: TODO) => {
  const {type, search, pageIndex, pageSize} = getState().contacts.contactsList;

  dispatch(
    contactApi.getContacts({
      queryParams: {
        pageIndex,
        pageSize,
        type,
        search,
      },
    })
  );
};

export const setGroupListParams = (params: TODO) => (dispatch: TODO) => {
  dispatch({
    type: actionTypes.CONTACTS_GROUPS_SET_LIST_PARAMS,
    payload: params,
  });

  dispatch(getGroups());
};

export const setGroupDetailsListParams = (params: TODO) => (dispatch: TODO) => {
  dispatch({
    type: actionTypes.CONTACTS_GROUPS_DETAILS_SET_LIST_PARAMS,
    payload: params,
  });

  const {reload = true} = params;

  if (reload) {
    // until we have a way to fetch only contacts for one group
    dispatch(getGroups(true));
  }
};

export const getGroups =
  (isDetail = false) =>
  (dispatch: TODO, getState: () => RootState) => {
    const {type, search, pageIndex, pageSize, group} = getState().contacts.groupsList;

    dispatch(
      contactApi.getGroups({
        queryParams: {
          pageIndex,
          pageSize: isDetail ? 9999 : pageSize,
          type,
          search,
          group,
        },
      })
    );
  };
