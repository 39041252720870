import {Form} from 'antd';
import {currencyOptions, numberFormat} from '../../../utils/formatter';
import {TimeCharterCalculationProp, TimeCharterCalculationResult} from './timeCharterCalculation';
import styled from 'styled-components';

import {NotAvailable} from '../../../utils/NotAvailable';

export interface ResultCardProps {
  inputState: TimeCharterCalculationProp;
  outputState: TimeCharterCalculationResult;
}

export const ResultCard = (props: ResultCardProps) => {
  const {durationInDays, netFreight, bunkerCosts, totalOtherExpenses, totalNetFreight, totalNetFreightPerDay} =
    props.outputState;
  const hasInput = durationInDays > 0;

  const isNegativ = (value = 0) => value < 0;

  return (
    <FormContainer>
      <FormItem label={'Total duration'}>{hasInput ? durationInDays + ' days' : NotAvailable}</FormItem>
      <FormItem $isNegativ={isNegativ(netFreight)} label={'Net freight'}>
        {hasInput ? '$' + numberFormat(netFreight, null, currencyOptions) : NotAvailable}
      </FormItem>
      <FormItem $isNegativ={isNegativ(bunkerCosts)} label={'Bunker costs'}>
        {hasInput ? '$' + numberFormat(bunkerCosts, null, currencyOptions) : NotAvailable}
      </FormItem>
      <FormItem $isNegativ={isNegativ(totalOtherExpenses)} label={'Other expenses'}>
        {hasInput ? '$' + numberFormat(totalOtherExpenses, null, currencyOptions) : NotAvailable}
      </FormItem>
      <FormItem $isNegativ={isNegativ(totalNetFreight)} label={'Total net freight:'}>
        {hasInput ? '$' + numberFormat(totalNetFreight, null, currencyOptions) : NotAvailable}
      </FormItem>
      <FormItem $isNegativ={isNegativ(totalNetFreightPerDay)} label={'Total net freight per Day:'}>
        {hasInput ? '$' + numberFormat(totalNetFreightPerDay, null, currencyOptions) : NotAvailable}
      </FormItem>
    </FormContainer>
  );
};

const FormContainer = styled(Form)`
  display: flex;
  gap: 10px;
  padding: 0 20px 2px;

  .ant-form-item-control {
    box-shadow: none;
    background: none;
  }
`;

const FormItem = styled(Form.Item)<{$isNegativ?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;
  padding: 5px 10px 8px;
  border: var(--border-base);
  border-radius: var(--border-radius-button);

  .ant-form-item-row {
    width: 100%;
  }
  .ant-form-item-label {
    display: block;
    flex: 0 0 100%;
    text-align: left;
    margin-bottom: -2px;
  }
  .ant-form-item-control {
    flex: 0 0 100%;
    font-weight: bold;
    .ant-form-item-control-input {
      min-height: unset;
      color: ${({$isNegativ}) => ($isNegativ ? 'red' : 'rgba(0, 0, 0, 0.85)')};
    }
  }
`;
