import {useQuery} from '@tanstack/react-query';
import {ApiError} from '../api/utils/ApiError';
import {weatherApi} from '../api/symfony/symfonyApi';
import {TODO} from '../utils/TODO';

const locationQueryKey = `useGetWeatherAndLocationByLatLonQueryKey`;

export const useGetWeatherAndLocationByLatLonQuery = ({lat, lon}: {lat: number; lon: number}) => {
  return useQuery<TODO, ApiError>({
    queryKey: [locationQueryKey, lat, lon],
    queryFn: async () => {
      return await weatherApi.getWeatherForecast({lat: lat.toString(), lon: lon.toString()});
    },
  });
};
