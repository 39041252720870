import {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import VesselServiceActions, {VesselServiceRequestInformation} from '../../redux/ApiService/vesselService';
import {NotFound} from '../../atoms/EmptyState/404';
import LoadingComponent from '../../components/DataGrid/LoadingComponent';
import MyFleetVesselDetails from './MyFleetVesselDetails';
import MarketVesselDetails from './MarketVesselDetails';
import {ModalActions} from '../../redux/Modal';
import {VesselDetailsActions} from '../../redux/VesselDetails';
import {TBN} from '../../components/Placeholder/Placeholder';
import {ExportFixturesModal} from '../VesselFixturesScreen/ExportFixturesModal/ExportFixturesModal';
import './style.scss';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {assert} from '../../utils/assert';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {invalidateGetVesselQuery} from '../../queries/useGetVesselQuery';
import {invalidateGetStatsVesselQuery, useGetStatsVesselQuery} from './useGetStatsVesselQuery';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import {useQueryClient} from '@tanstack/react-query';
import {VesselDetailsVessel} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {catch404} from '../../redux/ApiService/catch404';
import {useGetVesselDetailsQuery, VesselDetailsApi} from './useGetVesselDetailsQuery';
import ManageFixtureModal from '../../components/Modal/ManageFixture/ManageFixtureModal';
import {AddToProject} from '../../components/Modal/AddToProject/AddToProject';
import {Delete} from '../../components/Modal/Delete/DeleteModal';
import {PortfolioLocationEdit} from '../../components/Modal/PortfolioLocationEdit/PortfolioLocationEditModal';
import {ClonePortfolioEntry} from '../../components/Modal/ClonePortfolioEntry';

export const VesselDetailScreen: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<'id'>();
  const vesselId = parseInt(params.id!);
  const location = useLocation();
  const vesselDetailsApi: VesselDetailsApi = location.pathname.includes('vessel-database')
    ? 'new-vessel-db'
    : 'symfony';

  const queryClient = useQueryClient();

  const [exportFixtureModalVisible, setExportFixtureModalVisible] = useState(false);
  const vesselStatisticRequestInformation = useSelector(
    state => state.api.vessels.getStatsVessel
  ) as VesselServiceRequestInformation<unknown>;
  const statsVessel = useSelector(state => state.portfolio.statsVessel);

  const getVesselHistory = useCallback(
    async (vesselId: number) => {
      dispatch(VesselDetailsActions.getVesselHistory(vesselId));
    },
    [dispatch]
  );
  const getPortHistory = useCallback(
    async (vesselId: number) => {
      dispatch(VesselDetailsActions.getPortHistory(vesselId));
    },
    [dispatch]
  );

  const getVesselVoyages = useCallback(
    async (vesselId: number) => {
      dispatch(VesselDetailsActions.getVesselVoyages(vesselId));
    },
    [dispatch]
  );
  const deleteVessel = (vesselId: number) => dispatch(VesselServiceActions.deleteVessel(vesselId));
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const handleVesselLoaded = useCallback(
    async (vessel: VesselDetailsVessel | null) => {
      if (vessel) {
        getVesselHistory(vesselId).catch(catch404);
        getPortHistory(vesselId).catch(catch404);
        getVesselVoyages(vesselId).catch(catch404);
        window.scrollTo(0, 0);
      }
    },
    [getVesselHistory, getPortHistory, getVesselVoyages, vesselId]
  );

  const vesselQuery = useGetVesselDetailsQuery({
    vesselId,
    vesselDetailsApi,
  });

  const vessel: VesselDetailsVessel | null | undefined = vesselQuery.data;
  const imo = vessel?.aisDetails?.imo;
  useGetStatsVesselQuery(imo, {
    enabled: !!imo,
  });

  useEffect(() => {
    if (vesselQuery.isSuccess && vesselQuery.data) {
      void handleVesselLoaded(vesselQuery.data);
    }
  }, [vesselQuery.isSuccess, vesselQuery.data, handleVesselLoaded]);

  const handleVesselChanged = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    invalidateGetVesselQuery(queryClient, vesselId);
    if (imo) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateGetStatsVesselQuery(queryClient, imo);
    }
  };

  const handleOnAddToProjectClick = () => {
    showModal(<AddToProject type="vessel" id={vessel!.id} />);
  };

  const handleCloneClick = () => {
    assert(vessel);
    showModal(<ClonePortfolioEntry type="vessel" vessel={vessel} title="Clone vessel" buttonText="Clone vessel" />);
  };

  const onAddToMyFleetClick = () => {
    assert(vessel);
    showModal(
      <ClonePortfolioEntry type="vessel" vessel={vessel} title="Add to My Fleet" buttonText="Add to My Fleet" />
    );
  };

  const handleFixtureExportClick = () => {
    setExportFixtureModalVisible(true);
  };

  const handleDeleteClick = () => {
    assert(vessel);
    showModal(
      <Delete
        target={{
          name: vessel.name || <TBN />,
          id: vessel.id,
        }}
        onDelete={(id: number, callback: (props: {errors: $TSFixMe; bonusMsg: string}) => void, close: () => void) => {
          deleteVessel(id)
            .then(() => {
              close();
              navigate('/my-fleet');
            })
            .catch((e: $TSFixMe) => {
              const errors: $TSFixMe = [];
              if (e.payload && e.payload.error && e.payload.error.errors) {
                e.payload.error.errors.forEach((err: $TSFixMe) => {
                  const {type, id, name} = err.data;
                  const link = type === 'project' ? `/${type}s/${id}` : `/${type}s/${name}`;
                  errors.push({title: type, msg: name, link: link});
                });
                const bonusMsg =
                  'The vessel you want to delete is currently used in projects. Please delete the\nfollowing projects first.';
                callback({errors, bonusMsg});
              }
            });
        }}
      />
    );
  };

  const handleCreateFixtureClick = () => {
    assert(vessel);
    dispatch(
      ModalActions.show(
        <ManageFixtureModal
          updateVessel
          vessel={vessel}
          callback={() => {
            handleVesselChanged();
          }}
        />
      )
    );
  };

  const handleManageNextOpenLocationClick = () => {
    showModal(
      <PortfolioLocationEdit
        vessel={vessel!}
        withDateOpen
        callback={() => {
          handleVesselChanged();
        }}
        showManageNextButton={false}
      />
    );
  };

  const isLoading = vesselQuery.isLoading;
  const isNotFound = !vessel;
  if (isLoading) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }
  if (isNotFound) {
    return <NotFound />;
  }

  let breadcrumbs: BreadcrumbItem[] = [];
  switch (vessel.target) {
    case 'market': {
      breadcrumbs = [
        {title: 'Market', href: '/market/vessel'},
        vessel.offer?.isArchived ? {title: 'Archive'} : {title: 'Vessel circular'},
      ];
      break;
    }
    case 'database': {
      breadcrumbs = [{title: 'Vessel database', href: '/vessel-database'}, {title: 'Vessel'}];
      break;
    }
    case 'portfolio': {
      breadcrumbs = [
        {title: 'My Fleet', href: '/my-fleet'},
        vessel.isArchived ? {title: 'Archive'} : {title: 'Vessel'},
      ];
      break;
    }
    default:
      break;
  }

  return (
    <>
      <ExportFixturesModal
        visible={exportFixtureModalVisible}
        vesselId={vessel.id}
        onClose={() => setExportFixtureModalVisible(false)}
      />
      {vessel.target === 'portfolio' || vessel.target === 'database' ? (
        <MyFleetVesselDetails
          vessel={vessel}
          statsVessel={statsVessel}
          vesselStatisticRequestInformation={vesselStatisticRequestInformation}
          breadcrumbs={breadcrumbs}
          onVesselChanged={handleVesselChanged}
          onAddToProjectClick={handleOnAddToProjectClick}
          onDeleteClick={handleDeleteClick}
          onManageNextOpenLocationClick={handleManageNextOpenLocationClick}
          onCloneClick={handleCloneClick}
          onCreateFixtureClick={handleCreateFixtureClick}
          onFixtureExportClick={handleFixtureExportClick}
        />
      ) : (
        <MarketVesselDetails
          vessel={vessel}
          statsVessel={statsVessel}
          vesselStatisticRequestInformation={vesselStatisticRequestInformation}
          breadcrumbs={breadcrumbs}
          onVesselChanged={handleVesselChanged}
          onAddToMyFleetClick={onAddToMyFleetClick}
          onAddToProjectClick={handleOnAddToProjectClick}
          onCreateFixtureClick={handleCreateFixtureClick}
        />
      )}
    </>
  );
};
