import {Dayjs} from 'dayjs';
import {DistanceCalculation} from '../../../../api/symfony/generated';
import {FilterParams} from '../filterParams';
import {addSeaVoyageArrivalDate} from '../prepare/addSeaVoyageArrivalDate';
import {addSeaDistance} from '../prepare/getSeaDistance/addSeaDistance';
import {Step7Result} from './step7/step7';
import {Step7Vessel} from './step7/vesselWithEstimatedDestination/vesselWithEstimatedDestination';
import {StepResult} from './StepResult';

export type Step8Vessel = Step7Vessel & {
  distanceSeaVoyageInNm: number;
  distanceRoutes: DistanceCalculation[];
  estimatedArrivalDate: string;
};

export type Step8Result = StepResult<Step8Vessel>;

/**
 *  extend vessel  -calc and add distanceSeaVoyageInNm and estimatedArrivalDate
 */
export const step8 = async ({
  params,
  preResult,
  currentTime,
}: {
  preResult: Step7Result;
  params: FilterParams;
  currentTime?: Dayjs;
}): Promise<Step8Result> => {
  let vessels: Step8Vessel[] = await addSeaDistance({
    vessels: preResult.vessels,
    maxDistance: params.maxDistanceInKM,
    portCoordinates: params.portCoordinates,
    onChangeProgressInPercent: params.onChangeProgressInPercent,
  });

  vessels = addSeaVoyageArrivalDate({
    vessels: vessels,
    currentTime: currentTime,
  });

  return {
    vessels: vessels,
    loggedFilters: [...preResult.loggedFilters],
  };
};
