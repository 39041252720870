/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ContractType,
  ContractTypeFromJSON,
  ContractTypeToJSON,
  SearchContainer,
  SearchContainerFromJSON,
  SearchContainerToJSON,
} from '../models';

export interface GetAutoCompleteRequest {
  query: string;
  indexName?: string;
  indexNames?: string;
  pageIndex?: number;
  pageSize?: number;
  paramWorkspaces?: string;
  paramContractType?: ContractType;
  showTinyPorts?: number;
  vesselTypes?: string;
}

/**
 *
 */
export class SearchApi extends runtime.BaseAPI {
  /**
   * Fetches search results for an autocomplete search.
   */
  async getAutoCompleteRaw(requestParameters: GetAutoCompleteRequest): Promise<runtime.ApiResponse<SearchContainer>> {
    if (requestParameters.query === null || requestParameters.query === undefined) {
      throw new runtime.RequiredError(
        'query',
        'Required parameter requestParameters.query was null or undefined when calling getAutoComplete.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query;
    }

    if (requestParameters.indexName !== undefined) {
      queryParameters['indexName'] = requestParameters.indexName;
    }

    if (requestParameters.indexNames !== undefined) {
      queryParameters['indexNames'] = requestParameters.indexNames;
    }

    if (requestParameters.pageIndex !== undefined) {
      queryParameters['pageIndex'] = requestParameters.pageIndex;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.paramWorkspaces !== undefined) {
      queryParameters['param[workspaces]'] = requestParameters.paramWorkspaces;
    }

    if (requestParameters.paramContractType !== undefined) {
      queryParameters['param[contractType]'] = requestParameters.paramContractType;
    }

    if (requestParameters.showTinyPorts !== undefined) {
      queryParameters['showTinyPorts'] = requestParameters.showTinyPorts;
    }

    if (requestParameters.vesselTypes !== undefined) {
      queryParameters['vesselTypes'] = requestParameters.vesselTypes;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/search`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => SearchContainerFromJSON(jsonValue));
  }

  /**
   * Fetches search results for an autocomplete search.
   */
  async getAutoComplete(requestParameters: GetAutoCompleteRequest): Promise<SearchContainer> {
    const response = await this.getAutoCompleteRaw(requestParameters);
    return await response.value();
  }
}
