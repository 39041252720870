import React, {useMemo, useState, FC} from 'react';
import {OneDayStatistics, TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import {Col, Input, Row, Select} from 'antd';
import {Column} from './Column';
import {ValueRepresentation} from './getDatasource';
import {VesselType} from '../VesselType';
import {getColumnValues} from './getColumnValues';
import styled from 'styled-components';

export type TiedUpSortOption = 'name' | 'value+' | 'value-';

const sortOptions = [
  {label: 'Sort by most tied up', value: 'value+'},
  {label: 'Sort by least tied up', value: 'value-'},
  {label: 'Sort alphabetically', value: 'name'},
];

export type TiedUpDisplayOption = 'percentage' | 'absolute';

const DisplayOptions = [
  {label: 'Show percentages', value: 'percentage'},
  {label: 'Show absolute values', value: 'absolute'},
];

export const TiedUpVesselsSummary: FC<{
  vesselType: VesselType;
  basicQuantity: number;
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[];
  valueRepresentation: ValueRepresentation;
  accessor: (day: OneDayStatistics) => number;
  onChangeValueRepresentation: (value: ValueRepresentation) => void;
  selectedTradingArea: string;
  onSelectTradingArea: (tradingArea: string) => void;
  hoveredTradingArea: string;
  onHoverTradingArea: (tradingArea: string) => void;
}> = ({
  vesselType,
  basicQuantity,
  tradingAreaTiedUpVesselsStatistics,
  valueRepresentation,
  onChangeValueRepresentation,
  accessor,
  selectedTradingArea,
  onSelectTradingArea,
  hoveredTradingArea,
  onHoverTradingArea,
}) => {
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState<TiedUpSortOption>('name');

  return (
    <>
      <Container onMouseLeave={() => onHoverTradingArea('')}>
        <Header
          searchText={searchText}
          onChangeSearchText={setSearchText}
          sortBy={sortBy}
          onChangeSortBy={setSortBy}
          valueRepresentation={valueRepresentation}
          onChangeValueRepresentation={onChangeValueRepresentation}
        />
        <Content
          tradingAreaTiedUpVesselsStatistics={tradingAreaTiedUpVesselsStatistics}
          vesselType={vesselType}
          basicQuantity={basicQuantity}
          valueRepresentation={valueRepresentation}
          searchText={searchText}
          sortBy={sortBy}
          accessor={accessor}
          selectedTradingArea={selectedTradingArea}
          onSelectTradingArea={onSelectTradingArea}
          hoveredTradingArea={hoveredTradingArea}
          onHoverTradingArea={onHoverTradingArea}
        />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 188px);
`;

const Header: FC<{
  searchText: string;
  onChangeSearchText: (value: string) => void;
  sortBy: TiedUpSortOption;
  onChangeSortBy: (value: TiedUpSortOption) => void;
  valueRepresentation: ValueRepresentation;
  onChangeValueRepresentation: (value: ValueRepresentation) => void;
}> = ({searchText, onChangeSearchText, sortBy, onChangeSortBy, valueRepresentation, onChangeValueRepresentation}) => {
  return (
    <Row gutter={[8, 8]}>
      <Col span={6}>
        <Input.Search
          allowClear
          placeholder="Trading area"
          onChange={event => {
            onChangeSearchText(event.target.value);
          }}
          value={searchText}
          style={{}}
        />
      </Col>
      <Col span={6}>
        <Select<TiedUpSortOption>
          options={sortOptions}
          onChange={event => {
            onChangeSortBy(event);
          }}
          value={sortBy}
          style={{width: '100%'}}
        />
      </Col>
      <Col span={12}>
        <Select<TiedUpDisplayOption>
          options={DisplayOptions}
          onChange={event => {
            onChangeValueRepresentation(event);
          }}
          value={valueRepresentation}
          style={{minWidth: 180}}
        />
      </Col>
    </Row>
  );
};

const Footer: FC = () => (
  <Footnote>
    We consider all vessels that are slower than 5 knots on average. The indicated value is the average of the last 7
    days.
    <br />
    The trend shown is based on the quotient of the average of the last 7 days and the previous 23 days.
  </Footnote>
);

const Footnote = styled.p`
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: -4px;
`;

const Content: FC<{
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[];
  accessor: (day: OneDayStatistics) => number;
  basicQuantity: number;
  searchText: string;
  sortBy: TiedUpSortOption;
  vesselType: VesselType;
  valueRepresentation: ValueRepresentation;
  selectedTradingArea: string;
  onSelectTradingArea: (tradingArea: string) => void;
  hoveredTradingArea: string;
  onHoverTradingArea: (tradingArea: string) => void;
}> = ({
  searchText,
  sortBy,
  vesselType,
  valueRepresentation,
  tradingAreaTiedUpVesselsStatistics,
  accessor,
  basicQuantity,
  selectedTradingArea,
  onSelectTradingArea,
  hoveredTradingArea,
  onHoverTradingArea,
}) => {
  const values = useMemo(
    () =>
      getColumnValues({
        tradingAreaTiedUpVesselsStatistics,
        valueRepresentation,
        accessor,
        basicQuantity,
        searchText,
        sortBy,
      }),
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [searchText, sortBy, valueRepresentation, basicQuantity, tradingAreaTiedUpVesselsStatistics]
  );

  return (
    <ScrollContainer>
      <Column
        values={values[0] ?? []}
        valueRepresentation={valueRepresentation}
        vesselType={vesselType}
        selectedTradingArea={selectedTradingArea}
        onSelectTradingArea={onSelectTradingArea}
        hoveredTradingArea={hoveredTradingArea}
        onHoverTradingArea={onHoverTradingArea}
      />
      <Column
        values={values[1] ?? []}
        valueRepresentation={valueRepresentation}
        vesselType={vesselType}
        selectedTradingArea={selectedTradingArea}
        onSelectTradingArea={onSelectTradingArea}
        hoveredTradingArea={hoveredTradingArea}
        onHoverTradingArea={onHoverTradingArea}
      />
    </ScrollContainer>
  );
};

const ScrollContainer = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 100%;
  margin-top: 12px;
`;
