/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VesselRouteItem, VesselRouteItemFromJSON, VesselRouteItemFromJSONTyped, VesselRouteItemToJSON} from './';

/**
 *
 * @export
 * @interface VesselRouteResponse
 */
export interface VesselRouteResponse {
  /**
   *
   * @type {Array<VesselRouteItem>}
   * @memberof VesselRouteResponse
   */
  items: Array<VesselRouteItem>;
}

export function VesselRouteResponseFromJSON(json: any): VesselRouteResponse {
  return VesselRouteResponseFromJSONTyped(json, false);
}

export function VesselRouteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselRouteResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(VesselRouteItemFromJSON),
  };
}

export function VesselRouteResponseToJSON(value?: VesselRouteResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(VesselRouteItemToJSON),
  };
}
