import {FC} from 'react';
import {Switch} from 'antd';
import styled from 'styled-components';

export type ContractDuration = 'monthly' | 'yearly';

export const ContractDurationPicker: FC<{
  contractDuration: ContractDuration;
  setContractDuration: (contractDuration: ContractDuration) => void;
}> = ({contractDuration, setContractDuration}) => {
  return (
    <>
      <StyledSwitch
        checked={contractDuration === 'yearly'}
        onChange={checked => setContractDuration(checked ? 'yearly' : 'monthly')}
        data-testid="ContractDurationPicker"
        checkedChildren="Yearly"
        unCheckedChildren="Monthly"
      />
    </>
  );
};

const StyledSwitch = styled(Switch)`
  .ant-switch-inner {
    background: #40a2ff;
  }
`;
