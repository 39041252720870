/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselGearDTO
 */
export interface VesselGearDTO {
  /**
   * Primary key in the node vessel DB, NOT the Symfony DB.
   * @type {number}
   * @memberof VesselGearDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof VesselGearDTO
   */
  type: VesselGearDTOTypeEnum;
  /**
   *
   * @type {number}
   * @memberof VesselGearDTO
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof VesselGearDTO
   */
  capacity: number | null;
  /**
   *
   * @type {string}
   * @memberof VesselGearDTO
   */
  description: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum VesselGearDTOTypeEnum {
  Gear = 'gear',
  Grab = 'grab',
}

export function VesselGearDTOFromJSON(json: any): VesselGearDTO {
  return VesselGearDTOFromJSONTyped(json, false);
}

export function VesselGearDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselGearDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    quantity: json['quantity'],
    capacity: json['capacity'],
    description: json['description'],
  };
}

export function VesselGearDTOToJSON(value?: VesselGearDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    quantity: value.quantity,
    capacity: value.capacity,
    description: value.description,
  };
}
