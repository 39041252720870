import {FC, ReactNode} from 'react';
import {MenuItem, MenuItemLink} from '../../../atoms/Menu/MenuItem';
import vesselService from '../../../redux/ApiService/vesselService';
import {ModalActions} from '../../../redux/Modal';
import {PortfolioVessel} from '../../../redux/Portfolio';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {useOpenVesselForm} from '../../../screens/CargoVesselForm/utils/useOpenVesselForm';
import {ActionMenu} from '../../ActionMenu/ActionMenu';
import {TBN} from '../../Placeholder/Placeholder';
import './style.scss';
import {VesselFromApi} from '../../Modal/ManageFixture/ManageFixtureForm/sections/GeneralSection/VesselFromApi';
import {useUser} from '../../UserContext/useUser';
import ManageFixtureModal from '../../Modal/ManageFixture/ManageFixtureModal';
import {ClonePortfolioEntry} from '../../Modal/ClonePortfolioEntry';
import Confirmation from '../../Modal/Confirmation';
import PortfolioColorEdit from '../../Modal/PortfolioColorEdit';
import {AddToProject} from '../../Modal/AddToProject/AddToProject';
import {Delete} from '../../Modal/Delete/DeleteModal';
import {Archive} from '../../Modal/Archive';
import {MoveToWorkspace} from '../../Modal/MoveToWorkspace';

interface VesselGridActionMenuProps {
  vessel: PortfolioVessel;
  isArchive: boolean;
  reloadGrid: (pageIndex?: number) => void;
  onRemoveFromClipboard: (vessel: PortfolioVessel) => void;
  onManageSubPortfolios: (vessel: PortfolioVessel) => void;
}

export const VesselGridActionMenu: FC<VesselGridActionMenuProps> = ({
  vessel,
  isArchive,
  reloadGrid,
  onManageSubPortfolios,
  onRemoveFromClipboard,
}) => {
  const user = useUser();
  const userCompany = user.payload?.company;
  const clipboardState = useSelector(state => state.gridClipboard.portfolio.vessels);

  const dispatch = useDispatch();
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));
  const deleteVessel = (vesselId: number) => dispatch(vesselService.deleteVessel(vesselId));
  const updateVessel = (vesselId: number, body: PortfolioVessel) => dispatch(vesselService.patch(vesselId, body));
  const removeFixtureDetails = (vesselId: number) => dispatch(vesselService.removeFixtureDetails(vesselId));

  const openVesselForm = useOpenVesselForm();
  return (
    <ActionMenu
      items={[
        {
          key: 'view',
          label: <MenuItemLink label="View vessel" href={`/vessel/${vessel.id}`} />,
        },
        {
          key: 'add',
          isValid: !isArchive,
          label: (
            <MenuItem
              label="Add to project"
              onClick={() => {
                showModal(<AddToProject type="vessel" id={vessel.id} />);
              }}
              withDivider
            />
          ),
        },
        {
          key: 'move',
          isValid: !isArchive && !!(userCompany && userCompany.id),
          label: (
            <MenuItem
              label="Move to workspace"
              onClick={() => {
                showModal(<MoveToWorkspace type="portfolioVessel" data={vessel} reload={() => reloadGrid(1)} />);
              }}
            />
          ),
        },
        {
          key: 'manage',
          isValid: !isArchive,
          label: <MenuItem label="Manage vessel lists" onClick={() => onManageSubPortfolios(vessel)} />,
        },
        {
          key: 'edit',
          label: <MenuItem label="Edit vessel" onClick={() => openVesselForm({id: vessel.id})} withDivider />,
        },
        {
          key: 'archive',
          label: (
            <MenuItem
              label={!isArchive ? 'Archive vessel' : 'Restore vessel'}
              onClick={() => {
                showModal(
                  <Archive
                    type="vessel"
                    target={vessel}
                    update={(
                      id: number,
                      body: PortfolioVessel,
                      close: () => void,
                      setError: (error: boolean) => void
                    ) => {
                      updateVessel(id, body).then(
                        () => {
                          reloadGrid(1);
                          close();
                        },
                        () => {
                          setError(true);
                        }
                      );
                    }}
                  />
                );
              }}
            />
          ),
        },
        {
          key: 'clone',
          isValid: !isArchive,
          label: (
            <MenuItem
              label="Clone vessel"
              onClick={() => {
                showModal(
                  <ClonePortfolioEntry
                    title="Clone vessel"
                    buttonText="Clone vessel"
                    type="vessel"
                    vessel={vessel}
                    reload={reloadGrid}
                  />
                );
              }}
            />
          ),
        },
        {
          key: 'Delete vessel',
          isValid: !isArchive,
          label: (
            <MenuItem
              label="Delete vessel"
              onClick={() => {
                showModal(
                  <Delete
                    target={{
                      name: vessel.name || <TBN />,
                      id: vessel.id,
                    }}
                    onDelete={(id: number, callback: $TSFixMe, close: () => void) => {
                      deleteVessel(id)
                        .then(() => {
                          reloadGrid(1);
                          close();
                          const v = clipboardState.find(cs => cs.id === id);
                          if (v) {
                            onRemoveFromClipboard(vessel);
                            reloadGrid();
                          }
                        })
                        .catch((e: $TSFixMe) => {
                          const errors: $TSFixMe = [];
                          if (e.payload && e.payload.error && e.payload.error.errors) {
                            e.payload.error.errors.forEach((err: $TSFixMe) => {
                              const {type, id, name} = err.data;
                              const link = type === 'project' ? `/${type}s/${id}` : `/${type}s/${name}`;
                              errors.push({title: type, msg: name, link: link});
                            });
                            const bonusMsg =
                              'The vessel you want to delete is currently used in projects. Please delete the\nfollowing projects first.';
                            callback({errors, bonusMsg});
                          }
                        });
                    }}
                  />
                );
              }}
            />
          ),
        },
        {
          key: 'create fixture',
          label: (
            <MenuItem
              withDivider
              label={'Create fixture'}
              onClick={() => {
                dispatch(
                  ModalActions.show(
                    <ManageFixtureModal
                      vessel={vessel as unknown as VesselFromApi}
                      updateVessel
                      callback={() => {
                        reloadGrid();
                      }}
                    />
                  )
                );
              }}
            />
          ),
        },
        {
          key: 'remove',
          label: (
            <MenuItem
              label={'Remove fixture details'}
              onClick={() => {
                showModal(
                  <Confirmation
                    title={`Do you really want to remove fixture details?`}
                    confirmButtonLabel="Yes, Remove!"
                    danger
                    onConfirm={() => {
                      removeFixtureDetails(vessel.id).then(() => {
                        reloadGrid();
                      });
                    }}
                  />
                );
              }}
              disabled={!(vessel as $TSFixMe).usesFixtures}
              disabledHint={'Does not use fixture data'}
            />
          ),
        },
        {
          key: 'Set colour marker',
          isValid: !isArchive,
          label: (
            <MenuItem
              withDivider
              label="Set colour marker"
              onClick={() => {
                showModal(<PortfolioColorEdit type="vessel" data={vessel} reload={reloadGrid} />);
              }}
            />
          ),
        },
      ]}
    />
  );
};
