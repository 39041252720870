import {Button} from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px 15px;
`;

export const Headline = styled.h2`
  font-size: var(--font-size-lg);
  font-weight: bold;
  text-align: center;
  margin: 12px 0;
`;

export const BorderBox = styled.div<{$highlight?: boolean}>`
  margin-bottom: 10px;
  padding: 10px 15px 7px;
  border: var(--border-width) solid ${({$highlight}) => ($highlight ? 'var(--color-blue)' : 'var(--border-color)')};
  border-radius: var(--border-radius);
`;

export const PickerHeadline = styled.p`
  margin: 0 0 5px;
`;

export const PickerWrapper = styled.div`
  display: block;
  margin: 0 0 12px;
  height: 42px;
`;

export const FullWidthButton = styled(Button)`
  width: 100%;
`;

export const VerticalSeparator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  color: var(--color-gray-2);

  &::before,
  &::after {
    display: block;
    content: '';
    height: 126px;
    width: 1px;
    border-left: 1px solid var(--border-color);
    margin: 10px 0;
  }
`;
