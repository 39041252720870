import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetMarketVesselsRequest} from '../../redux/ApiService/MarketService';
import {ApiError} from '../../api/utils/ApiError';
import {TODO} from '../../utils/TODO';
import MarketService from '../../redux/ApiService/MarketService';
import {useDispatch} from '../../redux/react-redux';

const QUERY_KEY_PREFIX = 'market-vessels';

export const useMarketVesselsQuery = (
  request: GetMarketVesselsRequest,
  options?: Omit<UseQueryOptions<TODO, ApiError>, 'queryKey'>
) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, request],
    queryFn: () => dispatch(MarketService.getVessels(request)),
    ...options,
  });
};
