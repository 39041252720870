/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CurrentUserOptions,
  CurrentUserOptionsFromJSON,
  CurrentUserOptionsFromJSONTyped,
  CurrentUserOptionsToJSON,
  FeaturePermission,
  FeaturePermissionFromJSON,
  FeaturePermissionFromJSONTyped,
  FeaturePermissionToJSON,
  Reference,
  ReferenceFromJSON,
  ReferenceFromJSONTyped,
  ReferenceToJSON,
} from './';

/**
 *
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
  /**
   *
   * @type {number}
   * @memberof CurrentUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  fullName: string;
  /**
   *
   * @type {CurrentUserOptions}
   * @memberof CurrentUser
   */
  options?: CurrentUserOptions;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  avatar?: string | null;
  /**
   *
   * @type {Reference}
   * @memberof CurrentUser
   */
  company: Reference;
  /**
   *
   * @type {Reference}
   * @memberof CurrentUser
   */
  workspace: Reference;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  email: string;
  /**
   *
   * @type {Array<FeaturePermission>}
   * @memberof CurrentUser
   */
  permissions: Array<FeaturePermission>;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUser
   */
  isOnboarded?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  onboardingSkippedAt?: string | null;
}

export function CurrentUserFromJSON(json: any): CurrentUser {
  return CurrentUserFromJSONTyped(json, false);
}

export function CurrentUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    fullName: json['fullName'],
    options: !exists(json, 'options') ? undefined : CurrentUserOptionsFromJSON(json['options']),
    avatar: !exists(json, 'avatar') ? undefined : json['avatar'],
    company: ReferenceFromJSON(json['company']),
    workspace: ReferenceFromJSON(json['workspace']),
    email: json['email'],
    permissions: (json['permissions'] as Array<any>).map(FeaturePermissionFromJSON),
    isOnboarded: !exists(json, 'isOnboarded') ? undefined : json['isOnboarded'],
    onboardingSkippedAt: !exists(json, 'onboardingSkippedAt') ? undefined : json['onboardingSkippedAt'],
  };
}

export function CurrentUserToJSON(value?: CurrentUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    fullName: value.fullName,
    options: CurrentUserOptionsToJSON(value.options),
    avatar: value.avatar,
    company: ReferenceToJSON(value.company),
    workspace: ReferenceToJSON(value.workspace),
    email: value.email,
    permissions: (value.permissions as Array<any>).map(FeaturePermissionToJSON),
    isOnboarded: value.isOnboarded,
    onboardingSkippedAt: value.onboardingSkippedAt,
  };
}
