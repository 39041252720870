import {MergedVisits} from './useCongestionVisitsWithVesselInformationsQuery';
import * as echarts from 'echarts';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

// Dataformat for Scatterplot EChart
type ScatterPlotData = [typeIndex: number, value: number, occurences: number];
type ScatterSeriesData = [value: number, occurences: number][];

type Range = [min: number, max: number];
type LabeledVisit = MergedVisits & {label?: string};

const SCALE_MIN = 5;
const SCALE_MAX = 40;
// scales values from input range to [SCALE_MIN, SCALE_MAX]
const scaleValues = (value: number, [min, max]: Range) => {
  return value !== 0 ? ((value - min) * (SCALE_MAX - SCALE_MIN)) / (max - min) + SCALE_MIN : 0;
};

const getDurationValues = (visits: MergedVisits[]) => {
  const durationValues: number[] = [];
  for (const {visitDuration} of visits) {
    const duration = Math.round(visitDuration);
    if (!durationValues.includes(duration)) {
      durationValues.push(duration);
    }
  }
  return durationValues;
};

const getTypeDurationCounts = (visits: MergedVisits[], durationCounts: Record<number, number>) => {
  const typeDurationCounts = cloneDeep(durationCounts);
  visits.forEach(({visitDuration}) => {
    const duration = Math.round(visitDuration);
    typeDurationCounts[duration] += 1;
  });
  return typeDurationCounts;
};

const getScatterPlotSeries = (index: number, typeDurationCounts: Record<number, number>): ScatterPlotData[] => {
  const data: ScatterPlotData[] = [];
  for (const key of Object.keys(typeDurationCounts)) {
    const duration = parseInt(key);
    const durationCount = typeDurationCounts[duration];
    data.push([index, duration, durationCount]);
  }
  return data;
};

const getScatterPlotData = (
  perTypeGrouped: Record<string, LabeledVisit[]>,
  types: string[],
  durationCountMap: Record<number, number>
) => {
  let dataSet: ScatterPlotData[] = [];
  forEach(perTypeGrouped, (visits, type) => {
    const typeDurationCounts = getTypeDurationCounts(visits, durationCountMap);
    const series = getScatterPlotSeries(types.indexOf(type), typeDurationCounts);
    dataSet = dataSet.concat(series);
  });
  return dataSet;
};

const getDurationCountMap = (durationValuesSorted: number[]) => {
  const acc: Record<number, number> = {};
  forEach(durationValuesSorted, duration => {
    acc[duration] = 0;
  });
  return acc;
};

const getDurationCountRange = (data: ScatterPlotData[]): Range => {
  return [minBy(data, date => date[2])?.[2] ?? -Infinity, maxBy(data, date => date[2])?.[2] ?? Infinity];
};

const getEChartOptions = (
  types: string[],
  durationValueLabels: string[],
  durationCountRange: Range,
  getColor: (type: string) => string
) => {
  const title: echarts.TitleComponentOption[] = [];
  const singleAxis: echarts.SingleAxisComponentOption[] = [];
  const series: echarts.ScatterSeriesOption[] = [];
  types.forEach((type, idx) => {
    title.push({
      textBaseline: 'top',
      top: ((idx + 0.4) * 100) / types.length - 1 + '%',
      text: type,
    });
    singleAxis.push({
      left: 140,
      right: 1,
      top: (idx * 100) / types.length + 8 + '%',
      height: 100 / types.length - 16 + '%',
      type: 'category',
      data: durationValueLabels,
      axisLabel: {
        interval: 20,
      },
    });
    series.push({
      singleAxisIndex: idx,
      coordinateSystem: 'singleAxis',
      type: 'scatter',
      data: [],
      color: getColor(type),
      symbolSize: (dataItem: number[]) => scaleValues(Math.round(dataItem[1]), durationCountRange),
    });
  });
  return {
    title,
    singleAxis,
    series,
  };
};

type ScatterPlotOptions = {
  title: echarts.TitleComponentOption[];
  singleAxis: echarts.SingleAxisComponentOption[];
  series: echarts.ScatterSeriesOption[];
  data: ScatterPlotData[];
  perTypeGrouped: Record<string | number, MergedVisits[]>;
};

export const getDurationScatterPlotByProp = (
  visits: LabeledVisit[],
  propKey: keyof LabeledVisit,
  getColor: (visit: string) => string
): ScatterPlotOptions => {
  // get duration values and labels
  const durationValues: number[] = getDurationValues(visits);
  const durationValuesSorted = sortBy(durationValues);
  const durationValueLabels = durationValuesSorted.map(value => value + 'h');

  // cut visits into groups by given propKey
  const perTypeGrouped = groupBy(visits, vessel => vessel[propKey]);
  const types = Object.keys(perTypeGrouped);

  // sort types by amount of visits descending
  const sortedTypes = sortBy(types, type => -perTypeGrouped[type].length);

  // count occurrences of durations and get range of these counts
  const durationCountMap = getDurationCountMap(durationValuesSorted);
  const data: ScatterPlotData[] = getScatterPlotData(perTypeGrouped, sortedTypes, durationCountMap);
  const durationCountRange: Range = getDurationCountRange(data);

  // prepare EChart options
  const {title, singleAxis, series} = getEChartOptions(sortedTypes, durationValueLabels, durationCountRange, getColor);

  // fill prepared series with data
  data.forEach(dataItem => {
    (series[dataItem[0]]!.data as ScatterSeriesData).push([dataItem[1], dataItem[2]]);
  });

  return {
    title,
    singleAxis,
    series,
    data,
    perTypeGrouped,
  };
};
