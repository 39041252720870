/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VesselStatus {
  Active = 'active',
  Drydock = 'drydock',
  Employed = 'employed',
  Inlayup = 'inlayup',
  Inservice = 'inservice',
  Onorder = 'onorder',
  Scrapped = 'scrapped',
  Yard = 'yard',
}

export function VesselStatusFromJSON(json: any): VesselStatus {
  return VesselStatusFromJSONTyped(json, false);
}

export function VesselStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselStatus {
  return json as VesselStatus;
}

export function VesselStatusToJSON(value?: VesselStatus | null): any {
  return value as any;
}
