import dayjs, {Dayjs} from 'dayjs';
import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {dateEarlierThanOrEqualTo} from '../../../../../utils/validators';
import {Location} from '../../utils/Location';
import forEach from 'lodash/forEach';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';

export const Defaults: InternalType = {
  fixturedelivery: [],
  fixturedeliveryvia: [],
  fixtureredelivery: [],
  laycanFrom: null,
  laycanTo: null,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const internalType: InternalType = {
    fixturedelivery: getLocationsFromStations(database.stations, 'fixturedelivery'),
    fixturedeliveryvia: getLocationsFromStations(database.stations, 'fixturedeliveryvia'),
    fixtureredelivery: getLocationsFromStations(database.stations, 'fixtureredelivery'),
    laycanFrom: database.laycanFrom ? dayjs(database.laycanFrom) : null,
    laycanTo: database.laycanTo ? dayjs(database.laycanTo) : null,
  };

  return internalType;
};

export const toDatabase = ({
  laycanFrom,
  laycanTo,
  fixturedelivery,
  fixturedeliveryvia,
  fixtureredelivery,
}: InternalType): DatabaseType => {
  const database: DatabaseType = {
    stations: transformLocationFieldsToApi({fixturedelivery, fixturedeliveryvia, fixtureredelivery}),
  };
  if (laycanFrom) {
    database.laycanFrom = laycanFrom.format(DateTimeFormat.IsoDate);
  }

  if (laycanTo) {
    database.laycanTo = laycanTo.format(DateTimeFormat.IsoDate);
  }

  return database;
};

type Type = 'fixturedelivery' | 'fixturedeliveryvia' | 'fixtureredelivery';

export const getLocationsFromStations = (stations: {type: Type; locations: Location[]}[], type: Type) => {
  return stations.find((s: $TSFixMe) => s.type === type)?.locations ?? [];
};

export const transformLocationFieldsToApi = (values: Record<Type, Location[]>): Station[] => {
  const list: Station[] = [];
  forEach(values, (locations, key) => {
    const oneTypeStations = transformLocationFieldForApi(locations, key as Type);
    if (oneTypeStations.locations.length !== 0) {
      list.push(oneTypeStations);
    }
  });

  return list;
};
const transformLocationFieldForApi = (locations: Location[], type: Type): Station => ({
  type: type,
  locations: locations ?? [],
});

export type Station = {
  type: 'fixturedelivery' | 'fixturedeliveryvia' | 'fixtureredelivery';
  locations: Location[];
};

export interface DatabaseType {
  stations: Station[];
  laycanFrom?: string | null;
  laycanTo?: string | null;
}

export interface InternalType {
  fixturedelivery: Location[];
  fixturedeliveryvia: Location[];
  fixtureredelivery: Location[];
  laycanFrom?: Dayjs | null;
  laycanTo?: Dayjs | null;
}

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Location / Laycan',
  branch: 'fixtureStation',
  defaults: Defaults,

  fromDatabase,
  validators: {
    laycanFrom: dateEarlierThanOrEqualTo('laycanTo'),
  },
  toDatabase,
});
