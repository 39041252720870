/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CumulatedTiedUpContainerVessels
 */
export interface CumulatedTiedUpContainerVessels {
  /**
   *
   * @type {number}
   * @memberof CumulatedTiedUpContainerVessels
   */
  vessels: number;
  /**
   *
   * @type {number}
   * @memberof CumulatedTiedUpContainerVessels
   */
  teu: number;
}

export function CumulatedTiedUpContainerVesselsFromJSON(json: any): CumulatedTiedUpContainerVessels {
  return CumulatedTiedUpContainerVesselsFromJSONTyped(json, false);
}

export function CumulatedTiedUpContainerVesselsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CumulatedTiedUpContainerVessels {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vessels: json['vessels'],
    teu: json['teu'],
  };
}

export function CumulatedTiedUpContainerVesselsToJSON(value?: CumulatedTiedUpContainerVessels | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vessels: value.vessels,
    teu: value.teu,
  };
}
