import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as VesselTypeDefinition from '../VesselType/VesselTypeDefinition';
import * as GeneralDefinition from '../General/GeneralDefinition';
import * as SizeDefinition from '../Size/SizeDefinition';
import * as DimensionsDefinition from '../Dimensions/DimensionsDefinition';
import * as IntakeDefinition from '../Intake/IntakeDefinition';
import * as FeaturesDefinition from '../Features/FeaturesDefinition';
import * as DateOpenDefinition from '../DateOpen/DateOpenDefinition';
import * as PositionOpenDefinition from '../PositionOpen/PositionOpenDefinition';
import * as VesselStatusDefinition from '../VesselStatus/VesselStatusDefinition';
import * as OwnershipDefinition from '../Ownership/OwnershipDefinition';
import * as ArchiveDefinition from '../Archive/ArchiveDefinition';

export const PortfolioVesselFilterBranchDefinitions = makeFilterBranchDefinitions([
  VesselTypeDefinition.filterBranchDefinition,
  GeneralDefinition.filterBranchDefinition,
  SizeDefinition.filterBranchDefinition,
  DimensionsDefinition.filterBranchDefinition,
  IntakeDefinition.filterBranchDefinition,
  FeaturesDefinition.filterBranchDefinition,
  DateOpenDefinition.filterBranchDefinition,
  PositionOpenDefinition.filterBranchDefinition,
  VesselStatusDefinition.filterBranchDefinition,
  OwnershipDefinition.filterBranchDefinition,
  ArchiveDefinition.filterBranchDefinition,
] as const);

export type PortfolioVesselInternalFilter = Partial<FilterInternalType<typeof PortfolioVesselFilterBranchDefinitions>>;
export type PortfolioVesselDatabaseFilter = Partial<FilterDatabaseType<typeof PortfolioVesselFilterBranchDefinitions>>;
