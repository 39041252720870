import React, {FC, useState} from 'react';
import {Alert, Button, message, Table} from 'antd';
import {useQuery} from '@tanstack/react-query';
import {Actions as ProjectAction} from '../../../../redux/ApiService/ProjectService';
import {useDispatch} from 'react-redux';
import capitalize from 'lodash/capitalize';
import {useMarketVesselsFromGridClipboard} from '../utils/fromGridClipboard';
import {Cargo, CargoProject} from '../types';
import {getUpperBoundForFactorToReachMaximumCountOfCalculations} from '../utils/MultiCalcConfig';
import NoResults from '../../../../screens/market/EmptyState/NoResults';
import {useGetUserDateTime} from '../../../DateTime/useGetUserDateTime';
import {ModalFooter} from '../../../../antd/components/ModalFooter';

const queryKey = 'MultiCalcWizardCargoProjects';

const pageSize = 5;

export const ProjectCargoes: FC<{onSubmit: (params: {projectIds: number[]; vesselIds: number[]}) => void}> = ({
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>([]);

  const [pageIndex, setPageIndex] = useState(1);
  const projectsQuery = useQuery({
    queryKey: [queryKey, pageIndex],
    queryFn: async () => {
      const data = (await dispatch(ProjectAction.getAllProjects(pageIndex, pageSize, 'cargo'))).data;
      return {items: data.items as CargoProject[], totalItems: data.totalItems};
    },
  });

  const vesselsQuery = useMarketVesselsFromGridClipboard({});
  const maxCargoes = vesselsQuery.isLoading
    ? 0
    : getUpperBoundForFactorToReachMaximumCountOfCalculations(vesselsQuery.data!.length);

  const vesselIds = (vesselsQuery.data ?? []).map(vessel => vessel.id);
  const getUserDateTime = useGetUserDateTime();
  const loading = projectsQuery.isLoading || vesselsQuery.isLoading;
  return (
    <>
      {!loading && <Alert type={'info'} message={`Select ${maxCargoes} cargoes maximal.`} />}
      <Table
        data-testid="MultiCalcWizardProjectTable"
        loading={loading}
        locale={{emptyText: <NoResults type={'cargo projects'} secondLine={''} />}}
        columns={[
          {
            title: 'Project name',
            key: 'name',
            dataIndex: 'name',
          },
          {
            title: 'Charterer',
            key: 'charterer',
            dataIndex: ['cargo', 'chartererName'],
          },
          {
            title: 'Commodity',
            key: 'commodity',
            render: (_, project) => getCommoditySummarized(project.cargo) ?? 'N/A',
          },
          {
            title: 'From/Laydays',

            key: 'laycan_from',
            dataIndex: ['cargo', 'laycanFrom'],
            render: v => (v ? getUserDateTime({value: v, format: 'date'}) : 'N/A'),
          },
          {
            title: 'To/Cancelling',

            key: 'laycan_to',
            dataIndex: ['cargo', 'laycanTo'],
            render: v => (v ? getUserDateTime({value: v, format: 'date'}) : 'N/A'),
          },
        ]}
        rowKey={'id'}
        dataSource={projectsQuery.isSuccess ? projectsQuery.data.items : []}
        rowSelection={{
          selectedRowKeys: selectedProjectIds,
          // the projectIds are numbers always
          onChange: newRowKeys => {
            const existingProjectIds = selectedProjectIds.filter(projectId => {
              if (projectsQuery.data?.items.map(qp => qp.id).includes(projectId)) {
                return false;
              }
              return true;
            });

            // The projectsIds which aren't in selectedProjects at this time,
            // they would mapped to the projectsQuery item with the same id
            const addedProjectIds = newRowKeys
              .map(id => projectsQuery.data!.items.find(project => project.id === id)!)
              .map(project => project.id);

            const newProjectIds = [...existingProjectIds, ...addedProjectIds];
            if (newProjectIds.length > maxCargoes) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error(`Select ${maxCargoes} cargoes maximal.`);
              return;
            }
            setSelectedProjectIds(newProjectIds);
          },
        }}
        pagination={{
          pageSize,
          onChange: pageIndex => {
            setPageIndex(pageIndex);
          },
          total: projectsQuery.data?.totalItems ?? 0,
        }}
      />
      <ModalFooter style={{marginTop: 24}}>
        <Button
          data-testid="MultiCalcWizardSend"
          type={'primary'}
          disabled={selectedProjectIds.length === 0 || !vesselsQuery.isSuccess}
          onClick={() => {
            onSubmit({
              projectIds: selectedProjectIds,
              vesselIds: vesselIds,
            });
          }}>
          Calculate
        </Button>
      </ModalFooter>
    </>
  );
};

export const getCommoditySummarized = (cargo: Cargo) => {
  const category = getCommodityCategoryFormatted(cargo);
  const type = getCommodityTypeFormatted(cargo);

  if (!category) {
    return null;
  }

  if (type) {
    return `${category} (${type})`;
  }

  return category;
};

export const getCommodityCategoryFormatted = ({commodityCategory}: Cargo): string | null =>
  commodityCategory ? capitalize(commodityCategory) : null;

export const getCommodityTypeFormatted = ({commodityType}: Cargo) =>
  commodityType ? capitalize(commodityType).replace(/-/g, ' ') : null;
