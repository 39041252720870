import {MenuItem} from '../../../atoms/Menu/MenuItem';
import React, {FC} from 'react';
import {useOpenVesselForm} from '../../CargoVesselForm/utils/useOpenVesselForm';
import {TODO} from '../../../utils/TODO';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';

type Props = {
  vessel?: TODO;
  onProjectClick: () => void;
  onManageNextOpenLocationClick: () => void;
  onCloneClick: () => void;
  onDeleteClick: () => void;
  onCreateFixtureClick: () => void;
  onFixtureExportClick: () => void;
};

export const MyFleetVesselDetailsScreenActionMenu: FC<Props> = ({
  vessel,
  onCloneClick,
  onCreateFixtureClick,
  onDeleteClick,
  onFixtureExportClick,
  onProjectClick,
  onManageNextOpenLocationClick,
}) => {
  const openVesselForm = useOpenVesselForm();

  return (
    <ScreenHeader.Actions.Menu
      position={'right'}
      items={[
        {
          key: 'edit',
          'data-cy': 'EditVessel',
          label: (
            <MenuItem
              onClick={() => {
                openVesselForm({id: vessel.id});
              }}
              label={'Edit vessel'}
              withDivider
            />
          ),
        },
        {
          key: 'delete',
          isValid: !vessel.isArchived,
          'data-cy': 'DeleteVessel',
          label: <MenuItem onClick={onDeleteClick} label={'Delete vessel'} />,
        },
        {
          key: 'clone',
          isValid: !vessel.isArchived,
          'data-cy': 'CloneVessel',
          label: <MenuItem onClick={onCloneClick} label={'Clone vessel'} />,
        },
        {
          key: 'add-to-project',
          isValid: !vessel.isArchived,
          'data-cy': 'AddToProject',
          label: <MenuItem onClick={onProjectClick} label={'Add to project'} withDivider />,
        },
        {
          key: 'update-position',
          'data-cy': 'UpdatePosition',
          label: <MenuItem onClick={onManageNextOpenLocationClick} label={'Manage next open location'} withDivider />,
        },
        {
          key: 'create-fixture',
          'data-cy': 'CreateFixture',
          label: <MenuItem onClick={onCreateFixtureClick} label={'Create fixture'} />,
        },
        {
          key: 'export-fixture',
          isValid: vessel.fixtureCount && vessel.fixtureCount > 0,
          'data-cy': 'ExportFixtures',
          label: <MenuItem onClick={onFixtureExportClick} label={'Export fixtures'} />,
        },
      ]}
    />
  );
};
