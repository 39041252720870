import {
  NegotiationParty,
  NegotiationTerm,
  NegotiationTermStatus,
  NegotiationTermWasAddedIn,
  TermModification,
} from '../../../../../../api/node-backend/generated';

const {NewTerm, Unmodified} = TermModification;

const {Ongoing, Accepted, RejectionRequested, Rejected} = NegotiationTermStatus;

type TermBaseState = {
  isNew: boolean;
  isCountered: boolean;
  isModified: boolean;
  isModifiedByCurrentParty: boolean;
  isFixed: boolean;
};

type TermActionState = {
  canBeEdited: boolean;
  canBeAccepted: boolean;
  canBeRejectionRequested: boolean;
  canBeRejectRequestSettled: boolean;
  canBeDeleted: boolean;
  canBeReset: boolean;
};

export type DerivedNegotiationTermState = TermBaseState & TermActionState;

export const getDerivedTermState = ({
  party,
  roundCanBeEdited,
  term,
  termModification,
  isRecap,
  isFirstRecapRound,
}: {
  party: NegotiationParty;
  roundCanBeEdited: boolean;
  term: NegotiationTerm;
  termModification?: TermModification;
  isRecap?: boolean;
  isFirstRecapRound?: boolean;
}): DerivedNegotiationTermState => {
  const baseState = getTermBaseState({party, term, termModification, isRecap});
  const actionState = getTermActionState({
    party,
    roundCanBeEdited,
    term,
    termModification,
    isFirstRecapRound,
    baseState,
  });

  return {
    ...baseState,
    ...actionState,
  };
};

const getTermBaseState = ({
  party,
  term,
  termModification,
  isRecap,
}: {
  party: NegotiationParty;
  term: NegotiationTerm;
  termModification?: TermModification;
  isRecap?: boolean;
}): TermBaseState => {
  const {originId, wasAddedIn} = term;

  const isNew = originId === null;
  const isModified = ![Unmodified, NewTerm, undefined].includes(termModification);
  // lastModifiedBy only reflects who last changed term status
  const isModifiedByCurrentParty = term.lastModifiedBy === party;
  // lastEditedBy only reflects who last changed the term text
  const isCountered = term.lastEditedBy !== party;
  const isFixed = !!isRecap && wasAddedIn !== NegotiationTermWasAddedIn.Recap;

  return {
    isNew,
    isCountered,
    isModified,
    isModifiedByCurrentParty,
    isFixed,
  };
};

const getTermActionState = ({
  party,
  roundCanBeEdited,
  term,
  termModification,
  isFirstRecapRound,
  baseState,
}: {
  party: NegotiationParty;
  roundCanBeEdited: boolean;
  term: NegotiationTerm;
  termModification?: TermModification;
  isFirstRecapRound?: boolean;
  baseState: TermBaseState;
}): TermActionState => {
  const {status} = term;
  const {isNew, isCountered, isFixed, isModified, isModifiedByCurrentParty} = baseState;

  const canBeReset = isModified && isModifiedByCurrentParty;

  const noActionsAllowedState = {
    canBeEdited: false,
    canBeAccepted: false,
    canBeRejectionRequested: false,
    canBeRejectRequestSettled: false,
    canBeDeleted: false,
    canBeReset: false,
  };

  // If the round is not editable, no actions are allowed
  if (!roundCanBeEdited) {
    return noActionsAllowedState;
  }

  // Status "New" is not mapped to a term status, we need to handle it separately
  if (isNew) {
    return {
      ...noActionsAllowedState,
      canBeEdited: true,
      canBeDeleted: true,
    };
  }

  switch (status) {
    case Ongoing:
      return {
        ...noActionsAllowedState,
        canBeReset,
        canBeEdited: !canBeReset,
        canBeAccepted: !canBeReset && !isFirstRecapRound && isCountered,
        canBeRejectionRequested: !canBeReset && !isFirstRecapRound && isCountered && !isFixed,
      };
    case RejectionRequested:
      return {
        ...noActionsAllowedState,
        canBeReset,
        canBeEdited: !canBeReset,
        canBeRejectRequestSettled: !canBeReset && !isModifiedByCurrentParty,
      };
    case Accepted:
    case Rejected:
      return {
        ...noActionsAllowedState,
        canBeReset,
      };
    default:
      throw new UnhandledTermStateError(term, termModification, party, roundCanBeEdited);
  }
};

class UnhandledTermStateError extends Error {
  constructor(
    public term: NegotiationTerm,
    public termModification?: TermModification,
    public party?: NegotiationParty,
    public roundCanBeEdited?: boolean
  ) {
    super('Unhandled term state');
    this.name = 'UnhandledTermStateError';
    // eslint-disable-next-line no-console
    console.error({
      term,
      termModification,
      party,
      roundCanBeEdited,
    });
  }
}
