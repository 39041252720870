import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import {useMemo, FC} from 'react';
import {StatCard} from '../../sharedComponents/StatCard';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

const weekdays = ['Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat', 'Sun'];

export const ServedVisitsByWeekDay: FC<{visits: MergedPortCongestionVisit[]; title: string; color: string}> = ({
  visits,
  title,
  color,
}) => {
  const dataSource = useMemo(() => {
    const data: number[][] = [[], [], [], [], [], [], []];

    const perDateGrouped = groupBy(visits, vessel => dayjs(vessel.endTimestamp).format(DateTimeFormat.IsoDate));

    forEach(perDateGrouped, (visits: MergedPortCongestionVisit[], dateString) => {
      const dayOfWeek = dayjs(dateString).weekday();
      data[dayOfWeek].push(visits.length);
    });

    const labels = weekdays;

    return {data, labels};
  }, [visits]);

  return (
    <StatCard>
      <ReactECharts
        option={{
          title: [
            {
              left: 'center',
              text: title,
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: dataSource.labels,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              type: 'boxplot',
              data: dataSource.data,
              showBackground: false,
              itemStyle: {
                color,
                borderColor: 'rgba(0,0,0,0.5)',
              },
            },
          ],
        }}
      />
    </StatCard>
  );
};
