import produce from 'immer';
import {useContext} from 'react';
import {VoyageCharterContext} from '../../VoyageCharterContext';
import {InputState} from '../../../utils/voyageChartercalculation/voyageChartercalculation';
import {VoyageInput} from '../../../VoyageInformation/VoyageTypes';

type UpdateVoyageInputParams = {voyageInput: Partial<VoyageInput>};

export type UpdateVoyageInput = (params: UpdateVoyageInputParams) => void;

export const updateVoyageInput = ({
  setInputState,
  inputState,
  voyageInput,
}: UpdateVoyageInputParams & {
  inputState: InputState;
  setInputState: (inputState: InputState) => void;
}) => {
  setInputState(
    produce(inputState, draftState => {
      draftState.voyage = {...draftState.voyage, ...voyageInput};
      return draftState;
    })
  );
};

export const useUpdateVoyageInput = (): UpdateVoyageInput => {
  const {state, setInputState} = useContext(VoyageCharterContext);
  const {inputState} = state;
  return ({voyageInput}) =>
    updateVoyageInput({
      voyageInput,
      setInputState,
      inputState,
    });
};
