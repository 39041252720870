/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselOfferUpdateRequestVesselIntakes,
  VesselOfferUpdateRequestVesselIntakesFromJSON,
  VesselOfferUpdateRequestVesselIntakesFromJSONTyped,
  VesselOfferUpdateRequestVesselIntakesToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselOfferUpdateRequestVessel
 */
export interface VesselOfferUpdateRequestVessel {
  /**
   *
   * @type {string}
   * @memberof VesselOfferUpdateRequestVessel
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof VesselOfferUpdateRequestVessel
   */
  dwtSummer?: number;
  /**
   *
   * @type {number}
   * @memberof VesselOfferUpdateRequestVessel
   */
  lengthOverall?: number;
  /**
   *
   * @type {number}
   * @memberof VesselOfferUpdateRequestVessel
   */
  beam?: number;
  /**
   *
   * @type {number}
   * @memberof VesselOfferUpdateRequestVessel
   */
  draft?: number;
  /**
   *
   * @type {number}
   * @memberof VesselOfferUpdateRequestVessel
   */
  reeferPlugs?: number;
  /**
   *
   * @type {string}
   * @memberof VesselOfferUpdateRequestVessel
   */
  nextOpenFrom?: string;
  /**
   *
   * @type {string}
   * @memberof VesselOfferUpdateRequestVessel
   */
  nextOpenTo?: string;
  /**
   *
   * @type {Array<any>}
   * @memberof VesselOfferUpdateRequestVessel
   */
  nextOpen?: Array<any>;
  /**
   *
   * @type {number}
   * @memberof VesselOfferUpdateRequestVessel
   */
  imo?: number;
  /**
   *
   * @type {Array<VesselOfferUpdateRequestVesselIntakes>}
   * @memberof VesselOfferUpdateRequestVessel
   */
  intakes?: Array<VesselOfferUpdateRequestVesselIntakes>;
}

export function VesselOfferUpdateRequestVesselFromJSON(json: any): VesselOfferUpdateRequestVessel {
  return VesselOfferUpdateRequestVesselFromJSONTyped(json, false);
}

export function VesselOfferUpdateRequestVesselFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselOfferUpdateRequestVessel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    dwtSummer: !exists(json, 'dwtSummer') ? undefined : json['dwtSummer'],
    lengthOverall: !exists(json, 'lengthOverall') ? undefined : json['lengthOverall'],
    beam: !exists(json, 'beam') ? undefined : json['beam'],
    draft: !exists(json, 'draft') ? undefined : json['draft'],
    reeferPlugs: !exists(json, 'reeferPlugs') ? undefined : json['reeferPlugs'],
    nextOpenFrom: !exists(json, 'nextOpenFrom') ? undefined : json['nextOpenFrom'],
    nextOpenTo: !exists(json, 'nextOpenTo') ? undefined : json['nextOpenTo'],
    nextOpen: !exists(json, 'nextOpen') ? undefined : json['nextOpen'],
    imo: !exists(json, 'imo') ? undefined : json['imo'],
    intakes: !exists(json, 'intakes')
      ? undefined
      : (json['intakes'] as Array<any>).map(VesselOfferUpdateRequestVesselIntakesFromJSON),
  };
}

export function VesselOfferUpdateRequestVesselToJSON(value?: VesselOfferUpdateRequestVessel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    dwtSummer: value.dwtSummer,
    lengthOverall: value.lengthOverall,
    beam: value.beam,
    draft: value.draft,
    reeferPlugs: value.reeferPlugs,
    nextOpenFrom: value.nextOpenFrom,
    nextOpenTo: value.nextOpenTo,
    nextOpen: value.nextOpen,
    imo: value.imo,
    intakes:
      value.intakes === undefined
        ? undefined
        : (value.intakes as Array<any>).map(VesselOfferUpdateRequestVesselIntakesToJSON),
  };
}
