/* tslint:disable */
/* eslint-disable */
/**
 * PetridishService
 * PetridishService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Annotation
 */
export interface Annotation {
  /**
   *
   * @type {string}
   * @memberof Annotation
   */
  annotationId?: string;
  /**
   *
   * @type {number}
   * @memberof Annotation
   */
  userId?: number;
  /**
   *
   * @type {number}
   * @memberof Annotation
   */
  imo?: number;
  /**
   *
   * @type {string}
   * @memberof Annotation
   */
  text?: string;
  /**
   *
   * @type {Date}
   * @memberof Annotation
   */
  lastUpdated?: Date;
}

export function AnnotationFromJSON(json: any): Annotation {
  return AnnotationFromJSONTyped(json, false);
}

export function AnnotationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Annotation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    annotationId: !exists(json, 'annotationId') ? undefined : json['annotationId'],
    userId: !exists(json, 'userId') ? undefined : json['userId'],
    imo: !exists(json, 'imo') ? undefined : json['imo'],
    text: !exists(json, 'text') ? undefined : json['text'],
    lastUpdated: !exists(json, 'lastUpdated') ? undefined : new Date(json['lastUpdated']),
  };
}

export function AnnotationToJSON(value?: Annotation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    annotationId: value.annotationId,
    userId: value.userId,
    imo: value.imo,
    text: value.text,
    lastUpdated: value.lastUpdated === undefined ? undefined : value.lastUpdated.toISOString(),
  };
}
