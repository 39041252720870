/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Member, MemberFromJSON, MemberFromJSONTyped, MemberToJSON} from './';

/**
 *
 * @export
 * @interface UpdateCompanyMembersResponseData
 */
export interface UpdateCompanyMembersResponseData {
  /**
   *
   * @type {Array<Member>}
   * @memberof UpdateCompanyMembersResponseData
   */
  items: Array<Member>;
  /**
   *
   * @type {number}
   * @memberof UpdateCompanyMembersResponseData
   */
  totalItems: number;
}

export function UpdateCompanyMembersResponseDataFromJSON(json: any): UpdateCompanyMembersResponseData {
  return UpdateCompanyMembersResponseDataFromJSONTyped(json, false);
}

export function UpdateCompanyMembersResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCompanyMembersResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(MemberFromJSON),
    totalItems: json['totalItems'],
  };
}

export function UpdateCompanyMembersResponseDataToJSON(value?: UpdateCompanyMembersResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(MemberToJSON),
    totalItems: value.totalItems,
  };
}
