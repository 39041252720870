import {Button, Col, Form, FormInstance, Row} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {PortPickerWithFlag} from '../../../../components/PortPicker/PortPickerWithFlag';
import {Cargo} from '../../../../components/SearchPicker/Type';
import {VesselSizeSelect} from './VesselSizeSelect';
import {PotentialCompetitorsFormData} from '../types';
import {EnabledFilters} from '../FilterOptionDropdown';
import {InputNumber} from '../../../../antd/components/InputNumber';
import {DatePicker} from '../../../../antd/components/DatePicker';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

export const PotentialCompetitorsFormBody = (props: {
  canSubmit: boolean;
  form: FormInstance<PotentialCompetitorsFormData>;
  currentTime?: Dayjs;
  filterOptions: EnabledFilters;
  onChangeCargo?: (cargo: Cargo | undefined) => void;
}) => {
  return (
    <div data-testid={'SelectBar'}>
      <div style={{display: 'none'}}>
        <Form.Item name={'maxDwt'}>
          <InputNumber />
        </Form.Item>
        <Form.Item name={'minDwt'}>
          <InputNumber />
        </Form.Item>
      </div>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <Form.Item
            name={'port'}
            valuePropName={'port'}
            rules={[
              {
                required: true,
                message: 'Port is required.',
              },
            ]}
            label={'Port'}>
            <PortPickerWithFlag isTriggeredOnTextChange={false} placeholder={'Select port'} size={'small'} />
          </Form.Item>
        </Col>
        <Col md={12}>
          {props.filterOptions.size && (
            <Form.Item
              name={'vesselSize'}
              label={'Vessel size'}
              rules={[
                {
                  required: true,
                  message: 'Vessel size is required.',
                },
              ]}>
              <VesselSizeSelect
                onChange={item => {
                  props.form.setFieldsValue({
                    minDwt: item.dwtFrom,
                    maxDwt: item.dwtTo,
                  });
                }}
              />
            </Form.Item>
          )}
        </Col>
        <Col md={12}>
          <Form.Item
            name={'speed'}
            label={'Speed (in knots)'}
            rules={[
              {
                required: true,
                message: 'Speed is required.',
              },
              {
                validator: (_, v) => {
                  if (v === undefined || v === '') {
                    return Promise.resolve();
                  }
                  if (v <= 0) {
                    return Promise.reject('Speed must be greater than 0.');
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <InputNumber style={{width: '100%'}} />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            name={'fromDate'}
            label={'From'}
            rules={[
              {
                required: false,
              },
              {
                validator: (_, value: Dayjs | undefined) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (value.diff(props.currentTime ?? dayjs(), 'day') < 0) {
                    return Promise.reject('From-Date must be in the future.');
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value: Dayjs | undefined) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const toDate = props.form.getFieldValue('toDate');

                  if (toDate < value) {
                    return Promise.reject('From-Date must be before To-Date.');
                  }

                  return Promise.resolve();
                },
              },
              {
                validator: (_, value: Dayjs) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (value.diff(props.currentTime ?? dayjs(), 'day') > 21) {
                    return Promise.reject('From-Date must be no more than 21 days in the future.');
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <DatePicker data-testid={'fromDate'} format={DateTimeFormat.Date} style={{width: '100%'}} />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name={'toDate'}
            label={'To'}
            rules={[
              {
                required: true,
                message: 'To-Date is required.',
              },
              {
                validator: (_, value: Dayjs) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (value.diff(props.currentTime ?? dayjs(), 'day') < 0) {
                    return Promise.reject('To-Date must be in the future.');
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value: Dayjs) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (value.diff(props.currentTime ?? dayjs(), 'day') > 21) {
                    return Promise.reject('To-Date must be no more than 21 days in the future.');
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <DatePicker
              data-testid={'toDate'}
              onChange={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                props.form.validateFields(['fromDate']);
              }}
              format={DateTimeFormat.Date}
              style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={'end'}>
        <Col>
          <Button
            data-testid="submitButton"
            type={'primary'}
            size={'middle'}
            loading={!props.canSubmit}
            htmlType={'submit'}>
            Search
          </Button>
        </Col>
      </Row>
    </div>
  );
};
