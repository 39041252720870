import {useQuery} from '@tanstack/react-query';
import {negotiationNegotiationApi} from '../../../api/node-backend/nodeBackendApis';

export const FIND_ALL_NEGOTIATIONS_QUERY_KEY = ['negotiationNegotiationApi.findAllNegotiations'];

export const useFindAllNegotiationsQuery = () =>
  useQuery({
    queryKey: FIND_ALL_NEGOTIATIONS_QUERY_KEY,
    queryFn: async () => {
      const result = await negotiationNegotiationApi.findAllNegotiations();
      return result;
    },
  });
