import {Attribute} from '../../../atoms/Attribute/Attribute';
import Station, {StationObject} from '../../../model/Station';
import Vessel from '../../../model/Vessel';
import {useGetUserDateTime} from '../../DateTime/useGetUserDateTime';
import {PortTitleWithFlag} from '../../PortTitleWithFlag';

export const VesselSection = ({vessel}: $TSFixMe) => {
  const getUserDateTime = useGetUserDateTime();
  const {currentArea, currentPort, destinationPort, destinationArea, eta} = vessel.aisDetails ?? {};

  const currentLocation = currentArea ?? currentPort;
  const destinationLocation = destinationPort ?? destinationArea;
  const nextOpenLocation = vessel.stations.find((station: StationObject) => station.type === Station.NEXTOPEN)
    ?.locations[0];

  return (
    <>
      <Attribute tab="xxl" label={'Built year'} value={vessel.builtYear} />
      <Attribute tab="xxl" label={'IMO'} value={vessel.imo} />
      <Attribute tab="xxl" label={'Vessel type'} value={Vessel.formatVesselType(vessel)} />
      <Attribute tab="xxl" label={'Vessel size'} value={Vessel.getSizeValue(vessel)} />
      <br />
      <Attribute
        tab="xxl"
        label="Current location"
        value={currentLocation && <PortTitleWithFlag port={currentLocation} />}
      />
      <Attribute
        tab="xxl"
        label="Next port"
        value={destinationLocation && <PortTitleWithFlag port={destinationLocation} />}
      />
      <Attribute tab="xxl" label="ETA" value={eta && getUserDateTime({value: eta, format: 'dateTime'})} />
      <br />
      <Attribute
        tab="xxl"
        label="Next open"
        value={nextOpenLocation && <PortTitleWithFlag port={nextOpenLocation} />}
      />
      <Attribute tab="xxl" label="Date open" value={Vessel.formatDateOpen(vessel)} />
    </>
  );
};
