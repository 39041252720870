import {cargoAccessor} from '../../utils/cargoAccessorResult';
import CargoUtil from '../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import Cargo from '../../../../model/Cargo';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';

const sizeAccessor = (type: $TSFixMe): AccessorFn<CargoGridColumnsProps> => {
  const component = (d: Cargo) => CargoUtil.formatSizeQuantity(d);
  return cargoAccessor(component, type);
};

export const sizeColumn = ({
  type,
  minWidth = 4,
}: {
  type: $TSFixMe;
  minWidth?: number;
}): CustomColumnDef<CargoGridColumnsProps> => ({
  header: 'Size / quantity',
  id: 'sizeMin',
  enableSorting: true,
  className: 'uppercase',
  accessorFn: sizeAccessor(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
