import {CompositeLayer, ScatterplotLayer} from 'deck.gl';
import PortHexagonLayer from './PortHexagonLayer';
import {COLORS} from '../../../../components/SeaboMap/const';
import PortLabelLayer from './PortLabelLayer';

export class SingleMarketPortLayer extends CompositeLayer {
  renderLayers() {
    const {id, isShowMarketVessels, isShowMarketCargoes} = this.props;

    const visible = true;
    const layers = [];

    const getAmountCargoes = item => {
      return item.amountCargoes;
    };
    const getAmountVessels = item => {
      return item.amountVessels;
    };

    layers.push(
      new ScatterplotLayer({
        id: `${id}-filtered-point`,
        data: this.props.data,
        getFillColor: COLORS.PORT_POINT_FILL,
        getPosition: d => d.coordinates,
        opacity: 1,
        getRadius: 64,
        radiusScale: 32,
        radiusMinPixels: 1,
        radiusMaxPixels: 3,
        stroked: false,
        visible,
      })
    );

    if (isShowMarketVessels || isShowMarketCargoes) {
      layers.push(
        new PortLabelLayer({
          id: `${id}-`,
          data: this.props.data,
          fontFamily: 'Akkurat, monospace',
          getPixelOffset: [0, -30],
          getPosition: d => d.coordinates,
          getSize: 18,
          noTooltip: true,
          opacity: 1,
          pickable: true,
          visible,
          getAmountCargoes,
          getAmountVessels,
          marketVesselsOn: isShowMarketVessels,
          marketCargoesOn: isShowMarketCargoes,
          getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 10000],
        })
      );
    }

    if (isShowMarketCargoes) {
      layers.push(
        new PortHexagonLayer({
          id: `${id}-commodities`,
          data: this.props.data,
          getPosition: d => d.coordinates,
          getSize: 30,
          noTooltip: true,
          opacity: 1,
          pickable: true,
          visible: true,
          contractFilterOn: true,
          commodityFilterOn: true,
          cargoSubPortfolioFilterOn: true,
          getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 10000],
        })
      );
    }

    return layers;
  }
}
