import LabelWrapper from '../../../../atoms/LabelWrapper';
import {TextArea} from '../../../../atoms/TextArea/TextArea';
import {FC} from 'react';

export const Comment: FC<{
  comment: string;
  onChange: (value: string) => void;
}> = ({comment, onChange}) => {
  return (
    <LabelWrapper style={{width: '100%'}} contentWrapperStyle={{marginBottom: 0}} label={'Note/ comment'}>
      <TextArea
        id={'form-comment-comment'}
        onChange={e => {
          onChange(e.target.value);
        }}
        value={comment}
        noResize
        rows={8}
        large={false}
        limit={1000}
      />
    </LabelWrapper>
  );
};
