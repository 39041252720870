import {FC} from 'react';
import styled from 'styled-components';
import {UserDateTime} from '../DateTime/UserDateTime';
import {OfferMail} from './OfferMail';

export const OfferMailView: FC<{offerMail: OfferMail}> = ({offerMail}) => {
  return (
    <>
      <OfferMailHeader>
        <table>
          <tbody>
            <tr>
              <th>From:</th>
              <td>{offerMail.sender}</td>
            </tr>
            <tr>
              <th>Subject:</th>
              <td>{offerMail.subject}</td>
            </tr>
            <tr>
              <th>Posted&nbsp;at:</th>
              <td>
                <UserDateTime value={offerMail.date?.toString() ?? ''} format="weekdayDateTime" />
              </td>
            </tr>
          </tbody>
        </table>
      </OfferMailHeader>
      <OfferMailBody>
        <p>{offerMail.extractedText}</p>
      </OfferMailBody>
    </>
  );
};

const OfferMailBody = styled.div`
  max-height: 350px;
  max-width: 100%;
  padding: 20px 0;
  overflow-y: scroll;

  p {
    white-space: pre-wrap;
  }
`;

const OfferMailHeader = styled.div`
  padding-bottom: 20px;
  border-bottom: var(--border-base);

  td,
  th {
    text-align: left;
    padding-right: 10px;
    vertical-align: top;
  }
  th {
    font-weight: bold;
  }
`;
