/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  MailInfo,
  MailInfoFromJSON,
  MailInfoFromJSONTyped,
  MailInfoToJSON,
  ParsedVesselData,
  ParsedVesselDataFromJSON,
  ParsedVesselDataFromJSONTyped,
  ParsedVesselDataToJSON,
  RawVesselData,
  RawVesselDataFromJSON,
  RawVesselDataFromJSONTyped,
  RawVesselDataToJSON,
} from './';

/**
 *
 * @export
 * @interface JSONAllVesselData
 */
export interface JSONAllVesselData {
  /**
   *
   * @type {ParsedVesselData}
   * @memberof JSONAllVesselData
   */
  parsedVesselData?: ParsedVesselData;
  /**
   *
   * @type {RawVesselData}
   * @memberof JSONAllVesselData
   */
  rawVesselData?: RawVesselData;
  /**
   *
   * @type {string}
   * @memberof JSONAllVesselData
   */
  outputMask?: string;
  /**
   *
   * @type {string}
   * @memberof JSONAllVesselData
   */
  essence?: string;
  /**
   *
   * @type {number}
   * @memberof JSONAllVesselData
   */
  seconds?: number;
  /**
   *
   * @type {MailInfo}
   * @memberof JSONAllVesselData
   */
  mailInfo?: MailInfo;
}

export function JSONAllVesselDataFromJSON(json: any): JSONAllVesselData {
  return JSONAllVesselDataFromJSONTyped(json, false);
}

export function JSONAllVesselDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JSONAllVesselData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    parsedVesselData: !exists(json, 'parsedVesselData')
      ? undefined
      : ParsedVesselDataFromJSON(json['parsedVesselData']),
    rawVesselData: !exists(json, 'rawVesselData') ? undefined : RawVesselDataFromJSON(json['rawVesselData']),
    outputMask: !exists(json, 'outputMask') ? undefined : json['outputMask'],
    essence: !exists(json, 'essence') ? undefined : json['essence'],
    seconds: !exists(json, 'seconds') ? undefined : json['seconds'],
    mailInfo: !exists(json, 'mailInfo') ? undefined : MailInfoFromJSON(json['mailInfo']),
  };
}

export function JSONAllVesselDataToJSON(value?: JSONAllVesselData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parsedVesselData: ParsedVesselDataToJSON(value.parsedVesselData),
    rawVesselData: RawVesselDataToJSON(value.rawVesselData),
    outputMask: value.outputMask,
    essence: value.essence,
    seconds: value.seconds,
    mailInfo: MailInfoToJSON(value.mailInfo),
  };
}
