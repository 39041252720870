import {FC} from 'react';
import {Card, Table} from 'antd';
import numeral from 'numeral';
import capitalize from 'lodash/capitalize';
import {TODO} from '../../../utils/TODO';
import styled from 'styled-components';
import {ColumnsType} from 'antd/es/table';
import {Coordinates} from '../../../utils/Coordinates';
import {DraftItem} from '../../../utils/vessel/useDraftStatsQuery';
import {Icon} from '../../../atoms/Icon';
import {MapDetailsClose, MapDetailsSubtitle, MapDetailsTitle} from '../MapDetails/Components/shared-components';
import {useSelector} from '../../../redux/react-redux';
import {BallastStatus} from '../../BallastStatus/BallastStatus';
import {activeVesselElementSelector} from '../../../redux/MapDetails';
import {MapElement} from '../MapDetails/utils/types';

export interface SideContentRendererProps {
  items: MapSelectedVessel[];
  columns?: ColumnsType<MapSelectedVessel>;
  className?: string;
  onSelectElement: (element: MapElement) => void;
  onClose: () => void;
}

export const ListOfSelectedVessels: FC<SideContentRendererProps> = ({
  items,
  columns,
  className,
  onSelectElement,
  onClose,
}) => {
  const amountMpp = items.filter(item => item.type === 'mpp').length;
  const amountContainer = items.filter(item => item.type === 'container').length;
  const amountBulker = items.filter(item => item.type === 'bulker').length;

  const activeMapVessel = useSelector(activeVesselElementSelector);
  const getIsActiveMapVesselByIMO = (imo: number) => (activeMapVessel?.object.imo === imo ? 'active' : '');

  return (
    <SelectedVesselsContainer
      className={className}
      extra={
        <MapDetailsClose onClick={onClose}>
          <Icon type={'clear'} />
        </MapDetailsClose>
      }
      title={
        <>
          <MapDetailsTitle>Selected vessels{items?.length ? ` (${items?.length})` : ''}</MapDetailsTitle>
          <MapDetailsSubtitle>
            {amountContainer} Container | {amountBulker} Bulker | {amountMpp} MPPs
          </MapDetailsSubtitle>
        </>
      }>
      <SelectedVesselsTable
        pagination={false}
        columns={columns ?? defaultColumns}
        rowClassName={({imo}) => getIsActiveMapVesselByIMO(imo)}
        onRow={(record: TODO) => ({
          onClick: () =>
            onSelectElement({...record, coordinate: record.object.coordinates, lngLat: record.object.coordinates}),
        })}
        dataSource={items}
      />
    </SelectedVesselsContainer>
  );
};

export interface MapSelectedVessel {
  imo: number;
  name: string;
  type: string;

  // Capacity in DWT, not meaningful for container vessels.
  capacity: number;

  d?: number;

  // Exists only for container vessels.
  teu?: number;
  loa: number | undefined;
  beam: number | undefined;
  destinationPort?: {
    name: string;
    countryObject: {
      code: string;
    };
  };
  destination?: string;
  object: {
    coordinates: Coordinates;
  };
  draftItem?: DraftItem;
  isBallast?: boolean;
}

export const defaultColumns: ColumnsType<MapSelectedVessel> = [
  {
    title: '',
    key: 'isBallast',
    dataIndex: 'isBallast',
    sorter: (a: MapSelectedVessel, b: MapSelectedVessel) => {
      const getValue = ({isBallast, type}: {isBallast: boolean | undefined; type: string}) => {
        if (type !== 'bulker' || isBallast === undefined) {
          return 20;
        }
        if (isBallast) {
          return 10;
        }
        return 0;
      };
      return getValue({isBallast: a.isBallast, type: a.type}) - getValue({isBallast: b.isBallast, type: b.type});
    },
    render: (_: unknown, item: MapSelectedVessel) => (
      <BallastStatus isBallast={item.type !== 'bulker' ? undefined : item.isBallast} />
    ),
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    render: (name: string) => <b>{name}</b>,
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    render: (type: MapSelectedVessel['type']) => {
      if (type === 'bulker') {
        return 'Bulker';
      }
      if (type === 'mpp') {
        return 'MPP';
      }

      return capitalize(type);
    },
  },
  {
    title: 'LOA',
    key: 'loa',
    sorter: (a: MapSelectedVessel, b: MapSelectedVessel) => (a.loa ?? 0) - (b.loa ?? 0),
    dataIndex: 'loa',
    render: (v: MapSelectedVessel) => numeral(v).format('0,0.[0000]'),

    align: 'right',
  },
  {
    title: 'Beam',
    key: 'beam',
    sorter: (a: MapSelectedVessel, b: MapSelectedVessel) => (a.beam ?? 0) - (b.beam ?? 0),
    dataIndex: 'beam',
    render: (v: MapSelectedVessel) => numeral(v).format('0,0.[0000]'),

    align: 'right',
  },
  {
    title: 'DWT / TEU',
    key: 'dwt_teu',
    sorter: (a: MapSelectedVessel, b: MapSelectedVessel) => a.capacity - b.capacity,
    dataIndex: 'dwt_teu',
    align: 'right',
    render: (_: unknown, item: MapSelectedVessel) => {
      if (item.type === 'container' || item.type === 'mpp') {
        return `${numeral(item.teu).format('0,0.[0000]')} TEU`;
      }
      return `${numeral(item.capacity).format('0,0.[0000]')} DWT`;
    },
  },
];

const SelectedVesselsTable = styled(Table)`
  .ant-table-thead {
    position: sticky;
    top: 0;
    background: var(--map-subheader-color);
    box-shadow: var(--map-subheader-shadow);
    z-index: var(--map-subheader-z);

    & > tr > th {
      position: relative;
      padding: 10px 20px 9.5px;
      background: var(--color-gray-6);
      color: var(--color-gray-1);
      font-weight: bold;
      text-align: left;
      border-bottom: 1px solid var(--border-color-light);
      border-radius: 0 !important;
    }
  }
  .ant-table-tbody {
    & > tr.ant-table-row:hover > td {
      background: var(--map-list-item-hover-color);
    }
  }
  tbody > tr {
    cursor: pointer;

    &.active {
      background: var(--map-list-item-active-color);
    }
  }
` as typeof Table;

const SelectedVesselsContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;

  .ant-card-head {
    height: 72px;
    border-bottom: 1px solid var(--border-color-light);
    box-shadow: var(--map-header-shadow);
    z-index: var(--map-header-z);

    .ant-card-head-wrapper {
      height: 100%;
    }
  }
  .ant-card-body {
    flex: 1;
    padding: 0;
    overflow-y: scroll;
  }
`;
