import {Link} from 'react-router-dom';
import {UserProfile} from '../../api/symfony/generated';
import {useGetCompanyQuery} from '../../queries/useCompanyQuery';

export const MemberAtCompany = ({user}: {user: UserProfile}) => {
  const companyId = user.company?.id;

  const getCompanyQuery = useGetCompanyQuery(companyId!, {
    enabled: !!companyId,
  });

  if (!user.company) {
    return null;
  }

  if (!getCompanyQuery.isSuccess) {
    return null;
  }

  return (
    <p className="user-profile__company">
      <span style={{textTransform: 'capitalize'}}>Member</span> at{' '}
      <Link to={`/company/${user.company.id}`}>{user.company.name}</Link>
    </p>
  );
};
