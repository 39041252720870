/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ProjectVessel
 */
export interface ProjectVessel {
  /**
   *
   * @type {number}
   * @memberof ProjectVessel
   */
  id: number;
  /**
   *
   * @type {object}
   * @memberof ProjectVessel
   */
  project: object;
  /**
   *
   * @type {object}
   * @memberof ProjectVessel
   */
  vessel: object;
  /**
   *
   * @type {number}
   * @memberof ProjectVessel
   */
  rating: number | null;
  /**
   *
   * @type {string}
   * @memberof ProjectVessel
   */
  color: string | null;
  /**
   *
   * @type {Date}
   * @memberof ProjectVessel
   */
  updatedAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ProjectVessel
   */
  createdAt: Date;
}

export function ProjectVesselFromJSON(json: any): ProjectVessel {
  return ProjectVesselFromJSONTyped(json, false);
}

export function ProjectVesselFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectVessel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    project: json['project'],
    vessel: json['vessel'],
    rating: json['rating'],
    color: json['color'],
    updatedAt: new Date(json['updatedAt']),
    createdAt: new Date(json['createdAt']),
  };
}

export function ProjectVesselToJSON(value?: ProjectVessel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    project: value.project,
    vessel: value.vessel,
    rating: value.rating,
    color: value.color,
    updatedAt: value.updatedAt.toISOString(),
    createdAt: value.createdAt.toISOString(),
  };
}
