import {VesselHistoryRoutePoint} from '../../../../queries/useVesselHistoryQuery';

export const findNearestPointAtTimestamp = ({
  route,
  timestamp,
}: {
  route: VesselHistoryRoutePoint[];
  timestamp: number;
}) => {
  let lastDiff = Infinity;

  for (let index = 0; index < route.length; index++) {
    const item = route[index];
    const currentDiff = Math.abs(item.timestamp - timestamp);

    if (currentDiff > lastDiff) {
      return route[index - 1];
    }
    lastDiff = currentDiff;
  }
  return route[route.length - 1];
};
