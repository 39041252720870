import {FC} from 'react';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const PromoBar: FC<{promoBarVisible: boolean; onPromoBarDismissed: () => void}> = ({
  promoBarVisible,
  onPromoBarDismissed,
}) => {
  if (!promoBarVisible) {
    return null;
  }
  return (
    <PromoBarContainer>
      <Link to="/subscription">
        <InfoIcon />
        You are currently using the free plan: Unlock this tool's full potential now, with seabo Pro.
      </Link>
      <button onClick={onPromoBarDismissed}>Dismiss</button>
    </PromoBarContainer>
  );
};

const PromoBarContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: var(--promo-height);
  width: 100%;
  padding: 0 20px;
  font-size: var(--font-size-sm);
  border-bottom: 2px solid var(--color-upgrade);
  cursor: default;
  user-select: none;

  a {
    color: inherit !important;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    height: 20px;
    padding: 0 5px;
    margin-left: 10px;
    background-color: inherit;
    color: inherit;
    border: var(--border-width) solid rgba(0, 0, 0, 0.5);
    border-radius: var(--border-radius-button);
    box-shadow: var(--box-shadow-button);
    cursor: pointer;
  }

  @media (prefers-color-scheme: light) {
    background: var(--color-white);
    color: var(--color-gray-2);

    button {
      background: rgba(0, 0, 0, 0.1);
      border: var(--border-width) solid rgba(0, 0, 0, 0.5);
    }
  }
  @media (prefers-color-scheme: dark) {
    background: var(--color-gray-1);
    color: var(--color-gray-6);

    button {
      background: rgba(255, 255, 255, 0.1);
      border: var(--border-width) solid rgba(255, 255, 255, 0.5);
    }
  }
`;

const InfoIcon = styled(InfoCircleOutlined)`
  color: var(--color-gray-3);
  opacity: 0.6;
  margin-right: 5px;
`;
