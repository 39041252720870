import React, {FC} from 'react';

import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useSelector} from '../../redux/react-redux';
import {FeaturePermission} from '../../api/symfony/generated';
import {useIsPermitted} from '../../utils/useIsPermitted';

interface Props {
  title: string;
  subTitle?: string;
  userId?: number | string;
}

export const UserColumn: FC<Props> = ({title, subTitle, userId}) => {
  const companyUsers = useSelector(state => state.company.data.user);
  const isManageCompaniesPermitted = useIsPermitted(FeaturePermission.ManageCompanies);

  const userIsInCompany = companyUsers?.find(user => user.id === userId);

  const userIdToLink = isManageCompaniesPermitted ? userId : userIsInCompany?.id;

  return (
    <StyledLink data-cy="UserColumn" to={userIdToLink ? '/user/' + userIdToLink : ''}>
      <Title data-cy="Title">{title}</Title>
      {subTitle && <SubTitle data-cy="SubTitle">{subTitle}</SubTitle>}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Title = styled.span<{$grey?: boolean}>`
  font-size: var(--font-size-md);
  display: block;
  width: 100%;
`;

const SubTitle = styled.span`
  color: var(--color-gray-2);
  font-size: var(--font-size-sm);
  display: block;
  width: 100%;
`;
