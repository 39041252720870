import {useContext, FC} from 'react';
import {SaveFilled} from '@ant-design/icons';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {VoyageCalculationButton} from '../utils/VoyageCalculationButton';
import {useSaveVoyageCalculation} from '../utils/useSaveVoyageCalculation';

export const SaveChangesButton: FC<{id: number}> = ({id}) => {
  const voyageContext = useContext(VoyageCharterContext);
  const {saveExistingVoyageCalculation} = useSaveVoyageCalculation({});

  return (
    <>
      <VoyageCalculationButton
        data-cy="saveChangesButton"
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          saveExistingVoyageCalculation({id});
        }}
        type={'primary'}
        disabled={voyageContext.state.metaState.savedLastChanges}
        icon={<SaveFilled />}>
        Save
      </VoyageCalculationButton>
    </>
  );
};
