import {FC} from 'react';
import {TextAreaKeyControlled} from '../../../../../../../antd/components/TextAreaKeyControlled';

type TermEditorTextAreaProps = {
  focusOnMount?: boolean;
  value: string;
  onSave: () => void;
  onChange: (value: string) => void;
  onCancel: () => void;
};

export const TermEditorTextArea: FC<TermEditorTextAreaProps> = ({focusOnMount, value, onSave, onChange, onCancel}) => {
  return (
    <TextAreaKeyControlled
      data-testid="TermEditorTextArea"
      className="TermEditorTextArea"
      placeholder={'Type your reply here or leave empty to cancel.'}
      autoSize={{minRows: 1, maxRows: 10}}
      focusOnMount={focusOnMount}
      value={value}
      onSave={onSave}
      onCancel={onCancel}
      onChange={event => onChange(event.target.value)}
      // dont you styled components here to prevent jest warning "NaN is an invalid value for the height css style property."
      style={{
        width: '100%',
        maxWidth: 'unset !important',
        height: '100%',
        margin: 0,
        padding: '4px 100px 4px 11px',
        background: 'transparent',
        lineHeight: 1.125,
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
        zIndex: 0,
        cursor: 'text',
        transition: 'none',
      }}
    />
  );
};
