/**
 * count of calculations is the product of
 * factor: vessels
 * factor: cargoes
 */
export const maximumCountOfCalculations = 300;

export const getUpperBoundForFactorToReachMaximumCountOfCalculations = (factor: number) => {
  return Math.floor(maximumCountOfCalculations / factor);
};
