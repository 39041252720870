import {
  NegotiationParty,
  NegotiationTerm,
  NegotiationTermStatus,
  NegotiationTermWasAddedIn,
} from '../../../../../../api/node-backend/generated';

export const createMockTerm = (overrides?: Partial<NegotiationTerm>): NegotiationTerm => ({
  originId: 'mock-term-origin-id',
  id: 'mock-term-id',
  text: 'mock-term-text',
  sortIndex: 1,
  subjects: [],
  status: NegotiationTermStatus.Ongoing,
  lastEditedBy: NegotiationParty.Creator,
  wasAddedIn: NegotiationTermWasAddedIn.Recap,
  type: null,
  lastModifiedBy: NegotiationParty.Creator,
  lastModifiedAt: new Date(),
  ...overrides,
});
