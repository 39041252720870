import {TODO} from '../../../utils/TODO';
import {Station} from './useGetCargoRouteLayer';

const reduceStationToLocations = (stations: TODO[]) => {
  const locations = [];
  for (const station of stations) {
    for (const location of station.locations || []) {
      locations.push(location);
    }
  }
  return locations;
};

export const getStationLocationsFromCargo = ({stations}: {stations: Station[]}) => {
  const fromStations = stations.filter(
    (station: Station) => station.type === 'delivery' || (station.type === 'loading' && station.locations?.length)
  );
  const viaStations = stations.filter(
    (station: Station) => station.type === 'deliveryvia' && station.locations?.length
  );
  const toStations = stations.filter(
    (station: Station) => station.type === 'redelivery' || (station.type === 'discharge' && station.locations?.length)
  );

  return {
    fromLocations: reduceStationToLocations(fromStations),
    viaLocations: reduceStationToLocations(viaStations),
    toLocations: reduceStationToLocations(toStations),
  };
};
