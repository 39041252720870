import {NegotiationSummary} from '../../../../api/node-backend/generated';

export const PLACEHOLDER = '__________';
export const negotiationPlaceholderRegExp = /\{([a-zA-Z.]*?)\}/g;

export const replaceNegotiationPlaceholdersWithValues = (templateText: string, summary: NegotiationSummary): string => {
  const replacer = getNegotiationPlaceholderValueReplacer(summary);
  return templateText.replace(negotiationPlaceholderRegExp, replacer);
};

const getNegotiationPlaceholderValueReplacer =
  (negotiation: NegotiationSummary) =>
  (_: string, key: string): string => {
    if (key === 'PLACEHOLDER') {
      return PLACEHOLDER;
    }
    if (key.includes('.')) {
      return getNestedNegotiationPlaceholderValue(key, negotiation);
    }
    return getValueOrPlaceholder(negotiation[key as keyof NegotiationSummary]);
  };

const getNestedNegotiationPlaceholderValue = (key: string, negotiation: NegotiationSummary): string => {
  const keys = key.split('.') as (keyof NegotiationSummary)[];

  // any is needed here to traverse the object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let value: any = negotiation;
  try {
    for (const key of keys) {
      value = value[key];
    }
    return getValueOrPlaceholder(value);
  } catch (error) {
    return PLACEHOLDER;
  }
};

const getValueOrPlaceholder = (value: unknown): string => {
  return typeof value === 'string' || typeof value === 'number' ? value.toString() : PLACEHOLDER;
};
