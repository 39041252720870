import PulsatingCirclesLayer from './PulsatingCirclesLayer';
import {LonLatCoordinates, transformObjectFromLonLat} from '../../../../utils/Coordinates';
import {LAYERS} from '../../const';

type PulsatingSelectElementProps = {
  selectedElement: {
    object: {
      coordinates: LonLatCoordinates;
    };
    layer: {id: string};
  };
};

export const PulsatingSelectElement = (props: PulsatingSelectElementProps) => {
  if (
    props.selectedElement === null ||
    props.selectedElement.layer.id === LAYERS.PORTLESS ||
    props.selectedElement.layer.id === LAYERS.TRADING_AREA
  ) {
    return <></>;
  }
  return (
    <PulsatingCirclesLayer
      points={[transformObjectFromLonLat(props.selectedElement.object.coordinates)]}
      color={'#ffc752'}
    />
  );
};
