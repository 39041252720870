import dayjs, {Dayjs} from 'dayjs';
import {FC} from 'react';
import {DatePicker, Input, Space} from 'antd';
import {EditorDescription, ButtonBox} from './NegotiationReplyTimeEditor';
import {DateTimeFormat} from '../../../../../../utils/DateTimeFormat';

type ReplyTimeEditorTimestampProps = {
  timestamp: Dayjs;
  hasError: boolean;
  onTimestampChange: (timestamp: Dayjs) => void;
};

export const ReplyTimeEditorTimestamp: FC<ReplyTimeEditorTimestampProps> = ({
  timestamp,
  hasError,
  onTimestampChange,
}) => {
  const dateFromProp = timestamp;
  const hourFromProp = timestamp.hour() ?? 0;
  const minuteFromProp = timestamp.minute() ?? 0;

  const onChange = ({
    date = dateFromProp,
    hour = hourFromProp,
    minute = minuteFromProp,
  }: {
    date?: Dayjs | null;
    hour?: number;
    minute?: number;
  }) => {
    const timestamp = dayjs(date).hour(hour).minute(minute).second(0);
    onTimestampChange(timestamp);
  };

  return (
    <div data-testid="ReplyTimeEditorTimestamp">
      <EditorDescription>Target date and time (24h)</EditorDescription>
      <ButtonBox>
        <DatePicker
          size="small"
          format={DateTimeFormat.Date}
          value={dateFromProp}
          onChange={event => onChange({date: event})}
          data-testid="ReplyTimeEditorDate"
          style={{width: '50%'}}
          popupStyle={{width: 'auto'}}
          disabledDate={date => {
            return date.isBefore(dayjs().startOf('day'));
          }}
          getPopupContainer={trigger => {
            return trigger.parentElement!;
          }}
        />
        <Space.Compact size="small" style={{width: '50%'}}>
          <Input
            size="small"
            type="number"
            min={0}
            max={23}
            status={hasError ? 'error' : undefined}
            value={hourFromProp.toString().padStart(2, '0')}
            onChange={event => onChange({hour: parseInt(event.target.value)})}
            data-testid="ReplyTimeEditorHour"
          />
          <Input
            size="small"
            type="number"
            min={0}
            max={59}
            status={hasError ? 'error' : undefined}
            value={minuteFromProp.toString().padStart(2, '0')}
            onChange={event => onChange({minute: parseInt(event.target.value)})}
            data-testid="ReplyTimeEditorMinute"
          />
        </Space.Compact>
      </ButtonBox>
      {hasError && <div style={{color: 'red'}}>Timestamp must be in the future</div>}
    </div>
  );
};
