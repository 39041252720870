import {useQuery} from '@tanstack/react-query';
import {queryClient} from '../api/utils/reactQueryClient';
import {mapApi} from '../api/symfony/symfonyApi';
import {AisVesselDetailsApiModel} from '../api/symfony/generated';

const queryKey = 'mapApiGetAppApiMapGetvesselaisdetails';

export const useGetAppApiMapGetvesselaisdetailsQuery = ({imo}: {imo: string}) => {
  return useQuery({
    queryKey: [queryKey, imo],
    queryFn: async () => await mapApi.getAppApiMapGetvesselaisdetails({imo}),
  });
};

export const fetchGetAppApiMapGetvesselaisdetails = async ({imo}: {imo: string}): Promise<AisVesselDetailsApiModel> => {
  return await queryClient.fetchQuery({
    queryKey: [queryKey, imo],
    queryFn: async () => await mapApi.getAppApiMapGetvesselaisdetails({imo}),
  });
};
