import {FC} from 'react';

export const PercentagePointsDifference: FC<{difference: number; className?: string}> = props => {
  let sign = '';
  let color = '';
  if (props.difference < 0) {
    sign = '-';
    color = 'red';
  } else if (props.difference > 0) {
    sign = '+';
    color = 'green';
  }

  const value = Math.abs(Math.round(props.difference * 10) / 10);
  if (value === 0) {
    return <div style={{width: 44, display: 'inline-block'}} />;
  }
  return (
    <div style={{color, width: 44, display: 'inline-block'}} className={props.className}>
      {sign}
      {value}%
    </div>
  );
};
