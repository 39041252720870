import React, {FC} from 'react';
import {
  NegotiationRoundResult,
  NegotiationRoundResultModification,
  NegotiationSummary,
  NegotiationTemplate,
  NegotiationTemplateSummary,
} from '../../../../../api/node-backend/generated';
import {TermAreaHeader} from './TermAreaHeader';
import {LoadingBanner, NegotiationCard} from '../../../Components/shared';
import {NegotiationTermAreaSidebarLeft} from './Sidebar/NegotiationTermAreaSidebarLeft';
import {NegotiationTermAreaSidebarRight} from './Sidebar/NegotiationTermAreaSidebarRight';
import styled, {css} from 'styled-components';
import {CreateArea} from './CreateArea';
import {GlobalSubjects} from './GlobalSubjects/GlobalSubjects';
import {Terms} from './Terms/Terms';
import {TermActionsHook} from '../TermItem/utils/useGetTermActionsWithMutations';
import {SubjectActionsHook} from '../Subjects/utils/useGetSubjectActionsWithMutations';
import {SidebarActionsHook} from './Sidebar/useGetSidebarActionsWithMutations';
import {NegotiationActions} from '../CommitArea/utils/useGetNegotiationActionsWithMutations';
import {TemplateActionsHook} from '../../../NegotiationTemplates/utils/useTemplateActions';

type NegotiationTermAreaProps = {
  negotiation: NegotiationSummary;
  currentRoundResult?: NegotiationRoundResult;
  currentRoundResultModifications?: NegotiationRoundResultModification;
  templateSummaries: NegotiationTemplateSummary[];
  isGuest?: boolean;
  negotiationActions: NegotiationActions;
  actionsBusy?: boolean;
  isFirstRecapRound?: boolean;
  useGetTemplateDetail: (id?: number) => {data?: NegotiationTemplate};
  getTermActions: TermActionsHook;
  getSubjectActions: SubjectActionsHook;
  getSidebarActions: SidebarActionsHook;
  getTemplateActions: TemplateActionsHook;
};

export const NegotiationTermArea: FC<NegotiationTermAreaProps> = ({
  negotiation,
  currentRoundResult,
  currentRoundResultModifications,
  templateSummaries,
  isGuest,
  negotiationActions,
  actionsBusy,
  isFirstRecapRound,
  useGetTemplateDetail,
  getTermActions,
  getSubjectActions,
  getSidebarActions,
  getTemplateActions,
}) => {
  if (!currentRoundResult || !currentRoundResultModifications) {
    return (
      <NegotiationTermAreaCard data-testid="NegotiationTermArea">
        <NegotiationTermAreaSidebarLeft />
        <TermAreaHeader negotiation={negotiation} isFirstRecapRound={isFirstRecapRound} />
        <LoadingBanner data-testid="NegotiationTermListLoading" />
      </NegotiationTermAreaCard>
    );
  }

  return (
    <NegotiationTermAreaCard data-testid="NegotiationTermArea" $highlight>
      <NegotiationTermAreaSidebarLeft />
      <NegotiationTermAreaSidebarRight
        negotiation={negotiation}
        roundResult={currentRoundResult}
        roundResultModifications={currentRoundResultModifications}
        isFirstRecapRound={isFirstRecapRound}
        actionsBusy={actionsBusy}
        getSidebarActions={getSidebarActions}
      />
      <TermAreaHeader negotiation={negotiation} isFirstRecapRound={isFirstRecapRound} />
      <Terms
        negotiation={negotiation}
        roundResult={currentRoundResult}
        roundResultModifications={currentRoundResultModifications}
        templateSummaries={templateSummaries}
        negotiationActions={negotiationActions}
        isFirstRecapRound={isFirstRecapRound}
        actionsBusy={actionsBusy}
        useGetTemplateDetail={useGetTemplateDetail}
        getTermActions={getTermActions}
        getSubjectActions={getSubjectActions}
      />
      <GlobalSubjects
        negotiation={negotiation}
        roundResult={currentRoundResult}
        roundResultModifications={currentRoundResultModifications}
        actionsBusy={actionsBusy}
        getSubjectActions={getSubjectActions}
      />
      <CreateArea
        negotiation={negotiation}
        roundResult={currentRoundResult}
        isGuest={isGuest}
        negotiationActions={negotiationActions}
        actionsBusy={actionsBusy}
        templateActions={getTemplateActions()}
      />
    </NegotiationTermAreaCard>
  );
};

export const NegotiationTermAreaCard = styled(NegotiationCard)<{$highlight?: boolean}>`
  width: var(--main-width);
  margin: 0 auto 10px;
  overflow: initial;

  ${({$highlight}) =>
    $highlight &&
    css`
      animation: pulse 0.5s 1;
    `}

  .ant-card-body {
    padding: 20px;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
`;
