import {File} from './File';

/**
 * Lets the browser download the given file as if it came from the internet.
 */
export const startBrowserDownload = async (file: File) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([file.blob]));
  link.setAttribute('download', file.filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode!.removeChild(link);
};
