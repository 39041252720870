import {useMutation} from '@tanstack/react-query';
import {widgetApi} from '../../../api/symfony/symfonyApi';
import {ApiError} from '../../../api/utils/ApiError';
import {queryClient} from '../../../api/utils/reactQueryClient';
import {invalidateWidgetQuery} from './useWidgetQuery';

export const useRemoveWidgetMutation = () => {
  return useMutation<void, ApiError, number>({
    mutationFn: async (id: number) => {
      await widgetApi.deleteWidget({id});
    },
    onSuccess: async () => {
      await invalidateWidgetQuery(queryClient);
    },
  });
};
