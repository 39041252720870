import {useQuery} from '@tanstack/react-query';
import {congestionLightReportApi} from '../api/node-backend/nodeBackendApis';
import {CongestionLightReport, GetCongestionLightReportRequest} from '../api/node-backend/generated';
import {UseQueryOptions} from '../api/utils/ReactQuery';

export const useGetCongestionLightReport = (
  queryRequest?: GetCongestionLightReportRequest,
  options?: UseQueryOptions<CongestionLightReport, (string | GetCongestionLightReportRequest | undefined)[]>
) => {
  return useQuery({
    queryKey: ['congestionLightReportApi.getCongestionLightReport', queryRequest],
    queryFn: () => {
      return congestionLightReportApi.getCongestionLightReport(queryRequest!);
    },
    ...options,
  });
};
