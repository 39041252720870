/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface WorkspaceWrite
 */
export interface WorkspaceWrite {
  /**
   *
   * @type {string}
   * @memberof WorkspaceWrite
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WorkspaceWrite
   */
  description?: string | null;
}

export function WorkspaceWriteFromJSON(json: any): WorkspaceWrite {
  return WorkspaceWriteFromJSONTyped(json, false);
}

export function WorkspaceWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
  };
}

export function WorkspaceWriteToJSON(value?: WorkspaceWrite | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
  };
}
