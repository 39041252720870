/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface RecoveryToken
 */
export interface RecoveryToken {
  /**
   *
   * @type {string}
   * @memberof RecoveryToken
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof RecoveryToken
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof RecoveryToken
   */
  email: string;
}

export function RecoveryTokenFromJSON(json: any): RecoveryToken {
  return RecoveryTokenFromJSONTyped(json, false);
}

export function RecoveryTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecoveryToken {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: json['token'],
    userName: json['userName'],
    email: json['email'],
  };
}

export function RecoveryTokenToJSON(value?: RecoveryToken | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
    userName: value.userName,
    email: value.email,
  };
}
