import {FC, ReactNode, useState} from 'react';
import {TODO} from '../../../../utils/TODO';
import {ActionMenu} from '../../../../components/ActionMenu/ActionMenu';
import Icon from '../../../../atoms/Icon';
import {MenuItem, MenuItemLink} from '../../../../atoms/Menu/MenuItem';
import {ModalActions} from '../../../../redux/Modal';
import {useDispatch} from '../../../../redux/react-redux';
import {EditCircularModal} from '../../EditCircular/EditCircularModal';
import {useDetachOfferMutation} from '../../utils/useDetachOfferMutation';
import {AddToMyCargoesModal} from '../../../../components/Modal/ClonePortfolioEntry/AddToMyCargoesModal';
import {showNotification} from '../../../../utils/notification';
import {ReportMailParsingProblemModal} from '../../ReportMailParsingProblemModal';
import {useUser} from '../../../../components/UserContext/useUser';
import {MoveToWorkspace} from '../../../../components/Modal/MoveToWorkspace';
import {AddToProject} from '../../../../components/Modal/AddToProject/AddToProject';

export const MarketCargoGridActionMenu: FC<{
  offer: TODO;
  isSub: boolean;
  onGridDataChange: () => void;
}> = ({offer, isSub, onGridDataChange}) => {
  const [showSmartInBoxModal, setShowSmartInBoxModal] = useState<boolean>(false);
  const [showReportMailParsingProblemModal, setShowReportMailParsingProblemModal] = useState(false);

  const [showAddToMyCargoesModal, setShowAddToMyCargoesModal] = useState(false);

  const user = useUser();
  const dispatch = useDispatch();

  const detachOfferMutation = useDetachOfferMutation({
    onSuccess: () => {
      onGridDataChange();
    },
  });

  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  return (
    <>
      {showSmartInBoxModal && (
        <EditCircularModal
          onSuccess={() => {
            showNotification('Circular was updated.');
            onGridDataChange();
            setShowSmartInBoxModal(false);
          }}
          offer={offer}
          onCancel={() => setShowSmartInBoxModal(false)}
        />
      )}
      {showReportMailParsingProblemModal && (
        <ReportMailParsingProblemModal offer={offer} onCancel={() => setShowReportMailParsingProblemModal(false)} />
      )}
      <AddToMyCargoesModal
        open={showAddToMyCargoesModal}
        onCancel={() => setShowAddToMyCargoesModal(false)}
        cargo={offer.cargo}
        onSuccess={() => {}}
      />
      <ActionMenu
        actionIcon={<Icon type={'more-vert'} />}
        items={[
          {
            key: 'View cargo',
            label: <MenuItemLink label="View cargo" href={`/cargo/${offer.cargo.id}`} />,
          },
          {
            key: 'Edit offer',
            label: (
              <MenuItem
                label="Edit circular"
                onClick={() => {
                  setShowSmartInBoxModal(true);
                }}
                dataCy="MenuItemEditOffer"
              />
            ),
          },
          {
            key: 'Report a parsing problem',
            isValid: offer.isParserOffer,
            label: (
              <MenuItem
                label="Report a parsing problem"
                onClick={() => {
                  setShowReportMailParsingProblemModal(true);
                }}
                dataCy="MenuItemReportParsingProblem"
              />
            ),
          },
          {
            key: 'Add to project',
            label: (
              <MenuItem
                label="Add to project"
                onClick={() => {
                  showModal(<AddToProject type="cargo" id={offer.cargo.id} />);
                }}
              />
            ),
          },
          ...(isSub
            ? [
                {
                  key: 'Mark as non duplicate',
                  label: (
                    <MenuItem
                      label="Mark as non duplicate"
                      onClick={() => {
                        detachOfferMutation.mutate({id: offer.id, type: 'cargo'});
                      }}
                    />
                  ),
                },
              ]
            : []),
          {
            key: 'Add to My Cargoes',
            label: (
              <MenuItem
                label="Add to My Cargoes"
                onClick={() => {
                  setShowAddToMyCargoesModal(true);
                }}
              />
            ),
          },
          {
            key: 'Move to workspace',
            isValid: !!user?.payload?.company?.id,
            label: (
              <MenuItem
                label="Move to workspace"
                onClick={() => {
                  showModal(<MoveToWorkspace type="marketCargo" data={offer} reload={onGridDataChange} />);
                }}
                disabled={offer.public}
                disabledHint={offer.public ? "You can't move public offers to another workspace" : undefined}
              />
            ),
          },
        ]}></ActionMenu>
    </>
  );
};
