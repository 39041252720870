/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AisRecordResponse,
  AisRecordResponseFromJSON,
  AisRecordResponseFromJSONTyped,
  AisRecordResponseToJSON,
} from './';

/**
 *
 * @export
 * @interface AisRecordsResponse
 */
export interface AisRecordsResponse {
  /**
   *
   * @type {Array<AisRecordResponse>}
   * @memberof AisRecordsResponse
   */
  items: Array<AisRecordResponse>;
}

export function AisRecordsResponseFromJSON(json: any): AisRecordsResponse {
  return AisRecordsResponseFromJSONTyped(json, false);
}

export function AisRecordsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisRecordsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(AisRecordResponseFromJSON),
  };
}

export function AisRecordsResponseToJSON(value?: AisRecordsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(AisRecordResponseToJSON),
  };
}
