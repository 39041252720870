import dayjs from 'dayjs';
import sumBy from 'lodash/sumBy';
import {assertUnreachable} from '../../../../../../utils/assert';
import {ConsumptionTransaction} from '../ConsumptionTransaction';
import {avg} from './accountingMethods/avg';
import {lifo} from './accountingMethods/lifo/lifo';

export type AccountingMethod = 'FIFO' | 'LIFO' | 'AVG';

export const getCostsForConsumption = ({
  dateOfConsumption,
  sortedTransactions,
  accountingMethod,
  quantity,
}: {
  quantity: number;
  dateOfConsumption: Date;
  accountingMethod: AccountingMethod;
  sortedTransactions: ConsumptionTransaction[];
}): number => {
  const filteredTransactions = sortedTransactions.filter(
    transaction => dayjs(transaction.date).isBefore(dayjs(dateOfConsumption)) || transaction.date === undefined
  );

  const availableQuantityAtThisMoment = sumBy(filteredTransactions, transaction => transaction.quantity);

  if (quantity === 0) {
    return 0;
  }

  if (availableQuantityAtThisMoment < quantity) {
    throw new Error(
      `Not enough quantity available. Available: ${availableQuantityAtThisMoment}, requested: ${quantity}`
    );
  }

  switch (accountingMethod) {
    case 'FIFO':
      return lifo({
        filteredTransactions,
        quantity,
      });
    case 'LIFO':
      return lifo({
        filteredTransactions,
        quantity,
      });
    case 'AVG':
      return avg({
        filteredTransactions,
        quantity,
      });
    default:
      assertUnreachable(accountingMethod);
  }
};
