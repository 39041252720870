import React, {FC} from 'react';
import {assertUnreachable} from '../../../../../../utils/assert';
import {TermModification} from '../../../../../../api/node-backend/generated';
import {Tooltip} from 'antd';
import {DiffIcon, EditIcon} from './shared';

type TermModificationIconProps = {
  isOwnEdit: boolean;
  termModification?: TermModification;
};

export const TermModificationIcon: FC<TermModificationIconProps> = ({isOwnEdit, termModification}) => {
  switch (termModification) {
    case undefined:
    case TermModification.Unmodified:
      return (
        <Tooltip title="Unmodified">
          <div>
            <DiffIcon type="pause" size="small" color={isOwnEdit ? 'blue-light' : 'gray'} />
          </div>
        </Tooltip>
      );
    case TermModification.UpdateTermText:
      return (
        <Tooltip title="Edited text">
          <div>
            <EditIcon type="edit" size="small" color="white-fixed" $bgColor={isOwnEdit ? 'blue' : 'gray-2'} />
          </div>
        </Tooltip>
      );
    case TermModification.AcceptTerm:
      return (
        <Tooltip title="Accepted">
          <div>
            <DiffIcon type="checkmark" size="small" color="green" />
          </div>
        </Tooltip>
      );
    case TermModification.RequestRejection:
      return (
        <Tooltip title="Requested rejection">
          <div>
            <DiffIcon type="close" size="small" color="red" />
          </div>
        </Tooltip>
      );
    case TermModification.RejectRejectionRequest:
      return (
        <Tooltip title="Reset rejection request back to ongoing">
          <div>
            <DiffIcon type="edit" size="small" color={isOwnEdit ? 'blue-light' : 'gray'} />
          </div>
        </Tooltip>
      );
    case TermModification.RejectTerm:
      return (
        <Tooltip title="Rejected">
          <div>
            <DiffIcon type="close" size="small" color="red" />
          </div>
        </Tooltip>
      );
    case TermModification.NewTerm:
      return (
        <Tooltip title="New">
          <div>
            <DiffIcon type="item-add-selection-solid" size="small" color={isOwnEdit ? 'blue' : 'gray'} />
          </div>
        </Tooltip>
      );
    default:
      assertUnreachable(termModification);
  }
};
