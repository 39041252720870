import {Cargo} from '../../../../../SearchPicker/Type';

export const mapCargoToQuantityInMts = (cargo: Cargo): number => {
  let cargoQuantity: number | undefined = undefined;
  if (cargo.quantityUnit === 'mt' || cargo.quantityUnit === 'mts') {
    cargoQuantity = cargo.quantity || cargo.quantityMax || cargo.quantityMin || undefined;
  }
  if (cargo.quantityUnit === 'cbm') {
    cargoQuantity = Math.round(
      (cargo.quantity || cargo.quantityMax || cargo.quantityMin || 0) * (cargo.commodityStowageFactor || 1)
    );
  }

  if (cargoQuantity === undefined && (cargo.quantity || cargo.quantityMax)) {
    cargoQuantity = cargo.quantity || cargo.quantityMax || undefined;
  }

  if (cargoQuantity === undefined && cargo.vesselSizeMax) {
    cargoQuantity = cargo.vesselSizeMax;
  }
  if (cargoQuantity === undefined && cargo.vesselSize) {
    cargoQuantity = cargo.vesselSize;
  }

  if (isNaN(cargoQuantity!)) {
    cargoQuantity = undefined;
  }

  return cargoQuantity || 0;
};
