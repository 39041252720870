import {TripsLayer} from '@deck.gl/geo-layers/typed';
import {Coordinates} from '../../../../utils/Coordinates';
import dayjs from 'dayjs';

type LatLng = {
  lat: number;
  lng: number;
};

export type FirstLastTimestamp = {
  first: number;
  last: number;
};

export type Waypoint = {latLng: LatLng; timestamp: number};

export interface RouteItem {
  waypoints: Waypoint[];
  type: string;
  firstLast: FirstLastTimestamp;
}

type Params = {
  /**
   * Current time of the frame in seconds.
   * Infinity means that the animation is paused.
   */
  currentTime: number;
  routes: RouteItem[];
  id?: string;
  trailLength?: number;
  color?: [number, number, number] | ((d: RouteItem) => [number, number, number]);
};

export const useRouteTripLayer = ({currentTime, routes, color, trailLength = 60000000, id}: Params) => {
  if (currentTime === Infinity) {
    return null;
  }

  return new TripsLayer({
    id: `TripsLayer-${id}`,
    data: routes,
    currentTime: dayjs(currentTime).unix(),
    fadeTrail: true,
    getTimestamps: (d: RouteItem) => {
      const timestamps = d.waypoints.map(p => {
        const timestamp = dayjs(Math.round(p.timestamp)).unix();
        return timestamp;
      });

      return timestamps;
    },
    getPath: (d: RouteItem) => {
      const path: Coordinates[] = d.waypoints.map(p => [p.latLng.lat, p.latLng.lng]);
      return path;
    },
    getColor: color ?? [253, 128, 93],
    opacity: 0.8,
    widthMinPixels: 5,
    rounded: true,
    trailLength,
  });
};
