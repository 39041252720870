import {Form, Input} from 'antd';
import styled from 'styled-components';
import {TimeCharterCalculationProp, TimeCharterCalculationResult} from './timeCharterCalculation';
import {TODO} from '../../../utils/TODO';

export interface VesselInput {
  ballastDistance?: number;
  ballastSpeed?: number;
  ballastConsumptionIFO?: number;
  ballastConsumptionMDO?: number;
  waitingConsumptionIFO?: number;
  waitingConsumptionMDO?: number;
}

export interface VesselInformationProps {
  onChange: (obj: VesselInput) => void;
  inputState: TimeCharterCalculationProp;
  outputState: TimeCharterCalculationResult;
}

export const VesselInformation = (props: VesselInformationProps) => {
  const onUpdate = (field: keyof VesselInput, value: TODO) => {
    props.onChange({...props.inputState.vessel, [field]: value});
  };

  return (
    <Form>
      <FormItem label={'Ballast distance'}>
        <Input
          value={props.inputState.vessel.ballastDistance}
          onChange={v => onUpdate('ballastDistance', v.currentTarget.value)}
          type={'number'}
          min={0}
          addonAfter={'nm'}
        />
      </FormItem>
      <FormItem label={'Ballast speed'}>
        <Input
          value={props.inputState.vessel.ballastSpeed}
          onChange={v => onUpdate('ballastSpeed', v.currentTarget.value)}
          type={'number'}
          min={0}
          addonAfter={'knots'}
        />
      </FormItem>
      <FormItem label={'Ballast consumption IFO'}>
        <Input
          value={props.inputState.vessel.ballastConsumptionIFO}
          onChange={v => onUpdate('ballastConsumptionIFO', v.currentTarget.value)}
          type={'number'}
          min={0}
          addonAfter={'mt / day'}
        />
      </FormItem>
      <FormItem label={'Ballast consumption MDO'}>
        <Input
          value={props.inputState.vessel.ballastConsumptionMDO}
          onChange={v => onUpdate('ballastConsumptionMDO', v.currentTarget.value)}
          type={'number'}
          min={0}
          addonAfter={'mt / day'}
        />
      </FormItem>
      <FormItem label={'Consumption (waiting) IFO'}>
        <Input
          value={props.inputState.vessel.waitingConsumptionIFO}
          onChange={v => onUpdate('waitingConsumptionIFO', v.currentTarget.value)}
          type={'number'}
          min={0}
          addonAfter={'mt / day'}
        />
      </FormItem>
      <FormItem label={'Consumption  (waiting) MDO'}>
        <Input
          value={props.inputState.vessel.waitingConsumptionMDO}
          onChange={v => onUpdate('waitingConsumptionMDO', v.currentTarget.value)}
          type={'number'}
          min={0}
          addonAfter={'mt / day'}
        />
      </FormItem>
    </Form>
  );
};

export const FormItem = styled(Form.Item)`
  .ant-form-item-label,
  .ant-form-item-control {
    flex: 0 0 50%;

    > * {
      width: 100%;
    }

    @media screen and (max-width: 1680px) {
      flex: 0 0 100%;
    }
  }
`;
