import {numberBetween, numberBetweenAndGreaterOrEqual} from '../../../../utils/validators';
import {convertValueToNamedValue, NamedValue} from '../FilterConversion/utils/NamedValue';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

type DurationUnit = 'day' | 'week' | 'month' | 'year';

export const DurationUnits: NamedValue<DurationUnit>[] = [
  {name: 'Days', value: 'day'},
  {name: 'Weeks', value: 'week'},
  {name: 'Months', value: 'month'},
  {name: 'Years', value: 'year'},
];

export type DatabaseType = {
  range: {
    from?: number;
    to?: number;
  };
  unit: DurationUnit;
};

export type InternalType = {
  rangeFrom: number | '';
  rangeTo: number | '';
  unit: NamedValue<DurationUnit>;
};

export const Defaults: InternalType = {
  rangeFrom: '',
  rangeTo: '',
  unit: DurationUnits[0],
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {range, unit} = database;
  return {
    rangeFrom: range?.from ?? '',
    rangeTo: range?.to ?? '',
    unit: convertValueToNamedValue({
      value: unit,
      available: DurationUnits,
      defaultValue: {
        name: '*** Unknown **',
        value: unit,
      },
    }),
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {rangeFrom, rangeTo, unit} = internal;
  return {
    range: {
      from: rangeFrom === '' ? undefined : rangeFrom,
      to: rangeTo === '' ? undefined : rangeTo,
    },
    unit: unit.value,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Duration',
  branch: 'duration',
  defaults: Defaults,
  validators: {
    rangeFrom: numberBetween(0, 99999),
    rangeTo: numberBetweenAndGreaterOrEqual(0, 99999, 'rangeFrom'),
  },
  fromDatabase,
  toDatabase,
});
