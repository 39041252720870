import React, {FC} from 'react';
import styled from 'styled-components';
import {useSelector} from '../../../redux/react-redux';
import {SidebarMenuBar} from './SidebarMenuBar';
import {Spacer} from '../../Spacer/Spacer';
import {WorkspaceSelect} from '../../WorkspaceSelect/WorkspaceSelect';
import {useWorkspacesQuery} from '../../../queries/useWorkspacesQuery';
import {CompanyWorkspace} from '../../../api/symfony/generated';
import {getSidebarMode} from '../../../redux/selectors';

export const SidebarUpperAreaContent: FC = () => {
  const sidebarMode = useSelector(getSidebarMode);
  const companyWorkspacesQuery = useWorkspacesQuery();
  const companyWorkspaces: CompanyWorkspace[] = companyWorkspacesQuery.isSuccess ? companyWorkspacesQuery.data : [];
  const hasCompanyWorkspaces = companyWorkspaces.length > 0;
  const workspaceSelectSize = sidebarMode === 'wide' ? 'wide' : 'narrow';

  return (
    <SidebarUpperAreaContentStyled>
      <Spacer height={30} />
      {hasCompanyWorkspaces && (
        <>
          <WorkspaceSelect
            colorScheme="WhiteOnBlue"
            size={workspaceSelectSize}
            placement="bottomLeft"
            style={{marginLeft: workspaceSelectSize === 'wide' ? '14px' : 0}}
          />
          <Spacer height={20} />
        </>
      )}
      <SidebarMenuBar mode={sidebarMode} />
    </SidebarUpperAreaContentStyled>
  );
};

const SidebarUpperAreaContentStyled = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`;
