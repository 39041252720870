/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UpdateTermTextRequest
 */
export interface UpdateTermTextRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateTermTextRequest
   */
  text: string;
}

export function UpdateTermTextRequestFromJSON(json: any): UpdateTermTextRequest {
  return UpdateTermTextRequestFromJSONTyped(json, false);
}

export function UpdateTermTextRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTermTextRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: json['text'],
  };
}

export function UpdateTermTextRequestToJSON(value?: UpdateTermTextRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
  };
}
