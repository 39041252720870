import {StepResult} from './StepResult';
import {FilterParams} from '../filterParams';
import {Step3Vessel} from './step3';
import {Step4Result} from './step4';
import geolibGetDistance from 'geolib/es/getDistance';

export type Step5Vessel = Step3Vessel & {
  airlineDistanceInMeters: number;
};

export type Step5Result = StepResult<Step5Vessel>;

/**
 *  extend vessel  -calc and add airlineDistanceInMeters
 */
export const step5 = ({params, preResult}: {preResult: Step4Result; params: FilterParams}): Step5Result => {
  const vesselList: Step5Vessel[] = preResult.vessels.map(vessel => {
    let airlineDistanceInMeters = 0;

    if (vessel.destinationPort) {
      const vesselDestPortDistance = geolibGetDistance(
        {
          latitude: vessel.destinationPort!.latitude!,
          longitude: vessel.destinationPort!.longitude!,
        },
        {
          latitude: vessel.coordinates![0]!,
          longitude: vessel.coordinates![1]!,
        }
      );

      const destPortPortDistance = geolibGetDistance(
        {
          latitude: params.portCoordinates[0],
          longitude: params.portCoordinates[1],
        },
        {
          latitude: vessel.destinationPort!.latitude!,
          longitude: vessel.destinationPort!.longitude!,
        }
      );

      airlineDistanceInMeters = destPortPortDistance + vesselDestPortDistance;
    } else {
      airlineDistanceInMeters = geolibGetDistance(
        {
          latitude: params.portCoordinates[0],
          longitude: params.portCoordinates[1],
        },
        {
          latitude: vessel.coordinates![0]!,
          longitude: vessel.coordinates![1]!,
        }
      );
    }
    return {...vessel, airlineDistanceInMeters};
  });

  return {
    vessels: vesselList,
    loggedFilters: [...preResult.loggedFilters],
  };
};
