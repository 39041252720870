/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  DraftChartPortVisit,
  DraftChartPortVisitFromJSON,
  DraftChartPortVisitFromJSONTyped,
  DraftChartPortVisitToJSON,
} from './';

/**
 *
 * @export
 * @interface DraftChartResponse
 */
export interface DraftChartResponse {
  /**
   *
   * @type {Array<DraftChartPortVisit>}
   * @memberof DraftChartResponse
   */
  portVisits: Array<DraftChartPortVisit>;
  /**
   *
   * @type {number}
   * @memberof DraftChartResponse
   */
  minDraft: number;
  /**
   *
   * @type {number}
   * @memberof DraftChartResponse
   */
  maxDraft: number;
}

export function DraftChartResponseFromJSON(json: any): DraftChartResponse {
  return DraftChartResponseFromJSONTyped(json, false);
}

export function DraftChartResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftChartResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    portVisits: (json['portVisits'] as Array<any>).map(DraftChartPortVisitFromJSON),
    minDraft: json['minDraft'],
    maxDraft: json['maxDraft'],
  };
}

export function DraftChartResponseToJSON(value?: DraftChartResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    portVisits: (value.portVisits as Array<any>).map(DraftChartPortVisitToJSON),
    minDraft: value.minDraft,
    maxDraft: value.maxDraft,
  };
}
