import {FC, MouseEventHandler, ReactNode, RefObject, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import {ModalActions} from '../../redux/Modal';
import './style.scss';
import {createGlobalStyle} from 'styled-components';
import useEvent from 'beautiful-react-hooks/useEvent';
import {useDispatch} from '../../redux/react-redux';

/** @deprecated use AntD Modal instead */
export interface BaseModalChildrenProps {
  close: () => void;
}

/** @deprecated use AntD Modal instead */
export type BaseModalChildren = (props: BaseModalChildrenProps) => ReactNode;

type Props = {
  closeable?: boolean;
  narrow?: boolean;
  middle?: boolean;
  noPaddingSideBody?: boolean;
  noTopDownPadding?: boolean;
  noPadding?: boolean;
  onClose?: () => boolean;
  title?: ReactNode;
  noHeader?: boolean;
  subTitle?: string;
  className?: string;
  width?: number;
  maxWidth?: number;
  children: BaseModalChildren;
};

/**
 * BaseModal renders a modal. It is rendered indirectly through Redux action ModalActions.show(),
 * for exampale:
 *
 * ```
 * import {ModalActions} from '../../redux/Modal';
 *
 * ...
 * dispatch(ModalActions.show(<AddToProject type="vessel" id={id} />));
 * ```
 *
 * @deprecated use AntD Modal instead
 * */
const BaseModal: FC<Props> = ({
  closeable = true,
  narrow,
  middle,
  noPaddingSideBody,
  noTopDownPadding,
  noPadding,
  onClose,
  title,
  noHeader,
  subTitle,
  className,
  width,
  maxWidth,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const hideModal = () => {
    dispatch(ModalActions.hide());
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (closeable && (event.key === 'Escape' || event.keyCode === 27)) {
      close();
    }
  };

  const windowRef = useRef(window);
  const onKeydown = useEvent<KeyboardEvent>(windowRef as unknown as RefObject<HTMLElement>, 'keydown');
  onKeydown(handleKeydown);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      setVisible(true);
    });
  }, []);

  const onDialogClick: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
  };

  const close = () => {
    let willClose = true;
    if (onClose) {
      willClose = onClose();
      if (willClose === undefined) {
        willClose = true;
      }
    }
    if (willClose) {
      setVisible(false);
      setTimeout(() => {
        hideModal();
      }, 150);
    }
  };

  return (
    <div className={classNames('modal', {'modal--visible': visible}, className)}>
      <GlobalOverlayStyle />
      <div className="modal__overlay" />
      <div className="modal__content">
        <div
          style={{
            maxWidth: maxWidth,
            width: width,
          }}
          onClick={onDialogClick}
          className={classNames('modal__dialog', {
            'modal__dialog--narrow': narrow,
            'modal__dialog--middle': middle,
          })}>
          {closeable && <CloseButton close={close} />}
          {!noHeader && (
            <div className="modal__header">
              {title && <Title title={title} />}
              {subTitle && <SubTitle subTitle={subTitle} />}
            </div>
          )}
          <div
            className={classNames(
              'modal__body',
              {'modal__body--no-side-padding': noPaddingSideBody},
              {'modal__body--no-top-down-padding': noTopDownPadding},
              {'modal__body--no-padding': noPadding}
            )}>
            {typeof children === 'function' ? children({close: close}) : children}
          </div>
        </div>
      </div>
    </div>
  );
};

const CloseButton: FC<{close: () => void}> = ({close}) => (
  <div className="modal__close" onClick={close}>
    <Icon type="clear" />
  </div>
);

const Title: FC<{title: ReactNode}> = ({title}) => <h1 className="modal__title">{title}</h1>;

const SubTitle: FC<{subTitle: ReactNode}> = ({subTitle}) => <p className="modal__sub-title">{subTitle}</p>;

/** @deprecated use AntD Modal instead */
export default BaseModal;

const GlobalOverlayStyle = createGlobalStyle`
body{
  overflow: hidden;
  }
`;
