import {useMutation, useQueryClient} from '@tanstack/react-query';
import {portfolioGroupApi} from '../../../api/symfony/symfonyApi';
import {DeleteSubPortfolioRequest} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';
import {invalidateGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';
import {PortfolioType} from '../../../redux/Portfolio';

interface DeleteSubPortfolioParams {
  id: number;
  type: PortfolioType;
}

export const useDeleteSubPortfolio = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, ApiError, DeleteSubPortfolioRequest>({
    mutationFn: request => portfolioGroupApi.deleteSubPortfolio(request),
  });

  const deleteSubPortfolio = async ({type, id}: DeleteSubPortfolioParams) => {
    const subPortfoliosQueryKey = ['subPortfolios', type];
    await queryClient.cancelQueries({
      queryKey: subPortfoliosQueryKey,
    });

    // Talk to the API
    await mutation.mutateAsync(
      {id},
      {
        onSettled: async () => {
          // Reload
          await invalidateGetSubPortfoliosQuery(queryClient, type);
        },
      }
    );
  };

  return deleteSubPortfolio;
};
