/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Codes
 */
export interface Codes {
  /**
   *
   * @type {string}
   * @memberof Codes
   */
  alpha2?: string;
  /**
   *
   * @type {string}
   * @memberof Codes
   */
  alpha3?: string;
  /**
   *
   * @type {string}
   * @memberof Codes
   */
  numeric?: string;
}

export function CodesFromJSON(json: any): Codes {
  return CodesFromJSONTyped(json, false);
}

export function CodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Codes {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    alpha2: !exists(json, 'alpha-2') ? undefined : json['alpha-2'],
    alpha3: !exists(json, 'alpha-3') ? undefined : json['alpha-3'],
    numeric: !exists(json, 'numeric') ? undefined : json['numeric'],
  };
}

export function CodesToJSON(value?: Codes | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    'alpha-2': value.alpha2,
    'alpha-3': value.alpha3,
    numeric: value.numeric,
  };
}
