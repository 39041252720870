import styled from 'styled-components';
import Icon, {IconType} from '../../../../atoms/Icon';
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {Link} from 'react-router-dom';
import {StyledCardTitle} from '../VesselInformation/VesselInformation';
import {NotAvailable} from '../../../../utils/NotAvailable';

interface WidgetTitleProps {
  icon: IconType;
  title: string;
  showBody: boolean;
  onToggleBody: () => void;
  children?: React.ReactNode;
}
export const WidgetTitle: React.FC<WidgetTitleProps> = props => {
  const CaretIcon = props.showBody ? CaretUpOutlinedIcon : CaretDownOutlinedIcon;

  return (
    <StyledWidgetTitleWrapper>
      <StyledCardTitle
        data-cy={`WidgetTitle_${props.title}`}
        onClick={() => {
          props.onToggleBody();
        }}>
        <div style={{display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Icon type={props.icon} size="small" color="blue" style={{marginRight: 5}} />
            {props.title}
          </div>
          <CaretIcon />
        </div>
      </StyledCardTitle>
      {props.children}
    </StyledWidgetTitleWrapper>
  );
};

interface WidgetTitleBasicInformationProps {
  name?: string;
  link?: string;
}
export const WidgetTitleBasicInformation: React.FC<WidgetTitleBasicInformationProps> = props => (
  <BasicInfoWrapper>
    <BasicInfoTitle data-cy="widgetTitle">{props.name || NotAvailable}</BasicInfoTitle>
    {props.link && (
      <Link to={props.link} target={'_blank'} rel="noreferrer">
        <DetailButton data-cy="widgetTitleLink" style={{fontWeight: 700}} type={'link'}>
          View details
        </DetailButton>
      </Link>
    )}
  </BasicInfoWrapper>
);

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledWidgetTitleWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const CaretDownOutlinedIcon = styled(CaretDownOutlined)`
  color: var(--color-gray-2);
`;

const CaretUpOutlinedIcon = styled(CaretUpOutlined)`
  color: var(--color-gray-2);
`;

export const WidgetTitleProperties = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const WidgetTitleBasicProperty = styled.div`
  font-size: var(--font-size-lg);
  font-weight: 400;
  color: var(--color-black);
  width: 50%;

  &:nth-of-type(even) {
    text-align: right;
  }
`;

export const WidgetTitleMailContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin: 5px 0;
`;

const BasicInfoTitle = styled.span`
  display: inline-block;
  width: calc(100% - 7rem);
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-black);
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DetailButton = styled(Button)`
  width: 7rem;
  display: inline-block;
`;
