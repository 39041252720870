/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Laycan
 */
export interface Laycan {
  /**
   *
   * @type {Date}
   * @memberof Laycan
   */
  start?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Laycan
   */
  end?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Laycan
   */
  spot?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Laycan
   */
  empty?: boolean;
}

export function LaycanFromJSON(json: any): Laycan {
  return LaycanFromJSONTyped(json, false);
}

export function LaycanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Laycan {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    start: !exists(json, 'start') ? undefined : json['start'] === null ? null : new Date(json['start']),
    end: !exists(json, 'end') ? undefined : json['end'] === null ? null : new Date(json['end']),
    spot: !exists(json, 'spot') ? undefined : json['spot'],
    empty: !exists(json, 'empty') ? undefined : json['empty'],
  };
}

export function LaycanToJSON(value?: Laycan | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    start: value.start === undefined ? undefined : value.start === null ? null : value.start.toISOString(),
    end: value.end === undefined ? undefined : value.end === null ? null : value.end.toISOString(),
    spot: value.spot,
    empty: value.empty,
  };
}
