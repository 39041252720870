import {Popover, Typography} from 'antd';
import {FC, ReactNode} from 'react';
import {PopoverContent} from './shared';
import {useSelector} from '../../redux/react-redux';
import {Link} from 'react-router-dom';
import {getCompany} from '../../redux/selectors';

const {Title} = Typography;

type EasySharingPromotionPopoverProps = {
  children: ReactNode;
};

export const EasySharingPromotionPopover: FC<EasySharingPromotionPopoverProps> = ({children}) => {
  const {data: company} = useSelector(getCompany);
  const companyId = company?.id;

  return (
    <Popover
      trigger="click"
      overlayInnerStyle={{padding: 10}}
      content={
        <PopoverContent>
          <Title level={5}>Receive similar circulars from other seabo users automatically</Title>
          <p>Join our “quid pro quo” sharing feature by selecting submarkets that you share with other users.</p>
          <p>
            Go to <Link to={`/company/${companyId}/settings/workspace`}>workspace settings</Link> and enable Easy
            Sharing
          </p>
        </PopoverContent>
      }>
      {children}
    </Popover>
  );
};

export const EasySharingActiveInboxPopover: FC<EasySharingPromotionPopoverProps> = ({children}) => {
  return (
    <Popover
      trigger="click"
      overlayInnerStyle={{padding: 10}}
      content={
        <PopoverContent>
          <b>Easy Sharing is activated.</b>
          <br />
          You receive circulars from seabo public market.
        </PopoverContent>
      }>
      {children}
    </Popover>
  );
};
