import {FC, Fragment} from 'react';
import {Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts';
import {TODO} from '../../../../../utils/TODO';
import CompanyPieChart from './CompanyPieChart';
import styled from 'styled-components';

type Props = {
  data: TODO;
  isPortfolio?: boolean;
  Key?: 'total' | 'vessel' | 'cargo' | 'projects';
};

type FillColourIndex = Record<string, string>;

type FillColour = Record<string, FillColourIndex>;

const fillColour: FillColour = {
  cargo: {
    created: 'var(--color-blue)',
    received: 'var(--color-azure)',
    shortlist: '#00467e',
  },
  vessel: {
    created: '#62d388',
    received: '#54E200',
    shortlist: '#2FD309',
  },
};

type DataCargoVessel = {
  name: string;
  value: number;
  fill: string;
};

const createPieGraph = (data: TODO) => {
  const dataVessel: DataCargoVessel[] = [];
  const dataCargo: DataCargoVessel[] = [];
  Object.keys(data).forEach(object => {
    Object.keys(data[object]).forEach(key => {
      if (key !== 'total') {
        if (key === 'vessel') {
          dataVessel.push({name: `vessel ${object}`, value: data[object]['vessel'], fill: fillColour.vessel[object]});
        } else if (key === 'cargo') {
          dataCargo.push({name: `cargo ${object}`, value: data[object]['cargo'], fill: fillColour.cargo[object]});
        }
      }
    });
  });
  return {vessel: dataVessel, cargo: dataCargo};
};

type EntryBase = Record<'cargo' | 'vessel' | 'total', number>;
type Entry = [string, EntryBase];

const createHistoryGraphData = ({history}: TODO) => {
  const dataHistory: TODO = [];
  Object.keys(history).forEach(date => {
    const data: TODO[] = [];
    Object.entries(history[date]).forEach(entry => {
      data.push({
        [`${entry[0]}-cargo`]: (entry as Entry)[1].cargo ? (entry as Entry)[1].cargo : 0,
        [`${entry[0]}-vessel`]: (entry as Entry)[1].vessel ? (entry as Entry)[1].vessel : 0,
      });
    });
    dataHistory.push({name: date, ...data[0], ...data[1]});
  });
  return dataHistory;
};

export const CompanyDataOverviewData: FC<Props> = props => {
  const {data, isPortfolio} = props;
  const {vessel, cargo} = createPieGraph(data);
  const AreaGraphData = data.history ? createHistoryGraphData(data) : [];

  return (
    <StyledCompanyData>
      <StyledDataPie>
        {isPortfolio ? (
          <CompanyPieChart data={cargo.concat(vessel)} label={'Cargo & Vessel'} />
        ) : (
          <Fragment>
            <CompanyPieChart data={cargo} label="Cargo" />
            <CompanyPieChart data={vessel} label="Vessel" />
          </Fragment>
        )}
      </StyledDataPie>
      {data.history && (
        <div>
          <StyledHeadline>History</StyledHeadline>
          <StyledGraph>
            <AreaChart
              width={600}
              height={400}
              data={AreaGraphData.reverse()}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign={'top'} />
              <Area
                type="monotone"
                dataKey="created-cargo"
                fill={fillColour.cargo.created}
                stroke={fillColour.cargo.created}
              />
              <Area
                type="monotone"
                dataKey="created-vessel"
                fill={fillColour.vessel.created}
                stroke={fillColour.vessel.created}
              />
              <Area
                type="monotone"
                dataKey="received-cargo"
                fill={fillColour.cargo.received}
                stroke={fillColour.cargo.received}
              />
              <Area
                type="monotone"
                dataKey="received-vessel"
                fill={fillColour.vessel.received}
                stroke={fillColour.vessel.received}
              />
              <Area
                type="monotone"
                dataKey="shortlist-cargo"
                fill={fillColour.cargo.shortlist}
                stroke={fillColour.cargo.shortlist}
              />
              <Area
                type="monotone"
                dataKey="shortlist-vessel"
                fill={fillColour.vessel.shortlist}
                stroke={fillColour.vessel.shortlist}
              />
            </AreaChart>
          </StyledGraph>
        </div>
      )}
    </StyledCompanyData>
  );
};

const StyledGraph = styled.div`
  display: flex;
  justify-content: space-around;
  .recharts-legend-wrapper {
    margin-left: 20px;
  }
`;

const StyledHeadline = styled.div`
  font-size: var(--font-size-lg);
  font-weight: 600;
  line-height: 32px;
`;

const StyledDataPie = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const StyledCompanyData = styled.div`
  overflow: hidden;
`;
