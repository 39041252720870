/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CommodityResponseData,
  CommodityResponseDataFromJSON,
  CommodityResponseDataFromJSONTyped,
  CommodityResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface CommodityResponse
 */
export interface CommodityResponse {
  /**
   *
   * @type {CommodityResponseData}
   * @memberof CommodityResponse
   */
  data: CommodityResponseData;
}

export function CommodityResponseFromJSON(json: any): CommodityResponse {
  return CommodityResponseFromJSONTyped(json, false);
}

export function CommodityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommodityResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: CommodityResponseDataFromJSON(json['data']),
  };
}

export function CommodityResponseToJSON(value?: CommodityResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: CommodityResponseDataToJSON(value.data),
  };
}
