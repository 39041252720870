import {
  NegotiationParty,
  NegotiationSummary,
  NegotiationRoundResult,
  NegotiationStatus,
  NegotiationActor,
} from '../../../api/node-backend/generated';
import {mockNegotiationSummary} from './mockNegotiationSummary';
import {getSummaryFromRoundResult} from './getSummaryFromRoundResult';
import {createMockRoundResults} from './createMockRoundResults';

/**
 * Creates a mocked Negotiation with round results according to input parameters.
 * See more about Negotiations in general in `/node-backend/src/negotiation/README.md`.
 *
 * @param {NegotiationCase} negotiationCase
 * Depending on these 6 parameters, every major case of a negotiation can be calculated
 *
 * @param {NegotiationParty} negotiationCase.party
 * Sets the party from whose perspective the negotiation is viewed
 *
 * @param {NegotiationStatus} negotiationCase.status
 * Sets the status of the negotiation
 *
 * @param {boolean} negotiationCase.allTermsSettled
 * Controls whether the latest round should contain only terms that are accepted or fully rejected
 *
 * @param {boolean} negotiationCase.allSubjectsAccepted
 * Controls whether the latest round should contain only subjects that are accepted, lifted or failed
 *
 * @param {boolean} negotiationCase.allSubjectsSettled
 * Controls whether the latest round should contain only subjects that are either lifted or failed
 *
 * @param {boolean} negotiationCase.latestRoundCommitted
 * Controls whether the latest round is committed or not
 *
 *
 * @example
 * "Valid negotiation cases (* = any value)"
 * // |         | NEGOTIAT. | ALL TERMS | ALL SUBS | ALL SUBS | LATEST ROUND |
 * // | PARTY   | STATUS    | SETTLED   | ACCEPTED | SETTLED  | COMMITTED    |
 * // |---------|-----------|-----------|----------|----------|--------------|
 * // | Creator | Offer     | false     | *        | *        | false        | = send offer
 * // |---------|-----------|-----------|----------|----------|--------------| (there is no offer round for counterpart)
 * // | *       | Ongoing   | false     | *        | *        | *            |
 * // | *       | Ongoing   | true      | true     | *        | false        | = move to recap
 * // |---------|-----------|-----------|----------|----------|--------------| (terms are moved back to ongoing)
 * // | *       | Recap     | false     | true     | *        | *            |
 * // | *       | Recap     | true      | true     | true     | false        | = move to settled
 * // |---------|-----------|-----------|----------|----------|--------------|
 * // | *       | Settled   | true      | true     | true     | true         |
 * // |---------|-----------|-----------|----------|----------|--------------|
 * // | *       | Cancelled | *         | *        | *        | true         |
 * // |---------|-----------|-----------|----------|----------|--------------|
 */
export type NegotiationCase = {
  party: NegotiationParty;
  status: NegotiationStatus;
  allTermsSettled: boolean;
  allSubjectsAccepted: boolean;
  allSubjectsSettled: boolean;
  latestRoundCommitted: boolean;
};

export const createMockNegotiation = ({
  party,
  status,
  allTermsSettled,
  allSubjectsAccepted,
  allSubjectsSettled,
  latestRoundCommitted,
  overwriteCounterparty,
  overwriteCommitDate,
}: NegotiationCase & {
  overwriteCounterparty?: Partial<NegotiationActor>;
  overwriteCommitDate?: Date;
}): {
  negotiation: NegotiationSummary;
  roundResults: NegotiationRoundResult[];
} => {
  validNegotiationCaseGuard({
    party,
    status,
    allTermsSettled,
    allSubjectsAccepted,
    allSubjectsSettled,
    latestRoundCommitted,
  });

  const id = `${party}--${status}--${allTermsSettled ? 'all-settled' : 'not-all-settled'}--${
    latestRoundCommitted ? 'committed' : 'not-committed'
  }`;

  const roundResults = createMockRoundResults({
    id,
    party,
    status,
    allTermsSettled,
    allSubjectsAccepted,
    allSubjectsSettled,
    latestRoundCommitted,
    overwriteCommitDate,
  });

  const latestRound = getSummaryFromRoundResult(roundResults[roundResults.length - 1]);

  const negotiation: NegotiationSummary = {
    ...mockNegotiationSummary,
    id,
    party,
    status,
    counterpart: {
      ...mockNegotiationSummary.counterpart,
      ...overwriteCounterparty,
    },
    latestRound,
    title: id.replaceAll(/--/g, ' | '),
    roundCount: roundResults.length,
  };

  return {negotiation, roundResults};
};

export const InvalidNegotiationCaseError = Error;

/**
 * Checks for invalid input combinations and throws InvalidNegotiationCaseError if found.
 */
export const validNegotiationCaseGuard = ({
  party,
  status,
  allTermsSettled,
  allSubjectsAccepted,
  allSubjectsSettled,
  latestRoundCommitted,
}: NegotiationCase) => {
  if (party === NegotiationParty.Creator && status === NegotiationStatus.Offer && allTermsSettled) {
    throw new InvalidNegotiationCaseError('Creator can not have an Offer round that has all terms settled.');
  }

  if (party === NegotiationParty.Counterpart && status === NegotiationStatus.Offer) {
    throw new InvalidNegotiationCaseError(
      'Counterpart cannot have an Offer round. When Offer round is sent by Creator, it automatically moves negotiation to Ongoing.'
    );
  }

  if (status === NegotiationStatus.Ongoing && allTermsSettled && allSubjectsAccepted && latestRoundCommitted) {
    throw new InvalidNegotiationCaseError(
      'A round in Ongoing or Recap status with all terms settled and subjects at least accepted cannot be committed. This would have moved negotiation to next state.'
    );
  }

  if (status === NegotiationStatus.Recap && allTermsSettled && allSubjectsSettled && latestRoundCommitted) {
    throw new InvalidNegotiationCaseError(
      'A round in Ongoing or Recap status with all terms settled and subjects at least accepted cannot be committed. This would have moved negotiation to next state.'
    );
  }

  if (status === NegotiationStatus.Settled && (!allTermsSettled || !allSubjectsSettled || !latestRoundCommitted)) {
    throw new InvalidNegotiationCaseError(
      'A round in Settled status must have been committed and cannot have terms or subjects that are not settled.'
    );
  }
};
