import React, {FC} from 'react';
import {Upload, UploadProps} from 'antd';
import ImgCrop from 'antd-img-crop';

/**
 * This is a minimal wrapper around Ant Design's `Upload` component.
 * Its only purpose is to allow mocking via Jest.
 */
export const AntdUpload: FC<
  UploadProps & {
    modalTitle?: string;
  }
> = ({modalTitle, ...props}) => (
  <ImgCrop modalTitle={modalTitle}>
    <Upload {...props} />
  </ImgCrop>
);
