import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import {FC} from 'react';
import styled from 'styled-components';
import {Icon} from '../../../atoms/Icon';
import {WeatherDay as WeatherDayType} from '../../../components/WeatherWidget/utils/weatherUtils';
import {WeatherIcon} from './WeatherIcon';

export const WeatherDay: FC<{data: WeatherDayType}> = ({data}) => {
  if (!data) {
    return null;
  }

  const {temp, wind_speed: windSpeed, humidity, weather, dt} = data;
  const {description, id} = weather[0];
  const formatDay = dayjs.unix(dt).format('ddd. DD.MM.');

  return (
    <DayContainer>
      <Day>{formatDay}</Day>
      <Description>
        <WeatherIcon id={id} />
        {capitalize(description)}
      </Description>
      <Value>
        {Math.round(temp.min)} °C / {Math.round(temp.max)} °C
      </Value>
      <Value>
        <Icon size="small" type={'weather-wind'} />
        {Math.round(windSpeed)} KPH
      </Value>
      <Value>
        <Icon size="small" type={'weather-humidity'} />
        {humidity} %
      </Value>
    </DayContainer>
  );
};

const DayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px;

  &:nth-of-type(even) {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Day = styled.div`
  flex: 0 0 33%;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 0 0 67%;
  font-weight: bold;
  line-height: 1.1;
  white-space: pre;
  margin-bottom: 5px;
`;

const Value = styled.div`
  position: relative;
  flex: 1 0 33%;

  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    width: 1px;
    background: rgba(0, 0, 0, 0.2);
  }
`;
