import React, {createContext, useContext, useEffect, useState} from 'react';
import {blankState} from './blankstate';
import {MapContextValue, MapSettings, SeaboMapProps} from './Types';
import produce from 'immer';
import {useMapInstance} from './useMapInstance';

const InitialMapContextValue: MapContextValue = {
  state: blankState,
  setFullscreen: () => {},
  setSelectMode: () => {},
  setSelectedItems: () => {},
  setSetting: () => {},
  setSettings: () => {},
  setShowSideContent: () => {},
  setShowSettingView: () => {},
  setViewState: () => {},
  setMapSize: () => {},
  setMapReady: () => {},
  setSwitches: () => {},
  updateSwitches: () => {},
  fitBounds: () => {},
  setMapResolution: () => {},
  props: {},
};

const MapContext = createContext<MapContextValue>(InitialMapContextValue);

const MapContextProvider = (props: {
  children: React.ReactNode;
  seaboMapProps: SeaboMapProps;
  initialMapSettings: Partial<MapSettings> | undefined;
}) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const map = useMapInstance({
    isInitialized,
    mapApi: props.seaboMapProps.mapApi,
    onInitializationDone: () => setIsInitialized(true),
    initialMapSettings: props.initialMapSettings,
    initialViewState: props.seaboMapProps.initialViewState,
  });

  useEffect(() => {
    props.seaboMapProps.onViewStateChange?.(map.state.viewState);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [map.state.viewState]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    props.seaboMapProps.onSettingsChanged?.(map.state.settings);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [map.state.settings]);

  useEffect(() => {
    if (props.seaboMapProps.defaultVesselIconSizeMultiplicator) {
      map.setSettings(
        produce(map.state.settings, draftSettings => {
          draftSettings.vesselIconSizeMultiplicator = props.seaboMapProps.defaultVesselIconSizeMultiplicator!;
        })
      );
    }

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [props.seaboMapProps.defaultVesselIconSizeMultiplicator]);

  useEffect(() => {
    if (map.state.selectedItems.items.length > 0) {
      map.setShowSideContent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.state.selectedItems]);

  const value: MapContextValue = {
    ...map,
    props: props.seaboMapProps,
  };

  if (!isInitialized) {
    return null;
  }

  return <MapContext.Provider value={value}>{props.children}</MapContext.Provider>;
};

export const useMapContext = (): MapContextValue => {
  const value = useContext(MapContext);
  return value;
};

export {MapContext, MapContextProvider};
