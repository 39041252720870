import React, {Component} from 'react';
import {TODO} from '../../utils/TODO';
import {ValidateFunction} from '../../utils/Validator';

const emptyStringToNull = (obj: $TSFixMe) => {
  const o = JSON.parse(JSON.stringify(obj));
  Object.keys(o).forEach(key => {
    if (o[key] && typeof o[key] === 'object') {
      o[key] = emptyStringToNull(o[key]);
    } else if (o[key] === '') {
      o[key] = null;
    }
  });
  return o;
};

type OwnFormProviderProps = {
  onFormSubmit?: (formData: TODO, _?: TODO) => void;
  sectionMapping?: Record<string, TODO>;
  formData?: $TSFixMe;
  formValidationErrors?: $TSFixMe[];
};

type FormProviderState = $TSFixMe;

type FormProviderProps = OwnFormProviderProps &
  typeof FormProvider.defaultProps & {
    children: TODO;
  };

/** @deprecated use AntD Form instead */
class FormProvider extends Component<FormProviderProps, FormProviderState> {
  static defaultProps = {
    onFormSubmit: (_: TODO) => {},
  };

  fieldToSection: $TSFixMe;
  transformers: $TSFixMe;
  validators: $TSFixMe;

  constructor(props: FormProviderProps) {
    super(props);
    this.fieldToSection = props.sectionMapping
      ? Object.keys(props.sectionMapping).reduce((fieldToSection: Record<string, TODO>, section) => {
          props.sectionMapping![section].forEach((field: $TSFixMe) => {
            fieldToSection[field] = section;
          });
          return fieldToSection;
        }, {})
      : {};
    this.state = {
      defaults: {},
      form: props.formData ? this.transformFromApiToSection(props.formData) : {},
      validations: {},
    };
  }

  componentDidUpdate(prevProps: FormProviderProps, prevState: FormProviderState) {
    if (this.props.formData !== prevProps.formData) {
      const sectionData = this.transformFromApiToSection(this.props.formData);
      this.setState((state: $TSFixMe) => ({
        form: Object.keys(sectionData).reduce((form, section) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          form[section] =
            this.transformers[section] && typeof this.transformers[section].fromApi === 'function'
              ? {...state.defaults[section], ...this.transformers[section].fromApi(sectionData[section])}
              : {...state.defaults[section], ...sectionData[section]};

          return form;
        }, {}),
      }));
    }

    if (this.props.formValidationErrors !== prevProps.formValidationErrors) {
      const transformedErrors = this.transformApiErrorsToSection(this.props.formValidationErrors);
      const newValidations = Object.keys(transformedErrors).reduce((validations: Record<string, TODO>, section) => {
        validations[section] = {
          ...this.state.validations[section],
          ...transformedErrors[section],
        };
        return validations;
      }, {});

      if (prevState.validations && prevState.validations.stations && prevState.validations.stations.stations) {
        // hack for resetting these station errors
        this.setState((prev: $TSFixMe) => ({
          ...prev,

          validations: {
            ...prev.validations,
            stations: {
              ...prev.validations.stations,
              stations: {},
            },
            ...newValidations,
          },
        }));
      } else {
        this.setState((prev: $TSFixMe) => ({
          ...prev,

          validations: {
            ...prev.validations,
            ...newValidations,
          },
        }));
      }
    }
  }

  handleValueToField = (data: $TSFixMe, field: $TSFixMe) => {
    const {defaults} = this.state || {};

    if (data[field] || data[field] === 0 || data[field] === false) {
      return data[field];
    } else if (defaults && defaults[this.fieldToSection[field]]) {
      return defaults[this.fieldToSection[field]][field];
    }
    return '';
  };

  transformFromApiToSection = (data: Record<string, TODO> | null): Record<string, TODO> =>
    Object.keys(data ?? {}).reduce((section, field) => {
      if (this.fieldToSection[field]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        section[this.fieldToSection[field]] = {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...section[this.fieldToSection[field]],
          [field]: this.handleValueToField(data, field),
        };
      }
      return section;
    }, {});

  transformApiErrorsToSection = (apiErrors: $TSFixMe) =>
    apiErrors.reduce((section: $TSFixMe, error: $TSFixMe) => {
      const location = error.location.match(/(\w+)\[(\d+)]\.(\w+)\[?(\d+)?]?\.?(\w+)?/);
      const fieldName = (location && location[1]) || error.location;
      if (this.fieldToSection[fieldName]) {
        section[this.fieldToSection[fieldName]] = {
          ...section[this.fieldToSection[fieldName]],
          [fieldName]: location
            ? {
                ...((section[this.fieldToSection[fieldName]] && section[this.fieldToSection[fieldName]][fieldName]) ||
                  {}),
                [location[2]]: {
                  ...((section[this.fieldToSection[fieldName]] &&
                    section[this.fieldToSection[fieldName]][fieldName] &&
                    section[this.fieldToSection[fieldName]][fieldName][location[2]]) ||
                    {}),
                  [location[3]]: location[4]
                    ? {
                        ...((section[this.fieldToSection[fieldName]] &&
                          section[this.fieldToSection[fieldName]][fieldName] &&
                          section[this.fieldToSection[fieldName]][fieldName][location[2]] &&
                          section[this.fieldToSection[fieldName]][fieldName][location[2]][location[3]]) ||
                          {}),
                        [location[4]]: {
                          ...((section[this.fieldToSection[fieldName]] &&
                            section[this.fieldToSection[fieldName]][fieldName] &&
                            section[this.fieldToSection[fieldName]][fieldName][location[2]] &&
                            section[this.fieldToSection[fieldName]][fieldName][location[2]][location[3]] &&
                            section[this.fieldToSection[fieldName]][fieldName][location[2]][location[3]][
                              location[4]
                            ]) ||
                            {}),
                          [location[5]]: {error: error.message, invalid: true},
                        },
                      }
                    : {error: error.message, invalid: true},
                },
              }
            : {error: error.message, invalid: true},
        };
      }
      return section;
    }, {});

  runToApiTransformer = (sections: $TSFixMe) =>
    emptyStringToNull(
      Object.keys(sections).reduce(
        (toApi, section) => ({
          ...toApi,
          [section]:
            this.transformers[section] && typeof this.transformers[section].toApi === 'function'
              ? this.transformers[section].toApi(sections[section], sections)
              : sections[section],
        }),
        {}
      )
    );

  runFromApiTransformer = (section: $TSFixMe, defaults: $TSFixMe) => {
    return this.transformers[section] && typeof this.transformers[section].fromApi === 'function'
      ? {...defaults, ...this.transformers[section].fromApi(this.state.form[section])}
      : {...defaults, ...this.state.form[section]};
  };

  _handleChange = (section: $TSFixMe, fields: $TSFixMe, callback: $TSFixMe) => {
    this.setState(
      (prev: $TSFixMe) => ({
        ...prev,

        form: {
          ...prev.form,
          [section]: fields,
        },
      }),
      callback
    );
  };

  _handleSubmit = () => {
    return this.props.onFormSubmit(this.runToApiTransformer(this.state.form), this._resetForm);
  };

  _registerForm = (section: $TSFixMe, defaults: $TSFixMe, transformers: $TSFixMe, validators: $TSFixMe) => {
    this.transformers = {
      ...this.transformers,
      [section]: transformers,
    };

    this.validators = {
      ...this.validators,
      [section]: validators,
    };

    this.setState((prev: $TSFixMe) => ({
      ...prev,

      defaults: {
        ...prev.defaults,
        [section]: defaults,
      },

      form: {
        ...prev.form,
        [section]: (prev.form[section] && this.runFromApiTransformer(section, defaults)) || defaults,
      },

      validations: {
        ...prev.validations,
        [section]: Object.keys(defaults).reduce((valField, field) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          valField[field] = {invalid: false, error: ''};
          return valField;
        }, {}),
      },
    }));
  };

  _unRegisterForm = (section: $TSFixMe) => {
    delete this.transformers[section];

    this.setState((state: $TSFixMe) => ({
      defaults: {
        ...state.defaults,
        [section]: undefined,
      },

      form: {
        ...state.form,
        [section]: undefined,
      },

      validations: {
        ...state.validations,
        [section]: undefined,
      },
    }));
  };

  _resetForm = (callback: $TSFixMe) => {
    this.setState(
      (state: $TSFixMe) => ({
        ...state,

        form: Object.keys(state.form).reduce((acc, cur) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          acc[cur] = state.defaults[cur];
          return acc;
        }, {}),

        validations: Object.keys(state.validations).reduce((acc, cur) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          acc[cur] = Object.keys(state.defaults[cur]).reduce((valField, field) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            valField[field] = {invalid: false, error: ''};
            return valField;
          }, {});
          return acc;
        }, {}),
      }),
      callback
    );
  };

  _validateFields = (section: $TSFixMe, field: $TSFixMe) => {
    let isValid = true;
    if (section && field) {
      const error = this.validators[section][field](this.state.form[section][field], this.state.form[section]);
      isValid = !error;
      const validations = {
        ...this.state.validations,
        [section]: {
          ...this.state.validations[section],
          [field]: {
            invalid: !!error,
            error: error || '',
          },
        },
      };
      this.setState({validations});
    }

    return isValid;
  };

  _validateSections = (sections: $TSFixMe) => {
    let isValid = true;
    const validations = {
      ...this.state.validations,
      ...sections.reduce((newValidations: $TSFixMe, section: $TSFixMe) => {
        newValidations[section] = {
          ...this.state.validations[section],
          ...Object.keys(this.validators[section]).reduce((fields, field) => {
            if (typeof this.validators[section][field] === 'function') {
              const error = this.validators[section][field](this.state.form[section][field], this.state.form[section]);
              if (isValid) {
                isValid = !error;
              }
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              fields[field] = {
                invalid: !!error,
                error: error || '',
              };
            }
            return fields;
          }, {}),
        };
        return newValidations;
      }, {}),
    };
    this.setState({validations});

    return isValid;
  };

  _validateDynamicRows = (section: string, field: string, subField: string, index: number) => {
    const validateFunction: ValidateFunction = this.validators[section]['row'][subField];
    const valueOfSubField = this.state.form[section][field][index][subField];
    const sectionValues: Record<string, TODO> = this.state.form[section];

    let isValid = true;
    if (section && field && subField && typeof index !== 'undefined') {
      const error = validateFunction(valueOfSubField, sectionValues, field, index, subField);
      isValid = !error;
      const validations = {
        ...this.state.validations,
        [section]: {
          ...this.state.validations[section],
          [field]: {
            ...this.state.validations[section][field],
            [index]: {
              ...this.state.validations[section][field][index],
              [subField]: {
                invalid: !!error,
                error: error || '',
              },
            },
          },
        },
      };
      this.setState({validations});
    }

    return isValid;
  };

  render() {
    const {children} = this.props;
    const {defaults, form, validations} = this.state;
    return (
      <React.Fragment>
        {children({
          defaults,
          form,
          validations,
          resetForm: this._resetForm,
          onChange: this._handleChange,
          onSubmit: this._handleSubmit,
          registerForm: this._registerForm,
          unRegisterForm: this._unRegisterForm,
          validateFields: this._validateFields,
          validateSections: this._validateSections,
          validateDynamicRows: this._validateDynamicRows,
          runToApiTransformer: this.runToApiTransformer,
        })}
      </React.Fragment>
    );
  }
}

/** @deprecated use AntD Form instead */
export default FormProvider;
