import {FC} from 'react';
import {useGetSisterVesselsQuery} from './useGetSisterVesselsQuery';
import {IMOVesselGrid} from '../../../../components/IMOVesselGrid/IMOVesselGrid';
import {shipNameColumn} from '../../../../components/GridLayout/columns/vessel/shipNameColumn/shipNameColumn';
import {grainColumn} from '../../../../components/GridLayout/columns/vessel/grainColumn';
import {lengthColumn} from '../../../../components/GridLayout/columns/vessel/lengthColumn';
import {teuColumn} from '../../../../components/GridLayout/columns/vessel/teuColumn';
import {dwtColumn} from '../../../../components/GridLayout/columns/vessel/dwtColumn';
import {shipBuilderColumn} from '../../../../components/GridLayout/columns/vessel/shipBuilderColumn';

export const SisterVesselsTab: FC<{imo: number | null}> = ({imo}) => {
  const sisterVesselsQuery = useGetSisterVesselsQuery({imo});
  return (
    <IMOVesselGrid
      imos={sisterVesselsQuery.data ?? []}
      id={'sisterVessels'}
      columns={[
        shipNameColumn({options: {openAsNewTab: true}}),
        teuColumn({}),
        dwtColumn({}),
        grainColumn({}),
        lengthColumn({}),
        shipBuilderColumn({}),
      ]}
      unroundedTopLeft
      unroundedTopRight
    />
  );
};
