import dayjs, {Dayjs} from 'dayjs';
import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {dateEarlierThanOrEqualTo, stringLengthLessThanOrEqualTo} from '../../../../../utils/validators';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';

type DateString = string;

export interface DatabaseType {
  date: {
    from?: DateString;
    to?: DateString;
  };
  source?: string;
}

export interface InternalType {
  from: Dayjs | null;
  to: Dayjs | null;
  source: string;
}

export const Defaults: InternalType = {
  from: null,
  to: null,
  source: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    to: database.date.to ? dayjs(database.date.to) : null,
    from: database.date.from ? dayjs(database.date.from) : null,
    source: database.source ?? Defaults.source,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const database: DatabaseType = {
    date: {},
  };

  if (internal.source !== '') {
    database.source = internal.source;
  }

  if (internal.from) {
    database.date.from = internal.from.format(DateTimeFormat.IsoDate);
  }
  if (internal.to) {
    database.date.to = internal.to.format(DateTimeFormat.IsoDate);
  }

  return database;
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Fixture date',
  branch: 'fixtureDate',
  defaults: Defaults,

  validators: {
    from: dateEarlierThanOrEqualTo('to'),
    source: stringLengthLessThanOrEqualTo(100),
  },
  fromDatabase,
  toDatabase,
});
