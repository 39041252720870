import {Button} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {Attribute, AttributeContainer, AttributeValue} from '../../../atoms/Attribute/Attribute';
import {NegotiationItemsPreview} from './NegotiationItemsPreview';
import {
  NegotiationParty,
  NegotiationRoundResultSummary,
  NegotiationStatus,
  NegotiationSummary as NegotiationSummaryType,
} from '../../../api/node-backend/generated';
import {NegotiationReplyTime} from './ReplyTime/NegotiationReplyTime';
import {NegotiationLastReply} from './NegotiationLastReply';
import {NegotiationActorLabel} from './NegotiationActorLabel';
import {NegotiationRoundTermStatsChange} from './RoundTermStats/NegotiationRoundTermStatsChange';
import {NegotiationRoundStatsContainer} from './RoundTermStats/NegotiationRoundTermStats';
import {getNegotiationStatusLabel} from './getNegotiationStatusLabel';

const lock = '/static/icon/dashboard/lock.svg';
const unlocked = '/static/icon/dashboard/unlocked.svg';

type NegotiationSummaryProps = {
  negotiation: NegotiationSummaryType;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  isFirstRecapRound?: boolean;
  isSticky?: boolean;
  onToggleStickyClick?: () => void;
};

export const NegotiationSummary: FC<NegotiationSummaryProps> = ({
  negotiation,
  roundResultSummaries,
  isFirstRecapRound,
  isSticky,
  onToggleStickyClick,
}) => {
  const {party, creator, counterpart, roundCount, latestRound, cargo, vessel} = negotiation;
  const isCreator = party === NegotiationParty.Creator;
  const otherActor = isCreator ? counterpart : creator;
  const isOwnTurn = !latestRound.committedAt;

  return (
    <NegotiationSummaryContainer
      onClick={onToggleStickyClick}
      data-testid="NegotiationSummary"
      data-cy="NegotiationSummary">
      {onToggleStickyClick && (
        <ToggleButton data-cy="NegotiationHeaderCollapseToggle">
          <IconWrapper>
            <img src={isSticky ? unlocked : lock} alt="lock icon" />
          </IconWrapper>
        </ToggleButton>
      )}
      <AttributeBox>
        <Attribute tab="l" label={'Counterpart'} value={<NegotiationActorLabel actor={otherActor} />} />
        <Attribute tab="l" label="Round" value={roundCount || 'Not started yet'} />
        <Attribute
          tab="l"
          label={
            [NegotiationStatus.Settled, NegotiationStatus.Cancelled].includes(negotiation.status)
              ? 'Ended at'
              : 'Last counter'
          }
          value={<NegotiationLastReply roundResultSummaries={roundResultSummaries} format="dateTime" />}
        />
      </AttributeBox>
      <AttributeBox>
        <Attribute
          tab="m"
          label="Status"
          value={getNegotiationStatusLabel(negotiation.status, isOwnTurn, roundCount, isFirstRecapRound)}
        />
        <Attribute
          tab="m"
          label="Reply time"
          value={<NegotiationReplyTime negotiation={negotiation} roundResultSummaries={roundResultSummaries} />}
        />
        <Attribute
          tab="m"
          label="Changes"
          value={<NegotiationRoundTermStatsChange roundResultSummaries={roundResultSummaries} />}
        />
      </AttributeBox>
      <NegotiationItemsPreview cargo={cargo} vessel={vessel} />
    </NegotiationSummaryContainer>
  );
};

const NegotiationSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  margin-right: -10px;
`;

const AttributeBox = styled.div<{$isLarge?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 22.5%;
  line-height: 1.5;

  ${AttributeContainer} {
    width: 100%;
    margin: 2px 0;
  }

  ${AttributeValue} {
    width: 100%;
  }

  &:first-of-type {
    flex: 0 0 22.5%;
  }

  ${({$isLarge}) => $isLarge && 'flex: 0 0 40%;'}

  ${NegotiationRoundStatsContainer} {
    opacity: 0.75;
  }
`;

const ToggleButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  padding: 0 0 0 6px;
  margin: 0 -8px 0 2px;
  background: var(--color-gray-3) !important;
  border-radius: var(--border-radius-lg);
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
`;
