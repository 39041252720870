import {ApiError} from './ApiError';

/**
 * A UnauthorizedError error sent by the backend
 */
export class UnauthorizedError extends ApiError {
  name = 'UnauthorizedError';

  constructor(message: string) {
    super(message, 401);
  }
}
