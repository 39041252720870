import {Button, Checkbox, Form, Popover, Space} from 'antd';
import {DistanceCalculator} from '../utils/voyageChartercalculation/voyageChartercalculation';
import {SyncOutlined} from '@ant-design/icons';
import styled from 'styled-components';

export const DistanceCalcOptions = (props: {
  isLoadingRoutes?: boolean;
  distanceCalculatorValues: DistanceCalculator;
  onChange: (distanceCalculatorValues: Partial<DistanceCalculator>) => void;
  block?: boolean;
}) => {
  const {distanceCalculatorValues, block} = props;

  return (
    <Popover
      placement={'bottom'}
      title={<PopoverTitle>Route options</PopoverTitle>}
      trigger={['click']}
      content={
        <PopoverContent>
          <Form<DistanceCalculator>
            style={{
              margin: '0 0',
            }}
            onValuesChange={values => {
              props.onChange(values);
            }}
            initialValues={distanceCalculatorValues}>
            <Space direction="vertical">
              <Form.Item valuePropName={'checked'} name={'allowSuezCanal'} noStyle>
                <Checkbox data-cy="allowSuez">Allow Suez Canal</Checkbox>
              </Form.Item>
              <Form.Item valuePropName={'checked'} name={'allowPanamaCanal'} noStyle>
                <Checkbox data-cy="allowPanama">Allow Panama Canal</Checkbox>
              </Form.Item>
              <Form.Item valuePropName={'checked'} name={'allowKielCanal'} noStyle>
                <Checkbox data-cy="allowKiel">Allow Kiel Canal</Checkbox>
              </Form.Item>
              <Form.Item valuePropName={'checked'} name={'avoidSECA'} noStyle>
                <Checkbox data-cy="avoidSECA">Avoid ECA/minimise ECA</Checkbox>
              </Form.Item>
              <Form.Item valuePropName={'checked'} name={'avoidPiracyAreas'} noStyle>
                <Checkbox data-cy="avoidPiracy">Avoid Piracy Areas</Checkbox>
              </Form.Item>
            </Space>
          </Form>
        </PopoverContent>
      }>
      <Button
        data-cy="routeOptionsButton"
        type={'primary'}
        block={block}
        icon={props.isLoadingRoutes ? <SyncOutlined spin /> : undefined}>
        Route options
      </Button>
    </Popover>
  );
};

const PopoverTitle = styled.div`
  font-size: var(--font-size-lg);
  font-weight: normal;
  margin: 11px 0 12px 0;
`;
const PopoverContent = styled.div`
  margin: 10px 16px 16px 16px;
`;
