import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {LexicalTypeaheadMenuPlugin, MenuTextMatch} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {TextNode, LexicalEditor, LexicalCommand, createCommand} from 'lexical';
import {$createPlaceholderNode} from '../nodes/PlaceholderNode';
import {FC, useCallback, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import {PlaceholderMenu, PlaceholderOption} from '../PlaceholderMenu';
import {negotiationPlaceholderKeys, NegotiationPlaceholderKey} from '../../../utils/placeholderKeys';
import {useLexicalEditorFocusEvents} from '../utils/useLexicalEditorFocusEvents';

const SUGGESTION_LIST_LENGTH_LIMIT = 5;

export const PLACEHOLDER_TRIGGERS = ['{'];
const TRIGGERS = PLACEHOLDER_TRIGGERS.join('');
const END_TRIGGERS = ['}'].join('');
const VALID_CHARS = '[a-zA-Z0-9\\. ]';
const LENGTH_LIMIT = 50;

const PlaceholderRegex = new RegExp(
  `(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}){0,${LENGTH_LIMIT}}))[${END_TRIGGERS}]?$`
);

export const SHOW_MENU_COMMAND: LexicalCommand<boolean> = createCommand();
export const HIDE_MENU_COMMAND: LexicalCommand<boolean> = createCommand();

/**
 * Provides a menu that allows to search and insert placeholders into the text
 */
export const PlaceholderMenuPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);

  const [isVisible, setIsVisible] = useState(false);

  useLexicalEditorFocusEvents({
    onFocus: () => setIsVisible(true),
    onBlur: () => setIsVisible(false),
  });

  const options = useMemo(() => {
    return Object.entries(negotiationPlaceholderKeys)
      .filter(
        ([key, label]) =>
          !queryString ||
          key.toLowerCase().includes(queryString.toLowerCase()) ||
          label.toLowerCase().includes(queryString.toLowerCase())
      )
      .map(([key]) => new PlaceholderOption(key as NegotiationPlaceholderKey))
      .slice(0, queryString ? SUGGESTION_LIST_LENGTH_LIMIT : undefined)
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [queryString]);

  const onSelectOption = useCallback(
    (selectedOption: PlaceholderOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(
        () => {
          const placeholderNode = $createPlaceholderNode(selectedOption.key);
          if (nodeToReplace) {
            nodeToReplace.replace(placeholderNode);
          }
          placeholderNode.selectEnd();
          closeMenu();
        },
        {
          onUpdate: () => {
            editor.dispatchCommand(HIDE_MENU_COMMAND, true);
          },
        }
      );
    },
    [editor]
  );

  return (
    <LexicalTypeaheadMenuPlugin<PlaceholderOption>
      commandPriority={4}
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForPlaceholders}
      options={options}
      menuRenderFn={(anchorElementRef, {selectedIndex, selectOptionAndCleanUp, setHighlightedIndex}) =>
        anchorElementRef.current && isVisible && options.length
          ? createPortal(
              <PlaceholderMenu
                options={options}
                selectedIndex={selectedIndex}
                onSelectOption={(idx, option) => {
                  setHighlightedIndex(idx);
                  selectOptionAndCleanUp(option);
                }}
                onHoverOption={idx => {
                  setHighlightedIndex(idx);
                }}
              />,
              anchorElementRef.current
            )
          : null
      }
    />
  );
};

const checkForPlaceholders = (text: string, editor: LexicalEditor): MenuTextMatch | null => {
  const match = PlaceholderRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];
    if (matchingString.length >= 0) {
      editor.dispatchCommand(SHOW_MENU_COMMAND, true);
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  editor.dispatchCommand(HIDE_MENU_COMMAND, true);
  return null;
};
