import diff from 'deep-diff';
import {PortfolioVessel} from '../../../../../redux/Portfolio';
import {assertUnreachable} from '../../../../../utils/assert';

export const filterIntakesFromDiff = ({
  diffs,
  returns,
}: {
  diffs: diff.DiffEdit<PortfolioVessel, PortfolioVessel>[];
  returns: 'onlyIntake' | 'withoutIntake';
}): diff.DiffEdit<PortfolioVessel, PortfolioVessel>[] => {
  return diffs.filter(diff => {
    const isPartOfIntake = (diff.path?.[0] ?? '') === 'intakes';
    switch (returns) {
      case 'onlyIntake':
        return isPartOfIntake;
      case 'withoutIntake':
        return !isPartOfIntake;
      default:
        assertUnreachable(returns);
        return false;
    }
  });
};
