import Icon from '../../atoms/Icon';

const getType = (indexName: $TSFixMe) => {
  switch (indexName) {
    case 'ports':
      return ['port', 'Ports'];
    case 'vessels_market':
      return ['markets', 'Market vessels'];
    case 'vessels_portfolio':
      return ['portfolio', 'Portfolio vessels'];
    default:
      return ['unknown', ''];
  }
};

type GroupHeaderProps = {
  children: string;
};

const GroupHeader = ({children}: GroupHeaderProps) => {
  const [iconType, title] = getType(children);
  if (children === 'overviewPage') {
    return null;
  }
  return (
    <div className={'stations-select-header'}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'IconType'... Remove this comment to see the full error message */}
      <Icon type={iconType} />
      <span className={'stations-select-header__text'}>{title}</span>
    </div>
  );
};

export default GroupHeader;
