import {useQuery} from '@tanstack/react-query';
import {negotiationNegotiationTermHistoryApi} from '../../../../../../../api/node-backend/nodeBackendApis';

export const GET_TERM_HISTORY_QUERY_KEY = (termId: string) => [
  'negotiationNegotiationTermHistoryApi.getTermHistory',
  termId,
];

export const useGetTermHistoryQuery = (termId: string) =>
  useQuery({
    queryKey: GET_TERM_HISTORY_QUERY_KEY(termId),
    queryFn: async () => {
      const response = await negotiationNegotiationTermHistoryApi.getTermHistory({termId});
      return response;
    },
  });
