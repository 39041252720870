import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {SELECTION_CHANGE_COMMAND, $getSelection, COMMAND_PRIORITY_HIGH} from 'lexical';
import {useLayoutEffect} from 'react';

/**
 * Prevents the user from selecting the term type node or moving the cursor in front of it
 */
export const TermTypePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const currentNode = $getSelection()?.getNodes()[0];
        if (currentNode?.getType() === 'termType') {
          currentNode.selectEnd();
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_HIGH
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
