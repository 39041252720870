import {ApiServiceEntry, makeApiService} from './ApiService';
import {TODO} from '../../utils/TODO';

const entries: ApiServiceEntry[] = [
  {
    fn: 'post',
    route: '/api/cargoes',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'validate',
    route: '/api/cargoes/validate',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'patch',
    route: '/api/cargoes/{cargoId}',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'getCargo',
    route: '/api/cargoes/{id}',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'deleteCargo',
    route: '/api/cargoes/{cargoId}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'multiPatch',
    route: '/api/cargoes/multiPatch',
    method: 'PUT',
    storeData: false,
  },
  {
    fn: 'workspaceUpdate',
    route: '/api/cargoes/workspaceUpdate/{cargoId}',
    method: 'PUT',
  },
  {
    fn: 'createMultipleCargoes',
    route: '/api/cargoes/multiCreate',
    method: 'POST',
  },
];

const cargoApi = makeApiService(entries, {apiName: 'cargoApi'});
export const reducers = cargoApi.reducers;
export const actions = cargoApi.actions;
export const actionTypes = cargoApi.actionTypes;

export const CargoServiceActions = {
  getCargo: (id: TODO) => actions.getCargo({params: {id}}),
  createCargo: (body: TODO) => actions.post({body}),
  patch: (cargoId: TODO, body: TODO) => actions.patch({params: {cargoId}, body}),
  deleteCargo: (cargoId: TODO) => actions.deleteCargo({params: {cargoId}}),
  multiPatch: (body: TODO) => actions.multiPatch({body}),
  workspaceUpdate: (cargoId: TODO, workspaceId: TODO) =>
    actions.workspaceUpdate({params: {cargoId}, body: workspaceId}),
  multiCreate: (cargoes: TODO, workspace: TODO, subPortfolio: TODO) =>
    actions.createMultipleCargoes({body: {cargoes, workspace, subPortfolio}}),
};

export default CargoServiceActions;
