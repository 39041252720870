import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {BLUR_COMMAND, COMMAND_PRIORITY_CRITICAL} from 'lexical';
import {FC, RefObject, useEffect, useLayoutEffect, useState} from 'react';

type ExtendBlurTargetPluginProps = {
  containerRef: RefObject<HTMLElement>;
};

/**
 * Extends the area that the Lexical Editor registers blur commands for to the given ref
 */
export const ExtendBlurTargetPlugin: FC<ExtendBlurTargetPluginProps> = ({containerRef}) => {
  const [editor] = useLexicalComposerContext();
  const [isBlurBlocked, setIsBlurBlocked] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const handleMouseDown = (event: MouseEvent) => {
      if (
        container.contains(event.target as Node) ||
        document.querySelector('#typeahead-menu')?.contains(event.target as Node)
      ) {
        setIsBlurBlocked(true);
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      if (
        container.contains(event.target as Node) ||
        document.querySelector('#typeahead-menu')?.contains(event.target as Node)
      ) {
        setIsBlurBlocked(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [containerRef]);

  useLayoutEffect(() => {
    return editor.registerCommand(
      BLUR_COMMAND,
      () => {
        if (isBlurBlocked) {
          setIsBlurBlocked(false);
          // We need to return true here to prevent the Lexical Editor from handling the blur event
          return true;
        }
        return false;
      },
      // We need to use a high priority to make sure that this command is executed before the Lexical Editor's blur command
      COMMAND_PRIORITY_CRITICAL
    );
  });

  return null;
};
