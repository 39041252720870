import {useState, FC} from 'react';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {FilterCategory, FilterItem, FilterType} from '../../api/symfony/generated';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {VesselDatabaseActions} from '../../redux/VesselDatabase';
import {newSortKey} from '../../components/TabList/newSortKey';
import {SaveFilterModal} from '../../components/Modal/SaveFilters/SaveFilterModal';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {useQueryClient} from '@tanstack/react-query';
import {invalidFiltersQuery} from '../../queries/filter/useFiltersQuery';
import {
  VesselDatabaseDatabaseFilter,
  VesselDatabaseFilterBranchDefinitions,
} from '../../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';

export const SaveFilterButton: FC<{
  disabled: boolean;
  currentFilterSettings: VesselDatabaseDatabaseFilter | null;
  filterProviderApi: FilterProviderApi<typeof VesselDatabaseFilterBranchDefinitions>;
  activeTabId: number | 'all';
  currentFilterObject: FilterItem | undefined;
}> = ({disabled, currentFilterObject, activeTabId, filterProviderApi, currentFilterSettings}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const filters: FilterItem[] = useSelector(state => state.vesselDatabase.filters);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  if (disabled) {
    return null;
  }

  const loadFilterItem = (filter: FilterItem) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    filterProviderApi.loadFilterItem(filter, {
      onFilterSettingsChangeEnabled: false,
    });
  };

  return (
    <>
      <ScreenHeader.Features.Button
        data-cy="VesselDbSaveFiltersBTN"
        type="primary"
        onClick={() => {
          setModalVisible(true);
        }}>
        Save filters
      </ScreenHeader.Features.Button>
      <SaveFilterModal
        visible={modalVisible}
        createOrUpdateModal={{
          title: 'Save filters',
          description: (
            <>
              Do you want to update the current filter "{currentFilterObject?.name}"
              <br />
              or do you want to create a new one?
            </>
          ),
        }}
        createModal={{
          title: 'Create filter',
          description: <>You can save your filter and then access it from the tab bar.</>,
          namePlaceholder: 'Name for the new filter',
        }}
        filterSettings={currentFilterSettings}
        category={FilterCategory.Vesseldatabase}
        type={FilterType.Vessel}
        nextSortKey={newSortKey(filters)}
        currentFilterObject={currentFilterObject}
        openAsNew={activeTabId === 'all'}
        onCreateSuccess={createdFilter => {
          dispatch(VesselDatabaseActions.addFilter(createdFilter));
          dispatch(VesselDatabaseActions.setActiveFilterId(createdFilter.id));
          loadFilterItem(createdFilter);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          invalidFiltersQuery(queryClient);
        }}
        onUpdateSuccess={updatedFilter => {
          dispatch(VesselDatabaseActions.updateFilter(updatedFilter));
          loadFilterItem(updatedFilter);
        }}
        onClose={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};
