import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import {isApiErrorWithStatus} from '../api/utils/ApiError';
import {UseQueryOptions} from '../api/utils/ReactQuery';
import {
  GetVesselDetailsResponseSchema,
  VesselDetailsVessel,
} from '../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {fetchJSON} from '../api/utils/fetchJSON';
import {assert} from '../utils/assert';

export const getGetVesselQueryKey = (vesselId: number | undefined) => ['getVessel', vesselId];

export const getVesselByVesselId = async (vesselId: number): Promise<VesselDetailsVessel | null> => {
  assert(vesselId !== undefined, 'vesselId must be defined');
  const url = `/api/vessels/${vesselId}`;
  let vessel;
  try {
    vessel = await fetchJSON<VesselDetailsVessel>(url);
  } catch (error) {
    if (isApiErrorWithStatus(error, 404)) {
      return null;
    }
    throw error;
  }
  // Validate vessel
  return GetVesselDetailsResponseSchema.parse(vessel);
};

export const useGetVesselQuery = ({
  vesselId,
  options,
}: {
  vesselId: number | undefined;
  options?: Omit<UseQueryOptions<VesselDetailsVessel | null>, 'queryKey'>;
}): UseQueryResult<VesselDetailsVessel | null> =>
  useQuery({
    queryKey: getGetVesselQueryKey(vesselId),
    queryFn: async () => {
      return getVesselByVesselId(vesselId!);
    },
    ...options,
  });

export const invalidateGetVesselQuery = async (queryClient: QueryClient, vesselId: number) => {
  await queryClient.invalidateQueries({
    queryKey: getGetVesselQueryKey(vesselId),
  });
};
