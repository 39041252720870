import {useQuery} from '@tanstack/react-query';
import {companyApi} from '../../../../api/node-backend/nodeBackendApis';

export const useGetCompanyApiKeyQuery = (companyId: number) => {
  return useQuery({
    queryKey: ['companyApi.getCompanyApiKey', companyId],
    queryFn: () => {
      return companyApi.getCompanyApiKey({
        companyId,
      });
    },
  });
};
