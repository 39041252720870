import {AISRecord} from './AISRecord';
import {useMemo} from 'react';

export const useAisRecordsCsvRowMapping = (aisRecords: AISRecord[]) => {
  const aisRecordRowToAisRecord: Record<number, AISRecord> = useMemo(() => {
    const entries: [number, AISRecord][] = aisRecords.map(aisRecord => [aisRecord.csvRow, aisRecord]);
    const map: Record<number, AISRecord> = Object.fromEntries(entries);
    return map;
  }, [aisRecords]);
  return aisRecordRowToAisRecord;
};
