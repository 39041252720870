import {assertUnreachable} from '../../../utils/assert';
import {OfferBucket} from '../../../api/symfony/generated/models';
import {OfferType} from './transformBucketsToTimeSeries';

export const getValueOfBucket = ({bucket, offerType}: {bucket: OfferBucket; offerType: OfferType}) => {
  switch (offerType) {
    case 'vessel':
      return bucket.numberOfVesselOffers ?? 0;
    case 'cargo':
      return bucket.numberOfCargoOffers ?? 0;
    case 'all':
      return bucket.numberOfOffers ?? 0;
    default:
      assertUnreachable(offerType);
  }
};
