import dayjs, {Dayjs} from 'dayjs';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {getTerm} from '../../../utils/updatesWaitingtimesOnInputstate/calcNewInputState';
import {checkIsWorking} from '../../../utils/updatesWaitingtimesOnInputstate/checkIsWorking';
import {getInitialWaitingTimeBasedOnLaycan} from '../../../utils/updatesWaitingtimesOnInputstate/getInitialWaitingTimeBasedOnLaycan';
import {CalcTimeLinePoint} from '../../VoyageTypes';
import {DateTimeFormat} from '../../../../../../utils/DateTimeFormat';

type Params = {
  laycanFrom: Dayjs | undefined;
  point: CalcTimeLinePoint;
};

export type ExplainDurationItem = {date: string; working: boolean; reason?: 'watingForLaycan' | 'holidayOrFree'};

export const useDurationAtPortExplainQuery = ({point, laycanFrom}: Params) => {
  const queryClient = useQueryClient();

  const start = dayjs(point.outputItem?.startDate);
  const end = dayjs(point.outputItem?.endDate);

  return useQuery({
    queryKey: ['durationInPortExplainedPerDate', start, end, point.outputItem?.duration, point.item.portId],
    queryFn: async (): Promise<ExplainDurationItem[]> => {
      const workingOnDay: ExplainDurationItem[] = [];
      let date = start.clone();

      if (point.outputItem?.duration === 0) {
        return [];
      }
      // wait for laycan
      let initialWaiting = Math.floor(
        getInitialWaitingTimeBasedOnLaycan({
          startDate: date,
          laycanFrom: laycanFrom,
          isFirstLoadingLeg: point.isFirstLoadingLeg,
        }) / 24
      );

      while (initialWaiting > 0) {
        workingOnDay.push({
          date: date.format(DateTimeFormat.IsoDate),
          working: false,
          reason: 'watingForLaycan',
        });
        date = date.add(1, 'day');
        initialWaiting = initialWaiting - 1;
      }

      if (date.isSame(end)) {
        const working = await checkIsWorking({
          date,
          isMorning: false,
          queryClient,
          countryCode: point.item.countryCode,
          term: getTerm(point.item) ?? 'SHINC',
        });
        return [
          {
            working,
            date: date.format(DateTimeFormat.IsoDate),
            reason: working ? undefined : 'holidayOrFree',
          },
        ];
      }
      // waiting between working
      while (date.isSameOrBefore(end, 'date')) {
        const working = await checkIsWorking({
          date,
          isMorning: false,
          queryClient,
          countryCode: point.item.countryCode,
          term: getTerm(point.item) ?? 'SHINC',
        });
        workingOnDay.push({
          date: date.format(DateTimeFormat.IsoDate),
          working,
          reason: working ? undefined : 'holidayOrFree',
        });
        date = date.add(1, 'day');
      }
      return workingOnDay;
    },
  });
};
