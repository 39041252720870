import {Vessel} from '../useMyFleetQuery/useMyFleetQuery';
import {getNextOpen} from './parts/getNextOpen';
import {getShipBuilder} from './parts/getShipBuilder';
import {tabsTime} from './utils';
import {getVesselType} from './parts/getVesselType';
import {TODO} from '../../../../../utils/TODO';
import {getConsumption} from './parts/getConsumption';
import {getGears} from './parts/getGears';
import {getDraftAndDwat} from './parts/getDraftAndDwat';
import {getLoaBeam} from './parts/getLoaBeam';

export const getPositionStringBulker = (vessel2: Vessel): string => {
  const nextOpen = getNextOpen(vessel2);
  const shipBuilder = getShipBuilder(vessel2);

  const vessel = vessel2 as TODO;
  const consumption = getConsumption(vessel);

  return `
MV ${vessel.name} - open ${nextOpen}
${vessel.vesselType}, built ${vessel.builtYear ?? ''} at ${vessel.shipBuilder ?? 'n/A'}, ${vessel.countryFlag} flag
${getDraftAndDwat(vessel)}
grain/bale about ${vessel.grain ?? 'n/A'} cbm / ${vessel.bale ?? 'n/A'} cbm
${getLoaBeam(vessel)}
${vessel.holdsAmount ?? 'n/A'} ho / ${vessel.hatchesAmount ?? 'n/A'} ha${getGears(vessel)}
gt about ${vessel.grossTonnage} / nt about ${vessel.netTonnage}
${consumption}
all details about – wog
`;
  return `
Name: ${vessel.name}${tabsTime(10)}${nextOpen}
${getVesselType(vessel)} ${shipBuilder}

`;
};
