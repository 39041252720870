import {Button} from 'antd';
import React, {FC, useState} from 'react';
import styled from 'styled-components';
import {NewTerm} from '../../../../../../../api/node-backend/generated';
import {TermParserModal} from './TermParserModal';

type TermParserModalButtonProps = {
  onCreateTerms: (terms: NewTerm[]) => void;
};

export const TermParserModalButton: FC<TermParserModalButtonProps> = ({onCreateTerms}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <TermParserModal open={showModal} onClose={() => setShowModal(false)} onSuccess={onCreateTerms} />
      <ButtonContainer>
        <Button type="primary" onClick={() => setShowModal(true)}>
          Paste & parse contract
        </Button>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0;
`;
