import React, {FC} from 'react';
import {getDataFieldId} from '../../utils/calcWarnings/calcWarnings';
import {Input, InputProps} from 'antd';

export interface InputWithDataFieldIdProps extends InputProps {
  area: string;
  field: string;
}

export const InputWithDataFieldId: FC<InputWithDataFieldIdProps> = ({area, field, ...props}) => {
  return (
    <Input
      size="small"
      data-field-id={getDataFieldId({
        field: field,
        area: area,
      })}
      {...props}
    />
  );
};
