import {InputState, voyageChartercalculation} from '../voyageChartercalculation/voyageChartercalculation';
import produce from 'immer';
import {calcPointLoadDisDuration} from '../voyageChartercalculation/calcPointDuration';
import {VoyagePoint} from '../../VoyageInformation/VoyageTypes';
import {calcTangentialHalvesOfTheDayAndWaitingTime} from './calcTangentialHalvesOfTheDayAndWaitingTime';
import {QueryClient} from '@tanstack/react-query';

/**
 * Selects the term relevant for the calculation if loading is done at the port,
 * then the loading terms otherwise the discharge terms.
 * @param voyagePoint
 */
export const getTerm = (voyagePoint: VoyagePoint) => {
  if (voyagePoint.types.includes('loading')) {
    return voyagePoint.termsLoading;
  }
  return voyagePoint.termsDischarging;
};

/**
 * Calculates the new input state by determining the new waiting times of the points.
 * Starting with the passed firstIndex up to the last point.
 *
 * @param inputState - The State at the time when the change occurred
 * @param firstIndex - First index at which a recalculation is required.
 */
export const calcNewInputState = async ({
  inputState,
  firstIndex,
  queryClient,
}: {
  inputState: InputState;
  firstIndex: number;
  queryClient: QueryClient;
}): Promise<{inputState: InputState; change: boolean}> => {
  let change = false;

  const newInputState = await produce(inputState, async draftInputState => {
    let outputState = voyageChartercalculation(draftInputState);
    const indexFirstLoadingLeg = inputState.voyage.points.findIndex(point => point.types?.includes('loading'));

    for (let index = firstIndex; index < draftInputState.voyage.points.length; index++) {
      const inputPoint = draftInputState.voyage.points[index];
      const outputPoint = outputState.voyage.points[index];
      const oldEndDate = outputPoint.endDate;

      const {durationLoadDis} = calcPointLoadDisDuration({voayagePoint: inputPoint});
      let totalDuration = durationLoadDis;
      if (inputPoint.turnTimeInHours !== undefined && !isNaN(inputPoint.turnTimeInHours)) {
        totalDuration += inputPoint.turnTimeInHours / 24;
      }

      const startDate = outputPoint.startDate;

      if (inputPoint.waitingDurationInHours === undefined) {
        const daysAtWork = await calcTangentialHalvesOfTheDayAndWaitingTime({
          term: getTerm(inputPoint) ?? 'SHINC',
          totalDuration,
          startDate,
          laycanFrom: inputState.voyage.laycanFrom,
          isFirstLoadingLeg: index === indexFirstLoadingLeg,
          countryCode: inputPoint.countryCode,
          queryClient,
        });
        inputPoint.waitingDurationInHours = daysAtWork.waitingTime;
      }

      outputState = voyageChartercalculation(draftInputState);

      if (outputState.voyage.points[index].endDate !== oldEndDate) {
        change = true;
      }
    }
  });
  return {inputState: newInputState, change};
};
