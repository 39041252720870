import React, {FC} from 'react';
import {TermTypeIcon} from './TermTypeIcon';
import {NegotiationTermType} from '../../../../../../../api/node-backend/generated';
import {termTypeLabels} from './termTypeLabels';
import styled, {css} from 'styled-components';

type TermTypeLabelProps = {
  termType: NegotiationTermType | null;
  className?: string;
};

export const TermTypeLabel: FC<TermTypeLabelProps> = ({termType, className}) => {
  if (!termType) {
    return null;
  }

  return (
    <TermTypeLabelContainer className={className} $showIcon={termType !== null}>
      <StyledTermTypeIcon termType={termType} size="small" />
      <TermTypeLabelText>{termType ? termTypeLabels[termType] + ' ' : ''}</TermTypeLabelText>
    </TermTypeLabelContainer>
  );
};

export const TermTypeLabelContainer = styled.span<{$showIcon?: boolean}>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  line-height: 1;

  ${({$showIcon}) =>
    !$showIcon &&
    css`
      gap: 0;
      ${StyledTermTypeIcon} {
        display: none;
      }
    `}
`;

const StyledTermTypeIcon = styled(TermTypeIcon)`
  position: relative;
  top: -1px;
`;

export const InlineTermTypeLabel = styled(TermTypeLabel)`
  display: inline;
  height: 1em;
  margin-left: -2px;
  margin-right: 4px;
  font-size: inherit;
  line-height: inherit;
  font-weight: bold;
  vertical-align: baseline;

  ${StyledTermTypeIcon} {
    position: relative;
    top: 1px;
    margin-right: 3px;
  }
`;

export const TermTypeLabelText = styled.span``;
