import {makeApiService, ApiServiceEntry} from './ApiService';
import {TODO} from '../../utils/TODO';

type Id = number;
type Type = TODO;
type Types = TODO;
type Body = TODO;

const entries: ApiServiceEntry[] = [
  {
    fn: 'getAllProjects',
    route: '/api/projects',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getProject',
    route: '/api/projects/{id}',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getShortlistVessels',
    route: '/api/projects/{id}/vessels',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getShortlistCargoes',
    route: '/api/projects/{id}/cargoes',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'getProjectComments',
    route: '/api/projects/{id}/comments',
    method: 'GET',
    storeData: false,
  },
  {
    fn: 'addProjectComment',
    route: '/api/projects/{id}/comments',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'removeProjectComment',
    route: '/api/projects/{id}/comments/{commentId}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'updateProjectComment',
    route: '/api/projects/{id}/comments/{commentId}',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'updateShortlistItem',
    route: '/api/projects/{id}/{type}/{shortlistItemId}',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'updateProject',
    route: '/api/projects/{id}',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'updateProjectList',
    route: '/api/projects/{id}',
    method: 'PATCH',
    storeData: false,
  },
  {
    fn: 'uploadProjectDocuments',
    route: '/api/attachments/project-document',
    method: 'POST',
    isFormData: true,
  },
  {
    fn: 'addToProjects',
    route: '/api/projects/{id}',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'createProject',
    route: '/api/projects',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'addToShortlist',
    route: '/api/projects/{id}/{type}',
    method: 'POST',
    storeData: false,
  },
  {
    fn: 'deleteFromShortlist',
    route: '/api/projects/{id}/{type}/{shortlistItemId}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'deleteProject',
    route: '/api/projects/{id}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'deleteProjectDocument',
    route: '/api/projects/{id}/document/{documentUuid}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'addTypeToProject',
    route: '/api/projects/{id}/{types}',
    method: 'POST',
    storeData: false,
  },
];

const project = makeApiService(entries, {apiName: 'project'});

export const reducers = project.reducers;
export const actions = project.actions;
export const actionTypes = project.actionTypes;

export type ProjectType = 'vessel' | 'cargo';

export const Actions = {
  getAllProjects: (
    pageIndex = 1,
    pageSize = 5,
    type: ProjectType | null = null,
    isArchived: number | undefined = undefined
  ) => actions.getAllProjects({queryParams: {pageIndex, pageSize, type, isArchived}}),

  getProject: (id: Id) => actions.getProject({params: {id}}),
  getShortlistVessels: (id: Id) => actions.getShortlistVessels({params: {id}, queryParams: {pageSize: 1000}}),
  getShortlistCargoes: (id: Id) => actions.getShortlistCargoes({params: {id}, queryParams: {pageSize: 1000}}),
  getProjectComments: (id: Id) => actions.getProjectComments({params: {id}, queryParams: {pageSize: 1000}}),

  addToShortlist: (id: Id, type: Type, body: Body) => actions.addToShortlist({params: {id, type}, body}),
  addProjectComment: (id: Id, body: Body) => actions.addProjectComment({params: {id}, body}),
  addToProjects: (id: Id) => actions.addToProjects({params: {id}}),
  addTypeToProject: (id: Id, types: Types, body: Body) => actions.addTypeToProject({params: {id, types}, body}),

  createProject: (body: Body) => actions.createProject({body}),
  uploadProjectDocuments: (body: Body) => actions.uploadProjectDocuments({body}),

  updateShortlistItem: (id: Id, type: Type, shortlistItemId: TODO, body: Body) =>
    actions.updateShortlistItem({params: {id, type, shortlistItemId}, body}),
  updateProjectComment: (id: Id, commentId: TODO, body: Body) =>
    actions.updateProjectComment({params: {id, commentId}, body}),
  updateProject: (id: Id, body: Body) => actions.updateProject({params: {id}, body}),
  updateProjectList: (id: Id, body: Body) => actions.updateProjectList({params: {id}, body}),

  deleteProject: (id: Id) => actions.deleteProject({params: {id}}),
  removeProjectComment: (id: Id, commentId: TODO) => actions.removeProjectComment({params: {id, commentId}}),
  deleteProjectDocument: (id: Id, documentUuid: string) => actions.deleteProjectDocument({params: {id, documentUuid}}),
  deleteFromShortlist: (id: Id, type: Type, shortlistItemId: TODO) =>
    actions.deleteFromShortlist({params: {id, type, shortlistItemId}}),
};

export default Actions;
