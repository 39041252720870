import ProjectActions from '../../redux/ApiService/ProjectService';
import {usePushMessages} from './usePushMessages';
import {FC} from 'react';
import {invalidateNotificationsQuery} from '../../queries/useNotificationsQuery';
import {useStore} from '../../redux/react-redux';
import {useQueryClient} from '@tanstack/react-query';

export const ServerPushSubscriptions: FC = () => {
  useProjectsListSubscription();
  useNotificationsSubscription();
  return null;
};

const useProjectsListSubscription = () => {
  const store = useStore();
  usePushMessages('com.seabo.project', () => {
    store.dispatch(ProjectActions.getAllProjects());
  });
};

const useNotificationsSubscription = () => {
  const queryClient = useQueryClient();
  usePushMessages('com.seabo.notifications.new', () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    invalidateNotificationsQuery(queryClient);
  });
};
