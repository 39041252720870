import {useMemo, FC} from 'react';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import sumBy from 'lodash/sumBy';
import {NumberStatistics} from './NumberStatistics';

export const TiedUpDWT: FC<{
  currentBulkerVisits: MergedPortCongestionVisit[];
}> = ({currentBulkerVisits}) => {
  const boundedDwt = useMemo(() => {
    return sumBy(currentBulkerVisits, visit => visit.dwtSummer ?? 0);
  }, [currentBulkerVisits]);

  return <NumberStatistics title={'Tied up DWT'} suffix={'MT'} value={boundedDwt} />;
};
