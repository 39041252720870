import {CargoInput} from './CargoTypes';
import {convertCftToCbm} from './convertCftToCbm';

/**
 * Returns capacity of cargo in cubic meters.
 */
export const getCargoCapacity = (cargo: CargoInput): number => {
  const quantity = cargo.cargoQuantity ?? 0;
  const stowageFactor = cargo.stowageFactor ?? 1;
  const stowageFactorDisplayUnit = cargo.stowageFactorDisplayUnit ?? 'cbm';

  if (stowageFactorDisplayUnit === 'cft') {
    const capacityInCft = quantity * stowageFactor;

    return convertCftToCbm(capacityInCft);
  } else {
    return quantity * stowageFactor;
  }
};
