/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {OfferTag, OfferTagFromJSON, OfferTagFromJSONTyped, OfferTagToJSON} from './';

/**
 * A set of cargo and vessel offers that share some characteristics expressed by tags.
 * @export
 * @interface OfferBucket
 */
export interface OfferBucket {
  /**
   * Tags that describe the characteristics of this bucket.
   * @type {Array<OfferTag>}
   * @memberof OfferBucket
   */
  tags?: Array<OfferTag>;
  /**
   * IDs of cargo offers in this bucket.
   * @type {Set<number>}
   * @memberof OfferBucket
   */
  cargoOfferIds?: Set<number>;
  /**
   * IDs of vessel offers in this bucket.
   * @type {Set<number>}
   * @memberof OfferBucket
   */
  vesselOfferIds?: Set<number>;
  /**
   * The number of cargo offers in this bucket.
   * @type {number}
   * @memberof OfferBucket
   */
  numberOfCargoOffers?: number;
  /**
   * The number of vessel offers in this bucket.
   * @type {number}
   * @memberof OfferBucket
   */
  numberOfVesselOffers?: number;
  /**
   * The number of cargo and vessel offers in this bucket.
   * @type {number}
   * @memberof OfferBucket
   */
  numberOfOffers?: number;
}

export function OfferBucketFromJSON(json: any): OfferBucket {
  return OfferBucketFromJSONTyped(json, false);
}

export function OfferBucketFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferBucket {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tags: !exists(json, 'tags') ? undefined : (json['tags'] as Array<any>).map(OfferTagFromJSON),
    cargoOfferIds: !exists(json, 'cargoOfferIds') ? undefined : json['cargoOfferIds'],
    vesselOfferIds: !exists(json, 'vesselOfferIds') ? undefined : json['vesselOfferIds'],
    numberOfCargoOffers: !exists(json, 'numberOfCargoOffers') ? undefined : json['numberOfCargoOffers'],
    numberOfVesselOffers: !exists(json, 'numberOfVesselOffers') ? undefined : json['numberOfVesselOffers'],
    numberOfOffers: !exists(json, 'numberOfOffers') ? undefined : json['numberOfOffers'],
  };
}

export function OfferBucketToJSON(value?: OfferBucket | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tags: value.tags === undefined ? undefined : (value.tags as Array<any>).map(OfferTagToJSON),
    cargoOfferIds: value.cargoOfferIds,
    vesselOfferIds: value.vesselOfferIds,
    numberOfCargoOffers: value.numberOfCargoOffers,
    numberOfVesselOffers: value.numberOfVesselOffers,
    numberOfOffers: value.numberOfOffers,
  };
}
