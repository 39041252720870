import React, {FC, useRef} from 'react';
import {InlineTermTypeSelect} from '../../NegotiationDetail/Components/TermItem/Components/TermType/TermTypeSelect';
import {TermItemDumb} from '../../NegotiationDetail/Components/TermItem/TermItemDumb';
import {createMockTerm} from '../../NegotiationDetail/Components/TermItem/test-utils/createMockTerm';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import {TermActions} from '../../NegotiationDetail/Components/TermItem/utils/useGetTermActionsWithMutations';
import {TermTemplateEditor} from './TermTemplateEditor/TermTemplateEditor';
import {TermActionArea} from '../../NegotiationDetail/Components/TermItem/Components/TermActionArea';
import {defaultTermColors} from '../../utils/colors';
import {InlineTermTypeLabel} from '../../NegotiationDetail/Components/TermItem/Components/TermType/TermTypeLabel';
import {focusTermText, getTermListItem, getTermTextNode} from './TermTemplateEditor/utils/utils';

type TermItemTemplateProps = {
  idx: number;
  term: ReturnType<typeof createMockTerm>;
  canBeEdited?: boolean;
  dragListeners?: SyntheticListenerMap;
  termActions: TermActions;
};

export const TermItemTemplate: FC<TermItemTemplateProps> = ({idx, term, canBeEdited, dragListeners, termActions}) => {
  const termContainerRef = useRef<HTMLDivElement>(null);

  const {text, type} = term;
  const {updateTermText, updateTermType} = termActions;

  const blurTerm = () => {
    const termText = getTermTextNode(termContainerRef.current);
    termText?.blur();
  };
  const startEditing = () => {
    const currentTerm = getTermListItem(termContainerRef);
    focusTermText(currentTerm);
  };
  const focusPreviousTerm = () => {
    const previousTerm = getTermListItem(termContainerRef)?.previousElementSibling;
    if (!previousTerm) {
      blurTerm();
      return;
    }
    focusTermText(previousTerm);
  };
  const focusNextTerm = () => {
    const nextTerm = getTermListItem(termContainerRef)?.nextElementSibling;
    // In the DragAndDropList-Component, react-dnd adds a screenreader announcement at the end of the list, which is not a drag-item
    if (!nextTerm || !nextTerm.classList.contains('drag-item')) {
      blurTerm();
      return;
    }
    focusTermText(nextTerm);
  };

  return (
    <TermItemDumb
      containerRef={termContainerRef}
      termId={idx}
      indexLabel={idx + 1}
      text={text}
      colors={defaultTermColors}
      dragListeners={dragListeners}
      isHighlighted={canBeEdited}
      aboveTextAddon={
        canBeEdited ? (
          <InlineTermTypeSelect termType={type} onTermTypeChange={updateTermType} />
        ) : (
          <InlineTermTypeLabel termType={type} />
        )
      }
      textReplacement={
        canBeEdited ? (
          <TermTemplateEditor
            containerRef={termContainerRef}
            id={idx}
            text={text}
            type={type}
            onChangeText={updateTermText}
            onChangeType={updateTermType}
            onFinishEditing={focusNextTerm}
            onMoveToPreviousTerm={focusPreviousTerm}
            onMoveToNextTerm={focusNextTerm}
          />
        ) : null
      }
      afterTextAddon={
        <TermActionArea
          termState={{canBeEdited, canBeDeleted: canBeEdited}}
          termActions={termActions}
          onEdit={startEditing}
        />
      }
    />
  );
};
