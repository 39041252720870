import {Checkbox} from 'antd';
import styled from 'styled-components';

export const TermsContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const HideTermsCheckbox = styled(Checkbox)`
  margin-left: 30px;
  color: var(--color-gray-2);
`;
