import {FC, useEffect, useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import {MapApi} from '../../../components/SeaboMap/MapApi';
import {SeaboMap} from '../../../components/SeaboMap/SeaboMap';
import {MapHoverContainer} from './components/MapHoverContainer';
import {useLocalStorage} from '../../../utils/useLocalStorage';
import {MapControlButton} from '../../../components/SeaboMap/MapContext/Types';
import {makePartialMapSwitches} from '../../../components/SeaboMap/utils/makePartialMapSwitches';
import {DEFAULT_MAP_STYLE} from '../../../components/SeaboMap/const';

const CLOSED_MAP_HEIGHT = 320;
const lock = '/static/icon/dashboard/lock.svg';
const unlocked = '/static/icon/dashboard/unlocked.svg';

const localStorageKeyDashboardHeroMapIsLocked = 'DashboardHeroMapIsLocked';

export const HeroMap: FC<{map: MapApi}> = ({map}) => {
  const [maxHeight, setMaxHeight] = useState(2000);
  const [isLocked, setIsLocked] = useLocalStorage(localStorageKeyDashboardHeroMapIsLocked, false);

  const [isOpen, setIsOpen] = useState(isLocked);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    handleSetMaxHeight();
  };

  const handleSetMaxHeight = () => {
    const openMapHeight = Math.floor(window.innerHeight * 0.66);
    setMaxHeight(openMapHeight < CLOSED_MAP_HEIGHT ? CLOSED_MAP_HEIGHT : openMapHeight);
  };

  useLayoutEffect(() => {
    handleSetMaxHeight();
  }, []);

  const clickHandler = () => {
    setIsLocked(p => !p);
  };

  return (
    <MapRootContainer data-tour-id="DashboardMap">
      <MapHoverContainer openedHeight={maxHeight} closedHeight={CLOSED_MAP_HEIGHT} isOpen={isOpen}>
        <MapContainer
          height={maxHeight}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => !isLocked && setIsOpen(false)}>
          <SeaboMap
            mapApi={map}
            canFullScreen={false}
            showSearch
            showLegend={false}
            mapControlButtonsToHide={[MapControlButton.CARGOES]}
            mapControlMenuSectionsToHide={['vessel_portfolio', 'vessel_market', 'vessel_on']}
            initialMapSettings={{
              switches: {
                ...makePartialMapSwitches({
                  ports: true,
                  tradingArea: false,
                  tradingAreaNames: false,
                  highRiskAreas: true,
                  vesselMyFleet: false,
                  emissionControlAreas: true,
                }),

                // eslint-disable-next-line camelcase
                vessel_ais_sub: {
                  bulker: true,
                  tanker: false,
                  container: false,
                  mpp: false,
                  vState: false,
                  other: false,
                  vDisabled: false,
                  state: false,
                },
              },
              mapStyle: DEFAULT_MAP_STYLE,
              openWeatherMapLayer: 'precipitation_new',
            }}
            vesselCargoMode
            showPopups
            defaults="dashboard"
            settingIdentifier={'db2'}
          />
          <LockContainer $positionY={maxHeight} onClick={clickHandler}>
            <IconWrapper>
              <img src={isLocked ? lock : unlocked} />
            </IconWrapper>
            <Flex>{`${isLocked ? 'Unlock' : 'Lock'} map`}</Flex>
          </LockContainer>
        </MapContainer>
      </MapHoverContainer>
    </MapRootContainer>
  );
};

const LockContainer = styled.div<{$positionY: number}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  width: 200px;
  height: 100px;
  top: ${({$positionY}) => $positionY + 16}px;
  transform: translate(-50%, -100%);
  border-radius: 100px;
  padding: 10px;
`;

const MapContainer = styled.div<{height: number}>`
  height: ${({height}) => height}px;
  width: 100%;
  position: relative;
  background: #001e36;
`;

const MapRootContainer = styled.div`
  border-radius: var(--border-radius-widget);
  box-shadow: var(--box-shadow-widget-dragging);
  overflow: hidden;
  margin: 0 7.5px 16px 7.5px;

  &:hover {
    box-shadow: var(--box-shadow-widget-dragging);
  }
`;

const IconWrapper = styled.div`
  width: 25px;
  height: 25px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    left: 5px;
    bottom: 2px;
    position: relative;
  }
`;

const Flex = styled.div`
  color: var(--color-white);
`;
