import dayjs from 'dayjs';
import sumBy from 'lodash/sumBy';
import {CostInput, CostOutput} from '../../CostInformation/CostTypes';
import {VesselInput} from '../../VesselInformation/VesselTypes';
import {ConsumptionResult, getConsumptionForPoint} from '../../VoyageInformation/Timeline/getConsumptionForPoint';
import {getConsumptionForRoute} from '../../VoyageInformation/Timeline/getConsumptionForRoute';
import {VoyageInput, VoyageOutput, VoyagePointOutput, VoyageRouteOutput} from '../../VoyageInformation/VoyageTypes';
import {WarningOutput} from '../../Warning/WarningTypes';
import {calcChronicalTimeLineList} from '../calcChronicalTimeLineList';
import {CargoInput, CargoOutput} from '../CargoTypes';
import {getBunkerBalanceForTimePeriod} from '../consumptionSequence/getBunkerBalanceForTimePeriod';
import {avgDateOfConsumptionRange} from './avgDateOfConsumptionRange';
import {calcRevenueNet} from './calcRevenueNet';
import {getConsumptionTransactionsFromROB} from './getConsumptionTransactionsFromROB';
import {subCalcPoints} from './subCalcPoints';
import {subCalcRoutes} from './subCalcRoutes';
import {transformVoyageItemConsumptionToConsumptionTransactions} from './transformVoyageItemConsumptionToConsumptionTransactions';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import calcWarnings from '../calcWarnings/calcWarnings';

export type DistanceCalculator = {
  allowSuezCanal: boolean;
  allowPanamaCanal: boolean;
  allowKielCanal: boolean;
  avoidSECA: boolean;
  avoidPiracyAreas: boolean;
};

export interface CalculatorInput {
  name?: string;
  note?: string;
  isFavorite: boolean;
  distanceCalculator: DistanceCalculator;
  projectId?: number;
}

export interface InputState {
  vessel: VesselInput;
  voyage: VoyageInput;
  cost: CostInput;
  cargo: CargoInput;
  calculator: CalculatorInput;
}
export interface OutputState {
  voyage: VoyageOutput;
  cost: CostOutput;
  cargo: CargoOutput;
  warning: WarningOutput;
}

export const voyageChartercalculation = (props: InputState): OutputState => {
  const data: OutputState = {
    voyage: {
      consumptionTransactions: [],
      points: [],
      routes: [],
      cO2Emission: 0,
    },
    cost: {
      costCo2: 0,
      costConsumption: 0,
      otherCostsSum: 0,
      sumPortCosts: 0,
      costCommission: 0,
    },
    cargo: {
      revenueNet: 0,
      revenueTotal: 0,
      duration: 0,
      tce: 0,
    },
    warning: {
      list: [],
    },
  };

  data.voyage.consumptionTransactions.push(...getConsumptionTransactionsFromROB(props.vessel.remainingOnBoards));

  subCalcRoutes({
    inputState: props,
    outputState: data,
  });

  props.cost.otherCosts.forEach(item => {
    data.cost.otherCostsSum += item.cost;
  });

  subCalcPoints({
    inputState: props,
    outputState: data,
  });

  let timestamp = dayjs(props.vessel.openDate);
  let isAfterInfinityDuration = false;

  const chronicalTimeLineList = calcChronicalTimeLineList({inputState: props});
  chronicalTimeLineList.forEach(node => {
    let descriptionForConsumptionTransaction = '';

    let consumptionResults: ConsumptionResult[] = [];

    let voyageRouteOrPointOutput: VoyagePointOutput | VoyageRouteOutput | null = null;
    if (node.nodeType === 'point') {
      descriptionForConsumptionTransaction = `Consumption in port ${node.item.name}`;
      voyageRouteOrPointOutput = data.voyage.points[node.indexInOldArray];

      consumptionResults = [
        getConsumptionForPoint({
          point: node.item,
          costInput: props.cost,
          type: 'main',
          duration: voyageRouteOrPointOutput.duration,
          isScrubberFitted: props.vessel.isScrubberFitted,
        }),
        getConsumptionForPoint({
          point: node.item,
          costInput: props.cost,
          type: 'aux',
          duration: voyageRouteOrPointOutput.duration,
          isScrubberFitted: props.vessel.isScrubberFitted,
        }),
      ];
    } else {
      const legBeforRoute = data.voyage.points[node.indexInOldArray];
      const start = props.voyage.points[node.indexInOldArray];
      const end = props.voyage.points[node.indexInOldArray + 1];
      voyageRouteOrPointOutput = data.voyage.routes[node.indexInOldArray];

      consumptionResults = [
        ...getConsumptionForRoute({
          costInput: props.cost,
          type: 'main',
          duration: voyageRouteOrPointOutput.duration,
          isScrubberFitted: props.vessel.isScrubberFitted,
          route: node.item,
        }),
        ...getConsumptionForRoute({
          costInput: props.cost,
          type: 'aux',
          duration: voyageRouteOrPointOutput.duration,
          isScrubberFitted: props.vessel.isScrubberFitted,
          route: node.item,
        }),
      ];

      descriptionForConsumptionTransaction = `Consumption on route between ${start.name} and ${end.name}`;

      voyageRouteOrPointOutput.isBallast = legBeforRoute.usedDwat === 0;
    }

    if (!isAfterInfinityDuration) {
      voyageRouteOrPointOutput.startDate = timestamp.format(DateTimeFormat.IsoDate);
      timestamp = dayjs(timestamp).add(voyageRouteOrPointOutput.duration, 'day');
      voyageRouteOrPointOutput.endDate = timestamp.format(DateTimeFormat.IsoDate);
    }

    if (voyageRouteOrPointOutput.duration === Infinity) {
      isAfterInfinityDuration = true;
      voyageRouteOrPointOutput.endDate = undefined;
    }

    data.voyage.consumptionTransactions.push(
      ...transformVoyageItemConsumptionToConsumptionTransactions({
        consumptions: consumptionResults,
        isScrubberFitted: props.vessel.isScrubberFitted,
        description: descriptionForConsumptionTransaction,
        date: avgDateOfConsumptionRange(
          dayjs(voyageRouteOrPointOutput.startDate!),
          dayjs(voyageRouteOrPointOutput.endDate!)
        ).toDate(),
      })
    );
  });

  data.cargo.revenueNet = calcRevenueNet({
    input: props,
    output: data,
  });
  data.cost.costCommission = (data.cargo.revenueNet * (props.cargo.totalCommission ?? 0)) / 100.0;

  data.voyage.cO2Emission =
    sumBy(data.voyage.routes, item => item.cO2Emission) + sumBy(data.voyage.points, item => item.cO2Emission);

  data.cost.costCo2 = data.voyage.cO2Emission * (props.cost.costCo2PerMts ?? 0);

  const mgoConsumption = getBunkerBalanceForTimePeriod({
    costPerUncoveredUnit: props.cost.costMgoPerMts ?? 0,
    end: dayjs(props.vessel.openDate)
      .add(data.cargo.duration + 1, 'days')
      .toDate(),
    fuelType: 'mgo',
    start: dayjs(props.vessel.openDate).toDate(),
    transactions: data.voyage.consumptionTransactions,
  });

  const ifoConsumption = getBunkerBalanceForTimePeriod({
    costPerUncoveredUnit: props.cost.costIfoPerMts ?? 0,
    end: dayjs(props.vessel.openDate)
      .add(data.cargo.duration + 1, 'days')
      .toDate(),
    fuelType: 'ifo',
    start: dayjs(props.vessel.openDate).toDate(),
    transactions: data.voyage.consumptionTransactions,
  });

  const vlsfoConsumption = getBunkerBalanceForTimePeriod({
    costPerUncoveredUnit: props.cost.costVlsfoPerMts ?? 0,
    end: dayjs(props.vessel.openDate)
      .add(data.cargo.duration + 1, 'days')
      .toDate(),
    fuelType: 'vlsfo',
    start: dayjs(props.vessel.openDate).toDate(),
    transactions: data.voyage.consumptionTransactions,
  });

  const totalConsumptionCost = mgoConsumption.totalCosts + ifoConsumption.totalCosts + vlsfoConsumption.totalCosts;

  data.cost.costConsumption = totalConsumptionCost;

  data.cargo.revenueTotal =
    data.cargo.revenueNet -
    data.cost.costCommission -
    data.cost.costConsumption -
    data.cost.otherCostsSum -
    data.cost.sumPortCosts -
    data.cost.costCo2;

  data.cargo.tce = data.cargo.revenueTotal / data.cargo.duration;

  if (isNaN(data.cargo.tce) || Math.abs(data.cargo.tce) === Infinity) {
    data.cargo.tce = 0;
  }

  data.warning = calcWarnings(props, data);

  return data;
};
