import {ChangeEvent, Fragment, useEffect} from 'react';
import styled from 'styled-components';
import {Button} from '../../../atoms/Button/Button';
import Input from '../../../atoms/Input';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {TextArea} from '../../../atoms/TextArea/TextArea';
import autocompleteActions from '../../../redux/ApiService/autocomplete';
import {useDispatch} from '../../../redux/react-redux';
import {ColorPicker, colorOptions} from '../../ColorPicker/ColorPicker';

export type Color = {
  name: string;
  value: string;
};

interface CreateNewProjectProps {
  type: string;
  newProjectName: string;
  onChangeProjectName: (e: string) => void;
  newProjectComment: string;
  onChangeComment: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  newProjectColour: Color;
  onChangeColour: (e: Color) => void;
  onClickNewProject: () => void;
  disabled: boolean;
}

const CreateNewProject = ({
  type,
  newProjectName,
  onChangeProjectName,
  newProjectComment,
  onChangeComment,
  newProjectColour,
  onChangeColour,
  onClickNewProject,
  disabled,
}: CreateNewProjectProps) => {
  const dispatch = useDispatch();
  const preFillSearch = (indexName: 'vessels_portfolio' | 'cargoes_portfolio') =>
    dispatch(autocompleteActions.preFillSearch(indexName));

  useEffect(() => {
    preFillSearch(type === 'cargo' ? 'vessels_portfolio' : 'cargoes_portfolio');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <LabelWrapper label="Project name" spMT="m">
        <Input id="project-name" type="text" value={newProjectName} onChange={onChangeProjectName} />
      </LabelWrapper>

      <LabelWrapper label="Note / comment">
        <TextArea
          id="project-comment"
          onChange={onChangeComment}
          value={newProjectComment}
          noResize
          rows={4}
          large={false}
          style={{marginBottom: 0}}
        />
      </LabelWrapper>

      <LabelWrapper label="Marker colour" contentWrapperStyle={{marginBottom: 0}}>
        <ColorPicker
          id="project-new-color"
          name="project-new-color"
          colorId={colorOptions.find(color => color.value === newProjectColour.value)?.colorId as number}
          onChange={colorOption => {
            onChangeColour({
              name: colorOption.label,
              value: colorOption.value as string,
            });
          }}
        />
      </LabelWrapper>

      <StyledAddToProjectCentered>
        <Button
          disabled={disabled}
          primary
          spMT="m"
          bold
          onClick={onClickNewProject}
          label="Create a new PROJECT"
          upper
        />
      </StyledAddToProjectCentered>
    </Fragment>
  );
};

export default CreateNewProject;

const StyledAddToProjectCentered = styled.div`
  text-align: center;
`;
