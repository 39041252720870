import {Button, Col, Row} from 'antd';
import {useState, FC} from 'react';
import {assertUnreachable} from '../../../utils/assert';
import {Select} from '../../../antd/components/Select';
import {useProjectsQuery} from '../../../queries/useProjectsQuery';
import {useAddItemToProjectMutation} from '../../../queries/projects/useAddItemToProjectMutation';
import {showNotification} from '../../../utils/notification';
import {Modal, ModalWidth} from '../../../antd/components/Modal';
import {Center} from '../../Center/Center';

type GeneralProps = {visible: boolean; onCancel: () => void};

type CargoProjectProps = {
  projectType: 'cargo';
  vesselIds: number[];
};

type VesselProjectProps = {
  projectType: 'vessel';
  cargoIds: number[];
};

type Props = GeneralProps & (CargoProjectProps | VesselProjectProps);

export const AddItemToProjectModal: FC<Props> = props => {
  const addItemToProjectMutation = useAddItemToProjectMutation({
    onSuccess: () => {
      showNotification('Items added to project.');
      setSelectProjectId(undefined);
      onCancel();
    },
  });
  const [selectProjectId, setSelectProjectId] = useState<undefined | number>(undefined);
  const {visible, onCancel, projectType} = props;
  const projectQuery = useProjectsQuery({projectType});
  const projectsOptions = projectQuery.data?.map(item => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  let modalTitle = '';
  let description = '';
  let itemIdsToAddToProject: number[] = [];

  switch (projectType) {
    case 'cargo':
      modalTitle = 'Add to cargo project';
      itemIdsToAddToProject = props.vesselIds;
      description = `${itemIdsToAddToProject.length} vessels would add to this cargo project`;
      break;
    case 'vessel':
      modalTitle = 'Add to vessel project';
      itemIdsToAddToProject = props.cargoIds;
      description = `${itemIdsToAddToProject.length} cargoes would add to this vessel project`;
      break;
    default:
      assertUnreachable(projectType);
  }

  return (
    <Modal
      destroyOnClose
      confirmLoading={addItemToProjectMutation.isPending}
      open={visible}
      onCancel={onCancel}
      title={modalTitle}
      width={ModalWidth.Middle}
      footer={
        <Button
          type="primary"
          disabled={!selectProjectId}
          onClick={() => {
            switch (projectType) {
              case 'cargo':
                addItemToProjectMutation.mutate({
                  projectType: 'cargo',
                  projectId: selectProjectId!,
                  vesselIds: itemIdsToAddToProject,
                });
                break;
              case 'vessel':
                addItemToProjectMutation.mutate({
                  projectType: 'vessel',
                  projectId: selectProjectId!,
                  cargoIds: itemIdsToAddToProject,
                });
                break;
              default:
                assertUnreachable(projectType);
            }
          }}>
          Add to project
        </Button>
      }>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Center>{description}</Center>
        </Col>
        <Col span={24}>
          <Select
            allowClear={true}
            loading={projectQuery.isLoading}
            options={projectsOptions}
            style={{width: '100%'}}
            onClear={() => {
              setSelectProjectId(undefined);
            }}
            onSelect={(projectId: number) => {
              setSelectProjectId(projectId);
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};
