import {Container, FormButtonGroup, StyledForm, Wrapper} from '../CargoVesselForm.styled';
import Title from '../../../atoms/Title';
import LoadingOverlay from '../../../atoms/Loading/LoadingOverlay';
import ProgressTabBar from '../../../components/ProgressTabBar';
import MarketSegment from '../CargoSections/MarketSegment';
import ContractType from '../CargoSections/ContractType';
import Charterer from '../CargoSections/Charterer';
import Commodity from '../CargoSections/Commodity';
import Quantity from '../CargoSections/Quantity/Quantity';
import VesselFeatures from '../CargoSections/VesselFeatures';
import Duration from '../CargoSections/Duration';
import Laycan from '../CargoSections/Laycan';
import Stations from '../CargoSections/Stations';
import Documents from '../SharedSections/Documents';
import Comment from '../SharedSections/Comment';
import Commission from '../CargoSections/Commission';
import {Button, Card} from 'antd';
import {FC} from 'react';
import {TODO} from '../../../utils/TODO';
import {steps} from './steps';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {BreadcrumbItem} from '../../../atoms/Breadcrumb/BreadcrumbItem';

type CargoFormBodyProps = {
  id?: number;
  formProps: TODO;
  submitting: boolean;
  uploading: boolean;
  validateSections: (section: string[]) => boolean;
  onSubmit: () => void;
  validateAndGoToStep: (formProps: TODO) => void;
  scrollToFirstError: () => void;
};

export const CargoFormBody: FC<CargoFormBodyProps> = ({
  id,
  formProps,
  submitting,
  uploading,
  onSubmit,
  scrollToFirstError,
  validateSections,
  validateAndGoToStep,
}) => {
  const cargoType = formProps.form.marketSegment && formProps.form.marketSegment.cargoType;
  const contractType = formProps.form.contractType && formProps.form.contractType.contractType;

  const isEdit = !!id;
  const formTitle = isEdit ? 'Edit' : 'Add cargo';

  const tabs = {
    label: steps.label,
    onClick: () => {
      if (validateSections(steps.sections)) {
        validateAndGoToStep(formProps);
      }
    },
    disable: !cargoType || !contractType,
  };

  const breadcrumbs: BreadcrumbItem[] = [{title: 'My Cargoes', href: '/my-cargoes'}];
  if (isEdit) {
    breadcrumbs.push({title: 'Cargo', href: `/cargo/${id}`});
  }
  breadcrumbs.push({title: formTitle});

  return (
    <Container className="cargo-vessel-form">
      <ScreenHeader
        helmetTitle={`${formTitle}${
          formProps.form.commodity?.commodityCategory?.name
            ? ' ' + formProps.form.commodity?.commodityCategory?.name + ' cargo'
            : ''
        }`}
        breadcrumbs={breadcrumbs}
      />
      <Wrapper>
        {submitting && <LoadingOverlay />}
        <ProgressTabBar tabs={[tabs]} />
        <StyledForm
          autoComplete="off"
          onKeyDown={e => {
            if (e.key === 'Enter' && (e.target as TODO).tagName !== 'TEXTAREA') {
              e.preventDefault();
            }
          }}
          onSubmit={e => {
            e.preventDefault();
            if (validateSections(steps.sections)) {
              onSubmit();
            } else {
              scrollToFirstError();
            }
          }}>
          <fieldset disabled={submitting || uploading}>
            <Card>
              {!isEdit && (
                <div
                  className="container-fluid"
                  style={{backgroundColor: 'var(--color-white)', borderRadius: '3px', padding: '20px 30px'}}>
                  <div className="row">
                    <div className="scol-12">
                      <Title center large>
                        Please select market segment and contract type
                      </Title>
                    </div>
                  </div>
                </div>
              )}
              <MarketSegment {...formProps} show isEdit={isEdit} />
              <ContractType {...formProps} show={cargoType} isEdit={isEdit} />
              <Charterer {...formProps} show={contractType} />
              <Commodity {...formProps} show={contractType && cargoType !== 'container'} />
              <Quantity
                {...formProps}
                validateSections={validateSections}
                show={contractType && contractType === 'vc'}
                contractType={contractType}
              />
              <VesselFeatures
                {...formProps}
                show={cargoType && contractType && contractType !== 'vc'}
                isEdit={isEdit}
              />

              {/* According to FRIN-5318 and this show prop condition it can be removed */}
              <Duration {...formProps} show={contractType && contractType !== 'vc'} isEdit={isEdit} />
              <Laycan {...formProps} show={contractType} />
              <Stations {...formProps} show={contractType} />
              <Documents {...formProps} show={contractType} uploadType={'cargo'} isEdit={isEdit} />
              <Comment {...formProps} show={contractType} />
              <Commission {...formProps} show={contractType} />
              {contractType && (
                <FormButtonGroup>
                  <Button id="submit-form" htmlType="submit" type="primary" disabled={submitting || uploading}>
                    Save and exit
                  </Button>
                </FormButtonGroup>
              )}
            </Card>
          </fieldset>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};
