/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ContractType {
  Vc = 'vc',
  Coa = 'coa',
  Bc = 'bc',
  Pc = 'pc',
  Tct = 'tct',
  Sale = 'sale',
}

export function ContractTypeFromJSON(json: any): ContractType {
  return ContractTypeFromJSONTyped(json, false);
}

export function ContractTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractType {
  return json as ContractType;
}

export function ContractTypeToJSON(value?: ContractType | null): any {
  return value as any;
}
