import {Collapse} from 'antd';
import {FC} from 'react';
import {useOfferMailAsJsonQuery} from '../utils/useOfferMailAsJsonQuery';
import {OfferMailView} from '../../../OfferMailModal/OfferMailView';
import {NotAvailable} from '../../../../utils/NotAvailable';

export const OfferMailCollapse: FC<{id?: number; type: 'cargo' | 'vessel'}> = ({id, type}) => {
  const {isInitialLoading: isLoading, data} = useOfferMailAsJsonQuery({id, type});

  return (
    <Collapse>
      {data ? (
        <Collapse.Panel key={1} data-cy="mailAvailable" header={'Show original email'}>
          <OfferMailView offerMail={data} />
        </Collapse.Panel>
      ) : (
        <Collapse.Panel
          key={2}
          data-cy="mailNotAvailable"
          header={`Original email ${isLoading ? 'loading...' : NotAvailable}`}
          collapsible="disabled"
        />
      )}
    </Collapse>
  );
};
