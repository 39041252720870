import {FC, ReactNode, useEffect, useState} from 'react';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {useCurrentUserQuery} from '../../queries/useCurrentUserQuery';
import UserActions from '../../redux/User';
import {assert} from '../../utils/assert';
import User from '../../model/User';

/**
 * Loads the current user and puts it in Redux.
 * Only this is done, we render the component's children.
 */
export const UserLoader: FC<{children: ReactNode}> = ({children}) => {
  const [userLoading, setUserLoading] = useState(true);

  const dispatch = useDispatch();

  const currentUserQuery = useCurrentUserQuery();

  useEffect(() => {
    if (currentUserQuery.isSuccess && currentUserQuery.data) {
      dispatch(UserActions.set(currentUserQuery.data));
    }
  }, [currentUserQuery.isSuccess, currentUserQuery.data, dispatch]);

  useEffect(() => {
    if (currentUserQuery.isFetched) {
      setUserLoading(false);
    }
  }, [currentUserQuery.isFetched]);

  const user = useSelector(state => state.user);
  if (user) {
    verifyUser(user);
  }

  // Render nothing until we have loaded all data.
  // Careful: We can't use react-query's isLoading state here because it ends before onSuccess has
  // put the current user into Redux.
  if (userLoading) {
    return null;
  }

  return <>{children}</>;
};

const verifyUser = (user: User) => {
  assert(user.payload.company, `User ${user.id} has no company`);
  assert(user.workspace, `User has no workspace`);
  assert(user.workspace.id, `User workspace is ${user.workspace.id}`);
};
