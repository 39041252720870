import React, {FC} from 'react';
import cx from 'classnames';

export const DetailTableBody: FC<{scrollable?: boolean; children: React.ReactNode}> = ({children, scrollable}) => (
  <tbody className={cx('detail-table__table', 'detail-table__body', {'detail-table__body--scrollable': scrollable})}>
    {children}
  </tbody>
);

export default DetailTableBody;
