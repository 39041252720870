import {makeApiService, ApiServiceEntry} from './ApiService';

const entries: ApiServiceEntry[] = [
  {fn: 'getParserEmailList', route: '/api/search', storeData: false},
  {fn: 'getParserEmailListPre', route: '/api/search/pre', storeData: false},
  {fn: 'getParserStatistics', route: '/api/search/monitor/statistics', storeData: false},
];

const monitorApi = makeApiService(entries, {apiName: 'monitor'});

export const reducers = monitorApi.reducers;
export const actions = monitorApi.actions;
export const actionTypes = monitorApi.actionTypes;
