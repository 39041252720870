import {Col} from 'antd';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {getAllDaysInPeriod} from '../../utils/getAllDaysInPeriod/getAllDaysInPeriod';
import {getBucketDate} from '../../utils/getBucketDate';
import {getValueOfBucket} from '../../utils/getValueOfBucket';
import {OfferType} from '../../utils/transformBucketsToTimeSeries';
import {VerticalPaddingCard} from '../VerticalPaddingCard';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {getMedian} from '../../../CongestionScreens/utils/getMedian';

export const DayOfWeekBarChartSections = ({
  buckets,
  offerType,
  span = 12,
}: {
  buckets: OfferBucket[];
  offerType: OfferType;
  span?: number;
}) => {
  const allDaysInPeriod: Record<string, number> = {};
  const start = minBy(buckets, bucket => getBucketDate(bucket));
  const end = maxBy(buckets, bucket => getBucketDate(bucket));

  if (!start || !end) {
    return null;
  }
  getAllDaysInPeriod({
    start: getBucketDate(start),
    end: getBucketDate(end),
  }).forEach(date => {
    allDaysInPeriod[date] = 0;
  });

  const data: number[][] = [[], [], [], [], [], [], []];
  buckets.forEach(bucket => {
    const date = getBucketDate(bucket);
    const count = getValueOfBucket({bucket, offerType: offerType});

    allDaysInPeriod[date.format(DateTimeFormat.IsoDate)] += count;
  });

  for (const date in allDaysInPeriod) {
    const count = allDaysInPeriod[date];
    const dayOfWeek = dayjs(date).weekday();
    data[dayOfWeek].push(count);
  }

  const dataWithZeroIfEmpty = data.map(row => {
    if (row.length === 0) {
      return [0];
    }
    return row;
  });

  return (
    <Col span={span}>
      <VerticalPaddingCard>
        <ReactECharts
          style={{minHeight: 300}}
          option={{
            legend: {
              left: 8,
            },
            title: {
              text: 'Circulars per weekday',
              left: 'center',
              align: 'right',
            },
            tooltip: {},
            dataset: {
              source: [
                ['Weekday', 'Median circulars per day'],
                ['Monday', getMedian(dataWithZeroIfEmpty[0])],
                ['Tuesday', getMedian(dataWithZeroIfEmpty[1])],
                ['Wednesday', getMedian(dataWithZeroIfEmpty[2])],
                ['Thursday', getMedian(dataWithZeroIfEmpty[3])],
                ['Friday', getMedian(dataWithZeroIfEmpty[4])],
                ['Saturday', getMedian(dataWithZeroIfEmpty[5])],
                ['Sunday', getMedian(dataWithZeroIfEmpty[6])],
              ],
            },
            xAxis: {type: 'category'},
            yAxis: {},
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [{type: 'bar'}],
            grid: {
              left: 48,
              right: 24,
              bottom: 20,
            },
          }}
        />
      </VerticalPaddingCard>
    </Col>
  );
};
