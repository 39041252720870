import {FC} from 'react';
import {TODO} from '../../utils/TODO';

interface Location {
  highlight?: {
    aliases?: string[];
    name?: TODO;
    code?: TODO;
  };
}

export const LocationAlias: FC<{location: Location}> = ({location: {highlight}}) => {
  if (!highlight || !highlight.aliases || highlight.name || highlight.code || highlight.aliases.length <= 0) {
    return <></>;
  }

  return <span className={'location-output__sub-title'}>(Alias: {highlight.aliases.join(', ')})</span>;
};
