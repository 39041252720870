/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface DistanceCalculation
 */
export interface DistanceCalculation {
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof DistanceCalculation
   */
  waypoints: Array<Array<number>>;
  /**
   *
   * @type {number}
   * @memberof DistanceCalculation
   */
  distance: number;
  /**
   *
   * @type {number}
   * @memberof DistanceCalculation
   */
  piracyIntersection: number;
  /**
   *
   * @type {number}
   * @memberof DistanceCalculation
   */
  secaIntersection: number;
  /**
   *
   * @type {number}
   * @memberof DistanceCalculation
   */
  duration: number;
}

export function DistanceCalculationFromJSON(json: any): DistanceCalculation {
  return DistanceCalculationFromJSONTyped(json, false);
}

export function DistanceCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DistanceCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    waypoints: json['waypoints'],
    distance: json['distance'],
    piracyIntersection: json['piracyIntersection'],
    secaIntersection: json['secaIntersection'],
    duration: json['duration'],
  };
}

export function DistanceCalculationToJSON(value?: DistanceCalculation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    waypoints: value.waypoints,
    distance: value.distance,
    piracyIntersection: value.piracyIntersection,
    secaIntersection: value.secaIntersection,
    duration: value.duration,
  };
}
