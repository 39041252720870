import {FC} from 'react';
import {Button} from '../../../Button/Button';
import '../style.scss';

const EmptyStateMainPortfolio: FC = () => {
  return (
    <div id="vessel-empty-state-sub-container" className="empty-state-container-main">
      <img
        src="/static/images/seanexxt/portfolio/emptyStateVessel.png"
        className="empty-state-container-main__image"
        alt="emptyStateVessel"
      />
      <div className={'empty-state-container-main__header'}>Let's add your first vessel!</div>
      <div className={'empty-state-container-main__subline'}>There are currently no vessels in your list.</div>
      <div className={'empty-state-container-main__subline'}>Add a vessel from the Market or create a new one.</div>
      <Button id={'empty-state-action-button'} to={'/vessel/create'} label={'Create vessel'} bold upper primary />
    </div>
  );
};

export default EmptyStateMainPortfolio;
