import classNames from 'classnames';
import {MenuInfo} from 'rc-menu/lib/interface';
import {FC, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {SidebarMode} from '../../../redux/Sidebar';
import {assert} from '../../../utils/assert';
import {findMenuElementByKey, findMenuItemByPath, findSubMenuByMenuItem, getFirstMenuItemDef} from './MenuDef';
import {classNameForSidebarMode, Menu} from './SidebarMenuComponents';
import {getMenuItems, menuSet} from './sidebarMenuDefinitions';
import {useSelector} from '../../../redux/react-redux';
import {getHasProPlan, getUserPermissions} from '../../../redux/selectors';

export const SidebarMenuBar: FC<{mode: SidebarMode}> = ({mode}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentMenuItem = findMenuItemByPath(menuSet, location.pathname);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const userPermissions = useSelector(getUserPermissions);
  const isSidebarOpen = useSelector(state => state.sidebar.mode === 'wide');
  const hasProPlan = useSelector(getHasProPlan);
  const isMissingFeatures = !hasProPlan;
  const enablePremiumBadge = isSidebarOpen && isMissingFeatures;

  // Everytime the location changes, we recalculate the selected menu items and the open submenus.
  useEffect(() => {
    const selectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
    setSelectedKeys(selectedKeys);

    if (currentMenuItem) {
      const subMenu = findSubMenuByMenuItem(menuSet, currentMenuItem.key);
      const openKeys = subMenu ? [subMenu.key] : [];
      setOpenKeys(openKeys);
    } else {
      setOpenKeys([]);
    }
  }, [currentMenuItem]);

  const openKeysEffective = mode === 'narrow' ? [] : openKeys;

  const onClick = (info: MenuInfo) => {
    const key = info.key.toString();
    onSelectMenuItem(key);
  };

  const onSelectMenuItem = (key: string) => {
    const menuElement = findMenuElementByKey(menuSet, key);
    if (!menuElement) {
      throw new Error(`Unknown menu element key"${key}"`);
    }
    setSelectedKeys([key]);
  };

  const onOpenChange = (newOpenKeys: string[]) => {
    const newOpenKeysAsStrings = newOpenKeys.filter(key => !!key).map(key => key.toString());
    const latestOpenKey = newOpenKeysAsStrings[newOpenKeysAsStrings.length - 1];
    setOpenKeys([latestOpenKey]);
  };

  interface SubMenuTitleEvent {
    key: string;
  }

  const onSubMenuClick = (event: SubMenuTitleEvent) => {
    // When the sidebar is narrow, we allow the user to click on a sub menu's title
    // to select the very first menu item contained in it.

    if (mode !== 'narrow') {
      return;
    }
    const subMenuKey = event.key;
    const menuItem = getFirstMenuItemDef(menuSet, subMenuKey);
    assert(menuItem !== undefined, `No menu item found for sub menu "${subMenuKey}"`);
    navigate(menuItem.url);
    onSelectMenuItem(menuItem.key);
  };

  const className = classNames('SidebarMenu', classNameForSidebarMode(mode));

  const {upperMenuItems, lowerMenuItems} = getMenuItems(onSubMenuClick, userPermissions, enablePremiumBadge);

  return (
    <>
      <Menu
        className={className}
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeysEffective}
        onOpenChange={onOpenChange}
        onClick={onClick}
        items={upperMenuItems}
      />
      <MenuSpacer />
      <Menu
        className={classNames('SidebarMenuLower', classNameForSidebarMode(mode))}
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeysEffective}
        onClick={onClick}
        onOpenChange={onOpenChange}
        items={lowerMenuItems}
      />
    </>
  );
};

const MenuSpacer = styled.div`
  margin-top: auto;
`;
