import {RowValueType} from '../../utils/RowValueType';
import {TODO} from '../../../../utils/TODO';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {numberFormat} from '../../../../utils/formatter';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';

const reeferPlugsAccessor =
  (type: RowValueType): AccessorFn<TODO> =>
  (rowValue: TODO): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'}>
        <div className={'market-grid-cell-content'}>{numberFormat(vessel.reeferPlugs, {emptyString: '-'})}</div>
      </div>
    );
  };
export const reeferColumn = ({
  type,
  minWidth = 6,
  sortable = true,
}: {
  type?: RowValueType;
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<SeaboVessel> => ({
  header: 'reefer',
  id: 'reeferPlugs',
  accessorFn: reeferPlugsAccessor(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
  enableSorting: sortable,
});
