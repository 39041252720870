import styled from 'styled-components';
import Icon from '../../atoms/Icon';
import {useGetEasySharingState} from '../../hooks/useGetEasySharingState';

export const EasySharingPromotionBanner = () => {
  const {isEasySharingLoading, isEasySharingEnabled, isEasySharingSetupComplete} = useGetEasySharingState();

  if (isEasySharingLoading) {
    return null;
  }

  if (!isEasySharingEnabled || isEasySharingSetupComplete) {
    return null;
  }

  return (
    <EasySharingPromotionBannerContainer>
      <InboxIcon type="block" size="large" />
      <div>
        <b>You have enabled Easy Sharing in this workspace, but not selected an outbox yet.</b>
        <br />
        Select or create a submarket with circulars to share below and activate the outbox{' '}
        <Icon type="outbox" style={{verticalAlign: 'sub', color: 'inherit'}} /> to receive circulars from the shared
        seabo market.
      </div>
    </EasySharingPromotionBannerContainer>
  );
};

const EasySharingPromotionBannerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin: 15px 0 0;
  padding: 5px 10px;
  line-height: 1.3;
  background: var(--color-orange-bg);
  border: 2px solid var(--color-orange);
  border-radius: var(--border-radius-tab);
`;

const InboxIcon = styled(Icon)`
  position: relative;
  font-size: 36px;
  color: var(--color-orange);
  line-height: 1;
  opacity: 0.66;
`;
