import {FC} from 'react';
import {ProjectCargoMap} from './ProjectDetailItem/ProjectCargoMap/ProjectCargoMap';
import {ProjectVesselMap} from './ProjectDetailItem/ProjectVesselMap/ProjectVesselMap';
import {useProjectDetails} from './useProjectDetails';

type ProjectDetailMapProps = {
  selectedShortlistItemId: number | null;
  onShortlistItemClick?: (id: number) => void;
};

export const ProjectDetailMap: FC<ProjectDetailMapProps> = ({selectedShortlistItemId, onShortlistItemClick}) => {
  const {
    project: {vessel, cargo, type},
  } = useProjectDetails();

  return type === 'vessel' ? (
    <ProjectVesselMap
      vessel={vessel!}
      selectedShortlistItemId={selectedShortlistItemId}
      onShortlistItemClick={onShortlistItemClick}
    />
  ) : (
    <ProjectCargoMap
      cargo={cargo!}
      selectedShortlistItemId={selectedShortlistItemId}
      onShortlistItemClick={onShortlistItemClick}
    />
  );
};
