import {FC} from 'react';
import styled from 'styled-components';
import Icon from '../../../../../../../../atoms/Icon';
import {Button, Space} from 'antd';

export const AddLadenLegsButton: FC<{showLadenLegs: boolean; onToggle: () => void}> = ({onToggle, showLadenLegs}) => {
  if (showLadenLegs) {
    return null;
  }
  return (
    <StyledButton ghost type={'link'} icon onClick={onToggle} data-cy="AddLadenLegsButton">
      <Space>
        <Icon type={'item-add-selection-solid'} />
        <span>Add laden legs</span>
      </Space>
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  text-transform: none;
  color: rgb(138, 138, 138);
  font-weight: normal;
  padding: 0;
  margin: 0 0 0 -9px;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: rgb(138, 138, 138);
  }
`;
