import {useDispatch} from 'react-redux';
import vesselService from '../../../redux/ApiService/vesselService';
import PortfolioService from '../../../redux/ApiService/PortfolioService';
import {PortfolioVessel} from '../../../redux/Portfolio';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import omit from 'lodash/omit';
import {ApiError} from '../../../api/utils/ApiError';
import {Dispatch} from 'redux';

export interface CloneableVessel extends Omit<PortfolioVessel, 'id' | 'target'> {
  id?: number;
}

type Vessel = CloneableVessel;

type CloneParams = {
  vessel: Vessel;
  workspaceId?: number;
  subPortfolioIds?: number[];
};

const cloneOneVessel = async (params: CloneParams & {dispatch: Dispatch}) => {
  const {vessel, workspaceId, subPortfolioIds, dispatch} = params;
  const cloneVessel = getVesselWithoutIdButOrginalVesselId(vessel);
  const newVessel = await dispatch(vesselService.createVessel(cloneVessel));

  if (workspaceId !== undefined) {
    await dispatch(vesselService.workspaceUpdate(newVessel.id, {workspaceID: workspaceId}));
    if (subPortfolioIds && subPortfolioIds.length > 0) {
      await dispatch(PortfolioService.assignToGroups('vessel', newVessel.id, subPortfolioIds));
    }
  }
  return newVessel;
};

export const useCloneVesselToMyFleetMutation = (
  options: UseMutationOptions<PortfolioVessel, ApiError, CloneParams>
) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({vessel, workspaceId, subPortfolioIds}: CloneParams) => {
      const newVessel = await cloneOneVessel({
        workspaceId,
        dispatch,
        subPortfolioIds,
        vessel,
      });
      return newVessel;
    },
    ...options,
  });
};

type MultipleCloneParams = {
  vessels: Vessel[];
  workspaceId?: number;
  subPortfolioIds?: number[];
};

export const useCloneMultipleVesselsToMyFleetMutation = (
  {
    onProgress,
  }: {
    onProgress: (importedVessels: number) => void;
  },
  options: UseMutationOptions<PortfolioVessel[], ApiError, MultipleCloneParams>
) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({vessels, workspaceId, subPortfolioIds}: MultipleCloneParams) => {
      const newVessels: PortfolioVessel[] = [];
      for (let index = 0; index < vessels.length; index++) {
        const vessel = vessels[index];
        const portfolioVessel = await cloneOneVessel({
          workspaceId,
          dispatch,
          subPortfolioIds,
          vessel,
        });
        onProgress(index);

        newVessels.push(portfolioVessel);
      }

      return newVessels;
    },
    ...options,
  });
};

const getVesselWithoutIdButOrginalVesselId = (vessel: Vessel) => {
  const copy = omit(vessel, 'id');
  copy.originVesselId = vessel.id;
  return copy;
};
