import {numberFormat} from '../../../../utils/formatter';

export const getPeriodLabel = ({
  periodMin,
  periodMax,
}: {
  periodMin: undefined | number;
  periodMax: undefined | number;
}) => {
  const minMaxIsSame = periodMax === periodMin && !!periodMin;
  if (minMaxIsSame) {
    return numberFormat(periodMin);
  }
  if (periodMin && periodMax) {
    return numberFormat(periodMin) + ' - ' + numberFormat(periodMax);
  }
  if (periodMin || periodMax) {
    return numberFormat(periodMin ?? periodMax);
  }

  return '-';
};
