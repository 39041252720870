import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {StyledRangePicker} from '../../../../RangePicker/StyledRangePicker';
import {DatePickerPairLayout} from '../../utils/DatePickerPairLayout';
import {filterBranchDefinition} from './UpdatedAtDefinition';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const UpdatedAt = ({filterProviderApi}: Props) => {
  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
      {({handleRangePickerChange, values, ...props}) => {
        const fromValue = values.from;
        const toValue = values.to;
        return (
          <div className="container">
            <div className="row">
              <div className="scol-12">
                <LabelWrapper id={'filter-updated-at'} contentWrapperStyle={{marginBottom: 0}}>
                  <DatePickerPairLayout>
                    <StyledRangePicker
                      data-cy={'DatePickerFromDate DatePickerToDate'}
                      format={DateTimeFormat.Date}
                      value={[fromValue, toValue]}
                      placeholder={['from date', 'to date']}
                      onChange={value => {
                        const [from, to] = [...(value ?? [null, null])];
                        handleRangePickerChange({from, to});
                      }}
                    />
                  </DatePickerPairLayout>
                </LabelWrapper>
              </div>
              {props.validations?.to && <div className="error">{props.validations.to}</div>}
            </div>
          </div>
        );
      }}
    </FilterButton>
  );
};

export default UpdatedAt;
