import {FC} from 'react';
import {NegotiationReplyTime} from '../../../../api/node-backend/generated';
import {CountdownLabel} from './CountdownLabel';
import {getExpiryDateFromReplyTime, getIsReplyTimeSet} from './utils';

type ReplyTimeLabelProps = {
  replyTime: NegotiationReplyTime | null;
  committedAt: Date | null;
  dateTimePrefix?: string;
  dateTimeSuffix?: string;
  countdownPrefix?: string;
  countdownSuffix?: string;
  suffix?: string;
  expiredText?: string;
  emptyText?: string;
  style?: React.CSSProperties;
};

export const ReplyTimeLabel: FC<ReplyTimeLabelProps> = ({
  replyTime,
  committedAt,
  dateTimePrefix,
  dateTimeSuffix,
  countdownPrefix,
  countdownSuffix,
  emptyText,
  expiredText,
  style,
}) => {
  if (!getIsReplyTimeSet(replyTime) || !committedAt) {
    return (
      <span style={style} data-testid="ReplyTimeLabel">
        {emptyText}
      </span>
    );
  }

  const expiryDate = getExpiryDateFromReplyTime(replyTime, committedAt);

  return (
    <span style={style} data-testid="ReplyTimeLabel">
      <CountdownLabel
        countdownModeDefault={false}
        expiryDate={expiryDate}
        dateTimePrefix={dateTimePrefix}
        dateTimeSuffix={dateTimeSuffix}
        countdownPrefix={countdownPrefix}
        countdownSuffix={countdownSuffix}
        expiredText={expiredText}
      />
    </span>
  );
};
