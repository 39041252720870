import {distance} from '../utils/distance';
import {AnimationSeaSegment, AnimationSeaSegmentWithoutRedundantFields} from '../model/AnimationEvent';
import * as speed from '../utils/speed';
import {AISRecord} from '../queries/aisRecordsQuery/AISRecord';
import {calculateHeading} from '../queries/aisRecordsQuery/calculateHeading';

export const calculateRedundantFields = (
  seaSegmentWithoutRedundantFields: AnimationSeaSegmentWithoutRedundantFields
): AnimationSeaSegment => {
  const {from, to} = seaSegmentWithoutRedundantFields;
  const durationInMinutes = to.timestamp.diff(from.timestamp, 'minute');
  const distanceInKM = distance(from.coordinates, to.coordinates);
  const speedInKnots = speed.speedInKnots(distanceInKM, durationInMinutes);
  const standingStill = isStandingStill(speedInKnots);
  const heading = calculateHeadingFromTo(from, to, standingStill);
  const timestamp = from.timestamp;
  const fullSeaSegment: AnimationSeaSegment = {
    ...seaSegmentWithoutRedundantFields,
    timestamp,
    durationInMinutes,
    distanceInKM,
    speedInKnots,
    standingStill,
    heading,
  };
  return fullSeaSegment;
};

const isStandingStill = (speedInKnots: number): boolean => speedInKnots < 0.5;

const calculateHeadingFromTo = (from: AISRecord, to: AISRecord, moored: boolean): number => {
  const heading = moored
    ? // Trust the heading from the AIS record.
      from.heading
    : calculateHeading(from, to);
  return heading;
};
