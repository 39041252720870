import {useMemo, useState} from 'react';
import orderBy from 'lodash/orderBy';
import {useQuery} from '@tanstack/react-query';
import {distanceDWTColumn} from './distanceDWTColumn';
import {dwtColumn} from './dwtColumn';
import {arrivalColumn} from './arrivalColumn';
import {ResultVessel} from '../types';
import {lastPortColumn} from './lastPortColumn';
import NoResults from '../../../market/EmptyState/NoResults';
import {speedColumn} from '../../../../components/GridLayout/columns/vessel/speedColumn';
import {DraftItem, useDraftStatsQuery} from '../../../../utils/vessel/useDraftStatsQuery';
import {getIsBallast} from '../../../../utils/vessel/getIsBallast';
import {ballastColumn} from '../../../../components/GridLayout/columns/vessel/ballastColumn';
import {shipNameColumn} from '../../../../components/GridLayout/columns/vessel/shipNameColumn/shipNameColumn';
import {grainColumn} from '../../../../components/GridLayout/columns/vessel/grainColumn';
import {gearsColumn} from '../../../../components/GridLayout/columns/vessel/gearsColumn';
import {lengthColumn} from '../../../../components/GridLayout/columns/vessel/lengthColumn';
import {draftColumn} from '../../../../components/GridLayout/columns/vessel/draftColumn';
import {fetchJSON} from '../../../../api/utils/fetchJSON';
import {TODO} from '../../../../utils/TODO';
import {annotationColumn} from '../annotations/annotationColumn';
import {DataGrid} from '../../../../components/DataGrid/DataGrid';

type VesselWithIsBallast = ResultVessel & {
  isBallast: boolean | undefined;
};

const extendVesselWithIsBallast = ({
  vessel,
  draftItems,
}: {
  vessel: ResultVessel;
  draftItems?: DraftItem[];
}): VesselWithIsBallast => {
  const draftItem = draftItems?.find(item => item.imo === vessel.imo);
  const isBallast = getIsBallast({vessel, draftItem});
  return {...vessel, isBallast};
};

export const Grid = (props: {vessels: ResultVessel[]; onHoverVessel?: (vessel: ResultVessel | null) => void}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const draftStatsQuery = useDraftStatsQuery();
  const [sort, setSort] = useState<{id: string; desc: boolean}[]>([
    {
      id: 'distanceInMeters',
      desc: false,
    },
  ]);
  const [pageSize, setPageSize] = useState(10);

  const rawVessels = useMemo(() => {
    let vessels: VesselWithIsBallast[] = props.vessels.map(vessel =>
      extendVesselWithIsBallast({vessel, draftItems: draftStatsQuery.data})
    );

    vessels = sort.length === 0 ? vessels : orderBy(vessels, [sort[0].id], [sort[0].desc ? 'desc' : 'asc']);

    return vessels.slice(pageSize * (pageNumber - 1), pageSize * pageNumber);
  }, [pageSize, pageNumber, props.vessels, sort, draftStatsQuery.data]);
  const imos: (number | undefined)[] = rawVessels.map(({imo}) => imo);
  const dataSourceQuery = useQuery({
    queryKey: ['competitorsGrid', imos],
    queryFn: async () => {
      const vesselPromises = rawVessels.map(async vessel => {
        const detailsData = await fetchJSON<TODO>(`/api/vessels/${vessel.vesselId}`);
        return {...detailsData, ...vessel};
      });
      return Promise.all(vesselPromises);
    },
  });

  const totalCount = props.vessels.length;
  return (
    <DataGrid
      getTrProps={(_: TODO, rowInfo) => {
        return {
          onMouseOver: () => {
            props.onHoverVessel?.(rowInfo?.original);
          },
          onMouseLeave: () => {
            props.onHoverVessel?.(null);
          },
        };
      }}
      className={'potential-competitors '}
      showPageSizeOptions={true}
      totalCount={totalCount}
      pageSize={pageSize}
      page={pageNumber}
      onPageChange={newPage => {
        setPageNumber(newPage);
      }}
      onPageSizeChange={newPageSize => {
        setPageSize(newPageSize);
        setPageNumber(1);
      }}
      columns={[
        shipNameColumn({options: {openAsNewTab: true}}),
        distanceDWTColumn,
        lastPortColumn({}),
        arrivalColumn({}),
        annotationColumn(),
        dwtColumn,
        grainColumn({}),
        ballastColumn({}),
        speedColumn({}),
        gearsColumn({}),
        lengthColumn({}),
        draftColumn({}),
      ]}
      onSortedChange={sort => {
        setSort([sort]);
      }}
      data={dataSourceQuery.data ?? []}
      loading={dataSourceQuery.isLoading}
      zeroBasedPageIndex={false}
      TbodyComponent={dataSourceQuery.data?.length ? null : <NoResults type={'vessels'} />}
      noHoverStatePointer
    />
  );
};
