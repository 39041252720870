import {useInvalidateNegotiationQueries} from '../../../../utils/useInvalidateNegotiationQueries';
import {DerivedNegotiationTermState} from '../../TermItem/utils/getDerivedTermState';
import {useAcceptMultipleTermsMutation} from './useAcceptMultipleTermsMutation';
import {useAcceptRejectionRequestMultipleTermsMutation} from './useAcceptRejectionRequestMultipleTermsMutation';
import {useDeleteMultipleTermsMutation} from './useDeleteMultipleTermsMutation';
import {useRejectMultipleTermsMutation} from './useRejectMultipleTermsMutation';
import {useRejectRejectionRequestMultipleTermsMutation} from './useRejectRejectionRequestMultipleTermsMutation';
import {useResetMultipleTermsMutation} from './useResetMultipleTermsMutation';

export type SidebarActionsHook = (
  derivedTermStates: {id: string; state: DerivedNegotiationTermState}[]
) => SidebarActions;

export type SidebarActions = {
  onAccept: () => void;
  onReject: () => void;
  onDelete: () => void;
  onReset: () => void;
  sidebarActionBusy?: boolean;
};

export const sidebarActionsNoop = {
  onAccept: () => {},
  onReject: () => {},
  onDelete: () => {},
  onReset: () => {},
  sidebarActionBusy: false,
};

export const useGetSidebarActionsWithMutations = (
  negotiationId?: string,
  roundResultId?: string
): {
  getSidebarActions: SidebarActionsHook;
  sidebarActionBusy: boolean;
} => {
  const invalidateNegotiationQueries = useInvalidateNegotiationQueries(negotiationId, roundResultId);

  const acceptMultipleTermsMutation = useAcceptMultipleTermsMutation({onSuccess: invalidateNegotiationQueries});
  const rejectMultipleTermsMutation = useRejectMultipleTermsMutation({onSuccess: invalidateNegotiationQueries});
  const acceptRejectionRequestMultipleTermsMutation = useAcceptRejectionRequestMultipleTermsMutation({
    onSuccess: invalidateNegotiationQueries,
  });
  const rejectRejectionRequestMultipleTermsMutation = useRejectRejectionRequestMultipleTermsMutation({
    onSuccess: invalidateNegotiationQueries,
  });
  const deleteMultipleTermsMutation = useDeleteMultipleTermsMutation({onSuccess: invalidateNegotiationQueries});
  const resetMultipleTermsMutation = useResetMultipleTermsMutation({onSuccess: invalidateNegotiationQueries});

  const sidebarActionBusy =
    acceptMultipleTermsMutation.isPending ||
    rejectMultipleTermsMutation.isPending ||
    acceptRejectionRequestMultipleTermsMutation.isPending ||
    rejectRejectionRequestMultipleTermsMutation.isPending ||
    deleteMultipleTermsMutation.isPending ||
    resetMultipleTermsMutation.isPending;

  if (!negotiationId || !roundResultId) {
    return {
      getSidebarActions: () => sidebarActionsNoop,
      sidebarActionBusy,
    };
  }

  const getSidebarActions = (derivedTermStates: {id: string; state: DerivedNegotiationTermState}[]) => {
    const deletableTermIds = derivedTermStates.filter(({state}) => state.canBeDeleted).map(({id}) => id);
    const onDelete = () => {
      deleteMultipleTermsMutation.mutate(deletableTermIds);
    };

    const rejectRequestSettleableTermIds = derivedTermStates
      .filter(({state}) => state.canBeRejectRequestSettled)
      .map(({id}) => id);

    const acceptableTermIds = derivedTermStates.filter(({state}) => state.canBeAccepted).map(({id}) => id);
    const onAccept = () => {
      if (acceptableTermIds.length) {
        acceptMultipleTermsMutation.mutate(acceptableTermIds);
      }
      if (rejectRequestSettleableTermIds.length) {
        acceptRejectionRequestMultipleTermsMutation.mutate(rejectRequestSettleableTermIds);
      }
    };

    const rejectableTermIds = derivedTermStates.filter(({state}) => state.canBeRejectionRequested).map(({id}) => id);
    const onReject = () => {
      if (rejectableTermIds.length) {
        rejectMultipleTermsMutation.mutate(rejectableTermIds);
      }
      if (rejectRequestSettleableTermIds.length) {
        rejectRejectionRequestMultipleTermsMutation.mutate(rejectRequestSettleableTermIds);
      }
    };

    const resettableTermIds = derivedTermStates.filter(({state}) => state.canBeReset).map(({id}) => id);
    const onReset = () => {
      resetMultipleTermsMutation.mutate(resettableTermIds);
    };

    return {
      onAccept,
      onReject,
      onDelete,
      onReset,
      sidebarActionBusy,
    };
  };

  return {
    getSidebarActions,
    sidebarActionBusy,
  };
};
