import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import locationApi from '../../../redux/ApiService/locationService';
import {acceptFloatOrEmpty, acceptIntOrEmpty} from '../../../utils/formatter';
import {Button} from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {AsyncSelect} from '../../../atoms/Select/AsyncSelect';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import Input from '../../../atoms/Input';
import Checkbox from '../../../atoms/Checkbox';
import IconButton from '../../../atoms/IconButton';
import {
  DropdownMultiValueLocation,
  DropdownSingleValueLocation,
  getLocationSelectOption,
} from '../../../components/LocationOutput/LocationOutputRow';
import {getLocationValues} from '../../../components/LocationOutput/getLocationValues';
import DetailsSection from '../../../atoms/DetailsSection';
import CompoundInput from '../../../atoms/CompoundInput';
import HorizontalLine from '../../../atoms/HorizontalLine';
import {GroupHeading} from '../../../atoms/Select/otherComponents';
import Select from '../../../atoms/Select/Select';
import {AsyncMultiSelect} from '../../../atoms/Select/AsyncMultiSelect';
import {alwaysValid} from '../../../utils/validators';

const locationUnitTypes = {
  mt: 'MT',
  cbm: 'CBM',
  cft: 'CFT',
  day: 'DAY',
};

const locationTermTypes = {
  fhex: 'FHEX',
  finc: 'FINC',
  phex: 'PHEX',
  'satpm shex': 'SATPM SHEX',
  sshex: 'SSHEX',
  shex: 'SHEX',
  shinc: 'SHINC',
  sshinc: 'SSHINC',
  ssex: 'SSEX',
  ssinc: 'SSINC',
};

const newLocation = {
  location: null,
  cqd: false,
  rate: '',
  terms: '',
  timeToTurn: '',
  unit: '',
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rows' implicitly has an 'any' type.
const transformLocationRowsForApi = (rows, type) => ({
  type: type,

  locations:
    rows && rows[0]
      ? rows.map((row: $TSFixMe) =>
          row.location && row.location.id
            ? {
                id: row.location.id,
                type: row.location.type,
                additionalInfo: {
                  ...row,
                  rate: parseFloat(row.rate),
                  timeToTurn: parseInt(row.timeToTurn),
                  location: null,
                },
              }
            : {}
        )
      : [],
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'locations' implicitly has an 'any' type... Remove this comment to see the full error message
const transformLocationFieldForApi = (locations, type) => {
  return {
    type: type,

    locations:
      locations && locations[0]
        ? locations.map((location: $TSFixMe) => ({
            id: location.id,
            type: location.type,
          }))
        : [],
  };
};

const transformFromApiToFields = (stations: $TSFixMe) =>
  stations.reduce((values: $TSFixMe, field: $TSFixMe) => {
    switch (field.type) {
      case 'loading':
      case 'discharge':
        values[field.type] = field.locations
          ? field.locations.map((row: $TSFixMe) => ({
              location: {id: row.id, code: row.code, type: row.type, name: row.name, countryObject: row.countryObject},
              cqd: row.additionalInfo?.cqd || false,
              rate: row.additionalInfo?.rate || '',
              terms: row.additionalInfo?.terms || '',
              timeToTurn: row.additionalInfo?.timeToTurn || '',
              unit: row.additionalInfo?.unit || '',
            }))
          : [];
        break;
      case 'delivery':
      case 'deliveryvia':
      case 'redelivery':
        values[field.type] = field.locations ? field.locations : [];
        break;
      default:
    }
    return values;
  }, {});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'validations' implicitly has an 'any' ty... Remove this comment to see the full error message
const transformValidationsFromApi = (validations, contractType) => ({
  loading:
    (contractType === 'vc' && validations.stations[0] && validations.stations[0].locations) || validations.loading,

  discharge:
    (contractType === 'vc' && validations.stations[1] && validations.stations[1].locations) || validations.discharge,

  delivery:
    (contractType !== 'vc' && validations.stations[0] && validations.stations[0].locations) || validations.delivery,

  deliveryvia:
    (contractType !== 'vc' && validations.stations[1] && validations.stations[1].locations) || validations.deliveryvia,

  redelivery:
    (contractType !== 'vc' && validations.stations[2] && validations.stations[2].locations) || validations.redelivery,
});

const Wrapper = BindToProvider(
  'Loading',
  'stations',
  {
    stations: null,
    loading: [newLocation],
    discharge: [newLocation],
    delivery: [],
    deliveryvia: [],
    redelivery: [],
  },
  {
    loading: alwaysValid,
    discharge: alwaysValid,
  },
  {
    toApi: ({loading, discharge, delivery, deliveryvia, redelivery}: $TSFixMe, {contractType}: $TSFixMe) => {
      const stations = [];
      if (contractType.contractType && contractType.contractType === 'vc') {
        stations.push(transformLocationRowsForApi(loading, 'loading'));
        stations.push(transformLocationRowsForApi(discharge, 'discharge'));
      } else if (contractType.contractType && contractType.contractType !== 'vc') {
        stations.push(transformLocationFieldForApi(delivery, 'delivery'));
        stations.push(transformLocationFieldForApi(deliveryvia, 'deliveryvia'));
        stations.push(transformLocationFieldForApi(redelivery, 'redelivery'));
      }
      return {stations: stations};
    },
    fromApi: (fields: $TSFixMe) => ({
      ...(fields.stations && fields.stations[0] && transformFromApiToFields(fields.stations)),
      stations: null,
    }),
  },
  true,
  false
)(WrappedDetailsSection);

const LocationRows = ({
  rows = [],
  validation,
  onChange,
  searchAll,
  type = 'location',
  rateLabel = 'Rate',
}: $TSFixMe) => {
  const onRowChange = (field: $TSFixMe, value: $TSFixMe, i: $TSFixMe) => {
    if (field === 'cqd' && value === true) {
      rows[i] = {...newLocation, location: rows[i].location};
    }
    onChange([
      ...rows.slice(0, i),
      {
        ...rows[i],
        [field]: value,
      },
      ...rows.slice(i + 1),
    ]);
  };

  const onRemove = (i: $TSFixMe) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
    onChange(rows.filter((v, idx) => idx !== i));
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const locationUnitOptions = Object.keys(locationUnitTypes).map(value => ({value, name: locationUnitTypes[value]}));

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const locationTermOptions = Object.keys(locationTermTypes).map(value => ({value, name: locationTermTypes[value]}));

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  return rows.map((values, i) => (
    <Fragment key={i}>
      <div className="row">
        <div className="scol-10">
          <LabelWrapper
            label={(rows.length > 1 ? `${i + 1}. ` : '') + (type === 'location' ? 'Load port' : 'Discharge port')}
            htmlFor={`form-stations-location-${type}-${i}`}>
            <AsyncSelect
              id={`form-stations-location-${type}-${i}`}
              name={`form-stations-location-${type}-${i}`}
              value={values.location}
              onChange={(o: $TSFixMe) => onRowChange('location', o, i)}
              getOptionLabel={(o: $TSFixMe) => getLocationSelectOption(o, true)}
              getOptionValue={(option: $TSFixMe) => option.id}
              loadOptions={(q: $TSFixMe) => (q.length > 1 ? searchAll(q, 'vc') : Promise.resolve([]))}
              cacheOptions
              defaultOptions
              placeholder="Enter port, country or area"
              components={{GroupHeading, SingleValue: DropdownSingleValueLocation}}
              isClearable
            />
          </LabelWrapper>
        </div>
        <div className="scol-2">
          <div className="cargo-vessel-form__v-centered-field">
            <IconButton
              id={`form-stations-location-remove-${type}-${i}`}
              type={'scrap'}
              iconStyle={{cursor: 'pointer'}}
              onClick={() => onRemove(i)}
              title={'Remove'}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="scol-12 scol-sm-6 scol-md-4">
          <LabelWrapper
            label={rateLabel}
            htmlFor={`form-stations-rate-${type}-${i}`}
            hasError={validation[i] && validation[i].rate && validation[i].rate.invalid}>
            <CompoundInput>
              <Input
                id={`form-stations-rate-${type}-${i}`}
                onChange={v => acceptFloatOrEmpty(v, v => onRowChange('rate', v, i), 7, 5)}
                value={values.rate}
                hasError={validation[i] && validation[i].rate && validation[i].rate.invalid}
                disabled={values.cqd}
              />
              <Select
                id={`form-stations-unit-${type}-${i}`}
                name={`form-stations-unit-${type}-${i}`}
                options={locationUnitOptions}
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                value={{value: values.unit, name: locationUnitTypes[values.unit]}}
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                defaultValue={{value: values.unit, name: locationUnitTypes[values.unit]}}
                onChange={(o: $TSFixMe) => onRowChange('unit', o.value, i)}
                getOptionLabel={(o: $TSFixMe) => o.name}
                getOptionValue={(o: $TSFixMe) => o.value}
                components={{DropdownIndicator: ArrowIndicator}}
                isSearchable={false}
                isDisabled={values.cqd}
                hasError={validation[i] && validation[i].unit && validation[i].unit.invalid}
              />
            </CompoundInput>
          </LabelWrapper>
          {validation[i] && validation[i].rate && validation[i].rate.error && (
            <p className="text-danger text-danger--no-margin">{validation[i].rate.error}</p>
          )}
          {validation[i] && validation[i].unit && validation[i].unit.error && (
            <p className="text-danger text-danger--no-margin">{validation[i].unit.error}</p>
          )}
        </div>
        <div className="scol-12 scol-sm-6 scol-md-4">
          <LabelWrapper label={'Terms'} htmlFor={`form-stations-terms-${i}`}>
            <Select
              id={`form-stations-terms-${type}-${i}`}
              name={`form-stations-terms-${type}-${i}`}
              options={locationTermOptions}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value={{value: values.terms, name: locationTermTypes[values.terms]}}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              defaultValue={{value: values.terms, name: locationTermTypes[values.terms]}}
              onChange={(o: $TSFixMe) => onRowChange('terms', (o && o.value) || '', i)}
              getOptionLabel={(o: $TSFixMe) => o.name}
              getOptionValue={(o: $TSFixMe) => o.value}
              components={{DropdownIndicator: ArrowIndicator}}
              isSearchable
              isClearable
              isDisabled={values.cqd}
            />
          </LabelWrapper>
        </div>
        <div className="scol-12 scol-sm-6 scol-md-2">
          <LabelWrapper
            label={'TT'}
            htmlFor={`form-stations-tt-${type}-${i}`}
            hasError={validation[i] && validation[i].timeToTurn && validation[i].timeToTurn.invalid}>
            <Input
              id={`form-stations-tt-${type}-${i}`}
              onChange={v => acceptIntOrEmpty(v, v => onRowChange('timeToTurn', v, i))}
              value={values.timeToTurn}
              hasError={validation[i] && validation[i].timeToTurn && validation[i].timeToTurn.invalid}
              disabled={values.cqd}
              maxLength={4}
            />
          </LabelWrapper>
          {validation[i] && validation[i].timeToTurn && validation[i].timeToTurn.error && (
            <p className="text-danger text-danger--no-margin">{validation[i].timeToTurn.error}</p>
          )}
        </div>
        <div className="scol-12 scol-sm-4 scol-md-2">
          <div className="cargo-vessel-form__v-centered-field">
            <Checkbox
              id={`form-stations-cqd-${type}-${i}`}
              label={'CQD'}
              title={'Customary quick dispatch'}
              checked={values.cqd}
              onChange={e => onRowChange('cqd', e.target.checked, i)}
            />
          </div>
        </div>
      </div>
      <HorizontalLine large topSpace={'small'} />
    </Fragment>
  ));
};

const AddLocationButton = ({rows, onChange, type = 'loading'}: $TSFixMe) => (
  <Button
    id={`form-stations-${type}-intake-add`}
    icon
    label={
      <Fragment>
        <Icon type={'item-add-selection-solid'} color="blue" style={{marginRight: 6, verticalAlign: 'bottom'}} />
        {rows.length === 0 ? 'ADD LOCATION AREA OR PORT' : 'ADD ANOTHER LOCATION AREA OR PORT'}
      </Fragment>
    }
    onClick={() => onChange(type, [...rows, newLocation])}
  />
);

const Stations = (props: $TSFixMe) => {
  const {form, isEdit} = props;
  const marketSegment = form && form.marketSegment;

  useEffect(() => {
    if (!isEdit && marketSegment) {
      if (marketSegment.cargoType === 'drybulk') {
        newLocation.unit = 'mt';
      } else if (marketSegment.cargoType === 'container') {
        newLocation.unit = 'day';
      }
    }
  }, [marketSegment, isEdit]);

  return (
    <Wrapper title={'Load rate'} {...props}>
      {({onChange, values, validations}: $TSFixMe) => {
        const transformedApiValidations = transformValidationsFromApi(
          validations,
          props.form.contractType.contractType
        );
        return (
          <Fragment>
            {props.form &&
              props.form.contractType &&
              props.form.contractType.contractType &&
              props.form.contractType.contractType === 'vc' && (
                <Fragment>
                  <DetailsSection title={'Loading'} hasBorderBottom={true}>
                    <div className="container-fluid">
                      <LocationRows
                        rows={values.loading}
                        validation={transformedApiValidations.loading}
                        onChange={(values: $TSFixMe) => onChange('loading', values)}
                        searchAll={props.searchAll}
                        rateLabel={'Load rate'}
                      />
                      <div className={'row'}>
                        <div className={'scol-12 cargo-vessel-form__icon-button'}>
                          <AddLocationButton rows={values.loading} onChange={onChange} type={'loading'} />
                        </div>
                      </div>
                    </div>
                  </DetailsSection>
                  <DetailsSection title={'Discharge'} hasBorderBottom={true}>
                    <div className="container-fluid">
                      <LocationRows
                        rows={values.discharge}
                        validation={transformedApiValidations.discharge}
                        onChange={(values: $TSFixMe) => onChange('discharge', values)}
                        searchAll={props.searchAll}
                        type={'discharge'}
                        rateLabel={'Discharge rate'}
                      />
                      <div className={'row'}>
                        <div className={'scol-12 cargo-vessel-form__icon-button'}>
                          <AddLocationButton rows={values.discharge} onChange={onChange} type={'discharge'} />
                        </div>
                      </div>
                    </div>
                  </DetailsSection>
                </Fragment>
              )}
            {props.form &&
              props.form.contractType &&
              props.form.contractType.contractType &&
              props.form.contractType.contractType !== 'vc' && (
                <Fragment>
                  <DetailsSection title={'Delivery'} hasBorderBottom={true}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="scol-12">
                          <LabelWrapper label={'Location'} htmlFor={`form-stations-delivery`}>
                            <AsyncMultiSelect
                              id={`form-stations-delivery`}
                              name={`form-stations-delivery`}
                              value={values.delivery}
                              onChange={(value: $TSFixMe) => onChange('delivery', getLocationValues(value))}
                              getOptionLabel={(o: $TSFixMe) => getLocationSelectOption(o, true)}
                              getOptionValue={(option: $TSFixMe) => option.id}
                              loadOptions={(q: $TSFixMe) => (q.length > 1 ? props.searchAll(q) : Promise.resolve([]))}
                              cacheOptions
                              defaultOptions
                              placeholder="Enter port, country or area"
                              components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                            />
                          </LabelWrapper>
                        </div>
                      </div>
                    </div>
                  </DetailsSection>
                  <DetailsSection title={'Trade / Via'} hasBorderBottom={true}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="scol-12">
                          <LabelWrapper label={'Location'} htmlFor={`form-stations-delivery`}>
                            <AsyncMultiSelect
                              id={`form-stations-delivery`}
                              name={`form-stations-delivery`}
                              value={values.deliveryvia}
                              onChange={(value: $TSFixMe) => onChange('deliveryvia', getLocationValues(value))}
                              getOptionLabel={(o: $TSFixMe) => getLocationSelectOption(o, true)}
                              getOptionValue={(option: $TSFixMe) => option.id}
                              loadOptions={(q: $TSFixMe) => (q.length > 1 ? props.searchAll(q) : Promise.resolve([]))}
                              cacheOptions
                              defaultOptions
                              placeholder="Enter port, area, or country"
                              components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                            />
                          </LabelWrapper>
                        </div>
                      </div>
                    </div>
                  </DetailsSection>
                  <DetailsSection title={'Redelivery'} hasBorderBottom={true}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="scol-12">
                          <LabelWrapper label={'Location'} htmlFor={`form-stations-redelivery`}>
                            <AsyncMultiSelect
                              id={`form-stations-redelivery`}
                              name={`form-stations-redelivery`}
                              value={values.redelivery}
                              onChange={(value: $TSFixMe) => onChange('redelivery', getLocationValues(value))}
                              getOptionLabel={(o: $TSFixMe) => getLocationSelectOption(o, true)}
                              getOptionValue={(option: $TSFixMe) => option.id}
                              loadOptions={(q: $TSFixMe) => (q.length > 1 ? props.searchAll(q) : Promise.resolve([]))}
                              cacheOptions
                              defaultOptions
                              placeholder="Enter port, country or area"
                              components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                            />
                          </LabelWrapper>
                        </div>
                      </div>
                    </div>
                  </DetailsSection>
                </Fragment>
              )}
          </Fragment>
        );
      }}
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  searchAll: (search: $TSFixMe, type: $TSFixMe) => dispatch(locationApi.searchAll(search, type)),
});

export default connect(null, mapDispatchToProps)(Stations);
