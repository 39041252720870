import {createSelector} from 'reselect';
import {LAYERS} from '../components/SeaboMap/const';
import {
  isMapVesselElement,
  MapDetailItem as MapDetailPortPreviewItem,
  MapDetailItemType as MapDetailPortPreviewItemType,
  MapElement,
  MapPortElement,
  PortAreaCV,
} from '../components/SeaboMap/MapDetails/utils/types';
import {actionTypes as mapApi} from './ApiService/MapService/MapService';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {RootState} from './store';

export const actionsTypes = {
  SET_MAP_ELEMENT: 'MAP_DETAILS_SET_MAP_ELEMENT',
  SET_LAST_PORT: 'MAP_DETAILS_SET_LAST_PORT',
  SET_PORT_PREVIEW_ITEM: 'MAP_DETAILS_SET_PORT_PREVIEW_ITEM',
  SET_SORT_OPTIONS: 'MAP_DETAILS_SET_SORT_OPTIONS',
};

export type CargoSortOptions = '' | 'size' | 'laycan';
export type VesselSortOptions = '' | 'size' | 'year' | 'dateOpen';

interface MapDetailsState {
  layerId: keyof typeof LAYERS | '';
  mapElement: MapElement | null;
  lastPort: MapPortElement | null;
  portPreviewItem: MapDetailPortPreviewItem | null;
  portPreviewItemType: MapDetailPortPreviewItemType | null;
  cargoSort: CargoSortOptions;
  cargoSortDesc: boolean;
  vesselSort: VesselSortOptions;
  vesselSortDesc: boolean;
  portCV: PortAreaCV | null;
  portCVLazyLoading: boolean;
}

const initialState: MapDetailsState = {
  layerId: '',
  mapElement: null,
  lastPort: null,
  portPreviewItem: null,
  portPreviewItemType: null,
  cargoSort: '',
  cargoSortDesc: false,
  vesselSort: '',
  vesselSortDesc: false,
  portCV: null,
  portCVLazyLoading: false,
};

export const mapDetailsReducer = (state = initialState, {type, payload}: ActionWithPayload): MapDetailsState => {
  switch (type) {
    case actionsTypes.SET_MAP_ELEMENT: {
      if (payload === null) {
        return {
          ...state,
          layerId: '',
          mapElement: null,
          lastPort: null,
        };
      }
      return {
        ...state,
        // makes update recognition more consistent
        layerId: payload.layer.id,
        mapElement: {...payload},
      };
    }
    case actionsTypes.SET_LAST_PORT: {
      return {
        ...state,
        lastPort: {...payload},
      };
    }
    case actionsTypes.SET_PORT_PREVIEW_ITEM: {
      if (!payload.item) {
        return {
          ...state,
          portPreviewItem: null,
          portPreviewItemType: null,
        };
      }
      return {
        ...state,
        portPreviewItem: {...payload.item},
        portPreviewItemType: payload.type,
      };
    }
    case actionsTypes.SET_SORT_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    case mapApi.getPortItems.PENDING:
    case mapApi.getAreaItems.PENDING: {
      const isLazyLoad = payload.queryParams && payload.queryParams.pageIndex > 1;
      return {
        ...state,
        portPreviewItem: null,
        portPreviewItemType: null,
        portCV: isLazyLoad ? state.portCV : initialState.portCV,
        portCVLazyLoading: isLazyLoad,
      };
    }
    case mapApi.getPortItems.SUCCESS:
    case mapApi.getAreaItems.SUCCESS:
      if (state.portCVLazyLoading && state.portCV) {
        return {
          ...state,
          portPreviewItem: null,
          portPreviewItemType: null,
          ...mergeLazyLoadedPortCV(state, payload),
        };
      }
      return {
        ...state,
        portPreviewItem: null,
        portPreviewItemType: null,
        portCV: {...payload},
      };
    default:
      return state;
  }
};

export const setMapDetailElement = (element: MapElement | null) => ({
  type: actionsTypes.SET_MAP_ELEMENT,
  payload: element,
});
export const setMapDetailLastPort = (element: MapPortElement | null) => ({
  type: actionsTypes.SET_LAST_PORT,
  payload: element,
});

export const setMapDetailPortPreviewItem = (
  item: MapDetailPortPreviewItem | null,
  type: MapDetailPortPreviewItemType | null
) => ({
  type: actionsTypes.SET_PORT_PREVIEW_ITEM,
  payload: {item, type},
});

export const setMapDetailSort = (newSortOptions: {
  cargoSort?: CargoSortOptions;
  cargoSortDesc?: boolean;
  vesselSort?: VesselSortOptions;
  vesselSortDesc?: boolean;
}) => ({
  type: actionsTypes.SET_SORT_OPTIONS,
  payload: newSortOptions,
});

export const activeVesselElementSelector = createSelector(
  ({mapDetails}: RootState) => mapDetails.mapElement,
  (mapElement: MapElement | null) => (isMapVesselElement(mapElement) ? mapElement : null)
);

const mergeLazyLoadedPortCV = (state: MapDetailsState, payload: PortAreaCV): {portCV: PortAreaCV} => ({
  portCV: {
    portfolio: {
      cargoes: {
        ...(state.portCV?.portfolio.cargoes ?? {totalItems: 0}),
        items: [...(state.portCV?.portfolio.cargoes.items ?? []), ...payload.portfolio.cargoes.items],
      },
      vessels: {
        ...(state.portCV?.portfolio.vessels ?? {totalItems: 0}),
        items: [...(state.portCV?.portfolio.vessels.items ?? []), ...payload.portfolio.vessels.items],
      },
    },
    market: {
      cargoes: {
        ...(state.portCV?.market.cargoes ?? {totalItems: 0}),
        items: [...(state.portCV?.market.cargoes.items ?? []), ...payload.market.cargoes.items],
      },
      vessels: {
        ...(state.portCV?.market.vessels ?? {totalItems: 0}),
        items: [...(state.portCV?.market.vessels.items ?? []), ...payload.market.vessels.items],
      },
    },
  },
});
