import React, {FC} from 'react';
import {Tooltip} from 'antd';
import {CalcTimeLinePoint, CalcTimeLineRoute} from '../../VoyageTypes';
import {RouteOverlay} from './RouteOverlay';
import {PointOverlay} from './PointOverlay';

export const DurationExplainTooltip: FC<{
  point?: CalcTimeLinePoint;
  route?: CalcTimeLineRoute;
  children: React.ReactNode;
}> = props => {
  const item = props.point ?? props.route;

  const isRoute = item?.nodeType === 'route';
  return (
    <Tooltip
      overlayInnerStyle={{width: isRoute ? 260 : 400}}
      color={'white'}
      trigger={['hover']}
      placement={'bottomRight'}
      overlay={isRoute ? <RouteOverlay route={item} /> : <PointOverlay point={item!} />}>
      {props.children}
    </Tooltip>
  );
};
