export enum OnboardingStep {
  VerifyCompany = 0,
  AddUsers = 1,
  ConfigureWorkspaces = 2,
  UpgradePlan = 3,
  SetupSegments = 4,
  Finished = 5,
}

export const getLastNotCompletedOnboardingStep = ({
  userSkippedOnboarding,
  isCompanyAdmin,
  isCompanyOnboarded,
  isVerified,
  hasMembers,
  hasWorkspaces,
  hasProPlan,
  isUserOnboarded,
  hasSelfCategorization,
}: {
  userSkippedOnboarding?: boolean;
  isCompanyAdmin: boolean;
  isCompanyOnboarded: boolean;
  isVerified: boolean;
  hasMembers: boolean;
  hasWorkspaces: boolean;
  hasProPlan: boolean;
  isUserOnboarded?: boolean;
  hasSelfCategorization: boolean;
}) => {
  if (userSkippedOnboarding) {
    return null;
  }
  if (isCompanyAdmin && !isCompanyOnboarded) {
    if (!isVerified) {
      return OnboardingStep.VerifyCompany;
    }
    if (!hasMembers) {
      return OnboardingStep.AddUsers;
    }
    if (!hasWorkspaces) {
      return OnboardingStep.ConfigureWorkspaces;
    }
    if (!hasProPlan) {
      return OnboardingStep.UpgradePlan;
    }
  }
  if (!isUserOnboarded) {
    if (!hasSelfCategorization) {
      return OnboardingStep.SetupSegments;
    }
    return OnboardingStep.Finished;
  }
  return null;
};
