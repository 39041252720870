export const sortingFnForStrings = (a: string | null | undefined, b: string | undefined | null) => {
  const aString = a ?? '';
  const bString = b ?? '';

  if (aString < bString) {
    return -1;
  }
  if (bString < aString) {
    return 1;
  }

  return 0;
};
