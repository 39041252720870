import {actions as mapApi, actionTypes as mapApiActionTypes} from './ApiService/MapService/MapService';
import {SelectedItem} from '../components/SeaboMap/MapContext/Types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TODO} from '../utils/TODO';
import type {ViewState} from '../components/SeaboMap/ViewState';

export const actionsTypes = {
  MAP_SET_SETTINGS: 'MAP_SET_SETTINGS',
};

export type MapSize = {
  width: number;
  height: number;
} | null;

export type SelectedItems = {
  items: Array<SelectedItem>;
  type: string;
};
export type OpenSeaMapData = TODO;

export type LayersData = {
  openSeaMapData?: OpenSeaMapData;
};

interface StateSettingsMap {
  lat: number;
  lon: number;
  zoom: number;
}

type StateSettingsSwitches = TODO;

export type Settings = {
  map: StateSettingsMap;
  switches: StateSettingsSwitches;
  version: number;
  vesselIconSizeCapacityRelation: boolean;
  vesselIconStyle: string;
  vesselIconSizeMultiplicator: number;
  mapStyle: string;
  showOpenSeaMapGeoJSON: boolean;
  showOpenSeaMapTileLayer: boolean;
} | null;

export interface MapState {
  settings: Settings;
  viewState?: ViewState;
  selectedItems: SelectedItems;
  isFullScreen: boolean;
  isSelectMode: boolean;
  isShowSideContent: boolean;
  isShowSettingView: boolean;
  mapSize: MapSize;
  layersData: LayersData;
}

const initialState: MapState = {
  settings: null,
  isFullScreen: false,
  isSelectMode: false,
  isShowSideContent: false,
  isShowSettingView: false,
  mapSize: null,
  selectedItems: {type: '', items: []},
  layersData: {},
};

/**
 * The slug to load the map settings from the backend.
 */
export type IdentifierProp =
  | 'vessel_database'
  | 'potential_competitors'
  | 'defaults'
  | 'dashboard'
  | 'distance_calc'
  | 'my_fleet'
  | 'vesselDetailsMap'
  | 'port_backend_details'
  | 'cargo_detail'
  | 'port_detail'
  | 'port_editor'
  | 'segment_selection'
  | 'service_detail'
  | 'tied_up_vessels'
  | 'market'
  | 'gap-analysis'
  | 'live_circulars'
  | 'vessel_detail'
  | 'spotifind'
  | 'db2'
  | 'congestion_report';

const MapSlice = createSlice({
  name: 'Map',
  initialState,
  reducers: {
    setSettings(state, action: PayloadAction<Settings>) {
      state.settings = action.payload;
    },
    setState(state, action: PayloadAction<MapState>) {
      state = {...state, ...action.payload};
    },
    setFullScreen(state, action: PayloadAction<boolean>) {
      state.isFullScreen = action.payload;
    },
    setSelectMode(state, action: PayloadAction<boolean>) {
      state.isSelectMode = action.payload;
    },
    setShowSideContent(state, action: PayloadAction<boolean>) {
      state.isShowSideContent = action.payload;
    },
    setShowSettingsView(state, action: PayloadAction<boolean>) {
      state.isShowSettingView = action.payload;
    },
    setViewState(state, action: PayloadAction<ViewState>) {
      state.viewState = action.payload;
    },
    setSelectedItems(state, action: PayloadAction<SelectedItems>) {
      state.selectedItems = action.payload;
    },
    setMapSize(state, action: PayloadAction<MapSize>) {
      state.mapSize = action.payload;
    },
    setLayersData(state, action: PayloadAction<LayersData>) {
      state.layersData = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(mapApiActionTypes.getSettings.SUCCESS, (state, action: PayloadAction<Settings>) => {
      state.settings = action.payload;
    });
  },
});

const getSettings = (identifier?: IdentifierProp) => {
  return mapApi.getSettings({
    params: {identifier: identifier ?? DEFAULT_MAP_IDENTIFIER},
    transformers: {SUCCESS: (r: TODO) => r.data},
  });
};

export const DEFAULT_MAP_IDENTIFIER = 'default';

const saveSettings = (identifier: IdentifierProp | undefined, data: TODO) => {
  return mapApi.saveSettings({params: {identifier: identifier ?? DEFAULT_MAP_IDENTIFIER}, body: {...data}});
};

export const MapActions = {
  ...MapSlice.actions,
  getSettings,
  saveSettings,
};

export const mapReducer = MapSlice.reducer;
