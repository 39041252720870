/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AccountCompanyPrimaryManager
 */
export interface AccountCompanyPrimaryManager {
  /**
   *
   * @type {number}
   * @memberof AccountCompanyPrimaryManager
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AccountCompanyPrimaryManager
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AccountCompanyPrimaryManager
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AccountCompanyPrimaryManager
   */
  email: string;
  /**
   *
   * @type {Date}
   * @memberof AccountCompanyPrimaryManager
   */
  registrationDate: Date | null;
  /**
   *
   * @type {Date}
   * @memberof AccountCompanyPrimaryManager
   */
  lastActivity?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof AccountCompanyPrimaryManager
   */
  isDisabled?: boolean | null;
}

export function AccountCompanyPrimaryManagerFromJSON(json: any): AccountCompanyPrimaryManager {
  return AccountCompanyPrimaryManagerFromJSONTyped(json, false);
}

export function AccountCompanyPrimaryManagerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AccountCompanyPrimaryManager {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    registrationDate: json['registrationDate'] === null ? null : new Date(json['registrationDate']),
    lastActivity: !exists(json, 'lastActivity')
      ? undefined
      : json['lastActivity'] === null
      ? null
      : new Date(json['lastActivity']),
    isDisabled: !exists(json, 'isDisabled') ? undefined : json['isDisabled'],
  };
}

export function AccountCompanyPrimaryManagerToJSON(value?: AccountCompanyPrimaryManager | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    registrationDate: value.registrationDate === null ? null : value.registrationDate.toISOString(),
    lastActivity:
      value.lastActivity === undefined
        ? undefined
        : value.lastActivity === null
        ? null
        : value.lastActivity.toISOString(),
    isDisabled: value.isDisabled,
  };
}
