import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import React, {FC} from 'react';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {MarketPortFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketPortFilterBranchDefinitions';

export const FilterControls: FC<{
  isFilterUiOpen: boolean;
  onChangeFilterUiOpen: (isFilterUiOpen: boolean) => void;
  filterProviderApi: FilterProviderApi<typeof MarketPortFilterBranchDefinitions>;
}> = ({filterProviderApi, isFilterUiOpen, onChangeFilterUiOpen}) => {
  return (
    <>
      {filterProviderApi.isAnyFilterApplied && (
        <ScreenHeader.Features.Button data-cy="PortsResetFiltersBTN" onClick={filterProviderApi.onResetAllClick}>
          Reset filters
        </ScreenHeader.Features.Button>
      )}
      <ScreenHeader.Features.Toggle
        value={isFilterUiOpen}
        onChange={event => onChangeFilterUiOpen(event.target.value)}
        options={[
          {
            id: 'show-filters',
            label: `Filters${filterProviderApi.appliedFilterCount ? ` (${filterProviderApi.appliedFilterCount})` : ''}`,
            value: true,
            props: {
              'data-cy': 'PortsShowFiltersBTN',
            },
          },
          {
            id: 'show-map',
            label: 'Map',
            value: false,
            props: {
              'data-cy': 'PortsShowMapBTN',
            },
          },
        ]}
      />
    </>
  );
};
