/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {SearchHit, SearchHitFromJSON, SearchHitFromJSONTyped, SearchHitToJSON} from './';

/**
 *
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
  /**
   *
   * @type {Array<SearchHit>}
   * @memberof SearchResult
   */
  items: Array<SearchHit>;
  /**
   *
   * @type {number}
   * @memberof SearchResult
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof SearchResult
   */
  pageIndex: number;
  /**
   *
   * @type {number}
   * @memberof SearchResult
   */
  pageSize: number;
  /**
   *
   * @type {string}
   * @memberof SearchResult
   */
  searchTerm: string;
}

export function SearchResultFromJSON(json: any): SearchResult {
  return SearchResultFromJSONTyped(json, false);
}

export function SearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(SearchHitFromJSON),
    totalItems: json['totalItems'],
    pageIndex: json['pageIndex'],
    pageSize: json['pageSize'],
    searchTerm: json['searchTerm'],
  };
}

export function SearchResultToJSON(value?: SearchResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(SearchHitToJSON),
    totalItems: value.totalItems,
    pageIndex: value.pageIndex,
    pageSize: value.pageSize,
    searchTerm: value.searchTerm,
  };
}
