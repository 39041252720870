import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {numberBetween} from '../../../../../utils/validators';

export interface DatabaseType {
  positionUnchangedDays?: number | '';
  excludeInPort: boolean;
  excludeMoored: boolean;
  excludeAnchored: boolean;
}

export interface InternalType {
  positionUnchangedDays: string;
  excludeInPort: boolean;
  excludeMoored: boolean;
  excludeAnchored: boolean;
}

export const Defaults: InternalType = {
  positionUnchangedDays: '',
  excludeInPort: false,
  excludeMoored: false,
  excludeAnchored: false,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {positionUnchangedDays, excludeInPort, excludeMoored, excludeAnchored} = database;
  return {
    positionUnchangedDays: typeof positionUnchangedDays === 'number' ? positionUnchangedDays.toString() : '',
    excludeInPort: excludeInPort,
    excludeMoored: excludeMoored,
    excludeAnchored: excludeAnchored,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {positionUnchangedDays, excludeInPort, excludeMoored, excludeAnchored} = internal;
  return {
    positionUnchangedDays:
      positionUnchangedDays === undefined || positionUnchangedDays === '' ? undefined : parseInt(positionUnchangedDays),
    excludeInPort: excludeInPort,
    excludeMoored: excludeMoored,
    excludeAnchored: excludeAnchored,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel position',
  branch: 'vesselPosition',
  defaults: Defaults,
  validators: {
    positionUnchangedDays: numberBetween(0, 60),
  },
  fromDatabase,
  toDatabase,
});
