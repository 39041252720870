import {Component} from 'react';
import ErrorWidget from './ErrorWidget';

function getDisplayName(WrappedComponent: $TSFixMe) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
function withErrorHandler(WrappedComponent: $TSFixMe) {
  class WithErrorHandler extends Component {
    constructor(props: $TSFixMe) {
      super(props);
      this.state = {hasError: false};
    }
    static getDerivedStateFromError() {
      return {hasError: true};
    }
    render() {
      if ((this.state as $TSFixMe).hasError) {
        return <ErrorWidget {...this.props} msg={'Sorry, an error occurred'} />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  (WithErrorHandler as $TSFixMe).displayName = `WithErrorHandler(${getDisplayName(WrappedComponent)})`;
  return WithErrorHandler;
}
export default withErrorHandler;
