import {VoyagePoint} from '../VoyageTypes';
import {isPointInSECA} from '../../utils/isPointInSECA';
import {geoJsonLatLngToGeolibCoordinates} from '../../utils/geoJsonLatLngToGeolibCoordinates';
import {SearchPickerPort} from '../../../../SearchPicker/SearchPickerPort';

export const voyagePointPartialWithEmptyPortInformations: Partial<VoyagePoint> = {
  coordinates: [0, 0],
  countryCode: undefined,
  name: '',

  isPort: false,
};

export const transformPortToVoyagePointPartial = (port: SearchPickerPort): Partial<VoyagePoint> => {
  const changes: Partial<VoyagePoint> = {
    coordinates: port.coordinates,
    countryCode: port.country,
    name: port.name,
    isPort: true,
    originalPort: port,
  };

  changes.isInSECA = isPointInSECA({
    point: geoJsonLatLngToGeolibCoordinates(port.coordinates),
  });

  return changes;
};
