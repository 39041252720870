import React, {Fragment, useEffect} from 'react';
import {Intake, IntakeTypeOptions, intakeTypes, intakeTypesArray, IntakeValidation, newIntake} from './IntakeTypes';
import produce from 'immer';
import {IntakeRow} from './IntakeRow';
import {Button} from '../../../../atoms/Button/Button';
import Icon from '../../../../atoms/Icon';
import xor from 'lodash/xor';

export const IntakeRows = ({
  intakes = [],
  validation,
  onChange,
  validateRow,
  contractTypeVC = false,
}: {
  intakes: Intake[];
  validation: Record<number, IntakeValidation> & {invalid: boolean; error?: string};
  onChange: (newIntakes: Intake[]) => void;
  validateRow: (section: 'intakes', field: keyof Intake, index: number) => void;
  contractTypeVC?: boolean;
}) => {
  const allIntakeTypeOptions: IntakeTypeOptions = intakeTypesArray.map(value => ({value, name: intakeTypes[value]}));
  const usedIntakeTypes = intakes.map(intake => intake.type);
  const unusedIntakeTypes = xor(intakeTypesArray, usedIntakeTypes);

  const canAddNewRow = unusedIntakeTypes.length > 0;
  const addAnotherBtnLabel = contractTypeVC ? 'ADD ANOTHER Container type' : 'ADD ANOTHER';

  const onRowChange = (field: keyof Intake, value: number | string | boolean, rowIndex: number) => {
    onChange(
      produce(intakes, draftIntakes => {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number | boolean' is not assignable... Remove this comment to see the full error message
        draftIntakes[rowIndex][field] = value;
      })
    );
  };

  const onAddRow = () => {
    if (!canAddNewRow) {
      return;
    }
    onChange(
      produce(intakes, draftIntakes => {
        draftIntakes.push({
          ...newIntake,
          type: unusedIntakeTypes[0],
        });
      })
    );
  };

  const onRemove = (index: number) => {
    onChange(intakes.filter((_, idx) => idx !== index));
  };

  // TODO: Validate Intakes Hack -  https://seanexxt.atlassian.net/browse/FRIN-1228
  useEffect(() => {
    if (validation.invalid) {
      intakes.forEach((_, index) => {
        setTimeout(() => {
          validateRow('intakes', 'quantity', index);
        }, 0);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.invalid, validation.error]);
  return (
    <>
      {intakes.map((intake, index) => {
        const possibleIntakeTypeOptions = allIntakeTypeOptions.filter(option => {
          if (option.value === intake.type) {
            return true;
          }
          return !usedIntakeTypes.includes(option.value);
        });

        return (
          <IntakeRow
            intake={intake}
            index={index}
            key={index}
            possibleIntakeTypeOptions={possibleIntakeTypeOptions}
            onChange={(field, value) => {
              onRowChange(field, value, index);
            }}
            validation={validation[index]}
            onValidate={field => {
              validateRow('intakes', field, index);
            }}
            onRemove={() => onRemove(index)}
          />
        );
      })}

      {canAddNewRow && (
        <div className={'row'}>
          <div className={'scol-12 cargo-vessel-form__icon-button'}>
            <Button
              id={'form-intake-intake-add'}
              icon
              label={
                <Fragment>
                  <Icon
                    type={'item-add-selection-solid'}
                    color="blue"
                    style={{marginRight: 6, verticalAlign: 'bottom'}}
                  />
                  {intakes.length === 0 ? 'ADD CONTAINER' : addAnotherBtnLabel}
                </Fragment>
              }
              upper
              onClick={onAddRow}
            />
          </div>
        </div>
      )}
    </>
  );
};
