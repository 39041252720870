/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Query
 */
export interface Query {
  /**
   * List of country codes.
   * @type {Array<string>}
   * @memberof Query
   */
  countriesOrRegions: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Query
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof Query
   */
  month?: number;
  /**
   *
   * @type {number}
   * @memberof Query
   */
  day?: number;
  /**
   *
   * @type {boolean}
   * @memberof Query
   */
  upcoming?: boolean;
}

export function QueryFromJSON(json: any): Query {
  return QueryFromJSONTyped(json, false);
}

export function QueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Query {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    countriesOrRegions: json['countriesOrRegions'],
    year: json['year'],
    month: !exists(json, 'month') ? undefined : json['month'],
    day: !exists(json, 'day') ? undefined : json['day'],
    upcoming: !exists(json, 'upcoming') ? undefined : json['upcoming'],
  };
}

export function QueryToJSON(value?: Query | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    countriesOrRegions: value.countriesOrRegions,
    year: value.year,
    month: value.month,
    day: value.day,
    upcoming: value.upcoming,
  };
}
