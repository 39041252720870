import lodashMap from 'lodash/map';
import lodashMax from 'lodash/max';
import {FilterItem} from '../../api/symfony/generated';

export const newSortKey = (filters: FilterItem[]): number => {
  const allSortKeys = lodashMap(filters, 'sortKey');
  const maxSortKey = lodashMax(allSortKeys) ?? 0;
  const newSortKey = maxSortKey + 1;
  return newSortKey;
};
