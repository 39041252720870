/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {FastVesselImport, FastVesselImportFromJSON, FastVesselImportFromJSONTyped, FastVesselImportToJSON} from './';

/**
 *
 * @export
 * @interface FastVesselImportResponse
 */
export interface FastVesselImportResponse {
  /**
   *
   * @type {Array<FastVesselImport>}
   * @memberof FastVesselImportResponse
   */
  items: Array<FastVesselImport>;
}

export function FastVesselImportResponseFromJSON(json: any): FastVesselImportResponse {
  return FastVesselImportResponseFromJSONTyped(json, false);
}

export function FastVesselImportResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FastVesselImportResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(FastVesselImportFromJSON),
  };
}

export function FastVesselImportResponseToJSON(value?: FastVesselImportResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(FastVesselImportToJSON),
  };
}
