/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
  /**
   *
   * @type {number}
   * @memberof Coordinates
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Coordinates
   */
  lon: number;
}

export function CoordinatesFromJSON(json: any): Coordinates {
  return CoordinatesFromJSONTyped(json, false);
}

export function CoordinatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coordinates {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    lat: json['lat'],
    lon: json['lon'],
  };
}

export function CoordinatesToJSON(value?: Coordinates | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    lat: value.lat,
    lon: value.lon,
  };
}
