import {CSSProperties, FC} from 'react';
import styled from 'styled-components';
import Cargo from '../../model/Cargo';
import {getIconForCommodity} from '../../utils/helper';
import Icon from '../Icon';

export const CargoSvg = ({color = '#FF8E23'}) => {
  return (
    <svg width="42" height="48" style={{transform: 'scale(.43)'}}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M22.5.9l18 10.2a3 3 0 0 1 1.5 2.6v20.6c0 1-.6 2-1.5 2.6L22.5 47a3 3 0 0 1-3 0l-18-10A3 3 0 0 1 0 34.3V13.7c0-1 .6-2 1.5-2.6L19.5 1c1-.6 2-.6 3 0z"
      />
    </svg>
  );
};

export type CargoContractType = 'empty' | 'tct' | 'vc' | 'pc' | 'bc';
type Props = {
  type?: CargoContractType;
  commodityCategory?: string | null;
  style?: CSSProperties;
  className?: string;
};

export const CargoHexagon: FC<Props> = ({type, commodityCategory, style}) => (
  <CargoHexagonContainer style={style}>
    <CargoSvgContainer>
      <CargoSvg color={Cargo.getColor(type)} />
    </CargoSvgContainer>
    {!!commodityCategory && <StyledIcon type={getIconForCommodity(commodityCategory)} />}
  </CargoHexagonContainer>
);

const CargoHexagonContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 22px;
  width: 20px;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--font-size-lg) !important;
  color: var(--color-white) !important;
`;

const CargoSvgContainer = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
