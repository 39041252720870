import {Layer, Source} from 'react-map-gl';
import {OPENWEATHERMAP_APP_ID, OPENWEATHERMAP_LAYERS} from '../../const';
import {OpenweathermapLayer, openweathermapNoLayer} from './OpenweathermapLayerType';

type Props = {
  openweathermapLayer: OpenweathermapLayer | typeof openweathermapNoLayer;
};

const getSourceId = (value: string) => `mapBoxOpenWeatherMapLayerSource-${value}`;
export const OpenWeatherMapSource = () => {
  return (
    <>
      {OPENWEATHERMAP_LAYERS.map(item => {
        const sourceId = getSourceId(item.value);

        return (
          <Source
            key={sourceId}
            id={sourceId}
            type={'raster'}
            tiles={[`https://tile.openweathermap.org/map/${item.value}/{z}/{x}/{y}/?appid=${OPENWEATHERMAP_APP_ID}`]}
          />
        );
      })}
    </>
  );
};

export const OpenWeatherMapLayer = (props: Props) => {
  const sourceId = getSourceId(props.openweathermapLayer!);

  return (
    <>
      {props.openweathermapLayer !== openweathermapNoLayer && (
        <Layer key={sourceId} type={'raster'} source={sourceId} paint={{}} />
      )}
    </>
  );
};
