import isPropValid from '@emotion/is-prop-valid';
import {ReactNode} from 'react';
import {StyleSheetManager, WebTarget} from 'styled-components';

export const StyledComponentsProvider = ({children}: {children: ReactNode}) => {
  return (
    <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
      {children}
    </StyleSheetManager>
  );
};

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName: string, target: WebTarget) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};
