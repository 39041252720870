import {Link} from 'react-router-dom';
import {ActionMenu} from '../../../../components/ActionMenu/ActionMenu';
import {MenuItem} from '../../../../atoms/Menu/MenuItem';
import Icon, {IconType} from '../../../../atoms/Icon';
import styled from 'styled-components';

interface WidgetHeaderProps {
  title: React.ReactElement | string;
  icon?: IconType;
  navigation?: {
    label: React.ReactElement | string;
    link: string;
  }[];
  additionalContent?: string | React.ReactElement;
  onRemove?: () => void;
  onOpenSettingsEditor?: () => void;
}

const WidgetHeader = ({
  title,
  icon,
  navigation = [],
  additionalContent,
  onRemove,
  onOpenSettingsEditor,
}: WidgetHeaderProps) => (
  <StyledWidgetHeader>
    <HeaderLeft className="draggable">
      {icon && <StyledIcon type={icon} size={'medium'} />}
      {title}
    </HeaderLeft>
    <HeaderRight>
      {additionalContent}
      {navigation.map(({label, link}: {label: React.ReactElement | string; link: string}, idx: string | number) => (
        <Link className="dashboard__link" to={link} key={idx}>
          {label}
        </Link>
      ))}
      <ActionMenu
        items={[
          ...(onOpenSettingsEditor
            ? [
                {
                  key: 'Settings',
                  label: <MenuItem label="Settings" onClick={() => onOpenSettingsEditor()} />,
                },
              ]
            : []),
          {
            key: 'remove',
            label: (
              <MenuItem
                label="Remove"
                onClick={() => {
                  onRemove && onRemove();
                }}
              />
            ),
          },
        ]}
      />
    </HeaderRight>
  </StyledWidgetHeader>
);

const StyledWidgetHeader = styled.div`
  border-top-left-radius: var(--border-radius-widget);
  border-top-right-radius: var(--border-radius-widget);
  flex-grow: 0;
  width: 100%;
  min-height: 60px;
  font-weight: bold;
  font-size: var(--font-size);
  text-transform: uppercase;
  display: flex;
  border-bottom: var(--border-width) solid var(--border-color-transparent);

  .light & {
    background: var(--color-white);
    color: var(--color-black);
  }

  .blue-light & {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  .blue-dark & {
    background-color: var(--color-azure);
    color: var(--color-white);
  }

  .icon {
    color: inherit !important;
    opacity: 0.6;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  cursor: grab;
  flex-grow: 1;
  justify-content: start;
  align-items: center;
  padding: 0 20px 0 20px;
`;
const HeaderRight = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 10px 0 20px;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 5px;
`;

export default WidgetHeader;
