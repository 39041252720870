/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselOffersForLocationResponseDataItems,
  VesselOffersForLocationResponseDataItemsFromJSON,
  VesselOffersForLocationResponseDataItemsFromJSONTyped,
  VesselOffersForLocationResponseDataItemsToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselOffersForLocationResponseData
 */
export interface VesselOffersForLocationResponseData {
  /**
   *
   * @type {Array<VesselOffersForLocationResponseDataItems>}
   * @memberof VesselOffersForLocationResponseData
   */
  items: Array<VesselOffersForLocationResponseDataItems>;
}

export function VesselOffersForLocationResponseDataFromJSON(json: any): VesselOffersForLocationResponseData {
  return VesselOffersForLocationResponseDataFromJSONTyped(json, false);
}

export function VesselOffersForLocationResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselOffersForLocationResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(VesselOffersForLocationResponseDataItemsFromJSON),
  };
}

export function VesselOffersForLocationResponseDataToJSON(value?: VesselOffersForLocationResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(VesselOffersForLocationResponseDataItemsToJSON),
  };
}
