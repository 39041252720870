/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VesselTypeEnum {
  Bulker = 'bulker',
  Container = 'container',
  Mpp = 'mpp',
}

export function VesselTypeEnumFromJSON(json: any): VesselTypeEnum {
  return VesselTypeEnumFromJSONTyped(json, false);
}

export function VesselTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselTypeEnum {
  return json as VesselTypeEnum;
}

export function VesselTypeEnumToJSON(value?: VesselTypeEnum | null): any {
  return value as any;
}
