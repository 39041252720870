import {ScatterplotLayer, IconLayer, ArcLayer} from '@deck.gl/layers/typed';
import {Coordinates} from '../../../utils/Coordinates';
import {mapIcons} from './utils';
import {RGBColor} from '../../../utils/deck.gl/deck.gl-types';

export const getScatterplotLayer = ({
  index,
  color,
  coordinates,
  active,
  onClick,
}: {
  index: number;
  color: RGBColor;
  coordinates: Coordinates;
  active?: boolean;
  onClick?: () => void;
}) => {
  if (!coordinates) {
    return null;
  }
  return new ScatterplotLayer({
    id: `scatterplot-layer-${index}`,
    data: [coordinates],
    stroked: true,
    filled: true,
    radiusScale: 4,
    radiusMinPixels: 4,
    lineWidthMinPixels: 1,
    getRadius: 300,
    getPosition: () => coordinates,
    getFillColor: () => [...color, active ? 128 : 64],
    getLineColor: () => color,
    onClick,
  });
};

export const getIconLayer = ({
  index,
  color,
  coordinates,
  active,
  type,
  onClick,
}: {
  index: number;
  color: RGBColor;
  coordinates: Coordinates;
  active?: boolean;
  type?: keyof typeof mapIcons;
  onClick?: () => void;
}) => {
  if (!coordinates) {
    return null;
  }

  return new IconLayer({
    id: `icon-layer-${index}`,
    data: [coordinates],
    pickable: true,
    getPosition: () => coordinates,
    getColor: () => [...color, active ? 255 : 128],
    ...(type ? mapIcons[type] : mapIcons.marker),
    onClick,
  });
};

export const getArcLayer = ({
  index,
  color,
  coordinates,
}: {
  index: number;
  color: RGBColor;
  coordinates: [from: Coordinates, to: Coordinates];
}) => {
  if (!coordinates) {
    return null;
  }
  return new ArcLayer({
    id: `arc-layer-${index}`,
    data: [coordinates],
    pickable: true,
    getHeight: 0.4,
    getWidth: 3,
    getSourcePosition: () => coordinates[0],
    getTargetPosition: () => coordinates[1],
    getSourceColor: () => [...color, 128],
    getTargetColor: () => [...color, 128],
  });
};
