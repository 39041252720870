import React, {FC} from 'react';
import {CenteredContentArea, FullscreenDialog} from './atoms/FullscreenDialog/FullscreenDialog';
import {LoadingModal} from './components/LoadingModal/LoadingModal';
import {queryClient} from './api/utils/reactQueryClient';
import {QueryClientProvider} from '@tanstack/react-query';

/**
 * Configuration options for the maintenance page.
 */
interface MaintenancePageProps {
  message: string;
}

/**
 * Displays a maintenance page.
 *
 * In case of maintenance, this page is shown instead of starting the application,
 * which ensures that usage of the backend systems will be prevented (at least for
 * new users that reload the page).
 */
export const MaintenancePage: FC<MaintenancePageProps> = ({message}) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div data-testid="maintenance-page">
          <FullscreenDialog>
            <CenteredContentArea>
              <LoadingModal isLoading={true} headline={message} />
            </CenteredContentArea>
          </FullscreenDialog>
        </div>
      </QueryClientProvider>
    </>
  );
};
