import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export interface DatabaseType {
  privacy: {
    myVessels: boolean;
    privateShares: boolean;
    publicShares: boolean;
  };
}

export interface InternalType {
  myVessels: boolean;
  privateShares: boolean;
  publicShares: boolean;
}

export const Defaults: InternalType = {
  myVessels: false,
  privateShares: false,
  publicShares: false,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    ...Defaults,
    ...database.privacy,
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  return {
    privacy: internal,
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Privacy',
  branch: 'attributes',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
