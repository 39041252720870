import {Button, Form, Input, message} from 'antd';
import {Modal} from '../../../../atoms/Modal/Modal';
import React, {useContext} from 'react';
import {OtherCostInput} from './CostTypes';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {regexNotIncludeMinusSign} from '../../../../utils/regexExpressions';
import {ModalFooter} from '../../../../antd/components/ModalFooter';

interface ConsumptionModeDrawerProps {
  /**
   * null - means no index exits and its a new cost item
   */
  index: number | null;
  onClose: () => void;
}

const OtherCostDrawer = (props: ConsumptionModeDrawerProps) => {
  const isNew = props.index === null;

  const voyageContext = useContext(VoyageCharterContext);

  const {setInputState} = voyageContext;
  const {inputState} = voyageContext.state;

  const newVal: OtherCostInput = {
    name: '',
    cost: 0,
  };
  const data = isNew ? newVal : {...inputState.cost.otherCosts[props.index ?? 0]} || newVal;
  const [form] = Form.useForm();

  const save = async () => {
    await form.validateFields();
    let itemData = form.getFieldsValue() as OtherCostInput;

    itemData = {...itemData, cost: parseFloat(String(itemData.cost))};
    const otherCosts = [...inputState.cost.otherCosts];

    if (isNew) {
      otherCosts.push(itemData);
    } else {
      otherCosts[props.index ?? 0] = itemData;
    }
    setInputState({...inputState, cost: {...inputState.cost, otherCosts}});
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.success('Save expenses changes');
    props.onClose();
  };

  return (
    <Modal
      data-cy="editExpenseModal"
      open={true}
      title={isNew ? 'New expense' : 'Edit expense'}
      closable={true}
      onCancel={() => props.onClose()}
      width={400}
      footer={null}>
      <Form form={form} name="basic" initialValues={data} onFinish={save} onFinishFailed={() => {}} layout={'vertical'}>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>

        <Form.Item
          rules={[
            {
              message: 'Cost must be greater than or equal to 0.',
              pattern: regexNotIncludeMinusSign,
            },
          ]}
          label="Cost"
          name="cost">
          <Input type={'number'} min={0} addonAfter={'USD'} style={{maxWidth: 160}} />
        </Form.Item>
        <ModalFooter>
          {!isNew && (
            <Button
              data-cy="removeButton"
              danger
              onClick={() => {
                const otherCosts = [...inputState.cost.otherCosts].filter((k, i) => i !== props.index);

                setInputState({...inputState, cost: {...inputState.cost, otherCosts}});
                props.onClose();
              }}>
              Remove
            </Button>
          )}

          <Button data-cy="saveButton" type={'primary'} htmlType="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default OtherCostDrawer;
