import React, {Fragment} from 'react';
import BindToProvider, {WrappedDetailsSection} from '../../../../components/FormProvider/BindToProvider';
import Input from '../../../../atoms/Input';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import CompoundInput from '../../../../atoms/CompoundInput';
import {acceptFloatOrEmpty, acceptIntOrEmpty} from '../../../../utils/formatter';
import {floatBetweenSwitchable, numberBetween, requiredNumberBetween} from '../../../../utils/validators';
import {IntakeRows} from './IntakeRows';
import {Intake, IntakeUnit, intakeUnitOptions, intakeUnits, newIntake} from './IntakeTypes';
import Select from '../../../../atoms/Select/Select';
import {TODO} from '../../../../utils/TODO';

const Wrapper = BindToProvider(
  'Intake',
  'intake',
  // Defaults:
  {
    intakes: [{...newIntake}, {...newIntake, type: 'teu14'}],
    dwtSummer: '',
    reeferPlugs: '',
    holdsAmount: '',
    hatchesAmount: '',
    grain: '',
    grainUnit: Object.keys(intakeUnits)[0],
    bale: '',
    baleUnit: Object.keys(intakeUnits)[0],
  },
  {
    dwtSummer: numberBetween(0, 999999),
    reeferPlugs: numberBetween(0, 99999),
    holdsAmount: numberBetween(0, 999),
    hatchesAmount: numberBetween(0, 999),
    grain: floatBetweenSwitchable(0, 'cbm', 999999, 'cft', 99999999, 'grainUnit'),
    bale: floatBetweenSwitchable(0, 'cbm', 999999, 'cft', 99999999, 'baleUnit'),
    intakes: (intakes: Intake[]) => {
      const errors = intakes.some(intake => !!requiredNumberBetween(1, 99999)(intake.quantity));
      /*
      If an error is found in this area, a DateString is generated,
      this is then intercepted in the Intake Rows and then reacted with a useEffect by the validation of the Rows.
       */
      return errors ? Date.now().toString() : false;
    },
    row: {
      quantity: requiredNumberBetween(1, 99999),
    },
  },
  {
    toApi: (fields: TODO, {general}: TODO) => ({
      ...fields,
      dwtSummer: fields.dwtSummer && parseInt(fields.dwtSummer),
      reeferPlugs: fields.reeferPlugs && parseInt(fields.reeferPlugs),
      holdsAmount: fields.holdsAmount && parseInt(fields.holdsAmount),
      hatchesAmount: fields.hatchesAmount && parseInt(fields.hatchesAmount),
      grain: fields.grain && parseFloat(fields.grain),
      bale: fields.bale && parseFloat(fields.bale),
      intakes:
        // eslint-disable-next-line no-nested-ternary
        general.vesselType !== 'bulker'
          ? fields.intakes
            ? fields.intakes.map((intake: Intake) => ({...intake, quantity: parseFloat(intake.quantity)}))
            : []
          : [],
    }),
    fromApi: (fields: TODO) => ({
      ...fields,
      intakes: fields.intakes
        ? fields.intakes.map((intake: TODO) => ({
            ...newIntake,
            ...Object.keys(intake).reduce((fields, field) => {
              if (intake[field] || intake[field] === 0) {
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                fields[field] = intake[field];
              }
              return fields;
            }, {}),
          }))
        : [],
    }),
  },
  true
)(WrappedDetailsSection);

const DWTSummer = ({value, validation, onChange}: TODO) => (
  <Fragment>
    <LabelWrapper label={'DWT (Summer)'} htmlFor={'form-intake-dwt-summer'} hasError={validation.invalid}>
      <Input
        id={'form-intake-dwt-summer'}
        onChange={v => acceptIntOrEmpty(v.toString(), v => onChange('dwtSummer', v), 6)}
        value={value}
        hasError={validation.invalid}
      />
    </LabelWrapper>
    {validation.error && <p className="text-danger text-danger--no-margin">{validation.error}</p>}
  </Fragment>
);

const IntakeGrain = ({values, validations, onChange}: TODO) => (
  <Fragment>
    <LabelWrapper label={'Grain'} htmlFor={'form-intake-grain'} hasError={validations.grain.invalid}>
      <CompoundInput>
        <Input
          id={'form-intake-grain'}
          onChange={v => acceptFloatOrEmpty(v.toString(), v => onChange('grain', v), 8, 5)}
          value={values.grain}
          hasError={validations.grain.invalid}
        />
        <Select
          width={'100%'}
          hasError={false}
          id={`form-intake-grain-unit`}
          name={`form-intake-grain-unit`}
          options={intakeUnitOptions}
          value={{value: values.grainUnit, name: intakeUnits[values.grainUnit as IntakeUnit]}}
          defaultValue={{value: values.grainUnit, name: intakeUnits[values.grainUnit as IntakeUnit]}}
          onChange={o => onChange('grainUnit', o!.value)}
          getOptionLabel={(o: TODO) => o.name}
          getOptionValue={(o: TODO) => o.value}
          components={{DropdownIndicator: ArrowIndicator}}
          isSearchable={false}
        />
      </CompoundInput>
    </LabelWrapper>
    {validations.grain.error && <p className="text-danger text-danger--no-margin">{validations.grain.error}</p>}
  </Fragment>
);

const IntakeBale = ({values, validations, onChange}: TODO) => (
  <Fragment>
    <LabelWrapper label={'Bale'} htmlFor={'form-intake-bale'} hasError={validations.bale.invalid}>
      <CompoundInput>
        <Input
          id={'form-intake-bale'}
          onChange={v => acceptFloatOrEmpty(v.toString(), v => onChange('bale', v), 8, 5)}
          value={values.bale}
          hasError={validations.bale.invalid}
        />
        <Select
          hasError={false}
          width={'100%'}
          id={`form-intake-bale-unit`}
          name={`form-intake-bale-unit`}
          options={intakeUnitOptions}
          value={{value: values.baleUnit, name: intakeUnits[values.baleUnit as IntakeUnit]}}
          defaultValue={{value: values.baleUnit, name: intakeUnits[values.baleUnit as IntakeUnit]}}
          onChange={(o: TODO) => onChange('baleUnit', o.value)}
          getOptionLabel={(o: TODO) => o.name}
          getOptionValue={(o: TODO) => o.value}
          components={{DropdownIndicator: ArrowIndicator}}
          isSearchable={false}
        />
      </CompoundInput>
    </LabelWrapper>
    {validations.bale.error && <p className="text-danger text-danger--no-margin">{validations.bale.error}</p>}
  </Fragment>
);

const ReeferPlugs = ({value, validation, onChange}: TODO) => (
  <Fragment>
    <LabelWrapper label={'Reefer plugs'} htmlFor={'form-intake-reefer-plugs'} hasError={validation.invalid}>
      <Input
        id={'form-intake-reefer-plugs'}
        onChange={v => acceptIntOrEmpty(v.toString(), v => onChange('reeferPlugs', v), 5)}
        value={value}
        hasError={validation.invalid}
      />
    </LabelWrapper>
    {validation.error && <p className="text-danger text-danger--no-margin">{validation.error}</p>}
  </Fragment>
);

const Holds = ({values, validations, onChange}: TODO) => (
  <Fragment>
    <LabelWrapper label={'Holds'} hasError={validations.holdsAmount.invalid || validations.hatchesAmount.invalid}>
      <Input
        id={'form-intake-holds-amount'}
        onChange={v => acceptIntOrEmpty(v.toString(), v => onChange('holdsAmount', v), 3)}
        value={values.holdsAmount}
        hasError={validations.holdsAmount.invalid}
      />
    </LabelWrapper>
    {validations.holdsAmount.error && (
      <p className="text-danger text-danger--no-margin">{validations.holdsAmount.error}</p>
    )}
  </Fragment>
);

const Hatches = ({values, validations, onChange}: TODO) => (
  <Fragment>
    <LabelWrapper label={'Hatches'} hasError={validations.holdsAmount.invalid || validations.hatchesAmount.invalid}>
      <Input
        id={'form-intake-hatches-amount'}
        onChange={v => acceptIntOrEmpty(v.toString(), v => onChange('hatchesAmount', v), 3)}
        value={values.hatchesAmount}
        hasError={validations.hatchesAmount.invalid}
      />
    </LabelWrapper>
    {validations.hatchesAmount.error && (
      <p className="text-danger text-danger--no-margin">{validations.hatchesAmount.error}</p>
    )}
  </Fragment>
);

const IntakeSection = (props: TODO) => (
  <Wrapper {...props}>
    {({onChange, values, validations, validateRow, form}: TODO) => {
      return (
        <div className="container-fluid">
          {form.general && form.general.vesselType && (
            <Fragment>
              {form.general.vesselType === 'mpp' && (
                <Fragment>
                  <div className={'row'}>
                    <div className={'scol-12 scol-sm-2'}>
                      <DWTSummer value={values.dwtSummer} validation={validations.dwtSummer} onChange={onChange} />
                    </div>
                    <div className="scol-12 scol-sm-4 scol-lg-2">
                      <ReeferPlugs
                        value={values.reeferPlugs}
                        validation={validations.reeferPlugs}
                        onChange={onChange}
                      />
                    </div>
                    <div className={'scol-12 scol-sm-4 scol-lg-3'}>
                      <IntakeGrain values={values} validations={validations} onChange={onChange} />
                    </div>
                    <div className={'scol-12 scol-sm-4 scol-lg-3'}>
                      <IntakeBale values={values} validations={validations} onChange={onChange} />
                    </div>
                    <div className={'scol-12 scol-sm-4 scol-lg-1'}>
                      <Holds values={values} validations={validations} onChange={onChange} />
                    </div>
                    <div className={'scol-12 scol-sm-4 scol-lg-1'}>
                      <Hatches values={values} validations={validations} onChange={onChange} />
                    </div>
                  </div>
                  <HorizontalLine large />
                  <IntakeRows
                    intakes={values.intakes}
                    validation={validations.intakes}
                    onChange={values => onChange('intakes', values)}
                    validateRow={validateRow}
                  />
                </Fragment>
              )}
              {form.general.vesselType === 'bulker' && (
                <Fragment>
                  <div className={'row'}>
                    <div className={'scol-12 scol-sm-6 scol-lg-2'}>
                      <DWTSummer value={values.dwtSummer} validation={validations.dwtSummer} onChange={onChange} />
                    </div>
                    <div className={'scol-12 scol-sm-6 scol-lg-3'}>
                      <IntakeGrain values={values} validations={validations} onChange={onChange} />
                    </div>
                    <div className={'scol-12 scol-sm-6 scol-lg-3'}>
                      <IntakeBale values={values} validations={validations} onChange={onChange} />
                    </div>
                    <div className={'scol-12 scol-sm-6 scol-lg-2'}>
                      <Holds values={values} validations={validations} onChange={onChange} />
                    </div>
                    <div className={'scol-12 scol-sm-6 scol-lg-2'}>
                      <Hatches values={values} validations={validations} onChange={onChange} />
                    </div>
                  </div>
                </Fragment>
              )}
              {form.general.vesselType === 'container' && (
                <Fragment>
                  <IntakeRows
                    intakes={values.intakes}
                    validation={validations.intakes}
                    onChange={(values: TODO) => onChange('intakes', values)}
                    validateRow={validateRow}
                  />

                  <HorizontalLine large />
                  <div className={'row'}>
                    <div className="scol-12 scol-sm-6 scol-lg-4">
                      <DWTSummer value={values.dwtSummer} validation={validations.dwtSummer} onChange={onChange} />
                    </div>
                    <div className="scol-12 scol-sm-6 scol-lg-2">
                      <ReeferPlugs
                        value={values.reeferPlugs}
                        validation={validations.reeferPlugs}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      );
    }}
  </Wrapper>
);

export default IntakeSection;
