import {numberUnitFormat} from '../../../../utils/formatter';
import {percentCalc} from '../../../../utils/helper';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';

export const aisVesselDraftColumn: CustomColumnDef<$TSFixMe> = {
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        AIS reported draft
        <br />% of max rep. draft
      </div>
    </div>
  ),
  id: 'draft',
  accessorKey: 'draught',
  cell: info => info.getValue(),
  accessorFn: row => (
    <div data-sort={row.draught}>
      <div>
        {numberUnitFormat(
          row.draught,
          'meters',
          {toUpperCase: false, emptyString: '-'},
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )}
      </div>
      <div>
        {numberUnitFormat(percentCalc(row.draught, row.draughtMax), '%', {
          toUpperCase: false,
          emptyString: '-',
        })}
      </div>
    </div>
  ),
  minWidth: 3,
  sortingFn: (a, b) => {
    return (a.original.draught ?? 0) - (b.original.draught ?? 0);
  },
  enableSorting: true,
};
