import React, {ReactElement} from 'react';
import {components, MultiValueProps, GroupHeadingProps, SingleValueProps, OptionProps} from 'react-select';
import './style.scss';
import {CNR, TBN} from '../../components/Placeholder/Placeholder';

type DefaultValue = {name?: string; label?: string | ReactElement; chartererName?: string};

export const DropdownSingleValue = <Value extends OptionProps & DefaultValue>(props: SingleValueProps<Value>) => (
  <components.SingleValue {...props}>
    {props.data.name || props.data.label || props.data.chartererName || <TBN />}
  </components.SingleValue>
);
export const DropdownSingleValueCharterer = (props: $TSFixMe) => (
  <components.SingleValue {...props}>{props.data.label || <CNR />}</components.SingleValue>
);
export const DropdownMultiValue = <Value extends OptionProps & DefaultValue>(props: MultiValueProps<Value>) => (
  <components.MultiValue {...props}>
    {props.data.name || props.data.label || props.data.chartererName || <TBN />}
  </components.MultiValue>
);
const groupHeadingStyles = {
  color: 'var(--color-gray-2)',
  padding: '5px 0px',
  borderBottom: '1px solid #E6E6E6',
  marginBottom: 5,
  fontSize: '16px',
};
export const GroupHeading = <Value, IsMulti extends boolean>(props: GroupHeadingProps<Value, IsMulti>) => (
  <div style={groupHeadingStyles}>
    <components.GroupHeading {...props} />
  </div>
);
