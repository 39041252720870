/**
 * Parses a Response's json body.
 *
 * The purpose is to give us better Sentry logging where an endpoint returns a non JSON response although JSOn was expected.
 *
 * @see https://seanexxt.atlassian.net/browse/FRIN-4295
 */
export const loadResponseJson = async <ReturnValue>(
  response: Response,
  method: string,
  url: string
): Promise<ReturnValue> => {
  try {
    return await response.json();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `Error parsing JSON response for request to ${method} ${url} - ${response.status}/${response.statusText}`,
      e
    );
    throw e;
  }
};
