import {FC, useCallback, useEffect} from 'react';
import {ArchiveSubPortfolioList} from '../../../components/Portfolio/SubPortfolioList/ArchiveSubPortfolioList';
import {ChangeAllItemsPayload, GridClipboardActions} from '../../../redux/GridClipboard';
import {PortfolioActions, PortfolioActions as PortfolioRedux, PortfolioType} from '../../../redux/Portfolio';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {getIfAllSelected} from '../../portfolio/shared/getIfAllSelected';
import {MyCargoesGrid} from './MyCargoesGrid';
import {MyCargoesActionButton} from './MyCargoesActionButton';
import {GetMyCargoesQueryKey, useGetMyCargoesQuery} from './useGetMyCargoesQuery';
import {useOpenCargoForm} from '../../CargoVesselForm/utils/useOpenCargoForm';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {useQueryClient} from '@tanstack/react-query';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

const TYPE: PortfolioType = 'cargo';

export const MyCargoesArchiveScreen: FC = () => {
  const dispatch = useDispatch();
  const openCargoFrom = useOpenCargoForm();

  const queryClient = useQueryClient();
  const cargoesInGridClipboard = useSelector(state => state.gridClipboard.portfolio.cargoes);
  const {pageSize, pageNumber, sortField, sortOrder} = useSelector(state => state.portfolio.cargoGridState);
  const {activeTabCargo} = useSelector(state => state.portfolio.screenState);

  const setCargoes = useCallback(
    ({cargoes, cargoesTotal}: {cargoes: PortfolioCargo[] | null; cargoesTotal: number}) => {
      dispatch(PortfolioActions.setCargoes({cargoes, cargoesTotal}));
    },
    [dispatch]
  );

  const changeSelectedItems = useCallback(
    (payload: ChangeAllItemsPayload) => {
      dispatch(GridClipboardActions.changeAllItems(payload));
    },
    [dispatch]
  );

  const setPageIndex = (pageNumber: number) =>
    dispatch(PortfolioRedux.setGridState({portfolioType: 'cargo', gridState: {pageNumber}}));

  const cargoQuery = useGetMyCargoesQuery({
    filters: null,
    subPortfolioId: activeTabCargo,
    isArchive: true,
    pageNumber: pageNumber,
    pageSize,
    sortField,
    sortOrder,
  });

  const cargoes: PortfolioCargo[] | null = cargoQuery.isSuccess ? cargoQuery.data.items : null;
  const cargoesTotal = cargoQuery.isSuccess ? cargoQuery.data.totalItems : 0;

  const loading = cargoQuery.isLoading;

  const reloadGrid = (pageIndex?: number) => {
    if (pageIndex) {
      setPageIndex(pageIndex);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryClient.invalidateQueries({
      queryKey: [GetMyCargoesQueryKey],
    });
  };

  useEffect(() => {
    if (cargoQuery.isSuccess && cargoQuery.data) {
      setCargoes({
        cargoes: cargoQuery.data.items,
        cargoesTotal: cargoQuery.data.totalItems,
      });
      changeSelectedItems({
        section: 'portfolio',
        subsection: 'cargoes',
        newValue: getIfAllSelected(cargoesInGridClipboard, cargoQuery.data.items),
      });
    }
  }, [cargoQuery.data, cargoQuery.isSuccess, cargoesInGridClipboard, changeSelectedItems, setCargoes]);

  return (
    <div className={'portfolio'} data-testid="MyCargoesArchiveScreen">
      <ScreenHeader
        helmetTitle="Archived My Cargoes"
        breadcrumbs={[{title: 'My Cargoes', href: '/my-cargoes'}, {title: 'Archive'}]}
        actions={
          <ScreenHeader.Actions.Button id="addCargo" data-cy="PortfolioCargoAddBTN" onClick={() => openCargoFrom({})}>
            Add cargo
          </ScreenHeader.Actions.Button>
        }
      />
      <ArchiveSubPortfolioList type={TYPE} />
      <MyCargoesGrid
        isFiltered={false}
        isArchive={true}
        loading={loading}
        cargoes={cargoes}
        cargoesTotal={cargoesTotal}
        onReloadGrid={(pageIndex?: number) => {
          reloadGrid(pageIndex);
        }}
      />
      <MyCargoesActionButton items={cargoesInGridClipboard} archive={true} onReloadGrid={reloadGrid} />
    </div>
  );
};
