import {QueryClient, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {portfolioGroupApi} from '../api/symfony/symfonyApi';
import {PortfolioGroupList} from '../api/symfony/generated';
import {ApiError} from '../api/utils/ApiError';
import {PortfolioType} from '../redux/Portfolio';
import {mapPortfolioTypeToEnum} from './mapPortfolioTypeToEnum';

export const useGetSubPortfoliosQuery = (
  {type}: {type: PortfolioType},
  queryOptions?: UseQueryOptions<PortfolioGroupList, ApiError>
) =>
  useQuery({
    queryKey: ['subPortfolios', type],
    queryFn: () =>
      portfolioGroupApi.getSubPortfolios({
        type: mapPortfolioTypeToEnum(type),
      }),
    ...queryOptions,
  });

export const invalidateGetSubPortfoliosQuery = async (queryClient: QueryClient, type: PortfolioType) => {
  await queryClient.invalidateQueries({
    queryKey: ['subPortfolios', type],
  });
};
