import {ReactNode} from 'react';
import {Plan, plans} from '../../../../utils/Plans';
import {MostPopularBadge, BestValueBadge} from '../PlanBadge';

export type PlanWithUvps = Plan & {
  description: ReactNode;
  uvpLabel: ReactNode;
  uvps: ReactNode[];
  badge?: ReactNode;
};

export const planUvps: PlanWithUvps[] = [
  {
    ...plans[0],
    description: 'The quickest and easiest way to try seabo.',
    uvpLabel: 'What you can do',
    uvps: [
      'Live AIS maps and vessel data',
      'Easy fixture management',
      'Automatic circular import',
      'Enhanced filtering and sorting workflow  ',
      'Customizable circular lists',
      'One-glance dashboard overview ',
    ],
  },
  {
    ...plans[1],
    description: 'All you need.',
    uvpLabel: (
      <>
        Everything in <b>Free</b>, plus
      </>
    ),
    uvps: [
      'Comprehensive vessel and port data',
      'Fleet and cargo management',
      'Prospects grouping with flexible projects',
      'Distance calculation with ECA and piracy',
      'TCE calculation with bunker and more',
      'Customizable circular statistics',
    ],
    badge: <MostPopularBadge />,
  },
  {
    ...plans[2],
    description: 'All pro features to supercharge your business.',
    uvpLabel: (
      <>
        Everything in <b>Standard</b>, plus
      </>
    ),
    uvps: [
      'Automatic competitor comparison',
      'Negotiation tool to replace clunky emails',
      'Spot vessel finding in one click',
      'Multiple vessel-cargo calculations at once',
      'Live congestion of ports and chokepoints',
      'Priority access to new features',
    ],
    badge: <BestValueBadge />,
  },
];
