/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ListImosRequest,
  ListImosRequestFromJSON,
  ListImosRequestToJSON,
  ListImosResponse,
  ListImosResponseFromJSON,
  ListImosResponseToJSON,
  ListVesselsRequest,
  ListVesselsRequestFromJSON,
  ListVesselsRequestToJSON,
  ListVesselsResponse,
  ListVesselsResponseFromJSON,
  ListVesselsResponseToJSON,
  SeaboVessel,
  SeaboVesselFromJSON,
  SeaboVesselToJSON,
} from '../models';

export interface FindVesselByIdRequest {
  id: string;
}

export interface ListImosOperationRequest {
  listImosRequest: ListImosRequest;
}

export interface ListVesselsOperationRequest {
  listVesselsRequest: ListVesselsRequest;
}

/**
 *
 */
export class VesselDBVesselsApi extends runtime.BaseAPI {
  /**
   * Returns the vessel with the given id.     The response is a full SeaboVessel, including consumptions and gears
   */
  async findVesselByIdRaw(requestParameters: FindVesselByIdRequest): Promise<runtime.ApiResponse<SeaboVessel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling findVesselById.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel-db/vessels/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => SeaboVesselFromJSON(jsonValue));
  }

  /**
   * Returns the vessel with the given id.     The response is a full SeaboVessel, including consumptions and gears
   */
  async findVesselById(requestParameters: FindVesselByIdRequest): Promise<SeaboVessel> {
    const response = await this.findVesselByIdRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the imos of vessels matching the given filters.
   */
  async listImosRaw(requestParameters: ListImosOperationRequest): Promise<runtime.ApiResponse<ListImosResponse>> {
    if (requestParameters.listImosRequest === null || requestParameters.listImosRequest === undefined) {
      throw new runtime.RequiredError(
        'listImosRequest',
        'Required parameter requestParameters.listImosRequest was null or undefined when calling listImos.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel-db/vessels/imos`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ListImosRequestToJSON(requestParameters.listImosRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ListImosResponseFromJSON(jsonValue));
  }

  /**
   * Returns the imos of vessels matching the given filters.
   */
  async listImos(requestParameters: ListImosOperationRequest): Promise<ListImosResponse> {
    const response = await this.listImosRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns the vessels matching the given filters.        Supports pagination and sorting.        The response omits consumptions and gears.
   */
  async listVesselsRaw(
    requestParameters: ListVesselsOperationRequest
  ): Promise<runtime.ApiResponse<ListVesselsResponse>> {
    if (requestParameters.listVesselsRequest === null || requestParameters.listVesselsRequest === undefined) {
      throw new runtime.RequiredError(
        'listVesselsRequest',
        'Required parameter requestParameters.listVesselsRequest was null or undefined when calling listVessels.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel-db/vessels`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ListVesselsRequestToJSON(requestParameters.listVesselsRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ListVesselsResponseFromJSON(jsonValue));
  }

  /**
   * Returns the vessels matching the given filters.        Supports pagination and sorting.        The response omits consumptions and gears.
   */
  async listVessels(requestParameters: ListVesselsOperationRequest): Promise<ListVesselsResponse> {
    const response = await this.listVesselsRaw(requestParameters);
    return await response.value();
  }
}
