import {useMutation, useQueryClient} from '@tanstack/react-query';
import {portfolioGroupApi} from '../../../api/symfony/symfonyApi';
import {PortfolioGroupDetails, PortfolioGroupList, UpdateSubPortfolioRequest} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';
import produce from 'immer';
import {invalidateGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';
import {PortfolioType} from '../../../redux/Portfolio';
import {mapPortfolioTypeToEnum} from '../../../queries/mapPortfolioTypeToEnum';

interface RenameSubPortfolioParams {
  id: number;
  name: string;
  type: PortfolioType;
}

export const useRenameSubPortfolio = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<PortfolioGroupDetails, ApiError, UpdateSubPortfolioRequest>({
    mutationFn: request => portfolioGroupApi.updateSubPortfolio(request),
  });

  const renameSubPortfolio = async ({type, id, name}: RenameSubPortfolioParams) => {
    const subPortfoliosQueryKey = ['subPortfolios', type];
    await queryClient.cancelQueries({
      queryKey: subPortfoliosQueryKey,
    });

    // Calculate our new state so that we can display it immediately
    const portfolioGroupList = queryClient.getQueryData(subPortfoliosQueryKey) as PortfolioGroupList;
    const updatedSubPortfolioGroupList = produce(portfolioGroupList, draftPortfolioGroupList => {
      const subPortfolios = draftPortfolioGroupList.data.items;
      const subPortfolio = subPortfolios.find(subPortfolio => subPortfolio.id === id);
      if (!subPortfolio) {
        throw new Error('Not found');
      }
      subPortfolio.name = name;
    });

    // Optimistically update to the state
    queryClient.setQueryData(subPortfoliosQueryKey, updatedSubPortfolioGroupList);

    // Talk to the API
    mutation.mutate(
      {
        id,
        portfolioGroupStub: {
          type: mapPortfolioTypeToEnum(type),
          name,
        },
      },
      {
        onSettled: async () => {
          // Reload
          await invalidateGetSubPortfoliosQuery(queryClient, type);
        },
      }
    );
  };

  return renameSubPortfolio;
};
