/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselIntakeDTO
 */
export interface VesselIntakeDTO {
  /**
   * Primary key in the Node Backend vessel DB, NOT the Symfony DB.
   * @type {number}
   * @memberof VesselIntakeDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof VesselIntakeDTO
   */
  type: VesselIntakeDTOTypeEnum;
  /**
   *
   * @type {number}
   * @memberof VesselIntakeDTO
   */
  quantity: number;
  /**
   *
   * @type {boolean}
   * @memberof VesselIntakeDTO
   */
  isHighCube: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VesselIntakeDTO
   */
  isEmpty: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum VesselIntakeDTOTypeEnum {
  Teu = 'teu',
  Teu14 = 'teu14',
  Feu = 'feu',
  _45f = '45f',
}

export function VesselIntakeDTOFromJSON(json: any): VesselIntakeDTO {
  return VesselIntakeDTOFromJSONTyped(json, false);
}

export function VesselIntakeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselIntakeDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    quantity: json['quantity'],
    isHighCube: json['isHighCube'],
    isEmpty: json['isEmpty'],
  };
}

export function VesselIntakeDTOToJSON(value?: VesselIntakeDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    quantity: value.quantity,
    isHighCube: value.isHighCube,
    isEmpty: value.isEmpty,
  };
}
