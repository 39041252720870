import {cargoAccessor} from '../../utils/cargoAccessorResult';
import {getCommodityTypeFormatted} from '../../../../screens/CargoVesselForm/helper';
import {RowValueType} from '../../utils/RowValueType';
import {CommodityCategory} from '../../../../atoms/CommodityCategory';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {TODO} from '../../../../utils/TODO';
import {sortingFnForStrings} from '../../utils/sortingFnForStrings';
import {MarketCargo} from '../../../../screens/market/MarketCargo/MarketCargoOffer';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';

const commodityAccessor = (type: RowValueType): AccessorFn<CargoGridColumnsProps> =>
  cargoAccessor((cargo: MarketCargo) => {
    return (
      <div className={'market-grid-cell'}>
        <div className={'market-grid-cell-content'}>{getCommodityTypeFormatted(cargo) || '-'}</div>
        <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
          {cargo.cargoType === 'container'
            ? 'Container'
            : <CommodityCategory commodityCategory={cargo.commodityCategory || ''} /> || '-'}
        </div>
      </div>
    );
  }, type);

export const commodityColumn = ({
  type,
  minWidth = 8,
}: {
  type: RowValueType;
  minWidth?: number;
}): CustomColumnDef<CargoGridColumnsProps> => ({
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        Commodity Type
        <br />
        Category
      </div>
    </div>
  ),
  sortingFn: (a: TODO, b: TODO) => {
    return sortingFnForStrings(a.original.cargo.commodityCategory, b.original.cargo.commodityCategory);
  },
  id: 'commodityType',
  enableSorting: true,
  accessorFn: commodityAccessor(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
