import {FC, ReactNode} from 'react';
import {ButtonProps, Modal as AntdModal, ModalProps} from 'antd';
import omit from 'lodash/omit';
import styled from 'styled-components';
import classNames from 'classnames';

type ExtendedModalProps = ModalProps & {
  subtitle?: ReactNode;
  okButtonProps?: ButtonProps & {'data-testid'?: string};
};

/**
 * Standard model width values.
 */
export const ModalWidth = {
  Middle: 520,
  Large: 1000,
};

export const Modal: FC<ExtendedModalProps> = props => {
  const propsForAntdModal = omit(props, ['title', 'children', 'subtitle']);
  return (
    <StyledAntdModal
      {...propsForAntdModal}
      cancelButtonProps={{style: {display: 'none'}}}
      className={classNames({
        'modal-without-title': !props.title,
      })}>
      <ModalHead title={props.title} subtitle={props.subtitle} />
      <ModalBody>{props.children}</ModalBody>
    </StyledAntdModal>
  );
};

const StyledAntdModal = styled<typeof AntdModal>(AntdModal)`
  .ant-modal-body {
    background: var(--color-white);
  }
  span.ant-modal-close-x {
    padding-top: 2px;
    padding-right: 2px;
    svg {
      width: 1.3em;
      height: 1.3em;
    }
  }

  .ant-modal-footer {
    text-align: center;
    /* We rely on margin collapse here to get the correct spacing between the buttons and the body */
    margin-top: 48px;
  }
`;

const ModalHead: FC<{title?: ReactNode; subtitle?: ReactNode}> = ({title, subtitle}) => {
  return (
    <ModalHeader>
      {title && <Title style={{marginBottom: 36}}>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </ModalHeader>
  );
};

const ModalHeader = styled.div`
  display: block;
`;

const ModalBody = styled.div`
  display: block;
  padding-top: 0;
`;

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--color-gray-1);
  text-align: center;
  font-size: var(--modal-title-font-size);
  font-weight: var(--modal-title-font-weight);
  line-height: var(--modal-title-font-size);
`;

const Subtitle = styled.div`
  display: block;
  color: var(--color-gray-2);
  font-size: var(--font-size-lg);
  line-height: 24px;
  text-align: center;
`;
