/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Owner, OwnerFromJSON, OwnerFromJSONTyped, OwnerToJSON} from './';

/**
 *
 * @export
 * @interface ManagementHeadOwners
 */
export interface ManagementHeadOwners {
  /**
   *
   * @type {Array<Owner>}
   * @memberof ManagementHeadOwners
   */
  owners: Array<Owner>;
  /**
   *
   * @type {number}
   * @memberof ManagementHeadOwners
   */
  vesselsWithoutInformation: number;
}

export function ManagementHeadOwnersFromJSON(json: any): ManagementHeadOwners {
  return ManagementHeadOwnersFromJSONTyped(json, false);
}

export function ManagementHeadOwnersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementHeadOwners {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    owners: (json['owners'] as Array<any>).map(OwnerFromJSON),
    vesselsWithoutInformation: json['vesselsWithoutInformation'],
  };
}

export function ManagementHeadOwnersToJSON(value?: ManagementHeadOwners | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    owners: (value.owners as Array<any>).map(OwnerToJSON),
    vesselsWithoutInformation: value.vesselsWithoutInformation,
  };
}
