import {Attribute} from '../../atoms/Attribute/Attribute';
import {getCompassDirection, getCompassDirectionLabel} from '../../utils/helper';
import {useGetUserDateTime} from '../../components/DateTime/useGetUserDateTime';
import {PortTitleWithFlag} from '../../components/PortTitleWithFlag';
import {VesselDetailsVessel} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

type VesselLoationPartialProps = {
  vessel: VesselDetailsVessel;
};

export const VesselLocationPartial = ({vessel}: VesselLoationPartialProps) => {
  const getUserDateTime = useGetUserDateTime();
  if (!vessel.aisDetails) {
    return <p>Vessel position not found.</p>;
  }
  const {
    coordinates,
    postime,
    speed,
    draught,
    statusText,
    lastPort,
    lastArea,
    destinationPort,
    destinationArea,
    course,
    eta,
  } = vessel.aisDetails;

  const lastLocation = lastPort ?? lastArea ?? {};
  const destinationLocation = destinationPort ?? destinationArea ?? {};

  const receivedTime = postime ? new Date(postime).toISOString() : '';
  const courseValue = getCompassDirectionLabel(getCompassDirection(course)) + ' (' + course + '°)';

  return (
    <>
      <Attribute tab={'m'} label="Received" value={getUserDateTime({value: receivedTime, format: 'dateTime'})} />
      <Attribute tab={'m'} label="Position" value={coordinates} />
      {lastPort && <Attribute tab={'m'} label="Last port" value={<PortTitleWithFlag port={lastLocation} />} />}
      {destinationPort && (
        <Attribute tab={'m'} label="Next port" value={<PortTitleWithFlag port={destinationLocation} />} />
      )}
      {eta && <Attribute tab={'m'} label="ETA" value={getUserDateTime({value: eta, format: 'dateTime'})} />}
      <br />
      <Attribute tab={'m'} label="Speed" value={speed + ' knots'} />
      <Attribute tab={'m'} label="Course" value={courseValue} />
      <Attribute tab={'m'} label="Draft" value={draught + ' meters'} />
      <Attribute tab={'m'} label="Status" value={statusText} />
    </>
  );
};
