import {App as AntdApp, ConfigProvider} from 'antd';
import {FC, Suspense, lazy} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Provider} from 'react-redux';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {initAntDesign} from './antd/initAntDesign';
import {FeaturePermission} from './api/symfony/generated';
import {queryClient} from './api/utils/reactQueryClient';
import ModalRoot from './atoms/BaseModal/ModalRoot';
import {CookieBanner} from './atoms/CookieBanner/CookieBanner';
import {NotFound} from './atoms/EmptyState/404';
import {FullLayout} from './atoms/Layout/FullLayout';
import Loading from './atoms/Loading';
import {AccessTokenManager} from './components/AccessTokenManager/AccessTokenManager';
import {MultiCalcScreen} from './components/CharterCalculation/MultiCalc/MultiCalcScreen';
import {FlashNotification} from './components/FlashNotification/FlashNotification';
import {NodeBackendConfigModal} from './components/NodeBackendConfigModal/NodeBackendConfigModal';
import {ProtectedRoute} from './components/routes/ProtectedRoute';
import {GlobalVariablesForTestsAndDebugging} from './components/ReactRouterHistory/ReactRouterHistory';
import {ScrollToTop} from './components/ScrollToTop/ScrollToTop';
import {ErrorBoundary} from './components/Sentry/ErrorBoundary';
import {PushMessages} from './components/ServerPush/PushMessages';
import {SessionKeepalive} from './components/SessionKeepalive/SessionKeepalive';
import {StrictMode} from './components/StrictMode/StrictMode';
import {CompanyLoader} from './components/UserContext/CompanyLoader';
import {store} from './redux/store';
import {useReduxPersistence} from './redux/storePersistence';
import {PotentialCompetitorsScreen} from './screens/Analytics/PotentialCompetitors/PotentialCompetitorsScreen';
import {LoginScreen} from './screens/Authentication/Login/LoginScreen';
import {LostPasswordScreen} from './screens/Authentication/LostPassword/LostPasswordScreen';
import {LostPasswordEmailSentScreen} from './screens/Authentication/LostPasswordEmailSent/LostPasswordEmailSentScreen';
import {ResetPasswordScreen} from './screens/Authentication/ResetPassword/ResetPasswordScreen';
import {SignupScreen} from './screens/Authentication/Signup/SignupScreen';
import {CargoDetailScreen} from './screens/CargoDetail/CargoDetailScreen';
import {CargoFormScreen} from './screens/CargoVesselForm/CargoFormScreen';
import {VesselFormScreen} from './screens/CargoVesselForm/VesselFormScreen';
import {VoyageCharterScreen} from './screens/CharterCalculator/VoyageCharterScreen';
import {CircularStatsScreen} from './screens/CircularStatsScreen/CircularStatsScreen';
import {CompanyScreens} from './screens/CompanyScreens/CompanyScreens';
import {CompanySubscriptionScreens} from './screens/CompanySubscriptionScreen/CompanySubscriptionScreens';
import {CongestionScreens} from './screens/CongestionScreens/CongestionScreens';
import {MarketImportInstructionsScreen} from './screens/MarketImportInstructionsScreen/MarketImportInstructionsScreen';
import {MyCargoesScreens} from './screens/MyCargoesScreens/MyCargoesScreens';
import {MyFleetScreens} from './screens/MyFleetScreens/MyFleetScreens';
import {NotificationsScreen} from './screens/NotificationsScreen/NotificationsScreen';
import {ProjectScreens} from './screens/ProjectScreens/ProjectScreens';
import {SpotifindScreen} from './screens/Spotifind/SpotifindScreen';
import {TiedUpVesselsScreen} from './screens/TiedUpVesselsScreen/TiedUpVesselsScreen';
import {UserAccountScreen} from './screens/UserAccountScreen/UserAccountScreen';
import {UserOnboarding} from './screens/UserOnboardingScreen/UserOnboarding';
import {UserProfileScreen} from './screens/UserProfile/UserProfileScreen';
import {VesselDatabaseListScreen} from './screens/VesselDatabaseScreen/VesselDatabaseListScreen';
import {VesselDetailScreen} from './screens/VesselDetailScreen/VesselDetailScreen';
import {VesselFixtureScreen} from './screens/VesselFixturesScreen/VesselFixtureScreen';
import {BackendScreenPermissions} from './screens/backend/BackendScreenPermissions';
import {ContactsScreens} from './screens/contacts/ContactsScreens';
import {DashboardScreen} from './screens/dashboard/DashboardScreen';
import {MarketScreens} from './screens/market/MarketScreens';
import {PortfolioScreens} from './screens/portfolio/PortfolioScreens/PortfolioScreens';
import {PortScreens} from './screens/ports/PortScreens';
import {initLocalization} from './utils/Localization';
import {useUserLoggedIn} from './utils/useUserLoggedIn';
import {AntdFormConfig, AntdTheme} from './antd/configuration/AntdConfiguration';
import {DistanceCalculationScreen} from './screens/DistanceCalculator/DistanceCalculationScreen';
import {NegotiationScreens} from './screens/Negotiation/NegotiationScreens';
import {GuestScreens} from './screens/Guest/GuestScreens';
import {NotificationsSetup} from './components/Notification';
import {setupDayjs} from './utils/setupDayjs';
import {PremiumRoute} from './components/routes/PremiumRoute';
import {CsrfTokenRefreshLoop} from './hooks/CsrfTokenRefreshLoop/CsrfTokenRefreshLoop';
import {UserLoader} from './components/UserContext/UserLoader';
import {TimeCharterScreen} from './components/CharterCalculation/Timecharter/TimecharterScreen';
import {HelpCenterScreen} from './screens/HelpCenter/HelpCenterScreen';
import {StyledComponentsProvider} from './components/StyledComponentsProvider/StyledComponentsProvider';
import {PiwikManager} from './components/PiwikManager/PiwikManager';

setupDayjs();
initLocalization();
initAntDesign();

const BackendScreens = lazy(() => import('./screens/backend/BackendScreens'));
const TestScreens = lazy(() => import('./screens/TestScreens/TestScreens'));
const SearchScreen = lazy(() => import('./screens/search/SearchScreen/SearchScreen'));

export const App = () => {
  useReduxPersistence();
  return (
    <StrictMode enabled={true}>
      <Suspense fallback={<Loading />}>
        <StyledComponentsProvider>
          <ConfigProvider theme={AntdTheme} form={AntdFormConfig}>
            <AntdApp>
              <ErrorBoundary location="App">
                <NodeBackendConfigModal />
                <Provider store={store}>
                  <HelmetProvider>
                    <Helmet defaultTitle="seabo" titleTemplate="%s - seabo" />
                    <QueryClientProvider client={queryClient}>
                      <CsrfTokenRefreshLoop>
                        <UserLoader>
                          {/* !! react18 */}
                          {/* @ts-expect-error Property 'children' does not exist on type */}
                          <DndProvider backend={HTML5Backend}>
                            <BrowserRouter>
                              <PiwikManager />
                              <Routes>
                                <Route path="/login" element={<LoginScreen />} />
                                <Route path="/signup" element={<SignupScreen />} />
                                <Route path="/authentication/lost-password" element={<LostPasswordScreen />} />
                                <Route
                                  path="/authentication/lost-password/email-sent"
                                  element={<LostPasswordEmailSentScreen />}
                                />
                                <Route path="/authentication/reset-password" element={<ResetPasswordScreen />} />
                                <Route path="/guest/*" element={<GuestScreens />} />
                                <Route path="*" element={<LoggedInSwitch />} />
                              </Routes>
                              <ScrollToTop />
                              <FlashNotification />
                              <GlobalVariablesForTestsAndDebugging />
                            </BrowserRouter>
                            <CookieBanner />
                          </DndProvider>
                        </UserLoader>
                      </CsrfTokenRefreshLoop>
                      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
                    </QueryClientProvider>
                    <NotificationsSetup />
                  </HelmetProvider>
                </Provider>
              </ErrorBoundary>
            </AntdApp>
          </ConfigProvider>
        </StyledComponentsProvider>
      </Suspense>
    </StrictMode>
  );
};

const LoggedInSwitch: FC = () => {
  return useUserLoggedIn() ? <LoggedIn /> : <NotLoggedIn />;
};

const LoggedIn: FC = () => {
  return (
    <AccessTokenManager>
      <CompanyLoader>
        <ErrorBoundary location="FullLayout">
          <ModalRoot />
          <UserOnboarding>
            <FullLayout>
              <PushMessages>
                <Routes>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/help-center" element={<HelpCenterScreen />} />
                  <Route path="/dashboard" element={<DashboardScreen />} />
                  <Route
                    path="/backend/*"
                    element={
                      <ProtectedRoute permissions={BackendScreenPermissions} component={() => <BackendScreens />} />
                    }
                  />
                  <Route path="/company/:id/*" element={<CompanyScreens />} />
                  <Route path="/contacts/*" element={<ContactsScreens />} />
                  <Route path="/distance-calculator" element={<DistanceCalculationScreen />} />
                  <Route
                    path="/spotifind"
                    element={
                      <PremiumRoute requiredPermission={FeaturePermission.AccessSpotifind}>
                        <SpotifindScreen />
                      </PremiumRoute>
                    }
                  />
                  <Route
                    path="/fixtures/new"
                    element={<Navigate to={{pathname: '/fixtures'}} state={{openNewFixtureModal: true}} />}
                  />
                  <Route path="/fixtures" element={<VesselFixtureScreen />} />
                  <Route path="/market/*" element={<MarketScreens />} />
                  <Route path="/vessel-database" element={<VesselDatabaseListScreen />} />
                  <Route path="/my-cargoes/*" element={<MyCargoesScreens />} />
                  <Route key="cargoCreate" path="/cargo/create" element={<CargoFormScreen />} />
                  <Route key="cargoEdit" path="/cargo/:id/edit" element={<CargoFormScreen />} />
                  <Route path="/cargo/:id" element={<CargoDetailScreen />} />
                  <Route path="/my-fleet/*" element={<MyFleetScreens />} />
                  <Route key="vesselCreate" path="/vessel/create" element={<VesselFormScreen />} />
                  <Route key="vesselEdit" path="/vessel/:id/edit" element={<VesselFormScreen />} />
                  <Route path="/vessel/:id" element={<VesselDetailScreen />} />
                  <Route path="/vessel/:id/vessel-database" element={<VesselDetailScreen />} />
                  <Route path="/portfolio/*" element={<PortfolioScreens />} />
                  <Route path="/chartercalculator/timecharter" element={<TimeCharterScreen />} />
                  <Route path="/chartercalculator/multi" element={<MultiCalcScreen />} />
                  <Route path="/chartercalculator/voyagecharter" element={<VoyageCharterScreen />} />
                  <Route
                    path="/analytics/potential-competitors"
                    element={
                      <PremiumRoute requiredPermission={FeaturePermission.AccessCompetitorAnalysis}>
                        <PotentialCompetitorsScreen />
                      </PremiumRoute>
                    }
                  />
                  <Route path="/ports/*" element={<PortScreens />} />
                  <Route
                    path="/circular-stats"
                    element={
                      <PremiumRoute requiredPermission={FeaturePermission.AccessCircularStats}>
                        <CircularStatsScreen />
                      </PremiumRoute>
                    }
                  />
                  <Route path="/projects/*" element={<ProjectScreens />} />
                  <Route
                    path="/lab/congestion/*"
                    element={
                      <PremiumRoute requiredPermission={FeaturePermission.AccessCongestionAnalysis}>
                        <CongestionScreens />
                      </PremiumRoute>
                    }
                  />
                  <Route
                    path="/lab/tied-up-vessels"
                    element={
                      <PremiumRoute requiredPermission={FeaturePermission.AccessTiedUpVessels}>
                        <TiedUpVesselsScreen />
                      </PremiumRoute>
                    }
                  />
                  <Route path="/search/:searchTerm" element={<SearchScreen />} />
                  <Route path="/user/:id" element={<UserProfileScreen />} />
                  <Route path="/notifications" element={<NotificationsScreen />} />
                  <Route path="/negotiations/*" element={<NegotiationScreens />} />
                  <Route path="/subscription/*" element={<CompanySubscriptionScreens />} />
                  <Route
                    path="/subscription/*"
                    element={
                      <ProtectedRoute
                        component={CompanySubscriptionScreens}
                        permissions={[FeaturePermission.ManageOwnCompany]}
                      />
                    }
                  />
                  <Route path="/user-account/*" element={<UserAccountScreen />} />
                  <Route path="/test/*" element={<TestScreens />} />
                  <Route path="/setup/market-import" element={<MarketImportInstructionsScreen />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </PushMessages>
              <SessionKeepalive />
            </FullLayout>
          </UserOnboarding>
        </ErrorBoundary>
      </CompanyLoader>
    </AccessTokenManager>
  );
};

const NotLoggedIn: FC = () => {
  return <Navigate to="/login" />;
};
