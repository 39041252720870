/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UserWidgetWrite
 */
export interface UserWidgetWrite {
  /**
   *
   * @type {string}
   * @memberof UserWidgetWrite
   */
  identifier: string;
  /**
   *
   * @type {object}
   * @memberof UserWidgetWrite
   */
  settings: object;
  /**
   *
   * @type {number}
   * @memberof UserWidgetWrite
   */
  posX: number;
  /**
   *
   * @type {number}
   * @memberof UserWidgetWrite
   */
  posY: number;
  /**
   *
   * @type {number}
   * @memberof UserWidgetWrite
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof UserWidgetWrite
   */
  height: number;
}

export function UserWidgetWriteFromJSON(json: any): UserWidgetWrite {
  return UserWidgetWriteFromJSONTyped(json, false);
}

export function UserWidgetWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWidgetWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    identifier: json['identifier'],
    settings: json['settings'],
    posX: json['posX'],
    posY: json['posY'],
    width: json['width'],
    height: json['height'],
  };
}

export function UserWidgetWriteToJSON(value?: UserWidgetWrite | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    identifier: value.identifier,
    settings: value.settings,
    posX: value.posX,
    posY: value.posY,
    width: value.width,
    height: value.height,
  };
}
