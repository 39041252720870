import {useState, FC} from 'react';
import {useDispatch} from 'react-redux';
import {ClipboardItem, GridClipboardActions} from '../../../redux/GridClipboard';
import produce from 'immer';
import {useGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';
import {PortfolioType} from '../../../redux/Portfolio';
import {useAssignToSubPortfoliosMutation} from './useMultiAssignToGroupsMutation';
import pull from 'lodash/pull';
import sortBy from 'lodash/sortBy';
import {Button, Checkbox, Flex, Modal, Typography} from 'antd';
import {ModalFooter} from '../../../antd/components/ModalFooter';
import {ModalWidth} from '../../../antd/components/Modal';

const {Paragraph} = Typography;

interface AddToSubPortfoliosModalProps {
  type: PortfolioType;
  items: ClipboardItem[];
  onOk: () => void;
  onClose: () => void;
}

export const AddToSubPortfoliosModal: FC<AddToSubPortfoliosModalProps> = ({type, items, onOk, onClose}) => {
  // Selected sub portfolio ids:
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const subPortfoliosQuery = useGetSubPortfoliosQuery({type});
  const subPortfolios = subPortfoliosQuery.data?.data.items ?? [];
  const sortedSubPortfolios = sortBy(subPortfolios, 'sortOrder');

  const isVessel = type === 'vessel';

  const assignMutation = useAssignToSubPortfoliosMutation();

  const handleSubmit = async () => {
    await assignMutation.mutateAsync({type, subPortfolios: selectedIds, items});
    resetClipboardSelection();
    onClose();
    onOk();
  };

  const dispatch = useDispatch();
  const resetClipboardSelection = () => dispatch(GridClipboardActions.resetSelection('portfolio'));

  const toggleSelected = (id: number) => {
    const isSelected = selectedIds.includes(id);
    const updated = produce(selectedIds, draftSelectedIds => {
      if (isSelected) {
        draftSelectedIds = pull(draftSelectedIds, id);
      } else {
        draftSelectedIds.push(id);
      }
    });
    setSelectedIds(updated);
  };
  return (
    <Modal
      title={`Add to ${isVessel ? 'vessel lists' : 'cargo lists'}`}
      open
      onCancel={onClose}
      footer={null}
      width={ModalWidth.Middle}>
      <div className={'subPortfolios__data'}>
        <Flex vertical align="center">
          <Paragraph>
            You can only add, not remove via this modal.
            <br />
            <br />
          </Paragraph>
          {sortedSubPortfolios.length ? (
            <div>
              {sortedSubPortfolios.map(subPortfolio => (
                <div>
                  <Checkbox
                    onChange={() => toggleSelected(subPortfolio.id)}
                    id={`${subPortfolio.id}-${subPortfolio.name}`}>
                    {subPortfolio.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          ) : (
            <span>You have no {isVessel ? 'vessel lists.' : 'cargo lists.'}</span>
          )}
        </Flex>
      </div>
      {assignMutation.isError && <div className={'subPortfolios__error'}>Something went wrong, please try again</div>}
      <ModalFooter>
        <Button
          disabled={subPortfolios.length === 0 || assignMutation.isPending}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            handleSubmit();
          }}
          type="primary"
          className="uppercase">
          Add to cargo lists
        </Button>
      </ModalFooter>
    </Modal>
  );
};
