import EmptyStateVessel from '../images/emptyStateVessel.png';
import EmptyStateCargo from '../images/emptyStateCargo.png';
import EmptyStateProjects from '../images/emptyStateProjects.png';
import EmptyStateNegotiations from '../images/emptyStateNegotiations.png';
import emptyStateMarketCargoes from '../../../screens/market/EmptyState/empty-states-market-cargoes.png';
import emptyStateMarketVessels from '../../../screens/market/EmptyState/empty-states-market-vessels.png';
import './style.scss';

const _getImage = (type: $TSFixMe, location: $TSFixMe) => {
  switch (location) {
    case 'portfolio':
      return type === 'vessel' ? EmptyStateVessel : EmptyStateCargo;
    case 'projects':
      return EmptyStateProjects;
    case 'negotiations':
      return EmptyStateNegotiations;
    case 'markets':
      return type === 'vessel' ? emptyStateMarketVessels : emptyStateMarketCargoes;
    default:
      return;
  }
};

const EmptyStateArchive = ({type, location}: $TSFixMe) => {
  return (
    <div className={'empty-state'}>
      <div id="archive-empty-state" className="archive-empty-state">
        <img src={_getImage(type, location)} className="archive-empty-state__image" alt={`empty-state-archive`} />
        <div className={'archive-empty-state__header'}>Your {type} archive is empty</div>
        <div className={'archive-empty-state__subline'}>
          You are able to archive {type} items from the "3-dot-menu" in the table view <br />
          or the blue "actions button" on the detail pages in the top right corner.
        </div>
      </div>
    </div>
  );
};

export default EmptyStateArchive;
