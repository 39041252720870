import sortBy from 'lodash/sortBy';
import {
  ContainerDesignOption,
  LoadContainerDesignsFunction,
} from '../../../components/FilterProvider/Filters/Size/Size';
import {useContainerVesselDesignsQuery} from './useContainerVesselDesignsQuery';

/**
 * This hook provides a function than searches autocomplete options for container vessel design types.
 */
export const useSearchContainerDesignsForAutocomplete = (): LoadContainerDesignsFunction => {
  const queryResult = useContainerVesselDesignsQuery();

  const searchContainerDesignsForAutocomplete = async (queryString: string): Promise<ContainerDesignOption[]> => {
    const designTypes = queryResult.isSuccess ? queryResult.data.containerVesselDesigns : [];
    const options = searchContainerDesigns(queryString, designTypes);
    return options;
  };

  return searchContainerDesignsForAutocomplete;
};

export const searchContainerDesigns = (queryString: string, designTypes: string[]): ContainerDesignOption[] => {
  const found: string[] = designTypes.filter(designType => isQueryMatchingName(queryString, designType));
  const sorted = sortBy(found);
  const mapped: ContainerDesignOption[] = sorted.map(designType => ({
    label: designType,
    value: designType,
  }));
  return mapped;
};

const isQueryMatchingName = (query: string, name: string): boolean =>
  name.toLowerCase().startsWith(query.toLowerCase());
