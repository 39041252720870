import {FC} from 'react';
import styled from 'styled-components';
import {Attribute, AttributeContainer} from '../../atoms/Attribute/Attribute';
import {Dot} from '../../atoms/Dot/Dot';
import {UserDateTime} from '../DateTime/UserDateTime';
import {Rating} from '../Rating/Rating';
import {ProjectDetailCard} from './ProjectDetailCard';
import {useProjectDetails} from './useProjectDetails';

export const ProjectDetailHeader: FC = () => {
  const {project, updateProject, onEdit} = useProjectDetails();

  const {name, isArchived, dateCreated, lastUpdated, comment, rating, color} = project;

  const handleStarClick = (value: number) => {
    updateProject(project.rating !== value ? {rating: value} : {rating: 0});
  };

  return (
    <ProjectDetailCard>
      <FlexBox>
        <BoxThird>
          <Attribute tab={'xl'} label="Title" value={name + (isArchived ? ' (Archived)' : '')} />
          <Attribute tab={'xl'} label="Rating" value={<Rating rating={rating} onClick={handleStarClick} />} />
          <Attribute
            tab={'xl'}
            label="Marker"
            value={
              <DotWrapper onClick={onEdit}>
                <Dot $color={color} $size={'medium'} />
              </DotWrapper>
            }
          />
        </BoxThird>
        <BoxThird>
          <Attribute tab={'xl'} label="Created by" value={project.user.fullName} />
          <Attribute tab={'xl'} label="Date created" value={<UserDateTime value={dateCreated} format={'date'} />} />
          <Attribute tab={'xl'} label="Last updated" value={<UserDateTime value={lastUpdated} format={'date'} />} />
        </BoxThird>
        <BoxThird>
          <CommentWrapper>
            <Attribute tab={'xl'} label={'Note / comment'} value={' '} />
            <CommentBox readOnly value={comment} placeholder="Click to comment" onClick={onEdit} />
          </CommentWrapper>
        </BoxThird>
      </FlexBox>
    </ProjectDetailCard>
  );
};

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const BoxThird = styled.div`
  flex: 1 1 33%;

  ${AttributeContainer}:last-of-type {
    margin-bottom: 0;
  }
`;

const CommentWrapper = styled.div`
  margin-top: -5px;
`;

const CommentBox = styled.textarea`
  display: block;
  height: 50px;
  max-height: 50px;
  overflow-y: auto;
  width: 100%;
  padding: 5px 10px;
  margin: 5px 0 0;
  border: var(--border-base);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-inset-input);
  background: var(--color-gray-6);
  cursor: pointer;
  resize: none;
  box-sizing: border-box;
`;

const DotWrapper = styled.span`
  cursor: pointer;
`;
