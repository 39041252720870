import {workspaceApi} from '../api/symfony/symfonyApi';
import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import {CompanyWorkspace} from '../api/symfony/generated';

const WORKSPACES_QUERY_KEY = 'workspaceApi.getCompanyWorkspaces';

export const useWorkspacesQuery = (): UseQueryResult<Array<CompanyWorkspace>> => {
  return useQuery({
    queryKey: [WORKSPACES_QUERY_KEY],
    queryFn: async () => {
      const companyWorkspaces = await workspaceApi.getCompanyWorkspaces();
      return companyWorkspaces.data.items;
    },
  });
};

export const invalidateWorkspacesQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [WORKSPACES_QUERY_KEY],
  });
};
