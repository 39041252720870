import {Vessel} from '../useMyFleetQuery/useMyFleetQuery';
import {getNextOpen} from './parts/getNextOpen';
import {getShipBuilder} from './parts/getShipBuilder';
import {tabsTime} from './utils';
import {getVesselType} from './parts/getVesselType';

export const getPositionStringOther = (vessel: Vessel): string => {
  const nextOpen = getNextOpen(vessel);
  const shipBuilder = getShipBuilder(vessel);

  return `
Name: ${vessel.name}${tabsTime(10)}${nextOpen}
${getVesselType(vessel)} ${shipBuilder}

`;
};
