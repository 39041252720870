import {Coordinates} from '../../../../../utils/Coordinates';
import {getDistance} from 'geolib';

type Params = {
  waypoints: Array<Coordinates>;
  start: Coordinates;
};

export const sortWaypoints = (params: Params): Array<Coordinates> => {
  const waypoints = [...params.waypoints];
  if (waypoints.length === 0) {
    return waypoints;
  }
  const firstPoint = params.waypoints[0];

  const distanceFirstPoint = getDistance(
    {
      lat: firstPoint[0],
      lng: firstPoint[1],
    },
    {
      lat: params.start[0],
      lng: params.start[1],
    }
  );

  const lastPoint = params.waypoints[params.waypoints.length - 1];

  const distanceLastPoint = getDistance(
    {
      lat: lastPoint[0],
      lng: lastPoint[1],
    },
    {
      lat: params.start[0],
      lng: params.start[1],
    }
  );

  if (distanceFirstPoint < distanceLastPoint) {
    return waypoints;
  }
  return waypoints.reverse();
};
