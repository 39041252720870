/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ManagementHeadOwners,
  ManagementHeadOwnersFromJSON,
  ManagementHeadOwnersFromJSONTyped,
  ManagementHeadOwnersToJSON,
  ManagementManagingOwners,
  ManagementManagingOwnersFromJSON,
  ManagementManagingOwnersFromJSONTyped,
  ManagementManagingOwnersToJSON,
} from './';

/**
 *
 * @export
 * @interface OwnersReport
 */
export interface OwnersReport {
  /**
   *
   * @type {ManagementHeadOwners}
   * @memberof OwnersReport
   */
  managementHeadOwners: ManagementHeadOwners;
  /**
   *
   * @type {ManagementManagingOwners}
   * @memberof OwnersReport
   */
  managementManagingOwners: ManagementManagingOwners;
}

export function OwnersReportFromJSON(json: any): OwnersReport {
  return OwnersReportFromJSONTyped(json, false);
}

export function OwnersReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnersReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    managementHeadOwners: ManagementHeadOwnersFromJSON(json['managementHeadOwners']),
    managementManagingOwners: ManagementManagingOwnersFromJSON(json['managementManagingOwners']),
  };
}

export function OwnersReportToJSON(value?: OwnersReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    managementHeadOwners: ManagementHeadOwnersToJSON(value.managementHeadOwners),
    managementManagingOwners: ManagementManagingOwnersToJSON(value.managementManagingOwners),
  };
}
