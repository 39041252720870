import {Form, List, Tooltip, Space} from 'antd';
import React, {useEffect} from 'react';
import {blankVoyagePointOutput, CalcTimeLinePoint, VoyagePoint, VoyagePointOutput} from '../../../VoyageTypes';
import NoteIcon from '../../../../utils/NoteIcon';
import {VesselConsumptionMode} from '../../../../VesselInformation/VesselTypes';
import {Definitions} from '../../../Definitions/Definitions';
import {PointHeaderRow} from '../PointHeaderRow';
import {ValueRow} from '../../../Definitions/ValueRow';
import {Value} from '../../../Definitions/Value';
import {getFormattedDateOrNotAvailable} from '../../../../../../../utils/DateTime';
import {Quantity} from './Quantity';
import {Terms} from './Terms';
import {PortPickerWithFlag} from '../../../../../../PortPicker/PortPickerWithFlag';
import {Types} from './Types';
import {regexNotIncludeMinusSign} from '../../../../../../../utils/regexExpressions';
import {DurationCell} from '../../../utils/DurationCell';
import {SelectAlternativePort} from './SelectAlternativePort';
import {InputWithDataFieldId} from '../../../utils/InputWithDataFieldId';
import * as LegRow from '../../../LegRow';
import {FuelTypeSelect} from '../../../../VesselInformation/SpeedAndConsumtion/EditModal';
import {ConsumptionOrSpeedField} from '../../../utils/ConsumptionOrSpeedField';
import {Co2EmissionValueCell} from '../../Co2EmissionValueCell';
import {SearchPickerPort} from '../../../../../../SearchPicker/SearchPickerPort';
import {ExtraTimeField} from './ExtraTimeField';
import {PortDAField} from './PortDAField';
import {VoyageCalculationButton} from '../../../../utils/VoyageCalculationButton';
import {StyledFormItem} from '../../StyledFormItem';

export const PointEditView = (props: {
  consumptionModes: VesselConsumptionMode[];
  item: CalcTimeLinePoint;
  area: string;
  onDeactivateEditMode: () => void;
  onChangePort: (port: SearchPickerPort | undefined) => void;
  isFirstLoadingLeg: boolean;
  avgDurationLast10VesselsInMinutes: number | undefined;
  onRemovePoint: () => void;
  onChangePoint: (point: Partial<VoyagePoint>) => void;
}) => {
  const [form] = Form.useForm();

  const {item} = props;
  const point = props.item.item;
  const outputItem: VoyagePointOutput = item.outputItem ?? blankVoyagePointOutput;

  const port = point.name
    ? {
        name: point.name,
        countryObject: {
          code: point.countryCode,
        },
      }
    : undefined;

  /*
  since the waiting time is manipulated by editing other fields,
  when the form is open, the form must be updated.
  */
  useEffect(() => {
    form.setFieldsValue({
      waitingDurationInHours: point.waitingDurationInHours,
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [point.waitingDurationInHours]);

  return (
    <Form<VoyagePoint>
      size={'middle'}
      onValuesChange={(v: Partial<VoyagePoint>) => {
        const changes: Partial<VoyagePoint> = form.getFieldsValue();

        if (!(changes.types ?? []).includes('loading')) {
          changes.load = 0;
          form.setFieldsValue({load: 0});
        }

        if (!(changes.types ?? []).includes('discharge')) {
          changes.dis = 0;
          form.setFieldsValue({dis: 0});
        }
        if (v.consumption) {
          /*
          if the consumption is changed via the form,
          this is always to be interpreted as a manual change,
           which implies that it cannot be said if it is eco
           */
          changes.consumption = {...point.consumption, ...v.consumption};
        }

        props.onChangePoint(changes);
      }}
      onFinish={() => {
        props.onDeactivateEditMode();
      }}
      form={form}
      initialValues={point}>
      <LegRow.Wrapper>
        <List.Item.Meta
          title={
            <LegRow.Header>
              <div data-cy="legRowNameEdit" key={'name'}>
                <PortPickerWithFlag
                  isTriggeredOnTextChange={false}
                  port={port}
                  onChange={props.onChangePort}
                  size="small"
                />
              </div>
              {point.alternativePorts && (
                <div data-cy="legRowAlternativesEdit">
                  <SelectAlternativePort
                    point={point}
                    onChange={changes => {
                      props.onChangePoint(changes);
                      form.setFieldsValue(changes);
                    }}
                  />
                </div>
              )}
              <div data-cy="legRowTypesEdit" key={'types'} style={{minWidth: 120, maxWidth: 310, height: 24}}>
                <Types item={item} />
              </div>
              {point.note !== undefined && point.note.length > 0 && (
                <Tooltip key={'note'} title={point.note}>
                  <NoteIcon key={'icon'} />
                </Tooltip>
              )}
              <VoyageCalculationButton
                type={'primary'}
                size="small"
                data-cy="legRowSubmitEdit"
                onClick={() => form.submit()}>
                Save
              </VoyageCalculationButton>
            </LegRow.Header>
          }
          description={
            <>
              <Definitions>
                <PointHeaderRow avgDurationLast10VesselsInMinutes={props.avgDurationLast10VesselsInMinutes} />
                <ValueRow>
                  <Value>
                    <Space.Compact>
                      <StyledFormItem
                        noStyle
                        name={['consumption', 'mainConsumption']}
                        normalize={s => {
                          const n = parseFloat(s);
                          if (isNaN(n)) {
                            return undefined;
                          }
                          if (n < 0) {
                            return 0;
                          }
                          return n;
                        }}>
                        <ConsumptionOrSpeedField
                          item={props.item}
                          field={'consumption.mainConsumption'}
                          area={props.area}
                          style={{width: 80}}
                          consumptionModes={props.consumptionModes}
                          onChangeCompleteConsumption={consumption => {
                            form.setFieldsValue({consumption});
                            props.onChangePoint({
                              ...point,
                              consumption,
                            });
                          }}
                        />
                      </StyledFormItem>
                      <StyledFormItem noStyle name={['consumption', 'mainFuelType']}>
                        <FuelTypeSelect dataCy={'mainFuelType'} size="small" />
                      </StyledFormItem>
                    </Space.Compact>
                  </Value>
                  <Value>
                    <Space.Compact>
                      <StyledFormItem
                        noStyle
                        name={['consumption', 'auxConsumption']}
                        normalize={s => {
                          const n = parseFloat(s);
                          if (isNaN(n)) {
                            return undefined;
                          }
                          if (n < 0) {
                            return 0;
                          }
                          return n;
                        }}>
                        <ConsumptionOrSpeedField
                          item={props.item}
                          consumptionModes={props.consumptionModes}
                          field={'consumption.auxConsumption'}
                          area={props.area}
                          style={{width: 80}}
                          onChangeCompleteConsumption={consumption => {
                            form.setFieldsValue({consumption});
                            props.onChangePoint({
                              ...point,
                              consumption,
                            });
                          }}
                        />
                      </StyledFormItem>
                      <StyledFormItem noStyle name={['consumption', 'auxFuelType']}>
                        <FuelTypeSelect dataCy={'auxFuelType'} size="small" />
                      </StyledFormItem>
                    </Space.Compact>
                  </Value>
                  <Value>
                    <Quantity area={props.area} point={point} />
                  </Value>
                  <Value>
                    <Terms area={props.area} point={point} />
                  </Value>
                  <Value>
                    <StyledFormItem
                      style={{width: 110}}
                      rules={[
                        {
                          message: 'Turn time must be greater than or equal to 0.',
                          pattern: regexNotIncludeMinusSign,
                        },
                      ]}
                      normalize={s => {
                        const n = parseInt(s, 10);
                        if (isNaN(n)) {
                          return undefined;
                        }
                        if (n < 0) {
                          return 0;
                        }
                        return n;
                      }}
                      name="turnTimeInHours">
                      <InputWithDataFieldId
                        area={props.area}
                        field={'turnTimeInHours'}
                        style={{width: 110}}
                        type={'number'}
                        suffix={'hours'}
                      />
                    </StyledFormItem>
                  </Value>
                  <Value>
                    <StyledFormItem
                      style={{width: 110}}
                      rules={[
                        {
                          message: 'Extra time must be greater than or equal to 0.',
                          pattern: regexNotIncludeMinusSign,
                        },
                      ]}
                      normalize={s => {
                        const n = parseInt(s, 10);
                        if (isNaN(n)) {
                          return undefined;
                        }
                        if (n < 0) {
                          return 0;
                        }
                        return n;
                      }}
                      name="waitingDurationInHours">
                      <ExtraTimeField
                        area={props.area}
                        legItem={item}
                        avgDurationLast10VesselsInMinutes={props.avgDurationLast10VesselsInMinutes}
                      />
                    </StyledFormItem>
                  </Value>
                  <Value>
                    <StyledFormItem
                      rules={[
                        {
                          message: 'Port DA must be greater than or equal to 0.',
                          pattern: regexNotIncludeMinusSign,
                        },
                      ]}
                      normalize={s => {
                        const n = parseInt(s, 10);
                        return isNaN(n) ? undefined : n;
                      }}
                      name="portCosts">
                      <PortDAField legItem={item} area={props.area} />
                    </StyledFormItem>
                  </Value>
                  <DurationCell point={item} />
                  <Co2EmissionValueCell point={point} pointOutput={outputItem} />
                  <Value>
                    <span data-cy="legRowStartDate">{getFormattedDateOrNotAvailable(outputItem.startDate)}</span>
                  </Value>
                  <Value>
                    <span data-cy="legRowEndDate">{getFormattedDateOrNotAvailable(outputItem.endDate)}</span>
                  </Value>
                </ValueRow>
              </Definitions>
            </>
          }
        />
      </LegRow.Wrapper>
    </Form>
  );
};
