import {datadogRum} from '@datadog/browser-rum';

export const initDatadog = () => {
  // eslint-disable-next-line  no-template-curly-in-string
  if (!window.DD_CLIENT_TOKEN || window.DD_CLIENT_TOKEN === '${DD_CLIENT_TOKEN}') {
    // eslint-disable-next-line no-console
    console.warn('Datadog client token not found - skipping Datadog RUM initialization');
    return;
  }

  datadogRum.init({
    applicationId: window.DD_APPLICATION_ID!,
    clientToken: window.DD_CLIENT_TOKEN!,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'frontend',
    // The environment that is tracked (only allows lowercase letters)
    env: window.PLATFORM_ENV?.toLowerCase(),
    // The version number to identify the deployed version of your application in Datadog
    version: window.__FRONTEND_VERSION__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};
