/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VesselStop, VesselStopFromJSON, VesselStopFromJSONTyped, VesselStopToJSON} from './';

/**
 *
 * @export
 * @interface VesselStops
 */
export interface VesselStops {
  /**
   *
   * @type {Array<VesselStop>}
   * @memberof VesselStops
   */
  vesselStops: Array<VesselStop>;
}

export function VesselStopsFromJSON(json: any): VesselStops {
  return VesselStopsFromJSONTyped(json, false);
}

export function VesselStopsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselStops {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vesselStops: (json['vesselStops'] as Array<any>).map(VesselStopFromJSON),
  };
}

export function VesselStopsToJSON(value?: VesselStops | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vesselStops: (value.vesselStops as Array<any>).map(VesselStopToJSON),
  };
}
