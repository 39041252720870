import {VesselVoyageTimelineEventType, VesselVoyageTimelineResponse} from '../../../../api/node-backend/generated';

// "& {}" is used to get key preview
// eslint-disable-next-line
export type VoyageTimelineEventKey = keyof VesselVoyageTimelineResponse & {};

export type VoyageTimelineFilters = Record<VesselVoyageTimelineEventType, boolean>;

export const voyageTimelineFilterLabels: Record<VesselVoyageTimelineEventType, string> = {
  [VesselVoyageTimelineEventType.PortVisit]: 'Port Visit',
  [VesselVoyageTimelineEventType.PassingPortVisit]: 'Port Passing',
  [VesselVoyageTimelineEventType.AnchorageVisit]: 'Anchorage Visit',
  [VesselVoyageTimelineEventType.PierVisit]: 'Pier Visit',
  [VesselVoyageTimelineEventType.DryDockVisit]: 'Dry Dock',
  [VesselVoyageTimelineEventType.Route]: 'Sea Voyage',
  [VesselVoyageTimelineEventType.DestinationChange]: 'Destination Change',
  [VesselVoyageTimelineEventType.EtaChange]: 'ETA Change',
  [VesselVoyageTimelineEventType.DraftChange]: 'Draft Change',
  [VesselVoyageTimelineEventType.Drift]: 'Drift',
  [VesselVoyageTimelineEventType.NameChange]: 'Name Change',
  [VesselVoyageTimelineEventType.FlagChange]: 'Flag Change',
  [VesselVoyageTimelineEventType.AisOutage]: 'AIS Outage',
};

export const defaultVoyageTimelineFilters: VoyageTimelineFilters = {
  [VesselVoyageTimelineEventType.PortVisit]: true,
  [VesselVoyageTimelineEventType.PassingPortVisit]: true,
  [VesselVoyageTimelineEventType.AnchorageVisit]: true,
  [VesselVoyageTimelineEventType.PierVisit]: true,
  [VesselVoyageTimelineEventType.DryDockVisit]: true,
  [VesselVoyageTimelineEventType.Route]: true,
  [VesselVoyageTimelineEventType.DestinationChange]: true,
  [VesselVoyageTimelineEventType.EtaChange]: true,
  [VesselVoyageTimelineEventType.DraftChange]: true,
  [VesselVoyageTimelineEventType.Drift]: true,
  [VesselVoyageTimelineEventType.NameChange]: true,
  [VesselVoyageTimelineEventType.FlagChange]: true,
  [VesselVoyageTimelineEventType.AisOutage]: true,
};
