import {ApiError} from '../../../../api/utils/ApiError';
import {File} from '../../../../components/ExportReportsModal/File';
import {getRecommendedFilename} from '../../../../components/ExportReportsModal/getRecommendedFilename';
import {TODO} from '../../../../utils/TODO';

interface Options {
  group?: 'all' | number;
  vesselFilters: TODO;
  reportList: string[];
  vesselIdFilter: number[] | null;
}

export const getPortfolioVesselReports = async (options: Options): Promise<File> => {
  const downloadUrl = getDownloadUrl(options);
  const response = await fetch(downloadUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new ApiError(response.statusText, response.status);
  }

  const blob = await response.blob();
  const filename = getRecommendedFilename(response) ?? 'Report.xlsx';

  const file: File = {blob, filename};
  return file;
};

const getDownloadUrl = (options: Options) => {
  const {group, vesselFilters, reportList, vesselIdFilter} = options;

  const queryParams = new URLSearchParams();
  queryParams.set('group', group !== 'all' ? (group as string | undefined) ?? '' : '');
  queryParams.set('export-type', reportList.join(','));
  if (vesselFilters) {
    queryParams.set('filters', JSON.stringify(vesselFilters));
  }
  if (vesselIdFilter) {
    queryParams.set('vessels', vesselIdFilter.join(','));
  }

  const downloadUrl = `/api/portfolio/vessels/export?${queryParams}`;

  return downloadUrl;
};
