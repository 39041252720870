import {InputState} from './voyageChartercalculation/voyageChartercalculation';
import produce, {Draft} from 'immer';
import {getBunkerPriceProposalsByQuery} from './useBunkerPriceProposalsQuery';

export const appendBunkerPricesToInputState = async (inputState: InputState): Promise<InputState> => {
  const bunkerPrices = await getBunkerPriceProposalsByQuery({voyagePoints: inputState.voyage.points});

  const ifoPrices = bunkerPrices.filter(price => ['IFO380', 'IFO180'].includes(price.grade!));

  const mgoPrices = bunkerPrices.filter(price => price.fuelType === 'mgo');

  const vlsfoPrices = bunkerPrices.filter(price => price.grade === 'VLSFO');

  return produce<InputState>(inputState, (draft: Draft<InputState>) => {
    if (mgoPrices[0]?.currentPrice) {
      draft.cost.costMgoPerMts = mgoPrices[0].currentPrice;
    }
    if (mgoPrices[0]?.currentPrice) {
      draft.cost.costMdoPerMts = mgoPrices[0].currentPrice;
    }
    if (ifoPrices[0]?.currentPrice) {
      draft.cost.costIfoPerMts = ifoPrices[0].currentPrice;
    }
    if (vlsfoPrices[0]?.currentPrice) {
      draft.cost.costVlsfoPerMts = vlsfoPrices[0].currentPrice;
    }
  });
};
