import {ReactNode} from 'react';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import styled from 'styled-components';
import {Blurred} from '../../utils/Blurred';

export interface EngineColumnAccessorOptions {
  blurred?: boolean;
}

export const engineAccessor =
  (options: EngineColumnAccessorOptions): AccessorFn<SeaboVessel> =>
  (vessel): ReactNode => {
    if (!vessel.engine) {
      return <Blurred blurred={options.blurred}>-</Blurred>;
    }
    return (
      <Blurred blurred={options.blurred}>
        <Line>
          {vessel.engine.mainEngineDesigner} {vessel.engine.engineDesignation}
        </Line>
        <Line>
          {vessel.engine.mcoKw} kw / {vessel.engine.mcoHp} hp / {vessel.engine.mcoRpm} rpm
        </Line>
      </Blurred>
    );
  };
export const engineColumn = ({
  minWidth = 10,
  options = {},
}: {
  minWidth?: number;
  options?: EngineColumnAccessorOptions;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div data-cy="header-engine">
      <div>Engine</div>
    </div>
  ),

  id: 'engine',
  accessorFn: engineAccessor(options),
  cell: info => info.getValue(),
  enableSorting: false,
  minWidth,
});

const Line = styled.div`
  white-space: nowrap;
`;
