import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Link, useNavigate} from 'react-router-dom';
import {PasswordRecoveryRequest} from '../../../api/symfony/generated/models/PasswordRecoveryRequest';
import {CenteredContentArea, FullscreenDialog} from '../../../atoms/FullscreenDialog/FullscreenDialog';
import {Center} from '../../../components/Center/Center';
import {notification} from '../../../components/Notification';
import {BottomArea, BottomLink, Dialog, DividerStyled, TitleStyled} from '../utils/shared-components';
import {DashboardButton} from './DashboardButton';
import {LostPasswordForm} from './LostPasswordForm';
import {useRecoverPasswordMutation} from '../../../queries/useRecoverPasswordMutation';

export const LostPasswordScreen = () => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const recoverPasswordMutation = useRecoverPasswordMutation({
    onSuccess: () => {
      navigate('/authentication/lost-password/email-sent');
    },
    onError: error => {
      if (error.status === 422) {
        setErrorMessage(error.message);
        return;
      }

      if (error.status === 503) {
        setErrorMessage(error.payload?.title ?? error.message);
        return;
      }

      const args = {
        message: 'API Error',
        description: error.message,
        duration: 0,
      };
      notification.error(args);
    },
  });

  const onChange = () => {
    setErrorMessage(undefined);
  };

  const onSubmit = (formValues: PasswordRecoveryRequest) => {
    recoverPasswordMutation.mutate(formValues);
  };

  return (
    <>
      <Helmet>
        <title>Lost password</title>
      </Helmet>
      <FullscreenDialog topRightChildren={<DashboardButton />}>
        <CenteredContentArea>
          <Center>
            <Dialog>
              <Center>
                <TitleStyled level={3}>Lost password</TitleStyled>
              </Center>
              <DividerStyled />
              <LostPasswordForm
                errorMessage={errorMessage}
                submitting={recoverPasswordMutation.isPending}
                onChange={onChange}
                onSubmit={onSubmit}
              />
              <BottomArea>
                <Link to="/login">
                  <BottomLink block>Back to login</BottomLink>
                </Link>
              </BottomArea>
            </Dialog>
          </Center>
        </CenteredContentArea>
      </FullscreenDialog>
    </>
  );
};
