import {FC} from 'react';
import {useCurrentFilters} from './utils/useCurrentFilters';
import {SaveFilterButton} from './SaveFilterButton';
import {MarketMap} from './MarketMap/MarketMap';
import {VesselGridLayoutSelect} from '../../components/VesselGridLayoutSelect/VesselGridLayoutSelect';
import {MarketVesselGrid} from './MarketVessel/MarketVesselGrid';
import {MarketCargoGrid} from './MarketCargo/MarketCargoGrid';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {useSelector} from '../../redux/react-redux';
import {useDispatch} from 'react-redux';
import {MarketActions, MarketType, VesselGridLayoutMode} from '../../redux/Market';
import {MarketCargoFilters} from './MarketCargoFilters';
import {MarketVesselFilters} from './MarketVesselFilters';
import {HeroFilterMapCollapse} from '../../components/HeroFilterMapCollapse/HeroFilterMapCollapse';
import {MarketImportPromotion} from '../../components/MarketImportPromotion/MarketImportPromotion';
import {FeaturePermission} from '../../api/symfony/generated';
import {useIsPermitted} from '../../utils/useIsPermitted';
import {TabListLayout} from '../../components/TabList/TabList';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {EasySharingPromotionBanner} from './EasySharingPromotionBanner';
import {SubMarketList} from './SubMarketList/SubMarketList';
import {MarketTourDefinition} from './MarketTourDefinition';
import {MarketVesselFilterBranchDefinitions} from '../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';
import {MarketCargoFilterBranchDefinitions} from '../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';

export const MarketBody: FC<{
  marketType: MarketType;
  vesselFilterProviderApi: FilterProviderApi<typeof MarketVesselFilterBranchDefinitions>;
  cargoFilterProviderApi: FilterProviderApi<typeof MarketCargoFilterBranchDefinitions>;
  userSelectedLayoutMode: VesselGridLayoutMode;
  displayedLayoutMode: VesselGridLayoutMode;
  onChangeVesselGridMode: (mode: VesselGridLayoutMode) => void;
  onVesselsLoadeded: () => void;
  _changeAllSelection: (api: 'cargoes' | 'vessels') => void;
}> = ({
  marketType,
  userSelectedLayoutMode,
  displayedLayoutMode,
  onChangeVesselGridMode,
  vesselFilterProviderApi,
  cargoFilterProviderApi,
  onVesselsLoadeded,
  _changeAllSelection,
}) => {
  const dispatch = useDispatch();

  const {isFilterUiOpen} = useSelector(state => state.market);
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);

  const currentFilters = useCurrentFilters({
    type: marketType,
    cargoFilterProviderApi,
    vesselFilterProviderApi,
  });
  const {filterProviderApi} = currentFilters;

  const setFilterUiOpen = (open: boolean) => {
    return dispatch(MarketActions.setFilterUiOpen(open));
  };

  const setMarketType = (marketType: MarketType) => {
    return dispatch(MarketActions.setMarketType(marketType));
  };

  const breadcrumbs = [{title: 'Market'}];

  return (
    <div className="market-screen">
      <ScreenHeader
        helmetTitle={'Market'}
        breadcrumbs={breadcrumbs}
        tourDefinition={MarketTourDefinition}
        features={
          <>
            {filterProviderApi.isAnyFilterApplied && (
              <SaveFilterButton {...currentFilters} disabled={filterProviderApi.disableSaveButton} />
            )}
            {filterProviderApi.isAnyFilterApplied && (
              <ScreenHeader.Features.Button data-cy="MarketResetFiltersBTN" onClick={filterProviderApi.onResetAllClick}>
                Reset filters
              </ScreenHeader.Features.Button>
            )}
            <ScreenHeader.Features.Toggle
              value={isFilterUiOpen}
              onChange={event => setFilterUiOpen(event.target.value)}
              options={[
                {
                  id: 'show-filters',
                  label: `Filters${
                    filterProviderApi.appliedFilterCount ? ` (${filterProviderApi.appliedFilterCount})` : ''
                  }`,
                  value: true,
                  props: {
                    'data-cy': 'MarketShowFiltersBTN',
                  },
                },
                {
                  id: 'show-map',
                  label: 'Map',
                  value: false,
                  props: {
                    'data-cy': 'MarketShowMapBTN',
                  },
                },
              ]}
            />
            <ScreenHeader.Features.Toggle
              value={marketType}
              onChange={event => setMarketType(event.target.value)}
              options={[
                {
                  id: 'section-vessel',
                  label: 'Vessel',
                  value: 'vessel',
                  props: {
                    'data-cy': 'MarketShowVesselsBTN',
                  },
                },
                {
                  id: 'section-cargo',
                  label: 'Cargo',
                  value: 'cargo',
                  props: {
                    'data-cy': 'MarketShowCargoesBTN',
                  },
                },
              ]}
            />
          </>
        }
      />
      <HeroFilterMapCollapse
        viewOpen={isFilterUiOpen ? 'filters' : 'map'}
        filters={
          <>
            {marketType === 'vessel' ? <MarketVesselFilters vesselFilterProviderApi={vesselFilterProviderApi} /> : null}
            {marketType === 'cargo' ? <MarketCargoFilters cargoFilterProviderApi={cargoFilterProviderApi} /> : null}
          </>
        }
        map={<MarketMap />}
      />
      <EasySharingPromotionBanner />
      <TabListLayout>
        <SubMarketList marketType={marketType} filterProviderApi={filterProviderApi} />
        {marketType === 'vessel' && (
          <VesselGridLayoutSelect
            displayedLayoutMode={displayedLayoutMode}
            userSelectedLayoutMode={userSelectedLayoutMode}
            onChangeUserSelectedLayoutMode={mode => {
              onChangeVesselGridMode(mode);
            }}
          />
        )}
      </TabListLayout>
      {marketType === 'vessel' && (
        <MarketVesselGrid
          onVesselsLoadeded={onVesselsLoadeded}
          displayedLayoutMode={displayedLayoutMode}
          vesselFilterProviderApi={vesselFilterProviderApi}
        />
      )}
      {marketType === 'cargo' && (
        <MarketCargoGrid
          _changeAllSelection={() => {
            _changeAllSelection('cargoes');
          }}
          cargoFilterProviderApi={cargoFilterProviderApi}
        />
      )}
      {manageOwnCompanyPermitted && <MarketImportPromotion />}
    </div>
  );
};
