/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationActor,
  NegotiationActorFromJSON,
  NegotiationActorFromJSONTyped,
  NegotiationActorToJSON,
  NegotiationCargo,
  NegotiationCargoFromJSON,
  NegotiationCargoFromJSONTyped,
  NegotiationCargoToJSON,
  NegotiationParty,
  NegotiationPartyFromJSON,
  NegotiationPartyFromJSONTyped,
  NegotiationPartyToJSON,
  NegotiationRoundResultSummary,
  NegotiationRoundResultSummaryFromJSON,
  NegotiationRoundResultSummaryFromJSONTyped,
  NegotiationRoundResultSummaryToJSON,
  NegotiationStatus,
  NegotiationStatusFromJSON,
  NegotiationStatusFromJSONTyped,
  NegotiationStatusToJSON,
  NegotiationVessel,
  NegotiationVesselFromJSON,
  NegotiationVesselFromJSONTyped,
  NegotiationVesselToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationSummary
 */
export interface NegotiationSummary {
  /**
   * The negotiation id is the id from a negotiation on the platform.
   * @type {string}
   * @memberof NegotiationSummary
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NegotiationSummary
   */
  title: string;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationSummary
   */
  party: NegotiationParty;
  /**
   *
   * @type {string}
   * @memberof NegotiationSummary
   */
  privateNote: string | null;
  /**
   *
   * @type {NegotiationVessel}
   * @memberof NegotiationSummary
   */
  vessel: NegotiationVessel;
  /**
   *
   * @type {NegotiationCargo}
   * @memberof NegotiationSummary
   */
  cargo: NegotiationCargo;
  /**
   *
   * @type {NegotiationActor}
   * @memberof NegotiationSummary
   */
  creator: NegotiationActor;
  /**
   *
   * @type {NegotiationActor}
   * @memberof NegotiationSummary
   */
  counterpart: NegotiationActor;
  /**
   *
   * @type {Date}
   * @memberof NegotiationSummary
   */
  canceledAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof NegotiationSummary
   */
  createdAt: Date | null;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationSummary
   */
  canceledBy: NegotiationParty;
  /**
   *
   * @type {NegotiationStatus}
   * @memberof NegotiationSummary
   */
  status: NegotiationStatus;
  /**
   *
   * @type {NegotiationRoundResultSummary}
   * @memberof NegotiationSummary
   */
  latestRound: NegotiationRoundResultSummary;
  /**
   *
   * @type {number}
   * @memberof NegotiationSummary
   */
  roundCount: number;
  /**
   * The reason for the cancellation of the negotiation. This is only provided but not required when the negotiation has been cancelled.
   * @type {string}
   * @memberof NegotiationSummary
   */
  cancellationReason: string | null;
}

export function NegotiationSummaryFromJSON(json: any): NegotiationSummary {
  return NegotiationSummaryFromJSONTyped(json, false);
}

export function NegotiationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    party: NegotiationPartyFromJSON(json['party']),
    privateNote: json['privateNote'],
    vessel: NegotiationVesselFromJSON(json['vessel']),
    cargo: NegotiationCargoFromJSON(json['cargo']),
    creator: NegotiationActorFromJSON(json['creator']),
    counterpart: NegotiationActorFromJSON(json['counterpart']),
    canceledAt: json['canceledAt'],
    createdAt: json['createdAt'],
    canceledBy: NegotiationPartyFromJSON(json['canceledBy']),
    status: NegotiationStatusFromJSON(json['status']),
    latestRound: NegotiationRoundResultSummaryFromJSON(json['latestRound']),
    roundCount: json['roundCount'],
    cancellationReason: json['cancellationReason'],
  };
}

export function NegotiationSummaryToJSON(value?: NegotiationSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    party: NegotiationPartyToJSON(value.party),
    privateNote: value.privateNote,
    vessel: NegotiationVesselToJSON(value.vessel),
    cargo: NegotiationCargoToJSON(value.cargo),
    creator: NegotiationActorToJSON(value.creator),
    counterpart: NegotiationActorToJSON(value.counterpart),
    canceledAt: value.canceledAt,
    createdAt: value.createdAt,
    canceledBy: NegotiationPartyToJSON(value.canceledBy),
    status: NegotiationStatusToJSON(value.status),
    latestRound: NegotiationRoundResultSummaryToJSON(value.latestRound),
    roundCount: value.roundCount,
    cancellationReason: value.cancellationReason,
  };
}
