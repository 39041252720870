import {VoyageInput} from '../../VoyageInformation/VoyageTypes';
import {VesselInput} from '../../VesselInformation/VesselTypes';

export const isVesselNextOpenPortFirstPoint = (voyage: VoyageInput, vessel: VesselInput) => {
  const vesselNextOpenPort = vessel?.nextOpenPoint;
  const voyageFirstPoint = voyage?.points[0];

  const nextOpenPortId = vesselNextOpenPort?.portId || vesselNextOpenPort?.originalPort?.id;
  const firstPointPortId = voyageFirstPoint?.portId || voyageFirstPoint?.originalPort?.id;

  if (!nextOpenPortId || !firstPointPortId) {
    return false;
  }

  return nextOpenPortId === firstPointPortId;
};
