import {FullScreenButton} from './FullScreenButton';
import {SelectLayerButton} from './SelectLayerButton';
import {SettingsViewButton} from './SettingsViewButton';
import './styles.scss';
import {ZoomIncreaseButton} from './ZoomIncreaseButton';
import {ZoomDecreaseButton} from './ZoomDecreaseButton';
import styled from 'styled-components';
import {FocusOnCoordinatesButton} from './FocusOnCoordinatesButton';

const MapNavigationBar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  right: 12px;
  bottom: 28px;
  z-index: 20;

  button {
    min-height: unset;
    color: var(--color-white-fixed) !important;
    &:disabled {
      color: var(--color-gray-2-fixed) !important;
    }
  }
`;

export const MapNavigation = () => {
  return (
    <MapNavigationBar>
      <FocusOnCoordinatesButton />
      <SettingsViewButton />
      <SelectLayerButton />
      <FullScreenButton />
      <ZoomIncreaseButton />
      <ZoomDecreaseButton />
    </MapNavigationBar>
  );
};

export default MapNavigation;
