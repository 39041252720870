import {PageHeader} from '@ant-design/pro-layout';
import {Col, Row} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {TODO} from '../../../utils/TODO';
import {ResultCard} from './ResultCard';

export const PageHeaderBox: FC<{inputState: TODO; outputState: TODO}> = ({inputState, outputState}) => {
  return (
    <StyledAffix>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledPageHeader data-cy={'PageHeader'} ghost={false}>
            <HeaderContainer>
              <ResultCard inputState={inputState} outputState={outputState} />
            </HeaderContainer>
          </StyledPageHeader>
        </Col>
      </Row>
    </StyledAffix>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 16px 0;
  margin: 0 1px;
  background: var(--color-white);
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-widget-dragging);
  overflow: hidden;

  .ant-page-header-content {
    padding-top: 4px;
  }
  .ant-page-header-heading {
    padding: 4px 24px 16px 24px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAffix = styled.div`
  transition: all 0.3s;
  position: sticky;
  top: 72px;
  z-index: 5;
  margin-bottom: 16px;
`;
