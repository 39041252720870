import {useState, FC} from 'react';
import {Col, Row} from 'antd';
import {ImportCargoWithParser} from './ImportCargoWithParser';
import {
  BorderBox,
  FullWidthButton,
  Headline,
  PickerHeadline,
  PickerWrapper,
  VerticalSeparator,
  Wrapper,
} from '../utils/components';
import styled from 'styled-components';
import {Attribute} from '../../../../../atoms/Attribute/Attribute';
import {CargoPicker} from '../../../../CargoPicker/CargoPicker';
import {getFormattedDateOrNotAvailable} from '../../../../../utils/DateTime';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {Cargo} from '../../../../SearchPicker/Type';
import {ContractType} from '../../../../../api/symfony/generated';
import {fetchJSON} from '../../../../../api/utils/fetchJSON';
import {NotAvailable} from '../../../../../utils/NotAvailable';

const extractValue = (values: string | string[]) => {
  if (Array.isArray(values)) {
    return values[0];
  }
  return values;
};

export const CargoImport: FC<{
  cargoId?: number;
  onSubmit: (cargo: Cargo) => void;
  onBack: () => void;
  onSkip: () => void;
}> = ({cargoId, onSubmit, onBack, onSkip}) => {
  const cargoQuery = useQuery({
    queryKey: [cargoId],

    queryFn: async () => {
      if (!cargoId) {
        return null;
      }
      return await fetchJSON<Cargo>(`/api/cargoes/${cargoId}`);
    },
    placeholderData: keepPreviousData,
  });

  const preselectedCargo: Cargo | null = cargoQuery?.data ?? null;
  const [cargo, setCargo] = useState<Cargo | null>(preselectedCargo);

  const pickerPlaceholder =
    cargo?.chartererName || cargo?.commodityCategory
      ? `${cargo?.chartererName ?? 'TBA'} - ${cargo?.commodityCategory ?? NotAvailable}`
      : '';

  return (
    <Row gutter={[16, 16]} justify={'center'}>
      <Col span={24}>
        <Headline>Search or parse cargo</Headline>
      </Col>
      <Col span={11}>
        <Wrapper>
          <BorderBox $highlight={!!cargo}>
            <PickerHeadline>{cargo ? 'Your chosen cargo: ' : 'Search for My Cargoes cargo below'}</PickerHeadline>
            <CargoPickerWrapper>
              <CargoPicker
                paramContractType={ContractType.Vc}
                size={'middle'}
                defaultValue={pickerPlaceholder}
                indexNames={['cargoes_portfolio']}
                onSelect={cargo => setCargo(cargo)}
              />
            </CargoPickerWrapper>
            <Attribute label={'Cargo type'} value={cargo?.cargoType} upper={true} tab={'xxl'} />
            <Attribute label={'Quantity'} value={cargo?.quantity} upper={true} tab={'xxl'} />
            <Attribute
              label={'Laycan'}
              value={`${getFormattedDateOrNotAvailable(
                cargo?.laycanFrom ?? undefined
              )} - ${getFormattedDateOrNotAvailable(cargo?.laycanTo ?? undefined)}`}
              upper={true}
              tab={'xxl'}
            />
            <Attribute label={'Total commission'} value={cargo?.commissionTotal} upper={true} tab={'xxl'} />
            <Attribute
              label={'Commodity category'}
              value={extractValue(cargo?.commodityCategory ?? '')}
              upper={true}
              tab={'xxl'}
            />
            <Attribute
              label={'Commodity type'}
              value={extractValue(cargo?.commodityType ?? '')}
              upper={true}
              tab={'xxl'}
            />
          </BorderBox>
          <FullWidthButton
            data-cy="nextButton"
            type={'primary'}
            onClick={() => (cargo ? onSubmit(cargo) : null)}
            disabled={!cargo}>
            Next
          </FullWidthButton>
        </Wrapper>
      </Col>
      <Col span={1}>
        <VerticalSeparator>or</VerticalSeparator>
      </Col>
      <Col span={11}>
        <ImportCargoWithParser onSubmit={onSubmit} />
      </Col>
      <StyledCol span={12}>
        <BackButton data-cy="backButton" onClick={onBack}>
          Back
        </BackButton>
      </StyledCol>
      <StyledCol span={12}>
        <SkipButton data-cy="skipButton" onClick={onSkip}>
          Skip
        </SkipButton>
      </StyledCol>
    </Row>
  );
};

const StyledCol = styled(Col)`
  padding: 15px 15px 10px;
`;

const HalfWidthButton = styled(FullWidthButton)`
  width: calc(100% - 38px);
`;
const BackButton = styled(HalfWidthButton)`
  margin-left: 38px;
`;
const SkipButton = styled(HalfWidthButton)`
  margin-right: 38px;
`;

const CargoPickerWrapper = styled(PickerWrapper)`
  height: 42px;
`;
