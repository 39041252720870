/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CargoOfferUpdateRequestCargo,
  CargoOfferUpdateRequestCargoFromJSON,
  CargoOfferUpdateRequestCargoFromJSONTyped,
  CargoOfferUpdateRequestCargoToJSON,
} from './';

/**
 *
 * @export
 * @interface CargoOfferUpdateRequest
 */
export interface CargoOfferUpdateRequest {
  /**
   *
   * @type {CargoOfferUpdateRequestCargo}
   * @memberof CargoOfferUpdateRequest
   */
  cargo: CargoOfferUpdateRequestCargo;
}

export function CargoOfferUpdateRequestFromJSON(json: any): CargoOfferUpdateRequest {
  return CargoOfferUpdateRequestFromJSONTyped(json, false);
}

export function CargoOfferUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CargoOfferUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cargo: CargoOfferUpdateRequestCargoFromJSON(json['cargo']),
  };
}

export function CargoOfferUpdateRequestToJSON(value?: CargoOfferUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cargo: CargoOfferUpdateRequestCargoToJSON(value.cargo),
  };
}
