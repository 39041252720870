import arrayMove from 'array-move';
import sortBy from 'lodash/sortBy';
import React, {ComponentType, FC, ReactNode, useState} from 'react';
import {
  ConnectableElement,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceSpec,
  DropTarget,
  DropTargetMonitor,
  DropTargetSpec,
} from 'react-dnd';
import styled from 'styled-components';
import {Tabs} from '../../antd/components/Tabs';
import {assert} from '../../utils/assert';
import {EditNameModal} from './EditNameModal';
import {Tab, TabId} from './Tab';
import {TabContextMenuButton} from './TabContextMenuButton';
import {TabPaneProps, TabsProps} from 'antd';
import {TODO} from '../../utils/TODO';

type Key = string;

interface TabItem extends Omit<TabPaneProps, 'tab'> {
  key: string;
  label: React.ReactNode;
}

interface TabNodeProps {
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  children: ConnectableElement;
}

// Drag & Drop node
const TabNode = ({connectDragSource, connectDropTarget, children}: TabNodeProps) => {
  return connectDragSource(connectDropTarget(children));
};

interface DropTargetSpecProps {
  index: TODO;
  moveTabNode: (dragKey: Key, hoverKey: Key) => void;
}

const cardTarget: DropTargetSpec<DropTargetSpecProps> = {
  drop(props: DropTargetSpecProps, monitor: DropTargetMonitor) {
    const dragKey = monitor.getItem().index;
    const hoverKey = props.index;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    monitor.getItem().index = hoverKey;
  },
};

interface DragSourceSpecProps {
  id: TODO;
  index: TODO;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cardSource: DragSourceSpec<DragSourceSpecProps, any> = {
  beginDrag(props: DragSourceSpecProps) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WrapTabNode: any = DropTarget('DND_NODE', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource('DND_NODE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(TabNode)
);

interface DraggableTabsProps extends TabsProps {
  onSortEnd: SortEndHandler;
}

const DraggableTabs: FC<DraggableTabsProps> = props => {
  const {onSortEnd, ...restProps} = props;
  const [order, setOrder] = useState<Key[]>([]);

  const items = props.items || [];

  const moveTabNode = (dragKey: Key, hoverKey: Key) => {
    if (dragKey === 'all') {
      return;
    }
    const newOrder: Key[] = order.slice();

    items.forEach(item => {
      if (newOrder.indexOf(item.key) === -1) {
        newOrder.push(item.key);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);
    arrayMove.mutate(newOrder, dragIndex, hoverIndex);
    setOrder(newOrder);

    // Subtract 1 from both indices because we have the artifical tab for 'all'.
    onSortEnd({oldIndex: dragIndex - 1, newIndex: hoverIndex - 1});
  };

  // types not exported by antd
  const renderTabBar = (props: TODO, DefaultTabBar: ComponentType<TODO>) => (
    <DefaultTabBar {...props}>
      {(node: TODO) => (
        <WrapTabNode key={node.key} index={node.key} moveTabNode={moveTabNode}>
          {node}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  const sortedTabPanes = sortTabPanes(order, items);

  return <Tabs renderTabBar={renderTabBar} {...restProps} items={sortedTabPanes} />;
};

const sortTabPanes = (order: Key[], items: TabItem[]): TabItem[] => {
  const sortedTabPanes: TabItem[] = sortBy<TabItem>(items, a => {
    const indexInOrder = order.indexOf((a as TabItem).key as string);
    if (indexInOrder !== -1) {
      return indexInOrder;
    }

    // If we can't find our components by key, then we try to find them by identity.
    const indexInItems = items.indexOf(a);
    return indexInItems;
  });

  return sortedTabPanes;
};

export interface SortEnd {
  oldIndex: number;
  newIndex: number;
}

export type SortEndHandler = (sortEnd: SortEnd) => void;

export interface TabListProps<TAB extends Tab> {
  tabs: TAB[];
  allItemsLabel: string;
  activeTab: TabId;
  createTabEnabled: boolean;
  createModalTitle: string;
  createModalButtonLabel: string;
  createTabDefaultName: string;
  renameModalTitle: string;
  renameModalButtonLabel: string;
  tabBarExtraContent?: ReactNode;
  onSelectTab: (subTab: TAB | 'all') => void;
  onCreateTab: (name: string) => Promise<TAB>;
  onRenameTab: (newName: string, tabId: number) => Promise<void>;
  onDeleteTab: (tab: TAB) => void;
  onSortEnd: SortEndHandler;
  renderMainTabExtra?: () => JSX.Element | null;
  renderAdditionalTabExtra?: (tab: TAB) => JSX.Element | null;
}

export const TabList = <TAB extends Tab>(props: TabListProps<TAB>) => {
  const [renaming, setRenaming] = useState<Tab | null>(null);
  const [creating, setCreating] = useState(false);
  const {
    tabs,
    activeTab,
    createTabEnabled,
    createModalTitle,
    createModalButtonLabel,
    createTabDefaultName,
    renameModalTitle,
    renameModalButtonLabel,
    tabBarExtraContent,
    allItemsLabel,
    onSelectTab,
    onCreateTab,
    onRenameTab,
    onDeleteTab,
    onSortEnd,
    renderMainTabExtra,
    renderAdditionalTabExtra,
  } = props;

  const addTab = () => {
    setCreating(true);
  };

  const handleChange = (activeKey: Key) => {
    const tabId = tabKeyToId(activeKey);
    if (tabId === activeTab) {
      // Don't allow a click on the active tab to be treated as a change of tab.
      return;
    }

    if (tabId === 'all') {
      onSelectTab('all');
      return;
    }

    const tab = tabs.find(tab => tab.id === tabId);
    assert(!!tab, activeKey);
    onSelectTab(tab);
  };

  const getItems = () => {
    const items = [
      {
        key: 'all',
        label: (
          <TabLabel>
            <div>{allItemsLabel}</div>
            <TabLabelRight>{renderMainTabExtra?.()}</TabLabelRight>
          </TabLabel>
        ),
        closable: false,
      },
    ];
    const additionalTabs = tabs.map(tab => {
      return {
        key: `${tab.id}`,
        label: (
          <TabLabel data-cy="TabLabel" data-testid={`TabPane-${tab.id}`}>
            <div data-cy="TabLabelName" data-cy-name={tab.name}>
              {tab.name}
            </div>
            <TabLabelRight>
              {renderAdditionalTabExtra?.(tab)}
              {activeTab === tab.id && (
                <TabContextMenuButton
                  onRename={() => {
                    setRenaming(tab);
                  }}
                  onDelete={() => {
                    onDeleteTab(tab);
                  }}
                />
              )}
            </TabLabelRight>
          </TabLabel>
        ),
        closable: false,
      };
    });

    return [...items, ...additionalTabs];
  };

  return (
    <>
      <DraggableTabsStyled
        className="TabList"
        type="editable-card"
        activeKey={tabIdToKey(activeTab)}
        onSortEnd={onSortEnd}
        onEdit={() => {
          addTab();
        }}
        onChange={handleChange}
        tabBarGutter={4}
        tabBarExtraContent={tabBarExtraContent}
        hideAdd={!createTabEnabled}
        data-testid="TabList"
        items={getItems()}
      />
      {creating && (
        <EditNameModal
          title={createModalTitle}
          value={createTabDefaultName}
          buttonLabel={createModalButtonLabel}
          cursor="all"
          onOk={newName => {
            setCreating(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onCreateTab(newName);
          }}
          onCancel={() => {
            setCreating(false);
          }}
        />
      )}
      {renaming && (
        <EditNameModal
          title={renameModalTitle}
          value={renaming.name ?? ''}
          buttonLabel={renameModalButtonLabel}
          cursor="all"
          onOk={newName => {
            setRenaming(null);
            void onRenameTab(newName, renaming.id);
          }}
          onCancel={() => {
            setRenaming(null);
          }}
        />
      )}
    </>
  );
};

const tabIdToKey = (tabId: TabId): Key => String(tabId);
const tabKeyToId = (key: Key): TabId => (key === 'all' ? 'all' : parseInt(key, 10));

const TabLabel = styled.div`
  min-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DraggableTabsStyled: FC<DraggableTabsProps> = styled(DraggableTabs)`
  margin-top: 20px;
  height: 36px;
  overflow: hidden;
  max-width: calc(100% - var(--border-radius-card));

  .ant-tabs-tab {
    border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
  }
`;

export const TabListLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const TabLabelRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
  margin-right: -5px;
`;
