import {ZodSchema} from 'zod';
import {isLocalDevEnvironment, isTest} from '../../utils/environment';

const enabled = isLocalDevEnvironment() || isTest();

export const gentleZodValidation = <SchemaType>(schema: ZodSchema, data: unknown): SchemaType => {
  if (enabled) {
    return schema.parse(data);
  }

  return data as SchemaType;
};
