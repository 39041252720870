import {TODO} from '../../utils/TODO';
import {SWITCHES} from './mapOptions/switches';
import {LAYERS, MAPBOX_STYLES} from './const';
import {MapContextValue, RenderTradingAreaLayerProps} from './MapContext/Types';
import {useSelector} from '../../redux/react-redux';
import * as MapService from '../../redux/ApiService/MapService/MapService';
import {PortLayer as PortLayerJS, TradingAreaLayer} from './layers';
import {MapAreaElement, MapElement} from './MapDetails/utils/types';
import {PickingInfo} from '@deck.gl/core/typed';

const PortLayer = PortLayerJS as TODO;

interface MapTradingArea {
  id: number;
  name: string;
}

export const useRenderDynamicLayers = ({
  context,
  onSelectElement,
  onSelectTradingArea,
  showPortIds,
  searchElement,
  minimumZoomLevelToShowPorts,
  selectedTradingArea,
  ports,
}: {
  context: MapContextValue;
  selectedTradingArea: MapTradingArea | null;
  onSelectTradingArea: (element: MapAreaElement) => void;
  showPortIds: number[] | undefined;
  searchElement: TODO;
  minimumZoomLevelToShowPorts?: number;
  onSelectElement: (element: MapElement) => void;
  ports: TODO[];
}) => {
  const smallPorts = useSelector(store => MapService.smallPorts(store.api.map));
  const largePorts = useSelector(store => MapService.largePorts(store.api.map));
  const tradingAreasGeoJson = useSelector(store => MapService.tradingAreasGeoJson(store.api.map));

  const {viewState, settings, switches} = context.state;
  // const {searchElement, selectedTradingArea} = state;

  const layers: TODO[] = [];

  const tradingArea = switches[SWITCHES.LAYER_TRADING_AREA]?.state;

  if (tradingArea) {
    const fontColorOnLayer = MAPBOX_STYLES[settings.mapStyle].fontColorOnLayer;

    const taProps: RenderTradingAreaLayerProps = {
      id: LAYERS.TRADING_AREA,
      key: LAYERS.TRADING_AREA,
      onClick: onSelectTradingArea as (pickingInfo: PickingInfo) => void,
      selected: selectedTradingArea?.id ?? null,
      showLabels: switches[SWITCHES.TRADING_AREA_NAMES]!.state,
      data: tradingAreasGeoJson,
      fontColorOnLayer,
      switches,
    };

    layers.push(new TradingAreaLayer({...taProps, data: tradingAreasGeoJson}));
  }

  // you can put in AIS vessels from the outside,
  // this allows you specific filtering, but cancels out other internal filtering.

  if (searchElement && searchElement.type === 'port') {
    showPortIds ??= [];
    showPortIds.push(searchElement.id);
  }

  layers.push(
    new PortLayer({
      id: LAYERS.PORT,
      key: LAYERS.PORT,
      ports,
      smallPorts,
      largePorts,
      showPortIds,
      switches,
      zoom: viewState.zoom,
      minimumZoomLevel: minimumZoomLevelToShowPorts,
      onClick: onSelectElement,
      pickable: true,
    })
  );

  return layers;
};
