import {numberFormat} from '../../../../utils/formatter';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';

export interface VesselWithTeuQuantityAndTeu14Quantity {
  teuQuantity: number | null;
  teu14Quantity: number | null;
}

export const aisVesselTeuColumn: CustomColumnDef<VesselWithTeuQuantityAndTeu14Quantity> = {
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        TEU
        <br />
        TEU14
      </div>
    </div>
  ),
  id: 'aisTeu',
  accessorKey: 'teuQuantity',
  accessorFn: (row: $TSFixMe) => (
    <div data-sort={row.teuQuantity}>
      <div>{numberFormat(row.teuQuantity, {emptyString: '-'})}</div>
      <div>{numberFormat(row.teu14Quantity, {emptyString: '-'})}</div>
    </div>
  ),
  cell: info => info.getValue(),
  minWidth: 2,
  sortingFn: (a, b) => {
    const aTeu = a.original.teuQuantity ?? a.original.teu14Quantity ?? 0;
    const bTeu = b.original.teuQuantity ?? b.original.teu14Quantity ?? 0;
    return aTeu - bTeu;
  },
  enableSorting: true,
};
