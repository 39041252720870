import React, {FC} from 'react';
import {numberFormat} from '../../../../../utils/formatter';
import Vessel from '../../../../../model/Vessel';
import DateTime from '../../../../../model/DateTime';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import {FormInstance} from 'antd';
import {FormValues} from '../FormValues';
import Station from '../../../../../model/Station';
import {useGetVesselQuery} from '../../../../../queries/useGetVesselQuery';
import {VesselDetailsVessel} from '../../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {fixtureRateTermTypes} from '../utils';

const Rate = ({values}: {values: FormValues}) => {
  const rate = values.options?.[0]?.rate;
  const rateCurrency = values.options?.[0]?.rateCurrency;
  const rateTerms = values.options?.[0]?.rateTerms;
  return rateCurrency && rate ? (
    <div>
      <span>
        {numberFormat(rate)} <span style={{textTransform: 'uppercase'}}>{rateCurrency}</span>{' '}
        {fixtureRateTermTypes[rateTerms]}
      </span>
    </div>
  ) : (
    <>N/A</>
  );
};

const DatesOpen: FC<{
  vessel: VesselDetailsVessel | null | undefined;
}> = ({vessel}) => {
  if (!vessel) {
    return <>N/A</>;
  }
  if (vessel.nextOpenFrom || vessel.nextOpenTo) {
    return <>{Vessel.formatDateOpen({nextOpenFrom: vessel.nextOpenFrom, nextOpenTo: vessel.nextOpenTo})}</>;
  }
  if (vessel.spotState) {
    return (
      <>{'Spot' + (vessel.spotDate && ' (' + DateTime.fromServerResponse(vessel.spotDate).getFormattedDate() + ')')}</>
    );
  }
  return <>N/A</>;
};

export const CurrentCommercialDetails = ({form}: {form: FormInstance<FormValues>}) => {
  const formData = form?.getFieldsValue();

  const vesselQuery = useGetVesselQuery({
    vesselId: formData?.vessel?.id,
    options: {
      enabled: !!formData?.vessel?.id,
    },
  });
  const vessel: VesselDetailsVessel | null | undefined = vesselQuery.data;

  if (!formData) {
    return null;
  }

  return (
    <div className={'container-fluid form-current-commercial-details'} data-cy="CurrentCommercialDetails">
      <div className="row">
        <div className={'scol-12 scol-sm-12'} style={{marginBottom: '15px'}}>
          Current commercial details
        </div>
      </div>
      <div className="row">
        <div className="scol-12 scol-sm-6">
          <LabelWrapper label={'Port open'} htmlFor={`form-current-commercial-details__port-open`}>
            <div className={'form-current-commercial-details__port-open'} style={{fontSize: '13px'}}>
              {vessel?.stations
                ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"nextopen"' is not assignable to... Remove this comment to see the full error message
                  Station.getLocationByStationType(vessel.stations, 'nextopen', {
                    short: false,
                    emptyState: 'N/A',
                  })
                : 'N/A'}
            </div>
          </LabelWrapper>
        </div>
        <div className="scol-12 scol-sm-6">
          <LabelWrapper label={'Charterer'} htmlFor={`form-current-commercial-details__charterer`}>
            <div className={'form-current-commercial-details__charterer'} style={{fontSize: '13px'}}>
              {vessel?.managementDisponentOwner ?? 'N/A'}
            </div>
          </LabelWrapper>
        </div>
      </div>
      <div className="row">
        <div className="scol-12 scol-sm-6">
          <LabelWrapper label={'Date open'} htmlFor={`form-current-commercial-details__date-open`}>
            <div className={'form-current-commercial-details__date-open'} style={{fontSize: '13px'}}>
              <DatesOpen vessel={vessel} />
            </div>
          </LabelWrapper>
        </div>
        <div className="scol-12 scol-sm-6">
          <LabelWrapper label={'Terms'} htmlFor={`form-current-commercial-details__terms`}>
            <div className={'form-current-commercial-details__terms'} style={{fontSize: '13px'}}>
              <Rate values={formData} />
            </div>
          </LabelWrapper>
        </div>
      </div>
    </div>
  );
};
