import {CargoContractType} from '../../../../atoms/CargoHexagon/CargoHexagon';

export type FreightUnit = 'perDay' | 'perMts' | 'lumpsum';

export type StowageFactorDisplayUnit = 'cft' | 'cbm';

export const DefaultStowageFactorDisplayUnit: StowageFactorDisplayUnit = 'cbm';

export interface CargoInput {
  cargoProduct?: string;
  /**
   * Stowage factor always in cbm/mt
   */
  stowageFactor?: number;
  stowageFactorDisplayUnit: StowageFactorDisplayUnit;
  cargoQuantity?: number;
  totalCommission?: number;
  contractType?: CargoContractType;
  freightIdea?: number;
  freightUnit: FreightUnit;
  chartererName?: string;
  id?: number;
}

export interface CargoOutput {
  revenueNet: number;
  duration: number;
  tce: number;
  revenueTotal: number;
}

export interface CargoInformationProps {
  open?: boolean;
}
