import {useContext} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';

export const useCalcName = (): string => {
  const voyageContext = useContext(VoyageCharterContext);
  const {inputState} = voyageContext.state;
  const fromLeg = inputState.voyage.points[0] || {};
  const toLeg = inputState.voyage.points[inputState.voyage.points.length - 1] || {};

  return (
    inputState.calculator.name ||
    `${inputState.vessel.name || '[vessel]'} | ${fromLeg.name || '[start]'} -> ${toLeg.name || '[destination]'} `
  );
};
