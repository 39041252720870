import {Dayjs} from 'dayjs';
import {isEtaOlderThan30Days} from '../../../isEtaOlderThan30Days';
import {Step5Vessel} from '../step5';
import {
  Step7Vessel,
  vesselWithEstimatedDestination,
} from './vesselWithEstimatedDestination/vesselWithEstimatedDestination';

export const extendVesselToStep7Vessel = async ({
  vessel,
  currentTime,
}: {
  vessel: Step5Vessel;
  currentTime: Dayjs;
}): Promise<Step7Vessel> => {
  if (isEtaOlderThan30Days({vessel, currentTime})) {
    return {...vessel, etaIsEstimated: false};
  } else {
    return vesselWithEstimatedDestination({vessel, currentTime});
  }
  return {...vessel, etaIsEstimated: false};
};
