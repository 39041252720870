import {Button, Col, Row, Typography} from 'antd';
import {OnboardingStepProps} from './useUserOnboarding';
import styled from 'styled-components';
import {ReactNode} from 'react';
import {SupportCta} from '../../components/SupportAgentsBar/SupportAgentsBar';

const {Title, Paragraph} = Typography;

export const OnboardingStepWrapper = ({
  title,
  subTitle,
  previousEnabled,
  nextEnabled,
  hasNext,
  onPrevious,
  onNext,
  dataTestId,
  children,
}: OnboardingStepProps & {title?: string; subTitle?: string; dataTestId: string; children: ReactNode}) => {
  return (
    <div data-testid={`OnboardingStep-${dataTestId}`}>
      {(title || subTitle) && (
        <IntroContainer>
          {title && <Title level={3}>{title}</Title>}
          {subTitle && <Paragraph>{subTitle}</Paragraph>}
        </IntroContainer>
      )}
      <StepWrapper>{children}</StepWrapper>
      <StepNavigation>
        <Row align="middle" justify="center" gutter={[16, 16]}>
          <Col>
            <Button
              data-testid="OnboardingBackButton"
              type="default"
              size="large"
              disabled={!previousEnabled}
              onClick={onPrevious}>
              Back
            </Button>
          </Col>
          <Col>
            <Button
              data-testid="OnboardingNextButton"
              type="primary"
              size="large"
              disabled={!nextEnabled}
              onClick={onNext}>
              {!hasNext ? 'Finish' : 'Next'}
            </Button>
          </Col>
        </Row>
      </StepNavigation>
      <SupportContainer>
        <SupportCta />
      </SupportContainer>
    </div>
  );
};

const IntroContainer = styled.div`
  margin-bottom: 40px;
  text-align: center;
`;

const StepWrapper = styled.div`
  min-height: calc(100vh - 340px);
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
`;

const StepNavigation = styled.div`
  position: sticky;
  bottom: 0;
  width: calc(100% + 80px);
  padding: 20px;
  margin: 40px -40px -40px;
  background: var(--color-white);
  text-align: center;
  border-bottom: var(--border-base);
  box-shadow: 0 -15px 20px 0 rgba(0, 0, 0, 0.1);
`;

const SupportContainer = styled.div`
  width: calc(100% + 80px);
  margin: 0 -40px -40px;
  padding: 80px 0 30px;
  background: var(--color-white);
  border-bottom: var(--border-base);
`;
