import {connect} from 'react-redux';
import {actions as contribApi} from '../../../redux/ApiService/contribService';
import Title from '../../../atoms/Title';
import {AsyncSelect} from '../../../atoms/Select/AsyncSelect';
import DetailsSection from '../../../atoms/DetailsSection';
import {Alert, Button} from 'antd';
import {Link} from 'react-router-dom';
import {useGetPortfolioVesselsQuery} from '../../MyFleetScreens/MyFleetScreen/useGetPortfolioVesselsQuery/useGetPortfolioVesselsQuery';

const VesselSearch = ({
  onSelect,
  imoSearch,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  imoValue,
  ...rest
}: $TSFixMe) => {
  const imo = rest.form.general?.imo;

  const vesselExitsQuery = useGetPortfolioVesselsQuery(
    {
      filters: {
        generalInformation: {
          imo: imo,
        },
      },
      pageSize: 1,
      pageNumber: 1,
      sortOrder: 'asc',
    },
    {enabled: !!imo}
  );

  const alreadyExistingVessel = vesselExitsQuery.data?.data?.items?.[0];
  return (
    <DetailsSection center {...rest}>
      <div className="container-fluid">
        <div className="row">
          <div className="scol-12">
            <Title center large style={{marginBottom: '20px'}}>
              Enter vessel name or IMO
            </Title>
          </div>
        </div>
        <div className="row">
          <div className="scol-12">
            <div style={{margin: '0 auto', maxWidth: '472px'}}>
              <AsyncSelect
                id={'filter-general-IMO'}
                name={'filter-general-IMO'}
                value={''}
                onChange={onSelect}
                getOptionLabel={(option: $TSFixMe) => (
                  <span>
                    {option.name}{' '}
                    <span style={{color: 'var(--color-gray-2)'}}>
                      ({option.imo} {option.vesselType && <span>, {option.vesselType.toUpperCase()}</span>})
                    </span>
                  </span>
                )}
                getOptionValue={(option: $TSFixMe) => option.imo}
                loadOptions={(q: $TSFixMe) => (q.length > 2 ? imoSearch(q) : Promise.resolve([]))}
                isClearable
                placeholder={'Type to search...'}
                loadingMessage={() => 'Searching ...'}
              />
              {alreadyExistingVessel && (
                <Alert
                  style={{
                    marginTop: 16,
                    padding: 8,
                  }}
                  message={`A vessel with the same IMO already exists in My Fleet.`}
                  action={
                    <Link to={`/vessel/${alreadyExistingVessel.id}`}>
                      <Button style={{padding: 0, paddingLeft: 8}} type="link">
                        Go to vessel
                      </Button>
                    </Link>
                  }
                  type="warning"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </DetailsSection>
  );
};

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  imoSearch: (query: $TSFixMe) => dispatch(contribApi.imo({params: {query}})),
});

export default connect(null, mapDispatchToProps)(VesselSearch);
