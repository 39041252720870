/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CompanyMemberResponseData,
  CompanyMemberResponseDataFromJSON,
  CompanyMemberResponseDataFromJSONTyped,
  CompanyMemberResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyMemberResponse
 */
export interface CompanyMemberResponse {
  /**
   *
   * @type {CompanyMemberResponseData}
   * @memberof CompanyMemberResponse
   */
  data: CompanyMemberResponseData;
}

export function CompanyMemberResponseFromJSON(json: any): CompanyMemberResponse {
  return CompanyMemberResponseFromJSONTyped(json, false);
}

export function CompanyMemberResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyMemberResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: CompanyMemberResponseDataFromJSON(json['data']),
  };
}

export function CompanyMemberResponseToJSON(value?: CompanyMemberResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: CompanyMemberResponseDataToJSON(value.data),
  };
}
