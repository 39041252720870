import {useContext, useEffect} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {useFocusMapOnCoordinates} from './useFocusMapOnCoordinates';

/*
The SeaboMap gets coordinatesToFocus : Array<[number,number]> (Array of Coordinates)

This hook, looks if the props.coordinatesToFocus or the mapReady State changes,
if it's the case => set the viewState (boundingbox) fitting all coordinates with padding of 50
 */
export const useInitalFocusOnCoordinatesReceivedByMapProps = () => {
  const mapContext = useContext(MapContext);
  const focusMapOnCoordinates = useFocusMapOnCoordinates();

  useEffect(() => {
    if (!mapContext.state.mapReady) {
      return;
    }
    if (mapContext.props.coordinatesToFocus !== undefined) {
      focusMapOnCoordinates({
        coordinates: mapContext.props.coordinatesToFocus,
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [mapContext.props.coordinatesToFocus, mapContext.state.mapReady]);
};
