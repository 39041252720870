/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  MailInfo,
  MailInfoFromJSON,
  MailInfoFromJSONTyped,
  MailInfoToJSON,
  ParsedCargoData,
  ParsedCargoDataFromJSON,
  ParsedCargoDataFromJSONTyped,
  ParsedCargoDataToJSON,
  RawCargoData,
  RawCargoDataFromJSON,
  RawCargoDataFromJSONTyped,
  RawCargoDataToJSON,
} from './';

/**
 *
 * @export
 * @interface JSONAllCargoData
 */
export interface JSONAllCargoData {
  /**
   *
   * @type {ParsedCargoData}
   * @memberof JSONAllCargoData
   */
  parsedCargoData?: ParsedCargoData;
  /**
   *
   * @type {RawCargoData}
   * @memberof JSONAllCargoData
   */
  rawCargoData?: RawCargoData;
  /**
   *
   * @type {string}
   * @memberof JSONAllCargoData
   */
  outputMask?: string;
  /**
   *
   * @type {string}
   * @memberof JSONAllCargoData
   */
  essence?: string;
  /**
   *
   * @type {number}
   * @memberof JSONAllCargoData
   */
  seconds?: number;
  /**
   *
   * @type {MailInfo}
   * @memberof JSONAllCargoData
   */
  mailInfo?: MailInfo;
}

export function JSONAllCargoDataFromJSON(json: any): JSONAllCargoData {
  return JSONAllCargoDataFromJSONTyped(json, false);
}

export function JSONAllCargoDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JSONAllCargoData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    parsedCargoData: !exists(json, 'parsedCargoData') ? undefined : ParsedCargoDataFromJSON(json['parsedCargoData']),
    rawCargoData: !exists(json, 'rawCargoData') ? undefined : RawCargoDataFromJSON(json['rawCargoData']),
    outputMask: !exists(json, 'outputMask') ? undefined : json['outputMask'],
    essence: !exists(json, 'essence') ? undefined : json['essence'],
    seconds: !exists(json, 'seconds') ? undefined : json['seconds'],
    mailInfo: !exists(json, 'mailInfo') ? undefined : MailInfoFromJSON(json['mailInfo']),
  };
}

export function JSONAllCargoDataToJSON(value?: JSONAllCargoData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parsedCargoData: ParsedCargoDataToJSON(value.parsedCargoData),
    rawCargoData: RawCargoDataToJSON(value.rawCargoData),
    outputMask: value.outputMask,
    essence: value.essence,
    seconds: value.seconds,
    mailInfo: MailInfoToJSON(value.mailInfo),
  };
}
