import {SeaboVessel, VesselTypeEnum} from '../../../../api/node-backend/generated';
import {CloneableVessel} from '../../../../components/Modal/ClonePortfolioEntry/useCloneVesselToMyFleetMutation';
import {Consumption, Intake, IntakeType} from '../../../../redux/Portfolio';
import {assert} from '../../../../utils/assert';
import {getDesignSubType} from './getDesignSubType';

export const makeCloneableVessel = (vessel: SeaboVessel): CloneableVessel => {
  const intakes: Intake[] =
    vessel.intakes?.map(intake => {
      assert(intake.quantity, 'Missing quantity in intake.');
      return {
        type: intake.type as IntakeType,
        quantity: intake.quantity,
        isHighCube: intake.isHighCube ?? false,
      };
    }) ?? [];

  const consumptions = vessel.consumptions?.map(
    (consumption): Consumption => ({
      fuelConsumption: consumption.fuelConsumption,
      type: consumption.type,
      speed: consumption.speed,
      fuelType: consumption.fuelType ?? 'ifo',
    })
  );

  const clonableVessel: CloneableVessel = {
    ...vessel,
    intakes,
    consumptions,
    vesselType: vessel.vesselType as VesselTypeEnum,
    status: 'active',
    portfolioGroups: [],
    designSubType: getDesignSubType(vessel),
    redeliveryNotice: vessel.redeliveryNotice,
  };
  return clonableVessel;
};
