import classNames from 'classnames';
import React, {FC} from 'react';
import Title from '../Title';
import CSS from 'csstype';

import './loading-block.scss';

const LoadingAnimation: FC<{
  width?: number | string;
  height?: number | string;
  color?: string;
  margin?: string;
  className?: string;
  'data-testid'?: string;
}> = ({width = 200, height = 150, color = '#aaa', margin, className, 'data-testid': dataTestId}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Ebene_1"
    viewBox="0 0 64 48"
    width={width}
    height={height}
    style={margin ? {margin: margin} : {}}
    className={className}
    data-testid={dataTestId}>
    <path
      id="shape1"
      d="M43.8 32.4l-7.7-4.3a8.4 8.4 0 0 0-8.4 0L20 32.4l-2.5 1.5 2.6 1.4 5.3 3 2.4 1.4a8.4 8.4 0 0 0 8.4 0l10.3-5.8-2.7-1.5z"
      fill={color}>
      <animateTransform
        attributeName="transform"
        begin="0s"
        dur="3s"
        repeatCount="indefinite"
        type="translate"
        values="19.2126, 20.3208; 0,0; 12.8084, 13.5472; 0,0; 19.2126, 20.3208;"
      />
      <animateTransform
        additive="sum"
        attributeName="transform"
        begin="0s"
        dur="3s"
        repeatCount="indefinite"
        type="scale"
        values="0.4; 1; 0.6; 1; 0.4"
      />
    </path>
    <path
      id="shape3"
      d="M27.8 19.9a8.4 8.4 0 0 0 8.4 0l10.3-5.8-10.3-5.7a8.4 8.4 0 0 0-8.4 0l-10.3 5.8 10.3 5.7z"
      fill={color}>
      <animateTransform
        attributeName="transform"
        begin="0s"
        dur="4s"
        repeatCount="indefinite"
        type="translate"
        values="6.4,5.8; 0,0; 19.191,12.355; 0,0; 6.4, 5.8;"
      />
      <animateTransform
        additive="sum"
        attributeName="transform"
        begin="0s"
        dur="4s"
        repeatCount="indefinite"
        type="scale"
        values="0.8; 1; 0.4; 1; 0.8"
      />
    </path>
    <path
      id="shape4"
      d="M10.3 29.8a8.4 8.4 0 0 0 8.4 0l.2-.1 10-5.7-10.3-5.8a8.4 8.4 0 0 0-8.4 0h-.1L0 24l10.3 5.8z"
      fill={color}>
      <animateTransform
        attributeName="transform"
        begin="0s"
        dur="2s"
        repeatCount="indefinite"
        type="translate"
        values="11.56, 19.2048; 0, 0; 2.89, 4.8012; 0,0; 11.56, 19.2048;"
      />
      <animateTransform
        additive="sum"
        attributeName="transform"
        begin="0s"
        dur="2s"
        repeatCount="indefinite"
        type="scale"
        values="0.2; 1; 0.8; 1; 0.2"
      />
    </path>
    <path
      id="shape2"
      d="M61.4 22.6l-7.7-4.3a8.4 8.4 0 0 0-8.4 0h-.1L35.1 24l10.3 5.8a8.4 8.4 0 0 0 8.4 0l.2-.1L64 24l-2.6-1.4z"
      fill={color}>
      <animateTransform
        attributeName="transform"
        begin="0s"
        dur="4s"
        repeatCount="indefinite"
        type="translate"
        values="19.8176, 9.604; 0,0; 29.7264, 14.406; 0,0; 19.8176, 9.604;"
      />
      <animateTransform
        additive="sum"
        attributeName="transform"
        begin="0s"
        dur="4s"
        repeatCount="indefinite"
        type="scale"
        values="0.6; 1; 0.4; 1; 0.6"
      />
    </path>
  </svg>
);

export const LoadingText: FC<{
  text?: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  style?: CSS.Properties;
}> = ({text = 'Loading', small, medium, large, ...rest}) => (
  <Title small={small} medium={medium} large={large} {...rest}>
    <span className="seabo-loading-text">
      {text}
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  </Title>
);

export const LoadingIndicator: FC<{
  text?: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  width?: string;
  color?: string;
  className?: string;
}> = ({text, small, medium, large, width, color, className}) => (
  <div className={classNames('seabo-loading-indicator', className)}>
    <LoadingAnimation width={width} color={color} />
    {text && (
      <Title small={small} medium={medium} large={large} center>
        {text}
      </Title>
    )}
  </div>
);

export default LoadingAnimation;
