import {FC} from 'react';
import {NegotiationRoundResult, NegotiationStatus, NegotiationSummary} from '../../../../../api/node-backend/generated';
import {CommitAreaActionArea} from './CommitAreaActionArea';
import styled from 'styled-components';
import {CommitAreaHeadline} from './CommitAreaHeadline';
import {NegotiationActions} from './utils/useGetNegotiationActionsWithMutations';
import {getDerivedNegotiationState} from '../../utils/getDerivedNegotiationState';
import {NegotiationCard} from '../../../Components/shared';

type NegotiationCommitAreaProps = {
  negotiation: NegotiationSummary;
  currentRoundResult?: NegotiationRoundResult;
  actionsBusy?: boolean;
  negotiationActions: NegotiationActions;
  isFirstRecapRound?: boolean;
};

export const NegotiationCommitArea: FC<NegotiationCommitAreaProps> = ({
  negotiation,
  currentRoundResult,
  actionsBusy,
  negotiationActions,
  isFirstRecapRound,
}) => {
  const {status} = negotiation;
  const negotiationState = getDerivedNegotiationState(negotiation);
  const {roundIsEditable, hasOngoingTerms, hasOngoingSubjects, hasUnsettledSubjects, canOnlyCancel} = negotiationState;

  return (
    <CommitAreaContainer data-testid="NegotiationCommitArea">
      <CommitAreaHeadline
        negotiationStatus={status}
        isOwnTurn={roundIsEditable}
        allTermsSettled={!hasOngoingTerms}
        allSubjectsAccepted={!hasOngoingSubjects}
        allSubjectsSettled={!hasUnsettledSubjects}
        isFirstRecapRound={isFirstRecapRound}
        canOnlyCancel={canOnlyCancel}
        isTutorial={!!negotiationActions.exitTutorial}
      />
      <CommitAreaActionArea
        negotiation={negotiation}
        negotiationState={negotiationState}
        currentRoundResult={currentRoundResult}
        isRecap={status === NegotiationStatus.Recap}
        actionsBusy={actionsBusy}
        negotiationActions={negotiationActions}
      />
    </CommitAreaContainer>
  );
};

const CommitAreaContainer = styled(NegotiationCard)`
  width: var(--main-width);
  margin: 0 auto 10px;
  text-align: center;
  overflow: initial;

  .ant-card-body {
    padding: 20px;
  }
`;
