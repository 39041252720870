import {FC} from 'react';
import {Select} from '../../../../../../../antd/components/Select';
import {fixtureDurationUnits} from '../../../utils';

export const DurationUnitPicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  return (
    <Select
      allowClear={false}
      value={value}
      onChange={onChange}
      options={Object.entries(fixtureDurationUnits).map(([value, label]) => ({
        value,
        label,
      }))}
    />
  );
};
