import {Form, Select} from 'antd';
import {FC} from 'react';
import {aisInitialFilters} from '../../../mapOptions/aisInitialFilters';
import {AisFormItem, OnChangeSingleFormValue} from '../AisFormItem';
import {MinMaxRangeFilter} from './MinMaxRangeFilter';

export const IntakeFormItem: FC<{
  onSetValuesViaApi: OnChangeSingleFormValue;
  intakeUnit: 'teu' | 'dwt';
  disabled: boolean;
}> = ({onSetValuesViaApi, intakeUnit, disabled}) => {
  const step = intakeUnit === 'teu' ? 100 : 1000;
  const max = intakeUnit === 'teu' ? 30000 : 500000;

  return (
    <>
      <Form.Item name={'intakeUnit'} style={{position: 'absolute', right: 70, zIndex: 600}}>
        <Select
          disabled={disabled}
          size={'small'}
          style={{
            width: 70,
            fontSize: 11,
          }}
          onChange={() => {
            onSetValuesViaApi('intake', aisInitialFilters.intake);
          }}
          options={[
            {label: 'TEU', value: 'teu'},
            {label: 'DWT', value: 'dwt'},
          ]}
          getPopupContainer={() => {
            return document.getElementById('intake-select')!;
          }}
        />
      </Form.Item>
      <div id="intake-select" />
      <AisFormItem
        name={'intake'}
        defaultValue={aisInitialFilters.intake}
        label={'Intake'}
        labelCol={{span: 24}}
        onChangeSingleFormValue={onSetValuesViaApi}>
        <MinMaxRangeFilter disabled={disabled} step={step} max={max} min={0} />
      </AisFormItem>
    </>
  );
};
