import Vessel from '../../model/Vessel';

import './dateOpen.scss';

type Props = {
  label?: string | null;
  from?: string | null;
  to?: string | null;
  spotState?: boolean | null;
  spotDate?: Date | string | null;
};

const DateOpen = ({label, from, to, spotState, spotDate}: Props) => {
  return (
    <div className="date-open-detail">
      <div className="date-open-detail__label">{label}</div>
      <div className="date-open-detail__value">
        {from || to || spotState
          ? Vessel.formatDateOpen({nextOpenFrom: from, nextOpenTo: to, spotState, spotDate})
          : 'N/A'}
      </div>
    </div>
  );
};

export default DateOpen;
