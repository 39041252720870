/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  BulkerPerClass,
  BulkerPerClassFromJSON,
  BulkerPerClassFromJSONTyped,
  BulkerPerClassToJSON,
  CumulatedTiedUpBulkerVessels,
  CumulatedTiedUpBulkerVesselsFromJSON,
  CumulatedTiedUpBulkerVesselsFromJSONTyped,
  CumulatedTiedUpBulkerVesselsToJSON,
  CumulatedTiedUpContainerVessels,
  CumulatedTiedUpContainerVesselsFromJSON,
  CumulatedTiedUpContainerVesselsFromJSONTyped,
  CumulatedTiedUpContainerVesselsToJSON,
} from './';

/**
 *
 * @export
 * @interface OneDayStatistics
 */
export interface OneDayStatistics {
  /**
   * a timestamp in iso format (ISO-8601)
   * @type {string}
   * @memberof OneDayStatistics
   */
  date: string;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof OneDayStatistics
   */
  cumulatedTiedUpBulkerVessels: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpContainerVessels}
   * @memberof OneDayStatistics
   */
  cumulatedTiedUpContainerVessels: CumulatedTiedUpContainerVessels;
  /**
   *
   * @type {BulkerPerClass}
   * @memberof OneDayStatistics
   */
  cumulatedTiedUpBulkersPerClass: BulkerPerClass;
}

export function OneDayStatisticsFromJSON(json: any): OneDayStatistics {
  return OneDayStatisticsFromJSONTyped(json, false);
}

export function OneDayStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OneDayStatistics {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: json['date'],
    cumulatedTiedUpBulkerVessels: CumulatedTiedUpBulkerVesselsFromJSON(json['cumulatedTiedUpBulkerVessels']),
    cumulatedTiedUpContainerVessels: CumulatedTiedUpContainerVesselsFromJSON(json['cumulatedTiedUpContainerVessels']),
    cumulatedTiedUpBulkersPerClass: BulkerPerClassFromJSON(json['cumulatedTiedUpBulkersPerClass']),
  };
}

export function OneDayStatisticsToJSON(value?: OneDayStatistics | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    cumulatedTiedUpBulkerVessels: CumulatedTiedUpBulkerVesselsToJSON(value.cumulatedTiedUpBulkerVessels),
    cumulatedTiedUpContainerVessels: CumulatedTiedUpContainerVesselsToJSON(value.cumulatedTiedUpContainerVessels),
    cumulatedTiedUpBulkersPerClass: BulkerPerClassToJSON(value.cumulatedTiedUpBulkersPerClass),
  };
}
