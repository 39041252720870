import React, {FC} from 'react';
import {ExportReportsModal} from '../../../../components/ExportReportsModal/ExportReportsModal';
import {Typography} from 'antd';
import {useSelector} from '../../../../redux/react-redux';
import {getPortfolioVesselReports} from './getPortfolioVesselReports';
import {Report} from '../../../../components/ExportReportsModal/Report';
import {RootState} from '../../../../redux/store';
import {createSelector} from 'reselect';

const {Paragraph} = Typography;

export const ExportVesselsModal: FC<{
  visible: boolean;
  scope: 'filters' | 'selection';
  onClose: () => void;
}> = ({visible, scope, onClose}) => {
  const group = useSelector(state => state.portfolio.screenState.activeTabVessel);
  const vesselsTotalInDataGrid = useSelector(state => state.portfolio.screenState.vesselsTotal);
  const vesselFilters = useSelector(state => state.portfolioFilters.vesselFilterSettings);
  const selectedVessels = useSelector(selectedVesselsSelector);
  const vesselsTotal = scope === 'selection' ? selectedVessels.length : vesselsTotalInDataGrid;

  return (
    <ExportReportsModal
      visible={visible}
      title="Export Excel file"
      description={
        <Paragraph>
          You are about to download the current view with <b>{vesselsTotal}</b> vessels as an Excel file.
          <br />
          You can use the filters on the <a onClick={onClose}>My Fleet</a> page to narrow down that list.
        </Paragraph>
      }
      availableReports={AvailableReports}
      buttonLabel="Export Excel file"
      loadReportsFromServer={(reportList: string[]) => {
        const vesselIdFilter = scope === 'selection' ? selectedVessels : null;
        return getPortfolioVesselReports({
          group,
          vesselFilters,
          reportList: reportList,
          vesselIdFilter,
        });
      }}
      onClose={onClose}
    />
  );
};

const AvailableReports: Report[] = [
  {
    label: 'Full report',
    id: 'tonnage-list-full',
    filename: 'Tonnage-list-full.xlsx',
  },
  {
    label: 'Outlook report',
    id: 'tonnage-list-draft-outlook',
    filename: 'Tonnage-list-draft-outlook.xlsx',
  },
  {
    label: 'Competition report',
    id: 'tonnage-list-draft-competition',
    filename: 'Tonnage-list-draft-competition.xlsx',
  },
];

const gridclipboardPortfolioVesselsSelector = (state: RootState) => state.gridClipboard.portfolio.vessels;
const selectedVesselsSelector = createSelector([gridclipboardPortfolioVesselsSelector], vessels =>
  vessels.map(vessel => vessel.id)
);
