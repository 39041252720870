import {StyledFlag} from '../StyledFlag';
import {PortOption} from '../Type';
import {SearchHit} from '../../../api/symfony/generated/models/SearchHit';
import {SearchPickerPort} from '../SearchPickerPort';

export const makePortOption = (searchHit: SearchHit): PortOption => {
  const source = searchHit.source as SearchPickerPort;
  return {
    id: source.id,
    key: source.type + '_' + source.id,
    type: source.type,
    source: source,
    value: `${source.name} ${source.countryObject?.code ? `(${source.countryObject.code.toUpperCase()})` : ''}`,
    label: (
      <div
        data-testid="portPickerOption"
        data-cy="portPickerOption"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <span>
          <StyledFlag countryCode={source.countryObject?.code} shadow width={13} height={10} /> {source.name}{' '}
          {source.countryObject?.code ? `(${source.countryObject.code.toUpperCase()})` : ''}
        </span>
      </div>
    ),
  };
};
