import {CompanyMember} from '../../../queries/useCompanyMembersQuery';
import uniq from 'lodash/uniq';
import CompanyUser from '../../../model/company/CompanyUser';
import User from '../../../model/User';

export type Workspace = {
  id: number;
  name: string;
  users: CompanyUser[];
};

export const validateRemoveUser = ({
  user,
  members,
  currentUser,
  workspace,
}: {
  user: CompanyUser;
  members: CompanyUser[];
  workspace: Workspace;
  currentUser: User;
}): string | null => {
  // You try to delete yourself from your current workspace
  const isCurrentUser = user.id === currentUser.id;
  const isCurrentWorkspace = workspace.id === currentUser.workspace?.id;
  const currentUserTriesToDeleteHimselfFromHisCurrentWorkspace = isCurrentUser && isCurrentWorkspace;

  if (currentUserTriesToDeleteHimselfFromHisCurrentWorkspace) {
    return "You can't remove yourself from your current workspace";
  }

  // Tries to delete the last workspace of a user
  const companyUser = members.find(companyUser => companyUser.id === user.id) as unknown as CompanyMember | undefined;

  const usersWorkspaceIds = uniq([workspace.id, ...(companyUser?.workspaces?.map(workspace => workspace.id) ?? [])]);
  const userHasOnlyOneWorkspace = usersWorkspaceIds.length === 1;

  if (userHasOnlyOneWorkspace) {
    return "You can't remove the last workspace from a user";
  }

  return null;
};
