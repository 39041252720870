import React, {FC} from 'react';
import {MenuOption} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import styled from 'styled-components';
import {Card} from 'antd';
import {NegotiationPlaceholderKey, negotiationPlaceholderKeys} from '../../utils/placeholderKeys';
import {Placeholder} from './nodes/PlaceholderNode';

export class PlaceholderOption extends MenuOption {
  key: NegotiationPlaceholderKey;
  label: string;

  constructor(key: NegotiationPlaceholderKey) {
    const label = negotiationPlaceholderKeys[key];
    super(label);
    this.key = key;
    this.label = label;
  }
}

type PlaceholderMenuProps = {
  options: PlaceholderOption[];
  selectedIndex: number | null;
  onHoverOption: (index: number, option: PlaceholderOption) => void;
  onSelectOption: (index: number, option: PlaceholderOption) => void;
};

export const PlaceholderMenu: FC<PlaceholderMenuProps> = ({options, selectedIndex, onHoverOption, onSelectOption}) => {
  return (
    <PlaceholderMenuContainer className="typeahead-popover mentions-menu">
      <ul>
        {options.map((option, idx: number) => (
          <PlaceholderMenuItem
            key={option.key}
            index={idx}
            option={option}
            isSelected={selectedIndex === idx}
            onClick={() => onSelectOption(idx, option)}
            onMouseEnter={() => onHoverOption(idx, option)}
          />
        ))}
      </ul>
    </PlaceholderMenuContainer>
  );
};

type PlaceholderMenuItemProps = {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: PlaceholderOption;
};

const PlaceholderMenuItem: FC<PlaceholderMenuItemProps> = ({index, isSelected, onClick, onMouseEnter, option}) => {
  let className = 'item';
  if (isSelected) {
    className += ' selected';
  }
  return (
    <li
      ref={option.setRefElement}
      key={option.key}
      id={'typeahead-item-' + index}
      tabIndex={-1}
      className={className}
      role="option"
      aria-selected={isSelected}
      onMouseEnter={onMouseEnter}
      onClickCapture={event => {
        event.stopPropagation();
        event.preventDefault();
        onClick();
      }}>
      <Placeholder $isSelected={isSelected}>{option.label}</Placeholder>
    </li>
  );
};

const PlaceholderMenuContainer = styled(Card)`
  position: absolute;
  box-shadow: var(--box-shadow-dropdown);
  z-index: 1000;

  .ant-card-body {
    min-width: 150px;
    max-height: 200px;
    padding: 5px;
    overflow-y: auto;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 3px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`;
