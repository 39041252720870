/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Commission,
  CommissionFromJSON,
  CommissionFromJSONTyped,
  CommissionToJSON,
  Laycan,
  LaycanFromJSON,
  LaycanFromJSONTyped,
  LaycanToJSON,
  Quantity,
  QuantityFromJSON,
  QuantityFromJSONTyped,
  QuantityToJSON,
  VesselRequirement,
  VesselRequirementFromJSON,
  VesselRequirementFromJSONTyped,
  VesselRequirementToJSON,
} from './';

/**
 * Parsed and enriched data for a cargo offer
 * @export
 * @interface ParsedCargoData
 */
export interface ParsedCargoData {
  /**
   *
   * @type {number}
   * @memberof ParsedCargoData
   */
  fillCounter?: number;
  /**
   *
   * @type {number}
   * @memberof ParsedCargoData
   */
  dirtiness?: number;
  /**
   *
   * @type {number}
   * @memberof ParsedCargoData
   */
  confidence?: number;
  /**
   *
   * @type {Quantity}
   * @memberof ParsedCargoData
   */
  quantity?: Quantity | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  cargo?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  cargoCategories?: Array<string> | null;
  /**
   *
   * @type {Laycan}
   * @memberof ParsedCargoData
   */
  laycan?: Laycan | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  charterer?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  broker?: string | null;
  /**
   *
   * @type {Commission}
   * @memberof ParsedCargoData
   */
  commission?: Commission | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  vesselSize?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  loadPort?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  dischargePort?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof ParsedCargoData
   */
  maxVesselAge?: number | null;
  /**
   *
   * @type {Quantity}
   * @memberof ParsedCargoData
   */
  duration?: Quantity | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  durationUnit?: ParsedCargoDataDurationUnitEnum;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  loadRate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  dischargeRate?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  delivery?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  redelivery?: Array<string> | null;
  /**
   *
   * @type {Quantity}
   * @memberof ParsedCargoData
   */
  dwt?: Quantity | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  via?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  dischargeRateOptions?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  loadRateOptions?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ParsedCargoData
   */
  stowageFactor?: Array<number> | null;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  tct?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  period?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  voyage?: boolean | null;
  /**
   *
   * @type {Quantity}
   * @memberof ParsedCargoData
   */
  teu?: Quantity | null;
  /**
   *
   * @type {Quantity}
   * @memberof ParsedCargoData
   */
  teu14?: Quantity | null;
  /**
   *
   * @type {Quantity}
   * @memberof ParsedCargoData
   */
  reefer?: Quantity | null;
  /**
   *
   * @type {VesselRequirement}
   * @memberof ParsedCargoData
   */
  vesselRequirement?: VesselRequirement | null;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  offerToBuy?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof ParsedCargoData
   */
  mol?: number | null;
  /**
   *
   * @type {string}
   * @memberof ParsedCargoData
   */
  molOption?: ParsedCargoDataMolOptionEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedCargoData
   */
  trading?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  empty?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  chartererOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  commissionOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  chartererAndCommissionOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  missingCharterer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  missingCommission?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedCargoData
   */
  missingChartererCommission?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum ParsedCargoDataDurationUnitEnum {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}
/**
 * @export
 * @enum {string}
 */
export enum ParsedCargoDataMolOptionEnum {
  Moloo = 'MOLOO',
  Molco = 'MOLCO',
}

export function ParsedCargoDataFromJSON(json: any): ParsedCargoData {
  return ParsedCargoDataFromJSONTyped(json, false);
}

export function ParsedCargoDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsedCargoData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fillCounter: !exists(json, 'fillCounter') ? undefined : json['fillCounter'],
    dirtiness: !exists(json, 'dirtiness') ? undefined : json['dirtiness'],
    confidence: !exists(json, 'confidence') ? undefined : json['confidence'],
    quantity: !exists(json, 'quantity') ? undefined : QuantityFromJSON(json['quantity']),
    cargo: !exists(json, 'cargo') ? undefined : json['cargo'],
    cargoCategories: !exists(json, 'cargoCategories') ? undefined : json['cargoCategories'],
    laycan: !exists(json, 'laycan') ? undefined : LaycanFromJSON(json['laycan']),
    charterer: !exists(json, 'charterer') ? undefined : json['charterer'],
    broker: !exists(json, 'broker') ? undefined : json['broker'],
    commission: !exists(json, 'commission') ? undefined : CommissionFromJSON(json['commission']),
    vesselSize: !exists(json, 'vesselSize') ? undefined : json['vesselSize'],
    loadPort: !exists(json, 'loadPort') ? undefined : json['loadPort'],
    dischargePort: !exists(json, 'dischargePort') ? undefined : json['dischargePort'],
    maxVesselAge: !exists(json, 'maxVesselAge') ? undefined : json['maxVesselAge'],
    duration: !exists(json, 'duration') ? undefined : QuantityFromJSON(json['duration']),
    durationUnit: !exists(json, 'durationUnit') ? undefined : json['durationUnit'],
    loadRate: !exists(json, 'loadRate') ? undefined : json['loadRate'],
    dischargeRate: !exists(json, 'dischargeRate') ? undefined : json['dischargeRate'],
    delivery: !exists(json, 'delivery') ? undefined : json['delivery'],
    redelivery: !exists(json, 'redelivery') ? undefined : json['redelivery'],
    dwt: !exists(json, 'dwt') ? undefined : QuantityFromJSON(json['dwt']),
    via: !exists(json, 'via') ? undefined : json['via'],
    dischargeRateOptions: !exists(json, 'dischargeRateOptions') ? undefined : json['dischargeRateOptions'],
    loadRateOptions: !exists(json, 'loadRateOptions') ? undefined : json['loadRateOptions'],
    stowageFactor: !exists(json, 'stowageFactor') ? undefined : json['stowageFactor'],
    tct: !exists(json, 'tct') ? undefined : json['tct'],
    period: !exists(json, 'period') ? undefined : json['period'],
    voyage: !exists(json, 'voyage') ? undefined : json['voyage'],
    teu: !exists(json, 'teu') ? undefined : QuantityFromJSON(json['teu']),
    teu14: !exists(json, 'teu14') ? undefined : QuantityFromJSON(json['teu14']),
    reefer: !exists(json, 'reefer') ? undefined : QuantityFromJSON(json['reefer']),
    vesselRequirement: !exists(json, 'vesselRequirement')
      ? undefined
      : VesselRequirementFromJSON(json['vesselRequirement']),
    offerToBuy: !exists(json, 'offerToBuy') ? undefined : json['offerToBuy'],
    mol: !exists(json, 'mol') ? undefined : json['mol'],
    molOption: !exists(json, 'molOption') ? undefined : json['molOption'],
    trading: !exists(json, 'trading') ? undefined : json['trading'],
    empty: !exists(json, 'empty') ? undefined : json['empty'],
    complete: !exists(json, 'complete') ? undefined : json['complete'],
    chartererOnly: !exists(json, 'chartererOnly') ? undefined : json['chartererOnly'],
    commissionOnly: !exists(json, 'commissionOnly') ? undefined : json['commissionOnly'],
    chartererAndCommissionOnly: !exists(json, 'chartererAndCommissionOnly')
      ? undefined
      : json['chartererAndCommissionOnly'],
    missingCharterer: !exists(json, 'missingCharterer') ? undefined : json['missingCharterer'],
    missingCommission: !exists(json, 'missingCommission') ? undefined : json['missingCommission'],
    missingChartererCommission: !exists(json, 'missingChartererCommission')
      ? undefined
      : json['missingChartererCommission'],
  };
}

export function ParsedCargoDataToJSON(value?: ParsedCargoData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fillCounter: value.fillCounter,
    dirtiness: value.dirtiness,
    confidence: value.confidence,
    quantity: QuantityToJSON(value.quantity),
    cargo: value.cargo,
    cargoCategories: value.cargoCategories,
    laycan: LaycanToJSON(value.laycan),
    charterer: value.charterer,
    broker: value.broker,
    commission: CommissionToJSON(value.commission),
    vesselSize: value.vesselSize,
    loadPort: value.loadPort,
    dischargePort: value.dischargePort,
    maxVesselAge: value.maxVesselAge,
    duration: QuantityToJSON(value.duration),
    durationUnit: value.durationUnit,
    loadRate: value.loadRate,
    dischargeRate: value.dischargeRate,
    delivery: value.delivery,
    redelivery: value.redelivery,
    dwt: QuantityToJSON(value.dwt),
    via: value.via,
    dischargeRateOptions: value.dischargeRateOptions,
    loadRateOptions: value.loadRateOptions,
    stowageFactor: value.stowageFactor,
    tct: value.tct,
    period: value.period,
    voyage: value.voyage,
    teu: QuantityToJSON(value.teu),
    teu14: QuantityToJSON(value.teu14),
    reefer: QuantityToJSON(value.reefer),
    vesselRequirement: VesselRequirementToJSON(value.vesselRequirement),
    offerToBuy: value.offerToBuy,
    mol: value.mol,
    molOption: value.molOption,
    trading: value.trading,
    empty: value.empty,
    complete: value.complete,
    chartererOnly: value.chartererOnly,
    commissionOnly: value.commissionOnly,
    chartererAndCommissionOnly: value.chartererAndCommissionOnly,
    missingCharterer: value.missingCharterer,
    missingCommission: value.missingCommission,
    missingChartererCommission: value.missingChartererCommission,
  };
}
