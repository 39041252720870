/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface NewsItemWrite
 */
export interface NewsItemWrite {
  /**
   *
   * @type {string}
   * @memberof NewsItemWrite
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof NewsItemWrite
   */
  description: string;
  /**
   *
   * @type {Date}
   * @memberof NewsItemWrite
   */
  publishedAt: Date | null;
}

export function NewsItemWriteFromJSON(json: any): NewsItemWrite {
  return NewsItemWriteFromJSONTyped(json, false);
}

export function NewsItemWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItemWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json['title'],
    description: json['description'],
    publishedAt: json['publishedAt'] === null ? null : new Date(json['publishedAt']),
  };
}

export function NewsItemWriteToJSON(value?: NewsItemWrite | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    publishedAt: value.publishedAt === null ? null : value.publishedAt.toISOString(),
  };
}
