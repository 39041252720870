import {FC} from 'react';
import {useGetUserDateTime, UserDateTimeProps} from './useGetUserDateTime';

/**
 * This Component formats a time under consideration of the user time zone.
 */
export const UserDateTime: FC<UserDateTimeProps> = ({value, format, source}) => {
  const getUserDateTime = useGetUserDateTime();
  return <>{getUserDateTime({value, format, source})}</>;
};
