import dayjs from 'dayjs';
import {ReactNode} from 'react';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {TODO} from '../../../../utils/TODO';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {RowValueType} from '../../utils/RowValueType';
import {ColumnHeader} from '../../../DataGrid/ColumnHeader/ColumnHeader';
import {SeaboVessel} from '../../../../api/node-backend/generated';

export const createdAtAccessor =
  (type: RowValueType): AccessorFn<SeaboVessel> =>
  (rowValue: TODO): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'} data-cy="cell-createdAt">
        <div className={'market-grid-cell-content'}>{dayjs(vessel.createdAt).format(DateTimeFormat.Date)}</div>
      </div>
    );
  };
export const createdAtColumn = ({
  type,
  minWidth = 8,
}: {
  type?: RowValueType;
  minWidth?: number;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <ColumnHeader sortable data-cy="header-createdAt">
      created At
    </ColumnHeader>
  ),

  id: 'createdAt',
  accessorFn: createdAtAccessor(type),
  cell: info => info.getValue(),
  enableSorting: true,
  minWidth,
});
