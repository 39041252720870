import {FilterItem, FilterType} from '../../api/symfony/generated';
import {useSelector} from '../../redux/react-redux';
import {useMemo} from 'react';

export const useSubMarkets = (filterType: FilterType): FilterItem[] => {
  const allSubMarkets: FilterItem[] = useSelector(state => state.subMarkets.subMarkets);
  const subMarkets: FilterItem[] = useMemo(
    () => allSubMarkets.filter(subMarket => subMarket.type === filterType),
    [allSubMarkets, filterType]
  );
  return subMarkets;
};
