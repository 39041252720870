import {TODO} from '../../utils/TODO';
import {useState, FC} from 'react';
import {TagsView} from '../TagSelection/TagsView';
import {TagSelection} from '../TagSelection/TagSelection';
import {Tag, TagType} from '../../api/symfony/generated';
import {useAvailableTagsQuery} from '../../queries/useAvailableTagsQuery';
import {useUpdateFixtureMutation} from './useUpdateFixturesMutation';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

interface Props {
  fixture: TODO;
  onChange: (() => void) | undefined;
}

export const FixtureTags: FC<Props> = props => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const availableTagsQueryResult = useAvailableTagsQuery({type: TagType.Fixture});
  const mutateFixture = useUpdateFixtureMutation({
    onSuccess: () => props.onChange && props.onChange(),
  });

  if (!availableTagsQueryResult.isSuccess) {
    return null;
  }

  const sortedAvailableTags = sortTags(availableTagsQueryResult.data.items);
  const sortedSelectedTags = sortTags(getSelectedFixtureTags(props.fixture));

  const updateFixtureTags = (newSelectedTags: Tag[]) => {
    const updatedFixture = {...props.fixture, tags: newSelectedTags};
    mutateFixture.mutate(updatedFixture);
  };

  return (
    <TagSelection
      onOpenChange={open => {
        setModalOpen(open);
      }}
      availableTags={sortedAvailableTags}
      selectedTags={sortedSelectedTags}
      onChange={newSelectedTags => updateFixtureTags(newSelectedTags)}>
      <FlexBox>
        <TagsView modalOpen={modalOpen} selectedTags={sortedSelectedTags} />
      </FlexBox>
    </TagSelection>
  );
};

const sortTags = (tags: Tag[]): Tag[] => {
  const sortedTags = sortBy(tags, tag => tag.name.toLowerCase());
  return sortedTags;
};

const getSelectedFixtureTags = (fixture: TODO): Tag[] => {
  return fixture.tags;
};

const FlexBox = styled.div`
  display: flex;
  max-width: 100%;
`;
