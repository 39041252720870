import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Select} from 'antd';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {ModalActions} from '../../../redux/Modal';
import PortfolioService, {Workspace} from '../../../redux/ApiService/PortfolioService';
import {TODO} from '../../../utils/TODO';
import {useCloneVesselToMyFleetMutation} from './useCloneVesselToMyFleetMutation';
import {useCloneCargoToMyCargoesMutation} from './useCloneCargoToMyCargoesMutation';
import {useWorkspacesQuery} from '../../../queries/useWorkspacesQuery';
import {Modal, ModalWidth} from '../../../antd/components/Modal';
import {useUser} from '../../UserContext/useUser';
import {assert} from '../../../utils/assert';
import {VesselDetailsVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {PortfolioVessel} from '../../../redux/Portfolio';
import {MapDetailItem} from '../../SeaboMap/MapDetails/utils/types';

type SubPortfolio = {
  id: number;
  name: string;
  sortOrder: number;
};

type Type = 'vessel' | 'cargo';

type Cargo = TODO;

type VesselProps = {
  type: 'vessel';
  vessel: VesselDetailsVessel | PortfolioVessel | MapDetailItem;
  cargo?: never;
};

type CargoProps = {
  type: 'cargo';
  cargo: Cargo;
  vessel?: never;
};

type Props = (VesselProps | CargoProps) & {
  title?: string;
  reload?: () => void;
  buttonText?: string;
};

export const ClonePortfolioEntry = ({title: titleOverride, type, cargo, vessel, reload, buttonText}: Props) => {
  const dispatch = useDispatch();
  const vesselSubPortfolios = useSelector(
    state => (state.portfolio as {selectableVesselSubPortfolios: SubPortfolio[]}).selectableVesselSubPortfolios
  );
  const cargoSubPortfolios = useSelector(
    state => (state.portfolio as {selectableCargoSubPortfolios: SubPortfolio[]}).selectableCargoSubPortfolios
  );
  const user = useUser();
  const activeWorkspace = user.workspace;

  const subPortfolios: SubPortfolio[] = (
    type === 'vessel' ? vesselSubPortfolios : cargoSubPortfolios
  ) as SubPortfolio[];

  const isVessel = type === 'vessel';

  // object is the cargo or vessel in props
  const object: Cargo | VesselDetailsVessel = isVessel ? vessel : cargo;
  assert(object, 'Missing both cargo and vessel');
  const title = titleOverride ?? `Add to ${isVessel ? 'My Fleet' : 'My Cargoes'}`;
  const cta = buttonText ?? `Add to ${isVessel ? 'My Fleet' : 'My Cargoes'}`;

  const onSuccess = () => {
    const typeName = type === 'vessel' ? 'Vessel' : 'Cargo';
    void message.success(`${typeName} was added.`);
    dispatch(ModalActions.hide());
    reload?.();
  };
  // hook to clone

  const cloneVesselMutation = useCloneVesselToMyFleetMutation({
    onSuccess,
  });
  const cloneCargoMutation = useCloneCargoToMyCargoesMutation({onSuccess});

  // get workspaces
  const workspacesQuery = useWorkspacesQuery();

  const workspaces = workspacesQuery.data;

  const [name, setName] = useState(object.chartererName || object.name);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(activeWorkspace);
  const [selectedSubPortfolios, setSelectedSubPortfolios] = useState<SubPortfolio[]>([]);

  const loading = cloneCargoMutation.isPending || cloneVesselMutation.isPending;
  const getSubData = (type: Type, workspace: Workspace) =>
    type === 'vessel'
      ? dispatch(PortfolioService.getVesselGroups(workspace))
      : dispatch(PortfolioService.getCargoGroups(workspace));
  // Get the sub portfolios from selected workspace
  useEffect(() => {
    getSubData(type, selectedWorkspace);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedWorkspace]);

  const onOk = async () => {
    if (isVessel) {
      cloneVesselMutation.mutate({
        vessel: {...object, name, chartererName: name},
        workspaceId: selectedWorkspace.id,
        subPortfolioIds: selectedSubPortfolios.map(item => item.id),
      });
    } else {
      cloneCargoMutation.mutate({
        cargo: {...object, name, chartererName: name},
        workspaceId: selectedWorkspace.id,
        subPortfolioIds: selectedSubPortfolios.map(item => item.id),
      });
    }
  };

  return (
    <Modal
      centered={true}
      onCancel={() => {
        dispatch(ModalActions.hide());
      }}
      footer={
        <Button
          data-cy={'clonePortfolioEntryModalCloneBtn'}
          disabled={!name}
          key="submit"
          type="primary"
          loading={loading}
          onClick={onOk}>
          {cta}
        </Button>
      }
      width={ModalWidth.Middle}
      onOk={() => {}}
      open={true}
      title={title}>
      <div data-cy={'clonePortfolioEntryModalContent'}>
        <Form layout={'vertical'}>
          <Form.Item label="Name">
            <Input id={'name'} value={name} onChange={event => setName(event.target.value)} />
          </Form.Item>
          <Form.Item label="Select workspace">
            <Select
              value={selectedWorkspace.id}
              showSearch
              optionFilterProp="children"
              onChange={(value: number) => {
                const workspace = workspaces?.find(item => item.id === value);
                if (workspace) {
                  setSelectedWorkspace(workspace);
                }
              }}
              filterOption={(input, option) =>
                (option?.children?.filter(child => child.toLowerCase().indexOf(input.toLowerCase())).length || 0) >= 0
              }>
              {workspaces?.map(item => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          {subPortfolios.length > 0 && (
            <Form.Item label={`Select ${isVessel ? 'vessel list' : 'cargo list'}`}>
              <Select
                mode="multiple"
                value={selectedSubPortfolios.map(item => item.id)}
                showSearch
                optionFilterProp="children"
                onChange={(value: number[]) => {
                  const portfolios = subPortfolios.filter(item => value.includes(item.id)) ?? [];
                  setSelectedSubPortfolios(portfolios);
                }}
                filterOption={(input, option) =>
                  (option?.children?.filter(child => child.toLowerCase().indexOf(input.toLowerCase())).length || 0) >= 0
                }>
                {subPortfolios.map(item => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};
