/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AisPortApiModel,
  AisPortApiModelFromJSON,
  AisPortApiModelFromJSONTyped,
  AisPortApiModelToJSON,
  AisTradingAreaApiModel,
  AisTradingAreaApiModelFromJSON,
  AisTradingAreaApiModelFromJSONTyped,
  AisTradingAreaApiModelToJSON,
} from './';

/**
 * A combination of AIS and static vessel data.
 * @export
 * @interface AisVesselDetailsApiModel
 */
export interface AisVesselDetailsApiModel {
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  mmsi?: number;
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  imo?: number;
  /**
   *
   * @type {string}
   * @memberof AisVesselDetailsApiModel
   */
  name?: string | null;
  /**
   * The country code of the vessel flag.
   * @type {string}
   * @memberof AisVesselDetailsApiModel
   */
  country?: string | null;
  /**
   * The class of the vessel, e.g. "bulker", "container" or "tanker".
   * @type {string}
   * @memberof AisVesselDetailsApiModel
   */
  _class?: string | null;
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  longitude?: number | null;
  /**
   * Human-readable coordinates.
   * @type {string}
   * @memberof AisVesselDetailsApiModel
   */
  coordinates?: string | null;
  /**
   * Time of the position data.
   * @type {Date}
   * @memberof AisVesselDetailsApiModel
   */
  postime?: Date | null;
  /**
   * Length overall in meters.
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  loa?: number | null;
  /**
   * Beam in meters.
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  beam?: number | null;
  /**
   * Draught in meters.
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  draught?: number | null;
  /**
   * Speed in knots.
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  speed?: number | null;
  /**
   * Course in degrees.
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  course?: number | null;
  /**
   * Current heading in degrees.
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  heading?: number | null;
  /**
   *
   * @type {string}
   * @memberof AisVesselDetailsApiModel
   */
  statusText?: string | null;
  /**
   *
   * @type {AisTradingAreaApiModel}
   * @memberof AisVesselDetailsApiModel
   */
  lastArea?: AisTradingAreaApiModel | null;
  /**
   *
   * @type {AisPortApiModel}
   * @memberof AisVesselDetailsApiModel
   */
  lastPort?: AisPortApiModel | null;
  /**
   *
   * @type {AisTradingAreaApiModel}
   * @memberof AisVesselDetailsApiModel
   */
  currentArea?: AisTradingAreaApiModel | null;
  /**
   *
   * @type {AisPortApiModel}
   * @memberof AisVesselDetailsApiModel
   */
  currentPort?: AisPortApiModel | null;
  /**
   * Provided destination information.
   * @type {string}
   * @memberof AisVesselDetailsApiModel
   */
  destination?: string | null;
  /**
   *
   * @type {AisTradingAreaApiModel}
   * @memberof AisVesselDetailsApiModel
   */
  destinationArea?: AisTradingAreaApiModel | null;
  /**
   *
   * @type {AisPortApiModel}
   * @memberof AisVesselDetailsApiModel
   */
  destinationPort?: AisPortApiModel | null;
  /**
   * Estimated time of arrival.
   * @type {Date}
   * @memberof AisVesselDetailsApiModel
   */
  eta?: Date | null;
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  teuQuantity?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  teu14Quantity?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  dwtSummer?: number | null;
  /**
   *
   * @type {number}
   * @memberof AisVesselDetailsApiModel
   */
  vesselId?: number | null;
}

export function AisVesselDetailsApiModelFromJSON(json: any): AisVesselDetailsApiModel {
  return AisVesselDetailsApiModelFromJSONTyped(json, false);
}

export function AisVesselDetailsApiModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AisVesselDetailsApiModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mmsi: !exists(json, 'mmsi') ? undefined : json['mmsi'],
    imo: !exists(json, 'imo') ? undefined : json['imo'],
    name: !exists(json, 'name') ? undefined : json['name'],
    country: !exists(json, 'country') ? undefined : json['country'],
    _class: !exists(json, 'class') ? undefined : json['class'],
    latitude: !exists(json, 'latitude') ? undefined : json['latitude'],
    longitude: !exists(json, 'longitude') ? undefined : json['longitude'],
    coordinates: !exists(json, 'coordinates') ? undefined : json['coordinates'],
    postime: !exists(json, 'postime') ? undefined : json['postime'] === null ? null : new Date(json['postime']),
    loa: !exists(json, 'loa') ? undefined : json['loa'],
    beam: !exists(json, 'beam') ? undefined : json['beam'],
    draught: !exists(json, 'draught') ? undefined : json['draught'],
    speed: !exists(json, 'speed') ? undefined : json['speed'],
    course: !exists(json, 'course') ? undefined : json['course'],
    heading: !exists(json, 'heading') ? undefined : json['heading'],
    statusText: !exists(json, 'statusText') ? undefined : json['statusText'],
    lastArea: !exists(json, 'lastArea') ? undefined : AisTradingAreaApiModelFromJSON(json['lastArea']),
    lastPort: !exists(json, 'lastPort') ? undefined : AisPortApiModelFromJSON(json['lastPort']),
    currentArea: !exists(json, 'currentArea') ? undefined : AisTradingAreaApiModelFromJSON(json['currentArea']),
    currentPort: !exists(json, 'currentPort') ? undefined : AisPortApiModelFromJSON(json['currentPort']),
    destination: !exists(json, 'destination') ? undefined : json['destination'],
    destinationArea: !exists(json, 'destinationArea')
      ? undefined
      : AisTradingAreaApiModelFromJSON(json['destinationArea']),
    destinationPort: !exists(json, 'destinationPort') ? undefined : AisPortApiModelFromJSON(json['destinationPort']),
    eta: !exists(json, 'eta') ? undefined : json['eta'] === null ? null : new Date(json['eta']),
    teuQuantity: !exists(json, 'teuQuantity') ? undefined : json['teuQuantity'],
    teu14Quantity: !exists(json, 'teu14Quantity') ? undefined : json['teu14Quantity'],
    dwtSummer: !exists(json, 'dwtSummer') ? undefined : json['dwtSummer'],
    vesselId: !exists(json, 'vesselId') ? undefined : json['vesselId'],
  };
}

export function AisVesselDetailsApiModelToJSON(value?: AisVesselDetailsApiModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mmsi: value.mmsi,
    imo: value.imo,
    name: value.name,
    country: value.country,
    class: value._class,
    latitude: value.latitude,
    longitude: value.longitude,
    coordinates: value.coordinates,
    postime: value.postime === undefined ? undefined : value.postime === null ? null : value.postime.toISOString(),
    loa: value.loa,
    beam: value.beam,
    draught: value.draught,
    speed: value.speed,
    course: value.course,
    heading: value.heading,
    statusText: value.statusText,
    lastArea: AisTradingAreaApiModelToJSON(value.lastArea),
    lastPort: AisPortApiModelToJSON(value.lastPort),
    currentArea: AisTradingAreaApiModelToJSON(value.currentArea),
    currentPort: AisPortApiModelToJSON(value.currentPort),
    destination: value.destination,
    destinationArea: AisTradingAreaApiModelToJSON(value.destinationArea),
    destinationPort: AisPortApiModelToJSON(value.destinationPort),
    eta: value.eta === undefined ? undefined : value.eta === null ? null : value.eta.toISOString(),
    teuQuantity: value.teuQuantity,
    teu14Quantity: value.teu14Quantity,
    dwtSummer: value.dwtSummer,
    vesselId: value.vesselId,
  };
}
