interface BaseVessel {
  vesselType: string;
}

export const filterVesselByType = <Vessel extends BaseVessel>({
  vessels,
  filter,
}: {
  vessels: Vessel[];
  filter: string[];
}): Vessel[] => {
  if (filter.length === 0) {
    return vessels;
  }
  return vessels.filter(vessel => {
    return filter.includes(vessel.vesselType);
  });
};
