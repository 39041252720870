import dayjs, {Dayjs} from 'dayjs';
import isNumber from 'is-number';
import {roundNumberToDecimals} from '../../utils/roundNumber';
import {numberFormat} from '../../utils/formatter';
import {NotAvailable} from '../../utils/NotAvailable';

export const getRouteResults = (response: $TSFixMe) => ({
  route: response.waypoints ? response.waypoints : null,
  distance: response.distance ? response.distance : null,
  seca: response.secaIntersection ? response.secaIntersection : null,
  piracy: response.piracyIntersection ? response.piracyIntersection : null,
});

export const convertTimeToTimezone = (date: Dayjs | null | undefined, timezone: string) =>
  date?.clone().tz(timezone) ?? null;

export const timeDiff = (hours: number | null) => {
  if (hours === null) {
    return {
      label: '',
      value: NotAvailable,
    };
  }

  const decimalHours = hours / 24;

  const duration = {
    label: 'd',
    value: decimalHours.toFixed(1).replace(/[.,]0$/, ''),
  };

  return duration;
};

export const getSpeedLabel = (speed: number | 'Infinity') => {
  if (speed === 'Infinity') {
    return 'n/a';
  }
  return numberFormat(roundNumberToDecimals(speed, 2));
};

export const etdFields = (date: Dayjs, duration: number | undefined, tz: string) => ({
  ETA: date,
  ETD: duration
    ? dayjs(date)
        .subtract(duration * 3600, 'seconds')
        .tz(tz)
    : null,
});

/**
 *
 * @param duration in days
 * @param tz timezone
 */
export const etaFields = (date: Dayjs, duration: number | undefined, tz: string) => ({
  ETA: duration
    ? dayjs(date)
        .add(duration * 3600, 'seconds')
        .tz(tz)
    : null,
  ETD: date,
});

type CalculateResultsObjectParams = {
  eta: Dayjs;
  etd: Dayjs;
  speed: number;
  distance: number;
  type: string;
};

export const calculateResultsObject = ({type, eta, etd, speed, distance}: CalculateResultsObjectParams) => {
  const duration = isNumber(speed) && isNumber(distance) ? distance / speed : undefined;
  const base = {
    speed,
    distance,
    duration,
    ETA: eta,
    ETD: etd,
    hasResult: !!duration,
  };
  switch (type) {
    case 'ETA': {
      return {
        ...base,
        ...etaFields(etd, duration, 'UTC'),
      };
    }
    case 'ETD': {
      return {
        ...base,
        ...etdFields(eta, duration, 'UTC'),
      };
    }
    case 'SPEED': {
      if (eta === null) {
        eta = dayjs();
      }
      const diff = Math.abs(dayjs.duration(eta.diff(etd)).asHours());
      return {
        ...base,
        ETA: eta,
        ETD: etd,
        speed: distance ? distance / diff : null,
        duration: diff,
      };
    }
    default: {
      return base;
    }
  }
};
export const calculate = (routePoints: $TSFixMe) => (total: $TSFixMe, route: $TSFixMe, key: $TSFixMe) => {
  if (route && route.errorCode) {
    const {from, to} = routePoints[key];
    return {
      ...total,
      routes: [
        ...total.routes,
        {
          success: false,
          coords: [
            [from.lon, from.lat],
            [to.lon, to.lat],
          ],
        },
      ],
      hasError: [...total.hasError, key],
    };
  }
  const section = getRouteResults(route);
  return {
    ...total,
    routes: [
      ...total.routes,
      {
        success: true,
        coords: section.route,
        seca: section.seca,
        piracy: section.piracy,
        distance: section.distance,
      },
    ],
    distance: total.distance + section.distance,
    seca: total.seca + section.seca,
    piracy: total.piracy + section.piracy,
  };
};
const areas = {
  suez: 11,
  panama: 23,
  kiel: 35,
};
export const blockedAreas = (options: $TSFixMe) =>
  `${(
    Object.keys(options).reduce(
      (result, key) =>
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        ['panama', 'suez', 'kiel'].indexOf(key) > -1 && options[key].value ? [...result, areas[key]] : result,
      []
    ) as $TSFixMe
  ).join(',')}`;
