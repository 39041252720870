const DragHandle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14">
    <path
      fill="var(--color-gray-2)"
      fillRule="evenodd"
      d="M2.436 3.41a1.538 1.538 0 1 1 0-3.077 1.538 1.538 0 0 1 0 3.077zm0 5.128a1.538 1.538 0 1 1 0-3.076 1.538 1.538 0 0 1 0 3.076zM7.564 3.41a1.538 1.538 0 1 1 0-3.077 1.538 1.538 0 0 1 0 3.077zm0 5.128a1.538 1.538 0 1 1 0-3.076 1.538 1.538 0 0 1 0 3.076zm-5.128 5.129a1.538 1.538 0 1 1 0-3.077 1.538 1.538 0 0 1 0 3.077zm5.128 0a1.538 1.538 0 1 1 0-3.077 1.538 1.538 0 0 1 0 3.077z"
    />
  </svg>
);
export default DragHandle;
