import {FC} from 'react';
import LoadingAnimation from '../../../atoms/Loading';
import {NegotiationPreviewCard} from './NegotiationPreviewCard';
import {FlexBox} from '../Components/shared';
import {useFindAllNegotiationsQuery} from './useFindAllNegotiationsQuery';
import Lighthouse from '../../../atoms/EmptyState/LightHouse';
import {NegotiationStatus, NegotiationSummary} from '../../../api/node-backend/generated';
import {negotiationStatusOrder} from '../Components/NegotiationSteps';
import {useArray} from '../../../utils/useArray';
import {Card} from 'antd';
import {useNavigate} from 'react-router';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import {CargoContractType} from '../../../atoms/CargoHexagon/CargoHexagon';
import {FilterBar} from '../../../components/FilterBar/FilterBar';
import {NegotiationContractTypeFilter} from './Filter/NegotiationContractTypeFilter';
import {NegotiationStatusFilter} from './Filter/NegotiationStatusFilter';

const isCargoContractType = (value: string | null): value is CargoContractType =>
  !!value && ['vc', 'tct', 'pc', 'bc'].includes(value);

export const NegotiationOverviewList: FC = () => {
  const navigate = useNavigate();

  const [statusFilter, {set: setStatusFilter}] = useArray<NegotiationStatus>([]);
  const [contractTypeFilter, {set: setContractTypeFilter}] = useArray<CargoContractType>([]);

  const negotiationOverviewQuery = useFindAllNegotiationsQuery();

  if (!negotiationOverviewQuery.isSuccess) {
    return (
      <FlexBox style={{justifyContent: 'center'}} data-testid="NegotiationOverviewListLoading">
        <LoadingAnimation />
      </FlexBox>
    );
  }

  const negotiations = negotiationOverviewQuery.data;

  const getIsIncludedContractType = (negotiation: NegotiationSummary) => {
    return (
      contractTypeFilter.length === 0 ||
      (isCargoContractType(negotiation.cargo.contractType) &&
        contractTypeFilter.includes(negotiation.cargo.contractType))
    );
  };

  const getIsIncludedStatus = (negotiation: NegotiationSummary) => {
    return statusFilter.length === 0 || statusFilter.includes(negotiation.status);
  };

  const filteredNegotiations = negotiations.filter(
    negotiation => getIsIncludedContractType(negotiation) && getIsIncludedStatus(negotiation)
  );

  const Filters = () => (
    <FilterBar
      id="negotiationFilters"
      filters={[
        <NegotiationContractTypeFilter values={contractTypeFilter} onChange={setContractTypeFilter} />,
        <NegotiationStatusFilter values={statusFilter} onChange={setStatusFilter} />,
      ]}
    />
  );

  if (filteredNegotiations.length === 0) {
    return (
      <>
        <Filters />
        <Card>
          <FlexBox style={{width: '100%', justifyContent: 'center'}} data-testid="NegotiationOverviewListEmpty">
            <Lighthouse
              headline="No negotiations found"
              showCallbackButton
              onClickButton={() => navigate('/negotiations/create')}
              buttonText={`Create ${negotiations.length ? 'new' : 'your first'} negotiation`}
              subHeadline="Try to change your filter criteria or"
            />
          </FlexBox>
        </Card>
      </>
    );
  }

  // Sort by most recent first, then by status
  const sortedNegotiations = sortBy(
    filteredNegotiations,
    negotiation => negotiationStatusOrder.indexOf(negotiation.status),
    negotiation => (negotiation.latestRound.committedAt ? -dayjs(negotiation.latestRound.committedAt).valueOf() : 0)
  );

  return (
    <div data-testid="NegotiationOverviewList">
      <Filters />
      <div style={{marginBlockStart: '20px'}}>
        {sortedNegotiations.map(negotiation => (
          <NegotiationPreviewCard key={negotiation.id} negotiation={negotiation} />
        ))}
      </div>
    </div>
  );
};
