import React, {FC} from 'react';
import {Tooltip} from 'antd';
import {TourDefinition} from '../OnboardingTour/TourDefinition';
import {ScreenActionButton} from './ScreenActionButton';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {invalidateTourUserDataQuery} from '../OnboardingTour/useTourUserDataQuery';
import {useQueryClient} from '@tanstack/react-query';
import {useUpdateTourUserDataMutation} from '../OnboardingTour/useUpdateTourUserDataMutation';

type ShowTourButtonProps = {
  tourDefinition: TourDefinition;
};

export const ShowTourButton: FC<ShowTourButtonProps> = ({tourDefinition}) => {
  const tourId = tourDefinition.id;
  const queryClient = useQueryClient();
  const repeatTourMutation = useUpdateTourUserDataMutation(tourId);

  const resetTour = () => {
    repeatTourMutation.mutate(
      {tourId, step: 0},
      {
        onSuccess: () => {
          void invalidateTourUserDataQuery(tourId, queryClient);
        },
      }
    );
  };

  return (
    <Tooltip title="Show tour">
      <div style={{marginLeft: 4}}>
        <ScreenActionButton onClick={resetTour} data-testid="ShowTourButton">
          <QuestionCircleOutlined />
        </ScreenActionButton>
      </div>
    </Tooltip>
  );
};
