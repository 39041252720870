import {Form, Select} from 'antd';
import React, {useContext} from 'react';
import {MapContext} from '../../MapContext/MapContext';
import {MAPBOX_STYLES} from '../../const';
import {MapStyle} from '../../MapContext/Types';

export const MapBoxLayer = () => {
  const mapContext = useContext(MapContext);
  const options = Object.keys(MAPBOX_STYLES).map(key => {
    return {value: key, label: MAPBOX_STYLES[key as MapStyle].label};
  });

  const changeMapStyle = (value: MapStyle) => {
    return mapContext.setSetting('mapStyle', value);
  };

  return (
    <Form
      size={'small'}
      colon={false}
      labelCol={{span: 12}}
      wrapperCol={{span: 12}}
      layout="horizontal"
      labelAlign="left">
      <Form.Item
        style={{
          marginRight: 8,
        }}
        label={'Map style'}>
        <Select<MapStyle>
          value={mapContext.state.settings.mapStyle}
          onChange={value => {
            changeMapStyle(value);
          }}>
          {options.map(item => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
