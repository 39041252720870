/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanyStartPromotionResponse
 */
export interface CompanyStartPromotionResponse {
  /**
   * Promotion end date
   * @type {string}
   * @memberof CompanyStartPromotionResponse
   */
  promotionUntil: string;
}

export function CompanyStartPromotionResponseFromJSON(json: any): CompanyStartPromotionResponse {
  return CompanyStartPromotionResponseFromJSONTyped(json, false);
}

export function CompanyStartPromotionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyStartPromotionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    promotionUntil: json['promotionUntil'],
  };
}

export function CompanyStartPromotionResponseToJSON(value?: CompanyStartPromotionResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    promotionUntil: value.promotionUntil,
  };
}
