import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {TimelineEvent, getEventHasCoordinates} from '../utils/types';
import {voyageTimelineFilterLabels} from '../utils/filters';
import {getEventColor, getEventEnd, getEventStart, getIsEventSelected} from '../utils/utils';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import dayjs from 'dayjs';
import {
  AnchorageVisit,
  DestinationChange,
  DraftChange,
  EtaChange,
  FlagChange,
  NameChange,
  PassingPortVisit,
  PierVisit,
  VesselVoyageTimelineEventType,
} from '../../../../api/node-backend/generated';
import {UserDateTime} from '../../../../components/DateTime/UserDateTime';
import Icon from '../../../../atoms/Icon';

type SubEventListProps = {
  events: TimelineEvent[];
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: (id: TimelineEvent | null) => void;
};

export const SubEventList: FC<SubEventListProps> = ({events, selectedEvent, onSelectEvent}) => {
  return (
    <SubEventListContainer>
      {events.map((event, idx) => (
        <SubEventListItem key={idx}>
          <SubEvent event={event} selectedEvent={selectedEvent} onSelectEvent={onSelectEvent} />
        </SubEventListItem>
      ))}
    </SubEventListContainer>
  );
};

type SubEventProps = {
  event: TimelineEvent;
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: (id: TimelineEvent | null) => void;
};

const SubEvent: FC<SubEventProps> = ({event, selectedEvent, onSelectEvent}) => {
  const {eventType} = event;
  const color = getEventColor(eventType);

  const start = getEventStart(event);
  const end = getEventEnd(event);

  const eventStart = dayjs(start).format(DateTimeFormat.DateTime);
  const eventEnd = end ? dayjs(end).format(DateTimeFormat.DateTime) : null;
  const eventDate = eventStart + (eventEnd ? ` - ${eventEnd}` : '');
  const eventLabel = voyageTimelineFilterLabels[eventType];
  const eventDetails = getEventDetails(event);

  const hasCoordinates = getEventHasCoordinates(event);
  const isSelected = getIsEventSelected(event, selectedEvent);
  const handleSelect = () => {
    isSelected ? onSelectEvent?.(null) : onSelectEvent?.(event);
  };

  return (
    <SubEventContainer $color={color} onClick={handleSelect}>
      <EventDate>{eventDate}</EventDate>
      <b>{eventLabel}</b>
      <EventDetailsContainer>
        {eventDetails}
        {hasCoordinates && <Icon type="location" color={isSelected ? 'white' : 'light-gray'} size="small" />}
      </EventDetailsContainer>
    </SubEventContainer>
  );
};

const getEventDetails = (event: TimelineEvent): string | ReactNode => {
  switch (event.eventType) {
    case VesselVoyageTimelineEventType.PassingPortVisit:
      return <div>{(event as PassingPortVisit).port?.name}</div>;
    case VesselVoyageTimelineEventType.PierVisit:
      return <div>{(event as PierVisit).pier.port?.name}</div>;
    case VesselVoyageTimelineEventType.AnchorageVisit:
      return <div>{(event as AnchorageVisit).anchorage.port.name}</div>;
    case VesselVoyageTimelineEventType.NameChange:
      return <div>{(event as NameChange).newName}</div>;
    case VesselVoyageTimelineEventType.FlagChange:
      return <div>{(event as FlagChange).newFlag}</div>;
    case VesselVoyageTimelineEventType.EtaChange:
      return (
        <div>
          <UserDateTime value={(event as EtaChange).newETA.toString()} format="dateTime" />
        </div>
      );
    case VesselVoyageTimelineEventType.DestinationChange:
      return <div>{(event as DestinationChange).newDestination}</div>;
    case VesselVoyageTimelineEventType.DraftChange:
      return <div>{(event as DraftChange).newDraft}m</div>;
    default:
      return null;
  }
};

const SubEventListContainer = styled.ul`
  margin: 2px 10px 5px -2px;
  padding: 10px 30px 0 0;
  border-top: var(--border-base);
  list-style: none;
`;

const SubEventListItem = styled.li`
  position: relative;
  padding: 0;
  margin: 0;
`;

const SubEventContainer = styled.div<{$color: string}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 6px;
  margin: 0 -30px 2px 0;
  background: var(--timeline-color-${({$color}) => $color});
  color: var(--timeline-color-${({$color}) => $color}-text);
  border-radius: var(--border-radius-lg);
  &::before {
    content: '';
    position: absolute;
    top: 7.5px;
    left: -15.5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--timeline-color-${({$color}) => $color});
  }
`;

const EventDate = styled.div`
  width: 300px;
`;

const EventDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin: 0 0 0 auto;
  text-align: right;
`;
