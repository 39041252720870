import dayjs, {Dayjs} from 'dayjs';
import {FC} from 'react';
import Input from '../../../atoms/Input';
import LabelWrapper from '../../../atoms/LabelWrapper';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import {WrapperProps} from '../../../components/FormProvider/WrapperProps';
import {StyledRangePicker} from '../../../components/RangePicker/StyledRangePicker';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {dateEarlierThanOrEqualTo} from '../../../utils/validators';

type DryDockFields = 'dryDockFrom' | 'dryDockTo' | 'dryDockNote';

type DryDockValues = {
  dryDockFrom: null | Dayjs;
  dryDockTo: null | Dayjs;
  dryDockNote: string;
};
type DryDockValuesApi = {
  dryDockFrom: string | null;
  dryDockTo: string | null;
  dryDockNote: string;
};

const Wrapper = BindToProvider(
  'Next Dry Dock',
  'dryDock',
  {
    dryDockFrom: null,
    dryDockTo: null,
    note: '',
  },
  {
    dryDockFrom: dateEarlierThanOrEqualTo('dryDockTo'),
  },
  {
    toApi: ({dryDockFrom, dryDockTo, dryDockNote}: DryDockValues): DryDockValuesApi => ({
      dryDockNote,
      dryDockFrom: dryDockFrom ? dryDockFrom.format(DateTimeFormat.IsoDate) : null,
      dryDockTo: dryDockTo ? dryDockTo.format(DateTimeFormat.IsoDate) : null,
    }),
    fromApi: ({dryDockFrom, dryDockTo, dryDockNote}: DryDockValuesApi): DryDockValues => ({
      dryDockTo: dryDockTo ? dayjs(dryDockTo) : null,
      dryDockFrom: dryDockFrom ? dayjs(dryDockFrom) : null,
      dryDockNote,
    }),
  },
  true
)(WrappedDetailsSection);

export const NextDryDock: FC = props => {
  return (
    <Wrapper {...props}>
      {({onChange, onChangeRangePicker, values, validations}: WrapperProps<DryDockFields, DryDockValues>) => {
        return (
          <div className="container-fluid">
            <div className="row">
              <div className="scol-12 scol-sm-8">
                <LabelWrapper
                  label={'Period from/to'}
                  htmlFor={`dryDockFrom dryDockTo`}
                  hasError={validations.dryDockFrom.invalid}>
                  <StyledRangePicker
                    id={'filter-dateopen-date filter-dateopen-date-to'}
                    format={DateTimeFormat.Date}
                    value={[values.dryDockFrom, values.dryDockTo]}
                    getPopupContainer={() => {
                      return document.getElementById('nextDockFromTo')!;
                    }}
                    placeholder={['From date', 'To date']}
                    onChange={value => {
                      const [from, to] = (value ?? [null, null]).values();
                      onChangeRangePicker({
                        dryDockFrom: from,
                        dryDockTo: to,
                      });
                    }}
                  />
                  <div id="nextDockFromTo" />
                </LabelWrapper>
              </div>

              <div className="scol-4">
                <LabelWrapper label={'Note'} htmlFor={`dryDockNote`}>
                  <Input
                    id={'dryDockNote'}
                    value={values.dryDockNote}
                    onChange={value => {
                      onChange('dryDockNote', value);
                    }}
                  />
                </LabelWrapper>
              </div>
            </div>{' '}
            <div className="scol-12">
              {validations.dryDockFrom.error && (
                <p className="text-danger text-danger--no-margin">{validations.dryDockFrom.error}</p>
              )}
            </div>
          </div>
        );
      }}
    </Wrapper>
  );
};

export default NextDryDock;
