import {useSelector} from '../../../../../../redux/react-redux';
import {OnMapElementChange} from '../../../utils/utils';

export const useBackToLastPort = (onMapElementChange?: OnMapElementChange) => {
  const lastPort = useSelector(({mapDetails}) => mapDetails.lastPort!);

  const coordinates = lastPort?.coordinate ?? lastPort?.lngLat;

  if (!coordinates) {
    return () => {};
  }

  return () =>
    onMapElementChange?.({
      ...lastPort.object,
      type: 'port',
      longitude: coordinates[0],
      latitude: coordinates[1],
    });
};
