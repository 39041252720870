import {FC, useState} from 'react';
import styled from 'styled-components';
import {ChokepointKey} from '../ChokepointCongestionScreen/utils/useChokepointCongestionQuery';
import {ChokepointCongestionCard} from './ChokepointCongestionCard';
import {PortKey} from '../PortCongestionScreen/utils/usePortCongestionQuery';
import {PortCongestionCard} from './PortCongestionCard';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';

export const CongestionOverviewScreen: FC = () => {
  const [viewKey, setViewKey] = useState<'ports' | 'chokepoints'>('chokepoints');

  return (
    <div className={'congestion-reports'}>
      <ScreenHeader
        helmetTitle="Congestion Reports"
        breadcrumbs={[{title: 'Lab'}, {title: 'Congestion Reports'}]}
        features={
          <ScreenHeader.Features.Toggle
            data-cy="CongestionViewToggle"
            value={viewKey}
            onChange={event => setViewKey(event.target.value)}
            options={[
              {
                id: 'show-chokepoints',
                label: 'Chokepoints',
                value: 'chokepoints',
                props: {
                  'data-testid': 'CongestionShowChokepointsBTN',
                },
              },
              {
                id: 'show-ports',
                label: 'Ports',
                value: 'ports',
                props: {
                  'data-testid': 'CongestionShowPortsBTN',
                },
              },
            ]}
          />
        }
      />
      <p>
        Automated congestion analyses for known chokepoints from aggregated seabo data of the last 48h. <br />
      </p>
      {viewKey === 'chokepoints' && (
        <CongestionReportsContainer key={'chokepoints'} style={{width: '100%'}}>
          {Object.values(ChokepointKey).map(chokepointKey => (
            <ChokepointCongestionCard key={chokepointKey} chokepointKey={chokepointKey} />
          ))}
        </CongestionReportsContainer>
      )}
      {viewKey === 'ports' && (
        <CongestionReportsContainer data-testid={'portsTab'} key={'ports'} style={{width: '100%'}}>
          {Object.values(PortKey).map(portKey => (
            <PortCongestionCard key={portKey} portKey={portKey} />
          ))}
        </CongestionReportsContainer>
      )}
    </div>
  );
};

const CongestionReportsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`;
