import {FC} from 'react';
import Title from '../../../../atoms/Title';
import {aisVesselExpectedGrid} from '../../../../components/GridLayout';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {PortsActions} from '../../../../redux/Ports';
import produce from 'immer';
import Lighthouse from '../../../../atoms/EmptyState/LightHouse';
import {usePortExpectedVesselsQuery} from '../usePortExpectedVesselsQuery';
import {useLocalStorage} from '../../../../utils/useLocalStorage';
import {DataGrid} from '../../../../components/DataGrid/DataGrid';

export const VesselsExpectedSection: FC<{portCode: string}> = ({portCode}) => {
  const [, setLocalStoragePageSize] = useLocalStorage('grid_page_size-port_details_expected_vessels', 50);
  const expectedVesselsGrid = useSelector(state => state.ports.expectedVesselsGrid);

  const expectedVesselsQuery = usePortExpectedVesselsQuery({portCode});

  const dispatch = useDispatch();

  return (
    <div className="port-screen__grid-expected">
      <Title medium style={{marginTop: 20, marginBottom: 10}}>
        Vessels expected
      </Title>
      <DataGrid
        className={'datagrid-expected-vessels'}
        columns={aisVesselExpectedGrid}
        data={expectedVesselsQuery.data}
        totalCount={expectedVesselsQuery.data?.length ?? 0}
        loading={expectedVesselsQuery.isLoading}
        TbodyComponent={
          expectedVesselsQuery.data?.length ? null : <Lighthouse headline={'No vessels expected in port'} />
        }
        zeroBasedPageIndex={false}
        responsive
        manualPagination={false}
        manualSorting={false}
        page={expectedVesselsGrid.page}
        pageSize={expectedVesselsGrid.pageSize}
        sorted={expectedVesselsGrid.sorted}
        onPageChange={page => {
          dispatch(
            PortsActions.setExpectedGridState(
              produce(expectedVesselsGrid, draftGridState => {
                draftGridState.page = page;
              })
            )
          );
        }}
        onPageSizeChange={pageSize => {
          setLocalStoragePageSize(pageSize);
          dispatch(
            PortsActions.setExpectedGridState(
              produce(expectedVesselsGrid, draftGridState => {
                draftGridState.pageSize = pageSize;
                draftGridState.page = 1;
              })
            )
          );
        }}
        onSortedChange={sorted => {
          dispatch(
            PortsActions.setExpectedGridState(
              produce(expectedVesselsGrid, draftGridState => {
                draftGridState.sorted = [sorted];
                draftGridState.page = 1;
              })
            )
          );
        }}
      />
    </div>
  );
};
