import {useDispatch} from 'react-redux';
import PortfolioService from '../../../redux/ApiService/PortfolioService';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import omit from 'lodash/omit';
import cargoService from '../../../redux/ApiService/cargoService';
import {ApiError} from '../../../api/utils/ApiError';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';

type CloneParams = {
  cargo: Cargo;
  workspaceId?: number;
  subPortfolioIds?: number[];
};

type Cargo = PortfolioCargo;

export const useCloneCargoToMyCargoesMutation = (
  options: UseMutationOptions<PortfolioCargo, ApiError, CloneParams>
) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({cargo, workspaceId, subPortfolioIds}: CloneParams) => {
      const clonedCargo = getCargoWithoutIdButOrginalCargoId(cargo);
      const newCargo = await dispatch(cargoService.createCargo(clonedCargo));

      if (workspaceId !== undefined) {
        await dispatch(cargoService.workspaceUpdate(newCargo.id, {workspaceID: workspaceId}));
        if (subPortfolioIds && subPortfolioIds.length > 0) {
          await dispatch(PortfolioService.assignToGroups('cargo', newCargo.id, subPortfolioIds));
        }
      }

      return newCargo;
    },
    ...options,
  });
};

function getCargoWithoutIdButOrginalCargoId(cargo: Cargo) {
  const copy = omit(cargo, 'id');
  copy.originCargoId = cargo.id;
  return copy;
}
