import {filterApi} from '../../api/symfony/symfonyApi';
import {FilterItem, GetFiltersRequest} from '../../api/symfony/generated';
import {QueryClient, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {ApiError} from '../../api/utils/ApiError';

const QUERY_KEY = 'filterApi.getFilters';

/**
 * Loads filters from the API.
 */

export const useFiltersQuery = (request: GetFiltersRequest, options?: UseQueryOptions<FilterItem[], ApiError>) =>
  useQuery({
    queryKey: [QUERY_KEY, request],
    queryFn: async () => {
      const response = await filterApi.getFilters(request);
      return response.items;
    },
    ...options,
  });

export const invalidFiltersQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [QUERY_KEY],
  });
};
