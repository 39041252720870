import {message} from 'antd';
import {ProfilePictureForm} from './ProfilePictureForm';
import {ProfilePictureFormValues} from './ProfilePictureFormValues';
import {useRemoveProfilePictureMutation} from './useRemoveProfilePictureMutation';
import {useQueryClient} from '@tanstack/react-query';
import User from '../../../model/User';
import {invalidateCurrentUserQuery} from '../../../queries/useCurrentUserQuery';
import {Helmet} from 'react-helmet-async';
import {showNotification} from '../../../utils/notification';
import {useUser} from '../../../components/UserContext/useUser';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';

export const ProfilePicture = () => {
  const user: User = useUser();

  const queryClient = useQueryClient();

  // The mutation that removes the profile picture
  const removePictureMutation = useRemoveProfilePictureMutation({
    onSuccess: () => {
      // Reload profile data
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateCurrentUserQuery(queryClient);
      showNotification('Profile picture was removed.');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateCurrentUserQuery(queryClient);
    },
    onSettled: async () => {
      await invalidateCurrentUserQuery(queryClient);
    },
  });

  const currentValues: ProfilePictureFormValues = getCurrentValues(user);

  if (removePictureMutation.isError) {
    // eslint-disable-next-line no-console
    console.log('mutationResult.error', removePictureMutation.error);
  }

  return (
    <>
      <Helmet title="Profile picture - Account management" />
      <div className="ProfilePicture">
        <SettingsCard>
          <SettingsCardHeader title={'Profile picture'} />
          <SettingsCardBody>
            <ProfilePictureForm
              currentValues={currentValues}
              removing={removePictureMutation.isPending}
              onChange={info => {
                if (info.file.status === 'done') {
                  showNotification(`"${info.file.name}" file uploaded successfully`);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  invalidateCurrentUserQuery(queryClient);
                } else if (info.file.status === 'error') {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.error(`${info.file.name} file upload failed.`);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  invalidateCurrentUserQuery(queryClient);
                }
              }}
              onRemove={removePictureMutation.mutate}
            />
          </SettingsCardBody>
        </SettingsCard>
      </div>
    </>
  );
};

const getCurrentValues = (user: User | null): ProfilePictureFormValues => {
  if (!user) {
    return {
      avatar: null,
    };
  }

  return {
    avatar: user.avatar,
  };
};
