import {FC} from 'react';
import {Select} from '../../../../../../antd/components/Select';

export const GearedPicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  return (
    <Select
      allowClear={true}
      value={value}
      onChange={onChange}
      options={[
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ]}
    />
  );
};
