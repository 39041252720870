export const getVesselTypeFromSpire = (spireVesselType: string | null): 'bulker' | 'container' | 'mpp' => {
  switch (spireVesselType) {
    case 'Bulk Carrier':
    case 'General Dry Cargo':
      return 'bulker';

    case 'Container Ship':
    case 'Container':
      return 'container';
    default:
      return 'mpp';
  }
};
