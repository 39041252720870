import {FC} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useSelector} from '../../../redux/react-redux';
import {getSubscriptionPlanName} from '../../../redux/selectors';
import './style.scss';

export const CompanySubscriptionStatus: FC = () => {
  const subscriptionPlanName = useSelector(getSubscriptionPlanName);

  return (
    <Link to={`/subscription`}>
      <SubscriptionStatusStyled>{subscriptionPlanName}</SubscriptionStatusStyled>
    </Link>
  );
};

const SubscriptionStatusStyled = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--color-blue);
  overflow: hidden;
  border-radius: var(--border-radius-lg);
  color: var(--color-gray-6);
  padding: 10px 40px;
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
`;
