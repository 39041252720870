import Flag from '../../atoms/Flags';
import {PortLocation} from '../LocationOutput/PortLocation';
import {Link} from 'react-router-dom';
import {numberUnitFormat} from '../../utils/formatter';
import {percentCalc} from '../../utils/helper';
import {UserDateTime} from '../DateTime/UserDateTime';
import {CustomColumnDef} from '../DataGrid/DataGrid';
import {sortingFnForStrings} from './utils/sortingFnForStrings';
import {getFormattedVesselType} from './getFormattedVesselType';

interface Vessel {
  imo?: number;
  mmsi?: number;
  name: string;
  country?: string | null;
  vesselId?: number | null;
  class?: string | null;
  vesselType?: string | null;

  destinationPort?: {
    name: string;
  };
  destination?: string;

  eta?: string;
  speed: number | null;
  speedMax: number | null;
}

export const aisVesselNameWithTypeColumn: CustomColumnDef<Vessel> = {
  header: 'Vessel name',
  id: 'name',
  cell: info => info.getValue(),
  accessorFn: row => (
    <div data-sort={row.name}>
      {row.vesselId ? (
        <span>
          <Link to={`/vessel/${row.vesselId}`}>{row.name}</Link>
        </span>
      ) : (
        <span>{row.name || '-'}</span>
      )}
      <div style={{display: 'flex', alignItems: 'center'}}>
        {row.country && (
          <span style={{marginRight: 5, marginBottom: 2}}>
            <Flag countryCode={row.country} shadow width={13} height={10} />
          </span>
        )}
        &nbsp;&nbsp;
        <span style={{color: 'var(--color-gray-2)'}}>{getFormattedVesselType(row.class ?? row.vesselType)}</span>
      </div>
    </div>
  ),
  minWidth: 4,
  enableSorting: true,
  sortingFn: (a, b) => {
    const aName = a.original.name;
    const bName = b.original.name;

    return sortingFnForStrings(aName, bName);
  },
};

export const aisVesselImoMmsiColumn: CustomColumnDef<Vessel> = {
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        IMO
        <br />
        MMSI
      </div>
    </div>
  ),
  id: 'imo',
  cell: info => info.getValue(),
  accessorFn: row => (
    <div data-sort={row.imo}>
      <div>{row.imo}</div>
      <div>{row.mmsi}</div>
    </div>
  ),
  sortingFn: (a, b) => {
    if (!!a.original.imo && !!b.original.imo) {
      return a.original.imo - b.original.imo;
    }
    if (!!a.original.mmsi && !!b.original.mmsi) {
      return a.original.mmsi - b.original.mmsi;
    }
    return 0;
  },
  minWidth: 2,
  enableSorting: true,
};

export const aisVesselDestinationColumn: CustomColumnDef<Vessel> = {
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        AIS Destination (real)
        <br />
        AIS Destination (mapped)
      </div>
    </div>
  ),
  id: 'destination',
  cell: info => info.getValue(),
  accessorFn: row => (
    <div data-sort={row.destinationPort ? row.destinationPort.name : row.destination}>
      <div>{row.destination}</div>
      <div>{row.destinationPort ? <PortLocation location={row.destinationPort} /> : '-'}</div>
    </div>
  ),
  sortingFn: (a, b) => {
    const aDestination = a.original.destinationPort?.name ?? a.original.destination;
    const bDestination = b.original.destinationPort?.name ?? b.original.destination;

    return sortingFnForStrings(aDestination, bDestination);
  },
  minWidth: 6,
  enableSorting: true,
};

export const aisVesselEtaColumn: CustomColumnDef<Vessel> = {
  header: 'AIS ETA',
  id: 'eta',
  cell: info => info.getValue(),
  accessorFn: row => (
    <span data-sort={row.eta}>{row.eta ? <UserDateTime value={row.eta} format={'dateTime'} /> : '-'}</span>
  ),
  sortingFn: (a, b) => {
    const aEta = a.original.eta;
    const bEta = b.original.eta;

    return sortingFnForStrings(aEta, bEta);
  },
  minWidth: 2,
  enableSorting: true,
};

export const aisVesselSpeedColumn: CustomColumnDef<Vessel> = {
  header: () => (
    <div className="datagrid-sortable-header">
      <div>
        AIS reported speed
        <br />% of max rep. speed
      </div>
    </div>
  ),
  id: 'speed',
  cell: info => info.getValue(),
  accessorFn: row => (
    <div data-sort={row.speed}>
      <div>{numberUnitFormat(row.speed, 'knots', {toUpperCase: false, emptyString: '-'})}</div>
      <div>
        {numberUnitFormat(percentCalc(row.speed, row.speedMax), '%', {
          toUpperCase: false,
          emptyString: '-',
        })}
      </div>
    </div>
  ),
  minWidth: 3,
  sortingFn: (a, b) => {
    return (a.original.speed ?? 0) - (b.original.speed ?? 0);
  },
  enableSorting: true,
};
