import {isTest} from '../../utils/environment';

let ajaxCsrfToken: string | null = null;

export const setAjaxCsrfToken = (ajaxCsrfToken_: string | null) => {
  ajaxCsrfToken = ajaxCsrfToken_;
};

/**
 * Returns the API token.
 */
export const getAjaxCsrfTokenOrFail = (): string => {
  const token = getAjaxCsrfToken();
  if (!token) {
    throw new Error('Missing ajaxCsrfToken token');
  }
  return token;
};

/**
 * Returns the API token or null.
 */
export const getAjaxCsrfToken = (): string | null => {
  if (isTest()) {
    return 'csrf-token-for-test';
  }
  return ajaxCsrfToken;
};

/**
 * Returns the fetch headers that our API commonly uses.
 */
export const getFetchHeaders = () => {
  const apiToken = getAjaxCsrfTokenOrFail();
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Ajax-Csrf-Token': apiToken,
  };
  return headers;
};
