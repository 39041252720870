import {DatePicker} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import React, {FC} from 'react';
import {DateTimeFormat} from '../../../../../../utils/DateTimeFormat';
import {VoyageTimeSeriesEntry} from '../../../utils/calculateVoyageTimeSeries';
import {VoyageTimelineDraftScrollbar} from './VoyageTimelineDraftScrollbar';

type TimelineDateControlProps = {
  voyageTimeSeries: VoyageTimeSeriesEntry[];
  minDate: Dayjs;
  selectedDate?: Dayjs;
  onDateChange: (selectedDate: Dayjs) => void;
};

export const TimelineDateControl: FC<TimelineDateControlProps> = ({
  voyageTimeSeries,
  minDate,
  selectedDate,
  onDateChange,
}) => {
  const handlePickerDateChange = (date: Dayjs | null) => {
    if (!date) {
      return onDateChange(minDate);
    }
    onDateChange(date);
  };
  return (
    <>
      <DatePicker
        value={selectedDate}
        onChange={handlePickerDateChange}
        allowClear={false}
        format={DateTimeFormat.Date}
        disabledDate={date => date.isBefore(minDate) || date.isAfter(dayjs())}
        style={{flexShrink: 0, width: 120}}
      />
      <VoyageTimelineDraftScrollbar
        voyageTimeSeries={voyageTimeSeries ?? []}
        selectedDate={selectedDate}
        onChangeSelectedDate={onDateChange}
      />
    </>
  );
};
