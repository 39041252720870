import {VoyagePoint} from '../../../VoyageTypes';
import {NotApplicable} from '../../../utils/NotApplicable';

type Props = {
  point: VoyagePoint;
};
export const Terms = (props: Props) => {
  const isLoading = props.point.types?.includes('loading');
  const isDischarge = props.point.types?.includes('discharge');
  const active = isLoading || isDischarge;

  if (!active) {
    return <NotApplicable />;
  }

  const rate = isLoading ? props.point.loadingRate : props.point.dischargingRate;
  const terms = isLoading ? props.point.termsLoading : props.point.termsDischarging;

  return (
    <span>
      {rate} pwwd / {terms}
    </span>
  );
};
