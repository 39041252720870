import {ChangeEvent, Fragment, useEffect, useState, FC} from 'react';
import BaseModal from '../../../atoms/BaseModal';
import ProjectService, {ProjectType} from '../../../redux/ApiService/ProjectService';
import {actions as PortfolioService} from '../../../redux/ApiService/PortfolioService';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import CreateNewProject, {Color} from './CreateNewProject';
import ToggleableButtonGroup from '../../ButtonGroup/ToggleableButtonGroup';
import ButtonGroupButton from '../../ButtonGroup/ButtonGroupButton';
import {showNotification} from '../../../utils/notification';
import {useDispatch} from '../../../redux/react-redux';
import styled from 'styled-components';
import {TODO} from '../../../utils/TODO';
import {Project} from '../../../components/CharterCalculation/MultiCalc/types';
import {useAddTypeToProjectMutation} from './useAddTypeToProjectMutation';
import {useCreateProjectMutation} from './useCreateProjectMutation';
import Select from '../../../atoms/Select/Select';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {Button} from 'antd';

interface AddToProjectModalProps {
  type: string;
  id: number;
  close?: () => void;
}

const AddToProjectModal: FC<AddToProjectModalProps> = ({type, id, close}) => {
  const [addToProjectModalState, setAddToProjectModalState] = useState({
    projects: [],
    portfolios: [],
    tab: 'add',
    selectedProject: null,
    newProjectName: '',
    newProjectColour: {name: '', value: ''},
    newProjectComment: '',
    loading: true,
    error: false,
    errorMessage: '',
    buttonDisabled: true,
  });

  const dispatch = useDispatch();
  const isVessel: boolean = type === 'vessel';
  const createProjectMutation = useCreateProjectMutation({
    onSuccess: data => {
      showNotification('Project created');
      setAddToProjectModalState(prevState => ({...prevState, selectedProject: data}));
      addToProject(data);
    },
  });
  const addTypeToProject = useAddTypeToProjectMutation({
    onSuccess: () => {
      showNotification('Item added to a project');
      close?.();
    },
    onError: e => {
      if (e.payload && e.payload.error && e.payload.error.message) {
        setAddToProjectModalState(prevState => ({...prevState, error: true, errorMessage: e.payload.error.message}));
      }
    },
  });

  const {selectedProject, tab, newProjectColour, newProjectName, newProjectComment, loading, error} =
    addToProjectModalState;

  useEffect(() => {
    const getAllProjects = (type: ProjectType) => dispatch(ProjectService.getAllProjects(undefined, undefined, type));
    const getAllData = (type: ProjectType) => dispatch(PortfolioService.getSubData({params: {type}}));
    const typeSelected: ProjectType = type === 'vessel' ? 'cargo' : 'vessel';
    Promise.all([getAllData(typeSelected), getAllProjects(typeSelected)])
      .then(responses => {
        setAddToProjectModalState(prevState => ({
          ...prevState,
          portfolios: responses[0].data.items,
          projects: responses[1].data.items,
          loading: false,
        }));
      })
      .catch(() => {
        setAddToProjectModalState(prevState => ({...prevState, loading: false, error: true}));
      });
  }, [dispatch, type]);

  const addToProject = (project: Project) => {
    const newTypeForProject = isVessel ? 'vessels' : 'cargoes';
    addTypeToProject.mutate({id: project.id, types: newTypeForProject, body: [id]});
  };

  const onClickNewProject = () => {
    const {newProjectColour, newProjectName, newProjectComment} = addToProjectModalState;
    const projectType = !isVessel ? 'cargo' : 'vessel';

    createProjectMutation.mutate({
      [projectType]: id,
      color: newProjectColour.value,
      comment: newProjectComment,
      name: newProjectName,
      type: projectType,
    });
  };
  const handleOnChange = (tab: string) => {
    setAddToProjectModalState(prevState => ({...prevState, tab}));
  };

  if (error) {
    return (
      <StyledAddToProjectCentered>
        <div>{addToProjectModalState.errorMessage}</div>
        <Button
          type="primary"
          onClick={() => setAddToProjectModalState(prevState => ({...prevState, error: false, errorMessage: ''}))}>
          OK
        </Button>
      </StyledAddToProjectCentered>
    );
  }

  return (
    <StyledAddToProject>
      <ToggleableButtonGroup onChange={value => handleOnChange(value.toString())} defaultValue={'add'}>
        <ButtonGroupButton id="add-to-project" label={'Add to project'} value={'add'} />
        <ButtonGroupButton id="new-project" label={'New project'} value={'update'} />
      </ToggleableButtonGroup>

      {tab === 'add' ? (
        <Fragment>
          <LabelWrapper spMT={'m'} label={'Project'} style={{marginBottom: -16}}>
            <Select
              id={'project-selection'}
              value={selectedProject}
              placeholder={'Select a project'}
              onChange={(option: TODO) => {
                setAddToProjectModalState(prevState => ({
                  ...prevState,
                  selectedProject: option,
                  buttonDisabled: false,
                }));
              }}
              getOptionLabel={(option: TODO) => option.name}
              getOptionValue={(option: TODO) => option.id}
              options={addToProjectModalState.projects}
              components={{
                DropdownIndicator: ArrowIndicator,
              }}
              cacheOptions
              defaultOptions
              isSearchable={true}
              isLoading={loading}
              isClearable
            />
          </LabelWrapper>

          <ModalFooter>
            <Button
              key={'ok'}
              disabled={addToProjectModalState.buttonDisabled}
              type="primary"
              onClick={() => addToProject(selectedProject!)}>
              ADD TO PROJECT
            </Button>
          </ModalFooter>
        </Fragment>
      ) : (
        <CreateNewProject
          disabled={createProjectMutation.isPending || createProjectMutation.isSuccess}
          type={type}
          newProjectName={newProjectName}
          newProjectComment={newProjectComment}
          newProjectColour={newProjectColour}
          onChangeProjectName={(newProjectName: string) =>
            setAddToProjectModalState(prevState => ({...prevState, newProjectName}))
          }
          onChangeComment={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setAddToProjectModalState(prevState => ({...prevState, newProjectComment: e.target.value}))
          }
          onChangeColour={(option: Color) =>
            setAddToProjectModalState(prevState => ({...prevState, newProjectColour: option}))
          }
          onClickNewProject={onClickNewProject}
        />
      )}
    </StyledAddToProject>
  );
};

export const AddToProject = (props: AddToProjectModalProps) => {
  return (
    <BaseModal middle title={'Add to project'}>
      {modalProps => <AddToProjectModal {...props} {...modalProps} />}
    </BaseModal>
  );
};

const StyledAddToProjectCentered = styled.div`
  text-align: center;
`;

const StyledAddToProject = styled.div`
  text-align: left;
`;
