import {Value} from '../Definitions/Value';
import {durationAccuracy} from './visualizationAccuracy';
import {CalcTimeLinePoint, CalcTimeLineRoute} from '../VoyageTypes';
import {DurationExplainTooltip} from '../Timeline/DurationExplainTooltip/DurationExplainTooltip';

export const DurationCell = (props: {point: CalcTimeLinePoint} | {route: CalcTimeLineRoute}) => {
  const isPoint = 'point' in props;

  const outputItem = isPoint ? props.point.outputItem : props.route.outputItem;

  const point = isPoint ? props.point : undefined;
  const route = !isPoint ? props.route : undefined;
  return (
    <Value calculated>
      <DurationExplainTooltip point={point!} route={route!}>
        <span data-cy="duration">{outputItem!.duration.toFixed(durationAccuracy)} days</span>
      </DurationExplainTooltip>
    </Value>
  );
};
