import {useNavigate} from 'react-router-dom';
import {assertUnreachable} from './assert';
import {isTest} from './environment';

interface GoToUrlOptions {
  mode?: GoToUrlMode;
}

export type GoToUrlMode = 'PushIfPossible' | 'PageLoad' | 'Push';

/**
 * A hook that returns a function to change the browser URL.
 */
export const useGoToUrl = () => {
  const changeBrowserUrl = useChangeBrowserUrl();
  const pushUrl = usePushUrl();

  const goToUrl = (url: string, options?: GoToUrlOptions) => {
    const mode = options?.mode ?? 'PushIfPossible';
    const isRelative = !url.startsWith('http');
    const isWoltlab = url.includes('/scf/');

    switch (mode) {
      case 'PushIfPossible':
        if (isRelative && !isWoltlab) {
          pushUrl(url);
        } else {
          changeBrowserUrl(url);
        }
        break;
      case 'PageLoad':
        changeBrowserUrl(url);
        break;
      case 'Push':
        pushUrl(url);
        break;
      default: {
        assertUnreachable(mode);
      }
    }
  };

  return goToUrl;
};

/**
 * A hook that returns a function to change the browser URL.
 * In a real browser that function will cause a page load.
 * In JsDom it will still work with react-router.
 */
export const useChangeBrowserUrl = () => {
  const pushUrl = usePushUrl();
  const changeBrowserUrl = (url: string) => {
    if (isTest()) {
      // This works in tests
      pushUrl(url);
    } else {
      // This works in a real browser only
      window.location.href = url;
    }
  };
  return changeBrowserUrl;
};

/**
 * A hook that returns a function to change the browser URL by manipulating the history.
 */
export const usePushUrl = () => {
  const navigate = useNavigate();
  const pushUrl = (url: string) => {
    navigate(url);
  };
  return pushUrl;
};
