/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CreateNotificationRequestEventEnum {
  CompanyWorkspaceUserAdded = 'company.workspace.user.added',
  CompanyWorkspaceUserRemoved = 'company.workspace.user.removed',
  MarketCargoShare = 'market.cargo.share',
  MarketVesselShare = 'market.vessel.share',
  SocialConnect = 'social.connect',
  Negotiation = 'negotiation',
}

export function CreateNotificationRequestEventEnumFromJSON(json: any): CreateNotificationRequestEventEnum {
  return CreateNotificationRequestEventEnumFromJSONTyped(json, false);
}

export function CreateNotificationRequestEventEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNotificationRequestEventEnum {
  return json as CreateNotificationRequestEventEnum;
}

export function CreateNotificationRequestEventEnumToJSON(value?: CreateNotificationRequestEventEnum | null): any {
  return value as any;
}
