import {ConsumptionFuelType} from './getUsedFuelType';
import {assertUnreachable} from '../../../../utils/assert';

const calculateCO2EmissionFactor = ({fuelType}: {fuelType: ConsumptionFuelType}): number => {
  switch (fuelType) {
    case 'vlsfo':
      return 3.151;
    case 'ifo':
      return 3.114;
    case 'mgo':
      return 3.206;
    default:
      assertUnreachable(fuelType);
  }
};

export const calculateCO2Emission = ({consumption, fuelType}: {fuelType: ConsumptionFuelType; consumption: number}) => {
  return calculateCO2EmissionFactor({fuelType}) * consumption;
};
