interface Filter {
  maxDistanceInKM: number;
}

export const airlineDistanceFilter = <Vessel extends {airlineDistanceInMeters: number}>({
  vessels,
  filter,
}: {
  filter: Filter;
  vessels: Vessel[];
}) => {
  return vessels.filter(vessel => {
    if (vessel.airlineDistanceInMeters / 1000 > filter.maxDistanceInKM) {
      return false;
    }
    return true;
  });
};
