import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {Tag} from '../../../../api/symfony/generated/models';
import {tagApi} from '../../../../api/symfony/symfonyApi';
import {ApiError} from '../../../../api/utils/ApiError';
import {UpdateTagRequest} from '../../../../api/symfony/generated';

export const useUpdateTagMutation = (options: UseMutationOptions<Tag, ApiError, UpdateTagRequest>) => {
  return useMutation<Tag, ApiError, UpdateTagRequest>({
    mutationFn: async (request: UpdateTagRequest) => tagApi.updateTag(request),
    ...options,
  });
};
