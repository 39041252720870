import React, {FC} from 'react';
import {Checkbox} from 'antd';
import styled from 'styled-components';
import {NegotiationActor} from '../../../../../../api/node-backend/generated';
import {UserDateTime} from '../../../../../../components/DateTime/UserDateTime';
import {NegotiationActorLabel} from '../../../../Components/NegotiationActorLabel';

type HistoryDetailHeaderProps = {
  committedBy?: NegotiationActor;
  committedAt: Date;
  hideUnchanged: boolean;
  hideSettled: boolean;
  onToggleHideUnchanged: () => void;
  onToggleHideSettled: () => void;
  onDismissClick: () => void;
};

export const HistoryDetailHeader: FC<HistoryDetailHeaderProps> = ({
  committedBy,
  committedAt,
  hideUnchanged,
  hideSettled,
  onToggleHideUnchanged,
  onToggleHideSettled,
}) => (
  <Headline>
    {committedBy ? (
      <span>
        Round by <NegotiationActorLabel actor={committedBy} /> -{' '}
        <UserDateTime value={new Date(committedAt).toISOString()} format="dateTime" />
      </span>
    ) : (
      <span>Current status</span>
    )}
    <div>
      <Checkbox onClick={onToggleHideUnchanged} checked={hideUnchanged}>
        Hide unchanged
      </Checkbox>
      <Checkbox onClick={onToggleHideSettled} checked={hideSettled}>
        Hide settled
      </Checkbox>
    </div>
  </Headline>
);

const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 10px;
  gap: 10px;

  button {
    margin-left: 10px;
  }
`;
