import {Trip} from './Trip';
import {TripsLayer} from '@deck.gl/geo-layers/typed';
import {Color, interpolateColor} from '../../utils/interpolateColor';
import {LonLatCoordinates} from '../../../../../../utils/Coordinates';

const COLOR_SPEED_0: Color = [245, 226, 49];
const COLOR_MAX_SPEED_SPEED: Color = [244, 83, 65];
// const BLUE = [98, 172, 249];

export const useTripsLayer = (timestamp: number, trips: Trip[], visible: boolean, speed: number) => {
  const color = colorForSpeed(speed, trips[0]);

  return new TripsLayer<Trip>({
    id: 'trips-layer',
    data: trips,
    visible: visible,
    getPath: (trip: Trip): LonLatCoordinates[] => trip.coordinates,
    getTimestamps: (trip: Trip): number[] => trip.timestamps,
    getColor: color,
    opacity: 0.8,
    widthMinPixels: 5,
    jointRounded: true,
    fadeTrail: true,
    trailLength: 300000000,
    currentTime: timestamp,
    transitions: {
      getColor: {
        duration: 200,
      },
    },
  });
};

const colorForSpeed = (speed: number, trip: Trip): Color => {
  const maxSpeed = trip.maxSpeed;
  const percentOfMaxSpeed = speed / maxSpeed;
  // const color = percentOfMaxSpeed > 0.5 ? COLOR_MAX_SPEED_SPEED : COLOR_SPEED_0;
  // console.log(
  //   percentOfMaxSpeed,
  //   "color",
  //   color,
  //   "speed",
  //   speed,
  //   "maxSpeed",
  //   maxSpeed,
  // );
  const color = interpolateColor(COLOR_SPEED_0, COLOR_MAX_SPEED_SPEED, percentOfMaxSpeed);
  return color;
};
