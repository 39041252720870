import {VoyageCharter} from '../../components/CharterCalculation/Voyagecharter/VoyageCharter';
import {useLocation} from 'react-router-dom';

export const VoyageCharterScreen = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const id = urlParams.get('id');
  const projectId = urlParams.get('project_id');
  const vesselId = urlParams.get('vessel_id');
  const cargoesIds = urlParams.get('cargoes_ids');

  return (
    <VoyageCharter
      id={id ? parseInt(id) : undefined}
      projectId={projectId ? parseInt(projectId) : undefined}
      vesselId={vesselId ? parseInt(vesselId) : undefined}
      cargoesIds={cargoesIds ? [parseInt(cargoesIds)] : undefined}
    />
  );
};

export default VoyageCharterScreen;
