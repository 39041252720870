import React, {FC, useState} from 'react';
import {$createPlaceholderNode, $isPlaceholderNode, Placeholder} from './nodes/PlaceholderNode';
import styled from 'styled-components';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getSelection} from 'lexical';
import {NegotiationTermType} from '../../../../../api/node-backend/generated';
import {useLexicalEditorFocusEvents} from './utils/useLexicalEditorFocusEvents';
import {NegotiationPlaceholderKey, negotiationPlaceholderKeys} from '../../utils/placeholderKeys';
import last from 'lodash/last';
import {$isTermTypeNode} from './nodes/TermTypeNode';

type PlaceholderBarProps = {
  termType: NegotiationTermType | null;
};

/**
 * Displays a list of placeholder-suggestions that can be directly inserted at the current cursor position.
 *
 * Note: We auto-add spaces around the placeholders inserted through this component for readability.
 */
export const PlaceholderBar: FC<PlaceholderBarProps> = ({termType}) => {
  const [editor] = useLexicalComposerContext();
  const [isVisible, setIsVisible] = useState(false);

  useLexicalEditorFocusEvents({
    onFocus: () => setIsVisible(true),
    onBlur: () => setIsVisible(false),
  });

  // Our premise is that placeholders are primarily used as part of sentences (surrounded by spaces)
  // If the user clicks on a placeholder-suggestion, we insert spaces where necessary
  const onClickPlaceholder = (placeholderKey: NegotiationPlaceholderKey) => {
    editor.update(() => {
      if ($isPlaceholderNode(last($getSelection()?.getNodes()))) {
        $getSelection()?.insertText(' ');
      }
      $getSelection()?.insertNodes([$createPlaceholderNode(placeholderKey)]);
      $getSelection()?.insertText(' ');
    });
  };

  const addPlaceholderBracket = () => {
    editor.update(() => {
      const lastNode = last($getSelection()?.getNodes());
      if ($isPlaceholderNode(lastNode) || $isTermTypeNode(lastNode)) {
        $getSelection()?.insertText(' ');
      }
      $getSelection()?.insertText('{');
    });
  };

  const placeholderSuggestions = getPlaceholderSuggestions(termType);

  return (
    <PlaceholderBarContainer $isVisible={isVisible}>
      Suggestions:
      {placeholderSuggestions.map(([placeholderKey, label]) => (
        <Placeholder
          key={placeholderKey}
          $isWide
          onClick={() => onClickPlaceholder(placeholderKey as NegotiationPlaceholderKey)}>
          {label}
        </Placeholder>
      ))}
      {placeholderSuggestions.length > 0 && (
        <Placeholder $isSelected $isWide onClick={addPlaceholderBracket}>
          + add new
        </Placeholder>
      )}
    </PlaceholderBarContainer>
  );
};

export const getPlaceholderSuggestions = (termType: NegotiationTermType | null, maxItems = 10) => {
  return Object.entries(negotiationPlaceholderKeys)
    .filter(([placeholderKey]) => {
      if (!termType) {
        return true;
      }
      if (placeholderKey === 'PLACEHOLDER') {
        return true;
      }
      switch (termType) {
        case NegotiationTermType.Account:
        case NegotiationTermType.Owners:
        case NegotiationTermType.Brokerage:
          return placeholderKey.includes('creator') || placeholderKey.includes('counterpart');
        case NegotiationTermType.Vessel:
          return placeholderKey.includes('vessel');
        case NegotiationTermType.Cargo:
          return placeholderKey.includes('cargo');
        case NegotiationTermType.Laycan:
          return placeholderKey.includes('laycan');
        case NegotiationTermType.Redelivery:
        case NegotiationTermType.DischargePort:
          return placeholderKey.includes('discharge');
        case NegotiationTermType.Delivery:
        case NegotiationTermType.LoadPort:
          return placeholderKey.includes('loading');
        default:
          return true;
      }
    })
    .slice(0, maxItems);
};

const PlaceholderBarContainer = styled.span<{$isVisible?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid var(--color-self);
  font-size: var(--font-size-sm);
  overflow: hidden;

  height: ${({$isVisible}) => ($isVisible ? 'auto' : 0)};
  margin: ${({$isVisible}) => ($isVisible ? '5px 0 0' : 0)};
  padding: ${({$isVisible}) => ($isVisible ? '6px 0 2px' : 0)};
  border-width: ${({$isVisible}) => ($isVisible ? 1 : 0)};
  opacity: ${({$isVisible}) => ($isVisible ? 0.8 : 0)};
  will-change: height, margin, padding, border-width, opacity;
  transition:
    height,
    margin,
    padding,
    border-width,
    opacity 0.2s ease-in-out;
`;
