import {Dayjs} from 'dayjs';
import {FilterParams} from '../filterParams';
import {filterFromDate} from '../filters/filterFromDate';
import {Step8Vessel} from './step8';
import {Step9Result} from './step9';
import {StepResult} from './StepResult';

export type Step10Result = StepResult<Step8Vessel>;

/**
 * filter vessels - only vessels whose ETA after the laycan is From (lower bound)
 *
 * If the laycan is 14 days in the future, it may not be worthwhile to spot his ship so long,
 * so ships that would have to wait for example 10 days, can be filtered out with this.
 */
export const step10 = ({
  params,
  preResult,
}: {
  preResult: Step9Result;
  params: FilterParams;
  currentTime?: Dayjs;
}): Step10Result => {
  const vesselList = [...preResult.vessels];
  if (!params.fromDate) {
    return {
      vessels: [...preResult.vessels],
      loggedFilters: [...preResult.loggedFilters],
    };
  }

  const vessels = filterFromDate({vessels: vesselList, fromDate: params.fromDate});
  return {
    vessels: vessels,
    loggedFilters: [
      ...preResult.loggedFilters,
      {
        label: `Arrives not too early`,
        count: vessels.length,
        id: 'arrivesNotTooEarly',
      },
    ],
  };
};
