import dayjs, {Dayjs} from 'dayjs';
import {CSSProperties, useMemo, FC} from 'react';
import {DatePicker as BasicDatePicker} from '../../antd/components/DatePicker';
import DateTime from '../../model/DateTime';
import InputGroup from './InputGroup';
import './style.scss';
import {TODO} from '../../utils/TODO';

type DatePickerProps = {
  id?: string;
  placeholder?: string;
  value?: Dayjs | null;
  disabled?: boolean;
  locale?: string;
  isClearable?: boolean;
  hasError?: boolean;
  format?: string;
  month?: Dayjs | null;
  groupStyle?: CSSProperties;
  onChange?: (date: Dayjs | null) => void;
  disableDaysBeforeToday?: boolean;
  dataCy?: string;
  disableDaysAfterToday?: boolean;
  align?: 'left' | 'right';
};

const DatePicker: FC<DatePickerProps> = ({
  id,
  value = null,
  onChange,
  placeholder = 'Select day',
  format = DateTime.dateFormat,
  isClearable = false,
  hasError = false,
  disabled,
  groupStyle,
  disableDaysBeforeToday = false,
  disableDaysAfterToday = false,
  month,
  dataCy,
}) => {
  const _getDisabledDays = (date: Dayjs) => {
    if (disableDaysBeforeToday) {
      return dayjs().diff(date, 'days') > 0;
    } else if (disableDaysAfterToday) {
      return date.isAfter(dayjs());
    }
    return false;
  };

  const pickerId = useMemo(() => id || Math.random().toString(), [id]);
  const val = value?.toDate ? dayjs(value.toDate()) : null;
  const defaultValue = month?.toDate ? dayjs(month.toDate()) : undefined;
  return (
    <div>
      <InputGroup
        groupStyle={{
          ...groupStyle,
          marginRight: '-32px',
          width: '100%',
        }}
        dataCy={dataCy}
        inputComponent={
          <BasicDatePicker
            id={pickerId}
            defaultValue={defaultValue}
            value={val}
            disabledDate={date => {
              return _getDisabledDays(date);
            }}
            disabled={disabled}
            format={format}
            placeholder={placeholder}
            allowClear={isClearable}
            onChange={(date: TODO) => {
              const newValue = date === null ? null : dayjs(date);
              onChange?.(newValue);
            }}
            getPopupContainer={() => {
              return document.getElementById(`popup-${pickerId}`)!;
            }}
          />
        }
        hasError={hasError}
      />
      <div id={`popup-${pickerId}`} />
    </div>
  );
};

export default DatePicker;
