import User from '../model/User';
import * as Sentry from '@sentry/browser';
import {CurrentUser, Reference} from '../api/symfony/generated';
import {assert} from '../utils/assert';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// The shape of this Redux partition
export type UserState = User | null;

// I use the type assertion to direct type inference
const initialState = null as UserState;

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    set(state: UserState, action: PayloadAction<CurrentUser | null>) {
      if (!action.payload) {
        return null;
      }
      const user = new User(action.payload);
      Sentry.setUser({id: user.id.toString(), email: user.email});
      return user;
    },
    setWorkspace(state: UserState, action: PayloadAction<Reference>): UserState {
      assert(state, 'non null state is required');
      const newWorkspace = action.payload;
      const user = new User({
        ...state.payload,
        workspace: newWorkspace,
      });
      return user;
    },
  },
});

export const userReducer = userSlice.reducer;
const UserActions = userSlice.actions;

export default UserActions;
