export interface CountryRecord {
  code: string;
  name: string;
}

export const countries: CountryRecord[] = [
  {
    code: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AX',
    name: 'Åland Islands',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    name: 'Argentina',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'BH',
    name: 'Bahrain',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BY',
    name: 'Belarus',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BO',
    name: 'Bolivia, Plurinational State of',
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
  },
  {
    code: 'BR',
    name: 'Brazil',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'TD',
    name: 'Chad',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'KM',
    name: 'Comoros',
  },
  {
    code: 'CG',
    name: 'Congo',
  },
  {
    code: 'CD',
    name: 'Congo, the Democratic Republic of the',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'CI',
    name: "Côte d'Ivoire",
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'CU',
    name: 'Cuba',
  },
  {
    code: 'CW',
    name: 'Curaçao',
  },
  {
    code: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    code: 'DK',
    name: 'Denmark',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'DM',
    name: 'Dominica',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FI',
    name: 'Finland',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'GF',
    name: 'French Guiana',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'GL',
    name: 'Greenland',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GN',
    name: 'Guinea',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    name: 'Guyana',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    code: 'VA',
    name: 'Holy See (Vatican City State)',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'IR',
    name: 'Iran, Islamic Republic of',
  },
  {
    code: 'IQ',
    name: 'Iraq',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JO',
    name: 'Jordan',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'KP',
    name: "Korea, Democratic People's Republic of",
  },
  {
    code: 'KR',
    name: 'Korea, Republic of',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    name: "Lao People's Democratic Republic",
  },
  {
    code: 'LV',
    name: 'Latvia',
  },
  {
    code: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'LR',
    name: 'Liberia',
  },
  {
    code: 'LY',
    name: 'Libya',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'MO',
    name: 'Macao',
  },
  {
    code: 'MK',
    name: 'Macedonia, the Former Yugoslav Republic of',
  },
  {
    code: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'MV',
    name: 'Maldives',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'FM',
    name: 'Micronesia, Federated States of',
  },
  {
    code: 'MD',
    name: 'Moldova, Republic of',
  },
  {
    code: 'MC',
    name: 'Monaco',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MA',
    name: 'Morocco',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'NO',
    name: 'Norway',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Palestine, State of',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    name: 'Poland',
  },
  {
    code: 'PT',
    name: 'Portugal',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'QA',
    name: 'Qatar',
  },
  {
    code: 'RE',
    name: 'Réunion',
  },
  {
    code: 'RO',
    name: 'Romania',
  },
  {
    code: 'RU',
    name: 'Russian Federation',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy',
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'MF',
    name: 'Saint Martin (French part)',
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'RS',
    name: 'Serbia',
  },
  {
    code: 'SC',
    name: 'Seychelles',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
  },
  {
    code: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
  },
  {
    code: 'SO',
    name: 'Somalia',
  },
  {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'SS',
    name: 'South Sudan',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SR',
    name: 'Suriname',
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SZ',
    name: 'Swaziland',
  },
  {
    code: 'SE',
    name: 'Sweden',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'SY',
    name: 'Syrian Arab Republic',
  },
  {
    code: 'TW',
    name: 'Taiwan, Province of China',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'TZ',
    name: 'Tanzania, United Republic of',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
  },
  {
    code: 'VN',
    name: 'Viet Nam',
  },
  {
    code: 'VG',
    name: 'Virgin Islands, British',
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
  },
  {
    code: 'YE',
    name: 'Yemen',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
  },
];

const midToCountryCode: Record<string, string> = {
  '201': 'AL',
  '202': 'AD',
  '203': 'AT',
  '204': 'PT',
  '205': 'BE',
  '206': 'BY',
  '207': 'BG',
  '208': 'VA',
  '209': 'CY',
  '210': 'CY',
  '211': 'DE',
  '212': 'CY',
  '213': 'GE',
  '214': 'MD',
  '215': 'MT',
  '216': 'AM',
  '218': 'DE',
  '219': 'DK',
  '220': 'DK',
  '224': 'ES',
  '225': 'ES',
  '226': 'FR',
  '227': 'FR',
  '228': 'FR',
  '229': 'MT',
  '230': 'FI',
  '231': 'FO',
  '232': 'GB',
  '233': 'GB',
  '234': 'GB',
  '235': 'GB',
  '236': 'GI',
  '237': 'GR',
  '238': 'HR',
  '239': 'GR',
  '240': 'GR',
  '241': 'GR',
  '242': 'MA',
  '243': 'HU',
  '244': 'NL',
  '245': 'NL',
  '246': 'NL',
  '247': 'IT',
  '248': 'MT',
  '249': 'MT',
  '250': 'IE',
  '251': 'IS',
  '252': 'LI',
  '253': 'LU',
  '254': 'MC',
  '255': 'PT',
  '256': 'MT',
  '257': 'NO',
  '258': 'NO',
  '259': 'NO',
  '261': 'PL',
  '262': 'ME',
  '263': 'PT',
  '264': 'RO',
  '265': 'SE',
  '266': 'SE',
  '267': 'SK',
  '268': 'SM',
  '269': 'CH',
  '270': 'CZ',
  '271': 'TR',
  '272': 'UA',
  '273': 'RU',
  '274': 'MK',
  '275': 'LV',
  '276': 'EE',
  '277': 'LT',
  '278': 'SI',
  '279': 'RS',
  '301': 'AI',
  '303': 'US',
  '304': 'AG',
  '305': 'AG',
  '306': 'CW',
  '307': 'AW',
  '308': 'BS',
  '309': 'BS',
  '310': 'BM',
  '311': 'BS',
  '312': 'BZ',
  '314': 'BB',
  '316': 'CA',
  '319': 'KY',
  '321': 'CR',
  '323': 'CU',
  '325': 'DM',
  '327': 'DO',
  '329': 'GP',
  '330': 'GD',
  '331': 'GL',
  '332': 'GT',
  '335': 'HN',
  '336': 'HT',
  '338': 'US',
  '339': 'JM',
  '341': 'KN',
  '343': 'LC',
  '345': 'MX',
  '347': 'MQ',
  '348': 'MS',
  '350': 'NI',
  '351': 'PA',
  '352': 'PA',
  '353': 'PA',
  '354': 'PA',
  '355': 'PA',
  '356': 'PA',
  '357': 'PA',
  '358': 'PR',
  '359': 'SV',
  '361': 'PM',
  '362': 'TT',
  '364': 'TC',
  '366': 'US',
  '367': 'US',
  '368': 'US',
  '369': 'US',
  '370': 'PA',
  '371': 'PA',
  '372': 'PA',
  '373': 'PA',
  '374': 'PA',
  '375': 'VC',
  '376': 'VC',
  '377': 'VC',
  '378': 'VG',
  '379': 'VI',
  '401': 'AF',
  '403': 'SA',
  '405': 'BD',
  '408': 'BH',
  '410': 'BT',
  '412': 'CN',
  '413': 'CN',
  '414': 'CN',
  '416': 'TW',
  '417': 'LK',
  '419': 'IN',
  '422': 'IR',
  '423': 'AZ',
  '425': 'IQ',
  '428': 'IL',
  '431': 'JP',
  '432': 'JP',
  '434': 'TM',
  '436': 'KZ',
  '437': 'UZ',
  '438': 'JO',
  '440': 'KR',
  '441': 'KR',
  '443': 'PS',
  '445': 'KP',
  '447': 'KW',
  '450': 'LB',
  '451': 'KG',
  '453': 'MO',
  '455': 'MV',
  '457': 'MN',
  '459': 'NP',
  '461': 'OM',
  '463': 'PK',
  '466': 'QA',
  '468': 'SY',
  '470': 'AE',
  '472': 'TJ',
  '473': 'YE',
  '475': 'YE',
  '477': 'HK',
  '478': 'BA',
  '501': 'FR',
  '503': 'AU',
  '506': 'MM',
  '508': 'BN',
  '510': 'FM',
  '511': 'PW',
  '512': 'NZ',
  '514': 'KH',
  '515': 'KH',
  '516': 'CX',
  '518': 'CK',
  '520': 'FJ',
  '523': 'CC',
  '525': 'ID',
  '529': 'KI',
  '531': 'LA',
  '533': 'MY',
  '536': 'MP',
  '538': 'MH',
  '540': 'NC',
  '542': 'NU',
  '544': 'NR',
  '546': 'PF',
  '548': 'PH',
  '553': 'PG',
  '555': 'PN',
  '557': 'SB',
  '559': 'AS',
  '561': 'WS',
  '563': 'SG',
  '564': 'SG',
  '565': 'SG',
  '566': 'SG',
  '567': 'TH',
  '570': 'TO',
  '572': 'TV',
  '574': 'VN',
  '576': 'VU',
  '577': 'VU',
  '578': 'WF',
  '601': 'ZA',
  '603': 'AO',
  '605': 'DZ',
  '607': 'FR',
  '608': 'GB',
  '609': 'BI',
  '610': 'BJ',
  '611': 'BW',
  '612': 'CF',
  '613': 'CM',
  '615': 'CG',
  '616': 'KM',
  '617': 'CV',
  '618': 'FR',
  '619': 'CI',
  '620': 'KM',
  '621': 'DJ',
  '622': 'EG',
  '624': 'ET',
  '625': 'ER',
  '626': 'GA',
  '627': 'GH',
  '629': 'GM',
  '630': 'GW',
  '631': 'GQ',
  '632': 'GN',
  '633': 'BF',
  '634': 'KE',
  '635': 'FR',
  '636': 'LR',
  '637': 'LR',
  '638': 'SS',
  '642': 'LY',
  '644': 'LS',
  '645': 'MU',
  '647': 'MG',
  '649': 'ML',
  '650': 'MZ',
  '654': 'MR',
  '655': 'MW',
  '656': 'NE',
  '657': 'NG',
  '659': 'NA',
  '660': 'RE',
  '661': 'RW',
  '662': 'SD',
  '663': 'SN',
  '664': 'SC',
  '665': 'SH',
  '666': 'SO',
  '667': 'SL',
  '668': 'ST',
  '669': 'SZ',
  '670': 'TD',
  '671': 'TG',
  '672': 'TN',
  '674': 'TZ',
  '675': 'UG',
  '676': 'CD',
  '677': 'TZ',
  '678': 'ZM',
  '679': 'ZW',
  '701': 'AR',
  '710': 'BR',
  '720': 'BO',
  '725': 'CL',
  '730': 'CO',
  '735': 'EC',
  '740': 'FK',
  '745': 'GF',
  '750': 'GY',
  '755': 'PY',
  '760': 'PE',
  '765': 'SR',
  '770': 'UY',
  '775': 'VE',
};

const nameMap: Record<string, string> = {};
const codeMap: Record<string, string> = {};

countries.forEach(country => {
  nameMap[country.name.toLowerCase()] = country.code;
  codeMap[country.code.toLowerCase()] = country.name;
});

class Country {
  static getCode(name = ''): string | undefined {
    if (typeof name !== 'string') {
      return;
    }

    if (!nameMap[name.toLowerCase()]) {
      return;
    }
    return nameMap[name.toLowerCase()];
  }

  static getName(code: string | null = ''): string | undefined {
    if (typeof code !== 'string') {
      return;
    }

    if (!codeMap[code.toLowerCase()]) {
      return;
    }
    return codeMap[code.toLowerCase()];
  }

  static codeExist(code: string): boolean {
    if (typeof code !== 'string') {
      return false;
    }
    if (!codeMap[code.toLowerCase()]) {
      return false;
    }
    return true;
  }

  static getFromMid(code = ''): string | undefined {
    if (typeof code !== 'string') {
      return;
    }

    return midToCountryCode[code];
  }

  static get codeList(): Record<string, string> {
    return codeMap;
  }

  static get namelist(): Record<string, string> {
    return nameMap;
  }
  static getList(): CountryRecord[] {
    return countries;
  }
}

export default Country;
