import {AutoComplete, Input} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import {ContractType} from '../../api/symfony/generated';
import {Coordinates} from '../../utils/Coordinates';
import {Cargo, CargoOption} from '../SearchPicker/Type';
import {CargoPickerIndexNames, useOptionsFromCargoSearch} from '../SearchPicker/useOptionsFromCargoSearch';
import {SizeType} from 'antd/es/config-provider/SizeContext';

interface CargoPickerProps {
  onSelect: (cargo: Cargo) => void;
  size?: SizeType;

  onChangeText?: (text: string) => void;
  defaultValue?: string;
  paramContractType?: ContractType;

  indexNames?: CargoPickerIndexNames | CargoPickerIndexNames[];
  autoFocus?: boolean;
  prefix?: React.ReactNode;
}

export interface LocationAdditionalInfo {
  rate?: number;
  unit?: 'day' | 'mt' | 'cbm' | 'cft';
  terms?: string;

  timeToTurn?: number;
}

export type Location = {
  name: string;
  additionalInfo?: LocationAdditionalInfo;

  type: string;
  code: string;
  country: string;
  coordinates?: Coordinates;
  id: number;
};

const StyledAutoComplete: typeof AutoComplete = styled(AutoComplete)`
  width: 100%;
`;

export const CargoPicker = (props: CargoPickerProps) => {
  const [searchTag, setSearchTag] = useState('');

  const optionsQuery = useOptionsFromCargoSearch({
    paramContractType: props.paramContractType,
    searchTag,
    indexNames: props.indexNames,
  });

  return (
    <StyledAutoComplete
      dropdownStyle={{
        minWidth: 400,
      }}
      autoFocus={props.autoFocus}
      defaultValue={props.defaultValue}
      onSelect={(_v: string, option: unknown) => {
        props.onSelect((option as CargoOption).source);
      }}
      options={optionsQuery.options}>
      <Input.Search
        prefix={props.prefix}
        data-testid="CargoPickerInputField"
        size={props.size}
        defaultValue={props.defaultValue}
        onChange={event => {
          const newValue = event.currentTarget.value.trim();
          setSearchTag(newValue);
          if (props.onChangeText) {
            props.onChangeText(newValue);
          }
        }}
        placeholder="Search for a cargo"
      />
    </StyledAutoComplete>
  );
};
