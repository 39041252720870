import {FilterOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {FC} from 'react';
import styled from 'styled-components';
import {Attribute} from '../../../atoms/Attribute/Attribute';
import {DeltaIcon} from '../../../components/DeltaIcon/DeltaIcon';
import {ExtLinkIcon} from '../../../components/ExtLinkIcon/ExtLinkIcon';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {
  chokepointMeta,
  GREEN,
  RED,
  useChokepointPolygonQuery,
} from '../ChokepointCongestionScreen/utils/chokepointMeta';
import {getBaseInformation} from '../ChokepointCongestionScreen/utils/getBaseInformation';
import {ChokepointKey} from '../ChokepointCongestionScreen/utils/useChokepointCongestionQuery';
import {useCongestionVisitsWithVesselInformationsQuery} from '../ChokepointCongestionScreen/utils/useCongestionVisitsWithVesselInformationsQuery';
import {MapCard} from '../MapCard/MapCard';

type ChokepointCongestionCardProps = {
  chokepointKey: ChokepointKey;
};

export const ChokepointCongestionCard: FC<ChokepointCongestionCardProps> = ({chokepointKey}) => {
  const polygonQuery = useChokepointPolygonQuery();
  const polygon = polygonQuery.isSuccess ? polygonQuery.data[chokepointKey] : undefined;
  const chokepointCongestionQuery = useCongestionVisitsWithVesselInformationsQuery(chokepointKey, {});
  const {title, centerPoint, zoom} = chokepointMeta[chokepointKey];

  if (!chokepointCongestionQuery.isSuccess) {
    return (
      <MapCard
        data-testid="ChokepointCongestionCard"
        url={`/lab/congestion/chokepoint/${chokepointKey}`}
        isLoading={true}
        mapStyle={'SAT'}
        centerPoint={centerPoint}
        zoom={zoom}>
        <TileTitle>
          {chokepointCongestionQuery.isError ? `Error retrieving ${title}.` : `${title} loading...`}
        </TileTitle>
      </MapCard>
    );
  }

  const {
    report: {isCongested, passedVisitsLast48h},
    currentVisits,
    queryTimestamp,
  } = chokepointCongestionQuery.data;

  const {delta, medianWaiting, medianPassage, bulker, container, mpp, total} = getBaseInformation(
    chokepointCongestionQuery.data
  );

  return (
    <MapCard
      testId={'ChokepointCongestionCard'}
      url={`/lab/congestion/chokepoint/${chokepointKey}`}
      currentVisits={currentVisits}
      polygon={polygon}
      mapStyle={'SAT'}
      polygonColor={isCongested ? RED : GREEN}
      centerPoint={centerPoint}
      zoom={zoom}>
      <TileTitle>
        {title}
        <ExtLinkIcon />
      </TileTitle>
      <SubTitleContainer>
        <StatusLabel>
          <StatusIcon $isCongested={isCongested} /> Transit seemed {isCongested ? 'congested' : 'normal'} in the last
          24h
        </StatusLabel>
        <BaseInfoLabel data-testid={'chokepoint-status-details'}>
          <DeltaIcon value={delta} isInverted /> {Math.abs(delta).toLocaleString()} {delta >= 0 ? 'more' : 'fewer'}{' '}
          Vessel
          {Math.abs(delta) === 1 ? '' : 's'} than expected
          <br />
          <TotalIcon /> ca. {(passedVisitsLast48h / 2).toLocaleString()} vessel{passedVisitsLast48h === 1 ? '' : 's'}{' '}
          total
        </BaseInfoLabel>
      </SubTitleContainer>
      <AttributeContainer>
        <AttributeTitle>Current transit</AttributeTitle>
        <PrimaryAttribute small tab="l" label="Vessels total" value={total ?? '0'} testId={'Vessels'} />
        <AttributeSeparator />
        <Attribute small tab="l" label="Bulker" value={bulker?.length ?? '0'} testId={'Bulker'} />
        <Attribute small tab="l" label="Container" value={container?.length ?? '0'} testId={'Container'} />
        <Attribute small tab="l" label="MPP" value={mpp?.length ?? '0'} testId={'MPP'} />
        <AttributeSeparator />
        <Attribute small tab="l" label="Waiting time" value={medianWaiting + ' d'} testId={'Waiting'} />
        <Attribute small tab="l" label="Passage time" value={medianPassage + ' d'} testId={'Passage'} />
      </AttributeContainer>
      <FooterContainer data-testid="lastUpdated">
        last updated: {dayjs(queryTimestamp).format(DateTimeFormat.DateTime)}
      </FooterContainer>
    </MapCard>
  );
};

const TileTitle = styled.h2`
  width: 100%;
  margin: 0 0 5px;
  color: var(--color-white);
  font-size: var(--font-size-lg);
  font-weight: bold;
  text-shadow: 0 0 15px var(--color-black);
`;

const SubTitleContainer = styled.div`
  width: 100%;
  margin: 0 0 auto;
  color: var(--color-white);
`;

const StatusLabel = styled.p`
  margin: 0 0 5px;
  font-weight: bold;
`;

const StatusIcon = styled.span<{$isCongested: boolean}>`
  --diameter: 1.1em;
  display: inline-block;
  position: relative;
  top: 2px;
  height: var(--diameter);
  width: var(--diameter);
  margin-right: 3px;
  border-radius: 50%;
  background: var(--color-${({$isCongested}) => ($isCongested ? 'red' : 'green')});
`;

const TotalIcon = styled(FilterOutlined)`
  --diameter: 1.2em;
  display: inline-block;
  position: relative;
  height: var(--diameter);
  width: var(--diameter);
  padding: 1px;
  margin-right: 3px;
  border-radius: var(--border-radius);
  background: var(--color-blue);
`;

const BaseInfoLabel = styled.p`
  margin: 0;
  font-size: var(--font-size-sm);
  line-height: 2;
`;

const AttributeContainer = styled.div`
  width: 28%;
  min-width: 170px;
  max-width: 250px;
  margin: -45px 0 auto;
  padding: 10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: var(--border-radius);
  color: var(--color-gray-1);

  [data-testid='attributeLabel'] {
    color: var(--color-gray-1);
  }
  [data-testid='attributeValue'] {
    font-weight: bold;
  }
`;

const AttributeTitle = styled.p`
  margin-bottom: 10px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-1);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
`;

const PrimaryAttribute = styled(Attribute)`
  font-weight: bold;
`;

const AttributeSeparator = styled.div`
  height: 10px;
  width: 100%;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin: auto 0 0;
  color: var(--color-gray-3);
  text-shadow: 0 0 2px var(--color-black);
`;
