/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationTermType,
  NegotiationTermTypeFromJSON,
  NegotiationTermTypeFromJSONTyped,
  NegotiationTermTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface UpdateTermTypeRequest
 */
export interface UpdateTermTypeRequest {
  /**
   *
   * @type {NegotiationTermType}
   * @memberof UpdateTermTypeRequest
   */
  type: NegotiationTermType | null;
}

export function UpdateTermTypeRequestFromJSON(json: any): UpdateTermTypeRequest {
  return UpdateTermTypeRequestFromJSONTyped(json, false);
}

export function UpdateTermTypeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTermTypeRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: NegotiationTermTypeFromJSON(json['type']),
  };
}

export function UpdateTermTypeRequestToJSON(value?: UpdateTermTypeRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: NegotiationTermTypeToJSON(value.type),
  };
}
