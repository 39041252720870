/* eslint-disable no-nested-ternary */
import React, {Fragment, useState, useCallback} from 'react';
import {FileRejection, useDropzone, Accept} from 'react-dropzone';
import classNames from 'classnames';
import {transformFileSize} from '../../utils/helper';
import Loading from '../../atoms/Loading';

import './style.scss';

export const acceptDocsWithoutRtf: Accept = {
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/msword': ['.doc'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

export const acceptDocs: Accept = {
  ...acceptDocsWithoutRtf,
  'application/rtf': ['.rtf'],
};

export const acceptImages: Accept = {
  'image/jpeg': ['.jpeg'],
  'image/jpg': ['.jpg'],
  'image/png': ['.png'],
};

type Props = {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  headline?: string;
  subline?: string;
  maxSize?: number;
  minSize?: number;
  accept: Accept;
  multiple?: boolean;
  small?: boolean;
  loading?: boolean;
  uploadErrors: string[];
};

const Upload = ({
  onDrop,
  headline,
  subline,
  maxSize = 10000000,
  minSize = 1,
  accept = {},
  multiple = false,
  small,
  loading = false,
  uploadErrors = [],
}: Props) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [error, setError] = useState(false);
  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setRejectedFiles(rejectedFiles);
      setError(rejectedFiles.length > 0);
      onDrop(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
        rejectedFiles
      );
    },
    [onDrop]
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: handleDrop,
    multiple,
    maxSize,
    minSize,
    accept,
  });

  const renderErrorMsg = (rejectedFiles: FileRejection[]) => {
    let sizeError = 0;
    let toSmall = 0;
    rejectedFiles.forEach(file => {
      if (file.file.size > maxSize) {
        sizeError++;
      }
      if (file.file.size < minSize) {
        toSmall++;
      }
    });

    if (sizeError > 0) {
      return (
        <span>
          {
            /* prettier-ignore */ `${sizeError} file${sizeError > 1 ? 's' : ''} rejected as ${sizeError > 1 ? 'they' : 'it'} exceed${sizeError > 1 ? '' : 's'} the allowed file size of ${transformFileSize(maxSize)}`
          }
        </span>
      );
    } else if (toSmall > 0) {
      return (
        <span>
          {
            /* prettier-ignore */ `${toSmall} file${toSmall > 1 ? 's' : ''} rejected as ${toSmall > 1 ? 'they' : 'it'} exceed${toSmall > 1 ? '' : 's'} the allowed file size should be at least ${transformFileSize(minSize)}`
          }
        </span>
      );
    } else {
      return (
        <span>
          {
            /* prettier-ignore */ `${rejectedFiles.length} file${rejectedFiles.length > 1 ? 's' : ''} rejected, the file type doesn´t match`
          }
        </span>
      );
    }
  };

  const renderBackendErrorMsg = (errors: $TSFixMe) => (
    <div className={'upload__backend-errors'}>
      {errors.map((e: $TSFixMe, key: $TSFixMe) => (
        <span key={key}>{e.message}</span>
      ))}
    </div>
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={classNames('upload', {'upload--small': small, 'upload--drag-active': isDragActive})}>
        {!loading ? (
          <Fragment>
            {!isDragActive ? (
              <Fragment>
                <div
                  className={classNames('upload__headline', {
                    'upload__headline--error': error || uploadErrors.length > 0,
                  })}>
                  {error
                    ? renderErrorMsg(rejectedFiles)
                    : uploadErrors.length > 0
                    ? renderBackendErrorMsg(uploadErrors)
                    : headline}
                </div>
                <div className={'upload__subline'}>{subline}</div>
                <div className={'upload__supported-types'}>
                  Supported types:{' '}
                  {Object.values(accept)
                    .map(a => {
                      return a.map(b => b.toUpperCase());
                    })
                    .join(', ')}
                </div>
                <div className={'upload__subline'}>Max. file size: {maxSize / 1000000}mb</div>
              </Fragment>
            ) : (
              <div>Drop the file to upload</div>
            )}
          </Fragment>
        ) : (
          <div className="upload__info">
            <Loading width={100} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
