import {NegotiationStatus, NegotiationRoundResultSummary} from '../../../../../api/node-backend/generated';

export type NegotiationHistoryEventType = 'round' | 'current' | 'waiting' | NegotiationStatus;

export type NegotiationHistoryEvent = {
  type: NegotiationHistoryEventType;
  date: Date | null;
  roundIndex?: number;
  roundResult?: NegotiationRoundResultSummary;
};

export const getNegotiationHistoryEvents = (
  roundResultSummaries: NegotiationRoundResultSummary[]
): NegotiationHistoryEvent[] => {
  const events: NegotiationHistoryEvent[] = [];

  for (let i = 0; i < roundResultSummaries.length; i++) {
    const roundResult = roundResultSummaries[i];
    const previousRoundResult = roundResultSummaries[i - 1];

    const isLatestRound = i === roundResultSummaries.length - 1;
    const isCommitted = !!roundResult.committedAt;
    const hasEnded = [NegotiationStatus.Settled, NegotiationStatus.Cancelled].includes(roundResult.negotiationStatus);

    if (roundResult.negotiationStatus !== previousRoundResult?.negotiationStatus) {
      events.push({
        type: roundResult.negotiationStatus,
        date: roundResult.committedAt,
        roundIndex: i,
        roundResult,
      });
    }

    // If the negotiation has ended, we don't need to show the current round
    if (hasEnded) {
      break;
    }

    events.push({
      type: isCommitted ? 'round' : 'current',
      date: roundResult.committedAt,
      roundIndex: i,
      roundResult,
    });

    if (isLatestRound && isCommitted) {
      events.push({
        type: 'waiting',
        date: null,
      });
    }
  }

  return events;
};
