import {PauseOutlined, PlayCircleOutlined} from '@ant-design/icons';
import {Button, Row, Slider} from 'antd';
import dayjs from 'dayjs';
import {FC} from 'react';
import styled from 'styled-components';
import {TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

export const MapPlayerBox: FC<{
  tradingAreas: TradingAreaTiedUpVesselsStatistic[];
  dateIndex: number;
  onChangeDateIndex: (dateIndex: number) => void;
  onToggleIsPlay: () => void;
  isPlaying: boolean;
}> = ({onChangeDateIndex, tradingAreas, dateIndex, isPlaying, onToggleIsPlay}) => {
  const selectedDate = dayjs(tradingAreas[0].oneDayStatistics[dateIndex].date);
  return (
    <Container data-testid={'MapPlayerBox'}>
      <Row>
        <Button
          type={'primary'}
          icon={isPlaying ? <PauseOutlined /> : <PlayCircleOutlined />}
          onClick={() => onToggleIsPlay()}>
          {isPlaying ? 'Stop' : 'Play'}
        </Button>
        <div style={{textAlign: 'right', display: 'inline-block', flex: 1, fontSize: 20}}>
          {selectedDate.format(DateTimeFormat.Date)}
        </div>
      </Row>
      <div style={{display: 'inline-block', width: '100%'}}>
        <Slider
          value={dateIndex}
          onChange={onChangeDateIndex}
          max={tradingAreas[0].oneDayStatistics.length - 1}
          tooltip={{
            open: false,
          }}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  top: 8px;
  left: 8px;
  background-color: var(--color-white);
  height: 70px;
  width: 220px;
  position: absolute;
  border-radius: 4px;
  padding: 4px;
  padding-right: 8px;
`;
