import {FC} from 'react';
import {Modal} from '../../antd/components/Modal';
import {Button} from 'antd';
import {ModalFooter} from '../../antd/components/ModalFooter';
import styled from 'styled-components';

export const ConfirmationModal: FC<{
  open?: boolean;
  title?: string | null;
  description?: string;
  danger?: boolean;
  okText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
}> = ({open, title = null, description, danger = false, okText = 'OK', onConfirm, onClose}) => {
  return (
    <Modal title={title} open={open} onCancel={onClose} footer={null} destroyOnClose>
      <Description>{description}</Description>
      <ModalFooter>
        <Button
          onClick={() => {
            onConfirm?.();
            onClose?.();
          }}
          type="primary"
          danger={danger}
          data-cy="confirmation-modal-yes-button">
          {okText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Description = styled.div`
  font-size: var(--font-size-xl);
  text-align: center;
`;
