import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {Space} from 'antd';

/**
 * Footer for an ITS modal.
 *
 */
export const ModalFooter: FC<{children: ReactNode; className?: string}> = ({children, className}) => (
  <ModalFooterStyled className={className}>
    <Space>{children}</Space>
  </ModalFooterStyled>
);

const ModalFooterStyled = styled.div`
  text-align: center;
  margin-top: 48px;

  .ant-btn {
    min-width: 80px;
  }
`;
