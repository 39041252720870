import React, {useContext, useMemo} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {VoyageRouteOutput} from '../VoyageInformation/VoyageTypes';
import {interpolateRgb} from 'd3-interpolate';
import {color as d3color, rgb, RGBColor} from 'd3-color';
import {Color} from '../../types';
import {Point, Route, useCharterCalculationVoyageLayer} from './useCharterCalculationVoyageLayer';
import {SeaboMap} from '../../../SeaboMap/SeaboMap';
import {makePartialMapSwitches} from '../../../SeaboMap/utils/makePartialMapSwitches';
import {MapControlButton} from '../../../SeaboMap/MapContext/Types';

interface MapProps {
  open?: boolean;
  height?: number | string;
}

const colorRed = getComputedStyle(document.documentElement).getPropertyValue('--color-red') || '#f00046';
const colorGreen = getComputedStyle(document.documentElement).getPropertyValue('--color-green') || '#62d388';

export const Map = (props: MapProps) => {
  const ColorInterpolator = interpolateRgb(colorRed, colorGreen);

  const voyageContext = useContext(VoyageCharterContext);

  const {inputState, outputState} = voyageContext.state;

  const points: Array<Point> = inputState.voyage.points.map(item => {
    return {
      coordinates: item.coordinates,
      isPort: item.isPort,
    };
  });

  const routes: Array<Route> = inputState.voyage.routes.map((item, index) => {
    const outItem = outputState.voyage.routes[index] || ({} as VoyageRouteOutput);

    /*
    do a interpolation between green (laden) and red (balast),
    then get the RGBColor, or generate a black color RGBColor
     */
    const color: RGBColor =
      d3color(
        ColorInterpolator((outputState.voyage.points[index] || {usedDwat: 0}).usedDwat / (inputState.vessel.dwat || 1))
      )?.rgb() ?? rgb(0, 0, 0);

    return {
      path: item.waypoints !== undefined && inputState.voyage.routesCalculated ? item.waypoints : outItem.endPoints,
      color: [color.r, color.g, color.b] as Color,
    };
  });

  const coordinatesToFocus = useMemo(() => {
    return outputState.voyage.routes.flatMap(item => item.endPoints);
  }, [outputState.voyage.routes]);

  const calculationVoyageLayer = useCharterCalculationVoyageLayer({
    routes,
    points,
  });

  return (
    <div style={{width: '100%', height: props.height ?? 250, transition: 'height 1s'}}>
      <SeaboMap
        mapMenuZIndex={20}
        coordinatesToFocus={coordinatesToFocus}
        showSearch={false}
        showLegend={false}
        vesselCargoMode={false}
        showPopups={false}
        defaults="dashboard"
        vesselsToVisualize={{
          data: [],
        }}
        settingIdentifier={'db2'}
        settingsPersistent={false}
        layers={[...calculationVoyageLayer]}
        lassoSelectionEnabled={false}
        mapControlButtonsToHide={[MapControlButton.CARGOES, MapControlButton.VESSELS]}
        initialMapSettings={{
          mapStyle: 'SAT',
          switches: {
            ...makePartialMapSwitches({
              ports: true,
              tradingAreaNames: false,
              highRiskAreas: true,
              emissionControlAreas: true,
            }),
          },
        }}
      />
    </div>
  );
};
