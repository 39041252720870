import {UseQueryResult} from '@tanstack/react-query';
import {SearchContainer, SearchHit} from '../../api/symfony/generated/models';
import {OptionGroups} from './AutocompleteOptions';
import {makeCountryOption} from './makeOption/makeCountryOption';
import {makePortOption} from './makeOption/makePortOption';
import {makeTradingAreaOption} from './makeOption/makeTradingAreaOption';
import {LocationOption} from './Type';
import {useSearchQuery} from './useSearchQuery';

type Params = {
  searchTag: string;
  filter?: (locationItem: SearchHit) => boolean;
};

export type OptionsFromLocationSearch = {
  options: OptionGroups<LocationOption>;
  searchQuery: UseQueryResult<SearchContainer, Error>;
};

/**
 * Performs a search request for the given searchTag and returns
 * the result as options for an Antd <AutoComplete> component.
 */
export const useOptionsFromLocationSearch = (params: Params): OptionsFromLocationSearch => {
  const {searchTag} = params;
  const indexNames = 'location_country,location_port,location_tradingArea';
  const searchQuery = useSearchQuery({searchTag, indexNames});
  if (!searchQuery.isSuccess) {
    return {
      options: [],
      searchQuery,
    };
  }

  const portOptions = searchQuery.data.data.items.location_port.items
    .filter(item => (params.filter ? params.filter(item) : true))
    .map(makePortOption);
  const countryOptions = searchQuery.data.data.items.location_country.items
    .filter(item => (params.filter ? params.filter(item) : true))
    .map(makeCountryOption);
  const tradingAreaOptions = searchQuery.data.data.items.location_tradingArea.items
    .filter(item => (params.filter ? params.filter(item) : true))
    .map(makeTradingAreaOption);

  const optionGroups: OptionGroups<LocationOption> = [];
  if (portOptions.length > 0) {
    optionGroups.push({
      label: 'Ports',
      options: portOptions,
    });
  }
  if (countryOptions.length > 0) {
    optionGroups.push({
      label: 'Country',
      options: countryOptions,
    });
  }
  if (tradingAreaOptions.length > 0) {
    optionGroups.push({
      label: 'Tradingarea',
      options: tradingAreaOptions,
    });
  }

  return {options: optionGroups, searchQuery};
};
