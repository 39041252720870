/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {string}
   * @memberof Document
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  fileExtension: string;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  size: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  mimeType: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  context: string;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  uploaded: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  assigned: boolean;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  created: string;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  downloadCount: number;
}

export function DocumentFromJSON(json: any): Document {
  return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    filename: json['filename'],
    fileExtension: json['fileExtension'],
    size: json['size'],
    mimeType: json['mimeType'],
    url: json['url'],
    context: json['context'],
    uploaded: json['uploaded'],
    assigned: json['assigned'],
    created: json['created'],
    downloadCount: json['downloadCount'],
  };
}

export function DocumentToJSON(value?: Document | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    filename: value.filename,
    fileExtension: value.fileExtension,
    size: value.size,
    mimeType: value.mimeType,
    url: value.url,
    context: value.context,
    uploaded: value.uploaded,
    assigned: value.assigned,
    created: value.created,
    downloadCount: value.downloadCount,
  };
}
