import {AnimationPortEntry, AnimationSeaSegment} from '../model/AnimationEvent';
import {interpolateAisRecord} from './interpolateAISRecord';
import {AISRecord} from '../queries/aisRecordsQuery/AISRecord';
import {distance} from '../utils/distance';
import {transformLonLatFromObject} from '../../../../../utils/Coordinates';
import {calculateRedundantFields} from './calculateRedundantFields';
import clamp from 'lodash/clamp';

export const splitSeaSegment = (
  seaSegment: AnimationSeaSegment,
  portEntry: AnimationPortEntry,
  distanceSplitPort: number
): AnimationSeaSegment[] => {
  let splitPercentage = calculateSplitPercentFromPort(seaSegment, portEntry, distanceSplitPort);
  splitPercentage = clamp(splitPercentage, 0, 1);
  const seaSegmentsAfterSplit = splitSeaSegmentAtPercent(seaSegment, splitPercentage);
  return seaSegmentsAfterSplit;
};

const calculateSplitPercentFromPort = (
  seaSegment: AnimationSeaSegment,
  portEntry: AnimationPortEntry,
  distanceSplitPort: number
): number => {
  const portCoordinates = transformLonLatFromObject(portEntry.portVisit.port.coordinates);
  const distanceFromPort = distance(seaSegment.from.coordinates, portCoordinates);
  const distanceToPort = distance(seaSegment.to.coordinates, portCoordinates);
  const percent = calculateSplitPercent(distanceFromPort, distanceToPort, distanceSplitPort);

  return percent;
};

export const splitSeaSegmentAtPercent = (seaSegment: AnimationSeaSegment, splitPercentage: number) => {
  const splitWaypoint: AISRecord = interpolateAisRecord(seaSegment.from, seaSegment.to, splitPercentage);

  const newSeaSegment0: AnimationSeaSegment = calculateRedundantFields({
    ...seaSegment,
    id: `sea-segment-${seaSegment.id}-split-0`,
    to: splitWaypoint,
  });

  const newSeaSegment1: AnimationSeaSegment = calculateRedundantFields({
    ...seaSegment,
    id: `sea-segment-${seaSegment.id}-split-1`,
    from: splitWaypoint,
  });

  return [newSeaSegment0, newSeaSegment1];
};

export const calculateSplitPercent = (distanceFromPort: number, distanceToPort: number, distanceSplitPort: number) => {
  // If the sea segment takes us farther way from port, do the split at at the end of the sea segment.
  if (distanceFromPort < distanceToPort) {
    return 1;
  }
  return (distanceFromPort - distanceSplitPort) / (distanceFromPort - distanceToPort);
};
