import chunk from 'lodash/chunk';
import {isApiError} from '../../../../../../api/utils/ApiError';

const CHUNK_SIZE = 7;

export const batchPatchRequests = async <RequestParameters, ResponseType>(
  requestParameters: RequestParameters[],
  patchRequest: (requestParameters: RequestParameters) => Promise<ResponseType>,
  chunkSize = CHUNK_SIZE
): Promise<ResponseType> => {
  if (requestParameters.length === 0) {
    throw new Error('requestParameters must not be empty');
  }
  const requestParametersWithoutLast = requestParameters.slice(0, requestParameters.length - 1);
  const lastRequestParameter = requestParameters[requestParameters.length - 1];

  const chunkedRequestParameters = chunk(requestParametersWithoutLast, chunkSize);

  for (const chunkedRequestParameter of chunkedRequestParameters) {
    await Promise.all(
      chunkedRequestParameter.map(params => {
        try {
          return patchRequest(params);
        } catch (error) {
          if (isApiError(error)) {
            // we don't care about the result of the requests, we only care about the last one
            return null;
          }
          throw error;
        }
      })
    );
  }

  return patchRequest(lastRequestParameter);
};
