export enum SubscriptionStepEnum {
  Upgrade = 'upgrade', // 1
  Feature = 'feature', // 2
  Summary = 'summary', // 3
}

export const getSubscriptionStepNumber = (nextStep?: string) => {
  switch (nextStep) {
    case undefined:
      return 0;
    case `/subscription/${SubscriptionStepEnum.Upgrade}`:
      return 1;
    case `/subscription/${SubscriptionStepEnum.Feature}`:
      return 2;
    case `/subscription/${SubscriptionStepEnum.Summary}`:
      return 3;
    default:
      return 0;
  }
};
