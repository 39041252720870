// import { useControls } from "leva";

export const useGetIconSizeByZoom = () => {
  // const values = useControls({
  //   xOffset: {
  //     value: 12,
  //     min: 0,
  //     max: 40,
  //   },
  //   xScale: {
  //     value: 2.05,
  //     min: 1,
  //     max: 10,
  //   },
  //   yOffset: {
  //     value: 4,
  //     min: 0,
  //     max: 40,
  //   },
  //   min: {
  //     value: 7,
  //     min: 0,
  //     max: 40,
  //   },
  //   max: {
  //     value: 10,
  //     min: 0,
  //     max: 40,
  //   },
  //   powBelow: {
  //     value: 2,
  //     step: 1,
  //     min: 1,
  //     max: 10,
  //   },
  //   powAbove: {
  //     value: 4,
  //     step: 1,
  //     min: 1,
  //     max: 10,
  //   },
  // });
  const values = {
    xOffset: 12,
    xScale: 2.05,
    yOffset: 4,
    min: 7,
    max: 10,
    powBelow: 2,
    powAbove: 4,
  };
  const {xOffset, xScale, yOffset, min, max, powBelow, powAbove} = values;

  return (zoom: number) => {
    const pow = zoom < xOffset ? powBelow : powAbove;
    const maxBelow = zoom < xOffset ? max : Infinity;
    return Math.min(maxBelow, Math.max(Math.pow((zoom - xOffset) / xScale, pow) + yOffset, min));
  };
};
