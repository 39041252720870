import React, {FC, useState} from 'react';
import {Alert, Avatar, Space, Typography} from 'antd';
import {ProfilePictureFormValues} from './ProfilePictureFormValues';
import {UploadAvatarButton} from './UploadAvatarButton';
import {RemoveAvatarButton} from './RemoveAvatarButton';
import {UserOutlined} from '@ant-design/icons';
import {UploadChangeParam, UploadFile} from 'antd/es/upload';
import {
  SettingsSection,
  SettingsSectionBody,
  SettingsSectionTitle,
} from '../../../components/SettingsCard/SettingsSection';

const {Paragraph} = Typography;

export const ProfilePictureForm: FC<{
  currentValues: ProfilePictureFormValues;
  removing: boolean;
  onChange?: (info: UploadChangeParam<UploadFile>) => void;
  onRemove: () => void;
}> = ({currentValues, removing, onChange, onRemove}) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const avatar = currentValues.avatar;

  const handleRemove = () => {
    setValidationErrors([]);
    onRemove();
  };

  return (
    <>
      <SettingsSection $antForm>
        <SettingsSectionTitle>Picture</SettingsSectionTitle>
        <SettingsSectionBody>
          <div className="ProfilePictureForm" data-testid="ProfilePictureForm">
            {validationErrors.length > 0 && (
              <>
                <Alert message={validationErrors[0]} type="error" />
                <br />
              </>
            )}

            <br />
            <Avatar
              shape="circle"
              size={128}
              icon={avatar ? undefined : <UserOutlined data-testid="UserPlaceholderIcon" />}
              src={avatar || undefined}
            />
          </div>
          <br />
          <Space>
            <UploadAvatarButton onValidationError={setValidationErrors} onChange={onChange} />
            <RemoveAvatarButton disabled={!currentValues.avatar} removing={removing} onRemove={handleRemove} />
          </Space>
          <br />
          <br />
          <Paragraph type="secondary">
            You may use the following file extensions GIF, JPG, JPEG, PNG <br /> for your profile picture with a maximum
            file size of 5MB.
            <br /> The minimum dimensions are 128×128 pixels.
          </Paragraph>
        </SettingsSectionBody>
      </SettingsSection>
    </>
  );
};
