import {InputWithDataFieldId} from '../../../utils/InputWithDataFieldId';
import {SuggestedWaitingTime} from './SuggestedWaitingTime';
import {CalcTimeLinePoint} from '../../../VoyageTypes';
import {useSelector} from '../../../../../../../redux/react-redux';
import {useGetSuggestedWaitingTimes} from './useGetSuggestedWaitingTimes';
import {Popover} from 'antd';

export const ExtraTimeField = ({
  area,
  value,
  legItem,
  onChange,
  avgDurationLast10VesselsInMinutes,
}: {
  area: string;
  legItem: CalcTimeLinePoint;
  avgDurationLast10VesselsInMinutes: number | undefined;
  onChange?: (value: string) => void;
  value?: string;
}) => {
  return (
    <ApplyTooltip
      onChange={onChange}
      legItem={legItem}
      avgDurationLast10VesselsInMinutes={avgDurationLast10VesselsInMinutes}>
      <InputWithDataFieldId
        size="small"
        area={area}
        field={'waitingDurationInHours'}
        style={{width: 110}}
        type={'number'}
        value={value}
        onChange={event => {
          onChange?.(event.target.value);
        }}
        suffix={'hours'}
      />
    </ApplyTooltip>
  );
};

const ApplyTooltip = ({
  legItem,
  avgDurationLast10VesselsInMinutes,
  onChange,
  children,
}: {
  legItem: CalcTimeLinePoint;
  avgDurationLast10VesselsInMinutes?: number;
  onChange?: (value: string) => void;
  children: React.ReactNode;
}) => {
  const laycanFrom = useSelector(state => state.charterCalculation.inputState.voyage.laycanFrom);

  const waitingTimes = useGetSuggestedWaitingTimes({
    laycanFrom: laycanFrom,
    legItem,
    avgDurationLast10VesselsInMinutes,
  });
  if (!avgDurationLast10VesselsInMinutes || !waitingTimes) {
    return <>{children}</>;
  }

  return (
    <Popover
      open={true}
      placement="bottom"
      content={
        <SuggestedWaitingTime
          waitingTimes={waitingTimes}
          onChangeWaitingTime={waitingDurationInHours => {
            onChange?.(waitingDurationInHours.toString());
          }}
        />
      }>
      {children}
    </Popover>
  );
};
