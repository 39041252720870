import React, {Fragment, ReactNode} from 'react';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import Checkbox from '../../../../atoms/Checkbox';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import {filterBranchDefinition, GearedOptions} from './FeaturesDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import Select from '../../../../atoms/Select/Select';
import styled from 'styled-components';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
  badge?: ReactNode;
  disabled?: boolean;
}

const Features = ({filterProviderApi, disabled, badge}: Props) => (
  <>
    <FilterButton
      disabled={disabled}
      filterProviderApi={filterProviderApi}
      filterBranchDefinition={filterBranchDefinition}
      size={'big'}>
      {({handleChange, values}) => (
        <Fragment>
          <div className="container">
            <Grid>
              <Checkbox
                label={'Cellular'}
                id={'filter-feature-cellular'}
                checked={values.cellular}
                onChange={e => handleChange('cellular', e.target.checked)}
              />
              <Checkbox
                label={'ITF-fitted'}
                id={'filter-feature-itfFitted'}
                checked={values.itfFitted}
                onChange={e => handleChange('itfFitted', e.target.checked)}
              />
              <Checkbox
                label={'Bow thruster'}
                id={'filter-feature-bowThrusted'}
                checked={values.bowThrusted}
                onChange={e => handleChange('bowThrusted', e.target.checked)}
              />
              <Checkbox
                label={'Stern thruster'}
                id={'filter-feature-sternThrusted'}
                checked={values.sternThrusted}
                onChange={e => handleChange('sternThrusted', e.target.checked)}
              />
              <Checkbox
                label={'Open-hatch'}
                id={'filter-feature-openHatched'}
                checked={values.openHatched}
                onChange={e => handleChange('openHatched', e.target.checked)}
              />
              <Checkbox
                label={'Lakes-fitted'}
                id={'filter-feature-lakesFitted'}
                checked={values.lakesFitted}
                onChange={e => handleChange('lakesFitted', e.target.checked)}
              />
              <Checkbox
                label={'Log-fitted'}
                id={'filter-feature-logFitted'}
                checked={values.logFitted}
                onChange={e => handleChange('logFitted', e.target.checked)}
              />
              <Checkbox
                label={'CO2-fitted'}
                id={'filter-feature-co2Fitted'}
                checked={values.co2Fitted}
                onChange={e => handleChange('co2Fitted', e.target.checked)}
              />
              <Checkbox
                label={'Box-shape'}
                id={'filter-feature-boxShaped'}
                checked={values.boxShaped}
                onChange={e => handleChange('boxShaped', e.target.checked)}
              />
              <Checkbox
                label={'Tween-decks'}
                id={'filter-feature-tweenDecks'}
                checked={values.tweenDecks}
                onChange={e => handleChange('tweenDecks', e.target.checked)}
              />
              <Checkbox
                label={'A60 bulkhead'}
                id={'filter-feature-a60Bulkhead'}
                checked={values.a60Bulkhead}
                onChange={e => handleChange('a60Bulkhead', e.target.checked)}
              />
              <Checkbox
                label={'Scrubber-fitted'}
                id={'filter-feature-scrubberFitted'}
                checked={values.scrubberFitted}
                onChange={e => handleChange('scrubberFitted', e.target.checked)}
              />
              <Checkbox
                label={'Ice class'}
                id={'filter-feature-iceClass'}
                checked={values.iceClass}
                onChange={e => handleChange('iceClass', e.target.checked)}
              />
            </Grid>
            <HorizontalLine large />
            <div className="row bottom-fix">
              <div className="scol-4">
                <LabelWrapper label={'Geared'} htmlFor={'filter-feature-geared'}>
                  <Select
                    id={'filter-feature-geared'}
                    name={'filter-feature-geared'}
                    value={values.geared}
                    isClearable
                    onChange={value => handleChange('geared', value)}
                    options={GearedOptions}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.value}
                    components={{
                      DropdownIndicator: ArrowIndicator,
                    }}
                  />
                </LabelWrapper>
              </div>
              {/*
                <div className="scol-4">
                  <LabelWrapper label={'Grab-fitted'} htmlFor={'filter-feature-grabbed'}>
                    <Select
                      id={'filter-feature-grabbed'}
                      name={'filter-feature-grabbed'}
                      value={values.grabbed}
                      isClearable
                      onChange={value => handleChange('grabbed', value)}
                      options={grabOptions}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.value}
                      components={{
                        DropdownIndicator: ArrowIndicator,
                      }}
                    />
                  </LabelWrapper>
                </div>
              */}
            </div>
          </div>
        </Fragment>
      )}
    </FilterButton>
    {badge}
  </>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 12px;
`;

export default Features;
