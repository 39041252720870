import React, {ReactElement, FC} from 'react';
import {Dropdown, DropDownProps, Menu} from 'antd';
import styled from 'styled-components';
import Icon from '../../atoms/Icon';
import {ItemType} from 'antd/es/menu/interface';

export type ItemTypeWithDataCy = ItemType & {'data-cy'?: string; isValid?: boolean};
interface Props {
  actionIcon?: ReactElement;
  dataTestid?: string;
  position?: DropDownProps['placement'];
  className?: string;
  items: ItemTypeWithDataCy[];
}

export const ActionMenu: FC<Props> = props => {
  const items = props.items
    .filter(item => item.isValid !== false)
    .map(item => {
      // delete isValid Property to prevent passing it as attribute to the dom element
      delete item.isValid;
      return item;
    });

  return (
    <Dropdown
      placement={props.position}
      trigger={['click']}
      menu={{
        items: [
          {
            key: '1',
            label: <StyledMenu items={items} />,
          },
        ],
      }}>
      <div
        data-cy={'ActionMenu'}
        data-testid={props.dataTestid}
        style={{cursor: 'pointer'}}
        className={props.className}>
        {props.actionIcon ?? <Icon type={'more-vert'} />}
      </div>
    </Dropdown>
  );
};

const StyledMenu = styled<typeof Menu>(Menu)`
  min-width: 155px;

  .ant-dropdown-menu-item {
    padding: 0;
  }
`;
