import {CSSProperties, FC, MouseEventHandler, useState} from 'react';
import {UserConsumer} from '../../components/UserContext/UserConsumer';
import {socialApi} from '../../api/symfony/symfonyApi';
import {ConnectedState} from '../../api/symfony/generated';
import {Button} from 'antd';

interface Props {
  userID: number;
  connectedState: ConnectedState | number;
  style?: CSSProperties;
}

interface ButtonProps {
  label: string;
  onClick: MouseEventHandler;
}

const mapLegacyConnectedState = (state: number): ConnectedState => {
  switch (state) {
    case 3:
      return ConnectedState.Friends;
    case 2:
      return ConnectedState.Received;
    case 1:
      return ConnectedState.Pending;
  }

  return ConnectedState.Strangers;
};

type DisplayedButtonProps = Pick<ButtonProps, 'label' | 'onClick'>;

export const ContactButton: FC<Props> = ({userID, connectedState, style}) => {
  if (typeof connectedState === 'number') {
    connectedState = mapLegacyConnectedState(connectedState);
  }

  const [currentState, setConnectedState] = useState(connectedState);
  const [hoverState, setHoverState] = useState(false);
  let displayedButtonProps: DisplayedButtonProps | undefined;

  switch (currentState) {
    case ConnectedState.Friends:
      displayedButtonProps = {
        label: 'Remove Connection',
        onClick: async () => {
          await socialApi.removeFriend({userId: userID});
          setConnectedState(ConnectedState.Strangers);
        },
      };
      break;
    case ConnectedState.Received:
      displayedButtonProps = {
        label: 'Accept',
        onClick: async () => {
          await socialApi.confirmFriend({userId: userID});
          setConnectedState(ConnectedState.Friends);
        },
      };
      break;
    case ConnectedState.Pending:
      displayedButtonProps = {
        label: hoverState ? 'Withdraw' : 'Request Sent',
        onClick: async () => {
          await socialApi.withdrawFriend({userId: userID});
          setConnectedState(ConnectedState.Strangers);
        },
      };
      break;
    case ConnectedState.Strangers:
    default:
      displayedButtonProps = {
        label: 'Connect',
        onClick: async () => {
          await socialApi.addFriend({userId: userID});
          setConnectedState(ConnectedState.Pending);
        },
      };
  }

  return (
    <UserConsumer>
      {user =>
        user.id !== userID ? (
          <Button
            {...(displayedButtonProps as DisplayedButtonProps)}
            onMouseEnter={() => setHoverState(true)}
            onMouseLeave={() => setHoverState(false)}
            style={{minWidth: '160px', ...style}}
            type="primary"
            id={`contact-${userID}`}>
            {displayedButtonProps?.label}
          </Button>
        ) : (
          <></>
        )
      }
    </UserConsumer>
  );
};

export default ContactButton;
