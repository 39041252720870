import React, {FC, useState} from 'react';
import useOnClickOutside from 'use-onclickoutside';
import {TermEditorActions} from './TermEditorActions';
import {TermEditorTextArea} from './TermEditorTextArea';
import {TermDiff} from '../TermDiff';
import {EditorContainer, EditorContent, EditorEditorContainer} from '../shared';

type TermEditorProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  currentText: string;
  showDiff: boolean;
  helpText?: string;
  onSave: (termText: string) => void;
  onCancel: () => void;
};

export const TermEditor: FC<TermEditorProps> = ({
  containerRef,
  currentText,
  showDiff = true,
  helpText = 'SHIFT + ENTER for newline | ENTER for save | ESCAPE for cancel',
  onSave,
  onCancel,
}) => {
  const [newText, setNewText] = useState(currentText);

  const finishEditing = () => {
    if (!newText || newText === currentText) {
      onCancel();
      return;
    }
    onSave(newText);
  };

  useOnClickOutside(containerRef, () => {
    if (newText && newText !== currentText) {
      finishEditing();
      return;
    }
    onCancel();
  });

  const hasDiff = currentText.trim() && newText.trim() !== currentText.trim();

  return (
    <EditorContainer data-testid="TermEditor">
      <EditorContent>
        {hasDiff && showDiff && <TermDiff previousText={currentText} currentText={newText} />}
        <EditorEditorContainer>
          <TermEditorTextArea
            focusOnMount
            value={newText}
            onSave={finishEditing}
            onCancel={onCancel}
            onChange={setNewText}
          />
          <TermEditorActions helpText={helpText} onSave={finishEditing} onCancel={onCancel} />
        </EditorEditorContainer>
      </EditorContent>
    </EditorContainer>
  );
};
