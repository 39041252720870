import {VoyagePoint} from '../VoyageInformation/VoyageTypes';
import {getCoordinatesFromCountryOrTradingArea} from '../../../../queries/useGetCoordinatesFromCountryOrTradingAreaQuery';
import {isPointInSECA} from './isPointInSECA';
import {geoJsonLatLngToGeolibCoordinates} from './geoJsonLatLngToGeolibCoordinates';
import {Coordinates} from '../../../../utils/Coordinates';
import {TODO} from '../../../../utils/TODO';

type Vessel = TODO;

export const loadNextOpenLocation = async (params: {vessel: Vessel}): Promise<VoyagePoint | undefined> => {
  const locations =
    (params.vessel.stations ?? []).find((station: TODO) => station.type === 'nextopen')?.locations ?? [];

  if (locations.length > 0 && locations[0].coordinates) {
    const point: VoyagePoint = {
      isPort: false,
      isInSECA: isPointInSECA({
        point: geoJsonLatLngToGeolibCoordinates(locations[0].coordinates as Coordinates),
      }),
      countryCode: locations[0].country,
      coordinates: locations[0].coordinates,
      name: locations[0].name,
      portCosts: 0,
      otherDuration: [],
      load: 0,
      types: ['nextOpen'],
      dis: 0,
    };
    return point;
  } else if (locations.length > 0) {
    const coords = await getCoordinatesFromCountryOrTradingArea({
      searchTag: locations[0].name,
      type: locations[0].type,
    });

    if (coords === undefined) {
      return undefined;
    }

    return {
      isPort: false,
      isInSECA: isPointInSECA({
        point: geoJsonLatLngToGeolibCoordinates(coords),
      }),
      coordinates: coords!,
      name: locations[0].name,
      portCosts: 0,
      otherDuration: [],
      load: 0,
      dis: 0,
      types: ['nextOpen'],
    };
  } else {
    // TODO Later get from AIS-api when no nextopen position

    return undefined;
  }
};
