import {FC, useEffect} from 'react';
import {Space, Typography} from 'antd';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Spacer} from '../../../atoms/Spacer/Spacer';
import {showNotification} from '../../../utils/notification';
import {CompanyEditSubscriptionPlanEnum} from '../../../api/symfony/generated';
import {useSelector} from '../../../redux/react-redux';
import {getSubscriptionPlan, getSubscriptionPlanName} from '../../../redux/selectors';
import {getSubscriptionPlanNameFromSlug} from '../../../utils/Plans';
import {StepContainer, YesPleaseButton, NoThanksButton} from './shared';
import {useSendChangeSubscriptionMail} from './utils';

const {Title} = Typography;

export const CompanySubscriptionUpgradeScreen: FC = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams('plan');
  const targetPlanSlug = params.get('plan') as CompanyEditSubscriptionPlanEnum | undefined;
  const currentPlanSlug = useSelector(getSubscriptionPlan);
  const targetPlanTitle = getSubscriptionPlanNameFromSlug(targetPlanSlug!);
  const currentPlanTitle = useSelector(getSubscriptionPlanName);

  const sendChangeSubscriptionMail = useSendChangeSubscriptionMail();

  useEffect(() => {
    if (!targetPlanSlug || !currentPlanSlug) {
      navigate(`/subscription`);
    }
    if (targetPlanSlug === currentPlanSlug) {
      showNotification(`You are already subscribed to ${currentPlanTitle}.`, 'info', 5);
      navigate(`/subscription`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlanSlug, targetPlanSlug]);

  const onConfirmClick = async () => {
    sendChangeSubscriptionMail(targetPlanSlug!);
  };

  return (
    <StepContainer data-testid="CompanySubscriptionUpgrade">
      <Title level={2}>Ready to tailor your plan?</Title>
      <Title level={4}>
        Whether adding features or simplifying, we are here to change your subscription.
        <br />
        We will reach out soon to adjust your account and billing details.
      </Title>
      <Spacer height={48} />
      <Space direction="vertical">
        <YesPleaseButton type={'primary'} onClick={onConfirmClick}>
          Yes, upgrade to {targetPlanTitle}
        </YesPleaseButton>
        <Link to={'/subscription'}>
          <NoThanksButton type={'link'}>No thanks, keep me subscribed to {currentPlanTitle}</NoThanksButton>
        </Link>
      </Space>
    </StepContainer>
  );
};
