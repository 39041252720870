import React, {FC} from 'react';
import styled from 'styled-components';
import {ConsumptionTable, DimensionsTable, IntakeTable} from '../../../CargoVesselForm/helper';
import DetailFeatures from '../../../../components/DetailFeatures';
import ImageThumbnailGrid from '../../../../components/ImageThumbnailGrid';
import DocumentsGrid from '../../../../components/DocumentsGrid';
import {Icon} from '../../../../atoms/Icon';
import {Consumption} from '../../../MyFleetScreens/MyFleetScreen/VesselPositionListModal/useMyFleetQuery/useMyFleetQuery';
import {StyledCard} from '../../shared';
import {Button} from 'antd';
import {VesselDetailsVessel} from '../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {LastDestinations} from '../../LastDestinations/LastDestinations';
import {NextDryDockTable} from './NextDryDockTable';

export const GeneralTechnicalTab: FC<{
  vessel: VesselDetailsVessel;
  showVoyagePreview: boolean;
  showVoyageDetails: () => void;
}> = ({vessel, showVoyagePreview, showVoyageDetails}) => {
  return (
    <StyledCard>
      <FlexContainer>
        <GeneralDetailsContainer>
          <BoxFull>
            <BoxTitle>Dimensions</BoxTitle>
            <DimensionsTable vessel={vessel}></DimensionsTable>
          </BoxFull>
          <BoxHalf>
            <BoxTitle>Intake</BoxTitle>
            <IntakeTable vessel={vessel} />
          </BoxHalf>
          <BoxHalf>
            <BoxTitle>Features</BoxTitle>
            <DetailFeatures
              detailObject={vessel}
              iceClass={vessel.iceClass}
              segment={vessel.vesselType}
              type={'vessel'}
            />
          </BoxHalf>
          <BoxHalf>
            <BoxTitle>Next dry dock</BoxTitle>
            <NextDryDockTable vessel={vessel} />
          </BoxHalf>
          {vessel.consumptions.some((c: Consumption) => c.type.length > 0) ? (
            <BoxTwoThird>
              <BoxTitle>Speed &amp; Consumption</BoxTitle>
              <ConsumptionTable {...vessel} />
            </BoxTwoThird>
          ) : null}
          {vessel.documents?.length ? (
            <BoxFull>
              <BoxTitle>Documents</BoxTitle>
              <DocumentsGrid documents={vessel.documents} />
            </BoxFull>
          ) : null}
          {vessel.images?.length ? (
            <BoxFull>
              <BoxTitle>Images</BoxTitle>
              <ImageThumbnailGrid images={vessel.images} />
            </BoxFull>
          ) : null}
        </GeneralDetailsContainer>
        {showVoyagePreview && (
          <RouteContainer>
            <BoxFull>
              <ClickableBoxTitle onClick={() => showVoyageDetails()}>
                Last destinations <Icon type={'arrow-right'} size={'small'} />
              </ClickableBoxTitle>
              <LastDestinations imo={vessel.imo} />
              <Button type="primary" onClick={() => showVoyageDetails()} style={{width: '100%', margin: '10px 0 20px'}}>
                See more voyage details
              </Button>
            </BoxFull>
          </RouteContainer>
        )}
      </FlexContainer>
    </StyledCard>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
`;

const GeneralDetailsContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  justify-content: start;
  flex-shrink: 1;
  width: auto;
  padding: 0;
`;

const RouteContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  justify-content: start;
  flex-shrink: 0;
  width: 33%;
  padding: 0;
`;

const Box = styled.div`
  padding: 20px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const BoxHalf = styled(Box)`
  width: 50%;
`;

const BoxTwoThird = styled(Box)`
  width: 66%;
`;

const BoxFull = styled(Box)`
  width: 100%;
`;

const BoxTitle = styled.h3`
  font-size: var(--font-size-lg);
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 0;
`;

const ClickableBoxTitle = styled(BoxTitle)`
  cursor: pointer;
`;
