import {SWITCHES} from '../../mapOptions/switches';
import {MapSwitches} from './types';

export type ItemViewOption = 'all' | 'portfolio' | 'market' | 'none';
export type MapDetailPortAreaItemViewState = {
  vessels: ItemViewOption;
  cargoes: ItemViewOption;
};

export const getItemView = (switches?: Partial<MapSwitches>): MapDetailPortAreaItemViewState => {
  const itemView: MapDetailPortAreaItemViewState = {
    vessels: 'none',
    cargoes: 'none',
  };
  if (!switches) {
    return itemView;
  }
  if (switches[SWITCHES.VESSEL_MARKET]?.state && switches[SWITCHES.VESSEL_PORTFOLIO]?.state) {
    itemView.vessels = 'all';
  } else if (!switches[SWITCHES.VESSEL_MARKET]?.state && switches[SWITCHES.VESSEL_PORTFOLIO]?.state) {
    itemView.vessels = 'portfolio';
  } else if (switches[SWITCHES.VESSEL_MARKET]?.state && !switches[SWITCHES.VESSEL_PORTFOLIO]?.state) {
    itemView.vessels = 'market';
  }
  if (switches[SWITCHES.CARGO_MARKET]?.state && switches[SWITCHES.CARGO_PORTFOLIO]?.state) {
    itemView.cargoes = 'all';
  } else if (!switches[SWITCHES.CARGO_MARKET]?.state && switches[SWITCHES.CARGO_PORTFOLIO]?.state) {
    itemView.cargoes = 'portfolio';
  } else if (switches[SWITCHES.CARGO_MARKET]?.state && !switches[SWITCHES.CARGO_PORTFOLIO]?.state) {
    itemView.cargoes = 'market';
  }
  return itemView;
};
