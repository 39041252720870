import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {getTradingAreas} from '../../../../redux/ApiService/MapService/MapService';
import {TradingArea} from '../../../../redux/ApiService/MapService/GetTradingAreasResponse';
import {useDispatch} from '../../../../redux/react-redux';

export const useGetTradingAreasQuery = (): UseQueryResult<TradingArea[]> => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['tradingAreas'],
    queryFn: async () => {
      const response = await dispatch(getTradingAreas());
      return response.items;
    },
  });
};
