import {useChangeBrowserUrl} from '../../utils/useChangeBrowserUrl';
import {showNofificationAfterPageReload} from '../../components/FlashNotification/FlashNotification';
import {useLocation} from 'react-router-dom';
import {useReloadPageHard} from '../../utils/useReloadPage';

/**
 * Performs a page reload and goes to a safe, workspace independent URL.
 * For example, a vessel page details page is not safe because it is workspace dependent
 * - the vessel may only be visible in a specific workspace.
 */
export const useReloadPageAndGoToSafeUrl = () => {
  const location = useLocation();
  const reloadPage = useReloadPageHard();
  const changeBrowserUrl = useChangeBrowserUrl();

  const reloadPageAndGoToSafeUrl = () => {
    if (isUrlWorkspaceDependent(location.pathname)) {
      showNofificationAfterPageReload({
        message: 'You were moved to the dashboard because the previous URL is workspace dependent',
        duration: 7,
        type: 'success',
      });
      // Go to a safe url and reload the page.
      changeBrowserUrl('/dashboard');
    } else {
      // We are at a safe url, just reload the page.
      reloadPage();
    }
  };

  return reloadPageAndGoToSafeUrl;
};

const WorkspaceDependentUrlPatterns: RegExp[] = [
  /^\/vessel\/\d+/,
  /^\/my-fleet\/\d+/,
  /^\/cargo\/\d+/,
  /^\/my-cargoes\/\d+/,
  /^\/portfolio\/vessel\/\d+\/edit/,
  /^\/projects\/\d+/,
  /^\/chartercalculator\/voyagecharter/,
  /^\/chartercalculator\/multi/,
];

export const isUrlWorkspaceDependent = (pathname: string): boolean =>
  WorkspaceDependentUrlPatterns.some(workspaceDependentUrlPattern => pathname.match(workspaceDependentUrlPattern));
