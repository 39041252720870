/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {MapPort, MapPortFromJSON, MapPortFromJSONTyped, MapPortToJSON} from './';

/**
 *
 * @export
 * @interface ContainerPort
 */
export interface ContainerPort {
  /**
   *
   * @type {MapPort}
   * @memberof ContainerPort
   */
  portInformation: MapPort;
  /**
   * The global share of all visits from container vessels in percent
   * @type {number}
   * @memberof ContainerPort
   */
  globalShare: number;
}

export function ContainerPortFromJSON(json: any): ContainerPort {
  return ContainerPortFromJSONTyped(json, false);
}

export function ContainerPortFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerPort {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    portInformation: MapPortFromJSON(json['portInformation']),
    globalShare: json['globalShare'],
  };
}

export function ContainerPortToJSON(value?: ContainerPort | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    portInformation: MapPortToJSON(value.portInformation),
    globalShare: value.globalShare,
  };
}
