import {useContext, FC} from 'react';
import {VoyageCharterContext} from '../../Context/VoyageCharterContext';
import styled from 'styled-components';
import numeral from 'numeral';

export const Visualization: FC = () => {
  const voyageContext = useContext(VoyageCharterContext);

  const robs = voyageContext.state.inputState.vessel.remainingOnBoards;

  return (
    <Container>
      {robs.map((rob, index) => (
        <Row key={index}>
          <FuelType>{rob.bunkerType.toUpperCase()}</FuelType>
          <Quantity>{numeral(rob.quantity).format('0,0')} MTS</Quantity>
          <Price>{numeral(rob.price).format('$ 0,0')} /MT</Price>
        </Row>
      ))}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
`;

const Row = styled.div`
  display: flex;
`;

const FuelType = styled.div`
  width: 50px;
`;
const Price = styled.div`
  flex: 1;
  text-align: right;
`;

const Quantity = styled.div`
  width: 80px;
  padding-right: 15px;
  text-align: right;
`;
