import {z} from 'zod';

export const CoordinatesSchema = z.tuple([z.number(), z.number()]);

export type LatLonCoordinates = [latitude: number, longitude: number];
export type LonLatCoordinates = [longitude: number, latitude: number];
/**
 * @deprecated Use LatLonCoordinates or LonLatCoordinates instead
 */
export type Coordinates = [number, number];

export type CoordinatesObject = {lat: number; lon: number};

export const transformLatLonFromObject = (object: CoordinatesObject): LatLonCoordinates => [object.lat, object.lon];
export const transformLonLatFromObject = (object: CoordinatesObject): LonLatCoordinates => [object.lon, object.lat];

export const transformObjectFromLatLon = (latLon: LatLonCoordinates): CoordinatesObject => {
  return {
    lat: latLon[0],
    lon: latLon[1],
  };
};

export const transformObjectFromLonLat = (lonLat: LonLatCoordinates): CoordinatesObject => {
  return {
    lon: lonLat[0],
    lat: lonLat[1],
  };
};
