import {FilterItem} from '../../api/symfony/generated';
import {FilterBranchDefinitionsDefault, FilterInternalType} from './FilterBranchDefinition';

/**
 * The API for the useFilterProvider hook.
 *
 * When created, the FilterProviderApi infers its types from the FilterBranchDefinitions the FilterProviderProps receive.
 *
 * When used as a prop type, you can pass a FilterBranchDefinitions object to the FilterProviderProps to define the necessary filter branches.
 *
 * @template FilterBranchDefinitions - The type of filter branch definitions.
 */
export type FilterProviderApi<FilterBranchDefinitions extends FilterBranchDefinitionsDefault> = {
  /**
   * A name that is supposed to identify the FilterProvider instance
 
   */
  name: string;
  /**
   * The current filter settings in internal Format.
   */
  internalFilterSettings: Partial<FilterInternalType<FilterBranchDefinitions>>;
  /**
   * The values used to reset a filter or all filters.
   */
  defaults: FilterInternalType<FilterBranchDefinitions>;
  /**
   * The number of active filters.
   */
  appliedFilterCount: number;
  /**
   * Whether any filter is active.
   */
  isAnyFilterApplied: boolean;
  /**
   * List of active filter branches.
   * For example, ['vessel', 'openDate'].
   */
  appliedFilters: Record<keyof FilterBranchDefinitions, boolean>;
  /**
   * API filter id, for example for a request to /api/portfolio/filters/vessel/:id.
   */
  activeFilterId: number | null;
  /**
   * Seems to be 'name' all the time.
   */
  activeFilterName: string | undefined | null;
  /**
   * Whether the "Save" button should be shown and enabled.
   */
  disableSaveButton: boolean;
  /**
   * Resets all branches to defaults.
   */
  resetAll: () => void;
  /**
   * To be called when the user clicks 'Apply' in a FilterButton.
   * Updates a filter branch with the given values.
   */
  onApplyClick: <BranchKey extends keyof FilterBranchDefinitions>(
    branch: BranchKey,
    fields: Partial<FilterBranchDefinitions[BranchKey]['defaults']>
  ) => void;
  /**
   * To be called when the user clicks 'Reset' in a FilterButton .
   * Resets the given branch to default values.
   */
  onResetClick: <BranchKey extends keyof FilterBranchDefinitions>(branch: BranchKey) => void;
  /**
   * To be called when the user clicks the global 'Reset' link.
   * Resets all branches to defaults.
   */
  onResetAllClick: () => void;
  /**
   * Loads the given filter into this filter provider.
   * The filter is asserted to be in API format and is converted to internal format.
   *
   * @param options enables the caller to suppress the onFilterSettingsChange event.
   */
  loadFilterItem: (filter: FilterItem, options?: LoadFilterItemOptions) => Promise<void>;
};

export interface LoadFilterItemOptions {
  /**
   * Whether the onFilterSettingsChange event should be triggered.
   */
  onFilterSettingsChangeEnabled?: boolean;
}

export const LoadFilterItemOptionsDefault: LoadFilterItemOptions = {
  onFilterSettingsChangeEnabled: true,
};
