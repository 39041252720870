import anime from 'animejs';
import {RefObject} from 'react';
import {AnimationEvent} from '../model/AnimationEvent';
import {MapRef} from 'react-map-gl';
import {LonLatCoordinates} from '../../../../../utils/Coordinates';
import {assert, assertUnreachable} from '../../../../../utils/assert';
import {FeatureToggles, VoyageAnimationAnimatedPortVisits} from '../../../../../utils/FeatureToggles';
import {atBegin} from './easing';
import {ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT} from './ZoomLevel';
import dayjs from 'dayjs';

export const animatePortExit = ({
  animationEventIndex,
  animationEvents,
  animationSpeed,
  timeline,
  mapRef,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
  mapRef: RefObject<MapRef>;
}) => {
  const portExitEvent = animationEvents[animationEventIndex];
  assert(portExitEvent.type === 'port-exit');

  switch (FeatureToggles.voyageAnimationAnimatedPortVisits) {
    case VoyageAnimationAnimatedPortVisits.Disabled:
      animationEventIndex++;
      return animationEventIndex;
    case VoyageAnimationAnimatedPortVisits.Safe:
      return animatePortExitSafe({
        animationEventIndex,
        animationEvents,
        animationSpeed,
        timeline,
        mapRef,
      });
    case VoyageAnimationAnimatedPortVisits.Old:
    // Fall through
    case VoyageAnimationAnimatedPortVisits.New:
      return animatePortExitNew({
        animationEventIndex,
        animationEvents,
        animationSpeed,
        timeline,
        mapRef,
      });
    default:
      assertUnreachable(FeatureToggles.voyageAnimationAnimatedPortVisits);
  }
};

const animatePortExitSafe = ({
  animationEventIndex,
  animationEvents,
  timeline,
  mapRef: _,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
  mapRef: RefObject<MapRef>;
}) => {
  const portExitEvent = animationEvents[animationEventIndex];
  assert(portExitEvent.type === 'port-exit');
  animationEventIndex++;

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log(
        'PORT-Exit IN',
        portExitEvent.portVisit.port.name,
        portExitEvent.timestamp?.toISOString(),
        dayjs().toString()
      );
    },
    duration: 100,
  });

  timeline.add(
    {
      portVisitId: -1,
      duration: 10,
      easing: atBegin,
    },
    '-=300'
  );
  timeline.add({
    cameraFollowsVessel: 1,
    portVisitId: -1,
    duration: 10,
    easing: atBegin,
  });

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log(
        'PORT-Exit OUT',
        portExitEvent.portVisit.port.name,
        portExitEvent.timestamp?.toString(),
        dayjs().toString()
      );
    },
    duration: 100,
  });

  return animationEventIndex;
};

const animatePortExitNew = ({
  animationEventIndex,
  animationEvents,
  timeline,
  mapRef,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
  mapRef: RefObject<MapRef>;
}) => {
  const portExitEvent = animationEvents[animationEventIndex];
  assert(portExitEvent.type === 'port-exit');
  animationEventIndex++;

  const portLocation: LonLatCoordinates = [
    portExitEvent.portVisit.port.coordinates.lon,
    portExitEvent.portVisit.port.coordinates.lat,
  ];

  timeline.add({
    duration: 200,
  });
  timeline.add({
    delay: 10,
    begin() {
      // eslint-disable-next-line no-console
      console.log('PORT-Exit');

      mapRef.current!.flyTo({
        zoom: ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT,
        center: portLocation,
        maxDuration: 1000,
      });
    },
    duration: 1200,
  });
  timeline.add(
    {
      portVisitId: -1,
      duration: 10,
      easing: atBegin,
    }
    // '-=300'
  );
  timeline.add({
    cameraFollowsVessel: 1,
    portVisitId: -1,
    duration: 10,
    easing: atBegin,
  });
  return animationEventIndex;
};
