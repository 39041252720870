import {AutoComplete, AutoCompleteProps} from 'antd';
import React, {FC} from 'react';
import styled from 'styled-components';

type SubjectEditorAutoCompleteProps = AutoCompleteProps<string>;

export const SubjectEditorAutoComplete: FC<SubjectEditorAutoCompleteProps> = ({...props}) => {
  return (
    <SubjectTextEditorInput<string>
      {...props}
      size="small"
      options={[
        'Subject to owners reconfirmation / bod approval',
        'Subject to charterers reconfirmation / bod approval',
        'Subject to charterers comments on the TCD',
        'Subject to agreement on CP details',
        'Subject to successful takeover of the ship',
      ].map(value => ({value, label: value}))}
      filterOption={(inputValue, option) =>
        !!option?.value?.toString().toUpperCase().includes(inputValue.toUpperCase())
      }
    />
  );
};

const SubjectTextEditorInput = styled(AutoComplete)`
  width: 100%;
  margin: 0;

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    .ant-select-selection-placeholder {
      padding-left: 4px !important;
      color: var(--color-gray-2) !important;
      user-select: none;
    }

    .ant-select-selection-search-input {
      font-size: var(--font-size) !important;
      padding-top: 1px !important;
      padding-left: 4px !important;
    }
  }
` as typeof AutoComplete;
