import {assert} from '../../../utils/assert';
import {Coordinates} from '../../../utils/Coordinates';
import {MapState} from '../MapContext/Types';
import {getViewStateFromBounds} from './getViewStateFromBounds';
import {ViewState} from '../ViewState';

export const fitMapToBounds = (
  coords: Coordinates[],
  mapState: MapState,
  setViewState: (viewState: ViewState) => void
) => {
  assert(mapState.mapSize);

  try {
    // [[left, bottom], [right, top]]
    const newViewState = getViewStateFromBounds({
      mapSize: mapState.mapSize,
      viewState: mapState.viewState,
      coords,
      options: {
        padding: 50,
      },
    });
    setViewState(newViewState);

    // eslint-disable-next-line no-empty
  } catch (e) {}
};
