import {Card} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';

interface User {
  avatarUrl: string;
  fullName: string;
}

export const UserCard: FC<{user: User; companyName: string}> = ({user, companyName}) => {
  return (
    <StyledUserCard>
      <Image src={user.avatarUrl} />

      <UserCardName>{user.fullName}</UserCardName>
      <Position>{companyName}</Position>
    </StyledUserCard>
  );
};

const StyledUserCard = styled(Card)`
  height: 310px;
  text-align: center;
  background-color: var(--color-white);
`;

const UserCardName = styled.div`
  font-weight: bold;
  margin-top: 20px;
`;

const Image = styled.img`
  height: 105px;
  width: 105px;
  border-radius: 100px;
`;

const Position = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
`;
