import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;

  .action-label {
    display: inline-block;
    width: 0;
    overflow: hidden;
    transition: width 0.1s;
  }
`;

const Wrapper = styled.div<{
  showActions?: boolean;
}>`
  &:hover {
    ${Actions} {
      gap: 4px;
      opacity: 1;
      .action-label {
        width: auto;
      }
    }
  }

  ${props =>
    props.showActions &&
    `
    ${Actions} {
      gap: 4px;
      opacity: 1;
      .action-label {
        width: auto;
      }
    }
  `}
`;

const Header = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: start;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 8px 8px 0;
`;

export {Wrapper, Header, Actions};
