import {FC} from 'react';
import {LoadingDots} from '../shared';
import {NegotiationRoundStatsContainer, NegotiationRoundSummaryTermStats} from './NegotiationRoundTermStats';
import {numberFormat} from '../../../../utils/formatter';
import {NegotiationRoundResultSummary} from '../../../../api/node-backend/generated';
import styled from 'styled-components';

const getRoundResultStatChanges = (roundResults: NegotiationRoundResultSummary[]) => {
  const [lastRound, currentRound] = roundResults.slice(-2);

  // if there is no current round, we are in the first round
  if (!currentRound) {
    return {
      ongoingChange: lastRound.ongoingTerms,
      acceptedChange: lastRound.acceptedTerms,
      rejectedChange: lastRound.rejectedTerms,
    };
  }

  const ongoingChange = currentRound.ongoingTerms - lastRound.ongoingTerms;
  const acceptedChange = currentRound.acceptedTerms - lastRound.acceptedTerms;
  const rejectedChange = currentRound.rejectedTerms - lastRound.rejectedTerms;

  return {
    ongoingChange,
    acceptedChange,
    rejectedChange,
  };
};

const formatChange = (count: number) => numberFormat(count, {}, {signDisplay: 'exceptZero'});

type NegotiationRoundTermStatsChangeProps = {
  roundResultSummaries?: NegotiationRoundResultSummary[];
  showOngoing?: boolean;
  showAccepted?: boolean;
  showRejected?: boolean;
  badgeColor?: string | [string, string, string];
  noChangesText?: string;
  grouped?: boolean;
};

export const NegotiationRoundTermStatsChange: FC<NegotiationRoundTermStatsChangeProps> = ({
  roundResultSummaries,
  showOngoing,
  showAccepted,
  showRejected,
  badgeColor,
  noChangesText = 'No changes',
  grouped,
}) => {
  if (!roundResultSummaries) {
    return <LoadingDots />;
  }

  const {ongoingChange, acceptedChange, rejectedChange} = getRoundResultStatChanges(roundResultSummaries);

  if (!ongoingChange && !acceptedChange && !rejectedChange) {
    return <span data-testid="NegotiationRoundTermStatsChange">{noChangesText}</span>;
  }

  const ongoingLabel = formatChange(ongoingChange);
  const acceptedLabel = formatChange(acceptedChange);
  const rejectedLabel = formatChange(rejectedChange);

  return (
    <TermStatsChangeContainer data-testid="NegotiationRoundTermStatsChange">
      <NegotiationRoundSummaryTermStats
        ongoingTerms={ongoingLabel}
        acceptedTerms={acceptedLabel}
        rejectedTerms={rejectedLabel}
        showOngoing={ongoingChange !== 0 || showOngoing}
        showAccepted={acceptedChange !== 0 || showAccepted}
        showRejected={rejectedChange !== 0 || showRejected}
        badgeColor={badgeColor}
        grouped={grouped}
      />
    </TermStatsChangeContainer>
  );
};

const TermStatsChangeContainer = styled.span`
  width: auto;

  ${NegotiationRoundStatsContainer} {
    justify-content: flex-start;
    & > div {
      flex: 0 1 50px;
    }
  }
`;
