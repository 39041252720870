import {FC} from 'react';
import {TODO} from '../../../../utils/TODO';

export const ModalError: FC<{
  fromMarket: boolean;
  marketOneWasSelected: boolean;
  vesselOption: TODO[] | undefined;
}> = props => {
  if (!props.fromMarket) {
    return null;
  }

  const vesselOptions = props.vesselOption ?? [];
  if (vesselOptions.length > 0) {
    return null;
  }

  if (props.marketOneWasSelected) {
    return null;
  }

  const vesselOptionsAreEmpty = vesselOptions.length === 0;

  return (
    <div className={'modal__error'}>
      {vesselOptionsAreEmpty && 'Disabled due to vessel not found in portfolio'}
      {!vesselOptionsAreEmpty && !props.marketOneWasSelected
        ? 'Please select portfolio entry, as there is more than one'
        : 'well uhm?'}
    </div>
  );
};
