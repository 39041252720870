import {Col} from 'antd';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import {useMemo} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {TODO} from '../../../../utils/TODO';
import {getAlphaForChartColor} from '../../utils/getAlphaForChartColor';
import {VerticalPaddingCard} from '../VerticalPaddingCard';
import {UndefinedLabel} from '../UndefinedLabel';

export const CargoCommodityCategoryChart = ({
  buckets,
  perSender = false,
}: {
  buckets: OfferBucket[];
  perSender?: boolean;
}) => {
  const vesselBuckets = useMemo(() => buckets.filter(bucket => (bucket.numberOfCargoOffers ?? 0) > 0), [buckets]);
  const groupedBuckets = groupBy(
    vesselBuckets.map(bucket => {
      return {
        name: bucket.tags?.find(item => item.name === 'commodityCategory')?.value ?? UndefinedLabel,
        count: bucket.numberOfCargoOffers,
      };
    }),
    'name'
  );

  const groupLenth = Object.keys(groupedBuckets).length;
  const data = Object.entries(groupedBuckets).map(([key, value], index) => {
    const valueSum = sumBy(value, 'count');
    const alpha = getAlphaForChartColor(Math.floor(((index + 1) / groupLenth) * 100));

    return {
      name: key,
      value: valueSum,
      itemStyle: {
        color: `rgba(0, 100, 180, ${alpha})`,
      },
    };
  });

  const rest = data.filter(set => set.name !== 'other');
  const other = [
    ...data
      .filter(set => set.name === 'other')
      .map((set, index) => ({
        ...set,
        itemStyle: {
          color: `rgba(0, 100, 180, ${getAlphaForChartColor(Math.floor((index / rest.length) * 100), 50)})`,
        },
      })),
    {
      name: 'rest',
      value: sumBy(rest, 'value'),
      itemStyle: {
        color: 'rgba(0, 100, 180, 1)',
      },
    },
  ];

  const series: TODO[] = [
    {
      name: 'cargoes',
      type: 'pie',
      stillShowZeroSum: false,
      radius: ['45%', '60%'],
      rotation: -0.5 * Math.PI - (25 / 180) * Math.PI,
      label: {
        margin: [8, 8, 8, 8],
        width: 200,
        fontSize: 13,
      },
      labelLine: {
        show: true,
        length: 10,
        length2: 20,
        smooth: true,
      },
      data: perSender ? data : rest,
      startAngle: -120,
      emphasis: {
        itemStyle: {
          shadowBlur: 1,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
  ];

  if (!perSender) {
    series.push({
      name: 'cargoes',
      type: 'pie',
      stillShowZeroSum: false,
      radius: '35%',
      label: {
        fontSize: 13,
        position: 'inner',
        color: 'var(--color-white)',
      },
      labelLine: {
        show: false,
      },
      data: other,
      startAngle: -120,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    });
  }

  return (
    <Col span={8}>
      <VerticalPaddingCard>
        <ReactECharts
          option={{
            title: {
              text: `Cargoes by commodity (${sumBy(data, 'value')})`,
              left: 'center',
            },
            tooltip: {
              trigger: 'item',
            },
            series,
          }}
          style={{minHeight: 450}}
        />
      </VerticalPaddingCard>
    </Col>
  );
};
