import {createSelector} from 'reselect';
import {makeApiService, ApiServiceEntry, RequestState} from './ApiService';
import {RootState} from '../store';
import {TODO} from '../../utils/TODO';
import {ColumnSort} from '@tanstack/react-table';
import {MarketCargoDatabaseFilter} from '../../components/FilterProvider/Filters/Market/MarketCargoFilterDefinition';
import {MarketVesselDatabaseFilter} from '../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';

const entries: ApiServiceEntry[] = [
  {
    fn: 'post',
    route: '/api/market/filters/{type}',
    method: 'POST',
  },
  {
    fn: 'vessel',
    route: '/api/market/vessel-offers',
    method: 'GET',
  },
  {
    fn: 'cargo',
    route: '/api/market/cargo-offers',
    method: 'GET',
  },
  {
    fn: 'vesselOffer',
    route: '/api/market/vessel-offers/{id}',
    method: 'GET',
  },
  {
    fn: 'cargoOffer',
    route: '/api/market/cargo-offers/{id}',
    method: 'GET',
  },
  {
    fn: 'addToWorkspaceFromCargoMarket',
    route: `/api/market/cargo-offers/{offerID}/workspace/{workspaceID}`,
    method: 'PUT',
  },
  {
    fn: 'addToWorkspaceFromVesselMarket',
    route: `/api/market/vessel-offers/{offerID}/workspace/{workspaceID}`,
    method: 'PUT',
  },
  {
    fn: 'detachOffer',
    route: '/api/market/{type}-offers/{id}/detach',
    method: 'POST',
  },
  {
    fn: 'toggleArchive',
    route: '/api/market/{type}-offers/{id}/archive',
    method: 'PUT',
  },
  {
    fn: 'multiArchive',
    route: '/api/market/{type}-offers/multi/archive',
    method: 'POST',
  },
];

const marketService = makeApiService(entries, {apiName: 'market'});
export const reducers = marketService.reducers;
export const actions = marketService.actions;
export const actionTypes = marketService.actionTypes;
const emptyResponse = {items: [], totalItems: 0, hasError: false};

export interface GetMarketVesselsRequest {
  filterSettings: MarketVesselDatabaseFilter | null;
  page: number;
  pageSize: number;
  sortedBy: ColumnSort;
}

export interface GetMarketCargoesRequest {
  filterSettings: MarketCargoDatabaseFilter | null;
  page: number;
  pageSize: number;
  sortedBy: ColumnSort;
}

export const MarketServiceActions = {
  getVessels: ({filterSettings: filters, page = 1, pageSize = 25, sortedBy}: GetMarketVesselsRequest) =>
    actions.vessel({
      queryParams: {
        pageIndex: page,
        pageSize,
        filters: filters ? JSON.stringify(filters) : undefined,
        sortField: sortedBy.id,
        sortOrder: sortedBy.desc === true ? 'desc' : 'asc',
      },
      transformers: {
        ERROR: () => ({data: {...emptyResponse}}),
      },
    }),
  getCargoes: ({filterSettings, page = 1, pageSize = 25, sortedBy}: GetMarketCargoesRequest) =>
    actions.cargo({
      queryParams: {
        pageIndex: page,
        pageSize,
        filters: filterSettings ? JSON.stringify(filterSettings) : undefined,
        sortField: sortedBy.id,
        sortOrder: sortedBy.desc === true ? 'desc' : 'asc',
      },
      transformers: {
        ERROR: () => ({data: {...emptyResponse}}),
      },
    }),
  getCargoOffer: (id: number) => actions.cargoOffer({params: {id}}),
  getVesselOffer: (id: number) => actions.vesselOffer({params: {id}}),
  addToWorkspaceFromCargoMarket: (offerID: number, workspaceID: number) =>
    actions.addToWorkspaceFromCargoMarket({params: {offerID, workspaceID}}),
  addToWorkspaceFromVesselMarket: (offerID: number, workspaceID: number) =>
    actions.addToWorkspaceFromVesselMarket({params: {offerID, workspaceID}}),
  detachOffer: (id: number, type: string) => actions.detachOffer({params: {id, type}}),
  toggleArchive: (type: string, id: number) => actions.toggleArchive({params: {id, type}}),
  multiArchive: (body: TODO, type: string) => actions.multiArchive({body, params: {type}}),
};

export default MarketServiceActions;

export interface VesselOfferCollection {
  items: TODO[];
  totalItems: number;
  hasError: boolean;
  initial: boolean;
}

export const vesselOfferCollectionSelector = createSelector(
  [
    (state: RootState) => state.api.market.vessel.data,
    (state: RootState) => state.api.market.vessel.state,
    (state: RootState) => state.api.market.vessel.initial,
  ],
  (collection, state, initial): VesselOfferCollection =>
    collection === null
      ? {
          ...emptyResponse,
          hasError: state === RequestState.ERROR,
          initial,
        }
      : {
          ...collection.data,
          initial,
        }
);

export const cargoOfferCollectionSelector = createSelector(
  [
    (state: RootState) => state.api.market.cargo.data,
    (state: RootState) => state.api.market.cargo.state,
    (state: RootState) => state.api.market.cargo.initial,
  ],
  (collection, state, initial) =>
    collection === null
      ? {
          ...emptyResponse,
          hasError: state === RequestState.ERROR,
          initial,
        }
      : {
          ...collection.data,
          initial,
        }
);

export const portsCollectionSelector = createSelector(
  [
    (state: RootState) => state.api.market.ports.data,
    (state: RootState) => state.api.market.ports.state,
    (state: RootState) => state.api.market.ports.initial,
  ],
  (collection, state, initial) =>
    collection === null
      ? {
          totalCount: 0,
          page: 1,
          pageSize: 25,
          sortCol: 'code',
          sortOrder: 'asc',
          list: [],
          hasError: state === RequestState.ERROR,
          initial,
        }
      : {
          ...collection.data,
          hasError: state === 3,
          initial,
        }
);
