/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselOfferUpdateRequestVesselIntakes
 */
export interface VesselOfferUpdateRequestVesselIntakes {
  /**
   *
   * @type {string}
   * @memberof VesselOfferUpdateRequestVesselIntakes
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof VesselOfferUpdateRequestVesselIntakes
   */
  quantity?: number;
}

export function VesselOfferUpdateRequestVesselIntakesFromJSON(json: any): VesselOfferUpdateRequestVesselIntakes {
  return VesselOfferUpdateRequestVesselIntakesFromJSONTyped(json, false);
}

export function VesselOfferUpdateRequestVesselIntakesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselOfferUpdateRequestVesselIntakes {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: !exists(json, 'type') ? undefined : json['type'],
    quantity: !exists(json, 'quantity') ? undefined : json['quantity'],
  };
}

export function VesselOfferUpdateRequestVesselIntakesToJSON(value?: VesselOfferUpdateRequestVesselIntakes | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    quantity: value.quantity,
  };
}
