import {CustomColumnDef} from '../../DataGrid/DataGrid';
import {ProjectVessel} from '../../../api/symfony/generated';

export const vesselActionsColumn = ({
  accessorFn,
  minWidth = 4,
  sortable = false,
}: {
  accessorFn: (d: $TSFixMe) => React.ReactElement;
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<ProjectVessel> => ({
  id: 'vessel.actions',
  header: '',
  enableSorting: sortable,
  minWidth,
  className: 'datagrid-td-action',
  accessorFn,
  cell: info => info.getValue(),
});
