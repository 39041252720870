import {CountryTag} from '../../SearchPicker/makeTag/CountryTag';
import {PortTag} from '../../SearchPicker/makeTag/PortTag';
import {TradingAreaTag} from '../../SearchPicker/makeTag/TradingAreaTag';
import {assertUnreachable} from '../../../utils/assert';
import {FC} from 'react';
import {Location} from '../../SearchPicker/Type';

export const LocationTag: FC<{location: Location; onRemoveLocation: () => void}> = ({location, onRemoveLocation}) => {
  const locationType = location.type;
  switch (locationType) {
    case 'country':
      return (
        <CountryTag
          key={location.id}
          country={location}
          onClose={() => {
            onRemoveLocation();
          }}
        />
      );
    case 'port':
      return (
        <PortTag
          key={location.id}
          port={location}
          onClose={() => {
            onRemoveLocation();
          }}
        />
      );
    case 'tradingarea':
      return (
        <TradingAreaTag
          key={location.id}
          tradingArea={location}
          onClose={() => {
            onRemoveLocation();
          }}
        />
      );
    default:
      assertUnreachable(locationType);
  }
};
