import {Flex} from 'antd';
import {PlanBox} from './PlanBox';
import {ContractDurationPicker, ContractDuration} from '../ContractDurationPicker';
import {getSubscriptionPlan} from '../../../../redux/selectors';
import {useSelector} from '../../../../redux/react-redux';
import {planUvps} from './planUvps';
import {BadgeLightBlue} from '../PlanBadge';

export const PlanUvpComparison = ({
  showActionButtons = true,
  contractDuration,
  onChangeContractDuration,
}: {
  showActionButtons?: boolean;
  contractDuration: ContractDuration;
  onChangeContractDuration: (contractDuration: ContractDuration) => void;
}) => {
  const subscribedPlan = useSelector(getSubscriptionPlan);

  return (
    <div>
      <Flex gap={10} justify="end" align="center">
        <ContractDurationPicker contractDuration={contractDuration} setContractDuration={onChangeContractDuration} />
        <span>Switch to annual billing</span>
        <BadgeLightBlue $isSmall>Save 15%</BadgeLightBlue>
      </Flex>
      <Flex gap={20} justify="center" align="start" style={{margin: '40px 0'}}>
        {planUvps.map((plan, idx) => (
          <PlanBox
            key={idx}
            targetPlan={plan}
            showActionButton={showActionButtons}
            subscribedPlanSlug={subscribedPlan}
            contractDuration={contractDuration}
          />
        ))}
      </Flex>
    </div>
  );
};
