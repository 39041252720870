import React, {FC} from 'react';
import {CalcTimeLineRoute} from '../../VoyageTypes';
import {Divider} from 'antd';
import {Attribute, TabSize} from '../../../../../../atoms/Attribute/Attribute';
import {durationAccuracy} from '../../utils/visualizationAccuracy';

const tabSize: TabSize = 'xxl';

export const RouteOverlay: FC<{route: CalcTimeLineRoute}> = ({route}) => {
  const speed = route.item.consumption?.speed ?? 0.0001;
  const etmal = speed * 24;
  const distance = route.item.distance;
  const durationWithoutSeaMargin = distance / etmal;
  const seaMargin = route.item.seaMargin ?? 0;
  const durationConditionedBySeaMargin = (seaMargin / 100) * durationWithoutSeaMargin;

  return (
    <div style={{color: 'var(--color-black)'}}>
      <Divider orientation={'center'}>Duration at sea</Divider>
      <Attribute label="Speed" value={`${speed} knots`} tab={tabSize} />
      <Attribute label="ETMAL" value={`${etmal} nm`} tab={tabSize} />
      <Attribute label="Distance" value={`${distance} nm`} tab={tabSize} />
      <Attribute
        label="Duration without sea margin"
        value={`${durationWithoutSeaMargin.toFixed(durationAccuracy)} d`}
        tab={tabSize}
      />
      <Attribute label="Sea margin" value={`${seaMargin} %`} tab={tabSize} />
      <Attribute
        label="Duration conditioned by sea margin"
        value={`${durationConditionedBySeaMargin.toFixed(durationAccuracy)} d`}
        tab={tabSize}
      />
      <Attribute label="Duration" value={`${route.outputItem?.duration.toFixed(durationAccuracy)} d`} tab={tabSize} />
    </div>
  );
};
