import React, {FC} from 'react';
import {WorkspaceSelect} from '../../WorkspaceSelect/WorkspaceSelect';
import {useWorkspacesQuery} from '../../../queries/useWorkspacesQuery';
import {CompanyWorkspace} from '../../../api/symfony/generated/models/CompanyWorkspace';

export const HeaderWorkspaceSelect: FC = () => {
  const companyWorkspacesQuery = useWorkspacesQuery();
  const companyWorkspaces: CompanyWorkspace[] = companyWorkspacesQuery.isSuccess ? companyWorkspacesQuery.data : [];
  // Only show this component if the user has any company workspaces.
  if (companyWorkspaces.length === 0) {
    return null;
  }

  return <WorkspaceSelect colorScheme="BlueOnGrey" placement="bottomRight" />;
};
