import {Card} from 'antd';
import {Map} from './Map';
import styled, {css} from 'styled-components';
import {WidgetTitle} from '../components/WidgetTitle';

export const MapWidget = (props: {showBody: boolean; onChangeShowBody: (showBody: boolean) => void}) => {
  return (
    <StyledMapCard
      $showBody={props.showBody}
      title={
        <WidgetTitle
          icon="world"
          title="Map"
          showBody={props.showBody}
          onToggleBody={() => {
            props.onChangeShowBody(!props.showBody);
          }}
        />
      }>
      <Map height={'100%'} key={'map'} />
    </StyledMapCard>
  );
};

const StyledMapCard = styled(Card)<{$showBody?: boolean}>`
  flex-direction: column;
  padding-top: 10px;

  .ant-card-body {
    flex: 1;
    display: none;
    padding: 0px;
  }

  ${({$showBody}) =>
    $showBody &&
    css`
      height: 100%;
      display: flex;

      .ant-card-body {
        display: block;
      }
    `}
`;
