/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CreateNegotiationCounterpartDto
 */
export interface CreateNegotiationCounterpartDto {
  /**
   * The name of the counterpart of the negotiation. This information is only for this negotiation. It is not mapped to a company on the platform. Its only visible to both parties.
   * @type {string}
   * @memberof CreateNegotiationCounterpartDto
   */
  name: string;
  /**
   * The email address of the counterpart of the negotiation. We will send emails to the address. We do NOT map the email to another user or actor. It is possible that we register multiple users with the same email address.
   * @type {string}
   * @memberof CreateNegotiationCounterpartDto
   */
  email: string;
  /**
   * The company name of the counterpart of the negotiation. This information is only for this negotiation. It is not mapped to a company on the platform. Its only visible to both parties.
   * @type {string}
   * @memberof CreateNegotiationCounterpartDto
   */
  company: string;
}

export function CreateNegotiationCounterpartDtoFromJSON(json: any): CreateNegotiationCounterpartDto {
  return CreateNegotiationCounterpartDtoFromJSONTyped(json, false);
}

export function CreateNegotiationCounterpartDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNegotiationCounterpartDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    email: json['email'],
    company: json['company'],
  };
}

export function CreateNegotiationCounterpartDtoToJSON(value?: CreateNegotiationCounterpartDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    company: value.company,
  };
}
