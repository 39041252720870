import {useState} from 'react';
import {Button, Dropdown, Menu} from 'antd';
import {ModalActions} from '../../../redux/Modal';
import {ClipboardItem, GridClipboardActions} from '../../../redux/GridClipboard';
import {MultiCalcVesselModal} from '../../../components/CharterCalculation/MultiCalc/MultiCalcVesselModal/MultiCalcVesselModal';
import {AddClipboardItemsToProjectModal} from '../AddClipboardItemsToProjectModal/AddClipboardItemsToProjectModal';
import {useDispatch} from '../../../redux/react-redux';
import {MenuItemType} from 'antd/es/menu/interface';
import {MultiPortfolioAddModal} from '../../../components/Modal/Multiselect/Market/MultiselectAddToPortfolio';

type Props = {
  items: ClipboardItem[];
  onGridDataChange: () => void;
};

export const MarketCargoActionButton = (props: Props) => {
  const [show, setShow] = useState(false);
  const {items} = props;
  const count = items.length;

  if (count === 0) {
    return null;
  }

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={visible => {
        setShow(visible);
      }}
      open={show}
      menu={{
        items: [
          {
            key: '1',
            label: (
              <DropdownMenu
                onClick={() => {
                  setShow(false);
                }}
                {...props}
              />
            ),
          },
        ],
      }}>
      <Button className="uppercase" type={'primary'}>
        {count} {count === 1 ? 'cargo' : 'cargoes'} selected
      </Button>
    </Dropdown>
  );
};
type DropdownMenuProps = Props & {
  onClick: () => void;
};
const DropdownMenu = (props: DropdownMenuProps) => {
  const dispatch = useDispatch();
  const [multiCalcModalVisible, setMultiCalcModalVisible] = useState(false);
  const [addToProjectVisible, setAddToProjectVisible] = useState(false);

  return (
    <>
      <Menu
        selectedKeys={[]}
        items={
          [
            {
              key: 'addToPortfolios',
              label: 'Add to My Cargoes',
              onClick: () => {
                dispatch(
                  ModalActions.show(
                    <MultiPortfolioAddModal type="cargo" data={props.items} reload={props.onGridDataChange} />
                  )
                );
                props.onClick();
              },
            },
            {
              key: 'multiCalc',
              label: <div data-testid="MultiCalcMenuItem">Multi calculation (VC)</div>,
              onClick: () => {
                props.onClick();
                setMultiCalcModalVisible(true);
                props.onClick();
              },
            },
            {
              key: 'addToProject',
              label: <div data-testid="AddToProjectItem">Add to project</div>,
              onClick: () => {
                props.onClick();
                setAddToProjectVisible(true);
                props.onClick();
              },
            },
            {
              key: 'addToProject',
              label: 'Unselect all cargoes',
              onClick: () => {
                dispatch(GridClipboardActions.removeAllItemsFromClipboard('cargoes', 'market'));
                props.onClick();
              },
            },
          ].filter(Boolean) as MenuItemType[]
        }></Menu>

      <MultiCalcVesselModal
        visible={multiCalcModalVisible}
        onClose={() => {
          setMultiCalcModalVisible(false);
        }}
      />
      <AddClipboardItemsToProjectModal
        projectType={'vessel'}
        visible={addToProjectVisible}
        onCancel={() => setAddToProjectVisible(false)}
      />
    </>
  );
};
