import {FC, useMemo} from 'react';
import {DataGrid, DataGridProps} from '../../../../components/DataGrid/DataGrid';
import EmptyStateMarketFiltersCargo from '../../EmptyState/EmptyStateMarketFiltersCargo';
import NoResults from '../../EmptyState/NoResults';
import {cargoOfferCollectionSelector} from '../../../../redux/ApiService/MarketService';
import EmptyStateMarket from '../../EmptyState/EmptyStateMarket';
import {MarketExpander, MarketSubComponent} from '../../SubComponents';
import {GridClipboardActions} from '../../../../redux/GridClipboard';
import {GridMetaData} from '../../GridMetaData';
import {TODO} from '../../../../utils/TODO';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {getSubscriptionPlanLimits} from '../../../../redux/selectors';
import {getCircularsLimitedByPlan} from '../../utils/getCircularsLimitedByPlan';
import {easySharingPromotionColumn} from '../../../../components/GridLayout/columns/shared/easySharingPromotionColumn';
import {getMarketGridTdProps} from '../../utils/getMarketGridTdProps';
import {getCargoColumns} from './getCargoColumns';
import {getClipBoardColumn} from './GetClipBoardColumn';

type OwnCargoGridProps = {
  totalCount?: $TSFixMe;
  onPageChange?: $TSFixMeFunction;
  onPageSizeChange?: $TSFixMeFunction;
  onSortedChange?: $TSFixMeFunction;
  onSettingChange?: (gridMetaData: Partial<GridMetaData>) => void;
  showProjectModal?: $TSFixMe;
  showPortfolioModal?: $TSFixMe;
  isFiltered: boolean;
  sharedCircularsBlurred: boolean;
  isSubMarket: boolean;
  gotoFilter: () => void;
  onGridDataChange: () => void;
};

type CargoGridProps = OwnCargoGridProps & Omit<DataGridProps<TODO>, 'columns' | 'data'>;

export const CargoGrid: FC<CargoGridProps> = ({
  isFiltered,
  isSubMarket,
  sharedCircularsBlurred,
  onGridDataChange,
  onSettingChange,
  gotoFilter,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const clipboardState = useSelector(state => state.gridClipboard.market.cargoes);
  const checkAllCargoes = useSelector(state => state.gridClipboard.market.checkAllCargoes);
  const cargoOffers = useSelector(cargoOfferCollectionSelector);
  const cargoMeta = useSelector(state => state.market.cargo);
  const loading = useSelector(state => state.api.market.cargo.loading);
  const {marketGridLimit} = useSelector(getSubscriptionPlanLimits);

  const isNewSubMarket = isSubMarket && !isFiltered;
  const {totalCountLimited, itemsLimited} = getCircularsLimitedByPlan<typeof cargoOffers.items>(
    isNewSubMarket,
    loading,
    marketGridLimit,
    cargoOffers,
    cargoMeta
  );

  const addItemToClipboard = (item: TODO) =>
    dispatch(
      GridClipboardActions.addItem({
        section: 'market',
        subsection: 'cargoes',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );

  const removeItemFromClipboard = (item: TODO) =>
    dispatch(
      GridClipboardActions.removeItem({
        section: 'market',
        subsection: 'cargoes',
        item: {
          id: item.id,
          name: item.name,
        },
      })
    );

  const addAllItemsToClipboard = () => dispatch(GridClipboardActions.addAllGridItemsToClipboard('cargoes', 'market'));

  const removeAllItemsFromClipboard = () =>
    dispatch(GridClipboardActions.removeAllItemsFromClipboard('cargoes', 'market'));

  const displayEmptyState = () => {
    if (isSubMarket && !isFiltered) {
      return <EmptyStateMarketFiltersCargo onClick={gotoFilter} />;
    }
    if (isFiltered && cargoOffers.totalItems === 0) {
      return <NoResults type={'cargoes'} />;
    }
    if (!isSubMarket && !isFiltered && cargoOffers.totalItems === 0) {
      return <EmptyStateMarket type={'cargoes'} />;
    }
    return null;
  };

  const columns = useMemo(() => {
    return [
      easySharingPromotionColumn(sharedCircularsBlurred),
      getClipBoardColumn({
        clipboardState,
        checkAllCargoes,
        removeItemFromClipboard,
        removeAllItemsFromClipboard,
        addAllItemsToClipboard,
        addItemToClipboard,
      }),
      {
        size: 45,
        width: 45,
        id: 'expander',
        header: '',
        cell: ({row}) => {
          return (
            <div
              onClick={() => {
                row.toggleExpanded();
              }}>
              <MarketExpander
                isExpanded={row.getIsExpanded()}
                number={row.original && row.original.attachedOffers.length}
              />
            </div>
          );
        },
      },
      ...getCargoColumns({
        isSub: false,
        onGridDataChange,
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedCircularsBlurred, clipboardState]);

  // useMemo is required because the columns are being recreated on every render.
  // Without memoization this means the "open" state of the Add to My Cargos modal would be lost.
  //
  // See also FRIN-5431
  const subColumns = useMemo(
    () => [
      getClipBoardColumn({
        clipboardState,
        checkAllCargoes,
        addAllItemsToClipboard,
        removeAllItemsFromClipboard,
        removeItemFromClipboard,
        addItemToClipboard,
      }),
      ...getCargoColumns({isSub: true, onGridDataChange}),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clipboardState]
  );

  return (
    <>
      <DataGrid
        className={'market-grid market-grid--cargo'}
        columns={columns}
        loading={loading}
        data={itemsLimited}
        totalCount={totalCountLimited}
        page={cargoMeta.page}
        pageSize={cargoMeta.pageSize}
        showPagination={!isNewSubMarket}
        showJumper={!isNewSubMarket}
        sorted={[cargoMeta.sortedBy]}
        zeroBasedPageIndex={false}
        responsive
        noHoverStatePointer
        TbodyComponent={displayEmptyState()}
        getTdProps={getMarketGridTdProps(sharedCircularsBlurred)}
        renderSubComponent={row => <MarketSubComponent original={row.original} columns={subColumns} />}
        onSettingChange={onSettingChange}
        {...restProps}
      />
    </>
  );
};
