export const GraphSvg = () => (
  <svg width={430} height={330} viewBox="0 0 430 330" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h430L273.437 289.998 254 330h-78l-19.437-40.002L0 0z" fill="var(--color-white)" />
    <path d="M47.5 29l-11-21L377 39.5 365.5 61l-318-32z" fill="#DB4453" />
    <path d="M130 189l-11-21.5 176 32-11 21L130 189z" fill="#3BAEDA" />
    <path d="M135 199.5l11 21.5 121.5 31.5 10.5-21-143-32z" fill="#4B89DB" />
    <path d="M53.5 39.5L64 61l285 31.5 10.5-21-306-32z" fill="#E9573E" />
    <path d="M80 93L68.5 71 344 103.5 333 125 80 93z" fill="#F3BB48" />
    <path d="M97 124.5l-10.5-21 241 32-11 21.5L97 124.5z" fill="#8CC051" />
    <path d="M102 135l11 22 187 32 11-21.5L102 135z" fill="#36BC9B" />
    <path d="M152 231.5l10.5 21.5 88 31.5 11-21-109.5-32z" fill="#967BDC" />
    <path d="M168.5 263.5l10.5 21 55 32 11.5-21-77-32z" fill="#D870AD" />
    <path d="M47.32 29h335.335l11.342-22H36l11.32 22z" fill="url(#prefix__paint0_linear)" />
    <path d="M63.819 61l-11.32-22h324.998l-11.342 22H63.819z" fill="url(#prefix__paint1_linear)" />
    <path d="M79.818 93h269.179l11.342-22H68.498l11.32 22z" fill="url(#prefix__paint2_linear)" />
    <path d="M96.646 125h236.681l11.342-22H85.326l11.32 22z" fill="url(#prefix__paint3_linear)" />
    <path d="M112.997 157H316.91l11.342-22H101.677l11.32 22z" fill="url(#prefix__paint4_linear)" />
    <path d="M129.594 189l-11.321-22h193.566l-11.342 22H129.594z" fill="url(#prefix__paint5_linear)" />
    <path d="M145.818 221h138.179l11.342-22H134.498l11.32 22z" fill="url(#prefix__paint6_linear)" />
    <path d="M162.477 253l-11.32-22h127.682l-11.342 22h-105.02z" fill="url(#prefix__paint7_linear)" />
    <path d="M178.935 285h72.062l11.342-22h-94.724l11.32 22z" fill="url(#prefix__paint8_linear)" />
    <path d="M195.394 317l-11.321-22h61.766l-11.342 22h-39.103z" fill="url(#prefix__paint9_linear)" />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint2_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint3_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint4_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint5_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint6_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint7_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint8_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
      <linearGradient
        id="prefix__paint9_linear"
        x1={214.999}
        y1={9.5}
        x2={214.999}
        y2={317}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#ED5564" />
        <stop offset={0.137} stopColor="#FB6E52" />
        <stop offset={0.243} stopColor="#FFCE55" />
        <stop offset={0.344} stopColor="#A0D468" />
        <stop offset={0.453} stopColor="#48CFAE" />
        <stop offset={0.556} stopColor="#4FC0E8" />
        <stop offset={0.643} stopColor="#5D9CEC" />
        <stop offset={0.758} stopColor="#AC92ED" />
        <stop offset={0.872} stopColor="#EC87BF" />
        <stop offset={0.973} stopColor="#CCD0D9" />
      </linearGradient>
    </defs>
  </svg>
);
