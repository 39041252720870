import {AISRecord} from '../../queries/aisRecordsQuery/AISRecord';
import round from 'lodash/round';
import {Leg, Route} from './Route';
import {distance} from '../../utils/distance';
import {LonLatCoordinates} from '../../../../../../utils/Coordinates';
import * as speed from '../../utils/speed';

export const makeRoute = (aisRecords: AISRecord[]): Route => {
  let route: Route = [];
  let previousCoordinates: LonLatCoordinates = aisRecords[0].coordinates;
  let previousTime = aisRecords[0].timestamp;
  let previousAisRecord = aisRecords[0];
  for (let index = 1; index < aisRecords.length; index++) {
    const aisRecord = aisRecords[index];
    const coordinates: LonLatCoordinates = aisRecord.coordinates;
    const time = aisRecord.timestamp;
    const durationInMinutes = round(time.diff(previousTime) / 1000 / 60);
    if (durationInMinutes < 0) {
      throw new Error(`durationInMinutes < 0: ${durationInMinutes} ${time} - ${previousTime}`);
    }
    const distanceInKilometers = round(distance(previousCoordinates, coordinates), 1);
    const knots = speed.speedInKnots(distanceInKilometers, durationInMinutes);
    const speedInKnots = round(knots);
    const leg: Leg = {
      from: {
        index: index - 1,
        aisRecord: previousAisRecord,
      },
      to: {
        index: index,
        aisRecord,
      },
      durationInMinutes,
      distanceInKilometers,
      speedInKnots,
    };

    route.push(leg);

    previousCoordinates = coordinates;
    previousTime = time;
    previousAisRecord = aisRecord;
  }

  // Remove legs with speed > 100 knots
  route = route.filter(leg => leg.speedInKnots < 100);

  return route;
};
