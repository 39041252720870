import Supercluster from 'supercluster';
import {PortClusterLayer as PortClusterLayerJS} from './PortClusterLayer';
import {TODO} from '../../../../utils/TODO';
import * as GeoJSON from 'geojson';

const PortClusterLayer = PortClusterLayerJS as TODO;

export const getPortMarketLayer = ({
  portCluster,
  currentZoom,
  onClick,
  boundingBox,
  isShowMarketCargoes,
  isShowMarketVessels,
}: {
  portCluster: Supercluster;
  currentZoom: number;
  boundingBox: GeoJSON.BBox;
  isShowMarketVessels: boolean;
  isShowMarketCargoes: boolean;
  onClick: (portOrPortCluster: TODO) => void;
}) => {
  const allFeatures = portCluster.getClusters(boundingBox, Math.round(currentZoom));

  const clusterFeatures = allFeatures.filter(feature => 'cluster' in feature.properties);
  const singlePortFeatures = allFeatures.filter(feature => !('cluster' in feature.properties));

  return new PortClusterLayer({
    onClick,
    singlePortFeatures,
    clusterFeatures,
    portCluster: portCluster,
    zoom: currentZoom,
    isShowMarketCargoes,
    isShowMarketVessels,
  });
};
