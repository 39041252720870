/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {OneDayStatistics, OneDayStatisticsFromJSON, OneDayStatisticsFromJSONTyped, OneDayStatisticsToJSON} from './';

/**
 *
 * @export
 * @interface TradingAreaTiedUpVesselsStatistic
 */
export interface TradingAreaTiedUpVesselsStatistic {
  /**
   *
   * @type {string}
   * @memberof TradingAreaTiedUpVesselsStatistic
   */
  tradingAreaName: string;
  /**
   *
   * @type {string}
   * @memberof TradingAreaTiedUpVesselsStatistic
   */
  tradingAreaCode: string;
  /**
   *
   * @type {Array<OneDayStatistics>}
   * @memberof TradingAreaTiedUpVesselsStatistic
   */
  oneDayStatistics: Array<OneDayStatistics>;
}

export function TradingAreaTiedUpVesselsStatisticFromJSON(json: any): TradingAreaTiedUpVesselsStatistic {
  return TradingAreaTiedUpVesselsStatisticFromJSONTyped(json, false);
}

export function TradingAreaTiedUpVesselsStatisticFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TradingAreaTiedUpVesselsStatistic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tradingAreaName: json['tradingAreaName'],
    tradingAreaCode: json['tradingAreaCode'],
    oneDayStatistics: (json['oneDayStatistics'] as Array<any>).map(OneDayStatisticsFromJSON),
  };
}

export function TradingAreaTiedUpVesselsStatisticToJSON(value?: TradingAreaTiedUpVesselsStatistic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tradingAreaName: value.tradingAreaName,
    tradingAreaCode: value.tradingAreaCode,
    oneDayStatistics: (value.oneDayStatistics as Array<any>).map(OneDayStatisticsToJSON),
  };
}
