import {useQuery} from '@tanstack/react-query';
import {useDispatch} from '../../../redux/react-redux';
import {getPortExpectedVessels} from '../../../redux/ApiService/ports';

export const usePortExpectedVesselsQuery = ({portCode}: {portCode: string}) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['PortExpectedVessels', portCode],
    queryFn: () => {
      return dispatch(getPortExpectedVessels(portCode));
    },
  });
};
