/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NegotiationActorType {
  Guest = 'guest',
  User = 'user',
}

export function NegotiationActorTypeFromJSON(json: any): NegotiationActorType {
  return NegotiationActorTypeFromJSONTyped(json, false);
}

export function NegotiationActorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationActorType {
  return json as NegotiationActorType;
}

export function NegotiationActorTypeToJSON(value?: NegotiationActorType | null): any {
  return value as any;
}
