import {IconLayer} from 'deck.gl';
import memoize from 'lodash/memoize';
import ForegroundTextLayer from './ForegroundTextLayer';
import NewBgFontAtlasManager, {
  DEFAULT_CHAR_SET,
} from '../../../../../components/SeaboMap/layers/helper/BgFontAtlasManager';
import iconAtlas from '../../../../../components/SeaboMap/layers/icons/icon-atlas.png';
import iconMapping from '../../../../../components/SeaboMap/layers/icons/icon-mapping.json';
import {CAP_LEFT_SYMBOL, CAP_RIGHT_SYMBOL, SEPARATOR_SYMBOL} from '../../../../../components/SeaboMap/layers/const';

const getIconData = memoize(data => {
  const start = [];
  const vessel = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].text === CAP_LEFT_SYMBOL) {
      start.push(data[i]);
    } else if (data[i].text === SEPARATOR_SYMBOL) {
      vessel.push(data[i]);
    }
  }
  return {start, vessel};
});

class BackgroundLayer extends ForegroundTextLayer {
  initializeState() {
    this.state = {
      fontAtlasManager: new NewBgFontAtlasManager(this.context.gl, 2, 20),
    };
  }

  renderLayers() {
    return [
      new IconLayer({
        ...this.props,
        id: `${this.props.id}-label-tip`,
        data: this.props.data,
        getColor: [255, 255, 255],
        getIcon: () => 'bg-tip-mask',
        getPixelOffset: [0, -12],
        iconAtlas,
        iconMapping,
      }),
      super.renderLayers(),
      new IconLayer({
        ...this.props,
        id: `${this.props.id}-type-icon`,
        data: getIconData(this.state.data).start,
        getIcon: this.props.getIcon,
        getPixelOffset: d => [d.size[0] * -0.142, -55],
        getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 10000],
        getPosition: d => d.__source.object.coordinates,
        iconAtlas,
        iconMapping,
      }),
      new IconLayer({
        ...this.props,
        id: `${this.props.id}-vessel-icon`,
        data: getIconData(this.state.data).vessel,
        getColor: [68, 159, 255],
        getIcon: () => 'vessel-45-mask',
        getPixelOffset: d => [d.offsetLeft * 0.125 - 17, -30],
        getSize: this.props.getSize - 2,
        getPosition: d => d.__source.object.coordinates,
        iconAtlas,
        iconMapping,
      }),
    ];
  }
}
BackgroundLayer.defaultProps = {
  capLeftSymbol: CAP_LEFT_SYMBOL,
  capRightSymbol: CAP_RIGHT_SYMBOL,
  characterSet: DEFAULT_CHAR_SET,
  getIcon: () => '',
};
BackgroundLayer.layerName = 'BackgroundLayer';
export default BackgroundLayer;
