import {ParsedCargoData} from '../../../../../api/parser/generated';
import {Intake} from '../../../../CargoVesselForm/utils/IntakeSection/IntakeTypes';

export const getIntakes = (cargoData: ParsedCargoData) => {
  const intakes: Intake[] = [];

  if (cargoData.teu) {
    const teuMax = cargoData.teu.maximum?.toString();
    const teuMin = cargoData.teu.minimum?.toString();
    if (teuMax || teuMin) {
      intakes.push({
        type: 'teu',
        quantity: teuMax ?? teuMin!,
        isHighCube: false,
      });
    }
  }
  if (cargoData.teu14) {
    const teu14Max = cargoData.teu14.maximum?.toString();
    const teu14Min = cargoData.teu14.minimum?.toString();
    if (teu14Max || teu14Min) {
      intakes.push({
        type: 'teu14',
        quantity: teu14Max ?? teu14Min!,
        isHighCube: false,
      });
    }
  }

  return intakes;
};
