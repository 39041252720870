import React, {FC, useEffect, useState} from 'react';
import {ServerPushSocket, ServerPushSocketImpl, ServerPushSocketStub} from './ServerPushSocket';
import {ServerPushSubscriptions} from './ServerPushSubscriptions';
import {isTest} from '../../utils/environment';
import {ServerPushSocketProvider} from './ServerPushContext';
import {useSelector} from '../../redux/react-redux';
import {assert} from '../../utils/assert';

/**
 * Subscribes the app to push messages via ServerPush / Socket.io.
 * Must only be rendered when the user is logged in.
 */
export const PushMessages: FC<{
  children: React.ReactNode;
}> = ({children}) => {
  const accessToken = useSelector(state => state.authentication.accessToken);
  assert(accessToken);
  const [serverPushSocket, setServerPushSocket] = useState<ServerPushSocket | null>(null);

  // Everytime we receive a new access token, we use a new ServerPush instance.
  useEffect(() => {
    const current = newServerPushSocket(accessToken);
    setServerPushSocket(current);
    return () => {
      current.disconnect();
    };
  }, [accessToken]);

  if (!serverPushSocket) {
    return null;
  }

  return (
    <ServerPushSocketProvider value={serverPushSocket}>
      <ServerPushSubscriptions />
      {children}
    </ServerPushSocketProvider>
  );
};

const newServerPushSocket = (accessToken: string): ServerPushSocket =>
  isTest() ? new ServerPushSocketStub() : new ServerPushSocketImpl(accessToken);
