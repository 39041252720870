import {InlineSelectEditor} from '../../../../atoms/InlineSelectorEditor/InlineSelectEditor';
import {FreightUnit} from '../utils/CargoTypes';
import {EditableHeaderBox} from './EditableHeaderBox';
import {EditorFormattedValueLabel} from './EditorFormattedValueLabel';

export const freightIdeaUnitOptions: {label: string; value: FreightUnit}[] = [
  {label: 'MT', value: 'perMts'},
  {label: 'Lumpsum', value: 'lumpsum'},

  /*
  Currently we do not want the user to be able to select these options, but it is already implemented.
  The requirements to remove it are old, so they may no longer be up to date.

  {label: 'Day', value: 'perDay'},
   */
];

export const FreightIdeaUnit = (props: {freightUnit: FreightUnit; onChangeUnit: (value: FreightUnit) => void}) => {
  return (
    <EditableHeaderBox
      title={props.freightUnit === 'lumpsum' ? 'Payment terms' : 'Payment terms per'}
      hideRadiusLeft
      closeOnClickOutside={false}
      hideRadiusRight
      renderInput={(editing, setEditMode) => (
        <InlineSelectEditor<FreightUnit>
          size={'large'}
          editing={editing}
          onSelect={value => {
            props.onChangeUnit(value);
            setEditMode(false);
          }}
          value={props.freightUnit}
          options={freightIdeaUnitOptions}
          formattedValue={
            <EditorFormattedValueLabel>
              {freightIdeaUnitOptions?.find(option => option.value === props.freightUnit)?.label ?? ''}
            </EditorFormattedValueLabel>
          }
          hideEditButton={true}
        />
      )}
    />
  );
};
