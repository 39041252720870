/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UpdateSubjectTextRequest
 */
export interface UpdateSubjectTextRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSubjectTextRequest
   */
  text: string;
}

export function UpdateSubjectTextRequestFromJSON(json: any): UpdateSubjectTextRequest {
  return UpdateSubjectTextRequestFromJSONTyped(json, false);
}

export function UpdateSubjectTextRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSubjectTextRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: json['text'],
  };
}

export function UpdateSubjectTextRequestToJSON(value?: UpdateSubjectTextRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
  };
}
