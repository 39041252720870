import {useEffect, useState} from 'react';
import {OneCalcResult} from './utils/oneCalc';
import {MultiCharterCalculationState} from './types';
import {useBuildInitialOneCalcResults} from './utils/useBuildInitialOneCalcResults';
import {useOnChangedAssumptions} from './utils/useOnChangedAssumptions';
import {useUpdateSingleCalc} from './utils/useUpdateSingleCalc';
import produce from 'immer';
import {calcBreakEvenFreightIdea} from './utils/calcBreakEvenFreightIdea';

export type MultiCalculation = {
  breakEvenCalcs: OneCalcResult[] | undefined;
  oneCalcResults: OneCalcResult[] | undefined;
  updateFreightIdea: (oneCalc: OneCalcResult, freightIdea: number) => boolean;
  updateFreightIdeaByTce: (oneCalc: OneCalcResult, tceTarget: number) => boolean;
  matrix: {
    vesselCount: number;
    cargoCount: number;
    getOneCalcResult: (list: OneCalcResult[], vesselIndex: number, cargoIndex: number) => OneCalcResult | undefined;
    updateFreightIdeas: (list: OneCalcResult[], newFreightIdea: number) => boolean[];
    updateFreightIdeasByTce: (list: OneCalcResult[], tceTarget: number) => boolean[];
  };
  isLoading: boolean;
};

type Props = {
  multiCharterCalculationState: MultiCharterCalculationState;
};

export const useMultiCalculation = (props: Props): MultiCalculation => {
  const {multiCharterCalculationState} = props;

  const [oneCalcResults, setOneCalcResults] = useState<OneCalcResult[] | undefined>();
  const [breakEvenCalcs, setBreakEvenCalcs] = useState<OneCalcResult[] | undefined>();

  const {isLoading, isSuccess, data} = useBuildInitialOneCalcResults({
    multiCharterCalculationState,
  });

  useOnChangedAssumptions({
    multiCharterCalculationState,
    setOneCalcResults,
    oneCalcResults,
    setBreakEvenCalcs,
  });

  const updateSingleCalc = useUpdateSingleCalc({
    oneCalcResults,
    setOneCalcResults,
  });

  const updateFreightIdea = (oneCalc: OneCalcResult, freightIdea: number) => {
    const newInput = produce(oneCalc.voyageCalculationInput, draftInput => {
      draftInput.cargo.freightIdea = freightIdea;
    });
    return updateSingleCalc(oneCalc, newInput);
  };

  const updateFreightIdeaByTce = (oneCalc: OneCalcResult, tceTarget: number) => {
    const newFreightIdea = calcBreakEvenFreightIdea({oneCalc, tceTarget});
    return updateFreightIdea(oneCalc, newFreightIdea);
  };

  const vesselCount = multiCharterCalculationState.vessels.length;
  const cargoCount = multiCharterCalculationState.cargoes.length;
  const matrix = {
    vesselCount,
    cargoCount,
    getOneCalcResult: (list: OneCalcResult[], vesselIndex: number, cargoIndex: number) => {
      return list[cargoCount * vesselIndex + cargoIndex];
    },
    updateFreightIdeas: (list: OneCalcResult[], newFreightIdea: number) => {
      const results: boolean[] = [];
      list.forEach(oneCalcResult => results.push(updateFreightIdea(oneCalcResult, newFreightIdea)));
      return results;
    },
    updateFreightIdeasByTce: (list: OneCalcResult[], tceTarget: number) => {
      const results: boolean[] = [];
      list.forEach(oneCalcResult => results.push(updateFreightIdeaByTce(oneCalcResult, tceTarget)));
      return results;
    },
  };

  useEffect(() => {
    if (isSuccess && data) {
      setBreakEvenCalcs(data);
      setOneCalcResults(data);
    }
  }, [data, isSuccess]);

  return {oneCalcResults, breakEvenCalcs, updateFreightIdea, updateFreightIdeaByTce, matrix, isLoading};
};
