import {useContext, FC} from 'react';
import {VoyageCharterContext} from '../../Context/VoyageCharterContext';
import {Form, Space} from 'antd';
import {Modal} from '../../../../../antd/components/Modal';
import {RemainingOnBoard} from '../../VesselInformation/VesselTypes';
import {InputNumber} from '../../../../../antd/components/InputNumber';
import numeral from 'numeral';
import produce from 'immer';
import {everythingWithoutNumbersAndDotRegex} from './everythingWithoutNumbersAndDotRegex';

type EditROBModalProps = {
  onClose: () => void;
};
type FormType = {robs: RemainingOnBoard[]};

export const EditROBModal: FC<EditROBModalProps> = ({onClose}) => {
  const voyageContext = useContext(VoyageCharterContext);

  const initialData = {robs: voyageContext.state.inputState.vessel.remainingOnBoards};
  const [form] = Form.useForm<FormType>();

  const robs = Form.useWatch('robs', form);

  return (
    <Modal open={true} onCancel={onClose} onOk={() => form.submit()} title={'Edit ROB'} okText={'Update'}>
      <Form<FormType>
        form={form}
        onFinish={values => {
          voyageContext.setInputState(
            produce(voyageContext.state.inputState, draft => {
              draft.vessel.remainingOnBoards = values.robs;
            })
          );
          onClose();
        }}
        initialValues={initialData}>
        <Form.List name="robs">
          {fields => {
            return fields.map(({name, key}, index) => {
              // At the first render, the robs are undefined
              if (!robs) {
                return null;
              }
              const robPerFuelType: RemainingOnBoard = robs[key];
              const isLastItem = index === fields.length - 1;
              const noMarginBottom = isLastItem ? {marginBottom: 0} : {};
              return (
                <Space style={{marginBottom: 0}} align={'baseline'}>
                  <Form.Item style={{width: 50, ...noMarginBottom}} name={[name, 'fuelType']}>
                    {robPerFuelType.bunkerType.toUpperCase()}
                  </Form.Item>
                  <Form.Item
                    name={[name, 'quantity']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                        message: 'Quantity must be greater than or equal to 0',
                      },
                      {
                        validator: (_, quantity) => {
                          if (!quantity && robPerFuelType.price) {
                            return Promise.reject('If you add a price the quantity is required');
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                    style={{...noMarginBottom}}>
                    <InputNumber
                      formatter={value => {
                        if (value === '') {
                          return '';
                        }
                        return `${numeral(value).format('0,0[.]0')}`;
                      }}
                      parser={value => {
                        if (!value) {
                          return '';
                        }
                        const numberValue = parseFloat(value.replace(everythingWithoutNumbersAndDotRegex, ''));
                        return Math.round(numberValue).toString();
                      }}
                      placeholder={'Quantity in mt'}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'price']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                        message: 'Price be greater than or equal to 0',
                      },

                      {
                        validator: (_, price) => {
                          if (!price && robPerFuelType.quantity) {
                            return Promise.reject('If you add a quantity the price is required');
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                    style={{...noMarginBottom}}>
                    <InputNumber
                      formatter={value => {
                        if (value === '') {
                          return '';
                        }
                        return `$ ${numeral(value).format('0,0[.]0')}`;
                      }}
                      parser={value => value!.replace(everythingWithoutNumbersAndDotRegex, '')}
                      placeholder={'Price in $ / mt'}
                    />
                  </Form.Item>
                </Space>
              );
            });
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};
