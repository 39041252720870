import {TODO} from '../../../../utils/TODO';
import {MapTradingAreaWithMarketCirculars} from '../useExtendPortsAndTradingAreasWithCirculars';
import {AggregateCircularsLayer} from '../AggregateCircularsLayer/AggregateCircularsLayer';

import {NotAvailable} from '../../../../utils/NotAvailable';

export const getTradingAreaMarketLayer = ({
  tradingAreas,
  isShowMarketCargoes,
  isShowMarketVessels,
}: {
  tradingAreas: MapTradingAreaWithMarketCirculars[];
  isShowMarketVessels: boolean;
  isShowMarketCargoes: boolean;
}) => {
  return new AggregateCircularsLayer({
    id: `market-trading-aera-label`,
    data: tradingAreas,
    getPosition: (d: TODO) => {
      return d.coordinates;
    },
    getSize: 18,
    opacity: 1,
    isShowMarketVessels,
    isShowMarketCargoes,
    pickable: true,
    visible: true,
    getPixelOffset: [0, -8],
    getItemAmount: (tradingArea: MapTradingAreaWithMarketCirculars) => {
      const {amountVessels, amountCargoes} = tradingArea.circulars;
      return {amountCargoes, amountVessels};
    },
    getPickingInfo: (obj: TODO) => {
      const name = obj.info.object?.name ?? NotAvailable;

      return {
        ...obj.info,
        object: {
          ...obj.info.object,
          name,
        },
        polygon: undefined,
        noHighlight: true,
        noTooltip: true,
      };
    },
    getPolygonOffset: ({layerIndex}: TODO) => [0, -layerIndex * 10000],
  });
};
