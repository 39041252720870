import {SearchHit} from '../../../../api/symfony/generated/models';
import {SeaboVessel, SeaboVesselOption} from '../../../SearchPicker/Type';
import {List} from 'antd';
import {vesselDisplay} from '../../../../atoms/DropdownDisplay/vesselDisplay';

export const makeCustomVesselPickerOption = (searchHit: SearchHit): SeaboVesselOption => {
  const source = searchHit.source as SeaboVessel;
  return {
    id: source.id,
    key: `${source.type}_${source.imo}_${source.id}`,
    type: source.type,
    source: source,
    value: source.name,
    name: source.name,
    label: (
      <List.Item
        data-testid="seaboVesselPickerOption"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        {vesselDisplay(source)}
      </List.Item>
    ),
  };
};
