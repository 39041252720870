import {Button, FormItemProps} from 'antd';
import styled from 'styled-components';
import {FC, ReactElement} from 'react';
import {StyledFormItem} from '../StyledFormItem';
import {NamePath} from 'antd/es/form/interface';
import {TODO} from '../../../../utils/TODO';

export type OnChangeSingleFormValue = (name: NamePath, value: TODO) => void;

type AisFormItemProps = FormItemProps & {
  name: NamePath;
  defaultValue: TODO;
  extraAtLabelLine?: ReactElement;
  onChangeSingleFormValue: OnChangeSingleFormValue;
};

export const AisFormItem: FC<AisFormItemProps> = ({
  onChangeSingleFormValue,
  defaultValue,
  extraAtLabelLine,
  ...props
}) => {
  return (
    <AisStyledFormItem
      {...props}
      label={
        <div>
          <span>{props.label}</span> {extraAtLabelLine}
          <StyledButton
            style={{position: 'absolute', right: 0, padding: 0}}
            type={'link'}
            size={'small'}
            onClick={() => {
              onChangeSingleFormValue(props.name, defaultValue);
            }}>
            Clear
          </StyledButton>
        </div>
      }
    />
  );
};

export const StyledButton = styled(Button)`
  font-size: var(--font-size-sm);
`;

const AisStyledFormItem = styled(StyledFormItem)``;
