import {OneCalcResult, oneCalcRecalculate} from './oneCalc';
import {MultiCharterCalculationState} from '../types';
import {useEffect} from 'react';
import produce from 'immer';
import {calcBreakEvenFreightIdea} from './calcBreakEvenFreightIdea';

type Props = {
  oneCalcResults: OneCalcResult[] | undefined;
  setOneCalcResults: (calcs: OneCalcResult[] | undefined) => void;
  setBreakEvenCalcs: (calcs: OneCalcResult[] | undefined) => void;
  multiCharterCalculationState: MultiCharterCalculationState;
};

export const useOnChangedAssumptions = (props: Props) => {
  const {multiCharterCalculationState, setBreakEvenCalcs, setOneCalcResults, oneCalcResults} = props;

  /**
   * when the assumptions changed, the Hook performs calculations for all existing calculations ,
   * but with modificated assumptions
   */
  useEffect(() => {
    if (!oneCalcResults) {
      return;
    }
    const newCalcs = oneCalcResults.map(calc => {
      const inputState = produce(calc.voyageCalculationInput, draftInputState => {
        draftInputState.cost.costIfoPerMts = multiCharterCalculationState.assumptions.costIfoPerMts;
        draftInputState.cost.costMdoPerMts = multiCharterCalculationState.assumptions.costMdoPerMts;
        draftInputState.voyage.routes.forEach(route => {
          route.seaMargin = multiCharterCalculationState.assumptions.seaMargin;
        });

        draftInputState.voyage.points.forEach(port => {
          port.portCosts = multiCharterCalculationState.assumptions.portCosts;
        });
        return draftInputState;
      });
      return oneCalcRecalculate({voyageCalculationInput: inputState, oneCalc: calc});
    });

    setOneCalcResults(newCalcs);

    const breakEvenCalcs = newCalcs.map(calc => {
      const inputState = produce(calc.voyageCalculationInput, draftInputState => {
        draftInputState.cargo.freightIdea = calcBreakEvenFreightIdea({oneCalc: calc});

        return draftInputState;
      });
      return oneCalcRecalculate({voyageCalculationInput: inputState, oneCalc: calc});
    });

    setBreakEvenCalcs(breakEvenCalcs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiCharterCalculationState.assumptions]);
};
