import {ReactElement, ReactNode} from 'react';
import {GroupBase, Props, SelectComponentsConfig} from 'react-select';
import Async from 'react-select/async';
import {ClearIndicator, SearchIndicator} from './Indicators';
import {
  clearIndicator,
  control,
  indicatorsContainer,
  menu,
  menuList,
  option,
  singleValue,
  valueContainer,
} from './styles';

// @ts-expect-error Type 'Element' is not assignable to type 'string'.
interface AsyncSelectProps<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends Props<OptionType, IsMulti, GroupType> {
  isDisabled?: boolean;
  width?: number | string;
  components?: Partial<SelectComponentsConfig<OptionType, IsMulti, GroupType>>;
  hasError?: boolean;
  clearable?: boolean;
  getOptionLabel?: (option: OptionType) => ReactNode;
  getOptionValue?: (option: OptionType) => ReactNode;
  isOptionsDisabled?: (option: OptionType) => boolean;
  height?: number | string;
  id: string;
  name?: string;
  value?: IsMulti extends true ? OptionType[] : OptionType;
  allowCreateWhileLoading?: boolean;
  isMulti?: IsMulti;
  createLabel?: (value: string) => ReactNode;
  noOptionsMessage?: () => string | ReactElement;
  maxLength?: number;
  formatCreateLabel?: (inputValue: string) => string;
  loadOptions: (value: string) => Promise<OptionType[]>;
  cacheOptions?: boolean;
  defaultOptions?: boolean | (IsMulti extends true ? OptionType[] : OptionType);
  createOptionPosition?: 'first' | 'last';
}

export const AsyncSelect = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
  id,
  name,
  width,
  components,
  hasError,
  ...props
}: AsyncSelectProps<OptionType, IsMulti, GroupType>) => (
  <Async
    id={id}
    name={`select-${name}`}
    classNamePrefix={'seabo-react-select'}
    styles={{
      option,
      control: control(width, hasError),
      menu,
      menuList,
      singleValue,
      valueContainer,
      indicatorsContainer,
      clearIndicator,
    }}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator: SearchIndicator,
      ClearIndicator: ClearIndicator,
      ...components,
    }}
    // !! react18 - package types only allow string as return. But element is needed in CargoTabPane and works - /projects -> create project
    // @ts-expect-error Type '((option: OptionType) => string | Element) | undefined' is not assignable to type 'GetOptionLabel<OptionType> | undefined'.
    getOptionLabel={props.getOptionLabel}
    {...props}
  />
);
