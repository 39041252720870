import {FC, useCallback, useEffect} from 'react';
import {FilterCategory, FilterItem, FilterType} from '../../../api/symfony/generated';
import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import {HeroFilterMapCollapse} from '../../../components/HeroFilterMapCollapse/HeroFilterMapCollapse';
import {ActiveSubPortfolioList} from '../../../components/Portfolio/SubPortfolioList/ActiveSubPortfolioList';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {useLoadOrCreateFilterQuery} from '../../../queries/filter/useLoadOrCreateFilter';
import {ChangeAllItemsPayload, GridClipboardActions} from '../../../redux/GridClipboard';
import {
  PortfolioActions,
  PortfolioActions as PortfolioRedux,
  PortfolioType,
  SubPortfolioId,
} from '../../../redux/Portfolio';
import {PortfolioFiltersActions} from '../../../redux/PortfolioFilters';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {useOpenCargoForm} from '../../CargoVesselForm/utils/useOpenCargoForm';
import {getIfAllSelected} from '../../portfolio/shared/getIfAllSelected';
import '../../portfolio/shared/style.scss';
import {MyCargoesFilters} from './Filters/MyCargoesFilters';
import {MyCargoesActionButton} from './MyCargoesActionButton';
import {MyCargoesGrid} from './MyCargoesGrid';
import {GetMyCargoesQueryKey, useGetMyCargoesQuery} from './useGetMyCargoesQuery';
import {useQueryClient} from '@tanstack/react-query';
import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {Space} from 'antd';
import {MyCargoesTourDefinition} from './MyCargoesTourDefinition';
import {PortfolioCargoFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioCargoFilterBranchDefinitions';

const TYPE: PortfolioType = 'cargo';

type MyCargoesBodyProps = {
  filterProviderApi: FilterProviderApi<typeof PortfolioCargoFilterBranchDefinitions>;
};

export const MyCargoesBody: FC<MyCargoesBodyProps> = ({filterProviderApi}) => {
  const dispatch = useDispatch();
  const openCargoFrom = useOpenCargoForm();

  const queryClient = useQueryClient();

  const cargoesInGridClipboard = useSelector(state => state.gridClipboard.portfolio.cargoes);
  const {pageSize, pageNumber, sortField, sortOrder} = useSelector(state => state.portfolio.cargoGridState);
  const {cargoFilterSettings} = useSelector(state => state.portfolioFilters);
  const {activeTabCargo} = useSelector(state => state.portfolio.screenState);

  const selectSubPortfolio = (id: SubPortfolioId) => dispatch(PortfolioActions.selectSubPortfolio(TYPE, id));
  const setCargoFilterFromApi = useCallback(
    (filter: FilterItem) => {
      dispatch(PortfolioFiltersActions.setCargoFilterFromApi(filter));
    },
    [dispatch]
  );
  const setCargoes = useCallback(
    ({cargoes, cargoesTotal}: {cargoes: PortfolioCargo[] | null; cargoesTotal: number}) => {
      dispatch(PortfolioActions.setCargoes({cargoes, cargoesTotal}));
    },
    [dispatch]
  );
  const changeSelectedItems = useCallback(
    (payload: ChangeAllItemsPayload) => {
      dispatch(GridClipboardActions.changeAllItems(payload));
    },
    [dispatch]
  );
  const setPageNumber = (pageNumber: number) =>
    dispatch(PortfolioRedux.setGridState({portfolioType: 'cargo', gridState: {pageNumber}}));

  // Load filter from API
  const filterQuery = useLoadOrCreateFilterQuery({
    category: FilterCategory.Portfolio,
    type: FilterType.Cargo,
  });
  const filterLoaded = filterQuery.isSuccess && !!cargoFilterSettings;

  const cargoQuery = useGetMyCargoesQuery(
    {
      filters: cargoFilterSettings,
      subPortfolioId: activeTabCargo,
      isArchive: false,
      pageNumber,
      pageSize,
      sortField,
      sortOrder,
    },
    {enabled: filterLoaded}
  );

  useEffect(() => {
    dispatch(GridClipboardActions.removeAllItemsFromClipboard('cargoes', 'portfolio'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabCargo]);

  useEffect(() => {
    if (filterQuery.isSuccess && filterQuery.data) {
      setCargoFilterFromApi(filterQuery.data);
    }
  }, [filterQuery.isSuccess, filterQuery.data, setCargoFilterFromApi]);

  useEffect(() => {
    if (cargoQuery.isSuccess && cargoQuery.data) {
      setCargoes({
        cargoes: cargoQuery.data.items,
        cargoesTotal: cargoQuery.data.totalItems,
      });
      changeSelectedItems({
        section: 'portfolio',
        subsection: 'cargoes',
        newValue: getIfAllSelected(cargoesInGridClipboard, cargoQuery.data.items),
      });
    }
  }, [cargoQuery.isSuccess, cargoQuery.data, setCargoes, changeSelectedItems, cargoesInGridClipboard]);

  const cargoes: PortfolioCargo[] | null = cargoQuery.isSuccess ? cargoQuery.data.items : null;
  const cargoesTotal = cargoQuery.isSuccess ? cargoQuery.data.totalItems : 0;
  const loading = filterQuery.isLoading || cargoQuery.isLoading;

  const reloadGrid = (pageIndex?: number) => {
    if (pageIndex) {
      setPageNumber(pageIndex);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryClient.invalidateQueries({
      queryKey: [GetMyCargoesQueryKey],
    });
  };

  return (
    <div className={'portfolio'} data-testid="MyCargoesScreen">
      <ScreenHeader
        helmetTitle="My Cargoes"
        breadcrumbs={[{title: 'My Cargoes'}]}
        tourDefinition={MyCargoesTourDefinition}
        actions={
          <Space size={4}>
            <ScreenHeader.Actions.Link
              id="archiveButton"
              ghost={true}
              to="/my-cargoes/archive"
              data-cy="PortfolioCargoToArchiveBTN">
              Go to archive
            </ScreenHeader.Actions.Link>
            <ScreenHeader.Actions.Button id="addCargo" data-cy="PortfolioCargoAddBTN" onClick={() => openCargoFrom({})}>
              Add cargo
            </ScreenHeader.Actions.Button>
          </Space>
        }
        features={
          <>
            {filterProviderApi.isAnyFilterApplied && (
              <ScreenHeader.Features.Button
                data-cy="PortfolioCargoResetFiltersBTN"
                onClick={filterProviderApi.onResetAllClick}>
                Reset filters
              </ScreenHeader.Features.Button>
            )}
          </>
        }
      />
      <HeroFilterMapCollapse filters={<MyCargoesFilters filterProviderApi={filterProviderApi} />} />
      <ActiveSubPortfolioList
        type={TYPE}
        onDelete={() => {
          reloadGrid(1);
        }}
        onSubTabSelect={id => {
          selectSubPortfolio(id);
          setPageNumber(1);
        }}
      />
      <MyCargoesGrid
        isFiltered={filterProviderApi.isAnyFilterApplied}
        isArchive={false}
        loading={loading}
        cargoes={cargoes}
        cargoesTotal={cargoesTotal}
        onReloadGrid={(pageIndex?: number) => {
          reloadGrid(pageIndex);
        }}
      />

      <MyCargoesActionButton items={cargoesInGridClipboard} archive={false} onReloadGrid={reloadGrid} />
    </div>
  );
};
