import {SpotifindVesselAttributes} from '../../components/FilterProvider/Filters/Spotifind/SpotifindVesselAttributes/SpotifindVesselAttributes';
import VesselType from '../../components/FilterProvider/Filters/VesselType/VesselType';
import {SpotifindPosition} from '../../components/FilterProvider/Filters/Spotifind/SpotifindPosition/SpotifindPosition';
import {SpotifindVesselStatus} from '../../components/FilterProvider/Filters/Spotifind/SpotifindVesselStatus/SpotifindVesselStatus';
import {SpotifindParameters} from '../../components/FilterProvider/Filters/Spotifind/SpotifindParameters/SpotifindParameters';
import React, {FC} from 'react';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {FilterBar} from '../../components/FilterBar/FilterBar';
import styled from 'styled-components';
import {SpotifindFilterBranchDefinitions} from '../../components/FilterProvider/Filters/Spotifind/SpotifindFilterBranchDefinitions';

export const SpotifindFilters: FC<{filterProviderApi: FilterProviderApi<typeof SpotifindFilterBranchDefinitions>}> = ({
  filterProviderApi,
}) => {
  return (
    <StyledFilterBar
      id="spotifindFilters"
      filters={[
        <VesselType filterProviderApi={filterProviderApi} />,
        <SpotifindVesselAttributes filterProviderApi={filterProviderApi} />,
        <SpotifindPosition filterProviderApi={filterProviderApi} />,
        <SpotifindVesselStatus filterProviderApi={filterProviderApi} />,
        <SpotifindParameters filterProviderApi={filterProviderApi} />,
      ]}
    />
  );
};

const StyledFilterBar = styled(FilterBar)`
  margin-bottom: 20px;
`;
