/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselBuiltYearDwt,
  VesselBuiltYearDwtFromJSON,
  VesselBuiltYearDwtFromJSONTyped,
  VesselBuiltYearDwtToJSON,
} from './';

/**
 *
 * @export
 * @interface BuiltYearDwtReport
 */
export interface BuiltYearDwtReport {
  /**
   * Matching bulker vessels that have both a built year and a DWT value
   * @type {Array<VesselBuiltYearDwt>}
   * @memberof BuiltYearDwtReport
   */
  bulkerVessels: Array<VesselBuiltYearDwt>;
  /**
   *
   * @type {number}
   * @memberof BuiltYearDwtReport
   */
  minBuiltYear: number;
  /**
   *
   * @type {number}
   * @memberof BuiltYearDwtReport
   */
  maxBuiltYear: number;
  /**
   *
   * @type {number}
   * @memberof BuiltYearDwtReport
   */
  minDwt: number;
  /**
   *
   * @type {number}
   * @memberof BuiltYearDwtReport
   */
  maxDwt: number;
  /**
   * Number of bulkers that lack a built year or DWT value.
   * @type {number}
   * @memberof BuiltYearDwtReport
   */
  bulkerVesselsWithoutValue: number;
  /**
   * The report does not include non-bulkers. This is the number of non-bulkers that matched the given filter.
   * @type {number}
   * @memberof BuiltYearDwtReport
   */
  nonBulkerVessels: number;
}

export function BuiltYearDwtReportFromJSON(json: any): BuiltYearDwtReport {
  return BuiltYearDwtReportFromJSONTyped(json, false);
}

export function BuiltYearDwtReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuiltYearDwtReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bulkerVessels: (json['bulkerVessels'] as Array<any>).map(VesselBuiltYearDwtFromJSON),
    minBuiltYear: json['minBuiltYear'],
    maxBuiltYear: json['maxBuiltYear'],
    minDwt: json['minDwt'],
    maxDwt: json['maxDwt'],
    bulkerVesselsWithoutValue: json['bulkerVesselsWithoutValue'],
    nonBulkerVessels: json['nonBulkerVessels'],
  };
}

export function BuiltYearDwtReportToJSON(value?: BuiltYearDwtReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bulkerVessels: (value.bulkerVessels as Array<any>).map(VesselBuiltYearDwtToJSON),
    minBuiltYear: value.minBuiltYear,
    maxBuiltYear: value.maxBuiltYear,
    minDwt: value.minDwt,
    maxDwt: value.maxDwt,
    bulkerVesselsWithoutValue: value.bulkerVesselsWithoutValue,
    nonBulkerVessels: value.nonBulkerVessels,
  };
}
