import classNames from 'classnames';
import '../../styles.scss';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import styled from 'styled-components';

export enum ColorColumnColor {
  RED = 0,
  ORANGE = 1,
  BLUE = 2,
  GREEN = 3,
  PURPLE = 4,
  YELLOW = 5,
}

interface ColumnType {
  // null means no color
  color: ColorColumnColor | null | string;
}

export type ColorPickerFunction<ColumnItemType> = (item: ColumnItemType) => ColorColumnColor | null;

interface Props<ColumnItemType> {
  height?: number;
  colorPickerFunction: ColorPickerFunction<ColumnItemType>;
}

export const colorColumn = <ColumnItemType extends ColumnType>(
  props: Props<ColumnItemType>
): CustomColumnDef<ColumnItemType> => {
  const {colorPickerFunction} = props;
  const result: CustomColumnDef<ColumnItemType> = {
    header: () => <StyledHeader />,
    id: 'color',
    accessorFn: item => {
      const color = getColor(item, colorPickerFunction);
      return (
        <div
          style={{height: props.height ? props.height : '100%'}}
          className={classNames('market-grid-cell market-grid-cell--color', {
            [`market-grid-cell--color-${color}`]: color !== null,
          })}>
          {' '}
        </div>
      );
    },
    cell: info => info.getValue(),
    width: 3,
    enableSorting: false,
    className: 'datagrid-td-color',
    headerClassName: 'datagrid-th-color',
  };

  return result;
};

const StyledHeader = styled.div`
  width: 7px;
`;

const getColor = <Type extends ColumnType>(item: Type, colorPickerFunction: ColorPickerFunction<Type>) => {
  return colorPickerFunction ? colorPickerFunction(item) : item.color;
};
