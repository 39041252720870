import {Tooltip} from 'antd';
import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {NegotiationSubjectStatus} from '../../../../../api/node-backend/generated';
import {getSubjectStatusIcon} from './utils/getSubjectStatusIcon';
import Icon from '../../../../../atoms/Icon';
import {getSubjectStatusLabel} from './utils/getSubjectStatusLabel';
import {TermItemDumb} from '../TermItem/TermItemDumb';
import {NegotiationColorSet} from '../../../utils/colors';

type SubjectItemDumbProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  text: string;
  status: NegotiationSubjectStatus;
  colors: NegotiationColorSet;
  isHighlighted?: boolean;
  beforeTextAddon?: ReactNode;
  textReplacement?: ReactNode;
  contentReplacement?: ReactNode;
  afterTextAddon?: ReactNode;
  onTextClick?: () => void;
  'data-testid'?: string;
};

export const SubjectItemDumb: FC<SubjectItemDumbProps> = ({
  containerRef,
  text,
  status,
  colors,
  isHighlighted,
  beforeTextAddon,
  textReplacement,
  contentReplacement,
  afterTextAddon,
  onTextClick,
  'data-testid': dataTestId,
}) => {
  return (
    <TermItemDumb
      data-testid={dataTestId || 'SubjectItem'}
      containerRef={containerRef}
      text={text}
      isHighlighted={isHighlighted}
      isRejectRequested={status === NegotiationSubjectStatus.RejectionRequested}
      isRejected={status === NegotiationSubjectStatus.Rejected}
      colors={colors}
      onTextClick={onTextClick}
      indexLabel={
        <Tooltip title={getSubjectStatusLabel(status)} trigger="hover">
          <div>
            <StatusIcon type={getSubjectStatusIcon(status)} size="small" />
          </div>
        </Tooltip>
      }
      beforeTextAddon={beforeTextAddon}
      textReplacement={textReplacement}
      contentReplacement={contentReplacement}
      afterTextAddon={afterTextAddon}
    />
  );
};

const StatusIcon = styled(Icon)`
  position: relative;
  top: 1px;
  font-size: 1.2rem;
`;
