import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {classNameForSidebarMode} from './SidebarMenuComponents';
import {getSidebarMode} from '../../../redux/selectors';
import {useSelector} from '../../../redux/react-redux';

export const Container: FC<{dataTourId?: string; children: ReactNode}> = ({dataTourId, children}) => {
  const sidebarMode = useSelector(getSidebarMode);

  return (
    <ContainerStyled className={classNameForSidebarMode(sidebarMode)} data-testid="Sidebar" data-tour-id={dataTourId}>
      {children}
    </ContainerStyled>
  );
};

export const ContainerStyled = styled.nav`
  display: block;
  height: calc(100% - var(--top-offset));

  position: fixed;
  left: 0px;
  top: var(--top-offset);

  z-index: 100;
  background-color: var(--color-azure);

  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.1);

  transition: width 0.1s;

  &.sidebarMode-wide {
    width: 204px;
  }

  &.sidebarMode-narrow {
    width: 80px;
  }

  overflow-x: hidden;
  overflow-y: hidden;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
`;

export const UpperArea = styled.div`
  flex-grow: 1;
  margin-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #fefefe6c transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar-track {
    background-color: transparent;
    &:hover {
      all: inherit;
    }
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    &:hover {
      all: inherit;
    }
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: #fefefe6c;
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fefefe6c;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
    &:hover {
      all: inherit;
    }
  }
`;

export const LowerArea = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;

  background-color: var(--color-azure);
`;
