import {useEffect} from 'react';
import styled from 'styled-components';
import {ConnectedState} from '../../../api/symfony/generated';
import Card from '../../../components/Card/Card';
import LoadingComponent from '../../../components/DataGrid/LoadingComponent';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import ContactsService from '../../../redux/ApiService/ContactsService';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import RequestRow, {Person} from './RequestRow';

export const ContactRequestsScreen: React.FC = () => {
  const dispatch = useDispatch();

  const getRequests = () => dispatch(ContactsService.getRequests());

  useEffect(() => {
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contact-requests">
      <ScreenHeader
        helmetTitle="Connection requests"
        breadcrumbs={[{title: 'Contacts'}, {title: 'Manage connections'}]}
      />
      <RequestsView />
    </div>
  );
};

const RequestsView = () => {
  const {loading, data: requestData} = useSelector(state => state.api.contacts.getRequests);

  if (loading || !requestData) {
    return <Loading />;
  }

  if (!requestData.received || !requestData.send) {
    return <NoData />;
  }

  return (
    <div>
      <SuggestionTitle>
        {`${requestData.received.length + requestData.send.length} connection ${
          requestData.received.length + requestData.send.length === 1 ? ' request waiting' : 'requests waiting'
        }`}
      </SuggestionTitle>
      <SuggestionWrapper>
        {requestData.received.map((person: Person) => (
          <RequestRow key={person.id} person={person} connectedState={ConnectedState.Received} />
        ))}
        {requestData.send.map((person: Person) => (
          <RequestRow key={person.id} person={person} connectedState={ConnectedState.Pending} />
        ))}
      </SuggestionWrapper>
    </div>
  );
};

const Loading = () => {
  return (
    <Card>
      <LoadingComponent style={{textAlign: 'center'}} />
    </Card>
  );
};

const NoData = () => {
  return (
    <Card>
      <img src={'/static/images/seanexxt/contacts/empty_follow.jpg'} alt={'empty-follow'} />
      <div>View here all people who sent you a connection request.</div>
    </Card>
  );
};

const SuggestionTitle = styled.div`
  color: var(--color-gray-2);
  font-size: var(--font-size-lg);
  padding: 22px 0;
`;

const SuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
