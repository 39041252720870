import React, {useState} from 'react';
import {PortPicker} from './PortPicker';
import Flag from '../../atoms/Flags';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {SearchPickerPort} from '../SearchPicker/SearchPickerPort';

interface PortPickerPropsPort {
  name: string;
  countryObject?: {
    code: string | undefined;
  };
}

type Props = {
  port?: PortPickerPropsPort | undefined;
  onChange?: (port: SearchPickerPort | undefined) => void;
  className?: string;
  size?: SizeType;
  /**
   * Set to false to disable the onChange event when the text is changed.
   * It's necessary to prevent triggering a validation error while the user is typing.
   * @default true
   *
   */
  isTriggeredOnTextChange?: boolean;
  placeholder?: string;
};

export const PortPickerWithFlag = (props: Props) => {
  const [hasTextChange, setHasTextChange] = useState(false);
  const {port} = props;

  const value = `${port?.name ?? ''} ${port?.countryObject?.code ? `(${port.countryObject.code.toUpperCase()})` : ''}`;
  return (
    <PortPicker
      className={props.className}
      placeholder={props.placeholder}
      size={props.size}
      prefix={
        !hasTextChange && port && port.countryObject?.code ? (
          <Flag countryCode={port.countryObject.code} />
        ) : (
          <span style={{width: 17}}></span>
        )
      }
      defaultValue={value}
      onBlur={() => {
        if (hasTextChange) {
          props.onChange && props.onChange(undefined);
          setHasTextChange(false);
        }
      }}
      onSelect={port => {
        setHasTextChange(false);
        props.onChange && props.onChange(port);
      }}
      onChangeText={(_, eventType) => {
        if (eventType === 'onChange') {
          setHasTextChange(true);

          if (props.isTriggeredOnTextChange !== false) {
            props.onChange?.(undefined);
          }
        }
      }}
    />
  );
};
