import {Location} from '../../../../CargoPicker/CargoPicker';
import {VoyagePointTerm, voyagePointTerms} from '../../VoyageInformation/VoyagePointTerm';

export const getTerm = (location: Location): VoyagePointTerm | undefined => {
  let term: VoyagePointTerm | undefined;

  if (location.additionalInfo?.terms) {
    term = voyagePointTerms.includes(location.additionalInfo.terms.toUpperCase() as VoyagePointTerm)
      ? (location.additionalInfo.terms.toUpperCase() as VoyagePointTerm)
      : 'SSHINC';
  }
  return term;
};
