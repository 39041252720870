import dayjs from 'dayjs';
import _capitalize from 'lodash/capitalize';
import {ContractType} from '../api/symfony/generated';
import {commodityCategories} from '../components/SeaboMap/helper';
import {numberRangeUnitFormat, numberUnitFormat} from '../utils/formatter';
import BaseModel from './BaseModel';
import {getLocationString} from './helper';
import Station from './Station';
import User from './User';
import {DateTimeFormat} from '../utils/DateTimeFormat';

const getAttributes = (p: $TSFixMe, o: $TSFixMe) => {
  if (!p) {
    return [];
  }

  let data = {};

  switch (p.contractType) {
    case ContractType.Tct:
      data = {
        'Contract Type': 'Time charter trip',
        Size: o.renderVesselSize,
        'Vessel Type': o.renderVesselType,
        Duration: o.renderDuration,
        Period: o.renderPeriod,
        Delivery: getLocationString(o.stations, 'delivery'),
        Redelivery: getLocationString(o.stations, 'redelivery'),
      };
      break;
    case ContractType.Pc:
      data = {
        'Contract Type': 'Period charter',
        Size: o.renderVesselSize,
        'Vessel Type': o.renderVesselType,
        Duration: o.renderDuration,
        Period: o.renderPeriod,
        Delivery: getLocationString(o.stations, 'delivery'),
        Redelivery: getLocationString(o.stations, 'redelivery'),
      };
      break;
    case ContractType.Bc:
      data = {
        'Contract Type': 'Bareboat charter',
        Size: o.renderVesselSize,
        'Vessel Type': o.renderVesselType,
        Duration: o.renderDuration,
        Period: o.renderPeriod,
        Delivery: getLocationString(o.stations, 'delivery'),
        Redelivery: getLocationString(o.stations, 'redelivery'),
      };
      break;
    case ContractType.Vc:
      data = {
        'Contract Type': 'Voyage charter',
        Commodity: _capitalize(o.commodityCategory),
        Quantity: o.renderQuantity,
        Laycan: o.renderLaycan,
        Loading: getLocationString(o.stations, 'loading'),
        Discharge: getLocationString(o.stations, 'discharge'),
      };
      break;
    default:
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return Object.keys(data).map(key => ({label: key, value: data[key] ? data[key] : '–'}));
};

class Cargo extends BaseModel {
  attributes: $TSFixMe;
  user: $TSFixMe;

  constructor(payload: $TSFixMe) {
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 0 or more.
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    this.user = payload.user ? new User(payload.user) : null;
    this.attributes = getAttributes(payload, this);
  }

  get id() {
    return this.payload.id;
  }

  get name() {
    return this.payload.name;
  }

  get chartererName() {
    return this.payload.chartererName;
  }

  get contractType() {
    return this.payload.contractType;
  }

  get commodityCategory() {
    return this.payload.commodityCategory;
  }

  get quantityUnit() {
    return this.payload.quantityUnit;
  }

  get renderVesselSize() {
    const s = [];

    if (this.payload.vesselSizeMin) {
      s.push(this.payload.vesselSizeMin + ' ' + (this.payload.vesselSizeUnit ? this.payload.vesselSizeUnit : 'MTS'));
    }

    if (this.payload.vesselSizeMax) {
      s.push(this.payload.vesselSizeMax + ' ' + (this.payload.vesselSizeUnit ? this.payload.vesselSizeUnit : 'MTS'));
    }

    const a = s.join(' - ');

    return a;
  }

  get renderVesselType() {
    const s = [];

    if (this.payload.vesselType) {
      s.push(this.payload.vesselType);
    }

    if (this.payload.vesselSubtype) {
      s.push(this.payload.vesselSubtype);
    }

    if (this.payload.maxVesselAge) {
      const age = this.payload.maxVesselAge;
      s.push(age === 1 ? age + ' year' : age + ' years');
    }

    return s.join(' / ');
  }

  get renderDuration() {
    // For TCT
    if (this.payload.duration && this.payload.durationUnit) {
      return this.payload.duration + ' ' + this.payload.durationUnit;
    }

    const s = [];

    // For BC + PC
    if (this.payload.durationMin && this.payload.durationUnit) {
      s.push(this.payload.durationMin + ' ' + this.payload.durationUnit);
    }

    if (this.payload.durationMax && this.payload.durationUnit) {
      s.push(this.payload.durationMax + ' ' + this.payload.durationUnit);
    }

    return s.join(' - ');
  }

  get renderPeriod() {
    const s = [];

    if (this.payload.periodFrom) {
      s.push(dayjs(this.payload.periodFrom).format(DateTimeFormat.Date));
    }

    if (this.payload.periodTo) {
      s.push(dayjs(this.payload.periodTo).format(DateTimeFormat.Date));
    }

    return s.join(' - ');
  }

  get renderLaycan() {
    const s = [];

    if (this.payload.laycanFrom) {
      s.push(dayjs(this.payload.laycanFrom).format(DateTimeFormat.Date));
    }

    if (this.payload.laycanTo) {
      s.push(dayjs(this.payload.laycanTo).format(DateTimeFormat.Date));
    }

    return s.join(' - ');
  }

  get renderQuantity() {
    const s = [];

    if (this.payload.quantityMin) {
      s.push(this.payload.quantityMin + ' ' + (this.payload.quantityUnit ? this.payload.quantityUnit : 'MTS'));
    }

    if (this.payload.quantityMax) {
      s.push(this.payload.quantityMax + ' ' + (this.payload.quantityUnit ? this.payload.quantityUnit : 'MTS'));
    }

    return s.join(' - ');
  }

  get renderDelivery() {
    return this.payload.deliveryPort;
  }

  get renderWeight() {
    const weight = this.contractType === 'vc' ? this.payload.quantityMax : this.payload.vesselSizeMax;
    return numberUnitFormat(weight, this.quantityUnit, {emptyString: ''});
  }

  get renderAvailability() {
    const p = this.payload;
    if (p.periodFrom || p.laycanFrom) {
      const from = p.periodFrom || p.laycanFrom;
      const until = p.periodTo || p.laycanTo;

      return dayjs(from).format(DateTimeFormat.Date) + ' - ' + dayjs(until).format(DateTimeFormat.Date);
    }
    return '';
  }

  get title() {
    return this.chartererName;
  }

  get cargoType() {
    return this.payload.cargoType;
  }

  get intakes() {
    return this.payload.intakes;
  }

  get stations() {
    return this.payload.stations;
  }

  get laycanFrom() {
    return this.payload.laycanFrom;
  }

  get laycanTo() {
    return this.payload.laycanTo;
  }

  get quantity() {
    return this.payload.quantity;
  }

  get quantityMin() {
    return this.payload.quantityMin;
  }

  get quantityMax() {
    return this.payload.quantityMax;
  }

  get vesselSizeMin() {
    return this.payload.vesselSizeMin;
  }

  get vesselSizeMax() {
    return this.payload.vesselSizeMax;
  }

  static getSizeValue(
    {
      cargoType,
      contractType,
      intakes,
      quantityMin,
      quantityMax,
      quantityUnit,
      vesselSizeMin,
      vesselSizeMax,
      vesselSizeUnit,
    }: $TSFixMe,
    emptyString: $TSFixMe
  ) {
    const teu = intakes.find((d: $TSFixMe) => d.type === 'teu') || null;

    if (contractType === 'vc') {
      if (cargoType === 'container') {
        return teu && numberUnitFormat(teu.quantity, 'teu', {emptyString});
      } else {
        return numberRangeUnitFormat(quantityMin, quantityMax, quantityUnit, {emptyString});
      }
    }
    return numberRangeUnitFormat(vesselSizeMin, vesselSizeMax, vesselSizeUnit, {emptyString});
  }

  static getLocationLoadDelShortValue({contractType, stations}: $TSFixMe, short?: boolean, emptyState?: boolean) {
    return contractType === 'vc'
      ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"loading"' is not assignable to ... Remove this comment to see the full error message
        Station.getLocationByStationType(stations, 'loading', {short, emptyState})
      : // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"delivery"' is not assignable to... Remove this comment to see the full error message
        Station.getLocationByStationType(stations, 'delivery', {short, emptyState});
  }

  static getLocationDischRedelShortValue({contractType, stations}: $TSFixMe, short?: boolean, emptyState?: boolean) {
    return contractType === 'vc'
      ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"discharge"' is not assignable t... Remove this comment to see the full error message
        Station.getLocationByStationType(stations, 'discharge', {short, emptyState})
      : // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"redelivery"' is not assignable ... Remove this comment to see the full error message
        Station.getLocationByStationType(stations, 'redelivery', {short, emptyState});
  }

  static getCommodityCategoryForDetails({cargoType, commodityCategory}: $TSFixMe) {
    if (cargoType === 'container') {
      if (!commodityCategory) {
        return 'Container';
      }
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return commodityCategory ? commodityCategories[commodityCategory.toLowerCase()] : null;
  }

  static getColor(type: $TSFixMe) {
    switch (type) {
      case 'vc':
        return '#FF8E23';
      case 'coa':
        return '#F3B215';
      case 'tct':
        return '#73D2E6';
      case 'pc':
        return '#EF3CB4';
      case 'bc':
        return '#4340D6';
      default:
        return 'var(--color-gray-2)';
    }
  }

  static formatContractType({contractType}: $TSFixMe, shortVersion: $TSFixMe) {
    const types = {
      bc: 'Bareboat charter',
      pc: 'Period charter',
      tct: 'Time charter trip',
      vc: 'Voyage charter',
    };

    if (shortVersion) {
      return contractType === ContractType.Bc ? 'BBC' : contractType && contractType.toUpperCase();
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return types[contractType] ? types[contractType] : '–';
  }
}

export default Cargo;
