import {ReactElement} from 'react';
import {GroupBase, Props, SelectComponentsConfig} from 'react-select';
import AsyncCreateableSelect from 'react-select/async-creatable';
import {ClearIndicator, SearchIndicator} from './Indicators';
import {
  clearIndicator,
  control,
  indicatorsContainer,
  menu,
  menuList,
  multiValue,
  multiValueLabel,
  multiValueRemove,
  option,
  singleValue,
  valueContainer,
} from './styles';

// @ts-expect-error Type 'Element' is not assignable to type 'string'.
interface AsyncMultiCreatableProps<OptionType, GroupType extends GroupBase<OptionType> = GroupBase<OptionType>>
  extends Props<OptionType, true, GroupType> {
  isDisabled?: boolean;
  width?: number | string;
  components?: Partial<SelectComponentsConfig<OptionType, true, GroupType>>;
  hasError?: boolean;
  clearable?: boolean;
  getOptionLabel?: (option: OptionType) => string | JSX.Element;
  isOptionsDisabled?: (option: OptionType) => boolean;
  height?: number | string;
  id: string;
  name?: string;
  value: OptionType[];
  allowCreateWhileLoading?: boolean;
  createLabel?: (value: string) => string;
  noOptionsMessage?: () => string | ReactElement;
  maxLength?: number;
  formatCreateLabel?: (inputValue: string) => string;
  loadOptions: (value: string) => Promise<OptionType[]>;
  cacheOptions?: boolean;
  defaultOptions?: boolean | OptionType[];
  createOptionPosition?: 'first' | 'last';
}

export const AsyncMultiCreatable = <OptionType, GroupType extends GroupBase<OptionType> = GroupBase<OptionType>>({
  id,
  name,
  components,
  hasError,
  getOptionLabel,
  allowCreateWhileLoading = true,
  createLabel,
  ...props
}: AsyncMultiCreatableProps<OptionType, GroupType>) => (
  <AsyncCreateableSelect
    id={id}
    name={`select-${name}`}
    classNamePrefix={'seabo-react-select'}
    isMulti={true}
    // !! react18 - package types only allow string as return. But element is needed in CargoTabPane and works - /projects -> create project
    // @ts-expect-error Type '((option: OptionType) => string | Element) | undefined' is not assignable to type 'GetOptionLabel<OptionType> | undefined'.
    getOptionLabel={getOptionLabel}
    isClearable
    isSearchable={true}
    allowCreateWhileLoading={allowCreateWhileLoading}
    formatCreateLabel={createLabel}
    styles={{
      option,
      control: (base, state) => ({
        ...control('100%', hasError)(base, state),
        padding: '4px 6px',
      }),
      menu,
      menuList,
      singleValue,
      valueContainer,
      indicatorsContainer,
      multiValue,
      multiValueLabel,
      multiValueRemove,
      clearIndicator,
    }}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator: SearchIndicator,
      MultiValueRemove: ClearIndicator,
      ...components,
    }}
    {...props}
  />
);
