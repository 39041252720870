import {AisFilterForm} from './AisFilterForm/AisFilterForm';
import {useThrottle} from '../../../utils/useThrottle';
import {TODO} from '../../../utils/TODO';
import {useMapContext} from '../MapContext/MapContext';

export const AisFilter = ({
  disabled,
  filters,
  onChange,
}: {
  disabled: boolean;
  filters: TODO;
  onChange: (filter: TODO) => void;
}) => {
  const context = useMapContext();
  const onChangeThrottled = useThrottle(onChange, [context], 500, {leading: false, trailing: true});

  return (
    <AisFilterForm
      disabled={disabled}
      filters={filters}
      onChange={values => {
        onChangeThrottled({
          ...filters,
          ...values,
        });
      }}
    />
  );
};
