import dayjs from 'dayjs';

export const formatTimestamp = (timestamp: Date): string => {
  const m = dayjs.tz(timestamp);

  const withinToday = m.isSame(dayjs(), 'day');
  if (withinToday) {
    return m.fromNow();
  }

  const withinAWeek = dayjs(dayjs()).diff(timestamp, 'days') < 7;
  if (withinAWeek) {
    return m.format('dddd, H:mm');
  }

  return m.format('MMMM Do YYYY, H:mm');
};
