import {FC} from 'react';
import {MenuItem} from '../../atoms/Menu/MenuItem';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';

export const MarketVesselDetailsScreenActionMenu: FC<{
  onAddToMyFleetClick: () => void;
  onAddToProjectClick: () => void;
  onCreateFixtureClick: () => void;
}> = ({onAddToMyFleetClick, onAddToProjectClick, onCreateFixtureClick}) => (
  <ScreenHeader.Actions.Menu
    position={'right'}
    items={[
      {
        key: 'add-to-fleet',
        'data-cy': 'AddToMyFleet',
        label: <MenuItem dataCy={'CloneVesselMenuItem'} onClick={onAddToMyFleetClick} label={'Add to My Fleet'} />,
      },
      {
        key: 'add-to-project',
        'data-cy': 'AddToProject',
        label: <MenuItem dataCy="AddToProjectMenuItem" onClick={onAddToProjectClick} label={'Add to project'} />,
      },
      {
        key: 'create-fixture',
        'data-cy': 'CreateFixture',
        label: <MenuItem dataCy={'CreateFixtureMenuItem'} onClick={onCreateFixtureClick} label={'Create fixture'} />,
      },
    ]}
  />
);
