import {Consumption} from '../../useMyFleetQuery/useMyFleetQuery';
import table from 'text-table';

const getPortConsumptionString = (consumptions: Consumption[]): string => {
  const portConsumption = consumptions.filter(con => ['portidle', 'portworking'].includes(con.type));
  if (portConsumption.length > 0) {
    const portWorking = portConsumption.find(con => con.type === 'portworking');
    const portIdle = portConsumption.find(con => con.type === 'portidle');

    let workingString = !portWorking
      ? ''
      : `about ${portWorking.fuelConsumption} mt ${portWorking.fuelType ?? ''} working`;
    if (portWorking?.extraFuelConsumption) {
      workingString = workingString + `+ ${portWorking.extraFuelConsumption} ${portWorking.extraFuelType ?? ''} mt/d`;
    }

    const idleString = !portIdle ? '' : `about ${portIdle.fuelConsumption} mt ${portIdle.fuelType ?? ''} idle`;

    return `
\tport cons. ${idleString} / ${workingString}`;
  }

  return '';
};

export const getConsumption = ({consumptions}: {consumptions: Consumption[]}): string => {
  const nonPortConsumption = consumptions.filter(con => !['portidle', 'portworking'].includes(con.type));

  const list = nonPortConsumption.map(con => {
    const row = [`\t${con.type}`, `${con.speed} knots`, `${con.fuelConsumption} ${con.fuelType} mt/d`];

    if (con.extraFuelConsumption) {
      row.push(`+ ${con.extraFuelConsumption} ${con.extraFuelType} mt/d`);
    }

    return row;
  });

  const portConsumptionString = getPortConsumptionString(consumptions);

  if (list.length === 0 && portConsumptionString === '') {
    return 'Consumption: n/a';
  }
  return `Consumption:
${table(list, {align: ['l', 'r', 'r']})}${portConsumptionString}`;
};
