import classNames from 'classnames';
import {DeckGL} from '@deck.gl/react/typed';
import {MapView, PickingInfo} from '@deck.gl/core/typed';
import {TooltipContent} from '@deck.gl/core/src/lib/tooltip';
import 'mapbox-gl/dist/mapbox-gl.css';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {RequestState} from '../../redux/ApiService/ApiService';
import * as MapService from '../../redux/ApiService/MapService/MapService';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {assert} from '../../utils/assert';
import {TODO} from '../../utils/TODO';
import {useThrottle} from '../../utils/useThrottle';
import {LAYERS, MAPBOX_STYLES, SAVE_FORMAT_VERSION} from './const';
import {getInitialMapSwitches} from './defaultMapSwitches';
import {getOptionById} from './helper';
import {HookComponent} from './Hooks/HookComponent';
import {useSetMapDetailElement} from './Hooks/useSetMapDetailElement';
import {HighlightLayer as HighlightLayerJS} from './layers';
import {LayerProvider} from './layers/LayerProvider/LayerProvider';
import {MapBoxLayer} from './layers/MapBoxLayer';
import SelectLayerJS from './layers/nebula/SelectLayer';
import {MapContextProvider, useMapContext} from './MapContext/MapContext';
import {MapSettings, MapSize, MapStyle, SeaboMapProps} from './MapContext/Types';
import {MapCtrl} from './MapCtrl/MapCtrl';
import {MapDetails} from './MapDetails/MapDetails';
import {
  isMapPortElement,
  MapAreaElement,
  MapElement,
  MapOptionChangeValue,
  MapSwitches,
  MapSwitchesKey,
} from './MapDetails/utils/types';
import MapLegend from './MapLegend/MapLegend';
import {MapNavigation} from './MapNavigation/MapNavigation';
import {getInitialOptions} from './mapOptions/getInitialOptions';
import {SWITCHES} from './mapOptions/switches';
import {MapSearch} from './MapSearch/MapSearch';
import './seabo-map.scss';
import SettingsView from './SettingsView/SettingsView';
import {SideContentContainer} from './SideContent/SideContentContainer';
import {useSettingsPersistence} from './useSettingsPersistence';
import {getTransitionInterpolator} from './utils/getTransitionInterpolator';
import {onSearchSelect as onSearchSelectFromImport} from './utils/onSearchSelect/onSearchSelect';
import {SearchElement} from './utils/onSearchSelect/SearchElement';
import {WebGlDetection} from './WebGlDetection';
import {ErrorBoundary} from '../Sentry/ErrorBoundary';
import {useRenderDynamicLayers} from './useRenderDynamicLayers';
import {RootState} from '../../redux/store';
import {createSelector} from 'reselect';

const SelectLayer = SelectLayerJS as TODO;
const HighlightLayer = HighlightLayerJS as TODO;

const noop = () => {};

interface MapTradingArea {
  id: number;
  name: string;
}

const SeaboMapInternal = ({
  showSearch = false,
  showLegend = false,
  showControl = true,
  showNavigation = true,
  vesselCargoMode = false,
  showPopups = false,
  onReady = noop,
  onSaveSettings = noop,
  saveMapState = true,
  onViewStateChange = noop,
  onPortSelect = noop,
  dragRotate = false,
  promoLayerEnabled = true,
  isDragPanEnabled = true,
  isScrollZoomEnabled = true,
  ...props
}: SeaboMapProps) => {
  const mounted = useRef(false);
  const mapRef = useRef();
  const context = useMapContext();

  const mapOptions = getInitialOptions();
  const [isHovering, setIsHovering] = useState(false);
  const [hoverCoords, setHoverCoords] = useState(null);
  const [searchElement, setSearchElement] = useState<SearchElement | null>(null);
  const [selectedTradingArea, setSelectedTradingArea] = useState<MapTradingArea | null>(null);

  const ports = useSelector(portsSelector);

  const loading = useSelector(
    store =>
      store.api.map.getPorts.loading ||
      store.api.map.getTradingAreas.loading ||
      store.api.map.getPortfolioGroups.loading
  );
  const portsLoaded = useSelector(store => store.api.map.getPorts.state === RequestState.SUCCESS);
  const tradingAreasLoaded = useSelector(store => store.api.map.getTradingAreas.state === RequestState.SUCCESS);
  const selectedElement = useSelector(store => store.mapDetails.mapElement);

  const dispatch = useDispatch();
  const getPortfolioGroups = () => dispatch(MapService.getPortfolioGroups());
  const getPorts = () => dispatch(MapService.getPorts());
  const getTradingAreas = () => dispatch(MapService.getTradingAreas());
  const setMapDetailElement = useSetMapDetailElement();

  const saveSettings = () => {
    const {viewState, switches} = context.state;
    onSaveSettings({
      switches,
      ...(saveMapState && {
        map: {
          lat: parseFloat(viewState.latitude.toFixed(4)),
          lon: parseFloat(viewState.longitude.toFixed(4)),
          zoom: parseFloat(viewState.zoom.toFixed(4)),
        },
      }),
      version: SAVE_FORMAT_VERSION,
    });
  };

  const debouncedSaveSettings = useThrottle(saveSettings, [], 3000);
  const debouncedPropsOnViewStateChange = useThrottle(onViewStateChange ?? (() => {}), [], 500, {
    leading: true,
    trailing: true,
  });
  const debouncedPropsOnChangeMapSize = useThrottle(props.onChangeMapSize ?? (() => {}), [], 200);
  const debouncedPropsOnChangeSwitches = useThrottle(props.onChangeSwitches ?? (() => {}), [], 100);

  const updateViewState = ({viewState}: {viewState: TODO}) => {
    context.setViewState({...context.state.viewState, ...viewState});

    debouncedPropsOnViewStateChange(viewState);
    if (saveMapState) {
      debouncedSaveSettings();
    }
  };

  const loadData = () => {
    if (!portsLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getPorts();
    }

    if (!tradingAreasLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getTradingAreas();
    }

    if (vesselCargoMode) {
      getPortfolioGroups().then(() => {
        if (mounted.current) {
          const newSwitches = getInitialMapSwitches();

          const switches = {
            ...context.state.switches,
            [SWITCHES.VESSEL_PORTFOLIO_SUB]: newSwitches[SWITCHES.VESSEL_PORTFOLIO_SUB],
            [SWITCHES.CARGO_PORTFOLIO_SUB]: newSwitches[SWITCHES.CARGO_PORTFOLIO_SUB],
          };

          context.setSwitches(switches);
        }
      });
    }
  };

  // componentDidMount()
  useEffect(() => {
    mounted.current = true;
    loadData();

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMapDetailElement(props.defaultSelectedElement ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultSelectedElement]);

  useEffect(() => {
    setSearchElement(null);
  }, [showPopups]);

  const onSelectTradingArea = (element: MapAreaElement) => {
    const {properties} = element.object;
    const {id, name} = properties;
    props.onSelectElement?.(element as MapElement);
    setSelectedTradingArea(selectedTradingArea =>
      selectedTradingArea && selectedTradingArea.id === id ? null : {id, name}
    );
  };

  const onSelectElement = (element: MapElement) => {
    props.onSelectElement?.(element);

    if (element === null) {
      setMapDetailElement(null);
    } else if (element.noHighlight) {
      // TA click handling
      if (element.polygon) {
        context.fitBounds(element.polygon);
      }
    } else {
      const layerId = element?.layer?.id;

      if (layerId === LAYERS.PORT) {
        assert(isMapPortElement(element));
        onPortSelect?.(element.object.id);
      }

      setMapDetailElement(element);

      context.setViewState({
        ...context.state.viewState,
        ...getTransitionInterpolator(),
        longitude: (element as TODO).coordinate[0],
        latitude: (element as TODO).coordinate[1],
      });
    }
  };

  const renderDynamicLayers = useRenderDynamicLayers({
    context,
    ports,
    selectedTradingArea,
    showPortIds: props.showPortIds,
    searchElement,
    onSelectTradingArea,
    onSelectElement,
  });

  const onMapElementChange = useCallback(
    (object: TODO | null) => {
      const {searchElement, selectElement} = onSearchSelectFromImport({
        layers: (mapRef as TODO).current.deck.layerManager.layers,
        ports,
        object,
      });

      setSearchElement(searchElement);
      setMapDetailElement(selectElement);

      if (searchElement) {
        updateViewState({
          viewState: {
            ...getTransitionInterpolator(),
            latitude: searchElement.latitude,
            longitude: searchElement.longitude,
            zoom: 10,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ports]
  );

  const onOptionChange = (switchId: MapSwitchesKey, value: MapOptionChangeValue) => {
    const prevSwitches = context.state.switches;
    const isSubMenuMatch = switchId.match(/(.+)_sub$/); // user clicked on a sub menu switch
    const option = getOptionById(isSubMenuMatch ? isSubMenuMatch[1] : switchId, mapOptions);

    if (prevSwitches[switchId]!.vDisabled && prevSwitches[switchId]!.vState) {
      const newSwitches = {
        ...prevSwitches,
        [switchId]: {...prevSwitches[switchId], vState: false},
      };
      context.setSwitches(newSwitches);
      debouncedPropsOnChangeSwitches(newSwitches);
      return {switches: newSwitches};
    }

    const newValue = value === null ? !prevSwitches[switchId]!.state : value;
    const newSwitches = {
      [switchId]:
        typeof newValue === 'object' ? newValue : {...prevSwitches[switchId], state: newValue, vState: newValue},
    };

    function toggleEnable(currentSwitchId: string, keys: MapSwitchesKey[]) {
      keys.forEach(s => {
        if (newSwitches[currentSwitchId].state === true) {
          const newValue = newSwitches[s] ? newSwitches[s].state : prevSwitches[s]!.vState!;
          newSwitches[s] = {
            ...prevSwitches[s],
            ...newSwitches[s],
            state: newValue,
            vState: newValue,
            vDisabled: false,
          };
        } else {
          newSwitches[s] = {...prevSwitches[s], ...newSwitches[s], state: false, vState: true, vDisabled: true};
        }
      });
    }

    // a sub menu switch toggles also it's main switch
    if (isSubMenuMatch) {
      const mainSwitchStateShould = Object.values(newValue).some(v => v);
      if (
        (mainSwitchStateShould || !option.subMenuNoDisable) &&
        prevSwitches[isSubMenuMatch[1] as MapSwitchesKey]!.state !== mainSwitchStateShould
      ) {
        newSwitches[isSubMenuMatch[1]] = {
          ...prevSwitches[isSubMenuMatch[1] as MapSwitchesKey],
          state: mainSwitchStateShould,
          vState: mainSwitchStateShould,
          vDisabled: false,
        };
      }
    }

    if (option.turnsOn) {
      option.turnsOn.forEach((s: MapSwitchesKey) => {
        newSwitches[s] = {...prevSwitches[s], state: true, vState: true, vDisabled: false};
      });
    }

    if (option.turnsOff) {
      option.turnsOff.forEach((s: MapSwitchesKey) => {
        newSwitches[s] = {...prevSwitches[s], state: false, vState: false, vDisabled: false};
      });
    }

    for (const newSwitchId in newSwitches) {
      if (/(.+)_sub$/.test(newSwitchId)) {
        continue;
      }
      const option = getOptionById(newSwitchId, mapOptions);

      if (option.turnsOn) {
        option.turnsOn.forEach((s: MapSwitchesKey) => {
          newSwitches[s] = {...prevSwitches[s], state: true, vState: true, vDisabled: false};
        });
      }
    }

    for (const newSwitchId in newSwitches) {
      if (/(.+)_sub$/.test(newSwitchId)) {
        continue;
      }
      const option = getOptionById(newSwitchId, mapOptions);

      if (option.togglesEnable) {
        toggleEnable(newSwitchId, option.togglesEnable);
      }
    }

    for (const newSwitchId in newSwitches) {
      if (/(.+)_sub$/.test(newSwitchId)) {
        continue;
      }
      const option = getOptionById(newSwitchId, mapOptions);

      if (!option) {
        continue;
      }
      if (option.turnsOnSub && newSwitches[newSwitchId].state === true && !isSubMenuMatch) {
        const subSwitchId = `${option.id}_sub` as MapSwitchesKey;
        if (!Object.values(prevSwitches[subSwitchId]!).some(v => v)) {
          newSwitches[subSwitchId] = option.turnsOnSub.reduce(
            (subs: Record<string, TODO>, subToTurnOn: MapSwitchesKey) => {
              subs[subToTurnOn] = true;
              return subs;
            },
            prevSwitches[subSwitchId]
          );
        }
      }
    }

    for (const newSwitchId in newSwitches) {
      if (/(.+)_sub$/.test(newSwitchId)) {
        continue;
      }
      const option = getOptionById(newSwitchId, mapOptions);

      if (!option) {
        continue;
      }
      const allSwitches: MapSwitches = {...prevSwitches, ...newSwitches};

      if (option.togglesEnable) {
        const atLeastOneIsOn = Object.keys(allSwitches)
          .filter(s => option.togglesEnable.includes(s))
          .find(s => allSwitches[s as MapSwitchesKey]!.state === true);
        // turn off option
        if (!atLeastOneIsOn) {
          if (newValue === false || (typeof newValue === 'object' && !Object.values(newValue).find(v => v))) {
            newSwitches[newSwitchId] = {state: false, vState: false, vDisabled: false};
            toggleEnable(newSwitchId, option.togglesEnable);
          } else if (option.turnsOnIfNone) {
            option.turnsOnIfNone.forEach((s: MapSwitchesKey) => {
              newSwitches[s] = {state: true, vState: true, vDisabled: false};
            });
          }
        }
      }
    }

    const switches = {
      ...prevSwitches,
      ...newSwitches,
    };

    context.setSwitches(switches);
    debouncedPropsOnChangeSwitches(switches);

    return {
      switches,
      selectedTradingArea:
        newSwitches[SWITCHES.LAYER_TRADING_AREA] && newSwitches[SWITCHES.LAYER_TRADING_AREA].state === false
          ? null
          : selectedTradingArea,
    };
  };

  const onResize = (mapSize: MapSize) => {
    context.setMapSize(mapSize);
    debouncedPropsOnChangeMapSize(mapSize);
  };

  const onDeckGlLoad = () => {
    onViewStateChange?.(context.state.viewState);
    context.setMapReady(true);
    onReady?.();
  };

  const getDeckglTooltip = (event: PickingInfo): TooltipContent => {
    if (!event.layer) {
      return null;
    }

    let tooltipText;
    let hoverCoords = null;

    if (props.tooltip) {
      if (event.object && !isHovering) {
        setIsHovering(true);
      }

      return {
        html: `<div>${props.tooltip}</div>`,
        className: 'map-tooltip',
        style: {
          padding: '0',
          background: 'none',
        },
      };
    }

    if (event.object) {
      tooltipText = event.object.name;
      hoverCoords = !tooltipText || event.object.coordinates;

      if (event.layer.id === 'PortClusterLayer') {
        setIsHovering(true);
      } else {
        setIsHovering(true);
        setHoverCoords(hoverCoords);
      }

      if (tooltipText) {
        return {
          html: `<div>${tooltipText}</div>`,
          className: 'map-tooltip',
          style: {
            padding: '0',
            background: 'none',
          },
        };
      }
    } else if (isHovering || hoverCoords) {
      setIsHovering(false);
      setHoverCoords(null);
    }

    return null;
  };

  const getDeckglCursor = ({isDragging}: {isDragging: boolean}) => {
    if (context.state.isSelectMode) {
      return 'crosshair';
    }
    if (isHovering) {
      return props.cursorHover || 'pointer';
    }
    if (isDragging) {
      return 'grabbing';
    }
    return props.cursor || 'grab';
  };

  const {viewState, mapReady, isFullScreen, settings, selectedItems, switches} = context.state;
  const {layers, moveCtrlDown, overlays, effects} = props;
  const showMapDetails = !!showPopups && !!selectedElement;

  useEffect(() => {
    props.onSettingsChanged?.(settings);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const mapHeight = context.state.mapSize?.height;

  return (
    <MapContainer
      className={classNames('seabo-mapContainer', props.className, {
        'seabo-mapContainer--fullscreen': isFullScreen,
      })}>
      <Background
        $mapStyle={settings.mapStyle}
        className={`seabo-map seabo-map--${mapReady && !loading ? 'ready' : 'loading'} ${
          showMapDetails ? 'seabo-map--half' : ''
        } `}>
        {showControl && mapHeight && (
          <MapCtrl
            switches={switches}
            mapOptions={mapOptions}
            otherLayersDropDown={props.otherLayersDropDown}
            mapMenuZIndex={props.mapMenuZIndex}
            mapHeight={mapHeight}
            moveDown={moveCtrlDown}
            onChange={onOptionChange}
          />
        )}
        {overlays}
        {showNavigation && <MapNavigation />}
        {showSearch && <MapSearch onSelect={onMapElementChange} visible={!showMapDetails} />}
        {showLegend && <MapLegend vesselIconStyle={settings.vesselIconStyle} />}
        <MapBoxLayer staticMapBoxLayer={props.staticMapBoxLayer} selectedElement={selectedElement} />
        <LayerProvider
          onSelectElement={onSelectElement}
          layerFromOutside={layers}
          promoLayerEnabled={promoLayerEnabled}
          searchElement={selectedElement}
          selectedElement={selectedElement}
          viewState={viewState}>
          {layersFromProvider => {
            return (
              // !! react 18
              <DeckGL
                useDevicePixels={settings.mapResolution}
                onViewStateChange={updateViewState}
                onResize={onResize}
                onLoad={onDeckGlLoad}
                views={[new MapView({repeat: true})]}
                initialViewState={viewState}
                effects={effects}
                layers={[layersFromProvider, ...renderDynamicLayers]}
                controller={{
                  dragRotate,
                  touchRotate: true,
                  scrollZoom: isScrollZoomEnabled,
                  doubleClickZoom: true,
                  dragPan: isDragPanEnabled,
                }}
                ref={mapRef as TODO}
                getCursor={getDeckglCursor}
                getTooltip={getDeckglTooltip}
                pickingRadius={8}>
                {context.props.layersOnBottom ?? []}
                <HighlightLayer id="hover" getPosition={hoverCoords} visible={Array.isArray(hoverCoords)} />
                {searchElement && (
                  <HighlightLayer
                    id="search-highlight"
                    getPosition={[searchElement.longitude, searchElement.latitude]}
                  />
                )}
                {context.state.isSelectMode && (
                  <SelectLayer
                    id={'selection-layer'}
                    layerIds={[LAYERS.AIS]}
                    onSelect={(elements: TODO[]) => {
                      context.setSelectedItems({type: 'SelectLayer_Vessels', items: elements});
                      context.setSelectMode(false);
                    }}
                  />
                )}
                {context.props.layersOnTop ?? []}
              </DeckGL>
            );
          }}
        </LayerProvider>
        {context.props.componentOnTop ?? []}
      </Background>
      {showMapDetails && <MapDetails selectedItems={selectedItems} onMapElementChange={onMapElementChange} />}
      {context.props.componentsAsRightSiblings ?? []}
      {showPopups && (
        <SideContentContainer
          switches={switches}
          render={context.props.sideContentRenderer}
          onSelectElement={onSelectElement}
        />
      )}
      <SettingsView />
      <HookComponent />
    </MapContainer>
  );
};

export const SeaboMap: FC<SeaboMapProps> = props => {
  const {settingsLoaded, settings, saveSettings} = useSettingsPersistence({
    settingsPersistent: props.settingsPersistent,
    settingIdentifier: props.settingIdentifier,
    initialMapSettings: props.initialMapSettings,
  });

  const onSettingsChanged = (settings: MapSettings) => {
    saveSettings(settings);
    props.onSettingsChanged?.(settings);
  };

  if (!settingsLoaded) {
    return null;
  }

  return (
    <ErrorBoundary location="SeaboMap">
      <MapContextProvider seaboMapProps={props} initialMapSettings={settings}>
        <WebGlDetection>
          <StyledSeaboMapInternal {...props} initialMapSettings={settings} onSettingsChanged={onSettingsChanged} />
        </WebGlDetection>
      </MapContextProvider>
    </ErrorBoundary>
  );
};

const StyledSeaboMapInternal = styled(SeaboMapInternal)<SeaboMapProps>`
  .map-menu {
    ${({mapMenuZIndex}) =>
      mapMenuZIndex &&
      `
      z-index: ${mapMenuZIndex};
      `}
  }
`;

const Background = styled.div<{$mapStyle: MapStyle}>`
  background-color: ${({$mapStyle}) => MAPBOX_STYLES[$mapStyle].backgroundColor};
`;

const MapContainer = styled.div`
  container-name: seaboMap;
  container-type: size;
`;

const getPortsApiData = (store: RootState) => store.api.map.getPorts.data;
const portsSelector = createSelector([getPortsApiData], portsApiData => (portsApiData ? portsApiData.items : []));
