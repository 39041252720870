import {Card} from 'antd';
import dayjs from 'dayjs';
import {Fragment, FC} from 'react';
import styled from 'styled-components';
import {Attribute} from '../../../atoms/Attribute/Attribute';
import {ColumnContent} from '../../../atoms/DetailsSection/MultiColAttributesSection';
import {UserDateTime} from '../../../components/DateTime/UserDateTime';
import DetailFeatures from '../../../components/DetailFeatures';
import DocumentsGrid from '../../../components/DocumentsGrid';
import {numberRangeFormat} from '../../../utils/formatter';
import {TODO} from '../../../utils/TODO';
import {FeatureSection} from '../../CargoVesselForm/CargoVesselForm.styled';
import CargoUtil from '../../market/MarketCargo/CargoGrid/CargoUtil';
import {IntakeGrid} from '../../VesselDetailScreen/sections/Intake';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {NotAvailable} from '../../../utils/NotAvailable';

type CargoDetailBodyProps = {
  cargo?: TODO;
  type?: 'vc' | 'tct' | 'pc' | 'bc';
  generalInfoColumns: TODO;
  quantitySection: TODO;
  commoditySection: TODO;
  commissionSection: TODO;
  vesselFeatures: TODO;
};

export const CargoDetailBody: FC<CargoDetailBodyProps> = ({
  cargo,
  quantitySection,
  commissionSection,
  vesselFeatures,
}: CargoDetailBodyProps) => {
  return (
    <CargoDetailsBodyContainer>
      <div className="cargo-content__content">
        <FlexContainer>
          <BoxThird>
            {/* Laycan*/}
            <BoxTitle>Laycan</BoxTitle>
            <Attribute
              tab={'xl'}
              label={'From/Laydays'}
              value={cargo.laycanFrom ? dayjs(cargo.laycanFrom).format(DateTimeFormat.Date) : NotAvailable}
            />
            <Attribute
              tab={'xl'}
              label={'To/Cancelling'}
              value={cargo.laycanTo ? dayjs(cargo.laycanTo).format(DateTimeFormat.Date) : NotAvailable}
            />
          </BoxThird>
          {['tct', 'pc', 'bc'].includes(cargo.contractType) && (
            <BoxThird>
              <BoxTitle>Duration</BoxTitle>
              <Attribute label={'Duration'} value={CargoUtil.formatDuration(cargo)} tab={'xl'} />
              {cargo.contractType !== 'tct' && (
                <Attribute
                  tab={'xl'}
                  label={'Period'}
                  value={
                    <Fragment>
                      {cargo.periodFrom ? <UserDateTime value={cargo.periodFrom} format={'date'} /> : NotAvailable} -{' '}
                      {cargo.periodTo ? <UserDateTime value={cargo.periodTo} format={'date'} /> : NotAvailable}
                    </Fragment>
                  }
                />
              )}
              {cargo.contractType === 'pc' && (
                <Attribute tab={'xl'} label={'Laden legs'} value={numberRangeFormat(cargo.ladenMin, cargo.ladenMax)} />
              )}
              <Attribute label={'± Days duration'} value={cargo.durationAbout} tab={'xl'} />
              {cargo.contractType !== 'tct' && (
                <Attribute label={'± Days period'} value={cargo.periodAbout} tab={'xl'} />
              )}
              {cargo.contractType === 'pc' && <Attribute label={'Option'} value={cargo.ladenOption} tab={'xl'} />}
            </BoxThird>
          )}

          {/* VC: Quantity*/}
          {cargo.cargoType === 'drybulk' && cargo.contractType === 'vc' && (
            <BoxThird>
              <BoxTitle>Quantity</BoxTitle>
              <FlexBox>
                {quantitySection.map((col: {content: TODO[]}, key: number) => (
                  <BoxQuarter key={`multi-col-${key}`}>
                    <ColumnContent detailObject={cargo} content={col.content} vertical />
                  </BoxQuarter>
                ))}
              </FlexBox>
            </BoxThird>
          )}
          <BoxThird></BoxThird>
        </FlexContainer>
        {(cargo.contractType !== 'vc' || (cargo.cargoType === 'container' && cargo.contractType === 'vc')) && (
          <FlexContainer>
            {/* VesselFeatures */}
            {cargo.contractType !== 'vc' && (
              <BoxFull>
                <BoxTitle>Vessel specs</BoxTitle>
                <FlexBox>
                  {vesselFeatures.map((col: {content: TODO[]}, key: number) => (
                    <BoxQuarter key={`multi-col-${key}`}>
                      <ColumnContent detailObject={cargo} content={col.content} tab={'xl'} />
                    </BoxQuarter>
                  ))}
                  <BoxHalf>
                    <StyledDetailFeatures
                      detailObject={cargo}
                      iceClass={cargo.iceClass}
                      segment={cargo.vesselType}
                      type={'cargo'}
                    />
                  </BoxHalf>
                </FlexBox>
              </BoxFull>
            )}
            {/* Intakes */}
            {cargo.cargoType === 'container' && cargo.contractType === 'vc' && (
              <BoxHalf>
                <BoxTitle>Intakes</BoxTitle>
                <IntakeGrid intakes={cargo.intakes} type={'cargo'} />
              </BoxHalf>
            )}
          </FlexContainer>
        )}
        <FlexContainer>
          {/* Commission*/}
          <BoxThird>
            <BoxTitle>Commission</BoxTitle>
            <FlexBox>
              {commissionSection.map((col: {content: TODO[]}, key: number) => (
                <BoxQuarter key={`multi-col-${key}`}>
                  <ColumnContent detailObject={cargo} content={col.content} vertical />
                </BoxQuarter>
              ))}
            </FlexBox>
          </BoxThird>
          <BoxThird>
            {/* Documents */}
            <BoxTitle>Notes / Comments</BoxTitle>
            <span className="vessel-content__text">{cargo.comment || NotAvailable}</span>
          </BoxThird>
          <BoxThird>
            {/* Comment */}
            <BoxTitle>Documents</BoxTitle>
            <DocumentsGrid documents={cargo.documents} />
          </BoxThird>
        </FlexContainer>
      </div>
    </CargoDetailsBodyContainer>
  );
};

const CargoDetailsBodyContainer = styled(Card)`
  .ant-card-body {
    padding: 20px 23px;
  }
  .cargo-content__content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  width: 100%;
`;

const FlexContainer = styled(FlexBox)`
  flex-wrap: nowrap;
  gap: 20px;
`;

const BoxFull = styled.div`
  width: 100%;
`;

const BoxHalf = styled.div`
  flex-shrink: 1;
  width: 50%;
`;

const BoxThird = styled.div`
  flex-shrink: 1;
  width: 33%;
`;

const BoxQuarter = styled.div`
  flex-shrink: 1;
  width: 25%;
`;

const BoxTitle = styled.h3`
  font-size: var(--font-size-lg);
  font-weight: normal;
  margin-bottom: 10px;
`;

const StyledDetailFeatures = styled(DetailFeatures)`
  ${FeatureSection} {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
    margin-bottom: 5px;

    > * {
      width: 130px;
      height: 26px;
    }
  }
`;
