import {cargoAccessor} from '../../utils/cargoAccessorResult';
import Station from '../../../../model/Station';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import Cargo from '../../../../model/Cargo';

const dischargeRedeliveryFixtureAccessor = (type: $TSFixMe) =>
  cargoAccessor(
    (d: $TSFixMe) => (
      <div className="fixture-grid-cell__discharge">
        {Station.getLocationByStationType(d.stations, Station.FIXTUREREDELIVERY, {
          short: true,
          highlight: true,
          emptyState: '-',
        })}
      </div>
    ),
    type
  );
export const dischargeRedeliveryFixtureColumn = ({
  type,
  minWidth = 8,
}: {
  type: $TSFixMe;
  minWidth?: number;
}): CustomColumnDef<Cargo> => ({
  header: 'Disch. / redlvry',
  id: 'location',
  enableSorting: false,
  accessorFn: dischargeRedeliveryFixtureAccessor(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
