import React, {FC, ReactNode} from 'react';
import {Checkbox, Flex, Input, Space} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {Tag} from '../../api/symfony/generated';
import xor from 'lodash/xor';
import {ModalPopover, ModalPopoverTitle} from './ModalPopover';

interface TagSelectionProps {
  /**
   * Clicking these children will open the popover.
   */
  children: ReactNode;
  /**
   * The set of available tags.
   */
  availableTags: Tag[];
  /**
   * The set of tags currently selected on the tagged object.
   */
  selectedTags: Tag[];
  /**
   * Triggered when the popover closes and the selection has changed.
   */
  onChange: (selectedTags: Tag[]) => void;

  onOpenChange: (open: boolean) => void;
}

export const TagSelection: FC<TagSelectionProps> = ({
  children,
  availableTags,
  onOpenChange,
  selectedTags,
  onChange,
}) => {
  const [newTags, setNewTags] = React.useState<Tag[]>(selectedTags);
  const [visible, _setVisible] = React.useState<boolean>(false);

  const setVisible = (visible: boolean) => {
    _setVisible(visible);
    onOpenChange(visible);
    if (!visible) {
      // When closing the popover, submit the selection.
      onSubmit();
    }
  };

  const onSelectionChange = (tags: Tag[]): void => {
    setNewTags(tags);
  };

  const onSubmit = () => {
    if (xor(selectedTags, newTags).length > 0) {
      onChange(newTags);
    }
  };

  return (
    <ModalPopover
      trigger="click"
      title={null}
      open={visible}
      onOpenChange={visible => {
        setVisible(visible);
      }}
      content={() => (
        <PopoverContent
          availableTags={availableTags}
          selectedTags={newTags}
          onChange={tags => {
            onSelectionChange(tags);
          }}
          onClose={() => {
            setVisible(false);
          }}
        />
      )}>
      {children}
    </ModalPopover>
  );
};

const NoData = () => {
  return <div style={{textAlign: 'center'}}>No tags</div>;
};

const PopoverContent = ({
  availableTags,
  selectedTags,
  onChange,
  onClose,
}: {
  availableTags: Tag[];
  selectedTags: Tag[];
  onChange: (tags: Tag[]) => void;
  onClose: () => void;
}) => {
  const [search, setSearch] = React.useState<string>('');

  const handleTagClick = (tag: Tag, selectedValue: boolean): void => {
    if (selectedValue) {
      onChange([...selectedTags, tag]);
    } else {
      const newSelection: Tag[] = selectedTags.filter((selectedTag: Tag) => selectedTag.id !== tag.id);
      onChange(newSelection);
    }
  };

  const searchResult = availableTags?.filter((tag: Tag) => tag.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <ContentContainer>
      <Flex justify="space-between" align="center" style={{marginBottom: 16}}>
        <ModalPopoverTitle>Select tags</ModalPopoverTitle>
        <CloseOutlined
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        />
      </Flex>
      <Input.Search
        placeholder={'Search'}
        onChange={event => setSearch(event.target.value)}
        style={{width: '100%', marginBottom: 10}}
        size="small"
      />
      <ScrollArea>
        <Space size="small" direction="vertical">
          {searchResult.length === 0 ? (
            <NoData />
          ) : (
            <Space direction="vertical">
              {searchResult?.map((tag: Tag, index: number) => (
                <Checkbox
                  key={index}
                  checked={selectedTags.some(selectedTag => selectedTag.id === tag.id)}
                  onChange={e => handleTagClick(tag, e.target.checked)}>
                  {tag.name}
                </Checkbox>
              ))}
            </Space>
          )}
        </Space>
      </ScrollArea>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  width: calc(194px - 2 * 16px);
  height: 275px;
  margin: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const ScrollArea = styled.div`
  overflow-y: auto;
`;
