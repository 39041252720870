import {Action} from 'redux';

export const API = {
  REQUEST: 'API_REQUEST',
  CANCEL_REQUEST: 'API_CANCEL_REQUEST',
  VALIDATION_ERROR: 'API_VALIDATION_ERROR',
  ERROR: 'API_ERROR',
};

const Actions = {
  error: (error: number) =>
    ({
      type: API.ERROR,
      error,
    }) as Action,
};

export default Actions;
