import Flag from '../../../atoms/Flags/index';
import {Link} from 'react-router-dom';
import Country from '../../../model/Country';
import {CustomColumnDef} from '../../../components/DataGrid/DataGrid';
import Port from '../../../model/Port';

export const portColumns: () => CustomColumnDef<Port>[] = () => [
  {
    header: 'Flag',
    id: 'flag',
    cell: info => info.getValue(),
    accessorFn: (d: $TSFixMe) => <Flag countryCode={d.country} />,
    minWidth: 15,
    enableSorting: true,
  },
  {
    header: 'Port name',
    id: 'name',
    cell: info => info.getValue(),
    accessorFn: (d: $TSFixMe) => <Link to={'/ports/' + d.code}> {d.name} </Link>,
    minWidth: 50,
    enableSorting: true,
  },
  {
    header: 'Country',
    id: 'country',
    cell: info => info.getValue(),
    accessorFn: (d: $TSFixMe) => Country.getName(d.country),
    minWidth: 40,
    enableSorting: true,
  },
  {
    header: 'UN/Locode',
    id: 'code',
    cell: info => info.getValue(),
    accessorFn: (d: $TSFixMe) => d.code,
    minWidth: 40,
    enableSorting: true,
  },
  {
    header: 'Area',
    id: 'tradingArea',
    cell: info => info.getValue(),
    accessorFn: (d: $TSFixMe) => d.tradingArea,
    minWidth: 20,
    enableSorting: true,
  },
];
