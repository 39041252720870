import {Statistic} from 'antd';
import {FC} from 'react';
import styled, {css} from 'styled-components';
import {ChokepointCongestionSummaryReport} from '../../../api/node-backend/generated';
import {StatCard} from '../sharedComponents/StatCard';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {getMedian} from '../utils/getMedian';
import {ChokepointKey} from './utils/useChokepointCongestionQuery';
import {isSlowStreaming} from './isSlowStreaming';

export const CongestionStatus: FC<{report: ChokepointCongestionSummaryReport; chokepointKey: ChokepointKey}> = ({
  report,
  chokepointKey,
}) => {
  const status = report.isCongested ? 'Congested' : 'Normal';

  return (
    <StatCard>
      <SummaryStatistic
        $isUncommon={report.isCongested}
        title={'Status'}
        value={`${status} ${isSlowStreaming({chokepointKey, report}) ? '(Slow-streaming)' : ''}`}
        precision={0}
      />
    </StatCard>
  );
};

const SummaryStatistic = styled(Statistic)<{$isUncommon: boolean}>`
  .ant-statistic-content {
    ${({$isUncommon}) =>
      $isUncommon
        ? css`
            color: var(--color-red);
          `
        : css`
            color: var(--color-green-dark);
          `};
  }
`;

export const CurrentVessels: FC<{currentVisits: MergedVisits[]}> = ({currentVisits}) => {
  return (
    <StatCard>
      <Statistic title={'Currently'} value={currentVisits.length} suffix={'vessels'} precision={0} />
    </StatCard>
  );
};

export const VesselsPassaged: FC<{report: ChokepointCongestionSummaryReport}> = ({report}) => {
  const normalPassagedVessels48h = report.normalPassedVisitsPerDay * 2;
  const vesselsFinishedChangedInComparision = (report.passedVisitsLast48h / normalPassagedVessels48h - 1) * 100;
  return (
    <StatCard>
      <Statistic
        prefix={vesselsFinishedChangedInComparision > 0 ? '+ ' : '- '}
        title={'Change in transits (48h)'}
        value={Math.abs(vesselsFinishedChangedInComparision)}
        suffix={'%'}
        precision={2}
      />
    </StatCard>
  );
};

export const FinishedVisits: FC<{
  finishedVisits: MergedVisits[];
  onSetVisits: (visits: MergedVisits[]) => void;
}> = ({finishedVisits, onSetVisits}) => {
  return (
    <StatCard
      onClick={() => {
        onSetVisits(finishedVisits);
      }}>
      <Statistic title={'Finished transits'} value={finishedVisits.length} precision={0} />
    </StatCard>
  );
};

export const MedianWaitingTime: FC<{visits: MergedVisits[]}> = ({visits}) => {
  const waitingDurationMedian = getMedian(visits.map(visit => visit.waitingDuration));
  return (
    <StatCard>
      <Statistic title={'Median waiting time'} value={waitingDurationMedian / 24} suffix={'d'} precision={2} />
    </StatCard>
  );
};

export const MedianPassageTime: FC<{visits: MergedVisits[]}> = ({visits}) => {
  const passageDurationMedian = getMedian(visits.map(visit => visit.passageDuration));
  return (
    <StatCard>
      <Statistic title={'Median transit time'} value={passageDurationMedian / 24} suffix={'d'} precision={2} />
    </StatCard>
  );
};
