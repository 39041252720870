import {VesselFromSpire} from '../../../../queries/queriesToSpire/VesselFromSpire';
import {TODO} from '../../../../utils/TODO';
import {getVesselTypeFromSpire} from './getVesselTypeFromSpire';

type Vessel = TODO;

export const transformSpireVesselToPlattformVessel = (spireVessel: VesselFromSpire): Vessel => {
  const spireVesselType = spireVessel._embedded.enhanced_data._embedded.vessel_and_trading_type.vessel_type;

  const vesselType = getVesselTypeFromSpire(spireVesselType);

  let owner: string | undefined = spireVessel._embedded.enhanced_data._embedded.history.commercial_owner;
  if (owner === 'Unknown') {
    owner = undefined;
  }

  return {
    countryFlag: spireVessel.flag,
    name: spireVessel.name,
    draft: spireVessel._embedded.enhanced_data._embedded.dimensions.draught,
    shipBuilder: spireVessel._embedded.enhanced_data._embedded.history.ship_builder,
    beam: spireVessel.width ?? 0,
    lengthOverall: spireVessel._embedded.enhanced_data._embedded.dimensions.loa,
    dwtSummer: spireVessel._embedded.enhanced_data._embedded.capacity.dwt,
    intakes: spireVessel._embedded.enhanced_data._embedded.capacity.teu
      ? [
          {
            type: 'teu',
            isEmpty: false,
            isHighCube: false,
            quantity: spireVessel._embedded.enhanced_data._embedded.capacity.teu,
          },
        ]
      : [],
    tonnesPerCentimetre: spireVessel._embedded.enhanced_data._embedded.capacity.tpcmi,
    builtYear: spireVessel._embedded.enhanced_data._embedded.history.built_year,
    grossTonnage: spireVessel._embedded.enhanced_data._embedded.capacity.gross_tonnage,
    netTonnage: spireVessel._embedded.enhanced_data._embedded.capacity.net_tonnage,
    imo: spireVessel.imo,
    mmsi: spireVessel.mmsi,
    callSign: spireVessel.call_sign,
    owner,
    vesselType,

    spireAddedAt: spireVessel.updated_at,

    gears: [],
    consumptions: [],
    comment: null,
    nextOpenFrom: null,
    nextOpenTo: null,
    dryDockFrom: null,
    dryDockTo: null,
    dryDockNote: null,
    spotState: false,
    spotDate: null,
    stations: [],
    fixtures: [],
    selectedFixture: null,
    rate: null,
    rateCurrency: null,
    rateTerms: null,
    redeliveryNotice: null,
    color: null,
    documents: [],
    images: [],
  };
};
