import {MapSwitch, MapSwitches} from '../MapDetails/utils/types';

type MapSwitchAlias =
  | 'ports'
  | 'highRiskAreas'
  | 'emissionControlAreas'
  | 'tradingAreaNames'
  | 'vesselAll'
  | 'cargoAll'
  | 'vesselMyFleet'
  | 'cargoMyCargo'
  | 'vesselMarket'
  | 'cargoCommodities'
  | 'tradingArea'
  | 'cargoMarket';

const getDefaultSwitch = (value: boolean): MapSwitch => ({
  state: value,
  vDisabled: false,
  vState: value,
});

// The MapSwitch architecture is a bit of a mess. This function should make it easier to set the switches.
export const makePartialMapSwitches = (records: Partial<Record<MapSwitchAlias, boolean>>): Partial<MapSwitches> => {
  const mapSwitches: Partial<MapSwitches> = {};

  for (const [key, value] of Object.entries(records)) {
    switch (key as MapSwitchAlias) {
      case 'ports':
        // eslint-disable-next-line camelcase
        mapSwitches.m_port = getDefaultSwitch(value);

        break;
      case 'highRiskAreas':
        // eslint-disable-next-line camelcase
        mapSwitches.layer_piracy = getDefaultSwitch(value);

        break;
      case 'vesselMarket':
        // eslint-disable-next-line camelcase
        mapSwitches.vessel_market = getDefaultSwitch(value);
        break;
      case 'vesselMyFleet':
        // eslint-disable-next-line camelcase
        mapSwitches.vessel_portfolio = getDefaultSwitch(value);
        break;
      case 'cargoMyCargo':
        // eslint-disable-next-line camelcase
        mapSwitches.cargo_portfolio = getDefaultSwitch(value);
        break;

      case 'cargoCommodities':
        // eslint-disable-next-line camelcase
        mapSwitches.cargo_commodities = getDefaultSwitch(value);
        break;
      case 'cargoMarket':
        // eslint-disable-next-line camelcase
        mapSwitches.cargo_market = getDefaultSwitch(value);
        break;

      case 'vesselAll':
        // eslint-disable-next-line camelcase
        mapSwitches.m_vessel = getDefaultSwitch(value);
        break;
      case 'cargoAll':
        // eslint-disable-next-line camelcase
        mapSwitches.m_cargo = getDefaultSwitch(value);
        break;
      case 'emissionControlAreas':
        // eslint-disable-next-line camelcase
        mapSwitches.layer_eca = getDefaultSwitch(value);
        break;
      case 'tradingAreaNames':
        // eslint-disable-next-line camelcase
        mapSwitches.trading_area_names = getDefaultSwitch(value);
        break;
      case 'tradingArea':
        // eslint-disable-next-line camelcase
        mapSwitches.layer_trading_area = getDefaultSwitch(value);
        break;
      default:
        throw new Error(`Unknown map switch alias: ${key}`);
    }
  }
  return mapSwitches;
};
