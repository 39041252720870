import {getFormattedString} from './getFormattedString';
import {DateFormat} from './formatMapping';
import {DateTimeSource} from './sourceMapping';
import {useUserOrNull} from '../UserContext/useUser';

export interface UserDateTimeProps {
  format: DateFormat;
  source?: DateTimeSource;
  value: string;
}

/**
 * This hook returns a function.
 * With the function a time can be converted into a date, dateTime or time under consideration of the user time zone.
 */
export const useGetUserDateTime = () => {
  const user = useUserOrNull();

  const timezone = user?.options.timezone;

  const getUserDateTime = ({value, format, source}: UserDateTimeProps): string => {
    return getFormattedString({value, format, source, tz: timezone});
  };

  return getUserDateTime;
};
