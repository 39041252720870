import {Radio} from 'antd';
import styled from 'styled-components';
import {cleanIdentifier} from '../../utils/helper';
import {TODO} from '../../utils/TODO';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Value = string | number | Record<string, any>;

export interface Option {
  label: string;
  value: string | number;
  key: string;
  disabled?: boolean;
}

interface ChildComponent {
  props: {
    label: string;
    value: string | number;
    id: string;
    disabled?: boolean;
  };
}

type Children = (ChildComponent | undefined)[];

interface Props {
  children?: Children;
  size?: 'large' | 'middle' | 'small';
  options?: Array<Option>;
  value?: Value;
  onChange?: (value: Value) => void;
  defaultValue?: Value;
  className?: string;
  fullWidth?: boolean;
}

const UnstyledToggleableButtonGroup = (props: Props) => {
  let items: Array<Option>;
  // Options hits children
  if (props.options) {
    items = props.options;
  } else if (props.children) {
    // Here we rely on React internals for `children`. These internals can change with
    // newer React versions. We could use React.Children.map and friends, but then the 'key' prop
    // prop would become unavailable.
    // The solution is to stop using the old components in 'children', and instead use 'options' or
    // Radio.Button directly.

    // Flatten any component arrays in children
    const flattened = props.children.flat(1);
    // Remove 'undefined' entries
    const filtered = flattened.filter(item => item) as ChildComponent[];
    items = filtered.map(item => ({...item.props, key: (item as TODO).key ?? item.props.id}));
  } else {
    items = [];
  }

  // Enable uncontrolled component behaviour.
  // Passing 'undefined' to value is not enough, the value prop really has to be absent.
  const valueProp = props.value === undefined ? {} : {value: props.value};

  return (
    <Radio.Group
      className={props.className}
      size={props.size}
      buttonStyle="solid"
      defaultValue={props.defaultValue}
      {...valueProp}
      onChange={event => {
        props.onChange?.(event.target.value);
      }}>
      {items.map(item => {
        return (
          <Radio.Button
            id={cleanIdentifier(item.key, 'toggleable')}
            disabled={item.disabled}
            key={item.key}
            value={item.value}>
            {item.label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

const ToggleableButtonGroup = styled(UnstyledToggleableButtonGroup)`
  ${props =>
    props.fullWidth &&
    `
         width: 100%;
  `}
`;

export default ToggleableButtonGroup;
