import dayjs, {Dayjs} from 'dayjs';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {dateEarlierThanOrEqualTo} from '../../../../utils/validators';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

export type CargoType = 'container' | 'drybulk';

export type DatabaseType = {
  from?: string | null;
  to?: string | null;
  spotState?: boolean;
  spotOnly?: boolean;
};

export type InternalType = {
  from: Dayjs | null;
  to: Dayjs | null;
  spotState?: boolean;
  spotOnly?: boolean;
};

export const Defaults: InternalType = {
  from: null,
  to: null,
  spotState: true,
  spotOnly: false,
};

export const fromDatabase = (database: DatabaseType): InternalType => ({
  from: database.from ? dayjs(database.from) : null,
  to: database.to ? dayjs(database.to) : null,
  spotState: database.spotState,
  spotOnly: database.spotOnly,
});

export const toDatabase = ({from, to, spotState, spotOnly}: InternalType): DatabaseType => ({
  spotState: spotState,
  spotOnly: spotOnly,
  from: from ? from.format(DateTimeFormat.IsoDate) : undefined,
  to: to ? to.format(DateTimeFormat.IsoDate) : undefined,
});

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Date open',
  branch: 'dateOpen',
  defaults: Defaults,
  validators: {
    from: dateEarlierThanOrEqualTo('to'),
  },
  fromDatabase,
  toDatabase,
});
