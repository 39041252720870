import {MapRef, ViewState} from 'react-map-gl';
import React, {RefObject, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useStaticRouteLayer} from '../layers/StaticRouteLayer/useStaticRouteLayer';
import {Leg} from '../layers/StaticRouteLayer/Route';
import {AISRecord} from '../queries/aisRecordsQuery/AISRecord';
import {makeRoute} from '../layers/StaticRouteLayer/makeRoute';
import {makeTrips} from '../layers/TripsLayer/makeTrips';
import {useTripsLayer} from '../layers/TripsLayer/useTripsLayer';
import {DeckGLOverlay} from './DeckGLOverlay';
import {usePortAreaLayer} from '../layers/PortAreaLayer/usePortAreaLayer';
import {VoyageAnimationPortVisit} from '../model/VoyageAnimationPortVisit';
import clamp from 'lodash/clamp';
import {interpolateGeoCoordinates} from '../utils/interpolateGeoCoordinates';
import {usePortNameLayer} from '../layers/PortNameLayer/usePortNameLayer';
import {PortMarkers} from '../layers/PortMarkers/PortMarkers';
import {VoyageAnimationApi} from './VoyageAnimationApi';
import {TimeControl} from '../model/TimeControl';
import {useVesselIconLayer, VesselSprite} from '../layers/VesselIconLayer/useVesselIconLayer';
import {AnimationState} from '../model/AnimationState';
import {makeAnimeAnimation} from '../makeAnimeAnimation/makeAnimeAnimation';
import {AnimeAnimation} from './AnimeAnimation';
import {useAisRecordsCsvRowMapping} from '../queries/aisRecordsQuery/useAisRecordsCsvRowMapping';
import {LonLatCoordinates} from '../../../../../utils/Coordinates';
import {assert} from '../../../../../utils/assert';
import {makeAnimationEvents} from '../makeAnimationEvents/makeAnimationEvents';
import {VesselTypeKey} from '../../../../../components/consts/vesselTypes';
import {useThrottle} from '../../../../../utils/useThrottle';
import {isTest} from '../../../../../utils/environment';
import {useStaticAnimationEventsLayer} from '../layers/StaticRouteLayer/useStaticAnimationEventsLayer';
import {AnimationEvent} from '../model/AnimationEvent';
import {MAPBOX_ACCESS_TOKEN, MAPBOX_STYLES} from '../../../../../components/SeaboMap/const';
import Map from './Map';

const INITIAL_VIEW_STATE: ViewState = {
  longitude: 0,
  latitude: 54,
  zoom: 5,
  bearing: 0,
  pitch: 30,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export const MAP_ELEMENT_ID = 'voyage-animation-map';

const MIN_DISTANCE_TO_PORT_ANINMATION_START_IN_KM = 7;

const doNothing = () => {};

export const VoyageAnimation = ({
  voyageAnimationApi,
  vesselType,
  timeControl,
  aisRecords,
  portVisits,
  tripsLayerVisible,
  routeLayerVisible,
  staticAnimationEventsLayerVisible,
  portAreaLayerVisible,
  autoplay,
  onLegClick,
  onAimationEventClick,
  onVesselClick,
  onPortEntered,
  onAnimationReady,
  onAnimationTimestampUpdate,
}: {
  voyageAnimationApi: VoyageAnimationApi;
  vesselType: VesselTypeKey;
  timeControl: TimeControl;
  aisRecords: AISRecord[];
  portVisits: VoyageAnimationPortVisit[];
  tripsLayerVisible: boolean;
  routeLayerVisible: boolean;
  portAreaLayerVisible: boolean;
  staticAnimationEventsLayerVisible: boolean;
  autoplay: boolean;
  onPortEntered: (portVisit: VoyageAnimationPortVisit) => void;
  onLegClick: (leg: Leg) => void;
  onAimationEventClick: (animationEvent: AnimationEvent) => void;
  onVesselClick: (vesselSprite: VesselSprite) => void;
  onAnimationReady: () => void;
  onAnimationTimestampUpdate?: (timestamp: number) => void;
}) => {
  const [currentPortVisit, setCurrentPortVisit] = useState<VoyageAnimationPortVisit | undefined>(undefined);

  assert(aisRecords.length >= 2, 'Expected at least 2 AIS records');

  const animationStartCoordinates = aisRecords[0].coordinates;

  const [animationState, setAnimationState] = useState<AnimationState>({
    timestamp: aisRecords[0].timestamp.valueOf(),
    longitude: aisRecords[0].coordinates[0],
    latitude: aisRecords[0].coordinates[1],
    angle: aisRecords[0].heading,
    portVisitId: -1,
    cameraFollowsVessel: 1,
    standingStill: 0,
    aisRecordCsvRow: 0,
    portAreaVibility: 0,
  });

  const {timestamp} = animationState;

  /**
   * The time that has passed since the start of the period in milliseconds.
   */
  const [zoom, setZoom] = useState(() => INITIAL_VIEW_STATE.zoom);

  const mapRef: RefObject<MapRef> = useRef<MapRef>(null);
  const [vesselSprite, setVesselSprite] = useState<VesselSprite>({
    coordinate: aisRecords[0].coordinates,
    angle: aisRecords[0].heading,
  });
  const lastUpdateRef = useRef<number>(performance.now());

  const onAnimationTimestampUpdateThrottled = useThrottle(onAnimationTimestampUpdate ?? doNothing, [], 500, {
    leading: true,
    trailing: true,
  });

  /**
   * Called by the anime.js animation.
   */
  const onUpdate = (animationState: AnimationState) => {
    const now = performance.now();
    const realTimePassedMs = now - lastUpdateRef.current;
    lastUpdateRef.current = now;

    onAnimationTimestampUpdateThrottled(animationState.timestamp);
    setAnimationState(animationState);
    const vesselSprite: VesselSprite = {
      coordinate: [animationState.longitude, animationState.latitude],
      angle: animationState.angle,
    };
    setVesselSprite(vesselSprite);
    const portVisit = portVisitsMap[animationState.portVisitId];
    setCurrentPortVisit(portVisit);
    const moored = animationState.portVisitId !== -1 && animationState.standingStill === 1;
    if (animationState.cameraFollowsVessel && !moored) {
      moveCameraTowardsVessel(realTimePassedMs, vesselSprite);
    }
  };

  // Use a ref to keep the onUpdate function non stale.
  const onUpdateRef = useRef(onUpdate);
  onUpdateRef.current = onUpdate;

  const animeAnimationRef = useRef<AnimeAnimation>();

  const animationEvents = useMemo(() => {
    return makeAnimationEvents(portVisits, aisRecords, MIN_DISTANCE_TO_PORT_ANINMATION_START_IN_KM);
  }, [aisRecords, portVisits]);

  const trips = useMemo(() => {
    return makeTrips(animationEvents);
  }, [animationEvents]);

  useLayoutEffect(() => {
    if (!mapRef.current) {
      return;
    }
    mapRef.current.flyTo({
      center: animationStartCoordinates,
      duration: 300,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current]);

  // When getting a new imo or new timeframe or new animation speed, we create a new animation.
  useLayoutEffect(() => {
    if (!mapRef.current) {
      // Wait until the map is ready.
      return;
    }

    if (timeControl.playing) {
      // Stop old animation
      animeAnimationRef.current?.animeTimeline.pause();
    }
    assert(!animeAnimationRef.current, 'animeAnimationRef.current is already set');

    const animeAnimation = makeAnimeAnimation(
      animationStartCoordinates,
      animationEvents,
      mapRef,
      timeControl.speed,
      autoplay,
      onUpdateRef
    );
    animeAnimationRef.current = animeAnimation;
    voyageAnimationApi.animeTimeline = animeAnimation.animeTimeline;
    voyageAnimationApi.animationState = animeAnimation.animationState;
    setAnimationState(animeAnimation.animationState);
    setVesselSprite({
      coordinate: [animeAnimation.animationState.longitude, animeAnimation.animationState.latitude],
      angle: animeAnimation.animationState.angle,
    });

    // Expose the animeAnimation object in the JS console for debugging.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).animeAnimation = animeAnimation;

    onAnimationReady();

    return () => {
      animeAnimationRef.current?.animeTimeline.pause();
      animeAnimationRef.current = undefined;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aisRecords, timeControl.speed, mapRef.current]);

  const center = mapRef.current?.getCenter();
  const currentCameraPosition: LonLatCoordinates = center
    ? [center.lng, center.lat]
    : [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude];

  const moveCameraTowardsVessel = (realTimePassedMs: number, vesselSprite: VesselSprite) => {
    assert(mapRef.current, 'moveCameraTowardsVessel: mapRef.current is not set');
    // Move the camera to follow the vessel by interpolating the new camera position from the vessel position by X percent.
    const cameraSpeedPercent = 0.02;
    const percent = clamp(cameraSpeedPercent * (realTimePassedMs / 8.33), 0, 1);
    const newCameraPosition = interpolateGeoCoordinates(currentCameraPosition, vesselSprite.coordinate, percent);
    mapRef.current!.setCenter(newCameraPosition);
  };

  const route = useMemo(() => makeRoute(aisRecords), [aisRecords]);

  const portVisitOngoing = !!currentPortVisit;

  const interpolatedVesselPosition = vesselSprite.coordinate;

  const centerVesselInViewport = () => {
    if (isTest()) {
      return;
    }
    if (!mapRef.current) {
      // eslint-disable-next-line no-console
      console.error('mapRef.current is not available in centerVesselInViewport');
      return;
    }
    mapRef.current.flyTo({
      center: interpolatedVesselPosition,
      duration: 1500,
    });
  };

  voyageAnimationApi.recenter = () => {
    centerVesselInViewport();
  };

  const portVisitsMap = useMemo(() => {
    const entries: [number, VoyageAnimationPortVisit][] = portVisits.map(porVisit => [porVisit.id, porVisit]);
    const map: Record<number, VoyageAnimationPortVisit> = Object.fromEntries(entries);
    return map;
  }, [portVisits]);

  useEffect(() => {
    // After initial render, center the vessel in the viewport
    const timerId = setTimeout(() => {
      voyageAnimationApi.recenter();
    }, 120);

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aisRecords]);

  useEffect(() => {
    if (currentPortVisit) {
      onPortEntered(currentPortVisit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPortVisit]);

  const tripsLayerDisplayed = tripsLayerVisible && !portVisitOngoing;

  const portNameLayer = usePortNameLayer(portVisits);
  const portAreaLayer = usePortAreaLayer(currentPortVisit, animationState.portAreaVibility, portAreaLayerVisible);
  const vesselIconLayer = useVesselIconLayer(vesselType, zoom, vesselSprite, onVesselClick);
  const aisRecordCsvRowMapping = useAisRecordsCsvRowMapping(aisRecords);
  const aisRecord = aisRecordCsvRowMapping[animationState.aisRecordCsvRow] ?? aisRecords[0];
  const speed = aisRecord.speed;
  const tripsLayer = useTripsLayer(timestamp, trips, tripsLayerDisplayed, speed);
  const staticRouteLayer = useStaticRouteLayer({
    route,
    routeLayerVisible,
    onLegClick,
  });
  const staticAnimationEventsLayer = useStaticAnimationEventsLayer({
    animationEvents,
    staticAnimationEventsLayerVisible,
    onAimationEventClick,
  });

  const portMarkersVisible = !portVisitOngoing;

  const layers = [
    tripsLayer,
    vesselIconLayer,
    portAreaLayer,
    routeLayerVisible && staticRouteLayer,
    staticAnimationEventsLayerVisible && staticAnimationEventsLayer,
    portMarkersVisible && portNameLayer,
  ].filter(Boolean);

  return (
    <Map
      id={MAP_ELEMENT_ID}
      ref={mapRef}
      initialViewState={INITIAL_VIEW_STATE}
      mapStyle={MAPBOX_STYLES.SAT.styleURL}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      onError={e => {
        // eslint-disable-next-line no-console
        console.error('Deck.gl error', e.error);
      }}
      onZoom={e => {
        setZoom(e.viewState.zoom);
      }}>
      <DeckGLOverlay useDevicePixels={true} layers={layers} />
      {portMarkersVisible && <PortMarkers portVisits={portVisits} />}
    </Map>
  );
};
