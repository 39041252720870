import cx from 'classnames';
import Icon from '../../atoms/Icon';
import StationSelect from './StationSelect';
import DragHandle from './DragHandle';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import styled from 'styled-components';
import {DistanceCalculatorDate} from '../../screens/DistanceCalculator/CalculatorSettings/DistanceCalculatorDate';
import {roundNumberToDecimals} from '../../utils/roundNumber';
import {numberFormat} from '../../utils/formatter';

function Station({
  id,
  dragID,
  station,
  onChangeStation,
  onChangeDate,
  onRemove,
  isLast,
  isFirst,
  activeTab,
  resultETD,
  resultETA,
  isCalculating,
  onCalculate,
  onClearCalculation,
  disableRemove,
  placeholder,
  seca,
  piracy,
  hasError,
  previousHasError,
  previousStationId,
  onKeyDown,
}: $TSFixMe) {
  // Use dragID as the id for the useSortable hook because one Station can occur multiple times
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: dragID});
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      style={style}
      className={cx('timeline__item', {
        'timeline__item--last': isLast,
        'timeline__item--first': isFirst,
        'timeline__item--has-error': hasError,
        'timeline__item--previous-has-error': previousHasError,
      })}
      data-testid={`Station-${id}`}>
      <div className="timeline__line">
        <span className={'timeline__line-before'} />
        <span className={'timeline__line-after'} />
      </div>
      <div className="timeline__content">
        <div className="timeline__station" data-testid={`Station-${id}-Select`}>
          <StationSelect
            id={`stations-select-${id}`}
            placeholder={placeholder}
            value={station}
            onChange={(s: $TSFixMe) => onChangeStation(s)}
            onKeyDown={(s: $TSFixMe) => onKeyDown && onKeyDown(s)}
            width="100%"
            size="medium"
            previousStationId={previousStationId}
          />
        </div>
        <div className="timeline__date" data-testid={`Station-${id}-DatePicker`}>
          {isFirst ? (
            <DistanceCalculatorDate
              type="ETD"
              result={resultETD}
              timezone={station?.timezone}
              showInput={activeTab === 'ETA' || activeTab === 'SPEED'}
              isCalculating={isCalculating}
              onChange={onChangeDate}
              onCalculate={onCalculate}
              onClear={onClearCalculation}
            />
          ) : null}
          {isLast ? (
            <DistanceCalculatorDate
              type="ETA"
              result={resultETA}
              timezone={station?.timezone}
              showInput={activeTab === 'ETD' || activeTab === 'SPEED'}
              isCalculating={isCalculating}
              onChange={onChangeDate}
              onCalculate={onCalculate}
              onClear={onClearCalculation}
            />
          ) : null}
        </div>
        {(!!seca || !!piracy) && (
          <div className="timeline__infos">
            {!!seca && (
              <div className="timeline__info" data-testid={`Station-${id}-NoteSECA`}>
                <Icon type={'block'} />
                Crossing emission control area ({numberFormat(roundNumberToDecimals(seca, 2))} NM)
              </div>
            )}
            {!!piracy && (
              <div className="timeline__info" data-testid={`Station-${id}-NoteHRA`}>
                <Icon type={'block'} />
                Crossing high risk area ({numberFormat(roundNumberToDecimals(piracy, 2))} NM)
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className="timeline__menu"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        data-testid={`Station-${id}-ButtonDrag`}>
        <div>
          <DragHandle />
        </div>
      </div>
      <RemoveIcon
        className="timeline__remove"
        data-testid={`Station-${id}-ButtonRemove`}
        style={{visibility: disableRemove ? 'hidden' : 'visible'}}>
        <Icon type={'close'} onClick={onRemove} />
      </RemoveIcon>
    </div>
  );
}

export default Station;

const RemoveIcon = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 8px 0 18px 10px;
  visibility: hidden;
`;
