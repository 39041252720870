import ReactDOM from 'react-dom';
import './style.scss';
import classNames from 'classnames';
import Icon from '../../../atoms/Icon';
import {TODO} from '../../../utils/TODO';
import {Link} from 'react-router-dom';

type Props = {
  className?: string;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  onFocus?: $TSFixMeFunction;
  onSelect?: (option: TODO, event: React.MouseEvent) => void;
  option: TODO;
  selectProps: {
    inputValue: string;
  };

  innerProps: Record<string, TODO>;
  innerRef: React.Ref<HTMLDivElement>;
  data: {
    overViewPageLink?: boolean;
    searchTitle?: string;
    searchSubTitle?: string;
  };
};
export const AutoCompleteOption = (props: Props) => {
  const onSelect = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    props.onSelect?.(props.option, event);
  };

  const {
    data: {searchSubTitle, searchTitle, overViewPageLink},
    innerProps,
    innerRef,
    isFocused,
  } = props;
  const css = classNames('search-autocomplete-option', {
    'search-autocomplete-option--focused': isFocused,
  });
  if (overViewPageLink) {
    const portalParent = document.getElementById('overviewLink');

    if (portalParent) {
      return ReactDOM.createPortal(
        <Link
          to={'/search/' + props.selectProps.inputValue}
          className={'autocomplete-menu-list'}
          onClick={onSelect}
          {...innerProps}>
          <span className={'autocomplete-menu-list__text'}>Show all search results</span>
          <span className={'autocomplete-menu-list__icon'}>
            <Icon type={'angle-right'} color={'white'} />
          </span>
        </Link>,
        portalParent
      );
    }
  }
  return (
    <div ref={innerRef} onClick={onSelect} {...innerProps} className={css}>
      <span className={'search-autocomplete-option__title'}>{searchTitle}</span>{' '}
      <span className={'search-autocomplete-option__sub-title'}>{searchSubTitle}</span>
    </div>
  );
};

export default AutoCompleteOption;
