import {IconLayer, PathLayer} from '@deck.gl/layers/typed';
import iconAtlas from '../../../SeaboMap/layers/icons/icon-atlas.png';
import iconMapping from '../../../SeaboMap/layers/icons/icon-mapping.json';
import {Color} from '../../types';
import {Coordinates} from '../../../../utils/Coordinates';
import {useMemo} from 'react';
import {TODO} from '../../../../utils/TODO';

export type Point = {
  coordinates: Coordinates;
  isPort: boolean;
};

export type Route = {
  color: Color;
  path: Array<Coordinates>;
};

export type Props = {
  id?: string;
  points: Array<Point>;
  routes: Array<Route>;
};

export const useCharterCalculationVoyageLayer = (props: Props) => {
  return useMemo(() => {
    return [
      new PathLayer(
        {
          id: `charter-calculation-voyage-${props.id}-path`,
          data: props.routes,
          pickable: true,
          widthScale: 20,
          wrapLongitude: true,
          widthMinPixels: 2,
          getPath: (d: TODO) => d.path,
          getColor: (d: TODO) => {
            return d.color || [160, 160, 0];
          },
          getWidth: () => 5,
        } as TODO /* deckgl types are shit :D */
      ),
      new IconLayer({
        id: `charter-calculation-voyage-${props.id}-icon`,
        data: props.points,
        getIcon: () => 'anchor-yellow',
        getPosition: (d: TODO) => d.coordinates,
        getSize: 50,
        iconAtlas,
        iconMapping,
        opacity: 0.03,
        visible: true,
      }),
    ];
  }, [props]);
};
