/* tslint:disable */
/* eslint-disable */
/**
 * BunkerService
 * BunkerService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  FuturePrice,
  FuturePriceFromJSON,
  FuturePriceFromJSONTyped,
  FuturePriceToJSON,
  Price,
  PriceFromJSON,
  PriceFromJSONTyped,
  PriceToJSON,
} from './';

/**
 *
 * @export
 * @interface Prices
 */
export interface Prices {
  /**
   *
   * @type {Array<Price>}
   * @memberof Prices
   */
  priceList?: Array<Price>;
  /**
   *
   * @type {Array<FuturePrice>}
   * @memberof Prices
   */
  futurePriceList?: Array<FuturePrice>;
  /**
   *
   * @type {number}
   * @memberof Prices
   */
  errorCode?: number;
  /**
   *
   * @type {string}
   * @memberof Prices
   */
  errorMessage?: string;
}

export function PricesFromJSON(json: any): Prices {
  return PricesFromJSONTyped(json, false);
}

export function PricesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prices {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    priceList: !exists(json, 'priceList') ? undefined : (json['priceList'] as Array<any>).map(PriceFromJSON),
    futurePriceList: !exists(json, 'futurePriceList')
      ? undefined
      : (json['futurePriceList'] as Array<any>).map(FuturePriceFromJSON),
    errorCode: !exists(json, 'errorCode') ? undefined : json['errorCode'],
    errorMessage: !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
  };
}

export function PricesToJSON(value?: Prices | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    priceList: value.priceList === undefined ? undefined : (value.priceList as Array<any>).map(PriceToJSON),
    futurePriceList:
      value.futurePriceList === undefined ? undefined : (value.futurePriceList as Array<any>).map(FuturePriceToJSON),
    errorCode: value.errorCode,
    errorMessage: value.errorMessage,
  };
}
