import {getAjaxCsrfToken} from '../../api/utils/api';
import {scope} from './scope';

export const oAuthStateTokenSessionStorageKey = 'oAuthStateToken';

/**
 * @see https://oauth2.thephpleague.com/authorization-server/auth-code-grant/
 */
export const generateAuthorizationCode = () => {
  const currentUrl = window.location.origin + window.location.pathname + encodeURI(window.location.search);

  const csrfToken = getAjaxCsrfToken();
  window.sessionStorage.setItem(oAuthStateTokenSessionStorageKey, csrfToken!);
  window.location.href =
    '/api/authorize?response_type=code&client_id=platform&scope=' +
    scope +
    '&state=' +
    csrfToken +
    '&redirect_uri=' +
    currentUrl;
};

export const removeOAuthStateToken = () => {
  window.sessionStorage.removeItem(oAuthStateTokenSessionStorageKey);
};
