import {Texture2D} from '@luma.gl/core';
import {buildMapping} from './font-atlas-utils';
import LRUCache from './lru-cache';
import {CAP_LEFT_SYMBOL, CAP_RIGHT_SYMBOL, SEPARATOR_SYMBOL, VESSEL_SYMBOL, CARGO_SYMBOL} from '../const';

function getDefaultCharacterSet() {
  const charSet = [];
  for (let i = 32; i < 128; i++) {
    charSet.push(String.fromCharCode(i));
  }
  return charSet;
}

const generateCap = (width, height, capSymbolWidth, isMirrored) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const cornerRadius = capSymbolWidth * 2;
  const cornerRadiusHalf = cornerRadius / 2;

  canvas.width = width * 2;
  canvas.height = height;

  ctx.lineJoin = 'round';
  ctx.lineWidth = cornerRadius;
  ctx.strokeRect(cornerRadiusHalf, cornerRadiusHalf, width * 2 - cornerRadius, height - cornerRadius);
  ctx.fillRect(cornerRadiusHalf, cornerRadiusHalf, width * 2 - cornerRadius, height - cornerRadius);

  return ctx.getImageData(isMirrored ? width : 0, 0, width, height);
};

export const DEFAULT_CHAR_SET = getDefaultCharacterSet().concat([
  CAP_LEFT_SYMBOL,
  CAP_RIGHT_SYMBOL,
  SEPARATOR_SYMBOL,
  VESSEL_SYMBOL,
  CARGO_SYMBOL,
]);

export const DEFAULT_FONT_FAMILY = 'Monaco, monospace';
export const DEFAULT_FONT_WEIGHT = 'normal';
export const DEFAULT_FONT_SIZE = 64;
export const DEFAULT_BUFFER = 2;
export const DEFAULT_CUTOFF = 0.25;
export const DEFAULT_RADIUS = 3;

const GL_TEXTURE_WRAP_S = 0x2802;
const GL_TEXTURE_WRAP_T = 0x2803;
const GL_CLAMP_TO_EDGE = 0x812f;
const MAX_CANVAS_WIDTH = 1024;

// only preserve latest three fontAtlas
const CACHE_LIMIT = 3;

/**
 * [key]: {
 *   xOffset, // x position of last character in mapping
 *   yOffset, // y position of last character in mapping
 *   mapping, // x, y coordinate of each character in shared `fontAtlas`
 *   data, // canvas
 *   width. // canvas.width,
 *   height, // canvas.height
 * }
 *
 */
const cache = new LRUCache(CACHE_LIMIT);

const VALID_PROPS = ['fontFamily', 'fontWeight', 'characterSet', 'fontSize', 'buffer', 'cutoff', 'radius'];

/**
 * get all the chars not in cache
 * @param key cache key
 * @param characterSet (Array|Set)
 * @returns {Array} chars not in cache
 */
function getNewChars(key, characterSet) {
  const cachedFontAtlas = cache.get(key);
  if (!cachedFontAtlas) {
    return characterSet;
  }

  const newChars = [];
  const cachedMapping = cachedFontAtlas.mapping;
  let cachedCharSet = Object.keys(cachedMapping);
  cachedCharSet = new Set(cachedCharSet);

  let charSet = characterSet;
  if (charSet instanceof Array) {
    charSet = new Set(charSet);
  }

  charSet.forEach(char => {
    if (!cachedCharSet.has(char)) {
      newChars.push(char);
    }
  });

  return newChars;
}

function setTextStyle(ctx, fontFamily, fontSize, fontWeight) {
  ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  ctx.fillStyle = '#000';
  ctx.textBaseline = 'baseline';
  ctx.textAlign = 'left';
}

export default class BgFontAtlasManager {
  constructor(gl, heightScale = 1.2, capSymbolWidth = 20) {
    this.gl = gl;

    // font settings
    this.props = {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontWeight: DEFAULT_FONT_WEIGHT,
      characterSet: DEFAULT_CHAR_SET,
      fontSize: DEFAULT_FONT_SIZE,
      buffer: DEFAULT_BUFFER,
      cutoff: DEFAULT_CUTOFF,
      radius: DEFAULT_RADIUS,
      heightScale,
      capSymbolWidth,
    };

    // key is used for caching generated fontAtlas
    this._key = null;
    this._texture = new Texture2D(this.gl);
  }

  finalize() {
    this._texture.delete();
  }

  get texture() {
    return this._texture;
  }

  get mapping() {
    const data = cache.get(this._key);
    return data && data.mapping;
  }

  get scale() {
    return this.props.heightScale;
  }

  setProps(props = {}) {
    VALID_PROPS.forEach(prop => {
      if (prop in props) {
        this.props[prop] = props[prop];
      }
    });

    // update cache key
    const oldKey = this._key;
    this._key = this._getKey();

    const charSet = getNewChars(this._key, this.props.characterSet);
    const cachedFontAtlas = cache.get(this._key);

    // if a fontAtlas associated with the new settings is cached and
    // there are no new chars
    if (cachedFontAtlas && charSet.length === 0) {
      // update texture with cached fontAtlas
      if (this._key !== oldKey) {
        this._updateTexture(cachedFontAtlas);
      }
      return;
    }

    // update fontAtlas with new settings
    const fontAtlas = this._generateFontAtlas(this._key, charSet, cachedFontAtlas);
    this._updateTexture(fontAtlas);

    // update cache
    cache.set(this._key, fontAtlas);
  }

  _updateTexture({data: canvas, width, height}) {
    // resize texture
    if (this._texture.width !== width || this._texture.height !== height) {
      this._texture.resize({width, height});
    }

    // update image data
    this._texture.setImageData({
      data: canvas,
      width,
      height,
      parameters: {
        [GL_TEXTURE_WRAP_S]: GL_CLAMP_TO_EDGE,
        [GL_TEXTURE_WRAP_T]: GL_CLAMP_TO_EDGE,
      },
    });

    // this is required step after texture data changed
    this._texture.generateMipmap();
  }

  _generateFontAtlas(key, characterSet, cachedFontAtlas) {
    const {fontFamily, fontWeight, fontSize, buffer, heightScale, capSymbolWidth} = this.props;
    let canvas = cachedFontAtlas && cachedFontAtlas.data;
    if (!canvas) {
      canvas = document.createElement('canvas');
      canvas.width = MAX_CANVAS_WIDTH;
    }
    const ctx = canvas.getContext('2d');

    setTextStyle(ctx, fontFamily, fontSize, fontWeight);

    // 1. build mapping
    const {mapping, canvasHeight, xOffset, yOffset} = buildMapping(
      Object.assign(
        {
          getFontWidth: char =>
            [CAP_LEFT_SYMBOL, CAP_RIGHT_SYMBOL].includes(char) ? capSymbolWidth : ctx.measureText(char).width,
          fontHeight: Math.floor(fontSize * heightScale),
          buffer,
          characterSet,
          maxCanvasWidth: MAX_CANVAS_WIDTH,
        },
        cachedFontAtlas && {
          mapping: cachedFontAtlas.mapping,
          xOffset: cachedFontAtlas.xOffset,
          yOffset: cachedFontAtlas.yOffset,
        }
      )
    );

    // 2. update canvas
    // copy old canvas data to new canvas only when height changed
    if (canvas.height !== canvasHeight) {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      canvas.height = canvasHeight;
      ctx.putImageData(imageData, 0, 0);
    }
    setTextStyle(ctx, fontFamily, fontSize, fontWeight);

    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 3. layout characters (but only CAP_LEFT_SYMBOL and CAP_RIGHT_SYMBOL)
    for (const char of [CAP_LEFT_SYMBOL, CAP_RIGHT_SYMBOL]) {
      const {x, y, width, height} = mapping[char];
      ctx.clearRect(char === CAP_LEFT_SYMBOL ? x - 5 : x - 1, y - 4, width + 4, height + 8);
      ctx.putImageData(generateCap(width, height, capSymbolWidth, char === CAP_RIGHT_SYMBOL), x, y);
    }

    // canvas.style.cssText = "position: absolute; top: 0; left: 0; z-index: 999;";
    // document.body.append(canvas);

    return {
      xOffset,
      yOffset,
      mapping,
      data: canvas,
      width: canvas.width,
      height: canvas.height,
    };
  }

  _getKey() {
    const {gl, fontFamily, fontWeight, fontSize, buffer, radius, cutoff, heightScale, capSymbolWidth} = this.props;
    return `${gl} ${fontFamily} ${fontWeight} ${fontSize} ${buffer} ${radius} ${cutoff} ${heightScale} ${capSymbolWidth}`;
  }
}
