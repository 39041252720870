/* tslint:disable */
/* eslint-disable */
/**
 * BunkerService
 * BunkerService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Location, LocationFromJSON, LocationFromJSONTyped, LocationToJSON} from './';

/**
 * Most fields are self-explanatory. Those that are not, I don't understand either, probably
 * @export
 * @interface Price
 */
export interface Price {
  /**
   *
   * @type {string}
   * @memberof Price
   */
  port?: string;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  portDisplayOrder?: number;
  /**
   *
   * @type {boolean}
   * @memberof Price
   */
  isPostedPice?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Price
   */
  isHolidayInPort?: boolean;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  region?: string;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  lng?: number;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  portCode?: string;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  timeZoneName?: string;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  timeZoneOffset?: string;
  /**
   *
   * @type {Date}
   * @memberof Price
   */
  lastUpdatedOnUTC?: Date;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  priceAtLastUpdate?: number;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  grade?: string;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  brentMovementSinceLastUpdate?: number;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  brentAtLastUpdate?: number;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  currentPrice?: number;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  averageOver7Days?: number;
  /**
   *
   * @type {boolean}
   * @memberof Price
   */
  isCalculated?: boolean;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  totalSources?: number;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  fuelAvailibility?: string;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  portId?: string;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  priceId?: string;
  /**
   *
   * @type {Location}
   * @memberof Price
   */
  location?: Location;
}

export function PriceFromJSON(json: any): Price {
  return PriceFromJSONTyped(json, false);
}

export function PriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Price {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    port: !exists(json, 'port') ? undefined : json['port'],
    portDisplayOrder: !exists(json, 'portDisplayOrder') ? undefined : json['portDisplayOrder'],
    isPostedPice: !exists(json, 'isPostedPice') ? undefined : json['isPostedPice'],
    isHolidayInPort: !exists(json, 'isHolidayInPort') ? undefined : json['isHolidayInPort'],
    country: !exists(json, 'country') ? undefined : json['country'],
    region: !exists(json, 'region') ? undefined : json['region'],
    lat: !exists(json, 'lat') ? undefined : json['lat'],
    lng: !exists(json, 'lng') ? undefined : json['lng'],
    portCode: !exists(json, 'portCode') ? undefined : json['portCode'],
    timeZoneName: !exists(json, 'timeZoneName') ? undefined : json['timeZoneName'],
    timeZoneOffset: !exists(json, 'timeZoneOffset') ? undefined : json['timeZoneOffset'],
    lastUpdatedOnUTC: !exists(json, 'lastUpdatedOnUTC') ? undefined : new Date(json['lastUpdatedOnUTC']),
    priceAtLastUpdate: !exists(json, 'priceAtLastUpdate') ? undefined : json['priceAtLastUpdate'],
    grade: !exists(json, 'grade') ? undefined : json['grade'],
    brentMovementSinceLastUpdate: !exists(json, 'brentMovementSinceLastUpdate')
      ? undefined
      : json['brentMovementSinceLastUpdate'],
    brentAtLastUpdate: !exists(json, 'brentAtLastUpdate') ? undefined : json['brentAtLastUpdate'],
    currentPrice: !exists(json, 'currentPrice') ? undefined : json['currentPrice'],
    averageOver7Days: !exists(json, 'averageOver7Days') ? undefined : json['averageOver7Days'],
    isCalculated: !exists(json, 'isCalculated') ? undefined : json['isCalculated'],
    totalSources: !exists(json, 'totalSources') ? undefined : json['totalSources'],
    fuelAvailibility: !exists(json, 'fuelAvailibility') ? undefined : json['fuelAvailibility'],
    portId: !exists(json, 'portId') ? undefined : json['portId'],
    priceId: !exists(json, 'priceId') ? undefined : json['priceId'],
    location: !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
  };
}

export function PriceToJSON(value?: Price | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    port: value.port,
    portDisplayOrder: value.portDisplayOrder,
    isPostedPice: value.isPostedPice,
    isHolidayInPort: value.isHolidayInPort,
    country: value.country,
    region: value.region,
    lat: value.lat,
    lng: value.lng,
    portCode: value.portCode,
    timeZoneName: value.timeZoneName,
    timeZoneOffset: value.timeZoneOffset,
    lastUpdatedOnUTC: value.lastUpdatedOnUTC === undefined ? undefined : value.lastUpdatedOnUTC.toISOString(),
    priceAtLastUpdate: value.priceAtLastUpdate,
    grade: value.grade,
    brentMovementSinceLastUpdate: value.brentMovementSinceLastUpdate,
    brentAtLastUpdate: value.brentAtLastUpdate,
    currentPrice: value.currentPrice,
    averageOver7Days: value.averageOver7Days,
    isCalculated: value.isCalculated,
    totalSources: value.totalSources,
    fuelAvailibility: value.fuelAvailibility,
    portId: value.portId,
    priceId: value.priceId,
    location: LocationToJSON(value.location),
  };
}
