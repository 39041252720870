import {Helmet} from 'react-helmet-async';
import {FilterCategory, FilterType} from '../../api/symfony/generated';
import {FilterDebugger} from '../../components/FilterProvider/FilterDebugger';
import {
  SpotifindDatabaseFilter,
  SpotifindFilterBranchDefinitions,
} from '../../components/FilterProvider/Filters/Spotifind/SpotifindFilterBranchDefinitions';
import {useFilterProvider} from '../../components/FilterProvider/useFilterProvider';
import {useLoadOrCreateFilterQuery} from '../../queries/filter/useLoadOrCreateFilter';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {SpotifindActions} from '../../redux/Spotifind';
import {SpotifindBody} from './SpotifindBody';
import './style.scss';
import {useUpdateSpotifindFilter} from './useUpdateSpotifindFilter';
import {useEffect} from 'react';

export const SpotifindScreen = () => {
  const filterState = useSelector(state => state.spotifind.filterState);

  const dispatch = useDispatch();

  const filtersQuery = useLoadOrCreateFilterQuery({
    category: FilterCategory.Spotifind,
    type: FilterType.Vessel,
  });

  const onFilterChange = useUpdateSpotifindFilter();
  const isLoading = filtersQuery.isLoading;

  const filterProviderApi = useFilterProvider({
    name: 'Spotifind',
    filterBranchDefinitions: SpotifindFilterBranchDefinitions,
    filterData: {
      id: filterState.filterId,
      filterSettings: filterState.filter,
    },
    filterProviderState: filterState.filterProviderState,
    onFilterProviderStateChange: filterProviderState => {
      dispatch(SpotifindActions.setFilterProvider(filterProviderState));
    },
    onFilterSettingsChange: filterSettings => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onFilterChange(filterSettings as SpotifindDatabaseFilter);
    },
  });

  useEffect(() => {
    if (filtersQuery.isSuccess && filtersQuery.data) {
      dispatch(SpotifindActions.loadFiltersSuccess([filtersQuery.data]));
    }
  }, [filtersQuery.isSuccess, filtersQuery.data, dispatch]);

  return (
    <div className={'spotifind-screen'}>
      <Helmet title={'Spotifind'} />
      <SpotifindBody
        filterState={filterState}
        isLoading={isLoading}
        filtersLoaded={filtersQuery.isSuccess}
        filterProviderApi={filterProviderApi}
      />
      <FilterDebugger currentFilters={filterState.filter} filterProviderState={filterState.filterProviderState} />
    </div>
  );
};
