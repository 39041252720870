import {FC} from 'react';
import {VoyagePoint} from '../VoyageInformation/VoyageTypes';
import {PortPickerWithFlag} from '../../../PortPicker/PortPickerWithFlag';
import {transformVoyagePointToPortParam} from '../VoyageInformation/utils/transformVoyagePointToPortParam';
import {transformPortToVoyagePointPartial} from '../VoyageInformation/utils/transformPortToVoyagePointPartial';

export const PortPickerWithFlagForVoyageLeg: FC<{
  value?: VoyagePoint;
  onChange?: (value: VoyagePoint | undefined) => void;
}> = ({value, onChange}) => {
  return (
    <PortPickerWithFlag
      port={value ? transformVoyagePointToPortParam(value) : undefined}
      onChange={port => {
        if (!port) {
          onChange?.(undefined);
          return;
        }
        onChange?.({...value, ...transformPortToVoyagePointPartial(port)} as VoyagePoint);
      }}
      size={'middle'}
    />
  );
};
