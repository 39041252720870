import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {companyApi} from '../../../../api/symfony/symfonyApi';
import {CompanyStartPromotionResponse} from '../../../../api/symfony/generated';

export const useStartTrialMutation = (options?: UseMutationOptions<CompanyStartPromotionResponse>) =>
  useMutation({
    mutationFn: async () => {
      const response = await companyApi.companyStartPromotion();
      return response;
    },
    ...options,
  });
