import {TourDefinition, TourId, getFinalStep} from '../../components/OnboardingTour/TourDefinition';

export const DistanceCalculationTourDefinition: TourDefinition = {
  id: TourId.DistanceCalc,
  steps: [
    {
      title: 'Distance Calculation',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/10.%20Tools%20Onboarding.mp4',
      },
      pdfDownloadUrl:
        "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/10%20Tools/Distance%20Calculation/Let's%20meet%20the%20distance%20calculation.pdf",
      placement: 'center',
    },
    {
      title: 'How to do a basic Distance calculation',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/10%20How%20to%20Distance%20Calc/10.1%20How%20to%20do%20a%20basic%20distance%20calculation.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/10%20Tools/Distance%20Calculation/Work%20with%20the%20distance%20calculation.pdf',
      placement: 'center',
    },
    {
      title: 'How to do a multi leg Distance Calculation',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/10%20How%20to%20Distance%20Calc/10.2%20How%20to%20do%20a%20multi%20leg%20distance%20calculation%20&%20reorder.mp4',
      },
      placement: 'center',
    },
    {
      title: 'How to use route options',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/10%20How%20to%20Distance%20Calc/10.3%20How%20to%20use%20route%20options.mp4',
      },
      placement: 'center',
    },
    getFinalStep('Distance Calculation'),
  ],
};
