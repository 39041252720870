/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Role, RoleFromJSON, RoleFromJSONTyped, RoleToJSON} from './';

/**
 *
 * @export
 * @interface Member
 */
export interface Member {
  /**
   *
   * @type {number}
   * @memberof Member
   */
  userID: number;
  /**
   *
   * @type {string}
   * @memberof Member
   */
  companyPosition?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Member
   */
  companyVisibility?: boolean;
  /**
   *
   * @type {Array<Role>}
   * @memberof Member
   */
  roles?: Array<Role>;
}

export function MemberFromJSON(json: any): Member {
  return MemberFromJSONTyped(json, false);
}

export function MemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Member {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userID: json['userID'],
    companyPosition: !exists(json, 'companyPosition') ? undefined : json['companyPosition'],
    companyVisibility: !exists(json, 'companyVisibility') ? undefined : json['companyVisibility'],
    roles: !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(RoleFromJSON),
  };
}

export function MemberToJSON(value?: Member | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userID: value.userID,
    companyPosition: value.companyPosition,
    companyVisibility: value.companyVisibility,
    roles: value.roles === undefined ? undefined : (value.roles as Array<any>).map(RoleToJSON),
  };
}
