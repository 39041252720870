import {Cell, Row, Table, TableState, flexRender} from '@tanstack/react-table';
import classNames from 'classnames';
import {CustomColumnDef} from './DataGrid';
import {ReactNode} from 'react';

export const DataGridBody = <T,>({
  table,
  renderSubComponent,
  getTrProps,
  getTdProps,
}: {
  table: Table<T>;
  renderSubComponent?: (row: Row<T>) => ReactNode;
  getTrProps?: (state: TableState, rowInfo: Row<T>) => Partial<CustomColumnDef<T>>;
  getTdProps?: (state: TableState, rowInfo: Row<T>, cell: Cell<T, unknown>) => Partial<CustomColumnDef<T>>;
}) => {
  return (
    <div className="rt-tbody">
      {table.getRowModel().rows.map(row => {
        const rowProps = getTrProps?.(table.getState(), row);
        return (
          <div key={row.id} className="rt-tr-group" role="rowgroup">
            <div
              {...rowProps}
              className={classNames('rt-tr', rowProps?.className)}
              data-cy={`DataGridRow-${row.id}`}
              role="row">
              {row.getVisibleCells().map(cell => {
                const cellProps = getTdProps?.(table.getState(), row, cell);
                const columnDef = cell.column.columnDef as CustomColumnDef<T>;

                return (
                  <div
                    key={cell.id}
                    {...cellProps}
                    className={classNames('rt-td', columnDef.className, cellProps?.className)}
                    data-cy={`DataGridCell-${cell.id}`}
                    style={{
                      flex: columnDef.minWidth,
                      width: columnDef.width || columnDef.maxWidth || 'auto',
                      maxWidth: columnDef.maxWidth,
                      ...cellProps?.style,
                    }}
                    role="gridcell">
                    {flexRender(columnDef.cell, cell.getContext())}
                  </div>
                );
              })}
            </div>
            {row.getIsExpanded() && (
              <div>
                <div>{renderSubComponent?.(row)}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
