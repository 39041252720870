import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
`;

export const BoxHalf = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 50%;

  &:first-of-type:not(:last-of-type) {
    border-right: var(--border-width) solid var(--border-color-light);
  }
`;
