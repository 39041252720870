import {VesselDetailsVessel} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

export type VesselDetailTab =
  | 'generalTechnicalTab'
  | 'vesselHistoryTab'
  | 'voyageTimelineTab'
  | 'vesselStatsTab'
  | 'sharingHistoryTab'
  | 'sisterVesselsTab'
  | 'vesselFixtureTab'
  | 'vesselSaleFixtureTab';

export type TabsVisibleMap = Record<VesselDetailTab, boolean>;

export const getWhichTabsShouldBeVisible = ({vessel}: {vessel: VesselDetailsVessel}): TabsVisibleMap => {
  return {
    generalTechnicalTab: true,
    sisterVesselsTab: !!vessel.imo,
    vesselHistoryTab: true,
    voyageTimelineTab: !!vessel.aisDetails,
    vesselStatsTab: !!vessel.aisDetails,
    sharingHistoryTab: 'relatedOffers' in vessel && vessel.relatedOffers.length > 0,
    vesselFixtureTab: vessel.fixtureCount > 0,
    vesselSaleFixtureTab: vessel.fixtureCount > 0,
  };
};
