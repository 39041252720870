import React, {FC} from 'react';
import {CompanyDataOverviewData} from './Data/CompanyDataOverviewData';
import LoadingComponent from '../../../../components/DataGrid/LoadingComponent';
import styled from 'styled-components';
import {useCompanyDataQuery} from './useCompanyDataQuery';
import {Company} from '../../../../redux/ApiService/CompanyService/Company';
import {Tabs, TabsProps} from 'antd';
import {SettingsCard} from '../../../../components/SettingsCard/SettingsCard';
import {SettingsCardHeader} from '../../../../components/SettingsCard/SettingsCardHeader';
import {SettingsCardBody} from '../../../../components/SettingsCard/SettingsCardBody';

export const CompanySettingsActivity: FC<{company: Company}> = ({company}) => {
  const {data, isSuccess} = useCompanyDataQuery(company.id);

  if (!isSuccess) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }

  const items: TabsProps['items'] = [
    {
      label: 'Market',
      key: 'market',
      children: <CompanyDataOverviewData key={'market'} data={data.market} />,
    },
    {
      label: 'Portfolio',
      key: 'portfolio',
      children: <CompanyDataOverviewData key={'portfolio'} data={data.portfolio} isPortfolio />,
    },
    {
      label: 'Projects',
      key: 'projects',
      children: <CompanyDataOverviewData key={'projects'} data={data.projects} />,
    },
  ];

  return (
    <StyledCompanyDataOverview>
      <SettingsCard>
        <SettingsCardHeader title={'Company activity'} />
        <SettingsCardBody>
          <SettingsSection>
            <Spacer />
            <Tabs items={items}></Tabs>
          </SettingsSection>
        </SettingsCardBody>
      </SettingsCard>
    </StyledCompanyDataOverview>
  );
};

const StyledCompanyDataOverview = styled.div`
  text-align: center;
`;

const Spacer = styled.div`
  margin-top: 24px;
`;

const SettingsSection = styled.div`
  border-top: 1px solid var(--color-gray-3);
  padding-bottom: 40px;
`;
