import {Pie, PieChart, Tooltip, ResponsiveContainer} from 'recharts';
import styled from 'styled-components';

const renderCustomLabel = ({name, value}: $TSFixMe) => `${name.split(' ')[1]}: ${value}`;

const CompanyPieChart = ({data, label}: $TSFixMe) => (
  <StyledDataPie>
    <StyledHeadline>{label}</StyledHeadline>
    {data[0].value !== 0 || data[1].value !== 0 ? (
      <ResponsiveContainer height={300}>
        <PieChart>
          <Pie
            cx={'50%'}
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cy={150}
            outerRadius={100}
            labelLine={false}
            label={renderCustomLabel}
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    ) : (
      <div>No data found</div>
    )}
  </StyledDataPie>
);

export default CompanyPieChart;

const StyledDataPie = styled.div`
  width: 100%;
`;

const StyledHeadline = styled.div`
  font-size: var(--font-size-lg);
  font-weight: 600;
  line-height: 32px;
`;
