import {FC} from 'react';
import {Ticker} from '../Ticker';
import {VesselOfferStatic} from './VesselOfferStatic';
import {CargoOfferStatic} from './CargoOfferStatic';
import {OfferBucket} from '../../../../api/symfony/generated';
import {AvgCircularsPerDay} from './AvgCircularsPerDay';
import {NumberDifferentSenders} from './NumberDifferentSenders';

export const TopTicker: FC<{
  buckets: OfferBucket[];
}> = ({buckets}) => {
  return (
    <Ticker span={10}>
      <VesselOfferStatic buckets={buckets} />
      <CargoOfferStatic buckets={buckets} />
      <AvgCircularsPerDay buckets={buckets} />
      <NumberDifferentSenders buckets={buckets} />
    </Ticker>
  );
};
