/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UserReference
 */
export interface UserReference {
  /**
   *
   * @type {string}
   * @memberof UserReference
   */
  avatar?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserReference
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserReference
   */
  fullName: string;
}

export function UserReferenceFromJSON(json: any): UserReference {
  return UserReferenceFromJSONTyped(json, false);
}

export function UserReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserReference {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    avatar: !exists(json, 'avatar') ? undefined : json['avatar'],
    id: json['id'],
    fullName: json['fullName'],
  };
}

export function UserReferenceToJSON(value?: UserReference | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    avatar: value.avatar,
    id: value.id,
    fullName: value.fullName,
  };
}
