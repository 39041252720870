import {VesselDetailHeader} from './VesselDetailHeader';
import VesselDetailBody from './VesselDetailBody';
import {MyFleetVesselDetailsScreenActionMenu} from './components/MyFleetVesselDetailsScreenActionMenu';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import {
  MyFleetVessel,
  VesselDatabaseVessel,
} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {VesselServiceRequestInformation} from '../../redux/ApiService/vesselService';

type Props = {
  vessel: MyFleetVessel | VesselDatabaseVessel;
  statsVessel?: $TSFixMe;
  vesselStatisticRequestInformation: VesselServiceRequestInformation<unknown>;
  vesselAttributes?: $TSFixMe[];
  breadcrumbs: BreadcrumbItem[];
  onAddToProjectClick: () => void;
  onManageNextOpenLocationClick: () => void;
  onCloneClick: () => void;
  onFixtureExportClick: () => void;
  onCreateFixtureClick: () => void;
  onDeleteClick: () => void;
  onVesselChanged: () => void;
};

const MyFleetVesselDetails = ({
  vessel,
  statsVessel,
  vesselStatisticRequestInformation,
  breadcrumbs,
  onAddToProjectClick,
  onManageNextOpenLocationClick,
  onCloneClick,
  onFixtureExportClick,
  onCreateFixtureClick,
  onDeleteClick,
  onVesselChanged,
}: Props) => {
  return (
    <>
      <ScreenHeader
        helmetTitle={vessel.name ?? undefined}
        breadcrumbs={breadcrumbs}
        actions={
          vessel.target !== 'database' && (
            <MyFleetVesselDetailsScreenActionMenu
              onDeleteClick={onDeleteClick}
              vessel={vessel}
              onCloneClick={onCloneClick}
              onCreateFixtureClick={onCreateFixtureClick}
              onFixtureExportClick={onFixtureExportClick}
              onProjectClick={onAddToProjectClick}
              onManageNextOpenLocationClick={onManageNextOpenLocationClick}
            />
          )
        }
      />
      <div className="vessel-content__header">
        <VesselDetailHeader vessel={vessel} />
      </div>
      <VesselDetailBody
        vessel={vessel}
        onVesselChanged={onVesselChanged}
        statsVessel={statsVessel}
        vesselStatisticRequestInformation={vesselStatisticRequestInformation}
      />
    </>
  );
};

export default MyFleetVesselDetails;
