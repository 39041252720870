/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface WorkspaceMember
 */
export interface WorkspaceMember {
  /**
   *
   * @type {number}
   * @memberof WorkspaceMember
   */
  userID: number;
}

export function WorkspaceMemberFromJSON(json: any): WorkspaceMember {
  return WorkspaceMemberFromJSONTyped(json, false);
}

export function WorkspaceMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMember {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userID: json['userID'],
  };
}

export function WorkspaceMemberToJSON(value?: WorkspaceMember | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userID: value.userID,
  };
}
