import MarketActions from '../../../redux/ApiService/MarketService';
import {useDispatch} from '../../../redux/react-redux';
import {useQuery} from '@tanstack/react-query';

export interface RawMessage {
  /**
   * Date when the mail was sent.
   */
  date: Date;
  /**
   * Mail address of the sender.
   */
  sender: string;
  /**
   * Recipients of the mail. The list includes 'To' and 'Cc' recipients.
   */
  recipients?: string[];
  /**
   * The subject of the mail. Never null, but may be empty.
   */
  subject: string;
  /**
   * The text that has been extracted from the mail.
   */
  text: string | null;
}

interface Offer {
  rawMessage: RawMessage;
}

export const useOfferQuery = (id: number, type: 'vessel' | 'cargo') => {
  const dispatch = useDispatch();
  const getCargoOffer = (id: number): Promise<Offer> => dispatch(MarketActions.getCargoOffer(id));
  const getVesselOffer = (id: number): Promise<Offer> => dispatch(MarketActions.getVesselOffer(id));

  const getOffer = type === 'vessel' ? getVesselOffer : getCargoOffer;

  return useQuery({
    queryKey: ['offer', type, id],
    queryFn: async () => await getOffer(id),
  });
};
