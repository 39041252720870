import {Button, Dropdown, Menu} from 'antd';
import {FC, useState} from 'react';
import {GridClipboardActions} from '../../redux/GridClipboard';
import {ModalActions} from '../../redux/Modal';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {MultiPortfolioAddModal} from '../../components/Modal/Multiselect/Market/MultiselectAddToPortfolio';

export const VesselDatabaseClipboardButton: FC<{
  onReloadGrid: () => void;
}> = props => {
  const [visible, setVisible] = useState(false);
  // TODO: Build a separate clipboard
  const clipboardItems = useSelector(state => state.gridClipboard.portfolio.vessels);

  const count = clipboardItems.length;

  if (count === 0) {
    return null;
  }

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={visible => {
        setVisible(visible);
      }}
      open={visible}
      menu={{
        items: [
          {
            key: '1',
            label: (
              <DropdownMenu
                items={clipboardItems}
                onReloadGrid={props.onReloadGrid}
                onClick={() => {
                  setVisible(false);
                }}
              />
            ),
          },
        ],
      }}>
      <Button className="uppercase" type={'primary'}>
        {count} {count === 1 ? 'vessel' : 'vessels'} selected
      </Button>
    </Dropdown>
  );
};

type DropdownMenuProps = {
  items: unknown[];
  onClick: () => void;
  onReloadGrid: () => void;
};

const DropdownMenu = (props: DropdownMenuProps) => {
  const dispatch = useDispatch();

  return (
    <>
      <Menu
        selectedKeys={[]}
        onClick={async menuEvent => {
          switch (menuEvent.key) {
            case 'addToPortfolios':
              dispatch(
                ModalActions.show(
                  <MultiPortfolioAddModal type="vessel" data={props.items} reload={props.onReloadGrid} />
                )
              );
              break;

            case 'unselect':
              dispatch(GridClipboardActions.removeAllItemsFromClipboard('vessels', 'market'));
          }

          props.onClick();
        }}>
        <Menu.Item key={'addToPortfolios'}>Add to My Fleet</Menu.Item>
        <Menu.Item key={'unselect'}>Unselect all vessels</Menu.Item>
      </Menu>
    </>
  );
};
