import {Card} from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  border-radius: 0 var(--border-radius-card) var(--border-radius-card) var(--border-radius-card);
  overflow: hidden;

  .ant-card-body {
    height: 100%;
    padding: 0;
  }
`;
