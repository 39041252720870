import React, {Ref, FC} from 'react';
import styled, {css, keyframes} from 'styled-components';
import {Typography} from 'antd';

const {Title} = Typography;

export const SupportAgentsBar: FC<{
  isSmall?: boolean;
  animate?: boolean;
  scrollRef?: Ref<HTMLDivElement>;
}> = ({isSmall, animate, scrollRef}) => {
  return (
    <SupportAgentsContainer ref={scrollRef}>
      <HorizontalLine $isSmall={isSmall} />
      <SupportCta animate={animate} />
    </SupportAgentsContainer>
  );
};

export const SupportCta = ({animate}: {animate?: boolean}) => {
  return (
    <SupportCopy>
      <AnimationContainer $animate={animate}>
        <Title level={5}>Do you require any assistance?</Title>
        <p>Contact our competent support professionals for assistance setting up your account and features.</p>
      </AnimationContainer>
      <Title level={5}>
        <a href="mailto:support@seabo.com">support@seabo.com</a>
      </Title>
    </SupportCopy>
  );
};

const highlightAnimation = keyframes`
  50% {
    transform: scale(1.1);
  }
`;

const SupportAgentsContainer = styled.div`
  margin-bottom: 40px;
`;

const SupportCopy = styled.div`
  text-align: center;
`;

const AnimationContainer = styled.div<{$animate?: boolean}>`
  ${({$animate}) =>
    $animate
      ? css`
          animation: ${highlightAnimation} 0.5s;
        `
      : ''}
`;

const HorizontalLine = styled.hr<{$isSmall?: boolean}>`
  border: var(--border-width) solid transparent;
  border-top-color: var(--border-color);
  margin: ${({$isSmall}) => ($isSmall ? '20px 0' : '60px 0 40px')};
`;
