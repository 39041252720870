import {FC} from 'react';
import Title from '../../../../atoms/Title';
import {aisVesselMooredGrid} from '../../../../components/GridLayout';
import {useDispatch, useSelector} from '../../../../redux/react-redux';
import {PortsActions} from '../../../../redux/Ports';
import produce from 'immer';
import Lighthouse from '../../../../atoms/EmptyState/LightHouse';
import {useVesselsInPortQuery} from '../useVesselsInPortQuery';
import {useLocalStorage} from '../../../../utils/useLocalStorage';
import {DataGrid} from '../../../../components/DataGrid/DataGrid';

export const VesselsInPortSection: FC<{portCode: string}> = ({portCode}) => {
  const mooredVesselsGrid = useSelector(state => state.ports.mooredVesselsGrid);
  const [, setLocalStoragePageSize] = useLocalStorage('grid_page_size-port_details_moored_vessels', 10);

  const dispatch = useDispatch();

  const vesselsInPortQuery = useVesselsInPortQuery({portCode: portCode});

  return (
    <div className="port-screen__grid-moored">
      <Title medium style={{marginTop: 20, marginBottom: 10}}>
        Vessels in Port
      </Title>
      <DataGrid
        className={'datagrid-moored-vessels'}
        columns={aisVesselMooredGrid}
        data={vesselsInPortQuery.data}
        zeroBasedPageIndex={false}
        responsive
        manualPagination={false}
        manualSorting={false}
        totalCount={vesselsInPortQuery.data?.length}
        loading={vesselsInPortQuery.isLoading}
        TbodyComponent={vesselsInPortQuery.data?.length ? null : <Lighthouse headline={'No vessels in port'} />}
        page={mooredVesselsGrid.page}
        pageSize={mooredVesselsGrid.pageSize}
        sorted={mooredVesselsGrid.sorted}
        onPageChange={page => {
          dispatch(
            PortsActions.setMooredGridState(
              produce(mooredVesselsGrid, draftGridState => {
                draftGridState.page = page;
              })
            )
          );
        }}
        onPageSizeChange={pageSize => {
          setLocalStoragePageSize(pageSize);
          dispatch(
            PortsActions.setMooredGridState(
              produce(mooredVesselsGrid, draftGridState => {
                draftGridState.page = 1;
                draftGridState.pageSize = pageSize;
              })
            )
          );
        }}
        onSortedChange={sorted => {
          dispatch(
            PortsActions.setMooredGridState(
              produce(mooredVesselsGrid, draftGridState => {
                draftGridState.page = 1;
                draftGridState.sorted = [sorted];
              })
            )
          );
        }}
      />
    </div>
  );
};
