import Flag from '../../atoms/Flags';
import Icon from '../../atoms/Icon';
import DetailsColumn from '../../atoms/DetailsSection/DetailsColumn';
import {Attribute} from '../../atoms/Attribute/Attribute';
import AttributeIcon from '../../atoms/Attribute/AttributeIcon';
import {numberFormat, numberUnitFormat} from '../../utils/formatter';
import {components} from 'react-select';
import {LocationAlias} from './LocationAlias';
import {PortLocation} from './PortLocation';

export const CountryLocation = ({location, style = {}, renderAlias = false}: $TSFixMe) => (
  <div
    data-testid={'countryLocation'}
    className={'location-output__location'}
    style={style}
    data-cy-location={location.name}>
    <Flag countryCode={location.code} border width={13} height={10} />
    <span className={'location-output__title'}>{location.name}</span>
    <span className={'location-output__sub-title'}>({location.code})</span>
    {renderAlias && <LocationAlias location={location} />}
  </div>
);

export const TradingAreaLocation = ({location, style, renderAlias = false}: $TSFixMe) => (
  <div className={'location-output__location'} style={style} data-cy-location={location.name}>
    <Icon type={'world'} size={'small'} />
    <span className={'location-output__title'}>{location.name}</span>
    <span className={'location-output__sub-title'}>({location.code})</span>
    {renderAlias && <LocationAlias location={location} />}
  </div>
);

export const UnknownLocation = ({name = 'Unknown', style}: $TSFixMe) => (
  <div className={'location-output__location'} style={style} data-cy-location={name}>
    <Icon type={'help'} size={'small'} />
    <span className={'location-output__title'}>{name}</span>
  </div>
);

export const LocationCollection = ({location, style}: $TSFixMe) => (
  <div className={'location-output__location'} style={style} data-cy-location={location.name}>
    <Icon type={'world'} size={'small'} />
    <span className={'location-output__title'}>{location.name}</span>
  </div>
);

export const renderLocation = (type: $TSFixMe, location: $TSFixMe, renderAlias = false, onClick: $TSFixMe) => {
  switch (type) {
    case 'port':
      return <PortLocation location={location} renderAlias={renderAlias} onClick={onClick} />;
    case 'country':
      return <CountryLocation location={location} renderAlias={renderAlias} />;
    case 'tradingarea':
      return <TradingAreaLocation location={location} renderAlias={renderAlias} />;
    case 'collection':
      return <LocationCollection location={location} />;
    case 'unknown':
    default:
      return <UnknownLocation name={location.name} />;
  }
};

export const DropdownMultiValueLocation = (props: $TSFixMe) => (
  <components.MultiValue {...props}>{getLocationSelectOption(props.data, false)}</components.MultiValue>
);

export const DropdownSingleValueLocation = (props: $TSFixMe) => (
  <components.SingleValue {...props}>{getLocationSelectOption(props.data, false)}</components.SingleValue>
);

export const getLocationSelectOption = (option: $TSFixMe, renderAlias = false) =>
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  renderLocation(
    option.type,
    {
      ...option,
      country: option.countryObject ? option.countryObject.code : undefined,
    },
    renderAlias
  );

type LocationOutputRowProps = {
  vertical?: boolean;
  type?: string;
  location?: $TSFixMe;
  stationType?: string;
};

const LocationOutputRow = ({vertical, type, stationType, location}: LocationOutputRowProps) => (
  <div className={'location-output__row'}>
    {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2. */}
    {renderLocation(type, location)}
    {(stationType === 'loading' || stationType === 'discharge') && (
      <div className="location-output__information">
        <DetailsColumn size={3}>
          <Attribute
            vertical={vertical}
            tab={'m'}
            label={stationType === 'loading' ? 'Load rate' : 'Disch rate'}
            value={numberUnitFormat(location.additionalInfo.rate, location.additionalInfo.unit)}
          />
        </DetailsColumn>
        <DetailsColumn size={5}>
          <Attribute
            vertical={vertical}
            tab={'m'}
            label={'Terms'}
            value={location.additionalInfo.terms ? location.additionalInfo.terms.toUpperCase() : 'N/A'}
          />
        </DetailsColumn>
        <DetailsColumn size={2}>
          <Attribute
            vertical={vertical}
            label={'tt'}
            tab={'m'}
            value={numberFormat(location.additionalInfo.timeToTurn)}
          />
        </DetailsColumn>
        <DetailsColumn size={2}>
          <AttributeIcon check={location.additionalInfo.cqd} label={'CQD'} />
        </DetailsColumn>
      </div>
    )}
  </div>
);

export default LocationOutputRow;
