import {FormInstance} from 'antd';
import {FormValues} from '../../../FormValues';
import {ContractType} from '../../../ContractTypes';
import {FixtureDuration} from '../../../utils';
import {Option} from '../../../UpdateFixtureRequestSchema/UpdateFixtureRequestSchema';
import cloneDeep from 'lodash/cloneDeep';
import {assertUnreachable} from '../../../../../../../utils/assert';

export const changesDefaults = (
  value: ContractType | null,
  currentOptions: FormValues['options'],
  form: FormInstance<FormValues>
) => {
  switch (value) {
    case 'pc':
    // Fallthrough
    case 'bc':
    // Fallthrough
    case 'tct':
    // Fallthrough
    case 'vc': {
      const settings = getDurationUnitAndRateTermForContractType(value);
      currentOptions[0] = setPeriodUnitAndRateTerms(currentOptions[0], settings.periodUnit, settings.rateTerms);
      form.setFieldValue('options', currentOptions);
      break;
    }

    default:
      break;
  }
};

const setPeriodUnitAndRateTerms = (option: Option, periodUnit: FixtureDuration, rateTerms: Option['rateTerms']) => {
  return cloneDeep({...option, periodUnit, rateTerms});
};

export const getDurationUnitAndRateTermForContractType = (
  contractType: Exclude<ContractType, 'sale'>
): {
  periodUnit: FixtureDuration;
  rateTerms: Option['rateTerms'];
} => {
  switch (contractType) {
    case 'vc': {
      return {
        periodUnit: 'days',
        rateTerms: 'mt',
      };
    }
    case 'tct': {
      return {
        periodUnit: 'days',
        rateTerms: 'day',
      };
    }
    case 'pc': {
      return {
        periodUnit: 'months',
        rateTerms: 'day',
      };
    }
    case 'bc': {
      return {
        periodUnit: 'months',
        rateTerms: 'day',
      };
    }

    default:
      assertUnreachable(contractType);
  }
};
