import {FC, useState} from 'react';
import BaseModal from '../../../atoms/BaseModal/index';
import {Button} from '../../../atoms/Button/Button';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {ProfileInput} from '../../ProfileInput';
import {useDispatch} from '../../../redux/react-redux';
import {Company} from '../../../redux/ApiService/CompanyService/Company';
import './style.scss';

type Props = {
  company: Company;
  onFinished?: (companyData: Company) => void;
  close?: () => void;
};

export const VerifyEditCompanySettings: FC<Props> = ({company, onFinished, close}) => {
  const dispatch = useDispatch();

  const [companyData, setCompanyData] = useState<Company>(company);
  const [error, setError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const updateCompany = () => {
    const {id, ...updateBody} = {...companyData, isVerified: true};
    dispatch(CompanyActions.updateCompany(id, updateBody));
  };

  const onChange = (changeState: string, changedValue: string) => {
    if (changeState === 'business' || changeState === 'companyMailDomain') {
      setCompanyData(prev => ({
        ...prev,
        [changeState]: changedValue,
      }));
    } else if (changeState.includes('address')) {
      setCompanyData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [changeState.replace('address.', '')]: changedValue,
        },
      }));
    } else if (changeState.includes('contact')) {
      setCompanyData(prev => ({
        ...prev,
        contact: {
          ...prev.contact,
          [changeState.replace('contact.', '')]: changedValue,
        },
      }));
    }
  };
  const saveCompany = async () => {
    setDisableButton(true);
    try {
      await updateCompany();
      onFinished?.(companyData);
      close?.();
    } catch {
      setError(true);
    }
  };

  const {name, business, contact, address} = companyData;
  return (
    <>
      <div className={'verify-company-modal'}>
        <div className={'verify-company-modal__section'}>
          <div className={'verify-company-modal__category'}>Main information</div>
          <div className={'verify-company-modal__content'}>
            <ProfileInput label={'Company name'} selectedName={'name'} selectedValue={name} readOnly />
            <ProfileInput
              label={'Business description'}
              selectedName={'business'}
              selectedValue={business}
              onChange={onChange}
            />
          </div>
        </div>

        <div className={'verify-company-modal__section'}>
          <div className={'verify-company-modal__category'}>Postal address</div>
          <div className={'verify-company-modal__content'}>
            <ProfileInput
              label={'Address name'}
              selectedName={'address.name'}
              selectedValue={address.name}
              onChange={onChange}
            />
            <div className={'row'}>
              <div className={'scol-8'}>
                <ProfileInput
                  label={'Street'}
                  selectedName={'address.street'}
                  selectedValue={address.street}
                  onChange={onChange}
                />
              </div>
              <div className={'scol-4'}>
                <ProfileInput
                  label={'No'}
                  selectedName={'address.streetNumber'}
                  selectedValue={address.streetNumber}
                  onChange={onChange}
                />
              </div>
            </div>
            <ProfileInput
              label={'Street 2'}
              selectedName={'address.street2'}
              selectedValue={address.street2}
              onChange={onChange}
            />
            <div className={'row'}>
              <div className={'scol-4'}>
                <ProfileInput
                  label={'Postal code'}
                  selectedName={'address.zip'}
                  selectedValue={address.zip}
                  onChange={onChange}
                />
              </div>
              <div className={'scol-8'}>
                <ProfileInput
                  label={'City'}
                  selectedName={'address.city'}
                  selectedValue={address.city}
                  onChange={onChange}
                />
              </div>
            </div>
            <ProfileInput
              label={'Country'}
              selectedName={'address.country'}
              selectedValue={address.country}
              onChange={onChange}
            />
          </div>
        </div>

        <div className={'verify-company-modal__section'}>
          <div className={'verify-company-modal__category'}>Contact</div>
          <div className={'verify-company-modal__content'}>
            <ProfileInput
              label={'Website'}
              selectedName={'contact.website'}
              selectedValue={contact.website}
              onChange={onChange}
            />
            <ProfileInput
              label={'General company email'}
              selectedName={'contact.email'}
              selectedValue={contact.email}
              onChange={onChange}
            />
            <ProfileInput
              label={'Phone'}
              selectedName={'contact.phone'}
              selectedValue={contact.phone}
              onChange={onChange}
            />
            <ProfileInput
              label={'Fax'}
              selectedName={'contact.fax'}
              selectedValue={contact.fax}
              labelContentWrapperStyle={{marginBottom: 0}}
              onChange={onChange}
            />
          </div>
        </div>

        {error && (
          <div className={'verify-company-modal__error'}>
            Something went wrong, please reload the site or <a href="https://www.seabo.com/contact">contact us</a>
          </div>
        )}
        <div className={'verify-company-modal-button-group'}>
          <Button
            disabled={disableButton}
            label={'Save'}
            bold
            primary
            upper
            id={'verify__edit__save'}
            style={{margin: '0 auto'}}
            onClick={saveCompany}
          />
        </div>
      </div>
    </>
  );
};

export const VerifyEditCompanyModal = (props: $TSFixMe) => (
  <BaseModal title="Edit company details" subTitle="Please check all relevant information">
    {modalProps => <VerifyEditCompanySettings {...props} {...modalProps} />}
  </BaseModal>
);
