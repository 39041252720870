import {AISRecord} from '../queries/aisRecordsQuery/AISRecord';
import {VoyageAnimationPortVisit} from './VoyageAnimationPortVisit';
import {Dayjs} from 'dayjs';
import {LonLatCoordinates} from '../../../../../utils/Coordinates';

export interface AnimationSeaSegment {
  id: string;
  type: 'sea-segment';
  from: AISRecord;
  to: AISRecord;
  heading: number;
  insidePort?: boolean;
  timestamp: Dayjs;
  distanceInKM: number;
  durationInMinutes: number;
  standingStill: boolean;
  speedInKnots: number;
}

export type AnimationSeaSegmentWithoutRedundantFields = Omit<
  AnimationSeaSegment,
  'distanceInKM' | 'durationInMinutes' | 'speedInKnots' | 'standingStill' | 'timestamp' | 'heading'
>;

export interface AnimationPortEntry {
  id: string;
  type: 'port-entry';
  portVisit: VoyageAnimationPortVisit;
  vesselCoordinates?: LonLatCoordinates;
  timestamp: Dayjs;
}

export interface AnimationPortExit {
  id: string;
  type: 'port-exit';
  portVisit: VoyageAnimationPortVisit;
  nextPortVisit?: VoyageAnimationPortVisit;
  timestamp: Dayjs | null;
}

export type AnimationEvent = AnimationSeaSegment | AnimationPortEntry | AnimationPortExit;

export const isSeaSegment = (animationEvent: AnimationEvent): animationEvent is AnimationSeaSegment =>
  animationEvent.type === 'sea-segment';

export const isPortEntry = (animationEvent: AnimationEvent): animationEvent is AnimationPortEntry =>
  animationEvent.type === 'port-entry';
