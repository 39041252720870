import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError} from '../../../../api/utils/ApiError';
import {annotationApi} from '../../../../api/annotations/annotationsApi';
import {UpdateAnnotationByIdRequest} from '../../../../api/annotations/generated';

export const useUpdateAnnotationMutation = (
  options: UseMutationOptions<void, ApiError, UpdateAnnotationByIdRequest>
) => {
  const updateAnnotation = async (requestData: UpdateAnnotationByIdRequest) => {
    return annotationApi.updateAnnotationById({
      annotationId: requestData.annotationId,
      newAnnotationRequest: requestData.newAnnotationRequest,
    });
  };
  return useMutation({
    mutationFn: updateAnnotation,
    ...options,
  });
};
