import {Button, Col, Modal, Progress, Row} from 'antd';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import styled from 'styled-components';
import {fetchJSON} from '../../api/utils/fetchJSON';
import Lottie from 'lottie-react';

export type LoadingModalProps = {
  isLoading: boolean;
  progress?: number;
  headline?: string;
  lottieFileUrl?: string;
  onCancel?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LottieAnimationData = any;

const defaultLottieFileUrl = '/static/lottieFiles/loadingVesselLottieFile.json';

export const LoadingModal = ({
  isLoading,
  progress,
  headline = 'This live-report is calculating and will be available soon.',
  lottieFileUrl = defaultLottieFileUrl,
  onCancel,
}: LoadingModalProps) => {
  const lottieFileQuery = useQuery({
    queryKey: ['lottieFile', lottieFileUrl],
    queryFn: () => fetchJSON<LottieAnimationData>(lottieFileUrl),
    placeholderData: keepPreviousData,
  });

  if (!isLoading) {
    return null;
  }

  return (
    <Modal
      wrapProps={{
        style: {
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
        },
      }}
      style={{top: 0}}
      footer={null}
      width={600}
      destroyOnClose
      closable={false}
      open={isLoading}>
      {headline && <ModalTitle>{headline}</ModalTitle>}
      <Row gutter={[16, 16]}>
        {progress !== undefined ? (
          <Col style={{alignItems: 'center', justifyItems: 'center', display: 'flex'}} span={8}>
            <Progress type="circle" percent={progress} />
          </Col>
        ) : null}
        <Col key={lottieFileQuery.isSuccess ? 'loaded' : 'unloaded'} span={progress === undefined ? 24 : 16}>
          {lottieFileQuery.isSuccess && (
            <Lottie
              animationData={lottieFileQuery.data}
              rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice',
              }}
              loop={true}
              autoplay={true}
            />
          )}
        </Col>
        {onCancel && (
          <Col style={{textAlign: 'center'}} span={24}>
            <Button danger onClick={onCancel}>
              Cancel
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

const ModalTitle = styled.p`
  color: var(--color-black);
  font-size: var(--font-size-lg);
  font-weight: bold;
  text-align: center;
`;
