import React, {useContext} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {StarOutlined, StarFilled} from '@ant-design/icons';
import styled from 'styled-components';

const FavIcon = styled(StarFilled)`
  color: var(--color-yellow);
  margin-right: 8px;
`;

const NoFavIcon = styled(StarOutlined)`
  color: var(--color-yellow);
  margin-right: 8px;
`;

export const Favorite = () => {
  const voyageContext = useContext(VoyageCharterContext);

  const {inputState} = voyageContext.state;
  const {setInputState} = voyageContext;

  const change = () => {
    setInputState({
      ...inputState,
      calculator: {...inputState.calculator, isFavorite: inputState.calculator.isFavorite !== true},
    });
  };
  return (
    <span>
      {inputState.calculator.isFavorite ? (
        <FavIcon data-testid="isFavorite" onClick={change} />
      ) : (
        <NoFavIcon data-testid="isNotFavorite" onClick={change} />
      )}
    </span>
  );
};
