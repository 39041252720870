import {useQuery} from '@tanstack/react-query';
import {ApiError} from '../../../api/utils/ApiError';
import Port from '../../../model/Port';
import {fetchJSON} from '../../../api/utils/fetchJSON';
import {ColumnSort} from '@tanstack/react-table';
import {UseQueryOptions} from '../../../api/utils/ReactQuery';
import {MarketPortDatabaseFilter} from '../../../components/FilterProvider/Filters/Market/MarketPortFilterBranchDefinitions';

type Result = {
  items: Port[];
  totalItems: number;
};

export interface GetPortsRequest {
  filterSettings: MarketPortDatabaseFilter | null;
  page: number;
  pageSize: number;
  sortedBy: ColumnSort;
}

const getRequestUrl = (request: GetPortsRequest) => {
  const sortField = request.sortedBy.id;
  const sortOrder = request.sortedBy.desc ? 'desc' : 'asc';

  const filters = encodeURI(JSON.stringify(request.filterSettings));

  return `/api/ports/list?pageIndex=${request.page}&pageSize=${request.pageSize}&filters=${filters}&sortField=${sortField}&sortOrder=${sortOrder}`;
};

export const usePortsQuery = (request: GetPortsRequest, options?: UseQueryOptions<Result, ApiError>) => {
  return useQuery<Result, ApiError>({
    queryKey: ['ports', request],
    queryFn: async () => {
      const response = await fetchJSON<{data: Result}>(getRequestUrl(request));
      return response.data;
    },
    ...options,
  });
};
