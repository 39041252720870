import {TourDefinition, TourId, getFinalStep} from '../../components/OnboardingTour/TourDefinition';

const FILTERS_SELECTOR = '[data-debugname="Filters"]';
const GRID_ROW_SELECTOR = '.datagrid .rt-tbody .rt-tr';

export const VesselDatabaseTourDefinition: TourDefinition = {
  id: TourId.VesselDB,
  steps: [
    {
      title: "Let's meet: Vessel Database",
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/3.%20Onboarding%20Vessel%20DB%20.mp4',
      },
      pdfDownloadUrl:
        "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/04%20Vessel%20DB/Let's%20meet%20the%20vessel%20DB.pdf",
      placement: 'center',
    },
    {
      title: 'How to use filters',
      target: FILTERS_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/3%20How%20to%20Vessel%20DB/3.1%20How%20to%20use%20vessel%20DB%20filter.mp4',
      },
      placement: 'bottom',
    },
    {
      title: 'How to create a Vessel DB list',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/3%20How%20to%20Vessel%20DB/3.2%20How%20to%20create%20vessel%20DB%20list%20amp4.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/04%20Vessel%20DB/Create%20a%20list%20and%20filter%20by%20size.pdf',
      placement: 'center',
    },
    {
      title: 'How to add a Vessel to My Fleet from Vessel DB',
      target: GRID_ROW_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/3%20How%20to%20Vessel%20DB/3.3%20How%20to%20add%20a%20vessel%20to%20my%20fleet%20from%20vessel%20DB.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/04%20Vessel%20DB/add%20a%20vessel%20to%20my%20fleet.pdf',
      placement: 'bottom',
    },
    getFinalStep('Vessel Database'),
  ],
};
