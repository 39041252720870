import React, {Component} from 'react';
import {connect} from 'react-redux';
import BaseModal from '../../../atoms/BaseModal/index';
import {Button} from '../../../atoms/Button/Button';
import LabelWrapper from '../../../atoms/LabelWrapper';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {CompanyDetailedInformation} from '../../Company/components/DetailedInformation/CompanyDetailedInformation';
import './style.scss';
import {TextArea} from '../../../atoms/TextArea/TextArea';
import {showNotification} from '../../../utils/notification';
import {RootState} from '../../../redux/store';

type EditCompanyDescriptionModalState = $TSFixMe;
// eslint-disable-next-line @typescript-eslint/ban-types
class EditCompanyDescriptionModal extends Component<{}, EditCompanyDescriptionModalState> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props: {}) {
    super(props);
    this.state = {
      companyData: (props as $TSFixMe).companyData,
      error: false,
      disableButton: false,
    };
  }
  onSelect = (changeState: $TSFixMe, changedValue: $TSFixMe) => {
    this.setState((prev: $TSFixMe) => ({
      ...prev,
      companyData: {
        ...prev.companyData,
        [changeState]: changedValue.value,
      },
    }));
  };
  onChange = (changeState: $TSFixMe, changedValue: $TSFixMe) => {
    this.setState((prev: $TSFixMe) => ({
      ...prev,
      companyData: {
        ...prev.companyData,
        [changeState]: changedValue,
      },
    }));
  };
  saveCompany = async () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateCompany' does not exist on type 'R... Remove this comment to see the full error message
    const {updateCompany, callback} = this.props;
    const {id, description, employees, foundYear, companyType, vesselsManaged} = this.state.companyData;
    this.setState({disableButton: true});
    try {
      await updateCompany(id, {
        description,
        employees,
        foundYear: parseInt(foundYear),
        companyType,
        vesselsManaged: parseInt(vesselsManaged),
      });
      callback();
      (this.props as $TSFixMe).close();
      showNotification('Company details was saved.');
    } catch (error) {
      this.setState({error: true});
      throw error;
    }
  };
  render() {
    const {companyData, error, disableButton} = this.state;
    const {description, employees, foundYear, companyType, vesselsManaged} = companyData;
    return (
      <>
        <div className={'company-description-headline'}>
          <div className={'company-description-headline__headline'}>Enter company description</div>
          <div className={'company-description-headline__subline'}>Tell us a little more about your company</div>
        </div>

        <div className={'company-description-modal'}>
          <div className={'company-description-modal__section'}>
            <LabelWrapper label={'Short description'}>
              <TextArea
                value={description !== null ? description : ''}
                id={'description'}
                onChange={event => this.onChange('description', event.target.value)}
              />
            </LabelWrapper>

            <CompanyDetailedInformation
              onChange={this.onChange}
              onSelect={this.onSelect}
              foundYear={foundYear}
              companyType={companyType}
              employees={employees}
              vesselsManaged={vesselsManaged}
            />
          </div>

          {error && (
            <div className={'company-description-modal__error'}>
              Something went wrong, please reload the site or <a href="https://www.seabo.com//contact">contact us</a>
            </div>
          )}
          <div>
            <Button
              label={'cancel'}
              bold
              upper
              id={'description__edit__cancel'}
              onClick={(this.props as $TSFixMe).close}
            />
            <Button
              disabled={disableButton}
              label={'save'}
              bold
              primary
              upper
              id={'description__edit__save'}
              onClick={this.saveCompany}
            />
          </div>
        </div>
      </>
    );
  }
}
const EditCompanyDescription = (props: $TSFixMe) => (
  <BaseModal noHeader noPaddingSideBody>
    {(modalProps: $TSFixMe) => <EditCompanyDescriptionModal {...props} {...modalProps} />}
  </BaseModal>
);
const mapStateToProps = (state: RootState) => ({
  error: state.company.error,
});
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  updateCompany: (id: $TSFixMe, data: $TSFixMe) => dispatch(CompanyActions.updateCompany(id, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyDescription);
