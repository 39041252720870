import {CSSProperties, FC, useState} from 'react';
import {TimeControl} from '../../../model/TimeControl';
import {Button, Card, Slider} from 'antd';
import {BackwardOutlined, CaretRightOutlined, ForwardOutlined, PauseOutlined} from '@ant-design/icons';
import {produce} from 'immer';
import {SliderMarks} from 'antd/es/slider';
import dayjs from 'dayjs';
import {VoyageAnimationApi} from '../../../VoyageAnimation/VoyageAnimationApi';
import clamp from 'lodash/clamp';
import round from 'lodash/round';
// @ts-ignore bad declaration file
import useInterval from 'beautiful-react-hooks/useInterval';
import {DateTimeFormat} from '../../../../../../../utils/DateTimeFormat';

const TIME_STEP_POINTS = 1;

const SPEED_MARKS: SliderMarks = {
  0: '1',
  1: '10',
  2: '100',
  3: '1000',
  4: '10000',
  5: '100000',
  6: '1000000',
};

export const DEFAULT_SPEED_EXPONENT = 4.7;
export const DEFAULT_SPEED = 10 ** DEFAULT_SPEED_EXPONENT;

const seekPercent = (animeTimeline: anime.AnimeTimelineInstance, percent: number) => {
  const newProgress = animeTimeline.duration * (percent / 100);
  animeTimeline.seek(newProgress);
};

export const TimeControlForm: FC<{
  timeControl: TimeControl;
  periodStart: dayjs.Dayjs;
  periodEnd: dayjs.Dayjs;
  voyageAnimationApi: VoyageAnimationApi;
  style?: CSSProperties;
  onTimeControlUpdate: (timeControl: TimeControl) => void;
}> = ({timeControl, periodStart, periodEnd, voyageAnimationApi, style, onTimeControlUpdate}) => {
  const [_forceUpdate, setForceUpdate] = useState(0);

  const forceRender = () => {
    setForceUpdate(prev => prev + 1);
  };

  // Polling to update the current time
  useInterval(forceRender, 1000);

  const animeTimeline = voyageAnimationApi.animeTimeline;

  return (
    <Card style={style}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            gap: 6,
          }}>
          <Button
            type="default"
            shape="circle"
            icon={<BackwardOutlined />}
            size="small"
            onClick={() => {
              if (!animeTimeline) {
                return;
              }
              const newValue = clamp(animeTimeline.progress - TIME_STEP_POINTS, 0, 100);
              seekPercent(animeTimeline, newValue);
              setTimeout(() => {
                voyageAnimationApi.recenter();
                forceRender();
              }, 100);
            }}
          />
          {timeControl.playing ? (
            <Button
              className="pause-button"
              type="default"
              shape="circle"
              icon={<PauseOutlined />}
              size="large"
              onClick={() => {
                // Pause animation
                voyageAnimationApi.animeTimeline?.pause();
                onTimeControlUpdate(
                  produce(timeControl, draft => {
                    draft.playing = false;
                  })
                );
              }}
            />
          ) : (
            <Button
              className="start-button"
              type="default"
              shape="circle"
              icon={<CaretRightOutlined />}
              size="large"
              onClick={() => {
                // Start animation
                voyageAnimationApi.animeTimeline?.play();
                onTimeControlUpdate(
                  produce(timeControl, draft => {
                    draft.playing = true;
                  })
                );
              }}
            />
          )}
          <Button
            type="default"
            shape="circle"
            icon={<ForwardOutlined />}
            size="small"
            onClick={() => {
              if (!animeTimeline) {
                return;
              }
              const newValue = clamp(animeTimeline?.progress + TIME_STEP_POINTS, 0, 100);
              seekPercent(animeTimeline, newValue);
              setTimeout(() => {
                voyageAnimationApi.recenter();
                forceRender();
              }, 100);
            }}
          />

          <div>&nbsp;&nbsp;</div>

          <div style={{position: 'relative', flexGrow: 1}}>
            <Slider
              style={{flexGrow: 1}}
              min={0}
              max={100}
              tooltip={{
                formatter: value => {
                  if (typeof value !== 'number') {
                    return '';
                  }
                  return `${round(value, 1)}%`;
                },
              }}
              value={animeTimeline?.progress ?? 0}
              onChange={percent => {
                if (!animeTimeline) {
                  return;
                }

                seekPercent(animeTimeline, percent);
                forceRender();
              }}
            />

            <div className="period-start" style={{position: 'absolute', bottom: -10, left: 4}}>
              <div style={{fontSize: 12}}>{periodStart.utc().format(DateTimeFormat.DateTime)}</div>
            </div>

            <div className="period-end" style={{position: 'absolute', bottom: -10, right: 4}}>
              <div style={{fontSize: 12}}>{periodEnd.utc().format(DateTimeFormat.DateTime)}</div>
            </div>
          </div>
          <div>&nbsp;&nbsp;</div>

          <div className="current-time" style={{fontSize: 16, minWidth: 200}}>
            {dayjs(voyageAnimationApi.animationState.timestamp).format(DateTimeFormat.DateTime)}
          </div>

          <div className="speed-slider" style={{minWidth: 380}}>
            <h4>Speed</h4>
            <Slider
              marks={SPEED_MARKS}
              step={0.01}
              defaultValue={DEFAULT_SPEED_EXPONENT}
              min={0}
              max={6}
              tooltip={{
                formatter: value => {
                  if (typeof value !== 'number') {
                    return '';
                  }
                  return Math.round(10 ** value);
                },
              }}
              onChange={value => {
                onTimeControlUpdate(
                  produce(timeControl, draft => {
                    draft.speed = 10 ** value;
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
