import React, {FC} from 'react';
import {Modal, Steps} from 'antd';
import {ProjectCargoes} from './ProjectCargoes';
import {useGoToMultiCalc} from '../utils/useGoToMultiCalc';

export const MultiCalcCargoModal: FC<{visible: boolean; onClose: () => void}> = ({visible, onClose}) => {
  const goToMultiCalc = useGoToMultiCalc();

  return (
    <Modal
      data-cy={'MultiCalcCargoModal'}
      width={1000}
      footer={null}
      onCancel={onClose}
      title={'Multi calculation (VC)'}
      open={visible}
      destroyOnClose>
      <Steps
        current={1}
        size={'small'}
        items={[{title: 'Vessels'}, {title: 'Cargo projects'}, {title: 'Results'}]}
        style={{marginBottom: 36}}
      />
      <ProjectCargoes
        onSubmit={params => {
          goToMultiCalc({
            projectIds: params.projectIds,
            vesselIds: params.vesselIds,
          });
          onClose();
        }}
      />
    </Modal>
  );
};
