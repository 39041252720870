import {Dayjs} from 'dayjs';
import {Step5Vessel} from '../../step5';
import {getEstimatedEta} from './getEstimatedEta';
import {getPredictedPort} from './getPredictedPort';

export type Step7Vessel = Step5Vessel & {
  etaIsEstimated: boolean;
};

export const vesselWithEstimatedDestination = async ({
  vessel,
  currentTime,
}: {
  vessel: Step5Vessel;
  currentTime: Dayjs;
}): Promise<Step7Vessel> => {
  const predictedPort = await getPredictedPort(vessel);
  if (!predictedPort) {
    // can not predict a port
    return {...vessel, etaIsEstimated: false};
  }

  const predictedEta = await getEstimatedEta({vessel, predictedPort, currentTime});

  const step7Vessel: Step7Vessel = {
    ...vessel,
    etaIsEstimated: true,
    eta: predictedEta.toDate(),
    destinationPort: predictedPort,
  };

  return step7Vessel;
};
