import {convertLabelToSlug} from '../FilterConversion/utils/FilterConversionUtils';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {stringLengthLessThanOrEqualTo} from '../../../../utils/validators';

export interface DatabaseType {
  registeredOwner?: string;
  managingOwner?: string;
  disponentOwners?: string[];
}

export interface ChartererName {
  value: string;
  label: string;
}

export interface InternalType {
  registeredOwner: string;
  disponentOwners: ChartererName[];
  managingOwner: string;
}

export const Defaults: InternalType = {
  registeredOwner: '',
  disponentOwners: [],
  managingOwner: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {registeredOwner, managingOwner, disponentOwners} = database;
  return {
    registeredOwner: registeredOwner ?? '',
    managingOwner: managingOwner ?? '',
    disponentOwners: disponentOwners ? disponentOwners.map(c => ({value: convertLabelToSlug(c), label: c})) : [],
  };
};

export const toDatabase = ({disponentOwners, registeredOwner, managingOwner}: InternalType): DatabaseType => ({
  registeredOwner: registeredOwner || undefined,
  managingOwner: managingOwner || undefined,
  disponentOwners: disponentOwners ? disponentOwners.map(d => d.label) : [],
});

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Ownership',
  branch: 'ownership',
  defaults: Defaults,
  validators: {
    registeredOwner: stringLengthLessThanOrEqualTo(191),
    managingOwner: stringLengthLessThanOrEqualTo(191),
  },
  fromDatabase,
  toDatabase,
});
