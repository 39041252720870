/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UpdateConsentContractVersionDTO
 */
export interface UpdateConsentContractVersionDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateConsentContractVersionDTO
   */
  title: string;
  /**
   *
   * @type {Date}
   * @memberof UpdateConsentContractVersionDTO
   */
  validFrom: Date | null;
}

export function UpdateConsentContractVersionDTOFromJSON(json: any): UpdateConsentContractVersionDTO {
  return UpdateConsentContractVersionDTOFromJSONTyped(json, false);
}

export function UpdateConsentContractVersionDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateConsentContractVersionDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json['title'],
    validFrom: json['validFrom'] === null ? null : new Date(json['validFrom']),
  };
}

export function UpdateConsentContractVersionDTOToJSON(value?: UpdateConsentContractVersionDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    validFrom: value.validFrom === null ? null : value.validFrom.toISOString(),
  };
}
