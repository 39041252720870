/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface VesselConsumptionDTO
 */
export interface VesselConsumptionDTO {
  /**
   * Primary key in the node vessel DB, NOT the Symfony DB.
   * @type {number}
   * @memberof VesselConsumptionDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof VesselConsumptionDTO
   */
  type: VesselConsumptionDTOTypeEnum;
  /**
   *
   * @type {number}
   * @memberof VesselConsumptionDTO
   */
  speed: number;
  /**
   *
   * @type {number}
   * @memberof VesselConsumptionDTO
   */
  fuelConsumption: number;
  /**
   *
   * @type {string}
   * @memberof VesselConsumptionDTO
   */
  fuelType: VesselConsumptionDTOFuelTypeEnum;
  /**
   *
   * @type {number}
   * @memberof VesselConsumptionDTO
   */
  extraFuelConsumption: number | null;
  /**
   *
   * @type {string}
   * @memberof VesselConsumptionDTO
   */
  extraFuelType: VesselConsumptionDTOExtraFuelTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum VesselConsumptionDTOTypeEnum {
  Laden = 'laden',
  Ballast = 'ballast',
  Ballasteco = 'ballasteco',
  Ladeneco = 'ladeneco',
  Ladenballast = 'ladenballast',
  Portidle = 'portidle',
  Portworking = 'portworking',
  Designdraft = 'designdraft',
  Scantlingdraft = 'scantlingdraft',
} /**
 * @export
 * @enum {string}
 */
export enum VesselConsumptionDTOFuelTypeEnum {
  Ifo = 'ifo',
  Mdo = 'mdo',
  Lfo = 'lfo',
  Lsfo = 'lsfo',
  Lsmgo = 'lsmgo',
  Mgo = 'mgo',
  Hfo = 'hfo',
} /**
 * @export
 * @enum {string}
 */
export enum VesselConsumptionDTOExtraFuelTypeEnum {
  Ifo = 'ifo',
  Mdo = 'mdo',
  Lfo = 'lfo',
  Lsfo = 'lsfo',
  Lsmgo = 'lsmgo',
  Mgo = 'mgo',
  Hfo = 'hfo',
}

export function VesselConsumptionDTOFromJSON(json: any): VesselConsumptionDTO {
  return VesselConsumptionDTOFromJSONTyped(json, false);
}

export function VesselConsumptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselConsumptionDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    speed: json['speed'],
    fuelConsumption: json['fuelConsumption'],
    fuelType: json['fuelType'],
    extraFuelConsumption: json['extraFuelConsumption'],
    extraFuelType: json['extraFuelType'],
  };
}

export function VesselConsumptionDTOToJSON(value?: VesselConsumptionDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    speed: value.speed,
    fuelConsumption: value.fuelConsumption,
    fuelType: value.fuelType,
    extraFuelConsumption: value.extraFuelConsumption,
    extraFuelType: value.extraFuelType,
  };
}
