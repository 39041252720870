import React, {FC, ReactNode, useCallback} from 'react';
import {ActionMenu} from '../ActionMenu/ActionMenu';
import Icon from '../../atoms/Icon';
import {MenuItem, MenuItemLink} from '../../atoms/Menu/MenuItem';
import {ModalActions} from '../../redux/Modal';
import LoadingComponent from '../DataGrid/LoadingComponent';
import {showNotification} from '../../utils/notification';
import ShortListEmptyState from '../../atoms/EmptyState/Project/Shortlist';
import {CharterCalculationMenuItem} from './CharterCalculationMenuItem';
import {FeatureToggles} from '../../utils/FeatureToggles';
import {shipNameColumn} from '../GridLayout/columns/vessel/shipNameColumn/shipNameColumn';
import {dwtColumn} from '../GridLayout/columns/vessel/dwtColumn';
import {gearsColumn} from '../GridLayout/columns/vessel/gearsColumn';
import {lengthColumn} from '../GridLayout/columns/vessel/lengthColumn';
import {teuColumn} from '../GridLayout/columns/vessel/teuColumn';
import {noteColumn as vesselCommentColumn} from '../GridLayout/columns/vessel/noteColumn';
import {openColumn} from '../GridLayout/columns/vessel/openColumn';
import {chartererNameColumn} from '../GridLayout/columns/cargo/chartererNameColumn';
import {sizeColumn} from '../GridLayout/columns/cargo/sizeColumn';
import {commodityColumn} from '../GridLayout/columns/cargo/commodityColumn';
import {durationColumn} from '../GridLayout/columns/cargo/durationColumn';
import {laycanColumn} from '../GridLayout/columns/cargo/laycanColumn';
import {loadDeliveryColumn} from '../GridLayout/columns/cargo/loadDeliveryColumn';
import {dischargeRedeliveryColumn} from '../GridLayout/columns/cargo/dischargeRedeliveryColumn';
import {commissionColumn} from '../GridLayout/columns/cargo/commissionColumn';
import {commentColumn as cargoCommentColumn} from '../GridLayout/columns/cargo/commentColumn';
import {cargoPostedByColumn} from './Shortlist/CargoPostedByColumn';
import {vesselPostedByColumn} from './Shortlist/VesselPostedByColumn';
import {cargoActionsColumn} from './Shortlist/CargoActionColumns';
import {vesselActionsColumn} from './Shortlist/VesselActionsColumn';
import {useDispatch} from '../../redux/react-redux';
import {RowValueType} from '../GridLayout/utils/RowValueType';
import {useDeleteShortlistMutation} from './useDeleteShortlistMutation';
import {TODO} from '../../utils/TODO';
import {useProjectDetails} from './useProjectDetails';
import {ProjectDetailCard} from './ProjectDetailCard';
import {Button} from 'antd';
import styled from 'styled-components';
import {grainColumn} from '../GridLayout/columns/vessel/grainColumn';
import {useAisVesselsQuery} from '../../queries/useAisVesselsQuery';
import {CustomColumnDef, DataGrid} from '../DataGrid/DataGrid';
import {createSearchParams} from 'react-router-dom';
import {Shortlist} from '../Modal/ProjectDetailModals/addToShortlist';
import {useProjectDetailsCharterCalculationsQuery} from '../../queries/useProjectDetailsCharterCalculationsQuery';
import {useShortlistQuery} from '../../queries/useShortlistQuery';

type ProjectDetailShortlistProps = {
  selectedShortlistItemId: number | null;
  onShortlistItemClick: (id: number) => void;
};

export const ProjectDetailsShortlist: FC<ProjectDetailShortlistProps> = ({
  selectedShortlistItemId,
  onShortlistItemClick,
}) => {
  const dispatch = useDispatch();
  const {
    project: {id, type: projectType, vessel, cargo, isArchived},
    getProject,
  } = useProjectDetails();

  const query = useProjectDetailsCharterCalculationsQuery(id);
  const projectCharterCalculations = query.data?.items ?? [];
  const isVesselShortlist = projectType === 'cargo';

  const onDeleteFromShortlist = useDeleteShortlistMutation({
    onSuccess: () => {
      showNotification();
      getProject();
    },
  });
  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const handleDelete = (type: 'cargoes' | 'vessels', itemId: number) => {
    onDeleteFromShortlist.mutate({
      projectId: id,
      type,
      shortlistItemId: itemId,
    });
  };

  const getCreateNegotiationLink = (shortlistItemID: number) => {
    let vesselId = '';
    let cargoId = '';
    if (isVesselShortlist) {
      vesselId = shortlistItemID.toString();
      cargoId = cargo?.id.toString();
    } else {
      cargoId = shortlistItemID.toString();
      vesselId = vessel?.id.toString();
    }

    const ids = Object.entries({vesselId, cargoId}).filter(([, value]) => !!value);
    const query = ids.length ? `?${createSearchParams(ids)}` : '';

    return `/negotiations/create${query}`;
  };

  const shortListQuery = useShortlistQuery({id, isVesselShortlist});
  const aisVesselsQuery = useAisVesselsQuery();

  const vesselLocationAvailableColor = useCallback(
    (imo: number) => (aisVesselsQuery.data?.find(aisVessel => aisVessel.imo === imo) ? 'gray' : 'light-gray'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shortListQuery.dataUpdatedAt, aisVesselsQuery.dataUpdatedAt]
  );

  const showVesselPositionColor = useCallback(
    ({imo, id}: {imo: number; id: number}) =>
      selectedShortlistItemId === id ? 'blue' : vesselLocationAvailableColor(imo),
    [selectedShortlistItemId, vesselLocationAvailableColor]
  );

  const getCargoGrid = (type: RowValueType): CustomColumnDef<TODO>[] => [
    {
      id: 'showOnMap',
      header: '',
      accessorFn: (d: TODO) => (
        <Icon
          type="location"
          onClick={() => onShortlistItemClick(d.cargo.id)}
          color={selectedShortlistItemId === d.cargo.id ? 'blue' : 'gray'}
        />
      ),
      cell: info => info.getValue(),
      minWidth: 1.5,
    },
    chartererNameColumn({type}),
    durationColumn({type}),
    sizeColumn({type, minWidth: 3}),
    commodityColumn({type, minWidth: 5}),
    laycanColumn({type, minWidth: 5}),
    loadDeliveryColumn({type, showFlag: true}),
    dischargeRedeliveryColumn({type, showFlag: true}),
    commissionColumn({type, minWidth: 3}),
    cargoPostedByColumn({}),
    cargoCommentColumn({type, editable: true}),
    {
      id: 'chartercalc',
      header: 'TCE',
      accessorFn: d => {
        const id = d.cargo.id;
        const charteCalcForId = projectCharterCalculations.find(c => c.cargoes?.[0]?.id === id);
        // TODO nessecary due to not being provided by the API
        return <div>{(charteCalcForId?.result as TODO)?.cargo?.tce || '-'}</div>;
      },
      cell: info => info.getValue(),
      minWidth: 1.5,
    },
    cargoActionsColumn({
      minWidth: 2,
      accessorFn: (d: TODO) => (
        <ActionMenu
          actionIcon={<Icon type={'more-vert'} />}
          items={[
            {
              key: 'View Cargo',
              label: <MenuItemLink label="View cargo" href={`/cargo/${d.cargo.id}`} />,
            },
            {
              key: 'cargo',
              label: <CharterCalculationMenuItem cargo={d.cargo} items={projectCharterCalculations} />,
            },
            ...(FeatureToggles.negotiations
              ? [
                  {
                    key: 'negotiation',
                    label: <MenuItemLink label="Create negotiation" href={getCreateNegotiationLink(d.cargo.id)} />,
                  },
                ]
              : []),
            {
              key: 'remove',
              isValid: !isArchived,
              label: <MenuItem label="Delete" onClick={() => handleDelete('cargoes', d.id)} />,
            },
          ]}
        />
      ),
    }),
  ];

  const getVesselGrid = (type: RowValueType): CustomColumnDef<TODO>[] => [
    {
      id: 'showOnMap',
      header: '',
      accessorFn: d => (
        <Icon
          type="location"
          onClick={() => onShortlistItemClick(d.vessel.id)}
          color={showVesselPositionColor(d.vessel)}
        />
      ),
      cell: info => info.getValue(),
      minWidth: 1.5,
    },
    shipNameColumn({type}),
    dwtColumn({type}),
    ...(cargo!.cargoType === 'drybulk' ? [grainColumn({type})] : [teuColumn({type})]),
    gearsColumn({type}),
    lengthColumn({type}),
    openColumn({type, minWidth: 14, showFlag: true}),
    vesselPostedByColumn({minWidth: 5}),
    vesselCommentColumn({type}),
    vesselActionsColumn({
      minWidth: 2,
      accessorFn: d => (
        <ActionMenu
          actionIcon={<Icon type={'more-vert'} />}
          items={[
            {
              key: 'View Vessel',
              label: <MenuItemLink label="View vessel" href={`/vessel/${d.vessel.id}`} />,
            },
            {
              key: 'chartercalc',
              label: <CharterCalculationMenuItem vessel={d.vessel} items={projectCharterCalculations} />,
            },
            ...(FeatureToggles.negotiations
              ? [
                  {
                    key: 'negotiation',
                    label: <MenuItemLink label="Create negotiation" href={getCreateNegotiationLink(d.vessel.id)} />,
                  },
                ]
              : []),
            {
              key: 'remove',
              isValid: !isArchived,
              label: <MenuItem label="Delete" onClick={() => handleDelete('vessels', d.id)} />,
            },
          ]}
        />
      ),
    }),
  ];

  if (
    shortListQuery.isSuccess &&
    shortListQuery.data.length > 0 &&
    isVesselShortlist &&
    !shortListQuery.data[0].vessel
  ) {
    throw new Error('Not a vessel short list - this is the bug from FRIN-1705');
  }

  const titleLabel = isVesselShortlist ? 'Vessel shortlist' : 'Cargo shortlist';

  return (
    <ProjectDetailCard
      noPadding
      header={`${titleLabel} (${shortListQuery.data?.length ?? 'loading'})`}
      footer={
        !isArchived && (
          <Button
            type="primary"
            onClick={() =>
              showModal(
                <Shortlist
                  id={id}
                  type={isVesselShortlist ? 'vessel' : 'cargoes'}
                  shortList={shortListQuery.data ?? []}
                  onAdd={getProject}
                  close={() => {}}
                />
              )
            }>
            {isVesselShortlist ? 'Add vessels' : 'Add cargoes'}
          </Button>
        )
      }>
      <div>
        {shortListQuery.isLoading && <LoadingComponent style={{textAlign: 'center'}} />}
        {shortListQuery.isSuccess && shortListQuery.data.length === 0 && (
          <ShortListEmptyState
            type={isVesselShortlist ? 'vessel' : 'cargo'}
            onClick={() =>
              showModal(
                <Shortlist
                  id={id}
                  type={isVesselShortlist ? 'vessel' : 'cargoes'}
                  onAdd={getProject}
                  shortList={shortListQuery.data ?? []}
                  close={() => {}}
                />
              )
            }
          />
        )}
        {shortListQuery.isSuccess && shortListQuery.data.length !== 0 && (
          <StyledDataGrid<TODO>
            responsive
            manualSorting={false}
            unrounded
            id={'project-detail-shortlist__data-grid'}
            columns={isVesselShortlist ? getVesselGrid('market') : getCargoGrid('market')}
            data={shortListQuery.data}
            loading={shortListQuery.isLoading}
            getTrProps={(_: unknown, rowInfo: TODO) => {
              if (rowInfo?.original[isVesselShortlist ? 'vessel' : 'cargo'].id === selectedShortlistItemId) {
                return {className: 'is-highlighted'};
              }
              return {};
            }}
          />
        )}
      </div>
    </ProjectDetailCard>
  );
};

const StyledDataGrid = styled(DataGrid)`
  .is-highlighted {
    background: var(--color-blue-bg);
  }
`;
