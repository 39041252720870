/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ConnectedState,
  ConnectedStateFromJSON,
  ConnectedStateFromJSONTyped,
  ConnectedStateToJSON,
  ContactDetails,
  ContactDetailsFromJSON,
  ContactDetailsFromJSONTyped,
  ContactDetailsToJSON,
  Reference,
  ReferenceFromJSON,
  ReferenceFromJSONTyped,
  ReferenceToJSON,
  UserCard,
  UserCardFromJSON,
  UserCardFromJSONTyped,
  UserCardToJSON,
} from './';

/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  email?: string | null;
  /**
   *
   * @type {Date}
   * @memberof UserProfile
   */
  registrationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof UserProfile
   */
  friendsCount: number;
  /**
   *
   * @type {Array<UserCard>}
   * @memberof UserProfile
   */
  friends: Array<UserCard>;
  /**
   *
   * @type {ContactDetails}
   * @memberof UserProfile
   */
  contactDetails: ContactDetails;
  /**
   *
   * @type {number}
   * @memberof UserProfile
   */
  userID: number;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  avatar: string;
  /**
   *
   * @type {Reference}
   * @memberof UserProfile
   */
  company?: Reference | null;
  /**
   *
   * @type {ConnectedState}
   * @memberof UserProfile
   */
  currentUserFriendStatus: ConnectedState;
}

export function UserProfileFromJSON(json: any): UserProfile {
  return UserProfileFromJSONTyped(json, false);
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: !exists(json, 'email') ? undefined : json['email'],
    registrationDate: !exists(json, 'registrationDate') ? undefined : new Date(json['registrationDate']),
    friendsCount: json['friendsCount'],
    friends: (json['friends'] as Array<any>).map(UserCardFromJSON),
    contactDetails: ContactDetailsFromJSON(json['contactDetails']),
    userID: json['userID'],
    fullName: json['fullName'],
    avatar: json['avatar'],
    company: !exists(json, 'company') ? undefined : ReferenceFromJSON(json['company']),
    currentUserFriendStatus: ConnectedStateFromJSON(json['currentUserFriendStatus']),
  };
}

export function UserProfileToJSON(value?: UserProfile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    registrationDate: value.registrationDate === undefined ? undefined : value.registrationDate.toISOString(),
    friendsCount: value.friendsCount,
    friends: (value.friends as Array<any>).map(UserCardToJSON),
    contactDetails: ContactDetailsToJSON(value.contactDetails),
    userID: value.userID,
    fullName: value.fullName,
    avatar: value.avatar,
    company: ReferenceToJSON(value.company),
    currentUserFriendStatus: ConnectedStateToJSON(value.currentUserFriendStatus),
  };
}
