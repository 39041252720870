import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {numberFormat} from '../../../../utils/formatter';
import classNames from 'classnames';
import {AccessorFn} from '@tanstack/react-table';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import '../../../../screens/market/GridDisplay/style.scss';
import {Blurred} from '../../utils/Blurred';

export interface LengthColumnAccessorOptions {
  blurred?: boolean;
}

const lengthAccessor =
  (type: RowValueType, options: LengthColumnAccessorOptions): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'} data-cy="cell-loa">
        <Blurred blurred={options.blurred}>
          <div className={'market-grid-cell-content'}>
            {numberFormat(
              vessel.lengthOverall,
              {emptyString: '-'},
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </div>
          <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
            {numberFormat(
              vessel.beam,
              {emptyString: '-'},
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </div>
        </Blurred>
      </div>
    );
  };

export const lengthColumn = ({
  type,
  minWidth = 4,
  enableSorting = true,
  options = {},
}: {
  type?: RowValueType;
  minWidth?: number;
  enableSorting?: boolean;
  options?: LengthColumnAccessorOptions;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div className={classNames({'datagrid-sortable-header': enableSorting})} data-cy="header-loa">
      <div>
        LOA
        <br />
        Beam
      </div>
    </div>
  ),

  id: 'loa',
  accessorFn: lengthAccessor(type, options),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
  enableSorting,
});
