/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationSubjectModificationReport,
  NegotiationSubjectModificationReportFromJSON,
  NegotiationSubjectModificationReportFromJSONTyped,
  NegotiationSubjectModificationReportToJSON,
  NegotiationTermStatus,
  NegotiationTermStatusFromJSON,
  NegotiationTermStatusFromJSONTyped,
  NegotiationTermStatusToJSON,
  TermModification,
  TermModificationFromJSON,
  TermModificationFromJSONTyped,
  TermModificationToJSON,
} from './';

/**
 *
 * @export
 * @interface RoundResultTermModification
 */
export interface RoundResultTermModification {
  /**
   *
   * @type {string}
   * @memberof RoundResultTermModification
   */
  termId: string;
  /**
   *
   * @type {string}
   * @memberof RoundResultTermModification
   */
  oldText: string | null;
  /**
   *
   * @type {string}
   * @memberof RoundResultTermModification
   */
  newText: string;
  /**
   *
   * @type {NegotiationTermStatus}
   * @memberof RoundResultTermModification
   */
  oldStatus: NegotiationTermStatus;
  /**
   *
   * @type {NegotiationTermStatus}
   * @memberof RoundResultTermModification
   */
  newStatus: NegotiationTermStatus;
  /**
   * The term has changed since the last round.
   * @type {boolean}
   * @memberof RoundResultTermModification
   */
  changed: boolean;
  /**
   *
   * @type {TermModification}
   * @memberof RoundResultTermModification
   */
  termModification: TermModification;
  /**
   * The modifications of subject in this term since the previous round.
   * @type {Array<NegotiationSubjectModificationReport>}
   * @memberof RoundResultTermModification
   */
  subjectModificationReports: Array<NegotiationSubjectModificationReport>;
}

export function RoundResultTermModificationFromJSON(json: any): RoundResultTermModification {
  return RoundResultTermModificationFromJSONTyped(json, false);
}

export function RoundResultTermModificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RoundResultTermModification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    termId: json['termId'],
    oldText: json['oldText'],
    newText: json['newText'],
    oldStatus: NegotiationTermStatusFromJSON(json['oldStatus']),
    newStatus: NegotiationTermStatusFromJSON(json['newStatus']),
    changed: json['changed'],
    termModification: TermModificationFromJSON(json['termModification']),
    subjectModificationReports: (json['subjectModificationReports'] as Array<any>).map(
      NegotiationSubjectModificationReportFromJSON
    ),
  };
}

export function RoundResultTermModificationToJSON(value?: RoundResultTermModification | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    termId: value.termId,
    oldText: value.oldText,
    newText: value.newText,
    oldStatus: NegotiationTermStatusToJSON(value.oldStatus),
    newStatus: NegotiationTermStatusToJSON(value.newStatus),
    changed: value.changed,
    termModification: TermModificationToJSON(value.termModification),
    subjectModificationReports: (value.subjectModificationReports as Array<any>).map(
      NegotiationSubjectModificationReportToJSON
    ),
  };
}
