import {TourDefinition, TourId, getFinalStep} from '../../components/OnboardingTour/TourDefinition';

const FILTERS_SELECTOR = '[data-debugname="Filters"]';
const GRID_ROW_SELECTOR = '.datagrid .rt-tbody .rt-tr';

export const MarketTourDefinition: TourDefinition = {
  id: TourId.Market,
  steps: [
    {
      title: "Let's meet: Market",
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/2.%20Onboarding%20Market.mp4',
      },
      pdfDownloadUrl: "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/03%20Market/Let's%20meet%20the%20market.pdf",
      placement: 'center',
    },
    {
      title: 'How to use filters',
      target: FILTERS_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/2%20How%20to%20Market/2.1%20How%20to%20use%20filters.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/03%20Market/How%20to%20use%20the%20filters.pdf',
      placement: 'bottom',
    },
    {
      title: 'How to add a submarket',
      target: FILTERS_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/2%20How%20to%20Market/2.2%20%20Add%20Submarket.mp4',
      },
      placement: 'bottom',
    },
    {
      title: 'How to see vessel and cargo details',
      target: GRID_ROW_SELECTOR,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/2%20How%20to%20Market/2.3%20How%20to%20see%20vessel%20and%20cargoe%20details.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/03%20Market/see%20vessel%20and%20cargoe%20details.pdf',
      placement: 'bottom',
    },
    {
      title: 'How to edit details and add to a project',
      target: GRID_ROW_SELECTOR,
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/03%20Market/edit%20details%20&%20add%20to%20a%20project.pdf',
      placement: 'center',
    },
    {
      title: 'How to see the original mail',
      target: GRID_ROW_SELECTOR,
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/03%20Market/see%20the%20original%20e_mail.pdf',
      placement: 'center',
    },
    getFinalStep('Market'),
  ],
};
