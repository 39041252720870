/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CompanySignupRequest
 */
export interface CompanySignupRequest {
  /**
   *
   * @type {string}
   * @memberof CompanySignupRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompanySignupRequest
   */
  contactPhone: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanySignupRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CompanySignupRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CompanySignupRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CompanySignupRequest
   */
  newPassword: string;
  /**
   *
   * @type {string}
   * @memberof CompanySignupRequest
   */
  source?: string;
}

export function CompanySignupRequestFromJSON(json: any): CompanySignupRequest {
  return CompanySignupRequestFromJSONTyped(json, false);
}

export function CompanySignupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySignupRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    contactPhone: json['contactPhone'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    newPassword: json['newPassword'],
    source: !exists(json, 'source') ? undefined : json['source'],
  };
}

export function CompanySignupRequestToJSON(value?: CompanySignupRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    contactPhone: value.contactPhone,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    newPassword: value.newPassword,
    source: value.source,
  };
}
