import styled from 'styled-components';
import {InputNumber as AntdInputNumber, InputNumberProps} from 'antd';
import {FC} from 'react';

export const InputNumber: FC<InputNumberProps> = styled(AntdInputNumber)`
  box-sizing: border-box;
  background-color: var(--color-gray-6);
  border: var(--border-base);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-inset-input);
  font-size: var(--font-size-md);
  appearance: none;
  padding: 0 12px;
  color: var(--color-black);
  font-weight: 400;
  outline: none;
  position: relative;
  width: 100%;
`;
