import {TODO} from '../utils/TODO';
import {actions as portsApi, actionTypes as portsApiTypes} from './ApiService/ports';
import {AppThunk} from './AppThunk';
import {ActionWithPayload} from './middlware/ActionWithPayload';

type Filter = TradingArea | Country | Port;

interface TradingArea {
  type: 'tradingarea';
  id: string;
  // "Southeast Asia"
  name: string;
  // "SEASIA"
  code: string;
  aliases: string[];
}

interface Country {
  type: 'country';
  // "China"
  name: string;
  id: number;
  // "CN"
  code: string;
  aliases: string[];
}

interface CountryObject {
  // "DE"
  code: string;
  // "Germany"
  name: string;
}

type Coordinates = [lat: number, lon: number];

export type ReduxPort = Port;

export type ImportantFilter = 'important' | 'unimportant';
export type ImportantReviewedFilter = 'reviewed' | 'unreviewed';

export interface PortListParams {
  pageIndex?: number;
  pageSize?: number;
  sorted?: TODO;
  filters?: TODO;
  ids?: TODO;
  polygonFilter?: TODO;
  tinyFilter?: boolean;
  importantFilter?: ImportantFilter;
  importantReviewedFilter?: ImportantReviewedFilter;
}

export interface Port {
  id: number;
  // Hamburg
  name: string;
  // UN Locode, like "DEHAM"
  code: string;
  type: 'port';
  // "DE"
  country: string;
  countryObject: CountryObject;
  // "Europe/Berlin"
  timezone: string;
  lat: number;
  lon: number;
  // The same lat/lon again :-(
  coordinates: Coordinates;
  // E.g. 1
  reviewStatus: number;
  poloygonReviewed: boolean;
  polygon: Coordinates[];
  // E.g. "UKC"
  tradingArea: string;
  // E.g. "UK Continent"
  tradingAreaName: string;
  tradingAreaId: number;
  isTiny: boolean;
  isLarge: true;
  // ISO date
  updatedAt: string;
  // ISO date
  createdAt: string;
}

interface SortColumn {
  id: string;
  desc: boolean;
}

/**
 * The state of the data grid in /backend/ports.
 */
interface PortsBackendState {
  portList: {
    items: Port[];
    pageIndex: number;
    pageSize: number;
    sorted: SortColumn[];
    filters: Filter[] | null;
    ids: null;
    polygonFilter: boolean;
    tinyFilter: boolean;
    importantFilter?: ImportantFilter;
    importantReviewedFilter?: ImportantReviewedFilter;
    totalItems: number;
  };
}

const initialState: PortsBackendState = {
  portList: {
    items: [],
    pageIndex: 1,
    pageSize: 10,
    sorted: [{id: 'name', desc: false}],
    filters: null,
    ids: null,
    polygonFilter: false,
    tinyFilter: false,
    importantFilter: undefined,
    importantReviewedFilter: undefined,
    totalItems: 0,
  },
};

const actionTypes = {
  PORTS_SET_PORTLIST_PARAMS: 'PORTS_SET_PORTLIST_PARAMS',
};

export const portsBackendReducer = (state = initialState, action: ActionWithPayload): PortsBackendState => {
  const {type, payload} = action;
  switch (type) {
    case portsApiTypes.getPortList.SUCCESS:
      return {
        ...state,
        portList: {
          ...state.portList,
          items: payload.data.items,
          totalItems: payload.data.totalItems,
        },
      };
    case actionTypes.PORTS_SET_PORTLIST_PARAMS: {
      const portListParams: PortListParams = payload;
      return {
        ...state,
        portList: {
          ...state.portList,
          pageIndex: portListParams.pageIndex || state.portList.pageIndex,
          pageSize: portListParams.pageSize || state.portList.pageSize,
          sorted: portListParams.sorted || state.portList.sorted,
          filters: 'filters' in portListParams ? portListParams.filters : state.portList.filters,
          ids: 'ids' in portListParams ? portListParams.ids : state.portList.ids,
          polygonFilter:
            'polygonFilter' in portListParams ? portListParams.polygonFilter : state.portList.polygonFilter,
          tinyFilter: 'tinyFilter' in portListParams ? portListParams.tinyFilter! : state.portList.tinyFilter,
          importantFilter:
            'importantFilter' in portListParams ? portListParams.importantFilter : state.portList.importantFilter,
          importantReviewedFilter:
            'importantReviewedFilter' in portListParams
              ? portListParams.importantReviewedFilter
              : state.portList.importantReviewedFilter,
        },
      };
    }
    default:
      return state;
  }
};

interface BackendPortsParams {
  tradingAreas?: (number | string)[];
  countries?: number[];
  /**
   * Port ids.
   */
  ids?: number[] | null;
  polygonFilter?: boolean;
  tinyFilter?: boolean;
  important?: boolean;
  importantReviewed?: boolean;
}

export const getPortList = (): AppThunk => (dispatch, getState) => {
  const portList = getState().portsBackend.portList;
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    items,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    totalItems,
    sorted,
    filters,
    ids,
    polygonFilter,
    tinyFilter,
    importantFilter,
    importantReviewedFilter,
    ...otherParams
  } = portList;

  const params: TODO = {
    ...otherParams,
  };

  if (sorted.length > 0) {
    params.sortField = sorted[0].id === 'tradingAreaName' ? 'tradingArea' : sorted[0].id;
    params.sortOrder = sorted[0].desc ? 'desc' : 'asc';
  }

  let backendPortsParams: BackendPortsParams = {};
  if (filters) {
    backendPortsParams = {tradingAreas: [], countries: [], ids: []};
    filters.forEach((f: Filter) => {
      if (f.type === 'tradingarea') {
        backendPortsParams.tradingAreas!.push(f.id);
      } else if (f.type === 'country') {
        backendPortsParams.countries!.push(f.id);
      } else if (f.type === 'port') {
        backendPortsParams.ids!.push(f.id);
      }
    });
  } else if (ids) {
    backendPortsParams = {ids};
  }

  backendPortsParams.polygonFilter = polygonFilter;
  backendPortsParams.tinyFilter = tinyFilter;
  if (importantFilter) {
    backendPortsParams.important = importantFilter === 'important';
  }
  if (importantReviewedFilter) {
    backendPortsParams.importantReviewed = importantReviewedFilter === 'reviewed';
  }
  params.filters = JSON.stringify({backendPortsParams});

  return dispatch(portsApi.getPortList({queryParams: {...params, backendView: 1}}));
};

export const setPortListParams =
  (params: PortListParams): AppThunk =>
  dispatch => {
    dispatch({
      type: actionTypes.PORTS_SET_PORTLIST_PARAMS,
      payload: params,
    });
  };
