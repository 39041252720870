export type VesselSizeType = {label: string; value: string; dwtFrom: number; dwtTo: number; color: string};
export const vesselSizeTypes: VesselSizeType[] = [
  {
    color: '#e16666',
    label: 'Mini Bulker',
    value: 'minibulker',
    dwtFrom: 0,
    dwtTo: 19999,
  },
  {
    color: '#66e19b',
    label: 'Handysize',
    value: 'handysize',
    dwtFrom: 20000,
    dwtTo: 39999,
  },
  {
    color: '#d5e166',
    label: 'Handymax',
    value: 'handymax',
    dwtFrom: 40000,
    dwtTo: 49999,
  },
  {
    color: '#e166cd',
    label: 'Supramax',
    value: 'supramax',
    dwtFrom: 50000,
    dwtTo: 59999,
  },
  {
    label: 'Ultramax',
    value: 'ultramax',
    dwtFrom: 60000,
    color: '#667fe1',
    dwtTo: 69999,
  },
  {
    label: 'Panamax',
    value: 'panamax',
    dwtFrom: 70000,
    color: '#e19d66',
    dwtTo: 79999,
  },
  {
    label: 'Kamsarmax',
    value: 'kamsarmax',
    dwtFrom: 80000,
    dwtTo: 83999,
    color: '#178cf5',
  },
  {
    label: 'Post-Panamax',
    value: 'postpanamax',
    dwtFrom: 84000,
    dwtTo: 109999,
    color: '#b066e1',
  },
  {
    label: 'Capesize',
    value: 'capesize',
    dwtFrom: 110000,
    dwtTo: 500000,
    color: '#e166d7',
  },
];

export const getVesselSize = (dwt: number) => {
  return vesselSizeTypes.find(item => {
    return item.dwtFrom <= dwt && item.dwtTo >= dwt;
  });
};

export const getVesselSizeTypeByLabel = (label: string) => {
  return vesselSizeTypes.find(item => {
    return item.label === label;
  });
};
