import {TODO} from '../../../../utils/TODO';
import {transformSearchElementToSelectElement} from './transformSearchElementToSelectElement';
import {toSearchElement} from './SearchElement';

export const onSearchSelect = ({
  object,
  layers,
  ports,
}: {
  object: TODO;
  layers: {id: string}[];
  ports: TODO[];
}): {searchElement: TODO; selectElement: TODO} => {
  if (object === null) {
    return {selectElement: null, searchElement: null};
  }

  const searchElement = toSearchElement({object, ports});

  if (searchElement === null) {
    return {selectElement: null, searchElement: null};
  }

  const selectElement = transformSearchElementToSelectElement({
    layers: layers,
    searchItem: searchElement,
  });

  return {searchElement, selectElement};
};
