/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  BaleUnitEnum,
  BaleUnitEnumFromJSON,
  BaleUnitEnumFromJSONTyped,
  BaleUnitEnumToJSON,
  Company,
  CompanyFromJSON,
  CompanyFromJSONTyped,
  CompanyToJSON,
  Consumption,
  ConsumptionFromJSON,
  ConsumptionFromJSONTyped,
  ConsumptionToJSON,
  Document,
  DocumentFromJSON,
  DocumentFromJSONTyped,
  DocumentToJSON,
  Engine,
  EngineFromJSON,
  EngineFromJSONTyped,
  EngineToJSON,
  Fixture,
  FixtureFromJSON,
  FixtureFromJSONTyped,
  FixtureToJSON,
  Gear,
  GearFromJSON,
  GearFromJSONTyped,
  GearToJSON,
  GrainUnitEnum,
  GrainUnitEnumFromJSON,
  GrainUnitEnumFromJSONTyped,
  GrainUnitEnumToJSON,
  Image,
  ImageFromJSON,
  ImageFromJSONTyped,
  ImageToJSON,
  Intake,
  IntakeFromJSON,
  IntakeFromJSONTyped,
  IntakeToJSON,
  Station,
  StationFromJSON,
  StationFromJSONTyped,
  StationToJSON,
  StatusEnum,
  StatusEnumFromJSON,
  StatusEnumFromJSONTyped,
  StatusEnumToJSON,
  VesselTypeEnum,
  VesselTypeEnumFromJSON,
  VesselTypeEnumFromJSONTyped,
  VesselTypeEnumToJSON,
} from './';

/**
 *
 * @export
 * @interface SeaboVessel
 */
export interface SeaboVessel {
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  target: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  spireUpdateTimestamp: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  spireVesselId: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  countryFlag: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  draft: number | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  shipBuilder: string | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  beam: number | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  lengthOverall: number | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  dwtSummer: number | null;
  /**
   *
   * @type {Array<Intake>}
   * @memberof SeaboVessel
   */
  intakes: Array<Intake>;
  /**
   *
   * @type {Array<Station>}
   * @memberof SeaboVessel
   */
  stations?: Array<Station>;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  tonnesPerCentimetre: number | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  builtYear: number | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  grossTonnage: number | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  netTonnage: number | null;
  /**
   * Grain capacity in CBM
   * @type {number}
   * @memberof SeaboVessel
   */
  grain: number | null;
  /**
   *
   * @type {GrainUnitEnum}
   * @memberof SeaboVessel
   */
  grainUnit: GrainUnitEnum;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  imo: number;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  mmsi: number | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  callSign: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  managementHeadOwner: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  managementManagingOwner: string | null;
  /**
   *
   * @type {VesselTypeEnum}
   * @memberof SeaboVessel
   */
  vesselType: VesselTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  spireVesselType?: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  spireSubType?: string;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  generalAisVesselType?: string;
  /**
   * Bale capacity in CBM
   * @type {number}
   * @memberof SeaboVessel
   */
  bale: number | null;
  /**
   *
   * @type {BaleUnitEnum}
   * @memberof SeaboVessel
   */
  baleUnit: BaleUnitEnum;
  /**
   * Container hull design
   * @type {string}
   * @memberof SeaboVessel
   */
  designType: string | null;
  /**
   * Bulker hull design
   * @type {string}
   * @memberof SeaboVessel
   */
  designSubType: string | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  holdsAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  hatchesAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  reeferPlugs: number | null;
  /**
   *
   * @type {Array<Consumption>}
   * @memberof SeaboVessel
   */
  consumptions: Array<Consumption>;
  /**
   *
   * @type {Array<Gear>}
   * @memberof SeaboVessel
   */
  gears: Array<Gear>;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureLogFitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureLakesFitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureTweenDecks: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureA60Bulkhead: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureCo2Fitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureBoxShape: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureOpenHatch: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureItfFitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureBowThrust: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureSternThrust: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureCellular: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  featureScrubberFitted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  geared?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  grabFitted?: boolean;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  charterName: string | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  color: number | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  comment: string | null;
  /**
   *
   * @type {Company}
   * @memberof SeaboVessel
   */
  company: Company | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  confidenceScore: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  displayed: boolean;
  /**
   *
   * @type {Array<Document>}
   * @memberof SeaboVessel
   */
  documents: Array<Document>;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  dryDockFrom: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  dryDockTo: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  dryDockNote: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  exName: string | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  fixtureCount: number;
  /**
   *
   * @type {Array<Fixture>}
   * @memberof SeaboVessel
   */
  fixtures: Array<Fixture>;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  iceClass: string | null;
  /**
   *
   * @type {Array<Image>}
   * @memberof SeaboVessel
   */
  images: Array<Image>;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  isArchived: boolean;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  managementDisponentOwner: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  nextOpenFrom: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  nextOpenTo: string | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  rate: number | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  rateCurrency: string | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  rateTerms: string | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  redeliveryNotice: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  reviewed: boolean;
  /**
   *
   * @type {Fixture}
   * @memberof SeaboVessel
   */
  selectedFixture: Fixture | null;
  /**
   *
   * @type {string}
   * @memberof SeaboVessel
   */
  spotDate: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  spotState: boolean;
  /**
   *
   * @type {StatusEnum}
   * @memberof SeaboVessel
   */
  status: StatusEnum;
  /**
   *
   * @type {object}
   * @memberof SeaboVessel
   */
  user: object | null;
  /**
   *
   * @type {boolean}
   * @memberof SeaboVessel
   */
  usesFixtures: boolean;
  /**
   *
   * @type {Engine}
   * @memberof SeaboVessel
   */
  engine?: Engine | null;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  sternThrusterCount?: number;
  /**
   *
   * @type {number}
   * @memberof SeaboVessel
   */
  bowThrusterCount?: number;
}

export function SeaboVesselFromJSON(json: any): SeaboVessel {
  return SeaboVesselFromJSONTyped(json, false);
}

export function SeaboVesselFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaboVessel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    target: json['target'],
    source: json['source'],
    createdAt: json['createdAt'],
    updatedAt: json['updatedAt'],
    spireUpdateTimestamp: json['spireUpdateTimestamp'],
    spireVesselId: json['spireVesselId'],
    countryFlag: json['countryFlag'],
    name: json['name'],
    draft: json['draft'],
    shipBuilder: json['shipBuilder'],
    beam: json['beam'],
    lengthOverall: json['lengthOverall'],
    dwtSummer: json['dwtSummer'],
    intakes: (json['intakes'] as Array<any>).map(IntakeFromJSON),
    stations: !exists(json, 'stations') ? undefined : (json['stations'] as Array<any>).map(StationFromJSON),
    tonnesPerCentimetre: json['tonnesPerCentimetre'],
    builtYear: json['builtYear'],
    grossTonnage: json['grossTonnage'],
    netTonnage: json['netTonnage'],
    grain: json['grain'],
    grainUnit: GrainUnitEnumFromJSON(json['grainUnit']),
    imo: json['imo'],
    mmsi: json['mmsi'],
    callSign: json['callSign'],
    managementHeadOwner: json['managementHeadOwner'],
    managementManagingOwner: json['managementManagingOwner'],
    vesselType: VesselTypeEnumFromJSON(json['vesselType']),
    spireVesselType: !exists(json, 'spireVesselType') ? undefined : json['spireVesselType'],
    spireSubType: !exists(json, 'spireSubType') ? undefined : json['spireSubType'],
    generalAisVesselType: !exists(json, 'generalAisVesselType') ? undefined : json['generalAisVesselType'],
    bale: json['bale'],
    baleUnit: BaleUnitEnumFromJSON(json['baleUnit']),
    designType: json['designType'],
    designSubType: json['designSubType'],
    holdsAmount: json['holdsAmount'],
    hatchesAmount: json['hatchesAmount'],
    reeferPlugs: json['reeferPlugs'],
    consumptions: (json['consumptions'] as Array<any>).map(ConsumptionFromJSON),
    gears: (json['gears'] as Array<any>).map(GearFromJSON),
    featureLogFitted: json['featureLogFitted'],
    featureLakesFitted: json['featureLakesFitted'],
    featureTweenDecks: json['featureTweenDecks'],
    featureA60Bulkhead: json['featureA60Bulkhead'],
    featureCo2Fitted: json['featureCo2Fitted'],
    featureBoxShape: json['featureBoxShape'],
    featureOpenHatch: json['featureOpenHatch'],
    featureItfFitted: json['featureItfFitted'],
    featureBowThrust: json['featureBowThrust'],
    featureSternThrust: json['featureSternThrust'],
    featureCellular: json['featureCellular'],
    featureScrubberFitted: json['featureScrubberFitted'],
    geared: !exists(json, 'geared') ? undefined : json['geared'],
    grabFitted: !exists(json, 'grabFitted') ? undefined : json['grabFitted'],
    charterName: json['charterName'],
    color: json['color'],
    comment: json['comment'],
    company: CompanyFromJSON(json['company']),
    confidenceScore: json['confidenceScore'],
    displayed: json['displayed'],
    documents: (json['documents'] as Array<any>).map(DocumentFromJSON),
    dryDockFrom: json['dryDockFrom'],
    dryDockTo: json['dryDockTo'],
    dryDockNote: json['dryDockNote'],
    exName: json['exName'],
    fixtureCount: json['fixtureCount'],
    fixtures: (json['fixtures'] as Array<any>).map(FixtureFromJSON),
    iceClass: json['iceClass'],
    images: (json['images'] as Array<any>).map(ImageFromJSON),
    isArchived: json['isArchived'],
    managementDisponentOwner: json['managementDisponentOwner'],
    nextOpenFrom: json['nextOpenFrom'],
    nextOpenTo: json['nextOpenTo'],
    rate: json['rate'],
    rateCurrency: json['rateCurrency'],
    rateTerms: json['rateTerms'],
    redeliveryNotice: json['redeliveryNotice'],
    reviewed: json['reviewed'],
    selectedFixture: FixtureFromJSON(json['selectedFixture']),
    spotDate: json['spotDate'],
    spotState: json['spotState'],
    status: StatusEnumFromJSON(json['status']),
    user: json['user'],
    usesFixtures: json['usesFixtures'],
    engine: !exists(json, 'engine') ? undefined : EngineFromJSON(json['engine']),
    sternThrusterCount: !exists(json, 'sternThrusterCount') ? undefined : json['sternThrusterCount'],
    bowThrusterCount: !exists(json, 'bowThrusterCount') ? undefined : json['bowThrusterCount'],
  };
}

export function SeaboVesselToJSON(value?: SeaboVessel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    target: value.target,
    source: value.source,
    createdAt: value.createdAt,
    updatedAt: value.updatedAt,
    spireUpdateTimestamp: value.spireUpdateTimestamp,
    spireVesselId: value.spireVesselId,
    countryFlag: value.countryFlag,
    name: value.name,
    draft: value.draft,
    shipBuilder: value.shipBuilder,
    beam: value.beam,
    lengthOverall: value.lengthOverall,
    dwtSummer: value.dwtSummer,
    intakes: (value.intakes as Array<any>).map(IntakeToJSON),
    stations: value.stations === undefined ? undefined : (value.stations as Array<any>).map(StationToJSON),
    tonnesPerCentimetre: value.tonnesPerCentimetre,
    builtYear: value.builtYear,
    grossTonnage: value.grossTonnage,
    netTonnage: value.netTonnage,
    grain: value.grain,
    grainUnit: GrainUnitEnumToJSON(value.grainUnit),
    imo: value.imo,
    mmsi: value.mmsi,
    callSign: value.callSign,
    managementHeadOwner: value.managementHeadOwner,
    managementManagingOwner: value.managementManagingOwner,
    vesselType: VesselTypeEnumToJSON(value.vesselType),
    spireVesselType: value.spireVesselType,
    spireSubType: value.spireSubType,
    generalAisVesselType: value.generalAisVesselType,
    bale: value.bale,
    baleUnit: BaleUnitEnumToJSON(value.baleUnit),
    designType: value.designType,
    designSubType: value.designSubType,
    holdsAmount: value.holdsAmount,
    hatchesAmount: value.hatchesAmount,
    reeferPlugs: value.reeferPlugs,
    consumptions: (value.consumptions as Array<any>).map(ConsumptionToJSON),
    gears: (value.gears as Array<any>).map(GearToJSON),
    featureLogFitted: value.featureLogFitted,
    featureLakesFitted: value.featureLakesFitted,
    featureTweenDecks: value.featureTweenDecks,
    featureA60Bulkhead: value.featureA60Bulkhead,
    featureCo2Fitted: value.featureCo2Fitted,
    featureBoxShape: value.featureBoxShape,
    featureOpenHatch: value.featureOpenHatch,
    featureItfFitted: value.featureItfFitted,
    featureBowThrust: value.featureBowThrust,
    featureSternThrust: value.featureSternThrust,
    featureCellular: value.featureCellular,
    featureScrubberFitted: value.featureScrubberFitted,
    geared: value.geared,
    grabFitted: value.grabFitted,
    charterName: value.charterName,
    color: value.color,
    comment: value.comment,
    company: CompanyToJSON(value.company),
    confidenceScore: value.confidenceScore,
    displayed: value.displayed,
    documents: (value.documents as Array<any>).map(DocumentToJSON),
    dryDockFrom: value.dryDockFrom,
    dryDockTo: value.dryDockTo,
    dryDockNote: value.dryDockNote,
    exName: value.exName,
    fixtureCount: value.fixtureCount,
    fixtures: (value.fixtures as Array<any>).map(FixtureToJSON),
    iceClass: value.iceClass,
    images: (value.images as Array<any>).map(ImageToJSON),
    isArchived: value.isArchived,
    managementDisponentOwner: value.managementDisponentOwner,
    nextOpenFrom: value.nextOpenFrom,
    nextOpenTo: value.nextOpenTo,
    rate: value.rate,
    rateCurrency: value.rateCurrency,
    rateTerms: value.rateTerms,
    redeliveryNotice: value.redeliveryNotice,
    reviewed: value.reviewed,
    selectedFixture: FixtureToJSON(value.selectedFixture),
    spotDate: value.spotDate,
    spotState: value.spotState,
    status: StatusEnumToJSON(value.status),
    user: value.user,
    usesFixtures: value.usesFixtures,
    engine: EngineToJSON(value.engine),
    sternThrusterCount: value.sternThrusterCount,
    bowThrusterCount: value.bowThrusterCount,
  };
}
