import {TODO} from '../../../utils/TODO';

type Params = {
  item: TODO;
};

export const getVesselSizeScalar = (params: Params): number => {
  const {item} = params;
  let scalar = 1;

  switch (item.type) {
    case 'container': {
      const checkVal = item.teu ?? item.teuQuantity ?? item.teu14Quantity;
      scalar = checkVal / 15000;
      break;
    }
    case 'bulker': {
      const checkVal = item.dwt ?? item.dwtSummer;
      scalar = checkVal / 200000;
      break;
    }
    case 'mpp': {
      const checkVal = item.dwt ?? item.dwtSummer ?? item.teu ?? item.teuQuantity ?? item.teu14Quantity;
      scalar = checkVal / 200000;
      break;
    }
    default: {
      /*
     Other vessel types exits on the plattform but we havnt data about size, now.
      */
    }
  }

  if (scalar < 0.2) {
    scalar = 0.2;
  }
  if (scalar > 2) {
    scalar = 2 + (scalar - 2) * 0.2;
  }

  return scalar;
};
