/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CurrentUserOptions
 */
export interface CurrentUserOptions {
  /**
   *
   * @type {string}
   * @memberof CurrentUserOptions
   */
  timezone?: string;
}

export function CurrentUserOptionsFromJSON(json: any): CurrentUserOptions {
  return CurrentUserOptionsFromJSONTyped(json, false);
}

export function CurrentUserOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUserOptions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timezone: !exists(json, 'timezone') ? undefined : json['timezone'],
  };
}

export function CurrentUserOptionsToJSON(value?: CurrentUserOptions | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timezone: value.timezone,
  };
}
