/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CargoOffersForLocationResponseDataContractType
 */
export interface CargoOffersForLocationResponseDataContractType {
  /**
   *
   * @type {number}
   * @memberof CargoOffersForLocationResponseDataContractType
   */
  vc: number;
  /**
   *
   * @type {number}
   * @memberof CargoOffersForLocationResponseDataContractType
   */
  tct: number;
  /**
   *
   * @type {number}
   * @memberof CargoOffersForLocationResponseDataContractType
   */
  pc: number;
  /**
   *
   * @type {number}
   * @memberof CargoOffersForLocationResponseDataContractType
   */
  bc: number;
}

export function CargoOffersForLocationResponseDataContractTypeFromJSON(
  json: any
): CargoOffersForLocationResponseDataContractType {
  return CargoOffersForLocationResponseDataContractTypeFromJSONTyped(json, false);
}

export function CargoOffersForLocationResponseDataContractTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CargoOffersForLocationResponseDataContractType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vc: json['vc'],
    tct: json['tct'],
    pc: json['pc'],
    bc: json['bc'],
  };
}

export function CargoOffersForLocationResponseDataContractTypeToJSON(
  value?: CargoOffersForLocationResponseDataContractType | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vc: value.vc,
    tct: value.tct,
    pc: value.pc,
    bc: value.bc,
  };
}
