import {FeaturePermission} from '../api/symfony/generated';
import {useSelector} from '../redux/react-redux';
import {getUser} from '../redux/selectors';
import {isPermittedToUser} from '../model/Permissions';

/**
 * Returns whether the current user has the given permission.
 *
 */
export const useIsPermitted = (permission: FeaturePermission | undefined): boolean => {
  const user = useSelector(getUser);
  if (!user) {
    return false;
  }
  const isPermitted = isPermittedToUser(permission, user.payload);
  return isPermitted;
};
