import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import {Fixture} from '../../../screens/VesselDetailScreen/VesselFixtures/fixtureType';
import {GridState} from '../../../redux/FixtureData';
import {fetchJSON} from '../../../api/utils/fetchJSON';
import dayjs from 'dayjs';
import {VesselFixturesDatabaseFilter} from '../../FilterProvider/Filters/Fixtures/VesselFixturesFilterBranchDefinitions';
import produce from 'immer';

type FixtureListResponse = {
  totalItems: number;
  items: Fixture[];
};

export type FixturesQueryResult = UseQueryResult<FixtureListResponse>;

interface QueryParams {
  workspaceId: number;
  gridState: GridState;
  filter: VesselFixturesDatabaseFilter | null;
}

const FixturesQueryKey = 'getFixtures';

export const useFixturesQuery = (queryParams: QueryParams): FixturesQueryResult =>
  useQuery({
    queryKey: [FixturesQueryKey, queryParams],
    queryFn: () => loadFixtures(queryParams),
  });

const loadFixtures = async (queryParams: QueryParams): Promise<FixtureListResponse> => {
  const {workspaceId, gridState, filter} = queryParams;
  const workspaceIdForApi = workspaceId;
  const apiFilter = convertFilterToApi(filter);

  // TODO: Replace this handwritten code with vesselFixtureApi.getVesselFixtureList()
  const url = `/api/workspaces/${workspaceIdForApi}/vessel-fixtures?pageIndex=${gridState.pageIndex}&pageSize=${
    gridState.pageSize
  }&sortField=${gridState.sortField}&sortOrder=${gridState.sortOrder}${
    apiFilter ? `&filters=${JSON.stringify(apiFilter)}` : ''
  }`;
  const json = await fetchJSON<{data: FixtureListResponse}>(url);
  return json.data;
};

/**
 * Converts the filter to the API convention.
 *
 * Here we receive date strings, but we want to send timestamps that represents the start or end of the day.
 * This allows us to use the current user's timezone without the PHP backend having to know about it.
 *
 * @see FRIN-5341 for more details.
 */
const convertFilterToApi = (filter: VesselFixturesDatabaseFilter | null): VesselFixturesDatabaseFilter | null =>
  produce(filter, (draftFilter: VesselFixturesDatabaseFilter | null) => {
    if (!draftFilter?.fixtureDate?.date) {
      return;
    }
    if (draftFilter.updatedAtRange?.from) {
      draftFilter.updatedAtRange.from = startOfDay(draftFilter.updatedAtRange.from);
    }
    if (draftFilter.updatedAtRange?.to) {
      draftFilter.updatedAtRange.to = endOfDay(draftFilter.updatedAtRange.to);
    }
  });

const startOfDay = (dateString: string | null): string | null => {
  if (!dateString) {
    return null;
  }
  return dayjs(dateString).startOf('day').toISOString();
};

const endOfDay = (dateString: string | null): string | null => {
  if (!dateString) {
    return null;
  }
  return dayjs(dateString).endOf('day').toISOString();
};

export const invalidateFixturesQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [FixturesQueryKey],
  });
};
