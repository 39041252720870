import React, {useMemo} from 'react';
import {Button, Collapse} from 'antd';
import {MulticalcVesselInput, MulticalcCargoInput} from '../../types';
import {MultiCalculation} from '../../useMultiCalculation';
import {isCalculationWithResult} from '../../utils/isCalculationWithResult';
import {OneCalcResult} from '../../utils/oneCalc';
import {VesselToCargoTable} from './VesselToCargoTable';
import styled from 'styled-components';
import {useGoToUrl} from '../../../../../utils/useChangeBrowserUrl';
import {getCalcVesselSets} from './getCalcVesselSets';
import LoadingAnimation from '../../../../../atoms/Loading';
import {Center} from '../../../../Center/Center';
import {LoadingStatus} from '../components';

type Props = {
  vessels: MulticalcVesselInput[];
  multiCalculation: MultiCalculation;
  cargoes: MulticalcCargoInput[];
  isLoading?: boolean;
};

export const VesselToCargoScreen = ({vessels, multiCalculation, cargoes, isLoading}: Props) => {
  const goToUrl = useGoToUrl();

  const filteredBreakEvenCalcs: OneCalcResult[] = useMemo(() => {
    return (multiCalculation.breakEvenCalcs ?? [])
      .filter(calc => isCalculationWithResult(calc))
      .filter(item => item.voyageCalculationOutput.voyage.points.length >= 2);
  }, [multiCalculation.breakEvenCalcs]);

  const {successfulCalcVessels, erroneousCalcVessels, hiddenCalcVessels} = getCalcVesselSets(
    filteredBreakEvenCalcs,
    vessels
  );
  return (
    <div data-testid={'VesselToCargoScreen'} data-cy={'VesselToCargoScreen'}>
      <p>Compare Break-Even Calculations based on your selected Vessels.</p>
      {isLoading ? (
        <Center>
          <LoadingAnimation />
          <LoadingStatus />
        </Center>
      ) : (
        <>
          {successfulCalcVessels.length ? (
            <BorderBox>
              <VesselToCargoTable
                testId={'successful'}
                vessels={successfulCalcVessels}
                multiCalculation={multiCalculation}
                breakEvenCalcs={filteredBreakEvenCalcs}
                cargoes={cargoes}
              />
            </BorderBox>
          ) : (
            <PaddedBorderBox>
              <p>
                Sorry, there were no successful calculations. <br />
                Please try again later or select different Cargoes and/or Vessels. <br />
                <br />
                See below for more information.
              </p>
            </PaddedBorderBox>
          )}
          {erroneousCalcVessels.length ? (
            <StyledCollapse
              items={[
                {
                  key: 'errors',
                  label: `${erroneousCalcVessels.length} calculation${
                    erroneousCalcVessels.length !== 1 ? 's' : ''
                  } contained errors`,
                  children: (
                    <VesselToCargoTable
                      testId={'erroneous'}
                      vessels={erroneousCalcVessels}
                      multiCalculation={multiCalculation}
                      breakEvenCalcs={filteredBreakEvenCalcs}
                      cargoes={cargoes}
                    />
                  ),
                },
              ]}
            />
          ) : null}
          {multiCalculation.oneCalcResults !== undefined && hiddenCalcVessels.length > 0 ? (
            <StyledCollapse
              items={[
                {
                  key: 'hidden',
                  label: `${hiddenCalcVessels.length} calculation${
                    hiddenCalcVessels.length !== 1 ? 's' : ''
                  } could not be computed`,
                  children: (
                    <>
                      <HiddenCalcInfo>
                        MultiCalc has hidden{' '}
                        {hiddenCalcVessels.length !== 1 ? 'these Calculations' : 'this Calculation'} because{' '}
                        {hiddenCalcVessels.length !== 1 ? 'their' : 'its'} vessel could not be computed. Fix them and
                        reload to enable MultiCalc for these Vessels too.
                      </HiddenCalcInfo>
                      <ul>
                        {hiddenCalcVessels.map((calcVessel: MulticalcVesselInput) => (
                          <li key={calcVessel.vessel.id}>
                            <Button type={'link'} onClick={() => goToUrl(`/vessel/${calcVessel.vessel?.id}`)}>
                              Open vessel "{calcVessel.vessel.name}"
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </>
                  ),
                },
              ]}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

const BorderBox = styled.div`
  border: var(--border-light);
  border-radius: var(--border-radius);
  overflow: hidden;
`;

const PaddedBorderBox = styled(BorderBox)`
  padding: 20px 20px 5px;
`;

const StyledCollapse = styled(Collapse)`
  margin-top: 20px;

  .ant-collapse-content > .ant-collapse-content-box {
    overflow: auto;
    padding: 0;
  }
`;

const HiddenCalcInfo = styled.p`
  margin: 20px 20px 10px;
`;
