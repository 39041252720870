export const Atmosphere = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '232', height = '144'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 232 144">
    <g fill="none" fillRule="evenodd">
      <rect width="160" height="16" x="24" y="32" fill={primary} rx="8" />
      <rect width="160" height="16" x="48" fill={secondary} rx="8" />
      <rect width="160" height="16" x="72" y="64" fill={secondary} rx="8" />
      <rect width="160" height="16" y="96" fill={primary} rx="8" />
      <rect width="160" height="16" x="24" y="128" fill={secondary} rx="8" />
    </g>
  </svg>
);

export const Clouds = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '232', height = '144'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323 129" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={secondary}
        d="M180.8 53.5a26 26 0 0 0-40-16.5 44.7 44.7 0 0 0-83.4 14.8 26.2 26.2 0 0 0-29.6 14.6A19.4 19.4 0 0 0 0 83.7C0 94.4 8.7 103 19.4 103h150.5a26 26 0 0 0 10.9-49.5"
      />
      <path
        fill={primary}
        d="M301 57.3a37.7 37.7 0 0 0-58.1-24A64.8 64.8 0 0 0 122 54.8a38 38 0 0 0-42.8 21.1A28 28 0 0 0 39 101a28 28 0 0 0 28 28h218.3A37.7 37.7 0 0 0 301 57.3"
      />
    </g>
  </svg>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Cold = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '196', height = '166'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196 166" width={width} height={height}>
    <g fill="none" fillRule="evenodd" stroke={primary}>
      <path
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth="7"
        d="M98 132V78m28 27H72m45.8-19.4l-38.2 38.2m37.4.3L78.8 85.9"
      />
      <path
        strokeWidth="10"
        d="M100.6 7.4a3 3 0 0 0-5.1 0L6 156.4a3 3 0 0 0 2.6 4.5h179a3 3 0 0 0 2.5-4.5l-89.5-149z"
      />
    </g>
  </svg>
);

export const Drizzle = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '309', height = '171'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309 171" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke={secondary}
        strokeDasharray="8 16"
        strokeLinecap="round"
        strokeWidth="8"
        d="M71.598 144l-10.497 22.392M102.598 144l-10.497 22.392M132.598 144l-10.497 22.392"
      />
      <path
        fill={secondary}
        d="M155.229 32.574a26.017 26.017 0 0 0-14.521 4.4C133.085 23.277 118.42 14 101.573 14c-22.405 0-40.961 16.403-44.207 37.801a26.215 26.215 0 0 0-5.802-.653c-10.549 0-19.626 6.244-23.723 15.217a19.368 19.368 0 0 0-8.464-1.934C8.675 64.43 0 73.065 0 83.715 0 94.366 8.675 103 19.377 103H169.95C184.336 103 196 91.392 196 77.073c0-10.466-6.237-19.478-15.21-23.573-2.34-11.925-12.893-20.926-25.561-20.926z"
      />
      <path
        fill={primary}
        d="M249.923 26.921a37.69 37.69 0 0 0-21.04 6.378C217.838 13.446 196.588 0 172.177 0c-32.465 0-59.352 23.775-64.055 54.79a37.973 37.973 0 0 0-8.406-.946c-15.286 0-28.439 9.05-34.375 22.056a28.056 28.056 0 0 0-12.264-2.804C37.57 73.096 25 85.611 25 101.048 25 116.486 37.57 129 53.077 129h218.176C292.1 129 309 112.175 309 91.42c0-15.17-9.037-28.232-22.038-34.167-3.393-17.285-18.683-30.332-37.039-30.332z"
      />
    </g>
  </svg>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Extreme = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '249', height = '173'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 173" width={width} height={height}>
    <path
      fill={primary}
      fillRule="evenodd"
      d="M211.2 27.8c-11.1-1.5-23.6-2.7-36.3-3.4 5.7 3.4 7.1 7.2 7.1 10.1 0 4-2.8 9.8-16.1 14-7 2.3-15.9 3.8-25.4 4.3-21.9 3-53 5.2-72.3 5.2-6.7 0-12.8-.2-18.3-.5A292 292 0 0 0 132.5 68c64 0 109.8-15.5 115.8-30.7-1.5-1.8-11-6-37.1-9.5m17 33.6c-3 1.2-6 2.2-9.3 3.3A299.2 299.2 0 0 1 132.5 76c-32.6 0-63.3-4-86.4-11.3-9.5-3-17.3-6.4-23.3-10.1h.1C18.4 58 16 61.6 16 65c0 14.7 43.7 31 106.5 31S229 79.7 229 65c0-1.2-.3-2.4-.8-3.6m-39 48a83.4 83.4 0 0 1-20.4 9 248 248 0 0 1-72.3 9.6c-5.4 0-10.8-.1-16.1-.4 8.2 6.6 31.3 13.4 63.6 13.4 18.6 0 36-2.3 49-6.4 13.3-4.2 18-9.2 18-12.1 0-4.2-8-9.3-21.9-13.2m-96.5 33c-5-1.7-9.3-3.5-12.7-5.5v.1C68.3 139.6 62 143.5 62 146c0 4 16.6 12 47 12 24.4 0 39.9-5.1 45-9.2l-10 .2c-19.4 0-37.6-2.4-51.4-6.8m50.3 19.9h.1a136 136 0 0 1-47.7 3.3c-.6.4-1 .8-1.2 1.1 1.2 2 11 6.5 29.4 6.5s28.2-4.5 29.4-6.5c-.6-1.1-4-3-10-4.4m23.4-51.3c13-4 21.8-9.2 25.2-14.3a293.8 293.8 0 0 1-69 7.5c-30 0-58-3.7-79.3-10.4A69.4 69.4 0 0 1 14 78C5 82.5 0 87.4 0 92c0 6.5 10 13.5 26.6 18.8a240 240 0 0 0 69.9 9.2 240 240 0 0 0 69.8-9.2m-41.2-86.6a206.7 206.7 0 0 0-19.6 2.7C95.9 29.3 91 32.7 91 34.5c0 3.1 14.1 10.5 41.5 10.5S174 37.6 174 34.5c0-3.1-14.1-10.5-41.5-10.5-2.6 0-5 0-7.4.2M68 50c8.8 0 20-.5 31.6-1.3l-.6-.1C85.8 44.3 83 38.6 83 34.5c0-4 2.8-9.8 16.1-14 7-2.3 15.6-3.7 25-4.3a202 202 0 0 1 19-.8 551 551 0 0 1 72.4 4.9c15 2.2 24.7 4.5 30.8 6.9C235.2 13.2 191.5 0 132.5 0 63.8 0 16 18 16 34c0 2.9 1.5 5.8 4.4 8.7C26.1 46.5 39 50 68.2 50"
    />
  </svg>
);

export const Hail = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '309', height = '190'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309 190" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={secondary}
        d="M155.2 32.6a26 26 0 0 0-14.5 4.4 44.7 44.7 0 0 0-83.3 14.8 26.2 26.2 0 0 0-29.6 14.6A19.4 19.4 0 0 0 0 83.7C0 94.4 8.7 103 19.4 103h150.5a26 26 0 0 0 10.9-49.5 26 26 0 0 0-25.6-21z"
      />
      <path
        fill={primary}
        d="M250 27c-7.9 0-15.1 2.3-21.1 6.3A64.8 64.8 0 0 0 108 54.8a38 38 0 0 0-42.8 21.1A28 28 0 0 0 25 101a28 28 0 0 0 28 28h218.3A37.7 37.7 0 0 0 287 57.3a37.7 37.7 0 0 0-37-30.4z"
      />
      <g fill={primary}>
        <g transform="translate(60 144)">
          <circle cx="23" cy="5" r="5" />
          <circle cx="41" cy="5" r="5" />
          <circle cx="59" cy="5" r="5" />
          <circle cx="5" cy="5" r="5" />
        </g>
        <g transform="translate(50 162)">
          <circle cx="23" cy="5" r="5" />
          <circle cx="41" cy="5" r="5" />
          <circle cx="59" cy="5" r="5" />
          <circle cx="5" cy="5" r="5" />
        </g>
        <g transform="translate(60 180)">
          <circle cx="23" cy="5" r="5" />
          <circle cx="41" cy="5" r="5" />
          <circle cx="59" cy="5" r="5" />
          <circle cx="5" cy="5" r="5" />
        </g>
      </g>
    </g>
  </svg>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Hot = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '196', height = '166'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196 166" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke={primary}
        strokeWidth="10"
        d="M100.63 7.4a3 3 0 0 0-5.15 0L5.98 156.35a3 3 0 0 0 2.57 4.55h179a3 3 0 0 0 2.58-4.55L100.63 7.4z"
      />
      <path fill="#F00046" d="M104 109.4a11.5 11.5 0 1 1-10-.49V81h10v28.4z" />
      <path
        stroke={primary}
        strokeWidth="6"
        d="M92.95 109.79V73a4 4 0 0 1 4-4h3.1a4 4 0 0 1 4 4v36.79a11.28 11.28 0 0 1 5.95 9.9A11.4 11.4 0 0 1 98.5 131 11.4 11.4 0 0 1 87 119.7c0-4.27 2.4-7.99 5.95-9.91z"
      />
    </g>
  </svg>
);

export const Rain = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '309', height = '212'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 309 212">
    <g fill="none" fillRule="evenodd">
      <path
        fill={secondary}
        d="M155.2 32.6a26 26 0 0 0-14.5 4.4 44.7 44.7 0 0 0-83.3 14.8 26.2 26.2 0 0 0-29.6 14.6A19.4 19.4 0 0 0 0 83.7C0 94.4 8.7 103 19.4 103h150.5a26 26 0 0 0 10.9-49.5 26 26 0 0 0-25.6-21z"
      />
      <path
        fill={primary}
        d="M250 27c-7.9 0-15.1 2.3-21.1 6.3A64.8 64.8 0 0 0 108 54.8a38 38 0 0 0-42.8 21.1A28 28 0 0 0 25 101a28 28 0 0 0 28 28h218.3A37.7 37.7 0 0 0 287 57.3a37.7 37.7 0 0 0-37-30.4z"
      />
      <path
        stroke={secondary}
        strokeLinecap="round"
        strokeWidth="8"
        d="M92.6 144l-10.5 22.4m50.5-22.4l-10.5 22.4M72 144l-19 39m60.1-39L83 208"
      />
    </g>
  </svg>
);

export const Snow = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '309', height = '205'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 309 205">
    <g fill="none" fillRule="evenodd">
      <g
        stroke={primary}
        strokeWidth="4"
        transform="matrix(1 0 0 -1 120 170)"
        strokeLinecap="round"
        strokeLinejoin="bevel">
        <path d="M12 0L12 25M25 12L0 12M21.1923882 21.4852814L3.51471863 3.80761184M20.8388348 3.66116524L3.16116524 21.3388348" />
      </g>
      <g
        stroke={primary}
        strokeWidth="4"
        transform="matrix(1 0 0 -1 87 203)"
        strokeLinecap="round"
        strokeLinejoin="bevel">
        <path d="M12 0L12 25M25 12L0 12M21.1923882 21.4852814L3.51471863 3.80761184M20.8388348 3.66116524L3.16116524 21.3388348" />
      </g>
      <g
        stroke={primary}
        strokeWidth="4"
        transform="matrix(1 0 0 -1 54 170)"
        strokeLinecap="round"
        strokeLinejoin="bevel">
        <path d="M12 0L12 25M25 12L0 12M21.1923882 21.4852814L3.51471863 3.80761184M20.8388348 3.66116524L3.16116524 21.3388348" />
      </g>
      <path
        fill={secondary}
        d="M155.228805,32.5736358 C149.852916,32.5736358 144.857154,34.196004 140.707782,36.9738667 C133.085295,23.2765453 118.419766,14 101.57313,14 C79.1676588,14 60.6118712,30.4029214 57.3658363,51.8011781 C55.4993662,51.3782289 53.5595757,51.1479801 51.5642608,51.1479801 C41.0153592,51.1479801 31.9378512,57.3916179 27.8411558,66.3649433 C25.2841915,65.1265592 22.4118777,64.4308537 19.3772622,64.4308537 C8.67460113,64.4308537 0,73.0648279 0,83.7150726 C0,94.3660259 8.67460113,103 19.3772622,103 L169.949146,103 C184.336342,103 196,91.3919204 196,77.0732816 C196,66.6065274 189.763484,57.5949453 180.790618,53.5000597 C178.449344,41.574591 167.896883,32.5736358 155.228805,32.5736358 Z"
      />
      <path
        fill={primary}
        d="M249.923371,26.9213373 C242.133817,26.9213373 234.89506,29.2728597 228.882705,33.2992 C217.837876,13.4457791 196.587824,0 172.177392,0 C139.712322,0 112.825364,23.7750209 108.121926,54.7904716 C105.417449,54.1774328 102.606732,53.8437015 99.7155615,53.8437015 C84.4304185,53.8437015 71.2772945,62.8934687 65.3412666,75.8997493 C61.6362775,74.1047881 57.4743534,73.096406 53.0772575,73.096406 C37.56932,73.096406 25,85.6108179 25,101.04769 C25,116.485588 37.56932,129 53.0772575,129 L271.252845,129 C292.099597,129 309,112.174806 309,91.4208239 C309,76.2499104 299.963416,63.1881791 286.961916,57.2528955 C283.569458,39.9676657 268.279158,26.9213373 249.923371,26.9213373 Z"
      />
    </g>
  </svg>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Sunny = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '212', height = '212'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 212 212">
    <g fill="none" fillRule="evenodd">
      <circle cx="106" cy="106" r="106" fill="#E78B52" />
      <circle cx="105" cy="105" r="73" fill="#E99D46" />
      <circle cx="105" cy="105" r="39" fill="#EDC80D" />
    </g>
  </svg>
);

export const Thunderstorm = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '309', height = '198'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309 198" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={secondary}
        d="M155.2 32.6a26 26 0 0 0-14.5 4.4 44.7 44.7 0 0 0-83.3 14.8 26.2 26.2 0 0 0-29.6 14.6A19.4 19.4 0 0 0 0 83.7C0 94.4 8.7 103 19.4 103h150.5a26 26 0 0 0 10.9-49.5 26 26 0 0 0-25.6-21z"
      />
      <path
        fill={primary}
        d="M250 27c-7.9 0-15.1 2.3-21.1 6.3A64.8 64.8 0 0 0 108 54.8a38 38 0 0 0-42.8 21.1A28 28 0 0 0 25 101a28 28 0 0 0 28 28h218.3A37.7 37.7 0 0 0 287 57.3a37.7 37.7 0 0 0-37-30.4z"
      />
      <path
        fill="#FC0"
        d="M97.2 98h33a1 1 0 0 1 1 1.4l-12.1 28a1 1 0 0 0 1 1.5h23a1 1 0 0 1 .6 1.7l-67.4 66.8a1 1 0 0 1-1.6-1l19-49.1a1 1 0 0 0-.9-1.4H77a1 1 0 0 1-.9-1.4l20.3-45.9a1 1 0 0 1 1-.6z"
      />
    </g>
  </svg>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Windy = ({primary = '#ECF3FD', secondary = '#C6DCF9', width = '195', height = '165'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195 165" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke={primary}
        strokeWidth="10"
        d="M100 6.5a3 3 0 0 0-5 0l-89.6 149A3 3 0 0 0 8 160h179a3 3 0 0 0 2.6-4.5L100 6.5z"
      />
      <path
        fill={primary}
        d="M91.6 100H70a3 3 0 1 1 0-6h21.5a7 7 0 0 0 7.2-7 5 5 0 0 0-5.1-5 3.4 3.4 0 0 0-3.5 3.4 3 3 0 0 1-3.1 3 3 3 0 0 1-3.1-3c0-5.2 4.3-9.4 9.7-9.4 6.2 0 11.3 5 11.3 11 0 7.2-6 13-13.4 13zm12.3 38a9.5 9.5 0 0 1-9.4-9.4 3 3 0 0 1 3-3 3 3 0 0 1 3 3c0 1.8 1.6 3.3 3.4 3.3a5 5 0 0 0 5-4.9 7 7 0 0 0-7-7H70a3 3 0 0 1-3.1-3 3 3 0 0 1 3-3H102c7.2 0 13.1 5.8 13.1 13 0 6-5 11-11 11zM70 110a3 3 0 0 1-3-3 3 3 0 0 1 3-3h46.2a7 7 0 0 0 6.9-7 5 5 0 0 0-4.9-5 3.3 3.3 0 0 0-3.3 3.4 3 3 0 0 1-3 3 3 3 0 0 1-3-3c0-5.2 4.2-9.4 9.3-9.4A11 11 0 0 1 129 97a13 13 0 0 1-12.8 13H70z"
      />
    </g>
  </svg>
);

const mapping = {
  atmosphere: Atmosphere,
  clouds: Clouds,
  cold: Cold,
  drizzle: Drizzle,
  extreme: Extreme,
  hail: Hail,
  hot: Hot,
  rain: Rain,
  snow: Snow,
  sunny: Sunny,
  thunderstorm: Thunderstorm,
  windy: Windy,
};

export type IconWeatherIds = keyof typeof mapping;

export const getIconById = (id?: IconWeatherIds) => {
  if (id) {
    return mapping[id];
  }
  return null;
};
