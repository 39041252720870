import React, {FC, useState} from 'react';
import {Button} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {AntdUpload} from '../../../components/AntdUpload/AntdUpload';
import {getAjaxCsrfTokenOrFail} from '../../../api/utils/api';
import {RcFile, UploadChangeParam} from 'antd/es/upload';

export const UploadAvatarButton: FC<{
  onChange?: (info: UploadChangeParam) => void;
  onValidationError?: (errors: string[]) => void;
}> = ({onChange, onValidationError}) => {
  const [uploading, setUploading] = useState(false);
  const headers = {
    Accept: 'application/json',
    'X-Ajax-Csrf-Token': getAjaxCsrfTokenOrFail(),
  };

  const beforeUpload = (file: RcFile): boolean => {
    const validationErrors: string[] = [];
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      validationErrors.push('You can only upload JPG, PNG, or GIF files');
    }
    const maxSizeInMb = 5;
    if (file.size / 1024 > maxSizeInMb * 1000 * 1000) {
      validationErrors.push(`Image must be smaller than ${maxSizeInMb}MB`);
    }
    onValidationError?.(validationErrors);
    return validationErrors.length === 0;
  };

  return (
    <AntdUpload
      name="file"
      modalTitle={'Edit profile picture'}
      showUploadList={false}
      action="/api/account-management/avatar"
      headers={headers}
      beforeUpload={beforeUpload}
      onChange={info => {
        if (info.file.status === 'uploading') {
          setUploading(true);
        }
        if (info.file.status === 'done') {
          setUploading(false);
        } else if (info.file.status === 'error') {
          setUploading(false);
        }
        onChange?.(info);
      }}
      data-testid="Upload">
      <Button type="primary" loading={uploading}>
        <UploadOutlined />
        Upload
      </Button>
    </AntdUpload>
  );
};
