import {FilterTypeDefault, ValidationErrors} from '../atoms/Filter/types';
import {IconType} from '../atoms/Icon';

export const cleanIdentifier = (identifer: string | null | undefined, defaultName = ''): string | undefined =>
  identifer ? `${defaultName}-${identifer}`.replace(' ', '-') : undefined;

export const clamp = (value: string | number, min = 0, max = 99.99): number => {
  const number = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(number) ? min : Math.min(Math.max(number, min), max);
};

export const percentCalc = (act: number | null, max: number | null): number | null => {
  if (act === null || max === null || max === 0) {
    return null;
  }
  return Math.round((act / max) * 100);
};

export const hasError = (field: string, validations: ValidationErrors<FilterTypeDefault>): boolean =>
  !!validations[field];

export const getErrorMessage = (
  field: string,
  validations: ValidationErrors<FilterTypeDefault>
): string | undefined => {
  return validations[field];
};

export const getCompassDirection = (
  degrees: number | null | undefined
): 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW' | 'N/A' => {
  if (!degrees) {
    return 'N/A';
  }

  const normalizedDegrees = degrees + (22.5 % 360);

  if (normalizedDegrees < 45) {
    return 'N';
  } else if (normalizedDegrees < 90) {
    return 'NE';
  } else if (normalizedDegrees < 135) {
    return 'E';
  } else if (normalizedDegrees < 180) {
    return 'SE';
  } else if (normalizedDegrees < 225) {
    return 'S';
  } else if (normalizedDegrees < 270) {
    return 'SW';
  } else if (normalizedDegrees < 315) {
    return 'W';
  } else {
    return 'NW';
  }
};

export const getCompassDirectionLabel = (
  compassDirection: 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW' | 'N/A'
) => {
  switch (compassDirection) {
    case 'N':
      return 'North';
    case 'NE':
      return 'North East';
    case 'E':
      return 'East';
    case 'SE':
      return 'South East';
    case 'S':
      return 'South';
    case 'SW':
      return 'South West';
    case 'W':
      return 'West';
    case 'NW':
      return 'North West';
    default:
      return 'N/A';
  }
};

export const getCargoContractTypeLabel = (type: string): string => {
  switch (type) {
    case 'vc':
      return 'Voyage Charter';
    case 'tct':
      return 'Time Charter Trip';
    case 'pc':
      return 'Period Charter';
    case 'bc':
      return 'Bareboat Charter';
    default:
      return 'N/A';
  }
};

export const getNameFromIceClass = (value: string): string => {
  switch (value) {
    case '1as':
      return '1A Super';
    case '1a':
      return '1A';
    case '1b':
      return '1B';
    case '1c':
      return '1C';
    case '1d':
      return '1D';
    case 'e':
      return 'E';
    default:
      return value;
  }
};

// new function to get all "icon names" for icon component all 25 categories if something will change
export const getIconForCommodity = (key: string): IconType => {
  switch (key) {
    case 'organic':
      return 'organic-product';
    case 'agricultural':
      return 'agricultural-product';
    case 'stone-aggregate':
      return 'stone';
    case 'steels-generals':
      return 'steel';
    case 'feedstock':
      return 'foodstock';
    case 'vehicles':
      return 'vehicle';
    case 'paper':
      return 'paper-product';
    case 'iron-ore':
      return 'iron-ore-products';
    case 'sulphur':
      return 'sulphor';
    case 'minerals':
      return 'mineral';
    case 'forest-products':
    case 'ash':
    case 'chemical':
    case 'coal':
    case 'concentrate':
    case 'fertilizer':
    case 'grain':
    case 'metal':
    case 'ore':
    case 'petcoke':
    case 'salt':
    case 'scrap':
    case 'sugar':
    case 'slag':
      return key;
    case 'generals':
    default:
      return 'other';
  }
};

export const transformFileSize = (val: number, precision = 2): string => {
  let symbol = 'Byte';
  const factor = Math.pow(10, precision);
  if (val >= 1000) {
    val /= 1000;
    symbol = 'kB';
  }
  if (val >= 1000) {
    val /= 1000;
    symbol = 'MB';
  }
  if (val >= 1000) {
    val /= 1000;
    symbol = 'GB';
  }
  if (val >= 1000) {
    val /= 1000;
    symbol = 'TB';
  }
  return String(Math.round(val * factor) / factor + ' ' + symbol);
};
