import {useState} from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {useMapApi} from '../../components/SeaboMap/useMapApi';
import {AddWidgetModal} from './AddWidgetModal/AddWidgetModal';
import './style.scss';
import {HeroMap} from './widgets/HeroMap';
import {Space} from 'antd';
import {DashboardTourDefinition} from './DashboardTourDefinition';
import {useWidgetsApi} from './DashboardWidgets/useWidgetsApi';
import {DashboardWidgets} from './DashboardWidgets/DashboardWidgets';

export const DashboardScreen = () => {
  const [addWidgetModalVisible, setAddWidgetModalVisible] = useState(false);
  const widgetsApi = useWidgetsApi();
  const mapApi = useMapApi();

  return (
    <div className="dashboard" data-testid="Dashboard">
      <AddWidgetModal
        visible={addWidgetModalVisible}
        onClose={() => setAddWidgetModalVisible(false)}
        onChoose={widget => widgetsApi.addWidget(widget)}
      />
      <ScreenHeader
        helmetTitle="Dashboard"
        breadcrumbs={[{title: 'Dashboard'}]}
        tourDefinition={DashboardTourDefinition}
        actions={
          <Space size={4}>
            <ScreenHeader.Actions.Button
              disabled={widgetsApi.loading}
              onClick={() => setAddWidgetModalVisible(true)}
              data-cy="DashboardAddBTN">
              Add widget
            </ScreenHeader.Actions.Button>
          </Space>
        }
      />
      <HeroMap map={mapApi} />
      <DashboardWidgets widgetsApi={widgetsApi} />
    </div>
  );
};
