import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {numberFormat} from '../../../../utils/formatter';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';

const dwtAccessor =
  (type: RowValueType): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return (
      <div className={'market-grid-cell'} data-cy="cell-size">
        <div className={'market-grid-cell-content'}>{numberFormat(vessel.dwtSummer, {emptyString: '-'})}</div>
      </div>
    );
  };
export const dwtColumn = ({
  type,
  minWidth = 6,
  sortable = true,
}: {
  type?: RowValueType;
  minWidth?: number;
  sortable?: boolean;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-size">
      <div>
        Size
        <br />
        (DWT)
      </div>
    </div>
  ),

  id: 'dwtSummer',
  accessorFn: dwtAccessor(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
  enableSorting: sortable,
});
