import DateTime from '../../model/DateTime';
import {assertUnreachable} from '../../utils/assert';

export type DateTimeSource = 'unix' | 'js' | 'server';

export const sourceMapping = (source: DateTimeSource, dateTime: string, tz: string): DateTime => {
  switch (source) {
    case 'unix':
      return DateTime.fromServerTimeStamp(dateTime, tz);
    case 'js':
      return DateTime.fromJsTimeStamp(dateTime, tz);
    case 'server':
      return DateTime.fromServerResponse(dateTime, undefined, tz);
    default:
      assertUnreachable(source);
  }
};
