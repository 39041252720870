import {Alert} from 'antd';
import dayjs from 'dayjs';
import {useEffect, useState, FC} from 'react';
import {useSelector} from '../../../../redux/react-redux';
import {StyledCard} from '../components/StyledCard';
import {WidgetTitle} from '../components/WidgetTitle';
import {CompetitorsBody} from './CompetitorsBody';
import {useGetCompetitorsMutation} from './useGetCompetitorsMutation';
import {DataSourceVessel} from './generateDataSourceVessel';

export const CompetitorsCard: FC = () => {
  const [showBody, setShowBody] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [resultVessels, setResultVessels] = useState<DataSourceVessel[] | undefined>(undefined);

  const loadingPort = useSelector(state =>
    state.charterCalculation.inputState.voyage.points.find(point => point.types.includes('loading'))
  );

  const nextOpenPort = useSelector(state =>
    state.charterCalculation.inputState.voyage.points.find(point => point.types.includes('nextOpen'))
  );

  const laycanTo = useSelector(state => state.charterCalculation.inputState.voyage.laycanTo);
  const inputState = useSelector(state => state.charterCalculation.inputState);

  const minDwt = useSelector(state => state.charterCalculation.inputState.cargo.cargoQuantity);
  const maxDwt = (minDwt ?? 0) * 1.5;

  const getCompetitorsMutation = useGetCompetitorsMutation({
    setProgress,
    setIsLoading,
    options: {
      onSuccess: result => {
        setResultVessels(result);
      },
    },
  });

  const laycanToInNext21Days = laycanTo && laycanTo.isBetween(dayjs().startOf('day'), dayjs().add(21, 'days'));

  useEffect(() => {
    setIsLoading(false);
    setResultVessels(undefined);
  }, [minDwt, laycanTo, loadingPort?.originalPort?.id, inputState]);

  return (
    <StyledCard
      style={{width: '100%', paddingTop: 10}}
      $showBody={showBody}
      title={
        <WidgetTitle
          icon="database"
          title="Potential competitors"
          showBody={showBody}
          onToggleBody={() => {
            setShowBody(!showBody);
          }}
        />
      }>
      {showBody ? (
        <CompetitorsBody
          progress={progress}
          toDate={laycanTo}
          maxDwt={maxDwt}
          minDwt={minDwt}
          getCompetitors={async params => {
            await getCompetitorsMutation.mutateAsync(params);
          }}
          loadingPort={loadingPort!}
          resultVessels={resultVessels}
          isLoading={isLoading}
        />
      ) : (
        <div>
          {minDwt === 0 && <Alert type={'error'} description={'Please the cargo size.'} />}
          {!laycanToInNext21Days && <Alert type={'error'} description={'Laycan must be in the next 21 days.'} />}
          {!nextOpenPort && <Alert type={'error'} description={'Please select a next open port.'} />}
          {!laycanTo && <Alert type={'error'} description={'Please select laycan to.'} />}
          {!loadingPort && <Alert type={'error'} description={'Please select a loading port.'} />}
        </div>
      )}
    </StyledCard>
  );
};
