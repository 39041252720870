import {AsyncCreateable} from '../../../../../../atoms/Select/AsyncCreateable';
import {vesselDisplay} from '../../../../../../atoms/DropdownDisplay/vesselDisplay';
import {DropdownSingleValue, GroupHeading} from '../../../../../../atoms/Select/otherComponents';
import React, {FC, useEffect} from 'react';
import {Form} from 'antd';
import {useDispatch, useSelector} from '../../../../../../redux/react-redux';
import autocompleteApi from '../../../../../../redux/ApiService/autocomplete';
import {FormValues} from '../../FormValues';
import {TODO} from '../../../../../../utils/TODO';
import {useThrottle} from '../../../../../../utils/useThrottle';
import {setHiddenVesselFields} from './setHiddenVesselFields';

export const VesselPicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({onChange, value}) => {
  // ITS is not perfect
  const vesselNameFromProps = value;
  const form = Form.useFormInstance<FormValues>();

  const contractType = Form.useWatch<FormValues['contractType']>('contractType', form) ?? '';

  const resetHiddenVesselFields = () => {
    form.setFieldsValue({
      vesselType: 'bulker',
      designSubType: '',
      designType: '',
      vessel: null,
      dwat: null,
      isGeared: null,
      teuQuantity: null,
      sellerName: null,
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(autocompleteApi.preFillSearchVessels());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preFilledVessels = useSelector(state => state.preFill.vessels);

  const debouncedSearch = useThrottle<(searchString: string) => Promise<TODO[]>>(
    async (searchString: string) => {
      if (searchString.length > 0) {
        return await dispatch(
          autocompleteApi.searchAllVessels(searchString, {
            'param[workspaces]': 'all',
          })
        );
      } else {
        return [];
      }
    },
    [dispatch, autocompleteApi.searchAllVessels],
    1000
  );

  return (
    <>
      <AsyncCreateable
        id={`form-general-vessel-name`}
        name={`form-general-vessel-name`}
        value={{
          name: vesselNameFromProps,
        }}
        // @ts-expect-error is not assignable to type
        onChange={(value: VesselFromApi | {value: string} | null) => {
          // On clear
          if (!value) {
            onChange?.(null);
            resetHiddenVesselFields();
            return;
          }

          // If selected vessel is a freetext string (not from the dropdown)
          if ('value' in value) {
            // changes the value of the vesselName field
            onChange?.(value.value);
            resetHiddenVesselFields();
            return;
          }

          // Is selected from dropdown
          // Changes the value of the vesselName field
          onChange?.(value.name);
          setHiddenVesselFields(form, contractType, value);
        }}
        getOptionValue={(option: $TSFixMe) => (option.name ? option.name : option.value)}
        getOptionLabel={(option: $TSFixMe) => (option.name ? vesselDisplay(option, option.highlight) : option.value)}
        defaultOptions={preFilledVessels ?? []}
        noOptionsMessage={() => {
          // this.handleNoopMsg((this.props as $TSFixMe).preFillError);
          return '';
        }}
        loadOptions={searchString => debouncedSearch(searchString)!}
        isClearable
        createLabel={(value: $TSFixMe) => value}
        createOptionPosition={'first'}
        placeholder={'Type to search...'}
        loadingMessage={() => 'Searching ...'}
        components={{GroupHeading, SingleValue: DropdownSingleValue}}
        autoFocus={false}
      />
    </>
  );
};
