import React, {Component} from 'react';
import {connect} from 'react-redux';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import Upload, {acceptDocs} from '../../../components/Upload';
import {actions as attachmentApi} from '../../../redux/ApiService/AttachmentService';
import DocumentsGrid from '../../../components/DocumentsGrid';
import {RootState} from '../../../redux/store';
import {TODO} from '../../../utils/TODO';

const Wrapper = BindToProvider(
  'Documents',
  'documents',
  {
    documents: [],
  },
  {},
  {},
  true
)(WrappedDetailsSection);
type DocumentsState = $TSFixMe;

type DocumentsProps = {
  uploadType: TODO;
  maxSize?: number;
};

class Documents extends Component<DocumentsProps, DocumentsState> {
  constructor(props: DocumentsProps) {
    super(props);
    this.state = {
      data: (props as $TSFixMe).isEdit ? (props as $TSFixMe).form.documents.documents : [],
      errors: [],
    };
  }
  _handleUpload = (files: $TSFixMe, onChange: $TSFixMe) => {
    const {uploadType} = this.props;
    const formData = new FormData();
    files.forEach((document: $TSFixMe, key: $TSFixMe) => {
      formData.append(`fileName${key}`, document);
    });
    (this.props as $TSFixMe).upload(formData, uploadType).then((res: $TSFixMe) => {
      if (res?.data?.items?.length > 0 || res?.errors) {
        this.setState(
          (prev: $TSFixMe) => ({
            data: [...prev.data, ...res.data.items],
            errors: res.errors,
          }),
          () => onChange('documents', this.state.data)
        );
      }
    });
  };
  _handleRemove = (idx: $TSFixMe, onChange: $TSFixMe) => {
    this.setState(
      (prev: $TSFixMe) => ({
        data: prev.data.filter((item: $TSFixMe, key: $TSFixMe) => key !== idx),
      }),
      () => onChange('documents', this.state.data)
    );
  };
  render() {
    return (
      <Wrapper {...this.props}>
        {({onChange, values}: $TSFixMe) => {
          return (
            <div className="container-fluid">
              <div className="row">
                <div className="scol-12 scol-sm-12">
                  <DocumentsGrid
                    documents={values.documents}
                    noText
                    deletable
                    onRemove={(idx: $TSFixMe) => this._handleRemove(idx, onChange)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="scol-12">
                  <Upload
                    headline="Add file"
                    subline={'Drag and drop your files here or click to upload'}
                    accept={acceptDocs}
                    multiple
                    onDrop={files => this._handleUpload(files, onChange)}
                    loading={(this.props as $TSFixMe).uploading}
                    maxSize={this.props.maxSize}
                    uploadErrors={this.state.errors}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Wrapper>
    );
  }
}
const mapStateToProps = ({api}: RootState) => ({
  uploading: api.attachmentService.uploadVesselDocuments.loading || api.attachmentService.uploadCargoDocuments.loading,
});
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  upload: (body: $TSFixMe, uploadType: $TSFixMe) =>
    dispatch(attachmentApi[uploadType === 'vessel' ? 'uploadVesselDocuments' : 'uploadCargoDocuments']({body})),
});
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
