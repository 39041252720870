import {FilterProviderState} from '../components/FilterProvider/FilterProviderState';
import {
  PortfolioCargoDatabaseFilter,
  PortfolioCargoFilterBranchDefinitions,
} from '../components/FilterProvider/Filters/Portfolio/PortfolioCargoFilterBranchDefinitions';
import {
  PortfolioVesselDatabaseFilter,
  PortfolioVesselFilterBranchDefinitions,
} from '../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';
import {FilterItem} from '../api/symfony/generated';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface PortfolioFiltersState {
  vesselFilterId: number | null;
  vesselFilterSettings: PortfolioVesselDatabaseFilter | null;
  vesselFilterProvider: FilterProviderState<typeof PortfolioVesselFilterBranchDefinitions> | null;
  renewedVesselFilter: boolean;

  cargoFilterId: number | null;
  cargoFilterSettings: PortfolioCargoDatabaseFilter | null;
  cargoFilterProvider: FilterProviderState<typeof PortfolioCargoFilterBranchDefinitions> | null;
  renewedCargoFilter: boolean;
}

const initialState: PortfolioFiltersState = {
  vesselFilterId: null,
  vesselFilterSettings: null,
  vesselFilterProvider: null,
  renewedVesselFilter: false,

  cargoFilterId: null,
  cargoFilterSettings: null,
  cargoFilterProvider: null,
  renewedCargoFilter: false,
};

const PortfolioFiltersSlice = createSlice({
  name: 'PortfolioFilters',
  initialState,
  reducers: {
    setVesselFilterSettings(state, action: PayloadAction<PortfolioVesselDatabaseFilter>) {
      state.vesselFilterSettings = action.payload;
    },
    setVesselFilterFromApi(state, action: PayloadAction<FilterItem>) {
      const filter: FilterItem = action.payload;
      state.vesselFilterId = filter.id;
      state.renewedVesselFilter = true;
      state.vesselFilterSettings = {
        ...state.vesselFilterSettings,
        ...filter.filterSettings,
      };
    },
    setVesselDeeplinkFilterSettings(state, action: PayloadAction<PortfolioVesselDatabaseFilter>) {
      state.vesselFilterId = -1;
      state.renewedVesselFilter = true;
      state.vesselFilterSettings = action.payload;
    },
    setVesselFilterProviderState(
      state,
      action: PayloadAction<FilterProviderState<typeof PortfolioVesselFilterBranchDefinitions>>
    ) {
      state.renewedVesselFilter = false;
      state.vesselFilterProvider = action.payload;
    },
    setCargoFilterSettings(state, action: PayloadAction<PortfolioCargoDatabaseFilter>) {
      state.cargoFilterSettings = action.payload;
    },
    setCargoFilterFromApi(state, action: PayloadAction<FilterItem>) {
      const filter: FilterItem = action.payload;
      state.cargoFilterId = filter.id;
      state.renewedCargoFilter = true;
      state.cargoFilterSettings = {
        ...state.cargoFilterSettings,
        ...filter.filterSettings,
      };
    },
    setCargoFilterProviderState(
      state,
      action: PayloadAction<FilterProviderState<typeof PortfolioCargoFilterBranchDefinitions>>
    ) {
      state.renewedCargoFilter = false;
      state.cargoFilterProvider = action.payload;
    },
  },
});

export const PortfolioFiltersActions = PortfolioFiltersSlice.actions;
export const portfolioFiltersReducer = PortfolioFiltersSlice.reducer;
