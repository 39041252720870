import {SearchHit} from '../../../api/symfony/generated/models/SearchHit';
import {TradingAreaOption} from '../Type';
import {TradingArea} from '../../../redux/ApiService/MapService/GetTradingAreasResponse';

export const makeTradingAreaOption = (searchHit: SearchHit): TradingAreaOption => {
  const source = searchHit.source as TradingArea;
  return {
    id: source.id,
    key: source.type + '_' + source.id,
    type: source.type,
    source: source,
    value: `${source.name}`,
    label: (
      <div
        data-testid="tradingAreaPickerOption"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <span>{source.name}</span>
      </div>
    ),
  };
};
