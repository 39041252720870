import {z} from 'zod';
import {CoordinatesSchema} from '../../../utils/Coordinates';

export const TradingAreaSchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  coordinates: CoordinatesSchema,
  type: z.literal('tradingarea'),
  polygon: z.array(z.array(CoordinatesSchema)),
  aliases: z.optional(z.array(z.string())),
});
export type TradingArea = z.infer<typeof TradingAreaSchema>;
export const GetTradingAreasResponseSchema = z.object({
  data: z.object({
    items: z.array(TradingAreaSchema),
    totalItems: z.number(),
  }),
});
export type GetTradingAreasResponse = z.infer<typeof GetTradingAreasResponseSchema>;

export type GetTradingAreasResponseData = GetTradingAreasResponse['data'];
