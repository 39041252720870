export const steps = {
  label: 'Enter information',
  sections: [
    'marketSegment',
    'contractType',
    'charterer',
    'commodity',
    'quantity',
    'vesselFeatures',
    'laycan',
    'stations',
    'documents',
    'comment',
    'commission',
  ],
};
