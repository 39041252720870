import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {NegotiationRoundResult, ReplyTimeUpdate} from '../../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useSetReplyTimeMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {negotiationId: string; replyTime: ReplyTimeUpdate}>
) =>
  useMutation({
    mutationFn: async ({negotiationId, replyTime}) => {
      const result = await negotiationNegotiationApi.setReplyTime({negotiationId, setReplyTimeRequest: {replyTime}});
      return result;
    },
    ...options,
  });
