import {AISRecord} from './AISRecord';

/**
 * Improves the quality of the given AIS records.
 */
export const improveDataQuality = (aisRecords: AISRecord[]) => {
  const updatedAisRecords = aisRecords.filter(isValidAisRecord);
  return updatedAisRecords;
};

const MAX_VALID_SPEED = 60;

const isValidAisRecord = (aisRecord: AISRecord): boolean => {
  return aisRecord.speed <= MAX_VALID_SPEED;
};
