import lodashMap from 'lodash/map';

export const getLocationString = (stations: $TSFixMe, type: $TSFixMe) => {
  const locations = !stations
    ? []
    : stations.reduce(
        (tally: $TSFixMe, station: $TSFixMe) =>
          station.type === type ? [...tally, ...lodashMap(station.locations, location => location.name)] : tally,
        []
      );
  return locations.length > 0 ? locations.join(', ') : '-';
};
