import {FC} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useClearClipboardOnToggleArchive} from '../../components/useClearClipboardOnToggleArchive/useClearClipboardOnToggleArchive';
import {MyFleetArchiveScreen} from './MyFleetScreen/MyFleetArchiveScreen';
import {MyFleetScreen} from './MyFleetScreen/MyFleetScreen';

export const MyFleetScreens: FC = () => {
  useClearClipboardOnToggleArchive({
    section: 'portfolio',
    subsection: 'vessels',
  });
  return (
    <Routes>
      <Route path="/" element={<MyFleetScreen />} />
      <Route path="/archive" element={<MyFleetArchiveScreen />} />
    </Routes>
  );
};
