import {FC, useState} from 'react';
import {Checkbox, Input, Modal, ModalProps, Space} from 'antd';
import {ModalWidth} from '../../../../../antd/components/Modal';

type ConfirmCancelNegotiationModalProps = Omit<ModalProps, 'title' | 'width' | 'okType' | 'onOk' | 'cancelText'> & {
  showCreateNewCheckbox?: boolean;
  onOk: (reason?: string, shouldCreateNew?: boolean) => void;
};

export const ConfirmCancelNegotiationModal: FC<ConfirmCancelNegotiationModalProps> = ({
  onOk,
  showCreateNewCheckbox = true,
  ...restProps
}) => {
  const [reason, setReason] = useState<string>('');
  const [shouldCreateNew, setShouldCreateNew] = useState<boolean>(false);

  return (
    <Modal
      title={'Are you sure you want to cancel this negotiation?'}
      okType="danger"
      width={ModalWidth.Middle}
      onOk={() => onOk(reason, shouldCreateNew)}
      cancelText="Abort"
      {...restProps}>
      <p>By cancelling the negotiation, you will lose all progress and the negotiation will be closed.</p>
      <p>Do you want to add a reason for the cancellation?</p>
      <Space direction="vertical" size="small">
        <Input
          type="text"
          placeholder="Reason for cancellation (optional)"
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
        {showCreateNewCheckbox && (
          <Checkbox checked={shouldCreateNew} onChange={e => setShouldCreateNew(e.target.checked)}>
            Re-open a new negotiation offer with the current infos and terms after cancellation?
          </Checkbox>
        )}
      </Space>
    </Modal>
  );
};
