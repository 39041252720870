import {FC, useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../../../../api/utils/fetchJSON';
import {AsyncCreateable} from '../../../../../../atoms/Select/AsyncCreateable';
import {Form} from 'antd';
import {FormValues} from '../../FormValues';
import {useThrottle} from '../../../../../../utils/useThrottle';
import {LabeledValue} from '../../../../../../utils/LabeledValue';
import {TODO} from '../../../../../../utils/TODO';

export const ChartererPicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  const form = Form.useFormInstance<FormValues>();

  const chartererNamesQuery = useQuery({
    queryKey: ['chartererNames'],
    queryFn: () => fetchJSON<string[]>('/static/jsons/charterer-names.json'),
  });

  const allOptions = useMemo(
    () => chartererNamesQuery.data?.map(transformStringToLabeledValue) ?? [],
    [chartererNamesQuery.data]
  );

  const resetHiddenCargoFields = () => {
    form.setFieldsValue({
      cargo: null,
    });
  };

  const debouncedSearch = useThrottle<(searchString: string) => Promise<LabeledValue[]>>(
    async (searchString: string) => {
      if (searchString.length > 0) {
        const results = (
          chartererNamesQuery.data?.filter(option => option.toLowerCase().includes(searchString.toLowerCase())) ?? []
        ).map(transformStringToLabeledValue);
        return results;
      } else {
        return [];
      }
    },
    [],
    400
  );

  return (
    <AsyncCreateable
      id={'form-general-charterer-name'}
      value={{
        value: value ?? '',
        label: value ?? '',
      }}
      onChange={(item: {value: string} | null) => {
        resetHiddenCargoFields();

        onChange?.(item?.value ?? null);
      }}
      getOptionValue={(option: LabeledValue) => option.value}
      loadOptions={searchString => debouncedSearch(searchString)!}
      defaultOptions={allOptions}
      noOptionsMessage={() => {
        return '';
      }}
      isClearable
      createLabel={(value: TODO) => value}
      createOptionPosition={'first'}
      placeholder={'Type to search...'}
      loadingMessage={() => 'Searching ...'}
      autoFocus={false}
    />
  );
};

const transformStringToLabeledValue = (value: string): LabeledValue => ({value, label: value});
