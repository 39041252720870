import {LineLayer} from '@deck.gl/layers/typed';
import {Leg, Route} from './Route';

type Props = {
  route: Route;
  routeLayerVisible: boolean;
  onLegClick: (leg: Leg) => void;
};

export const useStaticRouteLayer = ({route, onLegClick, routeLayerVisible}: Props) => {
  return new LineLayer<Leg>({
    id: 'static-route-layer',
    visible: routeLayerVisible,
    data: route,
    getWidth: () => 4,
    getColor: d => (d.durationInMinutes > 30 ? [100, 0, 0] : [0, 200, 0]),
    getSourcePosition: d => d.from.aisRecord.coordinates,
    getTargetPosition: (d: Leg) => d.to.aisRecord.coordinates,

    autoHighlight: true,
    highlightColor: [0, 0, 128, 128],
    pickable: true,
    wrapLongitude: true,
    onClick: info => {
      const leg: Leg = info.object;
      // eslint-disable-next-line no-console
      console.log('onClick', leg);
      onLegClick(leg);
    },
  });
};
