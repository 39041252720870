/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Invitee
 */
export interface Invitee {
  /**
   *
   * @type {string}
   * @memberof Invitee
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Invitee
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof Invitee
   */
  email: string;
}

export function InviteeFromJSON(json: any): Invitee {
  return InviteeFromJSONTyped(json, false);
}

export function InviteeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invitee {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
  };
}

export function InviteeToJSON(value?: Invitee | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
  };
}
