import {CompositeLayer, CompositeLayerProps} from '@deck.gl/core/typed';
import {GeoJsonLayer, GeoJsonLayerProps, IconLayer} from '@deck.gl/layers/typed';
import iconAtlas from './icons/port-icon-atlas.svg';
import iconMapping from './icons/port-icon-mapping.json';
import {geoJsonLayerPropsDefaults} from '../const';
import {getGeoJsonLine} from '../helper';
import {TODO} from '../../../utils/TODO';
import {parseFloatTS} from '../../../utils/parseNumberTS';
import {Feature, LineString} from '@turf/helpers';
import {RGBAColor} from '../../../utils/deck.gl/deck.gl-types';
import {FeatureCollection} from 'geojson';

interface Station {
  latitude: number;
  longitude: number;
  resultType: string;
  name: string;
}

const iconLayerProps = {
  getSize: 70,
  iconAtlas,
  iconMapping,
  opacity: 1,
};

type Props = CompositeLayerProps & {
  stations: Station[];
  routes: TODO[];
};

export class StationsLayer extends CompositeLayer<Props> {
  renderLayers() {
    const {stations, routes} = this.props;
    const positions = [...stations];
    positions.shift();

    const data: FeatureCollection = {
      type: 'FeatureCollection',
      features: routes.map(r =>
        getGeoJsonLine<{color: RGBAColor}>(r.coords, {color: r.success ? [72, 171, 255, 255] : [240, 0, 70, 255]})
      ),
    };

    return [
      routes &&
        new GeoJsonLayer<Feature<LineString>>({
          ...(geoJsonLayerPropsDefaults as Partial<GeoJsonLayerProps>),
          data: data,
          getFillColor: d => d.properties?.color,
          getLineColor: d => d.properties?.color,
          lineWidthScale: 1.5,
          lineWidthMinPixels: 1,
          wrapLongitude: true,
          lineWidthMaxPixels: 20,
        }),
      stations.length > 0 &&
        new IconLayer<IconLayerData>({
          ...iconLayerProps,
          getPosition: d => [parseFloatTS(d.location.lon), parseFloatTS(d.location.lat), 50],
          id: 'station-start-icon',
          getIcon: () => 'port-start',
          data: [
            {
              stationType: 'from',
              location: {
                lat: parseFloatTS(stations[0].latitude),
                lon: parseFloatTS(stations[0].longitude),
              },
              meta: {
                pointType: stations[0].resultType,
                name: stations[0].name,
              },
            },
          ],
        }),
      stations.length > 1 &&
        new IconLayer<IconLayerData>({
          ...iconLayerProps,
          id: 'station-end-icon',
          getPosition: d => [parseFloatTS(d.location.lon), parseFloatTS(d.location.lat), 50],
          getIcon: () => 'port-end',
          data: positions.map(s => ({
            stationType: 'to',
            location: {
              lat: parseFloatTS(s.latitude),
              lon: parseFloatTS(s.longitude),
            },
            meta: {
              pointType: s.resultType,
              name: s.name,
            },
          })),
        }),
    ];
  }
}
export default StationsLayer;

type IconLayerData = {
  stationType: string;
  location: {
    lat: number;
    lon: number;
  };
  meta: {
    pointType: string;
    name: string;
  };
};
