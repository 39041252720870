import {FC} from 'react';
import {WrongResult} from './useFastVesselImportMapQuery';
import {Col, Row} from 'antd';
import {getDynamicTableColumns} from '../getDynamicTableColumns';
import {CustomColumnDef, DataGrid} from '../../../../../components/DataGrid/DataGrid';

export const WrongVesselGrid: FC<{
  results: WrongResult[];
}> = ({results}) => {
  return (
    <Row>
      <Col span={24}>
        <DataGrid<WrongResult>
          data={results}
          columns={[...(getDynamicTableColumns(['imo', 'message']) as CustomColumnDef<WrongResult>[])]}
        />
      </Col>
    </Row>
  );
};
