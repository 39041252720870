import {SubscriptionMailPurpose} from '../../../api/node-backend/generated';
import {CompanyEditSubscriptionPlanEnum} from '../../../api/symfony/generated';
import {useNavigate} from 'react-router';
import {showNotification} from '../../../utils/notification';
import {useSendChangeSubscriptionMailMutation} from './useSendSubscriptionMailMutation';

const showErrorNotification = () => {
  showNotification(
    `Sorry, we could not notify our team. Please try again later, or contact us at support@seabo.com.`,
    'error',
    20
  );
};

export const useSendChangeSubscriptionMail = () => {
  const sendChangeSubscriptionMailMutation = useSendChangeSubscriptionMailMutation();
  const navigate = useNavigate();

  return (targetPlan: CompanyEditSubscriptionPlanEnum) =>
    sendChangeSubscriptionMailMutation.mutate(
      {purpose: SubscriptionMailPurpose.ChangeSubscription, targetPlan},
      {
        onSuccess: () => {
          showNotification(`Thank you! We will contact you shortly.`, 'success', 5);
          navigate('/dashboard');
        },
        onError: () => {
          showErrorNotification();
        },
      }
    );
};

export const useSendCancelSubscriptionMail = () => {
  const sendChangeSubscriptionMailMutation = useSendChangeSubscriptionMailMutation();
  const navigate = useNavigate();

  return () =>
    sendChangeSubscriptionMailMutation.mutate(
      {purpose: SubscriptionMailPurpose.CancelSubscription},
      {
        onSuccess: () => {
          showNotification(`Alright! We will contact you shortly.`, 'success', 5);
          navigate('/dashboard');
        },
        onError: () => {
          showErrorNotification();
        },
      }
    );
};
