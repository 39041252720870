/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CargoOfferListResponseData
 */
export interface CargoOfferListResponseData {
  /**
   *
   * @type {Array<object>}
   * @memberof CargoOfferListResponseData
   */
  items: Array<object>;
  /**
   *
   * @type {number}
   * @memberof CargoOfferListResponseData
   */
  totalItems: number;
}

export function CargoOfferListResponseDataFromJSON(json: any): CargoOfferListResponseData {
  return CargoOfferListResponseDataFromJSONTyped(json, false);
}

export function CargoOfferListResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CargoOfferListResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: json['items'],
    totalItems: json['totalItems'],
  };
}

export function CargoOfferListResponseDataToJSON(value?: CargoOfferListResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: value.items,
    totalItems: value.totalItems,
  };
}
