import {DatePicker} from 'antd';
import styled from 'styled-components';

export const StyledDatePicker = styled(DatePicker)`
  background-color: var(--color-gray-6);
  border: 1px solid var(--color-gray-4);
  color: var(--color-black);

  .ant-picker-input > input {
    color: var(--color-black);
  }

  input::placeholder {
    color: var(--color-placeholder);
  }
` as typeof DatePicker;
