import isEqual from 'lodash/isEqual';
import {removeEmpty} from '../../utils/removeEmpty';
import {FilterBranchDefinitionsDefault, FilterInternalType} from './FilterBranchDefinition';

export const getAppliedFilterBranches = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  filterSettings: Partial<FilterInternalType<FilterBranchDefinitions>>,
  defaults: Partial<FilterInternalType<FilterBranchDefinitions>>
): Record<keyof FilterBranchDefinitions, boolean> => {
  const appliedFiltersBranches = {} as Record<keyof FilterBranchDefinitions, boolean>;

  for (const branch of Object.keys(filterSettings) as (keyof FilterBranchDefinitions)[]) {
    const defaultsForBranch = defaults[branch];
    if (!defaultsForBranch) {
      // eslint-disable-next-line no-console
      console.warn(`getAppliedFilterBranches - no defaults for branch ${branch.toString()}`);
      appliedFiltersBranches[branch] = false;
      continue;
    }
    appliedFiltersBranches[branch] = isFilterBranchApplied(filterSettings[branch]!, defaultsForBranch);
  }
  return appliedFiltersBranches;
};

const isFilterBranchApplied = <FilterBranchDefinitions extends FilterBranchDefinitionsDefault>(
  filterBranch: Partial<FilterInternalType<FilterBranchDefinitions>[keyof FilterBranchDefinitions]>,
  defaults: FilterInternalType<FilterBranchDefinitions>[keyof FilterBranchDefinitions]
): boolean => {
  const filterBranchWithoutEmpty = removeEmpty(filterBranch);
  const defaultsWithoutEmpty = removeEmpty(defaults);
  return !isEqual(filterBranchWithoutEmpty, defaultsWithoutEmpty);
};
