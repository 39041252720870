import {Modal} from 'antd';
import {FC} from 'react';
import {Offer, EditCircularForm} from './EditCircularForm/EditCircularForm';

export const EditCircularModal: FC<{offer: undefined | Offer; onCancel: () => void; onSuccess: () => void}> = ({
  offer,
  onCancel,
  onSuccess,
}) => {
  if (!offer) {
    return null;
  }

  return (
    <Modal width={1300} footer={null} onCancel={onCancel} open={true} title="Edit circular">
      <EditCircularForm onSuccess={onSuccess} offer={offer} />
    </Modal>
  );
};
