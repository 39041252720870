import TextLayer80 from '../../../../../components/SeaboMap/layers/deckgl80/text-layer/text-layer';
import {createIterable, log} from '@deck.gl/core';
import {SEPARATOR_SYMBOL} from '../../../../../components/SeaboMap/layers/const';

const MISSING_CHAR_WIDTH = 32;

class ForegroundTextLayer extends TextLayer80 {
  transformStringToLetters(dataRange = {}) {
    const {
      data,
      lineHeight,
      getText,
      clearSeparator = true,
      capLeftSymbol = '',
      capRightSymbol = '',
      altColor,
    } = this.props;
    const {iconMapping} = this.state;
    const {startRow, endRow} = dataRange;
    const {iterable, objectInfo} = createIterable(data, startRow, endRow);

    const transformedData = [];

    for (const object of iterable) {
      const transformCharacter = (transformed, object) => {
        return this.getSubLayerRow(transformed, object, objectInfo.index);
      };

      objectInfo.index++;
      const text = capLeftSymbol + getText(object, objectInfo) + capRightSymbol;
      if (text) {
        const rows = text.split('\n');

        // width and height of the paragraph
        const size = [0, 0];
        let offsetTop = 0;

        rows.forEach(row => {
          /* INLINED transformRow */
          let offsetLeft = 0;
          let rowHeight = 0;
          let isText = true;

          let characters = Array.from(row);
          characters = characters.map(character => {
            if (isText && character === SEPARATOR_SYMBOL) {
              isText = false;
              character = clearSeparator ? ' ' : character;
            }

            const datum = {
              text: character,
              offsetLeft,
            };

            if (isText) {
              datum.color = altColor;
            }

            const frame = iconMapping[character];

            if (frame) {
              offsetLeft += frame.width;
              if (!rowHeight) {
                // frame.height should be a constant
                rowHeight = frame.height * lineHeight;
              }
            } else {
              log.warn(`Missing character: ${character}`)();
              offsetLeft += MISSING_CHAR_WIDTH;
            }

            return datum;
          });

          /* END OF INLINED transformRow */
          const rowSize = [offsetLeft, rowHeight];

          characters.forEach(datum => {
            datum.offsetTop = offsetTop;
            datum.size = size;
            datum.rowSize = rowSize;

            // console.log(datum)
            transformedData.push(transformCharacter(datum, datum.color ? object : {...object, color: altColor}));
          });

          offsetTop = offsetTop + rowHeight;
          size[0] = Math.max(size[0], offsetLeft);
        });

        // last row
        size[1] = offsetTop;
      }
    }

    return transformedData;
  }
}

ForegroundTextLayer.layerName = 'ForegroundTextLayer';
export default ForegroundTextLayer;
