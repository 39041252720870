/**
 * A version of `parseInt()` that deals with the fact that JS tolerates parseInt(number) but TS doesn't.
 */
export const parseIntTS = (value: number | string | undefined | null, radix?: number): number => {
  // We just delegate to the runtime and trust that it will work anyway.
  return parseInt(value as string, radix);
};

/**
 * A version of `parseFloat()` that deals with the fact that JS tolerates parseFloat(number) but TS doesn't.
 */
export const parseFloatTS = (value: number | string | undefined | null): number => {
  // We just delegate to the runtime and trust that it will work anyway.
  return parseFloat(value as string);
};

export const isNaNTS = (value: number | string | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return isNaN(value as any);
};
