import numeral from 'numeral';

export const formatUSD = (value: string | null | undefined | number) => {
  if (typeof value !== 'number') {
    return value;
  }
  return numeral(value).format('$ 0,0');
};

/**
 * Formats a number to EUR format WITHOUT the currency symbol.
 * Example: 1234.56 -> 1.234,56
 */
export const formatEUR = (value: string | null | undefined | number) => {
  if (typeof value !== 'number') {
    return value;
  }
  return numeral(value).format(`0,0[.]00`);
};
