import {useEffect, useMemo, FC} from 'react';
import {ProjectVessel} from '../../types';
import {useProjectDetails} from '../../useProjectDetails';
import {SeaboMap} from '../../../SeaboMap/SeaboMap';
import {useMapApi} from '../../../SeaboMap/useMapApi';
import styled from 'styled-components';
import {getCargoesLayer} from './getCargoesLayer';
import {useDispatch} from 'react-redux';
import {useAisVesselsQuery} from '../../../../queries/useAisVesselsQuery';
import {useGetCoordinatesFromLocation} from '../../../../screens/CargoDetail/CargoDetailHeader/useGetCargoRouteLayer';
import {useQuery} from '@tanstack/react-query';
import ProjectService from '../../../../redux/ApiService/ProjectService';
import {useVesselRouteLayer} from '../../../SeaboMap/layers/VesselRouteLayer/VesselRouteLayer';
import {MapControlButton} from '../../../SeaboMap/MapContext/Types';
import {Coordinates} from '../../../../utils/Coordinates';
import {getBoundsFromCoordinates} from '../../../SeaboMap/utils/getBoundsFromCoordinates';

type ProjectVesselMapProps = {
  vessel: ProjectVessel;
  selectedShortlistItemId: number | null;
  onShortlistItemClick?: (id: number) => void;
};

export const ProjectVesselMap: FC<ProjectVesselMapProps> = ({
  vessel,
  selectedShortlistItemId,
  onShortlistItemClick,
}) => {
  const {
    project: {id},
  } = useProjectDetails();

  const dispatch = useDispatch();
  const mapApi = useMapApi();
  const aisVesselsQuery = useAisVesselsQuery();
  const {getCoordinatesFromLocation} = useGetCoordinatesFromLocation();

  const cargoesFromSortlistQuery = useQuery({
    queryKey: ['shortlist', id],
    queryFn: async () => {
      const result = await dispatch(ProjectService.getShortlistCargoes(id));
      return result.data;
    },
    enabled: !!id,
  });

  const vesselsToVisualize = useMemo(() => {
    if (!aisVesselsQuery.data) {
      return [];
    }
    return aisVesselsQuery.data.filter(v => v.imo === vessel.imo);
  }, [aisVesselsQuery.data, vessel]);

  const vesselRouteLayer = useVesselRouteLayer({imo: vessel.imo, arrowSize: 4, zoom: 0, arrowOpacity: 0.33});

  const {layers, cargoesCoordinates} = useMemo(
    () =>
      getCargoesLayer({
        selectedShortlistItemId,
        shortlistCargoes: cargoesFromSortlistQuery.data,
        onShortlistItemClick,
        getCoordinatesFromLocation,
      }),
    [selectedShortlistItemId, cargoesFromSortlistQuery, onShortlistItemClick, getCoordinatesFromLocation]
  );

  const fitBounds = () => {
    const coords: Coordinates[] = [];
    vesselsToVisualize.forEach(v => {
      coords.push(v.coordinates! as Coordinates);
    });
    cargoesCoordinates.forEach(cargo => {
      if (cargo.from) {
        coords.push(cargo.from);
      }
      if (cargo.to) {
        coords.push(cargo.to);
      }
    });
    if (coords.length === 0) {
      return;
    }
    mapApi.fitBounds(getBoundsFromCoordinates(coords));
  };

  useEffect(() => {
    if (!mapApi.state.mapReady || !cargoesFromSortlistQuery.isSuccess) {
      return;
    }

    fitBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapApi.state.mapReady, vesselsToVisualize, cargoesFromSortlistQuery.isSuccess]);

  return (
    <MapContainer>
      <SeaboMap
        mapApi={mapApi}
        mapControlButtonsToHide={[MapControlButton.VESSELS, MapControlButton.CARGOES, MapControlButton.PORTS]}
        vesselsToVisualize={{
          data: vesselsToVisualize,
          constantIconSize: 6,
        }}
        initialViewState={{
          zoom: 0.1,
          minZoom: 0,
          maxZoom: 12,
        }}
        initialMapSettings={{
          mapStyle: 'SAT',
        }}
        settingsButtonVisible={false}
        lassoSelectionEnabled={false}
        layers={[vesselRouteLayer, ...layers]}
        saveMapState={false}
      />
    </MapContainer>
  );
};

const MapContainer = styled.div`
  height: 100%;
  min-height: 400px;

  .seabo-mapContainer {
    border-radius: var(--border-radius-card);
  }
`;
