import {message} from 'antd';
import React, {useContext, useState} from 'react';
import {FolderOpenOutlined} from '@ant-design/icons';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {OpenDialog} from './OpenDialog';
import {VoyageCalculationButton} from '../utils/VoyageCalculationButton';

export const OpenButton = () => {
  const voyageContext = useContext(VoyageCharterContext);

  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <VoyageCalculationButton
        data-cy="openButton"
        onClick={() => {
          setShowModal(true);
        }}
        type={'dashed'}
        icon={<FolderOpenOutlined />}>
        Open
      </VoyageCalculationButton>
      {showModal && (
        <OpenDialog
          onCancel={() => {
            setShowModal(false);
          }}
          visible={showModal}
          onLoad={async item => {
            await voyageContext.loadFromCloud(item.id as number);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Calculation loaded');
          }}
        />
      )}
    </>
  );
};
