import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {ApiError} from '../../../../api/utils/ApiError';
import {negotiationNegotiationTemplateApi} from '../../../../api/node-backend/nodeBackendApis';
import {UpdateNegotiationTemplateRequest} from '../../../../api/node-backend/generated/apis/NegotiationNegotiationTemplateApi';
import {NegotiationTemplate} from '../../../../api/node-backend/generated/models/NegotiationTemplate';

export const useUpdateNegotiationTemplateMutation = (
  options?: UseMutationOptions<NegotiationTemplate, ApiError, UpdateNegotiationTemplateRequest>
) =>
  useMutation({
    mutationFn: async ({createOrUpdateNegotiationTemplateDTO, templateId}) => {
      const result = await negotiationNegotiationTemplateApi.updateNegotiationTemplate({
        createOrUpdateNegotiationTemplateDTO,
        templateId,
      });
      return result;
    },
    ...options,
  });
