import {FC} from 'react';
import {Input, Space} from 'antd';
import {EditorDescription, ButtonBox} from './NegotiationReplyTimeEditor';
import {parseIntTS} from '../../../../../../utils/parseNumberTS';
import dayjs from 'dayjs';
import {setupDayjs} from '../../../../../../utils/setupDayjs';

setupDayjs();

type ReplyTimeEditorDurationProps = {
  duration?: number | null;
  onDurationChange: (duration: number) => void;
};

export const ReplyTimeEditorDuration: FC<ReplyTimeEditorDurationProps> = ({duration = 0, onDurationChange}) => {
  const durationFromProp = dayjs.duration(duration || 0);
  const daysFromProp = durationFromProp.days();
  const hoursFromProp = durationFromProp.hours();
  const minutesFromProp = durationFromProp.minutes();

  const onChange = ({
    days = daysFromProp,
    hours = hoursFromProp,
    minutes = minutesFromProp,
  }: {
    days?: number;
    hours?: number;
    minutes?: number;
  }) => {
    const duration = dayjs.duration({days, hours, minutes}).asMilliseconds();
    onDurationChange(duration);
  };

  return (
    <div data-testid="ReplyTimeEditorDuration">
      <EditorDescription>Duration until timeout</EditorDescription>
      <ButtonBox>
        <Space.Compact size="small">
          <Input
            size="small"
            type="number"
            step={1}
            min={0}
            value={daysFromProp}
            suffix="d"
            onChange={event => onChange({days: parseIntTS(event.target.value)})}
            data-testid="ReplyTimeEditorDays"
          />
          <Input
            size="small"
            type="number"
            step={1}
            min={0}
            value={hoursFromProp}
            suffix="h"
            onChange={event => onChange({hours: parseIntTS(event.target.value)})}
            data-testid="ReplyTimeEditorHours"
          />
          <Input
            size="small"
            type="number"
            step={1}
            min={0}
            value={minutesFromProp}
            suffix="min"
            onChange={event => onChange({minutes: parseIntTS(event.target.value)})}
            data-testid="ReplyTimeEditorMinutes"
          />
        </Space.Compact>
      </ButtonBox>
    </div>
  );
};
