import {useMemo, FC} from 'react';
import sumBy from 'lodash/sumBy';
import {NumberStatistics} from '../../../components/NumberStatistics';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';

export const TiedUpBulkerVessels: FC<{
  currentBulkerVisits: MergedVisits[];
}> = ({currentBulkerVisits}) => {
  const tiedUpDwt = useMemo(() => {
    return sumBy(currentBulkerVisits, visit => visit.dwtSummer ?? 0);
  }, [currentBulkerVisits]);

  return <NumberStatistics title={'Tied up DWT'} suffix={'MT'} value={tiedUpDwt} />;
};
