import React, {FC, useEffect, useRef} from 'react';
import {Button, Form, Input, InputRef} from 'antd';
import {Modal} from '../../antd/components/Modal';
import {ModalFooter} from '../../antd/components/ModalFooter';

interface EditNameFormValues {
  name: string;
}

export const EditNameModal: FC<{
  title: string;
  value: string;
  buttonLabel: string;
  cursor: 'start' | 'end' | 'all';
  onOk: (newName: string) => void;
  onCancel: () => void;
}> = ({title, value, buttonLabel, cursor, onOk, onCancel}) => {
  const onFinish = (values: EditNameFormValues) => {
    onOk(values.name);
  };
  const ref = useRef<InputRef>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus({cursor});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal title={title} open onCancel={onCancel} width={400} destroyOnClose footer={null}>
      <Form<EditNameFormValues>
        layout="vertical"
        name="control-hooks"
        initialValues={{
          name: value,
        }}
        preserve={false}
        onFinish={onFinish}
        autoComplete="off"
        data-testid="EditNameForm">
        <input role="presentation" autoComplete="off" type="hidden" />
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}>
          <Input maxLength={27} ref={ref} data-testid="NameInput" autoComplete="off" />
        </Form.Item>
        <ModalFooter>
          <Button key="submit" type="primary" htmlType="submit" data-testid="OkButton">
            {buttonLabel}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
