import {assertUnreachable} from '../../../utils/assert';
import {TODO} from '../../../utils/TODO';
import {RowValueType} from './RowValueType';

export const getVesselFromRowValue = (rowValue: TODO, type: RowValueType): TODO => {
  switch (type) {
    case 'market':
      return rowValue.vessel;
    case 'search':
      return rowValue._source;
    case 'portfolio':
      return rowValue;
    case 'vessel-database':
      return rowValue;
    case 'congestion':
      return rowValue;
    case undefined:
      return rowValue;
    default:
      assertUnreachable(type);
  }
};
