import styled, {keyframes} from 'styled-components';
import LoadingAnimation from '../Loading';

export const FullScreenLoadingAnimation = () => {
  return (
    <Container data-testid="FullScreenLoadingAnimation">
      <LoadingAnimation />
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  animation: ${fadeIn} 0.5s;
`;
