import dayjs, {Dayjs} from 'dayjs';
import {GroupedPerUnit} from '../groupBucketsByDate';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

export const getAllDaysInPeriod = ({start, per, end}: {start: Dayjs; end: Dayjs; per?: GroupedPerUnit}): string[] => {
  const allDatesInPeriod = [];
  let startDate = dayjs(start);
  while (!startDate.isAfter(end, 'day')) {
    allDatesInPeriod.push(startDate.format(DateTimeFormat.IsoDate));
    startDate = dayjs(startDate).add(1, per ?? 'day');
  }

  return allDatesInPeriod;
};
