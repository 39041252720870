import {
  AccountManagementApi,
  AuthenticationApi,
  CargoOfferApi,
  CharterCalculationApi,
  CommodityApi,
  CompanyApi,
  Configuration,
  ContactApi,
  NotificationApi,
  PortApi,
  PortfolioCargoApi,
  ProjectApi,
  RoutesApi,
  PortfolioGroupApi,
  PortfolioVesselApi,
  MapApi,
  NewsApi,
  SearchApi,
  SessionApi,
  SocialApi,
  VesselOfferApi,
  WorkspaceApi,
  FilterApi,
  TagApi,
  InboxApi,
  VesselApi,
  WeatherApi,
  PortfolioCargoesApi,
  WidgetApi,
  VesselFixtureApi,
} from './generated';

import {apiAddCsrfTokenMiddleware} from '../utils/apiAddCsrfTokenMiddleware';
import {apiUpdateCsrfTokenMiddleware} from '../utils/apiUpdateCsrfTokenMiddleware';
import {apiTransactionIdMiddleware} from '../utils/apiTransactionIdMiddleware';
import {apiErrorMiddleware} from '../utils/apiErrorMiddleware';

// This is the same basepath as used by JSDom. It helps to use the same basepath
// so that it will be consistent across JSDom and non JSDom tests.
const BasePathForTest = 'http://localhost';

const BasePath =
  typeof window !== 'undefined' && typeof window.location !== 'undefined' ? window.location.origin : BasePathForTest;
const configuration = new Configuration({
  basePath: BasePath,
  credentials: 'same-origin',
  middleware: [apiAddCsrfTokenMiddleware, apiTransactionIdMiddleware, apiUpdateCsrfTokenMiddleware, apiErrorMiddleware],
});

export const accountManagementApi = new AccountManagementApi(configuration);
export const authenticationApi = new AuthenticationApi(configuration);
export const charterCalculationApi = new CharterCalculationApi(configuration);
export const companyApi = new CompanyApi(configuration);
export const searchApi = new SearchApi(configuration);
export const sessionApi = new SessionApi(configuration);
export const workspaceApi = new WorkspaceApi(configuration);
export const notificationApi = new NotificationApi(configuration);
export const cargoOfferApi = new CargoOfferApi(configuration);
export const vesselOfferApi = new VesselOfferApi(configuration);
export const projectApi = new ProjectApi(configuration);
export const portfolioGroupApi = new PortfolioGroupApi(configuration);
export const socialApi = new SocialApi(configuration);
export const portfolioCargoApi = new PortfolioCargoApi(configuration);
export const portfolioCargoesApi = new PortfolioCargoesApi(configuration);
export const portfolioVesselApi = new PortfolioVesselApi(configuration);
export const commodityApi = new CommodityApi(configuration);
export const filterApi = new FilterApi(configuration);
export const contactApi = new ContactApi(configuration);
export const portApi = new PortApi(configuration);
export const mapApi = new MapApi(configuration);
export const newsApi = new NewsApi(configuration);
export const routesApi = new RoutesApi(configuration);
export const tagApi = new TagApi(configuration);
export const inboxApi = new InboxApi(configuration);
export const vesselApi = new VesselApi(configuration);
export const weatherApi = new WeatherApi(configuration);
export const widgetApi = new WidgetApi(configuration);
export const vesselFixtureApi = new VesselFixtureApi(configuration);
