/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Reference,
  ReferenceFromJSON,
  ReferenceFromJSONTyped,
  ReferenceToJSON,
  Role,
  RoleFromJSON,
  RoleFromJSONTyped,
  RoleToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyMember
 */
export interface CompanyMember {
  /**
   *
   * @type {number}
   * @memberof CompanyMember
   */
  userID: number;
  /**
   *
   * @type {string}
   * @memberof CompanyMember
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyMember
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyMember
   */
  lastName: string;
  /**
   *
   * @type {Array<Role>}
   * @memberof CompanyMember
   */
  roles: Array<Role>;
  /**
   *
   * @type {string}
   * @memberof CompanyMember
   */
  companyPosition: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CompanyMember
   */
  companyVisibility: boolean;
  /**
   *
   * @type {Date}
   * @memberof CompanyMember
   */
  companyJoinTime: Date;
  /**
   *
   * @type {Array<Reference>}
   * @memberof CompanyMember
   */
  workspaces: Array<Reference>;
}

export function CompanyMemberFromJSON(json: any): CompanyMember {
  return CompanyMemberFromJSONTyped(json, false);
}

export function CompanyMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyMember {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userID: json['userID'],
    fullName: json['fullName'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    roles: (json['roles'] as Array<any>).map(RoleFromJSON),
    companyPosition: json['companyPosition'],
    companyVisibility: json['companyVisibility'],
    companyJoinTime: new Date(json['companyJoinTime']),
    workspaces: (json['workspaces'] as Array<any>).map(ReferenceFromJSON),
  };
}

export function CompanyMemberToJSON(value?: CompanyMember | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userID: value.userID,
    fullName: value.fullName,
    firstName: value.firstName,
    lastName: value.lastName,
    roles: (value.roles as Array<any>).map(RoleToJSON),
    companyPosition: value.companyPosition,
    companyVisibility: value.companyVisibility,
    companyJoinTime: value.companyJoinTime.toISOString(),
    workspaces: (value.workspaces as Array<any>).map(ReferenceToJSON),
  };
}
