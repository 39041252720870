import {
  convertKeysWithBooleansToStringArray,
  convertStringArrayToKeysWithBooleans,
} from '../FilterConversion/utils/FilterConversionUtils';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

type ContractType = 'VC' | 'TCT' | 'PC' | 'BC';

export type DatabaseType = ContractType[];
export type InternalType = Record<ContractType, boolean>;

export const Defaults: InternalType = {
  VC: false,
  TCT: false,
  PC: false,
  BC: false,
};

export const fromDatabase = (database: DatabaseType): InternalType =>
  convertStringArrayToKeysWithBooleans(database, Defaults);

export const toDatabase = (internal: InternalType): DatabaseType => convertKeysWithBooleansToStringArray(internal);

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Contract type',
  branch: 'contractTypes',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
