import {SidebarLegend} from './SidebarLegend';
import {NegotiationTermAreaSidebarContainer, StickySideBarContainer} from './shared';

export const NegotiationTermAreaSidebarLeft = () => {
  return (
    <NegotiationTermAreaSidebarContainer $position="left">
      <StickySideBarContainer>
        <SidebarLegend />
      </StickySideBarContainer>
    </NegotiationTermAreaSidebarContainer>
  );
};
