import React, {useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {StyledButton} from './StyledButton';
import SelectLayerIcon from './SelectLayerIcon.svg';
import {Tooltip} from 'antd';

export const SelectLayerButton = () => {
  const mapContext = useContext(MapContext);

  const hasSelectLayer = mapContext.props.lassoSelectionEnabled ?? true;
  if (!hasSelectLayer) {
    return null;
  }

  return (
    <Tooltip title={'Selection (Alt-Drag)'} placement="left">
      <StyledButton
        onClick={() => {
          if (mapContext.state.isSelectMode) {
            mapContext.setSelectMode(false);
          } else {
            mapContext.setSelectMode(true);
          }
        }}>
        {mapContext.state.isSelectMode ? 'X' : <img alt={''} src={SelectLayerIcon} />}
      </StyledButton>
    </Tooltip>
  );
};
