import {useRef} from 'react';
import {AnimeTimelineInstance} from 'animejs';
import {AnimationState} from '../model/AnimationState';

export interface VoyageAnimationApi {
  recenter: () => void;
  animeTimeline: AnimeTimelineInstance | null;
  animationState: AnimationState;
}

const VoyageAnimationApiDefaultImpl: VoyageAnimationApi = {
  recenter: () => {
    throw new Error('Method not implemented.');
  },
  animeTimeline: null,
  animationState: {
    timestamp: 0,
    longitude: 0,
    latitude: 0,
    angle: 0,
    cameraFollowsVessel: 1,
    standingStill: 0,
    portVisitId: -1,
    aisRecordCsvRow: 0,
    portAreaVibility: 0,
  },
};

export const useVoyageAnimationApi = (): VoyageAnimationApi => {
  const animationStateRef = useRef<VoyageAnimationApi>(VoyageAnimationApiDefaultImpl);
  return animationStateRef.current;
};
