import {useEffect} from 'react';
import {SidebarMode} from '../../../redux/Sidebar';
import {useSelector} from '../../../redux/react-redux';
import {saveSidebarInLocalStorage} from '../../../redux/storePersistence';

/**
 * Everytime our sidebar mode changes in Redux, we store it in local storage.
 */
export const useSyncSidebarModeToLocalStorage = () => {
  const mode: SidebarMode = useSelector(state => state.sidebar.mode);
  useEffect(() => {
    saveSidebarInLocalStorage(mode);
  }, [mode]);
};
