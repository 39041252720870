import {useState} from 'react';
import {useDispatch} from 'react-redux';
import BaseModal from '../../../atoms/BaseModal/index';
import './style.scss';
import {CompanyListWorkspaceUser} from '../../../screens/CompanyScreens/CompanySettingsScreen/components/CompanyListWorkspaceUser/CompanyListWorkspaceUser';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {SearchIndicator} from '../../../atoms/Select/Indicators';
import NamedAvatar from '../../../atoms/NamedAvatar';
import {
  indicatorsContainer,
  modalControl,
  modalMenu,
  modalMenuList,
  option,
  singleValue,
  valueContainer,
} from '../../../atoms/Select/styles';
import {RootState} from '../../../redux/store';
import {TODO} from '../../../utils/TODO';
import {invalidateWorkspacesQuery} from '../../../queries/useWorkspacesQuery';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useSelector} from '../../../redux/react-redux';
import CompanyUser from '../../../model/company/CompanyUser';
import {invalidateCompanyMembers} from '../../../queries/useCompanyMembersQuery';
import {validateRemoveUser, Workspace} from './validateRemoveUser';
import Select from '../../../atoms/Select/Select';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {Button} from 'antd';
import {ModalWidth} from '../../../antd/components/Modal';

type Props = {
  members: CompanyUser[];
  editWorkspaceData: Workspace;
  close: () => void;
};

const AddCompanyMembersToWorkspaceModalContent = (props: Props) => {
  const {editWorkspaceData, members, close} = props;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getWorkspaceList = (id: number) => dispatch(CompanyActions.getWorkspaceList(id));
  const company = useSelector((state: RootState) => state.company.data);
  const currentUser = useSelector((state: RootState) => state.user)!;

  const [workspaceMembers, setWorkspaceMembers] = useState<TODO[]>(
    editWorkspaceData.users.map(user => members.find(member => member.id === user.id))
  );
  const [nonMembers, setNonMembers] = useState<TODO[]>(
    members.filter((member: $TSFixMe) => !editWorkspaceData.users.map((user: $TSFixMe) => user.id).includes(member.id))
  );
  const addMembersToWorkspace = (body: CompanyUser[]) =>
    dispatch(CompanyActions.addMembersToWorkspace(company.id, editWorkspaceData.id, body));

  const saveWorkspaceMembersMutation = useMutation({
    mutationFn: async () => {
      await addMembersToWorkspace(workspaceMembers);
    },
    onSuccess: async () => {
      await getWorkspaceList(company.id);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateWorkspacesQuery(queryClient);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateCompanyMembers(queryClient);
      close();
    },
  });

  const onRemoveUserFromWorkspace = (user: CompanyUser) => {
    setNonMembers([...nonMembers, user]);
    setWorkspaceMembers(
      workspaceMembers.filter((workspaceUser: CompanyUser) => {
        return workspaceUser.id !== user.id;
      })
    );
  };

  const onAddUserToWorkspace = (user: CompanyUser) => {
    setWorkspaceMembers([...workspaceMembers, user]);
    setNonMembers(
      nonMembers.filter((nonMember: CompanyUser) => {
        return nonMember.id !== user.id;
      })
    );
  };

  const disableButton = saveWorkspaceMembersMutation.isPending;

  return (
    <div className={'company-workspace-modal'}>
      <div className="company-workspace-modal__search">
        <Select
          options={nonMembers}
          isOptionsDisabled={(option: $TSFixMe) => !!workspaceMembers.find((u: $TSFixMe) => u.id === option.id)}
          value={null}
          getOptionLabel={(option: $TSFixMe) => <NamedAvatar imageUrl={option.avatarUrl} name={option.fullName} />}
          getOptionValue={(option: $TSFixMe) => option.fullName}
          onChange={(user: $TSFixMe, meta: $TSFixMe) => meta.action === 'select-option' && onAddUserToWorkspace(user)}
          placeholder={'Search for users to add them to the workspace'}
          components={{
            DropdownIndicator: SearchIndicator,
          }}
          styles={{
            option,
            control: modalControl,
            menuList: modalMenuList,
            menu: modalMenu,
            singleValue,
            valueContainer,
            indicatorsContainer,
          }}
        />
      </div>
      <div className="company-workspace-modal__user-list">
        <CompanyListWorkspaceUser
          users={workspaceMembers}
          validateRemoveUser={user =>
            validateRemoveUser({
              user,
              currentUser,
              workspace: editWorkspaceData,
              members,
            })
          }
          onRemove={user => onRemoveUserFromWorkspace(user)}
        />
      </div>

      <ModalFooter>
        <Button
          disabled={disableButton}
          type="primary"
          id={'company-add-workspace__save'}
          onClick={() => saveWorkspaceMembersMutation.mutate()}
          style={{minWidth: 96}}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export const AddCompanyMembersToWorkspaceModal = (props: $TSFixMe) => (
  <BaseModal
    title={
      <>
        Manage members of
        <br />"{props.editWorkspaceData.name}"
      </>
    }
    width={ModalWidth.Middle}>
    {(modalProps: $TSFixMe) => <AddCompanyMembersToWorkspaceModalContent {...props} {...modalProps} />}
  </BaseModal>
);
