import {FC, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import CargoIcon from '../../../../atoms/CargoIcon';
import {useCommodityCategory} from '../../../../atoms/CommodityCategory';
import Icon from '../../../../atoms/Icon';
import {numberFormat} from '../../../../utils/formatter';
import {getCommodityTypeFormatted} from '../MultiCalcCargoModal/ProjectCargoes';
import {CargoProject, VesselProject} from '../types';
import {OneCalcResult} from '../utils/oneCalc';
import {TruncatedTextWithHint} from '../../../../atoms/TruncatedTextWithHint/TruncatedTextWithHint';

export const TableProjectDetail: FC<{project: CargoProject | VesselProject}> = ({project}) => {
  return (
    <StyledLink to={`/projects/${project.id}`}>
      <Square>
        <StyledIcon type="work-tray" />
      </Square>
      <b>
        <TruncatedTextWithHint text={project.name} />
      </b>
    </StyledLink>
  );
};

export const TableCargoDetail: FC<{cargo: OneCalcResult['cargo']}> = ({cargo}) => {
  const cargoLabel = cargo.chartererName ?? 'TBA';
  const commodityCategory = useCommodityCategory(cargo)?.name;
  const commodityType = getCommodityTypeFormatted(cargo);
  const quantity =
    cargo.quantityMin !== cargo.quantityMax
      ? `${numberFormat(cargo.quantityMin)} - ${numberFormat(cargo.quantityMax)}`
      : numberFormat(cargo.quantityMin);

  return (
    <StyledLink to={`/cargo/${cargo.id}`}>
      <CargoIcon color="var(--color-blue)" small cargoType="vc" commodityType={cargo.commodityCategory} />
      <div>
        <b>
          <TruncatedTextWithHint text={cargoLabel} />
        </b>
        <br />
        {commodityCategory} {commodityType ? `(${commodityType})` : ''}
        <br />
        {quantity} mts
      </div>
    </StyledLink>
  );
};

export const TableVesselDetail: FC<{vessel: OneCalcResult['vessel']}> = ({vessel}) => {
  const vesselLabel = `${vessel.name} (${vessel.imo})`;
  return (
    <StyledLink to={`/vessel/${vessel.id}`}>
      <StyledCircle>
        <StyledIcon type="vessel-complex" />
      </StyledCircle>
      <div>
        <b>
          <TruncatedTextWithHint text={vesselLabel} />
        </b>
        <br />
        {vessel.aisDetails?.currentArea?.name ?? vessel.aisDetails?.currentPort?.name ?? 'Location N/A'}
        <br />
        {numberFormat(Math.round(vessel.dwtSummer / 1000) * 1000)} mts
      </div>
    </StyledLink>
  );
};

const loadingStatusTexts = [
  'Collecting vessel information',
  'Collecting cargo information',
  'Filtering information',
  'Finding best routes',
  'Estimating route durations',
  'Computing bunker usages',
  'Calculating bunker costs',
  'Summarizing total trip costs',
  'Ranking calculation results',
  'Formatting calculation data',
  'We are almost there. If the data does not appear soon please try reloading this page.',
];

export const LoadingStatus: FC = () => {
  const created = useRef(true);
  const [textIndex, setTextIndex] = useState(0);
  const [dots, setDots] = useState('.');
  const loadingStatusText = loadingStatusTexts[textIndex];

  useEffect(() => {
    const textInterval = setInterval(() => {
      setTextIndex(index => {
        if (index < loadingStatusTexts.length - 1) {
          return index + 1;
        }
        clearInterval(textInterval);
        return loadingStatusTexts.length - 1;
      });
    }, 3000);
    const dotsInterval = setInterval(() => setDots(dots => (dots.length < 3 ? dots + '.' : '.')), 1000);

    return () => {
      clearInterval(textInterval);
      clearInterval(dotsInterval);
    };
  }, [created]);

  return (
    <LoadingStatusText>
      <InvisibleDots>{dots}</InvisibleDots>
      {loadingStatusText}
      {dots}
    </LoadingStatusText>
  );
};

const LoadingStatusText = styled.div`
  width: 100%;
  text-align: center;
`;
const InvisibleDots = styled.span`
  color: transparent;
  user-select: none;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: var(--color-gray-1);
  font-weight: normal;
  line-height: 1.25;
`;

const StyledIcon = styled(Icon)`
  font-size: var(--font-size-xl) !important;
  vertical-align: sub;
`;

export const Circle = styled.div`
  --circumference: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--circumference);
  width: var(--circumference);
  margin-right: 5px;
  background: var(--color-gray-4);
  color: var(--color-gray-1);
  border-radius: 50%;
`;

const Square = styled.div`
  --size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  margin-right: 5px;
  background: var(--color-gray-2);
  color: var(--color-gray-1);
  border-radius: var(--border-radius);

  ${StyledIcon} {
    color: var(--color-white) !important;
  }
`;

const StyledCircle = styled(Circle)`
  --circumference: 36px;
  background: var(--color-blue);

  ${StyledIcon} {
    color: var(--color-white) !important;
  }
`;
