import {FC} from 'react';
import styled from 'styled-components';
import {Divider} from 'antd';
import {Center} from '../../../components/Center/Center';
import {SignupForm} from './SignupForm';
import {useMutation} from '@tanstack/react-query';
import {CompanySignupRequest} from '../../../api/symfony/generated';
import {ApiError, isApiError} from '../../../api/utils/ApiError';
import {authenticationApi} from '../../../api/symfony/symfonyApi';
import {BottomArea, BottomLabel, BottomLink, Dialog, TitleStyled} from '../utils/shared-components';
import {Link} from 'react-router-dom';
import {TODO} from '../../../utils/TODO';

export const SignupDialog: FC<{source?: string; onSuccess: () => void}> = ({source, onSuccess}) => {
  const signupMutation = useMutation<void, ApiError, CompanySignupRequest>({
    mutationFn: (loginRequest: CompanySignupRequest): Promise<void> => {
      return authenticationApi.signupCompany({
        companySignupRequest: loginRequest,
      });
    },
    onSuccess,
    onError: error => {
      if (isApiError(error) && error.status === 422) {
        return;
      }
      throw error;
    },
  });

  const onSubmit = (formValues: TODO) => signupMutation.mutateAsync(formValues);

  return (
    <Dialog>
      <Center>
        <TitleStyled level={3}>Sign up to seabo</TitleStyled>
      </Center>
      <DividerStyled />
      <SignupContainer>
        <SignupForm
          source={source}
          submitError={signupMutation.error}
          isSubmitting={signupMutation.isPending}
          onSubmit={onSubmit}
        />
      </SignupContainer>
      <BottomArea>
        <BottomLabel>Already have an account?</BottomLabel>
        <Link to="/login">
          <BottomLink block>Log in</BottomLink>
        </Link>
      </BottomArea>
    </Dialog>
  );
};

const DividerStyled = styled(Divider)`
  margin-bottom: 0;
`;

const SignupContainer = styled.div`
  position: relative;
  padding-top: 31px;
`;
