import {CompositeLayer, IconLayer} from 'deck.gl';
import highlightVessel from './icons/highlight.svg';

const iconMapping = {
  marker: {
    x: 0,
    y: 0,
    width: 128,
    height: 128,
  },
};

class HighlightLayer extends CompositeLayer {
  renderLayers() {
    return [
      new IconLayer({
        id: `${this.props.id}-icon`,
        data: [{}],
        getIcon: () => 'marker',
        getPosition: this.props.getPosition,
        getSize: 50,
        iconAtlas: highlightVessel,
        iconMapping,
        opacity: 0.03,
        visible: this.props.visible,
      }),
    ];
  }
}

HighlightLayer.layerName = 'HighlightLayer';
export default HighlightLayer;
