import {actions as searchApi, actionTypes as searchActionTypes} from './ApiService/autocomplete';
import {AppThunk} from './AppThunk';
import {ActionWithPayload} from './middlware/ActionWithPayload';

interface QueryParams {
  pageSize: number;
  pageIndex: number;
  searchTerm: string;
  indexName: string;
}

// The shape of this Redux partition
interface SearchState {
  singleSearch: {
    items: [];
    totalItems: number;
    pageSize: number;
    pageIndex: number;
    searchTerm: string;
    indexName: string;
    loading: boolean;
  };
}

const initialState: SearchState = {
  singleSearch: {
    items: [],
    totalItems: 0,
    pageSize: 10,
    pageIndex: 1,
    searchTerm: '',
    indexName: '',
    loading: false,
  },
};

const actionTypes = {
  SEARCH_SET_GRID_OPTIONS: 'SEARCH_SET_GRID_OPTIONS',
};

export const searchReducer = (state = initialState, action: ActionWithPayload): SearchState => {
  const {type, payload} = action;
  switch (type) {
    case searchActionTypes.singleSearch.PENDING:
      return {
        ...state,
        singleSearch: {
          ...state.singleSearch,
          items: [],
          totalItems: 0,
          loading: true,
        },
      };
    case searchActionTypes.singleSearch.SUCCESS:
      if (payload.data) {
        return {
          ...state,
          singleSearch: {
            ...state.singleSearch,
            items: payload.data.items || [],
            totalItems: payload.data.totalItems || 0,
            loading: false,
          },
        };
      }

      return {
        ...state,
        singleSearch: {
          ...state.singleSearch,
          items: payload || [],
          totalItems: payload ? payload.length : 0,
          loading: false,
        },
      };
    case actionTypes.SEARCH_SET_GRID_OPTIONS:
      return {
        ...state,
        singleSearch: {
          ...state.singleSearch,
          searchTerm: payload.searchTerm || state.singleSearch.searchTerm,
          pageIndex: payload.pageIndex || state.singleSearch.pageIndex,
          pageSize: payload.pageSize || state.singleSearch.pageSize,
          indexName: payload.indexName || state.singleSearch.indexName,
        },
      };
    default:
      return state;
  }
};

export const setSearchGridOptions =
  (queryParams: QueryParams): AppThunk =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.SEARCH_SET_GRID_OPTIONS,
      payload: queryParams,
    });

    const {pageSize, pageIndex, searchTerm, indexName} = getState().search.singleSearch;

    dispatch(
      searchApi.singleSearch({
        queryParams: {
          indexName,
          query: searchTerm,
          pageIndex,
          pageSize,
        },
      })
    );
  };

export const getSingleSearch =
  (queryParams: QueryParams): AppThunk =>
  dispatch => {
    dispatch({type: actionTypes.SEARCH_SET_GRID_OPTIONS, payload: queryParams});
    dispatch(searchApi.singleSearch({queryParams}));
  };
