import {FilterCategory} from '../api/symfony/generated';
import {useFiltersQuery} from '../queries/filter/useFiltersQuery';
import {useWorkspacesQuery} from '../queries/useWorkspacesQuery';
import {useSelector} from '../redux/react-redux';
import {getWorkspaceId} from '../redux/selectors';
import {FeatureToggles} from '../utils/FeatureToggles';

type EasySharingState = {
  isEasySharingLoading: boolean;
  isEasySharingEnabled: boolean;
  isEasySharingSetupComplete: boolean;
  isOutboxRemovalCritical: boolean;
};

export const useGetEasySharingState = (): EasySharingState => {
  const currentWorkspaceId = useSelector(getWorkspaceId);

  const workspacesQuery = useWorkspacesQuery();
  const filtersQuery = useFiltersQuery({category: FilterCategory.Market});

  const isEasySharingLoading = workspacesQuery.isLoading || filtersQuery.isLoading;

  const workspaces = workspacesQuery.data;
  const currentWorkspace = workspaces?.find(workspace => workspace.id === currentWorkspaceId);
  const isEasySharingEnabled = !!currentWorkspace?.easySharing && FeatureToggles.easySharing;

  const filters = filtersQuery.data;
  const outboxCount = filters?.filter(filter => filter.outbox).length ?? 0;

  const isEasySharingSetupComplete = isEasySharingEnabled && outboxCount > 0;
  const isOutboxRemovalCritical = isEasySharingEnabled && outboxCount === 1;

  return {
    isEasySharingLoading,
    isEasySharingEnabled,
    isEasySharingSetupComplete,
    isOutboxRemovalCritical,
  };
};
