import {useSelector} from '../../../redux/react-redux';
import {invalidateFixturesQuery, useFixturesQuery} from '../../../components/Tools/FixtureGrid/useFixturesQuery';
import produce from 'immer';
import {getWorkspaceId} from '../../../redux/selectors';
import {useQueryClient} from '@tanstack/react-query';
import {VesselFixturesDatabaseFilter} from '../../../components/FilterProvider/Filters/Fixtures/VesselFixturesFilterBranchDefinitions';

export const useCanExportSaleFixturesQuery = ({filter}: {filter: VesselFixturesDatabaseFilter}) => {
  const workspaceId = useSelector(getWorkspaceId);
  const queryClient = useQueryClient();

  const newFilter = produce(filter, (draftFilter: VesselFixturesDatabaseFilter) => {
    if (draftFilter.fixtureType === undefined) {
      draftFilter.fixtureType = {contractTypes: [], isExtension: false, isOptionDeclared: false};
    }
    if (draftFilter.fixtureType.contractTypes.length === 0) {
      draftFilter.fixtureType.contractTypes = ['SALE'];
    }
  });

  const fixturesQuery = useFixturesQuery({
    filter: newFilter,
    gridState: {
      pageIndex: 1,
      pageSize: 0,
      sortField: '',
      sortOrder: 'desc',
    },
    workspaceId,
  });

  const refetch = async () => {
    await invalidateFixturesQuery(queryClient);
  };

  if (newFilter.fixtureType?.contractTypes === undefined) {
    return {value: false, refetch};
  }

  if (newFilter.fixtureType.contractTypes.includes('SALE')) {
    return {value: (fixturesQuery.data?.totalItems ?? 0) > 0, refetch};
  }

  return {value: false, refetch};
};
