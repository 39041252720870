import {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {ModalActions} from '../../redux/Modal';
import {useSelector} from '../../redux/react-redux';

/** @deprecated use AntD Modal instead */
const ModalRoot: FC = () => {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal.modal);
  const location = useLocation();
  const hideModal = () => {
    if (modal) {
      dispatch(ModalActions.hide());
    }
  };

  useEffect(() => {
    hideModal();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{modal}</>;
};

export default ModalRoot;
