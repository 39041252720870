import {FC} from 'react';
import {Cargo, CargoDisplay} from '../../../atoms/DropdownDisplay/CargoDisplay';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {SearchIndicator} from '../../../atoms/Select/Indicators';
import {DropdownSingleValue} from '../../../atoms/Select/otherComponents';
import Select from '../../../atoms/Select/Select';
import {TODO} from '../../../utils/TODO';

export const CargoTabPane: FC<{
  selectedValue: TODO;
  isLoading: boolean;
  onChange: (data: TODO) => void;
  searchDataCargo: TODO[];
  onSearch: (query: string, index: string) => void;
  onBlur: () => void;
}> = ({selectedValue, isLoading, searchDataCargo, onBlur, onSearch, onChange}) => {
  return (
    <LabelWrapper label="Cargo" style={{marginTop: '10px'}}>
      <Select<Cargo>
        id={'create-project-cargo-select'}
        value={selectedValue}
        isLoading={isLoading}
        options={searchDataCargo}
        onBlur={onBlur}
        components={{
          DropdownIndicator: SearchIndicator,
          // @ts-expect-error I don't know what the problem is
          SingleValue: DropdownSingleValue,
        }}
        onInputChange={data => onSearch(data, 'cargoes_portfolio')}
        onChange={onChange}
        getOptionLabel={option => <CargoDisplay cargo={option} />}
        getOptionValue={option => option.id.toString()}
        placeholder={'Search for cargoes in My Cargoes'}
        filterOption={() => true} // Disable client side filtering
        isClearable
      />
    </LabelWrapper>
  );
};
