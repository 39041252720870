import {FC} from 'react';
import {CargoSection} from './CargoSection';
import {VesselSection} from './VesselSection';
import {useProjectDetails} from '../useProjectDetails';
import {ProjectDetailCard} from '../ProjectDetailCard';
import {CargoItemHeader} from '../../../screens/CargoDetail/CargoDetailHeader/CargoItemHeader';
import {VesselItemHeader} from '../../../screens/VesselDetailScreen/sections/Header/VesselItemHeader';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Button} from 'antd';

export const ProjectDetailItem: FC = () => {
  const {
    project: {vessel, cargo, type},
  } = useProjectDetails();
  const isVessel = type === 'vessel';

  const url = isVessel ? `/vessel/${vessel.id}` : `/cargo/${cargo.id}`;

  return (
    <ProjectDetailCard
      header={
        <FlexBox>
          <span>Project {capitalize(type)}</span>
          <Link to={url}>
            <Button type="default">View details</Button>
          </Link>
        </FlexBox>
      }>
      <Container>
        {isVessel ? <VesselItemHeader vessel={vessel} /> : <CargoItemHeader cargo={cargo} />}
        {isVessel ? <VesselSection vessel={vessel} /> : <CargoSection cargo={cargo} />}
      </Container>
    </ProjectDetailCard>
  );
};

const Container = styled.div`
  margin-top: -5px;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
