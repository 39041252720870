import LoadingOverlay from '../../atoms/Loading/LoadingOverlay';
import React, {useState} from 'react';
import {Section} from './Section/Section';
import {useTiedUpVesselsStatisticsQuery} from './useTiedUpVesselsStatisticsQuery';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';

export const TiedUpVesselsScreen = () => {
  const [viewKey, setViewKey] = useState<'bulker' | 'container'>('bulker');

  const tiedUpVesselsStatisticsQuery = useTiedUpVesselsStatisticsQuery();

  if (!tiedUpVesselsStatisticsQuery.isSuccess) {
    return <LoadingOverlay />;
  }

  return (
    <div data-testid={'TiedUpVesselsScreen'}>
      <ScreenHeader
        helmetTitle="Tied Up Vessels"
        breadcrumbs={[{title: 'Lab'}, {title: 'Tied Up Vessels'}]}
        features={
          <ScreenHeader.Features.Toggle
            data-cy="TiedUpViewToggle"
            value={viewKey}
            onChange={event => setViewKey(event.target.value)}
            options={[
              {
                id: 'show-bulker',
                label: 'Bulker',
                value: 'bulker',
                props: {
                  'data-testid': 'TiedUpShowBulkerBTN',
                },
              },
              {
                id: 'show-container',
                label: 'Container',
                value: 'container',
                props: {
                  'data-testid': 'TiedUpShowContainerBTN',
                },
              },
            ]}
          />
        }
      />
      <p>
        This report shows the distribution of the tied up vessels on the level of the trading areas. <br />
        Vessels are considered tied up if they stay at the same location for 24 hours.
      </p>
      {viewKey === 'bulker' && (
        <Section tradingAreaTiedUpVesselsStatistics={tiedUpVesselsStatisticsQuery.data} vesselType={'bulker'} />
      )}
      {viewKey === 'container' && (
        <Section tradingAreaTiedUpVesselsStatistics={tiedUpVesselsStatisticsQuery.data} vesselType={'container'} />
      )}
    </div>
  );
};
