import React, {CSSProperties, FC} from 'react';
import classNames from 'classnames';

import './style.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HtmlElement = any;

interface Props {
  style?: CSSProperties;
  onClick?: () => void;
  disabled?: boolean;
  Tag?: HtmlElement;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  center?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Title: FC<Props> = ({
  style = {},
  onClick = () => {},
  disabled = false,
  Tag = 'h2',
  small,
  medium,
  large,
  center,
  className,
  children,
}) => {
  const css = {
    title: true,
    'title--small': small,
    'title--medium': medium,
    'title--large': large,
    'title--center': center,
  };
  return (
    <Tag className={classNames(css, className)} style={style} onClick={() => !disabled && onClick()}>
      {children}
    </Tag>
  );
};

export default Title;
