import {Fragment} from 'react';
import {Button} from '../../../../../atoms/Button/Button';
import Title from '../../../../../atoms/Title';
import styled from 'styled-components';

export const CompanyWorkspaceEmptyState = ({isNew, onClick}: $TSFixMe) => {
  return (
    <Fragment>
      <StyledCompanyWorkspaceEmptyState>
        <img src={'/static/images/seanexxt/empty-states-contact-no-contacts.png'} alt={'missing workspace'} />
        <StyledCompanyWorkspaceEmptyStateHeadline>
          <Title large>No active workspaces</Title>
        </StyledCompanyWorkspaceEmptyStateHeadline>
        <StyledCompanyWorkspaceEmptyStateSubline>
          Create a new workspace
          {isNew ? <div>to organize your company users</div> : <div>or reactivate one below</div>}
        </StyledCompanyWorkspaceEmptyStateSubline>
        <Button label={'add workspace'} primary bold upper onClick={onClick} />
      </StyledCompanyWorkspaceEmptyState>
    </Fragment>
  );
};

const StyledCompanyWorkspaceEmptyState = styled.div`
  text-align: center;
  margin-top: 60px;
`;

const StyledCompanyWorkspaceEmptyStateHeadline = styled.div`
  margin-top: 40px;
`;

const StyledCompanyWorkspaceEmptyStateSubline = styled.div`
  width: 300px;
  margin: 20px auto;
  color: var(--color-gray-2);
`;
