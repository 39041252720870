/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  ExtraFittings,
  ExtraFittingsFromJSON,
  ExtraFittingsFromJSONTyped,
  ExtraFittingsToJSON,
  Laycan,
  LaycanFromJSON,
  LaycanFromJSONTyped,
  LaycanToJSON,
} from './';

/**
 * Parsed and enriched data for a vessel offer
 * @export
 * @interface ParsedVesselData
 */
export interface ParsedVesselData {
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  fillCounter?: number;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  dirtiness?: number;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  confidence?: number;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  vesselName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  flag?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  isoFlag?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  yearBuilt?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  imoNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  size?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  draft?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  tpc?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  grainCapacity?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  baleCapacity?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  length?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  beam?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  holds?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  hatches?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  cranes?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  grabs?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  craneCapacity?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  grabCapacity?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedVesselData
   */
  lastCargoes?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  feu?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  grt?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  nrt?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  speedLaden?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  consumptionLaden?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  speedBallast?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  consumptionBallast?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  consumptionPort?: number | null;
  /**
   *
   * @type {ExtraFittings}
   * @memberof ParsedVesselData
   */
  extraFittings?: ExtraFittings | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  vesselType?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParsedVesselData
   */
  port?: Array<string> | null;
  /**
   *
   * @type {Laycan}
   * @memberof ParsedVesselData
   */
  date?: Laycan | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  consumptionPortWorking?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  ecoConsumptionBallast?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  ecoConsumptionLaden?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  ecoSpeedBallast?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  ecoSpeedLaden?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  fillLevel?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  formerSynopsis?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  forSale?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  teu?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  teu14?: number | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  reefer?: number | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  builder?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  design?: string | null;
  /**
   *
   * @type {number}
   * @memberof ParsedVesselData
   */
  lineNumber?: number | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  mmsi?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ParsedVesselData
   */
  completeSpeedBallast?: Array<number> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ParsedVesselData
   */
  completeSpeedLaden?: Array<number> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ParsedVesselData
   */
  completeConsumptionBallast?: Array<number> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ParsedVesselData
   */
  completeConsumptionLaden?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof ParsedVesselData
   */
  subType?: string;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  empty?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  positionOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  nameOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  positionNameOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  missingNameOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  missingPositionNameOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParsedVesselData
   */
  missingPositionOnly?: boolean;
}

export function ParsedVesselDataFromJSON(json: any): ParsedVesselData {
  return ParsedVesselDataFromJSONTyped(json, false);
}

export function ParsedVesselDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsedVesselData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fillCounter: !exists(json, 'fillCounter') ? undefined : json['fillCounter'],
    dirtiness: !exists(json, 'dirtiness') ? undefined : json['dirtiness'],
    confidence: !exists(json, 'confidence') ? undefined : json['confidence'],
    vesselName: !exists(json, 'vesselName') ? undefined : json['vesselName'],
    flag: !exists(json, 'flag') ? undefined : json['flag'],
    isoFlag: !exists(json, 'isoFlag') ? undefined : json['isoFlag'],
    yearBuilt: !exists(json, 'yearBuilt') ? undefined : json['yearBuilt'],
    imoNumber: !exists(json, 'imoNumber') ? undefined : json['imoNumber'],
    size: !exists(json, 'size') ? undefined : json['size'],
    draft: !exists(json, 'draft') ? undefined : json['draft'],
    tpc: !exists(json, 'tpc') ? undefined : json['tpc'],
    grainCapacity: !exists(json, 'grainCapacity') ? undefined : json['grainCapacity'],
    baleCapacity: !exists(json, 'baleCapacity') ? undefined : json['baleCapacity'],
    length: !exists(json, 'length') ? undefined : json['length'],
    beam: !exists(json, 'beam') ? undefined : json['beam'],
    holds: !exists(json, 'holds') ? undefined : json['holds'],
    hatches: !exists(json, 'hatches') ? undefined : json['hatches'],
    cranes: !exists(json, 'cranes') ? undefined : json['cranes'],
    grabs: !exists(json, 'grabs') ? undefined : json['grabs'],
    craneCapacity: !exists(json, 'craneCapacity') ? undefined : json['craneCapacity'],
    grabCapacity: !exists(json, 'grabCapacity') ? undefined : json['grabCapacity'],
    lastCargoes: !exists(json, 'lastCargoes') ? undefined : json['lastCargoes'],
    feu: !exists(json, 'feu') ? undefined : json['feu'],
    grt: !exists(json, 'grt') ? undefined : json['grt'],
    nrt: !exists(json, 'nrt') ? undefined : json['nrt'],
    speedLaden: !exists(json, 'speedLaden') ? undefined : json['speedLaden'],
    consumptionLaden: !exists(json, 'consumptionLaden') ? undefined : json['consumptionLaden'],
    speedBallast: !exists(json, 'speedBallast') ? undefined : json['speedBallast'],
    consumptionBallast: !exists(json, 'consumptionBallast') ? undefined : json['consumptionBallast'],
    consumptionPort: !exists(json, 'consumptionPort') ? undefined : json['consumptionPort'],
    extraFittings: !exists(json, 'extraFittings') ? undefined : ExtraFittingsFromJSON(json['extraFittings']),
    vesselType: !exists(json, 'vesselType') ? undefined : json['vesselType'],
    port: !exists(json, 'port') ? undefined : json['port'],
    date: !exists(json, 'date') ? undefined : LaycanFromJSON(json['date']),
    consumptionPortWorking: !exists(json, 'consumptionPortWorking') ? undefined : json['consumptionPortWorking'],
    ecoConsumptionBallast: !exists(json, 'ecoConsumptionBallast') ? undefined : json['ecoConsumptionBallast'],
    ecoConsumptionLaden: !exists(json, 'ecoConsumptionLaden') ? undefined : json['ecoConsumptionLaden'],
    ecoSpeedBallast: !exists(json, 'ecoSpeedBallast') ? undefined : json['ecoSpeedBallast'],
    ecoSpeedLaden: !exists(json, 'ecoSpeedLaden') ? undefined : json['ecoSpeedLaden'],
    fillLevel: !exists(json, 'fillLevel') ? undefined : json['fillLevel'],
    formerSynopsis: !exists(json, 'formerSynopsis') ? undefined : json['formerSynopsis'],
    forSale: !exists(json, 'forSale') ? undefined : json['forSale'],
    teu: !exists(json, 'teu') ? undefined : json['teu'],
    teu14: !exists(json, 'teu14') ? undefined : json['teu14'],
    reefer: !exists(json, 'reefer') ? undefined : json['reefer'],
    builder: !exists(json, 'builder') ? undefined : json['builder'],
    design: !exists(json, 'design') ? undefined : json['design'],
    lineNumber: !exists(json, 'lineNumber') ? undefined : json['lineNumber'],
    mmsi: !exists(json, 'mmsi') ? undefined : json['mmsi'],
    completeSpeedBallast: !exists(json, 'completeSpeedBallast') ? undefined : json['completeSpeedBallast'],
    completeSpeedLaden: !exists(json, 'completeSpeedLaden') ? undefined : json['completeSpeedLaden'],
    completeConsumptionBallast: !exists(json, 'completeConsumptionBallast')
      ? undefined
      : json['completeConsumptionBallast'],
    completeConsumptionLaden: !exists(json, 'completeConsumptionLaden') ? undefined : json['completeConsumptionLaden'],
    subType: !exists(json, 'subType') ? undefined : json['subType'],
    empty: !exists(json, 'empty') ? undefined : json['empty'],
    complete: !exists(json, 'complete') ? undefined : json['complete'],
    positionOnly: !exists(json, 'positionOnly') ? undefined : json['positionOnly'],
    nameOnly: !exists(json, 'nameOnly') ? undefined : json['nameOnly'],
    positionNameOnly: !exists(json, 'positionNameOnly') ? undefined : json['positionNameOnly'],
    missingNameOnly: !exists(json, 'missingNameOnly') ? undefined : json['missingNameOnly'],
    missingPositionNameOnly: !exists(json, 'missingPositionNameOnly') ? undefined : json['missingPositionNameOnly'],
    missingPositionOnly: !exists(json, 'missingPositionOnly') ? undefined : json['missingPositionOnly'],
  };
}

export function ParsedVesselDataToJSON(value?: ParsedVesselData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fillCounter: value.fillCounter,
    dirtiness: value.dirtiness,
    confidence: value.confidence,
    vesselName: value.vesselName,
    flag: value.flag,
    isoFlag: value.isoFlag,
    yearBuilt: value.yearBuilt,
    imoNumber: value.imoNumber,
    size: value.size,
    draft: value.draft,
    tpc: value.tpc,
    grainCapacity: value.grainCapacity,
    baleCapacity: value.baleCapacity,
    length: value.length,
    beam: value.beam,
    holds: value.holds,
    hatches: value.hatches,
    cranes: value.cranes,
    grabs: value.grabs,
    craneCapacity: value.craneCapacity,
    grabCapacity: value.grabCapacity,
    lastCargoes: value.lastCargoes,
    feu: value.feu,
    grt: value.grt,
    nrt: value.nrt,
    speedLaden: value.speedLaden,
    consumptionLaden: value.consumptionLaden,
    speedBallast: value.speedBallast,
    consumptionBallast: value.consumptionBallast,
    consumptionPort: value.consumptionPort,
    extraFittings: ExtraFittingsToJSON(value.extraFittings),
    vesselType: value.vesselType,
    port: value.port,
    date: LaycanToJSON(value.date),
    consumptionPortWorking: value.consumptionPortWorking,
    ecoConsumptionBallast: value.ecoConsumptionBallast,
    ecoConsumptionLaden: value.ecoConsumptionLaden,
    ecoSpeedBallast: value.ecoSpeedBallast,
    ecoSpeedLaden: value.ecoSpeedLaden,
    fillLevel: value.fillLevel,
    formerSynopsis: value.formerSynopsis,
    forSale: value.forSale,
    teu: value.teu,
    teu14: value.teu14,
    reefer: value.reefer,
    builder: value.builder,
    design: value.design,
    lineNumber: value.lineNumber,
    mmsi: value.mmsi,
    completeSpeedBallast: value.completeSpeedBallast,
    completeSpeedLaden: value.completeSpeedLaden,
    completeConsumptionBallast: value.completeConsumptionBallast,
    completeConsumptionLaden: value.completeConsumptionLaden,
    subType: value.subType,
    empty: value.empty,
    complete: value.complete,
    positionOnly: value.positionOnly,
    nameOnly: value.nameOnly,
    positionNameOnly: value.positionNameOnly,
    missingNameOnly: value.missingNameOnly,
    missingPositionNameOnly: value.missingPositionNameOnly,
    missingPositionOnly: value.missingPositionOnly,
  };
}
