/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineEventTypeFromJSON,
  VesselVoyageTimelineEventTypeFromJSONTyped,
  VesselVoyageTimelineEventTypeToJSON,
  VoyagePort,
  VoyagePortFromJSON,
  VoyagePortFromJSONTyped,
  VoyagePortToJSON,
} from './';

/**
 *
 * @export
 * @interface PassingPortVisit
 */
export interface PassingPortVisit {
  /**
   *
   * @type {VesselVoyageTimelineEventType}
   * @memberof PassingPortVisit
   */
  eventType: VesselVoyageTimelineEventType;
  /**
   *
   * @type {number}
   * @memberof PassingPortVisit
   */
  imo: number;
  /**
   *
   * @type {VoyagePort}
   * @memberof PassingPortVisit
   */
  port: VoyagePort;
  /**
   *
   * @type {Date}
   * @memberof PassingPortVisit
   */
  enterTimestamp: Date;
  /**
   *
   * @type {Date}
   * @memberof PassingPortVisit
   */
  exitTimestamp: Date | null;
  /**
   * The draft of the vessel when it entered the port
   * @type {number}
   * @memberof PassingPortVisit
   */
  enterDraft: number;
  /**
   * The draft of the vessel when it exited the port
   * @type {number}
   * @memberof PassingPortVisit
   */
  exitDraft: number | null;
}

export function PassingPortVisitFromJSON(json: any): PassingPortVisit {
  return PassingPortVisitFromJSONTyped(json, false);
}

export function PassingPortVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassingPortVisit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeFromJSON(json['eventType']),
    imo: json['imo'],
    port: VoyagePortFromJSON(json['port']),
    enterTimestamp: new Date(json['enterTimestamp']),
    exitTimestamp: json['exitTimestamp'] === null ? null : new Date(json['exitTimestamp']),
    enterDraft: json['enterDraft'],
    exitDraft: json['exitDraft'],
  };
}

export function PassingPortVisitToJSON(value?: PassingPortVisit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    eventType: VesselVoyageTimelineEventTypeToJSON(value.eventType),
    imo: value.imo,
    port: VoyagePortToJSON(value.port),
    enterTimestamp: value.enterTimestamp.toISOString(),
    exitTimestamp: value.exitTimestamp === null ? null : value.exitTimestamp.toISOString(),
    enterDraft: value.enterDraft,
    exitDraft: value.exitDraft,
  };
}
