import {FirstLastTimestamp, RouteItem} from '../useRouteTripLayer';

export const getFirstLastTimestampsFromRouteItems = (routeItems: RouteItem[]): FirstLastTimestamp => {
  let min = Infinity;
  let max = 0;

  routeItems.forEach(item => {
    const {first, last} = item.firstLast;

    if (first < min) {
      min = first;
    }
    if (last > max) {
      max = last;
    }
  });

  return {
    first: min,
    last: max,
  };
};
