import React, {FC} from 'react';
import {Modal, Steps} from 'antd';
import {ProjectVessels} from './ProjectVessels';
import {useGoToMultiCalc} from '../utils/useGoToMultiCalc';

export const MultiCalcVesselModal: FC<{visible: boolean; onClose: () => void}> = ({visible, onClose}) => {
  const goToMultiCalc = useGoToMultiCalc();

  return (
    <Modal
      data-cy={'MultiCalcVesselModal'}
      width={1000}
      footer={null}
      onCancel={onClose}
      title={'Multi calculation (VC)'}
      open={visible}
      destroyOnClose>
      <Steps
        current={1}
        size={'small'}
        items={[{title: 'Cargoes'}, {title: 'Vessel Projects'}, {title: 'Results'}]}
        style={{marginBottom: 32}}
      />
      <ProjectVessels
        onSubmit={params => {
          goToMultiCalc({
            cargoIds: params.cargoIds,
            projectIds: params.projectIds,
          });

          onClose();
        }}
      />
    </Modal>
  );
};
