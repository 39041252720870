import anime from 'animejs';
import {AnimationState} from '../model/AnimationState';
import {AnimeAnimation, OnUpdateFunc} from '../VoyageAnimation/AnimeAnimation';
import {RefObject} from 'react';
import {AnimationEvent, isSeaSegment} from '../model/AnimationEvent';
import {MapRef} from 'react-map-gl';
import {LonLatCoordinates} from '../../../../../utils/Coordinates';
import {assert, assertUnreachable} from '../../../../../utils/assert';
import {atBegin} from './easing';
import {ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT} from './ZoomLevel';
import {animatePortEntry} from './animatePortEntry';
import {animatePortExit} from './animatePortExit';
import {animateSeaSegment} from './animateSeaSegment';

export const makeAnimeAnimation = (
  animationStartCoordinates: LonLatCoordinates,
  animationEvents: AnimationEvent[],
  mapRef: RefObject<MapRef>,
  animationSpeed: number,
  autoplay: boolean,
  onUpdateRef: RefObject<OnUpdateFunc>
): AnimeAnimation => {
  // eslint-disable-next-line no-console
  console.time('makeAnimationTimer');

  const firstSeaSegment = animationEvents.find(isSeaSegment);
  assert(firstSeaSegment, 'No sea segment found');
  const initialAnimationState: AnimationState = {
    timestamp: firstSeaSegment.from.timestamp.valueOf(),
    longitude: firstSeaSegment.from.coordinates[0],
    latitude: firstSeaSegment.from.coordinates[1],
    angle: firstSeaSegment.heading,
    cameraFollowsVessel: 1,
    standingStill: 0,
    portVisitId: -1,
    aisRecordCsvRow: 0,
    portAreaVibility: 0,
  };

  // The state mutated by the animation.
  const animationState: AnimationState = {
    ...initialAnimationState,
  };

  // The timeline is the animation control object.
  const animeTimeline = anime.timeline({
    targets: animationState,
    autoplay,
    loop: false,
    update() {
      onUpdateRef.current!(animationState);
    },
  });
  // At the beginning of the animation, set the properties to their initial values.
  animeTimeline.add({
    ...initialAnimationState,
    easing: atBegin,
    duration: 100,
  });

  animeTimeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log('Fly to start coordinates');
      mapRef.current!.flyTo({
        zoom: ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT,
        minZoom: ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT,
        center: animationStartCoordinates,
        duration: 200,
      });
      if (mapRef.current!.getZoom() !== ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT) {
        // For some quirky reason, Mapbox flyTo does not always set the zoom level correctly.
        // eslint-disable-next-line no-console
        console.log('Set zoom');
        mapRef.current!.setZoom(ZOOM_LEVEL_FOR_SEA_VOYAGE_OUTSIDE_PORT);
      }
    },
    duration: 500,
  });

  // Generate keyframes for each event in the voyage.
  for (let animationEventIndex = 0; animationEventIndex < animationEvents.length; ) {
    const eventType = animationEvents[animationEventIndex].type;
    switch (eventType) {
      case 'sea-segment':
        animationEventIndex = animateSeaSegment({
          animationEventIndex,
          animationEvents,
          animationSpeed,
          timeline: animeTimeline,
        });
        break;
      case 'port-entry':
        animationEventIndex = animatePortEntry({
          animationEventIndex,
          animationEvents,
          animationSpeed,
          timeline: animeTimeline,
          mapRef,
        });
        break;
      case 'port-exit':
        animationEventIndex = animatePortExit({
          animationEventIndex,
          animationEvents,
          animationSpeed,
          timeline: animeTimeline,
          mapRef,
        });
        break;
      default:
        assertUnreachable(eventType);
    }
  }

  const animation: AnimeAnimation = {
    onUpdateRef,
    animationState,
    animeTimeline,
  };

  // eslint-disable-next-line no-console
  console.timeEnd('makeAnimationTimer');

  return animation;
};
