import {useMemo, FC} from 'react';
import {MergedVisits} from './utils/useCongestionVisitsWithVesselInformationsQuery';
import {Col} from 'antd';
import {TiedUpBulkerVessels} from './TiedUpBulkerVessels';
import {TiedUpContainerVessels} from './TiedUpContainerVessels';

export const TiedUp: FC<{currentVisits: MergedVisits[]}> = ({currentVisits}) => {
  const currentContainer = useMemo(
    () => currentVisits.filter(visit => visit.vesselType === 'CONTAINER'),
    [currentVisits]
  );

  const currentBulker = useMemo(() => currentVisits.filter(visit => visit.vesselType === 'BULKER'), [currentVisits]);

  return (
    <>
      <Col span={12}>
        <TiedUpBulkerVessels currentBulkerVisits={currentBulker} />
      </Col>
      <Col span={12}>
        <TiedUpContainerVessels currentContainerVisits={currentContainer} />
      </Col>
    </>
  );
};
