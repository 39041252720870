import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../../../../api/utils/fetchJSON';
import {MapDetailArea, PortAreaCV} from '../../../utils/types';

export const useAreaItemsQuery = (portlessAreas: MapDetailArea[]) =>
  useQuery({
    queryKey: ['mapDetailAreaQuery', portlessAreas.map(area => area.id)],
    queryFn: async () => {
      const areaIdsString = portlessAreas.map(area => area.id).join(',');
      const areaItems = await fetchJSON<{data: PortAreaCV}>(
        `/api/map/area-items?areas=${areaIdsString}&portless=true&pageSize=2000`
      );
      return areaItems.data;
    },
    enabled: !!portlessAreas.length,
  });
