/* tslint:disable */
/* eslint-disable */
/**
 * BunkerService
 * BunkerService
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Query
 */
export interface Query {
  /**
   * Bottom left corner of geo search box (optional)
   * @type {number}
   * @memberof Query
   */
  longitudeBottomLeft?: number;
  /**
   * Bottom left corner of geo search box (optional)
   * @type {number}
   * @memberof Query
   */
  latitudeBottomLeft?: number;
  /**
   * Upper right corner of geo search box (optional)
   * @type {number}
   * @memberof Query
   */
  longitudeUpperRight?: number;
  /**
   * Upper right corner of geo search box (optional)
   * @type {number}
   * @memberof Query
   */
  latitudeUpperRight?: number;
  /**
   * Search by UN-Loccode (optional if searching by bbox)
   * @type {Array<string>}
   * @memberof Query
   */
  unlocodes?: Array<string>;
  /**
   * Search archives: from (optional)
   * @type {Date}
   * @memberof Query
   */
  from?: Date;
  /**
   * Search archives: to (optional)
   * @type {Date}
   * @memberof Query
   */
  to?: Date;
  /**
   * Should futures be returned (slower)
   * @type {boolean}
   * @memberof Query
   */
  includeFutures?: boolean;
  /**
   * SLIM / DEFAULT
   * @type {string}
   * @memberof Query
   */
  outputFormat?: QueryOutputFormatEnum;
  /**
   * "IFO380", "MGO", "VLSFO", "ULSFO", "IFO180"
   * @type {Array<string>}
   * @memberof Query
   */
  grades?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum QueryOutputFormatEnum {
  Slim = 'SLIM',
  Default = 'DEFAULT',
}

export function QueryFromJSON(json: any): Query {
  return QueryFromJSONTyped(json, false);
}

export function QueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Query {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    longitudeBottomLeft: !exists(json, 'longitudeBottomLeft') ? undefined : json['longitudeBottomLeft'],
    latitudeBottomLeft: !exists(json, 'latitudeBottomLeft') ? undefined : json['latitudeBottomLeft'],
    longitudeUpperRight: !exists(json, 'longitudeUpperRight') ? undefined : json['longitudeUpperRight'],
    latitudeUpperRight: !exists(json, 'latitudeUpperRight') ? undefined : json['latitudeUpperRight'],
    unlocodes: !exists(json, 'unlocodes') ? undefined : json['unlocodes'],
    from: !exists(json, 'from') ? undefined : new Date(json['from']),
    to: !exists(json, 'to') ? undefined : new Date(json['to']),
    includeFutures: !exists(json, 'includeFutures') ? undefined : json['includeFutures'],
    outputFormat: !exists(json, 'outputFormat') ? undefined : json['outputFormat'],
    grades: !exists(json, 'grades') ? undefined : json['grades'],
  };
}

export function QueryToJSON(value?: Query | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    longitudeBottomLeft: value.longitudeBottomLeft,
    latitudeBottomLeft: value.latitudeBottomLeft,
    longitudeUpperRight: value.longitudeUpperRight,
    latitudeUpperRight: value.latitudeUpperRight,
    unlocodes: value.unlocodes,
    from: value.from === undefined ? undefined : value.from.toISOString(),
    to: value.to === undefined ? undefined : value.to.toISOString(),
    includeFutures: value.includeFutures,
    outputFormat: value.outputFormat,
    grades: value.grades,
  };
}
