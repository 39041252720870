import React, {useContext, useEffect, useRef, useState} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {Input} from 'antd';
import Icon from '../../../../atoms/Icon';
import useOnClickOutside from 'use-onclickoutside';

export const CalculatorNote = () => {
  const voyageContext = useContext(VoyageCharterContext);

  const {inputState} = voyageContext.state;
  const {setInputState} = voyageContext;

  const [showInput, setShowInput] = useState(false);
  const [textInput, setTextInput] = useState(inputState.calculator.note);
  useEffect(() => {
    setTextInput(inputState.calculator.note);
  }, [inputState.calculator.note]);

  const divRef = useRef<HTMLDivElement>(null);

  const updateTextInput = (text: string | undefined) => {
    setInputState({...inputState, calculator: {...inputState.calculator, note: text}});
  };

  useOnClickOutside(divRef, () => {
    if (showInput) {
      updateTextInput(textInput);
      setShowInput(false);
    }
  });

  const inputFiled = (
    <Input.TextArea
      data-cy="calculatorNoteEdit"
      rows={8}
      onBlur={() => {
        updateTextInput(textInput);
        setShowInput(false);
      }}
      value={textInput}
      onChange={v => {
        setTextInput(v.currentTarget.value);
      }}
    />
  );
  const openButton = (
    <span
      data-cy="calculatorNoteView"
      style={{cursor: 'pointer'}}
      onClick={() => {
        setShowInput(true);
      }}>
      {inputState.calculator.note}
      <a style={{fontSize: 14, fontWeight: 700}}>
        {!inputState.calculator.note ? (
          '+ Add note'
        ) : (
          <Icon className={'editMarker'} size={'unset'} style={{verticalAlign: 'bottom'}} type={'edit'} />
        )}
      </a>
    </span>
  );

  return <div ref={divRef}>{showInput ? inputFiled : openButton}</div>;
};
