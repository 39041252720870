import {useCallback, useEffect, useState} from 'react';
import {Button, Form} from 'antd';
import {SettingsFormValues} from './SettingsFormValues';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {Store} from 'antd/es/form/interface';

import {accountManagementApi} from '../../../api/symfony/symfonyApi';
import {Settings} from '../../../api/symfony/generated';
import {ApiError} from '../../../api/utils/ApiError';
import {TimezoneSelector} from '../../../atoms/TimezoneSelector/TimezoneSelector';
import {Helmet} from 'react-helmet-async';
import {invalidateCurrentUserQuery} from '../../../queries/useCurrentUserQuery';
import {assert} from '../../../utils/assert';
import {SettingsCardBody} from '../../../components/SettingsCard/SettingsCardBody';
import {SettingsCard} from '../../../components/SettingsCard/SettingsCard';
import {SettingsCardHeader} from '../../../components/SettingsCard/SettingsCardHeader';
import {
  SettingsSection,
  SettingsSectionBody,
  SettingsSectionTitle,
} from '../../../components/SettingsCard/SettingsSection';
import {SettingsButtons} from '../../../components/SettingsCard/SettingsButtons';
import {showNotification} from '../../../utils/notification';

const QUERY_KEY = 'account-management/settings';

export const Timezone = () => {
  const [formKey, setFormKey] = useState(1);
  const resetForm = useCallback(() => {
    setFormKey(formKey + 1);
  }, [formKey, setFormKey]);

  const queryResult = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: (): Promise<Settings> => accountManagementApi.getSettings(),
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  // The mutation that saves the data to be edited
  const updateSettingsMutation = useMutation<Settings, ApiError, Settings>({
    mutationFn: (settings: Settings): Promise<Settings> => accountManagementApi.updateSettings({settings}),
    onSuccess: async () => {
      // Reload account data
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateCurrentUserQuery(queryClient);
      await showNotification('Your settings have been saved');
    },
  });

  const onSubmit = (formValues: Store) => {
    updateSettingsMutation.mutate({...initialValues, ...(formValues as Settings)});
  };

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.isSuccess, queryResult.data]);

  if (queryResult.isLoading) {
    return <span style={{visibility: 'hidden'}}>Loading account data</span>;
  }

  if (queryResult.isError) {
    // Message will be displayed via message.error
    return <span>Error</span>;
  }

  assert(queryResult.data);
  const initialValues: SettingsFormValues = queryResult.data;

  return (
    <>
      <Helmet title="Timezone - User account" />
      <SettingsCard data-testid="Timezone">
        <SettingsCardHeader title={'Time zone'} />

        <SettingsCardBody>
          <Form
            key={formKey}
            name="basic"
            initialValues={initialValues}
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => {}}>
            <SettingsSection $antForm>
              <SettingsSectionTitle>Choose time zone</SettingsSectionTitle>
              <SettingsSectionBody>
                <Form.Item
                  label="Time zone"
                  name={['general', 'timezone']}
                  rules={[{required: true, message: 'Timezone is required'}]}>
                  <TimezoneSelector data-testid="general_timezone" />
                </Form.Item>
              </SettingsSectionBody>
            </SettingsSection>
            <SettingsButtons>
              <Button data-testid="Submit" type="primary" htmlType="submit" disabled={updateSettingsMutation.isPending}>
                Save
              </Button>
            </SettingsButtons>
          </Form>
        </SettingsCardBody>
      </SettingsCard>
    </>
  );
};
