import {InlineNumberEditor} from '../../../../atoms/InlineNumberEditor/InlineNumberEditor';
import numeral from 'numeral';
import {EditableHeaderBox} from './EditableHeaderBox';
import {EditorFormattedValueLabel} from './EditorFormattedValueLabel';

export const FreightIdea = (props: {
  freightIdea: number | undefined;
  onChangeIdea: (value: number | undefined) => void;
}) => {
  return (
    <EditableHeaderBox
      title="Freight idea"
      childWidth={'80%'}
      hideRadiusRight
      renderInput={(editing, setEditMode) => (
        <InlineNumberEditor
          min={0}
          closeOnBlur={false}
          size={'large'}
          editing={editing}
          closeOnEnter={true}
          onEditingChanged={setEditMode}
          editModeAvailable={false}
          editButtonAlignment="text-bottom"
          value={props.freightIdea ?? 0}
          formattedValue={
            <EditorFormattedValueLabel data-cy={'freightIdea'}>
              ${numeral(props.freightIdea).format('0,0[.]0[0]')}
            </EditorFormattedValueLabel>
          }
          onChange={value => props.onChangeIdea(Math.abs(value))}
        />
      )}
    />
  );
};
