import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../../../../api/utils/fetchJSON';
import {MapDetailVesselType} from '../../../utils/types';
import {assert} from '../../../../../../utils/assert';
import {UseQueryOptions} from '../../../../../../api/utils/ReactQuery';

export const useMapDetailsVesselQuery = (imo: number | undefined, options?: UseQueryOptions<MapDetailVesselType>) =>
  useQuery({
    queryKey: ['/api/map/vessels/', imo],
    queryFn: async () => {
      assert(imo);
      const mapDetailVessel = await fetchJSON<MapDetailVesselType>(`/api/map/vessels/${imo}`);
      return mapDetailVessel;
    },
    ...options,
  });
