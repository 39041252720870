import {makeApiService, ApiServiceEntry} from './ApiService';
import {TODO} from '../../utils/TODO';

const entries: ApiServiceEntry[] = [
  {
    fn: 'uploadVesselImages',
    route: '/api/attachments/vessel-image',
    method: 'POST',
    isFormData: true,
  },
  {
    fn: 'uploadVesselDocuments',
    route: '/api/attachments/vessel-document',
    method: 'POST',
    isFormData: true,
  },
  {
    fn: 'uploadCargoDocuments',
    route: '/api/attachments/cargo-document',
    method: 'POST',
    isFormData: true,
  },
  {
    fn: 'deleteAttachmentByUuid',
    route: '/api/attachments/{id}',
    method: 'DELETE',
    storeData: false,
  },
  {
    fn: 'uploadContactGroupImages',
    route: '/api/attachments/contactGroup-image',
    method: 'POST',
    isFormData: true,
  },
];

const attachment = makeApiService(entries, {apiName: 'attachment'});

export const reducers = attachment.reducers;
export const actions = attachment.actions;
export const actionTypes = attachment.actionTypes;

export const AttachmentServiceActions = {
  deleteAttachmentByUuid: (id: TODO) => actions.deleteAttachmentByUuid({params: {id}}),
};

export default AttachmentServiceActions;
