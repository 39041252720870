import dayjs from 'dayjs';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {GroupHeading} from '../../../atoms/Select/otherComponents';
import {Toggle} from '../../../atoms/Toggle/Toggle';
import {getLocationValues} from '../../../components/LocationOutput/getLocationValues';
import {
  DropdownMultiValueLocation,
  getLocationSelectOption,
} from '../../../components/LocationOutput/LocationOutputRow';
import locationApi from '../../../redux/ApiService/locationService';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {dateEarlierThanOrEqualTo} from '../../../utils/validators';
import {AsyncMultiSelect} from '../../../atoms/Select/AsyncMultiSelect';
import {StyledRangePicker} from '../../../components/RangePicker/StyledRangePicker';
import {TODO} from '../../../utils/TODO';
import {assert} from '../../../utils/assert';

interface InternalFormValues {
  nextOpenFrom: null | dayjs.Dayjs;
  nextOpenTo: null | dayjs.Dayjs;
  stations: TODO[];
  isRange: boolean;
  spotState: boolean;
}

const Wrapper = BindToProvider(
  'Next open',
  'nextOpen',
  {
    nextOpenFrom: null,
    nextOpenTo: null,
    stations: [],
    isRange: false,
    spotState: false,
  },
  {
    nextOpenFrom: dateEarlierThanOrEqualTo('nextOpenTo'),
  },
  {
    toApi: ({nextOpenFrom, nextOpenTo, stations, isRange, spotState}: InternalFormValues) => {
      assert(nextOpenFrom === null || dayjs.isDayjs(nextOpenFrom));
      assert(nextOpenTo === null || dayjs.isDayjs(nextOpenTo));

      return {
        nextOpenFrom: nextOpenFrom?.format('YYYY-MM-DD') ?? null,
        nextOpenTo: nextOpenTo?.format('YYYY-MM-DD') ?? null,
        spotState,
        stations:
          stations && stations[0]
            ? [
                {
                  type: 'nextopen',
                  isRange,
                  locations: stations.map((location: $TSFixMe) => ({
                    id: location.id,
                  })),
                },
              ]
            : [],
      };
    },
    fromApi: ({nextOpenFrom, nextOpenTo, stations, spotState}: $TSFixMe): InternalFormValues => ({
      nextOpenFrom: nextOpenFrom ? dayjs(nextOpenFrom) : null,
      nextOpenTo: nextOpenTo ? dayjs(nextOpenTo) : null,
      stations: (stations && stations[0] && stations[0]['locations']) || [],
      isRange: (stations && stations[0] && stations[0]['isRange']) || false,
      spotState,
    }),
  },
  true
)(WrappedDetailsSection);

const NextOpen = (props: $TSFixMe) => {
  const {onChange, form} = props;
  const status = form && form.general && form.general.status;
  const spotState = form && form.nextOpen && form.nextOpen.spotState;

  // When a vessel becomes spot, we want to clear the next open dates
  useEffect(() => {
    if (spotState === true) {
      onChange('nextOpen', {
        nextOpenFrom: null,
        nextOpenTo: null,
        spotState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotState]);

  useEffect(() => {
    if (status === 'employed' || status === 'scrapped') {
      onChange('nextOpen', {
        nextOpenFrom: null,
        nextOpenTo: null,
        spotState: false,
        spotDate: null,
        stations: [],
      });
    } else if (status === 'inlayup') {
      onChange('nextOpen', {
        nextOpenFrom: null,
        nextOpenTo: null,
      });
    }
  }, [status, onChange]);

  return (
    <Wrapper {...props}>
      {({onChange, values, validations, onChangeRangePicker}: $TSFixMe) => (
        <div className="container-fluid">
          <div className="row">
            <div className="scol-12">
              <LabelWrapper label={'Location open'} htmlFor={`form-nextopen-stations`}>
                <AsyncMultiSelect
                  id={`form-nextopen-stations`}
                  name={`form-nextopen-stations`}
                  value={values.stations}
                  onChange={(value: $TSFixMe) => onChange('stations', getLocationValues(value))}
                  getOptionLabel={(o: $TSFixMe) => getLocationSelectOption(o, true)}
                  getOptionValue={(option: $TSFixMe) => option.id}
                  loadOptions={(q: $TSFixMe) => (q.length > 1 ? props.searchAll(q) : Promise.resolve([]))}
                  cacheOptions
                  defaultOptions
                  placeholder="Enter port, country or area"
                  components={{GroupHeading, MultiValue: DropdownMultiValueLocation}}
                  isDisabled={status === 'employed' || status === 'scrapped'}
                />
              </LabelWrapper>
            </div>
          </div>
          <div className="row">
            <div className="scol-8">
              <LabelWrapper
                label="Next open from - Next open  to"
                htmlFor={`form-next-open`}
                hasError={validations.nextOpenFrom.invalid}>
                <StyledRangePicker
                  id={'form-next-open'}
                  format={DateTimeFormat.Date}
                  value={[values.nextOpenFrom, values.nextOpenTo]}
                  getPopupContainer={() => {
                    return document.getElementById('nextOpen')!;
                  }}
                  placeholder={['Next open from', 'Next open to']}
                  disabled={status === 'employed' || status === 'scrapped' || status === 'inlayup' || values.spotState}
                  onChange={value => {
                    const [from, to] = (value ?? [null, null]).values();
                    onChangeRangePicker({
                      nextOpenFrom: from,
                      nextOpenTo: to,
                    });
                  }}
                />
                <div id="nextOpen" />
              </LabelWrapper>
            </div>
          </div>
          <div className="row">
            <div className="scol-12 scol-sm-4">
              <LabelWrapper htmlFor={'toogle-spotmarket'} label={'Spot'}>
                <Toggle
                  disabled={status === 'employed' || status === 'scrapped' || status === 'inlayup'}
                  id={'toogle-spotmarket'}
                  checked={!!values.spotState}
                  onChange={() => {
                    const spotState = !values.spotState;
                    onChange('spotState', spotState);
                  }}
                  reverse
                />
              </LabelWrapper>
            </div>
            <div className="scol-12">
              {validations.nextOpenFrom.error && (
                <p className="text-danger text-danger--no-margin">{validations.nextOpenFrom.error}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  searchAll: (search: $TSFixMe) => dispatch(locationApi.searchAll(search)),
});

export default connect(null, mapDispatchToProps)(NextOpen);
