import {Col} from 'antd';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import lodashMap from 'lodash/map';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {assertUnreachable} from '../../../../utils/assert';
import {OfferType} from '../../utils/transformBucketsToTimeSeries';
import {VerticalPaddingCard} from '../VerticalPaddingCard';
import {UndefinedLabel} from '../UndefinedLabel';

export const TopTenChart = ({
  buckets,
  limit,
  groupedByTag,
  offerType,
  title,
}: {
  buckets: OfferBucket[];
  limit?: number;
  title?: string;
  offerType?: OfferType;
  groupedByTag?: 'sender' | 'segment' | 'tradingArea';
}) => {
  const groupedBUckets = groupBy(buckets, bucket => {
    return bucket.tags?.find(tag => tag.name === (groupedByTag ?? 'sender'))?.value ?? UndefinedLabel;
  });

  let dataSource: [string, number, number, number][] = lodashMap(groupedBUckets, (buckets, key) => {
    return [
      key,
      sumBy(buckets, bucket => bucket.numberOfOffers ?? 0),
      sumBy(buckets, bucket => bucket.numberOfCargoOffers ?? 0),
      sumBy(buckets, bucket => bucket.numberOfVesselOffers ?? 0),
    ];
  });

  let byKey: number;
  const switchOfferType = offerType ?? 'all';
  switch (switchOfferType) {
    case 'all':
      byKey = 1;
      break;
    case 'cargo':
      byKey = 2;
      break;
    case 'vessel':
      byKey = 3;
      break;
    default:
      assertUnreachable(switchOfferType);
  }
  dataSource = reverse(sortBy(dataSource, byKey)).slice(0, limit ?? 10);

  return (
    <Col span={12}>
      <VerticalPaddingCard>
        <ReactECharts
          style={{minHeight: 300}}
          option={{
            legend: {
              left: 8,
              top: 20,
            },
            title: {
              text: title,
              left: 'center',
              align: 'right',
            },
            tooltip: {},
            dataset: {
              source: [['sender', 'All Circulars', 'Cargo Circulars', 'Vessel Circulars'], ...dataSource],
            },
            xAxis: {type: 'category'},
            yAxis: {},
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [{type: 'bar'}, {type: 'bar'}, {type: 'bar'}],
            grid: {
              left: 48,
              right: 24,
              bottom: 20,
            },
          }}
        />
      </VerticalPaddingCard>
    </Col>
  );
};
