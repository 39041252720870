import {MailForwardingHelpLinkList} from '../../../CompanySubscriptionScreen/components/MailForwardingHelpLinkList';
import React, {FC} from 'react';
import styled from 'styled-components';
import {Typography} from 'antd';
import {InstructionsCollapse, InstructionsContainer, InstructionsStepList} from './shared';

const {Title} = Typography;

export const SmartInboxInstructions: FC<{open?: boolean; onSupportLinkClick: () => void}> = ({
  open,
  onSupportLinkClick,
}) => {
  return (
    <InstructionsContainer $color="blue-bg">
      <InstructionsCollapse ghost activeKey={open ? '1' : undefined}>
        <InstructionsCollapse.Panel header={<Title level={5}>Want to see your own circulars in market?</Title>} key="1">
          <FlexBox>
            <div>
              <p>Three easy steps to connect your mail inbox to our email parser:</p>
              <InstructionsStepList>
                <li>Choose the workspace that you want to connect your circular inbox to</li>
                <li>Copy the email address below the title and follow the instructions to the right</li>
                <li>seabo receives your circulars and automatically imports them into your Market</li>
              </InstructionsStepList>
            </div>
            <div>
              <Title level={5}>Setup instructions</Title>
              <MailForwardingHelpLinkList onSupportLinkClick={onSupportLinkClick} />
            </div>
          </FlexBox>
        </InstructionsCollapse.Panel>
      </InstructionsCollapse>
    </InstructionsContainer>
  );
};

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  & > div {
    min-width: 25%;
  }
`;
