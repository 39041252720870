import React, {useState} from 'react';
import numeral from 'numeral';
import {EditOutlined} from '@ant-design/icons/lib';
import styled from 'styled-components';
import {CellEditor} from './CellEditor';
import {roundNumberToDecimals} from '../../../../utils/roundNumber';

type Props = {
  freightIdea: number;
  onChange: (freightIdea: number) => void;
};

export const FreightIdeaCell = (props: Props) => {
  const [value, setValue] = useState(roundNumberToDecimals(props.freightIdea, 3));
  const [showEditInput, setShowEditInput] = useState(false);

  if (showEditInput) {
    return (
      <CellEditor
        min={0}
        value={roundNumberToDecimals(value, 3)}
        onChange={value => setValue(roundNumberToDecimals(value, 3))}
        onBlur={() => {
          props.onChange(value);
          setShowEditInput(false);
        }}
      />
    );
  }
  return (
    <FreightIdeaSpan
      data-cy="freight-idea-cell-span"
      data-testid="FreightIdeaCellSpan"
      onClick={() => {
        setShowEditInput(true);
      }}>
      {numeral(roundNumberToDecimals(props.freightIdea, 3)).format('$0,0[.]000')} <EditOutlined size={13} />
    </FreightIdeaSpan>
  );
};

const FreightIdeaSpan = styled.span`
  cursor: pointer;
  width: 100%;
`;
