/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {TagType, TagTypeFromJSON, TagTypeFromJSONTyped, TagTypeToJSON} from './';

/**
 *
 * @export
 * @interface TagWrite
 */
export interface TagWrite {
  /**
   *
   * @type {string}
   * @memberof TagWrite
   */
  name: string;
  /**
   *
   * @type {TagType}
   * @memberof TagWrite
   */
  type: TagType;
}

export function TagWriteFromJSON(json: any): TagWrite {
  return TagWriteFromJSONTyped(json, false);
}

export function TagWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    type: TagTypeFromJSON(json['type']),
  };
}

export function TagWriteToJSON(value?: TagWrite | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: TagTypeToJSON(value.type),
  };
}
