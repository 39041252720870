/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  TradeFlowPortActivity,
  TradeFlowPortActivityFromJSON,
  TradeFlowPortActivityFromJSONTyped,
  TradeFlowPortActivityToJSON,
  VesselVoyageTimelineEventType,
  VesselVoyageTimelineEventTypeFromJSON,
  VesselVoyageTimelineEventTypeFromJSONTyped,
  VesselVoyageTimelineEventTypeToJSON,
  VoyagePort,
  VoyagePortFromJSON,
  VoyagePortFromJSONTyped,
  VoyagePortToJSON,
} from './';

/**
 *
 * @export
 * @interface PortVisit
 */
export interface PortVisit {
  /**
   *
   * @type {number}
   * @memberof PortVisit
   */
  id: number;
  /**
   *
   * @type {VesselVoyageTimelineEventType}
   * @memberof PortVisit
   */
  eventType: VesselVoyageTimelineEventType;
  /**
   *
   * @type {number}
   * @memberof PortVisit
   */
  imo: number;
  /**
   *
   * @type {VoyagePort}
   * @memberof PortVisit
   */
  port: VoyagePort;
  /**
   *
   * @type {TradeFlowPortActivity}
   * @memberof PortVisit
   */
  tradeFlow: TradeFlowPortActivity | null;
  /**
   *
   * @type {Date}
   * @memberof PortVisit
   */
  enterTimestamp: Date;
  /**
   *
   * @type {Date}
   * @memberof PortVisit
   */
  exitTimestamp: Date | null;
  /**
   * The draft of the vessel when it entered the port
   * @type {number}
   * @memberof PortVisit
   */
  enterDraft: number;
  /**
   * The draft of the vessel when it exited the port
   * @type {number}
   * @memberof PortVisit
   */
  exitDraft: number | null;
}

export function PortVisitFromJSON(json: any): PortVisit {
  return PortVisitFromJSONTyped(json, false);
}

export function PortVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortVisit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    eventType: VesselVoyageTimelineEventTypeFromJSON(json['eventType']),
    imo: json['imo'],
    port: VoyagePortFromJSON(json['port']),
    tradeFlow: TradeFlowPortActivityFromJSON(json['tradeFlow']),
    enterTimestamp: new Date(json['enterTimestamp']),
    exitTimestamp: json['exitTimestamp'] === null ? null : new Date(json['exitTimestamp']),
    enterDraft: json['enterDraft'],
    exitDraft: json['exitDraft'],
  };
}

export function PortVisitToJSON(value?: PortVisit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    eventType: VesselVoyageTimelineEventTypeToJSON(value.eventType),
    imo: value.imo,
    port: VoyagePortToJSON(value.port),
    tradeFlow: TradeFlowPortActivityToJSON(value.tradeFlow),
    enterTimestamp: value.enterTimestamp.toISOString(),
    exitTimestamp: value.exitTimestamp === null ? null : value.exitTimestamp.toISOString(),
    enterDraft: value.enterDraft,
    exitDraft: value.exitDraft,
  };
}
