import {Select} from 'antd';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {CalcTimeLinePoint, voyagePointTypeOptions} from '../../../VoyageTypes';

import {StyledFormItem} from '../../StyledFormItem';

type Props = {
  item: CalcTimeLinePoint;
};

export const Types = (props: Props) => {
  const options = props.item.indexInOldArray === 0 ? voyagePointTypeOptions : omit(voyagePointTypeOptions, 'nextOpen');
  return (
    <StyledFormItem
      noStyle
      rules={[
        {required: true, message: 'Select at least 1 type.'},
        {
          validator: (_, value) => {
            if (value.includes('loading') && value.includes('discharge')) {
              return Promise.reject();
            }
            return Promise.resolve();
          },
          message: 'Choose either Loading or Discharge.',
        },
      ]}
      validateFirst={true}
      name={'types'}>
      <Select
        size="small"
        notFoundContent={'Mode not found'}
        style={{minWidth: 150}}
        mode={'multiple'}
        allowClear={true}
        options={map(options, (item, key) => {
          return {value: key, key: key, label: item.label};
        })}
      />
    </StyledFormItem>
  );
};
