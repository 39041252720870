/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface NewsImage
 */
export interface NewsImage {
  /**
   *
   * @type {string}
   * @memberof NewsImage
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NewsImage
   */
  url: string;
}

export function NewsImageFromJSON(json: any): NewsImage {
  return NewsImageFromJSONTyped(json, false);
}

export function NewsImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    url: json['url'],
  };
}

export function NewsImageToJSON(value?: NewsImage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    url: value.url,
  };
}
