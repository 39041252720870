import {FC} from 'react';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import {ModalActions} from '../../redux/Modal';
import {useDispatch} from '../../redux/react-redux';
import styled, {css} from 'styled-components';
import {TODO} from '../../utils/TODO';
import ShowRawMessageModal from '../../components/Modal/ShowRawMessage';

const getConfidenceScoreLevel = (score: number) => {
  if (isNaN(score)) {
    return [];
  }
  switch (true) {
    case score >= 85:
      return ['green', 'Good'];
    case score >= 50:
      return ['yellow', 'Medium'];
    case score >= 10:
      return ['red', 'Low'];
    case score < 10:
      return ['brown', 'Poor'];
    default:
      return [];
  }
};

interface Props {
  score: number;
  isEmail: boolean | undefined;
  normalize: boolean;
  type: 'cargo' | 'vessel' | 'MarketCargo';
  offerId: number;
  tooltipDisabled?: boolean;
}

export const Source: FC<Props> = ({isEmail, score, normalize, type, offerId}) => {
  const dispatch = useDispatch();
  const onClick = (ownProps: TODO) =>
    dispatch(ModalActions.show(<ShowRawMessageModal type={ownProps.type} offerId={ownProps.offerId} />));

  const [level, name] = getConfidenceScoreLevel(score);

  return (
    <StyledSource theme={{level, isEmail, normalize}}>
      <Tooltip
        title={isEmail ? `${name} <br/> Shared by E-mail` : 'Shared on seabo'}
        position="top"
        size={'small'}
        arrow={false}
        animateFill={false}
        trigger={'mouseenter'}
        theme={'dark'}>
        <Icon type={isEmail ? 'sent' : 'logo-wave'} onClick={() => (isEmail ? onClick({type, offerId}) : () => {})} />
      </Tooltip>
    </StyledSource>
  );
};

const StyledSource = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 3px;
  margin-left: 6px;
  background: transparent;
  cursor: pointer;
  ${props =>
    props.theme.normalize &&
    css`
      margin-top: 0;
      margin-left: 0;
      width: auto;
      height: auto;
      padding-top: 2px;
    `}
  .icon {
    font-size: var(--font-size-xl);
    color: var(--color-azure) !important;
    color: ${props => props.theme.level === 'green' && props.theme.isEmail && '#31a72c !important'};
    color: ${props => props.theme.level === 'yellow' && props.theme.isEmail && '#f7b16f !important'};
    color: ${props => props.theme.level === 'red' && props.theme.isEmail && '#e68585 !important;'};
    color: ${props => props.theme.level === 'brown' && props.theme.isEmail && '#d1af99 !important;'};
  }
`;
