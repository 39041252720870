import {FC} from 'react';
import {
  NegotiationAttachment,
  NegotiationRoundResult,
  NegotiationRoundResultModification,
  NegotiationRoundResultSummary,
  NegotiationStatus,
  NegotiationSummary as NegotiationSummaryType,
  NegotiationTemplate,
  NegotiationTemplateSummary,
} from '../../../api/node-backend/generated';
import {NegotiationHistory} from './Components/History/NegotiationHistory';
import {NegotiationTermArea} from './Components/TermArea/NegotiationTermArea';
import {NegotiationHeader} from './Components/NegotiationHeader';
import {NegotiationCommitArea} from './Components/CommitArea/NegotiationCommitArea';
import {NegotiationReplyTimeArea} from './Components/ReplyTimeArea/NegotiationReplyTimeArea';
import {NegotiationRoundDiff} from './Components/RoundDiff/NegotiationRoundDiff';
import {NegotiationPrintExport} from './Components/NegotiationPrintExport';
import {TermActionsHook} from './Components/TermItem/utils/useGetTermActionsWithMutations';
import {SubjectActionsHook} from './Components/Subjects/utils/useGetSubjectActionsWithMutations';
import {NegotiationActionsHook} from './Components/CommitArea/utils/useGetNegotiationActionsWithMutations';
import {SidebarActionsHook} from './Components/TermArea/Sidebar/useGetSidebarActionsWithMutations';
import {NegotiationAttachments} from './Components/Attachments/NegotiationAttachments';
import {AttachmentActionsHook} from './Components/Attachments/useAttachmentActions';
import {TemplateActionsHook} from '../NegotiationTemplates/utils/useTemplateActions';

type NegotiationDetailBodyProps = {
  negotiation: NegotiationSummaryType;
  roundResultSummaries?: NegotiationRoundResultSummary[];
  currentRoundResult?: NegotiationRoundResult;
  currentRoundResultModifications?: NegotiationRoundResultModification;
  previousRoundResult?: NegotiationRoundResult;
  previousRoundResultModifications?: NegotiationRoundResultModification;
  templateSummaries?: NegotiationTemplateSummary[];
  attachments?: NegotiationAttachment[];
  printContainerRef?: React.RefObject<HTMLDivElement>;
  isGuest?: boolean;
  actionsBusy?: boolean;
  useGetTemplateDetail: (id?: number) => {data?: NegotiationTemplate};
  getTermActions: TermActionsHook;
  getSubjectActions: SubjectActionsHook;
  getNegotiationActions: NegotiationActionsHook;
  getSidebarActions: SidebarActionsHook;
  getTemplateActions: TemplateActionsHook;
  getAttachmentActions: AttachmentActionsHook;
};

export const NegotiationDetailBody: FC<NegotiationDetailBodyProps> = ({
  negotiation,
  roundResultSummaries,
  currentRoundResult,
  currentRoundResultModifications,
  previousRoundResult,
  previousRoundResultModifications,
  templateSummaries = [],
  attachments = [],
  printContainerRef,
  isGuest,
  actionsBusy,
  useGetTemplateDetail,
  getTermActions,
  getSubjectActions,
  getNegotiationActions,
  getSidebarActions,
  getTemplateActions,
  getAttachmentActions,
}) => {
  const negotiationActions = getNegotiationActions();
  const attachmentActions = getAttachmentActions();

  // This is a special case where we need to check if the current round is the first recap round
  const isFirstRecapRound =
    // This checks the case for the user entering the recap
    (currentRoundResultModifications?.oldNegotiationStatus !== NegotiationStatus.Recap &&
      currentRoundResultModifications?.newNegotiationStatus === NegotiationStatus.Recap) ||
    // This checks the case for the party waiting to receive the recap
    (currentRoundResultModifications?.newNegotiationStatus !== NegotiationStatus.Recap &&
      negotiation.status === NegotiationStatus.Recap);

  return (
    <>
      {printContainerRef && (
        <NegotiationPrintExport
          printContainerRef={printContainerRef}
          negotiation={negotiation}
          roundResultSummaries={roundResultSummaries}
          currentRoundResult={currentRoundResult}
          attachments={attachments}
        />
      )}
      <div data-testid="NegotiationDetailBody">
        <NegotiationHeader
          negotiation={negotiation}
          roundResultSummaries={roundResultSummaries}
          currentRoundResult={currentRoundResult}
          isFirstRecapRound={isFirstRecapRound}
        />
        <NegotiationRoundDiff
          negotiation={negotiation}
          previousRoundResult={previousRoundResult}
          previousRoundResultModifications={previousRoundResultModifications}
        />
        <NegotiationTermArea
          negotiation={negotiation}
          currentRoundResult={currentRoundResult}
          currentRoundResultModifications={currentRoundResultModifications}
          templateSummaries={templateSummaries}
          isGuest={isGuest}
          negotiationActions={negotiationActions}
          actionsBusy={actionsBusy}
          isFirstRecapRound={isFirstRecapRound}
          useGetTemplateDetail={useGetTemplateDetail}
          getTermActions={getTermActions}
          getSubjectActions={getSubjectActions}
          getSidebarActions={getSidebarActions}
          getTemplateActions={getTemplateActions}
        />
        {[NegotiationStatus.Offer, NegotiationStatus.Ongoing].includes(negotiation.status) && (
          <NegotiationReplyTimeArea
            negotiation={negotiation}
            roundResultSummaries={roundResultSummaries}
            currentRoundResult={currentRoundResult}
            negotiationActions={negotiationActions}
            actionsBusy={actionsBusy}
          />
        )}
        <NegotiationCommitArea
          negotiation={negotiation}
          currentRoundResult={currentRoundResult}
          actionsBusy={actionsBusy}
          negotiationActions={negotiationActions}
          isFirstRecapRound={isFirstRecapRound}
        />
        <NegotiationAttachments
          negotiation={negotiation}
          attachments={attachments}
          attachmentActions={attachmentActions}
        />
        <NegotiationHistory negotiation={negotiation} roundResultSummaries={roundResultSummaries} />
      </div>
    </>
  );
};
