import {FC} from 'react';
import {CompanyWorkspace} from './CompanyWorkspace/CompanyWorkspace';
import {CompanyWorkspace as CompanyWorkspaceType} from '../../../../api/symfony/generated/models/CompanyWorkspace';
import {Loading, StyledSettingsSection, StyledSettingsSectionWrapper} from './shared';

interface InactiveWorkspacesProps {
  loading: boolean;
  inactiveWorkspaces: CompanyWorkspaceType[];
  handleWorkspaceEdit: (id: number) => void;
  addMembers: (id: number) => void;
  handleWorkspaceInactive: (id: number) => void;
}

export const WorkspaceSettingsListDisabled: FC<InactiveWorkspacesProps> = ({
  loading,
  inactiveWorkspaces,
  handleWorkspaceEdit,
  addMembers,
  handleWorkspaceInactive,
}) => {
  if (inactiveWorkspaces.length === 0) {
    return null;
  }

  return (
    <div>
      <Loading isLoading={loading}>
        {inactiveWorkspaces.map((workspace: CompanyWorkspaceType) => (
          <StyledSettingsSection key={workspace.name}>
            <StyledSettingsSectionWrapper>
              <CompanyWorkspace
                onDefaultWorkspaceSelected={() => {}}
                onEdit={handleWorkspaceEdit}
                addMembers={addMembers}
                onWorkspaceInactive={handleWorkspaceInactive}
                workspace={workspace}
              />
            </StyledSettingsSectionWrapper>
          </StyledSettingsSection>
        ))}
      </Loading>
    </div>
  );
};
