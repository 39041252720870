import React, {CSSProperties, FC, useMemo, useRef} from 'react';
import {VoyageTimeSeriesEntry} from '../../../utils/calculateVoyageTimeSeries';
import styled, {keyframes} from 'styled-components';
import {Dayjs} from 'dayjs';
import {Tooltip} from 'antd';
import {VesselVoyageTimelineEventType} from '../../../../../../api/node-backend/generated';
import {DateTimeFormat} from '../../../../../../utils/DateTimeFormat';
import maxBy from 'lodash/maxBy';

type VoyageTimelineDraftScrollbarProps = {
  voyageTimeSeries: VoyageTimeSeriesEntry[];
  selectedDate?: Dayjs;
  onChangeSelectedDate?: (selectedDate: Dayjs) => void;
};

export const VoyageTimelineDraftScrollbar: FC<VoyageTimelineDraftScrollbarProps> = ({
  voyageTimeSeries,
  selectedDate,
  onChangeSelectedDate,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const dateBars = useMemo(() => {
    const daysInVoyage = voyageTimeSeries.length;
    const maxDraft = maxBy(voyageTimeSeries, 'draft')?.draft ?? Infinity;

    const dateBars = voyageTimeSeries.map((entry, idx) => {
      const {date, draft} = entry;
      const height = Math.min(draft / maxDraft, 1) * 100 + '%';
      const width = 100 / daysInVoyage + '%';
      const left = (100 / daysInVoyage) * idx + '%';
      const background = entry.color;
      const label =
        entry.type === VesselVoyageTimelineEventType.PortVisit
          ? `${entry.date.format(DateTimeFormat.Date)}\n ${entry.portVisit.portName}`
          : `${entry.date.format(DateTimeFormat.Date)}\n ${entry.route[0]?.portName} - ${
              entry.route[1]?.portName ?? 'TBA'
            }`;

      return {
        ...entry,
        label,
        valueStyle: {
          background,
          height,
        },
        barStyle: {
          width,
          left,
        },
        onClick: () => {
          onChangeSelectedDate?.(date);
        },
      };
    });

    return dateBars;
  }, [voyageTimeSeries, onChangeSelectedDate]);

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const {clientX} = event;
    const {current} = ref;
    if (!current) {
      return;
    }
    const {left, width} = current.getBoundingClientRect();
    const progress = (clientX - left) / width;
    const index = Math.floor(progress * dateBars.length);
    const date = dateBars[index]?.date;
    if (date) {
      onChangeSelectedDate?.(date);
    }
  };

  const scrollBarLeftOffset = selectedDate
    ? (dateBars.findIndex(dateBar => dateBar.date.isSame(selectedDate, 'day')) / dateBars.length) * 100 + '%'
    : '100%';

  return (
    <DraftScrollBarContainer ref={ref}>
      <ScrollBarInner>
        {dateBars.map(({onClick, barStyle, valueStyle, ...entry}, idx) => (
          <Tooltip mouseEnterDelay={0} mouseLeaveDelay={0} key={idx + 'tooltip'} title={entry.label}>
            <DateBar key={idx + 'bar'} onClick={onClick} barStyle={barStyle} valueStyle={valueStyle} />
          </Tooltip>
        ))}
        <ElapsedTimeOverlay style={{left: scrollBarLeftOffset}} />
      </ScrollBarInner>
      <ScrollHandle onDrag={event => handleDrag(event)} draggable style={{left: scrollBarLeftOffset}} />
    </DraftScrollBarContainer>
  );
};

const DateBar: FC<{onClick: () => void; barStyle: CSSProperties; valueStyle: CSSProperties}> = ({
  onClick,
  barStyle,
  valueStyle,
}) => {
  return (
    <DateBarContainer onClick={onClick} style={barStyle}>
      <span style={valueStyle} />
    </DateBarContainer>
  );
};

const slideDown = keyframes`
  from {
    height: 0;
  }
`;
const DateBarContainer = styled.div`
  position: absolute;
  height: 100%;
  cursor: pointer;
  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation: ${slideDown} 0.3s ease-in-out forwards;
  }
`;

const DraftScrollBarContainer = styled.div`
  position: relative;
  height: 36px;
  width: 100%;
`;

const ScrollBarInner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--color-gray-5);
  border: var(--border-base);
  border-radius: var(--border-radius-button);
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
`;

const ScrollHandle = styled.div`
  position: absolute;
  top: -5px;
  height: calc(100% + 10px);
  width: 10px;
  transform: translateX(-50%);
  background: var(--color-white);
  border: var(--border-base);
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-card);
  cursor: grab;
  z-index: 1;
`;

const ElapsedTimeOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  pointer-events: none;
`;
