import {Gear} from '../../useMyFleetQuery/useMyFleetQuery';
import maxBy from 'lodash/maxBy';

const getMaxGearString = (subGears: Gear[]) => {
  const maxItem = maxBy(
    subGears.filter(gear => !!gear.capacity),
    'capacity'
  );
  if (!maxItem) {
    return '';
  }
  const unit = maxItem.type === 'grab' ? 'cbm' : 'mts';

  return `, ${maxItem.quantity}x${maxItem.capacity} ${unit} cranes`;
};

export const getGears = (vessel: {gears: Gear[]}): string => {
  const grabGears = vessel.gears.filter(gear => gear.type === 'grab');
  const gearGears = vessel.gears.filter(gear => gear.type === 'gear');

  const grabString = getMaxGearString(grabGears);
  const gearString = getMaxGearString(gearGears);

  return `${grabString}${gearString}`;
};
