import MapBoxStaticLayer from 'react-map-gl';
import {MAPBOX_ACCESS_TOKEN, MAPBOX_STYLES} from '../const';
import {OpenWeatherMapLayer, OpenWeatherMapSource} from './mapbox/OpenWeatherMapLayer';
import {PulsatingSelectElement} from './mapbox/PulsatingSelectElement';
import {TODO} from '../../../utils/TODO';
import {FC} from 'react';
import {useMapContext} from '../MapContext/MapContext';
import {MapElement} from '../MapDetails/utils/types';
import {MapSettings, MapSize} from '../MapContext/Types';
import {ViewState} from '../ViewState';
import {HighRiskArea} from '../HighRiskArea';

/**
 * The Mapbox layer renders the base layer (with land and seas).
 * For Example in Overview pages we could have multiple maps, but to we can't have so many webGL-contexts.
 * But among other layers the MapBox Layers need them, so we have to work around them.
 * This option reduces the number of webGL-contexts per map by 1.
 * By using this option, some sub layers of the mapbox layer will not be rendered.
 * We can provide that by using a image instead of the mapbox-webgl-layer.
 */
export const MapBoxLayer: FC<{selectedElement: MapElement | null; staticMapBoxLayer?: boolean}> = ({
  selectedElement,
  staticMapBoxLayer,
}) => {
  const context = useMapContext();

  if (staticMapBoxLayer) {
    return <StaticMap />;
  }
  const {
    state: {settings},
  } = context;

  return (
    <MapBoxStaticLayer
      viewState={{
        width: 100,
        height: 100,
        ...context.state.viewState,
        ...context.state.mapSize,
      }}
      reuseMaps={true}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      mapStyle={MAPBOX_STYLES[settings.mapStyle].styleURL}>
      <OpenWeatherMapSource />
      <HighRiskArea />
      <OpenWeatherMapLayer openweathermapLayer={settings.openWeatherMapLayer} />
      <PulsatingSelectElement selectedElement={selectedElement as TODO} />
      {context.props.layersOnTopOfMapBox ?? []}
    </MapBoxStaticLayer>
  );
};

const StaticMap = () => {
  const context = useMapContext();

  const {
    state: {viewState, settings, mapSize},
  } = context;

  return <img src={getStaticImage(viewState, mapSize, settings)} />;
};

const getStaticImage = (viewState: ViewState, mapSize: MapSize, settings: MapSettings) => {
  const style = MAPBOX_STYLES[settings.mapStyle];
  return `https://api.mapbox.com/styles/v1/${style.styleAccount}/${style.styleId}/static/${viewState.longitude},${viewState.latitude},${viewState.zoom},${viewState.bearing},${viewState.pitch}/${mapSize?.width}x${mapSize?.height}?access_token=${MAPBOX_ACCESS_TOKEN}`;
};
