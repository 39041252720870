import React, {ReactNode} from 'react';

/**
 * Header for a datagrid column.
 *
 * @param children the label
 */
export const ColumnHeader: React.FC<{children: ReactNode; sortable?: boolean; ['data-cy']?: string}> = ({
  children,
  'data-cy': dataCy,
  sortable,
}) => (
  <div className={sortable ? 'datagrid-sortable-header' : undefined} data-cy={dataCy}>
    {children}
  </div>
);
