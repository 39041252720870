import {Button, Tooltip} from 'antd';
import styled from 'styled-components';
import {WaitingTimes} from './useGetSuggestedWaitingTimes';

export const SuggestedWaitingTime = ({
  waitingTimes,
  onChangeWaitingTime,
}: {
  waitingTimes: WaitingTimes;
  onChangeWaitingTime: (value: number) => void;
}) => {
  return (
    <Tooltip
      overlayInnerStyle={{
        width: '350px',
        whiteSpace: 'pre-wrap',
      }}
      placement={'bottom'}
      title={
        <div>
          <table
            style={{
              width: '100%',
              textAlign: 'left',
            }}>
            <tr>
              <td>Initial waiting time based on laycan</td>
              <td style={{textAlign: 'right'}}>{waitingTimes.initalWaitingTime} hours</td>
            </tr>
            <tr>
              <td>Waiting time based on terms</td>
              <td style={{textAlign: 'right'}}>{waitingTimes.interWaitingTimeBasedOnTerm} hours</td>
            </tr>
            <tr>
              <td>Due to current congestion</td>
              <td style={{textAlign: 'right'}}>{waitingTimes.congestionBasedWaitingTime} hours</td>
            </tr>
            <tr>
              <td>Total estimated waiting time</td>
              <td style={{textAlign: 'right'}}>{waitingTimes.totalWaitingTime} hours</td>
            </tr>
          </table>
        </div>
      }>
      <StyledButton
        onClick={() => {
          onChangeWaitingTime(waitingTimes.totalWaitingTime);
        }}
        size={'small'}
        type={'link'}>
        Apply ({waitingTimes.totalWaitingTime} h)
      </StyledButton>
    </Tooltip>
  );
};

const StyledButton = styled(Button)`
  padding: 0px;
`;
