import {TourDefinition, TourId, getFinalStep} from '../OnboardingTour/TourDefinition';

export const ProjectsTourDefinition: TourDefinition = {
  id: TourId.Projects,
  steps: [
    {
      title: 'Projects',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/Onboarding%20Videos/7.%20Onboarding%20Projects.mp4',
      },
      pdfDownloadUrl: "https://seabo-help.fra1.digitaloceanspaces.com/pdfs/08%20Projects/Let's%20meet%20Projects.pdf",
      placement: 'center',
    },
    {
      title: 'How to create a project',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/7%20How%20to%20Projects/7.1%20How%20to%20create%20a%20Project.mp4',
      },
      pdfDownloadUrl: 'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/08%20Projects/Create%20a%20project.pdf',
      placement: 'center',
    },
    {
      title: 'How to do a calculation',
      target: null,
      media: {
        type: 'video',
        url: 'https://seabo-help.fra1.digitaloceanspaces.com/How%20To%20Pop%20Up%20Videos/7%20How%20to%20Projects/7.2%20How%20to%20do%20a%20Calculation.mp4',
      },
      pdfDownloadUrl:
        'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/08%20Projects/Do%20a%20voyage%20calculation.pdf',
      placement: 'center',
    },
    getFinalStep('Projects'),
  ],
};
