import React, {FC, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {NegotiationRoundResultSummary, NegotiationSummary} from '../../../../../../api/node-backend/generated';
import {TimelineEventBubble} from './TimelineEventBubble';
import {getNegotiationHistoryEvents} from '../getNegotiationHistoryEvents';

type HistoryTimelineProps = {
  negotiation: NegotiationSummary;
  roundResultSummaries: NegotiationRoundResultSummary[];
  selectedRound?: NegotiationRoundResultSummary | null;
  onSelectRound?: (index: string | null) => void;
};

export const HistoryTimeline: FC<HistoryTimelineProps> = ({
  negotiation,
  roundResultSummaries,
  selectedRound,
  onSelectRound,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    containerRef.current.scrollTo?.({left: containerRef.current.scrollWidth, behavior: 'smooth'});
  }, []);

  const negotiationHistoryEvents = useMemo(() => {
    return getNegotiationHistoryEvents(roundResultSummaries);
  }, [roundResultSummaries]);

  return (
    <HistoryTimelineContainer data-testid="HistoryTimeline" ref={containerRef}>
      <Timeline $hasSelectedRound={!!selectedRound}>
        {negotiationHistoryEvents.map((event, idx) => (
          <TimelineEventBubble
            key={idx}
            negotiation={negotiation}
            roundResult={event.roundResult}
            type={event.type}
            date={event.date}
            roundIndex={event.roundIndex}
            isSelected={!!selectedRound && selectedRound.id === event.roundResult?.id}
            onSelectEvent={onSelectRound}
          />
        ))}
      </Timeline>
    </HistoryTimelineContainer>
  );
};

const HistoryTimelineContainer = styled.div`
  --timeline-height: 150px;
  --timeline-gap: 10px;
  position: relative;
  width: calc(100% + 40px);
  height: var(--timeline-height);
  margin: 0 -20px;
  padding: 0 20px;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Timeline = styled.ul<{$hasSelectedRound?: boolean}>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: var(--timeline-gap);
  height: 100%;
  margin: 0;
  padding: 0 20px 0 0;
  ${({$hasSelectedRound}) => $hasSelectedRound && 'border-bottom: var(--border-base);'}
`;
