import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {
  numberBetween,
  numberBetweenAndGreaterOrEqual,
  stringLengthLessThanOrEqualTo,
} from '../../../../../utils/validators';
import {convertValuesToNamedValues, NamedValue} from '../../FilterConversion/utils/NamedValue';
import {convertLabelToSlug} from '../../FilterConversion/utils/FilterConversionUtils';
import {BulkerDesigns} from '../../VesselSize/VesselSizeDefinition';
import {parseIntTS} from '../../../../../utils/parseNumberTS';

export interface DatabaseType {
  name?: string;
  imo?: number;
  minimumAge?: number;
  maximumAge?: number;
  vesselTypes: string[];
  vesselDesigns: string[];
  vesselDesignTypes: string[];
}

interface IMO {
  imo: number;
}

interface DesignType {
  label: string;
  value: string;
}

export interface InternalType {
  name: string;
  imo?: IMO;
  minimumAge: string | number;
  maximumAge: string | number;
  isBulker: boolean;
  isMPP: boolean;
  isContainer: boolean;
  vesselDesigns: NamedValue<string>[];
  vesselDesignTypes: DesignType[];
}

export const Defaults: InternalType = {
  name: '',
  imo: undefined,
  minimumAge: '',
  maximumAge: '',
  isBulker: false,
  isMPP: false,
  isContainer: false,
  vesselDesigns: [],
  vesselDesignTypes: [],
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const result: InternalType = {
    maximumAge: database.maximumAge ?? '',
    minimumAge: database.minimumAge ?? '',
    isBulker: database.vesselTypes.includes('bulker'),
    isMPP: database.vesselTypes.includes('mpp'),
    isContainer: database.vesselTypes.includes('container'),
    imo: database.imo ? {imo: database.imo} : undefined,
    name: database.name ?? '',
    vesselDesigns: database.vesselDesigns ? convertValuesToNamedValues(database.vesselDesigns, BulkerDesigns) : [],
    vesselDesignTypes:
      database.vesselDesignTypes?.map(vesselDesignType => ({
        value: convertLabelToSlug(vesselDesignType),
        label: vesselDesignType,
      })) ?? [],
  };

  return result;
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const vesselTypes: string[] = [];
  if (internal.isBulker) {
    vesselTypes.push('bulker');
  }
  if (internal.isMPP) {
    vesselTypes.push('mpp');
  }
  if (internal.isContainer) {
    vesselTypes.push('container');
  }

  return {
    name: internal.name ? internal.name : undefined,
    imo: internal.imo?.imo ? internal.imo.imo : undefined,
    minimumAge: internal.minimumAge ? parseIntTS(internal.minimumAge) : undefined,
    maximumAge: internal.maximumAge ? parseIntTS(internal.maximumAge) : undefined,
    vesselTypes,
    vesselDesigns: internal.vesselDesignTypes ? internal.vesselDesigns.map(design => design.value) : [],
    vesselDesignTypes: internal.vesselDesignTypes ? internal.vesselDesignTypes.map(designType => designType.label) : [],
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel',
  branch: 'fixtureVessel',
  defaults: Defaults,
  validators: {
    name: stringLengthLessThanOrEqualTo(191),
    minimumAge: numberBetween(1950, 2050),
    maximumAge: numberBetweenAndGreaterOrEqual(1950, 2050, 'minimumAge'),
  },
  fromDatabase,
  toDatabase,
});
