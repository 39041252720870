import {ReactElement, useState} from 'react';
import styled from 'styled-components';
import {InputEditor} from './InputEditor';
import Icon from '../Icon';
import {InputProps} from 'antd';
import {Property} from 'csstype';

type Props = {
  value: number;
  min?: number;
  size?: InputProps['size'];
  block?: boolean;
  closeOnEnter?: boolean;
  closeOnBlur?: boolean;
  formattedValue: ReactElement;
  editModeAvailable?: boolean;
  editButtonAlignment?: Property.VerticalAlign;
  onChange: (freightIdea: number) => void;
  editing?: boolean;
  onEditingChanged?: (editing: boolean) => void;
};

export const InlineNumberEditor = (props: Props) => {
  const [editingState, setEditingState] = useState(false);

  const closeOnBlur = props.closeOnBlur ?? true;
  const closeOnEnter = props.closeOnEnter ?? true;

  if (props.editing || editingState) {
    return (
      <InputEditor
        closeOnEnter={closeOnEnter}
        closeOnBlur={closeOnBlur}
        min={props.min}
        size={props.size}
        style={{width: '100%'}}
        value={props.value}
        onClose={() => {
          if (props.onEditingChanged) {
            props.onEditingChanged(false);
          } else {
            setEditingState(false);
          }
        }}
        onChange={value => {
          props.onChange(value);
        }}
      />
    );
  }

  if (!props.editModeAvailable) {
    return <span>{props.formattedValue}</span>;
  }

  return (
    <IdleValueContainer
      onClick={() => {
        if (props.editing !== undefined && props.onEditingChanged) {
          props.onEditingChanged(true);
        } else {
          setEditingState(true);
        }
      }}>
      <IdleValue block={props.block}>{props.formattedValue} </IdleValue>
      <Icon
        className={'editMarker'}
        size={'unset'}
        style={{verticalAlign: props.editButtonAlignment || 'bottom'}}
        type={'edit'}
      />
    </IdleValueContainer>
  );
};

const IdleValueContainer = styled.span`
  cursor: pointer;

  .editMarker {
    opacity: 0;
  }
  :hover {
    .editMarker {
      opacity: 1;
    }
  }
`;

const IdleValue = styled.span<{block?: boolean}>`
  ${({block}) =>
    block &&
    `
    display: inline-block;
    width: calc(100% - 30px);
  `}
`;
