import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalNode,
  DecoratorNode,
  EditorConfig,
  NodeKey,
} from 'lexical';
import {
  NegotiationPlaceholderKey,
  isNegotiationPlaceholderLabel,
  negotiationPlaceholderKeys,
} from '../../../utils/placeholderKeys';
import {ReactNode} from 'react';
import styled from 'styled-components';

export type SerializedPlaceholderNode = {
  placeholderKey: NegotiationPlaceholderKey;
  type: 'placeholder';
  version: 1;
};

/**
 * A Lexical node that renders a placeholder with styling in the text
 */
export class PlaceholderNode extends DecoratorNode<ReactNode> {
  __placeholderKey: NegotiationPlaceholderKey;

  static getType(): string {
    return 'placeholder';
  }

  static clone(node: PlaceholderNode): PlaceholderNode {
    return new PlaceholderNode(node.__placeholderKey);
  }

  static importJSON(serializedNode: SerializedPlaceholderNode): PlaceholderNode {
    const node = $createPlaceholderNode(serializedNode.placeholderKey);
    return node;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-placeholder')) {
          return null;
        }
        return {
          conversion: convertPlaceholderElement,
          priority: 1,
        };
      },
    };
  }

  constructor(placeholderKey: NegotiationPlaceholderKey, key?: NodeKey) {
    super(key);
    this.__placeholderKey = placeholderKey;
  }

  decorate(): ReactNode {
    return <Placeholder>{negotiationPlaceholderKeys[this.__placeholderKey]}</Placeholder>;
  }

  exportJSON(): SerializedPlaceholderNode {
    return {
      placeholderKey: this.__placeholderKey,
      type: 'placeholder',
      version: 1,
    };
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-placeholder', this.__placeholderKey);
    return element;
  }

  updateDOM(_prevNode: unknown, _dom: HTMLElement, _config: EditorConfig): boolean {
    return true;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-placeholder', this.__placeholderKey);
    return {element};
  }

  // prevent replacement
  replace<N extends LexicalNode>(replaceWith: N): N {
    if ($isPlaceholderNode(replaceWith)) {
      return replaceWith;
    }
    return this as unknown as N;
  }

  isInline(): boolean {
    return true;
  }

  isIsolated(): boolean {
    return true;
  }

  isKeyboardSelectable(): boolean {
    return false;
  }
}

function convertPlaceholderElement(domNode: HTMLElement): DOMConversionOutput | null {
  const placeholderKey = domNode.getAttribute('data-lexical-placeholder');

  if (placeholderKey !== null) {
    if (isNegotiationPlaceholderLabel(placeholderKey)) {
      const node = $createPlaceholderNode(placeholderKey as NegotiationPlaceholderKey);
      return {
        node,
      };
    }
    const labelMatch = Object.entries(negotiationPlaceholderKeys).find(([_, label]) => label === placeholderKey);
    if (labelMatch) {
      const node = $createPlaceholderNode(labelMatch[0] as NegotiationPlaceholderKey);
      return {
        node,
      };
    }
  }

  return null;
}

export function $createPlaceholderNode(placeholderKey: NegotiationPlaceholderKey): PlaceholderNode {
  const placeholderNode = new PlaceholderNode(placeholderKey);
  return placeholderNode;
}

export function $isPlaceholderNode(node: LexicalNode | null | undefined): node is PlaceholderNode {
  return node instanceof PlaceholderNode;
}

export const Placeholder = styled.span<{$isSelected?: boolean; $isWide?: boolean}>`
  padding: 0 2px;
  border-radius: var(--border-radius);
  background-color: var(--color-blue-light);
  color: var(--color-blue-dark);
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;

  ${({$isWide}) =>
    $isWide &&
    `
    padding-left: 5px;
    padding-right: 5px;
  `}

  ${({$isSelected}) =>
    $isSelected &&
    `
    background-color: var(--color-primary);
    color: var(--color-white);
  `}
`;
