import {FavIcon} from './FavIcon';

import {useNotificationsQuery} from '../../../queries/useNotificationsQuery';

export const SmartFavIcon = () => {
  const notificationsQuery = useNotificationsQuery({
    pageNumber: 1,
  });

  const count = notificationsQuery.data?.unreadItems ?? 0;

  return <FavIcon count={count} />;
};
