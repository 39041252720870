import {Button, Card} from 'antd';
import dayjs from 'dayjs';
import {FC, ReactElement, useState} from 'react';
import TitleHeader from '../../../atoms/TitleHeader/index';
import {Attribute} from '../../../atoms/Attribute/Attribute';
import Country from '../../../model/Country';
import formatcoords from 'formatcoords';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../../atoms/Icon';
import {useFitToPort} from '../../../components/SeaboMap/helper';
import {SinglePortLayer} from '../../../components/SeaboMap/layers/SinglePortLayer/SinglePortLayer';
import {SeaboMap} from '../../../components/SeaboMap/SeaboMap';
import {TODO} from '../../../utils/TODO';
import {PortKey} from '../../CongestionScreens/PortCongestionScreen/utils/usePortCongestionQuery';
import {PortWeather} from './PortWeather';
import {DateTimeFormat} from '../../../utils/DateTimeFormat';
import {CurrentAveragePortstay} from './CurrentAveragePortstay';
import {MapControlButton} from '../../../components/SeaboMap/MapContext/Types';
import {makePartialMapSwitches} from '../../../components/SeaboMap/utils/makePartialMapSwitches';

const getFormattedCoords = (lat: $TSFixMe, lon: $TSFixMe) =>
  formatcoords(parseFloat(lat), parseFloat(lon)).format('DD MM ss X', {
    latLonSeparator: ', ',
    decimalPlaces: 2,
  });

const getLocalTime = (timezone: $TSFixMe) => (timezone ? dayjs().tz(timezone).format(DateTimeFormat.DateTime) : null);

const getPortDetailAttributes = ({port}: {port: TODO}) => {
  const {id, code, timezone, lat, lon, totalMoored, totalExpected} = port;
  const portDetailAttributes: Record<string, ReactElement | null | string | number> = {
    code,
    timezone,
    'Local time': getLocalTime(timezone),
    coordinates: getFormattedCoords(lat, lon),
    'Vessels in port': totalMoored,
    'Vessels expected': totalExpected,
    'Current average portstay': <CurrentAveragePortstay portId={id} />,
  };

  return portDetailAttributes;
};
export const PortHeader: FC<TODO> = ({map, port, totalMoored, totalExpected, aisVessels}) => {
  const [tooltip, setTooltip] = useState(null);

  useFitToPort(map, port);

  const onHover = ({object}: $TSFixMe) => {
    if (object && object.properties.tooltip) {
      setTooltip(object.properties.tooltip);
    } else {
      setTooltip(null);
    }
  };

  const congestionReportKey = PortKey[port.name.replaceAll(' ', '') as keyof typeof PortKey];

  const portDetailAttributes = getPortDetailAttributes({
    port: {...port, totalMoored, totalExpected},
  });

  return (
    <PortHeaderContainer>
      <PortHeaderDetails>
        <TitleHeader country={Country.getName(port.country)} title={port.name} countryCode={port.country} />
        <div className="columns">
          <div className="columns__column">
            {Object.keys(portDetailAttributes).map((attr, idx) => (
              <Attribute key={idx} label={attr} value={portDetailAttributes[attr]} tab={'xl'} />
            ))}
          </div>
        </div>
        {!!congestionReportKey && (
          <Link to={`/lab/congestion/port/${congestionReportKey}`}>
            <Button style={{marginTop: 10}}>
              <Icon type="arrow-right" size="small" style={{verticalAlign: 'middle', marginRight: 5}} /> Port congestion
              Report
            </Button>
          </Link>
        )}
      </PortHeaderDetails>
      <PortHeaderMap>
        <FlexBox>
          {port.lat && port.lon && (
            <BoxHalf>
              <PortWeather lat={port.lat} lon={port.lon} />
            </BoxHalf>
          )}
          <BoxHalf>
            <SeaboMap
              defaults={'port_detail'}
              settingIdentifier={'port_detail'}
              saveMapState={false}
              centerPoint={[port.lon, port.lat]}
              layers={[new SinglePortLayer({port, onHover})]}
              tooltip={tooltip}
              extAisVessels={aisVessels}
              initialViewState={{
                zoom: 1,
                minZoom: 0.2,
                maxZoom: 19,
              }}
              isDragPanEnabled={true}
              isScrollZoomEnabled={true}
              mapControlButtonsToHide={[MapControlButton.CARGOES, MapControlButton.VESSELS, MapControlButton.PORTS]}
              initialMapSettings={{
                switches: makePartialMapSwitches({
                  ports: false,
                  tradingAreaNames: false,
                  highRiskAreas: false,
                  emissionControlAreas: false,
                }),
              }}
              showLegend={false}
              showSearch={false}
              showControl={false}
              showFocusBtn={false}
              showNavigation={false}
              settingsButtonVisible={false}
            />
          </BoxHalf>
        </FlexBox>
      </PortHeaderMap>
    </PortHeaderContainer>
  );
};

const PortHeaderContainer = styled(Card)`
  min-height: 200px;
  margin-bottom: 20px;

  .ant-card-body {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
    padding: 20px 1px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  gap: 20px;
`;

const Box = styled.div`
  height: auto;
  align-self: stretch;
`;

const BoxHalf = styled(Box)`
  width: 50%;
`;

const PortHeaderDetails = styled(Box)`
  width: 34%;
  height: auto;
  align-self: stretch;
`;

const PortHeaderMap = styled(Box)`
  width: 66%;
`;
