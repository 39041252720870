/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ExportToPdfResponse
 */
export interface ExportToPdfResponse {
  /**
   * The URL of the PDF file. The PDF contains the complete negotiation.
   * @type {string}
   * @memberof ExportToPdfResponse
   */
  pdfUrl: string;
}

export function ExportToPdfResponseFromJSON(json: any): ExportToPdfResponse {
  return ExportToPdfResponseFromJSONTyped(json, false);
}

export function ExportToPdfResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportToPdfResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pdfUrl: json['pdfUrl'],
  };
}

export function ExportToPdfResponseToJSON(value?: ExportToPdfResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pdfUrl: value.pdfUrl,
  };
}
