/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {PortAreaType, PortAreaTypeFromJSON, PortAreaTypeFromJSONTyped, PortAreaTypeToJSON} from './';

/**
 *
 * @export
 * @interface PointOfInterest
 */
export interface PointOfInterest {
  /**
   *
   * @type {number}
   * @memberof PointOfInterest
   */
  id: number;
  /**
   *
   * @type {PortAreaType}
   * @memberof PointOfInterest
   */
  type: PortAreaType | null;
  /**
   *
   * @type {Array<Array<Array<number>>>}
   * @memberof PointOfInterest
   */
  polygon: Array<Array<Array<number>>> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof PointOfInterest
   */
  point: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof PointOfInterest
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof PointOfInterest
   */
  category: string | null;
}

export function PointOfInterestFromJSON(json: any): PointOfInterest {
  return PointOfInterestFromJSONTyped(json, false);
}

export function PointOfInterestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointOfInterest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: PortAreaTypeFromJSON(json['type']),
    polygon: json['polygon'],
    point: json['point'],
    name: json['name'],
    category: json['category'],
  };
}

export function PointOfInterestToJSON(value?: PointOfInterest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: PortAreaTypeToJSON(value.type),
    polygon: value.polygon,
    point: value.point,
    name: value.name,
    category: value.category,
  };
}
