import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getLocalStorage} from '../utils/useLocalStorage';

// The shape of this Redux partition
export interface PortsState {
  mooredVesselsGrid: GridState;
  expectedVesselsGrid: GridState;
}

interface GridState {
  page: number;
  pageSize: number;
  sorted: SortColumn[];
}

interface SortColumn {
  id: string;
  desc: boolean;
}

const initialState: PortsState = {
  mooredVesselsGrid: {
    page: 1,
    pageSize: getLocalStorage('grid_page_size-port_details_moored_vessels', 10),
    sorted: [{id: 'name', desc: false}],
  },
  expectedVesselsGrid: {
    page: 1,
    pageSize: getLocalStorage('grid_page_size-port_details_expected_vessels', 10),
    sorted: [{id: 'eta', desc: false}],
  },
};

const portsSlice = createSlice({
  name: 'Ports',
  initialState,
  reducers: {
    setMooredGridState(state, action: PayloadAction<GridState>) {
      state.mooredVesselsGrid = action.payload;
    },
    setExpectedGridState(state, action: PayloadAction<GridState>) {
      state.expectedVesselsGrid = action.payload;
    },
  },
});

export const portsReducer = portsSlice.reducer;

export const PortsActions = portsSlice.actions;
