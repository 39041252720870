import styled from 'styled-components';

//* A placeholder to be used when a vessel's charterer is not set
export const TBN = () => <Placeholder>TBN</Placeholder>;

export const TBNString = 'TBN';

//* A placeholder to be used when a cargo's charterer is not set
export const CNR = () => <Placeholder>CNR</Placeholder>;

const Placeholder = styled.span`
  font-style: italic;
`;
