import {NegotiationTermType} from '../../../../../../../api/node-backend/generated';

export const termTypeLabels: Record<NegotiationTermType, string> = {
  [NegotiationTermType.Account]: 'Account',
  [NegotiationTermType.Owners]: 'Owners',
  [NegotiationTermType.Vessel]: 'Vessel',
  [NegotiationTermType.Delivery]: 'Delivery',
  [NegotiationTermType.LoadPort]: 'Load port',
  [NegotiationTermType.Laycan]: 'Laycan',
  [NegotiationTermType.Duration]: 'Duration',
  [NegotiationTermType.Cargo]: 'Cargo',
  [NegotiationTermType.CharterHire]: 'Charter hire',
  [NegotiationTermType.Freight]: 'Freight',
  [NegotiationTermType.Demurrage]: 'Demurrage',
  [NegotiationTermType.Dispatch]: 'Dispatch',
  [NegotiationTermType.CargoHolds]: 'Cargo holds',
  [NegotiationTermType.CargoGear]: 'Cargo gear',
  [NegotiationTermType.Redelivery]: 'Redelivery',
  [NegotiationTermType.DischargePort]: 'Discharge port',
  [NegotiationTermType.Ilohc]: 'ILOHC',
  [NegotiationTermType.CargoExclusions]: 'Cargo exclusions',
  [NegotiationTermType.TradingExclusions]: 'Trading exclusions',
  [NegotiationTermType.BunkerClause]: 'Bunker clause',
  [NegotiationTermType.LawArbitration]: 'Law / arbitration',
  [NegotiationTermType.OtherwiseAsPerBaseCharterParty]: 'Otherwise as per base charter party',
  [NegotiationTermType.Addcom]: 'Addcom',
  [NegotiationTermType.Brokerage]: 'Brokerage',
};
