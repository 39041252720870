import React, {FC, useState} from 'react';
import {getDataFieldId} from '../../utils/calcWarnings/calcWarnings';
import {Select, SelectProps} from 'antd';
import {TODO} from '../../../../../utils/TODO';

export interface SelectWithDataFieldIdProps<T> extends SelectProps<T> {
  area: string;
  field: string;
}

export const SelectWithDataFieldId: FC<SelectWithDataFieldIdProps<TODO>> = ({area, field, ...props}) => {
  const [defaultOpen, setDefaultOpen] = useState(false);

  return (
    <div
      tabIndex={0}
      style={{display: 'inline-block'}}
      onFocus={() => {
        setDefaultOpen(true);
      }}
      data-field-id={getDataFieldId({
        field: field,
        area: area,
      })}>
      <Select
        open={defaultOpen}
        onDropdownVisibleChange={display => {
          setDefaultOpen(display);
        }}
        {...props}
        style={{width: 100}}
      />
    </div>
  );
};
