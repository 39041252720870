import React, {FC} from 'react';
import DetailsColumn from '../../../../atoms/DetailsSection/DetailsColumn';
import {Attribute} from '../../../../atoms/Attribute/Attribute';
import {Row} from 'antd';
import {VesselDetailsVessel} from '../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {dateRangeString} from '../../../../model/Vessel';
import {NotAvailable} from '../../../../utils/NotAvailable';

export const NextDryDockTable: FC<{vessel: VesselDetailsVessel}> = ({vessel}) => (
  <Row>
    <DetailsColumn size={12}>
      <Attribute tab={'xxl'} value={dateRangeString(vessel.dryDockFrom, vessel.dryDockTo)} label={'Period'} />
      <Attribute tab={'xxl'} label={'Note'} value={vessel?.dryDockNote ? vessel.dryDockNote : NotAvailable} />
    </DetailsColumn>
  </Row>
);
