import {
  NegotiationParty,
  NegotiationRoundResult,
  NegotiationStatus,
  NegotiationSubject,
  NegotiationSubjectStatus,
  NegotiationTerm,
  NegotiationTermStatus,
  NegotiationTermWasAddedIn,
} from '../../../api/node-backend/generated';
import {assertUnreachable} from '../../../utils/assert';
import {NegotiationCase} from './createMockNegotiation';
import {getSummaryFromRoundResult} from './getSummaryFromRoundResult';
import {mockNegotiationCounterpart, mockNegotiationCreator} from './mockNegotiationSummary';

/**
 * Returns mocked round results for the given party, round case and committed status.
 */
export const createMockRoundResults = ({
  id,
  party,
  status,
  allTermsSettled,
  allSubjectsAccepted,
  allSubjectsSettled,
  latestRoundCommitted,
  overwriteCommitDate,
}: NegotiationCase & {
  id: string;
  overwriteCommitDate?: Date;
}) => {
  const roundResults = getRoundResultsByStatus(
    id,
    status,
    allTermsSettled,
    allSubjectsAccepted,
    allSubjectsSettled,
    overwriteCommitDate
  );

  let lastIndex = roundResults.length - 1;

  // make sure there is a counter round if the given party is not the same as the last of getRoundResultsByStatus
  if (roundResults[lastIndex].party !== party) {
    roundResults.push(createCounterRound(roundResults[lastIndex], party));
    lastIndex++;
  }

  // replace the latest round result to match the given arguments
  roundResults.splice(
    lastIndex,
    1,
    createRoundResultByCase(roundResults[lastIndex], {
      party,
      status,
      allTermsSettled,
      allSubjectsAccepted,
      allSubjectsSettled,
      latestRoundCommitted,
    })
  );

  return roundResults;
};

export const getRoundResultsByStatus = (
  id: string,
  status: NegotiationStatus,
  allTermsSettled: boolean,
  allSubjectsAccepted: boolean,
  allSubjectsSettled: boolean,
  overwriteCommitDate?: Date
): NegotiationRoundResult[] => {
  // roundCases are an abstraction on top of the negotiation status and all terms accepted
  // roundCases are only viable in correct order
  // at the bottom of this file are the roundResults for each roundCase

  // cancelled case does not fit in the roundCase order, so it is handled separately
  if (status === NegotiationStatus.Cancelled) {
    return [{...mockRoundResults[0], id: `${id}--cancelled`}];
  }

  const roundCase = getRoundCaseFromStatus(status, allTermsSettled, allSubjectsAccepted, allSubjectsSettled);

  const endIndex = negotiationRoundCases.indexOf(roundCase) + 1;

  const roundResults = [];
  for (let i = 0; i < endIndex; i++) {
    const mockRoundResult = {
      ...mockRoundResults[i],
      id: `${id}--${negotiationRoundCases[i]}`,
    };

    if (overwriteCommitDate && mockRoundResult.committedAt) {
      mockRoundResult.committedAt = overwriteCommitDate;
      mockRoundResult.sentAt = overwriteCommitDate;
    }

    roundResults.push(mockRoundResult);
  }
  return roundResults;
};

export const negotiationRoundCases = [
  'offer',
  'ongoing',
  'ongoing-all-settled',
  'recap',
  'recap-all-settled',
  'settled',
] as const;

/**
 * The possible states of a negotiation round.
 * see comments within mockRoundResults for the round results that correspond to each state.
 */
export type NegotiationRoundCase = (typeof negotiationRoundCases)[number];

const getRoundCaseFromStatus = (
  status: Exclude<NegotiationStatus, NegotiationStatus.Cancelled>,
  allTermsSettled: boolean,
  allSubjectsAccepted: boolean,
  allSubjectsSettled: boolean
): NegotiationRoundCase => {
  switch (status) {
    case NegotiationStatus.Offer:
      return 'offer';
    case NegotiationStatus.Ongoing:
      return allTermsSettled && allSubjectsAccepted ? 'ongoing-all-settled' : 'ongoing';
    case NegotiationStatus.Recap:
      return allTermsSettled && allSubjectsSettled ? 'recap-all-settled' : 'recap';
    case NegotiationStatus.Settled:
      return 'settled';
    default:
      assertUnreachable(status);
  }
};

export const NEGOTIATION_COUNTER_ROUND_SUFFIX = '-counter-round';
const createCounterRound = (roundResult: NegotiationRoundResult, party: NegotiationParty): NegotiationRoundResult => {
  return {
    ...roundResult,
    id: roundResult.id + NEGOTIATION_COUNTER_ROUND_SUFFIX,
    party,
  };
};

const createRoundResultByCase = (
  roundResult: NegotiationRoundResult,
  {party, status, allTermsSettled, allSubjectsAccepted, allSubjectsSettled, latestRoundCommitted}: NegotiationCase
): NegotiationRoundResult => ({
  ...roundResult,
  party,
  negotiationStatus: status,
  committedBy: latestRoundCommitted ? roundResult.committedBy : null,
  committedAt: latestRoundCommitted ? roundResult.committedAt : null,
  terms: transformTermsByCase(roundResult.terms, party, allTermsSettled),
  subjects: transformSubjectsByCase(roundResult.subjects, allSubjectsAccepted, allSubjectsSettled),
});

const transformTermsByCase = (terms: NegotiationTerm[], party: NegotiationParty, accept: boolean): NegotiationTerm[] =>
  [...terms].map(term => ({
    ...term,
    lastEditedBy: accept ? party : term.lastEditedBy,
    status: accept && term.status !== NegotiationTermStatus.Rejected ? NegotiationTermStatus.Accepted : term.status,
  }));

const transformSubjectsByCase = (
  subjects: NegotiationSubject[],
  accept: boolean,
  settle: boolean
): NegotiationSubject[] =>
  [...subjects].map(subject => ({
    ...subject,
    status:
      // eslint-disable-next-line no-nested-ternary
      settle ? NegotiationSubjectStatus.Lifted : accept ? NegotiationSubjectStatus.Accepted : subject.status,
  }));

// This creator-offer-round initially adds 3 terms
export const mockOfferRound: NegotiationRoundResult = {
  id: 'round0',
  committedBy: mockNegotiationCreator,
  committedAt: new Date('2023-04-01'),
  sentAt: new Date('2023-04-01'),
  subjects: [],
  party: NegotiationParty.Creator,
  negotiationStatus: NegotiationStatus.Offer,
  replyTime: {
    duration: 1000 * 60 * 60 * 24, // 1 day
    timestamp: null,
  },
  terms: [
    {
      originId: 'term0-0',
      id: 'term0-0',
      text: 'term0-0',
      sortIndex: 0,
      status: NegotiationTermStatus.Ongoing,
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      type: null,
      subjects: [],
      lastEditedBy: NegotiationParty.Creator,
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      type: null,
      originId: 'term1-0',
      id: 'term1-0',
      subjects: [],
      text: 'term1-0',
      sortIndex: 1,
      status: NegotiationTermStatus.Ongoing,
      lastEditedBy: NegotiationParty.Creator,
      lastModifiedBy: NegotiationParty.Creator,
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      originId: 'term2-0',
      type: null,
      id: 'term2-0',
      text: 'term2-0',
      sortIndex: 2,
      status: NegotiationTermStatus.Ongoing,
      subjects: [],
      lastEditedBy: NegotiationParty.Creator,
      lastModifiedBy: NegotiationParty.Creator,
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      lastModifiedAt: new Date('2023-04-03'),
    },
  ],
};

// This counterpart-round accepts the second term, rejects the third and adds a new one
export const mockCounterOfferRound: NegotiationRoundResult = {
  ...mockOfferRound,
  id: 'round1',
  committedBy: mockNegotiationCounterpart,
  committedAt: new Date('2023-04-02'),
  sentAt: new Date('2023-04-02'),
  party: NegotiationParty.Counterpart,
  negotiationStatus: NegotiationStatus.Ongoing,
  replyTime: {
    duration: 1000 * 60 * 60 * 24, // 1 day
    timestamp: null,
  },
  terms: [
    {
      originId: 'term0-0',
      id: 'term0-1',
      text: 'term0-0',
      type: null,
      subjects: [],
      sortIndex: 0,
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      status: NegotiationTermStatus.Ongoing,
      lastEditedBy: NegotiationParty.Creator,
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      originId: 'term1-0',
      id: 'term1-1',
      text: 'term1-0',
      type: null,
      sortIndex: 1,
      subjects: [],
      status: NegotiationTermStatus.Accepted,
      lastEditedBy: NegotiationParty.Counterpart,
      lastModifiedBy: NegotiationParty.Creator,
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      type: null,
      originId: 'term2-0',
      id: 'term2-1',
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      subjects: [],
      text: 'term2-0',
      sortIndex: 2,
      status: NegotiationTermStatus.Rejected,
      lastEditedBy: NegotiationParty.Counterpart,
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      originId: 'term3-0',
      id: 'term3-0',
      text: 'term3-0',
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      type: null,
      sortIndex: 3,
      subjects: [],
      status: NegotiationTermStatus.Ongoing,
      lastEditedBy: NegotiationParty.Counterpart,
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
  ],
};

// This creator-round edits the last term, so that also every term-case is covered,
// except new terms, but they are covered by the offer-round
export const mockCurrentRound: NegotiationRoundResult = {
  id: 'round2',
  committedBy: null,
  subjects: [],
  committedAt: null,
  sentAt: new Date('2023-04-03'),
  party: NegotiationParty.Creator,
  negotiationStatus: NegotiationStatus.Ongoing,
  replyTime: {
    duration: null,
    timestamp: null,
  },
  terms: [
    {
      originId: 'term0-0',
      id: 'term0-2',
      subjects: [],
      type: null,
      text: 'term0-1',
      sortIndex: 0,
      status: NegotiationTermStatus.Ongoing,
      lastEditedBy: NegotiationParty.Counterpart,
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      originId: 'term1-0',
      subjects: [],
      id: 'term1-2',
      type: null,
      text: 'term1-0',
      sortIndex: 1,
      status: NegotiationTermStatus.Accepted,
      lastEditedBy: NegotiationParty.Counterpart,
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      originId: 'term2-0',
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      id: 'term2-2',
      text: 'term2-0',
      subjects: [],
      sortIndex: 2,
      type: null,
      status: NegotiationTermStatus.Rejected,
      lastEditedBy: NegotiationParty.Counterpart,
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
    {
      originId: 'term3-0',
      id: 'term3-1',
      type: null,
      text: 'term3-1',
      wasAddedIn: NegotiationTermWasAddedIn.Ongoing,
      sortIndex: 3,
      status: NegotiationTermStatus.Ongoing,
      lastEditedBy: NegotiationParty.Creator,
      subjects: [],
      lastModifiedBy: NegotiationParty.Creator,
      lastModifiedAt: new Date('2023-04-03'),
    },
  ],
};

export const mockRoundResults: NegotiationRoundResult[] = [
  // NegotiationRoundCase: "offer"
  mockOfferRound,
  // NegotiationRoundCase: "ongoing"
  mockCounterOfferRound,
  // NegotiationRoundCase: "ongoing-all-settled"
  {
    ...mockCurrentRound,
    id: 'round3',
    committedBy: mockNegotiationCreator,
    committedAt: new Date('2023-04-03'),
    sentAt: new Date('2023-04-03'),
    party: NegotiationParty.Creator,
    terms: mockCurrentRound.terms.map(term => ({
      ...term,
      lastEditedBy: NegotiationParty.Creator,
      status: term.status !== NegotiationTermStatus.Rejected ? NegotiationTermStatus.Accepted : term.status,
    })),
    subjects: [
      {
        negotiationId: '',
        roundResultId: 'round4',
        termId: null,
        party: NegotiationParty.Creator,
        originId: 'subject0-0',
        id: 'subject0-0',
        text: 'subject0-0',
        lastModifiedAt: new Date('2023-04-04'),
        lastModifiedBy: NegotiationParty.Counterpart,
        status: NegotiationSubjectStatus.Accepted,
        textLastUpdatedBy: NegotiationParty.Counterpart,
      },
    ],
  },
  // NegotiationRoundCase: "recap"
  {
    ...mockCurrentRound,
    id: 'round4',
    committedBy: mockNegotiationCounterpart,
    committedAt: new Date('2023-04-04'),
    sentAt: new Date('2023-04-04'),
    party: NegotiationParty.Counterpart,
    negotiationStatus: NegotiationStatus.Recap,
    terms: mockCurrentRound.terms.map(term => ({
      ...term,
      lastEditedBy: NegotiationParty.Counterpart,
      status: term.status !== NegotiationTermStatus.Rejected ? NegotiationTermStatus.Ongoing : term.status,
    })),
    subjects: [
      {
        negotiationId: '',
        roundResultId: 'round4',
        termId: null,
        party: NegotiationParty.Creator,
        originId: 'subject0-0',
        id: 'subject0-0',
        lastModifiedAt: new Date('2023-04-04'),
        lastModifiedBy: NegotiationParty.Counterpart,
        text: 'subject0-0',
        status: NegotiationSubjectStatus.Ongoing,
        textLastUpdatedBy: NegotiationParty.Counterpart,
      },
    ],
  },
  // NegotiationRoundCase: "recap-all-settled"
  {
    ...mockCurrentRound,
    id: 'round5',
    committedBy: mockNegotiationCreator,
    committedAt: new Date('2023-04-05'),
    sentAt: new Date('2023-04-05'),
    party: NegotiationParty.Creator,
    negotiationStatus: NegotiationStatus.Recap,
    terms: mockCurrentRound.terms.map(term => ({
      ...term,
      lastEditedBy: NegotiationParty.Creator,
      status: term.status !== NegotiationTermStatus.Rejected ? NegotiationTermStatus.Accepted : term.status,
    })),
    subjects: [
      {
        negotiationId: '',
        roundResultId: 'round4',
        termId: null,
        party: NegotiationParty.Creator,
        originId: 'subject0-0',
        id: 'subject0-0',
        text: 'subject0-0',
        lastModifiedAt: new Date('2023-04-04'),
        lastModifiedBy: NegotiationParty.Counterpart,
        status: NegotiationSubjectStatus.Ongoing,
        textLastUpdatedBy: NegotiationParty.Counterpart,
      },
    ],
  },
  // NegotiationRoundCase: "settled"
  {
    ...mockCurrentRound,
    id: 'round6',
    committedBy: mockNegotiationCounterpart,
    committedAt: new Date('2023-04-04'),
    sentAt: new Date('2023-04-04'),
    party: NegotiationParty.Counterpart,
    negotiationStatus: NegotiationStatus.Settled,
    terms: mockCurrentRound.terms.map(term => ({
      ...term,
      lastEditedBy: NegotiationParty.Counterpart,
      status: term.status !== NegotiationTermStatus.Rejected ? NegotiationTermStatus.Accepted : term.status,
    })),

    subjects: [
      {
        negotiationId: '',
        roundResultId: 'round4',
        termId: null,
        lastModifiedAt: new Date('2023-04-04'),
        lastModifiedBy: NegotiationParty.Counterpart,
        party: NegotiationParty.Creator,
        originId: 'subject0-0',
        id: 'subject0-0',
        text: 'subject0-0',
        status: NegotiationSubjectStatus.Lifted,
        textLastUpdatedBy: NegotiationParty.Counterpart,
      },
    ],
  },
];

export const mockRoundResultSummaries = mockRoundResults.map(getSummaryFromRoundResult);
