import {FC, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Automatically scroll to top on page navigation.
 * This became necessary with SPA links.
 *
 * See https://medium.com/fantageek/how-to-auto-scroll-to-top-in-react-router-5-7e39d314842d
 */
export const ScrollToTop: FC = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    scrollToTop();
  }, [pathname]);
  return null;
};

export const scrollToTop = (scrollBehavior: ScrollBehavior = 'auto') => {
  window.scrollTo({top: 0, left: 0, behavior: scrollBehavior});
};
