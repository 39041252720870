import styled from 'styled-components';

export type TabPaneProps = {
  id?: string;
  children?: React.ReactNode;
  active?: boolean;
  label?: string;
};

/**
 *
 *
 * In This Component the PropTypes of the withMargin HigherOrderComponent are available to provide spacing.
 * Please have a look at  [WithPadding](/#spacerpaddinghoc) for more details.
 **/

const TabPane = ({id, children, active}: TabPaneProps) => (
  <StyledTabPane active={active} id={id} aria-labelledby="nav-home-tab">
    {children}
  </StyledTabPane>
);
TabPane._META_NAME = 'TabPane';

export default TabPane;

const StyledTabPane = styled.div<{active?: boolean}>`
  ${({active}) => active && 'display: block'}
`;
