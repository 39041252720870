import {QueryClient, useQuery} from '@tanstack/react-query';
import {accountManagementApi} from '../api/symfony/symfonyApi';
import {AccountSegments} from '../api/symfony/generated';
import {UseQueryOptions} from '../api/utils/ReactQuery';

const KEY = ['accountSegments'];

export const useAccountSegmentsQuery = (options?: UseQueryOptions<AccountSegments>) =>
  useQuery({
    queryKey: KEY,
    queryFn: () => accountManagementApi.getAccountSegments(),
    ...options,
  });

export const invalidateAccountSegmentsQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: KEY,
  });
};
