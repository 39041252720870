import styled from 'styled-components';

export const LabelBadge = styled.div`
  display: inline-block;
  margin-left: 5px;
  padding: 2px 2px 1px;
  background: var(--color-white);
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-weight: bold;
  line-height: 0.8;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  border-radius: var(--border-radius);
`;
