import {RemainingOnBoard} from '../../VesselInformation/VesselTypes';
import {ConsumptionTransaction} from '../consumptionSequence/ConsumptionTransaction';

export const getConsumptionTransactionsFromROB = (remainingOnBoards: RemainingOnBoard[]): ConsumptionTransaction[] => {
  return remainingOnBoards.map(remainingOnBoard => {
    const transaction: ConsumptionTransaction = {
      description: `ROB ${remainingOnBoard.bunkerType}`,
      fuelType: remainingOnBoard.bunkerType,
      quantity: remainingOnBoard.quantity ?? 0,
      pricePerUnit: remainingOnBoard.price,
    };

    return transaction;
  });
};
