import {actionTypes as FixtureActionTypes} from './ApiService/FixtureService';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {FilterItem} from '../api/symfony/generated';
import {
  VesselFixturesDatabaseFilter,
  VesselFixturesFilterBranchDefinitions,
} from '../components/FilterProvider/Filters/Fixtures/VesselFixturesFilterBranchDefinitions';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getLocalStorage} from '../utils/useLocalStorage';
import {FilterProviderState} from '../components/FilterProvider/FilterProviderState';
import {Fixture} from '../api/node-backend/generated';

export interface GridState {
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortOrder: 'desc';
}

export interface FilterState {
  filter: VesselFixturesDatabaseFilter | null;
  filterId: number | null;
  filterProvider: FilterProviderState<typeof VesselFixturesFilterBranchDefinitions> | null;
  filterChanged: boolean;
}

// The shape of this Redux partition
interface FixtureDataState {
  fixtures: Fixture[];
  totalItems: number;
  doReload: boolean;
  error: boolean;
  filterState: FilterState;
  gridState: GridState;
  fixtureHelperLoading: boolean;
  fixtureHelperError: boolean;
  fixtureHelperErrorMessage: null;
}

const initialState: FixtureDataState = {
  fixtures: [],
  totalItems: 0,
  doReload: false,
  error: false,
  filterState: {
    filter: null,
    filterId: null,
    filterProvider: null,
    filterChanged: false,
  },
  gridState: {
    pageIndex: 1,
    pageSize: getLocalStorage('seabo_fixtures_page_size', 50),
    sortField: 'laycanFrom',
    sortOrder: 'desc',
  },
  fixtureHelperLoading: false,
  fixtureHelperError: false,
  fixtureHelperErrorMessage: null,
};

const FixtureDataSlice = createSlice({
  name: 'fixtureData',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<VesselFixturesDatabaseFilter>) => {
      state.filterState.filter = {
        ...state.filterState.filter,
        ...action.payload,
      };
      state.filterState.filterChanged = true;

      state.gridState = {
        ...state.gridState,
        ...action.payload,
      };
    },

    loadFilterSuccess: (state, action: PayloadAction<FilterItem>) => {
      const filter: FilterItem = action.payload;
      state.filterState.filter = {
        ...state.filterState.filter,
        ...filter.filterSettings,
      };
      state.filterState.filterId = filter.id;
    },

    setFilterProvider: (
      state,
      action: PayloadAction<FilterProviderState<typeof VesselFixturesFilterBranchDefinitions>>
    ) => {
      state.filterState.filterProvider = {
        ...state.filterState.filterProvider,
        ...action.payload,
      };
    },

    setGridState: (state, action: PayloadAction<Partial<GridState>>) => {
      state.gridState = {
        ...state.gridState,
        ...action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(FixtureActionTypes.patchFixture.SUCCESS, state => {
      state.doReload = true;
    });
    builder.addCase(FixtureActionTypes.createFixture.SUCCESS, state => {
      state.doReload = true;
    });
    builder.addCase(FixtureActionTypes.patchFixture.PENDING, state => {
      state.error = false;
    });
    builder.addCase(FixtureActionTypes.createFixture.PENDING, state => {
      state.error = false;
    });
    builder.addCase(FixtureActionTypes.patchFixture.ERROR, state => {
      state.error = true;
    });
    builder.addCase(FixtureActionTypes.createFixture.ERROR, state => {
      state.error = true;
    });
    builder.addCase(FixtureActionTypes.fixtureHelper.PENDING, state => {
      state.fixtureHelperLoading = true;
      state.fixtureHelperError = false;
      state.fixtureHelperErrorMessage = null;
    });
    builder.addCase(FixtureActionTypes.fixtureHelper.SUCCESS, state => {
      state.fixtureHelperLoading = false;
    });
    builder.addCase(FixtureActionTypes.fixtureHelper.ERROR, (state, action: ActionWithPayload) => {
      state.fixtureHelperLoading = false;
      state.fixtureHelperError = true;
      state.fixtureHelperErrorMessage =
        action.payload && action.payload.data && action.payload.data.error && action.payload.data.error.message;
    });
  },
});

export const fixtureDataReducer = FixtureDataSlice.reducer;
export const FixtureDataActions = FixtureDataSlice.actions;
