import {Button, List, Popconfirm, Tag, Tooltip} from 'antd';
import {VoyagePointOutput, CalcTimeLinePoint, blankVoyagePointOutput, voyagePointTypeOptions} from '../../VoyageTypes';
import {NoteIcon} from '../../../utils/NoteIcon';
import numeral from 'numeral';
import {VesselConsumptionMode} from '../../../VesselInformation/VesselTypes';
import Flag from '../../../../../../atoms/Flags/index';
import {getFormattedDateOrNotAvailable} from '../../../../../../utils/DateTime';
import {Definitions} from '../../Definitions/Definitions';
import {PointHeaderRow} from './PointHeaderRow';
import {ValueRow} from '../../Definitions/ValueRow';
import {Value} from '../../Definitions/Value';
import {Quantity} from './PointStaticView/Quantity';
import {Terms} from './PointStaticView/Terms';
import {DurationCell} from '../../utils/DurationCell';
import {AlternativePortIcon} from './PointEditView/AlternativePortIcon';
import * as LegRow from '../../LegRow';
import {ConsumptionFuelTypeLabel} from './ConsumptionFuelTypeLabel';
import {ConsumptionTooltip} from '../ConsumptionTooltip';
import {Co2EmissionValueCell} from '../Co2EmissionValueCell';
import {NotAvailable} from '../../../../../../utils/NotAvailable';
import {usePortDAForVoyagePointQuery} from './usePortDAForVoyagePointQuery';
import {DeleteOutlined, WarningOutlined} from '@ant-design/icons';
import Icon from '../../../../../../atoms/Icon';
import {useState} from 'react';

const pointTypeToColor = (type: string) => {
  switch (type) {
    case 'nextOpen':
      return 'var(--color-blue)';
    case 'loading':
      return 'var(--color-green)';
    case 'discharge':
      return 'var(--color-red)';
    default:
      return 'var(--color-black)';
  }
};

export const PointStaticView = (props: {
  consumptionModes: VesselConsumptionMode[];
  item: CalcTimeLinePoint;
  isFirstLoadingLeg: boolean;
  onActivateEditMode: () => void;
  onRemovePoint: () => void;
  avgDurationLast10VesselsInDays: number | undefined;
}) => {
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState(false);

  const {item} = props;
  const point = item.item;

  const outputItem: VoyagePointOutput = item.outputItem ?? blankVoyagePointOutput;

  const portDAQuery = usePortDAForVoyagePointQuery(item);

  return (
    <LegRow.Wrapper showActions={isConfirmDeletePopupOpen}>
      <List.Item.Meta
        title={
          <LegRow.Header>
            <div data-cy="legRowNameView" key={'name'} onClick={props.onActivateEditMode}>
              {point.name ? (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Flag countryCode={point.countryCode} />{' '}
                  <span style={{marginLeft: 4, fontWeight: 700}}>{point.name}</span>
                </div>
              ) : (
                '[No Name]'
              )}
            </div>
            {point.alternativePorts && (
              <div data-cy="legRowAlternativesView" onClick={props.onActivateEditMode}>
                <Tooltip overlay={'Alternative ports available.'}>
                  <AlternativePortIcon />
                </Tooltip>
              </div>
            )}
            <div data-cy="legRowTypesView" key={'types'} onClick={props.onActivateEditMode}>
              {point.isInSECA && <Tag>ECA</Tag>}
              {point.types?.map(key => (
                <Tag key={key} color={pointTypeToColor(key)}>
                  {voyagePointTypeOptions[key].label.toUpperCase()}
                </Tag>
              ))}
            </div>
            {point.note !== undefined && point.note.length > 0 && (
              <Tooltip key={'note'} title={point.note}>
                <NoteIcon data-cy="legRowNotes" key={'icon'} />
              </Tooltip>
            )}
            <LegRow.Actions>
              <Button
                size="small"
                type="text"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={props.onActivateEditMode}>
                <Icon type={'edit'} />
                <span className="action-label"> Edit</span>
              </Button>
              <Popconfirm
                data-cy="legRowRemove"
                onOpenChange={setIsConfirmDeletePopupOpen}
                onConfirm={props.onRemovePoint}
                overlayInnerStyle={{padding: 8}}
                title={'Are you sure you want to delete this leg?'}>
                <Button size="small" type="text" danger style={{display: 'flex', alignItems: 'center'}}>
                  <DeleteOutlined />
                  <span className="action-label"> Delete</span>
                </Button>
              </Popconfirm>
            </LegRow.Actions>
          </LegRow.Header>
        }
        description={
          <Definitions>
            <PointHeaderRow avgDurationLast10VesselsInMinutes={props.avgDurationLast10VesselsInDays} />
            <ValueRow
              onClick={() => {
                props.onActivateEditMode();
              }}>
              <Value>
                <ConsumptionTooltip pointOutput={outputItem} point={point}>
                  <ConsumptionFuelTypeLabel point={point} type={'main'} /> {point.consumption?.mainConsumption || '0'}{' '}
                  mts/d
                </ConsumptionTooltip>
              </Value>
              <Value>
                <ConsumptionTooltip pointOutput={outputItem} point={point}>
                  <ConsumptionFuelTypeLabel point={point} type={'aux'} /> {point.consumption?.auxConsumption || '0'}{' '}
                  mts/d
                </ConsumptionTooltip>
              </Value>
              <Value>
                <Quantity point={point} />
              </Value>
              <Value>
                <Terms point={point} />
              </Value>
              <Value>{point.turnTimeInHours || '0'} h</Value>
              <Value>{point.waitingDurationInHours || '0'} h</Value>
              <Value
                style={
                  portDAQuery.data && portDAQuery.data.totalCost !== point.portCosts
                    ? {color: 'var(--color-red-dark)'}
                    : undefined
                }>
                {portDAQuery.data && portDAQuery.data.totalCost !== point.portCosts ? (
                  <Tooltip title={<>${portDAQuery.data.totalCost} estimated</>}>
                    {point.portCosts === undefined ? NotAvailable : numeral(point.portCosts).format('$0,0[.]00')}
                    <WarningOutlined style={{marginLeft: 4}} />
                  </Tooltip>
                ) : (
                  <>{point.portCosts === undefined ? NotAvailable : numeral(point.portCosts).format('$0,0[.]00')}</>
                )}
              </Value>
              <DurationCell point={item} />
              <Co2EmissionValueCell point={point} pointOutput={outputItem} />
              <Value calculated>
                <span data-cy="legRowStartDate">{getFormattedDateOrNotAvailable(outputItem.startDate)}</span>
              </Value>
              <Value calculated>
                <span data-cy="legRowEndDate">{getFormattedDateOrNotAvailable(outputItem.endDate)}</span>
              </Value>
            </ValueRow>
          </Definitions>
        }
      />
    </LegRow.Wrapper>
  );
};
