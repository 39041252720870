import numeral from 'numeral';
import {Table} from 'antd';
import {MulticalcVesselInput, MulticalcCargoInput} from '../../types';
import {NestedTable} from './NestedTable/NestedTable';
import {MultiCalculation} from '../../useMultiCalculation';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import {OneCalcResult} from '../../utils/oneCalc';
import {Route} from '../../utils/Route';
import {TableCargoDetail, TableProjectDetail} from '../components';

type Props = {
  testId: string;
  vessels: MulticalcVesselInput[];
  multiCalculation: MultiCalculation;
  breakEvenCalcs: OneCalcResult[];
  cargoes: MulticalcCargoInput[];
};

export const CargoToVesselTable = ({testId, vessels, multiCalculation, breakEvenCalcs, cargoes}: Props) => {
  return (
    <Table<MulticalcCargoInput>
      data-testid={'CargoToVesselTable_' + testId}
      data-cy={'CargoToVesselTable_' + testId}
      loading={multiCalculation.oneCalcResults === undefined}
      dataSource={cargoes}
      rowKey={input => `${input.cargo.id}_${input.project?.id ?? ''}_${testId}`}
      columns={[
        {
          key: 'project',
          dataIndex: ['project'],
          title: 'Project',
          width: 4,
          render: (value, item) => {
            if (item.project === undefined) {
              return '';
            }
            return <TableProjectDetail project={item.project} />;
          },
        },
        {
          key: 'cargo',
          title: 'Cargo',
          width: 4,
          render: (value, item) => {
            return (
              <span data-testid={'CargoToVesselTableVesselColumn_' + testId}>
                <TableCargoDetail cargo={item.cargo} />
              </span>
            );
          },
        },
        {
          key: 'route',
          title: 'Route',
          width: 5,
          render: (_, item) => {
            return <Route cargo={item.cargo} />;
          },
        },
        {
          key: 'freightIdea_min',
          align: 'right',
          title: 'Min. Freight idea on break even',
          width: 2,
          render: (_, item) => {
            const val = minBy(
              breakEvenCalcs.filter(calc => calc.cargo.id === item.cargo.id),
              calc => calc.voyageCalculationInput.cargo.freightIdea
            )?.voyageCalculationInput.cargo.freightIdea;
            return (
              <span data-testid={'CargoToVesselTableFreightIdeaColumn_' + testId}>
                {numeral(val).format('$0,0[.]000')}
              </span>
            );
          },
        },
        {
          key: 'revenue_net_max',
          align: 'right',
          title: 'Max. Net revenue on Break Even',
          width: 2,
          render: (_, item) => {
            const val = maxBy(
              breakEvenCalcs.filter(calc => calc.cargo.id === item.cargo.id),
              calc => calc.voyageCalculationOutput.cargo.revenueNet
            )?.voyageCalculationOutput.cargo.revenueNet;
            return numeral(val).format('$0,0');
          },
        },
        {
          key: 'duration_min',
          align: 'right',
          title: 'Min. Duration',
          width: 2,
          render: (_, item) => {
            const val = minBy(
              breakEvenCalcs.filter(calc => calc.cargo.id === item.cargo.id),
              calc => calc.voyageCalculationOutput.cargo.duration
            )?.voyageCalculationOutput.cargo.duration;

            return val ? `${val.toFixed(1)} days` : '';
          },
        },
      ]}
      pagination={false}
      expandable={{
        expandedRowRender: cargoInput => {
          return (
            <NestedTable cargo={cargoInput} multiCalculation={multiCalculation} vessels={vessels} testId={testId} />
          );
        },
      }}
    />
  );
};
