import {FC, useEffect} from 'react';
import {NegotiationDetailScreen} from './NegotiationDetailScreen';
import {SupportAgentsBar} from '../../../components/SupportAgentsBar/SupportAgentsBar';

export const NegotiationDetailGuestScreen: FC = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <NegotiationDetailScreen isGuest />
      <SupportAgentsBar />
    </>
  );
};
