import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {numberBetween, numberBetweenAndGreaterOrEqual} from '../../../../../utils/validators';
import {convertToInt} from '../../FilterConversion/utils/FilterConversionUtils';

export interface DatabaseType {
  deadWeight?: {
    from?: number;
    to?: number;
  };
  teu?: {
    from?: number;
    to?: number;
  };
  teu14?: {
    from?: number;
    to?: number;
  };
}

export interface InternalType {
  deadWeightFrom: number | '';
  deadWeightTo: number | '';
  teuFrom: number | '';
  teuTo: number | '';
  teu14From: number | '';
  teu14To: number | '';
}

export const Defaults: InternalType = {
  deadWeightFrom: '',
  deadWeightTo: '',
  teuFrom: '',
  teuTo: '',
  teu14From: '',
  teu14To: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {deadWeight, teu, teu14} = database;
  return {
    deadWeightFrom: deadWeight?.from ?? '',
    deadWeightTo: deadWeight?.to ?? '',
    teuFrom: teu?.from ?? '',
    teuTo: teu?.to ?? '',
    teu14From: teu14?.from ?? '',
    teu14To: teu14?.to ?? '',
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {deadWeightFrom, deadWeightTo, teuFrom, teuTo, teu14From, teu14To} = internal;
  return {
    deadWeight: {
      from: convertToInt(deadWeightFrom),
      to: convertToInt(deadWeightTo),
    },
    teu: {
      from: convertToInt(teuFrom),
      to: convertToInt(teuTo),
    },
    teu14: {
      from: convertToInt(teu14From),
      to: convertToInt(teu14To),
    },
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Vessel size',
  branch: 'vesselAttributes',
  defaults: Defaults,
  validators: {
    deadWeightFrom: numberBetween(0, 999999),
    deadWeightTo: numberBetweenAndGreaterOrEqual(0, 999999, 'deadWeightFrom'),
    teuFrom: numberBetween(0, 99999),
    teuTo: numberBetweenAndGreaterOrEqual(0, 99999, 'teuFrom'),
    teu14From: numberBetween(0, 99999),
    teu14To: numberBetweenAndGreaterOrEqual(0, 99999, 'teu14From'),
  },
  fromDatabase,
  toDatabase,
});
