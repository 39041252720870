/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Attachment,
  AttachmentFromJSON,
  AttachmentFromJSONTyped,
  AttachmentToJSON,
  CompanyAddress,
  CompanyAddressFromJSON,
  CompanyAddressFromJSONTyped,
  CompanyAddressToJSON,
  CompanyContact,
  CompanyContactFromJSON,
  CompanyContactFromJSONTyped,
  CompanyContactToJSON,
  CompanyUser,
  CompanyUserFromJSON,
  CompanyUserFromJSONTyped,
  CompanyUserToJSON,
} from './';

/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {number}
   * @memberof Company
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof Company
   */
  foundYear?: number | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  taxId?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  employees?: string | null;
  /**
   *
   * @type {number}
   * @memberof Company
   */
  vesselsManaged?: number | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  business?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  companyType?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  companyMailDomain?: string | null;
  /**
   *
   * @type {Attachment}
   * @memberof Company
   */
  companyLogo?: Attachment;
  /**
   *
   * @type {CompanyUser}
   * @memberof Company
   */
  user: CompanyUser;
  /**
   *
   * @type {CompanyAddress}
   * @memberof Company
   */
  address: CompanyAddress;
  /**
   *
   * @type {CompanyContact}
   * @memberof Company
   */
  contact: CompanyContact;
  /**
   *
   * @type {boolean}
   * @memberof Company
   */
  isVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Company
   */
  isFollowing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Company
   */
  isOnboarded: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Company
   */
  isPayingCustomer: boolean;
  /**
   *
   * @type {Date}
   * @memberof Company
   */
  createdAt: Date;
  /**
   *
   * @type {boolean}
   * @memberof Company
   */
  showConnectMarketplaceBanner: boolean;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  promotionUntil?: string | null;
}

export function CompanyFromJSON(json: any): Company {
  return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    foundYear: !exists(json, 'foundYear') ? undefined : json['foundYear'],
    taxId: !exists(json, 'taxId') ? undefined : json['taxId'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    vesselsManaged: !exists(json, 'vesselsManaged') ? undefined : json['vesselsManaged'],
    business: !exists(json, 'business') ? undefined : json['business'],
    companyType: !exists(json, 'companyType') ? undefined : json['companyType'],
    companyMailDomain: !exists(json, 'companyMailDomain') ? undefined : json['companyMailDomain'],
    companyLogo: !exists(json, 'companyLogo') ? undefined : AttachmentFromJSON(json['companyLogo']),
    user: CompanyUserFromJSON(json['user']),
    address: CompanyAddressFromJSON(json['address']),
    contact: CompanyContactFromJSON(json['contact']),
    isVerified: json['isVerified'],
    isFollowing: !exists(json, 'isFollowing') ? undefined : json['isFollowing'],
    isOnboarded: json['isOnboarded'],
    isPayingCustomer: json['isPayingCustomer'],
    createdAt: new Date(json['createdAt']),
    showConnectMarketplaceBanner: json['showConnectMarketplaceBanner'],
    promotionUntil: !exists(json, 'promotionUntil') ? undefined : json['promotionUntil'],
  };
}

export function CompanyToJSON(value?: Company | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    foundYear: value.foundYear,
    taxId: value.taxId,
    employees: value.employees,
    vesselsManaged: value.vesselsManaged,
    business: value.business,
    companyType: value.companyType,
    companyMailDomain: value.companyMailDomain,
    companyLogo: AttachmentToJSON(value.companyLogo),
    user: CompanyUserToJSON(value.user),
    address: CompanyAddressToJSON(value.address),
    contact: CompanyContactToJSON(value.contact),
    isVerified: value.isVerified,
    isFollowing: value.isFollowing,
    isOnboarded: value.isOnboarded,
    isPayingCustomer: value.isPayingCustomer,
    createdAt: value.createdAt.toISOString(),
    showConnectMarketplaceBanner: value.showConnectMarketplaceBanner,
    promotionUntil: value.promotionUntil,
  };
}
