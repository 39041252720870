import {FC} from 'react';
import {Button, Form} from 'antd';
import SpeedInput from '../../../components/SpeedInput';
import {ResultDisplay} from '../../../components/StationsSelector/ResultDisplay';
import {getSpeedLabel} from '../utils';

type DistanceCalculatorSpeedProps = {
  showInput: boolean;
  result: number | null;
  isCalculating: boolean;
  onCalculate: () => void;
  onClear?: () => void;
};

export const DistanceCalculatorSpeed: FC<DistanceCalculatorSpeedProps> = ({
  showInput,
  result,
  isCalculating,
  onCalculate,
  onClear,
}) => {
  const width = '100%';

  if (showInput) {
    return (
      <Form.Item name="speed" label="Speed" style={{width}}>
        <SpeedInput onChange={onClear} data-testid="CalculatorInput-Speed" />
      </Form.Item>
    );
  }

  if (result) {
    return (
      <Form.Item label="Speed" data-testid="CalculatorResult-Speed">
        <ResultDisplay style={{width}} value={`${getSpeedLabel(result)} kts`} onClear={onClear} />
      </Form.Item>
    );
  }

  return (
    <Form.Item name="speed" label="Speed" data-testid="CalculatorCalculate-Speed">
      <Button type="primary" style={{width}} onClick={onCalculate} disabled={isCalculating}>
        Calculat{!isCalculating ? 'e' : 'ing'}
      </Button>
    </Form.Item>
  );
};
