import {NegotiationTermType, NewTerm} from '../../../../../../../api/node-backend/generated';
import {isNaNTS, parseIntTS} from '../../../../../../../utils/parseNumberTS';
import {termTypeLabels} from '../../../TermItem/Components/TermType/termTypeLabels';

const nonAlphanumericPattern = `[^a-zA-Z0-9]`;
const termIndexPattern = `${nonAlphanumericPattern}*?([0-9]+)${nonAlphanumericPattern}*`;
const termStartPattern = `(?:${termIndexPattern}|${nonAlphanumericPattern})?\\s*`;
const termTypePattern = `(${Object.values(termTypeLabels).join('|')})?\\s*`;

const termTextPatternSingle = `(.*)`;
const termEndPatternSingle = `(?=\n{1,})`;
const termSplitRegexSingle = new RegExp(`${termTextPatternSingle}+${termEndPatternSingle}?`, 'g');
const termRegexSingle = new RegExp(`^${termStartPattern}${termTypePattern}${termTextPatternSingle}`, 'si');

const termTextPatternDouble = `(.+\\n?.*?)`;
const termEndPatternDouble = `(?=\n{2,})`;
const termSplitRegexDouble = new RegExp(`${termTextPatternDouble}+${termEndPatternDouble}?`, 'g');
const termRegexDouble = new RegExp(`^${termStartPattern}${termTypePattern}${termTextPatternDouble}`, 'si');

const termTypeReverseMap = Object.fromEntries(
  Object.entries(termTypeLabels).map(([key, value]) => [value, key as NegotiationTermType])
);

export const parseTermsFromText = (text: string, lineBreak: 'single' | 'double'): NewTerm[] => {
  const termSplitRegex = lineBreak === 'single' ? termSplitRegexSingle : termSplitRegexDouble;
  const termRegex = lineBreak === 'single' ? termRegexSingle : termRegexDouble;

  const termMatches = text.match(termSplitRegex) ?? [];
  const terms: NewTerm[] = termMatches.filter(Boolean).map(parseSingleTerm(termRegex));
  return terms;
};

const parseSingleTerm =
  (termRegex: RegExp) =>
  (rawText: string, idx: number): NewTerm => {
    const regexMatches = termRegex.exec(rawText);
    const [, sortIndexMatch, typeMatch, text] = regexMatches ?? [];

    const hasSortIndexMatch = !!sortIndexMatch && !isNaNTS(sortIndexMatch);
    const sortIndex = hasSortIndexMatch ? parseIntTS(sortIndexMatch) - 1 : idx;

    const hasTypeMatch = !!typeMatch && !!termTypeReverseMap[typeMatch];
    const type = hasTypeMatch ? termTypeReverseMap[typeMatch] : null;

    return {
      text,
      sortIndex,
      type,
    };
  };
