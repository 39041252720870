import {Button, Table, Tooltip} from 'antd';
import {FC, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {
  NegotiationSummary,
  NegotiationTemplate,
  NegotiationTemplateSummary,
  NewTerm,
} from '../../../../../../../api/node-backend/generated';
import {TermPreview} from '../TermPreview';
import {ArrowRightOutlined} from '@ant-design/icons';
import {replaceNegotiationPlaceholdersWithValues} from '../../../../../NegotiationTemplates/utils/replaceNegotiationPlaceholdersWithValues';
import Icon from '../../../../../../../atoms/Icon';

type ContractTemplateSelectorWithPreviewProps = {
  negotiation: NegotiationSummary;
  templateSummaries: NegotiationTemplateSummary[];
  isSmall?: boolean;
  useGetTemplateDetail: (id?: number) => {data?: NegotiationTemplate};
  onChangeTerms: (terms: NewTerm[]) => void;
};

export const ContractTemplateSelectorWithPreview: FC<ContractTemplateSelectorWithPreviewProps> = ({
  negotiation,
  templateSummaries,
  isSmall,
  useGetTemplateDetail,
  onChangeTerms,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | undefined>(undefined);

  const selectedTemplateQuery = useGetTemplateDetail(selectedTemplateId);
  const selectedTemplate = selectedTemplateQuery.data;

  const termsWithValues: NewTerm[] = useMemo(() => {
    if (!selectedTemplate?.terms) {
      return [];
    }
    return selectedTemplate.terms.map((term, idx) => ({
      ...term,
      sortIndex: idx,
      text: replaceNegotiationPlaceholdersWithValues(term.text, negotiation),
    }));
  }, [negotiation, selectedTemplate]);

  useEffect(() => {
    onChangeTerms(termsWithValues);
  }, [termsWithValues, onChangeTerms]);

  const sortedTemplateSummaries = useMemo(
    () => templateSummaries.sort((a, b) => (a.companyId ?? 0) - (b.companyId ?? 0)),
    [templateSummaries]
  );

  return (
    <TermPreview terms={termsWithValues} showPreviewIfEmpty={true}>
      <StyledTable
        dataSource={sortedTemplateSummaries}
        size={isSmall ? 'small' : 'middle'}
        pagination={false}
        columns={[
          {
            title: 'Template',
            dataIndex: 'title',
            render: (_, record) => (
              <>
                <b style={{whiteSpace: 'pre'}}>
                  {record.name}
                  {!record.companyId && (
                    <Tooltip title="template provided by seabo">
                      <div style={{display: 'inline-block'}}>
                        <Icon type="logo-wave" color="blue" style={{position: 'relative', top: 2, marginLeft: 5}} />
                      </div>
                    </Tooltip>
                  )}
                </b>
                <br />({record.termCount} terms)
              </>
            ),
          },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: '',
            key: 'action',
            width: 10,
            render: (_, record) => (
              <Button
                size={isSmall ? 'small' : 'middle'}
                type={isSmall ? 'default' : 'primary'}
                onClick={() => setSelectedTemplateId(record.id)}>
                <ArrowRightOutlined />
              </Button>
            ),
          },
        ]}
        onRow={record => ({
          className: record.id === selectedTemplateId ? 'selected' : '',
          onClick: () => setSelectedTemplateId(record.id),
        })}
      />
    </TermPreview>
  );
};

const StyledTable = styled(Table)`
  height: 100% !important;
  overflow-y: auto;

  .selected {
    background: var(--color-gray-5);
  }
` as typeof Table;
