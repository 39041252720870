/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselOfferUpdateRequestVessel,
  VesselOfferUpdateRequestVesselFromJSON,
  VesselOfferUpdateRequestVesselFromJSONTyped,
  VesselOfferUpdateRequestVesselToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselOfferUpdateRequest
 */
export interface VesselOfferUpdateRequest {
  /**
   *
   * @type {VesselOfferUpdateRequestVessel}
   * @memberof VesselOfferUpdateRequest
   */
  vessel: VesselOfferUpdateRequestVessel;
}

export function VesselOfferUpdateRequestFromJSON(json: any): VesselOfferUpdateRequest {
  return VesselOfferUpdateRequestFromJSONTyped(json, false);
}

export function VesselOfferUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselOfferUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vessel: VesselOfferUpdateRequestVesselFromJSON(json['vessel']),
  };
}

export function VesselOfferUpdateRequestToJSON(value?: VesselOfferUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vessel: VesselOfferUpdateRequestVesselToJSON(value.vessel),
  };
}
