import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {Blurred} from '../../utils/Blurred';

export interface ShipBuilderColumnAccessorOptions {
  blurred?: boolean;
}

const shipBuilderAccessor =
  (type: RowValueType, options: ShipBuilderColumnAccessorOptions): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    return <Blurred blurred={options.blurred}>{vessel.shipBuilder}</Blurred>;
  };
export const shipBuilderColumn = ({
  type,
  minWidth = 6,
  sortable = false,
  options = {},
}: {
  type?: RowValueType;
  minWidth?: number;
  sortable?: boolean;
  options?: ShipBuilderColumnAccessorOptions;
}): CustomColumnDef<SeaboVessel> => ({
  header: 'Ship builder',
  id: 'shipBuilder',
  accessorFn: shipBuilderAccessor(type, options),
  cell: info => info.getValue(),
  minWidth,
  enableSorting: sortable,
});
