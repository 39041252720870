import {BackwardOutlined, PauseOutlined, CaretRightOutlined, ForwardOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {Dayjs} from 'dayjs';
import React, {FC} from 'react';
import styled from 'styled-components';
import {VoyageTimeSeriesEntry} from '../../../utils/calculateVoyageTimeSeries';
import {TimelineDateControl} from './TimelineDateControl';

type AnimationControlsProps = {
  voyageTimeSeries: VoyageTimeSeriesEntry[];
  minDate: Dayjs;
  isPlaying?: boolean;
  autoPlay: boolean;
  selectedDate?: Dayjs;
  onPlay?: () => void;
  onPause?: () => void;
  onDateChange: (selectedDate: Dayjs) => void;
  onToggleAutoPlay: () => void;
};

export const AnimationControls: FC<AnimationControlsProps> = ({
  voyageTimeSeries,
  minDate,
  isPlaying,
  autoPlay,
  selectedDate,
  onPlay,
  onPause,
  onDateChange,
  onToggleAutoPlay,
}) => {
  return (
    <AnimationControlsContainer>
      <AutoPlayToggle onClick={onToggleAutoPlay} type={autoPlay ? 'primary' : 'dashed'}>
        Auto
        <br />
        Play
      </AutoPlayToggle>
      <PlayerButton
        type="default"
        icon={<BackwardOutlined />}
        onClick={() => onDateChange((selectedDate ?? minDate).subtract(5, 'days'))}
      />
      {isPlaying ? (
        <PlayerButton className="pause-button" type="default" icon={<PauseOutlined />} onClick={onPause} />
      ) : (
        <PlayerButton className="start-button" type="default" icon={<CaretRightOutlined />} onClick={onPlay} />
      )}
      <PlayerButton
        type="default"
        icon={<ForwardOutlined />}
        onClick={() => onDateChange((selectedDate ?? minDate).add(5, 'days'))}
      />
      <TimelineDateControl
        voyageTimeSeries={voyageTimeSeries}
        minDate={minDate}
        selectedDate={selectedDate}
        onDateChange={onDateChange}
      />
    </AnimationControlsContainer>
  );
};

const AnimationControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background: var(--color-gray-6);
  z-index: 10;
`;

const AutoPlayToggle = styled(Button)`
  padding: 5px;
  font-size: var(--font-size-sm);
  line-height: 1;
`;

const PlayerButton = styled(Button)`
  flex-shrink: 0;
  width: 40px !important;
`;
