import {FC} from 'react';
import {Button, ButtonProps} from 'antd';
import styled from 'styled-components';
import {Link, LinkProps} from 'react-router-dom';
import {To} from '@remix-run/router/history';

export const UpgradeButton: FC<
  {
    to: To;
    target?: LinkProps['target'];
  } & Omit<ButtonProps, 'primary' | 'secondary' | 'danger' | 'ghost' | 'dashed' | 'text' | 'link'>
> = ({to, target, ...props}) => {
  return (
    <Link target={target} to={to}>
      <StyledButton {...props}>{props.children}</StyledButton>
    </Link>
  );
};

const StyledButton = styled(Button)`
  height: auto;
  padding: 8px 18px;
  background: var(--color-upgrade) !important;
  color: var(--color-white) !important;
  font-weight: bold;
  text-transform: uppercase !important;
  border: none;
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);

  &:active {
    background: var(--color-upgrade-light);
    color: var(--color-white);
  }

  &:hover {
    background: var(--color-upgrade-dark);
    color: var(--color-white);
  }
`;
