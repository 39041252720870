import {VoyagePoint} from '../../../VoyageTypes';
import {DownloadOutlined, UploadOutlined} from '@ant-design/icons';
import {NotApplicable} from '../../../utils/NotApplicable';

type Props = {
  point: VoyagePoint;
};
export const Quantity = (props: Props) => {
  const isLoading = props.point.types?.includes('loading');
  const isDischarge = props.point.types?.includes('discharge');
  const active = isLoading || isDischarge;

  if (!active) {
    return <NotApplicable />;
  }

  const quantity = isLoading ? props.point.load : props.point.dis;

  return (
    <span>
      {isLoading ? <DownloadOutlined /> : <UploadOutlined />}
      <span style={{marginLeft: 4}}>{quantity}</span>
    </span>
  );
};
