import {searchApi} from '../../../../../api/symfony/symfonyApi';
import {ParsedCargoData} from '../../../../../api/parser/generated';
import {Location} from '../../../../../components/CargoPicker/CargoPicker';

export const getStationLocations = async ({
  portNames,
  locationType,
  cargoData,
}: {
  portNames?: string[];
  locationType: 'nextopen' | 'discharge' | 'loading';
  cargoData?: ParsedCargoData;
}) => {
  const dischargingRate = cargoData?.dischargeRate ? parseInt(cargoData.dischargeRate) : undefined;
  const loadingRate = cargoData?.loadRate ? parseInt(cargoData.loadRate) : undefined;

  const locations: Location[] = (
    await Promise.all(
      (portNames ?? []).map(async portName => {
        const results = (
          await searchApi.getAutoComplete({
            query: portName,
            indexNames: 'location_country,ports,location_tradingArea',
          })
        ).data.items;

        const port = results['ports']?.items?.[0]?.source;
        const tradingArea = results['location_tradingArea']?.items?.[0]?.source;
        const country = results['location_country']?.items?.[0]?.source;
        return port ?? tradingArea ?? (country as Location);
      })
    )
  ).filter(item => !!item) as Location[];

  return locations.map(location => {
    const newLocation: Location = {...location};
    if (!newLocation.additionalInfo) {
      newLocation.additionalInfo = {};
    }

    if (locationType === 'discharge') {
      if (dischargingRate) {
        newLocation.additionalInfo.rate = dischargingRate;
        newLocation.additionalInfo.unit = 'mt';
      }
    }
    if (locationType === 'loading') {
      if (loadingRate) {
        newLocation.additionalInfo.rate = loadingRate;
        newLocation.additionalInfo.unit = 'mt';
      }
    }

    return newLocation;
  });
};
