import {FC, useRef} from 'react';
import {MapControlButton} from './MapControlButton';
import {useMapContext} from '../MapContext/MapContext';
import {MapMenu} from '../MapMenu/MapMenu';
import {MapOptionChangeValue, MapSwitches, MapSwitchesKey} from '../MapDetails/utils/types';
import useOnClickOutside from 'use-onclickoutside';
import {MapControlMenuDropdownContainer} from './MapControlMenuDropdownContainer';
import {MapOption} from '../mapOptions/mapOptions';

type MapControlCargoButtonProps = {
  switches: MapSwitches;
  mapOption: MapOption;
  isOpen: boolean;
  mapHeight: number;
  onChange: (switchId: MapSwitchesKey, value: MapOptionChangeValue) => void;
  onToggleOpen: () => void;
};

export const MapControlCargoButton: FC<MapControlCargoButtonProps> = ({
  isOpen,
  onChange,
  switches,
  mapHeight,
  mapOption,
  onToggleOpen,
}) => {
  const context = useMapContext();
  const isActive = context?.state.switches.cargo_on?.state;

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => {
    if (isOpen) {
      onToggleOpen?.();
    }
  });

  return (
    <div ref={containerRef}>
      <MapControlButton
        active={isActive}
        open={isOpen}
        iconActive={'box-open'}
        iconInactive={'cargo-disabled'}
        onClick={onToggleOpen}
      />
      {isOpen && (
        <MapControlMenuDropdownContainer mapHeight={mapHeight}>
          <MapMenu
            option={mapOption}
            switches={switches}
            onChange={onChange}
            onFilterChange={filters => {
              context.updateSwitches(draft => {
                draft.vessel_ais.filters = filters;
              });
            }}
          />
        </MapControlMenuDropdownContainer>
      )}
    </div>
  );
};
