import {ReactNode} from 'react';
import {DashboardTourDefinition} from '../dashboard/DashboardTourDefinition';
import {MarketTourDefinition} from '../market/MarketTourDefinition';
import {TourDefinition} from '../../components/OnboardingTour/TourDefinition';
import {PortsTourDefinition} from '../ports/PortListScreen/PortsTourDefinition';
import {MyFleetTourDefinition} from '../MyFleetScreens/MyFleetScreen/MyFleetTourDefinition';
import {MyCargoesTourDefinition} from '../MyCargoesScreens/MyCargoesScreen/MyCargoesTourDefinition';
import {ProjectsTourDefinition} from '../../components/ProjectsList/ProjectsTourDefinition';
import {VesselFixtureTourDefinition} from '../VesselFixturesScreen/VesselFixtureTourDefintion';
import {DistanceCalculationTourDefinition} from '../DistanceCalculator/DistanceCalculationTourDefinition';
import {VoyageCharterTourDefinition} from '../../components/CharterCalculation/Voyagecharter/VoyageCharterTourDefinition';
import {VesselDatabaseTourDefinition} from '../VesselDatabaseScreen/VesselDatabaseTourDefinition';
import {IconType} from '../../atoms/Icon';

type ContentSection = {
  title: string;
  icon?: IconType;
  description?: string | ReactNode;
  platformPath: string;
  content: ContentPiece[];
};

type ContentPiece = {
  title: string | ReactNode;
  description?: string | ReactNode;
  imageUrl?: string;
  videoUrl?: string;
  pdfUrl?: string;
};

const getContentPiecesFromTour = (tour: TourDefinition): ContentPiece[] => {
  return tour.steps.map(step => ({
    title: step.title,
    description: step.content,
    imageUrl: step.media?.type === 'image' ? step.media.url : undefined,
    videoUrl: step.media?.type === 'video' ? step.media.url : undefined,
    pdfUrl: step.pdfDownloadUrl,
  }));
};

export const helpCenterContent: ContentSection[] = [
  {
    title: 'Getting started',
    platformPath: '/users/me',
    content: [
      {
        title: 'How to add a user to your workspace',
        pdfUrl:
          'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/00%20Account%20Set%20Up/Add%20a%20user%20to%20workspace.pdf',
      },
      {
        title: 'How to add a user',
        pdfUrl:
          'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/00%20Account%20Set%20Up/How%20to%20add%20a%20user.pdf',
      },
      {
        title: 'How to add a workspace',
        pdfUrl:
          'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/00%20Account%20Set%20Up/How%20to%20add%20a%20workspace.pdf',
      },
      {
        title: 'How to upgrade your account',
        pdfUrl:
          'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/00%20Account%20Set%20Up/Upgrade%20your%20account.pdf',
      },
    ],
  },
  {
    title: 'Navigation',
    platformPath: '/users/me',
    content: [
      {
        title: 'Lets meet the navigation 1',
        pdfUrl: 'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/01%20Navigation/Navigation%201.pdf',
      },
      {
        title: 'Lets meet the navigation 2',
        pdfUrl: 'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/01%20Navigation/Navigation%202.pdf',
      },
      {
        title: 'Lets meet the navigation 3',
        pdfUrl: 'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/01%20Navigation/Navigation%203.pdf',
      },
      {
        title: 'Lets meet the navigation 4',
        pdfUrl: 'https://seabo-help.fra1.digitaloceanspaces.com/pdfs/01%20Navigation/Navigation%204.pdf',
      },
    ],
  },
  {
    title: 'Dashboard',
    platformPath: '/dashboard',
    content: getContentPiecesFromTour(DashboardTourDefinition),
  },
  {
    title: 'Market',
    platformPath: '/market',
    content: getContentPiecesFromTour(MarketTourDefinition),
  },
  {
    title: 'Vessel Database',
    platformPath: '/vessel-database',
    content: getContentPiecesFromTour(VesselDatabaseTourDefinition),
  },
  {
    title: 'Ports',
    platformPath: '/ports',
    content: getContentPiecesFromTour(PortsTourDefinition),
  },
  {
    title: 'My Fleet',
    platformPath: '/my-fleet',
    content: getContentPiecesFromTour(MyFleetTourDefinition),
  },
  {
    title: 'My Cargoes',
    platformPath: '/my-cargoes',
    content: getContentPiecesFromTour(MyCargoesTourDefinition),
  },
  {
    title: 'Projects',
    platformPath: '/projects',
    content: getContentPiecesFromTour(ProjectsTourDefinition),
  },
  {
    title: 'Fixtures',
    platformPath: '/fixtures',
    content: getContentPiecesFromTour(VesselFixtureTourDefinition),
  },
  {
    title: 'Tools / Distance Calculation',
    platformPath: '/distance-calculator',
    content: getContentPiecesFromTour(DistanceCalculationTourDefinition),
  },
  {
    title: 'Tools / Voyage Calculation',
    platformPath: '/chartercalculator/voyagecharter',
    content: getContentPiecesFromTour(VoyageCharterTourDefinition),
  },
];
