import dayjs, {Dayjs} from 'dayjs';
import {Step8Vessel} from '../steps/step8';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

type Props = {
  toDate: Dayjs;
  resultVessels: Step8Vessel[];
};

export const filterToDate = ({toDate, resultVessels}: Props): Step8Vessel[] => {
  const toDateString = dayjs(toDate).format(DateTimeFormat.IsoDate);
  return resultVessels.filter(vessel => {
    const vesselArriveDate = dayjs(vessel.estimatedArrivalDate).format(DateTimeFormat.IsoDate);
    return vesselArriveDate <= toDateString;
  });
};
