import '../../styles.scss';
import {useIsPermitted} from '../../../../utils/useIsPermitted';
import {FeaturePermission} from '../../../../api/symfony/generated';
import styled from 'styled-components';
import Icon from '../../../../atoms/Icon';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {TODO} from '../../../../utils/TODO';

export const easySharingPromotionColumnId = 'easySharingPromotion';

export const easySharingPromotionColumn = (showIfShared?: boolean): CustomColumnDef<TODO> => ({
  id: easySharingPromotionColumnId,
  size: 0,
  width: 0,
  enableSorting: false,
  header: '',
  accessorFn: d => (
    <EasySharingPromotionColumn $show={!!showIfShared && d.shared}>
      <EasySharingPromotion />
    </EasySharingPromotionColumn>
  ),
  cell: info => info.getValue(),
});

const EasySharingPromotionColumn = styled.div<{$show: boolean}>`
  position: relative;
  height: 100%;
  overflow: visible;
  ${({$show}) => !$show && 'display: none;'}
`;

const EasySharingPromotion = () => {
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);

  return (
    <EasySharingPromotionContainer>
      <EasySharingIcon type="inbox" />
      <div>
        <Headline>This circular was shared via seabo Easy Sharing</Headline>
        <Text>
          {manageOwnCompanyPermitted ? (
            <>
              Click <Icon type="outbox" size="small" color="black" style={{verticalAlign: 'bottom'}} /> on a submarket
              tab now to share and receive circulars.
            </>
          ) : (
            <>Ask a company admin to activate Easy Sharing to see this circular.</>
          )}
        </Text>
      </div>
    </EasySharingPromotionContainer>
  );
};

const EasySharingPromotionContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  width: 420px;
  padding: 5px;
  background: var(--color-orange-bg);
  line-height: 1.125;
  color: var(--color-black);
  border-radius: var(--border-radius-tab);
  opacity: 0.8;
  z-index: 3;
`;

const Headline = styled.div`
  font-size: var(--font-size);
  font-weight: bold;
`;
const Text = styled.div`
  font-size: var(--font-size-sm);
`;

const EasySharingIcon = styled(Icon)`
  color: var(--color-orange);
  font-size: 35px !important;
  line-height: 0.9;
`;
