/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Role {
  Admin = 'ROLE_ADMIN',
  CompanyManager = 'ROLE_COMPANY_MANAGER',
  Staff = 'ROLE_STAFF',
  SeaboInternalService = 'ROLE_SEABO_INTERNAL_SERVICE',
  User = 'ROLE_USER',
  NextLogisticsVenturesVip = 'ROLE_NEXT_LOGISTICS_VENTURES_VIP',
}

export function RoleFromJSON(json: any): Role {
  return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
  return json as Role;
}

export function RoleToJSON(value?: Role | null): any {
  return value as any;
}
