/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationParty,
  NegotiationPartyFromJSON,
  NegotiationPartyFromJSONTyped,
  NegotiationPartyToJSON,
  NegotiationSubject,
  NegotiationSubjectFromJSON,
  NegotiationSubjectFromJSONTyped,
  NegotiationSubjectToJSON,
  NegotiationTermStatus,
  NegotiationTermStatusFromJSON,
  NegotiationTermStatusFromJSONTyped,
  NegotiationTermStatusToJSON,
  NegotiationTermType,
  NegotiationTermTypeFromJSON,
  NegotiationTermTypeFromJSONTyped,
  NegotiationTermTypeToJSON,
  NegotiationTermWasAddedIn,
  NegotiationTermWasAddedInFromJSON,
  NegotiationTermWasAddedInFromJSONTyped,
  NegotiationTermWasAddedInToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationTerm
 */
export interface NegotiationTerm {
  /**
   *
   * @type {string}
   * @memberof NegotiationTerm
   */
  id: string;
  /**
   * ID of the root term. It doesn't have to be created in the first round, but it is the first term in the chain.
   * @type {string}
   * @memberof NegotiationTerm
   */
  originId: string | null;
  /**
   *
   * @type {number}
   * @memberof NegotiationTerm
   */
  sortIndex: number;
  /**
   *
   * @type {string}
   * @memberof NegotiationTerm
   */
  text: string;
  /**
   *
   * @type {Array<NegotiationSubject>}
   * @memberof NegotiationTerm
   */
  subjects: Array<NegotiationSubject>;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationTerm
   */
  lastEditedBy: NegotiationParty;
  /**
   *
   * @type {NegotiationTermStatus}
   * @memberof NegotiationTerm
   */
  status: NegotiationTermStatus;
  /**
   *
   * @type {NegotiationTermType}
   * @memberof NegotiationTerm
   */
  type: NegotiationTermType | null;
  /**
   * The date and time when the term was last modified. This includes updates to the text, status changes, or creation.
   * @type {Date}
   * @memberof NegotiationTerm
   */
  lastModifiedAt: Date;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationTerm
   */
  lastModifiedBy: NegotiationParty;
  /**
   *
   * @type {NegotiationTermWasAddedIn}
   * @memberof NegotiationTerm
   */
  wasAddedIn: NegotiationTermWasAddedIn;
}

export function NegotiationTermFromJSON(json: any): NegotiationTerm {
  return NegotiationTermFromJSONTyped(json, false);
}

export function NegotiationTermFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationTerm {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    originId: json['originId'],
    sortIndex: json['sortIndex'],
    text: json['text'],
    subjects: (json['subjects'] as Array<any>).map(NegotiationSubjectFromJSON),
    lastEditedBy: NegotiationPartyFromJSON(json['lastEditedBy']),
    status: NegotiationTermStatusFromJSON(json['status']),
    type: NegotiationTermTypeFromJSON(json['type']),
    lastModifiedAt: new Date(json['lastModifiedAt']),
    lastModifiedBy: NegotiationPartyFromJSON(json['lastModifiedBy']),
    wasAddedIn: NegotiationTermWasAddedInFromJSON(json['wasAddedIn']),
  };
}

export function NegotiationTermToJSON(value?: NegotiationTerm | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    originId: value.originId,
    sortIndex: value.sortIndex,
    text: value.text,
    subjects: (value.subjects as Array<any>).map(NegotiationSubjectToJSON),
    lastEditedBy: NegotiationPartyToJSON(value.lastEditedBy),
    status: NegotiationTermStatusToJSON(value.status),
    type: NegotiationTermTypeToJSON(value.type),
    lastModifiedAt: value.lastModifiedAt.toISOString(),
    lastModifiedBy: NegotiationPartyToJSON(value.lastModifiedBy),
    wasAddedIn: NegotiationTermWasAddedInToJSON(value.wasAddedIn),
  };
}
