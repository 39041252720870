import {FC} from 'react';
import {MarketVesselOffer} from '../../MarketVessel/MarketVesselOffer';
import {VesselForm} from './VesselForm/VesselForm';
import {CargoForm} from './CargoForm/CargoForm';
import {MarketCargoOffer} from '../../MarketCargo/MarketCargoOffer';

export type Offer = MarketVesselOffer | MarketCargoOffer;

export const EditCircularForm: FC<{offer: Offer; onSuccess: () => void}> = ({offer, onSuccess}) => {
  const isVesselOffer = 'vessel' in offer;
  return isVesselOffer ? (
    <VesselForm onSuccess={onSuccess} vesselOffer={offer} />
  ) : (
    <CargoForm onSuccess={onSuccess} cargoOffer={offer} />
  );
};
