import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import {cargoGrid} from '../../../../components/GridLayout';
import {ColumnHeader} from '../../../../components/DataGrid/ColumnHeader/ColumnHeader';
import {PostedBy} from './PostedBy';
import {MarketCargoGridActionMenu} from './MarketCargoGridActionMenu';

export const getCargoColumns = ({
  isSub,
  onGridDataChange,
}: {
  isSub: boolean;
  onGridDataChange: () => void;
}): CustomColumnDef<$TSFixMe>[] => [
  ...cargoGrid('market'),
  {
    header: () => (
      <ColumnHeader sortable data-cy="header-posted">
        Posted
      </ColumnHeader>
    ),
    id: 'createdAt',
    enableSorting: true,
    minWidth: 8,
    accessorFn: (d: $TSFixMe) => <PostedBy circular={d} />,
    cell: info => info.getValue(),
  },
  {
    header: '',
    id: 'cargoDatagridActions',
    enableSorting: false,
    maxWidth: 48,
    className: 'datagrid-td-action',
    accessorFn: (offer: $TSFixMe) => (
      <MarketCargoGridActionMenu offer={offer} isSub={isSub} onGridDataChange={onGridDataChange} />
    ),
    cell: info => info.getValue(),
  },
];
