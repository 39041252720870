import {LexicalNode, DecoratorNode, NodeKey, EditorConfig, LexicalEditor} from 'lexical';
import {NegotiationTermType} from '../../../../../../api/node-backend/generated';
import {InlineTermTypeSelect} from '../../../../NegotiationDetail/Components/TermItem/Components/TermType/TermTypeSelect';
import {ReactNode} from 'react';

export type SerializedTermTypeNode = {
  termType: NegotiationTermType | null;
  type: 'termType';
  version: 1;
};

/**
 * A Lexical node that renders an <InlineTermTypeSelect /> in the editor
 */
export class TermTypeNode extends DecoratorNode<ReactNode> {
  __termType: NegotiationTermType | null;
  __onTermTypeChangeExternal: (type: NegotiationTermType | null) => void;

  static getType(): string {
    return 'termType';
  }

  static clone(node: TermTypeNode): TermTypeNode {
    return new TermTypeNode(node.__termType, node.__onTermTypeChangeExternal, node.__key);
  }

  static importJSON(serializedNode: SerializedTermTypeNode): TermTypeNode {
    const node = $createTermTypeNode(serializedNode.termType, () => {});
    return node;
  }

  constructor(
    termType: NegotiationTermType | null,
    onTermTypeChange: (type: NegotiationTermType | null) => void,
    key?: NodeKey
  ) {
    super(key);
    this.__termType = termType;
    this.__onTermTypeChangeExternal = onTermTypeChange;
  }

  decorate(editor: LexicalEditor): ReactNode {
    // We need to reflect state changes in this class as well because LexicalEditor does not re-render nodes when props change
    const onTermTypeChange = (termType: NegotiationTermType | null) => {
      this.__onTermTypeChangeExternal(termType);
      editor.update(() => {
        const writable = this.getWritable();
        writable.__termType = termType;
      });
    };

    return <InlineTermTypeSelect termType={this.__termType} onTermTypeChange={onTermTypeChange} />;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(_prevNode: unknown, _dom: HTMLElement, _config: EditorConfig): boolean {
    return true;
  }

  exportJSON(): SerializedTermTypeNode {
    return {
      termType: this.__termType,
      type: 'termType',
      version: 1,
    };
  }

  // prevent deletion
  remove(): void {}

  // prevent replacement
  replace<N extends LexicalNode>(replaceWith: N): N {
    if ($isTermTypeNode(replaceWith)) {
      return replaceWith;
    }
    return this as unknown as N;
  }

  isInline(): boolean {
    return true;
  }

  isIsolated(): boolean {
    return true;
  }

  isKeyboardSelectable(): boolean {
    return false;
  }
}

export function $createTermTypeNode(
  termType: NegotiationTermType | null,
  onTermTypeChange: (termType: NegotiationTermType | null) => void
): TermTypeNode {
  const termTypeNode = new TermTypeNode(termType, onTermTypeChange);
  return termTypeNode;
}

export function $isTermTypeNode(node: LexicalNode | null | undefined): node is TermTypeNode {
  return node instanceof TermTypeNode;
}
