import React, {FC, useState} from 'react';
import {AutoComplete, Input} from 'antd';
import styled from 'styled-components';
import {Location, LocationOption} from '../SearchPicker/Type';
import {useOptionsFromLocationSearch} from '../SearchPicker/useOptionsFromLocationSearch';
import {SearchHit} from '../../api/symfony/generated/models';
import {SizeType} from 'antd/es/config-provider/SizeContext';

interface LocationPickerProps {
  defaultValue?: string;
  autoFocus?: boolean;
  inputSize?: SizeType;
  optionsFilter?: (locationItem: SearchHit) => boolean;
  onSelect: (location: Location) => void;
  onChangeText?: (text: string) => void;
}

const StyledAutoComplete = styled<typeof AutoComplete>(AutoComplete)`
  width: 100%;
`;

export const LocationPicker: FC<LocationPickerProps> = ({
  defaultValue,
  autoFocus,
  inputSize = 'middle',
  optionsFilter,
  onSelect,
  onChangeText,
}) => {
  const [searchTag, setSearchTag] = useState('');

  const options = useOptionsFromLocationSearch({searchTag, filter: optionsFilter});

  const notFoundContent =
    !options.searchQuery.isLoading && options.options.length === 0 && searchTag.length > 1
      ? 'No location found.'
      : undefined;

  return (
    <StyledAutoComplete
      size={inputSize}
      notFoundContent={notFoundContent}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      onSelect={(_: unknown, item: unknown) => {
        onSelect((item as LocationOption).source);
        if (onChangeText) {
          onChangeText((item as LocationOption).value);
        }
      }}
      options={options.options}>
      <Input.Search
        data-testid="locationPickerInputField"
        size={inputSize}
        onChange={event => {
          event.stopPropagation();
          const newValue = event.currentTarget.value.trim();
          setSearchTag(newValue);
          if (onChangeText) {
            onChangeText(newValue);
          }
        }}
        placeholder="Search for a port, country, or trading area"
      />
    </StyledAutoComplete>
  );
};
