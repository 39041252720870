import diff, {DiffEdit} from 'deep-diff';
import {PortfolioVessel} from '../../../../../redux/Portfolio';

export const getDiffsFromTwoVessels = ({
  oldVesselWithoutNull,
  newVesselWithoutNull,
}: {
  oldVesselWithoutNull: PortfolioVessel;
  newVesselWithoutNull: PortfolioVessel;
}): diff.DiffEdit<PortfolioVessel, PortfolioVessel>[] => {
  const diffs = diff
    .diff<PortfolioVessel, PortfolioVessel>(oldVesselWithoutNull, newVesselWithoutNull)
    ?.filter(diff => diff.kind === 'E') as DiffEdit<PortfolioVessel, PortfolioVessel>[];

  return diffs;
};
