import {Button, Form, Input, Modal} from 'antd';
import {FC, useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {Annotation, NewAnnotationRequest} from '../../../../api/annotations/generated';
import {assert} from '../../../../utils/assert';
import {showNotification} from '../../../../utils/notification';
import {invalidateAnnotationQuery} from './useAnnotationQuery';
import {useCreateAnnotationMutation} from './useCreateAnnotationMutation';
import {useRemoveAnnotationMutation} from './useRemoveAnnotationMutation';
import {useUpdateAnnotationMutation} from './useUpdateAnnotationMutation';
import {useUser} from '../../../../components/UserContext/useUser';

export const AddOrUpdateAnnotationModal: FC<{
  visible: boolean;
  imo: number;
  annotation: Annotation | null;
  isNew: boolean;
  onCancel: () => void;
}> = ({visible, annotation, isNew, imo, onCancel}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(annotation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotation]);

  const queryClient = useQueryClient();
  const user = useUser();
  const userId = user.id;
  const afterSave = () => {
    onCancel();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    invalidateAnnotationQuery(queryClient);
  };

  const createMutation = useCreateAnnotationMutation({
    onSuccess: () => {
      showNotification('Annotation was added.');

      afterSave();
    },
  });
  const updateMutation = useUpdateAnnotationMutation({
    onSuccess: () => {
      afterSave();
      showNotification('Annotation was updated.');
    },
  });
  const removeMutation = useRemoveAnnotationMutation({
    onSuccess: () => {
      afterSave();
      showNotification('Annotation was deleted.');
    },
  });

  const footer = [
    <Button onClick={onCancel}>Close</Button>,
    <Button type="primary" onClick={form.submit}>
      Save
    </Button>,
  ];
  if (!isNew) {
    footer.unshift(
      <Button
        danger={true}
        onClick={() =>
          removeMutation.mutate({
            annotationId: annotation!.annotationId!,
          })
        }>
        Remove
      </Button>
    );
  }

  if (!visible) {
    return null;
  }

  return (
    <Modal
      data-cy={'AddOrUpdateAnnotationModal'}
      destroyOnClose
      footer={footer}
      open={visible}
      title={isNew ? 'Add annotation' : 'Update annotation'}
      onCancel={onCancel}>
      <Form<NewAnnotationRequest>
        data-cy={'AddOrUpdateAnnotationForm'}
        onFinish={values => {
          const newAnnotation = {
            ...values,
            imo,
            userId: userId!,
          };
          if (isNew) {
            createMutation.mutate(newAnnotation);
          } else {
            assert(annotation);
            updateMutation.mutate({
              annotationId: annotation.annotationId!,
              newAnnotationRequest: {
                ...values,
                imo,
                userId: userId!,
              },
            });
          }
        }}
        form={form}>
        <Form.Item rules={[{required: true, message: 'You need to add an annotation.'}]} name={'text'}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
