/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {CurrentUser, CurrentUserFromJSON, CurrentUserToJSON} from '../models';

/**
 *
 */
export class SessionApi extends runtime.BaseAPI {
  /**
   * Fetch details about the currently authenticated user.
   */
  async getCurrentUserRaw(): Promise<runtime.ApiResponse<CurrentUser>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/me`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CurrentUserFromJSON(jsonValue));
  }

  /**
   * Fetch details about the currently authenticated user.
   */
  async getCurrentUser(): Promise<CurrentUser> {
    const response = await this.getCurrentUserRaw();
    return await response.value();
  }

  /**
   * Ping the session.
   */
  async pingRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/me/keepalive`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Ping the session.
   */
  async ping(): Promise<void> {
    await this.pingRaw();
  }
}
