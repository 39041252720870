import {CSSProperties, ChangeEventHandler, FC} from 'react';
import styled from 'styled-components';

const charactersLeft = (text: $TSFixMe, limit: $TSFixMe) => {
  if (text) {
    const char = text.length;
    return limit - char + ' / ' + limit + ' characters remaining';
  } else {
    return limit + ' / ' + limit + ' characters remaining';
  }
};

type Props = {
  id?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: () => void;
  noBorder?: boolean;
  noResize?: boolean;
  rows?: number;
  style?: CSSProperties;
  maxLength?: number;
  placeholder?: string;
  value?: string;
  color?: 'white';
  large?: boolean;
  className?: string;
  limit?: number;
};

/** @deprecated use AntD Input.TextArea instead */
export const TextArea: FC<Props> = ({
  id,
  onChange = () => {},
  onBlur = () => {},
  noBorder,
  noResize,
  rows,
  placeholder,
  value,
  color,
  className,
  large = true,
  limit,
  ...props
}) => (
  <TextAreaWrapper className={className}>
    <StyledTextArea
      theme={{color, noBorder, noResize, large}}
      id={id}
      {...props}
      onChange={onChange}
      onBlur={onBlur}
      rows={rows}
      placeholder={placeholder}
      value={value}
      maxLength={limit && limit}
    />
    {limit && <TextAreaLimit>{charactersLeft(value, limit)}</TextAreaLimit>}
  </TextAreaWrapper>
);

const TextAreaWrapper = styled.div`
  position: relative;
`;

const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  background-color: ${props => (props.theme.color === 'white' ? 'var(--color-white)' : 'var(--color-gray-6)')};
  ${props =>
    props.theme.noBorder
      ? 'border: 0; border-radius: 0'
      : 'border: var(--border-base); border-radius: var(--border-radius-button); box-shadow: var(--box-shadow-inset-input);'};
  font-size: ${props => (props.theme.large ? '16px' : '13px')};
  padding: 9px 12px;
  appearance: none;
  color: var(--color-gray-1);
  font-weight: 400;
  outline: none;
  margin: 0;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  height: calc(100% - 32px);
  min-height: 36px;
  resize: ${props => props.theme.noResize && 'none'};
  &:focus {
    border-color: var(--color-blue);
  }
  &::placeholder {
    height: 24px;
    width: 116px;
    font-size: var(--font-size-lg);
    line-height: 24px;
    white-space: nowrap;
    color: var(--color-placeholder);
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: var(--color-placeholder);
  }

  &::-ms-input-placeholder {
    color: var(--color-placeholder);
  }
`;

const TextAreaLimit = styled.span`
  width: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 22px;
  padding-bottom: 5px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-2);
`;
