import React, {FC} from 'react';
import {Form, Input, Alert} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {Store} from 'antd/es/form/interface';
import {PasswordRecoveryRequest} from '../../../api/symfony/generated/models/PasswordRecoveryRequest';
import {Asterisk, CtaButton, InputLabel, Instruction, Layout} from '../utils/shared-components';

export const LostPasswordForm: FC<{
  submitting: boolean;
  errorMessage?: string;
  onChange: () => void;
  onSubmit: (formValues: PasswordRecoveryRequest) => void;
}> = ({submitting, errorMessage, onChange, onSubmit}) => {
  const onFinish = (values: Store) => {
    onSubmit(values as PasswordRecoveryRequest);
  };

  return (
    <Layout>
      <Instruction>
        No worries. <br />
        We will email you a link to reset your password.
      </Instruction>

      <Form
        name="lost-password"
        data-testid="LostPasswordForm"
        initialValues={{
          email: '',
          password: '',
          rememberMe: true,
        }}
        onValuesChange={onChange}
        onFinish={onFinish}>
        <InputLabel>
          <span>
            Your account email<Asterisk>*</Asterisk>
          </span>
        </InputLabel>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid Email.',
            },
            {
              required: true,
              message: 'Please input your Email.',
            },
          ]}>
          <Input prefix={<UserOutlined />} autoComplete="email" placeholder="Email" data-testid="email" />
        </Form.Item>

        {errorMessage && (
          <>
            <Alert message={errorMessage} type="error" showIcon data-testid="ErrorMessage" />
            <br />
          </>
        )}

        <Form.Item>
          <CtaButton
            type="primary"
            htmlType="submit"
            size="large"
            disabled={submitting}
            loading={submitting}
            data-testid="Submit">
            Submit
          </CtaButton>
        </Form.Item>
      </Form>
    </Layout>
  );
};
