import {ApiError} from '../../api/utils/ApiError';
import {File} from '../../components/ExportReportsModal/File';
import {getRecommendedFilename} from '../../components/ExportReportsModal/getRecommendedFilename';
import {TODO} from '../../utils/TODO';

export const getFixtureReports = async ({
  fixtureFilters,
  workspaceId,
  reportList,
}: {
  fixtureFilters: TODO;
  reportList: string[];
  workspaceId: number;
}): Promise<File> => {
  const exportType = reportList.join(',');
  const downloadUrl =
    `/api/workspaces/${workspaceId}/vessel-fixtures/export?` +
    (fixtureFilters ? `export-type=${exportType}&filters=${JSON.stringify(fixtureFilters)}` : '');
  const response = await fetch(downloadUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.status !== 200) {
    throw new ApiError(response.statusText, response.status);
  }
  const blob = await response.blob();
  const filename = getRecommendedFilename(response) ?? 'Report.xlsx';
  const file: File = {blob, filename};
  return file;
};
