/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VesselLoaBeam, VesselLoaBeamFromJSON, VesselLoaBeamFromJSONTyped, VesselLoaBeamToJSON} from './';

/**
 *
 * @export
 * @interface LoaBeamReport
 */
export interface LoaBeamReport {
  /**
   *
   * @type {Array<VesselLoaBeam>}
   * @memberof LoaBeamReport
   */
  vessels: Array<VesselLoaBeam>;
  /**
   *
   * @type {number}
   * @memberof LoaBeamReport
   */
  minLoa: number;
  /**
   *
   * @type {number}
   * @memberof LoaBeamReport
   */
  maxLoa: number;
  /**
   *
   * @type {number}
   * @memberof LoaBeamReport
   */
  minBeam: number;
  /**
   *
   * @type {number}
   * @memberof LoaBeamReport
   */
  maxBeam: number;
  /**
   *
   * @type {number}
   * @memberof LoaBeamReport
   */
  vesselsWithoutValue: number;
}

export function LoaBeamReportFromJSON(json: any): LoaBeamReport {
  return LoaBeamReportFromJSONTyped(json, false);
}

export function LoaBeamReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoaBeamReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vessels: (json['vessels'] as Array<any>).map(VesselLoaBeamFromJSON),
    minLoa: json['minLoa'],
    maxLoa: json['maxLoa'],
    minBeam: json['minBeam'],
    maxBeam: json['maxBeam'],
    vesselsWithoutValue: json['vesselsWithoutValue'],
  };
}

export function LoaBeamReportToJSON(value?: LoaBeamReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vessels: (value.vessels as Array<any>).map(VesselLoaBeamToJSON),
    minLoa: value.minLoa,
    maxLoa: value.maxLoa,
    minBeam: value.minBeam,
    maxBeam: value.maxBeam,
    vesselsWithoutValue: value.vesselsWithoutValue,
  };
}
