import {ApiError} from './ApiError';
import {loadResponseJson} from './loadResponseJson';

export const fetchJSON = async <ReturnValue>(url: string, init?: RequestInit): Promise<ReturnValue> => {
  const response = await fetch(url, init);
  if (!response.ok) {
    throw new ApiError(response.statusText, response.status);
  }

  const json: ReturnValue = await loadResponseJson(response, init?.method ?? 'GET', url);
  return json;
};
