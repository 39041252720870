import {Intake, IntakeTypeEnum} from '../../../api/node-backend/generated';

export const getTeuQuantity = (intakes?: Array<Intake>): number | undefined => {
  const teu = intakes?.find(intake => intake.type === IntakeTypeEnum.Teu);
  if (teu) {
    return teu.quantity;
  }

  const teu14 = intakes?.find(intake => intake.type === IntakeTypeEnum.Teu14);

  return teu14?.quantity;
};
