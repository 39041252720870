import React, {FC} from 'react';
import styled from 'styled-components';

export const LegendBox: FC<{title: string; listItems: React.ReactElement[]}> = ({title, listItems}) => {
  return (
    <StyledBox>
      <StyledTitle>{title}</StyledTitle>
      <StyledList>
        {listItems.map((item, idx: number) => {
          return (
            <StyledListItem className="legend-box__item" key={idx}>
              {item}
            </StyledListItem>
          );
        })}
      </StyledList>
    </StyledBox>
  );
};

const StyledBox = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledTitle = styled.h3`
  margin-bottom: 0.25em;
  color: var(--color-white);
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0px;
`;
const StyledListItem = styled.li`
  margin-bottom: 0.125rem;
`;
