import dayjs, {Dayjs} from 'dayjs';

interface Filter {
  toDate?: Dayjs;
}

export const vesselEtaIsAfterLaycanFilter = <
  Vessel extends {eta?: Date | null; estimatedDurationInEtaPortInDays?: number},
>({
  vessels,
  filter,
}: {
  filter: Filter;
  vessels: Vessel[];
}) => {
  return vessels.filter(vessel => {
    if (vessel.eta) {
      const etaAndPortWorkDuration = dayjs(vessel.eta).add(vessel.estimatedDurationInEtaPortInDays ?? 0, 'day');
      return etaAndPortWorkDuration.toISOString() <= filter.toDate!.toISOString();
    }

    return true;
  });
};
