import {FC, useEffect, useMemo, useRef, useState} from 'react';
import useViewportState from 'beautiful-react-hooks/useViewportState';
import styled, {css} from 'styled-components';
import debounce from 'lodash/debounce';

export const DataGridStickyHeaderWrapper: FC<{
  children: React.ReactNode;
  enableStickyHeader: boolean;
}> = ({children, enableStickyHeader}) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const {scrollY} = useViewportState();
  const [isSticky, setIsSticky] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [widthForFixedHeader, setWidthForFixedHeader] = useState(0);
  const headerRect = useMemo(() => {
    return document.querySelector('#full-page-header')?.getBoundingClientRect();
  }, []);
  const windowHeaderHeight = headerRect?.height ?? 0;
  useEffect(() => {
    checkForStickyDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  const checkForStickyDebounce = debounce(() => {
    if (!headerRef.current) {
      return;
    }

    const rect = headerRef.current.getBoundingClientRect();
    const rectParent = headerRef.current.parentElement?.getBoundingClientRect();
    if (!rectParent) {
      return;
    }
    if (!rect) {
      return;
    }

    calculateAndSetWidthForFixedHeader(rectParent);

    const showingStatus = calculateShowingStatus({
      headerDomRect: rect,
      parentDomRect: rectParent,
    });

    switch (showingStatus) {
      case 'inactive':
        setIsHidden(false);
        setIsSticky(false);
        break;
      case 'hide':
        setIsHidden(true);
        break;
      case 'shown':
        setIsHidden(false);
        setIsSticky(true);
        break;
    }
  }, 50);

  const calculateShowingStatus = ({
    parentDomRect,
    headerDomRect,
  }: {
    headerDomRect: DOMRect;
    parentDomRect: DOMRect;
  }): 'inactive' | 'shown' | 'hide' => {
    // if the view is back to the top
    if (parentDomRect.top > windowHeaderHeight) {
      return 'inactive';
    }
    // if the view is to much down

    const hiddenByHeaderOrWindowHeader = windowHeaderHeight + headerDomRect.height;
    const visibleFromHeader = parentDomRect.top + parentDomRect.height - hiddenByHeaderOrWindowHeader;
    if (visibleFromHeader < 0) {
      return 'hide';
    }

    return 'shown';
  };

  const calculateAndSetWidthForFixedHeader = (parentDomRect: DOMRect) => {
    setWidthForFixedHeader(parentDomRect.width);
  };
  return (
    <Header
      $isHidden={isHidden}
      $isSticky={isSticky && enableStickyHeader}
      $widthForFixedHeader={widthForFixedHeader}
      $windowHeaderHeight={windowHeaderHeight}
      ref={headerRef}
      className="rt-thead -header">
      {children}
    </Header>
  );
};

const Header = styled.div<{
  $isSticky: boolean;
  $widthForFixedHeader?: number;
  $windowHeaderHeight: number;
  $isHidden: boolean;
}>`
  ${({$isSticky, $widthForFixedHeader, $isHidden, $windowHeaderHeight}) => {
    if ($isSticky) {
      return css`
        top: ${$windowHeaderHeight}px;
        position: fixed;
        background-color: white;
        z-index: 100;
        width: ${$widthForFixedHeader}px;

        ${$isHidden &&
        css`
          visibility: hidden;
        `}
      `;
    }
  }}
`;
