/* eslint-disable no-nested-ternary */
import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import LabelWrapper from '../../../atoms/LabelWrapper';
import Input from '../../../atoms/Input';
import HorizontalLine from '../../../atoms/HorizontalLine';
import Checkbox from '../../../atoms/Checkbox';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import {acceptFloatOrEmpty, acceptIntOrEmpty} from '../../../utils/formatter';
import {
  alwaysValid,
  floatBetween,
  numberBetween,
  numberSmallerThan,
  stringLengthLessThanOrEqualTo,
} from '../../../utils/validators';
import {
  cargoFeatures,
  cargoFeatureSet,
  designSubTypes,
  gearFromApiToFrontend,
  gearFromFrontendToApi,
  gearRequiredTypes,
  gearTypes,
  iceClasses,
  newGear,
  vesselFeatureUnits,
  vesselTypes,
} from '../helper';
import IconButton from '../../../atoms/IconButton';
import {Button} from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon';
import autocompleteApi from '../../../redux/ApiService/autocomplete';
import {FeatureSection} from '../CargoVesselForm.styled';
import {RootState} from '../../../redux/store';
import Select from '../../../atoms/Select/Select';
import {AsyncCreateable} from '../../../atoms/Select/AsyncCreateable';

// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const unitOptions = Object.keys(vesselFeatureUnits).map(value => ({value, name: vesselFeatureUnits[value]}));

// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const requiredGearOptions = Object.keys(gearRequiredTypes).map(value => ({value, name: gearRequiredTypes[value]}));

const quantityOptions = ' '
  .repeat(20)
  .split('')
  .map((i, k) => ({value: k + 1, name: k + 1 + 'x'}));

// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const gearOptions = Object.keys(gearTypes).map(value => ({value, name: gearTypes[value]}));

const Wrapper = BindToProvider(
  'Vessel',
  'vesselFeatures',
  {
    gears: [],
    gear: Object.keys(gearRequiredTypes)[1],
    vesselSizeMin: '',
    vesselSizeUnit: '',
    vesselSizeMax: '',
    vesselType: '',
    vesselSubtype: '',
    maxVesselAge: '',
    reeferPlugs: '',
    iceClass: '',
    ...Object.keys(cargoFeatureSet).reduce((values, field) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      values[field] = false;
      return values;
    }, {}),
  },
  {
    vesselSizeMin: numberSmallerThan('vesselSizeMax'),
    vesselSubtype: stringLengthLessThanOrEqualTo(127),
    gears: alwaysValid,
    maxVesselAge: numberBetween(0, 99),
    reeferPlugs: numberBetween(0, 99999),
    row: {
      capacity: floatBetween(1, 9999),
    },
  },
  {
    toApi: (fields: $TSFixMe, sections: $TSFixMe) => {
      const gear = gearFromFrontendToApi(fields.gear);

      return {
        ...fields,
        vesselSubtype: typeof fields.vesselSubtype === 'object' ? fields.vesselSubtype.label : fields.vesselSubtype,
        maxVesselAge:
          sections.contractType.contractType !== 'vc'
            ? parseInt(fields.maxVesselAge)
            : parseInt(sections.charterer.maxVesselAgeVc),
        reeferPlugs: parseInt(fields.reeferPlugs),
        vesselSizeMin: fields.vesselSizeMin,
        gear,

        vesselSizeMax: fields.vesselSizeMax,
        gears: fields.gears
          ? fields.gears.map((gear: $TSFixMe) => ({
              ...gear,
              capacity: parseFloat(gear.capacity),
            }))
          : [],
      };
    },
    fromApi: (fields: $TSFixMe) => {
      const gear = gearFromApiToFrontend(fields.gear);

      return {
        ...fields,
        vesselSubtype:
          fields.vesselType === 'container'
            ? fields.vesselSubtype
              ? {label: fields.vesselSubtype, value: fields.vesselSubtype}
              : '' // if type container but no subType
            : fields.vesselSubtype || '', // if not container then its null or string
        gears: fields.gears
          ? fields.gears.map((gear: $TSFixMe) => ({
              ...newGear,

              ...Object.keys(gear).reduce((fields, field) => {
                if (gear[field] || gear[field] === 0) {
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  fields[field] = gear[field];
                }
                return fields;
              }, {}),
            }))
          : [],
        gear,
        vesselType: fields.vesselType ? fields.vesselType.toLowerCase() : '',
      };
    },
  },
  true
)(WrappedDetailsSection);

const GearRows = (props: $TSFixMe) => {
  const {validation, validateRow, gears = [], vesselType, cargoType} = props;

  const onRemove = (gears: $TSFixMe, i: $TSFixMe) => {
    props.onChange(gears.filter((v: $TSFixMe, idx: $TSFixMe) => idx !== i));
  };

  const onRowChange = (field: $TSFixMe, value: $TSFixMe, i: $TSFixMe) => {
    props.onChange([
      ...props.gears.slice(0, i),
      {
        ...props.gears[i],
        [field]: value,
      },
      ...props.gears.slice(i + 1),
    ]);
  };

  return gears.map((values: $TSFixMe, i: $TSFixMe) => (
    <div className="row" key={i}>
      {cargoType === 'drybulk' && (
        <div className="scol-12 scol-sm-4 scol-lg-3">
          <LabelWrapper
            label={vesselType === 'container' ? 'Gear' : 'Gear / Grabs'}
            htmlFor={`form-features-type-${i}`}>
            <Select
              id={`form-features-type-${i}`}
              name={`form-features-type-${i}`}
              options={vesselType === 'container' ? [{value: 'gear', name: gearTypes['gear']}] : gearOptions}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value={{value: values.type, name: gearTypes[values.type]}}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              defaultValue={{value: values.type, name: gearTypes[values.type]}}
              onChange={(o: $TSFixMe) => onRowChange('type', o.value, i)}
              getOptionLabel={(o: $TSFixMe) => o.name}
              getOptionValue={(o: $TSFixMe) => o.value}
              components={{DropdownIndicator: ArrowIndicator}}
              isSearchable={false}
            />
          </LabelWrapper>
        </div>
      )}
      <div className="scol-12 scol-sm-2">
        <LabelWrapper label={'Amount'} htmlFor={`form-features-quantity-${i}`}>
          <Select
            id={`form-features-quantity-${i}`}
            name={`form-features-quantity-${i}`}
            options={quantityOptions}
            value={{value: values.quantity, name: `${values.quantity}x`}}
            defaultValue={{value: values.quantity, name: `${values.quantity}x`}}
            onChange={(o: $TSFixMe) => onRowChange('quantity', o.value, i)}
            getOptionLabel={(o: $TSFixMe) => o.name}
            getOptionValue={(o: $TSFixMe) => o.value}
            components={{DropdownIndicator: ArrowIndicator}}
            isSearchable={false}
          />
        </LabelWrapper>
      </div>
      <div className="scol-12 scol-sm-2">
        <LabelWrapper
          label={'Capacity ' + (values.type === 'gear' ? '(mts)' : '(cbm)')}
          htmlFor={`form-features-capacity-${i}`}
          hasError={validation[i] && validation[i].capacity && validation[i].capacity.invalid}>
          <Input
            id={`form-features-capacity-${i}`}
            onChange={v => acceptFloatOrEmpty(v, v => onRowChange('capacity', v, i), 4, 2)}
            onKeyUp={() => validateRow('gears', 'capacity', i)}
            value={values.capacity}
            hasError={validation[i] && validation[i].capacity && validation[i].capacity.invalid}
          />
        </LabelWrapper>
        {validation[i] && validation[i].capacity && validation[i].capacity.error && (
          <p className="text-danger text-danger--no-margin">{validation[i].capacity.error}</p>
        )}
      </div>
      <div className="scol-12 scol-sm-3 scol-lg-4">
        <LabelWrapper label={'Type'} htmlFor={`form-features-description-${i}`}>
          <Input
            id={`form-features-description-${i}`}
            onChange={value => onRowChange('description', value, i)}
            value={values.description}
            maxLength={255}
          />
        </LabelWrapper>
      </div>
      <div className="scol-12 scol-sm-1">
        <div className="cargo-vessel-form__v-centered-field">
          <IconButton
            id={`form-features-gear-remove-${i}`}
            type={'scrap'}
            iconStyle={{cursor: 'pointer'}}
            onClick={() => onRemove(gears, i)}
            title={'Remove'}
          />
        </div>
      </div>
      <HorizontalLine large topSpace={'small'} />
    </div>
  ));
};

const VesselFeatures = (props: $TSFixMe) => {
  const {form, isEdit, onChange, loadDesignTypeOptions, preFillSearch} = props;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const iceClassOptions = Object.keys(iceClasses).map(value => ({value, name: iceClasses[value]}));

  const handleGearTypeChange = (value: $TSFixMe) => {
    props.onChange('vesselFeatures', {
      ...props.form.vesselFeatures,
      gear: value,
      gears: value === 'required' ? [newGear] : [],
    });
  };

  const handleVesselTypeChange = (value: $TSFixMe) => {
    props.onChange('vesselFeatures', {
      ...props.form.vesselFeatures,
      vesselType: value,
      cellular: value === 'container' || false,
    });
  };

  const cargoType = form.marketSegment && form.marketSegment.cargoType;
  const contractType = form.contractType && form.contractType.contractType && form.contractType.contractType;

  useEffect(() => {
    preFillSearch('design_type');
  }, [preFillSearch]);

  useEffect(
    () => {
      if (!isEdit && cargoType) {
        if (cargoType === 'drybulk') {
          onChange('vesselFeatures', {
            ...form.vesselFeatures,
            vesselType: 'bulker',
            vesselSizeUnit: 'dwt',
            cellular: false,
          });
        } else if (cargoType === 'container') {
          onChange('vesselFeatures', {
            ...form.vesselFeatures,
            vesselType: 'container',
            vesselSizeUnit: 'teu',
            cellular: contractType !== 'vc' || false,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEdit, cargoType, contractType, onChange]
  );

  return (
    <Wrapper {...props}>
      {({onChange, values, validations, validate, validateRow, form}: $TSFixMe) => (
        <div className={'container-fluid'}>
          <div className="row">
            <div className="scol-12 scol-sm-4">
              <LabelWrapper
                label={'Min size'}
                htmlFor={'form-vessel-size-min'}
                hasError={validations.vesselSizeMin.invalid}>
                <Input
                  id={'form-vessel-size-min'}
                  onChange={v => acceptIntOrEmpty(v, v => onChange('vesselSizeMin', v), 6)}
                  onKeyUp={() => validate('vesselSizeMin')}
                  value={values.vesselSizeMin}
                  hasError={validations.vesselSizeMin.invalid}
                />
              </LabelWrapper>
            </div>
            <div className="scol-12 scol-sm-4">
              <LabelWrapper
                label={'Max size'}
                htmlFor={'form-vessel-size-max'}
                hasError={validations.vesselSizeMin.invalid || validations.vesselSizeMax.invalid}>
                <Input
                  id={'form-vessel-size-max'}
                  onChange={v => acceptIntOrEmpty(v, v => onChange('vesselSizeMax', v), 6)}
                  onKeyUp={() => validate('vesselSizeMin')}
                  value={values.vesselSizeMax}
                  hasError={validations.vesselSizeMin.invalid || validations.vesselSizeMax.invalid}
                />
              </LabelWrapper>
            </div>
            <div className="scol-12 scol-sm-4">
              <LabelWrapper label={'Unit'} htmlFor={'form-vessel-size-unit'}>
                <Select
                  id={`form-vessel-size-unit`}
                  name={`form-vessel-size-unit`}
                  options={unitOptions}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  value={{value: values.vesselSizeUnit, name: vesselFeatureUnits[values.vesselSizeUnit]}}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  defaultValue={{value: values.vesselSizeUnit, name: vesselFeatureUnits[values.vesselSizeUnit]}}
                  onChange={(o: $TSFixMe) => onChange('vesselSizeUnit', o.value)}
                  getOptionLabel={(o: $TSFixMe) => o.name}
                  getOptionValue={(o: $TSFixMe) => o.value}
                  components={{DropdownIndicator: ArrowIndicator}}
                  isSearchable={false}
                />
              </LabelWrapper>
            </div>
            <div className="scol-12">
              {validations.vesselSizeMin.error && (
                <p className="text-danger text-danger--no-margin">{validations.vesselSizeMin.error}</p>
              )}
              {validations.vesselSizeMax.error && (
                <p className="text-danger text-danger--no-margin">{validations.vesselSizeMax.error}</p>
              )}
              {validations.vesselSizeUnit.error && (
                <p className="text-danger text-danger--no-margin">{validations.vesselSizeUnit.error}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="scol-12 scol-sm-6 scol-lg-4">
              <LabelWrapper label={'Vessel type'} htmlFor={'form-vessel-vessel-type'}>
                <Select
                  id={'form-vessel-vessel-type'}
                  name={'form-vessel-vessel-type'}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  value={{value: values.vesselType, name: vesselTypes[values.vesselType]}}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  defaultValue={{value: values.vesselType, name: vesselTypes[values.vesselType]}}
                  // @ts-expect-error ts-migrate(1345) FIXME: An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
                  onChange={(o: $TSFixMe) => handleVesselTypeChange(o.value) || ''}
                  options={Object.keys(vesselTypes)
                    .filter(value => {
                      if (form.marketSegment.cargoType === 'drybulk') {
                        return value !== 'container';
                      } else if (form.marketSegment.cargoType === 'container') {
                        return value !== 'bulker';
                      }
                      return null;
                    })
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    .map(value => ({value, name: vesselTypes[value]}))}
                  getOptionLabel={(option: $TSFixMe) => option.name}
                  getOptionValue={(option: $TSFixMe) => option.value}
                  components={{
                    DropdownIndicator: ArrowIndicator,
                  }}
                  isSearchable={false}
                />
              </LabelWrapper>
            </div>
            <div className="scol-12 scol-sm-6 scol-lg-4">
              {values.vesselType && values.vesselType === 'bulker' && (
                <LabelWrapper label={'Sub type'} htmlFor={'form-vessel-vessel-sub-type'}>
                  <Select
                    id={'form-vessel-vessel-sub-type'}
                    name={'form-vessel-vessel-sub-type'}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={{value: values.vesselSubtype, name: designSubTypes[values.vesselSubtype]}}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    defaultValue={{value: values.vesselSubtype, name: designSubTypes[values.vesselSubtype]}}
                    onChange={(o: $TSFixMe) => onChange('vesselSubtype', (o && o.value) || '')}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    options={Object.keys(designSubTypes).map(value => ({value, name: designSubTypes[value]}))}
                    getOptionLabel={(option: $TSFixMe) => option.name}
                    getOptionValue={(option: $TSFixMe) => option.value}
                    components={{
                      DropdownIndicator: ArrowIndicator,
                    }}
                    isSearchable={false}
                    isClearable
                  />
                </LabelWrapper>
              )}
              {values.vesselType && values.vesselType === 'container' && (
                <Fragment>
                  <LabelWrapper label={'Design type'} htmlFor={'form-vessel-sub-type-freetext'}>
                    <AsyncCreateable
                      id={`form-vessel-sub-type-freetext`}
                      name={`form-vessel-sub-type-freetext`}
                      value={values.vesselSubtype}
                      onChange={(value: $TSFixMe) => onChange('vesselSubtype', value)}
                      getOptionLabel={(option: $TSFixMe) => option.label}
                      getOptionValue={(option: $TSFixMe) => option.label}
                      loadOptions={(q: $TSFixMe) => (q.length > 1 ? loadDesignTypeOptions(q) : Promise.resolve([]))}
                      defaultOptions={
                        props.preFillData.map((p: $TSFixMe) => ({
                          value: p.slug,
                          label: p.name,
                        })) || []
                      }
                      isClearable
                      createLabel={(value: $TSFixMe) => value}
                      createOptionPosition={'first'}
                      placeholder={'Type to search...'}
                      loadingMessage={() => 'Searching ...'}
                    />
                  </LabelWrapper>
                  {validations.vesselSubtype.error && (
                    <p className="text-danger text-danger--no-margin">{validations.vesselSubtype.error}</p>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="scol-12 scol-sm-6 scol-lg-4">
              <LabelWrapper
                label={'Max vessel age (years)'}
                htmlFor={'form-vessel-vessel-age'}
                hasError={validations.maxVesselAge.invalid}>
                <Input
                  id={'form-vessel-vessel-age'}
                  onChange={v => acceptIntOrEmpty(v, v => onChange('maxVesselAge', v), 2)}
                  value={values.maxVesselAge}
                  maxLength={2}
                  hasError={validations.maxVesselAge.invalid}
                />
              </LabelWrapper>
              {validations.maxVesselAge.error && (
                <p className="text-danger text-danger--no-margin">{validations.maxVesselAge.error}</p>
              )}
            </div>
            {form.vesselFeatures.vesselType !== 'bulker' && (
              <div className="scol-12 scol-sm-6 scol-lg-4">
                <LabelWrapper
                  label={'Min reefer plugs'}
                  htmlFor={'form-vessel-reefer-plugs'}
                  hasError={validations.reeferPlugs.invalid}>
                  <Input
                    id={'form-vessel-reefer-plugs'}
                    onChange={v => acceptIntOrEmpty(v, v => onChange('reeferPlugs', v), 5)}
                    value={values.reeferPlugs}
                    hasError={validations.reeferPlugs.invalid}
                  />
                </LabelWrapper>
                {validations.reeferPlugs.error && (
                  <p className="text-danger text-danger--no-margin">{validations.reeferPlugs.error}</p>
                )}
              </div>
            )}
          </div>
          <HorizontalLine large />
          <div className="row">
            <div className="scol-12 scol-lg-4">
              <LabelWrapper
                label={values.vesselType === 'container' ? 'Gear' : 'Gear / Grabs'}
                htmlFor={'form-vessel-gear-select'}>
                <Select
                  id={'form-vessel-gear-select'}
                  name={'form-vessel-gear-select'}
                  options={requiredGearOptions}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  value={{value: values.gear, name: gearRequiredTypes[values.gear]}}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  defaultValue={{value: values.gear, name: gearRequiredTypes[values.gear]}}
                  // @ts-expect-error ts-migrate(1345) FIXME: An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
                  onChange={(o: $TSFixMe) => handleGearTypeChange(o.value) || ''}
                  getOptionLabel={(o: $TSFixMe) => o.name}
                  getOptionValue={(o: $TSFixMe) => o.value}
                  components={{DropdownIndicator: ArrowIndicator}}
                  isSearchable={false}
                />
              </LabelWrapper>
            </div>
          </div>
          {values.gear !== '' && values.gear !== 'gearless' && (
            <Fragment>
              <GearRows
                gears={values.gears}
                vesselType={values.vesselType}
                cargoType={form.marketSegment.cargoType}
                onChange={(values: $TSFixMe) => onChange('gears', values)}
                validateRow={validateRow}
                validation={validations.gears}
              />
              <div className={'row'}>
                <div className={'scol-12 cargo-vessel-form__icon-button'}>
                  <Button
                    id={'form-features-gear-add'}
                    icon
                    label={
                      <Fragment>
                        <Icon type={'item-add-selection-solid'} color="blue" style={{marginRight: 6}} />
                        {values.gears.length === 0
                          ? values.vesselType === 'container'
                            ? 'ADD GEAR'
                            : 'ADD GEAR / GRABS'
                          : 'ADD MORE'}
                      </Fragment>
                    }
                    onClick={() => onChange('gears', [...values.gears, newGear])}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {values.vesselType && (
            <Fragment>
              <HorizontalLine large />
              <div className="row">
                <div className={'scol-12'}>
                  <FeatureSection>
                    {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                    {cargoFeatures[values.vesselType].map((feature: $TSFixMe, i: $TSFixMe) => (
                      <Checkbox
                        id={`form-features-feature-${i}`}
                        key={i}
                        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        label={cargoFeatureSet[feature]}
                        checked={values[feature]}
                        onChange={e => onChange(feature, e.target.checked)}
                      />
                    ))}
                  </FeatureSection>
                </div>
              </div>
            </Fragment>
          )}
          <HorizontalLine large />
          <div className="row">
            <div className={'scol-12 scol-sm-4'}>
              <LabelWrapper label={'Ice class'} htmlFor={'form-features-iceclass'}>
                <Select
                  id={'form-features-iceclass'}
                  name={'form-features-iceclass'}
                  options={iceClassOptions}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  value={{value: values.iceClass, name: iceClasses[values.iceClass]}}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  defaultValue={{value: values.iceClass, name: iceClasses[values.iceClass]}}
                  onChange={(o: $TSFixMe) => onChange('iceClass', (o && o.value) || '')}
                  getOptionLabel={(o: $TSFixMe) => o.name}
                  getOptionValue={(o: $TSFixMe) => o.value}
                  components={{DropdownIndicator: ArrowIndicator}}
                  isSearchable={false}
                  isClearable
                />
              </LabelWrapper>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const mapStateToProps = ({preFill}: RootState) => ({
  preFillData: preFill.data,
});

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  loadDesignTypeOptions: (query: $TSFixMe) => dispatch(autocompleteApi.searchDesignTypes(query)),
  preFillSearch: (indexName: $TSFixMe, page: $TSFixMe, pageSize: $TSFixMe) =>
    dispatch(autocompleteApi.preFillSearch(indexName, page, pageSize)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VesselFeatures);
