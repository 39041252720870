import {useQuery} from '@tanstack/react-query';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {ApiError} from '../../../../api/utils/ApiError';
import {NegotiationRoundResult} from '../../../../api/node-backend/generated';
import {negotiationNegotiationRoundResultApi} from '../../../../api/node-backend/nodeBackendApis';

export const FIND_ROUND_RESULT_BY_ID_QUERY_KEY = (roundResultId?: string) => [
  'negotiationNegotiationRoundResultApi.findRoundResultById',
  roundResultId,
];

export const useFindRoundResultByIdQuery = (
  roundResultId?: string,
  options?: UseQueryOptions<NegotiationRoundResult, ApiError>
) =>
  useQuery({
    queryKey: FIND_ROUND_RESULT_BY_ID_QUERY_KEY(roundResultId),
    queryFn: async () => {
      const response = await negotiationNegotiationRoundResultApi.findRoundResultById({roundResultId: roundResultId!});
      return response;
    },
    enabled: !!roundResultId,
    refetchOnWindowFocus: true,
    ...options,
  });
