import kebabCase from 'lodash/kebabCase';
import {ColumnHeader} from './ColumnHeader/ColumnHeader';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const prepareColumns = <T extends unknown>({columns}: {columns: T[]}) => {
  return columns.map((column: $TSFixMe) => {
    const newColumn = {...column};
    if (typeof column.header === 'string') {
      const newHeader = () => (
        <ColumnHeader sortable={column.enableSorting} data-cy={kebabCase(`column-header-${column.header}`)}>
          {column.header}
        </ColumnHeader>
      );

      newColumn.header = newHeader;
    }

    return newColumn;
  });
};
export default prepareColumns;
