import React, {FC} from 'react';
import {NegotiationSummary} from '../../../../../api/node-backend/generated';
import styled from 'styled-components';
import {NegotiationSteps} from '../../../Components/NegotiationSteps';
import {TermAreaHeadline} from './TermAreaHeadline';

type TermAreaHeaderProps = {
  negotiation: NegotiationSummary;
  isFirstRecapRound?: boolean;
};

export const TermAreaHeader: FC<TermAreaHeaderProps> = ({negotiation, isFirstRecapRound}) => {
  return (
    <TermAreaHeaderContainer>
      <NegotiationSteps negotiation={negotiation} isFirstRecapRound={isFirstRecapRound} size="small" />
      <TermAreaHeadline negotiation={negotiation} isFirstRecapRound={isFirstRecapRound} />
    </TermAreaHeaderContainer>
  );
};

const TermAreaHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: -10px -10px 15px;
  padding-bottom: 10px;
`;
