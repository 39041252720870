import {TODO} from '../../../utils/TODO';
import VesselFixturesGrid from '../VesselFixtures/VesselFixtures';
import {Fixture} from '../VesselDetailBody';

export const VesselSaleFixtureTab = ({vessel, onVesselChanged}: {vessel: TODO; onVesselChanged?: TODO}) => {
  const saleFixtures = vessel.fixtures.filter((fixture: Fixture) => {
    return fixture.contractType === 'sale';
  });

  return (
    <VesselFixturesGrid
      key={'vessel-sale-fixture'}
      tabKey={'salesHistory'}
      id={'vessel-sale-fixture'}
      selectedFixtureId={vessel.selectedFixture?.id}
      data={saleFixtures}
      onFixtureChanged={onVesselChanged}
      unroundedTopLeft
      unroundedTopRight
    />
  );
};
