import {useQuery} from '@tanstack/react-query';
import {UseQueryOptions} from '../api/utils/ReactQuery';
import {vesselDBVesselsApi} from '../api/node-backend/nodeBackendApis';
import {assert} from '../utils/assert';
import {
  VesselDatabaseVessel,
  VesselDatabaseVesselSchema,
} from '../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

const QUERY_KEY_PREFIX = 'vesselDB.findVesselById';

export const useVesselDBFindVesselByIdQuery = (
  id: number | undefined | null,
  options?: UseQueryOptions<VesselDatabaseVessel>
) =>
  useQuery({
    queryKey: [QUERY_KEY_PREFIX, id],
    queryFn: async () => {
      assert(id);
      const responseVessel = await vesselDBVesselsApi.findVesselById({id: id.toString()});
      const vessel = VesselDatabaseVesselSchema.parse(responseVessel);
      return vessel;
    },
    ...options,
  });
