import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import CompanyActions from '../redux/ApiService/CompanyService/CompanyService';
import {useDispatch, useSelector} from '../redux/react-redux';
import {CompanyUser, CompanyWorkspace} from '../api/symfony/generated';

const COMPANY_MEMBERS_QUERY_KEY = 'companyMembers';

export type CompanyMember = {
  avatarUrl: string;
  id: number;
  workspaces: CompanyWorkspace[];
  companyJoinTime: string;
  companyPosition: null | string;
  companyVisibility: boolean;
  firstName: string;
  fullName: string;
  lastName: string;
  roles: string[];
  userID: number;
};

export const useCompanyMembersQuery = (): UseQueryResult<CompanyMember[]> => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.company.data.id);
  return useQuery({
    queryKey: [COMPANY_MEMBERS_QUERY_KEY],
    queryFn: async () => {
      const response = await dispatch(CompanyActions.getMembers(companyId));

      const users = response.data.items.map((user: CompanyUser) => {
        return {avatarUrl: `/scf/api/UserAvatar?userid=${user.id}`, ...user};
      });

      return users;
    },
  });
};

export const invalidateCompanyMembers = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [COMPANY_MEMBERS_QUERY_KEY],
  });
};
