import {useMemo} from 'react';
import Supercluster from 'supercluster';
import {MapPortWithMarketCirculars} from '../useExtendPortsAndTradingAreasWithCirculars';

export const useGetMarketPortClusters = ({ports}: {ports: MapPortWithMarketCirculars[]}) => {
  return useMemo(() => {
    const cluster = new Supercluster({
      minZoom: 0, // 0
      maxZoom: 9, // 16
      radius: 70, // 40
      extent: 512, // 512
      nodeSize: 16, // 64
    });

    const portFeatures = ports.map(portToFeature);
    cluster.load(portFeatures);

    return cluster;
  }, [ports]);
};

const portToFeature = (port: MapPortWithMarketCirculars): Supercluster.PointFeature<MapPortWithMarketCirculars> => {
  return {
    type: 'Feature',
    properties: port,
    geometry: {
      type: 'Point',
      coordinates: port.coordinates,
    },
  };
};
