import {FC, ReactNode} from 'react';
import styled from 'styled-components';
import {Button, Popconfirm} from 'antd';
import {
  NegotiationRoundResult,
  NegotiationRoundResultModification,
  NegotiationStatus,
  NegotiationSummary,
} from '../../../../../../api/node-backend/generated';
import Icon from '../../../../../../atoms/Icon';
import {combineWithTermModification} from '../utils';
import {getDerivedTermState} from '../../TermItem/utils/getDerivedTermState';
import {SidebarActionsLabel, SidebarCard} from './shared';
import {SidebarActionsHook} from './useGetSidebarActionsWithMutations';

type SidebarActionAreaProps = {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  roundResultModifications: NegotiationRoundResultModification;
  isFirstRecapRound?: boolean;
  actionsBusy?: boolean;
  getSidebarActions: SidebarActionsHook;
};

export const SidebarActionArea: FC<SidebarActionAreaProps> = ({
  negotiation,
  roundResult,
  roundResultModifications,
  isFirstRecapRound,
  actionsBusy,
  getSidebarActions,
}) => {
  const {party, latestRound, status} = negotiation;
  const {committedAt} = latestRound;
  const roundCanBeEdited =
    ![NegotiationStatus.Cancelled, NegotiationStatus.Settled].includes(status) && committedAt === null;

  const terms = roundResult.terms;
  const termWithModifications = terms.map(combineWithTermModification(roundResultModifications));
  const derivedTermStates = termWithModifications.map(({term, termModifications}) => {
    return {
      id: term.id,
      state: getDerivedTermState({
        party,
        roundCanBeEdited,
        term,
        termModification: termModifications?.termModification,
        isRecap: status === NegotiationStatus.Recap,
        isFirstRecapRound,
      }),
    };
  });

  const {onDelete, onAccept, onReject, onReset, sidebarActionBusy} = getSidebarActions(derivedTermStates);

  return (
    <>
      <SidebarActionsLabel>Batch actions</SidebarActionsLabel>
      <SidebarCard>
        {derivedTermStates.some(t => t.state.canBeDeleted) && (
          <ButtonWithPopconfirm
            loading={sidebarActionBusy}
            disabled={actionsBusy}
            confirmText="Remove all new terms?"
            color="rejected"
            onConfirm={onDelete}>
            <Icon size="small" type="delete" />
            Remove all
          </ButtonWithPopconfirm>
        )}
        {derivedTermStates.some(t => t.state.canBeAccepted) && (
          <ButtonWithPopconfirm
            confirmText="Accept all pending terms?"
            color="accepted"
            loading={sidebarActionBusy}
            disabled={actionsBusy}
            onConfirm={onAccept}>
            <Icon size="small" type="checkmark" />
            Accept all
          </ButtonWithPopconfirm>
        )}
        {derivedTermStates.some(t => t.state.canBeRejectionRequested) && (
          <ButtonWithPopconfirm
            confirmText="Reject all pending terms?"
            color="rejected"
            loading={sidebarActionBusy}
            disabled={actionsBusy}
            onConfirm={onReject}>
            <Icon size="small" type="close" />
            Reject all
          </ButtonWithPopconfirm>
        )}
        {derivedTermStates.some(t => t.state.canBeReset) && (
          <ButtonWithPopconfirm
            confirmText="Reset all edited terms?"
            color="partner"
            loading={sidebarActionBusy}
            disabled={actionsBusy}
            onConfirm={onReset}>
            <Icon size="small" type="undo" />
            Reset all
          </ButtonWithPopconfirm>
        )}
      </SidebarCard>
    </>
  );
};

const ButtonWithPopconfirm: FC<{
  confirmText: string;
  color: string;
  loading?: boolean;
  disabled?: boolean;
  onConfirm: () => void;
  children: ReactNode;
}> = ({confirmText, color, loading, disabled, onConfirm, children}) => (
  <Popconfirm
    placement="right"
    title={confirmText}
    cancelText="Cancel"
    okText={'yes'}
    onConfirm={onConfirm}
    overlayInnerStyle={{padding: 5}}
    disabled={disabled}>
    <ActionButton size="small" block $color={color} disabled={disabled} loading={loading}>
      {children}
    </ActionButton>
  </Popconfirm>
);

const ActionButton = styled(Button)<{$color: string}>`
  &.ant-btn.ant-btn-default {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-${({$color}) => $color});
    border-color: var(--color-${({$color}) => $color}-border);
    background: var(--color-${({$color}) => $color}-bg);
    border-radius: var(--border-radius);
    font-size: var(--font-size-xs);
    line-height: 1;
  }

  .icon {
    margin-right: 3px;
    color: var(--color-${({$color}) => $color});
    vertical-align: middle;
  }
`;
