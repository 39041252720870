import {ArrowRightOutlined} from '@ant-design/icons';
import {FC} from 'react';
import styled from 'styled-components';

export const DeltaIcon: FC<{value: number; isInverted?: boolean; className?: string}> = ({
  value,
  isInverted,
  className,
}) => {
  if (isInverted ? value < 0 : value > 0) {
    return (
      <Container $color="green" $isInverted={isInverted} className={className}>
        <ArrowRightOutlined />
      </Container>
    );
  }
  if (isInverted ? value > 0 : value < 0) {
    return (
      <Container $color="red" $isInverted={isInverted} className={className}>
        <ArrowRightOutlined />
      </Container>
    );
  }
  if (value === 0) {
    return (
      <Container $color="grey-2" $isInverted={isInverted} className={className}>
        -
      </Container>
    );
  }
  return null;
};

const Container = styled.span<{$color: 'grey-2' | 'red' | 'green'; $isInverted?: boolean}>`
  position: relative;
  top: 0.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.2em;
  width: 1.2em;
  margin-right: 0.1em;
  background: var(--color-${({$color}) => $color});
  border-radius: var(--border-radius-button);
  box-shadow: var(--box-shadow-button);

  .anticon {
    transform: rotate(${({$color, $isInverted}) => ($color === ($isInverted ? 'red' : 'green') ? '-' : '')}45deg);
  }
`;
