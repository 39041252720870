import {Input} from 'antd';
import {Modal} from '../../../../antd/components/Modal';
import {useState} from 'react';
import {useDispatch} from '../../../../redux/react-redux';
import {actions as cargoApi} from '../../../../redux/ApiService/cargoService';
import {useQueryClient} from '@tanstack/react-query';
import {TODO} from '../../../../utils/TODO';
import Tooltip from '../../../../atoms/Tooltip';
import Icon from '../../../../atoms/Icon';
import {invalidateShortlistQueries} from '../../../../queries/useShortlistQuery';

export const EditCommentModal = ({cargo, editable}: {cargo: TODO; editable: boolean}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [comment, setComment] = useState(cargo.comment);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleOnOk = async () => {
    await dispatch(cargoApi.patch({params: {cargoId: cargo.id}, body: {...cargo, comment}}));
    await invalidateShortlistQueries(queryClient);
    setModalOpen(false);
  };

  if (!editable) {
    return (
      <Tooltip position={'top'} trigger={'mouseenter focus'} style={{paddingTop: '20px'}} title={cargo.comment}>
        <Icon type={'block'} />
      </Tooltip>
    );
  }

  return (
    <div>
      <div onClick={() => setModalOpen(true)}>
        <Tooltip position={'top'} trigger={'mouseenter focus'} style={{paddingTop: '20px'}} title={cargo.comment}>
          <Icon type={'edit'} />
        </Tooltip>
      </div>
      <Modal
        open={modalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        onOk={handleOnOk}
        title="Edit note"
        okText={'Update'}>
        <Input.TextArea
          rows={8}
          value={comment}
          onChange={e => {
            setComment(e.target.value);
          }}
        />
      </Modal>
    </div>
  );
};
