import {ParsedVesselData} from '../../../../../api/parser/generated';
import {Gear} from '../../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

export const getGearsFromParser = (vessel: ParsedVesselData): Gear[] => {
  const list: Gear[] = [];

  if (vessel.grabs) {
    list.push({
      id: 0,
      type: 'grab',
      quantity: vessel.grabs,
      capacity: vessel.grabCapacity ?? null,
      description: null,
    });
  }
  if (vessel.cranes) {
    list.push({
      id: 0,
      type: 'gear',
      quantity: vessel.cranes,
      capacity: vessel.craneCapacity ?? null,
      description: null,
    });
  }
  return list;
};
