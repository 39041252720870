import {cargoAccessor} from '../../utils/cargoAccessorResult';
import CargoUtil from '../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {RowValueType} from '../../utils/RowValueType';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';

const laycanAccessor = <T,>(type: RowValueType) =>
  cargoAccessor((d: T) => <div className="market-grid-cell-content__laycan">{CargoUtil.formatLaycan(d)}</div>, type);

export const laycanColumn = <T,>({
  type,
  minWidth = 4,
}: {
  type: RowValueType;
  minWidth?: number;
}): CustomColumnDef<T> => ({
  header: 'Laycan',
  id: 'laycanFrom',
  enableSorting: true,
  accessorFn: laycanAccessor<T>(type),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
