import {CSSProperties, FC, ReactNode} from 'react';
import styled from 'styled-components';

interface Props {
  center?: boolean;
  size?: number;
  style?: CSSProperties;
  additionalClasses?: string;
  children: ReactNode;
}

export const StyledDetailsCol = styled.div`
  padding-top: 5px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const DetailsColumn: FC<Props> = ({children, size, style, additionalClasses = ''}) => (
  <StyledDetailsCol className={`details-column scol-${size} ${additionalClasses}`} style={style}>
    {children}
  </StyledDetailsCol>
);

export default DetailsColumn;
