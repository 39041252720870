import {CalcTimeLineList, CalcTimeLineRoute, VoyagePoint, VoyageRoute} from '../VoyageTypes';
import {Timeline as AntdTimeline} from 'antd';
import {PointTimelineItem} from './PointTimelineItem/PointTimelineItem';
import {RouteTimelineItem} from './RouteTimelineItem/RouteTimelineItem';
import {FC, useState, useEffect} from 'react';
import styled from 'styled-components';
import {VesselInput} from '../../VesselInformation/VesselTypes';
import {AddItem, defaultVoyagePoint, voyagePointWithNextOpen} from './AddItem';
import {DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToWindowEdges} from '@dnd-kit/modifiers';
import {TODO} from '../../../../../utils/TODO';
import {CalcTimeLinePoint} from '../VoyageTypes';

export type ActiveEditsMap = Record<string, boolean>;
export const defaultIsEditing = false;

interface TimelineProps {
  className?: string;
  vesselInput: VesselInput;
  items: CalcTimeLineList;
  onChangePoint: (index: number, point: Partial<VoyagePoint>) => void;
  onRemovePoint: (index: number) => void;
  onChangeRoute: (index: number, route: Partial<VoyageRoute>) => void;
  onAddPoint: (newPoint: VoyagePoint, postion: 'start' | 'end') => void;
  onChangeEditing: (key: string, isEditing: boolean) => void;
  activeEditsMap: ActiveEditsMap;
  onSortEnd: ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => void;
}

const TimelineComp: FC<TimelineProps> = ({
  className,
  items,
  vesselInput,
  onAddPoint,
  onChangePoint,
  onRemovePoint,
  onChangeRoute,
  onChangeEditing,
  activeEditsMap,
  onSortEnd,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [modItems, setModItems] = useState<CalcTimeLineList>(
    items.map((item, index) => {
      return {
        ...item,
        id: index + 1,
      };
    })
  );

  useEffect(() => {
    const newItems = items.map((item, index) => {
      return {
        ...item,
        id: index + 1,
      };
    });

    setModItems(newItems);
  }, [items]);

  const getIsEditing = (key: string) => {
    return activeEditsMap[key] ?? defaultIsEditing;
  };

  const handleDragEnd = (event: TODO) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setModItems(items => {
        const oldIndex = items.map(e => e.id).indexOf(active.id);
        const newIndex = items.map(e => e.id).indexOf(over.id);
        onSortEnd({oldIndex, newIndex});

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const hasNextOpen = items.some(point => point.nodeType === 'point' && point.item.types.includes('nextOpen'));

  const pointItems = modItems.filter(item => item.nodeType === 'point') as CalcTimeLinePoint[];
  const routeItems = modItems.filter(item => item.nodeType !== 'point') as CalcTimeLineRoute[];

  const points = pointItems.map((item, index) => {
    const keyPoint = 'point_' + index;
    const keyRoute = 'point_' + index;
    const routeItem: CalcTimeLineRoute = routeItems[index];
    return {
      children: (
        <>
          <PointTimelineItem
            isEditing={getIsEditing(keyPoint)}
            onChangeEditing={isEditing => onChangeEditing(keyPoint, isEditing)}
            onRemovePoint={() => onRemovePoint(item.indexInOldArray)}
            consumptionModes={vesselInput.consumptionModes}
            onChangePoint={point => onChangePoint(item.indexInOldArray, point)}
            item={item}
            id={item.id!}
            key={item.id}
          />
          {routeItem && (
            <RouteTimelineItem
              id={routeItem.id!}
              isEditing={getIsEditing(keyRoute)}
              onChangeEditing={isEditing => onChangeEditing(keyRoute, isEditing)}
              consumptionModes={vesselInput.consumptionModes}
              onChangeRoute={route => onChangeRoute(routeItem.indexInOldArray, route)}
              item={routeItem}
              key={routeItem.id!}
            />
          )}
        </>
      ),
    };
  });

  return (
    <div>
      <AddItem
        hint={hasNextOpen ? 'You can only have one next open leg' : undefined}
        disabled={hasNextOpen}
        label={'ADD NEXT OPEN LEG'}
        onAddPoint={() => {
          onAddPoint(voyagePointWithNextOpen, 'start');
        }}
      />
      <div
        style={{
          marginTop: 20,
        }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
          <SortableContext items={modItems as TODO} strategy={verticalListSortingStrategy}>
            <AntdTimeline className={className} items={points} />
          </SortableContext>
        </DndContext>
      </div>
      <AddItem
        onAddPoint={() => {
          onAddPoint(defaultVoyagePoint, 'end');
        }}
      />
    </div>
  );
};

export const Timeline: FC<TimelineProps> = styled(TimelineComp)`
  li.ant-timeline-item:last-child > div.ant-timeline-item-tail {
    border: none;
  }
  .ant-timeline-item {
    padding-bottom: 0;
  }
  .ant-timeline-item-tail {
    inset-block-start: 18px;
  }
  .ant-timeline-item-head-blue {
    top: 8px;
  }
`;

export default Timeline;
