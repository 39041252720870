import {Tabs} from 'antd';
import {MulticalcVesselInput, MulticalcCargoInput} from '../types';
import {MultiCalculation} from '../useMultiCalculation';
import {CargoToVesselScreen} from './CargoToVessel/CargoToVesselScreen';
import {VesselToCargoScreen} from './VesselToCargo/VesselToCargoScreen';
import {Matrix} from './Matrix/Matrix';
import Lighthouse from '../../../../atoms/EmptyState/LightHouse';

type Props = {
  vessels: MulticalcVesselInput[];
  multiCalculation: MultiCalculation;
  cargoes: MulticalcCargoInput[];
  isLoading?: boolean;
};

export const Visualization = (props: Props) => {
  const {cargoes, vessels} = props;

  if (vessels.length === 0) {
    return <Lighthouse small headline={'No vessels '} />;
  }
  if (cargoes.length === 0) {
    return <Lighthouse small headline={'No cargoes'} />;
  }

  const items = [
    {'data-cy': 'Matrix', label: 'Calculation Matrix', key: '1', children: <Matrix {...props} />},
    {'data-cy': 'VesselToCargoTable', label: 'Vessel → Cargo', key: '2', children: <VesselToCargoScreen {...props} />},
    {'data-cy': 'CargoToVesselTable', label: 'Cargo → Vessel', key: '3', children: <CargoToVesselScreen {...props} />},
  ];
  return <Tabs items={items} />;
};
