import {Role} from '../../../api/symfony/generated';
import remove from 'lodash/remove';
import produce from 'immer';

export const updateRoles = (roles: Role[] | undefined, isCompanyManager: boolean): Role[] => {
  const result = produce(roles ?? [], updatedRoles => {
    remove(updatedRoles, role => role === Role.CompanyManager);
    if (isCompanyManager) {
      // Add the role
      updatedRoles.push(Role.CompanyManager);
    }
  });
  return result;
};
