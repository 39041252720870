import {cargoAccessor} from '../../utils/cargoAccessorResult';
import CargoUtil from '../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import Cargo from '../../../../model/Cargo';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';

const loadDeliveryAccessor = (type: $TSFixMe, {showFlag}: {showFlag?: boolean}) =>
  cargoAccessor(
    (d: Cargo) => <div className="market-grid-cell-content__load">{CargoUtil.loadingLocation(d, '-', showFlag)}</div>,
    type
  );
export const loadDeliveryColumn = ({
  type,
  minWidth = 8,
  showFlag,
}: {
  type: $TSFixMe;
  minWidth?: number;
  showFlag?: boolean;
}): CustomColumnDef<CargoGridColumnsProps> => ({
  header: 'Load / delivery',
  id: 'load',
  enableSorting: false,
  accessorFn: loadDeliveryAccessor(type, {showFlag}),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
});
