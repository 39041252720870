/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  NegotiationRoundResult,
  NegotiationRoundResultFromJSON,
  NegotiationRoundResultToJSON,
  NewSubjectsRequest,
  NewSubjectsRequestFromJSON,
  NewSubjectsRequestToJSON,
  UpdateSubjectTextRequest,
  UpdateSubjectTextRequestFromJSON,
  UpdateSubjectTextRequestToJSON,
} from '../models';

export interface AcceptSubjectRequest {
  subjectId: string;
}

export interface AcceptSubjectRejectionRequest {
  subjectId: string;
}

export interface DeleteSubjectRequest {
  subjectId: string;
}

export interface FailSubjectRequest {
  subjectId: string;
}

export interface LiftSubjectRequest {
  subjectId: string;
}

export interface NewSubjectsOperationRequest {
  newSubjectsRequest: NewSubjectsRequest;
}

export interface RejectSubjectRejectionRequestRequest {
  subjectId: string;
}

export interface RequestSubjectRejectionRequest {
  subjectId: string;
}

export interface ResetSubjectRequest {
  subjectId: string;
}

export interface UpdateSubjectTextOperationRequest {
  subjectId: string;
  updateSubjectTextRequest: UpdateSubjectTextRequest;
}

/**
 *
 */
export class NegotiationNegotiationSubjectApi extends runtime.BaseAPI {
  /**
   */
  async acceptSubjectRaw(
    requestParameters: AcceptSubjectRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling acceptSubject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/accept`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async acceptSubject(requestParameters: AcceptSubjectRequest): Promise<NegotiationRoundResult> {
    const response = await this.acceptSubjectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Accept the outstanding rejection request. Only possible if the subject is requested for rejection.
   */
  async acceptSubjectRejectionRaw(
    requestParameters: AcceptSubjectRejectionRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling acceptSubjectRejection.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/accept-rejection`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Accept the outstanding rejection request. Only possible if the subject is requested for rejection.
   */
  async acceptSubjectRejection(requestParameters: AcceptSubjectRejectionRequest): Promise<NegotiationRoundResult> {
    const response = await this.acceptSubjectRejectionRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async deleteSubjectRaw(
    requestParameters: DeleteSubjectRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling deleteSubject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/delete`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async deleteSubject(requestParameters: DeleteSubjectRequest): Promise<NegotiationRoundResult> {
    const response = await this.deleteSubjectRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async failSubjectRaw(requestParameters: FailSubjectRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling failSubject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/fail`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async failSubject(requestParameters: FailSubjectRequest): Promise<NegotiationRoundResult> {
    const response = await this.failSubjectRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async liftSubjectRaw(requestParameters: LiftSubjectRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling liftSubject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/lift`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async liftSubject(requestParameters: LiftSubjectRequest): Promise<NegotiationRoundResult> {
    const response = await this.liftSubjectRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async newSubjectsRaw(
    requestParameters: NewSubjectsOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.newSubjectsRequest === null || requestParameters.newSubjectsRequest === undefined) {
      throw new runtime.RequiredError(
        'newSubjectsRequest',
        'Required parameter requestParameters.newSubjectsRequest was null or undefined when calling newSubjects.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: NewSubjectsRequestToJSON(requestParameters.newSubjectsRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async newSubjects(requestParameters: NewSubjectsOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.newSubjectsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Reject the rejection request. Only possible if the subject is requested for rejection. The subject will be set to status \"ongoing\".
   */
  async rejectSubjectRejectionRequestRaw(
    requestParameters: RejectSubjectRejectionRequestRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling rejectSubjectRejectionRequest.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/reject-rejection`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Reject the rejection request. Only possible if the subject is requested for rejection. The subject will be set to status \"ongoing\".
   */
  async rejectSubjectRejectionRequest(
    requestParameters: RejectSubjectRejectionRequestRequest
  ): Promise<NegotiationRoundResult> {
    const response = await this.rejectSubjectRejectionRequestRaw(requestParameters);
    return await response.value();
  }

  /**
   * Request a subjetion rejection. Only possible if the negotiation is in \"ongoing\" or \"recap\" state, and the current user is the active party. Only possible if the subject has status ongoing.
   */
  async requestSubjectRejectionRaw(
    requestParameters: RequestSubjectRejectionRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling requestSubjectRejection.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/request-rejection`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   * Request a subjetion rejection. Only possible if the negotiation is in \"ongoing\" or \"recap\" state, and the current user is the active party. Only possible if the subject has status ongoing.
   */
  async requestSubjectRejection(requestParameters: RequestSubjectRejectionRequest): Promise<NegotiationRoundResult> {
    const response = await this.requestSubjectRejectionRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async resetSubjectRaw(requestParameters: ResetSubjectRequest): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling resetSubject.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}/reset`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async resetSubject(requestParameters: ResetSubjectRequest): Promise<NegotiationRoundResult> {
    const response = await this.resetSubjectRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateSubjectTextRaw(
    requestParameters: UpdateSubjectTextOperationRequest
  ): Promise<runtime.ApiResponse<NegotiationRoundResult>> {
    if (requestParameters.subjectId === null || requestParameters.subjectId === undefined) {
      throw new runtime.RequiredError(
        'subjectId',
        'Required parameter requestParameters.subjectId was null or undefined when calling updateSubjectText.'
      );
    }

    if (
      requestParameters.updateSubjectTextRequest === null ||
      requestParameters.updateSubjectTextRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'updateSubjectTextRequest',
        'Required parameter requestParameters.updateSubjectTextRequest was null or undefined when calling updateSubjectText.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/subjects/{subjectId}`.replace(
        `{${'subjectId'}}`,
        encodeURIComponent(String(requestParameters.subjectId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateSubjectTextRequestToJSON(requestParameters.updateSubjectTextRequest),
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationRoundResultFromJSON(jsonValue));
  }

  /**
   */
  async updateSubjectText(requestParameters: UpdateSubjectTextOperationRequest): Promise<NegotiationRoundResult> {
    const response = await this.updateSubjectTextRaw(requestParameters);
    return await response.value();
  }
}
