import {ValidationProblem} from '../../api/symfony/generated';
import {ApiError} from './ApiError';
import {ValidationProblemViolations} from '../../api/symfony/generated';
import {assert} from '../../utils/assert';

/**
 * A validation error sent by the backend
 * with content-type: application/problem+json
 */
export class ValidationError extends ApiError {
  name = 'ValidationError';
  validationProblem: ValidationProblem;

  constructor(message: string, validationProblem: ValidationProblem, status: number) {
    super(message, status);
    this.validationProblem = validationProblem;
  }
}

export const isValidationError = (error: unknown): error is ValidationError => error instanceof ValidationError;

/**
 * Returns the first validation violation for the given ValidationError and propertyPath.
 */
export const violationForField = (error: Error, propertyPath: string): ValidationProblemViolations | undefined => {
  assert(isValidationError(error), 'Must be a ValidationError');
  const validationProblem = error.validationProblem;
  const violations: ValidationProblemViolations[] = validationProblem.violations ?? [];
  return violations.find(violation => violation.propertyPath === propertyPath);
};
