import {FC} from 'react';
import styled from 'styled-components';
import {useCircularEmailQuery} from '../../useCircularEmailQuery';
import {Offer} from '../EditCircularForm';
import Divider from '../../../../../atoms/Divider';

export const MailView: FC<{offer: Offer}> = ({offer}) => {
  const type = 'vessel' in (offer ?? {}) ? 'vessel' : 'cargo';
  const emailQuery = useCircularEmailQuery(
    {
      id: offer?.id,
      type,
    },
    {
      enabled: !!offer,
    }
  );
  const circularEmail = emailQuery.data;

  return (
    <StyledMailView>
      <MailHeader>
        <MailHeaderColumn>
          <Bold>From:</Bold>
          <Bold>Subject:</Bold>
          <Bold>Posted at:</Bold>
        </MailHeaderColumn>
        <MailHeaderColumn>
          <Text>{circularEmail?.sender}</Text>
          <Text>{circularEmail?.subject}</Text>
          <Text>{circularEmail?.postedAt.format('ddd,MMM Do YYYY, HH:mm')}</Text>
        </MailHeaderColumn>
      </MailHeader>
      <Divider />
      <MailContent>{circularEmail?.text}</MailContent>
    </StyledMailView>
  );
};

const StyledMailView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`;

const MailHeader = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
`;

const MailHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
`;

const Bold = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: var(--font-size-md);
  line-height: 24px;
  color: var(--color-black);
`;
const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 24px;
  color: var(--color-black);
`;

const MailContent = styled.div`
  white-space: break-spaces;
`;
