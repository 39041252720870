import React, {FC} from 'react';
import cx from 'classnames';

import './detailtable.scss';

interface RowProps {
  headerStyle?: boolean;
  noBorder?: boolean;
  children: React.ReactNode;
}

const Row: FC<RowProps> = ({headerStyle, children, noBorder}) => (
  <tr
    className={cx('detail-table__row', {
      'detail-table__row--no-border': noBorder,
      'detail-table__row--header-style': headerStyle,
    })}>
    {children}
  </tr>
);

interface Props extends RowProps {
  headerWrapper?: boolean;
  children: React.ReactNode;
}

type DetailTableRowProps = {
  headerWrapper?: boolean;
  noBorder?: boolean;
  children: React.ReactNode;
};

export const DetailTableRow: FC<Props> = ({headerWrapper, ...props}: DetailTableRowProps) =>
  headerWrapper ? (
    <thead className="detail-table__header ">
      <Row {...props} />
    </thead>
  ) : (
    <Row {...props} />
  );

export default DetailTableRow;
