/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AisRecordResponse
 */
export interface AisRecordResponse {
  /**
   *
   * @type {number}
   * @memberof AisRecordResponse
   */
  csvRow: number;
  /**
   *
   * @type {number}
   * @memberof AisRecordResponse
   */
  timestamp: number;
  /**
   * coordintes in [lon, lat] format
   * @type {Array<number>}
   * @memberof AisRecordResponse
   */
  coordinates: Array<number>;
  /**
   *
   * @type {number}
   * @memberof AisRecordResponse
   */
  heading: number;
  /**
   *
   * @type {number}
   * @memberof AisRecordResponse
   */
  speed: number;
}

export function AisRecordResponseFromJSON(json: any): AisRecordResponse {
  return AisRecordResponseFromJSONTyped(json, false);
}

export function AisRecordResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisRecordResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    csvRow: json['csvRow'],
    timestamp: json['timestamp'],
    coordinates: json['coordinates'],
    heading: json['heading'],
    speed: json['speed'],
  };
}

export function AisRecordResponseToJSON(value?: AisRecordResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    csvRow: value.csvRow,
    timestamp: value.timestamp,
    coordinates: value.coordinates,
    heading: value.heading,
    speed: value.speed,
  };
}
