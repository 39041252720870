import {ReactNode} from 'react';
import Icon from '../../../atoms/Icon';
import styled from 'styled-components';

export const IntersectionBox = ({label, value}: {label: ReactNode; value: ReactNode}) => {
  return (
    <IntersectionBoxContainer data-testid={`IntersectionBox-${label}`}>
      <Icon type={'block'} />
      <Label>{label}</Label>
      <Value>{value}</Value>
    </IntersectionBoxContainer>
  );
};

const IntersectionBoxContainer = styled.div`
  flex: 0 0 auto;
  border-right: var(--border-base);
  padding: 12px 15px;
  display: flex;
  align-items: center;

  &:last-child {
    border-right-width: 0;
  }

  .icon {
    color: var(--color-orange);
    font-size: var(--font-size-lg);
    margin-right: 8px;
  }
`;

const Label = styled.span`
  color: var(--color-gray-1);
  font-size: var(--font-size-md);
  font-weight: bold;
  line-height: 19px;
`;

const Value = styled.span`
  margin-left: 7px;
  margin-right: 7px;
  color: #696969;
  font-size: var(--font-size-sm);
  line-height: 19px;
  font-weight: bold;
`;
