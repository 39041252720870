import {Link} from 'react-router-dom';
import {numberFormat} from '../../../utils/formatter';
import DateTime from '../../../model/DateTime';
import classNames from 'classnames';
import Icon from '../../../atoms/Icon';
import {TBN} from '../../../components/Placeholder/Placeholder';
import Tooltip from '../../../atoms/Tooltip';
import {UserColumn} from '../../../atoms/UserColumn/UserColumn';
import {formatContractType} from '../utils/formatContractType';
import {RateFixtureColumn} from '../RateFixtureColumn';
import {TODO} from '../../../utils/TODO';
import {laycanColumn} from '../columns/cargo/laycanColumn';
import {loadDeliveryFixtureColumn} from '../columns/cargo/loadDeliveryFixtureColumn';
import {dischargeRedeliveryFixtureColumn} from '../columns/cargo/dischargeRedeliveryFixtureColumn';
import {commissionColumn} from '../columns/cargo/commissionColumn';
import {FixtureTags} from '../FixtureTags';
import {UserDateTime} from '../../DateTime/UserDateTime';
import {getPeriodLabel} from './columns/helpers';
import {getDurationUnit} from '../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {CustomColumnDef} from '../../DataGrid/DataGrid';
import {Fixture} from '../../../screens/VesselDetailScreen/VesselFixtures/fixtureType';

export const fixtureGridColumns = ({
  onFixtureChanged,
  updateVessel,
}: {
  onFixtureChanged?: (params?: TODO) => void;
  updateVessel?: boolean;
}): CustomColumnDef<TODO>[] => [
  {
    header: 'Type',
    id: 'contractType',
    accessorKey: 'contractType',
    accessorFn: row => {
      return (
        <div data-cy={'FixtureGridTypeCell'} className={'market-grid-cell fixture-grid-cell__contract-type'}>
          <div
            className={classNames('market-grid-cell-content', {
              'market-grid-cell-content--no-sub': !(row.isExtension || row.isOptionDeclared),
            })}>
            {formatContractType(row.contractType)}
          </div>
          <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
            <div>{row.isOptionDeclared ? 'Opt. decl.' : row.isExtension && 'Ext.'}</div>
          </div>
        </div>
      );
    },
    cell: info => info.getValue(),
    minWidth: 5,
    enableSorting: true,
  },
  {
    header: 'Vessel name',
    id: 'vesselName',
    accessorKey: 'vesselName',
    accessorFn: (row: $TSFixMe) => {
      return (
        <div data-cy={'FixtureGridVesselNameCell'} className={'market-grid-cell fixture-grid-cell__vessel-name'}>
          <div
            className={classNames('market-grid-cell-content', {
              'market-grid-cell-content--no-sub': !(row.designSubType || row.designType),
            })}>
            {row.vessel ? <Link to={`/vessel/${row.vessel.id}`}>{row.vesselName}</Link> : row.vesselName}
          </div>
          {row.designType ||
            (row.designSubType && (
              <div
                className={'market-grid-cell-content market-grid-cell-content--sub'}
                style={{textTransform: 'capitalize'}}>
                {row.vesselType === 'container' ? row.designType || ' - ' : row.designSubType || ' - '}
              </div>
            ))}
        </div>
      );
    },
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 12,
  },
  {
    header: 'Size',
    id: 'size',
    accessorFn: (d: $TSFixMe) => (
      <div data-cy={'FixtureGridSizeCell'} className={'market-grid-cell fixture-grid-cell__size'}>
        <div className={'market-grid-cell-content market-grid-cell-content--no-sub'}>
          {d.vesselType === 'container'
            ? numberFormat(d.teuQuantity) + (d.teuQuantity ? ' TEU' : '')
            : numberFormat(d.dwat) + (d.dwat ? ' DWT' : '')}
        </div>
      </div>
    ),
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 5,
  },
  {
    header: 'Charterer',
    id: 'chartererName',
    accessorKey: 'chartererName',
    accessorFn: (row: $TSFixMe) => {
      return (
        <div data-cy={'FixtureGridChartererCell'} className={'market-grid-cell fixture-grid-cell__charterer-name'}>
          <div className={'market-grid-cell-content market-grid-cell-content--no-sub'}>
            {row.cargo ? (
              <Link to={`/cargo/${row.cargo.id}`}>{row.charterName ?? row.chartererName ?? <TBN />}</Link>
            ) : (
              row.charterName ?? row.chartererName ?? <TBN />
            )}
          </div>
        </div>
      );
    },
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 8,
  },
  laycanColumn<Fixture>({
    type: 'portfolio',
    minWidth: 8,
  }),
  loadDeliveryFixtureColumn({
    type: 'portfolio',
    minWidth: 8,
  }),
  {
    header: 'Duration',
    id: 'period',
    accessorFn: ({options}: $TSFixMe) => {
      const periodMin = options?.[0]?.periodMin;
      const periodMax = options?.[0]?.periodMax;
      const ladenLegsMin = options?.[0]?.ladenLegsMin;
      const ladenLegsMax = options?.[0]?.ladenLegsMax;
      const periodUnit = getDurationUnit(options?.[0]?.periodUnit, Math.max(periodMin, periodMax));

      return (
        <div data-cy={'FixtureGridDurationCell'} className={'market-grid-cell fixture-grid-cell__period'}>
          <div
            style={{textTransform: 'uppercase'}}
            className={classNames('market-grid-cell-content', {
              'market-grid-cell-content--no-sub': !(periodMin || periodMax),
            })}>
            {getPeriodLabel({periodMax, periodMin})}
          </div>
          <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
            {options[0] && (periodMin || periodMax) && periodUnit}
          </div>
          {ladenLegsMin && ladenLegsMax && (
            <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
              {`${ladenLegsMin}-${ladenLegsMax} llgs`}
            </div>
          )}
        </div>
      );
    },
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 8,
  },
  {
    header: 'Rate',
    id: 'rate',
    accessorFn: (fixture: $TSFixMe) => {
      return <RateFixtureColumn onFixtureChanged={onFixtureChanged} updateVessel={updateVessel} fixture={fixture} />;
    },
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 8,
  },
  commissionColumn({
    type: 'portfolio',
    minWidth: 4,
  }),
  dischargeRedeliveryFixtureColumn({
    type: 'portfolio',
    minWidth: 5,
  }),
  {
    header: 'Fixt. Date',
    id: 'fixtureDate',
    // accessorKey: 'fixtureDate',
    accessorFn: (row: $TSFixMe) => (
      <div data-cy={'FixtureGridFixtureDateCell'} className={'market-grid-cell fixture-grid-cell__fixture-date'}>
        <div
          className={classNames('market-grid-cell-content', {
            'market-grid-cell-content--no-sub': !row.source,
          })}>
          {DateTime.fromServerResponse(row.fixtureDate).getFormattedDate()}
        </div>
        <div className={'market-grid-cell-content market-grid-cell-content--sub'}>{row.source}</div>
      </div>
    ),
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 8,
  },
  {
    header: 'Tags',
    id: 'tags',
    minWidth: 8,
    accessorFn: f => <FixtureTags fixture={f} onChange={onFixtureChanged} />,
    cell: info => info.getValue(),
  },
  {
    header: 'Note',
    id: 'remark',
    accessorFn: (d: $TSFixMe) => (
      <div
        data-cy={'FixtureGridNoteCell'}
        className={'market-grid-cell fixture-grid-cell__note'}
        style={{textAlign: 'center'}}>
        <div className={'market-grid-cell-content'}>
          {d.remark || d.options.length > 1 ? (
            <Tooltip
              position={'top'}
              trigger={'mouseenter focus'}
              style={{paddingTop: '20px'}}
              title={d.options.length > 1 ? `Plus options <br />${d.remark ? d.remark : ''}` : d.remark}>
              <Icon type={'block'} />
            </Tooltip>
          ) : (
            '-'
          )}
          {d.redeliveryNotice && <div>{d.redeliveryNotice}</div>}
        </div>
      </div>
    ),
    cell: info => info.getValue(),
    enableSorting: false,
    minWidth: 5,
  },

  {
    header: 'updated at',
    id: 'updatedAt',
    // accessorKey: 'updatedAt',
    accessorFn: (row: $TSFixMe) => (
      <div data-cy={'FixtureGridUpdatedAtCell'} className={'market-grid-cell fixture-grid-cell__created'}>
        <div className={'market-grid-cell-content'}>
          <UserColumn
            userId={row.updatedBy ? row.updatedBy.id : row.user.id}
            title={row.updatedBy ? row.updatedBy.fullName : row.user.fullName}
          />
        </div>

        <span className={'market-grid-cell-content market-grid-cell-content--sub'}>
          <UserDateTime format={'dateTime'} value={row.updatedAt} />
        </span>
      </div>
    ),
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 8,
  },
];
