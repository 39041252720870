import {LegendBox} from './LegendBox';
import {cargoMenu, CargoMenuItemKey} from '../helper';
import {CargoContractIcon} from '../mapOptions/CargoContractIcon';

export const CargoesLegend = () => {
  return (
    <LegendBox
      title={'Cargo'}
      listItems={(Object.keys(cargoMenu) as CargoMenuItemKey[]).map(type => {
        return <CargoContractIcon type={type} label={cargoMenu[type]} />;
      })}
    />
  );
};
