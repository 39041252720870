import styled from 'styled-components';
import {DatePicker as BasicDatePicker} from 'antd';

export const DatePicker = styled(BasicDatePicker)`
  &.ant-picker {
    width: 100%;
    padding: 7px 12px;
    color: var(--color-black);
    height: 36px;
    background-color: var(--color-gray-6);
    &:hover {
      background-color: var(--color-gray-6);
    }
  }
` as typeof BasicDatePicker;
