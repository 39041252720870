import {useSearchQuery} from '../SearchPicker/useSearchQuery';
import {makePortOption} from './makeOption/makePortOption';
import {PortOption} from './Type';

type Params = {
  searchTag: string;
};

/**
 * Performs a search request for the given searchTag and returns
 * the result as options for an Antd <AutoComplete> component.
 */
export const useOptionsFromPortSearch = (params: Params): PortOption[] => {
  const {searchTag} = params;

  const searchQuery = useSearchQuery({searchTag, indexNames: 'ports'});
  if (!searchQuery.isSuccess) {
    return [];
  }
  const options: PortOption[] = searchQuery.data.data.items.ports.items.map(makePortOption);
  return options;
};
