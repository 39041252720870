import {ActionMenu} from '../../ActionMenu/ActionMenu';
import Icon from '../../../atoms/Icon';
import {MenuItem} from '../../../atoms/Menu/MenuItem';
import {fixtureGridColumns} from '../../GridLayout/fixtureGridHelper/fixtureGridHelper';
import {CustomColumnDef} from '../../DataGrid/DataGrid';
import {TODO} from '../../../utils/TODO';
import {ManageFixtureProps} from '../../Modal/ManageFixture/ManageFixtureModal';
import {ReactNode} from 'react';
import {Delete} from '../../Modal/Delete/DeleteModal';

type Params = {
  modalCallback?: (params?: TODO) => void;
  showModal: (modal: ReactNode) => void;
  showManageFixtureModal: (props: ManageFixtureProps) => void;
  deleteFixture: (id: number) => Promise<void>;
};

export const getFixtureGridColumns: (params: Params) => CustomColumnDef<TODO>[] = ({
  showModal,
  modalCallback,
  showManageFixtureModal,
  deleteFixture,
}) => [
  ...fixtureGridColumns({onFixtureChanged: modalCallback, updateVessel: true}),
  {
    id: 'actions',
    header: '',
    enableSorting: false,
    minWidth: 4,
    style: {padding: '0'},
    className: 'datagrid-td-action',
    cell: info => info.getValue(),
    accessorFn: d => (
      <div data-cy={'FixtureGridActionsCell'}>
        <ActionMenu
          actionIcon={<Icon data-cy={'FixtureGridActions'} type={'more-vert'} />}
          items={[
            {
              key: 'VesselFixtureEditButton',
              label: (
                <MenuItem
                  dataCy={'VesselFixtureEditButton'}
                  label="Edit fixture"
                  onClick={() => {
                    showManageFixtureModal({
                      updateVessel: true,
                      formData: d,
                      callback: modalCallback,
                    });
                  }}
                />
              ),
            },
            {
              key: 'VesselFixtureDeleteButton',
              label: (
                <MenuItem
                  dataCy={'VesselFixtureDeleteButton'}
                  label="Delete fixture"
                  onClick={() => {
                    showModal(
                      <Delete
                        title="Are you sure you want to delete this fixture for"
                        target={{
                          name: d.customVesselNameLabel,
                          id: d.id,
                        }}
                        onDelete={(id: number, setErrors: TODO, close: () => void) => {
                          deleteFixture(d.id).then(
                            () => {
                              close();
                            },
                            (error: TODO) =>
                              setErrors({
                                errors: [
                                  {
                                    msg: error?.payload?.title ?? 'Something went wrong',
                                  },
                                ],
                              })
                          );
                        }}
                      />
                    );
                  }}
                />
              ),
            },
          ]}
        />
      </div>
    ),
  },
];
