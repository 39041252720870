import React, {FC} from 'react';
import {Icon, IconType} from '../../../../atoms/Icon';

type OnBoardingItemProps = {
  label: string;
  icon: IconType;
  done: boolean;
  className: string;
  onClick: () => void;
};

export const OnBoardingItem: FC<OnBoardingItemProps> = ({className, onClick, done, icon, label}) => (
  <div
    className={className + ' onBoarding-content-orb'}
    onClick={() => {
      if (!done) {
        onClick();
      }
    }}>
    <div className={'onBoarding-content-orb__icon' + (done ? ' done' : ' pointer')}>
      <Icon type={icon} />
    </div>
    <div className={'text' + (done ? ' done' : '')}>{label}</div>
  </div>
);
