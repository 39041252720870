import {FC} from 'react';
import DetailTableRow from '../../../../../components/DetailTable/DetailTableRow';
import DetailsTableCell from '../../../../../components/DetailTable/DetailTableCell';
import Icon from '../../../../../atoms/Icon';
import DetailTableBody from '../../../../../components/DetailTable/DetailTableBody';
import NamedAvatar from '../../../../../atoms/NamedAvatar';
import DetailTable from '../../../../../components/DetailTable';
import CompanyUser from '../../../../../model/company/CompanyUser';
import Tooltip from '../../../../../atoms/Tooltip';

type Props = {
  users?: CompanyUser[];
  onRemove: (user: CompanyUser) => void;
  isList?: boolean;
  validateRemoveUser?: (user: CompanyUser) => string | null;
};

export const CompanyListWorkspaceUser: FC<Props> = ({users, onRemove, isList, validateRemoveUser}) => {
  if (!users || users.length === 0) {
    return null;
  }

  return (
    <DetailTable fullWidth>
      <DetailTableBody>
        {users.map(user => {
          return (
            <WorkspaceUserRow
              validateRemoveUser={validateRemoveUser}
              key={user.id}
              user={user}
              onRemove={() => onRemove(user)}
              isList={isList}
            />
          );
        })}
      </DetailTableBody>
    </DetailTable>
  );
};

interface WorkspaceUserRowProps {
  user: CompanyUser;
  onRemove: () => void;
  isList?: boolean;
  validateRemoveUser?: (user: CompanyUser) => string | null;
}

const WorkspaceUserRow: FC<WorkspaceUserRowProps> = ({user, onRemove, isList, validateRemoveUser}) => {
  const errorMessage: string | null = validateRemoveUser?.(user) ?? null;
  const canRemoveThisUser = !errorMessage;

  return (
    <DetailTableRow>
      <DetailsTableCell width={'95%'}>
        <NamedAvatar name={user.fullName} imageUrl={user.avatarUrl} />
      </DetailsTableCell>
      <DetailsTableCell width={'5%'}>
        {isList ? (
          <span>&nbsp;</span>
        ) : (
          <RemoveIcon errorMessage={errorMessage} canRemoveThisUser={canRemoveThisUser} onRemove={onRemove} />
        )}
      </DetailsTableCell>
    </DetailTableRow>
  );
};

const RemoveIcon: FC<{canRemoveThisUser: boolean; onRemove: () => void; errorMessage: string | null}> = ({
  onRemove,
  canRemoveThisUser,
  errorMessage,
}) => {
  if (canRemoveThisUser) {
    return (
      <Icon type={'clear'} size={'medium'} color={'gray'} onClick={() => onRemove()} style={{cursor: 'pointer'}} />
    );
  }
  return (
    <Tooltip trigger={'mouseenter'} title={errorMessage!}>
      <Icon disabled={true} type={'clear'} size={'medium'} color={'gray'} style={{cursor: 'pointer'}} />
    </Tooltip>
  );
};
