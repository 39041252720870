import Lighthouse from '../../atoms/EmptyState/LightHouse';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {FilterCategory} from '../../api/symfony/generated';
import {
  SpotifindDatabaseFilter,
  SpotifindFilterBranchDefinitions,
} from '../../components/FilterProvider/Filters/Spotifind/SpotifindFilterBranchDefinitions';

const SpotFilter: SpotifindDatabaseFilter = {
  vesselTypes: ['bulker', 'container', 'mpp'],
  vesselStatus: {
    aisStatus: [],
    includeAisStatus: false,
  },
  vesselPosition: {
    positionUnchangedDays: 6,
    excludeInPort: true,
    excludeMoored: true,
    excludeAnchored: true,
  },
  filterParameters: {
    speedMax: 5,
  },
  vesselAttributes: {
    deadWeight: {},
    teu: {},
    teu14: {},
  },
};

export const NoSpotifindFilterBox = ({
  filterProviderApi,
}: {
  filterProviderApi: FilterProviderApi<typeof SpotifindFilterBranchDefinitions>;
}) => {
  return (
    <Lighthouse
      buttonText={'Use spot filter'}
      showCallbackButton={true}
      onClickButton={async () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        filterProviderApi.loadFilterItem({
          category: FilterCategory.Spotifind,
          id: 0,
          filterSettings: SpotFilter,
        });
      }}
      headline={'Please add filters to show results.'}
      subHeadline={''}
    />
  );
};
