import {Dayjs} from 'dayjs';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {vesselOfferApi} from '../../../../../api/symfony/symfonyApi';
import {ApiError} from '../../../../../api/utils/ApiError';
import {Location} from '../../../../../components/SearchPicker/Type';
import {MarketVessel, MarketVesselOffer} from '../../../MarketVessel/MarketVesselOffer';
import {TODO} from '../../../../../utils/TODO';

export interface VesselFormValues extends MarketVessel {
  teu: number | undefined;
  nextOpen?: [null | Dayjs, null | Dayjs];
  nextOpenLocations: Location[];
}

export const useUpdateVesselOfferMutation = (
  {vesselOffer}: {vesselOffer: MarketVesselOffer},
  options?: UseMutationOptions<TODO, ApiError, VesselFormValues>
) => {
  return useMutation({
    mutationFn: async (changes: VesselFormValues) => {
      const nextOpenFrom = changes.nextOpen?.[0] ? changes.nextOpen[0]?.toISOString() : undefined;
      const nextOpenTo = changes.nextOpen?.[1] ? changes.nextOpen[1]?.toISOString() : undefined;

      const newOffer = (await vesselOfferApi.updateVesselOffer({
        offer: vesselOffer.id,
        vesselOfferUpdateRequest: {
          vessel: {
            imo: changes.imo,
            name: changes.name,
            dwtSummer: changes.dwtSummer,
            intakes: changes.teu ? [{type: 'teu', quantity: changes.teu}] : undefined,
            lengthOverall: changes.lengthOverall,
            beam: changes.beam,
            draft: changes.draft,
            reeferPlugs: changes.reeferPlugs,
            nextOpenFrom,
            nextOpenTo,
            nextOpen: changes.nextOpenLocations.map(location => location.id),
          },
        },
      })) as MarketVesselOffer;

      return newOffer;
    },
    ...options,
  });
};
