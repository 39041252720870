/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FuelTypeEnum {
  Ifo = 'ifo',
  Mgo = 'mgo',
  Mdo = 'mdo',
}

export function FuelTypeEnumFromJSON(json: any): FuelTypeEnum {
  return FuelTypeEnumFromJSONTyped(json, false);
}

export function FuelTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelTypeEnum {
  return json as FuelTypeEnum;
}

export function FuelTypeEnumToJSON(value?: FuelTypeEnum | null): any {
  return value as any;
}
