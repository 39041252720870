import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {predictedConsumptionApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {PredictedConsumptionsResponse} from '../../../../../../api/node-backend/generated';

export const usePredictConsumptionsMutation = (
  options: UseMutationOptions<PredictedConsumptionsResponse | undefined, unknown, number>
) => {
  return useMutation({
    mutationKey: ['predictConsumptions'],
    mutationFn: async (imo: number) => {
      const result = await predictedConsumptionApi.predictConsumptionByImo({
        imo: imo,
      });

      return result;
    },
    ...options,
  });
};
