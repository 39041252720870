import {convertToFloat, convertToInt} from '../FilterConversion/utils/FilterConversionUtils';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {convertValueToNamedValue, NamedValue} from '../FilterConversion/utils/NamedValue';
import {
  floatBetweenAndGreaterOrEqualSwitchable,
  floatBetweenSwitchable,
  numberBetween,
  numberBetweenAndGreaterOrEqual,
} from '../../../../utils/validators';

export type IntakeUnit = 'cbm' | 'cft';

export const IntakeUnits: NamedValue<IntakeUnit>[] = [
  {name: 'CBM', value: 'cbm'},
  {name: 'CFT', value: 'cft'},
];

export type DatabaseType = {
  grainVolume: {
    from?: number;
    to?: number;
  };
  grainUnit?: IntakeUnit;
  baleVolume: {
    from?: number;
    to?: number;
  };
  baleUnit?: IntakeUnit;
  reeferPlugs: {
    from?: number;
    to?: number;
  };
};

export type InternalType = {
  grainFrom: string;
  grainTo: string;
  grainUnit: NamedValue<IntakeUnit> | '';
  baleFrom: string;
  baleTo: string;
  baleUnit: NamedValue<IntakeUnit> | '';
  plugsFrom: number | '';
  plugsTo: number | '';
};

export const Defaults: InternalType = {
  grainFrom: '',
  grainTo: '',
  grainUnit: {
    name: 'CBM',
    value: 'cbm',
  },
  baleFrom: '',
  baleTo: '',
  baleUnit: {
    name: 'CBM',
    value: 'cbm',
  },
  plugsFrom: '',
  plugsTo: '',
};

export const convertToString = (value: number | undefined): string => {
  if (value === undefined || value === null) {
    return '';
  }
  return value.toString();
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {grainVolume, grainUnit, baleVolume, baleUnit, reeferPlugs} = database;
  return {
    grainFrom: convertToString(grainVolume.from),
    grainTo: convertToString(grainVolume.to),
    grainUnit: convertValueToNamedValue({value: grainUnit, available: IntakeUnits, defaultValue: ''}),
    baleFrom: convertToString(baleVolume.from),
    baleTo: convertToString(baleVolume.to),
    baleUnit: convertValueToNamedValue({value: baleUnit, available: IntakeUnits, defaultValue: ''}),
    plugsFrom: reeferPlugs.from ?? '',
    plugsTo: reeferPlugs.to ?? '',
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {grainFrom, grainTo, grainUnit, baleFrom, baleTo, baleUnit, plugsFrom, plugsTo} = internal;
  return {
    grainVolume: {
      from: convertToFloat(grainFrom),
      to: convertToFloat(grainTo),
    },
    grainUnit: grainUnit ? grainUnit.value : undefined,
    baleVolume: {
      from: convertToFloat(baleFrom),
      to: convertToFloat(baleTo),
    },
    baleUnit: baleUnit ? baleUnit.value : undefined,
    reeferPlugs: {
      from: convertToInt(plugsFrom),
      to: convertToInt(plugsTo),
    },
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Intake',
  branch: 'intake',
  defaults: Defaults,
  validators: {
    grainFrom: floatBetweenSwitchable(0, 'CBM', 999999, 'CFT', 99999999, 'grainUnit'),
    grainTo: floatBetweenAndGreaterOrEqualSwitchable(0, 'CBM', 999999, 'CFT', 99999999, 'grainFrom', 'grainUnit'),
    baleFrom: floatBetweenSwitchable(0, 'CBM', 999999, 'CFT', 99999999, 'baleUnit'),
    baleTo: floatBetweenAndGreaterOrEqualSwitchable(0, 'CBM', 999999, 'CFT', 99999999, 'baleFrom', 'baleUnit'),
    plugsFrom: numberBetween(0, 99999),
    plugsTo: numberBetweenAndGreaterOrEqual(0, 99999, 'plugsFrom'),
  },
  fromDatabase,
  toDatabase,
});
