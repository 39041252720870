import {useState} from 'react';

export function useStepper(steps: $TSFixMe) {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(
    steps.reduce((completed: $TSFixMe, s: $TSFixMe, i: $TSFixMe) => {
      completed[i] = false;
      return completed;
    }, {})
  );

  const gotoStep = (i: $TSFixMe) => {
    setCompletedSteps(
      steps.reduce((completed: $TSFixMe, s: $TSFixMe, j: $TSFixMe) => {
        completed[j] = j < i;
        return completed;
      }, {})
    );
    setCurrentStep(i);
  };

  const findStepWithFirstError = (formValidationErrors: $TSFixMe, sectionMapping: $TSFixMe, steps: $TSFixMe) => {
    let step = 0;

    formValidationErrors.forEach((error: $TSFixMe) => {
      const location = error.location.match(/(\w+)\[(\d+)]\.(\w+)\[?(\d+)?]?\.?(\w+)?/);
      const fieldName = (location && location[1]) || error.location;

      steps.forEach((currentStep: $TSFixMe, index: $TSFixMe) => {
        if (currentStep['sections']) {
          currentStep['sections'].forEach((currentSection: $TSFixMe) => {
            if (sectionMapping[currentSection]) {
              sectionMapping[currentSection].forEach((currentField: $TSFixMe) => {
                if (fieldName === currentField && index > step) {
                  step = index;
                }
              });
            }
          });
        }
      });
    });

    return step;
  };

  return {currentStep, completedSteps, gotoStep, findStepWithFirstError};
}
