import {useGetCompetitors} from '../../../../hooks/useGetCompetitors/useGetCompetitors';
import {defaultFilters} from '../../../../screens/Analytics/PotentialCompetitors/FilterOptionDropdown';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {PotentialCompetitorsFormData} from '../../../../screens/Analytics/PotentialCompetitors/types';
import {useGetCompetitorsDataSource} from './useGetCompetitorsDataSource';
import {DataSourceVessel} from './generateDataSourceVessel';

export const useGetCompetitorsMutation = ({
  options,
  setProgress,
  setIsLoading,
}: {
  setProgress: (progress: number) => void;
  setIsLoading: (loading: boolean) => void;
  options?: UseMutationOptions<DataSourceVessel[], unknown, PotentialCompetitorsFormData, unknown>;
}) => {
  const getCompetitorsDataSource = useGetCompetitorsDataSource();

  const {getCompetitors} = useGetCompetitors({
    filterOptions: defaultFilters,
    onChangeIsLoading: val => setIsLoading(val),
    onChangeLogs: () => {},
    onChangeResult: () => {},
    onProgress: setProgress,
  });

  return useMutation({
    mutationFn: async (formValues: PotentialCompetitorsFormData) => {
      const resultVessels = await getCompetitors(formValues);
      return getCompetitorsDataSource({resultVessels});
    },
    ...options,
  });
};
