import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {numberBetween, numberLessThanOrEqualTo, stringLengthLessThanOrEqualTo} from '../../../../utils/validators';
import {countries} from '../../../../model/Country';

export type DatabaseType = {
  name?: string;
  minimumAge?: number;
  maximumAge?: number;
  imo?: number;
  includeFlags?: boolean;
  flags?: FlagCode[];
};

type FlagCode = string;

export type InternalType = {
  name?: string;
  minimumAge?: string | number;
  maximumAge?: string | number;
  imo?: string;
  includeFlags: boolean;
  flags: {name: string; code: string}[];
};

export const Defaults: InternalType = {
  name: '',
  minimumAge: '',
  maximumAge: '',
  imo: '',
  includeFlags: true,
  flags: [],
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    name: database.name ?? '',
    imo: database.imo ? database.imo.toString() : '',
    minimumAge: database.minimumAge ?? '',
    maximumAge: database.maximumAge ?? '',
    includeFlags: database.includeFlags ?? false,
    flags: (database.flags ?? []).map(code => countries.find(country => country.code === code)!),
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const imo = internal.imo === '' || internal.imo === undefined ? undefined : parseInt(internal.imo!);
  const minimumAge = typeof internal.minimumAge === 'string' ? parseInt(internal.minimumAge) : internal.minimumAge;
  const maximumAge = typeof internal.maximumAge === 'string' ? parseInt(internal.maximumAge) : internal.maximumAge;

  const result = {
    ...internal,
    flags: internal.flags && internal.flags.length > 0 ? internal.flags.map(flag => flag.code) : [],
    imo: imo,
    minimumAge: isNaN(minimumAge ?? NaN) ? undefined : minimumAge,
    maximumAge: isNaN(maximumAge ?? NaN) ? undefined : maximumAge,
  };

  if (imo === undefined) {
    delete result.imo;
  }

  if (minimumAge === undefined) {
    delete result.minimumAge;
  }

  if (maximumAge === undefined) {
    delete result.maximumAge;
  }
  if (result.name === '') {
    delete result.name;
  }

  return result;
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'General',
  branch: 'generalInformation',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
  validators: {
    name: stringLengthLessThanOrEqualTo(127),
    imo: numberBetween(1000000, 9999999),
    minimumAge: [numberBetween(1950, 2050), numberLessThanOrEqualTo('maximumAge')],
    maximumAge: numberBetween(1950, 2050),
  },
});
