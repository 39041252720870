import styled from 'styled-components';

interface PropertyBoxProps {
  title: string;
  backgroundColor: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

export const PropertyBox: React.FC<PropertyBoxProps> = props => (
  <PropertyContainer>
    <IconWrapper $color={props.backgroundColor}>{props.icon}</IconWrapper>
    <ContentWrapper>
      <div>
        <PropertyTitle data-cy="propertyTitle">{props.title}</PropertyTitle>
      </div>
      <div>
        <PropertyValue data-cy="propertyValue">{props.children}</PropertyValue>
      </div>
    </ContentWrapper>
  </PropertyContainer>
);

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div<{$color: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius);
  background-color: ${({$color}) => $color};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  line-height: 1.25;
`;

const PropertyTitle = styled.span`
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
`;

const PropertyValue = styled.span`
  font-size: var(--font-size-lg);
`;
