import styled from 'styled-components';

export const Title = styled.div`
  font-size: var(--font-size-3xl);
  font-weight: 600;
  line-height: 1.4;
  color: var(--color-black);
  margin: 40px 0 10px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const SubTitle = styled.div`
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-md);
  line-height: 1.4;
  color: var(--color-gray-2);
`;
