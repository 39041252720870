import {PortfolioGroupType} from '../api/symfony/generated';
import {PortfolioType} from '../redux/Portfolio';

export const mapPortfolioTypeToEnum = (type: PortfolioType): PortfolioGroupType => {
  switch (type) {
    case 'vessel':
      return PortfolioGroupType.Vessel;
    case 'cargo':
      return PortfolioGroupType.Cargo;
    default:
      throw new Error(type);
  }
};
