import LabelWrapper from '../../../../atoms/LabelWrapper';
import Input from '../../../../atoms/Input';
import {hasError} from '../../../../utils/helper';
import {filterBranchDefinition} from './PortAttributesDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const PortAttributes = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <div className="container">
        <div className="row bottom-fix">
          <div className="scol-12">
            <LabelWrapper
              label={'Name'}
              htmlFor={'filter-port-attributes-name'}
              hasError={hasError('name', validations)}>
              <Input
                id={'filter-general-name'}
                onChange={value => handleChange('name', value)}
                value={values.name}
                hasError={hasError('name', validations)}
              />
            </LabelWrapper>
          </div>
          <div className="scol-12">
            <LabelWrapper
              label={'Un/locode'}
              htmlFor={'filter-port-attributes-code'}
              hasError={hasError('code', validations)}>
              <Input
                id={'filter-port-attributes-code'}
                onChange={value => handleChange('code', value)}
                value={values.code}
                hasError={hasError('code', validations)}
              />
            </LabelWrapper>
          </div>
        </div>
      </div>
    )}
  </FilterButton>
);

export default PortAttributes;
