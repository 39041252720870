import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {openColumn} from '../../../../components/GridLayout/columns/vessel/openColumn';
import {noteColumn} from '../../../../components/GridLayout/columns/vessel/noteColumn';
import {PostedBy} from './PostedBy';
import {MarketVesselGridActionMenu} from './MarketVesselGridActionMenu';

export const getVesselColumns = ({
  isSub,
  onGridDataChange,
  getGridColumns,
}: {
  isSub: boolean;
  getGridColumns: () => CustomColumnDef<SeaboVessel>[];
  onGridDataChange: () => void;
}): CustomColumnDef<SeaboVessel>[] => [
  ...getGridColumns(),
  openColumn({type: 'market'}),
  noteColumn({type: 'market'}),
  {
    header: () => (
      <div className="datagrid-sortable-header" data-cy="header-posted">
        <div>Posted</div>
      </div>
    ),
    id: 'createdAt',
    enableSorting: true,
    accessorFn: (d: $TSFixMe) => <PostedBy circular={d} />,
    cell: info => info.getValue(),
    minWidth: 12,
    minSize: 12,
  },
  {
    id: 'vessel.actions',
    header: '',
    enableSorting: false,
    maxWidth: 48,
    maxSize: 48,
    className: 'datagrid-td-action',
    accessorFn: (offer: $TSFixMe) => (
      <MarketVesselGridActionMenu isSub={isSub} offer={offer} onGridDataChange={onGridDataChange} />
    ),
    cell: info => info.getValue(),
  },
];
