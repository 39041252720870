import {getSpeedLabel, timeDiff} from '../utils';
import {numberFormat} from '../../../utils/formatter';
import styled from 'styled-components';
import {IntersectionBox} from './IntersectionBox';
import {ResultBox} from './ResultBox';
import {Dayjs} from 'dayjs';

export const CalculationResults = ({
  seca,
  piracy,
  eta,
  etd,
  speed,
  distance,
  duration,
}: {
  seca: number | null;
  piracy: number | null;
  eta: Dayjs | null;
  etd: Dayjs | null;
  speed: number | null;
  distance: number | null;
  duration: number | null;
}) => {
  const durationResult = timeDiff(duration);
  return (
    <ResultsContainer>
      <ResultsRow>
        {!!etd && (
          <ResultBox
            label="ETD"
            labelSecondary={`UTC(${etd.format('Z')})`}
            value={etd.format('DD.MM.')}
            valueSecondary={etd.format('HH:mm')}
          />
        )}
        {!!eta && (
          <ResultBox
            label="ETA"
            labelSecondary={`UTC(${eta.format('Z')})`}
            value={eta.format('DD.MM.')}
            valueSecondary={eta.format('HH:mm')}
          />
        )}
        {!!speed && (
          <ResultBox label="Speed" value={getSpeedLabel(speed)} valueSecondary={'kts'} hasError={speed > 40} />
        )}
        {!!duration && (
          <ResultBox label="Duration" value={durationResult.value} valueSecondary={durationResult.label} />
        )}
        {!!distance && <ResultBox label="Distance" value={numberFormat(distance.toFixed(2))} valueSecondary={'NM'} />}
      </ResultsRow>
      <IntersectionsRow>
        {!!seca && <IntersectionBox label="ECA" value={`${numberFormat(seca.toFixed(2))} NM`} />}
        {!!piracy && <IntersectionBox label="HRA" value={`${numberFormat(piracy.toFixed(2))} NM`} />}
      </IntersectionsRow>
    </ResultsContainer>
  );
};

const ResultsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 100px;
  max-width: 700px;
  z-index: 1;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 13px;
  margin: 13px auto 0;
  border-radius: 3px;
  background-color: var(--color-white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const ResultsRow = styled(Row)`
  width: calc(100% - 26px);
`;

const IntersectionsRow = styled(Row)`
  width: max-content;
  margin-top: 13px;
  margin-left: 13px;
`;
