import ngeohash from 'ngeohash';
import calcRoutes, {CalcRoutesProps} from '../../../../../components/CharterCalculation/Voyagecharter/utils/calcRoutes';
import {getGeoHashesFromRouteKey} from './utils';
import lodashChunk from 'lodash/chunk';
import {DistanceCalculation} from '../../../../../api/symfony/generated';
import * as Sentry from '@sentry/browser';

/**
 * The introduction of geohashes serves to reduce the number of queries.
 * Geohashes are used to combine geographically nearby target points and starting points.
 * @param params
 */
const getDistanceForOneRoute = async (params: {routeKey: string}): Promise<DistanceCalculation> => {
  const endpoints = getGeoHashesFromRouteKey(params.routeKey);

  const startCoords = ngeohash.decode(endpoints.fromGeohash);
  const endCoords = ngeohash.decode(endpoints.toGeohash);

  const routeProps: CalcRoutesProps = {
    routes: [
      {
        coordinates: {
          start: {lat: startCoords.latitude, lon: startCoords.longitude},
          end: {lat: endCoords.latitude, lon: endCoords.longitude},
        },
      },
    ],

    options: {
      avoidPiracyAreas: false,
      avoidSECA: false,
      allowKielCanal: true,
      allowSuezCanal: true,
      allowPanamaCanal: true,
    },
  };

  const distances = await calcRoutes(routeProps);

  const distance = distances[0];

  if (!distance.distance) {
    Sentry.captureException(
      new Error(`No distance found for route ${params.routeKey} - RouteProps: ${JSON.stringify(routeProps)}`)
    );

    return {
      distance: Infinity,
      duration: 0,
      piracyIntersection: 0,
      secaIntersection: 0,
      waypoints: [],
    };
  }
  return distance;
};
const chunkSize = 10;

export type DistanceMap = Record<string, DistanceCalculation>;

export const generateDistanceMap = async ({
  routeKeys,
  onChangeProgressInPercent,
}: {
  routeKeys: string[];
  onChangeProgressInPercent: (percent: number) => void;
}): Promise<DistanceMap> => {
  const map: DistanceMap = getDistanceMapFromSessionStorage();

  const missingRouteKeys = routeKeys.filter(routeKey => !map[routeKey]);

  const geoHashChucks = lodashChunk(missingRouteKeys, chunkSize);

  for (let index = 0; index < geoHashChucks.length; index++) {
    await Promise.all(
      geoHashChucks[index].map(async routeKey => {
        map[routeKey] = await getDistanceForOneRoute({routeKey});
      })
    );
    onChangeProgressInPercent(Math.floor((index / geoHashChucks.length) * 100));
  }
  try {
    sessionStorage.setItem(DISTANCE_MAP_SESSION_STORAGE_KEY, JSON.stringify(map));
  } catch (e) {
    if (e instanceof DOMException) {
      if (e.name === 'QuotaExceededError') {
        return map;
      }
    }
    throw e;
  }

  return map;
};

const DISTANCE_MAP_SESSION_STORAGE_KEY = 'distanceMap';

const getDistanceMapFromSessionStorage = () => {
  const distanceMapFromSessionStorage = sessionStorage.getItem(DISTANCE_MAP_SESSION_STORAGE_KEY);
  if (distanceMapFromSessionStorage) {
    return JSON.parse(distanceMapFromSessionStorage);
  }
  return {};
};
