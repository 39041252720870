import React, {FC, useEffect} from 'react';
import styled from 'styled-components';
import {countryCodeToFlag} from './countryCodeToFlag';
import * as Sentry from '@sentry/browser';

interface Props {
  countryCode?: string | null;
  className?: string;
  width?: number;
  height?: number;
  shadow?: boolean;
  border?: boolean;
  keepWidthWhenEmpty?: boolean;
  showFallbackFlag?: boolean;
}

export const Flag: FC<Props> = ({
  countryCode = '',
  width = 16,
  height = 12,
  className,
  shadow = false,
  border = false,
  keepWidthWhenEmpty = false,
  showFallbackFlag = false,
}: Props) => {
  useEffect(() => {
    if (!!countryCode && typeof countryCode !== 'string') {
      Sentry.captureException(new Error(`Invalid countryCode: ${countryCode}`));
    }
  }, [countryCode]);

  if (!countryCode) {
    countryCode = '';
  }

  if (typeof countryCode !== 'string') {
    countryCode = '';
  }
  const flag = countryCodeToFlag[countryCode.toLowerCase()];
  if (countryCode && !!flag) {
    return (
      <img
        className={className}
        src={flag}
        alt={countryCode}
        width={width}
        height={height}
        style={{
          boxShadow: shadow ? '0 0 2px 0 rgba(0,0,0,0.4)' : undefined,
          outline: border ? '1px solid #ccc' : undefined,
        }}
      />
    );
  }

  if (showFallbackFlag) {
    return (
      <FallbackFlag width={width} height={height} className={className}>
        ?
      </FallbackFlag>
    );
  }

  if (keepWidthWhenEmpty) {
    return <FallbackWidth width={width} height={height} />;
  }

  if (countryCode) {
    return <>{countryCode}</>;
  }

  return null;
};

const FallbackFlag = styled.span<{width: number; height: number}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: inline-block;
  padding: 1px;
  background: var(--color-gray-3);
  font-size: var(--font-size-xs);
  color: var(--color-gray-1);
  text-align: center;
  line-height: 1;
`;

const FallbackWidth = styled.span<{width: number; height: number}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: inline-block;
`;

export default Flag;
