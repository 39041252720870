import {PolygonLayer} from '@deck.gl/layers/typed';
import {VoyageAnimationPortVisit} from '../../model/VoyageAnimationPortVisit';
import {interpolate} from '../../utils/interpolate';

export const usePortAreaLayer = (
  currentPortVisit: VoyageAnimationPortVisit | undefined,
  portAreaVisibility: number,
  portAreaLayerVisible: boolean
) => {
  const data = currentPortVisit ? [currentPortVisit] : [];

  const layer = new PolygonLayer<VoyageAnimationPortVisit>({
    id: 'port-area-layer',
    data,
    visible: portAreaLayerVisible,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 2,
    getPolygon: d => d.port.polygon[0],
    getFillColor: [245, 124, 0, portAreaVisibility > 0 ? interpolate(0, 60, portAreaVisibility) : 0],
    getLineColor: [245, 124, 0, portAreaVisibility > 0 ? interpolate(150, 250, portAreaVisibility) : 0],
    getLineWidth: 1,
  });

  return layer;
};
