import React, {FC, useMemo, useState} from 'react';
import {
  NegotiationSummary,
  NegotiationRoundResult,
  NegotiationStatus,
  NegotiationRoundResultModification,
  NegotiationSubjectStatus,
} from '../../../../../../api/node-backend/generated';
import styled from 'styled-components';
import {SubjectActionsHook} from '../../Subjects/utils/useGetSubjectActionsWithMutations';
import {ListHeader} from '../ListHeader';
import {HideTermsCheckbox} from '../shared';
import {Tooltip} from 'antd';
import {combineWithSubjectModification, getIsSubjectSettledAndCommitted} from '../utils';
import sortBy from 'lodash/sortBy';
import {SubjectItem} from '../../Subjects/SubjectItem';

type GlobalSubjectsProps = {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  roundResultModifications: NegotiationRoundResultModification;
  actionsBusy?: boolean;
  getSubjectActions: SubjectActionsHook;
};

export const GlobalSubjects: FC<GlobalSubjectsProps> = ({
  negotiation,
  roundResult,
  roundResultModifications,
  actionsBusy,
  getSubjectActions,
}) => {
  const {status} = negotiation;
  const {committedAt, subjects} = roundResult;

  const [hideSettledSubjects, setHideSettledSubjects] = useState(false);

  const negotiationCanBeEdited = ![NegotiationStatus.Cancelled, NegotiationStatus.Settled].includes(status);
  const roundCanBeEdited = negotiationCanBeEdited && committedAt === null;
  const isOfferOrSettled = [NegotiationStatus.Offer, NegotiationStatus.Settled, NegotiationStatus.Cancelled].includes(
    status
  );

  const subjectsWithModifications = useMemo(() => {
    const {subjects} = roundResult;
    let subjectsWithModifications = subjects.map(combineWithSubjectModification(roundResultModifications));
    if (hideSettledSubjects) {
      subjectsWithModifications = subjectsWithModifications.filter(item => !getIsSubjectSettledAndCommitted(item));
    }
    if (negotiation.status === NegotiationStatus.Recap) {
      subjectsWithModifications = subjectsWithModifications.filter(
        ({subject}) => subject.status !== NegotiationSubjectStatus.Rejected
      );
    }
    subjectsWithModifications = sortBy(subjectsWithModifications, ({subject}) => subject.party);
    return subjectsWithModifications;
  }, [roundResult, roundResultModifications, hideSettledSubjects, negotiation.status]);

  if (!subjects.length) {
    return null;
  }

  return (
    <GlobalSubjectsContainer data-testid="GlobalSubjects">
      <ListHeader
        indexLabel=""
        itemLabel="Subjects"
        additionalContent={
          !isOfferOrSettled ? (
            <HideTermsCheckbox
              checked={hideSettledSubjects}
              onChange={event => setHideSettledSubjects(event.target.checked)}>
              <Tooltip title="Includes previously rejected and lifted subjects">Hide agreed subjects</Tooltip>
            </HideTermsCheckbox>
          ) : null
        }
      />
      {subjectsWithModifications.map(subjectWithModification => (
        <SubjectItem
          key={subjectWithModification.subject.id}
          subjectWithModifications={subjectWithModification}
          negotiation={negotiation}
          roundCanBeEdited={roundCanBeEdited}
          actionsBusy={actionsBusy}
          getSubjectActions={getSubjectActions}
        />
      ))}
    </GlobalSubjectsContainer>
  );
};

export const GlobalSubjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
