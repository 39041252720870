import {FC} from 'react';
import styled from 'styled-components';
import {NegotiationReplyTime} from '../../../../api/node-backend/generated';
import {UserDateTime} from '../../../../components/DateTime/UserDateTime';
import {formatReplyTimeDuration} from './utils';

type ReplyTimeDraftLabelProps = {
  replyTime?: NegotiationReplyTime | null;
  prefix?: string;
  suffix?: string;
  emptyText?: string;
};

export const ReplyTimeDraftLabel: FC<ReplyTimeDraftLabelProps> = ({
  replyTime,
  prefix = 'Scheduled for ',
  suffix = '.',
  emptyText = 'No reply time scheduled yet.',
}) => {
  const {duration, timestamp} = replyTime ?? {};

  if (!duration && !timestamp) {
    return (
      <ReplyTimeDraftLabelContainer data-testid="ReplyTimeDraftLabel">
        <NoWrap>{emptyText}</NoWrap>
      </ReplyTimeDraftLabelContainer>
    );
  }

  return (
    <ReplyTimeDraftLabelContainer data-testid="ReplyTimeDraftLabel">
      {prefix && <NoWrap>{prefix}</NoWrap>}
      <NoWrap>
        {timestamp && <UserDateTime value={new Date(timestamp).toISOString()} format="dateTime" />}
        {duration && formatReplyTimeDuration(duration, false)}
      </NoWrap>
      {suffix && <NoWrap>{suffix}</NoWrap>}
    </ReplyTimeDraftLabelContainer>
  );
};

const ReplyTimeDraftLabelContainer = styled.span`
  cursor: pointer;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;
