import React, {FC, useState} from 'react';
import Lighthouse from '../../atoms/EmptyState/LightHouse';
import {notificationApi} from '../../api/symfony/symfonyApi';
import {invalidateNotificationsQuery, useNotificationsQuery} from '../../queries/useNotificationsQuery';
import {Notification} from '../../api/symfony/generated';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {ApiError} from '../../api/utils/ApiError';
import {notificationColumns} from './notificationColumns';
import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import './style.scss';
import {useLocalStorage} from '../../utils/useLocalStorage';
import {DataGrid} from '../../components/DataGrid/DataGrid';
import {ConfirmationModal} from '../../components/Modal/ConfirmationModal';
import {useGoToUrl} from '../../utils/useChangeBrowserUrl';

export const NotificationsScreen: FC = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [acknowledged, setAcknowledged] = useState<boolean | undefined>(false);
  const [pageSize, setPageSize] = useLocalStorage('notifications_page_size', 10);
  const [markAllAsReadModalOpen, setMarkAllAsReadModalOpen] = useState(false);
  const goToUrl = useGoToUrl();

  const queryClient = useQueryClient();

  const onChangeAcknowledged = (viewAcknowledged: boolean | undefined) => {
    setAcknowledged(viewAcknowledged);
    setPageNumber(1);
  };

  const notificationsQuery = useNotificationsQuery({
    pageNumber: 1,
    acknowledged,
    pageSize: 10000,
  });
  const acknowledgeAllMutation = useMutation<void, ApiError, void>({
    mutationFn: () => notificationApi.acknowledgeAllNotifications(),
    onSuccess: async () => {
      await invalidateNotificationsQuery(queryClient);
    },
  });

  if (!notificationsQuery.isSuccess) {
    return null;
  }

  const notifications: Notification[] = notificationsQuery.data.items;

  return (
    <div className={'notifications-screen'}>
      {markAllAsReadModalOpen && (
        <ConfirmationModal
          open
          description={'Do you really want to mark all notifications as read?'}
          okText={'Yes'}
          onConfirm={() => {
            acknowledgeAllMutation.mutate();
          }}
          onClose={() => {
            setMarkAllAsReadModalOpen(false);
          }}
        />
      )}
      <ScreenHeader
        helmetTitle="Notifications"
        breadcrumbs={[{title: 'Notifications'}]}
        actions={
          <ScreenHeader.Actions.Button
            disabled={notifications.every(notification => notification.isRead)}
            data-cy="NotificationsMarkAllReadBTN"
            onClick={() => {
              setMarkAllAsReadModalOpen(true);
            }}>
            Mark all as Read
          </ScreenHeader.Actions.Button>
        }
        features={
          <ScreenHeader.Features.Toggle
            id="section-select"
            value={acknowledged}
            onChange={e => onChangeAcknowledged(e.target.value)}
            options={[
              {
                id: 'section-all',
                label: 'All',
                value: true,
                props: {
                  'data-cy': 'NotificationsShowAllBTN',
                },
              },
              {
                id: 'section-unread',
                label: 'Unread',
                value: false,
                props: {
                  'data-cy': 'NotificationsShowUnreadBTN',
                },
              },
            ]}
          />
        }
      />
      <DataGrid
        responsive
        hideHeader={true}
        columns={notificationColumns(goToUrl)}
        data={notifications}
        loading={notificationsQuery.isLoading}
        noHoverStatePointer
        manualPagination={false}
        zeroBasedPageIndex={false}
        noHoverStateBackgroundEffect
        page={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={(pageSize: number) => {
          setPageSize(pageSize);
          setPageNumber(1);
        }}
        pageSize={pageSize}
        totalCount={notificationsQuery.data?.totalItems ?? 0}
        TbodyComponent={
          notificationsQuery.isSuccess && notificationsQuery.data.totalItems === 0 ? (
            <Lighthouse headline={'There are no notifications at the moment.'} />
          ) : null
        }
      />
    </div>
  );
};
