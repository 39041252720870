import {Button} from '../../../Button/Button';

import '../style.scss';

type Props = {
  goToAll: $TSFixMeFunction;
};

const EmptyStateSubPortfolio = ({goToAll}: Props) => (
  <div id="vessel-empty-state-sub-container" className="empty-state-container-sub">
    <div className={'empty-state-container-sub__header'}>Adding vessels to a vessel list</div>
    <div className={'empty-state-container-sub__images'}>
      <div className={'empty-state-context-container'}>
        <img
          className={'empty-state-image-container-sub__image'}
          src="/static/images/seanexxt/portfolio/empty-sub-portfolio-vessel-1.png"
          alt="empty-sub-portfolio-vessel-1"
          style={{margin: 'auto'}}
        />
      </div>
      <div className={'empty-state-context-container'}>
        <img
          className={'empty-state-image-container-sub__image'}
          src="/static/images/seanexxt/portfolio/empty-sub-portfolio-vessel-2.png"
          alt="empty-sub-portfolio-vessel-2"
          style={{margin: 'auto'}}
        />
      </div>
      <div className={'empty-state-context-container'}>
        <img
          className={'empty-state-image-container-sub__image'}
          src="/static/images/seanexxt/portfolio/empty-sub-portfolio-vessel-3.png"
          alt="empty-sub-portfolio-vessel-3"
          style={{margin: 'auto'}}
        />
      </div>
      <div className={'empty-state-context-container'}>
        Go to <br />
        All vessels
      </div>
      <div className={'empty-state-context-container'}>
        On a selected vessel,
        <br /> choose "Manage vessel lists"
        <br />
        from the 3-dot menu
      </div>
      <div className={'empty-state-context-container'}>
        Select a vessel list
        <br /> to add the vessel to
      </div>
    </div>
    <Button id={'empty-state-action-button'} onClick={goToAll} label={'GO TO ALL VESSELS'} bold upper primary />
  </div>
);

export default EmptyStateSubPortfolio;
