import Country from '../model/Country';
import inRange from 'lodash/inRange';
import {ValidateFunctionOnlyValueAndSectionValues, ValidateFunctionOnlyValue, Validator} from './Validator';
import {TODO} from './TODO';
import {isImoValid} from './isImoValid';
import dayjs from 'dayjs';

export const required = (msg = 'This field is required'): ValidateFunctionOnlyValue => {
  return val => {
    if (typeof val === 'string') {
      return !val.trim() && msg;
    } else {
      return !val && msg;
    }
  };
};
export const isImo = (msg = 'Must be a valid IMO number'): ValidateFunctionOnlyValue => {
  return val => {
    if (val === null || val === undefined || val === '') {
      return false;
    }

    if (isImoValid(val)) {
      return false;
    }
    return msg;
  };
};

export const email = (msg = 'Please enter a valid email'): ValidateFunctionOnlyValue => {
  return val =>
    !!val &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      val
    ) &&
    msg;
};

export const phone = (msg = 'Please enter a valid phone number'): ValidateFunctionOnlyValue => {
  const phoneRegex =
    /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/;
  return val => !!val && !phoneRegex.test(val.replace(/\s/g, '')) && msg;
};

export const url = (msg = 'Please enter a valid URL'): ValidateFunctionOnlyValue => {
  const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return val => !!val && !urlRegex.test(val) && msg;
};

export const slug = (
  msg = 'Please enter a valid URL using only letters, numbers or hyphens (-)'
): ValidateFunctionOnlyValue => {
  return val => !!val && !/^[A-Za-z0-9-]+$/i.test(val) && msg;
};

export const number = (msg = 'Must be a number'): ValidateFunctionOnlyValue => {
  return val => !!val && isNaN(val) && msg;
};

export const alphaNumeric = (msg = 'Field must be only letters or numbers'): ValidateFunctionOnlyValue => {
  return val => !!val && !/^[A-Za-z0-9]+$/i.test(val) && msg;
};

export const alpha = (msg = 'Field must be only letters'): ValidateFunctionOnlyValue => {
  return val => !!val && !/^[A-Za-z]+$/i.test(val) && msg;
};

export const alphaNumericSpecial = (msg = 'No special characters allowed'): ValidateFunctionOnlyValue => {
  return val => !!val && !/^[A-Za-z0-9'_./#&+\-\s]+$/i.test(val) && msg;
};

export const equals = (value: TODO, msg = `Field must be equal to ${value}`): ValidateFunctionOnlyValue => {
  return val => !!val && val !== value && msg;
};

export const equalsField = (
  field: string,
  msg = `Field must be equal to ${field}`
): ValidateFunctionOnlyValueAndSectionValues => {
  return (val, values) => !!val && val !== values[field] && msg;
};

export const greaterThan = (min = 0, msg?: string): ValidateFunctionOnlyValue => {
  return (val: number | string | undefined) => {
    switch (typeof val) {
      case 'undefined':
        return false;
      case 'number':
        return val <= min && (msg || `Number must be greater than ${min}`);
      default:
        return !!val && val.length <= min && (msg || `Must have a length greater than ${min}`);
    }
  };
};

export const greaterThanOrEqualTo = (min = 0, msg?: string): ValidateFunctionOnlyValue => {
  return (val: number | string | undefined) => {
    switch (typeof val) {
      case 'undefined':
        return false;
      case 'number':
        return val < min && (msg ?? `Number must be greater than or equal to ${min}`);
      default:
        return !!val && val.length < min && (msg ?? `Must have a length greater than or equal to ${min}`);
    }
  };
};

export const lessThan = (max = 0, msg: string): ValidateFunctionOnlyValue => {
  return val => {
    switch (typeof val) {
      case 'undefined':
        return false;
      case 'number':
        return val >= max && (msg || `Number must be less than ${max}`);
      default:
        return !!val && val.length >= max && (msg || `Must have a length less than ${max}`);
    }
  };
};

export const numberBetween =
  (start: number, end: number, msg = `Must be a number between ${start} and ${end}`): ValidateFunctionOnlyValue =>
  val => {
    const valid = !!val && (parseFloat(val) != val || !inRange(parseFloat(val), start, end + 1)) && msg; // eslint-disable-line eqeqeq
    return valid;
  };

export const floatBetween =
  (start: number, end: number, msg = `Must be a number between ${start} and ${end}`): ValidateFunctionOnlyValue =>
  val =>
    !!val && (parseFloat(val) != val || !inRange(parseFloat(val), start, end + 1)) && msg; // eslint-disable-line eqeqeq

export const lessThanOrEqualTo = (max = 0, msg: string): ValidateFunctionOnlyValue => {
  return val => {
    switch (typeof val) {
      case 'undefined':
        return false;
      case 'number':
        return val > max && (msg || `Number must be less than or equal to ${max}`);
      default:
        return !!val && val.length < max && (msg || `Must have a length less than or equal to ${max}`);
    }
  };
};

export const stringLengthLessThanOrEqualTo = (
  max = 0,
  msg = `Must have a length less than or equal to ${max}`
): ValidateFunctionOnlyValue => {
  return val => lessThanOrEqualTo(max, msg)(val && val.length);
};

export const stringLengthGreaterThanOrEqualTo = (min = 0, msg: string): ValidateFunctionOnlyValue => {
  return val => greaterThanOrEqualTo(min, msg)(val && val.length);
};

export const geoCoordinateLatitude = (
  msg = 'This must be a valid Latitude Coordinate betweem -90 and 90'
): ValidateFunctionOnlyValue => {
  const latReg = new RegExp('(^(\\+?|-)[0-8][0-9]?(?:\\.\\d{1,6})?$)|(^(\\+?|-)\\d{1,2}$)');
  return val => {
    return !latReg.test(val) ? msg : false;
  };
};

export const geoCoordinateLongitude = (
  msg = 'This must be a valid Longitude Coordinate betweem -180 and 180'
): ValidateFunctionOnlyValue => {
  return val => {
    const lonReg = new RegExp('^(\\+|-)?(\\d+).?(\\d+)?$');

    if (!lonReg.test(val)) {
      return msg;
    }
    const longitude = parseFloat(val);
    if (isNaN(longitude)) {
      return msg;
    }
    return !(longitude > -180 && longitude < 180) ? msg : false;
  };
};

export const countryCodeValidator = (msg = 'This Country is not valid'): ValidateFunctionOnlyValue => {
  return val => {
    if (!Country.codeExist(val)) {
      return msg;
    }
    return false;
  };
};

export const numberSmallerThan =
  (field: string, msg = 'Min must be smaller than max.'): ValidateFunctionOnlyValueAndSectionValues =>
  (value, section) => {
    if (!isNaN(parseInt(value)) && !isNaN(parseInt(section[field]))) {
      if (parseInt(value) > parseInt(section[field])) {
        return msg;
      }
    }
    return false;
  };

export const rowNumberSmallerThan =
  (filedToCompare: string, _?: unknown, msg = 'Min must be smaller than max.'): Validator =>
  (valueOfSubField, sectionValues, field, index) => {
    if (!isNaN(parseInt(valueOfSubField)) && !isNaN(parseInt(sectionValues[field][index][filedToCompare]))) {
      if (parseInt(valueOfSubField) > parseInt(sectionValues[field][index][filedToCompare])) {
        return msg;
      }
    }
    return false;
  };

export const dateEarlierThanOrEqualTo =
  (field: string, msg = 'Min date must be earlier than max date.'): ValidateFunctionOnlyValueAndSectionValues =>
  (value, section) => {
    if (value && section[field]) {
      const minValue = dayjs(value);
      const maxValue = dayjs(section[field]);
      if (maxValue.isBefore(minValue)) {
        return msg;
      }
    }
    return false;
  };

export const numberLessThanOrEqualTo =
  (
    field: string,
    msg = 'Max value must be greater than or equal to min value'
  ): ValidateFunctionOnlyValueAndSectionValues =>
  (value, section) => {
    if (value && section[field]) {
      const minValue = parseFloat(value);
      const maxValue = parseFloat(section[field]);
      if (maxValue < minValue) {
        return msg;
      }
    }
    return false;
  };

export const selectUnitFor =
  (field: string, msg = 'Please select a unit'): ValidateFunctionOnlyValueAndSectionValues =>
  (value, section) =>
    String(section[field]).trim() !== '' && !value && msg;

export const floatBetweenAndGreaterOrEqualSwitchable =
  (
    start: number,
    firstSelectionUnit: string,
    firstSelection: number,
    sectionSelectionUnit: string,
    sectionSelection: number,
    field: string,
    fieldUnit: string
  ): ValidateFunctionOnlyValueAndSectionValues =>
  (value, sectionValues) => {
    if (!sectionValues[fieldUnit] || sectionValues[fieldUnit].name === firstSelectionUnit) {
      const numberCheck = floatBetween(start, firstSelection)(value);
      const greaterOrEqualCheck =
        parseFloat(value) < parseFloat(sectionValues[field]) &&
        `Number must be greater than or equal to ${parseFloat(sectionValues[field])}`;

      if (numberCheck) {
        return numberCheck;
      }
      if (greaterOrEqualCheck) {
        return greaterOrEqualCheck;
      }
    } else if (sectionValues[fieldUnit].name === sectionSelectionUnit) {
      const numberCheck = floatBetween(start, sectionSelection)(value);
      const greaterOrEqualCheck =
        parseFloat(value) < parseFloat(sectionValues[field]) &&
        `Number must be greater than or equal to ${parseFloat(sectionValues[field])}`;

      if (numberCheck) {
        return numberCheck;
      }
      if (greaterOrEqualCheck) {
        return greaterOrEqualCheck;
      }
    }

    return false;
  };

export const numberBetweenAndGreaterOrEqual =
  (start: number, end: number, field: string): ValidateFunctionOnlyValueAndSectionValues =>
  (value, section) => {
    const numberCheck = numberBetween(start, end, undefined)(value);
    const greaterOrEqualCheck = greaterThanOrEqualTo(section[field], undefined)(value);
    if (numberCheck) {
      return numberCheck;
    }
    if (greaterOrEqualCheck) {
      return greaterOrEqualCheck;
    }

    return false;
  };

export const floatBetweenAndGreaterOrEqual =
  (start: number, end: number, field: string): ValidateFunctionOnlyValueAndSectionValues =>
  (value, section) => {
    const numberCheck = floatBetween(start, end)(value);
    const greaterOrEqualCheck =
      parseFloat(value) < parseFloat(section[field]) &&
      `Number must be greater than or equal to ${parseFloat(section[field])}`;

    if (numberCheck) {
      return numberCheck;
    }
    if (greaterOrEqualCheck) {
      return greaterOrEqualCheck;
    }

    return false;
  };

export const floatBetweenSwitchable =
  (
    start: number,
    firstSelectionUnit: string,
    firstSelection: number,
    sectionSelectionUnit: string,
    sectionSelection: number,
    fieldUnit: string
  ): Validator =>
  (value: string, section: TODO) => {
    if (
      !section[fieldUnit] ||
      section[fieldUnit] === firstSelectionUnit ||
      section[fieldUnit].name === firstSelectionUnit
    ) {
      return floatBetween(start, firstSelection)(value);
    } else if (section[fieldUnit] === sectionSelectionUnit || section[fieldUnit].name === sectionSelectionUnit) {
      return floatBetween(start, sectionSelection)(value);
    }
    return false;
  };

export const requiredNumberBetween =
  (start: number, end: number): ValidateFunctionOnlyValue =>
  value => {
    const requiredError = required()(value);
    const numberError = numberBetween(start, end, undefined)(value);
    return requiredError ? requiredError : numberError ?? false;
  };

export const alwaysValid: Validator = (): false => false;
