import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {Tag} from '../../../../api/symfony/generated/models';
import {ApiError} from '../../../../api/utils/ApiError';
import {tagApi} from '../../../../api/symfony/symfonyApi';
import {CreateTagRequest} from '../../../../api/symfony/generated';

export const useCreateTagMutation = (options: UseMutationOptions<Tag, ApiError, CreateTagRequest>) => {
  return useMutation<Tag, ApiError, CreateTagRequest>({
    mutationFn: async (request: CreateTagRequest) => await tagApi.createTag(request),
    ...options,
  });
};
