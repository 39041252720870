import {ConsumptionTransaction} from './ConsumptionTransaction';
import sortBy from 'lodash/sortBy';
import {ConsumptionFuelType} from '../getUsedFuelType';
import {getCostsForConsumption} from './getCostsForConsumption/getCostsForConsumption';
import dayjs from 'dayjs';

export type BunkerBalanceAnalysisResult = {
  quantityAtStart: number;
  quantityAtEnd: number;

  totalUsage: number;
  coveredUsage: number;
  uncoveredUsage: number;
  isAnyTimeUncovered: boolean;
  uncoveredCosts: number;
  coveredCosts: number;
  totalCosts: number;
};

export const getBunkerBalanceForTimePeriod = ({
  transactions,
  fuelType,
  start,
  end,
  costPerUncoveredUnit,
}: {
  transactions: ConsumptionTransaction[];
  fuelType: ConsumptionFuelType;
  start: Date;
  end: Date;
  costPerUncoveredUnit: number;
}): BunkerBalanceAnalysisResult => {
  const filteredTransactions = transactions.filter(transaction => transaction.fuelType === fuelType);
  const sortedTransactions = sortBy(filteredTransactions, transaction => {
    return transaction.date && dayjs(transaction.date).isValid() ? `B${transaction.date.toISOString()}` : 'A';
  });

  let coveredUsage = 0;
  let uncoveredUsage = 0;
  let availableQuantity = 0;
  let isAnyTimeUncovered = false;
  let quantityAtStart = 0;

  sortedTransactions.forEach(transaction => {
    // After this point, we are only interested in transactions that happened during the time period
    if (transaction.date && transaction.date > end) {
      return;
    }

    // if transaction is before start, the usage is not relevant, but we need to update the available quantity
    if (!transaction.date || transaction.date < start) {
      quantityAtStart += transaction.quantity;
      availableQuantity += transaction.quantity;

      return;
    }

    if (transaction.quantity > 0) {
      availableQuantity += transaction.quantity;
      return;
    }

    // Usage in the current period

    if (availableQuantity <= 0) {
      uncoveredUsage += Math.abs(transaction.quantity);
      availableQuantity += transaction.quantity;
      isAnyTimeUncovered = true;
      return;
    }

    if (Math.abs(transaction.quantity) < availableQuantity) {
      coveredUsage += Math.abs(transaction.quantity);
      availableQuantity += transaction.quantity;
      return;
    }

    // we can cover a part of the usage
    const coveredPart = availableQuantity;
    coveredUsage += coveredPart;
    uncoveredUsage += Math.abs(transaction.quantity) - coveredPart;
    availableQuantity = -uncoveredUsage;
    isAnyTimeUncovered = true;
  });

  const uncoveredCosts = uncoveredUsage * costPerUncoveredUnit;

  const coveredCosts = getCostsForConsumption({
    dateOfConsumption: start,
    sortedTransactions,
    accountingMethod: 'AVG',
    quantity: coveredUsage,
  });

  const totalUsage = coveredUsage + uncoveredUsage;
  const totalCosts = uncoveredCosts + coveredCosts;
  return {
    quantityAtStart,
    quantityAtEnd: availableQuantity,
    totalUsage,
    isAnyTimeUncovered,
    uncoveredUsage,
    coveredUsage,
    coveredCosts,
    uncoveredCosts,
    totalCosts,
  };
};
