import classNames from 'classnames';
import {FC, useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {Link} from 'react-router-dom';
import {UserCard} from '../../../api/symfony/generated';
import Avatar from '../../../atoms/Avatar';
import {ContactButton} from '../../../atoms/ConnectionButtons/ContactButton';
import Lighthouse from '../../../atoms/EmptyState/LightHouse';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import ContactsService from '../../../redux/ApiService/ContactsService';
import {ModalActions} from '../../../redux/Modal';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import EmptyStateImage from './empty_contactlist.jpg';
import {Recommendations} from './Recommendations/Recommendations';
import {Recommendation} from './Recommendations/RecommendationsTransition';
import {DataGrid} from '../../../components/DataGrid/DataGrid';
import {SOCIAL_USER_QUERY_KEY, useSocialUserQuery} from '../../../queries/useSocialUserQuery';
import {getUser} from '../../../redux/selectors';
import {AddContact} from '../../../components/Modal/AddContact/AddContact';

export const ContactList: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser)!;
  const recommendations = useSelector(state => state.api.contacts.getFriendRecommendations.data);
  const recommendationsItems: Recommendation[] = recommendations?.data?.items;

  const queryClient = useQueryClient();

  const socialUserQuery = useSocialUserQuery(user.id);

  const invalidateSocialUserQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: [SOCIAL_USER_QUERY_KEY, user.id],
    });
  };

  const loading = socialUserQuery.isLoading;
  const friends: UserCard[] = socialUserQuery.isSuccess ? socialUserQuery.data.friends : [];

  const getFriendRecommendations = () => dispatch(ContactsService.getFriendRecommendations(8));
  useEffect(() => {
    !recommendationsItems && getFriendRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendations]);

  const displayModal = () => {
    dispatch(
      ModalActions.show(
        <AddContact
          afterUpdate={async () => {
            await invalidateSocialUserQuery();
            getFriendRecommendations();
          }}
        />
      )
    );
  };

  return (
    <div className={'contacts'}>
      <ScreenHeader
        helmetTitle="Contacts"
        breadcrumbs={[{title: 'Contacts'}, {title: 'Contact list'}]}
        actions={
          <ScreenHeader.Actions.Button onClick={displayModal} data-cy="ContactsAddBTN">
            Add contacts
          </ScreenHeader.Actions.Button>
        }
      />
      <DataGrid
        responsive
        id={'contacts-grid'}
        hideHeader
        columns={[
          {
            header: '',
            id: 'user',
            accessorFn: (userCard: UserCard) => (
              <div style={{display: 'flex'}}>
                <div
                  className={classNames('contacts-grid__border', {
                    // 'contacts-grid__border--contact': d.currentUserFriendStatus === 'friends',
                  })}>
                  {' '}
                </div>
                <div className={'contacts-grid__user'}>
                  <Link to={`/user/${userCard.userID}`}>
                    <Avatar imageUrl={userCard.avatar} size={'large'} />
                    <div style={{marginLeft: 15, marginTop: 7}}>
                      <span className={'contacts-grid__user-name'} style={{fontWeight: 'bold'}}>
                        {userCard.fullName}
                      </span>
                      <div>{userCard.company ? userCard.company.name : 'No company'}</div>
                    </div>
                  </Link>
                </div>
              </div>
            ),
            cell: info => info.getValue(),
            minWidth: 14,
            enableSorting: false,
          },
          {
            header: '',
            id: 'button',
            accessorFn: (userCard: UserCard) => (
              <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '10px'}}>
                <ContactButton
                  connectedState={userCard.currentUserFriendStatus}
                  userID={userCard.userID}
                  style={{marginRight: 8}}
                />
              </div>
            ),
            cell: info => info.getValue(),
            enableSorting: false,
            minWidth: 32,
            maxWidth: 220,
          },
        ]}
        data={friends}
        loading={loading}
        zeroBasedPageIndex={false}
        noHoverStatePointer
        noHoverStateBackgroundEffect
        page={1}
        pageSize={10}
        totalCount={friends.length}
        showPagination={false}
        NoDataComponent={
          <Lighthouse
            style={{marginTop: 0}}
            onClickButton={displayModal}
            buttonText={'Add contacts'}
            headline={"Let's add your first contacts!"}
            subHeadline={`We want to help you establish a strong network. Invite and securely add relevant connections.`}
            showCallbackButton
            alternativeImage={EmptyStateImage}
          />
        }
      />
      <Recommendations recommendations={recommendationsItems} showMore={getFriendRecommendations} />
    </div>
  );
};
