import {FC, ReactNode} from 'react';
import {CompanyWorkspace as CompanyWorkspaceType} from '../../../../api/symfony/generated/models/CompanyWorkspace';
import {CompanyWorkspace} from './CompanyWorkspace/CompanyWorkspace';
import {CompanyWorkspaceEmptyState} from './CompanyWorkspaceEmptyState/CompanyWorkspaceEmptyState';
import {StyledSettingsSection, StyledSettingsSectionWrapper} from './shared';
import CreateWorkspace from '../../../../components/Modal/CreateWorkspace';

interface ActiveWorkspacesListProps {
  workspaces: CompanyWorkspaceType[];
  handleWorkspaceEdit: (id: number) => void;
  addMembers: (id: number) => void;
  handleWorkspaceInactive: (id: number) => void;
  inactiveWorkspaces: CompanyWorkspaceType[];
  showModal: (modal: ReactNode) => void;
  onDefaultWorkspaceSelected: (workspaceId: number) => void;
  onChangeWorkspace: () => void;
  onEasySharingInfoClick?: () => void;
}

export const WorkspaceSettingsListActive: FC<ActiveWorkspacesListProps> = ({
  workspaces,
  handleWorkspaceEdit,
  addMembers,
  handleWorkspaceInactive,
  onChangeWorkspace,
  inactiveWorkspaces,
  onDefaultWorkspaceSelected,
  showModal,
  onEasySharingInfoClick,
}) => {
  if (workspaces.length === 0) {
    return (
      <CompanyWorkspaceEmptyState
        isNew={!(inactiveWorkspaces.length > 0)}
        onClick={() => {
          showModal(<CreateWorkspace method="add" />);
        }}
      />
    );
  }

  return (
    <>
      {workspaces.map((workspace: CompanyWorkspaceType) => (
        <StyledSettingsSection key={workspace.name}>
          <StyledSettingsSectionWrapper>
            <CompanyWorkspace
              onChangeWorkspace={onChangeWorkspace}
              onDefaultWorkspaceSelected={() => {
                onDefaultWorkspaceSelected(workspace.id);
              }}
              onEdit={handleWorkspaceEdit}
              addMembers={addMembers}
              onWorkspaceInactive={handleWorkspaceInactive}
              {...workspace}
              workspace={workspace}
              onEasySharingInfoClick={onEasySharingInfoClick}
            />
          </StyledSettingsSectionWrapper>
        </StyledSettingsSection>
      ))}
    </>
  );
};
