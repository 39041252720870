import {useState} from 'react';
import {Button, Dropdown, Menu} from 'antd';
import {ModalActions} from '../../../redux/Modal';
import {ClipboardItem, GridClipboardActions} from '../../../redux/GridClipboard';
import {MultiCalcCargoModal} from '../../../components/CharterCalculation/MultiCalc/MultiCalcCargoModal/MultiCalcCargoModal';
import {AddClipboardItemsToProjectModal} from '../AddClipboardItemsToProjectModal/AddClipboardItemsToProjectModal';
import {useDispatch} from '../../../redux/react-redux';
import {TODO} from '../../../utils/TODO';
import {MenuItemType} from 'antd/es/menu/interface';
import {MultiPortfolioAddModal} from '../../../components/Modal/Multiselect/Market/MultiselectAddToPortfolio';

type Props = {
  items: ClipboardItem[];
  reloadGrid: TODO;
};

export const MarketVesselActionButton = (props: Props) => {
  const [show, setShow] = useState(false);
  const count = props.items.length;

  if (count === 0) {
    return null;
  }

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={visible => {
        setShow(visible);
      }}
      open={show}
      menu={{
        items: [
          {
            key: '1',
            label: (
              <DropdownMenu
                onClick={() => {
                  setShow(false);
                }}
                {...props}
              />
            ),
          },
        ],
      }}>
      <Button className="uppercase" type={'primary'}>
        {count} {count === 1 ? 'vessel' : 'vessels'} selected
      </Button>
    </Dropdown>
  );
};

type DropdownMenuProps = Props & {
  onClick: () => void;
};

const DropdownMenu = (props: DropdownMenuProps) => {
  const dispatch = useDispatch();
  const [multiCalcModalVisible, setMultiCalcModalVisible] = useState(false);
  const [addToProjectVisible, setAddToProjectVisible] = useState(false);

  return (
    <>
      <Menu
        selectedKeys={[]}
        items={
          [
            {
              key: 'addToPortfolios',
              label: 'Add to My Fleet',
              onClick: () => {
                dispatch(
                  ModalActions.show(
                    <MultiPortfolioAddModal type="vessel" data={props.items} reload={props.reloadGrid} />
                  )
                );
                props.onClick();
              },
            },
            {
              key: 'multiCalc',
              label: <div data-testid="MultiCalcMenuItem">Multi Calculation (VC)</div>,
              onClick: () => {
                props.onClick();
                setMultiCalcModalVisible(true);
              },
            },
            {
              key: 'addToProject',
              label: <div data-testid="AddToProjectMenuItem">Add to Project</div>,
              onClick: () => {
                props.onClick();
                setAddToProjectVisible(true);
              },
            },
            {
              key: 'unselect',
              label: 'Unselect all vessels',
              onClick: () => {
                dispatch(GridClipboardActions.removeAllItemsFromClipboard('vessels', 'market'));
                props.onClick();
              },
            },
          ].filter(Boolean) as MenuItemType[]
        }
      />

      <MultiCalcCargoModal
        visible={multiCalcModalVisible}
        onClose={() => {
          setMultiCalcModalVisible(false);
        }}
      />
      <AddClipboardItemsToProjectModal
        projectType={'cargo'}
        visible={addToProjectVisible}
        onCancel={() => setAddToProjectVisible(false)}
      />
    </>
  );
};
