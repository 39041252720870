import PiwikPro from '@piwikpro/react-piwik-pro';
import * as Sentry from '@sentry/browser';
import {isLocalDevEnvironment} from '../../utils/environment';

export const initPiwik = () => {
  if (isLocalDevEnvironment()) {
    return;
  }
  if (!window.PP_CONTAINER_ID || !window.PP_CONTAINER_URL) {
    Sentry.captureException(
      new Error('PiwikPro could not be intialized. PP_CONTAINER_ID or PP_CONTAINER_URL not set'),
      {
        extra: {PP_CONTAINER_ID: window.PP_CONTAINER_ID, PP_CONTAINER_URL: window.PP_CONTAINER_URL},
      }
    );
    return;
  }

  PiwikPro.initialize(window.PP_CONTAINER_ID, window.PP_CONTAINER_URL);
};
