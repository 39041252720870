import {useEffect, useState, FC} from 'react';
import styled from 'styled-components';
import {hexToRgb} from '../../../components/SeaboMap/helper';
import {SeaboMap} from '../../../components/SeaboMap/SeaboMap';
import {useMapApi} from '../../../components/SeaboMap/useMapApi';
import Cargo from '../../../model/Cargo';
import {CargoStations} from './CargoStations';
import {useGetCargoRouteLayer} from './useGetCargoRouteLayer';
import {getBounds} from './utils';

export const CargoRouteMap: FC<{cargo: Cargo}> = ({cargo}) => {
  const mapApi = useMapApi();
  const [fromIndex, setFromIndex] = useState(0);
  const [viaIndex, setViaIndex] = useState(0);
  const [toIndex, setToIndex] = useState(0);

  const stations = cargo.stations;
  const color = hexToRgb(Cargo.getColor(cargo.contractType))!;
  const {stationCoordinates, layers} = useGetCargoRouteLayer(stations, color, fromIndex, viaIndex, toIndex);

  useEffect(() => {
    if (!mapApi.state.mapReady || !stationCoordinates) {
      return;
    }
    const bounds = getBounds([stationCoordinates.from, stationCoordinates.via, stationCoordinates.to]);
    mapApi.fitBounds([
      [bounds.minLng, bounds.minLat],
      [bounds.maxLng, bounds.maxLat],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationCoordinates]);

  return (
    <FlexBox>
      <BoxHalf>
        <CargoStations
          cargo={cargo}
          fromIndex={fromIndex}
          viaIndex={viaIndex}
          toIndex={toIndex}
          setFromIndex={setFromIndex}
          setViaIndex={setViaIndex}
          setToIndex={setToIndex}
        />
      </BoxHalf>
      <BoxHalf>
        {layers && (
          <SeaboMap
            defaults={'cargo_detail'}
            settingIdentifier={'cargo_detail'}
            mapApi={mapApi}
            layers={layers}
            vesselsToVisualize={{
              data: [],
            }}
            centerPoint={stationCoordinates?.from}
            initialViewState={{
              zoom: 0.5,
              minZoom: 0.2,
              maxZoom: 10,
              pitch: 45,
            }}
            showPopups
            showLegend={false}
            showSearch={false}
            showControl={false}
            showFocusBtn={false}
            settingsButtonVisible={false}
          />
        )}
      </BoxHalf>
    </FlexBox>
  );
};

const FlexBox = styled.div`
  display: flex;
  height: 100%;
  gap: 20px;
`;

const BoxHalf = styled.div`
  flex: 1;
`;
