import {FormInstance} from 'antd/es/form';
import {FormValues} from '../../FormValues';
import {ApiCargoStation, CargoFromApi} from './CargoFromApi';
import {assert} from '../../../../../../utils/assert';
import {Option, Station} from '../../UpdateFixtureRequestSchema/UpdateFixtureRequestSchema';
import {DefaultFormValues} from '../../DefaultFormValues';
import {getDurationUnitAndRateTermForContractType} from './ContractTypePicker/changesDefaults';
import {ContractType} from '../../ContractTypes';

export const setHiddenCargoFields = (
  form: FormInstance<FormValues>,
  cargoFromApi: CargoFromApi,
  firstOption: Option | undefined
) => {
  const contractType = cargoFromApi.contractType as ContractType;

  const isSale = contractType === 'sale';

  const stations = getStationsFromCargo(cargoFromApi.stations);

  const defaultDurationUnitAndRateTerms = !isSale ? getDurationUnitAndRateTermForContractType(contractType) : null;

  form.setFieldsValue({
    cargo: {
      id: cargoFromApi.id,
    },
    chartererName: cargoFromApi.chartererName,
    contractType: cargoFromApi.contractType as FormValues['contractType'],
    commissionTotal: cargoFromApi.commissionTotal,
    options: [
      {
        ...DefaultFormValues.options[0],
        ...firstOption,
        periodMin: cargoFromApi.durationMin,
        periodMax: cargoFromApi.durationMax,
        periodUnit: (cargoFromApi.durationUnit ?? defaultDurationUnitAndRateTerms?.periodUnit) as Option['periodUnit'],
        rateTerms: defaultDurationUnitAndRateTerms?.rateTerms,
      },
    ],
    stations,
    laycanFrom: cargoFromApi.laycanFrom,
    laycanTo: cargoFromApi.laycanTo,
  });
};

const getStationsFromCargo = (stations: ApiCargoStation[]): Station[] => {
  const newStations: Station[] = [
    {
      type: 'fixturedelivery',
      locations: [],
    },
    {
      type: 'fixturedeliveryvia',
      locations: [],
    },
    {
      type: 'fixtureredelivery',
      locations: [],
    },
  ];

  stations.forEach(station => {
    const stationType = getStationType(station.type);
    const relatedStation = newStations.find(s => s.type === stationType);
    assert(relatedStation);
    relatedStation.locations.push(...(station.locations ?? []));
  });

  return newStations;
};

const getStationType = (type: string) => {
  switch (type) {
    case 'loading':
    // Fallthrough
    case 'delivery':
      return 'fixturedelivery';
    case 'deliveryvia':
      return 'fixturedeliveryvia';
    case 'discharge':
    // Fallthrough
    case 'redelivery':
      return 'fixtureredelivery';
    case 'fixturedelivery':
    // Fallthrough
    case 'fixturedeliveryvia':
    // Fallthrough
    case 'fixtureredelivery':
      return type;
    default:
      throw new Error('Unknown station type: ' + type);
  }
};
