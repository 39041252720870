import sortBy from 'lodash/sortBy';

export const getMedian = (array: number[]): number => {
  if (array.length === 0) {
    throw new Error("Array can't be empty.");
  }
  const sortedArray = sortBy(array);

  if (sortedArray.length === 2) {
    return sortedArray[0];
  }

  return sortedArray[Math.floor(sortedArray.length / 2)];
};
