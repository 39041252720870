import {Button, Form, Radio, Row, Space, Typography} from 'antd';
import React, {FC, useState} from 'react';
import {ReportDisableable} from './Report';
import {Center} from '../Center/Center';
import {ModalFooter} from '../../antd/components/ModalFooter';

const {Paragraph} = Typography;

const layout = {
  labelCol: {span: 3},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {offset: 3, span: 16},
};

interface FormValues {
  reportName?: string;
}

export const ExportReportsForm: FC<{
  description: JSX.Element;
  availableReports: ReportDisableable[];
  buttonLabel: JSX.Element | string;
  onSubmit: (reportList: string[]) => void;
}> = ({description, availableReports, buttonLabel, onSubmit}) => {
  const [form] = Form.useForm();
  const [anyReportSelected, setAnyReportSelected] = useState(false);

  const onFinish = (formValues: FormValues) => {
    const reportList = getReportList(formValues);
    onSubmit(reportList);
  };

  return (
    <div data-testid="ExportReportsForm">
      {description}
      <div style={{height: 4}} />
      <Form<FormValues>
        {...layout}
        form={form}
        name="export"
        onFinish={onFinish}
        onValuesChange={(_changedFields: FormValues, formValues: FormValues) => {
          setAnyReportSelected(isAnyReportSelected(formValues));
        }}>
        <Center>
          <Paragraph>Select the desired report:</Paragraph>
        </Center>
        <Row justify="center">
          <Form.Item {...tailLayout} name="reportName" noStyle>
            <Radio.Group style={{boxShadow: 'none'}}>
              <Space direction="vertical" size={6}>
                {availableReports.map(report => (
                  <div key={report.id}>
                    <Radio disabled={report.disabled} value={report.id} data-testid={`option/${report.id}`}>
                      {report.label}
                    </Radio>
                  </div>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>

        <ModalFooter>
          <Button
            data-cy={'ExportButton'}
            type="primary"
            htmlType="submit"
            className="uppercase"
            disabled={!anyReportSelected}
            data-testid="DownloadButton">
            {buttonLabel}
          </Button>
        </ModalFooter>
      </Form>
    </div>
  );
};

const isAnyReportSelected = (formValues: FormValues): boolean => !!formValues.reportName;

const getReportList = (formValues: FormValues): string[] => {
  return [formValues.reportName!];
};
