/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CargoOffersForLocationResponseDataCargoType,
  CargoOffersForLocationResponseDataCargoTypeFromJSON,
  CargoOffersForLocationResponseDataCargoTypeFromJSONTyped,
  CargoOffersForLocationResponseDataCargoTypeToJSON,
  CargoOffersForLocationResponseDataContractType,
  CargoOffersForLocationResponseDataContractTypeFromJSON,
  CargoOffersForLocationResponseDataContractTypeFromJSONTyped,
  CargoOffersForLocationResponseDataContractTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface CargoOffersForLocationResponseDataItems
 */
export interface CargoOffersForLocationResponseDataItems {
  /**
   *
   * @type {string}
   * @memberof CargoOffersForLocationResponseDataItems
   */
  date?: string;
  /**
   *
   * @type {CargoOffersForLocationResponseDataCargoType}
   * @memberof CargoOffersForLocationResponseDataItems
   */
  cargoType: CargoOffersForLocationResponseDataCargoType;
  /**
   *
   * @type {CargoOffersForLocationResponseDataContractType}
   * @memberof CargoOffersForLocationResponseDataItems
   */
  contractType: CargoOffersForLocationResponseDataContractType;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CargoOffersForLocationResponseDataItems
   */
  commodityCategory: {[key: string]: number};
}

export function CargoOffersForLocationResponseDataItemsFromJSON(json: any): CargoOffersForLocationResponseDataItems {
  return CargoOffersForLocationResponseDataItemsFromJSONTyped(json, false);
}

export function CargoOffersForLocationResponseDataItemsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CargoOffersForLocationResponseDataItems {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: !exists(json, 'date') ? undefined : json['date'],
    cargoType: CargoOffersForLocationResponseDataCargoTypeFromJSON(json['cargoType']),
    contractType: CargoOffersForLocationResponseDataContractTypeFromJSON(json['contractType']),
    commodityCategory: json['commodityCategory'],
  };
}

export function CargoOffersForLocationResponseDataItemsToJSON(
  value?: CargoOffersForLocationResponseDataItems | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    cargoType: CargoOffersForLocationResponseDataCargoTypeToJSON(value.cargoType),
    contractType: CargoOffersForLocationResponseDataContractTypeToJSON(value.contractType),
    commodityCategory: value.commodityCategory,
  };
}
