import {useQuery} from '@tanstack/react-query';
import {negotiationNegotiationTemplateApi} from '../../../../api/node-backend/nodeBackendApis';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {NegotiationTemplate} from '../../../../api/node-backend/generated';
import {ApiError} from '../../../../api/utils/ApiError';

export const GET_NEGOTIATION_TEMPLATE_QUERY_KEY = (templateId?: number) => [
  'negotiationNegotiationTemplateApi.getNegotiationTemplateSummaries',
  templateId,
];

export const useGetNegotiationTemplateQuery = (
  templateId?: number,
  options?: UseQueryOptions<NegotiationTemplate, ApiError>
) =>
  useQuery({
    queryKey: GET_NEGOTIATION_TEMPLATE_QUERY_KEY(templateId),
    queryFn: async () => {
      const result = await negotiationNegotiationTemplateApi.getNegotiationTemplate({templateId: templateId!});
      return result;
    },
    enabled: !!templateId,
    ...options,
  });
