import {InfoCircleOutlined} from '@ant-design/icons';
import {Button, Col} from 'antd';
import {FC} from 'react';
import styled, {css} from 'styled-components';
import {Attribute, AttributeLabel, AttributeValue} from '../../../../atoms/Attribute/Attribute';
import {numberUnitFormat} from '../../../../utils/formatter';
import {roundNumberToDecimals} from '../../../../utils/roundNumber';
import {VesselType} from '../VesselType';
import {TiedUpTradingAreaWithDistributionAt, ValueRepresentation} from './getDatasource';
import {OneTradingAreaTimeSeriesModal} from './OneTradingAreaTimeSeriesModal';
import {PercentagePointsDifference} from './PercentagePointsDifference';

export const Column: FC<{
  values: TiedUpTradingAreaWithDistributionAt[];
  valueRepresentation: ValueRepresentation;
  vesselType: VesselType;
  selectedTradingArea: string;
  onSelectTradingArea: (tradingArea: string) => void;
  hoveredTradingArea: string;
  onHoverTradingArea: (tradingArea: string) => void;
}> = ({
  values,
  valueRepresentation,
  vesselType,
  selectedTradingArea,
  onSelectTradingArea,
  hoveredTradingArea,
  onHoverTradingArea,
}) => {
  return (
    <Col xl={12} xxl={24}>
      {values.map(tiedUpTradingArea => (
        <TiedUpVesselsSummaryTradingAreaEntry
          key={tiedUpTradingArea.tradingAreaName}
          vesselType={vesselType}
          tiedUpTradingArea={tiedUpTradingArea}
          valueRepresentation={valueRepresentation}
          selectedTradingArea={selectedTradingArea}
          onSelectTradingArea={onSelectTradingArea}
          hoveredTradingArea={hoveredTradingArea}
          onHoverTradingArea={onHoverTradingArea}
        />
      ))}
    </Col>
  );
};

const TiedUpVesselsSummaryTradingAreaEntry: FC<{
  vesselType: VesselType;
  tiedUpTradingArea: TiedUpTradingAreaWithDistributionAt;
  valueRepresentation: ValueRepresentation;
  selectedTradingArea: string;
  onSelectTradingArea: (tradingArea: string) => void;
  hoveredTradingArea: string;
  onHoverTradingArea: (tradingArea: string) => void;
}> = ({
  vesselType,
  tiedUpTradingArea,
  valueRepresentation,
  selectedTradingArea,
  onSelectTradingArea,
  hoveredTradingArea,
  onHoverTradingArea,
}) => {
  const unit = vesselType === 'container' ? 'TEU' : 'MT';
  const value = tiedUpTradingArea.distributionAt;
  return (
    <>
      <OneTradingAreaTimeSeriesModal
        visible={selectedTradingArea === tiedUpTradingArea.tradingAreaName}
        tiedUpTradingArea={tiedUpTradingArea}
        valueRepresentation={valueRepresentation}
        vesselType={vesselType}
        onClose={() => onSelectTradingArea('')}
      />
      <StyledRow
        $hovered={hoveredTradingArea === tiedUpTradingArea.tradingAreaName}
        onClick={() => onSelectTradingArea(tiedUpTradingArea.tradingAreaName)}
        onMouseEnter={() => onHoverTradingArea(tiedUpTradingArea.tradingAreaName)}
        onMouseLeave={() => onHoverTradingArea('')}>
        <StyledAttribute
          testId="TiedUpVesselsSummaryTradingAreaEntry"
          label={
            <Button type={'link'}>
              {tiedUpTradingArea.tradingAreaName} <InfoCircleOutlined style={{marginLeft: 4, fontSize: 10}} />
            </Button>
          }
          value={
            <>
              {valueRepresentation === 'percentage' ? (
                <>
                  <span>{value.toFixed(2)} %</span>
                  <PercentagePointsDifference difference={tiedUpTradingArea.percentagePointsDifference} />
                </>
              ) : (
                numberUnitFormat(roundNumberToDecimals(value, -3), unit)
              )}
            </>
          }
          tab="xxl"
        />
      </StyledRow>
    </>
  );
};

const StyledRow = styled.div<{$hovered: boolean}>`
  cursor: pointer;

  &:nth-of-type(even) {
    background: rgba(0, 0, 0, 0.02);
  }

  ${({$hovered}) =>
    $hovered &&
    css`
      background: rgba(0, 0, 0, 0.04) !important;
      ${AttributeLabel}, ${AttributeValue} {
        font-weight: bold !important;
      }
    `}
`;

const StyledAttribute = styled(Attribute)`
  justify-content: space-between;
  margin: 0;
  padding: 2px 10px 2px 0;

  ${AttributeValue} {
    width: 120px;
    padding-right: 10px;
    text-align: right;
  }
  ${AttributeLabel} {
    min-width: 180px;
  }
`;
