import React, {useState} from 'react';
import {AutoComplete, Input} from 'antd';
import styled from 'styled-components';
import {SeaboVesselOption, SeaboVessel} from '../SearchPicker/Type';
import {VesselPickerIndexName, useOptionsFromSeaboVesselSearch} from '../SearchPicker/useOptionsFromSeaboVesselSearch';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import {SearchHit, VesselType} from '../../api/symfony/generated/models';

interface SeaboVesselPickerProps {
  value?: string;
  defaultValue?: string;
  searchValue?: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  size?: SizeType;
  prefix?: React.ReactNode;
  indexNames?: VesselPickerIndexName[] | VesselPickerIndexName;
  vesselTypes?: VesselType[];
  makeOption?: (searchHit: SearchHit) => SeaboVesselOption;
  onSelect: (seaboVessel: SeaboVessel) => void;
  onChange?: (seaboVesselName: string) => void;
  onChangeText?: (text: string) => void;
}

const StyledAutoComplete: typeof AutoComplete = styled(AutoComplete)`
  width: 100%;
`;

export const SeaboVesselPicker = (props: SeaboVesselPickerProps) => {
  const [searchTag, setSearchTag] = useState(props.searchValue ?? '');

  const options = useOptionsFromSeaboVesselSearch({
    indexNames: props.indexNames,
    makeOption: props.makeOption,
    searchTag,
    vesselTypes: props.vesselTypes,
  });

  return (
    <StyledAutoComplete
      autoFocus={props.autoFocus}
      options={options}
      value={props.value}
      defaultValue={props.defaultValue}
      searchValue={searchTag}
      onSelect={(_value: string, option: SeaboVesselOption) => {
        props.onSelect(option.source);
      }}
      onChange={(_value: string, option: SeaboVesselOption | SeaboVesselOption[]) => {
        if (Array.isArray(option)) {
          props.onChange?.(option[0].name);
          return;
        }
        props.onChange?.(option.name);
      }}
      dropdownRender={el => <div data-testid="SeaboVesselPickerAutoCompleteMenu">{el}</div>}
      data-testid="SeaboVesselPickerAutoComplete">
      <Input.Search
        size={props.size}
        allowClear={props.allowClear}
        prefix={props.prefix}
        data-testid="SeaboVesselPickerInputField"
        data-cy="SeaboVesselPickerInputField"
        onChange={event => {
          const newValue = event.currentTarget.value.trim();
          setSearchTag(newValue);
          if (props.onChangeText) {
            props.onChangeText(newValue);
          }
        }}
        placeholder="Search for a vessel"
      />
    </StyledAutoComplete>
  );
};
