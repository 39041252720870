import {DatePicker} from 'antd';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {TODO} from '../../../../../utils/TODO';

export const SmartInboxRangePicker = ({value, onChange, id}: TODO) => {
  return (
    <DatePicker.RangePicker
      id={id}
      value={[value[0], value[1]]}
      onChange={dates => {
        onChange([dates?.[0], dates?.[1]]);
      }}
      style={{width: '100%'}}
      format={DateTimeFormat.Date}
      allowEmpty={[true, true]}
    />
  );
};
