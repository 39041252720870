import {FC} from 'react';
import {
  NegotiationParty,
  NegotiationStatus,
  NegotiationTerm,
  RoundResultTermModification,
  TermModification,
} from '../../../../../../api/node-backend/generated';
import {TermModificationIcon} from './TermModificationIcon';
import {getDerivedTermState} from '../../TermItem/utils/getDerivedTermState';
import {getTermColors} from '../../../../utils/colors';
import {TermItemDumb} from '../../TermItem/TermItemDumb';
import {TermDiff} from '../../TermItem/Components/TermDiff';

type TermItemHistoryProps = {
  party: NegotiationParty;
  status: NegotiationStatus;
  term: NegotiationTerm;
  termModifications?: RoundResultTermModification;
};

export const TermItemHistory: FC<TermItemHistoryProps> = ({party, status, term, termModifications}) => {
  const {oldText, termModification} = termModifications ?? {};

  const {status: termStatus} = term;
  const {isCountered} = getDerivedTermState({
    roundCanBeEdited: false,
    term,
    party,
    termModification,
  });

  const colors = getTermColors(termStatus, status, isCountered);

  return (
    <TermItemDumb
      indexLabel={term.sortIndex + 1}
      text={term.text}
      colors={colors}
      beforeTextAddon={<TermModificationIcon isOwnEdit={!isCountered} termModification={termModification} />}
      textReplacement={
        termModification === TermModification.UpdateTermText ? (
          <TermDiff currentText={term.text} previousText={oldText ?? undefined} />
        ) : null
      }
    />
  );
};
