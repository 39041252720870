import React, {FC} from 'react';
import {Row, Col, Button} from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import {ActionMenu} from '../../../components/ActionMenu/ActionMenu';
import {NegotiationTemplate} from '../../../api/node-backend/generated';

type TemplateHeaderProps = {
  template: NegotiationTemplate;
  hasChanges: boolean;
  onChangeTemplate: (template: NegotiationTemplate) => void;
  onRevertTemplate: () => void;
  onSaveTemplate: () => void;
  onDuplicateTemplate: () => void;
  onRemoveTemplate: () => void;
};

export const TemplateHeader: FC<TemplateHeaderProps> = ({
  template,
  hasChanges,
  onChangeTemplate,
  onRevertTemplate,
  onSaveTemplate,
  onDuplicateTemplate,
  onRemoveTemplate,
}) => {
  return (
    <>
      <Row justify={'space-between'} align={'top'}>
        <Col>
          <Title
            level={3}
            editable={{
              text: template.name,
              tooltip: 'click to edit text',
              triggerType: ['icon', 'text'],
              onChange: name => onChangeTemplate({...template, name}),
            }}>
            {template.name}
          </Title>
        </Col>
        <Col>
          <Row gutter={[10, 10]} align={'middle'}>
            {hasChanges && (
              <>
                <Col>
                  <Button type="default" onClick={onRevertTemplate}>
                    Revert
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" onClick={onSaveTemplate}>
                    Save changes
                  </Button>
                </Col>
              </>
            )}
            <Col>
              <ActionMenu
                items={[
                  {
                    key: 'duplicate',
                    label: 'Duplicate',
                    onClick: onDuplicateTemplate,
                  },
                  {
                    key: 'delete',
                    label: 'Delete',
                    onClick: onRemoveTemplate,
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Paragraph
        editable={{
          text: template.description,
          tooltip: 'click to edit text',
          triggerType: ['icon', 'text'],
          onChange: description => onChangeTemplate({...template, description}),
        }}>
        {template.description || 'No description'}
      </Paragraph>
    </>
  );
};
