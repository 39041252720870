import {CharterCalculationList} from '../../../../atoms/CharterCalculationList';
import {Button, Modal} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {CharterCalculation} from '../../../../api/symfony/generated/models';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {useSaveVoyageCalculation} from '../utils/useSaveVoyageCalculation';
import {ModalFooter} from '../../../../antd/components/ModalFooter';

interface SaveDialogProps {
  visible: boolean;
  onCancel: () => void;
}

export const SaveDialog = (props: SaveDialogProps) => {
  const [selectedItem, setSelectedItem] = useState<CharterCalculation | null>(null);
  const voyageContext = useContext(VoyageCharterContext);

  const {saveVoyageCalculation, saveExistingVoyageCalculation} = useSaveVoyageCalculation({
    onSuccess: () => {
      props.onCancel();
    },
  });

  useEffect(() => {
    if (voyageContext.state.metaState.calculation.id !== null) {
      setSelectedItem({id: voyageContext.state.metaState.calculation.id} as CharterCalculation);
    }
  }, [voyageContext.state.metaState.calculation.id]);

  return (
    <Modal
      getContainer={'#root'}
      footer={
        <ModalFooter style={{marginTop: 16}}>
          <Button data-cy="saveNewButton" data-testid={'save-btn'} type={'primary'} onClick={saveVoyageCalculation}>
            Save new Calculation
          </Button>
        </ModalFooter>
      }
      width={1000}
      okText={'Save'}
      open={props.visible}
      title={'Save'}
      styles={{body: {padding: 0}}}
      onCancel={props.onCancel}>
      <CharterCalculationList
        selectedItemId={selectedItem?.id ?? undefined}
        onSelect={item => {
          setSelectedItem(item);
        }}
        onOverwriteCalculation={item => saveExistingVoyageCalculation({id: item.id!})}
      />
    </Modal>
  );
};
