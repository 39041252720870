/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ConsentContractVersion,
  ConsentContractVersionFromJSON,
  ConsentContractVersionToJSON,
  CreateConsentContractVersionDTO,
  CreateConsentContractVersionDTOFromJSON,
  CreateConsentContractVersionDTOToJSON,
  UpdateConsentContractVersionDTO,
  UpdateConsentContractVersionDTOFromJSON,
  UpdateConsentContractVersionDTOToJSON,
} from '../models';

export interface CreateConsentContractVersionRequest {
  consentContractId: string;
  createConsentContractVersionDTO: CreateConsentContractVersionDTO;
}

export interface DownloadConsentContractVersionFileRequest {
  consentContractId: string;
  consentContractVersionId: string;
}

export interface GetConsentContractVersionRequest {
  consentContractId: string;
  consentContractVersionId: string;
}

export interface ListConsentContractVersionsByContractIdRequest {
  consentContractId: string;
}

export interface PublishConsentContractVersionRequest {
  consentContractId: string;
  consentContractVersionId: string;
}

export interface UpdateConsentContractVersionRequest {
  consentContractId: string;
  consentContractVersionId: string;
  updateConsentContractVersionDTO: UpdateConsentContractVersionDTO;
}

export interface UploadConsentContractVersionFileRequest {
  consentContractId: string;
  consentContractVersionId: string;
  file: Blob;
}

/**
 *
 */
export class ConsentContractVersionsApi extends runtime.BaseAPI {
  /**
   */
  async createConsentContractVersionRaw(
    requestParameters: CreateConsentContractVersionRequest
  ): Promise<runtime.ApiResponse<ConsentContractVersion>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling createConsentContractVersion.'
      );
    }

    if (
      requestParameters.createConsentContractVersionDTO === null ||
      requestParameters.createConsentContractVersionDTO === undefined
    ) {
      throw new runtime.RequiredError(
        'createConsentContractVersionDTO',
        'Required parameter requestParameters.createConsentContractVersionDTO was null or undefined when calling createConsentContractVersion.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{consentContractId}/versions`.replace(
        `{${'consentContractId'}}`,
        encodeURIComponent(String(requestParameters.consentContractId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateConsentContractVersionDTOToJSON(requestParameters.createConsentContractVersionDTO),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractVersionFromJSON(jsonValue));
  }

  /**
   */
  async createConsentContractVersion(
    requestParameters: CreateConsentContractVersionRequest
  ): Promise<ConsentContractVersion> {
    const response = await this.createConsentContractVersionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Downloadstream for a negotiation attachment.
   */
  async downloadConsentContractVersionFileRaw(
    requestParameters: DownloadConsentContractVersionFileRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling downloadConsentContractVersionFile.'
      );
    }

    if (
      requestParameters.consentContractVersionId === null ||
      requestParameters.consentContractVersionId === undefined
    ) {
      throw new runtime.RequiredError(
        'consentContractVersionId',
        'Required parameter requestParameters.consentContractVersionId was null or undefined when calling downloadConsentContractVersionFile.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{consentContractId}/versions/{consentContractVersionId}/file`
        .replace(`{${'consentContractId'}}`, encodeURIComponent(String(requestParameters.consentContractId)))
        .replace(
          `{${'consentContractVersionId'}}`,
          encodeURIComponent(String(requestParameters.consentContractVersionId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Downloadstream for a negotiation attachment.
   */
  async downloadConsentContractVersionFile(
    requestParameters: DownloadConsentContractVersionFileRequest
  ): Promise<Blob> {
    const response = await this.downloadConsentContractVersionFileRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getConsentContractVersionRaw(
    requestParameters: GetConsentContractVersionRequest
  ): Promise<runtime.ApiResponse<ConsentContractVersion>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling getConsentContractVersion.'
      );
    }

    if (
      requestParameters.consentContractVersionId === null ||
      requestParameters.consentContractVersionId === undefined
    ) {
      throw new runtime.RequiredError(
        'consentContractVersionId',
        'Required parameter requestParameters.consentContractVersionId was null or undefined when calling getConsentContractVersion.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{consentContractId}/versions/{consentContractVersionId}`
        .replace(`{${'consentContractId'}}`, encodeURIComponent(String(requestParameters.consentContractId)))
        .replace(
          `{${'consentContractVersionId'}}`,
          encodeURIComponent(String(requestParameters.consentContractVersionId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractVersionFromJSON(jsonValue));
  }

  /**
   */
  async getConsentContractVersion(
    requestParameters: GetConsentContractVersionRequest
  ): Promise<ConsentContractVersion> {
    const response = await this.getConsentContractVersionRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async listConsentContractVersionsByContractIdRaw(
    requestParameters: ListConsentContractVersionsByContractIdRequest
  ): Promise<runtime.ApiResponse<Array<ConsentContractVersion>>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling listConsentContractVersionsByContractId.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{consentContractId}/versions`.replace(
        `{${'consentContractId'}}`,
        encodeURIComponent(String(requestParameters.consentContractId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ConsentContractVersionFromJSON));
  }

  /**
   */
  async listConsentContractVersionsByContractId(
    requestParameters: ListConsentContractVersionsByContractIdRequest
  ): Promise<Array<ConsentContractVersion>> {
    const response = await this.listConsentContractVersionsByContractIdRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async publishConsentContractVersionRaw(
    requestParameters: PublishConsentContractVersionRequest
  ): Promise<runtime.ApiResponse<ConsentContractVersion>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling publishConsentContractVersion.'
      );
    }

    if (
      requestParameters.consentContractVersionId === null ||
      requestParameters.consentContractVersionId === undefined
    ) {
      throw new runtime.RequiredError(
        'consentContractVersionId',
        'Required parameter requestParameters.consentContractVersionId was null or undefined when calling publishConsentContractVersion.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{consentContractId}/versions/{consentContractVersionId}/publish`
        .replace(`{${'consentContractId'}}`, encodeURIComponent(String(requestParameters.consentContractId)))
        .replace(
          `{${'consentContractVersionId'}}`,
          encodeURIComponent(String(requestParameters.consentContractVersionId))
        ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractVersionFromJSON(jsonValue));
  }

  /**
   */
  async publishConsentContractVersion(
    requestParameters: PublishConsentContractVersionRequest
  ): Promise<ConsentContractVersion> {
    const response = await this.publishConsentContractVersionRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateConsentContractVersionRaw(
    requestParameters: UpdateConsentContractVersionRequest
  ): Promise<runtime.ApiResponse<ConsentContractVersion>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling updateConsentContractVersion.'
      );
    }

    if (
      requestParameters.consentContractVersionId === null ||
      requestParameters.consentContractVersionId === undefined
    ) {
      throw new runtime.RequiredError(
        'consentContractVersionId',
        'Required parameter requestParameters.consentContractVersionId was null or undefined when calling updateConsentContractVersion.'
      );
    }

    if (
      requestParameters.updateConsentContractVersionDTO === null ||
      requestParameters.updateConsentContractVersionDTO === undefined
    ) {
      throw new runtime.RequiredError(
        'updateConsentContractVersionDTO',
        'Required parameter requestParameters.updateConsentContractVersionDTO was null or undefined when calling updateConsentContractVersion.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/consent/contracts/{consentContractId}/versions/{consentContractVersionId}`
        .replace(`{${'consentContractId'}}`, encodeURIComponent(String(requestParameters.consentContractId)))
        .replace(
          `{${'consentContractVersionId'}}`,
          encodeURIComponent(String(requestParameters.consentContractVersionId))
        ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateConsentContractVersionDTOToJSON(requestParameters.updateConsentContractVersionDTO),
    });

    return new runtime.JSONApiResponse(response, jsonValue => ConsentContractVersionFromJSON(jsonValue));
  }

  /**
   */
  async updateConsentContractVersion(
    requestParameters: UpdateConsentContractVersionRequest
  ): Promise<ConsentContractVersion> {
    const response = await this.updateConsentContractVersionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Uploads a new file to a consent contract version.
   */
  async uploadConsentContractVersionFileRaw(
    requestParameters: UploadConsentContractVersionFileRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.consentContractId === null || requestParameters.consentContractId === undefined) {
      throw new runtime.RequiredError(
        'consentContractId',
        'Required parameter requestParameters.consentContractId was null or undefined when calling uploadConsentContractVersionFile.'
      );
    }

    if (
      requestParameters.consentContractVersionId === null ||
      requestParameters.consentContractVersionId === undefined
    ) {
      throw new runtime.RequiredError(
        'consentContractVersionId',
        'Required parameter requestParameters.consentContractVersionId was null or undefined when calling uploadConsentContractVersionFile.'
      );
    }

    if (requestParameters.file === null || requestParameters.file === undefined) {
      throw new runtime.RequiredError(
        'file',
        'Required parameter requestParameters.file was null or undefined when calling uploadConsentContractVersionFile.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{contentType: 'multipart/form-data'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request({
      path: `/node-backend/consent/contracts/{consentContractId}/versions/{consentContractVersionId}/file`
        .replace(`{${'consentContractId'}}`, encodeURIComponent(String(requestParameters.consentContractId)))
        .replace(
          `{${'consentContractVersionId'}}`,
          encodeURIComponent(String(requestParameters.consentContractVersionId))
        ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Uploads a new file to a consent contract version.
   */
  async uploadConsentContractVersionFile(requestParameters: UploadConsentContractVersionFileRequest): Promise<void> {
    await this.uploadConsentContractVersionFileRaw(requestParameters);
  }
}
