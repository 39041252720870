import {Tabs as AntdTabs} from 'antd';
import styled from 'styled-components';
/**
 * Ant Design Tabs component restyled for seabo design.
 */
export const Tabs = styled(AntdTabs).attrs({type: 'editable-card'})`
  .ant-tabs-content-holder {
    margin-top: 24px;
  }

  .ant-tabs-tab {
    border-radius: var(--border-radius-tab) var(--border-radius-tab) 0 0 !important;
    background-color: var(--color-gray-4) !important;
    color: var(--color-gray-2) !important;
    height: 36px;

    transition: none !important;
  }

  .ant-tabs-tab-btn {
    transition: none !important;

    &:active {
      color: unset;
    }

    &:focus {
      color: var(--color-gray-2) !important;
    }
  }

  .ant-tabs-tab-active {
    background-color: var(--color-azure) !important;

    .ant-tabs-tab-btn {
      font-weight: 700 !important;
      color: var(--color-white-fixed) !important;
      background-color: var(--color-azure) !important;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-nav-list > button {
    background-color: var(--color-gray-3) !important;

    color: var(--color-white) !important;
    border-radius: var(--border-radius-tab) var(--border-radius-tab) 0 0 !important;
  }

  .ant-tabs-nav-add {
    background-color: var(--color-gray-3) !important;
    border-radius: var(--border-radius-tab) var(--border-radius-tab) 0 0 !important;
  }

  .ant-tabs-nav-add svg {
    color: var(--color-black) !important;
  }

  /* "x" button */
  .ant-tabs-tab-remove {
    display: none;
  }

  /* "..." button */
  .ant-tabs-nav-more {
    background-color: var(--color-gray-4) !important;
    border-radius: var(--border-radius-tab) var(--border-radius-tab) 0 0 !important;
  }
` as typeof AntdTabs;
