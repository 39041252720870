import anime from 'animejs';
import {AnimationEvent, AnimationSeaSegment} from '../model/AnimationEvent';
import {atBegin} from './easing';
import dayjs from 'dayjs';
import {assert} from '../../../../../utils/assert';

const SEA_SEGMENT_DURATION_FACTOR_INSIDE_PORT = 1300;

/**
 * Collects all sea segments that are adjacent to each other.
 *
 * @return the index of the first event that is not a sea segment
 */
const collectSeaSegments = (
  voyageEvents: AnimationEvent[],
  animationEventIndex: number
): {animationEventIndex: number; seaSegments: AnimationSeaSegment[]} => {
  const seaSegments: AnimationSeaSegment[] = [];
  for (; animationEventIndex < voyageEvents.length; animationEventIndex++) {
    const voyageEvent = voyageEvents[animationEventIndex];
    if (voyageEvent.type !== 'sea-segment') {
      break;
    }
    seaSegments.push(voyageEvent);
  }
  return {animationEventIndex, seaSegments};
};

export const animateSeaSegment = ({
  animationEventIndex,
  animationEvents,
  animationSpeed,
  timeline,
}: {
  animationEventIndex: number;
  animationEvents: AnimationEvent[];
  animationSpeed: number;
  timeline: anime.AnimeTimelineInstance;
}) => {
  const firstAnimationEvent = animationEvents[animationEventIndex];
  assert(firstAnimationEvent.type === 'sea-segment');

  // Collect all sea segments that are adjacent to each other.
  // This is necessary because we want to generate keyframes.
  const result = collectSeaSegments(animationEvents, animationEventIndex);
  const {seaSegments} = result;
  animationEventIndex = result.animationEventIndex;

  let totalDuration = 0;
  const angleKeyframes = [];
  const locationKeyframes = [];

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log(
        'SeaSegment in',
        firstAnimationEvent.id,
        firstAnimationEvent.timestamp?.toString(),
        dayjs().toString()
      );
    },
    duration: 100,
  });

  for (const seaSegment of seaSegments) {
    const timestamp = seaSegment.to.timestamp.valueOf();

    let duration;
    if (seaSegment.insidePort && !seaSegment.standingStill) {
      // Travelling inside port
      duration = seaSegment.distanceInKM * SEA_SEGMENT_DURATION_FACTOR_INSIDE_PORT;
    } else {
      // Travelling outside port or idling inside port at a pier:
      // Use normal animation speed
      duration = (seaSegment.to.timestamp.valueOf() - seaSegment.from.timestamp.valueOf()) / animationSpeed;
    }
    const angle = seaSegment.heading;
    const standingStill = seaSegment.standingStill ? 1 : 0;
    locationKeyframes.push({
      timestamp,
      longitude: seaSegment.to.coordinates[0],
      latitude: seaSegment.to.coordinates[1],
      duration,
    });
    angleKeyframes.push({
      angle,
      standingStill,
      aisRecordCsvRow: seaSegment.from.csvRow,
      duration,
      easing: atBegin,
    });
    totalDuration += duration;
  }

  timeline
    .add({
      easing: 'linear',
      keyframes: locationKeyframes,
    })
    .add(
      {
        keyframes: angleKeyframes,
      },
      // Run in parallel
      `-=${totalDuration}`
    );

  timeline.add({
    begin() {
      // eslint-disable-next-line no-console
      console.log('SeaSegment out', 'now', dayjs().toString());
    },
    duration: 100,
  });

  return animationEventIndex;
};
