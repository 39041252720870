/**
 * This type allows you to define a set of mutually exclusive props.
 * It is useful for components that have a set of mutually exclusive props like button colors or sizes.
 *
 * It should give a type error if you try to use a prop that is not in the list of mutually exclusive props
 * Still, best practice would be to also check if more than one prop is set in the component itself
 *
 * @see https://stackoverflow.com/a/65583911 for more info
 *
 * @example
 * type ButtonColor = "red" | "blue";
 * type ButtonProps = {
 *   label: string;
 *   ...
 * } & MutuallyExclusiveProps<ButtonColor, boolean>;
 *
 * const Button: FC<ButtonProps> = ({label, red, blue}) => {
 *   mutuallyExclusivePropsCheck({red, blue});
 *   ...
 * };
 *
 * // Type Result:
 * type ButtonProps =
 * | { label: string; red?: undefined; blue?: undefined; }
 * | { label: string; red: true; blue?: undefined; }
 * | { label: string; red?: undefined; blue: true; }
 *
 * // This is valid
 * <Button label="Hello" red />;
 *
 * // This is also valid
 * <Button label="Hello" />;
 *
 * // This is invalid
 * <Button label="Hello" red blue />;
 */
export type MutuallyExclusiveProps<PropNames extends string, PropType> =
  | JustOneProp<PropNames, PropType>
  | NoProps<PropNames>;

type JustOneProp<PropNames extends string, PropType> = {
  [K in PropNames]: {[P in K]: PropType} & {[P in Exclude<PropNames, K>]?: never} extends infer O
    ? {[P in keyof O]: O[P]}
    : never;
}[PropNames];

type NoProps<PropNames extends string> = {[P in PropNames]?: never};

export const mutuallyExclusivePropsCheck = <PropNames extends string, PropType>(props: Record<PropNames, PropType>) => {
  const propNames = Object.keys(props) as PropNames[];
  const propValues = Object.values(props) as PropType[];
  const numberOfPropsSet = propValues.filter(value => value !== undefined).length;
  if (numberOfPropsSet > 1) {
    throw new Error(`Only one of the following props can be set: ${propNames.join(', ')}`);
  }
};
