import LabelWrapper from '../../atoms/LabelWrapper';
import Input, {InputProps} from '../../atoms/Input';
import {acceptFloatOrEmpty} from '../../utils/formatter';
import {parseFloatTS} from '../../utils/parseNumberTS';

/** @deprecated use AntD Input instead */
const FloatRangeInput = ({
  id,
  label,
  fromValue,
  fromProps,
  toProps,
  toValue,
  onFromChange,
  onToChange,
  allowEqualMinMax,
  decimalsLeft = 8,
  decimalsRight = 5,
  ...props
}: {
  id?: string;
  label: string;
  fromValue?: string | number;
  toValue?: string | number;
  fromProps: InputProps;
  toProps: InputProps;
  onFromChange: (value: string) => void;
  onToChange: (value: string) => void;
  allowEqualMinMax?: boolean;
  decimalsLeft?: number;
  decimalsRight?: number;
  flex?: boolean;
}) => {
  let hasError =
    parseFloatTS(toValue) <= parseFloatTS(fromValue) &&
    `Max value must be greater than min value ${parseFloatTS(fromValue)}`;

  if (allowEqualMinMax) {
    hasError =
      parseFloatTS(toValue) < parseFloatTS(fromValue) &&
      `Max value must be greater than or equal to min value ${parseFloatTS(fromValue)}`;
  }

  return (
    <LabelWrapper flex label={label} id={id} hasError={!!hasError} {...props}>
      <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: 8}}>
        <Input
          id={`filter-size-${label}-from`}
          style={{width: '100%'}}
          onChange={v => acceptFloatOrEmpty(v, v => onFromChange(v), decimalsLeft, decimalsRight)}
          value={fromValue?.toString()}
          {...fromProps}
        />
        <Input
          id={`filter-size-${label}-to`}
          style={{width: '100%'}}
          onChange={v => acceptFloatOrEmpty(v, v => onToChange(v), decimalsLeft, decimalsRight)}
          value={toValue?.toString()}
          hasError={!!hasError || toProps.hasError}
          {...toProps}
        />
      </div>
      {!!hasError && <div className={'input-info input-info--hasError'}> {hasError}</div>}
    </LabelWrapper>
  );
};

/** @deprecated use AntD Input instead */
export default FloatRangeInput;
