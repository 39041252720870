/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {Coordinates, CoordinatesFromJSON, CoordinatesFromJSONTyped, CoordinatesToJSON} from './';

/**
 *
 * @export
 * @interface VoyagePort
 */
export interface VoyagePort {
  /**
   *
   * @type {number}
   * @memberof VoyagePort
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof VoyagePort
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof VoyagePort
   */
  countryCode: string;
  /**
   *
   * @type {string}
   * @memberof VoyagePort
   */
  countryName: string;
  /**
   *
   * @type {string}
   * @memberof VoyagePort
   */
  code: string;
  /**
   *
   * @type {Coordinates}
   * @memberof VoyagePort
   */
  coordinates: Coordinates;
  /**
   *  Polygons include potentially multiple polygons. A polygon consists of coordinate pairs. The order is longitude and than latitude
   * @type {Array<Array<Array<number>>>}
   * @memberof VoyagePort
   */
  polygon: Array<Array<Array<number>>>;
}

export function VoyagePortFromJSON(json: any): VoyagePort {
  return VoyagePortFromJSONTyped(json, false);
}

export function VoyagePortFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoyagePort {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    countryCode: json['countryCode'],
    countryName: json['countryName'],
    code: json['code'],
    coordinates: CoordinatesFromJSON(json['coordinates']),
    polygon: json['polygon'],
  };
}

export function VoyagePortToJSON(value?: VoyagePort | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    countryCode: value.countryCode,
    countryName: value.countryName,
    code: value.code,
    coordinates: CoordinatesToJSON(value.coordinates),
    polygon: value.polygon,
  };
}
