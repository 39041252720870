import {ReactNode} from 'react';
import {GroupBase, Props, SelectComponentsConfig} from 'react-select';
import Creatable from 'react-select/creatable';
import {ClearIndicator, SearchIndicator} from './Indicators';
import {
  clearIndicator,
  control,
  indicatorsContainer,
  menu,
  menuList,
  option,
  singleValue,
  valueContainer,
} from './styles';

// @ts-expect-error Type 'Element' is not assignable to type 'string'.
interface MultiSelectProps<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends Props<OptionType, IsMulti, GroupType> {
  isDisabled?: boolean;
  width?: number | string;
  components?: Partial<SelectComponentsConfig<OptionType, IsMulti, GroupType>>;
  hasError?: boolean;
  clearable?: boolean;
  maxLength?: number;
  getOptionLabel?: (option: OptionType) => ReactNode;
  isOptionsDisabled?: (option: OptionType) => boolean;
  height?: number | string;
  cacheOptions?: boolean;
  defaultOptions?: boolean | OptionType;
  formatCreateLabel?: (inputValue: string) => string;
}

export const SelectCreatable = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
  id,
  name,
  width,
  components,
  hasError,
  ...props
}: Omit<MultiSelectProps<OptionType, IsMulti, GroupType>, 'isMulti'>) => (
  <Creatable
    id={id}
    name={`select-${name}`}
    isMulti={false as IsMulti}
    styles={{
      option,
      control: control(width, hasError),
      menu,
      menuList,
      singleValue,
      valueContainer,
      indicatorsContainer,
      clearIndicator,
    }}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator: SearchIndicator,
      ClearIndicator: ClearIndicator,
      ...components,
    }}
    // !! react18 - package types only allow string as return. But element is needed in CargoTabPane and works - /projects -> create project
    // @ts-expect-error Type '((option: OptionType) => string | Element) | undefined' is not assignable to type 'GetOptionLabel<OptionType> | undefined'.
    getOptionLabel={props.getOptionLabel}
    {...props}
  />
);
