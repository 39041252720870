import {transformFileSize} from '../../utils/helper';
import {UserDateTime} from '../DateTime/UserDateTime';
import {useProjectDetails} from './useProjectDetails';
import {TODO} from '../../utils/TODO';
import {ProjectDetailCard} from './ProjectDetailCard';
import {Button} from 'antd';
import {FC} from 'react';
import {useDispatch} from 'react-redux';
import ProjectService from '../../redux/ApiService/ProjectService';
import {UploadDocument} from './UploadDocument';
import {DataGrid} from '../DataGrid/DataGrid';

export const ProjectDetailDocuments: FC = () => {
  const dispatch = useDispatch();
  const {
    project: {id, documents, isArchived},
  } = useProjectDetails();

  const deleteProjectDocument = (uuid: string) => dispatch(ProjectService.deleteProjectDocument(id, uuid));

  const handleDocumentDelete = (documentUuid: string) => {
    deleteProjectDocument(documentUuid);
  };

  return (
    <ProjectDetailCard noPadding header="Documents" footer={!isArchived && <UploadDocument />}>
      {documents.length > 0 && (
        <DataGrid
          responsive
          data={documents}
          unrounded
          emptyMessage="Upload documents below."
          columns={[
            {
              id: 'type',
              header: 'Type',
              minWidth: 5,
              accessorFn: (row: TODO) => row.fileExtension.toUpperCase(),
              cell: info => info.getValue(),
            },
            {
              id: 'name',
              header: 'Name',
              minWidth: 50,
              accessorFn: (row: TODO) => row.filename,
              cell: info => info.getValue(),
            },
            {
              id: 'size',
              header: 'Size',
              minWidth: 15,
              accessorFn: (row: TODO) => transformFileSize(row.size),
              cell: info => info.getValue(),
            },
            {
              id: 'uploaded',
              header: 'Uploaded',
              minWidth: 10,
              accessorFn: (row: TODO) =>
                row.uploadedDate !== null ? <UserDateTime value={row.created} format={'date'} /> : '-',
              cell: info => info.getValue(),
            },
            {
              id: 'actions',
              header: 'Actions',
              minWidth: 20,
              accessorFn: (row: TODO) => (
                <>
                  <Button rel="noopener noreferrer" target="_blank" style={{marginRight: 10}} href={row.url}>
                    View
                  </Button>
                  <Button id={'project-detail-information__remove'} onClick={() => handleDocumentDelete(row.uuid)}>
                    Delete
                  </Button>
                </>
              ),
              cell: info => info.getValue(),
            },
          ]}
        />
      )}
    </ProjectDetailCard>
  );
};
