import {useEffect, useMemo, useState} from 'react';
import produce from 'immer';
import {Coordinates} from '../../../utils/Coordinates';
import {useSelector} from '../../../redux/react-redux';
import {useAisVesselsQuery} from '../../../queries/useAisVesselsQuery';
import {parseIntTS} from '../../../utils/parseNumberTS';
import {RootState} from '../../../redux/store';
import {createSelector} from '@reduxjs/toolkit';

interface SelectVessel {
  id: number;
}

interface APIVessel {
  id: number;
  imo: string;
  vessel: {
    imo: string;
  };
  coordinates: [number, number];
}

const apiVesselsSelector = createSelector(
  (state: RootState) => state?.api.market.vessel.data?.data?.items || [],
  items => items
);

export const useMatchClipboardWithAisVessels = () => {
  const aisVesselsQuery = useAisVesselsQuery();

  const apiVessels: Array<APIVessel> = useSelector(apiVesselsSelector);

  const selectedVessels: Array<SelectVessel> = useSelector(store => store.gridClipboard.market.vessels || []);

  /*
  offerIdToImo is a Record to match vesselOfferIds with the IMO of a vessel

  when ever the user looks at a new page of vessels, the api recieves the new vessels,
  this effect reads all vessels and save a new entry in the record vesselOfferId => vesselIMO

  in further steps the record is used to mapping selected vessels with aisData
   */
  const [offerIdToImo, setOfferIdToImo] = useState<Record<string, number>>({});
  useEffect(() => {
    setOfferIdToImo(
      produce(offerIdToImo, draftOfferIdToImo => {
        apiVessels.forEach(offer => {
          draftOfferIdToImo[offer.id] = parseIntTS(offer.vessel.imo);
        });
      })
    );
  }, [apiVessels, offerIdToImo]);

  const imosToShow: number[] = useMemo(() => {
    return selectedVessels.map(offer => offerIdToImo[offer.id]).filter(imo => imo);
  }, [selectedVessels, offerIdToImo]);

  /*
  filter the ais data for entries with a imo which is included
  */
  const points: Coordinates[] = useMemo(() => {
    const aisVessels = aisVesselsQuery.data ?? [];
    return aisVessels
      .filter(aisVessel => imosToShow.includes(aisVessel.imo!))
      .map(aisVessel => [aisVessel.coordinates![1], aisVessel.coordinates![0]]);
  }, [imosToShow, aisVesselsQuery.data]);

  return {
    points,
    notMatchedVessels: imosToShow.length - points.length,
  };
};
