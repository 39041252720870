import {useState} from 'react';
import styled, {keyframes} from 'styled-components';

export const CookieBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState<boolean>(() =>
    localStorage.getItem('acceptedCookies') ? true : false
  );

  const onAccept = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setAcceptedCookies(true);
  };

  return (
    <>
      {!acceptedCookies && (
        <CookieBannerStyledContainer>
          <CookieBannerStyled>
            This site uses cookies. By continuing to browse this site, you are agreeing to our use of cookies.{' '}
            <MoreDetailsLink href="https://www.seabo.com/privacy-policy">More details</MoreDetailsLink>
            <ButtonContainer>
              <StyledButton onClick={() => onAccept()}>X</StyledButton>
            </ButtonContainer>
          </CookieBannerStyled>
        </CookieBannerStyledContainer>
      )}
    </>
  );
};

const showCookieBanner = keyframes`
  from {
    transform: translateY(150vh);
  }
  to {
    transform: translateY(0);
  }
`;

const CookieBannerStyledContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const CookieBannerStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--color-gray-4);
  color: var(--color-black);
  border-top: 1px var(--color-gray-4) solid;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translateY(100vh);
  animation: ${showCookieBanner} 2s ease-in-out forwards;
  animation-delay: 500ms;
`;

const MoreDetailsLink = styled.a`
  margin: 0px 20px 0 20px;
  color: blue;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: -18px;
  right: 0;
`;

const StyledButton = styled.div`
  min-width: 32px;
  cursor: pointer;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--color-gray-3);
  background-color: var(--color-gray-4);
  height: 36px;
  font-size: var(--font-size-md);
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: none;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;
