import {FilterProviderState} from '../../components/FilterProvider/FilterProviderState';
import {
  VesselDatabaseDatabaseFilter,
  VesselDatabaseFilterBranchDefinitions,
} from '../../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';
import {useFilterProvider} from '../../components/FilterProvider/useFilterProvider';
import {useDispatch, useSelector} from '../../redux/react-redux';
import {VesselDatabaseActions} from '../../redux/VesselDatabase';

export const useVesselDatabaseFilterProvider = () => {
  const {filterProviderState} = useSelector(state => state.vesselDatabase);
  const dispatch = useDispatch();
  const setPageIndex = (pageIndex: number) => dispatch(VesselDatabaseActions.setPageIndex(pageIndex));
  const setFilterSettings = (filterSettings: VesselDatabaseDatabaseFilter) =>
    dispatch(VesselDatabaseActions.setFilterSettings(filterSettings));
  const onFilterProviderStateChange = (
    filterProviderState: FilterProviderState<typeof VesselDatabaseFilterBranchDefinitions>
  ) => dispatch(VesselDatabaseActions.setFilterProviderState(filterProviderState));

  const onFilterSettingsChange = (filterSettings: VesselDatabaseDatabaseFilter) => {
    setFilterSettings(filterSettings);
  };

  const onFilterSettingsChangeByUser = () => {
    setPageIndex(1);
  };

  const filterProviderApi = useFilterProvider({
    name: 'vesselDatabase',
    filterBranchDefinitions: VesselDatabaseFilterBranchDefinitions,
    filterProviderState,
    onFilterSettingsChange,
    onFilterSettingsChangeByUser,
    onFilterProviderStateChange,
  });

  return filterProviderApi;
};
