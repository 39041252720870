/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum LocationType {
  Port = 'port',
  Country = 'country',
  Tradingarea = 'tradingarea',
  Collection = 'collection',
  Unknown = 'unknown',
}

export function LocationTypeFromJSON(json: any): LocationType {
  return LocationTypeFromJSONTyped(json, false);
}

export function LocationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationType {
  return json as LocationType;
}

export function LocationTypeToJSON(value?: LocationType | null): any {
  return value as any;
}
