import {useDispatch} from 'react-redux';
import {MarketActions, MarketType} from '../../../redux/Market';
import {GridMetaData} from '../GridMetaData';

export const useSetGridMetaData = (marketType: MarketType) => {
  const dispatch = useDispatch();

  const setGridMetaData = ({page, pageSize, sortedBy}: Partial<GridMetaData>) => {
    if (page) {
      dispatch(MarketActions.setPage({marketType, page}));
    }
    if (sortedBy) {
      dispatch(MarketActions.setSortedBy({marketType, sortedBy}));
    }
    if (pageSize) {
      dispatch(MarketActions.setPageSize({marketType, pageSize}));
    }
  };

  return setGridMetaData;
};

export const useSetPage = (marketType: MarketType) => {
  const dispatch = useDispatch();

  const setPage = (page: number) => {
    dispatch(MarketActions.setPage({marketType, page}));
  };

  return setPage;
};
