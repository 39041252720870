/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  TradeFlowPortActivityLocation,
  TradeFlowPortActivityLocationFromJSON,
  TradeFlowPortActivityLocationFromJSONTyped,
  TradeFlowPortActivityLocationToJSON,
  TradeFlowPortActivityMatchingConfidence,
  TradeFlowPortActivityMatchingConfidenceFromJSON,
  TradeFlowPortActivityMatchingConfidenceFromJSONTyped,
  TradeFlowPortActivityMatchingConfidenceToJSON,
  TradeFlowPortActivityType,
  TradeFlowPortActivityTypeFromJSON,
  TradeFlowPortActivityTypeFromJSONTyped,
  TradeFlowPortActivityTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface TradeFlowPortActivity
 */
export interface TradeFlowPortActivity {
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  eta: string | null;
  /**
   *
   * @type {TradeFlowPortActivityMatchingConfidence}
   * @memberof TradeFlowPortActivity
   */
  matchingConfidence: TradeFlowPortActivityMatchingConfidence;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  etd: string | null;
  /**
   *
   * @type {TradeFlowPortActivityType}
   * @memberof TradeFlowPortActivity
   */
  type: TradeFlowPortActivityType;
  /**
   *
   * @type {TradeFlowPortActivityLocation}
   * @memberof TradeFlowPortActivity
   */
  importLocation: TradeFlowPortActivityLocation;
  /**
   *
   * @type {TradeFlowPortActivityLocation}
   * @memberof TradeFlowPortActivity
   */
  exportLocation: TradeFlowPortActivityLocation;
  /**
   *
   * @type {number}
   * @memberof TradeFlowPortActivity
   */
  volume: number | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  commodityClass: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  agent: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  carrierName: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  commodityProduct: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  charterer: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  shipper: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  operator: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  shipmentStatus: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  cargoStatus: string | null;
  /**
   *
   * @type {string}
   * @memberof TradeFlowPortActivity
   */
  commodity: string | null;
}

export function TradeFlowPortActivityFromJSON(json: any): TradeFlowPortActivity {
  return TradeFlowPortActivityFromJSONTyped(json, false);
}

export function TradeFlowPortActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeFlowPortActivity {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    eta: json['eta'],
    matchingConfidence: TradeFlowPortActivityMatchingConfidenceFromJSON(json['matchingConfidence']),
    etd: json['etd'],
    type: TradeFlowPortActivityTypeFromJSON(json['type']),
    importLocation: TradeFlowPortActivityLocationFromJSON(json['importLocation']),
    exportLocation: TradeFlowPortActivityLocationFromJSON(json['exportLocation']),
    volume: json['volume'],
    commodityClass: json['commodityClass'],
    agent: json['agent'],
    carrierName: json['carrierName'],
    commodityProduct: json['commodityProduct'],
    charterer: json['charterer'],
    shipper: json['shipper'],
    operator: json['operator'],
    shipmentStatus: json['shipmentStatus'],
    cargoStatus: json['cargoStatus'],
    commodity: json['commodity'],
  };
}

export function TradeFlowPortActivityToJSON(value?: TradeFlowPortActivity | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    eta: value.eta,
    matchingConfidence: TradeFlowPortActivityMatchingConfidenceToJSON(value.matchingConfidence),
    etd: value.etd,
    type: TradeFlowPortActivityTypeToJSON(value.type),
    importLocation: TradeFlowPortActivityLocationToJSON(value.importLocation),
    exportLocation: TradeFlowPortActivityLocationToJSON(value.exportLocation),
    volume: value.volume,
    commodityClass: value.commodityClass,
    agent: value.agent,
    carrierName: value.carrierName,
    commodityProduct: value.commodityProduct,
    charterer: value.charterer,
    shipper: value.shipper,
    operator: value.operator,
    shipmentStatus: value.shipmentStatus,
    cargoStatus: value.cargoStatus,
    commodity: value.commodity,
  };
}
