import {NamedValue} from '../FilterConversion/utils/NamedValue';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export interface DatabaseType {
  logFitted: boolean;
  lakesFitted: boolean;
  tweenDecks: boolean;
  a60Bulkhead: boolean;
  co2Fitted: boolean;
  boxShaped: boolean;
  openHatched: boolean;
  cellular: boolean;
  sternThrusted: boolean;
  bowThrusted: boolean;
  itfFitted: boolean;
  scrubberFitted: boolean;
  iceClass: boolean;
  geared?: boolean;
  grabbed?: boolean;
}

export interface InternalType {
  logFitted: boolean;
  lakesFitted: boolean;
  tweenDecks: boolean;
  a60Bulkhead: boolean;
  co2Fitted: boolean;
  boxShaped: boolean;
  openHatched: boolean;
  cellular: boolean;
  sternThrusted: boolean;
  bowThrusted: boolean;
  itfFitted: boolean;
  scrubberFitted: boolean;
  iceClass: boolean;
  geared: NamedValue<Gear> | null;
  grabbed: NamedValue<Grab> | null;
}

export const Defaults: InternalType = {
  logFitted: false,
  lakesFitted: false,
  tweenDecks: false,
  a60Bulkhead: false,
  co2Fitted: false,
  boxShaped: false,
  openHatched: false,
  cellular: false,
  sternThrusted: false,
  bowThrusted: false,
  itfFitted: false,
  scrubberFitted: false,
  iceClass: false,
  geared: null,
  grabbed: null,
};

type Gear = 'geared' | 'gearless';

export const GearedOptions: NamedValue<Gear>[] = [
  {name: 'Geared', value: 'geared'},
  {name: 'Gearless', value: 'gearless'},
];

type Grab = 'grabbed' | 'grabless';

export const GrabOptions: NamedValue<Grab>[] = [
  {name: 'Yes', value: 'grabbed'},
  {name: 'No', value: 'grabless'},
];

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    ...database,
    geared: convertGearedFromDatabase(database.geared),
    grabbed: convertGrabbedFromDatabase(database.grabbed),
  };
};

const convertGearedFromDatabase = (value: boolean | undefined | null): NamedValue<Gear> | null => {
  switch (value) {
    case undefined:
      return null;
    case null:
      return null;
    case true:
      return GearedOptions[0];
    case false:
      return GearedOptions[1];
  }
};

const convertGrabbedFromDatabase = (value: boolean | undefined | null): NamedValue<Grab> | null => {
  switch (value) {
    case undefined:
      return null;
    case null:
      return null;
    case true:
      return GrabOptions[0];
    case false:
      return GrabOptions[1];
  }
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  return {
    ...internal,
    geared: convertGearedToDatabase(internal.geared),
    grabbed: convertGrabbedToDatabase(internal.grabbed),
  };
};

const convertGearedToDatabase = (value: NamedValue<Gear> | null): boolean | undefined => {
  if (!value) {
    return undefined;
  }
  return value.value === 'geared';
};

const convertGrabbedToDatabase = (value: NamedValue<Grab> | null): boolean | undefined => {
  if (!value) {
    return undefined;
  }
  return value.value === 'grabbed';
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Features',
  branch: 'features',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
