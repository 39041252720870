/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CreateCompany
 */
export interface CreateCompany {
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  addressName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  addressStreet?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  addressStreetTwo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  addressStreetNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  addressZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  addressCity?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  addressCountry?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  contactPersonFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  contactPersonLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  contactPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  contactFax?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  contactWebsite?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateCompany
   */
  foundYear?: number | null;
  /**
   * Tax id of an arbitrary state, hence in an arbitrary format
   * @type {string}
   * @memberof CreateCompany
   */
  taxId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  employees?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateCompany
   */
  vesselsManaged?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  business?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  companyType?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateCompany
   */
  subscriptionPlan?: string;
  /**
   * Contains null, when the company never was in the promotion phase.
   * While in the promotion phase, the users of the company will
   * be able to use features that are usually only unlocked
   * by a paid {@link SubscriptionPlan}.
   * @type {Date}
   * @memberof CreateCompany
   */
  promotionUntil?: Date | null;
  /**
   * This property is deprecated and is replaced by the subscription plan.
   * @type {boolean}
   * @memberof CreateCompany
   */
  isPayingCustomer: boolean;
}

export function CreateCompanyFromJSON(json: any): CreateCompany {
  return CreateCompanyFromJSONTyped(json, false);
}

export function CreateCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompany {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    addressName: !exists(json, 'addressName') ? undefined : json['addressName'],
    addressStreet: !exists(json, 'addressStreet') ? undefined : json['addressStreet'],
    addressStreetTwo: !exists(json, 'addressStreetTwo') ? undefined : json['addressStreetTwo'],
    addressStreetNumber: !exists(json, 'addressStreetNumber') ? undefined : json['addressStreetNumber'],
    addressZip: !exists(json, 'addressZip') ? undefined : json['addressZip'],
    addressCity: !exists(json, 'addressCity') ? undefined : json['addressCity'],
    addressCountry: !exists(json, 'addressCountry') ? undefined : json['addressCountry'],
    contactPersonFirstName: !exists(json, 'contactPersonFirstName') ? undefined : json['contactPersonFirstName'],
    contactPersonLastName: !exists(json, 'contactPersonLastName') ? undefined : json['contactPersonLastName'],
    contactEmail: !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
    contactPhone: !exists(json, 'contactPhone') ? undefined : json['contactPhone'],
    contactFax: !exists(json, 'contactFax') ? undefined : json['contactFax'],
    contactWebsite: !exists(json, 'contactWebsite') ? undefined : json['contactWebsite'],
    foundYear: !exists(json, 'foundYear') ? undefined : json['foundYear'],
    taxId: !exists(json, 'taxId') ? undefined : json['taxId'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    vesselsManaged: !exists(json, 'vesselsManaged') ? undefined : json['vesselsManaged'],
    business: !exists(json, 'business') ? undefined : json['business'],
    companyType: !exists(json, 'companyType') ? undefined : json['companyType'],
    subscriptionPlan: !exists(json, 'subscriptionPlan') ? undefined : json['subscriptionPlan'],
    promotionUntil: !exists(json, 'promotionUntil')
      ? undefined
      : json['promotionUntil'] === null
      ? null
      : new Date(json['promotionUntil']),
    isPayingCustomer: json['isPayingCustomer'],
  };
}

export function CreateCompanyToJSON(value?: CreateCompany | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    addressName: value.addressName,
    addressStreet: value.addressStreet,
    addressStreetTwo: value.addressStreetTwo,
    addressStreetNumber: value.addressStreetNumber,
    addressZip: value.addressZip,
    addressCity: value.addressCity,
    addressCountry: value.addressCountry,
    contactPersonFirstName: value.contactPersonFirstName,
    contactPersonLastName: value.contactPersonLastName,
    contactEmail: value.contactEmail,
    contactPhone: value.contactPhone,
    contactFax: value.contactFax,
    contactWebsite: value.contactWebsite,
    foundYear: value.foundYear,
    taxId: value.taxId,
    employees: value.employees,
    vesselsManaged: value.vesselsManaged,
    business: value.business,
    companyType: value.companyType,
    subscriptionPlan: value.subscriptionPlan,
    promotionUntil:
      value.promotionUntil === undefined
        ? undefined
        : value.promotionUntil === null
        ? null
        : value.promotionUntil.toISOString(),
    isPayingCustomer: value.isPayingCustomer,
  };
}
