import {RowValueType} from '../../utils/RowValueType';
import {ReactNode} from 'react';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import {AccessorFn} from '@tanstack/react-table';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';

import {NotAvailable} from '../../../../utils/NotAvailable';
import {SeaboVessel} from '../../../../api/node-backend/generated';
import {GrainColumnAccessorOptions} from './grainColumn';
import {Blurred} from '../../utils/Blurred';

export interface GearsColumnAccessorOptions {
  blurred?: boolean;
}

type GearEntry = {
  type: 'gear';
  quantity?: number;
  capacity?: number;
};

type GrabEntry = {
  type: 'grab';
  quantity?: number;
  capacity?: number;
};
type GearGrabEntry = GearEntry | GrabEntry;

const gearsAccessor =
  (type: RowValueType, showQuantity: boolean, options: GearsColumnAccessorOptions): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);
    const hasGears = vessel.gears && vessel.gears.length > 0;
    const {blurred} = options;

    if (!hasGears) {
      return <Blurred blurred={blurred}>No</Blurred>;
    }
    if (!showQuantity) {
      return <Blurred blurred={blurred}>Yes</Blurred>;
    }
    const gears: GearEntry | undefined = vessel.gears.find((item: GearGrabEntry) => item.type === 'gear');
    const grabs: GrabEntry | undefined = vessel.gears.find((item: GearGrabEntry) => item.type === 'grab');

    let text = gears
      ? `${gears.quantity ?? NotAvailable}x${gears.capacity ?? NotAvailable}`
      : `${NotAvailable}x${NotAvailable}`;

    if (grabs) {
      text += ` / ${grabs.quantity ?? NotAvailable}x${grabs.capacity ?? NotAvailable}`;
    }

    return <Blurred blurred={options.blurred}>{text}</Blurred>;
  };
export const gearsColumn = ({
  type,
  minWidth = 4,
  showQuantity = false,
  sortable = false,
  options = {},
}: {
  showQuantity?: boolean;
  type?: RowValueType;
  minWidth?: number;
  sortable?: boolean;
  options?: GrainColumnAccessorOptions;
}): CustomColumnDef<SeaboVessel> => ({
  header: 'Gear',
  id: 'gears',
  accessorFn: gearsAccessor(type, showQuantity, options),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
  enableSorting: sortable,
});
