import {Vessel} from '../useMyFleetQuery/useMyFleetQuery';
import {getNextOpen} from './parts/getNextOpen';
import {getShipBuilder} from './parts/getShipBuilder';
import {tabsTime} from './utils';
import {getVesselType} from './parts/getVesselType';
import {getConsumption} from './parts/getConsumption';
import {getDraftAndDwat} from './parts/getDraftAndDwat';
import {getSize} from './parts/getSize';
import {getGears} from './parts/getGears';
import {getLoaBeam} from './parts/getLoaBeam';

export const getPositionStringContainer = (vessel: Vessel): string => {
  const nextOpen = getNextOpen(vessel);
  const shipBuilder = getShipBuilder(vessel);
  const consumption = getConsumption(vessel);

  return `${vessel.name}${tabsTime(3)}${nextOpen}
${getVesselType(vessel)} ${shipBuilder}
${getDraftAndDwat(vessel)}
${getLoaBeam(vessel)}${getGears(vessel)}
${getSize(vessel)}
${consumption}`;
};
