/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationParty,
  NegotiationPartyFromJSON,
  NegotiationPartyFromJSONTyped,
  NegotiationPartyToJSON,
  NegotiationSubjectStatus,
  NegotiationSubjectStatusFromJSON,
  NegotiationSubjectStatusFromJSONTyped,
  NegotiationSubjectStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface NegotiationSubject
 */
export interface NegotiationSubject {
  /**
   *
   * @type {string}
   * @memberof NegotiationSubject
   */
  id: string;
  /**
   * ID of the root term. It doesn't have to be created in the first round, but it is the first term in the chain.
   * @type {string}
   * @memberof NegotiationSubject
   */
  originId: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationSubject
   */
  termId: string | null;
  /**
   *
   * @type {string}
   * @memberof NegotiationSubject
   */
  roundResultId: string;
  /**
   *
   * @type {string}
   * @memberof NegotiationSubject
   */
  negotiationId: string;
  /**
   *
   * @type {string}
   * @memberof NegotiationSubject
   */
  text: string;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationSubject
   */
  party: NegotiationParty;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationSubject
   */
  textLastUpdatedBy: NegotiationParty;
  /**
   *
   * @type {NegotiationParty}
   * @memberof NegotiationSubject
   */
  lastModifiedBy: NegotiationParty;
  /**
   *
   * @type {Date}
   * @memberof NegotiationSubject
   */
  lastModifiedAt: Date;
  /**
   *
   * @type {NegotiationSubjectStatus}
   * @memberof NegotiationSubject
   */
  status: NegotiationSubjectStatus;
}

export function NegotiationSubjectFromJSON(json: any): NegotiationSubject {
  return NegotiationSubjectFromJSONTyped(json, false);
}

export function NegotiationSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): NegotiationSubject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    originId: json['originId'],
    termId: json['termId'],
    roundResultId: json['roundResultId'],
    negotiationId: json['negotiationId'],
    text: json['text'],
    party: NegotiationPartyFromJSON(json['party']),
    textLastUpdatedBy: NegotiationPartyFromJSON(json['textLastUpdatedBy']),
    lastModifiedBy: NegotiationPartyFromJSON(json['lastModifiedBy']),
    lastModifiedAt: new Date(json['lastModifiedAt']),
    status: NegotiationSubjectStatusFromJSON(json['status']),
  };
}

export function NegotiationSubjectToJSON(value?: NegotiationSubject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    originId: value.originId,
    termId: value.termId,
    roundResultId: value.roundResultId,
    negotiationId: value.negotiationId,
    text: value.text,
    party: NegotiationPartyToJSON(value.party),
    textLastUpdatedBy: NegotiationPartyToJSON(value.textLastUpdatedBy),
    lastModifiedBy: NegotiationPartyToJSON(value.lastModifiedBy),
    lastModifiedAt: value.lastModifiedAt.toISOString(),
    status: NegotiationSubjectStatusToJSON(value.status),
  };
}
