import {QueryClient, useQuery} from '@tanstack/react-query';
import {VesselDatabaseDatabaseFilter} from '../../../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';
import {ApiError} from '../../../api/utils/ApiError';
import {vesselDBVesselsApi} from '../../../api/node-backend/nodeBackendApis';
import {ListVesselsResponse} from '../../../api/node-backend/generated';
import {
  ListVesselsRequest,
  ListVesselsRequestSortOrderEnum,
} from '../../../api/node-backend/generated/models/ListVesselsRequest';
import {convertFilterSettings} from './convertFilterSettings';
import {ColumnSort} from '@tanstack/react-table';
import {UseQueryOptions} from '../../../api/utils/ReactQuery';

const ListVesselsQueryKey = 'vesselDBApi.listVessels';

export interface ListVesselsParams {
  pageIndex: number;
  pageSize: number;
  sortingRule?: ColumnSort;
  filterSettings?: VesselDatabaseDatabaseFilter;
}

export const useListVesselsQuery = (
  request: ListVesselsParams,
  options?: UseQueryOptions<ListVesselsResponse, ApiError>
) => {
  const listVesselsRequest: ListVesselsRequest = convertRequest(request);
  return useQuery({
    queryKey: [ListVesselsQueryKey, listVesselsRequest],
    queryFn: () => vesselDBVesselsApi.listVessels({listVesselsRequest}),
    ...options,
  });
};

export const invalidateListVesselsQuery = async (queryClient: QueryClient, request: ListVesselsParams) => {
  const listVesselsRequest: ListVesselsRequest = convertRequest(request);
  await queryClient.invalidateQueries({
    queryKey: [ListVesselsQueryKey, listVesselsRequest],
  });
};

const convertRequest = (request: ListVesselsParams): ListVesselsRequest => {
  const {pageIndex, pageSize, sortingRule, filterSettings} = request;
  const sortField = sortingRule?.id;
  const sortOrder =
    sortingRule && sortingRule.desc ? ListVesselsRequestSortOrderEnum.Desc : ListVesselsRequestSortOrderEnum.Asc;
  const filters = convertFilterSettings(filterSettings);
  const convertedRequest: ListVesselsRequest = {
    pageIndex: pageIndex - 1,
    pageSize,
    sortField,
    sortOrder,
    filters,
  };
  return convertedRequest;
};
