import {FC} from 'react';
import {NegotiationActor} from '../../../../../../api/node-backend/generated';
import {UserDateTime} from '../../../../../../components/DateTime/UserDateTime';
import {NegotiationActorLabel} from '../../../../Components/NegotiationPartyLabel';
import {MetaContainer, NoWrap, Faded} from './shared';

type TermMetaProps = {
  actor: NegotiationActor;
  isSelf: boolean;
  committedAt: Date | null;
  roundIndex?: number;
};

export const TermMeta: FC<TermMetaProps> = ({actor, isSelf, committedAt, roundIndex}) => {
  return (
    <MetaContainer data-testid="TermMeta">
      <NoWrap>
        <Faded>last edit:</Faded> <NegotiationActorLabel actor={actor} isSelf={isSelf} />
        {roundIndex !== undefined && <span> ({roundIndex + 1})</span>}
      </NoWrap>
      <NoWrap>
        {committedAt ? <UserDateTime value={committedAt.toISOString()} format={'dateTime'} /> : 'not sent yet'}
      </NoWrap>
    </MetaContainer>
  );
};
