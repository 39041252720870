import React, {useState} from 'react';
import BaseModal from '../../../atoms/BaseModal';
import {Button} from '../../../atoms/Button/Button';
import {connect} from 'react-redux';
import vesselService from '../../../redux/ApiService/vesselService';
import {GridClipboardActions, Section, Subsection} from '../../../redux/GridClipboard';
import cargoService from '../../../redux/ApiService/cargoService';
import MarketService from '../../../redux/ApiService/MarketService';
import {TODO} from '../../../utils/TODO';

type MultiArchiveProps = {
  type?: string;
  reload: () => void;
  section?: string;
  targets?: TODO;
  updateVessels: (vessel: TODO, close: TODO, setError: TODO) => Promise<void>;
  updateCargoes: (vessel: TODO, close: TODO, setError: TODO) => Promise<void>;
  archive: boolean;
  resetSelection: (section: Section, subSection?: Subsection) => void;
  multiArchiveMarket?: TODO;
};

const MultiArchive = ({
  type,
  targets,
  updateVessels,
  updateCargoes,
  archive,
  reload,
  resetSelection,
  section,
  multiArchiveMarket,
}: MultiArchiveProps) => {
  const [disableButtons, setDisabledButtons] = useState(false);
  const [error, setError] = useState(false);
  const pluralLabel = type === 'vessel' ? 'vessels' : 'cargoes';

  return (
    <BaseModal middle title={archive ? `Restore ${pluralLabel}` : `Archive ${pluralLabel}`} narrow>
      {({close}: $TSFixMe) => {
        const onClick = async () => {
          setDisabledButtons(true);
          setError(false);
          if (section === 'market') {
            await multiArchiveMarket({offers: targets}, type).reload();
            resetSelection('market', type === 'vessel' ? 'vessels' : 'cargoes');
            close();
          } else {
            if (type === 'vessel') {
              await updateVessels({isArchived: !archive, vessels: targets}, close, setError);
              reload();
              resetSelection('portfolio');
              close();
            } else if (type === 'cargo') {
              await updateCargoes({isArchived: !archive, cargoes: targets}, close, setError);
              reload();
              resetSelection('portfolio');
              close();
            }
          }
        };

        return (
          <div className={'archive-modal'}>
            <div className={'archive-modal__text'}>
              Do you want to {archive ? `restore this ${type}` : `archive this ${type}`} collection?
              {section === 'market' && <p>Please note public shared offer can't be archived</p>}
            </div>
            {error && <div className={'modal__error'}>Something went wrong, please try again</div>}
            <div className={'archive-modal__buttons'}>
              <Button
                disabled={disableButtons && !error}
                label={archive ? `Restore ${pluralLabel}` : `Archive ${pluralLabel}`}
                bold
                primary
                upper
                id={'description__edit__save'}
                onClick={onClick}
              />
            </div>
          </div>
        );
      }}
    </BaseModal>
  );
};

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  updateVessels: (body: $TSFixMe) => dispatch(vesselService.multiPatch(body)),
  updateCargoes: (body: $TSFixMe) => dispatch(cargoService.multiPatch(body)),
  resetSelection: (payload: $TSFixMe) => dispatch(GridClipboardActions.resetSelection(payload)),
  multiArchiveMarket: (body: $TSFixMe, type: $TSFixMe) => dispatch(MarketService.multiArchive(body, type)),
});

export default connect(null, mapDispatchToProps)(MultiArchive);
