/* tslint:disable */
/* eslint-disable */
/**
 * Parser
 * Parser Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Commission
 */
export interface Commission {
  /**
   *
   * @type {number}
   * @memberof Commission
   */
  addressCommission?: number;
  /**
   *
   * @type {number}
   * @memberof Commission
   */
  brokerFee?: number;
  /**
   *
   * @type {number}
   * @memberof Commission
   */
  totalCommission?: number;
  /**
   *
   * @type {string}
   * @memberof Commission
   */
  commOptions?: string;
  /**
   *
   * @type {boolean}
   * @memberof Commission
   */
  empty?: boolean;
}

export function CommissionFromJSON(json: any): Commission {
  return CommissionFromJSONTyped(json, false);
}

export function CommissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Commission {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    addressCommission: !exists(json, 'addressCommission') ? undefined : json['addressCommission'],
    brokerFee: !exists(json, 'brokerFee') ? undefined : json['brokerFee'],
    totalCommission: !exists(json, 'totalCommission') ? undefined : json['totalCommission'],
    commOptions: !exists(json, 'commOptions') ? undefined : json['commOptions'],
    empty: !exists(json, 'empty') ? undefined : json['empty'],
  };
}

export function CommissionToJSON(value?: Commission | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    addressCommission: value.addressCommission,
    brokerFee: value.brokerFee,
    totalCommission: value.totalCommission,
    commOptions: value.commOptions,
    empty: value.empty,
  };
}
