import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ReactNode} from 'react';

export interface ModalState {
  modal: ReactNode | null;
}

const initialState: ModalState = {
  modal: null,
};

type ShowPayload = ReactNode;

/** @deprecated use AntD Modal instead */
const ModalSlice = createSlice({
  name: 'Modal',
  initialState,
  reducers: {
    show(state, action: PayloadAction<ShowPayload>) {
      const modal = action.payload;
      state.modal = modal;
    },
    hide(_state) {
      return initialState;
    },
  },
});

/** @deprecated use AntD Modal instead */
export const modalReducer = ModalSlice.reducer;

/** @deprecated use AntD Modal instead */
export const ModalActions = ModalSlice.actions;
