import {FC, ReactElement} from 'react';
import {Col, Row} from 'antd';

export const TwoColumnLayout: FC<{map: ReactElement; grid: ReactElement}> = ({map, grid}) => {
  return (
    <Row style={{minHeight: 'calc(100vh - 350px)'}}>
      <Col span={12}>
        <div style={{height: 'calc(100vh - 350px)', position: 'sticky', top: 0}}>{map}</div>
      </Col>
      <Col span={12}>{grid}</Col>
    </Row>
  );
};
