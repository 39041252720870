import {useContext, useEffect} from 'react';
import {VoyageCharterContext} from '../VoyageCharterContext';
import produce from 'immer';

const isVesselExistingOnPlatform = async (plattformId: number): Promise<boolean> => {
  const response = await fetch('/api/vessels/' + plattformId);
  return response.status === 200;
};

/**
 Problem:
 User has selected a vessel and then went to Portfolio and deleted the vessel.
 Afterwards he went back to Voyage Calc, in this case the data of the vessel should be preserved,
 but the link to the vessel should be removed
 */
export const useCheckIfVesselExist = () => {
  const {setInputState, state} = useContext(VoyageCharterContext);
  const vesselId = state.inputState.vessel.id;

  const removeVessel = () => {
    setInputState(
      produce(state.inputState, draftState => {
        draftState.vessel.id = undefined;
      })
    );
  };

  const checkIfVesselExist = async () => {
    if (vesselId) {
      const vesselExistingOnPlatform = await isVesselExistingOnPlatform(vesselId);
      if (!vesselExistingOnPlatform) {
        removeVessel();
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkIfVesselExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
