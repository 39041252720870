/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  InviteToCompanyResponseData,
  InviteToCompanyResponseDataFromJSON,
  InviteToCompanyResponseDataFromJSONTyped,
  InviteToCompanyResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface InviteToCompanyResponse
 */
export interface InviteToCompanyResponse {
  /**
   *
   * @type {InviteToCompanyResponseData}
   * @memberof InviteToCompanyResponse
   */
  data: InviteToCompanyResponseData;
}

export function InviteToCompanyResponseFromJSON(json: any): InviteToCompanyResponse {
  return InviteToCompanyResponseFromJSONTyped(json, false);
}

export function InviteToCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteToCompanyResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: InviteToCompanyResponseDataFromJSON(json['data']),
  };
}

export function InviteToCompanyResponseToJSON(value?: InviteToCompanyResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: InviteToCompanyResponseDataToJSON(value.data),
  };
}
