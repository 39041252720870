import styled from 'styled-components';
import {ClockCircleOutlined} from '@ant-design/icons';
import {HeaderBox} from './HeaderBox';

const ValueSpan = styled.span`
  color: var(--color-black);
  font-size: var(--font-size-lg);
  font-weight: 700;
  padding-top: 5px;

  .ant-affix & {
    font-size: var(--font-size-lg);
  }
`;

export const DurationBox = ({
  isMarginRight = true,
  durationAccuracy,
  value,
}: {
  value: number;
  durationAccuracy: number;
  isMarginRight?: boolean;
}) => {
  return (
    <HeaderBox
      title="Duration"
      isMarginRight={isMarginRight}
      renderIcon={color => <ClockCircleOutlined style={{fontSize: 18, color}} />}>
      <ValueSpan data-cy={'duration'}> {isFinite(value) ? value.toFixed(durationAccuracy) : 'N/A'} days</ValueSpan>
    </HeaderBox>
  );
};
