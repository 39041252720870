import {ProfileInput} from '../../../ProfileInput';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../../atoms/Select/Indicators';
import Select from '../../../../atoms/Select/Select';
import {Fragment} from 'react';

const employeesOptions = [
  {label: '1 employee', value: '1 employee'},
  {label: '2-10 employees', value: '2-10 employees'},
  {label: '11-50 employees', value: '11-50 employees'},
  {label: '51-200 employees', value: '51-200 employees'},
  {label: '201-500 employees', value: '201-500 employees'},
  {label: '501-1,000 employees', value: '501-1,000 employees'},
  {label: '1,001-5,000 employees', value: '1,001-5,000 employees'},
  {label: '5,001-10,000 employees', value: '5,001-10,000 employees'},
  {label: '10,001+ employees', value: '10,001+ employees'},
];

export const companyTypesOptions = [
  {label: '', value: ''},
  {label: 'Public company', value: 'Public Company'},
  {label: 'Educational institution', value: 'Educational Institution'},
  {label: 'Self-employed', value: 'Self-Employed'},
  {label: 'Government agency', value: 'Government Agency'},
  {label: 'Nonprofit', value: 'Nonprofit'},
  {label: 'Sole proprietorship', value: 'Sole Proprietorship'},
  {label: 'Privately held', value: 'Privately Held'},
  {label: 'Partnership', value: 'Partnership'},
];

export const CompanyDetailedInformation = ({
  foundYear,
  taxId,
  companyType,
  employees,
  vesselsManaged,
  onSelect,
  onChange,
}: $TSFixMe) => {
  return (
    <Fragment>
      <ProfileInput
        label={'Tax ID'}
        selectedName={'taxId'}
        selectedValue={taxId}
        onChange={(key, value) => {
          onChange(key, `${value}`);
        }}
      />
      <ProfileInput
        label={'Founded'}
        selectedName={'foundYear'}
        selectedValue={foundYear}
        onChange={(key, value) => {
          // TODO: This should be refactored to an antd form
          const rawValue = value.replace('-', '');
          const valueAsNumber = rawValue === '' ? null : parseInt(rawValue);
          onChange(key, valueAsNumber);
        }}
        type={'number'}
        min={0}
      />

      <LabelWrapper label={'Company type'}>
        <Select
          value={companyTypesOptions.find(e => {
            return e.label === companyType;
          })}
          options={companyTypesOptions}
          getOptionLabel={(option: $TSFixMe) => option.label}
          getOptionValue={(option: $TSFixMe) => option.value}
          onChange={(e: $TSFixMe) => onSelect('companyType', e)}
          components={{
            DropdownIndicator: ArrowIndicator,
          }}
          isClearable={false}
        />
      </LabelWrapper>

      <LabelWrapper label={'Employees'}>
        <Select
          value={employeesOptions.find(e => {
            return e.label === employees;
          })}
          options={employeesOptions}
          getOptionLabel={(option: $TSFixMe) => option.label}
          getOptionValue={(option: $TSFixMe) => option.value}
          onChange={(e: $TSFixMe) => onSelect('employees', e)}
          components={{
            DropdownIndicator: ArrowIndicator,
          }}
          clearable={false}
        />
      </LabelWrapper>

      <ProfileInput
        label={'Vessels'}
        selectedName={'vesselsManaged'}
        selectedValue={vesselsManaged}
        onChange={(key, value) => {
          // TODO: This should be refactored to an antd form
          const rawValue = value.replace('-', '');
          const valueAsNumber = rawValue === '' ? null : parseInt(rawValue);

          onChange(key, valueAsNumber);
        }}
        type={'number'}
        min={0}
      />
    </Fragment>
  );
};
