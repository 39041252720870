import cx from 'classnames';
import Icon from '../../../atoms/Icon';

export const MapToggleBtn = ({
  id,
  checked,
  disabled = false,
  visualDisabled,
  onChange,
  label,
  hasSubMenu,
  isSubMenuOpen,
  onToggleSubMenu,
  specialControl,
}: $TSFixMe) => (
  <label
    className={cx('map-toggle', {'map-toggle--disabled': disabled || visualDisabled})}
    htmlFor={`map-toggle-${id}`}>
    <div
      className={cx('map-toggle__label', {'map-toggle__label--clickable': hasSubMenu})}
      onClick={
        hasSubMenu
          ? e => {
              e.preventDefault();
              if (!disabled) {
                onToggleSubMenu();
              }
            }
          : undefined
      }>
      {label}
      {hasSubMenu && <Icon type={`angle-${isSubMenuOpen ? 'up' : 'down'}`} />}
    </div>
    {!specialControl && (
      <input
        id={`map-toggle-${id}`}
        className="map-toggle__input"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    )}
    {!specialControl && <div className="map-toggle__slider" />}
    {specialControl}
  </label>
);
