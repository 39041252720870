import DateTime from '../../model/DateTime';

export type DateFormat = 'date' | 'time' | 'weekdayDateTime' | 'dateTime';

export const formatMapping = (format: DateFormat, dateTimeOrDate: DateTime | Date, timezone: string): string => {
  let dateTime: DateTime;
  if (dateTimeOrDate instanceof Date) {
    dateTime = new DateTime(dateTimeOrDate.toISOString());
  } else {
    dateTime = dateTimeOrDate;
  }

  switch (format) {
    case 'date':
      return dateTime.formattedDate(timezone);
    case 'time':
      return dateTime.formattedTime(timezone);
    case 'weekdayDateTime':
      return dateTime.formattedWeekdayDateTime(timezone);
    case 'dateTime':
    default:
      return dateTime.formattedDateTime(timezone);
  }
};
