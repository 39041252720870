import {useEffect} from 'react';
import {assert} from '../../utils/assert';
import {SidebarModes, SidebarMode} from '../../redux/Sidebar';
import {useSelector} from '../../redux/react-redux';
import capitalize from 'lodash/capitalize';

/**
 * Maps a SidebarMode to a CSS classname.
 *
 * For instance, 'wide' => 'sidebarWide'.
 */
const sidebarModeToClassName = (mode: SidebarMode): string => `sidebar${capitalize(mode)}`;

const AllSidebarModeClassNames: string[] = SidebarModes.map(sidebarModeToClassName);

const updateMainWidth = (mode: SidebarMode) => {
  const rootElement = document.getElementById('root');
  assert(rootElement);
  rootElement.classList.remove(...AllSidebarModeClassNames);
  rootElement.classList.add(sidebarModeToClassName(mode));
};

/**
 * Updates the width of the '.main' area according to the sidebar's mode,
 * by updating a CSS class on #root.
 */
export const useUpdateMainWidth = () => {
  const mode: SidebarMode = useSelector(state => state.sidebar.mode);
  useEffect(() => {
    updateMainWidth(mode);
  }, [mode]);
};
