import {Card, Col, Row} from 'antd';
import dayjs from 'dayjs';
import {FC, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {OfferStatistics} from '../../../../api/symfony/generated/models';
import {StyledRangePicker} from '../../../../components/RangePicker/StyledRangePicker';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {GroupedPerUnitFilter} from '../../Filter/GroupedPerUnitFilter';
import {OfferTypeFilter} from '../../Filter/OfferTypeFilter';
import {StyleType, StyleTypeOption} from '../../Filter/StyleTypeOption';
import {CargoCommodityCategoryChart} from '../../Sections/CargoCommodityCategoryChart/CargoCommodityCategoryChart';
import {DayOfWeekBarChartSections} from '../../Sections/DayOfWeekBoxplotsSections/DayOfWeekBarChartSections';
import {TimeserieChart} from '../../Sections/TimeserieChart/TimeserieChart';
import {TopTenChart} from '../../Sections/TopTenChart/TopTenChart';
import {TradingAreaSection} from '../../Sections/TradingAreaSection/TradingAreaSection';
import {VesselSegmentChart} from '../../Sections/VesselSegmentChart/VesselSegmentChart';
import {VesselTypeChart} from '../../Sections/VesselTypeChart/VesselTypeChart';
import {GroupedPerUnit} from '../../utils/groupBucketsByDate';
import {OfferType, transformBucketsToTimeSeries} from '../../utils/transformBucketsToTimeSeries';
import {useFilterOfferBucketsByDate} from '../../utils/useFilterOfferBucketsByDate';
import {TopTicker} from '../../Sections/OfferStatic/TopTicker';

export const AllSenders: FC<{offerStatistics: OfferStatistics}> = ({offerStatistics}) => {
  const [offerType, setOfferType] = useState<OfferType>('all');
  const [per, setPer] = useState<GroupedPerUnit>('day');
  const [styleType, setStyleType] = useState<StyleType>('line');
  const allBuckets = offerStatistics.buckets!;
  // needed to prevent chart flashing when zoom is changed in chart
  const isChangeFromDatePicker = useRef(false);
  const [initStartDate, initEndDate] = useMemo<[string, string]>(() => {
    const bucketsToTime = transformBucketsToTimeSeries({buckets: allBuckets, per, offerType: offerType});
    return [bucketsToTime[0].date, bucketsToTime[bucketsToTime.length - 1].date];
  }, [allBuckets, per, offerType]);

  const [startDate, setStartDate] = useState<string>(initStartDate);
  const [endDate, setEndDate] = useState<string>(initEndDate);

  const allBucketsInPeriod = useFilterOfferBucketsByDate({
    buckets: allBuckets,
    startDate,
    endDate,
  });

  return (
    <>
      <StyledRow gutter={[20, 20]}>
        <Col span={8}>
          <OfferTypeFilter onChange={value => setOfferType(value)} value={offerType} />
        </Col>
        <Col span={6}>
          <DateRangeContainer>
            <StyledRangePicker
              allowClear={false}
              format={DateTimeFormat.Date}
              value={[dayjs(startDate), dayjs(endDate)]}
              getPopupContainer={() => {
                return document.getElementById('topTenSenderRangePanel')!;
              }}
              placeholder={['Start date', 'End date']}
              onChange={value => {
                const [from, to] = (value ?? [dayjs(), dayjs()]).values();
                isChangeFromDatePicker.current = true;
                setStartDate(from?.format(DateTimeFormat.IsoDate) ?? '');
                setEndDate(to?.format(DateTimeFormat.IsoDate) ?? '');
              }}
              disabledDate={date => {
                return date.isBefore(initStartDate) || date.isAfter(initEndDate);
              }}
            />
          </DateRangeContainer>
          <div id="topTenSenderRangePanel" />
        </Col>
        <TopTicker buckets={allBucketsInPeriod} />
      </StyledRow>
      <TimeChartContainer>
        <StyledCard>
          <ChartSelect>
            <GroupedPerUnitFilter onChange={value => setPer(value)} value={per} />
            <StyleTypeOption onChange={value => setStyleType(value)} value={styleType} />
          </ChartSelect>
          <TimeserieChart
            onZoomIn={event => {
              isChangeFromDatePicker.current = false;
              setStartDate(event.start);
              setEndDate(event.end);
            }}
            offerType={offerType}
            per={per}
            styleType={styleType}
            buckets={allBuckets}
            grid={{
              left: 0,
              right: 5,
            }}
            zoomRange={[startDate, endDate]}
          />
        </StyledCard>
      </TimeChartContainer>
      <Row gutter={[20, 20]}>
        <TopTenChart buckets={allBucketsInPeriod} offerType={offerType} limit={10} title={'Top 10 senders'} />
        <TopTenChart
          buckets={allBucketsInPeriod}
          offerType={offerType}
          groupedByTag={'segment'}
          title={'Market segments'}
        />
        <TopTenChart
          buckets={allBucketsInPeriod}
          offerType={offerType}
          limit={10}
          groupedByTag={'tradingArea'}
          title={'Top 10 trading areas'}
        />
        <DayOfWeekBarChartSections offerType={offerType} buckets={allBucketsInPeriod} />
      </Row>
      <Divider />
      <Row gutter={[20, 20]}>
        <TradingAreaSection key={'map'} by={offerType} buckets={allBucketsInPeriod} />
        <VesselTypeChart buckets={allBucketsInPeriod} />
        <VesselSegmentChart buckets={allBucketsInPeriod} />
        <CargoCommodityCategoryChart buckets={allBucketsInPeriod} />
      </Row>
    </>
  );
};

const StyledRow = styled(Row)`
  position: sticky;
  top: 72px;
  z-index: 22;
  padding: 16px 0;
  background-color: var(--color-gray-5);
`;

const ChartSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const TimeChartContainer = styled.div`
  position: sticky;
  top: -170px;
  margin-bottom: 20px;
  z-index: 21;
  background: var(--color-gray-5);
  outline: solid 20px var(--color-gray-5);
  border-radius: var(--border-radius-card);
`;

const Divider = styled.div`
  height: 16px;
  width: 100%;
`;

const DateRangeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-button);
  font-size: var(--font-size-md);
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
  overflow: hidden;
`;

const StyledCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    padding: 24px 24px 0 24px;
  }
`;
