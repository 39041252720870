/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  Codes,
  CodesFromJSON,
  CodesFromJSONTyped,
  CodesToJSON,
  Subdivision,
  SubdivisionFromJSON,
  SubdivisionFromJSONTyped,
  SubdivisionToJSON,
} from './';

/**
 *
 * @export
 * @interface Country
 */
export interface Country {
  /**
   *
   * @type {string}
   * @memberof Country
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Country
   */
  name?: string;
  /**
   *
   * @type {Codes}
   * @memberof Country
   */
  codes?: Codes;
  /**
   *
   * @type {Array<string>}
   * @memberof Country
   */
  languages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Country
   */
  flag?: string;
  /**
   *
   * @type {Array<Subdivision>}
   * @memberof Country
   */
  subdivisions?: Array<Subdivision>;
}

export function CountryFromJSON(json: any): Country {
  return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, 'code') ? undefined : json['code'],
    name: !exists(json, 'name') ? undefined : json['name'],
    codes: !exists(json, 'codes') ? undefined : CodesFromJSON(json['codes']),
    languages: !exists(json, 'languages') ? undefined : json['languages'],
    flag: !exists(json, 'flag') ? undefined : json['flag'],
    subdivisions: !exists(json, 'subdivisions')
      ? undefined
      : (json['subdivisions'] as Array<any>).map(SubdivisionFromJSON),
  };
}

export function CountryToJSON(value?: Country | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    name: value.name,
    codes: CodesToJSON(value.codes),
    languages: value.languages,
    flag: value.flag,
    subdivisions:
      value.subdivisions === undefined ? undefined : (value.subdivisions as Array<any>).map(SubdivisionToJSON),
  };
}
