import {useState, FC} from 'react';
import {MarketFiltersActions} from '../../../redux/MarketFilters';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {FilterControls} from './FilterControls';
import {PortFilters} from './PortFilters';
import {PortGrid} from './PortGrid';
import {PortListMap} from './PortListMap';
import {usePortListFilterProvider} from './usePortListFilterProvider';
import {usePortsQuery} from './usePortsQuery';
import {ScreenHeader} from '../../../components/ScreenHeader/ScreenHeader';
import {HeroFilterMapCollapse} from '../../../components/HeroFilterMapCollapse/HeroFilterMapCollapse';
import {useLocalStorage} from '../../../utils/useLocalStorage';
import {ColumnSort} from '@tanstack/react-table';
import {PortsTourDefinition} from './PortsTourDefinition';
import {keepPreviousData} from '@tanstack/react-query';
import {MarketPortDatabaseFilter} from '../../../components/FilterProvider/Filters/Market/MarketPortFilterBranchDefinitions';

const DEFAULT_PAGE_SIZE = 10;

export const PortListScreen: FC = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('port-list-page-size', DEFAULT_PAGE_SIZE);

  const [isFilterUiOpen, setIsFilterUiOpen] = useState(true);
  const [sortedBy, setSortedBy] = useState<ColumnSort>({id: 'name', desc: false});

  const {portFilterSettings} = useSelector(state => state.marketFilters);

  const portsQuery = usePortsQuery(
    {
      page,
      pageSize,
      sortedBy,
      filterSettings: portFilterSettings,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const setPortFilterSettings = (filterSettings: MarketPortDatabaseFilter) =>
    dispatch(MarketFiltersActions.setPortFilterSettings(filterSettings));

  const portFilterProviderApi = usePortListFilterProvider({
    onChangePortFilter: filterSettings => {
      setPortFilterSettings(filterSettings);
      setPage(1);
    },
  });

  return (
    <>
      <ScreenHeader
        helmetTitle="Ports"
        breadcrumbs={[{title: 'Databases'}, {title: 'Ports'}]}
        tourDefinition={PortsTourDefinition}
        features={
          <FilterControls
            isFilterUiOpen={isFilterUiOpen}
            onChangeFilterUiOpen={setIsFilterUiOpen}
            filterProviderApi={portFilterProviderApi}
          />
        }
      />
      <HeroFilterMapCollapse
        viewOpen={isFilterUiOpen ? 'filters' : 'map'}
        filters={<PortFilters portFilterProviderApi={portFilterProviderApi} />}
        map={<PortListMap ports={portsQuery.data?.items} />}
      />
      <PortGrid
        onChangeSortedBy={setSortedBy}
        isLoading={portsQuery.isLoading}
        items={portsQuery.data?.items}
        totalCount={portsQuery.data?.totalItems}
        sortedBy={sortedBy}
        onChangePageSize={setPageSize}
        onChangePage={setPage}
        page={page}
        pageSize={pageSize}
        portFilterProviderApi={portFilterProviderApi}
      />
    </>
  );
};
