import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as VesselTypeDefinition from '../VesselType/VesselTypeDefinition';
import * as GeneralDefinition from '../General/GeneralDefinition';
import * as SizeDefinition from '../Size/SizeDefinition';
import * as DimensionsDefinition from '../Dimensions/DimensionsDefinition';
import * as IntakeDefinition from '../Intake/IntakeDefinition';
import * as FeaturesDefinition from '../Features/FeaturesDefinition';

export const VesselDatabaseFilterBranchDefinitions = makeFilterBranchDefinitions([
  VesselTypeDefinition.filterBranchDefinition,
  GeneralDefinition.filterBranchDefinition,
  SizeDefinition.filterBranchDefinition,
  DimensionsDefinition.filterBranchDefinition,
  IntakeDefinition.filterBranchDefinition,
  FeaturesDefinition.filterBranchDefinition,
] as const);

export type VesselDatabaseInternalFilter = Partial<FilterInternalType<typeof VesselDatabaseFilterBranchDefinitions>>;
export type VesselDatabaseDatabaseFilter = Partial<FilterDatabaseType<typeof VesselDatabaseFilterBranchDefinitions>>;
