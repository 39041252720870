import {useDispatch, useSelector} from '../../../redux/react-redux';
import {PortfolioType} from '../../../redux/Portfolio';
import {SortEnd, SortEndHandler, TabList} from '../../TabList/TabList';
import {RootState} from '../../../redux/store';
import {FC} from 'react';
import {TabType} from '../../TabList/Tab';
import {ModalActions} from '../../../redux/Modal';
import {assert} from '../../../utils/assert';
import sortBy from 'lodash/sortBy';
import {useGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';
import {useUpdateSubPortfolioSortOrder} from './useUpdateSubPortfolioSortOrder';
import {useRenameSubPortfolio} from './useRenameSubPortfolio';
import {useCreateSubPortfolio} from './useCreateSubPortfolio';
import {PortfolioGroupDetails, PortfolioGroupListDataItems, PortfolioGroupStub} from '../../../api/symfony/generated';
import {mapPortfolioTypeToEnum} from '../../../queries/mapPortfolioTypeToEnum';
import {useDeleteSubPortfolio} from './useDeleteSubPortfolio';
import {Delete} from '../../Modal/Delete/DeleteModal';

interface Props {
  type: PortfolioType;
  onDelete: () => void;
  onSubTabSelect: (subTab: number | 'all') => void;
}

export const ActiveSubPortfolioList: FC<Props> = props => {
  assert(props.type === 'cargo' || props.type === 'vessel', props.type);

  const dispatch = useDispatch();

  const subPortfoliosQuery = useGetSubPortfoliosQuery({type: props.type});
  const subPortfolios = subPortfoliosQuery.data?.data.items ?? [];

  const activeTab = useSelector(state => getActivePortfolioTab(state, props.type));

  const tabs = sortBy(subPortfolios, 'sortOrder');

  const isVessel = props.type === 'vessel';

  const allItemsLabel = getAllItemsLabel(props.type);

  const updateSubPortfolioSortOrder = useUpdateSubPortfolioSortOrder();

  // A tab was moved from oldIndex to newIndex
  const onSortEnd: SortEndHandler = async ({oldIndex, newIndex}: SortEnd) => {
    await updateSubPortfolioSortOrder({
      type: props.type,
      oldIndex,
      newIndex,
    });
  };

  const createSubPortfolio = useCreateSubPortfolio();

  const onCreateTab = async (name: string): Promise<PortfolioGroupListDataItems> => {
    const sortOrder = tabs.length + 1;
    const portfolioGroupStub: PortfolioGroupStub = {
      type: mapPortfolioTypeToEnum(props.type),
      name,
      sortOrder,
    };
    const createdSubPortfolio: PortfolioGroupDetails = await createSubPortfolio({
      portfolioGroupStub,
    });
    onSelectTab(createdSubPortfolio);
    return createdSubPortfolio;
  };

  const renameSubPortfolio = useRenameSubPortfolio();

  const onRenameTab = async (name: string, tabId: number): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    renameSubPortfolio({type: props.type, id: tabId, name});
  };

  const deleteSubPortfolio = useDeleteSubPortfolio();

  const onDeleteTab = (tab: PortfolioGroupListDataItems) => {
    const {id, name} = tab;
    dispatch(
      ModalActions.show(
        <Delete
          target={{
            name,
            id,
          }}
          onDelete={async (id: number, _callback: $TSFixMe, close: () => void) => {
            close();
            onSelectTab('all');
            await deleteSubPortfolio({type: props.type, id});
            props.onDelete();
          }}
        />
      )
    );
  };

  const onSelectTab = (subTab: PortfolioGroupListDataItems | 'all') => {
    props.onSubTabSelect(subTab === 'all' ? 'all' : subTab.id);
  };

  return (
    <TabList<PortfolioGroupListDataItems>
      tabs={tabs}
      activeTab={activeTab}
      allItemsLabel={allItemsLabel}
      createTabEnabled={true}
      createTabDefaultName={isVessel ? 'New vessel list' : 'New cargo list'}
      createModalTitle={isVessel ? 'New vessel list' : 'New cargo list'}
      createModalButtonLabel="Create"
      renameModalTitle={isVessel ? 'Rename vessel list' : 'Rename cargo list'}
      renameModalButtonLabel="Rename"
      onSelectTab={onSelectTab}
      onSortEnd={onSortEnd}
      onCreateTab={onCreateTab}
      onRenameTab={onRenameTab}
      onDeleteTab={onDeleteTab}
    />
  );
};

const getActivePortfolioTab = (state: RootState, type: PortfolioType) => {
  const {activeTabVessel, activeTabCargo} = state.portfolio.screenState;
  switch (type) {
    case 'vessel':
      return activeTabVessel;
    case 'cargo':
      return activeTabCargo;
    default:
      throw new Error(`Bad type ${type}`);
  }
};

const getAllItemsLabel = (type: TabType) => {
  switch (type) {
    case 'cargo':
      return 'All cargoes';
    case 'vessel':
      return 'All vessels';
    default:
      throw new Error(`Bad type ${type}`);
  }
};
