import {useSelector} from '../../redux/react-redux';
import {decodeJwt} from 'jose';
import dayjs, {Dayjs} from 'dayjs';
import {RootState} from '../../redux/store';
import {createSelector} from 'reselect';

let overriddenExpirationDateForTesting: Dayjs | undefined = undefined;

export const useIsAccessTokenExpired = (): boolean => {
  const originalExpirationDate = useSelector(expirationDateSelector);
  const expirationDate = overriddenExpirationDateForTesting ?? originalExpirationDate;
  if (!expirationDate) {
    return false;
  }
  const isAccessTokenExpired = expirationDate.isBefore(dayjs());
  return isAccessTokenExpired;
};

const expirationDateSelector = createSelector(
  (state: RootState) => state.authentication.accessToken,
  token => {
    if (!token) {
      return null;
    }

    const payload = decodeJwt(token);

    const expiration = dayjs(payload.exp! * 1000);
    return expiration;
  }
);

/**
 * Overrides the expiration date of the access token for testing purposes.
 * Can be used in manual testing to simulate an expired access token.
 * - Take your computer offline
 * - In the JS console call `expireAccessTokenForTesting()` to expire the access token.
 * - Take your computer online
 * - The app should reload.
 */
const expireAccessTokenForTesting = () => {
  overriddenExpirationDateForTesting = dayjs().subtract(1, 'hours');
  // eslint-disable-next-line no-console
  console.log('🔥 Overrode access token expiration date for testing:', overriddenExpirationDateForTesting.toString());
};

window.expireAccessTokenForTesting = expireAccessTokenForTesting;
