/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CongestionLightReportDay
 */
export interface CongestionLightReportDay {
  /**
   * Date of the congestion report
   * @type {string}
   * @memberof CongestionLightReportDay
   */
  date: string | null;
  /**
   * Average duration of exited vessels in port in minutes
   * @type {number}
   * @memberof CongestionLightReportDay
   */
  avgDuration: number;
}

export function CongestionLightReportDayFromJSON(json: any): CongestionLightReportDay {
  return CongestionLightReportDayFromJSONTyped(json, false);
}

export function CongestionLightReportDayFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CongestionLightReportDay {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: json['date'],
    avgDuration: json['avgDuration'],
  };
}

export function CongestionLightReportDayToJSON(value?: CongestionLightReportDay | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    avgDuration: value.avgDuration,
  };
}
