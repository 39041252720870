/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {ReplyTimeUpdate, ReplyTimeUpdateFromJSON, ReplyTimeUpdateFromJSONTyped, ReplyTimeUpdateToJSON} from './';

/**
 *
 * @export
 * @interface SetReplyTimeRequest
 */
export interface SetReplyTimeRequest {
  /**
   *
   * @type {ReplyTimeUpdate}
   * @memberof SetReplyTimeRequest
   */
  replyTime: ReplyTimeUpdate | null;
}

export function SetReplyTimeRequestFromJSON(json: any): SetReplyTimeRequest {
  return SetReplyTimeRequestFromJSONTyped(json, false);
}

export function SetReplyTimeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetReplyTimeRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    replyTime: ReplyTimeUpdateFromJSON(json['replyTime']),
  };
}

export function SetReplyTimeRequestToJSON(value?: SetReplyTimeRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    replyTime: ReplyTimeUpdateToJSON(value.replyTime),
  };
}
