import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import Radio from '../../../atoms/Radio';

const cargoTypes = {
  container: 'Container',
  drybulk: 'Dry bulk',
};

const Wrapper = BindToProvider(
  'Market segment',
  'marketSegment',
  {
    id: '',
    cargoType: '',
  },
  {},
  {},
  true,
  true,
  true,
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3-8 arguments, but got 11.
  false,
  false,
  true // hasBorderBottom
)(WrappedDetailsSection);

const MarketSegment = (props: $TSFixMe) => {
  const handleCargoTypeChange = (value: $TSFixMe) => {
    const marketSegment = {...props.form['marketSegment'], cargoType: value};

    props.resetForm(() => props.onChange('marketSegment', marketSegment));
  };

  return (
    <Wrapper {...props}>
      {({values}: $TSFixMe) => (
        <div className="container-fluid">
          <div className="row">
            <div className="scol-12">
              {Object.keys(cargoTypes).map((type, i) => (
                <Radio
                  key={i}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  label={cargoTypes[type]}
                  checked={values.cargoType === type}
                  onChange={() => handleCargoTypeChange(type)}
                  id={`form-market-segment-cargo-type-${i}`}
                  name={'cargoType'}
                  disabled={props.isEdit}
                  dataCy={`cargo-type-${type}`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default MarketSegment;
