import {IconLayer} from 'deck.gl';
import NewBgFontAtlasManager from '../../../../components/SeaboMap/layers/helper/BgFontAtlasManager';
import iconAtlas from '../../../../components/SeaboMap/layers/icons/icon-atlas.png';
import iconMapping from '../../../../components/SeaboMap/layers/icons/icon-mapping.json';
import ForegroundTextLayer from './ForegroundTextLayer';
import {getNumberOfDigitsOfNumber} from '../../../../utils/getNumberOfDigitsOfNumber';

class BackgroundLayer extends ForegroundTextLayer {
  initializeState() {
    this.state = {
      fontAtlasManager: new NewBgFontAtlasManager(this.context.gl, 1.8, 24),
    };
  }

  renderLayers() {
    const {getItemAmount, isShowMarketVessels, isShowMarketCargoes, getSize} = this.props;

    const layers = [];
    layers.push(
      new IconLayer({
        ...this.props,
        id: `${this.props.id}-label-tip`,
        data: this.props.data,
        getIcon: () => 'bg-tip-mask',
        iconAtlas,
        iconMapping,
        getPixelOffset: [0, 8],
      })
    );
    layers.push(super.renderLayers());

    if (isShowMarketVessels) {
      layers.push(
        new IconLayer({
          ...this.props,
          id: `${this.props.id}-vessel-icon`,
          data: this.props.data,
          getColor: [68, 159, 255],
          getIcon: () => 'vessel-45-mask',
          getPixelOffset: obj => {
            const {amountVessels, amountCargoes} = getItemAmount(obj);
            const charCount = getNumberOfDigitsOfNumber(amountVessels) + getNumberOfDigitsOfNumber(amountCargoes);

            if (!isShowMarketCargoes) {
              return [charCount * -3, -10];
            }
            return [-3 * charCount - 18, -10];
          },
          iconAtlas,
          getSize: getSize - 2,
          iconMapping,
        })
      );
    }

    if (isShowMarketCargoes) {
      layers.push(
        new IconLayer({
          ...this.props,
          id: `${this.props.id}-cargo-icon`,
          data: this.props.data,
          getColor: [68, 159, 255],
          getIcon: () => 'cargo-mask',
          getPixelOffset: obj => {
            const {amountVessels} = getItemAmount(obj);
            const charCount = getNumberOfDigitsOfNumber(amountVessels);
            let xPosition = 3 * charCount + 4;
            if (!isShowMarketVessels) {
              xPosition = -14;
            }
            return [xPosition, -10];
          },
          iconAtlas,
          getSize: getSize - 2,
          iconMapping,
        })
      );
    }

    return layers;
  }
}

BackgroundLayer.layerName = 'BackgroundLayer';
export default BackgroundLayer;
