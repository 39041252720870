import {commodityCategories} from '../helper';
import {SWITCHES} from './switches';
import {aisSubSection} from './aisSubSection';
import {types} from './types';
import {MapCommodityIcon} from './MapCommodityIcon';
import {IconType} from '../../../atoms/Icon';
import {MapSwitchesKey} from '../MapDetails/utils/types';

export type MapOption = {
  id: MapSwitchesKey;
  iconActive: IconType;
  iconInactive: IconType;
  group: number;
  default?: boolean;
  hidden?: boolean;
  sections?: MapOptionSection[];
};

export type MapOptionSection = MapOptionSectionItem[];

export type MapOptionSectionItem = {
  id: MapSwitchesKey;
  headline?: string;
  subMenuHeader?: boolean;
  iconActive?: IconType;
  iconInactive?: IconType;
  sections?: MapOptionSection[];
  turnsOn?: string[];
  turnsOnSub?: string[];
  default?: boolean;
  turnsOnIfNone?: string[];
  subMenu?: SubMenuOption[];
  togglesEnable?: string[];
  label: string;
  subMenuLabel?: string;
  disabled?: boolean;
  type: number;
  subMenuNoDisable?: boolean;
  filters?: Record<string, string | string[]>;
};

export type SubMenuOption = {
  id: string;
  label: JSX.Element;
  default?: boolean;
};

export enum BasicOption {
  PORTS = 0,
  LAYERS = 1,
}

export const basicOptions: MapOption[] = [
  {
    id: SWITCHES.M_PORT,
    iconActive: 'port',
    iconInactive: 'port-disabled',
    group: 0,
  },
  {
    id: SWITCHES.M_LAYER,
    iconActive: 'layers',
    iconInactive: 'layers',
    sections: [
      [
        {
          id: SWITCHES.LAYER_TRADING_AREA,
          type: types.SECTION_TOGGLE,
          label: 'Trading areas',
          default: false,
        },
        {
          id: SWITCHES.TRADING_AREA_NAMES,
          type: types.SECTION_TOGGLE,
          label: 'Show area names',
          default: false,
        },
      ],
      [
        {
          id: SWITCHES.LAYER_SATELLITE,
          type: types.SECTION_TOGGLE,
          label: 'Satellite view',
        },
        {
          id: SWITCHES.LAYER_PIRACY,
          type: types.SECTION_TOGGLE,
          label: 'High risk areas',
        },
        {
          id: SWITCHES.LAYER_ECA,
          type: types.SECTION_TOGGLE,
          label: 'Emission control areas',
        },
      ],
    ],
    group: 1,
  },
];

export const vesselOption: MapOption = {
  id: SWITCHES.M_VESSEL,
  iconActive: 'vessel-complex',
  iconInactive: 'vessel-disabled',
  sections: [
    [
      {
        id: SWITCHES.VESSEL_ON,
        type: types.SECTION_TOGGLE,
        label: 'Show vessels',
        togglesEnable: [SWITCHES.VESSEL_MARKET, SWITCHES.VESSEL_PORTFOLIO, SWITCHES.VESSEL_AIS],
        turnsOnIfNone: [SWITCHES.VESSEL_MARKET],
      },
    ],
    [
      {
        id: SWITCHES.VESSEL_MARKET,
        type: types.SECTION_TOGGLE,
        label: 'Market vessels',
        turnsOn: [SWITCHES.VESSEL_ON],
        disabled: true,
      },
      {
        id: SWITCHES.VESSEL_PORTFOLIO,
        type: types.SECTION_SUBMENU_TOGGLE,
        label: 'My Fleet',
        turnsOn: [SWITCHES.VESSEL_ON],
        disabled: true,
        subMenuLabel: 'Vessel lists',
        subMenu: [],
        subMenuNoDisable: true,
      },
    ],
    [
      {
        ...aisSubSection,
        disabled: true,
        turnsOn: [SWITCHES.VESSEL_ON],
      },
    ],
  ],
  group: 0,
};

export const cargoOption: MapOption = {
  id: SWITCHES.M_CARGO,
  iconActive: 'box-open',
  iconInactive: 'cargo-disabled',
  sections: [
    [
      {
        id: SWITCHES.CARGO_ON,
        type: types.SECTION_TOGGLE,
        label: 'Show cargoes',
        togglesEnable: [
          SWITCHES.CARGO_MARKET,
          SWITCHES.CARGO_PORTFOLIO,
          SWITCHES.CARGO_CONTRACT_TYPE,
          SWITCHES.CARGO_COMMODITIES,
        ],
        turnsOnIfNone: [SWITCHES.CARGO_MARKET],
      },
    ],
    [
      {
        id: SWITCHES.CARGO_MARKET,
        type: types.SECTION_TOGGLE,
        label: 'Market cargoes',
        turnsOn: [SWITCHES.CARGO_ON],
        disabled: true,
      },
      {
        id: SWITCHES.CARGO_PORTFOLIO,
        type: types.SECTION_SUBMENU_TOGGLE,
        label: 'My Cargoes',
        turnsOn: [SWITCHES.CARGO_ON],
        disabled: true,
        subMenuLabel: 'Cargo lists',
        subMenu: [],
        subMenuNoDisable: true,
      },
      {
        id: SWITCHES.CARGO_COMMODITIES,
        type: types.SECTION_SUBMENU_TOGGLE,
        label: 'Commodities',
        turnsOn: [SWITCHES.CARGO_ON],
        disabled: true,
        subMenuLabel: 'All types',
        subMenu: [
          ...Object.keys(commodityCategories).map(category => ({
            id: category,
            label: (
              <MapCommodityIcon
                type={category}
                label={commodityCategories[category as keyof typeof commodityCategories]}
              />
            ),
          })),
        ],
      },
    ],
  ],
  group: 0,
};
