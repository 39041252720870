/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Fixture
 */
export interface Fixture {
  /**
   *
   * @type {number}
   * @memberof Fixture
   */
  id: number;
}

export function FixtureFromJSON(json: any): Fixture {
  return FixtureFromJSONTyped(json, false);
}

export function FixtureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fixture {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
  };
}

export function FixtureToJSON(value?: Fixture | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
  };
}
