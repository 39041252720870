import {Fragment, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import {acceptIntOrEmpty} from '../../../utils/formatter';
import {numberSmallerThan, dateEarlierThanOrEqualTo} from '../../../utils/validators';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import Input from '../../../atoms/Input';
import DatePicker from '../../../atoms/DatePicker';
import Icon from '../../../atoms/Icon';
import {Button} from '../../../atoms/Button/Button';
import IconButton from '../../../atoms/IconButton';
import {useUser} from '../../../components/UserContext/useUser';
import Select from '../../../atoms/Select/Select';

const durationUnits = {
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
};

const ladenOptions = {
  'in charterers option': 'in charterers option',
};

// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const durationUnitOptions = Object.keys(durationUnits).map(value => ({value, name: durationUnits[value]}));
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
const ladenOptionsOptions = Object.keys(ladenOptions).map(value => ({value, name: ladenOptions[value]}));

const defaults = {
  durationMin: '',
  durationMax: '',
  durationUnit: Object.keys(durationUnits)[0],
  durationAbout: '',
  periodFrom: null,
  periodTo: null,
  periodAbout: '',
  ladenMin: '',
  ladenMax: '',
  ladenOption: '',
};

const Wrapper = BindToProvider(
  'Duration',
  'duration',
  defaults,
  {
    durationMin: numberSmallerThan('durationMax'),
    ladenMin: numberSmallerThan('ladenMax'),
    periodFrom: dateEarlierThanOrEqualTo('periodTo'),
  },
  {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
    toApi: (fields, sections) => ({
      ...fields,
      ladenOption: sections.contractType.contractType !== 'pc' ? undefined : fields.ladenOption,
      periodFrom: fields.periodFrom && fields.periodFrom.toFormattedString(),
      periodTo: fields.periodTo && fields.periodTo.toFormattedString(),
    }),
    fromApi: ({periodFrom, periodTo, ...fields}: $TSFixMe) => ({
      ...fields,
      periodFrom: periodFrom ? dayjs(periodFrom) : null,
      periodTo: periodTo ? dayjs(periodTo) : null,
    }),
  },
  true
)(WrappedDetailsSection);

const Duration = (props: $TSFixMe) => {
  const {form, onChange} = props;
  const [showPeriod, setShowPeriod] = useState(false);
  const [showLaden, setShowLaden] = useState(false);

  const user = useUser();

  const contractType = form.contractType && form.contractType.contractType;
  const durationUnit = form.duration && form.duration.durationUnit;

  useEffect(
    () => {
      if (contractType === 'tct' && durationUnit !== 'days' && !props.isEdit) {
        onChange('duration', {...form.duration, durationUnit: 'days'});
      }
      if (contractType === 'bbc' && durationUnit !== 'years' && !props.isEdit) {
        onChange('duration', {...form.duration, durationUnit: 'years'});
      }
      if (contractType === 'pc' && durationUnit !== 'months' && !props.isEdit) {
        onChange('duration', {...form.duration, durationUnit: 'months'});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contractType]
  );

  useEffect(() => {
    if (props.isEdit) {
      if (props.form && props.form.duration && (props.form.duration.ladenMin || props.form.duration.ladenMax)) {
        setShowLaden(true);
      }

      if (props.form && props.form.duration && (props.form.duration.periodFrom || props.form.duration.periodTo)) {
        setShowPeriod(true);
      }
    }
  }, [form.contractType, form.duration, onChange, props.form, props.isEdit]);

  const onRemovePeriod = () => {
    setShowPeriod(false);
    props.onChange('duration', {
      ...props.form.duration,
      periodFrom: defaults.periodFrom,
      periodTo: defaults.periodTo,
      periodAbout: defaults.periodAbout,
    });
  };

  const onRemoveLaden = () => {
    setShowLaden(false);
    props.onChange('duration', {
      ...props.form.duration,
      ladenMin: defaults.ladenMin,
      ladenMax: defaults.ladenMax,
      ladenOption: defaults.ladenOption,
    });
  };

  return (
    <Wrapper {...props}>
      {({onChange, values, validations, validate, form}: $TSFixMe) => {
        const isTct = form.contractType.contractType === 'tct';
        const isPc = form.contractType.contractType === 'pc';
        return (
          <div className="container-fluid">
            <div className={'row'}>
              <div className="scol-12 scol-sm-3">
                <LabelWrapper
                  label={'Min'}
                  htmlFor={'form-duration-duration-min'}
                  hasError={validations.durationMin.invalid}>
                  <Input
                    id={'form-duration-duration-min'}
                    onChange={v => acceptIntOrEmpty(v, v => onChange('durationMin', v))}
                    onKeyUp={() => validate('durationMin')}
                    value={values.durationMin}
                    maxLength={4}
                    hasError={validations.durationMin.invalid}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12 scol-sm-3">
                <LabelWrapper
                  label={'Max'}
                  htmlFor={'form-duration-duration-max'}
                  hasError={validations.durationMin.invalid || validations.durationMax.invalid}>
                  <Input
                    id={'form-duration-duration-max'}
                    onChange={v => acceptIntOrEmpty(v, v => onChange('durationMax', v))}
                    onKeyUp={() => validate('durationMin')}
                    value={values.durationMax}
                    maxLength={4}
                    hasError={validations.durationMin.invalid || validations.durationMax.invalid}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12 scol-sm-3">
                <LabelWrapper
                  label={'Unit'}
                  htmlFor={'form-duration-duration-unit'}
                  hasError={validations.durationUnit.invalid}>
                  <Select
                    id={`form-duration-duration-unit`}
                    name={`form-duration-duration-unit`}
                    options={durationUnitOptions}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={{value: values.durationUnit, name: durationUnits[values.durationUnit]}}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    defaultValue={{value: values.durationUnit, name: durationUnits[values.durationUnit]}}
                    onChange={(o: $TSFixMe) => onChange('durationUnit', o.value)}
                    getOptionLabel={(o: $TSFixMe) => o.name}
                    getOptionValue={(o: $TSFixMe) => o.value}
                    components={{DropdownIndicator: ArrowIndicator}}
                    isSearchable={false}
                  />
                </LabelWrapper>
              </div>
              <div className="scol-12 scol-sm-3">
                <LabelWrapper
                  label={'± days'}
                  htmlFor={'form-duration-duration-about'}
                  hasError={validations.durationAbout.invalid}>
                  <Input
                    id={'form-duration-duration-about'}
                    onChange={v => acceptIntOrEmpty(v, v => onChange('durationAbout', v))}
                    value={values.durationAbout}
                    hasError={validations.durationAbout.invalid}
                    maxLength={4}
                  />
                </LabelWrapper>
                {validations.durationAbout.error && (
                  <p className="text-danger text-danger--no-margin">{validations.durationAbout.error}</p>
                )}
              </div>
              <div className="scol-12 scol-sm-4">
                {validations.durationMin.error && (
                  <p className="text-danger text-danger--no-margin">{validations.durationMin.error}</p>
                )}
                {validations.durationMax.error && (
                  <p className="text-danger text-danger--no-margin">{validations.durationMax.error}</p>
                )}
              </div>
            </div>
            {!isTct && showPeriod && (
              <div className={'row'}>
                <Fragment>
                  <div className="scol-12 scol-sm-4">
                    <LabelWrapper
                      label={'Period from'}
                      htmlFor={'form-duration-period-from'}
                      hasError={validations.periodFrom.invalid}>
                      <DatePicker
                        id={'form-duration-period-from'}
                        value={values.periodFrom}
                        onChange={date => onChange('periodFrom', date, () => validate('periodFrom'))}
                        locale={user.locale}
                        placeholder={''}
                        isClearable
                        hasError={validations.periodFrom.invalid}
                      />
                    </LabelWrapper>
                  </div>
                  <div className="scol-12 scol-sm-4">
                    <LabelWrapper
                      label={'Period to'}
                      htmlFor={'form-duration-period-to'}
                      hasError={validations.periodFrom.invalid} /* This is no mistake, it's periodFrom */
                    >
                      <DatePicker
                        id={'form-duration-period-to'}
                        value={values.periodTo}
                        onChange={date => onChange('periodTo', date, () => validate('periodFrom'))}
                        locale={user.locale}
                        placeholder={''}
                        isClearable
                        hasError={validations.periodFrom.invalid}
                        month={values.periodFrom}
                      />
                    </LabelWrapper>
                  </div>
                  <div className="scol-12 scol-sm-3">
                    <LabelWrapper
                      label={'± days'}
                      htmlFor={'form-duration-period-about'}
                      hasError={validations.periodAbout.invalid}>
                      <Input
                        id={'form-duration-period-about'}
                        onChange={v => acceptIntOrEmpty(v, v => onChange('periodAbout', v))}
                        value={values.periodAbout}
                        hasError={validations.periodAbout.invalid}
                        maxLength={4}
                      />
                    </LabelWrapper>
                    {validations.periodAbout.error && (
                      <p className="text-danger text-danger--no-margin">{validations.periodAbout.error}</p>
                    )}
                  </div>
                  <div className="scol-12 scol-sm-1">
                    <div className="cargo-vessel-form__v-centered-field">
                      <IconButton
                        id={`form-duration-remove-period`}
                        type={'scrap'}
                        iconStyle={{cursor: 'pointer'}}
                        onClick={onRemovePeriod}
                        title={'Remove period'}
                      />
                    </div>
                  </div>
                </Fragment>
                {validations.periodFrom.error && (
                  <div className={'scol-12'}>
                    <p className="text-danger text-danger--no-margin">{validations.periodFrom.error}</p>
                  </div>
                )}
              </div>
            )}
            {isPc && showLaden && (
              <div className="row">
                <div className="scol-12 scol-sm-4">
                  <LabelWrapper
                    label={'Min (laden legs)'}
                    htmlFor={'form-duration-laden-min'}
                    hasError={validations.ladenMin.invalid}>
                    <Input
                      id={'form-duration-laden-min'}
                      onChange={v => acceptIntOrEmpty(v, v => onChange('ladenMin', v))}
                      onKeyUp={() => validate('ladenMin')}
                      value={values.ladenMin}
                      hasError={validations.ladenMin.invalid}
                      maxLength={4}
                    />
                  </LabelWrapper>
                  {validations.ladenMin.error && (
                    <p className="text-danger text-danger--no-margin">{validations.ladenMin.error}</p>
                  )}
                </div>
                <div className="scol-12 scol-sm-4">
                  <LabelWrapper
                    label={'Max (laden legs)'}
                    htmlFor={'form-duration-laden-max'}
                    hasError={validations.ladenMin.invalid || validations.ladenMax.invalid}>
                    <Input
                      id={'form-duration-laden-max'}
                      onChange={v => acceptIntOrEmpty(v, v => onChange('ladenMax', v))}
                      onKeyUp={() => validate('ladenMin')}
                      value={values.ladenMax}
                      hasError={validations.ladenMin.invalid || validations.ladenMax.invalid}
                      maxLength={4}
                    />
                  </LabelWrapper>
                  {validations.ladenMax.error && (
                    <p className="text-danger text-danger--no-margin">{validations.ladenMax.error}</p>
                  )}
                </div>
                <div className="scol-12 scol-sm-3">
                  <LabelWrapper label={'Option'} htmlFor={'form-duration-laden-option'}>
                    <Select
                      id={`form-duration-laden-option`}
                      name={`form-duration-laden-option`}
                      options={ladenOptionsOptions}
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      value={{value: values.ladenOption, name: ladenOptions[values.ladenOption]}}
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      defaultValue={{value: values.ladenOption, name: ladenOptions[values.ladenOption]}}
                      onChange={(o: $TSFixMe) => onChange('ladenOption', o.value)}
                      getOptionLabel={(o: $TSFixMe) => o.name}
                      getOptionValue={(o: $TSFixMe) => o.value}
                      components={{DropdownIndicator: ArrowIndicator}}
                      isSearchable={false}
                    />
                  </LabelWrapper>
                </div>
                <div className="scol-12 scol-sm-1">
                  <div className="cargo-vessel-form__v-centered-field">
                    <IconButton
                      id={`form-duration-remove-laden`}
                      type={'scrap'}
                      iconStyle={{cursor: 'pointer'}}
                      onClick={onRemoveLaden}
                      title={'Remove laden legs'}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={'row'}>
              {!isTct && !showPeriod && (
                <div className={'scol-12 cargo-vessel-form__icon-button'}>
                  <Button
                    id={'form-duration-add-period'}
                    icon
                    label={
                      <Fragment>
                        <Icon type={'item-add-selection-solid'} color="blue" style={{marginRight: 6}} /> Define exact
                        period
                      </Fragment>
                    }
                    upper
                    onClick={() => setShowPeriod(true)}
                  />
                </div>
              )}
              {isPc && !showLaden && (
                <div className={'scol-12 cargo-vessel-form__icon-button'}>
                  <br />
                  <Button
                    id={'form-duration-add-laden'}
                    icon
                    label={
                      <Fragment>
                        <Icon type={'item-add-selection-solid'} color="blue" style={{marginRight: 6}} /> Add laden legs
                      </Fragment>
                    }
                    upper
                    onClick={() => setShowLaden(true)}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Wrapper>
  );
};

export default Duration;
