import {FC} from 'react';

const DragHandle: FC<{children?: JSX.Element}> = props => {
  if (props.children === undefined) {
    return <span>::</span>;
  }
  return props.children;
};

export default DragHandle;
