import {FC, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {MarketScreen} from './MarketScreen';
import {MarketActions, MarketType} from '../../redux/Market';
import {useDispatch, useSelector} from '../../redux/react-redux';

export const MarketScreens: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MarketScreen />} />
      <Route path="/vessel" element={<MarketTypeRedirect marketType={'vessel'} />} />
      <Route path="/cargo" element={<MarketTypeRedirect marketType={'cargo'} />} />
    </Routes>
  );
};

/**
 * Updates the selected market type and removes it from the url.
 */
const MarketTypeRedirect: FC<{marketType: MarketType}> = ({marketType}) => {
  const marketTypeInRedux = useSelector(state => state.market.marketType);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (marketType !== marketTypeInRedux) {
      dispatch(MarketActions.setMarketType(marketType));
    }
    navigate('/market', {replace: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
