/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CumulatedTiedUpBulkerVessels,
  CumulatedTiedUpBulkerVesselsFromJSON,
  CumulatedTiedUpBulkerVesselsFromJSONTyped,
  CumulatedTiedUpBulkerVesselsToJSON,
} from './';

/**
 *
 * @export
 * @interface BulkerPerClass
 */
export interface BulkerPerClass {
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  capesize: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  handy: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  handymax: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  kamsarmax: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  miniBulker: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  panamax: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  postPanamax: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  supramax: CumulatedTiedUpBulkerVessels;
  /**
   *
   * @type {CumulatedTiedUpBulkerVessels}
   * @memberof BulkerPerClass
   */
  ultramax: CumulatedTiedUpBulkerVessels;
}

export function BulkerPerClassFromJSON(json: any): BulkerPerClass {
  return BulkerPerClassFromJSONTyped(json, false);
}

export function BulkerPerClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkerPerClass {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    capesize: CumulatedTiedUpBulkerVesselsFromJSON(json['capesize']),
    handy: CumulatedTiedUpBulkerVesselsFromJSON(json['handy']),
    handymax: CumulatedTiedUpBulkerVesselsFromJSON(json['handymax']),
    kamsarmax: CumulatedTiedUpBulkerVesselsFromJSON(json['kamsarmax']),
    miniBulker: CumulatedTiedUpBulkerVesselsFromJSON(json['miniBulker']),
    panamax: CumulatedTiedUpBulkerVesselsFromJSON(json['panamax']),
    postPanamax: CumulatedTiedUpBulkerVesselsFromJSON(json['postPanamax']),
    supramax: CumulatedTiedUpBulkerVesselsFromJSON(json['supramax']),
    ultramax: CumulatedTiedUpBulkerVesselsFromJSON(json['ultramax']),
  };
}

export function BulkerPerClassToJSON(value?: BulkerPerClass | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    capesize: CumulatedTiedUpBulkerVesselsToJSON(value.capesize),
    handy: CumulatedTiedUpBulkerVesselsToJSON(value.handy),
    handymax: CumulatedTiedUpBulkerVesselsToJSON(value.handymax),
    kamsarmax: CumulatedTiedUpBulkerVesselsToJSON(value.kamsarmax),
    miniBulker: CumulatedTiedUpBulkerVesselsToJSON(value.miniBulker),
    panamax: CumulatedTiedUpBulkerVesselsToJSON(value.panamax),
    postPanamax: CumulatedTiedUpBulkerVesselsToJSON(value.postPanamax),
    supramax: CumulatedTiedUpBulkerVesselsToJSON(value.supramax),
    ultramax: CumulatedTiedUpBulkerVesselsToJSON(value.ultramax),
  };
}
