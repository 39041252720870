export type VesselTypeKey = 'mpp' | 'container' | 'bulker';

export type VesselType = {
  key: VesselTypeKey;
  strokeColor: string;
  fillColor: string;
  name: string;
};

export const mpp: VesselType = {
  key: 'mpp',
  strokeColor: '#fffff',
  fillColor: '#ffeb00',
  name: 'MPP',
};

export const container: VesselType = {
  key: 'container',
  strokeColor: '#fffff',
  fillColor: '#40a2ff',
  name: 'CONTAINER',
};

export const bulker: VesselType = {
  key: 'bulker',
  strokeColor: '#fffff',
  fillColor: '#62d388',
  name: 'BULKER',
};

export const vesselTypes: VesselType[] = [mpp, container, bulker];

export const vesselTypeByName = (name: string): VesselType | undefined => {
  return vesselTypes.find(vesselType => vesselType.name === name);
};
