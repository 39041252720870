/* eslint-disable no-nested-ternary */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const option = (base, state) => ({
  ...base,
  color: 'var(--color-gray-1)',
  padding: '8px 8px',
  backgroundColor: state.isSelected
    ? 'var(--color-blue-bg)'
    : state.isFocused
    ? 'var(--color-gray-5)'
    : 'var(--color-white)',
  cursor: 'pointer',
});

export const control =
  (width: string | number | undefined, hasError?: boolean) =>
  (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
    base,
    {isFocused, menuIsOpen, isDisabled}: $TSFixMe
  ) => ({
    ...base,
    padding: '0 8px',
    margin: 0,
    background: 'var(--color-gray-6)',
    color: 'var(--color-black)',
    fontSize: 'var(--font-size-md)',
    fontWeight: 400,
    lineHeight: '18px',
    borderRadius: 'var(--border-radius-button)',
    border: 'var(--border-base)',
    borderColor: hasError
      ? 'var(--color-red)'
      : isFocused || menuIsOpen
      ? 'var(--color-blue)'
      : isDisabled
      ? 'var(--color-gray-5)'
      : 'var(--color-gray-4)',
    outline: 'none',
    boxShadow: 'var(--box-shadow-inset-input)',
    WebkitAppearance: 'none',
    position: 'relative',
    '&:hover': {
      borderColor: 'var(--color-blue)',
    },
    width: width ? width : '100%',
    minHeight: 'var(--element-height)',
  });

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const menu = (base, _state) => ({
  ...base,
  marginTop: '0',
  background: 'var(--color-white)',
  color: 'var(--color-black)',
  borderRadius: 'var(--border-radius-button)',
  boxShadow: 'var(--box-shadow-dropdown)',
  border: 'none',
  padding: '0',
  fontSize: 'var(--font-size-sm)',
  zIndex: '10',
  overflow: 'hidden',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const menuList = (base, _state) => ({
  ...base,
  padding: 0,
  background: 'var(--color-white)',
  color: 'var(--color-black)',
  fontSize: 'var(--font-size-md)',
  textAlign: 'left',
});

export const singleValue = (base: $TSFixMe, state: $TSFixMe) => {
  return {
    ...base,
    paddingLeft: '0',
    background: 'var(--color-gray-6)',
    color: 'var(--color-black)',
    fontSize: 'var(--font-size-md)',
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
  };
};

export const valueContainer = (base: $TSFixMe, _state: $TSFixMe) => {
  return {
    ...base,
    padding: '0',
    position: 'relative',
    maxHeight: '250px',
    overflowY: 'auto',
  };
};
export const indicatorsContainer = (base: $TSFixMe, _state: $TSFixMe) => {
  return {
    ...base,
    padding: '0',
  };
};

export const menuPortal = (base: $TSFixMe) => ({
  ...base,
  position: 'relative',
  left: '0',
  top: '0',
  minHeight: '300px',
  border: 'var(--border-base)',
  borderTopWidth: '0',
  overflow: 'hidden',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const multiValue = (base, _state) => ({
  ...base,
  backgroundColor: 'var(--color-gray-4)',
  border: 'var(--border-base)',
  color: 'var(--color-black)',
  fontFamily: 'Akkurat',
  fontSize: 'var(--font-size-md)',
  lineHeight: '18px',
  paddingLeft: '0',
  paddingRight: '3px',
  borderRadius: 'var(--border-radius-lg)',
  boxShadow: 'var(--box-shadow-button)',
  margin: '2px 6px 2px 0',

  '&:last-child': {
    marginRight: 0,
  },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const multiValueLabel = (base, _state) => ({
  ...base,
  color: 'var(--color-black)',
  padding: '2px 4px',
});
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const multiValueRemove = (base, _state) => ({
  ...base,
  backgroundColor: 'inherit',
  cursor: 'pointer',
  paddingRight: '6px',
  '&:hover': {
    backgroundColor: 'var(--color-gray-1)',
  },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const autoCompleteControl = (base, state) => ({
  ...control(412)(base, state),
  background: 'var(--color-gray-6)',
  color: 'var(--color-black)',
  borderRadius: 'var(--border-radius-button) !important',
  boxShadow: 'var(--box-shadow-dropdown)',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const mapSearchControl = (base, state) => ({
  ...autoCompleteControl(base, state),
  width: state.isFocused || state.hasValue || state.menuIsOpen ? 320 : 108,
  opacity: state.isFocused || state.hasValue || state.menuIsOpen ? 1 : 0.6,
  '&:hover': {opacity: 1, width: 320},
});

export const mapSearchPlaceholder = (base: $TSFixMe) => ({
  ...base,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  right: 0,
  left: 0,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const autoCompleteMenu = (base, state) => ({
  ...menu(base, state),
  width: 412,
  height: 450,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const autoCompleteMenuList = (base, state) => ({
  ...menuList(base, state),
  background: 'var(--color-gray-6)',
  color: 'var(--color-gray-1)',
  height: 450,
  maxHeight: 450,
  boxShadow: 'var(--box-shadow-dropdown)',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const mapSearchMenu = (base, state) => ({
  ...menu(base, state),
  minHeight: 100,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const mapSearchMenuList = (base, state) => ({
  ...menuList(base, state),
  minHeight: 100,
  maxHeight: 600,
  paddingBottom: 20,
  borderTop: 'var(--border-base)',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const stationSelectMenuList = (base, state) => ({
  ...menuList(base, state),
  height: 250,
  maxHeight: 250,
  boxShadow: 'var(--box-shadow-dropdown)',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const stationSelectControlBase = width => (base, state) => ({
  ...control(width)(base, state),
  backgroundColor: 'var(--color-gray-6)',
  boxShadow: 'var(--box-shadow-inset-input)',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const stationSelectMenuBase = (base, state) => ({
  ...menu(base, state),
  width: 250,
  height: 250,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const modalMenu = (base, state) => ({
  ...menu(base, state),
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const modalControl = (base, state) => ({
  ...control('100%')(base, state),
  backgroundColor: 'var(--color-gray-6)',
  boxShadow: 'var(--box-shadow-inset-input)',
  height: 'auto',
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
export const modalMenuList = (base, state) => ({
  ...menuList(base, state),
  overflowX: 'hidden',
  overflowY: 'auto',
  maxHeight: '300px',
});

export const clearIndicator = (base: $TSFixMe) => ({
  ...base,
  padding: '3px',
});
