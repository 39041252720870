import LabelWrapper from '../../../../atoms/LabelWrapper';
import {hasError} from '../../../../utils/helper';
import NumberRangeInput from '../../../RangeInput/NumberRangeInput';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import {designSubTypes} from '../../../../screens/CargoVesselForm/helper';
import {filterBranchDefinition} from './VesselSizeDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {MultiSelect} from '../../../../atoms/Select/MultiSelect';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const VesselSize = ({filterProviderApi}: Props) => {
  return (
    <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition} size={'big'}>
      {({handleChange, values, validations}) => (
        <div className="container">
          <div className="row bottom-fix">
            <div className="scol-4">
              <NumberRangeInput
                label={'DWT (Summer)'}
                onFromChange={value => handleChange('deadWeightFrom', value)}
                onToChange={value => handleChange('deadWeightTo', value)}
                fromValue={values.deadWeightFrom}
                toValue={values.deadWeightTo}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 999999,
                  placeholder: 'min',
                  hasError: hasError('deadWeightFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 999999,
                  placeholder: 'max',
                  hasError: hasError('deadWeightTo', validations),
                }}
              />
            </div>
            <div className="scol-4">
              <NumberRangeInput
                label={'TEU'}
                onFromChange={value => handleChange('teuFrom', value)}
                onToChange={value => handleChange('teuTo', value)}
                fromValue={values.teuFrom}
                toValue={values.teuTo}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 999,
                  placeholder: 'min',
                  hasError: hasError('teuFrom', validations),
                }}
                toProps={{
                  min: 0,
                  max: 999,
                  placeholder: 'max',
                  hasError: hasError('teuTo', validations),
                }}
              />
            </div>
            <div className="scol-4">
              <NumberRangeInput
                label={'TEU14'}
                onFromChange={value => handleChange('teu14From', value)}
                onToChange={value => handleChange('teu14To', value)}
                fromValue={values.teu14From}
                toValue={values.teu14To}
                allowEqualMinMax
                fromProps={{
                  min: 0,
                  max: 999,
                  placeholder: 'min',
                  hasError: hasError('teu14From', validations),
                }}
                toProps={{
                  min: 0,
                  max: 999,
                  placeholder: 'max',
                  hasError: hasError('teu14To', validations),
                }}
              />
            </div>
            <HorizontalLine large topSpace={'small'} />
            <div className="scol-6">
              <LabelWrapper label={'Bulker sub type'} htmlFor={'filter-vesselsize-design'}>
                <MultiSelect
                  id={'filter-vesselsize-design'}
                  value={values.vesselDesigns}
                  onChange={value => handleChange('vesselDesigns', [...value])}
                  options={
                    Object.keys(designSubTypes).map(value => ({
                      value,
                      name: designSubTypes[value as keyof typeof designSubTypes],
                    })) as typeof values.vesselDesigns
                  }
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.value}
                />
              </LabelWrapper>
            </div>
          </div>
        </div>
      )}
    </FilterButton>
  );
};

export default VesselSize;
