import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
  COMMAND_PRIORITY_LOW,
  $getSelection,
  $isRangeSelection,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
} from 'lexical';
import {mergeRegister} from '@lexical/utils';
import {FC, useLayoutEffect} from 'react';
import {$isTermTypeNode} from '../nodes/TermTypeNode';

/**
 * Fires given callbacks on arrow up and down keys
 */
export const ArrowKeysCallbackPlugin: FC<{onArrowUp?: () => void; onArrowDown?: () => void}> = ({
  onArrowUp,
  onArrowDown,
}) => {
  const [editor] = useLexicalComposerContext();
  useLayoutEffect(() => {
    return mergeRegister(
      editor.registerCommand<KeyboardEvent | null>(
        KEY_ARROW_UP_COMMAND,
        () => {
          if (!onArrowUp) {
            return false;
          }
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return false;
          }
          // To determine if the cursor is at the beginning of the text, we check if the previous sibling is not a term type node
          const previousNode = selection.getNodes()[0].getPreviousSibling();
          if (previousNode && !$isTermTypeNode(previousNode)) {
            return false;
          }
          onArrowUp();
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<KeyboardEvent | null>(
        KEY_ARROW_DOWN_COMMAND,
        () => {
          if (!onArrowDown) {
            return false;
          }
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return false;
          }
          // To determine if the cursor is at the end of the text, we check if there is no next sibling
          const nextNode = selection.getNodes()[0].getNextSibling();
          if (nextNode) {
            return false;
          }
          onArrowDown();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onArrowUp, onArrowDown]);

  return null;
};
