import {VesselTypeKey} from '../../../../../../components/consts/vesselTypes';

export interface VesselIcon {
  url: string;
  width: number;
  height: number;
}

const VesselTypeKeyToVesselIcon: {[key in VesselTypeKey]: VesselIcon} = {
  container: {
    url: '/static/icon/voyage-animation/vessel-icon-container.svg',
    width: 36,
    height: 135,
  },
  bulker: {
    url: '/static/icon/voyage-animation/vessel-icon-bulker.svg',
    width: 36,
    height: 135,
  },
  mpp: {
    url: '/static/icon/voyage-animation/vessel-icon-mpp.svg',
    width: 36,
    height: 135,
  },
};

export const getVesselIcon = (vesselTypeKey: VesselTypeKey): VesselIcon => {
  return VesselTypeKeyToVesselIcon[vesselTypeKey];
};

export const getVesselTypeKey = (vesselType: string | null): VesselTypeKey => {
  switch (vesselType) {
    case 'container':
      return 'container';
    case 'bulker':
      return 'bulker';
    case 'mpp':
      return 'mpp';
    default:
      return 'container';
  }
};
