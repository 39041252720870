import Country from '../../../../../../model/Country';
import {PortLocation} from '../../../../../LocationOutput/PortLocation';
import {MapDetailArea, isMapPortElement, isMapAreaElement, isMapPortlessElement} from '../../../utils/types';
import {MapDetailsWeather} from '../../MapDetailsWeather';
import {
  MapDetailsError,
  MapDetailsHeader,
  MapDetailsTitle,
  MapDetailsSubtitle,
  MapDetailsContent,
  MapDetailLink,
  MapDetailsLoading,
  ActionButtonLeft,
} from '../../shared-components';
import {MapDetailPortAreaContent} from './MapDetailPortAreaContent';
import {useSelector} from '../../../../../../redux/react-redux';
import {assert} from '../../../../../../utils/assert';
import {Tooltip} from 'antd';
import {AimOutlined} from '@ant-design/icons';
import {usePortItemsQuery} from './usePortItemsQuery';
import {useAreaItemsQuery} from './useAreaItemsQuery';
import {ExtLinkIcon} from '../../../../../ExtLinkIcon/ExtLinkIcon';
import {filterPortAreaData, OnMapElementChange} from '../../../utils/utils';
import {useMapContext} from '../../../../MapContext/MapContext';
import * as Sentry from '@sentry/react';

export const MapDetailPort: React.FC<{onVesselLocationClicked?: OnMapElementChange}> = ({onVesselLocationClicked}) => {
  const {mapElement} = useSelector(({mapDetails}) => mapDetails);
  // must be port based on layer switch above
  assert(isMapPortElement(mapElement));

  const {
    state: {
      selectedItems,
      settings: {switches},
    },
  } = useMapContext();
  const {id, code, name, country, coordinates} = mapElement.object;

  const portItemsQuery = usePortItemsQuery(id);
  if (portItemsQuery.isLoading) {
    return MapDetailsLoading;
  }
  if (portItemsQuery.isError) {
    return MapDetailsError;
  }
  assert(portItemsQuery.isSuccess);

  const portCV = filterPortAreaData(portItemsQuery.data, switches);

  const centerPortOnMap = () => {
    if (!mapElement.lngLat) {
      // eslint-disable-next-line no-console
      console.error('mapElement.lngLat is undefined');
      Sentry.captureException(new Error('mapElement.lngLat is undefined'));
      return;
    }
    onVesselLocationClicked?.({
      ...mapElement.object,
      type: 'port',
      longitude: mapElement.lngLat[0],
      latitude: mapElement.lngLat[1],
    });
  };

  const isFullView = !selectedItems.items.length;

  return (
    <>
      <MapDetailsHeader>
        <Tooltip title="Center port on map" placement="bottomLeft">
          {!!onVesselLocationClicked && (
            <ActionButtonLeft onClick={centerPortOnMap}>
              <AimOutlined />
            </ActionButtonLeft>
          )}
        </Tooltip>
        <MapDetailsTitle>
          <MapDetailLink to={`/ports/${code}`} target="_blank">
            {name}
            <ExtLinkIcon />
          </MapDetailLink>
          <MapDetailsSubtitle>
            <PortLocation
              location={{
                countryObject: {
                  name: Country.getName(country)!,
                  code: country,
                },
                name: '',
                code,
              }}
            />
          </MapDetailsSubtitle>
        </MapDetailsTitle>
        {isFullView && <MapDetailsWeather weatherCoordinates={{lon: coordinates[0], lat: coordinates[1]}} />}
      </MapDetailsHeader>
      <MapDetailsContent>
        <MapDetailPortAreaContent portCV={portCV} onVesselLocationClicked={onVesselLocationClicked} />
      </MapDetailsContent>
    </>
  );
};

export const MapDetailPortless: React.FC<{onVesselLocationClicked?: OnMapElementChange}> = ({
  onVesselLocationClicked,
}) => {
  const {mapElement} = useSelector(({mapDetails}) => mapDetails);
  // must be portless based on layer switch above
  assert(isMapPortlessElement(mapElement));

  const {
    state: {
      settings: {switches},
    },
  } = useMapContext();
  const {portlessAreas} = mapElement;

  const areaItemsQuery = useAreaItemsQuery(portlessAreas);
  if (areaItemsQuery.isLoading) {
    return MapDetailsLoading;
  }
  if (areaItemsQuery.isError) {
    return MapDetailsError;
  }
  assert(areaItemsQuery.isSuccess);

  const portCV = filterPortAreaData(areaItemsQuery.data, switches);

  return (
    <>
      <MapDetailsHeader>
        <MapDetailsTitle>
          Without designated port in
          {portlessAreas.length === 1 ? (
            <MapDetailsSubtitle>
              {portlessAreas[0].name} ({portlessAreas[0].code})
            </MapDetailsSubtitle>
          ) : (
            <MapDetailsSubtitle $isEllipsis={true}>
              {portlessAreas.map(({code}: MapDetailArea) => code).join(', ')}
            </MapDetailsSubtitle>
          )}
        </MapDetailsTitle>
      </MapDetailsHeader>
      <MapDetailsContent>
        <MapDetailPortAreaContent portCV={portCV} onVesselLocationClicked={onVesselLocationClicked} />
      </MapDetailsContent>
    </>
  );
};

export const MapDetailTradingArea: React.FC<{onVesselLocationClicked?: OnMapElementChange}> = ({
  onVesselLocationClicked,
}) => {
  const {mapElement} = useSelector(({mapDetails}) => mapDetails);
  // must be portless based on layer switch above
  assert(isMapAreaElement(mapElement));

  const {
    state: {
      settings: {switches},
    },
  } = useMapContext();
  const portlessAreas = [mapElement.object.properties];

  const areaItemsQuery = useAreaItemsQuery(portlessAreas);
  if (areaItemsQuery.isLoading) {
    return MapDetailsLoading;
  }
  if (areaItemsQuery.isError) {
    return MapDetailsError;
  }
  assert(areaItemsQuery.isSuccess);

  const portCV = filterPortAreaData(areaItemsQuery.data, switches);

  return (
    <>
      <MapDetailsHeader>
        <MapDetailsTitle>
          Without designated port in
          {portlessAreas.length === 1 ? (
            <MapDetailsSubtitle>
              {portlessAreas[0].name} ({portlessAreas[0].code})
            </MapDetailsSubtitle>
          ) : (
            <MapDetailsSubtitle $isEllipsis={true}>
              {portlessAreas.map(({code}: MapDetailArea) => code).join(', ')}
            </MapDetailsSubtitle>
          )}
        </MapDetailsTitle>
      </MapDetailsHeader>
      <MapDetailsContent>
        <MapDetailPortAreaContent portCV={portCV} onVesselLocationClicked={onVesselLocationClicked} />
      </MapDetailsContent>
    </>
  );
};
