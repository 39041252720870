import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {inboxApi} from '../../../api/symfony/symfonyApi';
import {OfferStatistics} from '../../../api/symfony/generated/models';

export const useInboxOfferQuery = (
  {workspace, from, to}: {workspace?: number; from?: string; to?: string},
  options?: UseQueryOptions<OfferStatistics>
) =>
  useQuery({
    queryKey: ['useInboxOfferQuery', workspace, from, to],
    queryFn: async () => {
      return await inboxApi.getOfferStatisticsByWorkspace({
        workspace: workspace ?? 0,
        to,
        from,
      });
    },
    ...options,
  });
