import chunk from 'lodash/chunk';

type Params<ID, Result> = {
  fetchSingle: (id: ID) => Promise<Result>;
  ids: ID[];
  chunkSize?: number;
  onProgress?: (percentage: number) => void;
};

/**
 * Fetches all objects with the given ids by passing individual ids to the `fetchSingle` function. Limits parallelism in order to protect the server from too much load.
 *
 * @param params.fetchSingle The function to fetch a single item.
 * @param params.ids The ids are the params of the fetchSingle function.
 * @param params.chunkSize The size of the chunks.
 * @param params.onProgress The function to call when the percentage of the fetch is changed.
 * @returns The array of the results of the fetch functions.
 */
export const fetchChunked = async <ID, Result>({
  chunkSize = 10,
  fetchSingle,
  ids,
  onProgress,
}: Params<ID, Result>): Promise<Result[]> => {
  const chunkedIds = chunk(ids, chunkSize);

  const results: Result[] = [];

  let progressPercentage = 0;
  onProgress?.(progressPercentage);
  for (const chunkOfIds of chunkedIds) {
    const resultsOfOneChunk = await Promise.all(chunkOfIds.map(fetchSingle));
    results.push(...resultsOfOneChunk);
    progressPercentage += 100 / chunkedIds.length;
    onProgress?.(progressPercentage);
  }

  return results;
};
