/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FilterType {
  Vessel = 'vessel',
  Cargo = 'cargo',
  Port = 'port',
  Service = 'service',
  Carrier = 'carrier',
}

export function FilterTypeFromJSON(json: any): FilterType {
  return FilterTypeFromJSONTyped(json, false);
}

export function FilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterType {
  return json as FilterType;
}

export function FilterTypeToJSON(value?: FilterType | null): any {
  return value as any;
}
