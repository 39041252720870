import {Col} from 'antd';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import {useMemo} from 'react';
import {OfferBucket} from '../../../../api/symfony/generated/models';
import {getAlphaForChartColor} from '../../utils/getAlphaForChartColor';
import {VerticalPaddingCard} from '../VerticalPaddingCard';
import {UndefinedLabel} from '../UndefinedLabel';

export const VesselSegmentChart = ({buckets}: {buckets: OfferBucket[]}) => {
  const vesselBuckets = useMemo(() => buckets.filter(bucket => (bucket.numberOfVesselOffers ?? 0) > 0), [buckets]);

  const groupBuckets = groupBy(
    vesselBuckets.map(bucket => {
      return {
        name: bucket.tags?.find(item => item.name === 'segment')?.value ?? UndefinedLabel,
        count: bucket.numberOfVesselOffers,
      };
    }),
    'name'
  );

  const groupLenth = Object.keys(groupBuckets).length;
  const data = Object.entries(groupBuckets).map(([key, value], index) => {
    const alpha = getAlphaForChartColor(Math.floor((index / groupLenth) * 100));
    return {
      name: key,
      value: sumBy(value, 'count'),
      itemStyle: {
        color: `rgba(0, 100, 180, ${alpha})`,
      },
    };
  });

  return (
    <Col span={8}>
      <VerticalPaddingCard>
        <ReactECharts
          option={{
            title: {
              text: `Vessels by segment (${sumBy(data, 'value')})`,
              left: 'center',
            },
            tooltip: {
              trigger: 'item',
            },

            series: [
              {
                name: 'vessels',
                type: 'pie',
                radius: '70%',
                data: data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                },
              },
            ],
          }}
          style={{minHeight: 450}}
        />
      </VerticalPaddingCard>
    </Col>
  );
};
