import {Button} from 'antd';
import {FC, useState} from 'react';
import {ExportVoyageTimelineModal} from './ExportVoyageTimelineModal';

export const ExportVoyageTimeline: FC<{
  imo: number;
}> = ({imo}) => {
  const [showExportModal, setShowExportModal] = useState(false);

  return (
    <>
      <ExportVoyageTimelineModal
        visible={showExportModal}
        imo={imo}
        onClose={() => {
          setShowExportModal(false);
        }}
      />
      <Button
        onClick={() => {
          setShowExportModal(true);
        }}>
        Export
      </Button>
    </>
  );
};
