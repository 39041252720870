import {useEffect, useState, FC} from 'react';
import BaseModal from '../../../atoms/BaseModal/index';
import styled from 'styled-components';
import LabelWrapper from '../../../atoms/LabelWrapper/index';
import {MultiValueRemove, SearchIndicator} from '../../../atoms/Select/Indicators';
import autocomplete, {actions as autocompleteActions, Query} from '../../../redux/ApiService/autocomplete';
import ProjectService from '../../../redux/ApiService/ProjectService';
import TabPane from '../../../atoms/Tabs/TabPane';
import {Tabs} from '../../../atoms/Tabs/Tabs';
import {DropdownMultiValue} from '../../../atoms/Select/otherComponents';
import {showNotification} from '../../../utils/notification';
import {vesselDisplay} from '../../../atoms/DropdownDisplay/vesselDisplay';
import {CargoDisplay} from '../../../atoms/DropdownDisplay/CargoDisplay';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {TODO} from '../../../utils/TODO';
import {AsyncMultiSelect} from '../../../atoms/Select/AsyncMultiSelect';
import {Button} from 'antd';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';

export interface Source {
  id: number;
  type?: string;
  value: number;
  [key: string]: unknown;
}

interface Props {
  id: number;
  shortList: TODO;
  type: 'vessel' | 'cargoes';
  onAdd: () => void;
  close: () => void;
}

const AddToShortList: FC<Props> = ({id, shortList, type, onAdd, close}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [shortlistItemId, setShortlistItemId] = useState<Source[]>([]);
  const dispatch = useDispatch();
  const preFillData = useSelector(
    ({api}) =>
      api.autoComplete.preFillSearch.data?.data?.items?.reduce((acc: Source[], curr: TODO) => {
        acc.push({type: curr._source?.aliasName === 'seabo_vessels' ? 'vessel' : 'cargo', ...curr._source});
        return acc;
      }, [])
  );
  const addToShortlist = (id: number, type: 'vessels' | 'cargoes', body: number[]) =>
    dispatch(ProjectService.addToShortlist(id, type, body));
  const preFillSearch = (indexName: string) => dispatch(autocompleteActions.preFillSearch({queryParams: {indexName}}));
  const loadSingleSearchProjects = (query: Query, indexName: string, pageIndex = 1, pageSize = 5) =>
    dispatch(autocomplete.loadSingleSearchProjects(query, {indexName, pageIndex, pageSize}));

  const isVessel = type === 'vessel';

  useEffect(() => {
    preFillSearch(isVessel ? 'vessels_portfolio' : 'cargoes_portfolio');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (data: Source[]) => {
    setShortlistItemId(data);
    setButtonDisabled(!data);
  };

  const handleReset = (key: number) => {
    setShortlistItemId([]);
    setButtonDisabled(true);
    if (key) {
      isVessel ? preFillSearch('vessels_market') : preFillSearch('cargoes_market');
    } else {
      isVessel ? preFillSearch('vessels_portfolio') : preFillSearch('cargoes_portfolio');
    }
  };

  const handleSave = () => {
    if (shortlistItemId.length > 0) {
      const shortlistItemIDs: number[] = shortlistItemId.map((item: Source) => item.id);
      addToShortlist(id, isVessel ? 'vessels' : 'cargoes', shortlistItemIDs)
        .then(() => {
          setButtonDisabled(false);
          onAdd?.();
          close();
          showNotification();
        })
        .catch(() => {
          close();
        });
      setButtonDisabled(true);
    }
  };

  const labelTabPane = isVessel ? 'My Fleet' : 'My Cargoes';
  const labelWrapper = isVessel ? 'Vessel' : 'Cargo';

  return (
    <>
      <StyledShortListContainer>
        <Tabs buttonGroupTab onChangeActive={key => handleReset(key)}>
          <TabPane label={labelTabPane}>
            <CustomLabelWrapper
              label={labelWrapper}
              handleChange={handleChange}
              shortlistItemId={shortlistItemId}
              isVessel={isVessel}
              preFillData={preFillData}
              loadSingleSearchProjects={loadSingleSearchProjects}
              shortList={shortList}
            />
          </TabPane>
          <TabPane label={'Market'}>
            <CustomLabelWrapper
              label={'Market'}
              handleChange={handleChange}
              shortlistItemId={shortlistItemId}
              isVessel={isVessel}
              preFillData={preFillData}
              loadSingleSearchProjects={loadSingleSearchProjects}
              shortList={shortList}
            />
          </TabPane>
        </Tabs>

        <ModalFooter>
          <Button disabled={buttonDisabled} onClick={() => handleSave()} size="large" type="primary">
            Add to shortlist
          </Button>
        </ModalFooter>
      </StyledShortListContainer>
    </>
  );
};

interface CustomLabelWrapperProps {
  label: string;
  isVessel: boolean;
  loadSingleSearchProjects: (q: Query, project: string) => void;
  preFillData: Source[];
  handleChange: (data: Source[]) => void;
  shortList: TODO;
  shortlistItemId: Source[];
}

const CustomLabelWrapper: FC<CustomLabelWrapperProps> = ({
  label,
  isVessel,
  loadSingleSearchProjects,
  preFillData,
  handleChange,
  shortList,
  shortlistItemId,
}) => {
  const labelWrapper = isVessel ? 'Vessel' : 'Cargo';
  const idAsyncMultiSelectIsVessel = isVessel ? 'portfolio-vessel-select' : 'portfolio-cargo-select';
  const idAsyncMultiSelectMarket = isVessel ? 'market-vessel-select' : 'market-cargo-select';
  const labelLoadingSingleSearchProjects = isVessel ? 'vessels_portfolio' : 'cargoes_portfolio';
  const labelLoadingSingleSearchMarket = isVessel ? 'vessels_market' : 'cargoes_market';
  const labelGetOption = (option: TODO) => {
    return isVessel ? vesselDisplay(option, option.highlight) : <CargoDisplay cargo={option} />;
  };
  const placeholderIsVessel = isVessel ? 'Search for vessels in My Fleet' : 'Search for cargoes in My Cargoes';
  const placeholderMarket = isVessel ? 'Search for vessels in Market' : 'Search for cargoes in Market';

  const placeholder = label === 'Market' ? placeholderMarket : placeholderIsVessel;
  const labelLoadingSingleSearch =
    label === 'Market' ? labelLoadingSingleSearchMarket : labelLoadingSingleSearchProjects;
  const idAsyncMultiSelect = label === 'Market' ? idAsyncMultiSelectMarket : idAsyncMultiSelectIsVessel;

  const filterOption = (value: number) =>
    !shortList.find((s: TODO) => {
      const {project = {}, vessel = {}, cargo = {}} = s || {};
      return project.type === 'cargo' ? vessel?.id === value : cargo?.id === value;
    });

  return (
    <LabelWrapper label={labelWrapper} style={{marginTop: 20}} contentWrapperStyle={{marginBottom: 0}}>
      <AsyncMultiSelect<Source>
        id={idAsyncMultiSelect}
        value={shortlistItemId}
        loadOptions={(q: Query) =>
          q.length > 1 ? loadSingleSearchProjects(q, labelLoadingSingleSearch) : Promise.resolve([])
        }
        defaultOptions={preFillData || []}
        components={{
          DropdownIndicator: SearchIndicator,
          MultiValueRemove: MultiValueRemove,
          // @ts-expect-error I don't know what the problem is
          MultiValue: DropdownMultiValue,
        }}
        onChange={(data: TODO) => {
          handleChange(Array.isArray(data) ? data : [data]);
        }}
        getOptionLabel={(option: TODO) => labelGetOption(option)}
        getOptionValue={(option: TODO) => option.id}
        placeholder={placeholder}
        filterOption={(option: TODO) => filterOption(option.value as unknown as number)}
      />
    </LabelWrapper>
  );
};

export const Shortlist = (props: Props) => {
  const isVessel = props.type === 'vessel';
  const title = isVessel ? 'Add vessels to shortlist' : 'Add cargoes to shortlist';

  return (
    <BaseModal middle title={title}>
      {(modalProps: TODO) => <AddToShortList {...props} {...modalProps} />}
    </BaseModal>
  );
};

const StyledShortListContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`;
