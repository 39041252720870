/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ActionValidationViolationList,
  ActionValidationViolationListFromJSON,
  ActionValidationViolationListToJSON,
  CompanyWorkspace,
  CompanyWorkspaceFromJSON,
  CompanyWorkspaceToJSON,
  CompanyWorkspaceCollection,
  CompanyWorkspaceCollectionFromJSON,
  CompanyWorkspaceCollectionToJSON,
  ValidationProblem,
  ValidationProblemFromJSON,
  ValidationProblemToJSON,
  WorkspaceMember,
  WorkspaceMemberFromJSON,
  WorkspaceMemberToJSON,
  WorkspaceWrite,
  WorkspaceWriteFromJSON,
  WorkspaceWriteToJSON,
} from '../models';

export interface AddWorkspaceRequest {
  company: number;
  workspaceWrite: WorkspaceWrite;
}

export interface SelectWorkspaceRequest {
  id: number;
}

export interface SetWorkspaceAsDefaultWorkspaceRequest {
  id: number;
}

export interface UpdateWorkspaceRequest {
  company: number;
  workspace: number;
  companyWorkspace: CompanyWorkspace;
}

export interface UpdateWorkspaceMembersRequest {
  company: string;
  workspace: string;
  workspaceMember: Array<WorkspaceMember>;
}

/**
 *
 */
export class WorkspaceApi extends runtime.BaseAPI {
  /**
   */
  async addWorkspaceRaw(requestParameters: AddWorkspaceRequest): Promise<runtime.ApiResponse<CompanyWorkspace>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling addWorkspace.'
      );
    }

    if (requestParameters.workspaceWrite === null || requestParameters.workspaceWrite === undefined) {
      throw new runtime.RequiredError(
        'workspaceWrite',
        'Required parameter requestParameters.workspaceWrite was null or undefined when calling addWorkspace.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/workspace`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(requestParameters.company))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: WorkspaceWriteToJSON(requestParameters.workspaceWrite),
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyWorkspaceFromJSON(jsonValue));
  }

  /**
   */
  async addWorkspace(requestParameters: AddWorkspaceRequest): Promise<CompanyWorkspace> {
    const response = await this.addWorkspaceRaw(requestParameters);
    return await response.value();
  }

  /**
   * Lists company workspaces the current user is a member of.
   */
  async getCompanyWorkspacesRaw(): Promise<runtime.ApiResponse<CompanyWorkspaceCollection>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/users/company_workspaces`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyWorkspaceCollectionFromJSON(jsonValue));
  }

  /**
   * Lists company workspaces the current user is a member of.
   */
  async getCompanyWorkspaces(): Promise<CompanyWorkspaceCollection> {
    const response = await this.getCompanyWorkspacesRaw();
    return await response.value();
  }

  /**
   */
  async selectWorkspaceRaw(requestParameters: SelectWorkspaceRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling selectWorkspace.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/workspaces/{id}/select`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async selectWorkspace(requestParameters: SelectWorkspaceRequest): Promise<void> {
    await this.selectWorkspaceRaw(requestParameters);
  }

  /**
   */
  async setWorkspaceAsDefaultWorkspaceRaw(
    requestParameters: SetWorkspaceAsDefaultWorkspaceRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling setWorkspaceAsDefaultWorkspace.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/workspace/{id}/make-company-default`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async setWorkspaceAsDefaultWorkspace(requestParameters: SetWorkspaceAsDefaultWorkspaceRequest): Promise<void> {
    await this.setWorkspaceAsDefaultWorkspaceRaw(requestParameters);
  }

  /**
   */
  async updateWorkspaceRaw(requestParameters: UpdateWorkspaceRequest): Promise<runtime.ApiResponse<CompanyWorkspace>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling updateWorkspace.'
      );
    }

    if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
      throw new runtime.RequiredError(
        'workspace',
        'Required parameter requestParameters.workspace was null or undefined when calling updateWorkspace.'
      );
    }

    if (requestParameters.companyWorkspace === null || requestParameters.companyWorkspace === undefined) {
      throw new runtime.RequiredError(
        'companyWorkspace',
        'Required parameter requestParameters.companyWorkspace was null or undefined when calling updateWorkspace.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/workspace/{workspace}`
        .replace(`{${'company'}}`, encodeURIComponent(String(requestParameters.company)))
        .replace(`{${'workspace'}}`, encodeURIComponent(String(requestParameters.workspace))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: CompanyWorkspaceToJSON(requestParameters.companyWorkspace),
    });

    return new runtime.JSONApiResponse(response, jsonValue => CompanyWorkspaceFromJSON(jsonValue));
  }

  /**
   */
  async updateWorkspace(requestParameters: UpdateWorkspaceRequest): Promise<CompanyWorkspace> {
    const response = await this.updateWorkspaceRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async updateWorkspaceMembersRaw(
    requestParameters: UpdateWorkspaceMembersRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.company === null || requestParameters.company === undefined) {
      throw new runtime.RequiredError(
        'company',
        'Required parameter requestParameters.company was null or undefined when calling updateWorkspaceMembers.'
      );
    }

    if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
      throw new runtime.RequiredError(
        'workspace',
        'Required parameter requestParameters.workspace was null or undefined when calling updateWorkspaceMembers.'
      );
    }

    if (requestParameters.workspaceMember === null || requestParameters.workspaceMember === undefined) {
      throw new runtime.RequiredError(
        'workspaceMember',
        'Required parameter requestParameters.workspaceMember was null or undefined when calling updateWorkspaceMembers.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/company/{company}/workspace/{workspace}/members`
        .replace(`{${'company'}}`, encodeURIComponent(String(requestParameters.company)))
        .replace(`{${'workspace'}}`, encodeURIComponent(String(requestParameters.workspace))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.workspaceMember.map(WorkspaceMemberToJSON),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async updateWorkspaceMembers(requestParameters: UpdateWorkspaceMembersRequest): Promise<void> {
    await this.updateWorkspaceMembersRaw(requestParameters);
  }
}
