import {PasswordRecoveryRequest} from '../api/symfony/generated';
import {authenticationApi} from '../api/symfony/symfonyApi';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError} from '../api/utils/ApiError';

export const useRecoverPasswordMutation = (options?: UseMutationOptions<void, ApiError, PasswordRecoveryRequest>) => {
  return useMutation({
    mutationFn: (passwordRecoveryRequest: PasswordRecoveryRequest): Promise<void> =>
      authenticationApi.recoverPassword({passwordRecoveryRequest}),
    ...options,
  });
};
