import React, {FC} from 'react';
import styled from 'styled-components';
import {UserDateTime} from '../../../../../../components/DateTime/UserDateTime';
import {NegotiationActor, NegotiationRoundResultSummary} from '../../../../../../api/node-backend/generated';
import {NegotiationActorLabel} from '../../../../Components/NegotiationActorLabel';
import {NegotiationRoundSummaryTermStats} from '../../../../Components/RoundTermStats/NegotiationRoundTermStats';
import {EventContainer, ActiveDetailArrow, EventBubble} from './shared';

type RoundResultBubbleProps = {
  actor: NegotiationActor;
  isSelf: boolean;
  roundResult: NegotiationRoundResultSummary;
  roundIndex: number;
  isSelected: boolean;
  onSelect?: () => void;
};

export const RoundResultBubble: FC<RoundResultBubbleProps> = ({
  actor,
  isSelf,
  roundResult,
  roundIndex,
  isSelected,
  onSelect,
}) => {
  const {committedAt} = roundResult;

  return (
    <EventContainer
      $color={isSelf ? 'self' : 'partner'}
      onClick={onSelect}
      data-testid={`TimelineEventBubbleRoundResult${isSelf ? 'Self' : 'Partner'}`}>
      <ActorEventBubble $isSelf={isSelf} $isSelected={isSelected}>
        <ActorLabel actor={actor} />
        <NegotiationRoundSummaryTermStats {...roundResult} grouped />
      </ActorEventBubble>
      <TimelineDot $isSelected={isSelected}>{roundIndex + 1}</TimelineDot>
      <TimestampLabel $isSelf={isSelf}>
        <UserDateTime value={new Date(committedAt!).toISOString()} format="dateTime" />
      </TimestampLabel>
      {isSelected && <ActiveDetailArrow />}
    </EventContainer>
  );
};

type CurrentRoundBubbleProps = {
  roundResult: NegotiationRoundResultSummary;
};

export const CurrentRoundBubble: FC<CurrentRoundBubbleProps> = ({roundResult}) => (
  <EventContainer $color="self" data-testid="TimelineEventBubbleCurrentRound">
    <EventBubble>
      <b style={{whiteSpace: 'pre'}}>Current round</b>
      <NegotiationRoundSummaryTermStats {...roundResult} grouped />
    </EventBubble>
  </EventContainer>
);

const ActorEventBubble = styled(EventBubble)<{$isSelf?: boolean; $isSelected?: boolean}>`
  --border-offset: ${({$isSelected}) => ($isSelected ? '-6.5px' : '-5px')};
  --rotation: ${({$isSelf}) => ($isSelf ? '135deg' : '315deg')};

  ${({$isSelf}) => ($isSelf ? 'margin-bottom: var(--inner-margin);' : 'margin-top: var(--inner-margin);')}

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    height: 10px;
    width: 10px;
    transform: translateX(-50%) rotateZ(45deg);
    background: linear-gradient(var(--rotation), rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 48%, var(--accent-color-bg) 49%);

    ${({$isSelf}) =>
      $isSelf
        ? `
            border-bottom: var(--bubble-border);
            border-right: var(--bubble-border);
            bottom: var(--border-offset);
          `
        : `
            border-top: var(--bubble-border);
            border-left: var(--bubble-border);
            top: var(--border-offset);
          `}
  }
`;

const TimelineDot = styled.div<{$isSelected?: boolean}>`
  --size: ${({$isSelected}) => ($isSelected ? '16px' : '13px')};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--size);
  min-width: var(--size);
  padding: 1px 3px;
  background: var(--accent-color);
  color: var(--color-white);
  font-size: var(--font-size-xs);
  font-weight: bold;
  border-radius: var(--size);
  z-index: 3;
`;

const TimestampLabel = styled.div<{$isSelf?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-xs);

  ${({$isSelf}) =>
    $isSelf
      ? `
    order: 3;
    margin-bottom: var(--outer-margin);
  `
      : `
    order: 1;
    margin-top: var(--outer-margin);
  `};
`;

const ActorLabel = styled(NegotiationActorLabel)`
  color: var(--accent-color);
  font-weight: bold;
`;
