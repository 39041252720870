import {useSelector} from '../../../redux/react-redux';
import {CompanySettingsUsers} from '../../CompanyScreens/CompanySettingsScreen/CompanySettingsUsers/CompanySettingsUsers';
import {OnboardingStepWrapper} from '../OnboardingStepWrapper';
import {OnboardingStepProps} from '../useUserOnboarding';

export const AddUsers = (props: OnboardingStepProps) => {
  const company = useSelector(state => state.company.data);
  return (
    <OnboardingStepWrapper
      title="Add users"
      subTitle="Please add users to your company."
      dataTestId="AddUsers"
      {...props}>
      <CompanySettingsUsers company={company} />
    </OnboardingStepWrapper>
  );
};
