/* eslint-disable no-nested-ternary */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import BaseModal from '../../../atoms/BaseModal/index';
import './style.scss';
import {ArrowIndicator} from '../../../atoms/Select/Indicators';
import vesselService from '../../../redux/ApiService/vesselService';
import cargoService from '../../../redux/ApiService/cargoService';
import companyService from '../../../redux/ApiService/CompanyService/CompanyService';
import Loading from '../../../atoms/Loading';
import MarketActions from '../../../redux/ApiService/MarketService';
import {Link} from 'react-router-dom';
import {RootState} from '../../../redux/store';
import Select from '../../../atoms/Select/Select';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {Button} from 'antd';
import styled from 'styled-components';

type MoveToWorkspaceModalState = $TSFixMe;
// eslint-disable-next-line @typescript-eslint/ban-types
class MoveToWorkspaceModal extends Component<{}, MoveToWorkspaceModalState> {
  state = {
    disabled: true,
    loading: true,
    workspaceId: null,
    error: false,
    errorMsg: '',
  };
  componentDidMount() {
    if ((this.props as $TSFixMe).userCompany) {
      (this.props as $TSFixMe).getUserWorkspaceList().then(() => {
        this.setState({loading: false});
      });
    }
  }
  moveToWorkspace() {
    this.setState({disabled: true});
    const {workspaceId} = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const {data, type, close, reload} = this.props;
    switch (type) {
      case 'portfolioVessel':
        (this.props as $TSFixMe)
          .addToWorkspaceVessel(data.id, {workspaceID: workspaceId})
          .then(() => {
            reload();
            this.setState({disabled: false});
            close();
          })
          .catch((e: $TSFixMe) => {
            this.setState({
              errorMsg: e.payload.error.message || 'Something went wrong',
              error: true,
              errorItems: e.payload.error?.items,
            });
          });
        break;
      case 'portfolioCargo':
        (this.props as $TSFixMe)
          .addToWorkspaceCargo(data.id, {workspaceID: workspaceId})
          .then(() => {
            reload();
            this.setState({disabled: false});
            close();
          })
          .catch((e: $TSFixMe) => {
            this.setState({
              errorMsg: e.payload.error.message || 'Something went wrong',
              error: true,
              errorItems: e.payload.error?.items,
            });
          });
        break;
      case 'marketVessel':
        (this.props as $TSFixMe)
          .addToWorkspaceFromVesselMarket(data.id, workspaceId)
          .then(() => {
            this.setState({disabled: false});
            reload();
            close();
          })
          .catch((e: $TSFixMe) => {
            this.setState({
              errorMsg: e.payload.error.message || 'Something went wrong',
              error: true,
              errorItems: e.payload.error?.items,
            });
          });
        break;
      case 'marketCargo':
        (this.props as $TSFixMe)
          .addToWorkspaceFromCargoMarket(data.id, workspaceId)
          .then(() => {
            this.setState({disabled: false});
            reload();
            close();
          })
          .catch((e: $TSFixMe) => {
            this.setState({
              errorMsg: e.payload?.error?.message
                ? e.payload.error.message
                : e.payload?.data?.error?.message
                ? e.payload.data.error.message
                : 'Something went wrong',
              error: true,
              errorItems: e?.payload?.error?.items,
            });
          });
        break;
      default:
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'errorItems' does not exist on type '{ di... Remove this comment to see the full error message
    const {loading, error, errorMsg, errorItems} = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'workspaces' does not exist on type 'Read... Remove this comment to see the full error message
    const {workspaces, data, user, close} = this.props;
    let items = [];
    if (workspaces.data) {
      items = workspaces.data.items.filter((w: $TSFixMe) => w.id !== user.workspace?.id);
    }
    return (
      <>
        {!loading && items ? (
          <div className={'move-to-workspace'}>
            <Select
              className={'move-to-workspace__select'}
              onChange={(type: $TSFixMe, meta: $TSFixMe) => {
                meta.action === 'select-option'
                  ? this.setState({workspaceId: type.id, disabled: false})
                  : this.setState({workspaceId: null, disabled: true});
              }}
              components={{DropdownIndicator: ArrowIndicator}}
              options={items}
              getOptionLabel={(option: $TSFixMe) => option.name}
              getOptionValue={(option: $TSFixMe) => option.id}
              isClearable={true}
            />

            {data && !data.public && (
              <Warning>
                <strong>Warning:</strong> Vessel will be removed from current workspace
              </Warning>
            )}
            {error && (
              <div className="error">
                {errorMsg}
                <div>
                  <div>
                    {errorItems &&
                      errorItems.map((errorItem: $TSFixMe) => (
                        <div>
                          {errorItem.type === 'nego' ? (
                            <Link to={`/negotiations/${errorItem.id}`} onClick={close}>
                              Negotiation {errorItem.id}
                            </Link>
                          ) : (
                            <Link to={`/projects/${errorItem.id}`} onClick={close}>
                              Project {errorItem.id}
                            </Link>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}

            <ModalFooter>
              <Button key="submit" disabled={this.state.disabled} onClick={() => this.moveToWorkspace()} type="primary">
                move to workspace
              </Button>
            </ModalFooter>
          </div>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const Warning = styled.div`
  margin-top: 8px;
`;
const MoveToWorkspaceUnconnected = (props: $TSFixMe) => (
  <BaseModal middle title="Move to workspace">
    {(modalProps: $TSFixMe) => <MoveToWorkspaceModal {...props} {...modalProps} />}
  </BaseModal>
);
const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  addToWorkspaceVessel: (vesselId: $TSFixMe, body: $TSFixMe) => dispatch(vesselService.workspaceUpdate(vesselId, body)),
  addToWorkspaceCargo: (cargoId: $TSFixMe, body: $TSFixMe) => dispatch(cargoService.workspaceUpdate(cargoId, body)),
  addToWorkspaceFromCargoMarket: (offerId: $TSFixMe, workspaceId: $TSFixMe) =>
    dispatch(MarketActions.addToWorkspaceFromCargoMarket(offerId, workspaceId)),
  addToWorkspaceFromVesselMarket: (offerId: $TSFixMe, workspaceId: $TSFixMe) =>
    dispatch(MarketActions.addToWorkspaceFromVesselMarket(offerId, workspaceId)),
  getUserWorkspaceList: () => dispatch(companyService.getUserWorkspaceList()),
});
const mapStateToProps = (state: RootState) => ({
  userCompany: state.user!.payload.company,
  workspaces: state.company.workspaces,
  user: state.user!.payload,
});
export const MoveToWorkspace = connect(mapStateToProps, mapDispatchToProps)(MoveToWorkspaceUnconnected);
