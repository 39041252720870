import {useDispatch, useSelector} from '../../redux/react-redux';
import {SortEnd, SortEndHandler, TabList} from '../../components/TabList/TabList';
import arrayMove from 'array-move';
import {FC, useCallback, useEffect} from 'react';
import {ModalActions} from '../../redux/Modal';
import {assert} from '../../utils/assert';
import sortBy from 'lodash/sortBy';
import {FilterCategory, FilterItem, FilterItemWrite, FilterType} from '../../api/symfony/generated';
import produce from 'immer';
import {useFiltersQuery} from '../../queries/filter/useFiltersQuery';
import {patchFilter, PatchFilterRequest, usePatchFilterMutation} from '../../queries/filter/usePatchFilterMutation';
import {newSortKey} from '../../components/TabList/newSortKey';
import {VesselDatabaseActions} from '../../redux/VesselDatabase';
import {useCreateFilterMutation} from '../../queries/filter/useCreateFilterMutation';
import {useDeleteFilterMutation} from '../../queries/filter/useDeleteFilterMutation';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import {Delete} from '../../components/Modal/Delete/DeleteModal';
import {VesselDatabaseFilterBranchDefinitions} from '../../components/FilterProvider/Filters/VesselDatabase/VesselDatabaseFilterBranchDefinitions';

const FILTER_CATEGORY = FilterCategory.Vesseldatabase;
const FILTER_TYPE = FilterType.Vessel;

interface Props {
  filterProviderApi: FilterProviderApi<typeof VesselDatabaseFilterBranchDefinitions>;
}

export const VesselDatabaseFilterList: FC<Props> = ({filterProviderApi}) => {
  const {filters, activeFilterId, filterProviderState} = useSelector(state => state.vesselDatabase);

  const dispatch = useDispatch();

  const filtersQuery = useFiltersQuery({category: FILTER_CATEGORY});

  const sortedFilters = sortBy(filters, 'sortKey');

  // A tab was moved from oldIndex to newIndex
  const onSortEnd: SortEndHandler = ({oldIndex, newIndex}: SortEnd) => {
    const newSortedFilters = arrayMove(sortedFilters, oldIndex, newIndex);
    newSortedFilters.forEach((filter, index) => {
      const id = filter.id;
      dispatch(VesselDatabaseActions.updateFilterSortKey({id, newSortKey: index}));

      const request: PatchFilterRequest = {
        id,
        filterItemWrite: {
          sortKey: index,
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      patchFilter(request);
    });
  };

  const onSelectTab = useCallback(
    async (tab: FilterItem | 'all'): Promise<void> => {
      const tabId = tab === 'all' ? tab : tab.id;
      dispatch(VesselDatabaseActions.setActiveFilterId(tabId));

      if (tabId === 'all') {
        filterProviderApi.onResetAllClick();
      } else {
        await filterProviderApi.loadFilterItem(tab as FilterItem);
      }
    },
    [dispatch, filterProviderApi]
  );

  const createFilterMutation = useCreateFilterMutation();

  const onCreateTab = async (name: string): Promise<FilterItem> => {
    const sortKey = newSortKey(sortedFilters);
    const filterItemWrite: FilterItemWrite = {
      category: FILTER_CATEGORY,
      type: FILTER_TYPE,
      name,
      sortKey,
      filterSettings: undefined,
    };
    const filter: FilterItem = await createFilterMutation.mutateAsync({
      filterItemWrite,
    });
    dispatch(VesselDatabaseActions.addFilter(filter));
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onSelectTab(filter);
    await filtersQuery.refetch();

    return filter;
  };

  const patchFilterMutation = usePatchFilterMutation();

  const onRenameTab = async (newName: string, filterId: number): Promise<void> => {
    const filter = sortedFilters.find(tab => tab.id === filterId);
    assert(!!filter, `filter with id ${filterId} not found`);

    const request: PatchFilterRequest = {
      id: filterId,
      filterItemWrite: {
        name: newName,
      },
    };
    const updatedFilter: FilterItem = produce(filter, draft => {
      draft.name = newName;
    });
    dispatch(VesselDatabaseActions.updateFilter(updatedFilter));

    await patchFilterMutation.mutateAsync(request);

    await filtersQuery.refetch();
  };

  const deleteFilterMutation = useDeleteFilterMutation();

  const onDeleteTab = (tab: FilterItem) => {
    const {id, name} = tab;
    dispatch(
      ModalActions.show(
        <Delete
          target={{
            name,
            id,
          }}
          onDelete={async (id: number, _callback: $TSFixMe, close: () => void) => {
            close();
            await deleteFilterMutation.mutateAsync({id});
            dispatch(VesselDatabaseActions.deleteFilter(id));
            await filtersQuery.refetch();
            void onSelectTab('all');
          }}
        />
      )
    );
  };

  useEffect(() => {
    if (filtersQuery.isSuccess && filtersQuery.data) {
      dispatch(VesselDatabaseActions.setFilters(filtersQuery.data));
      if (!filterProviderState) {
        // We initialize the tab id only on the very first render, because we don't want to lose the selected tab
        // when switching between e.g. Vessel DB, Market, and back.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onSelectTab('all');
      }
    }
  }, [filtersQuery.isSuccess, filtersQuery.data, dispatch, filterProviderState, onSelectTab]);

  return (
    <TabList<FilterItem>
      tabs={sortedFilters}
      activeTab={activeFilterId}
      allItemsLabel={'All vessels'}
      createTabDefaultName="New filter"
      createModalTitle="New filter"
      createModalButtonLabel="Create"
      renameModalTitle="Rename filter"
      renameModalButtonLabel="Rename"
      createTabEnabled={true}
      onSelectTab={onSelectTab}
      onSortEnd={onSortEnd}
      onCreateTab={onCreateTab}
      onRenameTab={onRenameTab}
      onDeleteTab={onDeleteTab}
    />
  );
};
