import React, {FC} from 'react';
import {Icon} from '../../../atoms/Icon';
import styled from 'styled-components';
import {mailForwardingHelpLinkList} from '../mailForwardingHelpLinkList';
import {Typography} from 'antd';

const {Link} = Typography;

export const MailForwardingHelpLinkList: FC<{onSupportLinkClick?: () => void}> = ({onSupportLinkClick}) => {
  return (
    <LinkList>
      {mailForwardingHelpLinkList.map((link, idx) => (
        <li key={idx}>
          <a href={link.url} target="_blank" rel="noreferrer">
            <StyledIcon size="small" type="arrow-right" />
            {link.title}
          </a>
        </li>
      ))}
      <li onClick={onSupportLinkClick}>
        <Link>
          <StyledIcon size="small" type="arrow-right" />
          Others (Support)
        </Link>
      </li>
    </LinkList>
  );
};

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle;
`;

const LinkList = styled.ul`
  width: 145px;
  padding: 0;
  margin: 0;
  list-style: none;
`;
