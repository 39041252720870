import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {convertValueToNamedValue, NamedValue} from '../../FilterConversion/utils/NamedValue';
import {parseIntTS} from '../../../../../utils/parseNumberTS';
import {convertToInt} from '../../FilterConversion/utils/FilterConversionUtils';

export interface DatabaseType {
  periodFrom?: number;
  periodTo?: number;
  periodUnit: PeriodUnit;
  rateFrom?: number;
  rateTo?: number;
  rateCurrency?: RateCurrency;
  rateTerms?: RateTerm;
}

export interface InternalType {
  periodFrom: number | '';
  periodTo: number | '';
  periodUnit: NamedValue<PeriodUnit>;
  rateFrom: number | '';
  rateTo: number | '';
  rateCurrency: NamedValue<RateCurrency> | null;
  rateTerms: NamedValue<RateTerm> | null;
}

export const Defaults: InternalType = {
  periodFrom: '',
  periodTo: '',
  periodUnit: {name: 'Days', value: 'days'},
  rateFrom: '',
  rateTo: '',
  rateCurrency: null,
  rateTerms: null,
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    rateTerms: convertValueToNamedValue<RateTerm, null>({
      value: database.rateTerms,
      defaultValue: null,
      available: PeriodRateTermOptions,
    }),
    rateCurrency: convertValueToNamedValue<RateCurrency, null>({
      value: database.rateCurrency,
      defaultValue: null,
      available: PeriodRateCurrencyOptions,
    }),
    periodUnit: convertValueToNamedValue<PeriodUnit, null>({
      value: database.periodUnit,
      defaultValue: null,
      available: PeriodOptionUnits,
    })!,
    periodFrom: database.periodFrom ? parseIntTS(database.periodFrom) : '',
    periodTo: database.periodTo ? parseIntTS(database.periodTo) : '',
    rateFrom: database.rateFrom ? parseIntTS(database.rateFrom) : '',
    rateTo: database.rateTo ? parseIntTS(database.rateTo) : '',
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => ({
  periodFrom: convertToInt(internal.periodFrom),
  periodTo: convertToInt(internal.periodTo),
  rateFrom: convertToInt(internal.rateFrom),
  rateTo: convertToInt(internal.rateTo),
  periodUnit: internal.periodUnit.value,
  rateCurrency: internal.rateCurrency ? internal.rateCurrency.value : undefined,
  rateTerms: internal.rateTerms ? internal.rateTerms.value : undefined,
});

type PeriodUnit = 'days' | 'weeks' | 'months' | 'years';

export const PeriodOptionUnits: NamedValue<PeriodUnit>[] = [
  {name: 'Days', value: 'days'},
  {name: 'Weeks', value: 'weeks'},
  {name: 'Months', value: 'months'},
  {name: 'Years', value: 'years'},
];

type RateTerm = 'day' | 'mt' | 'cbm' | 'lumpsum' | 'teu' | 'feu';
export const PeriodRateTermOptions: NamedValue<RateTerm>[] = [
  {name: 'per day', value: 'day'},
  {name: 'per mt', value: 'mt'},
  {name: 'per cbm', value: 'cbm'},
  {name: 'Lumpsum', value: 'lumpsum'},
  {name: 'per TEU', value: 'teu'},
  {name: 'per FEU', value: 'feu'},
];

type RateCurrency = 'usd' | 'eur' | 'yen' | 'other';
export const PeriodRateCurrencyOptions: NamedValue<RateCurrency>[] = [
  {name: 'USD', value: 'usd'},
  {name: 'EUR', value: 'eur'},
  {name: 'YEN', value: 'yen'},
  {name: 'Other', value: 'other'},
];

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Duration / Rate',
  branch: 'fixtureOptions',
  defaults: Defaults,

  fromDatabase,
  toDatabase,
});
