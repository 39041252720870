import {SearchElement} from './SearchElement';

interface Layer {
  id: string;
}

export const transformSearchElementToSelectElement = ({
  searchItem,
  layers,
}: {
  searchItem: SearchElement;
  layers: Layer[];
}) => {
  let layerId: 'AIS' | 'PORT';
  switch (searchItem.type) {
    case 'ais-vessel':
      layerId = 'AIS';
      break;
    case 'port':
      layerId = 'PORT';
      break;
    default:
      return null;
  }
  const layer = layers.find(layer => layer.id === layerId);
  if (layer === undefined) {
    return null;
  }
  return {
    object: {...searchItem, coordinates: [searchItem.longitude, searchItem.latitude]},
    coordinates: [searchItem.longitude, searchItem.latitude],
    lngLat: [searchItem.longitude, searchItem.latitude],
    layer,
    devicePixel: [200, 200],
    pixel: [200, 200],
    pixelRatio: 1,
    x: 200,
    y: 200,
    index: 1,
    color: [207, 52, 0, 3],
  };
};
