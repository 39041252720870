import {Button} from '../../../atoms/Button/Button';
import emptySubMarketOne from './empty-sub-market-1.png';
import emptySubMarketCargoTwo from './empty-sub-market-cargo-2.png';
import emptySubMarketThree from './empty-sub-market-3.png';

import './style.scss';

const EmptyStateMarketFiltersCargo = ({onClick}: $TSFixMe) => (
  <div id="emptyStateContainer">
    <div id="emptyStateSubMarketContainer" className="emptyStateContainer">
      <h2>
        Creating submarkets <br /> for a customised market view
      </h2>
      <div className="imageDiv">
        <div className="image">
          <img src={emptySubMarketOne} alt="All filters" />
        </div>
        <div className="image">
          <img src={emptySubMarketCargoTwo} alt="Select filters" />
        </div>
        <div className="image">
          <img src={emptySubMarketThree} alt="Save filters" />
        </div>
        <div className="image">
          Switch to <b>filter view</b>
          <br /> in the map toggle
        </div>
        <div className="image">
          Select and apply the
          <br />
          filters you need
        </div>
        <div className="image">Save filters</div>
      </div>
      <Button spMT={'xl'} primary upper bold label={'Go to filters'} onClick={onClick} />
    </div>
  </div>
);

export default EmptyStateMarketFiltersCargo;
