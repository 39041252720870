import DateTime from '../../../../model/DateTime';
import {RowValueType} from '../../utils/RowValueType';
import {TODO} from '../../../../utils/TODO';
import {getVesselFromRowValue} from '../../utils/getVesselFromRowValue';
import Station from '../../../../model/Station';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {AccessorFn} from '@tanstack/react-table';
import {SeaboVessel} from '../../../../api/node-backend/generated';

const openAccessor =
  (type: RowValueType, {showFlag}: {showFlag?: boolean}): AccessorFn<SeaboVessel> =>
  rowValue => {
    const vessel = getVesselFromRowValue(rowValue, type);
    const dateOpenFormatted = formatDateOpenAsJsx(vessel);
    return (
      <div className={'next-open-column inline-editable'} data-date={vessel.nextOpenFrom || null}>
        <div className="next-open-column__date" data-cy="NextOpenDate">
          {dateOpenFormatted}
        </div>
        <div className="next-open-column__location" data-cy="NextOpenLocation">
          {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"nextopen"' is not assignable to... Remove this comment to see the full error message */}
          {Station.getLocationByStationType(vessel.stations, 'nextopen', {
            short: true,
            highlight: true,
            emptyState: '-',
            showFlag,
          })}
        </div>
      </div>
    );
  };

export const formatDateOpenAsJsx = (vessel: $TSFixMe) => {
  const {spotState, spotDate, nextOpenFrom, nextOpenTo} = vessel;
  return spotState
    ? 'Spot' + (spotDate && ' (' + DateTime.fromServerResponse(spotDate).getFormattedDate() + ')')
    : dateRangeAsJsx(nextOpenFrom, nextOpenTo);
};
const dateRangeAsJsx = (fromDate: $TSFixMe, toDate: $TSFixMe) => {
  if (!fromDate && !toDate) {
    return 'N/A';
  }
  const from = fromDate ? DateTime.fromServerResponse(fromDate).getFormattedDate() : '';
  const to = toDate ? DateTime.fromServerResponse(toDate).getFormattedDate() : '';
  const showTwoDates = fromDate && toDate && fromDate !== toDate;
  return showTwoDates ? (
    <>
      {from} -
      <br />
      {to}
    </>
  ) : (
    from
  );
};
export const openColumn = ({
  type,
  minWidth = 20,
  sortable = true,
  showFlag = false,
}: {
  type: RowValueType;
  minWidth?: number;
  sortable?: boolean;
  showFlag?: boolean;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-open">
      <div>Open</div>
    </div>
  ),

  id: 'nextOpen',
  accessorFn: openAccessor(type, {showFlag}),
  cell: info => info.getValue(),
  minWidth,
  enableSorting: sortable,
  minSize: minWidth,
  sortingFn: (a: TODO, b: TODO) => {
    if (a.props['data-date'] === b.props['data-date']) {
      return 0;
    }
    return a.props['data-date'] > b.props['data-date'] ? -1 : 1;
  },
});
