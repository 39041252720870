import {Tabs} from 'antd';
import {AllSenders} from './Tabs/TopTenSender/AllSenders';
import {PerSender} from './Tabs/PerSender/PerSender';
import dayjs from 'dayjs';
import {useInboxOfferQuery} from './utils/useInboxOfferQuery';
import {LoadingModal} from '../../components/LoadingModal/LoadingModal';
import {useChangeBrowserUrl} from '../../utils/useChangeBrowserUrl';
import styled from 'styled-components';
import Lighthouse from '../../atoms/EmptyState/LightHouse';
import {MarketImportPromotion} from '../../components/MarketImportPromotion/MarketImportPromotion';
import {DateTimeFormat} from '../../utils/DateTimeFormat';
import {useUser} from '../../components/UserContext/useUser';

export const CircularsStatsBody = () => {
  const user = useUser();
  const workspaceId = user.workspace?.id;
  const changeBrowserUrl = useChangeBrowserUrl();

  const from = dayjs().subtract(365, 'days').format(DateTimeFormat.IsoDate);

  const offerQuery = useInboxOfferQuery({
    workspace: workspaceId,
    from,
  });

  if (!offerQuery.isSuccess) {
    return (
      <LoadingModal
        isLoading={true}
        onCancel={() => {
          changeBrowserUrl('/dashboard');
        }}
      />
    );
  }

  if (!offerQuery.data?.buckets || offerQuery.data.buckets.length === 0) {
    return (
      <>
        <Lighthouse
          headline="No data"
          subHeadline="No data available for this workspace. Connct your mailbox to seabo or import circulars manually to get analytics."
        />
        <MarketImportPromotion />
      </>
    );
  }

  return (
    <StyledTabs
      items={[
        {key: 'allSenders', label: 'All senders', children: <AllSenders offerStatistics={offerQuery.data} />},
        {key: 'perSender', label: 'Per sender', children: <PerSender offerStatistics={offerQuery.data} />},
      ]}
    />
  );
};

const StyledTabs = styled(Tabs)`
  overflow: inherit !important;

  .ant-tabs-tabpane-active {
    overflow-y: inherit;

    > div {
      overflow: inherit;
    }
  }

  .ant-tabs-nav {
    margin: 0;
  }
`;
