/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BaleVolumeUnit,
  BaleVolumeUnitFromJSON,
  BaleVolumeUnitToJSON,
  BatchRemoveVesselsFromSubPortfolioRequest,
  BatchRemoveVesselsFromSubPortfolioRequestFromJSON,
  BatchRemoveVesselsFromSubPortfolioRequestToJSON,
  BulkerDesignType,
  BulkerDesignTypeFromJSON,
  BulkerDesignTypeToJSON,
  GrainVolumeUnit,
  GrainVolumeUnitFromJSON,
  GrainVolumeUnitToJSON,
  ObjectCollectionWrapper,
  ObjectCollectionWrapperFromJSON,
  ObjectCollectionWrapperToJSON,
  SortDirection,
  SortDirectionFromJSON,
  SortDirectionToJSON,
  VesselFilterGearedOption,
  VesselFilterGearedOptionFromJSON,
  VesselFilterGearedOptionToJSON,
  VesselStatus,
  VesselStatusFromJSON,
  VesselStatusToJSON,
  VesselType,
  VesselTypeFromJSON,
  VesselTypeToJSON,
} from '../models';

export interface GetPortfolioVesselsRequest {
  archive?: boolean;
  ids?: Array<number>;
  vesselTypes?: Array<VesselType>;
  name?: string;
  imo?: number;
  minimumAge?: number;
  maximumAge?: number;
  flags?: Array<string>;
  includeFlags?: boolean;
  deadweightFrom?: number;
  deadweightTo?: number;
  holdsFrom?: number;
  holdsTo?: number;
  hatchesFrom?: number;
  hatchesTo?: number;
  teuFrom?: number;
  teuTo?: number;
  teu14From?: number;
  teu14To?: number;
  bulkerDesignTypes?: Array<BulkerDesignType>;
  containerDesignTypes?: Array<string>;
  lengthFrom?: number;
  lengthTo?: number;
  beamFrom?: number;
  beamTo?: number;
  draftFrom?: number;
  draftTo?: number;
  grossTonnageFrom?: number;
  grossTonnageTo?: number;
  netTonnageFrom?: number;
  netTonnageTo?: number;
  tonnesPerCentimetreFrom?: number;
  tonnesPerCentimetreTo?: number;
  grainVolumeFrom?: number;
  grainVolumeTo?: number;
  grainUnit?: GrainVolumeUnit;
  baleVolumeFrom?: number;
  baleVolumeTo?: number;
  baleUnit?: BaleVolumeUnit;
  reeferPlugsFrom?: number;
  reeferPlugsTo?: number;
  cellular?: boolean;
  openHatched?: boolean;
  boxShaped?: boolean;
  lakesFitted?: boolean;
  itfFitted?: boolean;
  tweenDecks?: boolean;
  bowThrusted?: boolean;
  logFitted?: boolean;
  a60Bulkhead?: boolean;
  sternThrusted?: boolean;
  co2Fitted?: boolean;
  scrubberFitted?: boolean;
  geared?: VesselFilterGearedOption;
  iceClass?: boolean;
  dateOpenFrom?: Date;
  dateOpenTo?: Date;
  spotState?: boolean;
  spotOnly?: boolean;
  positionOpenPorts?: Array<number>;
  positionOpenTradingAreas?: Array<number>;
  positionOpenCountries?: Array<number>;
  positionOpenCollections?: Array<number>;
  vesselStatuses?: Array<VesselStatus>;
  registeredOwner?: string;
  managingOwner?: string;
  disponentOwners?: Array<string>;
  group?: number;
  groups?: string;
  filters?: string;
  pageNumber?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: SortDirection;
}

export interface RemoveVesselFromPortfolioGroupsRequest {
  batchRemoveVesselsFromSubPortfolioRequest: BatchRemoveVesselsFromSubPortfolioRequest;
}

/**
 *
 */
export class PortfolioVesselApi extends runtime.BaseAPI {
  /**
   */
  async getPortfolioVesselsRaw(
    requestParameters: GetPortfolioVesselsRequest
  ): Promise<runtime.ApiResponse<ObjectCollectionWrapper>> {
    const queryParameters: any = {};

    if (requestParameters.archive !== undefined) {
      queryParameters['archive'] = requestParameters.archive;
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids;
    }

    if (requestParameters.vesselTypes) {
      queryParameters['vesselTypes[]'] = requestParameters.vesselTypes;
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name;
    }

    if (requestParameters.imo !== undefined) {
      queryParameters['imo'] = requestParameters.imo;
    }

    if (requestParameters.minimumAge !== undefined) {
      queryParameters['minimumAge'] = requestParameters.minimumAge;
    }

    if (requestParameters.maximumAge !== undefined) {
      queryParameters['maximumAge'] = requestParameters.maximumAge;
    }

    if (requestParameters.flags) {
      queryParameters['flags[]'] = requestParameters.flags;
    }

    if (requestParameters.includeFlags !== undefined) {
      queryParameters['includeFlags'] = requestParameters.includeFlags;
    }

    if (requestParameters.deadweightFrom !== undefined) {
      queryParameters['deadweightFrom'] = requestParameters.deadweightFrom;
    }

    if (requestParameters.deadweightTo !== undefined) {
      queryParameters['deadweightTo'] = requestParameters.deadweightTo;
    }

    if (requestParameters.holdsFrom !== undefined) {
      queryParameters['holdsFrom'] = requestParameters.holdsFrom;
    }

    if (requestParameters.holdsTo !== undefined) {
      queryParameters['holdsTo'] = requestParameters.holdsTo;
    }

    if (requestParameters.hatchesFrom !== undefined) {
      queryParameters['hatchesFrom'] = requestParameters.hatchesFrom;
    }

    if (requestParameters.hatchesTo !== undefined) {
      queryParameters['hatchesTo'] = requestParameters.hatchesTo;
    }

    if (requestParameters.teuFrom !== undefined) {
      queryParameters['teuFrom'] = requestParameters.teuFrom;
    }

    if (requestParameters.teuTo !== undefined) {
      queryParameters['teuTo'] = requestParameters.teuTo;
    }

    if (requestParameters.teu14From !== undefined) {
      queryParameters['teu14From'] = requestParameters.teu14From;
    }

    if (requestParameters.teu14To !== undefined) {
      queryParameters['teu14To'] = requestParameters.teu14To;
    }

    if (requestParameters.bulkerDesignTypes) {
      queryParameters['bulkerDesignTypes[]'] = requestParameters.bulkerDesignTypes;
    }

    if (requestParameters.containerDesignTypes) {
      queryParameters['containerDesignTypes[]'] = requestParameters.containerDesignTypes;
    }

    if (requestParameters.lengthFrom !== undefined) {
      queryParameters['lengthFrom'] = requestParameters.lengthFrom;
    }

    if (requestParameters.lengthTo !== undefined) {
      queryParameters['lengthTo'] = requestParameters.lengthTo;
    }

    if (requestParameters.beamFrom !== undefined) {
      queryParameters['beamFrom'] = requestParameters.beamFrom;
    }

    if (requestParameters.beamTo !== undefined) {
      queryParameters['beamTo'] = requestParameters.beamTo;
    }

    if (requestParameters.draftFrom !== undefined) {
      queryParameters['draftFrom'] = requestParameters.draftFrom;
    }

    if (requestParameters.draftTo !== undefined) {
      queryParameters['draftTo'] = requestParameters.draftTo;
    }

    if (requestParameters.grossTonnageFrom !== undefined) {
      queryParameters['grossTonnageFrom'] = requestParameters.grossTonnageFrom;
    }

    if (requestParameters.grossTonnageTo !== undefined) {
      queryParameters['grossTonnageTo'] = requestParameters.grossTonnageTo;
    }

    if (requestParameters.netTonnageFrom !== undefined) {
      queryParameters['netTonnageFrom'] = requestParameters.netTonnageFrom;
    }

    if (requestParameters.netTonnageTo !== undefined) {
      queryParameters['netTonnageTo'] = requestParameters.netTonnageTo;
    }

    if (requestParameters.tonnesPerCentimetreFrom !== undefined) {
      queryParameters['tonnesPerCentimetreFrom'] = requestParameters.tonnesPerCentimetreFrom;
    }

    if (requestParameters.tonnesPerCentimetreTo !== undefined) {
      queryParameters['tonnesPerCentimetreTo'] = requestParameters.tonnesPerCentimetreTo;
    }

    if (requestParameters.grainVolumeFrom !== undefined) {
      queryParameters['grainVolumeFrom'] = requestParameters.grainVolumeFrom;
    }

    if (requestParameters.grainVolumeTo !== undefined) {
      queryParameters['grainVolumeTo'] = requestParameters.grainVolumeTo;
    }

    if (requestParameters.grainUnit !== undefined) {
      queryParameters['grainUnit'] = requestParameters.grainUnit;
    }

    if (requestParameters.baleVolumeFrom !== undefined) {
      queryParameters['baleVolumeFrom'] = requestParameters.baleVolumeFrom;
    }

    if (requestParameters.baleVolumeTo !== undefined) {
      queryParameters['baleVolumeTo'] = requestParameters.baleVolumeTo;
    }

    if (requestParameters.baleUnit !== undefined) {
      queryParameters['baleUnit'] = requestParameters.baleUnit;
    }

    if (requestParameters.reeferPlugsFrom !== undefined) {
      queryParameters['reeferPlugsFrom'] = requestParameters.reeferPlugsFrom;
    }

    if (requestParameters.reeferPlugsTo !== undefined) {
      queryParameters['reeferPlugsTo'] = requestParameters.reeferPlugsTo;
    }

    if (requestParameters.cellular !== undefined) {
      queryParameters['cellular'] = requestParameters.cellular;
    }

    if (requestParameters.openHatched !== undefined) {
      queryParameters['openHatched'] = requestParameters.openHatched;
    }

    if (requestParameters.boxShaped !== undefined) {
      queryParameters['boxShaped'] = requestParameters.boxShaped;
    }

    if (requestParameters.lakesFitted !== undefined) {
      queryParameters['lakesFitted'] = requestParameters.lakesFitted;
    }

    if (requestParameters.itfFitted !== undefined) {
      queryParameters['itfFitted'] = requestParameters.itfFitted;
    }

    if (requestParameters.tweenDecks !== undefined) {
      queryParameters['tweenDecks'] = requestParameters.tweenDecks;
    }

    if (requestParameters.bowThrusted !== undefined) {
      queryParameters['bowThrusted'] = requestParameters.bowThrusted;
    }

    if (requestParameters.logFitted !== undefined) {
      queryParameters['logFitted'] = requestParameters.logFitted;
    }

    if (requestParameters.a60Bulkhead !== undefined) {
      queryParameters['a60Bulkhead'] = requestParameters.a60Bulkhead;
    }

    if (requestParameters.sternThrusted !== undefined) {
      queryParameters['sternThrusted'] = requestParameters.sternThrusted;
    }

    if (requestParameters.co2Fitted !== undefined) {
      queryParameters['co2Fitted'] = requestParameters.co2Fitted;
    }

    if (requestParameters.scrubberFitted !== undefined) {
      queryParameters['scrubberFitted'] = requestParameters.scrubberFitted;
    }

    if (requestParameters.geared !== undefined) {
      queryParameters['geared'] = requestParameters.geared;
    }

    if (requestParameters.iceClass !== undefined) {
      queryParameters['iceClass'] = requestParameters.iceClass;
    }

    if (requestParameters.dateOpenFrom !== undefined) {
      queryParameters['dateOpenFrom'] = (requestParameters.dateOpenFrom as any).toISOString().substr(0, 10);
    }

    if (requestParameters.dateOpenTo !== undefined) {
      queryParameters['dateOpenTo'] = (requestParameters.dateOpenTo as any).toISOString().substr(0, 10);
    }

    if (requestParameters.spotState !== undefined) {
      queryParameters['spotState'] = requestParameters.spotState;
    }

    if (requestParameters.spotOnly !== undefined) {
      queryParameters['spotOnly'] = requestParameters.spotOnly;
    }

    if (requestParameters.positionOpenPorts) {
      queryParameters['positionOpen[ports][]'] = requestParameters.positionOpenPorts;
    }

    if (requestParameters.positionOpenTradingAreas) {
      queryParameters['positionOpen[tradingAreas][]'] = requestParameters.positionOpenTradingAreas;
    }

    if (requestParameters.positionOpenCountries) {
      queryParameters['positionOpen[countries][]'] = requestParameters.positionOpenCountries;
    }

    if (requestParameters.positionOpenCollections) {
      queryParameters['positionOpen[collections][]'] = requestParameters.positionOpenCollections;
    }

    if (requestParameters.vesselStatuses) {
      queryParameters['vesselStatuses[]'] = requestParameters.vesselStatuses;
    }

    if (requestParameters.registeredOwner !== undefined) {
      queryParameters['registeredOwner'] = requestParameters.registeredOwner;
    }

    if (requestParameters.managingOwner !== undefined) {
      queryParameters['managingOwner'] = requestParameters.managingOwner;
    }

    if (requestParameters.disponentOwners) {
      queryParameters['disponentOwners[]'] = requestParameters.disponentOwners;
    }

    if (requestParameters.group !== undefined) {
      queryParameters['group'] = requestParameters.group;
    }

    if (requestParameters.groups !== undefined) {
      queryParameters['groups'] = requestParameters.groups;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters['filters'] = requestParameters.filters;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters['pageNumber'] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters['sortField'] = requestParameters.sortField;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/vessels`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => ObjectCollectionWrapperFromJSON(jsonValue));
  }

  /**
   */
  async getPortfolioVessels(requestParameters: GetPortfolioVesselsRequest): Promise<ObjectCollectionWrapper> {
    const response = await this.getPortfolioVesselsRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async removeVesselFromPortfolioGroupsRaw(
    requestParameters: RemoveVesselFromPortfolioGroupsRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.batchRemoveVesselsFromSubPortfolioRequest === null ||
      requestParameters.batchRemoveVesselsFromSubPortfolioRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'batchRemoveVesselsFromSubPortfolioRequest',
        'Required parameter requestParameters.batchRemoveVesselsFromSubPortfolioRequest was null or undefined when calling removeVesselFromPortfolioGroups.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/portfolio/vessels/batch-remove-from-subportfolio`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: BatchRemoveVesselsFromSubPortfolioRequestToJSON(
        requestParameters.batchRemoveVesselsFromSubPortfolioRequest
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async removeVesselFromPortfolioGroups(requestParameters: RemoveVesselFromPortfolioGroupsRequest): Promise<void> {
    await this.removeVesselFromPortfolioGroupsRaw(requestParameters);
  }
}
