import {CostInput} from '../../CostInformation/CostTypes';
import {getUsedFuelType} from '../../utils/getUsedFuelType';
import {calculateConsumptionTakingIntoAccountFuelSubstitution} from '../../utils/calculateConsumptionTakingIntoAccountFuelSubstitution';
import {getBunkerPriceByFuelType} from '../../utils/getBunkerPriceByFuelType';
import {ConsumptionType, VoyageRoute} from '../VoyageTypes';
import {ConsumptionResult} from './getConsumptionForPoint';
import {calculateCO2Emission} from '../../utils/calculateCO2Emission';

const calcSubRoutePart = ({
  route,
  type,
  isScrubberFitted,
  isSECA,
  costInput,
  duration,
}: {
  isScrubberFitted: boolean;
  duration: number;
  route: VoyageRoute;
  type: ConsumptionType;
  isSECA: boolean;
  costInput: CostInput;
}): ConsumptionResult => {
  const fuelType = (type === 'main' ? route.consumption?.mainFuelType : route.consumption?.auxFuelType) ?? 'ifo';
  const consumptionFuel = getUsedFuelType({
    isSECA,
    fuelType,
    isScrubberFitted: isScrubberFitted,
  });

  const fuelConsumption =
    (type === 'main' ? route.consumption?.mainConsumption : route.consumption?.auxConsumption) ?? 0;

  const dailyConsumption = calculateConsumptionTakingIntoAccountFuelSubstitution({
    orginalConsumption: fuelConsumption,
    orginalFuelType: fuelType,
    substitutionFuelType: consumptionFuel,
  });

  const consumption = dailyConsumption * duration;

  const pricePerMts = getBunkerPriceByFuelType({costInput: costInput, fuelType: consumptionFuel});
  return {
    fuelType: consumptionFuel,
    dailyConsumption: dailyConsumption,
    duration: duration,
    inSeca: isSECA,
    consumption: consumption,
    pricePerMts: pricePerMts,
    costs: pricePerMts * consumption,
    cO2Emission: calculateCO2Emission({consumption, fuelType: consumptionFuel}),
  };
};

export const getConsumptionForRoute = ({
  route,
  duration,
  type,
  isScrubberFitted,
  costInput,
}: {
  isScrubberFitted: boolean;
  duration: number;
  costInput: CostInput;
  route: VoyageRoute;
  type: ConsumptionType;
}): ConsumptionResult[] => {
  const seaMargin = (100 + (route.seaMargin ?? 0)) / 100;
  const distance = route.distance * seaMargin;
  if (distance === 0) {
    return [];
  }
  const distanceInSeca = (route.distanceInSeca ?? 0) * seaMargin;
  const durationPerDistance = duration / distance;

  const durationSECA = durationPerDistance * distanceInSeca;
  const durationOutsideSECA = Math.round((duration - durationSECA) * 100) / 100;

  const results: ConsumptionResult[] = [];

  if (durationSECA) {
    results.push(
      calcSubRoutePart({
        duration: durationSECA,
        isSECA: true,
        isScrubberFitted,
        type,
        costInput,
        route,
      })
    );
  }
  if (durationOutsideSECA) {
    results.push(
      calcSubRoutePart({
        duration: durationOutsideSECA,
        isSECA: false,
        isScrubberFitted,
        type,
        costInput,
        route,
      })
    );
  }

  return results;
};
