import {Rate} from 'antd';
import styled from 'styled-components';
import {StarFilled} from '@ant-design/icons/lib';

type Props = {
  onClick: (value: number) => void;
  rating: number;
};
export const Rating = (props: Props) => {
  return (
    <StyledRate defaultValue={props.rating} character={<StyledStarFilled />} onChange={value => props.onClick(value)} />
  );
};

const StyledStarFilled = styled<typeof StarFilled>(StarFilled)`
  font-size: var(--font-size-md);
`;

const StyledRate = styled<typeof Rate>(Rate)`
  margin-top: -1px;
  color: var(--color-blue);
`;
