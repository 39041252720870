import {Button} from 'antd';
import {useSelector} from '../../../../redux/react-redux';
import {getHasProPlan, getIsInPromotionPhase, getPromotionDaysLeft} from '../../../../redux/selectors';
import {BannerContent, BannerTitle, ButtonRow, BannerContainer} from './shared';
import styled from 'styled-components';
import dayjs from 'dayjs';

const FEATURE_FLAG_TRIAL_CANCEL = false;

export const CancelTrialBanner = () => {
  const isInPromotionPhase = useSelector(getIsInPromotionPhase);
  const promotionDaysLeft = useSelector(getPromotionDaysLeft);
  const hasProPlan = useSelector(getHasProPlan);

  if (!isInPromotionPhase || hasProPlan) {
    return null;
  }

  // dayjs .humanize() works well for numbers below 25, but shows "a month" for values above, so we fix that manually
  const daysLeftString =
    promotionDaysLeft > 25
      ? `${promotionDaysLeft.toFixed()} days`
      : dayjs.duration(promotionDaysLeft, 'days').humanize();

  return (
    <Banner>
      <Border />
      <BannerContent>
        <BannerTitle>You have {daysLeftString} left on your Pro Plan trial.</BannerTitle>
        <div>Upgrade your plan right now to keep your advantage over the market.</div>
        {FEATURE_FLAG_TRIAL_CANCEL && (
          <ButtonRow>
            <StyledButton type="primary">Cancel Free Trial</StyledButton>
          </ButtonRow>
        )}
      </BannerContent>
    </Banner>
  );
};

const Banner = styled(BannerContainer)`
  background-color: var(--color-upgrade-bg);
  color: var(--color-black);
`;

const Border = styled.div`
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23f75f4e' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

const StyledButton = styled(Button)`
  background: var(--color-upgrade) !important;
  color: var(--color-white) !important;

  &:hover {
    background: var(--color-upgrade) !important;
    color: var(--color-white) !important;
  }
`;
