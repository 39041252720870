import './style.scss';
import React, {FC} from 'react';
import {Attribute} from '../../atoms/Attribute/Attribute';
import styled from 'styled-components';
import {VesselLocationSection} from './VesselLocationSection';
import {VesselItemHeader} from './sections/Header/VesselItemHeader';
import {AttributeData, insertValuesForAttributes, leftBoxAttributes, rightBoxAttributes} from './sections/Header/utils';
import {Card} from 'antd';
import {VesselDetailsVessel} from '../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';

interface VesselDetailHeaderProps {
  vessel: VesselDetailsVessel;
  showDetailLink?: boolean;
}

export type VesselDetailImage = {
  url: string;
};

export const VesselDetailHeader = ({vessel, showDetailLink}: VesselDetailHeaderProps) => {
  const leftColumnAttributeData: AttributeData[] = insertValuesForAttributes(vessel, leftBoxAttributes(vessel));
  const rightColumnAtributeData: AttributeData[] = insertValuesForAttributes(vessel, rightBoxAttributes(vessel));

  return (
    <OuterFlexBox>
      <BoxHalf>
        <StyledCard>
          <VesselItemHeader vessel={vessel} images={vessel.images} showDetailLink={showDetailLink} />
          <FlexBox>
            <BoxHalf>
              {leftColumnAttributeData.map((attributeData, index) => (
                <TemplateAttribute key={index} attributeData={attributeData} />
              ))}
            </BoxHalf>
            <BoxHalf>
              {rightColumnAtributeData.map((attributeData, index) => (
                <TemplateAttribute key={index} attributeData={attributeData} />
              ))}
              {vessel.comment && (
                <>
                  <br />
                  <Attribute label="Note / Comment" value={vessel.comment} tab="xl" />
                </>
              )}
            </BoxHalf>
          </FlexBox>
        </StyledCard>
      </BoxHalf>
      <BoxHalf>
        <StyledCard>
          <VesselLocationSection vessel={vessel} />
        </StyledCard>
      </BoxHalf>
    </OuterFlexBox>
  );
};

export const TemplateAttribute: FC<{attributeData: AttributeData}> = ({attributeData}) => {
  const {label, value, props, tab} = attributeData;
  return <Attribute label={label} value={value} tab={tab || 'xl'} {...props} />;
};

const FlexBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
`;

const OuterFlexBox = styled(FlexBox)`
  margin-bottom: 20px;
`;

const BoxHalf = styled.div`
  width: calc(50% - 10px);
  height: auto;
  align-self: stretch;
`;

const StyledCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    padding: 10px 20px;
    height: 100%;
  }
`;
