const Timezones = [
  {
    value: 'Pacific/Samoa',
    label: 'American Samoa, Midway',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'Hawaii',
  },
  {
    value: 'America/Anchorage',
    label: 'Alaska',
  },
  {
    value: 'America/Los_Angeles',
    label: 'Pacific Time, Tijuana',
  },
  {
    value: 'America/Phoenix',
    label: 'Phoenix',
  },
  {
    value: 'America/Chihuahua',
    label: 'Chihuahua, La Paz, Mazatlán',
  },
  {
    value: 'America/Denver',
    label: 'Mountain Time',
  },
  {
    value: 'America/Chicago',
    label: 'Central Time',
  },
  {
    value: 'America/Mexico_City',
    label: 'Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'America/Tegucigalpa',
    label: 'Middle America',
  },
  {
    value: 'America/Regina',
    label: 'Saskatchewan',
  },
  {
    value: 'America/Bogota',
    label: 'Bogotá, Lima, Quito',
  },
  {
    value: 'America/New_York',
    label: 'Eastern Time',
  },
  {
    value: 'America/Indiana/Indianapolis',
    label: 'Indianapolis',
  },
  {
    value: 'America/Rio_Branco',
    label: 'Rio Branco',
  },
  {
    value: 'America/Caracas',
    label: 'Caracas',
  },
  {
    value: 'America/Asuncion',
    label: 'Asunción',
  },
  {
    value: 'America/Halifax',
    label: 'Halifax, Nova Scotia',
  },
  {
    value: 'America/Cuiaba',
    label: 'Cuiabá',
  },
  {
    value: 'America/La_Paz',
    label: 'Georgetown, La Paz, Manaus, San Juan',
  },
  {
    value: 'America/Santiago',
    label: 'Santiago',
  },
  {
    value: 'America/St_Johns',
    label: 'Newfoundland',
  },
  {
    value: 'America/Sao_Paulo',
    label: 'Brasília',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'Buenos Aires',
  },
  {
    value: 'America/Cayenne',
    label: 'Cayenne, Fortaleza',
  },
  {
    value: 'America/Godthab',
    label: 'Greenland',
  },
  {
    value: 'America/Montevideo',
    label: 'Montevideo',
  },
  {
    value: 'Atlantic/South_Georgia',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    value: 'Atlantic/Azores',
    label: 'Azores',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: 'Cape Verde',
  },
  {
    value: 'Africa/Casablanca',
    label: 'Casablanca',
  },
  {
    value: 'UTC',
    label: 'UTC',
  },
  {
    value: 'Europe/London',
    label: 'Dublin, Edinburgh, Lisbon, London',
  },
  {
    value: 'Africa/Monrovia',
    label: 'Monrovia, Reykjavík',
  },
  {
    value: 'Europe/Berlin',
    label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Europe/Belgrade',
    label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Europe/Paris',
    label: 'Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Sarajevo',
    label: 'Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Africa/Algiers',
    label: 'West-Central Africa',
  },
  {
    value: 'Africa/Windhoek',
    label: 'Windhoek',
  },
  {
    value: 'Europe/Athens',
    label: 'Athens, Bucharest, Istanbul',
  },
  {
    value: 'Asia/Beirut',
    label: 'Beirut',
  },
  {
    value: 'Asia/Damascus',
    label: 'Damascus',
  },
  {
    value: 'Africa/Harare',
    label: 'Harare, Pretoria',
  },
  {
    value: 'Europe/Helsinki',
    label: 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Asia/Jerusalem',
    label: 'Jerusalem',
  },
  {
    value: 'Africa/Cairo',
    label: 'Cairo',
  },
  {
    value: 'Europe/Kaliningrad',
    label: 'Kaliningrad',
  },
  {
    value: 'Asia/Amman',
    label: 'Amman',
  },
  {
    value: 'Asia/Baghdad',
    label: 'Baghdad',
  },
  {
    value: 'Europe/Minsk',
    label: 'Minsk',
  },
  {
    value: 'Europe/Moscow',
    label: 'Moscow, Saint Petersburg, Volgograd',
  },
  {
    value: 'Asia/Kuwait',
    label: 'Kuwait, Riyadh',
  },
  {
    value: 'Africa/Nairobi',
    label: 'Nairobi',
  },
  {
    value: 'Asia/Tehran',
    label: 'Tehran',
  },
  {
    value: 'Asia/Muscat',
    label: 'Abu Dhabi, Muscat',
  },
  {
    value: 'Asia/Baku',
    label: 'Baku',
  },
  {
    value: 'Asia/Yerevan',
    label: 'Yerevan',
  },
  {
    value: 'Indian/Mauritius',
    label: 'Port Louis',
  },
  {
    value: 'Asia/Tbilisi',
    label: 'Tbilisi',
  },
  {
    value: 'Asia/Kabul',
    label: 'Kabul',
  },
  {
    value: 'Asia/Karachi',
    label: 'Islamabad, Karachi',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: 'Yekaterinburg',
  },
  {
    value: 'Asia/Tashkent',
    label: 'Tashkent',
  },
  {
    value: 'Asia/Kolkata',
    label: 'Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Colombo',
    label: 'Sri Jayawardenapura',
  },
  {
    value: 'Asia/Katmandu',
    label: 'Kathmandu',
  },
  {
    value: 'Asia/Almaty',
    label: 'Astana',
  },
  {
    value: 'Asia/Dhaka',
    label: 'Dhaka',
  },
  {
    value: 'Asia/Novosibirsk',
    label: 'Novosibirsk',
  },
  {
    value: 'Asia/Rangoon',
    label: 'Yangon (Rangoon)',
  },
  {
    value: 'Asia/Bangkok',
    label: 'Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: 'Krasnoyarsk',
  },
  {
    value: 'Asia/Irkutsk',
    label: 'Irkutsk',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: 'Kuala Lumpur, Singapore',
  },
  {
    value: 'Asia/Chongqing',
    label: 'Beijing, Chongqing, Hong Kong, Ürümqi',
  },
  {
    value: 'Australia/Perth',
    label: 'Perth',
  },
  {
    value: 'Asia/Taipei',
    label: 'Taipei',
  },
  {
    value: 'Asia/Ulaanbaatar',
    label: 'Ulan Bator',
  },
  {
    value: 'Asia/Yakutsk',
    label: 'Yakutsk',
  },
  {
    value: 'Asia/Tokyo',
    label: 'Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Asia/Seoul',
    label: 'Seoul',
  },
  {
    value: 'Australia/Adelaide',
    label: 'Adelaide',
  },
  {
    value: 'Australia/Darwin',
    label: 'Darwin',
  },
  {
    value: 'Australia/Brisbane',
    label: 'Brisbane',
  },
  {
    value: 'Australia/Sydney',
    label: 'Canberra, Melbourne, Sydney',
  },
  {
    value: 'Pacific/Guam',
    label: 'Guam, Port Moresby',
  },
  {
    value: 'Australia/Hobart',
    label: 'Hobart',
  },
  {
    value: 'Asia/Vladivostok',
    label: 'Magadan, Vladivostok',
  },
  {
    value: 'Pacific/Noumea',
    label: 'Solomon Islands, New Caledonia',
  },
  {
    value: 'Pacific/Auckland',
    label: 'Auckland, Wellington',
  },
  {
    value: 'Pacific/Fiji',
    label: 'Fiji',
  },
  {
    value: 'Pacific/Tongatapu',
    label: "Nuku'alofa",
  },
  {
    value: 'Pacific/Apia',
    label: 'Samoa',
  },
];

export default Timezones;
