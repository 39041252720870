import {Marker} from 'react-map-gl';
import styled from 'styled-components';
import {CoordinatesObject} from '../../../../utils/Coordinates';

type PulsatingCirclesLayerProps = {
  points: Array<CoordinatesObject>;
  maxSize?: number;
  color: string | [number, number, number];
};

interface CircleProps {
  className?: string;
}

const Circle = (props: CircleProps) => {
  return <div {...props} />;
};

interface StyledCircleItem {
  color: string | [number, number, number];
  $maxSize: number;
}

const StyledCircle = styled(Circle)<StyledCircleItem>`
  background-color: ${props => props.color};
  animation-name: pulsatingCircleAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  @keyframes pulsatingCircleAnimation {
    from {
      width: 0px;
      height: 0px;
      position: initial;
      opacity: 1;
      top: 0px;
      left: 0px;
      border-radius: 0px;
    }
    to {
      height: ${(props: StyledCircleItem) => props.$maxSize}px;
      border-radius: ${(props: StyledCircleItem) => props.$maxSize / 2}px;
      opacity: 0;
      width: ${(props: StyledCircleItem) => props.$maxSize}px;
      position: initial;
      top: ${(props: StyledCircleItem) => -(props.$maxSize / 2)}px;
      left: ${(props: StyledCircleItem) => -(props.$maxSize / 2)}px;
    }
  }
`;

export const PulsatingCirclesLayer = (props: PulsatingCirclesLayerProps) => {
  const maxSize = props.maxSize ?? 100;
  return (
    <>
      {props.points.map((coords, index) => {
        return (
          <Marker key={index} latitude={coords.lat} longitude={coords.lon}>
            <StyledCircle $maxSize={maxSize} color={props.color}></StyledCircle>
          </Marker>
        );
      })}
    </>
  );
};

export default PulsatingCirclesLayer;
