import NumberRangeInput from '../../../../RangeInput/NumberRangeInput';
import {hasError} from '../../../../../utils/helper';
import {ArrowIndicator} from '../../../../../atoms/Select/Indicators';
import {PeriodOptionUnits, PeriodRateCurrencyOptions, PeriodRateTermOptions} from './FixtureOptionsDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './FixtureOptionsDefinition';
import Select from '../../../../../atoms/Select/Select';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const FixtureOptions = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <div className="container">
        <div className="row">
          <div className="scol-12">
            <NumberRangeInput
              flex
              label={'Duration'}
              id={'filter-fixtures-options-period-range'}
              fromValue={values.periodFrom}
              onFromChange={value => handleChange('periodFrom', value)}
              toValue={values.periodTo}
              onToChange={value => handleChange('periodTo', value)}
              allowEqualMinMax
              fromProps={{
                min: 0,
                max: 99999,
                placeholder: 'min',
                hasError: hasError('periodFrom', validations),
              }}
              toProps={{
                min: 0,
                max: 99999,
                placeholder: 'max',
                hasError: hasError('periodTo', validations),
              }}
            />
            <Select
              id={'filter-fixtures-options-period-range-unit'}
              value={values.periodUnit}
              defaultValue={values.periodUnit}
              onChange={unit => {
                handleChange('periodUnit', unit);
              }}
              options={PeriodOptionUnits}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.value}
              components={{
                DropdownIndicator: ArrowIndicator,
              }}
              isSearchable={false}
            />
          </div>
        </div>
        <div className="row">
          <div className="scol-12">
            <NumberRangeInput
              flex
              label={'Rate'}
              id={'filter-fixtures-options-period-rate'}
              spMT={'m'}
              fromValue={values.rateFrom}
              onFromChange={value => handleChange('rateFrom', value)}
              toValue={values.rateTo}
              onToChange={value => handleChange('rateTo', value)}
              allowEqualMinMax
              fromProps={{
                min: 0,
                max: 99999,
                placeholder: 'min',
                hasError: hasError('rateFrom', validations),
              }}
              toProps={{
                min: 0,
                max: 99999,
                placeholder: 'max',
                hasError: hasError('rateTo', validations),
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="scol-6">
            <Select
              id={'filter-fixtures-options-period-rate-currency'}
              value={values.rateCurrency}
              defaultValue={values.rateCurrency}
              onChange={value => {
                handleChange('rateCurrency', value);
              }}
              options={PeriodRateCurrencyOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.value}
              components={{
                DropdownIndicator: ArrowIndicator,
              }}
              isClearable={true}
              isSearchable={false}
            />
          </div>
          <div className="scol-6">
            <Select
              id={'filter-fixtures-options-period-rate-terms'}
              value={values.rateTerms}
              defaultValue={values.rateTerms}
              onChange={value => {
                handleChange('rateTerms', value);
              }}
              options={PeriodRateTermOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.value}
              components={{
                DropdownIndicator: ArrowIndicator,
              }}
              isClearable={true}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
    )}
  </FilterButton>
);

export default FixtureOptions;
