import React, {FC} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {FormValues} from '../FormValues';
import {FormInstance} from 'antd';

const MAX_COMMENT_LENGTH = 256;

export const CommentSection: FC<{form: FormInstance<FormValues>}> = ({form}) => {
  const remark = Form.useWatch<FormValues['remark']>('remark', form) ?? '';
  const commentCharactersRemaining = MAX_COMMENT_LENGTH - remark.length;

  return (
    <div data-cy="CommentSection">
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label="Source" name="source" data-cy="source">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Comment"
            extra={`${commentCharactersRemaining}/${MAX_COMMENT_LENGTH} characters remaining`}
            data-cy="comment">
            <Form.Item
              name="remark"
              noStyle
              rules={[{max: MAX_COMMENT_LENGTH, message: 'Maximum length is 256 characters'}]}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
