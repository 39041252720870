export type FilterId =
  | 'allVessels'
  | 'updatedAt'
  | 'etaUpdated'
  | 'vesselType'
  | 'vesselSize'
  | 'destination'
  | 'beelineDistance'
  | 'etaBeforeLaycan'
  | 'arrivesNotTooEarly'
  | 'arrivesInTime';

export const filterOptions: {id: FilterId; borderColor: string}[] = [
  {id: 'allVessels', borderColor: '#DB4453'},
  {id: 'updatedAt', borderColor: '#E9573E'},
  {id: 'etaUpdated', borderColor: '#F3BB48'},
  {id: 'vesselType', borderColor: '#8CC051'},
  {id: 'vesselSize', borderColor: '#36BC9B'},
  {id: 'destination', borderColor: '#8784F3'},
  {id: 'beelineDistance', borderColor: '#4B89DB'},
  {id: 'etaBeforeLaycan', borderColor: '#967BDC'},
  {id: 'arrivesInTime', borderColor: '#D870AD'},
  {id: 'arrivesNotTooEarly', borderColor: '#aba1a7'},
];
