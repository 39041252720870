import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {Location} from '../utils/Location';

export type StationType = 'loaddelivery' | 'deliveryvia' | 'dischargeredelivery';

const StationTypes: StationType[] = ['loaddelivery', 'deliveryvia', 'dischargeredelivery'];

export interface Station {
  type: StationType;
  locations: Location[];
}

export interface DatabaseType {
  stations: Station[];
}

export interface InternalType {
  stations: Station[];
  loaddelivery: Location[];
  deliveryvia: Location[];
  dischargeredelivery: Location[];
}

export const Defaults: InternalType = {
  stations: [],
  loaddelivery: [],
  deliveryvia: [],
  dischargeredelivery: [],
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const internal: InternalType = {...Defaults};
  for (const station of database.stations) {
    internal[station.type] = station.locations;
  }
  return internal;
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const database: DatabaseType = {
    stations: [],
  };
  for (const key of StationTypes) {
    const locations = internal[key];
    if (locations.length === 0) {
      continue;
    }
    const station: Station = {
      type: key,
      locations,
    };
    database.stations.push(station);
  }
  return database;
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Location',
  branch: 'station',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
