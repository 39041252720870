import {CSSProperties, useState} from 'react';
import {Input, InputProps} from 'antd';

type Props = {
  value: number;
  size?: InputProps['size'];
  style?: CSSProperties;
  min?: number;
  onChange: (value: number) => void;
  closeOnBlur: boolean;
  closeOnEnter: boolean;
  onClose: () => void;
};

export const InputEditor = ({closeOnEnter, closeOnBlur, size, style, min, onClose, onChange, value}: Props) => {
  const [tempValue, setTempValue] = useState(value.toString() ?? '');

  const onUpdate = () => {
    const stringValue = tempValue.replace(',', '.');
    const numberValue = parseFloat(stringValue);
    if (isNaN(numberValue)) {
      return;
    }
    if (min !== undefined && min > numberValue) {
      return;
    }
    onChange(numberValue);
  };

  return (
    <Input
      autoFocus={true}
      onClick={event => event.stopPropagation()}
      size={size}
      style={style}
      onBlur={() => {
        onUpdate();
        if (!closeOnBlur) {
          return;
        }
        onClose();
      }}
      onKeyDown={event => {
        if (closeOnEnter && event.key === 'Enter') {
          onUpdate();
          onClose();
        }
      }}
      value={tempValue}
      onChange={event => {
        setTempValue(event.currentTarget.value);
      }}
    />
  );
};
