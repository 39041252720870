import {ReactNode} from 'react';
import {SubscriptionPlan, SubscriptionPlans} from '../../../../redux/ApiService/CompanyService/Company';
import {BadgeBlue, BadgeGrey} from '../PlanBadge';

export const planFeatures: ({title: string; badge?: ReactNode} & Record<SubscriptionPlan, boolean | string>)[] = [
  {
    title: 'Dashboard',
    [SubscriptionPlans.Free]: 'Light',
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Market',
    [SubscriptionPlans.Free]: 'Light',
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Vessel Database',
    [SubscriptionPlans.Free]: 'Light',
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Port Database',
    [SubscriptionPlans.Free]: 'Light',
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Fixtures',
    [SubscriptionPlans.Free]: true,
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'My Fleet',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'My Cargoes',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Projects',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Distance Calculator',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Voyage Calculator',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Circular Statistics',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: true,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Congestion Reports',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: false,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Competitor Analysis',
    [SubscriptionPlans.Standard]: false,
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Spotifind',
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: false,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'Negotiations',
    badge: <BadgeBlue $isSmall>NEW</BadgeBlue>,
    [SubscriptionPlans.Standard]: false,
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Pro]: true,
  },
  {
    title: 'seabo Lab',
    badge: <BadgeGrey $isSmall>BETA</BadgeGrey>,
    [SubscriptionPlans.Free]: false,
    [SubscriptionPlans.Standard]: false,
    [SubscriptionPlans.Pro]: true,
  },
];
