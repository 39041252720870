import {Checkbox, Col, Form, Row} from 'antd';
import {OptionsRow} from './OptionsRow/OptionsRow';
import {InputNumber} from '../../../../../../antd/components/InputNumber';
import {BrokerPicker} from './BrokerPicker';
import {numberBetween} from '../../../../../../utils/AntDValidator';
import {DefaultFormValues} from '../../DefaultFormValues';

export const OptionsSection = () => {
  return (
    <div data-cy="OptionsSection">
      <Form.List name={'options'}>
        {(fields, {add, remove}) => {
          return (
            <OptionsRow
              onAdd={() =>
                add({
                  ...DefaultFormValues.options[0],
                })
              }
              fields={fields}
              onRemove={remove}
            />
          );
        }}
      </Form.List>

      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Form.Item
            name={'commissionTotal'}
            label={'Commission (%)'}
            rules={[{type: 'number'}, numberBetween('Commission', 0, 99.99)]}>
            <InputNumber precision={2} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'broker'} label={'Broker'}>
            <BrokerPicker />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'redeliveryNotice'}
            label={'Redel. notice (in days)'}
            rules={[{type: 'number'}, numberBetween('Redel. notice', 0, 999)]}>
            <InputNumber
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Form.Item name={'isExtension'} valuePropName="checked" data-cy="extension">
            <Checkbox>Extension</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'isOptionDeclared'} valuePropName="checked" data-cy="optionDeclared">
            <Checkbox>Option declared</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
