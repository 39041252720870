import {FeatureCollection} from 'geojson';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LoadingAnimation from '../../../atoms/Loading';
import {Coordinates} from '../../../utils/Coordinates';
import {MapCardMap} from './MapCardMap';
import {MergedVisits} from '../ChokepointCongestionScreen/utils/useCongestionVisitsWithVesselInformationsQuery';
import {MergedPortCongestionVisit} from '../PortCongestionScreen/utils/useCongestionVisitsWithVesselInformationsQuery';
import {RGBAColor} from '../../../utils/deck.gl/deck.gl-types';
import {MapStyle} from '../../../components/SeaboMap/MapContext/Types';

export const MapCard: FC<{
  url?: string;
  currentVisits?: (MergedVisits | MergedPortCongestionVisit)[];
  polygon?: FeatureCollection;
  polygonColor?: RGBAColor;
  centerPoint?: Coordinates;
  zoom?: number;
  isLoading?: boolean;
  testId?: string;
  mapStyle?: MapStyle;
  children: React.ReactNode;
}> = ({url, currentVisits, mapStyle, polygon, polygonColor, centerPoint, zoom, isLoading, testId, children}) => (
  <TileContainer data-testid={testId}>
    <MapContainer>
      <MapCardMap
        staticMapBoxLayer={true}
        vessels={currentVisits ?? []}
        mapStyle={mapStyle}
        polygon={polygon}
        polygonColor={polygonColor}
        centerPoint={centerPoint}
        zoom={zoom}
      />
      {isLoading && <StyledLoadingAnimation />}
    </MapContainer>
    <Link to={url ?? '#'}>
      <ContentContainer>{children}</ContentContainer>
    </Link>
  </TileContainer>
);

const TileContainer = styled.div`
  position: relative;
  width: calc(50% - 5px);
  min-height: 400px;
  background: rgba(1, 30, 54, 1);
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-card);
  overflow: hidden;
`;

const MapContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
`;

const ContentContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.2);
  color: var(--color-white);
  text-align: start;
  z-index: 1;
`;

const StyledLoadingAnimation = styled(LoadingAnimation)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
`;
