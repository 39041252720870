import {DiffEdit} from 'deep-diff';
import {PortfolioVessel} from '../../../../redux/Portfolio';
import {Form, Radio, Space} from 'antd';
import startCase from 'lodash/startCase';
import {TODO} from '../../../../utils/TODO';

const getBooleanLabel = (value: boolean) => (value ? 'Yes' : 'No');

export const OneChangeDialog = ({
  diff,
  value,
  onChange,
}: {
  diff: DiffEdit<PortfolioVessel, PortfolioVessel>;
  onChange: (newValue: TODO) => void;
  value: TODO;
}) => {
  const left = diff.lhs;
  const leftLabel = typeof left === 'boolean' ? getBooleanLabel(left) : left;
  const right = diff.rhs;
  const rightLabel = typeof right === 'boolean' ? getBooleanLabel(right) : right;

  const label = diff.path?.join(' / ');

  const isArrayOrObject = (diff.path?.length ?? 0) > 1;
  return (
    <>
      <Form.Item label={startCase(label)}>
        <Radio.Group
          disabled={isArrayOrObject}
          data-testid={'oneChangeDialogRadio'}
          style={{paddingLeft: 32}}
          onChange={event => {
            onChange(event.target.value);
          }}
          value={value}>
          <Space direction="vertical">
            <Radio data-testid={'oneChangeDialogLeftOption'} value={left}>
              {leftLabel as string}
            </Radio>
            <Radio data-testid={'oneChangeDialogRightOption'} value={right}>
              {rightLabel as string}
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};
