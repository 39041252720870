import {FC} from 'react';
import {Select} from '../../../../../../antd/components/Select';
import {RootState} from '../../../../../../redux/store';
import {useSelector} from '../../../../../../redux/react-redux';

// Only for container vessels

export const DesignTypePicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  const preFillData = useSelector((state: RootState) => state.preFill.data);

  return (
    <Select
      options={preFillData.map(item => ({
        label: item.name,
        key: item.slug,
        value: item.name,
      }))}
      value={value}
      onChange={onChange}
    />
  );
};
