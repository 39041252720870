import {FC} from 'react';
import styled from 'styled-components';
import {StyledButton} from './AisFormItem';

export const ResetFormRow: FC<{onReset: () => void; disabled: boolean}> = ({onReset, disabled}) => {
  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <Label>Filters</Label>
      <StyledButton
        type={'link'}
        size={'small'}
        style={{padding: 0}}
        onClick={() => {
          if (disabled) {
            return;
          }
          onReset();
        }}>
        Clear all
      </StyledButton>
    </div>
  );
};

const Label = styled.label`
  color: var(--color-white);
  font-weight: 700;
  min-height: 20px;
  font-size: var(--font-size-md);
  font-family: var(--font-family-sans-serif);
`;
