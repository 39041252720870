import {TODO} from '../../utils/TODO';
import {makeApiService, ApiServiceEntry} from './ApiService';

interface Data {
  items: TODO[];
}

const entries: ApiServiceEntry[] = [
  {
    fn: 'search',
    route: '/api/users/contacts',
    method: 'GET',
  },
  {
    fn: 'getRequests',
    route: '/api/users/follow/requests',
    method: 'GET',
  },
  {
    fn: 'getContacts',
    route: '/api/contacts',
    method: 'GET',
    abortPrevCalls: true,
  },
  {
    fn: 'getContactsAutocomplete',
    route: '/api/contacts',
    method: 'GET',
    abortPrevCalls: true,
  },
  {
    fn: 'updateGroup',
    route: '/api/contacts/contact/{id}/groups',
    method: 'POST',
  },
  {
    fn: 'searchUser',
    route: '/api/contacts/searchuser',
    method: 'GET',
  },
  {
    fn: 'getFriendRecommendations',
    route: '/api/contacts/recommendations',
    method: 'GET',
  },
  {
    fn: 'getGroup',
    route: '/api/contacts/groups/{id}',
    method: 'GET',
  },
  {
    fn: 'getGroups',
    route: '/api/contacts/groups',
    method: 'GET',
  },
  {
    fn: 'addGroup',
    route: '/api/contacts/groups',
    method: 'POST',
  },
  {
    fn: 'editGroup',
    route: '/api/contacts/groups/{id}',
    method: 'PATCH',
  },
  {
    fn: 'deleteGroup',
    route: '/api/contacts/groups/{id}',
    method: 'DELETE',
  },
  {
    fn: 'removeUserFromGroup',
    route: '/api/contacts/groups/{groupId}/contacts/{userId}',
    method: 'DELETE',
  },
  {
    fn: 'addContactsToGroup',
    route: '/api/contacts/groups/{id}/contacts',
    method: 'POST',
  },
];

const contactsApi = makeApiService(entries, {apiName: 'contacts'});

export const reducers = contactsApi.reducers;
export const actions = contactsApi.actions;
export const actionTypes = contactsApi.actionTypes;

const itemTransformer = {transformers: {SUCCESS: ({data}: {data: Data}) => data.items}};

export const ContactsServiceActions = {
  search: (search: TODO) =>
    actions.search({
      queryParams: {search},
      transformers: {SUCCESS: ({data}: {data: Data}) => data.items.map(c => c.platformUser)},
    }),
  getRequests: () => actions.getRequests(itemTransformer),
  getFriendRecommendations: (pageSize: number) => actions.getFriendRecommendations({queryParams: pageSize}),
  getContacts: (type: TODO, pageIndex = 1, pageSize = 10, search = undefined, group = undefined) =>
    actions.getContacts({
      queryParams: {
        type,
        pageIndex,
        pageSize,
        search,
        group,
      },
    }),
  getContactsAutocomplete: (type: TODO, pageIndex = 1, pageSize = 10, search: TODO) =>
    actions.getContactsAutocomplete({
      queryParams: {
        type,
        pageIndex,
        pageSize,
        search,
      },
      transformers: {SUCCESS: ({data}: {data: Data}) => data.items},
    }),
  addGroup: (body: TODO) => actions.addGroup({body}),
  deleteGroup: (id: TODO) => actions.deleteGroup({params: {id}}),
  removeUserFromGroup: (groupId: TODO, userId: TODO) => actions.removeUserFromGroup({params: {groupId, userId}}),
  getGroups: (pageIndex = 1, pageSize = 10, search = undefined, group = undefined) =>
    actions.getGroups({
      queryParams: {
        pageIndex,
        pageSize,
        search,
        group,
      },
    }),
  getGroup: (id: TODO) => actions.getGroup({params: id}),
  addContactsToGroup: (id: TODO, body: TODO) => actions.addContactsToGroup({params: {id: id}, body}),
  editGroup: (id: TODO, body: TODO) => actions.editGroup({params: {id: id}, body}),
  updateGroup: (contactId: TODO, params: TODO) =>
    actions.updateGroup({
      params: {id: contactId},
      body: {params},
    }),
  searchUser: (search: TODO) =>
    actions.searchUser({
      queryParams: {pageSize: 5, search},
      transformers: {SUCCESS: ({data}: {data: TODO}) => data.items},
    }),
};

export default ContactsServiceActions;
