import React, {FC} from 'react';
import {Typography} from 'antd';
import {Center} from '../../components/Center/Center';
import styled from 'styled-components';

const {Title} = Typography;

/**
 * This components shows its children only on a desktop computer. Otherwise it displays only a warning.
 */
export const DesktopRequired: FC<{children: React.ReactNode}> = ({children}) => (
  <>
    <DesktopOnly>{children}</DesktopOnly>

    <MobileOnly>
      <Warning />
    </MobileOnly>
  </>
);

const DesktopOnly = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const Warning = () => (
  <Center style={{textAlign: 'center'}}>
    <Title level={1} style={{color: 'white'}}>
      Please use a desktop computer to login
    </Title>
  </Center>
);
