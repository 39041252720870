/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  PredictedConsumption,
  PredictedConsumptionFromJSON,
  PredictedConsumptionFromJSONTyped,
  PredictedConsumptionToJSON,
} from './';

/**
 *
 * @export
 * @interface PredictedConsumptionsResponse
 */
export interface PredictedConsumptionsResponse {
  /**
   *
   * @type {PredictedConsumption}
   * @memberof PredictedConsumptionsResponse
   */
  portIdle: PredictedConsumption | null;
  /**
   *
   * @type {PredictedConsumption}
   * @memberof PredictedConsumptionsResponse
   */
  portWorking: PredictedConsumption | null;
  /**
   *
   * @type {PredictedConsumption}
   * @memberof PredictedConsumptionsResponse
   */
  ballastSpeed8: PredictedConsumption | null;
  /**
   *
   * @type {PredictedConsumption}
   * @memberof PredictedConsumptionsResponse
   */
  ballastSpeed14: PredictedConsumption | null;
  /**
   *
   * @type {PredictedConsumption}
   * @memberof PredictedConsumptionsResponse
   */
  ladenSpeed8: PredictedConsumption | null;
  /**
   *
   * @type {PredictedConsumption}
   * @memberof PredictedConsumptionsResponse
   */
  ladenSpeed14: PredictedConsumption | null;
}

export function PredictedConsumptionsResponseFromJSON(json: any): PredictedConsumptionsResponse {
  return PredictedConsumptionsResponseFromJSONTyped(json, false);
}

export function PredictedConsumptionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PredictedConsumptionsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    portIdle: PredictedConsumptionFromJSON(json['portIdle']),
    portWorking: PredictedConsumptionFromJSON(json['portWorking']),
    ballastSpeed8: PredictedConsumptionFromJSON(json['ballastSpeed8']),
    ballastSpeed14: PredictedConsumptionFromJSON(json['ballastSpeed14']),
    ladenSpeed8: PredictedConsumptionFromJSON(json['ladenSpeed8']),
    ladenSpeed14: PredictedConsumptionFromJSON(json['ladenSpeed14']),
  };
}

export function PredictedConsumptionsResponseToJSON(value?: PredictedConsumptionsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    portIdle: PredictedConsumptionToJSON(value.portIdle),
    portWorking: PredictedConsumptionToJSON(value.portWorking),
    ballastSpeed8: PredictedConsumptionToJSON(value.ballastSpeed8),
    ballastSpeed14: PredictedConsumptionToJSON(value.ballastSpeed14),
    ladenSpeed8: PredictedConsumptionToJSON(value.ladenSpeed8),
    ladenSpeed14: PredictedConsumptionToJSON(value.ladenSpeed14),
  };
}
