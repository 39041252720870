import {QueryClient, useQuery} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import ProjectService from '../redux/ApiService/ProjectService';

export const useShortlistQuery = ({isVesselShortlist, id}: {isVesselShortlist: boolean; id: number}) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['shortlist', id],
    queryFn: async () => {
      const query = isVesselShortlist
        ? dispatch(ProjectService.getShortlistVessels(id))
        : dispatch(ProjectService.getShortlistCargoes(id));

      const result = await query;
      return result.data;
    },
    enabled: !!id,
  });
};

export const invalidateShortlistQueries = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: ['shortlist'],
  });
};
