import {NegotiationRoundResult, NegotiationRoundResultModification} from '../../../api/node-backend/generated';

type RoundResultUpdate = (roundResult: NegotiationRoundResult) => NegotiationRoundResult;
type RoundResultModificationUpdate = (
  roundResultModification: NegotiationRoundResultModification
) => NegotiationRoundResultModification;

/**
 * Updates a given immutable negotiation state, e.g. react-query or useState.
 *
 * @param setRoundResultState The setter function for the round result state
 * @param setRoundResultModificationState The setter function for the round result modification state
 * @returns An object with functions to update the state for a term or subject
 */
export const updateAbstractNegotiationState = ({
  setRoundResultState,
  setRoundResultModificationState,
}: {
  setRoundResultState: (updater?: (oldState?: NegotiationRoundResult) => NegotiationRoundResult | undefined) => void;
  setRoundResultModificationState: (
    updater?: (oldState?: NegotiationRoundResultModification) => NegotiationRoundResultModification | undefined
  ) => void;
}) => {
  return ({
    roundResultUpdate,
    roundResultModificationUpdate,
  }: {
    roundResultUpdate?: RoundResultUpdate;
    roundResultModificationUpdate?: RoundResultModificationUpdate;
  }) => {
    setRoundResultState(oldState => {
      if (!oldState || !roundResultUpdate) {
        return oldState;
      }
      return roundResultUpdate(oldState);
    });
    setRoundResultModificationState(oldState => {
      if (!oldState || !roundResultModificationUpdate) {
        return oldState;
      }
      return roundResultModificationUpdate(oldState);
    });
  };
};
