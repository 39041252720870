import {CenteredContentArea, FullscreenDialog} from '../../../atoms/FullscreenDialog/FullscreenDialog';
import {Center} from '../../../components/Center/Center';
import {ResetPasswordDialog} from './ResetPasswordDialog';

export const ResetPasswordScreen = () => {
  return (
    <div data-testid="ResetPasswordScreen">
      <FullscreenDialog>
        <CenteredContentArea>
          <Center>
            <ResetPasswordDialog />
          </Center>
        </CenteredContentArea>
      </FullscreenDialog>
    </div>
  );
};
