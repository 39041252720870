import {Col, DatePicker, Form, FormInstance, Input, InputNumber, Row} from 'antd';
import {FC} from 'react';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {LocationsPickerPopover} from '../../../../../components/LocationPicker/LocationsPickerPopover';
import {InputNumberAcceptCommaAndDot} from '../../../../../antd/components/InputNumberAcceptCommaAndDot';
import {SeaboVesselPicker} from '../../../../../components/SeaboVesselPicker/SeaboVesselPicker';
import {SeaboVessel} from '../../../../../components/SearchPicker/Type';

export const VesselContainerFormItems: FC<{form: FormInstance}> = ({form}) => {
  const onSelectVessel = (vessel: SeaboVessel) => {
    form.setFieldsValue({
      name: vessel.name,
      imo: vessel.imo,
    });
  };
  return (
    <Row gutter={[8, 8]} data-testid={'VesselContainerFormItems'}>
      <Col span={12}>
        <Form.Item label={'Shipname'} name={'name'}>
          <SeaboVesselPicker onSelect={onSelectVessel} indexNames={['ais_vessels']} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={'IMO'} name={'imo'}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item name={'TEU'} rules={[{min: 0, type: 'number', message: 'TEU cannot be less than 0.'}]} label={'TEU'}>
          <InputNumber style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={'LOA'}
          rules={[{min: 0, type: 'number', message: 'LOA cannot be less than 0.'}]}
          name={'lengthOverall'}>
          <InputNumberAcceptCommaAndDot style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={'Beam'}
          rules={[{min: 0, type: 'number', message: 'Beam cannot be less than 0.'}]}
          name={'beam'}>
          <InputNumberAcceptCommaAndDot style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={'Draft'}
          rules={[{min: 0, type: 'number', message: 'Draft cannot be less than 0.'}]}
          name={'draft'}>
          <InputNumberAcceptCommaAndDot style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={'Reefer'}
          rules={[{min: 0, type: 'number', message: 'Reefer plugs cannot be less than 0.'}]}
          name={'reeferPlugs'}>
          <InputNumber style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={'Open date'} name={'nextOpen'}>
          <DatePicker.RangePicker format={DateTimeFormat.Date} allowEmpty={[true, true]} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={'Open location'} name={'nextOpenLocations'} style={{marginBottom: 4}}>
          <LocationsPickerPopover
            onSelect={locations => {
              form.setFieldsValue({
                nextOpenLocations: locations,
              });
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
