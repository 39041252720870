import {makeFilterBranchDefinition} from '../../../FilterBranchDefinition';
import {floatBetween, floatBetweenAndGreaterOrEqual} from '../../../../../utils/validators';
import {convertNumberToString, convertToFloat} from '../../FilterConversion/utils/FilterConversionUtils';

export interface DatabaseType {
  speedMin?: number;
  speedMax?: number;
  draughtMin?: number;
  draughtMax?: number;
  draughtPercentMin?: number;
  draughtPercentMax?: number;
  draughtChangeMin?: number;
  draughtChangeMax?: number;
  draughtChangePercentMin?: number;
  draughtChangePercentMax?: number;
}

export interface InternalType {
  speedMin?: string;
  speedMax?: string;
  draughtMin?: string;
  draughtMax?: string;
  draughtPercentMin?: string;
  draughtPercentMax?: string;
  draughtChangeMin?: string;
  draughtChangeMax?: string;
  draughtChangePercentMin?: string;
  draughtChangePercentMax?: string;
}

export const Defaults: InternalType = {
  speedMin: '',
  speedMax: '',
  draughtMin: '',
  draughtMax: '',
  draughtPercentMin: '',
  draughtPercentMax: '',
  draughtChangeMin: '',
  draughtChangeMax: '',
  draughtChangePercentMin: '',
  draughtChangePercentMax: '',
};

export const fromDatabase = ({
  speedMin,
  speedMax,
  draughtMin,
  draughtMax,
  draughtPercentMin,
  draughtPercentMax,
  draughtChangeMin,
  draughtChangeMax,
  draughtChangePercentMin,
  draughtChangePercentMax,
}: DatabaseType): InternalType => ({
  speedMin: convertNumberToString(speedMin),
  speedMax: convertNumberToString(speedMax),
  draughtMin: convertNumberToString(draughtMin),
  draughtMax: convertNumberToString(draughtMax),
  draughtPercentMin: convertNumberToString(draughtPercentMin),
  draughtPercentMax: convertNumberToString(draughtPercentMax),
  draughtChangeMin: convertNumberToString(draughtChangeMin),
  draughtChangeMax: convertNumberToString(draughtChangeMax),
  draughtChangePercentMin: convertNumberToString(draughtChangePercentMin),
  draughtChangePercentMax: convertNumberToString(draughtChangePercentMax),
});

export const toDatabase = ({
  speedMin,
  speedMax,
  draughtMin,
  draughtMax,
  draughtPercentMin,
  draughtPercentMax,
  draughtChangeMin,
  draughtChangeMax,
  draughtChangePercentMin,
  draughtChangePercentMax,
}: InternalType): DatabaseType => ({
  speedMin: convertToFloat(speedMin),
  speedMax: convertToFloat(speedMax),
  draughtMin: convertToFloat(draughtMin),
  draughtMax: convertToFloat(draughtMax),
  draughtPercentMin: convertToFloat(draughtPercentMin),
  draughtPercentMax: convertToFloat(draughtPercentMax),
  draughtChangeMin: convertToFloat(draughtChangeMin),
  draughtChangeMax: convertToFloat(draughtChangeMax),
  draughtChangePercentMin: convertToFloat(draughtChangePercentMin),
  draughtChangePercentMax: convertToFloat(draughtChangePercentMax),
});

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Filter parameters',
  branch: 'filterParameters',
  defaults: Defaults,
  validators: {
    speedMin: floatBetween(0, 99.99),
    speedMax: floatBetweenAndGreaterOrEqual(0, 99.99, 'speedMin'),
    draughtMin: floatBetween(0, 99999999.99999),
    draughtMax: floatBetweenAndGreaterOrEqual(0, 99999999.99999, 'draughtMin'),
    draughtPercentMin: floatBetween(0, 100),
    draughtPercentMax: floatBetweenAndGreaterOrEqual(0, 100, 'draughtPercentMin'),
    draughtChangeMin: floatBetween(0, 99999999.99999),
    draughtChangeMax: floatBetweenAndGreaterOrEqual(0, 99999999.99999, 'draughtChangeMin'),
    draughtChangePercentMin: floatBetween(0, 100),
    draughtChangePercentMax: floatBetweenAndGreaterOrEqual(0, 100, 'draughtChangePercentMin'),
  },
  fromDatabase,
  toDatabase,
});
