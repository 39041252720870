import {FC} from 'react';
import * as Sentry from '@sentry/react';
import {Button, Result} from 'antd';
import {QueryErrorResetBoundary} from '@tanstack/react-query';

interface Props {
  location: string;
  children: React.ReactNode;
}

/**
 * An error boundary that will report the error to Sentry and allow the user to re-render the UI,
 * so that she can hopefully continue to work with the current state.
 */
export const ErrorBoundaryForProduction: FC<Props> = ({location, children}) => (
  <QueryErrorResetBoundary>
    {({reset}) => (
      <Sentry.ErrorBoundary
        beforeCapture={scope => {
          // Remember which error boundary caught the error.
          scope.setTag('location', location);
        }}
        fallback={({error, resetError}) => (
          <ErrorBoundaryBody
            error={error}
            onOK={() => {
              // React-Query
              reset();
              // Sentry
              resetError();
            }}
          />
        )}>
        {children}
      </Sentry.ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

const ErrorBoundaryBody: FC<{error: Error; onOK: () => void}> = ({error, onOK}) => (
  <Result
    status="500"
    title="Sorry, something went wrong."
    subTitle={
      <span>
        Our team has been notified of the problem.
        <br />
        <br />
        {error.message}
        <br />
        at time: {new Date().toISOString()}
      </span>
    }
    extra={
      <Button type="primary" onClick={onOK}>
        OK
      </Button>
    }
  />
);
