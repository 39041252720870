import {CargoInput} from '../../utils/CargoTypes';
import {VoyageInput} from '../../VoyageInformation/VoyageTypes';

export const getIsCargoReady = ({cargo, voyage}: {cargo: CargoInput; voyage: VoyageInput}): boolean => {
  if (!voyage.points.some(leg => leg.types?.includes('loading'))) {
    return false;
  }
  if (!voyage.points.some(leg => leg.types?.includes('discharge'))) {
    return false;
  }
  if (!cargo.cargoQuantity) {
    return false;
  }

  return true;
};

export const getIsCargoImported = ({cargo, voyage}: {cargo: CargoInput; voyage: VoyageInput}): boolean => {
  if (voyage.points.some(leg => leg.types?.includes('loading'))) {
    return true;
  }
  if (voyage.points.some(leg => leg.types?.includes('discharge'))) {
    return true;
  }
  if (cargo.cargoQuantity) {
    return true;
  }
  if (cargo.chartererName) {
    return true;
  }
  if (cargo.cargoProduct) {
    return true;
  }

  return false;
};
