import {CargoPicker} from './CargoPicker';
import {Cargo} from '../SearchPicker/Type';
import {CargoContractType, CargoHexagon} from '../../atoms/CargoHexagon/CargoHexagon';
import {CargoPickerIndexNames} from '../SearchPicker/useOptionsFromCargoSearch';
import {ContractType} from '../../api/symfony/generated';
import {BlindInputFieldToReset} from '../../atoms/BlindInputFieldToReset/BlindInputFieldToReset';
import {SizeType} from 'antd/es/config-provider/SizeContext';

export interface CargoPickerWithHexagonCargoProp {
  contractType?: CargoContractType;
  name?: string;
}

type Props = {
  cargo?: CargoPickerWithHexagonCargoProp | undefined;
  indexNames?: CargoPickerIndexNames;
  onChange?: (cargo: Cargo | undefined) => void;
  size?: SizeType;
  paramContractType?: ContractType;
};

export const CargoPickerWithHexagon = (props: Props) => {
  const {cargo} = props;
  if (cargo) {
    return (
      <BlindInputFieldToReset
        dataTestIdPrefix={'CargoPicker'}
        prefix={<CargoHexagon type={cargo?.contractType} />}
        onReset={() => {
          props.onChange?.(undefined);
        }}
        size={props.size}
        value={cargo.name ?? ''}
      />
    );
  }

  return (
    <CargoPicker
      paramContractType={props.paramContractType}
      size={props.size}
      prefix={<span style={{width: 17}}></span>}
      defaultValue={''}
      indexNames={props.indexNames}
      onSelect={cargo => {
        props.onChange && props.onChange(cargo);
      }}
    />
  );
};
