/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {NegotiationAttachment, NegotiationAttachmentFromJSON, NegotiationAttachmentToJSON} from '../models';

export interface DeleteAttachmentRequest {
  negotiationId: string;
  attachmentId: string;
}

export interface DownloadAttachmentRequest {
  negotiationId: string;
  attachmentId: string;
}

export interface GetNegotiationAttachmentsRequest {
  negotiationId: string;
}

export interface NewAttachmentRequest {
  negotiationId: string;
  file: Blob;
}

/**
 *
 */
export class NegotiationNegotiationAttachmentApi extends runtime.BaseAPI {
  /**
   * Deletes a negotiation attachment.
   */
  async deleteAttachmentRaw(requestParameters: DeleteAttachmentRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling deleteAttachment.'
      );
    }

    if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
      throw new runtime.RequiredError(
        'attachmentId',
        'Required parameter requestParameters.attachmentId was null or undefined when calling deleteAttachment.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/{negotiationId}/attachments/{attachmentId}`
        .replace(`{${'negotiationId'}}`, encodeURIComponent(String(requestParameters.negotiationId)))
        .replace(`{${'attachmentId'}}`, encodeURIComponent(String(requestParameters.attachmentId))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes a negotiation attachment.
   */
  async deleteAttachment(requestParameters: DeleteAttachmentRequest): Promise<void> {
    await this.deleteAttachmentRaw(requestParameters);
  }

  /**
   * Downloadstream for a negotiation attachment.
   */
  async downloadAttachmentRaw(requestParameters: DownloadAttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling downloadAttachment.'
      );
    }

    if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
      throw new runtime.RequiredError(
        'attachmentId',
        'Required parameter requestParameters.attachmentId was null or undefined when calling downloadAttachment.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/{negotiationId}/attachments/{attachmentId}`
        .replace(`{${'negotiationId'}}`, encodeURIComponent(String(requestParameters.negotiationId)))
        .replace(`{${'attachmentId'}}`, encodeURIComponent(String(requestParameters.attachmentId))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Downloadstream for a negotiation attachment.
   */
  async downloadAttachment(requestParameters: DownloadAttachmentRequest): Promise<Blob> {
    const response = await this.downloadAttachmentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns all attachments for a negotiation.
   */
  async getNegotiationAttachmentsRaw(
    requestParameters: GetNegotiationAttachmentsRequest
  ): Promise<runtime.ApiResponse<Array<NegotiationAttachment>>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling getNegotiationAttachments.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/{negotiationId}/attachments`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(NegotiationAttachmentFromJSON));
  }

  /**
   * Returns all attachments for a negotiation.
   */
  async getNegotiationAttachments(
    requestParameters: GetNegotiationAttachmentsRequest
  ): Promise<Array<NegotiationAttachment>> {
    const response = await this.getNegotiationAttachmentsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Uploads a new attachment to a negotiation.
   */
  async newAttachmentRaw(requestParameters: NewAttachmentRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.negotiationId === null || requestParameters.negotiationId === undefined) {
      throw new runtime.RequiredError(
        'negotiationId',
        'Required parameter requestParameters.negotiationId was null or undefined when calling newAttachment.'
      );
    }

    if (requestParameters.file === null || requestParameters.file === undefined) {
      throw new runtime.RequiredError(
        'file',
        'Required parameter requestParameters.file was null or undefined when calling newAttachment.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{contentType: 'multipart/form-data'}];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: {append(param: string, value: any): any};
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request({
      path: `/node-backend/negotiations/{negotiationId}/attachments`.replace(
        `{${'negotiationId'}}`,
        encodeURIComponent(String(requestParameters.negotiationId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Uploads a new attachment to a negotiation.
   */
  async newAttachment(requestParameters: NewAttachmentRequest): Promise<void> {
    await this.newAttachmentRaw(requestParameters);
  }
}
