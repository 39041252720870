import BindToProvider, {WrappedDetailsSection} from '../../../../components/FormProvider/BindToProvider';
import {vesselTypes} from '../../helper';
import {isImo, numberBetween, required, stringLengthLessThanOrEqualTo} from '../../../../utils/validators';
import Country from '../../../../model/Country';
import {TODO} from '../../../../utils/TODO';

export const Wrapper = BindToProvider(
  'General',
  'general',
  {
    id: '',
    vesselType: Object.keys(vesselTypes)[0],
    designType: '',
    designSubType: '',
    name: '',
    charterName: '',
    imo: '',
    builtYear: '',
    countryFlag: null,
    shipBuilder: '',
    status: 'active',
  },
  {
    designType: stringLengthLessThanOrEqualTo(191),
    name: required(),
    vesselType: required(),
    charterName: stringLengthLessThanOrEqualTo(191),
    imo: isImo(),
    builtYear: numberBetween(1950, 2050),
    shipBuilder: stringLengthLessThanOrEqualTo(127),
  },
  {
    toApi: (fields: TODO) => ({
      ...fields,
      imo: parseInt(fields.imo),
      builtYear: fields.builtYear && parseInt(fields.builtYear),
      countryFlag: fields.countryFlag && fields.countryFlag.code,
      designType: fields.designType && fields.designType.label,
      vesselType: fields.vesselType !== 'other' ? fields.vesselType : null,
    }),
    fromApi: (fields: TODO) => ({
      ...fields,
      vesselType: fields.vesselType ? fields.vesselType : 'other',
      designType: fields.designType ? {label: fields.designType, value: fields.designType} : '',
      countryFlag: fields.countryFlag
        ? {code: fields.countryFlag, name: Country.getName(fields.countryFlag), type: 'country'}
        : null,
    }),
  },
  true
)(WrappedDetailsSection);
