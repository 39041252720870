import numeral from 'numeral';
import {ResultVessel} from '../types';
import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';

export const distanceDWTColumn: CustomColumnDef<ResultVessel> = {
  header: () => (
    <div className="datagrid-sortable-header" data-cy="header-shipname">
      <div>Distance</div>
    </div>
  ),
  id: 'distanceSeaVoyageInNm',
  accessorFn: v => <span>{numeral(v.distanceSeaVoyageInNm).format('0,0')} nm</span>,
  cell: info => info.getValue(),
  minWidth: 12,
  enableSorting: true,
};
