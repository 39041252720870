import {useQuery} from '@tanstack/react-query';
import {LonLatCoordinates} from '../../utils/Coordinates';
import {fetchJSON} from '../../api/utils/fetchJSON';
import {TODO} from '../../utils/TODO';
import {useImoToAisVesselMap} from '../../queries/useImoToAisVesselMap';
import {UseQueryOptions} from '../../api/utils/ReactQuery';

export interface SpotifindVessel {
  imo: number;
  coordinates: LonLatCoordinates;
  course: number;
  heading: number;
  eta: string | null;
  postime: string | null;
}

const key = 'GetSpotVessels';

export const useGetSpotifindVesselsQuery = ({
  options,
  filter,
}: {
  filter: TODO;
  options?: UseQueryOptions<SpotifindVessel[]>;
}) => {
  const aisVesselMap = useImoToAisVesselMap({
    etaMaxAge: '90d',
    format: 'extended',
  });

  return useQuery({
    queryKey: [key, filter],
    queryFn: async () => {
      const filterJsonString = filter ? JSON.stringify(filter) : undefined;
      const uri = `/api/spotifind/vessels?filters=${encodeURI(filterJsonString!)}`;
      const json: {data: {items: SpotifindVessel[]}} = await fetchJSON(uri);
      const vessels = json.data.items;
      const vesselsWithHeading = vessels.map((vesselFromSpotifindApi: SpotifindVessel) => {
        const vesselInAisVesselMap = aisVesselMap.imoToAisVesselMap?.[vesselFromSpotifindApi.imo];
        const etaInApiResult = vesselFromSpotifindApi.eta;
        const extendedVessel: SpotifindVessel = {
          ...vesselFromSpotifindApi,
          heading: vesselFromSpotifindApi.course,
        };

        /**
         * TODO: Remove the merge logic below when the spotifind API is updated.
         * The spotifind API does not return the ETA and postime.
         * Until the API is updated, we can use the AIS vessel map to get the ETA and postime.
         */
        if (!etaInApiResult) {
          const etaFromAisVessel = vesselInAisVesselMap?.eta?.toISOString() ?? null;
          extendedVessel.eta = etaFromAisVessel;
        }

        if (!extendedVessel.postime) {
          const updatedAtFromAisVessel = vesselInAisVesselMap?.updatedAt?.toISOString() ?? null;
          extendedVessel.postime = updatedAtFromAisVessel;
        }
        return extendedVessel;
      });
      return vesselsWithHeading;
    },

    refetchOnMount: false,
    ...options,
    enabled: !aisVesselMap.isLoading && (options?.enabled ?? true),
  });
};
