/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AccountSegments
 */
export interface AccountSegments {
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSegments
   */
  types: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSegments
   */
  commodities: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSegments
   */
  sizes: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSegments
   */
  tradingAreas: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSegments
   */
  userSelfCategorization: Array<string>;
}

export function AccountSegmentsFromJSON(json: any): AccountSegments {
  return AccountSegmentsFromJSONTyped(json, false);
}

export function AccountSegmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountSegments {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    types: json['types'],
    commodities: json['commodities'],
    sizes: json['sizes'],
    tradingAreas: json['tradingAreas'],
    userSelfCategorization: json['userSelfCategorization'],
  };
}

export function AccountSegmentsToJSON(value?: AccountSegments | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    types: value.types,
    commodities: value.commodities,
    sizes: value.sizes,
    tradingAreas: value.tradingAreas,
    userSelfCategorization: value.userSelfCategorization,
  };
}
