import {FC, Fragment} from 'react';
import CompanyActions from '../../../../redux/ApiService/CompanyService/CompanyService';
import {TODO} from '../../../../utils/TODO';
import {useDispatch} from '../../../../redux/react-redux';
import styled from 'styled-components';
import {useUser} from '../../../../components/UserContext/useUser';
import sumBy from 'lodash/sumBy';
import {useQueryClient} from '@tanstack/react-query';
import {invalidateCompaniesQuery} from '../../../../queries/useCompanyQuery';
import {CollapsibleContent} from './CollapsibleContent';
import {Header} from './Header';
import './style.scss';
import {Company, CompanyUser} from '../../../../redux/ApiService/CompanyService/Company';

interface CompanyOnBoardingProps {
  company: Company;
  users: CompanyUser[];
}

export const CompanyOnBoarding: FC<CompanyOnBoardingProps> = ({users, company}) => {
  const dispatch = useDispatch();

  const user = useUser();

  const updateCompany = (id: number, data: TODO) => dispatch(CompanyActions.updateCompany(id, data));

  const queryClient = useQueryClient();

  const refresh = async () => invalidateCompaniesQuery(queryClient);
  const onRemove = async () => {
    await updateCompany(company.id, {isOnboarded: true});
    await refresh();
  };

  const isVerified = !!company.isVerified;
  const hasLogo = !!company.companyLogo;
  const hasDescription = !!(company.foundYear && company.companyType && company.description && company.employees);
  const hasUsers = users.length > 1;

  const partsOfProgress = [isVerified, hasLogo, hasUsers, hasDescription];
  const percentage = Math.round((sumBy(partsOfProgress, item => (item ? 1 : 0)) * 100) / partsOfProgress.length);
  const isFinished = percentage === 100;

  return (
    <Fragment>
      <StyledOnBoarding>
        <div>
          <Header percentage={percentage} isFinished={isFinished} user={user} />
          <CollapsibleContent
            isVerified={isVerified}
            data={company}
            onRefresh={refresh}
            hasLogo={hasLogo}
            hasUsers={hasUsers}
            hasDescription={hasDescription}
            onRemove={onRemove}
            isFinished={isFinished}
          />
        </div>
      </StyledOnBoarding>
    </Fragment>
  );
};

const StyledOnBoarding = styled.div`
  background-color: var(--color-white);
  min-width: 329px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
