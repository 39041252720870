import React, {FC} from 'react';
import {Col, Form, Row} from 'antd';
import {StationPicker} from './StationPicker';
import {LaycanDateRangePicker} from './LaycanDateRangePicker';

export const StationsSection: FC = () => {
  return (
    <div data-cy="StationsSection">
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item label="Load / Delivery" data-cy="load-delivery">
            <StationPicker stationType={'fixturedelivery'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item label="Trade / Via" data-cy="trade-via">
            <StationPicker stationType={'fixturedeliveryvia'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item label="Discharge / Redelivery" data-cy="discharge-redelivery">
            <StationPicker stationType={'fixtureredelivery'} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={9} data-cy="from-to">
          <Form.Item label="From/Laydays - To/Cancelling">
            <LaycanDateRangePicker />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
