import {Dayjs} from 'dayjs';
import {QueryClient} from '@tanstack/react-query';
import {isDayAHoliday} from '../isDayAHoliday';

export const satpmShex = async ({
  isMorning,
  isoWeekday,
  date,
  queryClient,
  countryCode,
}: {
  isMorning: boolean;
  isoWeekday: number;
  date: Dayjs;
  countryCode?: string;
  queryClient: QueryClient;
}) => {
  if (await isDayAHoliday({date, countryCode, queryClient})) {
    return false;
  }

  if (isoWeekday === 7) {
    return false;
  }
  if (isoWeekday === 6 && !isMorning) {
    return false;
  }
  return true;
};
