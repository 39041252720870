import {CSSProperties, FC, ReactNode} from 'react';
import styled from 'styled-components';

/**
 * Footer for an AntD modal.
 *
 */
export const ModalFooter: FC<{children?: ReactNode; style?: CSSProperties}> = ({children, style}) => (
  <ModalFooterStyled className="ModalFooter" style={style}>
    {children}
  </ModalFooterStyled>
);

const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  column-gap: 12px;

  .ant-btn {
    min-width: 80px;
  }
`;
