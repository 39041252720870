import {CustomColumnDef} from '../../../../components/DataGrid/DataGrid';

export const getDynamicTableColumns = (columns: string[]): CustomColumnDef<Record<string, string | null>>[] => {
  return columns.map(columnKey => ({
    header: columnKey,
    id: columnKey,
    cell: info => info.getValue(),
    accessorFn: d => {
      return <span>{d[columnKey]}</span>;
    },
    minWidth: 3,
  }));
};
