import {FilterProviderApi} from '../../../components/FilterProvider/FilterProviderApi';
import {FC} from 'react';
import NoResults from '../../market/EmptyState/NoResults';
import styled from 'styled-components';
import {portColumns} from './portColumns';
import Port from '../../../model/Port';
import {DataGrid} from '../../../components/DataGrid/DataGrid';
import {ColumnSort} from '@tanstack/react-table';
import {MarketPortFilterBranchDefinitions} from '../../../components/FilterProvider/Filters/Market/MarketPortFilterBranchDefinitions';

export const PortGrid: FC<{
  portFilterProviderApi: FilterProviderApi<typeof MarketPortFilterBranchDefinitions>;
  page: number;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  onChangeSortedBy: (sortedBy: ColumnSort) => void;
  isLoading: boolean;
  items?: Port[];
  totalCount?: number;
  sortedBy: ColumnSort;
  onChangePage: (page: number) => void;
}> = ({pageSize, page, onChangeSortedBy, onChangePageSize, onChangePage, items, isLoading, totalCount, sortedBy}) => {
  const displayEmptyState = () => {
    if (totalCount === 0) {
      return <NoResults type={'ports'} />;
    }
  };

  return (
    <Container>
      <DataGrid<Port>
        showJumper
        className={'datagrid-ports'}
        columns={portColumns()}
        data={items}
        totalCount={totalCount}
        page={page}
        pageSize={pageSize}
        loading={isLoading}
        sorted={[sortedBy]}
        onSettingChange={data => {
          onChangePageSize(data.pageSize);
          onChangePage(data.page);
          if (data.sortedBy) {
            onChangeSortedBy(data.sortedBy);
          }
        }}
        TbodyComponent={displayEmptyState()}
        zeroBasedPageIndex={false}
        responsive
        noHoverStatePointer
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-top: 20px;
`;
