import {QueryClient, useQuery, UseQueryResult} from '@tanstack/react-query';
import {assert} from '../../utils/assert';
import {TODO} from '../../utils/TODO';
import {useDispatch} from '../../redux/react-redux';
import VesselServiceActions from '../../redux/ApiService/vesselService';
import {ApiError, isApiErrorWithStatus} from '../../api/utils/ApiError';
import * as Sentry from '@sentry/browser';
import {UseQueryOptions} from '../../api/utils/ReactQuery';

const GetStatsVesselQueryKey = 'VesselServiceActions.getStatsVessel';

export const useGetStatsVesselQuery = (
  imo: number | undefined | null,
  options?: UseQueryOptions<TODO, ApiError>
): UseQueryResult<TODO> => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [GetStatsVesselQueryKey, imo],
    queryFn: async (): Promise<TODO> => {
      assert(imo);
      try {
        return await dispatch(VesselServiceActions.getStatsVessel(imo));
      } catch (e) {
        if (isApiErrorWithStatus(e, 404)) {
          Sentry.captureException(e);
          return null;
        }
        throw e;
      }
    },
    ...options,
  });
};

export const invalidateGetStatsVesselQuery = async (queryClient: QueryClient, imo: number) => {
  await queryClient.invalidateQueries({
    queryKey: [GetStatsVesselQueryKey, imo],
  });
};
