/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum StationType {
  Delivery = 'delivery',
  Deliveryvia = 'deliveryvia',
  Discharge = 'discharge',
  Nextopen = 'nextopen',
  Loading = 'loading',
  Redelivery = 'redelivery',
  Fixturedelivery = 'fixturedelivery',
  Fixtureredelivery = 'fixtureredelivery',
  Fixturedeliveryvia = 'fixturedeliveryvia',
}

export function StationTypeFromJSON(json: any): StationType {
  return StationTypeFromJSONTyped(json, false);
}

export function StationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationType {
  return json as StationType;
}

export function StationTypeToJSON(value?: StationType | null): any {
  return value as any;
}
