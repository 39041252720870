export const canDoVoyageCalc = ({
  vessel,
  cargo,
}: {
  vessel?: {vesselType: string};
  cargo?: {cargoType: string};
}): boolean => {
  if (vessel && vessel.vesselType !== 'bulker') {
    return false;
  }
  if (cargo && cargo.cargoType !== 'drybulk') {
    return false;
  }

  return true;
};
