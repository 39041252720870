import {TODO} from '../../../utils/TODO';
import VesselFixturesGrid from '../VesselFixtures/VesselFixtures';
import {Fixture} from '../VesselDetailBody';

export const VesselFixtureTab = ({vessel, onVesselChanged}: {vessel: TODO; onVesselChanged: () => void}) => {
  const nonSaleFixtures = vessel.fixtures.filter((fixture: Fixture) => {
    return fixture.contractType !== 'sale';
  });
  return (
    <VesselFixturesGrid
      id={'vessel-fixture'}
      key={'vessel-fixture'}
      tabKey={'commercialHistory'}
      selectedFixtureId={vessel.selectedFixture?.id}
      data={nonSaleFixtures}
      onFixtureChanged={onVesselChanged}
      unroundedTopLeft
      unroundedTopRight
    />
  );
};
