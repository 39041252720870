import {VoyagePoint} from '../VoyageTypes';

export const transformVoyagePointToPortParam = (point: VoyagePoint) => {
  if (!point) {
    return undefined;
  }
  return point.name
    ? {
        name: point.name,
        countryObject: {
          code: point.countryCode,
        },
      }
    : undefined;
};
