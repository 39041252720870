import {Button, Col, Form, FormListFieldData, Row, Space} from 'antd';
import React, {FC} from 'react';
import {Duration} from './Duration';
import {DurationUnitPicker} from './DurationUnitPicker';
import {RateAndCurrencyField} from './RateAndCurrencyField';
import {RateTermPicker} from './RateTermPicker';
import IconButton from '../../../../../../../atoms/IconButton';
import Icon from '../../../../../../../atoms/Icon';
import {LadenLegs} from './LadenLegs/LadenLegs';
import Divider from '../../../../../../../atoms/Divider';
import styled from 'styled-components';

export const OptionsRow: FC<{
  fields: FormListFieldData[];
  onRemove: (index: number | number[]) => void;
  onAdd: () => void;
}> = ({fields, onAdd, onRemove}) => {
  return (
    <>
      {fields.map((field, index) => (
        <SingleOptionRow key={index} onRemove={onRemove} onAdd={onAdd} field={field} />
      ))}

      <Button
        ghost
        type={'link'}
        icon
        onClick={onAdd}
        style={{padding: 0, border: 0, marginLeft: -8}}
        data-cy="AddOptionButton">
        <Space>
          <Icon type={'item-add-selection-solid'} color="blue" />
          <span>{fields.length === 0 ? 'ADD PERIOD' : 'ADD OPTION'}</span>
        </Space>
      </Button>
    </>
  );
};

const SingleOptionRow: FC<{
  onRemove: (index: number | number[]) => void;
  onAdd: () => void;
  field: FormListFieldData;
}> = ({onRemove, field}) => {
  return (
    <Row data-cy="SingleOptionRow">
      {field.name !== 0 && <OptionCounter data-cy="optionCounter">{field.name}. Option</OptionCounter>}
      <Form.Item hidden={true} name={[field.name, 'id']} />
      <Row gutter={[8, 8]} align="middle">
        <Col span={6}>
          <Form.Item label="Duration" data-cy="duration">
            <Duration field={field} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'Duration unit'} name={[field.name, 'periodUnit']} data-cy="durationUnit">
            <DurationUnitPicker />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Rate" data-cy="rate">
            <RateAndCurrencyField field={field} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Row align="middle">
            <Form.Item
              label={'Terms'}
              name={[field.name, 'rateTerms']}
              rules={[
                {
                  required: true,
                },
              ]}
              data-cy="terms"
              style={{flexGrow: '1'}}>
              <RateTermPicker />
            </Form.Item>
            <IconButton
              type={'delete'}
              iconStyle={{cursor: 'pointer'}}
              onClick={() => onRemove(field.name)}
              title={'Remove'}
              buttonStyle={{boxShadow: 'none', marginTop: 2, marginLeft: 4, marginRight: 4}}
              data-cy="deleteOptionButton"
            />
          </Row>
        </Col>
      </Row>
      <LadenLegs field={field} />
      <DividerWithoutTopMargin />
    </Row>
  );
};

const OptionCounter = styled.div`
  color: var(--color-gray-2);
  margin-bottom: 8px;
`;

export const DividerWithoutTopMargin = styled(Divider)`
  margin-top: 0;
  margin-bottom: 16px;
`;
