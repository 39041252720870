import {Card} from 'antd';
import {FC} from 'react';
import {VesselDetailsVessel} from '../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {VoyageTimeline} from '../VoyageTimeline/VoyageTimeline';

type VoyageTimelineTabProps = {
  vessel: VesselDetailsVessel;
};

export const VoyageTimelineTab: FC<VoyageTimelineTabProps> = ({vessel}) => {
  return (
    <Card style={{borderTopLeftRadius: 0}}>
      <VoyageTimeline vessel={vessel} />
    </Card>
  );
};
