import {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import {Route, Routes, useParams} from 'react-router-dom';
import {CompanyDetailsScreen} from './CompanyDetailsScreen/CompanyDetailsScreen';
import {CompanySettingsScreens} from './CompanySettingsScreen/CompanySettingsScreens';
import {VerifyCompanyScreen} from './VerifyCompanyScreen/VerifyCompanyScreen';
import {useSelector} from '../../redux/react-redux';
import {RootState} from '../../redux/store';
import {Company} from '../../redux/ApiService/CompanyService/Company';
import {parseIntTS} from '../../utils/parseNumberTS';
import {useIsOwnCompany} from './useIsOwnCompany';

export const CompanyScreens: FC = () => {
  const params = useParams<{id: string}>();

  const companyId = parseIntTS(params.id);

  const company: Company = useSelector((state: RootState) => state.company.data);

  const isOwnCompany = useIsOwnCompany({companyId});

  const isVerified = company.isVerified;

  if (!isOwnCompany) {
    return (
      <>
        <Helmet title={'Company'} />
        <Routes>
          <Route path={``} element={<CompanyDetailsScreen />} />
        </Routes>
      </>
    );
  }

  if (!isVerified) {
    return (
      <>
        <Helmet title={'Company'} />
        <Routes>
          <Route path={`*`} element={<VerifyCompanyScreen />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <Helmet title={'Company'} />
      <Routes>
        <Route path={``} element={<CompanyDetailsScreen />} />
        <Route path={`/settings/*`} element={<CompanySettingsScreens />} />
      </Routes>
    </>
  );
};
