import React, {FC} from 'react';
import {Button, Form, Input} from 'antd';
import {Store, ValidateErrorEntity} from 'rc-field-form/lib/interface';
import {ProfileFormValues} from './ProfileFormValues';
import {ApiErrorMessage} from '../../../components/ApiErrorMessage/ApiErrorMessage';
import {
  SettingsSection,
  SettingsSectionBody,
  SettingsSectionTitle,
} from '../../../components/SettingsCard/SettingsSection';
import {SettingsButtons} from '../../../components/SettingsCard/SettingsButtons';
import {PhoneInputFormItem, phoneNumberValidationRules} from '../../../atoms/PhoneInputFormItem';

const onFinishFailed = (_errorInfo: ValidateErrorEntity) => {};

export const ProfileForm: FC<{
  initialValues: ProfileFormValues;
  isSubmitting: boolean;
  onSubmit: (formValues: ProfileFormValues) => Promise<void>;
  submitError?: Error | null;
}> = ({initialValues, isSubmitting, onSubmit, submitError}) => {
  const onFinish = async (values: Store) => {
    const formValues = values as ProfileFormValues;
    await onSubmit(formValues);
  };

  return (
    <div className="ProfileForm" data-testid="ProfileForm">
      <Form
        name="Profile"
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        {submitError && (
          <>
            <br />
            <ApiErrorMessage error={submitError} />
          </>
        )}

        <SettingsSection $antForm>
          <SettingsSectionTitle>Postal address</SettingsSectionTitle>

          <SettingsSectionBody>
            <Form.Item label="Street - No." style={{marginBottom: 0}}>
              <Form.Item name="street" style={{display: 'inline-block', width: 'calc(70% - 8px)'}}>
                <Input data-testid="street" />
              </Form.Item>
              <Form.Item name="streetNumber" style={{display: 'inline-block', width: 'calc(30%)', margin: '0 0 0 8px'}}>
                <Input data-testid="streetNumber" />
              </Form.Item>
            </Form.Item>

            <Form.Item label="Zip - City" style={{marginBottom: 0}}>
              <Form.Item name="zipCode" style={{display: 'inline-block', width: 'calc(30% - 8px)'}}>
                <Input data-testid="zipCode" />
              </Form.Item>
              <Form.Item name="city" style={{display: 'inline-block', width: 'calc(70%)', margin: '0 0 0 8px'}}>
                <Input data-testid="city" />
              </Form.Item>
            </Form.Item>

            <Form.Item label="Country" name="country" rules={[]}>
              <Input data-testid="country" />
            </Form.Item>
          </SettingsSectionBody>
        </SettingsSection>

        <SettingsSection $antForm>
          <SettingsSectionTitle>Contact options</SettingsSectionTitle>
          <SettingsSectionBody>
            <Form.Item label="Website" name="website" rules={[]}>
              <Input data-testid="website" />
            </Form.Item>

            <Form.Item label="Telephone" name="telephone" rules={[...phoneNumberValidationRules]}>
              <PhoneInputFormItem dataTestId="telephone" />
            </Form.Item>

            <Form.Item label="Fax" name="fax" rules={[...phoneNumberValidationRules]}>
              <PhoneInputFormItem dataTestId="fax" />
            </Form.Item>

            <Form.Item label="Skype" name="skype" rules={[]}>
              <Input data-testid="skype" />
            </Form.Item>
          </SettingsSectionBody>
        </SettingsSection>

        <SettingsButtons>
          <Button type="primary" htmlType="submit" disabled={isSubmitting} data-testid="Submit">
            Save
          </Button>
        </SettingsButtons>
      </Form>
    </div>
  );
};
