import {Form, Modal} from 'antd';
import {FC, useEffect, useMemo, useState} from 'react';
import {PortfolioVessel} from '../../../../redux/Portfolio';
import {useOpenVesselForm} from '../../../CargoVesselForm/utils/useOpenVesselForm';
import {getVesselWithoutNull} from './getVesselWithoutNull';
import {IntakeDiffs} from './IntakeDiffs';
import {OneChangeDialog} from './OneChangeDialog';
import {filterIntakesFromDiff} from './utils/filterIntakesFromDiff';
import {getDiffsFromTwoVessels} from './utils/getDiffsFromTwoVessels';
import {TODO} from '../../../../utils/TODO';

export const MatchingOrderBookVesselModal: FC<{
  onCancel: () => void;
  oldVessel: PortfolioVessel;
  newVessel: PortfolioVessel;
}> = ({oldVessel, onCancel, newVessel}) => {
  const openVesselForm = useOpenVesselForm();

  const [vessel, setVessel] = useState<TODO>({...oldVessel});

  const newVesselWithoutNull: PortfolioVessel = useMemo(() => {
    return getVesselWithoutNull<PortfolioVessel>(newVessel as PortfolioVessel);
  }, [newVessel]);

  const oldVesselWithoutNull: PortfolioVessel = useMemo(() => {
    const oldVesselWithoutNull: PortfolioVessel = getVesselWithoutNull<PortfolioVessel>(oldVessel as PortfolioVessel);
    return {...newVesselWithoutNull, ...oldVesselWithoutNull};
  }, [newVesselWithoutNull, oldVessel]);

  const diffs = useMemo(() => {
    return getDiffsFromTwoVessels({newVesselWithoutNull, oldVesselWithoutNull});
  }, [newVesselWithoutNull, oldVesselWithoutNull]);

  useEffect(() => {
    setVessel({...vessel, ...oldVesselWithoutNull});
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [oldVesselWithoutNull]);

  const diffsWithoutIntake = filterIntakesFromDiff({diffs, returns: 'withoutIntake'});
  const hasIntakeDiffs = filterIntakesFromDiff({diffs, returns: 'onlyIntake'}).length > 0;
  return (
    <Modal
      title={'Match with new vessel database'}
      onOk={() => {
        openVesselForm({
          id: vessel.id,

          defaultVesselValues: vessel,
        });
      }}
      okText={'Update vessels from Database'}
      onCancel={onCancel}
      open={true}>
      <Form layout={'vertical'}>
        {diffsWithoutIntake?.map(diff => {
          const key = diff.path?.[0];

          return (
            <OneChangeDialog
              key={key}
              onChange={(newValue: TODO) => {
                setVessel({...vessel, [key]: newValue});
              }}
              value={vessel[key]}
              diff={diff}
            />
          );
        })}
        <IntakeDiffs
          onChange={newIntakes => {
            setVessel({...vessel, intakes: newIntakes});
          }}
          newVessel={newVesselWithoutNull}
          oldvessel={oldVesselWithoutNull}
          value={vessel['intakes']}
          hasIntakeDiffs={hasIntakeDiffs}
        />
      </Form>
    </Modal>
  );
};
