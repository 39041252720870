/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselOffersForLocationResponseData,
  VesselOffersForLocationResponseDataFromJSON,
  VesselOffersForLocationResponseDataFromJSONTyped,
  VesselOffersForLocationResponseDataToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselOffersForLocationResponse
 */
export interface VesselOffersForLocationResponse {
  /**
   *
   * @type {VesselOffersForLocationResponseData}
   * @memberof VesselOffersForLocationResponse
   */
  data: VesselOffersForLocationResponseData;
}

export function VesselOffersForLocationResponseFromJSON(json: any): VesselOffersForLocationResponse {
  return VesselOffersForLocationResponseFromJSONTyped(json, false);
}

export function VesselOffersForLocationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VesselOffersForLocationResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: VesselOffersForLocationResponseDataFromJSON(json['data']),
  };
}

export function VesselOffersForLocationResponseToJSON(value?: VesselOffersForLocationResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: VesselOffersForLocationResponseDataToJSON(value.data),
  };
}
