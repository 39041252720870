import {FC} from 'react';
import {PhoneOutlined} from '@ant-design/icons';
import PhoneInput, {Country} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import classNames from 'classnames';
import styled from 'styled-components';

export const PhoneInputFormItem: FC<{
  placeholder?: string;
  onChange?: (value: string | undefined) => void;
  dataTestId?: string;
  defaultCountry?: Country;
  value?: string;
}> = ({placeholder, value, onChange, defaultCountry, dataTestId}) => {
  const noBorder = false;
  const hasError = false;
  return (
    <StyledPhoneInput
      value={value}
      onChange={value => {
        if (onChange) {
          onChange(value);
        }
      }}
      international={true}
      className={classNames('input', `input--tel`, {
        'input--no-border': noBorder,
        'input--has-error': hasError,
      })}
      prefix={<PhoneOutlined />}
      autoComplete="tel"
      placeholder={placeholder}
      data-testid={dataTestId}
      defaultCountry={defaultCountry}
    />
  );
};

const StyledPhoneInput = styled(PhoneInput)`
  input {
    border: none;
    background: transparent;
  }

  input:focus {
    outline: none;
  }
` as unknown as typeof PhoneInput;

export const phoneNumberValidationRules = [
  {
    required: false,
    message: 'Please input your phone number.',
    pattern: RegExp('^(?:\\+|00)[1-9]{1,2}[0-9 ]{5,}$'),
  },
  {
    max: 15,
    message: 'Please input a valid phone number. (max. 15 digits)',
  },
];
