import React, {FC} from 'react';
import {Button, Tooltip} from 'antd';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {SidebarMode, SidebarActions} from '../../../redux/Sidebar';
import IconLeft from './icons/icon-left.svg';
import IconRight from './icons/icon-right.svg';

export const SidebarControls: FC<{mode: SidebarMode}> = ({mode}) => {
  return (
    <SidebarControlsStyled>
      {mode === 'narrow' && (
        <LayoutNarrow>
          <HiddenButton />
          <WideButton />
        </LayoutNarrow>
      )}
      {mode === 'wide' && (
        <LayoutWide>
          <NarrowButton />
        </LayoutWide>
      )}
    </SidebarControlsStyled>
  );
};

const HiddenButton = () => <ModeButton mode="hidden" tooltip="Hide" icon={IconLeft} dataTestId="HiddenButton" />;
const NarrowButton = () => <ModeButton mode="narrow" tooltip="Minify" icon={IconLeft} dataTestId="NarrowButton" />;
const WideButton = () => <ModeButton mode="wide" tooltip="Expand" icon={IconRight} dataTestId="WideButton" />;

const ModeButton: FC<{mode: SidebarMode; tooltip: string; icon: string; dataTestId: string}> = ({
  mode,
  tooltip,
  icon,
  dataTestId,
}) => {
  const dispatch = useDispatch();

  return (
    <Tooltip title={tooltip} placement="topLeft">
      <IconButton
        onClick={() => {
          dispatch(SidebarActions.setSidebarMode(mode));
        }}
        data-testid={dataTestId}>
        <img alt="Sidebar control" src={icon} style={{width: '22px', height: '22px'}} />
      </IconButton>
    </Tooltip>
  );
};

export const RevalSidebarButton = () => {
  return (
    <FloatingArea>
      <ModeButton mode="narrow" tooltip="Show sidebar" icon={IconRight} dataTestId="RevalSidebarButton" />
    </FloatingArea>
  );
};

const FloatingArea = styled.nav`
  position: fixed;
  bottom: 12px;
  left: 5px;
  z-index: 500;
`;

const SidebarControlsStyled = styled.div`
  height: 51px;
  padding-right: 13px;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LayoutNarrow = styled(Layout)`
  justify-content: space-evenly;
`;

const LayoutWide = styled(Layout)`
  justify-content: flex-end;
  padding-right: 13px;
`;

const IconButton = styled(Button)`
  border: none;
  background-color: transparent;
  padding: 0;
  width: 22px;
  height: 22px;
  border-radius: 16px;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  .anticon {
    font-size: var(--font-size-xl);
    color: #e0e0e0;

    &:hover {
      fill: var(--color-white);
    }
  }
`;
