import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';
import {
  floatBetween,
  floatBetweenAndGreaterOrEqual,
  numberBetween,
  numberBetweenAndGreaterOrEqual,
} from '../../../../utils/validators';
import {sizesToSizeObject} from '../Size/SizeDefinition';

export interface DatabaseType {
  grossTonnage: {
    from?: number;
    to?: number;
  };
  netTonnage: {
    from?: number;
    to?: number;
  };
  length: {
    from?: number;
    to?: number;
  };
  beam: {
    from?: number;
    to?: number;
  };
  tonnesPerCentimetre: {
    from?: number;
    to?: number;
  };
  draft: {
    from?: number;
    to?: number;
  };
}

export interface InternalType {
  grossTonnageFrom: number | '';
  grossTonnageTo: number | '';
  lengthFrom: number | string; // can be decimal
  lengthTo: number | string; // can be decimal
  netTonnageFrom: number | '';
  netTonnageTo: number | '';
  beamFrom: number | string; // can be decimal
  beamTo: number | string; // can be decimal
  tonnesPerCentimetreFrom: number | string; // can be decimal
  tonnesPerCentimetreTo: number | string; // can be decimal
  draftFrom: number | string; // can be decimal
  draftTo: number | string; // can be decimal
}

export const Defaults: InternalType = {
  grossTonnageFrom: '',
  grossTonnageTo: '',
  lengthFrom: '',
  lengthTo: '',
  netTonnageFrom: '',
  netTonnageTo: '',
  beamFrom: '',
  beamTo: '',
  tonnesPerCentimetreFrom: '',
  tonnesPerCentimetreTo: '',
  draftFrom: '',
  draftTo: '',
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  return {
    beamFrom: database.beam.from ? database.beam.from.toString() : '',
    beamTo: database.beam.to ? database.beam.to.toString() : '',
    draftFrom: database.draft.from ? database.draft.from.toString() : '',
    draftTo: database.draft.to ? database.draft.to.toString() : '',
    grossTonnageFrom: database.grossTonnage.from ? database.grossTonnage.from : '',
    grossTonnageTo: database.grossTonnage.to ? database.grossTonnage.to : '',
    lengthFrom: database.length.from ? database.length.from.toString() : '',
    lengthTo: database.length.to ? database.length.to.toString() : '',
    netTonnageFrom: database.netTonnage.from ? database.netTonnage.from : '',
    netTonnageTo: database.netTonnage.to ? database.netTonnage.to : '',
    tonnesPerCentimetreFrom: database.tonnesPerCentimetre.from ? database.tonnesPerCentimetre.from.toString() : '',
    tonnesPerCentimetreTo: database.tonnesPerCentimetre.to ? database.tonnesPerCentimetre.to.toString() : '',
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  return {
    beam: sizesToSizeObject({from: internal.beamFrom, to: internal.beamTo}),
    grossTonnage: sizesToSizeObject({from: internal.grossTonnageFrom, to: internal.grossTonnageTo}),
    length: sizesToSizeObject({from: internal.lengthFrom, to: internal.lengthTo}),
    netTonnage: sizesToSizeObject({from: internal.netTonnageFrom, to: internal.netTonnageTo}),
    tonnesPerCentimetre: sizesToSizeObject({
      from: internal.tonnesPerCentimetreFrom,
      to: internal.tonnesPerCentimetreTo,
    }),
    draft: sizesToSizeObject({from: internal.draftFrom, to: internal.draftTo}),
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Dimensions',
  branch: 'dimensions',
  defaults: Defaults,
  validators: {
    grossTonnageFrom: numberBetween(0, 999999),
    grossTonnageTo: numberBetweenAndGreaterOrEqual(0, 999999, 'grossTonnageFrom'),
    netTonnageFrom: numberBetween(0, 999999),
    netTonnageTo: numberBetweenAndGreaterOrEqual(0, 999999, 'netTonnageFrom'),
    lengthFrom: floatBetween(0, 99999999.99),
    lengthTo: floatBetweenAndGreaterOrEqual(0, 99999999.99, 'lengthFrom'),
    beamFrom: floatBetween(0, 99999999.99),
    beamTo: floatBetweenAndGreaterOrEqual(0, 99999999.99, 'beamFrom'),
    tonnesPerCentimetreFrom: floatBetween(0, 99999999.99),
    tonnesPerCentimetreTo: floatBetweenAndGreaterOrEqual(0, 99999999.99, 'tonnesPerCentimetreFrom'),
    draftFrom: floatBetween(0, 99999999.99),
    draftTo: floatBetweenAndGreaterOrEqual(0, 99999999.99, 'draftFrom'),
  },
  fromDatabase,
  toDatabase,
});
