import {Form} from 'antd';

export const HiddenFields = () => {
  // We needs these hidden fields to keep state for fields that are conditionally rendered.
  return (
    <>
      <Form.Item hidden={true} name={'designSubType'} />
      <Form.Item hidden={true} name={'designType'} />
      <Form.Item hidden={true} name={'vessel'} />
      <Form.Item hidden={true} name={'dwat'} />
      <Form.Item hidden={true} name={'teuQuantity'} />
      <Form.Item hidden={true} name={'sellerName'} />
      <Form.Item hidden={true} name={'contractType'} />
      <Form.Item hidden={true} name={'commissionTotal'} />
      <Form.Item hidden={true} name={'cargo'} />
      <Form.Item hidden={true} name={'options'} />
      <Form.Item hidden={true} name={'stations'} />
      <Form.Item hidden={true} name={'laycanFrom'} />
      <Form.Item hidden={true} name={'laycanTo'} />
    </>
  );
};
