import {NegotiationStatus} from '../../../api/node-backend/generated';

export const getNegotiationStatusLabel = (
  status: NegotiationStatus,
  isOwnTurn?: boolean,
  roundCount: number = 3,
  isFirstRecapRound?: boolean
) => {
  switch (status) {
    case NegotiationStatus.Offer:
      return 'Offer draft';
    case NegotiationStatus.Ongoing:
      if (roundCount <= 2) {
        // Offer sent + Offer received should be displayed as "Offer"
        return isOwnTurn ? 'Offer received' : 'Offer sent';
      }
      return isOwnTurn ? 'Counter received' : 'Counter sent';
    case NegotiationStatus.Recap:
      if (isFirstRecapRound) {
        return 'Terms accepted';
      }
      return isOwnTurn ? 'Recap received' : 'Recap sent';
    case NegotiationStatus.Settled:
      return 'Clean';
    case NegotiationStatus.Cancelled:
      return 'Cancelled';
    default:
      return 'Unknown';
  }
};
