import {FC, ReactElement, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {FeaturePermission} from '../../api/symfony/generated';
import {useIsPermitted} from '../../utils/useIsPermitted';

export const PremiumRoute: FC<{
  requiredPermission: FeaturePermission;
  children: ReactElement;
}> = ({children, requiredPermission}): ReactElement | null => {
  const hasPermission = useIsPermitted(requiredPermission);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasPermission) {
      navigate('/subscription');
    }
  }, [hasPermission, navigate]);

  if (hasPermission) {
    return children;
  }

  return null;
};
