import {FC} from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  value?: string | null;
}

export const ContactDetail: FC<Props> = ({label, value}) =>
  value ? (
    <DetailItem className="user-profile__contact-detail-item">
      <ItemTitle className="user-profile__contact-detail-item-title">{label}</ItemTitle>
      <ItemValue className="user-profile__contact-detail-item-value">{value}</ItemValue>
    </DetailItem>
  ) : (
    <></>
  );

const DetailItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ItemTitle = styled.span`
  font-size: var(--font-size-md);
  font-weight: bold;
`;

const ItemValue = styled.div`
  font-size: var(--font-size-md);
  color: var(--color-gray-2);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
