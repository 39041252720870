import {VoyagePoint} from '../../../VoyageTypes';
import {Input, Select, Space} from 'antd';
import {regexNotIncludeMinusSign} from '../../../../../../../utils/regexExpressions';
import {getDataFieldId} from '../../../../utils/calcWarnings/calcWarnings';
import {SelectWithDataFieldId} from '../../../utils/SelectWithDataFieldId';
import {voyagePointTerms} from '../../../VoyagePointTerm';
import {NotApplicable} from '../../../utils/NotApplicable';
import {StyledFormItem} from '../../StyledFormItem';

type Props = {
  point: VoyagePoint;
  area: string;
};
export const Terms = (props: Props) => {
  const isLoading = props.point.types?.includes('loading');
  const isDischarge = props.point.types?.includes('discharge');
  const active = isLoading || isDischarge;

  if (!active) {
    return <NotApplicable />;
  }

  return (
    <Space.Compact>
      <StyledFormItem
        noStyle
        name={isLoading ? 'loadingRate' : 'dischargingRate'}
        rules={[
          {
            message: 'Rate must be greater than or equal to 0.',
            pattern: regexNotIncludeMinusSign,
          },
        ]}
        normalize={s => {
          const n = parseInt(s, 10);
          return isNaN(n) ? undefined : n;
        }}>
        <Input
          size="small"
          data-field-id={getDataFieldId({field: isLoading ? 'loadingRate' : 'dischargingRate', area: props.area})}
          type={'number'}
          style={{width: 120}}
          suffix={'pwwd'}
        />
      </StyledFormItem>
      <StyledFormItem noStyle name={isLoading ? 'termsLoading' : 'termsDischarging'}>
        <SelectWithDataFieldId
          field={isLoading ? 'termsLoading' : 'termsDischarging'}
          area={props.area}
          style={{width: 100}}
          size="small">
          {voyagePointTerms.map(key => {
            return (
              <Select.Option key={key} value={key}>
                {key}
              </Select.Option>
            );
          })}
        </SelectWithDataFieldId>
      </StyledFormItem>
    </Space.Compact>
  );
};
