import {Button, Space} from 'antd';
import {useEffect, useState, FC} from 'react';
import styled from 'styled-components';
import {NegotiationReplyTime} from '../../../../../../api/node-backend/generated';
import {ReplyTimeEditorDuration} from './ReplyTimeEditorDuration';
import {ReplyTimeEditorTimestamp} from './ReplyTimeEditorTimestamp';
import dayjs, {Dayjs} from 'dayjs';

type EditorType = 'duration' | 'timestamp';

type NegotiationReplyTimeEditorProps = {
  replyTime?: NegotiationReplyTime | null;
  onReplyTimeChange: (replyTime: NegotiationReplyTime | null) => void;
};

export const NegotiationReplyTimeEditor: FC<NegotiationReplyTimeEditorProps> = ({replyTime, onReplyTimeChange}) => {
  // NegotiationReplyTime API only accepts one of duration or timestamp
  // we want to return the currently visible, but keep the other one while editing for usability
  const [editorType, setEditorType] = useState<EditorType>('duration');
  const [duration, setDuration] = useState<number>(0);
  const [timestamp, setTimestamp] = useState<Dayjs>(dayjs().add(1, 'minute'));

  useEffect(() => {
    if (!replyTime) {
      return;
    }
    const {duration, timestamp} = replyTime;
    if (duration) {
      setDuration(duration);
      return;
    }
    if (timestamp) {
      setTimestamp(dayjs(timestamp));
      setEditorType('timestamp');
    }
  }, [replyTime]);

  // Reset the reply time to default values
  const removeReplyTime = () => {
    setTimestamp(dayjs().add(1, 'minute'));
    setDuration(0);
    onReplyTimeChange({duration: null, timestamp: null});
  };

  const handleReplyTimeChange = () => {
    onReplyTimeChange(
      editorType === 'duration' ? {duration, timestamp: null} : {timestamp: timestamp.toDate(), duration: null}
    );
  };

  const hasError = editorType === 'timestamp' && timestamp.isBefore(dayjs());

  const switchEditorType = (newType: EditorType) => {
    setEditorType(newType);
    if (newType === 'timestamp' && hasError) {
      setTimestamp(dayjs().add(1, 'minute'));
    }
  };

  return (
    <ReplyTimeEditorContainer data-testid="NegotiationReplyTimeEditor">
      <Space.Compact block size="small">
        <Button
          type={editorType === 'duration' ? 'primary' : 'default'}
          block
          onClick={() => switchEditorType('duration')}
          data-testid="ToggleDurationButton">
          Duration
        </Button>
        <Button
          type={editorType === 'timestamp' ? 'primary' : 'default'}
          block
          onClick={() => switchEditorType('timestamp')}
          data-testid="ToggleTimestampButton">
          Date
        </Button>
      </Space.Compact>
      {editorType === 'duration' ? (
        <ReplyTimeEditorDuration duration={duration} onDurationChange={setDuration} />
      ) : (
        <ReplyTimeEditorTimestamp timestamp={timestamp} hasError={hasError} onTimestampChange={setTimestamp} />
      )}
      <ButtonBox>
        <ColoredButton
          size="small"
          $color="green"
          block
          onClick={handleReplyTimeChange}
          disabled={hasError}
          data-testid="ReplyTimeEditorSaveButton">
          Save
        </ColoredButton>
        <ColoredButton
          size="small"
          $color="red"
          block
          disabled={!duration && !timestamp}
          onClick={removeReplyTime}
          data-testid="ReplyTimeEditorDeleteButton">
          Remove
        </ColoredButton>
      </ButtonBox>
    </ReplyTimeEditorContainer>
  );
};

const ReplyTimeEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 250px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
`;

const ColoredButton = styled(Button)<{$color: string}>`
  margin-top: 3px;
  &:not(:disabled) {
    background: var(--color-${({$color}) => $color}) !important;
    border-color: var(--color-${({$color}) => $color}) !important;
    color: white !important;
  }
`;

export const EditorDescription = styled.div`
  padding: 5px;
  font-size: var(--font-size-sm);
  font-weight: bold;
  line-height: 1;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  width: 100%;

  > div {
    width: 100%;
  }

  input,
  .ant-input-affix-wrapper,
  .ant-picker {
    border-radius: var(--border-radius-lg);
  }

  input {
    max-height: 30px;
    text-align: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
    }
  }

  .ant-input {
    padding-right: 2px;
  }
`;
