import {MapViewState} from '@deck.gl/core/typed';
import {MapControlButton, MapSize} from '../../components/SeaboMap/MapContext/Types';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {getViewBounds} from '../../components/SeaboMap/utils/getViewBounds';
import PulsatingCirclesLayer from '../../components/SeaboMap/layers/mapbox/PulsatingCirclesLayer';
import {SpotifindVessel} from './useGetSpotifindVesselsQuery';
import {
  defaultColumns,
  ListOfSelectedVessels,
  MapSelectedVessel,
} from '../../components/SeaboMap/SideContent/ListOfSelectedVessels';
import {ColumnType} from 'antd/es/table';
import Flag from '../../atoms/Flags/index';
import {SeaboMap} from '../../components/SeaboMap/SeaboMap';
import {ViewBounds} from '../../redux/ViewBounds';
import {transformObjectFromLonLat} from '../../utils/Coordinates';
import {useDispatch} from 'react-redux';
import {setMapDetailElement} from '../../redux/MapDetails';
import {useThrottle} from '../../utils/useThrottle';
import {makePartialMapSwitches} from '../../components/SeaboMap/utils/makePartialMapSwitches';
import {AisVessel} from '../../api/symfony/generated';

export const SpotifindMap: FC<{
  selectedVessel: SpotifindVessel | undefined;
  vessels?: SpotifindVessel[];
  onChangeViewBounds: (viewBounds: ViewBounds) => void;
}> = ({selectedVessel, vessels, onChangeViewBounds}) => {
  const [mapSize, setMapSize] = useState<MapSize | undefined>(undefined);
  const [viewState, setViewState] = useState<MapViewState | undefined>(undefined);
  const dispatch = useDispatch();

  // We debounce this callback because when syncWithMap is enabled, it causes the grid to refilter and then replace all rows.
  const throttledOnChangeViewBounds = useThrottle(onChangeViewBounds, [], 150);

  useEffect(() => {
    if (mapSize && viewState) {
      const viewBounds = getViewBounds({mapSize, viewState});
      throttledOnChangeViewBounds(viewBounds);
    }
  }, [viewState, mapSize, throttledOnChangeViewBounds]);

  const layersOnTopOfMap = useMemo(() => {
    if (selectedVessel) {
      return [
        <PulsatingCirclesLayer points={[transformObjectFromLonLat(selectedVessel.coordinates)]} color={'#ff70ea'} />,
      ];
    }
    return [];
  }, [selectedVessel]);

  useEffect(() => {
    // When the filters and thus the vessels change, the MapDetails should be closed if they are open.
    dispatch(setMapDetailElement(null));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vessels]);

  return (
    <SeaboMap
      style={{minHeight: 100, display: 'flex', flex: 1}}
      settingIdentifier="spotifind"
      showSearch
      vesselsToVisualize={{
        data: vessels as AisVessel[],
        constantIconSize: 8,
      }}
      showPopups
      initialMapSettings={{
        switches: makePartialMapSwitches({
          ports: true,
          highRiskAreas: true,
          emissionControlAreas: true,
        }),
      }}
      mapControlButtonsToHide={[MapControlButton.CARGOES, MapControlButton.VESSELS]}
      layersOnTopOfMapBox={layersOnTopOfMap}
      onViewStateChange={viewState => {
        setViewState(viewState);
      }}
      onChangeMapSize={mapSize => {
        setMapSize(mapSize);
      }}
      sideContentRenderer={props => {
        const columns = defaultColumns.filter(column => !['loa', 'beam'].includes(column.key as string));

        const destinationColum: ColumnType<MapSelectedVessel> = {
          title: 'Destination',
          key: 'destination',
          render: (_: unknown, vessel: MapSelectedVessel) => {
            if (vessel.destinationPort) {
              return (
                <span>
                  <Flag countryCode={vessel.destinationPort.countryObject.code} />
                  <span style={{marginLeft: 4}}>{vessel.destinationPort.name}</span>
                </span>
              );
            }
            return <span>{vessel.destination ?? '-'}</span>;
          },
        };

        columns.splice(2, 0, destinationColum);
        return <ListOfSelectedVessels {...props} columns={columns} />;
      }}
    />
  );
};
