import React, {CSSProperties, FC, ReactNode} from 'react';
import cx from 'classnames';
import {numberFormat} from '../../utils/formatter';

import './detailtable.scss';
import {TODO} from '../../utils/TODO';

const renderNA = (...attributes: TODO[]) =>
  attributes.every(attribute => typeof attribute === 'undefined' || attribute === null);

interface Props {
  width?: CSSProperties['width'];
  value?: TODO;
  gray?: boolean;
  withPadding?: boolean;
  textEllipsis?: boolean;
  upper?: boolean;
  formatted?: boolean;
  numberFormatOptions?: Intl.NumberFormatOptions;
  children?: React.ReactNode;
}

export const DetailsTableCell: FC<Props> = ({
  children,
  value,
  width = 'auto',
  gray,
  withPadding,
  textEllipsis,
  upper,
  formatted = false,
  numberFormatOptions,
}) => {
  let renderedValue: ReactNode;
  if (renderNA(children, value)) {
    renderedValue = 'N/A';
  } else if (value) {
    renderedValue = formatted ? numberFormat(value, undefined, numberFormatOptions) : value;
  } else {
    renderedValue = children;
  }

  return (
    <td
      style={{width: width}}
      className={cx(
        'detail-table__cell',
        {'detail-table__cell--color-gray': gray},
        {'detail-table__cell--with-padding': withPadding},
        {'detail-table__cell--upper': upper},
        {'detail-table__cell--text-ellipsis': textEllipsis}
      )}>
      {renderedValue}
    </td>
  );
};

export default DetailsTableCell;
