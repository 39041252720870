import {Col, Modal, Row} from 'antd';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import chunk from 'lodash/chunk';
import {FC, useMemo} from 'react';
import {Attribute} from '../../../../atoms/Attribute/Attribute';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {numberUnitFormat} from '../../../../utils/formatter';
import {roundNumberToDecimals} from '../../../../utils/roundNumber';
import {VesselType} from '../VesselType';
import {TiedUpTradingAreaWithDistributionAt, ValueRepresentation} from './getDatasource';
import {TODO} from '../../../../utils/TODO';
import styled from 'styled-components';

const getUnit = (valueRepresentation: ValueRepresentation, vesselType: VesselType) => {
  switch (true) {
    case valueRepresentation === 'percentage':
      return '%';
    case vesselType === 'container':
      return 'TEU';
    default:
      return 'MT';
  }
};

const formatDateValue = (value: number, unit: string) =>
  unit === '%'
    ? numberUnitFormat(value.toFixed(2), unit)
    : numberUnitFormat(roundNumberToDecimals(value / 1000, 0), unit);

export const OneTradingAreaTimeSeriesModal: FC<{
  visible: boolean;
  tiedUpTradingArea: TiedUpTradingAreaWithDistributionAt;
  valueRepresentation: ValueRepresentation;
  vesselType: VesselType;
  onClose: () => void;
}> = ({visible, tiedUpTradingArea, valueRepresentation, vesselType, onClose}) => {
  const chunks = useMemo(() => {
    return chunk(tiedUpTradingArea.dataSeries, tiedUpTradingArea.dataSeries.length / 6);
  }, [tiedUpTradingArea.dataSeries]);

  const unit = getUnit(valueRepresentation, vesselType);

  return (
    <Modal
      title={`Time series for "${tiedUpTradingArea.tradingAreaName}"`}
      width={1000}
      destroyOnClose
      open={visible}
      onCancel={onClose}
      footer={null}>
      <div style={{color: 'var(--color-black)'}}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ReactECharts
              option={{
                tooltip: {
                  trigger: 'axis',
                  position: function (pt: TODO) {
                    return [pt[0], '10%'];
                  },
                },
                grid: {
                  left: 5,
                  right: 5,
                },

                xAxis: {
                  type: 'time',
                  boundaryGap: false,
                },
                yAxis: {
                  type: 'value',
                  boundaryGap: [0, '100%'],
                },
                dataZoom: [
                  {
                    type: 'inside',
                    start: 0,
                    end: 100,
                  },
                  {
                    start: 0,
                    end: 100,
                  },
                ],
                series: [
                  {
                    name: 'Tied up vessels',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {},
                    data: tiedUpTradingArea.dataSeries.map(({date, value}) => {
                      return [date, value];
                    }),
                  },
                ],
              }}
            />
          </Col>
          {valueRepresentation === 'absolute' && (
            <Col span={24}>
              <span>Values are in 1000 {unit}</span>
            </Col>
          )}
          <OneColumn dates={chunks[0]} unit={unit} />
          <OneColumn dates={chunks[1]} unit={unit} />
          <OneColumn dates={chunks[2]} unit={unit} />
          <OneColumn dates={chunks[3]} unit={unit} />
          <OneColumn dates={chunks[4]} unit={unit} />
          <OneColumn dates={chunks[5]} unit={unit} />
        </Row>
      </div>
    </Modal>
  );
};

const OneColumn: FC<{dates: {value: number; date: string}[]; unit: string}> = ({dates, unit}) => {
  return (
    <StyledCol span={4}>
      {dates.map(date => {
        return (
          <StyledAttribute
            key={date.date}
            label={dayjs(date.date).format(DateTimeFormat.Date)}
            value={`${formatDateValue(date.value, unit)}`}
          />
        );
      })}
    </StyledCol>
  );
};

const StyledAttribute = styled(Attribute)`
  overflow: hidden;

  > span {
    margin-left: 6px;

    span {
      white-space: nowrap;
    }
  }
`;

const StyledCol = styled(Col)`
  padding-left: 4px !important;
  padding-right: 4px !important;
`;
