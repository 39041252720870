import React, {FC, Fragment, ReactNode} from 'react';
import {Button, Form, FormInstance, InputNumber, notification, Select} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {VesselConsumptionMode, VesselConsumptionModeUseCase, VesselInput} from '../../../VesselInformation/VesselTypes';
import {validateConsumption} from './validateConsumption';
import {validateSpeed} from './validateSpeed';
import {FuelTypeSelect} from '../../../VesselInformation/SpeedAndConsumtion/EditModal';
import {usePredictConsumptionsMutation} from './usePredictConsumptionsMutation';
import {parseIntTS} from '../../../../../../utils/parseNumberTS';
import {assert} from '../../../../../../utils/assert';
import {
  PredictedConsumption,
  PredictedConsumptionsResponse,
  PredictionInputVessel,
} from '../../../../../../api/node-backend/generated';
import {isApiError} from '../../../../../../api/utils/ApiError';
import {transformVesselConsumptionModesFromPredictedConsumptions} from './transformVesselConsumptionModesFromPredictedConsumptions';
import IconButton from '../../../../../../atoms/IconButton';

const defaultValue: VesselConsumptionMode = {
  useCase: 'laden',
  speed: 0,
  mainConsumption: 0,
  auxConsumption: 0,
  auxFuelType: 'mgo',
  mainFuelType: 'ifo',
};

const getArrangement = (consumptionsResponse: PredictedConsumptionsResponse): PredictionInputVessel | undefined => {
  // It is possible that we don't have a prediction for every situation.
  // We need to find any prediction that is not null, if possible.
  // The order is not important.
  const result =
    consumptionsResponse.ladenSpeed14 ??
    consumptionsResponse.ladenSpeed8 ??
    consumptionsResponse.ballastSpeed8 ??
    consumptionsResponse.ballastSpeed14 ??
    consumptionsResponse.portIdle ??
    consumptionsResponse.portWorking;

  return result?.arrangement;
};

export const ConsumptionsFormItem: FC<{form: FormInstance<VesselInput>; imo?: string}> = ({form, imo}) => {
  const predictConsumptionMutation = usePredictConsumptionsMutation({
    onSettled: result => {
      assert(result);
      const newConsumptions: VesselConsumptionMode[] = transformVesselConsumptionModesFromPredictedConsumptions(
        Object.values(result) as PredictedConsumption[]
      );
      form.setFieldsValue({
        consumptionModes: [...newConsumptions],
        dwat: getArrangement(result)?.dwtSummer,
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      form.validateFields();
    },
    onError: error => {
      if (isApiError(error) && error.status === 404) {
        notification.error({
          message: 'No consumptions for this vessel.',
          description:
            'Unfortunately, we could not predict consumption data for this vessel. We are continuously increasing our database.',
        });
        return;
      }
      throw error;
    },
  });

  return (
    <Form.List
      rules={[
        {
          validator: (_, value) => {
            const useCases = value.map((mode: VesselConsumptionMode) => mode.useCase);

            const need: VesselConsumptionModeUseCase[] = ['laden', 'ballast'];

            if (!need.every(useCase => useCases.includes(useCase))) {
              return Promise.reject(`You need at least one consumption for Laden and Ballast cases.`);
            }
            return Promise.resolve();
          },
        },
      ]}
      name="consumptionModes">
      {(fields, {add, remove}, {errors}) => {
        return (
          <>
            <GridLayout>
              <GridRow>
                <ModeCell>
                  <Header>Mode</Header>
                </ModeCell>
                <SpeedCell>
                  <Header>Speed</Header>
                </SpeedCell>
                <MainConsumptionCell>
                  <Header>Main consumption</Header>
                </MainConsumptionCell>
                <AuxConsumptionCell>
                  <Header>Auxiliary consumption</Header>
                </AuxConsumptionCell>
              </GridRow>
              {fields.map((field, index) => (
                <GridRow key={field.name}>
                  <ModeCell>
                    <StyledFormItem
                      wrapperCol={{span: 24}}
                      rules={[{required: true, message: 'Use case is required.'}]}
                      name={[field.name, 'useCase']}>
                      <Select<VesselConsumptionModeUseCase>
                        data-cy="consumptionModeSelect"
                        onChange={async value => {
                          if (['portidle', 'portworking'].includes(value)) {
                            // portidle and portworking have no speed
                            form.setFieldValue(['consumptionModes', field.name, 'speed'], 0);
                          }
                          void form.validateFields();
                        }}
                        style={{width: '100%'}}>
                        <Select.Option value={'ballast'}>Ballast</Select.Option>
                        <Select.Option value={'laden'}>Laden</Select.Option>
                        <Select.Option value={'portworking'}>Port working</Select.Option>
                        <Select.Option value={'portidle'}>Port idle</Select.Option>
                      </Select>
                    </StyledFormItem>
                  </ModeCell>
                  <SpeedCell>
                    <StyledFormItem
                      shouldUpdate={(prevValues, curValues) => {
                        const prevUseMode = (prevValues as VesselInput).consumptionModes[field.name]?.['useCase'];
                        const currentUseMode = (curValues as VesselInput).consumptionModes[field.name]?.['useCase'];
                        return currentUseMode !== prevUseMode;
                      }}>
                      {() => {
                        const isInPort = form
                          .getFieldValue(['consumptionModes', field.name, 'useCase'])
                          .includes('port');
                        return (
                          <StyledFormItem
                            hidden={isInPort}
                            wrapperCol={{span: 24}}
                            name={[field.name, 'speed']}
                            rules={[{required: !isInPort, message: 'Speed is required.'}, validateSpeed]}>
                            <InputNumber min={0} data-cy="speedInput" style={{width: '100%'}} />
                          </StyledFormItem>
                        );
                      }}
                    </StyledFormItem>
                  </SpeedCell>
                  <MainConsumptionCell>
                    <ConsumptionDiv>
                      <StyledFormItem rules={[validateConsumption]} name={[field.name, 'mainConsumption']}>
                        <InputNumber min={0} data-cy="mainConsumptionInput" />
                      </StyledFormItem>
                      <StyledFormItem rules={[]} name={[field.name, 'mainFuelType']}>
                        <FuelTypeSelect data-cy="mainFuelTypeSelect" />
                      </StyledFormItem>
                    </ConsumptionDiv>
                  </MainConsumptionCell>
                  <AuxConsumptionCell>
                    <ConsumptionDiv>
                      <StyledFormItem rules={[validateConsumption]} name={[field.name, 'auxConsumption']}>
                        <InputNumber min={0} data-cy="auxConsumptionInput" />
                      </StyledFormItem>
                      <StyledFormItem rules={[]} name={[field.name, 'auxFuelType']}>
                        <FuelTypeSelect data-cy="auxFuelTypeSelect" />
                      </StyledFormItem>
                    </ConsumptionDiv>
                  </AuxConsumptionCell>
                  <IconCell>
                    <IconContainer>
                      <IconButton
                        type={'scrap'}
                        iconStyle={{cursor: 'pointer'}}
                        iconSize={'medium'}
                        onClick={() => {
                          remove(index);
                          void form.validateFields();
                        }}
                        title={'Remove'}
                      />
                    </IconContainer>
                  </IconCell>
                </GridRow>
              ))}
              <GridRow>
                <AddConsumptionCelll>
                  <AddButton
                    data-cy="addButton"
                    type="dashed"
                    onClick={() => add(defaultValue)}
                    block
                    icon={<PlusOutlined />}>
                    Add consumption
                  </AddButton>
                </AddConsumptionCelll>
                <AuxConsumptionCell>
                  <AddButton
                    data-cy="predict"
                    disabled={predictConsumptionMutation.isPending || !imo}
                    type="dashed"
                    onClick={() => {
                      predictConsumptionMutation.mutate(parseIntTS(imo!));
                    }}
                    block>
                    Predict
                  </AddButton>
                </AuxConsumptionCell>
              </GridRow>
            </GridLayout>
            {errors && <Errors data-cy="errors">{errors}</Errors>}
          </>
        );
      }}
    </Form.List>
  );
};

const AddButton = styled(Button)`
  margin-top: 1px;
`;
const StyledFormItem = styled(Form.Item)`
  flex: 1;
  margin: 0;

  .ant-form-item-control {
    max-width: 100%;
  }
`;

const ConsumptionDiv = styled.div`
  display: flex;
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: [mode] 1fr [colum-gap-1] 20px [speed] 0.5fr [column-gap-2] 20px [main-consumption] 1fr [column-gap-3] 20px [aux-consumption] 1fr [column-gap-4] 10px [icon] 20px;
  row-gap: 9px;
`;

const GridRow: FC<{children: ReactNode}> = ({children}) => <>{children}</>;

const ModeCell = styled.div`
  grid-column: mode;
`;

const SpeedCell = styled.div`
  grid-column: speed;
`;

const MainConsumptionCell = styled.div`
  grid-column: main-consumption;
`;

const AuxConsumptionCell = styled.div`
  grid-column: aux-consumption;
`;

const IconCell = styled.div`
  grid-column: icon;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;
  height: 100%;
`;

const AddConsumptionCelll = styled.div`
  grid-column: mode / column-gap-3;
`;

const Header = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: -4px;
`;

const Errors = styled.div`
  margin-top: 4px;
  color: var(--color-red);
`;
