import {FC, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from '../../redux/react-redux';
import {SmartFavIcon} from './FavIcon/SmartFavIcon';
import {FullPageFooter} from './FullPageFooter';
import {FullPageHeader} from './FullPageHeader/FullPageHeader';
import {MainContentArea} from './MainContentArea';
import {Sidebar} from './Sidebar/Sidebar';
import {getHasProPlan} from '../../redux/selectors';

export const windowHeaderHeight = 72;

export const FullLayout: FC<{children: React.ReactNode}> = ({children}) => {
  const hasProPlan = useSelector(getHasProPlan);
  const [promoBarVisible, setPromoBarVisible] = useState(!hasProPlan);
  return (
    <>
      <SmartFavIcon />
      <Wrapper className="FullPageLayout" $promoBarVisible={promoBarVisible}>
        <FullPageHeader promoBarVisible={promoBarVisible} onPromoBarDismissed={() => setPromoBarVisible(false)} />
        <Sidebar />
        <div className="userNotice layoutBoundary" style={{display: 'block'}}></div>
        <div className="pageNavigation">
          <div>
            <div id="breadcrumbs-portal" />
            <div id="action-bar-portal" />
          </div>
        </div>
        <div className="contentArea">
          <section id="main" role="main">
            <div className="layoutBoundary">
              <div id="content" className="content">
                <div>
                  <MainContentArea>{children}</MainContentArea>
                </div>
              </div>
            </div>
          </section>
          <FullPageFooter />
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div<{$promoBarVisible: boolean}>`
  --header-height: ${windowHeaderHeight}px;
  --promo-height: 24px;

  --top-offset: ${({$promoBarVisible}) =>
    $promoBarVisible ? 'calc(var(--header-height) + var(--promo-height))' : 'var(--header-height)'};

  height: 100%;
  display: flex;
  flex-direction: column;

  .contentArea {
    flex: 1 0 auto;
    padding: 10px 96px 60px 96px;
    transition: padding 0.1s;

    > .layoutBoundary {
      position: relative;
      max-width: 1440px;
      padding: 0;
      background-color: var(--color-white);
    }
  }

  .pageNavigation {
    margin-top: var(--top-offset);

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
