import {Select, SelectProps} from 'antd';
import React, {FC} from 'react';
import {NegotiationTermType} from '../../../../../../../api/node-backend/generated';
import {TermTypeLabel} from './TermTypeLabel';
import {termTypeLabels} from './termTypeLabels';
import styled from 'styled-components';
import Icon from '../../../../../../../atoms/Icon';

const EmptyValue = 'none';

type TermTypeSelectProps = Omit<SelectProps, 'options' | 'value' | 'defaultValue' | 'onChange'> & {
  termType: NegotiationTermType | null;
  onTermTypeChange?: (newTermType: NegotiationTermType | null) => void;
};

export const TermTypeSelect: FC<TermTypeSelectProps> = ({termType, onTermTypeChange, ...selectProps}) => {
  return (
    <StyledSelect<NegotiationTermType | typeof EmptyValue>
      data-cy="TermTypeSelect"
      size="small"
      popupMatchSelectWidth={200}
      {...selectProps}
      defaultValue={termType ?? EmptyValue}
      value={termType ?? EmptyValue}
      onChange={value => onTermTypeChange?.(value === EmptyValue ? null : (value as NegotiationTermType))}
      onClick={event => event.stopPropagation()}
      options={[
        {
          value: EmptyValue,
          label: (
            <Placeholder>
              <Icon type="angle-down" />
              <span>Term type</span>
            </Placeholder>
          ),
        },
        ...Object.keys(termTypeLabels).map(termTypeOption => ({
          value: termTypeOption,
          label: <StyledTermTypeLabel termType={termTypeOption as NegotiationTermType} />,
        })),
      ]}
    />
  );
};

const StyledSelect = styled(Select)`
  --element-height-sm: 31px;
  --border-radius-button: var(--border-radius);
  --font-size-md: var(--font-size-sm);
  --border-base: none;
  --box-shadow-inset-input: none;

  flex-shrink: 0;
  margin-right: 5px;

  > div {
    padding: 0 10px !important;
  }
  > span {
    display: none !important;
  }
  .ant-select-selection-item {
    padding-right: 0 !important;
  }
  .ant-select-selector {
    background: var(--term-color-background) !important;
    transition: background-color 0.1s !important;
  }
  &.ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }
  &.ant-select-disabled .ant-select-selector {
    background: var(--color-gray-6) !important;
  }
` as typeof Select;

const Placeholder = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--color-gray-2);
`;

export const StyledTermTypeLabel = styled(TermTypeLabel)`
  font-weight: bold;
  margin-right: 5px;
`;

export const InlineTermTypeSelect = styled(TermTypeSelect).attrs({
  bordered: false,
})`
  --element-height-sm: 17px;

  display: inline-block;
  position: relative;
  top: 1px;
  left: -3px;
  height: 16px !important;
  margin-right: 3px;
  font-size: var(--font-size-md);

  > div {
    padding: 0 !important;
  }

  ${StyledTermTypeLabel} {
    .icon {
      position: relative;
      top: 0;
    }
    margin-right: 0;
    gap: 3px;
  }
`;
