import {VoyagePoint} from '../../VoyageInformation/VoyageTypes';

type Params = {
  voyagePoints: VoyagePoint[];
  /**
   * the index at voyage.points where the source voyage point is. after this index, the new voyage points will be added.
   */
  indexVoyagePoint: number;
};

/**
 * Add a new voyage points from the alternative ports of one voyage point   to the voyage input
 */
export const addAlternativePortsFormVoyagePointToVoyagePoints = (params: Params): VoyagePoint[] => {
  const selectedVoyagePoint = params.voyagePoints[params.indexVoyagePoint];

  const newPointsFormAlternativePorts: VoyagePoint[] = (selectedVoyagePoint.alternativePorts ?? []).map(port => {
    return {
      ...selectedVoyagePoint,
      ...port,
      alternativePorts: undefined,
    };
  });

  if (!newPointsFormAlternativePorts) {
    return params.voyagePoints;
  }
  const points: VoyagePoint[] = [...params.voyagePoints];

  points.splice(params.indexVoyagePoint, 1, ...newPointsFormAlternativePorts);

  return points;
};
