import {Coordinates} from '../../../utils/Coordinates';
import mapIconMarker from './map-icon-marker.svg';
import mapIconVessel from './map-icon-vessel.svg';

export const mapIcons = {
  marker: {
    getSize: () => 32,
    getIcon: () => 'marker',
    iconAtlas: mapIconMarker,
    iconMapping: {
      marker: {x: 0, y: 0, width: 24, height: 24, anchorY: 24, mask: true},
    },
  },
  vessel: {
    getSize: () => 32,
    getIcon: () => 'vessel',
    iconAtlas: mapIconVessel,
    iconMapping: {
      marker: {x: 0, y: 0, width: 4096, height: 4096, anchorY: 2048, mask: true},
    },
  },
};

export const getBounds = (coords: (Coordinates | undefined)[]) => {
  const latIdx = 1,
    lngIdx = 0;

  const lats = coords.filter(coord => !!coord).map(coord => coord![latIdx]);
  const lngs = coords.filter(coord => !!coord).map(coord => coord![lngIdx]);

  return {
    minLat: Math.min(...lats),
    maxLat: Math.max(...lats),
    minLng: Math.min(...lngs),
    maxLng: Math.max(...lngs),
  };
};
