import {FuelType} from '../VesselInformation/VesselTypes';
import {assertUnreachable} from '../../../../utils/assert';

export type ConsumptionFuelType = 'ifo' | 'mgo' | 'vlsfo';

export const getUsedFuelType = ({
  isScrubberFitted,
  isSECA,
  fuelType,
}: {
  isScrubberFitted: boolean;
  isSECA: boolean;
  fuelType: FuelType;
}): ConsumptionFuelType => {
  switch (fuelType) {
    // Fallthrough
    case 'mdo':
    case 'mgo':
      return 'mgo';
    case 'ifo':
      if (isScrubberFitted) {
        return 'ifo';
      }
      if (isSECA) {
        return 'mgo';
      }
      return 'vlsfo';
    default:
      assertUnreachable(fuelType);
  }
};
