/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CargoOffersForLocationResponseDataCargoType
 */
export interface CargoOffersForLocationResponseDataCargoType {
  /**
   *
   * @type {number}
   * @memberof CargoOffersForLocationResponseDataCargoType
   */
  container: number;
  /**
   *
   * @type {number}
   * @memberof CargoOffersForLocationResponseDataCargoType
   */
  drybulk: number;
}

export function CargoOffersForLocationResponseDataCargoTypeFromJSON(
  json: any
): CargoOffersForLocationResponseDataCargoType {
  return CargoOffersForLocationResponseDataCargoTypeFromJSONTyped(json, false);
}

export function CargoOffersForLocationResponseDataCargoTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CargoOffersForLocationResponseDataCargoType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    container: json['container'],
    drybulk: json['drybulk'],
  };
}

export function CargoOffersForLocationResponseDataCargoTypeToJSON(
  value?: CargoOffersForLocationResponseDataCargoType | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    container: value.container,
    drybulk: value.drybulk,
  };
}
