/* eslint-disable no-nested-ternary */
import MultiColAttributesSection from '../../VesselDetailScreen/sections/MultiColAttributesSection';
import DetailsSection from '../../../atoms/DetailsSection';
import ImageThumbnailGrid from '../../../components/ImageThumbnailGrid';
import DetailsColumn from '../../../atoms/DetailsSection/DetailsColumn';
import DateOpen from '../../../components/DateOpen';
import LocationOutput from '../../../components/LocationOutput';
import Card from '../../../components/Card/Card';
import DocumentsGrid from '../../../components/DocumentsGrid';
import DetailFeatures from '../../../components/DetailFeatures';
import {
  backendColumns,
  ConsumptionTable,
  dimensionsColumns,
  generalInfoColumns,
  IntakeBulker,
  IntakeContainer,
  IntakeMpp,
  ownershipColumns,
} from '../helper';

const checkInvalidVesselType = (type: $TSFixMe) => type === 'tanker' || type === 'other';

const Review = ({form, isBackend}: $TSFixMe) => {
  const {
    general,
    comment,
    dimensions,
    documents,
    features,
    images,
    intake,
    nextOpen,
    ownership,
    consumptions,
    backend,
  } = form;

  return (
    <Card isTransparent>
      <MultiColAttributesSection
        hasBorderBottom
        label={'General information'}
        columns={generalInfoColumns(general.vesselType)}
        vessel={general}
        size={12}
        tab={'l'}
      />
      {backend && isBackend && (
        <DetailsSection hasBorderBottom title={'Backend'}>
          {backendColumns(backend)}
        </DetailsSection>
      )}
      <MultiColAttributesSection
        hasBorderBottom
        label={'Dimensions'}
        columns={dimensionsColumns(general.vesselType)}
        vessel={dimensions}
        size={12}
        tab={'s'}
      />
      {general.vesselType && (
        <DetailsSection hasBorderBottom title={'Intake'}>
          {general.vesselType === 'bulker' ? (
            <IntakeBulker {...intake} />
          ) : general.vesselType === 'container' ? (
            <IntakeContainer {...intake} type={'vessel'} />
          ) : general.vesselType === 'mpp' ? (
            <IntakeMpp {...intake} type={'vessel'} />
          ) : null}
        </DetailsSection>
      )}
      {!checkInvalidVesselType(general.vesselType) && (
        <DetailsSection hasBorderBottom title={'Features'}>
          <DetailFeatures
            detailObject={features}
            iceClass={features.iceClass}
            segment={general.vesselType}
            type={'vessel'}
          />
        </DetailsSection>
      )}
      {!checkInvalidVesselType(general.vesselType) && (
        <DetailsSection hasBorderBottom title={'Speed and consumption'}>
          <ConsumptionTable {...consumptions} />
        </DetailsSection>
      )}
      <MultiColAttributesSection
        hasBorderBottom
        label={'Ownership'}
        columns={ownershipColumns}
        vessel={ownership}
        size={12}
        tab={'xxl'}
      />
      {!isBackend && (
        <DetailsSection title="Next open" hasBorderBottom>
          <DetailsColumn size={12}>
            <DateOpen
              label={'Date open'}
              from={nextOpen.nextOpenFrom}
              to={nextOpen.nextOpenTo}
              spotState={nextOpen.spotState}
              spotDate={new Date()}
            />
            <LocationOutput
              style={{marginTop: '50px'}}
              locations={nextOpen.stations && nextOpen.stations.length > 0 ? nextOpen.stations : []}
            />
          </DetailsColumn>
        </DetailsSection>
      )}
      <DetailsSection hasBorderBottom title="Note / comment">
        <DetailsColumn size={12}>
          <span className="vessel-content__text">{comment.comment || 'N/A'}</span>
        </DetailsColumn>
      </DetailsSection>
      <DetailsSection hasBorderBottom title={'Images'}>
        <ImageThumbnailGrid images={images.images || null} />
      </DetailsSection>
      <DetailsSection hasBorderBottom title={'Documents'}>
        <DocumentsGrid documents={documents.documents || null} />
      </DetailsSection>
    </Card>
  );
};

export default Review;
