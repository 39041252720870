/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UpdateUserOnboardingState
 */
export interface UpdateUserOnboardingState {
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserOnboardingState
   */
  isOnboarded?: boolean;
  /**
   * Onboarding skipped time
   * @type {Date}
   * @memberof UpdateUserOnboardingState
   */
  onboardingSkippedAt?: Date | null;
}

export function UpdateUserOnboardingStateFromJSON(json: any): UpdateUserOnboardingState {
  return UpdateUserOnboardingStateFromJSONTyped(json, false);
}

export function UpdateUserOnboardingStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateUserOnboardingState {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isOnboarded: !exists(json, 'isOnboarded') ? undefined : json['isOnboarded'],
    onboardingSkippedAt: !exists(json, 'onboardingSkippedAt')
      ? undefined
      : json['onboardingSkippedAt'] === null
      ? null
      : new Date(json['onboardingSkippedAt']),
  };
}

export function UpdateUserOnboardingStateToJSON(value?: UpdateUserOnboardingState | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isOnboarded: value.isOnboarded,
    onboardingSkippedAt:
      value.onboardingSkippedAt === undefined
        ? undefined
        : value.onboardingSkippedAt === null
        ? null
        : value.onboardingSkippedAt.toISOString(),
  };
}
