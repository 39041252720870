import dayjs from 'dayjs';
import {GetPortfolioVesselsRequest} from '../../../../api/symfony/generated/apis';
import {
  BaleVolumeUnit,
  BulkerDesignType,
  GrainVolumeUnit,
  LocationType,
  VesselFilterGearedOption,
  VesselStatus,
  VesselType,
} from '../../../../api/symfony/generated/models';
import {PortfolioVesselDatabaseFilter} from '../../../../components/FilterProvider/Filters/Portfolio/PortfolioVesselFilterBranchDefinitions';
import {Location} from '../../../../components/FilterProvider/Filters/utils/Location';
import {assertUnreachable} from '../../../../utils/assert';

type PortfolioVesselsFilter = Omit<
  GetPortfolioVesselsRequest,
  | 'pageSize'
  | 'filters'
  | 'pageIndex'
  | 'archive'
  | 'group'
  | 'groups'
  | 'newFilter'
  | 'sortField'
  | 'sortOrder'
  | 'ids'
>;

export const getFiltersForNewFilterRequest = ({
  filters,
}: {
  filters: PortfolioVesselDatabaseFilter;
}): PortfolioVesselsFilter => {
  if (filters === null) {
    return {};
  }

  let geared: VesselFilterGearedOption | undefined = undefined;
  if (filters.features?.geared !== undefined) {
    if (filters.features.geared) {
      geared = VesselFilterGearedOption.Geared;
    } else {
      geared = VesselFilterGearedOption.Gearless;
    }
  }

  let grainUnit: GrainVolumeUnit | undefined = undefined;
  if (filters.intake?.grainUnit !== undefined) {
    if (filters.intake?.grainUnit === 'cbm') {
      grainUnit = GrainVolumeUnit.Cbm;
    } else {
      grainUnit = GrainVolumeUnit.Cft;
    }
  }
  let baleUnit: BaleVolumeUnit | undefined = undefined;
  if (filters.intake?.baleUnit !== undefined) {
    if (filters.intake?.baleUnit === 'cbm') {
      baleUnit = BaleVolumeUnit.Cbm;
    } else {
      baleUnit = BaleVolumeUnit.Cft;
    }
  }

  let vesselStatuses: Array<VesselStatus> | undefined = undefined;

  if (filters.vesselStatuses?.statuses) {
    vesselStatuses = filters.vesselStatuses.statuses.map(status => {
      switch (status) {
        case 'active':
          return VesselStatus.Active;
        case 'drydock':
          return VesselStatus.Drydock;
        case 'employed':
          return VesselStatus.Employed;
        case 'inlayup':
          return VesselStatus.Inlayup;
        case 'onorder':
          return VesselStatus.Onorder;
        case 'scrapped':
          return VesselStatus.Scrapped;
        case 'yard':
          return VesselStatus.Yard;
        default:
          assertUnreachable(status);
          return VesselStatus.Yard;
      }
    });
  }
  const result = {
    // dateopen
    dateOpenFrom: filters.dateOpen?.from ? dayjs(filters.dateOpen.from).toDate() : undefined,
    dateOpenTo: filters.dateOpen?.to ? dayjs(filters.dateOpen.to).toDate() : undefined,
    spotState: filters.dateOpen?.spotState,
    spotOnly: transformBoolean(filters.dateOpen?.spotOnly),

    vesselTypes: filters.vesselTypes as VesselType[],

    // generalInformation
    name: filters.generalInformation?.name,
    imo: filters.generalInformation?.imo,
    minimumAge: filters.generalInformation?.minimumAge,
    maximumAge: filters.generalInformation?.maximumAge,
    flags: filters.generalInformation?.flags,
    includeFlags: filters.generalInformation?.includeFlags,

    // size
    deadweightFrom: filters.size?.deadWeight.from,
    deadweightTo: filters.size?.deadWeight.to,
    holdsFrom: filters.size?.holds.from,
    holdsTo: filters.size?.holds.to,
    hatchesFrom: filters.size?.hatches.from,
    hatchesTo: filters.size?.hatches.to,
    teuFrom: filters.size?.teu.from,
    teuTo: filters.size?.teu.to,
    teu14From: filters.size?.teu14.from,
    teu14To: filters.size?.teu14.to,
    bulkerDesignTypes: filters.size?.designs as BulkerDesignType[],
    containerDesignTypes: filters.size?.designTypes,

    // dimensions
    lengthFrom: filters.dimensions?.length.from,
    lengthTo: filters.dimensions?.length.to,
    beamFrom: filters.dimensions?.beam.from,
    beamTo: filters.dimensions?.beam.to,
    draftFrom: filters.dimensions?.draft.from,
    draftTo: filters.dimensions?.draft.to,
    grossTonnageFrom: filters.dimensions?.grossTonnage.from,
    grossTonnageTo: filters.dimensions?.grossTonnage.to,
    netTonnageFrom: filters.dimensions?.netTonnage.from,
    netTonnageTo: filters.dimensions?.netTonnage.to,
    tonnesPerCentimetreFrom: filters.dimensions?.tonnesPerCentimetre.from,
    tonnesPerCentimetreTo: filters.dimensions?.tonnesPerCentimetre.to,

    // intake
    grainVolumeFrom: filters.intake?.grainVolume.from,
    grainVolumeTo: filters.intake?.grainVolume.to,
    grainUnit,
    baleVolumeFrom: filters.intake?.baleVolume.from,
    baleVolumeTo: filters.intake?.baleVolume.to,
    baleUnit,
    reeferPlugsFrom: filters.intake?.reeferPlugs.from,
    reeferPlugsTo: filters.intake?.reeferPlugs.to,

    // features
    a60Bulkhead: transformBoolean(filters.features?.a60Bulkhead),
    cellular: transformBoolean(filters.features?.cellular),
    openHatched: transformBoolean(filters.features?.openHatched),
    boxShaped: transformBoolean(filters.features?.boxShaped),
    lakesFitted: transformBoolean(filters.features?.lakesFitted),
    itfFitted: transformBoolean(filters.features?.itfFitted),
    tweenDecks: transformBoolean(filters.features?.tweenDecks),
    bowThrusted: transformBoolean(filters.features?.bowThrusted),
    logFitted: transformBoolean(filters.features?.logFitted),
    sternThrusted: transformBoolean(filters.features?.sternThrusted),
    co2Fitted: transformBoolean(filters.features?.co2Fitted),
    scrubberFitted: transformBoolean(filters.features?.scrubberFitted),
    iceClass: transformBoolean(filters.features?.iceClass),
    geared,

    // position
    positionOpenPorts: getPositionOpenIdsPerType({
      stations: filters.positionOpen?.stations ?? [],
      type: LocationType.Port,
    }),
    positionOpenTradingAreas: getPositionOpenIdsPerType({
      stations: filters.positionOpen?.stations ?? [],
      type: LocationType.Tradingarea,
    }),
    positionOpenCountries: getPositionOpenIdsPerType({
      stations: filters.positionOpen?.stations ?? [],
      type: LocationType.Country,
    }),
    positionOpenCollections: getPositionOpenIdsPerType({
      stations: filters.positionOpen?.stations ?? [],
      type: LocationType.Collection,
    }),

    vesselStatuses,
    registeredOwner: filters.ownership?.registeredOwner,
    managingOwner: filters.ownership?.managingOwner,
    disponentOwners: filters.ownership?.disponentOwners,
  };

  const filtersWithoutEmptyArrays = filterEmptyArraysFromFilter(result);

  const filtersWithoutUndefined = filterUndefinedFromFilter(filtersWithoutEmptyArrays);
  return filtersWithoutUndefined;
};

const filterEmptyArraysFromFilter = (filter: PortfolioVesselsFilter): PortfolioVesselsFilter => {
  const newValue = {...filter};

  const keys = Object.keys(newValue);

  for (const key of keys) {
    const value = newValue[key as keyof PortfolioVesselsFilter];
    if (value instanceof Array && value.length === 0) {
      delete newValue[key as keyof PortfolioVesselsFilter];
    }
  }
  return newValue;
};
const filterUndefinedFromFilter = (filter: PortfolioVesselsFilter): PortfolioVesselsFilter => {
  const newValue = {...filter};

  const keys = Object.keys(newValue);

  for (const key of keys) {
    const value = newValue[key as keyof PortfolioVesselsFilter];
    if (value === undefined) {
      delete newValue[key as keyof PortfolioVesselsFilter];
    }
  }
  return newValue;
};

const getPositionOpenIdsPerType = ({
  stations,
  type,
}: {
  stations: Location[];
  type: LocationType.Port | LocationType.Tradingarea | LocationType.Country | LocationType.Collection;
}) => {
  const list = stations.filter(station => {
    return station.type === type;
  });

  return list.map(station => station.id);
};

const transformBoolean = (value: boolean | undefined): boolean | undefined => {
  if (value) {
    return true;
  }
  return undefined;
};
