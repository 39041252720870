import {Popconfirm, Tooltip} from 'antd';
import {FC, MouseEventHandler} from 'react';
import {FeaturePermission, FilterItem} from '../../../api/symfony/generated';
import {SharingIcon} from '../../../components/EasySharing/shared';
import {useIsPermitted} from '../../../utils/useIsPermitted';

type EasySharingTogglesProps = {
  isActiveTab: boolean;
  tab: FilterItem;
  isOutboxRemovalCritical: boolean;
  onOutboxChange?: (tab: FilterItem) => Promise<void>;
};

export const EasySharingToggles: FC<EasySharingTogglesProps> = ({
  isActiveTab,
  tab,
  isOutboxRemovalCritical,
  onOutboxChange,
}) => {
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);

  if (!manageOwnCompanyPermitted) {
    return (
      <span>
        <Tooltip title="Please ask a company administrator to change Easy Sharing settings for this workspace">
          <div>
            <SharingIcon type="outbox" $isActive={tab.outbox} $isActiveTab={isActiveTab} />
          </div>
        </Tooltip>
      </span>
    );
  }

  const captureClick: MouseEventHandler = event => {
    event.stopPropagation();
  };

  const toggleOutbox = async () => {
    await onOutboxChange?.(tab);
  };

  if (tab.outbox) {
    return (
      <span onClick={captureClick}>
        <Popconfirm
          trigger="click"
          overlayInnerStyle={{maxWidth: isOutboxRemovalCritical ? 350 : 250, padding: '10px 20px 10px 10px'}}
          title="Do you really want to deactivate outbox for this submarket?"
          description={
            isOutboxRemovalCritical
              ? 'This is your last outbox. If you deactivate it you will not receive shared circulars anymore.'
              : ''
          }
          onConfirm={toggleOutbox}>
          <div>
            <SharingIcon type="outbox" $isActive={tab.outbox} $isActiveTab={isActiveTab} />
          </div>
        </Popconfirm>
      </span>
    );
  }

  return (
    <span onClick={captureClick}>
      <Popconfirm
        trigger="click"
        overlayInnerStyle={{maxWidth: 350, padding: '10px 20px 10px 10px'}}
        title="Do you really want to activate outbox for this submarket?"
        description={
          <>
            <Tooltip title="Only circulars with a parsing score of 'Good' or 'Medium' are applicable">
              <span style={{color: 'var(--color-blue)'}}>All applicable circulars</span>
            </Tooltip>{' '}
            in this submarket will be shared with seabo users. <br />
            You can deactivate this feature at any time, but once shared circulars will remain shared.
          </>
        }
        onConfirm={toggleOutbox}>
        <div>
          <SharingIcon type="outbox" $isActive={tab.outbox} $isActiveTab={isActiveTab} />
        </div>
      </Popconfirm>
    </span>
  );
};
