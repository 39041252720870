import {AccountSegments} from '../../../../api/symfony/generated';

export type Step = 'userSelfCategorization' | 'segment' | 'sizes' | 'commodities' | 'tradingAreas';

export const getOkButtonDisabled = ({currentStep, segmentData}: {currentStep: Step; segmentData: AccountSegments}) => {
  switch (currentStep) {
    case 'sizes':
      return segmentData.sizes?.length === 0;
    case 'commodities':
      return segmentData.commodities?.length === 0;
    case 'tradingAreas':
      return segmentData.tradingAreas?.length === 0;
    case 'userSelfCategorization':
      return segmentData.userSelfCategorization?.length === 0;
    case 'segment':
      return segmentData.types?.length === 0;
  }
  return false;
};
