/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Bulk vessel designs
 * @export
 * @enum {string}
 */
export enum BulkVesselDesignEnum {
  Minibulker = 'minibulker',
  Handysize = 'handysize',
  Handymax = 'handymax',
  Supramax = 'supramax',
  Ultramax = 'ultramax',
  Panamax = 'panamax',
  Kamsarmax = 'kamsarmax',
  Postpanamax = 'postpanamax',
  Capesize = 'capesize',
}

export function BulkVesselDesignEnumFromJSON(json: any): BulkVesselDesignEnum {
  return BulkVesselDesignEnumFromJSONTyped(json, false);
}

export function BulkVesselDesignEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkVesselDesignEnum {
  return json as BulkVesselDesignEnum;
}

export function BulkVesselDesignEnumToJSON(value?: BulkVesselDesignEnum | null): any {
  return value as any;
}
