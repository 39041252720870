import {Button} from 'antd';
import React, {FC, useState} from 'react';
import {SaveContractTemplateModal} from './SaveContractTemplateModal';
import {
  CreateOrUpdateNegotiationTemplateDTO,
  NegotiationRoundResult,
  NegotiationSummary,
} from '../../../../../../../api/node-backend/generated';
import {ButtonProps} from 'antd/es';
import {message} from '../../../../../../../components/Notification';

type SaveContractTemplateModalButtonProps = ButtonProps & {
  negotiation: NegotiationSummary;
  roundResult: NegotiationRoundResult;
  disabled?: boolean;
  onSaveContactTemplate: (contractTemplate: CreateOrUpdateNegotiationTemplateDTO, onSuccess: () => void) => void;
};

export const SaveContractTemplateModalButton: FC<SaveContractTemplateModalButtonProps> = ({
  negotiation,
  roundResult,
  disabled,
  onSaveContactTemplate,
  ...buttonProps
}) => {
  const [showModal, setShowModal] = useState(false);

  const onFinish = async (contractTemplate: CreateOrUpdateNegotiationTemplateDTO) => {
    onSaveContactTemplate(contractTemplate, () => {
      void message.success('Template saved');
      setShowModal(false);
    });
  };

  return (
    <>
      <SaveContractTemplateModal
        negotiation={negotiation}
        roundResult={roundResult}
        open={showModal}
        onClose={() => setShowModal(false)}
        onFinish={onFinish}
      />
      <Button size="small" block {...buttonProps} onClick={() => setShowModal(true)} disabled={disabled}>
        Save current terms as template
      </Button>
    </>
  );
};
