import React, {useEffect} from 'react';
import BindToProvider, {WrappedDetailsSection} from '../../../components/FormProvider/BindToProvider';
import LabelWrapper from '../../../atoms/LabelWrapper';
import Input from '../../../atoms/Input';
import {stringLengthLessThanOrEqualTo} from '../../../utils/validators';
import autocomplete from '../../../redux/ApiService/autocomplete';
import {connect} from 'react-redux';
import {RootState} from '../../../redux/store';
import {AsyncCreateable} from '../../../atoms/Select/AsyncCreateable';

const Wrapper = BindToProvider(
  'Ownership',
  'ownership',
  {
    managementHeadOwner: '',
    managementManagingOwner: '',
    managementDisponentOwner: '',
  },
  {
    managementHeadOwner: stringLengthLessThanOrEqualTo(127),
    managementManagingOwner: stringLengthLessThanOrEqualTo(127),
    managementDisponentOwner: stringLengthLessThanOrEqualTo(127),
  },
  {
    toApi: (fields: $TSFixMe) => ({
      ...fields,
      managementDisponentOwner: fields.managementDisponentOwner ? fields.managementDisponentOwner.label : '',
    }),
    fromApi: (fields: $TSFixMe) => ({
      ...fields,

      managementDisponentOwner: fields.managementDisponentOwner
        ? {label: fields.managementDisponentOwner, value: fields.managementDisponentOwner}
        : '',
    }),
  },
  true
)(WrappedDetailsSection);

const Ownership = (props: $TSFixMe) => {
  const {preFillCharterer} = props;

  useEffect(() => {
    preFillCharterer();
  }, [preFillCharterer]);

  return (
    <Wrapper {...props}>
      {({
        onChange,
        values,
        validations,
        validate,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        form,
      }: $TSFixMe) => (
        <div className="container-fluid">
          <div className="row">
            <div className="scol-12 scol-lg-4">
              <LabelWrapper
                label={'Commercial manager'}
                htmlFor={'form-ownership-head-owner'}
                hasError={validations.managementHeadOwner.invalid}>
                <Input
                  id={'form-ownership-head-owner'}
                  onChange={value => onChange('managementHeadOwner', value)}
                  value={values.managementHeadOwner}
                  hasError={validations.managementHeadOwner.invalid}
                  maxLength={127}
                />
              </LabelWrapper>
              {validations.managementHeadOwner.error && (
                <p className="text-danger text-danger--no-margin">{validations.managementHeadOwner.error}</p>
              )}
            </div>
            <div className="scol-12 scol-lg-4">
              <LabelWrapper
                label={'Owner'}
                htmlFor={'form-ownership-managing-owner'}
                hasError={validations.managementManagingOwner.invalid}>
                <Input
                  id={'form-ownership-managing-owner'}
                  onChange={value => onChange('managementManagingOwner', value)}
                  value={values.managementManagingOwner}
                  hasError={validations.managementManagingOwner.invalid}
                  maxLength={127}
                />
              </LabelWrapper>
              {validations.managementManagingOwner.error && (
                <p className="text-danger text-danger--no-margin">{validations.managementManagingOwner.error}</p>
              )}
            </div>
            <div className="scol-12 scol-lg-4">
              <LabelWrapper
                label={'Charterer / Disponent owner'}
                htmlFor={'form-ownership-disponent-owner'}
                hasError={validations.managementDisponentOwner.invalid}>
                <AsyncCreateable
                  id={`form-ownership-disponent-owner`}
                  name={`form-ownership-disponent-owner`}
                  value={values.managementDisponentOwner}
                  onChange={(value: $TSFixMe) =>
                    onChange('managementDisponentOwner', value, () => validate('managementDisponentOwner'))
                  }
                  getOptionLabel={(option: $TSFixMe) => option.label}
                  getOptionValue={(option: $TSFixMe) => option.label}
                  loadOptions={(q: $TSFixMe) => (q.length > 1 ? props.searchCharterer(q) : Promise.resolve([]))}
                  defaultOptions={
                    props.preFillChartererData.map((p: $TSFixMe) => ({
                      value: p.slug,
                      label: p.name,
                    })) || []
                  }
                  isClearable
                  createLabel={(value: $TSFixMe) => value}
                  createOptionPosition={'first'}
                  placeholder={'Type to search...'}
                  loadingMessage={() => 'Searching ...'}
                  onBlur={() => validate('managementDisponentOwner')}
                  hasError={validations.managementDisponentOwner.invalid}
                  maxLength={127}
                />
              </LabelWrapper>
              {validations.managementDisponentOwner.error && (
                <p className="text-danger text-danger--no-margin">{validations.managementDisponentOwner.error}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const mapStateToProps = ({api}: RootState) => ({
  preFillChartererData: api.autoComplete.preFillCharterer.data?.data?.items || [],
});

const mapDispatchToProps = (dispatch: $TSFixMe) => ({
  searchCharterer: (query: $TSFixMe) => dispatch(autocomplete.searchCharterer(query)),
  preFillCharterer: () => dispatch(autocomplete.preFillCharterer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ownership);
