import {useNavigate} from 'react-router-dom';
import {Assumptions} from '../types';
import {updateUrlSearchParamsWithAssumptions} from './updateUrlSearchParamsWithAssumptions';

type Params = {
  cargoIds?: number[];
  projectIds?: number[];
  vesselIds?: number[];
  assumptions?: Assumptions;
};
type GoToMultiCalc = (params: Params) => void;

const generateURL = (params: Params) => {
  const {assumptions} = params;
  const urlParams = new URLSearchParams();
  urlParams.set('cargoIds', (params.cargoIds ?? []).join(','));
  urlParams.set('vesselIds', (params.vesselIds ?? []).join(','));
  urlParams.set('projectIds', (params.projectIds ?? []).join(','));

  if (assumptions) {
    updateUrlSearchParamsWithAssumptions(urlParams, assumptions);
  }

  return `/chartercalculator/multi?${urlParams.toString()}`;
};

export const useGoToMultiCalc = (): GoToMultiCalc => {
  const navigate = useNavigate();

  return params => navigate(generateURL(params));
};
