import Checkbox from '../../../../atoms/Checkbox';
import {cleanIdentifier} from '../../../../utils/helper';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './VesselTypeDefinition';
import {Space} from 'antd';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

const VesselType = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values}) => (
      <div className="container">
        <Space direction="vertical" size={12}>
          <Checkbox
            id={cleanIdentifier('MPP', 'filter-option')}
            checked={values.mpp}
            label={'MPP'}
            onChange={e => handleChange('mpp', e.target.checked)}
          />
          <Checkbox
            id={cleanIdentifier('Bulker', 'filter-option')}
            checked={values.bulker}
            label={'Bulker'}
            onChange={e => handleChange('bulker', e.target.checked)}
          />
          <Checkbox
            id={cleanIdentifier('Container', 'filter-option')}
            checked={values.container}
            label={'Container'}
            onChange={e => handleChange('container', e.target.checked)}
          />
        </Space>
      </div>
    )}
  </FilterButton>
);

export default VesselType;
