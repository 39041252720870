import {LocationAdditionalInfo} from '../../../CargoPicker/CargoPicker';
import {assertUnreachable} from '../../../../utils/assert';

/*
  pwwd = Per Weather Working Day
    Returns the loading or discharge rate per day, but only if the weather is good and it's a working day.
 */
export const getPwwdRateInMt = ({
  additionalInfo,
  quantityInMt,
  stowageFactor,
}: {
  additionalInfo?: LocationAdditionalInfo;
  quantityInMt: number;
  /**
   * m3/MT or  ft3/MT
   */
  stowageFactor: number;
}): number | undefined => {
  if (!additionalInfo?.rate) {
    return undefined;
  }

  switch (additionalInfo.unit) {
    case undefined:
    // Fallthrough
    case 'mt':
      return additionalInfo.rate;
    case 'day':
      return Math.round(quantityInMt / additionalInfo.rate);
    case 'cft': {
      const rateForCbm = calculatePwwdRateCbm({rate: additionalInfo.rate, stowageFactor});

      const convertedRate = 35.3147;

      return Math.round(rateForCbm * convertedRate);
    }

    case 'cbm':
      return Math.round(calculatePwwdRateCbm({rate: additionalInfo.rate, stowageFactor}));
    default:
      assertUnreachable(additionalInfo.unit);
  }
};

const calculatePwwdRateCbm = ({rate, stowageFactor}: {rate: number; stowageFactor: number}): number => {
  /*
    We need MT, MT = rate / stowageFactor
    MT = (m3) / (m3/MT) = m3 * MT/m3
   */

  return rate / stowageFactor;
};
