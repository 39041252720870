import {CompositeLayer} from '@deck.gl/core/typed';
import {BitmapLayer} from '@deck.gl/layers/typed';
import {GeoBoundingBox, TileLayer} from '@deck.gl/geo-layers/typed';
import {assert} from '../../../utils/assert';
import {TODO} from '../../../utils/TODO';
import {FeaturePermission} from '../../../api/symfony/generated';
import {useIsPermitted} from '../../../utils/useIsPermitted';

export const useWatermarkLayer = (): WatermarkLayer[] => {
  const isViewMapWithoutWatermarkPermitted = useIsPermitted(FeaturePermission.ViewMapWithoutWatermark);
  return isViewMapWithoutWatermarkPermitted ? [] : [new WatermarkLayer({})];
};

class WatermarkLayer extends CompositeLayer<TODO> {
  static layerName = 'WatermarkLayer';
  renderLayers() {
    return [
      new TileLayer({
        data: '/static/images/seanexxt/map-watermark.png',
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: props => {
          assertGeoBoundingBox(props.tile.bbox);
          const {
            bbox: {west, south, east, north},
          } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: [west, south, east, north],
          });
        },
      }),
    ];
  }
}
function assertGeoBoundingBox(value: unknown): asserts value is GeoBoundingBox {
  assert(value && typeof value === 'object' && 'west' in value);
}
