import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {BreadcrumbItem} from '../../atoms/Breadcrumb/BreadcrumbItem';
import {NotFound} from '../../atoms/EmptyState/404';
import LoadingComponent from '../../components/DataGrid/LoadingComponent';
import {useGetCargoQuery} from '../../queries/useGetCargoQuery';
import cargoService from '../../redux/ApiService/cargoService';
import {ModalActions} from '../../redux/Modal';
import {useDispatch} from '../../redux/react-redux';
import {TODO} from '../../utils/TODO';
import {
  cargoGeneralInfoColumns,
  cargoGeneralInfoColumnsVc,
  cargoVesselFeatures,
  commissionSection,
  commoditySection,
  commoditySectionVc,
  quantitySection,
} from '../CargoVesselForm/helper';
import {MarketCargo} from './MarketCargo';
import {MyCargoesCargo} from './MyCargoesCargo';
import ManageFixtureModal from '../../components/Modal/ManageFixture/ManageFixtureModal';
import {ClonePortfolioEntry} from '../../components/Modal/ClonePortfolioEntry';
import {AddToProject} from '../../components/Modal/AddToProject/AddToProject';
import {Delete} from '../../components/Modal/Delete/DeleteModal';

export const CargoDetailScreen = () => {
  const [isNotFound, setIsNotFound] = useState(false);
  const navigate = useNavigate();
  const params = useParams<'id'>();
  const id = params.id ? parseInt(params.id) : undefined;
  const dispatch = useDispatch();

  const showModal = (modal: ReactNode) => dispatch(ModalActions.show(modal));

  const deleteCargo = (cargoId: number) => dispatch(cargoService.deleteCargo(cargoId));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {data, isSuccess, isFetching, isError} = useGetCargoQuery({
    cargoId: id!,
    options: {enabled: !!id},
  });

  const cargo = data;
  const isLoading = !isSuccess || isFetching;

  const update = useCallback(() => {
    if (isNotFound) {
      setIsNotFound(false);
    }
  }, [isNotFound]);

  useEffect(() => {
    if (isSuccess && data) {
      update();
    }
  }, [isSuccess, data, update]);

  useEffect(() => {
    if (isError) {
      setIsNotFound(true);
    }
  }, [isError]);

  const _handleProjectClick = () => {
    showModal(<AddToProject type="cargo" id={cargo!.id} />);
  };

  const _handleEditClick = () => {
    navigate(`/cargo/${cargo?.id}/edit`);
  };

  const _handleCloneClick = (cargo: TODO) => {
    showModal(
      <ClonePortfolioEntry type="cargo" cargo={cargo} buttonText="Add to My Cargoes" title="Add to My Cargoes" />
    );
  };

  const _handleDeleteClick = (cargo: TODO) => {
    showModal(
      <Delete
        target={{
          name: cargo.chartererName || 'TBN',
          id: cargo.id,
        }}
        onDelete={async (id: TODO, callback: (param: {errors: TODO; bonusMsg: string}) => void, close: () => void) => {
          try {
            await deleteCargo(id);
            close();
            navigate('/my-cargoes');
          } catch (error: TODO) {
            const errors: TODO = [];
            if (error.payload && error.payload.error && error.payload.error.errors) {
              error.payload.error.errors.forEach((err: TODO) => {
                const {type, id, name} = err.data;
                const link = type === 'project' ? `/${type}s/${id}` : `/${type}s/${name}`;
                errors.push({title: type, msg: name, link});
              });
              const bonusMsg =
                'The vessel you want to delete is currently used in projects. Please delete the\nfollowing projects first.';
              callback({errors, bonusMsg});
            }
          }
        }}
      />
    );
  };

  const vesselFeatures = useMemo(() => (cargo ? cargoVesselFeatures(cargo.vesselType) : []), [cargo]);

  if (isNotFound) {
    return <NotFound title={'Cargo not found'} />;
  }
  if (isLoading || !cargo) {
    return <LoadingComponent style={{textAlign: 'center'}} />;
  }

  let breadcrumbs: BreadcrumbItem[] = [];
  switch (cargo.target) {
    case 'portfolio':
      breadcrumbs = [{title: 'My Cargoes', href: '/my-cargoes'}, {title: 'Cargo'}];
      break;
    case 'market':
      breadcrumbs = [{title: 'Market', href: '/market/cargo'}, {title: 'Cargo circular'}];
      break;
    default:
      break;
  }
  return (
    <>
      {cargo.target === 'portfolio' ? (
        <MyCargoesCargo
          cargo={cargo}
          generalInfoColumns={cargo.contractType === 'vc' ? cargoGeneralInfoColumnsVc : cargoGeneralInfoColumns}
          commoditySection={cargo.contractType === 'vc' ? commoditySectionVc : commoditySection}
          quantitySection={quantitySection}
          commissionSection={commissionSection}
          vesselFeatures={vesselFeatures}
          breadcrumbs={breadcrumbs}
          handleProjectClick={_handleProjectClick}
          handleEditClick={_handleEditClick}
          handleCloneClick={() => _handleCloneClick(cargo)}
          handleCreateFixtureClick={() => dispatch(ModalActions.show(<ManageFixtureModal cargo={cargo} />))}
          handleDeleteClick={() => _handleDeleteClick(cargo)}
        />
      ) : (
        <MarketCargo
          cargo={cargo}
          generalInfoColumns={cargo.contractType === 'vc' ? cargoGeneralInfoColumnsVc : cargoGeneralInfoColumns}
          commoditySection={cargo.contractType === 'vc' ? commoditySectionVc : commoditySection}
          quantitySection={quantitySection}
          commissionSection={commissionSection}
          vesselFeatures={vesselFeatures}
          breadcrumbs={breadcrumbs}
          handleCloneClick={() => _handleCloneClick(cargo)}
          handleProjectClick={_handleProjectClick}
          handleCreateFixtureClick={() => dispatch(ModalActions.show(<ManageFixtureModal cargo={cargo} />))}
        />
      )}
    </>
  );
};
