import {z} from 'zod';

export const SearchPickerPortSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  coordinates: z.tuple([z.number(), z.number()]),
  lat: z.number(),
  lon: z.number(),
  aliases: z.optional(z.array(z.string())),
  searouteLat: z.optional(z.number()),
  searouteLon: z.optional(z.number()),
  country: z.string(),
  countryObject: z.object({
    code: z.string(),
    name: z.string(),
  }),
  type: z.literal('port'),
  searchTitle: z.optional(z.string()),
  aliasName: z.optional(z.string()),
  resultType: z.optional(z.literal('port')),
  timezone: z.string().nullable(),
  tradingArea: z.object({
    name: z.string(),
    code: z.string(),
    id: z.number(),
  }),
});
export type SearchPickerPort = z.infer<typeof SearchPickerPortSchema>;
