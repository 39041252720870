/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CreateNotificationRequest
 */
export interface CreateNotificationRequest {
  /**
   *
   * @type {string}
   * @memberof CreateNotificationRequest
   */
  event: CreateNotificationRequestEventEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateNotificationRequest
   */
  recipientIds: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CreateNotificationRequest
   */
  authorId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateNotificationRequest
   */
  message: string;
  /**
   * A URL to redirect the user to when they click the notification. Currently used with relative URLs only.
   * @type {string}
   * @memberof CreateNotificationRequest
   */
  link: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateNotificationRequestEventEnum {
  CompanyWorkspaceUserAdded = 'company.workspace.user.added',
  CompanyWorkspaceUserRemoved = 'company.workspace.user.removed',
  MarketCargoShare = 'market.cargo.share',
  MarketVesselShare = 'market.vessel.share',
  SocialConnect = 'social.connect',
  Negotiation = 'negotiation',
}

export function CreateNotificationRequestFromJSON(json: any): CreateNotificationRequest {
  return CreateNotificationRequestFromJSONTyped(json, false);
}

export function CreateNotificationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNotificationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event: json['event'],
    recipientIds: json['recipientIds'],
    authorId: !exists(json, 'authorId') ? undefined : json['authorId'],
    message: json['message'],
    link: json['link'],
  };
}

export function CreateNotificationRequestToJSON(value?: CreateNotificationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event: value.event,
    recipientIds: value.recipientIds,
    authorId: value.authorId,
    message: value.message,
    link: value.link,
  };
}
