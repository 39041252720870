import {useNavigate} from 'react-router';
import {isTest} from './environment';

/**
 * A hook that returns a function to reload the current page.
 * In a browser that function will cause a page load.
 *
 * In test environments execution is omitted because window does not exist.
 */
export const useReloadPageHard = () => {
  const reloadPage = () => {
    if (isTest()) {
      return;
    }
    window.location.reload();
  };
  return reloadPage;
};

/**
 * A hook that returns a function to "soft reload" the page.
 * Soft reload quickly changes the browser URL to refreshUrl ("/") and back to the current URL.
 * In a browser that function will *not* cause a page load.
 * It will only refresh content that is dependent on the URL (i.e. router content).
 *
 * In test environments execution is omitted because it depends on possibly non-existent router.
 */
export const useReloadPageSoft = (refreshUrl = '/') => {
  const navigate = useNavigate();
  const reloadPageSoft = () => {
    const path = window.location.pathname;
    if (isTest()) {
      return;
    }
    navigate(refreshUrl);
    // Deferring the back-navigation to the next tick
    setTimeout(() => navigate(path), 0);
  };
  return reloadPageSoft;
};
