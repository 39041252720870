/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NegotiationTermWasAddedIn {
  Offer = 'offer',
  Ongoing = 'ongoing',
  Recap = 'recap',
}

export function NegotiationTermWasAddedInFromJSON(json: any): NegotiationTermWasAddedIn {
  return NegotiationTermWasAddedInFromJSONTyped(json, false);
}

export function NegotiationTermWasAddedInFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NegotiationTermWasAddedIn {
  return json as NegotiationTermWasAddedIn;
}

export function NegotiationTermWasAddedInToJSON(value?: NegotiationTermWasAddedIn | null): any {
  return value as any;
}
