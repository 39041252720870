import {useQuery} from '@tanstack/react-query';
import {CharterCalculationCollection} from '../api/symfony/generated';
import {charterCalculationApi} from '../api/symfony/symfonyApi';
import {ApiError} from '../api/utils/ApiError';

export const useProjectDetailsCharterCalculationsQuery = (projectId: number) => {
  return useQuery<CharterCalculationCollection, ApiError>({
    queryKey: ['get-project-details-chartercalculations', projectId],
    queryFn: () =>
      charterCalculationApi.getCalculations({
        projectId,
      }),
  });
};
