import {DistanceCalculator} from '../../Voyagecharter/utils/voyageChartercalculation/voyageChartercalculation';
import {distanceCalcPrefix} from './updateUrlSearchParamsWithAssumptions';

export const getDistanceCalcOptionsFromURLParams = (urlParams: URLSearchParams): Partial<DistanceCalculator> => {
  const distanceCalculator: Partial<DistanceCalculator> = {};
  const dcList: string[] = [];

  for (const key of urlParams.keys()) {
    if (key.startsWith(distanceCalcPrefix)) {
      dcList.push(key);
    }
  }
  dcList.forEach(urlParamKeyForDisCalc => {
    const value = urlParams.get(urlParamKeyForDisCalc);

    if (!(value === '1' || value === '0')) {
      throw new Error('Multicalc Assumptions for Distance Calculator (read) must be "1" or "0');
    }
    const keyWithoutPrefix = urlParamKeyForDisCalc.slice(distanceCalcPrefix.length);
    distanceCalculator[keyWithoutPrefix as keyof DistanceCalculator] = value === '1';
  });
  return distanceCalculator;
};

export const setURLSearchParamsFromDistanceCalculator = (
  distanceCalculator: DistanceCalculator,
  urlParams: URLSearchParams
) => {
  Object.keys(distanceCalculator).forEach(dcItemKey => {
    const value = distanceCalculator[dcItemKey as keyof DistanceCalculator];
    if (typeof value !== 'boolean') {
      throw new Error('Multicalc Assumptions for Distance Calculator (write) must be booleans');
    }
    urlParams.set(distanceCalcPrefix + dcItemKey, value ? '1' : '0');
  });
};
