import {Middleware, RequestContext, FetchParams} from '../../api/symfony/generated';
import {getAjaxCsrfTokenOrFail} from './api';
import {addHeaderOnContext} from './middlewareAddHeader';

const HttpMethodRequiresToken: Record<string, boolean> = {
  PUT: true,
  POST: true,
  PATCH: true,
  DELETE: true,
};

/**
 * This middleware for the generated API sets the 'X-Ajax-Csrf-Token' header when necessary.
 */
export const apiAddCsrfTokenMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    const httpMethod = context.init.method ?? 'GET';
    if (!HttpMethodRequiresToken[httpMethod]) {
      return;
    }
    addHeaderOnContext(context, 'X-Ajax-Csrf-Token', getAjaxCsrfTokenOrFail());
  },
};
