import {FC} from 'react';
import {UserOnboardingScreen} from './UserOnboardingScreen';
import {useUserOnboarding} from './useUserOnboarding';

export const UserOnboarding: FC<{children: React.ReactNode}> = ({children}) => {
  const [onboardingStep, stepProps] = useUserOnboarding();

  if (onboardingStep === null) {
    return <>{children}</>;
  }

  return <UserOnboardingScreen onboardingStep={onboardingStep} stepProps={stepProps} />;
};
