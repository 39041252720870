import {notification} from 'antd';
import {useSelectWorkspaceMutation} from './useSelectWorkspaceMutation';
import {ApiError} from '../../api/utils/ApiError';
import {handleError} from '../../api/utils/reactQueryClient';
import {useChangeBrowserUrl} from '../../utils/useChangeBrowserUrl';
import {useReloadPageAndGoToSafeUrl} from './useReloadPageAndGoToSafeUrl';
import {useWorkspacesQuery} from '../../queries/useWorkspacesQuery';

export const useSwitchToWorkspace = () => {
  const changeBrowserUrl = useChangeBrowserUrl();
  const reloadAndGoToSafeUrl = useReloadPageAndGoToSafeUrl();
  const selectWorkspaceMutation = useSelectWorkspaceMutation();

  const workspacesQuery = useWorkspacesQuery();
  const workspaces = workspacesQuery.data;

  if (!workspaces) {
    return () => {
      notifyWorkspacesNotAvailableError();
    };
  }

  return (workspaceId: number, targetUrl?: string) => {
    const workspace = workspaces.find(workspace => workspace.id === workspaceId);

    if (!workspace) {
      notifyWorkspaceNotFoundError();
      return;
    }

    selectWorkspaceMutation.mutate(workspace, {
      onSuccess: () => {
        if (targetUrl) {
          changeBrowserUrl(targetUrl);
        } else {
          reloadAndGoToSafeUrl();
        }
      },
      onError: (error: ApiError) => {
        if (error.status === 401) {
          notifyWorkspaceNotAuthorizedError();
          return;
        }
        if (error.status === 404) {
          notifyWorkspaceNotFoundError();
          return;
        }
        handleError(error);
        throw error;
      },
    });
  };
};

const notifyWorkspaceNotAuthorizedError = () => {
  notification.error({
    message: 'Not authorized',
    description: (
      <p>
        You are not authorized to access this workspace.
        <br />
        Please reload the page.
      </p>
    ),
    duration: 0,
    style: {width: 500},
  });
};

const notifyWorkspaceNotFoundError = () => {
  notification.error({
    message: 'Workspace not found',
    description: <p>Possibly this workspace was disabled or you were removed from it. Please reload the page.</p>,
    duration: 0,
    placement: 'top',
    style: {width: 500, position: 'relative'},
  });
};

const notifyWorkspacesNotAvailableError = () => {
  notification.error({
    message: 'Workspaces not available',
    description: <p>Workspaces were not available at the moment. Please try again.</p>,
    duration: 0,
    placement: 'top',
    style: {width: 500, position: 'relative'},
  });
};
