import React, {CSSProperties, FC} from 'react';
import classNames from 'classnames';
import {UnionTypeFromArray} from '../../utils/UnionTypeFromArray';
import './style.scss';

/**
 * To change icons:
 * - visit https://icomoon.io/app/ and import current /static/fonts/icomoon.svg
 * - make the changes
 * - export fonts and scss variables (lower right button)
 * - check preferences menu:
 *   - font name: "icomoon"
 *   - class prefix: "icon-"
 *   - generate preprocessor variables for: sass
 * - download files
 * - overwrite current icomoon.* files in /static/fonts/
 * - overwrite variables.scss in current folder
 * - adjust icon names / variables in this file and style.scss
 */

export const iconSizes = ['unset', 'small', 'medium-small', 'medium', 'medium-large', 'large', 'extra-large'] as const;
export type IconSize = UnionTypeFromArray<typeof iconSizes>;

export const iconTypes = [
  'polygon',
  'logo',
  'add',
  'arrow-right',
  'block',
  'box-open',
  'cement',
  'checkmark',
  'close',
  'coal',
  'dashboard',
  'date',
  'delete',
  'donut',
  'cloud-download',
  'edit',
  'fertilizer',
  'file',
  'foodstock',
  'grain',
  'caret-down',
  'camera',
  'clear',
  'cloud-upload',
  'drag-handle',
  'filter-list',
  'format-bold',
  'format-italic',
  'format-list-bulleted',
  'format-list-numbered',
  'help',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'link',
  'notifications',
  'phone-message',
  'person',
  'photo',
  'question-answer',
  'reply',
  'settings',
  'sms-failed',
  'star',
  'subdirectory-arrow-right',
  'thumb-down',
  'thumb-up',
  'verified-badge',
  'item-add-selection',
  'item-not-selected',
  'large-view',
  'location',
  'markets',
  'mineral',
  'more-vert',
  'negotiations',
  'network',
  'news',
  'seabo-wave',
  'port',
  'portfolio',
  'radio-unchecked',
  'radio-checked',
  'salt',
  'sand',
  'scrap',
  'cargo-disabled',
  'other',
  'search',
  'sent',
  'steel',
  'stone',
  'table-grid',
  'table',
  'time-full',
  'time-outline',
  'vessel-complex',
  'vessel',
  'visibility',
  'forest-products',
  'work-tray',
  'weather',
  'tips',
  'stock',
  'rss-feed',
  'check-mark-line',
  'world',
  'item-add-selection-solid',
  'sugar',
  'sulphor',
  'vehicle',
  'iron-ore-products',
  'ore',
  'organic-product',
  'nego-attention',
  'paper-product',
  'petcoke',
  'agricultural-product',
  'ash',
  'chemical',
  'concentrate',
  'file-attachment',
  'send',
  'minus',
  'attachment',
  'bookmark',
  'layers',
  'port-disabled',
  'vessel-disabled',
  'no-port',
  'no-vessel',
  'pirates',
  'network-circle',
  'nego-failed',
  'nego-success',
  'nego-timer',
  'nego-mainterms',
  'nego-cpdetails',
  'nego-cleanrecap',
  'metal',
  'weather-humidity',
  'weather-waves',
  'weather-wind',
  'redo',
  'underline',
  'undo',
  'linkedin',
  'google',
  'company',
  'social-facebook',
  'social-instagram',
  'social-linkedin',
  'social-twitter',
  'markets-disabled',
  'portfolio-disabled',
  'addressbook',
  'layers-disabled',
  'add-person',
  'start-chat',
  'tour-add-contacts',
  'tour-edit-profile',
  'tour-product',
  'logo-wave',
  'parser-process',
  'tools',
  'workspace',
  'history',
  'slag',
  'cargo',
  'database',
  'play',
  'pause',
  'stop',
  'inbox',
  'received',
  'inboxes',
  'outbox',
] as const;
export type IconType = UnionTypeFromArray<typeof iconTypes>;

export const iconColors = [
  'black',
  'black-fixed',
  'white',
  'white-fixed',
  'gray',
  'gray-fixed',
  'light-gray',
  'light-gray-fixed',
  'lighter-gray',
  'lighter-gray-fixed',
  'azure',
  'azure-fixed',
  'blue',
  'blue-light',
  'green',
  'yellow',
  'orange',
  'red',
] as const;
export type IconColor = UnionTypeFromArray<typeof iconColors>;

export interface IconProps {
  id?: string;
  type: IconType;
  size?: IconSize;
  color?: IconColor;
  centered?: boolean;
  disabled?: boolean;
  dataTestid?: string;
  rounded?: boolean;
  hovered?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  style?: CSSProperties;
  title?: string;
}

export const Icon: FC<IconProps> = ({
  id,
  type,
  disabled = false,
  size,
  rounded,
  dataTestid,
  color,
  centered,
  className: classNameFromProps,
  hovered,
  style,
  onClick = () => {},
  title,
}) => {
  const className = classNames(
    'icon',
    `icon--${type}`,
    {
      [`icon--${size}`]: !!size,
      [`icon--${color}`]: !!color,
      'icon--disabled': disabled,
      'icon--rounded': rounded,
      'icon--centered': centered,
      'icon--hovered': hovered,
    },
    classNameFromProps
  );
  return (
    <i
      data-testid={dataTestid}
      id={id}
      title={title}
      className={className}
      style={style}
      onClick={e => !disabled && onClick(e)}
    />
  );
};

export default Icon;
