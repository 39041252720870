import dayjs, {Dayjs} from 'dayjs';
import {DateTimeFormat} from '../utils/DateTimeFormat';
import timezones from './timezones';

class DateTime {
  static timeFormat = DateTimeFormat.Time;
  static dateFormat = DateTimeFormat.Date;
  static dateTimeFormat = DateTimeFormat.DateTime;
  static weekdayDateTimeFormat = DateTimeFormat.WeekdayDateTime;

  originalDateTime: string;
  tz: string;
  dayjsInstance: Dayjs;

  constructor(dateTime: string, format?: string, tz?: string) {
    this.originalDateTime = dateTime;
    this.tz = tz || dayjs.tz.guess();
    this.dayjsInstance = dateTime ? dayjs.utc(dateTime, format).tz(this.tz) : dayjs.utc().tz(this.tz);
  }

  /*
  if you want to format the date without worrying about the user's timezone,
  you can use "UseDateTime" or "useGetUserDateTime
   */
  formattedDate(timezone: string) {
    return this.dayjsInstance.tz(timezone).format(DateTime.dateFormat);
  }

  /*
  if you want to format the date without worrying about the user's timezone,
  you can use "UseDateTime" or "useGetUserDateTime
   */
  formattedTime(timezone: string) {
    return this.dayjsInstance.tz(timezone).format(DateTime.timeFormat);
  }

  formattedWeekdayDateTime(timezone: string) {
    return this.dayjsInstance.tz(timezone).format(DateTime.weekdayDateTimeFormat);
  }

  /*
  if you want to format the date without worrying about the user's timezone,
  you can use "UseDateTime" or "useGetUserDateTime
   */
  formattedDateTime(timezone: string) {
    return this.dayjsInstance.tz(timezone).format(DateTime.dateTimeFormat);
  }

  getFormattedDate(format = DateTime.dateFormat) {
    return this.dayjsInstance.format(format);
  }

  getFormattedDateTime(format = DateTime.dateTimeFormat) {
    return this.dayjsInstance.format(format);
  }

  getFormattedWeekdayDateTime(format = DateTime.weekdayDateTimeFormat) {
    return this.dayjsInstance.format(format);
  }

  get moment() {
    return this.dayjsInstance.clone();
  }

  get original() {
    return this.originalDateTime;
  }

  static get timeZones() {
    return timezones;
  }

  static fromServerTimeStamp(timeStamp: string, tz?: string) {
    return new DateTime(timeStamp, 'X', tz);
  }

  static fromJsTimeStamp(timeStamp: string, tz?: string) {
    return new DateTime(timeStamp, 'x', tz);
  }

  static fromServerResponse(timeString: string, format?: string, tz?: string) {
    return new DateTime(timeString, format, tz);
  }

  static fromLocalDate(localDate?: Dayjs, tz?: string) {
    return new DateTime(localDate ? localDate.toString() : '', 'YYYYMMDD', tz);
  }
}

export default DateTime;
