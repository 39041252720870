import {FC} from 'react';
import {useCommodityCategoriesQuery} from '../queries/useCommodityCategoriesQuery';

export const useCommodityCategory = ({commodityCategory}: {commodityCategory: string}) => {
  const commodityCategoriesQuery = useCommodityCategoriesQuery();
  const commodityCategories = commodityCategoriesQuery.data?.items ?? [];
  return commodityCategories.find(category => category.slug === commodityCategory);
};

export const CommodityCategory: FC<{commodityCategory: string}> = ({commodityCategory}) => {
  const label = useCommodityCategory({commodityCategory})?.name ?? '-';

  return <span>{label}</span>;
};
