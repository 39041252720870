import z from 'zod';

const ReferenceSchema = z.object({
  id: z.number(),
  name: z.string().nullish(),
});

const AttachmentSchema = z.object({
  uuid: z.string(),
  url: z.string(),
  created: z.date(),
});

const CoordinatesSchema = z.tuple([z.number(), z.number()]);

const StationLocationSchema = z.object({
  name: z.string().optional(),
  additionalInfo: z
    .object({
      rate: z.number().nullable().optional(),
      terms: z.string().nullable().optional(),
    })
    .optional(),
  type: z.string(),
  code: z.string().optional(),
  country: z.string().optional(),
  coordinates: CoordinatesSchema.optional(),
  id: z.number(),
});

const CompanyUserSchema = z.object({
  id: z.number(),
  userID: z.number(),
  fullName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  companyPosition: z.string().nullable(),
  companyVisibility: z.boolean(),
  companyJoinTime: z.date(),
});

const CompanyAddressSchema = z.object({
  name: z.string().nullish(),
  street: z.string().nullish(),
  street2: z.string().nullish(),
  streetNumber: z.string().nullish(),
  zip: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
});

const CompanyContactSchema = z.object({
  email: z.string().nullish(),
  phone: z.string().nullish(),
  fax: z.string().nullish(),
  website: z.string().nullish(),
});

const CompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullish(),
  foundYear: z.number().nullish(),
  employees: z.string().nullish(),
  vesselsManaged: z.number().nullish(),
  business: z.string().nullish(),
  companyType: z.string().nullish(),
  companyMailDomain: z.string().nullish(),
  companyLogo: AttachmentSchema.optional(),
  user: CompanyUserSchema.optional(),
  address: CompanyAddressSchema.optional(),
  contact: CompanyContactSchema.optional(),
  isVerified: z.boolean().optional(),
  isFollowing: z.boolean().optional(),
  isOnboarded: z.boolean().optional(),
  isPayingCustomer: z.boolean().optional(),
  createdAt: z.date().optional(),
  showConnectMarketplaceBanner: z.boolean().optional(),
});

const UserSchema = z.object({
  id: z.number(),
  fullName: z.string(),
  imageUrl: z.string().optional(),
  locale: z.string().optional(),
  options: z
    .object({
      timezone: z.string(),
    })
    .optional(),
  company: ReferenceSchema.optional(),
  email: z.string().optional(),
  avatar: z.string().nullable(),
  workspace: ReferenceSchema.nullish(),
});

const IntakeSchema = z.object({
  id: z.number().optional(),
  type: z.enum(['teu', 'teu14', 'feu', '45f']),
  quantity: z.number(),
  isHighCube: z.boolean(),
  isEmpty: z.boolean(),
});

const UpdatedBySchema = z.object({
  avatar: z.string(),
  company: ReferenceSchema,
  firstName: z.string(),
  fullName: z.string(),
  id: z.number(),
  lastName: z.string(),
  newsletter: z.boolean().nullish(),
});

const DocumentSchema = z.object({
  assigned: z.boolean(),
  context: z.string(),
  created: z.string(),
  downloadCount: z.number(),
  fileExtension: z.string(),
  filename: z.string(),
  mimeType: z.string(),
  size: z.number(),
  uploaded: z.boolean(),
  url: z.string(),
  uuid: z.string(),
});

const PortfolioGroupSchema = z.object({
  id: z.number(),
  name: z.string(),
});

const PortfolioCargoStationSchema = z.object({
  id: z.number(),
  isRange: z.boolean(),
  locations: z.array(StationLocationSchema),
  type: z.string(),
});

const RelatedOfferSchema = z.object({
  id: z.number(),
  user: UserSchema,
  company: CompanySchema,
  workspace: z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    email: z.string(),
    isDefault: z.boolean(),
    isDisabled: z.boolean(),
    easySharing: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
  }),
  updatedAt: z.string(),
  createdAt: z.string(),
  public: z.boolean(),
  shared: z.boolean(),
  isArchived: z.boolean(),
  originSender: z.string().nullable(),
  vessel: z.object({id: z.number(), name: z.string(), targetType: z.enum(['market'])}),
  sharedWith: z.object({users: z.array(z.unknown())}),
});

const PortfolioCargoSchema = z.object({
  id: z.number(),
  name: z.string().nullish(),
  portfolioGroups: z.array(PortfolioGroupSchema).optional(),
  originCargoId: z.number().optional(),
  chartererName: z.string().nullable(),
  target: z.literal('portfolio'),
  vesselType: z.string().optional(),
  contractType: z.string(),
  isArchived: z.boolean(),
  offer: z
    .object({
      isArchived: z.boolean(),
    })
    .optional(),
  user: UserSchema.optional(),
  company: CompanySchema.optional(),
  cargoType: z.string().optional(),
  commodity: z.string().nullish(),
  commodityType: z.string().nullish(),
  commodityCategory: z.string().nullish(),
  commissionTotal: z.number().optional(),
  commissionAddress: z.number().nullish(),
  commissionBrokerage: z.number().nullish(),
  confidenceScore: z.number().nullish(),
  comment: z.string().nullish(),
  maxVesselAge: z.number().nullish(),
  color: z.number().nullable(),
  documents: z.array(DocumentSchema),
  laycanFrom: z.string().nullish(),
  laycanTo: z.string().nullish(),
  gear: z.enum(['gearless', 'geared', 'gear_optional']).nullable(),
  reeferPlugs: z.number().nullish(),
  commodityStowageFactor: z.number().nullish(),
  commodityStowageFactorUnit: z.string().nullish(),
  quantityMin: z.number().nullish(),
  quantityMax: z.number().nullish(),
  quantityUnit: z.string().nullish(),
  tolerance: z.number().nullish(),
  toleranceUnit: z.string().nullish(),
  quantityOption: z.string().nullish(),
  stations: z.array(PortfolioCargoStationSchema).optional(),
  teuQuantity: z.number().nullish(),
  intakes: z.array(IntakeSchema),
  updatedBy: UpdatedBySchema.nullish(),
  durationMin: z.number().nullish(),
  durationMax: z.number().nullish(),
  durationAbout: z.number().nullish(),
  durationUnit: z.string().nullish(),
  relatedOffers: z.array(RelatedOfferSchema).optional(),
  updatedAt: z.string().optional(),
  createdAt: z.string().optional(),
});

export const PortfolioCargoCollectionSchema = z.object({
  items: z.array(PortfolioCargoSchema),
  totalItems: z.number(),
});

export type Station = z.infer<typeof PortfolioCargoStationSchema>;
export type Company = z.infer<typeof CompanySchema>;
export type PortfolioGroup = z.infer<typeof PortfolioGroupSchema>;
export type PortfolioCargo = z.infer<typeof PortfolioCargoSchema>;
export type CargoCollection = z.infer<typeof PortfolioCargoCollectionSchema>;

const MarketCargoOfferSchema = z.object({
  id: z.number(),
  user: UserSchema.nullish(),
  company: CompanySchema.nullish(),
  workspace: ReferenceSchema.nullish(),
  updatedAt: z.string(),
  createdAt: z.string(),
  public: z.boolean(),
  shared: z.boolean(),
  isArchived: z.boolean(),
  originSender: z.string(),
  cargo: z.object({id: z.number(), name: z.null()}),
  sharedWith: z.object({users: z.array(z.unknown())}),
});

const MarketCargoSchema = z.object({
  id: z.number(),
  name: z.string().nullish(),
  user: UserSchema.nullish(),
  company: CompanySchema.nullish(),
  cargoType: z.string().nullish(),
  contractType: z.string(),
  chartererName: z.string().nullish(),
  commodity: z.string().nullish(),
  commodityType: z.string().nullish(),
  commodityCategory: z.string().nullish(),
  commissionTotal: z.number().nullish(),
  commissionAddress: z.number().nullish(),
  commissionBrokerage: z.number().nullish(),
  confidenceScore: z.number().nullish(),
  comment: z.string().nullish(),
  maxVesselAge: z.number().nullish(),
  color: z.number().nullish(),
  documents: z.array(DocumentSchema).optional(),
  laycanFrom: z.string().nullish(),
  laycanTo: z.string().nullish(),
  gear: z.string().nullish(),
  reeferPlugs: z.number().nullish(),
  commodityStowageFactor: z.number().nullish(),
  commodityStowageFactorUnit: z.string().nullish(),
  quantityMin: z.number().nullish(),
  quantityMax: z.number().nullish(),
  quantityUnit: z.string().nullish(),
  tolerance: z.number().nullish(),
  toleranceUnit: z.string().nullish(),
  quantityOption: z.string().nullish(),
  stations: z.array(StationLocationSchema).optional(),
  teuQuantity: z.number().nullish(),
  intakes: z.array(IntakeSchema).optional(),
  target: z.literal('market'),
  isArchived: z.boolean(),
  offer: MarketCargoOfferSchema.optional(),
  updatedAt: UpdatedBySchema.or(z.string()),
  createdAt: z.string(),
  durationMin: z.number().nullish(),
  durationMax: z.number().nullish(),
  durationAbout: z.number().nullish(),
  durationUnit: z.string().nullish(),
  vesselType: z.string().optional(),
  vesselSubtype: z.string().nullish(),
  vesselSizeMin: z.number().nullish(),
  vesselSizeMax: z.number().nullish(),
  vesselSizeUnit: z.string().nullish(),
  gears: z.array(z.unknown()).optional(),
  logFitted: z.boolean().nullish(),
  lakesFitted: z.boolean().nullish(),
  co2Fitted: z.boolean().nullish(),
  itfFitted: z.boolean().nullish(),
  tweenDecks: z.boolean().nullish(),
  boxShape: z.boolean().nullish(),
  openHatch: z.boolean().nullish(),
  a60Bulkhead: z.boolean().nullish(),
  cellular: z.boolean().nullish(),
  bowThrust: z.boolean().nullish(),
  sternThrust: z.boolean().nullish(),
  iceClass: z.string().nullish(),
  scrubberFitted: z.boolean().nullish(),
});

export type MarketCargo = z.infer<typeof MarketCargoSchema>;

export const GetCargoDetailsResponseSchema = z.discriminatedUnion('target', [MarketCargoSchema, PortfolioCargoSchema]);

export type CargoDetailsResponse = z.infer<typeof GetCargoDetailsResponseSchema>;
