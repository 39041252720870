import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {negotiationNegotiationTermApi} from '../../../../../../api/node-backend/nodeBackendApis';
import {NegotiationRoundResult, NegotiationTermType} from '../../../../../../api/node-backend/generated';
import {ApiError} from '../../../../../../api/utils/ApiError';

export const useUpdateTermTypeMutation = (
  options?: UseMutationOptions<NegotiationRoundResult, ApiError, {termId: string; type: NegotiationTermType | null}>
) =>
  useMutation({
    mutationFn: async ({termId, type}: {termId: string; type: NegotiationTermType | null}) => {
      const result = await negotiationNegotiationTermApi.updateTermType({termId, updateTermTypeRequest: {type}});
      return result;
    },
    ...options,
  });
