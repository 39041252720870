import {CharterCalculationList} from '../../../../atoms/CharterCalculationList';
import {Modal} from 'antd';
import {CharterCalculation} from '../../../../api/symfony/generated/models';
import {useChangeBrowserUrl} from '../../../../utils/useChangeBrowserUrl';
import styled from 'styled-components';

interface OpenDialogProps {
  visible: boolean;
  onCancel: () => void;
  /**
   * the function would triggered with the ID of the selected Calculation.
   */
  onLoad: (item: CharterCalculation) => void;
}

export const OpenDialog = (props: OpenDialogProps) => {
  const changeBrowserUrl = useChangeBrowserUrl();
  const load = (item: CharterCalculation) => {
    changeBrowserUrl(`?id=${item.id}`);
    props.onCancel();
  };

  return (
    <StyledModal
      footer={<div style={{marginTop: -40}}></div>}
      open={props.visible}
      getContainer={'#root'}
      title={'Open'}
      onCancel={props.onCancel}>
      <CharterCalculationList
        onOpenCalculation={item => {
          load(item);
        }}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 90% !important;
  max-width: 1100px;
`;
