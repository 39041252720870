import {useState} from 'react';
import useInterval from 'beautiful-react-hooks/useInterval';

type AnimatedLayerAnimationMetaDataSource = {
  /**
   * The current dateIndex
   */
  dateIndex: number;
  /**
   * To manipulate the dateIndex externally, for example when you like to have a slider.
   */
  setDateIndex: (value: number) => void;
  isPlaying: boolean;
  setIsPlaying: (value: boolean) => void;
};

type UseAnimatedLayersOrganisationProps = {
  lastIndexAtTheTimeSeries: number;
};

/**
 * There are layers that represent a time series, which you may want to animate.
 * This hook is used for this purpose.
 * It handles whether the time series should be played and at which point of the time series you are currently.
 */
export const usePlayer = ({
  lastIndexAtTheTimeSeries,
}: UseAnimatedLayersOrganisationProps): AnimatedLayerAnimationMetaDataSource => {
  const [dateIndex, _setDateIndex] = useState(lastIndexAtTheTimeSeries);
  const [isPlaying, setIsPlaying] = useState(false);

  const setDateIndex = (newIndex: number) => {
    if (lastIndexAtTheTimeSeries < newIndex) {
      setIsPlaying(false);
      return;
    }
    _setDateIndex(newIndex);
  };

  useInterval(() => {
    if (isPlaying) {
      setDateIndex(dateIndex + 1);
    }
  }, 200);

  return {dateIndex, setDateIndex, isPlaying, setIsPlaying};
};
