import {CompanyMember} from '../api/symfony/generated';
import {actionTypes} from './ApiService/CompanyService/CompanyService';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import {Company} from './ApiService/CompanyService/Company';
import {TODO} from '../utils/TODO';

type WorkspacePayloadError = TODO;

interface Workspaces extends TODO {
  data?: {
    items?: TODO[];
  };
  users?: TODO[];
}

type ErrorUser = TODO;

export type CompanyState = {
  loading: boolean;
  companyLoading: boolean;
  data: Company;
  error: boolean;

  users: CompanyMember[];
  userLoading: boolean;
  workspacePayloadError: WorkspacePayloadError;
  workspaces: Workspaces;

  errorUser: ErrorUser;
};

const initialState: CompanyState = {
  // TODO: 'loading' is set to true initially although the company is not loading...
  // This is quite bad.
  // Use 'companyLoading' instead of 'loading' and remove 'loading'
  loading: true,
  companyLoading: false,
  data: {} as Company,
  error: false,

  users: [],
  userLoading: true,

  workspacePayloadError: [],
  workspaces: [],

  errorUser: [],
};

export const companyReducer = (state = initialState, action: ActionWithPayload): CompanyState => {
  switch (action.type) {
    case 'SET_COMPANY':
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.companyUpdate.SUCCESS:
    case actionTypes.followOrUnfollow.SUCCESS:
      return {
        ...state,
        loading: false,
        companyLoading: false,
        data: action.payload,
      };
    case actionTypes.companyUpdate.ERROR:
    case actionTypes.followOrUnfollow.ERROR:
      return {
        ...state,
        loading: false,
        companyLoading: false,
        error: true,
      };
    case actionTypes.getMembers.SUCCESS:
    case actionTypes.removeMember.SUCCESS:
      return {
        ...state,
        users: action.payload.data.items,
        userLoading: false,
      };
    case actionTypes.addMembers.SUCCESS:
      return {
        ...state,
        errorUser: action.payload.data.items.filter((user: TODO) => user.status === 'error'),
      };
    case actionTypes.getMembers.ERROR:
    case actionTypes.addMembers.ERROR:
    case actionTypes.removeMember.ERROR:
      return {
        ...state,
        userLoading: false,
        error: true,
      };

    case actionTypes.getWorkspaceList.SUCCESS:
    case actionTypes.getUserWorkspaceList.SUCCESS:
      return {
        ...state,
        // loading: false,
        workspaces: action.payload,
      };
    case actionTypes.getWorkspaceList.ERROR:
    case actionTypes.getUserWorkspaceList.ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actionTypes.addWorkspace.SUCCESS:
    case actionTypes.editWorkspace.SUCCESS:
    case actionTypes.deleteWorkspace.SUCCESS:
      return {
        ...state,
        loading: false,
        workspaces: {
          ...state.workspaces,
          [action.payload.id]: action.payload,
        },
      };
    case actionTypes.addWorkspace.ERROR:
    case actionTypes.editWorkspace.ERROR:
    case actionTypes.deleteWorkspace.ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        workspacePayloadError: action.payload.data,
      };

    // case
    default:
      return state;
  }
};

const setCompany = (company: Company) => {
  const payload: Partial<CompanyState> = {
    data: company,
    loading: false,
    companyLoading: false,
    error: false,
  };

  return {
    type: 'SET_COMPANY',
    payload: payload,
  };
};

export const CompanyActions = {
  setCompany,
};
