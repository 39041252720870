import {NegotiationSubjectStatus} from '../../../../../../api/node-backend/generated';
import {IconType} from '../../../../../../atoms/Icon';
import {assertUnreachable} from '../../../../../../utils/assert';

export const getSubjectStatusIcon = (status: NegotiationSubjectStatus): IconType => {
  switch (status) {
    case NegotiationSubjectStatus.Ongoing:
    case NegotiationSubjectStatus.Accepted:
    case NegotiationSubjectStatus.Rejected:
    case NegotiationSubjectStatus.RejectionRequested:
      return 'block';
    case NegotiationSubjectStatus.Lifted:
      return 'checkmark';
    case NegotiationSubjectStatus.Failed:
      return 'close';
    default:
      assertUnreachable(status);
  }
};
