import {Popover, PopoverProps} from 'antd';
import {CSSProperties, FC, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {assert} from '../../utils/assert';
import {WorkspaceList} from './WorkspaceList';
import {ButtonLabelNarrow, WorkspaceSelectButtonNarrow} from './WorkspaceSelectButtonNarrow';
import {ButtonLabelWide, WorkspaceSelectButtonWide} from './WorkspaceSelectButtonWide';
import {User, Workspace} from './WorkspaceSelectTypes';

export type Placement = PopoverProps['placement'];

export type ColorScheme = 'BlueOnGrey' | 'WhiteOnBlue';

export type WorkspaceSelectSize = 'wide' | 'narrow';

const WorkspaceTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 4px;
`;

/**
 * The 'dumb' component for WorkspaceSelect.
 * Its responsibility is rendering and also emitting events.
 * Handling the events is up to the 'smart' component (= WorkspaceSelect).
 */
export const WorkspaceSelectDumb: FC<{
  colorScheme?: ColorScheme;
  size?: WorkspaceSelectSize;
  placement?: Placement;
  companyWorkspaces: Workspace[];
  user: User;
  value: number;
  style?: CSSProperties;
  dataTestId?: string;
  onSelect?: (id: number) => void;
}> = ({
  colorScheme = 'WhiteOnBlue',
  placement = 'bottomRight',
  size = 'wide',
  companyWorkspaces,
  user,
  value,
  style,
  dataTestId,
  onSelect,
}) => {
  assert(value !== 0, 'Workspace 0 is not supported anymore');
  const [open, setOpen] = useState(false);
  const className = `WorkspaceSelectButton--${colorScheme}`;
  const selectedWorkspace = companyWorkspaces.find(workspace => workspace.id === value);
  if (!selectedWorkspace) {
    throw new Error(
      `Selected workspace ${value} not available for user. Available: ${companyWorkspaces.map(
        workspace => workspace.id
      )}`
    );
  }

  const handleSelect = ({id}: {id: number}) => {
    setOpen(false);
    onSelect?.(id);
  };
  const WorkspaceSelectButton = size === 'wide' ? WorkspaceSelectButtonWide : WorkspaceSelectButtonNarrow;
  const ButtonLabel = size === 'wide' ? ButtonLabelWide : ButtonLabelNarrow;

  return (
    <>
      <Popover
        className="workspacePopover"
        placement={placement}
        content={<WorkspaceList workspaces={companyWorkspaces} user={user} value={value} onSelect={handleSelect} />}
        trigger="click"
        title={<WorkspaceTitle>Your workspaces</WorkspaceTitle>}
        overlayClassName="workspaceSelectPopup"
        open={open}
        onOpenChange={visible => {
          setOpen(visible);
        }}>
        <WorkspaceSelectButton className={className} style={style} data-testid={dataTestId}>
          <ButtonLabel workspace={selectedWorkspace} />
        </WorkspaceSelectButton>
      </Popover>
      <PopupGlobalStyles />
    </>
  );
};

const PopupGlobalStyles = createGlobalStyle`
  .workspaceSelectPopup {
    position: fixed;

    .ant-popover-title {
      margin-bottom: 0;
      padding: 5px 16px 4px;
    }

    .ant-popover-inner {
      padding: 0;
    }
  }
`;
