import {useQuery} from '@tanstack/react-query';
import {getMarketCircularItems} from '../../../redux/ApiService/MapService/MapService';
import {useDispatch, useSelector} from '../../../redux/react-redux';

export const useCircularItemsMapQuery = () => {
  const dispatch = useDispatch();

  const {vesselFilterSettings, cargoFilterSettings} = useSelector(state => state.marketFilters);

  return useQuery({
    queryKey: ['circularItemsMapQuery', vesselFilterSettings, cargoFilterSettings],
    queryFn: async () => {
      const tradingAreaMap: Record<number, CircularItem> = {};
      const portMap: Record<number, CircularItem> = {};
      const circulars = (await dispatch(
        getMarketCircularItems({cargoFilters: cargoFilterSettings, vesselFilters: vesselFilterSettings})
      )) as MarketCirculars;

      circulars.ports.forEach(port => {
        portMap[port.id] = port;
      });
      circulars.areas.forEach(area => {
        tradingAreaMap[area.id] = area;
      });

      return {tradingAreaMap, portMap};
    },
  });
};

export type CircularItem = {
  amountCargoes: number;
  amountVessels: number;
  market: {
    amountCargoes: number;
    amountVessels: number;
  };
  id: number;
};

type MarketCirculars = {
  areas: CircularItem[];
  ports: CircularItem[];
};
