import {CircularProgressbar} from 'react-circular-progressbar';
import {FC} from 'react';
import styled from 'styled-components';
import User from '../../../../model/User';

export const Header: FC<{
  percentage: number;
  isFinished: boolean;
  user: User;
}> = ({percentage, isFinished, user}) => {
  return (
    <StyledHeadline>
      <StyledHeadlinePercent>
        <CircularProgressbar value={percentage} text={`${percentage}%`} />
      </StyledHeadlinePercent>
      <StyledHeadlinePersonal>
        <StyledHeadlinePersonalGreeting>
          {timeRelatedGreetings()}, <span>{user.fullName}</span>!
        </StyledHeadlinePersonalGreeting>
        <StyledHeadlinePersonalContext>
          {isFinished ? (
            <span>Congratulations! Your company profile is set up now.</span>
          ) : (
            <span>
              You have completed <span>{percentage}</span>% of your company profile. Let's finalise the rest.
            </span>
          )}
        </StyledHeadlinePersonalContext>
      </StyledHeadlinePersonal>
    </StyledHeadline>
  );
};

const StyledHeadline = styled.div`
  display: flex;
  width: 100%;
  height: 99px;
`;

const StyledHeadlinePercent = styled.div`
  max-width: 100px;
  min-width: 100px;
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 15px;

  .CircularProgressbar {
    width: 100%;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: var(--color-green);
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: var(--color-gray-3);
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: var(--color-gray-1);
    font-size: var(--font-size-2xl);
    font-weight: bold;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  .CircularProgressbar .CircularProgressbar-background {
    fill: var(--color-green);
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: var(--color-white);
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: var(--color-white);
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }
`;

const StyledHeadlinePersonal = styled.div`
  text-align: left;
  padding-left: 15px;
  width: inherit;
`;

const StyledHeadlinePersonalGreeting = styled.div`
  margin-top: 20px;
  font-size: var(--font-size-2xl);
  font-weight: bold;
`;
const StyledHeadlinePersonalContext = styled.div`
  * {
    font-size: var(--font-size-lg) !important;
    font-weight: bold !important;
    color: var(--color-blue) !important;
  }
`;

export const timeRelatedGreetings = (currentTime = new Date()): string => {
  const currentHour = currentTime.getHours();
  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return 'Good afternoon';
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return 'Good evening';
  }
  // Between dawn and noon
  return 'Good morning';
};
