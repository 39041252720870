import {colorColumn} from '../../../components/GridLayout/columns/shared/colorColumn';
import {fixtureGridColumns} from '../../../components/GridLayout/fixtureGridHelper/fixtureGridHelper';
import {ActionMenu} from '../../../components/ActionMenu/ActionMenu';
import Icon from '../../../atoms/Icon';
import {MenuItem} from '../../../atoms/Menu/MenuItem';
import {Fixture} from './fixtureType';
import {CustomColumnDef} from '../../../components/DataGrid/DataGrid';
import {TODO} from '../../../utils/TODO';
import {ManageFixtureProps} from '../../../components/Modal/ManageFixture/ManageFixtureModal';
import {ReactNode} from 'react';
import {Delete} from '../../../components/Modal/Delete/DeleteModal';

export const getColumnsForSalesHistory = ({
  selectedFixtureId,
  showManageFixtureModal,
  onFixtureChanged,
  showModal,
  deleteFixture,
}: {
  selectedFixtureId: number | undefined;
  showModal: (modal: ReactNode) => void;
  showManageFixtureModal: (props: ManageFixtureProps) => void;
  deleteFixture: (id: number) => Promise<void>;
  onFixtureChanged: () => void;
}) => {
  const columns: CustomColumnDef<TODO>[] = [
    colorColumn<Fixture>({
      height: 68,
      colorPickerFunction: fixture => {
        return fixture.id === selectedFixtureId ? 4 : null;
      },
    }),

    ...fixtureGridColumns({onFixtureChanged: onFixtureChanged, updateVessel: true}),
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      maxWidth: 48,
      className: 'datagrid-td-action',
      accessorFn: d => (
        <ActionMenu
          actionIcon={<Icon type={'more-vert'} />}
          items={[
            {
              key: 'VesselFixtureEditButton',
              label: (
                <MenuItem
                  dataCy={'VesselFixtureEditButton'}
                  label="Edit fixture"
                  onClick={() => {
                    showManageFixtureModal({
                      formData: d,
                      updateVessel: true,
                      callback: onFixtureChanged,
                    });
                  }}
                />
              ),
            },

            {
              key: 'VesselFixtureDeleteButton',
              label: (
                <MenuItem
                  dataCy={'VesselFixtureDeleteButton'}
                  label="Delete fixture"
                  onClick={() => {
                    showModal(
                      <Delete
                        title="Are you sure you want to delete this fixture for"
                        target={{
                          name: d.customVesselNameLabel,
                          id: d.id,
                        }}
                        onDelete={(
                          id: number,
                          setErrors: (arg0: {errors: {msg: string}[]}) => TODO,
                          close: () => void
                        ) => {
                          deleteFixture(d.id).then(
                            () => {
                              onFixtureChanged();
                              close();
                            },
                            (error: TODO) =>
                              setErrors({errors: [{msg: error?.payload?.title ?? 'Something went wrong'}]})
                          );
                        }}
                      />
                    );
                  }}
                />
              ),
            },
          ]}
        />
      ),
      cell: info => info.getValue(),
    },
  ];

  const buyerColumnIndex = columns.findIndex(col => col.id === 'chartererName');
  columns[buyerColumnIndex] = {
    header: 'Buyer',
    id: 'buyerName',
    accessorFn: ({buyerName}: {buyerName: string}) => {
      return (
        <div className={'market-grid-cell fixture-grid-cell__charterer-name'}>
          <div className={'market-grid-cell-content market-grid-cell-content--no-sub'}>{buyerName}</div>
        </div>
      );
    },
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 8,
  };

  const sellerColumnIndex = columns.findIndex(col => col.id === 'location');
  columns[sellerColumnIndex] = {
    header: 'Seller',
    id: 'sellerName',
    accessorKey: 'sellerName',
    accessorFn: ({sellerName}: {sellerName: string}) => (
      <div className={'market-grid-cell fixture-grid-cell__charterer-name'}>
        <div className={'market-grid-cell-content market-grid-cell-content--no-sub'}>{sellerName}</div>
      </div>
    ),
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 8,
  };

  columns.splice(
    columns.findIndex(col => col.id === 'period'),
    1
  );

  return columns;
};
