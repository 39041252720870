/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {NewSubject, NewSubjectFromJSON, NewSubjectFromJSONTyped, NewSubjectToJSON} from './';

/**
 *
 * @export
 * @interface NewSubjectsRequest
 */
export interface NewSubjectsRequest {
  /**
   * In which negotiation the new term should be added.
   * @type {string}
   * @memberof NewSubjectsRequest
   */
  negotiationId: string;
  /**
   * Terms to be added to the negotiation.
   * @type {Array<NewSubject>}
   * @memberof NewSubjectsRequest
   */
  newSubjects: Array<NewSubject>;
}

export function NewSubjectsRequestFromJSON(json: any): NewSubjectsRequest {
  return NewSubjectsRequestFromJSONTyped(json, false);
}

export function NewSubjectsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewSubjectsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    negotiationId: json['negotiationId'],
    newSubjects: (json['newSubjects'] as Array<any>).map(NewSubjectFromJSON),
  };
}

export function NewSubjectsRequestToJSON(value?: NewSubjectsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    negotiationId: value.negotiationId,
    newSubjects: (value.newSubjects as Array<any>).map(NewSubjectToJSON),
  };
}
