import {useQuery} from '@tanstack/react-query';
import {vesselVoyageTimelineApi} from '../../../../api/node-backend/nodeBackendApis';

export const useGetDraftChartQuery = (imo?: number | null) =>
  useQuery({
    queryKey: ['vesselVoyageTimelineApi.getDraftChart', imo],
    queryFn: async () => {
      const response = await vesselVoyageTimelineApi.getDraftChart({imo: imo!});
      return response;
    },
    enabled: !!imo,
  });
