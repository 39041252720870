import {PageHeader} from '@ant-design/pro-layout';
import {Col, Divider, Row} from 'antd';
import produce from 'immer';
import {useContext, FC} from 'react';
import styled from 'styled-components';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import {durationAccuracy} from '../VoyageInformation/utils/visualizationAccuracy';
import {CalculatorName} from './CalculatorName';
import {CalculatorNote} from './CalculatorNote';
import {CO2EmissionBox} from './CO2EmissionBox';
import {DurationBox} from './DurationBox';
import {Favorite} from './Favorite';
import {FreightIdea} from './FreightIdea';
import {FreightIdeaUnit} from './FreightIdeaUnit';
import {OpenButton} from './OpenButton';
import {SaveAsNewButton} from './SaveAsNewButton';
import {SaveChangesButton} from './SaveChangesButton';
import {SeaMarginBox} from './SeaMarginBox';
import {TCEBox} from './TCEBox';
import {TotalCommission} from './TotalCommission';

export const PageHeaderBox: FC<{id?: number}> = ({id}) => {
  const voyageContext = useContext(VoyageCharterContext);

  const {outputState, inputState} = voyageContext.state;

  return (
    <StyledAffix>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledPageHeader
            data-cy={'PageHeader'}
            ghost={false}
            title={
              <FavouriteContainer className="header-head">
                <Favorite />
                <CalculatorName />
              </FavouriteContainer>
            }
            extra={
              <>
                <OpenButton key={'open'} />
                {id && <SaveChangesButton key={'saveChanges'} id={id} />}
                <SaveAsNewButton key={'saveNew'} />
              </>
            }>
            <Divider className="header-divider" style={{marginTop: 0, marginBottom: 15}} />
            <HeaderContainer>
              <HeaderNotes className="header-notes">
                <CalculatorNote />
              </HeaderNotes>
              <InputRow>
                <InputContainer>
                  <FreightIdea
                    freightIdea={inputState.cargo.freightIdea}
                    onChangeIdea={value => {
                      voyageContext.setInputState(
                        produce(inputState, draftInputState => {
                          draftInputState.cargo.freightIdea = value;
                        })
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <FreightIdeaUnit
                    freightUnit={inputState.cargo.freightUnit}
                    onChangeUnit={value => {
                      voyageContext.setInputState(
                        produce(inputState, draftInputState => {
                          draftInputState.cargo.freightUnit = value;
                        })
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <TotalCommission
                    value={inputState.cargo.totalCommission}
                    onChange={value => {
                      voyageContext.setInputState(
                        produce(inputState, draftInputState => {
                          draftInputState.cargo.totalCommission = value;
                        })
                      );
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <SeaMarginBox inputState={inputState} setInputState={voyageContext.setInputState} />
                </InputContainer>
                <InputContainer>
                  <TCEBox value={outputState.cargo.tce} />
                </InputContainer>
                <InputContainer>
                  <DurationBox
                    isMarginRight={false}
                    value={outputState.cargo.duration}
                    durationAccuracy={durationAccuracy}
                  />
                </InputContainer>
                <InputContainer>
                  <CO2EmissionBox />
                </InputContainer>
              </InputRow>
            </HeaderContainer>
          </StyledPageHeader>
        </Col>
      </Row>
    </StyledAffix>
  );
};

const StyledAffix = styled.div`
  transition: all 0.3s;
  position: sticky;
  top: -11px;
  z-index: 22;
  box-shadow: var(--box-shadow-widget-dragging);
  border-radius: var(--border-radius-card);
  overflow: hidden;
`;

const StyledPageHeader = styled(PageHeader)`
  border-radius: var(--border-radius);
  padding: 16px 0;
  background-color: var(--color-white);

  .ant-page-header-content {
    padding-top: 4px;
  }
  .ant-page-header-heading {
    padding: 4px 24px 16px 24px;
  }
`;

const InputContainer = styled.div`
  margin: 0 8px 18px 8px;
  min-width: 210px;
  flex: 1 1 0px;
`;

const InputRow = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  padding: 0 12px;
`;

const HeaderNotes = styled.div`
  flex: 1;
  padding: 0 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FavouriteContainer = styled.div`
  display: flex;
  justify-content: center;
`;
