import {ParsedVesselData} from '../../../../../api/parser/generated';
import {TODO} from '../../../../../utils/TODO';

export const getConsumptions = (vesselData: ParsedVesselData) => {
  const consumptions: TODO = [];

  // Ballast
  if (vesselData.consumptionBallast && vesselData.speedBallast) {
    consumptions.push({
      type: 'ballast',
      speed: vesselData.speedBallast,
      fuelConsumption: vesselData.consumptionBallast,
      fuelType: 'ifo',
      extraFuelType: 'mdo',
    });
  }
  if (vesselData.ecoConsumptionBallast && vesselData.ecoSpeedBallast) {
    consumptions.push({
      type: 'ballast',
      speed: vesselData.ecoSpeedBallast,
      fuelConsumption: vesselData.ecoConsumptionBallast,
      fuelType: 'ifo',
      extraFuelType: 'mdo',
    });
  }

  // Laden
  if (vesselData.consumptionLaden && vesselData.speedLaden) {
    consumptions.push({
      type: 'laden',
      speed: vesselData.speedLaden,
      fuelConsumption: vesselData.consumptionLaden,
      fuelType: 'ifo',
      extraFuelType: 'mdo',
    });
  }
  if (vesselData.ecoConsumptionLaden && vesselData.ecoSpeedLaden) {
    consumptions.push({
      type: 'laden',
      speed: vesselData.ecoSpeedLaden,
      fuelConsumption: vesselData.ecoConsumptionLaden,
      fuelType: 'ifo',
      extraFuelType: 'mdo',
    });
  }

  // Port working
  if (vesselData.consumptionPortWorking) {
    consumptions.push({
      type: 'portworking',
      speed: 0,
      fuelConsumption: vesselData.consumptionPortWorking,
      fuelType: 'ifo',
      extraFuelType: 'mdo',
    });
  }

  // Port idle
  if (vesselData.consumptionPort) {
    consumptions.push({
      type: 'portidle',
      speed: vesselData.ecoSpeedLaden,
      fuelConsumption: vesselData.ecoConsumptionLaden,
      fuelType: 'ifo',
      extraFuelType: 'mdo',
    });
  }

  return consumptions;
};
