import {FC} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useClearClipboardOnToggleArchive} from '../../components/useClearClipboardOnToggleArchive/useClearClipboardOnToggleArchive';
import {MyCargoesArchiveScreen} from './MyCargoesScreen/MyCargoesArchiveScreen';
import {MyCargoesScreen} from './MyCargoesScreen/MyCargoesScreen';

export const MyCargoesScreens: FC = () => {
  useClearClipboardOnToggleArchive({
    section: 'portfolio',
    subsection: 'cargoes',
  });

  return (
    <Routes>
      <Route path="/" element={<MyCargoesScreen />} />
      <Route path="/archive" element={<MyCargoesArchiveScreen />} />
    </Routes>
  );
};
