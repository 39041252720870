import React, {ReactNode} from 'react';
import styled, {CSSProperties, css} from 'styled-components';

type Props = {
  calculated?: boolean;
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
};

export const Value = styled(({calculated, style, className, children}: Props) => {
  return (
    <td style={style} className={className}>
      <StyledTableCellContent $calculated={calculated}>{children}</StyledTableCellContent>
    </td>
  );
})`
  vertical-align: top;
`;

const StyledTableCellContent = styled.div<{$calculated?: boolean}>`
  display: flex;
  align-items: center;
  min-height: 18px;
  padding: 0 2px;
  cursor: pointer;

  ${props =>
    props.$calculated &&
    css`
      color: var(--transparent-extradark);
    `}

  .ant-form-item .ant-form-item-control-input {
    min-height: unset;
  }
`;
