import React, {useContext} from 'react';
import {Card} from 'antd';
import numeral from 'numeral';
import {VoyageCharterContext} from '../../Context/VoyageCharterContext';
import styled, {css} from 'styled-components';
import {Chart} from './Chart';
import {StyledTable} from '../../components/StyledTable';
import {WidgetTitle} from '../../components/WidgetTitle';

export const FinanceOverview = (props: {showBody: boolean; onChangeShowBody: (showBody: boolean) => void}) => {
  const voyageContext = useContext(VoyageCharterContext);
  const {outputState} = voyageContext.state;

  let data: Array<{
    value: number;
    name: string;
  }> = [];

  data.push({
    name: 'Gross freight',
    value: outputState.cargo.revenueNet,
  });

  data.push({
    name: 'Bunker cost',
    value: -outputState.cost.costConsumption,
  });

  data.push({
    name: 'Port cost',
    value: -outputState.cost.sumPortCosts,
  });
  data.push({
    name: 'CO2 emission expenses',
    value: -outputState.cost.costCo2,
  });
  data.push({
    name: 'Other expenses',
    value: -outputState.cost.otherCostsSum,
  });
  data.push({
    name: 'Commission',
    value: -outputState.cost.costCommission,
  });

  data.push({
    name: 'Net revenue',
    value: outputState.cargo.revenueTotal,
  });
  data.push({
    name: 'TCE',
    value: outputState.cargo.tce,
  });
  data = data.map(x => ({...x, key: x.name}));

  return (
    <StyledCard
      key={'1'}
      $showBody={props.showBody}
      data-cy={'FinanceOverviewVisualization'}
      title={
        <WidgetTitle
          icon="file"
          title={`Earnings / Cost breakdown`}
          showBody={props.showBody}
          onToggleBody={() => {
            props.onChangeShowBody(!props.showBody);
          }}></WidgetTitle>
      }>
      <StyledChartContainer style={{height: 300}}>{props.showBody && <Chart />}</StyledChartContainer>
      <StyledTable
        size={'small'}
        pagination={false}
        dataSource={data}
        columns={[
          {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            render: v => {
              if (v === 'TCE') {
                return <StyledTCE>{v}</StyledTCE>;
              }
              if (v === 'Total Revenue') {
                return <StyledRevenue>{v}</StyledRevenue>;
              }
              return v;
            },
          },
          {
            key: 'value',
            title: 'Costs in USD',
            dataIndex: 'value',
            align: 'right',
            render: (v, item) => {
              if (item.name === 'TCE') {
                return <StyledTCE>{numeral(v).format('$ 0,0')}</StyledTCE>;
              }
              if (item.name === 'Total Revenue') {
                return <StyledRevenue>{numeral(v).format('$ 0,0')}</StyledRevenue>;
              }
              return numeral(v).format('$ 0,0');
            },
          },
        ]}
      />
    </StyledCard>
  );
};

const StyledTCE = styled.span`
  font-weight: 1000;
  font-size: var(--font-size-md);
`;
const StyledRevenue = styled.span`
  font-weight: 600;
`;

const StyledChartContainer = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  transition: height 2s;
  margin-bottom: 24px;
`;

const StyledCard = styled(Card)<{$showBody?: boolean}>`
  flex-direction: column;

  .ant-card-head {
    padding-top: 10px;
  }

  .ant-card-body {
    display: none;
  }

  ${({$showBody}) =>
    $showBody &&
    css`
      height: 100%;
      display: flex;

      .ant-card-body {
        display: block;
      }
    `}
`;
