import React, {Component} from 'react';
import {connect} from 'react-redux';
import BaseModal from '../../../atoms/BaseModal/index';
import CompanyActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {ProfileInput} from '../../ProfileInput';
import LabelWrapper from '../../../atoms/LabelWrapper';
import {Button, Input} from 'antd';
import './style.scss';
import {RootState} from '../../../redux/store';
import {isValidationProblem} from '../../../api/utils/ValidationProblemType';
import {DispatchFunc} from '../../../redux/AppThunk';
import {handleError} from '../../../api/utils/reactQueryClient';
import {ModalWidth} from '../../../antd/components/Modal';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';

const {TextArea} = Input;

interface Workspace {
  name: string;
  email?: string | null;
  description?: string | null;
}

interface EditWorkspace extends Workspace {
  id: number;
}

type AddProps = {
  method: 'add';
  editWorkspaceData?: never;
};

type EditProps = {
  method: 'edit';
  editWorkspaceData: EditWorkspace;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    close?: () => void;
  } & (AddProps | EditProps);

interface State {
  disableButton: boolean;
  workspace: Workspace;
  error: boolean;
  nameUniqueError: string | undefined;
}

class CreateWorkspaceModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {editWorkspaceData, method} = props;
    this.state = {
      error: false,
      disableButton: false,
      nameUniqueError: '',
      workspace: {
        name: method === 'edit' ? editWorkspaceData.name : '',
        email: method === 'edit' ? editWorkspaceData.email : '',
        description: method === 'edit' ? editWorkspaceData.description : '',
      },
    };
  }

  onChange = (changeState: string, changedValue: $TSFixMe) => {
    if (changeState === 'email') {
      this.setState((prev: State) => ({
        ...prev,
        workspace: {
          ...prev.workspace,
          [changeState]: changedValue,
        },
      }));
    } else {
      this.setState((prev: State) => ({
        ...prev,
        workspace: {
          ...prev.workspace,
          [changeState]: changedValue,
        },
      }));
    }
  };

  addWorkspace = () => {
    this.setState({disableButton: true});
    if (this.props.method === 'edit') {
      this.props
        .editWorkspace(this.props.company.id, this.props.editWorkspaceData.id, this.state.workspace)
        .then(() => {
          this.props.getWorkspaceList(this.props.company.id);
          this.setState({disableButton: false});
          this.props.close?.();
        })
        .catch((error: unknown) => {
          this.checkForErrors(error);
          this.setState({disableButton: false});
        });
    } else if (this.props.method === 'add') {
      this.props
        .addWorkspace(this.props.company.id, this.state.workspace)
        .then(() => {
          this.props.getWorkspaceList(this.props.company.id);
          this.setState({disableButton: false});
          this.props.close?.();
        })
        .catch((error: unknown) => {
          this.checkForErrors(error);
          this.setState({disableButton: false});
        });
    }
  };

  checkForErrors = (error: unknown) => {
    const {workspacePayloadError} = this.props;
    if (isValidationProblem(workspacePayloadError)) {
      // We have a ValidationProblem
      workspacePayloadError.violations!.forEach(validationProblemViolation => {
        this.setState({nameUniqueError: validationProblemViolation.title});
      });
    } else if (workspacePayloadError.error) {
      // We have something custom designed by ITS
      const errors = workspacePayloadError.error.errors;
      errors.forEach((e: $TSFixMe) => {
        if (e.location === 'name') {
          this.setState({nameUniqueError: e.message});
        }
      });
    } else {
      handleError(error);
    }
  };

  render() {
    const {disableButton, workspace, nameUniqueError} = this.state;
    const {description, name} = workspace;
    return (
      <>
        <div className={'company-add-workspace-modal'}>
          <ProfileInput
            label={'Workspace name'}
            onChange={this.onChange}
            selectedName={'name'}
            selectedValue={name}
            type={'text'}
            hasError={!!nameUniqueError}
          />
          {!!nameUniqueError && <p className="workspace-info__error">{nameUniqueError}</p>}

          <LabelWrapper label={'Description'} contentWrapperStyle={{marginBottom: 0}}>
            <TextArea
              value={description !== null ? description : ''}
              onChange={event => this.onChange('description', event.target.value)}
              rows={3}
              style={{backgroundColor: 'var(--color-gray-6'}}
            />
          </LabelWrapper>
        </div>

        <ModalFooter className={'company-add-workspace-buttons'}>
          <Button
            disabled={disableButton}
            type="primary"
            id={'company-add-workspace__save'}
            onClick={this.addWorkspace}>
            {this.props.method === 'edit' ? 'edit workspace' : 'add workspace'}
          </Button>
        </ModalFooter>
      </>
    );
  }
}
const CreateWorkspace = (props: Props) => (
  <BaseModal title={props.method === 'edit' ? 'Edit workspace' : 'Add workspace'} width={ModalWidth.Middle}>
    {modalProps => <CreateWorkspaceModal {...props} {...modalProps} />}
  </BaseModal>
);

const mapStateToProps = (state: RootState) => ({
  company: state.company.data,
  users: state.company.users,
  workspacePayloadError: state.company.workspacePayloadError,
});

const mapDispatchToProps = (dispatch: DispatchFunc) => ({
  addWorkspace: (id: number, data: Workspace) => dispatch(CompanyActions.addWorkspace(id, data)),
  editWorkspace: (id: number, workspaceId: number, data: Workspace) =>
    dispatch(CompanyActions.editWorkspace(id, workspaceId, data)),
  getWorkspaceList: (id: number) => dispatch(CompanyActions.getWorkspaceList(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkspace);
