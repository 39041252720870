import {useState, FC} from 'react';
import {Button, Col, Row} from 'antd';
import {ImportVesselWithParser} from './ImportVesselWithParser';
import {SeaboVesselPickerWithFlag} from '../../../../SeaboVesselPicker/SeaboVesselPickerWithFlag';
import styled from 'styled-components';
import {Attribute} from '../../../../../atoms/Attribute/Attribute';
import {
  Headline,
  FullWidthButton,
  VerticalSeparator,
  BorderBox,
  Wrapper,
  PickerHeadline,
  PickerWrapper,
} from '../utils/components';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {VesselType} from '../../../../../api/symfony/generated';
import {SeaboVessel} from '../../../../../api/node-backend/generated';
import {fetchJSON} from '../../../../../api/utils/fetchJSON';

export const VesselImport: FC<{
  vesselId?: number;
  onSubmit: (vessel: SeaboVessel) => void;
  onSkip: () => void;
}> = ({vesselId, onSubmit, onSkip}) => {
  const vesselQuery = useQuery({
    queryKey: [vesselId],
    queryFn: async () => {
      if (!vesselId) {
        return null;
      }
      return await fetchJSON<SeaboVessel>(`/api/vessels/${vesselId}`);
    },
    placeholderData: keepPreviousData,
  });

  const preselectedVessel = vesselQuery?.data ?? null;
  const [vessel, setVessel] = useState<null | SeaboVessel>(preselectedVessel);

  return (
    <Row gutter={[16, 16]} justify={'center'}>
      <Col span={24}>
        <Headline>Search or Parse vessel</Headline>
      </Col>
      <Col span={11}>
        <Wrapper>
          <BorderBox $highlight={!!vessel}>
            <PickerHeadline>
              {vessel ? 'Your chosen Vessel: ' : 'Search for Project or My Fleet Vessel below'}
              {!vessel && <SupportNotice>(Bulk only)</SupportNotice>}
            </PickerHeadline>
            <PickerWrapper>
              <SeaboVesselPickerWithFlag
                size="middle"
                seaboVessel={vessel ?? undefined}
                indexNames={'vessels_portfolio'}
                vesselTypes={[VesselType.Bulker]}
                onChange={vessel => setVessel((vessel as unknown as SeaboVessel) ?? null)}
              />
            </PickerWrapper>
            <Attribute label={'Name'} value={vessel?.name} tab={'xxl'} />
            <Attribute label={'IMO'} value={vessel?.imo} tab={'xxl'} />
            <Attribute label={'Shipbuilder'} value={vessel?.shipBuilder} tab={'xxl'} />
            <Attribute label={'Vessel type'} value={vessel?.vesselType} upper={true} tab={'xxl'} />
            <Attribute label={'Design type'} value={vessel?.designType} upper={true} tab={'xxl'} />
            <Attribute label={'Design subtype'} value={vessel?.designSubType} upper={true} tab={'xxl'} />
          </BorderBox>
          <FullWidthButton
            data-cy="NextButton"
            type={'primary'}
            onClick={() => (vessel ? onSubmit(vessel) : null)}
            disabled={!vessel}>
            Next
          </FullWidthButton>
        </Wrapper>
      </Col>
      <Col span={1}>
        <VerticalSeparator>or</VerticalSeparator>
      </Col>
      <Col span={11}>
        <ImportVesselWithParser onSubmit={onSubmit} />
      </Col>
      <Col span={24}>
        <SkipButton data-cy="SkipButton" ghost={true} onClick={onSkip}>
          Skip
        </SkipButton>
      </Col>
    </Row>
  );
};

const SupportNotice = styled.span`
  margin-left: 5px;
  font-weight: normal;
  color: var(--color-gray-2);
`;

const SkipButton = styled(Button)`
  margin: 15px 36px 10px;
  width: calc(100% - 72px);
`;
