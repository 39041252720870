import React, {useContext} from 'react';
import {MapContext} from '../MapContext/MapContext';
import {SettingOutlined} from '@ant-design/icons';
import {StyledButton} from './StyledButton';
import {Tooltip} from 'antd';

export const SettingsViewButton = () => {
  const mapContext = useContext(MapContext);

  if (mapContext.props.settingsButtonVisible === false) {
    return null;
  }
  return (
    <Tooltip title={'Settings'} placement="left">
      <StyledButton
        onClick={() => {
          mapContext.setShowSettingView(true);
        }}>
        <SettingOutlined />
      </StyledButton>
    </Tooltip>
  );
};
