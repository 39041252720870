import {Button} from 'antd';
import styled from 'styled-components';
import {Card, Typography, Divider} from 'antd';
import {Icon} from '../../../atoms/Icon';

const {Title} = Typography;

export const TitleStyled = styled(Title)`
  padding-top: 34px;
  padding-bottom: 0;
  margin-bottom: 5px !important;
  font-weight: 900;
`;

export const Dialog = styled(Card)`
  border-radius: 16px;
  width: 450px;

  .ant-card-body {
    padding: 0;

    &::after {
      display: none;
    }
  }

  .ant-form-item-with-help {
    margin-bottom: 40px;
  }
`;

export const DividerStyled = styled(Divider)`
  margin-bottom: 31px;
`;

export const Instruction = styled.p`
  color: var(--color-black);
  text-align: center;
  margin-bottom: 20px;
`;

export const InputLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 3px;
  height: auto;
`;

export const Asterisk = styled.span`
  display: inline-block;
  margin-left: 1px;
  font-weight: normal;
  color: var(--color-gray-2);
`;

export const Layout = styled.div`
  margin-left: 62px;
  margin-right: 62px;
  margin-bottom: 42px;
`;

export const BottomArea = styled.div`
  padding: 0 62px 42px;
`;

export const BottomLabel = styled.label`
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: var(--color-gray-2);
`;

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
`;

export const BottomLink = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
  margin-top: 10px;
  padding-left: 3px;
  padding-right: 5px;
  background: var(--color-gray-2) !important;
  text-align: left;
  font-weight: bold;
  white-space: normal;
  text-transform: none !important;
  color: var(--color-white) !important;

  ${StyledIcon} {
    margin-right: 5px;
    font-size: var(--font-size-md) !important;
    color: inherit !important;
  }

  &:hover {
    background: var(--color-gray-1) !important;
  }
`;

export const CtaButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase !important;
  background: var(--color-upgrade) !important;
`;

export const InfoIcon = styled.span`
  box-sizing: border-box;
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  margin-left: 0.3em;
  border-radius: 100%;
  vertical-align: top;
  text-align: center;
  padding: 0 0.1em;
  background: var(--color-gray-4);
  font-size: var(--font-size-sm);
  color: var(--color-gray-1);
  font-weight: bold;
`;
