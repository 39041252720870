import dayjs from 'dayjs';
import {FC} from 'react';
import {CurrentUser, FeaturePermission, Role} from '../../../../../api/symfony/generated';
import {MenuItem} from '../../../../../atoms/Menu/MenuItem';
import NamedAvatar from '../../../../../atoms/NamedAvatar';
import {ActionMenu} from '../../../../../components/ActionMenu/ActionMenu';
import DetailTable from '../../../../../components/DetailTable';
import DetailTableBody from '../../../../../components/DetailTable/DetailTableBody';
import DetailsTableCell from '../../../../../components/DetailTable/DetailTableCell';
import DetailTableRow from '../../../../../components/DetailTable/DetailTableRow';
import CompanyUser from '../../../../../model/company/CompanyUser';
import {Workspace} from '../../../../../redux/ApiService/PortfolioService';
import {useDispatch} from '../../../../../redux/react-redux';
import {useIsPermitted} from '../../../../../utils/useIsPermitted';
import {DateTimeFormat} from '../../../../../utils/DateTimeFormat';
import {NotAvailable} from '../../../../../utils/NotAvailable';
import {ModalActions} from '../../../../../redux/Modal';
import {showNotification} from '../../../../../utils/notification';
import {useUser} from '../../../../../components/UserContext/useUser';
import {Delete} from '../../../../../components/Modal/Delete/DeleteModal';

interface Props {
  users: CompanyUser[];
  onRemove: (user: CompanyUser) => Promise<void>;
  onEdit: (user: CompanyUser) => void;
}

const CompanyListUser: FC<Props> = ({users, onRemove, onEdit}) => {
  const manageOwnCompanyPermitted = useIsPermitted(FeaturePermission.ManageOwnCompany);
  const user = useUser();
  const currentUser: CurrentUser = user.payload;

  const dispatch = useDispatch();

  return (
    <DetailTable fullWidth>
      <DetailTableRow headerWrapper>
        <DetailsTableCell width={'30%'} value={'NAME'} />
        <DetailsTableCell width={'20%'} value={'POSITION'} />
        <DetailsTableCell width={'20%'} value={'WORKSPACES'} />
        <DetailsTableCell width={'15%'} value={'MANAGER'} />
        <DetailsTableCell width={'15%'} value={'ADDED'} />
        <DetailsTableCell width={'3%'} value={''} />
      </DetailTableRow>
      <DetailTableBody>
        {users.length > 0 &&
          users.map((user: CompanyUser, index: number) => (
            <DetailTableRow key={index}>
              <DetailsTableCell>
                <NamedAvatar
                  name={user.fullName}
                  email={user.email}
                  imageUrl={user.avatarUrl}
                  isMe={user.id === currentUser!.id}
                />
              </DetailsTableCell>
              <DetailsTableCell value={user.companyPosition ? user.companyPosition : NotAvailable} />
              <DetailsTableCell
                value={
                  user.workspaces
                    ? user.workspaces.map((space: Workspace, index: number) => (
                        <span key={space.id}>{index === 0 ? space.name : `, ${space.name}`}</span>
                      ))
                    : NotAvailable
                }
              />
              <DetailsTableCell value={user.payload.roles.includes(Role.CompanyManager) ? 'Yes' : ''} />
              <DetailsTableCell value={user.added ? dayjs(user.added).format(DateTimeFormat.Date) : NotAvailable} />
              <DetailsTableCell>
                <ActionMenu
                  items={[
                    {
                      key: 'edit',
                      label: <MenuItem key="edit" label={'Edit user'} onClick={() => onEdit(user)} />,
                    },
                    {
                      key: 'remove',
                      isValid: manageOwnCompanyPermitted,
                      label: (
                        <MenuItem
                          key="remove"
                          label={'Remove'}
                          onClick={() => {
                            dispatch(
                              ModalActions.show(
                                <Delete
                                  target={{
                                    name: user.payload.fullName,
                                    id: user.payload.id,
                                  }}
                                  onDelete={async (_, __, close) => {
                                    await onRemove(user);
                                    showNotification(`${user.fullName} has been removed.`, 'success');
                                    close();
                                  }}
                                />
                              )
                            );
                          }}
                          active={manageOwnCompanyPermitted}
                        />
                      ),
                    },
                  ]}
                />
              </DetailsTableCell>
            </DetailTableRow>
          ))}
      </DetailTableBody>
    </DetailTable>
  );
};

export default CompanyListUser;
