import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../api/utils/fetchJSON';
import {Coordinates} from '../utils/Coordinates';
import {TODO} from '../utils/TODO';
import {getConfiguration} from '../utils/configuration';

type Params = {
  searchTag: string;
  type: 'country' | 'tradingarea';
};
const queryKey = 'useGetCoordinatesFromCountryOrTradingAreaQuery';
export const useGetCoordinatesFromCountryOrTradingAreaQuery = (params: Params) =>
  useQuery({
    queryKey: [queryKey, params.searchTag, params.type],
    queryFn: () => {
      return getCoordinatesFromCountryOrTradingArea(params);
    },
  });

export const getCoordinatesFromCountryOrTradingArea = async (params: Params): Promise<Coordinates | undefined> => {
  const searchTag = params.searchTag.trim();

  const json = await fetchJSON<TODO>(`${getConfiguration('REACT_APP_SEEKRAUTS_SERVICE_BASE_PATH')}/rauts2`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      startPort: searchTag,
      endPort: searchTag,
    }),
  });

  if (json.errorCode === -2) {
    return undefined;
  }
  const coordinates: number[] = json.waypoints[0];

  return [coordinates[0], coordinates[1]];
};
