import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import {useMemo} from 'react';
import {TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import {ClassInformation} from '../classInformation';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

export type DateTiedUpPercentage = {
  dateString: string;
  tiedUpPercentage: number;
};

export const useGlobalTiedUpVesselsTrendChartDataSource = (
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[],
  vesselClass: ClassInformation
) => {
  return useMemo(
    () => calculateGlobalTiedUpVesselsTrendChartDataSource(tradingAreaTiedUpVesselsStatistics, vesselClass),
    [tradingAreaTiedUpVesselsStatistics, vesselClass]
  );
};

export const calculateGlobalTiedUpVesselsTrendChartDataSource = (
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[],
  vesselClass: ClassInformation
) => {
  // from all trading areas all days in one array
  const allDaysOverAllTradingAreas = tradingAreaTiedUpVesselsStatistics.flatMap(
    tradingArea => tradingArea.oneDayStatistics
  );

  const daysGrouped = groupBy(allDaysOverAllTradingAreas, item => dayjs(item.date).format(DateTimeFormat.IsoDate));

  const daysDateStrings = Object.keys(daysGrouped).sort();

  const percentageOfTiedUpPerDay: DateTiedUpPercentage[] = daysDateStrings.map(dateString => {
    const allRecordsOfADay = daysGrouped[dateString];
    const sumTiedUpSize = sumBy(allRecordsOfADay, item => vesselClass.accessor(item));
    const tiedUpPercentage = Math.round((sumTiedUpSize / vesselClass.size) * 100);
    return {dateString, tiedUpPercentage};
  });
  return percentageOfTiedUpPerDay;
};
