import useLocalStorageFromBeautifulHooks from 'beautiful-react-hooks/useLocalStorage';
import {assert} from './assert';
import {z} from 'zod';

/**
 * Saves a value in local storage.
 *
 * Requires a default value to make sure that the returned value is never null.
 */
interface UseLocalStorage {
  (storageKey: string, defaultValue: number): [number, (value: number | ((previousValue: number) => number)) => void];
  (storageKey: string, defaultValue: string): [string, (value: string | ((previousValue: string) => string)) => void];
  (
    storageKey: string,
    defaultValue: boolean
  ): [boolean, (value: boolean | ((previousValue: boolean) => boolean)) => void];
  <T>(storageKey: string, defaultValue: T, schema: z.ZodType<T>): [T, (value: T | ((previousValue: T) => T)) => void];
}
export const useLocalStorage: UseLocalStorage = <TValue>(
  storageKey: string,
  defaultValue: TValue,
  schema?: z.ZodType<TValue>
): [TValue, (value: TValue | ((previousValue: TValue) => TValue)) => void] => {
  const [value, setValue] = useLocalStorageFromBeautifulHooks<TValue>(storageKey, defaultValue);
  assert(value !== null, 'useLocalStorage: value is null');
  const mySchema = schema ?? findSchema<TValue>(value);

  const result = mySchema.safeParse(value);
  if (result.success) {
    return [result.data as TValue, setValue];
  }

  return [value, setValue];
};

const findSchema = <T>(value: T) => {
  switch (typeof value) {
    case 'string':
      return z.string();
    case 'number':
      return z.number();
    case 'boolean':
      return z.boolean();
    default:
      throw new Error('schema is missing');
  }
};

interface GetLocalStorage {
  (name: string, defaultValue: number): number;
  (name: string, defaultValue: string): string;
  (name: string, defaultValue: boolean): boolean;
  <T>(name: string, defaultValue: T, schema: z.ZodType<T>): T;
}

/**
 * Helper to retrieve a value from local storage
 *
 * After beautiful-react-hooks JSON.stringify's the value, we need to parse strings, number and booleans to get rid of hyphens
 */
export const getLocalStorage: GetLocalStorage = <T>(name: string, defaultValue: T, schema?: z.ZodType<T>): T => {
  const storageValue = localStorage.getItem(name);
  const value: T = storageValue ? JSON.parse(storageValue) : defaultValue;
  const mySchema = schema ?? findSchema<T>(defaultValue);

  const result = mySchema.safeParse(value);
  if (result.success) {
    return result.data as T;
  } else {
    // eslint-disable-next-line no-console
    console.warn('getLocalStorage result error', result.error.issues);
    return defaultValue;
  }
};

export const setLocalStorage = <T>(name: string, value: T | string | number | boolean): void => {
  localStorage.setItem(name, JSON.stringify(value));
};
