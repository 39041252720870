/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ConsentContractVersion
 */
export interface ConsentContractVersion {
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersion
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersion
   */
  title: string;
  /**
   *
   * @type {Date}
   * @memberof ConsentContractVersion
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ConsentContractVersion
   */
  updatedAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ConsentContractVersion
   */
  validFrom: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ConsentContractVersion
   */
  publishedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersion
   */
  consentContractId: string;
  /**
   *
   * @type {string}
   * @memberof ConsentContractVersion
   */
  checksum: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ConsentContractVersion
   */
  fileUploaded: boolean;
}

export function ConsentContractVersionFromJSON(json: any): ConsentContractVersion {
  return ConsentContractVersionFromJSONTyped(json, false);
}

export function ConsentContractVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentContractVersion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    createdAt: new Date(json['createdAt']),
    updatedAt: new Date(json['updatedAt']),
    validFrom: json['validFrom'] === null ? null : new Date(json['validFrom']),
    publishedAt: json['publishedAt'] === null ? null : new Date(json['publishedAt']),
    consentContractId: json['consentContractId'],
    checksum: json['checksum'],
    fileUploaded: json['fileUploaded'],
  };
}

export function ConsentContractVersionToJSON(value?: ConsentContractVersion | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    createdAt: value.createdAt.toISOString(),
    updatedAt: value.updatedAt.toISOString(),
    validFrom: value.validFrom === null ? null : value.validFrom.toISOString(),
    publishedAt: value.publishedAt === null ? null : value.publishedAt.toISOString(),
    consentContractId: value.consentContractId,
    checksum: value.checksum,
    fileUploaded: value.fileUploaded,
  };
}
