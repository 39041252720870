import {makeApiService, ApiServiceEntry} from './ApiService';
import {TODO} from '../../utils/TODO';

const entries: ApiServiceEntry[] = [
  {
    fn: 'getRoute',
    route: '/api/routes/fromto/lon:{fromLon}lat:{fromLat}/lon:{toLon}lat:{toLat}',
    method: 'GET',
  },
];

const routeApi = makeApiService(entries, {apiName: 'routes'});
export const reducers = routeApi.reducers;
export const actions = routeApi.actions;
export const actionTypes = routeApi.actionTypes;

type GetParams = {
  fromLon: number;
  fromLat: number;
  toLon: number;
  toLat: number;
  speed: number;
  avoidSeca: TODO;
  avoidPiracy: TODO;
  blockedAreas: TODO;
};

export const RouteServiceActions = {
  get: ({fromLon, fromLat, toLon, toLat, speed, avoidSeca, avoidPiracy, blockedAreas}: GetParams) =>
    actions.getRoute({
      queryParams: {speed, avoidSeca, avoidPiracy, blockedAreas},
      params: {fromLon, fromLat, toLon, toLat},
    }),
};

export default RouteServiceActions;
