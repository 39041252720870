import {useSelector} from '../../../redux/react-redux';
import {CompanySettingsWorkspace} from '../../CompanyScreens/CompanySettingsScreen/CompanySettingsWorkspace/CompanySettingsWorkspace';
import {OnboardingStepWrapper} from '../OnboardingStepWrapper';
import {OnboardingStepProps} from '../useUserOnboarding';

export const ConfigureWorkspaces = (props: OnboardingStepProps) => {
  const company = useSelector(state => state.company.data);
  return (
    <OnboardingStepWrapper
      title="Setup your workspaces"
      subTitle="Please configure your workspaces and email inboxes to receive your circulars inside seabo."
      dataTestId="ConfigureWorkspaces"
      {...props}>
      <CompanySettingsWorkspace company={company} />
    </OnboardingStepWrapper>
  );
};
