import React, {FC} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AccountSegments} from '../../api/symfony/generated';
import {accountManagementApi} from '../../api/symfony/symfonyApi';
import {invalidateAccountSegmentsQuery, useAccountSegmentsQuery} from '../../queries/useAccountSegmentsQuery';
import {AccountSegmentSelectorSteps} from './AccountSegmentSelector/AccountSegmentSelectorSteps';
import {AccountSegmentSelectorTabs} from './AccountSegmentSelector/AccountSegmentSelectorTabs';

export const AccountSegmentsSettings: FC<{
  type: 'steps' | 'tabs';
  onSave: (accountSegments: AccountSegments) => void;
}> = ({type, onSave}) => {
  const accountSegmentQuery = useAccountSegmentsQuery();
  const queryClient = useQueryClient();

  const saveMutation = useMutation({
    mutationFn: async (accountSegments: AccountSegments) => {
      await accountManagementApi.updateAccountSegments({
        accountSegments,
      });
      return accountSegments;
    },
    onSuccess: (data: AccountSegments) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateAccountSegmentsQuery(queryClient);
      onSave?.(data);
    },
  });

  if (!accountSegmentQuery.isSuccess) {
    return null;
  }

  const hasAccountSegments = !!accountSegmentQuery.data.userSelfCategorization;
  const values: AccountSegments = hasAccountSegments
    ? accountSegmentQuery.data
    : {
        sizes: [],
        userSelfCategorization: [],
        tradingAreas: [],
        types: [],
        commodities: [],
      };

  return (
    <div data-testid="AccountSegmentSettings">
      {type === 'steps' ? (
        <AccountSegmentSelectorSteps
          values={values}
          onSubmit={data => {
            saveMutation.mutate(data);
          }}
        />
      ) : (
        <AccountSegmentSelectorTabs
          values={values}
          onSubmit={data => {
            saveMutation.mutate(data);
          }}
        />
      )}
    </div>
  );
};
