import React, {FC} from 'react';
import {Typography} from 'antd';
import {ExportReportsModal} from '../../../../components/ExportReportsModal/ExportReportsModal';
import {vesselVoyageTimelineExcelReportsApi} from '../../../../api/node-backend/nodeBackendApis';
import dayjs from 'dayjs';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';

const {Paragraph} = Typography;

const getReports = (imo: number) => [
  {
    label: 'Leg based voyage timeline report',
    id: 'leg-based-voyage-timeline-report',
    filename: `Leg-based-voyage-timeline-report-IMO: ${imo} / ${dayjs().format(DateTimeFormat.DateTime)}.xlsx`,
    downloadFunc: async (imo: number): Promise<Blob> => vesselVoyageTimelineExcelReportsApi.getLegBasedReport({imo}),
  },
  {
    label: 'Area visits report',
    id: 'area-visits-report',
    filename: `Area-visits-report-IMO: ${imo} / ${dayjs().format(DateTimeFormat.DateTime)}.xlsx`,
    downloadFunc: async (imo: number): Promise<Blob> => vesselVoyageTimelineExcelReportsApi.getAreaVisitsReport({imo}),
  },
  {
    label: 'Vessel changes report',
    id: 'vessel-changes-report',
    filename: `Vessel-changes-report-IMO: ${imo} / ${dayjs().format(DateTimeFormat.DateTime)}.xlsx`,
    downloadFunc: async (imo: number): Promise<Blob> =>
      vesselVoyageTimelineExcelReportsApi.getVesselChangesReport({imo}),
  },
  {
    label: 'Daily activity report',
    id: 'daily-activity-report',
    filename: `Daily-activity-report-IMO: ${imo} / ${dayjs().format(DateTimeFormat.DateTime)}.xlsx`,
    downloadFunc: async (imo: number): Promise<Blob> =>
      vesselVoyageTimelineExcelReportsApi.getDailyActivityReport({imo}),
  },
  {
    label: 'Overview report',
    id: 'overview-report',
    filename: `Overview-report.xlsx`,
    downloadFunc: async (imo: number): Promise<Blob> => vesselVoyageTimelineExcelReportsApi.getOverviewReport({imo}),
  },
];

export const ExportVoyageTimelineModal: FC<{
  visible: boolean;
  imo: number;
  onClose: () => void;
}> = ({visible, onClose, imo}) => {
  const reports = getReports(imo);

  return (
    <ExportReportsModal
      visible={visible}
      title="Export voyage timeline"
      description={
        <Paragraph>You are about to download the voyage timeline of the viewed vessel as an Excel file.</Paragraph>
      }
      availableReports={reports}
      buttonLabel="Export voyage timeline"
      loadReportsFromServer={async (reportList: string[]) => {
        const reportId = reportList[0];

        const report = reports.find(r => r.id === reportId);
        if (!report) {
          throw new Error(`Unknown report ${reportId}`);
        }
        const blob = await report.downloadFunc(imo);

        return {
          filename: report.filename,
          blob,
        };
      }}
      onClose={onClose}
    />
  );
};
