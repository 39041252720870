import styled from 'styled-components';
import HorizontalLine from '../../../../atoms/HorizontalLine';
import {RecommendationsTransition, Recommendation} from './RecommendationsTransition';

export const Recommendations = ({
  recommendations,
  showMore,
}: {
  recommendations: Recommendation[];
  showMore: () => void;
}) => {
  if (!recommendations || recommendations.length === 0) {
    return null;
  }
  return (
    <>
      <TitleWrapper>
        <StyledScreenTitle title={'People you might know'} />
      </TitleWrapper>
      <HorizontalLineContainer>
        <HorizontalLine />
      </HorizontalLineContainer>
      <RecommendationContainer>
        <RecommendationsTransition recommendations={recommendations.slice(0, 8)} />
      </RecommendationContainer>
      {recommendations.length > 0 && <ShowMore onClick={showMore}>Show others</ShowMore>}
    </>
  );
};

const TitleWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HorizontalLineContainer = styled.div`
  margin: 10px 0;
`;

const RecommendationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 302px;
  margin-left: -5px;
`;

const ShowMore = styled.div`
  text-align: center;
  color: var(--color-blue);
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
  font-size: var(--font-size-lg);
`;

const StyledScreenTitle = styled.div`
  justify-content: flex-start;
  flex: 0;
`;
