import {useQuery} from '@tanstack/react-query';
import {negotiationNegotiationApi} from '../../../../api/node-backend/nodeBackendApis';
import {UseQueryOptions} from '../../../../api/utils/ReactQuery';
import {ApiError} from '../../../../api/utils/ApiError';
import {NegotiationRoundResultSummary} from '../../../../api/node-backend/generated';

export const FIND_ROUND_RESULTS_BY_NEGOTIATION_ID_QUERY_KEY = (negotiationId?: string) => [
  'negotiationNegotiationApi.findRoundResultsByNegotiationId',
  negotiationId,
];

export const useFindRoundResultsByNegotiationIdQuery = (
  negotiationId: string,
  options?: UseQueryOptions<NegotiationRoundResultSummary[], ApiError>
) =>
  useQuery({
    queryKey: FIND_ROUND_RESULTS_BY_NEGOTIATION_ID_QUERY_KEY(negotiationId),
    queryFn: async () => {
      const response = await negotiationNegotiationApi.findRoundResultsByNegotiationId({negotiationId});
      return response;
    },
    refetchOnWindowFocus: true,
    ...options,
  });
