import {useQueryClient} from '@tanstack/react-query';
import {Dayjs} from 'dayjs';
import {useEffect, useState} from 'react';
import {calcPointLoadDisDuration} from '../../../../utils/voyageChartercalculation/calcPointDuration';
import {calcTangentialHalvesOfTheDayAndWaitingTime} from '../../../../utils/updatesWaitingtimesOnInputstate/calcTangentialHalvesOfTheDayAndWaitingTime';
import {getTerm} from '../../../../utils/updatesWaitingtimesOnInputstate/calcNewInputState';
import {CalcTimeLinePoint} from '../../../VoyageTypes';

export type WaitingTimes = {
  interWaitingTimeBasedOnTerm: number;
  initalWaitingTime: number;
  congestionBasedWaitingTime: number;
  totalWaitingTime: number;
};

export const useGetSuggestedWaitingTimes = ({
  laycanFrom,
  avgDurationLast10VesselsInMinutes,
  legItem,
}: {
  avgDurationLast10VesselsInMinutes: number | undefined;
  legItem: CalcTimeLinePoint;
  laycanFrom: Dayjs | undefined;
}): WaitingTimes | null => {
  const queryClient = useQueryClient();

  const [waitingTimes, setWaitingTimes] = useState<WaitingTimes | null>(null);

  const calculateWaitingTimes = async () => {
    if (!laycanFrom || !legItem.item || !avgDurationLast10VesselsInMinutes) {
      return;
    }

    const {durationLoadDis} = calcPointLoadDisDuration({voayagePoint: legItem.item});

    const result = await calcTangentialHalvesOfTheDayAndWaitingTime({
      countryCode: legItem.item.countryCode,
      isFirstLoadingLeg: legItem.isFirstLoadingLeg,
      laycanFrom: laycanFrom,
      queryClient: queryClient,
      startDate: legItem.outputItem?.startDate,
      term: getTerm(legItem.item) ?? 'SHINC',
      totalDuration: durationLoadDis,
    });

    const avgDurationLast10VesselsInHours = Math.round(avgDurationLast10VesselsInMinutes / 60);

    setWaitingTimes({
      interWaitingTimeBasedOnTerm: result.interWaitingTimeBasedOnTerm,
      initalWaitingTime: result.initalWaitingTime,
      congestionBasedWaitingTime: avgDurationLast10VesselsInHours,
      totalWaitingTime: result.interWaitingTimeBasedOnTerm + result.initalWaitingTime + avgDurationLast10VesselsInHours,
    });
  };

  useEffect(
    () => {
      void calculateWaitingTimes();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      avgDurationLast10VesselsInMinutes,
      legItem.isFirstLoadingLeg,
      legItem.item,
      laycanFrom,
      queryClient,
      legItem.outputItem?.startDate,
    ]
  );

  return waitingTimes;
};
