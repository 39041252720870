/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VoyagePort, VoyagePortFromJSON, VoyagePortFromJSONTyped, VoyagePortToJSON} from './';

/**
 *
 * @export
 * @interface DryDockVisitDryDock
 */
export interface DryDockVisitDryDock {
  /**
   *
   * @type {number}
   * @memberof DryDockVisitDryDock
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DryDockVisitDryDock
   */
  name: string;
  /**
   * Polygons include potentially multiple polygons. A polygon consists of coordinate pairs. The order is longitude and than latitude
   * @type {Array<Array<Array<number>>>}
   * @memberof DryDockVisitDryDock
   */
  polygon: Array<Array<Array<number>>>;
  /**
   *
   * @type {VoyagePort}
   * @memberof DryDockVisitDryDock
   */
  port: VoyagePort;
}

export function DryDockVisitDryDockFromJSON(json: any): DryDockVisitDryDock {
  return DryDockVisitDryDockFromJSONTyped(json, false);
}

export function DryDockVisitDryDockFromJSONTyped(json: any, ignoreDiscriminator: boolean): DryDockVisitDryDock {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    polygon: json['polygon'],
    port: VoyagePortFromJSON(json['port']),
  };
}

export function DryDockVisitDryDockToJSON(value?: DryDockVisitDryDock | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    polygon: value.polygon,
    port: VoyagePortToJSON(value.port),
  };
}
