/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  VesselDataListResponseItems,
  VesselDataListResponseItemsFromJSON,
  VesselDataListResponseItemsFromJSONTyped,
  VesselDataListResponseItemsToJSON,
} from './';

/**
 *
 * @export
 * @interface VesselDataListResponse
 */
export interface VesselDataListResponse {
  /**
   *
   * @type {number}
   * @memberof VesselDataListResponse
   */
  totalItems: number;
  /**
   *
   * @type {Array<VesselDataListResponseItems>}
   * @memberof VesselDataListResponse
   */
  items: Array<VesselDataListResponseItems>;
}

export function VesselDataListResponseFromJSON(json: any): VesselDataListResponse {
  return VesselDataListResponseFromJSONTyped(json, false);
}

export function VesselDataListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselDataListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalItems: json['totalItems'],
    items: (json['items'] as Array<any>).map(VesselDataListResponseItemsFromJSON),
  };
}

export function VesselDataListResponseToJSON(value?: VesselDataListResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalItems: value.totalItems,
    items: (value.items as Array<any>).map(VesselDataListResponseItemsToJSON),
  };
}
