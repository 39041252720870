import {cargoAccessor} from '../../utils/cargoAccessorResult';
import CargoUtil from '../../../../screens/market/MarketCargo/CargoGrid/CargoUtil';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';
import {ColumnHeader} from '../../../DataGrid/ColumnHeader/ColumnHeader';
import Cargo from '../../../../model/Cargo';
import {CargoGridColumnsProps} from './CargoGridColumnsProps';

const durationAccessor = (type: $TSFixMe) =>
  cargoAccessor(
    (d: Cargo) => (
      <div className="market-grid-cell-content__duration" data-cy="cell-duration">
        {CargoUtil.formatDuration(d)}
      </div>
    ),
    type
  );
export const durationColumn = ({
  type,
  minWidth = 4,
}: {
  type: $TSFixMe;
  minWidth?: number;
}): CustomColumnDef<CargoGridColumnsProps> => ({
  header: () => (
    <ColumnHeader sortable data-cy="header-duration">
      Duration
    </ColumnHeader>
  ),
  id: 'duration',
  enableSorting: true,
  accessorFn: durationAccessor(type),
  cell: info => info.getValue(),
  sortingFn: (a, b) => {
    return (a.original.cargo.durationMin || 0) - (b.original.cargo.durationMin || 0);
  },
  minWidth,
  minSize: minWidth,
});
