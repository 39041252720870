/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {AisRecordsResponse, AisRecordsResponseFromJSON, AisRecordsResponseToJSON} from '../models';

export interface GetAISRecordsForYearRequest {
  imo: number;
  year: number;
}

export interface GetFreshAISRecordsRequest {
  imo: number;
  from: string;
}

/**
 *
 */
export class AISRecordApi extends runtime.BaseAPI {
  /**
   * Returns all AIS records for a given IMO and year. Represents them as CSV string that needs to be parsed on the client side. Triggers a 404 if no data is found.
   */
  async getAISRecordsForYearRaw(requestParameters: GetAISRecordsForYearRequest): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getAISRecordsForYear.'
      );
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        'year',
        'Required parameter requestParameters.year was null or undefined when calling getAISRecordsForYear.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.imo !== undefined) {
      queryParameters['imo'] = requestParameters.imo;
    }

    if (requestParameters.year !== undefined) {
      queryParameters['year'] = requestParameters.year;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/ais-records`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Returns all AIS records for a given IMO and year. Represents them as CSV string that needs to be parsed on the client side. Triggers a 404 if no data is found.
   */
  async getAISRecordsForYear(requestParameters: GetAISRecordsForYearRequest): Promise<Blob> {
    const response = await this.getAISRecordsForYearRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns all fresh AIS records for a given IMO and start date.
   */
  async getFreshAISRecordsRaw(
    requestParameters: GetFreshAISRecordsRequest
  ): Promise<runtime.ApiResponse<AisRecordsResponse>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getFreshAISRecords.'
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        'from',
        'Required parameter requestParameters.from was null or undefined when calling getFreshAISRecords.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.imo !== undefined) {
      queryParameters['imo'] = requestParameters.imo;
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = requestParameters.from;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/ais-records/fresh`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => AisRecordsResponseFromJSON(jsonValue));
  }

  /**
   * Returns all fresh AIS records for a given IMO and start date.
   */
  async getFreshAISRecords(requestParameters: GetFreshAISRecordsRequest): Promise<AisRecordsResponse> {
    const response = await this.getFreshAISRecordsRaw(requestParameters);
    return await response.value();
  }
}
