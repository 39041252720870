import React, {FC} from 'react';
import {EventType} from '../../../../api/symfony/generated/models/EventType';
import {Notification} from '../../../../api/symfony/generated/models/Notification';

/**
 * This is the place where we implement the different EventType formats.
 */
export const FormattedNotificationMessage: FC<{notification: Notification}> = ({notification}) => {
  switch (notification.eventType) {
    case EventType.FriendRequest: {
      const author = notification.authors[0];
      return (
        <span>
          <a href={`/user/${author.id}`} title={author.fullName}>
            {author.fullName}
          </a>{' '}
          sent you a connection request.
        </span>
      );
    }

    default:
      // EventType.Generic and any unknown EventType will simply render the default message.
      return <span>{notification.message}</span>;
  }
};
