import {FC} from 'react';
import {FilterProviderApi} from '../../components/FilterProvider/FilterProviderApi';
import General from '../../components/FilterProvider/Filters/General/General';
import {Size} from '../../components/FilterProvider/Filters/Size/Size';
import DateOpen from '../../components/FilterProvider/Filters/DateOpen/DateOpen';
import Dimensions from '../../components/FilterProvider/Filters/Dimensions/Dimensions';
import Ownership from '../../components/FilterProvider/Filters/Ownership/Ownership';
// import VesselPrivacy from '../../components/FilterProvider/Filters/VesselPrivacy';
import Intake from '../../components/FilterProvider/Filters/Intake/Intake';
import PositionOpen from '../../components/FilterProvider/Filters/PositionOpen/PositionOpen';
import VesselTypeFilter from '../../components/FilterProvider/Filters/VesselType/VesselType';
import Features from '../../components/FilterProvider/Filters/Features/Features';
import Posted from '../../components/FilterProvider/Filters/Posted/Posted';
import {FilterBar} from '../../components/FilterBar/FilterBar';
import {MarketVesselFilterBranchDefinitions} from '../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';

interface Props {
  vesselFilterProviderApi: FilterProviderApi<typeof MarketVesselFilterBranchDefinitions>;
}

export const MarketVesselFilters: FC<Props> = ({vesselFilterProviderApi}) => (
  <FilterBar
    id="vesselFilters"
    filters={[
      <VesselTypeFilter filterProviderApi={vesselFilterProviderApi} />,
      <General filterProviderApi={vesselFilterProviderApi} />,
      <Size filterProviderApi={vesselFilterProviderApi} />,
      <Dimensions filterProviderApi={vesselFilterProviderApi} />,
      <Intake filterProviderApi={vesselFilterProviderApi} />,
      <Features filterProviderApi={vesselFilterProviderApi} />,
      <DateOpen filterProviderApi={vesselFilterProviderApi} />,
      <PositionOpen filterProviderApi={vesselFilterProviderApi} />,
      <Ownership filterProviderApi={vesselFilterProviderApi} />,
      // <VesselPrivacy filterProviderApi={vesselFilterProviderApi} />,
      <Posted filterProviderApi={vesselFilterProviderApi} type={'vessel'} />,
    ]}
  />
);
