import WidgetContainer from './WidgetContainer';
import WidgetHeader from './WidgetHeader';

const ErrorWidget = ({onRemove, msg}: $TSFixMe) => (
  <WidgetContainer color="light">
    <WidgetHeader title="Error" icon="close" onRemove={onRemove} />
    <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{msg}</div>
  </WidgetContainer>
);

export const NotFoundWidget = (props: $TSFixMe) => <ErrorWidget {...props} msg="Unable to load Widget" />;

export default ErrorWidget;
