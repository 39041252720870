import React, {CSSProperties, ReactNode, FC} from 'react';
import cx from 'classnames';
import Flag from '../../atoms/Flags/index';
import {LocationAlias} from './LocationAlias';
import {Link} from 'react-router-dom';

export type PortLocationPort = {
  name?: string;
  code?: string;
  countryObject?: {
    code: string;
    name: string;
  };
  highlight?: Record<string, string[]>;
};
interface PortLocationProps {
  location: PortLocationPort;
  style?: CSSProperties;
  onClick?: () => void;
  renderAlias?: boolean;
  link?: boolean;
}

/**
 *
 * @param link If true, the location will be wrapped in a Link component.
 */
export const PortLocation: FC<PortLocationProps> = ({location, style, onClick, renderAlias = false, link}) => {
  const LinkWrapperComponent = ({children}: {children: ReactNode}) =>
    link ? <Link to={`/ports/${location.code}`}>{children}</Link> : <>{children}</>;
  return (
    <LinkWrapperComponent>
      <div
        className={cx('location-output__location', {'location-output__location--clickable': onClick})}
        data-cy="Port"
        data-cy-location={location.name}
        style={style}
        onClick={onClick}>
        <Flag countryCode={location.countryObject?.code} border width={13} height={10} />
        {location.name && (
          <span data-cy="PortLocationTitle" className={'location-output__title'}>
            {location.name}
          </span>
        )}
        {location.code && (
          <span data-cy="PortLocationSubTitle" className={'location-output__sub-title'}>
            ({location.countryObject?.name}, {location.code})
          </span>
        )}
        {renderAlias && <LocationAlias location={location} />}
      </div>
    </LinkWrapperComponent>
  );
};
