import {useQuery} from '@tanstack/react-query';
import {fetchJSON} from '../../../../../../api/utils/fetchJSON';
import {VesselApiResponse, VesselVoyages, StatsVessel} from '../../../../../../screens/VesselDetailScreen/utils/types';

export const useVesselVoyagesQuery = (id: number) =>
  useQuery({
    queryKey: ['vessel', 'voyages', id],
    queryFn: async () => {
      const response = await fetchJSON<VesselApiResponse<VesselVoyages>>(`/api/vessels/${id}/voyages`);
      return response;
    },
  });

export const useStatsVesselQuery = (imo: number | null) =>
  useQuery({
    queryKey: ['vessel', 'stats', imo],
    queryFn: async () => {
      const response = await fetchJSON<VesselApiResponse<StatsVessel>>(`/api/stats/vessel?imo=${imo}`);
      return response;
    },
    enabled: !!imo,
  });
