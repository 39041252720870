import React, {FC} from 'react';
import {Form} from 'antd';
import {IntakeFormItem} from './FormItems/IntakeFormItem';
import {LoaFormItem} from './FormItems/LoaFormItem';
import {BeamFormItem} from './FormItems/BeamFormItem';
import {SpeedFormItem} from './FormItems/SpeedFormItem';
import {aisInitialFilters} from '../../mapOptions/aisInitialFilters';
import {ResetFormRow} from './ResetFormRow';
import {StyledDivider} from '../../MapCtrl/StyledDivider';
import {NamePath} from 'antd/es/form/interface';
import {DraftFormItem} from './FormItems/DraftFormItem';

export const AisFilterForm: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (filters: Record<string, any>) => void;
  disabled: boolean;
}> = ({filters, disabled, onChange}) => {
  const [form] = Form.useForm();

  const onValuesChange = () => {
    const values = form.getFieldsValue();
    onChange(values);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSetValuesViaApi = (name: NamePath, value: any): void => {
    form.setFieldValue(name, value);

    onValuesChange();
  };

  const intakeUnit = Form.useWatch<'teu' | 'dwt'>('intakeUnit', form);

  return (
    <Form
      initialValues={filters}
      form={form}
      layout="vertical"
      onValuesChange={() => {
        onValuesChange();
      }}>
      <StyledDivider />
      <ResetFormRow
        disabled={disabled}
        onReset={() => {
          form.setFieldsValue(aisInitialFilters);
          onValuesChange();
        }}
      />
      <IntakeFormItem disabled={disabled} intakeUnit={intakeUnit} onSetValuesViaApi={onSetValuesViaApi} />
      <StyledDivider />
      <LoaFormItem disabled={disabled} onSetValuesViaApi={onSetValuesViaApi} />
      <StyledDivider />
      <BeamFormItem onSetValuesViaApi={onSetValuesViaApi} disabled={disabled} />
      <StyledDivider />
      <SpeedFormItem onSetValuesViaApi={onSetValuesViaApi} disabled={disabled} />
      <StyledDivider />
      <DraftFormItem onSetValuesViaApi={onSetValuesViaApi} disabled={disabled} />
    </Form>
  );
};
