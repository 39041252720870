import {RouteItem} from '../useRouteTripLayer';
import {getDistance} from 'geolib';
import {getFirstLastTimestampsFromRouteItem} from './getFirstLastTimestampsFromRouteItem';
import {DistanceCalculation} from '../../../../../api/symfony/generated';

export const getRouteItemForDistanceCalculation = ({
  distanceCalculationResult,
  startTimestamp,
  type = 'calculated',
}: {
  distanceCalculationResult: DistanceCalculation;
  startTimestamp: number;
  type?: string;
}): RouteItem => {
  let lastTime = startTimestamp;
  const waypointsCalc = distanceCalculationResult.waypoints.map((item, index) => {
    const itemT1 = distanceCalculationResult.waypoints[index - 1];

    const latIndex = 0;
    const lngIndex = 1;
    const distanceInKM = itemT1
      ? getDistance(
          {
            lat: itemT1[latIndex],
            lng: itemT1[lngIndex],
          },
          {
            lat: item[latIndex],
            lng: item[lngIndex],
          }
        ) / 1000
      : 0;

    const distanceInNM = distanceInKM / 1.852;

    const timePerDistance = distanceCalculationResult.duration / distanceCalculationResult.distance;

    const duration = timePerDistance * distanceInNM;
    lastTime = lastTime + duration;

    return {
      latLng: {
        lat: item[latIndex],
        lng: item[lngIndex],
      },
      timestamp: lastTime,
    };
  });
  // calculated
  return {
    waypoints: waypointsCalc,
    firstLast: getFirstLastTimestampsFromRouteItem(waypointsCalc),
    type,
  };
};
