import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as VesselTypeDefinition from '../VesselType/VesselTypeDefinition';
import * as GeneralDefinition from '../General/GeneralDefinition';
import * as SizeDefinition from '../Size/SizeDefinition';
import * as DimensionsDefinition from '../Dimensions/DimensionsDefinition';
import * as IntakeDefinition from '../Intake/IntakeDefinition';
import * as FeaturesDefinition from '../Features/FeaturesDefinition';
import * as DateOpenDefinition from '../DateOpen/DateOpenDefinition';
import * as PositionOpenDefinition from '../PositionOpen/PositionOpenDefinition';
import * as OwnershipDefinition from '../Ownership/OwnershipDefinition';
import * as VesselPrivacyDefinition from '../VesselPrivacy/VesselPrivacyDefinition';
import * as PostedDefinition from '../Posted/PostedDefinition';

export const MarketVesselFilterBranchDefinitions = makeFilterBranchDefinitions([
  VesselTypeDefinition.filterBranchDefinition,
  GeneralDefinition.filterBranchDefinition,
  SizeDefinition.filterBranchDefinition,
  DimensionsDefinition.filterBranchDefinition,
  IntakeDefinition.filterBranchDefinition,
  FeaturesDefinition.filterBranchDefinition,
  DateOpenDefinition.filterBranchDefinition,
  PositionOpenDefinition.filterBranchDefinition,
  OwnershipDefinition.filterBranchDefinition,
  VesselPrivacyDefinition.filterBranchDefinition,
  PostedDefinition.filterBranchDefinition,
] as const);

export type MarketVesselInternalFilter = Partial<FilterInternalType<typeof MarketVesselFilterBranchDefinitions>>;
export type MarketVesselDatabaseFilter = Partial<FilterDatabaseType<typeof MarketVesselFilterBranchDefinitions>>;
