import {Helmet} from 'react-helmet-async';
import {MinimalLayout} from '../../atoms/Layout/MinimalLayout';
import {Button, Typography} from 'antd';
import {OnboardingStepProps} from './useUserOnboarding';
import {FullScreenLoadingAnimation} from '../../atoms/FullScreenLoadingAnimation/FullScreenLoadingAnimation';
import {AddUsers} from './Steps/AddUsers';
import {ConfigureWorkspaces} from './Steps/ConfigureWorkspaces';
import {Finished} from './Steps/Finished';
import {SetupSegments} from './Steps/SetupSegments';
import {UpgradePlan} from './Steps/UpgradePlan';
import {VerifyCompany} from './Steps/VerifyCompany';
import {OnboardingStep} from './getLastNotCompletedStep';

const {Title} = Typography;

export const UserOnboardingScreen = ({
  onboardingStep,
  stepProps,
}: {
  onboardingStep: OnboardingStep;
  stepProps: OnboardingStepProps;
}) => {
  const {onSkip} = stepProps;

  if (stepProps.isLoading) {
    return <FullScreenLoadingAnimation />;
  }

  return (
    <>
      <Helmet title="Welcome to seabo" />
      <MinimalLayout
        backgroundColor="var(--color-gray-5)"
        navbarAddon={
          <>
            <Title level={4} style={{margin: '0 32px 0 0'}}>
              Welcome to seabo
            </Title>
            <Button data-testid="OnboardingSkipButton" type="dashed" size="large" onClick={onSkip}>
              Skip onboarding for now
            </Button>
          </>
        }>
        <OnboardingStepSwitch onboardingStep={onboardingStep} stepProps={stepProps} />
      </MinimalLayout>
    </>
  );
};

export const OnboardingStepSwitch = ({
  onboardingStep,
  stepProps,
}: {
  onboardingStep: OnboardingStep;
  stepProps: OnboardingStepProps;
}) => {
  switch (onboardingStep) {
    case OnboardingStep.VerifyCompany:
      return <VerifyCompany {...stepProps} />;
    case OnboardingStep.AddUsers:
      return <AddUsers {...stepProps} />;
    case OnboardingStep.ConfigureWorkspaces:
      return <ConfigureWorkspaces {...stepProps} />;
    case OnboardingStep.UpgradePlan:
      return <UpgradePlan {...stepProps} />;
    case OnboardingStep.SetupSegments:
      return <SetupSegments {...stepProps} />;
    case OnboardingStep.Finished:
      return <Finished {...stepProps} />;
    default:
      return null;
  }
};
