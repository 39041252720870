/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VesselFilters, VesselFiltersFromJSON, VesselFiltersFromJSONTyped, VesselFiltersToJSON} from './';

/**
 *
 * @export
 * @interface VesselDbReportRequest
 */
export interface VesselDbReportRequest {
  /**
   *
   * @type {VesselFilters}
   * @memberof VesselDbReportRequest
   */
  filters?: VesselFilters;
}

export function VesselDbReportRequestFromJSON(json: any): VesselDbReportRequest {
  return VesselDbReportRequestFromJSONTyped(json, false);
}

export function VesselDbReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VesselDbReportRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    filters: !exists(json, 'filters') ? undefined : VesselFiltersFromJSON(json['filters']),
  };
}

export function VesselDbReportRequestToJSON(value?: VesselDbReportRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    filters: VesselFiltersToJSON(value.filters),
  };
}
