import {Dayjs} from 'dayjs';

export const getInitialWaitingTimeBasedOnLaycan = ({
  isFirstLoadingLeg,
  laycanFrom,
  startDate,
}: {
  isFirstLoadingLeg: boolean;
  laycanFrom?: Dayjs;
  startDate: Dayjs;
}): number => {
  if (!isFirstLoadingLeg) {
    return 0;
  }

  if (laycanFrom && startDate) {
    if (startDate.isBefore(laycanFrom)) {
      const hoursToWait = laycanFrom.diff(startDate, 'hours');

      return hoursToWait;
    }
  }
  return 0;
};
