import React, {useState} from 'react';
import {MulticalcVesselInput, MulticalcCargoInput} from '../../types';
import {MultiCalculation} from '../../useMultiCalculation';
import {OneCalcResult} from '../../utils/oneCalc';
import {ConfigProvider, Table} from 'antd';
import {OneCargo} from './OneCargo';
import {OneVessel} from './OneVessel';
import {OneResult} from './OneResult';
import styled from 'styled-components';
import {Icon} from '../../../../../atoms/Icon';
import {getFilteredMatrix} from './getFilteredMatrix';
import {assert, assertUnreachable} from '../../../../../utils/assert';
import {ButtonGroup, ButtonGroupButton} from '../../../../ButtonGroup';
import Input from '../../../../../atoms/Input';
import {Button} from '../../../../../atoms/Button/Button';
import Lighthouse from '../../../../../atoms/EmptyState/LightHouse';
import LoadingAnimation from '../../../../../atoms/Loading';
import {Center} from '../../../../Center/Center';
import {LoadingStatus} from '../components';

type Props = {
  vessels: MulticalcVesselInput[];
  cargoes: MulticalcCargoInput[];
  multiCalculation: MultiCalculation;
  isLoading?: boolean;
};

export const Matrix = ({vessels, cargoes, multiCalculation, isLoading}: Props) => {
  const [hideErroneousCalcs, setHideErroneousCalcs] = useState(true);
  const [scoreView, setScoreView] = useState<'duration' | 'freightIdea'>('duration');
  const [globalTce, setGlobalTce] = useState(0);

  const oneCalcResults: OneCalcResult[] = multiCalculation.oneCalcResults ?? [];

  const {vesselCount, cargoCount} = multiCalculation.matrix;

  const calcVessels: OneCalcResult[] = vessels.map(
    (_, index) => multiCalculation.matrix.getOneCalcResult(oneCalcResults, index, 0)!
  );
  const calcCargoes: OneCalcResult[] = cargoes.map(
    (_, index) => multiCalculation.matrix.getOneCalcResult(oneCalcResults, 0, index)!
  );

  const {
    filteredCalcVessels,
    filteredCalcCargoes,
    calcVesselMatrixIndices,
    calcCargoMatrixIndices,
    freightIdeaScores,
    durationScores,
  } = getFilteredMatrix(vessels, cargoes, multiCalculation);

  const vesselCountDiff = vesselCount - filteredCalcVessels.length;
  const cargoCountDiff = cargoCount - filteredCalcCargoes.length;

  let scoreArray: number[][];
  switch (scoreView) {
    case 'freightIdea':
      scoreArray = freightIdeaScores;
      break;
    case 'duration':
      scoreArray = durationScores;
      break;
    default:
      assertUnreachable(scoreView);
  }

  return (
    <>
      <p>See all possible Vessel/Cargo combinations at a glance.</p>
      {isLoading ? (
        <Center>
          <LoadingAnimation />
          <LoadingStatus />
        </Center>
      ) : (
        <>
          <FlexBox>
            <PaddedBorderBox>
              <p>
                <HighlightMarker>Score</HighlightMarker> combinations by least:
              </p>
              <ButtonGroup>
                <ButtonGroupButton
                  onClick={() => setScoreView('duration')}
                  id="duration"
                  active={scoreView === 'duration'}
                  label={'Duration'}></ButtonGroupButton>
                <ButtonGroupButton
                  onClick={() => setScoreView('freightIdea')}
                  id="freightIdea"
                  active={scoreView === 'freightIdea'}
                  label={'Freight idea'}></ButtonGroupButton>
              </ButtonGroup>
            </PaddedBorderBox>
            <PaddedBorderBox>
              <p>Set minimum TCE for all combinations:</p>
              <div className="flex">
                <Input type={'number'} onChange={event => setGlobalTce(parseFloat(event))} id={'custom-tce'} />
                <Button
                  secondary
                  onClick={() => multiCalculation.matrix.updateFreightIdeasByTce(oneCalcResults, globalTce)}>
                  Submit
                </Button>
              </div>
            </PaddedBorderBox>
          </FlexBox>
          <BorderBox>
            <ConfigProvider
              renderEmpty={() => (
                <Lighthouse
                  small
                  headline={'Sorry, no data could be shown.'}
                  subHeadline={'Click "Show errors" in the upper left corner or try a different combination.'}
                />
              )}>
              <Table<OneCalcResult>
                loading={multiCalculation.oneCalcResults === undefined}
                pagination={false}
                scroll={{x: '100%'}}
                dataSource={hideErroneousCalcs ? filteredCalcVessels : calcVessels}
                rowKey={oneCalcResult => {
                  return `${oneCalcResult?.vessel?.id}_${oneCalcResult?.vesselProject?.id ?? ''}`;
                }}
                columns={[
                  {
                    title:
                      vesselCountDiff || cargoCountDiff ? (
                        <ErrorBox>
                          <ErroneousCalcToggle>
                            <StyledIcon type={'block'} />
                            <div>
                              There are
                              {vesselCountDiff ? ` ${vesselCountDiff} faulty Vessels` : ''}
                              {vesselCountDiff && cargoCountDiff ? ' and ' : ' '}
                              {cargoCountDiff ? `${cargoCountDiff} faulty Cargoes` : ''}
                            </div>
                          </ErroneousCalcToggle>
                          <Button secondary onClick={() => setHideErroneousCalcs(value => !value)}>
                            {hideErroneousCalcs ? 'Show' : 'Hide'} errors
                          </Button>
                        </ErrorBox>
                      ) : (
                        ''
                      ),
                    key: 'vessels',
                    render: (_, vessel) => <OneVessel oneCalcResult={vessel} />,
                    fixed: 'left',
                    className: 'matrix-grid-bg-gray',
                    width: 265,
                  },
                  ...(hideErroneousCalcs ? filteredCalcCargoes : calcCargoes).map((cargo, colIndex) => {
                    return {
                      title: <OneCargo oneCalcResult={cargo} />,
                      key: 'cargo' + colIndex,
                      width: 300,
                      dataIndex: 'cargo' + colIndex,
                      render: (_: unknown, __: OneCalcResult, rowIndex: number) => {
                        const vesselIndex = hideErroneousCalcs ? calcVesselMatrixIndices[rowIndex] : rowIndex;
                        const cargoIndex = hideErroneousCalcs ? calcCargoMatrixIndices[colIndex] : colIndex;
                        const oneCalcResult = multiCalculation.matrix.getOneCalcResult(
                          oneCalcResults,
                          vesselIndex,
                          cargoIndex
                        );
                        assert(oneCalcResult !== undefined);
                        return (
                          <OneResult
                            key={oneCalcResult.voyageCalculationInput.cargo.freightIdea}
                            multiCalculation={multiCalculation}
                            oneCalcResult={oneCalcResult}
                            score={scoreArray[vesselIndex][cargoIndex]}
                            hideError={hideErroneousCalcs}
                          />
                        );
                      },
                    };
                  }),
                ]}
              />
            </ConfigProvider>
          </BorderBox>
        </>
      )}
    </>
  );
};

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ErrorBox = styled.div`
  position: absolute;
  inset: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  padding: 10px;
  background: var(--color-gray-5);
  border-radius: var(--border-radius);
`;

const BorderBox = styled.div`
  border: var(--border-light);
  border-radius: var(--border-radius);
  overflow: hidden;

  .matrix-grid-bg-gray {
    background: var(--color-gray-6);
    border-right: 1px solid var(--color-gray-5);
  }

  * {
    box-sizing: border-box;
  }
`;

const PaddedBorderBox = styled(BorderBox)`
  width: calc(50% - 10px);
  padding: 20px;
`;

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: 10px;
  transform: rotateZ(180deg);
`;

const ErroneousCalcToggle = styled.div`
  display: flex;
  align-items: center;
  max-width: 230px;
`;

const HighlightMarker = styled.span`
  padding: 2px 4px;
  background: rgba(22, 166, 0, 0.25);
  border: var(--border-width) solid rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
`;
