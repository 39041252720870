import {FC, useState} from 'react';
import {useDispatch} from 'react-redux';
import vesselService from '../../../redux/ApiService/vesselService';
import cargoService from '../../../redux/ApiService/cargoService';
import {ModalFooter} from '../../../atoms/BaseModal/ModalFooter';
import {Button} from 'antd';
import {Modal} from '../../../atoms/Modal/Modal';
import {ModalActions} from '../../../redux/Modal';
import {ClipboardItem} from '../../../redux/GridClipboard';
import {useSelector} from '../../../redux/react-redux';
import {ColorPicker} from '../../ColorPicker/ColorPicker';
import LabelWrapper from '../../../atoms/LabelWrapper';

interface ColorPickerModalContentProps {
  data?: {
    color: number | null;
    id: number;
  };
  isLoading?: boolean;
  onSave: (colorId: number | null | undefined) => void;
}

const ColorPickerModalContent: FC<ColorPickerModalContentProps> = ({data, isLoading, onSave}) => {
  const [colorId, setColorId] = useState(data?.color || null);

  return (
    <div>
      <LabelWrapper label="Marker colour" contentWrapperStyle={{marginBottom: 0}}>
        <ColorPicker colorId={data?.color as number} onChange={colorOption => setColorId(colorOption.colorId)} />
      </LabelWrapper>
      <ModalFooter>
        <Button disabled={isLoading} onClick={() => onSave(colorId)} type="primary">
          Save and close
        </Button>
      </ModalFooter>
    </div>
  );
};

interface ColorPickerModalProps {
  data?: {
    color: number | null;
    id: number;
  };
  targets?: ClipboardItem[];
  type: 'vessel' | 'cargo' | 'multiVessel' | 'multiCargo';
  reload: () => void;
}

const ColorPickerModal: FC<ColorPickerModalProps> = ({data, type, targets, reload}) => {
  const dispatch = useDispatch();

  const savingVesselLoading = useSelector(({api}) => api.vessels.patch.loading);
  const savingMultiVesselLoading = useSelector(({api}) => api.vessels.multiPatch.loading);
  const savingCargoLoading = useSelector(({api}) => api.cargoes.patch.loading);
  const savingMultiCargoLoading = useSelector(({api}) => api.cargoes.multiPatch.loading);

  const handleClose = () => {
    reload();
    dispatch(ModalActions.hide());
  };

  const handleSave = (colorId: number | null | undefined) => {
    if (type === 'vessel') {
      dispatch(vesselService.patch(data?.id, {color: colorId})).then(handleClose);
    } else if (type === 'cargo') {
      dispatch(cargoService.patch(data?.id, {color: colorId})).then(handleClose);
    } else if (type === 'multiVessel') {
      dispatch(vesselService.multiPatch({vessels: targets, color: colorId})).then(handleClose);
    } else if (type === 'multiCargo') {
      dispatch(cargoService.multiPatch({cargoes: targets, color: colorId})).then(handleClose);
    }
  };
  return (
    <Modal title="Set colour marker" width={440} open footer={null} centered destroyOnClose onCancel={handleClose}>
      <ColorPickerModalContent
        data={data}
        isLoading={savingVesselLoading || savingMultiVesselLoading || savingCargoLoading || savingMultiCargoLoading}
        onSave={handleSave}
      />
    </Modal>
  );
};

export default ColorPickerModal;
