import {useState} from 'react';
import {assert} from '../../utils/assert';
import {Coordinates} from '../../utils/Coordinates';
import {MapApi, MapApiState} from './MapApi';
import {blankState} from './MapContext/blankstate';
import {MapInstance} from './MapContext/Types';

/**
 * Provides SeaboMap's control interface, similar to Ant Design's Form instance object.
 * See https://ant.design/components/form/#FormInstance
 *
 * It allows a parent of SeaboMap to update it.
 *
 * For example:
 *
 * ```
 *   const mapApi = useMapApi();
 *
 *   return (
 *     <div>
 *       <SeaboMapWithSettings map={mapApi} .../>
 *       <Button onClick={() => {
 *         mapApi.fitBounds([[30, 100], [40, 120]])
 *        }}>
 *        Recenter
 *       </Button>
 *     </div>
 *   )
 * ```
 */
export const useMapApi = (): MapApi => {
  const [mapApi, _] = useState<MapApi>(() => new MapApiImplementation());
  return mapApi;
};

/**
 * This is an implementation detail of SeaboMap and is NOT part of the public interface!
 */
export interface PrivateMapApi extends MapApi {
  setMapInstance: (mapInstance: MapInstance) => void;
}

class MapApiImplementation implements PrivateMapApi {
  private _mapInstance: MapInstance | null = null;

  setMapInstance(mapInstance: MapInstance) {
    this._mapInstance = mapInstance;
  }

  private get mapInstance(): MapInstance {
    assert(this._mapInstance, 'mapApi used before it was initialized by <SeaboMap>');
    return this._mapInstance!;
  }

  public get state(): MapApiState {
    // If the map instance is not yet available,
    // then haven't rendered the SeabmoMap component yet and can't offer any properties from SeaboMap.
    // Therefore the caller can't expect these props to be in the API.
    // Still we want the caller to be able to depend on the api state prop, because it makes
    // the caller's code more straight forward.
    // Therefore we offer the default state.
    if (!this._mapInstance) {
      return blankState;
    }

    return this.mapInstance.state;
  }

  public fitBounds(coords: Coordinates[]) {
    this.mapInstance.fitBounds(coords);
  }
}
