/* tslint:disable */
/* eslint-disable */
/**
 * Holiday Service
 * Holiday Service
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CountryResponse,
  CountryResponseFromJSON,
  CountryResponseToJSON,
  Holiday,
  HolidayFromJSON,
  HolidayToJSON,
  Query,
  QueryFromJSON,
  QueryToJSON,
} from '../models';

export interface GetHolidaysRequest {
  query?: Query;
}

/**
 *
 */
export class HolidayServiceApi extends runtime.BaseAPI {
  /**
   * Get all available countries/regions.
   */
  async getCountriesRaw(): Promise<runtime.ApiResponse<CountryResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/holidayservice/countries`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CountryResponseFromJSON(jsonValue));
  }

  /**
   * Get all available countries/regions.
   */
  async getCountries(): Promise<CountryResponse> {
    const response = await this.getCountriesRaw();
    return await response.value();
  }

  /**
   * Provides a list of all holidays either fixed or dynamic holidays per country/region. It can be chosen if list provides a general list or an upcoming list of holidays.
   * Get all available holidays per countries/regions
   */
  async getHolidaysRaw(requestParameters: GetHolidaysRequest): Promise<runtime.ApiResponse<Array<Holiday>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/holidayservice/holidays`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: QueryToJSON(requestParameters.query),
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(HolidayFromJSON));
  }

  /**
   * Provides a list of all holidays either fixed or dynamic holidays per country/region. It can be chosen if list provides a general list or an upcoming list of holidays.
   * Get all available holidays per countries/regions
   */
  async getHolidays(requestParameters: GetHolidaysRequest): Promise<Array<Holiday>> {
    const response = await this.getHolidaysRaw(requestParameters);
    return await response.value();
  }

  /**
   * This endpoint is used by Kubernetes to check the application\'s liveness and readiness. It always returns a 200 OK status with the text \'OK\'.
   * Simple health check for liveness and readiness.
   */
  async getPingRaw(): Promise<runtime.ApiResponse<string>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/ping`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * This endpoint is used by Kubernetes to check the application\'s liveness and readiness. It always returns a 200 OK status with the text \'OK\'.
   * Simple health check for liveness and readiness.
   */
  async getPing(): Promise<string> {
    const response = await this.getPingRaw();
    return await response.value();
  }
}
