import styled from 'styled-components';
import {Form} from 'antd';

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: var(--color-white);
    font-weight: 700;
    min-height: 20px;
    font-size: var(--font-size-md);
    font-family: var(--font-family-sans-serif);
    width: 100%;
  }

  .ant-form-item-control-input-content {
    text-align: right;
  }
  margin-bottom: 14px;
`;
