// Everytime we leave the page, we reset the page index.
// We make an exception if we go to /portfolio/cargo so that
// the user can toggle between vessel and cargo page.
import {Location} from 'history';
import {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {PortfolioActions} from '../../../redux/Portfolio';

/**
 * Resets portfolio grids when we switch to/from archive.
 * Keeps them untouched if we switch between vessel and cargo.
 */
export const usePortfolioGridsReset = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const prevPathnameRef = useRef('/');

  const resetPortfolioGrids = () => {
    dispatch(PortfolioActions.setPageNumber('vessel', 1));
    dispatch(PortfolioActions.setVessels({vessels: null, vesselsTotal: 0}));
    dispatch(PortfolioActions.setPageNumber('cargo', 1));
    dispatch(PortfolioActions.setCargoes({cargoes: null, cargoesTotal: 0}));
  };

  const checkForReset = (location: Location) => {
    if (!location.pathname.startsWith('/portfolio')) {
      return;
    }
    const prevPathname = prevPathnameRef.current;
    const currentPathname = location.pathname;
    if (isMovingFromOrToArchive(prevPathname, currentPathname)) {
      resetPortfolioGrids();
    }
  };

  const historyListener = (location: Location) => {
    checkForReset(location);
    // Remember where we went.
    prevPathnameRef.current = location.pathname;
  };

  useEffect(() => {
    historyListener(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

const isMovingFromOrToArchive = (prevPathname: string, currentPathname: string): boolean => {
  const prevIsArchive = isArchive(prevPathname);
  const currentIsArchive = isArchive(currentPathname);
  return (prevIsArchive && !currentIsArchive) || (!prevIsArchive && currentIsArchive);
};

const isArchive = (pathname: string) => pathname.includes('/archive');
