import styled from 'styled-components';
import {NegotiationRoundStatsContainer} from '../../../../Components/RoundTermStats/NegotiationRoundTermStats';
import {NegotiationColor, mapNegotiationColorSet} from '../../../../utils/colors';

export const EventContainer = styled.li<{
  $color?: NegotiationColor;
  onClick?: () => void;
}>`
  ${({$color = 'blue'}) => {
    const colors = mapNegotiationColorSet($color);
    return `
      --accent-color: var(--${colors.base});
      --accent-color-bg: var(--${colors.background});
      --accent-color-border: var(--${colors.border});
    `;
  }}

  --outer-margin: 30px;
  --inner-margin: 30px;

  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0;
  height: 100%;
  text-align: center;

  ${({onClick}) =>
    onClick &&
    `
    ${EventBubble} {
      cursor: pointer;
    }
  `}

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(var(--timeline-gap) / 2 * -1);
    right: calc(var(--timeline-gap) / 2 * -1);
    height: 1px;
    background: var(--border-color);
    z-index: 1;
  }

  &:first-of-type {
    margin-left: 0;

    &::before {
      left: 0;
    }
  }
  &:last-of-type {
    padding-right: 20px;

    &::before {
      right: 50%;
    }
  }
`;

export const EventBubble = styled.div<{
  $isLarge?: boolean;
  $isSelected?: boolean;
}>`
  --bubble-border: ${({$isSelected}) =>
    $isSelected ? 'var(--border-width-active) solid var(--accent-color-border)' : '0px solid transparent'};

  position: relative;
  order: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
  min-width: 140px;
  margin: 0;
  padding: ${({$isLarge, $isSelected}) => {
    if ($isLarge) {
      return '5px 15px';
    }
    return $isSelected ? '3px 6px 5px' : '5px 8px 7px';
  }};
  background: var(--accent-color-bg);
  color: var(--accent-color);
  line-height: 1.1;
  border: var(--bubble-border);
  border-radius: var(--border-radius-tab);
  z-index: 2;

  a {
    color: var(--accent-color);
  }

  ${NegotiationRoundStatsContainer} {
    opacity: ${({$isSelected}) => ($isSelected ? '1' : '.5')};
  }
`;

export const ActiveDetailArrow = styled.div`
  position: absolute;
  left: 50%;
  bottom: -12px;
  width: 20px;
  height: 20px;
  background: var(--color-white);
  border: var(--border-width) solid transparent;
  border-left-color: var(--border-color);
  border-top-color: var(--border-color);
  transform: translateX(-50%) rotate(45deg);
  z-index: 1;
`;
