/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  NegotiationTermType,
  NegotiationTermTypeFromJSON,
  NegotiationTermTypeFromJSONTyped,
  NegotiationTermTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface CreateOrUpdateNegotiationTemplateTermDTO
 */
export interface CreateOrUpdateNegotiationTemplateTermDTO {
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateNegotiationTemplateTermDTO
   */
  text: string;
  /**
   *
   * @type {NegotiationTermType}
   * @memberof CreateOrUpdateNegotiationTemplateTermDTO
   */
  type: NegotiationTermType | null;
}

export function CreateOrUpdateNegotiationTemplateTermDTOFromJSON(json: any): CreateOrUpdateNegotiationTemplateTermDTO {
  return CreateOrUpdateNegotiationTemplateTermDTOFromJSONTyped(json, false);
}

export function CreateOrUpdateNegotiationTemplateTermDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateOrUpdateNegotiationTemplateTermDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: json['text'],
    type: NegotiationTermTypeFromJSON(json['type']),
  };
}

export function CreateOrUpdateNegotiationTemplateTermDTOToJSON(
  value?: CreateOrUpdateNegotiationTemplateTermDTO | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
    type: NegotiationTermTypeToJSON(value.type),
  };
}
