import {SubscriptionPlan, SubscriptionPlans} from '../../redux/ApiService/CompanyService/Company';
import {assertUnreachable} from '../../utils/assert';
import {isTest, isLocalDevEnvironment} from '../../utils/environment';
import {ContractDuration} from './components/ContractDurationPicker';

// We use a stripe payment link to organize the payment process. The customer support team
// will take care of the rest and modify the subscription plan of the company.

// See: FRIN-5456
export const getPaymentLink = (plan: SubscriptionPlan, contractDuration: ContractDuration) => {
  const paymentLink = getPaymentLinkInternal(plan, contractDuration);

  // If the payment link is not a stripe payment link, we log an error.
  if (!paymentLink?.startsWith('https://buy.stripe.com/')) {
    if (!isLocalDevEnvironment()) {
      // eslint-disable-next-line no-console
      console.error(`Invalid payment link: ${paymentLink}`);
    }
  }

  return paymentLink;
};

const getPaymentLinkInternal = (plan: SubscriptionPlan, contractDuration: ContractDuration): string => {
  if (isTest()) {
    return 'https://buy.stripe.com/its-in-jest-test';
  }

  if (isLocalDevEnvironment()) {
    return `https://www.google.com/search?q=payment+link+stripe+${plan}+${contractDuration}`;
  }

  switch (plan) {
    case SubscriptionPlans.Free:
      throw new Error('Free plan has no payment link');
    case SubscriptionPlans.Standard: {
      if (contractDuration === 'monthly') {
        return window.PAYMENT_LINK_MONTHLY_STANDARD_URL!;
      }
      return window.PAYMENT_LINK_YEARLY_STANDARD_URL!;
    }
    case SubscriptionPlans.Pro: {
      if (contractDuration === 'monthly') {
        return window.PAYMENT_LINK_MONTHLY_PRO_URL!;
      }
      return window.PAYMENT_LINK_YEARLY_PRO_URL!;
    }

    default:
      assertUnreachable(plan);
  }
};
