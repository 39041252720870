export type KeysWithBooleans = Record<string, boolean>;

export const convertStringArrayToKeysWithBooleans = <KeyType extends string>(
  strings: KeyType[],
  defaults: Record<KeyType, boolean>
): Record<KeyType, boolean> => {
  const result = {...defaults};
  strings.forEach(s => {
    result[s] = true;
  });
  return result;
};

export const convertKeysWithBooleansToStringArray = <KeyType extends string>(
  keysWithBooleans: Record<KeyType, boolean>
): KeyType[] => {
  const result: KeyType[] = [];
  for (const key in keysWithBooleans) {
    if (keysWithBooleans[key]) {
      result.push(key);
    }
  }
  return result;
};

export const convertLabelToSlug = (label: string): string => {
  const lower: string = label.toLowerCase();
  return lower.replaceAll(/[- ]+/g, '-');
};

export const convertToFloat = (value: number | string | undefined): number | undefined => {
  if (value === undefined) {
    return undefined;
  }
  if (value === '') {
    return undefined;
  }
  if (typeof value === 'number') {
    return value;
  }
  return parseFloat(value);
};

export const convertToInt = (value: number | string | undefined): number | undefined => {
  if (value === undefined) {
    return undefined;
  }
  if (value === '') {
    return undefined;
  }
  if (typeof value === 'number') {
    return value;
  }
  return parseInt(value);
};

export const convertToIntOrEmptyString = (value: number | undefined): number | '' => {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
};

export const convertNumberToString = (value: number | null | undefined): string =>
  value === undefined || value === null ? '' : value.toString();
