import {FC, useEffect} from 'react';
import {Space, Typography} from 'antd';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Spacer} from '../../../atoms/Spacer/Spacer';
import {showNotification} from '../../../utils/notification';
import {useSelector} from '../../../redux/react-redux';
import {getSubscriptionPlan, getSubscriptionPlanName} from '../../../redux/selectors';
import {CompanyEditSubscriptionPlanEnum} from '../../../api/symfony/generated';
import {getSubscriptionPlanNameFromSlug} from '../../../utils/Plans';
import {StepContainer, NoThanksButton} from './shared';
import {modal} from '../../../components/Notification';
import {useSendChangeSubscriptionMail} from './utils';

const {Title} = Typography;

export const CompanySubscriptionDowngradeScreen: FC = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams('plan');
  const targetPlanSlug = params.get('plan') as CompanyEditSubscriptionPlanEnum | undefined;
  const currentPlanSlug = useSelector(getSubscriptionPlan);
  const targetPlanTitle = getSubscriptionPlanNameFromSlug(targetPlanSlug!);
  const currentPlanTitle = useSelector(getSubscriptionPlanName);

  const sendChangeSubscriptionMail = useSendChangeSubscriptionMail();

  useEffect(() => {
    if (!targetPlanSlug || !currentPlanSlug) {
      navigate(`/subscription`);
    }
    if (targetPlanSlug === currentPlanSlug) {
      showNotification(`You are already subscribed to ${currentPlanTitle}.`, 'info', 5);
      navigate(`/subscription`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlanSlug, targetPlanSlug]);

  const onConfirmClick = async () => {
    const {destroy} = modal.confirm({
      title: 'Are you sure?',
      content: `You will lose access to the subscribed features after this payment period ends.`,
      okText: 'downgrade',
      okButtonProps: {danger: true},
      onOk: () => {
        sendChangeSubscriptionMail(targetPlanSlug!);
        destroy();
      },
      cancelText: 'Keep subscription',
    });
  };

  return (
    <StepContainer data-testid="CompanySubscriptionDowngrade">
      <Title level={2}>Considering a Downgrade?</Title>
      <Title level={4}>
        We understand your needs might change. Downgrading will give you fewer features, but you will still enjoy many
        great tools that help you grow your business. If unsure, stay on your current plan to enjoy all the{' '}
        {currentPlanTitle}
        features. Otherwise, we will contact you shortly to arrange the change to your subscription.
      </Title>
      <Spacer height={48} />
      <Space direction="vertical">
        <NoThanksButton type={'primary'} danger onClick={onConfirmClick}>
          Yes, downgrade to {targetPlanTitle}
        </NoThanksButton>
        <Link to={'/subscription'}>
          <NoThanksButton type={'link'}>No, keep my {currentPlanTitle} benefits</NoThanksButton>
        </Link>
      </Space>
    </StepContainer>
  );
};
