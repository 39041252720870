/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ObjectCollection
 */
export interface ObjectCollection {
  /**
   *
   * @type {number}
   * @memberof ObjectCollection
   */
  totalItems: number;
  /**
   *
   * @type {Array<object>}
   * @memberof ObjectCollection
   */
  items: Array<object>;
}

export function ObjectCollectionFromJSON(json: any): ObjectCollection {
  return ObjectCollectionFromJSONTyped(json, false);
}

export function ObjectCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectCollection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalItems: json['totalItems'],
    items: json['items'],
  };
}

export function ObjectCollectionToJSON(value?: ObjectCollection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    totalItems: value.totalItems,
    items: value.items,
  };
}
