import {App} from './App';
import {initSentry} from './components/Sentry/SentrySetup';
import {initDatadog} from './components/Datadog/DatadogSetup';
import {setupFetch} from './utils/setupFetch';
import {createRoot} from 'react-dom/client';
import {initPiwik} from './components/PiwikManager/PiwikSetup';
import 'antd/dist/reset.css';
import './index.scss';
import {getConfiguration} from './utils/configuration';
import {MaintenancePage} from './MaintenancePage';

setupFetch();
initSentry();
initDatadog();
initPiwik();

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('DOM element #root not found');
}
const root = createRoot(rootElement);

const maintenanceMessage = getConfiguration('MAINTENANCE_MESSAGE');
if (maintenanceMessage !== undefined && maintenanceMessage !== null && maintenanceMessage !== '') {
  root.render(<MaintenancePage message={maintenanceMessage} />);
} else {
  root.render(<App />);
}
