/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {VoyagePort, VoyagePortFromJSON, VoyagePortFromJSONTyped, VoyagePortToJSON} from './';

/**
 *
 * @export
 * @interface AnchorageVisitAnchorage
 */
export interface AnchorageVisitAnchorage {
  /**
   *
   * @type {number}
   * @memberof AnchorageVisitAnchorage
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AnchorageVisitAnchorage
   */
  name: string;
  /**
   *
   * @type {VoyagePort}
   * @memberof AnchorageVisitAnchorage
   */
  port: VoyagePort;
  /**
   *  Polygons include potentially multiple polygons. A polygon consists of coordinate pairs. The order is longitude and than latitude
   * @type {Array<Array<Array<number>>>}
   * @memberof AnchorageVisitAnchorage
   */
  polygon: Array<Array<Array<number>>>;
}

export function AnchorageVisitAnchorageFromJSON(json: any): AnchorageVisitAnchorage {
  return AnchorageVisitAnchorageFromJSONTyped(json, false);
}

export function AnchorageVisitAnchorageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnchorageVisitAnchorage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    port: VoyagePortFromJSON(json['port']),
    polygon: json['polygon'],
  };
}

export function AnchorageVisitAnchorageToJSON(value?: AnchorageVisitAnchorage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    port: VoyagePortToJSON(value.port),
    polygon: value.polygon,
  };
}
