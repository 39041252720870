import {FC} from 'react';
import styled from 'styled-components';
import {VesselGraphSpeedDraught} from '../../../../../../screens/VesselDetailScreen/graphs/SpeedDraughtGraph';
import {StatsVessel, VesselApiResponse} from '../../../../../../screens/VesselDetailScreen/utils/types';
import {TODO} from '../../../../../../utils/TODO';
import {UserDateTime} from '../../../../../DateTime/UserDateTime';

type MapDetailVesselProfileGraphProps = {
  id: number;
  statsVessel: VesselApiResponse<StatsVessel> | undefined;
};

export const MapDetailVesselProfileGraph: FC<MapDetailVesselProfileGraphProps> = ({id, statsVessel}) => (
  <VesselGraphSpeedDraught
    vesselId={id}
    statsVessel={statsVessel}
    statsVesselLoading={!statsVessel}
    options={{
      chartHeight: 140,
      chartMargin: {
        top: 2,
        right: 20,
        bottom: 0,
        left: -20,
      },
      legendHeight: 22,
      areaLabelProps: {
        position: 'center',
        angle: 90,
        fontSize: 10,
      },
      legendLabelProps: {
        dx: -10,
        angle: -90,
        height: 24,
        fontSize: 10,
      },
      dateFormat: 'DD.MM.YY',
      hasContainer: false,
      onMouseMove: () => {},
      onMouseLeave: () => {},
      customTooltip: GraphTooltip,
      hideReportLink: true,
    }}
  />
);

const GraphTooltip = ({active, payload, label}: TODO) => {
  if (!payload || !active) {
    return null;
  }
  return (
    <TooltipContainer>
      <UserDateTime value={label} format={'dateTime'} />
      <p className="speed" style={{color: payload[0].color, height: '10px'}}>{`Speed: ${payload[0].value} kn`}</p>
      <p className="draft" style={{color: payload[1].color, height: '10px'}}>{`Draught: ${payload[1].value} m`}</p>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  flex-wrap: wrap;
  margin-top: -1px;
  margin-bottom: 1px;
  padding: 5px 10px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-dark);
  white-space: nowrap;
`;
