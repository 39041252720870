/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {InviteeInfo, InviteeInfoFromJSON, InviteeInfoFromJSONTyped, InviteeInfoToJSON} from './';

/**
 *
 * @export
 * @interface InviteToCompanyResponseData
 */
export interface InviteToCompanyResponseData {
  /**
   *
   * @type {Array<InviteeInfo>}
   * @memberof InviteToCompanyResponseData
   */
  items: Array<InviteeInfo>;
}

export function InviteToCompanyResponseDataFromJSON(json: any): InviteToCompanyResponseData {
  return InviteToCompanyResponseDataFromJSONTyped(json, false);
}

export function InviteToCompanyResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteToCompanyResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(InviteeInfoFromJSON),
  };
}

export function InviteToCompanyResponseDataToJSON(value?: InviteToCompanyResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(InviteeInfoToJSON),
  };
}
