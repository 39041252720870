/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  PredictedConsumptionsResponse,
  PredictedConsumptionsResponseFromJSON,
  PredictedConsumptionsResponseToJSON,
} from '../models';

export interface PredictConsumptionByImoRequest {
  imo: number;
}

/**
 *
 */
export class PredictedConsumptionApi extends runtime.BaseAPI {
  /**
   * For a given vessel, predicts its consumption in certain situations.
   */
  async predictConsumptionByImoRaw(
    requestParameters: PredictConsumptionByImoRequest
  ): Promise<runtime.ApiResponse<PredictedConsumptionsResponse>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling predictConsumptionByImo.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/predicted-consumption/vessels/{imo}`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => PredictedConsumptionsResponseFromJSON(jsonValue));
  }

  /**
   * For a given vessel, predicts its consumption in certain situations.
   */
  async predictConsumptionByImo(
    requestParameters: PredictConsumptionByImoRequest
  ): Promise<PredictedConsumptionsResponse> {
    const response = await this.predictConsumptionByImoRaw(requestParameters);
    return await response.value();
  }
}
