import {Alert, Button, message, Table} from 'antd';
import dayjs from 'dayjs';
import {FC, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import {Actions as ProjectAction} from '../../../../redux/ApiService/ProjectService';
import NoResults from '../../../../screens/market/EmptyState/NoResults';
import {VesselProject} from '../types';
import {useCargoesFromGridClipboard} from '../utils/fromGridClipboard';
import {getUpperBoundForFactorToReachMaximumCountOfCalculations} from '../utils/MultiCalcConfig';
import {ModalFooter} from '../../../../antd/components/ModalFooter';

const pageSize = 5;

const queryKey = 'MultiCalcWizardVesselProjects';
export const ProjectVessels: FC<{onSubmit: (params: {projectIds: number[]; cargoIds: number[]}) => void}> = ({
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<number[]>([]);

  const cargoesQuery = useCargoesFromGridClipboard({});

  const maxVessels = cargoesQuery.isLoading
    ? 0
    : getUpperBoundForFactorToReachMaximumCountOfCalculations(cargoesQuery.data!.length);

  const [pageIndex, setPageIndex] = useState(1);
  const projectsQuery = useQuery({
    queryKey: [queryKey, pageIndex],
    queryFn: async () => {
      const data = (await dispatch(ProjectAction.getAllProjects(pageIndex, pageSize, 'vessel'))).data;
      return {items: data.items as VesselProject[], totalItems: data.totalItems};
    },
  });

  const cargoIds = (cargoesQuery.data ?? [])
    .filter(cargo => cargo.contractType === 'vc' && cargo.cargoType !== 'container')
    .map(cargo => cargo.id);

  const loading = projectsQuery.isLoading || cargoesQuery.isLoading;
  return (
    <>
      {!loading && <Alert type={'info'} message={`Select up to ${maxVessels} vessels`} />}
      <Table
        data-testid="MultiCalcWizardProjectTable"
        loading={loading}
        locale={{emptyText: <NoResults type={'vessel projects'} secondLine={''} />}}
        columns={[
          {
            title: 'Project name',
            key: 'name',
            dataIndex: 'name',
          },
          {
            title: 'Vessel name',
            key: 'vessel_name',
            dataIndex: ['vessel', 'name'],
          },
          {
            title: 'Vessel IMO',
            key: 'vessel_imo',
            dataIndex: ['vessel', 'imo'],
          },
          {
            title: 'Next open',
            key: 'vessel_nextOpenFrom',
            render: v => (v ? dayjs(v).format('L') : ''),
            dataIndex: ['vessel', 'nextOpenFrom'],
          },
        ]}
        rowKey={'id'}
        dataSource={projectsQuery.data?.items.filter(project => {
          return project.vessel.vesselType !== 'container';
        })}
        rowSelection={{
          selectedRowKeys: selectedProjectsIds,
          onChange: v => {
            const existingProjectsIds = selectedProjectsIds.filter(projectId => {
              if (projectsQuery.data?.items.map(qp => qp.id).includes(projectId)) {
                return false;
              }
              return true;
            });

            // The projectsIds which aren't in selectedProjects at this time,
            // they would mapped to the projectsQuery item with the same id
            const addProjectsIds = v
              .map(id => projectsQuery.data!.items.find(project => project.id === id)!)
              .map(project => project.id);

            const newProjectsIds = [...existingProjectsIds, ...addProjectsIds];
            if (newProjectsIds.length > maxVessels) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error(`Select ${maxVessels} vessels maximal.`);
              return;
            }
            setSelectedProjectsIds(newProjectsIds);
          },
        }}
        pagination={{
          pageSize,
          onChange: pageIndex => {
            setPageIndex(pageIndex);
          },
          total: projectsQuery.data?.totalItems ?? 0,
        }}
      />

      <ModalFooter style={{marginTop: 32}}>
        <Button
          data-testid="MultiCalcWizardSend"
          type={'primary'}
          disabled={selectedProjectsIds.length === 0 || !cargoesQuery.isSuccess}
          onClick={() => {
            onSubmit({
              projectIds: selectedProjectsIds,
              cargoIds,
            });
          }}>
          Calculate
        </Button>
      </ModalFooter>
    </>
  );
};
