import {Vessel} from '../../../../components/CharterCalculation/MultiCalc/types';
import orderBy from 'lodash/orderBy';
import {Intake} from '../../../../api/node-backend/generated';
import {ColumnSort} from '@tanstack/react-table';

type SortAttribute = string | ((vessel: Vessel) => number);

const sortIdToSortAttributeMapping: Record<string, SortAttribute> = {
  loa: 'lengthOverall',
  dwtSummer: 'dwtSummer',
  name: 'name',
  draft: 'draft',
  teuAmount: (vessel: Vessel) => {
    const teu = vessel.intakes.find((intake: Intake) => intake.type === 'teu');
    return teu ? teu.quantity : 0;
  },
};

export const sortVessels = ({vessels, sortByColumns}: {vessels: Vessel[]; sortByColumns: ColumnSort[]}) => {
  const sortId: string | undefined = sortByColumns[0]?.id;
  const sortAttribute: SortAttribute | undefined = sortIdToSortAttributeMapping[sortId];

  if (!sortAttribute) {
    return vessels;
  }
  return orderBy(vessels, sortAttribute, sortByColumns[0].desc ? 'desc' : 'asc');
};
