import {Button} from 'antd';
import EmptyImageCargo from './empty-states-worktray-no-items-in-projects-cargo.png';
import EmptyImageVessel from './empty-states-worktray-no-items-in-projects-vessels.png';

type Props = {
  onClick?: $TSFixMeFunction;
  type?: string;
};

const ShortListEmptyState = ({type, onClick}: Props) => {
  const isVessel = type === 'vessel';

  return (
    <div className="empty-state">
      <div className="empty-state__content">
        <img src={type === 'vessel' ? EmptyImageVessel : EmptyImageCargo} alt={'Empty state Shortlist'} />
        <div className="empty-state__headline">Add {type === 'vessel' ? 'vessels' : 'cargoes'} to shortlist</div>
        <div className="empty-state__sub-headline">
          There are currently no {isVessel ? 'vessels' : 'cargoes'} in your shortlist.
          <br />
          Add them from the Market or My {isVessel ? 'Fleet' : 'Cargoes'}.
        </div>
        <Button type="primary" onClick={onClick}>
          {isVessel ? 'Add vessels to shortlist' : 'Add cargoes to shortlist'}
        </Button>
      </div>
    </div>
  );
};

export default ShortListEmptyState;
