import {Button} from 'antd';
import styled from 'styled-components';
import {useSelector} from '../../../../redux/react-redux';
import {getHasProPlan, getIsInPromotionPhase} from '../../../../redux/selectors';
import {useStartTrialMutation} from './useStartTrialMutation';
import {modal} from '../../../../components/Notification';
import {BannerContainer, BannerContent, BannerTitle, ButtonRow} from './shared';
import {backgroundSvg} from './backgroundSvg';

export const StartTrialBanner = () => {
  const isInPromotionPhase = useSelector(getIsInPromotionPhase);
  const hasProPlan = useSelector(getHasProPlan);

  const startTrialMutation = useStartTrialMutation();

  const startTrial = () => {
    const {destroy} = modal.confirm({
      title: 'Start your trial now',
      content: 'You will be able to use all features for free for 30 days.',
      onOk: async () => {
        await startTrialMutation.mutateAsync(undefined, {
          onSuccess: async () => {
            window.location.reload();
          },
        });
        destroy();
      },
      onCancel: () => destroy(),
    });
  };

  if (isInPromotionPhase || hasProPlan) {
    return null;
  }

  return (
    <Banner>
      <BannerContent>
        <BannerTitle>Can't decide yet?</BannerTitle>
        <div>Try seabo Pro for 30 days and use all features for free.</div>
        <ButtonRow>
          <StyledButton type="primary" onClick={startTrial}>
            Start free trial
          </StyledButton>{' '}
          <div>No credit card required</div>
        </ButtonRow>
      </BannerContent>
      <Background>{backgroundSvg}</Background>
    </Banner>
  );
};

const Banner = styled(BannerContainer)`
  background: var(--color-azure-new-dark);
  color: var(--color-white);
`;

const StyledButton = styled(Button)`
  background: var(--color-white) !important;
  color: var(--color-azure-new-dark) !important;

  &:hover {
    background: var(--color-white) !important;
    color: var(--color-azure-new-dark) !important;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 8rem;
  width: 100%;
  height: 100%;
  background: var(--color-azure-new-dark);
  z-index: 0;
  user-select: none;
  pointer-events: none;
`;
