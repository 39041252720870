import {AISRecord} from '../queries/aisRecordsQuery/AISRecord';
import dayjs from 'dayjs';
import {CoordinatesObject, LonLatCoordinates} from '../../../../../utils/Coordinates';

const PLACEHOLDER_AIS_RECORDS: AISRecord[] = [
  {
    csvRow: 2,
    timestamp: dayjs('2020-09-15T00:00:00.000Z'),
    coordinates: [124.199572, 29.519662],
    heading: 0,
    speed: 12,
  },
  {
    csvRow: 2,
    timestamp: dayjs('2020-09-15T00:00:05.000Z'),
    coordinates: [124.199572, 29.519663],
    heading: 0,
    speed: 12,
  },
];

export const getAisRecordPlaceholders = (vesselCoordinates: CoordinatesObject | null): AISRecord[] => {
  if (vesselCoordinates) {
    const coordinates: LonLatCoordinates = [vesselCoordinates.lon, vesselCoordinates.lat];
    return [
      {
        csvRow: 2,
        timestamp: dayjs('2020-09-15T00:00:00.000Z'),
        coordinates,
        heading: 0,
        speed: 12,
      },
      {
        csvRow: 2,
        timestamp: dayjs('2020-09-15T00:00:05.000Z'),
        coordinates,
        heading: 0,
        speed: 12,
      },
    ];
  } else {
    return PLACEHOLDER_AIS_RECORDS;
  }
};

export const getAisRecordsOrPlaceholders = (aisRecords: AISRecord[]): AISRecord[] => {
  if (aisRecords.length < 2) {
    return PLACEHOLDER_AIS_RECORDS;
  } else {
    return aisRecords;
  }
};
