import {Alert, Steps, StepsProps} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {NegotiationStatus, NegotiationSummary} from '../../../api/node-backend/generated';
import {NegotiationStatusBadge} from './NegotiationStatusBadge';
import {getNegotiationStatusColors} from '../utils/colors';

export const negotiationStatusOrder = [
  NegotiationStatus.Offer,
  NegotiationStatus.Ongoing,
  NegotiationStatus.Recap,
  NegotiationStatus.Settled,
  NegotiationStatus.Cancelled,
] as const;

type NegotiationStepsProps = {
  negotiation: NegotiationSummary;
  isFirstRecapRound?: boolean;
  size?: StepsProps['size'];
};

export const NegotiationSteps: FC<NegotiationStepsProps> = ({negotiation, isFirstRecapRound, size}) => {
  const {status, latestRound, cancellationReason} = negotiation;
  const {committedAt} = latestRound;

  const isSettled = status === NegotiationStatus.Settled;
  const isCancelled = status === NegotiationStatus.Cancelled;
  const isOwnTurn = !isSettled && !isCancelled && committedAt === null;

  // The first recap round is a special case where the status is already Recap, but the round should be displayed as Ongoing
  const adjustedStatus = isFirstRecapRound && !isOwnTurn ? NegotiationStatus.Ongoing : status;
  const statusIndex = negotiationStatusOrder.indexOf(adjustedStatus);

  return (
    <NegotiationStepsContainer data-testid="NegotiationSteps">
      {statusIndex < 3 ? (
        <StepsPaddingFix>
          <ColoredSteps
            size={size}
            current={statusIndex}
            items={[
              {title: <NegotiationStatusBadge status={NegotiationStatus.Offer} active={statusIndex >= 0} />},
              {title: <NegotiationStatusBadge status={NegotiationStatus.Ongoing} active={statusIndex >= 1} />},
              {title: <NegotiationStatusBadge status={NegotiationStatus.Recap} active={statusIndex >= 2} />},
              {title: <NegotiationStatusBadge status={NegotiationStatus.Settled} active={statusIndex >= 3} />},
            ]}
          />
        </StepsPaddingFix>
      ) : (
        <NegotiationStatusBadge status={status} large active />
      )}
      {cancellationReason && <CancellationReasonContainer message={cancellationReason} type="error" showIcon />}
    </NegotiationStepsContainer>
  );
};

const NegotiationStepsContainer = styled.div`
  width: 100%;
`;

const CancellationReasonContainer = styled(Alert)<{$isLongText?: boolean}>`
  margin: 8px 0 0;
  width: 100%;
  font-weight: bold;
  border-radius: var(--border-radius-tab);
`;
const ColoredSteps = styled(Steps)`
  .ant-steps-item {
    ${() => {
      let styleString = '';
      for (let i = 0; i < negotiationStatusOrder.length; i++) {
        const status = negotiationStatusOrder[i];
        const {base: color, background: colorBg, border: colorBorder} = getNegotiationStatusColors(status);
        styleString += `
          &:nth-of-type(${i + 1}) {
            --status-color-active: var(--${color});
            --status-color-finish: var(--${colorBorder});
            --status-color-wait: var(--${colorBg});
          }
        `;
      }
      return styleString;
    }}

    &.ant-steps-item-finish .ant-steps-item-icon {
      background: var(--status-color-finish);
      .anticon {
        margin-top: 4.5px;
        color: var(--status-color-active) !important;
      }
    }

    &.ant-steps-item-active .ant-steps-item-icon {
      background: var(--status-color-active);
      border-color: transparent;
    }

    &.ant-steps-item-wait .ant-steps-item-icon {
      background: var(--status-color-wait);
    }
  }
`;

const StepsPaddingFix = styled.div`
  padding: 5px 28px 6px 23px;
  background: var(--color-gray-6);
  border-radius: var(--border-radius-tab);
`;
