import {Input, Typography} from 'antd';
import {FC} from 'react';
import {FormSection, FormSectionBody, FormSectionHeader, FormSectionItem} from './shared';

const {Title} = Typography;

export const NegotiationFormGeneralSection: FC = () => {
  return (
    <FormSection>
      <FormSectionHeader>
        <Title level={4}>General</Title>
      </FormSectionHeader>
      <FormSectionBody>
        <FormSectionItem
          name="title"
          label="Negotiation title (this will be visible for your counterpart)"
          rules={[{required: true, message: 'Please name your negotiation'}]}>
          <Input placeholder="Negotiation title" />
        </FormSectionItem>
      </FormSectionBody>
    </FormSection>
  );
};
