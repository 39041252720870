/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface PredictionInputVessel
 */
export interface PredictionInputVessel {
  /**
   *
   * @type {number}
   * @memberof PredictionInputVessel
   */
  buildYear: number;
  /**
   *
   * @type {number}
   * @memberof PredictionInputVessel
   */
  beam: number;
  /**
   *
   * @type {number}
   * @memberof PredictionInputVessel
   */
  dwtSummer: number;
  /**
   *
   * @type {number}
   * @memberof PredictionInputVessel
   */
  loa: number;
  /**
   *
   * @type {number}
   * @memberof PredictionInputVessel
   */
  draft: number;
  /**
   *
   * @type {number}
   * @memberof PredictionInputVessel
   */
  speed: number;
  /**
   *
   * @type {string}
   * @memberof PredictionInputVessel
   */
  vesselType: PredictionInputVesselVesselTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PredictionInputVessel
   */
  fuelType: PredictionInputVesselFuelTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PredictionInputVessel
   */
  itf: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PredictionInputVessel
   */
  a60: boolean;
  /**
   *
   * @type {string}
   * @memberof PredictionInputVessel
   */
  mode: PredictionInputVesselModeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PredictionInputVesselVesselTypeEnum {
  Mpp = 'mpp',
  Bulker = 'bulker',
} /**
 * @export
 * @enum {string}
 */
export enum PredictionInputVesselFuelTypeEnum {
  Ifo = 'ifo',
  Mdo = 'mdo',
} /**
 * @export
 * @enum {string}
 */
export enum PredictionInputVesselModeEnum {
  Ballast = 'ballast',
  Portworking = 'portworking',
  Portidle = 'portidle',
  Laden = 'laden',
}

export function PredictionInputVesselFromJSON(json: any): PredictionInputVessel {
  return PredictionInputVesselFromJSONTyped(json, false);
}

export function PredictionInputVesselFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionInputVessel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    buildYear: json['buildYear'],
    beam: json['beam'],
    dwtSummer: json['dwtSummer'],
    loa: json['loa'],
    draft: json['draft'],
    speed: json['speed'],
    vesselType: json['vesselType'],
    fuelType: json['fuelType'],
    itf: json['itf'],
    a60: json['a60'],
    mode: json['mode'],
  };
}

export function PredictionInputVesselToJSON(value?: PredictionInputVessel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    buildYear: value.buildYear,
    beam: value.beam,
    dwtSummer: value.dwtSummer,
    loa: value.loa,
    draft: value.draft,
    speed: value.speed,
    vesselType: value.vesselType,
    fuelType: value.fuelType,
    itf: value.itf,
    a60: value.a60,
    mode: value.mode,
  };
}
