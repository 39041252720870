import React, {FC, ReactNode} from 'react';
import {cleanIdentifier} from '../../utils/helper';
import styled, {css} from 'styled-components';
import {Button} from 'antd';
import {LinkWrapperComponent} from '../../atoms/Button/Button';

interface Props {
  id: string;
  label?: string | ReactNode;
  active?: boolean;
  disabled?: boolean;
  primary?: boolean;
  dark?: boolean;
  small?: boolean;
  inactive?: boolean;
  className?: string;
  /**
   * This prop is used by the parent component ToggleableButtonGroup.
   */
  value?: string;
  to?: string;
  href?: string;
  dataCy?: string;
  'data-cy'?: string;
  dataTestId?: string;
  'data-testid'?: string;
  onClick?: () => void;
}

const ButtonGroupButton: FC<Props> = ({
  id = 'ButtonLabel',
  label = 'ButtonLabel',
  active = false,
  disabled = false,
  inactive = false,
  dark = false,
  className = '',
  to,
  href,
  onClick = () => {},
  ...props
}) => {
  const dataCy = props.dataCy ?? props['data-cy'];
  const dataTestId = props.dataTestId ?? props['data-testid'];

  return (
    <ButtonWrapper to={to} href={href} className={className} data-cy={dataCy} data-testid={dataTestId}>
      <StyledButton
        id={cleanIdentifier(id, 'button-group-btn')}
        $active={active}
        $inactive={inactive}
        $dark={dark}
        onClick={() => !disabled && onClick!()}
        disabled={disabled}>
        {label}
      </StyledButton>
    </ButtonWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(ButtonGroupButton as any)._META_NAME = 'ButtonGroupButton';

export default ButtonGroupButton;

const StyledButton = styled(Button)<{$active?: boolean; $inactive?: boolean; $dark?: boolean}>`
  display: block;
  width: 100%;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-base);
  text-transform: unset;

  ${({$active, $dark}) =>
    $active &&
    !$dark &&
    css`
      background-color: var(--color-blue) !important;
      border-color: var(--color-blue) !important;
      color: var(--color-white-fixed) !important;
    `}

  ${({$active, $dark}) =>
    $active &&
    $dark &&
    css`
      background-color: var(--color-azure) !important;
      border-color: var(--color-azure) !important;
      color: var(--color-white-fixed) !important;
    `}

  ${({$inactive}) =>
    $inactive &&
    css`
      background-color: transparent !important;
      color: var(--color-gray-2) !important;
      border: var(--border-base) !important;
    `};
`;

const ButtonWrapper = styled(LinkWrapperComponent)`
  &:first-child ${StyledButton} {
    border-right-width: 0;
    border-radius: var(--border-radius-button) 0 0 var(--border-radius-button);
  }
  &:last-child ${StyledButton} {
    border-left-width: 0;
    border-radius: 0 var(--border-radius-button) var(--border-radius-button) 0;
  }
`;
