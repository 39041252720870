import styled, {css} from 'styled-components';
import Icon from '../../../../../../atoms/Icon';
import {SidebarActionsLabel, SidebarCard} from './shared';

export const SidebarLegend = () => (
  <>
    <SidebarActionsLabel>Legend</SidebarActionsLabel>
    <SidebarCard>
      <SectionLabel>Terms:</SectionLabel>
      <LegendLabel $color="self">
        <ColorBox /> Your term
      </LegendLabel>
      <LegendLabel $color="partner">
        <ColorBox /> Counterpart's term
      </LegendLabel>
      <LegendLabel $color="rejected" $decoration="dashed">
        <ColorBox />
        Reject requested
      </LegendLabel>
      <LegendLabel $color="accepted" $decoration="solid">
        <ColorBox />
        Term rejected
      </LegendLabel>
      <LegendLabel $color="accepted">
        <ColorBox />
        Term accepted
      </LegendLabel>
      <LegendLabel $color="recap">
        <ColorBox />
        Recap outstanding
      </LegendLabel>
      <SectionLabel>Subjects:</SectionLabel>
      <LegendLabel $color="self">
        <LegendIcon size="small" type="block" /> Your subject
      </LegendLabel>
      <LegendLabel $color="partner">
        <LegendIcon size="small" type="block" /> Counterpart's subject
      </LegendLabel>
      <LegendLabel $color="rejected" $decoration="dashed">
        <LegendIcon size="small" type="block" />
        Reject requested
      </LegendLabel>
      <LegendLabel $color="accepted" $decoration="solid">
        <LegendIcon size="small" type="block" />
        Subject rejected
      </LegendLabel>
      <LegendLabel $color="yellow">
        <LegendIcon size="small" type="block" />
        Subject accepted
      </LegendLabel>
      <LegendLabel $color="accepted">
        <LegendIcon size="small" type="checkmark" />
        Subject lifted
      </LegendLabel>
      <LegendLabel $color="rejected">
        <LegendIcon size="small" type="close" />
        Subject failed
      </LegendLabel>
    </SidebarCard>
  </>
);

const ColorBox = styled.div`
  width: 10px;
  height: 10px;
  margin-left: -2px;
  margin-right: 4px;
  border-radius: var(--border-radius);
  border: var(--border-base);
`;

const LegendIcon = styled(Icon)`
  margin-left: -3px;
  margin-right: 4px;
  vertical-align: middle;
`;

const InlineIcon = styled(Icon)`
  margin-left: 3px;
  margin-right: 4px;
  vertical-align: middle;
`;

const SectionLabel = styled.div`
  margin: 8px 0 2px;
  font-weight: bold;

  &:first-of-type {
    margin-top: 0;
  }
`;

const LegendLabel = styled.div<{$color: string; $decoration?: string}>`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 2px 8px;
  background: var(--color-${({$color}) => $color}-bg);
  font-size: var(--font-size-sm);
  line-height: 1.2;
  border-radius: var(--border-radius);

  ${LegendIcon}, ${InlineIcon} {
    font-size: 12px;
    color: var(--color-${({$color}) => $color});
  }
  ${ColorBox} {
    background: var(--color-${({$color}) => $color});
  }

  ${({$decoration}) =>
    $decoration
      ? css`
          text-decoration: line-through;
          text-decoration-style: ${$decoration};
          text-decoration-color: rgba(0, 0, 0, 0.6);
        `
      : ''}
`;
