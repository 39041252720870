/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CreateNegotiationCargoDto,
  CreateNegotiationCargoDtoFromJSON,
  CreateNegotiationCargoDtoFromJSONTyped,
  CreateNegotiationCargoDtoToJSON,
  CreateNegotiationCounterpartDto,
  CreateNegotiationCounterpartDtoFromJSON,
  CreateNegotiationCounterpartDtoFromJSONTyped,
  CreateNegotiationCounterpartDtoToJSON,
  CreateNegotiationVesselDto,
  CreateNegotiationVesselDtoFromJSON,
  CreateNegotiationVesselDtoFromJSONTyped,
  CreateNegotiationVesselDtoToJSON,
} from './';

/**
 *
 * @export
 * @interface CreateNegotiationRequest
 */
export interface CreateNegotiationRequest {
  /**
   *
   * @type {string}
   * @memberof CreateNegotiationRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreateNegotiationRequest
   */
  privateNote?: string;
  /**
   * Initial terms that allow you to add multiple terms within the creation request.
   * @type {Array<string>}
   * @memberof CreateNegotiationRequest
   */
  terms: Array<string>;
  /**
   *
   * @type {CreateNegotiationVesselDto}
   * @memberof CreateNegotiationRequest
   */
  vessel: CreateNegotiationVesselDto;
  /**
   *
   * @type {CreateNegotiationCargoDto}
   * @memberof CreateNegotiationRequest
   */
  cargo: CreateNegotiationCargoDto;
  /**
   * It is your negotiating partner. Currently we only support a guest as counterpart.
   * @type {CreateNegotiationCounterpartDto}
   * @memberof CreateNegotiationRequest
   */
  counterpart: CreateNegotiationCounterpartDto;
}

export function CreateNegotiationRequestFromJSON(json: any): CreateNegotiationRequest {
  return CreateNegotiationRequestFromJSONTyped(json, false);
}

export function CreateNegotiationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNegotiationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json['title'],
    privateNote: !exists(json, 'privateNote') ? undefined : json['privateNote'],
    terms: json['terms'],
    vessel: CreateNegotiationVesselDtoFromJSON(json['vessel']),
    cargo: CreateNegotiationCargoDtoFromJSON(json['cargo']),
    counterpart: CreateNegotiationCounterpartDtoFromJSON(json['counterpart']),
  };
}

export function CreateNegotiationRequestToJSON(value?: CreateNegotiationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    privateNote: value.privateNote,
    terms: value.terms,
    vessel: CreateNegotiationVesselDtoToJSON(value.vessel),
    cargo: CreateNegotiationCargoDtoToJSON(value.cargo),
    counterpart: CreateNegotiationCounterpartDtoToJSON(value.counterpart),
  };
}
