import React, {Fragment, ReactNode} from 'react';
import NumberRangeInput from '../../../RangeInput/NumberRangeInput';
import FloatRangeInput from '../../../RangeInput/FloatRangeInput';
import {hasError} from '../../../../utils/helper';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../atoms/Filter/FilterButton';
import {filterBranchDefinition} from './DimensionsDefinition';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
  badge?: ReactNode;
  disabled?: boolean;
}

const Dimensions = ({filterProviderApi, disabled, badge}: Props) => {
  return (
    <>
      <FilterButton
        disabled={disabled}
        filterProviderApi={filterProviderApi}
        filterBranchDefinition={filterBranchDefinition}
        size="big">
        {({handleChange, values, validations}) => (
          <Fragment>
            <div className="container">
              <div className="row">
                <div className="scol-4">
                  <FloatRangeInput
                    label={'LOA'}
                    onFromChange={value => handleChange('lengthFrom', value)}
                    onToChange={value => handleChange('lengthTo', value)}
                    fromValue={values.lengthFrom}
                    toValue={values.lengthTo}
                    allowEqualMinMax
                    fromProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'min',
                      hasError: hasError('lengthFrom', validations),
                    }}
                    toProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'max',
                      hasError: hasError('lengthTo', validations),
                    }}
                  />
                </div>
                <div className="scol-4">
                  <FloatRangeInput
                    label={'Beam'}
                    onFromChange={value => handleChange('beamFrom', value)}
                    onToChange={value => handleChange('beamTo', value)}
                    fromValue={values.beamFrom}
                    toValue={values.beamTo}
                    allowEqualMinMax
                    fromProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'min',
                      hasError: hasError('beamFrom', validations),
                    }}
                    toProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'max',
                      hasError: hasError('beamTo', validations),
                    }}
                  />
                </div>
                <div className="scol-4">
                  <FloatRangeInput
                    label={'Draft'}
                    onFromChange={value => handleChange('draftFrom', value)}
                    onToChange={value => handleChange('draftTo', value)}
                    fromValue={values.draftFrom}
                    toValue={values.draftTo}
                    allowEqualMinMax
                    fromProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'min',
                      hasError: hasError('draftFrom', validations),
                    }}
                    toProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'max',
                      hasError: hasError('draftTo', validations),
                    }}
                  />
                </div>
              </div>
              <div className="row bottom-fix">
                <div className="scol-4">
                  <NumberRangeInput
                    label={'GT'}
                    onFromChange={value => handleChange('grossTonnageFrom', value)}
                    onToChange={value => handleChange('grossTonnageTo', value)}
                    fromValue={values.grossTonnageFrom}
                    toValue={values.grossTonnageTo}
                    allowEqualMinMax
                    fromProps={{
                      min: 0,
                      max: 999999,
                      placeholder: 'min',
                      hasError: hasError('grossTonnageFrom', validations),
                    }}
                    toProps={{
                      min: 0,
                      max: 999999,
                      placeholder: 'max',
                      hasError: hasError('grossTonnageTo', validations),
                    }}
                  />
                </div>
                <div className="scol-4">
                  <NumberRangeInput
                    label={'NT'}
                    onFromChange={value => handleChange('netTonnageFrom', value)}
                    onToChange={value => handleChange('netTonnageTo', value)}
                    fromValue={values.netTonnageFrom}
                    toValue={values.netTonnageTo}
                    allowEqualMinMax
                    fromProps={{
                      min: 0,
                      max: 999999,
                      placeholder: 'min',
                      hasError: hasError('netTonnageFrom', validations),
                    }}
                    toProps={{
                      min: 0,
                      max: 999999,
                      placeholder: 'max',
                      hasError: hasError('netTonnageTo', validations),
                    }}
                  />
                </div>
                <div className="scol-4">
                  <FloatRangeInput
                    label={'TPC'}
                    onFromChange={value => handleChange('tonnesPerCentimetreFrom', value)}
                    onToChange={value => handleChange('tonnesPerCentimetreTo', value)}
                    fromValue={values.tonnesPerCentimetreFrom}
                    toValue={values.tonnesPerCentimetreTo}
                    allowEqualMinMax
                    fromProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'min',
                      hasError: hasError('tonnesPerCentimetreFrom', validations),
                    }}
                    toProps={{
                      min: 0,
                      max: 99999999,
                      placeholder: 'max',
                      hasError: hasError('tonnesPerCentimetreTo', validations),
                    }}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </FilterButton>
      {badge}
    </>
  );
};
export default Dimensions;
