import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {ApiError} from '../../api/utils/ApiError';
import {workspaceApi} from '../../api/symfony/symfonyApi';
import {Workspace} from './WorkspaceSelectTypes';
import {assert} from '../../utils/assert';

/**
 * This mutation only changes the selected workspace in the backend.
 * Make sure to change the current state or reload the page on success.
 */
export const useSelectWorkspaceMutation = (options?: UseMutationOptions<void, ApiError, Workspace>) => {
  const selectWorkspaceMutation = useMutation({
    mutationFn: async (workspace: Workspace): Promise<void> => {
      assert(workspace.id);
      return workspaceApi.selectWorkspace(workspace);
    },
    ...options,
  });

  return selectWorkspaceMutation;
};
