import arrayMove from 'array-move';
import produce from 'immer';
import sortBy from 'lodash/sortBy';
import {FC, ReactNode, useEffect} from 'react';
import {FilterCategory, FilterItem, FilterItemWrite, FilterType} from '../../../api/symfony/generated';
import {EasySharingInbox} from '../EasySharing/EasySharingInbox';
import {EasySharingToggles} from '../EasySharing/EasySharingToggles';
import {newSortKey} from '../../../components/TabList/newSortKey';
import {Tab, TabId, TabType} from '../../../components/TabList/Tab';
import {SortEnd, SortEndHandler, TabList} from '../../../components/TabList/TabList';
import {useCreateFilterMutation} from '../../../queries/filter/useCreateFilterMutation';
import {useDeleteFilterMutation} from '../../../queries/filter/useDeleteFilterMutation';
import {patchFilter, PatchFilterRequest, usePatchFilterMutation} from '../../../queries/filter/usePatchFilterMutation';
import CompanyServiceActions from '../../../redux/ApiService/CompanyService/CompanyService';
import {MarketType} from '../../../redux/Market';
import {ModalActions} from '../../../redux/Modal';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {RootState} from '../../../redux/store';
import {SubMarketActions, SubMarketType} from '../../../redux/SubMarket';
import {assert, assertUnreachable} from '../../../utils/assert';
import {useUpdateFilterMutation} from '../../../queries/filter/useUpdateFilterMutation';
import {queryClient} from '../../../api/utils/reactQueryClient';
import {FeatureToggles} from '../../../utils/FeatureToggles';
import {useGetEasySharingState} from '../../../hooks/useGetEasySharingState';
import {useFiltersQuery} from '../../../queries/filter/useFiltersQuery';
import {useSubMarkets} from '../useSubMarkets';
import {Delete} from '../../../components/Modal/Delete/DeleteModal';

const isFilterItem = (tab: (Tab & {outbox?: undefined}) | FilterItem): tab is FilterItem => {
  return tab.outbox !== undefined;
};

interface Props {
  marketType: MarketType;
  tabBarExtraContent?: ReactNode;
  onSelectTab: (subMarket: FilterItem | null) => void;
  onDelete: () => void;
}

export const ActiveSubMarketList: FC<Props> = props => {
  const dispatch = useDispatch();

  const filtersQuery = useFiltersQuery({category: FilterCategory.Market});

  const subMarkets = useSubMarkets(props.marketType as FilterType);
  const activeTab = useSelector(state => getActiveTab(state, props.marketType));

  useEffect(() => {
    if (filtersQuery.isSuccess && filtersQuery.data) {
      dispatch(SubMarketActions.set(filtersQuery.data));
    }
  }, [dispatch, filtersQuery.isSuccess, filtersQuery.data]);

  useEffect(() => {
    dispatch(CompanyServiceActions.getUserWorkspaceList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {isEasySharingLoading, isEasySharingEnabled, isEasySharingSetupComplete, isOutboxRemovalCritical} =
    useGetEasySharingState();

  const tabs = sortBy(subMarkets, 'sortKey');

  const allItemsLabel = getAllItemsLabel(props.marketType);

  // A tab was moved from oldIndex to newIndex
  const onSortEnd: SortEndHandler = ({oldIndex, newIndex}: SortEnd) => {
    const newSubMarkets = arrayMove(tabs, oldIndex, newIndex);
    newSubMarkets.forEach((subMarket, index) => {
      const id = subMarket.id;
      dispatch(SubMarketActions.updateSortKey({id, newIndex: index}));

      const request: PatchFilterRequest = {
        id,
        filterItemWrite: {
          sortKey: index,
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      patchFilter(request);
    });
  };

  const onSelectTab = (tab: FilterItem | 'all') => {
    selectTab(tab);
  };

  const selectTab = (tab: FilterItem | 'all') => {
    if (tab === 'all') {
      if (activeTab !== 'all') {
        selectTabInRedux('all');
        props.onSelectTab(null);
      }
    } else {
      selectTabInRedux(tab.id);
      props.onSelectTab(tab);
    }
  };

  const selectTabInRedux = (tabId: TabId | 'all') => {
    dispatch(SubMarketActions.select({id: tabId, subMarketType: convertTabTypeToSubMarketType(props.marketType)}));
  };

  const createFilterMutation = useCreateFilterMutation();

  const onCreateTab = async (name: string): Promise<FilterItem> => {
    const sortKey = newSortKey(tabs);
    const filterItemWrite: FilterItemWrite = {
      category: FilterCategory.Market,
      type: props.marketType as FilterType,
      name,
      sortKey,
      filterSettings: undefined,
    };
    const subMarket: FilterItem = await createFilterMutation.mutateAsync({
      filterItemWrite,
    });
    dispatch(SubMarketActions.add(subMarket));
    onSelectTab(subMarket);
    return subMarket;
  };

  const patchFilterMutation = usePatchFilterMutation();

  const onRenameTab = async (newName: string, tabId: number): Promise<void> => {
    const tab = tabs.find(tab => tab.id === tabId);
    assert(!!tab, `tab with id ${tabId} not found`);

    const request: PatchFilterRequest = {
      id: tabId,
      filterItemWrite: {
        name: newName,
      },
    };
    patchFilterMutation.mutate(request);

    const updatedSubMarket: FilterItem = produce(tab, draft => {
      draft.name = newName;
    });
    dispatch(SubMarketActions.update(updatedSubMarket));
  };

  const deleteFilterMutation = useDeleteFilterMutation();

  const onDeleteTab = (tab: FilterItem) => {
    const {id, name} = tab;

    dispatch(
      ModalActions.show(
        <Delete
          target={{
            name,
            id,
          }}
          onDelete={async (id: number, _callback: $TSFixMe, close: () => void) => {
            close();
            await deleteFilterMutation.mutateAsync({id});
            dispatch(SubMarketActions.delete({id, subMarketType: convertTabTypeToSubMarketType(props.marketType)}));
            props.onDelete();
          }}
        />
      )
    );
  };

  const updateFilterMutation = useUpdateFilterMutation({
    onSuccess: () => {
      dispatch(CompanyServiceActions.getUserWorkspaceList());
    },
  });

  const updateFilter = async (tab: FilterItem) => {
    if (!isFilterItem(tab)) {
      return;
    }
    await updateFilterMutation.mutateAsync({
      id: tab.id,
      filterItemWrite: {
        filterSettings: tab.filterSettings || {},
        name: tab.name,
        sortKey: tab.sortKey,
        category: tab.category,
        type: tab.type,
        outbox: !tab.outbox,
      },
    });
    await queryClient.invalidateQueries({
      queryKey: ['filters', {category: tab.category}],
    });
  };

  const onOutboxChange = isEasySharingEnabled ? updateFilter : undefined;

  const renderMainTabExtraEasySharing = () => {
    if (isEasySharingLoading || !FeatureToggles.easySharing) {
      return null;
    }
    return <EasySharingInbox isInboxActive={isEasySharingSetupComplete} />;
  };

  const renderAdditionalTabExtraEasySharing = (tab: FilterItem) => {
    if (isEasySharingLoading || !isEasySharingEnabled || !isFilterItem(tab)) {
      return null;
    }
    return (
      <EasySharingToggles
        isActiveTab={activeTab === tab.id}
        tab={tab}
        isOutboxRemovalCritical={isOutboxRemovalCritical}
        onOutboxChange={onOutboxChange}
      />
    );
  };

  return (
    <TabList<FilterItem>
      tabs={tabs}
      activeTab={activeTab}
      allItemsLabel={allItemsLabel}
      createTabDefaultName="New submarket"
      createModalTitle="New submarket"
      createModalButtonLabel="Create"
      renameModalTitle="Rename submarket"
      renameModalButtonLabel="Rename"
      createTabEnabled={true}
      tabBarExtraContent={props.tabBarExtraContent}
      onSelectTab={onSelectTab}
      onSortEnd={onSortEnd}
      onCreateTab={onCreateTab}
      onRenameTab={onRenameTab}
      onDeleteTab={onDeleteTab}
      renderMainTabExtra={renderMainTabExtraEasySharing}
      renderAdditionalTabExtra={renderAdditionalTabExtraEasySharing}
    />
  );
};

const getAllItemsLabel = (type: TabType) => {
  switch (type) {
    case 'cargo':
      return `All cargoes`;
    case 'vessel':
      return `All vessels`;
    default:
      throw new Error(`Bad type ${type}`);
  }
};

const getActiveTab = (state: RootState, type: TabType) => {
  const {activeTabVessel, activeTabCargo} = state.subMarkets;
  switch (type) {
    case 'vessel':
      return activeTabVessel;
    case 'cargo':
      return activeTabCargo;
    default:
      throw new Error(`Bad type ${type}`);
  }
};

const convertTabTypeToSubMarketType = (tabType: TabType): SubMarketType => {
  switch (tabType) {
    case 'vessel':
      return FilterType.Vessel;
    case 'cargo':
      return FilterType.Cargo;
    default:
      assertUnreachable(tabType);
  }
};
