import chunk from 'lodash/chunk';
import {OneDayStatistics, TradingAreaTiedUpVesselsStatistic} from '../../../../api/node-backend/generated';
import {getDatasource, ValueRepresentation} from './getDatasource';
import {TiedUpSortOption} from './TiedUpVesselsSummary';

export const getColumnValues = ({
  tradingAreaTiedUpVesselsStatistics,
  valueRepresentation,
  accessor,
  basicQuantity,
  searchText,
  sortBy,
}: {
  tradingAreaTiedUpVesselsStatistics: TradingAreaTiedUpVesselsStatistic[];
  valueRepresentation: ValueRepresentation;
  accessor: (day: OneDayStatistics) => number;
  basicQuantity: number;
  searchText: string;
  sortBy: TiedUpSortOption;
}) => {
  const values = tradingAreaTiedUpVesselsStatistics.filter(item => {
    return (
      item.tradingAreaName.toLowerCase().includes(searchText.toLowerCase()) ||
      item.tradingAreaCode.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  const datasource = getDatasource({
    valueRepresentation,
    accessor,
    basicQuantity,
    tradingAreaTiedUpVesselsStatistics: values,
  });
  const sortedValues = datasource.tiedUpTradingAreasWithDistributionAt.sort((a, b) => {
    switch (sortBy) {
      case 'value+':
        return b.distributionAt - a.distributionAt;
      case 'value-':
        return a.distributionAt - b.distributionAt;
      case 'name':
      default:
        return a.tradingAreaName.localeCompare(b.tradingAreaName);
    }
  });
  const isLengthOdd = sortedValues.length % 2 === 1;
  const chunkSize = isLengthOdd ? sortedValues.length / 2 + 1 : sortedValues.length / 2;

  const chunks = chunk(sortedValues, chunkSize);
  return chunks;
};
