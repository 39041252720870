import {InputState} from '../components/CharterCalculation/Voyagecharter/utils/voyageChartercalculation/voyageChartercalculation';
import {MetaState} from '../components/CharterCalculation/Voyagecharter/Context/VoyageCharterContext';
import {blankState} from '../components/CharterCalculation/Voyagecharter/Context/blankState';
import {doPortRouteRelation} from '../components/CharterCalculation/Voyagecharter/Context/utils/doPortRouteRelation';
import {addInitialConsumptionToVoyage} from '../components/CharterCalculation/Voyagecharter/Context/utils/addInitialConsumptionToVoyage';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type CharterCalculationState = {
  inputState: InputState;
  metaState: MetaState;
};

const CharterCalculationSlice = createSlice({
  name: 'CharterCalculation',
  initialState: blankState,
  reducers: {
    setInputState(state, action: PayloadAction<InputState>) {
      const input = addInitialConsumptionToVoyage(doPortRouteRelation(action.payload));
      state.inputState = input;
      state.metaState.savedLastChanges = false;
    },
    setMetaState(state, action: PayloadAction<MetaState>) {
      state.metaState = action.payload;
    },
    setCharterCalculationState(state, action: PayloadAction<CharterCalculationState>) {
      state = action.payload;
    },
    resetCharterCalculationState() {
      return blankState;
    },
  },
});

export const CharterCalculationActions = CharterCalculationSlice.actions;
export const charterCalculationReducer = CharterCalculationSlice.reducer;
