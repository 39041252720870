import {Form, message, Modal} from 'antd';
import {GridClipboardActions, Subsection} from '../../../redux/GridClipboard';
import {useMutation} from '@tanstack/react-query';
import {useSelector} from '../../../redux/react-redux';
import {portfolioCargoesApi, portfolioVesselApi} from '../../../api/symfony/symfonyApi';
import {useGetSubPortfoliosQuery} from '../../../queries/useGetSubPortfoliosQuery';
import {useDispatch} from 'react-redux';

type Props = {
  subsection: Subsection;
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export const MultiRemoveSubPortfolio = (props: Props) => {
  const {activeTabCargo, activeTabVessel} = useSelector(state => state.portfolio.screenState);
  // at the moment cargoes is not supported by the backend, but is actually requested
  const {vessels, cargoes} = useSelector(state => state.gridClipboard.portfolio);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const subPortfoliosQuery = useGetSubPortfoliosQuery({type: props.subsection === 'cargoes' ? 'cargo' : 'vessel'});
  const subPortfolios = subPortfoliosQuery.data?.data.items ?? [];
  const selectedSubPortfolio = subPortfolios.find(
    sub => sub.id === (props.subsection === 'vessels' ? activeTabVessel : activeTabCargo)
  );
  const submitMutation = useMutation({
    mutationFn: async () => {
      if (props.subsection === 'vessels' && activeTabVessel !== 'all') {
        await portfolioVesselApi.removeVesselFromPortfolioGroups({
          batchRemoveVesselsFromSubPortfolioRequest: {
            subPortfolioId: activeTabVessel,
            vesselIds: vessels.map(vessel => vessel.id),
          },
        });
      }
      if (props.subsection === 'cargoes' && activeTabCargo !== 'all') {
        await portfolioCargoesApi.removeCargoesFromSubportfolio({
          removeCargoesFromSubportfolioRequest: {
            subPortfolioId: activeTabCargo,
            cargoIds: cargoes.map(cargo => cargo.id),
          },
        });
      }
      dispatch(GridClipboardActions.resetSelection('portfolio'));
    },

    onSuccess: () => {
      const text =
        props.subsection === 'vessels' ? 'Removed vessels from vessel lists.' : 'Removed cargoes from cargo lists.';
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success(text);
      props.onSubmit();
    },
  });

  const count = props.subsection === 'vessels' ? vessels.length : cargoes.length;
  const title = props.subsection === 'vessels' ? 'Remove from vessel lists' : 'Remove from cargo lists';

  return (
    <Form form={form}>
      <Modal
        okText={'Remove'}
        onCancel={props.onCancel}
        onOk={() => submitMutation.mutate()}
        open={props.visible}
        title={title}>
        Remove {count} {props.subsection} from "{selectedSubPortfolio?.name}".
      </Modal>
    </Form>
  );
};
