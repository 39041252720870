import displayGridUtils from '../../../../screens/market/GridDisplay/displayGridUtils';
import {CNR} from '../../../../components/Placeholder/Placeholder';
import {TODO} from '../../../../utils/TODO';
import {CustomColumnDef} from '../../../DataGrid/DataGrid';

const getDisplayChartererName = (vessel: Vessel) => {
  if (!vessel.selectedFixture) {
    return '-';
  }

  return vessel.selectedFixture.chartererName ?? <CNR />;
};

interface Vessel {
  selectedFixture: Fixture | null;
  managementDisponentOwner: string;
  rate: TODO;
  rateCurrency: TODO;
  rateTerms: TODO;
}

interface Fixture {
  id: number;
  chartererName: string;
}

type Props = {
  onOpenFixture: (fixture: Fixture) => void;
  onCreateNewFixture: (vessel: Vessel) => void;
};

export const chartererColumn = (props: Props): CustomColumnDef<Vessel> => {
  return {
    header: () => (
      <div className="datagrid-sortable-header">
        <div>
          Charterer
          <br />
          Rate
        </div>
      </div>
    ),
    id: 'managementDisponentOwner',
    accessorFn: vessel => {
      return (
        <div
          className={'market-grid-cell inline-editable'}
          onClick={() => {
            if (vessel.selectedFixture) {
              props.onOpenFixture(vessel.selectedFixture);
            } else {
              props.onCreateNewFixture(vessel);
            }
          }}>
          <div className={'market-grid-cell-content'}>{getDisplayChartererName(vessel)}</div>
          <div className={'market-grid-cell-content market-grid-cell-content--sub'}>
            {displayGridUtils.getVesselPeriodRate(vessel)}
          </div>
        </div>
      );
    },
    cell: info => info.getValue(),
    enableSorting: true,
    minWidth: 12,
  };
};
