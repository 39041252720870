import {Collapse, Table} from 'antd';
import capitalize from 'lodash/capitalize';
import React, {FC} from 'react';
import {negotiationPlaceholderKeys} from './utils/placeholderKeys';
import {Modal} from '../../../antd/components/Modal';

type PlaceholderInfoModalProps = {
  open: boolean;
  onClose: () => void;
};

export const PlaceholderInfoModal: FC<PlaceholderInfoModalProps> = ({open, onClose}) => {
  return (
    <Modal open={open} title="Placeholders" cancelButtonProps={{style: {display: 'none'}}} onOk={onClose}>
      <p>
        Placeholders are used to insert dynamic values into the negotiation text, for example the name of your
        counterpart.
      </p>
      <p>
        When you use a template for your offer, all placeholders are replaced with the values you entered when creating
        the negotiation. You can preview the result by switching to the preview tab.
      </p>
      <Collapse
        size="small"
        items={[
          {
            key: '1',
            label: 'List of all placeholders',
            style: {padding: 0},
            children: (
              <Table
                size="small"
                pagination={false}
                dataSource={Object.entries(negotiationPlaceholderKeys).map(([key, label]) => ({key, label}))}
                columns={[{title: 'Label', render: item => capitalize(item.label), key: 'label'}]}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
};
