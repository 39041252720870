import {OneCalcResult} from './oneCalc';
import intersection from 'lodash/intersection';

export const isCalculationWithResult = (calc: OneCalcResult): boolean => {
  const warningsWithWhichNothingWorks = [
    'cargo-has-no-quantity',
    'duration-too-long',
    'dwt-temporarily-over-capacity',
    'voyage-add-legs',
  ];

  if (
    intersection(
      calc.voyageCalculationOutput.warning.list.map(warning => warning.code),
      warningsWithWhichNothingWorks
    ).length > 0
  ) {
    return false;
  }

  return true;
};
