import {FC, useRef} from 'react';
import {
  NegotiationActor,
  NegotiationParty,
  NegotiationSubject,
  NegotiationSubjectModification,
  NegotiationSubjectModificationReport,
} from '../../../../../../api/node-backend/generated';
import {getDerivedSubjectState} from '../../Subjects/utils/getDerivedSubjectState';
import {SubjectItemDumb} from '../../Subjects/SubjectItemDumb';
import {SubjectModificationIcon} from './SubjectModificationIcon';
import {SubjectMeta} from '../../Subjects/SubjectMeta';
import {getSubjectColors} from '../../../../utils/colors';
import {TermDiff} from '../../TermItem/Components/TermDiff';

type SubjectItemHistoryProps = {
  subject: NegotiationSubject;
  subjectModifications?: NegotiationSubjectModificationReport;
  party: NegotiationParty;
  ownerActor: NegotiationActor;
  lastEditActor: NegotiationActor;
};

export const SubjectItemHistory: FC<SubjectItemHistoryProps> = ({
  subject,
  subjectModifications,
  party,
  ownerActor,
  lastEditActor,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {text, status, textLastUpdatedBy} = subject;
  const {oldText, subjectModification} = subjectModifications ?? {};

  const subjectState = getDerivedSubjectState({
    roundCanBeEdited: false,
    subject,
    party,
    subjectModifications,
  });
  const {isOwnSubject} = subjectState;

  const colors = getSubjectColors(status, textLastUpdatedBy !== party);

  return (
    <SubjectItemDumb
      containerRef={containerRef}
      text={text}
      status={status}
      colors={colors}
      beforeTextAddon={<SubjectModificationIcon isOwnEdit={!isOwnSubject} subjectModification={subjectModification} />}
      textReplacement={
        subjectModification === NegotiationSubjectModification.SubjectTextUpdated ? (
          <TermDiff currentText={subject.text} previousText={oldText ?? undefined} />
        ) : null
      }
      afterTextAddon={
        <SubjectMeta
          ownerActor={ownerActor}
          isOwnSubject={isOwnSubject}
          lastEditActor={lastEditActor}
          isOwnEdit={textLastUpdatedBy === party}
        />
      }
    />
  );
};
