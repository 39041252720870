import React, {FC, ReactNode} from 'react';
import {TODO} from '../../../../../utils/TODO';
import {getVesselFromRowValue} from '../../../utils/getVesselFromRowValue';
import {RowValueType} from '../../../utils/RowValueType';
import {MatchingVesselOrderButton, OnSyncWithSpireParams} from './MatchingVesselOrderButton';
import {AccessorFn} from '@tanstack/react-table';
import {CustomColumnDef} from '../../../../DataGrid/DataGrid';
import {SeaboVessel} from '../../../../../api/node-backend/generated';
import {VesselNameLabel} from '../../../../VesselNameLabel';
import {Link} from 'react-router-dom';
import {VesselStatusIcon} from '../../../../../screens/VesselDetailScreen/components/VesselStatusLabel';
import styled from 'styled-components';
import {VesselStatus} from '../../../../../screens/CargoVesselForm/helper';

const ShipNameCell: FC<{vessel: TODO; options?: ShipNameAccessorOptions}> = ({vessel, options = {}}) => {
  return (
    <VesselNameLabel
      vessel={vessel}
      customVesselNameLabel={
        <Cell>
          <MatchingVesselOrderButton vessel={vessel} onSyncWithSpire={options.onSyncWithSpire} />
          <VesselNameLink vessel={vessel} options={options} />
        </Cell>
      }
    />
  );
};

const VesselNameLink: FC<{vessel: TODO; options: ShipNameAccessorOptions}> = ({vessel, options}) => {
  const isLink = options.isLink ?? true;

  const vesselName = (
    <NoWrap>
      <span>{vessel.name || 'No name'}</span>
      {vessel.status && (
        <span>
          <VesselStatusIcon status={vessel.status as VesselStatus} />
        </span>
      )}
    </NoWrap>
  );

  if (!isLink) {
    return vesselName;
  }

  return (
    <Link
      target={options?.openAsNewTab ? '_blank' : undefined}
      data-cy="shipnameLink"
      to={`/vessel/${vessel.id}`}
      style={{display: 'block', maxWidth: '100%'}}>
      {vesselName}
    </Link>
  );
};

const NoWrap = styled.span`
  display: flex;
  gap: 1.5px;
  max-width: 100%;
  text-align: left;

  span:nth-child(1) {
    display: block;
    flex: 0 1 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  span:nth-child(2) {
    flex: 0 0 1.1em;
    display: flex;
    align-items: center;
  }
`;

export type ShipNameAccessorOptions = {
  openAsNewTab?: boolean;
  isLink?: boolean;
  onSyncWithSpire?: (params: OnSyncWithSpireParams) => void;
};
const shipNameAccessor =
  (type: RowValueType, options?: ShipNameAccessorOptions): AccessorFn<SeaboVessel> =>
  (rowValue): ReactNode => {
    const vessel = getVesselFromRowValue(rowValue, type);

    if (!vessel) {
      throw new Error(`vessel is ${vessel}, type is ${type}, options is ${JSON.stringify(options)}`);
    }

    return <ShipNameCell vessel={vessel} options={options} />;
  };

export const shipNameColumn = ({
  type,
  minWidth = 12,
  enableSorting = true,
  options,
  className = 'datagrid-sortable-header',
}: {
  type?: RowValueType;
  minWidth?: number;
  enableSorting?: boolean;
  options?: ShipNameAccessorOptions;
  className?: string;
}): CustomColumnDef<SeaboVessel> => ({
  header: () => (
    <div className={className} data-cy="header-shipname">
      <div>Vessel name</div>
    </div>
  ),
  id: 'name',
  accessorFn: shipNameAccessor(type, options),
  cell: info => info.getValue(),
  minWidth,
  minSize: minWidth,
  enableSorting,
});

const Cell = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
