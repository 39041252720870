import {Select} from 'antd';
import {OfferType} from '../utils/transformBucketsToTimeSeries';

export const OfferTypeFilter = ({value, onChange}: {onChange: (offerType: OfferType) => void; value: OfferType}) => {
  return (
    <Select
      defaultValue={value}
      style={{width: '100%'}}
      onChange={newValue => {
        onChange(newValue);
      }}>
      <Select.Option value={'vessel'}>Vessel circulars</Select.Option>
      <Select.Option value={'cargo'}>Cargo circulars</Select.Option>
      <Select.Option value={'all'}>All circulars</Select.Option>
    </Select>
  );
};
