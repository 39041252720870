import {CSSProperties, FC, HTMLAttributes, useRef} from 'react';
import styled from 'styled-components';
import {TimelineEvent, TimelineItem} from './utils/types';
import {VoyageTimelineEmpty} from './VoyageTimelineEmpty';
import {VoyageTimelineItem} from './VoyageTimelineItem/VoyageTimelineItem';
import {VoyageTimelineFilters} from './utils/filters';

type VoyageTimelineListProps = {
  voyageTimelineItems: TimelineItem[];
  filters?: VoyageTimelineFilters;
  maxDuration?: number;
  maxDraft?: number;
  isReduced?: boolean;
  style?: CSSProperties;
  className?: string;
  selectedEvent?: TimelineEvent | null;
  onSelectEvent?: (event: TimelineEvent | null) => void;
};

export const VoyageTimelineList: FC<VoyageTimelineListProps> = ({
  voyageTimelineItems,
  filters,
  maxDuration,
  maxDraft,
  isReduced,
  style,
  className,
  selectedEvent,
  onSelectEvent,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hasTimelineItems = !!voyageTimelineItems?.length;

  if (!hasTimelineItems) {
    return <VoyageTimelineEmpty />;
  }

  return (
    <VoyageTimelineListContainer
      ref={containerRef}
      style={style}
      className={className}
      data-testid="VoyageTimelineList">
      <TimelineList>
        {voyageTimelineItems.map((timelineItem, idx) => (
          <VoyageTimelineItem
            containerRef={containerRef}
            key={idx}
            index={idx}
            timelineItem={timelineItem}
            filters={filters}
            maxDraft={maxDraft}
            maxDuration={maxDuration}
            isReduced={isReduced}
            selectedEvent={selectedEvent}
            onSelectEvent={onSelectEvent}
          />
        ))}
      </TimelineList>
    </VoyageTimelineListContainer>
  );
};

const VoyageTimelineListContainer = styled.div<HTMLAttributes<HTMLDivElement> & {ref: React.RefObject<HTMLDivElement>}>`
  // Vessel timeline sizes
  --timeline-marker-radius: 12px;
  --timeline-marker-margin: 6px;
  --timeline-marker-padding: 20px;
  --timeline-marker-width: -5px;
  --timeline-width: 4px;
  --timeline-gap: 10px;

  position: relative;
  height: 100%;
  width: 100%;
  background: var(--color-gray-6);
  border: var(--border-base);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
`;

const TimelineList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  list-style: none;
  gap: var(--timeline-gap);
  font-size: var(--font-size-md);
  padding: 20px 20px 10px
    calc(var(--timeline-marker-width) + var(--timeline-marker-margin) + var(--timeline-marker-padding));
`;
