import {Card, Col, Row, Select} from 'antd';
import dayjs from 'dayjs';
import uniq from 'lodash/uniq';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {OfferStatistics} from '../../../../api/symfony/generated/models';
import Title from '../../../../atoms/Title';
import {StyledRangePicker} from '../../../../components/RangePicker/StyledRangePicker';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {GroupedPerUnitFilter} from '../../Filter/GroupedPerUnitFilter';
import {OfferTypeFilter} from '../../Filter/OfferTypeFilter';
import {StyleType, StyleTypeOption} from '../../Filter/StyleTypeOption';
import {CargoCommodityCategoryChart} from '../../Sections/CargoCommodityCategoryChart/CargoCommodityCategoryChart';
import {DayOfWeekBarChartSections} from '../../Sections/DayOfWeekBoxplotsSections/DayOfWeekBarChartSections';
import {Ticker} from '../../Sections/Ticker';
import {TimeserieChart} from '../../Sections/TimeserieChart/TimeserieChart';
import {TradingAreaSection} from '../../Sections/TradingAreaSection/TradingAreaSection';
import {VesselSegmentChart} from '../../Sections/VesselSegmentChart/VesselSegmentChart';
import {VesselTypeChart} from '../../Sections/VesselTypeChart/VesselTypeChart';
import {GroupedPerUnit} from '../../utils/groupBucketsByDate';
import {OfferType, transformBucketsToTimeSeries} from '../../utils/transformBucketsToTimeSeries';
import {useFilterOfferBucketsByDate} from '../../utils/useFilterOfferBucketsByDate';
import {TopTicker} from '../../Sections/OfferStatic/TopTicker';

export const PerSender: FC<{offerStatistics: OfferStatistics}> = ({offerStatistics}) => {
  const allBuckets = offerStatistics.buckets!;
  const [sender, setSender] = useState<string | undefined>();
  const [per, setPer] = useState<GroupedPerUnit>('day');
  const [styleType, setStyleType] = useState<StyleType>('line');
  const [offerType, setOfferType] = useState<OfferType>('all');
  // needed to prevent chart flashing when zoom is changed in chart
  const isChangeFromDatePicker = useRef(false);
  const allSender = uniq(allBuckets.map(bucket => bucket.tags?.find(tag => tag.name === 'sender')!.value));

  const allBucketsFromSender = useMemo(() => {
    return allBuckets.filter(bucket => bucket.tags?.some(tag => tag.name === 'sender' && tag.value === sender));
  }, [allBuckets, sender]);

  const [initStartDate, initEndDate] = useMemo<[string, string]>(() => {
    const bucketsToTime = transformBucketsToTimeSeries({buckets: allBucketsFromSender, per, offerType: offerType});
    return [bucketsToTime?.[0]?.date, bucketsToTime?.[bucketsToTime.length - 1]?.date];
  }, [allBucketsFromSender, per, offerType]);

  const [startDate, setStartDate] = useState<string>(initStartDate);
  const [endDate, setEndDate] = useState<string>(initEndDate);

  const allBucketsInPeriod = useFilterOfferBucketsByDate({
    buckets: allBucketsFromSender,
    startDate,
    endDate,
  });

  useEffect(() => {
    setStartDate(initStartDate);
    setEndDate(initEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sender, offerType]);

  return (
    <>
      <div>
        <StyledRow gutter={[16, 16]}>
          <Col span={24}>
            <Select
              showSearch={true}
              placeholder={'Select a sender'}
              defaultValue={sender}
              style={{width: '100%'}}
              onChange={v => {
                setSender(v);
              }}>
              {allSender.map(sender => (
                <Select.Option value={sender!} key={sender}>
                  {sender}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <OfferTypeFilter onChange={value => setOfferType(value)} value={offerType} />
          </Col>
          {startDate && endDate ? (
            <Col span={6}>
              <DateRangeContainer>
                <StyledRangePicker
                  allowClear={false}
                  format={DateTimeFormat.Date}
                  value={[dayjs(startDate), dayjs(endDate)]}
                  getPopupContainer={() => {
                    return document.getElementById('perSenderSenderRangePanel')!;
                  }}
                  placeholder={['Start date', 'End date']}
                  onChange={value => {
                    const [from, to] = (value ?? [dayjs(), dayjs()]).values();
                    isChangeFromDatePicker.current = true;
                    setStartDate(from?.format(DateTimeFormat.IsoDate) ?? '');
                    setEndDate(to?.format(DateTimeFormat.IsoDate) ?? '');
                  }}
                  disabledDate={date => {
                    return date.isBefore(initStartDate) || date.isAfter(initEndDate);
                  }}
                />
              </DateRangeContainer>
              <div id="perSenderSenderRangePanel" />
            </Col>
          ) : (
            <Ticker span={4}>
              <DateRangeContainer>
                <Flex>No sender selected</Flex>
              </DateRangeContainer>
            </Ticker>
          )}
          <TopTicker buckets={allBucketsInPeriod} />
        </StyledRow>

        {sender ? (
          <>
            <TimeChartContainer>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <StyledCard>
                    <ChartSelect>
                      <GroupedPerUnitFilter onChange={value => setPer(value)} value={per} />
                      <StyleTypeOption onChange={value => setStyleType(value)} value={styleType} />
                    </ChartSelect>
                    <TimeserieChart
                      onZoomIn={event => {
                        isChangeFromDatePicker.current = false;
                        setStartDate(event.start);
                        setEndDate(event.end);
                      }}
                      offerType={offerType}
                      per={per}
                      styleType={styleType}
                      buckets={allBucketsFromSender}
                      grid={{
                        left: 0,
                        right: 5,
                      }}
                      zoomRange={[startDate, endDate]}
                    />
                  </StyledCard>
                </Col>
              </Row>
            </TimeChartContainer>

            <Row gutter={[16, 16]}>
              <TradingAreaSection key={'map'} by={offerType} buckets={allBucketsInPeriod} />
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
              <DayOfWeekBarChartSections span={24} offerType={offerType} buckets={allBucketsInPeriod} />
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
              <VesselTypeChart buckets={allBucketsInPeriod} />
              <VesselSegmentChart buckets={allBucketsInPeriod} />
              <CargoCommodityCategoryChart perSender buckets={allBucketsInPeriod} />
            </Row>
          </>
        ) : (
          <>
            <Divider />
            <Card>
              <Title center large>
                Select a sender
              </Title>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

const StyledRow = styled(Row)`
  position: sticky;
  top: 72px;
  padding: 16px 0;
  background-color: var(--color-gray-5);
  z-index: 22;
`;

const ChartSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const TimeChartContainer = styled.div`
  position: sticky;
  top: -72px;
  margin-bottom: 20px;
  z-index: 21;
  background: var(--color-gray-5);
  outline: solid 20px var(--color-gray-5);
  border-radius: var(--border-radius-card);
`;

const Divider = styled.div`
  height: 16px;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
`;

const DateRangeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-button);
  font-size: var(--font-size-md);
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
  overflow: hidden;
`;

const StyledCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    padding: 24px 24px 0 24px;
  }
`;
