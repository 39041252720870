/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface PortCongestionVisit
 */
export interface PortCongestionVisit {
  /**
   *
   * @type {number}
   * @memberof PortCongestionVisit
   */
  imo: number;
  /**
   * The time in the port of the vessel at this visit in hours.
   * @type {number}
   * @memberof PortCongestionVisit
   */
  portDuration: number;
  /**
   * The waiting time of the vessel at this visit in hours.
   * @type {number}
   * @memberof PortCongestionVisit
   */
  waitingDuration: number;
  /**
   * The total time of the vessel at this visit in hours.
   * @type {number}
   * @memberof PortCongestionVisit
   */
  visitDuration: number;
  /**
   *
   * @type {number}
   * @memberof PortCongestionVisit
   */
  teu?: number;
  /**
   *
   * @type {number}
   * @memberof PortCongestionVisit
   */
  dwt?: number;
  /**
   *
   * @type {string}
   * @memberof PortCongestionVisit
   */
  vesselType: string;
  /**
   *
   * @type {string}
   * @memberof PortCongestionVisit
   */
  endTimestamp: string;
  /**
   *
   * @type {string}
   * @memberof PortCongestionVisit
   */
  startTimestamp: string;
  /**
   * The visit is still ongoing.
   * @type {boolean}
   * @memberof PortCongestionVisit
   */
  isOngoing: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortCongestionVisit
   */
  wasServed: boolean;
  /**
   *
   * @type {string}
   * @memberof PortCongestionVisit
   */
  estimatedPortAction: PortCongestionVisitEstimatedPortActionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PortCongestionVisitEstimatedPortActionEnum {
  Loading = 'loading',
  Discharge = 'discharge',
  Unknown = 'unknown',
}

export function PortCongestionVisitFromJSON(json: any): PortCongestionVisit {
  return PortCongestionVisitFromJSONTyped(json, false);
}

export function PortCongestionVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortCongestionVisit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    imo: json['imo'],
    portDuration: json['portDuration'],
    waitingDuration: json['waitingDuration'],
    visitDuration: json['visitDuration'],
    teu: !exists(json, 'teu') ? undefined : json['teu'],
    dwt: !exists(json, 'dwt') ? undefined : json['dwt'],
    vesselType: json['vesselType'],
    endTimestamp: json['endTimestamp'],
    startTimestamp: json['startTimestamp'],
    isOngoing: json['isOngoing'],
    wasServed: json['wasServed'],
    estimatedPortAction: json['estimatedPortAction'],
  };
}

export function PortCongestionVisitToJSON(value?: PortCongestionVisit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    imo: value.imo,
    portDuration: value.portDuration,
    waitingDuration: value.waitingDuration,
    visitDuration: value.visitDuration,
    teu: value.teu,
    dwt: value.dwt,
    vesselType: value.vesselType,
    endTimestamp: value.endTimestamp,
    startTimestamp: value.startTimestamp,
    isOngoing: value.isOngoing,
    wasServed: value.wasServed,
    estimatedPortAction: value.estimatedPortAction,
  };
}
