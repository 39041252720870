import {IconLayer, IconLayerProps} from '@deck.gl/layers/typed';
import {getVesselAngle} from '../utils/getVesselAngle';
import {iconMapping} from './iconMapping';
import iconAtlas from '../icons/seabo_map_icons/All-icons.svg';
import {assert} from '../../../../utils/assert';
import {Coordinates} from '../../../../utils/Coordinates';
import {AisVesselWithIconAndSize} from './AisVesselWithIconAndSize';

export type AisLayerProps = Partial<IconLayerProps<AisVesselWithIconAndSize>> & {
  data: AisVesselWithIconAndSize[];
  getSize: ((item: AisVesselWithIconAndSize) => number) | number;
  id: string;
};

export const getAisVesselLayer = (props: AisLayerProps) => {
  const layer = new IconLayer<AisVesselWithIconAndSize>({
    getIcon: item => {
      return item.icon;
    },
    pickable: true,
    getAngle: getVesselAngle,
    getPosition: (d: AisVesselWithIconAndSize) => {
      const coordinates = d.coordinates!;
      assert(coordinates.length === 2);
      return coordinates as Coordinates;
    },
    iconMapping,
    iconAtlas,
    ...props,
  });

  return layer;
};
