import {Col, Form, FormListFieldData, Row} from 'antd';
import {AddLadenLegsButton} from './AddLadenLegsButton';
import React, {FC, useState} from 'react';
import {FormValues} from '../../../../FormValues';
import {InputNumber} from '../../../../../../../../antd/components/InputNumber';
import IconButton from '../../../../../../../../atoms/IconButton';

export const LadenLegs: FC<{
  field: FormListFieldData;
}> = ({field}) => {
  const form = Form.useFormInstance();

  const options = Form.useWatch<FormValues['options'][0] | undefined>(['options', field.name], form);
  const {ladenLegsMax, ladenLegsMin} = options ?? {};

  const [showLadenLegs_, setShowLadenLegs] = useState<boolean>(false);

  const showLadenLegs = showLadenLegs_ || !!ladenLegsMax || !!ladenLegsMin;

  if (!showLadenLegs) {
    return (
      <Row>
        <AddLadenLegsButton showLadenLegs={showLadenLegs} onToggle={() => setShowLadenLegs(true)} />
      </Row>
    );
  }

  return (
    <Row>
      <Col span={12}>
        <Form.Item label="Laden legs">
          <Row gutter={4} align="middle">
            <Col span={10}>
              <Form.Item noStyle name={[field.name, 'ladenLegsMin']} data-cy="ladenLegsMin">
                <InputNumber min={0} maxLength={4} placeholder={'min'} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item noStyle name={[field.name, 'ladenLegsMax']} data-cy="ladenLegsMax">
                <InputNumber min={0} maxLength={4} placeholder={'max'} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <IconButton
                type={'delete'}
                iconStyle={{cursor: 'pointer'}}
                onClick={() => {
                  form.setFieldValue(['options', field.name, 'ladenLegsMin'], null);
                  form.setFieldValue(['options', field.name, 'ladenLegsMax'], null);
                  setShowLadenLegs(false);
                }}
                title={'Remove'}
                buttonStyle={{boxShadow: 'none', marginLeft: 1}}
                data-cy="DeleteLadenLegsButton"
              />
            </Col>
          </Row>
        </Form.Item>
      </Col>
    </Row>
  );
};
