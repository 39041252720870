/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {UserReference, UserReferenceFromJSON, UserReferenceFromJSONTyped, UserReferenceToJSON} from './';

/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {number}
   * @memberof Notification
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  link?: string | null;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  icon?: string | null;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  eventType: string;
  /**
   *
   * @type {Array<UserReference>}
   * @memberof Notification
   */
  authors: Array<UserReference>;
  /**
   *
   * @type {Date}
   * @memberof Notification
   */
  time: Date;
  /**
   *
   * @type {boolean}
   * @memberof Notification
   */
  isRead: boolean;
}

export function NotificationFromJSON(json: any): Notification {
  return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    message: json['message'],
    link: !exists(json, 'link') ? undefined : json['link'],
    icon: !exists(json, 'icon') ? undefined : json['icon'],
    eventType: json['eventType'],
    authors: (json['authors'] as Array<any>).map(UserReferenceFromJSON),
    time: new Date(json['time']),
    isRead: json['isRead'],
  };
}

export function NotificationToJSON(value?: Notification | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    message: value.message,
    link: value.link,
    icon: value.icon,
    eventType: value.eventType,
    authors: (value.authors as Array<any>).map(UserReferenceToJSON),
    time: value.time.toISOString(),
    isRead: value.isRead,
  };
}
