import {Card} from 'antd';
import {ReactNode, FC} from 'react';
import styled from 'styled-components';

type ProjectDetailCardProps = {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  noPadding?: boolean;
};

export const ProjectDetailCard: FC<ProjectDetailCardProps> = ({header, footer, children, noPadding}) => {
  return (
    <ProjectDetailCardStyled>
      {!!header && <CardHeader>{header}</CardHeader>}
      <CardBody noPadding={noPadding}>{children}</CardBody>
      {!!footer && <CardFooter>{footer}</CardFooter>}
    </ProjectDetailCardStyled>
  );
};

const ProjectDetailCardStyled = styled(Card)`
  height: 100%;
  margin-bottom: 20px;

  .ant-card-body {
    height: 100%;
    padding: 0;
  }
`;

const CardHeader = styled.div`
  padding: 15px 20px;
  font-size: var(--font-size-lg);
  border-bottom: var(--border-base);
`;

const CardBody = styled.div<{noPadding?: boolean}>`
  height: 100%;
  ${({noPadding}) => (noPadding ? '' : 'padding: 20px')};
`;

const CardFooter = styled.div`
  padding: 20px;
  border-top: var(--border-base);
`;
