import {FC} from 'react';
import LabelWrapper from '../../../../atoms/LabelWrapper';
import {getLocationValues} from '../../../LocationOutput/getLocationValues';
import {DropdownMultiValueLocation, getLocationSelectOption} from '../../../LocationOutput/LocationOutputRow';
import {ClearIndicator} from '../../../../atoms/Select/Indicators';
import {TODO} from '../../../../utils/TODO';
import {components} from 'react-select';
import {VesselStatus} from '../../../../screens/CargoVesselForm/helper';
import {useDispatch} from '../../../../redux/react-redux';
import locationApi from '../../../../redux/ApiService/locationService';
import {AsyncMultiSelect} from '../../../../atoms/Select/AsyncMultiSelect';
import {Location} from '../../../FilterProvider/Filters/utils/Location';

const GroupHeading = (props: $TSFixMe) => (
  <div className={'portfolio-location-edit__group-header'}>
    <components.GroupHeading {...props} />
  </div>
);

export const LocationFormItem: FC<{
  locations: Location[];
  vessel: TODO;
  status: VesselStatus;
  changeVesselState: (locations: Location[]) => void;
}> = ({locations, vessel, changeVesselState, status}) => {
  const dispatch = useDispatch();

  const onSearch = (query: string) => {
    return dispatch(locationApi.searchAll(query));
  };

  const isDisabled = status === 'scrapped' || status === 'inlayup';
  return (
    <LabelWrapper label={'Location open'}>
      <AsyncMultiSelect
        id={`portfolio-nextopen-stations`}
        name={`portfolio-nextopen-stations`}
        value={locations}
        autoFocus={vessel}
        onChange={(value: $TSFixMe) => changeVesselState(getLocationValues(value) || [])}
        getOptionLabel={(o: $TSFixMe) => getLocationSelectOption(o, true)}
        getOptionValue={(option: $TSFixMe) => option.id}
        loadOptions={(q: $TSFixMe) => (q.length > 1 ? onSearch(q) : Promise.resolve([]))}
        cacheOptions
        defaultOptions
        isClearable
        placeholder="Enter port, country or area"
        components={{GroupHeading, ClearIndicator, MultiValue: DropdownMultiValueLocation}}
        isDisabled={isDisabled}
      />
    </LabelWrapper>
  );
};
