/* eslint-disable prefer-rest-params */
import BaseModel from './BaseModel';
import User from './User';
import DateTime from './DateTime';
import {getLocationString} from './helper';
import {numberFormat, numberUnitFormat} from '../utils/formatter';

const returnNAIfEmpty = (text: string) => (text.trim().length === 0 ? 'N/A' : text);

export const dateRangeString = (
  fromDate: string | undefined | null,
  toDate: string | undefined | null,
  sep = ' - '
) => {
  const from = fromDate ? DateTime.fromServerResponse(fromDate).getFormattedDate() : '';
  const to = toDate && fromDate !== toDate ? DateTime.fromServerResponse(toDate).getFormattedDate() : '';

  // If both dates are in the same year, remove the year of the From-Date
  /**
  if (fromDate && toDate && fromDate.substring(0, 4) === toDate.substring(0, 4)) {
    from = DateTime.fromServerResponse(fromDate).getFormattedDate('DD.MM.');
  }
  **/

  return returnNAIfEmpty(`${from}${fromDate && toDate && fromDate !== toDate ? sep : ''}${to}`);
};

const getAttributes = (p: $TSFixMe) => {
  if (!p) {
    return [];
  }

  const a = [];
  if (p.dwtSummer) {
    a.push({label: 'DWT', value: numberFormat(p.dwtSummer)});
  }
  if (p.nextOpenFrom || p.nextOpenTo) {
    a.push({label: 'Date open', value: Vessel.formatDateOpen(p)});
  }
  if (p.stations) {
    a.push({label: 'Location open', value: getLocationString(p.stations, 'nextopen')});
  }
  if (p.builtYear) {
    a.push({label: 'Built', value: p.builtYear});
  }
  if (p.countryFlag) {
    a.push({label: 'Flag', value: p.countryFlag});
  }
  if (p.grain && p.grainUnit) {
    a.push({label: 'Grain capacity', value: numberUnitFormat(p.grain, p.grainUnit)});
  }

  return a;
};

class Vessel extends BaseModel {
  constructor(payload: $TSFixMe) {
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 0 or more.
    super(...arguments);
    this.user = payload.user ? new User(payload.user) : null;
    this.attributes = getAttributes(payload);
  }

  get highlight() {
    return this.payload.highlight;
  }

  get id() {
    return this.payload.id;
  }

  get name() {
    return this.payload.name;
  }

  get imo() {
    return this.payload.imo;
  }

  get type() {
    return this.payload.vesselType;
  }

  get title() {
    return this.name;
  }

  get countryFlag() {
    return this.payload.countryFlag;
  }

  get builtYear() {
    return this.payload.builtYear;
  }

  get dwtSummer() {
    return this.payload.dwtSummer;
  }

  get nextOpenFrom() {
    return this.payload.nextOpenFrom;
  }
  get nextOpenTo() {
    return this.payload.nextOpenTo;
  }

  get nextOpenDateFormatted() {
    return Vessel.formatDateOpen(this.payload);
  }

  get charterName() {
    return this.payload.charterName;
  }

  get intakes() {
    return this.payload.intakes;
  }

  get quantity() {
    return this.payload.quantity;
  }

  get managementManagingOwner() {
    return this.payload.managementManagingOwner;
  }

  get vesselType() {
    return this.payload.vesselType;
  }

  get iconUrl() {
    return '/static/images/placeholders/vessel.svg';
  }

  static formatDateOpen(
    {
      spotState,
      spotDate,
      nextOpenFrom,
      nextOpenTo,
    }: {
      spotState?: boolean | null;
      spotDate?: Date | string | null;
      nextOpenFrom?: string | null;
      nextOpenTo?: string | null;
    },
    sep = ' - '
  ) {
    if (spotState) {
      if (spotDate) {
        const spotDateString = typeof spotDate === 'string' ? spotDate : spotDate.toISOString();
        const formattedString = DateTime.fromServerResponse(spotDateString).getFormattedDate();
        return `Spot (${formattedString})`;
      } else {
        return 'Spot';
      }
    } else {
      return dateRangeString(nextOpenFrom!, nextOpenTo!, sep);
    }
  }

  static getSizeValue({vesselType, intakes, dwtSummer}: $TSFixMe, emptyString = 'N/A') {
    const isContainer = vesselType === 'container';
    const teu = intakes?.find((d: $TSFixMe) => d.type === 'teu') || null;
    const teu14 = intakes?.find((d: $TSFixMe) => d.type === 'teu14') || null;
    // eslint-disable-next-line no-nested-ternary
    return isContainer
      ? teu
        ? numberUnitFormat(teu.quantity, 'teu', {emptyString})
        : teu14 && numberUnitFormat(teu14.quantity, 'teu14', {emptyString})
      : numberUnitFormat(dwtSummer, 'dwt', {emptyString});
  }

  static formatVesselType = (vessel: $TSFixMe) => {
    if (!vessel.vesselType) {
      return '-';
    }
    return vessel.vesselType === 'mpp'
      ? vessel.vesselType.toUpperCase()
      : vessel.vesselType.charAt(0).toUpperCase() + vessel.vesselType.slice(1);
  };
  attributes: $TSFixMe;
  user: $TSFixMe;
}

export default Vessel;
