import {CheckOutlined, SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';
import {Dropdown, Menu, MenuProps, Radio} from 'antd';
import {FC} from 'react';
import styled from 'styled-components';
import {setMapDetailSort, VesselSortOptions, CargoSortOptions} from '../../../../../../redux/MapDetails';
import {useDispatch, useSelector} from '../../../../../../redux/react-redux';

export const ColumnOptions: FC<{type: 'cargo' | 'vessel'}> = ({type}) => {
  const {cargoSortDesc, vesselSortDesc} = useSelector(({mapDetails}) => mapDetails);
  const desc = type === 'cargo' ? cargoSortDesc : vesselSortDesc;
  return (
    <ColumnOptionsContainer>
      <Dropdown
        menu={{
          items: [
            {
              key: '1',
              label: type === 'cargo' ? <CargoSortMenu /> : <VesselSortMenu />,
            },
          ],
        }}
        trigger={['click']}
        placement="bottomRight">
        {desc ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
      </Dropdown>
    </ColumnOptionsContainer>
  );
};

export const CargoSortMenu: FC = () => {
  const dispatch = useDispatch();
  const {cargoSort, cargoSortDesc} = useSelector(({mapDetails}) => mapDetails);
  const setCargoSort = (newCargoSort: CargoSortOptions) =>
    dispatch(setMapDetailSort({cargoSort: newCargoSort === cargoSort ? '' : newCargoSort}));
  const setCargoSortDesc = (cargoSortDesc: boolean) => dispatch(setMapDetailSort({cargoSortDesc}));

  const menuItems: MenuProps['items'] = [
    {
      key: 'cargosort',
      label: (
        <StyledSwitch
          options={[
            {label: 'Ascending', value: false},
            {label: 'Descending', value: true},
          ]}
          onChange={e => setCargoSortDesc(e.target.value)}
          value={cargoSortDesc}
          optionType="button"
          buttonStyle="solid"
        />
      ),
    },
    {
      key: 'laycan',
      icon: cargoSort === 'laycan' ? <CheckOutlined /> : null,
      onClick: () => setCargoSort('laycan'),
      label: 'Laycan from',
    },
    {
      key: 'size',
      icon: cargoSort === 'size' ? <CheckOutlined /> : null,
      onClick: () => setCargoSort('size'),
      label: 'Size',
    },
  ];

  return <StyledMenu items={menuItems} />;
};

export const VesselSortMenu: FC = () => {
  const dispatch = useDispatch();
  const {vesselSort, vesselSortDesc} = useSelector(({mapDetails}) => mapDetails);
  const setVesselSort = (newVesselSort: VesselSortOptions) =>
    dispatch(setMapDetailSort({vesselSort: newVesselSort === vesselSort ? '' : newVesselSort}));
  const setVesselSortDesc = (vesselSortDesc: boolean) => dispatch(setMapDetailSort({vesselSortDesc}));

  const menuItems: MenuProps['items'] = [
    {
      key: 'vesselsort',
      label: (
        <StyledSwitch
          options={[
            {label: 'Ascending', value: false},
            {label: 'Descending', value: true},
          ]}
          onChange={e => setVesselSortDesc(e.target.value)}
          value={vesselSortDesc}
          optionType="button"
          buttonStyle="solid"
        />
      ),
    },
    {
      key: 'dateOpen',
      icon: vesselSort === 'dateOpen' ? <CheckOutlined /> : null,
      onClick: () => setVesselSort('dateOpen'),
      label: 'Date open',
    },
    {
      key: 'size',
      icon: vesselSort === 'size' ? <CheckOutlined /> : null,
      onClick: () => setVesselSort('size'),
      label: 'Size',
    },
    {
      key: 'year',
      icon: vesselSort === 'year' ? <CheckOutlined /> : null,
      onClick: () => setVesselSort('year'),
      label: 'Year',
    },
  ];

  return <StyledMenu items={menuItems} />;
};

const ColumnOptionsContainer = styled.div`
  float: right;
  margin-left: -22px;
`;

const StyledMenu = styled(Menu)`
  position: relative;
  top: 10px;
  right: -10px;
  min-width: 150px;
  padding: 4px;
  border: var(--border-base);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
`;

const StyledSwitch = styled(Radio.Group)``;
