/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AvailableOfferTag,
  AvailableOfferTagFromJSON,
  AvailableOfferTagFromJSONTyped,
  AvailableOfferTagToJSON,
  OfferBucket,
  OfferBucketFromJSON,
  OfferBucketFromJSONTyped,
  OfferBucketToJSON,
} from './';

/**
 * Statistics about cargo and vessel offers.
 * @export
 * @interface OfferStatistics
 */
export interface OfferStatistics {
  /**
   * Buckets with offers that share some characteristics. All buckets are disjoint.
   * @type {Array<OfferBucket>}
   * @memberof OfferStatistics
   */
  buckets?: Array<OfferBucket>;
  /**
   * A summary of tags and possible values that are available over all buckets in this statistic. It is *not* guaranteed that each bucket has all of the tag types that are returned.
   * @type {Array<AvailableOfferTag>}
   * @memberof OfferStatistics
   */
  availableTags?: Array<AvailableOfferTag>;
}

export function OfferStatisticsFromJSON(json: any): OfferStatistics {
  return OfferStatisticsFromJSONTyped(json, false);
}

export function OfferStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferStatistics {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    buckets: !exists(json, 'buckets') ? undefined : (json['buckets'] as Array<any>).map(OfferBucketFromJSON),
    availableTags: !exists(json, 'availableTags')
      ? undefined
      : (json['availableTags'] as Array<any>).map(AvailableOfferTagFromJSON),
  };
}

export function OfferStatisticsToJSON(value?: OfferStatistics | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    buckets: value.buckets === undefined ? undefined : (value.buckets as Array<any>).map(OfferBucketToJSON),
    availableTags:
      value.availableTags === undefined ? undefined : (value.availableTags as Array<any>).map(AvailableOfferTagToJSON),
  };
}
