import {FC} from 'react';
import {VoyagePoint} from '../../VoyageTypes';
import {getUsedFuelType} from '../../../utils/getUsedFuelType';
import {useSelector} from '../../../../../../redux/react-redux';

import {NotAvailable} from '../../../../../../utils/NotAvailable';

type Params = {point: VoyagePoint; type: 'main' | 'aux'};

const getLabel = ({point, isScrubberFitted, type}: Params & {isScrubberFitted: boolean}): string => {
  const consumptionFuelType = type === 'main' ? point.consumption?.mainFuelType : point.consumption?.auxFuelType;

  if (!consumptionFuelType) {
    return NotAvailable;
  }
  const fuelType = getUsedFuelType({
    isSECA: point.isInSECA,
    fuelType: consumptionFuelType,
    isScrubberFitted: isScrubberFitted,
  });

  return fuelType;
};

export const ConsumptionFuelTypeLabel: FC<Params> = ({point, type}) => {
  const isScrubberFitted = useSelector(state => state.charterCalculation.inputState.vessel.isScrubberFitted);
  return <span>{getLabel({point: point, isScrubberFitted, type})?.toLocaleUpperCase()}</span>;
};
