import {ReactNode} from 'react';
import Lighthouse from '../../../atoms/EmptyState/LightHouse';
import styled from 'styled-components';

export const VoyageTimelineEmpty = () => {
  return (
    <Container data-testid="VoyageTimelineEmpty">
      <Lighthouse
        headline={'No data available'}
        subHeadline={'Try to adjust the filters or come back another time.'}
        style={{marginTop: 0}}
      />
    </Container>
  );
};

const Container = styled.div<{children: ReactNode}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-base);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
`;
