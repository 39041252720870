import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import groupBy from 'lodash/groupBy';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
import {FC, useMemo} from 'react';
import {DateTimeFormat} from '../../../../utils/DateTimeFormat';
import {StatCard} from '../../sharedComponents/StatCard';
import {MergedPortCongestionVisit} from '../utils/useCongestionVisitsWithVesselInformationsQuery';
import {getEachDateInRange} from '../../utils/getEachDateInRange';

export const FinishedVisitsPerDate: FC<{
  visits: MergedPortCongestionVisit[];
  maxDate: string;
  onSetVisits: (vessels: MergedPortCongestionVisit[]) => void;
  title: string;
  barColor?: string;
  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, barColor, maxDate, title, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const perDateGrouped = groupBy(visits, vessel => dayjs(vessel.endTimestamp).format(DateTimeFormat.Date));

    const min = dayjs(minBy(visits, 'endTimestamp')!.endTimestamp);
    const max = dayjs(maxDate);

    const daysBetween = getEachDateInRange(min, max);
    const labels: string[] = daysBetween.map(day => day.format(DateTimeFormat.Date));

    const values: Record<string, number[]> = {};

    const perTypeGrouped = groupBy(visits, vessel => vessel.vesselType);
    const types = Object.keys(perTypeGrouped);
    for (const type of types) {
      const perDateGrouped = groupBy(perTypeGrouped[type], vessel =>
        dayjs(vessel.endTimestamp).format(DateTimeFormat.Date)
      );
      const valuesByType = labels.map(value => perDateGrouped[value]?.length ?? 0);
      values[type] = valuesByType;
    }
    return {values, labels, perDateGrouped};
  }, [visits, maxDate]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perDateGrouped[event.name]);
              onSetAppliedFilter(title, event.name);
            }
          },
        }}
        option={{
          title: [
            {
              left: 'center',
              text: title,
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: dataSource.labels,
          },
          yAxis: {
            type: 'value',
          },
          series: Object.keys(dataSource.values).map((key: string) => ({
            name: key,
            data: dataSource.values[key],
            type: 'bar',
            color: barColor,
            stack: 'total',
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          })),
        }}
      />
    </StatCard>
  );
};

export const VisitTimePerDate: FC<{
  visits: MergedPortCongestionVisit[];
  maxDate: string;
  onSetVisits: (vessels: MergedPortCongestionVisit[]) => void;
  title: string;
  barColor?: string;

  onSetAppliedFilter: (type: string, value: string) => void;
}> = ({visits, barColor, maxDate, title, onSetVisits, onSetAppliedFilter}) => {
  const dataSource = useMemo(() => {
    const perDateGrouped = groupBy(visits, vessel => dayjs(vessel.endTimestamp).format(DateTimeFormat.Date));

    const min = dayjs(minBy(visits, 'endTimestamp')!.endTimestamp);
    const max = dayjs(maxDate);

    const daysBetween = getEachDateInRange(min, max);
    const labels: string[] = daysBetween.map(day => day.format(DateTimeFormat.Date));

    const values: Record<string, number[]> = {};

    const perTypeGrouped = groupBy(visits, vessel => vessel.vesselType);
    const types = Object.keys(perTypeGrouped);
    for (const type of types) {
      const perDateGrouped = groupBy(perTypeGrouped[type], vessel =>
        dayjs(vessel.endTimestamp).format(DateTimeFormat.Date)
      );

      const valuesByType = labels.map(value => {
        const totalTime = perDateGrouped[value]?.map(visit => visit.waitingDuration) ?? [0];

        return Math.round((sumBy(totalTime) / totalTime.length / 24) * 100) / 100;
      });
      values[type] = valuesByType;
    }
    return {values, labels, perDateGrouped};
  }, [visits, maxDate]);

  return (
    <StatCard>
      <ReactECharts
        onEvents={{
          click: (event: {name?: string}) => {
            if (event.name) {
              onSetVisits(dataSource.perDateGrouped[event.name]);
              onSetAppliedFilter(title, event.name);
            }
          },
        }}
        option={{
          title: [
            {
              left: 'center',
              text: title,
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: dataSource.labels,
          },
          yAxis: {
            type: 'value',
          },
          series: Object.keys(dataSource.values).map((key: string) => ({
            name: key,
            data: dataSource.values[key],
            type: 'bar',
            color: barColor,
            stack: 'total',
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          })),
        }}
      />
    </StatCard>
  );
};
