import {Table, flexRender} from '@tanstack/react-table';
import classNames from 'classnames';
import {CustomColumnDef} from './DataGrid';
import {DataGridStickyHeaderWrapper} from './DataGridStickyHeaderWrapper';

export const DataGridHeader = <T,>({table, enableStickyHeader}: {table: Table<T>; enableStickyHeader: boolean}) => {
  return (
    <DataGridStickyHeaderWrapper enableStickyHeader={enableStickyHeader}>
      {table.getHeaderGroups().map(headerGroup => (
        <div key={headerGroup.id} className="rt-tr" data-cy={`DataGridHeaderGroup-${headerGroup.id}`} role="row">
          {headerGroup.headers.map(header => {
            const columnDef = header.column.columnDef as CustomColumnDef<T>;
            const sortableItem = columnDef.enableSorting;
            return (
              <div
                key={header.id}
                data-cy={`DataGridHeaderItem-${header.id}`}
                className={classNames(
                  columnDef.headerClassName,
                  'rt-th',
                  {'-cursor-pointer': !!sortableItem},
                  {'-sort-desc': header.column.getIsSorted() === 'desc'},
                  {'-sort-asc': header.column.getIsSorted() === 'asc'}
                )}
                style={{
                  flex: columnDef.minWidth,
                  width: columnDef.width || columnDef.maxWidth || 'auto',
                  maxWidth: columnDef.maxWidth,
                }}
                role="columnheader"
                onClick={header.column.getToggleSortingHandler()}>
                <div className={classNames({'datagrid-sortable-header': !!sortableItem})} data-cy="sorter">
                  {header.isPlaceholder ? null : flexRender(columnDef.header, header.getContext())}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </DataGridStickyHeaderWrapper>
  );
};
