import {Form} from 'antd';
import {FC} from 'react';
import {useQuery} from '@tanstack/react-query';
import PortfolioService, {Workspace} from '../../../../redux/ApiService/PortfolioService';
import {SubPortfolio} from '../../../../redux/Portfolio';
import {useDispatch} from '../../../../redux/react-redux';
import sortBy from 'lodash/sortBy';
import {MultiSelect} from '../../../../atoms/Select/MultiSelect';

export const CloneSelectSubPortfolios: FC<{
  workspace: Workspace;
  type: 'vessel' | 'cargo';
  selectedSubPortfolios: SubPortfolio[];
  onChange: (newSubportfolios: SubPortfolio[]) => void;
}> = ({workspace, selectedSubPortfolios, type, onChange}) => {
  const dispatch = useDispatch();
  const subPortfolioQuery = useQuery({
    queryKey: ['subPortfolioForOneWorkspace', workspace.id],
    queryFn: async () => {
      const action = type === 'vessel' ? PortfolioService.getVesselGroups : PortfolioService.getCargoGroups;

      const response = await dispatch<Promise<{data: SubPortfolio[]}>>(action(workspace));
      const subPortfolios = response.data;

      return sortBy(subPortfolios, ['sortOrder']);
    },
  });

  const subPortfolios = subPortfolioQuery.data ?? [{id: -1, name: 'Loading...', sortOrder: 0}];

  return (
    <Form.Item label={`Select ${type} list`}>
      <MultiSelect<SubPortfolio>
        value={selectedSubPortfolios}
        options={subPortfolios}
        onChange={newValue => {
          const options = Array.isArray(newValue) ? newValue : [newValue];
          onChange(options);
        }}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id.toString()}
      />
    </Form.Item>
  );
};
