import animateScrollTo from 'animated-scroll-to';
import {useEffect} from 'react';

export const useScrollToFirstError = () => {
  let scrollTimeout: number | null = null;

  useEffect(
    () => () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    },
    [scrollTimeout]
  );

  const scrollToFirstError = () => {
    scrollTimeout = window.setTimeout(() => {
      const firstEle = document.querySelector('.cargo-vessel-form .labeled-wrapper--hasError');
      if (firstEle) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        animateScrollTo(firstEle, {verticalOffset: -90});
      }
      scrollTimeout = null;
    }, 100);
  };

  return scrollToFirstError;
};
