export const fixtureCurrencyUnits = {
  usd: 'USD',
  eur: 'EUR',
  yen: 'YEN',
  other: 'Other',
};

export type FixtureCurrency = keyof typeof fixtureCurrencyUnits;

export const fixtureRateTermTypes = {
  day: 'per day',
  mt: 'per mt',
  cbm: 'per cbm',
  lumpsum: 'lumpsum',
  teu: 'per TEU',
  feu: 'per FEU',
};

export type FixtureRateTerm = keyof typeof fixtureRateTermTypes;

export const defaultBroker = [
  'Clarksons',
  'Containerland',
  'FH Bertling',
  'Harper Petersen',
  'Howe Robinson',
  'Maersk Broker',
  'Martini Chartering',
  'Maxbridge',
  'Mentz Decker',
  'Seapiece',
];

export const fixtureDurationUnits = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years',
};

export type FixtureDuration = keyof typeof fixtureDurationUnits;

export const durationOptions = Object.keys(fixtureDurationUnits).map(value => ({
  value,
  name: fixtureDurationUnits[value as FixtureDuration],
}));

export const currencyOptions = Object.keys(fixtureCurrencyUnits).map(value => ({
  value,
  name: fixtureCurrencyUnits[value as FixtureCurrency],
}));

export const rateTermOptions = Object.keys(fixtureRateTermTypes).map(value => ({
  value,
  name: fixtureRateTermTypes[value as FixtureRateTerm],
}));

export const brokerOptions = defaultBroker.map(value => ({value, label: value}));

export type ValidationForOneField =
  | undefined
  | {
      error: string;
      invalid: boolean;
    };

export const newPeriodOption = {
  periodMin: '',
  periodUnit: 'days',
  periodMax: '',
  rate: '',
  rateCurrency: Object.keys(fixtureCurrencyUnits)[0],
  rateTerms: Object.keys(fixtureRateTermTypes)[0],
};
