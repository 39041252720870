/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface AisRouteWaypoint
 */
export interface AisRouteWaypoint {
  /**
   * Unix timestamp of this waypoint.
   * @type {number}
   * @memberof AisRouteWaypoint
   */
  timestamp: number;
  /**
   * Speed at this waypoint in knots.
   * @type {number}
   * @memberof AisRouteWaypoint
   */
  speed?: number;
  /**
   * The coordinate of this waypoint. First longitude, then latitude.
   * @type {Array<number>}
   * @memberof AisRouteWaypoint
   */
  coordinates: Array<number>;
}

export function AisRouteWaypointFromJSON(json: any): AisRouteWaypoint {
  return AisRouteWaypointFromJSONTyped(json, false);
}

export function AisRouteWaypointFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisRouteWaypoint {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timestamp: json['timestamp'],
    speed: !exists(json, 'speed') ? undefined : json['speed'],
    coordinates: json['coordinates'],
  };
}

export function AisRouteWaypointToJSON(value?: AisRouteWaypoint | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timestamp: value.timestamp,
    speed: value.speed,
    coordinates: value.coordinates,
  };
}
