import React, {useMemo} from 'react';
import {Button, Collapse} from 'antd';
import {MulticalcVesselInput, MulticalcCargoInput} from '../../types';
import {MultiCalculation} from '../../useMultiCalculation';
import {useGoToUrl} from '../../../../../utils/useChangeBrowserUrl';
import styled from 'styled-components';
import {isCalculationWithResult} from '../../utils/isCalculationWithResult';
import {OneCalcResult} from '../../utils/oneCalc';
import {CargoToVesselTable} from './CargoToVesselTable';
import {getCalcCargoSets} from './getCalcCargoSets';
import LoadingAnimation from '../../../../../atoms/Loading';
import {Center} from '../../../../Center/Center';
import {LoadingStatus} from '../components';

type Props = {
  vessels: MulticalcVesselInput[];
  multiCalculation: MultiCalculation;
  cargoes: MulticalcCargoInput[];
  isLoading?: boolean;
};

export const CargoToVesselScreen = ({vessels, multiCalculation, cargoes, isLoading}: Props) => {
  const goToUrl = useGoToUrl();

  const filteredBreakEvenCalcs: OneCalcResult[] = useMemo(() => {
    return (multiCalculation.breakEvenCalcs ?? [])
      .filter(calc => isCalculationWithResult(calc))
      .filter(item => item.voyageCalculationOutput.voyage.points.length >= 2);
  }, [multiCalculation.breakEvenCalcs]);

  const {successfulCalcCargoes, erroneousCalcCargoes, hiddenCalcCargoes} = getCalcCargoSets(
    filteredBreakEvenCalcs,
    cargoes
  );

  return (
    <div data-testid={'CargoToVesselScreen'} data-cy={'CargoToVesselScreen'}>
      <p>Compare Break-Even Calculations based on your selected Cargoes.</p>
      {isLoading ? (
        <Center>
          <LoadingAnimation />
          <LoadingStatus />
        </Center>
      ) : (
        <>
          {successfulCalcCargoes.length ? (
            <BorderBox>
              <CargoToVesselTable
                testId={'successful'}
                vessels={vessels}
                multiCalculation={multiCalculation}
                breakEvenCalcs={filteredBreakEvenCalcs}
                cargoes={successfulCalcCargoes}
              />
            </BorderBox>
          ) : (
            <PaddedBorderBox>
              <p>
                Sorry, there were no successful calculations. <br />
                Please try again later or select different Cargoes and/or Vessels. <br />
                <br />
                See below for more information.
              </p>
            </PaddedBorderBox>
          )}
          {erroneousCalcCargoes.length ? (
            <StyledCollapse
              items={[
                {
                  key: 'errors',
                  label: `${erroneousCalcCargoes.length} calculation${
                    erroneousCalcCargoes.length !== 1 ? 's' : ''
                  } contained errors`,
                  children: (
                    <CargoToVesselTable
                      testId={'erroneous'}
                      vessels={vessels}
                      multiCalculation={multiCalculation}
                      breakEvenCalcs={filteredBreakEvenCalcs}
                      cargoes={erroneousCalcCargoes}
                    />
                  ),
                },
              ]}
            />
          ) : null}
          {multiCalculation.oneCalcResults !== undefined && hiddenCalcCargoes.length > 0 ? (
            <StyledCollapse
              items={[
                {
                  key: 'hidden',
                  label: `${hiddenCalcCargoes.length} calculation${
                    hiddenCalcCargoes.length !== 1 ? 's' : ''
                  } could not be computed`,
                  children: (
                    <>
                      <HiddenCalcInfo>
                        MultiCalc has hidden{' '}
                        {hiddenCalcCargoes.length !== 1 ? 'these Calculations' : 'this Calculation'} because{' '}
                        {hiddenCalcCargoes.length !== 1 ? 'their' : 'its'} cargo could not be computed. Fix them and
                        reload to enable MultiCalc for these cargoes too.
                      </HiddenCalcInfo>
                      <ul>
                        {hiddenCalcCargoes.map((calcCargo: MulticalcCargoInput) => {
                          const cargoName =
                            calcCargo.cargo.chartererName === null
                              ? `Cargo with ID ${calcCargo.cargo.id}`
                              : calcCargo.cargo.chartererName;
                          return (
                            <li key={calcCargo.cargo.id}>
                              <Button type={'link'} onClick={() => goToUrl(`/cargo/${calcCargo.cargo?.id}`)}>
                                Open cargo "{cargoName}"
                              </Button>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              ]}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

const BorderBox = styled.div`
  border: var(--border-light);
  border-radius: var(--border-radius);
  overflow: hidden;
`;

const PaddedBorderBox = styled(BorderBox)`
  padding: 20px 20px 5px;
`;

const StyledCollapse = styled(Collapse)`
  margin-top: 20px;

  .ant-collapse-content > .ant-collapse-content-box {
    overflow: auto;
    padding: 0;
  }
`;

const HiddenCalcInfo = styled.p`
  margin: 20px 20px 10px;
`;
