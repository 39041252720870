import styled from 'styled-components';
import {getIconById} from '../../../atoms/WheaterIcons';
import {renderWeatherBackground} from '../../../components/WeatherWidget/utils/weatherUtils';

export const WeatherIcon = ({id, isLarge}: {id?: number; isLarge?: boolean}) => {
  const weatherIconId = renderWeatherBackground(id ?? 0);
  const WeatherIconSvg = getIconById(weatherIconId);

  return <WeatherIconWrapper $isLarge={isLarge}>{WeatherIconSvg && <WeatherIconSvg />}</WeatherIconWrapper>;
};

const WeatherIconWrapper = styled.div<{$isLarge?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({$isLarge}) =>
    $isLarge
      ? `
      height: 40px;
      width: 80px;
    `
      : `
      height: 25px;
      width: 50px;
    `}
  margin-right: 5px;
  background: var(--color-blue-dark);
  border-radius: var(--border-radius);

  & > svg {
    height: ${({$isLarge}) => ($isLarge ? '90%' : 'calc(100% - 6px)')} !important;
    width: auto !important;
  }
`;
