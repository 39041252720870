import {FC} from 'react';
import styled from 'styled-components';
import {NegotiationStatus} from '../../../api/node-backend/generated';
import {getNegotiationStatusColors} from '../utils/colors';

type NegotiationStatusBadgeProps = {
  status: NegotiationStatus;
  active?: boolean;
  large?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const NegotiationStatusBadge: FC<NegotiationStatusBadgeProps> = ({status, active, large, style, onClick}) => {
  const label = status === NegotiationStatus.Settled ? 'Clean' : status;
  return (
    <StatusBadge
      $status={status}
      $active={active}
      $large={large}
      style={style}
      onClick={onClick}
      data-testid="NegotiationStatusBadge">
      {label}
    </StatusBadge>
  );
};

export const StatusBadge = styled.span<{$status: NegotiationStatus; $active?: boolean; $large?: boolean}>`
  --status-color: var(--${({$status}) => getNegotiationStatusColors($status).base});

  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 19px;
  margin-bottom: 2px;
  padding: 0 5px;
  border-radius: var(--border-radius);
  font-size: var(--font-size-sm);
  line-height: 1;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  ${({$active}) =>
    $active
      ? `
    background: var(--status-color);
    color: var(--color-white);
    border: 1.5px solid transparent;
    opacity: 1;
  `
      : `
    color: var(--status-color);
    border: 1.5px solid var(--status-color);
    opacity: .8;
  `}

  ${({$large}) =>
    $large &&
    `
    height: 35px;
    width: 100%;
    padding: 5px 10px;
    border-width: var(--border-width-active);
    border-radius: var(--border-radius-tab);
    font-size: var(--font-size);
    font-weight: bold;
  `}
`;
