import {Trip} from './Trip';
import {LonLatCoordinates} from '../../../../../../utils/Coordinates';
import {AnimationSeaSegment, AnimationEvent} from '../../model/AnimationEvent';

export const makeTrips = (animationEvents: AnimationEvent[]): Trip[] => {
  const seaSegments = animationEvents.filter((event: AnimationEvent) => {
    return event.type === 'sea-segment';
  }) as AnimationSeaSegment[];

  const coordinates: LonLatCoordinates[] = seaSegments.map(seaSegment => seaSegment.from.coordinates);
  const timestamps: number[] = seaSegments.map(seaSegment => seaSegment.timestamp.valueOf());

  const speeds: number[] = seaSegments.map(seaSegment => seaSegment.speedInKnots);
  const maxSpeed = Math.max(...speeds);

  const trip: Trip = {coordinates, timestamps, speeds, maxSpeed};
  const trips: Trip[] = [trip];
  return trips;
};
