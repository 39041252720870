import React, {useContext} from 'react';
import off from './off.svg';
import on from './on.svg';
import {MapContext} from '../../MapContext/MapContext';
import {StyledButton} from '../StyledButton';
import {Tooltip} from 'antd';

interface Props {
  className?: string;
}

export const FullScreenButton = (props: Props) => {
  const mapContext = useContext(MapContext);

  if (mapContext.props.canFullScreen === false) {
    return <></>;
  }
  return (
    <Tooltip title={'Fullscreen'} placement="left">
      <StyledButton
        className={`map-fullscreen-btn ${props.className}`}
        onClick={() => {
          if (mapContext.state.isFullScreen) {
            mapContext.setFullscreen(false);
          } else {
            mapContext.setFullscreen(true);
          }
        }}>
        <img alt={'fullscreenbtn'} src={mapContext.state.isFullScreen ? off : on} />
      </StyledButton>
    </Tooltip>
  );
};
