import {FC, useState} from 'react';
import {ArrowIndicator} from '../../atoms/Select/Indicators';
import Select from '../../atoms/Select/Select';
import styled from 'styled-components';
import {ColorColumnColor} from '../GridLayout/columns/shared/colorColumn';

export type ColorOption = {
  value: string | null;
  label: string;
  colorId: number | null;
};

interface Props {
  colorId: number | null;
  onChange: (colorObj: ColorOption) => void;
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
}

export const colorOptions: ColorOption[] = [
  {value: null, label: 'None', colorId: null},
  {value: '#f00046', label: 'Red', colorId: ColorColumnColor.RED},
  {value: '#ff8e23', label: 'Orange', colorId: ColorColumnColor.ORANGE},
  {value: '#40a2ff', label: 'Blue', colorId: ColorColumnColor.BLUE},
  {value: '#62d388', label: 'Green', colorId: ColorColumnColor.GREEN},
  {value: '#4340d6', label: 'Purple', colorId: ColorColumnColor.PURPLE},
  {value: '#ffbf00', label: 'Yellow', colorId: ColorColumnColor.YELLOW},
];

export const ColorPicker: FC<Props> = ({colorId, onChange, id, name, placeholder = 'Select a marker colour'}) => {
  const [color, setColor] = useState<ColorOption>(
    colorOptions.find(option => option.colorId === colorId) || colorOptions[0]
  );

  return (
    <Select<ColorOption>
      id={id}
      name={name}
      options={colorOptions}
      value={color}
      components={{
        DropdownIndicator: ArrowIndicator,
      }}
      getOptionLabel={option => (
        <OptionWrapper>
          {option.label}
          <OptionColor bgColor={option.value} />
        </OptionWrapper>
      )}
      getOptionValue={option => option.value || ''}
      placeholder={placeholder}
      onChange={data => {
        setColor(data as ColorOption);
        onChange(data as ColorOption);
      }}
      isSearchable={false}
    />
  );
};

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OptionColor = styled.div<{bgColor: string | null}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || 'transparent'};
`;
