// If this breaks something than this is necessary
// https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
// else o[key] = obj[key]; // Copy value.
export const removeEmpty = (obj: $TSFixMe) => {
  const o = JSON.parse(JSON.stringify(obj)); // Clone source oect.

  Object.keys(o).forEach(key => {
    if (o[key] && typeof o[key] === 'object') {
      o[key] = removeEmpty(o[key]);
    } else if (o[key] === undefined || o[key] === null || o[key] === '') {
      delete o[key];
    }
  });
  return o; // Return new object.
};
