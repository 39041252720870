import {Col, Row} from 'antd';
import {MapWidget} from '../Visualization/MapWidget';
import {FinanceOverview} from '../Visualization/FinanceOverview/FinanceOverview';
import React, {useState} from 'react';

export const MapResultRow = () => {
  const [showBody, setShowBody] = useState(false);
  return (
    <Row gutter={[16, 16]}>
      <Col span={16}>
        <MapWidget
          showBody={showBody}
          onChangeShowBody={newShowBody => {
            setShowBody(newShowBody);
          }}
        />
      </Col>
      <Col span={8}>
        <FinanceOverview
          showBody={showBody}
          onChangeShowBody={newShowBody => {
            setShowBody(newShowBody);
          }}
        />
      </Col>
    </Row>
  );
};
