import styled from 'styled-components';
import {Button} from 'antd';

type Props = {
  width?: number;
  height?: number;
};
export const StyledButton = styled(Button)<Props>`
  line-height: ${props => props.height ?? 26}px;
  display: flex;
  justify-content: center;
  padding: 0px;
  align-items: center;
  width: ${props => props.width ?? 26}px;
  height: ${props => props.height ?? 26}px;
  max-height: ${props => props.height ?? 26}px;
  max-width: ${props => props.width ?? 26}px;
  border: none;
  border-radius: 5px;
  background-color: rgba(0, 100, 180, 0.7) !important;
  color: var(--color-white);
  margin-bottom: 4px;
  cursor: pointer !important;

  &:hover {
    background-color: var(--color-blue);
  }
  &:focus {
    background-color: var(--color-white);
  }
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;
