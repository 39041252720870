import {Tabs} from 'antd';
import {FC, ReactNode, useMemo, useState} from 'react';
import styled from 'styled-components';
import {ConsumptionTable, DimensionsTable, IntakeTable} from '../../../../../../screens/CargoVesselForm/helper';
import {Consumption} from '../../../../../../screens/MyFleetScreens/MyFleetScreen/VesselPositionListModal/useMyFleetQuery/useMyFleetQuery';
import {calculateVoyageTimeline} from '../../../../../../screens/VesselDetailScreen/VoyageTimeline/utils/calculateVoyageTimeline';
import LoadingComponent from '../../../../../DataGrid/LoadingComponent';
import DetailFeatures from '../../../../../DetailFeatures';
import {MapDetailVesselProfileGraph} from './MapDetailVesselProfileGraph';
import {VesselDetailsVessel} from '../../../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {VoyageTimelineList} from '../../../../../../screens/VesselDetailScreen/VoyageTimeline/VoyageTimelineList';
import {useVesselVoyagesQuery, useStatsVesselQuery} from './queries';
import {useGetVoyageTimelineQuery} from '../../../../../../screens/VesselDetailScreen/VoyageTimeline/utils/useGetVoyageTimelineQuery';

export const MapDetailVesselTabs: FC<{
  detailVessel: VesselDetailsVessel;
}> = ({detailVessel}) => {
  const {id, imo} = detailVessel;

  const [activeTab, setActiveTab] = useState('dimensions');

  const vesselVoyagesQuery = useVesselVoyagesQuery(id);
  const statsVesselQuery = useStatsVesselQuery(imo);

  const vesselVoyages = vesselVoyagesQuery.data;
  const statsVessel = statsVesselQuery.data;

  const voyageTimelineQuery = useGetVoyageTimelineQuery(imo);

  const tabItems = useMemo(() => {
    const {voyageTimelineItems, maxDuration} = calculateVoyageTimeline(voyageTimelineQuery.data);
    const items = [
      {
        key: 'dimensions',
        label: <TabLabel>Dimensions</TabLabel>,
        children: (
          <TabContent $padded>
            <DimensionsTable vessel={detailVessel} />
          </TabContent>
        ),
      },
      {
        key: 'voyage-timeline',
        label: <TabLabel isLoading={vesselVoyagesQuery.isLoading}>Voyage Timeline</TabLabel>,
        disabled: !vesselVoyages?.data.items?.length,
        children: (
          <TabContent>
            <VoyageTimelineList
              voyageTimelineItems={voyageTimelineItems}
              maxDuration={maxDuration}
              style={{overflowY: 'scroll'}}
            />
          </TabContent>
        ),
      },
      {
        key: 'intake',
        label: <TabLabel>Intake</TabLabel>,
        children: (
          <TabContent $padded>
            <IntakeTable vessel={detailVessel} />
          </TabContent>
        ),
      },
      {
        key: 'features',
        label: <TabLabel>Features</TabLabel>,
        children: (
          <TabContent $padded>
            <DetailFeatures
              detailObject={detailVessel}
              iceClass={detailVessel.iceClass}
              segment={detailVessel.vesselType}
              type={'vessel'}
            />
          </TabContent>
        ),
      },
      {
        key: 'consumption',
        label: <TabLabel>Consumption</TabLabel>,
        disabled: !detailVessel.consumptions?.some((c: Consumption) => c.type.length > 0),
        children: (
          <TabContent $padded>
            <ConsumptionTable {...detailVessel} />
          </TabContent>
        ),
      },
      {
        key: 'statistics',
        label: <TabLabel isLoading={statsVesselQuery.isLoading}>Statistics</TabLabel>,
        disabled: !statsVessel?.data?.items?.length,
        children: (
          <TabContent>
            <MapDetailVesselProfileGraph id={id} statsVessel={statsVessel} />
          </TabContent>
        ),
      },
    ];
    return items;
  }, [id, detailVessel, vesselVoyages, statsVessel, vesselVoyagesQuery, statsVesselQuery, voyageTimelineQuery.data]);

  return (
    <TabContainer>
      <StyledTabs tabPosition="top" items={tabItems} activeKey={activeTab} onChange={key => setActiveTab(key)} />
    </TabContainer>
  );
};

const TabContainer = styled.div`
  z-index: 0;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: auto;
  min-height: 200px;

  .ant-tabs-nav {
    margin: 0;
    border-bottom: var(--border-width) solid var(--border-color-light);
    box-shadow: var(--map-subheader-shadow);

    &::before {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    justify-content: space-between;
    min-width: 100%;
    padding: 0 20px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`;

const TabLabel = ({children, isLoading}: {children: ReactNode; isLoading?: boolean}) => {
  return (
    <span>
      {children}
      {isLoading && <StyledLoadingComponent />}
    </span>
  );
};

const StyledLoadingComponent = styled(LoadingComponent)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.2);
  opacity: 0.4;
`;

const TabContent = styled.div<{$padded?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  padding: ${({$padded}) => ($padded ? '0 20px' : '0')};
  overflow-y: auto;

  & > * {
    width: 100%;
  }
`;
