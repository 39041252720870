import styled from 'styled-components';
import {Divider as DD} from 'antd';

export const Divider = styled(DD)`
  .ant-divider-inner-text {
    display: contents;
    padding: 0;
    background-color: var(--color-white);
  }
`;

export default Divider;
