import dayjs from 'dayjs';
import {useQuery} from '@tanstack/react-query';
import {
  GetPortfolioCargoesDurationUnitEnum,
  GetPortfolioCargoesGearEnum,
  GetPortfolioCargoesQuantityUnitEnum,
  GetPortfolioCargoesRequest,
} from '../../../api/symfony/generated';
import {portfolioCargoApi} from '../../../api/symfony/symfonyApi';
import {PortfolioCargoDatabaseFilter} from '../../../components/FilterProvider/Filters/Portfolio/PortfolioCargoFilterBranchDefinitions';
import {SortOrder, SubPortfolioId} from '../../../redux/Portfolio';
import {gentleZodValidation} from '../../../api/utils/gentleZodValidation';
import {
  CargoCollection,
  PortfolioCargoCollectionSchema,
} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {ApiError} from '../../../api/utils/ApiError';
import {UseQueryOptions} from '../../../api/utils/ReactQuery';

export interface GetMyCargoesQueryParams {
  filters: PortfolioCargoDatabaseFilter | null;
  subPortfolioId: SubPortfolioId;
  isArchive: boolean;
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortOrder: SortOrder;
}

const helperBooleanToNumber = (value: boolean | undefined): number | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return value ? 1 : undefined;
};

const helperBooleanToGearOrUndefined = (value: boolean | undefined): GetPortfolioCargoesGearEnum | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return value ? GetPortfolioCargoesGearEnum.Geared : GetPortfolioCargoesGearEnum.Gearless;
};

type RequestLocations = Pick<
  GetPortfolioCargoesRequest,
  | 'locationDischargeCountries'
  | 'locationDischargePorts'
  | 'locationDischargeTradingAreas'
  | 'locationLoadCountries'
  | 'locationLoadPorts'
  | 'locationLoadTradingAreas'
  | 'locationViaCountries'
  | 'locationViaPorts'
  | 'locationViaTradingAreas'
>;

const convertLocations = (cargoFilter: PortfolioCargoDatabaseFilter): RequestLocations => {
  const requestLocations: RequestLocations = {};

  const loaddelivery = cargoFilter.station?.stations.find(station => station.type === 'loaddelivery');
  const dischargeredelivery = cargoFilter.station?.stations.find(station => station.type === 'dischargeredelivery');
  const deliveryvia = cargoFilter.station?.stations.find(station => station.type === 'deliveryvia');

  const locationDischargeCountries =
    dischargeredelivery?.locations.filter(location => location.type === 'country').map(location => location.id) ?? [];
  requestLocations.locationDischargeCountries =
    locationDischargeCountries.length === 0 ? undefined : locationDischargeCountries;

  const locationDischargePorts =
    dischargeredelivery?.locations.filter(location => location.type === 'port').map(location => location.id) ?? [];
  requestLocations.locationDischargePorts = locationDischargePorts.length === 0 ? undefined : locationDischargePorts;

  const locationDischargeTradingAreas =
    dischargeredelivery?.locations.filter(location => location.type === 'tradingarea').map(location => location.id) ??
    [];
  requestLocations.locationDischargeTradingAreas =
    locationDischargeTradingAreas.length === 0 ? undefined : locationDischargeTradingAreas;

  const locationLoadCountries =
    loaddelivery?.locations.filter(location => location.type === 'country').map(location => location.id) ?? [];
  requestLocations.locationLoadCountries = locationLoadCountries.length === 0 ? undefined : locationLoadCountries;

  const locationLoadPorts =
    loaddelivery?.locations.filter(location => location.type === 'port').map(location => location.id) ?? [];
  requestLocations.locationLoadPorts = locationLoadPorts.length === 0 ? undefined : locationLoadPorts;

  const locationLoadTradingAreas =
    loaddelivery?.locations.filter(location => location.type === 'tradingarea').map(location => location.id) ?? [];
  requestLocations.locationLoadTradingAreas =
    locationLoadTradingAreas.length === 0 ? undefined : locationLoadTradingAreas;

  const locationViaCountries =
    deliveryvia?.locations.filter(location => location.type === 'country').map(location => location.id) ?? [];
  requestLocations.locationViaCountries = locationViaCountries.length === 0 ? undefined : locationViaCountries;

  const locationViaPorts =
    deliveryvia?.locations.filter(location => location.type === 'port').map(location => location.id) ?? [];
  requestLocations.locationViaPorts = locationViaPorts.length === 0 ? undefined : locationViaPorts;

  const locationViaTradingAreas =
    deliveryvia?.locations.filter(location => location.type === 'tradingarea').map(location => location.id) ?? [];
  requestLocations.locationViaTradingAreas = locationViaTradingAreas.length === 0 ? undefined : locationViaTradingAreas;

  return requestLocations;
};

const convertPortfolioCargoReduxFilterToRequest = (
  cargoFilter: PortfolioCargoDatabaseFilter | null
): GetPortfolioCargoesRequest => {
  const request: GetPortfolioCargoesRequest = {};

  if (cargoFilter === null) {
    return request;
  }

  if ((cargoFilter.contractTypes?.length ?? 0) > 0) {
    request.contractTypes = cargoFilter.contractTypes!.map(contact => contact.toLowerCase());
  }
  if (cargoFilter.cargoTypes && cargoFilter.cargoTypes.length > 0) {
    request.cargoTypes = cargoFilter.cargoTypes.map(contact => contact.toLowerCase());
  }
  if (cargoFilter.charterer?.chartererNames && cargoFilter.charterer?.chartererNames.length > 0) {
    request.chartererNames = cargoFilter.charterer.chartererNames.map(contact => contact.toLowerCase());
  }

  if (cargoFilter.vesselSize) {
    request.vesselSizeDeadweightMin = cargoFilter.vesselSize.deadWeight?.from;
    request.vesselSizeDeadweightMax = cargoFilter.vesselSize.deadWeight?.to;

    request.vesselSizeTeu14Min = cargoFilter.vesselSize.teu14?.from;
    request.vesselSizeTeu14Max = cargoFilter.vesselSize.teu14?.to;

    request.vesselSizeTeuMin = cargoFilter.vesselSize.teu?.from;
    request.vesselSizeTeuMax = cargoFilter.vesselSize.teu?.to;
  }

  if (cargoFilter.vesselSize) {
    if ((cargoFilter.vesselSize.vesselDesigns ?? []).length > 0) {
      request.bulkerSubTypes = cargoFilter.vesselSize.vesselDesigns;
    }
    if ((cargoFilter.vesselSize.vesselDesignTypes ?? []).length > 0) {
      request.containerDesignTypes = cargoFilter.vesselSize.vesselDesignTypes;
    }
  }

  if (cargoFilter.quantity) {
    request.quantityUnit = cargoFilter.quantity.unit as GetPortfolioCargoesQuantityUnitEnum;
    request.quantityMax = cargoFilter.quantity.quantity?.to;
    request.quantityMin = cargoFilter.quantity.quantity?.from;

    if (['teu', 'feu', '45f'].includes(cargoFilter.quantity.unit as string)) {
      request.quantityHighCube = cargoFilter.quantity.isHighcube ? 1 : undefined;
      request.quantityEmpty = cargoFilter.quantity.isEmpty ? 1 : undefined;
    }
  }

  if (cargoFilter.features) {
    request.iceClass = helperBooleanToNumber(cargoFilter.features.iceClass);
    request.itfFitted = helperBooleanToNumber(cargoFilter.features.itfFitted);
    request.a60Bulkhead = helperBooleanToNumber(cargoFilter.features.a60Bulkhead);
    request.co2Fitted = helperBooleanToNumber(cargoFilter.features.co2Fitted);
    request.boxShape = helperBooleanToNumber(cargoFilter.features.boxShaped);
    request.cellulared = helperBooleanToNumber(cargoFilter.features.cellular);
    request.bowThrusted = helperBooleanToNumber(cargoFilter.features.bowThrusted);
    request.lakesFitted = helperBooleanToNumber(cargoFilter.features.lakesFitted);
    request.gear = helperBooleanToGearOrUndefined(cargoFilter.features.geared);
    request.logFitted = helperBooleanToNumber(cargoFilter.features.logFitted);
    request.openHatch = helperBooleanToNumber(cargoFilter.features.openHatched);
    request.scrubberFitted = helperBooleanToNumber(cargoFilter.features.scrubberFitted);
    request.sternThrusted = helperBooleanToNumber(cargoFilter.features.sternThrusted);
    request.tweenDecks = helperBooleanToNumber(cargoFilter.features.tweenDecks);
  }

  if (cargoFilter.laycan) {
    if (cargoFilter.laycan.date?.from !== undefined) {
      const from = dayjs(cargoFilter.laycan.date?.from || undefined);
      request.laycanFrom = from.isValid() ? from.toDate() : undefined;
    }

    if (cargoFilter.laycan.date?.to !== undefined) {
      const to = dayjs(cargoFilter.laycan.date?.to || undefined);
      request.laycanTo = to.isValid() ? to.toDate() : undefined;
    }
  }

  if (cargoFilter.duration) {
    request.durationMax = cargoFilter.duration.range?.to;
    request.durationMin = cargoFilter.duration.range?.from;
    request.durationUnit = cargoFilter.duration.unit as GetPortfolioCargoesDurationUnitEnum;
  }

  if ((cargoFilter.commodities ?? []).length > 0) {
    request.commodityCategories = cargoFilter.commodities;
  }

  const updatedRequest = {...request, ...convertLocations(cargoFilter)};
  return updatedRequest;
};

const convertSortParamsToSortAttribute = (sortParams: GetMyCargoesQueryParams): string[] => {
  return [(sortParams.sortOrder === 'asc' ? '' : '-') + sortParams.sortField];
};

export const getGetMyCargoesRequest = (params: GetMyCargoesQueryParams): GetPortfolioCargoesRequest => {
  return {
    ...convertPortfolioCargoReduxFilterToRequest(params.filters),
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    newFilter: 1,
    sorts: convertSortParamsToSortAttribute(params),
    archived: params.isArchive ? 1 : 0,
    portfolioGroup: params.subPortfolioId === 'all' ? undefined : params.subPortfolioId,
  };
};

export const GetMyCargoesQueryKey = ' portfolioCargoApi.getPortfolioCargoes';
export const useGetMyCargoesQuery = (
  params: GetMyCargoesQueryParams,
  options?: UseQueryOptions<CargoCollection, ApiError>
) => {
  return useQuery<CargoCollection, ApiError>({
    queryKey: [GetMyCargoesQueryKey, params],
    queryFn: async () => {
      const request: GetPortfolioCargoesRequest = getGetMyCargoesRequest(params);
      const result = (await portfolioCargoApi.getPortfolioCargoes(request)) as CargoCollection;
      return gentleZodValidation(PortfolioCargoCollectionSchema, result);
    },
    ...options,
  });
};
