import {IconLayer} from '@deck.gl/layers/typed';
import iconAtlas from './icons/icon-atlas.png';
import iconMapping from './icons/icon-mapping.json';
import {CompositeLayer} from '@deck.gl/core/typed';
import {Position} from '@deck.gl/core/typed';
import {SWITCHES} from '../mapOptions/switches';
import {filterPortfolioVessels} from '../utils/filterPortfolioVessels';
import {useContext} from 'react';
import {MapElement, MapSwitches} from '../MapDetails/utils/types';
import {MapContext} from '../MapContext/MapContext';
import {useSelector} from '../../../redux/react-redux';
import {getAisVesselLayer} from './useVesselLayer/getAisVesselLayer';
import {AisVesselWithIconAndSize} from './useVesselLayer/AisVesselWithIconAndSize';

type Props = {
  zoom: number;
  data: AisVesselWithIconAndSize[];
};

export class PortfolioVesselLayer extends CompositeLayer<Props> {
  static layerName = 'PortfolioVesselLayer';

  renderLayers() {
    const baseSize = this.props.zoom * 3;
    return [
      getAisVesselLayer({
        id: `${this.props.id}-icons`,
        data: this.props.data,
        getSize: baseSize,
        sizeMinPixels: 14,
        onClick: this.props.onClick,
      }),
      new IconLayer<AisVesselWithIconAndSize>({
        id: `${this.props.id}-rect`,
        data: this.props.data,
        getSize: baseSize + 22,
        sizeMinPixels: 36,
        getColor: [255, 255, 255],
        getIcon: () => 'rect-dashed-mask',
        getPosition: d => d.coordinates! as Position,
        getPolygonOffset: ({layerIndex}) => [0, -layerIndex * 10000],
        iconAtlas,
        iconMapping,
      }),
    ];
  }
}

export const usePortfolioVesselLayer = ({
  switches,
  onSelectElement,
}: {
  switches: MapSwitches;
  onSelectElement: (item: MapElement) => void;
}) => {
  const mapContext = useContext(MapContext);
  const aisVessels = useSelector(state => state.api.map.getAisVessels?.data?.items);
  const portfolioVessels = useSelector(state => state.api.map.getPortfolioGroups?.data?.vessels);
  const zoom = mapContext.props.vesselsToVisualize?.constantIconSize ?? mapContext.state.viewState.zoom;

  if (!switches['vessel_portfolio']?.state) {
    return [];
  }

  return [
    new PortfolioVesselLayer({
      onClick: info => onSelectElement(info as MapElement),
      zoom: zoom,
      data: filterPortfolioVessels(switches[SWITCHES.VESSEL_PORTFOLIO_SUB]!)(portfolioVessels)(aisVessels),
    }),
  ];
};
