import {convertLabelToSlug} from '../FilterConversion/utils/FilterConversionUtils';
import {makeFilterBranchDefinition} from '../../FilterBranchDefinition';

export interface DatabaseType {
  chartererNames: string[];
}

export interface ChartererName {
  value: string;
  label: string;
}

export interface InternalType {
  chartererNames: ChartererName[];
}

export const Defaults: InternalType = {
  chartererNames: [],
};

export const fromDatabase = (database: DatabaseType): InternalType => {
  const {chartererNames} = database;
  return {
    chartererNames: chartererNames ? chartererNames.map(c => ({value: convertLabelToSlug(c), label: c})) : [],
  };
};

export const toDatabase = (internal: InternalType): DatabaseType => {
  const {chartererNames} = internal;
  return {
    chartererNames: chartererNames ? chartererNames.map(c => c.label) : [],
  };
};

export const filterBranchDefinition = makeFilterBranchDefinition({
  name: 'Charterer',
  branch: 'charterer',
  defaults: Defaults,
  fromDatabase,
  toDatabase,
});
