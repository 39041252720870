import {InfoCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';

export const NoDataComponentDefault = ({
  message = "We are sorry, we couldn't find any results.",
}: {
  message?: string;
}) => (
  <Center>
    <div>
      <InfoCircleOutlined /> {message}
    </div>
  </Center>
);

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
`;
