import {ScreenHeader} from '../../components/ScreenHeader/ScreenHeader';
import {CircularsStatsBody} from './CircularsStatsBody';

export const CircularStatsScreen = () => {
  return (
    <>
      <ScreenHeader helmetTitle="Circular Stats" breadcrumbs={[{title: 'Analytics'}, {title: 'Circular stats'}]} />
      <CircularsStatsBody />
    </>
  );
};
