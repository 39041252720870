import Station from '../../model/Station';
import {FC, Fragment} from 'react';
import {numberRangeUnitFormat, numberUnitFormat} from '../../utils/formatter';
import {TBN} from '../../components/Placeholder/Placeholder';
import {TODO} from '../../utils/TODO';
import {StationType} from '../../api/symfony/generated/models';
import './style.scss';
import {UserDateTime} from '../../components/DateTime/UserDateTime';
import {NotAvailable} from '../../utils/NotAvailable';

export interface Cargo {
  id: number;
  cargoType?: 'container' | 'drybulk' | null;
  intakes?: TODO[];
  contractType: string | null;
  laycanFrom?: string | null;
  laycanTo?: string | null;
  quantityMin?: string | number | null;
  quantityMax?: string | number | null;
  quantityUnit?: string | null;
  vesselSizeMin?: string | number | null;
  vesselSizeMax?: string | number | null;
  vesselSizeUnit?: string | null;
  chartererName?: string | null;
  stations: Array<TODO>; // StationObject
}

const Laycan: FC<{
  laycanFrom?: string | null;
  laycanTo?: string | null;
}> = props => {
  if (props.laycanFrom && props.laycanTo) {
    return (
      <Fragment>
        {props.laycanFrom ? <UserDateTime value={props.laycanFrom} format="date" /> : null}
        <span> - </span>
        {props.laycanTo ? <UserDateTime value={props.laycanTo} format="date" /> : null}
      </Fragment>
    );
  }

  if (props.laycanFrom) {
    return <UserDateTime value={props.laycanFrom} format="date" />;
  }
  if (props.laycanTo) {
    return <UserDateTime value={props.laycanTo} format="date" />;
  }
  return <>{NotAvailable}</>;
};

const size = ({cargo}: {cargo: Cargo}): string => {
  const isContainer = cargo.cargoType === 'container';
  const teu = cargo.intakes?.find((d: $TSFixMe) => d.type === 'teu') || null;
  if (cargo.contractType === 'vc') {
    return numberRangeUnitFormat(cargo.vesselSizeMin, cargo.vesselSizeMax, cargo.vesselSizeUnit);
  }
  if (!isContainer) {
    return numberRangeUnitFormat(cargo.quantityMin, cargo.quantityMax, cargo.quantityUnit);
  }
  if (teu) {
    return numberUnitFormat(teu.quantity, 'teu');
  }
  return NotAvailable;
};

export const CargoDisplay: FC<{cargo: Cargo}> = ({cargo}) => {
  const location =
    cargo.contractType === 'vc'
      ? Station.getLocationByStationType(cargo.stations, StationType.Loading, {short: true})
      : Station.getLocationByStationType(cargo.stations, StationType.Delivery, {short: true});

  return (
    <div className={'dropdown-display'}>
      <span className={'dropdown-display__title'}>{cargo.chartererName || <TBN />} </span>
      <span className={'dropdown-display__sub-title'}>
        (Size: {size({cargo})}, Load/Delivery: {location || 'N/A'}, Laycan:{' '}
        <Laycan laycanFrom={cargo.laycanFrom} laycanTo={cargo.laycanTo} />)
      </span>
    </div>
  );
};
