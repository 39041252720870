import {FC} from 'react';
import {Select} from '../../../../../../../antd/components/Select';
import {Form} from 'antd';
import {FormValues} from '../../../FormValues';
import {changesDefaults} from './changesDefaults';

const contractTypes = {
  pc: 'Period charter',
  tct: 'Time charter trip',
  vc: 'Voyage charter',
  bc: 'Bareboat charter',
  sale: 'Sale',
};

type ContractType = keyof typeof contractTypes;

export const ContractTypePicker: FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({value, onChange}) => {
  const form = Form.useFormInstance<FormValues>();

  const options = Form.useWatch('options', form);
  // Change defaults depending on the type of contract

  return (
    <Select
      allowClear={true}
      value={value}
      onChange={value => {
        onChange?.(value);
        changesDefaults(value as ContractType | null, options, form);
      }}
      options={Object.entries(contractTypes).map(([value, label]) => ({value, label}))}
    />
  );
};
