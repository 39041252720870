/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface GeolocationCoordinates
 */
export interface GeolocationCoordinates {
  /**
   *
   * @type {number}
   * @memberof GeolocationCoordinates
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof GeolocationCoordinates
   */
  longitude?: number;
}

export function GeolocationCoordinatesFromJSON(json: any): GeolocationCoordinates {
  return GeolocationCoordinatesFromJSONTyped(json, false);
}

export function GeolocationCoordinatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeolocationCoordinates {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    latitude: !exists(json, 'latitude') ? undefined : json['latitude'],
    longitude: !exists(json, 'longitude') ? undefined : json['longitude'],
  };
}

export function GeolocationCoordinatesToJSON(value?: GeolocationCoordinates | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    latitude: value.latitude,
    longitude: value.longitude,
  };
}
