import {FC} from 'react';
import Country from '../../../../../../model/Country';
import {
  MapDetailsSubtitle,
  MapDetailsTitle,
  MapDetailsHeader,
  MapDetailsContent,
  MapDetailLink,
  MapDetailsLoading,
  MapDetailsError,
  ActionButtonLeft,
} from '../../shared-components';
import {
  getMapDetailVesselDataColumns,
  MapDetailVesselDataAttributes,
  ColumnAttributeType,
  OnMapElementChange,
} from '../../../utils/utils';
import {CountryLocation} from '../../../../../LocationOutput/LocationOutputRow';
import {isMapVesselElement} from '../../../utils/types';
import {MapDetailsWeather} from '../../MapDetailsWeather';
import styled from 'styled-components';
import {Attribute, TabSize} from '../../../../../../atoms/Attribute/Attribute';
import {MapDetailVesselTabs} from './MapDetailVesselTabs';
import {useMapDetailsVesselQuery} from './useMapDetailsVesselQuery';
import {Divider, Tooltip} from 'antd';
import {BallastStatusQueried} from '../../../../../BallastStatus/BallastStatus';
import {useSelector} from '../../../../../../redux/react-redux';
import {assert} from '../../../../../../utils/assert';
import {RollbackOutlined} from '@ant-design/icons';
import {useBackToLastPort} from './useBackToLastPort';
import {ExtLinkIcon} from '../../../../../ExtLinkIcon/ExtLinkIcon';
import {useMapContext} from '../../../../MapContext/MapContext';
import {useGetVesselQuery} from '../../../../../../queries/useGetVesselQuery';
import {NotAvailable} from '../../../../../../utils/NotAvailable';

export const MapDetailVessel: FC<{onPortLocationClicked?: OnMapElementChange}> = ({onPortLocationClicked}) => {
  const {mapElement, lastPort} = useSelector(({mapDetails}) => mapDetails);
  // must be vessel based on layer switch above
  assert(isMapVesselElement(mapElement));
  const {
    state: {selectedItems},
  } = useMapContext();

  const object = mapElement.object;
  const {imo, type} = object;

  const backToLastPort = useBackToLastPort(onPortLocationClicked);
  const mapDetailVesselQuery = useMapDetailsVesselQuery(imo, {
    enabled: !!imo,
  });

  const vesselId = mapDetailVesselQuery.data?.vesselId;

  // Unfortunatelly the vesselId is not always available.
  // When we have the vessel in our AIS database but not in our php-vessel database, we don't have the vesselId.
  // In this case we we handle it gracefully by not showing the details but don't throw an error.
  // To register this problem we log it to the console and sentry.
  if (!vesselId && mapDetailVesselQuery.isSuccess) {
    // eslint-disable-next-line no-console
    console.warn(`VesselId not available for imo ${imo} and type ${type}`);
  }

  const detailVesselQuery = useGetVesselQuery({
    vesselId,
    options: {
      enabled: mapDetailVesselQuery.isSuccess && !!mapDetailVesselQuery.data.vesselId,
    },
  });
  const detailVessel = detailVesselQuery.data ?? undefined;

  if (mapDetailVesselQuery.isLoading) {
    return MapDetailsLoading;
  }
  if (mapDetailVesselQuery.isError) {
    return MapDetailsError;
  }
  assert(mapDetailVesselQuery.isSuccess);

  const mapDetailVessel = mapDetailVesselQuery.data;

  const {draught, name, country, latitude, longitude} = mapDetailVessel;
  const isEnhancedData = !!detailVessel?.dwtSummer;
  const mapDetailVesselDataColumn = getMapDetailVesselDataColumns(
    mapDetailVessel,
    detailVessel,
    onPortLocationClicked
  )!;

  const isFullView = !selectedItems.items.length;

  return (
    <>
      <MapDetailsHeader>
        {lastPort && (
          <Tooltip title={`Go back to ${lastPort.object.name ?? 'last Port'}`} placement="bottomLeft">
            <ActionButtonLeft onClick={backToLastPort}>
              <RollbackOutlined />
            </ActionButtonLeft>
          </Tooltip>
        )}
        <MapDetailLink to={vesselId ? `/vessel/${vesselId}` : ''} target="_blank">
          <MapDetailsTitle>
            <Tooltip
              title={isEnhancedData ? 'AIS data enhanced by seabo' : 'No seabo data available'}
              placement="bottomLeft"
              mouseEnterDelay={0.5}>
              <AISLabel $isEnhanced={isEnhancedData}>AIS{isEnhancedData ? '+' : ''}</AISLabel>
            </Tooltip>
            {name ?? NotAvailable}
            <StyledBallastStatus imo={imo} draft={draught} type={type} />
            {vesselId && <ExtLinkIcon />}
            <MapDetailsSubtitle>
              <CountryLocation location={{code: country, name: Country.getName(country)}} />
            </MapDetailsSubtitle>
          </MapDetailsTitle>
        </MapDetailLink>
        {isFullView && <MapDetailsWeather weatherCoordinates={{lat: latitude, lon: longitude}} />}
      </MapDetailsHeader>
      <MapDetailsContent>
        {!isFullView ? (
          <MapDetailReducedAttributes>
            <AttributeColumn column={mapDetailVesselDataColumn.leftColumn} />
            <StyledDivider />
            <AttributeColumn column={mapDetailVesselDataColumn.rightColumn} />
          </MapDetailReducedAttributes>
        ) : (
          <MapDetailVesselAttributes>
            <CenteredBox>
              <BoxHalf>
                <AttributeColumn column={mapDetailVesselDataColumn.leftColumn} tab={'xl'} />
              </BoxHalf>
              <BoxHalf>
                <LocationHeader>{`Current location`}</LocationHeader>
                <LocationSub>{mapDetailVesselDataColumn.currentLocation}</LocationSub>
                <AttributeColumn column={mapDetailVesselDataColumn.rightColumn} />
              </BoxHalf>
            </CenteredBox>
          </MapDetailVesselAttributes>
        )}
        {detailVessel && isFullView && <MapDetailVesselTabs detailVessel={detailVessel} />}
      </MapDetailsContent>
    </>
  );
};

const AttributeColumn: FC<{
  column: MapDetailVesselDataAttributes;
  tab?: TabSize;
}> = ({column, tab}) => (
  <>
    {Object.values<ColumnAttributeType>(column).map((attributes, idx) => {
      return (
        <AttributeSection key={idx}>
          {Object.keys(attributes).map(key => (
            <Attribute key={key} label={key} value={attributes[key]} tab={tab ?? 'm'} />
          ))}
        </AttributeSection>
      );
    })}
  </>
);

const AISLabel = styled.div<{$isEnhanced: boolean}>`
  --switch-height: 16px;

  position: relative;
  top: -1.5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: var(--switch-height);
  margin: 0 6px 0 -4px;
  padding: 0 4px;
  background: ${({$isEnhanced}) => ($isEnhanced ? 'var(--color-blue)' : 'var(--color-gray-4)')};
  color: ${({$isEnhanced}) => ($isEnhanced ? 'var(--color-white)' : 'var(--color-gray-2)')};
  font-size: var(--font-size-xs);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 0.8;
  border-radius: 10px;
  vertical-align: middle;
  user-select: none;
`;

const StyledBallastStatus = styled(BallastStatusQueried)`
  position: relative;
  top: -3px;
  margin: 0 0 0 4px;
`;

const LocationHeader = styled.h4`
  margin-top: 4px;
  margin-bottom: 0;
`;
const LocationSub = styled.div`
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: bold;
`;

const AttributeSection = styled.div`
  padding: 10px 0 0 0;
`;

const MapDetailVesselAttributes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  overflow: auto;
  flex: 1;
  border-bottom: 1px solid var(--border-color-light);
  z-index: 1;
`;

const MapDetailReducedAttributes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  padding: 10px 20px;
`;

const BoxHalf = styled.div`
  width: calc(50% - 5px);
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 980px;
  padding: 0 12px;

  ${BoxHalf}:first-of-type:not(:last-of-type) {
    border-right: var(--border-width) solid var(--border-color-light);
  }
`;

const StyledDivider = styled(Divider)`
  margin: 3px 0;
`;
