import {getEnvironment, isTest} from '../../utils/environment';
import {TourDefinition} from './TourDefinition';

export const createNodeWithClass = (className: string, node: string = 'div'): HTMLElement => {
  const newNode = document.createElement(node);
  newNode.classList.add(className);
  return newNode;
};

export const validateTour = (tourDefinition: TourDefinition): void => {
  // Hide the validation in test environment and production
  if (isTest() || getEnvironment() === 'Prod') {
    return;
  }
  for (const step of tourDefinition.steps) {
    const selector = step.target;
    if (!selector) {
      continue;
    }
    const element = document.querySelector(selector);
    if (!element) {
      // eslint-disable-next-line no-console
      console.warn(
        `Tour validation: Step with title ${step.title}: Target HTML element can't be found by CSS selector '${selector}'`
      );
    }
  }
};
