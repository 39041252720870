import Cargo from '../../../../model/Cargo';
import {getStationLocationsFromCargo} from '../../../../screens/CargoDetail/CargoDetailHeader/getStationLocationsFromCargo';
import {getIconLayer, getScatterplotLayer, getArcLayer} from '../../../../screens/CargoDetail/CargoDetailHeader/layers';
import {StationLocation} from '../../../../screens/CargoDetail/CargoDetailHeader/useGetCargoRouteLayer';
import {Coordinates} from '../../../../utils/Coordinates';
import {hexToRgb} from '../../../SeaboMap/helper';
import {ProjectCargo, ProjectMapLayer} from '../../types';

export const getCargoLayer = ({
  cargo,
  getCoordinatesFromLocation,
}: {
  cargo: ProjectCargo;
  getCoordinatesFromLocation: (location?: StationLocation | undefined) => Coordinates | undefined;
}): {
  layers: ProjectMapLayer[];
  cargoCoordinates: Coordinates[];
} => {
  const {fromLocations, toLocations} = getStationLocationsFromCargo(cargo);
  const cargoCoordinates = {
    from: getCoordinatesFromLocation(fromLocations[0]),
    to: getCoordinatesFromLocation(toLocations[0]),
    color: hexToRgb(Cargo.getColor(cargo.contractType))!,
  };

  const {from, to, color} = cargoCoordinates;
  const cargoLayers: ProjectMapLayer[] = [];
  if (from) {
    cargoLayers.push(getScatterplotLayer({index: 1, color, coordinates: from}));
  }
  if (to) {
    cargoLayers.push(getIconLayer({index: 1, color, coordinates: to, active: true, type: 'marker'}));
  }
  if (from && to) {
    cargoLayers.push(getArcLayer({index: 1, color, coordinates: [from, to]}));
  }

  return {
    layers: cargoLayers,
    cargoCoordinates: [cargoCoordinates.from, cargoCoordinates.to].filter(Boolean) as Coordinates[],
  };
};
