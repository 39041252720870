/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {AisRouteWaypoint, AisRouteWaypointFromJSON, AisRouteWaypointFromJSONTyped, AisRouteWaypointToJSON} from './';

/**
 *
 * @export
 * @interface AisRouteResponseData
 */
export interface AisRouteResponseData {
  /**
   * Waypoints of the route.
   * @type {Array<AisRouteWaypoint>}
   * @memberof AisRouteResponseData
   */
  route: Array<AisRouteWaypoint>;
  /**
   * IDs of visited ports.
   * @type {Array<number>}
   * @memberof AisRouteResponseData
   */
  ports: Array<number>;
}

export function AisRouteResponseDataFromJSON(json: any): AisRouteResponseData {
  return AisRouteResponseDataFromJSONTyped(json, false);
}

export function AisRouteResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisRouteResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    route: (json['route'] as Array<any>).map(AisRouteWaypointFromJSON),
    ports: json['ports'],
  };
}

export function AisRouteResponseDataToJSON(value?: AisRouteResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    route: (value.route as Array<any>).map(AisRouteWaypointToJSON),
    ports: value.ports,
  };
}
