import {FC, useRef} from 'react';
import {
  NegotiationRoundResult,
  NegotiationRoundResultModification,
  NegotiationSubjectModification,
  NegotiationSummary,
  TermModification,
} from '../../../../../api/node-backend/generated';
import styled from 'styled-components';
import {Collapse} from 'antd';
import {NegotiationTermAreaCard} from '../TermArea/NegotiationTermArea';
import {HistoryDetailBody} from '../History/Detail/HistoryDetailBody';

type NegotiationRoundDiffProps = {
  negotiation: NegotiationSummary;
  previousRoundResult?: NegotiationRoundResult;
  previousRoundResultModifications?: NegotiationRoundResultModification;
};

export const NegotiationRoundDiff: FC<NegotiationRoundDiffProps> = ({
  negotiation,
  previousRoundResult,
  previousRoundResultModifications,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  if (!previousRoundResult || !previousRoundResultModifications) {
    return null;
  }

  const termsWithModifications = previousRoundResultModifications.termModifications.filter(
    termModification => termModification.termModification !== TermModification.Unmodified
  );
  const subjectsWithModifications = previousRoundResultModifications.subjectModificationReports.filter(
    subjectModification => subjectModification.subjectModification !== NegotiationSubjectModification.Unmodified
  );
  const editCount = termsWithModifications.length + subjectsWithModifications.length;

  return (
    <NegotiationTermAreaCard ref={containerRef} data-testid="NegotiationRoundDiff">
      <RoundDiffContainer>
        <RoundDiffCollapse
          ghost
          expandIconPosition={'right'}
          items={[
            {
              key: '1',
              label: <RoundDiffHeader>Last round's changes ({editCount})</RoundDiffHeader>,
              children: (
                <HistoryDetailBody
                  negotiation={negotiation}
                  roundResult={previousRoundResult}
                  roundResultModifications={previousRoundResultModifications}
                />
              ),
            },
          ]}
        />
      </RoundDiffContainer>
    </NegotiationTermAreaCard>
  );
};

const RoundDiffContainer = styled.div`
  display: block;
  flex-direction: column;
  margin: -10px;
`;

const RoundDiffHeader = styled.div`
  font-size: var(--font-size-sm);
  font-weight: bold;
`;

const RoundDiffCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 3px 10px 1px !important;
    background: var(--color-gray-5);
    border-radius: var(--border-radius-lg) !important;
  }
  .ant-collapse-content {
    background: var(--color-gray-6);
    border: none;
  }
  .ant-collapse-content-box {
    padding: 5px 10px 10px !important;
  }
`;
