import {FC} from 'react';
import {HeaderLogo} from '../HeaderLogo';
import {HeaderWorkspaceSelect} from './HeaderWorkspaceSelect';
import {HeaderNotificationButton} from './HeaderNotificationButton/HeaderNotificationButton';
import {HeaderUserMenu} from './HeaderUserMenu';
import styled, {createGlobalStyle} from 'styled-components';
import {Spacer} from '../../Spacer/Spacer';
import {SearchBar} from '../../../components/SearchBar/SearchBar';
import {PromoBar} from './PromoBar';

export const FullPageHeader: FC<{
  promoBarVisible: boolean;
  onPromoBarDismissed: () => void;
}> = ({promoBarVisible, onPromoBarDismissed}) => {
  return (
    <FullPageHeaderStyled id="full-page-header">
      <DropdownInHeaderStyle />
      <PromoBar promoBarVisible={promoBarVisible} onPromoBarDismissed={onPromoBarDismissed} />
      <FullPageHeaderLayout>
        <HeaderLogo />
        <Spacer width={32} />
        <SearchArea>
          <SearchBar />
        </SearchArea>
        <ElasticSpacer />
        <HeaderWorkspaceSelect />
        <Spacer width={18} />
        <VerticalDivider />
        <Spacer width={13} />
        <HeaderNotificationButton />
        <Spacer width={15} />
        <HeaderUserMenu />
        <Spacer width={17} />
      </FullPageHeaderLayout>
    </FullPageHeaderStyled>
  );
};

const DropdownInHeaderStyle = createGlobalStyle`
  div.ant-dropdown.inHeader {
    position: fixed;
  }
`;

const FullPageHeaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;

  width: 100%;
  height: var(--top-offset);

  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.075);
  background-color: var(--color-white);
`;

const FullPageHeaderLayout = styled.div`
  width: calc(100vw - 15px);
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ElasticSpacer = styled.div`
  height: 100%;
  flex-grow: 1;
`;

const SearchArea = styled.div`
  flex-shrink: 1;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const VerticalDivider = styled.div`
  height: 32px;
  border-right: 1px solid var(--color-gray-5);
`;
