import {FC, useEffect, useState} from 'react';
import {Select} from 'antd';
import {StowageFactorDisplayUnit} from '../utils/CargoTypes';
import {assertUnreachable} from '../../../../utils/assert';
import {roundNumberToDecimals} from '../../../../utils/roundNumber';
import {StowageFactorPicker} from '../../../StowageFactorPicker/StowageFactorPicker';

type Props = {
  stowageFactor: number | undefined;
  stowageFactorDisplayUnit: StowageFactorDisplayUnit;
  /**
   * Always in cbm
   */
  onChangeStowageFactor: (value: number | undefined) => void;
  onChangeDisplayUnit: (unit: StowageFactorDisplayUnit) => void;
};

export const StowageFactorInput: FC<Props> = props => {
  const [internalValueInSelectedUnit, setInternalValueInSelectedUnit] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!props.stowageFactor) {
      setInternalValueInSelectedUnit(undefined);
      return;
    }
    switch (props.stowageFactorDisplayUnit) {
      case 'cbm':
        setInternalValueInSelectedUnit(props.stowageFactor);
        break;
      case 'cft':
        setInternalValueInSelectedUnit(fromCbmToCft(props.stowageFactor));
        break;
      default:
        assertUnreachable(props.stowageFactorDisplayUnit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stowageFactor]);

  useEffect(() => {
    if (!props.stowageFactor) {
      setInternalValueInSelectedUnit(undefined);
      return;
    }
    switch (props.stowageFactorDisplayUnit) {
      case 'cbm':
        setInternalValueInSelectedUnit(props.stowageFactor);
        break;
      case 'cft':
        setInternalValueInSelectedUnit(fromCbmToCft(props.stowageFactor));
        break;
      default:
        assertUnreachable(props.stowageFactorDisplayUnit);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stowageFactorDisplayUnit]);

  return (
    <div
      style={{
        display: 'flex',
      }}>
      <StowageFactorPicker
        style={{
          flex: 1,
        }}
        factor={internalValueInSelectedUnit}
        onChangeFactor={factor => {
          props.onChangeStowageFactor(factor);
        }}
        onSelectFactorInCbm={factor => {
          props.onChangeStowageFactor(factor);
          props.onChangeDisplayUnit('cbm');
        }}
      />
      <Select<StowageFactorDisplayUnit>
        data-cy={'stowageFactorDisplayUnit'}
        data-testid={'stowageFactorDisplayUnit'}
        value={props.stowageFactorDisplayUnit}
        onSelect={value => {
          props.onChangeDisplayUnit(value);
        }}
        style={{
          flex: 1,
        }}>
        <Select.Option data-testid={'stowageFactorDisplayUnitCbmOption'} value={'cbm'}>
          cbm / mts
        </Select.Option>
        <Select.Option data-testid={'stowageFactorDisplayUnitCftOption'} value={'cft'}>
          cft / mts
        </Select.Option>
      </Select>
    </div>
  );
};

const factorFromCbmToCft = 35.3147;
export const fromCbmToCft = (cbm: number) => roundNumberToDecimals(cbm * factorFromCbmToCft, 4);

export const fromCftToCbm = (cft: number) => roundNumberToDecimals(cft / factorFromCbmToCft, 4);
