export const typeFilter = <Vessel extends {type?: string | null}>({
  vessels,
  vesselType,
}: {
  vesselType?: string;
  vessels: Vessel[];
}): Vessel[] => {
  return vessels.filter(vessel => {
    if (vesselType) {
      return vessel.type === vesselType;
    }
    return true;
  });
};
