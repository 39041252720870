import {getGeohash, getRouteKey} from './utils';
import {Coordinates} from '../../../../../utils/Coordinates';
import {Step8Vessel} from '../../steps/step8';
import {Step7Vessel} from '../../steps/step7/vesselWithEstimatedDestination/vesselWithEstimatedDestination';
import {DistanceMap} from './generateDistanceMap';
import {sortWaypoints} from './sortWaypoints';
import {DistanceCalculation} from '../../../../../api/symfony/generated';

export const addSeaDistanceToResultVessels = ({
  distanceMap,
  portGeohash,
  vessels,
}: {
  distanceMap: DistanceMap;
  portGeohash: string;

  vessels: Step7Vessel[];
}): Step8Vessel[] => {
  return vessels.map(vessel => {
    const vesselGeohash = getGeohash(vessel.coordinates as Coordinates);

    const distanceRoutes: DistanceCalculation[] = [];
    let distance;
    if (vessel.destinationPort) {
      const destinationGeohash = getGeohash([vessel.destinationPort!.latitude!, vessel.destinationPort!.longitude!]);

      const distanceCalculation1 = distanceMap[getRouteKey(vesselGeohash, destinationGeohash)];
      distance = distanceCalculation1!.distance;
      distanceRoutes.push(distanceCalculation1);
      const distanceCalculation2 = distanceMap[getRouteKey(destinationGeohash, portGeohash)];
      distanceRoutes.push(distanceCalculation2);
      distance = distance + distanceCalculation2!.distance;
    } else {
      const distanceCalculation = distanceMap[getRouteKey(vesselGeohash, portGeohash)];
      distanceCalculation.waypoints = sortWaypoints({
        waypoints: distanceCalculation.waypoints as Coordinates[],
        start: vessel.coordinates as Coordinates,
      });
      distanceRoutes.push(distanceCalculation);
      distance = distanceCalculation!.distance;
    }

    return {...vessel, distanceSeaVoyageInNm: distance, estimatedArrivalDate: '', distanceRoutes};
  });
};
