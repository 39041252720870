/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ApiErrorDTO,
  ApiErrorDTOFromJSON,
  ApiErrorDTOToJSON,
  CreateNotificationRequest,
  CreateNotificationRequestFromJSON,
  CreateNotificationRequestToJSON,
  NotificationCollection,
  NotificationCollectionFromJSON,
  NotificationCollectionToJSON,
} from '../models';

export interface AcknowledgeNotificationRequest {
  id: number;
}

export interface CreateNotificationOperationRequest {
  createNotificationRequest: CreateNotificationRequest;
}

export interface GetUserNotificationsRequest {
  pageNumber?: number;
  pageSize?: number;
  acknowledged?: boolean;
}

/**
 *
 */
export class NotificationApi extends runtime.BaseAPI {
  /**
   * Acknowledge one or more notifications.
   */
  async acknowledgeAllNotificationsRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/notifications/acknowledge-all`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Acknowledge one or more notifications.
   */
  async acknowledgeAllNotifications(): Promise<void> {
    await this.acknowledgeAllNotificationsRaw();
  }

  /**
   * Acknowledge a given notification.
   */
  async acknowledgeNotificationRaw(
    requestParameters: AcknowledgeNotificationRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling acknowledgeNotification.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/notifications/{id}/acknowledge`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Acknowledge a given notification.
   */
  async acknowledgeNotification(requestParameters: AcknowledgeNotificationRequest): Promise<void> {
    await this.acknowledgeNotificationRaw(requestParameters);
  }

  /**
   * Creates a new notification (for one or more recipients).
   */
  async createNotificationRaw(
    requestParameters: CreateNotificationOperationRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.createNotificationRequest === null ||
      requestParameters.createNotificationRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'createNotificationRequest',
        'Required parameter requestParameters.createNotificationRequest was null or undefined when calling createNotification.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/notifications`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateNotificationRequestToJSON(requestParameters.createNotificationRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates a new notification (for one or more recipients).
   */
  async createNotification(requestParameters: CreateNotificationOperationRequest): Promise<void> {
    await this.createNotificationRaw(requestParameters);
  }

  /**
   * Fetches active notifications for the current user.
   */
  async getUserNotificationsRaw(
    requestParameters: GetUserNotificationsRequest
  ): Promise<runtime.ApiResponse<NotificationCollection>> {
    const queryParameters: any = {};

    if (requestParameters.pageNumber !== undefined) {
      queryParameters['pageNumber'] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.acknowledged !== undefined) {
      queryParameters['acknowledged'] = requestParameters.acknowledged;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/notifications`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NotificationCollectionFromJSON(jsonValue));
  }

  /**
   * Fetches active notifications for the current user.
   */
  async getUserNotifications(requestParameters: GetUserNotificationsRequest): Promise<NotificationCollection> {
    const response = await this.getUserNotificationsRaw(requestParameters);
    return await response.value();
  }
}
