/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CharterCalculation,
  CharterCalculationFromJSON,
  CharterCalculationToJSON,
  CharterCalculationCollection,
  CharterCalculationCollectionFromJSON,
  CharterCalculationCollectionToJSON,
  ValidationProblem,
  ValidationProblemFromJSON,
  ValidationProblemToJSON,
} from '../models';

export interface CreateCharterCalculationRequest {
  charterCalculation: CharterCalculation;
}

export interface EditCharterCalculationRequest {
  id: number;
  charterCalculation: CharterCalculation;
}

export interface GetCalculationsRequest {
  pageIndex?: number;
  pageSize?: number;
  projectId?: number;
  cargoId?: number;
  workspaceId?: number;
  tag?: string;
}

export interface GetCharterCalculationRequest {
  id: number;
}

export interface GetVisitedCalculationsRequest {
  pageIndex?: number;
  pageSize?: number;
}

export interface VisitCharterCalculationRequest {
  id: number;
}

/**
 *
 */
export class CharterCalculationApi extends runtime.BaseAPI {
  /**
   * Store a new calculation.
   */
  async createCharterCalculationRaw(
    requestParameters: CreateCharterCalculationRequest
  ): Promise<runtime.ApiResponse<CharterCalculation>> {
    if (requestParameters.charterCalculation === null || requestParameters.charterCalculation === undefined) {
      throw new runtime.RequiredError(
        'charterCalculation',
        'Required parameter requestParameters.charterCalculation was null or undefined when calling createCharterCalculation.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charter-calculations`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CharterCalculationToJSON(requestParameters.charterCalculation),
    });

    return new runtime.JSONApiResponse(response, jsonValue => CharterCalculationFromJSON(jsonValue));
  }

  /**
   * Store a new calculation.
   */
  async createCharterCalculation(requestParameters: CreateCharterCalculationRequest): Promise<CharterCalculation> {
    const response = await this.createCharterCalculationRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async editCharterCalculationRaw(
    requestParameters: EditCharterCalculationRequest
  ): Promise<runtime.ApiResponse<CharterCalculation>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling editCharterCalculation.'
      );
    }

    if (requestParameters.charterCalculation === null || requestParameters.charterCalculation === undefined) {
      throw new runtime.RequiredError(
        'charterCalculation',
        'Required parameter requestParameters.charterCalculation was null or undefined when calling editCharterCalculation.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charter-calculations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: CharterCalculationToJSON(requestParameters.charterCalculation),
    });

    return new runtime.JSONApiResponse(response, jsonValue => CharterCalculationFromJSON(jsonValue));
  }

  /**
   */
  async editCharterCalculation(requestParameters: EditCharterCalculationRequest): Promise<CharterCalculation> {
    const response = await this.editCharterCalculationRaw(requestParameters);
    return await response.value();
  }

  /**
   * List available charter calculations.
   */
  async getCalculationsRaw(
    requestParameters: GetCalculationsRequest
  ): Promise<runtime.ApiResponse<CharterCalculationCollection>> {
    const queryParameters: any = {};

    if (requestParameters.pageIndex !== undefined) {
      queryParameters['pageIndex'] = requestParameters.pageIndex;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    if (requestParameters.projectId !== undefined) {
      queryParameters['projectId'] = requestParameters.projectId;
    }

    if (requestParameters.cargoId !== undefined) {
      queryParameters['cargoId'] = requestParameters.cargoId;
    }

    if (requestParameters.workspaceId !== undefined) {
      queryParameters['workspaceId'] = requestParameters.workspaceId;
    }

    if (requestParameters.tag !== undefined) {
      queryParameters['tag'] = requestParameters.tag;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charter-calculations`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CharterCalculationCollectionFromJSON(jsonValue));
  }

  /**
   * List available charter calculations.
   */
  async getCalculations(requestParameters: GetCalculationsRequest): Promise<CharterCalculationCollection> {
    const response = await this.getCalculationsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches the charter calculation with the given ID.
   */
  async getCharterCalculationRaw(
    requestParameters: GetCharterCalculationRequest
  ): Promise<runtime.ApiResponse<CharterCalculation>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getCharterCalculation.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charter-calculations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CharterCalculationFromJSON(jsonValue));
  }

  /**
   * Fetches the charter calculation with the given ID.
   */
  async getCharterCalculation(requestParameters: GetCharterCalculationRequest): Promise<CharterCalculation> {
    const response = await this.getCharterCalculationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches calculations recently visited by the current user.
   */
  async getVisitedCalculationsRaw(
    requestParameters: GetVisitedCalculationsRequest
  ): Promise<runtime.ApiResponse<CharterCalculationCollection>> {
    const queryParameters: any = {};

    if (requestParameters.pageIndex !== undefined) {
      queryParameters['pageIndex'] = requestParameters.pageIndex;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charter-calculations/visited`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => CharterCalculationCollectionFromJSON(jsonValue));
  }

  /**
   * Fetches calculations recently visited by the current user.
   */
  async getVisitedCalculations(
    requestParameters: GetVisitedCalculationsRequest
  ): Promise<CharterCalculationCollection> {
    const response = await this.getVisitedCalculationsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Flag a given calculation as visited.
   */
  async visitCharterCalculationRaw(
    requestParameters: VisitCharterCalculationRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling visitCharterCalculation.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('Bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charter-calculations/{id}/visit`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Flag a given calculation as visited.
   */
  async visitCharterCalculation(requestParameters: VisitCharterCalculationRequest): Promise<void> {
    await this.visitCharterCalculationRaw(requestParameters);
  }
}
