/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  CongestionLightDay,
  CongestionLightDayFromJSON,
  CongestionLightDayFromJSONTyped,
  CongestionLightDayToJSON,
} from './';

/**
 *
 * @export
 * @interface HistoricCongestionLightReport
 */
export interface HistoricCongestionLightReport {
  /**
   *
   * @type {Array<CongestionLightDay>}
   * @memberof HistoricCongestionLightReport
   */
  days: Array<CongestionLightDay>;
}

export function HistoricCongestionLightReportFromJSON(json: any): HistoricCongestionLightReport {
  return HistoricCongestionLightReportFromJSONTyped(json, false);
}

export function HistoricCongestionLightReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HistoricCongestionLightReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    days: (json['days'] as Array<any>).map(CongestionLightDayFromJSON),
  };
}

export function HistoricCongestionLightReportToJSON(value?: HistoricCongestionLightReport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    days: (value.days as Array<any>).map(CongestionLightDayToJSON),
  };
}
