import {hasError} from '../../../../../utils/helper';
import Input from '../../../../../atoms/Input';
import {acceptIntOrEmpty} from '../../../../../utils/formatter';
import LabelWrapper from '../../../../../atoms/LabelWrapper';
import Checkbox from '../../../../../atoms/Checkbox';
import HorizontalLine from '../../../../../atoms/HorizontalLine';
import {filterBranchDefinition} from './SpotifindPositionDefinition';
import {FilterButton, FilterButtonFilterProviderApi} from '../../../../../atoms/Filter/FilterButton';

interface Props {
  filterProviderApi: FilterButtonFilterProviderApi<typeof filterBranchDefinition>;
}

export const SpotifindPosition = ({filterProviderApi}: Props) => (
  <FilterButton filterProviderApi={filterProviderApi} filterBranchDefinition={filterBranchDefinition}>
    {({handleChange, values, validations}) => (
      <div>
        <div className="row">
          <div className="scol-10">
            <LabelWrapper label={'Position unchanged since'} htmlFor={'filter-spotifind-positionunchanged'}>
              <Input
                id={'filter-spotifind-positionunchanged'}
                type={'number'}
                value={values.positionUnchangedDays}
                onChange={v => acceptIntOrEmpty(v, v => handleChange('positionUnchangedDays', v))}
                hasError={hasError('positionUnchangedDays', validations)}
              />
            </LabelWrapper>
          </div>
          <div className="scol-2" style={{paddingTop: '35px'}}>
            days
          </div>
        </div>
        <HorizontalLine large topSpace={'medium'} bottomSpace="small" />
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={'filter-spotifind-excludeinport'}
              label={'Exclude vessels in port'}
              checked={values.excludeInPort}
              onChange={e => handleChange('excludeInPort', e.target.checked)}
              spMT={'s'}
            />
          </div>
        </div>
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={'filter-spotifind-excludemoored'}
              label={'Exclude "moored" vessels'}
              checked={values.excludeMoored}
              onChange={e => handleChange('excludeMoored', e.target.checked)}
              spMT={'s'}
            />
          </div>
        </div>
        <div className="row">
          <div className="scol-12">
            <Checkbox
              id={'filter-spotifind-excludeanchored'}
              label={'Exclude "anchored" vessels'}
              checked={values.excludeAnchored}
              onChange={e => handleChange('excludeAnchored', e.target.checked)}
              spMT={'s'}
            />
          </div>
        </div>
      </div>
    )}
  </FilterButton>
);
