import {fetchJSON} from '../../../../api/utils/fetchJSON';
import {MapDetailVesselType} from './types';
import {isApiError} from '../../../../api/utils/ApiError';

export const getMapVesselDetailByImo = async (imo: number): Promise<MapDetailVesselType | null> => {
  try {
    const detailsData = await fetchJSON<MapDetailVesselType>(`/api/map/vessels/${imo}`);
    return detailsData;
  } catch (error) {
    if (isApiError(error)) {
      if (error.status === 404) {
        // Missing data in the vessel DB (e.g. if it is not synchronized fast enough)
        // Missing or outdated AIS data (also not so unlikely)
        // See https://seanexxt.atlassian.net/browse/FRIN-5451?focusedCommentId=32140
        return null;
      }
    }

    throw error;
  }
};
