/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {NegotiationTermHistory, NegotiationTermHistoryFromJSON, NegotiationTermHistoryToJSON} from '../models';

export interface GetTermHistoryRequest {
  termId: string;
}

/**
 *
 */
export class NegotiationNegotiationTermHistoryApi extends runtime.BaseAPI {
  /**
   * Returns the chain of a term until the latest visible round.
   */
  async getTermHistoryRaw(
    requestParameters: GetTermHistoryRequest
  ): Promise<runtime.ApiResponse<NegotiationTermHistory>> {
    if (requestParameters.termId === null || requestParameters.termId === undefined) {
      throw new runtime.RequiredError(
        'termId',
        'Required parameter requestParameters.termId was null or undefined when calling getTermHistory.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/negotiations/terms/{termId}/history`.replace(
        `{${'termId'}}`,
        encodeURIComponent(String(requestParameters.termId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue => NegotiationTermHistoryFromJSON(jsonValue));
  }

  /**
   * Returns the chain of a term until the latest visible round.
   */
  async getTermHistory(requestParameters: GetTermHistoryRequest): Promise<NegotiationTermHistory> {
    const response = await this.getTermHistoryRaw(requestParameters);
    return await response.value();
  }
}
