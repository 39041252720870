import {UseMutationOptions, useMutation} from '@tanstack/react-query';

import {UpdateCompanyMembersRequest, UpdateCompanyMembersResponse} from '../../../api/symfony/generated';
import {companyApi} from '../../../api/symfony/symfonyApi';
import {ApiError} from '../../../api/utils/ApiError';

export const useUpdateCompanyMembersMutation = (
  options?: UseMutationOptions<UpdateCompanyMembersResponse, ApiError, UpdateCompanyMembersRequest>
) =>
  useMutation({
    mutationFn: request => companyApi.updateCompanyMembers(request),
    ...options,
  });
