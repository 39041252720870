import React, {FC} from 'react';
import Icon from '../../../../../atoms/Icon';
import {VoyagePoint} from '../VoyageTypes';
import {Button} from 'antd';
import {Tooltip} from 'antd';

export const AddItem: FC<{
  onAddPoint: () => void;
  label?: string;
  disabled?: boolean;
  hint?: string;
}> = ({onAddPoint, label, hint, disabled}) => {
  return (
    <div className={'cargo-vessel-form__icon-button'}>
      <Tooltip title={hint}>
        <Button size={'small'} type={'link'} data-cy="addItemButton" icon disabled={disabled} onClick={onAddPoint}>
          <Icon type={'item-add-selection-solid'} color="blue" style={{marginRight: 6}} />
          {label ?? 'ADD LEG'}
        </Button>
      </Tooltip>
    </div>
  );
};

export const defaultVoyagePoint: VoyagePoint = {
  coordinates: [0, 0],
  isInSECA: true,
  dis: 0,
  isPort: false,
  types: [],
  load: 0,
  otherDuration: [],
  portCosts: 0,
};

export const voyagePointWithNextOpen: VoyagePoint = {
  coordinates: [0, 0],
  dis: 0,
  isInSECA: true,
  isPort: false,
  types: ['nextOpen'],
  load: 0,
  otherDuration: [],
  portCosts: 0,
};
