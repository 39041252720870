import {FC} from 'react';
import styled from 'styled-components';
import {NegotiationSummary, NegotiationParty, NegotiationActor} from '../../../api/node-backend/generated';

export const NegotiationPartyLabel: FC<{negotiation: NegotiationSummary; party: NegotiationParty}> = ({
  negotiation,
  party,
}) => {
  const actor = negotiation[party];
  const isSelf = negotiation.party === party;
  return <NegotiationActorLabel actor={actor} isSelf={isSelf} />;
};

export const NegotiationActorLabel: FC<{actor: NegotiationActor; isSelf?: boolean}> = ({actor, isSelf}) => {
  return isSelf ? <LegendYou>you</LegendYou> : <LegendPartner>{actor.name}</LegendPartner>;
};

export const LegendYou = styled.span`
  color: var(--color-self);
`;

export const LegendPartner = styled.span`
  color: var(--color-partner);
`;
