import {MapControlButton} from './MapControlButton';
import {useMapContext} from '../MapContext/MapContext';

export const MapControlPortButton = () => {
  const context = useMapContext();
  const isActive = context?.state.switches.m_port.state;
  return (
    <MapControlButton
      onClick={() => {
        context.updateSwitches(draft => {
          draft.m_port.state = !isActive;
        });
      }}
      open={false}
      iconActive={'port'}
      active={isActive}
      iconInactive={'port-disabled'}
    />
  );
};
