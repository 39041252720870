/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CreateConsentContractDTO
 */
export interface CreateConsentContractDTO {
  /**
   *
   * @type {string}
   * @memberof CreateConsentContractDTO
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreateConsentContractDTO
   */
  internalNote: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateConsentContractDTO
   */
  isTitlePlural: boolean;
}

export function CreateConsentContractDTOFromJSON(json: any): CreateConsentContractDTO {
  return CreateConsentContractDTOFromJSONTyped(json, false);
}

export function CreateConsentContractDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateConsentContractDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json['title'],
    internalNote: json['internalNote'],
    isTitlePlural: json['isTitlePlural'],
  };
}

export function CreateConsentContractDTOToJSON(value?: CreateConsentContractDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    internalNote: value.internalNote,
    isTitlePlural: value.isTitlePlural,
  };
}
