/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TermModification {
  NewTerm = 'newTerm',
  UpdateTermText = 'updateTermText',
  AcceptTerm = 'acceptTerm',
  RejectTerm = 'rejectTerm',
  Unmodified = 'unmodified',
  RequestRejection = 'requestRejection',
  RejectRejectionRequest = 'rejectRejectionRequest',
}

export function TermModificationFromJSON(json: any): TermModification {
  return TermModificationFromJSONTyped(json, false);
}

export function TermModificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermModification {
  return json as TermModification;
}

export function TermModificationToJSON(value?: TermModification | null): any {
  return value as any;
}
