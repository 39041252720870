import {FC, ReactNode} from 'react';
import Icon from '../Icon';
import styled, {css} from 'styled-components';

interface Props {
  check?: boolean;
  label?: string;
  small?: boolean;
}

export const AttributeIcon: FC<Props> = ({check = false, label, small}) => {
  return (
    <AttributeIconStyled $small={small}>
      <Value>
        <Icon type={check ? 'check-mark-line' : 'minus'} color={check ? 'green' : 'red'} size={'small'} />
      </Value>
      <Label $check={check}>{label}</Label>
    </AttributeIconStyled>
  );
};

const AttributeIconStyled = styled.div<{$small?: boolean; children: ReactNode}>`
  display: flex;
  align-items: center;
  line-height: 16px;
  color: var(--color-gray-3);
  ${({$small}) => ($small ? 'height: 20px !important;' : '')}
`;

const Value = styled.div`
  font-size: var(--font-size-md);
  padding-right: 10px;
`;

const Label = styled.div<{$check: boolean; children: ReactNode}>`
  text-transform: uppercase;
  font-size: var(--font-size-sm);

  ${props =>
    props.$check &&
    css`
      color: var(--color-gray-1);
    `}
`;

export default AttributeIcon;
