import {makeApiService, ApiServiceEntry} from './ApiService';
import {TODO} from '../../utils/TODO';

type PortId = TODO;
type Id = TODO;
type Params = TODO;
type Response = {
  data: {
    items: TODO;
  };
};

const entries: ApiServiceEntry[] = [
  {fn: 'getPort', route: '/api/ports/{portId}'},
  {fn: 'getPortList', route: '/api/ports/list', storeData: false},
  {fn: 'getAisDestinations', route: '/api/ports/{portId}/ais/destinations'},
  {fn: 'patchAisDestinations', route: '/api/ports/{portId}/ais/destinations/{destinationId}', method: 'PATCH'},
  {fn: 'patchBulkAisDestinations', route: '/api/ports/{portId}/ais/destinations', method: 'PATCH'},
  {fn: 'deleteAisDestinations', route: '/api/ports/{portId}/ais/destinations/{destinationId}', method: 'DELETE'},
  {fn: 'getCountryList', route: '/api/countries'},
  {fn: 'getTradingAreaList', route: '/api/trading-areas'},
  {fn: 'getUnknownLocationList', route: '/api/unknown-locations', storeData: false},
  {fn: 'createPort', route: '/api/ports', method: 'POST'},
  {fn: 'updatePort', route: '/api/ports/{portId}', method: 'PATCH'},
  {fn: 'deletePort', route: '/api/ports/{portId}', method: 'DELETE'},
  {fn: 'updatePortDetails', route: '/api/ports/details/{portDetailsId}', method: 'PATCH'},
  {fn: 'getPortMooredVessels', route: '/api/ports/{portId}/vessels/moored'},
  {fn: 'getPortExpectedVessels', route: '/api/ports/{portId}/vessels/expected'},
  {fn: 'getVesselOffersByLocation', route: '/api/ports/statistics/{id}/vessel-offers'},
  {fn: 'getCargoOffersByLocation', route: '/api/ports/statistics/{id}/cargo-offers'},
  {fn: 'getAisCongestionByLocation', route: '/api/ports/statistics/{id}/ais-congestion'},
];

const portsApi = makeApiService(entries, {apiName: 'ports'});

export const reducers = portsApi.reducers;
export const actions = portsApi.actions;
export const actionTypes = portsApi.actionTypes;

const transformers = {SUCCESS: (r: Response) => r.data.items, ERROR: () => []};

export const getPort = (portId: PortId) => actions.getPort({params: {portId}});

export const getPortMooredVessels = (portId: PortId) =>
  actions.getPortMooredVessels({
    params: {portId},
    queryParams: {pageSize: 9999},
    transformers,
  });

export const getPortExpectedVessels = (portId: PortId) =>
  actions.getPortExpectedVessels({
    params: {portId},
    queryParams: {pageSize: 9999},
    transformers,
  });

export const getVesselOffersByLocation = (id: Id, params?: Params) => {
  const {range = null} = params || {};
  return actions.getVesselOffersByLocation({
    params: {id},
    queryParams: range ? range : {},
    transformers,
  });
};

export const getCargoOffersByLocation = (id: Id, params?: Params) => {
  const {range = null} = params || {};
  return actions.getCargoOffersByLocation({
    params: {id},
    queryParams: range ? range : {},
    transformers,
  });
};

export const getAisCongestionByLocation = (id: Id) =>
  actions.getAisCongestionByLocation({
    params: {id},
    transformers,
  });
