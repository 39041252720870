/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';

export interface GetAreaVisitsReportRequest {
  imo: number;
}

export interface GetDailyActivityReportRequest {
  imo: number;
}

export interface GetLegBasedReportRequest {
  imo: number;
}

export interface GetOverviewReportRequest {
  imo: number;
}

export interface GetVesselChangesReportRequest {
  imo: number;
}

/**
 *
 */
export class VesselVoyageTimelineExcelReportsApi extends runtime.BaseAPI {
  /**
   */
  async getAreaVisitsReportRaw(requestParameters: GetAreaVisitsReportRequest): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getAreaVisitsReport.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel/{imo}/voyage-timelinevoyage-timeline/excel-reports/area-visits-report`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async getAreaVisitsReport(requestParameters: GetAreaVisitsReportRequest): Promise<Blob> {
    const response = await this.getAreaVisitsReportRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getDailyActivityReportRaw(
    requestParameters: GetDailyActivityReportRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getDailyActivityReport.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel/{imo}/voyage-timelinevoyage-timeline/excel-reports/daily-activity-report`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async getDailyActivityReport(requestParameters: GetDailyActivityReportRequest): Promise<Blob> {
    const response = await this.getDailyActivityReportRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getLegBasedReportRaw(requestParameters: GetLegBasedReportRequest): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getLegBasedReport.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel/{imo}/voyage-timelinevoyage-timeline/excel-reports/leg-based-voyage-timeline-report`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async getLegBasedReport(requestParameters: GetLegBasedReportRequest): Promise<Blob> {
    const response = await this.getLegBasedReportRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getOverviewReportRaw(requestParameters: GetOverviewReportRequest): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getOverviewReport.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel/{imo}/voyage-timelinevoyage-timeline/excel-reports/overview-report`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async getOverviewReport(requestParameters: GetOverviewReportRequest): Promise<Blob> {
    const response = await this.getOverviewReportRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async getVesselChangesReportRaw(
    requestParameters: GetVesselChangesReportRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.imo === null || requestParameters.imo === undefined) {
      throw new runtime.RequiredError(
        'imo',
        'Required parameter requestParameters.imo was null or undefined when calling getVesselChangesReport.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = typeof token === 'function' ? token('bearer', []) : token;

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/node-backend/vessel/{imo}/voyage-timelinevoyage-timeline/excel-reports/vessel-changes-report`.replace(
        `{${'imo'}}`,
        encodeURIComponent(String(requestParameters.imo))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async getVesselChangesReport(requestParameters: GetVesselChangesReportRequest): Promise<Blob> {
    const response = await this.getVesselChangesReportRaw(requestParameters);
    return await response.value();
  }
}
