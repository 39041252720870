/* tslint:disable */
/* eslint-disable */
/**
 * Node Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {SeaboVessel, SeaboVesselFromJSON, SeaboVesselFromJSONTyped, SeaboVesselToJSON} from './';

/**
 *
 * @export
 * @interface ListVesselsResponse
 */
export interface ListVesselsResponse {
  /**
   *
   * @type {Array<SeaboVessel>}
   * @memberof ListVesselsResponse
   */
  items: Array<SeaboVessel>;
  /**
   *
   * @type {number}
   * @memberof ListVesselsResponse
   */
  itemCount: number;
  /**
   *
   * @type {number}
   * @memberof ListVesselsResponse
   */
  totalItems: number;
}

export function ListVesselsResponseFromJSON(json: any): ListVesselsResponse {
  return ListVesselsResponseFromJSONTyped(json, false);
}

export function ListVesselsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListVesselsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(SeaboVesselFromJSON),
    itemCount: json['itemCount'],
    totalItems: json['totalItems'],
  };
}

export function ListVesselsResponseToJSON(value?: ListVesselsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(SeaboVesselToJSON),
    itemCount: value.itemCount,
    totalItems: value.totalItems,
  };
}
