import {FilterDatabaseType, FilterInternalType, makeFilterBranchDefinitions} from '../../FilterBranchDefinition';

import * as SpotifindVesselAttributes from './SpotifindVesselAttributes/SpotifindVesselAttributesDefinition';
import * as VesselType from '../VesselType/VesselTypeDefinition';
import * as SpotifindPosition from './SpotifindPosition/SpotifindPositionDefinition';
import * as SpotifindVesselStatus from './SpotifindVesselStatus/SpotifindVesselStatusDefinition';
import * as SpotifindParameters from './SpotifindParameters/SpotifindParametersDefinition';

export const SpotifindFilterBranchDefinitions = makeFilterBranchDefinitions([
  SpotifindVesselAttributes.filterBranchDefinition,
  VesselType.filterBranchDefinition,
  SpotifindPosition.filterBranchDefinition,
  SpotifindVesselStatus.filterBranchDefinition,
  SpotifindParameters.filterBranchDefinition,
] as const);

export type SpotifindInternalFilter = Partial<FilterInternalType<typeof SpotifindFilterBranchDefinitions>>;
export type SpotifindDatabaseFilter = Partial<FilterDatabaseType<typeof SpotifindFilterBranchDefinitions>>;
