/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EventType {
  Generic = 'generic',
  FriendRequest = 'friend_request',
}

export function EventTypeFromJSON(json: any): EventType {
  return EventTypeFromJSONTyped(json, false);
}

export function EventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventType {
  return json as EventType;
}

export function EventTypeToJSON(value?: EventType | null): any {
  return value as any;
}
