import {PortfolioCargo} from '../../../api/symfony/schemas/GetCargoDetailsResponseSchema/GetCargoDetailsResponseSchema';
import {ClipboardItem} from '../../../redux/GridClipboard';
import {PortfolioVessel} from '../../../redux/Portfolio';

export const getIfAllSelected = <PortfolioItem extends PortfolioVessel | PortfolioCargo>(
  clipboardItems: ClipboardItem[],
  itemsOnScreen: PortfolioItem[]
) => {
  if (itemsOnScreen.length === 0) {
    return false;
  }
  const itemsOnScreenIds = itemsOnScreen.map((itemOnScreen: PortfolioItem) => itemOnScreen.id);
  const isSelected = (clipboardItem: ClipboardItem) => itemsOnScreenIds.includes(clipboardItem.id);
  const selectedClipboardItems = clipboardItems.filter((vessel: ClipboardItem) => isSelected(vessel));
  return selectedClipboardItems.length === 0 ? false : selectedClipboardItems.length === itemsOnScreen.length;
};
