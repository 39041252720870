import {CSSProperties, KeyboardEventHandler, forwardRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {MarginMappingProps, withMarginMapping} from '../../components/withMargin';
import './style.scss';
import {TODO} from '../../utils/TODO';

/** @deprecated use AntD Input instead */
export interface InputProps extends MarginMappingProps {
  id?: string;
  type?: string;
  noBorder?: boolean;
  value?: string;
  className?: string;
  hasError?: boolean;
  autoComplete?: string;
  placeholder?: string;
  readOnly?: boolean;
  maxLength?: number | string;
  disabled?: string | boolean;
  autoFocus?: boolean;
  style?: CSSProperties;
  onChange?: (event: string) => void;
  onKeyUp?: KeyboardEventHandler;
  onKeyDown?: KeyboardEventHandler;
  min?: number | string | undefined;
  max?: number | string | undefined;
  name?: string;
  onBlur?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
}

/** @deprecated use AntD Input instead */
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      onChange = () => {},
      type = 'text',
      noBorder,
      className,
      hasError,
      autoComplete,
      placeholder,
      style,
      ...rest
    }: InputProps,
    ref
  ) => {
    const [spaceClasses, props] = withMarginMapping(rest);

    return (
      <input
        id={id}
        className={classNames(
          'input',
          `input--${type}`,
          {
            'input--no-border': noBorder,
            'input--has-error': hasError,
          },
          spaceClasses,
          className
        )}
        {...props}
        onChange={e => onChange(e.target.value)}
        type={type}
        readOnly={rest.readOnly}
        style={style}
        min={rest.min}
        placeholder={placeholder}
        autoComplete={autoComplete}
        ref={ref as TODO}
      />
    );
  }
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default Input;
