import {VoyagePoint} from '../../../../VoyageInformation/VoyageTypes';
import {useContext} from 'react';
import {VoyageCharterContext} from '../../../VoyageCharterContext';

export const useGetDischargeVoyagePointsAndIndexes = (): {point: VoyagePoint; index: number}[] => {
  const {state} = useContext(VoyageCharterContext);

  const list: {point: VoyagePoint; index: number}[] = [];
  state.inputState.voyage.points.forEach((point, index) => {
    if (point.types?.includes('discharge')) {
      list.push({
        point,
        index,
      });
    }
  });
  return list;
};
