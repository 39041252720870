import {SearchPickerPort} from '../../components/SearchPicker/SearchPickerPort';

/**
 * Combine an array of ports in an array of routes between them.
 * @param ports
 */
export const preparePortsForRequest = (ports: SearchPickerPort[]): RoutesFromTo[] => {
  if (ports.length < 2) {
    throw new Error('You need at least two stations to calculate a route');
  }
  const routes: RoutesFromTo[] = [];
  // Skip the first port, because we don't need to calculate a route to the first port.
  for (let i = 1; i < ports.length; i++) {
    const previousPort = ports[i - 1];
    const currentPort = ports[i];

    routes.push({
      from: {
        lon: previousPort.lon,
        lat: previousPort.lat,
      },
      to: {
        lon: currentPort.lon,
        lat: currentPort.lat,
      },
    });
  }

  return routes;
};

type RoutesFromTo = {
  from: {
    lon: number;
    lat: number;
  };
  to: {
    lon: number;
    lat: number;
  };
};
