import {QueryClient, useQuery} from '@tanstack/react-query';
import {GetMarketCargoesRequest} from '../../redux/ApiService/MarketService';
import {ApiError} from '../../api/utils/ApiError';
import {TODO} from '../../utils/TODO';
import MarketService from '../../redux/ApiService/MarketService';
import {useDispatch} from '../../redux/react-redux';
import {UseQueryOptions} from '../../api/utils/ReactQuery';

const MarketCargoesQueryKey = 'market-cargoes';

export const useMarketCargoesQuery = (request: GetMarketCargoesRequest, options?: UseQueryOptions<TODO, ApiError>) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: [MarketCargoesQueryKey, request],
    queryFn: () => dispatch(MarketService.getCargoes(request)),
    ...options,
  });
};

export const invalidateMarketCargoesQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: [MarketCargoesQueryKey],
  });
};
