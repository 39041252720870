import React, {useState} from 'react';
import numeral from 'numeral';
import {EditOutlined} from '@ant-design/icons/lib';
import styled from 'styled-components';
import {CellEditor} from './CellEditor';

type Props = {
  tce: number;
  onChange: (tce: number) => void;
};

export const TceCell = (props: Props) => {
  const [value, setValue] = useState(Math.round(props.tce));
  const [showEditInput, setShowEditInput] = useState(false);

  if (showEditInput) {
    return (
      <CellEditor
        value={Math.round(value)}
        onChange={value => setValue(Math.round(value))}
        onBlur={() => {
          props.onChange(value);
          setShowEditInput(false);
        }}
      />
    );
  }
  return (
    <FreightIdeaSpan
      data-testid="TCECellSpan"
      onClick={() => {
        setShowEditInput(true);
      }}>
      {numeral(Math.round(props.tce)).format('$0,0')} <EditOutlined size={13} />
    </FreightIdeaSpan>
  );
};

const FreightIdeaSpan = styled.span`
  cursor: pointer;
  width: 100%;
`;
