import {Result} from 'antd';
import {CenteredContentArea, FullscreenDialog} from '../../../atoms/FullscreenDialog/FullscreenDialog';
import {Center} from '../../../components/Center/Center';
import {BottomArea, BottomLink, Dialog} from '../utils/shared-components';
import {Link} from 'react-router-dom';

export const LostPasswordEmailSentScreen = () => (
  <div data-testid="LostPasswordEmailSentScreen">
    <FullscreenDialog>
      <CenteredContentArea>
        <Center>
          <Dialog>
            <Result
              status="success"
              title="Check your email"
              subTitle={
                <>
                  <br />
                  The system has sent a password reset link to your email account
                </>
              }
            />
            <BottomArea>
              <Link to="/login">
                <BottomLink block>Back to Login</BottomLink>
              </Link>
            </BottomArea>
          </Dialog>
        </Center>
      </CenteredContentArea>
    </FullscreenDialog>
  </div>
);
