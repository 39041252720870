import {OneDayStatistics} from '../../../api/node-backend/generated';

export type ClassInformation = {
  label: string;
  size: number;
  count: number;
  /**
   * It is part of the extraction of the value from the OneDayStatistics
   */
  accessor: (day: OneDayStatistics) => number;
  highestDistributionQouteForVisualization?: number;
};

/*
The Informations  based on 
 */
export type BulkerClass =
  | 'allBulkers'
  | 'handy'
  | 'handymax'
  | 'kamsarmax'
  | 'miniBulker'
  | 'panamax'
  | 'postPanamax'
  | 'supramax'
  | 'ultramax'
  | 'capesize';

export type ContainerClass = 'allContainers';

export const bulkerMap: Record<BulkerClass, ClassInformation> = {
  miniBulker: {
    label: 'Mini Bulker',
    size: 46485836,
    count: 6828,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.miniBulker.dwt,
  },
  handy: {
    label: 'Handysize',
    size: 91381897,
    count: 2877,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.handy.dwt,
  },
  handymax: {
    label: 'Handymax',
    size: 20699682,
    count: 447,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.handymax.dwt,
  },
  supramax: {
    label: 'Supramax',
    size: 108292631,
    count: 1947,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.supramax.dwt,
  },
  ultramax: {
    label: 'Ultramax',
    size: 70723438,
    count: 1126,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.ultramax.dwt,
  },
  panamax: {
    label: 'Panamax',
    size: 81638878,
    count: 1081,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.panamax.dwt,
  },
  kamsarmax: {
    label: 'Kamsarmax',
    size: 93708246,
    count: 1146,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.kamsarmax.dwt,
  },
  postPanamax: {
    label: 'Post Panamax',
    size: 52968220,
    count: 572,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.postPanamax.dwt,
  },
  capesize: {
    label: 'Capesize',
    size: 353826364,
    count: 1778,
    accessor: day => day.cumulatedTiedUpBulkersPerClass.capesize.dwt,
  },
  allBulkers: {
    label: 'All Bulkers',
    size: 919725192,
    count: 17802,
    accessor: day => day.cumulatedTiedUpBulkerVessels.dwt,
  },
};

export const containerMap: Record<ContainerClass, ClassInformation> = {
  allContainers: {
    label: 'All Containers',
    size: 24000000,
    count: 0,
    accessor: day => day.cumulatedTiedUpContainerVessels.teu,
    highestDistributionQouteForVisualization: 0.04,
  },
};

export const vesselsMap = {...bulkerMap, ...containerMap};
