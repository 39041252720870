import {IntakeType} from '../../../../redux/Portfolio';

export type IntakeUnit = 'cbm' | 'cft';

export const intakeTypes: Record<IntakeType, string> = {
  teu: 'TEU',
  teu14: 'TEU14',
  feu: 'FEU',
  '45f': '45f',
};
export const intakeTypesArray: Array<IntakeType> = Object.keys(intakeTypes) as IntakeType[];

export type IntakeTypeOptions = {
  value: IntakeType;
  name: string;
}[];

export type IntakeValidation = Record<
  keyof Intake,
  | undefined
  | {
      invalid: boolean;
      error?: string;
    }
>;

export const intakeUnits: Record<IntakeUnit, string> = {
  cbm: 'CBM',
  cft: 'CFT',
};

export const intakeUnitsArray: Array<IntakeUnit> = Object.keys(intakeUnits) as IntakeUnit[];

export type Intake = {
  type: IntakeType;
  quantity: string;
  isHighCube: boolean;
};

export const newIntake: Intake = {
  type: 'teu',
  quantity: '',
  isHighCube: false,
};

export const intakeUnitOptions = intakeUnitsArray.map(value => ({value, name: intakeUnits[value]}));
