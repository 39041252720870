import {Col, Row} from 'antd';
import Icon from '../../../atoms/Icon';
import styled from 'styled-components';

const seaboUvps = [
  {
    icon: 'location',
    title: 'Live Vessel Positions',
    content: 'Access real-time global vessel positions for accurate analysis.',
  },

  {
    icon: 'stock',
    title: 'Advanced Calculations',
    content: 'Efficient calculations for better decision-making.',
  },

  {
    icon: 'tips',
    title: 'Market Intelligence',
    content: 'Leverage AI-supported insights to stay ahead of the competition.',
  },

  {
    icon: 'time-outline',
    title: 'Accelerate Decisions',
    content: 'Reduce time to action with all critical data at your fingertips.',
  },
] as const;

export const SeaboUvps = () => {
  return (
    <Container gutter={40} justify="center" align="bottom" style={{margin: '60px 0 40px'}}>
      {seaboUvps.map(({icon, title, content}) => (
        <Col span={6} key={title}>
          <IconWrapper>
            <Icon type={icon} color="blue" size="medium" />
          </IconWrapper>
          <Title>{title}</Title>
          <Content>{content}</Content>
        </Col>
      ))}
    </Container>
  );
};

const Container = styled(Row)`
  margin: 50px 0 40px;
`;

const IconWrapper = styled.div`
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
`;

const Content = styled.div`
  height: 40px;
  font-size: 14px;
  color: var(--color-gray-2);
`;
