import {TODO} from '../../../../utils/TODO';
import {Feature} from '@turf/helpers';
import {getPortLayerLabel} from '../../helper';
import {PORT_LAYERS} from '../../const';
import {GeoJSON} from 'echarts/types/src/coord/geo/geoTypes';

type Port = TODO;

export const portToGeoJson = (port: Port): GeoJSON => {
  return {
    type: 'FeatureCollection',
    features: [...getPolygons(port), ...getPois(port), ...getPosition(port)] as TODO[],
  };
};

const getPosition = (port: Port): Feature<unknown, unknown>[] => {
  return port.coordinates?.length > 0
    ? [
        {
          type: 'Feature',
          properties: {type: 'port'},
          geometry: {
            type: 'Point',
            coordinates: port.coordinates,
          },
        },
      ]
    : [];
};

const getPolygons = (port: Port): Feature<unknown, unknown>[] => {
  return port.polygon?.length > 0
    ? [
        {
          type: 'Feature',
          properties: {type: 'port', tooltip: getPortLayerLabel({type: PORT_LAYERS.PORT})},
          geometry: {
            type: 'Polygon',
            coordinates: port.polygon,
          },
        },
      ]
    : [];
};

const getPois = (port: Port): Feature<unknown, unknown>[] => {
  return (port.pois || []).reduce((features: Feature[], poi: TODO) => {
    if (poi.polygon?.length > 0) {
      features.push({
        type: 'Feature',
        properties: {type: poi.type, tooltip: getPortLayerLabel(poi)},
        geometry: {
          type: 'Polygon',
          coordinates: poi.polygon,
        },
      });
    }
    if (poi.coordinates?.length > 0) {
      features.push({
        type: 'Feature',
        properties: {type: poi.type},
        geometry: {
          type: 'Point',
          coordinates: poi.coordinates,
        },
      });
    }
    return features;
  }, []);
};
