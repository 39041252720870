import React, {FC} from 'react';
import styled from 'styled-components';
import Avatar from '../../../atoms/Avatar';
import {UserColumn} from '../../../atoms/UserColumn/UserColumn';
import {ContactButton} from '../../../atoms/ConnectionButtons/ContactButton';

import {ConnectedState} from '../../../api/symfony/generated';

export interface Person {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  avatar: string;
  company?: {
    id: number;
    name: string;
  };
}

export interface Props {
  person: Person;
  connectedState: ConnectedState;
}

const RequestRow: FC<Props> = ({person, connectedState}) => (
  <StyledPerson key={person.id}>
    <PersonCredential>
      <PersonImage>
        <Avatar imageUrl={person.avatar} title={person.fullName} size="medium" />
      </PersonImage>
      <PersonName>
        <UserColumn userId={person.id} title={person.fullName} subTitle={person.company?.name} />
      </PersonName>
    </PersonCredential>
    <PersonAction>
      <ContactButton connectedState={connectedState} userID={person.id} />
    </PersonAction>
  </StyledPerson>
);

export default RequestRow;

const StyledPerson = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
  background: var(--color-white);
  font-weight: bold;
  font-size: var(--font-size-2xl);
  color: var(--color-gray-1);
  border-left: var(--border-radius-card) solid var(--color-azure);
  border-radius: var(--border-radius-card);
  box-shadow: var(--box-shadow-card);
`;

const PersonCredential = styled.div`
  display: flex;
  height: 72px;
  align-items: center;
`;

const PersonImage = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
`;

const PersonName = styled.div`
  color: var(--color-gray-1);
  font-size: var(--font-size-md);
  font-weight: bold;
  margin-right: 10px;
`;

const PersonAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
