import {createPortal} from 'react-dom';
import {FC} from 'react';

export const Portal: FC<{targetNodeId: string; children: React.ReactNode}> = ({targetNodeId, children}) => {
  const targetNode = document.getElementById(targetNodeId);
  if (!targetNode) {
    return null;
  }

  return createPortal(children, targetNode);
};
