import Upload, {acceptDocs} from '../Upload';
import {useState, FC} from 'react';
import {useDispatch} from 'react-redux';
import ProjectService from '../../redux/ApiService/ProjectService';
import {TODO} from '../../utils/TODO';
import {useProjectDetails} from './useProjectDetails';

export const UploadDocument: FC = () => {
  const dispatch = useDispatch();
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const {
    project: {documents},
    updateProject,
  } = useProjectDetails();
  const uploadProjectDocuments = (body: TODO) => dispatch(ProjectService.uploadProjectDocuments(body));

  const handleDocumentUpload = async (files: File[]) => {
    const formData = new FormData();
    files.forEach((document, key: number) => {
      formData.append(`fileName${key}`, document);
    });
    setDocumentsLoading(true);
    const response = await uploadProjectDocuments(formData);
    await updateProject({documents: [...documents, ...(response?.data.items ?? [])]});
    setErrors(response?.errors);
    setDocumentsLoading(false);
  };

  return (
    <div className="project-detail-information__upload-field">
      <Upload
        headline="Add files"
        subline="Drag and drop your files here or click to upload"
        accept={acceptDocs}
        multiple
        loading={documentsLoading}
        onDrop={(files: File[]) => handleDocumentUpload(files)}
        uploadErrors={errors}
      />
    </div>
  );
};
