import {useQuery} from '@tanstack/react-query';
import {chokepointCongestionApi} from '../../../../api/node-backend/nodeBackendApis';

export enum ChokepointKey {
  Bosphorus = 'bosphorus',
  Panama = 'panama',
  Suez = 'suez',
}

export const useChokepointCongestionQuery = (chokepoint: ChokepointKey) =>
  useQuery({
    queryKey: ['chokepointCongestion', chokepoint],
    queryFn: async () =>
      await chokepointCongestionApi.getStatistics({
        chokepoint,
      }),
  });
