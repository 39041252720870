/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
  AisCountryApiModel,
  AisCountryApiModelFromJSON,
  AisCountryApiModelFromJSONTyped,
  AisCountryApiModelToJSON,
} from './';

/**
 *
 * @export
 * @interface AisPortApiModel
 */
export interface AisPortApiModel {
  /**
   * The internal ID of the port.
   * @type {number}
   * @memberof AisPortApiModel
   */
  id?: number;
  /**
   * The name of the port.
   * @type {string}
   * @memberof AisPortApiModel
   */
  name?: string;
  /**
   * The port code.
   * @type {string}
   * @memberof AisPortApiModel
   */
  code?: string | null;
  /**
   * The country code of the port.
   * @type {string}
   * @memberof AisPortApiModel
   */
  country?: string | null;
  /**
   *
   * @type {AisCountryApiModel}
   * @memberof AisPortApiModel
   */
  countryObject?: AisCountryApiModel | null;
  /**
   * The timezone of the port.
   * @type {string}
   * @memberof AisPortApiModel
   */
  timezone?: string | null;
  /**
   * The latitude.
   * @type {number}
   * @memberof AisPortApiModel
   */
  lat?: number | null;
  /**
   * The longitude.
   * @type {number}
   * @memberof AisPortApiModel
   */
  lon?: number | null;
  /**
   * The location type. For ports, this is always "port".
   * @type {string}
   * @memberof AisPortApiModel
   */
  type?: string;
}

export function AisPortApiModelFromJSON(json: any): AisPortApiModel {
  return AisPortApiModelFromJSONTyped(json, false);
}

export function AisPortApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AisPortApiModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    code: !exists(json, 'code') ? undefined : json['code'],
    country: !exists(json, 'country') ? undefined : json['country'],
    countryObject: !exists(json, 'countryObject') ? undefined : AisCountryApiModelFromJSON(json['countryObject']),
    timezone: !exists(json, 'timezone') ? undefined : json['timezone'],
    lat: !exists(json, 'lat') ? undefined : json['lat'],
    lon: !exists(json, 'lon') ? undefined : json['lon'],
    type: !exists(json, 'type') ? undefined : json['type'],
  };
}

export function AisPortApiModelToJSON(value?: AisPortApiModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    code: value.code,
    country: value.country,
    countryObject: AisCountryApiModelToJSON(value.countryObject),
    timezone: value.timezone,
    lat: value.lat,
    lon: value.lon,
    type: value.type,
  };
}
