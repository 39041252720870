import React, {FC} from 'react';
import {assertUnreachable} from '../../../../../../utils/assert';
import {Tooltip} from 'antd';
import {DiffIcon, EditIcon} from './shared';
import {NegotiationSubjectModification} from '../../../../../../api/node-backend/generated';

type SubjectModificationIconProps = {
  isOwnEdit: boolean;
  subjectModification?: NegotiationSubjectModification;
};

export const SubjectModificationIcon: FC<SubjectModificationIconProps> = ({isOwnEdit, subjectModification}) => {
  switch (subjectModification) {
    case undefined:
    case NegotiationSubjectModification.Unmodified:
      return (
        <Tooltip title="Unmodified">
          <div>
            <DiffIcon type="pause" size="small" color={isOwnEdit ? 'blue-light' : 'gray'} />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.SubjectTextUpdated:
      return (
        <Tooltip title="Edited text">
          <div>
            <EditIcon type="edit" size="small" color="white-fixed" $bgColor={isOwnEdit ? 'blue' : 'gray-2'} />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.SubjectAccepted:
      return (
        <Tooltip title="Accepted">
          <div>
            <DiffIcon type="checkmark" size="small" color="green" />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.RejectionRequested:
      return (
        <Tooltip title="Requested rejection">
          <div>
            <DiffIcon type="close" size="small" color="red" />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.RejectionRequestRejected:
      return (
        <Tooltip title="Reset rejection request back to ongoing">
          <div>
            <DiffIcon type="edit" size="small" color={isOwnEdit ? 'blue-light' : 'gray'} />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.SubjectRejected:
      return (
        <Tooltip title="Rejected">
          <div>
            <DiffIcon type="close" size="small" color="red" />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.SubjectLifted:
      return (
        <Tooltip title="Lifted">
          <div>
            <DiffIcon type="checkmark" size="small" color={'green'} />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.SubjectFailed:
      return (
        <Tooltip title="Failed">
          <div>
            <DiffIcon type="close" size="small" color={'red'} />
          </div>
        </Tooltip>
      );
    case NegotiationSubjectModification.NewSubject:
      return (
        <Tooltip title="New">
          <div>
            <DiffIcon type="item-add-selection-solid" size="small" color={isOwnEdit ? 'blue' : 'gray'} />
          </div>
        </Tooltip>
      );
    default:
      assertUnreachable(subjectModification);
  }
};
