/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * Describes a characteristic of a bucket.
 * @export
 * @interface OfferTag
 */
export interface OfferTag {
  /**
   * The name of the tag.
   * @type {string}
   * @memberof OfferTag
   */
  name?: string;
  /**
   * The value of the tag.
   * @type {string}
   * @memberof OfferTag
   */
  value?: string;
}

export function OfferTagFromJSON(json: any): OfferTag {
  return OfferTagFromJSONTyped(json, false);
}

export function OfferTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferTag {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    value: !exists(json, 'value') ? undefined : json['value'],
  };
}

export function OfferTagToJSON(value?: OfferTag | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    value: value.value,
  };
}
