import React, {VFC} from 'react';

export const SignupFormStep2: VFC<{
  visible: boolean;
}> = ({visible}) => {
  return (
    <div hidden={!visible}>
      <h3>Thank you.</h3>
      <p>We are checking your request and will redirect you to the login screen if everything went right.</p>
    </div>
  );
};
