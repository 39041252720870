import {useContext, useEffect, useMemo} from 'react';
import {VoyageCharterContext} from '../Context/VoyageCharterContext';
import calcRoutes, {CalcRoutesPropsRoute} from './calcRoutes';
import produce from 'immer';
import {useQuery} from '@tanstack/react-query';
import {getDefaultRouteConsumption} from './getDefaultRouteConsumption';
import {VoyageConsumption} from '../VoyageInformation/VoyageTypes';
import {Coordinates} from '../../../../utils/Coordinates';

export const useCalcRoutesQuery = () => {
  const charterContext = useContext(VoyageCharterContext);

  const {inputState, outputState} = charterContext.state;

  const coordList = useMemo(() => {
    return charterContext.state.inputState.voyage.points.map(point => point.coordinates);
  }, [charterContext.state.inputState.voyage.points]);

  const query = useQuery({
    queryKey: ['useCalcRoutes', coordList, inputState.calculator.distanceCalculator],

    queryFn: async () => {
      const routes = outputState.voyage.routes.map(routeItem => {
        const route: CalcRoutesPropsRoute = {
          coordinates: {
            start: {lat: routeItem.endPoints[0][1], lon: routeItem.endPoints[0][0]},
            end: {lat: routeItem.endPoints[1][1], lon: routeItem.endPoints[1][0]},
          },
        };
        return route;
      });
      const data = await calcRoutes({
        routes,
        options: inputState.calculator.distanceCalculator,
      });
      return data;
    },

    enabled: coordList.length > 1 && charterContext.state.inputState.voyage.routesCalculated === false,
  });

  useEffect(() => {
    if (query.isSuccess && query.data) {
      const input = produce(inputState, draftState => {
        const routes = draftState.voyage.routes;
        query.data.forEach(item => {
          routes[item.index].waypoints = item.waypoints as Coordinates[];
          routes[item.index].distance = Math.round(item.distance);
          routes[item.index].distanceInSeca = Math.round(item.secaIntersection);
          routes[item.index].distanceInPiracy = Math.round(item.piracyIntersection);
          const routeConsumption: VoyageConsumption = getDefaultRouteConsumption({
            consumptionModes: inputState.vessel.consumptionModes,
            useCase: outputState.voyage.points[item.index].usedDwat > 0 ? 'laden' : 'ballast',
            inSeca: (routes[item.index].distanceInSeca ?? 0) > 0,
          });
          if (!routes[item.index].consumption?.manuallyAdded) {
            routes[item.index].consumption = routeConsumption;
          }
        });
        draftState.voyage.routesCalculated = true;
      });

      charterContext.setInputState(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess, query.data]);

  return query;
};
