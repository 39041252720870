import React, {useState} from 'react';
import {Button, Form, Input, message} from 'antd';
import {Workspace} from '../../../redux/ApiService/PortfolioService';
import {CloneableVessel, useCloneVesselToMyFleetMutation} from './useCloneVesselToMyFleetMutation';
import {PortfolioVessel, SubPortfolio} from '../../../redux/Portfolio';
import {CloneWorkspaceSelector} from './components/CloneWorkspaceSelector';
import {CloneSelectSubPortfolios} from './components/CloneSelectSubPortfolios';
import {Modal, ModalWidth} from '../../../antd/components/Modal';
import {useUser} from '../../UserContext/useUser';

type Props = {
  vessel: CloneableVessel;
  onSuccess: (newVessel: PortfolioVessel) => void;
  onCancel: () => void;
};

export const AddToMyFleetModal = (props: Props) => {
  const user = useUser();
  const activeWorkspace = user.workspace;
  const [name, setName] = useState(props.vessel.name);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(activeWorkspace);
  const [selectedSubPortfolios, setSelectedSubPortfolios] = useState<SubPortfolio[]>([]);

  const cloneVesselMutation = useCloneVesselToMyFleetMutation({
    onSuccess: newVessel => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success(`Vessel was added to My Fleet.`);
      props.onSuccess(newVessel);
      props.onCancel();
    },
  });

  return (
    <Modal
      centered={true}
      onCancel={props.onCancel}
      footer={
        <Button
          data-cy={'clonePortfolioEntryModalCloneBtn'}
          disabled={!name}
          key="submit"
          type="primary"
          loading={cloneVesselMutation.isPending}
          onClick={async () => {
            const vesselToClone = {...props.vessel, name};
            cloneVesselMutation.mutate({
              vessel: vesselToClone,
              workspaceId: selectedWorkspace.id,
              subPortfolioIds: selectedSubPortfolios.map(item => item.id),
            });
          }}>
          Add to My Fleet
        </Button>
      }
      open={true}
      width={ModalWidth.Middle}
      title={'Add to My Fleet'}>
      <div data-cy={'AddToMyFleetModalContent'}>
        <Form layout={'vertical'}>
          <Form.Item label="Name">
            <Input id={'name'} value={name} onChange={event => setName(event.target.value)} />
          </Form.Item>
          <CloneWorkspaceSelector selectedWorkspace={selectedWorkspace} onChange={setSelectedWorkspace} />
          <CloneSelectSubPortfolios
            type={'vessel'}
            workspace={selectedWorkspace}
            selectedSubPortfolios={selectedSubPortfolios}
            onChange={setSelectedSubPortfolios}
          />
        </Form>
      </div>
    </Modal>
  );
};
