import {catch404} from './ApiService/catch404';
import {actions as vesselApi, actionTypes as vesselApiTypes} from './ApiService/vesselService';
import {ActionWithPayload} from './middlware/ActionWithPayload';
import produce from 'immer';
import {TODO} from '../utils/TODO';
import {AppThunk} from './AppThunk';
import {getLocalStorage} from '../utils/useLocalStorage';

interface SortField {
  id: string;
  desc: boolean;
}

export interface VesselDetailsSubState {
  items?: TODO[];
  totalItems?: number;
  pageIndex: number;
  pageSize: number;
  sorted: SortField[];
}

interface VesselDetailsState {
  vesselId: number | null;
  vesselHistory: VesselDetailsSubState;
  portHistory: VesselDetailsSubState;
  vesselVoyages: VesselDetailsSubState;
  selectedTimestamp: number | undefined;
}

const initialState: VesselDetailsState = {
  vesselId: null,
  selectedTimestamp: undefined,
  vesselHistory: {
    items: [],
    totalItems: 0,
    pageIndex: 1,
    pageSize: getLocalStorage('grid_page_size-vessel_details_history', 25),
    sorted: [{id: 'createdAt', desc: true}],
  },
  portHistory: {
    items: [],
    totalItems: 0,
    pageIndex: 1,
    pageSize: getLocalStorage('grid_page_size-vessel_details_portcalls', 25),
    sorted: [{id: 'arrivalDate', desc: true}],
  },
  vesselVoyages: {
    items: [],
    totalItems: 0,
    pageIndex: 1,
    pageSize: getLocalStorage('grid_page_size-vessel_details_voyages', 25),
    sorted: [{id: 'departureDate', desc: true}],
  },
};

const actionTypes = {
  VESSEL_DETAILS_SET_VESSEL_HISTORY_PARAMS: 'VESSEL_DETAILS_SET_VESSEL_HISTORY_PARAMS',
  VESSEL_DETAILS_SET_PORT_HISTORY_PARAMS: 'VESSEL_DETAILS_SET_PORT_HISTORY_PARAMS',
  VESSEL_DETAILS_SET_VESSEL_VOYAGES_PARAMS: 'VESSEL_DETAILS_SET_VESSEL_VOYAGES_PARAMS',
  VESSEL_DETAILS_SET_SELECTED_TIMESTAMP: 'VESSEL_DETAILS_SET_SELECTED_TIMESTAMP',
};

export const vesselDetailsReducer = (state = initialState, {type, payload}: ActionWithPayload): VesselDetailsState => {
  switch (type) {
    case vesselApiTypes.getVesselHistory.SUCCESS:
      return {
        ...state,
        vesselHistory: {
          ...state.vesselHistory,
          items: payload.data.items,
          totalItems: payload.data.totalItems,
        },
      };
    case vesselApiTypes.getPortHistory.SUCCESS:
      return {
        ...state,
        portHistory: {
          ...state.portHistory,
          items: payload.data.items,
          totalItems: payload.data.totalItems,
        },
      };
    case vesselApiTypes.getVesselVoyages.SUCCESS:
      return {
        ...state,
        vesselVoyages: {
          ...state.vesselVoyages,
          items: payload.data.items,
          totalItems: payload.data.totalItems,
        },
      };

    case actionTypes.VESSEL_DETAILS_SET_VESSEL_HISTORY_PARAMS:
      return {
        ...state,
        vesselId: payload.vesselId,
        vesselHistory: {
          pageIndex: payload.pageIndex || state.vesselHistory.pageIndex,
          pageSize: payload.pageSize || state.vesselHistory.pageSize,
          sorted: payload.sorted || state.vesselHistory.sorted,
        },
      };
    case actionTypes.VESSEL_DETAILS_SET_PORT_HISTORY_PARAMS:
      return {
        ...state,
        vesselId: payload.vesselId,
        portHistory: {
          pageIndex: payload.pageIndex || state.portHistory.pageIndex,
          pageSize: payload.pageSize || state.portHistory.pageSize,
          sorted: payload.sorted || state.portHistory.sorted,
        },
      };
    case actionTypes.VESSEL_DETAILS_SET_VESSEL_VOYAGES_PARAMS:
      return {
        ...state,
        vesselId: payload.vesselId,
        vesselVoyages: {
          pageIndex: payload.pageIndex || state.vesselVoyages.pageIndex,
          pageSize: payload.pageSize || state.vesselVoyages.pageSize,
          sorted: payload.sorted || state.vesselVoyages.sorted,
        },
      };
    case actionTypes.VESSEL_DETAILS_SET_SELECTED_TIMESTAMP:
      return produce(state, draftState => {
        draftState.selectedTimestamp = payload;
      });
    default:
      return state;
  }
};

export const setSelectedTimestamp =
  (timestamp: number | undefined): AppThunk =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.VESSEL_DETAILS_SET_SELECTED_TIMESTAMP,
      payload: timestamp,
    });

    const vesselId = getState().portfolio.vessel!.id;
    dispatch(getVesselHistory(vesselId));
  };

export interface VesselHistoryParams {
  vesselId: number;
  pageIndex?: number;
  pageSize?: number;
  sorted?: SortField[];
}

export const setVesselHistoryParams =
  (params: VesselHistoryParams): AppThunk =>
  dispatch => {
    dispatch({
      type: actionTypes.VESSEL_DETAILS_SET_VESSEL_HISTORY_PARAMS,
      payload: params,
    });

    const vesselId = params.vesselId;
    dispatch(getVesselHistory(vesselId));
  };

export const getVesselHistory =
  (vesselId: TODO): AppThunk =>
  (dispatch, getState) => {
    const {pageIndex, pageSize, sorted} = getState().vesselDetails.vesselHistory;

    let sortField,
      sortOrder = null;

    if (sorted.length > 0) {
      sortField = sorted[0].id;
      sortOrder = sorted[0].desc ? 'desc' : 'asc';
    }

    dispatch(
      vesselApi.getVesselHistory({
        params: {vesselId},
        queryParams: {pageIndex, pageSize, sortField, sortOrder},
      })
    ).catch(catch404);
  };

export const setPortHistoryParams =
  (params: TODO): AppThunk =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.VESSEL_DETAILS_SET_PORT_HISTORY_PARAMS,
      payload: params,
    });

    const vesselId = getState().portfolio.vessel!.id;
    dispatch(getPortHistory(vesselId));
  };

export const getPortHistory =
  (vesselId: TODO): AppThunk =>
  (dispatch, getState) => {
    const {pageIndex, pageSize, sorted} = getState().vesselDetails.portHistory;

    let sortField,
      sortOrder = null;

    if (sorted.length > 0) {
      sortField = sorted[0].id;
      sortOrder = sorted[0].desc ? 'desc' : 'asc';
    }

    dispatch(
      vesselApi.getPortHistory({
        params: {vesselId},
        queryParams: {pageIndex, pageSize, sortField, sortOrder},
      })
    ).catch(catch404);
  };

export const setVesselVoyagesParams =
  (params: TODO): AppThunk =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.VESSEL_DETAILS_SET_VESSEL_VOYAGES_PARAMS,
      payload: params,
    });

    const vesselId = getState().portfolio.vessel!.id;
    dispatch(getVesselVoyages(vesselId));
  };

export const getVesselVoyages =
  (vesselId: TODO): AppThunk =>
  (dispatch, getState) => {
    const {pageIndex, pageSize, sorted} = getState().vesselDetails.vesselVoyages;

    let sortField,
      sortOrder = null;

    if (sorted.length > 0) {
      sortField = sorted[0].id;
      sortOrder = sorted[0].desc ? 'desc' : 'asc';
    }

    dispatch(
      vesselApi.getVesselVoyages({
        params: {vesselId},
        queryParams: {pageIndex, pageSize, sortField, sortOrder},
      })
    ).catch(catch404);
  };

export const VesselDetailsActions = {
  setSelectedTimestamp,
  setVesselHistoryParams,
  getVesselHistory,
  setPortHistoryParams,
  getPortHistory,
  setVesselVoyagesParams,
  getVesselVoyages,
};
