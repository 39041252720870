import {CSSProperties, FC, ReactNode} from 'react';
import cx from 'classnames';
import '../style.scss';
import lighthouse from './lighthouse.png';
import {Button} from '../../Button/Button';

const Lighthouse: FC<{
  headline?: string;
  subHeadline?: ReactNode;
  small?: boolean;
  block?: boolean;
  style?: CSSProperties;
  onClickButton?: () => void;
  buttonText?: ReactNode;
  hideImage?: boolean;
  showCallbackButton?: boolean;
  alternativeImage?: string;
  className?: string;
}> = ({
  headline,
  subHeadline,
  small,
  block,
  style,
  onClickButton,
  buttonText,
  showCallbackButton = false,
  alternativeImage,
  hideImage = false,
  className,
}) => (
  <div className={cx('empty-state', className, {'empty-state--block': block})} style={style}>
    <div className={'empty-state__content'}>
      {!hideImage && (
        <img
          src={alternativeImage ? alternativeImage : lighthouse}
          className={!alternativeImage ? cx('empty-state__img', {'empty-state__img--small': small}) : ''}
          alt={headline}
        />
      )}

      <div className={cx('empty-state__headline', {'empty-state__headline--small': small})}>{headline}</div>
      <div className={cx('empty-state__sub-headline', {'empty-state__sub-headline--small': small})}>{subHeadline}</div>
      {showCallbackButton && (
        <Button
          upper
          bold
          onClick={onClickButton}
          dataTestId={'lighthouseButton'}
          label={buttonText}
          id="lighthouse-button"
          primary
        />
      )}
    </div>
  </div>
);

export default Lighthouse;
