import {StyledButton} from './StyledButton';
import {MinusOutlined} from '@ant-design/icons/lib';
import {useHandleZoom} from '../Hooks/useHandleZoom';

export const ZoomDecreaseButton = () => {
  const {increaseZoomBy, canDecrease} = useHandleZoom();

  return (
    <StyledButton onClick={() => increaseZoomBy(-1)} disabled={!canDecrease}>
      <MinusOutlined />
    </StyledButton>
  );
};
