import {JSONAllMultiData} from '../../../../../api/parser/generated';
import {getConsumptions} from './getConsumptions';
import {getStationLocations} from '../utils/getStationLocations';
import {getVesselTypeFromSpire} from '../../../../MyFleetScreens/MyFleetScreen/VesselsFromSpire/getVesselTypeFromSpire';
import {getGearsFromParser} from './getGearsFromParser';
import {NotAvailable} from '../../../../../utils/NotAvailable';
import {parseIntTS} from '../../../../../utils/parseNumberTS';
import {DesignSubType} from '../../../../VesselDatabaseScreen/VesselDatabaseGrid/VesselGridActionMenu/getDesignSubType';
import {Station} from '../../../../../api/symfony/schemas/GetVesselDetailsResponseSchema/GetVesselDetailsResponseSchema';
import {assert} from '../../../../../utils/assert';
import {PortfolioVessel} from '../../../../../redux/Portfolio';
import {getIntakes} from './getIntakes';

type TransformedVessel = PortfolioVessel;

export const transformParserVesselToPlatformVessel = async (
  parsedMailData: JSONAllMultiData
): Promise<TransformedVessel> => {
  const vesselData = parsedMailData.vesselDataList?.[0]?.parsedVesselData;
  assert(vesselData);

  const intakes = getIntakes(vesselData);
  const consumptions = getConsumptions(vesselData);
  const gears = getGearsFromParser(vesselData);

  const vessel = {
    name: vesselData.vesselName ?? NotAvailable,
    builtYear: parseIntTS(vesselData.yearBuilt),
    nextOpenFrom: vesselData.date?.start?.toISOString() ?? null,
    nextOpenTo: vesselData.date?.end?.toISOString() ?? null,
    spotDate: vesselData.date?.spot && vesselData.date.start ? vesselData.date.start.toISOString() : null,
    spotState: !!vesselData.date?.spot,
    imo: parseIntTS(vesselData.imoNumber),
    mmsi: parseIntTS(vesselData.mmsi) ?? null,
    shipBuilder: vesselData.builder ?? null,
    confidenceScore: vesselData.confidence ?? null,
    vesselType: getVesselTypeFromSpire(vesselData.vesselType ?? null),
    grain: vesselData.grainCapacity ?? null,
    grainUnit: vesselData.grainCapacity ? 'cbm' : null,
    bale: vesselData.baleCapacity ?? null,
    baleUnit: vesselData.baleCapacity ? 'cbm' : null,
    designType: vesselData.design ?? null,
    designSubType: (vesselData.subType as DesignSubType) ?? null,
    tonnesPerCentimetre: vesselData.tpc ?? null,
    lengthOverall: vesselData.length ?? null,
    draft: vesselData.draft ?? null,
    holdsAmount: vesselData.holds ?? null,
    hatchesAmount: vesselData.hatches ?? null,
    featureLogFitted: vesselData.extraFittings?.logFitted ?? null,
    featureLakesFitted: vesselData.extraFittings?.lakesFitted ?? null,
    featureCo2Fitted: vesselData.extraFittings?.co2Fitted ?? null,
    featureItfFitted: vesselData.extraFittings?.itfFitted ?? null,
    featureTweenDecks: vesselData.extraFittings?.tweenDecks ?? null,
    featureBoxShape: vesselData.extraFittings?.boxShape ?? null,
    featureOpenHatch: vesselData.extraFittings?.openHatch ?? null,
    featureA60Bulkhead: vesselData.extraFittings?.a60Bulkhead ?? null,
    featureCellular: vesselData.extraFittings?.celled ?? null,
    featureBowThrust: vesselData.extraFittings?.bowThruster ?? null,
    featureSternThrust: vesselData.extraFittings?.sternThruster ?? null,
    featureScrubberFitted: vesselData.extraFittings?.scrubberFitted ?? null,
    countryFlag: vesselData.isoFlag ?? null,
    reeferPlugs: vesselData.reefer ?? null,
    dwtSummer: vesselData.size ?? null,
    grossTonnage: vesselData.grt ?? null,
    netTonnage: vesselData.nrt ?? null,
    beam: vesselData.beam ?? null,
    intakes: intakes as unknown as TransformedVessel['intakes'], // We need to force here because the typing of the vessel edit form is incorrect

    consumptions: consumptions,
    gears: gears,
    stations: [
      {
        type: 'nextopen',
        locations: await getStationLocations({portNames: vesselData.port ?? undefined, locationType: 'nextopen'}),
        isRange: false,
      } as Station,
    ],

    exName: null,
    user: null,
    company: null,
    charterName: null,
    callSign: null,
    comment: null,
    dryDockFrom: null,
    dryDockTo: null,
    dryDockNote: null,
    managementHeadOwner: null,
    managementManagingOwner: null,
    managementDisponentOwner: null,
    iceClass: null,
    status: null!,
    rate: null,
    rateCurrency: null,
    rateTerms: null,
    redeliveryNotice: null,
    color: null,
    documents: [],
    images: [],
    fixtures: [],
    fixtureCount: 0,
    selectedFixture: null,
    usesFixtures: false,
    isArchived: false,
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
    target: 'portfolio' as const,
    id: -1,
  };

  return vessel;
};
