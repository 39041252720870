import {MenuItemType} from 'antd/es/menu/interface';
import {MenuItem} from './SidebarMenuComponents';
import {MenuItemDef} from './MenuDef';
import {ReactElement} from 'react';
import {LinkComponent} from './LinkComponent';
import {Label} from './Label';

export const makeMenuItem = (
  item: MenuItemDef,
  hasPermission: boolean,
  icon: ReactElement | undefined,
  enableUpgradeBadge: boolean
): MenuItemType => {
  const isExternalLink = item.url.startsWith('http');

  if (!hasPermission) {
    return makePremiumMenuItem(item, false, icon, enableUpgradeBadge);
  }

  return {
    key: item.key,
    style: {
      paddingLeft: enableUpgradeBadge ? 8 : undefined,
      width: 'calc(100% + 16px)',
    },
    label: (
      <LinkComponent isExternalLink={isExternalLink} url={item.url}>
        <MenuItem premiumFeature={true} data-testid={item.testId} data-tour-id={item.tourId}>
          <Label
            enableUpgradeBadge={enableUpgradeBadge}
            isBetaFeature={item.isBetaFeature ?? false}
            text={item.label}
            icon={icon}
          />
        </MenuItem>
      </LinkComponent>
    ),
  };
};

const makePremiumMenuItem = (
  item: MenuItemDef,
  hasPermission: boolean,
  icon: ReactElement | undefined,
  enableUpgradeBadge: boolean
): MenuItemType => {
  const url = hasPermission ? item.url : '/subscription';

  return {
    key: item.key,
    style: {
      paddingLeft: enableUpgradeBadge ? 8 : undefined,
      width: 'calc(100% + 16px)',
    },
    label: (
      <LinkComponent url={url}>
        <MenuItem premiumFeature={true} data-testid={item.testId} data-tour-id={item.tourId}>
          <Label
            enableUpgradeBadge={enableUpgradeBadge}
            isBetaFeature={item.isBetaFeature ?? false}
            text={item.label}
            badgeStyle={{
              backgroundColor: 'var(--color-upgrade)',
              color: 'var(--color-white)',
              position: 'absolute',
              right: 44,
            }}
            badge={hasPermission ? undefined : 'Upgrade'}
            icon={icon}
          />
        </MenuItem>
      </LinkComponent>
    ),
  };
};
