import {MenuItem, MenuItemLink} from '../../atoms/Menu/MenuItem';
import {useSelector} from '../../redux/react-redux';
import {CharterCalculation} from '../../api/symfony/generated';
import {canDoVoyageCalc} from './canDoVoyageCalc';

type Props = {
  vessel?: {id: number};
  cargo?: {id: number};
  items: CharterCalculation[];
};

export const CharterCalculationMenuItem = (props: Props) => {
  const project = useSelector(state => state.projects.project);

  const cargoId = project.cargo?.id ?? props.cargo?.id;
  const vesselId = project.vessel?.id ?? props.vessel?.id;

  const projectId = project.id;

  if (!canDoVoyageCalc({vessel: props.vessel, cargo: props.cargo, ...project})) {
    return (
      <MenuItem
        disabled={true}
        disabledHint={
          'Unfortunately we do not support this combination yet. You can calculate voyages for a bulker with a dry bulk cargo.'
        }
        label={'Start calculation'}
      />
    );
  }

  const item = props.items.find(
    currentItem =>
      currentItem.cargoes?.find(cargoItem => cargoItem.id === cargoId) !== undefined &&
      currentItem.vessel?.id === vesselId
  );

  const isNew = item === undefined;

  return (
    <MenuItemLink
      label={isNew ? 'Start calculation' : 'Load calculation'}
      href={
        isNew
          ? `/chartercalculator/voyagecharter?cargoes_ids=${cargoId}&vessel_id=${vesselId}&project_id=${projectId}`
          : `/chartercalculator/voyagecharter?id=${item!.id}`
      }
    />
  );
};
