import {Dayjs} from 'dayjs';
import calcRoutes from '../../../../../../components/CharterCalculation/Voyagecharter/utils/calcRoutes';
import {Step5Vessel} from '../../step5';
import {getDurationInDaysBySpeed12Knots} from './getDurationInDaysBySpeed12Knots';
import {PredictedPort} from './getPredictedPort';

export const getEstimatedEta = async ({
  vessel,
  predictedPort,
  currentTime,
}: {
  vessel: Step5Vessel;
  predictedPort: PredictedPort;
  currentTime: Dayjs;
}): Promise<Dayjs> => {
  let eta = currentTime.clone();

  const response = await calcRoutes({
    routes: [
      {
        coordinates: {
          start: {lat: vessel.coordinates![0], lon: vessel.coordinates![1]},
          end: {lat: predictedPort.latitude, lon: predictedPort.longitude},
        },
      },
    ],
    options: {
      allowKielCanal: true,
      allowSuezCanal: true,
      allowPanamaCanal: true,
      avoidPiracyAreas: true,
      avoidSECA: true,
    },
  });
  const distance = response[0].distance;
  const duration = getDurationInDaysBySpeed12Knots(distance);

  eta = eta.add(duration, 'days');
  return eta;
};
