import {negotiationAttachmentApi} from '../../../../../api/node-backend/nodeBackendApis';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {NegotiationAttachment} from '../../../../../api/node-backend/generated';

export const useDeleteAttachmentMutation = (
  options: UseMutationOptions<void, Error, {negotiationId: string; attachment: NegotiationAttachment}>
) => {
  return useMutation({
    mutationFn: async ({negotiationId, attachment}: {negotiationId: string; attachment: NegotiationAttachment}) => {
      await negotiationAttachmentApi.deleteAttachment({
        negotiationId: negotiationId,
        attachmentId: attachment.id,
      });
    },
    ...options,
  });
};
