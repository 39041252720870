/* tslint:disable */
/* eslint-disable */
/**
 * seabo Platform API
 * An API to power the seabo Platform
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface UpdateCompanyPaymentStatus
 */
export interface UpdateCompanyPaymentStatus {
  /**
   *
   * @type {boolean}
   * @memberof UpdateCompanyPaymentStatus
   */
  isPaid: boolean;
}

export function UpdateCompanyPaymentStatusFromJSON(json: any): UpdateCompanyPaymentStatus {
  return UpdateCompanyPaymentStatusFromJSONTyped(json, false);
}

export function UpdateCompanyPaymentStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCompanyPaymentStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isPaid: json['isPaid'],
  };
}

export function UpdateCompanyPaymentStatusToJSON(value?: UpdateCompanyPaymentStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isPaid: value.isPaid,
  };
}
