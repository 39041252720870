import {
  MarketVesselDatabaseFilter,
  MarketVesselFilterBranchDefinitions,
} from '../../../components/FilterProvider/Filters/Market/MarketVesselFilterBranchDefinitions';
import {useFilterProvider} from '../../../components/FilterProvider/useFilterProvider';
import {FilterProviderState} from '../../../components/FilterProvider/FilterProviderState';
import {MarketFiltersActions} from '../../../redux/MarketFilters';
import {useDispatch, useSelector} from '../../../redux/react-redux';
import {useSetPage} from '../utils/useSetGridMetaData';

export const useMarketVesselFilterProvider = () => {
  const dispatch = useDispatch();

  const filterProviderState = useSelector(state => state.marketFilters.vesselFilterProviderState);

  const setPage = useSetPage('vessel');

  const onFilterSettingsChange = (filterSettings: MarketVesselDatabaseFilter) => {
    setVesselFilterSettings(filterSettings);
  };

  const onFilterSettingsChangeByUser = () => {
    setPage(1);
  };

  const setVesselFilterSettings = (filterSettings: MarketVesselDatabaseFilter) =>
    dispatch(MarketFiltersActions.setVesselFilterSettings(filterSettings));

  const setVesselFilterProviderState = (
    filterProviderState: FilterProviderState<typeof MarketVesselFilterBranchDefinitions>
  ) => dispatch(MarketFiltersActions.setVesselFilterProviderState(filterProviderState));

  const filterProviderApi = useFilterProvider({
    name: 'market/vessel',
    filterBranchDefinitions: MarketVesselFilterBranchDefinitions,
    filterProviderState,
    onFilterSettingsChange,
    onFilterSettingsChangeByUser,
    onFilterProviderStateChange: setVesselFilterProviderState,
  });

  return filterProviderApi;
};
