import {createSelector} from 'reselect';

import {makeApiService, ApiServiceEntry} from '../ApiService';
import CompanyUser from '../../../model/company/CompanyUser';
import {RootState} from '../../store';
import {Company, CompanySchema} from './Company';
import {SeaboThunkAction} from '../SeaboThunkAction';
import {TODO} from '../../../utils/TODO';

type Id = TODO;
type WorkspaceId = TODO;

const entries: ApiServiceEntry[] = [
  {
    fn: 'getCompany',
    route: '/api/company/{id}',
    method: 'GET',
    responseSchema: CompanySchema,
  },
  {
    fn: 'companyUpdate',
    route: '/api/company/{id}',
    method: 'PUT',
  },
  {
    fn: 'getMembers',
    route: '/api/company/{id}/members',
    method: 'GET',
  },
  {
    fn: 'addMembers',
    route: '/api/company/{id}/members',
    method: 'POST',
  },
  {
    fn: 'removeMember',
    route: '/api/company/{id}/members',
    method: 'DELETE',
  },

  {
    fn: 'updateMemberRole',
    route: '/api/company/{id}/members',
    method: 'PUT',
  },

  {
    fn: 'getWorkspaceList',
    route: '/api/company/{id}/workspaces',
    method: 'GET',
  },
  {
    fn: 'getUserWorkspaceList',
    route: '/api/users/company_workspaces',
    method: 'GET',
  },
  {
    // SINGLE
    fn: 'getWorkspaceDetails',
    route: '/api/company/{id}/workspace/{workspaceID}',
    method: 'GET',
  },
  {
    // SINGLE .memberlist
    fn: 'getWorkspaceMembers',
    route: '/api/company/{id}/workspace/{workspaceID}/members',
    method: 'GET',
  },
  {
    // SINGLE
    fn: 'addWorkspace',
    route: '/api/company/{id}/workspace',
    method: 'POST',
  },
  {
    // SINGLE
    fn: 'editWorkspace',
    route: '/api/company/{id}/workspace/{workspaceID}',
    method: 'PATCH',
  },
  {
    // SINGLE
    fn: 'deleteWorkspace',
    route: '/api/company/{id}/workspace/{workspaceID}',
    method: 'DELETE',
  },
  {
    // SINGLE
    fn: 'addMembersToWorkspace',
    route: '/api/company/{id}/workspace/{workspaceID}/members',
    method: 'POST',
  },
  {
    // follow
    fn: 'followOrUnfollow',
    route: '/api/company/{id}/follow',
    method: 'POST',
  },
  // data
  {
    fn: 'getCompanyData',
    route: '/api/company/{id}/statistics',
    method: 'GET',
  },
];

const company = makeApiService(entries, {apiName: 'company'});

export const reducers = company.reducers;
export const actions = company.actions;
export const actionTypes = company.actionTypes;

export const CompanyServiceActions = {
  getCompany: (id: Id): SeaboThunkAction<Company> => actions.getCompany({params: {id}}),
  updateCompany: (id: Id, body: TODO) => actions.companyUpdate({params: {id}, body}),

  getMembers: (id: Id) => actions.getMembers({params: {id}}),
  addMembers: (id: Id, body: TODO) => actions.addMembers({params: {id}, body}),
  removeMember: (id: Id, body: TODO) => actions.removeMember({params: {id}, body}),

  updateMemberRole: (id: Id, body: TODO) => actions.updateMemberRole({params: {id}, body}),

  addWorkspace: (id: Id, body: TODO) => actions.addWorkspace({params: {id}, body}),
  editWorkspace: (id: Id, workspaceID: WorkspaceId, body: TODO) =>
    actions.editWorkspace({params: {id, workspaceID}, body}),
  deleteWorkspace: (id: Id, workspaceID: WorkspaceId) => actions.deleteWorkspace({params: {id}, workspaceID}),
  addMembersToWorkspace: (id: Id, workspaceID: WorkspaceId, body: TODO) =>
    actions.addMembersToWorkspace({params: {id, workspaceID}, body}),

  getWorkspaceList: (id: Id) => actions.getWorkspaceList({params: {id}}),
  getUserWorkspaceList: () => actions.getUserWorkspaceList(),
  getWorkspaceDetails: (id: Id, workspaceID: WorkspaceId) => actions.getWorkspaceDetails({params: {id, workspaceID}}),
  getWorkspaceMembers: (id: Id, workspaceID: WorkspaceId) => actions.getWorkspaceMembers({params: {id, workspaceID}}),

  followOrUnfollow: (id: Id, body: TODO) => actions.followOrUnfollow({params: {id}, body}),

  getCompanyData: (id: Id) => actions.getCompanyData({params: {id}}),
};

export default CompanyServiceActions;

export const memberSelector = createSelector(
  (state: RootState) => state.company.users,
  users => (users ? users.map((user: TODO) => new CompanyUser(user)) : [])
);

export const allWorkspaceSelector = createSelector(
  (state: RootState) => state.company.workspaces.data,
  workspaces =>
    workspaces
      ? (workspaces.items ?? []).map(workspace => {
          return {
            ...workspace,
            users: workspace.users?.map((user: TODO) => new CompanyUser(user)),
          };
        })
      : []
);

export const workspaceSelector = createSelector(allWorkspaceSelector, workspaces =>
  workspaces.filter(workspace => !workspace.isDisabled)
);

export const inactiveWorkspaceSelector = createSelector(allWorkspaceSelector, workspaces =>
  workspaces.filter(workspace => workspace.isDisabled)
);

export const companyUserSelector = createSelector(
  (state: RootState) => state.company.data,
  company => (company ? (company.user ?? []).map((user: TODO) => new CompanyUser(user)) : [])
);
